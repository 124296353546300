import React, { Component } from 'react';
import { Avatar, Button, Drawer, Icon, notification, Input } from 'antd';
import { Text, Title } from 'common-components/typography';
import SectionContainer from 'common-components/containers/SectionContainer';
import { HoverCard } from 'common-components/cards';
import { Colors, ProgressBar } from '@blueprintjs/core';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import _ from 'lodash';
import { isEmpty } from 'lodash';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { TeamStatus } from 'utilities/enum-utils';
const { Search } = Input;

interface IWorkerDrawerProps {
  visible: boolean;
  onCloseDrawer: () => void;
  onAssignWorker: (workerId) => void;
  workerList: [];
  doFetchWorkerListLite: typeof dispatch.teamStore.doFetchWorkerListLite;
  serviceItem: any;
  rosterList: any;
  doAddWorkerIntoRoster: typeof dispatch.servicesStore.doAddWorkerIntoRoster;
}

interface IWorkerDrawerState {
  selectedItemKeys: Array<any>;
  isFetchingList: boolean;
  isAssigningWorker: boolean;
  isSearching: boolean;
  searchString: string;
  isActionModalOpen: boolean;
}

class ServiceRosterAddWorkerDrawer extends Component<IWorkerDrawerProps, IWorkerDrawerState> {
  state = {
    selectedItemKeys: [],
    isFetchingList: false,
    isAssigningWorker: false,
    isSearching: false,
    searchString: '',
    isActionModalOpen: false
  };

  componentDidMount = async () => {
    const { doFetchWorkerListLite } = this.props;
    this.setState({ isFetchingList: true });
    await doFetchWorkerListLite({
      supportWorkerStatus: [TeamStatus.ENABLED, TeamStatus.DRAFT, TeamStatus.BLOCKED],
      hasAppAccess: ['WORKER']
    });
    this.setState({ isFetchingList: false });
  };

  private _selectWorker = (workerId) => {
    const { selectedItemKeys } = this.state;
    let newSelectedItemKeys = selectedItemKeys;
    !_.find(newSelectedItemKeys, (key) => key === workerId)
      ? newSelectedItemKeys.push(workerId)
      : (newSelectedItemKeys = _.filter(newSelectedItemKeys, (item) => item !== workerId));

    this.setState({ selectedItemKeys: newSelectedItemKeys });
  };

  private _searchText = (txt) => {
    this.setState({ isSearching: false, searchString: txt });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });

    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _applySearchFilter = (workerList, searchStr) => {
    const targetStr = searchStr
      .toLowerCase()
      .split(' ')
      .filter(function(el) {
        return el.length !== 0;
      });

    // local filter; can expand this to include , later.
    const filteredList = workerList.filter((worker) =>
      _.some(
        targetStr,
        (string) =>
          (worker.firstName && worker.firstName.toLowerCase().indexOf(string) >= 0) ||
          (worker.lastName && worker.lastName.toLowerCase().indexOf(string) >= 0)
      )
    );

    return _.orderBy(
      _.map(filteredList, (worker) => {
        const nameArray = [...worker.firstName.toLowerCase().split(' '), ...worker.lastName.toLowerCase().split(' ')];
        let matchingRelevance = _.intersection(nameArray, targetStr);
        return { ...worker, matchingRelevance: matchingRelevance.length };
      }),
      'matchingRelevance',
      'desc'
    );
  };

  private _renderWorkerList() {
    if (this.state.isFetchingList) {
      return (
        <>
          <div className="mb-medium">
            <Text>Fetching team list...</Text>
          </div>
          <ProgressBar />
        </>
      );
    }

    const { workerList } = this.props;

    let filteredWorkerList = _.filter(
      workerList,
      (worker: any) => !_.find(this.props.rosterList, (roster) => roster.supportWorkerId === worker.supportWorkerId)
    );

    filteredWorkerList = _.isEmpty(this.state.searchString)
      ? [...filteredWorkerList]
      : this._applySearchFilter(filteredWorkerList, this.state.searchString);

    if (filteredWorkerList.length === 0) {
      return (
        <Text>
          Oops! No Team Member selectable found.{' '}
          {!_.isEmpty(this.state.searchString) && 'Try changing your searched text.'}
        </Text>
      );
    }

    return _.map(filteredWorkerList, (worker: any) => {
      const isSelected = _.find(this.state.selectedItemKeys, (key) => {
        return key === worker.supportWorkerId;
      });

      return (
        <HoverCard
          marginBottom={16}
          borderColor={Colors.LIGHT_GRAY5}
          key={worker.supportWorkerId}
          isSelected={isSelected}
          onClick={() => this._selectWorker(worker.supportWorkerId)}
        >
          <div className={'flex-row align-center justify-between'}>
            {/* Avatar image */}
            <div className="flex-row align-center">
              <Avatar src={worker.attachmentUrl} shape={'circle'} className={'avatar-medium'} />
              {/* Title */}
              <Title className={'ml-medium mt-none mb-none'} level={3}>
                {`${worker.firstName} ${worker.lastName}`}
              </Title>
            </div>

            <div>
              {isSelected && (
                <Icon type={'check-circle'} theme={'filled'} style={{ color: Colors.BLUE4, fontSize: '20px' }} />
              )}
            </div>
          </div>
        </HoverCard>
      );
    });
  }

  private _closeActionModal = () => {
    this.setState({ isActionModalOpen: false });
  };

  private _closeDrawerAndModal = () => {
    this.setState({ isActionModalOpen: false, selectedItemKeys: [] });
    this.props.onCloseDrawer();
  };

  private _handleAssignWorker = async () => {
    const { doAddWorkerIntoRoster, serviceItem, onCloseDrawer } = this.props;

    const workerIds = this.state.selectedItemKeys;

    if (!isEmpty(workerIds)) {
      // console.log(workerId);

      this.setState({ isAssigningWorker: true });
      try {
        await doAddWorkerIntoRoster({ serviceId: serviceItem.serviceId, supportWorkerIds: workerIds });

        this.setState({ selectedItemKeys: [] });

        notification.success({
          message: 'The selected Team Members have been added.'
        });
      } catch (e) {
        notification.error({ description: e.meta.message, message: e.message });
      }

      this.setState({ isAssigningWorker: false });

      onCloseDrawer();
    }
  };

  private _onCloseModal = () => {
    const { onCloseDrawer } = this.props;
    const { selectedItemKeys } = this.state;

    if (selectedItemKeys.length > 0) this.setState({ isActionModalOpen: true });
    else {
      this.setState({ selectedItemKeys: [] });
      onCloseDrawer();
    }
  };

  render() {
    const { visible } = this.props;
    const { selectedItemKeys } = this.state;

    const numberOfSelectedWorkers = selectedItemKeys.length;

    // TODO - Fix up the support Worker ID to show the correct one instead

    return (
      <>
        <ActionModal
          isOpen={this.state.isActionModalOpen}
          onClose={this._closeActionModal}
          title={'Discard changes'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>selected Team Member</b>, proceeding will remove your selection.
          </Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeActionModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size="large" onClick={this._closeDrawerAndModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <Drawer
          visible={visible}
          onClose={this._onCloseModal}
          title={'Select Team Worker from your team'}
          width={'40vw'}
        >
          <Search
            type={'Text'}
            size="large"
            allowClear={true}
            placeholder={'search by name'}
            className="mb-medium"
            onChange={this._onEnterSearchText}
            loading={this.state.isSearching}
          />
          <SectionContainer bordered>{this._renderWorkerList()}</SectionContainer>

          {this.state.isAssigningWorker && (
            <div className="mb-medium">
              <div className="mb-medium">
                <Text>Adding to service...</Text>
              </div>
              <ProgressBar />
            </div>
          )}

          <div
            style={{
              bottom: 0,
              position: 'sticky',
              zIndex: 20,
              width: 'inherit',
              height: '100px',
              backgroundColor: 'white'
            }}
            className="flex-row align-center justify-end"
          >
            <Button.Group>
              <GhostButton icon={'close'} size="large" onClick={this._onCloseModal}>
                Close
              </GhostButton>
              <PrimaryButton
                className="mr-medium rounded"
                size="large"
                icon={'plus'}
                disabled={numberOfSelectedWorkers === 0}
                onClick={this._handleAssignWorker}
                loading={this.state.isAssigningWorker}
              >
                {' '}
                Add Selected Team Member{numberOfSelectedWorkers > 1 && 's'}{' '}
                {numberOfSelectedWorkers > 0 && '(' + numberOfSelectedWorkers + ')'}
              </PrimaryButton>
            </Button.Group>
          </div>
        </Drawer>
      </>
    );
  }
}

const mapState = (state) => ({
  workerList: state.teamStore.workerListLite
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchWorkerListLite: dispatch.teamStore.doFetchWorkerListLite,
  doAddWorkerIntoRoster: dispatch.servicesStore.doAddWorkerIntoRoster
});

export default connect(
  mapState,
  mapDispatch
)(ServiceRosterAddWorkerDrawer);
