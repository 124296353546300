import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Checkbox, Col, Icon, notification, Row, Select } from 'antd';
import _ from 'lodash';
import { SubTitle, Text } from 'common-components/typography';
import NumberInput from 'common-components/inputs/NumberInput';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ndisHelper from 'variables/data/ndis-helper';
import { PaymentSources } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';

interface IAddManualQuoteModalProps {
  isOpen: boolean;
  isEdit: boolean;
  selectedService: any;
  onAddEditQuotation: (quotation) => void;
  onClose: () => void;
  quotation: any;
  paymentSourceType: string;
}

interface IAddManualQuoteModalState {
  title: string;
  isTotalQuote: boolean;
  isCategoryQuote: boolean;
  quoteAmount: number;
  spentAmount: number;
  balance: number;
  categoryQuote: ICategoryQuote[];
  categoryTotal: number;
  categorySpent: number;
  isQuoteAmountZeroError: boolean;
}

interface ICategoryQuote {
  quoteAmount: number;
  spentAmount: number;
  supportCategoryNumber: number;
  categoryName: string;
  isQuoteAmountZeroError: boolean;
  isEmptyCategoryError: boolean;
}

class AddManualQuoteModal extends Component<IAddManualQuoteModalProps, IAddManualQuoteModalState> {
  state = {
    title: 'Enter quote details',
    isTotalQuote: this.props.paymentSourceType !== PaymentSources.NDIS,
    isCategoryQuote: false,
    quoteAmount: 0,
    spentAmount: 0,
    balance: 0,
    categoryQuote: [],
    categoryTotal: 0,
    categorySpent: 0,
    isQuoteAmountZeroError: false
  };

  private _handleIsTotalQuote = () => {
    this.setState({
      isTotalQuote: !this.state.isTotalQuote,
      isCategoryQuote: this.state.isTotalQuote ? false : this.state.isCategoryQuote
    });
  };

  private _handleIsCategoryQuote = () => {
    this.setState({
      isCategoryQuote: !this.state.isCategoryQuote,
      isTotalQuote: this.state.isCategoryQuote ? false : this.state.isTotalQuote
    });
  };

  private _onChangeQuoteAmount = (value) => {
    const balance = value - this.state.spentAmount;

    this.setState({ balance, quoteAmount: value, isQuoteAmountZeroError: false });
  };

  private _onChangeSpentAmount = (value) => {
    const balance = this.state.quoteAmount - value;
    this.setState({ balance, spentAmount: value });
  };

  private _onAddQuoteCategory = () => {
    this.setState({
      categoryQuote: this.state.categoryQuote.concat({
        quoteAmount: 0,
        spentAmount: 0,
        supportCategoryNumber: null,
        categoryName: null
      })
    });
  };

  private _onClose = () => {
    this.setState({
      title: 'Enter quote details',
      isTotalQuote: false,
      isCategoryQuote: false,
      quoteAmount: 0,
      spentAmount: 0,
      balance: 0,
      categoryQuote: [],
      categoryTotal: 0,
      categorySpent: 0
    });
    this.props.onClose();
  };

  private _onChangeCategory = (value, index) => {
    const categoryQuote = _.clone(this.state.categoryQuote);
    categoryQuote[index] = value;

    const { quoteAmount, spentAmount } = _.reduce(
      categoryQuote,
      (acc, quote) => {
        return { quoteAmount: acc.quoteAmount + quote.quoteAmount, spentAmount: acc.spentAmount + quote.spentAmount };
      },
      { quoteAmount: 0, spentAmount: 0 }
    );

    this.setState({ categoryQuote, categoryTotal: quoteAmount, categorySpent: spentAmount });
  };

  private _onDeleteCategory = (index) => {
    const categoryQuote = _.clone(this.state.categoryQuote);
    categoryQuote.splice(index, 1);

    const { quoteAmount, spentAmount } = _.reduce(
      categoryQuote,
      (acc, quote) => {
        return { quoteAmount: acc.quoteAmount + quote.quoteAmount, spentAmount: acc.spentAmount + quote.spentAmount };
      },
      { quoteAmount: 0, spentAmount: 0 }
    );

    this.setState({ categoryQuote, categoryTotal: quoteAmount, categorySpent: spentAmount });
  };

  private _validateInput = () => {
    const { categoryQuote, isTotalQuote, quoteAmount } = this.state;
    let validated = true;

    if (isTotalQuote && quoteAmount === 0) {
      this.setState({ isQuoteAmountZeroError: true });
      validated = false;
    } else if (!isTotalQuote) {
      let newCategoryQuote = _.map(categoryQuote, (quote) => {
        if (!quote.quoteAmount || quote.quoteAmount === 0 || !quote.supportCategoryNumber) {
          validated = false;
          if (!quote.quoteAmount || quote.quoteAmount === 0) quote.isQuoteAmountZeroError = true;
          if (!quote.supportCategoryNumber) quote.isEmptyCategoryError = true;
        }

        return quote;
      });

      this.setState({ categoryQuote: newCategoryQuote });
    }
    return validated;
  };

  private _onSave = () => {
    if (!this._validateInput()) return;

    const { selectedService } = this.props;

    const { quoteAmount, spentAmount } = _.reduce(
      this.state.categoryQuote,
      (acc, quote) => {
        return { quoteAmount: acc.quoteAmount + quote.quoteAmount, spentAmount: acc.spentAmount + quote.spentAmount };
      },
      { quoteAmount: 0, spentAmount: 0 }
    );

    const manualQuote = {
      serviceId: selectedService.serviceId,
      serviceName: selectedService.serviceName,
      quoteAmount: this.state.isTotalQuote ? this.state.quoteAmount : quoteAmount,
      additionalCost: 0,
      isManualQuote: true,
      isManualTotal: this.state.isTotalQuote,
      spentAmount: this.state.isTotalQuote ? this.state.spentAmount : spentAmount,
      timeSlots: [],
      subQuotes: this.state.isTotalQuote
        ? [{ quoteAmount: this.state.quoteAmount, spentAmount: this.state.spentAmount, supportCategoryNumber: null }]
        : this.state.categoryQuote
    };

    this.props.onAddEditQuotation(manualQuote);
    this._onClose();
  };

  componentDidMount() {
    const { isEdit, quotation, paymentSourceType } = this.props;
    if (isEdit && quotation) {
      this.setState({
        spentAmount: quotation ? quotation.spentAmount : 0,
        quoteAmount: quotation ? quotation.quoteAmount : 0,
        balance: quotation ? quotation.quoteAmount - quotation.spentAmount : 0,
        isTotalQuote: paymentSourceType === PaymentSources.NDIS ? (quotation ? quotation.isManualTotal : false) : true,
        isCategoryQuote: quotation ? !quotation.isManualTotal : false,
        categoryQuote: quotation && !quotation.isManualTotal ? quotation.subQuotes : []
      });
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IAddManualQuoteModalProps>,
    prevState: Readonly<IAddManualQuoteModalState>,
    snapshot?: any
  ) {
    const { quotation, paymentSourceType } = this.props;
    if (prevProps.quotation !== quotation && this.props.isEdit) {
      this.setState({
        spentAmount: quotation ? quotation.spentAmount : 0,
        quoteAmount: quotation ? quotation.quoteAmount : 0,
        balance: quotation ? quotation.quoteAmount - quotation.spentAmount : 0,
        isTotalQuote: paymentSourceType === PaymentSources.NDIS ? (quotation ? quotation.isManualTotal : false) : true,
        isCategoryQuote: quotation ? !quotation.isManualTotal : false,
        categoryQuote: quotation && !quotation.isManualTotal ? quotation.subQuotes : [],
        categoryTotal: quotation && !quotation.isManualTotal ? quotation.quoteAmount : 0,
        categorySpent: quotation && !quotation.isManualTotal ? quotation.spentAmount : 0
      });
    }
  }

  render() {
    const { isOpen, isEdit = false, selectedService, paymentSourceType } = this.props;
    const { title, categoryQuote } = this.state;
    const supportCategories = ndisHelper.getAllCategories();

    return (
      <ActionModal isOpen={isOpen} width={'x2-large'} title={title} canCloseOutside={false} onClose={this._onClose}>
        <div>{paymentSourceType === PaymentSources.NDIS && <SubTitle>Select quote type</SubTitle>}</div>
        <div className="bordered border-standard-gray bg-quaternary ph-large pv-medium">
          {paymentSourceType === PaymentSources.NDIS && (
            <Checkbox
              checked={this.state.isTotalQuote}
              className={this.state.isTotalQuote && 'pv-small bordered-bottom border-standard-gray'}
              onChange={this._handleIsTotalQuote}
              disabled={this.state.isCategoryQuote}
            >
              <Text weight={'bold'}>Total quote figure</Text>
            </Checkbox>
          )}
          {this.state.isTotalQuote && (
            <>
              <Row className={'mt-small'}>
                <Col span={8}>
                  <SubTitle>AMOUNT QUOTED</SubTitle>
                </Col>
                <Col span={8}>
                  <SubTitle>AMOUNT SPENT</SubTitle>
                </Col>
                <Col span={8}>
                  <SubTitle>BALANCE</SubTitle>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Text color={'secondary'}>
                    <NumberInput
                      onChange={this._onChangeQuoteAmount}
                      size={'large'}
                      min={0}
                      max={10000000}
                      value={this.state.quoteAmount}
                      className={'mr-small'}
                      addonBefore={'$'}
                      style={{ width: '200px' }}
                    />
                  </Text>
                  {this.state.isQuoteAmountZeroError && (
                    <div>
                      <Text color="red-dark">Value must be greater than $0.00.</Text>
                    </div>
                  )}
                </Col>
                <Col span={8}>
                  <Text color={'secondary'}>
                    <NumberInput
                      onChange={this._onChangeSpentAmount}
                      size={'large'}
                      min={0}
                      max={10000000}
                      value={this.state.spentAmount}
                      className={'mr-small'}
                      addonBefore={'$'}
                      style={{ width: '200px' }}
                    />
                  </Text>
                </Col>
                <Col span={8}>
                  <div className="mt-x-small">
                    <Text color={'secondary'} size={'x-large'}>
                      {CommonUtils.formatPrice(this.state.balance)}
                    </Text>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </div>
        {paymentSourceType === PaymentSources.NDIS && (
          <div className="mt-medium bordered border-standard-gray bg-quaternary ph-large pv-medium">
            <Checkbox
              checked={this.state.isCategoryQuote}
              onChange={this._handleIsCategoryQuote}
              className={this.state.isCategoryQuote && 'pv-small bordered-bottom border-standard-gray'}
              disabled={this.state.isTotalQuote}
            >
              <Text weight={'bold'}>Quote by support category</Text>
            </Checkbox>
            {this.state.isCategoryQuote && (
              <>
                <Row className={'mt-medium'}>
                  <HyperlinkButton onClick={this._onAddQuoteCategory}>Add Category</HyperlinkButton>
                </Row>
                {_.map(this.state.categoryQuote, (category, index) => {
                  return (
                    <CategoryQuotationRow
                      key={index}
                      index={index}
                      category={category}
                      onChangeCategoryData={this._onChangeCategory}
                      supportCategories={supportCategories}
                      onDeleteCategory={this._onDeleteCategory}
                      showDeleteButton={categoryQuote.length > 1}
                    />
                  );
                })}

                <Row gutter={8} className={'pv-medium bordered mt-medium bg-secondary rounded-big'}>
                  <Col span={8} className={'pl-medium'}>
                    <SubTitle>TOTAL</SubTitle>
                  </Col>
                  <Col span={5}>
                    <Text color={'secondary'} size={'x-large'}>
                      {CommonUtils.formatPrice(this.state.categoryTotal)}
                    </Text>
                  </Col>
                  <Col span={5}>
                    <Text color={'secondary'} size={'x-large'}>
                      {CommonUtils.formatPrice(this.state.categorySpent)}
                    </Text>
                  </Col>
                  <Col span={4}>
                    <Text color={'secondary'} size={'x-large'}>
                      {CommonUtils.formatPrice(Number(this.state.categoryTotal - this.state.categorySpent))}
                    </Text>
                  </Col>
                </Row>
              </>
            )}
          </div>
        )}

        <ActionModalFooter>
          <SecondaryButton className="mr-medium" size="large" onClick={this._onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={this._onSave} disabled={!selectedService}>
            Save
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

interface ICategoryQuotationRowProps {
  onChangeCategoryData: (value, index) => void;
  onDeleteCategory: (index) => void;
  category: ICategoryQuote;
  supportCategories: any[];
  index: number;
  showDeleteButton: boolean;
}

class CategoryQuotationRow extends Component<ICategoryQuotationRowProps, any> {
  state = {
    supportCategoryNumber: null,
    quoteAmount: 0,
    spentAmount: 0,
    isQuoteAmountZeroError: false
  };
  private _onChangeCategoryNumber = (value) => {
    const { onChangeCategoryData, index } = this.props;
    onChangeCategoryData(
      {
        supportCategoryNumber: Number(value),
        quoteAmount: this.state.quoteAmount,
        spentAmount: this.state.spentAmount
      },
      index
    );
    this.setState({ supportCategoryNumber: value });
  };

  private _onChangeQuoteAmount = (value) => {
    const { onChangeCategoryData, index } = this.props;
    onChangeCategoryData(
      {
        supportCategoryNumber: this.state.supportCategoryNumber,
        quoteAmount: value,
        spentAmount: this.state.spentAmount
      },
      index
    );
    this.setState({ quoteAmount: value });
  };

  private _onChangeSpentAmount = (value) => {
    const { onChangeCategoryData, index } = this.props;
    onChangeCategoryData(
      {
        supportCategoryNumber: this.state.supportCategoryNumber,
        quoteAmount: this.state.quoteAmount,
        spentAmount: value
      },
      index
    );
    this.setState({ spentAmount: value });
  };

  private _onDeleteQuote = () => {
    const { index, onDeleteCategory } = this.props;
    onDeleteCategory(index);
  };

  componentDidMount() {
    const { category } = this.props;

    this.setState({
      supportCategoryNumber: category.supportCategoryNumber,
      quoteAmount: category.quoteAmount,
      spentAmount: category.spentAmount
    });
  }

  render() {
    const { category, supportCategories, showDeleteButton } = this.props;
    const { isQuoteAmountZeroError, isEmptyCategoryError } = category;
    const isError = isQuoteAmountZeroError || isEmptyCategoryError;

    return (
      <div className={'mv-medium'}>
        <Row gutter={8}>
          <Col span={8}>
            <Text color={'secondary'} size={'small'}>
              SELECT A CATEGORY
            </Text>
          </Col>
          <Col span={5}>
            <Text color={'secondary'} size={'small'}>
              AMOUNT QUOTED
            </Text>
          </Col>
          <Col span={5}>
            <Text color={'secondary'} size={'small'}>
              AMOUNT SPENT
            </Text>
          </Col>
          <Col span={4}>
            <Text color={'secondary'} size={'small'}>
              BALANCE
            </Text>
          </Col>
          <Col span={2} />
        </Row>
        <Row gutter={8} type={'flex'} align={'middle'}>
          <Col span={8}>
            <Select
              placeholder="Select..."
              size={'large'}
              value={category.supportCategoryNumber}
              className={isEmptyCategoryError ? 'width-full bordered border-red-dark rounded' : 'width-full'}
              onChange={this._onChangeCategoryNumber}
            >
              {_.map(supportCategories, (category) => {
                return <Select.Option value={category.supportCategoryNumber}>{category.supportCategory}</Select.Option>;
              })}
            </Select>
          </Col>
          <Col span={5}>
            <Text color={'secondary'}>
              <NumberInput
                onChange={this._onChangeQuoteAmount}
                value={this.state.quoteAmount}
                size={'large'}
                min={0}
                max={10000000}
                className={isQuoteAmountZeroError ? 'mr-small bordered border-red-dark rounded' : 'mr-small'}
                addonBefore={'$'}
                style={{ width: '150px' }}
              />
            </Text>
          </Col>
          <Col span={5}>
            <Text color={'secondary'}>
              <NumberInput
                onChange={this._onChangeSpentAmount}
                value={this.state.spentAmount}
                size={'large'}
                min={0}
                max={10000000}
                className={'mr-small'}
                addonBefore={'$'}
                style={{ width: '150px' }}
              />
            </Text>
          </Col>
          <Col span={4}>
            <div className="mt-x-small">
              <Text color={'secondary'} size={'x-large'}>
                {CommonUtils.formatPrice(this.state.quoteAmount - this.state.spentAmount)}
              </Text>
            </div>
          </Col>
          <Col span={2} className={'text-align-right'}>
            {showDeleteButton && <Icon type={'close'} onClick={this._onDeleteQuote} />}
          </Col>
        </Row>
        {isError && (
          <Row gutter={8}>
            <Col span={8}>
              {category.isEmptyCategoryError && (
                <div>
                  <Text color="red-dark">Category cannot be empty.</Text>
                </div>
              )}
            </Col>
            <Col span={8}>
              {category.isQuoteAmountZeroError && (
                <div>
                  <Text color="red-dark">Value must be greater than $0.00.</Text>
                </div>
              )}
            </Col>
            <Col span={8}></Col>
          </Row>
        )}
      </div>
    );
  }
}

export default AddManualQuoteModal;
