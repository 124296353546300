import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { ErrorSVG } from 'assets/UndrawSVG';
import { Paragraph, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Spinner } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { Avatar, Icon, Radio, Row } from 'antd';
import { BookingType, EditRecurringMode } from 'utilities/enum-utils';

interface IBookingRemoveWorkerActionModalProps {
  isOpen: boolean;
  onClose: (doRefreshData?: boolean) => void;
  selectedBooking: any;
  doRemoveWorker: typeof dispatch.bookingsStore.doRemoveWorker;
  bookingType: string;
}

class BookingRemoveWorkerActionModal extends Component<IBookingRemoveWorkerActionModalProps, any> {
  state = {
    step: this.props.bookingType === BookingType.BOOKING ? 1 : 6,
    canManuallyClose: true,
    title: this.props.bookingType === BookingType.BOOKING ? 'Remove Worker from booking' : 'Remove team member',
    workerName: '',
    selectedOption: EditRecurringMode.Current
  };

  renderView = () => {
    const { step } = this.state;
    const { selectedBooking } = this.props;
    if (!selectedBooking) {
      return <></>;
    }
    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <div>
            <Paragraph>
              The following confirmed Worker will be removed from this booking and receive a notification explaining
              they have been removed.
            </Paragraph>
          </div>
          <div className="pv-x-large">
            <div className="text-align-center">
              <Text className="text-size-x-large">
                <Icon type="close-circle" className="mr-small text-color-red" />
                Remove from booking
              </Text>
              <div className="mt-medium">
                <Avatar
                  icon="user"
                  size={120}
                  className="mb-small"
                  shape="square"
                  src={selectedBooking.workerAttachmentUrl}
                />
                <br />
                <b>
                  <Text size="x-large">
                    {selectedBooking.workerFirstName} {selectedBooking.workerLastName}
                  </Text>
                </b>
              </div>
            </div>
          </div>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" color="red" icon="check" onClick={this._onAccept}>
              Remove Worker
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <div className="text-align-center">
            <div className="pv-large">
              <Spinner size={150} />
            </div>

            <Paragraph>Removing the Worker from this booking, won't be long...</Paragraph>
          </div>
        </div>
      );
    }

    if (step === 3) {
      return (
        <div className="anim-fade-in">
          {selectedBooking.bookingType === BookingType.BOOKING ? (
            <div className="text-align-left">
              <Paragraph>
                {selectedBooking.isRecurring && this.state.selectedOption === EditRecurringMode.AllWorkerCurrentAll
                  ? `You have successfully removed all assigned workers form this booking and all following bookings.`
                  : `You have successfully removed ${this.state.workerName} as the assigned worker for this booking${
                      selectedBooking.isRecurring && this.state.selectedOption === EditRecurringMode.CurrentAll
                        ? ` and all following bookings in this recurring series where ${this.state.workerName} is the assigned worker`
                        : ''
                    }.`}
              </Paragraph>
            </div>
          ) : (
            <div className="text-align-left">
              <Paragraph>You have successfully removed the assigned team member</Paragraph>
            </div>
          )}

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              OK
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 4) {
      return (
        <div className="anim-fade-in">
          <div className="pv-medium">
            <img src={ErrorSVG} alt="ERROR" style={{ width: '100%', height: '200px' }} />
          </div>
          <div className="text-align-center">
            <Paragraph>Oops something has gone wrong, please try again</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Go Back to Booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (step === 5) {
      return (
        <>
          <div className="anim-slide-left">
            <Paragraph>
              The selected booking is part of a recurring booking series. Please select one of the following options for
              editing this booking
            </Paragraph>
          </div>
          <div>
            <div className="mb-small">
              <Text weight="bold">
                Remove {selectedBooking.workerFirstName + ' ' + selectedBooking.workerLastName} as the worker for:
              </Text>
            </div>
            <Radio.Group
              value={this.state.selectedOption}
              onChange={this._onChangeOption}
              className="ml-medium flex-wrap"
            >
              <Radio
                value={EditRecurringMode.Current}
                className={`${this.state.selectedOption === EditRecurringMode.Current && 'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  This booking only.
                </div>
              </Radio>
              <br />
              <Radio
                value={EditRecurringMode.CurrentAll}
                className={`${this.state.selectedOption === EditRecurringMode.CurrentAll &&
                  'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  This booking and all following bookings where{' '}
                  {selectedBooking.workerFirstName + ' ' + selectedBooking.workerLastName} is the assigned worker.
                </div>
              </Radio>
              <Radio
                value={EditRecurringMode.AllWorkerCurrentAll}
                className={`${this.state.selectedOption === EditRecurringMode.CurrentNext &&
                  'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  This booking and remove all other assigned workers from following bookings.
                </div>
              </Radio>
            </Radio.Group>
          </div>
          <div className={'mt-large'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._doRemoveWorker}>
                Continue
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (step === 6) {
      return (
        <div className="anim-fade-in">
          <div>
            <Paragraph>Are you sure you want to remove the assigned team member</Paragraph>
          </div>

          <ActionModalFooter>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onAccept}>
                Remove
              </PrimaryButton>
            </Row>
          </ActionModalFooter>
        </div>
      );
    }
  };

  private _doRemoveWorker = async () => {
    const { doRemoveWorker, selectedBooking } = this.props;
    const { selectedOption } = this.state;

    if (selectedBooking.isRecurring) {
      // prepare the payload depending on the selected Option
    }
    this.setState({ step: 2, canManuallyClose: false });
    try {
      await doRemoveWorker({
        bookingId: selectedBooking.bookingId,
        supportWorkerId: selectedBooking.workerId ? selectedBooking.workerId : selectedBooking.supportWorkerId,
        bookingRequestId: selectedBooking.bookingRequestId,
        isRecurring: selectedBooking.isRecurring,
        editRecurringMode: selectedOption,
        bookingType: selectedBooking.bookingType
      });
      this._onComplete();
      this.setState({ workerName: `${selectedBooking.workerFirstName} ${selectedBooking.workerLastName}` });
    } catch (e) {
      this._onError();
    }
  };

  private _onAccept = async () => {
    if (this.props.selectedBooking.isRecurring) {
      this.setState({ step: 5 });
    } else {
      await this._doRemoveWorker();
    }
  };

  private _onComplete = () => {
    this.setState({ step: 3, canManuallyClose: true, title: 'Worker successfully removed' });
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _onError = () => {
    this.setState({ step: 4, canManuallyClose: true });
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    // Reset back to step 1
    const doRefreshData = this.state.step === 3;
    this.props.bookingType === BookingType.BOOKING
      ? this.setState({ step: 1, title: 'Remove Worker from booking' }, () => onClose(doRefreshData))
      : this.setState({ step: 6, title: 'Remove team member' }, () => onClose(doRefreshData));
  };

  render() {
    let { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title={this.state.title}
        width="medium"
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this.renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBooking: state.bookingsStore.selectedBookingItem
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doRemoveWorker: dispatch.bookingsStore.doRemoveWorker
});

export default connect(
  mapState,
  mapDispatch
)(BookingRemoveWorkerActionModal);
