import { IPermissions, IServiceDepartment } from 'interfaces/account-interfaces';
import { Col, Row } from 'antd';
import { Text, Title } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import React from 'react';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import PermissionsControlPanel from 'views/account-management/add-new-member/panels/permissions-panel/portal/PermissionsControlPanel';
import _ from 'lodash';
import { IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import PermissionUtils from 'utilities/permission-utils';
import { Warning } from 'common-components/alerts';
import { UserPermissionRole } from 'utilities/enum-utils';

interface IEditPermissionsPanelProps {
  editPermissionsData: { permissions: IPermissions };
  setEditPermissionsData: any;
  serviceDepartments: IServiceDepartment[];
  savePermissions: () => void;
  onClose: () => void;
  isLoading: boolean;
  isSaving: boolean;
  portalUser: typeof state.authStore.portalUser;
}

class EditPermissionsPanel extends React.Component<IEditPermissionsPanelProps> {
  onChangePermissions = (permissions) => {
    const { editPermissionsData, setEditPermissionsData } = this.props;
    const updatedData = { ...editPermissionsData, permissions };
    setEditPermissionsData(updatedData);
  };

  render() {
    const { editPermissionsData, serviceDepartments, portalUser } = this.props;
    const { permissions } = editPermissionsData;

    const isPrimaryOwner = !!_.find(
      portalUser.permissions.permissionRoles,
      (role) => role.permissionRole === UserPermissionRole.PrimaryOwner
    );
    const isOwner = !!_.find(
      portalUser.permissions.permissionRoles,
      (role) => role.permissionRole === UserPermissionRole.Owner
    );
    const isTargetUserPrimaryOwner = !!_.find(
      permissions.permissionRoles,
      (role) => role.permissionRole === UserPermissionRole.PrimaryOwner
    );
    const isTargetUserOwner = !!_.find(
      permissions.permissionRoles,
      (role) => role.permissionRole === UserPermissionRole.Owner
    );

    const isNoPermissionError = permissions && !permissions.hasPortalAccess && !permissions.hasWorkerAppAccess;

    const hasEditPermission = PermissionUtils.validatePermission(
      'EditTeamMemberPermissions',
      portalUser.permissions.permissionRoles
    );

    return (
      <div>
        <div className="anim-slide-left">
          <Row className="ph-x2-large">
            {/* Left Column */}
            <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
              <div style={{ maxWidth: '80%', minHeight: 'calc(100vh - 208px)' }}>
                {/* Left Title */}
                <Title level={3} weight="regular">
                  Permissions
                </Title>

                {/* Left Description */}
                <Text size="large">
                  Configure what this person can do in your business. <br />
                  <br />
                  {isNoPermissionError ? (
                    <Warning content={'Assign at least one permission to continue creating this team member.'} />
                  ) : (
                    <Text size="large">Assign at least one permission to continue creating this team member.</Text>
                  )}
                  {/*<br />*/}
                  {/*<br /> Visit the <HyperlinkButton>Help Center</HyperlinkButton> to learn more.*/}
                </Text>
                <br />
              </div>
              {/* Left Button */}
              <div
                className="pv-x2-large width-full text-align-left"
                style={{ backgroundColor: 'rgba(248,249,250,0.7)', position: 'sticky', bottom: 0 }}
              >
                <PrimaryButton size="large" onClick={this.props.onClose}>
                  Close
                </PrimaryButton>
              </div>
            </Col>

            {/* Right Column */}
            <Col span={18} style={{ minHeight: 'calc(100vh - 88px)', maxWidth: '820px' }} className="pl-large">
              <div style={{ minHeight: 'calc(100vh - 208px)' }}>
                {/* <-- ! Right Content Starts here ! --> */}
                <div className="bg-white p-large bordered border-tertiary rounded">
                  {/*  White Panel stuff*/}
                  {this.props.isLoading ? (
                    <SpinningLoader size={100} message={'Fetching permissions...'} />
                  ) : (
                    <PermissionsControlPanel
                      permissions={permissions}
                      serviceDepartments={serviceDepartments}
                      onChangePermissions={this.onChangePermissions}
                      hasEditPermission={hasEditPermission}
                    />
                  )}
                </div>
              </div>
              {/* Right Button */}
              {!(isTargetUserPrimaryOwner || (isTargetUserOwner && !isPrimaryOwner && !isOwner)) && hasEditPermission && (
                <div
                  className="pv-x2-large width-full text-align-right"
                  style={{ backgroundColor: 'rgba(248,249,250,0.7)', position: 'sticky', bottom: 0 }}
                >
                  <PrimaryButton
                    size="large"
                    disabled={isNoPermissionError}
                    loading={this.props.isSaving}
                    onClick={this.props.savePermissions}
                  >
                    Save permissions
                  </PrimaryButton>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser
});

export default connect(
  mapState,
  null
)(EditPermissionsPanel);
