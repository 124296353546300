import React, { Component } from 'react';
import { BookingErrorType } from 'utilities/enum-utils';
import _ from 'lodash';
import { Icon } from 'antd';
import { Popover } from '@blueprintjs/core';
import { Text } from 'common-components/typography';

interface IBookingErrorIndicatorProps {
  bookingErrors: { bookingErrorType: BookingErrorType; number?: number }[];
  usePortal?: boolean;
}

class BookingErrorIndicator extends Component<IBookingErrorIndicatorProps> {
  private _renderIcon = () => {
    const { bookingErrors } = this.props;

    if (bookingErrors.length === 1) {
      const type = bookingErrors[0].bookingErrorType;

      switch (type) {
        case BookingErrorType.PaymentMethodError:
          return (
            <Icon
              type="minus-circle"
              theme="filled"
              className="text-color-red-light mr-x-small"
              style={{ fontSize: 20 }}
            />
          );
        case BookingErrorType.BookingRecordedTimeWarning:
        case BookingErrorType.BookingActualTimeWarning:
        case BookingErrorType.FundingPackageWarning:
        case BookingErrorType.ServiceAgreementWarning:
        case BookingErrorType.ServiceAgreementLineItemWarning:
          return (
            <Icon
              type="warning"
              theme="filled"
              className="text-color-orange-lighter mr-x-small"
              style={{ fontSize: 20 }}
            />
          );
      }
    } else {
      const isError = _.find(bookingErrors, (error) => error.bookingErrorType === BookingErrorType.PaymentMethodError);
      if (isError) {
        return (
          <Icon
            type="minus-circle"
            theme="filled"
            className="text-color-red-light mr-x-small"
            style={{ fontSize: 20 }}
          />
        );
      } else {
        return (
          <Icon
            type="warning"
            theme="filled"
            className="text-color-orange-lighter mr-x-small"
            style={{ fontSize: 20 }}
          />
        );
      }
    }
  };

  private _getPopoverContent = () => {
    const { bookingErrors } = this.props;

    if (bookingErrors.length > 1) {
      const isError = _.find(bookingErrors, (error) => error.bookingErrorType === BookingErrorType.PaymentMethodError);
      if (isError) {
        return (
          <div className="ph-medium pv-medium" style={{ width: '290px' }}>
            <div className="text-color-red-light">
              <Icon
                type="minus-circle"
                theme="filled"
                className="text-color-red-light mr-x-small"
                style={{ fontSize: 20 }}
              />{' '}
              Error
            </div>
            <Text className="mt-medium">Multiple errors/warnings on booking</Text>
          </div>
        );
      } else {
        return (
          <div className="ph-medium pv-medium" style={{ width: '290px' }}>
            <div className="text-color-orange-lighter">
              <Icon
                type="warning"
                theme="filled"
                className="text-color-orange-lighter mr-x-small"
                style={{ fontSize: 20 }}
              />{' '}
              Warning
            </div>
            <Text className="mt-medium">Multiple warnings on booking</Text>
          </div>
        );
      }
    } else {
      const type = bookingErrors[0].bookingErrorType;

      switch (type) {
        case BookingErrorType.PaymentMethodError:
          return (
            <div className="ph-medium pv-medium" style={{ width: '290px' }}>
              <div className="text-color-red-light">
                <Icon
                  type="minus-circle"
                  theme="filled"
                  className="text-color-red-light mr-x-small"
                  style={{ fontSize: 20 }}
                />{' '}
                Error
              </div>
              <Text className="mt-medium">
                1 or more line items in this booking does not have a <b>specified management method</b>
              </Text>
            </div>
          );
        case BookingErrorType.BookingRecordedTimeWarning:
          return (
            <div className="ph-medium pv-medium" style={{ width: '290px' }}>
              <div className="text-color-orange-lighter">
                <Icon
                  type="warning"
                  theme="filled"
                  className="text-color-orange-lighter mr-x-small"
                  style={{ fontSize: 20 }}
                />{' '}
                Warning
              </div>
              <Text className="mt-medium">
                The worker's recorded start and finish time are different than the scheduled time of the booking
              </Text>
            </div>
          );
        case BookingErrorType.BookingActualTimeWarning:
          return (
            <div className="ph-medium pv-medium" style={{ width: '290px' }}>
              <div className="text-color-orange-lighter">
                <Icon
                  type="warning"
                  theme="filled"
                  className="text-color-orange-lighter mr-x-small"
                  style={{ fontSize: 20 }}
                />{' '}
                Warning
              </div>
              <Text className="mt-medium">
                The worker's actual start and finish time are different than the scheduled time of the booking
              </Text>
            </div>
          );
        case BookingErrorType.FundingPackageWarning:
          return (
            <div className="ph-medium pv-medium" style={{ width: '290px' }}>
              <div className="text-color-orange-lighter">
                <Icon
                  type="warning"
                  theme="filled"
                  className="text-color-orange-lighter mr-x-small"
                  style={{ fontSize: 20 }}
                />{' '}
                Warning
              </div>
              <Text className="mt-medium">
                {bookingErrors[0].number} line items {bookingErrors[0].number > 1 ? 'are' : 'is'} not part of the
                customers funding package for the dates of this booking
              </Text>
            </div>
          );
        case BookingErrorType.ServiceAgreementWarning:
          return (
            <div className="ph-medium pv-medium" style={{ width: '290px' }}>
              <div className="text-color-orange-lighter">
                <Icon
                  type="warning"
                  theme="filled"
                  className="text-color-orange-lighter mr-x-small"
                  style={{ fontSize: 20 }}
                />{' '}
                Warning
              </div>
              <Text className="mt-medium">
                This service is not part of the customers service agreements for the dates of the booking
              </Text>
            </div>
          );
        case BookingErrorType.ServiceAgreementLineItemWarning:
          return (
            <div className="ph-medium pv-medium" style={{ width: '290px' }}>
              <div className="text-color-orange-lighter">
                <Icon
                  type="warning"
                  theme="filled"
                  className="text-color-orange-lighter mr-x-small"
                  style={{ fontSize: 20 }}
                />{' '}
                Warning
              </div>
              <Text className="mt-medium">
                Booking has line items that are not part of the customers service agreement
              </Text>
            </div>
          );
      }
    }
  };

  render() {
    const { bookingErrors, usePortal = true } = this.props;

    if (_.isEmpty(bookingErrors)) {
      return <></>;
    } else {
      return (
        <Popover
          content={this._getPopoverContent()}
          position={'bottom-right'}
          interactionKind="hover"
          usePortal={usePortal}
        >
          {this._renderIcon()}
        </Popover>
      );
    }
  }
}

export default BookingErrorIndicator;
