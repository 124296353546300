import React, { PureComponent } from 'react';
import { Typography } from 'antd';
import { TextProps } from 'antd/lib/typography/Text';

import { isEmpty } from 'lodash';
import { BlockProps } from 'antd/lib/typography/Base';
import { Omit } from 'antd/lib/_util/type';

import {
  PORTAL_COLORS,
  PORTAL_COMMON_SIZES,
  PORTAL_FONT_FAMILIES,
  PORTAL_FONT_WEIGHTS,
  PORTAL_LINE_HEIGHTS
} from '../../interfaces/common-ui-types';

import SubTitle from './SubTitle';
import FieldLabel from './FieldLabel';
import FieldValueText from './FieldValueText';

const { Text: BaseText, Title: BaseTitle, Paragraph: BaseParagraph } = Typography;

//region Extended Text Component
// Extended Text to have weight
interface ExtendedTextProps extends TextProps {
  weight?: PORTAL_FONT_WEIGHTS;
  size?: PORTAL_COMMON_SIZES;
  color?: PORTAL_COLORS;
  font?: PORTAL_FONT_FAMILIES;
  lineHeight?: PORTAL_LINE_HEIGHTS;
  onClick?: any; // hack to make this component have a onClick event
}

class Text extends PureComponent<ExtendedTextProps> {
  render() {
    // Defaults - size: large, font: Lato
    const {
      weight,
      color,
      size = 'large',
      className = '',
      children,
      font = '',
      lineHeight = 135, // changed to 135 instead.
      ...otherProps
    } = this.props;

    const targetWeight = !isEmpty(weight) ? `text-weight-${weight}` : '';
    const targetTextColor = !isEmpty(color) ? `text-color-${color}` : '';
    const targetSize = !isEmpty(size) ? `text-size-${size}` : '';
    const targetLineHeight = `line-height-${lineHeight}`;

    const finalClassName = `${targetWeight} ${targetTextColor} ${targetSize} ${targetLineHeight} ${font} ${className} `;

    return (
      <BaseText className={finalClassName} {...otherProps}>
        {children}
      </BaseText>
    );
  }
}
//endregion

//region Extended Title Components

// TitleProps is not exported from antd definitions...so interface is ripped to put here.
// Replace or revise this if necessary later.
declare const TITLE_ELE_LIST: [1, 2, 3, 4];
declare type BaseTitleProps = Omit<BlockProps & { level?: (typeof TITLE_ELE_LIST)[number] }, 'strong'>;

interface ExtendedTitleProps extends BaseTitleProps {
  weight?: PORTAL_FONT_WEIGHTS;
  size?: PORTAL_COMMON_SIZES;
  color?: PORTAL_COLORS;
  font?: PORTAL_FONT_FAMILIES;
  lineHeight?: PORTAL_LINE_HEIGHTS;
  onClick?: any; // hack to make this component have a onClick event
}

class Title extends PureComponent<ExtendedTitleProps> {
  render() {
    // Defaults - font: Poppins, weight: bold
    const {
      weight = 'bold',
      color,
      size,
      className = '',
      lineHeight = 135, // changed to 135 instead.
      children,
      font = '',
      ...otherProps
    } = this.props;

    const targetWeight = !isEmpty(weight) ? `text-weight-${weight}` : '';
    const targetTextColor = !isEmpty(color) ? `text-color-${color}` : '';
    const targetSize = !isEmpty(size) ? `text-size-${size}` : '';
    const targetLineHeight = `line-height-${lineHeight}`;

    const finalClassName = `${targetWeight} ${targetTextColor} ${targetSize} ${targetLineHeight} ${font} ${className} `;

    return (
      <BaseTitle className={finalClassName} {...otherProps}>
        {children}
      </BaseTitle>
    );
  }
}
//endregion

//region Extended Paragraph Component
interface ExtendedParagraphProps extends BlockProps {
  weight?: PORTAL_FONT_WEIGHTS;
  size?: PORTAL_COMMON_SIZES;
  color?: PORTAL_COLORS;
  font?: PORTAL_FONT_FAMILIES;
  lineHeight?: PORTAL_LINE_HEIGHTS;
  onClick?: any;
}

class Paragraph extends PureComponent<ExtendedParagraphProps> {
  render() {
    const { weight, color, size, className = '', children, font = '', lineHeight = 135, ...otherProps } = this.props;

    const targetWeight = !isEmpty(weight) ? `text-weight-${weight}` : '';
    const targetTextColor = !isEmpty(color) ? `text-color-${color}` : '';
    const targetSize = !isEmpty(size) ? `text-size-${size}` : '';
    const targetLineHeight = `line-height-${lineHeight}`;

    const finalClassName = `${targetWeight} ${targetTextColor} ${targetLineHeight} ${targetSize} ${font} ${className} `;

    return (
      <BaseParagraph className={finalClassName} {...otherProps}>
        {children}
      </BaseParagraph>
    );
  }
}
//endregion

export { Text, Title, Paragraph, SubTitle, FieldLabel, FieldValueText };
