import CommonUtils from 'utilities/common-utils';
import { Text } from 'common-components/typography';
import { Popover } from '@blueprintjs/core';
import { Icon } from 'antd';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { IconButton } from 'common-components/buttons';
import _ from 'lodash';
import React from 'react';
import { PaymentMethodTag } from 'common-components/tags';
import FundingUtils from 'utilities/funding-utils';

const WarningIcon = () => (
  <Icon
    type="warning"
    theme="filled"
    className="text-color-warning-orange mr-x-small cursor-pointer"
    style={{ fontSize: 20 }}
  />
);
const ErrorIcon = () => (
  <Icon
    type="minus-circle"
    theme="filled"
    className="text-color-red-light mr-x-small cursor-pointer"
    style={{ fontSize: 20 }}
  />
);

function getNdisUnitLabel(unit) {
  return unit === 'H' ? 'hr.' : 'EA' ? 'each' : '';
}

function getWarningIcon(warningType) {
  if (warningType === 'error') {
    return <ErrorIcon />;
  } else if (warningType === 'warning') {
    return <WarningIcon />;
  } else {
    return <></>;
  }
}

export function LineItemRow({
  lineItem,
  onEditLineItem,
  onDeleteLineItem,
  fundedCategories,
  canAddEditLineItem,
  tabType
}: {
  lineItem: any;
  onEditLineItem: any;
  onDeleteLineItem: any;
  fundedCategories: any;
  canAddEditLineItem: boolean;
  tabType: string;
}) {
  const {
    supportItemNumber,
    claimType,
    supportItemName,
    qty,
    unit,
    unitPrice,
    total,
    paymentMethod,
    teamMemberCustomerRatio
  } = lineItem;

  const allClaimTypes = {
    DIRECT_SERVICE: 'STD',
    CANCELLATION: 'CANC',
    TRAVEL: 'TRAN',
    REPORT_WRITING: 'REPW',
    NON_FACE_TO_FACE: 'NF2F',
    TELEHEALTH_SUPPORTS: 'THLT',
    IRREGULAR_SIL_SUPPORTS: 'IRSS'
  };

  const claimTypeOptions = [
    { value: allClaimTypes.DIRECT_SERVICE, label: 'Direct Service' },
    { value: allClaimTypes.CANCELLATION, label: 'Cancellation' },
    { value: allClaimTypes.TRAVEL, label: 'Travel' },
    { value: allClaimTypes.REPORT_WRITING, label: 'Report Writing' },
    { value: allClaimTypes.NON_FACE_TO_FACE, label: 'Non Face-to-face' },
    { value: allClaimTypes.TELEHEALTH_SUPPORTS, label: 'Telehealth supports' },
    { value: allClaimTypes.IRREGULAR_SIL_SUPPORTS, label: 'Irregular SIL supports' }
  ];

  const costLabel = `${CommonUtils.formatPrice(unitPrice)} x ${qty} ${getNdisUnitLabel(unit)}`;

  const totalLabel = CommonUtils.formatPrice(total);

  const isFunded = !_.isEmpty(
    FundingUtils.isLineItemExistInFundingPackage(
      lineItem.supportType,
      lineItem.supportCategoryNumber,
      lineItem.supportItemNumber,
      fundedCategories
    )
  );

  return (
    <tr className="evenodd">
      {/* Support item */}
      <td className="p-medium line-height-100">
        <Text size="regular" color="secondary" lineHeight={120}>
          {supportItemNumber}
        </Text>
        <br />
        <Text lineHeight={120}>{supportItemName}</Text>
      </td>

      {/* Claim type */}
      <td className="p-medium line-height-135">
        {_.find(claimTypeOptions, (item) => item.value === claimType)
          ? _.find(claimTypeOptions, (item) => item.value === claimType).label
          : 'Direct Service'}
        {paymentMethod && (
          <>
            <br />
            <Text lineHeight={135}>
              <PaymentMethodTag paymentMethod={paymentMethod} />
            </Text>
          </>
        )}
      </td>

      {/* Cost ƒ*/}
      <td className="p-medium line-height-135">
        <Text lineHeight={135}>{costLabel}</Text> <br />
        <Text size="regular" lineHeight={135}>
          ({teamMemberCustomerRatio ? teamMemberCustomerRatio : '1:1'} ratio)
        </Text>
        <br />
      </td>

      <td className="pv-medium ph-x-small">
        {!paymentMethod ? (
          <Popover
            content={
              <div className="ph-medium pv-medium" style={{ width: '350px' }}>
                <div className="text-color-red-light flex-row align-center mb-x-small">
                  <Icon
                    type="warning"
                    theme="filled"
                    className="text-color-red-light mr-x-small"
                    style={{ fontSize: 20 }}
                  />{' '}
                  Error
                </div>
                <Text className="mt-medium">
                  Line item is missing a <b>management method</b>
                </Text>
              </div>
            }
            position={'bottom-right'}
            usePortal={false}
            interactionKind="hover"
          >
            {getWarningIcon('error')}
          </Popover>
        ) : !isFunded ? (
          <Popover
            content={
              <div className="ph-medium pv-medium" style={{ width: '350px' }}>
                <div className="text-color-warning-orange flex-row align-center mb-x-small">
                  <Icon
                    type="warning"
                    theme="filled"
                    className="text-color-warning-orange mr-x-small"
                    style={{ fontSize: 20 }}
                  />{' '}
                  Warning
                </div>
                <Text className="mt-medium">
                  Line item is not part of the <b>active funding package</b> for the dates of the booking
                </Text>
              </div>
            }
            position={'bottom-right'}
            usePortal={false}
            interactionKind="hover"
          >
            {getWarningIcon('warning')}
          </Popover>
        ) : (
          <></>
        )}
      </td>

      {/* Total */}
      <td className="p-medium text-align-right">{totalLabel}</td>

      {/* Action icons */}
      <td className="p-medium" style={{ width: '64px' }}>
        {canAddEditLineItem && (
          <Popover
            content={
              <ActionMenu>
                <ActionMenuItem text="Edit line item" onClick={() => onEditLineItem(lineItem, lineItem.attendanceId)} />
                {supportItemNumber !== '04_599_0136_6_1' &&
                  !(tabType !== 'session' && lineItem.claimType === 'TRAN') && (
                    <ActionMenuItem
                      text="Delete line item"
                      className="text-color-red"
                      onClick={() => onDeleteLineItem(lineItem, lineItem.attendanceId)}
                    />
                  )}
              </ActionMenu>
            }
            position={'bottom-right'}
            usePortal={false}
          >
            <IconButton
              icon={'ellipsis'}
              size="default"
              iconColor={'blue-action'}
              color={'white'}
              bordered={true}
              className="border-standard-gray"
            />
          </Popover>
        )}
      </td>
    </tr>
  );
}
