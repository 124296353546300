import React, { useMemo, useState } from 'react';

import _ from 'lodash';
import { Collapse } from '@blueprintjs/core';

import { HeaderRow } from 'common-components/roster-control/roster-weekly/common/HeaderRow';
import { GroupColumn } from 'common-components/roster-control/roster-weekly/common/GroupColumn';
import { RevenueColumn } from 'common-components/roster-control/roster-weekly/customers/RevenueColumn';
import { HoursWorkedColumn } from 'common-components/roster-control/roster-weekly/workers/HoursWorkedColumn';

import {
  getEntireWeekForDay,
  uniqueCustomers,
  uniqueWorkers
} from 'common-components/roster-control/common/roster-utilities';

import { CustomerItemRow } from 'common-components/roster-control/roster-weekly/customers/CustomerItemRow';
import { WorkerItemRow } from 'common-components/roster-control/roster-weekly/workers/WorkerItemRow';
import { OpenSlotsRow } from 'common-components/roster-control/roster-weekly/workers/OpenSlotsRow';

import { ShiftSlotStatus } from 'utilities/enum-utils';

import {
  IRosterBooking,
  IRosterCustomer,
  IRosterShift,
  IRosterWorker
} from 'common-components/roster-control/interfaces/roster-interfaces';

import { EmptyWeeklyRows } from 'common-components/roster-control/roster-weekly/common/EmptyWeeklyRows';

import * as H from 'history';

/*
  Some notes about the display mode properties :
  
   DisplayMode :
   • Dynamic - list of customers/workers will be derived from the bookings/shifts (unique customers/workers).
   • Fixed   - list of customers/workers will be displayed based on the list of customers/workers passed in as props.

 */
interface RosterWeeklyProps {
  bookings: IRosterBooking[];
  shifts: IRosterShift[];

  selectedWeek?: [Date, Date];

  showOpenSlots?: boolean;
  showCustomers?: boolean;
  showWorkers?: boolean;

  customersList?: IRosterCustomer[];
  customersDisplayMode?: 'dynamic' | 'fixed';

  workersList?: IRosterWorker[];
  workersDisplayMode?: 'dynamic' | 'fixed';

  onClickHeaderDay?: any;

  isFetching?: boolean;

  refreshData?: () => void;

  history: H.History;
}

function RosterWeekly({
  bookings,
  shifts,

  selectedWeek = [new Date(), new Date()],

  showOpenSlots = true,
  showCustomers = true,
  showWorkers = true,

  customersList,
  customersDisplayMode = 'dynamic',

  workersList,
  workersDisplayMode = 'dynamic',

  onClickHeaderDay = () => {},
  refreshData = () => {},

  isFetching = false,

  history
}: RosterWeeklyProps) {
  const [isCustomersOpen, setCustomersOpen] = useState(true);
  const [isWorkersOpen, setWorkersOpen] = useState(true);

  const mToday = selectedWeek[0];

  const weekdays = getEntireWeekForDay(mToday);

  const openShifts = useMemo(
    () => (showOpenSlots ? _.filter(shifts, (shift) => shift.shiftSlotStatus === ShiftSlotStatus.UNASSIGNED) : []),
    [shifts, showOpenSlots]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const assignedShifts = useMemo(
    () =>
      _.filter(
        shifts,
        (shift) => shift.shiftSlotStatus !== ShiftSlotStatus.UNASSIGNED && !_.isEmpty(shift.supportWorkerId)
      ),
    [shifts]
  );

  const customers = useMemo(() => (customersDisplayMode === 'fixed' ? customersList : uniqueCustomers(bookings)), [
    bookings,
    customersList,
    customersDisplayMode
  ]);

  const workers = useMemo(() => (workersDisplayMode === 'fixed' ? workersList : uniqueWorkers(assignedShifts)), [
    assignedShifts,
    workersList,
    workersDisplayMode
  ]);

  const hasOpenShifts = _.size(openShifts) > 0;
  const hasCustomers = _.size(customers) > 0;
  const hasWorkers = _.size(workers) > 0;

  return (
    <div className="select-none bordered-top bordered-bottom bg-secondary">
      {/* Header row */}
      <HeaderRow days={weekdays} onClickDay={onClickHeaderDay} />

      {/* Customers section */}
      {showCustomers && (
        <div className="flex-column ">
          {/* Customer grouping row */}
          <div className="flex-row">
            {/* (Left) Group column */}
            <GroupColumn isOpen={isCustomersOpen} setOpen={setCustomersOpen} text={'Customers'} />

            {/* (Right) Revenue column. Replace  */}
            <div className="flex-row flex-1">
              {_.map(weekdays, (day, idx) => (
                <RevenueColumn showRevenue={false} revenueAmount={5127.6} day={day} key={idx} />
              ))}
            </div>
          </div>

          {/* Customers section */}
          <Collapse isOpen={isCustomersOpen} keepChildrenMounted={false} className="bg-white">
            {!hasCustomers && <EmptyWeeklyRows type="customer" isFetching={isFetching} />}

            {/* Customer row */}
            {_.map(customers, (customer, idx) => (
              <CustomerItemRow
                customer={customer}
                bookings={bookings}
                refreshData={refreshData}
                days={weekdays}
                key={idx}
                history={history}
              />
            ))}
          </Collapse>
        </div>
      )}

      {/* Workers section */}
      {showWorkers && (
        <div className="flex-column content-visibility-auto">
          <div className="flex-row">
            <GroupColumn isOpen={isWorkersOpen} setOpen={setWorkersOpen} text={'Team members'} />

            <div className="flex-row flex-1">
              {_.map(weekdays, (day, idx) => (
                <HoursWorkedColumn hoursTotal={8} costTotal={135.56} day={day} key={idx} showCost={false} />
              ))}
            </div>
          </div>

          <Collapse isOpen={isWorkersOpen} keepChildrenMounted={false} className="bg-white">
            {/* Open slots */}
            {hasOpenShifts && (
              <OpenSlotsRow openShifts={openShifts} days={weekdays} refreshData={refreshData} history={history} />
            )}

            {!hasWorkers && <EmptyWeeklyRows type="worker" isFetching={isFetching} />}

            {/* Worker rows */}
            {_.map(workers, (worker, idx) => (
              <WorkerItemRow
                worker={worker}
                shifts={assignedShifts}
                days={weekdays}
                refreshData={refreshData}
                key={idx}
                history={history}
              />
            ))}
          </Collapse>
        </div>
      )}
    </div>
  );
}

export default RosterWeekly;
