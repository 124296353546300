import { Moment } from 'moment';
import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from '../../../common-components/buttons/index';
import { FieldLabel, Text } from '../../../common-components/typography/index';
import { Radio, Icon, notification, Select } from 'antd';
import moment from 'moment-timezone';
import NumberInput from 'common-components/inputs/NumberInput';
import TimeInput from 'common-components/time-input/TimeInput';
import { dispatch, IRootDispatch } from '../../../stores/rematch/root-store';
import { ShiftSlotStatus } from '../../../utilities/enum-utils';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import CommonUtils from 'utilities/common-utils';

const { Option } = Select;

interface IActivityRecordEditTimeActionModalProps {
  isOpen: boolean;
  booking: any;
  onClose: () => void;
  doEditActivityRecordHours: typeof dispatch.bookingsStore.doEditActivityRecordHours;
  isSentToTimeSheet: boolean;
}

interface IActivityRecordEditTimeActionModalState {
  title: string;
  startDate: Moment;
  startDateTime: Moment;
  endDateTime: Moment;
  timeSelectOption: string;
  hours: number;
  minutes: number;
  canSave: boolean;
  step: number;
  isLoading: boolean;
}

class ActivityRecordEditTimeActionModal extends Component<
  IActivityRecordEditTimeActionModalProps,
  IActivityRecordEditTimeActionModalState
> {
  state = {
    title: 'Edit Activity Record',
    startDate: moment(),
    startDateTime: moment(),
    endDateTime: moment(),
    timeSelectOption: 'duration',
    hours: 1,
    minutes: 0,
    canSave: false,
    step: 1,
    isLoading: false
  };

  private _setStartDate = (date) => {
    this.setState({ startDate: moment(date) });
    this._onCheckDateAvailable();
  };

  private _onCheckDateAvailable = () => {
    //TODO: to be implement

    this.setState({ canSave: true });
  };

  private _onCloseModal = () => {
    this.setState({
      title: 'Edit Activity Record',
      startDate: moment(),
      startDateTime: moment(),
      endDateTime: moment(),
      timeSelectOption: 'duration',
      hours: 1,
      minutes: 0,
      canSave: false,
      step: 1,
      isLoading: false
    });
    this.props.onClose();
  };

  private _onChangeHours = (hour) => {
    const { minutes, startDate } = this.state;

    const startDateTime = moment(startDate)
      .set('hours', 6)
      .set('minutes', 0)
      .set('seconds', 0)
      .set('ms', 0);

    const endDate = moment(startDateTime)
      .add(hour, 'hours')
      .add(minutes, 'minutes');

    this.setState({ hours: hour, endDateTime: endDate, startDateTime });
  };

  private _onChangeMinutes = (minutes) => {
    const { hours, startDate } = this.state;

    const startDateTime = moment(startDate)
      .set('hours', 6)
      .set('minutes', 0)
      .set('seconds', 0)
      .set('ms', 0);

    const endDate = moment(startDateTime)
      .add(hours, 'hours')
      .add(minutes, 'minutes');

    this.setState({ minutes, endDateTime: endDate, startDateTime });
  };

  private _setStartTime = (date) => {
    const startTime = CommonUtils.formatCeilingDateTime(date);
    if (this.state.endDateTime.isSameOrBefore(moment(startTime))) {
      this.setState({ endDateTime: moment(startTime).add(1, 'hours'), startDateTime: moment(startTime) });
    } else {
      this.setState({ startDateTime: moment(startTime) });
    }
  };

  private _setEndTime = (date) => {
    const endTime = CommonUtils.formatCeilingDateTime(date);
    if (this.state.startDateTime.isSameOrAfter(moment(endTime))) {
      this.setState({ endDateTime: moment(endTime), startDateTime: moment(endTime).add(-1, 'hours') });
    } else {
      this.setState({ endDateTime: moment(endTime) });
    }
  };

  private _onNextStep = async () => {
    const { booking } = this.props;
    if (booking.isDuration) {
      this.setState({ step: 2, title: 'Edit Activity Record' });
    } else {
      await this._onSubmit(false);
    }
  };

  private _onSubmit = async (timeChange = true) => {
    this.setState({ isLoading: true });
    const { booking, isSentToTimeSheet, doEditActivityRecordHours } = this.props;
    const { endDateTime, startDateTime, startDate, timeSelectOption } = this.state;

    const updatedStartDateTime = moment
      .tz(startDate.format('YYYY-MM-DD') + ' ' + startDateTime.format('HH:mm'), booking.timezone)
      .toDate();

    const updatedEndDateTime = moment
      .tz(startDate.format('YYYY-MM-DD') + ' ' + endDateTime.format('HH:mm'), booking.timezone)
      .toDate();

    if (!timeChange) {
      try {
        await doEditActivityRecordHours({
          bookingId: booking.bookingId,
          startDateTime: updatedStartDateTime,
          endDateTime: updatedEndDateTime,
          shiftSlotStatus: ShiftSlotStatus.CHECKED_OUT,
          isDuration: timeSelectOption === 'duration'
        });
        notification.success({
          message: 'Hours added to timesheet',
          description: 'This activity record has been added to the team members timesheet pending record approved.'
        });
        this._onCloseModal();
      } catch (e) {
        notification.error({
          message: 'Oops, something went wrong, please try again.'
        });
        this.setState({ isLoading: false });
        this._onCloseModal();
      }
    } else {
      try {
        await doEditActivityRecordHours({
          bookingId: booking.bookingId,
          startDateTime: updatedStartDateTime,
          endDateTime: updatedEndDateTime,
          shiftSlotStatus: isSentToTimeSheet ? ShiftSlotStatus.CHECKED_OUT : booking.shiftSlotStatus,
          isDuration: timeSelectOption === 'duration'
        });

        if (isSentToTimeSheet) {
          notification.success({
            message: 'Hours added to timesheet',
            description: 'This activity record has been added to the team members timesheet pending record approved.'
          });
          notification.success({
            message: 'Time changed',
            description: 'You have successfully changed the time of this activity record'
          });
        } else {
          notification.success({
            message: 'Duration changed',
            description: 'You have successfully changed the duration of this activity record.'
          });
        }
        this._onCloseModal();
      } catch (e) {
        notification.error({
          message: 'Oops, something went wrong, please try again.'
        });
        this.setState({ isLoading: false });
      }
    }
  };

  componentDidMount() {
    const { booking, isSentToTimeSheet } = this.props;
    const { isDuration, startDateTime, endDateTime, timezone } = booking;

    const localStartDate = moment(moment.tz(startDateTime, timezone).format('YYYY-MM-DD'));
    const localStartDateTime = moment(moment.tz(startDateTime, timezone).format('YYYY-MM-DD HH:mm'));
    const localEndDateTime = moment(moment.tz(endDateTime, timezone).format('YYYY-MM-DD HH:mm'));
    const hours = moment(endDateTime).diff(moment(startDateTime), 'hour');
    const minutes = moment(endDateTime).diff(moment(startDateTime), 'minutes') - hours * 60;

    this.setState({
      startDate: localStartDate,
      startDateTime: localStartDateTime,
      endDateTime: localEndDateTime,
      hours,
      minutes,
      timeSelectOption: isDuration ? 'duration' : 'datetime',
      step: isSentToTimeSheet ? 1 : 2,
      title: isSentToTimeSheet ? 'Add hours to timesheet' : 'Edit Activity Record'
    });
  }

  private _renderContent = () => {
    const { booking } = this.props;
    const { isLoading } = this.state;
    if (this.state.step === 1) {
      return (
        <div>
          <Text>
            Are your sure you want to add this activity record to the assigned team members timesheet? (Pending record
            approval)
          </Text>
          <ActionModalFooter>
            <SecondaryButton size={'large'} onClick={this._onCloseModal} disabled={isLoading}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size={'large'} className={'ml-large'} onClick={this._onNextStep} loading={isLoading}>
              Add to timesheet
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (this.state.step === 2) {
      return (
        <div>
          <div>
            <FieldLabel text={'ACTIVITY DATE'} />
          </div>
          <div>
            <DatePicker
              className="gh-datepicker rounded"
              calendarClassName="gh-datepicker-calendar"
              dateFormat="dd/MM/yyyy"
              selected={this.state.startDate.toDate()}
              onChange={this._setStartDate}
              maxDate={new Date()}
            />
          </div>

          {!(booking.workerId && booking.shiftSlotStatus === ShiftSlotStatus.CHECKED_OUT) && (
            <div className={'mt-large'}>
              <Radio.Group
                size={'large'}
                value={this.state.timeSelectOption}
                onChange={(event) => {
                  this.setState({ timeSelectOption: event.target.value });
                }}
              >
                <Radio style={{ display: 'block' }} value={'duration'}>
                  Select a duration
                </Radio>
                <Radio style={{ display: 'block' }} className={'mt-small'} value={'datetime'}>
                  Select a start and finish time
                </Radio>
              </Radio.Group>
            </div>
          )}

          {this.state.timeSelectOption === 'duration' && (
            <div className={'mt-large'}>
              <FieldLabel text={'DURATION OF ACTIVITY'} />
              <div className={'mt-small'}>
                <NumberInput
                  min={0}
                  max={23}
                  value={this.state.hours}
                  onChange={this._onChangeHours}
                  placeholder={'1'}
                  size={'large'}
                  style={{ width: '120px' }}
                  precision={0}
                />
                <Text className={'ml-small mr-medium'}>Hours</Text>
                <Select
                  size={'large'}
                  style={{ width: '120px' }}
                  value={this.state.minutes}
                  onChange={this._onChangeMinutes}
                >
                  <Option value={0}>0</Option>
                  <Option value={5}>5</Option>
                  <Option value={10}>10</Option>
                  <Option value={15}>15</Option>
                  <Option value={20}>20</Option>
                  <Option value={25}>25</Option>
                  <Option value={30}>30</Option>
                  <Option value={35}>35</Option>
                  <Option value={40}>40</Option>
                  <Option value={45}>45</Option>
                  <Option value={50}>50</Option>
                  <Option value={55}>55</Option>
                </Select>
                <Text className={'ml-small'}>Minutes</Text>
              </div>
            </div>
          )}

          {this.state.timeSelectOption === 'datetime' && (
            <div className={'mt-large flex-row'}>
              <div>
                <FieldLabel text={'SELECT START TIME'} />
                <TimeInput
                  size="large"
                  value={moment(this.state.startDateTime)}
                  onChange={this._setStartTime}
                  className={'mt-small'}
                />
              </div>
              <div className={'ml-large'}>
                <FieldLabel text={'SELECT FINISH TIME'} />
                <TimeInput
                  size="large"
                  value={moment(this.state.endDateTime)}
                  onChange={this._setEndTime}
                  className={'mt-small'}
                />
              </div>
            </div>
          )}

          {booking.workerId && booking.shiftSlotStatus === ShiftSlotStatus.CONFIRMED && (
            <div
              style={{
                backgroundColor: ' #FAFAFA',
                border: '1px solid #EEEEEE',
                boxSizing: 'border-box',
                borderRadius: '8px'
              }}
              className={'flex-row p-large mt-large'}
            >
              <Icon type="info-circle" style={{ fontSize: '21px', color: '#696969' }} />
              <Text className={'ml-large'}>
                You have chosen to add the assigned team member hours to their timesheet. As a result you must add a
                start and finish time.
              </Text>
            </div>
          )}
          <ActionModalFooter className={'mt-large'}>
            <SecondaryButton size={'large'} onClick={this._onCloseModal} disabled={isLoading}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size={'large'} className={'ml-large'} loading={isLoading} onClick={this._onSubmit}>
              Save
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  render() {
    const { isOpen } = this.props;
    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width={'medium'}
        verticalAlignment="highest"
      >
        {this._renderContent()}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditActivityRecordHours: dispatch.bookingsStore.doEditActivityRecordHours
});

export default connect(
  null,
  mapDispatch
)(ActivityRecordEditTimeActionModal);
