import { Avatar, Checkbox, Col, Form, Icon, Input, InputNumber, notification, Radio, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Warning } from 'common-components/alerts';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import ActionModal from 'common-components/modal/ActionModal';
import { TimezoneIndicator } from 'common-components/timezone';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { timeZone } from 'interfaces/timezone-type';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { BookingStatus, EditRecurringMode } from 'utilities/enum-utils';

const FormItem = Form.Item;

const searchAutocompleteOptions = {
  componentRestrictions: { country: ['au'] },
};

interface IAddAddressModalProps extends FormComponentProps {
  closeCreateEditAddressModal: () => void;
  isOpen: boolean;
  saveCustomerAddress: (
    addressLocation: any,
    editRecurringMode?: any,
    numberOfBookings?: number,
    conflictBookingIds?: any,
  ) => void;
  address?: any;
  title?: any;
  subTitle?: any;
  isEditBookingAddress?: boolean;
  isRecurring?: boolean;
  numberOfBookingLeft?: number;
  doCheckEditLocationConflict: typeof dispatch.bookingsStore.doCheckEditLocationConflict;
  bookingId?: string;
  bookingRequestId?: string;
  timezone?: timeZone;
}

interface IAddAddressModalState {
  isLoadingFilter: boolean;
  isActionModalOpen: boolean;
  isManual: boolean;
  inputAddress: string;
  addressLocation: any;
  selectedLocation: boolean;
  selectedLattitude: any;
  selectedLongitude: any;
  address_components: any;
  displayRecurringOptions: boolean;
  selectedOption: number;
  numberOfBookings: number;
  isVerifying: boolean;
  customerConflicts: any;
  workerConflicts: any;
  isCustomerConflictList: boolean;
  isWorkerConflictList: boolean;
  modalWidth: string;
  cancelAllConflicts: boolean;
  errorConfirmConflicts: boolean;
  temporarySelectedAddress: any;
}

class AddAddressModal extends Component<IAddAddressModalProps, IAddAddressModalState> {
  state = {
    isLoadingFilter: false,
    isActionModalOpen: false,
    isManual: false,
    inputAddress: '',
    addressLocation: null,
    selectedLocation: false,
    selectedLattitude: '',
    selectedLongitude: '',
    address_components: [],
    displayRecurringOptions: false,
    selectedOption: EditRecurringMode.Current,
    numberOfBookings: 1,
    isVerifying: false,
    customerConflicts: [],
    workerConflicts: [],
    isCustomerConflictList: false,
    isWorkerConflictList: false,
    modalWidth: 'medium',
    cancelAllConflicts: false,
    errorConfirmConflicts: false,
    temporarySelectedAddress: null,
  };

  private _displayRecurringOptions = async () => {
    await this._generateCustomerAddress();
    this.setState({ displayRecurringOptions: true });
  };

  private _checkForConflicts = async () => {
    try {
      this.setState({ isVerifying: true });
      const result: any = await this.props.doCheckEditLocationConflict({
        bookingId: this.props.bookingId,
        bookingRequestId: this.props.bookingRequestId,
        editRecurringMode: this.state.selectedOption,
        numberOfBookings: this.state.numberOfBookings,
      });

      if (!_.isEmpty(result.data.customerConflicts)) {
        this.setState({
          isVerifying: false,
          customerConflicts: result.data.customerConflicts,
          workerConflicts: result.data.workerConflicts,
          displayRecurringOptions: false,
          modalWidth: 'x-large',
          isCustomerConflictList: true,
        });
      } else if (!_.isEmpty(result.data.workerConflicts)) {
        this.setState({
          isVerifying: false,
          workerConflicts: result.data.workerConflicts,
          displayRecurringOptions: false,
          modalWidth: 'x-large',
          isWorkerConflictList: true,
        });
      } else {
        this.setState({ isVerifying: false, displayRecurringOptions: false });
        await this._saveCustomerAddress();
      }
    } catch (e: any) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
      this.setState({
        isVerifying: false,
      });
    }
  };

  private _generateCustomerAddress = async () => {
    let addressLocation;
    const { form } = this.props;
    const { selectedLattitude, selectedLongitude, address_components } = this.state;
    if (this.state.isManual) {
      const streetAddress1 = form.getFieldValue('streetaddress');
      const streetAddress2 = form.getFieldValue('streetaddress2') ? form.getFieldValue('streetaddress2') : '';
      const suburb = form.getFieldValue('suburb');
      const state = form.getFieldValue('state');
      const postcode = form.getFieldValue('postcode');
      const country = 'Australia';
      const inputAddress = streetAddress1 + ' ' + streetAddress2 + ' , ' + suburb + ' , ' + state + ' , ' + country;
      const latlong = await CommonUtils.getLatLongByAddress(inputAddress);
      addressLocation = {
        state: state,
        geoLat: latlong.lat,
        geoLng: latlong.lng,
        country: country,
        locality: suburb,
        postcode: postcode,
        fullAddress: CommonUtils.formatFullAddress({
          streetAddress1,
          streetAddress2,
          locality: suburb,
          state,
          postcode,
          country,
        }),
        streetAddress1: streetAddress1,
        streetAddress2: streetAddress2,
        locationType: 'ActualVenue',
      };
      this.setState({ addressLocation });
    } else {
      const postal_code = _.find(address_components, (address) => {
        return address.types[0] === 'postal_code';
      });

      const country = _.find(address_components, (address) => {
        return address.types[0] === 'country';
      });

      const province = _.find(address_components, (address) => {
        return address.types[0] === 'administrative_area_level_1';
      });

      const locality = _.find(address_components, (address) => {
        return address.types[0] === 'locality';
      });

      const street_number = _.find(address_components, (address) => {
        return address.types[0] === 'street_number';
      });

      const route = _.find(address_components, (address) => {
        return address.types[0] === 'route';
      });

      const subpremise = _.find(address_components, (address) => {
        return address.types[0] === 'subpremise';
      });

      const streetAddress1 = route
        ? `${subpremise ? subpremise.long_name.replace(/\D/g, '') + '/' : ''}${
            street_number ? street_number.long_name : ''
          } ${route.long_name}`
        : '';

      addressLocation = {
        state: province !== undefined ? province.short_name : '',
        geoLat: selectedLattitude,
        geoLng: selectedLongitude,
        country: country !== undefined ? country.long_name : '',
        locality: locality !== undefined ? locality.long_name : '',
        postcode: postal_code !== undefined ? postal_code.long_name : '',
        streetAddress1,
        streetAddress2: '',
        fullAddress: CommonUtils.formatFullAddress({
          streetAddress1,
          streetAddress2: '',
          locality: locality !== undefined ? locality.long_name : '',
          state: province !== undefined ? province.short_name : '',
          postcode: postal_code !== undefined ? postal_code.long_name : '',
          country: country !== undefined ? country.long_name : '',
        }),
        locationType: 'ActualVenue',
      };
      this.setState({ addressLocation });
    }
  };

  private _generateLocationAndSave = async () => {
    await this._generateCustomerAddress();
    this.props.isEditBookingAddress && this.props.isRecurring
      ? await this._checkForConflicts()
      : await this._saveCustomerAddress();
  };

  private _saveCustomerAddress = async () => {
    this.props.saveCustomerAddress(
      this.state.addressLocation,
      this.state.selectedOption,
      this.state.numberOfBookings,
      [...this.state.customerConflicts, ...this.state.workerConflicts].map((x) => x.supportWorkerAttendanceId),
    );
    this._closeActionCreateModal();
  };

  private _onChangeCancelAllConflicts = (event) => {
    this.setState({ cancelAllConflicts: event.target.checked, errorConfirmConflicts: false });
  };

  private _displayWorkerConflicts = () => {
    if (!this.state.cancelAllConflicts) {
      this.setState({ errorConfirmConflicts: true });
    } else {
      this.setState({
        isCustomerConflictList: false,
        isWorkerConflictList: true,
        errorConfirmConflicts: false,
        cancelAllConflicts: false,
      });
    }
  };

  private _onChangeConflicts = () => {
    if (!this.state.cancelAllConflicts) {
      this.setState({ errorConfirmConflicts: true });
    } else {
      this.setState({ isCustomerConflictList: false, isWorkerConflictList: false });
      this._saveCustomerAddress();
    }
  };

  private _handleChange = (inputAddress) => {
    this.setState({ inputAddress: inputAddress });
    if (inputAddress == '') {
      this.setState({ selectedLocation: false });
    }
  };

  private _handleSelect = async (inputAddress) => {
    try {
      let selectedLocation = null;
      await geocodeByAddress(inputAddress)
        .then((results) => (selectedLocation = results[0]))
        .catch(() => this.setState({ selectedLocation: false }));

      if (selectedLocation) {
        const latLng = await getLatLng(selectedLocation);
        this.setState({
          selectedLocation: true,
          selectedLattitude: latLng.lat,
          selectedLongitude: latLng.lng,
          address_components: selectedLocation.address_components,
          temporarySelectedAddress: CommonUtils.createAddressFromGoogleMap(selectedLocation.address_components),
        });
      }
    } catch {
      this.setState({ selectedLocation: false });
    }
    this.setState({ inputAddress: inputAddress });
  };

  private _closeActionCreateModal = () => {
    this.setState({
      isActionModalOpen: false,
      inputAddress: '',
      selectedLocation: false,
      isManual: false,
      displayRecurringOptions: false,
      isCustomerConflictList: false,
      isWorkerConflictList: false,
      errorConfirmConflicts: false,
      cancelAllConflicts: false,
      customerConflicts: [],
      workerConflicts: [],
    });
    this.props.closeCreateEditAddressModal();
  };

  private _switchToManual = () => {
    this.setState({ isManual: true, selectedLocation: false, inputAddress: '' });
  };

  private _switchToAuto = () => {
    this.setState({ isManual: false, selectedLocation: false, inputAddress: '' });
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _onChangeNumberOfBookings = (event) => {
    this.setState({ numberOfBookings: event });
  };

  private _validatePostcode = (rule, value, callback) => {
    try {
      if (Number(value).toString().length !== 4) {
        throw Error('Postcode is required (4 digits)');
      }
    } catch (e) {
      callback(e);
      return;
    }
    callback();
  };

  private _addAddress = () => {
    let isFormValid = true;
    if (this.state.isManual) {
      this.props.form.validateFields((err, value) => {
        if (err) {
          isFormValid = false;
        }
      });
    }
    if (isFormValid) {
      this.props.isEditBookingAddress && this.props.isRecurring && this.props.numberOfBookingLeft > 0
        ? this._displayRecurringOptions()
        : this._generateLocationAndSave();
    }
  };

  render() {
    const { form, address, timezone } = this.props;
    const { getFieldDecorator } = form;
    const { workerConflicts } = this.state;

    const defaultStyles = {
      autocompleteItem: {
        backgroundColor: '#000',
        padding: '10px',
        color: '#555555',
        cursor: 'pointer',
      },
      autocompleteItemActive: {
        backgroundColor: '#fafafa',
      },
    };

    const selectedAddress = this.state.temporarySelectedAddress ? this.state.temporarySelectedAddress : address;

    return (
      <div>
        <ActionModal
          isOpen={this.props.isOpen}
          title={
            this.props.title ? (
              this.props.title
            ) : (
              <>
                Service <b>Location</b>
              </>
            )
          }
          onClose={this._closeActionCreateModal}
          width={this.state.modalWidth}
        >
          {this.state.displayRecurringOptions ? (
            <>
              <div className="anim-slide-left">
                <Paragraph>
                  The selected booking is part of a recurring booking series. Please select one of the following options
                  for editing this booking
                </Paragraph>
              </div>
              <div>
                <div className="mb-small">
                  <Text weight="bold">Edit location for:</Text>
                </div>
                <Radio.Group value={this.state.selectedOption} onChange={this._onChangeOption} className="ml-medium">
                  <Radio
                    value={EditRecurringMode.Current}
                    className={`${this.state.selectedOption === EditRecurringMode.Current &&
                      'text-weight-bold'} mb-small `}
                  >
                    <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                      This booking only.
                    </div>
                  </Radio>
                  <br />
                  <Radio
                    value={EditRecurringMode.CurrentAll}
                    className={`${this.state.selectedOption === EditRecurringMode.CurrentAll &&
                      'text-weight-bold'} mb-small `}
                  >
                    <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                      This booking and all following bookings.
                    </div>
                  </Radio>
                  <br />
                  <Radio
                    value={EditRecurringMode.CurrentNext}
                    className={`${this.state.selectedOption === EditRecurringMode.CurrentNext &&
                      'text-weight-bold'} mb-small `}
                  >
                    <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                      This booking and the next{' '}
                      <InputNumber
                        className="mh-x-small"
                        style={{ width: '50px' }}
                        min={1}
                        max={this.props.numberOfBookingLeft}
                        value={this.state.numberOfBookings}
                        onChange={this._onChangeNumberOfBookings}
                      />{' '}
                      booking
                      {this.state.numberOfBookings !== 1 && 's'}.
                    </div>
                    <br />
                    <Text size={'regular'} color="secondary" weight="regular" className="ml-x2-large">
                      (There {this.props.numberOfBookingLeft !== 1 ? 'are' : 'is'} {this.props.numberOfBookingLeft} left
                      in the recurring series after the currently selected booking)
                    </Text>
                  </Radio>
                </Radio.Group>
              </div>
              <div className={'mt-large'}>
                <Row type={'flex'} justify={'end'}>
                  <SecondaryButton className="mr-medium" size="large" onClick={this._closeActionCreateModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size="large" onClick={this._checkForConflicts}>
                    Continue
                  </PrimaryButton>
                </Row>
              </div>
            </>
          ) : this.state.isCustomerConflictList ? (
            <>
              <div className="anim-slide-left">
                <Warning
                  content={
                    <Text>
                      Additional bookings at the same time, but at different locations, have been found for this
                      customer. In order to successfully change the location of this booking you must also change the
                      location of all conflicting bookings listed below.
                    </Text>
                  }
                  className="mb-large"
                />
                <Row>
                  <Col span={8}>
                    <Text weight={'bold'} size="x-large">
                      {this.state.customerConflicts ? this.state.customerConflicts.length : 0} conflicting booking
                      {this.state.customerConflicts ? (this.state.customerConflicts.length !== 1 ? 's' : '') : 's'}
                    </Text>
                  </Col>
                  <Col span={16} className="text-align-right">
                    <TimezoneIndicator timezone={timezone} />
                  </Col>
                </Row>
                <Row className="text-weight-bold bordered-bottom border-standard-gray p-small mt-large">
                  <Col span={6}>Date</Col>
                  <Col span={6}>Service</Col>
                  <Col span={4}>Status</Col>
                  <Col span={8}>Worker assigned</Col>
                </Row>
                <div
                  style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '35vh' }}
                  className="bordered-bottom border-standard-gray"
                >
                  {(!this.state.customerConflicts || this.state.customerConflicts.length === 0) && (
                    <Row className="pv-medium">
                      <Text>No conflicts found.</Text>
                    </Row>
                  )}
                  {_.map(this.state.customerConflicts, (conflict) => (
                    <Row type="flex" align="middle" className="p-small border-secondary evenodd">
                      <Col span={6}>
                        {moment
                          .tz(conflict.startDateTime, timezone)
                          .startOf('day')
                          .isSame(moment.tz(conflict.endDateTime, timezone).startOf('day')) ? (
                          <text>
                            <b>{moment.tz(conflict.startDateTime, timezone).format('DD/MM/YYYY')}</b>
                            <br />
                            <Text>
                              {moment.tz(conflict.startDateTime, timezone).format('hh:mm a')} -{' '}
                              {moment.tz(conflict.endDateTime, timezone).format('hh:mm a')}
                            </Text>
                          </text>
                        ) : (
                          <text>
                            <b>{moment.tz(conflict.startDateTime, timezone).format('DD/MM/YYYY')}</b>{' '}
                            {moment.tz(conflict.startDateTime, timezone).format('hh:mm a')}
                            <br />
                            <b>{moment.tz(conflict.endDateTime, timezone).format('DD/MM/YYYY')}</b>{' '}
                            {moment.tz(conflict.endDateTime, timezone).format('hh:mm a')}
                          </text>
                        )}
                      </Col>
                      <Col
                        span={6}
                        title={conflict.serviceName}
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {conflict.serviceName}
                      </Col>
                      <Col span={4}>
                        {conflict.status === BookingStatus.INPROGRESS
                          ? 'In progress'
                          : _.startCase(_.snakeCase(conflict.status))}
                      </Col>
                      <Col
                        span={8}
                        title={conflict.shiftSlotStatus}
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {conflict.shiftSlotStatus === 'UNASSIGNED' ? (
                          <Text color={'red-dark'}>No worker assigned</Text>
                        ) : conflict.shiftSlotStatus === 'PUBLISHING' ? (
                          <Text color={'violet-light'}>
                            <Icon type="wifi" className="mr-x-small" />
                            Published
                          </Text>
                        ) : (
                          <Text>
                            {conflict.firstName} {conflict.lastName}
                          </Text>
                        )}
                      </Col>
                    </Row>
                  ))}
                </div>
                <div className="mt-large">
                  Pressing continue will change the location of all of the above booking to new address. Please check
                  the box below to confirm this.
                </div>
                <div className={'mt-small mb-large'}>
                  <Checkbox value={this.state.cancelAllConflicts} onChange={this._onChangeCancelAllConflicts}>
                    <b>Change location of all conflicting bookings.</b>
                  </Checkbox>
                  {this.state.errorConfirmConflicts && (
                    <>
                      <br />
                      <Text color="red-dark">In order to continue you must elect to change all locations.</Text>
                    </>
                  )}
                </div>
              </div>
              <div className={'mb-small'}>
                <Row type={'flex'} justify={'end'}>
                  <SecondaryButton className="mr-medium" size="large" onClick={this._closeActionCreateModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    size="large"
                    onClick={
                      !_.isEmpty(this.state.workerConflicts) ? this._displayWorkerConflicts : this._onChangeConflicts
                    }
                  >
                    Continue
                  </PrimaryButton>
                </Row>
              </div>
            </>
          ) : this.state.isWorkerConflictList ? (
            <>
              <div className="anim-slide-left">
                <Warning
                  content={
                    <Text>
                      Additional bookings at the same time, but at different locations, have been found for this worker.
                      In order to successfully change the location of this booking you must also change the location of
                      all conflicting bookings listed below by pressing continue.
                    </Text>
                  }
                  className="mb-large"
                />
                <Text weight={'bold'} size="x-large">
                  {workerConflicts ? workerConflicts.length : 0} conflicting booking
                  {workerConflicts ? (workerConflicts.length !== 1 ? 's' : '') : 's'}
                </Text>
                <Row className="text-weight-bold bordered-bottom border-standard-gray p-small mt-large">
                  <Col span={5}>Date</Col>
                  <Col span={8}>Service</Col>
                  <Col span={11}>Customer</Col>
                </Row>
                <div
                  style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '35vh' }}
                  className="bordered-bottom border-standard-gray"
                >
                  {(!workerConflicts || workerConflicts.length === 0) && (
                    <Row className="pv-medium">
                      <Text>No conflicts found.</Text>
                    </Row>
                  )}
                  {_.map(workerConflicts, (conflict) => (
                    <Row type="flex" align="middle" className="p-small border-secondary evenodd">
                      <Col span={5}>
                        {moment(conflict.startDateTime)
                          .startOf('day')
                          .isSame(moment(conflict.endDateTime).startOf('day')) ? (
                          <text>
                            <b>{moment(conflict.startDateTime).format('DD/MM/YYYY')}</b>
                            <br />
                            <Text>
                              {moment(conflict.startDateTime).format('hh:mm a')} -{' '}
                              {moment(conflict.endDateTime).format('hh:mm a')}
                            </Text>
                          </text>
                        ) : (
                          <text>
                            <b>{moment(conflict.startDateTime).format('DD/MM/YYYY')}</b>{' '}
                            {moment(conflict.startDateTime).format('hh:mm a')}
                            <br />
                            <b>{moment(conflict.endDateTime).format('DD/MM/YYYY')}</b>{' '}
                            {moment(conflict.endDateTime).format('hh:mm a')}
                          </text>
                        )}
                      </Col>
                      <Col
                        span={8}
                        title={conflict.serviceName}
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        {conflict.serviceName}
                      </Col>
                      <Col
                        span={11}
                        title={conflict.shiftSlotStatus}
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                      >
                        <Text>
                          <Avatar className="mr-medium" size={'small'} src={conflict.attachmentUrl} />{' '}
                          {conflict.firstName} {conflict.lastName}
                        </Text>
                      </Col>
                    </Row>
                  ))}
                </div>
                <div className="mt-large">
                  Pressing continue will change the location of all of the above booking to new address. Please check
                  the box below to confirm this.
                </div>
                <div className={'mt-small mb-large'}>
                  <Checkbox value={this.state.cancelAllConflicts} onChange={this._onChangeCancelAllConflicts}>
                    <b>Change location of all conflicting bookings.</b>
                  </Checkbox>
                  {this.state.errorConfirmConflicts && (
                    <>
                      <br />
                      <Text color="red-dark">In order to continue you must elect to change all locations.</Text>
                    </>
                  )}
                </div>
              </div>
              <div className={'mt-large'}>
                <Row type={'flex'} justify={'end'}>
                  <SecondaryButton className="mr-medium" size="large" onClick={this._closeActionCreateModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size="large" onClick={this._onChangeConflicts}>
                    Continue
                  </PrimaryButton>
                </Row>
              </div>
            </>
          ) : (
            <div className="anim-slide-left">
              <SpinningLoadingActionModel isOpen={this.state.isLoadingFilter} verticalAlignment={'highest'} />
              <Paragraph>
                {this.props.subTitle
                  ? this.props.subTitle
                  : 'Type the address you would like to include. We will try to automatically lookup your address. If we can’t find it, you can enter it manually by clicking below.'}
              </Paragraph>
              {!this.state.isManual ? (
                <React.Fragment>
                  <div className="mb-small" style={{ minHeight: '300px' }}>
                    {/*<SubTitle>Lookup Address</SubTitle>*/}
                    <FormItem className={'m-none'}>
                      <PlacesAutocomplete
                        value={this.state.inputAddress}
                        onSelect={this._handleSelect}
                        onChange={this._handleChange}
                        shouldFetchSuggestions={this.state.inputAddress.length > 2}
                        searchOptions={searchAutocompleteOptions}
                        styles={defaultStyles}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div>
                            <input
                              value={this.state.inputAddress}
                              {...getInputProps({
                                placeholder: 'Type your address ...',
                                className: 'location-search-input width-full pl-small',
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion, index) => {
                                const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer',
                                      borderTop: '1px ridge grey',
                                      borderLeft: '1px ridge grey',
                                      borderRight: '1px ridge grey',
                                    }
                                  : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer',
                                      borderTop: '1px ridge grey',
                                      borderLeft: '1px ridge grey',
                                      borderRight: '1px ridge grey',
                                    };
                                return (
                                  <div
                                    key={index}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>
                                      <Icon type={'environment'} /> {suggestion.description}
                                    </span>
                                  </div>
                                );
                              })}
                              {suggestions.length === 0 &&
                                !_.isEmpty(this.state.inputAddress) &&
                                !this.state.selectedLocation && <div>No results found.</div>}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </FormItem>
                  </div>
                  <div className={'mb-small'}>
                    <Row type={'flex'} justify={'end'}>
                      <Col className="text-align-left flex-grow">
                        <HyperlinkButton onClick={this._switchToManual}>Enter Address manually</HyperlinkButton>
                      </Col>
                      <Col>
                        <SecondaryButton className="mr-medium" size="large" onClick={this._closeActionCreateModal}>
                          Cancel
                        </SecondaryButton>
                      </Col>
                      <Col>
                        <PrimaryButton
                          disabled={!this.state.selectedLocation}
                          loading={this.state.isVerifying}
                          onClick={
                            this.props.isEditBookingAddress &&
                            this.props.isRecurring &&
                            this.props.numberOfBookingLeft > 0
                              ? this._displayRecurringOptions
                              : this._generateLocationAndSave
                          }
                          size="large"
                        >
                          Add Address
                        </PrimaryButton>
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div style={{ height: '60vh', overflow: 'auto' }}>
                    <div className="mb-small">
                      <SubTitle>Street Address</SubTitle>
                      <FormItem className={'m-none pr-small'}>
                        {getFieldDecorator('streetaddress', {
                          initialValue: selectedAddress ? selectedAddress.streetAddress1 : null,
                          rules: [
                            {
                              required: true,
                              message: 'Street address is required',
                            },
                          ],
                        })(<Input placeholder="Enter Street Address" />)}
                      </FormItem>
                    </div>
                    <div className="mb-small">
                      <SubTitle>Street Address 2</SubTitle>
                      <FormItem className={'m-none pr-small'}>
                        {getFieldDecorator('streetaddress2', {
                          initialValue: selectedAddress ? selectedAddress.streetAddress2 : null,
                        })(<Input placeholder="Enter Street Address 2" />)}
                      </FormItem>
                    </div>
                    <div className="mb-small width-1/2">
                      <SubTitle>Suburb</SubTitle>
                      <FormItem className={'m-none pr-small'}>
                        {getFieldDecorator('suburb', {
                          initialValue: selectedAddress ? selectedAddress.locality : null,
                          rules: [
                            {
                              required: true,
                              message: 'Suburb is required',
                            },
                          ],
                        })(<Input placeholder="Enter Suburb" />)}
                      </FormItem>
                    </div>
                    <div className="mb-small width-1/2">
                      <SubTitle>State</SubTitle>
                      <FormItem className={'m-none pr-small'}>
                        {getFieldDecorator('state', {
                          initialValue: selectedAddress ? selectedAddress.state : null,
                          rules: [
                            {
                              required: true,
                              message: 'State is required',
                            },
                          ],
                        })(
                          <Select
                            placeholder="Select a State"
                            style={{ width: '200px' }}
                            showSearch={true}
                            optionFilterProp={'children'}
                          >
                            <Select.Option value={'ACT'}>Australian Capital Territory</Select.Option>
                            <Select.Option value={'NSW'}>New South Wales</Select.Option>
                            <Select.Option value={'NT'}>Northern Territory</Select.Option>
                            <Select.Option value={'QLD'}>Queensland</Select.Option>
                            <Select.Option value={'SA'}>South Australia</Select.Option>
                            <Select.Option value={'TAS'}>Tasmania</Select.Option>
                            <Select.Option value={'VIC'}>Victoria</Select.Option>
                            <Select.Option value={'WA'}>Western Australia</Select.Option>
                          </Select>,
                        )}
                      </FormItem>
                    </div>
                    <div className="mb-small">
                      <SubTitle>Postcode</SubTitle>
                      <FormItem className={'m-none pr-small'}>
                        {getFieldDecorator('postcode', {
                          initialValue: selectedAddress ? selectedAddress.postcode : null,
                          rules: [
                            {
                              validator: this._validatePostcode,
                            },
                          ],
                        })(<Input maxLength={4} placeholder="Enter Postcode" style={{ width: '200px' }} />)}
                      </FormItem>
                    </div>
                    <div className="mb-small width-1/2">
                      <SubTitle>Country</SubTitle>
                      <FormItem className={'m-none pr-small'}>
                        <Select size={'large'} style={{ width: '200px' }} defaultValue={'AU'} disabled>
                          <Select.Option value={'AU'}>Australia</Select.Option>
                        </Select>
                      </FormItem>
                    </div>
                  </div>
                  <div className={'mb-small'}>
                    <Row type={'flex'} justify={'end'}>
                      {this.state.isManual && (
                        <Col className="mb-small text-align-left flex-grow">
                          <HyperlinkButton onClick={this._switchToAuto}>Address lookup tool</HyperlinkButton>
                        </Col>
                      )}
                      <Col>
                        <GhostButton size="large" className="mr-medium" onClick={this._closeActionCreateModal}>
                          Close
                        </GhostButton>
                      </Col>
                      <Col>
                        <PrimaryButton size="large" loading={this.state.isVerifying} onClick={this._addAddress}>
                          Add Address
                        </PrimaryButton>
                      </Col>
                    </Row>
                  </div>
                </React.Fragment>
              )}
            </div>
          )}
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCheckEditLocationConflict: dispatch.bookingsStore.doCheckEditLocationConflict,
});

export default connect(null, mapDispatch)(Form.create<IAddAddressModalProps>()(AddAddressModal));
