import React, { Component } from 'react';
import CenteredLayout from 'layouts/CenteredLayout';
import { Paragraph, Text, Title } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import * as H from 'history';
import { Col, Icon, Row } from 'antd';
import EditPlanManagementModal from 'views/account-management/components/FundingAndPaymentModals/EditPlanManagementModal';

interface IServiceAgreementPaymentMethodsViewProps {
  doFetchCompanyFundingAndPaymentLite: typeof dispatch.companyStore.doFetchCompanyFundingAndPaymentLite;
  doFetchCompanyLite: typeof dispatch.companyStore.doFetchCompanyLite;
  companyFundingAndPaymentLite: typeof state.companyStore.companyFundingAndPaymentLite;
  portalUser: typeof state.authStore.portalUser;
  history: H.History;
}

interface IServiceAgreementPaymentMethodsViewState {
  isLoading: boolean;
  isEditPlanManagementConfigOpen: boolean;
}

class ServiceAgreementPaymentMethodsView extends Component<
  IServiceAgreementPaymentMethodsViewProps,
  IServiceAgreementPaymentMethodsViewState
> {
  state = {
    isLoading: true,
    isEditPlanManagementConfigOpen: false
  };

  private _goToLandingPage = () => {
    this.props.history.push('/account/landing');
  };

  private _goToFeatureConfiguration = () => {
    this.props.history.push('/account/features');
  };

  private _goToServiceAgreementTemplateDetail = (paymentMethod) => {
    this.props.history.push(`/account/features/service-agreement-templates/${paymentMethod}`);
  };

  private _closeEditPlanManagementConfig = async () => {
    await this.props.doFetchCompanyLite([]);
    this.setState({ isEditPlanManagementConfigOpen: false });
  };

  componentDidMount = async () => {
    await this.props.doFetchCompanyFundingAndPaymentLite({});
    this.setState({ isLoading: false });
  };

  render() {
    return (
      <CenteredLayout backgroundColor="white" bordered={true} className={'ph-small'}>
        {this.state.isEditPlanManagementConfigOpen && (
          <EditPlanManagementModal
            isOpen={this.state.isEditPlanManagementConfigOpen}
            onClose={this._closeEditPlanManagementConfig}
          />
        )}
        <div className={'pb-x4-large'}>
          <div className="mt-x-large mb-medium">
            <Title level={3} weight="regular" className="mv-none">
              <span className={'cursor-pointer text-color-blue-action'} onClick={this._goToLandingPage}>
                {'Account >'}
              </span>
              <span className={'cursor-pointer text-color-blue-action'} onClick={this._goToFeatureConfiguration}>
                {'Feature configuration >'}
              </span>{' '}
              Service agreement templates
            </Title>
          </div>
          <Paragraph>
            Configure feature of your workspace. Any changes in your features below will take effect for your entire
            organisation.
          </Paragraph>
          <div className="mt-x-large bordered-bottom border-standard-gray width-2/3">
            <Row
              className={'bordered-top border-standard-gray pv-medium cursor-pointer hover-bg-quaternary'}
              gutter={24}
              onClick={() => this._goToServiceAgreementTemplateDetail('ndis')}
            >
              <Col span={18}>
                <div className={'mb-small'}>
                  <Text weight={'bold'}>NDIS</Text>
                </div>
                <Text color={'secondary'}>NDIS Service Agreement templates.</Text>
              </Col>
              <Col span={6} className={'text-align-right'}>
                <Text>
                  <Icon type={'right'} className={'ml-large'} />
                </Text>
              </Col>
            </Row>
            <Row
              className={'bordered-top border-standard-gray pv-medium cursor-pointer hover-bg-quaternary'}
              gutter={24}
              onClick={() => this._goToServiceAgreementTemplateDetail('vcp')}
            >
              <Col span={18}>
                <div className={'mb-small'}>
                  <Text weight={'bold'}>Vulnerable Children Program</Text>
                </div>
                <Text color={'secondary'}>VCP Service Agreement templates.</Text>
              </Col>
              <Col span={6} className={'text-align-right'}>
                <Text>
                  <Icon type={'right'} className={'ml-large'} />
                </Text>
              </Col>
            </Row>
          </div>
        </div>
      </CenteredLayout>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyFundingAndPaymentLite: state.companyStore.companyFundingAndPaymentLite,
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchCompanyFundingAndPaymentLite: dispatch.companyStore.doFetchCompanyFundingAndPaymentLite,
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite
});

export default connect(mapState, mapDispatch)(ServiceAgreementPaymentMethodsView);
