import React, { PureComponent, ReactNode } from 'react';

import { Text } from 'common-components/typography';
import { PORTAL_COLORS, PORTAL_FONT_WEIGHTS } from '../../interfaces/common-ui-types';

interface SubTitleProps {
  children?: ReactNode;
  color?: PORTAL_COLORS;
  weight?: PORTAL_FONT_WEIGHTS;
  size?: PORTAL_FONT_WEIGHTS;
  textClassName?: string;
  containerClassName?: string;
}

// <Text className="pl-medium text-uppercase letter-spacing-wide" weight="bolder" size="small" color="secondary">

class SubTitle extends PureComponent<SubTitleProps, any> {
  render() {
    const {
      color = 'secondary',
      weight = 'bold',
      size = 'small',
      textClassName = '',
      containerClassName = 'line-height-100',
      children
    } = this.props;

    const finalClassName = 'text-uppercase ' + textClassName;

    return (
      <div className={containerClassName}>
        <Text weight={weight} size={size} className={finalClassName} color={color}>
          {children}
        </Text>
      </div>
    );
  }
}

export default SubTitle;
