import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { Text } from 'common-components/typography';
import FullScreenModal, { FullScreenModalFooter } from 'common-components/modal/FullScreenModal';
import Steps from 'common-components/steps/Steps';
import { ICustomer } from 'interfaces/customer-interfaces';

import FundGeneralInformationPanel from './FundGeneralInformationPanel';
import FundKeyContactsPanel from './FundKeyContactsPanel';
import FundGoalsPanel from './FundGoalsPanel';
import FundSupportCategoryPanel from './FundSupportCategoryPanel';
import FundSummaryPanel from './FundSummaryPanel';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';

interface ICreateNewPackageModalProps {
  closeCreatePackageModal: () => void;
  onNextStep: (stepData: any) => void;
  onPreviousStep: (stepData: any) => void;
  isOpen: boolean;
  selectedCustomer: ICustomer;
  step: number;
  totalStep: number;
}

interface ICreateNewPackageModalState {}

class CreateNewPackageModal extends Component<ICreateNewPackageModalProps, ICreateNewPackageModalState> {
  private _onNextStep = (stepData) => {
    this.props.onNextStep(stepData);
  };

  private _closeCreatePackageModal = () => {
    this.props.closeCreatePackageModal();
  };

  private _renderStepContent = () => {
    const { step, selectedCustomer } = this.props;
    if (step === 1) {
      return (
        <FundGeneralInformationPanel
          onNextStep={this._onNextStep}
          closeCreatePackageModal={this._closeCreatePackageModal}
          selectedCustomer={selectedCustomer}
        />
      );
    } else if (step === 2) {
      return (
        <FundSupportCategoryPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this.props.onPreviousStep}
          closeCreatePackageModal={this._closeCreatePackageModal}
          selectedCustomer={selectedCustomer}
        />
      );
    } else if (step === 3) {
      return (
        <FundGoalsPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this.props.onPreviousStep}
          closeCreatePackageModal={this._closeCreatePackageModal}
          selectedCustomer={selectedCustomer}
        />
      );
    } else if (step === 4) {
      return (
        <FundSummaryPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this.props.onPreviousStep}
          closeCreatePackageModal={this._closeCreatePackageModal}
          selectedCustomer={selectedCustomer}
        />
      );
    }
  };

  render() {
    const { step } = this.props;
    let bottomText = 'Add General Information';
    if (step === 2) {
      bottomText = 'Add Categories of Support';
    } else if (step === 3) {
      bottomText = 'Add Goals';
    } else if (step === 4) {
      bottomText = 'Create Funding Package';
    } else if (step === 5) {
      bottomText = 'Create Funding Package';
    }

    let steps = [];
    for (let i = 1; i <= this.props.totalStep; i++) {
      var stepObj = {};
      stepObj['stepNumber'] = i;
      stepObj['stepLabel'] = i;
      steps.push(stepObj);
    }
    return (
      <FullScreenScrollableModal
        isOpen={this.props.isOpen}
        canCloseEsc={false}
        onClose={this.props.closeCreatePackageModal}
        footerContent={
          <FullScreenModalFooter align={'left'}>
            <Row gutter={0} type="flex" align="middle">
              <Col span={8} />
              <Col span={8}>
                <div className="justify-center flex-row">
                  <Text size="regular" className="mb-medium">
                    <span className="mr-medium text-weight-bold">
                      Step {this.props.step} of {this.props.totalStep}
                    </span>
                    <span className="">{bottomText}</span>
                  </Text>
                </div>
                <Steps currentStep={this.props.step} steps={steps} />
              </Col>
              <Col span={8} />
            </Row>
          </FullScreenModalFooter>
        }
      >
        {this._renderStepContent()}
      </FullScreenScrollableModal>
    );
  }
}

export default CreateNewPackageModal;
