/* eslint-disable */
import React, { Component } from 'react';
import { Col, Row, Menu, Tabs } from 'antd';
import themeVariables from 'theme/theme-variables';

interface PanelPropTypes {
  backgroundColor?: string;
  overflow?: string;
  span?: number;
  padding?: string;
  transition?: string;
  height?: string;
}

class ColumnPanel extends Component<PanelPropTypes, any> {
  render() {
    return (
      <Col
        span={this.props.span || 0}
        style={{
          backgroundColor: this.props.backgroundColor || 'white',
          height: this.props.height || themeVariables.bodyHeight,
          overflow: this.props.overflow || 'auto',
          padding: this.props.padding || '16px 24px',
          transition: this.props.transition || 'none'
        }}
      >
        {/*{...this.props}*/}
        {this.props.children}
      </Col>
    );
  }
}

class ColumnedLayout extends Component<any, any> {
  render() {
    return (
      <Row
        style={{
          height: themeVariables.bodyHeight,
          borderTop: this.props.bordered ? `1px ${themeVariables.lightGreyVariant2} solid` : '0px'
        }}
        gutter={0}
      >
        {this.props.children}
      </Row>
    );
  }
}

export default ColumnedLayout;

export { ColumnPanel };
