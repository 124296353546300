import React, { Component } from 'react';
import { Paragraph, Title } from '../../../common-components/typography';
import { Icon } from 'antd';

const baseUIColor = 'green-darker';

class HoverRow extends Component<any> {
  state = { isHovered: false };

  _toggleHoverState = () => this.setState({ isHovered: !this.state.isHovered });

  render() {
    const { text, tab, navigateTab, iconName = 'bulb' } = this.props;

    return (
      <div
        className={
          'pl-x-large p-medium bordered-top hover-bg-' +
          baseUIColor +
          ' hover-text-color-white flex-row justify-between text-size-large cursor-pointer align-center text-color-' +
          baseUIColor
        }
        onMouseEnter={this._toggleHoverState}
        onMouseLeave={this._toggleHoverState}
        onClick={() => navigateTab(tab)}
      >
        <div>
          <Icon type={iconName} className="mr-small" />
          {text}
        </div>
        <div>
          {this.state.isHovered && (
            <div>
              <span className="mr-small">Go to section</span>
              <Icon type="enter" />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export class StyleLandingView extends Component<any> {
  render() {
    const { navigateTab } = this.props;

    return (
      <div className="mb-x3-large">
        <div className="mb-x-large">
          <Title level={1} lineHeight={100}>
            The GoodHuman Portal Style Guide
            <span className="text-color-orange">•</span>
            <span className="text-color-green">•</span>
            <span className="text-color-cobalt">•</span>
            <span className="text-color-violet">•</span>
            <span className="text-color-rose">•</span>
          </Title>
        </div>

        <div className="max-width-640 mb-x-large">
          <Paragraph>Welcome to the GoodHuman style guide.</Paragraph>
          <Paragraph>
            This guide serves to act as a base guideline to the various styles utilized throughout GoodHuman Web.
            Information found here should act as a general guide rather than a hard & fast rule. Visit the individual
            sections below for the various topics.
          </Paragraph>
        </div>

        {/*Links */}
        <div>
          <Title level={3}>Base Styles</Title>

          <div className="mb-x-large">
            <HoverRow navigateTab={navigateTab} tab={'typography'} text="Typography" />
            <HoverRow navigateTab={navigateTab} tab={'grid-spacing'} text="Grid Spacing" />
            <HoverRow navigateTab={navigateTab} tab={'color'} text="Color" />
            <div className="bordered-top" />
          </div>

          <Title level={3}>Base Components</Title>

          <div className="mb-x-large">
            <HoverRow navigateTab={navigateTab} tab={'button'} text="Buttons" />
            <HoverRow navigateTab={navigateTab} tab={'dialog'} text="Dialogs" />
            <HoverRow navigateTab={navigateTab} tab={'information-feedback'} text="Information Feedback" />
            <HoverRow navigateTab={navigateTab} tab={'tab-navigation'} text="Tab Navigation" />
            <HoverRow navigateTab={navigateTab} tab={'list'} text="Lists" />
            <HoverRow navigateTab={navigateTab} tab={'form-element'} text="Form Elements" />
            <HoverRow navigateTab={navigateTab} tab={'icon'} text="Icons" />
            <HoverRow navigateTab={navigateTab} tab={'graphic'} text="Graphics" />
            <HoverRow navigateTab={navigateTab} tab={'transition'} text="Transitions" />

            <div className="bordered-top" />
          </div>

          <Title level={3}>App Components</Title>
          <div className="mb-x-large">
            <HoverRow navigateTab={navigateTab} tab={'booking-status'} text="Booking Status" />
            <div className="bordered-top" />
          </div>
        </div>
      </div>
    );
  }
}
