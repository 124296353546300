import React from 'react';

import { PermissionRowBase } from 'views/account-management/add-new-member/panels/permissions-panel/portal/PermissionRowBase';
import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import { DepartmentPermissionRow } from 'views/account-management/add-new-member/panels/permissions-panel/portal/permission-rows/DepartmentPermissionRow';
import { IPermissionRole, IPermissions, IServiceDepartment } from 'interfaces/account-interfaces';
import ServicesSectionModal from 'views/account-management/components/ServicesSectionModal';

import _, { first, isEmpty, map, some } from 'lodash';
import { ServicePermissionType, UserPermissionRole } from 'utilities/enum-utils';
import { getServicePermission, getServiceRole } from 'views/account-management/utils/permission-utils';

interface ServicePermissionsRowProps {
  serviceDepartments: IServiceDepartment[];
  permissions: IPermissions;
  onChangePermissions: any;
  actionsDisabled: boolean;
  hasAllAccess: boolean;
}

interface ServicePermissionsRowState {
  serviceModalOpen: boolean;
  currentDeptId: string;
  currentDeptPermission: ServicePermissionType;
  currentDeptFlags: any[];
}

export class ServicePermissionsRow extends React.Component<ServicePermissionsRowProps, ServicePermissionsRowState> {
  state = {
    serviceModalOpen: false,
    currentDeptId: null,
    currentDeptPermission: null,
    currentDeptFlags: []
  };

  // Services
  onOpenService = (departmentId) => {
    const { permissions } = this.props;

    const { permissionRoles } = permissions;

    let currentDeptPermission = null;
    let currentDeptFlags = [];

    const permissionsForDept = permissionRoles.filter((role) => role.serviceDepartmentId === departmentId);

    if (permissionsForDept.length > 0) {
      // found some permissions for department.
      const servicePermissions = permissionsForDept.filter(
        (role) => role.permissionRole === UserPermissionRole.ServiceAdmin || UserPermissionRole.ServiceMember
      );

      if (servicePermissions.length > 0) {
        // check and see if there's any serviceIds in the role.
        const permissionWithIds = servicePermissions.filter((role) => !isEmpty(role.serviceId));

        if (permissionWithIds.length > 0) {
          currentDeptPermission = ServicePermissionType.CUSTOM;

          // Custom flags
          currentDeptFlags = permissionWithIds.map((permission) => ({
            serviceId: permission.serviceId,
            accessFlag: getServicePermission(permission.permissionRole, this.props.hasAllAccess)
          }));
        } else {
          // NOT Custom Flags.
          const hasServiceManager = some(servicePermissions, ['permissionRole', UserPermissionRole.ServiceAdmin]);
          const hasServiceMember = some(servicePermissions, ['permissionRole', UserPermissionRole.ServiceMember]);

          if (hasServiceManager) {
            currentDeptPermission = ServicePermissionType.MANAGER;
          } else if (hasServiceMember) {
            currentDeptPermission = ServicePermissionType.MEMBER;
          } else {
            currentDeptPermission = ServicePermissionType.NO_ACCESS;
          }
        }
      }
    } else {
      // no permissions for department.
      currentDeptPermission = ServicePermissionType.NO_ACCESS;
    }

    this.setState({ serviceModalOpen: true, currentDeptId: departmentId, currentDeptPermission, currentDeptFlags });
  };

  onCloseService = () => this.setState({ serviceModalOpen: false });

  // Triggered when Save Changes is clicked from Modal
  onSelectService = (result) => {
    const { permissions, onChangePermissions } = this.props;
    const { servicePermission, departmentId, customFlags } = result;

    // permissionRoles
    let updatedRoles = [];

    const filteredRoles = permissions.permissionRoles.filter(
      (role) =>
        (role.permissionRole !== UserPermissionRole.ServiceMember &&
          role.permissionRole !== UserPermissionRole.ServiceAdmin) ||
        role.serviceDepartmentId !== departmentId
    );

    if (servicePermission === ServicePermissionType.NO_ACCESS) {
      // no access; nothing to update.
      updatedRoles = [];
    } else if (servicePermission === ServicePermissionType.MEMBER) {
      // member
      const memberPermission: IPermissionRole = {
        serviceId: null,
        serviceDepartmentId: departmentId,
        permissionRole: UserPermissionRole.ServiceMember
      };

      updatedRoles = [memberPermission];
    } else if (servicePermission === ServicePermissionType.MANAGER) {
      // manager
      const managerPermission: IPermissionRole = {
        serviceId: null,
        serviceDepartmentId: departmentId,
        permissionRole: UserPermissionRole.ServiceAdmin
      };

      updatedRoles = [managerPermission];
    } else if (servicePermission === ServicePermissionType.CUSTOM) {
      // custom
      updatedRoles = map(customFlags, (flag) => ({
        permissionRole: getServiceRole(flag.accessFlag),
        serviceId: flag.serviceId,
        serviceDepartmentId: departmentId
      }));
    }

    const updatedPermissions: IPermissions = { ...permissions, permissionRoles: [...filteredRoles, ...updatedRoles] };

    onChangePermissions(updatedPermissions);
  };

  renderContent() {
    const { serviceDepartments, permissions } = this.props;

    return (
      <div className="flex-row" style={{ width: '90%' }}>
        <div className="mr-large pv-medium flex-column justify-start">
          <div className="mt-x-small">
            <Icon type={'apartment'} className="text-size-x2-large" />
          </div>
        </div>

        <div className="bordered-top flex-1 pv-medium align-start justify-between">
          <Text lineHeight={100} size="x-large">
            Service departments
          </Text>

          <div className="pl-large">
            {map(serviceDepartments, (department: IServiceDepartment) => (
              <DepartmentPermissionRow
                departmentName={department.serviceDepartmentName}
                departmentId={department.serviceDepartmentId}
                onClickModify={this.onOpenService}
                key={department.serviceDepartmentId}
                permissions={permissions}
                hasAllAccess={this.props.hasAllAccess}
                actionsDisabled={this.props.actionsDisabled}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {/* Edit Dialog Modal */}
        <ServicesSectionModal
          isOpen={this.state.serviceModalOpen}
          departmentCustomFlags={this.state.currentDeptFlags}
          departmentId={this.state.currentDeptId}
          setSelectedOption={this.onSelectService}
          onClose={this.onCloseService}
          servicePermission={this.state.currentDeptPermission}
        />

        {/* Render */}
        {this.renderContent()}
      </>
    );
  }
}
