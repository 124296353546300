import React, { Component } from 'react';
import { Divider, Empty } from 'antd';
import { Text } from 'common-components/typography';
import { PrimaryButton } from 'common-components/buttons';
import { Warning } from 'common-components/alerts';
import { FilterType } from 'utilities/enum-utils';
import { FilterSection } from 'common-components/filter';
import { IFilter } from 'interfaces/filter-interfaces';
import { BookingNoteItem } from 'views/group-services/components/booking-notes/BookingNoteItem';
import _ from 'lodash';
import { IGroupServiceNote } from 'interfaces/service-interfaces';
import AddEditGroupNoteModal from 'views/group-services/components/booking-notes/AddEditGroupNoteModal';
import { DeleteGroupNoteModal } from 'views/group-services/components/booking-notes/DeleteGroupNoteModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import SpinningLoader from 'common-components/loading/SpinningLoader';

//region Default parameters
const availableFilters = [FilterType.DATE_RANGE, FilterType.CUSTOMER, FilterType.IS_INCIDENT];

const defaultFilters: IFilter[] = [
  {
    filter: FilterType.CUSTOMER,
    values: [],
    selectionLabel: 'All'
  },
  {
    filter: FilterType.IS_INCIDENT,
    values: [],
    selectionLabel: 'All'
  }
];
//endregion

interface ICustomerNotesPanelProps {
  selectedSession: typeof state.groupServiceStore.selectedSession;
  groupServiceSessionNotes: typeof state.groupServiceStore.groupServiceSessionNotes;
  doFetchGroupServiceSessionNotes: typeof dispatch.groupServiceStore.doFetchGroupServiceSessionNotes;
  doRemoveGroupServiceSessionNote: typeof dispatch.groupServiceStore.doRemoveGroupServiceSessionNote;
}

interface ICustomerNotesPanelState {
  filters: IFilter[];
  isDeleteNoteOpen: boolean;
  isEditNoteOpen: boolean;
  isLoading: boolean;
  noteMode: 'add' | 'edit' | string;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  targetNote?: IGroupServiceNote;
}

class SessionCustomerNotesPanel extends Component<ICustomerNotesPanelProps, ICustomerNotesPanelState> {
  state = {
    filters: [...defaultFilters], // TODO replace with actual default filters
    isDeleteNoteOpen: false,
    isEditNoteOpen: false,
    isLoading: false,
    noteMode: 'add',
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    targetNote: null
  };

  //region Event handlers
  private _onChangeFilter = async (filters: Array<any>) => {
    this.setState({ filters });

    const newPage = 1;
    this.setState({ page: 1 });
    const payload = this._buildPayload(filters, newPage);
    await this._fetchContent(payload);
  };

  private _formatFilterQuery = (filters) => {
    const requestFilter: any = {};
    _.forEach(filters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'customerUserIds':
            requestFilter.customerUserIds = _.map(filter.values, (v) => v.value);
            break;
          case 'isIncident':
            requestFilter.isIncident = _.map(filter.values, (value) => {
              return value === 'YES';
            });
            break;
          default:
            break;
        }
      }
    });

    return requestFilter;
  };

  // Add note action handler
  onAddNote = () => {
    this.onOpenEditNoteModal({ noteMode: 'add', targetNote: null });
  };

  // Edit note action handler
  onEditNote = ({ targetNote }) => {
    this.onOpenEditNoteModal({ noteMode: 'edit', targetNote });
  };

  // Delete note action handler
  onDeleteNote = ({ targetNote }) => {
    this.onOpenDeleteNoteModal({ targetNote });
  };

  //region Action handlers

  // The following actions trigger whenever the action has been confirmed from the modal.

  onSaveNoteAction = () => {
    this.setState({ isEditNoteOpen: false });
    this._loadFreshNotes();
  };

  onDeleteNoteAction = ({ targetNote }) => {
    const { selectedSession } = this.props;
    const payload = {
      serviceId: selectedSession.serviceId,
      serviceDateTimeId: selectedSession.serviceDateTimeId,
      noteId: targetNote.noteId
    };
    this.props.doRemoveGroupServiceSessionNote(payload);
  };
  //endregion

  //endregion

  //region Button handlers
  onOpenEditNoteModal = ({ targetNote, noteMode }) => this.setState({ isEditNoteOpen: true, targetNote, noteMode });
  onCloseEditNoteModal = () => this.setState({ isEditNoteOpen: false, targetNote: null });

  onOpenDeleteNoteModal = ({ targetNote }) => this.setState({ isDeleteNoteOpen: true, targetNote });
  onCloseDeleteNoteModal = () => this.setState({ isDeleteNoteOpen: false });
  //endregion

  private _buildPayload = (filters, page) => {
    const { selectedSession } = this.props;
    const { pageSize } = this.state;
    const pageTimestamp = new Date();
    this.setState({ pageTimestamp });
    const requestFilter = filters ? this._formatFilterQuery(filters) : null;

    const payload = {
      serviceId: selectedSession.serviceId,
      serviceDateTimeId: selectedSession.serviceDateTimeId,
      page,
      pageSize,
      pageTimestamp
    };

    return requestFilter ? { ...payload, ...requestFilter } : payload;
  };

  private _loadFreshNotes = async () => {
    const newPage = 1;
    this.setState({ page: newPage });
    const { filters } = this.state;
    const payload = this._buildPayload(filters, newPage);
    await this._fetchContent(payload);
  };

  private _loadMoreNotes = async () => {
    const newPage = this.state.page + 1;
    const payload = this._buildPayload(this.state.filters, newPage);
    this.setState({ page: newPage });
    await this._fetchContent(payload);
  };

  private _fetchContent = async (payload) => {
    const { selectedSession, doFetchGroupServiceSessionNotes } = this.props;
    if (!selectedSession) return;

    this.setState({ isLoading: true });
    await doFetchGroupServiceSessionNotes(payload);
    this.setState({ isLoading: false });
  };

  componentDidMount = async () => {
    await this._loadFreshNotes();
  };

  render() {
    const { isLoading } = this.state;
    const { groupServiceSessionNotes, selectedSession } = this.props;
    const warnings = selectedSession ? selectedSession.numberOfIncidentNotes : 0;

    const NotesEmptyState = () => (
      <div className="flex-1 bg-white  align-center flex-column">
        <div className="">
          <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
        <Text size="x2-large" color="secondary" weight="bold">
          No customer notes found.
        </Text>
      </div>
    );

    return (
      <>
        {/* Main content section */}
        <div className="pv-medium pl-small">
          {/* Warnings, if any*/}
          {warnings > 0 && (
            <div className="mb-large">
              <Warning
                displayType="block"
                content={
                  <Text>
                    There {warnings === 1 ? 'is' : 'are'} <b>{warnings}</b> incident{warnings === 1 ? '' : 's'} reported
                    during this session.
                  </Text>
                }
              />
            </div>
          )}

          {/* Content container  */}
          <div className="ph-large pt-large pb-x2-large rounded-big shadow-container bg-white flex-1">
            {/* Header section */}
            <section className="line-height-120 flex-row justify-between align-center">
              {/* Title */}
              <div>
                <div className="mb-x-small">
                  <Text lineHeight={120} weight="bold">
                    Notes
                  </Text>
                </div>

                <Text lineHeight={120}>Customer notes for this session</Text>
              </div>

              {/* Action buttons */}
              <div>
                <PrimaryButton icon="plus" size="large" onClick={this.onAddNote}>
                  Add note
                </PrimaryButton>
              </div>
            </section>

            <Divider className="mt-large mb-none" />

            {/* Filters*/}
            <section className="">
              <FilterSection
                availableFilters={availableFilters}
                filters={this.state.filters}
                onChangeFilter={this._onChangeFilter}
                displayTimezone={''}
              />
            </section>

            {isLoading ? (
              <SpinningLoader size={150} message={'Loading...'} />
            ) : _.isEmpty(groupServiceSessionNotes) ? (
              <NotesEmptyState />
            ) : (
              <section className="bg-quaternary bordered border-standard-gray ph-medium pt-medium">
                <InfiniteScrollLoading
                  hasMore={groupServiceSessionNotes.length >= this.state.page * this.state.pageSize}
                  loadingElementId={'content-container'}
                  loadMore={this._loadMoreNotes}
                  loaderColSpan={7}
                  loadingOffSet={60}
                >
                  {_.map(groupServiceSessionNotes, (note) => (
                    <BookingNoteItem
                      onEditNote={() => this.onEditNote({ targetNote: note })}
                      onDeleteNote={() => this.onDeleteNote({ targetNote: note })}
                      note={note}
                    />
                  ))}
                </InfiniteScrollLoading>
              </section>
            )}
          </div>
        </div>

        {/* Action modals */}

        {/* Add / edit note modal */}
        <AddEditGroupNoteModal
          isOpen={this.state.isEditNoteOpen}
          onClose={this.onCloseEditNoteModal}
          onSaveNoteAction={this.onSaveNoteAction}
          noteMode={this.state.noteMode}
          targetNote={this.state.targetNote}
          isAllowSelecteCustomer={true}
          selectedSession={this.props.selectedSession}
        />

        {/* Delete note modal  */}
        <DeleteGroupNoteModal
          isOpen={this.state.isDeleteNoteOpen}
          targetNote={this.state.targetNote}
          onClose={this.onCloseDeleteNoteModal}
          onDeleteNoteAction={this.onDeleteNoteAction}
        />
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceSessionNotes: state.groupServiceStore.groupServiceSessionNotes
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupServiceSessionNotes: dispatch.groupServiceStore.doFetchGroupServiceSessionNotes,
  doRemoveGroupServiceSessionNote: dispatch.groupServiceStore.doRemoveGroupServiceSessionNote
});

export default connect(
  mapState,
  mapDispatch
)(SessionCustomerNotesPanel);
