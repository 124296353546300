import React, { HTMLProps, PureComponent, ReactNode } from 'react';
import { Button, Icon } from 'antd';
import { PORTAL_COLORS } from 'src/interfaces/common-ui-types';

declare type ALERT_TYPE = 'success' | 'info' | 'warning' | 'error';

interface AlertTagProps extends HTMLProps<any> {
  bgColor?: PORTAL_COLORS;
  textColor?: PORTAL_COLORS;
  type?: ALERT_TYPE | string;
  icon?: string;
  text: string | ReactNode;
  minWidth?: number | string;
  maxWidth?: number | string;
  onClickView?: () => void;
  onClickIgnore?: () => void;
}

interface AlertTagState {
  bgColor?: PORTAL_COLORS;
  textColor?: PORTAL_COLORS;
  type?: ALERT_TYPE;
  icon?: string;
  text: string | ReactNode;
  minWidth?: number | string;
  maxWidth?: number | string;
  onClickView?: () => void;
  onClickIgnore?: () => void;
}

export default class AlertTag extends PureComponent<AlertTagProps, AlertTagState> {
  constructor(props: AlertTagProps) {
    super(props);
    this.state = {
      bgColor: 'red',
      textColor: 'white',
      type: 'error',
      icon: 'close-circle',
      text: 'Alert Tag'
    };
  }

  private _generateDefaultColor(props) {
    const { type, bgColor, textColor } = props;

    if (bgColor) {
      return {
        bgColor,
        textColor: textColor ? textColor : 'white'
      };
    }
    if (type === 'info') {
      return {
        bgColor: 'blue',
        textColor: 'white'
      };
    }
    if (type === 'success') {
      return {
        bgColor: 'green',
        textColor: 'white'
      };
    }
    if (type === 'warning') {
      return {
        bgColor: 'warning-orange-solid',
        textColor: 'white'
      };
    }
    // default error
    return {
      bgColor: 'red',
      textColor: 'white'
    };
  }

  render() {
    const { text, icon, minWidth, maxWidth, className, onClickView, onClickIgnore, ...props } = this.props;
    const { bgColor, textColor } = this._generateDefaultColor(this.props);

    return (
      <span
        className={`pv-x-small flex align-center ph-medium rounded-big bg-${bgColor} text-color-${textColor} ${className}`}
        style={{
          display: 'inline-block',
          width: 'fit-content',
          minWidth: minWidth ? minWidth : '200px',
          maxWidth: maxWidth ? maxWidth : 'auto'
        }}
        {...props}
      >
        <Icon
          className="mr-small cursor-pointer"
          type={icon ? icon : `close-circle`}
          theme="filled"
          onClick={onClickIgnore}
        />
        <span className="text-overflow-ellipsis">{text}</span>
        <Button
          className={`pr-none hover-text-color-lighter text-color-${textColor}`}
          onClick={onClickView}
          type="link"
          ghost
        >
          View details...
        </Button>
      </span>
    );
  }
}
