import React from 'react';

import _ from 'lodash';
import { Avatar, Icon } from 'antd';

import { Text } from 'common-components/typography';

import { DayColumn } from 'common-components/roster-control/roster-weekly/common/DayColumn';
import { ROSTER_WEEKLY_CONFIG } from 'common-components/roster-control/roster-weekly/roster-weekly-config';

const { CONTENT_PANEL_WIDTH } = ROSTER_WEEKLY_CONFIG;

const EMPTY_ROW_COUNT = 1;

interface EmptyWeeklyRowsProps {
  type?: 'customer' | 'worker' | string;
  numberOfRows?: number;
  isFetching?: boolean;
}

export function EmptyWeeklyRows({
  type = 'customer',
  numberOfRows = EMPTY_ROW_COUNT,
  isFetching = false
}: EmptyWeeklyRowsProps) {
  const typeLabel = type === 'customer' ? 'customers' : 'team members';

  return (
    <>
      {_.times(numberOfRows, (idx) => (
        <div className="flex-row" key={idx}>
          <div
            className="flex-row pv-large pl-x-large pr-medium bg-tertiary justify-between bordered-right bordered-bottom"
            style={{
              minWidth: `${CONTENT_PANEL_WIDTH}px`,
              maxWidth: `${CONTENT_PANEL_WIDTH}px`
            }}
          >
            <div className="flex-row flex-1 align-center">
              <Avatar shape={type === 'customer' ? 'circle' : 'square'} size="large" icon="user" className="mr-small" />
              <Text size="regular" color="tertiary">
                {!isFetching && `No ${typeLabel}`}
                {isFetching && `Loading ${typeLabel}`}
                {isFetching && <Icon className="ml-small text-color-blue-action" type="loading" />}
              </Text>
            </div>
          </div>

          <div className="flex-row flex-1 position-relative">
            {_.times(7, (idx) => (
              <DayColumn content={[]} key={idx} backgroundColor={'tertiary'} />
            ))}
          </div>
        </div>
      ))}
    </>
  );
}
