import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Avatar } from 'antd';
import { getShiftSlotTheme } from 'common-components/roster-control/common/roster-utilities';
import { IRosterShift } from 'common-components/roster-control/interfaces/roster-interfaces';
import { ROSTER_WEEKLY_CONFIG } from 'common-components/roster-control/roster-weekly/roster-weekly-config';
import AssignedShiftPopover from 'common-components/roster-control/roster-weekly/workers/AssignedShiftPopover';
import { BookingTypeIcon } from 'common-components/tags';
import ShiftSlotStatusTag from 'common-components/tags/ShiftSlotStatusTag';
import { Text } from 'common-components/typography';
import * as H from 'history';
import moment from 'moment';
import React, { useState } from 'react';
import CommonUtils from 'utilities/common-utils';
import { BookingType, ServiceType } from 'utilities/enum-utils';

const { ITEM_CARD_HEIGHT } = ROSTER_WEEKLY_CONFIG;

export function AssignedShiftCard({
  shift,
  refreshData,
  history
}: {
  shift: IRosterShift;
  refreshData: () => void;
  history: H.History;
}) {
  const { shiftStartDateTime, shiftEndDateTime, shiftSlotStatus, serviceName } = shift;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [canClosePopover, setCanClosePopover] = useState<boolean>(true);

  const mStartDate = moment(shiftStartDateTime);
  const mEndDate = moment(shiftEndDateTime);

  const startDateDisplay = mStartDate.format('h:mm a');
  const endDateDisplay = mEndDate.format('h:mm a');

  const { bgColor, borderColor } = getShiftSlotTheme(shiftSlotStatus);

  const refreshAndCloseData = () => {
    setCanClosePopover(true);
    setIsOpen(false);
    refreshData();
  };

  const openClosePopover = (nextOpenState) => {
    if (canClosePopover) {
      setIsOpen(nextOpenState);
    }
  };

  return (
    <div className="p-x-small cursor-pointer" style={{ width: '100%' }}>
      <Popover2
        isOpen={isOpen}
        content={
          <AssignedShiftPopover
            shift={shift}
            history={history}
            setCanClosePopover={setCanClosePopover}
            refreshAndCloseData={refreshAndCloseData}
          />
        }
        targetTagName={'div'}
        position="right-bottom"
        onInteraction={(nextOpenState) => openClosePopover(nextOpenState)}
      >
        <div
          className={`rounded-big p-small line-height-120 anim-fade-in ${bgColor} ${borderColor}`}
          style={{ width: '100%', minHeight: `${ITEM_CARD_HEIGHT}px` }}
        >
          <div className={'flex-row justify-between align-center '}>
            <ShiftSlotStatusTag status={shiftSlotStatus} size={'small'} />
            {shift.serviceType === ServiceType.INDIVIDUAL && shift.bookingId && (
              <div className={'ml-small'}>
                <Tooltip2
                  content={<Text color={'white'}>{shift.customerFirstName + ' ' + shift.customerLastName}</Text>}
                  position={'bottom-right'}
                >
                  <div className={'flex-row align-center'}>
                    <Avatar
                      size={20}
                      className={'text-white'}
                      style={{
                        backgroundColor: CommonUtils.getBackgroundColor(
                          shift.customerFirstName + shift.customerLastName
                        )
                      }}
                      shape="circle"
                    >
                      {shift.customerFirstName && shift.customerFirstName[0].toUpperCase()}{' '}
                      {shift.customerLastName && shift.customerLastName[0].toUpperCase()}
                    </Avatar>
                  </div>
                </Tooltip2>
              </div>
            )}
          </div>

          <div className="whitespace-nowrap overflow-hidden text-overflow-ellipsis" style={{ width: '100%' }}>
            <Text size="regular" lineHeight={120}>
              <b>{startDateDisplay}</b> - {endDateDisplay}
            </Text>
            <br />
            <div className={'flex-row align-center'}>
              <div style={{ minWidth: '16px' }}>
                <BookingTypeIcon
                  type={
                    shift.bookingType === BookingType.ACTIVITY_RECORD ? BookingType.ACTIVITY_RECORD : shift.serviceType
                  }
                  className={'mr-x-small'}
                />
              </div>
              <Text size="regular" lineHeight={120}>
                {serviceName}
              </Text>
            </div>
          </div>
        </div>
      </Popover2>
    </div>
  );
}
