import { Col, Icon, Row } from 'antd';
import { Paragraph, Text, Title } from 'common-components/typography';
import * as H from 'history';
import CenteredLayout from 'layouts/CenteredLayout';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TimeBasedAlertConfigType } from 'utilities/enum-utils';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import TimeBasedAlertsConfigurationModal from 'views/account-management/components/TimeBasedAlertsConfigurationModal';

interface ITimeBasedAlertsConfigureViewProps {
  doFetchTimeBasedAlertConfiguration: typeof dispatch.accountStore.doFetchTimeBasedAlertConfiguration;
  portalUser: typeof state.authStore.portalUser;
  timeBasedAlertConfigure: typeof state.accountStore.timeBasedAlertConfigure;
  history: H.History;
}

interface ITimeBasedAlertsConfigureViewState {
  isLoading: boolean;
  isEditTimeBasedAlertsConfigOpen: boolean;
  configType: TimeBasedAlertConfigType;
}

class TimeBasedAlertsConfigureView extends Component<
  ITimeBasedAlertsConfigureViewProps,
  ITimeBasedAlertsConfigureViewState
> {
  state = {
    isLoading: true,
    isEditTimeBasedAlertsConfigOpen: false,
    configType: null
  };

  private _goToLandingPage = () => {
    this.props.history.push('/account/landing');
  };

  private _goToFeatureConfiguration = () => {
    this.props.history.push('/account/features');
  };

  private _openEditTimeBasedConfig = () => {
    this.setState({ isEditTimeBasedAlertsConfigOpen: true });
  };

  private _closeEditTimeBasedConfig = async () => {
    await this.props.doFetchTimeBasedAlertConfiguration({});
    this.setState({ isEditTimeBasedAlertsConfigOpen: false });
  };

  componentDidMount = async () => {
    await this.props.doFetchTimeBasedAlertConfiguration({});
    this.setState({ isLoading: false });
  };

  render() {
    const { timeBasedAlertConfigure } = this.props;
    const documentExpiryDaysConfig = timeBasedAlertConfigure.alertConfiguration.documentExpiry;
    const serviceAgreementExpiryDaysConfig = timeBasedAlertConfigure.alertConfiguration.serviceAgreementExpiry;

    return (
      <CenteredLayout backgroundColor="white" bordered={true} className={'ph-small'}>
        {this.state.isEditTimeBasedAlertsConfigOpen && (
          <TimeBasedAlertsConfigurationModal
            isOpen={this.state.isEditTimeBasedAlertsConfigOpen}
            onClose={this._closeEditTimeBasedConfig}
            configType={this.state.configType}
            dayConfigured={
              this.state.configType === TimeBasedAlertConfigType.DOCUMENT_EXPIRY
                ? documentExpiryDaysConfig
                : serviceAgreementExpiryDaysConfig
            }
          />
        )}
        <div className={'pb-x4-large'}>
          <div className="mt-x-large mb-medium">
            <Title level={3} weight="regular" className="mv-none">
              <span className={'cursor-pointer text-color-blue-action'} onClick={this._goToLandingPage}>
                Account {'>'}
              </span>{' '}
              <span className={'cursor-pointer text-color-blue-action'} onClick={this._goToFeatureConfiguration}>
                Feature Configuration {'>'}
              </span>{' '}
              Time based alerts
            </Title>
          </div>
          <Paragraph>
            Configure features for your Workspace. Any changes to features below will take effect for your entire
            organisation’s Workspace settings and behaviour.
          </Paragraph>
          <div className="mt-x-large bordered-bottom border-standard-gray width-2/3">
            <Row
              className={'bordered-top border-standard-gray pv-medium cursor-pointer hover-bg-quaternary'}
              gutter={24}
              onClick={() => {
                this.setState({ configType: TimeBasedAlertConfigType.DOCUMENT_EXPIRY });
                this._openEditTimeBasedConfig();
              }}
            >
              <Col span={18}>
                <div className={'mb-small'}>
                  <Text weight={'bold'}>Document expiry</Text>
                </div>
                <Text color={'secondary'}>{documentExpiryDaysConfig} days</Text>
              </Col>
              <Col span={6} className={'text-align-right'}>
                <Text>
                  <Text>Edit</Text>
                  <Icon type={'right'} className={'ml-medium'} />
                </Text>
              </Col>
            </Row>
            <Row
              className={'bordered-top border-standard-gray pv-medium cursor-pointer hover-bg-quaternary'}
              gutter={24}
              onClick={() => {
                this.setState({ configType: TimeBasedAlertConfigType.SERVICE_AGREEMENT_EXPIRY });
                this._openEditTimeBasedConfig();
              }}
            >
              <Col span={18}>
                <div className={'mb-small'}>
                  <Text weight={'bold'}>Service agreement expiry</Text>
                </div>
                <Text color={'secondary'}>{serviceAgreementExpiryDaysConfig} days</Text>
              </Col>
              <Col span={6} className={'text-align-right'}>
                <Text>
                  <Text>Edit</Text>
                  <Icon type={'right'} className={'ml-medium'} />
                </Text>
              </Col>
            </Row>
          </div>
        </div>
      </CenteredLayout>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyFundingAndPayment: state.companyStore.companyFundingAndPayment,
  portalUser: state.authStore.portalUser,
  timeBasedAlertConfigure: state.accountStore.timeBasedAlertConfigure
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchTimeBasedAlertConfiguration: dispatch.accountStore.doFetchTimeBasedAlertConfiguration
});

export default connect(
  mapState,
  mapDispatch
)(TimeBasedAlertsConfigureView);
