import React, { Component } from 'react';

import { Avatar, Divider, Tabs } from 'antd';

import { ITransportBookingDetails } from 'views/group-services/manage-booking-modal/interfaces/group-booking-interfaces';
import { FieldLabel, Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import { BookingStatusSteps } from 'views/group-services/manage-booking-modal/common-components/BookingStatusSteps';

import _ from 'lodash';
import { BookingStatus, TransportAttendanceType } from 'utilities/enum-utils';
import InfoPanel from 'common-components/alerts/InfoPanel';
import ScheduledDetailsTabSection from 'views/group-services/manage-booking-modal/panels/transport/ScheduledDetailsTabSection';
import ActualDetailsTabSection from 'views/group-services/manage-booking-modal/panels/transport/ActualDetailsTabSection';
import { BookingActionButtons } from '../../common-components/BookingActionButtons';
import StartGroupTransportBookingModal from '../../common-components/StartTransportBookingModal';
import FinishTransportBookingModal from '../../common-components/FinishTransportBookingModal';
import RevertTransportBookingToConfirmModal from '../../common-components/RevertTransportBookingToConfirmModal';
import CancelTransportBookingModal from '../../common-components/CancelTransportBookingModal';
import ChangeTransportBookingToCustomerCancellationModal from '../../common-components/ChangeTransportBookingToCustomerCancellationModal';
import ReinstateTransportBookingModal from '../../common-components/ReinstateTransportBookingModal';
import WaiveTransportBookingModal from '../../common-components/WaiveTransportBookingModal';
import ApproveTransportBookingModal from '../../common-components/ApproveTransportBookingModal';
import RevokeApprovalTransportBookingModal from '../../common-components/RevokeApprovalTransportBookingModal';
import ChargeTransportBookingCancellationFeeModal from '../../common-components/ChargeTransportBookingCancellationFeeModal';
import RemoveTransportBookingCancellationChargeModal from '../../common-components/RemoveTransportBookingCancellationChargeModal';
import ChangeTransportBookingToBusinessCancelModal from '../../common-components/ChangeTransportBookingToBusinessCancelModal';
import RemoveTransportBookingWorkerModal from '../../common-components/RemoveTransportBookingWorkerModal';
import AssignTeamMemberModal from './AssignTeamMemberModal';

interface ITransportBookingDetailsPanelProps {
  transportDetails: ITransportBookingDetails;
  transportType: 'before' | 'after' | string;
  onEditDetails: any;
  onEditTravel: any;
}

interface ITransportBookingDetailsPanelState {
  isOpenStart: boolean;
  isOpenFinish: boolean;
  isOpenRevert: boolean;
  isOpenCancel: boolean;
  isOpenCustomerCancel: boolean;
  isOpenReinstate: boolean;
  isOpenWaive: boolean;
  isOpenApprove: boolean;
  isOpenRevoke: boolean;
  isOpenChargeCancel: boolean;
  isOpenRemoveCharge: boolean;
  isOpenBusinessCancel: boolean;
  isOpenRemoveTeamMember: boolean;
  isOpenAssignWorker: boolean;
}

class TransportBookingDetailsPanel extends Component<
  ITransportBookingDetailsPanelProps,
  ITransportBookingDetailsPanelState
> {
  state = {
    isOpenStart: false,
    isOpenFinish: false,
    isOpenRevert: false,
    isOpenCancel: false,
    isOpenCustomerCancel: false,
    isOpenReinstate: false,
    isOpenWaive: false,
    isOpenApprove: false,
    isOpenRevoke: false,
    isOpenChargeCancel: false,
    isOpenRemoveCharge: false,
    isOpenBusinessCancel: false,
    isOpenRemoveTeamMember: false,
    isOpenAssignWorker: false
  };

  private _onOpenStart = () => {
    this.setState({ isOpenStart: true });
  };

  private _onCloseStart = () => {
    this.setState({ isOpenStart: false });
  };

  private _onOpenFinish = () => {
    this.setState({ isOpenFinish: true });
  };

  private _onCloseFinish = () => {
    this.setState({ isOpenFinish: false });
  };

  private _onOpenRevert = () => {
    this.setState({ isOpenRevert: true });
  };

  private _onCloseRevert = () => {
    this.setState({ isOpenRevert: false });
  };

  private _onOpenCancel = () => {
    this.setState({ isOpenCancel: true });
  };

  private _onCloseCancel = () => {
    this.setState({ isOpenCancel: false });
  };

  private _onOpenCustomerCancel = () => {
    this.setState({ isOpenCustomerCancel: true });
  };

  private _onCloseCustomerCancel = () => {
    this.setState({ isOpenCustomerCancel: false });
  };

  private _onOpenReinstate = () => {
    this.setState({ isOpenReinstate: true });
  };

  private _onCloseReinstate = () => {
    this.setState({ isOpenReinstate: false });
  };

  private _onOpenWaive = () => {
    this.setState({ isOpenWaive: true });
  };

  private _onCloseWaive = () => {
    this.setState({ isOpenWaive: false });
  };

  private _onOpenApprove = () => {
    this.setState({ isOpenApprove: true });
  };

  private _onCloseApprove = () => {
    this.setState({ isOpenApprove: false });
  };

  private _onOpenRevokeApproval = () => {
    this.setState({ isOpenRevoke: true });
  };

  private _onCloseRevokeApproval = () => {
    this.setState({ isOpenRevoke: false });
  };

  private _onOpenChargeCancellation = () => {
    this.setState({ isOpenChargeCancel: true });
  };

  private _onCloseChargeCancellation = () => {
    this.setState({ isOpenChargeCancel: false });
  };

  private _onOpenRemoveCancellationCharge = () => {
    this.setState({ isOpenRemoveCharge: true });
  };

  private _onCloseRemoveCancellationCharge = () => {
    this.setState({ isOpenRemoveCharge: false });
  };

  private _onOpenBusinessCancel = () => {
    this.setState({ isOpenBusinessCancel: true });
  };

  private _onCloseBusinessCancel = () => {
    this.setState({ isOpenBusinessCancel: false });
  };

  private _onOpenRemoveTeamMember = () => {
    this.setState({ isOpenRemoveTeamMember: true });
  };

  private _onCloseRemoveTeamMember = () => {
    this.setState({ isOpenRemoveTeamMember: false });
  };

  private _onOpenAssignWorker = () => {
    this.setState({ isOpenAssignWorker: true });
  };

  private _onCloseAssignWorker = () => {
    this.setState({ isOpenAssignWorker: false });
  };

  private _onSelectWorker = () => {};

  render() {
    let { transportDetails, transportType, onEditDetails, onEditTravel } = this.props;

    const { status, supportWorkerId, attachmentUrl, firstName, lastName } = transportDetails;

    const transportLabel = transportType === 'before' ? 'Transport Before' : 'after' ? 'Transport After' : '';

    const isInProgressOrComplete = _.includes([BookingStatus.INPROGRESS, BookingStatus.COMPLETED], status);

    return (
      <div className="ph-large pb-large pt-small rounded-big bordered border-standard-gray line-height-120">
        <StartGroupTransportBookingModal
          booking={transportDetails}
          onClose={this._onCloseStart}
          isOpen={this.state.isOpenStart}
          attendanceType={
            transportType === 'before' ? TransportAttendanceType.PRE_SESSION : TransportAttendanceType.POST_SESSION
          }
        />

        <FinishTransportBookingModal
          isOpen={this.state.isOpenFinish}
          onClose={this._onCloseFinish}
          booking={transportDetails}
          attendanceType={
            transportType === 'before' ? TransportAttendanceType.PRE_SESSION : TransportAttendanceType.POST_SESSION
          }
        />

        <RevertTransportBookingToConfirmModal
          booking={transportDetails}
          onClose={this._onCloseRevert}
          attendanceType={
            transportType === 'before' ? TransportAttendanceType.PRE_SESSION : TransportAttendanceType.POST_SESSION
          }
          isOpen={this.state.isOpenRevert}
        />

        <CancelTransportBookingModal
          booking={transportDetails}
          onClose={this._onCloseCancel}
          bookingType={
            transportType === 'before' ? TransportAttendanceType.PRE_SESSION : TransportAttendanceType.POST_SESSION
          }
          isOpen={this.state.isOpenCancel}
        />

        <ChangeTransportBookingToCustomerCancellationModal
          transportBooking={transportDetails}
          onClose={this._onCloseCustomerCancel}
          isOpen={this.state.isOpenCustomerCancel}
          attendanceType={
            transportType === 'before' ? TransportAttendanceType.PRE_SESSION : TransportAttendanceType.POST_SESSION
          }
        />

        <ReinstateTransportBookingModal
          transportBooking={transportDetails}
          onClose={this._onCloseReinstate}
          isOpen={this.state.isOpenReinstate}
          attendanceType={
            transportType === 'before' ? TransportAttendanceType.PRE_SESSION : TransportAttendanceType.POST_SESSION
          }
        />

        <WaiveTransportBookingModal
          booking={transportDetails}
          onClose={this._onCloseWaive}
          isOpen={this.state.isOpenWaive}
          attendanceType={
            transportType === 'before' ? TransportAttendanceType.PRE_SESSION : TransportAttendanceType.POST_SESSION
          }
        />

        <ApproveTransportBookingModal
          transportBooking={transportDetails}
          onClose={this._onCloseApprove}
          isOpen={this.state.isOpenApprove}
          attendanceType={
            transportType === 'before' ? TransportAttendanceType.PRE_SESSION : TransportAttendanceType.POST_SESSION
          }
        />

        <RevokeApprovalTransportBookingModal
          transportBooking={transportDetails}
          onClose={this._onCloseRevokeApproval}
          isOpen={this.state.isOpenRevoke}
          attendanceType={
            transportType === 'before' ? TransportAttendanceType.PRE_SESSION : TransportAttendanceType.POST_SESSION
          }
        />

        <ChargeTransportBookingCancellationFeeModal
          transportBooking={transportDetails}
          onClose={this._onCloseChargeCancellation}
          isOpen={this.state.isOpenChargeCancel}
          attendanceType={
            transportType === 'before' ? TransportAttendanceType.PRE_SESSION : TransportAttendanceType.POST_SESSION
          }
        />

        <RemoveTransportBookingCancellationChargeModal
          transportBooking={transportDetails}
          onClose={this._onCloseRemoveCancellationCharge}
          isOpen={this.state.isOpenRemoveCharge}
          attendanceType={
            transportType === 'before' ? TransportAttendanceType.PRE_SESSION : TransportAttendanceType.POST_SESSION
          }
        />

        <ChangeTransportBookingToBusinessCancelModal
          transportBooking={transportDetails}
          onClose={this._onCloseBusinessCancel}
          isOpen={this.state.isOpenBusinessCancel}
          attendanceType={
            transportType === 'before' ? TransportAttendanceType.PRE_SESSION : TransportAttendanceType.POST_SESSION
          }
        />

        <RemoveTransportBookingWorkerModal
          booking={transportDetails}
          onClose={this._onCloseRemoveTeamMember}
          isOpen={this.state.isOpenRemoveTeamMember}
          attendanceType={
            transportType === 'before' ? TransportAttendanceType.PRE_SESSION : TransportAttendanceType.POST_SESSION
          }
        />

        <AssignTeamMemberModal
          isOpen={this.state.isOpenAssignWorker}
          onClose={this._onCloseAssignWorker}
          onSelectWorker={this._onSelectWorker}
          transportBooking={transportDetails}
          modelType={'assign'}
          attendanceType={
            transportType === 'before' ? TransportAttendanceType.PRE_SESSION : TransportAttendanceType.POST_SESSION
          }
        />

        <Tabs>
          <Tabs.TabPane key="scheduled-details" tab={'Scheduled details'}>
            <ScheduledDetailsTabSection
              onEditDetails={onEditDetails}
              transportLabel={transportLabel}
              transportDetails={transportDetails}
              transportType={transportType}
            />
          </Tabs.TabPane>

          <Tabs.TabPane key="actual-details" tab="Actual details">
            {!isInProgressOrComplete && (
              <div className="mt-small mb-large">
                <InfoPanel
                  text={
                    'Actual details for this transport arrangement will appear here when the booking is in progress or completed. '
                  }
                />
              </div>
            )}
            {isInProgressOrComplete && (
              <ActualDetailsTabSection
                onEditDetails={onEditTravel}
                transportDetails={transportDetails}
                transportType={transportType}
              />
            )}
          </Tabs.TabPane>
        </Tabs>

        <Divider className="divider-large" />

        <section className="">
          <div className="flex-1 mb-medium">
            <FieldLabel text="ASSIGNED TO" />
            {supportWorkerId ? (
              <div className="flex-row align-center">
                <Avatar src={attachmentUrl} icon="user" className="mr-small" />
                <Text>{`${firstName} ${lastName}`}</Text>
              </div>
            ) : (
              <Text color="secondary">Not assigned</Text>
            )}
          </div>

          {!isInProgressOrComplete && (
            <div className="flex-row">
              {supportWorkerId ? (
                <HyperlinkButton color={'red'} onClick={this._onOpenRemoveTeamMember}>
                  Remove team member...
                </HyperlinkButton>
              ) : (
                <HyperlinkButton onClick={this._onOpenAssignWorker}>Assign team member...</HyperlinkButton>
              )}
            </div>
          )}
        </section>

        <Divider className="divider-large" />

        {/* Booking status */}
        <section className="mb-large">
          <div className="mr-medium flex-1">
            <FieldLabel text="BOOKING STATUS" />
            <BookingStatusSteps bookingStatus={status} />
          </div>
        </section>

        {/* Booking actions  */}
        <section>
          <BookingActionButtons
            booking={transportDetails}
            onAcceptBooking={() => {}}
            onConfirmBooking={() => {}}
            onDeclineBooking={() => {}}
            onStartBooking={this._onOpenStart}
            onFinishBooking={this._onOpenFinish}
            onCancelBooking={this._onOpenCancel}
            onWaiveBooking={this._onOpenWaive}
            onChangeToCustomerCancel={this._onOpenCustomerCancel}
            onReinstateBooking={this._onOpenReinstate}
            onRevertToConfirm={this._onOpenRevert}
            onApprove={this._onOpenApprove}
            onRevokePayment={this._onOpenRevokeApproval}
            onChargeCancellationFee={this._onOpenChargeCancellation}
            onRemoveCancelCharge={this._onOpenRemoveCancellationCharge}
            onBusinessCancel={this._onOpenBusinessCancel}
            hasBillingErrors={false}
          />
        </section>
      </div>
    );
  }
}

export default TransportBookingDetailsPanel;
