import { Card, ProgressBar } from '@blueprintjs/core';
import React, { Component } from 'react';
import { Row, Col, Input, Icon, Skeleton } from 'antd';
import { FieldLabel, Paragraph, Text, Title } from 'common-components/typography/index';
import { PrimaryButton } from '../../../../../common-components/buttons/index';
import InfiniteScrollLoading from '../../../../../common-components/loading/InfiniteScrollLoading';
import PinnedAlertItem from '../../../../../common-components/pinned-alerts/PinnedAlertItem';
import { INewActivityRecordData } from '../../../../../interfaces/booking-interfaces';
import { ICustomerListItem } from '../../../../../interfaces/customer-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../../stores/rematch/root-store';
import SelectedCustomerRow from '../../../components/SelectedCustomerRow';
import { connect } from 'react-redux';
import _ from 'lodash';
import CustomerItemRow from '../CustomerItemRow';
import { ServiceType } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
const { Search } = Input;

interface IActivityRecordStep1SelectCustomerProps {
  newActivityRecordData: INewActivityRecordData;
  setNewActivityRecordData: typeof dispatch.bookingsStore.setNewActivityRecordData;
  doGetCustomer: typeof dispatch.customersStore.doGetCustomer;
  setCustomerFilterLite: typeof dispatch.customersStore.setCustomerFilterLite;
  doGetCustomersLite: typeof dispatch.customersStore.doGetCustomersLite;
  doFetchServicesLite: typeof dispatch.servicesStore.doFetchServicesLite;
  portalUser: typeof state.authStore.portalUser;
  customers: ICustomerListItem[];
  onNextStep: () => void;
}

interface IActivityRecordStep1SelectCustomerState {
  searchCustomer: string;
  isSearching: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  isLoading: boolean;
  isLoadingInfiniteScrolling: boolean;
}

const defaultActivityRecord: INewActivityRecordData = {
  selectedCustomer: null,
  selectedCustomerId: '',
  selectedService: null,
  selectedServiceId: '',
  selectedWorker: null,
  selectedWorkerId: '',
  bookingTimeSelection: null,
  bookingStartDate: null,
  bookingEndDate: null,
  bookingDate: null,
  durationHours: null,
  durationMinutes: null,
  bookLocation: null,
  paymentMethod: '',
  selectedBillingItems: [],
  note: null,
  attachments: [],
  manuallyAddedAddress: null,
  timeSlots: [],
  paymentSourceType: null,
  isDuration: false,
  isShiftSlotSentToTimesheet: false,
  isApproved: false,
  bookingId: '',
  bookingRequestId: '',
  referenceId: ''
};

class ActivityRecordStep1SelectCustomer extends Component<
  IActivityRecordStep1SelectCustomerProps,
  IActivityRecordStep1SelectCustomerState
> {
  state = {
    searchCustomer: '',
    isSearching: false,
    page: 1,
    pageSize: 10,
    pageTimestamp: new Date(),
    isLoading: true,
    isLoadingInfiniteScrolling: false
  };

  private _searchCustomer = async () => {
    let payload = { search: this.state.searchCustomer, sort: [['firstName', 'asc'], ['lastName', 'asc']] };
    const { doGetCustomersLite, setCustomerFilterLite } = this.props;
    this.setState({ isSearching: true, page: 1 });
    setCustomerFilterLite(payload);
    await doGetCustomersLite({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      sortByRelevance: true
    });
    this.setState({ isSearching: false });
  };

  debounceSearch = _.debounce(this._searchCustomer, 250);

  private _handleSearchCustomerName = (event) => {
    let searchText = event.target.value;
    this.setState({ searchCustomer: searchText, isSearching: true });
    if (_.isEmpty(searchText)) {
      this._loadContent();
    } else if (searchText.length >= 3) {
      this.debounceSearch();
    }
  };

  private _removeSelectedCustomer = async () => {
    const { setNewActivityRecordData, newActivityRecordData } = this.props;

    setNewActivityRecordData({ ...newActivityRecordData, selectedCustomerId: '', selectedCustomer: null });
  };

  private _fetchMoreCustomers = async () => {
    const { doGetCustomersLite } = this.props;
    await this.setState({ isLoadingInfiniteScrolling: true, page: this.state.page + 1 });
    await doGetCustomersLite({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp
    });
    this.setState({ isLoadingInfiniteScrolling: false });
  };

  private _loadContent = async () => {
    const { doGetCustomersLite, doFetchServicesLite, setCustomerFilterLite } = this.props;
    try {
      // TODO: Optimize the call later.
      setCustomerFilterLite({});
      await Promise.all([
        doFetchServicesLite({ serviceType: ServiceType.INDIVIDUAL }),
        doGetCustomersLite({
          page: this.state.page,
          pageSize: this.state.pageSize,
          pageTimestamp: this.state.pageTimestamp
        })
      ]);

      this.setState({ isLoading: false, isSearching: false });
    } catch (e) {
      this.setState({ isLoading: false, isSearching: false });
      throw e;
    }
  };

  private _setSelectedCustomerId = async (selectedCustomerId) => {
    const { customers, newActivityRecordData, setNewActivityRecordData, doGetCustomer } = this.props;

    const selectedCustomer = _.find(customers, (customer) => customer.userId === selectedCustomerId);

    // TODO : When selected a customer, load the customer's location as well for the Step 3 screen.
    // load it in the background, do not await for it.

    if (selectedCustomer) {
      await doGetCustomer({ userId: selectedCustomerId });
      setNewActivityRecordData({ ...newActivityRecordData, selectedCustomerId, selectedCustomer });
    }
  };

  private _onNextStep = () => {
    const { onNextStep } = this.props;
    onNextStep();
  };

  componentDidMount() {
    const { newActivityRecordData, setNewActivityRecordData } = this.props;
    if (_.isEmpty(newActivityRecordData)) {
      setNewActivityRecordData(defaultActivityRecord);
    }

    this._loadContent();
  }

  render() {
    const { newActivityRecordData, customers, portalUser } = this.props;

    const hasAccessToCustomerDetails = PermissionUtils.validatePermission(
      'ViewCustomerProfile',
      portalUser.permissions.permissionRoles
    );

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large mt-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 184px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Paragraph>
                The next few screens will cover everything that's needed to create an activity record.
              </Paragraph>
              <Paragraph>
                Let's start by selecting the <b>customer</b> you wish to create this activity record for
              </Paragraph>
              {/*<Paragraph>*/}
              {/*  Visit the <a>Help Center</a> to learn more.*/}
              {/*</Paragraph>*/}
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 184px)' }}>
            <div className="bg-white rounded-big p-large" style={{ minWidth: '250px', minHeight: '463px' }}>
              <Title level={4}>Select Customer</Title>
              <Paragraph>
                Select the <b>customer</b> that you'd like to create an activity record for.
              </Paragraph>

              <FieldLabel text={'CUSTOMER'} />
              <div className="flex-row mb-medium">
                <Search
                  placeholder={'Search for ...'}
                  value={this.state.searchCustomer}
                  onChange={this._handleSearchCustomerName}
                  loading={this.state.isSearching}
                  size="large"
                  allowClear
                />
              </div>

              {newActivityRecordData.selectedCustomerId !== '' ? (
                <>
                  <SelectedCustomerRow
                    selectedCustomerData={newActivityRecordData.selectedCustomer}
                    removeSelectedCustomer={this._removeSelectedCustomer}
                  />
                  {newActivityRecordData.selectedCustomer &&
                    newActivityRecordData.selectedCustomer.pinnedAlertNumber > 0 && (
                      <PinnedAlertItem
                        pinnedAlertNumber={newActivityRecordData.selectedCustomer.pinnedAlertNumber}
                        customerUserId={newActivityRecordData.selectedCustomerId}
                        isViewOnly={true}
                      />
                    )}
                </>
              ) : null}

              {this.state.isLoading && (
                <Card className="anim-fade-in-fast">
                  <div className="mb-small">
                    <Text>Fetching customers...</Text>
                  </div>
                  <ProgressBar animate={true} />
                </Card>
              )}

              {!this.state.isLoading && (
                <div
                  style={{ overflowY: 'auto', overflowX: 'hidden', height: '40vh' }}
                  className="mb-x-large bordered anim-slide-down rounded"
                  id="scroll-modal"
                >
                  <InfiniteScrollLoading
                    hasMore={customers.length >= this.state.page * this.state.pageSize}
                    loadingElementId={'scroll-modal'}
                    loadMore={this._fetchMoreCustomers}
                    loaderColSpan={7}
                    loadingOffSet={60}
                  >
                    {_.map(customers, (customer, i) => {
                      // If the user is not a Guardian
                      if (customer.isDependent || customer.isIndependent) {
                        return (
                          <CustomerItemRow
                            customer={customer}
                            key={i}
                            setSelectedCustomerId={this._setSelectedCustomerId}
                            hasAccessToCustomerDetails={hasAccessToCustomerDetails}
                          />
                        );
                      }
                    })}
                  </InfiniteScrollLoading>
                  {this.state.isLoadingInfiniteScrolling && (
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                  )}
                </div>
              )}
            </div>
            {!this.state.isLoading && (
              <div className="flex-row justify-end mt-x2-large">
                <PrimaryButton
                  size="large"
                  disabled={_.isEmpty(newActivityRecordData.selectedCustomerId)}
                  onClick={this._onNextStep}
                  icon="right"
                  iconPosition="right"
                >
                  Next
                </PrimaryButton>
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  customers: state.customersStore.customersLite,
  newActivityRecordData: state.bookingsStore.newActivityRecordData,
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetCustomersLite: dispatch.customersStore.doGetCustomersLite,
  doGetCustomer: dispatch.customersStore.doGetCustomer,
  setCustomerFilterLite: dispatch.customersStore.setCustomerFilterLite,
  doFetchServicesLite: dispatch.servicesStore.doFetchServicesLite,
  setNewActivityRecordData: dispatch.bookingsStore.setNewActivityRecordData
});

export default connect(
  mapState,
  mapDispatch
)(ActivityRecordStep1SelectCustomer);
