import React, { Component } from 'react';

import _ from 'lodash';

import { Empty, Skeleton } from 'antd';

import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { FilterType } from 'utilities/enum-utils';
import { FilterSection } from 'common-components/filter';
import CommonUtils from 'utilities/common-utils';

import { Text } from 'common-components/typography';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';

import { SessionGroupHeader } from 'views/group-services/session-listings/list-view/components/SessionGroupHeader';
import SessionRow from 'views/group-services/session-listings/list-view/components/SessionRow';
import { ListingTableHeader } from 'views/group-services/session-listings/list-view/components/ListingTableHeader';

import { timeZone } from 'interfaces/timezone-type';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import * as H from 'history';

const availableFilters = [FilterType.DATE_RANGE, FilterType.SERVICE, FilterType.SESSION_STATUS];

const SESSIONLIST_FILTERCONFIGS = {
  ALL: {
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [moment().startOf('week'), moment().endOf('week')],
        selectionLabel: CommonUtils.getFilterText(FilterType.DATE_RANGE, [
          moment().startOf('week'),
          moment().endOf('week')
        ])
      },
      {
        filter: FilterType.SERVICE,
        values: [],
        selectionLabel: 'All services'
      },
      {
        filter: FilterType.SESSION_STATUS,
        values: [],
        selectionLabel: 'All'
      }
    ]
  }
};

interface ISessionsListViewProps {
  history: H.History;
  sessions: typeof state.groupServiceStore.sessions;
  doFetchSessions: typeof dispatch.groupServiceStore.doFetchSessions;
  doResetSessions: typeof dispatch.groupServiceStore.doResetSessions;
  isActive: boolean;
  timezone: timeZone;
}

interface ISessionsListViewState {
  currentStartWeek: Date;
  currentEndWeek: Date;
  page: number;
  pageSize: number;
  filters: any;
  isFetching: boolean;
}

const EmptyState = () => (
  <div className="flex-1 bg-white mt-x2-large align-center flex-column anim-fade-in-fast">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No sessions found.
    </Text>{' '}
    <br /> <br />
    <Text color="secondary">All sessions that meet this filter will appear here.</Text>
    <Text color="secondary">Try adjusting your filter, or clicking on another view.</Text>
  </div>
);

function FetchingIndicator() {
  return (
    <div>
      <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
    </div>
  );
}

class SessionsListView extends Component<ISessionsListViewProps, ISessionsListViewState> {
  state = {
    currentStartWeek: new Date(),
    currentEndWeek: new Date(),
    filters: SESSIONLIST_FILTERCONFIGS['ALL'].filters,
    isFetching: false,
    page: 1,
    pageSize: 20
  };

  navigateTo = (location) => this.props.history.push(location);

  _onChangeFilter = async (filters) => {
    const { doResetSessions } = this.props;
    await doResetSessions({});
    this.setState({ filters, page: 1, isFetching: true }, this._fetchSessions);
  };

  _formatFilters = () => {
    // Run through non-empty filters and re-assign them into filter objects.
    const values = _.chain(this.state.filters)
      .filter((filter) => !_.isEmpty(filter.values))
      .map((filter) => {
        switch (filter.filter) {
          case 'startDate':
            return {
              startDate: filter.values[0].toDate(),
              endDate: filter.values[1].toDate()
            };
          case 'serviceIds':
            return { serviceIds: filter.values };
          case 'sessionStatus':
            return { status: filter.values };
        }
      })
      .value();

    // merge array into an object.
    return _.assign.apply(_, values);
  };

  _fetchSessions = async () => {
    const { doFetchSessions } = this.props;
    const filters = this._formatFilters();

    // Temporarily attaching it to a serviceId
    const request = {
      ...filters,
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: new Date()
    };

    this.setState({ isFetching: true });
    await doFetchSessions(request);
    this.setState({ isFetching: false });
  };

  _fetchMoreSessions = async () => {
    this.setState({ page: this.state.page + 1 }, () => {
      this._fetchSessions();
    });
  };

  componentDidMount = () => {
    this._fetchSessions();
  };

  componentDidUpdate(
    prevProps: Readonly<ISessionsListViewProps>,
    prevState: Readonly<ISessionsListViewState>,
    snapshot?: any
  ) {
    if (this.props.isActive !== prevProps.isActive) {
      if (this.props.isActive) {
        // switched to active.
        this.props.doResetSessions({});
        this._fetchSessions();
      }
    }
  }

  render() {
    const { sessions, timezone } = this.props;

    // TODO : Change this to ascending
    const sortedSessions = _.orderBy(sessions, ['startDateTime', 'asc']);

    // Group sessions by day.
    const groupedSessions = _.groupBy(sortedSessions, (session) =>
      moment.tz(session.startDateTime, timezone).format('dddd, D MMMM YYYY')
    );

    const groupDates = _.chain(groupedSessions)
      .keys()
      .value();

    const maxCount = this.state.page * this.state.pageSize;
    return (
      <div className="ph-large pb-large">
        {/* Header */}

        {/* Filter section */}
        <div className="mb-medium">
          <FilterSection
            availableFilters={availableFilters}
            // TODO : Change this to redux
            filters={this.state.filters}
            // TODO - Change this to actual timezone.
            displayTimezone={'Australia/Melbourne'}
            onChangeFilter={this._onChangeFilter}
            containerClassName="flex-row justify-between"
          />
        </div>

        {/* Change this to a more manageable table header ...? */}
        <ListingTableHeader />

        <div>
          {/* Changed to infinite loading */}
          <InfiniteScrollLoading
            loadingElementId="content-container"
            hasMore={sortedSessions.length >= maxCount}
            loadMore={this._fetchMoreSessions}
            loaderColSpan={7}
            loadingOffSet={60}
            customLoader={<div>Loading...</div>}
          >
            {_.map(groupDates, (dateKey) => (
              <div className="mb-x2-large bordered-bottom" key={dateKey}>
                <SessionGroupHeader dateKey={dateKey} />

                {_.map(groupedSessions[dateKey], (session, idx) => (
                  <SessionRow
                    session={session}
                    onNavigate={this.navigateTo}
                    key={session.serviceDateTimeId}
                    timezone={timezone}
                  />
                ))}
              </div>
            ))}
          </InfiniteScrollLoading>
        </div>

        {this.state.isFetching && <FetchingIndicator />}

        {!this.state.isFetching && _.isEmpty(groupDates) && <EmptyState />}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  // TODO : Change this to sessions timezone
  bookingDisplayTzCustom: state.bookingsStore.bookingDisplayTzCustom,
  bookingDisplayTzMode: state.bookingsStore.bookingDisplayTzMode,
  sessions: state.groupServiceStore.sessions,
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchSessions: dispatch.groupServiceStore.doFetchSessions,
  doResetSessions: dispatch.groupServiceStore.doResetSessions
});

export default connect(
  mapState,
  mapDispatch
)(SessionsListView);
