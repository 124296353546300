import React, { Component } from 'react';
import { Col, notification, Row } from 'antd';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { Paragraph } from 'common-components/typography';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import ActionModal from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';

interface IRemoveVCPNumbersModalProps {
  closeRemoveVCPNumbersModal: () => void;
  isOpen: boolean;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  numberType: string;
  doDeleteFundingGeneralInfo: typeof dispatch.customersStore.doDeleteFundingGeneralInfo;
}

class RemoveVCPNumbersModal extends Component<IRemoveVCPNumbersModalProps> {
  state = { isLoading: false, step: 1 };

  private _remove = async () => {
    const { doDeleteFundingGeneralInfo, selectedCustomer, numberType } = this.props;
    try {
      this.setState({
        isLoading: true
      });

      const payload: any = {
        userId: selectedCustomer.userId,
        numberType: numberType
      };

      await doDeleteFundingGeneralInfo(payload);

      this.setState({
        isLoading: false,
        step: 2
      });
    } catch (e) {
      notification.error({ message: 'Oops, an error has occurred, please try again.' });
    }
  };

  private _closeModal = () => {
    this.setState({ title: `Remove ${this.props.numberType} number`, step: 1, isLoading: false });
    this.props.closeRemoveVCPNumbersModal();
  };

  render() {
    const { step } = this.state;
    return (
      <div>
        <ActionModal
          title={`Remove ${this.props.numberType} number`}
          isOpen={this.props.isOpen}
          onClose={this._closeModal}
          width="medium"
        >
          {step === 1 && (
            <div className="anim-slide-left">
              <Paragraph>
                Are you sure you want to remove {this.props.numberType} number from this customer profile?
              </Paragraph>
              <div className={'mb-small mt-x2-large'}>
                <Row type={'flex'} className={'justify-end'}>
                  <Col className="mr-large">
                    <GhostButton
                      size="large"
                      onClick={this.props.closeRemoveVCPNumbersModal}
                      disabled={this.state.isLoading}
                    >
                      Cancel
                    </GhostButton>
                  </Col>
                  <Col>
                    <PrimaryButton size="large" color="red" onClick={this._remove} loading={this.state.isLoading}>
                      Remove
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="anim-slide-left">
              <Paragraph>
                You have successfully removed {this.props.numberType} number from the customer profile.
              </Paragraph>
              <div className={'mb-small mt-x2-large'}>
                <Row type={'flex'} className={'justify-end'}>
                  <Col>
                    <PrimaryButton size="large" onClick={this._closeModal}>
                      Close
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </ActionModal>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedCustomer: state.customersStore.selectedCustomer
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doDeleteFundingGeneralInfo: dispatch.customersStore.doDeleteFundingGeneralInfo
});

export default connect(
  mapState,
  mapDispatch
)(RemoveVCPNumbersModal);
