import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Checkbox, Col, Form, Icon, Input, Row } from 'antd';
import { SubTitle, Text } from 'common-components/typography';
import { FormComponentProps } from 'antd/es/form';
import NumberInput from 'common-components/inputs/NumberInput';
import BillingLineItemV2 from 'views/billings/components/BillingLineItemV2';
import { MmmGroup, PaymentSourceType } from 'utilities/enum-utils';
import { IBillingLineItem } from 'interfaces/booking-interfaces';
import { HyperlinkButton } from 'common-components/buttons';
import _ from 'lodash';
import { ndisHelper } from 'variables/data-helpers';

interface IAdditionalChargesSectionProps extends FormComponentProps {
  onNonFaceToFaceChange?: (e) => void;
  addAutoChargeLineItem?: () => void;
  updateAutoChargeLineItem?: (item, index) => void;
  removeAutoChargeLineItem?: (index) => void;
  isNonFaceToFace: boolean;
  nonFaceToFaceMinutes?: number;
  isEdit: boolean;
  mmmGroup?: MmmGroup;
  state?: string;
  serviceLineItems?: Array<any>;
  autoChargeLineItems: Array<any>;
  paymentSourceType?: string;
}

interface IAdditionalChargesSectionState {
  newBillingLineItem: IBillingLineItem;
  lineItems: any[];
  isLoading: boolean;
}

class AdditionalChargesSection extends Component<IAdditionalChargesSectionProps, IAdditionalChargesSectionState> {
  state = { newBillingLineItem: null, lineItems: this.props.autoChargeLineItems, isLoading: false };

  public autoChargeLineItemsValidateRef: any[] = [];

  private _setRef = (ref) => {
    if (ref) {
      this.autoChargeLineItemsValidateRef.push(ref);
    }
  };

  public onValidateNonFaceToFaceMinutes = () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      return form.getFieldValue('nonFaceToFaceMinutes');
    }
  };

  public onValidateAutoChargeItems = async () => {
    let isFormValid = true;

    await Promise.all(
      _.map(this.autoChargeLineItemsValidateRef, async (validate) => {
        const validated = await validate.onValidate();
        if (!validated) {
          isFormValid = false;
        }
      })
    );
    return isFormValid;
  };

  private _validateNonFaceToFaceMinutes = (rule, value, callback) => {
    try {
      if (!value) {
        throw Error('Please add a value');
      }
      if (value > 9999) {
        throw Error('Figure cannot exceed 9999 minutes');
      }
    } catch (e) {
      callback(e);
      return;
    }
    callback();
  };

  render() {
    const {
      onNonFaceToFaceChange,
      isNonFaceToFace,
      form,
      mmmGroup,
      state,
      autoChargeLineItems,
      serviceLineItems,
      paymentSourceType,
      isEdit,
      nonFaceToFaceMinutes
    } = this.props;

    let lineItems = [];
    if (autoChargeLineItems) {
      lineItems = _.map(autoChargeLineItems, (item) => {
        return {
          ...item,
          startDateTime: null,
          endDateTime: null,
          supportItemNumberArray: [],
          description: null,
          total: 0,
          paymentMethod: null,
          ruleType: null,
          isTravel: false,
          travelDistance: 0,
          mileagePrice: 0,
          isEditing: isEdit,
          paymentSourceType: item.paymentSourceType ? item.paymentSourceType : PaymentSourceType.NDIS
        };
      });
    }

    const serviceBillingItems = _.map(serviceLineItems, (item) => {
      const supportItem = item.supportItemName
        ? item.supportItemName
        : ndisHelper.getBySupportItemNumber(item.supportItemNumber).SupportItem;
      return { ...item, paymentSourceType: paymentSourceType, supportItem };
    });

    const billingItemDivWidth = isEdit ? '92%' : '100%';

    const { getFieldDecorator } = form;
    return (
      <>
        <div className={`pv-large ${isEdit ? 'bordered-top bordered-bottom border-standard-gray' : ''} mb-x-large`}>
          <SubTitle>Non face-to-face costs</SubTitle>
          {isEdit ? (
            <div>
              <Checkbox checked={isNonFaceToFace} onChange={onNonFaceToFaceChange} className={'mt-x-small'}>
                <Text size="medium">Automatically charge for non face-to-face costs</Text>
              </Checkbox>
              {isNonFaceToFace && (
                <div className={`ph-large pv-large bg-tertiary mt-x-small`}>
                  <SubTitle>Non Face-To-Face Minutes To Charge</SubTitle>
                  <Form.Item className="m-none">
                    {getFieldDecorator('nonFaceToFaceMinutes', {
                      rules: [
                        {
                          validator: this._validateNonFaceToFaceMinutes
                        }
                      ],
                      initialValue: nonFaceToFaceMinutes
                    })(
                      <NumberInput
                        placeholder="Add non face-to-face minutes..."
                        size={'default'}
                        style={{ width: '350px' }}
                        precision={0}
                      />
                    )}
                  </Form.Item>
                </div>
              )}
            </div>
          ) : (
            <Text className={'mt-x-small'}>
              {isNonFaceToFace ? (
                <>
                  <b>{nonFaceToFaceMinutes} minutes</b> of{' '}
                </>
              ) : (
                <b>No </b>
              )}
              non face-to-face time being charged per session
            </Text>
          )}
        </div>
        <div className={'mb-large'}>
          <SubTitle>Auto-Charged Line Items</SubTitle>
        </div>
        <Row
          style={isEdit ? { width: '94% ' } : {}}
          gutter={24}
          className="pb-medium mb-medium bordered-bottom border-standard-gray"
        >
          <Col span={11}>
            <SubTitle>Support Item</SubTitle>
          </Col>

          <Col span={4}>
            <SubTitle>Claim Type</SubTitle>
          </Col>

          <Col span={3}>
            <SubTitle>Unit</SubTitle>
          </Col>

          <Col span={3}>
            <SubTitle>Price</SubTitle>
          </Col>
          <Col span={3}>
            <SubTitle>Quantity</SubTitle>
          </Col>
        </Row>
        {lineItems.length > 0 ? (
          <>
            {lineItems.map((item, index) => {
              return (
                <div className="flex-row justify-between">
                  <div style={{ width: billingItemDivWidth }}>
                    {!this.state.isLoading && (
                      <BillingLineItemV2
                        key={item.bookingBillingLineItemId}
                        index={index}
                        wrappedComponentRef={this._setRef}
                        billingSupportItems={serviceBillingItems}
                        billingLineItem={item}
                        fundedCategories={[]}
                        serviceBillingLineItems={[]}
                        travelDistance={10}
                        showLineItemLabel={false}
                        isRemoving={false}
                        isCancelled={false}
                        cancellationCode={null}
                        cancellationReason={null}
                        isEditPaymentLineItem={true}
                        hasABTLineItems={false}
                        hasPTNLCLineItems={false}
                        isFilterOutTravelItem={true}
                        mmmGroup={mmmGroup}
                        locationState={state}
                        hideWarningFunding={true}
                        isSaveButtonOutside={true}
                        hideManagementMethod={true}
                        hideSelectionOptionLineItemWarning={true}
                        updateBillingItem={this.props.updateAutoChargeLineItem}
                        hideLineItemCost={true}
                        isAutoChargeLineItems={true}
                        hideBottomLineItemName={!isEdit}
                        hideInputLabels={true}
                        disableUnitSelection={true}
                      />
                    )}
                  </div>
                  {isEdit && (
                    <div className={'pt-large'}>
                      <HyperlinkButton key={index} onClick={() => this.props.removeAutoChargeLineItem(index)}>
                        Delete
                      </HyperlinkButton>
                    </div>
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <div className={`text-align-center width-full text-color-secondary mv-large`}>No auto-charge line items.</div>
        )}

        {isEdit && (
          <div className="mt-medium mb-large">
            <HyperlinkButton onClick={this.props.addAutoChargeLineItem}>
              <Icon type="plus" />
              &nbsp;Add service
            </HyperlinkButton>
          </div>
        )}
      </>
    );
  }
}
export default connect()(Form.create<IAdditionalChargesSectionProps>()(AdditionalChargesSection));
