import React, { Component } from 'react';

import { connect } from 'react-redux';

import _ from 'lodash';

import { InboxPanel } from 'views/messaging/inbox/InboxPanel';
import { ChannelsPanel } from 'views/messaging/channels/ChannelsPanel';
import { MessagesPanel } from 'views/messaging/messages/MessagesPanel';

import './local-styles/messaging.css';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import {
  IAssignedService,
  IChannel,
  IServiceRosterUser,
  MessageOrigin,
  MessageSubscriberType
} from 'interfaces/message-interfaces';
import { CustomerStatusType } from 'utilities/enum-utils';

const bodyHeight = 'calc(100vh - 66px)';

export interface INewChannelAttributes {
  serviceId: string;
  serviceName: string;
  userId: string;
  userName: string;
  subscriberType: MessageSubscriberType;
}

interface IMessageLandingViewProps {
  // dispatch functions
  doGetAssignedServices: any;
  doGetChannelsForService: any;
  setMessagingBadgeCount: any;
  setChannel: any;
  doResetUnreadCount: any;
  doGetServiceRoster: any;
  doGetCustomersLite: any;
  setCustomerFilterLite: typeof dispatch.customersStore.setCustomerFilterLite;
  doGetChannelDetail: any;
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;

  // dispatch states
  customers: any;
  assignedServices: IAssignedService[];
  channels: IChannel[];
  selectedServiceRoster: IServiceRosterUser[];

  // incoming target message
  incomingChannelId: string;
  incomingMessageId: string;

  // browser objects
  location: any;
  history: any;
}

interface IMessageLandingViewState {
  isFetchingServices?: boolean;
  currentServiceId: string;
  currentChannelId: string;
  currentChannelMode: string; // Used to indicate whether it's a new channel or an existing channel.
  newChannelAttributes: INewChannelAttributes; // this is used for new channel holders
}

class MessagingLandingView extends Component<IMessageLandingViewProps, IMessageLandingViewState> {
  state = {
    isFetchingServices: true,

    currentServiceId: null,

    currentChannelId: null,

    currentChannelMode: 'existing',

    newChannelAttributes: null
  };

  // Service clicked; reset everything.
  onClickService = async ({ serviceId }) => {
    // Uncomment this to keep the MessagesPanel open/do not change MessagesPanel whenever a service is clicked.
    // this.setState({ currentServiceId: serviceId });
    this.setState({ currentServiceId: serviceId, currentChannelId: null });
    this.props.doGetChannelsForService({ serviceId });
    this.props.doGetServiceRoster({ serviceId });
  };

  // Select an existing channel
  onSelectChannel = async ({ messageChannelId }) => {
    this.props.setMessagingBadgeCount(0);

    this.setState({ currentChannelId: messageChannelId, currentChannelMode: 'existing' });

    // locally reset to 0
    this.props.doResetUnreadCount({ messageChannelId, callApi: false });

    // TODO reset the unread message count for service as well.
  };

  onCreateChannel = async ({ newChannelAttributes }) => {
    if (!_.isEmpty(newChannelAttributes)) {
      this.setState({ currentChannelId: '', currentChannelMode: 'new', newChannelAttributes });
    }
  };

  onChannelCreated = async ({ channelId }) => {
    this.setState({ currentChannelMode: 'existing', currentChannelId: channelId, newChannelAttributes: null });
  };

  // Handle incoming origins (if any)
  handleOrigins = async () => {
    // handle deep links...?
    const { doGetChannelDetail, location } = this.props;

    // TODO Change location and origin to use Redux states instead of location.state.

    const state = location && location.state;
    const origin = state && state.origin;

    if (origin === MessageOrigin.Notification) {
      // -=== From Notification Clicked event (clicking on notification) ===-
      const incomingChannelId = state && state.messageChannelId;

      if (!_.isEmpty(incomingChannelId)) {
        const currentChannel = await doGetChannelDetail({ messageChannelId: incomingChannelId });

        this.setState({
          currentChannelId: incomingChannelId,
          currentChannelMode: 'existing',
          currentServiceId: currentChannel.serviceId
        });
      }
    } else if (origin === MessageOrigin.BookingDetailsCustomer || origin === MessageOrigin.BookingDetailsWorker) {
      // -=== From Booking Details ===-

      const { doGetChannelsForService, doGetServiceRoster } = this.props;

      const serviceId = state && state.serviceId;
      const serviceName = state && state.serviceName;
      const userId = state && state.userId;
      const userName = state && state.userName;
      const avatarUrl = state && state.avatarUrl;

      const subscriberType = MessageOrigin.BookingDetailsWorker
        ? MessageSubscriberType.SupportWorker
        : MessageSubscriberType.Customer;

      if (serviceId && userId) {
        this.setState({ currentServiceId: serviceId });
      }

      // TODO : Perhaps should fetch based on user Id...
      await Promise.all([doGetChannelsForService({ serviceId }), doGetServiceRoster({ serviceId })]);

      const matchedChannel: any = _.find(
        this.props.channels,
        (c) => c.serviceId === serviceId && (c.subscribers && _.find(c.subscribers, (sub) => sub.userId === userId))
      );

      if (!_.isEmpty(matchedChannel)) {
        // -=== Found a channel! Selecting it for display...
        this.onSelectChannel({ messageChannelId: matchedChannel.messageChannelId });
      } else {
        // -=== No such channel... creating a new channel.
        const newChannelAttributes: INewChannelAttributes = {
          serviceId,
          serviceName,
          userId,
          userName,
          subscriberType
        };

        this.onCreateChannel({ newChannelAttributes });
      }
    }
  };

  componentDidMount = async () => {
    const { doGetAssignedServices } = this.props;
    this.props.setSelectedSideNavMenuKeys(['/messaging']);
    // preload customers.
    this.props.setCustomerFilterLite({});
    this.props.doGetCustomersLite({
      customerStatus: [CustomerStatusType.ACTIVE, CustomerStatusType.ARCHIVED, CustomerStatusType.ENQUIRY]
    });

    // get assigned/available services for current user to display in Inbox view.
    await doGetAssignedServices();

    this.handleOrigins();
  };

  componentDidUpdate = async (
    prevProps: Readonly<IMessageLandingViewProps>,
    prevState: Readonly<IMessageLandingViewState>,
    snapshot?: any
  ) => {
    // Assigned services received
    if (prevProps.assignedServices !== this.props.assignedServices) {
      if (this.state.currentServiceId === null) {
        // first load
        if (!_.isEmpty(this.props.assignedServices)) {
          const currentServiceId = this.props.assignedServices[0].serviceId;
          this.props.doGetChannelsForService({ serviceId: currentServiceId });

          // preload service roster for create conversation
          this.props.doGetServiceRoster({ serviceId: currentServiceId });
          this.setState({ currentServiceId });
        }
      }
    }

    // This is coming in from clicking on a notification
    if (
      // prevProps.incomingChannelId !== this.props.incomingChannelId &&
      prevProps.incomingMessageId !== this.props.incomingMessageId
    ) {
      const { incomingChannelId, doGetChannelDetail, doGetChannelsForService } = this.props;

      if (!_.isEmpty(incomingChannelId)) {
        // Quickly load the current channel first.
        const currentChannel = await doGetChannelDetail({ messageChannelId: incomingChannelId });

        this.setState({
          currentChannelId: incomingChannelId,
          currentChannelMode: 'existing',
          currentServiceId: currentChannel.serviceId
        });

        // Then load the services for this channel, just in case.
        doGetChannelsForService({ serviceId: currentChannel.serviceId });
      }
    }
  };

  render() {
    const { assignedServices, channels, selectedServiceRoster, customers, setMessagingBadgeCount } = this.props;

    const currentService = _.isEmpty(this.state.currentServiceId)
      ? {}
      : _.find(assignedServices, (service) => service.serviceId === this.state.currentServiceId);

    const currentChannel = _.isEmpty(this.state.currentChannelId)
      ? null
      : _.find(channels, (channel) => channel.messageChannelId === this.state.currentChannelId);

    return (
      <div className="bg-white bordered-top border-secondary" style={{ height: bodyHeight, borderWidth: '2px' }}>
        <div className="flex-row" style={{ height: bodyHeight }}>
          {/* Inbox Panel */}
          <InboxPanel
            services={assignedServices}
            onClickService={this.onClickService}
            currentServiceId={this.state.currentServiceId}
          />

          {/* Channel Panel */}
          <ChannelsPanel
            onSelectChannel={this.onSelectChannel}
            onCreateChannel={this.onCreateChannel}
            currentService={currentService}
            currentChannel={currentChannel}
            selectedServiceRoster={selectedServiceRoster}
            channels={channels}
            customers={customers}
          />

          {/* Messages Panel */}
          <MessagesPanel
            currentChannel={currentChannel}
            currentChannelId={this.state.currentChannelId}
            currentChannelMode={this.state.currentChannelMode}
            newChannelAttributes={this.state.newChannelAttributes} // new channel props is used for new channel display
            onChannelCreated={this.onChannelCreated}
            currentService={currentService}
            setMessagingBadgeCount={setMessagingBadgeCount}
          />
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  // Channels
  assignedServices: state.channelsStore.assignedServices,
  channels: state.channelsStore.channels,
  selectedServiceRoster: state.channelsStore.selectedServiceRoster,

  // Incoming notification
  incomingChannelId: state.messagesStore.incomingChannelId,
  incomingMessageId: state.messagesStore.incomingMessageId,

  // Customers
  customers: state.customersStore.customersLite
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  // Channels
  doGetAssignedServices: dispatch.channelsStore.doGetAssignedServices,
  doGetChannelsForService: dispatch.channelsStore.doGetChannelsForService,
  setChannel: dispatch.channelsStore.setChannel,
  doResetUnreadCount: dispatch.channelsStore.doResetUnreadCount,
  doGetServiceRoster: dispatch.channelsStore.doGetServiceRoster,
  doGetChannelDetail: dispatch.channelsStore.doGetChannelDetail,

  // Customers
  doGetCustomersLite: dispatch.customersStore.doGetCustomersLite,
  setCustomerFilterLite: dispatch.customersStore.setCustomerFilterLite,

  // notifications
  setMessagingBadgeCount: dispatch.notificationsStore.setMessagingBadgeCount,

  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys
});

export default connect(
  mapState,
  mapDispatch
)(MessagingLandingView);
