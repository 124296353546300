import { Spinner } from '@blueprintjs/core';
import { Form, Icon, Input, message, notification, Progress, Select, Upload } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Information } from 'common-components/alerts';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { SubTitle, Text } from 'common-components/typography';
import { ref, uploadBytesResumable } from 'firebase/storage';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import firebaseApp from 'stores/firebase-app';
import { BookingType, CustomerNoteVisibleType } from '../../utilities/enum-utils';
import Utils from '../../utilities/Utils';

const { Option } = Select;
interface IAddEditNoteModalProps extends FormComponentProps {
  isOpen: boolean;
  onClose: () => void;
  bookingId?: string;
  editNote: boolean;
  editingNote: any;
  noteType: string;
  customerUserId?: string;
  doAddBookingNote: typeof dispatch.bookingsStore.doAddNote;
  doEditBookingNote: typeof dispatch.bookingsStore.doEditNote;
  doFetchSingleBooking: typeof dispatch.bookingsStore.doFetchSingleBooking;
  doAddCustomerNotes: typeof dispatch.customersStore.doAddCustomerNotes;
  doEditCustomerNote: typeof dispatch.customersStore.doEditCustomerNote;
  dofetchCustomerNotes: typeof dispatch.customersStore.dofetchCustomerNotes;
  resetCustomerNotesPageInfo: typeof dispatch.customersStore.resetCustomerNotesPageInfo;
  portalUser: typeof state.authStore.portalUser;
}

interface IAddEditNoteModalState {
  isLoading: boolean;
  isIncident: boolean;
  noteDetails: string;
  visibleType: string;
  selectedFile: any;
  isUploading: boolean;
  isInitialise: boolean;
  progress: number;
  selectedDocumentName: string;
  didDeleteDocument: boolean;
}

class AddEditNoteModal extends Component<IAddEditNoteModalProps, IAddEditNoteModalState> {
  state = {
    isLoading: false,
    isIncident: false,
    noteDetails: '',
    selectedDocumentName: '',
    visibleType: CustomerNoteVisibleType.PORTAL,
    selectedFile: null,
    isUploading: false,
    isInitialise: false,
    progress: 0,
    didDeleteDocument: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { editingNote, editNote } = this.props;
      if (editNote && Utils.isEmpty(this.state.noteDetails) && this.props.isOpen) {
        this.setState({
          noteDetails: editingNote.body,
          visibleType: editingNote.visibleType,
          isIncident: editingNote.isIncident,
          selectedFile: null,
          selectedDocumentName: editingNote.documentName,
        });
      }
    }
  }

  private _onCloseModel = () => {
    const { onClose } = this.props;
    this.setState({
      isIncident: false,
      noteDetails: '',
      selectedDocumentName: '',
      visibleType: CustomerNoteVisibleType.PORTAL,
      selectedFile: null,
    });
    onClose();
  };

  private _onPressAddNote = async () => {
    const { doAddBookingNote, doAddCustomerNotes, bookingId, noteType, customerUserId } = this.props;
    const { noteDetails, isIncident, visibleType, selectedFile } = this.state;

    if (!Utils.isEmpty(noteDetails) && noteDetails.length >= 50 && noteDetails.length < 35001) {
      this.setState({ isLoading: true });
      try {
        let result = null;
        if (noteType === BookingType.BOOKING || noteType === BookingType.ACTIVITY_RECORD) {
          result = await doAddBookingNote({
            isIncident: isIncident,
            visibleType: visibleType,
            noteContent: noteDetails,
            bookingId: bookingId,
            documentName: selectedFile && selectedFile.name,
          });
        } else {
          await doAddCustomerNotes({
            isIncident: isIncident,
            noteContent: noteDetails,
            customerUserId: customerUserId,
          });
        }
        if (selectedFile) {
          this._uploadFile(result);
        }
        this._onCloseModel();
      } catch (e) {
        notification.error({ message: 'Add note failed! Please try again.' });
      }
      this.setState({ isLoading: false });
    } else {
      this.props.form.validateFields();
    }
  };

  private _onPressEditNote = async () => {
    const { doEditBookingNote, doEditCustomerNote, editingNote, noteType, customerUserId } = this.props;
    const { noteDetails, isIncident, visibleType, selectedFile, didDeleteDocument } = this.state;
    if (!Utils.isEmpty(noteDetails) && noteDetails.length >= 50 && noteDetails.length < 35001) {
      try {
        this.setState({ isLoading: true });
        let result = null;
        if (noteType === BookingType.BOOKING || noteType === BookingType.ACTIVITY_RECORD) {
          result = await doEditBookingNote({
            isIncident: isIncident,
            visibleType: visibleType,
            noteContent: noteDetails,
            bookingId: editingNote.attendanceId,
            noteId: editingNote.noteId,
            documentName: selectedFile && selectedFile.name,
            documentAction:
              didDeleteDocument && !selectedFile && editingNote.documentName
                ? 'DELETE'
                : selectedFile
                ? 'UPDATE'
                : null,
          });

          // need to fetch customer note list if customerUserId is not null
          if (customerUserId) {
            this.props.resetCustomerNotesPageInfo({});
            await this.props.dofetchCustomerNotes({
              customerUserId: this.props.customerUserId,
            });
          } else {
            await this.props.doFetchSingleBooking({
              bookingId: editingNote.attendanceId,
            });
          }
        } else {
          await doEditCustomerNote({
            noteContent: noteDetails,
            isIncident: isIncident,
            noteId: editingNote.noteId,
            customerUserId: customerUserId,
          });
        }
        if (selectedFile && selectedFile.name !== editingNote.documentName) {
          this._uploadFile(result);
        }
        this._onCloseModel();
      } catch (e) {
        notification.error({ message: 'Edit note failed! Please try again.' });
      }
      this.setState({ isLoading: false });
    } else {
      this.props.form.validateFields();
    }
  };

  private _uploadFile = async (addedDocument) => {
    const { portalUser, bookingId } = this.props;
    const { selectedFile } = this.state;

    this.setState({ isInitialise: true });
    try {
      const metadata = {
        customMetadata: {
          documentId: addedDocument.documentId,
          serviceProviderId: portalUser.serviceProviderId,
          attendanceId: bookingId,
          noteId: addedDocument.noteId,
        },
      };
      this.setState({ isLoading: true });

      const storageRef = ref(firebaseApp.storage, `${addedDocument.uploadBucketUrl}/${selectedFile.name}`);
      const uploadFile = uploadBytesResumable(storageRef, selectedFile, metadata);

      await uploadFile.on(
        'state_changed',
        (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          this.setState({ progress });
        },
        (error) => {
          this.setState({ isLoading: false });
          this._onCloseModel();
          notification.error({ message: 'Upload failed! Please try again.', description: error });
        },
        () => {
          this.setState({ isLoading: false });
          this._onCloseModel();
          notification.success({
            message: `Attachment is currently scanning.`,
          });
        },
      );
    } catch (e) {
      notification.error({ message: 'Upload failed! Please try again.', description: e });
    }
    this.setState({ isInitialise: false });
  };

  private _noteDetailsValidator = (rule, value, callback) => {
    try {
      if (!value || value === '') {
        throw Error('Please fill in the note details.');
      }
      if (_.trim(value).length < 50) {
        throw Error('Please enter at least 50 characters');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _hasExtension = (file) => {
    return new RegExp(
      '(' +
        [
          '.jpeg',
          '.jpg',
          '.png',
          '.gif',
          '.pdf',
          '.txt',
          '.ppt',
          '.pptx',
          '.doc',
          '.docx',
          '.csv',
          '.xls',
          '.xlsx',
          '.xlsm',
        ]
          .join('|')
          .replace(/\./g, '\\.') +
        ')$',
    ).test(file.name.toLowerCase());
  };

  private _validateFile = (file) => {
    const isValidType = this._hasExtension(file);
    if (!isValidType) {
      message.error('This file extension is not supported, please choose another format.');
    }
    const isLt2M = file.size / 1024 / 1024 < 25;
    if (!isLt2M) {
      message.error('File must be smaller than 25MB.');
    }

    if (isLt2M && isValidType) {
      this.setState({ selectedFile: file });
    }

    return false;
  };

  private _resetSelectedFile = () => {
    this.setState({ selectedFile: null, selectedDocumentName: '', didDeleteDocument: true });
  };

  render() {
    const { form, isOpen, editNote, noteType, editingNote } = this.props;
    const { getFieldDecorator } = form;
    const { isIncident, isLoading, isUploading, progress, isInitialise, selectedFile } = this.state;

    return (
      <ActionModal
        isOpen={isOpen}
        title={editNote ? 'Edit note' : 'Add note'}
        width="medium"
        onClose={this._onCloseModel}
        verticalAlignment="highest"
      >
        {isUploading ? (
          <div className="mv-x2-large">
            <div className="text-align-center">
              <Progress type="circle" percent={progress} className="mb-medium" />
            </div>
            <div className="text-align-center">
              <Text size="x2-large">Uploading...</Text>
            </div>
          </div>
        ) : isInitialise ? (
          <div className="mv-x2-large">
            <div className="text-align-center">
              <Spinner size={80} />
            </div>
            <div className="text-align-center">
              <Text size="x2-large">Initializing...</Text>
            </div>
          </div>
        ) : (
          <>
            <div>
              {!editNote && noteType === 'GENERAL' && (
                <div className="mb-large">
                  <Information
                    content={
                      <Text size="regular">
                        This note will be added as a <b>general note</b>, and will be viewable only by Workspace users.
                        If you wish to add a note that is <b>related to a specific booking</b>, please{' '}
                        <b>add via the booking details page</b>.
                      </Text>
                    }
                  />
                </div>
              )}
              <Text>Does this note relate to an incident?</Text>
              <div className="mt-medium">
                {!isIncident && (
                  <>
                    <PrimaryButton className="mr-medium" onClick={() => this.setState({ isIncident: false })}>
                      No
                    </PrimaryButton>

                    <SecondaryButton color="secondary" onClick={() => this.setState({ isIncident: true })}>
                      Yes, this note relate to an incident
                    </SecondaryButton>
                  </>
                )}

                {isIncident && (
                  <>
                    <SecondaryButton
                      className="mr-medium"
                      color="secondary"
                      onClick={() => this.setState({ isIncident: false })}
                    >
                      No
                    </SecondaryButton>
                    <PrimaryButton color="red-dark" onClick={() => this.setState({ isIncident: true })}>
                      Yes, this note relate to an incident
                    </PrimaryButton>
                  </>
                )}
              </div>
            </div>
            <div className="mt-large">
              <SubTitle>Note</SubTitle>
              <div className="mt-medium">
                <Form.Item className={'m-none'}>
                  {getFieldDecorator('noteDetails', {
                    initialValue: this.state.noteDetails,
                    rules: [{ validator: this._noteDetailsValidator }],
                  })(
                    <Input.TextArea
                      placeholder={'Enter note details...'}
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      value={this.state.noteDetails}
                      onChange={(e) => this.setState({ noteDetails: e.target.value })}
                    />,
                  )}
                </Form.Item>
              </div>
              <Text size="regular" color="gray-darker" className="mt-small">
                50 characters minimum
              </Text>
            </div>
            {editNote && editingNote && (
              <div className="mt-medium">
                <Text color="gray-darker" weight="regular" size="regular">
                  Added by&nbsp;
                </Text>
                <Text color="gray-darker" weight="bold" size="regular">
                  {editingNote.firstName} {editingNote.lastName},&nbsp;
                  {moment(editingNote.createdOn).format('DD MMMM YYYY, hh:mm A')}
                </Text>
              </div>
            )}
            {editNote && editingNote && editingNote.updatedBy && (
              <div>
                <Text color="gray-darker" weight="regular" size="regular">
                  Last edited by&nbsp;
                </Text>
                <Text color="gray-darker" weight="bold" size="regular">
                  {editingNote.updatedByWorkerFirstName} {editingNote.updatedByWorkerLastName},&nbsp;
                  {moment(editingNote.updatedOn).format('DD MMMM YYYY, hh:mm A')}
                </Text>
              </div>
            )}
            {(noteType === BookingType.BOOKING || noteType === BookingType.ACTIVITY_RECORD) && (
              <>
                <div className="mt-large">
                  <SubTitle>Privacy</SubTitle>

                  <div className="mt-medium">
                    <Select
                      defaultValue={editNote ? editingNote && editingNote.visibleType : CustomerNoteVisibleType.PORTAL}
                      dropdownMatchSelectWidth={true}
                      onChange={(event) => {
                        this.setState({
                          visibleType: event,
                        });
                      }}
                      size="large"
                      className={'width-full'}
                      optionLabelProp="label"
                    >
                      <Option value={CustomerNoteVisibleType.PORTAL} label="Visible on workspace only">
                        <Text style={{ whiteSpace: 'pre-wrap' }}>
                          <b>Visible on the workspace only</b>
                          <br />
                          This note will be visible on the GoodHuman workspace only. Team members on the business app
                          cannot view this note.
                        </Text>
                      </Option>
                      <Option
                        value={CustomerNoteVisibleType.PORTAL_AND_APP}
                        label="Visible on workspace & business app"
                      >
                        <Text style={{ whiteSpace: 'pre-wrap' }}>
                          <b>Visible on the workspace & the business app</b>
                          <br />
                          This note will be visible on the GoodHuman workspace. Team members assigned to the booking can
                          also view this on the GoodHuman business app.
                        </Text>
                      </Option>
                    </Select>
                  </div>
                </div>
                <div className="mt-large">
                  <SubTitle>Attachment (Optional)</SubTitle>

                  <div className="mt-medium">
                    {_.isEmpty(selectedFile) && Utils.isEmpty(this.state.selectedDocumentName) ? (
                      <Upload multiple={false} beforeUpload={this._validateFile} showUploadList={false}>
                        <SecondaryButton icon={'upload'}>Select attachment...</SecondaryButton>
                      </Upload>
                    ) : (
                      <>
                        <Icon type="file" className="mr-x-small" />
                        {editNote && !Utils.isEmpty(this.state.selectedDocumentName)
                          ? this.state.selectedDocumentName
                          : selectedFile.name}
                        <HyperlinkButton className={'ml-small'} onClick={this._resetSelectedFile} color={'red-dark'}>
                          Remove attachment...
                        </HyperlinkButton>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}

            <div style={{ marginTop: 27 }} className="flex justify-end">
              <div>
                <GhostButton onClick={this._onCloseModel} size="large" loading={isLoading}>
                  Cancel
                </GhostButton>
              </div>
              {editNote ? (
                <div className="ml-medium">
                  <PrimaryButton onClick={this._onPressEditNote} loading={isLoading} size="large">
                    Save changes
                  </PrimaryButton>
                </div>
              ) : (
                <div className="ml-medium">
                  <PrimaryButton onClick={this._onPressAddNote} loading={isLoading} size="large">
                    Add Note
                  </PrimaryButton>
                </div>
              )}
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddBookingNote: dispatch.bookingsStore.doAddNote,
  doEditBookingNote: dispatch.bookingsStore.doEditNote,
  doAddCustomerNotes: dispatch.customersStore.doAddCustomerNotes,
  doEditCustomerNote: dispatch.customersStore.doEditCustomerNote,
  dofetchCustomerNotes: dispatch.customersStore.dofetchCustomerNotes,
  resetCustomerNotesPageInfo: dispatch.customersStore.resetCustomerNotesPageInfo,
  doFetchSingleBooking: dispatch.bookingsStore.doFetchSingleBooking,
});

export default connect(mapState, mapDispatch)(Form.create<IAddEditNoteModalProps>()(AddEditNoteModal));
