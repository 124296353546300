import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { Text } from 'common-components/typography';
import CommonUtils from 'utilities/common-utils';
import { ServiceType } from 'utilities/enum-utils';

class ViewQuotationQuoteItemView extends Component<any, any> {
  render() {
    const { quoteItem } = this.props;
    const totalQuoteAmount =
      quoteItem.serviceType === ServiceType.GROUP
        ? CommonUtils.formatPrice(Number(quoteItem.totalQuoteAmount))
        : CommonUtils.formatPrice(Number(quoteItem.qty) * quoteItem.price);

    return (
      <Row className={'pv-small bordered-bottom bordered-left bordered-right'} type={'flex'} align={'middle'}>
        <Col span={1} />
        <Col span={16} className={'pl-medium'}>
          <Text weight={'bold'}>{quoteItem.supportItemNumber}</Text>
          <br />
          <Text>{quoteItem.supportItemName}</Text>
        </Col>
        <Col span={4} className={'text-align-right'}>
          <Text weight={'bold'}>{totalQuoteAmount}</Text>
          <br />
          <Text size={'regular'} color={'gray'}>
            {quoteItem.isTravel
              ? `(${quoteItem.kms * quoteItem.numberOfBookings}km)`
              : `(${Number(quoteItem.qty)} x ${CommonUtils.formatPrice(quoteItem.price)})`}
          </Text>
        </Col>
        <Col span={3} />
      </Row>
    );
  }
}

export default ViewQuotationQuoteItemView;
