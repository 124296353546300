import React, { Component } from 'react';
import { ServDetailsSectionHeader } from '../common/ServDetailsSectionHeader';

import { Col, Radio, Row } from 'antd';

import { Calendar, Event, momentLocalizer } from 'react-big-calendar';

import moment from 'moment';

const localizer = momentLocalizer(moment);

const events: Event[] = [
  {
    title: 'Jonathan @ Prahran',
    allDay: false,
    start: new Date('2021-04-12T23:01:00.239Z'),
    end: new Date('2021-04-12T23:03:30.239Z')
  },
  {
    title: 'Jir @ South Yarra',
    allDay: false,
    start: new Date('2021-04-13T23:01:00.239Z'),
    end: new Date('2021-04-13T23:03:30.239Z')
  },
  {
    title: 'Tom  @ South Yarra',
    allDay: false,
    start: new Date('2021-04-14T23:05:00.239Z'),
    end: new Date('2021-04-15T23:07:30.239Z')
  }
];

const EventItem = ({ event }) => <div className="bg-secondary p-small rounded">{event.title}</div>;

export class ServiceBookingTab extends Component<any, any> {
  render() {
    return (
      <div className="mt-small">
        <Row type={'flex'} align={'bottom'}>
          <Col span={12}>
            <ServDetailsSectionHeader title={'Bookings overview'} hasDivider={false} />
          </Col>
          <Col span={12}>
            <div className="flex-row justify-end align-center">
              <Radio.Group>
                <Radio.Button defaultChecked={true}>Show List</Radio.Button>
                <Radio.Button>Show Calendar</Radio.Button>
              </Radio.Group>
            </div>
          </Col>
        </Row>

        <div style={{ height: '600px' }} className="mv-x-large ">
          <Calendar
            localizer={localizer}
            startAccessor={'start'}
            endAccessor={'end'}
            defaultView={'month'}
            events={events}
            components={{ event: EventItem }}
          />
        </div>
      </div>
    );
  }
}
