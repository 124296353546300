import React, { PureComponent } from 'react';
import { SupportWorkerAlertType } from 'utilities/enum-utils';
import { AlertTag } from 'common-components/tags';

export interface TeamAlertTagProps {
  alertType: SupportWorkerAlertType;
  numberOfAlerts: number;
  onClickView?: () => void;
  className?: string;
}

export interface TeamAlertTagState {}

class TeamAlertTag extends PureComponent<TeamAlertTagProps, TeamAlertTagState> {
  private _alertDescription(alertType: SupportWorkerAlertType) {
    if (alertType === SupportWorkerAlertType.DocumentExpired) {
      return `document${this.props.numberOfAlerts === 1 ? '' : 's'} expired.`;
    } else if (alertType === SupportWorkerAlertType.DocumentExpiringSoon) {
      return `document${this.props.numberOfAlerts === 1 ? '' : 's'} expiring soon.`;
    } else if (alertType === SupportWorkerAlertType.ComplianceDocumentExpired) {
      return `compliance document${this.props.numberOfAlerts === 1 ? '' : 's'} expired.`;
    } else if (alertType === SupportWorkerAlertType.ComplianceDocumentExpiringSoon) {
      return `compliance document${this.props.numberOfAlerts === 1 ? '' : 's'} expiring soon.`;
    }
  }
  private _alertColor(alertType: SupportWorkerAlertType) {
    if (
      alertType === SupportWorkerAlertType.ComplianceDocumentExpired ||
      alertType === SupportWorkerAlertType.DocumentExpired
    ) {
      return 'error';
    }
    return 'warning';
  }

  render() {
    const { onClickView, numberOfAlerts, alertType, ...props } = this.props;

    return (
      <AlertTag
        type={this._alertColor(alertType)}
        onClickView={() => onClickView()}
        text={
          <>
            <b>{numberOfAlerts}</b> {this._alertDescription(alertType)}
          </>
        }
        {...props}
      />
    );
  }
}

export default TeamAlertTag;
