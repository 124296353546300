import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Avatar } from 'antd';
import { getShiftSlotTheme } from 'common-components/roster-control/common/roster-utilities';
import { IRosterShift } from 'common-components/roster-control/interfaces/roster-interfaces';
import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';
import {
  getTimelineCardWidth,
  getTimelineOffset
} from 'common-components/roster-control/roster-daily/timeline-pane/common/card-width-utils';
import WorkerShiftPopover from 'common-components/roster-control/roster-daily/timeline-pane/workers/WorkerShiftPopover';
import { BookingTypeIcon } from 'common-components/tags';
import ShiftSlotStatusTag from 'common-components/tags/ShiftSlotStatusTag';
import { Text } from 'common-components/typography';
import * as H from 'history';
import moment from 'moment';
import React, { useState } from 'react';
import CommonUtils from 'utilities/common-utils';
import { BookingType, ServiceType } from 'utilities/enum-utils';

const { TIMELINE_CARD_HEIGHT, HOUR_BLOCK_WIDTH, TIMELINE_ROW_HEIGHT } = ROSTER_DAILY_CONFIG;

interface WorkerShiftCardProps {
  shift: IRosterShift;
  rowIndex?: number;
  history: H.History;
  refreshData?: () => void;
}

/* This component takes a start/end time, and calculates the position/width within a timeline. */
export function WorkerShiftCard({ shift, rowIndex, refreshData, history }: WorkerShiftCardProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [canClosePopover, setCanClosePopover] = useState<boolean>(true);

  const { shiftStartDateTime, shiftEndDateTime, shiftSlotStatus, serviceName } = shift;

  const mStartDate = moment(shiftStartDateTime);
  const mEndDate = moment(shiftEndDateTime);

  const startTimeDisplay = mStartDate.format('h:mm a');
  const endTimeDisplay = mEndDate.format('h:mm a');

  // Card properties
  const cardWidth = getTimelineCardWidth(shiftStartDateTime, shiftEndDateTime);
  const leftOffset = getTimelineOffset(shiftStartDateTime);
  const topOffset = rowIndex === 0 ? 4 : rowIndex * TIMELINE_ROW_HEIGHT + 4;

  // Determines whether this card is large enough to display text.
  const isSmallCard = cardWidth < HOUR_BLOCK_WIDTH / 2.6;

  const { bgColor, borderColor } = getShiftSlotTheme(shiftSlotStatus);

  const refreshAndCloseData = () => {
    setCanClosePopover(true);
    setIsOpen(false);
    refreshData();
  };

  const openClosePopover = (nextOpenState) => {
    if (canClosePopover) {
      setIsOpen(nextOpenState);
    }
  };

  return (
    <div
      className={`position-absolute rounded flex-column inline-block rounded-big cursor-pointer bordered anim-fade-in ${bgColor} ${borderColor}`}
      style={{
        width: `${cardWidth}px`,
        top: `${topOffset}px`,
        left: `${leftOffset}px`,
        height: `${TIMELINE_CARD_HEIGHT}px`,
        zIndex: 10
      }}
    >
      {/* Content for timeline item */}
      <Popover2
        isOpen={isOpen}
        content={
          <WorkerShiftPopover
            shift={shift}
            setCanClosePopover={setCanClosePopover}
            refreshAndCloseData={refreshAndCloseData}
            history={history}
          />
        }
        onInteraction={(nextOpenState) => openClosePopover(nextOpenState)}
        position="right-bottom"
      >
        <div
          className={`${isSmallCard ? 'pv-x-small ph-x2-small' : 'ph-small pv-x-small'} overflow-hidden`}
          style={{ width: `${cardWidth}px`, height: `${TIMELINE_CARD_HEIGHT}px` }}
        >
          {isSmallCard && (
            <div className="text-align-center">
              <Text size="x2-small" color="secondary">
                1:4
              </Text>
            </div>
          )}

          {!isSmallCard && (
            <div className={'flex-row justify-start align-center '}>
              <ShiftSlotStatusTag status={shiftSlotStatus} size={'small'} />
              {shift.serviceType === ServiceType.INDIVIDUAL && shift.bookingId && (
                <div className={'ml-small'}>
                  <Tooltip2
                    content={<Text color={'white'}>{shift.customerFirstName + ' ' + shift.customerLastName}</Text>}
                    position={'bottom-right'}
                  >
                    <div className={'flex-row align-center'}>
                      <Avatar
                        size={20}
                        className={'text-white'}
                        style={{
                          backgroundColor: CommonUtils.getBackgroundColor(
                            shift.customerFirstName + shift.customerLastName
                          )
                        }}
                        shape="circle"
                      >
                        {shift.customerFirstName && shift.customerFirstName[0].toUpperCase()}{' '}
                        {shift.customerLastName && shift.customerLastName[0].toUpperCase()}
                      </Avatar>
                    </div>
                  </Tooltip2>
                </div>
              )}
            </div>
          )}

          {!isSmallCard && (
            <div className="pt-x2-small flex-column line-height-100">
              <div className="text-overflow-ellipsis overflow-hidden whitespace-nowrap">
                <Text lineHeight={100} size="small" className="whitespace-nowrap">
                  <b>{startTimeDisplay}</b> - {endTimeDisplay}
                </Text>
              </div>
              <div className={'flex-row align-center'}>
                <BookingTypeIcon
                  type={
                    shift.bookingType === BookingType.ACTIVITY_RECORD ? BookingType.ACTIVITY_RECORD : shift.serviceType
                  }
                  className={'mr-x-small'}
                />
                <div className="text-overflow-ellipsis overflow-hidden whitespace-nowrap">
                  <Text lineHeight={100} size="regular" className="whitespace-nowrap">
                    {serviceName}
                  </Text>
                </div>
              </div>
            </div>
          )}
        </div>
      </Popover2>
    </div>
  );
}
