import React from 'react';

import { Avatar } from 'antd';
import { Popover } from '@blueprintjs/core';

import { Text } from 'common-components/typography';
import { IconButton } from 'common-components/buttons';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import CommonUtils from 'utilities/common-utils';
import * as H from 'history';

import { ROSTER_WEEKLY_CONFIG } from 'common-components/roster-control/roster-weekly/roster-weekly-config';

import { IRosterWorker } from 'common-components/roster-control/interfaces/roster-interfaces';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { connect } from 'react-redux';

const { CONTENT_PANEL_WIDTH } = ROSTER_WEEKLY_CONFIG;

interface WorkerDetailsColumnProps {
  worker: IRosterWorker;
  costTotal: number;
  totalDuration: number;
  history: H.History;
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;
}

function WorkerDetailsColumn({
  worker,
  costTotal,
  totalDuration,
  setSelectedSideNavMenuKeys,
  history
}: WorkerDetailsColumnProps) {
  const { workerLastName, workerFirstName, workerAvatarUrl } = worker;

  const workerFullName = `${workerFirstName} ${workerLastName}`;

  const goToWorker = () => {
    setSelectedSideNavMenuKeys(['/services']);
    return history.push(`/team/details/${worker.supportWorkerId}`);
  };

  return (
    <div
      className="flex-row pv-large pl-x-large pr-medium bg-quaternary justify-between bordered-right bordered-bottom"
      style={{ minWidth: `${CONTENT_PANEL_WIDTH}px`, maxWidth: `${CONTENT_PANEL_WIDTH}px` }}
    >
      <div className="flex-row">
        <Avatar shape="square" size="large" icon="user" className="mr-small" src={workerAvatarUrl} />
        <div
          className="line-height-135 block text-overflow-ellipsis overflow-hidden whitespace-nowrap"
          style={{ width: `${CONTENT_PANEL_WIDTH - 128}px` }}
        >
          <Text lineHeight={120}>{workerFullName}</Text> <br />
          <Text size="regular" color="secondary" lineHeight={135}>
            {CommonUtils.formatDurationFromSecondsString(totalDuration)}
          </Text>
        </div>
      </div>

      <Popover
        content={
          <ActionMenu>
            <ActionMenuItem text="View team member profile" onClick={goToWorker} />
          </ActionMenu>
        }
        position={'bottom-left'}
      >
        <IconButton
          icon="ellipsis"
          size="small"
          className="bg-white"
          bordered={true}
          iconColor="secondary"
          onClick={() => {}}
        />
      </Popover>
    </div>
  );
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys
});

export default connect(
  null,
  mapDispatch
)(WorkerDetailsColumn);
