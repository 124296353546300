import React, { Component } from 'react';
import { LeftNavItem } from 'common-components/navigation/LeftNavItem';
import { PLAN_PAYMENTLIST_FILTERCONFIGS } from '../components/planPayListFilterConfig';

interface IPlanPayListNavigationSectionProps {
  currentFilterConfig: any;
  setFilter: any;
}

class PlanPayListNavigationSection extends Component<IPlanPayListNavigationSectionProps, any> {
  _handleChangeFilter = (filterKey) => {
    const { setFilter } = this.props;
    setFilter(filterKey);
  };

  render() {
    const { currentFilterConfig } = this.props;

    return (
      <div className="bg-quaternary" style={{ width: '300px', overflowY: 'auto' }}>
        <div className="mt-x3-large mh-large">
          <div>
            <LeftNavItem
              filterConfig={PLAN_PAYMENTLIST_FILTERCONFIGS.READY_FOR_CLAIM}
              onClick={this._handleChangeFilter}
              isSelected={currentFilterConfig.key === PLAN_PAYMENTLIST_FILTERCONFIGS.READY_FOR_CLAIM.key}
            />
          </div>

          <div className="mb-large">
            <LeftNavItem
              filterConfig={PLAN_PAYMENTLIST_FILTERCONFIGS.CLAIMED}
              onClick={this._handleChangeFilter}
              isSelected={currentFilterConfig.key === PLAN_PAYMENTLIST_FILTERCONFIGS.CLAIMED.key}
            />
          </div>

          <div>
            <LeftNavItem
              filterConfig={PLAN_PAYMENTLIST_FILTERCONFIGS.PAID}
              onClick={this._handleChangeFilter}
              isSelected={currentFilterConfig.key === PLAN_PAYMENTLIST_FILTERCONFIGS.PAID.key}
            />
          </div>

          <div className="mb-large">
            <LeftNavItem
              filterConfig={PLAN_PAYMENTLIST_FILTERCONFIGS.PAYMENT_ISSUES}
              onClick={this._handleChangeFilter}
              isSelected={currentFilterConfig.key === PLAN_PAYMENTLIST_FILTERCONFIGS.PAYMENT_ISSUES.key}
            />
          </div>

          <div className="mb-large">
            <LeftNavItem
              filterConfig={PLAN_PAYMENTLIST_FILTERCONFIGS.CLAIM_BATCHES}
              onClick={this._handleChangeFilter}
              isSelected={currentFilterConfig.key === PLAN_PAYMENTLIST_FILTERCONFIGS.CLAIM_BATCHES.key}
            />
          </div>

          <div>
            <LeftNavItem
              filterConfig={PLAN_PAYMENTLIST_FILTERCONFIGS.REJECTED}
              onClick={this._handleChangeFilter}
              isSelected={currentFilterConfig.key === PLAN_PAYMENTLIST_FILTERCONFIGS.REJECTED.key}
            />
          </div>
          <div>
            <LeftNavItem
              filterConfig={PLAN_PAYMENTLIST_FILTERCONFIGS.WAIVED}
              onClick={this._handleChangeFilter}
              isSelected={currentFilterConfig.key === PLAN_PAYMENTLIST_FILTERCONFIGS.WAIVED.key}
            />
          </div>
        </div>
      </div>
    );
  }
}

export { PlanPayListNavigationSection };
