/* eslint-disable */
import React, { PureComponent, ReactNode } from 'react';
import { PORTAL_COLORS, PORTAL_FONT_SIZES, PORTAL_FONT_WEIGHTS } from 'interfaces/common-ui-types';
import _ from 'lodash';

interface ITagProps {
  content: ReactNode;
  rounded?: boolean;
  weight?: PORTAL_FONT_WEIGHTS;
  color?: PORTAL_COLORS;
  backgroundColor?: PORTAL_COLORS;
  theme?: 'dark' | 'light' | string;
  size?: 'default' | 'small' | string;
  textSize?: PORTAL_FONT_SIZES;
  className?: string;
  isFixedWidth?: boolean;
  width?: number;
}

export default class TextTag extends PureComponent<ITagProps, any> {
  render() {
    const {
      weight = 'regular',
      rounded = true,
      content,
      color = 'blue',
      theme = 'dark',
      textSize = 'regular',
      size = 'default',
      className = '',
      backgroundColor,
      isFixedWidth = false,
      width = 120,
      ...otherProps
    } = this.props;

    const roundedClass = rounded ? 'rounded' : '';

    const isDark = theme === 'dark';

    let bgClassName = isDark ? `bg-${color}` : `bg-${color}-lightest`;
    let textColor = isDark ? 'text-color-white' : `text-color-${color}-dark`;
    let borderClass = isDark ? `bordered border-${color}` : `bordered border-${color}-lighter`;
    let textWeightClass = `text-weight-${weight}`;
    let textSizeClass = `text-size-${textSize}`;
    let lineHeight = `line-height-120`;

    let paddingSize = `ph-small pv-x-small`; // default padding Size

    let widthStyle = isFixedWidth ? { maxWidth: `${width}px` } : {};

    // Exceptions for secondary / tertiary / quaternary colors
    if (color === 'black') {
    }

    if (color === 'secondary') {
      // No dark / light variants
      if (theme === 'dark') {
        bgClassName = 'bg-secondary';
        textColor = 'text-color-secondary';
        borderClass = 'bordered border-standard-gray';
      } else {
        bgClassName = 'bg-white';
        textColor = 'text-color-secondary';
        borderClass = 'bordered border-standard-gray';
      }
    }

    if (color === 'tertiary') {
      if (theme === 'dark') {
        bgClassName = 'bg-tertiary';
        textColor = 'text-color-secondary';
        borderClass = 'bordered border-tertiary';
      } else {
        bgClassName = 'bg-tertiary';
        textColor = 'text-color-secondary';
        borderClass = 'bordered border-standard-gray';
      }
    }

    if (color === 'quaternary') {
      if (theme === 'dark') {
        bgClassName = 'bg-quaternary';
        textColor = 'text-color-secondary';
        borderClass = 'bordered border-quaternary';
      } else {
        bgClassName = 'bg-quaternary';
        textColor = 'text-color-secondary';
        borderClass = 'bordered border-standard-gray';
      }
    }

    if (color === 'orange' && theme === 'light') {
      textColor = 'text-color-orange-dark';
    }

    if (size === 'small') {
      paddingSize = 'ph-x-small pv-x2-small';
      textSizeClass = !_.isEmpty(textSize) ? `text-size-${textSize}` : 'text-size-small';
      lineHeight = 'line-height-100';
    }

    if (!_.isEmpty(backgroundColor)) {
      bgClassName = `bg-${backgroundColor}`;
    }

    const finalClassName =
      `${roundedClass} ${bgClassName} ${textColor} ${borderClass} ${textWeightClass} ${textSizeClass} ${paddingSize} ` +
      `inline-block ${lineHeight} ${className} text-overflow-ellipsis overflow-hidden whitespace-nowrap`;

    return (
      <div className={finalClassName} style={{ ...widthStyle }} {...otherProps}>
        {content}
      </div>
    );
  }
}
