import React, { Component } from 'react';
import { Col, Empty, Form, notification, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { Paragraph, Text } from 'common-components/typography';
import { GhostButton, PrimaryButton, HyperlinkButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import _ from 'lodash';
import moment from 'moment-timezone';

interface ICloseSessionModalProps extends FormComponentProps {
  closeModal: () => void;
  isOpen: boolean;
  selectedSession: typeof state.groupServiceStore.selectedSession;
  doCloseSession: typeof dispatch.groupServiceStore.doCloseSession;
  doCheckCloseSession: typeof dispatch.groupServiceStore.doCheckCloseSession;
  setSelectedSessionTabKey: typeof dispatch.groupServiceStore.setSelectedSessionTabKey;
}

interface ICloseSessionModalState {
  title: string;
  isLoading: boolean;
  isChecking: boolean;
  isSuccess: boolean;
  isCanBeClosed: {
    status: boolean;
    attendanceErrors: any[];
    billingErrors: any[];
    supportWorkerErrors: any[];
  };
  selectedErrorTab: string;
}

const errorTabLabels = {
  attendances: 'attendances',
  billings: 'billings',
  incompleteShifts: 'incompleteShifts'
};

class CloseSessionModal extends Component<ICloseSessionModalProps, ICloseSessionModalState> {
  state = {
    title: 'Close session',
    isLoading: false,
    isChecking: false,
    isSuccess: false,
    isCanBeClosed: null,
    selectedErrorTab: errorTabLabels.attendances
  };

  private _closeModal = () => {
    this.setState({
      title: 'Close session',
      isLoading: false,
      isChecking: false,
      isSuccess: false,
      isCanBeClosed: null,
      selectedErrorTab: errorTabLabels.attendances
    });
    this.props.closeModal();
  };

  private _closeSession = async () => {
    const { selectedSession } = this.props;
    this.setState({ isLoading: true });
    try {
      await this.props.doCloseSession({
        serviceId: selectedSession.serviceId,
        serviceDateTimeId: selectedSession.serviceDateTimeId
      });
      this._closeModal();
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isLoading: false });
    }
  };

  private _changeSelectedErrorTab = (errorTab) => {
    this.setState({ selectedErrorTab: errorTab });
  };

  private _goToTab = async (selectedTab) => {
    await this.props.setSelectedSessionTabKey(selectedTab);
    this._closeModal();
  };

  componentDidUpdate = async (
    prevProps: Readonly<ICloseSessionModalProps>,
    prevState: Readonly<ICloseSessionModalState>,
    snapshot?: any
  ) => {
    const { selectedSession } = this.props;
    if (!prevProps.isOpen && this.props.isOpen && this.props.selectedSession) {
      this.setState({ isChecking: true });
      const result: any = await this.props.doCheckCloseSession({
        serviceId: selectedSession.serviceId,
        serviceDateTimeId: selectedSession.serviceDateTimeId
      });
      this.setState({ isChecking: false, isCanBeClosed: result });
    }
  };

  render() {
    const { selectedSession } = this.props;
    const { title, isLoading, isChecking, isSuccess, isCanBeClosed, selectedErrorTab } = this.state;

    // Attendance errors
    const attendanceNotStartedErrors =
      isCanBeClosed && isCanBeClosed.attendanceErrors && isCanBeClosed.attendanceErrors.bookingNotStarted;
    const attendanceNotCompletedErrors =
      isCanBeClosed && isCanBeClosed.attendanceErrors && isCanBeClosed.attendanceErrors.bookingNotCompleted;
    const attendanceErrorCount =
      (attendanceNotStartedErrors && attendanceNotStartedErrors.length) +
      (attendanceNotCompletedErrors && attendanceNotCompletedErrors.length);
    // Billing errors
    const billingLineItemMissingErrors =
      isCanBeClosed && isCanBeClosed.billingErrors && isCanBeClosed.billingErrors.lineItemMissing;
    const billingNotApprovedErrors =
      isCanBeClosed && isCanBeClosed.billingErrors && isCanBeClosed.billingErrors.bookingNotApproved;
    const billingErrorCount =
      (billingLineItemMissingErrors && billingLineItemMissingErrors.length) +
      (billingNotApprovedErrors && billingNotApprovedErrors.length);
    // Support worker errors
    const workerShiftNotApprovedErrors =
      isCanBeClosed && isCanBeClosed.supportWorkerErrors && isCanBeClosed.supportWorkerErrors.shiftNotApproved;
    const workerShiftNotCompletedErrors =
      isCanBeClosed && isCanBeClosed.supportWorkerErrors && isCanBeClosed.supportWorkerErrors.shiftNotCompleted;
    const workerShiftNotStartedErrors =
      isCanBeClosed && isCanBeClosed.supportWorkerErrors && isCanBeClosed.supportWorkerErrors.shiftNotStarted;
    const workerShiftNotConfirmedErrors =
      isCanBeClosed && isCanBeClosed.supportWorkerErrors && isCanBeClosed.supportWorkerErrors.shiftNotConfirmed;
    const supportWorkerIncompleteShiftErrors =
      (workerShiftNotApprovedErrors && workerShiftNotApprovedErrors.length) +
      (workerShiftNotCompletedErrors && workerShiftNotCompletedErrors.length) +
      (workerShiftNotStartedErrors && workerShiftNotStartedErrors.length) +
      (workerShiftNotConfirmedErrors && workerShiftNotConfirmedErrors.length);

    if (isCanBeClosed && selectedErrorTab === errorTabLabels.attendances && attendanceErrorCount === 0) {
      if (billingErrorCount > 0) {
        this.setState({ selectedErrorTab: errorTabLabels.billings });
      } else {
        this.setState({ selectedErrorTab: errorTabLabels.incompleteShifts });
      }
    }

    return (
      <div>
        <ActionModal isOpen={this.props.isOpen} title={title} onClose={this._closeModal} width={'large'}>
          {isChecking ? (
            <SpinningLoader size={100} message={'Checking if the session can be closed...'} />
          ) : (
            selectedSession && (
              <>
                {isCanBeClosed && !isCanBeClosed.status ? (
                  <>
                    <div>
                      <Paragraph>
                        There are unresolved issues detected. <br /> You'll need to resolve them before proceeding to
                        close this session.
                      </Paragraph>
                      <Row gutter={12}>
                        <Col span={7}>
                          <div className={'mb-medium'}>
                            <Text weight={'bold'}>Customer errors</Text>
                          </div>
                          <div className={'mb-medium'}>
                            {selectedErrorTab === errorTabLabels.attendances ? (
                              <Text color={'blue-action'} weight={'bold'}>
                                Attendances ({Number(attendanceErrorCount)})
                              </Text>
                            ) : (
                              <HyperlinkButton
                                onClick={() => this._changeSelectedErrorTab(errorTabLabels.attendances)}
                                color={Number(attendanceErrorCount) > 0 ? 'secondary' : 'tertiary'}
                              >
                                Attendances ({Number(attendanceErrorCount)})
                              </HyperlinkButton>
                            )}
                          </div>
                          <div className={'mb-medium'}>
                            {selectedErrorTab === errorTabLabels.billings ? (
                              <Text color={'blue-action'} weight={'bold'}>
                                Billings ({Number(billingErrorCount)})
                              </Text>
                            ) : (
                              <HyperlinkButton
                                onClick={() => this._changeSelectedErrorTab(errorTabLabels.billings)}
                                color={Number(billingErrorCount) > 0 ? 'secondary' : 'tertiary'}
                              >
                                Billings ({Number(billingErrorCount)})
                              </HyperlinkButton>
                            )}
                          </div>
                          <div className={'mb-medium'}>
                            <Text weight={'bold'}>Team member errors</Text>
                          </div>
                          <div className={'mb-medium'}>
                            {selectedErrorTab === errorTabLabels.incompleteShifts ? (
                              <Text color={'blue-action'} weight={'bold'}>
                                Incomplete shift ({Number(supportWorkerIncompleteShiftErrors)})
                              </Text>
                            ) : (
                              <HyperlinkButton
                                onClick={() => this._changeSelectedErrorTab(errorTabLabels.incompleteShifts)}
                                color={Number(supportWorkerIncompleteShiftErrors) > 0 ? 'secondary' : 'tertiary'}
                              >
                                Incomplete shift ({Number(supportWorkerIncompleteShiftErrors)})
                              </HyperlinkButton>
                            )}
                          </div>
                        </Col>
                        <Col span={17}>
                          {selectedErrorTab === errorTabLabels.attendances && (
                            <>
                              {attendanceNotStartedErrors && attendanceNotStartedErrors.length > 0 && (
                                <div>
                                  <div className={'mb-small'}>
                                    <Text color={'secondary'}>Booking not started</Text>
                                  </div>
                                  {_.map(attendanceNotStartedErrors, (error, index) => {
                                    return (
                                      <div
                                        className={`bordered flex-row rounded mb-${
                                          Number(index) === attendanceNotStartedErrors.length - 1 ? 'medium' : 'small'
                                        }`}
                                      >
                                        <div className={'bg-red-lighter rounded-left'} style={{ width: '3px' }} />
                                        <div className={`p-medium`}>
                                          <Text>
                                            <b>
                                              {error.firstName} {error.lastName}
                                            </b>{' '}
                                            booking has not started.
                                          </Text>
                                          <div className={'mt-small'}>
                                            <HyperlinkButton onClick={() => this._goToTab('CUSTOMERS')}>
                                              View bookings
                                            </HyperlinkButton>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                              {attendanceNotCompletedErrors && attendanceNotCompletedErrors.length > 0 && (
                                <div>
                                  <div className={'mb-small'}>
                                    <Text color={'secondary'}>Booking not completed</Text>
                                  </div>
                                  {_.map(attendanceNotCompletedErrors, (error, index) => {
                                    return (
                                      <div
                                        className={`bordered flex-row rounded mb-${
                                          Number(index) === attendanceNotCompletedErrors.length - 1 ? 'medium' : 'small'
                                        }`}
                                      >
                                        <div className={'bg-red-lighter rounded-left'} style={{ width: '3px' }} />
                                        <div className={`p-medium`}>
                                          <Text>
                                            <b>
                                              {error.firstName} {error.lastName}
                                            </b>{' '}
                                            booking has not been completed.
                                          </Text>
                                          <div className={'mt-small'}>
                                            <HyperlinkButton onClick={() => this._goToTab('CUSTOMERS')}>
                                              View bookings
                                            </HyperlinkButton>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </>
                          )}
                          {selectedErrorTab === errorTabLabels.billings && (
                            <>
                              {billingLineItemMissingErrors && billingLineItemMissingErrors.length > 0 && (
                                <div>
                                  <div className={'mb-small'}>
                                    <Text color={'secondary'}>Line items missing management method</Text>
                                  </div>
                                  {_.map(billingLineItemMissingErrors, (error, index) => {
                                    return (
                                      <div
                                        className={`bordered flex-row rounded mb-${
                                          Number(index) === billingLineItemMissingErrors.length - 1 ? 'medium' : 'small'
                                        }`}
                                      >
                                        <div className={'bg-red-lighter rounded-left'} style={{ width: '3px' }} />
                                        <div className={`p-medium`}>
                                          <Text>
                                            <b>
                                              {error.firstName} {error.lastName}
                                            </b>{' '}
                                            has {error.numberOfLineItemError} line item
                                            {error.numberOfLineItemError !== 1 && 's'} missing management method.
                                          </Text>
                                          <div className={'mt-small'}>
                                            <HyperlinkButton onClick={() => this._goToTab('CUSTOMERS')}>
                                              View bookings
                                            </HyperlinkButton>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                              {billingNotApprovedErrors && billingNotApprovedErrors.length > 0 && (
                                <div>
                                  <div className={'mb-small'}>
                                    <Text color={'secondary'}>Booking not approved</Text>
                                  </div>
                                  {_.map(billingNotApprovedErrors, (error, index) => {
                                    return (
                                      <div
                                        className={`bordered flex-row rounded mb-${
                                          Number(index) === billingNotApprovedErrors.length - 1 ? 'medium' : 'small'
                                        }`}
                                      >
                                        <div className={'bg-red-lighter rounded-left'} style={{ width: '3px' }} />
                                        <div className={`p-medium`}>
                                          <Text>
                                            <b>
                                              {error.firstName} {error.lastName}
                                            </b>{' '}
                                            booking has not been approved.
                                          </Text>
                                          <div className={'mt-small'}>
                                            <HyperlinkButton onClick={() => this._goToTab('CUSTOMERS')}>
                                              View bookings
                                            </HyperlinkButton>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              )}
                            </>
                          )}
                          {selectedErrorTab === errorTabLabels.incompleteShifts && (
                            <>
                              {workerShiftNotApprovedErrors && workerShiftNotApprovedErrors.length > 0 && (
                                <SupportWorkerErrorItem
                                  errors={workerShiftNotApprovedErrors}
                                  errorLabel={'approved'}
                                  timezone={selectedSession.timezone}
                                  goToTab={this._goToTab}
                                />
                              )}
                              {workerShiftNotCompletedErrors && workerShiftNotCompletedErrors.length > 0 && (
                                <SupportWorkerErrorItem
                                  errors={workerShiftNotCompletedErrors}
                                  errorLabel={'completed'}
                                  timezone={selectedSession.timezone}
                                  goToTab={this._goToTab}
                                />
                              )}
                              {workerShiftNotStartedErrors && workerShiftNotStartedErrors.length > 0 && (
                                <SupportWorkerErrorItem
                                  errors={workerShiftNotStartedErrors}
                                  errorLabel={'started'}
                                  timezone={selectedSession.timezone}
                                  goToTab={this._goToTab}
                                />
                              )}
                              {workerShiftNotConfirmedErrors && workerShiftNotConfirmedErrors.length > 0 && (
                                <SupportWorkerErrorItem
                                  errors={workerShiftNotConfirmedErrors}
                                  errorLabel={'confirmed'}
                                  timezone={selectedSession.timezone}
                                  goToTab={this._goToTab}
                                />
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </div>
                    <div className={'mt-large'}>
                      <Row type={'flex'} justify={'end'}>
                        <PrimaryButton size="large" onClick={this._closeModal}>
                          Close
                        </PrimaryButton>
                      </Row>
                    </div>
                  </>
                ) : isSuccess ? (
                  <>
                    <div className="mb-large">
                      <Paragraph>You have successfully closed this session.</Paragraph>
                    </div>
                    <div className={'mt-large'}>
                      <Row type={'flex'} justify={'end'}>
                        <PrimaryButton size="large" onClick={this._closeModal}>
                          Close
                        </PrimaryButton>
                      </Row>
                    </div>
                  </>
                ) : (
                  <div className="anim-slide-left">
                    <Paragraph>Are you sure you want to close this session?</Paragraph>

                    <div className={'mb-small'}>
                      <Row type={'flex'} justify={'end'}>
                        <Col>
                          <GhostButton size="large" onClick={this._closeModal}>
                            Cancel
                          </GhostButton>
                        </Col>
                        <Col>
                          <PrimaryButton loading={isLoading} onClick={this._closeSession} size="large">
                            Close session
                          </PrimaryButton>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
              </>
            )
          )}
        </ActionModal>
      </div>
    );
  }
}

const SupportWorkerErrorItem = ({ errors, errorLabel, timezone, goToTab }) => (
  <div>
    <div className={'mb-small'}>
      <Text color={'secondary'}>Booking not {errorLabel}</Text>
    </div>
    {_.map(errors, (error, index) => {
      return (
        <div className={`bordered flex-row rounded mb-${Number(index) === errors.length - 1 ? 'medium' : 'small'}`}>
          <div className={'bg-red-lighter rounded-left'} style={{ width: '3px' }} />
          <div className={`p-medium`}>
            <Paragraph>
              <b>
                {error.firstName} {error.lastName}
              </b>{' '}
              shift has not been {errorLabel}.
            </Paragraph>
            <Paragraph className={'mb-small'}>
              {moment.tz(error.shiftStartDateTime, timezone).format('ddd Do MMMM YYYY')}
              <br />
              {moment.tz(error.shiftStartDateTime, timezone).format('hh:mmA')} -{' '}
              {moment.tz(error.shiftEndDateTime, timezone).format('hh:mmA')}
            </Paragraph>
            <div>
              <HyperlinkButton onClick={() => goToTab('TEAM-MEMBERS')}>View shifts</HyperlinkButton>
            </div>
          </div>
        </div>
      );
    })}
  </div>
);

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCloseSession: dispatch.groupServiceStore.doCloseSession,
  doCheckCloseSession: dispatch.groupServiceStore.doCheckCloseSession,
  setSelectedSessionTabKey: dispatch.groupServiceStore.setSelectedSessionTabKey
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<ICloseSessionModalProps>()(CloseSessionModal));
