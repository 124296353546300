// @ts-ignore
import React, { Component } from 'react';
import _ from 'lodash';
import { Checkbox, Icon } from 'antd';
import { SubTitle, Text } from 'common-components/typography';
import CommonUtils from 'utilities/common-utils';
// import PaymentItemRow from './PaymentItemRow';
import { GhostButton, HyperlinkButton } from 'common-components/buttons';
import PermissionUtils from 'utilities/permission-utils';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Menu, Popover } from '@blueprintjs/core';
import LineItemRow from 'views/plan-management/payments/components/LineItemRow';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { PlanManagementClaimStatus, PlanManagementPaymentStatus } from 'utilities/enum-utils';

interface IPlanPaymentInvoiceRowProps {
  invoiceItem?: any;
  history?: any;
  openActionModal?: any;
  onCheckItem?: (planManagementInvoiceId) => void;
  canProcess: boolean;
  portalUser: typeof state.authStore.portalUser;
  displayCheckbox: boolean;
  actions: Array<any>;
  subActions: Array<any>;
  type: string;
  planManagementLineItemList: typeof state.planManagementStore.planManagementLineItemList;
  doFetchPlanManagementInvoiceDetails: typeof dispatch.planManagementStore.doFetchPlanManagementInvoiceDetails;
}

class PlanPaymentInvoiceRow extends Component<IPlanPaymentInvoiceRowProps> {
  _checkbox = null;
  state = {
    userId: null,
    showSubLines: false,
    openAction: false,
    isLoading: true
  };

  private _onCheckInvoice = (e, planManagementInvoiceId) => {
    e.stopPropagation();
    e.preventDefault();
    const { onCheckItem } = this.props;

    onCheckItem(planManagementInvoiceId);
  };

  private _onClickOpen = async () => {
    const { invoiceItem, doFetchPlanManagementInvoiceDetails } = this.props;
    this.setState({ isLoading: true, showSubLines: true });
    // !_.find(
    //   planManagementLineItemList,
    //   (invoiceLineItems) => invoiceLineItems.planManagementInvoiceId === invoiceItem.planManagementInvoiceId
    // ) &&
    //   (await doFetchPlanManagementInvoiceDetails({
    //     planManagementInvoiceId: invoiceItem.planManagementInvoiceId
    //   }));
    await doFetchPlanManagementInvoiceDetails({ planManagementInvoiceId: invoiceItem.planManagementInvoiceId });
    this.setState({ isLoading: false });
  };

  private _onClickClose = async () => {
    this.setState({ showSubLines: false });
  };

  private _onClickCustomer = (userId) => {
    const { history } = this.props;
    history.push(`/customer/details/${userId}`);
  };

  private _onClickSupplier = (planManagementSupplierId) => {
    const { history } = this.props;
    history.push(`/plan-management/provider/details/${planManagementSupplierId}`);
  };

  private _onClickInvoice = (planManagementInvoiceId) => {
    const { history } = this.props;
    history.push(`/plan-management/invoice/details/${planManagementInvoiceId}`);
  };

  // componentDidMount = () => {
  //   const { invoiceItem } = this.props;
  //
  //   if (!_.isEmpty(invoiceItem)) {
  //     const { customerUserId } = invoiceItem;
  //     this.setState({ customer });
  //   }
  // };

  render() {
    const {
      invoiceItem,
      canProcess,
      portalUser,
      displayCheckbox,
      actions,
      subActions,
      openActionModal,
      planManagementLineItemList
    } = this.props;

    if (_.isEmpty(invoiceItem)) {
      return <></>;
    }

    const {
      invoiceIssuedDate,
      invoiceNumber,
      firstName,
      lastName,
      customerUserId,
      numberOfItem,
      planManagementSupplierId,
      supplierName,
      total,
      isSelected,
      indeterminateCheck,
      invoiceTotal
    } = invoiceItem;

    const { type } = this.props;

    const displayBorder = this.state.showSubLines ? 'bordered-none' : '';

    // const numberOfErrors = errorTypes.length;

    // const numberOfLinesWithErrors = _.countBy(invoiceItem.billingItems, (line) => {
    //   return line.isError;
    // });

    // const disabledCheckbox = numberOfLinesWithErrors.true >= numberOfItems;
    const disabledCheckbox = false;
    const timezone = portalUser.timezone;
    const lineItemColSpan =
      6 +
      (displayCheckbox ? 1 : 0) +
      (actions && actions.length >= 1 ? 1 : 0) +
      (type === 'CLAIMED' || type === 'PAID' || type === 'PAYMENT_ISSUES' ? 2 : 0);

    const popoverContent = (
      <Menu>
        <div style={{ padding: '8px' }}>
          <Text strong type={'secondary'}>
            Invoice actions
          </Text>
        </div>
        {_.find(actions, (action) => action === 'WAIVE_INVOICE') && (
          <Menu.Item
            text={
              <div
                onClick={() =>
                  openActionModal({ action: 'WAIVE_INVOICE', additionalData: { type: 'INVOICE', item: invoiceItem } })
                }
              >
                <Text>Waive invoice</Text>
              </div>
            }
            className="hover-bg-gray-lightest"
          />
        )}
        {_.find(actions, (action) => action === 'REJECT') && (
          <Menu.Item
            text={
              <div onClick={() => openActionModal({ action: 'REJECT', additionalData: null })}>
                <Text color="red">Reject</Text>
              </div>
            }
            className="hover-bg-gray-lightest"
          />
        )}
        {_.find(actions, (action) => action === 'REJECT_INVOICE') && (
          <Menu.Item
            text={
              <div
                onClick={() =>
                  openActionModal({
                    action: 'REJECT_INVOICE',
                    additionalData: { type: 'REJECT_INVOICE', item: invoiceItem }
                  })
                }
              >
                <Text color="red">Reject invoice</Text>
              </div>
            }
            className="hover-bg-gray-lightest"
          />
        )}
        {_.find(actions, (action) => action === 'REJECT_LINE_ITEMS') && (
          <Menu.Item
            text={
              <div
                onClick={() =>
                  openActionModal({
                    action: 'REJECT_LINE_ITEMS',
                    additionalData: { type: 'REJECT_LINE_ITEMS', item: invoiceItem }
                  })
                }
              >
                <Text color="red">Reject line items</Text>
              </div>
            }
            className="hover-bg-gray-lightest"
          />
        )}
      </Menu>
    );

    const invoiceDetailsLineItems = _.find(
      planManagementLineItemList,
      (invoiceLineItems) => invoiceLineItems.planManagementInvoiceId === invoiceItem.planManagementInvoiceId
    );

    const invoiceLineItems = invoiceDetailsLineItems
      ? _.filter(invoiceDetailsLineItems.lineItems, (lineItem) => {
          console.log('lineItem: ', lineItem);
          if (type === 'CLAIMED') {
            return lineItem.claimStatus === PlanManagementClaimStatus.CLAIMED;
          } else if (type === 'READY_FOR_CLAIM') {
            return lineItem.claimStatus === PlanManagementClaimStatus.SENT_TO_PAYMENTS;
          } else if (type === 'WAIVED') {
            return lineItem.claimStatus === PlanManagementClaimStatus.WAIVED;
          } else if (type === 'REJECTED') {
            return lineItem.claimStatus === PlanManagementClaimStatus.REJECTED;
          } else if (type === 'PAID') {
            return lineItem.claimStatus === PlanManagementClaimStatus.CLAIMED;
          } else if (type === 'PAYMENT_ISSUES') {
            return lineItem;
          }
        })
      : [];
    return (
      <>
        <tr
          onClick={this.state.showSubLines ? this._onClickClose : this._onClickOpen}
          className={`${!isSelected && 'hover-bg-tertiary'} anim-fade-in ${
            isSelected ? 'bg-blue-lightest' : 'bg-white'
          }`}
        >
          {displayCheckbox && (
            <td
              className={`${displayBorder} fluid-cell check-all `}
              onClick={(e) => this._onCheckInvoice(e, invoiceItem.planManagementInvoiceId)}
              onMouseEnter={() => this._checkbox.focus()}
              onMouseLeave={() => this._checkbox.blur()}
            >
              {canProcess && (
                <Checkbox
                  checked={isSelected}
                  disabled={disabledCheckbox}
                  indeterminate={indeterminateCheck}
                  ref={(c) => (this._checkbox = c)}
                />
              )}
            </td>
          )}
          <td
            className={`${displayBorder} fluid-cell `}
            onClick={this.state.showSubLines ? this._onClickClose : this._onClickOpen}
          >
            <div className="pr-small">
              {this.state.showSubLines ? (
                <Icon className="size-x-large" type="up" />
              ) : (
                <Icon className="size-x-large" type="down" />
              )}
            </div>
          </td>
          <td className={`${displayBorder} fluid-cell `}>
            {moment.tz(invoiceIssuedDate, timezone).format('DD/MM/YYYY')}
          </td>
          <td className={`${displayBorder} ellipsis `}>
            <HyperlinkButton onClick={() => this._onClickInvoice(invoiceItem.planManagementInvoiceId)}>
              {invoiceNumber}
            </HyperlinkButton>
            <br />
            {type === 'REJECTED' ? (
              <Text color={'red-dark'}>
                <b>{numberOfItem}</b> rejected line item{numberOfItem > 1 && 's'}
              </Text>
            ) : type === 'WAIVED' ? (
              <Text color={'indigo'}>
                <b>{numberOfItem}</b> waived line item{numberOfItem > 1 && 's'}
              </Text>
            ) : (
              <Text color={'secondary'}>
                <b>{numberOfItem}</b> line item{numberOfItem > 1 && 's'}
              </Text>
            )}
          </td>
          <td className={`${displayBorder} ellipsis `}>
            <div className="pr-small">
              <Text style={{ whiteSpace: 'nowrap' }} weight="bold" size="regular">
                {PermissionUtils.validatePermission('ViewCustomerProfile', portalUser.permissions.permissionRoles) ? (
                  <HyperlinkButton onClick={() => this._onClickCustomer(customerUserId)} color="black">
                    {firstName} {lastName}
                  </HyperlinkButton>
                ) : (
                  firstName + ' ' + lastName
                )}
                {/*{numberOfErrors > 0 && (*/}
                {/*  <Text className="text-color-red ml-medium">*/}
                {/*    <Icon type="warning" className="text-color-red mr-x-small text-size-large" />*/}
                {/*    {numberOfErrors} error{numberOfErrors > 1 && 's'}*/}
                {/*  </Text>*/}
                {/*)}*/}
              </Text>
            </div>
          </td>
          <td className={`${displayBorder} ellipsis `}>
            <HyperlinkButton onClick={() => this._onClickSupplier(planManagementSupplierId)} color="black">
              {supplierName}
            </HyperlinkButton>
          </td>
          <td className={`${displayBorder} fluid-cell `}>
            <div className="pr-small text-align-right">
              <Text style={{ whiteSpace: 'nowrap' }}>{CommonUtils.formatPrice(total ? total : invoiceTotal)}</Text>
            </div>
          </td>
          {actions && actions.length > 0 && (
            <td className={`${displayBorder} fluid-cell `} onClick={(e) => e.stopPropagation()}>
              <Popover content={popoverContent} position={'bottom-right'}>
                <Icon type="ellipsis" className="text-size-x4-large" />
              </Popover>
            </td>
          )}
        </tr>
        <tr>
          {this.state.showSubLines && (
            <>
              <td colSpan={lineItemColSpan} className="bordered-none p-medium bg-quaternary cursor-default">
                <div className={'bg-white bordered border-standard-gray rounded'}>
                  <table style={{ width: '100%' }}>
                    <tr className={'border-standard-gray'}>
                      {(type === 'CLAIMED' || type === 'PAID' || type === 'PAYMENT_ISSUES') && (
                        <>
                          <td className={'ellipsis'}>
                            <SubTitle>Claim ref No</SubTitle>
                          </td>
                          <td className={'ellipsis'}>
                            <SubTitle>CLAIMED ON</SubTitle>
                          </td>
                        </>
                      )}
                      <td className={'ellipsis'}>
                        <SubTitle>Line item</SubTitle>
                      </td>
                      {type === 'WAIVED' ? (
                        <td className={'ellipsis'}>
                          <SubTitle>type</SubTitle>
                        </td>
                      ) : (
                        <td className={'ellipsis'}>
                          <SubTitle>Claim type</SubTitle>
                        </td>
                      )}
                      <td className={'ellipsis'}>
                        <SubTitle>Cost</SubTitle>
                      </td>
                      <td className={'ellipsis text-align-right'}>
                        <SubTitle>Total</SubTitle>
                      </td>
                      {subActions && subActions.length > 0 && <td className={'fluid-cell'} />}
                    </tr>
                    {this.state.isLoading ? (
                      <tr>
                        <td colSpan={6}>
                          <SpinningLoader size={50} message={''} />
                        </td>
                      </tr>
                    ) : (
                      _.map(invoiceLineItems, (lineItem, key) => (
                        <LineItemRow
                          lineItem={lineItem}
                          history={this.props.history}
                          key={key}
                          canProcess={canProcess}
                          subActions={subActions}
                          openActionModal={openActionModal}
                          type={this.props.type}
                        />
                      ))
                    )}
                  </table>
                </div>
              </td>
            </>
          )}
        </tr>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser
});

export default connect(
  mapState,
  null
)(PlanPaymentInvoiceRow);
