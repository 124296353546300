import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { IconButton, PrimaryButton } from 'common-components/buttons';
import _ from 'lodash';
import Search from 'antd/es/input/Search';
import FoundWorkerRow from 'views/bookings/components/FoundWorkerRow';
import { Icon } from 'antd';
import { ShiftSlotStatus } from 'utilities/enum-utils';
import { Popover } from '@blueprintjs/core';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';

interface ISelectWorkerSearchModalProps {
  isOpen: boolean;
  selectedWorker: any;
  bookingId: any;
  startDateTime: any;
  endDateTime: any;
  address: any;
  customerUserIds: Array<string>;
  serviceId: string;
  onCloseSearchModal: () => void;
  selectWorker: (selectedWorker) => void;
  onAssignWorker: (shiftSlotStatus) => void;
  doFetchServiceRoster: typeof dispatch.bookingsStore.doFetchServiceRoster;
}

interface ISelectWorkerSearchModalState {
  isSearching: boolean;
  searchedList: Array<null>;
  searchInputValue: string;
  searchString: string;
  workerNotFound: boolean;
}

class SelectWorkerSearchModal extends Component<ISelectWorkerSearchModalProps, ISelectWorkerSearchModalState> {
  state = {
    isSearching: false,
    searchedList: [],
    searchInputValue: '',
    searchString: '',
    workerNotFound: false
  };

  private _searchText = async (txt) => {
    const { startDateTime, endDateTime, address, serviceId, customerUserIds } = this.props;
    const searchedList: any = !_.isEmpty(txt)
      ? await this.props.doFetchServiceRoster({
          startDateTime,
          endDateTime,
          address,
          serviceId,
          customerUserIds,
          searchString: txt
        })
      : [];
    this.setState({
      isSearching: false,
      searchString: txt,
      searchedList,
      workerNotFound: searchedList && searchedList.length === 0 && !_.isEmpty(txt)
    });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true, searchInputValue: e.target.value });

    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _onCloseSearchModal = () => {
    const { onCloseSearchModal, selectWorker } = this.props;
    this.setState({ searchInputValue: '', searchString: '', workerNotFound: false, searchedList: [] });
    selectWorker(null);
    onCloseSearchModal();
  };

  render() {
    const { isOpen, selectedWorker, onAssignWorker } = this.props;
    const { isSearching, workerNotFound, searchInputValue, searchedList } = this.state;

    return (
      <>
        <div
          className={`width-full height-full absolute bg-white ${isOpen ? 'block' : 'none'}`}
          style={{ opacity: 0.8, top: 0, left: 0, zIndex: 9000 }}
        />
        <div
          className={`block bordered absolute bg-white p-medium ${isOpen ? 'block' : 'none'}`}
          style={{
            width: '51%',
            minHeight: '400px',
            height: 'calc(100vh - 600px)',
            zIndex: 9000,
            right: '48px',
            borderRadius: '10px',
            boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.2)'
          }}
        >
          <div className="flex-row justify-between">
            <Search
              onChange={this._onEnterSearchText}
              loading={isSearching}
              placeholder="Enter Team member name"
              style={{ width: '80%' }}
              allowClear
              value={searchInputValue}
              size="large"
            />
            <Icon type={'close'} className="font-size-large" onClick={this._onCloseSearchModal} />
          </div>
          <div
            className="width-full mv-medium"
            style={{ overflow: 'auto', minHeight: '250px', height: 'calc(100vh - 750px)' }}
          >
            {this.state.isSearching && <SpinningLoader size={100} message={'Loading'} />}
            {_.isEmpty(searchedList) &&
              !this.state.isSearching &&
              (!workerNotFound ? (
                <div className="text-align-center" style={{ margin: 'auto', marginTop: '10%' }}>
                  <Text weight="bold">Enter a Team member name to begin searching</Text>
                  <br />
                  <Text>We’ll search the service roster for matches.</Text>
                </div>
              ) : (
                <div className="text-align-center" style={{ margin: 'auto', marginTop: '10%' }}>
                  <Text weight="bold">No Team member found</Text>
                  <br />
                  <Text>Try searching for another person.</Text>
                </div>
              ))}
            {searchedList && searchedList.length > 0 && !this.state.isSearching && (
              <table className="width-full">
                <tbody>
                  {_.map(searchedList, (worker) => {
                    return (
                      <FoundWorkerRow
                        worker={worker}
                        isSelected={selectedWorker && worker.supportWorkerId === selectedWorker.supportWorkerId}
                        isSearch={true}
                        selectWorker={this.props.selectWorker}
                      />
                    );
                  })}
                </tbody>
              </table>
            )}
          </div>
          <div className="flex-row justify-end">
            <PrimaryButton
              disabled={!selectedWorker}
              onClick={() => onAssignWorker(ShiftSlotStatus.CONFIRMED)}
              size="large"
              className="ml-large"
            >
              Assign as Confirmed
            </PrimaryButton>

            <Popover
              content={
                <ActionMenu>
                  <ActionMenuItem text="Assign as pending" onClick={() => onAssignWorker(ShiftSlotStatus.PENDING)} />
                </ActionMenu>
              }
              position="bottom-left"
              usePortal={false}
              disabled={!selectedWorker}
            >
              <IconButton className="rounded-right ml-x2-small" icon="down" size="large" disabled={!selectedWorker} />
            </Popover>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServiceRoster: dispatch.bookingsStore.doFetchServiceRoster
});

export default connect(
  null,
  mapDispatch
)(SelectWorkerSearchModal);
