import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { SubTitle, Text } from 'common-components/typography';
import { Icon, Input, notification, Select } from 'antd';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import _ from 'lodash';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import CommonUtils from 'utilities/common-utils';
import globalConfig from 'variables/global-config';
import Script from 'react-load-script';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { connect } from 'react-redux';

const searchAutocompleteOptions = {
  componentRestrictions: { country: ['au'] }
};
const pattern = new RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);
interface IAddDebtorModalProps {
  isOpen: any;
  onClose: any;
  history: any;
  doAddDebtor: typeof dispatch.accountStore.doAddDebtor;
}

interface IAddDebtorModalState {
  debtorName: string;
  organisationName: string;
  debtorNumber: string;
  email: string;
  contactNumber: string;
  address: string;
  selectedLattitude: number;
  selectedLongitude: number;
  selectedAddress: any;
  debtorNameError: boolean;
  contactNumberError: boolean;
  emptyEmailError: boolean;
  invalidEmailError: boolean;
  debtorNumberError: boolean;
}

class AddDebtorModal extends Component<IAddDebtorModalProps, IAddDebtorModalState> {
  state = {
    debtorName: '',
    organisationName: null,
    debtorNumber: null,
    email: '',
    contactNumber: null,
    address: '',
    selectedLattitude: null,
    selectedLongitude: null,
    selectedAddress: null,
    debtorNameError: false,
    contactNumberError: false,
    emptyEmailError: false,
    invalidEmailError: false,
    debtorNumberError: false
  };

  private _onCloseModal = () => {
    this.setState({
      debtorName: '',
      debtorNumber: null,
      email: '',
      contactNumber: null,
      address: '',
      selectedLattitude: null,
      selectedLongitude: null,
      selectedAddress: null,
      debtorNameError: false,
      contactNumberError: false,
      emptyEmailError: false,
      invalidEmailError: false,
      debtorNumberError: false
    });
    this.props.onClose();
  };

  private _handleChange = (address) => {
    this.setState({ address });
  };

  private _handleSelect = (address) => {
    this.setState({ address: address });
    geocodeByAddress(address)
      .then((results) => {
        this.setState({
          selectedAddress: CommonUtils.createAddressFromGoogleMap(results[0].address_components)
        });
      })
      .catch((error) => console.error('Error', error));
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => this.setState({ selectedLattitude: lat, selectedLongitude: lng }))
      .catch((error) => console.error('Error', error));
  };

  private _validateMobileNumber = (value) => {
    const region = 'AU';

    if (!_.isEmpty(value)) {
      let phoneNumber = parsePhoneNumberFromString(value, region);
      if (phoneNumber === undefined || !phoneNumber || !phoneNumber.isValid()) {
        return false;
      }
    }
    return true;
  };

  private addDebtor = async () => {
    const {
      address,
      selectedLattitude,
      selectedLongitude,
      selectedAddress,
      contactNumber,
      debtorName,
      email,
      debtorNumber,
      organisationName
    } = this.state;
    const { doAddDebtor, history } = this.props;
    let hasError = false;
    this.setState({
      debtorNameError: false,
      debtorNumberError: false,
      emptyEmailError: false,
      invalidEmailError: false,
      contactNumberError: false
    });

    if (!debtorName) {
      this.setState({ debtorNameError: true });
      hasError = true;
    }
    if (!email) {
      this.setState({ emptyEmailError: true });
      hasError = true;
    }
    if (email) {
      //using regex to validate email
      if (!pattern.test(email)) {
        this.setState({ invalidEmailError: true });
        hasError = true;
      }
    }
    if (!this._validateMobileNumber(contactNumber)) {
      this.setState({ contactNumberError: true });
      hasError = true;
    }
    let debtorAdress = {};
    if (selectedAddress) {
      debtorAdress = { ...selectedAddress, geoLat: selectedLattitude, geoLng: selectedLongitude, fullAddress: address };
    }

    if (!hasError) {
      const payload = {
        newDebtor: {
          debtorName: debtorName,
          organisationName: organisationName,
          debtorNumber: debtorNumber,
          debtorContactNumber: contactNumber,
          debtorEmail: email,
          debtorCountryCode: contactNumber ? 'AU' : null,
          debtorAddress: debtorAdress
        },
        isNewDebtor: true
      };

      try {
        const newDebtorId = await doAddDebtor(payload);

        history.push(`debtor-management/${newDebtorId}`);
      } catch (e) {
        if (e.meta.message === 'The provided debtor email already exists') {
          this.setState({ emptyEmailError: true });
        } else if (e.meta.message === 'The provided debtor number already exists') {
          this.setState({ debtorNumberError: true });
        } else if (
          e.meta.message === 'The provided debtor email already exists, The provided debtor number already exists'
        ) {
          this.setState({ debtorNumberError: true, emptyEmailError: true });
        } else {
          notification.error({ message: 'Oops, an error has occurred, please try again.' });
        }
      }
    }
  };

  render() {
    const {
      debtorName,
      organisationName,
      debtorNumber,
      email,
      contactNumber,
      debtorNameError,
      contactNumberError,
      invalidEmailError,
      emptyEmailError,
      debtorNumberError
    } = this.state;

    return (
      <>
        <Script
          url={'https://maps.googleapis.com/maps/api/js?key=' + globalConfig.googleMapApiKey + '&libraries=places'}
        />
        <ActionModal
          title={<Text className={'text-size-x2-large'}>Add debtor</Text>}
          isOpen={this.props.isOpen}
          onClose={this._onCloseModal}
          width="medium"
        >
          <div className="anim-slide-left">
            <div>
              <SubTitle>Debtor Name</SubTitle>
              <div className="mt-x2-small">
                <Input
                  style={{ width: '50%' }}
                  placeholder={'Enter name'}
                  size={'large'}
                  value={debtorName}
                  onChange={(e) => this.setState({ debtorName: e.target.value })}
                />
              </div>
              {debtorNameError && (
                <Text size="regular" color="red" className="mt-small">
                  Please enter a debtor name
                </Text>
              )}
            </div>
            <div className="mt-medium">
              <div className="flex">
                <SubTitle>Organisation Name</SubTitle>
                <Text color="secondary" size="small" className="ml-small">
                  (Optional)
                </Text>
              </div>
              <div className="mt-x2-small">
                <Input
                  style={{ width: '50%' }}
                  placeholder={'Enter organisation name'}
                  size={'large'}
                  value={organisationName}
                  onChange={(e) => this.setState({ organisationName: e.target.value })}
                />
              </div>
            </div>
            <div className="mt-medium">
              <div className="flex">
                <SubTitle>Debtor ID</SubTitle>
                <Text color="secondary" size="small" className="ml-small">
                  (Optional)
                </Text>
              </div>
              <div className="mt-x2-small">
                <Input
                  style={{ width: '50%' }}
                  placeholder={'Enter ID'}
                  size={'large'}
                  value={debtorNumber}
                  onChange={(e) => this.setState({ debtorNumber: e.target.value })}
                />
              </div>
              {debtorNumberError && (
                <Text size="regular" color="red" className="mt-small">
                  Enter a unique ID.
                </Text>
              )}
            </div>
            <div className="mt-medium">
              <SubTitle>Email</SubTitle>
              <div className="mt-x2-small">
                <Input
                  style={{ width: '50%' }}
                  placeholder={'Enter email'}
                  size={'large'}
                  value={email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
              </div>
              {emptyEmailError && (
                <Text size="regular" color="red" className="mt-small">
                  Enter an email that is not associated with an existing debtor.
                </Text>
              )}
              {invalidEmailError && (
                <Text size="regular" color="red" className="mt-small">
                  Please enter a valid email address
                </Text>
              )}
            </div>

            <div className="mt-medium">
              <div className="flex">
                <SubTitle>Contact Number</SubTitle>
                <Text color="secondary" size="small" className="ml-small">
                  (Optional)
                </Text>
              </div>
              <div className={'flex-row mt-x2-small'}>
                <Select disabled size={'large'} className="mr-small" style={{ width: '110px' }} defaultValue={'AU'}>
                  <Select.Option value={'AU'}>+61 (AU)</Select.Option>
                </Select>

                <Input
                  size={'large'}
                  placeholder={'Enter number'}
                  style={{ width: '200px' }}
                  value={contactNumber}
                  onChange={(e) => this.setState({ contactNumber: e.target.value })}
                />
              </div>
              {contactNumberError && (
                <Text size="regular" color="red" className="mt-small">
                  Please enter a valid phone number
                </Text>
              )}
            </div>
            <div className="mt-medium mb-large">
              <div className="flex">
                <SubTitle>Address</SubTitle>
                <Text color="secondary" size="small" className="ml-small">
                  (Optional)
                </Text>
              </div>
              <div className={'mt-x2-small'}>
                <PlacesAutocomplete
                  value={this.state.address}
                  onSelect={this._handleSelect}
                  onChange={this._handleChange}
                  shouldFetchSuggestions={this.state.address.length > 2}
                  searchOptions={searchAutocompleteOptions}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <Input
                        value={this.state.address}
                        size={'large'}
                        {...getInputProps({
                          placeholder: 'Search for an address ...',
                          className: 'location-search-input'
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                          const style = suggestion.active
                            ? {
                                backgroundColor: '#fafafa',
                                cursor: 'pointer',
                                borderTop: '1px ridge grey',
                                borderLeft: '1px ridge grey',
                                borderRight: '1px ridge grey',
                                padding: '10px'
                              }
                            : {
                                backgroundColor: '#ffffff',
                                cursor: 'pointer',
                                borderTop: '1px ridge grey',
                                borderLeft: '1px ridge grey',
                                borderRight: '1px ridge grey',
                                padding: '10px'
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style
                              })}
                            >
                              <span>
                                <Icon type={'environment'} /> {suggestion.description}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
            </div>

            <ActionModalFooter>
              <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={() => this.addDebtor()}>
                Create debtor
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        </ActionModal>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddDebtor: dispatch.accountStore.doAddDebtor
});

export default connect(
  mapState,
  mapDispatch
)(AddDebtorModal);
