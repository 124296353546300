import React from 'react';
import { IGroupServiceCustomer } from 'interfaces/service-interfaces';
import { Avatar } from 'antd';
import { Text } from 'common-components/typography';

export function TagUserAvatar(props: { customer: IGroupServiceCustomer }) {
  const { customer } = props;
  const customerName = `${customer.taggedUserFirstName || ''} ${customer.taggedUserLastName || ''}`;
  return (
    <div className="flex-row align-center mr-medium mb-medium">
      <Avatar icon="user" size="small" src={customer.taggedUserAvatarUrl} />
      <Text size="regular" color="secondary" className="ml-x-small">
        {customerName}
      </Text>
    </div>
  );
}
