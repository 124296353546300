import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Col, Icon, notification, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { SubTitle, Text } from 'common-components/typography';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import {
  NdisSupportItemDateType,
  PlanManagementApprovalStatus,
  PlanManagementClaimStatus,
  PlanManagementClaimStatusDisplay,
  PlanManagementPaymentStatus
} from 'utilities/enum-utils';
import { ndisHelper } from 'variables/data-helpers';
import AddNDISLineItemModal from 'views/bookings/details/sections/content-section/tabs-panel/AddNDISLineItemModal';
import DeleteLineItemModal from 'views/plan-management/components/DeleteLineItemModal';
import ErrorPriceExceedingInvoiceModal from 'views/plan-management/components/ErrorPriceExceedingInvoiceModal';
import PlanManagementBillingLineItem from 'views/plan-management/components/PlanManagementBillingLineItem';
import RevokeClaimModal from 'views/plan-management/components/RevokeClaimModal';

interface PlanManagementInvoiceClaimPanelProps {
  invoice: any;
  doAddInvoiceLineItem: typeof dispatch.planManagementStore.doAddInvoiceLineItem;
  doEditInvoiceLineItem: typeof dispatch.planManagementStore.doEditInvoiceLineItem;
  doDeleteInvoiceLineItem: typeof dispatch.planManagementStore.doDeleteInvoiceLineItem;
  doSendInvoiceToPayment: typeof dispatch.planManagementStore.doSendInvoiceToPayment;
  doRevokeInvoice: typeof dispatch.planManagementStore.doRevokeInvoice;
}

interface PlanManagementInvoiceClaimPanelState {
  isLoading: boolean;
  isEdit: boolean;
  localLineItems: Array<any>;
  isOpenAddLineItem: boolean;
  isErrorPriceExceedingInvoice: boolean;
  isDeleteLineItemModalOpen: boolean;
  isRevokeLineItemModalOpen: boolean;
  selectedLineItemIndex: number;
  selectedLineItemForDelete: string;
}

const LineItemEmptyState = () => (
  <div className="mv-large flex-1 align-center flex-column">
    <Text size="x2-large" color="secondary" weight="bold">
      No line items added.
    </Text>{' '}
    <Text color="secondary">
      Click on <b>Add Line item</b> above to add one.
    </Text>
  </div>
);

class PlanManagementInvoiceClaimPanel extends Component<
  PlanManagementInvoiceClaimPanelProps,
  PlanManagementInvoiceClaimPanelState
> {
  state = {
    isLoading: false,
    isEdit: false,
    localLineItems: this.props.invoice.lineItems,
    isOpenAddLineItem: false,
    isErrorPriceExceedingInvoice: false,
    isDeleteLineItemModalOpen: false,
    isRevokeLineItemModalOpen: false,
    selectedLineItemIndex: null,
    selectedLineItemForDelete: null
  };

  private _addLineItem = () => {
    const newLocalLineItems = _.clone(this.props.invoice.lineItems);
    newLocalLineItems.unshift({});
    this.setState({ localLineItems: newLocalLineItems, selectedLineItemIndex: 0 });
  };

  private _closeLineItemModal = () => {
    this.setState({ isOpenAddLineItem: false });
  };

  private _openLineItemModal = () => {
    this.setState({ isOpenAddLineItem: true });
  };

  private _closeDeleteLineItemModal = () => {
    this.setState({ isDeleteLineItemModalOpen: false, selectedLineItemForDelete: null });
  };

  private _openDeleteLineItemModal = (planManagementLineItemId) => {
    this.setState({ isDeleteLineItemModalOpen: true, selectedLineItemForDelete: planManagementLineItemId });
  };

  private _closeRevokeLineItemModal = () => {
    this.setState({ isRevokeLineItemModalOpen: false });
  };

  private _openRevokeLineItemModal = () => {
    this.setState({ isRevokeLineItemModalOpen: true });
  };

  private _onChangeSupportItemNumber = (value) => {
    const { localLineItems, selectedLineItemIndex } = this.state;
    if (value) {
      const newSupportItem = ndisHelper.getBySupportItemNumber(value);
      const isABTOrPTNLC =
        newSupportItem &&
        (newSupportItem.DateType === NdisSupportItemDateType.ABT ||
          newSupportItem.DateType === NdisSupportItemDateType.PTNLC);
      const newLocalLineItem = _.map(localLineItems, (lineItem, key: number) => {
        if (selectedLineItemIndex === key) {
          return {
            ...lineItem,
            supportItemNumber: value,
            unit: newSupportItem ? newSupportItem.UnitOfMeasure : null,
            claimType: isABTOrPTNLC ? 'TRAN' : lineItem.claimType ? lineItem.claimType : null
          };
        } else {
          return { ...lineItem };
        }
      });
      this.setState({ localLineItems: newLocalLineItem });
      this._closeLineItemModal();
    }
  };

  private _saveInvoiceLineItem = async (payload) => {
    const { doAddInvoiceLineItem, doEditInvoiceLineItem } = this.props;
    try {
      this.setState({ isLoading: true });
      payload.planManagementLineItemId
        ? await doEditInvoiceLineItem({ ...payload, invoiceId: this.props.invoice.planManagementInvoiceId })
        : await doAddInvoiceLineItem({ ...payload, invoiceId: this.props.invoice.planManagementInvoiceId });
      this.setState({ localLineItems: this.props.invoice.lineItems, selectedLineItemIndex: null, isLoading: false });
    } catch (e) {
      if (e.meta.message === 'Line item sum total cannot exceed invoice total') {
        this.setState({ isErrorPriceExceedingInvoice: true, isLoading: false });
      } else {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
        this.setState({ isLoading: false });
      }
    }
  };

  private _deleteInvoiceLineItem = async (planManagementLineItemId) => {
    const { doDeleteInvoiceLineItem } = this.props;
    try {
      this.setState({ isLoading: true });
      await doDeleteInvoiceLineItem({
        planManagementLineItemId,
        invoiceId: this.props.invoice.planManagementInvoiceId
      });
      this.setState({ localLineItems: this.props.invoice.lineItems, selectedLineItemIndex: null, isLoading: false });
      this._closeDeleteLineItemModal();
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isLoading: false });
    }
  };

  private _sendToPayment = async () => {
    const { doSendInvoiceToPayment } = this.props;
    try {
      this.setState({ isLoading: true });
      await doSendInvoiceToPayment({ invoiceId: this.props.invoice.planManagementInvoiceId });
      this.setState({ isLoading: false });
      notification.success({ message: 'Invoice sent to payment successfully' });
    } catch (e) {
      this.setState({ isLoading: false });
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
  };

  private _revokeInvoice = async () => {
    const { doRevokeInvoice } = this.props;
    try {
      this.setState({ isLoading: true });
      await doRevokeInvoice({ invoiceId: this.props.invoice.planManagementInvoiceId });
      this.setState({ isLoading: false, isRevokeLineItemModalOpen: false });
      notification.success({ message: 'Invoice revoked successfully' });
    } catch (e) {
      this.setState({ isLoading: false });
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
  };

  private _cancelEdit = () => {
    this.setState({ localLineItems: this.props.invoice.lineItems, selectedLineItemIndex: null });
  };

  private _editLineItem = (lineItemIndex) => {
    this.setState({ selectedLineItemIndex: lineItemIndex });
  };

  componentDidUpdate(prevProps: Readonly<PlanManagementInvoiceClaimPanelProps>) {
    if (prevProps.invoice.lineItems !== this.props.invoice.lineItems) {
      this.setState({ localLineItems: this.props.invoice.lineItems });
    }
  }

  render() {
    const {
      claimStatus,
      invoiceTotal,
      supplierPaymentStatus,
      includesGst,
      approvalStatus,
      invoiceIssuedDate
    } = this.props.invoice;
    const {
      localLineItems,
      selectedLineItemIndex,
      isDeleteLineItemModalOpen,
      selectedLineItemForDelete,
      isRevokeLineItemModalOpen
    } = this.state;

    const totalAmount = localLineItems
      ? _.sumBy(localLineItems, (lineItem: any) => {
          return lineItem.total && lineItem.claimStatus !== PlanManagementClaimStatus.WAIVED
            ? Number(lineItem.total)
            : 0;
        })
      : 0;

    const isLineItemBeingEdited = selectedLineItemIndex !== null;
    const invoiceIsNotInApproveState = approvalStatus !== PlanManagementApprovalStatus.APPROVED;
    const isInvoiceTotalReached = Number(totalAmount) >= Number(invoiceTotal);
    const isInPaidStatus = supplierPaymentStatus !== PlanManagementPaymentStatus.UNPAID;
    const isAtLeastOneValidLineItemToSendToPayment = !!_.find(
      localLineItems,
      (lineItem) =>
        lineItem.claimStatus !== PlanManagementClaimStatus.SENT_TO_PAYMENTS &&
        lineItem.claimStatus !== PlanManagementClaimStatus.WAIVED &&
        lineItem.claimStatus !== PlanManagementClaimStatus.CANCELLED
    );
    const isAtLeastOneSentToPaymentLineItem = !!_.find(
      localLineItems,
      (lineItem) => lineItem.claimStatus === PlanManagementClaimStatus.SENT_TO_PAYMENTS
    );

    return (
      <div className="">
        <AddNDISLineItemModal
          closeLineItemModal={this._closeLineItemModal}
          isOpen={this.state.isOpenAddLineItem}
          hasABTLineItems={false}
          hasPTNLCLineItems={false}
          onChangeSupportItemNumber={this._onChangeSupportItemNumber}
          hideWarningFunding={true}
          filterFunded={false}
          isFilterOutTravelItem={false}
        />
        <ErrorPriceExceedingInvoiceModal
          isOpen={this.state.isErrorPriceExceedingInvoice}
          closeModal={() => this.setState({ isErrorPriceExceedingInvoice: false })}
          includesGst={includesGst}
        />
        <DeleteLineItemModal
          isOpen={isDeleteLineItemModalOpen}
          closeModal={this._closeDeleteLineItemModal}
          deleteLineItem={this._deleteInvoiceLineItem}
          selectedLineItem={selectedLineItemForDelete}
        />
        <RevokeClaimModal
          isOpen={isRevokeLineItemModalOpen}
          closeModal={this._closeRevokeLineItemModal}
          revokeClaim={this._revokeInvoice}
        />
        <div className="m-large mt-small">
          <Text size={'x-large'} weight="bold">
            Claims for this invoice
          </Text>
        </div>

        <div className="bordered border-standard-gray">
          <Row type={'flex'} align={'middle'} className={'p-medium bordered-bottom border-standard-gray'}>
            <Col span={4}>
              <SubTitle>Item count</SubTitle>
              {localLineItems ? localLineItems.length : 0} line item
              {localLineItems && localLineItems.length === 1 ? '' : 's'}
            </Col>
            <Col span={4} className={'text-align-right'}>
              <SubTitle>Total amount</SubTitle>

              {!isInvoiceTotalReached ? (
                <Tooltip2
                  content={`Claim amount differs from invoice amount${includesGst ? ' (Excluding GST)' : ''}.`}
                  position={'top'}
                >
                  <>
                    <Text color={'orange-dark'}>{CommonUtils.formatPrice(totalAmount)}</Text>
                    <Icon type={'warning'} theme={'filled'} className={'ml-small text-color-warning-orange'} />
                  </>
                </Tooltip2>
              ) : (
                <>
                  <Text>{CommonUtils.formatPrice(totalAmount)}</Text>
                  {Number(totalAmount) === Number(invoiceTotal) && <Icon type={'check'} className={'ml-small'} />}
                </>
              )}
            </Col>
            <Col span={2} />
            <Col span={4}>
              <SubTitle>NDIS Claim status</SubTitle>
              <Text>{PlanManagementClaimStatusDisplay[claimStatus]}</Text>
            </Col>
            <Col span={10} className={'flex-row text-align-right'}>
              <SecondaryButton
                icon={'plus'}
                disabled={
                  isInvoiceTotalReached || isInPaidStatus || isLineItemBeingEdited || claimStatus === 'CANCELLED'
                }
                onClick={this._addLineItem}
              >
                Add Line Item
              </SecondaryButton>
              {isInvoiceTotalReached && (
                <Tooltip2
                  content="You can no longer add line items to this invoice as the total value of the line items added to this invoice is equal to the invoice total."
                  position={'top'}
                >
                  <Icon type="question-circle" className="text-size-x-large text-color-blue ml-small" />
                </Tooltip2>
              )}
              {isInPaidStatus && (
                <Tooltip2
                  content="You cannot add line items to an invoice after it has been marked as paid."
                  position={'top'}
                >
                  <Icon type="question-circle" className="text-size-x-large text-color-blue ml-small" />
                </Tooltip2>
              )}
              <PrimaryButton
                icon={'arrow-right'}
                iconPosition={'right'}
                className={'ml-small'}
                disabled={
                  isLineItemBeingEdited || invoiceIsNotInApproveState || !isAtLeastOneValidLineItemToSendToPayment
                }
                onClick={this._sendToPayment}
              >
                Send to payment
              </PrimaryButton>
              {invoiceIsNotInApproveState && (
                <Tooltip2
                  content="You cannot send line items to payments until the invoice has been approved."
                  position={'top'}
                >
                  <Icon type="question-circle" className="text-size-x-large text-color-blue ml-small" />
                </Tooltip2>
              )}
              {isAtLeastOneSentToPaymentLineItem && (
                <Popover2
                  content={
                    <Menu>
                      <MenuItem text={'Revoke claim'} className={'pv-small'} onClick={this._openRevokeLineItemModal} />
                    </Menu>
                  }
                  position={'bottom-left'}
                >
                  <SecondaryButton className="ml-small">
                    <Icon type="ellipsis" />
                  </SecondaryButton>
                </Popover2>
              )}
            </Col>
          </Row>
          <Row className={'p-medium pt-none bg-tertiary'}>
            {localLineItems && localLineItems.length > 0 ? (
              _.map(localLineItems, (lineItem, key: number) => {
                return (
                  <PlanManagementBillingLineItem
                    key={key}
                    lineItemIndex={key}
                    lineItem={lineItem}
                    invoiceIssuedDate={invoiceIssuedDate}
                    displayMode={selectedLineItemIndex !== null && selectedLineItemIndex === key ? 'EDIT' : 'VIEW'}
                    disabledEdit={selectedLineItemIndex !== null && selectedLineItemIndex !== key}
                    openLineItemModal={this._openLineItemModal}
                    closeLineItemModal={this._closeLineItemModal}
                    saveInvoiceLineItem={this._saveInvoiceLineItem}
                    editLineItem={this._editLineItem}
                    deleteInvoiceLineItem={this._openDeleteLineItemModal}
                    cancelEdit={this._cancelEdit}
                    isLoading={this.state.isLoading}
                  />
                );
              })
            ) : (
              <LineItemEmptyState />
            )}
          </Row>
        </div>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddInvoiceLineItem: dispatch.planManagementStore.doAddInvoiceLineItem,
  doEditInvoiceLineItem: dispatch.planManagementStore.doEditInvoiceLineItem,
  doDeleteInvoiceLineItem: dispatch.planManagementStore.doDeleteInvoiceLineItem,
  doSendInvoiceToPayment: dispatch.planManagementStore.doSendInvoiceToPayment,
  doRevokeInvoice: dispatch.planManagementStore.doRevokeInvoice
});

const mapState = (state: IRootState) => ({
  // if needed
});

export default connect(mapState, mapDispatch)(PlanManagementInvoiceClaimPanel);
