import React, { Component } from 'react';
import { Paragraph } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { Icon, Radio } from 'antd';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { EditRecurringMode } from 'utilities/enum-utils';
import { ErrorSVG } from 'assets/UndrawSVG';
import { connect } from 'react-redux';

interface IBookingCancelRemoveFeeModalProps {
  onClose: () => void;
  selectedBooking: typeof state.bookingsStore.selectedBookingItem;
  isOpen: boolean;
  doUnchargeCustomerCancelledBooking: typeof dispatch.bookingsStore.doUnchargeCustomerCancelledBooking;
  doFetchNumberOfBookingsForRecurrence: typeof dispatch.bookingsStore.doFetchNumberOfBookingsForRecurrence;
}

class BookingCancelRemoveFeeModal extends Component<IBookingCancelRemoveFeeModalProps, any> {
  state = {
    step: 2,
    title: 'Remove cancellation fee?',
    canManuallyClose: true,
    totalNumberOfImpactedBookings: 0,
    selectedOption: EditRecurringMode.Current
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;

    // Do any manual clean up; reset to initial state
    this._reset();

    onClose();
  };

  private _doRemoveCancellationFee = async () => {
    this.setState({ step: 2, canManuallyClose: false });
    try {
      await this.props.doUnchargeCustomerCancelledBooking({
        editRecurringMode: this.state.selectedOption,
        bookingRequestId: this.props.selectedBooking.bookingRequestId,
        isRecurring: this.props.selectedBooking.isRecurring
      });
      this.setState({
        title: (
          <>
            Cancellation fee removed
            <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
          </>
        ),
        step: 3,
        canManuallyClose: true
      });
    } catch (e) {
      this.setState({ step: 4, canManuallyClose: true });
    }
  };

  private _reset = () =>
    this.setState({
      step: 2,
      title: 'Remove cancellation fee?',
      canManuallyClose: true,
      totalNumberOfImpactedBookings: 0,
      selectedOption: EditRecurringMode.Current
    });

  componentDidMount = async () => {
    const { selectedBooking } = this.props;
    const relatedBookings: any = await this.props.doFetchNumberOfBookingsForRecurrence({
      bookingRequestId: selectedBooking.bookingRequestId,
      status: selectedBooking.status
    });
    this.setState({ totalNumberOfImpactedBookings: 1 + relatedBookings.length, step: 1 });
  };

  componentDidUpdate = async (prevProps: Readonly<IBookingCancelRemoveFeeModalProps>) => {
    if (prevProps.isOpen === false && this.props.isOpen) {
      const { selectedBooking } = this.props;
      const relatedBookings: any = await this.props.doFetchNumberOfBookingsForRecurrence({
        bookingRequestId: selectedBooking.bookingRequestId,
        status: selectedBooking.status
      });
      this.setState({ totalNumberOfImpactedBookings: 1 + relatedBookings.length, step: 1 });
    }
  };

  private _renderView = () => {
    const { step, totalNumberOfImpactedBookings } = this.state;
    const { selectedBooking } = this.props;

    if (step === 1) {
      return (
        <div>
          <div className="anim-slide-left">
            <div className="mb-medium">
              {' '}
              {selectedBooking.isRecurring && totalNumberOfImpactedBookings > 1 ? (
                <>
                  <Paragraph>
                    This booking is part of a recurring series. There are{' '}
                    <b>
                      {totalNumberOfImpactedBookings} booking{totalNumberOfImpactedBookings !== 1 && 's'}
                    </b>{' '}
                    in this series that are being charged a cancellation fee that have not yet been processed for
                    payment.
                  </Paragraph>
                  <div>
                    <Radio.Group
                      value={this.state.selectedOption}
                      onChange={this._onChangeOption}
                      className="ml-medium"
                    >
                      <Radio
                        value={EditRecurringMode.Current}
                        className={`${this.state.selectedOption === EditRecurringMode.Current &&
                          'text-weight-bold'} mb-small `}
                      >
                        <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                          Remove cancellation fee for this booking only.
                        </div>
                      </Radio>
                      <br />
                      <Radio
                        value={EditRecurringMode.CurrentAll}
                        className={`${this.state.selectedOption === EditRecurringMode.CurrentAll &&
                          'text-weight-bold'} mb-small `}
                      >
                        <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                          Remove cancellation fee for all {totalNumberOfImpactedBookings} bookings.
                        </div>
                      </Radio>
                    </Radio.Group>
                  </div>
                </>
              ) : (
                <Paragraph>Are you sure you want to remove the cancellation fee for this booking?</Paragraph>
              )}
            </div>
          </div>

          <ActionModalFooter>
            <GhostButton size="large" className="mr-medium" onClick={this._onCloseModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" onClick={this._doRemoveCancellationFee}>
              Remove fee
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div>
          <div>
            <SpinningLoader size={150} message={'Loading'} />
          </div>
        </div>
      );
    }
    if (step === 3) {
      return (
        <div>
          <div>
            <div className="mb-medium">
              <Paragraph>
                You have successfully removed the cancellation fee for{' '}
                <b>
                  {this.state.selectedOption === EditRecurringMode.CurrentAll
                    ? totalNumberOfImpactedBookings + ' bookings'
                    : 'this booking'}
                </b>
                .
              </Paragraph>
              <Paragraph>
                The booking requires <b>no further action</b>.
              </Paragraph>
            </div>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (step === 4) {
      return (
        <div>
          <div style={{ minHeight: 317 }} className="flex-column justify-center text-align-center">
            <div className="pv-medium flex-column justify-center">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium flex-column justify-center ">
              <Paragraph>Oops something has gone wrong, please try again</Paragraph>
            </div>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Back to booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  render() {
    const { isOpen } = this.props;

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="large"
        verticalAlignment="highest"
      >
        {this._renderView()}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBooking: state.bookingsStore.selectedBookingItem
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doUnchargeCustomerCancelledBooking: dispatch.bookingsStore.doUnchargeCustomerCancelledBooking,
  doFetchNumberOfBookingsForRecurrence: dispatch.bookingsStore.doFetchNumberOfBookingsForRecurrence
});

export default connect(
  mapState,
  mapDispatch
)(BookingCancelRemoveFeeModal);
