import React, { Component } from 'react';
import { Avatar, Col, Row } from 'antd';
import { FieldLabel, Paragraph, Text } from 'common-components/typography';
import Title from 'antd/lib/typography/Title';
import { IconButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { Popover } from '@blueprintjs/core';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import _ from 'lodash';
import { IGroupServiceOverview, IGroupServiceSupportWorkersWithConflicts } from 'interfaces/service-interfaces';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { connect } from 'react-redux';

interface IAssignmentSummaryStepPanelProps {
  selectedGroupService: IGroupServiceOverview;
  selectedWorkers: IGroupServiceSupportWorkersWithConflicts[];
  doAddTeamMemberToSession: typeof dispatch.groupServiceStore.doAddTeamMemberToSession;
  onPreviousStep: () => void;
  onClose: () => void;
}

interface IAssignmentSummaryStepPanelState {
  step: number;
  isLoading: boolean;
}

class AssignmentSummaryStepPanel extends Component<IAssignmentSummaryStepPanelProps, IAssignmentSummaryStepPanelState> {
  state = {
    step: 1,
    isLoading: false
  };

  private _onAssignMember = async (isConfirmed: boolean) => {
    const { selectedWorkers, selectedGroupService, doAddTeamMemberToSession } = this.props;
    const supportWorkers = [];
    for (const worker of selectedWorkers) {
      const workerSession = this._parseWorkerSessions(worker);
      supportWorkers.push(workerSession);
    }
    const { serviceId } = selectedGroupService;
    const payload = { serviceId, isConfirmed, supportWorkers };
    this.setState({ isLoading: true });
    await doAddTeamMemberToSession(payload);
    this.setState({ isLoading: false, step: 2 });
  };

  private _parseWorkerSessions = (worker: IGroupServiceSupportWorkersWithConflicts) => {
    const serviceDateTimes = [];
    for (const session of worker.assignedSessions) {
      const { serviceDateTimeId, startDateTime, endDateTime } = session;
      serviceDateTimes.push({ serviceDateTimeId, startDateTime, endDateTime });
    }

    return { supportWorkerId: worker.supportWorkerId, serviceDateTimes };
  };

  render() {
    const { selectedWorkers } = this.props;
    const { isLoading, step } = this.state;

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={3}>{step === 1 ? 'Review' : 'All done!'}</Title>
              <Paragraph>
                {step === 1
                  ? 'Before you add team members to the sessions, review the number of sessions each team member is beingadded to and ensure it’s correct.'
                  : 'Nice work, you’ve added your team members to sessions for this group service'}
              </Paragraph>
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white rounded-big ph-large pt-large pb-x4-large" style={{ minWidth: '250px' }}>
              {isLoading ? (
                <>
                  <SpinningLoader size={50} message={''} />
                  <Paragraph size="x2-large" className={'text-align-center'}>
                    Adding team members to sessions
                  </Paragraph>
                  <Paragraph className={'text-align-center'}>This won't take long...</Paragraph>
                </>
              ) : (
                <>
                  <Title level={4}>{step === 1 ? 'Session summary review' : 'Sessions successfully assigned'}</Title>
                  <Paragraph className="pv-large">
                    {step === 1
                      ? 'You will be adding the following team members to the following sessions'
                      : ' You’ve successfully assigned the following team members to sessions :'}
                  </Paragraph>
                  <Row style={{ height: '48px' }} type="flex" align="middle" className="bordered-bottom ph-medium">
                    <Col span={8}>
                      <FieldLabel text="TEAM MEMBER" />
                    </Col>
                    <Col span={16}>
                      <FieldLabel text="NUMBER OF SESSIONS" />
                    </Col>
                  </Row>
                  {_.map(selectedWorkers, (worker) => (
                    <WorkerItem worker={worker} />
                  ))}
                </>
              )}
            </div>

            {!isLoading && step === 1 && (
              <ActionModalFooter align="right" className="mt-small pr-large">
                <SecondaryButton size="large" className="mr-medium" onClick={this.props.onPreviousStep}>
                  Back
                </SecondaryButton>
                <PrimaryButton size="large" onClick={() => this._onAssignMember(true)}>
                  Assign to sessions as confirmed
                </PrimaryButton>
                <Popover
                  content={
                    <ActionMenu>
                      <ActionMenuItem
                        text={'Assign to sessions as pending confirmation'}
                        onClick={() => this._onAssignMember(false)}
                      />
                    </ActionMenu>
                  }
                  position="bottom-right"
                  usePortal={false}
                  minimal={true}
                >
                  <IconButton size="large" icon={'down'} className="ml-x2-small rounded-right" />
                </Popover>
              </ActionModalFooter>
            )}

            {!isLoading && step === 2 && (
              <ActionModalFooter align="right" className="mt-small pr-large">
                <PrimaryButton size="large" onClick={this.props.onClose}>
                  Close
                </PrimaryButton>
              </ActionModalFooter>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

interface IWorkerItemProps {
  worker: IGroupServiceSupportWorkersWithConflicts;
}

function WorkerItem(props: IWorkerItemProps) {
  const { worker } = props;
  const workerFullName = `${worker.firstName} ${worker.lastName}`;
  const sessionCount = worker.assignedSessions ? worker.assignedSessions.length : 0;

  return (
    <Row className="ph-medium evenodd">
      <Col span={8} className="pv-medium">
        {' '}
        <Avatar src={worker.attachmentUrl} icon="user" className="mr-small" />
        <Text>{workerFullName}</Text>
      </Col>
      <Col span={16} className="pv-medium">
        <Text>
          <b>{sessionCount}</b> session{sessionCount !== 1 && 's'}
        </Text>
      </Col>
    </Row>
  );
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddTeamMemberToSession: dispatch.groupServiceStore.doAddTeamMemberToSession
});

export default connect(
  null,
  mapDispatch
)(AssignmentSummaryStepPanel);
