import { Tooltip2 } from '@blueprintjs/popover2';
import { Empty, Input, Skeleton, Tabs } from 'antd';
import { IconButton } from 'common-components/buttons';
import { FilterSection } from 'common-components/filter';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { ItemCountSheet } from 'common-components/Sheets/ItemCountSheet';
import { SubTitle, Text, Title } from 'common-components/typography';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { FilterType, PaymentSources } from 'utilities/enum-utils';
import BatchItemRow from '../components/details/BatchItemRow';

const { Search } = Input;

interface ListPanelProps {
  batchList?: typeof state.billingsStore.batchList;
  batchesFilter?: typeof state.billingsStore.batchesFilter;
  currentFilterConfig?: any;
  history?: any;
  isServiceProviderVCPEnabled: boolean;
  doFetchBatches: typeof dispatch.billingsStore.doFetchBatches;
  setBatches?: typeof dispatch.billingsStore.setBatches;
  setBatchesFilter?: typeof dispatch.billingsStore.setBatchesFilter;
  portalUser: typeof state.authStore.portalUser;
}

interface ListPanelState {
  topHeight: number;
  showFilters: boolean;
  showActionSheet: boolean;
  checkAllIndicator: boolean;
  indeterminateCheck: boolean;
  isLoading: boolean;
  isSearching: boolean;
  searchString: string;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  action: string;
  openAction: boolean;
  filters: any;
  paymentSourceType: string;
  availableFilters: Array<any>;
}

const BatchEmptyState = () => (
  <div className="flex-1 bg-white  align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No Batches found.
    </Text>{' '}
    <br /> <br />
    <Text color="secondary">All batches under this filter will appear here.</Text>
    <Text color="secondary">Try adjusting your filter, or clicking on another view.</Text>
  </div>
);

class PayListBatchSection extends PureComponent<ListPanelProps, ListPanelState> {
  // topHeight is used to control sticky

  state = {
    topHeight: 0,
    showFilters: false,
    showActionSheet: false,
    checkAllIndicator: false,
    indeterminateCheck: false,
    isLoading: false,
    isSearching: false,
    searchString: '',
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    action: '',
    openAction: false,
    filters: [],
    paymentSourceType: PaymentSources.NDIS,
    availableFilters: [FilterType.DATE_RANGE, FilterType.BATCH_AUTHOR]
  };

  // ref element for the header; used to determine the height of the header
  _headerElement = null;

  _handleHeaderHeight = () => {
    if (this._headerElement) {
      this.setState({ topHeight: this._headerElement.offsetHeight - 1 });
    }
  };

  private _applySearchFilter = (searchStr) => {
    const { batchesFilter, setBatchesFilter } = this.props;
    let newPlanPaymentsFilter = _.clone(batchesFilter);
    const existingSearchIndex = _.findIndex(newPlanPaymentsFilter, (filter: any) => filter.filter === 'search');
    if (existingSearchIndex > -1) {
      if (searchStr === '') {
        newPlanPaymentsFilter.splice(existingSearchIndex, 1);
      } else {
        newPlanPaymentsFilter[existingSearchIndex].values = searchStr;
      }
    } else {
      newPlanPaymentsFilter.push({ filter: 'search', values: searchStr });
    }
    setBatchesFilter(newPlanPaymentsFilter);
    this.setState({ isSearching: false });
  };

  _searchText = (txt) => {
    this._applySearchFilter(txt);
    this.setState({ isSearching: false, searchString: txt });
  };

  _debounceSearch = _.debounce(this._searchText, 500);

  _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });

    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _formatFilterQuery = (appliedFilters = this.props.batchesFilter) => {
    const requestFilter: any = {};
    _.forEach(appliedFilters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'startDate':
            requestFilter.exportedDate = [filter.values[0].toDate(), filter.values[1].toDate()];
            break;
          case 'batchAuthorIds':
            requestFilter.authorIds = _.map(filter.values, (portalUser) => {
              return portalUser.value;
            });
            break;
          case 'search':
            requestFilter.search = filter.values;
            break;
        }
      }
    });
    return requestFilter;
  };

  _refreshListings = async () => {
    const { doFetchBatches } = this.props;
    this.setState({
      isLoading: true,
      page: 1,
      checkAllIndicator: false,
      indeterminateCheck: false,
      showActionSheet: false
    });
    doFetchBatches({
      ...this._formatFilterQuery(),
      paymentSourceType: this.state.paymentSourceType,
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp
    });
    this.setState({ isLoading: false, pageTimestamp: new Date() });

    await doFetchBatches({
      ...this._formatFilterQuery(),
      paymentSourceType: this.state.paymentSourceType,
      page: this.state.page,
      pageTimestamp: this.state.pageTimestamp,
      pageSize: this.state.pageSize
    });
    this.setState({ isLoading: false });
  };

  private _fetchMoreBatches = async () => {
    const { doFetchBatches, currentFilterConfig, batchesFilter } = this.props;
    let appliedFilters = _.isEmpty(batchesFilter) ? currentFilterConfig.filters : batchesFilter;
    await this.setState({ isLoading: true, page: this.state.page + 1 });
    await doFetchBatches({
      ...this._formatFilterQuery(appliedFilters),
      paymentSourceType: this.state.paymentSourceType,
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp
    });
    this.setState({ isLoading: false });
  };

  private _changeTab = (e) => {
    this.props.setBatchesFilter([]);
    this.setState({ paymentSourceType: e }, () => this._refreshListings());
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setBatchesFilter(filters);
  };

  //region Component Lifecycle Methods
  componentDidMount = async () => {
    // Automatically set the top height for the top panel. This is required for sticky.
    this._handleHeaderHeight();
    const { doFetchBatches, currentFilterConfig, batchesFilter } = this.props;
    let appliedFilters = _.isEmpty(batchesFilter) ? currentFilterConfig.filters : batchesFilter;

    await this.setState({ isLoading: true });
    await doFetchBatches({
      ...this._formatFilterQuery(appliedFilters),
      paymentSourceType: this.state.paymentSourceType,
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp
    });
    this.setState({ isLoading: false });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { currentFilterConfig, doFetchBatches, setBatchesFilter } = this.props;

    if (prevState.showFilters !== this.state.showFilters) {
      this._handleHeaderHeight();
    }

    if (prevProps.currentFilterConfig.key !== currentFilterConfig.key) {
      this.setState({
        isLoading: true,
        searchString: '',
        checkAllIndicator: false,
        indeterminateCheck: false,
        showActionSheet: false,
        showFilters: false
      });
      await setBatchesFilter(currentFilterConfig.filters);
    }

    if (!_.isEqual(prevProps.batchesFilter, this.props.batchesFilter)) {
      await this.setState({ isLoading: true, page: 1 }, () =>
        doFetchBatches({
          ...this._formatFilterQuery(),
          paymentSourceType: this.state.paymentSourceType,
          page: this.state.page,
          pageSize: this.state.pageSize,
          pageTimestamp: this.state.pageTimestamp
        })
      );
      this.setState({ isLoading: false }, () => this._handleHeaderHeight());
    }
  };
  //endregion

  render() {
    const { batchList, batchesFilter, portalUser, history } = this.props;

    const { currentFilterConfig } = this.props;

    return (
      <div
        id="scroll"
        className="bg-white flex-1 width-full flex-column"
        style={{ overflowY: 'auto', position: 'relative' }}
      >
        {/* Do NOT remove this container div. It's required for Safari sticky to work. Why it works, I have no idea.*/}
        <div>
          <div className="booking-header" ref={(com) => (this._headerElement = com)}>
            <div className="flex-row justify-between align-center">
              <div>
                <Title level={3} className="mv-none" lineHeight={150}>
                  {currentFilterConfig.title}
                </Title>
                <Text color="secondary">{currentFilterConfig.description}</Text>
              </div>
              <div className="flex-column align-center">
                <div className="pr-x-small">
                  <Tooltip2 content="Refresh this view">
                    <IconButton
                      color="white"
                      onClick={this._refreshListings}
                      iconColor="black"
                      size="large"
                      icon="reload"
                    />
                  </Tooltip2>
                </div>
              </div>
            </div>

            <Tabs defaultActiveKey={'ALL'} animated={true} onChange={this._changeTab} className={'mt-large'}>
              <Tabs.TabPane tab={PaymentSources.NDIS} key={PaymentSources.NDIS} />
              {this.props.isServiceProviderVCPEnabled && (
                <Tabs.TabPane tab={PaymentSources.VCP} key={PaymentSources.VCP} />
              )}
            </Tabs>

            <div className="flex-row pb-medium align-center justify-between">
              <div className="mr-x2-large" style={{ minWidth: '300px' }}>
                <Search
                  onChange={this._onEnterSearchText}
                  loading={this.state.isSearching}
                  placeholder="Author name or Batch number"
                />
              </div>
              <FilterSection
                availableFilters={this.state.availableFilters}
                filters={batchesFilter ? batchesFilter : []}
                onChangeFilter={this._onChangeFilter}
                displayTimezone={portalUser.timezone}
                containerClassName={'mv-small'}
              />
            </div>
          </div>

          <table className="payment-listing">
            <thead>
              <tr>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <SubTitle>Date</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <SubTitle>Author</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <SubTitle>Batch</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <SubTitle>Invoices</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap text-align-right">
                  <SubTitle containerClassName="text-align-right">Total</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap" />
              </tr>
            </thead>

            <tbody>
              {!this.state.isLoading && _.isEmpty(batchList) && (
                <tr style={{ cursor: 'default' }}>
                  <td colSpan={7} style={{ borderBottom: '0px solid' }}>
                    <BatchEmptyState />
                  </td>
                </tr>
              )}

              <InfiniteScrollLoading
                hasMore={batchList.length >= this.state.page * this.state.pageSize}
                loadingElementId={'scroll'}
                loadMore={this._fetchMoreBatches}
                loaderColSpan={7}
                loadingOffSet={60}
              >
                {_.map(batchList, (batchItem) => (
                  <BatchItemRow batchItem={batchItem} history={history} key={batchItem.batchId} />
                ))}
              </InfiniteScrollLoading>
              {this.state.isLoading && (
                <tr style={{ borderBottom: '0px solid !important' }}>
                  <td colSpan={7}>
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Filler */}
        <div className="flex-1 bg-white">&nbsp;</div>

        {!this.state.isLoading && !this.state.showActionSheet && batchList.length > 0 && (
          <ItemCountSheet itemCount={batchList.length} itemLabel="Batch" plurial="es" />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBillingLineItem: state.billingsStore.selectedBillingLineItem
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedBillingLineItem: dispatch.billingsStore.setSelectedBillingLineItem
});

export default connect(mapState, mapDispatch)(PayListBatchSection);
