import { useQuery } from 'react-query';

import { fetchSessionCapacity } from 'stores/queries/roster/roster-queries';

export function useFetchSessionCapacity({ serviceId, serviceDateTimeId }, enabled) {
  const { data, remove, refetch, isFetching, isLoading, status, error } = useQuery(
    ['session-capacity', { serviceId, serviceDateTimeId }],
    () => fetchSessionCapacity({ serviceId, serviceDateTimeId }),
    {
      enabled
    }
  );

  return { data, remove, refetch, isFetching, isLoading, status, error };
}
