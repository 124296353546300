import moment from 'moment';
import { Avatar } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';
import { IChannel } from 'interfaces/message-interfaces';

import _ from 'lodash';

import { FaHashtag } from 'react-icons/fa';

interface IChannelItemProps {
  isSelected?: boolean;
  channel?: IChannel;
  onClickChannel: any;
}

export function ChannelItem({ isSelected = false, channel, onClickChannel }: IChannelItemProps) {
  const hasUnread = channel.unreadCount > 0;

  // base class style
  let containerClassName =
    'flex-row pv-medium ph-medium bordered-bottom cursor-pointer hover-bg-message-item anim-fade-in-fast ';

  if (isSelected) {
    containerClassName += ' bg-blue-lightest';
  }

  const {
    channelName,
    messageChannelId,
    lastMessagedOn,
    lastMessage: { text },
    serviceName
  } = channel;

  const lastMessagedOnText = moment(lastMessagedOn).fromNow();

  // TODO : A bit hacky. Might need to speak to Tony to get this done properly...
  const owner = _.find(channel.subscribers, (user) => {
    const name = user.firstName.trim() + ' ' + user.lastName.trim();
    return channelName.indexOf(name) >= 0;
  });

  const channelAvatar = !_.isEmpty(owner) && owner.attachmentUrl;

  return (
    <div
      className={containerClassName}
      style={{ position: 'relative' }}
      onClick={() => onClickChannel({ messageChannelId })}
    >
      {isSelected && (
        <div style={{ position: 'absolute', left: 0, width: '4px', height: '97px', top: 0 }} className="bg-blue" />
      )}

      {/* Utility display Col */}
      <div className="pr-small pt-x-small" style={{ width: '16px', overflow: 'hidden' }}>
        {hasUnread && <div style={{ height: '8px', width: '8px', borderRadius: '20px' }} className="bg-blue" />}
      </div>

      {/* Avatar Col */}
      <div className="pr-medium">
        <Avatar icon={'user'} size={'large'} src={channelAvatar} />
      </div>

      {/* Info Col */}
      <div className="flex-1">
        {/* Name & Date row */}
        <div className="flex-row justify-between flex-1 select-none">
          <Text size="large" weight="bold">
            {/*Ricardo Young*/}
            {channelName}
          </Text>
          <Text
            size="small"
            color={hasUnread ? 'black' : 'secondary'}
            weight={hasUnread ? 'bold' : 'regular'}
            className="select-none"
          >
            {/*14 Jul*/}
            {lastMessagedOnText}
          </Text>
        </div>

        {/* Message row */}
        <div>
          <Text
            ellipsis={true}
            style={{ width: '345px', whiteSpace: 'nowrap' }}
            lineHeight={150}
            size="regular"
            weight={hasUnread ? 'bold' : 'regular'}
            className="select-none"
            color={hasUnread ? 'black' : 'secondary'}
          >
            {text}
          </Text>
        </div>

        {/* Tag row */}
        <div className="flex-row align-center">
          <FaHashtag className="text-size-x-small text-color-blue-dark mr-x2-small" />
          <Text size="x-small" color="blue-dark" lineHeight={120} className="select-none" weight="bold">
            {`${serviceName}`}
          </Text>
        </div>
      </div>
    </div>
  );
}
