import React, { Component } from 'react';
import { Col, Form, Row } from 'antd';
import { Text, Title } from 'common-components/typography';
import { ICustomer } from 'interfaces/customer-interfaces';
import { FormComponentProps } from 'antd/es/form';
import GeneralEditInfoDetailRow from './GeneralEditInfoDetailRow';
import FundGoalEditDetailPanel from './FundGoalEditDetailPanel';
import FundEditKeyContactsPanel from './FundEditKeyContactsPanel';
import FundEditSupportCategoryPanel from './FundEditSupportCategoryPanel';
import FullScreenScrollableModal, { FullScreenModalFooter } from 'common-components/modal/FullScreenScrollableModal';
import FundEditDatesPanel from 'views/customers/details/tabs-panel/funding/components/FundEditDatesPanel';
import _ from 'lodash';

interface IEditFundingPackageProps extends FormComponentProps {
  closeEditFundingPackage: () => void;
  isOpen: boolean;
  selectedCustomer: ICustomer;
  packageType: string;
  packageId: string;
}

interface IEditFundingPackageState {
  keyContactsInputs: any[];
}

class EditFundingPackage extends Component<IEditFundingPackageProps, IEditFundingPackageState> {
  state = {
    keyContactsInputs: []
  };

  private _generalInfoSection = () => {
    return (
      <GeneralEditInfoDetailRow
        closeCreatePackageModal={this.props.closeEditFundingPackage}
        selectedCustomer={this.props.selectedCustomer}
        packageId={this.props.packageId}
      />
    );
  };

  private _goalInfoSection = () => {
    return (
      <FundGoalEditDetailPanel
        closeEditGoalModal={this.props.closeEditFundingPackage}
        selectedCustomer={this.props.selectedCustomer}
        packageId={this.props.packageId}
      />
    );
  };

  private _categoryInfoSection = () => {
    return (
      <FundEditSupportCategoryPanel
        closeEditCategoryModal={this.props.closeEditFundingPackage}
        selectedCustomer={this.props.selectedCustomer}
        packageId={this.props.packageId}
      />
    );
  };

  render() {
    const { selectedCustomer, packageType } = this.props;

    let fundpackages = selectedCustomer.funding.NDISFundingPackages;
    const selectedPackage = _.find(
      fundpackages,
      (fundpackage) => fundpackage.fundingPackageId === this.props.packageId
    );

    return (
      <FullScreenScrollableModal
        isOpen={this.props.isOpen}
        onClose={this.props.closeEditFundingPackage}
        canCloseEsc={false}
        footerContent={
          <FullScreenModalFooter align={'left'}>
            <Row gutter={0} type="flex" align="middle">
              <Col />
            </Row>
          </FullScreenModalFooter>
        }
      >
        {packageType === 'agency' ? (
          <React.Fragment>
            <div className="anim-slide-left">
              {' '}
              <Title level={2} className="text-weight-regular">
                Edit <b>Funding Package</b>
              </Title>
            </div>
            <div>
              <Text>
                You are editing general information from{' '}
                <b>
                  {selectedCustomer.firstName} {selectedCustomer.lastName}
                </b>{' '}
                Funding Package.
              </Text>
            </div>
            <div className={'mt-small'}>{this._generalInfoSection()}</div>
          </React.Fragment>
        ) : packageType === 'goal' ? (
          <React.Fragment>
            <div className="anim-slide-left">
              {' '}
              <Title level={2} className="text-weight-regular">
                Edit <b>Funding Package</b>
              </Title>
            </div>
            <div>
              <Text>
                You are editing the Goals from{' '}
                <b>
                  {selectedCustomer.firstName} {selectedCustomer.lastName}
                </b>{' '}
                Funding Package. Please enter the following details.
              </Text>
            </div>
            <div className={'mt-small'}>{this._goalInfoSection()}</div>
          </React.Fragment>
        ) : packageType === 'contact' ? (
          <React.Fragment>
            <div className="anim-slide-left">
              {' '}
              <Title level={2} className="text-weight-regular">
                Edit <b>Funding Package</b>
              </Title>
            </div>
            <div>
              <Text>
                You are editing the contacts of{' '}
                <b>
                  {selectedCustomer.firstName} {selectedCustomer.lastName}
                </b>
              </Text>
            </div>
            <FundEditKeyContactsPanel
              closeEditContactModal={this.props.closeEditFundingPackage}
              selectedCustomer={this.props.selectedCustomer}
              packageId={this.props.packageId}
            />
          </React.Fragment>
        ) : packageType === 'category' ? (
          <div className={'mt-small'}>{this._categoryInfoSection()}</div>
        ) : packageType === 'dates' ? (
          <React.Fragment>
            <div className="anim-slide-left">
              {' '}
              <Title level={2} className="text-weight-regular">
                Edit <b>Funding Package</b>
              </Title>
            </div>
            <div>
              <Text>
                You are editing the dates from{' '}
                <b>
                  {selectedCustomer.firstName} {selectedCustomer.lastName}
                </b>{' '}
                Funding Package. Please enter the following details.
              </Text>
            </div>
            <FundEditDatesPanel
              closeEditDateModal={this.props.closeEditFundingPackage}
              selectedCustomer={this.props.selectedCustomer}
              startDate={selectedPackage.startDate}
              reviewDate={selectedPackage.expiredDate}
              packageId={this.props.packageId}
            />
          </React.Fragment>
        ) : null}
      </FullScreenScrollableModal>
    );
  }
}

export default Form.create<IEditFundingPackageProps>()(EditFundingPackage);
