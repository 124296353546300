import React, { Component } from 'react';
import _ from 'lodash';
import { Col, Form, Input, notification, Row, Select } from 'antd';
import { FieldLabel, FieldValueText, Text, Title } from 'common-components/typography';
import { GhostButton, PrimaryButton, HyperlinkButton } from 'common-components/buttons';
import { Icon } from 'antd/es';
import { FormComponentProps } from 'antd/es/form';
import CommonUtils from 'utilities/common-utils';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import Map from 'common-components/map/Map';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import FormCol from 'common-components/forms/FormCol';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import ArchiveDebtorModal from './ArchiveDebtorModal';
import { ArchiveDebtorFrom } from 'utilities/enum-utils';
import UnarchiveDebtorModal from './UnarchiveDebtorModal';
import * as H from 'history';

const searchAutocompleteOptions = {
  componentRestrictions: { country: ['au'] },
};

interface IDebtorGeneralPanelProps extends FormComponentProps {
  debtor: any;
  history: H.History;
  doEditDebtorGeneralInfo: typeof dispatch.accountStore.doEditDebtorGeneralInfo;
  doArchiveDebtor: typeof dispatch.accountStore.doArchiveDebtor;
}

interface IDebtorGeneralPanelState {
  lattitude: any;
  longitude: any;
  selectedAddressIndex: number;
  isEdit: boolean;
  selectedTransport: string;
  isopen: boolean;
  isSaving: boolean;
  address: string;
  selectedLattitude: any;
  selectedLongitude: any;
  selectedAddress: any;
  isOpenArchiveModal: boolean;
  isOpenUnarchiveModal: boolean;
  debtorName: string;
  debtorOrganisationName: string;
  debtorNumber: string;
  debtorContactNumber: string;
  debtorEmail: string;
  debtorAddress: string;
  emailError: boolean;
  debtorNumberError: boolean;
}

class DebtorGeneralPanel extends Component<IDebtorGeneralPanelProps, IDebtorGeneralPanelState> {
  state = {
    lattitude: 0,
    longitude: 0,
    selectedAddressIndex: -1,
    isEdit: false,
    selectedTransport: '',
    isopen: false,
    isSaving: false,
    address: '',
    selectedLattitude: 0,
    selectedLongitude: 0,
    selectedAddress: null,
    isOpenArchiveModal: false,
    isOpenUnarchiveModal: false,
    debtorName: '',
    debtorOrganisationName: '',
    debtorNumber: '',
    debtorContactNumber: '',
    debtorEmail: '',
    debtorAddress: '',
    emailError: false,
    debtorNumberError: false,
  };

  private _handleChange = (address) => {
    this.setState({ address });
  };

  private _handleSelect = (address) => {
    this.setState({ address: address });
    geocodeByAddress(address)
      .then((results) => {
        this.setState({
          selectedAddress: CommonUtils.createAddressFromGoogleMap(results[0].address_components),
        });
      })
      .catch((error) => console.error('Error', error));
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => this.setState({ selectedLattitude: lat, selectedLongitude: lng }))
      .catch((error) => console.error('Error', error));
  };

  private _validateMobileNumber = (rule, value, callback) => {
    const region = 'AU';

    try {
      if (!_.isEmpty(value)) {
        const phoneNumber = parsePhoneNumberFromString(value, region);
        if (phoneNumber === undefined || !phoneNumber || !phoneNumber.isValid()) {
          throw new Error('Invalid Phone number');
        }
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };
  private _onSave = async () => {
    const { form, doEditDebtorGeneralInfo, debtor } = this.props;
    const { address, selectedAddress, selectedLattitude, selectedLongitude } = this.state;
    let isFormValid = true;
    this.setState({ emailError: false, debtorNumberError: false });
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      let debtorAdress = {};
      if (address && selectedAddress) {
        debtorAdress = {
          ...selectedAddress,
          geoLat: selectedLattitude,
          geoLng: selectedLongitude,
          fullAddress: address,
        };
      }

      const payload = {
        debtorId: debtor.debtorId,
        debtorName: form.getFieldValue('debtorName'),
        organisationName: form.getFieldValue('organisationName'),
        email: form.getFieldValue('debtorEmail'),
        debtorNumber: form.getFieldValue('debtorNumber'),
        contactNumber: form.getFieldValue('debtorContactNumber'),
        contactNumberCountryCode: form.getFieldValue('debtorContactNumber') ? 'AU' : null,
        address: debtorAdress,
      };

      //filtering out null values
      _.forOwn(payload, (value, key) => {
        if (!value || _.isEmpty(value)) {
          delete payload[key];
        }
      });

      try {
        await doEditDebtorGeneralInfo(payload);
        this.setState({
          isEdit: false,
          debtorName: form.getFieldValue('debtorName'),
          debtorOrganisationName: form.getFieldValue('organisationName'),
          debtorEmail: form.getFieldValue('debtorEmail'),
          debtorNumber: form.getFieldValue('debtorNumber'),
          debtorContactNumber: form.getFieldValue('debtorContactNumber'),
          debtorAddress: address,
          lattitude: selectedLattitude,
          longitude: selectedLongitude,
        });
      } catch (e) {
        if (e.meta.message === 'The provided debtor email already exists') {
          this.setState({ emailError: true });
        } else if (e.meta.message === 'The provided debtor number already exists') {
          this.setState({ debtorNumberError: true });
        } else if (
          e.meta.message === 'The provided debtor email already exists, The provided debtor number already exists'
        ) {
          this.setState({ debtorNumberError: true, emailError: true });
        } else {
          notification.error({ message: 'Oops, an error has occurred, please try again.' });
        }
      }
    }
  };

  componentDidMount = async () => {
    const { debtor } = this.props;
    if (debtor) {
      this.setState({
        debtorName: debtor.debtorName,
        debtorOrganisationName: debtor.organisationName,
        debtorNumber: debtor.debtorNumber ? debtor.debtorNumber : '',
        debtorContactNumber: debtor.debtorContactNumber ? debtor.debtorContactNumber : '',
        debtorEmail: debtor.debtorEmail,
        debtorAddress: debtor.address && debtor.address.fullAddress ? debtor.address.fullAddress : '',
        lattitude: debtor.address && debtor.address.fullAddress ? debtor.address.geoLat : 0,
        longitude: debtor.address && debtor.address.fullAddress ? debtor.address.geoLng : 0,
      });
    }
  };

  render() {
    const { debtor, form, history } = this.props;
    const {
      isEdit,
      isSaving,
      address,
      isOpenArchiveModal,
      isOpenUnarchiveModal,
      debtorName,
      debtorOrganisationName,
      debtorNumber,
      debtorContactNumber,
      debtorEmail,
      debtorAddress,
      lattitude,
      longitude,
      emailError,
      debtorNumberError,
    } = this.state;
    const { getFieldDecorator } = form;
    if (!isEdit) {
      return (
        <>
          <UnarchiveDebtorModal
            debtor={debtor}
            unarchiveDebtorFrom={ArchiveDebtorFrom.DETAILS_PAGE}
            isOpen={isOpenUnarchiveModal}
            history={history}
            closeUnarchiveModal={() => this.setState({ isOpenUnarchiveModal: false })}
          />
          <div className={'x2-large'}>
            <Row type={'flex'} justify={'space-between'} align={'bottom'}>
              <Col>
                <Title level={2} className={'m-none'}>
                  General Information
                </Title>
                <Text type={'secondary'}>General information about this debtor</Text>
              </Col>
              {debtor && debtor.isArchived ? (
                <Col className={'align-center'}>
                  <div className={'flex-row-reverse align-center'}>
                    <PrimaryButton onClick={() => this.setState({ isOpenUnarchiveModal: true })}>
                      Unarchive debtor
                    </PrimaryButton>
                  </div>
                </Col>
              ) : (
                <Col className={'align-center'}>
                  <div className={'flex-row-reverse align-center'}>
                    <PrimaryButton icon={'edit'} onClick={() => this.setState({ isEdit: true })}>
                      Edit Information
                    </PrimaryButton>
                  </div>
                </Col>
              )}
            </Row>
            <div className="mt-x-large">
              <Row gutter={24}>
                <Col span={12}>
                  <FieldLabel text={'Name'} />
                  <FieldValueText text={debtorName} />
                </Col>
                <Col span={12}>
                  <FieldLabel text={'Debtor ID'} />
                  <FieldValueText text={debtorNumber ? debtorNumber : 'Not set'} />
                </Col>
              </Row>
            </div>
            <div className="mt-x-large">
              <Row gutter={24}>
                <Col span={12}>
                  <FieldLabel text={'Contact Number'} />
                  <FieldValueText text={debtorContactNumber ? debtorContactNumber : 'Not set'} />
                </Col>
                <Col span={12}>
                  <FieldLabel text={'Email'} />
                  <FieldValueText text={debtorEmail} />
                </Col>
              </Row>
            </div>
            <div className="mt-x-large">
              <Row gutter={24}>
                <Col span={12}>
                  <FieldLabel text={'Organisation Name'} />
                  <FieldValueText text={debtorOrganisationName ? debtorOrganisationName : 'Not set'} />
                </Col>
              </Row>
            </div>
            <div className="mt-x-large mb-small">
              <FieldLabel text={'Address'} />
              <FieldValueText text={debtorAddress ? debtorAddress : 'Not set'} />
            </div>
            {debtorAddress && (
              <div style={{ width: '700px' }}>
                <Map lattitude={lattitude} longitude={longitude} />
              </div>
            )}
          </div>
        </>
      );
    } else {
      return (
        <div>
          <ArchiveDebtorModal
            debtor={debtor}
            archiveDebtorFrom={ArchiveDebtorFrom.DETAILS_PAGE}
            isOpen={isOpenArchiveModal}
            closeArchiveModal={() => this.setState({ isOpenArchiveModal: false })}
            closeEditMode={() => this.setState({ isEdit: false })}
          />
          <Row>
            <Title level={2} className={'m-none'}>
              General Information
            </Title>
            <Text type={'secondary'}>General information about this debtor</Text>
          </Row>
          <div className="mt-large">
            <Row>
              <FormCol label={'Name'} leftSpan={5} textAlign={'left'} />
              <Col span={19}>
                <Form.Item>
                  {getFieldDecorator('debtorName', {
                    initialValue: debtorName,
                    rules: [{ required: true, message: 'Please enter a name' }],
                  })(<Input size="large" placeholder="Enter name" style={{ maxWidth: '300px' }} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label={'Organisation'} leftSpan={5} textAlign={'left'} />
              <Col span={19}>
                <Form.Item>
                  {getFieldDecorator('organisationName', {
                    initialValue: debtorOrganisationName,
                    rules: [{ required: false }],
                  })(<Input size="large" placeholder="Enter organisation name" style={{ maxWidth: '300px' }} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label={'Debtor ID'} leftSpan={5} textAlign={'left'} />
              <Col span={19}>
                <Form.Item>
                  {getFieldDecorator('debtorNumber', {
                    initialValue: debtorNumber,
                    rules: [{ required: false }],
                  })(<Input size="large" placeholder="Enter ID" style={{ maxWidth: '300px' }} />)}
                  {debtorNumberError && (
                    <Text size="regular" color="red" className="ml-medium">
                      Enter a unique ID.
                    </Text>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label={'Email'} leftSpan={5} textAlign={'left'} />
              <Col span={19}>
                <Form.Item>
                  {getFieldDecorator('debtorEmail', {
                    initialValue: debtorEmail,
                    rules: [{ required: true, message: 'Please enter an email address' }],
                  })(<Input size="large" placeholder="Enter email" style={{ maxWidth: '300px' }} />)}
                  {emailError && (
                    <Text size="regular" color="red" className="ml-medium">
                      Enter an email that is not associated with an existing debtor.
                    </Text>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label={'Contact Number'} leftSpan={5} textAlign={'left'} />
              <Col span={19}>
                <div className={'flex-row mt-x2-small'}>
                  <Select disabled size={'large'} className="mr-small" style={{ width: '110px' }} defaultValue={'AU'}>
                    <Select.Option value={'AU'}>+61 (AU)</Select.Option>
                  </Select>
                  <Form.Item>
                    {getFieldDecorator('debtorContactNumber', {
                      initialValue: debtorContactNumber,
                      rules: [{ validator: this._validateMobileNumber }],
                    })(<Input size={'large'} placeholder={'Enter number'} style={{ maxWidth: '300px' }} />)}
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row>
              <FormCol label={'Address'} leftSpan={5} textAlign={'left'} />
              <Col span={19}>
                <PlacesAutocomplete
                  value={address}
                  onSelect={this._handleSelect}
                  onChange={this._handleChange}
                  shouldFetchSuggestions={this.state.address.length > 2}
                  searchOptions={searchAutocompleteOptions}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                      <Input
                        value={address}
                        size={'large'}
                        style={{ maxWidth: '500px' }}
                        {...getInputProps({
                          placeholder: 'Search for an address ...',
                          className: 'location-search-input',
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion, index) => {
                          const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                          const style = suggestion.active
                            ? {
                                backgroundColor: '#fafafa',
                                cursor: 'pointer',
                                borderTop: '1px ridge grey',
                                borderLeft: '1px ridge grey',
                                borderRight: '1px ridge grey',
                                padding: '10px',
                              }
                            : {
                                backgroundColor: '#ffffff',
                                cursor: 'pointer',
                                borderTop: '1px ridge grey',
                                borderLeft: '1px ridge grey',
                                borderRight: '1px ridge grey',
                                padding: '10px',
                              };
                          return (
                            <div
                              key={index}
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>
                                <Icon type={'environment'} /> {suggestion.description}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Col>
            </Row>

            <Row type={'flex'} justify={'space-between'} align={'middle'} className={'mt-large'}>
              <Col>
                <HyperlinkButton color="red" onClick={() => this.setState({ isOpenArchiveModal: true })}>
                  Archive debtor
                </HyperlinkButton>
              </Col>
              <Col className="flex">
                <GhostButton onClick={() => this.setState({ isEdit: false })}>Discard Changes</GhostButton>

                <PrimaryButton disabled={isSaving} onClick={this._onSave} icon={'save'}>
                  Save
                </PrimaryButton>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
  }
}

const mapState = (state: IRootState) => ({
  companyDataLite: state.companyStore.companyDataLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditDebtorGeneralInfo: dispatch.accountStore.doEditDebtorGeneralInfo,
  doArchiveDebtor: dispatch.accountStore.doArchiveDebtor,
});

export default connect(mapState, mapDispatch)(Form.create<IDebtorGeneralPanelProps>()(DebtorGeneralPanel));
