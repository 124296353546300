import React, { Component } from 'react';
import { Paragraph, Text, FieldLabel } from 'common-components/typography';
import { Avatar, Checkbox, Popover } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import _ from 'lodash';
import moment from 'moment-timezone';
import { ShiftStatusTag } from 'views/group-services/session-details/team-members/shift-slot-table/ShiftStatusTag';
import { BookingTypeIcon } from 'common-components/tags';
import { ServiceType } from 'utilities/enum-utils';
interface IAvailabilityConflictTimesProps {
  worker: any;
  timezone?: any;
  onSelectShifts?: (shifts) => void;
  type?: 'SHIFT' | 'BOOKING';
  showPopover?: boolean;
}

interface IAvailabilityConflictTimesState {
  selectedShifts: any[];
}

class AvailabilityConflictWorker extends Component<IAvailabilityConflictTimesProps, IAvailabilityConflictTimesState> {
  state = {
    selectedShifts: []
  };

  private _getPopoverContent = (item) => {
    const { timezone, worker } = this.props;
    const { customer } = item;
    const memberName = worker.firstName + ' ' + worker.lastName;
    const memberAvatar = worker.attachmentUrl;

    return (
      <div className="flex-column">
        <div style={{ width: '80px' }}>
          <ShiftStatusTag shiftStatus={item.status} />
        </div>
        <div className="flex-column mt-medium">
          <Text weight="bold" className="text-size-x-large">
            {moment.tz(item.startDateTime, timezone).format('dddd, DD MMM YYYY')}
          </Text>
          <Text weight="bold" className="text-size-x-large">
            {moment.tz(item.startDateTime, timezone).format('hh:mm A')} -{' '}
            {moment.tz(item.endDateTime, timezone).format('hh:mm A')}
          </Text>
          <Text className="text-size-x-large">{item.serviceProviderName}</Text>
        </div>
        <div className="flex-column mt-medium">
          <div className="flex align-center">
            <BookingTypeIcon type={item.serviceType} className={'mr-small'} />
            <div className="text-color-secondary">
              {item.serviceType === ServiceType.INDIVIDUAL ? 'Support service' : 'Group service'}
            </div>
          </div>
          <Text>{item.serviceName}</Text>
        </div>
        {!_.isEmpty(customer) && (
          <div className="flex-column mt-medium">
            <FieldLabel text="Customer" />
            <div className="flex align-center" key={customer.firstName + ' ' + customer.lastName}>
              <Avatar icon="user" size={32} shape="circle" src={customer.attachmentUrl} />
              <b className="ml-small">
                <Text weight="regular" className="text-size-large">
                  {customer.firstName + ' ' + customer.lastName}
                </Text>
              </b>
            </div>
          </div>
        )}
        <div className="flex-column mt-medium">
          <FieldLabel text="Team member" />
          <div className="flex align-center">
            <Avatar icon="user" size={32} shape="circle" src={memberAvatar} />
            <b className="ml-small">
              <Text weight="regular" className="text-size-large">
                {memberName}
              </Text>
            </b>
          </div>
        </div>
      </div>
    );
  };

  private _isChecked = (item) => {
    return _.findIndex(this.state.selectedShifts, item) !== -1;
  };

  private _mappedShifts = (shifts) =>
    !_.isEmpty(shifts)
      ? _.map(shifts, (item) => ({
          serviceDateTimeId: item.serviceDateTimeId,
          attendanceId: item.attendanceId,
          supportWorkerAttendanceId: item.supportWorkerAttendanceId
        }))
      : [];

  private _onCheckboxChange = (e, item) => {
    const { selectedShifts } = this.state;
    let newSelectedShifts = [...selectedShifts];

    if (e.target.checked) {
      newSelectedShifts.push(item);
    } else {
      newSelectedShifts = _.filter(
        newSelectedShifts,
        (shift) => shift.supportWorkerAttendanceId !== item.supportWorkerAttendanceId
      );
    }

    const mappedShifts = this._mappedShifts(newSelectedShifts);
    this.props.onSelectShifts(mappedShifts);
    this.setState({ selectedShifts: newSelectedShifts });
  };

  private _onCheckAll = () => {
    const { worker, onSelectShifts } = this.props;
    const bookings = worker.bookings || worker.shifts;
    let newSelectedShift = [];

    if (this.state.selectedShifts.length < bookings.length) {
      newSelectedShift = bookings;
    }

    const mappedShifts = this._mappedShifts(newSelectedShift);

    onSelectShifts(mappedShifts);
    this.setState({ selectedShifts: newSelectedShift });
  };

  render() {
    const { worker, timezone, type = 'SHIFT', showPopover = true } = this.props;
    const memberName = worker.firstName + ' ' + worker.lastName;
    const avatar = worker.attachmentUrl;
    const bookings = worker.bookings || worker.shifts;

    return (
      <div
        className="p-medium bordered border-standard-gray rounded-big line-height-135 flex-column bg-white mb-small"
        style={{ maxHeight: '300px', overflow: 'auto' }}
      >
        <div className="flex-row">
          <div className="ml-large flex-row">
            <Avatar icon="user" size={25} shape="square" src={avatar} />
            <b className="ml-small">
              <Text className="text-color-tertiary text-size-regular">{memberName}</Text>
            </b>
          </div>
        </div>
        <Paragraph>
          Use the checkboxes to <Text weight="bold">select which shifts you want to keep</Text> for the team member.
        </Paragraph>
        <HyperlinkButton onClick={this._onCheckAll}>
          {this.state.selectedShifts.length === bookings.length ? 'De-select all' : 'Select all'}
        </HyperlinkButton>
        <div className="mt-large bordered border-standard-gray rounded-big">
          {_.map(bookings, (item) => (
            <div
              key={type === 'BOOKING' ? item.bookingId : item.supportWorkerAttendanceId}
              className="p-medium evenodd"
            >
              <Checkbox
                className="mr-small"
                onChange={(e) => {
                  this._onCheckboxChange(e, item);
                }}
                checked={this._isChecked(item)}
              />
              <Text weight="bold">{item.serviceName}: </Text>
              <Popover content={showPopover ? this._getPopoverContent(item) : null} trigger="click" placement="right">
                <Text className="text-underline text-color-blue-action cursor-pointer">
                  {moment.tz(item.startDateTime, timezone).format('dddd DD/MM/YY, ')}{' '}
                  {moment.tz(item.startDateTime, timezone).format('hh:mm A')} -{' '}
                  {moment.tz(item.endDateTime, timezone).format('hh:mm A')}
                </Text>
              </Popover>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default AvailabilityConflictWorker;
