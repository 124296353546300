export enum BookingStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  CONFIRMED = 'CONFIRMED',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
  CUSTOMER_CANCELLED = 'CUSTOMER_CANCELLED',
  CUSTOMER_CANCELLED_WITH_FEE = 'CUSTOMER_CANCELLED_WITH_FEE',
  CUSTOMER_CANCELLED_WITHOUT_FEE = 'CUSTOMER_CANCELLED_WITHOUT_FEE',
  BUSINESS_CANCELLED = 'BUSINESS_CANCELLED',
  REJECTED = 'REJECTED',
  ARCHIVED = 'ARCHIVED'
}

export enum BookingStatusString {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  CONFIRMED = 'CONFIRMED',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
  CUSTOMER_CANCELLED = 'CUSTOMER CANCELLED',
  CUSTOMER_CANCELLED_WITH_FEE = 'CUSTOMER CANCELLED WITH FEE',
  CUSTOMER_CANCELLED_WITHOUT_FEE = 'CUSTOMER CANCELLED WITHOUT FEE',
  BUSINESS_CANCELLED = 'BUSINESS CANCELLED',
  REJECTED = 'REJECTED',
  BILLED = 'BILLED',
  ARCHIVED = 'ARCHIVED'
}

export enum PaymentMethods {
  NDIA = 'NDIA',
  PLAN = 'PLAN',
  SELF = 'SELF'
}

export enum PaymentMethodsLabel {
  NDIA = 'NDIA',
  PLAN = 'Plan Management',
  SELF = 'Self Management'
}

export enum PaymentStatus {
  INITIAL = 'INITIAL',
  REQUIRES_APPROVAL = 'REQUIRES_APPROVAL',
  READY_FOR_BILLING = 'READY_FOR_BILLING',
  SEND_TO_FINANCE = 'SEND_TO_FINANCE',
  PROCESSED = 'PROCESSED',
  BILLING_FAILED = 'BILLING_FAILED',
  WAIVED = 'WAIVED',
  NO_CHARGE = 'NO_CHARGE',
  REJECTED = 'REJECTED'
}

export enum PaymentStatusLabel {
  INITIAL = '-',
  REQUIRES_APPROVAL = 'Require approval',
  READY_FOR_BILLING = 'Ready for billing',
  SEND_TO_FINANCE = 'Sent to finance',
  PROCESSED = 'Processed',
  BILLING_FAILED = 'Billing failed',
  WAIVED = 'Waived',
  NO_CHARGE = 'No charge',
  REJECTED = 'Rejected'
}

export enum PublishShiftApplicationStatus {
  APPLIED = 'APPLIED',
  UNSUCCESSFUL = 'UNSUCCESSFUL'
}

export enum PublishShiftApplicationAvailability {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  OUTSIDE_GENERAL_AVAILABILITY = 'OUTSIDE_GENERAL_AVAILABILITY',
  UNAVAILABLE_CAN_BE_OVERRIDDEN = 'UNAVAILABLE_CAN_BE_OVERRIDDEN',
  UNAVAILABLE = 'UNAVAILABLE',
  SHIFT_CLASH = 'SHIFT_CLASH'
}

export enum WorkerFilterStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  UNASSIGNED = 'UNASSIGNED',
  PUBLISHING = 'PUBLISHING'
}

export enum ShiftSlotStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  UNASSIGNED = 'UNASSIGNED',
  CHECKED_IN = 'CHECKED_IN',
  CHECKED_OUT = 'CHECKED_OUT',
  CANCELLED = 'CANCELLED',
  PUBLISHING = 'PUBLISHING',
  CLAIMED = 'CLAIMED',
  REJECTED = 'REJECTED',

  // Added by Jir - No sure if the following status slots are in the API.
  APPROVED = 'APPROVED',
  NO_SHOW = 'NO_SHOW',
  DECLINED = 'DECLINED',

  //Added by Shaarang - Worker Paid for Shift, User Story- cancel-booking-with-no-fee-pay-team-member
  CANCELLED_PAID = 'CANCELLED_PAID',
  CANCELLED_UNPAID = 'CANCELLED_UNPAID',
  //Worker approved pay for cancelled booking
  SENT_TO_FINANCE = 'SENT_TO_FINANCE'
}

export enum BillingPaymentStatus {
  INITIAL = 'INITIAL',
  REQUIRES_APPROVAL = 'REQUIRES_APPROVAL',
  READY_FOR_BILLING = 'READY_FOR_BILLING',
  SEND_TO_FINANCE = 'SEND_TO_FINANCE',
  PROCESSED = 'PROCESSED',
  REJECTED = 'REJECTED',
  WAIVED = 'WAIVED',
  NO_CHARGE = 'NO_CHARGE'
}

export enum NdisSupportItemDateType {
  ABT = 'ABT',
  TR = 'TR',
  PTNLC = 'PTNLC'
}

export enum ServiceRecurringType {
  ONEOFF = 'ONEOFF',
  RECURRING = 'RECURRING'
}

export enum ServiceLocationType {
  FIXEDVENUE = 'FIXEDVENUE',
  ONLINE = 'ONLINE',
  CUSTOMERLOCATION = 'CUSTOMERLOCATION'
}

export enum ServiceWorkerSelectionType {
  SELECTABLE = 'SELECTABLE',
  ASSIGNED = 'ASSIGNED'
}

export enum ServicePublishStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
  ARCHIVED = 'ARCHIVED'
}

export enum ServiceType {
  INDIVIDUAL = 'INDIVIDUAL',
  GROUP = 'GROUP'
}

export enum ServiceTimeType {
  FIXEDTIME = 'FIXEDTIME',
  OPENTIME = 'OPENTIME'
}

export enum NdisSupportTypes {
  CORE = 'CORE',
  CAPITAL = 'CAPITAL',
  CB = 'CAPACITY BUILDING'
}

export enum MessageChannelType {
  IndividualChat = 1,
  GroupChat,
  ProviderToConsumer,
  ProviderToWorker,
  Enquiry
}

export enum RecurringBookingPattern {
  EveryDay = 1,
  EveryWeek,
  EveryFortnight,
  EveryFourWeeks,
  EveryThreeWeeks
}

export enum EditRecurringMode {
  Current = 1, // current attendance
  CurrentAll, // current attendance and all future attendance
  CurrentNext, // current attendance and number of attendance next
  Upcoming, // all attendance that booking status is CONFIRMED, PENDING or ACCEPTED
  AllWorkerUpcoming, // all attendance for all worker that booking status is CONFIRMED, PENDING or ACCEPTED
  AllWorkerCurrentAll, // all attendance for all worker that
  Custom //for custom recurring booking modal
}

export enum TimezoneSelectorMode {
  MyTimezone = 'MY_TIMEZONE',
  BookingTimezone = 'BOOKING_TIMEZONE',
  CustomTimezone = 'CUSTOM_TIMEZONE'
}

export enum PermissionType {
  NO_ACCESS = 'No access',
  VIEW_ONLY = 'View only',
  MEMBER = 'Member',
  MANAGER = 'Manager',
  CUSTOM = 'Custom'
}

// Local front-end only enums for select states
export enum PaymentPermissionType {
  NO_ACCESS = 'No access',
  MEMBER = 'Member',
  MANAGER = 'Manager',
  FULL_ACCESS = 'Full Access (Owner)'
}

export enum TeamPermissionType {
  VIEW_ONLY = 'View only',
  MANAGER = 'Manager',
  FULL_ACCESS = 'Full Access (Owner)'
}

export enum CustomerPermissionType {
  VIEW_ONLY = 'View only',
  MANAGER = 'Manager',
  FULL_ACCESS = 'Full Access (Owner)'
}

export enum PlanManagementPermissionType {
  NO_ACCESS = 'No access',
  ACCESS = 'Access'
}

export enum ServicePermissionType {
  NO_ACCESS = 'No access',
  MEMBER = 'Member',
  MANAGER = 'Manager',
  CUSTOM = 'Custom',
  FULL_ACCESS = 'Full Access (Owner)'
}

export enum TeamStatus {
  ENABLED = 'ENABLED',
  DRAFT = 'DRAFT',
  BLOCKED = 'BLOCKED',
  REMOVED = 'REMOVED'
}

export enum InvitationStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  DELETED = 'DELETED'
}
export enum InvitationType {
  NEW = 'NEW',
  RE_ENABLED = 'RE-ENABLED'
}

export enum UserPermissionRole {
  PrimaryOwner = 1,
  Owner = 2,
  CustomerManager = 3,
  CustomerViewOnly = 4,
  TeamManager = 5,
  TeamViewOnly = 6,
  PaymentManager = 7,
  PaymentMember = 8,
  ServiceAdmin = 9,
  ServiceMember = 10,
  PlanManager = 11
}

export enum BillingAutoGeneratedType {
  ScheduledTime = 0,
  WorkerCheckinCheckOutTime = 1,
  BeforeBookingTravel = 2,
  DuringBookingTravel = 3,
  PortalCheckinCheckOutTime = 4
}

export enum DocumentExpiryStatus {
  EXPIRED = 'EXPIRED',
  EXPIRING_SOON = 'EXPIRING_SOON',
  NOT_EXPIRED = 'NOT_EXPIRED'
}

export enum PaymentSourceType {
  INVOICE = 'INVOICE',
  NDIS = 'NDIS',
  VCP = 'VCP'
}

export enum DateFilterPeriodModes {
  ALL = 'ALL',
  TODAY = 'TODAY',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  CUSTOM = 'CUSTOM'
}

export enum FilterType {
  CUSTOMER = 'customerUserIds',
  WORKER = 'supportWorkerIds',
  SERVICE = 'serviceIds',
  BOOKING_STATUS = 'status',
  RECURRING = 'isRecurring',
  PAYMENT_STATUS = 'paymentStatus',
  DATE_RANGE = 'startDate',
  NOTE_TYPE = 'noteType',
  IS_INCIDENT = 'isIncident',
  NOTE_AUTHOR = 'noteAuthorIds',
  NOTE_SERVICE = 'noteService',
  SERVICE_STATUS = 'serviceStatus',
  SERVICE_DEPARTMENT = 'serviceDepartmentIds',
  SERVICE_TYPE = 'serviceType',
  NDIS_CATEGORIES = 'ndisCategories',
  PROVIDER = 'planManagementSupplierIds',
  SUPPLIER_PAYMENT_STATUS = 'supplierPaymentStatus',
  APPROVAL_STATUS = 'approvalStatus',
  CLAIM_STATUS = 'claimStatus',
  BOOKING_ERROR_TYPES = 'bookingErrorTypes',
  QUALIFICATIONS = 'qualifications',
  SPECIALITIES = 'specialities',
  RELIGIONS = 'religions',
  GENDER = 'gender',
  SKILLS = 'skills',
  LANGUAGES = 'languages',
  INTEREST = 'interests',
  CUSTOM_CUSTOMER = 'customCustomerUserIds',
  PAYMENT_METHODS = 'paymentMethods',
  PAYMENT_SOURCES = 'paymentSourceTypes',
  BATCH_AUTHOR = 'batchAuthorIds',
  SHOW_ARCHIVED_BOOKINGS = 'showArchivedBookings',
  PINNED_ALERTS = 'hasCustomerPinnedAlert',
  SESSION_STATUS = 'sessionStatus',
  SHIFT_SLOT_WARNINGS = 'showWarningsOnly',
  SHIFT_SLOT_STATUS = 'shiftSlotStatus',
  CUSTOMER_CONNECTION_STATUS = 'connectionStatus',
  MANAGEMENT_METHOD = 'managementMethod',
  USER_LOCATION_BY_STATE = 'userLocationByState',
  CUSTOMER_STATUS = 'customerStatus',
  PERMANENT_CONDITIONS = 'permanentConditions',
  CUSTOMER_MANAGED_BY = 'isManaged',
  PREFERRED_SUPPORT_WORKER = 'hasPreferredSupportWorkers',
  BLOCKED_SUPPORT_WORKER = 'hasBlockedSupportWorkers',
  BOOKING_TYPES = 'bookingTypes',
  WORKER_ALERTS = 'supportWorkerAlertTypes',
  CUSTOMER_ALERTS = 'alertTypes',
  SERVICE_DATE_TIMES = 'serviceDateTimeIds',
  GROUP_SERVICE_SCHEDULES = 'serviceScheduleIds',
  LOCATION_BY_SUBURBS = 'locationPostcodes',
  INVOICE_DATE = 'invoiceDate',
  DATE_PAID = 'datePaid',
  INVOICE_STATUS = 'invoiceStatus',
  SUPPORT_COORDINATOR = 'supportCoordinators',
  CASE_MANAGER = 'caseManagers'
}

export enum FilterLabel {
  customerUserIds = 'Customer',
  customCustomerUserIds = 'Customer',
  supportWorkerIds = 'Team member',
  serviceIds = 'Service',
  status = 'Booking status',
  isRecurring = 'Is recurring booking',
  paymentStatus = 'Payment status',
  startDate = 'Date range',
  noteType = 'Note type',
  isIncident = 'Incident?',
  noteService = 'Service',
  noteAuthorIds = 'Author',
  ndisCategories = 'Support categories',
  serviceStatus = 'Service status',
  serviceDepartmentIds = 'Service Department',
  serviceType = 'Service Type',
  planManagementSupplierIds = 'Provider',
  supplierPaymentStatus = 'Provider payment status',
  approvalStatus = 'Approval status',
  claimStatus = 'Claim status',
  bookingErrorTypes = 'Warnings/Errors',
  qualifications = 'Qualifications',
  specialities = 'Specialities',
  religions = 'Religions',
  gender = 'Gender',
  skills = 'Skills',
  languages = 'Languages Spoken',
  interests = 'Interests',
  paymentMethods = 'Management methods',
  paymentSourceTypes = 'Payment methods',
  batchAuthorIds = 'Batch authors',
  showArchivedBookings = 'Show archived bookings',
  hasCustomerPinnedAlert = 'Has pinned alerts',
  sessionStatus = 'Status',
  showWarningsOnly = 'Warnings',
  shiftSlotStatus = 'Team member status',
  connectionStatus = 'Connection status',
  managementMethod = 'Management method',
  userLocationByState = 'Location',
  customerStatus = 'Customer status',
  permanentConditions = 'Permanent conditions',
  isManaged = 'Managed by',
  hasPreferredSupportWorkers = 'Preferred team members',
  hasBlockedSupportWorkers = 'Blocked team members',
  bookingTypes = 'Booking types',
  supportWorkerAlertTypes = 'Alerts',
  alertTypes = 'Alerts',
  serviceDateTimeIds = 'Group service sessions',
  serviceScheduleIds = 'Group service schedules',
  locationPostcodes = 'Location (Postcode)',
  invoiceDate = 'Invoice date',
  datePaid = 'Date paid',
  invoiceStatus = 'Invoice Status',
  supportCoordinators = 'Support coordinators',
  caseManagers = 'Case managers'
}

export enum ServiceAgreementStatus {
  DRAFT = 'DRAFT',
  UNDER_CUSTOMER_REVIEW = 'UNDER_CUSTOMER_REVIEW',
  SIGNED = 'SIGNED',
  ACTIVE = 'ACTIVE',
  UPCOMING = 'UPCOMING',
  EXPIRED = 'EXPIRED',
  UNSIGNED = 'UNSIGNED',
  EXPIRING_SOON = 'EXPIRING_SOON'
}

export enum CustomerNoteVisibleType {
  PORTAL = 'PORTAL',
  PORTAL_AND_APP = 'PORTAL_AND_APP'
}

export enum NDISLineItemUnit {
  H = 'Hourly',
  EA = 'Each',
  D = 'Daily',
  YR = 'Yearly',
  WK = 'Weekly'
}

export enum ServiceStatus {
  UNPUBLISHED = 'UNPUBLISHED',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED'
}

export enum ServiceStatusLabel {
  UNPUBLISHED = 'Unpublished',
  DRAFT = 'Draft',
  PUBLISHED = 'Published'
}

export enum MmmGroup {
  NonRemote,
  Remote,
  VeryRemote
}

export enum PlanManagerType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL'
}

export enum PlanManagementApprovalStatus {
  NOT_SENT = 'NOT_SENT',
  SENT = 'SENT',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

export enum PlanManagementApprovalStatusDisplay {
  NOT_SENT = 'Not sent for Approval',
  SENT = 'Sent for Approval',
  APPROVED = 'Approved',
  REJECTED = 'Rejected'
}

export enum PlanManagementPaymentStatus {
  UNPAID = 'UNPAID',
  PAID = 'PAID',
  FAILED = 'FAILED'
}

export enum PlanManagementPaymentStatusDisplay {
  UNPAID = 'Unpaid',
  PAID = 'Paid',
  FAILED = 'Payment Issue'
}

export enum PlanManagementClaimStatus {
  UNCLAIMED = 'UNCLAIMED',
  CANCELLED = 'CANCELLED',
  WAIVED = 'WAIVED',
  PARTIALLY_REJECTED = 'PARTIALLY_REJECTED',
  PARTIALLY_SENT_TO_PAYMENTS = 'PARTIALLY_SENT_TO_PAYMENTS',
  PARTIALLY_CLAIMED = 'PARTIALLY_CLAIMED',
  REJECTED = 'REJECTED',
  CLAIMED = 'CLAIMED',
  SENT_TO_PAYMENTS = 'SENT_TO_PAYMENTS',
  READY_TO_BE_PAID = 'READY_TO_BE_PAID',
  PARTIALLY_UNCLAIMED = 'PARTIALLY_UNCLAIMED'
}

export enum PlanManagementClaimStatusDisplay {
  UNCLAIMED = 'Unclaimed',
  CANCELLED = 'Cancelled',
  WAIVED = 'Waived',
  PARTIALLY_REJECTED = 'Partially rejected',
  PARTIALLY_SENT_TO_PAYMENTS = 'Partially sent to payment',
  PARTIALLY_CLAIMED = 'Partially claimed',
  REJECTED = 'Rejected',
  CLAIMED = 'Claimed',
  SENT_TO_PAYMENTS = 'Sent to payment',
  READY_TO_BE_PAID = 'Ready to be paid',
  PARTIALLY_UNCLAIMED = 'Partially unclaimed'
}

export enum NdisClaimType {
  STANDARD = 'STANDARD',
  CANCELLATION = 'CANCELLATION',
  PROVIDER_TRAVEL = 'PROVIDER_TRAVEL',
  REPORT_WRITING = 'REPORT_WRITING',
  NON_FACE_TO_FACE = 'NON_FACE_TO_FACE',
  TELEHEALTH_SUPPORTS = 'TELEHEALTH_SUPPORTS',
  IRREGULAR_SIL_SUPPORTS = 'IRREGULAR_SIL_SUPPORTS'
}

export enum NdisClaimTypeLabel {
  STANDARD = 'Direct Support',
  CANCELLATION = 'Cancellation',
  PROVIDER_TRAVEL = 'Provider Travel',
  REPORT_WRITING = 'Report writing',
  NON_FACE_TO_FACE = 'Non face-to-face',
  TELEHEALTH_SUPPORTS = 'Telehealth supports',
  IRREGULAR_SIL_SUPPORTS = 'Irregular SIL supports'
}

export enum NdisCancellationReason {
  NSDH = 'NSDH',
  NSDF = 'NSDF',
  NSDT = 'NSDT',
  NSDO = 'NDSO'
}

export enum NdisCancellationReasonLabel {
  NSDH = 'No show due to health reasons',
  NSDF = 'No show due to family issues',
  NSDT = 'No show due to unavailability of transport',
  NSDO = 'No shoe due to other reasons'
}

export enum NdisUnitLabel {
  H = 'Hourly',
  EA = 'Each'
}

export enum SessionStatus {
  SCHEDULED = 'SCHEDULED',
  INPROGRESS = 'INPROGRESS',
  COMPLETED = 'COMPLETED',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED'
}

export enum SessionStatusLabel {
  SCHEDULED = 'Scheduled',
  INPROGRESS = 'In progress',
  COMPLETED = 'Completed',
  CLOSED = 'Closed',
  CANCELLED = 'Cancelled'
}

export enum BookingErrorType {
  PaymentMethodError = 1,
  BookingRecordedTimeWarning = 2,
  ServiceAgreementWarning = 3,
  FundingPackageWarning = 4,
  BookingActualTimeWarning = 5,
  BookingCheckinTimeWarning = 6,
  BookingCheckOutTimeWarning = 7,
  ServiceAgreementLineItemWarning = 8
}

export enum PaymentSources {
  NDIS = 'NDIS',
  VCP = 'VCP'
}

export enum VCPSupportItemDateTypeLabel {
  PBH = 'Public Holiday rate',
  SAT = 'Saturday rate',
  SUN = 'Sunday rate',
  WKD = 'Mon - Fri rate',
  WKE = 'After Hours rate',
  NSO = 'All',
  OVN = 'Night rate'
}

export enum CustomerSupportWorkerPreference {
  BLOCKED = 'BLOCKED',
  PREFERRED = 'PREFERRED'
}

export enum BookingErrorTimeSource {
  ACTUAL_TIME = 'ACTUAL_TIME',
  RECORD_TIME = 'RECORD_TIME'
}

export enum CustomerActiveType {
  ACTIVE = 'ACTIVE',
  DORMANT = 'DORMANT'
}

export enum ScheduleType {
  ONE_OFF = 'ONE_OFF',
  SCHEDULE = 'SCHEDULE'
}

export enum GroupServiceSessionStatus {
  SCHEDULED = 'SCHEDULED',
  INPROGRESS = 'INPROGRESS',
  CANCELLED = 'CANCELLED',
  CLOSED = 'CLOSED',
  COMPLETED = 'COMPLETED'
}

export enum TeamMemberCustomerRatio {
  ONE_TO_ONE = '1:1',
  ONE_TO_TWO = '1:2',
  ONE_TO_THREE = '1:3',
  ONE_TO_FOUR = '1:4',
  ONE_TO_FIVE = '1:5',
  ONE_TO_SIX = '1:6',
  TWO_TO_ONE = '2:1'
}

export enum GroupServiceClassification {
  COMMUNITY = 'COMMUNITY',
  CENTRE_BASED = 'CENTRE_BASED'
}

export enum PriceFrenquency {
  PERHOUR = 'PERHOUR',
  PERDAY = 'PERDAY',
  PERWEEK = 'PERWEEK',
  PERMONTH = 'PERMONTH',
  PERYEAR = 'PERYEAR',
  PEREACH = 'PEREACH'
}

export enum TransportAttendanceType {
  PRE_SESSION = 'PRE_SESSION',
  POST_SESSION = 'POST_SESSION'
}

export enum GroupBookingActionType {
  INDIVIDUAL_SESSIONS = 'INDIVIDUAL_SESSIONS',
  SCHEDULES = 'SCHEDULES'
}

export enum CustomerType {
  ALL = 'ALL',
  CUSTOMERS = 'CUSTOMERS',
  PARENT_GUARDIANS = 'PARENT_GUARDIANS',
  ENQUIRIES = 'ENQUIRIES'
}

export enum ShiftWarningType {
  'LATE_START' = 1,
  'LATE_FINISH' = 2,
  'EARLY_START' = 3,
  'EARLY_FINISH' = 4,
  'NO_SHOW' = 5,
  'SHIFT_UNFINISHED' = 6,
  'NO_WARNINGS' = 7
}

export enum WorkerAssignmentWarningType {
  UNAVAILABLE = 'unavailable',
  IN_SESSION = 'inSession',
  CAUTION = 'caution'
}

export enum OutsideAvailabilityType {
  UNAVAILABILITY = 'UNAVAILABILITY',
  OUTSIDE_GENERAL_AVAILABILITY = 'OUTSIDE_GENERAL_AVAILABILITY'
}

export enum SupportWorkerAvailability {
  AVAILABLE = 'AVAILABLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  LIMITED_AVAILABILITY = 'LIMITED_AVAILABILITY'
}

export enum MessageSubscriberType {
  PortalUser = 1,
  Customer,
  SupportWorker,
  None,
  ServiceProvider
}

export enum CustomerConnectionStatus {
  CONNECTED = 'CONNECTED',
  NOT_CONNECTED = 'NOT_CONNECTED',
  PENDING = 'PENDING'
}

export enum CustomerStatusType {
  ACTIVE = 'ACTIVE',
  ENQUIRY = 'ENQUIRY',
  ARCHIVED = 'ARCHIVED',
  SCHEDULED_TO_BE_ARCHIVED = 'SCHEDULED_TO_BE_ARCHIVED'
}

export enum BookingType {
  BOOKING = 'BOOKING',
  ACTIVITY_RECORD = 'ACTIVITY_RECORD'
}

export enum AlertLevel {
  ALL = 'ALL',
  HIGH = 1,
  MEDIUM = 2,
  LOW = 3
}

export enum CustomerAlertType {
  DocumentExpired = 0,
  DocumentExpiringSoon = 1,
  OverBudgetActual = 2,
  OverBudgetForecasted = 3,
  ServiceAgreementExpired = 4,
  ServiceAgreementExpiringSoon = 5
}

export enum CustomViewsModalType {
  MANAGE = 'MANAGE',
  DUPLICATE = 'DUPLICATE',
  DELETE = 'DELETE',
  CREATE = 'CREATE',
  SELECT_TEAM = 'SELECT_TEAM',
  SAVE_VIEW = 'SAVE_VIEW',
  SAVE_DEFAULT_VIEW = 'SAVE_DEFAULT_VIEW',
  SAVE_AS_NEW = 'SAVE_AS_NEW',
  SAVE_AS_NEW_COPY_FROM_OTHERS = 'SAVE_AS_NEW_COPY_FROM_OTHERS'
}

export enum CustomViewsViewCardType {
  DEFAULT = 'DEFAULT',
  CREATED_BY_ME = 'CREATED_BY_ME',
  CREATED_BY_OTHERS = 'CREATED_BY_OTHERS'
}

export enum CustomViewsType {
  PRIVATE = 1,
  TEAM = 2,
  EVERYONE = 3
}

export enum SupportWorkerAlertType {
  ComplianceDocumentExpired = 1,
  DocumentExpired = 2,
  ComplianceDocumentExpiringSoon = 3,
  DocumentExpiringSoon = 4
}

export enum CareInformationType {
  PERMANENT = 1,
  MEDICATION = 2,
  SUPPORTNEEDS = 3,
  BEHAVIOUR = 4,
  GENERAL = 5
}

export enum DebtorType {
  SELF = 1,
  EXTERNAL_PLAN = 2,
  INTERNAL_PLAN = 3,
  VCP = 4
}

export enum InvoiceStatus {
  PROCESSED = 'PROCESSED',
  PAID = 'PAID'
}

export enum ArchiveDebtorFrom {
  LISTING = 'LISTING',
  DETAILS_PAGE = 'DETAILS_PAGE'
}

export enum UnavailabilityType {
  ONE_OFF = 1,
  CUSTOM = 2
}

export enum CustomUnavailabilityOption {
  UnavailableAllDay = 0,
  AvailableAllDay = 1,
  UnavailableSpecificHours = 2
}

export enum TimeBasedAlertConfigType {
  DOCUMENT_EXPIRY = 'DOCUMENT_EXPIRY',
  SERVICE_AGREEMENT_EXPIRY = 'SERVICE_AGREEMENT_EXPIRY'
}
