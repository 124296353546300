import React, { useRef, useState } from 'react';

import { Icon } from 'antd';
import { useMouseHovered } from 'react-use';
import { Popover } from '@blueprintjs/core';

import { EndSpacer } from 'common-components/roster-control/roster-daily/timeline-pane/common/EndSpacer';
import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';
import { PORTAL_COLORS } from 'interfaces/common-ui-types';

import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { IRosterCustomer } from 'common-components/roster-control/interfaces/roster-interfaces';

import moment from 'moment-timezone';

const { TIMELINE_ROW_HEIGHT } = ROSTER_DAILY_CONFIG;

interface TimelineRowProps {
  customer?: IRosterCustomer; // <- Not too happy with this, as it makes it less generic. to fix later.
  currentDate?: Date;
  items?: any;
  rowWidth: number;
  rowHeight?: number; // Row height can passed in to overwrite the default TIMELINE_ROW_HEIGHT
  backgroundColor?: PORTAL_COLORS;
  hasAddAction?: boolean;
  onAddBooking?: any;
}

export function TimelineRow({
  customer,
  currentDate,
  items,
  rowWidth,
  rowHeight = TIMELINE_ROW_HEIGHT,
  backgroundColor = 'white',
  hasAddAction = false,
  onAddBooking
}: TimelineRowProps) {
  const rowRef = useRef(null);

  // UI states
  const [isHovered, setIsHovered] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);

  // Clicked pos is a buffered position that's stored when the user clicks on the add button
  const [clickedPos, setClickedPos] = useState(0);

  const bgColor = `bg-${backgroundColor}`;
  // const rowWidth = HOUR_BLOCK_WIDTH * _.size(hours); // For later use if we decide to allow users to change the hours displayed

  // elX = position of mouse cursor within the div element.
  const { elX: currentX } = useMouseHovered(rowRef, {
    bound: true,
    whenHovered: true
  });

  // round down elX to nearest 60
  const roundedX = Math.floor(currentX / 60) * 60 + 4;

  // Offset for the button. If the dialog's open, use the buffered position to prevent it from jumping around.
  const offsetX = isDialogOpen ? clickedPos : roundedX;

  // Handle opening of popover
  const handleOpen = () => {
    setClickedPos(roundedX); // set as buffer
    setDialogOpen(true);
  };

  // Handle closing of popover
  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleAddBooking = () => {
    const minutes = (clickedPos - 4) / 2; // -4 to account for the offset

    // add minutes to current date
    const targetDate = moment(currentDate)
      .startOf('day')
      .add(minutes, 'minutes')
      .toDate();

    const { customerId } = customer;

    onAddBooking({ customerId, startDateTime: targetDate });
  };

  return (
    <div className="flex-row">
      <div
        className={`position-relative bordered-right bordered-bottom ${bgColor}`}
        style={{ height: rowHeight, minWidth: `${rowWidth}px`, maxWidth: `${rowWidth}px` }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        ref={rowRef}
      >
        {hasAddAction && (isHovered || isDialogOpen) && (
          <AddBookingPopover
            offsetX={offsetX}
            onOpen={handleOpen}
            onClose={handleClose}
            onAddBooking={handleAddBooking}
          />
        )}

        {/* -•=== timeline items ===•- */}
        {items}
      </div>

      <EndSpacer height={rowHeight} />
    </div>
  );
}

const AddBookingPopover = ({ offsetX, onOpen, onClose, onAddBooking }) => (
  <div className="position-absolute" style={{ top: 4, left: offsetX }}>
    <Popover
      content={
        <ActionMenu>
          <ActionMenuItem text={'Add new booking...'} onClick={onAddBooking} />
        </ActionMenu>
      }
      position={'bottom-left'}
      targetTagName={'span'}
      onOpening={onOpen}
      onClose={onClose}
    >
      <div
        className="bg-white hover-bg-quaternary p-x-small rounded bordered border-blue-action flex-row align-center justify-center cursor-pointer anim-fade-in-fast"
        style={{ width: '24px', height: '24px' }}
      >
        <Icon type="plus" style={{ fontSize: '13px' }} className="text-color-blue-action" />
      </div>
    </Popover>
  </div>
);
