import { Checkbox } from 'antd';
import Search from 'antd/es/input/Search';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { StatusTag } from 'common-components/tags';
import { Text } from 'common-components/typography';
import { IFilter } from 'interfaces/filter-interfaces';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import {
  BookingErrorType,
  BookingStatus,
  BookingType,
  CustomerAlertType,
  CustomerConnectionStatus,
  CustomerStatusType,
  FilterType,
  InvoiceStatus,
  PaymentMethods,
  PaymentMethodsLabel,
  PaymentSources,
  PaymentStatus,
  PlanManagementApprovalStatus,
  PlanManagementClaimStatus,
  PlanManagementPaymentStatus,
  ServiceStatus,
  ServiceStatusLabel,
  ServiceType,
  SessionStatus,
  SessionStatusLabel,
  ShiftSlotStatus,
} from 'utilities/enum-utils';
import { ndisHelper } from 'variables/data-helpers';
import hobbies from 'variables/data/hobbies.json';
import languages from 'variables/data/languages.json';
import qualifications from 'variables/data/qualifications.json';
import religions from 'variables/data/religion.json';
import skills from 'variables/data/skills.json';
import specialities from 'variables/data/specialities.json';

interface IFilterMenuCheckboxListProps {
  filter: IFilter;
  canRemove?: boolean;
  saveNewFilterValue: (filterType, newFilterValue, selectionLabel) => void;
  removeFilter: (filterType) => void;
  servicesLite: typeof state.servicesStore.servicesLite;
  customerNoteAuthors: typeof state.customersStore.customerNoteAuthors;
  customerNoteServices: typeof state.customersStore.customerNoteServices;
  serviceDepartmentListLite: typeof state.servicesStore.serviceDepartmentListLite;
  planManagementSuppliers: typeof state.planManagementStore.planManagementSuppliers;
  selectedCustomersForFilter: typeof state.customersStore.selectedCustomersForFilter;
  disabilityInfoLiteList: typeof state.customersStore.disabilityInfoLiteList;
  doFetchServicesLite: typeof dispatch.servicesStore.doFetchServicesLite;
  doFetchCustomerNoteAuthors: typeof dispatch.customersStore.doFetchCustomerNoteAuthors;
  doFetchCustomerNoteServices: typeof dispatch.customersStore.doFetchCustomerNoteServices;
  doFetchServiceDepartmentsLite: typeof dispatch.servicesStore.doFetchServiceDepartmentsLite;
  doFetchPlanManagementSuppliers: typeof dispatch.planManagementStore.doFetchPlanManagementSuppliers;
  doFetchdisabilityInfoLiteList: typeof dispatch.customersStore.doFetchDisabilityInfoLiteList;
}

interface IFilterMenuCheckboxListState {
  isLoading: boolean;
  isSearching: boolean;
  localItemList: any;
  localSelectedItemList: any;
}

class FilterMenuCheckboxList extends PureComponent<IFilterMenuCheckboxListProps, IFilterMenuCheckboxListState> {
  state = {
    isLoading: true,
    isSearching: false,
    localItemList: [],
    localSelectedItemList: _.clone(this.props.filter.values),
  };
  private _checkboxes: any[] = [];

  private static _getWorkerStatusLabel(status) {
    if (status === ShiftSlotStatus.PENDING) {
      return 'Awaiting confirmation';
    } else if (status === ShiftSlotStatus.CLAIMED) {
      return 'Claimed';
    } else if (status === ShiftSlotStatus.CONFIRMED) {
      return 'Worker confirmed';
    } else if (status === ShiftSlotStatus.UNASSIGNED) {
      return 'No worker assigned';
    } else if (status === ShiftSlotStatus.PUBLISHING) {
      return 'Published';
    } else if (status === ShiftSlotStatus.REJECTED) {
      return 'Rejected';
    }
  }

  private _getItemList(filterType) {
    switch (filterType) {
      case FilterType.SERVICE:
        return _.map(this.props.servicesLite, (service) => {
          return { displayText: service.serviceName, value: service.serviceId };
        });
      case FilterType.BOOKING_STATUS:
        return _.map(
          _.filter(BookingStatus, (status) => status !== BookingStatus.ARCHIVED),
          (status) => {
            return {
              displayText: <StatusTag status={status} size={'small'} />,
              searchString: status,
              value: status,
            };
          },
        );
      case FilterType.SHIFT_SLOT_STATUS:
        return _.map(
          _.filter(
            ShiftSlotStatus,
            (status) =>
              status !== ShiftSlotStatus.CLAIMED &&
              status !== ShiftSlotStatus.REJECTED &&
              status !== ShiftSlotStatus.CANCELLED &&
              status !== ShiftSlotStatus.PUBLISHING &&
              status !== ShiftSlotStatus.SENT_TO_FINANCE &&
              status !== ShiftSlotStatus.CANCELLED_UNPAID &&
              status !== ShiftSlotStatus.CANCELLED_PAID,
          ),
          (status) => {
            return {
              displayText: CommonUtils.formatStatusString(status),
              searchString: status,
              value: status,
            };
          },
        );
      case FilterType.SERVICE_STATUS:
        return _.map(ServiceStatus, (status) => {
          return {
            displayText: ServiceStatusLabel[status],
            searchString: status,
            value: status,
          };
        });
      case FilterType.SESSION_STATUS:
        return _.map(SessionStatus, (status) => {
          return {
            displayText: SessionStatusLabel[status],
            searchString: status,
            value: status,
          };
        });
      case FilterType.RECURRING:
        return [
          {
            displayText: 'Yes',
            value: 'YES',
          },
          {
            displayText: 'No',
            value: 'NO',
          },
        ];
      case FilterType.PAYMENT_STATUS:
        const paymentStatusList = [];
        _.forEach(PaymentStatus, (status) => {
          if (status !== PaymentStatus.BILLING_FAILED && status !== PaymentStatus.READY_FOR_BILLING) {
            paymentStatusList.push({
              displayText:
                status === PaymentStatus.INITIAL
                  ? 'Pending booking completion'
                  : status === PaymentStatus.SEND_TO_FINANCE
                  ? 'Sent to payments'
                  : status[0] + _.replace(status.slice(1), /_/g, ' ').toLowerCase(),
              value: status,
            });
          }
        });
        return paymentStatusList;
      case FilterType.IS_INCIDENT:
        return [
          {
            displayText: 'Yes',
            value: 'YES',
          },
          {
            displayText: 'No',
            value: 'NO',
          },
        ];
      case FilterType.SHOW_ARCHIVED_BOOKINGS:
        return [
          {
            displayText: 'Yes',
            value: 'YES',
          },
          {
            displayText: 'No',
            value: 'NO',
          },
        ];
      case FilterType.PINNED_ALERTS:
        return [
          {
            displayText: 'Yes',
            value: 'YES',
          },
          {
            displayText: 'No',
            value: 'NO',
          },
        ];
      case FilterType.SHIFT_SLOT_WARNINGS:
        return [
          {
            displayText: (
              <>
                Only show slots with <b>warnings</b>
              </>
            ),
            value: 'YES',
          },
        ];
      case FilterType.NOTE_TYPE:
        return [
          {
            displayText: 'General note',
            value: 'GENERAL',
          },
          {
            displayText: 'Booking note',
            value: 'BOOKING',
          },
        ];
      case FilterType.NOTE_AUTHOR:
        return _.map(this.props.customerNoteAuthors, (author) => {
          return { displayText: author.firstName + ' ' + author.lastName, value: author.userId };
        });
      case FilterType.NOTE_SERVICE:
        return _.map(this.props.customerNoteServices, (service) => {
          return { displayText: service.serviceName, value: service.serviceId };
        });
      case FilterType.NDIS_CATEGORIES:
        return _.map(ndisHelper.getAllCategories(), (service) => {
          return {
            displayText: service.supportCategoryNumber + ' - ' + service.supportCategory,
            value: service.supportCategoryNumber,
          };
        });
      case FilterType.SERVICE_TYPE:
        return [
          {
            displayText: 'Support services',
            value: ServiceType.INDIVIDUAL,
          },
          {
            displayText: 'Group services',
            value: ServiceType.GROUP,
          },
        ];
      case FilterType.SERVICE_DEPARTMENT:
        return _.map(this.props.serviceDepartmentListLite, (department) => {
          return { displayText: department.serviceDepartmentName, value: department.serviceDepartmentId };
        });
      case FilterType.PROVIDER:
        return _.map(this.props.planManagementSuppliers, (provider) => {
          return { displayText: provider.supplierName, value: provider.planManagementSupplierId };
        });
      case FilterType.SUPPLIER_PAYMENT_STATUS:
        const supplierPaymentList = [];
        _.forEach(PlanManagementPaymentStatus, (type) => {
          supplierPaymentList.push({
            displayText: type[0] + _.replace(type.slice(1), /_/g, ' ').toLowerCase(),
            value: type,
          });
        });
        return supplierPaymentList;
      case FilterType.CLAIM_STATUS:
        const claimStatusList = [];
        _.forEach(PlanManagementClaimStatus, (type) => {
          claimStatusList.push({
            displayText: type[0] + _.replace(type.slice(1), /_/g, ' ').toLowerCase(),
            value: type,
          });
        });
        return claimStatusList;
      case FilterType.APPROVAL_STATUS:
        const approvalStatusList = [];
        _.forEach(PlanManagementApprovalStatus, (type) => {
          approvalStatusList.push({
            displayText: type[0] + _.replace(type.slice(1), /_/g, ' ').toLowerCase(),
            value: type,
          });
        });
        return approvalStatusList;
      case FilterType.QUALIFICATIONS:
        const qualificationsList = [];
        _.forEach(qualifications, (type) => {
          qualificationsList.push({
            displayText: type.tag[0] + _.replace(type.tag.slice(1), /_/g, ' ').toLowerCase(),
            value: type.tagId,
          });
        });
        return qualificationsList;
      case FilterType.SPECIALITIES:
        const specialitiesList = [];
        _.forEach(specialities, (type) => {
          specialitiesList.push({
            displayText: type.tag[0] + _.replace(type.tag.slice(1), /_/g, ' ').toLowerCase(),
            value: type.tagId,
          });
        });
        return specialitiesList;
      case FilterType.RELIGIONS:
        const religionsList = [];
        _.forEach(religions, (type) => {
          religionsList.push({
            displayText: type.tag[0] + _.replace(type.tag.slice(1), /_/g, ' ').toLowerCase(),
            value: type.tagId,
          });
        });
        return religionsList;
      case FilterType.GENDER:
        return [
          {
            displayText: 'Female',
            value: 'Female',
          },
          {
            displayText: 'Male',
            value: 'Male',
          },
          {
            displayText: 'Other',
            value: 'Non-binary',
          },
          {
            displayText: 'Prefer not to say',
            value: 'Not specified',
          },
        ];
      case FilterType.SKILLS:
        const skillsList = [];
        _.forEach(skills, (type) => {
          skillsList.push({
            displayText: type.tag[0] + _.replace(type.tag.slice(1), /_/g, ' ').toLowerCase(),
            value: type.tagId,
          });
        });
        return skillsList;
      case FilterType.LANGUAGES:
        const languagesList = [];
        _.forEach(languages, (type) => {
          languagesList.push({
            displayText: type.tag[0] + _.replace(type.tag.slice(1), /_/g, ' ').toLowerCase(),
            value: type.tagId,
          });
        });
        return languagesList;
      case FilterType.INTEREST:
        const interestsList = [];
        _.forEach(hobbies, (type) => {
          interestsList.push({
            displayText: type.tag[0] + _.replace(type.tag.slice(1), /_/g, ' ').toLowerCase(),
            value: type.tagId,
          });
        });
        return interestsList;
      case FilterType.BOOKING_ERROR_TYPES:
        return [
          { displayText: 'Payment method error', value: BookingErrorType.PaymentMethodError },
          { displayText: 'Funding package warning', value: BookingErrorType.FundingPackageWarning },
          { displayText: 'Shift time variation warning', value: BookingErrorType.BookingRecordedTimeWarning },
          {
            displayText: 'Service not part of service agreement warning',
            value: BookingErrorType.ServiceAgreementWarning,
          },
          {
            displayText: 'Line item not part of service agreement warning',
            value: BookingErrorType.ServiceAgreementLineItemWarning,
          },
        ];
      case FilterType.PAYMENT_SOURCES:
        return [
          { displayText: 'NDIS', value: PaymentSources.NDIS },
          { displayText: 'VCP', value: PaymentSources.VCP },
        ];
      case FilterType.PAYMENT_METHODS:
        const paymentMethods = [];
        _.forEach(PaymentMethods, (type) => {
          paymentMethods.push({
            displayText: PaymentMethodsLabel[type],
            value: type,
          });
        });
        return paymentMethods;
      case FilterType.CUSTOM_CUSTOMER:
        const customCustomers = [];
        _.forEach(this.props.selectedCustomersForFilter, (customer) => {
          customCustomers.push({
            displayText: `${customer.firstName} ${customer.lastName}`,
            value: customer.userId,
          });
        });
        return customCustomers;
      case FilterType.PERMANENT_CONDITIONS:
        return _.map(this.props.disabilityInfoLiteList, (disability) => {
          return { displayText: disability.disabilityName, value: disability.tagId };
        });
      case FilterType.CUSTOMER_ALERTS:
        return [
          { displayText: 'Documents Expired', value: CustomerAlertType.DocumentExpired },
          { displayText: 'Documents Expiring', value: CustomerAlertType.DocumentExpiringSoon },
          { displayText: 'Service agreements quote amount exceeded', value: CustomerAlertType.OverBudgetActual },
          {
            displayText: 'Service agreements quote amount exceeded (Forecast)',
            value: CustomerAlertType.OverBudgetForecasted
          },
          { displayText: 'No active service agreement', value: CustomerAlertType.ServiceAgreementExpired },
          { displayText: 'Service agreement expiring soon', value: CustomerAlertType.ServiceAgreementExpiringSoon }
        ];
      case FilterType.USER_LOCATION_BY_STATE:
        return [
          { displayText: 'Australian Capital Territory', value: 'ACT' },
          { displayText: 'New South Wales', value: 'NSW' },
          { displayText: 'Victoria', value: 'VIC' },
          { displayText: 'Queensland', value: 'QLD' },
          { displayText: 'Northern Territory', value: 'NT' },
          { displayText: 'South Australia', value: 'SA' },
          { displayText: 'Western Australia', value: 'WA' },
          { displayText: 'Tasmania', value: 'TAS' },
        ];
      case FilterType.CUSTOMER_MANAGED_BY:
        return [
          { displayText: 'Legal guardian', value: 'YES' },
          { displayText: 'Independently managed', value: 'NO' },
        ];
      case FilterType.CUSTOMER_CONNECTION_STATUS:
        const connectionStatus = [];
        _.forEach(CustomerConnectionStatus, (type) => {
          connectionStatus.push({
            displayText: type[0] + _.replace(type.slice(1), /_/g, ' ').toLowerCase(),
            value: type,
          });
        });
        return connectionStatus;
      case FilterType.MANAGEMENT_METHOD:
        const managementMethods = [];
        _.forEach(PaymentMethods, (type) => {
          managementMethods.push({
            displayText: PaymentMethodsLabel[type],
            value: type,
          });
        });
        return managementMethods;
      case FilterType.CUSTOMER_STATUS:
        const customerStatus = [];
        _.forEach(CustomerStatusType, (type) => {
          customerStatus.push({
            displayText: type[0] + _.replace(type.slice(1), /_/g, ' ').toLowerCase(),
            value: type,
          });
        });
        return customerStatus;
      case FilterType.BOOKING_TYPES:
        return [
          { displayText: 'Show bookings', value: BookingType.BOOKING },
          { displayText: 'Show activity records', value: BookingType.ACTIVITY_RECORD },
        ];
      case FilterType.WORKER_ALERTS:
        return [
          { displayText: 'Compliance document(s) expired', value: 1 },
          { displayText: 'Document(s) expired', value: 2 },
          { displayText: 'Compliance document(s) expiring', value: 3 },
          { displayText: 'Document(s) expiring', value: 4 },
        ];
      case FilterType.INVOICE_STATUS:
        return [
          { displayText: 'Paid', value: InvoiceStatus.PAID },
          { displayText: 'Processed', value: InvoiceStatus.PROCESSED },
        ];
      default:
        return;
    }
  }

  private _addSelection = (isChecked, item) => {
    const { localSelectedItemList } = this.state;

    let newFilter = [];
    if (isChecked) {
      if (!_.isEmpty(localSelectedItemList)) {
        newFilter = localSelectedItemList;
        newFilter.push(item);
      } else {
        newFilter = [item];
      }
    } else {
      newFilter = _.filter(localSelectedItemList, (filter) => filter !== item);
    }
    this.setState({ localSelectedItemList: [...newFilter] });
  };

  private _selectAllAction = () => {
    this.setState({
      localSelectedItemList: _.map(this.state.localItemList, (filterItem) => filterItem.value),
    });
  };

  private _clearSelectionAction = () => {
    this.setState({
      localSelectedItemList: [],
    });
  };

  private _closePopover = () => {
    this.setState({
      isSearching: false,
      localSelectedItemList: [],
    });
  };

  private _searchText = async (txt) => {
    const targetStr = txt
      .toLowerCase()
      .split(' ')
      .filter(function(el) {
        return el.length !== 0;
      });

    const newLocalItemList =
      targetStr.length > 0
        ? _.map(this.state.localItemList, (item) => {
            const displayTextArray = item.searchString
              ? item.searchString.toLowerCase().split(/ |_/)
              : item.displayText.toLowerCase().split(/ |_/);
            let matchingRelevance = _.intersection(displayTextArray, targetStr);
            if (
              _.some(targetStr, (string) =>
                item.searchString
                  ? item.searchString.toLowerCase().indexOf(string) >= 0
                  : item.displayText.toLowerCase().indexOf(string) >= 0,
              )
            ) {
              return { ...item, isHidden: false, matchingRelevance: matchingRelevance.length };
            } else {
              return { ...item, isHidden: true };
            }
          })
        : _.map(this.state.localItemList, (item) => {
            return { ...item, isHidden: false };
          });

    this.setState({ localItemList: newLocalItemList, isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _applySelection = () => {
    this.props.saveNewFilterValue(
      this.props.filter.filter,
      this.state.localSelectedItemList,
      CommonUtils.getFilterText(this.props.filter.filter, this.state.localSelectedItemList, this.state.localItemList),
    );
  };

  private _setRef = (ref) => {
    if (ref) {
      this._checkboxes.push(ref);
    }
  };

  componentDidMount = async () => {
    if (this.props.filter.filter === FilterType.SERVICE) {
      await this.props.doFetchServicesLite({});
    }
    if (this.props.filter.filter === FilterType.NOTE_AUTHOR) {
      await this.props.doFetchCustomerNoteAuthors({});
    }
    if (this.props.filter.filter === FilterType.NOTE_SERVICE) {
      await this.props.doFetchCustomerNoteServices({});
    }
    if (this.props.filter.filter === FilterType.SERVICE_DEPARTMENT) {
      await this.props.doFetchServiceDepartmentsLite({});
    }
    if (this.props.filter.filter === FilterType.PROVIDER) {
      await this.props.doFetchPlanManagementSuppliers({});
    }
    if (this.props.filter.filter === FilterType.PERMANENT_CONDITIONS) {
      await this.props.doFetchdisabilityInfoLiteList({});
    }
    this.setState({ localItemList: await this._getItemList(this.props.filter.filter), isLoading: false });
  };

  render() {
    const { localItemList } = this.state;
    const filterSettings = CommonUtils.getFilterSettings(this.props.filter.filter);
    const filteredList = _.filter(localItemList, (lineItem) => !lineItem.isHidden);

    return (
      <div style={{ width: '450px' }}>
        <div className={'pt-small ph-medium'}>
          <Text size={'x-large'} weight={'bold'}>
            {filterSettings.title}
          </Text>
        </div>
        <div className="pv-small ph-medium bordered-bottom border-standard-gray">
          {filterSettings.canSearch && (
            <Search
              placeholder={filterSettings.searchPlaceHolder}
              onChange={this._onEnterSearchText}
              loading={this.state.isSearching}
              allowClear={true}
            />
          )}
          <div className={'flex-row justify-between pt-small'}>
            <HyperlinkButton color={'blue-action'} onClick={this._selectAllAction}>
              Select all
            </HyperlinkButton>
            <HyperlinkButton color={'blue-action'} onClick={this._clearSelectionAction}>
              Clear selection
            </HyperlinkButton>
          </div>
        </div>
        <div style={{ maxHeight: '200px', overflow: 'auto' }} className={' bordered-bottom border-standard-gray'}>
          {this.state.isLoading ? (
            <SpinningLoader size={25} message={''} />
          ) : filteredList && filteredList.length > 0 ? (
            _.map(localItemList, (item, key) => (
              <div
                className={`pv-x-small ph-medium hover-bg-tertiary cursor-pointer ${item.isHidden ? 'hide' : ''}`}
                key={key}
                onMouseEnter={() => this._checkboxes[key] && this._checkboxes[key].focus()}
                onMouseLeave={() => this._checkboxes[key] && this._checkboxes[key].blur()}
                onClick={() => this._addSelection(!this._checkboxes[key].props.checked, item.value)}
              >
                <Checkbox
                  checked={
                    _.find(this.state.localSelectedItemList, (savedFilter) => savedFilter === item.value) !== undefined
                  }
                  ref={(c) => this._setRef(c)}
                  className={'mr-small'}
                />
                {item.displayText}
              </div>
            ))
          ) : (
            <div className={'pv-x-small ph-medium'}>No {filterSettings.title} found</div>
          )}
        </div>
        <div className="p-medium">
          <div className={'flex-row justify-between'}>
            <div>
              {this.props.canRemove && (
                <GhostButton color={'red'} onClick={() => this.props.removeFilter(this.props.filter.filter)}>
                  Remove filter
                </GhostButton>
              )}
            </div>
            <div>
              <SecondaryButton
                color={'blue-action'}
                className={'mr-medium bp3-popover-dismiss'}
                onClick={this._closePopover}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                color={'blue-action'}
                className={'bp3-popover-dismiss border-blue-action'}
                onClick={this._applySelection}
              >
                Apply
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  servicesLite: state.servicesStore.servicesLite,
  customerNoteAuthors: state.customersStore.customerNoteAuthors,
  customerNoteServices: state.customersStore.customerNoteServices,
  serviceDepartmentListLite: state.servicesStore.serviceDepartmentListLite,
  selectedCustomersForFilter: state.customersStore.selectedCustomersForFilter,
  planManagementSuppliers: state.planManagementStore.planManagementSuppliers,
  disabilityInfoLiteList: state.customersStore.disabilityInfoLiteList,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServicesLite: dispatch.servicesStore.doFetchServicesLite,
  doFetchCustomerNoteAuthors: dispatch.customersStore.doFetchCustomerNoteAuthors,
  doFetchCustomerNoteServices: dispatch.customersStore.doFetchCustomerNoteServices,
  doFetchServiceDepartmentsLite: dispatch.servicesStore.doFetchServiceDepartmentsLite,
  doFetchPlanManagementSuppliers: dispatch.planManagementStore.doFetchPlanManagementSuppliers,
  doFetchdisabilityInfoLiteList: dispatch.customersStore.doFetchDisabilityInfoLiteList,
});

export default connect(mapState, mapDispatch)(FilterMenuCheckboxList);
