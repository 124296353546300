import { Avatar, Checkbox, Col, Icon, InputNumber, notification, Radio, Row } from 'antd';
import { Information } from 'common-components/alerts';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { StatusTag } from 'common-components/tags';
import TimeInput from 'common-components/time-input/TimeInput';
import { TimezoneIndicator } from 'common-components/timezone';
import { FieldLabel, Paragraph, Text } from 'common-components/typography';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { EditRecurringMode, RecurringBookingPattern, ScheduleType } from 'utilities/enum-utils';
import AvailabilityConflictWorker from 'views/team/details/tabs-panel/availability/components/AvailabilityConflictWorker';

interface ISessionEditScheduledTimesModalProps {
  isOpen: any;
  onClose: any;
  selectedSession: typeof state.groupServiceStore.selectedSession;
  customerScheduleArchiveConflicts: typeof state.groupBookingsStore.customerScheduleArchiveConflicts;
  doUpdateSessionDates: typeof dispatch.groupServiceStore.doUpdateSessionDates;
  doFetchBookingsForSession: typeof dispatch.groupServiceStore.doFetchBookingsForSession;
  doFetchGroupBookingCustomerScheduleArchiveCheck: typeof dispatch.groupBookingsStore.doFetchGroupBookingCustomerScheduleArchiveCheck;
}

interface IEditBookingDatesActionModalState {
  title: any;
  isLoading: boolean;
  isSuccess: boolean;
  isRecurringOptions: boolean;
  isWorkerConflictList: boolean;
  startDateTime: Moment;
  endDateTime: Moment;
  isErrorEndDate: boolean;
  selectedOption: number;
  numberOfSession: number;
  isVerifying: boolean;
  modalWidth: string;
  numberOfBookingError: boolean;
  workerConflicts: any;
  isUpdateShiftSlot: boolean;
  isDisplayCustomerScheduleArchiveConflictModal: boolean;
  showUnavailableShift: boolean;
  showConflictShifts: string[];
  removedMembers: IRemoveShift[];
  showOutsideAvailabilityBooking: boolean;
  showOutsideAvailabilityWorkers: boolean;
  outsideAvailabilityConflicts: any;
  selectedShiftsToKeep: any[];
}

interface IRemoveShift {
  supportWorkerId: string;
  firstName: string;
  lastName: string;
  attachmentUrl: string;
  removeDate: string;
  shifts: any[];
}

class SessionEditScheduledTimesModal extends Component<
  ISessionEditScheduledTimesModalProps,
  IEditBookingDatesActionModalState
> {
  state = {
    title: 'Edit scheduled time of session',
    isLoading: false,
    isSuccess: false,
    isRecurringOptions: false,
    isWorkerConflictList: false,
    startDateTime: null,
    endDateTime: null,
    isErrorEndDate: false,
    selectedOption: EditRecurringMode.Current,
    numberOfSession: 1,
    isVerifying: false,
    cancelAllConflicts: false,
    errorConfirmConflicts: false,
    modalWidth: 'medium',
    numberOfBookingError: false,
    workerConflicts: [],
    isUpdateShiftSlot: false,
    isDisplayCustomerScheduleArchiveConflictModal: false,
    showUnavailableShift: false,
    showConflictShifts: [],
    removedMembers: [],
    showOutsideAvailabilityBooking: false,
    showOutsideAvailabilityWorkers: false,
    outsideAvailabilityConflicts: null,
    selectedShiftsToKeep: []
  };

  private _resetModal = () => {
    const { selectedSession } = this.props;
    this.setState({
      title: 'Edit scheduled time of session',
      isLoading: false,
      isSuccess: false,
      isRecurringOptions: false,
      isWorkerConflictList: false,
      isErrorEndDate: false,
      selectedOption: EditRecurringMode.Current,
      numberOfSession: 1,
      isVerifying: false,
      workerConflicts: [],
      isUpdateShiftSlot: false,
      isDisplayCustomerScheduleArchiveConflictModal: false,
      showUnavailableShift: false,
      showConflictShifts: [],
      modalWidth: 'medium',
      showOutsideAvailabilityBooking: false,
      showOutsideAvailabilityWorkers: false,
      outsideAvailabilityConflicts: [],
      selectedShiftsToKeep: []
    });
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this._resetModal();
      onClose();
    }
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _onChangeNumberOfSession = (event) => {
    this.setState({ numberOfSession: event });
  };

  private _displayRecurringOptions = () => {
    this.setState({ isRecurringOptions: true });
  };

  private _onWorkerConflictContinue = async () => {
    this.setState({
      isWorkerConflictList: false,
      title: 'Edit scheduled time of session',
    });
    await this._onSubmitEdit();
  };

  private _submitOutsideConflict = async (isRemove) => {
    this._onSubmitEdit(isRemove);
  };

  private _onChangeShiftsToKeep = (shifts, worker) => {
    this.setState({
      selectedShiftsToKeep: { ...this.state.selectedShiftsToKeep, [worker.supportWorkerId]: shifts }
    });
  };

  private _getShiftsSelectedToBeKept = (isRemoveTeamMember) => {
    const { selectedShiftsToKeep } = this.state;
    let shiftsToBeKept = [];

    const arrayList = Object.values(selectedShiftsToKeep);
    shiftsToBeKept = _.flatten(arrayList);

    return !isRemoveTeamMember ? shiftsToBeKept : [];
  };

  private _onSubmitEdit = async (isRemoveTeamMember?: boolean) => {
    const { selectedSession, doUpdateSessionDates } = this.props;
    const {
      startDateTime,
      endDateTime,
      selectedOption,
      numberOfSession,
      workerConflicts,
      isUpdateShiftSlot,
      outsideAvailabilityConflicts
    } = this.state;
    this.setState({ isLoading: true });
    try {
      const result: any = await doUpdateSessionDates(
        selectedSession.scheduleType === ScheduleType.SCHEDULE
          ? {
              serviceId: selectedSession.serviceId,
              serviceScheduleId: selectedSession.serviceScheduleId,
              serviceDateTimeId: selectedSession.serviceDateTimeId,
              scheduleTimeSlotId: selectedSession.scheduleTimeSlotId,
              startDateTime: moment.tz(moment(startDateTime).format('YYYY-MM-DD HH:mm'), selectedSession.timezone),
              endDateTime: moment.tz(moment(endDateTime).format('YYYY-MM-DD HH:mm'), selectedSession.timezone),
              recurringPattern: selectedSession.recurringPattern
                ? selectedSession.recurringPattern
                : RecurringBookingPattern.EveryWeek,
              isUpdateShiftSlot,
              recurringNext:
                this.state.selectedOption === 1
                  ? 0
                  : selectedOption === 2
                  ? selectedSession.upcomingSession
                  : numberOfSession,
              conflictWorkers: workerConflicts,
              shiftsSelectedToBeKept: !_.isEmpty(outsideAvailabilityConflicts)
                ? this._getShiftsSelectedToBeKept(isRemoveTeamMember)
                : []
            }
          : {
              serviceId: selectedSession.serviceId,
              serviceScheduleId: selectedSession.serviceScheduleId,
              serviceDateTimeId: selectedSession.serviceDateTimeId,
              scheduleTimeSlotId: selectedSession.scheduleTimeSlotId,
              startDateTime: moment.tz(moment(startDateTime).format('YYYY-MM-DD HH:mm'), selectedSession.timezone),
              endDateTime: moment.tz(moment(endDateTime).format('YYYY-MM-DD HH:mm'), selectedSession.timezone),
              isUpdateShiftSlot,
              recurringNext: 0,
              conflictWorkers: workerConflicts,
              shiftsSelectedToBeKept: !_.isEmpty(outsideAvailabilityConflicts)
                ? this._getShiftsSelectedToBeKept(isRemoveTeamMember)
                : []
            }
      );
      if (result && !_.isEmpty(result.workerConflicts)) {
        this.setState({
          isDisplayCustomerScheduleArchiveConflictModal: false,
          isLoading: false,
          isVerifying: false,
          workerConflicts: result.workerConflicts,
          modalWidth: 'x-large',
          isWorkerConflictList: true,
          title: 'Team member conflicts',
        });
      } else {
        this.setState({
          isLoading: false,
          isSuccess: true,
          isVerifying: false,
          isWorkerConflictList: false,
          isRecurringOptions: false,
          isDisplayCustomerScheduleArchiveConflictModal: false,
          title: 'Service time successfully changed',
        });
      }
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
      this.setState({ isLoading: false, isSuccess: false });
    }
  };

  private _onChangeStartDate = async (date) => {
    let startDateTime = moment(CommonUtils.formatCeilingDateTime(date));
    const dateDifference = startDateTime.diff(this.state.startDateTime, 'days');

    this.setState({
      startDateTime,
      endDateTime: this.state.endDateTime.add(dateDifference, 'days'),
      isErrorEndDate: false,
    });
  };

  private _onChangeEndDate = async (date) => {
    const { startDateTime } = this.state;

    let isErrorEndDate = false;

    let endDateTime = moment(CommonUtils.formatCeilingDateTime(date));

    if (endDateTime <= startDateTime) {
      endDateTime = moment(startDateTime).add(1, 'hour');
      isErrorEndDate = true;
    }

    this.setState({
      endDateTime,
      isErrorEndDate: isErrorEndDate,
    });
  };

  private _getMaxDate = (dateType) => {
    const { selectedSession } = this.props;

    const savedStartDate = selectedSession.startDateTime;
    const savedEndDate = this.state.endDateTime;
    const { recurringPattern } = selectedSession;

    if (recurringPattern === RecurringBookingPattern.EveryWeek) {
      return moment(dateType === 'startDate' ? savedStartDate : savedEndDate)
        .add(6, 'days')
        .toDate();
    } else if (recurringPattern === RecurringBookingPattern.EveryFortnight) {
      return moment(dateType === 'startDate' ? savedStartDate : savedEndDate)
        .add(13, 'days')
        .toDate();
    } else if (recurringPattern === RecurringBookingPattern.EveryThreeWeeks) {
      return moment(dateType === 'startDate' ? savedStartDate : savedEndDate)
        .add(19, 'days')
        .toDate();
    } else if (recurringPattern === RecurringBookingPattern.EveryFourWeeks) {
      return moment(dateType === 'startDate' ? savedStartDate : savedEndDate)
        .add(27, 'days')
        .toDate();
    } else {
      return;
    }
  };

  private _getMinDate = (dateType) => {
    const { selectedSession } = this.props;

    const savedStartDate = selectedSession.startDateTime;
    const savedEndDate = selectedSession.endDateTime;
    const { recurringPattern } = selectedSession;

    if (dateType === 'endDate') {
      return moment(this.state.startDateTime).toDate();
    } else if (recurringPattern === RecurringBookingPattern.EveryWeek) {
      return moment(dateType === 'startDate' ? savedStartDate : savedEndDate)
        .add(-6, 'days')
        .toDate();
    } else if (recurringPattern === RecurringBookingPattern.EveryFortnight) {
      return moment(dateType === 'startDate' ? savedStartDate : savedEndDate)
        .add(-13, 'days')
        .toDate();
    } else if (recurringPattern === RecurringBookingPattern.EveryThreeWeeks) {
      return moment(dateType === 'startDate' ? savedStartDate : savedEndDate)
        .add(-19, 'days')
        .toDate();
    } else if (recurringPattern === RecurringBookingPattern.EveryFourWeeks) {
      return moment(dateType === 'startDate' ? savedStartDate : savedEndDate)
        .add(-27, 'days')
        .toDate();
    } else {
      return;
    }
  };

  private _getInformationPatternText = () => {
    const { selectedSession } = this.props;
    switch (selectedSession && selectedSession.recurringPattern) {
      case RecurringBookingPattern.EveryWeek:
        return (
          <>
            This session is part of a <b>weekly</b> recurring schedule and dates can only be changed within a week of
            the booking.
          </>
        );
      case RecurringBookingPattern.EveryFortnight:
        return (
          <>
            This session is part of a <b>once every two weeks</b> recurring schedule and dates can only be changed
            within two weeks of the booking.
          </>
        );
      case RecurringBookingPattern.EveryThreeWeeks:
        return (
          <>
            This session is part of a <b>once every three weeks</b> recurring schedule and dates can only be changed
            within three weeks of the booking.
          </>
        );
      case RecurringBookingPattern.EveryFourWeeks:
        return (
          <>
            This session is part of a <b>once every four weeks</b> recurring schedule and dates can only be changed
            within four weeks of the booking.
          </>
        );

      case RecurringBookingPattern.EveryDay:
        return (
          <>
            This session is part of a <b>daily</b> recurring schedule. The start date and finish date must be on the
            same day.
          </>
        );
    }
  };

  private _customerScheduleArchiveCheck = async () => {
    const { doFetchGroupBookingCustomerScheduleArchiveCheck, selectedSession } = this.props;
    const { startDateTime, endDateTime, selectedOption, numberOfSession, isUpdateShiftSlot } = this.state;
    this.setState({ isLoading: true });
    try {
      const result: any = await doFetchGroupBookingCustomerScheduleArchiveCheck({
        startDateTime: moment.tz(moment(startDateTime).format('YYYY-MM-DD HH:mm'), selectedSession.timezone),
        endDateTime: moment.tz(moment(endDateTime).format('YYYY-MM-DD HH:mm'), selectedSession.timezone),
        recurringPattern:
          selectedSession.scheduleType === ScheduleType.ONE_OFF ? null : selectedSession.recurringPattern,
        scheduleTimeSlotId: selectedSession.scheduleTimeSlotId,
        serviceDateTimeId: selectedSession.serviceDateTimeId,
        serviceId: selectedSession.serviceId,
        serviceScheduleId: selectedSession.serviceScheduleId,
        recurringNext:
          selectedOption === EditRecurringMode.Current
            ? 0
            : selectedOption === EditRecurringMode.CurrentAll
            ? selectedSession.upcomingSession
            : numberOfSession,
        isUpdateShiftSlot,
      });
      this.setState({
        isLoading: false,
        modalWidth: _.isEmpty(result) ? 'medium' : 'x-large',
      });
      return result;
    } catch (e) {
      notification.error({ message: 'Oops something went wrong! Please try again' });
      this.setState({ isLoading: false });
    }
  };

  private _showConflictShifts = (index) => {
    const currentList = this.state.showConflictShifts;
    if (_.includes(currentList, index)) {
      const newList = _.filter(currentList, (item) => item !== index);
      this.setState({ showConflictShifts: newList });
    } else {
      const newList = [...currentList, index];
      this.setState({ showConflictShifts: newList });
    }
  };

  private _onEditScheduleTime = async () => {
    await this._customerScheduleArchiveCheck();
    const emptyConflictWhenUpdateShiftSlot =
      this.props.customerScheduleArchiveConflicts &&
      (_.isEmpty(this.props.customerScheduleArchiveConflicts.shiftSlots) &&
        _.isEmpty(this.props.customerScheduleArchiveConflicts.archiveConflicts) &&
        _.isEmpty(this.props.customerScheduleArchiveConflicts.workerOutsideAvailability));
    if (_.isEmpty(this.props.customerScheduleArchiveConflicts) || emptyConflictWhenUpdateShiftSlot) {
      this._onSubmitEdit();
    } else {
      // Team member scheduled removal
      if (
        this.props.customerScheduleArchiveConflicts.shiftSlots &&
        !_.isEmpty(this.props.customerScheduleArchiveConflicts.shiftSlots)
      ) {
        const removedMembers = this._removeShiftList(this.props.customerScheduleArchiveConflicts.shiftSlots);
        this.setState({
          showUnavailableShift: true,
          title: 'Team member unavailable for shifts',
          modalWidth: 'x-large',
          removedMembers,
        });
      } else if (
        this.props.customerScheduleArchiveConflicts.archiveConflicts &&
        !_.isEmpty(this.props.customerScheduleArchiveConflicts.archiveConflicts)
      ) {
        this.setState({
          isDisplayCustomerScheduleArchiveConflictModal: true,
          title: 'Customers unable to attend new session time',
          modalWidth: 'x-large'
        });
      }
      // Team member outside availability
      else if (
        this.props.customerScheduleArchiveConflicts.workerOutsideAvailability &&
        !_.isEmpty(this.props.customerScheduleArchiveConflicts.workerOutsideAvailability)
      ) {
        this.setState({
          isVerifying: false,
          showOutsideAvailabilityWorkers: true,
          title: 'Team member unavailable for shifts',
          outsideAvailabilityConflicts: this.props.customerScheduleArchiveConflicts.workerOutsideAvailability,
          isLoading: false,
          isRecurringOptions: false,
          modalWidth: 'x-large'
        });
      }
    }
  };

  private _removeShiftList = (list): Array<IRemoveShift> => {
    const mappedList = {};
    list.forEach((shift) => {
      if (mappedList[shift.supportWorkerId]) {
        const currentShifts = mappedList[shift.supportWorkerId].shifts;
        mappedList[shift.supportWorkerId].shifts = [
          ...currentShifts,
          { startDateTime: shift.shiftStartDateTime, endDateTime: shift.shiftEndDateTime },
        ];
      } else {
        mappedList[shift.supportWorkerId] = {
          supportWorkerId: shift.supportWorkerId,
          firstName: shift.firstName,
          lastName: shift.lastName,
          attachmentUrl: shift.attachmentUrl,
          removeDate: shift.removedOn,
          shifts: [{ startDateTime: shift.shiftStartDateTime, endDateTime: shift.shiftEndDateTime }],
        };
      }
    });
    return Object.values(mappedList);
  };

  componentDidMount() {
    const { selectedSession } = this.props;
    this.setState({
      isSuccess: false,
      isLoading: false,
      startDateTime: moment(
        moment.tz(selectedSession.startDateTime, selectedSession.timezone).format('YYYY-MM-DD HH:mm'),
      ),
      endDateTime: moment(moment.tz(selectedSession.endDateTime, selectedSession.timezone).format('YYYY-MM-DD HH:mm')),
    });
  }

  componentDidUpdate(
    prevProps: Readonly<ISessionEditScheduledTimesModalProps>,
    prevState: Readonly<IEditBookingDatesActionModalState>,
    snapshot?: any,
  ) {
    if (prevProps.selectedSession !== this.props.selectedSession) {
      const { selectedSession } = this.props;
      this.setState({
        startDateTime: moment(
          moment.tz(selectedSession.startDateTime, selectedSession.timezone).format('YYYY-MM-DD HH:mm'),
        ),
        endDateTime: moment(
          moment.tz(selectedSession.endDateTime, selectedSession.timezone).format('YYYY-MM-DD HH:mm'),
        ),
      });
    }
  }

  render() {
    const { isOpen, selectedSession, customerScheduleArchiveConflicts } = this.props;
    const { startDateTime, endDateTime, workerConflicts, isUpdateShiftSlot } = this.state;
    const informationPatternText = this._getInformationPatternText();
    const scheduledArchiveConflicts = this.state.isUpdateShiftSlot
      ? customerScheduleArchiveConflicts
        ? customerScheduleArchiveConflicts.archiveConflicts
        : []
      : customerScheduleArchiveConflicts;

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width={this.state.modalWidth}
        canCloseOutside={!this.state.isLoading}
        verticalAlignment="highest"
      >
        {!this.state.isLoading &&
          !this.state.isSuccess &&
          !this.state.isRecurringOptions &&
          !this.state.isDisplayCustomerScheduleArchiveConflictModal &&
          !this.state.isWorkerConflictList &&
          !this.state.showUnavailableShift &&
          !this.state.showOutsideAvailabilityWorkers &&
          !this.state.showOutsideAvailabilityBooking && (
            <div className="text-align-left mt-small">
              {selectedSession.scheduleType === ScheduleType.SCHEDULE && (
                <Information content={informationPatternText} />
              )}

              <div className="mt-medium mb-x-large">
                <Row type="flex" align="middle" gutter={24}>
                  <Col span={6}>
                    <Text weight={'bold'}>Start Date</Text>
                  </Col>
                  <Col span={7}>
                    {/* <div className="flex-row align-center justify-center mb-x-large"> */}
                    <DatePicker
                      className="gh-datepicker rounded"
                      calendarClassName="gh-datepicker-calendar"
                      dateFormat="d/M/yyyy"
                      selected={moment(startDateTime).toDate()}
                      maxDate={this._getMaxDate('startDate')}
                      minDate={this._getMinDate('startDate')}
                      onChange={this._onChangeStartDate}
                      disabled={selectedSession.recurringPattern === RecurringBookingPattern.EveryDay}
                    />
                  </Col>
                  <Col span={11}>
                    <TimeInput
                      size="large"
                      value={moment(this.state.startDateTime)}
                      onChange={this._onChangeStartDate}
                    />
                  </Col>
                </Row>
                {/* </div> */}
                {/* <div className="mb-medium">
              <Paragraph>Please select an End Date and Time</Paragraph>
            </div> */}
                <Row type="flex" align="middle" className="mt-medium" gutter={24}>
                  <Col span={6}>
                    <Text weight={'bold'}>Finish Date</Text>
                  </Col>
                  <Col span={7}>
                    <DatePicker
                      className="gh-datepicker rounded"
                      calendarClassName="gh-datepicker-calendar"
                      dateFormat="d/M/yyyy"
                      maxDate={this._getMaxDate('endDate')}
                      minDate={this._getMinDate('endDate')}
                      selected={moment(endDateTime).toDate()}
                      onChange={this._onChangeEndDate}
                      disabled={selectedSession.recurringPattern === RecurringBookingPattern.EveryDay}
                    />
                  </Col>
                  <Col span={11}>
                    <TimeInput size="large" value={moment(this.state.endDateTime)} onChange={this._onChangeEndDate} />
                  </Col>
                </Row>
                {this.state.isErrorEndDate && (
                  <Row>
                    <Col span={6}></Col>
                    <Col span={18}>
                      <div className="text-color-red mt-small ml-small">
                        <Icon type="exclamation-circle" className="mr-x-small" />
                        End time cannot be before Start time{' '}
                      </div>
                    </Col>
                  </Row>
                )}

                <div className="text-align-center mt-medium">
                  <Text size="small" color="secondary">
                    CURRENT TIMEZONE{' '}
                    <span className="ml-small">
                      <TimezoneIndicator
                        hasIcon={false}
                        showTzName={false}
                        bordered={false}
                        timezone={selectedSession.timezone}
                      />
                    </span>
                  </Text>
                </div>
              </div>

              <div className="mb-medium">
                <Checkbox
                  checked={isUpdateShiftSlot}
                  onChange={() => this.setState({ isUpdateShiftSlot: !isUpdateShiftSlot })}
                >
                  Update team member shifts based on new session times
                </Checkbox>
              </div>
            </div>
          )}
        {this.state.isLoading && <SpinningLoader size={100} message={'Loading'} />}
        {!this.state.isLoading && this.state.isSuccess && (
          <div className="">
            <div className="mb-large">
              <Paragraph>
                You have successfully changed the time of this session
                {this.state.selectedOption === EditRecurringMode.CurrentAll && ' and all following sessions'}
                {this.state.selectedOption === EditRecurringMode.CurrentNext &&
                  ` and the next ${this.state.numberOfSession} session${this.state.numberOfSession !== 1 ? 's' : ''}`}
                .
              </Paragraph>
            </div>
          </div>
        )}
        {!this.state.isLoading && this.state.isRecurringOptions && !this.state.showUnavailableShift && (
          <>
            <div>
              <div className="mb-small">
                <Text weight="bold">Edit time for:</Text>
              </div>
              <Radio.Group value={this.state.selectedOption} onChange={this._onChangeOption} className="ml-medium">
                <Radio
                  value={EditRecurringMode.Current}
                  className={`${this.state.selectedOption === EditRecurringMode.Current &&
                    'text-weight-bold'} mb-small `}
                >
                  <div className="ml-medium inline-box inline-flex" style={{ whiteSpace: 'normal' }}>
                    This session only.
                  </div>
                </Radio>
                <br />
                <Radio
                  value={EditRecurringMode.CurrentAll}
                  className={`${this.state.selectedOption === EditRecurringMode.CurrentAll &&
                    'text-weight-bold'} mb-small `}
                >
                  <div className="ml-medium inline-box inline-flex" style={{ whiteSpace: 'normal' }}>
                    This session and all following bookings.
                  </div>
                </Radio>
                <br />
                <Radio
                  value={EditRecurringMode.CurrentNext}
                  className={`${this.state.selectedOption === EditRecurringMode.CurrentNext &&
                    'text-weight-bold'} mb-small `}
                >
                  <div
                    className="ml-medium inline-box inline-flex align-center"
                    style={{ whiteSpace: 'normal' }}
                    color={this.state.numberOfBookingError ? 'red' : null}
                  >
                    This session and the next{' '}
                    <InputNumber
                      className="mh-x-small"
                      style={{ width: '50px' }}
                      min={1}
                      max={selectedSession.upcomingSession}
                      value={this.state.numberOfSession}
                      onChange={this._onChangeNumberOfSession}
                    />{' '}
                    session
                    {this.state.numberOfSession !== 1 && 's'}.
                  </div>
                  <br />
                  {this.state.numberOfBookingError && (
                    <>
                      <Text size={'regular'} color="red" weight="regular" className="ml-x2-large">
                        Please select a number between 1 - {selectedSession.upcomingSession}
                      </Text>
                      <br />
                    </>
                  )}
                </Radio>
              </Radio.Group>
              <br />
              <Text color="secondary" weight="regular">
                <i>
                  There {selectedSession.upcomingSession !== 1 ? 'are' : 'is'} {selectedSession.upcomingSession}{' '}
                  upcoming sessions in this schedule
                </i>
              </Text>
            </div>
            <div className={'mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  loading={this.state.isVerifying}
                  onClick={async () => {
                    await this._customerScheduleArchiveCheck();
                    if (
                      _.isEmpty(this.props.customerScheduleArchiveConflicts) ||
                      (_.isEmpty(this.props.customerScheduleArchiveConflicts.shiftSlots) &&
                        _.isEmpty(this.props.customerScheduleArchiveConflicts.workerOutsideAvailability))
                    ) {
                      this._onSubmitEdit();
                    } else {
                      this._onEditScheduleTime();
                    }
                  }}
                >
                  Continue
                </PrimaryButton>
              </Row>
            </div>
          </>
        )}

        {!this.state.isSuccess &&
          !this.state.isRecurringOptions &&
          !this.state.isWorkerConflictList &&
          !this.state.isDisplayCustomerScheduleArchiveConflictModal &&
          !this.state.showUnavailableShift &&
          !this.state.showOutsideAvailabilityBooking &&
          !this.state.showOutsideAvailabilityWorkers && (
            <ActionModalFooter>
              <SecondaryButton
                size="large"
                onClick={this._onCloseModal}
                className="mr-medium"
                disabled={this.state.isLoading}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                size="large"
                onClick={async () => {
                  if (selectedSession.scheduleType === ScheduleType.SCHEDULE) {
                    if (selectedSession.upcomingSession > 0) {
                      this._displayRecurringOptions();
                    } else {
                      this._onEditScheduleTime();
                    }
                  } else {
                    this.setState({
                      isVerifying: false,
                      isRecurringOptions: false,
                    });
                    this._onEditScheduleTime();
                  }
                }}
                loading={this.state.isLoading}
              >
                Next
              </PrimaryButton>
            </ActionModalFooter>
          )}
        {!_.isEmpty(scheduledArchiveConflicts) &&
          this.state.isDisplayCustomerScheduleArchiveConflictModal &&
          !this.state.isLoading && (
            <>
              <Paragraph>
                As a result of the changes being made, the following customers are unable to attend the session as they
                are scheduled to be archived on or before the new session date.
              </Paragraph>
              <Paragraph>The customers listed below will be removed from the session.</Paragraph>
              {selectedSession.scheduleType === ScheduleType.SCHEDULE && (
                <>
                  <FieldLabel text="NEW SESSION TIME" />
                  {moment
                    .tz(startDateTime, selectedSession.timezone)
                    .isSame(moment.tz(endDateTime, selectedSession.timezone), 'day') ? (
                    <Text>
                      {moment.tz(startDateTime, selectedSession.timezone).format('DD/MM/YYYY')},{' '}
                      {moment.tz(startDateTime, selectedSession.timezone).format('LT')} -{' '}
                      {moment.tz(endDateTime, selectedSession.timezone).format('LT')}
                    </Text>
                  ) : (
                    <Text>
                      {moment.tz(startDateTime, selectedSession.timezone).format('DD/MM/YYYY LT')} -{' '}
                      {moment.tz(endDateTime, selectedSession.timezone).format('DD/MM/YYYY LT')}
                    </Text>
                  )}
                </>
              )}

              <Row className="mt-x-large">
                <Col span={8}>
                  <FieldLabel text="CUSTOMER" />
                </Col>
                <Col span={8}>
                  <FieldLabel
                    text={
                      selectedSession.scheduleType === ScheduleType.SCHEDULE ? 'NEW SESSION TIME' : 'BOOKING STATUS'
                    }
                  />
                </Col>
                <Col span={8}>
                  <FieldLabel text="SCHEDULED ARCHIVE DATE" />
                </Col>
              </Row>
              {_.map(scheduledArchiveConflicts, (conflict) => {
                return (
                  <Row className="mv-x2-large">
                    <div className="flex align-center">
                      <Col span={8}>
                        <div className="align-center">
                          <Avatar className="mr-medium" src={conflict.customerAvatarUrl} />{' '}
                          <Text weight="bold">
                            {conflict.firstName} {conflict.lastName}
                          </Text>
                        </div>
                      </Col>
                      <Col span={8}>
                        {selectedSession.scheduleType === ScheduleType.SCHEDULE ? (
                          <Text>
                            {moment
                              .tz(conflict.selectedSessionDateTime.startDateTime, selectedSession.timezone)
                              .format('DD MMMM YYYY')}
                            <br />
                            {moment
                              .tz(conflict.selectedSessionDateTime.startDateTime, selectedSession.timezone)
                              .format('LT')}{' '}
                            -{' '}
                            {moment
                              .tz(conflict.selectedSessionDateTime.endDateTime, selectedSession.timezone)
                              .format('LT')}
                          </Text>
                        ) : (
                          <StatusTag status={conflict.bookingStatus} />
                        )}
                      </Col>
                      <Col span={8}>
                        <Text>{moment.tz(conflict.archiveDate, selectedSession.timezone).format('DD MMMM YYYY')}</Text>
                      </Col>
                    </div>
                  </Row>
                );
              })}

              <div className={'mt-large'}>
                <Row type={'flex'} justify={'end'}>
                  <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size="large" loading={this.state.isVerifying} onClick={this._onSubmitEdit}>
                    Continue
                  </PrimaryButton>
                </Row>
              </div>
            </>
          )}
        {this.state.isSuccess && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Ok
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {this.state.isWorkerConflictList && (
          <>
            <div className="anim-slide-left">
              <Text>
                As a result of the changes being made, Team members assigned to this session are no longer available due
                to schedule conflicts. If you choose to continue, the worker will be removed form the following
                sessions.
              </Text>
              <Row className="text-weight-bold bordered-bottom border-standard-gray p-small mt-large">
                <Col span={8}>Team member</Col>
                <Col span={8}>New session date</Col>
                <Col span={8}>Conflicting session/booking</Col>
              </Row>
              <div
                style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '35vh' }}
                className="bordered-bottom border-standard-gray"
              >
                {(!workerConflicts || workerConflicts.length === 0) && (
                  <Row className="pv-medium">
                    <Text>No conflicts found.</Text>
                  </Row>
                )}
                {_.map(workerConflicts, (conflict) => (
                  <Row type="flex" align="middle" className="p-small border-secondary evenodd">
                    <Col
                      span={8}
                      title={conflict.shiftSlotStatus}
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      <Text>
                        <Avatar className="mr-medium" size={'small'} src={conflict.attachmentUrl} />{' '}
                        {conflict.firstName} {conflict.lastName}
                      </Text>
                    </Col>
                    <Col span={8}>
                      {moment
                        .tz(conflict.newStartDateTime, selectedSession.timezone)
                        .startOf('day')
                        .isSame(moment.tz(conflict.newEndDateTime, selectedSession.timezone).startOf('day')) ? (
                        <text>
                          <b>{moment.tz(conflict.newStartDateTime, selectedSession.timezone).format('DD MMMM YYYY')}</b>
                          <br />
                          <Text>
                            {moment.tz(conflict.newStartDateTime, selectedSession.timezone).format('hh:mm a')} -{' '}
                            {moment.tz(conflict.newEndDateTime, selectedSession.timezone).format('hh:mm a')}
                          </Text>
                        </text>
                      ) : (
                        <text>
                          <b>{moment.tz(conflict.newStartDateTime, selectedSession.timezone).format('DD MMMM YYYY')}</b>{' '}
                          {moment.tz(conflict.newStartDateTime, selectedSession.timezone).format('hh:mm a')}
                          <br />
                          <b>
                            {moment.tz(conflict.newEndDateTime, selectedSession.timezone).format('DD MMMM YYYY')}
                          </b>{' '}
                          {moment.tz(conflict.newEndDateTime, selectedSession.timezone).format('hh:mm a')}
                        </text>
                      )}
                    </Col>
                    <Col span={8}>
                      <Text color={'blue-action'}>{conflict.bookingNumber}</Text>
                      {moment
                        .tz(conflict.startDateTime, selectedSession.timezone)
                        .startOf('day')
                        .isSame(moment.tz(conflict.endDateTime, selectedSession.timezone).startOf('day')) ? (
                        <text>
                          <b>{moment.tz(conflict.startDateTime, selectedSession.timezone).format('DD MMMM YYYY')}</b>
                          <br />
                          <Text>
                            {moment.tz(conflict.startDateTime, selectedSession.timezone).format('hh:mm a')} -{' '}
                            {moment.tz(conflict.endDateTime, selectedSession.timezone).format('hh:mm a')}
                          </Text>
                        </text>
                      ) : (
                        <text>
                          <b>{moment.tz(conflict.startDateTime, selectedSession.timezone).format('DD MMMM YYYY')}</b>{' '}
                          {moment.tz(conflict.startDateTime, selectedSession.timezone).format('hh:mm a')}
                          <br />
                          <b>{moment.tz(conflict.endDateTime, selectedSession.timezone).format('DD MMMM YYYY')}</b>{' '}
                          {moment.tz(conflict.endDateTime, selectedSession.timezone).format('hh:mm a')}
                        </text>
                      )}
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
            <div className={'mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._onWorkerConflictContinue}>
                  Continue
                </PrimaryButton>
              </Row>
            </div>
          </>
        )}
        {this.state.showUnavailableShift && !this.state.isSuccess && (
          <>
            <Paragraph>
              As the result of change being made, some of the assigned team member are no longer available.
            </Paragraph>
            <Paragraph>
              The following team member{this.state.removedMembers.length > 1 ? 's' : ''} will be removed from the
              bookings/shift listed bellow.
            </Paragraph>
            {this.state.showUnavailableShift &&
              customerScheduleArchiveConflicts.shiftSlots.length > 0 &&
              _.map(this.state.removedMembers, (member, index) => {
                return (
                  <div
                    className="p-medium bordered border-standard-gray rounded-big bg-quaternary line-height-135 flex-column mt-small"
                    style={{ maxHeight: '300px', overflow: 'auto' }}
                    key={member.supportWorkerId}
                  >
                    <div className="flex-row">
                      <div className="bg-red-lightest text-color-red text-weight-bold">
                        TEAM MEMBER SCHEDULED TO LEAVE COMPANY
                      </div>
                      <div className="ml-large flex-row">
                        <Avatar icon="user" size={25} shape="square" src={member.attachmentUrl} />
                        <b className="ml-small">
                          <Text className="text-color-tertiary text-size-regular">
                            {`${member.firstName} ${member.lastName}`}
                          </Text>
                        </b>
                      </div>
                    </div>
                    <Paragraph>
                      <Text weight="bold">{`${member.firstName} ${member.lastName}`}</Text> is scheduled to leave the
                      company on {moment.tz(member.removeDate, selectedSession.timezone).format('Do of MMMM')} and will
                      be removed from the following{' '}
                      <Text weight="bold">
                        {member.shifts.length} shift{member.shifts.length > 1 ? 's' : ''}
                      </Text>
                    </Paragraph>
                    <HyperlinkButton onClick={() => this._showConflictShifts(index)}>
                      {_.includes(this.state.showConflictShifts, index) ? 'Hide shifts...' : 'Show shifts...'}
                    </HyperlinkButton>
                    {_.includes(this.state.showConflictShifts, index) && (
                      <div className="mt-large">
                        {_.map(member.shifts, (shift, index) => (
                          <Paragraph key={index}>
                            <Text weight="bold">
                              {moment.tz(shift.startDateTime, selectedSession.timezone).format('DD MMMM YYYY')}
                            </Text>
                            <Text className="ml-small">
                              {moment.tz(shift.startDateTime, selectedSession.timezone).format('hh:mm A')} -{' '}
                              {moment.tz(shift.endDateTime, selectedSession.timezone).format('hh:mm A')}
                            </Text>
                          </Paragraph>
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}

            <div className="mt-x3-large">
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton size="large" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  className="ml-small"
                  onClick={() => {
                    if (!_.isEmpty(this.state.outsideAvailabilityConflicts)) {
                      this.setState({
                        showUnavailableShift: false,
                        showOutsideAvailabilityWorkers: true
                      });
                    } else {
                      this._onSubmitEdit;
                    }
                  }}
                >
                  Continue
                </PrimaryButton>
              </Row>
            </div>
          </>
        )}

        {this.state.showOutsideAvailabilityWorkers && !this.state.isLoading && !this.state.isSuccess && (
          <>
            <Paragraph>
              The new session time conflicts with the team member’s <Text weight="bold">General availability</Text>.
              Please select which shifts (if any) you wish to keep for the team member or continue without selecting
              any.
            </Paragraph>

            <div className="bg-quaternary pv-x-large ph-12 mt-x-large">
              {_.map(this.state.outsideAvailabilityConflicts, (worker) => (
                <>
                  <AvailabilityConflictWorker
                    worker={worker}
                    timezone={selectedSession.timezone}
                    onSelectShifts={(shifts) => this._onChangeShiftsToKeep(shifts, worker)}
                    type="SHIFT"
                  />
                </>
              ))}
            </div>
            <div className="mt-x3-large flex justify-end">
              <SecondaryButton size="large" onClick={this._onCloseModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" className="ml-small" onClick={() => this._submitOutsideConflict(false)}>
                Continue and keep in selected shifts
              </PrimaryButton>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession,
  customerScheduleArchiveConflicts: state.groupBookingsStore.customerScheduleArchiveConflicts,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateSessionDates: dispatch.groupServiceStore.doUpdateSessionDates,
  doFetchBookingsForSession: dispatch.groupServiceStore.doFetchBookingsForSession,
  doFetchGroupBookingCustomerScheduleArchiveCheck:
    dispatch.groupBookingsStore.doFetchGroupBookingCustomerScheduleArchiveCheck,
});

export default connect(mapState, mapDispatch)(SessionEditScheduledTimesModal);
