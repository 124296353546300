import React, { Component } from 'react';

import { Col, Row, Tabs } from 'antd';

import { SubTitle } from 'common-components/typography';

import CenteredLayout from '../../layouts/CenteredLayout';
import { StyleLandingView } from './pages/StyleLandingView';
import { TypographyPageView } from './pages/TypographyPageView';
import { ColorPageView } from './page/ColorPageView';
import { GridSpacingPageView } from './page/pages/GridSpacingPageView';
// import { ButtonsPageView } from './pages/ButtonsPageView';
import { ButtonsPageView } from './pages/ButtonsPageView';
import { DialogPageView } from './page/DialogPageView';
import { InformationFeedbackPageView } from './pages/InformationFeedbackPageView';
import { TabNavigationPageView } from './pages/TabNavigationPageView';
import { FormElementPageView } from './pages/FormElementPageView';
import { ListsPageView } from './pages/ListsPageView';
import { IconPageView } from './IconPageView';
import { GraphicsPageView } from './pages/GraphicsPageView';
import { TransitionsPageView } from './pages/TransitionsPageView';
import { BookingStatusPageView } from './pages/BookingStatusPageView';

import NavMenuButton from 'common-components/navigation/NavMenuButton';

const { TabPane } = Tabs;

class StyleGuideMainView extends Component<any, any> {
  // state = { activeKey: 'landing' };
  state = { activeKey: 'filter-component' };

  _navigateTab = (tabKey) => {
    this.setState({ activeKey: tabKey });
  };

  render() {
    const { activeKey } = this.state;

    return (
      <CenteredLayout backgroundColor="white" bordered topPadded={true}>
        <Row>
          <Col span={6}>
            {/*  */}
            <div className="pr-x7-large">
              <div className="mb-x-large">
                <NavMenuButton
                  text={'Home'}
                  icon={'home'}
                  onClick={this._navigateTab}
                  targetTab={'landing'}
                  currentTab={activeKey}
                />
              </div>

              <div className="mb-x-large">
                <SubTitle containerClassName="mb-small">Common Styles</SubTitle>
                <NavMenuButton
                  text={'Color'}
                  icon={'sketch'}
                  onClick={this._navigateTab}
                  targetTab="color"
                  currentTab={activeKey}
                />
                <NavMenuButton
                  text={'Grid Spacing'}
                  icon={'sketch'}
                  onClick={this._navigateTab}
                  targetTab="grid-spacing"
                  currentTab={activeKey}
                />
                <NavMenuButton
                  text={'Typography'}
                  icon={'sketch'}
                  onClick={this._navigateTab}
                  targetTab="typography"
                  currentTab={activeKey}
                />
              </div>

              <div className="mb-x-large">
                {/*<SideMenuNavHeader text="Common Components" />*/}
                <SubTitle containerClassName="mb-small">Common Components</SubTitle>
                <NavMenuButton
                  text={'Buttons'}
                  icon={'bulb'}
                  onClick={this._navigateTab}
                  targetTab="button"
                  currentTab={activeKey}
                />
                <NavMenuButton
                  text={'Dialogs'}
                  icon={'bulb'}
                  onClick={this._navigateTab}
                  targetTab="dialog"
                  currentTab={activeKey}
                />
                <NavMenuButton
                  text={'Information Feedback'}
                  icon={'bulb'}
                  onClick={this._navigateTab}
                  targetTab="information-feedback"
                  currentTab={activeKey}
                />
                <NavMenuButton
                  text={'Tab Navigations'}
                  icon={'bulb'}
                  onClick={this._navigateTab}
                  targetTab="tab-navigation"
                  currentTab={activeKey}
                />
                <NavMenuButton
                  text={'Lists'}
                  icon={'bulb'}
                  onClick={this._navigateTab}
                  targetTab="list"
                  currentTab={activeKey}
                />
                <NavMenuButton
                  text={'Form Elements'}
                  icon={'bulb'}
                  onClick={this._navigateTab}
                  targetTab="form-element"
                  currentTab={activeKey}
                />
                <NavMenuButton
                  text={'Icons'}
                  icon={'bulb'}
                  onClick={this._navigateTab}
                  targetTab="icon"
                  currentTab={activeKey}
                />
                <NavMenuButton
                  text={'Graphics'}
                  icon={'bulb'}
                  onClick={this._navigateTab}
                  targetTab="graphic"
                  currentTab={activeKey}
                />
                <NavMenuButton
                  text={'Transitions'}
                  icon={'bulb'}
                  onClick={this._navigateTab}
                  targetTab="transition"
                  currentTab={activeKey}
                />
              </div>

              <div className="mb-x-large">
                <SubTitle containerClassName="mb-small">App Components</SubTitle>
                <NavMenuButton
                  text={'Booking Statuses'}
                  icon={'calendar'}
                  onClick={this._navigateTab}
                  targetTab="booking-status"
                  currentTab={activeKey}
                />
                <NavMenuButton
                  text={'Filters View'}
                  icon={'calendar'}
                  onClick={this._navigateTab}
                  targetTab="filter-component"
                  currentTab={activeKey}
                />
              </div>
            </div>
          </Col>
          <Col span={18}>
            <Tabs renderTabBar={() => <div />} activeKey={activeKey}>
              <TabPane tab="landing" key="landing">
                <StyleLandingView navigateTab={this._navigateTab} />
              </TabPane>
              <TabPane key="typography" tab="typography">
                <TypographyPageView />
              </TabPane>
              <TabPane key="color" tab="color">
                <ColorPageView />
              </TabPane>
              <TabPane key="grid-spacing" tab="grid-spacing">
                <GridSpacingPageView />
              </TabPane>

              <TabPane key="button" tab="button">
                <ButtonsPageView />
              </TabPane>

              <TabPane key="dialog" tab="dialog">
                <DialogPageView />
              </TabPane>

              <TabPane key="information-feedback" tab="information-feedback">
                <InformationFeedbackPageView />
              </TabPane>

              <TabPane key="tab-navigation" tab="tab-navigation">
                <TabNavigationPageView />
              </TabPane>

              <TabPane key="form-element" tab="form-element">
                <FormElementPageView />
              </TabPane>

              <TabPane key="list" tab="list">
                <ListsPageView />
              </TabPane>

              <TabPane key="icon" tab="icon">
                <IconPageView />
              </TabPane>

              <TabPane key="graphic" tab="graphic">
                <GraphicsPageView />
              </TabPane>

              <TabPane key="transition" tab="transition">
                <TransitionsPageView />
              </TabPane>

              <TabPane key="booking-status" tab="booking-status">
                <BookingStatusPageView />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </CenteredLayout>
    );
  }
}

export default StyleGuideMainView;
