import React, { PureComponent } from 'react';
import { Text } from 'common-components/typography';
import { PlanManagementPaymentStatus, PlanManagementPaymentStatusDisplay } from 'utilities/enum-utils';

interface ISupplierStatusTagProps {
  status: PlanManagementPaymentStatus;
}

export default class SupplierStatusTag extends PureComponent<ISupplierStatusTagProps, any> {
  TextPaymentMethodColors = {
    UNPAID: 'secondary',
    PAID: 'green',
    FAILED: 'red-dark'
  };

  render() {
    const { status } = this.props;

    const textColor = this.TextPaymentMethodColors[status];
    const textLabel = PlanManagementPaymentStatusDisplay[status];

    return <Text className={`text-color-${textColor}`}>{textLabel}</Text>;
  }
}
