import React, { Component } from 'react';
import { Checkbox, Col, Row } from 'antd';
import { GhostButton, IconButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import moment from 'moment-timezone';
import SessionStatusTag from 'common-components/tags/SessionStatusTag';
import { Popover } from '@blueprintjs/core';
import { IGroupServiceSession } from 'interfaces/service-interfaces';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import * as H from 'history';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { GridRow } from 'common-components/grids';
import { SessionStatus } from 'utilities/enum-utils';

interface ISessionListItemProps {
  sessionItem: IGroupServiceSession;
  // checkAllSessions: boolean;
  timezone: string;
  history: H.History;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  openCancelSessionModal: (IGroupServiceSession) => void;
  groupServiceSessions?: typeof state.servicesStore.groupServiceSessions;
  setGroupServiceSessions: typeof dispatch.servicesStore.setGroupServiceSessions;
  OnCheckSession: (session) => void;
  //handleItemClick: (id: string) => void;
  doFetchSingleSession: typeof dispatch.groupServiceStore.doFetchSingleSession;
}

class SessionListItem extends Component<ISessionListItemProps> {
  private _openCancelSession = (e) => {
    e.stopPropagation();
    e.preventDefault();
    this.props.openCancelSessionModal(this.props.sessionItem);
  };

  private _getPopoverContent = (session) => {
    return (
      <ActionMenu>
        <ActionMenuItem text={'View session'} onClick={() => this._goToSession(session.serviceDateTimeId)} />
        {session.sessionStatus === SessionStatus.SCHEDULED && (
          <ActionMenuItem text={'Cancel session'} onClick={this._openCancelSession} />
        )}
      </ActionMenu>
    );
  };

  private _onCheckSessionItem = () => {
    this.props.OnCheckSession(this.props.sessionItem);
    this.forceUpdate();
  };

  private _goToSession = async (serviceDateTimeId) => {
    this.props.history.push(
      `/group-service/${this.props.selectedGroupService.serviceId}/session/details/${serviceDateTimeId}`
    );
  };

  render() {
    const { sessionItem, timezone } = this.props;
    const dateString = moment.tz(sessionItem.startDateTime, timezone).format('ddd, D MMM YYYY');
    const startTimeString = moment.tz(sessionItem.startDateTime, timezone).format('h:mm A');
    const endTimeString = moment.tz(sessionItem.endDateTime, timezone).format('h:mm A');

    return (
      <Row className="align-center flex-row mt-medium" style={{ display: 'flex' }}>
        <Col span={1} className="pl-medium pv-medium">
          <Checkbox checked={sessionItem.isChecked} onChange={this._onCheckSessionItem} />
        </Col>

        <Col span={23} className={'ph-none'}>
          <div className="ml-small">
            <GridRow
              containerClassName={'bordered rounded-big ph-none'}
              onClick={() => this._goToSession(sessionItem.serviceDateTimeId)}
            >
              {/* <Col span={1}>
                <Checkbox checked={sessionItem.isChecked} onClick={this._onCheckSessionItem} />
              </Col> */}
              <Col span={5}>
                <Text>{dateString}</Text>
                <br />
                <Text color="secondary">
                  {startTimeString} - {endTimeString}
                </Text>
              </Col>
              <Col span={4}>
                <SessionStatusTag status={sessionItem.sessionStatus} />
              </Col>
              <Col span={4}>
                {sessionItem.capacity ? (
                  <div className="">
                    <div className="line-height-120">
                      <Text lineHeight={120}>
                        <b>
                          {sessionItem.bookedCapacity}/{sessionItem.capacity}
                        </b>{' '}
                        <span className="text-size-regular text-weight-light">customers</span>
                      </Text>
                    </div>
                  </div>
                ) : (
                  <Text color={'secondary'}>-</Text>
                )}
              </Col>
              <Col span={4}>
                {sessionItem.totalShiftSlot ? (
                  <div className="line-height-120">
                    <Text lineHeight={120}>
                      <b>{sessionItem.totalAssignedShiftSlot + '/' + sessionItem.totalShiftSlot}</b>
                      <span className="text-size-regular text-weight-light"> shifts</span>
                    </Text>
                  </div>
                ) : (
                  <Text color={'secondary'}>-</Text>
                )}
              </Col>
              <Col span={5}>
                <div className="line-height-120">
                  <Text lineHeight={120}>{sessionItem.scheduleName}</Text>
                </div>
              </Col>
              <Col span={2} className="text-align-right">
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <Popover
                    content={this._getPopoverContent(sessionItem)}
                    position={'bottom-right'}
                    interactionKind="click"
                  >
                    <div
                      className="bordered border-standard-gray rounded text-align-center"
                      style={{ width: '28px', height: '28px' }}
                    >
                      <GhostButton icon={'ellipsis'} size={'small'} paddingSize={'x-small'} />
                    </div>
                  </Popover>
                </div>
              </Col>
            </GridRow>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService,
  groupServiceSessions: state.servicesStore.groupServiceSessions
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setGroupServiceSessions: dispatch.servicesStore.setGroupServiceSessions,
  doFetchSingleSession: dispatch.groupServiceStore.doFetchSingleSession
});

export default connect(
  mapState,
  mapDispatch
)(SessionListItem);
