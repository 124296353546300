import React, { Component } from 'react';
import _ from 'lodash';
import { ndisHelper } from 'variables/data-helpers';
import { Col, Icon, Row } from 'antd';
import { SubTitle, Text } from 'common-components/typography';
import CommonUtils from 'utilities/common-utils';
import ViewQuotationQuoteItemView from './ViewQuotationQuoteItemView';

class ViewQuotationSubQuoteView extends Component<any, any> {
  state = {
    openedQuoteItem: []
  };

  private _handleQuoteItems = (index) => {
    const { openedQuoteItem } = this.state;
    if (!!_.find(this.state.openedQuoteItem, (item) => item === index)) {
      this.setState({ openedQuoteItem: _.filter(openedQuoteItem, (item) => item !== index) });
    } else {
      this.setState({ openedQuoteItem: [...openedQuoteItem, index] });
    }
  };

  render() {
    const { quotation } = this.props;
    const { openedQuoteItem } = this.state;
    return _.map(quotation.subQuotes, (subQuote, index) => {
      const isOpen = !!_.find(openedQuoteItem, (item) => item === index + 1);
      if (quotation.isManualQuote && !quotation.isManualTotal) {
        const subQuoteCategoryDetail = ndisHelper.getByCategoryNumber(subQuote.supportCategoryNumber);
        return (
          <div key={index} className={'p-medium bg-secondary'}>
            <div className={'rounded-big bg-white'}>
              <Row
                className={'pv-small bordered-bottom bordered-left bordered-right border-standard-gray'}
                type={'flex'}
                align={'middle'}
              >
                <Col span={1} />
                <Col span={8} className={'pl-medium'}>
                  <Text weight={'bold'}>{subQuoteCategoryDetail[0].SupportPurposeType}</Text>
                  <br />
                  <Text>{subQuoteCategoryDetail[0].SupportCategories}</Text>
                </Col>
                <Col span={4} className={'text-align-right'}>
                  <SubTitle>Quote Amount</SubTitle>
                  <Text weight={'bold'}>{CommonUtils.formatPrice(Number(subQuote.quoteAmount))}</Text>
                </Col>
                <Col span={4} className={'text-align-right'}>
                  <SubTitle>Amount spent</SubTitle>
                  <Text weight={'bold'}>{CommonUtils.formatPrice(Number(subQuote.spentAmount))}</Text>
                </Col>
                <Col span={4} className={'text-align-right'}>
                  <SubTitle>Balance</SubTitle>
                  <Text weight={'bold'}>
                    {CommonUtils.formatPrice(Number(subQuote.quoteAmount) - Number(subQuote.spentAmount))}
                  </Text>
                </Col>
                <Col span={3} />
              </Row>
            </div>
          </div>
        );
      } else {
        return (
          <div className={'p-medium bg-secondary'}>
            <div className={'rounded-big bg-white'}>
              <Row className={'pv-small bordered-bottom bordered-left bordered-right'} type={'flex'} align={'middle'}>
                <Col span={1}>
                  {subQuote.quoteItems && subQuote.quoteItems.length > 0 && (
                    <Icon
                      type={!isOpen ? 'right' : 'down'}
                      onClick={() => this._handleQuoteItems(index + 1)}
                      className={'ml-medium'}
                    />
                  )}
                </Col>
                <Col span={12} className={'pl-medium'}>
                  {subQuote.supportType && subQuote.supportType !== 'VCP' && (
                    <>
                      <Text weight={'bold'}>{subQuote.supportType}</Text>
                      <br />
                    </>
                  )}
                  <Text>{subQuote.subQuoteName}</Text>
                </Col>
                <Col span={8} className={'text-align-right'}>
                  <Text weight={'bold'}>{CommonUtils.formatPrice(subQuote.quoteAmount)}</Text>
                </Col>
                <Col span={3} />
              </Row>
              {isOpen &&
                _.map(subQuote.quoteItems, (quoteItem) => {
                  quoteItem.serviceType = quotation.serviceType;
                  return <ViewQuotationQuoteItemView quoteItem={quoteItem} />;
                })}
            </div>
          </div>
        );
      }
    });
  }
}

export default ViewQuotationSubQuoteView;
