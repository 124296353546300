/* eslint-disable */
import React, { PureComponent, ReactNode } from 'react';
import { Button, Icon } from 'antd';
import { PORTAL_COLORS } from '../../interfaces/common-ui-types';
import GhostButton from './GhostButton';

interface IFilterButtonProps {
  children?: ReactNode;
  color?: PORTAL_COLORS;
  loading?: boolean;
  disabled?: boolean;
  paddingSize?: 'none' | 'x-small' | 'small' | 'medium' | 'large' | 'x-large';
  className?: string;
  onClick: any;
}

class FilterButton extends PureComponent<IFilterButtonProps, any> {
  render() {
    const { color, loading, disabled, paddingSize = 'small', className = '', onClick } = this.props;
    let finalClassName = '';

    finalClassName += className;

    return (
      <GhostButton
        icon="filter"
        color={color}
        loading={loading}
        disabled={disabled}
        className={finalClassName}
        paddingSize={paddingSize}
        onClick={onClick}
      >
        Filter
      </GhostButton>
    );
  }
}

export default FilterButton;
