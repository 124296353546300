import React, { PureComponent } from 'react';
import _ from 'lodash';
import { Checkbox } from 'antd';
import { FieldItem } from './FieldItem';
import defaultFields from 'variables/data/defaultBookingListingFields.json';
import { timeZone } from 'interfaces/timezone-type';
import { ServiceType } from 'utilities/enum-utils';

interface IBookingItemRow {
  bookingItem?: any;
  timezone: timeZone;
  showTimezoneIndicator: boolean;
  customFilterFields?: any;
  checked?: boolean;
  onCheck?: (bookingId) => void;
  onClickRow?: (serviceType, data) => void;
  openRecurringListModal?: (e, bookingItem) => void;
  setFilter?: (filters) => void;
}

class BookingItemRow extends PureComponent<IBookingItemRow> {
  _checkbox = null;
  state = {
    bookingId: null
  };

  _onCheckCell = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { onCheck } = this.props;
    onCheck(this.state.bookingId);
  };

  _onClickRow = (serviceType, data) => {
    const { onClickRow } = this.props;
    onClickRow(serviceType, data);
  };

  componentDidMount = () => {
    const { bookingItem } = this.props;

    // just hook it to this state
    if (!_.isEmpty(bookingItem)) {
      const { bookingId } = bookingItem;
      this.setState({ bookingId });
    }
  };

  render() {
    const { bookingItem, customFilterFields, checked, setFilter } = this.props;

    // something went wrong; return a null row.
    if (_.isEmpty(bookingItem)) {
      return <></>;
    }

    const customFields = !_.isEmpty(customFilterFields) ? customFilterFields : defaultFields;

    return (
      <tr
        key={`booking-item-row-${bookingItem.id}`}
        onClick={() =>
          this._onClickRow(
            bookingItem.serviceType,
            bookingItem.serviceType === ServiceType.INDIVIDUAL
              ? { bookingId: bookingItem.bookingId }
              : {
                bookingId: bookingItem.bookingId,
                serviceId: bookingItem.serviceId,
                serviceDateTimeId: bookingItem.serviceDateTimeId
              }
          )
        }
        className={`${!checked && 'hover-bg-tertiary'} anim-fade-in ${checked ? 'bg-blue-lightest' : 'bg-white'
          } evenodd`}
      >
        {/* Checkbox */}
        {bookingItem.serviceType !== ServiceType.GROUP ? (
          <td
            className="fluid-cell check-all pr-none"
            onClick={this._onCheckCell}
            onMouseEnter={() => this._checkbox.focus()}
            onMouseLeave={() => this._checkbox.blur()}
          >
            <Checkbox checked={checked} ref={(c) => (this._checkbox = c)} />
          </td>
        ) : (
          <td className="fluid-cell check-all pr-none" />
        )}
        {!_.isEmpty(customFields) &&
          _.map(customFields, (fields, idx) => (
            <FieldItem
              bookingItem={bookingItem}
              timezone={this.props.timezone}
              showTimezoneIndicator={this.props.showTimezoneIndicator}
              fieldName={fields.field}
              openRecurringListModal={this.props.openRecurringListModal}
              setFilter={setFilter}
              key={idx}
            />
          ))}
      </tr>
    );
  }
}

export { BookingItemRow };
