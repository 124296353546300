import apiClient from 'utilities/api-client';
import {
  IPaymentInvoiceItem,
  IPMBatchDetailItem,
  IPMBatchListingItems,
  IPMCustomer,
  IPMInvoice,
  IPMInvoiceStats,
  IPMSupplier
} from 'interfaces/plan-management-interfaces';
import Axios, { AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import { PlanManagementClaimStatus } from 'utilities/enum-utils';

const fileDownload = require('js-file-download');
interface IPlanManagementState {
  planManagementCustomers: [IPMCustomer];
  planManagementCustomerFilters: any;
  planManagementInvoices: [IPMInvoice];
  planManagementInvoiceFilters: any;
  selectedPlanManagementInvoiceDetails: any;
  selectedRejectPlanManagementLineItems: any;
  planManagementSuppliers: [IPMSupplier];
  planManagementSupplierFilters: any;
  planManagementSupplierDetail: IPMSupplier;
  planManagementInvoiceStatistics: IPMInvoiceStats;
  selectedPlanPaymentFilterKey: any;
  planPaymentsFilter: any;
  // payment
  planManagementInvoiceList: [IPaymentInvoiceItem] | [];
  planManagementInvoiceListFilter: any;
  selectedItem: any;
  planManagementLineItemList: any;
  planManagementOverviewData: any;
  planManagementBatchListing: IPMBatchListingItems[];
  planManagementBatchFilter: any;
  planManagementBatchDetailFilter: any;
  planManagementBatchDetail: IPMBatchDetailItem;
  planManagementBatchInvoiceDetails: any;
}

const initialState: IPlanManagementState = {
  planManagementCustomers: null,
  planManagementCustomerFilters: [],
  planManagementInvoices: null,
  planManagementInvoiceFilters: [],
  planManagementSuppliers: null,
  planManagementSupplierFilters: [],
  planManagementSupplierDetail: null,
  selectedPlanManagementInvoiceDetails: null,
  planManagementInvoiceStatistics: null,
  selectedPlanPaymentFilterKey: [],
  planPaymentsFilter: [],
  planManagementInvoiceList: [],
  planManagementInvoiceListFilter: [],
  selectedItem: null,
  planManagementLineItemList: [],
  planManagementOverviewData: [],
  selectedRejectPlanManagementLineItems: [],
  planManagementBatchListing: [],
  planManagementBatchFilter: [],
  planManagementBatchDetailFilter: [],
  planManagementBatchDetail: null,
  planManagementBatchInvoiceDetails: []
};

const planManagementStore = {
  state: initialState,
  reducers: {
    setPlanManagementCustomerFilters: (state, payload) => ({ ...state, planManagementCustomerFilters: payload }),
    setPlanManagementInvoices: (state, payload) => ({ ...state, planManagementInvoices: payload }),
    setPlanManagementInvoiceFilters: (state, payload) => ({ ...state, planManagementInvoiceFilters: payload }),
    setPlanManagementSuppliers: (state, payload) => ({ ...state, planManagementSuppliers: payload }),
    setSelectedPlanManagementInvoiceDetails: (state, payload) => ({
      ...state,
      selectedPlanManagementInvoiceDetails: payload
    }),
    setPlanManagementCustomers: (state, payload) => ({ ...state, planManagementCustomers: payload }),
    setPlanManagementSupplierFilters: (state, payload) => ({ ...state, planManagementSupplierFilters: payload }),
    setPlanManagementSupplierDetail: (state, payload) => ({ ...state, planManagementSupplierDetail: payload }),
    setPlanManagementInvoiceStatistics: (state, payload) => ({ ...state, planManagementInvoiceStatistics: payload }),
    setSelectedPlanPaymentFilterKey: (state, payload) => ({ ...state, selectedPlanPaymentFilterKey: payload }),
    editPlanManagementSupplier: (state, payload) => {
      return {
        ...state,
        planManagementSupplierDetail: { ...state.planManagementSuppliers, ...payload }
      };
    },
    setNewPlanManagementInvoiceDocumentRoute: (state, payload) => {
      if (state.selectedPlanManagementInvoiceDetails.planManagementInvoiceId === payload.planManagementInvoiceId) {
        const newPlanManageInvoiceDetails = _.cloneDeep(state.selectedPlanManagementInvoiceDetails);
        newPlanManageInvoiceDetails.documentUrl = payload.documentUrl;
        newPlanManageInvoiceDetails.uniqueId = payload.uniqueId;
        newPlanManageInvoiceDetails.documentStatus = payload.status;
        newPlanManageInvoiceDetails.documentId = payload.documentId;

        return { ...state, selectedPlanManagementInvoiceDetails: newPlanManageInvoiceDetails };
      }
      return { ...state };
    },
    addLineItemToInvoice(state, payload) {
      const InvoiceDetails = _.clone(state.selectedPlanManagementInvoiceDetails);
      const newLineItems = InvoiceDetails.lineItems;
      newLineItems.unshift(payload.invoiceLineItem);
      return {
        ...state,
        claimStatus: payload.claimStatus,
        selectedPlanManagementInvoiceDetails: { ...InvoiceDetails, lineItems: newLineItems }
      };
    },
    editLineItemToInvoice(state, payload) {
      const invoiceDetails = _.clone(state.selectedPlanManagementInvoiceDetails);
      return {
        ...state,
        selectedPlanManagementInvoiceDetails: {
          ...invoiceDetails,
          claimStatus: payload.claimStatus,
          lineItems: _.map(invoiceDetails.lineItems, (lineItem) => {
            if (payload.invoiceLineItem.planManagementLineItemId === lineItem.planManagementLineItemId) {
              return { ...payload.invoiceLineItem };
            } else {
              return { ...lineItem };
            }
          })
        }
      };
    },
    deleteLineItemToInvoice(state, payload) {
      const invoiceDetails = _.clone(state.selectedPlanManagementInvoiceDetails);
      return {
        ...state,
        selectedPlanManagementInvoiceDetails: {
          ...invoiceDetails,
          claimStatus: payload.claimStatus,
          lineItems: _.filter(
            invoiceDetails.lineItems,
            (lineItem) => payload.planManagementLineItemId !== lineItem.planManagementLineItemId
          )
        }
      };
    },
    sendInvoiceLineItemToPayment(state, payload) {
      const invoiceDetails = _.clone(state.selectedPlanManagementInvoiceDetails);
      return {
        ...state,
        selectedPlanManagementInvoiceDetails: {
          ...invoiceDetails,
          planManagementAuditLog: payload.planManagementAuditLog,
          claimStatus: PlanManagementClaimStatus.SENT_TO_PAYMENTS,
          lineItems: _.map(invoiceDetails.lineItems, (lineItem) => {
            if (lineItem.claimStatus !== PlanManagementClaimStatus.WAIVED) {
              return { ...lineItem, claimStatus: PlanManagementClaimStatus.SENT_TO_PAYMENTS };
            } else {
              return { ...lineItem };
            }
          })
        }
      };
    },
    revokeInvoice(state, payload) {
      const invoiceDetails = _.clone(state.selectedPlanManagementInvoiceDetails);
      return {
        ...state,
        selectedPlanManagementInvoiceDetails: {
          ...invoiceDetails,
          planManagementAuditLog: payload.planManagementAuditLog,
          claimStatus: payload.claimStatus,
          lineItems: _.map(invoiceDetails.lineItems, (lineItem) => {
            if (lineItem.claimStatus === PlanManagementClaimStatus.SENT_TO_PAYMENTS) {
              return { ...lineItem, claimStatus: PlanManagementClaimStatus.UNCLAIMED };
            } else {
              return { ...lineItem };
            }
          })
        }
      };
    },
    //payments
    setPlanManagementInvoiceList: (state, payload) => ({ ...state, planManagementInvoiceList: payload }),
    setPlanManagementInvoiceListFilter: (state, payload) => ({ ...state, planManagementInvoiceListFilter: payload }),
    setSelectedItem: (state, payload) => ({ ...state, selectedItem: payload }),
    setPlanManagementOverviewData: (state, payload) => ({ ...state, planManagementOverviewData: payload }),
    addInPlanManagementLineItemList(state, payload) {
      const planManagementLineItemList = _.clone(state.planManagementLineItemList);
      if (
        _.isEmpty(planManagementLineItemList) ||
        !_.find(
          planManagementLineItemList,
          (savedLineItem) => savedLineItem.planManagementInvoiceId === payload.planManagementInvoiceId
        )
      ) {
        planManagementLineItemList.push(payload);
        return {
          ...state,
          planManagementLineItemList
        };
      } else {
        return {
          ...state,
          planManagementLineItemList: _.map(planManagementLineItemList, (savedLineItem) => {
            if (savedLineItem.planManagementInvoiceId === payload.planManagementInvoiceId) {
              return { ...payload };
            } else {
              return { ...savedLineItem };
            }
          })
        };
      }
    },
    setSelectedRejectPlanManagementLineItems: (state, payload) => ({
      ...state,
      selectedRejectPlanManagementLineItems: payload
    }),
    setPlanManagementBatchListing(state, payload) {
      return { ...state, planManagementBatchListing: payload };
    },
    setPlanManagementBatchListingFilter(state, payload) {
      return { ...state, planManagementBatchFilter: payload };
    },
    setPlanManagementBatchDetailFilter(state, payload) {
      return { ...state, planManagementBatchDetailFilter: payload };
    },
    setPlanManagementBatchDetail(state, payload) {
      return { ...state, planManagementBatchDetail: payload };
    },
    setPlanManagementBatchInvoiceDetails(state, payload) {
      const planManagementBatchInvoiceDetails = _.clone(state.planManagementBatchInvoiceDetails);
      if (
        _.isEmpty(planManagementBatchInvoiceDetails) ||
        !_.find(
          planManagementBatchInvoiceDetails,
          (savedLineItem) => savedLineItem.planManagementInvoiceId === payload.planManagementInvoiceId
        )
      ) {
        planManagementBatchInvoiceDetails.push(payload);
        return {
          ...state,
          planManagementBatchInvoiceDetails: planManagementBatchInvoiceDetails
        };
      } else {
        return {
          ...state,
          planManagementBatchInvoiceDetails: _.map(planManagementBatchInvoiceDetails, (savedLineItem) => {
            if (savedLineItem.planManagementInvoiceId === payload.planManagementInvoiceId) {
              return { ...payload };
            } else {
              return { ...savedLineItem };
            }
          })
        };
      }
    }
  },
  effects: (dispatch) => ({
    async doFetchPlanManagementCustomers(payload, rootState) {
      try {
        const response = await apiClient.post(`/api/portal/plan-management/customers/list`, payload);
        let planManagementCustomers = response.data;
        if (payload.page > 1) {
          planManagementCustomers = rootState.planManagementStore.planManagementCustomers.concat(
            planManagementCustomers
          );
        }
        dispatch.planManagementStore.setPlanManagementCustomers(planManagementCustomers);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchPlanManagementSuppliers(payload, rootState) {
      try {
        const response = await apiClient.post(`/api/portal/plan-management/providers/list`, payload);
        let planManagementSuppliers = response.data;
        if (payload.page > 1) {
          planManagementSuppliers = rootState.planManagementStore.planManagementSuppliers.concat(
            planManagementSuppliers
          );
        }
        dispatch.planManagementStore.setPlanManagementSuppliers(planManagementSuppliers);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doAddPlanManagementSuppliers(payload, rootState) {
      try {
        await apiClient.post(`/api/portal/plan-management/providers`, payload);
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doEditPlanManagementSuppliers(payload, rootState) {
      try {
        const result = await apiClient.put(
          `/api/portal/plan-management/providers/${payload.planManagementSupplierId}`,
          payload
        );
        dispatch.planManagementStore.editPlanManagementSupplier(payload);
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchPlanManagementSupplierDetail(payload, rootState) {
      try {
        const result = await apiClient.get(
          `/api/portal/plan-management/providers/${payload.planManagementSupplierId}`,
          payload
        );
        dispatch.planManagementStore.setPlanManagementSupplierDetail(result.data);
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchPlanManagementInvoices(payload, rootState) {
      try {
        const response = await apiClient.post('/api/portal/plan-management/invoices/list', payload);
        let planManagementInvoiceList = response.data;
        if (payload.page > 1) {
          planManagementInvoiceList = rootState.planManagementStore.planManagementInvoices.concat(
            planManagementInvoiceList
          );
        }
        dispatch.planManagementStore.setPlanManagementInvoices(planManagementInvoiceList);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchPlanManagementInvoiceDetails(payload, rootState) {
      try {
        const response = await apiClient.get(`/api/portal/plan-management/invoices/${payload.planManagementInvoiceId}`);
        const invoiceItem = response.data;
        dispatch.planManagementStore.setSelectedPlanManagementInvoiceDetails(invoiceItem);
        dispatch.planManagementStore.addInPlanManagementLineItemList({
          planManagementInvoiceId: invoiceItem.planManagementInvoiceId,
          lineItems: invoiceItem.lineItems
        });
        return invoiceItem;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doCreatePlanManagementInvoice(payload, rootState) {
      try {
        console.log('PAYLOAD: ', payload);
        const response = await apiClient.post(`/api/portal/plan-management/invoices`, payload);
        return response.data;
      } catch (e) {
        console.log(e);
        if (e.meta.message === 'File extension not supported') {
          return {
            error: true,
            message: 'File extension not supported'
          };
        }
        throw e;
      }
    },

    async doFetchPlanManagementInvoiceStatistics(payload, rootState) {
      try {
        const response = await apiClient.get(
          `/api/portal/plan-management/invoices/counts${
            payload.planManagementSupplierId ? `?planManagementSupplierId=${payload.planManagementSupplierId}` : ''
          }${payload.customerUserId ? `?customerUserId=${payload.customerUserId}` : ''}`
        );
        dispatch.planManagementStore.setPlanManagementInvoiceStatistics(response.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doEditPlanManagementInvoice(payload, rootState) {
      try {
        const response = await apiClient.put(
          `/api/portal/plan-management/invoices/${payload.planManagementInvoiceId}`,
          payload
        );
        return response.data;
      } catch (e) {
        console.log(e);
        if (e.meta.message === 'File extension not supported') {
          return {
            error: true,
            message: 'File extension not supported'
          };
        }
        throw e;
      }
    },

    async doCancelPlanManagementInvoice(payload, rootState) {
      try {
        const response = await apiClient.delete(
          `/api/portal/plan-management/invoices/${payload.planManagementInvoiceId}`
        );
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doSendInvoiceForApproval(payload, rootState) {
      try {
        const response = await apiClient.post(
          `api/portal/plan-management/invoices/${payload.planManagementInvoiceId}/send-for-approval`,
          payload
        );
        dispatch.planManagementStore.setSelectedPlanManagementInvoiceDetails({
          ...rootState.planManagementStore.selectedPlanManagementInvoiceDetails,
          ...response.data
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doMarkInvoiceAs(payload, rootState) {
      try {
        const response = await apiClient.put(
          `api/portal/plan-management/invoices/${payload.planManagementInvoiceId}/approval-status`,
          payload
        );
        dispatch.planManagementStore.setSelectedPlanManagementInvoiceDetails({
          ...rootState.planManagementStore.selectedPlanManagementInvoiceDetails,
          ...response.data
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doRemoveApproval(payload, rootState) {
      try {
        const response = await apiClient.post(
          `api/portal/plan-management/invoices/${payload.planManagementInvoiceId}/remove-approval`
        );
        dispatch.planManagementStore.setSelectedPlanManagementInvoiceDetails({
          ...rootState.planManagementStore.selectedPlanManagementInvoiceDetails,
          ...response.data
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doDownloadInvoiceDocument(payload, rootState) {
      try {
        const config: AxiosRequestConfig = {
          responseType: 'blob',
          headers: {
            uniqueId: payload.uniqueId
          }
        };
        Axios.get(payload.documentUrl, config).then((res) => {
          fileDownload(res.data, payload.documentName);
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doAddInvoiceLineItem(payload, rootState) {
      try {
        const response = await apiClient.post(
          `api/portal/plan-management/invoices/${payload.invoiceId}/items`,
          payload
        );
        dispatch.planManagementStore.addLineItemToInvoice(response.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doEditInvoiceLineItem(payload, rootState) {
      try {
        const response = await apiClient.put(
          `api/portal/plan-management/invoices/${payload.invoiceId}/items/${payload.planManagementLineItemId}`,
          payload
        );
        dispatch.planManagementStore.editLineItemToInvoice(response.data);
        if (payload.updateInvoiceDetail) {
          dispatch.planManagementStore.doFetchPlanManagementInvoiceDetails({
            planManagementInvoiceId: payload.invoiceId
          });
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doDeleteInvoiceLineItem(payload, rootState) {
      try {
        await apiClient.delete(
          `api/portal/plan-management/invoices/${payload.invoiceId}/items/${payload.planManagementLineItemId}`
        );
        dispatch.planManagementStore.deleteLineItemToInvoice(payload);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doSendInvoiceToPayment(payload, rootState) {
      try {
        const response = await apiClient.post(
          `api/portal/plan-management/invoices/${payload.invoiceId}/send-to-payment`
        );
        dispatch.planManagementStore.sendInvoiceLineItemToPayment(response.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doRevokeInvoice(payload, rootState) {
      try {
        const response = await apiClient.post(`api/portal/plan-management/invoices/${payload.invoiceId}/revoke`);
        dispatch.planManagementStore.revokeInvoice(response.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchReadyForClaimInvoices(payload, rootState) {
      try {
        const response = await apiClient.post(`api/portal/plan-management/invoices/ready-for-claim`, payload);
        let planManagementInvoiceList = response.data;
        if (payload.page > 1) {
          planManagementInvoiceList = rootState.planManagementStore.planManagementInvoiceList.concat(
            planManagementInvoiceList
          );
        }
        dispatch.planManagementStore.setPlanManagementInvoiceList(planManagementInvoiceList);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchReadyForClaimOverview(payload, rootState) {
      try {
        const response = await apiClient.get(`api/portal/plan-management/invoices/all-ready-for-claim`);
        dispatch.planManagementStore.setPlanManagementOverviewData(response.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchRejectedInvoices(payload, rootState) {
      try {
        const response = await apiClient.post(`api/portal/plan-management/invoices/rejected`, payload);
        let planManagementInvoiceList = response.data;
        if (payload.page > 1) {
          planManagementInvoiceList = rootState.planManagementStore.planManagementInvoiceList.concat(
            planManagementInvoiceList
          );
        }
        dispatch.planManagementStore.setPlanManagementInvoiceList(planManagementInvoiceList);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doWaiveInvoice(payload, rootState) {
      try {
        const response = await apiClient.put(`api/portal/plan-management/invoices/${payload.invoiceId}/waive`, payload);
        // TODO: move to reducer
        if (response.status === 200) {
          const newInvoiceList = _.filter(
            rootState.planManagementStore.planManagementInvoiceList,
            (invoice) => invoice.planManagementInvoiceId !== payload.invoiceId
          );
          dispatch.planManagementStore.setPlanManagementInvoiceList(newInvoiceList);
          const overviewData = _.filter(
            rootState.planManagementStore.planManagementOverviewData,
            (lineItem) => lineItem.planManagementInvoiceId !== payload.invoiceId
          );
          dispatch.planManagementStore.setPlanManagementOverviewData(overviewData);
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doWaiveLineItem(payload, rootState) {
      try {
        const response = await apiClient.put(
          `api/portal/plan-management/invoices/${payload.invoiceId}/items/${payload.planManagementLineItemId}/waive`,
          payload
        );
        // TODO: move to reducer
        if (response.status === 200) {
          let newInvoiceList = _.map(rootState.planManagementStore.planManagementInvoiceList, (invoice) => {
            if (invoice.planManagementInvoiceId === payload.invoiceId) {
              return {
                ...invoice,
                numberOfItem: Number(invoice.numberOfItem) - 1,
                total: Number(invoice.total) - payload.total
              };
            } else {
              return { ...invoice };
            }
          });
          newInvoiceList = _.filter(newInvoiceList, (invoice) => Number(invoice.numberOfItem) >= 1);
          dispatch.planManagementStore.setPlanManagementInvoiceList(newInvoiceList);
          dispatch.planManagementStore.doFetchPlanManagementInvoiceDetails({
            planManagementInvoiceId: payload.invoiceId
          });
          if (rootState.planManagementStore.planManagementOverviewData) {
            const overviewData = _.filter(
              rootState.planManagementStore.planManagementOverviewData,
              (lineItem) => lineItem.planManagementLineItemId !== payload.planManagementLineItemId
            );
            dispatch.planManagementStore.setPlanManagementOverviewData(overviewData);
          }
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchClaimedInvoices(payload, rootState) {
      try {
        const response = await apiClient.post(`api/portal/plan-management/invoices/claimed`, payload);
        let planManagementInvoiceList = response.data;
        if (payload.page > 1) {
          planManagementInvoiceList = rootState.planManagementStore.planManagementInvoiceList.concat(
            planManagementInvoiceList
          );
        }
        dispatch.planManagementStore.setPlanManagementInvoiceList(planManagementInvoiceList);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchClaimedInvoicesOverview(payload, rootState) {
      try {
        const response = await apiClient.get(`api/portal/plan-management/invoices/all-claimed`);
        dispatch.planManagementStore.setPlanManagementOverviewData(response.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doRejectClaimedInvoiceLineItems(payload, rootState) {
      try {
        const response = await apiClient.put(
          `api/portal/plan-management/invoices/${payload.planManagementInvoiceId}/reject`,
          payload
        );
        return response.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doProcessInvoices(payload, rootState) {
      try {
        const response = await apiClient.post(`api/portal/plan-management/invoices/process`, payload);
        // TODO: move to reducer
        if (response.status === 200) {
          const newInvoiceList = _.filter(
            rootState.planManagementStore.planManagementInvoiceList,
            (invoice) => !_.find(payload.invoiceIds, (invoiceId) => invoice.planManagementInvoiceId === invoiceId)
          );
          dispatch.planManagementStore.setPlanManagementInvoiceList(newInvoiceList);
          const overviewData = _.filter(
            rootState.planManagementStore.planManagementOverviewData,
            (lineItem) => !_.find(payload.invoiceIds, (invoiceId) => lineItem.planManagementInvoiceId === invoiceId)
          );
          dispatch.planManagementStore.setPlanManagementOverviewData(overviewData);
        }
        return response.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doReSendToPayments(payload, rootState) {
      try {
        const response = await apiClient.put(
          `api/portal/plan-management/invoices/${payload.invoiceId}/items/${payload.planManagementLineItemId}/resend`,
          payload
        );
        // TODO: move to reducer
        if (response.status === 200) {
          let newInvoiceList = _.map(rootState.planManagementStore.planManagementInvoiceList, (invoice) => {
            if (invoice.planManagementInvoiceId === payload.invoiceId) {
              return {
                ...invoice,
                numberOfItem: Number(invoice.numberOfItem) - 1,
                total: Number(invoice.total) - payload.total
              };
            } else {
              return { ...invoice };
            }
          });
          newInvoiceList = _.filter(newInvoiceList, (invoice) => Number(invoice.numberOfItem) >= 1);
          dispatch.planManagementStore.setPlanManagementInvoiceList(newInvoiceList);
          dispatch.planManagementStore.doFetchPlanManagementInvoiceDetails({
            planManagementInvoiceId: payload.invoiceId
          });
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doMarkInvoicesAsPaid(payload, rootState) {
      try {
        const response = await apiClient.put(`api/portal/plan-management/invoices/paid`, payload);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchWaivedInvoiceList(payload, rootState) {
      try {
        const response = await apiClient.post(`api/portal/plan-management/invoices/waived`, payload);
        let planManagementInvoiceList = response.data;
        if (payload.page > 1) {
          planManagementInvoiceList = rootState.planManagementStore.planManagementInvoiceList.concat(
            planManagementInvoiceList
          );
        }
        dispatch.planManagementStore.setPlanManagementInvoiceList(planManagementInvoiceList);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doUnwaivePlanManagedInvoiceLineItems(payload, rootState) {
      try {
        const response = await apiClient.put(
          `api/portal/plan-management/invoices/${payload.invoiceId}/items/${payload.planManagementLineItemId}/un-waive`
        );
      } catch (e) {
        console.log(e);
        throw e;
      }
    },
    async doFetchBatchListing(payload, rootState) {
      try {
        const fullUrl = 'api/portal/plan-management/batches';
        const response = await apiClient.post(fullUrl, payload);
        let planManagementBatchListing = response.data;
        if (payload.page > 1) {
          planManagementBatchListing = rootState.planManagementStore.planManagementBatchListing.concat(
            planManagementBatchListing
          );
        }
        dispatch.planManagementStore.setPlanManagementBatchListing(planManagementBatchListing);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchBatchDetail(payload, rootState) {
      try {
        const fullUrl = `api/portal/plan-management/batches/${payload.batchId}`;
        const response = await apiClient.post(fullUrl, payload);
        let planManagementBatchDetail = response.data;
        if (payload.page > 1) {
          planManagementBatchDetail = rootState.planManagementStore.planManagementBatchDetail.concat(
            planManagementBatchDetail
          );
        }
        dispatch.planManagementStore.setPlanManagementBatchDetail(planManagementBatchDetail);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchPlanManagementBatchInvoiceDetails(payload, rootState) {
      try {
        const batchDetail = rootState.planManagementStore.planManagementBatchDetail;
        const fullUrl = `api/portal/plan-management/batches/${batchDetail.batch.batchId}/invoices/${payload.planManagementInvoiceId}`;
        const response = await apiClient.get(fullUrl);
        dispatch.planManagementStore.setPlanManagementBatchInvoiceDetails(response.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doGetDownloadNDISFileData(payload, rootState) {
      try {
        const fullUrl = `api/portal/plan-management/batches/${payload.batchId}/ndis`;
        const response = await apiClient.get(fullUrl);
        return response.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchPaidInvoices(payload, rootState) {
      try {
        const response = await apiClient.post(`api/portal/plan-management/invoices/paid`, payload);
        let planManagementInvoiceList = response.data;
        if (payload.page > 1) {
          planManagementInvoiceList = rootState.planManagementStore.planManagementInvoiceList.concat(
            planManagementInvoiceList
          );
        }
        dispatch.planManagementStore.setPlanManagementInvoiceList(planManagementInvoiceList);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doMarkAsUnpaidInvoices(payload, rootState) {
      try {
        const response = await apiClient.put(`api/portal/plan-management/invoices/unpaid`, payload);
        // TODO: Move to reducer
        if (response.status === 200) {
          const newInvoiceList = _.filter(
            rootState.planManagementStore.planManagementInvoiceList,
            (invoice) => !_.find(payload.invoiceIds, (invoiceId) => invoice.planManagementInvoiceId === invoiceId)
          );
          dispatch.planManagementStore.setPlanManagementInvoiceList(newInvoiceList);
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doMarkAsPaymentFailedInvoices(payload, rootState) {
      try {
        const response = await apiClient.put(`api/portal/plan-management/invoices/failed`, payload);
        // TODO: Move to reducer
        if (response.status === 200) {
          const newInvoiceList = _.filter(
            rootState.planManagementStore.planManagementInvoiceList,
            (invoice) => !_.find(payload.invoiceIds, (invoiceId) => invoice.planManagementInvoiceId === invoiceId)
          );
          dispatch.planManagementStore.setPlanManagementInvoiceList(newInvoiceList);
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchPaymentFailedInvoices(payload, rootState) {
      try {
        const response = await apiClient.post(`api/portal/plan-management/invoices/failed`, payload);
        let planManagementInvoiceList = response.data;
        if (payload.page > 1) {
          planManagementInvoiceList = rootState.planManagementStore.planManagementInvoiceList.concat(
            planManagementInvoiceList
          );
        }
        dispatch.planManagementStore.setPlanManagementInvoiceList(planManagementInvoiceList);
      } catch (e) {
        console.log(e);
        throw e;
      }
    }
  })
};

export default planManagementStore;
