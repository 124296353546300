import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { connect } from 'react-redux';
import { BookingSVG, ConfirmedSVG, ErrorSVG } from 'assets/UndrawSVG';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import _ from 'lodash';
import { Spinner } from '@blueprintjs/core';

interface IBookingApproveActionModelProps {
  isOpen: boolean;
  navigateTab: (key) => void;
  selectedBookingItem: typeof state.bookingsStore.selectedBookingItem;
  onClose: () => void;
  doSendToFinance: typeof dispatch.bookingsStore.doSendToFinance;
}

class BookingApproveActionModel extends Component<IBookingApproveActionModelProps, any> {
  state = {
    canManuallyClose: true,
    step: 1,
    title: 'Approve Booking'
  };

  renderView = () => {
    const { step } = this.state;
    const { selectedBookingItem } = this.props;

    if (step === 1) {
      const totalAmount = _.reduce(
        selectedBookingItem.billingLineItems,
        (acc, items) => {
          return _.round(acc + Number(items.billingTotal), 2);
        },
        0
      );

      return (
        <div className="anim-fade-in">
          <div className="text-align-left">
            <Paragraph>Are you sure you want to approve this booking request? </Paragraph>
            <Paragraph>Doing so will send all line items attached to this booking to payments. </Paragraph>
            <Paragraph>
              The total amount for this booking is: <b>$ {totalAmount}</b>
            </Paragraph>
          </div>
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal}>
              Not now
            </GhostButton>
            <PrimaryButton size="large" icon="check" onClick={this.onApprove}>
              Approve Booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <div className="text-align-center">
            <div className="pv-large">
              <Spinner size={150} />
            </div>

            <Paragraph>Approving this booking, won't be long...</Paragraph>
          </div>
        </div>
      );
    }
    if (step === 3) {
      return (
        <div className="anim-fade-in">
          <div className="text-align-left">
            <Paragraph>This booking has been successfully approved.</Paragraph>
            <Paragraph>All line items have been sent to payments where they are ready to be processed.</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 4) {
      return (
        <div className="anim-fade-in">
          <div className="pv-medium">
            <img src={ErrorSVG} alt="ERROR" style={{ width: '100%', height: '200px' }} />
          </div>
          <div className="text-align-center">
            <Paragraph>Oops something has gone wrong, please try again</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Go Back to Booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ canManuallyClose: true, step: 1, title: 'Approve Booking' });
    onClose();
  };

  onApprove = async () => {
    const { doSendToFinance } = this.props;
    this.setState({ step: 2, canManuallyClose: false });
    try {
      await doSendToFinance({});
      this.setState({ step: 3, canManuallyClose: true, title: 'Booking successfully approved' });
    } catch (e) {
      this.setState({ step: 4, canManuallyClose: true });
    }
  };

  onReview = () => {
    const { navigateTab } = this.props;
    navigateTab('billing');
    this.onCloseModal();
  };

  render() {
    const { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title={this.state.title}
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div className="flex-column justify-center">{this.renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({ selectedBookingItem: state.bookingsStore.selectedBookingItem });

const mapDispatch = (dispatch: IRootDispatch) => ({ doSendToFinance: dispatch.bookingsStore.doSendToFinance });

export default connect(
  mapState,
  mapDispatch
)(BookingApproveActionModel);
