import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SubTitle, Text } from 'common-components/typography';
import { IRootState, state } from 'stores/rematch/root-store';
import { ServiceLocationType, ScheduleType } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';
import moment from 'moment';
import _ from 'lodash';

interface INewScheduleDetailPanelProps {
  newGroupServiceSchedule: typeof state.servicesStore.newGroupServiceSchedule;
}

class NewScheduleDetailPanel extends Component<INewScheduleDetailPanelProps, null> {
  render() {
    const { newGroupServiceSchedule } = this.props;
    const isSchedule = newGroupServiceSchedule.scheduleType === ScheduleType.SCHEDULE;
    return isSchedule ? (
      <>
        <div className={'mb-large'}>
          <SubTitle>Title</SubTitle>
          {newGroupServiceSchedule.scheduleName}
        </div>
        <div className={'flex-row mb-large'}>
          <div className={'mr-large'} style={{ width: '250px' }}>
            <SubTitle>Location</SubTitle>
            {newGroupServiceSchedule.scheduleDirection === ServiceLocationType.FIXEDVENUE ? (
              <Text style={{ whiteSpace: 'pre-line' }}>
                {CommonUtils.formatFullAddress(newGroupServiceSchedule.address)}
              </Text>
            ) : (
              <Text color={'secondary'}>Online</Text>
            )}
          </div>
          <div>
            <SubTitle>Session created</SubTitle>
            {_.filter(newGroupServiceSchedule.sessions, (session) => session.isSelected).length}
          </div>
        </div>
        <div>
          <SubTitle>Details</SubTitle>
          {moment(newGroupServiceSchedule.scheduleStartDate).format('Do MMMM YYYY')} -{' '}
          {moment(newGroupServiceSchedule.scheduleEndDate).format('Do MMMM YYYY')}
          {_.map(newGroupServiceSchedule.timeSlots, (timeSlot) => {
            return (
              <div>
                <Text size={'regular'} color={'secondary'}>
                  {CommonUtils.getRecurringPatternLabel(timeSlot.recurringPattern)} -{' '}
                  <b>{moment(timeSlot.startDateTime).format('dddd')}</b> from{' '}
                  <b>
                    {moment(timeSlot.startDateTime).format('hh:mmA')} to {moment(timeSlot.endDateTime).format('hh:mmA')}
                  </b>
                </Text>{' '}
                <Text size={'regular'} weight={'bold'}>
                  ({timeSlot.capacity > 0 ? `Max capacity: ${timeSlot.capacity}` : 'No max capacity'})
                </Text>
              </div>
            );
          })}
        </div>
      </>
    ) : (
      <>
        <div className={'flex-row mb-large'}>
          <div className={'mr-large'} style={{ width: '250px' }}>
            <SubTitle>Details</SubTitle>
            {moment(newGroupServiceSchedule.scheduleStartDate).format('hh:mmA - Do MMMM YYYY')} to
            <br />
            {moment(newGroupServiceSchedule.scheduleEndDate).format('hh:mmA - Do MMMM YYYY')}
          </div>
          <div>
            <SubTitle>Capacity</SubTitle>
            {newGroupServiceSchedule.sessions && newGroupServiceSchedule.sessions[0].capacity > 0 ? (
              newGroupServiceSchedule.sessions[0].capacity
            ) : (
              <Text color={'secondary'}>No capacity limit</Text>
            )}
          </div>
        </div>
        <SubTitle>Location</SubTitle>
        {newGroupServiceSchedule.scheduleDirection === ServiceLocationType.FIXEDVENUE ? (
          <Text style={{ whiteSpace: 'pre-line' }}>
            {CommonUtils.formatFullAddress(newGroupServiceSchedule.address)}
          </Text>
        ) : (
          <Text color={'secondary'}>Online</Text>
        )}
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  newGroupServiceSchedule: state.servicesStore.newGroupServiceSchedule
});

export default connect(
  mapState,
  null
)(NewScheduleDetailPanel);
