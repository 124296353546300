import React, { Component } from 'react';

import _ from 'lodash';

import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { RouteComponentProps } from 'react-router-dom';

import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { IBillingBooking } from 'interfaces/booking-interfaces';

import themeVariables from 'theme/theme-variables';
// Custom CSS
import './payments/css/new-payment-listing.css';

import { PayListNavigationSection } from './payments/sections/PayListNavigationSection';
import PayListDetailsSection from './payments/sections/PayListDetailsSection';
import PayListBatchesSection from './payments/sections/PayListBatchSection';
import PayListProcessedSection from './payments/sections/PayListProcessedSection';
import { PAYMENTLIST_FILTERCONFIGS } from './payments/components/payListFilterConfig';
import PayListWaivedSection from 'views/billings/payments/sections/PayListWaivedSection';
import PayListRejectedSection from 'views/billings/payments/sections/PayListRejectedSection';
import ShiftHoursSection from 'views/billings/payments/sections/PayListShiftHoursSection';
import PermissionUtils from 'utilities/permission-utils';
import PayListPaidSection from './payments/sections/PayListPaidSection';

// Default Body Height
const { bodyHeight } = themeVariables;

//endregion

interface IPaymentListingsV2Props extends RouteComponentProps, FormComponentProps {
  paymentsList: typeof state.billingsStore.paymentsList;
  batchList: typeof state.billingsStore.batchList;
  invoicesList: typeof state.billingsStore.invoiceList;
  rejectedList: typeof state.billingsStore.rejectedList;
  shiftHoursList: typeof state.billingsStore.shiftHoursList;
  overviewPayments: typeof state.billingsStore.overviewPayments;
  portalUser: typeof state.authStore.portalUser;

  doFetchPayments: typeof dispatch.billingsStore.doFetchBillingLineItemsToReview;
  doFetchBatches: typeof dispatch.billingsStore.doFetchBatches;
  doFetchInvoices: typeof dispatch.billingsStore.doFetchInvoices;
  doFetchRejected: typeof dispatch.billingsStore.doFetchRejected;
  doFetchOverview: typeof dispatch.billingsStore.doFetchOverviewWaitingPayments;
  doFetchShiftHours: typeof dispatch.billingsStore.doFetchShiftHours;
  selectedFilterKey: typeof state.billingsStore.selectedFilterKey;
  setSelectedFilterKey: typeof dispatch.billingsStore.setSelectedFilterKey;

  paymentsFilter: typeof state.billingsStore.billingLineItemsFilter;
  invoicesFilter: typeof state.billingsStore.invoicesFilter;
  batchesFilter: typeof state.billingsStore.batchesFilter;
  rejectedFilter: typeof state.billingsStore.rejectedFilter;
  shiftHoursFilter: typeof state.billingsStore.shiftHoursFilter;

  setPaymentsFilter: typeof dispatch.billingsStore.setBillingLineItemsFilter;
  setInvoicesFilter: typeof dispatch.billingsStore.setInvoicesFilter;
  setBatchesFilter: typeof dispatch.billingsStore.setBatchesFilter;
  setRejectedFilter: typeof dispatch.billingsStore.setRejectedFilter;
  setShiftHoursFilter: typeof dispatch.billingsStore.setShiftHoursFilter;
  setSelectedInvoices: typeof dispatch.billingsStore.setSelectedInvoice;
  setSelectedRejected: typeof dispatch.billingsStore.setSelectedRejected;

  isSelectedAll: boolean;

  selectedPayments: IBillingBooking[];
  selectedInvoices: IBillingBooking[];
  selectedBatches: IBillingBooking[];
  selectedRejected: IBillingBooking[];

  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;

  history: any;
}

class PaymentListingV2View extends Component<IPaymentListingsV2Props, any> {
  state = {
    isServiceProviderVCPEnabled: false
  };

  _setFilter = async (filterKey) => {
    const { setSelectedFilterKey } = this.props;

    setSelectedFilterKey(filterKey);
  };

  componentDidMount = async () => {
    const { selectedFilterKey, setSelectedFilterKey, setPaymentsFilter } = this.props;

    this.props.setSelectedSideNavMenuKeys(['/payments']);

    const isVCPEnabled = await PermissionUtils.getIsServiceProviderVCPEnabled();
    this.setState({
      isServiceProviderVCPEnabled: !!isVCPEnabled
    });

    if (_.isEmpty(selectedFilterKey)) {
      const defaultFilterKey = 'WAITING_TO_BE_PROCESSED';
      await setSelectedFilterKey(defaultFilterKey);
      await setPaymentsFilter(PAYMENTLIST_FILTERCONFIGS[defaultFilterKey].filters);
    }
  };

  render() {
    const { isServiceProviderVCPEnabled } = this.state;
    // Used to determine what filter to apply.
    let { selectedFilterKey = 'WAITING_TO_BE_PROCESSED' } = this.props;

    if (_.isEmpty(selectedFilterKey)) {
      selectedFilterKey = 'WAITING_TO_BE_PROCESSED';
    }

    const currentFilterConfig = PAYMENTLIST_FILTERCONFIGS[selectedFilterKey];

    return (
      <div className="bg-red bordered-top border-tertiary" style={{ height: bodyHeight, borderWidth: '2px' }}>
        <div className="flex-row height-full">
          <PayListNavigationSection currentFilterConfig={currentFilterConfig} setFilter={this._setFilter} />
          {selectedFilterKey === 'PROCESSED' && (
            <PayListProcessedSection
              isServiceProviderVCPEnabled={isServiceProviderVCPEnabled}
              {...this.props}
              currentFilterConfig={currentFilterConfig}
            />
          )}
          {selectedFilterKey === 'PAID' && (
            <PayListPaidSection
              isServiceProviderVCPEnabled={isServiceProviderVCPEnabled}
              {...this.props}
              currentFilterConfig={currentFilterConfig}
            />
          )}
          {selectedFilterKey === 'BATCH' && (
            <PayListBatchesSection
              isServiceProviderVCPEnabled={isServiceProviderVCPEnabled}
              {...this.props}
              currentFilterConfig={currentFilterConfig}
            />
          )}
          {selectedFilterKey === 'WAITING_TO_BE_PROCESSED' && (
            <PayListDetailsSection
              isServiceProviderVCPEnabled={isServiceProviderVCPEnabled}
              {...this.props}
              currentFilterConfig={currentFilterConfig}
            />
          )}
          {selectedFilterKey === 'REJECTED' && (
            <PayListRejectedSection
              isServiceProviderVCPEnabled={isServiceProviderVCPEnabled}
              {...this.props}
              currentFilterConfig={currentFilterConfig}
            />
          )}
          {selectedFilterKey === 'WAIVED' && (
            <PayListWaivedSection
              isServiceProviderVCPEnabled={isServiceProviderVCPEnabled}
              {...this.props}
              currentFilterConfig={currentFilterConfig}
            />
          )}
          {selectedFilterKey === 'SHIFT_HOURS' && (
            <ShiftHoursSection {...this.props} currentFilterConfig={currentFilterConfig} />
          )}
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  paymentsList: state.billingsStore.paymentsList,
  batchList: state.billingsStore.batchList,
  invoicesList: state.billingsStore.invoiceList,
  rejectedList: state.billingsStore.rejectedList,
  shiftHoursList: state.billingsStore.shiftHoursList,
  overviewPayments: state.billingsStore.overviewPayments,
  selectedFilterKey: state.billingsStore.selectedFilterKey,
  paymentsFilter: state.billingsStore.paymentsFilter,
  batchesFilter: state.billingsStore.batchesFilter,
  invoicesFilter: state.billingsStore.invoicesFilter,
  shiftHoursFilter: state.billingsStore.shiftHoursFilter,
  rejectedFilter: state.billingsStore.rejectedFilter,
  isSelectedAll: state.billingsStore.paymentsSelectedAll,
  selectedBillingLineItemId: state.billingsStore.selectedBillingLineItemId,
  selectedBillingLineItem: state.billingsStore.selectedBillingLineItem,
  paymentsSelectedAll: state.billingsStore.paymentsSelectedAll,
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchPayments: dispatch.billingsStore.doFetchWaitingPayments,
  doFetchBatches: dispatch.billingsStore.doFetchBatches,
  doFetchInvoices: dispatch.billingsStore.doFetchInvoices,
  doFetchOverview: dispatch.billingsStore.doFetchOverviewWaitingPayments,
  doFetchRejected: dispatch.billingsStore.doFetchRejected,
  doFetchShiftHours: dispatch.billingsStore.doFetchShiftHours,
  setInvoices: dispatch.billingsStore.setInvoices,
  setBatches: dispatch.billingsStore.setBatches,
  setRejected: dispatch.billingsStore.setRejected,
  setShiftHours: dispatch.billingsStore.setShiftHours,
  setSelectedFilterKey: dispatch.billingsStore.setSelectedFilterKey,
  setPaymentsFilter: dispatch.billingsStore.setPaymentsFilter,
  setInvoicesFilter: dispatch.billingsStore.setInvoicesFilter,
  setBatchesFilter: dispatch.billingsStore.setBatchesFilter,
  setRejectedFilter: dispatch.billingsStore.setRejectedFilter,
  setShiftHoursFilter: dispatch.billingsStore.setShiftHoursFilter,
  setPaymentsSelectedAll: dispatch.billingsStore.setPaymentsSelectedAll,
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys
});

export default connect(
  mapState,
  mapDispatch
)(PaymentListingV2View);
