import React, { Component } from 'react';
import { Avatar, Checkbox, Col, Form, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { GhostButton, HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import moment, { Moment } from 'moment-timezone';
import _ from 'lodash';
import Search from 'antd/es/input/Search';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import DatePicker from 'react-datepicker';
import TimeInput from 'common-components/time-input/TimeInput';
import CommonUtils from 'utilities/common-utils';
import { BookingStatus } from 'utilities/enum-utils';

interface IMarkAttendancePanelProps extends FormComponentProps {
  saveTimes: (selectedCustomersTimes) => void;
  goBack: () => void;
  selectedSession: typeof state.groupServiceStore.selectedSession;
  sessionCustomerCustomerBookings: typeof state.groupServiceStore.sessionCustomerCustomerBookings;
  doFetchSessionCustomerDetails: typeof dispatch.groupServiceStore.doFetchSessionCustomerDetails;
  type: 'startDateTime' | 'endDateTime';
  selectedTime: Moment;
  displayedStatus?: Array<BookingStatus>;
}

interface IMarkAttendancePanelState {
  isLoading: boolean;
  isSearching: boolean;
  searchString: string;
  customersTimes: any;
  isEditCustomerTimesOpen: boolean;
  selectedCustomer: any;
  selectedCustomerTime: Moment;
}

class MarkAttendancePanel extends Component<IMarkAttendancePanelProps, IMarkAttendancePanelState> {
  state = {
    isLoading: false,
    isSearching: false,
    searchString: null,
    customersTimes: [],
    isEditCustomerTimesOpen: false,
    selectedCustomer: null,
    selectedCustomerTime: null
  };

  private _openEditCustomerTimes = (customer) => {
    this.setState({
      isEditCustomerTimesOpen: true,
      selectedCustomer: customer,
      selectedCustomerTime: customer.dateTime
    });
  };

  private _closeEditCustomerTimes = () => {
    this.setState({ isEditCustomerTimesOpen: false, selectedCustomer: null, selectedCustomerTime: null });
  };

  private _onSaveCustomerTimes = () => {
    const { selectedCustomer, selectedCustomerTime } = this.state;
    this.setState(
      {
        customersTimes: _.map(this.state.customersTimes, (customer) => {
          if (customer.attendanceId === selectedCustomer.attendanceId) {
            return { ...customer, dateTime: selectedCustomerTime };
          } else {
            return { ...customer };
          }
        })
      },
      () => this._closeEditCustomerTimes()
    );
  };

  private _handleCustomerSelection = (event, attendanceId) => {
    this.setState({
      customersTimes: _.map(this.state.customersTimes, (customer) => {
        if (customer.attendanceId === attendanceId) {
          return { ...customer, isSelected: event.target.checked };
        } else {
          return { ...customer };
        }
      })
    });
  };

  private _onClickFinish = () => {
    this.props.saveTimes(_.filter(this.state.customersTimes, (customer) => customer.isSelected));
  };

  private _onEnterSearchText = (e) => {
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _searchText = async (txt) => {
    this.setState({ searchString: txt });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _handleClickAll = (event) => {
    this.setState({
      customersTimes: _.map(this.state.customersTimes, (customer) => {
        return { ...customer, isSelected: event.target.checked };
      })
    });
  };

  private _onChangeDateTime = (e) => {
    this.setState({ selectedCustomerTime: CommonUtils.formatCeilingDateTime(e) });
  };

  componentDidMount = async () => {
    const { selectedSession } = this.props;
    this.setState({ isLoading: true });
    await this.props.doFetchSessionCustomerDetails({
      serviceId: selectedSession.serviceId,
      serviceDateTimeId: selectedSession.serviceDateTimeId
    });
  };

  componentDidUpdate(
    prevProps: Readonly<IMarkAttendancePanelProps>,
    prevState: Readonly<IMarkAttendancePanelState>,
    snapshot?: any
  ) {
    const { displayedStatus, sessionCustomerCustomerBookings } = this.props;
    if (prevProps.sessionCustomerCustomerBookings !== sessionCustomerCustomerBookings) {
      this.setState({
        customersTimes: _.map(
          displayedStatus
            ? _.filter(
                sessionCustomerCustomerBookings,
                (customer) => !!_.find(displayedStatus, (status) => status === customer.status)
              )
            : sessionCustomerCustomerBookings,
          (customer) => {
            return {
              customerAvatarUrl: customer.customerAvatarUrl,
              firstName: customer.firstName,
              lastName: customer.lastName,
              dateTime: this.props.selectedTime,
              isSelected: false,
              attendanceId: customer.attendanceId
            };
          }
        ),
        isLoading: false
      });
    }
  }

  render() {
    const { selectedSession, type } = this.props;
    const {
      isLoading,
      customersTimes,
      searchString,
      isEditCustomerTimesOpen,
      selectedCustomer,
      selectedCustomerTime
    } = this.state;

    const isStartTime = type === 'startDateTime';
    const searchStringArray = _.split(searchString, ' ');
    const filteredCustomerList =
      searchString && searchString.length > 0
        ? _.filter(
            customersTimes,
            (customer) =>
              _.some(searchStringArray, (txt) => _.includes(customer.firstName.toLowerCase(), txt.toLowerCase())) ||
              _.some(searchStringArray, (txt) => _.includes(customer.lastName.toLowerCase(), txt.toLowerCase()))
          )
        : customersTimes;

    const isAllChecked =
      customersTimes && customersTimes.length > 0 && _.every(customersTimes, (customer) => customer.isSelected);
    const isIndeterminate =
      customersTimes &&
      customersTimes.length > 0 &&
      !isAllChecked &&
      _.some(customersTimes, (customer) => customer.isSelected);

    return (
      <div>
        <ActionModal
          isOpen={isEditCustomerTimesOpen}
          title={'Edit start time'}
          onClose={this._closeEditCustomerTimes}
          width={'large'}
        >
          <div className="anim-slide-left">
            <Paragraph>Edit the customers {isStartTime ? 'start' : 'end'} time for this session</Paragraph>

            {selectedCustomer && (
              <>
                <div className={'mt-large'}>
                  <SubTitle>Customer</SubTitle>
                  <div className={' flex-row align-center'}>
                    <Avatar className="mr-small" src={selectedCustomer.customerAvatarUrl} icon={'user'} />{' '}
                    {selectedCustomer.firstName} {selectedCustomer.lastName}
                  </div>
                </div>
                <div className={'mt-large bg-white bordered round-big p-medium mb-x-large'}>
                  <SubTitle>Session {isStartTime ? 'start' : 'end'} time</SubTitle>
                  <div className="flex-row align-left">
                    <DatePicker
                      className="gh-datepicker rounded mr-small"
                      calendarClassName="gh-datepicker-calendar"
                      dateFormat="d/M/yyyy"
                      isClearable={false}
                      selected={moment(selectedCustomerTime).toDate()}
                      onChange={(event) => this._onChangeDateTime(event)}
                    />
                    <TimeInput
                      size="large"
                      value={moment(selectedCustomerTime)}
                      onChange={(event) => this._onChangeDateTime(event)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className={'mb-small'}>
              <Row type={'flex'} justify={'end'}>
                <Col>
                  <GhostButton size="large" onClick={this._closeEditCustomerTimes}>
                    Cancel
                  </GhostButton>
                </Col>
                <Col>
                  <PrimaryButton onClick={this._onSaveCustomerTimes} size="large">
                    Save
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </div>
        </ActionModal>
        {isLoading ? (
          <SpinningLoader size={100} message={'Loading customer list'} />
        ) : (
          <>
            <Paragraph>
              You can mark which customers have {isStartTime ? 'arrived for' : 'left'} the session and edit their{' '}
              {isStartTime ? 'start' : 'end'} time.{' '}
              {isStartTime ? 'You can do this at a later time if you are unsure which customers have arrived.' : ''}
            </Paragraph>
            <Search onChange={this._onEnterSearchText} placeholder="Search for..." allowClear size={'large'} />
            <div className={'bordered rounded-big shadow-container mt-medium mb-x-large'}>
              <Row className={'bordered-bottom ph-medium pv-small'}>
                <Col span={1}>
                  <Checkbox onClick={this._handleClickAll} checked={isAllChecked} indeterminate={isIndeterminate} />
                </Col>
                <Col span={9}>Customer</Col>
                <Col span={10}>{isStartTime ? 'Start' : 'End'} time</Col>
                <Col span={4}></Col>
              </Row>
              {filteredCustomerList && filteredCustomerList.length === 0 ? (
                <Row className={'p-medium evenodd'} type={'flex'} align={'middle'}>
                  <Col>
                    <Text color={'secondary'}>
                      No <b>confirmed</b> customer booking {isStartTime ? 'starting' : 'ending'} at the same time as the
                      session.
                    </Text>
                  </Col>
                </Row>
              ) : (
                _.map(filteredCustomerList, (customer) => {
                  return (
                    <Row className={'p-medium evenodd'} type={'flex'} align={'middle'}>
                      <Col span={1}>
                        <Checkbox
                          onClick={(event) => this._handleCustomerSelection(event, customer.attendanceId)}
                          checked={customer.isSelected}
                        />
                      </Col>
                      <Col span={9} className={'flex-row align-center'}>
                        <Text>
                          <Avatar className="mr-small" src={customer.customerAvatarUrl} icon={'user'} />{' '}
                          {customer.firstName} {customer.lastName}
                        </Text>
                      </Col>
                      <Col span={10}>{moment(customer.dateTime).format('DD MMMM Y, h:mm A')}</Col>
                      <Col span={4}>
                        <HyperlinkButton onClick={() => this._openEditCustomerTimes(customer)}>
                          Edit start time
                        </HyperlinkButton>
                      </Col>
                    </Row>
                  );
                })
              )}
            </div>
            <div className={'mb-small'}>
              <Row type={'flex'} justify={'end'}>
                <Col>
                  <GhostButton size="large" onClick={this.props.goBack}>
                    Back
                  </GhostButton>
                </Col>
                <Col>
                  <PrimaryButton onClick={this._onClickFinish} size="large">
                    {isStartTime ? 'Start session' : 'Finish session'}
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession,
  sessionCustomerCustomerBookings: state.groupServiceStore.sessionCustomerCustomerBookings
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchSessionCustomerDetails: dispatch.groupServiceStore.doFetchSessionCustomerDetails
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IMarkAttendancePanelProps>()(MarkAttendancePanel));
