import React, { PureComponent } from 'react';
import { Text, Title } from 'common-components/typography';
import { Checkbox, Empty, Icon, Skeleton, notification } from 'antd';
import _ from 'lodash';
import CreateNewActivityRecordModal from 'views/bookings/listings/components/CreateNewActivityRecordModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { ActionMenu, ActionMenuItem } from '../../../../common-components/action-menu/index';
import { BottomActionSheet } from '../components/details/BottomActionSheet';
import { BookingItemRow } from '../components/details/BookingItemRow';
import { IconButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { FilterSection } from 'common-components/filter';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { TimezoneSelector } from 'common-components/timezone';
import { timeZone } from 'interfaces/timezone-type';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { FilterType, ServiceType, TimezoneSelectorMode, CustomViewsModalType } from 'utilities/enum-utils';
import CreateNewBookingModal from 'views/bookings/listings/components/CreateNewBookingModal';
import RecurringBookingListModal from 'views/bookings/listings/components/RecurringBookingListModal';
import BulkApproveWarningModal from 'views/bookings/new-listings/components/details/action-sheets/BulkApproveWarningModal';
import BookingListingCalendar from 'views/bookings/new-listings/sections/BookingListingCalendar';
import BookListNavigationSection from './BookingListingNavigation';
import { ICustomView } from 'src/interfaces/custom-views-interface';
import CustomViewsModal from 'common-components/custom-views/modals/CustomViewsModal';
import CommonUtils from 'utilities/common-utils';
import * as H from 'history';
import { HeaderFieldItem } from '../components/details/HeaderFieldsItem';
import BookingBulkRemoveTeamMemberModal from '../../components/BookingBulkRemoveTeamMemberModal';
import CancelBulkBookingsModal from '../../listings/components/CancelBulkBookingsModal';

interface ListPanelProps {
  bookingsList?: typeof state.bookingsStore.bookingsList;
  selectedBookings?: typeof state.bookingsStore.selectedBookings;
  bookingsFilter?: typeof state.bookingsStore.bookingsFilter;
  currentFilterConfig?: any;
  history?: H.History;
  openBookingModal?: () => void;
  doFetchBookings?: typeof dispatch.bookingsStore.doFetchBookings;
  doFetchMoreBookings?: typeof dispatch.bookingsStore.doFetchMoreBookings;
  setSelectedBookings?: typeof dispatch.bookingsStore.setSelectedBookings;
  setBookingsFilter?: typeof dispatch.bookingsStore.setBookingsFilter;
  hideFilterTabToggle: () => void;

  doBatchAcceptBookings: typeof dispatch.bookingsStore.doBatchAcceptBookings;
  doBatchAcceptConfirmBookings: typeof dispatch.bookingsStore.doBatchAcceptConfirmBookings;
  doBatchConfirmBookings: typeof dispatch.bookingsStore.doBatchConfirmBookings;
  doBatchRejectBookings: typeof dispatch.bookingsStore.doBatchRejectBookings;
  doBatchApproveBookings: typeof dispatch.bookingsStore.doBatchApproveBookings;
  doBatchSendToFinanceBookings: typeof dispatch.bookingsStore.doBatchSendToFinanceBookings;
  setBookingList: typeof dispatch.bookingsStore.setBookingList;
  setBookingDisplayTzMode: typeof dispatch.bookingsStore.setBookingDisplayTzMode;
  setBookingDisplayTzCustom: typeof dispatch.bookingsStore.setBookingDisplayTzCustom;
  bookingDisplayTzCustom: typeof state.bookingsStore.bookingDisplayTzCustom;
  bookingDisplayTzMode: typeof state.bookingsStore.bookingDisplayTzMode;
  doBulkRemoveTeamMembersBookingListing: typeof dispatch.bookingsStore.doBulkRemoveTeamMembersBookingListing;
  doBulkCancelBookingsBookingListing: typeof dispatch.bookingsStore.doBulkCancelBookingsBookingListing;
  doGetPortalUserDetail: typeof dispatch.authStore.doGetPortalUserDetail;
  portalUser: typeof state.authStore.portalUser;
  setHasBookingListingFilterChanged: typeof dispatch.bookingsStore.setHasBookingListingFilterChanged;
  setCustomerBookingToOpen: typeof dispatch.groupServiceStore.setCustomerBookingToOpen;
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;
  showFilterSection: boolean;
  setFilter: (filterKey) => Promise<void>;
  bookingListingActiveTab: typeof state.bookingsStore.bookingListingActiveTab;
  displayedBookingListingTabs: typeof state.bookingsStore.displayedBookingListingTabs;
  setDisplayedBookingListingTabs: typeof dispatch.bookingsStore.setDisplayedBookingListingTabs;
  setBookingListingActiveTab: typeof dispatch.bookingsStore.setBookingListingActiveTab;
  defaultBookingViews: typeof state.bookingsStore.bookingViews;
  doUpdateBookingView: typeof dispatch.bookingsStore.doUpdateBookingView;
  doAddBookingView: typeof dispatch.bookingsStore.doAddBookingView;
  doDuplicateBookingView: typeof dispatch.bookingsStore.doDuplicateBookingView;
  doDeleteBookingView: typeof dispatch.bookingsStore.doDeleteBookingView;
  bookingViews: ICustomView[];
  doFetchBookingViews: typeof dispatch.bookingsStore.doFetchBookingViews;

  isBookingListingLoading: typeof state.bookingsStore.isBookingListingLoading;
  setIsBookingListingLoading: typeof dispatch.bookingsStore.setIsBookingListingLoading;
}

interface ListPanelState {
  topHeight: number;
  showFilters: boolean;
  showActionSheet: boolean;
  checkAllIndicator: boolean;
  indeterminateCheck: boolean;
  error: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  displayTimezone: timeZone;
  isRecurringListModalOpen: boolean;
  selectedBookingItem: any;
  isCustomPeriodPickerOpen: boolean;
  openedMenu: string;
  addNewFilterPanel: string;
  isBulkApprovalWarningModalOpen: boolean;
  missingPaymentMethodBookings: any;
  bulkActionList: null;
  showCreateBookingModal: boolean;
  showBookingCalendar: boolean;
  showCreateActivityRecordModal: boolean;
  viewModalType: CustomViewsModalType;
  showBookingViewModal: boolean;
  showBookingViewPopover: boolean;
  bookingStatus: string;
  isBookingBulkRemoveTeamMemberModalOpen: boolean;
  selectedBookingList: any;
  isCancelBookingOpen: boolean;
}

const BookingEmptyState = () => (
  <div className="flex-1 bg-white mt-x2-large align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No bookings found.
    </Text>{' '}
    <br /> <br />
    <Text color="secondary">All bookings under this filter will appear here.</Text>
    <Text color="secondary">Try adjusting your filter, or clicking on another view.</Text>
  </div>
);

const availableFilters = [
  FilterType.CUSTOMER,
  FilterType.WORKER,
  FilterType.SERVICE,
  FilterType.BOOKING_STATUS,
  FilterType.RECURRING,
  FilterType.PAYMENT_STATUS,
  FilterType.SHIFT_SLOT_STATUS,
  FilterType.DATE_RANGE,
  FilterType.PAYMENT_SOURCES,
  FilterType.BOOKING_ERROR_TYPES,
  FilterType.SHOW_ARCHIVED_BOOKINGS,
  FilterType.PINNED_ALERTS,
  FilterType.BOOKING_TYPES,
  FilterType.SERVICE_TYPE,
  FilterType.LOCATION_BY_SUBURBS,
  FilterType.SERVICE_DATE_TIMES,
  FilterType.GROUP_SERVICE_SCHEDULES,
];

const defaultFilterValue = [
  {
    [FilterType.DATE_RANGE]: [
      moment()
        .startOf('isoWeek')
        .toISOString(),
      moment()
        .endOf('isoWeek')
        .toISOString(),
    ],
  },
  { sort: [['startDateTime', 'asc']] },
  { [FilterType.CUSTOMER]: [] },
  { [FilterType.WORKER]: [] },
  { [FilterType.SHIFT_SLOT_STATUS]: [] },
  { [FilterType.SERVICE_TYPE]: [ServiceType.INDIVIDUAL] },
];

class BookingListingPanel extends PureComponent<ListPanelProps, ListPanelState> {
  // topHeight is used to control sticky
  state = {
    topHeight: 0,
    showFilters: false,
    showActionSheet: false,
    checkAllIndicator: false,
    indeterminateCheck: false,
    isSearching: false,
    error: false,
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    displayTimezone: this.props.portalUser.timezone,
    isRecurringListModalOpen: false,
    selectedBookingItem: null,
    isCustomPeriodPickerOpen: false,
    openedMenu: null,
    addNewFilterPanel: null,
    isBulkApprovalWarningModalOpen: false,
    missingPaymentMethodBookings: null,
    bulkActionList: null,
    showCreateBookingModal: false,
    showBookingCalendar: false,
    showCreateActivityRecordModal: false,
    viewModalType: CustomViewsModalType.CREATE,
    showBookingViewModal: false,
    showBookingViewPopover: false,
    bookingStatus: '',
    isBookingBulkRemoveTeamMemberModalOpen: false,
    selectedBookingList: [],
    isCancelBookingOpen: false
  };

  // ref element for the header; used to determine the height of the header
  private _headerElement = null;

  private _openBookingModal = () => this.setState({ showCreateBookingModal: true });
  private _closeBookingModal = async () => {
    const {
      doFetchBookings,
      currentFilterConfig,
      bookingsFilter,
      setBookingList,
      setIsBookingListingLoading,
    } = this.props;
    const appliedFilters = _.isEmpty(bookingsFilter) ? currentFilterConfig.filterValue : bookingsFilter;

    setIsBookingListingLoading(true);
    await await this.setState({
      page: 1,
      pageTimestamp: new Date(),
      displayTimezone: this._getDisplayTimezone(),
    });

    setBookingList([]);
    await doFetchBookings({
      filters: appliedFilters,
      page: 1,
      pageSize: this.state.pageSize,
      pageTimestamp: new Date(),
    });

    setIsBookingListingLoading(false);
    this.setState({ showCreateBookingModal: false });
  };

  // height change handler.
  private _handleHeaderHeight = () => {
    if (this._headerElement) {
      this.setState({ topHeight: this._headerElement.offsetHeight - 1 });
    }
  };

  // Checkbox handler
  private _checkItem = (bookingId) => {
    const { selectedBookings, setSelectedBookings } = this.props;

    // Toggle selected
    const newSelectedBookings = selectedBookings.map((booking) =>
      booking.bookingId === bookingId ? { ...booking, selected: !booking.selected } : { ...booking },
    );

    const someRowsHaveCheck = newSelectedBookings.some((b) => b.selected === true);
    const indeterminateCheck = someRowsHaveCheck && !newSelectedBookings.every((b) => b.selected === true);

    setSelectedBookings(newSelectedBookings);

    this.setState({ showActionSheet: someRowsHaveCheck, indeterminateCheck });
  };

  // Check all handler
  private _onCheckAll = () => {
    const { checkAllIndicator } = this.state;
    const { selectedBookings, setSelectedBookings } = this.props;

    if (_.isEmpty(selectedBookings)) {
      return;
    }

    const newCheckAll = !checkAllIndicator;
    const newSelectedBookings = selectedBookings.map((b) => ({
      ...b,
      selected: b.serviceType === ServiceType.INDIVIDUAL ? newCheckAll : false,
    }));

    setSelectedBookings(newSelectedBookings);

    this.setState({ checkAllIndicator: newCheckAll, showActionSheet: newCheckAll, indeterminateCheck: false });
  };

  // Row click handler
  private _onClickRow = async (serviceType, data) => {
    const { history } = this.props;
    if (serviceType === ServiceType.GROUP) {
      await this.props.setCustomerBookingToOpen(data.bookingId);
      history.push(`/group-service/${data.serviceId}/session/details/${data.serviceDateTimeId}`, {
        bookingId: data.bookingId,
      });
      this.props.setSelectedSideNavMenuKeys(['/services']);
    } else {
      history.push(`/bookings/details/${data.bookingId}`);
    }
  };

  // Deselect action handler
  private _onActionDeselect = () => {
    const { selectedBookings, setSelectedBookings } = this.props;

    // Unselect all
    const newSelectedBookings = selectedBookings.map((b) => ({ ...b, selected: false }));

    // reset everything
    this.setState({ showActionSheet: false, indeterminateCheck: false, checkAllIndicator: false });

    setSelectedBookings(newSelectedBookings);
  };

  private _refreshListings = async () => {
    const { doFetchBookings, bookingsFilter, setBookingList, setIsBookingListingLoading } = this.props;
    setIsBookingListingLoading(true);
    await this.setState({
      checkAllIndicator: false,
      indeterminateCheck: false,
      showActionSheet: false,
      pageTimestamp: new Date(),
      page: 1,
    });

    setBookingList([]);
    await doFetchBookings({
      filters: bookingsFilter,
      page: this.state.page,
      pageTimestamp: this.state.pageTimestamp,
      pageSize: this.state.pageSize,
    });
    setIsBookingListingLoading(false);
  };

  private _fetchMoreBooking = async () => {
    const { doFetchMoreBookings, currentFilterConfig, bookingsFilter, setIsBookingListingLoading } = this.props;
    const appliedFilters = _.isEmpty(bookingsFilter) ? currentFilterConfig.filterValue : bookingsFilter;

    setIsBookingListingLoading(true);
    await this.setState({ page: this.state.page + 1 });

    await doFetchMoreBookings({
      filters: appliedFilters,
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
    });
    setIsBookingListingLoading(false);
  };

  private _onChangeTimezone = async (type, value) => {
    if (type === 'mode') {
      await this.props.setBookingDisplayTzMode(value);
      this.setState({
        displayTimezone:
          value === TimezoneSelectorMode.MyTimezone
            ? this.props.portalUser.timezone
            : value === TimezoneSelectorMode.BookingTimezone
            ? null
            : this.props.bookingDisplayTzMode
            ? this.props.bookingDisplayTzMode
            : this.state.displayTimezone,
      });
    } else if (type === 'customTimezone') {
      await this.props.setBookingDisplayTzCustom(value);
      this.setState({ displayTimezone: value });
    }
  };

  private _openRecurringListModal = (e, bookingItem) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ isRecurringListModalOpen: true, selectedBookingItem: bookingItem });
  };

  private _closeRecurringListModal = () => {
    this.setState({ isRecurringListModalOpen: false, selectedBookingItem: null });
  };

  private _getDisplayTimezone = () => {
    if (this.props.bookingDisplayTzMode === TimezoneSelectorMode.MyTimezone) {
      return this.props.portalUser.timezone;
    } else if (this.props.bookingDisplayTzMode === TimezoneSelectorMode.BookingTimezone) {
      return null;
    } else if (
      this.props.bookingDisplayTzMode === TimezoneSelectorMode.CustomTimezone &&
      this.props.bookingDisplayTzCustom
    ) {
      return this.props.bookingDisplayTzCustom;
    } else {
      return this.state.displayTimezone;
    }
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setHasBookingListingFilterChanged(true);
    this.props.setBookingsFilter(filters);
  };

  private _openBulkApproveWarningModal = (missingPaymentMethodBookings, bookingList) => {
    this.setState({ isBulkApprovalWarningModalOpen: true, missingPaymentMethodBookings, bulkActionList: bookingList });
  };

  private _onCloseApproveWarningModal = () => {
    this.setState({ isBulkApprovalWarningModalOpen: false });
    this._refreshListings();
  };

  private _onOpenBookingBulkRemoveTeamMemberModal = (bookingList, status: string) => {
    this.setState({
      isBookingBulkRemoveTeamMemberModalOpen: true,
      selectedBookingList: bookingList,
      bookingStatus: status,
    });
  };

  private _onCloseBookingBulkRemoveTeamMemberModal = (removeSuccessfully: boolean) => {
    this.setState({ isBookingBulkRemoveTeamMemberModalOpen: false });
    removeSuccessfully && this._refreshListings();
  };

  private _onBulkRemoveTeamMemberBookingListing = async () => {
    try {
      const removeTeamMemberFromBookings = [];
      _.forEach(this.state.selectedBookingList, (selectedBooking) => {
        removeTeamMemberFromBookings.push({
          bookingId: selectedBooking.bookingId,
          bookingRequestId: selectedBooking.bookingRequestId,
          bookingType: selectedBooking.bookingType,
          supportWorkerId: selectedBooking.workerId ? selectedBooking.workerId : selectedBooking.supportWorkerId,
        });
      });
      const payload = {
        bookingStatus: this.state.bookingStatus,
        removeTeamMemberFromBookings,
      };
      await this.props.doBulkRemoveTeamMembersBookingListing(payload);
      notification.success({
        message: <Text weight="bold">Bulk actions successfully completed.</Text>,
        description: (
          <Text className="mt-medium">
            Bulk actions for <span className="text-weight-bold">Remove Team Members</span> complete.
          </Text>
        ),
        duration: 3,
      });
      return true;
    } catch (e) {
      notification.error({
        message: <Text weight="bold">Bulk actions failed.</Text>,
        description: (
          <Text className="mt-medium">
            Bulk actions for <span className="text-weight-bold">Remove Team Members</span> complete has encounter an
            error. Please try again.
          </Text>
        ),
      });
      return false;
    }
  };

  private _onOpenCancelBookingModal = (bookingList, status: string) => {
    this.setState({ isCancelBookingOpen: true, selectedBookingList: bookingList, bookingStatus: status });
  };

  private _onCloseCancelBookingModal = (cancelSuccessfully: boolean) => {
    this.setState({ isCancelBookingOpen: false });
    cancelSuccessfully && this._refreshListings();
  };

  private _onBulkCancelBookingListing = async (data) => {
    try {
      const cancelBookingsId = [];
      _.forEach(this.state.selectedBookingList, (selectedBooking) => cancelBookingsId.push(selectedBooking.bookingId));
      const payload = {
        bookingStatus: this.state.bookingStatus,
        cancelBookingsId,
        ...data
      };
      await this.props.doBulkCancelBookingsBookingListing(payload);
      return true;
    } catch (e) {
      return false;
    }
  };

  private _toggleShowBookingCalendar = () => {
    this.setState({ showBookingCalendar: !this.state.showBookingCalendar });
  };

  // TODO: tobe implement
  private _onOpenCreateActivityRecord = () => {
    this.setState({ showCreateActivityRecordModal: true });
  };

  private _onCloseCreateActivityRecord = () => {
    this.setState({ showCreateActivityRecordModal: false });
  };

  private _openBookingViewModal = (type: CustomViewsModalType) => {
    this.setState({ showBookingViewPopover: false });
    this.setState({ viewModalType: type, showBookingViewModal: true });
  };

  private _onSaveView = (tab: ICustomView) => {
    if (tab && tab.isOwner) {
      this._openBookingViewModal(CustomViewsModalType.SAVE_VIEW);
    } else if (tab && tab.isDefault) {
      this._openBookingViewModal(CustomViewsModalType.SAVE_DEFAULT_VIEW);
    } else {
      this._openBookingViewModal(CustomViewsModalType.SAVE_AS_NEW_COPY_FROM_OTHERS);
    }
  };

  private _closeBookingViewModal = () => {
    this.setState({ viewModalType: CustomViewsModalType.CREATE, showBookingViewModal: false });
  };

  //region Component Lifecycle Methods
  componentDidMount = async () => {
    this.props.setSelectedSideNavMenuKeys(['/bookings']);
    // document.querySelector('#scroll').addEventListener('scroll', this.listenScrollEvent);
    // Automatically set the top height for the top panel. This is required for sticky.
    this._handleHeaderHeight();
    const {
      doFetchBookings,
      setBookingList,
      bookingListingActiveTab,
      setDisplayedBookingListingTabs,
      displayedBookingListingTabs,
      setBookingsFilter,
      defaultBookingViews,
      doFetchBookingViews,
      setBookingListingActiveTab,
      setIsBookingListingLoading,
      history,
    } = this.props;

    setIsBookingListingLoading(true);
    this.setState({
      page: 1,
      pageTimestamp: new Date(),
      displayTimezone: this._getDisplayTimezone(),
    });

    doFetchBookingViews({});

    const showActiveTab = _.get(history, 'location.state.showActiveTab');
    if (bookingListingActiveTab) {
      if (showActiveTab || !bookingListingActiveTab.isDefault) {
        await setDisplayedBookingListingTabs([...displayedBookingListingTabs, bookingListingActiveTab]);
      } else {
        setBookingListingActiveTab(bookingListingActiveTab);
      }
    } else {
      setBookingListingActiveTab(defaultBookingViews[0]);
    }

    let activeTab = defaultBookingViews[0];
    if (!_.isEmpty(bookingListingActiveTab) && _.get(bookingListingActiveTab, 'filterValue')) {
      activeTab = bookingListingActiveTab;
    }

    const appliedFilters = activeTab.filterValue;
    setBookingsFilter(appliedFilters);
    setBookingList([]);

    await doFetchBookings({
      filters: appliedFilters,
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
    });

    setIsBookingListingLoading(false);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const {
      currentFilterConfig,
      doFetchBookings,
      setBookingsFilter,
      setBookingList,
      bookingListingActiveTab,
      bookingsFilter,
      setIsBookingListingLoading,
    } = this.props;

    if (prevState.showFilters !== this.state.showFilters) {
      this._handleHeaderHeight();
    }

    // go fetch filter
    if (prevProps.currentFilterConfig.customViewId !== currentFilterConfig.customViewId) {
      setIsBookingListingLoading(true);
      this.setState({
        checkAllIndicator: false,
        indeterminateCheck: false,
        showActionSheet: false,
        showFilters: false,
      });
      setBookingsFilter(currentFilterConfig.filterValue);
    }

    if (prevProps.bookingListingActiveTab !== bookingListingActiveTab) {
      setIsBookingListingLoading(true);
      this.setState({
        checkAllIndicator: false,
        indeterminateCheck: false,
        showActionSheet: false,
        showFilters: false,
      });
      setBookingsFilter(bookingListingActiveTab.filterValue);
      setIsBookingListingLoading(false);
    }

    if (!_.isEqual(prevProps.bookingsFilter, bookingsFilter)) {
      setIsBookingListingLoading(true);
      await this.setState({ page: 1, pageTimestamp: new Date() });
      setBookingList([]);
      await doFetchBookings({
        filters: bookingsFilter,
        page: this.state.page,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
      });
      setIsBookingListingLoading(false);
      this._handleHeaderHeight();
    }
  };
  //endregion

  render() {
    const {
      bookingsList,
      selectedBookings,
      currentFilterConfig,
      bookingListingActiveTab,
      bookingsFilter,
      setBookingsFilter,
      doUpdateBookingView,
      doAddBookingView,
      doDeleteBookingView,
      doDuplicateBookingView,
      bookingViews,
      defaultBookingViews,
      isBookingListingLoading,
    } = this.props;

    const { missingPaymentMethodBookings, bulkActionList, showBookingCalendar } = this.state;

    return (
      <div
        className="bg-white flex-1 width-full flex-column"
        style={{ overflowY: 'auto', position: 'relative' }}
        id="scroll"
      >
        <CreateNewBookingModal
          isOpen={this.state.showCreateBookingModal}
          history={this.props.history}
          closeCreateBookingModal={this._closeBookingModal}
        />
        <CreateNewActivityRecordModal
          isOpen={this.state.showCreateActivityRecordModal}
          onClose={this._onCloseCreateActivityRecord}
          history={this.props.history}
        />
        <RecurringBookingListModal
          history={this.props.history}
          isOpen={this.state.isRecurringListModalOpen}
          bookingItem={this.state.selectedBookingItem}
          closeRecurringBookingListModal={this._closeRecurringListModal}
          displayTimezone={this.state.displayTimezone}
        />
        <BulkApproveWarningModal
          isOpen={this.state.isBulkApprovalWarningModalOpen}
          missingPaymentMethodBookings={this.state.missingPaymentMethodBookings}
          onCloseModal={this._onCloseApproveWarningModal}
          hasApprovedBookings={
            missingPaymentMethodBookings &&
            bulkActionList &&
            missingPaymentMethodBookings.length < bulkActionList.length
          }
        />
        <CustomViewsModal
          isOpen={this.state.showBookingViewModal}
          onCloseViewModal={this._closeBookingViewModal}
          onUpdateViewModal={this._openBookingViewModal}
          type={this.state.viewModalType}
          pageViews={bookingViews}
          pageFilter={bookingsFilter}
          pageListingActiveTab={bookingListingActiveTab}
          setPageFilter={setBookingsFilter}
          doAddView={doAddBookingView}
          doDeleteView={doDeleteBookingView}
          doUpdateViewTab={doUpdateBookingView}
          doDuplicateView={doDuplicateBookingView}
          defaultFilterValue={defaultFilterValue}
          defaultViews={defaultBookingViews}
        />
        <CancelBulkBookingsModal
          isOpen={this.state.isCancelBookingOpen}
          onClose={this._onCloseCancelBookingModal}
          onCancel={this._onBulkCancelBookingListing}
          selectedBookings={this.state.selectedBookingList}
          amountItems={this.state.selectedBookingList.length}
        />
        <BookingBulkRemoveTeamMemberModal
          isOpen={this.state.isBookingBulkRemoveTeamMemberModalOpen}
          onClose={this._onCloseBookingBulkRemoveTeamMemberModal}
          bookingList={this.state.selectedBookingList}
          onRemove={this._onBulkRemoveTeamMemberBookingListing}
          {...this.props}
          {...this.state}
          isLoading={isBookingListingLoading}
        />
        {/*//region Header Section */}
        {/* Do NOT remove this container div. It's required for Safari sticky to work. Why it works, I have no idea.*/}
        <div>
          <div className="booking-header" ref={(com) => (this._headerElement = com)}>
            {/* Header */}
            <div className="flex-row justify-between align-center">
              <div className={'flex-row align-center'}>
                <IconButton
                  color={'white'}
                  size={'large'}
                  iconColor={'blue-action'}
                  className={'mr-medium'}
                  bordered={true}
                  onClick={this.props.hideFilterTabToggle}
                  icon={'layout'}
                />
                <Title level={3} className="mv-none" lineHeight={150}>
                  Bookings
                </Title>
                {/*<Text color="secondary">{currentFilterConfig.description}</Text>*/}
              </div>

              <div className="flex-row align-center">
                <div className="mr-medium">
                  <TimezoneSelector
                    mode={this.props.bookingDisplayTzMode}
                    bordered={false}
                    userTimezone={this.props.portalUser.timezone}
                    customTimezone={this.props.bookingDisplayTzCustom}
                    onChange={this._onChangeTimezone}
                  />
                </div>

                <div className="flex-column align-center">
                  <Tooltip2 content="Refresh this view" position="top">
                    <IconButton
                      color={'white'}
                      iconColor={'secondary'}
                      size={'large'}
                      bordered={true}
                      onClick={this._refreshListings}
                      icon="reload"
                    />
                  </Tooltip2>
                </div>
                <div className={'ml-medium align-center flex-row'}>
                  <PrimaryButton size={'large'} onClick={this._openBookingModal} icon="plus" className="rounded-left">
                    New booking
                  </PrimaryButton>
                  <div>
                    <Popover2
                      content={
                        <ActionMenu style={{ width: '230px' }}>
                          <ActionMenuItem text="New activity record" onClick={this._onOpenCreateActivityRecord} />
                        </ActionMenu>
                      }
                      position="top-right"
                    >
                      <IconButton className="rounded-right ml-x2-small" icon="down" size="large" />
                    </Popover2>
                  </div>
                </div>
              </div>
            </div>
            <BookListNavigationSection showFilterSection={this.props.showFilterSection} history={this.props.history} />
            {/*<div className={'mt-large'}>*/}
            {/*  {showBookingCalendar ? (*/}
            {/*    <>*/}
            {/*      <SecondaryButton className={'booking-listing-list-btn'} onClick={this._toggleShowBookingCalendar}>*/}
            {/*        List*/}
            {/*      </SecondaryButton>*/}
            {/*      <PrimaryButton className={'booking-listing-calendar-btn cursor-default'}>Calendar</PrimaryButton>*/}
            {/*    </>*/}
            {/*  ) : (*/}
            {/*    <>*/}
            {/*      <PrimaryButton className={'booking-listing-list-btn cursor-default'}>List</PrimaryButton>*/}
            {/*      <SecondaryButton className={'booking-listing-calendar-btn'} onClick={this._toggleShowBookingCalendar}>*/}
            {/*        Calendar*/}
            {/*      </SecondaryButton>*/}
            {/*    </>*/}
            {/*  )}*/}
            {/*</div>*/}

            <div className="flex-row align-center justify-start">
              <div className="ml-small">
                <FilterSection
                  availableFilters={availableFilters}
                  filters={this.props.bookingsFilter
                    .filter((filter) => !_.isEmpty(filter))
                    .map((filter) => {
                      if (_.isUndefined(filter.filter)) {
                        const [[key, value]] = Object.entries(filter);
                        return {
                          filter: key,
                          values: value || [],
                          selectionLabel: !_.isEmpty(value)
                            ? key === FilterType.DATE_RANGE
                              ? CommonUtils.getFilterText(FilterType.DATE_RANGE, [moment(value[0]), moment(value[1])])
                              : CommonUtils.getFilterText(key, value)
                            : CommonUtils.getFilterSettings(key).fullSelectionName,
                        };
                      }
                      return filter;
                    })}
                  onChangeFilter={this._onChangeFilter}
                  displayTimezone={this.state.displayTimezone}
                />
              </div>
              <div className="flex-row flex-grow justify-end">
                <SecondaryButton
                  className="text-color-blue-action mr-x-small ml-small"
                  onClick={() => this._onSaveView(bookingListingActiveTab)}
                >
                  <Icon className="ml-small mr-none" type="save" /> Save view
                </SecondaryButton>
              </div>
            </div>

            {/* TODO// add filters*/}
          </div>
          {showBookingCalendar ? (
            <BookingListingCalendar
              bookingsList={bookingsList}
              displayTimezone={this.state.displayTimezone}
              history={this.props.history}
            />
          ) : (
            <table className="booking-listing">
              <thead>
                <tr>
                  <th className="nowrap check-all pr-none" style={{ top: `${this.state.topHeight}px` }}>
                    <Checkbox
                      onClick={this._onCheckAll}
                      checked={this.state.checkAllIndicator}
                      indeterminate={this.state.indeterminateCheck}
                    />
                  </th>
                  <HeaderFieldItem customFields={currentFilterConfig.customFields} topHeight={this.state.topHeight} />
                </tr>
              </thead>
              <tbody>
                {!isBookingListingLoading && _.isEmpty(bookingsList) && (
                  <tr style={{ cursor: 'default' }}>
                    <td colSpan={7} style={{ borderBottom: '0px solid' }}>
                      <BookingEmptyState />
                    </td>
                  </tr>
                )}
                {isBookingListingLoading && (
                  <tr style={{ borderBottom: '0px solid !important' }}>
                    <td colSpan={7}>
                      <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                    </td>
                  </tr>
                )}
                <InfiniteScrollLoading
                  hasMore={bookingsList.length >= this.state.page * this.state.pageSize}
                  loadingElementId={'scroll'}
                  loadMore={this._fetchMoreBooking}
                  loaderColSpan={7}
                  loadingOffSet={60}
                >
                  {!_.isEmpty(bookingsList) &&
                    _.map(bookingsList, (bookingItem) => (
                      <BookingItemRow
                        timezone={this.state.displayTimezone}
                        showTimezoneIndicator={
                          !this.state.displayTimezone &&
                          moment.tz(bookingItem.timezone).format('Z') !==
                            moment.tz(this.props.portalUser.timezone).format('Z')
                        }
                        bookingItem={bookingItem}
                        // TODO :this is VERYYYY ugly and hacky... need to fix later.
                        // This is done in such a manner because there are two different arrays in booking listings.
                        // Need to consolidate to one array for this to work properly.
                        checked={
                          !_.isEmpty(_.find(selectedBookings, (b) => b.bookingId === bookingItem.bookingId)) &&
                          _.find(selectedBookings, (b) => b.bookingId === bookingItem.bookingId).selected
                        }
                        // bookingItem
                        onCheck={this._checkItem}
                        onClickRow={this._onClickRow}
                        customFilterFields={currentFilterConfig.customFields}
                        openRecurringListModal={this._openRecurringListModal}
                        setFilter={this._onChangeFilter}
                        key={bookingItem.bookingId}
                      />
                    ))}
                </InfiniteScrollLoading>
              </tbody>
            </table>
          )}
        </div>
        {/* Filler */}
        <div className="flex-1 bg-white">&nbsp;</div>
        {this.state.showActionSheet && (
          <BottomActionSheet
            selectedBookings={selectedBookings}
            onDeselect={this._onActionDeselect}
            refreshBookingListings={this._refreshListings}
            openBulkApproveWarningModal={this._openBulkApproveWarningModal}
            openBulkRemoveTeamMemberModal={this._onOpenBookingBulkRemoveTeamMemberModal}
            openCancelBookingModal={this._onOpenCancelBookingModal}
          />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  bookingDisplayTzCustom: state.bookingsStore.bookingDisplayTzCustom,
  bookingDisplayTzMode: state.bookingsStore.bookingDisplayTzMode,
  portalUser: state.authStore.portalUser,
  bookingListingActiveTab: state.bookingsStore.bookingListingActiveTab,
  displayedBookingListingTabs: state.bookingsStore.displayedBookingListingTabs,
  bookingViews: state.bookingsStore.bookingViews,
  isBookingListingLoading: state.bookingsStore.isBookingListingLoading,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setBookingDisplayTzMode: dispatch.bookingsStore.setBookingDisplayTzMode,
  setBookingDisplayTzCustom: dispatch.bookingsStore.setBookingDisplayTzCustom,
  doBulkRemoveTeamMembersBookingListing: dispatch.bookingsStore.doBulkRemoveTeamMembersBookingListing,
  doBulkCancelBookingsBookingListing: dispatch.bookingsStore.doBulkCancelBookingsBookingListing,
  doGetPortalUserDetail: dispatch.authStore.doGetPortalUserDetail,
  setHasBookingListingFilterChanged: dispatch.bookingsStore.setHasBookingListingFilterChanged,
  setCustomerBookingToOpen: dispatch.groupServiceStore.setCustomerBookingToOpen,
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys,
  setDisplayedBookingListingTabs: dispatch.bookingsStore.setDisplayedBookingListingTabs,
  doAddBookingView: dispatch.bookingsStore.doAddBookingView,
  doDuplicateBookingView: dispatch.bookingsStore.doDuplicateBookingView,
  doDeleteBookingView: dispatch.bookingsStore.doDeleteBookingView,
  doUpdateBookingView: dispatch.bookingsStore.doUpdateBookingView,
  doFetchBookingViews: dispatch.bookingsStore.doFetchBookingViews,
  setIsBookingListingLoading: dispatch.bookingsStore.setIsBookingListingLoading,
});

export default connect(
  mapState,
  mapDispatch,
)(BookingListingPanel);
