import React, { Component } from 'react';
import _ from 'lodash';
import {
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Popconfirm,
  Row,
  Select,
  Tag,
  Checkbox,
  Avatar,
  Radio,
} from 'antd';
import { ICustomer } from 'interfaces/customer-interfaces';
import SubSectionHeaderText from 'common-components/typography/SubSectionHeaderText';
import { FieldLabel, FieldValueText, Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import moment from 'moment-timezone';
import { tagColors } from 'theme/theme-variables';
import hobbies from 'variables/data/hobbies.json';
import religion from 'variables/data/religion.json';
import languages from 'variables/data/languages.json';
import { Icon } from 'antd/es';
import { FormComponentProps } from 'antd/es/form';
import CreateEditAddressModal from './CreateEditAddressModal';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import Map from './Map';
import globalConfig from 'variables/global-config';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import FormCol from 'common-components/forms/FormCol';
import supportLevel from 'variables/data/support-level.json';
import { LocationDisplay } from 'common-components/locations';
import CommonUtils from 'utilities/common-utils';
import { Tooltip2 } from '@blueprintjs/popover2';
import Script from 'react-load-script';
import { History } from 'history';
import { CustomerAlertType } from 'utilities/enum-utils';
import CustomerAlertTag from 'views/customers/details/components/CustomerAlert';

const Option = Select.Option;
const { TextArea } = Input;

interface IAlert {
  alertType: CustomerAlertType;
  numberOfAlerts: number;
  serviceAgreementIds: Array<any>;
}

interface ICustomerDetailsGeneralPanelProps extends FormComponentProps {
  history: History;
  selectedCustomer: ICustomer;
  doUpdateCustomerProfile: typeof dispatch.customersStore.doUpdateCustomerProfile;
  doFetchDisabilityList: typeof dispatch.customersStore.doFetchDisabilityList;
  doGetCountriesList: typeof dispatch.customersStore.doGetCountriesList;
  hasEditPermission: boolean;
  doCheckConflictWorkerForEditAddress: typeof dispatch.customersStore.doCheckConflictWorkerForEditAddress;
  doUpdateIgnoreAnAlert: typeof dispatch.customersStore.doUpdateIgnoreAnAlert;
  onRefresh: () => void;
}

interface ICustomerDetailsGeneralPanelState {
  lattitude: any;
  longitude: any;
  selectedAddressIndex: number;
  isEdit: boolean;
  selectedTransport: string;
  isopen: boolean;
  isCreateNewAddress: boolean;
  isActionModalOpen: boolean;
  isSaving: boolean;
  customerAddress: any;
  disabilities: any;
  isChangeBookingAddressModalOpen: boolean;
  isWorkerConflictModalOpen: boolean;
  isBookingAddressChanged: boolean;
  workerConflicts: any;
  customerAddressObj: any;
  selectedATSStatus: string;
  selectedRequiresTranslator: boolean;
  countries: any;
}

class CustomerDetailsGeneralPanel extends Component<
  ICustomerDetailsGeneralPanelProps,
  ICustomerDetailsGeneralPanelState
> {
  state = {
    lattitude: 0,
    longitude: 0,
    selectedAddressIndex: -1,
    isEdit: false,
    selectedTransport: '',
    isopen: false,
    isCreateNewAddress: false,
    isActionModalOpen: false,
    isSaving: false,
    customerAddress: [],
    disabilities: null,
    isChangeBookingAddressModalOpen: false,
    isWorkerConflictModalOpen: false,
    isBookingAddressChanged: false,
    workerConflicts: [],
    customerAddressObj: [],
    selectedATSStatus: undefined,
    selectedRequiresTranslator: undefined,
    countries: [],
  };

  private _deleteAddress = (index) => {
    const customerAddress = [...this.state.customerAddress];
    if (index !== -1) {
      customerAddress.splice(index, 1);

      if (!_.find(customerAddress, (address) => address.isPrimary)) {
        customerAddress[0].isPrimary = true;
      }

      this.setState({ customerAddress });
    }
  };

  private _renderSupportLevel = () => {
    const { selectedCustomer } = this.props;

    let selectedSupportLevel = _.find(
      supportLevel,
      (data) => data.customerSupportLevel === selectedCustomer.customerSupportLevel,
    );

    if (!selectedSupportLevel) {
      selectedSupportLevel = { customerSupportLevel: null, value: 'Not Set' };
    }

    return (
      <Tag
        color={
          selectedSupportLevel.customerSupportLevel === '1'
            ? tagColors.BLUE
            : selectedSupportLevel.customerSupportLevel === null
            ? tagColors.ORANGE
            : tagColors.RED
        }
        className={'mb-small'}
        key={selectedSupportLevel.value}
      >
        <Text color="white" weight="bold" size="large">
          {selectedSupportLevel.value}
        </Text>
      </Tag>
    );
  };

  private _changePrimaryAddress = (addressIndex) => {
    const { customerAddress } = this.state;

    let selectedAddress;
    const newCustomerAddress = _.map(customerAddress, (address, index) => {
      if (index === addressIndex) {
        selectedAddress = address;
        return { ...address, isPrimary: true };
      } else return { ...address, isPrimary: false };
    });

    selectedAddress.isPrimary = true;
    selectedAddress.geoLat = parseFloat(selectedAddress.geoLat);
    selectedAddress.geoLng = parseFloat(selectedAddress.geoLng);

    this._onOpenChangeBookingAddressModel(selectedAddress);
    this.setState({
      customerAddress: newCustomerAddress,
      selectedAddressIndex: 0,
      customerAddressObj: selectedAddress,
    });
  };

  private _saveCustomerAddress = async (addressLocation) => {
    let customerAddressObj;

    if (addressLocation.isManual) {
      const streetAddress1 = addressLocation.streetaddress ? addressLocation.streetaddress : '';
      const streetAddress2 = addressLocation.streetaddress2 ? addressLocation.streetaddress2 : '';
      const fulladdress =
        streetAddress1 +
        ' ' +
        streetAddress2 +
        ' , ' +
        addressLocation.suburb +
        ' , ' +
        addressLocation.state +
        ' , ' +
        addressLocation.country;
      const latlong = await CommonUtils.getLatLongByAddress(fulladdress);
      customerAddressObj = {
        state: addressLocation.state,
        geoLat: latlong.lat,
        geoLng: latlong.lng,
        country: addressLocation.country,
        locality: addressLocation.suburb,
        postcode: addressLocation.postcode,
        isPrimary: addressLocation.isPrimary !== undefined ? addressLocation.isPrimary : false,
        fullAddress: CommonUtils.formatFullAddress({
          streetAddress1: addressLocation.streetaddress,
          streetAddress2: addressLocation.streetaddress2,
          locality: addressLocation.suburb,
          state: addressLocation.state,
          postcode: addressLocation.postcode,
          country: addressLocation.country,
        }),
        streetAddress1: addressLocation.streetaddress,
        streetAddress2: addressLocation.streetaddress2,
      };
    } else {
      const postal_code = _.find(addressLocation.address_components, (address) => {
        return address.types[0] === 'postal_code';
      });

      const country = _.find(addressLocation.address_components, (address) => {
        return address.types[0] === 'country';
      });

      const province = _.find(addressLocation.address_components, (address) => {
        return address.types[0] === 'administrative_area_level_1';
      });

      const locality = _.find(addressLocation.address_components, (address) => {
        return address.types[0] === 'locality';
      });

      const street_number = _.find(addressLocation.address_components, (address) => {
        return address.types[0] === 'street_number';
      });

      const route = _.find(addressLocation.address_components, (address) => {
        return address.types[0] === 'route';
      });

      const subpremise = _.find(addressLocation.address_components, (address) => {
        return address.types[0] === 'subpremise';
      });

      const streetAddress1 = route
        ? `${subpremise ? subpremise.long_name.replace(/\D/g, '') + '/' : ''}${
            street_number ? street_number.long_name : ''
          } ${route.long_name}`
        : '';

      customerAddressObj = {
        state: province !== undefined ? province.short_name : '',
        geoLat: addressLocation.selectedLattitude,
        geoLng: addressLocation.selectedLongitude,
        country: country !== undefined ? country.long_name : '',
        locality: locality !== undefined ? locality.long_name : '',
        postcode: postal_code !== undefined ? postal_code.long_name : '',
        isPrimary: addressLocation.isPrimary !== undefined ? addressLocation.isPrimary : false,
        fullAddress: CommonUtils.formatFullAddress({
          streetAddress1,
          streetAddress2: '',
          locality: locality !== undefined ? locality.long_name : '',
          state: province !== undefined ? province.short_name : '',
          postcode: postal_code !== undefined ? postal_code.long_name : '',
          country: country !== undefined ? country.long_name : '',
        }),
        streetAddress1,
        streetAddress2: '',
      };
    }

    if (this.state.selectedAddressIndex >= 0) {
      if (customerAddressObj.isPrimary) {
        this._onOpenChangeBookingAddressModel(customerAddressObj);
        this.setState({ customerAddressObj: customerAddressObj });
      } else {
        this.state.customerAddress[this.state.selectedAddressIndex] = customerAddressObj;
      }
    } else {
      if (customerAddressObj.isPrimary) {
        this._onOpenChangeBookingAddressModel(customerAddressObj);
        this.setState({ customerAddressObj: customerAddressObj });
      } else {
        const joined = this.state.customerAddress.concat(customerAddressObj);

        this.setState({ customerAddress: joined });
      }
    }
  };

  private _onClickSave = async () => {
    const { form, selectedCustomer, doUpdateCustomerProfile } = this.props;

    let isFormValid = true;

    form.validateFields(async (err) => {
      if (err) {
        console.log(err);
        isFormValid = false;
      }
    });

    // check address, make sure all address have required field.
    const failedAddress = _.filter(
      this.state.customerAddress,
      (address) =>
        !address.streetAddress1 || !address.state || !address.postcode || !address.fullAddress || !address.locality,
    );

    if (!_.isEmpty(failedAddress)) {
      isFormValid = false;
      notification.error({
        message: 'Empty Address',
        description: 'Seems there is empty address, please edit or delete it.',
      });
    }

    if (isFormValid) {
      const dateOfBirth = form.getFieldValue('dateOfBirth');
      const dob = dateOfBirth ? moment(dateOfBirth).format('YYYY-MM-DD') : null;
      const payload = {
        userId: selectedCustomer.userId,
        aboutMe: form.getFieldValue('aboutMe'),
        dateOfBirth: dob,
        gender: form.getFieldValue('gender'),
        homePhoneCountryCode: form.getFieldValue('homePhoneCountryCode'),
        homePhoneNumber: form.getFieldValue('homePhoneNumber'),
        householdComposition: form.getFieldValue('householdComposition'),
        highestLevelOfEducation: form.getFieldValue('highestLevelOfEducation'),
        transportArrangements: form.getFieldValue('transportArrangements'),
        transportCompanyName: form.getFieldValue('transportCompanyName'),
        transportContactName: form.getFieldValue('transportContactName'),
        transportContactPhoneCountryCode: form.getFieldValue('transportContactPhoneCountryCode'),
        transportContactPhoneNumber: form.getFieldValue('transportContactPhoneNumber'),
        addresses: this.state.customerAddress,
        hobbies: form.getFieldValue('hobbies'),
        disabilities: form.getFieldValue('disabilities'),
        religions: form.getFieldValue('religions') ? [form.getFieldValue('religions')] : [],
        languages: form.getFieldValue('languages'),
        email: form.getFieldValue('email'),
        isWorkerSelectable: form.getFieldValue('teamSelect'),
        customerSupportLevel: form.getFieldValue('customerSupportLevel'),
        firstName: form.getFieldValue('firstName'),
        lastName: form.getFieldValue('lastName'),
        mobileCountryCode: form.getFieldValue('mobileCountryCode'),
        mobile: form.getFieldValue('mobile'),
        customerNumber: form.getFieldValue('customerNumber'),
        isBookingAddressChanged: this.state.isBookingAddressChanged,
        countryOfBirth: form.getFieldValue('countryOfBirth') ? form.getFieldValue('countryOfBirth') : null,
        straitsLanderStatus: form.getFieldValue('straitsLanderStatus')
          ? form.getFieldValue('straitsLanderStatus')
          : null,
        medicareNumber: form.getFieldValue('medicareNumber') ? form.getFieldValue('medicareNumber') : null,
        ambulanceNumber: form.getFieldValue('ambulanceNumber') ? form.getFieldValue('ambulanceNumber') : null,
        companionCard: form.getFieldValue('companionCard') ? form.getFieldValue('companionCard') : null,
        centrelinkNumber: form.getFieldValue('centrelinkNumber') ? form.getFieldValue('centrelinkNumber') : null,
        livingArrangements: form.getFieldValue('livingArrangements') ? form.getFieldValue('livingArrangements') : null,
        requiresTranslator: form.getFieldValue('requiresTranslator'),
        requiresTranslatorDetails: form.getFieldValue('requiresTranslator')
          ? form.getFieldValue('requiresTranslatorDetails')
          : null,
        lga: form.getFieldValue('lga') ? form.getFieldValue('lga') : null,
      };
      this.setState({ isSaving: true });
      try {
        await doUpdateCustomerProfile(payload);
        this.setState({ isSaving: false, isEdit: false });
      } catch (e) {
        notification.error({ message: 'Error', description: e.message });
        this.setState({ isSaving: false });
      }
    }
  };

  private _closeActionModal = () => {
    this.setState({ isActionModalOpen: false });
  };

  private _proceedDiscardChanges = () => {
    const { selectedCustomer } = this.props;

    this.setState({
      isActionModalOpen: false,
      isEdit: false,
      selectedRequiresTranslator: selectedCustomer.requiresTranslator,
      selectedATSStatus: selectedCustomer.straitsLanderStatus ? selectedCustomer.straitsLanderStatus : null,
    });
  };

  private _closeWithActionModal = () => {
    const { form, selectedCustomer } = this.props;

    let hasUnsavedChanges = false;

    if (selectedCustomer.isCrm) {
      let aboutMe = form.getFieldValue('aboutMe');
      let customerNumber = form.getFieldValue('customerNumber');
      let dateOfBirth = form.getFieldValue('dateOfBirth');
      let email = form.getFieldValue('email');
      let languages = form.getFieldValue('languages');
      let religions = [form.getFieldValue('religions')];
      let hobbies = form.getFieldValue('hobbies');
      let disabilities = form.getFieldValue('disabilities');
      let gender = form.getFieldValue('gender');
      let teamSelect = form.getFieldValue('teamSelect');
      const countryOfBirth = form.getFieldValue('countryOfBirth');
      const straitsLanderStatus = form.getFieldValue('straitsLanderStatus');
      const medicareNumber = form.getFieldValue('medicareNumber');
      const ambulanceNumber = form.getFieldValue('ambulanceNumber');
      const companionCard = form.getFieldValue('companionCard');
      const centrelinkNumber = form.getFieldValue('centrelinkNumber');
      const livingArrangements = form.getFieldValue('livingArrangements');
      const requiresTranslator = form.getFieldValue('requiresTranslator');
      const requiresTranslatorDetails = form.getFieldValue('requiresTranslatorDetails');
      const lga = form.getFieldValue('lga');
      let firstName = selectedCustomer.isCrm ? form.getFieldValue('firstName') : null;
      let lastName = selectedCustomer.isCrm ? form.getFieldValue('lastName') : null;
      let mobile = selectedCustomer.isCrm ? form.getFieldValue('mobile') : null;
      let mobileCountryCode = selectedCustomer.isCrm ? form.getFieldValue('mobileCountryCode') : null;

      const cklang =
        selectedCustomer.languages.length === languages.length &&
        selectedCustomer.languages.sort().every(function(value, index) {
          return value === languages.sort()[index];
        });

      const chkreligions =
        selectedCustomer.religions.length === religions.length &&
        selectedCustomer.religions.sort().every(function(value, index) {
          return value === religions.sort()[index];
        });

      const chkhobbies =
        selectedCustomer.hobbies.length === hobbies.length &&
        selectedCustomer.hobbies.sort().every(function(value, index) {
          return value === hobbies.sort()[index];
        });

      const chkdisabilities =
        selectedCustomer.disabilities.length === disabilities.length &&
        selectedCustomer.disabilities.sort().every(function(value, index) {
          return value === disabilities.sort()[index];
        });

      const chkdate =
        (!selectedCustomer.dateOfBirth && !dateOfBirth) ||
        moment(selectedCustomer.dateOfBirth, 'YYYY-MM-DD').isSame(moment(dateOfBirth, 'YYYY-MM-DD'));

      hasUnsavedChanges =
        this.state.isEdit &&
        (selectedCustomer.aboutMe !== aboutMe ||
          !chkdate ||
          selectedCustomer.email !== email ||
          !cklang ||
          !chkreligions ||
          !chkhobbies ||
          !chkdisabilities ||
          selectedCustomer.gender !== gender ||
          selectedCustomer.isWorkerSelectable !== teamSelect ||
          selectedCustomer.firstName !== firstName ||
          selectedCustomer.lastName !== lastName ||
          selectedCustomer.mobile !== mobile ||
          selectedCustomer.customerNumber !== customerNumber ||
          selectedCustomer.mobileCountryCode !== mobileCountryCode ||
          selectedCustomer.countryOfBirth !== countryOfBirth ||
          selectedCustomer.straitsLanderStatus !== straitsLanderStatus ||
          selectedCustomer.medicareNumber !== medicareNumber ||
          selectedCustomer.ambulanceNumber !== ambulanceNumber ||
          selectedCustomer.companionCard !== companionCard ||
          selectedCustomer.centrelinkNumber !== centrelinkNumber ||
          selectedCustomer.livingArrangements !== livingArrangements ||
          selectedCustomer.requiresTranslator !== requiresTranslator ||
          selectedCustomer.requiresTranslatorDetails !== requiresTranslatorDetails ||
          selectedCustomer.lga !== lga);
    } else {
      const teamSelect = form.getFieldValue('teamSelect');
      const customerSupportLevel = form.getFieldValue('customerSupportLevel');
      const customerNumber = form.getFieldValue('customerNumber');

      hasUnsavedChanges =
        this.state.isEdit &&
        (selectedCustomer.isWorkerSelectable !== teamSelect ||
          selectedCustomer.customerSupportLevel !== customerSupportLevel ||
          selectedCustomer.customerNumber !== customerNumber);
    }

    if (hasUnsavedChanges) {
      this.setState({ isActionModalOpen: true });
    } else {
      this.setState({ isActionModalOpen: false, isEdit: false });
    }
  };

  private _closeCreateEditAddressModal = () => {
    this.setState({ isopen: false, isCreateNewAddress: true });
  };

  private _openCreateEditAddressModal = (addressId) => {
    addressId = parseInt(addressId);
    if (addressId >= 0) {
      this.setState({ isCreateNewAddress: false, selectedAddressIndex: addressId });
    } else {
      this.setState({ isCreateNewAddress: true, selectedAddressIndex: -1 });
    }
    this.setState({ isopen: true });
  };

  async componentDidMount() {
    const { selectedCustomer, doFetchDisabilityList } = this.props;
    const addresses = selectedCustomer.addresses.sort((a, b) => b.isPrimary - a.isPrimary);
    this.setState({
      selectedTransport: selectedCustomer.transportArrangements,
      customerAddress: addresses,
      selectedRequiresTranslator: selectedCustomer.requiresTranslator,
      selectedATSStatus: selectedCustomer.straitsLanderStatus,
    });

    const disabilities: any = await doFetchDisabilityList({});
    this.setState({ disabilities });

    if (addresses.length > 0) {
      const primaryAddress = _.find(addresses, (address) => {
        return address.isPrimary;
      });

      const primaryIndex = _.findIndex(addresses, (address) => {
        return address.isPrimary;
      });
      if (primaryAddress !== undefined) {
        this.setState({
          lattitude: primaryAddress.geoLat,
          longitude: primaryAddress.geoLng,
          selectedAddressIndex: primaryIndex,
        });
      }
    }
  }

  private _renderHobbies = () => {
    const { selectedCustomer } = this.props;

    const list = _.map(selectedCustomer.hobbies, (tag) => {
      return hobbies.find((item) => item.tagId === tag);
    });

    return (
      <div>
        {_.map(list, (item) => {
          return (
            <Tag color={tagColors.GREEN} className={'mb-small'} key={item.tagId}>
              <Text color="white" weight="bold" size="large">
                {item.tag}
              </Text>
            </Tag>
          );
        })}
      </div>
    );
  };

  private _renderDisability = () => {
    const { selectedCustomer } = this.props;
    const { disabilities } = this.state;

    const list = _.map(selectedCustomer.disabilities, (tag) => {
      return disabilities.find((item) => item.tagId === tag);
    });

    return (
      <div>
        {_.map(list, (item) => {
          return (
            <Tag color={tagColors.BLUE} className={'mb-small'} key={item && item.tagId}>
              <Text color="white" weight="bold" size="large">
                {item && item.disabilityName}
              </Text>
            </Tag>
          );
        })}
      </div>
    );
  };

  private _renderReligion = () => {
    const { selectedCustomer } = this.props;

    const list = _.map(selectedCustomer.religions, (tag) => {
      return religion.find((item) => item.tagId === tag);
    });

    return (
      <div>
        {_.map(list, (item) => {
          return (
            <Tag color={tagColors.GOLD} className={'mb-small'} key={item.tagId}>
              <Text color="white" weight="bold" size="large">
                {item.tag}
              </Text>
            </Tag>
          );
        })}
      </div>
    );
  };

  private _setLatLong = (lattitude, longitude, index) => {
    // Don't show the map if the lattitude or the longitude is empty
    if (lattitude && longitude) {
      this.setState({ lattitude, longitude, selectedAddressIndex: index });
    }
  };

  private _renderAddresses = () => {
    const addresses = this.state.customerAddress;
    if (addresses.length > 0) {
      return (
        <div>
          {_.map(addresses, (address, index) => {
            const selectedColor = this.state.selectedAddressIndex === index ? 'green' : null;
            return (
              <React.Fragment key={index}>
                <Row type={'flex'} onClick={() => this._setLatLong(address.geoLat, address.geoLng, index)}>
                  <Col span={1}>
                    <Icon
                      type={'environment'}
                      className={
                        this.state.selectedAddressIndex === index
                          ? 'mr-small text-size-x3-large'
                          : !address.fullAddress
                          ? 'mr-small text-size-x3-large text-color-tertiary'
                          : 'mr-small text-size-x3-large cursor-pointer'
                      }
                      theme={this.state.selectedAddressIndex === index ? 'filled' : null}
                      style={{ color: selectedColor }}
                    />
                  </Col>
                  <Col className={'mr-small ml-small'}>
                    {address.isPrimary ? (
                      <Text type={'secondary'}> Primary Address</Text>
                    ) : (
                      <Text type={'secondary'}> Other Address</Text>
                    )}
                  </Col>
                </Row>
                <Row type={'flex'}>
                  <Col span={1} />
                  <Col className={'ml-small'}>
                    <pre>{address.fullAddress ? address.fullAddress : <Text color={'secondary'}>Not set</Text>}</pre>
                  </Col>
                </Row>
              </React.Fragment>
            );
          })}
        </div>
      );
    } else {
      return <div>No Address found.</div>;
    }
  };

  private _renderEditAddresses = () => {
    const addresses = this.state.customerAddress;
    addresses.sort((a, b) => b.isPrimary - a.isPrimary);
    return (
      <div>
        {_.map(addresses, (address, index) => {
          return (
            <React.Fragment key={index}>
              <Row type={'flex'}>
                <Col>
                  <Checkbox checked={address.isPrimary} onChange={() => this._changePrimaryAddress(index)}>
                    {address.isPrimary ? 'Primary address' : 'Mark as primary address'}
                  </Checkbox>
                </Col>
              </Row>
              <Row type={'flex'}>
                <Col>
                  <LocationDisplay location={address.fullAddress} />
                </Col>
              </Row>
              <Row type={'flex'} justify={'start'} gutter={16}>
                <Col>
                  <GhostButton icon={'edit'} onClick={() => this._openCreateEditAddressModal(index)}>
                    Edit
                  </GhostButton>
                </Col>
                {!address.isPrimary ? (
                  <Col>
                    <Popconfirm
                      title={'This address will be deleted. Do you want to proceed?'}
                      onConfirm={() => this._deleteAddress(index)}
                      okText={'Yes'}
                    >
                      <GhostButton icon={'delete'}>Delete</GhostButton>
                    </Popconfirm>
                  </Col>
                ) : null}
              </Row>
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  private _renderLanguage = () => {
    const { selectedCustomer } = this.props;

    const list = _.map(selectedCustomer.languages, (tag) => {
      return languages.find((item) => item.tagId === tag);
    });

    return (
      <div>
        {_.map(list, (item) => {
          return (
            <Tag color={tagColors.LIME} className={'mb-small'} key={item.tagId}>
              <Text color="white" weight="bold" size="large">
                {item.tag}
              </Text>
            </Tag>
          );
        })}
      </div>
    );
  };

  private _showEditForm = async () => {
    const countries = await this.props.doGetCountriesList();
    this.setState({ isEdit: true, countries });
  };

  private _validateMobileNumber = (rule, value, callback) => {
    const region = 'AU';
    const phoneNumber = parsePhoneNumberFromString(value, region);
    try {
      if (value !== '' && !phoneNumber.isValid()) {
        throw new Error('Invalid Phone Number');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  private _onOpenChangeBookingAddressModel = async (address) => {
    const { doCheckConflictWorkerForEditAddress, selectedCustomer } = this.props;
    const result: any = await doCheckConflictWorkerForEditAddress({
      userId: selectedCustomer.userId,
      address: address,
    });

    this.setState({ isChangeBookingAddressModalOpen: true, workerConflicts: result.data });
  };

  private _onCloseChangeBookingAddressModel = () => {
    this.setState({ isChangeBookingAddressModalOpen: false });
    this._openCreateEditAddressModal('');
  };

  private _onProceedChangeBookingAddressModel = () => {
    this.setState({
      isChangeBookingAddressModalOpen: false,
      isBookingAddressChanged: true,
      isWorkerConflictModalOpen: this.state.workerConflicts.length !== 0,
    });
    if (this.state.workerConflicts.length === 0) {
      this._savePrimaryAddress();
    }
  };

  private _onProceedDoNotChangeBookingAddressModel = () => {
    this.setState({
      isChangeBookingAddressModalOpen: false,
      isBookingAddressChanged: false,
      isWorkerConflictModalOpen: false,
    });
    this._savePrimaryAddress();
  };

  private _onCloseWorkerConflictModel = () => {
    this.setState({
      isChangeBookingAddressModalOpen: false,
      isWorkerConflictModalOpen: false,
      workerConflicts: [],
      isBookingAddressChanged: false,
    });
  };

  private _onConfirmRemoveConflictModel = () => {
    this.setState({
      isBookingAddressChanged: true,
      isWorkerConflictModalOpen: false,
    });
    this._savePrimaryAddress();
  };

  private _savePrimaryAddress = () => {
    const { customerAddressObj } = this.state;
    if (this.state.selectedAddressIndex >= 0) {
      const allAddress = _.map(this.state.customerAddress, (address) => {
        address.isPrimary = false;
        return address;
      });
      allAddress[this.state.selectedAddressIndex] = customerAddressObj;

      this.setState({ customerAddress: allAddress });
    } else {
      const addresses = _.map(this.state.customerAddress, (address) => {
        address.isPrimary = false;
        return address;
      });
      this.setState({
        customerAddress: addresses,
      });

      const joined = this.state.customerAddress.concat(customerAddressObj);

      this.setState({ customerAddress: joined });
    }
  };

  private _onAlertTagView = (alert) => {
    const { alertType, serviceAgreementIds } = alert;
    if (alertType === CustomerAlertType.DocumentExpired || alertType === CustomerAlertType.DocumentExpiringSoon) {
      this._goToSelectedTab('documents');
      return;
    }
    this._goToSelectedTab('new_service_agreements', serviceAgreementIds[0]);
  };

  private _ignoreAnAlert = async (alert) => {
    const { alertType } = alert;
    const { selectedCustomer } = this.props;
    try {
      const payload = {
        alertType,
        customerUserId: selectedCustomer.userId,
      };
      await this.props.doUpdateIgnoreAnAlert(payload);
      await this.props.onRefresh();
      notification.success({ message: 'Alert ignored.' });
    } catch (error) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
  };

  private _goToSelectedTab = (tab: string = 'generalinfo', serviceAgreementId?: string) => {
    const { selectedCustomer } = this.props;
    const selectedCustomerId = selectedCustomer.userId;
    let historyState: Object = { refresh: true, selectedTab: tab };
    if (serviceAgreementId) historyState = { ...historyState, openedServiceAgreementId: serviceAgreementId };

    this.props.history.push(`/customer/details/${selectedCustomerId}`, historyState);
  };

  private _onChangeSelectedATSStatus = (event) => {
    this.setState({ selectedATSStatus: event.target.value });
  };

  private _onChangeSelectedRequiresTranslator = (event) => {
    this.setState({ selectedRequiresTranslator: event.target.value });
  };

  render() {
    const { selectedCustomer, hasEditPermission, form } = this.props;
    const { disabilities, workerConflicts } = this.state;
    const { getFieldDecorator } = form;
    const lattitude = Number(this.state.lattitude);
    const longitude = Number(this.state.longitude);
    const isCRMRecord = selectedCustomer.isCrm;
    const hasAlert = selectedCustomer.customerAlerts && selectedCustomer.customerAlerts.length > 0;

    if (!_.isEmpty(selectedCustomer) && !this.state.isEdit) {
      return (
        <div className={'x2-large'}>
          <Row type={'flex'} justify={'space-between'} align={'bottom'}>
            <Col>
              <Title level={2} className={'m-none'}>
                Customer General Information
              </Title>
              <Text type={'secondary'}>General information about this customer.</Text>
            </Col>
            <Col className={'align-center'}>
              <div className={'flex-row-reverse align-center'}>
                {isCRMRecord && hasEditPermission && (
                  <PrimaryButton icon={'edit'} onClick={this._showEditForm}>
                    Edit Information
                  </PrimaryButton>
                )}
              </div>
            </Col>
          </Row>
          {hasAlert ? (
            <Row type={'flex'} className={`mt-x-large`}>
              <Col span={24}>
                <Row className="mb-medium">
                  <Col span={12}>
                    <FieldLabel text={'Alerts'} />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    {selectedCustomer.customerAlerts.map((alert) => (
                      <CustomerAlertTag
                        className="mr-medium mb-medium"
                        key={alert.alertType}
                        alertType={alert.alertType}
                        numberOfAlerts={alert.numberOfAlerts}
                        onClickIgnore={() => this._ignoreAnAlert(alert)}
                        onClickView={() => this._onAlertTagView(alert)}
                      />
                    ))}
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <div className={`mt-x-large ${!isCRMRecord && 'bordered p-medium rounded'}`}>
            {!isCRMRecord && (
              <Row className="mb-medium flex-row align-center">
                <Col span={12}>
                  <Title level={4}>
                    Business Data{' '}
                    <Tooltip2 position="top" content="Data own by the company, they can be changed at any time.">
                      <Icon type="question-circle" theme="filled" className="text-color-blue text-size-large" />
                    </Tooltip2>
                  </Title>
                </Col>
                <Col span={12} className="text-align-right">
                  {hasEditPermission && (
                    <PrimaryButton icon={'edit'} onClick={this._showEditForm}>
                      Edit Information
                    </PrimaryButton>
                  )}
                </Col>
              </Row>
            )}
            <div className="">
              <Row gutter={24}>
                <Col span={24}>
                  <FieldLabel text={'Team Member selection'} />
                  {selectedCustomer.isWorkerSelectable ? (
                    <Text>
                      <Icon type="check" className="mr-x-small text-color-green" />{' '}
                      <b style={{ color: 'green' }}>Allowed</b> to select an available Team Member when book a Service
                    </Text>
                  ) : (
                    <Text>
                      <Icon type="close" className="mr-x-small text-color-red" />{' '}
                      <b style={{ color: 'red' }}>Not allowed</b> to select an available Team Member when book a Service
                    </Text>
                  )}
                </Col>
              </Row>
            </div>
            <div className="mt-x-large">
              <Row gutter={24}>
                <Col span={12}>
                  <FieldLabel text={'Support Level'} />
                  {this._renderSupportLevel()}
                </Col>
                <Col span={12}>
                  <FieldLabel text={'Customer ID'} />
                  {selectedCustomer.customerNumber ? (
                    selectedCustomer.customerNumber
                  ) : (
                    <Text color={'secondary'}>Not set.</Text>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <div className={`mt-x-large ${!isCRMRecord && 'bordered p-medium rounded'}`}>
            {!isCRMRecord && (
              <Row className="mb-medium flex-row align-center">
                <Col span={24}>
                  <Title level={4}>
                    Customer Data{' '}
                    <Tooltip2
                      position="top"
                      content="This account being connected, only the customer can edit those data in their profil on the GoodHuman app."
                    >
                      <Icon type="question-circle" theme="filled" className="text-color-blue text-size-large" />
                    </Tooltip2>
                  </Title>
                </Col>
              </Row>
            )}
            <div>
              <Row gutter={24}>
                <Col span={12}>
                  <FieldLabel text={'About'} />
                  <FieldValueText
                    text={selectedCustomer.aboutMe ? selectedCustomer.aboutMe : 'Not Set'}
                    style={{ whiteSpace: 'pre-line' }}
                  />
                </Col>
              </Row>
            </div>
            <div className="mt-x-large">
              <Row gutter={24}>
                <Col span={12}>
                  <FieldLabel text={'Contact Number'} />
                  <SubSectionHeaderText text={'Mobile Number'} />
                  <FieldValueText
                    text={
                      selectedCustomer.mobile
                        ? parsePhoneNumberFromString(
                            selectedCustomer.mobile,
                            selectedCustomer.mobileCountryCode,
                          ).format('INTERNATIONAL')
                        : 'Not Set'
                    }
                  />
                  <SubSectionHeaderText text={'Home Number'} />
                  <FieldValueText
                    text={
                      selectedCustomer.homePhoneNumber
                        ? parsePhoneNumberFromString(
                            selectedCustomer.homePhoneNumber,
                            selectedCustomer.homePhoneCountryCode,
                          ).format('INTERNATIONAL')
                        : 'Not Set'
                    }
                  />
                </Col>
                <Col span={12}>
                  <FieldLabel text={'Date of Birth'} />
                  <FieldValueText
                    text={
                      selectedCustomer.dateOfBirth !== null
                        ? moment(selectedCustomer.dateOfBirth, 'YYYY-MM-DD').format('DD MMMM YYYY')
                        : 'Not Set'
                    }
                  />
                </Col>
              </Row>
            </div>
            <div className="mt-x-large">
              <Row gutter={24}>
                <Col span={12}>
                  <FieldLabel text={'Email'} />
                  <FieldValueText text={selectedCustomer.email ? selectedCustomer.email : 'Not Set'} />
                </Col>
                <Col span={12}>
                  <FieldLabel text={'Gender'} />
                  <FieldValueText text={selectedCustomer.gender ? selectedCustomer.gender : 'Not Set'} />
                </Col>
              </Row>
            </div>
            <div className="mt-x-large">
              <Row gutter={24}>
                <Col span={12}>
                  <FieldLabel text={'Addresses'} />
                  <div className={'mb-small'}>{this._renderAddresses()}</div>
                  <Row gutter={24} className={'mt-medium'}>
                    <Col span={12}>
                      <FieldLabel text={'LGA'} />
                      <FieldValueText text={selectedCustomer.lga ? selectedCustomer.lga : 'Not Set'} />
                    </Col>
                  </Row>
                </Col>
                <Col span={12}>
                  {lattitude !== 0 && longitude !== 0 && (
                    <div>
                      <Map lattitude={lattitude} longitude={longitude} />
                    </div>
                  )}
                </Col>
              </Row>
            </div>
            <div className="mt-x-large">
              <Row gutter={24} className={'mt-medium'}>
                <Col span={12}>
                  <FieldLabel text={'Permanent conditions'} />
                  <div>
                    {disabilities && selectedCustomer.disabilities.length > 0 ? this._renderDisability() : 'Not Set'}
                  </div>
                </Col>
                <Col span={12}>
                  <SubTitle>Hobbies</SubTitle>
                  <div>{selectedCustomer.hobbies.length > 0 ? this._renderHobbies() : 'Not Set'}</div>
                </Col>
              </Row>
            </div>
            <div className="mt-x-large">
              <Row gutter={24} className={'mt-medium'}>
                <Col span={12}>
                  <FieldLabel text={'Religion'} />
                  <div>{selectedCustomer.religions.length > 0 ? this._renderReligion() : 'Not Set'}</div>
                </Col>
                <Col span={12}>
                  <FieldLabel text={'Languages'} />
                  <div>{selectedCustomer.languages.length > 0 ? this._renderLanguage() : 'Not Set'}</div>
                </Col>
              </Row>
            </div>
            <div className="mt-x-large">
              <Row gutter={24} className={'mt-medium'}>
                <Col span={12}>
                  <FieldLabel text={'Country of birth'} />
                  <FieldValueText
                    text={selectedCustomer.countryOfBirth ? selectedCustomer.countryOfBirth : 'Not Set'}
                  />
                </Col>
                <Col span={12}>
                  <FieldLabel text={'Aboriginal or Torres Strait islander status'} />
                  <FieldValueText
                    text={
                      selectedCustomer.straitsLanderStatus === 'yes'
                        ? 'Yes'
                        : selectedCustomer.straitsLanderStatus === 'no'
                        ? 'No'
                        : selectedCustomer.straitsLanderStatus === 'preferNotToSay'
                        ? 'Prefer not to say'
                        : 'Not Set'
                    }
                  />
                </Col>
              </Row>
            </div>
            <div className="mt-x-large">
              <Row gutter={24} className={'mt-medium'}>
                <Col span={12}>
                  <FieldLabel text={'Medicare number'} />
                  <div>{selectedCustomer.medicareNumber ? selectedCustomer.medicareNumber : 'Not Set'}</div>
                </Col>
                <Col span={12}>
                  <FieldLabel text={'Ambulance number'} />
                  <div>{selectedCustomer.ambulanceNumber ? selectedCustomer.ambulanceNumber : 'Not Set'}</div>
                </Col>
              </Row>
            </div>
            <div className="mt-x-large">
              <Row gutter={24} className={'mt-medium'}>
                <Col span={12}>
                  <FieldLabel text={'Companion card'} />
                  <div>{selectedCustomer.companionCard ? selectedCustomer.companionCard : 'Not Set'}</div>
                </Col>
                <Col span={12}>
                  <FieldLabel text={'Centrelink number'} />
                  <div>{selectedCustomer.centrelinkNumber ? selectedCustomer.centrelinkNumber : 'Not Set'}</div>
                </Col>
              </Row>
            </div>
            <div className="mt-x-large">
              <Row gutter={24} className={'mt-medium'}>
                <Col span={12}>
                  <FieldLabel text={'Living arrangements'} />
                  <FieldValueText
                    text={selectedCustomer.livingArrangements ? selectedCustomer.livingArrangements : 'Not Set'}
                  />
                </Col>
                <Col span={12}>
                  <FieldLabel text={'Requires translator'} />
                  <FieldValueText
                    text={
                      selectedCustomer.requiresTranslator && selectedCustomer.requiresTranslatorDetails
                        ? selectedCustomer.requiresTranslatorDetails
                        : selectedCustomer.requiresTranslator === false
                        ? 'No'
                        : 'Not Set'
                    }
                  />
                </Col>
              </Row>
            </div>
          </div>
          {/*<div className="mt-x-large">*/}
          {/*  <Row gutter={24} className={'mt-medium'}>*/}
          {/*    <Col span={12}>*/}
          {/*      <FieldLabel text={'Living Arrangements'} />*/}
          {/*      <div>*/}
          {/*        <FieldValueText text={selectedCustomer.livingArrangements} />*/}
          {/*      </div>*/}
          {/*    </Col>*/}
          {/*    <Col span={12}>*/}
          {/*      <FieldLabel text={'Household Composition'} />*/}
          {/*      <div>*/}
          {/*        <FieldValueText text={selectedCustomer.householdComposition} />*/}
          {/*      </div>*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</div>*/}
          {/*<div className="mt-x-large">*/}
          {/*  <Row gutter={24} className={'mt-medium'}>*/}
          {/*    <Col span={12}>*/}
          {/*      <FieldLabel text={'Transport Arrangements'} />*/}
          {/*      <div>*/}
          {/*        <FieldValueText text={selectedCustomer.transportArrangements} />*/}
          {/*      </div>*/}
          {/*    </Col>*/}
          {/*    <Col span={12}>*/}
          {/*      <SubTitle>Highest Level Of Education</SubTitle>*/}
          {/*      <div>*/}
          {/*        <FieldValueText text={selectedCustomer.highestLevelOfEducation} />*/}
          {/*      </div>*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</div>*/}
          {/*{selectedCustomer.transportArrangements === 'company' ? (*/}
          {/*  <div className="mt-x-large">*/}
          {/*    <Row gutter={24} className={'mt-medium'}>*/}
          {/*      <Col span={12}>*/}
          {/*        <FieldLabel text={'Company Infos'} />*/}
          {/*        <div>*/}
          {/*          <SubSectionHeaderText text={'Company Name'} />*/}
          {/*          <FieldValueText text={selectedCustomer.transportCompanyName} />*/}
          {/*        </div>*/}
          {/*        <div>*/}
          {/*          <SubSectionHeaderText text={'Contact Name'} />*/}
          {/*          <FieldValueText text={selectedCustomer.transportContactName} />*/}
          {/*        </div>*/}
          {/*        <div>*/}
          {/*          <SubSectionHeaderText text={'Contact Number'} />*/}
          {/*          <FieldValueText*/}
          {/*            text={*/}
          {/*              selectedCustomer.transportContactPhoneNumber*/}
          {/*                ? parsePhoneNumberFromString(*/}
          {/*                    selectedCustomer.transportContactPhoneNumber,*/}
          {/*                    selectedCustomer.transportContactPhoneCountryCode*/}
          {/*                  ).format('INTERNATIONAL')*/}
          {/*                : 'Not Set'*/}
          {/*            }*/}
          {/*          />*/}
          {/*        </div>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*  </div>*/}
          {/*) : null}*/}
        </div>
      );
    } else if (!_.isEmpty(selectedCustomer) && this.state.isEdit) {
      const dateofbirth =
        selectedCustomer.dateOfBirth !== null ? moment(selectedCustomer.dateOfBirth, 'YYYY-MM-DD') : null;

      return (
        <div className={'x2-large'}>
          <ActionModal
            isOpen={this.state.isActionModalOpen}
            onClose={this._closeActionModal}
            title={'Discard changes'}
            showCloseButton={true}
          >
            <Text className={'mb-medium'}>
              You have <b>unsaved data</b>, proceeding will discard these changes.
            </Text>
            <br />
            <Text className={'mb-medium'}>Do you want to proceed?</Text>
            <ActionModalFooter>
              <PrimaryButton className="mr-medium" size="large" onClick={this._closeActionModal}>
                Cancel
              </PrimaryButton>
              <GhostButton size="large" onClick={this._proceedDiscardChanges}>
                Proceed
              </GhostButton>
            </ActionModalFooter>
          </ActionModal>
          <Script
            url={'https://maps.googleapis.com/maps/api/js?key=' + globalConfig.googleMapApiKey + '&libraries=places'}
          />
          <CreateEditAddressModal
            closeCreateEditAddressModal={() => {
              this._closeCreateEditAddressModal();
            }}
            selectedCustomer={selectedCustomer}
            isOpen={this.state.isopen}
            selectedAddressIndex={this.state.selectedAddressIndex}
            customerAddress={this.state.customerAddress}
            isCreateNewAddress={this.state.isCreateNewAddress}
            saveCustomerAddress={this._saveCustomerAddress}
          />
          <ActionModal
            isOpen={this.state.isChangeBookingAddressModalOpen}
            onClose={this._onCloseChangeBookingAddressModel}
            title={'Changing primary address'}
            showCloseButton={true}
          >
            <div className={'mb-large pt-medium'}>
              <Text>
                Do you want to change the location of the customers upcoming support service bookings to the new primary
                address?
              </Text>
            </div>
            <ActionModalFooter>
              <GhostButton size="large" onClick={this._onCloseChangeBookingAddressModel}>
                Go back
              </GhostButton>
              <SecondaryButton
                className="mr-medium"
                size="large"
                onClick={this._onProceedDoNotChangeBookingAddressModel}
              >
                Do not change
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onProceedChangeBookingAddressModel}>
                Change
              </PrimaryButton>
            </ActionModalFooter>
          </ActionModal>
          <ActionModal
            isOpen={this.state.isWorkerConflictModalOpen}
            title={'Team member conflicts'}
            onClose={this._onCloseWorkerConflictModel}
          >
            <div className="anim-slide-left pt-medium">
              <Paragraph>
                As a result of the changes being made, the team member assigned to the bookings being changed is no
                longer available.
              </Paragraph>
              <Paragraph>
                The team member will be removed from the booking being changed if you press the confirm button, and you
                will have to assign another team member to the booking.
              </Paragraph>
              <Row className="text-weight-regular bordered-bottom border-standard-gray p-small mt-medium text-size-small text-color-secondary">
                <Col span={8}>TEAM MEMBER</Col>
                <Col span={8}>BOOKING BEING CHANGED</Col>
                <Col span={8}>CONFLICTING BOOKING</Col>
              </Row>
              <div
                style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '35vh' }}
                // className="bordered-bottom border-standard-gray"
              >
                {(!workerConflicts || workerConflicts.length === 0) && (
                  <Row className="pv-medium">
                    <Text>No conflicts found.</Text>
                  </Row>
                )}
                {_.map(workerConflicts, (conflict) => (
                  <Row type="flex" align="middle" className="p-small border-secondary evenodd">
                    <Col span={8} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <Text className="text-weight-bolder">
                        <Avatar className="mr-medium" size={'default'} src={conflict.attachmentUrl} />{' '}
                        {conflict.firstName} {conflict.lastName}
                      </Text>
                    </Col>
                    <Col span={8} className={'text-size-regular mt-large mb-large line-height-120 text-weight-regular'}>
                      <Text weight={'bolder'} size={'regular'} lineHeight={120}>
                        {conflict.serviceName}
                      </Text>
                      <br />
                      {moment(conflict.startDateTime)
                        .startOf('day')
                        .isSame(moment(conflict.endDateTime).startOf('day')) ? (
                        <text>
                          {moment(conflict.startDateTime).format('DD MMMM YYYY')}
                          <br />
                          {moment(conflict.startDateTime).format('h:mm a')} -{' '}
                          {moment(conflict.endDateTime).format('h:mm a')}
                        </text>
                      ) : (
                        <text>
                          {moment(conflict.startDateTime).format('DD MMMM YYYY')}{' '}
                          {moment(conflict.startDateTime).format('h:mm a')}
                          <br />
                          {moment(conflict.endDateTime).format('DD MMMM YYYY')}{' '}
                          {moment(conflict.endDateTime).format('h:mm a')}
                        </text>
                      )}
                    </Col>
                    <Col span={8} className={'text-size-regular mt-large mb-large line-height-120'}>
                      <Text weight={'bolder'} size={'regular'} lineHeight={120}>
                        {conflict.conflictBooking.serviceName}
                      </Text>
                      <br />
                      {moment
                        .tz(conflict.startDateTime, conflict.timezone)
                        .startOf('day')
                        .isSame(moment.tz(conflict.conflictBooking.endDateTime, conflict.timezone).startOf('day')) ? (
                        <text>
                          {moment.tz(conflict.conflictBooking.startDateTime, conflict.timezone).format('DD MMMM YYYY')}
                          <br />
                          {moment.tz(conflict.conflictBooking.startDateTime, conflict.timezone).format('h:mm a')} -{' '}
                          {moment.tz(conflict.conflictBooking.endDateTime, conflict.timezone).format('h:mm a')}
                        </text>
                      ) : (
                        <text>
                          {moment.tz(conflict.conflictBooking.startDateTime, conflict.timezone).format('DD MMMM YYYY')}{' '}
                          {moment.tz(conflict.conflictBooking.startDateTime, conflict.timezone).format('h:mm a')}
                          <br />
                          {moment
                            .tz(conflict.conflictBooking.endDateTime, conflict.timezone)
                            .format('DD MMMM YYYY')}{' '}
                          {moment.tz(conflict.conflictBooking.endDateTime, conflict.timezone).format('h:mm a')}
                        </text>
                      )}
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
            <div className={'mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseWorkerConflictModel}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._onConfirmRemoveConflictModel}>
                  Confirm
                </PrimaryButton>
              </Row>
            </div>
          </ActionModal>
          <Title level={2}>Customer General Information</Title>
          <Row type={'flex'} justify={'space-between'} align={'bottom'}>
            <Col>
              <Text type={'secondary'}>General information about this customer.</Text>
            </Col>
          </Row>
          <div className="mt-x-large">
            {isCRMRecord && (
              <>
                <Row>
                  <FormCol label={'First name'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('firstName', {
                        initialValue: selectedCustomer.firstName,
                        rules: [{ required: true, message: 'Please enter a First Name' }],
                      })(<Input size="large" placeholder="Enter first name" style={{ maxWidth: '300px' }} />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'Last name'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('lastName', {
                        initialValue: selectedCustomer.lastName,
                        rules: [{ required: true, message: 'Please enter a Last Name' }],
                      })(<Input size="large" placeholder="Enter last name" style={{ maxWidth: '300px' }} />)}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <FormCol label={'Team Member Selection'} leftSpan={5} textAlign={'right'} />
              <Col span={19} className={'pl-medium'}>
                <Form.Item>
                  {getFieldDecorator('teamSelect', {
                    initialValue: selectedCustomer.isWorkerSelectable,
                  })(
                    <Text>
                      <Checkbox defaultChecked={selectedCustomer.isWorkerSelectable} className="mr-small" />
                      Allowed to select an available Team Member when book a Service.
                    </Text>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label={'Support Level'} leftSpan={5} textAlign={'right'} />
              <Col span={19} className={'pl-medium'}>
                <Form.Item>
                  {getFieldDecorator('customerSupportLevel', { initialValue: selectedCustomer.customerSupportLevel })(
                    <Select size="large" style={{ width: '250px' }}>
                      {_.map(supportLevel, (item) => {
                        return (
                          <Option value={item.customerSupportLevel} key={item.customerSupportLevel}>
                            {item.value}
                          </Option>
                        );
                      })}
                    </Select>,
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <FormCol label={'Customer ID'} leftSpan={5} textAlign={'right'} />
              <Col span={19} className={'pl-medium'}>
                <Form.Item>
                  {getFieldDecorator('customerNumber', { initialValue: selectedCustomer.customerNumber })(
                    <Input size="large" placeholder="Customer ID" style={{ maxWidth: '300px' }} />,
                  )}
                </Form.Item>
              </Col>
            </Row>
            {isCRMRecord && (
              <div>
                <Row>
                  <FormCol label={'About'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('aboutMe', {
                        initialValue: selectedCustomer.aboutMe,
                      })(<TextArea rows={4} />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'Date of birth'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('dateOfBirth', {
                        initialValue: dateofbirth,
                      })(
                        <DatePicker
                          size="large"
                          allowClear={false}
                          format="DD-MM-YYYY"
                          className="{'mr-small'}"
                          showToday={false}
                          disabledDate={(current) => {
                            return current && current >= moment().startOf('day');
                          }}
                        />,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                {isCRMRecord && (
                  <Row>
                    <FormCol label={'Mobile Number'} leftSpan={5} textAlign={'right'} />
                    <Col span={19} className={'pl-medium'}>
                      <Row>
                        <Col span={3}>
                          <Form.Item>
                            {getFieldDecorator('mobileCountryCode', {
                              initialValue:
                                selectedCustomer.mobileCountryCode !== null && selectedCustomer.mobileCountryCode !== ''
                                  ? selectedCustomer.mobileCountryCode
                                  : 'AU',
                            })(
                              <Select size="large" placeholder={'Please select country code'}>
                                <Option value={'AU'}>+61 (AU)</Option>
                              </Select>,
                            )}
                          </Form.Item>
                        </Col>
                        <Col span={10}>
                          <Form.Item>
                            {getFieldDecorator('mobile', {
                              initialValue: selectedCustomer.mobile !== null ? selectedCustomer.mobile : '',
                              rules: [
                                {
                                  validator: this._validateMobileNumber,
                                },
                              ],
                            })(<Input size="large" placeholder="Enter mobile number" />)}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                <Row>
                  <FormCol label={'Home Number'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Row>
                      <Col span={3}>
                        <Form.Item>
                          {getFieldDecorator('homePhoneCountryCode', {
                            initialValue:
                              selectedCustomer.homePhoneCountryCode !== null &&
                              selectedCustomer.homePhoneCountryCode !== ''
                                ? selectedCustomer.homePhoneCountryCode
                                : 'AU',
                          })(
                            <Select size="large" placeholder={'Please select country code'}>
                              <Option value={'AU'}>+61 (AU)</Option>
                            </Select>,
                          )}
                        </Form.Item>
                      </Col>
                      <Col span={10}>
                        <Form.Item>
                          {getFieldDecorator('homePhoneNumber', {
                            initialValue:
                              selectedCustomer.homePhoneNumber !== null ? selectedCustomer.homePhoneNumber : '',
                            rules: [
                              {
                                validator: this._validateMobileNumber,
                              },
                            ],
                          })(<Input size="large" placeholder="Enter home number" />)}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'Gender'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('gender', {
                        initialValue: selectedCustomer.gender,
                      })(
                        <Select
                          size="large"
                          placeholder={'Please select gender'}
                          style={{ fontSize: '16px' }}
                          className={'width-full'}
                        >
                          <Option value={'Male'}>Male</Option>
                          <Option value={'Female'}>Female</Option>
                          <Option value={'Non-binary'}>Other</Option>
                          <Option value={'Not specified'}>Prefer Not to Say</Option>
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'Email'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('email', {
                        initialValue: selectedCustomer.email,
                        rules: [
                          {
                            type: 'email',
                            message: 'Please enter valid Email',
                          },
                        ],
                      })(<Input size="large" placeholder={'Enter email'} />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'Address(es)'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium mt-small'}>
                    <div>{this._renderEditAddresses()}</div>
                  </Col>
                </Row>
                <Row className={'mv-small'}>
                  <FormCol label={''} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <div className={'customFormItem'}>
                      <PrimaryButton icon={'plus'} onClick={() => this._openCreateEditAddressModal('')}>
                        Add a new Address
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'Permanent conditions'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('disabilities', { initialValue: selectedCustomer.disabilities })(
                        <Select
                          mode={'multiple'}
                          style={{ width: '100%' }}
                          showSearch
                          filterOption={true}
                          optionFilterProp={'children'}
                          className="bg-tertiary focus-bg-white"
                          size="large"
                        >
                          {_.map(disabilities, (disability) => {
                            return (
                              <Select.Option key={disability.tagId} value={disability.tagId}>
                                {disability ? disability.disabilityName : 'Not found'}
                              </Select.Option>
                            );
                          })}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'Hobbies & Interests'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('hobbies', { initialValue: selectedCustomer.hobbies })(
                        <Select
                          mode={'multiple'}
                          style={{ width: '100%' }}
                          showSearch
                          filterOption={true}
                          optionFilterProp={'children'}
                          className="bg-tertiary focus-bg-white"
                          size="large"
                        >
                          {_.map(hobbies, (hobbie) => {
                            return (
                              <Select.Option key={hobbie.tagId} value={hobbie.tagId}>
                                {hobbie.tag}
                              </Select.Option>
                            );
                          })}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'Religion'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('religions', { initialValue: selectedCustomer.religions[0] })(
                        <Select
                          mode={'default'}
                          style={{ width: '100%' }}
                          showSearch
                          filterOption={true}
                          optionFilterProp={'children'}
                          className="bg-tertiary focus-bg-white"
                          size="large"
                        >
                          {_.map(religion, (hobbie) => {
                            return (
                              <Select.Option key={hobbie.tagId} value={hobbie.tagId}>
                                {hobbie.tag}
                              </Select.Option>
                            );
                          })}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'Languages'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('languages', { initialValue: selectedCustomer.languages })(
                        <Select
                          mode={'multiple'}
                          style={{ width: '100%' }}
                          showSearch
                          filterOption={true}
                          optionFilterProp={'children'}
                          className="bg-tertiary focus-bg-white"
                          size="large"
                        >
                          {_.map(languages, (hobbie) => {
                            return (
                              <Select.Option key={hobbie.tagId} value={hobbie.tagId}>
                                {hobbie.tag}
                              </Select.Option>
                            );
                          })}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'Country Of Birth'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('countryOfBirth', {
                        initialValue: selectedCustomer.countryOfBirth ? selectedCustomer.countryOfBirth : null,
                      })(
                        <Select
                          mode={'default'}
                          style={{ width: '100%' }}
                          showSearch
                          filterOption={true}
                          optionFilterProp={'children'}
                          className="bg-tertiary focus-bg-white"
                          size="large"
                          placeholder="Select..."
                        >
                          {_.map(this.state.countries, (country) => {
                            return (
                              <Select.Option key={country.countryId} value={country.countryName}>
                                {country.countryName}
                              </Select.Option>
                            );
                          })}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'Aboriginal/Torres Strait islander status'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('straitsLanderStatus', {
                        initialValue: this.state.selectedATSStatus,
                      })(
                        <Radio.Group onChange={this._onChangeSelectedATSStatus} className="ml-medium">
                          <Row>
                            <Radio
                              value={'no'}
                              className={`${this.state.selectedATSStatus === 'no' && 'text-weight-bold'} mb-small`}
                            >
                              <div
                                className="ml-medium inline-box inline-flex align-center"
                                style={{ whiteSpace: 'normal' }}
                              >
                                No
                              </div>
                            </Radio>
                          </Row>
                          <Row>
                            <Radio
                              value={'yes'}
                              className={`${this.state.selectedATSStatus === 'yes' && 'text-weight-bold'} mb-small`}
                            >
                              <div
                                className="ml-medium inline-box inline-flex align-center"
                                style={{ whiteSpace: 'normal' }}
                              >
                                Yes
                              </div>
                            </Radio>
                          </Row>
                          <Row>
                            <Radio
                              value={'preferNotToSay'}
                              className={`${this.state.selectedATSStatus === 'preferNotToSay' &&
                                'text-weight-bold'} mb-small`}
                            >
                              <div
                                className="ml-medium inline-box inline-flex align-center"
                                style={{ whiteSpace: 'normal' }}
                              >
                                Prefer not to say
                              </div>
                            </Radio>
                          </Row>
                        </Radio.Group>,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'Medicare Number'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('medicareNumber', {
                        initialValue: selectedCustomer.medicareNumber ? selectedCustomer.medicareNumber : null,
                        rules: [
                          {
                            max: 50,
                            message: 'Value should be less than 50 character',
                          },
                          {
                            pattern: /^[0-9]*$/,
                            message: 'Must be in numeric format',
                          },
                        ],
                      })(<Input size="large" placeholder="Enter number..." />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'Ambulance number'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('ambulanceNumber', {
                        initialValue: selectedCustomer.ambulanceNumber ? selectedCustomer.ambulanceNumber : null,
                        rules: [
                          {
                            max: 50,
                            message: 'Value should be less than 50 character',
                          },
                          {
                            pattern: /^[0-9]*$/,
                            message: 'Must be in numeric format',
                          },
                        ],
                      })(<Input size="large" placeholder="Enter number..." />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'Companion card'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('companionCard', {
                        initialValue: selectedCustomer.companionCard ? selectedCustomer.companionCard : null,
                        rules: [
                          {
                            max: 50,
                            message: 'Value should be less than 50 character',
                          },
                          {
                            pattern: /^[0-9,a-zA-Z,/]*$/,
                            message: 'Must be letters, numbers, or forward slashes',
                          },
                        ],
                      })(<Input size="large" placeholder="Enter companion card number..." />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'Centrelink number'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('centrelinkNumber', {
                        initialValue: selectedCustomer.centrelinkNumber ? selectedCustomer.centrelinkNumber : null,
                        rules: [
                          {
                            max: 50,
                            message: 'Value should be less than 50 character',
                          },
                          {
                            pattern: /^[0-9,a-zA-Z,/]*$/,
                            message: 'Must be letters, numbers, or forward slashes',
                          },
                        ],
                      })(<Input size="large" placeholder="Enter centrelink number..." />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'Living arrangements'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('livingArrangements', {
                        initialValue: selectedCustomer.livingArrangements ? selectedCustomer.livingArrangements : null,
                        rules: [
                          {
                            max: 500,
                            message: 'The maximum length of this field is 500 characters',
                          },
                        ],
                      })(<TextArea rows={4} placeholder={'Add living arrangements'} />)}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'Requires translator'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('requiresTranslator', {
                        initialValue: this.state.selectedRequiresTranslator,
                      })(
                        <Radio.Group onChange={this._onChangeSelectedRequiresTranslator} className="ml-medium">
                          <Row>
                            <Radio
                              value={false}
                              className={`${this.state.selectedRequiresTranslator === false &&
                                'text-weight-bold'} mb-small`}
                            >
                              <div
                                className="ml-medium inline-box inline-flex align-center"
                                style={{ whiteSpace: 'normal' }}
                              >
                                No
                              </div>
                            </Radio>
                          </Row>
                          <Row>
                            <Radio
                              value={true}
                              className={`${this.state.selectedRequiresTranslator === true &&
                                'text-weight-bold'} mb-small`}
                            >
                              <div
                                className="ml-medium inline-box inline-flex align-center"
                                style={{ whiteSpace: 'normal' }}
                              >
                                Yes
                              </div>
                            </Radio>
                          </Row>
                          {this.state.selectedRequiresTranslator && (
                            <Row>
                              <Form.Item>
                                {getFieldDecorator('requiresTranslatorDetails', {
                                  initialValue:
                                    selectedCustomer.requiresTranslatorDetails !== null
                                      ? selectedCustomer.requiresTranslatorDetails
                                      : null,
                                  rules: [
                                    {
                                      required: true,
                                      message: 'Please add a language',
                                    },
                                    {
                                      max: 100,
                                      message: 'Value should be less than 100 character',
                                    },
                                  ],
                                })(<Input size="large" placeholder="Add details..." />)}
                              </Form.Item>
                            </Row>
                          )}
                        </Radio.Group>,
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <FormCol label={'LGA'} leftSpan={5} textAlign={'right'} />
                  <Col span={19} className={'pl-medium'}>
                    <Form.Item>
                      {getFieldDecorator('lga', {
                        initialValue: selectedCustomer.lga !== null ? selectedCustomer.lga : null,
                      })(<Input size="large" placeholder="Enter LGA..." />)}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            )}

            {/*<Row>*/}
            {/*  <FormCol label={'Living Arrangements'} leftSpan={5} textAlign={'right'} />*/}
            {/*  <Col span={19} className={'pl-medium'}>*/}
            {/*    <Form.Item>*/}
            {/*      {getFieldDecorator('livingArrangements', {*/}
            {/*        initialValue: selectedCustomer.livingArrangements,*/}
            {/*        rules: [*/}
            {/*          {*/}
            {/*            required: true,*/}
            {/*            message: 'Living arrangements is required'*/}
            {/*          }*/}
            {/*        ]*/}
            {/*      })(<Input placeholder={'Enter Living Arrangements'} />)}*/}
            {/*    </Form.Item>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*  <FormCol label={'Household Composition'} leftSpan={5} textAlign={'right'} />*/}
            {/*  <Col span={19} className={'pl-medium'}>*/}
            {/*    <Form.Item>*/}
            {/*      {getFieldDecorator('householdComposition', {*/}
            {/*        initialValue: selectedCustomer.householdComposition,*/}
            {/*        rules: [*/}
            {/*          {*/}
            {/*            required: true,*/}
            {/*            message: 'Household composition is required'*/}
            {/*          }*/}
            {/*        ]*/}
            {/*      })(<Input placeholder={'Enter Household composition'} />)}*/}
            {/*    </Form.Item>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {/*<Row>*/}
            {/*  <FormCol label={'Transport Arrangements'} leftSpan={5} textAlign={'right'} />*/}
            {/*  <Col span={19} className={'pl-medium'}>*/}
            {/*    <Form.Item>*/}
            {/*      {getFieldDecorator('transportArrangements', {*/}
            {/*        initialValue: selectedCustomer.transportArrangements,*/}
            {/*        rules: [{ required: true, message: 'Please select transport arrangements' }]*/}
            {/*      })(*/}
            {/*        <Select*/}
            {/*          placeholder={'Please select transport arrangements'}*/}
            {/*          style={{ fontSize: '16px' }}*/}
            {/*          className={'width-full'}*/}
            {/*          onChange={this._handletransportArrangements}*/}
            {/*        >*/}
            {/*          <Option value={''}>*/}
            {/*            <i>Please select transport arrangements</i>*/}
            {/*          </Option>*/}
            {/*          <Option value={'private'}>Private</Option>*/}
            {/*          <Option value={'company'}>Company</Option>*/}
            {/*        </Select>*/}
            {/*      )}*/}
            {/*    </Form.Item>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            {/*{this.state.selectedTransport === 'company' ? (*/}
            {/*  <div>*/}
            {/*    <Row>*/}
            {/*      <FormCol label={'Company Name'} leftSpan={5} textAlign={'right'} />*/}
            {/*      <Col span={19} className={'pl-medium'}>*/}
            {/*        <Form.Item>*/}
            {/*          {getFieldDecorator('transportCompanyName', {*/}
            {/*            initialValue: selectedCustomer.transportCompanyName,*/}
            {/*            rules: [*/}
            {/*              {*/}
            {/*                required: true,*/}
            {/*                message: 'Company name is required'*/}
            {/*              }*/}
            {/*            ]*/}
            {/*          })(<Input placeholder={'Enter Company name'} />)}*/}
            {/*        </Form.Item>*/}
            {/*      </Col>*/}
            {/*    </Row>*/}
            {/*    <Row>*/}
            {/*      <FormCol label={'Contact Name'} leftSpan={5} textAlign={'right'} />*/}
            {/*      <Col span={19} className={'pl-medium'}>*/}
            {/*        <Form.Item>*/}
            {/*          {getFieldDecorator('transportContactName', {*/}
            {/*            initialValue: selectedCustomer.transportContactName,*/}
            {/*            rules: [*/}
            {/*              {*/}
            {/*                required: true,*/}
            {/*                message: 'Contact name is required'*/}
            {/*              }*/}
            {/*            ]*/}
            {/*          })(<Input placeholder={'Enter Contact name'} />)}*/}
            {/*        </Form.Item>*/}
            {/*      </Col>*/}
            {/*    </Row>*/}
            {/*    <Row>*/}
            {/*      <FormCol label={'Contact Mobile'} leftSpan={5} textAlign={'right'} />*/}
            {/*      <Col span={19} className={'pl-medium'}>*/}
            {/*        <Row>*/}
            {/*          <Col span={3}>*/}
            {/*            <Form.Item>*/}
            {/*              {getFieldDecorator('transportContactPhoneCountryCode', {*/}
            {/*                initialValue:*/}
            {/*                  selectedCustomer.transportContactPhoneCountryCode !== null &&*/}
            {/*                  selectedCustomer.transportContactPhoneCountryCode !== ''*/}
            {/*                    ? selectedCustomer.transportContactPhoneCountryCode*/}
            {/*                    : 'AU'*/}
            {/*              })(*/}
            {/*                <Select placeholder={'Please select country code'} style={{ fontSize: '16px' }}>*/}
            {/*                  <Option value={'AU'}>+61 (AU)</Option>*/}
            {/*                </Select>*/}
            {/*              )}*/}
            {/*            </Form.Item>*/}
            {/*          </Col>*/}
            {/*          <Col span={10}>*/}
            {/*            <Form.Item>*/}
            {/*              {getFieldDecorator('transportContactPhoneNumber', {*/}
            {/*                initialValue:*/}
            {/*                  selectedCustomer.transportContactPhoneNumber !== null*/}
            {/*                    ? selectedCustomer.transportContactPhoneNumber*/}
            {/*                    : '',*/}
            {/*                rules: [*/}
            {/*                  {*/}
            {/*                    validator: this._validateMobileNumber*/}
            {/*                  }*/}
            {/*                ]*/}
            {/*              })(<Input placeholder="Enter Contact mobile number" />)}*/}
            {/*            </Form.Item>*/}
            {/*          </Col>*/}
            {/*        </Row>*/}
            {/*        /!* <Row>*/}
            {/*          <Col span={3}>*/}
            {/*            <Form.Item className={'m-none'}>*/}
            {/*              {getFieldDecorator('transportContactPhoneCountryCode', {*/}
            {/*                initialValue:*/}
            {/*                  selectedCustomer.transportContactPhoneCountryCode !== null &&*/}
            {/*                  selectedCustomer.transportContactPhoneCountryCode !== ''*/}
            {/*                    ? selectedCustomer.transportContactPhoneCountryCode*/}
            {/*                    : 'AU'*/}
            {/*              })(*/}
            {/*                <Select placeholder={'Please select country code'} style={{ fontSize: '16px' }}>*/}
            {/*                  <Option value={'AU'}>+61 (AU)</Option>*/}
            {/*                </Select>*/}
            {/*              )}*/}
            {/*            </Form.Item>*/}
            {/*          </Col>*/}
            {/*          <Col span={10}>*/}
            {/*            <Form.Item className={'m-none'}>*/}
            {/*              {getFieldDecorator('transportContactPhoneNumber', {*/}
            {/*                initialValue: selectedCustomer.transportContactPhoneNumber,*/}
            {/*                rules: [*/}
            {/*                  { required: true, message: 'contact mobile is required' },*/}
            {/*                  {*/}
            {/*                    validator: this._validateMobileNumber,*/}
            {/*                    message: 'Invalid mobile number.'*/}
            {/*                  }*/}
            {/*                ]*/}
            {/*              })(<Input placeholder="Enter Contact mobile number" />)}*/}
            {/*            </Form.Item>*/}
            {/*          </Col>*/}
            {/*        </Row> *!/*/}
            {/*      </Col>*/}
            {/*    </Row>*/}
            {/*  </div>*/}
            {/*) : null}*/}
            {/*<Row>*/}
            {/*  <FormCol label={'Highest level of Education'} leftSpan={5} textAlign={'right'} />*/}
            {/*  <Col span={19} className={'pl-medium'}>*/}
            {/*    <Form.Item>*/}
            {/*      {getFieldDecorator('highestLevelOfEducation', {*/}
            {/*        initialValue: selectedCustomer.highestLevelOfEducation,*/}
            {/*        rules: [*/}
            {/*          {*/}
            {/*            required: true,*/}
            {/*            message: 'level of Education is required'*/}
            {/*          }*/}
            {/*        ]*/}
            {/*      })(<Input placeholder={'Enter Highest level of Education'} />)}*/}
            {/*    </Form.Item>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
            <Row>
              <FormCol label={''} leftSpan={5} textAlign={'right'} />
              <Col>
                <div className={'mb-small'}>
                  <Row type={'flex'} justify={'end'} gutter={16}>
                    <Col>
                      <GhostButton onClick={this._closeWithActionModal}>Discard Changes</GhostButton>
                    </Col>
                    <Col>
                      <PrimaryButton disabled={this.state.isSaving} onClick={this._onClickSave} icon={'save'}>
                        Save
                      </PrimaryButton>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateCustomerProfile: dispatch.customersStore.doUpdateCustomerProfile,
  doFetchDisabilityList: dispatch.customersStore.doFetchDisabilityList,
  doGetCountriesList: dispatch.customersStore.doGetCountriesList,
  doCheckConflictWorkerForEditAddress: dispatch.customersStore.doCheckConflictWorkerForEditAddress,
  doUpdateIgnoreAnAlert: dispatch.customersStore.doUpdateIgnoreAnAlert,
});

export default connect(
  null,
  mapDispatch,
)(Form.create<ICustomerDetailsGeneralPanelProps>()(CustomerDetailsGeneralPanel));
