import React from 'react';

import { Col, Row } from 'antd';
import { Text } from 'common-components/typography';

export const ServDetailsContentRow = ({
  label = null,
  subLabel = null,
  children = null,
  align = 'left',
  leftColSpan = 6,
  rightColSpan = 18
}) => (
  <Row type={'flex'} className={`text-align-${align} mb-x2-large`}>
    <Col span={leftColSpan} className="pr-large">
      <Text strong={true}>{label}</Text>
      <br />
      {subLabel && (
        <Text className="text-size-smaller" type={'secondary'}>
          {subLabel}
        </Text>
      )}
    </Col>
    <Col span={rightColSpan}>{children}</Col>
  </Row>
);
