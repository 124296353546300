import React, { Component } from 'react';
import { Col, Form, Row } from 'antd';
import { Text } from 'common-components/typography';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import * as uuid from 'uuid';
import FullScreenScrollableModal, { FullScreenModalFooter } from 'common-components/modal/FullScreenScrollableModal';
import Steps from 'common-components/steps/Steps';
// Panels
import ServiceTypeStepPanel from './wizard-steps/ServiceTypeStepPanel';
import InformationStepPanel from './wizard-steps/InformationStepPanel';
import LocationStepPanel from './wizard-steps/LocationStepPanel';
import WhenStepPanel from './wizard-steps/WhenStepPanel';
import PricingStepPanel from './wizard-steps/PricingStepPanel';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { ServiceLocationType, ServiceTimeType } from 'utilities/enum-utils';
import LineItemsStep from './wizard-steps/LineItemsStep';
import PaymentSettingsStepPanel from './wizard-steps/PaymentSettingsStepPanel';
import PublishStepPanel from './wizard-steps/PublishStepPanel';
import SummaryStepPanel from './wizard-steps/SummaryStepPanel';
import ConfirmationStepPanel from './wizard-steps/ConfirmationStepPanel';
import PaymentMethodStepPanel from './wizard-steps/PaymentMethodStepPanel';
import GroupServiceClassification from './wizard-steps/GroupServiceClassificationStepPanel';
import { ServiceType } from 'utilities/enum-utils';
import ServiceUtils from 'utilities/service-utils';

interface ICreateNewServiceModalProps extends FormComponentProps {
  closeCreateServiceModal: (serviceData?: any, isGroupService?: boolean) => void;
  setNewService?: typeof dispatch.servicesStore.setNewService;
  newService?: typeof state.servicesStore.newService;
  doCreateNewService?: typeof dispatch.servicesStore.doCreateNewService;
  doCreateNewGroupService?: typeof dispatch.servicesStore.doCreateNewGroupService;
  doFetchCompanyLite: typeof dispatch.companyStore.doFetchCompanyLite;
  companyData: typeof state.companyStore.companyDataLite;
  isOpen: boolean;
  history: any;
  authStore: typeof state.authStore;
}

interface ICreateNewServiceModalState {
  step: number;
  totalStep: number;
  isProceedOpen: boolean;
}

class CreateNewServiceModal extends Component<ICreateNewServiceModalProps, ICreateNewServiceModalState> {
  state = {
    step: 1,
    totalStep: 9,
    isProceedOpen: false
  };

  private _createNewEmptyService = () => {
    const serviceProviderId = this.props.authStore.portalUser.serviceProviderId;
    const timezone = this.props.companyData.timezone;

    return ServiceUtils.createNewEmptyService(serviceProviderId, timezone);
  };

  private _onNextStep = () => {
    this.setState({ step: this.state.step + 1 });
  };

  private _onPreviousStep = () => {
    this.setState({ step: this.state.step - 1 });
  };

  private _closeCreateServiceModal = async () => {
    if (this.state.step > 1) this.setState({ isProceedOpen: true });
    else this._resetAndCloseModal();
  };

  private _resetAndCloseModal = () => {
    this.props.setNewService(this._createNewEmptyService());
    this.setState({ isProceedOpen: false, step: 1 });
    this.props.closeCreateServiceModal();
  };

  private _goToNewService = () => {
    const { newService } = this.props;
    const newServiceData = newService;
    this.props.setNewService(this._createNewEmptyService());
    this.setState({ isProceedOpen: false, step: 1 });
    this.props.closeCreateServiceModal(newServiceData, this._isGroupService());
  };

  private _restartServiceCreation = () => {
    this.props.setNewService(this._createNewEmptyService());
    this.setState({ step: 1 });
  };

  private _isGroupService = () => {
    return this.props.newService.serviceType && this.props.newService.serviceType === ServiceType.GROUP;
  };

  componentDidMount = async () => {
    await this.props.doFetchCompanyLite([]);
    await this.props.setNewService(this._createNewEmptyService());
  };

  private _renderStepContent = () => {
    const {
      setNewService,
      newService,
      doCreateNewService,
      doCreateNewGroupService,
      authStore,
      companyData
    } = this.props;
    const { step } = this.state;
    if (step === 1) {
      return (
        <ServiceTypeStepPanel
          onNextStep={this._onNextStep}
          setNewService={setNewService}
          newService={newService}
          serviceProviderId={authStore.portalUser && authStore.portalUser.serviceProviderId}
          timezone={companyData && companyData.timezone}
        />
      );
    } else if (step === 2) {
      return (
        <InformationStepPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          setNewService={setNewService}
          newService={newService}
        />
      );
    } else if (step === 3) {
      if (this._isGroupService()) {
        return (
          <GroupServiceClassification
            onNextStep={this._onNextStep}
            onPreviousStep={this._onPreviousStep}
            setNewService={setNewService}
            newService={newService}
          />
        );
      } else {
        return (
          <LocationStepPanel
            onNextStep={this._onNextStep}
            onPreviousStep={this._onPreviousStep}
            setNewService={setNewService}
            newService={newService}
          />
        );
      }
    } else if (step === 4) {
      if (this._isGroupService()) {
        return (
          <LocationStepPanel
            onNextStep={this._onNextStep}
            onPreviousStep={this._onPreviousStep}
            setNewService={setNewService}
            newService={newService}
          />
        );
      } else {
        return (
          <WhenStepPanel
            onNextStep={this._onNextStep}
            onPreviousStep={this._onPreviousStep}
            setNewService={setNewService}
            newService={newService}
          />
        );
      }
    } else if (step === 5) {
      return (
        <PaymentMethodStepPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          setNewService={setNewService}
          newService={newService}
        />
      );
    } else if (step === 6) {
      return (
        <PaymentSettingsStepPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          setNewService={setNewService}
          newService={newService}
        />
      );
    } else if (step === 7) {
      return (
        <PublishStepPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          setNewService={setNewService}
          newService={newService}
        />
      );
    } else if (step === 8) {
      return (
        <SummaryStepPanel
          onPreviousStep={this._onPreviousStep}
          onNextStep={this._onNextStep}
          setNewService={setNewService}
          newService={newService}
          resetAndCloseModal={this._resetAndCloseModal}
          doCreateNewService={doCreateNewService}
          doCreateNewGroupService={doCreateNewGroupService}
        />
      );
    } else if (step === 9) {
      return (
        <ConfirmationStepPanel
          onPreviousStep={this._onPreviousStep}
          newService={newService}
          goToNewService={this._goToNewService}
          restartServiceCreation={this._restartServiceCreation}
        />
      );
    }
  };

  private _closeProceedModal = () => {
    this.setState({ isProceedOpen: false });
  };

  render() {
    let bottomText = 'Choose Service Type';

    if (this.state.step === 2) {
      bottomText = 'General Information';
    } else if (this.state.step === 3) {
      bottomText = this._isGroupService() ? 'Service classification' : 'Who, where & How many?';
    } else if (this.state.step === 4) {
      bottomText = this._isGroupService() ? 'Service location and capacity' : 'When';
    } else if (this.state.step === 5) {
      bottomText = this._isGroupService() ? 'General pricing information' : 'Pricing';
    } else if (this.state.step === 6) {
      bottomText = this._isGroupService() ? 'NDIS price settings' : 'Billing line items';
    } else if (this.state.step === 7) {
      bottomText = this._isGroupService() ? 'Publication settings' : 'Publish rules';
    } else if (this.state.step === 8) {
      bottomText = this._isGroupService() ? 'Overview' : 'Service summary';
    } else if (this.state.step === 9) {
      bottomText = 'Confirmation';
    }

    let steps = [];
    for (let i = 1; i <= this.state.totalStep; i++) {
      let stepObj = {};
      stepObj['stepNumber'] = i;
      stepObj['stepLabel'] = i;
      steps.push(stepObj);
    }

    return (
      <>
        <ActionModal
          isOpen={this.state.isProceedOpen}
          onClose={this._closeProceedModal}
          title={'Cancelling creating service'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, are you sure you want to <b>cancel</b> now ?
          </Text>
          <br />
          <Text className={'mb-medium'} weight={'bold'}>
            Your progress will not be saved.
          </Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeProceedModal}>
              Back
            </PrimaryButton>
            <GhostButton size="large" onClick={this._resetAndCloseModal}>
              Cancel
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <FullScreenScrollableModal
          isOpen={this.props.isOpen}
          onClose={this._closeCreateServiceModal}
          width={'large'}
          canCloseEsc={false}
          fixedHeight={false}
          footerContent={
            <FullScreenModalFooter align={'left'}>
              <Row gutter={0} type="flex" align="middle">
                <Col span={8} />
                <Col span={8}>
                  <div className="justify-center flex-row">
                    <Text size="regular" className="mb-medium">
                      <span className="mr-medium text-weight-bold">
                        Step {this.state.step} of {this.state.totalStep}
                      </span>
                      <span className="">{bottomText}</span>
                    </Text>
                  </div>
                  <Steps currentStep={this.state.step} steps={steps} />
                </Col>
                <Col span={8} />
              </Row>
            </FullScreenModalFooter>
          }
        >
          {this._renderStepContent()}
        </FullScreenScrollableModal>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  newService: state.servicesStore.newService,
  authStore: state.authStore,
  companyData: state.companyStore.companyDataLite
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewService: dispatch.servicesStore.setNewService,
  doCreateNewService: dispatch.servicesStore.doCreateNewService,
  doCreateNewGroupService: dispatch.servicesStore.doCreateNewGroupService,
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<ICreateNewServiceModalProps>()(CreateNewServiceModal));
