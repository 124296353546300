import { IGroupServiceSession } from 'interfaces/service-interfaces';
import React, { useState } from 'react';
import moment from 'moment-timezone';
import { GroupServiceSessionStatus, SessionStatus } from 'utilities/enum-utils';
import { Popover } from '@blueprintjs/core';
import { CardPopover } from 'views/group-services/session-listings/week-view/components/CardPopover';
import { Text, Paragraph } from 'common-components/typography';
import SessionStatusTag from 'common-components/tags/SessionStatusTag';
import { Icon } from 'antd';

import * as H from 'history';
import { timeZone } from 'interfaces/timezone-type';
import TextTag from 'common-components/tags/TextTag';

interface ISessionCardProps {
  session: IGroupServiceSession;
  history: H.History;
  timezone: timeZone;
}

export function SessionCard({ session, history, timezone }: ISessionCardProps) {
  const [isHovered, setHovered] = useState(false);

  const {
    serviceName,
    startDateTime,
    endDateTime,
    bookedCapacity,
    capacity,
    totalAssignedShiftSlot,
    totalShiftSlot,
    sessionStatus,
    scheduleName
  } = session;

  const isOverdue =
    moment.tz(startDateTime, timezone).isBefore(moment.tz(timezone)) && sessionStatus === SessionStatus.SCHEDULED;
  const isDueToStart =
    moment.tz(startDateTime, timezone).isBefore(moment.tz(timezone).add(15, 'minutes')) &&
    sessionStatus === SessionStatus.SCHEDULED;
  const isDueToFinish =
    moment.tz(endDateTime, timezone).isBefore(moment.tz(timezone).add(15, 'minutes')) &&
    moment.tz(endDateTime, timezone).isAfter(moment.tz(timezone)) &&
    sessionStatus === SessionStatus.INPROGRESS;

  //region Conditional styles
  let topLineColor = 'bg-blue';
  let textColor = 'black';
  let hoverBorderClass = '';
  let backgroundColor = 'bg-white';

  const shadowClass = isHovered ? 'shadow-box' : 'shadow-container';

  switch (sessionStatus) {
    case GroupServiceSessionStatus.SCHEDULED:
      topLineColor = 'bg-green-lighter';
      hoverBorderClass = 'hover-border-green-lighter';
      break;
    case GroupServiceSessionStatus.INPROGRESS:
      topLineColor = 'bg-orange-lighter';
      hoverBorderClass = 'hover-border-orange-lighter';
      break;
    case GroupServiceSessionStatus.COMPLETED:
      topLineColor = 'bg-blue-lighter';
      hoverBorderClass = 'hover-border-blue-lighter';
      break;
    case GroupServiceSessionStatus.CLOSED:
      topLineColor = 'bg-blue-darker';
      hoverBorderClass = 'hover-border-blue-darker';
      textColor = 'secondary';
      break;
    case GroupServiceSessionStatus.CANCELLED:
      topLineColor = 'bg-red-light';
      hoverBorderClass = 'hover-border-red-light';
      textColor = 'secondary';
      break;
  }
  //endregion

  if (isOverdue) {
    topLineColor = 'bg-rose-light';
    hoverBorderClass = 'hover-border-rose-lighter';
    backgroundColor = 'bg-red-lightest';
  }

  if (isDueToFinish || isDueToStart) {
    backgroundColor = 'bg-blue-action-lightest';
  }

  const timeDisplayLabel = (
    <>
      <b>{moment.tz(startDateTime, timezone).format('h:mm A')}</b> - {moment.tz(endDateTime, timezone).format('h:mm A')}
    </>
  );

  const customersLabel = (
    <>
      {bookedCapacity}/{capacity} customers
    </>
  );

  const teamMembersLabel = (
    <>
      {totalAssignedShiftSlot}/{totalShiftSlot} team members
    </>
  );

  return (
    <Popover
      content={<CardPopover session={session} history={history} timezone={timezone} />}
      usePortal={false}
      targetTagName="div"
      position="right"
    >
      <div
        className={`flex-1 anim-fade-in-fast anim-slide-down mh-12 mv-12 select-none bordered-bottom ${shadowClass} rounded-big line-height-135 cursor-pointer ${backgroundColor} ${hoverBorderClass}`}
        style={{ overflow: 'hidden' }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div className={topLineColor} style={{ height: '4px' }} />
        <div className={`bordered ph-12 pv-small flex-column ${hoverBorderClass}`}>
          {/* Time */}
          <Text size="small" color={textColor} lineHeight={135}>
            {timeDisplayLabel}
          </Text>

          {/* Service */}
          <Paragraph size="small" color={textColor} lineHeight={135} ellipsis={{ rows: 1 }} className="mv-none">
            {serviceName}
          </Paragraph>

          <Paragraph
            size="small"
            weight="light"
            color={textColor}
            lineHeight={135}
            ellipsis={{ rows: 1 }}
            className="mv-none"
          >
            {scheduleName}
          </Paragraph>

          {/* Tag */}
          <div className="inline-block">
            <SessionStatusTag status={sessionStatus} textSize="x-small" weight="bold" />
            {(isOverdue || isDueToStart || isDueToFinish) && (
              <TextTag
                content={isOverdue ? 'Overdue' : isDueToStart ? 'Due to start soon' : 'Due to end soon'}
                color={isOverdue ? 'rose' : 'blue-action'}
                textSize="x-small"
                weight="bold"
                size="small"
                className="ml-x-small"
              />
            )}
          </div>

          {/* Number of customers */}
          <div className="mt-small line-height-120 flex-row align-center">
            <Icon type="team" style={{ fontSize: '12px' }} />
            <Text size="small" color="secondary" lineHeight={120}>
              {customersLabel}
            </Text>
          </div>

          {/* Number of team members */}
          <div className="line-height-100 flex-row align-center">
            <Icon type="team" style={{ fontSize: '12px' }} />
            <Text size="small" color="secondary" lineHeight={120}>
              {teamMembersLabel}
            </Text>
          </div>
        </div>
      </div>
    </Popover>
  );
}
