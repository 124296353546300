import React, { Component } from 'react';
import { debounce } from 'lodash';
import { Skeleton } from 'antd';

interface IInfiniteScrollLoadingProps {
  hasMore: boolean;
  loadingElementId: string;
  loadingOffSet?: number;
  loadMore: () => void;
  loaderColSpan?: number;
  customLoader?: React.ReactNode;
  children?: React.ReactNode;
}

interface IInfiniteScrollLoadingState {
  isLoading: boolean;
}

class InfiniteScrollLoading extends Component<IInfiniteScrollLoadingProps, IInfiniteScrollLoadingState> {
  state = {
    isLoading: false,
  };

  listenScrollEvent = debounce(async () => {
    const { hasMore, loadingElementId, loadingOffSet = 0 } = this.props;
    const { isLoading } = this.state;

    if (!hasMore || isLoading) return;
    const loadingElement = document.querySelector(`#${loadingElementId}`);
    // Checks that the page has scrolled to the bottom
    if (
      loadingElement &&
      loadingElement.scrollTop + loadingElement.clientHeight + loadingOffSet >= loadingElement.scrollHeight
    ) {
      await this.load();
    }
  }, 50);

  load = async () => {
    const { loadMore } = this.props;
    await this.setState({ isLoading: true });
    await loadMore();
    await this.setState({ isLoading: false });
  };

  async componentDidMount() {
    const { loadingElementId } = this.props;

    document.querySelector(`#${loadingElementId}`) &&
      document.querySelector(`#${loadingElementId}`).addEventListener('scroll', this.listenScrollEvent);
  }

  componentWillUnmount() {
    const { loadingElementId } = this.props;
    document.querySelector(`#${loadingElementId}`) &&
      document.querySelector(`#${loadingElementId}`).removeEventListener('scroll', this.listenScrollEvent);
  }

  render() {
    const { children, loaderColSpan = 1 } = this.props;
    const { isLoading } = this.state;
    return (
      <>
        {children}
        {isLoading &&
          (!this.props.customLoader ? (
            <tr style={{ borderBottom: '0px solid !important' }}>
              <td colSpan={loaderColSpan}>
                <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
              </td>
            </tr>
          ) : (
            this.props.customLoader
          ))}
      </>
    );
  }
}

export default InfiniteScrollLoading;
