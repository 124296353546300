import React, { Component } from 'react';

import { Avatar, Icon, Input, Radio, Switch, Tooltip } from 'antd';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Text } from 'common-components/typography';
import { HyperlinkButton, IconButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ShiftStatusTag } from 'views/group-services/session-details/team-members/shift-slot-table/ShiftStatusTag';

import moment from 'moment-timezone';
import _ from 'lodash';
import { Popover, Spinner } from '@blueprintjs/core';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import {
  FilterType,
  GroupServiceSessionStatus,
  PublishShiftApplicationAvailability,
  SessionStatus,
  ShiftSlotStatus
} from 'utilities/enum-utils';
import { ISession, ISessionSupportWorker } from 'interfaces/session-interfaces';
import { IFilter } from 'interfaces/filter-interfaces';
import { FilterSection } from 'common-components/filter';
import { IShiftSlot } from 'interfaces/shift-interfaces';

interface IAssignWorkerModalProps {
  isOpen: boolean;
  shiftSlot: IShiftSlot | any;
  onClose: any;
  doAssignWorkerToShift: typeof dispatch.groupServiceStore.doAssignWorkerToShift;
  session: ISession | any;
  sessionSupportWorkers: typeof state.groupServiceStore.sessionSupportWorkers;
  doFetchSessionSupportWorkers: typeof dispatch.groupServiceStore.doFetchSessionSupportWorkers;
}

interface IAssignWorkerModalState {
  step: number;
  selectedWorker: ISessionSupportWorker;
  canManuallyClose: boolean;
  targetStatus: string;
  filters: IFilter[];
  isSearchAvailable: boolean;
  isLoading: boolean;
  isSearching: boolean;
  isWarningNotEnoughWorker: boolean;
  refreshShiftSlots: boolean;
}

const availableFilters = [
  FilterType.QUALIFICATIONS,
  FilterType.SKILLS,
  FilterType.RELIGIONS,
  FilterType.LANGUAGES,
  FilterType.GENDER,
  FilterType.INTEREST,
  FilterType.SPECIALITIES
];

class AssignWorkerModal extends Component<IAssignWorkerModalProps, IAssignWorkerModalState> {
  state = {
    step: 1,
    selectedWorker: null,
    canManuallyClose: false,
    targetStatus: ShiftSlotStatus.PENDING,
    filters: [],
    isSearchAvailable: true,
    isLoading: false,
    isSearching: false,
    isWarningNotEnoughWorker: false,
    refreshShiftSlots: false
  };

  private _reset = () => {
    this.setState({
      step: 1,
      selectedWorker: null,
      canManuallyClose: false,
      targetStatus: ShiftSlotStatus.PENDING,
      filters: [],
      isSearchAvailable: true,
      isLoading: false,
      isSearching: false,
      isWarningNotEnoughWorker: false,
      refreshShiftSlots: false
    });
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.setState({ filters });
  };

  private _formatFilterQuery = () => {
    const requestFilter: any = {};
    _.forEach(this.state.filters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'qualifications':
            requestFilter.qualifications = filter.values;
            break;
          case 'skills':
            requestFilter.skills = filter.values;
            break;
          case 'languages':
            requestFilter.languages = filter.values;
            break;
          case 'interests':
            requestFilter.interests = filter.values;
            break;
          case 'religions':
            requestFilter.religions = filter.values;
            break;
          case 'specialities':
            requestFilter.specialities = filter.values;
            break;
          case 'gender':
            requestFilter.gender = filter.values;
            break;
          default:
            break;
        }
      }
    });

    return requestFilter;
  };

  private _onToggleSearchAvailable = async () => {
    const newSearchAvailable = !this.state.isSearchAvailable;
    this.setState({ isSearchAvailable: newSearchAvailable });
    const payload = this._buildPayload();
    payload.isAvailable = newSearchAvailable;
    await this._loadContent(payload);
  };

  private _loadContent = async (payload) => {
    this.setState({ isLoading: true, selectedWorker: null });
    if (payload.startDateTime && payload.endDateTime) {
      await this.props.doFetchSessionSupportWorkers(payload);
    }
    this.setState({ isLoading: false });
  };

  private _searchText = async (txt) => {
    const payload = this._buildPayload();
    await this._loadContent({ ...payload, searchString: txt });
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this.setState({ isSearching: true });
      this._debounceSearch(e.target.value);
    }
  };

  private _buildPayload = () => {
    const { session, shiftSlot } = this.props;
    const { isSearchAvailable } = this.state;
    const { serviceDateTimeId, serviceId } = session;
    const requestFilter = this._formatFilterQuery();

    const payload = {
      serviceDateTimeId,
      serviceId,
      startDateTime: shiftSlot && shiftSlot.shiftStartDateTime,
      endDateTime: shiftSlot && shiftSlot.shiftEndDateTime,
      isAvailable: isSearchAvailable
    };

    return _.isEmpty(requestFilter) ? payload : { ...payload, ...requestFilter };
  };

  private _onAssignTeamMember = async (status: ShiftSlotStatus = null) => {
    const { doAssignWorkerToShift, shiftSlot, session } = this.props;
    const { selectedWorker } = this.state;
    if (!session || !shiftSlot) return;
    if (!selectedWorker) {
      this.setState({ isWarningNotEnoughWorker: true });
      return;
    }

    const { serviceId, serviceDateTimeId } = session;
    const { supportWorkerAttendanceId } = shiftSlot;
    const { supportWorkerId } = selectedWorker;

    this.setState({ step: 2, canManuallyClose: false });
    const payload = {
      serviceId,
      serviceDateTimeId,
      supportWorkerAttendanceId,
      supportWorkerId
    };

    if (status) {
      await doAssignWorkerToShift({ ...payload, shiftSlotStatus: status });
    } else {
      await doAssignWorkerToShift(payload);
    }

    this.setState({ step: 3, canManuallyClose: true, targetStatus: status, refreshShiftSlots: true });
  };

  private _onClose = () => {
    const { onClose } = this.props;
    this._reset();
    onClose({ targetFlag: 'isAssignWorkerOpen' }, this.state.refreshShiftSlots);
  };

  private _onSelectWorker = (selectedWorker) => {
    this.setState({ selectedWorker, isWarningNotEnoughWorker: false });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    if (this.props.session && this.props.session !== prevProps.session) {
      this._reset();
    }
    if (this.props.isOpen && this.props.isOpen !== prevProps.isOpen && this.props.session) {
      const payload = this._buildPayload();
      await this._loadContent(payload);
    }
    if (this.state.filters !== prevState.filters) {
      const payload = this._buildPayload();
      await this._loadContent(payload);
    }
  };

  render() {
    const { shiftSlot, sessionSupportWorkers, session } = this.props;
    const { selectedWorker } = this.state;

    if (_.isEmpty(shiftSlot)) {
      return <></>;
    }

    const { shiftStartDateTime, shiftEndDateTime } = shiftSlot;
    const assignedWorkerFullName =
      shiftSlot.firstName && shiftSlot.lastName ? `${shiftSlot.firstName} ${shiftSlot.lastName}` : 'Not assigned';
    const selectedWorkerFullName = selectedWorker
      ? `${selectedWorker.firstName || ''} ${selectedWorker.lastName || ''}`
      : '';

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title='Assign team member'
        onClose={this._onClose}
        width={this.state.step === 1 ? 'x2-large' : 'medium'}
        canCloseOutside={this.state.canManuallyClose}
      >
        {this.state.step === 1 && (
          <div>
            {/*Title */}
            <div className='mb-large'>
              <Text>Select the team member to assign to this shift.</Text>
            </div>

            {/* Display icon */}
            <div className='rounded-big bordered border-standard-gray flex-row line-height-120 mb-large'>
              <div className='p-medium bg-quaternary flex-column justify-center align-end'>
                <Text lineHeight={120} className='text-align-right'>
                  {/*9:30 AM*/}
                  {moment.tz(shiftStartDateTime, session.timezone).format('h:mm A')}
                  <br />
                  {/*10:30 AM*/}
                  {moment.tz(shiftEndDateTime, session.timezone).format('h:mm A')}
                </Text>
              </div>
              <div className='flex-row p-medium bg-quaternary align-center'>
                <div>
                  {!_.isEmpty(shiftSlot.attachmentUrl) && (
                    <Avatar icon='user' shape='square' src={shiftSlot.attachmentUrl} className='mr-medium' />
                  )}

                  {_.isEmpty(shiftSlot.attachmentUrl) && <Avatar icon='user' shape='square' className='mr-medium' />}
                </div>
                <div>
                  <Text color={shiftSlot.firstName ? 'secondary' : ''}>{assignedWorkerFullName}</Text>
                </div>
              </div>
              <div className='p-medium flex-1 bg-quaternary flex-column justify-center'>
                <div className='inline-block'>
                  <ShiftStatusTag shiftStatus={shiftSlot.shiftSlotStatus} />
                </div>
              </div>
            </div>

            {/* Content panel */}
            <div className='flex-row line-height-120 mb-medium'>
              {/* Left panel */}
              <div className='bg-quaternary p-medium rounded mr-medium' style={{ width: '360px' }}>
                <div className='mb-medium'>
                  <FieldLabel text={'FILTERS'} />
                </div>
                <div className='mb-medium'>
                  <Text lineHeight={120}>Show team members who...</Text>
                </div>
                <div className='mb-medium'>
                  <Switch
                    size='small'
                    checked={this.state.isSearchAvailable}
                    onChange={this._onToggleSearchAvailable}
                  />
                  <Text lineHeight={120} className='ml-small' size='regular'>
                    Are available during the scheduled shift(s)
                  </Text>
                </div>

                <div>
                  <FilterSection
                    availableFilters={availableFilters}
                    filters={this.state.filters}
                    onChangeFilter={this._onChangeFilter}
                    displayTimezone={''}
                    usePortal={false}
                  />
                </div>
              </div>

              {/* Right panel */}
              <div className='flex-1'>
                <div className='mb-medium'>
                  <Text size={'regular'} color='secondary' lineHeight={120}>
                    {/* TODO @ Jir - Update this number */}
                    <b>{this.state.selectedWorker === null ? '0' : '1'}/1</b> shift(s) to fill
                  </Text>
                </div>
                <div className='mb-medium'>
                  <Input.Search
                    size='large'
                    placeholder={'Search for team members...'}
                    onChange={this._onEnterSearchText}
                    loading={this.state.isSearching}
                    allowClear
                  />{' '}
                </div>

                <div
                  className='bordered border-standard-gray rounded'
                  style={{ minHeight: '25vh', maxHeight: '35vh', overflowY: 'auto' }}
                >
                  {_.map(sessionSupportWorkers, (worker) => (
                    <AssignWorkerItem
                      worker={worker}
                      onSelectWorker={this._onSelectWorker}
                      isSelected={
                        worker.supportWorkerId ===
                        (this.state.selectedWorker && this.state.selectedWorker.supportWorkerId)
                      }
                      key={worker.supportWorkerId}
                    />
                  ))}
                </div>
              </div>
            </div>

            <ActionModalFooter align='right'>
              <div className='flex-row justify-end'>
                <SecondaryButton size='large' className='mr-medium' onClick={this._onClose}>
                  Cancel
                </SecondaryButton>
                {session.sessionStatus === GroupServiceSessionStatus.COMPLETED ? (
                  <PrimaryButton size='large' onClick={() => this._onAssignTeamMember()} className='rounded-left'>
                    Assign team member
                  </PrimaryButton>
                ) : (
                  <>
                    <PrimaryButton
                      size='large'
                      // onClick={() => this._onAssignTeamMember(ShiftSlotStatus.PENDING)}
                      onClick={() => this._onAssignTeamMember(ShiftSlotStatus.CONFIRMED)}
                      className='rounded-left'
                    >
                      Assign as <b> Confirmed</b>
                    </PrimaryButton>

                    <Popover
                      content={
                        <ActionMenu>
                          <ActionMenuItem
                            text='Assign as Pending'
                            // onClick={() => this._onAssignTeamMember(ShiftSlotStatus.CONFIRMED)}
                            onClick={() => this._onAssignTeamMember(ShiftSlotStatus.PENDING)}
                          />
                        </ActionMenu>
                      }
                      position='bottom-left'
                      usePortal={false}
                    >
                      <IconButton className='rounded-right ml-x2-small' icon='down' size='large' />
                    </Popover>
                  </>
                )}
              </div>
              {this.state.isWarningNotEnoughWorker && (
                <div>
                  <Text color='red-dark'>You must select a team member to assign.</Text>
                </div>
              )}
            </ActionModalFooter>
          </div>
        )}

        {this.state.step === 2 && (
          <div className='line-height-135 anim-slide-left'>
            <div className='flex-column align-center mv-large justify-center'>
              <div className='mb-medium'>
                <Spinner size={80} />
              </div>
              <div className='text-align-center'>
                <Text color='secondary' weight='bold'>
                  Assigning team member to shift...
                </Text>
                <br />
                <Text color='secondary'>This won't take long.</Text>
              </div>
            </div>
          </div>
        )}

        {this.state.step === 3 && (
          <div className='anim-fade-in-fast line-height-135'>
            <div className='mb-medium'>
              <Text lineHeight={135}>You have successfully added a team member to the following slot : </Text>
            </div>

            <div className='mb-medium'>
              <FieldLabel text={'SHIFT TIME'} />
              <div className='mt-x2-small'>
                <Text>
                  {moment.tz(shiftStartDateTime, session.timezone).format('h:mm A')} -{' '}
                  {moment.tz(shiftEndDateTime, session.timezone).format('h:mm A D MMMM YYYY')}
                </Text>
              </div>
            </div>

            <div className='mb-large'>
              <FieldLabel text={'ASSIGNED TEAM MEMBER'} />
              <div className='mt-x-small flex-row align-center'>
                <Avatar
                  className={'mr-small'}
                  src={this.state.selectedWorker && this.state.selectedWorker.attachmentUrl}
                  shape={'square'}
                />
                <Text>
                  {selectedWorkerFullName} {this.state.targetStatus && `(${_.capitalize(this.state.targetStatus)})`}
                </Text>
              </div>
            </div>

            <ActionModalFooter align='right'>
              <PrimaryButton size='large' onClick={this._onClose}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

class AssignWorkerItem extends Component<{
  worker: ISessionSupportWorker;
  onSelectWorker: any;
  isSelected: boolean;
}> {
  onSelect = () => {
    const { worker, onSelectWorker } = this.props;
    onSelectWorker(worker);
  };

  render() {
    const { worker, isSelected } = this.props;
    const fullName = `${worker.firstName} ${worker.lastName}`;

    const isAvailable =
      worker.availability === PublishShiftApplicationAvailability.AVAILABLE ||
      worker.availability === PublishShiftApplicationAvailability.OUTSIDE_GENERAL_AVAILABILITY ||
      worker.availability === PublishShiftApplicationAvailability.UNAVAILABLE_CAN_BE_OVERRIDDEN;
    const isOutsideGeneralAvailability = worker.availability === PublishShiftApplicationAvailability.OUTSIDE_GENERAL_AVAILABILITY;
    const isUnavailableCanBeOverridden = worker.availability === PublishShiftApplicationAvailability.UNAVAILABLE_CAN_BE_OVERRIDDEN;

    const unavailableStyle = {
      iconClassName: 'text-color-red-dark bg-red-lightest',
      text: 'Unavailable',
      iconType: 'close'
    };

    const unavailableCanBeOverriddenStyle = {
      iconClassName: 'text-color-red-dark bg-red-lightest',
      text: 'Unavailable (can be overridden)',
      iconType: 'close'
    };

    const outsideGeneralAvailabilityStyle = {
      iconClassName: 'text-color-orange bg-orange-lightest',
      text: 'Outside general availability',
      iconType: 'question'
    };

    const availableStyle = {
      iconClassName: 'text-color-green-dark bg-green-lightest',
      text: 'Available',
      iconType: 'check'
    };

    const iconStyle = !isAvailable
      ? unavailableStyle
      : isUnavailableCanBeOverridden
      ? unavailableCanBeOverriddenStyle
      : isOutsideGeneralAvailability
      ? outsideGeneralAvailabilityStyle
      : availableStyle;

    return (
      <div
        className={`flex-row ph-medium align-center hover-bg-quaternary  ${isAvailable &&
          'cursor-pointer'} select-none`}
        style={{ paddingTop: '12px', paddingBottom: '12px' }}
        onClick={isAvailable ? this.onSelect : () => false}
      >
        <Radio checked={isSelected} disabled={!isAvailable} />
        <Avatar
          src={worker.attachmentUrl}
          size='large'
          className='ml-medium mr-medium'
          shape={'square'}
          style={{ opacity: !isAvailable ? 0.5 : 1 }}
        />
        <div
          style={{ width: '220px', flexDirection: 'column', opacity: !isAvailable ? 0.5 : 1 }}
          className='line-height-150'
        >
          <Text lineHeight={150}>{fullName}</Text> <br />
          <div className='line-height-100 align-center flex-row'>
            <Tooltip
              title={
                isUnavailableCanBeOverridden
                  ? 'This booking occurs during a time where the team member has indicated they are unavailable. You can still assign them to this shift but ensure you have checked with them before doing so.'
                  : ''
              }
              placement='top'
            >
              <Icon
                type={iconStyle.iconType}
                className={`mr-x-small ${iconStyle.iconClassName} rounded-full p-x-small`}
                style={{ fontSize: '10px' }}
              />
            </Tooltip>
            <Text size='regular'>{iconStyle.text}</Text>
          </div>
        </div>

        {/*
        <div className="flex-row align-center line-height-100">
          <Icon type="info-circle" className="mr-x-small text-color-secondary" />
          <Text lineHeight={100} size="regular" color="secondary">
            Assigned to session
          </Text>
        </div>
        */}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  sessionSupportWorkers: state.groupServiceStore.sessionSupportWorkers
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAssignWorkerToShift: dispatch.groupServiceStore.doAssignWorkerToShift,
  doFetchSessionSupportWorkers: dispatch.groupServiceStore.doFetchSessionSupportWorkers
});

export default connect(
  mapState,
  mapDispatch
)(AssignWorkerModal);
