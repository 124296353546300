import { Col, notification, Row } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { IRootDispatch, IRootState, state, dispatch } from 'stores/rematch/root-store';
import { Paragraph } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';
import { IGuardiansInfo } from 'interfaces/customer-interfaces';
import CommonUtils from 'utilities/common-utils';

interface IRevokeInvitationModalProps {
  isRevokeModalOpen: boolean;
  closeRevokeModal: () => void;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  doCancelCustomerConnectionRequest: typeof dispatch.customersStore.doCancelCustomerConnectionRequest;
  isGuardianModal?: boolean;
  selectedGuardian: IGuardiansInfo;
}

interface IRevokeInvitationModalState {
  isLoadingCancel: boolean;
}

class RevokeInvitationModal extends Component<IRevokeInvitationModalProps, IRevokeInvitationModalState> {
  state = {
    isLoadingCancel: false
  };

  private _closeModal = () => {
    this.props.closeRevokeModal();
  };

  private _onRevokeConnectionRequest = async (customerConnectionRequestId: string) => {
    try {
      if (!customerConnectionRequestId) throw Error('customerConnectionRequestId is wrong');

      const { selectedCustomer, doCancelCustomerConnectionRequest, isGuardianModal, selectedGuardian } = this.props;
      const firstName = isGuardianModal ? selectedGuardian.firstName : selectedCustomer.firstName;
      const lastName = isGuardianModal ? selectedGuardian.lastName : selectedCustomer.lastName;
      this.setState({ isLoadingCancel: true });

      await doCancelCustomerConnectionRequest({ customerConnectionRequestId: customerConnectionRequestId });
      this.setState({ isLoadingCancel: false });

      notification.open({
        message: <span className={'text-weight-bolder'}>Invitation revoked</span>,
        description: (
          <span>
            You have successfully revoked{' '}
            <span className={'text-weight-bolder'}>
              {firstName} {lastName}
            </span>{' '}
            invitation to the GoodHuman app
          </span>
        )
      });
      this._closeModal();
    } catch (e) {
      notification.error({ message: 'Ops, something wrong', description: e });
      this.setState({ isLoadingCancel: false });
    }
  };

  render() {
    const { isRevokeModalOpen, selectedCustomer, isGuardianModal, selectedGuardian } = this.props;
    //For getting customerConnectionRequestId of the current active request
    const activeConnectionRequest = CommonUtils.getActiveConnectionRequestHelper(
      isGuardianModal ? selectedGuardian : selectedCustomer
    );
    //END getting customerConnectionRequestId

    return (
      <>
        <ActionModal isOpen={isRevokeModalOpen} title={'Revoke invitation'} onClose={this._closeModal}>
          <Paragraph>Are you sure you want to revoke the invitation to the customer app.</Paragraph>

          <ActionModalFooter className={'mt-large'}>
            <Row type={'flex'} className={'justify-end'}>
              <Col className="mr-medium">
                <SecondaryButton size="large" onClick={this._closeModal}>
                  Cancel
                </SecondaryButton>
              </Col>
              <Col>
                <PrimaryButton
                  loading={this.state.isLoadingCancel}
                  onClick={() =>
                    this._onRevokeConnectionRequest(
                      !_.isEmpty(activeConnectionRequest) && activeConnectionRequest[0].customerConnectionRequestId
                    )
                  }
                  size="large"
                >
                  Revoke invitation
                </PrimaryButton>
              </Col>
            </Row>
          </ActionModalFooter>
        </ActionModal>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCancelCustomerConnectionRequest: dispatch.customersStore.doCancelCustomerConnectionRequest
});

export default connect(
  mapState,
  mapDispatch
)(RevokeInvitationModal);
