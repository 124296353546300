import React, { Component } from 'react';
import { FieldLabel, Title } from 'common-components/typography';
import { Col, Empty, Form, Input, Skeleton, Tabs, Icon, Popover } from 'antd';
import { ProgressBar } from '@blueprintjs/core';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import TeamListingItemV2 from './components/TeamListingItemV2';
import WorkerViewTabPopover from './components/TeamViewTabPopover';
import _ from 'lodash';
import { GridHeader } from 'common-components/grids';
import { FormComponentProps } from 'antd/es/form';
import { FilterType, CustomViewsModalType } from 'utilities/enum-utils';
import { FilterSection } from 'common-components/filter';
import { HyperlinkButton, SecondaryButton } from 'common-components/buttons';
import CustomViewPopover from 'common-components/custom-views/popover/CustomViewPopover';
import CustomViewsModal from 'common-components/custom-views/modals/CustomViewsModal';
import CommonUtils from 'utilities/common-utils';
import { ICustomView } from 'interfaces/custom-views-interface';

const { Search } = Input;
const { TabPane } = Tabs;
interface ITeamListingV2Props extends RouteComponentProps, FormComponentProps {
  workerPageList: typeof state.teamStore.workerPageList;
  workerPageFilter: typeof state.teamStore.workerPageFilter;
  workerViews: typeof state.teamStore.workerViews;
  workerListingActiveTab: typeof state.teamStore.workerListingActiveTab;
  defaultWorkerViews: typeof state.teamStore.defaultWorkerViews;
  displayedWorkerListingTabs: typeof state.teamStore.displayedWorkerListingTabs;
  doFetchWorkerPageList: typeof dispatch.teamStore.doFetchWorkerPageList;
  doFetchWorkerViews: typeof dispatch.teamStore.doFetchWorkerViews;
  setWorkerPageList: typeof dispatch.teamStore.setWorkerPageList;
  setWorkerPageFilter: typeof dispatch.teamStore.setWorkerPageFilter;
  setDisplayedWorkerListingTabs: typeof dispatch.teamStore.setDisplayedWorkerListingTabs;
  setWorkerListingActiveTab: typeof dispatch.teamStore.setWorkerListingActiveTab;
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;
  doAddWorkerView: typeof dispatch.teamStore.doAddWorkerView;
  doDeleteWorkerView: typeof dispatch.teamStore.doDeleteWorkerView;
  doUpdateWorkerViewTab: typeof dispatch.teamStore.doUpdateWorkerViewTab;
  doDuplicateWorkerView: typeof dispatch.teamStore.doDuplicateWorkerView;
}

const availableFilters = [
  FilterType.WORKER,
  FilterType.QUALIFICATIONS,
  FilterType.RELIGIONS,
  FilterType.GENDER,
  FilterType.SPECIALITIES,
  FilterType.LANGUAGES,
  FilterType.INTEREST,
  FilterType.WORKER_ALERTS,
  FilterType.SERVICE,
  FilterType.USER_LOCATION_BY_STATE
];

const defaultFilterValue = [{ [FilterType.WORKER]: [] }, { [FilterType.SERVICE]: [] }];

class TeamListingV2 extends Component<ITeamListingV2Props, any> {
  state = {
    isLoading: false,
    addWorkerDrawerVisible: false,
    isSearching: false,
    viewModalType: CustomViewsModalType.CREATE,
    showWorkerViewPopover: false,
    showWorkerViewModal: false,
    showTabIconPopover: ''
  };

  async componentDidMount() {
    const {
      doFetchWorkerPageList,
      doFetchWorkerViews,
      setWorkerPageFilter,
      setDisplayedWorkerListingTabs,
      setWorkerListingActiveTab,
      workerListingActiveTab,
      displayedWorkerListingTabs,
      defaultWorkerViews
    } = this.props;
    this.props.setSelectedSideNavMenuKeys(['/team']);
    this.setState({ isLoading: true });
    await doFetchWorkerViews({});
    const appliedFilters = this._generateFilters();
    await setWorkerPageFilter(appliedFilters);
    await doFetchWorkerPageList({});
    const showActiveTab = _.get(history, 'state.state.showActiveTab');
    if (showActiveTab && workerListingActiveTab) {
      !workerListingActiveTab.isDefault &&
        (await setDisplayedWorkerListingTabs([...displayedWorkerListingTabs, workerListingActiveTab]));
    } else {
      setWorkerListingActiveTab(defaultWorkerViews[0]);
    }
    this.setState({ isLoading: false });
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { workerListingActiveTab, doFetchWorkerPageList, setWorkerPageList, setWorkerPageFilter } = this.props;

    if (!_.isEqual(prevProps.workerListingActiveTab, workerListingActiveTab)) {
      await setWorkerPageFilter(this._generateFilters());
    }

    if (!_.isEqual(prevProps.workerPageFilter, this.props.workerPageFilter)) {
      this.setState({ isLoading: true });
      setWorkerPageList([]);
      await doFetchWorkerPageList({
        filters: this.props.workerPageFilter
      });

      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount() {
    const { setWorkerPageFilter, setDisplayedWorkerListingTabs } = this.props;
    setWorkerPageFilter([]);
    setDisplayedWorkerListingTabs([]);
  }

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _searchText = async (txt) => {
    const { doFetchWorkerPageList, setWorkerPageFilter, workerPageFilter } = this.props;
    const search = {
      filter: 'search',
      values: txt,
      selectionLabel: 'All'
    };
    const searchFilterIndex = _.findIndex(workerPageFilter, (workerFilter: any) => workerFilter.filter === 'search');
    searchFilterIndex >= 0 ? (workerPageFilter[searchFilterIndex] = search) : workerPageFilter.push(search);
    setWorkerPageFilter(workerPageFilter);
    await doFetchWorkerPageList({ sortByRelevance: true });
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setWorkerPageFilter(filters);
  };

  private _generateFilters = () => {
    const { workerListingActiveTab, defaultWorkerViews } = this.props;
    let activeTab = defaultWorkerViews[0];
    if (!_.isEmpty(workerListingActiveTab) && _.get(workerListingActiveTab, 'filterValue')) {
      activeTab = workerListingActiveTab;
    }
    return activeTab.filterValue
      .filter((filter) => !_.isEmpty(filter))
      .map((filter) => {
        const [[key, value]] = Object.entries(filter);
        return {
          filter: key,
          values: value || [],
          selectionLabel: CommonUtils.getFilterText(key, value)
        };
      });
  };

  private _onSaveView = (tab: ICustomView) => {
    if (tab && tab.isOwner) {
      this._openWorkerViewModal(CustomViewsModalType.SAVE_VIEW);
    } else if (tab && tab.isDefault) {
      this._openWorkerViewModal(CustomViewsModalType.SAVE_DEFAULT_VIEW);
    } else {
      this._openWorkerViewModal(CustomViewsModalType.SAVE_AS_NEW_COPY_FROM_OTHERS);
    }
  };

  private _changeTab = async (selectedTabId) => {
    const {
      workerPageFilter,
      displayedWorkerListingTabs,
      defaultWorkerViews,
      setWorkerPageFilter,
      setWorkerListingActiveTab
    } = this.props;
    const displayedTabs = [...defaultWorkerViews, ...displayedWorkerListingTabs];
    const activeTab = displayedTabs.find((tab) => tab.customViewId === selectedTabId);
    setWorkerListingActiveTab(activeTab);
    this.setState({ isLoading: true });
    setWorkerPageFilter({ ...workerPageFilter, userType: event });
    this.setState({ isLoading: false });
  };

  private _renderExtraTabs = () => {
    const {
      displayedWorkerListingTabs,
      workerViews,
      setDisplayedWorkerListingTabs,
      setWorkerListingActiveTab
    } = this.props;
    return (
      <>
        <Popover
          content={
            <CustomViewPopover
              pageViews={workerViews}
              displayedPageListingTabs={displayedWorkerListingTabs}
              onCreateNewView={() => this._openWorkerViewModal(CustomViewsModalType.CREATE)}
              onDisplayTab={this._toggleAddViewPopOverChange}
              setDisplayedPageListingTabs={setDisplayedWorkerListingTabs}
              setPageListingActiveTab={setWorkerListingActiveTab}
            />
          }
          trigger="click"
          visible={this.state.showWorkerViewPopover}
          onVisibleChange={this._toggleAddViewPopOverChange}
        >
          <HyperlinkButton className="ph-large">+ Add view</HyperlinkButton>
        </Popover>
        <HyperlinkButton className="ph-large" onClick={this._goWorkerAllViews}>
          All views
        </HyperlinkButton>
      </>
    );
  };

  private _renderViewTabIcon = (tab: ICustomView) => {
    const togglePopOver = (value) => {
      const showTabIconPopover = value ? tab.customViewId : '';
      this.setState({ showTabIconPopover });
    };
    return (
      <Popover
        content={
          <WorkerViewTabPopover
            onHidePopOver={togglePopOver}
            currentTab={tab}
            onShowWorkerViewModal={this._openWorkerViewModal}
          />
        }
        visible={this.state.showTabIconPopover === tab.customViewId}
        onVisibleChange={togglePopOver}
        placement="bottom"
        trigger="click"
      >
        <Icon className="ml-small mr-none" type="caret-down" />
      </Popover>
    );
  };

  private _toggleAddViewPopOverChange = (visible) => {
    this.setState({ showWorkerViewPopover: visible });
  };

  private _goWorkerAllViews = () => {
    const { history } = this.props;
    history.push(`/team/all-views`);
  };

  private _openWorkerViewModal = (type: CustomViewsModalType) => {
    this.setState({ showWorkerViewPopover: false });
    this.setState({ viewModalType: type, showWorkerViewModal: true });
  };

  private _closeWorkerViewModal = () => {
    this.setState({ viewModalType: CustomViewsModalType.CREATE, showWorkerViewModal: false });
  };

  render() {
    const {
      history,
      workerPageList,
      workerViews,
      workerListingActiveTab,
      workerPageFilter,
      defaultWorkerViews,
      displayedWorkerListingTabs,
      setWorkerPageFilter,
      doAddWorkerView,
      doDeleteWorkerView,
      doUpdateWorkerViewTab,
      doDuplicateWorkerView
    } = this.props;

    const { showWorkerViewModal, viewModalType } = this.state;

    const renderExtraTabs = this._renderExtraTabs();
    const generateActiveTabClassName = (activeTab, tab) =>
      _.get(activeTab, 'customViewId') === tab.customViewId ? `text-color-blue-action` : 'text-color-secondary';

    return (
      <div>
        <div>
          <Title level={3} className="mv-none" lineHeight={150}>
            Team Members
          </Title>
        </div>

        <div className="mb-x-large" style={{ position: 'sticky', top: '0px', zIndex: 10 }}>
          <div className="bg-white mt-large">
            <CustomViewsModal
              isOpen={showWorkerViewModal}
              onCloseViewModal={this._closeWorkerViewModal}
              onUpdateViewModal={this._openWorkerViewModal}
              type={viewModalType}
              pageViews={workerViews}
              pageFilter={workerPageFilter}
              pageListingActiveTab={workerListingActiveTab}
              defaultViews={defaultWorkerViews}
              defaultFilterValue={defaultFilterValue}
              setPageFilter={setWorkerPageFilter}
              doAddView={doAddWorkerView}
              doDeleteView={doDeleteWorkerView}
              doUpdateViewTab={doUpdateWorkerViewTab}
              doDuplicateView={doDuplicateWorkerView}
            />
            <Tabs
              type="card"
              tabBarExtraContent={renderExtraTabs}
              activeKey={_.get(workerListingActiveTab, 'customViewId')}
              defaultActiveKey="TEAM_MEMBERS"
              animated={true}
              onChange={this._changeTab}
            >
              {defaultWorkerViews.map((tab) => {
                return (
                  <TabPane
                    tab={<span className={generateActiveTabClassName(workerListingActiveTab, tab)}>{tab.name}</span>}
                    key={tab.customViewId}
                  />
                );
              })}
              {displayedWorkerListingTabs.map((tab: ICustomView) => {
                return (
                  <TabPane
                    tab={
                      <span className={generateActiveTabClassName(workerListingActiveTab, tab)}>
                        {tab.isPinned && <Icon type="pushpin" theme={'filled'} className={`mr-small`} />}
                        {tab.name}
                        {this._renderViewTabIcon(tab)}
                      </span>
                    }
                    key={tab.customViewId}
                  />
                );
              })}
            </Tabs>
            <div className="flex-row align-center justify-start mv-medium">
              <div>
                <Search
                  size="large"
                  placeholder="Search by name"
                  onChange={this._onEnterSearchText}
                  loading={this.state.isSearching}
                  style={{ width: '250px' }}
                  allowClear={true}
                />
              </div>

              <div className="ml-small">
                <FilterSection
                  availableFilters={availableFilters}
                  filters={workerPageFilter}
                  onChangeFilter={this._onChangeFilter}
                  displayTimezone={null}
                  displayMoreFilter={true}
                />
              </div>
              <div className="flex-row flex-grow justify-end">
                <SecondaryButton
                  className="text-color-blue-action mr-x-small ml-small"
                  onClick={() => this._onSaveView(workerListingActiveTab)}
                >
                  <Icon className="ml-small mr-none" type="save" /> Save view
                </SecondaryButton>
              </div>
            </div>
            <GridHeader bordered>
              <Col span={5} className="bg-white">
                <FieldLabel text={'Team Member'} />
              </Col>
              <Col span={4} className="bg-white">
                <FieldLabel text={'Location'} />
              </Col>
              <Col span={15} className="bg-white">
                <FieldLabel text={'Alerts'} />
              </Col>
            </GridHeader>
          </div>
        </div>
        {this.state.isLoading ? (
          <div className="">
            <div className="pv-large">
              <ProgressBar />
            </div>
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
          </div>
        ) : !_.isEmpty(workerPageList) ? (
          _.map(workerPageList, (item, index) => {
            return <TeamListingItemV2 workerItem={item} history={history} key={index} />;
          })
        ) : (
          <Empty description={'No Workers Found'} className="mt-large" />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  workerPageList: state.teamStore.workerPageList,
  workerPageFilter: state.teamStore.workerPageFilter,
  workerViews: state.teamStore.workerViews,
  workerListingActiveTab: state.teamStore.workerListingActiveTab,
  defaultWorkerViews: state.teamStore.defaultWorkerViews,
  displayedWorkerListingTabs: state.teamStore.displayedWorkerListingTabs
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchWorkerPageList: dispatch.teamStore.doFetchWorkerPageList,
  doFetchWorkerViews: dispatch.teamStore.doFetchWorkerViews,
  setWorkerPageFilter: dispatch.teamStore.setWorkerPageFilter,
  setWorkerPageList: dispatch.teamStore.setWorkerPageList,
  setDisplayedWorkerListingTabs: dispatch.teamStore.setDisplayedWorkerListingTabs,
  setWorkerListingActiveTab: dispatch.teamStore.setWorkerListingActiveTab,
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys,
  doAddWorkerView: dispatch.teamStore.doAddWorkerView,
  doDeleteWorkerView: dispatch.teamStore.doDeleteWorkerView,
  doUpdateWorkerViewTab: dispatch.teamStore.doUpdateWorkerViewTab,
  doDuplicateWorkerView: dispatch.teamStore.doDuplicateWorkerView
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<ITeamListingV2Props>()(TeamListingV2));
