import React, { Component } from 'react';
import { SubTitle, Text, Title } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { Avatar, Empty, Select, Skeleton } from 'antd';
import * as H from 'history';
import Search from 'antd/es/input/Search';
import _ from 'lodash';
import '../css/plan-management-listing.css';
import { PlanManagerType } from 'utilities/enum-utils';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';

interface IPlanManagementCustomerOverviewPanelProps {
  planManagementCustomers: typeof state.planManagementStore.planManagementCustomers;
  planManagementCustomerFilters: typeof state.planManagementStore.planManagementCustomerFilters;
  setPlanManagementCustomerFilters: typeof dispatch.planManagementStore.setPlanManagementCustomerFilters;
  doFetchPlanManagementCustomers: typeof dispatch.planManagementStore.doFetchPlanManagementCustomers;
  history: H.History;
}

interface IPlanManagementCustomerOverviewPanelState {
  isSearching: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
}

const CustomersEmptyState = () => (
  <tr>
    <td colSpan={4} className="text-align-center mb-x-large">
      <div className="">
        <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
      <Text size="x2-large" color="secondary" weight="bold">
        No Customers found.
      </Text>{' '}
      <br /> <br />
      <Text color="secondary">All customers under this filter will appear here. </Text>
      <Text color="secondary">Try adjusting your filter.</Text>
    </td>
  </tr>
);

class PlanManagementCustomerOverviewPanel extends Component<
  IPlanManagementCustomerOverviewPanelProps,
  IPlanManagementCustomerOverviewPanelState
> {
  state = {
    isSearching: false,
    isLoading: false,
    isLoadingMore: false,
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date()
  };

  private _applyFilter = async () => {
    await this.setState({
      page: 1,
      isLoading: true
    });
    const requestFilter = this._formatFilterQuery();
    await this.props.doFetchPlanManagementCustomers({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });
    this.setState({ isLoading: false });
  };

  private _formatFilterQuery = () => {
    const requestFilter: any = {};
    _.forEach(this.props.planManagementCustomerFilters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'planManagerType':
            requestFilter.planManagerType = filter.values;
            break;
          case 'searchString':
            requestFilter.searchString = filter.values;
        }
      }
    });
    return requestFilter;
  };

  private _changePlanManagerTypeFilter = async (e) => {
    const { planManagementCustomerFilters, setPlanManagementCustomerFilters } = this.props;
    let newPlanManagementCustomerFilters = _.clone(planManagementCustomerFilters);
    const existingSearchIndex = _.findIndex(
      planManagementCustomerFilters,
      (filter: any) => filter.filter === 'planManagerType'
    );
    if (existingSearchIndex > -1) {
      if (e === 'ALL') {
        newPlanManagementCustomerFilters.splice(existingSearchIndex, 1);
      } else {
        newPlanManagementCustomerFilters[existingSearchIndex].values = [e];
      }
    } else if (e !== 'ALL') {
      newPlanManagementCustomerFilters.push({ filter: 'planManagerType', values: [e] });
    }
    setPlanManagementCustomerFilters(newPlanManagementCustomerFilters);
    this.setState({ isSearching: false });
  };

  private _onEnterSearchText = (e) => {
    const searchLength = _.trim(e.target.value).length;
    if (searchLength >= 3 || searchLength === 0) {
      this.setState({ isSearching: true });
      this._debounceSearch(_.trim(e.target.value));
    }
  };

  private _searchText = async (txt) => {
    const { planManagementCustomerFilters, setPlanManagementCustomerFilters } = this.props;
    let newPlanManagementCustomerFilters = _.clone(planManagementCustomerFilters);
    const existingSearchIndex = _.findIndex(
      planManagementCustomerFilters,
      (filter: any) => filter.filter === 'searchString'
    );
    if (existingSearchIndex > -1) {
      if (txt === '') {
        newPlanManagementCustomerFilters.splice(existingSearchIndex, 1);
      } else {
        newPlanManagementCustomerFilters[existingSearchIndex].values = txt;
      }
    } else {
      newPlanManagementCustomerFilters.push({ filter: 'searchString', values: txt });
    }
    setPlanManagementCustomerFilters(newPlanManagementCustomerFilters);
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _fetchMorePlanManagementCustomers = async () => {
    const { doFetchPlanManagementCustomers } = this.props;
    await this.setState({ page: this.state.page + 1, isLoadingMore: true });
    const requestFilter = this._formatFilterQuery();
    await doFetchPlanManagementCustomers({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });
    this.setState({ isLoadingMore: false });
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.planManagementCustomerFilters !== this.props.planManagementCustomerFilters) {
      await this._applyFilter();
    }
  }

  private _goToCustomerDetailView = (customerUserId) => {
    this.props.history.push(`/plan-management/customer/details/${customerUserId}`);
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    await this.props.doFetchPlanManagementCustomers({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      planManagerType: [PlanManagerType.INTERNAL]
    });
    this.setState({ isLoading: false });
  };

  render() {
    const { planManagementCustomers, planManagementCustomerFilters } = this.props;
    const { isLoading, isLoadingMore } = this.state;

    const searchString = _.find(planManagementCustomerFilters, (filter: any) => filter.filter === 'searchString')
      ? planManagementCustomerFilters[
          _.findIndex(planManagementCustomerFilters, (filter: any) => filter.filter === 'searchString')
        ].values
      : undefined;
    const planManagerType = _.find(planManagementCustomerFilters, (filter: any) => filter.filter === 'planManagerType')
      ? planManagementCustomerFilters[
          _.findIndex(planManagementCustomerFilters, (filter: any) => filter.filter === 'planManagerType')
        ].values
      : PlanManagerType.INTERNAL;

    return (
      <div className={'pt-x-large'}>
        <div>
          <Title level={4} className="mb-none mt-x-small">
            Customers overview
          </Title>
        </div>

        <div className={'flex-row align-center justify-between mv-large'}>
          <Search
            placeholder="Search by name.."
            onChange={this._onEnterSearchText}
            loading={this.state.isSearching}
            style={{ width: '250px' }}
            defaultValue={searchString}
            allowClear={true}
          />

          <Select
            defaultValue={planManagerType}
            style={{ width: '200px' }}
            onChange={this._changePlanManagerTypeFilter}
          >
            <Select.Option value={'ALL'}>Show all</Select.Option>
            <Select.Option value={PlanManagerType.INTERNAL}>Show active only</Select.Option>
            <Select.Option value={PlanManagerType.EXTERNAL}>Show inactive only</Select.Option>
          </Select>
        </div>

        <div className="height-full bg-white">
          <table className={'width-full plan-management-listing'}>
            <tbody>
              <tr className={'bordered-bottom border-color-quaternary'}>
                <th style={{ width: '30%' }} className={'pb-small pl-medium'}>
                  <SubTitle>Customer</SubTitle>
                </th>
                <th style={{ width: '20%' }} className={'pb-small'}>
                  <SubTitle>Invoices to be paid</SubTitle>
                </th>
                <th style={{ width: '20%' }} className={'pb-small'}>
                  <SubTitle>Invoices to be claimed</SubTitle>
                </th>
                <th style={{ width: '30%' }} className={'pb-small'}>
                  <SubTitle>Invoices requiring approval</SubTitle>
                </th>
              </tr>
              {_.isEmpty(planManagementCustomers) ? (
                <CustomersEmptyState />
              ) : isLoading ? (
                <tr>
                  <td colSpan={4}>
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} />
                  </td>
                </tr>
              ) : (
                <>
                  <InfiniteScrollLoading
                    hasMore={planManagementCustomers.length >= this.state.page * this.state.pageSize}
                    loadingElementId={'content-container'}
                    loadMore={this._fetchMorePlanManagementCustomers}
                    loadingOffSet={100}
                    loaderColSpan={4}
                  >
                    {_.map(planManagementCustomers, (customer, key) => {
                      return (
                        <tr
                          className={'evenodd'}
                          key={key}
                          onClick={() => this._goToCustomerDetailView(customer.userId)}
                        >
                          <td className={'pl-medium'}>
                            <div className={'flex-row align-center mv-small'}>
                              <Avatar size="large" src={customer.attachmentUrl} className={'mr-medium'} />
                              <div>
                                <Text>
                                  {customer.firstName} {customer.lastName}
                                </Text>
                                {(customer.planManagerType === PlanManagerType.EXTERNAL ||
                                  (customer.isArchived && customer.planManagerType === PlanManagerType.INTERNAL)) && (
                                  <>
                                    <br />
                                    <Text color={'orange'}>Inactive</Text>
                                  </>
                                )}
                              </div>
                            </div>
                          </td>
                          <td className={'mv-small'}>{customer.numberOfInvoiceToBePaid}</td>
                          <td className={'mv-small'}>{customer.numberOfInvoiceToBeClaimed}</td>
                          <td className={'mv-small'}>{customer.numberOfInvoiceToBeApproved}</td>
                        </tr>
                      );
                    })}
                  </InfiniteScrollLoading>
                  {isLoadingMore && (
                    <tr>
                      <td colSpan={4}>
                        <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} />
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  planManagementCustomers: state.planManagementStore.planManagementCustomers,
  planManagementCustomerFilters: state.planManagementStore.planManagementCustomerFilters
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setPlanManagementCustomerFilters: dispatch.planManagementStore.setPlanManagementCustomerFilters,
  doFetchPlanManagementCustomers: dispatch.planManagementStore.doFetchPlanManagementCustomers
});

export default connect(
  mapState,
  mapDispatch
)(PlanManagementCustomerOverviewPanel);
