import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { notification } from 'antd';
import { connect } from 'react-redux';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import moment from 'moment';

interface IDeleteBillingLineItemModelProps {
  isOpen: boolean;
  selectedBillingLineItem: any;
  onClose: () => void;
  doDeleteBillingLineItem: typeof dispatch.billingsStore.doDeleteBillingLineItem;
}

interface IDeleteBillingLineItemModelState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

class DeleteBillingLineItemModel extends Component<IDeleteBillingLineItemModelProps, IDeleteBillingLineItemModelState> {
  state = {
    isLoading: false,
    isSuccess: false,
    isError: false
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false, isError: false });
      onClose();
    }
  };

  private _onSubmitWaive = async () => {
    const { selectedBillingLineItem, doDeleteBillingLineItem } = this.props;

    const payload = {
      bookingBillingLineItemId: selectedBillingLineItem.bookingBillingLineItemId,
      attendanceId: selectedBillingLineItem.attendanceId
    };

    try {
      this.setState({ isLoading: true });
      await doDeleteBillingLineItem(payload);
      this.setState({ isLoading: false });
      notification.success({ message: 'Line item deleted successfully' });
      this._onCloseModal();
    } catch (e) {
      this.setState({ isLoading: false });
      notification.error({ message: 'Error', description: 'Oops. Something went wrong, please try again later' });
    }
  };

  render() {
    const { isOpen, selectedBillingLineItem } = this.props;
    return (
      selectedBillingLineItem && (
        <ActionModal
          title="Delete Line Item"
          isOpen={isOpen}
          onClose={this._onCloseModal}
          width="medium"
          verticalAlignment="center"
        >
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>Are you sure you want to delete this line item?</Paragraph>
            </div>
            <div className="mb-medium mt-large">
              <Paragraph>
                Doing so will permanently delete this item from your system. This action is not reversible.
              </Paragraph>
            </div>
          </div>
          <ActionModalFooter>
            <GhostButton
              size="large"
              onClick={this._onCloseModal}
              className="mr-medium"
              disabled={this.state.isLoading}
            >
              Not now
            </GhostButton>
            <PrimaryButton size="large" onClick={this._onSubmitWaive} loading={this.state.isLoading}>
              Delete
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      )
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doDeleteBillingLineItem: dispatch.billingsStore.doDeleteBillingLineItem
});

export default connect(
  null,
  mapDispatch
)(DeleteBillingLineItemModel);
