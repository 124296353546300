import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { FilterType } from 'utilities/enum-utils';
import { FilterSection } from 'common-components/filter';
import GroupServiceSessionListItemSection from './GroupServiceSessionListItemSection';
import moment from 'moment';
import * as H from 'history';

interface IGroupServiceSessionListingPanelProps {
  groupServiceSessions?: typeof state.servicesStore.groupServiceSessions;
  doSearchGroupServiceSession: typeof dispatch.servicesStore.doSearchGroupServiceSession;
  setGroupServiceSessions: typeof dispatch.servicesStore.setGroupServiceSessions;
  setGroupServiceSessionFilter: typeof dispatch.servicesStore.setGroupServiceSessionFilter;
  groupBookingReloadSessionList: typeof state.servicesStore.groupBookingReloadSessionList;
  setGroupBookingReloadSessionList: typeof dispatch.servicesStore.setGroupBookingReloadSessionList;
  groupServiceSessionFilter: any;
  serviceId: string;
  timezone: string;
  selectedFilter: string;
  history: H.History;
  onCheckSession: (session) => void;
  onCheckAllSessions: () => void;
  checkAllSessions: boolean;
  isIndeterminate: boolean;
  onActionDeselect?: () => void;
}

interface IGroupServiceSessionListingsPanelState {
  isLoading: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
}

const availableFilters = [FilterType.DATE_RANGE, FilterType.SESSION_STATUS];

const SESSIONLIST_FILTERCONFIGS = {
  ALL: {
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [moment().startOf('week'), moment().endOf('week')],
        selectionLabel: CommonUtils.getFilterText(FilterType.DATE_RANGE, [
          moment().startOf('week'),
          moment().endOf('week')
        ])
      },
      {
        filter: FilterType.SESSION_STATUS,
        values: [],
        selectionLabel: 'All'
      }
    ]
  },
  NOT_CLOSED_SESSIONS: {
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [moment().startOf('week'), moment().endOf('week')],
        selectionLabel: CommonUtils.getFilterText(FilterType.DATE_RANGE, [
          moment().startOf('week'),
          moment().endOf('week')
        ])
      },
      {
        filter: FilterType.SESSION_STATUS,
        values: ['INPROGRESS'],
        selectionLabel: 'In progress'
      }
    ]
  }
};

class GroupServiceSessionListingPanel extends Component<
  IGroupServiceSessionListingPanelProps,
  IGroupServiceSessionListingsPanelState
> {
  state = {
    isLoading: false,
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date()
  };

  componentDidMount = () => {
    this._loadContent(_.isEmpty(this.props.groupServiceSessionFilter));
  };

  private _fetchMoreServices = async () => {
    const { doSearchGroupServiceSession, serviceId } = this.props;
    const newPage = this.state.page + 1;
    this.setState({ isLoading: true, page: newPage });

    //await asyncDelay(3000);
    const requestFilter = this._formatFilterQuery();
    await doSearchGroupServiceSession({
      serviceId,
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });
    this.setState({ isLoading: false });
  };

  private _loadContent = async (resetFilters = true) => {
    const { doSearchGroupServiceSession, serviceId, setGroupServiceSessions, selectedFilter } = this.props;
    const { pageSize } = this.state;
    // Only use this method to fetch fresh data.
    const newPage = 1;

    //Remove data in the store first.
    this.props.onActionDeselect();
    setGroupServiceSessions([]);
    this.setState({ page: newPage });
    this.setState({ isLoading: true });

    const filters = resetFilters
      ? selectedFilter && SESSIONLIST_FILTERCONFIGS[selectedFilter]
        ? SESSIONLIST_FILTERCONFIGS[selectedFilter].filters
        : SESSIONLIST_FILTERCONFIGS.ALL.filters
      : this.props.groupServiceSessionFilter;
    const requestFilter = this._formatFilterQuery(filters);
    //await asyncDelay(3000);
    await doSearchGroupServiceSession({
      serviceId,
      page: newPage,
      pageSize,
      pageTimestamp: new Date(),
      ...requestFilter
    });
    this.props.setGroupServiceSessionFilter(filters);
    this.setState({ isLoading: false });
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setGroupServiceSessionFilter(filters);
  };

  private _applyFilter = async () => {
    this.props.setGroupServiceSessions([]);
    this.props.onActionDeselect();
    const newPage = 1;
    this.setState({
      page: newPage,
      isLoading: true
    });

    const requestFilter = this._formatFilterQuery();
    await this.props.doSearchGroupServiceSession({
      serviceId: this.props.serviceId,
      page: newPage,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });

    this.setState({ isLoading: false });
  };

  private _formatFilterQuery = (filters = this.props.groupServiceSessionFilter) => {
    const requestFilter: any = {};
    _.forEach(filters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'sessionStatus':
            requestFilter.status = filter.values;
            break;
          case 'startDate':
            requestFilter.startDate = filter.values[0].toDate();
            requestFilter.endDate = filter.values[1].toDate();
            break;
          default:
            break;
        }
      }
    });

    return requestFilter;
  };

  async componentDidUpdate(prevProps, _prevState) {
    if (prevProps.groupServiceSessionFilter !== this.props.groupServiceSessionFilter) {
      await this._applyFilter();
    }
    if (prevProps.groupBookingReloadSessionList !== this.props.groupBookingReloadSessionList) {
      this._loadContent(false);
      await this.props.setGroupBookingReloadSessionList({ reload: false });
    }
  }

  render() {
    const { groupServiceSessionFilter, timezone, history } = this.props;
    const { page, pageSize, isLoading } = this.state;

    return (
      <div className="height-full">
        <div className="flex-row align-center pt-large pb-x-small">
          <FilterSection
            availableFilters={availableFilters}
            filters={groupServiceSessionFilter}
            displayTimezone={timezone}
            onChangeFilter={this._onChangeFilter}
            containerClassName="flex-row justify-between"
          />
        </div>
        <GroupServiceSessionListItemSection
          page={page}
          pageSize={pageSize}
          fetchMoreServices={this._fetchMoreServices}
          isLoading={isLoading}
          isIndeterminate={this.props.isIndeterminate}
          checkAllSessions={this.props.checkAllSessions}
          setCheckAllSessions={this.props.onCheckAllSessions}
          timezone={timezone}
          history={history}
          OnCheckSession={this.props.onCheckSession}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceSessions: state.servicesStore.groupServiceSessions,
  groupServiceSessionFilter: state.servicesStore.groupServiceSessionFilter,
  timezone: state.groupServiceStore.selectedGroupService.timezone,
  groupBookingReloadSessionList: state.servicesStore.groupBookingReloadSessionList
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doSearchGroupServiceSession: dispatch.servicesStore.doSearchGroupServiceSession,
  setGroupServiceSessionFilter: dispatch.servicesStore.setGroupServiceSessionFilter,
  setGroupServiceSessions: dispatch.servicesStore.setGroupServiceSessions,
  setGroupBookingReloadSessionList: dispatch.servicesStore.setGroupBookingReloadSessionList
});

export default connect(
  mapState,
  mapDispatch
)(GroupServiceSessionListingPanel);
