import React, { Component } from 'react';
import { Col, Form, Input, notification, Row } from 'antd';
import { connect } from 'react-redux';
import { Paragraph, SubTitle } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Title from 'antd/lib/typography/Title';
import { FormComponentProps } from 'antd/es/form';

interface IScheduleDetailsProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setNewGroupServiceSchedule?: typeof dispatch.servicesStore.setNewGroupServiceSchedule;
  newGroupServiceSchedule: typeof state.servicesStore.newGroupServiceSchedule;
  history?: any;
}

interface IScheduleDetailsState {
  isLoading: boolean;
}

class ScheduleDetails extends Component<IScheduleDetailsProps, IScheduleDetailsState> {
  state = {
    isLoading: false
  };

  private _goToNext = async () => {
    const { setNewGroupServiceSchedule, newGroupServiceSchedule, form } = this.props;

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      try {
        await setNewGroupServiceSchedule({
          ...newGroupServiceSchedule,
          scheduleName: form.getFieldValue('scheduleName')
        });
        this.props.onNextStep();
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong! Please try again.' });
      }
    }
  };

  render() {
    const { newGroupServiceSchedule, form } = this.props;
    const { isLoading } = this.state;
    const { getFieldDecorator } = form;

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>What do you want to call this schedule?</Title>
              <Paragraph>
                Naming the schedule will help other admins easily identify which schedule a session belongs to.
              </Paragraph>
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white rounded-big p-large" style={{ minWidth: '250px' }}>
              <Title level={4}>Schedule details</Title>
              <Paragraph>Please enter the name for this schedule.</Paragraph>
              <div className={'mt-large'}>
                <SubTitle>Title</SubTitle>
                <Form.Item className="m-none">
                  {getFieldDecorator('scheduleName', {
                    rules: [{ required: true, message: 'Please enter a schedule title' }],
                    initialValue: newGroupServiceSchedule ? newGroupServiceSchedule.scheduleName : null
                  })(
                    <Input
                      type={'text'}
                      size={'large'}
                      placeholder={'Schedule title'}
                      maxLength={100}
                      style={{ maxWidth: '600px' }}
                    />
                  )}
                </Form.Item>
              </div>
            </div>
            <div className="pv-medium width-full bg-tertiary" style={{ position: 'sticky', bottom: 0 }}>
              <Row gutter={0} type="flex" align="middle" justify={'space-between'} className="bg-transparent">
                <div className="text-align-right pv-medium">
                  <SecondaryButton size="large" disabled={isLoading} onClick={this.props.onPreviousStep}>
                    Back
                  </SecondaryButton>
                </div>
                <div className="text-align-right pv-medium">
                  <PrimaryButton size="large" loading={isLoading} onClick={this._goToNext}>
                    Next
                  </PrimaryButton>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewGroupServiceSchedule: dispatch.servicesStore.setNewGroupServiceSchedule
});

const mapState = (state: IRootState) => ({
  newGroupServiceSchedule: state.servicesStore.newGroupServiceSchedule
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IScheduleDetailsProps>()(ScheduleDetails));
