/* eslint-disable */
import React, { Component } from 'react';
import { Button, Col, Icon, Input, message, notification, Row, Typography } from 'antd';
import { BlueprintColors } from 'variables/colors';

import { Card, ProgressBar } from '@blueprintjs/core';
import { logoBlack } from 'assets/logo';
import NotAuthorizedLayout from 'layouts/NotAuthorizedLayout';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { IRootDispatch } from 'src/stores/rematch/root-store';
import { Text } from 'common-components/typography';
import CenteredLayout from '../../layouts/CenteredLayout';
import { HyperlinkButton } from '../../common-components/buttons';
import qs from 'query-string';

// import firebaseApp from 'stores/firebase-app';

class LoginView extends Component<any, any> {
  state = {
    login: '',
    password: '',
    email: '',
    isLoggingIn: false,
    isForgetPassword: false,
    returnTo: null,
    brandId: null,
    timestamp: null,
    isTokenLogin: false
  };

  // Simple state handler
  _handleInput = (target, value) => {
    let targetObj = {};
    targetObj[target] = value;
    this.setState(targetObj);
  };

  _onClickLogin = async () => {
    const dismissMessage = message.loading('Signing in...');
    this.setState({ isLoggingIn: true });

    try {
      const { doSignIn } = this.props;
      const { login, password } = this.state;

      await doSignIn({ login, password });

      dismissMessage();
      this.setState({ isLoggingIn: false });
    } catch (err) {
      dismissMessage();
      this.setState({ isLoggingIn: false });
      message.error(err.message);
    }
  };

  _onClickSend = async () => {
    this.setState({ isLoggingIn: true });
    const { doSendResetPasswordEmail } = this.props;
    const { email } = this.state;
    try {
      await doSendResetPasswordEmail({ email: email });
      notification.success({
        message: 'Reset instructions sent',
        description: 'Please check your email inbox and follow the instructions to reset your password.'
      });
      this.setState({ isLoggingIn: false, isForgetPassword: false });
    } catch (e) {
      // notification.error({
      //   message: 'Error',
      //   description: e.message
      // });
      notification.success({
        message: 'Reset instructions sent',
        description: 'Please check your email inbox and follow the instructions to reset your password.'
      });
      this.setState({ isLoggingIn: false, isForgetPassword: false });
    }
  };

  async componentDidMount() {
    const { location, doSignInUsingToken } = this.props;
    const query = qs.parse(location.search);
    let data = query.token;
    if (data) {
      const dismissMessage = message.loading('Signing in...');
      this.setState({ isLoggingIn: true, isTokenLogin: true });
      try {
        await doSignInUsingToken({ token: data });
        dismissMessage();
        this.setState({ isLoggingIn: false });
      } catch (err) {
        dismissMessage();
        this.setState({ isLoggingIn: false });
        message.error(err.message);
      }
    }
  }

  render() {
    // const { authStore } = this.props;

    // if it is login from redirect, will display login in view
    if (!this.state.isTokenLogin) {
      return (
        <NotAuthorizedLayout>
          {/* Login Panel*/}
          {/* NOTE Top Content Section */}
          <CenteredLayout maxWidth={800} height={'100%'} className="flex-column justify-center">
            <div className="flex-row justify-center pt-medium pb-x2-large">
              <img src={logoBlack} alt="" style={{ width: '45%', height: '100%' }} />
            </div>

            {this.state.isForgetPassword ? this.forgetPasswordView() : this.loginView()}
          </CenteredLayout>
        </NotAuthorizedLayout>
      );
    } else {
      return (
        // copy from app loading indicator
        <div
          style={{
            width: '100vw',
            height: '100vh',
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Card style={{ width: '25%', textAlign: 'center' }}>
            <div style={{ marginBottom: '16px' }}>
              <Typography.Text style={{ fontSize: '16px' }}>Logging In...</Typography.Text>
            </div>
            <ProgressBar animate={true} />
          </Card>
        </div>
      );
    }
  }

  private loginView() {
    return (
      <>
        <div className="text-align-center pb-x3-large">
          <Text className="text-size-x2-large">Sign in to manage your services and discover great communities.</Text>
        </div>

        {/* NOTE Input box Section */}
        {/* TODO Validate email & password */}
        <Row type={'flex'} justify={'center'}>
          <Col md={24}>
            {/* Email Input */}
            <Input
              size={'large'}
              // style={{ marginBottom: -1 }}
              placeholder={'Enter your email here'}
              allowClear
              value={this.state.login}
              onChange={(e) => this._handleInput('login', e.target.value)}
              prefix={<Icon type={'user'} className="text-color-tertiary" />}
            />

            {/* Password Input */}

            <Input.Password
              size={'large'}
              placeholder={'Password'}
              value={this.state.password}
              onChange={(e) => this._handleInput('password', e.target.value)}
              prefix={<Icon type={'lock'} className="text-color-tertiary" />}
            />

            {/* TODO Forgot your password functionality */}
            <div className="text-align-right mv-large mr-small">
              <HyperlinkButton onClick={() => this.setState({ isForgetPassword: true })}>
                Forgot your password?
              </HyperlinkButton>
            </div>

            <Button
              type={'primary'}
              block
              size={'large'}
              style={{
                marginBottom: '16px',
                backgroundColor: BlueprintColors.BLACK,
                borderColor: BlueprintColors.GRAY1
              }}
              onClick={this._onClickLogin}
            >
              {/*<Text>Sign In</Text>*/}Sign In
            </Button>

            <Button
              type={'dashed'}
              block
              size={'large'}
              className="hover-border-black"
              onClick={() => (window.location.href = 'https://www.goodhuman.me')}
            >
              <Text className="text-weight-regular">New to GoodHuman? Start here.</Text>
            </Button>

            <div style={{ marginTop: '24px', minHeight: '28px' }}>
              {this.state.isLoggingIn && <ProgressBar animate={true} />}
            </div>

            <div style={{ marginTop: '0px' }} className="text-align-center">
              <Text className="text-size-x-small text-color-secondary">
                * By logging in, you agree to our Terms of Use and acknowledge that you have read our Privacy Agreement.
              </Text>
            </div>
          </Col>
        </Row>
      </>
    );
  }

  private forgetPasswordView() {
    return (
      <div className="anim-slide-left">
        <div className="pb-medium text-align-center">
          <Text size="x2-large" weight="bold">
            Reset your password
          </Text>
        </div>
        <div style={{ textAlign: 'center', marginBottom: '32px', minHeight: '48px' }}>
          <Text style={{ fontSize: '16px' }}>
            Forgot your password? It happens. <br /> Enter your email address below, and we'll help you get right back
            into <b>GoodHuman</b>!
          </Text>
        </div>

        {/* NOTE Input box Section */}
        {/* TODO Validate email & password */}
        <Row type={'flex'} justify={'center'}>
          <Col md={24}>
            {/* Email Input */}
            <Input
              size={'large'}
              // style={{ marginBottom: -1 }}
              placeholder={'Enter your email here'}
              allowClear
              value={this.state.email}
              onChange={(e) => this._handleInput('email', e.target.value)}
              prefix={<Icon type={'user'} className="text-color-tertiary" />}
              className="mb-medium"
            />

            {/* TODO Forgot your password functionality */}
            <div className="text-align-right mb-large">
              <Button
                type={'link'}
                className="pr-none text-color-blue-action"
                onClick={() => this.setState({ isForgetPassword: false })}
              >
                Have an account? Sign in here.
              </Button>
            </div>

            <Button
              type={'primary'}
              block
              size={'large'}
              style={{
                marginBottom: '16px',
                backgroundColor: BlueprintColors.BLACK,
                borderColor: BlueprintColors.GRAY1
              }}
              onClick={this._onClickSend}
            >
              {/*<Text>Sign In</Text>*/}Send Reset Email
            </Button>

            <div style={{ marginTop: '24px', minHeight: '28px' }}>
              {this.state.isLoggingIn && <ProgressBar animate={true} />}
            </div>

            <div style={{ marginTop: '0px' }}>
              <Text className="text-size-x-small text-color-tertiary">
                * By logging in, you agree to our Terms of Use and acknowledge that you have read our Privacy Agreement.
              </Text>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state) => ({
  authStore: state.authStore,
  portalUserList: state.authStore.portalUserList
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setCurrentUser: dispatch.authStore.setCurrentUser,
  doSignIn: dispatch.authStore.doSignIn,
  doSignInUsingToken: dispatch.authStore.doSignInUsingToken,
  doFetchPortalUserList: dispatch.authStore.doFetchPortalUserList,
  setCurrentPortalUser: dispatch.authStore.setCurrentPortalUser,
  fetchDashboardData: dispatch.bookingsStore.doFetchDashboardData,
  doSendResetPasswordEmail: dispatch.authStore.doSendResetPasswordEmail
});

const connectStateDispatch = connect(
  mapState,
  mapDispatch
);

export default compose(connectStateDispatch)(LoginView);
