import React, { Component } from 'react';

import _ from 'lodash';
import moment from 'moment-timezone';
import { connect } from 'react-redux';

import DatePicker from 'react-datepicker';

import { Spinner } from '@blueprintjs/core';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import TimeInput from 'common-components/time-input/TimeInput';

import { IShiftSlot } from 'interfaces/shift-interfaces';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { ISession } from 'interfaces/session-interfaces';
import { Moment } from 'moment';
import CommonUtils from 'utilities/common-utils';
import { notification } from 'antd';

interface IFinishShiftModalProps {
  isOpen: boolean;
  shiftSlot: IShiftSlot;
  onClose: any;
  doFinishShift: any;
  session: ISession;
}

interface IFinishShiftModalState {
  step: number;
  canManuallyClose: boolean;
  shiftEndDate: Moment;
  refreshShiftSlots: boolean;
  isWarnShiftEndTimeBeforeStartTime: boolean;
}

class FinishShiftModal extends Component<IFinishShiftModalProps, IFinishShiftModalState> {
  state = {
    step: 1,
    canManuallyClose: true,
    shiftEndDate: moment(),
    refreshShiftSlots: false,
    isWarnShiftEndTimeBeforeStartTime: false
  };

  private _reset = () => {
    const { shiftSlot, session } = this.props;
    const { shiftEndDateTime } = shiftSlot;

    this.setState({
      step: 1,
      refreshShiftSlots: false,
      shiftEndDate: moment(moment.tz(shiftEndDateTime, session.timezone).format('YYYY-MM-DD HH:mm'))
    });
  };

  private _onFinishShift = async () => {
    const { doFinishShift, shiftSlot, session } = this.props;
    const { shiftEndDate } = this.state;
    const { workerCheckedInDateTime } = shiftSlot;
    if (moment(shiftEndDate).isSameOrBefore(moment(workerCheckedInDateTime))) {
      this.setState({ isWarnShiftEndTimeBeforeStartTime: true });
      return;
    }

    this.setState({ step: 2, canManuallyClose: false });

    try {
      const request = {
        serviceId: session.serviceId,
        serviceDateTimeId: session.serviceDateTimeId,
        supportWorkerAttendanceId: shiftSlot.supportWorkerAttendanceId,
        endDateTime: moment.tz(moment(this.state.shiftEndDate).format('YYYY-MM-DD HH:mm'), session.timezone)
      };
      await doFinishShift(request);

      this.setState({ step: 3, canManuallyClose: true, refreshShiftSlots: true });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  private _onClose = () => {
    const { onClose } = this.props;
    onClose({ targetFlag: 'isFinishShiftOpen' }, this.state.refreshShiftSlots);
    this._reset();
  };

  private _onChangeEndDate = (date) => {
    if (
      moment(date).isAfter(
        moment(
          moment
            .tz(this.props.shiftSlot.workerCheckedInDateTime, this.props.session.timezone)
            .format('YYYY-MM-DD HH:mm')
        )
      )
    ) {
      this.setState({ shiftEndDate: moment(CommonUtils.formatCeilingDateTime(date)) });
    } else {
      this.setState({ shiftEndDate: this.state.shiftEndDate });
    }
  };

  componentDidUpdate(prevProps: Readonly<IFinishShiftModalProps>) {
    if (this.props.isOpen && !prevProps.isOpen) {
      const { shiftSlot } = this.props;
      const { shiftEndDateTime } = shiftSlot;
      this.setState({
        shiftEndDate: moment(moment.tz(shiftEndDateTime, this.props.session.timezone).format('YYYY-MM-DD HH:mm')),
        isWarnShiftEndTimeBeforeStartTime: false
      });
    }
  }

  render() {
    const { shiftSlot, session } = this.props;

    if (_.isEmpty(shiftSlot)) {
      return <></>;
    }

    const { supportWorkerId, shiftEndDateTime } = shiftSlot;

    if (_.isEmpty(supportWorkerId)) {
      return <></>;
    }

    const shiftEndDateTimeWithoutTimezone = moment(
      moment.tz(shiftEndDateTime, session.timezone).format('YYYY-MM-DD HH:mm')
    );

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title="Finish shift"
        onClose={this._onClose}
        canCloseOutside={this.state.canManuallyClose}
      >
        {this.state.step === 1 && (
          <div>
            <div className="line-height-135 mb-medium">
              <Text lineHeight={135}>
                You are about to finish this shift on behalf of{' '}
                <b>
                  {shiftSlot.firstName} {shiftSlot.lastName}
                </b>
                . <br />
                <br />
                Please confirm the finish time below.
              </Text>
            </div>

            <div className="mb-large flex-row line-height-135">
              <div className="flex-1 mr-large">
                <FieldLabel text={'FINISH TIME'} />
                <div className="flex-row mt-x2-small">
                  <DatePicker
                    className="gh-datepicker rounded mr-medium"
                    calendarClassName="gh-datepicker-calendar"
                    onChange={this._onChangeEndDate}
                    dateFormat={'dd/MM/yyyy'}
                    isClearable={false}
                    selected={this.state.shiftEndDate.toDate()}
                    minDate={shiftEndDateTimeWithoutTimezone.toDate()}
                  />
                  <TimeInput size="large" value={moment(this.state.shiftEndDate)} onChange={this._onChangeEndDate} />
                </div>
                {this.state.isWarnShiftEndTimeBeforeStartTime && (
                  <div className={'text-color-red-dark text-size-regular'}>
                    The shift finish time should be later than the check in time.
                  </div>
                )}
              </div>
            </div>

            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onFinishShift}>
                Finish shift
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {this.state.step === 2 && (
          <div className="line-height-135 anim-slide-left">
            <div className="flex-column align-center mv-large justify-center">
              <div className="mb-medium">
                <Spinner size={80} />
              </div>
              <div className="text-align-center">
                <Text color="secondary" weight="bold">
                  Finishing shift...
                </Text>
                <br />
                <Text color="secondary">This won't take long.</Text>
              </div>
            </div>
          </div>
        )}

        {this.state.step === 3 && (
          <div className="anim-fade-in-fast">
            <div>
              <div className="line-height-135 mb-medium">
                <Text lineHeight={135}>
                  You have successfully <b>finished</b> this shift for{' '}
                  <b>
                    {shiftSlot.firstName} {shiftSlot.lastName}
                  </b>
                  .
                </Text>
              </div>

              <div className="mb-large flex-row line-height-135">
                <div className="flex-1 mr-large">
                  <FieldLabel text={'SHIFT FINISH TIME'} />

                  <div className="flex-row mt-x2-small">
                    <Text>{moment(this.state.shiftEndDate).format('h:mm A, D MMMM YYYY')}</Text>
                  </div>
                </div>
              </div>

              <ActionModalFooter align="right">
                <PrimaryButton size="large" onClick={this._onClose}>
                  Close
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFinishShift: dispatch.groupServiceStore.doFinishShift
});

export default connect(
  mapState,
  mapDispatch
)(FinishShiftModal);
