import React, { Component } from 'react';
import { SubTitle, Text, Title } from 'common-components/typography';
import { Tooltip2 } from '@blueprintjs/popover2';
import { IconButton, HyperlinkButton } from 'common-components/buttons';
import moment from 'moment-timezone';
import _ from 'lodash';
import { Empty, Input, Skeleton } from 'antd';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { RouteComponentProps } from 'react-router-dom';
import { FilterSection } from 'common-components/filter';
import { FilterType } from 'utilities/enum-utils';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
const { Search } = Input;

interface IPlanPayListBatchSectionProps extends RouteComponentProps {
  planManagementBatchListing: typeof state.planManagementStore.planManagementBatchListing;
  doFetchBatchListing: typeof dispatch.planManagementStore.doFetchBatchListing;
  planManagementBatchFilter: typeof state.planManagementStore.planManagementBatchFilter;
  setPlanManagementBatchListingFilter: typeof dispatch.planManagementStore.setPlanManagementBatchListingFilter;
  currentFilterConfig?: any;
  portalUser: typeof state.authStore.portalUser;
}

const availableFilters = [FilterType.DATE_RANGE];

const PaymentEmptyState = () => (
  <div className="flex-1 bg-white align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No Batch found.
    </Text>{' '}
    <br /> <br />
    <Text color="secondary">All exported batch under this filter will appear here.</Text>
    <Text color="secondary">Try adjusting your filter, or clicking on another view.</Text>
  </div>
);

class PlanPayListBatchSection extends Component<IPlanPayListBatchSectionProps, any> {
  state = {
    topHeight: 0,
    showFilters: false,
    showActionSheet: false,
    checkAllIndicator: false,
    indeterminateCheck: false,
    isLoading: false,
    isSearching: false,
    searchString: '',
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    action: '',
    openAction: false,
    filters: []
  };

  _headerElement = null;

  _handleHeaderHeight = () => {
    if (this._headerElement) {
      this.setState({ topHeight: this._headerElement.offsetHeight - 1 });
    }
  };

  private _fetchMoreItem = async () => {
    const { doFetchBatchListing } = this.props;
    this.setState({ isLoading: true });
    const requestFilter = this._formatFilterQuery();
    await doFetchBatchListing({
      page: this.state.page + 1,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });
    this.setState({ isLoading: false, page: this.state.page + 1 });
  };

  private async _refreshListings() {
    const { doFetchBatchListing } = this.props;
    this.setState({ isLoading: true, checkAllIndicator: false, indeterminateCheck: false, showActionSheet: false });

    const requestFilter = this._formatFilterQuery();
    await doFetchBatchListing({
      page: 1,
      pageTimestamp: new Date(),
      pageSize: this.state.pageSize,
      ...requestFilter
    });
    this.setState({ isLoading: false, pageTimestamp: new Date(), page: 1 });
  }

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setPlanManagementBatchListingFilter(filters);
  };

  private _searchText = async (txt) => {
    const { planManagementBatchFilter, setPlanManagementBatchListingFilter } = this.props;
    let newPlanPaymentsFilter = _.clone(planManagementBatchFilter);
    const existingSearchIndex = _.findIndex(newPlanPaymentsFilter, (filter: any) => filter.filter === 'searchString');
    if (existingSearchIndex > -1) {
      if (txt === '') {
        newPlanPaymentsFilter.splice(existingSearchIndex, 1);
      } else {
        newPlanPaymentsFilter[existingSearchIndex].values = txt;
      }
    } else {
      newPlanPaymentsFilter.push({ filter: 'searchString', values: txt });
    }
    setPlanManagementBatchListingFilter(newPlanPaymentsFilter);
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _formatFilterQuery = () => {
    const requestFilter: any = {};
    _.forEach(this.props.planManagementBatchFilter, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'startDate':
            requestFilter.exportedDateRange = [filter.values[0].toDate(), filter.values[1].toDate()];
            break;
          case 'searchString':
            requestFilter.searchString = filter.values;
            break;
        }
      }
    });
    return requestFilter;
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    this._handleHeaderHeight();
    const { doFetchBatchListing, setPlanManagementBatchListingFilter, currentFilterConfig } = this.props;
    await setPlanManagementBatchListingFilter(currentFilterConfig.filters);
    const requestFilter = this._formatFilterQuery();
    await doFetchBatchListing({
      page: this.state.page,
      pageTimestamp: this.state.pageTimestamp,
      pageSize: this.state.pageSize,
      ...requestFilter
    });
    this.setState({ isLoading: false });
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const {
      currentFilterConfig,
      setPlanManagementBatchListingFilter,
      doFetchBatchListing,
      planManagementBatchFilter
    } = this.props;

    if (prevState.showFilters !== this.state.showFilters) {
      this._handleHeaderHeight();
    }

    if (prevProps.currentFilterConfig.key !== currentFilterConfig.key) {
      this.setState({
        isLoading: true,
        checkAllIndicator: false,
        indeterminateCheck: false,
        showActionSheet: false
      });
      await setPlanManagementBatchListingFilter(currentFilterConfig.filters);
    }

    if (prevProps.planManagementBatchFilter !== planManagementBatchFilter) {
      this.setState({ isLoading: true });
      const requestFilter = this._formatFilterQuery();
      await doFetchBatchListing({
        page: 1,
        pageTimestamp: new Date(),
        pageSize: this.state.pageSize,
        ...requestFilter
      });
      this.setState({ isLoading: false, page: 1, pageTimestamp: new Date() });
    }
  };

  render() {
    const { planManagementBatchListing = [], history, portalUser } = this.props;

    const { currentFilterConfig } = this.props;

    return (
      <div
        className="bg-white flex-1 width-full flex-column"
        style={{ overflowY: 'auto', position: 'relative' }}
        id="scroll"
      >
        <div>
          <div className="plan-management-header" ref={(com) => (this._headerElement = com)}>
            <div className="flex-row justify-between align-center">
              <div>
                <Title level={3} className="mv-none" lineHeight={150}>
                  {currentFilterConfig.title}
                </Title>
                <Text color="secondary">{currentFilterConfig.description}</Text>
              </div>
              <div className="flex-column align-center">
                <div className="pr-x-small">
                  <Tooltip2 position="top" content="Refresh this view">
                    <IconButton
                      color="white"
                      onClick={this._refreshListings}
                      iconColor="black"
                      size="large"
                      icon="reload"
                    />
                  </Tooltip2>
                </div>
              </div>
            </div>

            <div className={'flex-row align-center'}>
              <Search
                placeholder="Search by name"
                onChange={this._onEnterSearchText}
                loading={this.state.isSearching}
                style={{ width: '250px' }}
                className={'mr-large mb-small'}
                allowClear={true}
              />
              <FilterSection
                availableFilters={availableFilters}
                filters={this.props.planManagementBatchFilter}
                onChangeFilter={this._onChangeFilter}
                displayTimezone={this.props.portalUser.timezone}
              />
            </div>
          </div>

          <table className="plan-payment-listing">
            <thead>
              <tr>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <SubTitle>EXPORTED DATE</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <SubTitle>BATCH</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <SubTitle>NUMBER OF INVOICES</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <SubTitle>EXPORTED BY</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap text-align-right">
                  <SubTitle containerClassName="text-align-right">TOTAL</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <></>
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.isLoading && (
                <tr style={{ borderBottom: '0px solid !important' }}>
                  <td colSpan={7}>
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                  </td>
                </tr>
              )}
              {!this.state.isLoading && _.isEmpty(planManagementBatchListing) && (
                <tr style={{ cursor: 'default' }}>
                  <td colSpan={7} style={{ borderBottom: '0px solid' }}>
                    <PaymentEmptyState />
                  </td>
                </tr>
              )}
              <InfiniteScrollLoading
                hasMore={planManagementBatchListing.length >= this.state.page * this.state.pageSize}
                loadingElementId={'scroll'}
                loadMore={this._fetchMoreItem}
                loaderColSpan={7}
                loadingOffSet={60}
              >
                {!this.state.isLoading &&
                  _.map(planManagementBatchListing, (batchItem) => (
                    <>
                      <tr
                        className={`anim-fade-in hover-bg-tertiary`}
                        onClick={() => history.push(`batches/${batchItem.batchId}`)}
                      >
                        <td>{moment.tz(batchItem.exportedDate, portalUser.timezone).format('D/M/YYYY')}</td>
                        <td>
                          <HyperlinkButton>{batchItem.batchNumber}</HyperlinkButton>
                        </td>
                        <td>{batchItem.numberOfInvoices}</td>
                        <td>{`${batchItem.firstName} ${batchItem.lastName}`}</td>
                        <td>
                          <div className="text-align-right">{batchItem.amount}</div>
                        </td>
                        <td>
                          <div className="text-align-center">
                            <HyperlinkButton>View details...</HyperlinkButton>
                          </div>
                        </td>
                      </tr>
                    </>
                  ))}
              </InfiniteScrollLoading>
            </tbody>
          </table>
        </div>

        {/* Filler */}
        <div className="flex-1 bg-white">&nbsp;</div>

        {/*{!this.state.isLoading && !this.state.showActionSheet && filterSearchList.length > 0 && (*/}
        {/*  <ItemCountSheet itemCount={filterSearchList.length} itemLabel="Batch" plurial="es" />*/}
        {/*)}*/}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  planManagementBatchListing: state.planManagementStore.planManagementBatchListing,
  planManagementBatchFilter: state.planManagementStore.planManagementBatchFilter,
  portalUser: state.authStore.portalUser
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchBatchListing: dispatch.planManagementStore.doFetchBatchListing,
  setPlanManagementBatchListingFilter: dispatch.planManagementStore.setPlanManagementBatchListingFilter
});

export default connect(mapState, mapDispatch)(PlanPayListBatchSection);
