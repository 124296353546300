import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { Form, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/es/form';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import _ from 'lodash';
import moment from 'moment';

interface IPlanPaymentWaivedReasonModalProps {
  isOpen: boolean;
  selectedItem: typeof state.planManagementStore.selectedItem;
  onClose: () => void;
  type: string;
}

interface IPlanPaymentWaivedReasonModalState {}

class PlanPaymentWaivedReasonModal extends Component<
  IPlanPaymentWaivedReasonModalProps,
  IPlanPaymentWaivedReasonModalState
> {
  private _onCloseModal = () => {
    const { onClose } = this.props;
    onClose();
  };

  render() {
    const { isOpen, selectedItem } = this.props;
    const { type, item } = this.props.selectedItem;
    return (
      <ActionModal
        title={'Waive reason'}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="medium"
        verticalAlignment="highest"
      >
        <div className="text-align-left">
          <div className="mb-medium">
            <>
              <b>
                Waived {item.waivedByFirstName && `by ${item.waivedByFirstName} ${item.waivedByLastName} `}on{' '}
                {moment(item.waivedOn).format('DD/MM/YYYY')}
              </b>
              <br />
              <br />
              <Paragraph>"{item.waivedReason}"</Paragraph>
            </>
          </div>
        </div>
        <ActionModalFooter>
          <GhostButton size="large" onClick={this._onCloseModal} className="mr-medium">
            Close
          </GhostButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedItem: state.planManagementStore.selectedItem
});
const mapDispatch = (dispatch: IRootDispatch) => ({});

export default connect(
  mapState,
  mapDispatch
)(PlanPaymentWaivedReasonModal);
