import { Paragraph, Text, Title } from 'common-components/typography';
import React, { Component } from 'react';
import { GhostButton, IconButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';
import { Avatar, Icon, Input } from 'antd';
import FullScreenModal, { FullScreenModalFooter } from 'common-components/modal/FullScreenModal';
import PopoverContainer, { PopoverFooter } from 'common-components/modal/PopoverContainer';

// import Undraw from 'react-undraw';

class ActionModalExample extends Component {
  state = { isModalOpen: false };

  closeModal = () => this.setState({ isModalOpen: false });
  openModal = () => this.setState({ isModalOpen: true });

  render() {
    return (
      <div>
        <ActionModal isOpen={this.state.isModalOpen} title={'Please confirm your booking'} onClose={this.closeModal}>
          <div className="mb-x-large">{/*<Undraw name="code-typing" />*/}</div>

          <div className="">
            <Paragraph className="max-width-640">
              You're about confirm the booking for Jonathan Murray. They will be notified shortly on this confirmation..
            </Paragraph>

            <Paragraph>Would you like to proceed?</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large">
              Proceed
            </PrimaryButton>
            <GhostButton size="large" onClick={this.closeModal}>
              Maybe later
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>

        <PrimaryButton onClick={this.openModal}>Show Modal</PrimaryButton>
      </div>
    );
  }
}

class ConfirmModalExample extends Component<any, { isConfirmModalOpen: boolean }> {
  state = { isConfirmModalOpen: false };

  _openModal = () => this.setState({ isConfirmModalOpen: true });
  _closeModal = () => this.setState({ isConfirmModalOpen: false });

  render() {
    const popoverContent = (
      <PopoverContainer title="Sample Popover">
        <Paragraph>Are you sure you want to cancel this booking?</Paragraph>
        <Paragraph>There will be no undo action from this target action.</Paragraph>
        <PopoverFooter align="right">
          <PrimaryButton
            className="mr-medium"
            onClick={() => {
              alert('Execute confirm action here.');
              this._closeModal();
            }}
          >
            OK
          </PrimaryButton>
          <GhostButton onClick={this._closeModal}>I've changed my mind</GhostButton>
        </PopoverFooter>
      </PopoverContainer>
    );

    return (
      <div className="mb-medium">
        <Popover
          popoverClassName="bg-white"
          content={popoverContent}
          position="bottom"
          isOpen={this.state.isConfirmModalOpen}
          onClose={this._closeModal}
        >
          <PrimaryButton onClick={this._openModal}>Confirmation Popover</PrimaryButton>
        </Popover>
      </div>
    );
  }
}

function MenuActionsExample() {
  const popoverMenu = (
    <Menu>
      <MenuDivider title={<Text>Group Items By</Text>} />
      <MenuItem
        text={
          <Text>
            <Icon type="book" className="mr-medium text-color-tertiary" />
            Status
          </Text>
        }
        label={'1'}
        className="hover-bg-green-lightest"
      />
      <MenuItem
        text={
          <Text>
            <Icon type="calendar" className="mr-medium text-color-tertiary" />
            Date
          </Text>
        }
        label={'2'}
        className="hover-bg-green-lightest"
      />
      <MenuItem
        text={
          <Text>
            <Icon type="schedule" className="mr-medium text-color-tertiary" />
            Service
          </Text>
        }
        label={'3'}
        className="hover-bg-green-lightest"
      />
    </Menu>
  );

  return (
    <div className="mb-medium">
      <Popover content={popoverMenu} position={'bottom'}>
        <SecondaryButton icon="menu">Other Actions</SecondaryButton>
      </Popover>
    </div>
  );
}

class CreateNewBookingPanel extends Component {
  render() {
    return (
      <div>
        <div>
          <Title level={2} weight="regular" lineHeight={100} className="mb-x-large">
            Create a new <span className="text-weight-bolder">booking</span>
          </Title>
        </div>
        <div className="mb-medium">
          <div className="mb-small">
            <Text>
              Which <b>customer</b> would you like to create a <b>booking</b> for?
            </Text>
          </div>

          <div className="flex-row mb-medium">
            <Input
              size="large"
              className="border-secondary mr-large"
              style={{ borderWidth: '2px' }}
              placeholder="Search for customer name..."
            />

            <PrimaryButton icon="enter" iconPosition="right" size="large">
              Go
            </PrimaryButton>
          </div>

          <div className="mb-medium mh-medium anim-fade-in">
            <div className="mb-x-small">
              <Text size="regular" color="secondary">
                You selected
              </Text>
            </div>
            <div className="inline-block bg-tertiary rounded flex-row align-center hover-bg-forest-lightest">
              <span className="mr-small">
                <Avatar icon="user" shape="square" size="large" />
              </span>
              <Text weight="black" className="mr-medium" lineHeight={100}>
                Jonathan Murray
              </Text>
              <IconButton icon={'close'} color="transparent" iconColor="black" />
            </div>
          </div>

          <div className="anim-slide-left">
            <div className="pl-medium">
              <Text size="regular" color="secondary">
                Customers
              </Text>
            </div>

            <div className="bordered-top pv-medium ph-medium flex-row align-center hover-bg-tertiary cursor-pointer">
              <span className="mr-medium">
                <Avatar icon="user" size="large" />
              </span>
              <Text weight="black">Jir Wong</Text>
            </div>

            <div className="bordered-top pv-medium ph-medium flex-row align-center hover-bg-tertiary cursor-pointer">
              <span className="mr-medium">
                <Avatar icon="user" size="large" />
              </span>
              <Text weight="black">Jir Wong</Text>
            </div>

            <div className="bordered-top pv-medium ph-medium flex-row align-center hover-bg-tertiary cursor-pointer">
              <span className="mr-medium">
                <Avatar icon="user" size="large" />
              </span>
              <Text weight="black">Jir Wong</Text>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class FullScreenModalExample extends Component {
  state = { isFullScreenModalOpen: false };

  _openModal = () => this.setState({ isFullScreenModalOpen: true });
  _closeModal = () => this.setState({ isFullScreenModalOpen: false });

  render() {
    return (
      <div>
        <FullScreenModal
          onClose={this._closeModal}
          isOpen={this.state.isFullScreenModalOpen}
          footerContent={
            <FullScreenModalFooter>
              <div className="flex-row justify-between align-center">
                <div>
                  <Text size="x-large">
                    Hint: You can close this screen by pressing <code>Esc</code>
                  </Text>
                </div>

                <div>
                  <Text size="regular" color="green">
                    Step <b>1</b> out of <b>3</b>
                  </Text>
                </div>

                <div>
                  <Text className="mr-medium" size="x-large">
                    Can't find what you were looking for?
                  </Text>
                  <PrimaryButton size="large">Contact Us</PrimaryButton>
                </div>
              </div>
            </FullScreenModalFooter>
          }
          width={'medium'}
        >
          <CreateNewBookingPanel />
        </FullScreenModal>

        <PrimaryButton size="large" icon="plus" onClick={this._openModal}>
          Create New Booking
        </PrimaryButton>
      </div>
    );
  }
}

export class DialogPageView extends Component {
  render() {
    return (
      <div className="mb-x7-large">
        <Title level={1}>Dialog</Title>

        <Paragraph>This page illustrates the various pop up modals used throughout the GoodHuman Portal.</Paragraph>

        <div className="mb-x3-large">
          <Title level={3}>Primary Modals</Title>
          <Paragraph className="max-width-640">
            All actions that has a main primary modal should make use of the <code>ActionModal</code> component (found
            in <code>common-components/modal/ActionModal.tsx</code>). <br />
            <br />
            This is a sample of the ActionModal (see this page's code for the example code).
          </Paragraph>

          <ActionModalExample />
        </div>

        <div className="mb-x3-large">
          <Title level={3}>Popover Modals</Title>

          <Paragraph className="max-width-640">
            Popver modals are used for confirmation of actions and action menus hidden away on a button. <br /> <br />
            Do note that the Popover modal used is tfrom the <code>@blueprintjs/core</code> library. <br /> <br />
            See below for samples of both types.
          </Paragraph>

          <ConfirmModalExample />

          <MenuActionsExample />
        </div>

        <div className="mb-x3-large">
          <Title level={3}>Full Screen Modals</Title>

          <Paragraph className="max-width-640">
            Full Screen modals are used primarily for a wizard style series of actions. See below for an example of a
            full screen modal.
          </Paragraph>

          <FullScreenModalExample />
        </div>
      </div>
    );
  }
}
