import React, { PureComponent } from 'react';
import { Button, Col, Divider, Form, Pagination, Row, Tooltip } from 'antd';
import WorkerDetailsBookingItem from './WorkerDetailsBookingItem';
import WorkerBookingListFilterPanel from './WorkerBookingListFilterPanel';
import { Text, Title } from 'common-components/typography';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import _ from 'lodash';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import HoverCard from 'common-components/cards/HoverCard';
import { Colors } from '@blueprintjs/core';
import moment from 'moment';
import SpinningLoader from 'common-components/loading/SpinningLoader';

interface IWorkerDetailsBookingPanelProps extends FormComponentProps {
  doFetchTeamBookingList: typeof dispatch.teamStore.doFetchTeamBookingList;
  bookingsFilter: typeof state.bookingsStore.bookingsFilter;
  portalUser: typeof state.authStore.portalUser;
  history: any;
}

interface IWorkerDetailsBookingPanelState {
  isLoading: boolean;
  isRefresh: boolean;
  isFilterOpen: boolean;
  currentPage: number;
  pageSize: number;
  bookings: any;
  startDate: any;
  endDate: any;
  bookingStatus: string;
}

class WorkerDetailsBookingPanel extends PureComponent<
  IWorkerDetailsBookingPanelProps,
  IWorkerDetailsBookingPanelState
> {
  state = {
    isLoading: false,
    isRefresh: false,
    isFilterOpen: false,
    currentPage: 1,
    pageSize: 5,
    bookings: [],
    startDate: '',
    endDate: '',
    bookingStatus: ''
  };

  _onRefresh = async () => {
    const { doFetchTeamBookingList } = this.props;
    this.setState({ isRefresh: true, isLoading: true });
    const statusArr = [this.state.bookingStatus];
    const dateArr =
      this.state.startDate !== '' &&
      this.state.endDate !== '' &&
      this.state.startDate !== undefined &&
      this.state.endDate !== null
        ? [
            moment(this.state.startDate)
              .startOf('day')
              .format(),
            moment(this.state.endDate)
              .endOf('day')
              .format()
          ]
        : null;
    let bookings: any;
    if (statusArr && dateArr) {
      bookings = await doFetchTeamBookingList({ status: statusArr, startDate: dateArr });
    } else if (dateArr) {
      bookings = await doFetchTeamBookingList({ startDate: dateArr });
    } else {
      bookings = await doFetchTeamBookingList({});
    }

    this.setState({ isRefresh: false, bookings, isLoading: false });
  };

  async componentDidMount() {
    const { doFetchTeamBookingList } = this.props;
    this.setState({ isLoading: true });
    const bookings = await doFetchTeamBookingList({});
    this.setState({ isLoading: false, bookings });
  }

  private _handleStatus = async (event) => {
    const { doFetchTeamBookingList } = this.props;
    this.setState({ bookingStatus: event });
    let bookings;
    const { startDate, endDate } = this.state;

    if (event !== '') {
      this.setState({ isLoading: true });
      const statusArr = [];
      statusArr.push(event);
      const dateArr = [];
      if (startDate !== '' && endDate !== '' && startDate !== undefined && endDate !== null) {
        dateArr.push(
          moment(startDate)
            .startOf('day')
            .format()
        );
        dateArr.push(
          moment(endDate)
            .endOf('day')
            .format()
        );
        bookings = await doFetchTeamBookingList({ status: statusArr, startDate: dateArr });
      } else {
        bookings = await doFetchTeamBookingList({ status: statusArr });
      }

      this.setState({ isLoading: false, bookings });
    } else {
      const dateArr = [];
      if (
        startDate !== '' &&
        startDate !== 'undefined' &&
        startDate !== null &&
        endDate !== '' &&
        endDate !== 'undefined' &&
        endDate !== null
      ) {
        dateArr.push(
          moment(this.state.startDate)
            .startOf('day')
            .format()
        );
        dateArr.push(
          moment(this.state.endDate)
            .endOf('day')
            .format()
        );
        bookings = await doFetchTeamBookingList({ startDate: dateArr });
      } else {
        bookings = await doFetchTeamBookingList({});
      }
      this.setState({ isLoading: false, bookings });
    }
  };

  private _handleStartDate = async (startDate) => {
    const { doFetchTeamBookingList } = this.props;
    const endDate = this.state.endDate;
    let bookings;
    this.setState({ startDate });
    if (
      startDate !== '' &&
      startDate !== 'undefined' &&
      startDate !== null &&
      endDate !== '' &&
      endDate !== 'undefined' &&
      endDate !== null
    ) {
      const dateArr = [];
      dateArr.push(moment(startDate.startOf('day')).format());
      dateArr.push(
        moment(endDate)
          .endOf('day')
          .format()
      );
      if (this.state.bookingStatus !== '') {
        const statusArr = [];
        statusArr.push(this.state.bookingStatus);
        bookings = await doFetchTeamBookingList({ status: statusArr, startDate: dateArr });
      } else {
        bookings = await doFetchTeamBookingList({ startDate: dateArr });
      }
    } else {
      if (this.state.bookingStatus !== '') {
        const statusArr = [];
        statusArr.push(this.state.bookingStatus);
        bookings = await doFetchTeamBookingList({ status: statusArr });
      } else {
        bookings = await doFetchTeamBookingList({});
      }
    }
    this.setState({ isLoading: false, bookings });
  };

  private _handleEndDate = async (endDate) => {
    const { doFetchTeamBookingList } = this.props;
    const startDate = this.state.startDate;
    let bookings;
    this.setState({ endDate });
    if (
      startDate !== '' &&
      startDate !== 'undefined' &&
      startDate !== null &&
      endDate !== '' &&
      endDate !== 'undefined' &&
      endDate !== null
    ) {
      const dateArr = [];
      dateArr.push(
        moment(startDate)
          .startOf('day')
          .format()
      );
      dateArr.push(moment(endDate.endOf('day')).format());
      if (this.state.bookingStatus !== '') {
        const statusArr = [];
        statusArr.push(this.state.bookingStatus);
        bookings = await doFetchTeamBookingList({
          status: statusArr,
          startDate: dateArr
        });
      } else {
        bookings = await doFetchTeamBookingList({ startDate: dateArr });
      }
    } else {
      if (this.state.bookingStatus !== '') {
        const statusArr = [];
        statusArr.push(this.state.bookingStatus);
        bookings = await doFetchTeamBookingList({ status: statusArr });
      } else {
        bookings = await doFetchTeamBookingList({});
      }
    }
    this.setState({ isLoading: false, bookings });
  };

  private _onChangePagination = (page) => {
    this.setState({
      currentPage: page
    });
  };

  private _goBookingDetails = (bookingID) => {
    const { history } = this.props;
    history.push(`/bookings/details/${bookingID}`);
  };

  render() {
    const { form, bookingsFilter } = this.props;
    const { bookings, isLoading } = this.state;

    return (
      <div>
        <Title level={2} className={'mv-none'}>
          Bookings
        </Title>
        <Text type={'secondary'}>View bookings for this team member.</Text>
        <div className="bg-white mt-small">
          <WorkerBookingListFilterPanel
            form={form}
            filters={bookingsFilter}
            handleStatus={this._handleStatus}
            handleStartDate={this._handleStartDate}
            handleEndDate={this._handleEndDate}
            isOpen={this.state.isFilterOpen}
            isDisabled={isLoading}
          />
        </div>

        <Divider className="divider-medium" />

        <Row type={'flex'} justify={'space-between'} align={'bottom'} className="mb-medium">
          <Col>
            <Text type={'secondary'}>{bookings.length} bookings found.</Text>
          </Col>
          <Col className={'text-align-right'}>
            <Button.Group>
              <Tooltip title={'Refresh Bookings'} placement={'topLeft'}>
                <Button
                  icon={'reload'}
                  onClick={() => this._onRefresh()}
                  disabled={this.state.isRefresh}
                  loading={this.state.isRefresh}
                />
              </Tooltip>
            </Button.Group>
          </Col>
        </Row>

        {isLoading ? (
          <SpinningLoader size={50} message={'Retrieving bookings...'} />
        ) : (
          _.chain(bookings)
            .slice((this.state.currentPage - 1) * this.state.pageSize, this.state.currentPage * this.state.pageSize)
            .map((booking) => {
              return (
                <HoverCard
                  borderColor={Colors.LIGHT_GRAY1}
                  onClick={() => this._goBookingDetails(booking.bookingId)}
                  key={booking.bookingId}
                >
                  <WorkerDetailsBookingItem booking={booking} key={booking.bookingId} />
                </HoverCard>
              );
            })
            .value()
        )}
        <div className={'mt-x-large'}>
          {!_.isEmpty(bookings) && this.state.pageSize < bookings.length && (
            <Pagination
              total={bookings.length}
              defaultPageSize={this.state.pageSize}
              onChange={this._onChangePagination}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  bookingsFilter: state.bookingsStore.bookingsFilter
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchTeamBookingList: dispatch.teamStore.doFetchTeamBookingList
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IWorkerDetailsBookingPanelProps>()(WorkerDetailsBookingPanel));
