import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { IRootDispatch, dispatch } from 'stores/rematch/root-store';
import { Paragraph } from 'common-components/typography';
import { Col, Row, notification } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ICustomer, IGuardiansInfo } from 'interfaces/customer-interfaces';

interface IRemoveGuardianModalProps {
  isRemoveGuardianModalOpen: boolean;
  closeRemoveGuardianModal: () => void;
  doRemoveGuardian: typeof dispatch.customersStore.doRemoveGuardian;
  selectedGuardian: IGuardiansInfo;
  selectedCustomer: ICustomer;
}

interface IRemoveGuardianModalState {}

class RemoveGuardianModal extends Component<IRemoveGuardianModalProps, IRemoveGuardianModalState> {
  private _closeModal = () => {
    this.props.closeRemoveGuardianModal();
  };

  private _onRemoveGuardian = async () => {
    const { selectedCustomer, selectedGuardian, doRemoveGuardian } = this.props;

    try {
      const res = await doRemoveGuardian({
        customerUserId: selectedCustomer.userId,
        guardianUserId: selectedGuardian.guardianUserId
      });
      notification.open({
        message: <span className={'text-weight-bolder'}>Legal Guardian removed</span>,
        description: (
          <span>
            You have removed{' '}
            <span className={'text-weight-bolder'}>
              {selectedGuardian.firstName} {selectedGuardian.lastName}
            </span>{' '}
            as a legal guardian for this customer.
          </span>
        )
      });
      this._closeModal();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  render() {
    const { isRemoveGuardianModalOpen } = this.props;
    const title = 'Remove legal guardian';

    return (
      <div>
        <ActionModal isOpen={isRemoveGuardianModalOpen} title={title} onClose={this._closeModal}>
          <Paragraph>
            Are you sure you want to remove this legal guardian from the customers profile.
            <br /> <br />
            The selected legal guardian will no longer be able to manage this customers profile. Ensure you have
            recieved express permission from the customer before you do this.
          </Paragraph>

          <ActionModalFooter className={'mt-large'}>
            <Row type={'flex'} className={'justify-end'}>
              <Col className="mr-medium">
                <SecondaryButton size="large" onClick={this._closeModal}>
                  Cancel
                </SecondaryButton>
              </Col>
              <Col>
                <PrimaryButton onClick={this._onRemoveGuardian} size="large">
                  Remove
                </PrimaryButton>
              </Col>
            </Row>
          </ActionModalFooter>
        </ActionModal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doRemoveGuardian: dispatch.customersStore.doRemoveGuardian
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveGuardianModal);
