import React, { Component } from 'react';
import { Col, Row, notification, Icon } from 'antd';
import { FieldLabel, Paragraph, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';
import ServiceItemInputModal from 'views/customers/details/tabs-panel/new-service-agreements/common-modals/ServiceItemInputModal';
import { IServiceAgreement, IServiceAgreementAdditionalCharges } from 'interfaces/customer-interfaces';
import { ServiceAgreementStatus } from 'utilities/enum-utils';
import ServiceItemViewModal from 'views/customers/details/tabs-panel/new-service-agreements/common-modals/ServiceItemViewModal';

interface IEditServiceAgreementServiceItemsModalProps {
  closeEdit: () => void;
  isOpen: boolean;
  serviceAgreement: IServiceAgreement;
  doUpdateServiceAgreementServiceLineItems: typeof dispatch.customersStore.doUpdateServiceAgreementServiceLineItems;
}

interface IEditServiceAgreementServiceItemsModalState {
  isLoading: boolean;
  isViewModalOpen: boolean;
  isQuoteModalOpen: boolean;
  startDate: Date;
  endDate: Date;
  isErrorEndDate: boolean;
  isProceedModalOpen: boolean;
  isEdit: boolean;
  selectedService: any;
  isDeleteModalOpen: boolean;
  isAddModalOpen: boolean;
  errorEmptyList: boolean;
  agreementServiceItems: Array<any>;
}

class EditServiceAgreementServiceItemsModal extends Component<
  IEditServiceAgreementServiceItemsModalProps,
  IEditServiceAgreementServiceItemsModalState
> {
  state = {
    isLoading: false,
    isViewModalOpen: false,
    startDate: null,
    endDate: null,
    isErrorEndDate: false,
    isProceedModalOpen: false,
    isQuoteModalOpen: false,
    isEdit: false,
    selectedService: null,
    isDeleteModalOpen: false,
    isAddModalOpen: false,
    errorEmptyList: false,
    agreementServiceItems:
      this.props.serviceAgreement && this.props.serviceAgreement.services ? this.props.serviceAgreement.services : []
  };

  private _saveNewServiceItems = async () => {
    this.setState({ isLoading: true });
    if (_.isEmpty(this.state.agreementServiceItems)) {
      this.setState({ errorEmptyList: true });
    } else {
      try {
        const newServices = _.map(this.state.agreementServiceItems, (service: any) => {
          const { comment, serviceId, lineItems, teamMemberCustomerRatio, additionalCharges } = service;
          const updatedLineItems = _.map(lineItems, (item) => {
            const { supportItemNumber, supportCategoryNumber, priority, groupCode } = item;
            return {
              supportItemNumber,
              supportCategoryNumber,
              priority,
              groupCode
            };
          });
          return {
            serviceId,
            comment,
            lineItems: updatedLineItems,
            teamMemberCustomerRatio: teamMemberCustomerRatio ? teamMemberCustomerRatio.ndis : null,
            additionalCharges
          };
        });

        await this.props.doUpdateServiceAgreementServiceLineItems({
          serviceAgreementId: this.props.serviceAgreement.userServiceAgreementId,
          services: newServices
        });

        notification.success({ message: 'Service agreement updated successfully.' });
        this._closeProceedModal();
        this._closeQuoteModal();
        this.props.closeEdit();
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
    this.setState({ isLoading: false });
  };

  private _openProceedModal = () => {
    this._closeQuoteModal();
    this.setState({ isProceedModalOpen: true });
  };

  private _closeProceedModal = () => {
    this.setState({ isProceedModalOpen: false });
  };

  private _openQuoteModal = () => {
    this.setState({ isQuoteModalOpen: true });
  };

  private _closeQuoteModal = () => {
    this.setState({ isQuoteModalOpen: false });
  };

  private _onOpenAddModal = () => this.setState({ isEdit: false, selectedService: null, isAddModalOpen: true });
  private _onCloseAddModal = () => this.setState({ isAddModalOpen: false, isEdit: false });

  private _onOpenDeleteModal = (service) => this.setState({ selectedService: service, isDeleteModalOpen: true });
  private _onCloseDeleteModal = () => this.setState({ selectedService: null, isDeleteModalOpen: false });

  private _addNewService = (service) => {
    this.setState({ agreementServiceItems: [...this.state.agreementServiceItems, service], errorEmptyList: false });
  };

  private _editService = (service) => {
    this.setState({
      agreementServiceItems: _.map(this.state.agreementServiceItems, (existingService) => {
        if (existingService.serviceId === service.serviceId) {
          return { ...service };
        } else {
          return { ...existingService };
        }
      })
    });
  };

  private _deleteService = (service) => {
    this.setState({
      agreementServiceItems: _.filter(
        this.state.agreementServiceItems,
        (existingService) => service.serviceId !== existingService.serviceId
      )
    });
    this._onCloseDeleteModal();
  };

  private _openEditService = (service) => {
    this.setState({ isEdit: true, selectedService: service, isAddModalOpen: true });
  };

  private _openViewModal = (service) => {
    this.setState({ isViewModalOpen: true, selectedService: service });
  };

  private _onCloseViewModal = () => {
    this.setState({ isViewModalOpen: false, selectedService: null });
  };

  componentDidUpdate(
    prevProps: Readonly<IEditServiceAgreementServiceItemsModalProps>,
    prevState: Readonly<IEditServiceAgreementServiceItemsModalState>,
    snapshot?: any
  ) {
    if (prevProps.serviceAgreement !== this.props.serviceAgreement || (!prevProps.isOpen && this.props.isOpen)) {
      this.setState({
        agreementServiceItems: this.props.serviceAgreement.services
      });
    }
  }

  render() {
    const { serviceAgreement } = this.props;
    const { agreementServiceItems } = this.state;

    const noItems = _.isEmpty(agreementServiceItems);
    return (
      <>
        <ActionModal
          isOpen={this.state.isDeleteModalOpen}
          onClose={this._onCloseDeleteModal}
          title={
            <>
              Delete <b>schedule of support</b> item
            </>
          }
          showCloseButton={true}
        >
          <Paragraph className={'mb-medium'}>This line item will be deleted from this service agreement.</Paragraph>
          <Paragraph className={'mb-medium'}>Do you want to proceed?</Paragraph>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseDeleteModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" color={'red'} onClick={() => this._deleteService(this.state.selectedService)}>
              Delete
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <ServiceItemViewModal
          onClose={this._onCloseViewModal}
          isOpen={this.state.isViewModalOpen}
          selectedServiceLineItems={this.state.selectedService ? this.state.selectedService.lineItems : null}
          paymentSourceType={serviceAgreement && serviceAgreement.paymentSourceType}
        />
        <ActionModal
          isOpen={this.state.isProceedModalOpen}
          onClose={this._closeProceedModal}
          title={'Editing a signed service agreement'}
          showCloseButton={true}
        >
          <br />
          <Text className={'mb-medium'}>
            You are making a change to a <b>signed</b> service agreement. Doing so will revert the status of this
            service agreement to <b>unsigned. Are you sure you want to make this change?</b>
          </Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeProceedModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size="large" onClick={this._saveNewServiceItems}>
              Save changes
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.state.isQuoteModalOpen}
          onClose={this._closeQuoteModal}
          title={'Changes to quote'}
          showCloseButton={true}
        >
          <Paragraph>
            As a result of new changes you have made to the services being delivered, the <b>value of the quote</b> for
            this service agreement will be <b>recalculated</b> in order to remain accurate.
          </Paragraph>
          <Paragraph>
            This may result in <b>significant changes</b> to the value of the quote. Are you sure you wish to continue?
          </Paragraph>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._closeQuoteModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              size="large"
              loading={this.state.isLoading}
              onClick={() => {
                if (
                  this.props.serviceAgreement &&
                  this.props.serviceAgreement.signedStatus === ServiceAgreementStatus.SIGNED
                ) {
                  this._openProceedModal();
                } else {
                  this._saveNewServiceItems();
                }
              }}
            >
              Continue
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this.props.closeEdit}
          width={'x3-large'}
          title={
            <>
              Edit <b>Service being delivered</b>
            </>
          }
        >
          {agreementServiceItems && (
            <>
              <Paragraph>
                Edit the details of the services being provided to the customer as part of this service agreement.
              </Paragraph>
              <div className="mt-large mb-x2-large">
                {/* Content here*/}
                <div className="mb-medium">
                  <HyperlinkButton onClick={this._onOpenAddModal}>
                    <Icon type="plus" />
                    &nbsp;Add service
                  </HyperlinkButton>
                </div>

                <div style={{ maxHeight: '200px', overflowY: 'auto' }} className={'pr-small'}>
                  <div className="bordered-bottom pb-medium">
                    <Row gutter={8}>
                      <Col span={6}>
                        <div className="ph-medium">
                          <FieldLabel text={'SERVICE NAME'} />
                        </div>
                      </Col>
                      <Col span={6}>
                        <div className="ph-medium">
                          <FieldLabel text={'LINE ITEM(S)'} />
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="ph-medium">
                          <FieldLabel text={'COMMENTS'} />
                        </div>
                      </Col>
                      <Col span={2} />
                      <Col span={2} />
                    </Row>
                  </div>

                  {noItems && (
                    <div className="p-medium">
                      <Text color={'secondary'}>No items added yet. Click on Add service to add one.</Text>
                    </div>
                  )}

                  {!noItems && (
                    <div>
                      {_.map(agreementServiceItems, (agreementServiceItem, idx) => (
                        <AgreementServiceItem
                          service={agreementServiceItem}
                          openDeleteModal={this._onOpenDeleteModal}
                          openEditService={this._openEditService}
                          openViewModal={this._openViewModal}
                          key={idx}
                        />
                      ))}
                    </div>
                  )}
                  {this.state.errorEmptyList && (
                    <Text color={'red-dark'} className={'pl-medium'}>
                      Please add at least one one service to the list of services being provided to the customer.
                    </Text>
                  )}
                </div>
              </div>
              <ActionModalFooter>
                <SecondaryButton size="large" onClick={this.props.closeEdit} className="mr-medium">
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  onClick={() => {
                    if (
                      this.props.serviceAgreement &&
                      this.props.serviceAgreement.quotations &&
                      this.props.serviceAgreement.quotations.length > 0
                    ) {
                      this._openQuoteModal();
                    } else if (
                      this.props.serviceAgreement &&
                      this.props.serviceAgreement.signedStatus === ServiceAgreementStatus.SIGNED
                    ) {
                      this._openProceedModal();
                    } else {
                      this._saveNewServiceItems();
                    }
                  }}
                  loading={this.state.isLoading}
                >
                  Save
                </PrimaryButton>
              </ActionModalFooter>
              <ServiceItemInputModal
                onClose={this._onCloseAddModal}
                isOpen={this.state.isAddModalOpen}
                addNewService={this._addNewService}
                editService={this._editService}
                isEdit={this.state.isEdit}
                existingServices={this.state.agreementServiceItems}
                selectedService={this.state.selectedService}
                paymentSourceType={this.props.serviceAgreement && this.props.serviceAgreement.paymentSourceType}
              />
            </>
          )}
        </ActionModal>
      </>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateServiceAgreementServiceLineItems: dispatch.customersStore.doUpdateServiceAgreementServiceLineItems
});

export default connect(
  null,
  mapDispatch
)(EditServiceAgreementServiceItemsModal);

function AgreementServiceItem({ service, openDeleteModal, openEditService, openViewModal }) {
  return (
    <div className="pv-medium evenodd">
      <Row gutter={16}>
        <Col span={6}>
          <div className="ph-medium">{service.serviceName}</div>
        </Col>
        <Col span={6}>
          <div className="ph-medium">
            {service.lineItems && service.lineItems.length > 0 ? (
              <HyperlinkButton onClick={() => openViewModal(service)}>
                {service.lineItems.length} line item
                {service.lineItems.length !== 1 ? 's' : ''} selected
              </HyperlinkButton>
            ) : (
              '0 line item selected'
            )}
          </div>
        </Col>
        <Col span={8} title={service.comment ? service.comment : 'No comment.'}>
          <Paragraph className="ph-medium m-none" ellipsis={true}>
            {service.comment ? service.comment : '-'}
          </Paragraph>
        </Col>
        <Col span={2}>
          <HyperlinkButton onClick={() => openEditService(service)}>
            Edit <Icon type={'edit'} />
          </HyperlinkButton>
        </Col>
        <Col span={2}>
          <HyperlinkButton color={'red-dark'} onClick={() => openDeleteModal(service)}>
            Delete <Icon type={'delete'} />
          </HyperlinkButton>
        </Col>
      </Row>
    </div>
  );
}
