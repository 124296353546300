import { timeZone } from 'interfaces/timezone-type';
import { Avatar, Col, Row } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';
import moment from 'moment-timezone';
import { HyperlinkButton } from 'common-components/buttons';
import { ServiceType } from 'utilities/enum-utils';

interface IConflictResultProps {
  conflict: any;
  timezone: timeZone;
  displayTeamMember?: boolean;
  goToBooking: (bookingId) => void;
  goToSession: (serviceId, serviceDateTimeId) => void;
}

export function ConflictResult(props: IConflictResultProps) {
  const { conflict, timezone, displayTeamMember, goToBooking, goToSession } = props;

  const selectedShift = conflict.selectedShift;
  const conflictShift = conflict.conflictShift;

  return (
    <Row className="pv-large evenodd" type={'flex'} align={'middle'}>
      <Col span={displayTeamMember ? 8 : 12} className="ph-medium  height-full">
        <div>
          <Text weight={'bold'}>{selectedShift.serviceName}</Text>
          <br />
          <Text>
            {moment.tz(selectedShift.startDateTime, timezone).format('D MMMM YYYY')}
            <br />
            {moment.tz(selectedShift.startDateTime, timezone).format('hh:mm A')} -{' '}
            {moment.tz(selectedShift.endDateTime, timezone).format('hh:mm A')}
          </Text>
        </div>
      </Col>
      <Col span={displayTeamMember ? 8 : 12} className="ph-medium height-full">
        <div>
          <HyperlinkButton
            color={'red-dark'}
            onClick={
              conflictShift.serviceType === ServiceType.INDIVIDUAL
                ? () => goToBooking(conflictShift.attendanceId)
                : () => goToSession(conflictShift.serviceId, conflictShift.serviceDateTimeId)
            }
          >
            {conflictShift.serviceName}
          </HyperlinkButton>
          <br />
          <Text>
            {moment.tz(conflictShift.startDateTime, timezone).format('D MMMM YYYY')}
            <br />
            {moment.tz(conflictShift.startDateTime, timezone).format('hh:mm A')} -{' '}
            {moment.tz(conflictShift.endDateTime, timezone).format('hh:mm A')}
          </Text>
        </div>
      </Col>
      {displayTeamMember && (
        <Col span={displayTeamMember ? 8 : 12} className="ph-medium height-full">
          <div>
            <Avatar icon="user" shape={'square'} size={'large'} src={conflictShift.attachmentUrl} />
            <Text className="ml-small">
              {conflictShift.workerFirstName} {conflictShift.workerLastName}
            </Text>
          </div>
        </Col>
      )}
    </Row>
  );
}
