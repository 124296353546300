import React, { Component, PureComponent } from 'react';
import { Icon, Timeline } from 'antd';
import { Text, Title } from 'common-components/typography';
import { Colors } from '@blueprintjs/core';
import moment from 'moment-timezone';

interface PlanManagementInvoiceActivityLogPanelProps {
  invoiceAuditLogs;
  timezoneData?: any;
}

interface PlanManagementInvoiceActivityLogPanelState {
  isLoading: boolean;
}

class PlanManagementInvoiceActivityLogPanel extends Component<
  PlanManagementInvoiceActivityLogPanelProps,
  PlanManagementInvoiceActivityLogPanelState
> {
  state = {
    isLoading: false
  };

  render() {
    const { invoiceAuditLogs } = this.props;
    return (
      <div className="mt-large">
        <Title level={3} className="mb-none" weight="bold">
          Activity log for this invoice
        </Title>

        <div className="pl-medium pt-medium mt-large">
          <Timeline>
            {invoiceAuditLogs.map((invoiceAuditLog, idx) => {
              const iconColor = invoiceAuditLog.role === 'Workspace user' ? Colors.GREEN5 : Colors.GOLD5;

              return (
                <PlanManagementInvoiceHistoryItem
                  key={idx}
                  invoiceAuditLog={invoiceAuditLog}
                  icon={'calendar'}
                  iconColor={iconColor}
                  timezoneData={this.props.timezoneData}
                />
              );
            })}
          </Timeline>
        </div>
      </div>
    );
  }
}

export default PlanManagementInvoiceActivityLogPanel;

interface PlanManagementInvoiceHistoryItemProps {
  icon: any;
  iconColor: string;
  timezoneData: any;
  invoiceAuditLog: any;
}

class PlanManagementInvoiceHistoryItem extends PureComponent<PlanManagementInvoiceHistoryItemProps> {
  static defaultProps = {
    iconColor: Colors.GOLD5,
    icon: 'calendar'
  };

  render() {
    const { invoiceAuditLog, icon, iconColor, timezoneData } = this.props;
    return (
      <Timeline.Item
        key={invoiceAuditLog.planManagementAuditLogId}
        dot={
          <Icon
            type={icon}
            style={{
              backgroundColor: iconColor,
              padding: '12px',
              color: 'white',
              borderRadius: '4px',
              fontSize: '15px'
            }}
          />
        }
      >
        <div className="pl-medium">
          <div className="flex-row align-center">
            <Text className="mr-small text-weight-bold">{invoiceAuditLog.role}</Text>

            <Text className="text-size-small text-color-secondary">
              {moment.tz(invoiceAuditLog.createdOn, timezoneData).format('h:mm A, ddd Do MMMM YYYY')}
            </Text>
          </div>

          <div className="mt-small ml-x-small">
            <Text>
              {invoiceAuditLog.firstName} {invoiceAuditLog.lastName} {invoiceAuditLog.actionType}
            </Text>
          </div>
          <br />
        </div>
      </Timeline.Item>
    );
  }
}
