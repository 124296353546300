import { Menu, MenuDivider, MenuItem, ProgressBar } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Avatar, Checkbox, Col, Empty, Icon, Input, Row, Skeleton, Tabs, Tooltip } from 'antd';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { GridHeader, GridRow } from 'common-components/grids';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { FieldLabel, Paragraph, Text, Title } from 'common-components/typography';
import * as H from 'history';
import CenteredLayout from 'layouts/CenteredLayout';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { InvitationStatus, TeamStatus, UserPermissionRole } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import AddNewMemberModal from 'views/account-management/add-new-member/AddNewMemberModal';
import TeamManagementModals from 'views/account-management/components/TeamManagementModals/TeamManagementModals';

const { TabPane } = Tabs;
const { Search } = Input;

interface ITeamManagementViewProps {
  accountTeamList: typeof state.teamStore.accountTeamList;
  accountTeamFilter: typeof state.teamStore.accountTeamFilter;
  invitesTeamList: typeof state.teamStore.invitesTeamList;
  invitesTeamFilter: typeof state.teamStore.invitesTeamFilter;
  portalUser: typeof state.authStore.portalUser;
  doFetchAccountTeamList: typeof dispatch.teamStore.doFetchAccountTeamList;
  setAccountTeamFilter: typeof dispatch.teamStore.setAccountTeamFilter;
  doFetchInvitations: typeof dispatch.teamStore.doFetchInvitations;
  setInvitesTeamFilter: typeof dispatch.teamStore.setInvitesTeamFilter;
  doFetchActiveTeamMemberCount: typeof dispatch.accountStore.doFetchActiveTeamMemberCount;
  history: H.History;
}

interface ITeamManagementViewState {
  isModalOpen: boolean;
  selectedDepartment: any;
  isLoading: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  isLoadingInfiniteScrolling: boolean;
  selectedTab: string;
  isOpen: boolean;
  selectedModal: string;
  modalData: any;
  activeTeamMemberCount: number;
}

const ListEmptyState = () => (
  <div className="flex-1 bg-white  align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No team member found.
    </Text>{' '}
    <br /> <br />
    <Text color="secondary">All team member under this filter will appear here.</Text>
    <Text color="secondary">Try adjusting your filter, or clicking on another tab.</Text>
  </div>
);

const ListEmptyInvitesState = () => (
  <div className="flex-1 bg-white  align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No invites found.
    </Text>{' '}
    <br /> <br />
    <Text color="secondary">All invites under this filter will appear here.</Text>
    <Text color="secondary">Try adjusting your filter, or clicking on another tab.</Text>
  </div>
);

class TeamManagementView extends Component<ITeamManagementViewProps, ITeamManagementViewState> {
  state = {
    isModalOpen: false,
    selectedDepartment: null,
    isLoading: true,
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    isLoadingInfiniteScrolling: false,
    selectedTab: 'TEAM',
    isOpen: false,
    selectedModal: null,
    modalData: null,
    activeTeamMemberCount: null,
  };

  private _goToLandingPage = () => {
    this.props.history.push('/account/landing');
  };

  private _onOpenModal = (selectedModal, modalData = null) => {
    this.setState({ isOpen: true, selectedModal, modalData });
  };

  openModal = () => this.setState({ isModalOpen: true });
  closeModal = async () => {
    await this.setState({ page: 1, pageTimestamp: new Date(), isLoading: true });
    await this.props.doFetchAccountTeamList({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
    });
    await this.props.doFetchInvitations({});
    this.setState({ isModalOpen: false, isLoading: false });
  };

  private _onCloseModal = async () => {
    const { pageSize, page, pageTimestamp } = this.state;
    const { doFetchAccountTeamList, doFetchInvitations } = this.props;
    this.setState({ isOpen: false, selectedModal: null, modalData: null, isLoading: false });
    // We need to fetch all loaded workers again
    await doFetchAccountTeamList({
      page: 1,
      pageSize: pageSize * page,
      pageTimestamp,
    });
    await doFetchInvitations({});
  };

  private _onEnterSearchText = (e) => {
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this.setState({ isLoading: true });
      this._debounceSearch(e.target.value);
    }
  };

  private _goToTeamProfile = (workerId) => {
    this.props.history.push(`/team/details/${workerId}`);
  };

  private _searchText = async (txt) => {
    const {
      doFetchAccountTeamList,
      setAccountTeamFilter,
      accountTeamFilter,
      doFetchInvitations,
      setInvitesTeamFilter,
      invitesTeamFilter,
    } = this.props;
    if (this.state.selectedTab === 'TEAM') {
      this.setState({ page: 1 });
      setAccountTeamFilter({ ...accountTeamFilter, search: txt });
      await doFetchAccountTeamList({
        page: this.state.page,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
      });
    } else {
      setInvitesTeamFilter({ ...invitesTeamFilter, search: txt });
      await doFetchInvitations({});
    }
    this.setState({ isLoading: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _fetchMoreWorkers = async () => {
    const { doFetchAccountTeamList } = this.props;
    await this.setState({ isLoadingInfiniteScrolling: true, page: this.state.page + 1 });
    await doFetchAccountTeamList({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
    });
    this.setState({ isLoadingInfiniteScrolling: false });
  };

  private _getActionMenu = (worker, portalUserOwnerRole) => {
    const { portalUser } = this.props;
    return (
      <Menu>
        <MenuDivider title={'VIEW'} className={'text-color-secondary bordered-top pt-large'} />

        {PermissionUtils.validatePermission('ViewTeamMemberProfile', portalUser.permissions.permissionRoles) && (
          <MenuItem
            label={''}
            className="hover-bg-blue-lightest mv-medium"
            text={'View profile'}
            onClick={() => this._goToTeamProfile(worker.supportWorkerId)}
          />
        )}

        {PermissionUtils.validatePermission('ViewTeamMemberPermissions', portalUser.permissions.permissionRoles) &&
          worker.status !== TeamStatus.REMOVED && (
            <MenuItem
              label={''}
              className="hover-bg-blue-lightest mv-medium"
              text={'View permissions'}
              onClick={() => this._onOpenModal('EditPermissionModal', worker)}
            />
          )}

        {(worker.status === TeamStatus.REMOVED || worker.removedOn) && (
          <MenuItem
            label={''}
            className="hover-bg-blue-lightest mv-medium"
            text={'View removal reason'}
            onClick={() => this._onOpenModal('ViewRemovalReason', worker)}
          />
        )}

        {(portalUserOwnerRole < worker.ownerRole ||
          worker.status === TeamStatus.DRAFT ||
          (worker.status === TeamStatus.ENABLED && worker.invitationStatus === InvitationStatus.PENDING)) && (
          <MenuDivider title={'MODIFY STATUS'} className={'text-color-secondary bordered-top pt-large'} />
        )}
        {(worker.status === TeamStatus.BLOCKED ||
          (worker.status === TeamStatus.ENABLED &&
            (worker.invitationStatus === InvitationStatus.ACCEPTED ||
              worker.invitationStatus === InvitationStatus.DELETED))) &&
          worker.removedOn &&
          PermissionUtils.validatePermission('EditRemovalDate', portalUser.permissions.permissionRoles) &&
          portalUserOwnerRole < worker.ownerRole && (
            <MenuItem
              label={''}
              className="hover-bg-blue-lightest mv-medium"
              text={'Edit removal date'}
              onClick={() => this._onOpenModal('EditRemovalDateModal', worker)}
            />
          )}
        {worker.status === TeamStatus.REMOVED && (
          <MenuItem
            label={''}
            className="hover-bg-blue-lightest mv-medium text-color-green"
            text={'Re-enable user'}
            onClick={() => this._onOpenModal('ReEnableUser', worker)}
          />
        )}

        {worker.status === TeamStatus.ENABLED &&
          worker.invitationStatus === InvitationStatus.ACCEPTED &&
          PermissionUtils.validatePermission('BlockTeamMember', portalUser.permissions.permissionRoles) &&
          portalUserOwnerRole < worker.ownerRole && (
            <MenuItem
              label={''}
              className="hover-bg-blue-lightest mv-medium"
              text={'Block access'}
              onClick={() => this._onOpenModal('BlockAccessModal', worker)}
            />
          )}

        {worker.status === TeamStatus.BLOCKED &&
          PermissionUtils.validatePermission('UnblockTeamMember', portalUser.permissions.permissionRoles) &&
          portalUserOwnerRole < worker.ownerRole && (
            <MenuItem
              label={''}
              className="hover-bg-blue-lightest mv-medium"
              text={'Unblock access'}
              onClick={() => this._onOpenModal('UnblockAccessModal', worker)}
            />
          )}

        {worker.status === TeamStatus.DRAFT &&
          PermissionUtils.validatePermission('InviteDraftTeamMember', portalUser.permissions.permissionRoles) && (
            <MenuItem
              label={''}
              className="hover-bg-blue-lightest mv-medium"
              text={'Invite user'}
              onClick={() => this._onOpenModal('InviteUserModal', worker)}
            />
          )}

        {worker.status === TeamStatus.DRAFT &&
          PermissionUtils.validatePermission('DeleteDraftTeamMember', portalUser.permissions.permissionRoles) && (
            <MenuItem
              label={''}
              className="hover-bg-blue-lightest text-color-red-dark mv-medium"
              text={'Delete draft'}
              onClick={() => this._onOpenModal('DeleteDraftUserModal', worker)}
            />
          )}

        {worker.status === TeamStatus.ENABLED &&
          worker.invitationStatus === InvitationStatus.PENDING &&
          PermissionUtils.validatePermission('RevokeInvites', portalUser.permissions.permissionRoles) && (
            <MenuItem
              label={''}
              className="hover-bg-blue-lightest mv-medium text-color-red-dark"
              text={'Revoke invitation'}
              onClick={() => this._onOpenModal('RevokeInvitationModal', { ...worker, mode: 'TEAM' })}
            />
          )}
        {(worker.status === TeamStatus.BLOCKED ||
          (worker.status === TeamStatus.ENABLED &&
            (worker.invitationStatus === InvitationStatus.ACCEPTED ||
              worker.invitationStatus === InvitationStatus.DELETED))) &&
          worker.removedOn &&
          PermissionUtils.validatePermission('CancelRemovalDate', portalUser.permissions.permissionRoles) &&
          portalUserOwnerRole < worker.ownerRole && (
            <MenuItem
              label={''}
              className="hover-bg-blue-lightest mv-medium"
              text={'Cancel removal'}
              onClick={() => this._onOpenModal('CancelRemovalDateModal', worker)}
            />
          )}
        {(worker.status === TeamStatus.BLOCKED ||
          (worker.status === TeamStatus.ENABLED &&
            (worker.invitationStatus === InvitationStatus.ACCEPTED ||
              worker.invitationStatus === InvitationStatus.DELETED))) &&
          PermissionUtils.validatePermission('RemoveTeamMember', portalUser.permissions.permissionRoles) &&
          portalUserOwnerRole < worker.ownerRole && (
            <MenuItem
              label={''}
              className="hover-bg-blue-lightest text-color-red-dark mv-medium"
              text={'Remove'}
              onClick={() => this._onOpenModal('PermanentlyRemoveUserModal', worker)}
            />
          )}
      </Menu>
    );
  };

  private _getInviteMenu = (status, worker) => {
    const { portalUser } = this.props;
    return (
      <Menu>
        <MenuDivider title={'INVITE OPTIONS'} className={'text-color-secondary bordered-top pt-large'} />

        {PermissionUtils.validatePermission('ResendInvites', portalUser.permissions.permissionRoles) && (
          <MenuItem
            label={''}
            className="hover-bg-blue-lightest mv-medium"
            text={status === InvitationStatus.PENDING ? 'Re-send invitation' : 'Modify invitation'}
            onClick={() =>
              this._onOpenModal(
                status === InvitationStatus.PENDING ? 'ReSendInvitationModal' : 'ReSendRevokedInvitationModal',
                worker,
              )
            }
          />
        )}

        {status === 'PENDING' && (
          <>
            {PermissionUtils.validatePermission('ResendInvites', portalUser.permissions.permissionRoles) && (
              <MenuItem
                label={''}
                className="hover-bg-blue-lightest mv-medium"
                text={'Modify invitation'}
                onClick={() => this._onOpenModal('ModifyInvitationModal', worker)}
              />
            )}
            {PermissionUtils.validatePermission('RevokeInvites', portalUser.permissions.permissionRoles) && (
              <MenuItem
                label={''}
                className="hover-bg-blue-lightest mv-medium text-color-red-dark"
                text={'Revoke invitation'}
                onClick={() => this._onOpenModal('RevokeInvitationModal', { ...worker, mode: 'INVITE' })}
              />
            )}
          </>
        )}
      </Menu>
    );
  };

  private _updateFilter = async (event, category, filterName) => {
    const { accountTeamFilter, doFetchAccountTeamList } = this.props;
    this.setState({ isLoading: true, page: 1 });
    let newFilter = { ...accountTeamFilter };
    newFilter.filter[category][filterName] = event.target.checked;
    await this.props.setAccountTeamFilter({ ...accountTeamFilter, filter: newFilter.filter });
    await doFetchAccountTeamList({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
    });
    this.setState({ isLoading: false });
  };

  private _getTeamFilterPopoverContent = () => {
    const { accountTeamFilter } = this.props;
    const filter = accountTeamFilter.filter
      ? accountTeamFilter.filter
      : {
          status: { isEnabled: true, isBlocked: true, isDraft: true, isRemoved: true },
          permission: { isOwner: true, hasPortalAccess: true, hasWorkerAppAccess: true },
          billable: { isBillable: true, isNotBillable: true },
        };
    return (
      <Row gutter={24} className={'p-large'} style={{ width: '600px' }}>
        <Col span={6}>
          <div className={'mb-medium'}>Status</div>
          <Checkbox
            className={'m-none mv-x-small'}
            onChange={(event) => this._updateFilter(event, 'status', 'isEnabled')}
            checked={filter.status.isEnabled}
          >
            <Text color={'green'}>Enabled</Text>
          </Checkbox>
          <Checkbox
            className={'m-none mv-x-small'}
            onChange={(event) => this._updateFilter(event, 'status', 'isBlocked')}
            checked={filter.status.isBlocked}
          >
            <Text>Blocked</Text>
          </Checkbox>
          <Checkbox
            className={'m-none mv-x-small'}
            onChange={(event) => this._updateFilter(event, 'status', 'isDraft')}
            checked={filter.status.isDraft}
          >
            <Text color={'secondary'}>Draft</Text>
          </Checkbox>
          <Checkbox
            className={'m-none mv-x-small'}
            onChange={(event) => this._updateFilter(event, 'status', 'isRemoved')}
            checked={filter.status.isRemoved}
          >
            <Text color={'red-dark'}>Removed</Text>
          </Checkbox>
        </Col>
        <Col span={9}>
          <div className={'mb-medium'}>Permission summary</div>
          <Checkbox
            className={'m-none mv-x-small'}
            onChange={(event) => this._updateFilter(event, 'permission', 'hasPortalAccess')}
            checked={filter.permission.hasPortalAccess}
          >
            Workspace
          </Checkbox>
          <Checkbox
            className={'m-none mv-x-small'}
            onChange={(event) => this._updateFilter(event, 'permission', 'hasWorkerAppAccess')}
            checked={filter.permission.hasWorkerAppAccess}
          >
            Business App
          </Checkbox>
        </Col>
        <Col span={7}>
          <div className={'mb-medium'}>Billable this month</div>
          <Checkbox
            className={'m-none mv-x-small'}
            onChange={(event) => this._updateFilter(event, 'billable', 'isBillable')}
            checked={filter.billable.isBillable}
          >
            <Icon type={'check-circle'} className={'text-color-warning-orange mr-x-small'} theme={'filled'} />
            Yes
          </Checkbox>
          <br />
          <Checkbox
            className={'m-none mv-x-small'}
            onChange={(event) => this._updateFilter(event, 'billable', 'isNotBillable')}
            checked={filter.billable.isNotBillable}
          >
            <Text color={'secondary'}>No</Text>
          </Checkbox>
        </Col>
      </Row>
    );
  };

  private _changeTab = (event) => {
    this.setState({ selectedTab: event });
  };

  componentDidMount = async () => {
    await this.props.doFetchAccountTeamList({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
    });
    await this.props.doFetchInvitations({});
    this.setState({
      isLoading: false,
      activeTeamMemberCount: Number(await this.props.doFetchActiveTeamMemberCount({})),
    });
  };

  render() {
    const { accountTeamList, invitesTeamList, portalUser } = this.props;
    const { activeTeamMemberCount } = this.state;

    const portalUserOwnerRole = !!_.find(
      portalUser.permissions.permissionRoles,
      (role) => role.permissionRole === UserPermissionRole.PrimaryOwner,
    )
      ? UserPermissionRole.PrimaryOwner
      : !!_.find(portalUser.permissions.permissionRoles, (role) => role.permissionRole === UserPermissionRole.Owner)
      ? UserPermissionRole.Owner
      : !!_.find(
          portalUser.permissions.permissionRoles,
          (role) => role.permissionRole === UserPermissionRole.TeamManager,
        )
      ? UserPermissionRole.TeamManager
      : 999;

    return (
      <CenteredLayout backgroundColor="white" bordered={true}>
        <AddNewMemberModal onClose={this.closeModal} isOpen={this.state.isModalOpen} history={this.props.history} />
        <TeamManagementModals
          isOpen={this.state.isOpen}
          onClose={this._onCloseModal}
          selectedModal={this.state.selectedModal}
          data={this.state.modalData}
        />
        <div className="flex-row justify-between">
          <Title level={3} weight="regular" className="mv-none">
            <span className={'cursor-pointer text-color-blue-action'} onClick={this._goToLandingPage}>
              {'Account >'}
            </span>{' '}
            Team management
          </Title>
          {PermissionUtils.validatePermission('AddNewTeamMember', portalUser.permissions.permissionRoles) && (
            <PrimaryButton size="large" icon={'plus'} onClick={this.openModal}>
              Add team member
            </PrimaryButton>
          )}
        </div>

        {/*Comment the active user block for now as the rules doesn't align with real billing rules*/}
        {/*<div className={'flex-row justify-center'}>*/}
        {/*  <div className="mv-large width-3/4 bg-warning-orange flex-row align-middle ph-medium pv-small">*/}
        {/*    <div>*/}
        {/*      <Icon type={'team'} className="text-size-x7-large" />*/}
        {/*    </div>*/}
        {/*    <div className={'mr-medium'}>*/}
        {/*      <Icon type={'thunderbolt'} theme="filled" className="text-color-orange" style={{ marginLeft: '-8px' }} />*/}
        {/*    </div>*/}
        {/*    <div>*/}
        {/*      <Text weight={'bold'} size={'x-large'}>*/}
        {/*        {activeTeamMemberCount} active user{activeTeamMemberCount !== 1 && 's'} this month*/}
        {/*      </Text>*/}
        {/*      <br />*/}
        {/*      <Text>*/}
        {/*        Active users count towards monthly subscription costs.*/}
        {/*        /!*Visit the{' '}*!/*/}
        {/*        /!*<HyperlinkButton>Help Center</HyperlinkButton> for more information.*!/*/}
        {/*      </Text>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className="mb-x-large" style={{ position: 'sticky', top: '0px', zIndex: 10 }}>
          <div className="bg-white">
            <Tabs defaultActiveKey={'WORKERS'} animated={true} onChange={this._changeTab}>
              <TabPane tab="Team members" key="TEAM" />
              <TabPane tab="Invites" key="INVITES" />
            </Tabs>

            {this.state.selectedTab === 'TEAM' ? (
              <>
                <div className="flex-row pb-medium bg-white">
                  <Search
                    size="large"
                    key={'team'}
                    placeholder="Search by name"
                    loading={this.state.isLoading}
                    defaultValue={this.props.accountTeamFilter && this.props.accountTeamFilter.search}
                    style={{ width: '400px' }}
                    allowClear={true}
                    className={'mr-large'}
                    onChange={this._onEnterSearchText}
                  />
                  <Popover2
                    content={this._getTeamFilterPopoverContent()}
                    popoverClassName={'mb-medium'}
                    position={'bottom'}
                    interactionKind="click"
                  >
                    <div className={'bordered p-small cursor-pointer'} style={{ width: '200px' }}>
                      <Icon type={'filter'} className={'mr-small'} /> Filters
                    </div>
                  </Popover2>
                </div>
                <GridHeader bordered containerClassName="border-width-medium border-secondary">
                  <Col span={8} className=" bg-white">
                    <FieldLabel text="Person" />
                  </Col>
                  <Col span={3} className=" bg-white">
                    <FieldLabel text="status" />
                  </Col>
                  <Col span={3} className=" bg-white">
                    <FieldLabel text="business app" />
                  </Col>
                  <Col span={4} className=" bg-white">
                    <FieldLabel text="workspace" />
                  </Col>
                  <Col span={4} className=" bg-white">
                    <FieldLabel text="Last access" />
                  </Col>
                  <Col span={2} className=" bg-white">
                    <FieldLabel text="" />
                  </Col>
                </GridHeader>
                {this.state.isLoading ? (
                  <div className="">
                    <div className="pv-large">
                      <ProgressBar />
                    </div>
                    <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
                    <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
                    <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
                  </div>
                ) : !_.isEmpty(accountTeamList) ? (
                  <InfiniteScrollLoading
                    hasMore={accountTeamList.length >= this.state.page * this.state.pageSize}
                    loadingElementId={'content-container'}
                    loadMore={this._fetchMoreWorkers}
                    loaderColSpan={7}
                    loadingOffSet={60}
                  >
                    {_.map(accountTeamList, (worker, index) => {
                      const lastAccess = moment().diff(moment(worker.lastVisited), 'days');
                      const fontColor = worker.status !== TeamStatus.ENABLED ? 'tertiary' : 'black';
                      return (
                        <GridRow
                          key={index}
                          containerClassName={`${worker.status !== TeamStatus.ENABLED && 'bg-quaternary'}`}
                        >
                          <Col span={8}>
                            <div className="flex-row align-center">
                              <div className="mr-medium flex-row align-center">
                                <Avatar
                                  size="large"
                                  shape={'square'}
                                  icon="user"
                                  style={{ opacity: worker.status !== TeamStatus.ENABLED ? 0.5 : 1 }}
                                  src={worker.attachmentUrl}
                                />
                                {worker.status === TeamStatus.REMOVED && (
                                  <div
                                    style={{
                                      position: 'absolute',
                                      zIndex: 10,
                                      marginLeft: '18px',
                                      width: '4px',
                                      backgroundColor: '#FAFAFA',
                                      transform: 'rotate(45deg)',
                                      height: '60px',
                                    }}
                                  />
                                )}
                              </div>
                              <div>
                                <Text
                                  weight={'bold'}
                                  color={worker.status !== TeamStatus.REMOVED ? fontColor : 'red-dark'}
                                  style={{ opacity: worker.status === TeamStatus.REMOVED ? 0.6 : 1 }}
                                >
                                  {worker.firstName + ' ' + worker.lastName}
                                </Text>
                                <br />
                                <Text
                                  color={worker.status !== TeamStatus.REMOVED ? fontColor : 'red-dark'}
                                  style={{ opacity: worker.status === TeamStatus.REMOVED ? 0.6 : 1 }}
                                  size={'regular'}
                                >
                                  {worker.locality ? worker.locality : 'Locality not set'} ·{' '}
                                  {worker.email ? worker.email : 'Email not set'}
                                </Text>
                              </div>
                            </div>
                          </Col>
                          <Col span={3}>
                            <Text
                              color={
                                worker.status !== TeamStatus.ENABLED
                                  ? worker.status !== TeamStatus.BLOCKED
                                    ? worker.status !== TeamStatus.DRAFT
                                      ? 'red-dark'
                                      : 'tertiary'
                                    : 'black'
                                  : 'green'
                              }
                            >
                              {worker.status.toUpperCase()}
                            </Text>
                            {worker.removedOn && worker.status === TeamStatus.ENABLED && (
                              <Tooltip
                                title={
                                  <Paragraph>
                                    This team member is scheduled to be removed on{' '}
                                    <Text weight="bold">{moment(worker.removedOn).format('DD/MM/YYYY')}</Text>
                                  </Paragraph>
                                }
                                overlayClassName="white-tooltip"
                              >
                                <Icon
                                  type={'exclamation-circle'}
                                  className={'ml-small text-color-warning-orange'}
                                  theme={'filled'}
                                />
                              </Tooltip>
                            )}
                          </Col>
                          <Col span={3} className={`text-color-${fontColor}`}>
                            {worker.status !== TeamStatus.REMOVED ? (
                              <>
                                {worker.hasWorkerAppAccess ? (
                                  <Text>
                                    <Text color="blue-action" weight={'dark'}>
                                      &#8226;
                                    </Text>{' '}
                                    Yes
                                  </Text>
                                ) : (
                                  <Text color={'secondary'}>No</Text>
                                )}
                              </>
                            ) : (
                              <Text color={'secondary'}>-</Text>
                            )}
                          </Col>
                          <Col span={4} className={`text-color-${fontColor}`}>
                            {worker.status !== TeamStatus.REMOVED ? (
                              <>
                                {worker.hasPortalAccess ? (
                                  <Text>
                                    <Text color="blue-action" weight={'dark'}>
                                      &#8226;
                                    </Text>{' '}
                                    Yes {worker.ownerRole === 1 && '(Primary owner)'}
                                    {worker.ownerRole === 2 && '(Owner)'}
                                    {worker.ownerRole === 5 && '(Team manager)'}
                                  </Text>
                                ) : (
                                  <Text color={'secondary'}>No</Text>
                                )}
                              </>
                            ) : (
                              <Text color={'secondary'}>-</Text>
                            )}
                          </Col>
                          <Col span={4} className={`text-color-${fontColor}`}>
                            {worker.isBillable ? (
                              <Icon
                                type={'check-circle'}
                                className={'mr-small text-color-warning-orange'}
                                theme={'filled'}
                              />
                            ) : (
                              <Icon type={'close-circle'} className={'mr-small text-color-tertiary'} />
                            )}
                            {moment(worker.lastVisited).unix() === 0 || worker.lastVisited === null ? (
                              <>Never</>
                            ) : (
                              <>{lastAccess > 0 ? lastAccess + ` day${lastAccess !== 1 ? 's' : ''} ago` : 'Today'}</>
                            )}
                          </Col>
                          <Col span={2}>
                            <Popover2
                              content={this._getActionMenu(worker, portalUserOwnerRole)}
                              popoverClassName={'mb-medium'}
                              position={'bottom'}
                              interactionKind="click"
                            >
                              <GhostButton
                                icon={'ellipsis'}
                                className={worker.status !== TeamStatus.ENABLED && 'bg-quaternary'}
                              />
                            </Popover2>
                          </Col>
                        </GridRow>
                      );
                    })}
                  </InfiniteScrollLoading>
                ) : (
                  <div style={{ borderBottom: '0px solid' }}>
                    <ListEmptyState />
                  </div>
                )}
                {this.state.isLoadingInfiniteScrolling && (
                  <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                )}
              </>
            ) : (
              <>
                <div className="flex-row pb-medium bg-white">
                  <Search
                    size="large"
                    key={'invite'}
                    placeholder="Search by name"
                    defaultValue={this.props.invitesTeamFilter && this.props.invitesTeamFilter.search}
                    loading={this.state.isLoading}
                    style={{ width: '400px' }}
                    allowClear={true}
                    className={'mr-large'}
                    onChange={this._onEnterSearchText}
                  />
                </div>
                <GridHeader bordered containerClassName="border-width-medium border-secondary">
                  <Col span={7} className=" bg-white">
                    <FieldLabel text="Sent to" />
                  </Col>
                  <Col span={4} className=" bg-white">
                    <FieldLabel text="Sent on" />
                  </Col>
                  <Col span={11} className=" bg-white">
                    <FieldLabel text="Invited by" />
                  </Col>
                  <Col span={2} className=" bg-white">
                    <FieldLabel text="" />
                  </Col>
                </GridHeader>
                {this.state.isLoading ? (
                  <div className="">
                    <div className="pv-large">
                      <ProgressBar />
                    </div>
                    <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
                    <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
                    <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
                  </div>
                ) : (
                  <>
                    {_.isEmpty(invitesTeamList.pendingList) && _.isEmpty(invitesTeamList.revokedList) && (
                      <ListEmptyInvitesState />
                    )}
                    {!_.isEmpty(invitesTeamList.pendingList) && (
                      <>
                        {' '}
                        <div className="mv-large">
                          <Text>Pending invites</Text>
                        </div>
                        {_.map(invitesTeamList.pendingList, (worker, index) => {
                          return (
                            <GridRow key={index}>
                              <Col span={7}>
                                <div className="flex-row align-center">
                                  <Avatar
                                    size="large"
                                    className={'mr-medium'}
                                    shape={'square'}
                                    icon="user"
                                    src={worker.attachmentUrl}
                                  />
                                  <div>
                                    <Text weight={'bold'}>{worker.firstName + ' ' + worker.lastName}</Text>
                                    <br />
                                    <Text size={'regular'}>{worker.email ? worker.email : 'Email not set'}</Text>
                                  </div>
                                </div>
                              </Col>
                              <Col span={4}>{moment(worker.createdOn).format('DD MMM YYYY')}</Col>
                              <Col span={11}>
                                <div className="flex-row align-center">
                                  <Avatar
                                    size="default"
                                    className={'mr-medium'}
                                    shape={'square'}
                                    icon="user"
                                    src={worker.invitedByAttachmentUrl}
                                  />
                                  <Text>{worker.invitedByFirstName + ' ' + worker.invitedByLastName}</Text>
                                </div>
                              </Col>
                              <Col span={2}>
                                <Popover2
                                  content={this._getInviteMenu(worker.invitationStatus, worker)}
                                  popoverClassName={'mb-medium'}
                                  position={'bottom'}
                                  interactionKind="click"
                                >
                                  <GhostButton icon={'ellipsis'} />
                                </Popover2>
                              </Col>
                            </GridRow>
                          );
                        })}
                      </>
                    )}
                    {!_.isEmpty(invitesTeamList.revokedList) && (
                      <>
                        {' '}
                        <div className="mv-large">
                          <Text>Revoked invites</Text>
                        </div>
                        {_.map(invitesTeamList.revokedList, (worker, index) => {
                          return (
                            <GridRow key={index}>
                              <Col span={7}>
                                <div className="flex-row align-center">
                                  <Avatar
                                    size="large"
                                    className={'mr-medium'}
                                    shape={'square'}
                                    icon="user"
                                    src={worker.attachmentUrl}
                                  />
                                  <div>
                                    <Text weight={'bold'}>{worker.firstName + ' ' + worker.lastName}</Text>
                                    <br />
                                    <Text size={'regular'}>{worker.email ? worker.email : 'Email not set'}</Text>
                                  </div>
                                </div>
                              </Col>
                              <Col span={4} />
                              <Col span={11} />
                              <Col span={2}>
                                <Popover2
                                  content={this._getInviteMenu('REVOKED', worker)}
                                  popoverClassName={'mb-medium'}
                                  position={'bottom'}
                                  interactionKind="click"
                                >
                                  <GhostButton icon={'ellipsis'} />
                                </Popover2>
                              </Col>
                            </GridRow>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </CenteredLayout>
    );
  }
}

const mapState = (state: IRootState) => ({
  accountTeamList: state.teamStore.accountTeamList,
  accountTeamFilter: state.teamStore.accountTeamFilter,
  invitesTeamList: state.teamStore.invitesTeamList,
  invitesTeamFilter: state.teamStore.invitesTeamFilter,
  portalUser: state.authStore.portalUser,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchAccountTeamList: dispatch.teamStore.doFetchAccountTeamList,
  setAccountTeamFilter: dispatch.teamStore.setAccountTeamFilter,
  doFetchInvitations: dispatch.teamStore.doFetchInvitations,
  setInvitesTeamFilter: dispatch.teamStore.setInvitesTeamFilter,
  doFetchActiveTeamMemberCount: dispatch.accountStore.doFetchActiveTeamMemberCount,
});

export default connect(
  mapState,
  mapDispatch,
)(TeamManagementView);
