/* eslint-disable */
import React, { Component } from 'react';

import { Button, Col, Divider, Dropdown, Icon, Menu, Modal, Row, Tag, Tooltip } from 'antd';

import { Colors, EditableText } from '@blueprintjs/core';

import styled from 'styled-components';

import ColumnedLayout, { ColumnPanel } from 'layouts/ColumnedLayout';

import { Paragraph, Text, Title } from 'common-components/typography';

import themeVariables, { backgroundColor } from 'theme/theme-variables';

import coverImage1 from 'assets/sample/cover-images/cover1.jpg';
import coverImage2 from 'assets/sample/cover-images/cover2.jpg';
import coverImage3 from 'assets/sample/cover-images/cover3.jpg';
import coverImage4 from 'assets/sample/cover-images/cover4.jpg';

import { UploadFileStatus } from 'antd/lib/upload/interface';

class ServiceLandingView extends Component<any, any> {
  _handleMenuClick = () => {};
  render() {
    return (
      <ColumnedLayout backgroundColor={'white'}>
        {/* NOTE Service Listings Column Panel */}
        <ColumnPanel span={10} backgroundColor={backgroundColor} padding={'16px 24px'}>
          <ServiceListingSection />
        </ColumnPanel>

        {/* NOTE Service Details Column Panel */}
        <ColumnPanel span={14} backgroundColor={'white'} padding={'16px 24px'}>
          <ServiceDetailsSection />
        </ColumnPanel>
      </ColumnedLayout>
    );
  }
}

// NOTE Service Listing Section Start
const ServiceItemRow: any = styled.div`
  background-color: white;
  border: 1px ${themeVariables.lightGreyVariant2} solid;
  padding: 16px 24px;
  border-radius: 4px;
  margin-bottom: 16px;

  &:hover {
    background-color: ${themeVariables.lightGreyVariant2};
  }
`;

const ServiceItem = () => (
  <ServiceItemRow>
    <div>
      <Title level={3}>Youth Jobs! Now</Title>
    </div>
    <div>
      <Paragraph type={'secondary'} ellipsis={true}>
        Youth Jobs Now is a dedicated service specially designed to cater to your special needs! This service runs on a
        daily basis, betwen 9am and 5pm, on Every Monday till Friday, and on special occasions, certain Saturday noons
        as well. Interested parties are most welcome to drop by and observe our ongoing classes.
      </Paragraph>
    </div>

    <div>
      <Row type={'flex'} justify={'space-between'}>
        <Col span={24 / 3} style={{ textAlign: 'left' }}>
          <Tag>
            <b>3 </b>Workers assigned
          </Tag>
        </Col>
        <Col span={24 / 3} style={{ textAlign: 'center' }}>
          <Tag color={'green'}>
            <b>10 </b> upcoming booking(s)
          </Tag>
        </Col>
        <Col span={24 / 3} style={{ textAlign: 'right' }}>
          <Tag color={'blue'}>Published</Tag>
        </Col>
      </Row>
    </div>
  </ServiceItemRow>
);

class ServiceListingSection extends Component<any, any> {
  render() {
    return (
      <React.Fragment>
        {/**/}
        <Row style={{ marginBottom: '16px' }}>
          <Col span={12}>
            <div style={{ marginTop: '2px' }}>
              <Title level={2} style={{ marginBottom: '0px', color: Colors.DARK_GRAY3 }}>
                All Services
              </Title>
            </div>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button type={'default'} style={{ marginRight: '8px' }} icon={'filter'}>
              Filter
            </Button>
            <Button type={'primary'} icon={'plus'}>
              New Service
            </Button>
          </Col>
        </Row>

        <Divider />

        <div>
          {/* NOTE Service Listings */}
          <div>
            <ServiceItem />
            <ServiceItem />
            <ServiceItem />
            <ServiceItem />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
// NOTE Service Listings Section End

// NOTE Service Details Section Start

const _leftSpan = 6;
const _rightSpan = 14;

class ServiceDetailsSection extends Component {
  state = {
    previewVisible: false,
    previewImage: coverImage1,
    fileList: [
      {
        uid: '-1',
        name: 'xxx.png',
        status: 'success' as UploadFileStatus,
        url: coverImage1,
        size: 90000,
        type: 'image'
      }
    ]
  };

  _getMenu() {
    return (
      <Menu>
        <div style={{ padding: '8px' }}>
          <Text strong type={'secondary'}>
            Service Actions
          </Text>
        </div>
        <Menu.Item onClick={this._handleMenuClick}>
          <Icon type={'check'} />
          Publish Service
        </Menu.Item>
        <Menu.Item onClick={this._handleMenuClick}>
          <Icon type={'cross'} />
          Unpublish Service
        </Menu.Item>
        <Menu.Item onClick={this._handleMenuClick}>
          <Icon type={'delete'} />
          Archive this service
        </Menu.Item>
      </Menu>
    );
  }

  _handleMenuClick = () => {
    // handle menu click here
  };

  render = () => {
    const menu = this._getMenu();
    return (
      <div>
        <Row type={'flex'} justify={'space-between'} align={'bottom'}>
          <Col span={12}>
            {/*<Title level={2} style={{ marginBottom: '0px', color: themeVariables.textColorSecondary }}>*/}
            {/*  Service Details*/}
            {/*</Title>*/}
            <Button.Group>{/*<Button icon={'edit'} type={'primary'}> Edit Service</Button>*/}</Button.Group>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Button.Group>
              {/*<Button type={'primary'}>*/}
              {/*  <Icon type={'check'} />*/}
              {/*  Confirm Booking*/}
              {/*</Button>*/}
              {/*<Button type={'danger'}>*/}
              {/*  <Icon type={'cross'} />*/}
              {/*  Cancel Booking*/}
              {/*</Button>*/}
              <Button icon={'save'} type={'primary'}>
                Save
              </Button>

              <Dropdown overlay={menu}>
                <Button>
                  Actions <Icon type="down" />
                </Button>
              </Dropdown>
            </Button.Group>
          </Col>
        </Row>

        <div style={{ marginTop: '32px' }}>
          <Modal
            visible={this.state.previewVisible}
            footer={null}
            onCancel={() => this.setState({ previewVisible: false })}
            centered
          >
            <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
          </Modal>

          <Row type={'flex'} justify={'center'}>
            {/* Left Column */}
            <Col span={_leftSpan} style={{ textAlign: 'right', paddingRight: '24px' }}>
              {/*<Upload*/}
              {/*  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"*/}
              {/*  listType="picture-card"*/}
              {/*  fileList={this.state.fileList}*/}
              {/*  onPreview={() => this.setState({ previewVisible: true })}*/}
              {/*  onChange={() => alert('change')}*/}
              {/*>*/}
              {/*  {null}*/}
              {/*</Upload>*/}
              <img
                src={coverImage1}
                style={{
                  width: '154px',
                  height: '154px',
                  objectFit: 'cover',
                  padding: '10px',
                  border: `1px ${Colors.LIGHT_GRAY3} solid`,
                  borderRadius: '4px'
                }}
                onClick={() => this.setState({ previewVisible: true })}
              />
            </Col>

            {/* Right Column */}
            <Col span={_rightSpan}>
              <Title level={2}>Youth Jobs Now</Title>

              <EditableText value={'Youth Jobs Now'} />

              <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                Youth Jobs Now is a dedicated service specially designed to cater to your special needs! This service
                runs on a daily basis, between 9am and 5pm, on Every Monday till Friday, and on special occasions,
                certain Saturday noons as well. Interested parties are most welcome to drop by and observe our ongoing
                classes.
                <br />
                <br />
                Take note that this may take place apart from our designated centers; it's highly recommended that you
                give us a call to check on the time availabilities for the upcoming sessions.
              </Paragraph>
            </Col>
          </Row>

          <Row type={'flex'} justify={'center'} style={{ marginTop: '16px' }}>
            <Col span={_leftSpan} style={{ textAlign: 'right', paddingRight: '24px' }}>
              <Text type={'secondary'}>Attached Media</Text>
            </Col>
            <Col span={_rightSpan}>
              <img
                src={coverImage2}
                style={{
                  width: '64px',
                  height: '64px',
                  objectFit: 'cover',
                  padding: '5px',
                  border: `1px ${Colors.LIGHT_GRAY3} solid`,
                  marginRight: '8px',
                  borderRadius: '4px'
                }}
                onClick={() => this.setState({ previewVisible: true })}
              />
              <img
                src={coverImage3}
                style={{
                  width: '64px',
                  height: '64px',
                  objectFit: 'cover',
                  padding: '5px',
                  border: `1px ${Colors.LIGHT_GRAY3} solid`,
                  marginRight: '8px',
                  borderRadius: '4px'
                }}
                onClick={() => this.setState({ previewVisible: true })}
              />
              <img
                src={coverImage4}
                style={{
                  width: '64px',
                  height: '64px',
                  objectFit: 'cover',
                  padding: '5px',
                  border: `1px ${Colors.LIGHT_GRAY3} solid`,
                  marginRight: '8px',
                  borderRadius: '4px'
                }}
                onClick={() => this.setState({ previewVisible: true })}
              />
            </Col>
          </Row>

          {/**/}

          <Row type={'flex'} justify={'center'} style={{ marginTop: '16px' }}>
            <Col span={_leftSpan} style={{ textAlign: 'right', paddingRight: '24px' }}>
              <Text type={'secondary'}>
                <Tooltip title={'Indicates whether this service has been published or is in Draft mode.'}>
                  Status
                </Tooltip>
              </Text>
            </Col>
            <Col span={_rightSpan}>
              <Tag color={'blue'}>Published</Tag>
            </Col>
          </Row>

          <Row type={'flex'} justify={'center'} style={{ marginTop: '16px' }}>
            <Col span={_leftSpan} style={{ textAlign: 'right', paddingRight: '24px' }}>
              <Text type={'secondary'}>
                <Tooltip title={'How much your service will cost.'}>Cost</Tooltip>
              </Text>
            </Col>
            <Col span={_rightSpan}>$99 per hour</Col>
          </Row>

          <Divider orientation={'left'}>NDIS</Divider>

          <Row type={'flex'} justify={'center'} style={{ marginTop: '16px' }}>
            <Col span={_leftSpan} style={{ textAlign: 'right', paddingRight: '24px' }}>
              <Text type={'secondary'}>Is this service NDIS Eligible?</Text>
            </Col>
            <Col span={_rightSpan}>Yes</Col>
          </Row>

          <Row type={'flex'} justify={'center'} style={{ marginTop: '16px' }}>
            <Col span={_leftSpan} style={{ textAlign: 'right', paddingRight: '24px' }}>
              <Text type={'secondary'}>NDIS Categories</Text>
            </Col>
            <Col span={_rightSpan}>
              <Tag color={'purple'}>Daily Assistance</Tag>
              <Tag color={'orange'}>Nursing</Tag>
              <Tag color={'green'}>Homecare</Tag>
            </Col>
          </Row>

          <Row type={'flex'} justify={'center'} style={{ marginTop: '16px' }}>
            <Col span={_leftSpan} style={{ textAlign: 'right', paddingRight: '24px' }}>
              <Text type={'secondary'}>Service Type</Text>
            </Col>
            <Col span={_rightSpan}>Individual basis</Col>
          </Row>

          <Row type={'flex'} justify={'center'} style={{ marginTop: '16px' }}>
            <Col span={_leftSpan} style={{ textAlign: 'right', paddingRight: '24px' }}>
              <Text type={'secondary'}>Is this a fixed location?</Text>
            </Col>
            <Col span={_rightSpan}>No</Col>
          </Row>

          <Row type={'flex'} justify={'center'} style={{ marginTop: '16px' }}>
            <Col span={_leftSpan} style={{ textAlign: 'right', paddingRight: '24px' }}>
              <Text type={'secondary'}>Can the customer assign a worker directly?</Text>
            </Col>
            <Col span={_rightSpan}>Yes</Col>
          </Row>

          <Divider orientation={'left'}>Costing Information</Divider>

          <Row type={'flex'} justify={'center'} style={{ marginTop: '16px' }}>
            <Col span={_leftSpan} style={{ textAlign: 'right', paddingRight: '24px' }}>
              <Text type={'secondary'}>Cost</Text>
            </Col>
            <Col span={_rightSpan}>$99 per hour</Col>
          </Row>
        </div>
      </div>
    );
  };
}

// NOTE Service Details Section End

export default ServiceLandingView;
