import { Text } from 'common-components/typography';
import { Avatar, Col, Row } from 'antd';
import React, { PureComponent } from 'react';
import moment from 'moment-timezone';
import { TimezoneIndicator } from 'common-components/timezone';
import { PrimaryButton } from 'common-components/buttons';
import _ from 'lodash';
import ActionModal from 'common-components/modal/ActionModal';
import { IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

interface IBulkApproveWarningModalProps {
  isOpen: boolean;
  missingPaymentMethodBookings: any;
  onCloseModal: () => void;
  hasApprovedBookings: boolean;
  portalUser: typeof state.authStore.portalUser;
}

class BulkApproveWarningModal extends PureComponent<IBulkApproveWarningModalProps> {
  render() {
    const { isOpen, missingPaymentMethodBookings, onCloseModal, hasApprovedBookings, portalUser } = this.props;

    const timezone = portalUser.timezone;

    return (
      <ActionModal
        title="Edit Line Item"
        isOpen={isOpen}
        onClose={onCloseModal}
        width="x-large"
        verticalAlignment="highest"
      >
        {missingPaymentMethodBookings && (
          <>
            <div className="anim-slide-left">
              <Text>
                The following bookings cannot be approved as line items in the booking are missing a payment method.
                <br />
                {!hasApprovedBookings ? (
                  <b>No bookings have been processed</b>
                ) : (
                  <>
                    All other bookings not in this list have been <b>successfully approved</b>
                  </>
                )}
                .
              </Text>
              <Row className="mt-large">
                <Col span={8}>
                  <Text weight={'bold'} size="x-large">
                    Booking{missingPaymentMethodBookings ? (missingPaymentMethodBookings.length !== 1 ? 's' : '') : 's'}{' '}
                    with the error ({missingPaymentMethodBookings ? missingPaymentMethodBookings.length : 0})
                  </Text>
                </Col>
                <Col span={16} className="text-align-right">
                  <TimezoneIndicator timezone={timezone} />
                </Col>
              </Row>
              <Row className="text-weight-bold bordered-bottom border-standard-gray p-small mt-large">
                <Col span={5}>Date</Col>
                <Col span={8}>Service</Col>
                <Col span={11}>Customer</Col>
              </Row>
              <div
                style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '35vh' }}
                className="bordered-bottom border-standard-gray"
              >
                {(!missingPaymentMethodBookings || missingPaymentMethodBookings.length === 0) && (
                  <Row className="pv-medium">
                    <Text>No booking found.</Text>
                  </Row>
                )}
                {_.map(missingPaymentMethodBookings, (conflict) => (
                  <Row type="flex" align="middle" className="p-small border-secondary evenodd">
                    <Col span={5}>
                      {moment
                        .tz(conflict.startDateTime, timezone)
                        .startOf('day')
                        .isSame(moment.tz(conflict.endDateTime, timezone).startOf('day')) ? (
                        <text>
                          <b>{moment.tz(conflict.startDateTime, timezone).format('DD/MM/YYYY')}</b>
                          <br />
                          <Text>
                            {moment.tz(conflict.startDateTime, timezone).format('hh:mm a')} -{' '}
                            {moment.tz(conflict.endDateTime, timezone).format('hh:mm a')}
                          </Text>
                        </text>
                      ) : (
                        <text>
                          <b>{moment.tz(conflict.startDateTime, timezone).format('DD/MM/YYYY')}</b>{' '}
                          {moment.tz(conflict.startDateTime, timezone).format('hh:mm a')}
                          <br />
                          <b>{moment.tz(conflict.endDateTime, timezone).format('DD/MM/YYYY')}</b>{' '}
                          {moment.tz(conflict.endDateTime, timezone).format('hh:mm a')}
                        </text>
                      )}
                    </Col>
                    <Col span={8} title={conflict.serviceName} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {conflict.serviceName}
                    </Col>
                    <Col
                      span={11}
                      title={conflict.shiftSlotStatus}
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      <Text>
                        <Avatar className="mr-medium" size={'small'} src={conflict.attachmentUrl} />{' '}
                        {conflict.firstName} {conflict.lastName}
                      </Text>
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
            <div className={'mt-x-large'}>
              <Row type={'flex'} justify={'end'}>
                <PrimaryButton size="large" onClick={onCloseModal}>
                  Ok
                </PrimaryButton>
              </Row>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser
});

export default connect(
  mapState,
  null
)(BulkApproveWarningModal);
