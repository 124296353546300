import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Avatar, Col, notification, Row, TimePicker } from 'antd';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { FieldLabel, SubTitle, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import DatePicker from 'react-datepicker';
import { timeZone } from 'interfaces/timezone-type';
import moment, { Moment } from 'moment-timezone';
import CommonUtils from 'utilities/common-utils';
import TimeInput from 'common-components/time-input/TimeInput';

interface IBulkStartShiftModalProps {
  isOpen: boolean;
  onClose: () => void;
  onTaskSuccess: () => void;
  toBeStartedShiftSlots: IShiftSlot[];
  serviceId: string;
  serviceDateTimeId: string;
  timezone: timeZone;
  doBatchStartShifts: typeof dispatch.groupServiceStore.doBatchStartShifts;
}

interface IBulkStartShiftModalState {
  isLoading: boolean;
  step: number;
  selectedShiftSlots: IShiftSlot[];
  editingShiftSlot: IShiftSlot;
  editingSlotCheckInTime: Moment;
}

class BulkStartShiftModal extends Component<IBulkStartShiftModalProps, IBulkStartShiftModalState> {
  state = {
    isLoading: false,
    step: 1,
    selectedShiftSlots: [],
    editingShiftSlot: null,
    editingSlotCheckInTime: null
  };

  private _reset = () => {
    this.setState({
      isLoading: false,
      step: 1,
      selectedShiftSlots: [],
      editingShiftSlot: null,
      editingSlotCheckInTime: null
    });
  };

  private _onBulkStartShifts = async () => {
    const { serviceId, serviceDateTimeId, doBatchStartShifts, onTaskSuccess } = this.props;
    const { selectedShiftSlots } = this.state;
    const shiftSlots = _.map(selectedShiftSlots, (shiftSlot) => ({
      supportWorkerAttendanceId: shiftSlot.supportWorkerAttendanceId,
      startDateTime: shiftSlot.workerCheckedInDateTime
    }));
    this.setState({ isLoading: true });
    try {
      await doBatchStartShifts({
        serviceDateTimeId,
        serviceId,
        shiftSlots
      });
      this.setState({ step: 3 });
      onTaskSuccess();
    } catch (e) {
      console.error(e);
      if (e.meta.message === 'Session status error') {
        notification.error({ message: 'Please check the session status before starting a shift.' });
      } else {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
    this.setState({ isLoading: false });
  };

  private _onEditCheckInDateTime = (shiftSlot: IShiftSlot) => {
    const editingSlotCheckInTime = moment(
      moment.tz(shiftSlot.workerCheckedInDateTime, this.props.timezone).format('YYYY-MM-DD HH:mm')
    );
    this.setState({ step: 2, editingShiftSlot: shiftSlot, editingSlotCheckInTime });
  };

  private _onChangeCheckInDateTime = (date) => {
    this.setState({ editingSlotCheckInTime: moment(CommonUtils.formatCeilingDateTime(date)) });
  };

  private _onBack = () => {
    this.setState({ step: 1, editingShiftSlot: null });
  };

  private _onSaveNewCheckInDateTime = () => {
    const { timezone } = this.props;
    const { editingShiftSlot, editingSlotCheckInTime } = this.state;
    const checkInDateTime = moment.tz(editingSlotCheckInTime.format('YYYY-MM-DD HH:mm'), timezone);
    editingShiftSlot.workerCheckedInDateTime = checkInDateTime;
    this.setState({ step: 1 });
  };

  private _formatFullName = (shiftSlot: IShiftSlot) => {
    if (!shiftSlot) return null;

    return `${shiftSlot.firstName || ''} ${shiftSlot.lastName || ''}`;
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.isOpen && !prevProps.isOpen) {
      this._reset();
    }
    if (this.props.toBeStartedShiftSlots !== prevProps.toBeStartedShiftSlots) {
      const newShiftSlots = _.cloneDeep(this.props.toBeStartedShiftSlots);
      const newShiftSlotsWithCheckIn = _.map(newShiftSlots, (slot) => {
        if (!slot.workerCheckedInDateTime) {
          slot.workerCheckedInDateTime = slot.shiftStartDateTime;
        }

        return slot;
      });

      this.setState({ selectedShiftSlots: newShiftSlotsWithCheckIn });
    }
  };

  render() {
    const { timezone } = this.props;
    const { step, editingShiftSlot, editingSlotCheckInTime, selectedShiftSlots } = this.state;
    const modalTitle = step === 1 ? 'Start shifts' : step === 2 ? 'Edit shift start time' : 'Shifts started';
    const itemCount = selectedShiftSlots.length;

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title={modalTitle}
        onClose={this.props.onClose}
        canCloseOutside={false}
        width={'large'}
      >
        {step === 1 && (
          <div>
            <Text>You are starting the shift on behalf of the following team members.</Text>
            <div className="bordered border-secondary mt-medium rounded-big shadow-container">
              <Row style={{ borderBottom: '1px solid #ebebeb' }}>
                <Col span={9} className="p-medium">
                  <FieldLabel text="Team member" />
                </Col>
                <Col span={9} className="p-medium">
                  <FieldLabel text="Start time" />
                </Col>
                <Col span={6} className="p-medium">
                  <FieldLabel text="" />
                </Col>
              </Row>
              {_.map(
                _.sortBy(selectedShiftSlots, [
                  (customer) => customer.firstName.toLowerCase(),
                  (customer) => customer.lastName.toLowerCase()
                ]),
                (shiftSlot) => (
                  <Row>
                    <Col span={9} className="p-medium">
                      <div className="flex-row align-center">
                        <Avatar icon={'user'} shape={'square'} src={shiftSlot.attachmentUrl} />
                        <Text className="ml-small">{this._formatFullName(shiftSlot)}</Text>
                      </div>
                    </Col>
                    <Col span={9} className="p-medium">
                      {moment.tz(shiftSlot.workerCheckedInDateTime, timezone).format("D MMMM 'YY. h:mmA")}
                    </Col>
                    <Col span={6} className="p-medium">
                      <HyperlinkButton onClick={() => this._onEditCheckInDateTime(shiftSlot)}>
                        Edit start time
                      </HyperlinkButton>
                    </Col>
                  </Row>
                )
              )}
            </div>
            <ActionModalFooter align="right" className="mt-large">
              <SecondaryButton size="large" className="mr-medium" onClick={this.props.onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                size="large"
                onClick={this._onBulkStartShifts}
                className="rounded"
                loading={this.state.isLoading}
              >
                Start shifts
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {step === 2 && editingShiftSlot && (
          <div>
            <Text>Edit the start time for this team member's shift</Text>
            <SubTitle containerClassName="mt-x-large">Team member</SubTitle>
            <div className="flex-row align-center">
              <Avatar icon={'user'} shape={'circle'} src={editingShiftSlot.attachmentUrl || ''} />
              <Text className="ml-small">{this._formatFullName(editingShiftSlot)}</Text>
            </div>
            <SubTitle containerClassName="mt-x-large">scheduled shift time</SubTitle>
            <Text>
              {moment.tz(editingShiftSlot.shiftStartDateTime, timezone).format('D/M/YYYY, h:mmA') +
                ' - ' +
                moment.tz(editingShiftSlot.shiftEndDateTime, timezone).format('h:mmA')}
            </Text>
            <div className="bordered border-secondary rounded-big p-large mt-x-large">
              <SubTitle>Start date/time</SubTitle>
              <div className="flex-row align-center">
                <DatePicker
                  className="gh-datepicker rounded width-full"
                  calendarClassName="gh-datepicker-calendar"
                  onChange={this._onChangeCheckInDateTime}
                  dateFormat={'dd/MM/yyyy'}
                  isClearable={false}
                  selected={moment(editingSlotCheckInTime).toDate()}
                />

                <TimeInput
                  size="large"
                  className="ml-medium"
                  value={moment(editingSlotCheckInTime)}
                  onChange={this._onChangeCheckInDateTime}
                />
              </div>
            </div>
            <ActionModalFooter align="right" className="mt-large">
              <SecondaryButton size="large" className="mr-medium" onClick={this._onBack}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                size="large"
                className="rounded"
                loading={this.state.isLoading}
                onClick={this._onSaveNewCheckInDateTime}
              >
                Save
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {step === 3 && (
          <div>
            <Text>
              You have started the shifts for{' '}
              <b>
                {itemCount} team member{itemCount === 1 ? '' : 's'}
              </b>{' '}
              on their behalf.
            </Text>
            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this.props.onClose}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doBatchStartShifts: dispatch.groupServiceStore.doBatchStartShifts
});

export default connect(null, mapDispatch)(BulkStartShiftModal);
