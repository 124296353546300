import React, { Component } from 'react';
import { Avatar, Col, Form, notification, Row, Select, Spin } from 'antd';
import { connect } from 'react-redux';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Title from 'antd/lib/typography/Title';
import { FormComponentProps } from 'antd/es/form';
import _ from 'lodash';
import { CustomerType } from 'utilities/enum-utils';
import { CustomerAdditionalInfos } from 'common-components/tooltips';
import PermissionUtils from 'utilities/permission-utils';

interface ISelectCustomerStepPanelProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  setCustomerToSchedule?: typeof dispatch.servicesStore.setCustomerToSchedule;
  doGetCustomersLite?: typeof dispatch.customersStore.doGetCustomersLite;
  setCustomersLite?: typeof dispatch.customersStore.setCustomersLite;
  customerToSchedule: typeof state.servicesStore.customerToSchedule;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  customersLite: typeof state.customersStore.customersLite;
  portalUser: typeof state.authStore.portalUser;
  history?: any;
}

interface ISelectCustomerStepPanelState {
  isLoading: boolean;
  isSearching: boolean;
  selectedCustomer: any;
}

class SelectCustomerStepPanel extends Component<ISelectCustomerStepPanelProps, ISelectCustomerStepPanelState> {
  state = {
    isLoading: false,
    isSearching: false,
    selectedCustomer: this.props.customerToSchedule ? this.props.customerToSchedule.selectedCustomer : null
  };

  private _searchText = async (txt) => {
    const { doGetCustomersLite } = this.props;
    await doGetCustomersLite({
      search: txt,
      userType: CustomerType.CUSTOMERS,
      sortByRelevance: true
    });
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    if (e.length >= 1) {
      this.setState({ isSearching: true });
      this._debounceSearch(e);
    } else if (e.length === 0) {
      this.props.setCustomersLite({});
    }
  };

  private _changeSelectedCustomer = (customerId) => {
    this.setState({ selectedCustomer: _.find(this.props.customersLite, (customer) => customer.userId === customerId) });
  };

  private _goToNext = async () => {
    const { setCustomerToSchedule, customerToSchedule, form } = this.props;

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      try {
        await setCustomerToSchedule({
          ...customerToSchedule,
          selectedCustomer: this.state.selectedCustomer
        });
        this.props.onNextStep();
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong! Please try again.' });
      }
    }
  };

  render() {
    const { customersLite, form } = this.props;
    const { isLoading, isSearching, selectedCustomer } = this.state;
    const { getFieldDecorator } = form;

    const hasAccessToCustomerDetails = PermissionUtils.validatePermission(
      'ViewCustomerProfile',
      this.props.portalUser.permissions.permissionRoles
    );

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>We'll guide you adding a customer to sessions</Title>
              <Paragraph>The next few screens will cover everything that's needed to create sessions.</Paragraph>
              <Paragraph>First select the customer you want to add to sessions.</Paragraph>
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
              <Title level={4}>Select customer</Title>
              <Paragraph>
                Let's start by selecting the <b>customer</b> you'd like to create session(s) for.
              </Paragraph>
              <SubTitle>Customer</SubTitle>
              <Form.Item>
                {getFieldDecorator('customer', {
                  rules: [{ required: true, message: 'Please select a customer' }],
                  initialValue: selectedCustomer && selectedCustomer.userId ? selectedCustomer.userId : undefined
                })(
                  <Select
                    showSearch={true}
                    placeholder="Search for..."
                    notFoundContent={isSearching ? <Spin size="small" /> : null}
                    onSearch={this._onEnterSearchText}
                    onChange={this._changeSelectedCustomer}
                    filterOption={false}
                    size={'large'}
                    style={{ width: '400px' }}
                  >
                    {_.map(customersLite, (customer) => (
                      <Select.Option key={customer.userId} value={customer.userId}>
                        <Avatar
                          icon={'user'}
                          size={'small'}
                          className={'mr-small'}
                          shape={'circle'}
                          src={customer.attachmentUrl}
                        />{' '}
                        {customer.firstName} {customer.lastName}
                        {hasAccessToCustomerDetails && !customer.isAlreadyInSession && customer.locality && (
                          <Text color={'secondary'}> ({customer.locality})</Text>
                        )}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              {selectedCustomer &&
                hasAccessToCustomerDetails &&
                (selectedCustomer.ndisNumber || selectedCustomer.dateOfBirth) && (
                  <CustomerAdditionalInfos
                    customer={selectedCustomer}
                    getOnlyContent={true}
                    noMarginTop={true}
                    hideInformation={['locality']}
                  />
                )}
            </div>
            <div className="pv-medium width-full bg-tertiary" style={{ position: 'sticky', bottom: 0 }}>
              <Row gutter={0} type="flex" align="middle" justify={'space-between'} className="bg-transparent">
                <div> </div>
                <div className="text-align-right pv-medium">
                  <PrimaryButton size="large" loading={isLoading} onClick={this._goToNext}>
                    Next
                  </PrimaryButton>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setCustomerToSchedule: dispatch.servicesStore.setCustomerToSchedule,
  setCustomersLite: dispatch.customersStore.setCustomersLite,
  doGetCustomersLite: dispatch.customersStore.doGetCustomersLite
});

const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService,
  customerToSchedule: state.servicesStore.customerToSchedule,
  customersLite: state.customersStore.customersLite,
  portalUser: state.authStore.portalUser
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<ISelectCustomerStepPanelProps>()(SelectCustomerStepPanel));
