import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Text } from 'common-components/typography';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';
import { IPermissions, IServiceDepartment } from 'interfaces/account-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import EditPermissionsPanel from 'views/account-management/edit-permissions/EditPermissionsPanel';
import { Avatar, Col, notification } from 'antd';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';

interface EditPermissionsModalProps {
  isOpen: boolean;
  onClose: any;
  worker?: any;
  editPermissionsData: { permissions: IPermissions };
  setEditPermissionsData: any;
  doFetchUserPermissions: typeof dispatch.accountStore.doFetchUserPermissions;
  serviceDepartmentList: typeof state.servicesStore.serviceDepartmentListLite;
  doFetchServiceDepartmentsLite: typeof dispatch.servicesStore.doFetchServiceDepartmentsLite;
  doUpdateTeamMemberPermissions: typeof dispatch.teamStore.doUpdateTeamMemberPermissions;
}

interface EditPermissionsModalState {
  isLoading: boolean;
  isSaving: boolean;
  confirmModalOpen: boolean;
}

class EditPermissionsModal extends Component<EditPermissionsModalProps, EditPermissionsModalState> {
  state = { isLoading: false, isSaving: false, confirmModalOpen: false };

  openConfirmModal = () => {
    this.setState({ confirmModalOpen: true });
  };

  private _confirmClose = () => {
    this.setState({ confirmModalOpen: false });
    this.props.onClose();
  };

  private _savePermissions = async () => {
    const { editPermissionsData } = this.props;
    try {
      this.setState({ isSaving: true });
      await this.props.doUpdateTeamMemberPermissions({
        supportWorkerId: this.props.worker.supportWorkerId,
        permissionRoles: editPermissionsData.permissions.permissionRoles,
        hasWorkerAppAccess: editPermissionsData.permissions.hasWorkerAppAccess,
        hasPortalAccess: editPermissionsData.permissions.hasPortalAccess
      });
      this.props.onClose();
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isSaving: false });
  };

  componentDidMount = async () => {
    await this.props.doFetchServiceDepartmentsLite({
      isArchived: false,
      page: 1,
      pageSize: 50,
      pageTimestamp: new Date()
    });
  };

  componentDidUpdate = async (
    prevProps: Readonly<EditPermissionsModalProps>,
    prevState: Readonly<EditPermissionsModalState>,
    snapshot?: any
  ) => {
    if (prevProps.worker !== this.props.worker && this.props.worker && this.props.isOpen) {
      this.setState({ isLoading: true });
      await this.props.doFetchUserPermissions({ supportWorkerId: this.props.worker.supportWorkerId });
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isOpen, editPermissionsData, setEditPermissionsData, serviceDepartmentList, worker } = this.props;

    return (
      <>
        <ActionModal
          isOpen={this.state.confirmModalOpen}
          width="small"
          title="Cancel editing permissions"
          canCloseOutside={true}
          onClose={() => this.setState({ confirmModalOpen: false })}
          verticalAlignment="high"
        >
          <div>
            <Text>If you cancel now, you will lose all data you have entered.</Text> <br /> <br />
            <Text>
              Are you sure you want to <b>cancel</b>?
            </Text>
          </div>
          <ActionModalFooter>
            <GhostButton size="large" className="mr-medium" onClick={() => this.setState({ confirmModalOpen: false })}>
              Go Back
            </GhostButton>
            <PrimaryButton size="large" onClick={this._confirmClose}>
              Confirm
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <FullScreenScrollableModal
          isOpen={isOpen}
          onClose={this.openConfirmModal}
          bgColor={'quaternary'}
          headerTitle={
            worker ? (
              <div className={'flex-row align-center'}>
                <Avatar shape={'square'} src={worker.attachmentUrl} className={'mr-medium'} />
                <Text>{worker.firstName + ' ' + worker.lastName}</Text>
              </div>
            ) : (
              'Edit permissions'
            )
          }
          width={'full'}
          canCloseEsc={false}
        >
          <EditPermissionsPanel
            isLoading={this.state.isLoading}
            isSaving={this.state.isSaving}
            serviceDepartments={serviceDepartmentList}
            editPermissionsData={editPermissionsData}
            setEditPermissionsData={setEditPermissionsData}
            savePermissions={this._savePermissions}
            onClose={this.openConfirmModal}
          />
        </FullScreenScrollableModal>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  editPermissionsData: state.accountStore.editPermissionsData,
  serviceDepartmentList: state.servicesStore.serviceDepartmentListLite
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setEditPermissionsData: dispatch.accountStore.setEditPermissionsData,
  doFetchUserPermissions: dispatch.accountStore.doFetchUserPermissions,
  doFetchServiceDepartmentsLite: dispatch.servicesStore.doFetchServiceDepartmentsLite,
  doUpdateTeamMemberPermissions: dispatch.teamStore.doUpdateTeamMemberPermissions
});

export default connect(
  mapState,
  mapDispatch
)(EditPermissionsModal);
