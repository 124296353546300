import React, { Component } from 'react';
import { Col, Row, Divider } from 'antd';
import { connect } from 'react-redux';
import { Paragraph, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Title from 'antd/lib/typography/Title';
import _ from 'lodash';
import FoundDuplicateRow from '../FoundDuplicatesRow';

interface IAddCustomerStepDuplicateCheckProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  goToCustomer: (userId?: string) => void;
  fetchAddCustomerFuzzyMatching?: typeof dispatch.customersStore.fetchAddCustomerFuzzyMatching;
  addCustomerDetailWizard: typeof state.customersStore.addCustomerDetailWizard;
  history?: any;
}

interface IAddCustomerStepDuplicateCheckState {
  isLoading: boolean;
  searchCustomerDetail: any;
  customerFuzzyMatchingList: any;
}

class AddCustomerStepDuplicateCheck extends Component<
  IAddCustomerStepDuplicateCheckProps,
  IAddCustomerStepDuplicateCheckState
> {
  state = {
    isLoading: false,
    customerFuzzyMatchingList: null,
    searchCustomerDetail: this.props.addCustomerDetailWizard
  };

  componentDidMount = async () => {
    const { addCustomerDetailWizard } = this.props;
    this.setState({ isLoading: true });
    const customerFuzzyMatchingList = await this.props.fetchAddCustomerFuzzyMatching({
      firstName: addCustomerDetailWizard.firstName,
      lastName: addCustomerDetailWizard.lastName
    });

    this.setState({
      customerFuzzyMatchingList: _.orderBy(customerFuzzyMatchingList, ['highCorrelation'], ['desc']),
      isLoading: false
    });
  };

  render() {
    const { onNextStep, onPreviousStep, goToCustomer } = this.props;
    const { customerFuzzyMatchingList } = this.state;

    return (
      <div className="anim-slide-left  pt-x3-large">
        <Row className="ph-x3-large">
          <Col span={6} style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white p-large">
              <Title level={4} className="mb-x-small text-size-large">
                Potential duplicates ({customerFuzzyMatchingList ? customerFuzzyMatchingList.length : 0})
              </Title>
              <Text size="small" color="gray">
                The following profiles match the data entered on the previous screen.
              </Text>
              <Divider className="mt-12 mb-none" />
              {customerFuzzyMatchingList && customerFuzzyMatchingList.length > 0 && !this.state.isLoading && (
                <div style={{ minHeight: '30vh', maxHeight: '40vh', overflowY: 'auto' }}>
                  <div className="width-full">
                    <>
                      {_.map(customerFuzzyMatchingList, (matchingUser) => {
                        return <FoundDuplicateRow matchingUser={matchingUser} />;
                      })}
                    </>
                  </div>
                </div>
              )}
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
              <Row className="mb-large">
                <Col>
                  <Title level={4} className="mb-large">
                    Potential duplicates found
                  </Title>
                  <Paragraph>There are some people in your business that match the data you’ve entered.</Paragraph>
                  <Paragraph>
                    To keep your business running smoothly it’s important to avoid creating duplicate customers.
                  </Paragraph>
                  <Paragraph>
                    Please double check that any of the profiles displayed don’t match the details of the customer you
                    are trying to add. If not, press the continue button to keep adding this customer
                  </Paragraph>
                </Col>
              </Row>
            </div>
            <div className="pv-large width-full" style={{ position: 'sticky', bottom: 0 }}>
              <Row gutter={0} type="flex" align="middle" className="bg-transparent">
                <Col className="bg-transparent" span={24}>
                  <div className="text-align-right pv-medium">
                    <SecondaryButton size="large" className="mr-medium" onClick={onPreviousStep}>
                      Back
                    </SecondaryButton>
                    <PrimaryButton size="large" onClick={onNextStep}>
                      Continue
                    </PrimaryButton>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  fetchAddCustomerFuzzyMatching: dispatch.customersStore.fetchAddCustomerFuzzyMatching
});

const mapState = (state: IRootState) => ({
  customers: state.customersStore.customers,
  addCustomerDetailWizard: state.customersStore.addCustomerDetailWizard
});

export default connect(
  mapState,
  mapDispatch
)(AddCustomerStepDuplicateCheck);
