// Environment : DEVELOPMENT

// GH Dev
const firebaseConfig = {
  apiKey: 'AIzaSyCyrAovQB7iimDvbgB52TLhwKQuf_QnhQo',
  authDomain: 'gh-dev-718d6.firebaseapp.com',
  databaseURL: 'https://gh-dev-718d6.firebaseio.com',
  projectId: 'gh-dev-718d6',
  storageBucket: 'gh-dev-718d6.appspot.com',
  messagingSenderId: '1090046020222'
};

export default firebaseConfig;
