import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { Avatar } from 'antd/es';
import { Row } from 'antd';
import CustomerTag from 'common-components/tags/CustomerTag';
import { CustomerStatusType } from 'utilities/enum-utils';

interface ICustomerConnectionCardProps {
  connectionStatus: string;
  ndisNumber: string;
  firstName: string;
  lastName: string;
  attachmentUrl: string;
  hasGuardian: boolean;
  className?: string;
  customerStatus?: CustomerStatusType;
}

class CustomerConnectionCard extends Component<ICustomerConnectionCardProps, any> {
  state = {};

  render() {
    const {
      connectionStatus,
      ndisNumber,
      firstName,
      lastName,
      attachmentUrl,
      customerStatus,
      hasGuardian,
      className = ''
    } = this.props;

    return (
      <div
        className={`p-medium bordered inline-block ${className}`}
        style={{ minWidth: '400px', boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.25)', borderRadius: '10px' }}
      >
        <div className="flex-row align-center">
          <div>
            <Avatar size={80} src={attachmentUrl} icon={'user'} />
          </div>
          <div className="ml-medium mr-medium pr-none">
            <Row className="mb-small">
              <Text size={'x-large'} weight={'bold'} className="mb-none">
                {firstName + ' ' + lastName}
              </Text>
              <br />
              <Text size="regular">Ndis Number: {ndisNumber ? ndisNumber : 'Not set'}</Text>
            </Row>
            <Row>
              <div className="flex">
                {customerStatus === CustomerStatusType.ENQUIRY && (
                  <CustomerTag type={'IS_ENQUIRY'} size={'small'} className="mr-medium" />
                )}
                {hasGuardian && <CustomerTag type={'HAS_GUARDIANS'} size={'small'} className="mr-medium" />}
                <CustomerTag type={connectionStatus} size={'small'} />
              </div>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerConnectionCard;
