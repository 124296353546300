import React, { Component } from 'react';
import { Paragraph, Title, Text } from 'common-components/typography';
import { Button, Card, Icon, Popover, Row } from 'antd';
import { CustomViewsModalType, CustomViewsViewCardType } from 'utilities/enum-utils';
import CustomViewsModal from '../../modals/CustomViewsModal';
import { ICustomView, IFilterValue } from 'interfaces/custom-views-interface';
import { RematchDispatcherAsync } from '@rematch/core';

interface ICustomAllViewsViewCardProps {
  pageName: string;
  type: CustomViewsViewCardType;
  viewList: ICustomView[];
  pageViews: ICustomView[];
  pageFilter: IFilterValue[];
  pageListingActiveTab: ICustomView;
  defaultViews: ICustomView[];
  defaultFilterValue: IFilterValue[];
  doFetchPageViews: (action) => ReturnType<RematchDispatcherAsync>;
  doAddView: (action) => ReturnType<RematchDispatcherAsync>;
  doUpdateViewTab: (action) => ReturnType<RematchDispatcherAsync>;
  doDuplicateView: (action) => ReturnType<RematchDispatcherAsync>;
  doDeleteView: (action) => ReturnType<RematchDispatcherAsync>;
  setPageListingActiveTab: (action) => void;
  setPageFilter: (action) => void;
  onClickView: () => void;
}

interface ICustomAllViewsViewCardState {
  isLoading: boolean;
  title: string;
  description: string;
  showCreateBookingModal: boolean;
  showViewModal: boolean;
  showViewPopover: boolean;
  showTabIconPopover: string;
  viewModalType: CustomViewsModalType;
}

class CustomAllViewsViewCard extends Component<ICustomAllViewsViewCardProps, ICustomAllViewsViewCardState> {
  state = {
    isLoading: false,
    title: '',
    description: '',
    showCreateBookingModal: false,
    viewModalType: CustomViewsModalType.CREATE,
    showViewModal: false,
    showViewPopover: false,
    showTabIconPopover: ''
  };

  private _getPopoverContent = () => {
    const { type, onClickView } = this.props;
    return (
      <div style={{ margin: '-4px -16px' }}>
        <Row className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest" onClick={onClickView}>
          View
        </Row>
        {type === CustomViewsViewCardType.CREATED_BY_ME && (
          <Row
            className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
            onClick={() => this._openViewModal(CustomViewsModalType.MANAGE)}
          >
            Manage view details
          </Row>
        )}
        <Row
          className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
          onClick={() => this._openViewModal(CustomViewsModalType.DUPLICATE)}
        >
          Duplicate view
        </Row>
        {type === CustomViewsViewCardType.CREATED_BY_ME && (
          <>
            <div className="bordered-top mv-small"></div>
            <Row
              className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest text-color-red"
              onClick={() => this._openViewModal(CustomViewsModalType.DELETE)}
            >
              Delete view
            </Row>
          </>
        )}
      </div>
    );
  };

  private _openViewModal = (type: CustomViewsModalType) => {
    this.setState({ viewModalType: type, showViewModal: true, showViewPopover: false });
  };

  private _closeViewModal = () => {
    this.setState({ viewModalType: CustomViewsModalType.CREATE, showViewModal: false });
  };

  private _setActiveView = (view: ICustomView) => {
    this.props.setPageListingActiveTab(view);
  };

  componentDidMount = () => {
    const { pageName, type } = this.props;

    if (type === CustomViewsViewCardType.DEFAULT) {
      this.setState({
        title: 'Default',
        description: `These are standard views in the ${pageName} tab provided by GoodHuman.`
      });
    } else if (type === CustomViewsViewCardType.CREATED_BY_ME) {
      this.setState({
        title: 'Created by me',
        description: 'Only you can see this view. You can manage view to share it with anyone in the workspace.'
      });
    } else if (type === CustomViewsViewCardType.CREATED_BY_OTHERS) {
      this.setState({
        title: 'Created by others',
        description:
          'These views are created by other users and shared with you. You can duplicate and add it to your view.'
      });
    }
  };

  render() {
    const { viewList } = this.props;
    const { isLoading, title, description, viewModalType, showViewModal } = this.state;

    return (
      <>
        <CustomViewsModal
          isOpen={showViewModal}
          onCloseViewModal={this._closeViewModal}
          onUpdateViewModal={this._openViewModal}
          {...this.props}
          type={viewModalType}
        />
        <Card bordered={true} loading={isLoading} style={{ borderRadius: 10 }}>
          <div style={{ minHeight: '70vh' }}>
            <Row className={'mb-x-small'}>
              <Title level={4} className="mv-none" size="x2-large" lineHeight={150}>
                {title} ({viewList && viewList.length})
              </Title>
            </Row>
            <Row>
              <Paragraph>{description}</Paragraph>
            </Row>
            <div>
              {viewList &&
                viewList.map((view, index) => {
                  return (
                    <div
                      className={`flex-row justify-between align-center pv-medium ph-small ${
                        index !== viewList.length - 1 ? 'bordered-bottom' : ''
                      } `}
                      style={{ columnGap: '24px' }}
                      key={view.name}
                    >
                      <Text weight="regular" size="regular" className="pv-none" ellipsis={true}>
                        {view.name}
                      </Text>
                      <Popover placement="bottomRight" content={this._getPopoverContent()} trigger="focus">
                        <Button className="ph-small border-standard-gray" onClick={() => this._setActiveView(view)}>
                          <Icon className="text-color-blue-light text-weight-bolder" type="ellipsis" theme="outlined" />
                        </Button>
                      </Popover>
                    </div>
                  );
                })}
            </div>
          </div>
        </Card>
      </>
    );
  }
}

export default CustomAllViewsViewCard;
