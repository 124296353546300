import React, { Component } from 'react';
import CenteredLayout from 'layouts/CenteredLayout';
import { Paragraph, Text, Title } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import * as H from 'history';
import { Col, Icon, Row } from 'antd';
import { australianFlag } from 'assets/Icons/country-flags';
import EditPlanManagementModal from 'views/account-management/components/FundingAndPaymentModals/EditPlanManagementModal';

interface IFeatureConfigurationViewProps {
  doFetchCompanyFundingAndPayment: typeof dispatch.companyStore.doFetchCompanyFundingAndPayment;
  doFetchCompanyLite: typeof dispatch.companyStore.doFetchCompanyLite;
  companyFundingAndPayment: typeof state.companyStore.companyFundingAndPayment;
  portalUser: typeof state.authStore.portalUser;
  history: H.History;
}

interface IFeatureConfigurationViewState {
  isLoading: boolean;
  isEditPlanManagementConfigOpen: boolean;
}

class FeatureConfigurationView extends Component<IFeatureConfigurationViewProps, IFeatureConfigurationViewState> {
  state = {
    isLoading: true,
    isEditPlanManagementConfigOpen: false
  };

  private _goToLandingPage = () => {
    this.props.history.push('/account/landing');
  };

  private _goToServiceAgreementTemplate = () => {
    this.props.history.push('/account/features/service-agreement-templates/ndis');
  };

  private _goToTimeBasedAlertConfigre = () => {
    this.props.history.push('/account/features/service-time-based-alerts-configure');
  };

  private _openEditPlanManagementConfig = () => {
    this.setState({ isEditPlanManagementConfigOpen: true });
  };

  private _closeEditPlanManagementConfig = async () => {
    await this.props.doFetchCompanyLite([]);
    this.setState({ isEditPlanManagementConfigOpen: false });
  };

  componentDidMount = async () => {
    await this.props.doFetchCompanyFundingAndPayment({});
    this.setState({ isLoading: false });
  };

  render() {
    return (
      <CenteredLayout backgroundColor="white" bordered={true} className={'ph-small'}>
        {this.state.isEditPlanManagementConfigOpen && (
          <EditPlanManagementModal
            isOpen={this.state.isEditPlanManagementConfigOpen}
            onClose={this._closeEditPlanManagementConfig}
          />
        )}
        <div className={'pb-x4-large'}>
          <div className="mt-x-large mb-medium">
            <Title level={3} weight="regular" className="mv-none">
              <span className={'cursor-pointer text-color-blue-action'} onClick={this._goToLandingPage}>
                {'Account >'}
              </span>{' '}
              Feature Configuration
            </Title>
          </div>
          <Paragraph>
            Configure features for your Workspace. Any changes to features below will take effect for your entire
            organisation’s Workspace settings and behaviour.
          </Paragraph>
          <div className="mt-x-large bordered-bottom border-standard-gray width-2/3">
            <Row
              className={'bordered-top border-standard-gray pv-medium cursor-pointer hover-bg-quaternary'}
              gutter={24}
              onClick={this._goToServiceAgreementTemplate}
            >
              <Col span={18}>
                <div className={'mb-small'}>
                  <Text weight={'bold'}>Terms & conditions templates</Text>
                </div>
                <Text color={'secondary'}>Create and save templates for use in service agreements.</Text>
              </Col>
              <Col span={6} className={'text-align-right'}>
                <Text>
                  <Text>Configure</Text>
                  <Icon type={'right'} className={'ml-medium'} />
                </Text>
              </Col>
            </Row>
            <Row
              className={'bordered-top border-standard-gray pv-medium cursor-pointer hover-bg-quaternary'}
              gutter={24}
              onClick={this._goToTimeBasedAlertConfigre}
            >
              <Col span={18}>
                <div className={'mb-small'}>
                  <Text weight={'bold'}>Time based alerts</Text>
                </div>
                <Text color={'secondary'}>Configure time based alerts in your system</Text>
              </Col>
              <Col span={6} className={'text-align-right'}>
                <Text>
                  <Text>Configure</Text>
                  <Icon type={'right'} className={'ml-medium'} />
                </Text>
              </Col>
            </Row>
            <Row
              className={'bordered-top border-standard-gray pv-medium cursor-pointer hover-bg-quaternary'}
              gutter={24}
              onClick={this._openEditPlanManagementConfig}
            >
              <Col span={18}>
                <div className={'mb-small'}>
                  <Text weight={'bold'}>Plan Management</Text>
                </div>
                <div className={'mb-small'}>
                  <img src={australianFlag} height={'8px'} alt={'Australian National Flag'} className={'mr-x-small'} />
                  <Text size={'regular'}>Australia</Text>
                </div>
                <Text color={'secondary'}>Manage funding as a Plan Manager for your selected customers.</Text>
              </Col>
              <Col span={6} className={'text-align-right'}>
                <Text>
                  <Text color="blue-action">
                    {this.props.companyFundingAndPayment &&
                    this.props.companyFundingAndPayment.planManagement.hasPlanManagement
                      ? 'ON'
                      : 'OFF'}
                  </Text>
                  <Icon type={'right'} className={'ml-medium'} />
                </Text>
              </Col>
            </Row>
          </div>
        </div>
      </CenteredLayout>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyFundingAndPayment: state.companyStore.companyFundingAndPayment,
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchCompanyFundingAndPayment: dispatch.companyStore.doFetchCompanyFundingAndPayment,
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite
});

export default connect(mapState, mapDispatch)(FeatureConfigurationView);
