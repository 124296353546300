import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from '../../../../common-components/modal/ActionModal';
import { TransportAttendanceType } from '../../../../utilities/enum-utils';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../stores/rematch/root-store';
import { FieldLabel, Paragraph, Text } from '../../../../common-components/typography';
import { GhostButton, PrimaryButton } from '../../../../common-components/buttons';
import { connect } from 'react-redux';
import { notification } from 'antd';

interface RevertTransportBookingToConfirmModalProps {
  isOpen: any;
  onClose: any;
  booking: any;
  attendanceType: TransportAttendanceType;
  doRevertTransportBookingToConfirm: typeof dispatch.groupBookingsStore.doRevertTransportBookingToConfirm;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
}

interface RevertTransportBookingToConfirmModalState {
  step: number;
  canManuallyClose: boolean;
  title: string;
}

class RevertTransportBookingToConfirmModal extends Component<
  RevertTransportBookingToConfirmModalProps,
  RevertTransportBookingToConfirmModalState
> {
  state = {
    step: 1,
    canManuallyClose: true,
    title: 'Revert to confirm'
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, canManuallyClose: true, title: 'Revert to confirm' }, onClose);
  };

  onSubmit = async () => {
    const { booking, selectedGroupBookingItem, doRevertTransportBookingToConfirm, attendanceType } = this.props;
    try {
      await doRevertTransportBookingToConfirm({
        bookingId: selectedGroupBookingItem.bookingId,
        transportBookingId: booking.attendanceId,
        attendanceType
      });

      this.setState({ step: 2, canManuallyClose: true, title: 'Booking reverted to confirmed' });
    } catch (e) {
      this.setState({ canManuallyClose: true });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  renderView = () => {
    const { step } = this.state;
    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <Paragraph>
            Doing this will return the booking status to <b>Confirmed.</b>
          </Paragraph>
          <Paragraph>
            Any start time recorded by assigned team member will be deleted along with any claims (Travel, additional
            expense etc...).
          </Paragraph>
          <Paragraph>All notes will be retained.</Paragraph>
          <Paragraph>
            Are you sure you want to revert this booking to <b>confirmed.</b>
          </Paragraph>

          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" onClick={this.onSubmit}>
              Revert to confirmed
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <Paragraph>
            You have successfully reverted the booking to <b>Confirmed.</b>
          </Paragraph>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Save
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  render() {
    let { isOpen, booking } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        {booking && <div>{this.renderView()}</div>}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRevertTransportBookingToConfirm: dispatch.groupBookingsStore.doRevertTransportBookingToConfirm
});

export default connect(
  mapState,
  mapDispatch
)(RevertTransportBookingToConfirmModal);
