/* eslint-disable */
import React, { Component } from 'react';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { ProgressBar } from '@blueprintjs/core';
import { Col, Row, Skeleton } from 'antd';
import { connect } from 'react-redux';
import CenteredLayout from 'layouts/CenteredLayout';

import { Text } from 'common-components/typography';
import { IServiceDetailsItem, IServiceTeamMember } from 'interfaces/service-interfaces';
import ServiceDetailsTopSection from './sections/ServiceDetailsTopSection';
import ServiceDetailsContentSection from './sections/ServiceDetailsContentSection';
import { RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import QueueAnim from 'rc-queue-anim';

interface IServiceDetailsUrlParams {
  serviceId: string;
}

interface IServiceDetailsViewProps extends RouteComponentProps<IServiceDetailsUrlParams> {
  doFetchSingleService: ({ serviceId: string }) => void;
  selectedService: IServiceDetailsItem;
  doUpdateIndividualServiceTime: typeof dispatch.servicesStore.doUpdateIndividualServiceTime;
  doUpdateIndividualServiceDetail: typeof dispatch.servicesStore.doUpdateIndividualServiceDetail;
  doRemoveWorkerFromRoster: typeof dispatch.servicesStore.doRemoveWorkerFromRoster;
  doAddKeyContactIntoService: typeof dispatch.servicesStore.doAddKeyContactIntoService;
  doRemoveContactFromService: typeof dispatch.servicesStore.doRemoveContactFromService;
  teamList: IServiceTeamMember[];
  doUpdateContactForService: typeof dispatch.servicesStore.doUpdateContactForService;
  doAddManagerIntoService: typeof dispatch.servicesStore.doAddManagerIntoService;
  doRemoveManagerFromService: typeof dispatch.servicesStore.doRemoveManagerFromService;
  doUpdateServicePricing: typeof dispatch.servicesStore.doUpdateServicePricing;
  setFilteredServices: typeof dispatch.servicesStore.setFilteredServices;
  setSelectedService: typeof dispatch.servicesStore.setSelectedService;
  filteredServices: typeof state.servicesStore.filteredServices;
  doFetchServiceDepartmentsLite: typeof dispatch.servicesStore.doFetchServiceDepartmentsLite;
}

interface IServiceDetailsViewState {
  isAwaitingFetch: boolean;
  isLoading: boolean;
}

class ServiceDetailsView extends Component<IServiceDetailsViewProps, IServiceDetailsViewState> {
  state = {
    isAwaitingFetch: false,
    isLoading: false
  };

  componentDidUpdate = async (
    prevProps: Readonly<IServiceDetailsViewProps>,
    prevState: Readonly<IServiceDetailsViewState>,
    snapshot?: any
  ) => {
    const { match, doFetchSingleService, doFetchServiceDepartmentsLite } = this.props;
    const { serviceId } = match.params;

    if (prevProps.match.params.serviceId !== serviceId) {
      this.setState({ isAwaitingFetch: true, isLoading: true });

      // fetch service
      await doFetchSingleService({ serviceId: serviceId });
      await doFetchServiceDepartmentsLite({});

      this.setState({ isAwaitingFetch: false, isLoading: false });
    }
  };

  componentDidMount = async () => {
    const { match, doFetchSingleService, doFetchServiceDepartmentsLite } = this.props;
    this.setState({
      isLoading: true
    });
    const { serviceId } = match.params;
    await doFetchSingleService({ serviceId: serviceId });
    await doFetchServiceDepartmentsLite({});
    this.setState({
      isLoading: false
    });
  };

  render() {
    // Maybe can move this into a common loading component later
    if (this.state.isAwaitingFetch) {
      return (
        <div className="view-container">
          <div className="pb-medium">
            <div className="pb-medium">
              <Text>Fetching service details...</Text>
            </div>
            <ProgressBar />
          </div>
          <Skeleton loading={this.state.isAwaitingFetch} />
        </div>
      );
    }

    const {
      selectedService,
      doUpdateIndividualServiceTime,
      doUpdateIndividualServiceDetail,
      doRemoveWorkerFromRoster,
      teamList,
      doAddKeyContactIntoService,
      doRemoveContactFromService,
      doUpdateContactForService,
      doAddManagerIntoService,
      doRemoveManagerFromService,
      doUpdateServicePricing,
      setFilteredServices,
      setSelectedService,
      filteredServices,
      history
    } = this.props;

    return (
      <CenteredLayout backgroundColor="white" bordered>
        {this.state.isLoading && (
          <>
            <div className="item-container">
              <div className="pb-medium">
                <Text>Fetching service data...</Text>
              </div>
              <ProgressBar />
            </div>
            <Skeleton loading={this.state.isLoading} active avatar title paragraph />
          </>
        )}
        {!this.state.isLoading && !_.isEmpty(selectedService) && (
          <Row>
            <Col sm={24} md={24}>
              <ServiceDetailsTopSection history={history} />

              <QueueAnim>
                <ServiceDetailsContentSection
                  doUpdateIndividualServiceTime={doUpdateIndividualServiceTime}
                  doUpdateIndividualServiceDetail={doUpdateIndividualServiceDetail}
                  doRemoveWorkerFromRoster={doRemoveWorkerFromRoster}
                  teamList={teamList}
                  doAddKeyContactIntoService={doAddKeyContactIntoService}
                  doRemoveContactFromService={doRemoveContactFromService}
                  doUpdateContactForService={doUpdateContactForService}
                  doAddManagerIntoService={doAddManagerIntoService}
                  doRemoveManagerFromService={doRemoveManagerFromService}
                  doUpdateServicePricing={doUpdateServicePricing}
                  setSelectedService={setSelectedService}
                  setFilteredServices={setFilteredServices}
                  filteredServices={filteredServices}
                />
              </QueueAnim>
            </Col>
          </Row>
        )}
      </CenteredLayout>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedService: state.servicesStore.selectedService,
  teamList: state.servicesStore.teamList,
  filteredServices: state.servicesStore.filteredServices
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchSingleService: dispatch.servicesStore.doFetchSingleService,
  doUpdateIndividualServiceTime: dispatch.servicesStore.doUpdateIndividualServiceTime,
  doUpdateIndividualServiceDetail: dispatch.servicesStore.doUpdateIndividualServiceDetail,
  doRemoveWorkerFromRoster: dispatch.servicesStore.doRemoveWorkerFromRoster,
  doAddKeyContactIntoService: dispatch.servicesStore.doAddKeyContactIntoService,
  doRemoveContactFromService: dispatch.servicesStore.doRemoveContactFromService,
  doUpdateContactForService: dispatch.servicesStore.doUpdateContactForService,
  doAddManagerIntoService: dispatch.servicesStore.doAddManagerIntoService,
  doRemoveManagerFromService: dispatch.servicesStore.doRemoveManagerFromService,
  doUpdateServicePricing: dispatch.servicesStore.doUpdateServicePricing,
  setFilteredServices: dispatch.servicesStore.setFilteredServices,
  setSelectedService: dispatch.servicesStore.setSelectedService,
  doFetchServiceDepartmentsLite: dispatch.servicesStore.doFetchServiceDepartmentsLite
});

export default connect(
  mapState,
  mapDispatch
)(ServiceDetailsView);
