import React, { Component } from 'react';
import { Avatar, Col } from 'antd';
import * as H from 'history';
import { Text } from 'common-components/typography';
import { IGroupServiceCustomerListItem } from 'interfaces/customer-interfaces';
import { GridRow } from 'common-components/grids';
import { GhostButton, HyperlinkButton } from 'common-components/buttons';
import { Popover } from '@blueprintjs/core';
import { IGroupServiceOverview } from 'interfaces/service-interfaces';
import { MessageOrigin } from 'interfaces/message-interfaces';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';

interface ICustomerListItemProps {
  history: H.History;
  serviceId: string;
  selectedGroupService: IGroupServiceOverview;
  customerItem: IGroupServiceCustomerListItem;
  onOpenCustomerScheduleModal: () => void;
}

export default class CustomerListItem extends Component<ICustomerListItemProps> {
  private _getPopoverContent = () => {
    return (
      <div>
        <ActionMenu className="pv-small">
          <ActionMenuItem
            className="hover-bg-secondary pv-small ph-medium"
            text={'View schedule'}
            onClick={this.props.onOpenCustomerScheduleModal}
          />
          <ActionMenuItem
            className="hover-bg-secondary pv-small ph-medium"
            text={'Message customer'}
            onClick={this._onMessageCustomer}
          />
        </ActionMenu>
      </div>
    );
  };

  private _viewCustomerProfile = () => {
    const newUrl = `/customer/details/${this.props.customerItem.customerUserId}`;
    this.props.history.push(newUrl);
  };

  private _onMessageCustomer = () => {
    const { history, customerItem, serviceId, selectedGroupService } = this.props;
    const userId = customerItem.customerUserId;
    const avatarUrl = customerItem.customerAvatarUrl;
    const serviceName = selectedGroupService.serviceName;
    const userName = `${customerItem.customerFirstName} ${customerItem.customerLastName}`;

    history.push({
      pathname: '/messaging',
      state: { origin: MessageOrigin.BookingDetailsCustomer, serviceId, userId, serviceName, userName, avatarUrl }
    });
  };

  render() {
    const { customerItem } = this.props;

    return (
      <GridRow containerClassName={'bordered rounded mt-medium'}>
        <Col span={7}>
          <div className="flex-row align-center">
            <Avatar className="mr-medium" size="large" icon="user" src={customerItem.customerAvatarUrl} />
            <HyperlinkButton
              onClick={this._viewCustomerProfile}
            >{`${customerItem.customerFirstName} ${customerItem.customerLastName}`}</HyperlinkButton>
          </div>
        </Col>
        <Col span={5}>
          <Text>{customerItem.upcomingBookingCount}</Text>
        </Col>
        <Col span={6}>
          <Text>{customerItem.completedBookingCount}</Text>
        </Col>
        <Col span={6} className="text-align-right">
          <Popover content={this._getPopoverContent()} position={'bottom-right'} interactionKind="click">
            <div
              className="bordered border-standard-gray rounded text-align-center"
              style={{ width: '28px', height: '28px' }}
            >
              <GhostButton icon={'ellipsis'} size={'small'} paddingSize={'x-small'} />
            </div>
          </Popover>
        </Col>
      </GridRow>
    );
  }
}
