import React from 'react';
import { IInstructionsDetails } from 'views/group-services/manage-booking-modal/interfaces/group-booking-interfaces';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Text } from 'common-components/typography';
import { Avatar, Input } from 'antd';
import moment from 'moment-timezone';
import _ from 'lodash';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../../stores/rematch/root-store';
import { connect } from 'react-redux';

interface IEditInstructionsModalProps {
  isOpen: boolean;
  mode: string;
  instructions: IInstructionsDetails;
  onClose: () => void;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
  doAddGroupBookingInstructions: typeof dispatch.groupBookingsStore.doAddGroupBookingInstructions;
  doUpdateGroupBookingInstructions: typeof dispatch.groupBookingsStore.doUpdateGroupBookingInstructions;
}

interface IEditInstructionsModalState {
  isLoading: boolean;
  instructionContent: string;
  isLessThanFiveCharError: boolean;
}

class EditInstructionsModal extends React.Component<IEditInstructionsModalProps, IEditInstructionsModalState> {
  state = {
    isLoading: false,
    instructionContent: '',
    isLessThanFiveCharError: false
  };

  onCancel = () => {
    const { onClose } = this.props;
    onClose();
  };

  onSave = async () => {
    const {
      onClose,
      mode,
      doAddGroupBookingInstructions,
      selectedGroupBookingItem,
      doUpdateGroupBookingInstructions,
      instructions
    } = this.props;
    const { instructionContent } = this.state;

    if (!instructionContent || instructionContent.length < 5) {
      this.setState({ isLessThanFiveCharError: true });
    } else {
      if (mode === 'add') {
        this.setState({ isLoading: true });
        await doAddGroupBookingInstructions({
          bookingId: selectedGroupBookingItem.bookingId,
          instruction: instructionContent
        });
        this.setState({ isLoading: false });
        onClose();
      } else if (mode === 'edit') {
        this.setState({ isLoading: true });
        if (instructionContent !== '') {
          await doUpdateGroupBookingInstructions({
            bookingId: selectedGroupBookingItem.bookingId,
            attendanceInstructionId: instructions.attendanceInstructionId,
            instruction: instructionContent
          });
        }
        this.setState({ isLoading: false });
        onClose();
      }
    }
  };

  onChangeInstruction = (event) => {
    this.setState({
      instructionContent: event.target.value,
      isLessThanFiveCharError: event.target.value && event.target.value.length < 5
    });
  };

  render() {
    const { isOpen, onClose, mode, instructions, selectedGroupBookingItem } = this.props;

    return (
      <ActionModal
        isOpen={isOpen}
        title={mode === 'edit' ? 'Edit instructions' : mode === 'add' ? 'Add instructions' : ''}
        onClose={onClose}
        width="x-large"
        verticalAlignment={'highest'}
        canCloseOutside={false}
      >
        <section className="mb-medium">
          <div className="mb-medium">
            <Text>Instructions for this booking for the following customer.</Text>
          </div>

          <div>
            <FieldLabel text="CUSTOMER" />
            <div className="flex-row mt-x2-small align-center line-height-100">
              <Avatar icon="user" src={selectedGroupBookingItem.attachmentUrl} className="mr-small" />
              <Text lineHeight={100}>
                {selectedGroupBookingItem.firstName} {selectedGroupBookingItem.lastName}
              </Text>
            </div>
          </div>
        </section>

        <section>
          <div className="mb-x2-small">
            <FieldLabel text={'INSTRUCTIONS'} />
          </div>
          <Input.TextArea
            rows={4}
            placeholder="Enter instructions for customer here..."
            onChange={this.onChangeInstruction}
            defaultValue={instructions ? instructions.instruction : ''}
          />
          {this.state.isLessThanFiveCharError && <Text color={'red-dark'}>Please enter more than 5 characters.</Text>}
        </section>

        {mode === 'edit' && instructions && (
          <section className="mt-large line-height-135 mb-medium">
            <div>
              <Text size={'regular'} color="secondary" lineHeight={135}>
                Created by{' '}
                <b>
                  {instructions.createdByFirstName} {instructions.createdByLastName}
                </b>{' '}
                on {moment.tz(instructions.createdOn, selectedGroupBookingItem.timezone).format('D/MM/YYYY h:mm A')}
              </Text>
            </div>
            {!_.isEmpty(instructions.updatedByLastName) && (
              <div>
                <Text size={'regular'} color="secondary" lineHeight={135}>
                  Edited by{' '}
                  <b>
                    {instructions.updatedByFirstName} {instructions.updatedByLastName}
                  </b>{' '}
                  on {moment.tz(instructions.updatedOn, selectedGroupBookingItem.timezone).format('D/MM/YYYY h:mm A')}
                </Text>
              </div>
            )}
          </section>
        )}

        <ActionModalFooter align={'right'}>
          <SecondaryButton onClick={this.onCancel} className="mr-medium" size="large" disabled={this.state.isLoading}>
            Cancel
          </SecondaryButton>

          <PrimaryButton onClick={this.onSave} size="large" loading={this.state.isLoading}>
            Save
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddGroupBookingInstructions: dispatch.groupBookingsStore.doAddGroupBookingInstructions,
  doUpdateGroupBookingInstructions: dispatch.groupBookingsStore.doUpdateGroupBookingInstructions
});

export default connect(
  mapState,
  mapDispatch
)(EditInstructionsModal);
