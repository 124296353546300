import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, Text } from 'common-components/typography';
import { Form, notification } from 'antd';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

interface ICancelRemovalDateModalProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  worker: any;
  doCancelRemovalDate: typeof dispatch.teamStore.doCancelRemovalDate;
}

interface ICancelRemovalDateModalState {
  isLoading: boolean;
}

class CancelRemovalDateModal extends Component<ICancelRemovalDateModalProps, ICancelRemovalDateModalState> {
  state = {
    isLoading: false
  };

  private _onCloseModal = () => {
    this.setState({ isLoading: false });
    this.props.onClose();
  };

  private _saveAction = async () => {
    const { onClose } = this.props;

    try {
      this.setState({ isLoading: true });
      const payload = {
        supportWorkerId: this.props.worker.supportWorkerId
      };
      await this.props.doCancelRemovalDate(payload);
      notification.success({
        message: 'Scheduled removal cancelled',
        description: `The team member's scheduled removal has been cancelled`
      });
      onClose();
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { isOpen, worker } = this.props;
    const { isLoading } = this.state;
    const name = worker ? worker.firstName + ' ' + worker.lastName : '';
    const removalDate = worker && worker.removedOn ? worker.removedOn : null;

    return (
      <ActionModal
        title="Cancel removal"
        isOpen={isOpen}
        onClose={this._onCloseModal}
        canCloseOutside={!isLoading}
        width="large"
      >
        <Paragraph>
          <Text weight="bold">{name}</Text> is scheduled to be removed on{' '}
          <Text weight="bold">{moment(removalDate).format('DD/MM/YYYY')}</Text>
        </Paragraph>
        <Paragraph className="mt-small">Are you sure you want to cancel this removal?</Paragraph>

        <ActionModalFooter>
          <SecondaryButton size="large" loading={isLoading} className="mr-medium" onClick={this._onCloseModal}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" loading={isLoading} onClick={this._saveAction}>
            Save
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCancelRemovalDate: dispatch.teamStore.doCancelRemovalDate
});

export default connect(
  null,
  mapDispatch
)(Form.create<ICancelRemovalDateModalProps>()(CancelRemovalDateModal));
