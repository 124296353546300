import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Checkbox, Icon, Tooltip } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { PaymentMethodTag } from 'common-components/tags';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootState, state } from 'src/stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { ServiceType } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import Utils from 'utilities/Utils';

interface IInvoiceItemRow {
  invoiceItem?: any;
  history?: any;
  openActionModal?: any;
  onClickReject?: any;
  setSelectedBillingLineItem?: typeof dispatch.billingsStore.setSelectedBillingLineItem;
  setSelectedRejectBillingItems?: typeof dispatch.billingsStore.setSelectedRejectBillingItems;
  portalUser?: typeof state.authStore.portalUser;
  selectedRejectBillingItems?: typeof state.billingsStore.selectedRejectBillingItems;
  onCheckInvoice?: (invoiceId) => void;
  isChecked?: boolean;
}

const claimType = {
  STANDARD_SERVICE: 'STD',
  CANCELLATION: 'CANC',
  TRAVEL: 'TRAN',
  REPORT_WRITING: 'REPW',
  NO_FACE_TO_FACE: 'NF2F',
  TELEHEALTH_SUPPORTS: 'THLT',
  IRREGULAR_SIL_SUPPORTS: 'IRSS',
};

const claimTypeOptions = [
  { value: claimType.STANDARD_SERVICE, label: 'Standard Service' },
  { value: claimType.CANCELLATION, label: 'Cancellation' },
  { value: claimType.TRAVEL, label: 'Travel' },
  { value: claimType.REPORT_WRITING, label: 'Report Writing' },
  { value: claimType.NO_FACE_TO_FACE, label: 'Non Face-to-face' },
  { value: claimType.TELEHEALTH_SUPPORTS, label: 'Telehealth supports' },
  { value: claimType.IRREGULAR_SIL_SUPPORTS, label: 'Irregular SIL supports' },
];

const unit = {
  HOURS: 'H',
  EACH: 'EA',
};

const unitOptions = [
  { value: unit.HOURS, label: 'hours' },
  { value: unit.EACH, label: 'each' }
];

class InvoiceItemRow extends Component<IInvoiceItemRow> {
  _checkbox = null;
  state = {
    customerId: null,
    showSubLines: false,
    openAction: false,
    invoiceId: null,
  };

  private _onClickBooking = (attendanceId) => {
    const { history } = this.props;
    history.push(`/bookings/details/${attendanceId}`);
  };

  private _onClickOpen = async () => {
    this.setState({ showSubLines: true });
  };

  private _onClickClose = async () => {
    this.setState({ showSubLines: false });
  };

  private _onClickBatch = (batchId) => {
    const { history } = this.props;
    history.push(`/batch/details/${batchId}`);
  };

  private _onClickCustomer = (userId) => {
    const { history } = this.props;
    history.push(`/customer/details/${userId}`);
  };

  private _onCheckInvoice = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { onCheckInvoice, invoiceItem } = this.props;
    onCheckInvoice(invoiceItem);
  };

  private _disableMarkAsPaid = (items) => {
    const rejectedItems = _.filter(items, (item) => item.isRejected);
    return rejectedItems.length === items.length;
  };

  componentDidMount = () => {
    const { invoiceItem } = this.props;

    if (!_.isEmpty(invoiceItem)) {
      const { invoiceId } = invoiceItem;
      this.setState({ invoiceId });
    }
  };

  render() {
    const { invoiceItem, portalUser, isChecked = false } = this.props;

    if (_.isEmpty(invoiceItem)) {
      return <></>;
    }

    const {
      exportedDate,
      invoiceTotal,
      firstName,
      lastName,
      userId,
      invoiceId,
      invoiceNumber,
      numberOfItem,
      batchNumber,
      batchId,
      paymentMethod,
      items,
    } = invoiceItem;

    const numberOfRejectedBooleans = _.countBy(invoiceItem.items, (item) => {
      return item.isRejected;
    });

    const displayBorder = this.state.showSubLines ? 'bordered-none' : '';

    const numberOfRejected = numberOfRejectedBooleans.true ? numberOfRejectedBooleans.true : 0;

    const isDisableMarkAsPaid = this._disableMarkAsPaid(items);

    return (
      <>
        <tr
          onClick={this.state.showSubLines ? this._onClickClose : this._onClickOpen}
          className={`anim-fade-in bg-white ${this.state.showSubLines && 'no-border'}`}
        >
          <td
            className={`${displayBorder} fluid-cell check-all `}
            onClick={(e) => {
              if (!isDisableMarkAsPaid) {
                this._onCheckInvoice(e);
              }
            }}
            onMouseEnter={() => this._checkbox.focus()}
            onMouseLeave={() => this._checkbox.blur()}
          >
            <Checkbox checked={isChecked} ref={(c) => (this._checkbox = c)} disabled={isDisableMarkAsPaid} />
          </td>
          <td className="fluid-cell">{moment(exportedDate).format('DD/MM/YYYY')}</td>
          <td className="ellipsis">
            <PaymentMethodTag paymentMethod={paymentMethod} className="mr-small" rounded={true} size="small" />
            <HyperlinkButton
              onClick={(e) => {
                e.stopPropagation();
                window.open(`/pdf?type=view-invoice&invoiceId=${invoiceId}`, '_blank');
              }}
              style={{ whiteSpace: 'nowrap' }}
            >
              {invoiceNumber}
            </HyperlinkButton>{' '}
            ({numberOfItem} item{numberOfItem > 1 && 's'}
            {numberOfRejected > 0 && (
              <Text color="red-dark" className="text-weight-bold">
                {' '}
                ({numberOfRejected} rejected)
              </Text>
            )}
            )
          </td>
          <td className="ellipsis">
            <div className="pr-small">
              <Text style={{ whiteSpace: 'nowrap' }} onClick={(e) => e.stopPropagation()}>
                {PermissionUtils.validatePermission('ViewCustomerProfile', portalUser.permissions.permissionRoles) ? (
                  <HyperlinkButton onClick={() => this._onClickCustomer(userId)} color="black">
                    {firstName} {lastName}
                  </HyperlinkButton>
                ) : (
                  firstName + ' ' + lastName
                )}
              </Text>
            </div>
          </td>
          <td className="ellipsis">
            <div className="pr-small">
              <HyperlinkButton onClick={() => this._onClickBatch(batchId)} style={{ whiteSpace: 'nowrap' }}>
                {batchNumber}
              </HyperlinkButton>
            </div>
          </td>
          <td className="fluid-cell">
            <div className="pr-small text-align-right">
              <Text style={{ whiteSpace: 'nowrap' }}>{CommonUtils.formatPrice(invoiceTotal)}</Text>
            </div>
          </td>
          <td style={{ width: '100px', textAlign: 'center' }} onClick={(e) => e.stopPropagation()}>
            <Popover2
              content={
                <Menu>
                  {PermissionUtils.validatePermission('ViewInvoices', portalUser.permissions.permissionRoles) && (
                    <MenuItem
                      text={
                        <div
                          onClick={() => {
                            window.open(`/pdf?type=view-invoice&invoiceId=${invoiceId}`, '_blank');
                          }}
                        >
                          <HyperlinkButton>View Invoice</HyperlinkButton>
                        </div>
                      }
                      className="hover-bg-gray-lightest"
                    />
                  )}
                  {PermissionUtils.validatePermission('MarkInvoicesAsPaid', portalUser.permissions.permissionRoles) && (
                    <MenuItem
                      disabled={isDisableMarkAsPaid}
                      text={
                        <div
                          onClick={() => {
                            if (!isDisableMarkAsPaid) {
                              this.props.openActionModal({
                                action: 'MARK_AS_PAID',
                                additionalData: items,
                              });
                            }
                          }}
                        >
                          <HyperlinkButton
                            color={this._disableMarkAsPaid(items) ? ' text-color-secondary' : 'blue-action'}
                          >
                            Mark as paid
                            {this._disableMarkAsPaid(items) && (
                              <Tooltip
                                title={
                                  <p>
                                    You cannot mark an invoice
                                    <br />
                                    as paid if all line items are
                                    <br />
                                    rejected
                                  </p>
                                }
                              >
                                <Icon type="question-circle" className="ml-12" />
                              </Tooltip>
                            )}
                          </HyperlinkButton>
                        </div>
                      }
                      className="hover-bg-gray-lightest"
                    />
                  )}
                  {numberOfRejected < numberOfItem &&
                    PermissionUtils.validatePermission(
                      'RejectPaymentBillingLineItem',
                      portalUser.permissions.permissionRoles,
                    ) && (
                      <MenuItem
                        text={
                          <div>
                            <HyperlinkButton
                              color="red"
                              onClick={() => {
                                if (!Utils.isEmpty(items) && items.length === 1) {
                                  const newItem = {
                                    billingLineItemId: items[0].bookingBillingLineItemId,
                                    attendanceId: items[0].attendanceId,
                                  };
                                  this.props.setSelectedRejectBillingItems([newItem]);
                                } else {
                                  this.props.setSelectedRejectBillingItems([]);
                                }
                                this.props.openActionModal({ action: 'REJECT_LINEITEM', additionalData: items });
                              }}
                            >
                              Reject Line Items
                            </HyperlinkButton>
                          </div>
                        }
                        className="hover-bg-gray-lightest"
                      />
                    )}
                  {paymentMethod !== 'NDIA' && numberOfRejected < numberOfItem && (
                    <MenuItem
                      text={
                        <div>
                          <HyperlinkButton
                            color="red"
                            onClick={() =>
                              this.props.openActionModal({
                                action: 'REJECT_LINEITEM',
                                additionalData: items,
                                selected: 'ALL',
                              })
                            }
                          >
                            Reject Entire Invoice
                          </HyperlinkButton>
                        </div>
                      }
                      className="hover-bg-gray-lightest"
                    />
                  )}
                </Menu>
              }
              position={'bottom-right'}
            >
              <Icon type="ellipsis" className="text-size-x4-large" />
            </Popover2>
          </td>
          <td style={{ width: '20px' }} onClick={this.state.showSubLines ? this._onClickClose : this._onClickOpen}>
            <div className="pr-small text-align-right">
              {this.state.showSubLines ? (
                <Icon className="size-x-large" type="up" />
              ) : (
                <Icon className="size-x-large" type="down" />
              )}
            </div>
          </td>
        </tr>
        <tr>
          {this.state.showSubLines && (
            <>
              <td colSpan={7} className="p-none pb-small">
                <table className="subLines" style={{ width: '100%' }}>
                  {_.map(items, (lineItem, key) => (
                    <>
                      <tr className={`text-align-middle ${items.length === key + 1 && 'no-border'}`}>
                        <td className="fluid-cell">
                          {lineItem.serviceType !== ServiceType.GROUP ? (
                            <HyperlinkButton onClick={() => this._onClickBooking(lineItem.attendanceId)}>
                              {moment(lineItem.startDateTime).format('DD/MM/YYYY')}
                            </HyperlinkButton>
                          ) : (
                            moment(lineItem.startDateTime).format('DD/MM/YYYY')
                          )}
                        </td>
                        <td className="fluid-cell">{lineItem.supportItemNumber}</td>
                        <td className="ellipsis">
                          {lineItem.claimType
                            ? claimTypeOptions.find((item) => item.value === lineItem.claimType).label
                            : 'Standard Service'}
                        </td>
                        <td className="ellipsis">{lineItem.claimReference}</td>
                        <td className="ellipsis" style={{ textAlign: 'right' }}>
                          {CommonUtils.formatQuantityDisplay(lineItem.qty)} (
                          {unitOptions.find((item) => item.value === lineItem.unit).label}) x{' '}
                          {CommonUtils.formatPrice(lineItem.unitPrice)}
                        </td>
                        <td className="fluid-cell text-align-right">
                          {CommonUtils.formatPrice(lineItem.billingTotal)}
                        </td>
                        <td style={{ width: '158px' }}>
                          {lineItem.isRejected && (
                            <Text color="red" weight="bold">
                              <Icon type="close-circle" className="mr-x-small" theme="filled" />
                              Rejected
                            </Text>
                          )}
                        </td>
                      </tr>
                    </>
                  ))}
                </table>
              </td>
            </>
          )}
        </tr>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  selectedRejectBillingItems: state.billingsStore.selectedRejectBillingItems,
});

export default connect(
  mapState,
  null,
)(InvoiceItemRow);
