import React, { PureComponent } from 'react';
import { Text } from 'common-components/typography';
import { PaymentStatus } from 'utilities/enum-utils';

interface IActivityRecordTagProps {
  size?: 'small' | 'default' | 'large';
  textSize?: string;
  className?: any;
  textClassName?: any;
  rounded?: boolean;
}

class ActivityRecordTag extends PureComponent<IActivityRecordTagProps, any> {
  render() {
    const { className, textClassName, textSize = 'large', size = 'default', rounded = true } = this.props;

    const bgColor = 'bg-blue-lightest';
    const textColor = 'text-color-blue';
    const roundedClass = rounded ? 'rounded' : '';

    let sizeClasses = '';
    if (size === 'default') {
      sizeClasses = 'pv-x-small ph-small text-size-regular ';
    } else if (size === 'large') {
      sizeClasses = 'pv-small ph-large text-size-large ';
    } else if (size === 'small') {
      sizeClasses = 'pv-x3-small ph-x-small text-size-small ';
    }
    const containerClassNames = `text-align-center ${sizeClasses} inline-block ${roundedClass} ${bgColor} ${className}`;
    const textClassNames = `${textColor} ${textClassName}`;

    return (
      <div className={containerClassNames}>
        <Text size={textSize} className={textClassNames}>
          Activity Record
        </Text>
      </div>
    );
  }
}

interface IActivityRecordStatusTagProps {
  size?: 'small' | 'default' | 'large';
  textSize?: string;
  className?: any;
  textClassName?: any;
  rounded?: boolean;
  paymentStatus: string;
}

class ActivityRecordStatusTag extends PureComponent<IActivityRecordStatusTagProps, any> {
  render() {
    const {
      className,
      textClassName,
      textSize = 'large',
      size = 'default',
      rounded = true,
      paymentStatus
    } = this.props;

    let text, bgColor, textColor;

    switch (paymentStatus) {
      case PaymentStatus.REQUIRES_APPROVAL:
        text = 'Pending';
        bgColor = 'bg-warning-orange';
        textColor = 'text-color-orange';
        break;
      case PaymentStatus.SEND_TO_FINANCE:
        text = 'Approved';
        bgColor = 'bg-blue';
        textColor = 'text-color-white';
        break;
      case PaymentStatus.WAIVED:
        text = 'Waived';
        bgColor = 'bg-red';
        textColor = 'text-color-white';
        break;
      default:
        text = '';
        bgColor = 'bg-white';
        textColor = 'text-color-white';
    }

    const roundedClass = rounded ? 'rounded' : '';

    let sizeClasses = '';
    if (size === 'default') {
      sizeClasses = 'pv-x-small ph-small text-size-regular ';
    } else if (size === 'large') {
      sizeClasses = 'pv-small ph-large text-size-large ';
    } else if (size === 'small') {
      sizeClasses = 'pv-x3-small ph-x-small text-size-small ';
    }
    const containerClassNames = `text-align-center ${sizeClasses} inline-block ${roundedClass} ${bgColor} ${className}`;
    const textClassNames = `${textColor} ${textClassName}`;

    return (
      <div className={containerClassNames}>
        <Text size={textSize} className={textClassNames}>
          {text}
        </Text>
      </div>
    );
  }
}

export { ActivityRecordTag, ActivityRecordStatusTag };
