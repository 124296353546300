import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { Icon } from 'antd';
import ActionSelectItem from 'common-components/items/ActionSelectItem';
import { PlanManagementPermissionType } from 'utilities/enum-utils';
import { getPlanManagementPermissionName } from 'views/account-management/utils/permission-utils';

interface IPlanManagementSectionModalProps {
  isOpen: any;
  onClose: any;
  setSelectedOption: (servicePermission) => any;
  permissions?: any;
}

interface IPlanManagementSectionModalState {
  planManagementPermission: string;
}

class PlanManagementSectionModal extends Component<IPlanManagementSectionModalProps, IPlanManagementSectionModalState> {
  state = { planManagementPermission: PlanManagementPermissionType.NO_ACCESS };

  private _onCloseModal = () => {
    this.props.onClose();
  };

  private _setSelectedOption = async () => {
    await this.props.setSelectedOption({ planManagementPermission: this.state.planManagementPermission });
    this._onCloseModal();
  };

  private _changePermissionMode = (newMode) => {
    this.setState({ planManagementPermission: newMode });
  };

  private _resetPermissions = () => {
    const planManagementPermission = getPlanManagementPermissionName(this.props.permissions);
    this.setState({ planManagementPermission });
  };

  componentDidMount() {
    this._resetPermissions();
  }

  componentDidUpdate(
    prevProps: Readonly<IPlanManagementSectionModalProps>,
    prevState: Readonly<IPlanManagementSectionModalState>,
    snapshot?: any
  ) {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.isOpen) {
        this._resetPermissions();
      }
    }
  }

  render() {
    const { planManagementPermission } = this.state;

    return (
      <ActionModal
        title={
          <>
            <Icon type={'team'} className={'mr-small text-size-x2-large'} />
            Plan Management
          </>
        }
        isOpen={this.props.isOpen}
        onClose={this._onCloseModal}
        width="medium"
      >
        <div className="mv-medium anim-slide-left">
          <div className={'mb-x-large'}>
            <div className={'mb-large'}>
              <Text size={'x-large'}>General permissions</Text>
            </div>

            <ActionSelectItem
              selectedAction={planManagementPermission}
              value={PlanManagementPermissionType.NO_ACCESS}
              title={'No access (default)'}
              description={'Cannot view or access this section'}
              onSetAction={this._changePermissionMode}
            />

            <ActionSelectItem
              selectedAction={planManagementPermission}
              value={PlanManagementPermissionType.ACCESS}
              title={'Access'}
              description={'Can see and perform all actions within this section'}
              onSetAction={this._changePermissionMode}
            />
          </div>

          <ActionModalFooter>
            <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._setSelectedOption}>
              Save changes
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      </ActionModal>
    );
  }
}

export default PlanManagementSectionModal;
