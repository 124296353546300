import React, { PureComponent } from 'react';
import { PORTAL_COLORS } from '../interfaces/common-ui-types';

interface CenteredLayoutProps {
  children?: any;
  className?: string;
  topPadded?: boolean;
  backgroundColor?: PORTAL_COLORS;
  height?: 'full' | string;
  maxWidth?: number;
  bordered?: boolean;
}

class CenteredLayout extends PureComponent<CenteredLayoutProps> {
  render() {
    const {
      children,
      className,
      topPadded = true,
      backgroundColor,
      maxWidth = 1152,
      bordered,
      height = 'full'
    } = this.props;

    const maxWidthStyle = `${maxWidth}px`;
    const backgroundColorClass = `bg-${backgroundColor}`;

    const heightStyle = height === 'full' ? { height: 'calc(100vh - 64px)', overflow: 'auto' } : { height };

    return (
      <div
        className={`width-full ${backgroundColorClass} ${bordered &&
          'bordered-top border-width-medium border-tertiary'}`}
        style={heightStyle}
        id="content-container"
      >
        <div className="flex-row justify-center height-full">
          <div style={{ maxWidth: maxWidthStyle }} className={`width-full ${topPadded && 'pt-x-large'} ${className}`}>
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default CenteredLayout;
