// Event Tags
import moment from 'moment-timezone';
import _ from 'lodash';
import { IExtendedMessage, MessageType } from 'interfaces/message-interfaces';
import { TextMessage } from 'views/messaging/messages/TextMessage';
import { NewSubscriberTag } from 'views/messaging/messages/components/tags/NewSubscriberTag';
import { DeleteSubscriberTag } from 'views/messaging/messages/components/tags/DeleteSubscriberTag';
import { NewChannelTag } from 'views/messaging/messages/components/tags/NewChannelTag';
import { LeaveChannelTag } from 'views/messaging/messages/components/tags/LeaveChannelTag';
import { DateTag } from 'views/messaging/messages/components/tags/DateTag';
import React from 'react';

interface IMessageItemTagProps {
  message: IExtendedMessage;
  deleteMessage: any;
}

export function MessageItem({ message, deleteMessage }: IMessageItemTagProps) {
  const {
    createdOn,
    sent,
    authorUserId,
    content,
    messageId,
    currentUserId,
    index,
    previousMessage,
    nextMessage,
    animateIn,
    messageType,
    messageChannelId,
    preferredTimezone
  } = message;

  const { text, attachments } = content;

  const time = moment.tz(createdOn, preferredTimezone).format('hh:mm A');

  const prevDate = !_.isEmpty(previousMessage)
    ? moment.tz(previousMessage.createdOn, preferredTimezone).format('D MMMM YYYY')
    : null;
  const currentDate = moment.tz(createdOn, preferredTimezone).format('D MMMM YYYY');

  const showDateTag = currentDate !== prevDate;

  let MessageComponent = null;

  // Choose which component to render as.
  switch (message.messageType) {
    case MessageType.NewMessage:
      MessageComponent = TextMessage;
      break;
    case MessageType.DeleteMessage:
      MessageComponent = TextMessage;
      break;
    case MessageType.NewSubscriber:
      MessageComponent = NewSubscriberTag;
      break;
    case MessageType.DeleteSubscriber:
      MessageComponent = DeleteSubscriberTag;
      break;
    case MessageType.NewChannel:
      MessageComponent = NewChannelTag;
      break;
    case MessageType.LeaveChannel:
      MessageComponent = LeaveChannelTag;
      break;
  }

  return (
    <div>
      {showDateTag && <DateTag message={message} />}
      <MessageComponent message={message} key={message.messageId} deleteMessage={deleteMessage} />
    </div>
  );
}
