import React, { Component } from 'react';

import { Steps } from 'antd';

import { PrimaryButton } from 'common-components/buttons';
import CenteredLayout from 'layouts/CenteredLayout';
import AddNewMemberModal from 'views/account-management/add-new-member/AddNewMemberModal';
import EditPermissionsModal from 'views/account-management/edit-permissions/EditPermissionsModal';

const { Step } = Steps;

class AccountAddMemberView extends Component<any, any> {
  // state = { isModalOpen: false };
  state = { isModalOpen: false, isEditModalOpen: false };

  openModal = () => this.setState({ isModalOpen: true });
  closeModal = () => this.setState({ isModalOpen: false });

  openEditModal = () => this.setState({ isEditModalOpen: true });
  closeEditModal = () => this.setState({ isEditModalOpen: false });

  render() {
    return (
      <CenteredLayout backgroundColor={'white'} bordered={true}>
        <div className="mb-medium">
          <PrimaryButton icon="plus" size="large" onClick={this.openModal}>
            Add New User
          </PrimaryButton>
        </div>

        <div>
          <PrimaryButton icon="plus" size="large" onClick={this.openEditModal}>
            Edit Permissions
          </PrimaryButton>
        </div>

        <AddNewMemberModal onClose={this.closeModal} isOpen={this.state.isModalOpen} history={this.props.history} />

        <EditPermissionsModal isOpen={this.state.isEditModalOpen} onClose={this.closeEditModal} />
      </CenteredLayout>
    );
  }
}

export default AccountAddMemberView;
