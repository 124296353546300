import React, { Component } from 'react';
import _ from 'lodash';
import { Form, Row } from 'antd';
import { Text } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { connect } from 'react-redux';

interface IArchiveDebtorModalProps {
  debtor: any;
  isOpen: boolean;
  archiveDebtorFrom: string;
  closeArchiveModal: () => void;
  doArchiveDebtor: typeof dispatch.accountStore.doArchiveDebtor;
  closeEditMode?: () => void;
}

interface IArchiveDebtorModalState {
  isArchiveSuccess: boolean;
  isArchiving: boolean;
  step: number;
}

class ArchiveDebtorModal extends Component<IArchiveDebtorModalProps, IArchiveDebtorModalState> {
  state = {
    isArchiveSuccess: false,
    isArchiving: false,
    step: 1
  };

  private closeModal = () => {
    const { closeEditMode } = this.props;
    this.setState({ isArchiveSuccess: false, isArchiving: false, step: 1 });
    if (closeEditMode) {
      closeEditMode();
    }
    this.props.closeArchiveModal();
  };

  private _onArchive = async () => {
    const { doArchiveDebtor, debtor, archiveDebtorFrom } = this.props;
    try {
      this.setState({ isArchiving: true });
      await doArchiveDebtor({ debtorId: debtor.debtorId, archiveDebtorFrom: archiveDebtorFrom });
      this.setState({
        isArchiving: false,
        isArchiveSuccess: true,
        step: 2
      });
    } catch (e) {
      console.log(e);
      if (e.meta.message === 'Debtor has unpaid invoices') {
        this.setState({
          isArchiving: false,
          isArchiveSuccess: false,
          step: 3
        });
      } else if (e.meta.message === 'Debtor is linked customers') {
        this.setState({
          isArchiving: false,
          isArchiveSuccess: false,
          step: 4
        });
      }
    }
  };

  render() {
    const { isArchiveSuccess, isArchiving, step } = this.state;
    const { isOpen } = this.props;
    return (
      <ActionModal
        title={
          step === 1 ? 'Archive debtor' : isArchiveSuccess ? 'Debtor successfully archived' : 'Cannot archive debtor'
        }
        isOpen={isOpen}
        onClose={() => this.closeModal()}
        width="small"
      >
        {step === 1 && (
          <>
            <Row>
              <Text>Are you sure you want to archive this debtor?</Text>
            </Row>
            <br />
            <Row>
              <Text>You will no longer be able to select this debtor as a fund manager for your customers.</Text>
            </Row>
            <ActionModalFooter>
              <GhostButton className="mr-medium" size="large" onClick={() => this.closeModal()}>
                Cancel
              </GhostButton>
              <PrimaryButton loading={isArchiving} color="red" size="large" onClick={this._onArchive}>
                Archive
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 2 && (
          <>
            <Row>
              <Text>
                This debtor has been archived and can no longer be selected as a fund manager for your customers.
              </Text>
            </Row>

            <ActionModalFooter>
              <PrimaryButton size="large" onClick={() => this.closeModal()}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 3 && (
          <>
            <Row>
              <Text>This debtor still has unpaid invoices.</Text>
            </Row>
            <br />
            <Row>
              <Text>A debtor cannot be archived when there are unpaid invoices attached to the account.</Text>
            </Row>

            <ActionModalFooter>
              <PrimaryButton size="large" onClick={() => this.closeModal()}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 4 && (
          <>
            <Row>
              <Text>This debtor is still linked to at least one customer in your workspace.</Text>
            </Row>
            <br />
            <Row>
              <Text>A debtor cannot be archived when there are still customers linked to the account.</Text>
            </Row>
            <ActionModalFooter>
              <PrimaryButton size="large" onClick={() => this.closeModal()}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doArchiveDebtor: dispatch.accountStore.doArchiveDebtor
});

export default connect(
  mapState,
  mapDispatch
)(ArchiveDebtorModal);
