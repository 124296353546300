import { useInfiniteQuery } from 'react-query';

import { getNextPageParam } from 'stores/hooks/common/common-hook-utils';
import { ROSTER_COMMON_CONFIG } from 'views/bookings/roster-view/common/roster-common-config';

import { formatBookingListingFilters } from 'stores/rematch/models/bookings-store';

import { fetchBookings } from 'stores/queries/roster/roster-queries';
import { QueryKeys } from 'stores/hooks/common/query-keys';

const pageSize = ROSTER_COMMON_CONFIG.pageSize;

export function useQueryInfiniteBookings({ filters, enabled }) {
  // Query function that calls the API
  const queryBookings = ({ pageParam = 1 }) => {
    const request = createBookingsRequest({ page: pageParam, pageSize, filters });
    return fetchBookings(request);
  };

  const {
    data, // data returned from queryBooking
    fetchNextPage, // function to fetch next page
    hasNextPage, // boolean to indicate if there is a next page
    isFetching, // boolean to indicate if the query is in progress
    isFetchingNextPage, // boolean to indicate if the query to fetch the next page is in progress
    status, // status of the query
    refetch, // function to refetch the query
    remove // function to remove/invalidate the query
  } = useInfiniteQuery([QueryKeys.BOOKINGS, { filters }], queryBookings, {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    enabled,
    getNextPageParam
  });

  return {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch,
    remove
  };
}

function createBookingsRequest({ filters = [], dateFilter = {}, page = 1, pageSize = 20, showOnlyAssigned = true }) {
  const formatted = formatBookingListingFilters({ filters });

  return {
    ...formatted,
    page,
    pageSize,
    showOnlyAssigned,
    sort: [['customerFirstName', 'asc'], ['startDateTime', 'asc']]
  };
}
