import React, { Component } from 'react';
import _ from 'lodash';
import { Col, notification, Row } from 'antd';
import { Paragraph } from 'common-components/typography';
import { IGroupServiceSchedule } from 'interfaces/service-interfaces';
import ActionModal from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { Text } from 'common-components/typography';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import { RecurringBookingPattern } from 'utilities/enum-utils';
import moment from 'moment-timezone';

interface IDeleteScheduleModalProps {
  closeDeleteScheduleModal: () => void;
  doRemoveGroupServiceSchedule: typeof dispatch.servicesStore.doRemoveGroupServiceSchedule;
  isOpen: boolean;
  serviceId: string;
  selectedSchedule?: IGroupServiceSchedule;
  resetScheduleList: () => void;
  timezone: string;
}

interface IDeleteScheduleModalState {
  title: string;
  schedulePeriodText: string;
  timeSlotsTexts: string[];
  isLoading: boolean;
}

class DeleteScheduleModal extends Component<IDeleteScheduleModalProps, IDeleteScheduleModalState> {
  state = {
    title: 'Delete schedule',
    schedulePeriodText: null,
    timeSlotsTexts: [],
    isLoading: false
  };

  private _deleteSchedule = async () => {
    const {
      serviceId,
      selectedSchedule,
      closeDeleteScheduleModal,
      doRemoveGroupServiceSchedule,
      resetScheduleList
    } = this.props;

    try {
      this.setState({ isLoading: true });
      await doRemoveGroupServiceSchedule({ serviceId, serviceScheduleId: selectedSchedule.serviceScheduleId });
      notification.success({ message: 'Schedule removed successfully.' });
      closeDeleteScheduleModal();
      resetScheduleList();
    } catch (e) {
      notification.error({ message: 'Oops, an error has occurred, please try again.' });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  private _closeModal = () => {
    this.props.closeDeleteScheduleModal();
  };

  private _parseSchedulePeriod = () => {
    if (_.isEmpty(this.props.selectedSchedule)) return;
    const { timezone } = this.props;
    const scheduleConfig = this.props.selectedSchedule.scheduleConfig;
    const startDate = moment.tz(scheduleConfig.scheduleStartDate, timezone);
    const endDate = moment.tz(scheduleConfig.scheduleEndDate, timezone);

    const startDateString =
      startDate.year() === endDate.year() ? startDate.format('DD MMMM') : startDate.format('DD MMMM YYYY');
    const endDateString = endDate.format('DD MMMM YYYY');

    const newSchedulePeriodText = `${startDateString} - ${endDateString}`;

    this.setState({ schedulePeriodText: newSchedulePeriodText });
  };

  private _parseTimeSlot = () => {
    if (_.isEmpty(this.props.selectedSchedule)) return;
    const timeSlot = this.props.selectedSchedule.scheduleConfig.timeSlots;
    const newSlotTexts = [];

    _.map(timeSlot, (ts) => {
      const recurringPattern = this._parseRecurringPatter(ts.recurringPattern);
      const weekDay = moment.tz(ts.startDateTime, ts.timezone).format('dddd');
      const startTime = moment.tz(ts.startDateTime, ts.timezone).format('HH:mm A');
      const endTime = moment.tz(ts.endDateTime, ts.timezone).format('HH:mm A');
      const newText = `${recurringPattern} ${weekDay} - ${startTime} to ${endTime}`;
      newSlotTexts.push(newText);
    });

    this.setState({ timeSlotsTexts: newSlotTexts });
  };

  private _parseRecurringPatter = (recurringPattern: RecurringBookingPattern) => {
    switch (recurringPattern) {
      case RecurringBookingPattern.EveryWeek:
        return 'Every';
      case RecurringBookingPattern.EveryFortnight:
        return 'Every 2nd';
      case RecurringBookingPattern.EveryFourWeeks:
        return 'Every 4th';
      //case 5:
      //  return 'Every 3rd';
      default:
        return 'N/A';
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.selectedSchedule && this.props.selectedSchedule !== prevProps.selectedSchedule) {
      this._parseSchedulePeriod();
      this._parseTimeSlot();
    }
  };

  render() {
    const { selectedSchedule } = this.props;

    return (
      <div>
        <ActionModal
          title={this.state.title}
          isOpen={this.props.isOpen}
          onClose={this.props.closeDeleteScheduleModal}
          width="medium"
        >
          <div className="anim-slide-left">
            <SpinningLoadingActionModel isOpen={this.state.isLoading} verticalAlignment={'highest'} />
            <Paragraph>You are about to delete the following schedule :</Paragraph>
            {!_.isEmpty(this.props.selectedSchedule) && (
              <>
                <div className="bg-white ph-medium pv-small rounded-big mb-medium relative bordered border-standard-gray">
                  <Text weight="bold">{selectedSchedule.scheduleName}</Text>
                  <div className="mb-medium">
                    <Text className="mb-medium">{this.state.schedulePeriodText}</Text>
                  </div>

                  {_.map(this.state.timeSlotsTexts, (timeSlotText) => (
                    <div className="bg-quaternary mb-small" style={{ width: 'min-content', whiteSpace: 'nowrap' }}>
                      <Text size="regular">{timeSlotText}</Text>
                    </div>
                  ))}
                </div>
                <div className="mt-large mb-x2-large">
                  All upcoming <b>(Scheduled)</b> sessions will be cancelled.
                  <br /> <b>Completed sessions</b> and <b>sessions in progress</b> will not be affected.
                  <br />
                  <br />
                  Would you like to continue?
                </div>
              </>
            )}

            <div className={'mb-small'}>
              <Row type={'flex'} className={'justify-end'}>
                <Col className="mr-large">
                  <SecondaryButton size="large" onClick={this._closeModal}>
                    Cancel
                  </SecondaryButton>
                </Col>
                <Col>
                  <PrimaryButton size="large" onClick={this._deleteSchedule}>
                    Delete schedule
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </div>
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRemoveGroupServiceSchedule: dispatch.servicesStore.doRemoveGroupServiceSchedule
});

export default connect(
  null,
  mapDispatch
)(DeleteScheduleModal);
