import React, { Component } from 'react';
import _ from 'lodash';
import { PrimaryButton } from 'common-components/buttons';
import { IGroupServiceCustomer, IGroupServiceNote } from 'interfaces/service-interfaces';
import { EmptyNote } from 'views/group-services/components/booking-notes/EmptyNote';
import { BookingNoteItem } from 'views/group-services/components/booking-notes/BookingNoteItem';
import AddEditGroupNoteModal from 'views/group-services/components/booking-notes/AddEditGroupNoteModal';
import { DeleteGroupNoteModal } from 'views/group-services/components/booking-notes/DeleteGroupNoteModal';
import { IRootDispatch, IRootState, state, dispatch } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { Skeleton } from 'antd';
import asyncDelay from 'utilities/asyncDelay';
import { ModalLoading } from 'views/group-services/manage-booking-modal/common-components/ModalLoading';

interface INotesPanelProps {
  booking: typeof state.groupBookingsStore.selectedGroupBookingItem;
  groupBookingNotes: typeof state.groupBookingsStore.groupBookingNotes;
  doFetchGroupBookingNotes: typeof dispatch.groupBookingsStore.doFetchGroupBookingNotes;
  doRemoveGroupBookingNote: typeof dispatch.groupBookingsStore.doRemoveGroupBookingNote;
  doFetchMoreNotes: any;
  doLoadFreshNotes: any;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  isLoading: boolean;
}

interface INotesPanelState {
  isAddNoteModalOpen: boolean;
  isDeleteNoteModalOpen: boolean;
  isLoading: boolean;
  noteMode: 'add' | 'edit' | string;
  targetNote: IGroupServiceNote;
  selectedCustomers: IGroupServiceCustomer[];
}

class NotesPanel extends Component<INotesPanelProps, INotesPanelState> {
  state = {
    isAddNoteModalOpen: false,
    isDeleteNoteModalOpen: false,
    isLoading: false,
    noteMode: 'add',
    targetNote: null,
    selectedCustomers: [
      {
        taggedUserId: this.props.booking.userId,
        taggedUserFirstName: this.props.booking.firstName,
        taggedUserLastName: this.props.booking.lastName,
        taggedUserAvatarUrl: this.props.booking.attachmentUrl
      }
    ]
  };

  //region Event Handlers
  onAddNote = () => {
    this.openAddNoteModal({ noteMode: 'add', targetNote: null });
  };

  onEditNote = ({ targetNote }) => {
    this.openAddNoteModal({ noteMode: 'edit', targetNote });
  };

  onDeleteNote = ({ targetNote }) => {
    this.openDeleteNoteModal({ targetNote });
  };

  //region Actions

  // The following actions trigger whenever the action has been confirmed from the modal.

  onDeleteNoteAction = ({ targetNote }) => {
    const { booking, doRemoveGroupBookingNote } = this.props;
    const payload = {
      bookingId: booking.bookingId,
      noteId: targetNote.noteId
    };

    doRemoveGroupBookingNote(payload);
  };

  onSaveNoteAction = async () => {
    this.setState({ isAddNoteModalOpen: false });
    await this.props.doLoadFreshNotes();
  };
  //endregion

  //endregion

  //region Modal Handlers
  openAddNoteModal = ({ noteMode, targetNote }) => this.setState({ isAddNoteModalOpen: true, noteMode, targetNote });
  closeAddNoteModal = () => this.setState({ isAddNoteModalOpen: false });

  openDeleteNoteModal = ({ targetNote }) => this.setState({ isDeleteNoteModalOpen: true, targetNote });
  closeDeleteNoteModal = () => this.setState({ isDeleteNoteModalOpen: false, targetNote: null });
  //endregion

  render() {
    const { groupBookingNotes, page, pageSize, isLoading } = this.props;

    // Loading
    if (isLoading) {
      return <ModalLoading text={'Fetching notes...'} />;
    }

    const hasNotes = !_.isEmpty(groupBookingNotes);
    const isNoNotesFound = !isLoading && !hasNotes;

    return (
      <>
        {/* Main content */}
        <div className="anim-fade-in-fast">
          {/* Add notes button */}
          <section className="mb-large">
            <PrimaryButton icon="plus" onClick={this.onAddNote}>
              Add Note
            </PrimaryButton>
          </section>

          {isNoNotesFound ? (
            <EmptyNote />
          ) : (
            <div id="scroll-container" style={{ height: '500px', overflowY: 'auto' }}>
              <section className="bg-quaternary bordered border-standard-gray ph-medium pt-medium">
                {hasNotes && (
                  <InfiniteScrollLoading
                    hasMore={groupBookingNotes.length >= page * pageSize}
                    loadingElementId={'scroll-container'}
                    loadMore={async () => await this.props.doFetchMoreNotes()}
                    loaderColSpan={7}
                    loadingOffSet={100}
                  >
                    {_.map(groupBookingNotes, (note) => (
                      <BookingNoteItem
                        onEditNote={() => this.onEditNote({ targetNote: note })}
                        onDeleteNote={() => this.onDeleteNote({ targetNote: note })}
                        note={note}
                      />
                    ))}
                  </InfiniteScrollLoading>
                )}
                {isLoading && (
                  <Skeleton paragraph={{ rows: 5, width: '100%' }} active={true} className="anim-slide-left" />
                )}
              </section>
            </div>
          )}
        </div>

        {/* Modals */}
        {/* Add / Edit modal */}
        <AddEditGroupNoteModal
          isOpen={this.state.isAddNoteModalOpen}
          onClose={this.closeAddNoteModal}
          noteMode={this.state.noteMode}
          targetNote={this.state.targetNote}
          onSaveNoteAction={this.onSaveNoteAction}
          isAllowSelecteCustomer={false}
          booking={this.props.booking}
          selectedCustomers={this.state.selectedCustomers}
        />

        {/* Delete modal */}
        <DeleteGroupNoteModal
          isOpen={this.state.isDeleteNoteModalOpen}
          targetNote={this.state.targetNote}
          onClose={this.closeDeleteNoteModal}
          onDeleteNoteAction={this.onDeleteNoteAction}
        />
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupBookingNotes: state.groupBookingsStore.groupBookingNotes
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupBookingNotes: dispatch.groupBookingsStore.doFetchGroupBookingNotes,
  doRemoveGroupBookingNote: dispatch.groupBookingsStore.doRemoveGroupBookingNote
});

export default connect(
  mapState,
  mapDispatch
)(NotesPanel);
