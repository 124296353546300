import { Checkbox, Col, Icon, Input, notification, Progress, Row, Select, Upload } from 'antd';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import NumberInput from 'common-components/inputs/NumberInput';
import ActionModal from 'common-components/modal/ActionModal';
import { FieldLabel, Text } from 'common-components/typography';
import { ref, uploadBytesResumable } from 'firebase/storage';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import firebaseApp from 'stores/firebase-app';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { PlanManagerType } from 'utilities/enum-utils';

const { TextArea } = Input;

interface IAddEditInvoiceDetailsModalProps {
  isOpen: boolean;
  selectedPlanManagementInvoiceDetails: any;
  modalMode: string;
  planManagementCustomers: any;
  onClose: any;
  planManagementSuppliers: any;
  addInvoiceCallback?: any;
  portalUser: any;
  isAutoGenerated?: boolean;
  selectedSupplierId?: string;
  selectedCustomerUserId?: string;
  doFetchPlanManagementCustomers: typeof dispatch.planManagementStore.doFetchPlanManagementCustomers;
  doFetchPlanManagementSuppliers: typeof dispatch.planManagementStore.doFetchPlanManagementSuppliers;
  doCreatePlanManagementInvoice: typeof dispatch.planManagementStore.doCreatePlanManagementInvoice;
  doEditPlanManagementInvoice: typeof dispatch.planManagementStore.doEditPlanManagementInvoice;
  doFetchPlanManagementInvoiceDetails: typeof dispatch.planManagementStore.doFetchPlanManagementInvoiceDetails;
}

interface IAddEditInvoiceDetailsModalState {
  isLoading: boolean;
  selectedCustomerId: string;
  selectedSupplierId: string;
  invoiceNumber: string;
  invoiceIssueDate: any;
  invoiceDueDate: any;
  invoiceTotal: any;
  additionalComments: any;
  showSelectedCustomerIdError: boolean;
  showSelectedSupplierIdError: boolean;
  showInvoiceNumberError: boolean;
  showInvoiceIssueDateError: boolean;
  showInvoiceDueDateError: boolean;
  showInvoiceTotalError: boolean;
  showDocumentError: boolean;
  invoiceTotalErrorString: string;
  gstAmountErrorString: string;
  includesGST: boolean;
  editNewFile: boolean;
  selectedFile: any;
  progress: number;
  isProgressBarLoading: boolean;
  gstAmount: number;
  showGstAmountError: boolean;
}

class AddEditInvoiceDetailsModal extends Component<IAddEditInvoiceDetailsModalProps, IAddEditInvoiceDetailsModalState> {
  state = {
    isLoading: false,
    selectedCustomerId: null,
    selectedSupplierId: null,
    invoiceNumber: '',
    invoiceIssueDate: null,
    invoiceDueDate: null,
    invoiceTotal: null,
    additionalComments: null,
    showSelectedCustomerIdError: false,
    showSelectedSupplierIdError: false,
    showInvoiceNumberError: false,
    showInvoiceIssueDateError: false,
    showInvoiceDueDateError: false,
    showInvoiceTotalError: false,
    includesGST: false,
    gstAmount: 0,
    showGstAmountError: false,
    selectedFile: null,
    editNewFile: false,
    progress: 0,
    isProgressBarLoading: false,
    invoiceTotalErrorString: 'Please insert an invoice total.',
    gstAmountErrorString: 'Please insert a GST amount.',
    showDocumentError: false
  };

  private _checkHasInputFieldsMissing = () => {
    const {
      selectedCustomerId,
      selectedSupplierId,
      invoiceNumber,
      invoiceIssueDate,
      invoiceDueDate,
      invoiceTotal,
      gstAmount,
      includesGST
    } = this.state;

    const totalLineItemPrices =
      this.props.selectedPlanManagementInvoiceDetails && this.props.modalMode === 'EDIT'
        ? _.reduce(
            this.props.selectedPlanManagementInvoiceDetails.lineItems,
            (acc, item) => {
              return acc + Number(item.price) * Number(item.qty);
            },
            0
          )
        : 0;

    this.setState({
      showSelectedCustomerIdError: !selectedCustomerId,
      showSelectedSupplierIdError: !selectedSupplierId,
      showInvoiceNumberError: !invoiceNumber,
      showInvoiceIssueDateError: !invoiceIssueDate,
      showInvoiceDueDateError: !invoiceDueDate,
      showInvoiceTotalError: !invoiceTotal || invoiceTotal >= 1000000 || invoiceTotal < totalLineItemPrices,
      invoiceTotalErrorString: !invoiceTotal
        ? 'Please insert an invoice total.'
        : invoiceTotal >= 1000000
        ? 'Invoice total must be no more than 6 digits and 2 decimal points.'
        : 'Invoice total cannot be less than the value of already processed line items for this invoice',
      gstAmountErrorString:
        includesGST && !gstAmount
          ? 'Please insert a GST amount.'
          : 'GST amount must be no more than 6 digits and 2 decimal points.',
      showGstAmountError: (includesGST && !gstAmount) || gstAmount >= 1000000
    });

    return (
      !selectedCustomerId ||
      !selectedSupplierId ||
      !invoiceNumber ||
      !invoiceIssueDate ||
      !invoiceDueDate ||
      !invoiceTotal ||
      invoiceTotal >= 1000000 ||
      invoiceTotal < totalLineItemPrices ||
      (includesGST && !gstAmount) ||
      gstAmount >= 1000000
    );
  };

  private _resetErrorMessages = () => {
    this.setState({
      showSelectedCustomerIdError: false,
      showSelectedSupplierIdError: false,
      showInvoiceNumberError: false,
      showInvoiceIssueDateError: false,
      showInvoiceDueDateError: false,
      showInvoiceTotalError: false,
      showGstAmountError: false,
      showDocumentError: false
    });
  };

  private _onCreateInvoice = async () => {
    const {
      selectedCustomerId,
      selectedSupplierId,
      invoiceNumber,
      invoiceIssueDate,
      invoiceDueDate,
      invoiceTotal,
      additionalComments,
      includesGST,
      selectedFile,
      gstAmount
    } = this.state;

    const hasFieldsMissing = this._checkHasInputFieldsMissing();
    if (!hasFieldsMissing) {
      try {
        this.setState({ isLoading: true });
        const payload: any = {
          customerUserId: selectedCustomerId,
          planManagementSupplierId: selectedSupplierId,
          invoiceNumber: invoiceNumber,
          invoiceIssuedDate: moment(invoiceIssueDate).format('YYYY-MM-DD'),
          invoiceDueDate: moment(invoiceDueDate).format('YYYY-MM-DD'),
          invoiceTotal: Number(invoiceTotal),
          comments: additionalComments,
          includesGst: includesGST,
          gstAmount: includesGST ? Number(gstAmount) : 0
        };

        if (selectedFile) {
          payload.documentName = selectedFile.name;
        }

        const result: any = await this.props.doCreatePlanManagementInvoice(payload);
        if (result.error) {
          if (result.message === 'File extension not supported') {
            this.setState({
              showDocumentError: true
            });
          }
        } else {
          if (selectedFile) {
            try {
              const metadata = {
                customMetadata: {
                  documentId: result.documentId,
                  planManagementInvoiceId: result.planManagementInvoiceId,
                  serviceProviderId: this.props.portalUser.serviceProviderId
                }
              };
              this.setState({ isProgressBarLoading: true });

              const storageRef = ref(firebaseApp.storage, `${result.uploadBucketUrl}/${selectedFile.name}`);
              const uploadFile = uploadBytesResumable(storageRef, selectedFile, metadata);

              await uploadFile.on(
                'state_changed',
                (snapshot) => {
                  const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                  this.setState({ progress });
                },
                (error) => {
                  this.setState({ isLoading: false, isProgressBarLoading: false });
                  notification.error({ message: 'Upload failed! Please try again.', description: error });
                },
                () => {
                  this.setState({ isLoading: false, isProgressBarLoading: false });
                  notification.success({ message: 'Document is currently scanning.' });
                }
              );
            } catch (e) {
              notification.error({ message: 'Upload failed! Please try again.', description: e });
            }
          }
          if (this.props.addInvoiceCallback) await this.props.addInvoiceCallback();
          this._onClose(true);
        }
        this.setState({ isLoading: false });
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong, please try again.' });
        this.setState({ isLoading: false });
      }
    }
  };

  private _onEditInvoice = async () => {
    const {
      selectedCustomerId,
      selectedSupplierId,
      invoiceNumber,
      invoiceIssueDate,
      invoiceDueDate,
      invoiceTotal,
      additionalComments,
      includesGST,
      selectedFile,
      editNewFile,
      gstAmount
    } = this.state;

    const hasFieldsMissing = this._checkHasInputFieldsMissing();
    if (!hasFieldsMissing) {
      this.setState({ isLoading: true });
      try {
        const payload = {
          customerUserId: selectedCustomerId,
          planManagementSupplierId: selectedSupplierId,
          invoiceNumber: invoiceNumber,
          invoiceIssuedDate: moment(invoiceIssueDate).format('YYYY-MM-DD'),
          invoiceDueDate: moment(invoiceDueDate).format('YYYY-MM-DD'),
          invoiceTotal: Number(invoiceTotal),
          comments: additionalComments,
          includesGst: includesGST,
          gstAmount: includesGST ? Number(gstAmount) : 0,
          planManagementInvoiceId: this.props.selectedPlanManagementInvoiceDetails.planManagementInvoiceId,
          documentName: selectedFile ? selectedFile.name : null,
          isNewDocument: editNewFile
        };
        const result: any = await this.props.doEditPlanManagementInvoice(payload);

        if (result.error) {
          if (result.message === 'File extension not supported') {
            this.setState({
              showDocumentError: true
            });
          }
        } else {
          if (
            (!!selectedFile && editNewFile) ||
            (!!selectedFile && !this.props.selectedPlanManagementInvoiceDetails.documentId)
          ) {
            try {
              const metadata = {
                customMetadata: {
                  documentId: result.documentId,
                  planManagementInvoiceId: result.planManagementInvoiceId,
                  serviceProviderId: this.props.portalUser.serviceProviderId
                }
              };
              this.setState({ isProgressBarLoading: true });

              const storageRef = ref(firebaseApp.storage, `${result.uploadBucketUrl}/${selectedFile.name}`);
              const uploadFile = uploadBytesResumable(storageRef, selectedFile, metadata);

              await uploadFile.on(
                'state_changed',
                (snapshot) => {
                  const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                  this.setState({ progress });
                },
                (error) => {
                  this.setState({ isLoading: false, isProgressBarLoading: false });
                  notification.error({ message: 'Upload failed! Please try again.', description: error });
                },
                () => {
                  this.setState({ isLoading: false, isProgressBarLoading: false });
                  notification.success({ message: 'Document is currently scanning.' });
                }
              );
            } catch (e) {
              notification.error({ message: 'Upload failed! Please try again.', description: e });
            }
          }

          await this.props.doFetchPlanManagementInvoiceDetails({
            planManagementInvoiceId: this.props.selectedPlanManagementInvoiceDetails.planManagementInvoiceId
          });
          notification.success({ message: 'Invoice updated successfully.' });
          this._onClose();
        }
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong, please try again.' });
        this.setState({ isLoading: false });
      }
      this.setState({ isLoading: false });
    }
  };

  private _onClose(refreshListing = false) {
    this.setState({
      selectedCustomerId: null,
      selectedSupplierId: null,
      invoiceNumber: null,
      invoiceIssueDate: null,
      invoiceDueDate: null,
      invoiceTotal: null,
      additionalComments: null,
      includesGST: false,
      selectedFile: null,
      editNewFile: false,
      gstAmount: 0,
      showSelectedCustomerIdError: false,
      showSelectedSupplierIdError: false,
      showInvoiceNumberError: false,
      showInvoiceIssueDateError: false,
      showInvoiceDueDateError: false,
      showInvoiceTotalError: false,
      showGstAmountError: false,
      showDocumentError: false
    });
    this.props.onClose(refreshListing);
  }

  private _populateFields = () => {
    const { selectedPlanManagementInvoiceDetails } = this.props;
    this.setState({
      selectedCustomerId: selectedPlanManagementInvoiceDetails.customerUserId,
      selectedSupplierId: selectedPlanManagementInvoiceDetails.planManagementSupplierId,
      invoiceNumber: selectedPlanManagementInvoiceDetails.invoiceNumber,
      invoiceIssueDate: new Date(selectedPlanManagementInvoiceDetails.invoiceIssuedDate),
      invoiceDueDate: new Date(selectedPlanManagementInvoiceDetails.invoiceDueDate),
      invoiceTotal: selectedPlanManagementInvoiceDetails.invoiceTotal,
      additionalComments: selectedPlanManagementInvoiceDetails.comments,
      includesGST: selectedPlanManagementInvoiceDetails.includesGst,
      gstAmount: selectedPlanManagementInvoiceDetails.gstAmount,
      selectedFile: selectedPlanManagementInvoiceDetails.documentId
        ? { name: selectedPlanManagementInvoiceDetails.documentName }
        : null
    });
  };

  private _onChangeIssueDate = (date) => {
    const { invoiceIssueDate, invoiceDueDate } = this.state;
    this._resetErrorMessages();

    if (moment(date).startOf('day') > moment(invoiceDueDate).startOf('day')) {
      this.setState({
        invoiceIssueDate: date,
        invoiceDueDate: moment(date)
          .add(
            moment(invoiceDueDate)
              .startOf('day')
              .diff(moment(invoiceIssueDate).startOf('day'), 'days'),
            'day'
          )
          .toDate()
      });
    } else {
      this.setState({ invoiceIssueDate: date });
    }
  };

  async componentDidMount() {
    if (this.props.modalMode === 'EDIT') {
      this._populateFields();
    } else {
      if (this.props.selectedSupplierId) this.setState({ selectedSupplierId: this.props.selectedSupplierId });
      if (this.props.selectedCustomerUserId) this.setState({ selectedCustomerId: this.props.selectedCustomerUserId });
      await this.props.doFetchPlanManagementCustomers({ planManagerType: [PlanManagerType.INTERNAL] });
      await this.props.doFetchPlanManagementSuppliers({});
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IAddEditInvoiceDetailsModalProps>,
    prevState: Readonly<IAddEditInvoiceDetailsModalState>,
    snapshot?: any
  ) {
    if (this.props.modalMode === 'ADD' && prevProps.isOpen !== this.props.isOpen) {
      if (this.props.selectedSupplierId) {
        this.setState({ selectedSupplierId: this.props.selectedSupplierId });
      } else if (this.props.selectedCustomerUserId) {
        this.setState({ selectedCustomerId: this.props.selectedCustomerUserId });
      }
    }
  }

  render() {
    const { selectedPlanManagementInvoiceDetails, modalMode, isAutoGenerated = false } = this.props;
    const { selectedSupplierId, selectedCustomerId } = this.state;
    return (
      <div>
        <ActionModal
          title={modalMode === 'ADD' ? 'Add new Invoice' : 'Edit invoice details'}
          isOpen={this.props.isOpen}
          onClose={() => {
            this._onClose();
            this.props.onClose();
          }}
          width="x-large"
        >
          <div className="anim-slide-left">
            <Text className="mt-large">
              {modalMode === 'ADD'
                ? 'Please enter the external invoice details below.'
                : 'Please edit the invoice details below.'}
            </Text>
            <Row gutter={16} className="mt-large">
              <Col span={12}>
                <FieldLabel text="CUSTOMER" />
                {modalMode === 'EDIT' || isAutoGenerated ? (
                  <Text size="large">
                    {selectedPlanManagementInvoiceDetails.firstName} {selectedPlanManagementInvoiceDetails.lastName}
                  </Text>
                ) : (
                  <>
                    <Select
                      placeholder="Select..."
                      className="mr-x4-large width-full"
                      size={'large'}
                      showSearch={true}
                      defaultValue={selectedCustomerId ? selectedCustomerId : undefined}
                      disabled={!!selectedCustomerId}
                      filterOption
                      optionFilterProp={'children'}
                      onChange={(value) => {
                        this._resetErrorMessages();
                        this.setState({ selectedCustomerId: value.toString() });
                      }}
                    >
                      {_.map(this.props.planManagementCustomers, (customer) => {
                        return (
                          <Select.Option value={customer.userId}>
                            {customer.firstName} {customer.lastName}
                          </Select.Option>
                        );
                      })}
                    </Select>

                    {this.state.showSelectedCustomerIdError && <Text color="red">Please select a customer</Text>}
                  </>
                )}
              </Col>
              <Col span={12} />
            </Row>
            <Row gutter={16} className="mt-x-large">
              <Col span={12}>
                <FieldLabel text="PROVIDER" />
                {modalMode === 'EDIT' || isAutoGenerated ? (
                  <Text size="large">{selectedPlanManagementInvoiceDetails.supplierName}</Text>
                ) : (
                  <>
                    <Select
                      placeholder="Select..."
                      className="mr-x4-large width-full"
                      size={'large'}
                      showSearch={true}
                      defaultValue={selectedSupplierId ? selectedSupplierId : undefined}
                      disabled={!!selectedSupplierId}
                      filterOption
                      optionFilterProp={'children'}
                      onChange={(value) => {
                        this._resetErrorMessages();
                        this.setState({ selectedSupplierId: value.toString() });
                      }}
                    >
                      {_.map(this.props.planManagementSuppliers, (supplier) => {
                        return (
                          <Select.Option value={supplier.planManagementSupplierId}>
                            {supplier.supplierName}
                          </Select.Option>
                        );
                      })}
                    </Select>
                    {this.state.showSelectedSupplierIdError && <Text color="red">Please select a provider</Text>}
                  </>
                )}
              </Col>
              <Col span={12} />
            </Row>
            <Row gutter={16} className="mt-x-large">
              <Col span={12}>
                <FieldLabel text="INVOICE NUMBER" />
                {isAutoGenerated ? (
                  <Text size="large">{selectedPlanManagementInvoiceDetails.invoiceNumber}</Text>
                ) : (
                  <>
                    <Input
                      className={'inline-block '}
                      size={'large'}
                      onChange={(e) => {
                        this._resetErrorMessages();
                        this.setState({ invoiceNumber: e.target.value });
                      }}
                      value={this.state.invoiceNumber}
                      placeholder={'Input name...'}
                    />
                    {this.state.showInvoiceNumberError && <Text color="red">Please insert an invoice number</Text>}
                  </>
                )}
              </Col>
              <Col span={12} />
            </Row>
            <div className="mt-large">
              <Row gutter={16} className="mt-x-large">
                <Col span={12}>
                  <FieldLabel text="INVOICE ISSUE DATE" />
                  {isAutoGenerated ? (
                    <Text size="large">
                      {moment(selectedPlanManagementInvoiceDetails.invoiceIssueDate).format('DD/MM/YYYY')}
                    </Text>
                  ) : (
                    <>
                      <DatePicker
                        className="gh-datepicker-plan-management rounded "
                        calendarClassName="gh-datepicker-calendar"
                        dateFormat="dd/MM/yyyy"
                        onChange={this._onChangeIssueDate}
                        placeholderText={'Input invoice issue date'}
                        selected={this.state.invoiceIssueDate}
                      />
                      <br />
                      {this.state.showInvoiceIssueDateError && (
                        <Text color="red">Please select an invoice issue date</Text>
                      )}
                    </>
                  )}
                </Col>
                <Col span={12}>
                  <FieldLabel text="INVOICE DUE DATE" />
                  {isAutoGenerated ? (
                    <Text size="large">
                      {moment(selectedPlanManagementInvoiceDetails.invoiceDueDate).format('DD/MM/YYYY')}
                    </Text>
                  ) : (
                    <>
                      <DatePicker
                        className="gh-datepicker-plan-management rounded "
                        calendarClassName="gh-datepicker-calendar"
                        dateFormat="dd/MM/yyyy"
                        minDate={this.state.invoiceIssueDate}
                        onChange={(date) => {
                          this._resetErrorMessages();
                          this.setState({ invoiceDueDate: date });
                        }}
                        placeholderText={'Input invoice due date'}
                        selected={this.state.invoiceDueDate}
                      />
                      <br />
                      {this.state.showInvoiceDueDateError && <Text color="red">Please select an invoice due date</Text>}
                    </>
                  )}
                </Col>
              </Row>
            </div>
            <Row gutter={16} className="mt-x-large">
              <Col span={12}>
                <FieldLabel text={this.state.includesGST ? 'INVOICE TOTAL (EXCLUDING GST)' : 'INVOICE TOTAL'} />

                {isAutoGenerated ? (
                  <Text size="large">{CommonUtils.formatPrice(selectedPlanManagementInvoiceDetails.invoiceTotal)}</Text>
                ) : (
                  <>
                    <NumberInput
                      size={'large'}
                      onChange={(e) => {
                        this._resetErrorMessages();
                        this.setState({ invoiceTotal: e });
                      }}
                      value={this.state.invoiceTotal}
                      precision={2}
                      addonBefore={'$'}
                      placeholder={'Enter total'}
                      style={{ width: '150px' }}
                    />
                    <br />
                    {this.state.showInvoiceTotalError && <Text color="red">{this.state.invoiceTotalErrorString}</Text>}
                  </>
                )}
              </Col>
              <Col span={12}>
                {this.state.includesGST && (
                  <>
                    <FieldLabel text="GST AMOUNT" />
                    {isAutoGenerated ? (
                      <Text size="large">
                        {CommonUtils.formatPrice(selectedPlanManagementInvoiceDetails.gstAmount)}
                      </Text>
                    ) : (
                      <>
                        <NumberInput
                          size={'large'}
                          onChange={(e) => {
                            this._resetErrorMessages();
                            this.setState({ gstAmount: e });
                          }}
                          value={this.state.gstAmount}
                          precision={2}
                          addonBefore={'$'}
                          placeholder={'Enter total'}
                          style={{ width: '150px' }}
                        />
                        <br />
                        {this.state.showGstAmountError && <Text color="red">{this.state.gstAmountErrorString}</Text>}
                      </>
                    )}
                  </>
                )}
              </Col>
            </Row>
            {!isAutoGenerated && (
              <Checkbox
                checked={this.state.includesGST}
                className="mt-small"
                onChange={() => {
                  this._resetErrorMessages();
                  this.setState({ includesGST: !this.state.includesGST });
                }}
              >
                Invoice includes GST
              </Checkbox>
            )}
            <div className="mt-large">
              <FieldLabel text="ATACHMENTS (OPTIONAL)" />
              {this.state.isProgressBarLoading ? (
                <Progress type="circle" percent={this.state.progress} className="mt-medium" />
              ) : _.isEmpty(this.state.selectedFile) ? (
                <>
                  <Upload
                    multiple={false}
                    beforeUpload={(file) => {
                      this.setState({ selectedFile: file });
                      return false;
                    }}
                    showUploadList={false}
                  >
                    <a>Upload an attachment...</a>
                  </Upload>
                </>
              ) : (
                <>
                  <Icon type="file" className="mr-x-small" />
                  {this.state.selectedFile.name}
                  {!isAutoGenerated && (
                    <HyperlinkButton
                      className="ml-medium"
                      onClick={() => this.setState({ selectedFile: null, editNewFile: true, showDocumentError: false })}
                    >
                      Replace
                    </HyperlinkButton>
                  )}
                </>
              )}
              <div>
                {this.state.showDocumentError && <Text color="red">Please upload a PDF, JPG or PNG document</Text>}
              </div>
            </div>
            <div className="mt-large">
              <FieldLabel text="COMMENTS (OPTIONAL)" />
              <TextArea
                style={{ resize: 'none' }}
                value={this.state.additionalComments}
                onChange={(e) => {
                  this._resetErrorMessages();
                  this.setState({ additionalComments: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="flex-row justify-end mt-large">
            <SecondaryButton
              size="large"
              onClick={() => {
                this._onClose();
                this.props.onClose();
              }}
              disabled={this.state.isLoading}
            >
              Cancel
            </SecondaryButton>

            <PrimaryButton
              size="large"
              loading={this.state.isLoading}
              onClick={modalMode === 'EDIT' ? this._onEditInvoice : this._onCreateInvoice}
              iconPosition="right"
              className="ml-medium"
            >
              Save
            </PrimaryButton>
          </div>
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchPlanManagementCustomers: dispatch.planManagementStore.doFetchPlanManagementCustomers,
  doFetchPlanManagementSuppliers: dispatch.planManagementStore.doFetchPlanManagementSuppliers,
  doCreatePlanManagementInvoice: dispatch.planManagementStore.doCreatePlanManagementInvoice,
  doEditPlanManagementInvoice: dispatch.planManagementStore.doEditPlanManagementInvoice,
  doFetchPlanManagementInvoiceDetails: dispatch.planManagementStore.doFetchPlanManagementInvoiceDetails
});

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  planManagementSuppliers: state.planManagementStore.planManagementSuppliers,
  planManagementCustomers: state.planManagementStore.planManagementCustomers,
  selectedPlanManagementInvoiceDetails: state.planManagementStore.selectedPlanManagementInvoiceDetails
});

export default connect(mapState, mapDispatch)(AddEditInvoiceDetailsModal);
