import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, SubTitle } from 'common-components/typography';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/es/form';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { notification, Form } from 'antd';
import { ServiceAgreementStatus } from 'utilities/enum-utils';

interface IChangeStatusServiceAgreementModalProps extends FormComponentProps {
  isOpen: boolean;
  serviceAgreement: any;
  onClose: () => void;
  newStatus: string;
  portalUser: typeof state.authStore.portalUser;
  doUpdateServiceAgreementStatus: typeof dispatch.customersStore.doUpdateServiceAgreementStatus;
}

interface IChangeStatusServiceAgreementModalState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

class ChangeStatusServiceAgreementModal extends Component<
  IChangeStatusServiceAgreementModalProps,
  IChangeStatusServiceAgreementModalState
> {
  state = {
    isLoading: false,
    isSuccess: false,
    isError: false
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false, isError: false });
      onClose();
    }
  };

  private _onChangeStatus = async () => {
    this.setState({ isLoading: true });
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        await this.props.doUpdateServiceAgreementStatus({
          serviceAgreementId: this.props.serviceAgreement.userServiceAgreementId,
          signedDate: form.getFieldValue('signedDate')
        });
        notification.success({ message: 'Service agreement status changed successfully.' });
        this.props.onClose();
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { isOpen, serviceAgreement, newStatus, form } = this.props;
    const { getFieldDecorator } = form;
    let title = null;
    let description = null;
    let primaryButtonTitle = '';

    if (newStatus === ServiceAgreementStatus.UNDER_CUSTOMER_REVIEW) {
      title = (
        <>
          Mark Service Agreement as <b>Under review</b>
        </>
      );
      description = (
        <>
          <Paragraph>
            This service agreement will be marked as <b>Under Review.</b>
          </Paragraph>
          <Paragraph>Would you like to proceed?</Paragraph>
        </>
      );
      primaryButtonTitle = 'Mark as Under Review';
    } else if (newStatus === ServiceAgreementStatus.SIGNED) {
      title = (
        <>
          Mark Service Agreement as <b>Signed</b>
        </>
      );
      description = (
        <>
          <Paragraph>
            Mark this agreement as signed if the customer has sent back a signed copy of the service agreement as it
            currently exist.
          </Paragraph>
          <SubTitle>SIGNED DATE</SubTitle>
          <Form.Item>
            {getFieldDecorator('signedDate', {
              initialValue: null,
              valuePropName: 'selected',
              rules: [
                {
                  required: true,
                  message: 'Please select a sign date'
                }
              ]
            })(
              <DatePicker
                className="gh-datepicker rounded"
                calendarClassName="gh-datepicker-calendar"
                dateFormat="d/M/yyyy"
                placeholderText={'Select date'}
                onChange={() => {}}
              />
            )}
          </Form.Item>
        </>
      );
      primaryButtonTitle = 'Save';
    } else if (newStatus === ServiceAgreementStatus.DRAFT) {
      title = (
        <>
          Revert Service Agreement as <b>Draft</b>
        </>
      );
      description = (
        <>
          <Paragraph>
            This service agreement will be marked as <b>Draft.</b>
          </Paragraph>
          <Paragraph>Would you like to proceed?</Paragraph>
        </>
      );
      primaryButtonTitle = 'Revert to Draft';
    }

    return (
      serviceAgreement &&
      title && (
        <ActionModal
          title={title}
          isOpen={isOpen}
          onClose={this._onCloseModal}
          width="large"
          verticalAlignment="center"
        >
          <div className="text-align-left">
            <div className="mb-medium">{description}</div>
          </div>
          <ActionModalFooter>
            <SecondaryButton
              size="large"
              onClick={this._onCloseModal}
              className="mr-medium"
              disabled={this.state.isLoading}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._onChangeStatus} loading={this.state.isLoading}>
              {primaryButtonTitle}
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      )
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateServiceAgreementStatus: dispatch.customersStore.doUpdateServiceAgreementStatus
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IChangeStatusServiceAgreementModalProps>()(ChangeStatusServiceAgreementModal));
