import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { Paragraph, SubTitle, Text, Title, FieldLabel } from 'common-components/typography';
import { Avatar, Col, Divider, Form, Icon, Radio, Row, Switch, Checkbox } from 'antd';
import { HyperlinkButton, IconButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { ErrorSVG } from 'assets/UndrawSVG';
import ActionModal from 'common-components/modal/ActionModal';
import { Warning } from 'common-components/alerts';
import moment from 'moment-timezone';
import { BookingType, EditRecurringMode, FilterType, ServiceType, ShiftSlotStatus } from 'utilities/enum-utils';
import WorkerStatusTag from 'common-components/tags/WorkerStatusTag';
import SelectWorkerSearchModal from 'views/bookings/components/SelectWorkerSearchModal';
import FoundWorkerRow from 'views/bookings/components/FoundWorkerRow';
import { FilterSection } from 'common-components/filter';
import { Popover } from '@blueprintjs/core';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import ShiftSlotStatusTag from 'common-components/tags/ShiftSlotStatusTag';
import { ConflictResult } from 'views/bookings/components/BookingConfictItem';
import * as H from 'history';
import DatePicker from 'react-datepicker';
import TimeInput from 'common-components/time-input/TimeInput';
import CommonUtils from 'utilities/common-utils';
import AvailabilityConflictTimes from 'views/team/details/tabs-panel/availability/components/AvailablilityConflictTimes';

interface IBookingAssignWorkerModalProps extends FormComponentProps {
  isOpen: boolean;
  onClose: (refreshData?: boolean) => void;
  onSubmitAssign: (
    selectedWorker: any,
    editRecurringMode: any,
    shiftSlotStatus: ShiftSlotStatus,
    isRemovePendingShiftSlots: boolean,
    startDateTime: Date | any,
    endDateTime: Date | any,
    conflicts?: any,
    bookingOutSideToBeKept?: any,
    bookingOutsideAvailability?: any,
  ) => void;
  serviceId: string;
  bookingId?: string;
  bookingRequestId?: string;
  doFetchServiceRoster: typeof dispatch.bookingsStore.doFetchServiceRoster;
  doCheckWorker: typeof dispatch.bookingsStore.doCheckWorker;
  doCheckAssignWorker: typeof dispatch.bookingsStore.doCheckAssignWorker;
  isRecurring?: boolean;
  sessions?: any;
  address?: any;
  displayTimezone?: any;
  isEditingBooking?: boolean;
  startDateTime?: Date;
  endDateTime?: Date;
  customerUserIds?: Array<string>;
  noValidationStep?: boolean;
  isAssignedTeamMemberConfirmed?: boolean;
  history: H.History;
  bookingType: string;
}

interface IBookingAssignWorkerModalState {
  error: any;
  selectedWorker: any;
  step: number;
  now: any;
  conflicts: any;
  pendingShiftConflicts: any;
  confirmedShiftConflicts: any;
  selectedOption: number;
  conflictErrorMessage: string;
  isSearchOpen: boolean;
  isLoadingFoundWorkers: boolean;
  filteredWorkers: any;
  activeFilters: {
    previouslyUsed: boolean;
    isSkillMatched: boolean;
    isAvailable: boolean;
    isCustomerPreferred: boolean;
  };
  listFilters: any;
  isLoading: boolean;
  shiftSlotStatus: ShiftSlotStatus;
  isRemovePendingShiftSlots: boolean;
  addTimesheet: any;
  workerTimesheetStartTime: Date;
  workerTimesheetEndTime: Date;
  scheduledRemoveConflict: any;
  showUnavailableBookings: boolean;
  shiftsOutsideGeneralAvailability: any;
  selectedShiftOutSideCanBeAssign: any;
}

const availableFilters = [
  FilterType.QUALIFICATIONS,
  FilterType.RELIGIONS,
  FilterType.GENDER,
  FilterType.SKILLS,
  FilterType.LANGUAGES,
  FilterType.INTEREST,
];

class BookingAssignWorkerModal extends Component<IBookingAssignWorkerModalProps, IBookingAssignWorkerModalState> {
  state = {
    error: null,
    selectedWorker: null,
    step: 1,
    now: new Date(),
    conflicts: null,
    pendingShiftConflicts: null,
    confirmedShiftConflicts: null,
    selectedOption: EditRecurringMode.Current,
    conflictErrorMessage: null,
    isSearchOpen: false,
    isLoadingFoundWorkers: false,
    filteredWorkers: [],
    activeFilters: {
      previouslyUsed: false,
      isSkillMatched: false,
      isAvailable: true,
      isCustomerPreferred: false,
    },
    listFilters: null,
    isLoading: false,
    shiftSlotStatus: ShiftSlotStatus.CONFIRMED,
    isRemovePendingShiftSlots: null,
    addTimesheet: null,
    workerTimesheetStartTime: moment(
      moment.tz(this.props.startDateTime, this.props.displayTimezone).format('YYYY-MM-DD HH:mm'),
    ).toDate(),
    workerTimesheetEndTime: moment(
      moment.tz(this.props.endDateTime, this.props.displayTimezone).format('YYYY-MM-DD HH:mm'),
    ).toDate(),
    scheduledRemoveConflict: null,
    showUnavailableBookings: false,
    shiftsOutsideGeneralAvailability: null,
    selectedShiftOutSideCanBeAssign: [],
  };

  private _onClose = async () => {
    const { onClose, startDateTime, endDateTime } = this.props;
    const refreshData = this.state.step === 4 || this.state.step === 12;
    this.setState({
      error: null,
      step: 2,
      conflicts: null,
      activeFilters: {
        previouslyUsed: false,
        isSkillMatched: false,
        isAvailable: true,
        isCustomerPreferred: false,
      },
      pendingShiftConflicts: null,
      confirmedShiftConflicts: null,
      shiftSlotStatus: ShiftSlotStatus.CONFIRMED,
      isRemovePendingShiftSlots: null,
      workerTimesheetStartTime: moment(
        moment.tz(startDateTime, this.props.displayTimezone).format('YYYY-MM-DD HH:mm'),
      ).toDate(),
      workerTimesheetEndTime: moment(
        moment.tz(endDateTime, this.props.displayTimezone).format('YYYY-MM-DD HH:mm'),
      ).toDate(),
      scheduledRemoveConflict: null,
      showUnavailableBookings: false,
    });

    onClose(refreshData);
  };

  private _onSelectWorker = (selectedWorker) => {
    this.setState({ selectedWorker });
  };

  private _onCancelChoose = () => {
    this.setState({ step: 2, selectedWorker: null });
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _onContinue = (isRemovePendingShiftSlots) => {
    const { pendingShiftConflicts } = this.state;
    if (pendingShiftConflicts && pendingShiftConflicts.length > 0) {
      this.setState({ step: 10 });
    } else {
      this._onSubmitAssign(isRemovePendingShiftSlots);
    }
  };

  private _checkForConflict = async () => {
    const {
      doCheckWorker,
      doCheckAssignWorker,
      isEditingBooking,
      bookingRequestId,
      startDateTime,
      sessions,
      serviceId,
      bookingId,
      address,
      isRecurring,
      bookingType,
      customerUserIds,
    } = this.props;

    this.setState({ isLoading: true });

    try {
      const result: any = isEditingBooking
        ? await doCheckAssignWorker({
            bookingRequestId,
            bookingId,
            editRecurringMode: this.state.selectedOption,
            workerId: this.state.selectedWorker.supportWorkerId,
            startDateTime,
          })
        : bookingType === BookingType.BOOKING
        ? await doCheckWorker({
            serviceId: serviceId,
            workerId: this.state.selectedWorker.supportWorkerId,
            address: address ? { ...address, geoLat: Number(address.geoLat), geoLng: Number(address.geoLng) } : null,
            sessions: sessions,
            customerUserId: customerUserIds && customerUserIds[0],
          })
        : { data: {} };

      const isSingleBooking = this._checkIsSingleBooking();

      const shiftsOutsideGeneralAvailability = result.data.shiftsOutsideGeneralAvailability;
      if (isRecurring && result) {
        const data = result.data.sessionsAfterRemovedWorker || result.data.shiftsAfterRemovedWorker;
        const conflicts = data.sessions || data.shiftSlots || [];

        if (conflicts.length > 0) {
          if (this.state.selectedOption === 1 && isEditingBooking) {
            this.setState({
              step: 15,
              isLoading: false,
              confirmedShiftConflicts: result.data.confirmedShiftConflicts,
              pendingShiftConflicts: result.data.pendingShiftConflicts,
              scheduledRemoveConflict: data,
            });
          } else {
            this.setState({
              step: 14,
              isLoading: false,
              confirmedShiftConflicts: result.data.confirmedShiftConflicts,
              pendingShiftConflicts: result.data.pendingShiftConflicts,
              scheduledRemoveConflict: data,
            });
          }
        } else if (
          shiftsOutsideGeneralAvailability &&
          shiftsOutsideGeneralAvailability.length > 0 &&
          !isSingleBooking
        ) {
          this.setState({
            step: 16,
            shiftsOutsideGeneralAvailability,
            isLoading: false,
          });
        } else {
          this._onSubmitAssign(this.state.shiftSlotStatus === ShiftSlotStatus.CONFIRMED, result.data.conflicts);
        }
      } else if (
        !isRecurring &&
        result &&
        result.data.pendingShiftConflicts &&
        result.data.pendingShiftConflicts.length > 0
      ) {
        this.setState({ pendingShiftConflicts: result.data.pendingShiftConflicts, step: 7, isLoading: false });
      } else if (result && result.data.confirmedShiftConflicts && result.data.confirmedShiftConflicts.length > 0) {
        this.setState({
          confirmedShiftConflicts: result.data.confirmedShiftConflicts,
          pendingShiftConflicts: result.data.pendingShiftConflicts,
          step: 9,
          isLoading: false,
        });
      } else if (result && result.data.pendingShiftConflicts && result.data.pendingShiftConflicts.length > 0) {
        this.setState({
          confirmedShiftConflicts: result.data.confirmedShiftConflicts,
          pendingShiftConflicts: result.data.pendingShiftConflicts,
          step: 10,
          isLoading: false,
        });
      } else if (
        result &&
        result.data.shiftsOutsideGeneralAvailability &&
        result.data.shiftsOutsideGeneralAvailability.length > 0 &&
        !isSingleBooking
      ) {
        this.setState({
          step: 16,
          shiftsOutsideGeneralAvailability: result.data.shiftsOutsideGeneralAvailability,
          isLoading: false,
        });
      } else {
        this._onSubmitAssign(this.state.shiftSlotStatus === ShiftSlotStatus.CONFIRMED, result.data.conflicts);
      }
    } catch (e) {
      this.setState({ step: 5 });
    }
  };

  private _goToBooking = (bookingId) => {
    const { history } = this.props;
    history.push(`/bookings/details/${bookingId}`);
  };

  private _goToSession = (serviceId, serviceDateTime) => {
    const { history } = this.props;
    history.push(`/group-service/${serviceId}/session/details/${serviceDateTime}`);
  };

  private _checkIsSingleBooking = () => {
    const { isRecurring, isEditingBooking } = this.props;
    const { selectedOption } = this.state;

    return isEditingBooking ? selectedOption === EditRecurringMode.Current : !isRecurring;
  };

  private _onSubmitAssign = async (isRemovePendingShiftSlots = null, conflicts = null) => {
    this.setState({ isLoading: true, isRemovePendingShiftSlots });
    const isSingleBooking = this._checkIsSingleBooking();
    try {
      const bookingsOutsideCanBeAssign = !_.isEmpty(this.state.shiftsOutsideGeneralAvailability)
        ? isSingleBooking
          ? [_.first(this.state.shiftsOutsideGeneralAvailability)]
          : this.state.selectedShiftOutSideCanBeAssign
        : [];
      await this.props.onSubmitAssign(
        this.state.selectedWorker,
        this.state.selectedOption,
        this.state.shiftSlotStatus,
        isRemovePendingShiftSlots
          ? isRemovePendingShiftSlots
          : this.state.shiftSlotStatus === ShiftSlotStatus.CONFIRMED,
        moment.tz(moment(this.state.workerTimesheetStartTime).format('YYYY-MM-DD HH:mm'), this.props.displayTimezone),
        moment.tz(moment(this.state.workerTimesheetEndTime).format('YYYY-MM-DD HH:mm'), this.props.displayTimezone),
        conflicts,
        bookingsOutsideCanBeAssign,
        this.state.shiftsOutsideGeneralAvailability,
      );
      this.props.noValidationStep
        ? this._onClose()
        : this.props.bookingType === BookingType.BOOKING
        ? // ? this.setState({ step: 4 })
          this._onClose() // this will not display successful modal anymore, temporary solution
        : this.setState({ step: 12 });
    } catch (e) {
      if (e.meta.message === 'Cannot assign worker, worker conflicts exist') {
        this.setState({
          conflictErrorMessage: 'This Team member is already assigned to another booking and cannot be selected.',
          step: 8,
        });
      } else if (e.meta.message.includes('Cannot assign worker,')) {
        this.setState({
          conflictErrorMessage:
            'This Team member is already assigned to a completed or in progress booking, please select another Team member.',
          step: 8,
        });
      } else {
        this.setState({ step: 5 });
      }
      this.setState({ isLoading: false });
    }
  };

  private _onOpenSearchModal = () => {
    this.setState({ selectedWorker: null, isSearchOpen: true });
  };

  private _onCloseSearchModal = () => {
    this.setState({ isSearchOpen: false });
  };

  private _onChangeListFilter = (filters: Array<any>) => {
    this.setState({ listFilters: filters });
  };

  private _onAssignWorker = async (shiftSlotStatus) => {
    this.setState({ shiftSlotStatus });
    if (this.props.isRecurring && this.props.isEditingBooking) {
      this.setState({ step: 6 });
    } else {
      await this._checkForConflict();
    }
  };

  private _ChooseNewWorker = () => {
    this.setState({ error: null, selectedWorker: null, step: 2, conflicts: null });
  };

  private _onChangeAddTimesheet = (e) => {
    this.setState({ addTimesheet: e.target.value });
  };

  private _onOpenAddTimesheet = () => {
    this.setState({ step: 11 });
  };

  private _onOpenEditTimesheet = () => {
    this.setState({ step: 13, addTimesheet: null, shiftSlotStatus: ShiftSlotStatus.CHECKED_OUT });
  };

  private _onChangeFilter = (filterLabel, newValue) => {
    this.setState({ activeFilters: { ...this.state.activeFilters, [filterLabel]: newValue } });
  };

  private _onChangeActivityDate = (date) => {
    const { workerTimesheetStartTime, workerTimesheetEndTime } = this.state;
    const newStartDate = moment(date)
      .set('hour', moment(workerTimesheetStartTime).hour())
      .set('minute', moment(workerTimesheetStartTime).minute());

    const newEndDate = moment(date)
      .set('hour', moment(workerTimesheetEndTime).hour())
      .set('minute', moment(workerTimesheetEndTime).minute());

    this.setState({ workerTimesheetStartTime: newStartDate.toDate(), workerTimesheetEndTime: newEndDate.toDate() });
  };
  private _onChangeActivityStartTime = (date) => {
    const newStartTime = CommonUtils.formatCeilingDateTime(moment(date));
    if (newStartTime.isAfter(moment(this.state.workerTimesheetEndTime))) {
      this.setState({
        workerTimesheetEndTime: moment(newStartTime)
          .add(1, 'hour')
          .toDate(),
      });
    }

    this.setState({
      workerTimesheetStartTime: newStartTime.toDate(),
    });
  };
  private _onChangeActivityEndTime = (date) => {
    let newEndTime = CommonUtils.formatCeilingDateTime(moment(date));
    if (newEndTime.isBefore(moment(this.state.workerTimesheetStartTime))) {
      newEndTime = moment(this.state.workerTimesheetStartTime).add(1, 'hour');
    }
    this.setState({
      workerTimesheetEndTime: newEndTime.toDate(),
    });
  };

  private _formatFilterQuery = () => {
    const requestFilter: any = {};
    _.forEach(this.state.listFilters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        requestFilter[filter.filter] = filter.values;
      }
    });
    return requestFilter;
  };

  private _applyFilter = async () => {
    const { doFetchServiceRoster, startDateTime, endDateTime, address, customerUserIds, serviceId } = this.props;
    this.setState({ isLoadingFoundWorkers: true });
    const requestFilters = this._formatFilterQuery();
    const filteredWorkers = await doFetchServiceRoster({
      startDateTime,
      endDateTime,
      address,
      customerUserIds,
      serviceId,
      ...this.state.activeFilters,
      ...requestFilters,
    });
    this.setState({ isLoadingFoundWorkers: false, filteredWorkers });
  };

  private _showUnavailableBookings = () => {
    this.setState({ showUnavailableBookings: !this.state.showUnavailableBookings });
  };

  private _onChangeSelectedShiftToBeKept = (shifts) => {
    this.setState({ selectedShiftOutSideCanBeAssign: shifts });
  };

  async componentDidMount() {
    const {
      serviceId,
      startDateTime,
      endDateTime,
      address,
      customerUserIds,
      doFetchServiceRoster,
      bookingType,
    } = this.props;
    this.setState({ step: 1 });
    try {
      if (bookingType === BookingType.ACTIVITY_RECORD) {
        const result: any = await doFetchServiceRoster({
          address,
          customerUserIds,
          serviceId,
          ...this.state.activeFilters,
        });
        this.setState({ step: 2, filteredWorkers: result });
      } else {
        const result: any = await doFetchServiceRoster({
          startDateTime,
          endDateTime,
          address,
          customerUserIds,
          serviceId,
          ...this.state.activeFilters,
        });
        this.setState({ step: 2, filteredWorkers: result });
      }
    } catch (e) {
      this.setState({ step: 5 });
    }
  }

  componentDidUpdate = async (
    prevProps: Readonly<IBookingAssignWorkerModalProps>,
    prevState: Readonly<IBookingAssignWorkerModalState>,
    snapshot?: any,
  ) => {
    const {
      startDateTime,
      endDateTime,
      address,
      customerUserIds,
      serviceId,
      doFetchServiceRoster,
      bookingType,
    } = this.props;
    const { activeFilters } = this.state;
    if (prevState.activeFilters !== activeFilters) {
      this.setState({ isLoadingFoundWorkers: true });
      if (bookingType === BookingType.ACTIVITY_RECORD) {
        const filteredWorkers = await doFetchServiceRoster({
          address,
          customerUserIds,
          serviceId,
          ...activeFilters,
        });
        this.setState({ isLoadingFoundWorkers: false, filteredWorkers });
      } else {
        const filteredWorkers = await doFetchServiceRoster({
          startDateTime,
          endDateTime,
          address,
          customerUserIds,
          serviceId,
          ...activeFilters,
        });
        this.setState({ isLoadingFoundWorkers: false, filteredWorkers });
      }
    }
    if (prevState.listFilters !== this.state.listFilters) {
      await this._applyFilter();
    }
  };

  renderContent = () => {
    const { displayTimezone, isRecurring, isEditingBooking, form } = this.props;
    const {
      selectedWorker,
      isSearchOpen,
      isLoadingFoundWorkers,
      filteredWorkers,
      activeFilters,
      listFilters,
      isLoading,
      isRemovePendingShiftSlots,
      confirmedShiftConflicts,
      pendingShiftConflicts,
      scheduledRemoveConflict,
      shiftsOutsideGeneralAvailability,
    } = this.state;

    const isShiftStatusPending = this.state.shiftSlotStatus === ShiftSlotStatus.PENDING;

    if (this.state.step === 1) {
      return (
        <>
          <Paragraph>
            Which support worker would you like to assign to this <b>booking</b>?
          </Paragraph>
          <div className="mt-x5-large">
            <SpinningLoader size={150} message={'Fetching support worker data ...'} />
          </div>
        </>
      );
    }
    if (this.state.step === 2) {
      return (
        <>
          <div>
            <Paragraph className="mb-large">
              Filter and select a team member for this{' '}
              {this.props.bookingType === BookingType.BOOKING ? 'booking' : 'activity record'}.
              {this.props.isAssignedTeamMemberConfirmed && ' Once assigned, the team member will become confirmed.'}
            </Paragraph>
          </div>
          <SelectWorkerSearchModal
            isOpen={isSearchOpen}
            selectedWorker={selectedWorker}
            bookingId={this.props.bookingId}
            startDateTime={this.props.startDateTime}
            endDateTime={this.props.endDateTime}
            address={this.props.address}
            customerUserIds={this.props.customerUserIds}
            serviceId={this.props.serviceId}
            onCloseSearchModal={this._onCloseSearchModal}
            selectWorker={this._onSelectWorker}
            onAssignWorker={this._onAssignWorker}
          />
          <table className="width-full">
            <tbody>
              <tr style={{ verticalAlign: 'top' }}>
                <td style={{ width: '40%' }}>
                  <div className="bg-tertiary p-medium mb-large">
                    <Title level={4} className={'mb-x-small'}>
                      Filters
                    </Title>
                    <Text>Show team members who...</Text>
                    <div className="ph-small pv-small">
                      <div>
                        <Switch
                          checked={activeFilters && activeFilters.isAvailable}
                          checkedChildren={<Icon type="check" />}
                          unCheckedChildren={<Icon type="close" />}
                          size={'small'}
                          className={'mr-x-small'}
                          onChange={(e) => this._onChangeFilter('isAvailable', e)}
                        />{' '}
                        <Text>Are available during the scheduled booking time</Text>
                      </div>
                      <div>
                        <Switch
                          checked={activeFilters && activeFilters.isSkillMatched}
                          size={'small'}
                          checkedChildren={<Icon type="check" />}
                          unCheckedChildren={<Icon type="close" />}
                          className={'mr-x-small'}
                          onChange={(e) => this._onChangeFilter('isSkillMatched', e)}
                        />{' '}
                        <Text>Has skills that match the customer(s) care needs</Text>
                      </div>
                      <div>
                        <Switch
                          checked={activeFilters && activeFilters.previouslyUsed}
                          size={'small'}
                          checkedChildren={<Icon type="check" />}
                          unCheckedChildren={<Icon type="close" />}
                          className={'mr-x-small'}
                          onChange={(e) => this._onChangeFilter('previouslyUsed', e)}
                        />{' '}
                        <Text>Have worked with the customer(s) before</Text>
                      </div>
                      <div>
                        <Switch
                          checked={activeFilters && activeFilters.isCustomerPreferred}
                          size={'small'}
                          checkedChildren={<Icon type="check" />}
                          unCheckedChildren={<Icon type="close" />}
                          className={'mr-x-small'}
                          onChange={(e) => this._onChangeFilter('isCustomerPreferred', e)}
                        />{' '}
                        <Text>Are preferred by the customer</Text>
                      </div>
                      <FilterSection
                        availableFilters={availableFilters}
                        filters={listFilters}
                        onChangeFilter={this._onChangeListFilter}
                        displayTimezone={null}
                        usePortal={false}
                        verticalDisplay={true}
                      />
                    </div>
                  </div>
                </td>
                <td style={{ width: '35px' }} />
                <td className="p-medium">
                  <div className="mb-x-small">
                    <div className="flex-row justify-between align-center ">
                      <Title level={4}>Team members on roster ({filteredWorkers ? filteredWorkers.length : 0})</Title>
                      <HyperlinkButton onClick={this._onOpenSearchModal}>
                        <Icon type="search" className="mr-x-small" />
                        Find Team member
                      </HyperlinkButton>
                    </div>
                    <div style={{ minHeight: '300px', height: 'calc(100vh - 600px)', overflow: 'auto' }}>
                      {this.state.isLoadingFoundWorkers && <SpinningLoader size={100} message={'Loading'} />}
                      {_.isEmpty(filteredWorkers) && !isLoadingFoundWorkers && (
                        <div className="mt-x2-large text-align-center" style={{ margin: 'auto' }}>
                          <Text weight="bold">No Team member found</Text>
                          <br />
                          <Text>Select a filter or find Team member to select.</Text>
                        </div>
                      )}
                      {filteredWorkers && filteredWorkers.length > 0 && !isLoadingFoundWorkers && (
                        <table className="width-full">
                          <tbody>
                            {_.map(filteredWorkers, (worker) => {
                              return (
                                <FoundWorkerRow
                                  worker={worker}
                                  isSelected={
                                    selectedWorker && worker.supportWorkerId === selectedWorker.supportWorkerId
                                  }
                                  isSearch={false}
                                  selectWorker={this._onSelectWorker}
                                />
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                    {this.props.bookingType === BookingType.ACTIVITY_RECORD ? (
                      <div className="flex-row justify-end mt-large">
                        <PrimaryButton
                          disabled={!selectedWorker}
                          onClick={
                            !isEditingBooking
                              ? () => this._onAssignWorker(ShiftSlotStatus.CONFIRMED)
                              : () => this._onOpenAddTimesheet()
                          }
                          size="large"
                          className="ml-large"
                        >
                          Assign Team member
                        </PrimaryButton>
                      </div>
                    ) : (
                      <div className="flex-row justify-end mt-large">
                        {!isEditingBooking && isRecurring ? (
                          <PrimaryButton
                            disabled={!selectedWorker}
                            onClick={() => this._onAssignWorker(ShiftSlotStatus.CONFIRMED)}
                            size="large"
                            className="ml-large"
                          >
                            Assign Team member
                          </PrimaryButton>
                        ) : (
                          <>
                            <PrimaryButton
                              disabled={!selectedWorker}
                              onClick={() => this._onAssignWorker(ShiftSlotStatus.CONFIRMED)}
                              size="large"
                              className="ml-large"
                            >
                              Assign as Confirmed
                            </PrimaryButton>

                            <Popover
                              content={
                                <ActionMenu>
                                  <ActionMenuItem
                                    text="Assign as pending"
                                    onClick={() => this._onAssignWorker(ShiftSlotStatus.PENDING)}
                                  />
                                </ActionMenu>
                              }
                              position="bottom-left"
                              usePortal={false}
                              disabled={!selectedWorker}
                            >
                              <IconButton
                                className="rounded-right ml-x2-small"
                                icon="down"
                                size="large"
                                disabled={!selectedWorker}
                              />
                            </Popover>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      );
    }
    if (this.state.step === 3) {
      return <SpinningLoader size={150} message={'Assigning worker ... '} />;
    }
    if (this.state.step === 4) {
      return (
        <div style={{ minHeight: 317 }} className="flex-colum">
          <div className="flex-column">
            <Paragraph>
              {`You have successfully assigned ${selectedWorker.firstName +
                ' ' +
                selectedWorker.lastName} to this shift${!isShiftStatusPending ? ' as confirmed' : ''}`}
              {isRemovePendingShiftSlots ? ' and removed them from any conflicting shifts' : ''}
              {this.props.isRecurring &&
                this.state.selectedOption === EditRecurringMode.CurrentAll &&
                ', and all following bookings in this recurring series where there were no workers assigned'}
              .
            </Paragraph>
            {isShiftStatusPending && (
              <Paragraph>
                {selectedWorker.firstName + ' ' + selectedWorker.lastName} will have to confirm this shift, or have the
                shift confirmed on her behalf before the booking can be started.
              </Paragraph>
            )}
          </div>
          <div className="text-align-center pt-large pb-x-large">
            <div className="mt-medium">
              <Avatar icon="user" size={120} className="mb-small" shape="square" src={selectedWorker.attachmentUrl} />
              <br />
              <b>
                <Text size="x-large">
                  {selectedWorker.firstName} {selectedWorker.lastName}
                </Text>
              </b>
              <div className="mt-small">
                <WorkerStatusTag
                  shiftSlotStatus={!isShiftStatusPending ? ShiftSlotStatus.CONFIRMED : ShiftSlotStatus.PENDING}
                  size="x-large"
                />
              </div>
            </div>
          </div>

          <div className="text-align-right">
            <PrimaryButton size="large" shape="rounded" onClick={this._onClose}>
              Close
            </PrimaryButton>
          </div>
        </div>
      );
    }
    if (this.state.step === 5) {
      return (
        <div style={{ minHeight: 317 }} className="flex-column justify-center text-align-center">
          <div className="pv-medium flex-column justify-center">
            <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
          </div>

          <div className="mb-medium flex-column justify-center ">
            <Paragraph>Oops something went wrong, please try again.</Paragraph>
          </div>
        </div>
      );
    }
    if (this.state.step === 8) {
      return (
        <>
          <div className="mt-large mb-x2-large">
            <Warning
              content={
                this.state.conflictErrorMessage
                  ? this.state.conflictErrorMessage
                  : 'Oops! Something  went wrong, please try again.'
              }
            />
          </div>
          <div className={'mt-large'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._ChooseNewWorker}>
                Choose another worker
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (this.state.step === 6) {
      return (
        <>
          <div className="anim-slide-left">
            <Paragraph>
              The booking you are editing is part of a recurring booking series. Please select one of the following
              options for editing this bookings.
            </Paragraph>
          </div>
          <div>
            <Radio.Group value={this.state.selectedOption} onChange={this._onChangeOption} className="ml-medium">
              <Radio
                value={EditRecurringMode.Current}
                className={`${this.state.selectedOption === EditRecurringMode.Current && 'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Select {selectedWorker.firstName + ' ' + selectedWorker.lastName} as the worker for this booking only.
                </div>
              </Radio>
              <br />
              <Radio
                value={EditRecurringMode.CurrentAll}
                className={`${this.state.selectedOption === EditRecurringMode.CurrentAll &&
                  'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Select {selectedWorker.firstName + ' ' + selectedWorker.lastName} as the worker for this booking and
                  all following bookings where there is no worker assigned.
                </div>
              </Radio>
              <br />
              <Radio
                value={EditRecurringMode.Upcoming}
                className={`${this.state.selectedOption === EditRecurringMode.Upcoming &&
                  'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Select {selectedWorker.firstName + ' ' + selectedWorker.lastName} as the worker for this booking and
                  all upcoming bookings where there is no worker assigned.
                </div>
              </Radio>
            </Radio.Group>
          </div>
          <div className={'mt-large'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" disabled={isLoading} size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" loading={isLoading} onClick={this._checkForConflict}>
                Continue
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (this.state.step === 7) {
      const { startDateTime, endDateTime } = this.props;
      const isShiftSlotPending = this.state.shiftSlotStatus === ShiftSlotStatus.PENDING;
      return (
        <>
          <div className="mb-medium">
            <Paragraph>
              The team member you have selected is assigned to the following shift(s), that occur at the same time as
              the booking you are attempting to assign them to.
            </Paragraph>
            {isShiftSlotPending ? (
              <Paragraph>
                As these shift(s) are still <b>pending team member confirmation</b>, you can still assign the team
                member to the selected shift.
              </Paragraph>
            ) : (
              <Paragraph>
                As these shift(s) are still <b>pending team member confirmation</b>, assigning the team member to the
                selected booking will remove them from the conflicting shift(s) listed below.
              </Paragraph>
            )}
            <div className={'mt-medium'}>
              <SubTitle>Selected booking</SubTitle>
              {moment.tz(startDateTime, displayTimezone).format('DD MMMM YYYY')}
              <br />
              {moment.tz(startDateTime, displayTimezone).format('HH:mm A')} -{' '}
              {moment.tz(endDateTime, displayTimezone).format('HH:mm A')}
            </div>
          </div>
          <Row className="text-color-secondary text-uppercase pt-medium">
            <Col span={12} className="ph-medium">
              <SubTitle>Conflicting session/Booking</SubTitle>
            </Col>
            <Col span={12} className="ph-medium">
              <SubTitle>Team member status</SubTitle>
            </Col>
          </Row>
          <Divider className="mv-medium mb-none" />
          <div style={{ overflow: 'auto', maxHeight: '35vh' }} className={'mb-large'}>
            {_.map(pendingShiftConflicts, (conflict) => (
              <Row className="pv-large evenodd" type={'flex'} align={'middle'}>
                <Col span={12} className="ph-medium height-full">
                  <div>
                    <HyperlinkButton
                      color={'red-dark'}
                      weight={'bold'}
                      onClick={
                        conflict.conflictShift.serviceType === ServiceType.INDIVIDUAL
                          ? () => this._goToBooking(conflict.conflictShift.attendanceId)
                          : () =>
                              this._goToSession(
                                conflict.conflictShift.serviceId,
                                conflict.conflictShift.serviceDateTimeId,
                              )
                      }
                    >
                      {conflict.conflictShift.serviceName}
                    </HyperlinkButton>
                    <br />
                    <Text>
                      {moment.tz(conflict.conflictShift.startDateTime, displayTimezone).format('D MMMM YYYY')}
                      <br />
                      {moment.tz(conflict.conflictShift.startDateTime, displayTimezone).format('hh:mm A')} -{' '}
                      {moment.tz(conflict.conflictShift.endDateTime, displayTimezone).format('hh:mm A')}
                    </Text>
                  </div>
                </Col>
                <Col span={12} className="ph-medium height-full">
                  <ShiftSlotStatusTag status={ShiftSlotStatus.PENDING} textSize={'x-large'} />
                </Col>
              </Row>
            ))}
          </div>
          <div className={'mb-small'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              {isShiftSlotPending ? (
                <>
                  <PrimaryButton
                    disabled={!selectedWorker}
                    onClick={() => this._onSubmitAssign(true)}
                    size="large"
                    loading={isLoading}
                  >
                    Continue & remove conflicts
                  </PrimaryButton>

                  <Popover
                    content={
                      <ActionMenu>
                        <ActionMenuItem
                          text="Continue without removing conflicts"
                          onClick={() => this._onSubmitAssign(false)}
                        />
                      </ActionMenu>
                    }
                    position="bottom-left"
                    usePortal={false}
                    disabled={!selectedWorker}
                  >
                    <IconButton
                      className="rounded-right ml-x2-small"
                      icon="down"
                      size="large"
                      disabled={!selectedWorker}
                    />
                  </Popover>
                </>
              ) : (
                <PrimaryButton
                  size="large"
                  loading={isLoading}
                  onClick={() => this._onSubmitAssign(!isShiftStatusPending)}
                >
                  Continue
                </PrimaryButton>
              )}
            </Row>
          </div>
        </>
      );
    }
    if (this.state.step === 9) {
      return (
        <>
          <div className="mb-medium">
            <Text>
              The selected team member cannot be confirmed for the following shifts as they are <b>confirmed</b> for the
              following shift(s) at the same time.
            </Text>
          </div>
          <Row className="text-color-secondary text-uppercase pt-medium">
            <Col span={12} className="ph-medium">
              <SubTitle>Booking in selected series</SubTitle>
            </Col>
            <Col span={12} className="ph-medium">
              <SubTitle>Conflicting session/booking</SubTitle>
            </Col>
          </Row>
          <Divider className="mv-medium mb-none" />
          <div style={{ overflow: 'auto', maxHeight: '35vh' }} className={'mb-large'}>
            {_.map(confirmedShiftConflicts, (conflict) => (
              <ConflictResult
                conflict={conflict}
                timezone={displayTimezone}
                goToBooking={this._goToBooking}
                goToSession={this._goToSession}
              />
            ))}
          </div>
          <div className={'mb-small'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={() => this._onContinue(!isShiftStatusPending)}>
                Continue
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (this.state.step === 10) {
      return (
        <>
          <div className="mb-medium">
            {isShiftStatusPending ? (
              <>
                <Paragraph>
                  The selected team member is assigned as <b>pending</b> to the following shift(s) that have time
                  conflicts with the shift you are attempting to assign them to.
                </Paragraph>
                <Paragraph>
                  You can optionally remove the team member from the following pending shifts if you wish.
                </Paragraph>
              </>
            ) : (
              <>
                <Paragraph>
                  The team member you have selected is assigned to the following shift(s), that occur at the same time
                  as the booking you are attempting to assign them to.
                </Paragraph>
                <Paragraph>
                  As these shift(s) are still <b>pending team member confirmation</b>, assigning the team member to the
                  selected booking will remove them from the conflicting shift(s) listed below.
                </Paragraph>
              </>
            )}
          </div>
          <Row className="text-color-secondary text-uppercase pt-medium">
            <Col span={12} className="ph-medium">
              <SubTitle>Booking in selected series</SubTitle>
            </Col>
            <Col span={12} className="ph-medium">
              <SubTitle>Conflicting session/booking</SubTitle>
            </Col>
          </Row>
          <Divider className="mv-medium mb-none" />
          <div style={{ overflow: 'auto', maxHeight: '35vh' }} className={'mb-large'}>
            {_.map(pendingShiftConflicts, (conflict) => (
              <ConflictResult
                conflict={conflict}
                timezone={displayTimezone}
                goToBooking={this._goToBooking}
                goToSession={this._goToSession}
              />
            ))}
          </div>
          <div className={'mb-small'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              {isShiftStatusPending ? (
                <>
                  <PrimaryButton onClick={() => this._onSubmitAssign(true)} size="large" loading={this.state.isLoading}>
                    Continue & remove conflicts
                  </PrimaryButton>

                  <Popover
                    content={
                      <ActionMenu>
                        <ActionMenuItem
                          text="Continue without removing conflicts"
                          onClick={() => this._onSubmitAssign(false)}
                        />
                      </ActionMenu>
                    }
                    position="bottom-left"
                    usePortal={false}
                  >
                    <IconButton className="rounded-right ml-x2-small" icon="down" size="large" />
                  </Popover>
                </>
              ) : (
                <PrimaryButton size="large" loading={isLoading} onClick={() => this._onSubmitAssign(true)}>
                  Continue
                </PrimaryButton>
              )}
            </Row>
          </div>
        </>
      );
    }
    if (this.state.step === 11) {
      const { addTimesheet } = this.state;
      return (
        <>
          <div className="mt-small mb">
            <Paragraph>Do you want to add this activity record to the team members timesheet?</Paragraph>
          </div>
          <div>
            <Row>
              <Radio.Group onChange={this._onChangeAddTimesheet}>
                <Radio value={true}>Add hours to timesheet</Radio>
                <Radio className="mt-x-small" value={false}>
                  <b>Do not</b> add hours to timesheet
                </Radio>
              </Radio.Group>
            </Row>
          </div>
          <div className="mt-medium">
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                size="large"
                loading={isLoading}
                disabled={addTimesheet === null}
                onClick={addTimesheet === true ? this._onOpenEditTimesheet : () => this._onSubmitAssign(true)}
              >
                Finish
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (this.state.step === 12) {
      return (
        <>
          <div className="mt-small">
            <Paragraph>
              You have successfully assigned{' '}
              <b>
                {this.state.selectedWorker.firstName} {this.state.selectedWorker.lastName}
              </b>{' '}
              as team member for this {this.props.bookingType === BookingType.BOOKING ? 'booking' : 'activity record'}
              {this.state.shiftSlotStatus === ShiftSlotStatus.CHECKED_OUT &&
                ` and changed the time of this ${
                  this.props.bookingType === BookingType.ACTIVITY_RECORD ? 'activity record' : 'booking'
                }`}
              .
            </Paragraph>
          </div>
        </>
      );
    }
    if (this.state.step === 13) {
      return (
        <>
          <div className="mt-medium">
            <Paragraph>
              In order to add this activity record to the team members timesheet you must indicate a start and finish
              time for the activity.
            </Paragraph>
          </div>
          <div className="mt-medium">
            <SubTitle>ACTIVITY DATE</SubTitle>
            <DatePicker
              onChange={this._onChangeActivityDate}
              selected={moment(this.state.workerTimesheetStartTime).toDate()}
              className="gh-datepicker gh-datepicker-flat rounded"
              calendarClassName="gh-datepicker-calendar"
              dateFormat="dd/MM/yyyy"
              placeholderText={'Select a date'}
            />
          </div>
          <div className="mt-medium">
            <Row type="flex" align="middle" className="mt-medium" gutter={24}>
              <Col span={9}>
                <SubTitle>START TIME </SubTitle>
                <TimeInput
                  size="large"
                  value={moment(this.state.workerTimesheetStartTime)}
                  onChange={this._onChangeActivityStartTime}
                />
              </Col>
              <Col span={9}>
                <SubTitle>END TIME</SubTitle>
                <TimeInput
                  size="large"
                  value={moment(this.state.workerTimesheetEndTime)}
                  onChange={this._onChangeActivityEndTime}
                />
              </Col>
            </Row>
          </div>
          <div className="mt-x3-large">
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" loading={isLoading} onClick={() => this._onSubmitAssign('CHECKED_OUT')}>
                Add to timesheet
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (this.state.step === 14) {
      const removalDate = scheduledRemoveConflict ? scheduledRemoveConflict.removedOn : '';
      const sessions = scheduledRemoveConflict
        ? scheduledRemoveConflict.sessions || scheduledRemoveConflict.shiftSlots
        : [];
      const name = selectedWorker ? selectedWorker.firstName + ' ' + selectedWorker.lastName : '';

      return (
        <>
          <Paragraph>
            The selected team member is unavailable for some booking you are attempting to assign them to. The team
            member will not be assigned to the following
          </Paragraph>
          <div
            className="p-medium bordered border-standard-gray rounded-big bg-quaternary line-height-135 flex-column"
            style={{ maxHeight: '300px', overflow: 'auto' }}
          >
            <div className="flex-row">
              <div className="bg-red-lightest text-color-red text-weight-bold">
                TEAM MEMBER SCHEDULED TO LEAVE COMPANY
              </div>
              <div className="ml-large flex-row">
                <Avatar icon="user" size={25} shape="square" src={selectedWorker.attachmentUrl} />
                <b className="ml-small">
                  <Text className="text-color-tertiary text-size-regular">{name}</Text>
                </b>
              </div>
            </div>
            <Paragraph>
              <Text weight="bold">{name}</Text> is scheduled to leave the company on{' '}
              {moment.tz(removalDate, displayTimezone).format('Do of MMMM')} and will not be assigned to the following{' '}
              <Text weight="bold">
                {sessions.length} booking{sessions.length > 1 ? 's' : ''}
              </Text>
            </Paragraph>
            <HyperlinkButton onClick={this._showUnavailableBookings}>
              {this.state.showUnavailableBookings ? 'Hide bookings...' : 'Show bookings...'}
            </HyperlinkButton>
            {this.state.showUnavailableBookings && (
              <div className="mt-large">
                {sessions.map((session) => (
                  <Paragraph key={session.serviceDateTimeId}>
                    <Text weight="bold">
                      {moment.tz(session.startDateTime, displayTimezone).format('DD MMMM YYYY')}
                    </Text>
                    <Text className="ml-small">
                      {moment.tz(session.startDateTime, displayTimezone).format('HH:mm A')} -{' '}
                      {moment.tz(session.endDateTime, displayTimezone).format('HH:mm A')}
                    </Text>
                  </Paragraph>
                ))}
              </div>
            )}
          </div>
          <div className="mt-x3-large">
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                size="large"
                className="ml-small"
                onClick={() => this._onSubmitAssign(!isShiftStatusPending)}
              >
                Continue
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (this.state.step === 15) {
      const removalDate = scheduledRemoveConflict ? scheduledRemoveConflict.removedOn : '';
      const name = selectedWorker ? selectedWorker.firstName + ' ' + selectedWorker.lastName : '';
      const bookings = scheduledRemoveConflict.sessions || scheduledRemoveConflict.shiftSlots || [];

      return (
        <>
          <Paragraph>
            The selected team member, <Text weight="bold">{name}</Text>, is scheduled to be remove on or before the
            scheduled booking time
          </Paragraph>
          <Paragraph className="mt-small">As a result {name} will not be assigned to this booking</Paragraph>
          <div className="mb-x2-small">
            <FieldLabel text={'TEAM MEMBER SCHEDULE TO BE REMOVE ON...'} />
          </div>
          <Paragraph>{moment.tz(removalDate, displayTimezone).format('DD MMMM YYYY')}</Paragraph>

          <div className="mb-x2-small">
            <FieldLabel text={'BOOKING DATE'} />
          </div>
          <div className="flex-column">
            <Text>{moment.tz(bookings[0].startDateTime, displayTimezone).format('DD MMMM YYYY')}</Text>
            <Text>
              {moment.tz(bookings[0].startDateTime, displayTimezone).format('HH:mm A')} -{' '}
              {moment.tz(bookings[1].endDateTime, displayTimezone).format('HH:mm A')}
            </Text>
          </div>
          <div className="mt-x3-large">
            <Row type={'flex'} justify={'end'}>
              <PrimaryButton size="large" onClick={this._onClose}>
                Confirm
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (this.state.step === 16) {
      const { shiftsOutsideGeneralAvailability, shiftSlotStatus } = this.state;
      const { customerUserIds } = this.props;

      return (
        <>
          <Paragraph>
            The new session time conflicts with the team member’s <Text weight="bold">General availability</Text>.
            Please select which shifts (if any) you wish to keep for the team member or continue without selecting any.
          </Paragraph>
          <div className="bg-quaternary pv-x-large ph-12 mt-x-large">
            <AvailabilityConflictTimes
              conflicts={shiftsOutsideGeneralAvailability}
              timezone={displayTimezone}
              onSelectShifts={this._onChangeSelectedShiftToBeKept}
              shiftSlotStatus={shiftSlotStatus}
            />
          </div>
          <div className="mt-x3-large">
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" className="ml-small" onClick={() => this._onSubmitAssign()}>
                Continue and assign to selected shifts
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
  };

  render() {
    const { isOpen, bookingType } = this.props;
    const { shiftSlotStatus } = this.state;

    return (
      <ActionModal
        title={
          this.state.step === 14 || this.state.step === 16
            ? 'Team member unavailable for bookings'
            : this.state.step === 4 && shiftSlotStatus === ShiftSlotStatus.CONFIRMED
            ? 'Team member assigned and confirmed'
            : this.state.step === 4
            ? 'Team member assigned'
            : this.state.step === 7
            ? 'Team member assigned to conflicting shift(s)'
            : this.state.step === 6
            ? 'Select Workers'
            : this.state.step === 11
            ? 'Add hours to timesheet'
            : this.state.step === 12
            ? 'Team member assigned'
            : this.state.step === 13
            ? 'Edit activity record'
            : bookingType === BookingType.ACTIVITY_RECORD && this.state.step === 2
            ? 'Select team member for this activity record'
            : 'Select team member for this booking'
        }
        isOpen={isOpen}
        width={
          this.state.step === 4 || this.state.step === 7
            ? 'large'
            : this.state.step === 11 || this.state.step === 12
            ? 'small'
            : this.state.step === 13
            ? 'medium'
            : 'x3-large'
        }
        onClose={this._onClose}
      >
        <div>{this.renderContent()}</div>
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServiceRoster: dispatch.bookingsStore.doFetchServiceRoster,
  doCheckWorker: dispatch.bookingsStore.doCheckWorker,
  doCheckAssignWorker: dispatch.bookingsStore.doCheckAssignWorker,
});

export default connect(null, mapDispatch)(Form.create<IBookingAssignWorkerModalProps>()(BookingAssignWorkerModal));
