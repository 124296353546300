import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { notification } from 'antd';
import { Paragraph } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { BookingStatus } from 'utilities/enum-utils';

interface UndoGroupBookingModalProps {
  isOpen: any;
  onClose: any;
  booking: any;
  doUndoGroupBookings: any;
}
interface UndoGroupBookingModalState {
  step: number;
  canManuallyClose: boolean;
  title: string;
  isLoading: boolean;
}

export class UndoGroupBookingModal extends Component<UndoGroupBookingModalProps, UndoGroupBookingModalState> {
  state = { step: 1, canManuallyClose: true, title: '', isLoading: false };

  renderView = () => {
    const { step } = this.state;
    const { booking } = this.props;
    if (step === 1) {
      return (
        <div className="anim-fade-in">
          {booking && booking.status === BookingStatus.INPROGRESS ? (
            <>
              <Paragraph>
                Doing this will return the booking status to <b>Confirmed.</b>
              </Paragraph>
              <Paragraph>
                Any start times recorded by assigned team members will be deleted along with any claims (Travel,
                additional expense etc...).
              </Paragraph>
              <Paragraph>All notes will be retained.</Paragraph>
              <Paragraph>
                Are your sure you want to revert this booking to <b>confirmed.</b>
              </Paragraph>
            </>
          ) : (
            <>
              <Paragraph>
                Undoing a bookings completion will return the booking status for this customer to <b>Confirmed</b>.
              </Paragraph>
              <Paragraph>
                Any start or finish times recorded will be deleted along with any claims (travel, additional expense
                etc...).
              </Paragraph>
              <Paragraph>All notes will be retained.</Paragraph>
              <Paragraph>Are you sure you want to undo this bookings completion?</Paragraph>
            </>
          )}
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal} disabled={this.state.isLoading}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" onClick={this.onAccept} disabled={this.state.isLoading}>
              {booking && booking.status === BookingStatus.INPROGRESS ? 'Revert to confirmed' : 'Undo'}
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <Paragraph>
            {booking && booking.status === BookingStatus.INPROGRESS
              ? 'You have successfully revert this booking to confirmed.'
              : 'You have successfully undone the completion of this booking.'}
          </Paragraph>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({
        step: 1,
        title:
          this.props.booking && this.props.booking.status === BookingStatus.INPROGRESS
            ? 'Revert to confirmed'
            : 'Undo booking completion'
      });
    }
  }

  onAccept = async () => {
    const { booking } = this.props;
    try {
      this.setState({ canManuallyClose: false, isLoading: true });
      await this.props.doUndoGroupBookings({
        serviceId: booking.serviceId,
        serviceDateTimeId: booking.serviceDateTimeId,
        bookingIds: [booking.bookingId]
      });
      this.setState({ step: 2, canManuallyClose: true, title: 'Booking successfully undone', isLoading: false });
    } catch (e) {
      this.setState({ canManuallyClose: true, isLoading: false });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, canManuallyClose: true, title: '' }, onClose);
  };

  render() {
    let { isOpen } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this.renderView()}</div>
      </ActionModal>
    );
  }
}
