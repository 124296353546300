import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';

interface IDepartmentCardProps {
  serviceDepartment: any;
  canArchive?: boolean;
  className?: string;
  editAction?: (department) => void;
  archiveAction?: (department) => void;
  viewAction?: (department) => void;
  canEditServiceDepartments?: boolean;
  canViewServiceDepartments?: boolean;
}

class DepartmentCard extends Component<IDepartmentCardProps, any> {
  state = {};

  render() {
    const {
      serviceDepartment,
      canArchive = false,
      className = '',
      canEditServiceDepartments,
      canViewServiceDepartments
    } = this.props;

    return (
      <div className={`p-medium bordered mb-small ${className}`} style={{ width: '400px', borderRadius: '10px' }}>
        <div>
          <div>
            <Text weight={'bold'}>{serviceDepartment.serviceDepartmentName}</Text>
          </div>
          <div>
            {serviceDepartment.numberOfServices > 0 ? (
              canViewServiceDepartments ? (
                <HyperlinkButton className="mr-medium" onClick={() => this.props.viewAction(serviceDepartment)}>
                  View services ({serviceDepartment.numberOfServices})
                </HyperlinkButton>
              ) : (
                <Text className={'mr-medium'} color={'secondary'}>
                  {serviceDepartment.numberOfServices} service{serviceDepartment.numberOfServices.length !== 1 && 's'}{' '}
                  assigned.
                </Text>
              )
            ) : (
              <Text className={'mr-medium'} color={'secondary'}>
                No services assigned
              </Text>
            )}
            {canEditServiceDepartments && (
              <HyperlinkButton className="mr-medium" onClick={() => this.props.editAction(serviceDepartment)}>
                Edit
              </HyperlinkButton>
            )}
            {canArchive && (
              <HyperlinkButton color={'red'} onClick={() => this.props.archiveAction(serviceDepartment)}>
                Archive
              </HyperlinkButton>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default DepartmentCard;
