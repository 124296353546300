import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SubTitle, Text, Paragraph } from 'common-components/typography';
import { IRootState, state } from 'stores/rematch/root-store';
import { RecurringBookingPattern } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import { IGroupServiceTimeslot } from 'interfaces/service-interfaces';
import { Checkbox, Divider, Form, Input, Select } from 'antd';
import TimeInput from 'common-components/time-input/TimeInput';
import DayPicker from 'common-components/inputs/DayPicker';
import NumberInput from 'common-components/inputs/NumberInput';

interface IAddEditTimeSlotsModalProps extends FormComponentProps {
  isOpen: boolean;
  onCloseModal: () => void;
  onSaveTimeslot: (timeSlot, isEdit) => void;
  isEdit: boolean;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  selectedTimeslot?: IGroupServiceTimeslot;
}

interface IAddEditTimeSlotsModalState {
  isLoading: boolean;
  startDateTime: any;
  endDateTime: any;
  hasFixedCapacity: boolean;
  recurringPattern: string;
  isEndTimeBeforeStartTimeError: boolean;
}

class AddEditTimeSlotsModal extends Component<IAddEditTimeSlotsModalProps, IAddEditTimeSlotsModalState> {
  state = {
    isLoading: false,
    startDateTime: this.props.selectedTimeslot
      ? moment(this.props.selectedTimeslot.startDateTime)
      : moment()
          .set('hours', 8)
          .set('minutes', 0)
          .set('seconds', 0)
          .set('milliseconds', 0),
    endDateTime: this.props.selectedTimeslot
      ? moment(this.props.selectedTimeslot.endDateTime)
      : moment()
          .set('hours', 9)
          .set('minutes', 0)
          .set('seconds', 0)
          .set('milliseconds', 0),
    hasFixedCapacity: this.props.selectedTimeslot
      ? this.props.selectedTimeslot.capacity > 0
      : this.props.selectedGroupService && this.props.selectedGroupService.capacity > 0,
    recurringPattern: this.props.selectedTimeslot ? this.props.selectedTimeslot.recurringPattern : null,
    isEndTimeBeforeStartTimeError: false,
  };

  private _roundDate(date) {
    return moment(CommonUtils.formatCeilingDateTime(date));
  }

  private _onCloseModal = () => {
    this.setState({
      isLoading: false,
      startDateTime: this.props.selectedTimeslot
        ? moment(this.props.selectedTimeslot.startDateTime)
        : moment()
            .set('hours', 8)
            .set('minutes', 0)
            .set('seconds', 0)
            .set('milliseconds', 0),
      endDateTime: this.props.selectedTimeslot
        ? moment(this.props.selectedTimeslot.endDateTime)
        : moment()
            .set('hours', 9)
            .set('minutes', 0)
            .set('seconds', 0)
            .set('milliseconds', 0),
      hasFixedCapacity: this.props.selectedTimeslot
        ? this.props.selectedTimeslot.capacity > 0
        : this.props.selectedGroupService && this.props.selectedGroupService.capacity > 0,
      recurringPattern: this.props.selectedTimeslot ? this.props.selectedTimeslot.recurringPattern : null,
      isEndTimeBeforeStartTimeError: false,
    });
    this.props.onCloseModal();
  };

  private _updateDay = (day) => {
    this.setState({
      startDateTime:
        moment(this.state.startDateTime).day() > day
          ? moment(this.state.startDateTime)
              .add(1, 'week')
              .isoWeekday(day)
          : moment(this.state.startDateTime).isoWeekday(day),
      endDateTime:
        moment(this.state.endDateTime).day() > day
          ? moment(this.state.endDateTime)
              .add(1, 'week')
              .isoWeekday(day)
          : moment(this.state.endDateTime).isoWeekday(day),
    });
  };

  private _onChangeHasFixedCapacity = async (e) => {
    this.setState({ hasFixedCapacity: e.target.checked });
  };

  private _onChangeStartingDate = (startingDate) => {
    const roundedValue = this._roundDate(moment(startingDate));
    let endDateTime = moment(this.state.endDateTime);
    if (endDateTime < roundedValue) {
      endDateTime = moment(roundedValue).add(1, 'hour');
    }
    this.setState({ startDateTime: moment(roundedValue), endDateTime });
  };

  private _onChangeEndingDate = (endingDate) => {
    const roundedValue = this._roundDate(moment(endingDate));
    this.setState({ endDateTime: roundedValue });
  };

  private _changeRecurringPattern = (recurringPattern) => {
    this.setState({ recurringPattern });
  };

  private _onSaveTimeslot = () => {
    const { form } = this.props;
    const { startDateTime, endDateTime } = this.state;

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (!this._checkTimeValidity()) {
      isFormValid = false;
    }

    if (isFormValid) {
      this.setState({ isLoading: true }, () =>
        this.props.onSaveTimeslot(
          {
            startDateTime,
            endDateTime,
            recurringPattern: form.getFieldValue('recurringPattern'),
            capacity: form.getFieldValue('capacity') ? form.getFieldValue('capacity') : null,
            description: form.getFieldValue('description'),
            scheduleTimeSlotId: this.props.isEdit ? this.props.selectedTimeslot.scheduleTimeSlotId : uuidv4(),
            timezone: this.props.selectedGroupService.timezone,
          },
          this.props.isEdit,
        ),
      );
    }
  };

  private _checkTimeValidity = () => {
    let startDateTime = this.state.startDateTime;
    let endDateTime = this.state.endDateTime;
    if (
      !moment(startDateTime)
        .startOf('day')
        .isSame(moment(endDateTime).startOf('day'))
    ) {
      endDateTime = moment(endDateTime)
        .set('day', moment(startDateTime).day())
        .set('month', moment(startDateTime).month())
        .set('year', moment(startDateTime).year());
    }

    if (startDateTime && endDateTime && moment(endDateTime).isBefore(startDateTime)) {
      this.setState({ isEndTimeBeforeStartTimeError: true });
      return false;
    } else {
      this.setState({ isEndTimeBeforeStartTimeError: false });
      return true;
    }
  };

  componentDidUpdate(
    prevProps: Readonly<IAddEditTimeSlotsModalProps>,
    prevState: Readonly<IAddEditTimeSlotsModalState>,
    snapshot?: any,
  ) {
    if (prevProps.selectedTimeslot !== this.props.selectedTimeslot) {
      this.setState({
        isLoading: false,
        startDateTime: this.props.selectedTimeslot
          ? moment(this.props.selectedTimeslot.startDateTime)
          : moment()
              .set('hours', 8)
              .set('minutes', 0)
              .set('seconds', 0)
              .set('milliseconds', 0),
        endDateTime: this.props.selectedTimeslot
          ? moment(this.props.selectedTimeslot.endDateTime)
          : moment()
              .set('hours', 9)
              .set('minutes', 0)
              .set('seconds', 0)
              .set('milliseconds', 0),
        hasFixedCapacity: this.props.selectedTimeslot
          ? this.props.selectedTimeslot.capacity > 0
          : this.props.selectedGroupService && this.props.selectedGroupService.capacity > 0,
        recurringPattern: this.props.selectedTimeslot ? this.props.selectedTimeslot.recurringPattern : null,
        isEndTimeBeforeStartTimeError: false,
      });
    }
    if (prevState.startDateTime !== this.state.startDateTime || prevState.endDateTime !== this.state.endDateTime) {
      this._checkTimeValidity();
    }
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { isEdit, isOpen, selectedGroupService, selectedTimeslot, form } = this.props;
    const { startDateTime, endDateTime, hasFixedCapacity, isEndTimeBeforeStartTimeError, isLoading } = this.state;
    const { getFieldDecorator } = form;

    return (
      <ActionModal
        title={isEdit ? 'Edit sessions' : 'Add new sessions'}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="x-large"
      >
        <div>
          <Paragraph>Please fill in the session details below.</Paragraph>
        </div>

        <div className={'mr-large flex-row align-center'}>
          <div className="mr-large">
            <SubTitle>Occurs</SubTitle>
            <Form.Item>
              {getFieldDecorator('recurringPattern', {
                rules: [{ required: true, message: 'Choose a recurring pattern.' }],
                initialValue:
                  selectedTimeslot && selectedTimeslot.recurringPattern ? selectedTimeslot.recurringPattern : undefined,
              })(
                <Select
                  size={'large'}
                  className={'mr-small'}
                  placeholder={'Select pattern'}
                  style={{ width: '255px' }}
                  onChange={(e) => this._changeRecurringPattern(e)}
                >
                  <Select.Option key={0} value={RecurringBookingPattern.EveryWeek}>
                    Every week
                  </Select.Option>
                  <Select.Option key={0} value={RecurringBookingPattern.EveryFortnight}>
                    Every 2 weeks
                  </Select.Option>
                  <Select.Option key={0} value={RecurringBookingPattern.EveryThreeWeeks}>
                    Every 3 weeks
                  </Select.Option>
                  <Select.Option key={0} value={RecurringBookingPattern.EveryFourWeeks}>
                    Every 4 weeks
                  </Select.Option>
                </Select>,
              )}
            </Form.Item>
          </div>
          <div className={'mr-large'}>
            <SubTitle>Day</SubTitle>
            <Form.Item>
              {getFieldDecorator('day', {
                rules: [{ required: true, message: 'Choose a day.' }],
                initialValue:
                  selectedTimeslot && selectedTimeslot.startDateTime
                    ? moment(selectedTimeslot.startDateTime).isoWeekday()
                    : null,
              })(<DayPicker size={'large'} onChange={this._updateDay} width={'255px'} />)}
            </Form.Item>
          </div>
          <div className={'mr-large'}>
            <SubTitle>Start time</SubTitle>
            <div className={'mb-large'}>
              <TimeInput
                size="large"
                value={moment(startDateTime)}
                onChange={(event) => this._onChangeStartingDate(event)}
              />
            </div>
          </div>
          <div>
            <SubTitle>End time</SubTitle>
            <div className={!isEndTimeBeforeStartTimeError && 'mb-large'}>
              <TimeInput
                size="large"
                value={moment(endDateTime)}
                onChange={(event) => this._onChangeEndingDate(event)}
                className={isEndTimeBeforeStartTimeError && 'border-red-dark'}
              />
              {isEndTimeBeforeStartTimeError && (
                <Text color={'red-dark'} style={{ lineHeight: '200%' }}>
                  Must be after start time.
                </Text>
              )}
            </div>
          </div>
        </div>
        <div className={'mt-small'}>
          <div className="mb-small">
            <Checkbox checked={hasFixedCapacity} onChange={this._onChangeHasFixedCapacity}>
              Has fixed capacity limit
            </Checkbox>
          </div>
          {hasFixedCapacity && (
            <div className={'p-medium bg-quaternary rounded-big'}>
              <Form.Item className="m-none">
                {getFieldDecorator('capacity', {
                  rules: [
                    {
                      required: true,
                      type: 'number',
                      min: 1,
                      max: 500,
                      message: 'Please enter a number between 1 and 500.',
                    },
                  ],
                  initialValue:
                    selectedTimeslot && selectedTimeslot.capacity
                      ? selectedTimeslot.capacity
                      : selectedGroupService && selectedGroupService.capacity
                      ? selectedGroupService.capacity
                      : null,
                })(<NumberInput size={'large'} precision={0} min={1} max={500} className={'mr-small'} />)}{' '}
                maximum customers
              </Form.Item>
            </div>
          )}
        </div>

        <div className="mt-large">
          <SubTitle>Description</SubTitle>
          <Form.Item className="m-none">
            {getFieldDecorator('description', {
              rules: [],
              initialValue: selectedTimeslot ? selectedTimeslot.description : null,
            })(<Input type={'text'} placeholder={'Optional description here...'} maxLength={250} size={'large'} />)}
          </Form.Item>
        </div>
        <ActionModalFooter>
          <PrimaryButton size="large" onClick={this._onSaveTimeslot} loading={isLoading}>
            Save
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService,
});

export default connect(
  mapState,
  null,
)(Form.create<IAddEditTimeSlotsModalProps>()(AddEditTimeSlotsModal));
