import React, { Component } from 'react';
import { FilterType, ServiceType } from 'utilities/enum-utils';
import _ from 'lodash';
import { Popover } from '@blueprintjs/core';
import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import { IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { ActionMenu, ActionMenuDivider, ActionMenuItem } from 'common-components/action-menu';
import CommonUtils from 'utilities/common-utils';
import moment from 'moment-timezone';

interface IBookingFilterShortcutsProps {
  bookingItem: any;
  usePortal?: boolean;
  setFilter: (filters) => void;
  bookingsFilter: typeof state.bookingsStore.bookingsFilter;
}

class BookingFilterShortcuts extends Component<IBookingFilterShortcutsProps> {
  private _setFilter = (filterType, value, selectionLabel) => {
    const { bookingsFilter } = this.props;
    let existingFilterIndex = _.findIndex(bookingsFilter, (filter) => filter.filter === filterType);
    let newBookingFilters = _.clone(bookingsFilter);
    if (existingFilterIndex >= 0) {
      newBookingFilters[existingFilterIndex] = {
        filter: filterType,
        values: value,
        selectionLabel: selectionLabel
      };
    } else {
      newBookingFilters.push({ filter: filterType, values: value, selectionLabel: selectionLabel });
    }
    this.props.setFilter(newBookingFilters);
  };

  private _getPopoverContent = () => {
    const { bookingItem } = this.props;

    return (
      <ActionMenu>
        <ActionMenuDivider title={<Text>Filter by</Text>} className={'ml-small'} />
        <ActionMenuItem
          text={<Text>This customer</Text>}
          onClick={() =>
            this._setFilter(
              FilterType.CUSTOMER,
              [
                {
                  displayText: bookingItem.customerFirstName + ' ' + bookingItem.customerLastName,
                  value: bookingItem.bookerUserId
                }
              ],
              bookingItem.customerFirstName + ' ' + bookingItem.customerLastName
            )
          }
          className="hover-bg-green-lightest"
        />
        {bookingItem.workerId && (
          <ActionMenuItem
            text={<Text>Assigned team member</Text>}
            onClick={() =>
              this._setFilter(
                FilterType.WORKER,
                [
                  {
                    displayText: bookingItem.workerFirstName + ' ' + bookingItem.workerLastName,
                    value: bookingItem.workerId
                  }
                ],
                bookingItem.workerFirstName + ' ' + bookingItem.workerLastName
              )
            }
            className="hover-bg-green-lightest"
          />
        )}
        <ActionMenuItem
          text={<Text>This service</Text>}
          onClick={() => this._setFilter(FilterType.SERVICE, [bookingItem.serviceId], bookingItem.serviceName)}
          className="hover-bg-green-lightest"
        />
        {bookingItem.serviceType === ServiceType.GROUP && (
          <>
            <ActionMenuItem
              text={<Text>This schedule</Text>}
              onClick={() =>
                this._setFilter(
                  FilterType.GROUP_SERVICE_SCHEDULES,
                  [
                    {
                      displayText: (
                        <>
                          <Text>
                            {moment.tz(bookingItem.scheduleStartDate, bookingItem.timezone).format('DD/MM/YY')} -{' '}
                            {moment.tz(bookingItem.scheduleEndDate, bookingItem.timezone).format('DD/MM/YY')}
                          </Text>
                          <br />
                          <Text>
                            {bookingItem.scheduleName ? bookingItem.scheduleName : 'One-off'} -{' '}
                            {bookingItem.serviceName}
                          </Text>
                        </>
                      ),
                      value: bookingItem.serviceScheduleId,
                      tooltip: (
                        <div className={'p-medium'} style={{ minWidth: '300px' }}>
                          <div className={'mb-medium'}>
                            <Text size={'regular'} weight={'bold'} color={'white'}>
                              Schedule dates
                            </Text>
                            <br />
                            <Text color={'white'}>
                              {moment
                                .tz(bookingItem.scheduleStartDate, bookingItem.timezone)
                                .format('dddd, DD MMM YYYY')}
                              <br />
                              {moment.tz(bookingItem.scheduleEndDate, bookingItem.timezone).format('ddd, DD MMM YYYY')}
                            </Text>
                          </div>
                          <div className={'mb-medium'}>
                            <Text size={'regular'} weight={'bold'} color={'white'}>
                              Name
                            </Text>
                            <br />
                            <Text color={'white'}>
                              {bookingItem.scheduleName ? bookingItem.scheduleName : 'One-off'}
                            </Text>
                          </div>
                          <div className={'mb-medium'}>
                            <Text size={'regular'} weight={'bold'} color={'white'}>
                              Service
                            </Text>
                            <br />
                            <Text color={'white'}>{bookingItem.serviceName}</Text>
                          </div>
                          <div className={'mb-medium'}>
                            <Text size={'regular'} weight={'bold'} color={'white'}>
                              Location
                            </Text>
                            <br />
                            <Text color={'white'} style={{ whiteSpace: 'pre-line' }}>
                              {bookingItem.address ? CommonUtils.formatFullAddress(bookingItem.address) : '-'}
                            </Text>
                          </div>
                        </div>
                      )
                    }
                  ],
                  '1 schedule'
                )
              }
              className="hover-bg-green-lightest"
            />
            <ActionMenuItem
              text={<Text>This session</Text>}
              onClick={() =>
                this._setFilter(
                  FilterType.SERVICE_DATE_TIMES,
                  [
                    {
                      displayText: (
                        <>
                          <Text>
                            {moment.tz(bookingItem.sessionStartDateTime, bookingItem.timezone).format('DD/MM/YY')}
                          </Text>{' '}
                          <Text color={'secondary'}>
                            {moment.tz(bookingItem.sessionStartDateTime, bookingItem.timezone).format('h:mm a') +
                              '-' +
                              moment.tz(bookingItem.sessionStartDateTime, bookingItem.timezone).format('h:mm a')}
                          </Text>
                          <br />
                          <Text>
                            {bookingItem.description
                              ? bookingItem.description
                              : bookingItem.scheduleName
                              ? bookingItem.scheduleName
                              : 'One-off'}{' '}
                            - {bookingItem.serviceName}
                          </Text>
                        </>
                      ),
                      value: bookingItem.serviceDateTimeId,
                      tooltip: (
                        <div className={'p-medium'} style={{ minWidth: '300px' }}>
                          <div className={'mb-medium'}>
                            <Text size={'regular'} weight={'bold'} color={'white'}>
                              Session times
                            </Text>
                            <br />
                            {moment
                              .tz(bookingItem.sessionStartDateTime, bookingItem.timezone)
                              .startOf('day')
                              .isSame(
                                moment.tz(bookingItem.sessionStartDateTime, bookingItem.timezone).startOf('day')
                              ) ? (
                              <Text color={'white'}>
                                {moment
                                  .tz(bookingItem.sessionStartDateTime, bookingItem.timezone)
                                  .format('ddd, DD MMM YYYY')}
                                <br />
                                {moment.tz(bookingItem.sessionStartDateTime, bookingItem.timezone).format('h:mm a - ')}
                                {moment.tz(bookingItem.sessionStartDateTime, bookingItem.timezone).format('h:mm a')}
                              </Text>
                            ) : (
                              <Text color={'white'}>
                                {moment
                                  .tz(bookingItem.sessionStartDateTime, bookingItem.timezone)
                                  .format('ddd, DD MMM YYYY hh:mmA')}
                                <br />
                                {moment
                                  .tz(bookingItem.sessionStartDateTime, bookingItem.timezone)
                                  .format('ddd, DD MMM YYYY hh:mmA')}
                              </Text>
                            )}
                          </div>
                          <div className={'mb-medium'}>
                            <Text size={'regular'} weight={'bold'} color={'white'}>
                              Schedule
                            </Text>
                            <br />
                            <Text color={'white'}>
                              {bookingItem.scheduleName ? bookingItem.scheduleName : 'One-off'}
                            </Text>
                          </div>
                          <div className={'mb-medium'}>
                            <Text size={'regular'} weight={'bold'} color={'white'}>
                              Session description
                            </Text>
                            <br />
                            <Text color={'white'}>{bookingItem.description ? bookingItem.description : '-'}</Text>
                          </div>
                          <div className={'mb-medium'}>
                            <Text size={'regular'} weight={'bold'} color={'white'}>
                              Service
                            </Text>
                            <br />
                            <Text color={'white'}>{bookingItem.serviceName}</Text>
                          </div>
                          <div className={'mb-medium'}>
                            <Text size={'regular'} weight={'bold'} color={'white'}>
                              Location
                            </Text>
                            <br />
                            <Text color={'white'} style={{ whiteSpace: 'pre-line' }}>
                              {bookingItem.address ? CommonUtils.formatFullAddress(bookingItem.address) : '-'}
                            </Text>
                          </div>
                        </div>
                      )
                    }
                  ],
                  '1 session'
                )
              }
              className="hover-bg-green-lightest"
            />
          </>
        )}
      </ActionMenu>
    );
  };

  render() {
    const { bookingItem, usePortal = true } = this.props;

    if (_.isEmpty(bookingItem)) {
      return <></>;
    } else {
      return (
        <Popover
          content={this._getPopoverContent()}
          position={'bottom-right'}
          interactionKind="hover"
          usePortal={usePortal}
        >
          <Icon type="filter" className="text-color-blue-action mr-x-small" />
        </Popover>
      );
    }
  }
}

const mapState = (state: IRootState) => ({
  bookingsFilter: state.bookingsStore.bookingsFilter
});

export default connect(
  mapState,
  null
)(BookingFilterShortcuts);
