import React, { Component } from 'react';
import { SubTitle, Title, Text } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { Avatar, Empty, Skeleton } from 'antd';
import * as H from 'history';
import '../css/plan-management-listing.css';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import CenteredLayout from 'layouts/CenteredLayout';
import { RouteComponentProps } from 'react-router-dom';
import { LeftNavItem } from 'common-components/navigation/LeftNavItem';
import StatisticDisplayCard from 'common-components/cards/StatisticDisplayCard';
import { FilterSection } from 'common-components/filter';
import _ from 'lodash';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import ApprovalStatusTag from 'common-components/tags/ApprovalStatusTag';
import ClaimStatusTag from 'common-components/tags/ClaimStatusTag';
import SupplierStatusTag from 'common-components/tags/SupplierStatusTag';
import CommonUtils from 'utilities/common-utils';
import {
  FilterType,
  PlanManagementApprovalStatus,
  PlanManagementClaimStatus,
  PlanManagementPaymentStatus,
  PlanManagerType
} from 'utilities/enum-utils';
import moment from 'moment-timezone';
import AddEditInvoiceDetailsModal from 'common-components/plan-management/AddEditInvoiceDetailsModal';

interface ICustomerUrlParams {
  customerUserId: string;
}

interface IPlanManagementCustomerDetailViewProps extends RouteComponentProps<ICustomerUrlParams> {
  history: H.History;
  portalUser: typeof state.authStore.portalUser;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  planManagementInvoices: typeof state.planManagementStore.planManagementInvoices;
  planManagementInvoiceFilters: typeof state.planManagementStore.planManagementInvoiceFilters;
  doGetCustomer: typeof dispatch.customersStore.doGetCustomer;
  doFetchPlanManagementInvoiceStatistics: typeof dispatch.planManagementStore.doFetchPlanManagementInvoiceStatistics;
  planManagementInvoiceStatistics: typeof state.planManagementStore.planManagementInvoiceStatistics;
  setPlanManagementInvoiceFilters: typeof dispatch.planManagementStore.setPlanManagementInvoiceFilters;
  doFetchPlanManagementInvoices: typeof dispatch.planManagementStore.doFetchPlanManagementInvoices;
}

interface IPlanManagementCustomerDetailViewState {
  selectedTab: string;
  isLoading: boolean;
  isLoadingCustomer: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  isLoadingMore: boolean;
  isAddInvoiceModalOpen: boolean;
}

const availableFilters = [
  FilterType.DATE_RANGE,
  FilterType.PROVIDER,
  FilterType.SUPPLIER_PAYMENT_STATUS,
  FilterType.APPROVAL_STATUS,
  FilterType.CLAIM_STATUS
];

const INVOICELIST_FILTERCONFIGS = {
  DEFAULT: {
    key: 'DEFAULT',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
        selectionLabel: CommonUtils.getFilterText(FilterType.DATE_RANGE, [
          moment().startOf('isoWeek'),
          moment().endOf('isoWeek')
        ])
      },
      {
        filter: FilterType.PROVIDER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.PROVIDER).fullSelectionName
      }
    ]
  },
  APPROVAL: {
    key: 'APPROVAL',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName
      },
      {
        filter: FilterType.PROVIDER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.PROVIDER).fullSelectionName
      },
      {
        filter: FilterType.APPROVAL_STATUS,
        values: [
          PlanManagementApprovalStatus.NOT_SENT,
          PlanManagementApprovalStatus.SENT,
          PlanManagementApprovalStatus.REJECTED
        ],
        selectionLabel: '3 status'
      },
      {
        filter: FilterType.SUPPLIER_PAYMENT_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.SUPPLIER_PAYMENT_STATUS).fullSelectionName
      },
      {
        filter: FilterType.CLAIM_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CLAIM_STATUS).fullSelectionName
      }
    ]
  },
  CLAIM: {
    key: 'CLAIM',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName
      },
      {
        filter: FilterType.PROVIDER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.PROVIDER).fullSelectionName
      },
      {
        filter: FilterType.CLAIM_STATUS,
        values: [
          PlanManagementClaimStatus.UNCLAIMED,
          PlanManagementClaimStatus.SENT_TO_PAYMENTS,
          PlanManagementClaimStatus.REJECTED
        ],
        selectionLabel: '3 status'
      },
      {
        filter: FilterType.APPROVAL_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.APPROVAL_STATUS).fullSelectionName
      },
      {
        filter: FilterType.SUPPLIER_PAYMENT_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.SUPPLIER_PAYMENT_STATUS).fullSelectionName
      }
    ]
  },
  PAYMENT: {
    key: 'PAYMENT',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName
      },
      {
        filter: FilterType.PROVIDER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.PROVIDER).fullSelectionName
      },
      {
        filter: FilterType.SUPPLIER_PAYMENT_STATUS,
        values: [PlanManagementPaymentStatus.UNPAID, PlanManagementPaymentStatus.FAILED],
        selectionLabel: '2 status'
      },
      {
        filter: FilterType.APPROVAL_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.APPROVAL_STATUS).fullSelectionName
      }
    ]
  }
};

const InvoiceEmptyState = () => (
  <tr>
    <td colSpan={8} className="text-align-center mb-x-large">
      <div className="">
        <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
      <Text size="x2-large" color="secondary" weight="bold">
        No Invoices found.
      </Text>{' '}
      <br /> <br />
      <Text color="secondary">All invoices under this filter will appear here. </Text>
      <Text color="secondary">Try adjusting your filter.</Text>
    </td>
  </tr>
);

class PlanManagementCustomerDetailView extends Component<
  IPlanManagementCustomerDetailViewProps,
  IPlanManagementCustomerDetailViewState
> {
  state = {
    selectedTab: 'INVOICES',
    isLoadingCustomer: false,
    isLoading: false,
    page: 1,
    pageSize: 10,
    pageTimestamp: new Date(),
    isLoadingMore: false,
    isAddInvoiceModalOpen: false
  };

  private _backToCustomerListing = () => {
    this.props.history.push(`/plan-management`, { refresh: true, selectedTab: 'CUSTOMERS' });
  };

  private _formatFilterQuery = () => {
    const requestFilter: any = {
      customerUserIds: [this.props.selectedCustomer.userId]
    };
    _.forEach(this.props.planManagementInvoiceFilters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'startDate':
            requestFilter.invoiceIssuedStartDate = filter.values[0].toDate();
            requestFilter.invoiceIssuedEndDate = filter.values[1].toDate();
            break;
          case 'planManagementSupplierIds':
            requestFilter.planManagementSupplierIds = filter.values;
            break;
          case 'supplierPaymentStatus':
            requestFilter.supplierPaymentStatus = filter.values;
            break;
          case 'approvalStatus':
            requestFilter.approvalStatus = filter.values;
            break;
          case 'claimStatus':
            requestFilter.claimStatus = filter.values;
            break;
        }
      }
    });
    return requestFilter;
  };

  private _applyFilter = async (refreshList = false) => {
    this.setState({
      page: 1,
      isLoading: true,
      pageTimestamp: refreshList ? new Date() : this.state.pageTimestamp
    });
    const requestFilter = await this._formatFilterQuery();
    await this.props.doFetchPlanManagementInvoiceStatistics({
      customerUserId: this.props.selectedCustomer.userId
    });
    await this.props.doFetchPlanManagementInvoices({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });
    this.setState({ isLoading: false });
  };

  private _applyFixedFilters = async (type) => {
    await this.props.setPlanManagementInvoiceFilters(INVOICELIST_FILTERCONFIGS[type].filters);
    await this._applyFilter();
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setPlanManagementInvoiceFilters(filters);
  };

  private _goToProvider = (e, planManagementSupplierId) => {
    const { history } = this.props;
    e.stopPropagation();
    e.preventDefault();
    history.push(`/provider/details/${planManagementSupplierId}`);
  };

  private _goToInvoiceDetailView = (planManagementInvoiceId) => {
    this.props.history.push(`/plan-management/invoice/details/${planManagementInvoiceId}`);
  };

  private _fetchMorePlanManagementInvoices = async () => {
    const { doFetchPlanManagementInvoices } = this.props;
    await this.setState({ page: this.state.page + 1, isLoadingMore: true });
    const requestFilter = this._formatFilterQuery();
    await doFetchPlanManagementInvoices({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });
    this.setState({ isLoadingMore: false });
  };

  private _closeAddInvoiceModal = async (refreshListing = false) => {
    this.setState({ isAddInvoiceModalOpen: false });
    if (refreshListing) await this._applyFilter(true);
  };

  private _openAddInvoiceModal = () => {
    this.setState({ isAddInvoiceModalOpen: true });
  };

  async componentDidMount() {
    this.setState({ isLoadingCustomer: true });
    await this.props.doGetCustomer({
      userId: this.props.match.params.customerUserId
    });
    await this.props.setPlanManagementInvoiceFilters(INVOICELIST_FILTERCONFIGS.DEFAULT.filters);
    const requestFilter = this._formatFilterQuery();
    await this.props.doFetchPlanManagementInvoiceStatistics({
      customerUserId: this.props.selectedCustomer.userId
    });
    await this.props.doFetchPlanManagementInvoices({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });
    this.setState({ isLoadingCustomer: false });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.planManagementInvoiceFilters !== this.props.planManagementInvoiceFilters) {
      await this._applyFilter();
    }
  }

  render() {
    const { selectedCustomer, planManagementInvoiceStatistics, portalUser, planManagementInvoices } = this.props;
    const { isAddInvoiceModalOpen, selectedTab, isLoading, isLoadingMore } = this.state;

    return (
      <CenteredLayout>
        <div id="content-container">
          <AddEditInvoiceDetailsModal
            isOpen={isAddInvoiceModalOpen}
            modalMode={'ADD'}
            onClose={this._closeAddInvoiceModal}
            selectedCustomerUserId={selectedCustomer ? selectedCustomer.userId : null}
          />
          <div className={'mv-large'}>
            <HyperlinkButton onClick={this._backToCustomerListing}>Back to customer listing</HyperlinkButton>
          </div>
          {this.state.isLoadingCustomer && (
            <>
              <div className="item-container">
                <div className="pb-medium">
                  <Text>Fetching customer data...</Text>
                </div>
              </div>
              <Skeleton loading={this.state.isLoadingCustomer} />
            </>
          )}
          {selectedCustomer && !this.state.isLoadingCustomer && (
            <div className={'flex-row'}>
              <div style={{ width: '250px' }} className={'mr-medium'}>
                <div className={'flex-row align-center mv-small'}>
                  <Avatar size={70} src={selectedCustomer.attachmentUrl} className={'mr-small'} />
                  <div>
                    <Title level={4}>
                      {selectedCustomer.firstName} {selectedCustomer.lastName}
                    </Title>
                  </div>
                </div>

                <SubTitle>Plan managed profile</SubTitle>
                <div className={'mt-medium'}>
                  <LeftNavItem
                    filterConfig={{ key: 'INVOICES', title: 'Invoices' }}
                    isSelected={selectedTab === 'INVOICES'}
                    // onClick={() => this.setState({ selectedTab: 'INVOICES' })}
                  />
                </div>
              </div>
              {selectedTab === 'INVOICES' && (
                <div className={'bg-white p-large rounded-big width-full'}>
                  <div className={'flex-row mb-large align-center justify-between'}>
                    <Title level={4} className="mb-none">
                      Invoices for {selectedCustomer.firstName}
                    </Title>
                    {selectedCustomer.funding.planManagerType === PlanManagerType.INTERNAL && (
                      <PrimaryButton
                        size="large"
                        className={'ml-large'}
                        onClick={this._openAddInvoiceModal}
                        icon={'plus'}
                      >
                        New invoice
                      </PrimaryButton>
                    )}
                  </div>
                  <div className={'flex-row mt-medium'}>
                    <StatisticDisplayCard
                      value={
                        planManagementInvoiceStatistics ? Number(planManagementInvoiceStatistics.pendingApproval) : 0
                      }
                      className={'mr-large'}
                      isLinkDisplayed={
                        planManagementInvoiceStatistics && Number(planManagementInvoiceStatistics.pendingApproval) > 0
                      }
                      linkFunction={() => this._applyFixedFilters('APPROVAL')}
                      titleText={
                        <>
                          Invoice pending <b>approval</b>
                        </>
                      }
                      linkText={'View all awaiting approval'}
                    />
                    <StatisticDisplayCard
                      value={
                        planManagementInvoiceStatistics ? Number(planManagementInvoiceStatistics.awaitingClaim) : 0
                      }
                      className={'mr-large'}
                      isLinkDisplayed={
                        planManagementInvoiceStatistics && Number(planManagementInvoiceStatistics.awaitingClaim) > 0
                      }
                      linkFunction={() => this._applyFixedFilters('CLAIM')}
                      titleText={
                        <>
                          Invoice awaiting <b>claim</b>
                        </>
                      }
                      linkText={'View all awaiting claim'}
                    />
                    <StatisticDisplayCard
                      value={
                        planManagementInvoiceStatistics ? Number(planManagementInvoiceStatistics.awaitingPayment) : 0
                      }
                      isLinkDisplayed={
                        planManagementInvoiceStatistics && Number(planManagementInvoiceStatistics.awaitingPayment) > 0
                      }
                      linkFunction={() => this._applyFixedFilters('PAYMENT')}
                      titleText={
                        <>
                          Invoice awaiting <b>payment</b>
                        </>
                      }
                      linkText={'View all awaiting payment'}
                    />
                  </div>

                  <div className={'mt-large'}>
                    <FilterSection
                      availableFilters={availableFilters}
                      filters={this.props.planManagementInvoiceFilters}
                      onChangeFilter={this._onChangeFilter}
                      displayTimezone={portalUser.timezone}
                    />
                  </div>

                  <div>
                    <table className={'width-full plan-management-listing'}>
                      <tbody>
                        <tr className={'bordered-bottom border-color-quaternary'}>
                          <th style={{ width: '15%' }} className={'pb-small pl-medium pr-small'}>
                            <SubTitle>Number/date</SubTitle>
                          </th>
                          <th style={{ width: '30%' }} className={'pb-small pr-small'}>
                            <SubTitle>Provider</SubTitle>
                          </th>
                          <th style={{ width: '40%' }} className={'pb-small pr-small'}>
                            {/*<SubTitle>Approval status</SubTitle>*/}
                            <SubTitle>Status</SubTitle>
                          </th>
                          {/*<th style={{ width: '10%' }} className={'pb-small pr-small'}>*/}
                          {/*  <SubTitle>Claim status</SubTitle>*/}
                          {/*</th>*/}
                          {/*<th style={{ width: '10%' }} className={'pb-small pr-small'}>*/}
                          {/*  <SubTitle>Supplier status</SubTitle>*/}
                          {/*</th>*/}
                          <th style={{ width: '15%' }} className={'pb-small text-align-right pr-medium'}>
                            <SubTitle>Amount</SubTitle>
                          </th>
                        </tr>
                        {_.isEmpty(planManagementInvoices) ? (
                          <InvoiceEmptyState />
                        ) : isLoading ? (
                          <tr>
                            <td colSpan={8}>
                              <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} />
                            </td>
                          </tr>
                        ) : (
                          <>
                            <InfiniteScrollLoading
                              hasMore={planManagementInvoices.length >= this.state.page * this.state.pageSize}
                              loadingElementId={'content-container'}
                              loadMore={this._fetchMorePlanManagementInvoices}
                              loadingOffSet={100}
                              loaderColSpan={4}
                            >
                              {_.map(planManagementInvoices, (invoice, key) => {
                                return (
                                  <tr
                                    className={'evenodd cursor-pointer'}
                                    onClick={() => this._goToInvoiceDetailView(invoice.planManagementInvoiceId)}
                                    key={key}
                                  >
                                    <td className={'pv-medium pl-medium pr-small'}>
                                      <Text color={'secondary'}>{invoice.invoiceNumber}</Text>
                                      <br />
                                      {moment.tz(invoice.invoiceIssuedDate, portalUser.timezone).format('DD/MM/YYYY')}
                                    </td>
                                    {/*<td className={'pv-medium pr-small'}>{invoice.invoiceNumber}</td>*/}
                                    <td className={'pv-medium pr-small'}>
                                      <HyperlinkButton color={'black'} onClick={this._goToProvider}>
                                        {invoice.supplierName}
                                      </HyperlinkButton>
                                    </td>
                                    <td className={'pv-medium pr-small'}>
                                      {/*<ApprovalStatusTag status={invoice.approvalStatus} />*/}
                                      <Text color={'secondary'}>
                                        Approval status: <ApprovalStatusTag status={invoice.approvalStatus} />
                                        <br />
                                        Claim status: <ClaimStatusTag status={invoice.claimStatus} />
                                        <br />
                                        Supplier status: <SupplierStatusTag status={invoice.supplierPaymentStatus} />
                                      </Text>
                                    </td>
                                    {/*<td className={'pv-medium pr-small'}>*/}
                                    {/*  <ClaimStatusTag status={invoice.claimStatus} />*/}
                                    {/*</td>*/}
                                    {/*<td className={'pv-medium pr-small'}>*/}
                                    {/*  <SupplierStatusTag status={invoice.supplierPaymentStatus} />*/}
                                    {/*</td>*/}
                                    <td className={'pv-medium text-align-right pr-medium'}>
                                      {CommonUtils.formatPrice(invoice.invoiceTotal)}
                                    </td>
                                  </tr>
                                );
                              })}
                            </InfiniteScrollLoading>
                            {isLoadingMore && (
                              <tr>
                                <td colSpan={8}>
                                  <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} />
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </CenteredLayout>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  selectedCustomer: state.customersStore.selectedCustomer,
  planManagementInvoiceStatistics: state.planManagementStore.planManagementInvoiceStatistics,
  planManagementInvoices: state.planManagementStore.planManagementInvoices,
  planManagementInvoiceFilters: state.planManagementStore.planManagementInvoiceFilters
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetCustomer: dispatch.customersStore.doGetCustomer,
  doFetchPlanManagementInvoiceStatistics: dispatch.planManagementStore.doFetchPlanManagementInvoiceStatistics,
  setPlanManagementInvoiceFilters: dispatch.planManagementStore.setPlanManagementInvoiceFilters,
  doFetchPlanManagementInvoices: dispatch.planManagementStore.doFetchPlanManagementInvoices
});

export default connect(
  mapState,
  mapDispatch
)(PlanManagementCustomerDetailView);
