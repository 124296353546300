import _ from 'lodash';
import React, { Component } from 'react';
import { Row, Col, Avatar, Icon } from 'antd';
import { PrimaryButton } from '../../../../../common-components/buttons/index';
import { FieldLabel, Paragraph, Text, Title } from '../../../../../common-components/typography/index';
import { INewActivityRecordData } from '../../../../../interfaces/booking-interfaces';
import { IRootDispatch, IRootState, dispatch } from '../../../../../stores/rematch/root-store';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

interface IActivityRecordStep6SummaryPanelProps {
  newActivityRecordData: INewActivityRecordData;
  history: any;
  setNewActivityRecordData: typeof dispatch.bookingsStore.setNewActivityRecordData;
  setBookSelectedService: typeof dispatch.servicesStore.setBookSelectedService;
}

interface IActivityRecordStep6SummaryPanelState {
  isLoadingNavigate: boolean;
}

class ActivityRecordStep6SummaryPanel extends Component<
  IActivityRecordStep6SummaryPanelProps,
  IActivityRecordStep6SummaryPanelState
> {
  state = {
    isLoadingNavigate: false
  };
  private _onNextStep = () => {
    this.setState({ isLoadingNavigate: true });
    const { history, newActivityRecordData, setNewActivityRecordData } = this.props;
    history.push('/bookings/details/' + newActivityRecordData.bookingId);
    this.setState({ isLoadingNavigate: false });
    setNewActivityRecordData({});
    this.props.setBookSelectedService({});
  };

  render() {
    const { newActivityRecordData } = this.props;
    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large mt-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 184px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Paragraph>
                The next few screens will cover everything that's need to create an activity record.
              </Paragraph>
              <Paragraph>
                Let's start by selecting the <b>customer</b> you wish to create this activity record for
              </Paragraph>
              {/*<Paragraph>*/}
              {/*  Visit the <a>Help Center</a> to learn more.*/}
              {/*</Paragraph>*/}
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 184px)' }}>
            <div className="bg-white rounded-big p-large" style={{ minWidth: '250px', minHeight: '627px' }}>
              <div className={'flex-row align-center'}>
                <Icon
                  type="check-circle"
                  theme="filled"
                  className={'text-size-x4-large'}
                  style={{ color: '#0083FF' }}
                />
                <div className={'mt-small ml-small'}>
                  <Title level={4} style={{ color: '#0083FF' }}>
                    Activity Record successfully created
                  </Title>
                </div>
              </div>

              {newActivityRecordData.isApprove && !newActivityRecordData.isApproved && (
                <div
                  style={{
                    backgroundColor: '#FFFAE5',
                    border: '1px solid #FFB366',
                    boxSizing: 'border-box',
                    borderRadius: '8px'
                  }}
                  className={'flex-row p-small align-center mt-large'}
                >
                  <Icon type={'warning'} theme="filled" style={{ color: '#FFB366' }} className={'text-size-x2-large'} />
                  <Text className={'ml-small'}>
                    The Record could not be approved due to billing errors that need to be resolved. Please resolve the
                    billing errors before approving the record.
                  </Text>
                </div>
              )}

              <div className={'mt-large'}>
                <FieldLabel text={'CUSTOMER'} />
                <div className={'flex-row align-center mt-x2-small'}>
                  <Avatar
                    size={32}
                    src={newActivityRecordData.selectedCustomer && newActivityRecordData.selectedCustomer.attachmentUrl}
                  />
                  <Text className={'ml-small'}>
                    {newActivityRecordData.selectedCustomer &&
                      newActivityRecordData.selectedCustomer.firstName + ' ' + newActivityRecordData &&
                      newActivityRecordData.selectedCustomer.lastName}
                  </Text>
                </div>
              </div>
              <div className={'mt-large'}>
                <FieldLabel text={'ASSIGNED TEAM MEMBER'} />
                {newActivityRecordData.selectedWorker && (
                  <div className={'flex-row align-center mt-x2-small'}>
                    <Avatar
                      src={newActivityRecordData.selectedWorker && newActivityRecordData.selectedWorker.attachmentUrl}
                    />
                    <Text className={'ml-small'}>
                      {newActivityRecordData.selectedWorker &&
                        newActivityRecordData.selectedWorker.firstName + ' ' + newActivityRecordData &&
                        newActivityRecordData.selectedWorker.lastName}
                    </Text>
                  </div>
                )}
                {!newActivityRecordData.selectedWorker && (
                  <div className={'flex-row align-center mt-x2-small'}>
                    <Text>No worker assigned</Text>
                  </div>
                )}
              </div>
              <div className={'mt-large'}>
                <FieldLabel text={'SERVICE'} />
                <Text className={'mt-x2-small'}>
                  {newActivityRecordData.selectedService && newActivityRecordData.selectedService.serviceName}
                </Text>
              </div>
              <div className={'mt-large'}>
                <FieldLabel text={'DATE & DURATION'} />
                <div className={'mt-x2-small'}>
                  <Text>{moment(newActivityRecordData.bookingDate).format('DD/MM/YYYY')}</Text>
                </div>
                {newActivityRecordData.isDuration && (
                  <div className={'mt-x2-small'}>
                    <Text>
                      ({newActivityRecordData.durationHours + 'hr ' + newActivityRecordData.durationMinutes + 'm'})
                    </Text>
                  </div>
                )}
                {!newActivityRecordData.isDuration && (
                  <div className={'mt-x2-small'}>
                    <Text>
                      (
                      {moment(newActivityRecordData.bookingStartDate).format('h:mmA') +
                        ' - ' +
                        moment(newActivityRecordData.bookingEndDate).format('h:mmA')}
                      )
                    </Text>
                  </div>
                )}
              </div>
              <div className={'mt-large'}>
                <FieldLabel text={'LOCATION'} />
                <Text className={'mt-x2-small'}>
                  {newActivityRecordData.bookLocation && newActivityRecordData.bookLocation.fullAddress}
                </Text>
              </div>
              <div className={'mt-large'}>
                <FieldLabel text={'NOTES'} />
                {newActivityRecordData.note && (
                  <Text className={'mt-x2-small'}>{newActivityRecordData.note.noteContent}</Text>
                )}
              </div>
              <div className={'mt-large'}>
                <FieldLabel text={'ATTACHMENTS(S)'} />
                {_.map(newActivityRecordData.attachments, (attachments) => (
                  <div key={attachments.attachmentId}>
                    <Text className={'mt-x2-small'}>{attachments.attachment.name}</Text>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-row justify-end mt-x2-large">
              <PrimaryButton
                size="large"
                onClick={this._onNextStep}
                icon="right"
                iconPosition="right"
                loading={this.state.isLoadingNavigate}
              >
                Go to record
              </PrimaryButton>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  newActivityRecordData: state.bookingsStore.newActivityRecordData
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewActivityRecordData: dispatch.bookingsStore.setNewActivityRecordData,
  setBookSelectedService: dispatch.servicesStore.setBookSelectedService
});

export default connect(
  mapState,
  mapDispatch
)(ActivityRecordStep6SummaryPanel);
