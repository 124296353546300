import React, { Component } from 'react';
import { Avatar, Col, notification, Radio, Row } from 'antd';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Title from 'antd/lib/typography/Title';
import { GroupBookingActionType } from 'utilities/enum-utils';

interface IActionTypeStepPanelProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setCustomerToSchedule: typeof dispatch.servicesStore.setCustomerToSchedule;
  customerToSchedule: typeof state.servicesStore.customerToSchedule;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  updateActionType: (actionType: string) => void;
}

interface IActionTypeStepPanelState {
  actionType: string;
  isActionTypeError: boolean;
}

class ActionTypeStepPanel extends Component<IActionTypeStepPanelProps, IActionTypeStepPanelState> {
  state = {
    actionType: this.props.customerToSchedule && this.props.customerToSchedule.actionType,
    isActionTypeError: false
  };

  private _selectActionType = async (actionType) => {
    this.setState({ actionType });
  };

  private _goToNext = async () => {
    const { setCustomerToSchedule, customerToSchedule } = this.props;
    const { actionType } = this.state;

    if (!actionType) {
      this.setState({ isActionTypeError: true });
    } else {
      try {
        await setCustomerToSchedule({
          ...customerToSchedule,
          actionType
        });
        this.props.updateActionType(actionType);
        this.props.onNextStep();
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong! Please try again.' });
      }
    }
  };

  render() {
    const { customerToSchedule } = this.props;
    const { actionType } = this.state;

    console.log(this.props.selectedGroupService);

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>We'll guide you adding a customer to sessions</Title>
              <Paragraph>Select how you want to add this customer to session.</Paragraph>
              <Paragraph>
                Adding to a <b>schedule</b> allows you to quickly add a customer to all sessions that are part of a
                specific schedule.
              </Paragraph>
              <Paragraph>
                Adding to <b>individual sessions</b> allows you to add customers to specific sessions wheter they are
                part of a schedule or not.
              </Paragraph>
              {customerToSchedule && customerToSchedule.selectedCustomer ? (
                <div className={'p-medium bg-white rounded-big'}>
                  <SubTitle>Selected customer</SubTitle>
                  <div className={'flex-row align-center mt-small'}>
                    <Avatar
                      className={'mr-small'}
                      shape={'circle'}
                      icon={'user'}
                      src={customerToSchedule.selectedCustomer.attachmentUrl}
                    />{' '}
                    {customerToSchedule.selectedCustomer.firstName} {customerToSchedule.selectedCustomer.lastName}
                  </div>
                </div>
              ) : (
                <Text color={'secondary'}>No customer selected</Text>
              )}
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
              <Title level={4}>How would you like to add this customer to sessions?</Title>
              <Paragraph>Please select an action below :</Paragraph>
              {this.props.selectedGroupService.hasSchedule && (
                <div
                  className={`mb-medium bordered border-standard-gray rounded-big shadow-container cursor-pointer p-medium flex-row ${actionType ===
                    GroupBookingActionType.SCHEDULES && 'bg-blue-lightest'}`}
                  onClick={() => this._selectActionType(GroupBookingActionType.SCHEDULES)}
                >
                  <div className={'pr-medium'}>
                    <Radio
                      checked={actionType === GroupBookingActionType.SCHEDULES}
                      value={GroupBookingActionType.SCHEDULES}
                    />
                  </div>
                  <div>
                    <b>Add to a schedule</b>
                    <br />
                    Add to a scheduled series of sessions for a group of service.
                  </div>
                </div>
              )}
              <div
                className={`bordered border-standard-gray rounded-big shadow-box cursor-pointer p-medium flex-row ${actionType ===
                  GroupBookingActionType.INDIVIDUAL_SESSIONS && 'bg-blue-lightest'}`}
                onClick={() => this._selectActionType(GroupBookingActionType.INDIVIDUAL_SESSIONS)}
              >
                <div className={'pr-medium'}>
                  <Radio
                    checked={actionType === GroupBookingActionType.INDIVIDUAL_SESSIONS}
                    value={GroupBookingActionType.INDIVIDUAL_SESSIONS}
                  />
                </div>
                <div>
                  <b>Add to individual sessions</b>
                  <br />
                  Add to a single session only.
                </div>
              </div>
              {this.state.isActionTypeError && (
                <div className={'mt-medium'}>
                  <Paragraph className={'text-color-red-dark'}>Please select an option.</Paragraph>
                </div>
              )}
            </div>
            <div className="pv-medium width-full bg-tertiary" style={{ position: 'sticky', bottom: 0 }}>
              <Row gutter={0} type="flex" align="middle" justify={'space-between'} className="bg-transparent">
                <div className="text-align-right pv-medium">
                  <SecondaryButton size="large" onClick={this.props.onPreviousStep}>
                    Back
                  </SecondaryButton>
                </div>
                <div className="text-align-right pv-medium">
                  <PrimaryButton size="large" onClick={this._goToNext}>
                    Next
                  </PrimaryButton>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  customerToSchedule: state.servicesStore.customerToSchedule,
  selectedGroupService: state.groupServiceStore.selectedGroupService
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setCustomerToSchedule: dispatch.servicesStore.setCustomerToSchedule
});

export default connect(
  mapState,
  mapDispatch
)(ActionTypeStepPanel);
