import { Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import React, { PureComponent } from 'react';

interface IStatisticDisplayCardProps {
  titleText: any;
  value: number;
  isLinkDisplayed: boolean;
  linkFunction?: () => void;
  linkText?: string;
  className?: string;
}

class StatisticDisplayCard extends PureComponent<IStatisticDisplayCardProps, any> {
  render() {
    const { titleText, value, isLinkDisplayed, linkText, linkFunction, className } = this.props;
    return (
      <div className={`bordered border-standard-gray rounded width-full ${className}`} style={{ maxWidth: '400px' }}>
        <div className={'ph-medium pt-small'}>{titleText}</div>
        <div className={'ph-medium pv-x-small'}>
          <Text size={'x4-large'} weight={'bold'}>
            {value}
          </Text>
        </div>
        <div className={'bg-tertiary ph-medium pv-small'} style={{ height: '40px' }}>
          {isLinkDisplayed && <HyperlinkButton onClick={linkFunction}>{linkText}</HyperlinkButton>}
        </div>
      </div>
    );
  }
}

export default StatisticDisplayCard;
