import { IGroupBookingRatio } from 'views/group-services/manage-booking-modal/interfaces/group-booking-interfaces';
import React from 'react';
import { timeZone } from 'interfaces/timezone-type';
import moment from 'moment-timezone';

export function RatioTableRowItem({
  customRatioItem,
  timezone
}: {
  customRatioItem: IGroupBookingRatio;
  timezone: timeZone;
}) {
  const { startDateTime, endDateTime, teamMemberCustomerRatio, comments } = customRatioItem;

  // TODO @ Jir - Not sure if ratio time should be a string or double date variables.
  return (
    <tr className="evenodd">
      <td className="p-medium">
        {moment.tz(startDateTime, timezone).format('hh:mmA - ')}
        {moment.tz(endDateTime, timezone).format('hh:mmA')}
      </td>
      <td className="p-medium">{teamMemberCustomerRatio ? teamMemberCustomerRatio : '-'}</td>
      <td className="p-medium">{comments ? comments : '-'}</td>
    </tr>
  );
}
