import React, { PureComponent } from 'react';
import { Text } from 'common-components/typography';
import { Icon } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import ViewPinnedAlertModal from 'common-components/pinned-alerts/ViewPinnedAlertModal';
import PermissionUtils from 'utilities/permission-utils';
import { IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';

interface IPinnedAlertItemProps {
  customerUserId: string;
  pinnedAlertNumber: number;
  containerClassName?: string;
  isViewOnly?: boolean;
  portalUser: typeof state.authStore.portalUser;
}

interface IPinnedAlertItemState {
  isPinnedAlertModalOpen: boolean;
}

class PinnedAlertItem extends PureComponent<IPinnedAlertItemProps, IPinnedAlertItemState> {
  state = {
    isPinnedAlertModalOpen: false
  };

  private _openPinnedAlertModal = () => {
    this.setState({ isPinnedAlertModalOpen: true });
  };

  private _closePinnedAlertModal = () => {
    this.setState({ isPinnedAlertModalOpen: false });
  };

  render() {
    const { pinnedAlertNumber, containerClassName, customerUserId, portalUser } = this.props;
    const { isPinnedAlertModalOpen } = this.state;

    const hasEditCustomerPermission = PermissionUtils.validatePermission(
      'EditCustomerProfile',
      portalUser.permissions.permissionRoles
    );

    return (
      <>
        <ViewPinnedAlertModal
          isOpen={isPinnedAlertModalOpen}
          customerUserId={customerUserId}
          closeViewPinnedAlertModal={this._closePinnedAlertModal}
          isViewOnly={!!this.props.isViewOnly || !hasEditCustomerPermission}
        />
        <div className={containerClassName ? containerClassName : `flex-row justify-between mv-small`}>
          <div
            className={`${
              pinnedAlertNumber > 0 ? 'bg-orange-lightest' : 'bg-green-lightest'
            } pv-x-small ph-small rounded`}
          >
            <Icon
              type={pinnedAlertNumber > 0 ? 'pushpin' : 'check-circle'}
              theme={'filled'}
              className={`mr-small ${pinnedAlertNumber > 0 ? 'text-color-warning-orange' : 'text-color-green'}`}
            />
            <Text className={pinnedAlertNumber > 0 ? 'text-color-warning-orange' : 'text-color-green'}>
              {pinnedAlertNumber > 0
                ? pinnedAlertNumber + ` pinned alert${pinnedAlertNumber !== 1 ? 's' : ''}.`
                : 'No pinned alerts.'}
            </Text>
            {PermissionUtils.validatePermission('EditCustomerProfile', portalUser.permissions.permissionRoles) && (
              <HyperlinkButton className={'ml-medium'} onClick={this._openPinnedAlertModal}>
                {pinnedAlertNumber > 0
                  ? `See pinned alert${pinnedAlertNumber !== 1 ? 's' : ''}`
                  : hasEditCustomerPermission
                  ? 'Add alert'
                  : ''}
              </HyperlinkButton>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser
});

export default connect(
  mapState,
  null
)(PinnedAlertItem);
