import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';

import { Text } from 'common-components/typography';
import { SessionsColumn } from 'views/group-services/session-listings/week-view/components/SessionsColumn';
import { IconButton } from 'common-components/buttons';
import { FilterType } from 'utilities/enum-utils';
import { FilterSection } from 'common-components/filter';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import * as H from 'history';
import { timeZone } from 'interfaces/timezone-type';

// Utility functions for adding 7 days
const addDays = (days) => (date, timezone) =>
  moment
    .tz(date, timezone)
    .add(days, 'days')
    .toDate();

const nextWeek = addDays(7);
const previousWeek = addDays(-7);

const getEntireWeekForDay = (day) => _.times(7, (i) => moment(day).add(i, 'days'));

const availableFilters = [FilterType.SERVICE, FilterType.SESSION_STATUS];

const SESSIONLIST_FILTERCONFIGS = {
  ALL: {
    filters: [
      {
        filter: FilterType.SERVICE,
        values: [],
        selectionLabel: 'All services'
      },
      {
        filter: FilterType.SESSION_STATUS,
        values: [],
        selectionLabel: 'All'
      }
    ]
  }
};

interface ISessionsWeekViewProps {
  history: H.History;
  sessions: typeof state.groupServiceStore.sessions;
  doFetchSessions: typeof dispatch.groupServiceStore.doFetchSessions;
  doResetSessions: typeof dispatch.groupServiceStore.doResetSessions;
  isActive: boolean;
  timezone: timeZone;
}

interface ISessionsWeekViewState {
  startWeek: Date;
  endWeek: Date;
  filters: any;
  isFetching: boolean;
  page: number;
  pageSize: number;
}

class SessionsWeekView extends Component<ISessionsWeekViewProps, ISessionsWeekViewState> {
  state = {
    startWeek: moment()
      .startOf('week')
      .toDate(),
    endWeek: moment()
      .endOf('week')
      .toDate(),
    filters: SESSIONLIST_FILTERCONFIGS['ALL'].filters,
    isFetching: false,
    page: 1,
    pageSize: 100
  };

  // _navigateToSession = (session: IGroupServiceSession) => {
  //   const { history } = this.props;
  //   const sessionDetailsUrl = `/group-service/${session.serviceId}/session/details/${session.serviceDateTimeId}`;
  // };

  _onChangeFilter = (filters) => {
    this.setState({ filters, page: 1 }, () => {
      this._fetchSessions();
    });
  };

  _formatFilters = () => {
    // Run through non-empty filters and re-assign them into filter objects.
    const values = _.chain(this.state.filters)
      .filter((filter) => !_.isEmpty(filter.values))
      .map((filter) => {
        switch (filter.filter) {
          case 'serviceIds':
            return { serviceIds: filter.values };
          case 'sessionStatus':
            return { status: filter.values };
        }
      })
      .value();

    // merge array into an object.
    return _.assign.apply(_, values);
  };

  _fetchSessions = async () => {
    const { doFetchSessions } = this.props;
    const filters = this._formatFilters();

    // Temporarily attaching it to a serviceId
    const request = {
      ...filters,
      startDate: this.state.startWeek,
      endDate: this.state.endWeek,
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: new Date()
    };

    this.setState({ isFetching: true });
    await doFetchSessions(request);
    this.setState({ isFetching: false });
  };

  _goPreviousWeek = () =>
    this.setState(
      {
        startWeek: previousWeek(this.state.startWeek, this.props.timezone),
        endWeek: previousWeek(this.state.endWeek, this.props.timezone)
      },
      this._fetchSessions
    );

  _goNextWeek = () =>
    this.setState(
      {
        startWeek: nextWeek(this.state.startWeek, this.props.timezone),
        endWeek: nextWeek(this.state.endWeek, this.props.timezone)
      },
      this._fetchSessions
    );

  componentDidMount = () => {
    // this._fetchSessions();
  };

  componentDidUpdate(
    prevProps: Readonly<ISessionsWeekViewProps>,
    prevState: Readonly<ISessionsWeekViewState>,
    snapshot?: any
  ) {
    if (this.props.isActive !== prevProps.isActive) {
      if (this.props.isActive) {
        // console.log('trigger');

        // switched to active.
        this.props.doResetSessions({});
        this._fetchSessions();
      }
    }
  }

  render() {
    const { sessions, history, timezone } = this.props;

    // Filter out only this week's sessions.
    const filteredSessions = _.filter(sessions, (session) =>
      moment.tz(session.startDateTime, timezone).isBetween(this.state.startWeek, this.state.endWeek)
    );

    // Group sessions by day.
    // Formats is as :
    // { '0': session, '1': session ... } where 0 - Sunday, 1 - Monday and so forth.
    // @ts-ignore
    const groupSessions = _.groupBy(filteredSessions, (session) => moment.tz(session.startDateTime, timezone).day());

    const currentWeekLabel = `${moment.tz(this.state.startWeek, timezone).format('D')} -  ${moment
      .tz(this.state.endWeek, timezone)
      .format('D MMMM YYYY')}`;

    const days = getEntireWeekForDay(this.state.startWeek);

    return (
      <div className="flex-column flex-1">
        {/* Filter section */}
        <div className="mb-medium ph-large">
          <FilterSection
            availableFilters={availableFilters}
            filters={this.state.filters}
            // TODO - Change this to actual timezone.
            displayTimezone={'Australia/Melbourne'}
            onChangeFilter={this._onChangeFilter}
            containerClassName="flex-row justify-between"
          />
        </div>

        {/* Date Navigator */}
        <div className="bg-white pv-medium bordered-top border-standard-gray flex-row justify-center align-center">
          <IconButton
            icon="double-left"
            color="white"
            className="mr-x2-large hover-bg-quaternary"
            iconColor="blue-action"
            onClick={this._goPreviousWeek}
          />

          <div style={{ minWidth: '280px' }} className="text-align-center line-height-100 mt-x-small cursor-pointer">
            <div className="hover-bg-quaternary hover-border-black pv-small ph-12 rounded-big inline-block select-none">
              <Text lineHeight={100} size="x-large" color="blue-action">
                {currentWeekLabel}
              </Text>
            </div>
          </div>

          <IconButton
            icon="double-right"
            color="white"
            className="ml-x2-large hover-bg-quaternary"
            iconColor="blue-action"
            onClick={this._goNextWeek}
          />
        </div>

        {/* Dates */}
        <div className="flex-column flex-1">
          <div className="flex-row">
            {_.map(days, (day, idx) => (
              <SessionsColumn
                day={day}
                sessions={groupSessions[idx]}
                isFetching={this.state.isFetching}
                history={history}
                timezone={timezone}
              />
            ))}
          </div>

          <div className="bordered-top bg-quaternary pv-x-small bordered-bottom" />
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  // TODO : Change this to sessions timezone
  bookingDisplayTzCustom: state.bookingsStore.bookingDisplayTzCustom,
  bookingDisplayTzMode: state.bookingsStore.bookingDisplayTzMode,

  sessions: state.groupServiceStore.sessions,
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchSessions: dispatch.groupServiceStore.doFetchSessions,
  doResetSessions: dispatch.groupServiceStore.doResetSessions
});

export default connect(mapState, mapDispatch)(SessionsWeekView);
