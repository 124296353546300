import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { Text } from 'common-components/typography';
import { ndisHelper } from 'variables/data-helpers';
import _ from 'lodash';
import { NdisSupportTypes } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';

interface IFundingViewCategoriesPanelProps {
  categoryData: any;
}

interface IFundingViewCategoriesPanelState {
  categoryData: any[];
}

const paymentMethodLabel = {
  NDIA: 'NDIA-managed',
  PLAN: 'PLAN-managed',
  SELF: 'SELF-managed'
};

class FundingViewCategoriesPanel extends Component<IFundingViewCategoriesPanelProps, IFundingViewCategoriesPanelState> {
  state = {
    categoryData: this.props.categoryData
  };

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    const { categoryData } = this.props;

    if (prevProps.categoryData !== categoryData) {
      const newCategoryData = _.map(categoryData, (data) => {
        return {
          ...data,
          funding: Number(data.funding),
          supportItemNumber: data.supportItemNumber ? data.supportItemNumber : null
        };
      });
      this.setState({ categoryData: newCategoryData });
    }
  }

  render() {
    const { categoryData } = this.state;

    const supportTypes = ndisHelper.supportPurposeTypes;
    const totalFunding = _.sumBy(categoryData, (data: any) => Number(data.funding));

    return (
      <React.Fragment>
        <Row className="bordered pv-medium ph-medium bg-tertiary">
          <Col span={12}>
            <Text size="x2-large">Total Funding</Text>
          </Col>
          <Col span={12} className="text-align-right">
            <Text size="x2-large">{CommonUtils.formatPrice(Number(totalFunding))}</Text>
          </Col>
        </Row>
        {_.map(supportTypes, (supportType) => {
          if (_.find(categoryData, (data) => data.supportType === supportType)) {
            const isCore = supportType.toUpperCase() === NdisSupportTypes.CORE;

            // Extract Categories for Support Type form categoryData and add the statedSupport if any
            const supportCategories = _.filter(categoryData, (data) => {
              return data.supportType === supportType && !data.supportItemNumber;
            }).map((category) => {
              const statedSupports = _.filter(categoryData, (supportData) => {
                if (isCore) return supportData.supportType === category.supportType && supportData.supportItemNumber;
                else {
                  return (
                    supportData.supportCategoryNumber === category.supportCategoryNumber &&
                    supportData.supportItemNumber
                  );
                }
              });
              return { ...category, statedSupports };
            });

            // Calculate total funding for the Stated Support
            const totalAllStatedSupports = _.sumBy(supportCategories, (category) =>
              _.sumBy(category.statedSupports, (statedSupport: any) => Number(statedSupport.funding))
            );

            // Calculate the total for this Support Type
            const totalSupport =
              _.sumBy(supportCategories, (category) => Number(category.funding)) + Number(totalAllStatedSupports);

            return (
              <div className="bordered mv-x-large">
                <Row>
                  <div
                    className={`pv-medium ph-medium width-full ${supportCategories.length > 0 &&
                      'bordered-bottom'} flex justify-between`}
                  >
                    <div className="flex align-center">
                      <Text size="x2-large">{supportType}</Text>
                    </div>
                    {totalSupport > 0 && (
                      <div>
                        <Text size="x2-large">{CommonUtils.formatPrice(totalSupport)}</Text>
                      </div>
                    )}
                  </div>
                </Row>
                {_.map(supportCategories, (category, key) => {
                  const totalCategory =
                    _.sumBy(category.statedSupports, (statedSupport: any) => Number(statedSupport.funding)) +
                    Number(category.funding);

                  return (
                    <>
                      {key >= 1 && (
                        <Row>
                          <div style={{ height: '20px' }} className="bg-secondary" />
                        </Row>
                      )}
                      <Row className="mv-small pv-small ph-small">
                        <Row className="flex align-center bg-secondary pv-x-small ph-small">
                          <Col span={15} className="align-center">
                            <Text>
                              {category.supportCategoryNumber === null
                                ? 'All Core Categories'
                                : category.supportCategoryName}
                            </Text>
                          </Col>
                          <Col span={4}>{paymentMethodLabel[category.paymentMethod]}</Col>
                          <Col span={5}>
                            <div className="text-align-right">Budget: {CommonUtils.formatPrice(category.funding)}</div>
                          </Col>
                        </Row>

                        <Row className="mv-small ph-small">
                          <Text weight="bold">Stated Supports funding</Text>
                        </Row>
                        {_.map(category.statedSupports, (statedSupport) => {
                          const lineItemName =
                            statedSupport.supportItemNumber !== '-'
                              ? ndisHelper.getBySupportItemNumber(statedSupport.supportItemNumber).SupportItem
                              : null;
                          return (
                            <Row className="mv-small ph-small">
                              <Col span={15}>
                                <Text>{lineItemName}</Text>
                              </Col>
                              <Col span={4}>{paymentMethodLabel[statedSupport.paymentMethod]}</Col>
                              <Col span={5}>
                                <div className="text-align-right">
                                  Budget: {CommonUtils.formatPrice(statedSupport.funding)}
                                </div>
                              </Col>
                            </Row>
                          );
                        })}
                        {!category.statedSupports ||
                          (category.statedSupports && category.statedSupports.length === 0 && (
                            <Row className="mv-small ph-small">
                              <Text>No Stated Supports fund.</Text>
                            </Row>
                          ))}
                      </Row>
                      <Row className="text-align-right mv-small ph-small">
                        <Text weight="bold">Total Category: {CommonUtils.formatPrice(totalCategory)}</Text>
                      </Row>
                    </>
                  );
                })}
              </div>
            );
          }
        })}
      </React.Fragment>
    );
  }
}

export default FundingViewCategoriesPanel;
