import React, { Component } from 'react';
import { notification, Form, Radio, Divider } from 'antd';
import { Text, Title } from 'common-components/typography';
import moment from 'moment-timezone';
import _ from 'lodash';
import { SecondaryButton, PrimaryButton } from 'common-components/buttons';
import ObsoleteStandardModal from 'common-components/modal/ObsoleteStandardModal';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { FormComponentProps } from 'antd/es/form';
import AvailabilityConflictTimes from '../AvailablilityConflictTimes';
import { TeamMemberTimezoneInput } from 'common-components/timezone';
import ModalSubContent from './ModalSubContent';
import { CustomUnavailabilityOption, UnavailabilityType } from 'utilities/enum-utils';

interface IUnavailableTimesModalProps extends FormComponentProps {
  isOpen: boolean;
  onClose: () => void;
  selectedItem: any;
  doAddUnavailableTime: typeof dispatch.teamStore.doAddUnavailableTime;
  doUpdateUnavailableTime: typeof dispatch.teamStore.doUpdateUnavailableTime;
  timezone?: any;
  doCheckAvailabilityConflict: typeof dispatch.teamStore.doCheckAvailabilityConflict;
}

interface IUnavailableTimesModalState {
  loading: boolean;
  step: number;
  conflictShifts: any;
  selectedShiftsToKeep: any[];
  modalWidth: string;
  selectedTimezone: string;
  timezoneType: number;
  customUnavailabilityWeekDays: any[];
  unavailability: any;
  missingTimezoneValue: boolean;
}

const radioStyle = {
  display: 'flex',
  height: '30px',
  lineHeight: '30px',
  alignItems: 'center'
};

class UnavailableTimeModal extends Component<IUnavailableTimesModalProps, IUnavailableTimesModalState> {
  state = {
    loading: false,
    step: 1,
    conflictShifts: [],
    selectedShiftsToKeep: [],
    modalWidth: '60vw',
    selectedTimezone: this.props.timezone,
    timezoneType: 1,
    customUnavailabilityWeekDays: [],
    unavailability: null,
    missingTimezoneValue: false
  };

  private _syncTimeWithDate = (date, time) => {
    const convertedDate = date;
    const convertedTime = time;

    const formattedDate = moment(convertedDate).format('YYYY-MM-DD');
    const formattedTime = moment(convertedTime).format('HH:mm');

    return moment(formattedDate + ' ' + formattedTime);
  };

  private _getUnavailabilityRequest = () => {
    const { form } = this.props;
    const { selectedTimezone } = this.state;
    const unavailabilityType = form.getFieldValue('scheduleType');
    const isAllDay = form.getFieldValue('isAllDay');
    let startTime = null;
    let endTime = null;
    let startDate = form.getFieldValue('startDate');
    let endDate = form.getFieldValue('endDate');

    if (!isAllDay) {
      startTime = this._syncTimeWithDate(startDate, form.getFieldValue('startTime'));
      endTime = this._syncTimeWithDate(endDate, form.getFieldValue('endTime'));
    }

    let unavailability = {
      unavailabilityType,
      startDate: this._convertTimeZone(startDate),
      endDate: this._convertTimeZone(endDate),
      comment: form.getFieldValue('comments'),
      isAllDay,
      timezone: selectedTimezone
    };

    if (!isAllDay && unavailabilityType === UnavailabilityType.ONE_OFF) {
      unavailability['startTime'] = this._convertTimeZone(startTime);
      unavailability['endTime'] = this._convertTimeZone(endTime);
    }
    if (unavailabilityType === UnavailabilityType.CUSTOM) {
      unavailability['customUnavailabilityWeekDays'] = this._reverseMapData();
    }

    return unavailability;
  };

  private _convertTimeZone = (time) => {
    const { selectedTimezone } = this.state;
    const dateTimeInString = moment(time).format('YYYY-MM-DD HH:mm');

    return moment.tz(dateTimeInString, selectedTimezone).toISOString();
  };

  private _reverseMapData = () => {
    const { customUnavailabilityWeekDays } = this.state;

    const filteredCustomAvailabilityWeekDays = _.filter(customUnavailabilityWeekDays, (day) => day.isInRange);

    return _.map(filteredCustomAvailabilityWeekDays, (item) => {
      let timeRange = _.map(item.timeRange, (time) => ({
        startTime: this._convertTimeZone(time.startTime),
        endTime: this._convertTimeZone(time.endTime)
      }));
      let unavailabilityOption: number = CustomUnavailabilityOption.UnavailableAllDay;
      if (item.isAvailableAllDay) {
        unavailabilityOption = CustomUnavailabilityOption.AvailableAllDay;
        timeRange = [];
      } else {
        if (!_.isEmpty(timeRange)) {
          unavailabilityOption = CustomUnavailabilityOption.UnavailableSpecificHours;
        }
      }
      return {
        weekDayId: item.number,
        unavailabilityOption,
        timeRange
      };
    });
  };

  private _submitUnavailableTime = async () => {
    const { selectedItem, doAddUnavailableTime, doUpdateUnavailableTime } = this.props;
    const { unavailability } = this.state;
    this.setState({ loading: true });
    try {
      if (!selectedItem) {
        const result = await doAddUnavailableTime({ unavailability, shiftToBeKept: this.state.selectedShiftsToKeep });
        notification.success({
          message: 'Unavailability added',
          description: 'You have successfully added an unavailability for this team member.'
        });
      } else {
        const result = await doUpdateUnavailableTime({
          unavailability,
          shiftToBeKept: this.state.selectedShiftsToKeep,
          timeUnavailabilityId: selectedItem.supportWorkerUnavailabilityId
        });
        notification.success({
          message: 'Unavailability updated',
          description: 'You have successfully updated an unavailability for this team member.'
        });
      }
      this.setState({ loading: false });
      this._onClose();
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ loading: false });
    }
  };

  private _validateUnavailableTime = () => {
    const { customUnavailabilityWeekDays } = this.state;

    const errorDate = _.filter(
      customUnavailabilityWeekDays,
      (item) =>
        !_.isEmpty(item.timeRange) &&
        !_.isEmpty(
          _.find(item.timeRange, (time) => {
            return time.error;
          })
        )
    );

    return _.isEmpty(errorDate);
  };

  private _onSave = async () => {
    const { form } = this.props;
    const { selectedTimezone } = this.state;
    let isFormValid = true;

    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (!selectedTimezone) {
      this.setState({ missingTimezoneValue: true });
    }

    const unavailabilityType = form.getFieldValue('scheduleType');
    if (unavailabilityType === UnavailabilityType.CUSTOM) {
      if (!this._validateUnavailableTime()) {
        isFormValid = false;
      }
    }

    if (isFormValid && selectedTimezone) {
      const { doCheckAvailabilityConflict } = this.props;
      const unavailability = this._getUnavailabilityRequest();
      this.setState({ loading: true, unavailability });
      try {
        const result = await doCheckAvailabilityConflict({ unavailability });
        if (!_.isEmpty(result)) {
          this.setState({ conflictShifts: result, step: 2, loading: false });
        } else {
          this._submitUnavailableTime();
        }
        this.setState({ loading: false });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
        this.setState({ loading: false });
      }
    }
  };

  private _onClose = () => {
    this.setState({
      loading: false,
      step: 1,
      selectedShiftsToKeep: [],
      conflictShifts: [],
      selectedTimezone: this.props.timezone,
      timezoneType: 1,
      customUnavailabilityWeekDays: [],
      unavailability: null,
      missingTimezoneValue: false
    });
    this.props.onClose();
  };

  private _onChangeShiftsToKeep = (shifts) => {
    this.setState({ selectedShiftsToKeep: shifts });
  };

  private _onChangeScheduleType = (e) => {
    const { form } = this.props;
    form.setFieldsValue({
      scheduleType: e.target.value,
      startDate: null,
      endDate: null,
      startDateTime: null,
      endDateTime: null,
      comment: ''
    });
  };

  private _onChangeTimezone = (type, newTimezone) => {
    const { timezone } = this.props;

    this.setState({
      selectedTimezone: type === 1 ? timezone : newTimezone,
      timezoneType: type,
      missingTimezoneValue: false
    });
  };

  private _onChangeCustomUnavailableTime = (customUnavailableTimes: any[]) => {
    this.setState({ customUnavailabilityWeekDays: customUnavailableTimes });
  };

  private _renderContent = () => {
    const { step, timezoneType, selectedTimezone, missingTimezoneValue } = this.state;
    const { form, selectedItem, timezone } = this.props;
    const { getFieldDecorator } = form;

    switch (step) {
      case 1:
        return (
          <>
            <Title level={3}>Schedule unavailability</Title>
            {!selectedItem ? (
              <>
                <Form.Item>
                  {getFieldDecorator('scheduleType', {
                    initialValue: 1
                  })(
                    <Radio.Group onChange={this._onChangeScheduleType}>
                      <Radio style={radioStyle} value={1}>
                        One-off unavailability
                      </Radio>
                      <Radio style={radioStyle} value={2}>
                        Custom
                      </Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
                <Divider />
              </>
            ) : (
              <>
                <Form.Item>
                  {getFieldDecorator('scheduleType', {
                    initialValue: selectedItem.unavailabilityType
                  })}
                </Form.Item>
              </>
            )}
            <div className="mb-medium">
              <TeamMemberTimezoneInput
                usePortal={false}
                value={selectedTimezone}
                onChange={this._onChangeTimezone}
                type={timezoneType}
                error={missingTimezoneValue}
              />
            </div>
            <ModalSubContent
              form={form}
              scheduleType={form.getFieldValue('scheduleType')}
              selectedItem={selectedItem}
              onChangeCustomUnavailableTime={this._onChangeCustomUnavailableTime}
              selectedTimezone={selectedTimezone}
            />

            <div className="flex-row justify-end mt-large">
              <SecondaryButton className="mr-medium" size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton className="mr-medium" size="large" onClick={this._onSave}>
                Save
              </PrimaryButton>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <Title level={3}>Review upcoming shifts</Title>
            <Text className="mt-large">
              This team member is currently assigned to shift(s) that fall outside their updated availability. The team
              member will be removed fromthese shifts. Please{' '}
              <Text weight="bold">select the shifts (if any) you wish to keep for this team member</Text> or continue
              without selecting any.
            </Text>
            <div className="bg-quaternary pv-x-large ph-12 mt-x-large">
              <AvailabilityConflictTimes
                conflicts={this.state.conflictShifts}
                timezone={selectedTimezone}
                onSelectShifts={this._onChangeShiftsToKeep}
              />
            </div>
            <div className="mt-x3-large flex justify-end">
              <SecondaryButton size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" className="ml-small" onClick={this._submitUnavailableTime}>
                Done
              </PrimaryButton>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  componentDidMount(): void {
    const { selectedItem, timezone } = this.props;
    if (selectedItem) {
      this.setState({
        selectedTimezone: selectedItem.timezone,
        timezoneType: selectedItem.timezone === timezone ? 1 : 2
      });
    } else {
      this.setState({
        selectedTimezone: timezone,
        timezoneType: 1
      });
    }
  }

  render() {
    const { isOpen } = this.props;

    return (
      <ObsoleteStandardModal visible={isOpen} onCancel={this._onClose} footer={null} width={this.state.modalWidth}>
        <div className="ph-medium mv-small">{this._renderContent()}</div>
      </ObsoleteStandardModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddUnavailableTime: dispatch.teamStore.doAddUnavailableTime,
  doUpdateUnavailableTime: dispatch.teamStore.doUpdateUnavailableTime,
  doCheckAvailabilityConflict: dispatch.teamStore.doCheckAvailabilityConflict
});

export default connect(
  null,
  mapDispatch
)(Form.create<IUnavailableTimesModalProps>()(UnavailableTimeModal));
