import React, { Component } from 'react';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../stores/rematch/root-store';
import { TransportAttendanceType } from '../../../../utilities/enum-utils';
import { connect } from 'react-redux';
import ActionModal, { ActionModalFooter } from '../../../../common-components/modal/ActionModal';
import { Paragraph } from '../../../../common-components/typography';
import { GhostButton, PrimaryButton } from '../../../../common-components/buttons';
import { notification } from 'antd';

interface IRevokeApprovalTransportBookingModalProps {
  isOpen: boolean;
  onClose: () => void;
  transportBooking: any;
  doRevokeTransportBookingApproval: typeof dispatch.groupBookingsStore.doRevokeTransportBookingApproval;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
  attendanceType: TransportAttendanceType;
}

interface IRevokeApprovalTransportBookingModalState {
  step: number;
  title: string;
  canManuallyClose: boolean;
}

class RevokeApprovalTransportBookingModal extends Component<
  IRevokeApprovalTransportBookingModalProps,
  IRevokeApprovalTransportBookingModalState
> {
  state = {
    step: 1,
    title: 'Revoke Approval',
    canManuallyClose: true
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, canManuallyClose: true, title: 'Revoke Approval' }, onClose);
  };

  onSubmit = async () => {
    const { transportBooking, selectedGroupBookingItem, attendanceType } = this.props;
    try {
      this.setState({ canManuallyClose: false });
      await this.props.doRevokeTransportBookingApproval({
        bookingId: selectedGroupBookingItem.bookingId,
        transportBookingId: transportBooking.attendanceId,
        attendanceType
      });
      this.setState({ step: 2, canManuallyClose: true, title: 'Booking approval Revoked' });
    } catch (e) {
      this.setState({ canManuallyClose: true });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  renderView = () => {
    const { step } = this.state;
    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <Paragraph>Are you sure you want to revoke approval for this booking</Paragraph>
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" icon="check" onClick={this.onSubmit}>
              Revoke approval
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <Paragraph>The approval for this booking has been successfully revoked</Paragraph>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  render() {
    let { isOpen } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this.renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRevokeTransportBookingApproval: dispatch.groupBookingsStore.doRevokeTransportBookingApproval
});

export default connect(
  mapState,
  mapDispatch
)(RevokeApprovalTransportBookingModal);
