import { Tooltip2 } from '@blueprintjs/popover2';
import { Col, Empty, Icon, Input, notification, Row, Select, Skeleton } from 'antd';
import { HyperlinkButton, IconButton, PrimaryButton } from 'common-components/buttons';
import { ItemCountSheet } from 'common-components/Sheets/ItemCountSheet';
import { SubTitle, Text, Title } from 'common-components/typography';
import { parse } from 'json2csv';
import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { IBatchDetail } from 'src/interfaces/booking-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import PermissionUtils from 'utilities/permission-utils';
import BatchDetailItemRow from './payments/components/details/BatchDetailItemRow';

const fileDownload = require('js-file-download');

const { Search } = Input;

interface ListPanelProps {
  batchDetail?: typeof state.billingsStore.batchDetail;
  batchDetailList?: typeof state.billingsStore.batchDetailList;
  selectedBatchDetail?: typeof state.billingsStore.selectedBatchDetail;
  batchDetailFilter?: typeof state.billingsStore.batchDetailFilter;
  portalUser?: typeof state.authStore.portalUser;
  history?: any;
  match?: any;
  doFetchBatchDetail: typeof dispatch.billingsStore.doFetchBatchDetail;
  doFetchBatchTenciaFile: typeof dispatch.billingsStore.doFetchBatchTenciaFile;
  doDownloadNDIAcsv: typeof dispatch.billingsStore.doDownloadNDIAcsv;
  setBatchDetail?: typeof dispatch.billingsStore.setBatchDetail;
  setBatchDetailList?: typeof dispatch.billingsStore.setBatchDetailList;
}

interface IBatchDetailUrlParams {
  batchId: string;
}

interface ListPanelState {
  topHeight: number;
  showFilters: boolean;
  showActionSheet: boolean;
  isLoading: boolean;
  isSearching: boolean;
  paymentMethodFilter: string;
  statusFilter: string;
  searchString: string;
  filters: any;
  batchId?: any;
}

const BatchDetailEmptyState = () => (
  <div className="flex-1 bg-white  align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No Invoices found.
    </Text>{' '}
    <br /> <br />
    <Text color="secondary">All invoices under this filter will appear here.</Text>
    <Text color="secondary">Try adjusting your filter, or clicking on another view.</Text>
  </div>
);

class PayListBatchDetailsView extends PureComponent<ListPanelProps, ListPanelState, IBatchDetailUrlParams> {
  state = {
    topHeight: 0,
    showFilters: false,
    showActionSheet: false,
    checkAllIndicator: false,
    indeterminateCheck: false,
    isLoading: false,
    isSearching: false,
    searchString: '',
    paymentMethodFilter: '',
    statusFilter: '',
    filters: [],
    batchId: 0
  };

  _headerElement = null;

  _handleHeaderHeight = () => {
    if (this._headerElement) {
      this.setState({ topHeight: this._headerElement.offsetHeight - 1 });
    }
  };

  _applySearchFilter = (batchDetailList, searchStr) => {
    const targetStr = searchStr
      .toLowerCase()
      .split(' ')
      .filter(function(el) {
        return el.length !== 0;
      });

    return batchDetailList.filter((batch) =>
      _.some(
        targetStr,
        (string) =>
          batch.firstName.toLowerCase().indexOf(string) >= 0 ||
          batch.lastName.toLowerCase().indexOf(string) >= 0 ||
          batch.invoiceNumber.toLowerCase().indexOf(string) >= 0
      )
    );
  };

  _searchText = (txt) => {
    this.setState({ isSearching: false, searchString: txt });
  };

  _debounceSearch = _.debounce(this._searchText, 500);

  _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });

    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  _addfilterPaymentMethod = (e) => {
    const paymentMethod = e === 'ALL' ? '' : e;
    this.setState({ isSearching: false, paymentMethodFilter: paymentMethod });
  };

  _addfilterStatus = (e) => {
    const status = e === 'ALL' ? '' : e;
    this.setState({ isSearching: false, statusFilter: status });
  };

  _searchPaymentMethod = (batchDetailList, paymentMethod) => {
    return batchDetailList.filter((batch) => batch.paymentMethod === paymentMethod);
  };

  _searchStatus = (batchDetailList, status) => {
    if (status === 'PROCESSED')
      return batchDetailList.filter((batch) => batch.status === 'SUCCESS' || batch.status === 'PENDING');
    else return batchDetailList.filter((batch) => batch.status === status);
  };

  _downloadTenciaFile = async () => {
    const { doFetchBatchTenciaFile, portalUser } = this.props;

    try {
      const resp: any = await doFetchBatchTenciaFile(this.state.batchId);
      if (portalUser.integrationConfig.financeExport && portalUser.integrationConfig.financeExport.style === 'MYOB') {
        fileDownload(resp, `finance-file.csv`);
      } else if (
        portalUser.integrationConfig.financeExport &&
        portalUser.integrationConfig.financeExport.style === 'MD365'
      ) {
        fileDownload(resp, `finance-file.tsv`);
      } else {
        const csvData = parse(resp);
        fileDownload(csvData, `finance-file.csv`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  _downloadCSVFile = async () => {
    const { doDownloadNDIAcsv } = this.props;
    try {
      const resp: any = await doDownloadNDIAcsv(this.state.batchId);
      const csvData = parse(resp.listData, { quote: '' });
      fileDownload(csvData, `ndia_export.csv`);
    } catch (e) {
      notification.error({
        message: 'Error while generating the NDIS File',
        description: 'The file generation failed, please try again.'
      });
    }
  };

  _refreshListings = async () => {
    const { doFetchBatchDetail, batchDetailFilter, setBatchDetailList, setBatchDetail } = this.props;
    this.setState({ isLoading: true, showActionSheet: false });
    this.setState({ isLoading: false });

    setBatchDetail([]);
    setBatchDetailList([]);
    await doFetchBatchDetail({
      ...batchDetailFilter,
      batchId: this.state.batchId
    });
    this.setState({ isLoading: false });
  };

  //region Component Lifecycle Methods
  componentDidMount = async () => {
    const { match } = this.props;
    const { params } = match;
    const batchId = params.batchId;

    this._handleHeaderHeight();
    const { doFetchBatchDetail, batchDetailFilter } = this.props;

    let appliedFilters = _.isEmpty(batchDetailFilter) ? [] : batchDetailFilter;

    await this.setState({ isLoading: true, batchId });
    await doFetchBatchDetail({ appliedFilters, batchId });
    this.setState({ isLoading: false });
  };

  render() {
    const { batchDetailList, batchDetail, portalUser, history } = this.props;

    let filterSearchList = [...batchDetailList];

    if (!_.isEmpty(this.state.searchString)) {
      filterSearchList = this._applySearchFilter(filterSearchList, this.state.searchString);
    }
    if (!_.isEmpty(this.state.paymentMethodFilter)) {
      filterSearchList = this._searchPaymentMethod(filterSearchList, this.state.paymentMethodFilter);
    }
    if (!_.isEmpty(this.state.statusFilter)) {
      filterSearchList = this._searchStatus(filterSearchList, this.state.statusFilter);
    }

    const isFullyRejected = batchDetailList.every((batch) => {
      return batch.listOfItem.every((item: IBatchDetail) => item.isRejected);
    });

    return (
      <div className="bg-white flex-1 width-full flex-column" style={{ overflowY: 'auto', position: 'relative' }}>
        <div>
          <div className="booking-header" ref={(com) => (this._headerElement = com)}>
            <div className="mb-medium">
              <HyperlinkButton onClick={() => history.push('/payments')}>
                <Icon type="left" /> Back to Batch List
              </HyperlinkButton>
            </div>
            <div className="flex-row justify-between align-center">
              <div>
                <Title level={3} className="mv-none" lineHeight={150}>
                  Batch Detail
                </Title>
                <Text color="secondary">View and manage your processed batches</Text>
              </div>
              <div className="flex-column align-center">
                <div className="pr-x-small">
                  <Tooltip2 content="Refresh this view">
                    <IconButton
                      color="white"
                      onClick={this._refreshListings}
                      iconColor="black"
                      size="large"
                      icon="reload"
                    />
                  </Tooltip2>
                </div>
              </div>
            </div>

            <div className="bordered ph-large pv-medium shadow mt-large mb-large">
              <Row className="">
                <Col>
                  <Title level={4} className="mv-none mb-medium">
                    Batch {batchDetail ? batchDetail.batchNumber : '-'}
                  </Title>
                </Col>
              </Row>
              <Row className="flex-row justify-between">
                <Col span={6}>
                  <Text size="x4-large">{batchDetail ? batchDetail.numberOfInvoice : '-'}</Text>
                  <br />
                  Number of invoices
                </Col>
                <Col span={6}>
                  <Text size="x4-large">{batchDetail ? batchDetail.numberOfFailedInvoice : '-'}</Text>
                  <br />
                  Sending failed
                </Col>
                <Col span={6}>
                  <Text size="x4-large">
                    {batchDetail ? moment(batchDetail.exportedDate).format('DD/MM/YYYY') : '-'}
                  </Text>
                  <br />
                  Exported date
                </Col>
                <Col span={6} className="text-align-center">
                  {batchDetail &&
                  !isFullyRejected &&
                  PermissionUtils.validatePermission('DownloadFinanceFile', portalUser.permissions.permissionRoles) ? (
                    <PrimaryButton size="large" className="rounded-full" onClick={this._downloadTenciaFile}>
                      Export finance File
                    </PrimaryButton>
                  ) : (
                    <div />
                  )}
                </Col>
              </Row>
            </div>

            <div className="flex-row pb-medium mt-large">
              <div className="mr-x2-large" style={{ minWidth: '300px' }}>
                <Text weight="bold">Customer or Invoice</Text>
                <br />
                <Search
                  onChange={this._onEnterSearchText}
                  loading={this.state.isSearching}
                  placeholder="Customer Name or Invoice number"
                />
              </div>
              <div>
                <Text weight="bold">Payment Methods</Text>
                <br />
                <Select
                  defaultValue="ALL"
                  className="mr-x4-large"
                  onChange={this._addfilterPaymentMethod}
                  style={{ width: '150px' }}
                >
                  <Select.Option value="ALL">All methods</Select.Option>
                  <Select.Option value="NDIA">NDIA</Select.Option>
                  <Select.Option value="PLAN">Plan Managed</Select.Option>
                  <Select.Option value="SELF">Self Managed</Select.Option>
                </Select>
              </div>
              <div>
                <Text weight="bold">Status</Text>
                <br />
                <Select
                  defaultValue="ALL"
                  className="mr-small"
                  onChange={this._addfilterStatus}
                  style={{ width: '150px' }}
                >
                  <Select.Option value="ALL">All status</Select.Option>
                  <Select.Option value="PROCESSED">Processed</Select.Option>
                  <Select.Option value="FAILED">Failed</Select.Option>
                </Select>
              </div>
            </div>
          </div>

          <table className="payment-listing">
            <thead>
              <tr>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <SubTitle>Invoice (items)</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <SubTitle>Customer</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <SubTitle>Status</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap text-align-right">
                  <SubTitle containerClassName="text-align-left">Total</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap" />
              </tr>
            </thead>

            <tbody>
              {this.state.isLoading && (
                <tr style={{ borderBottom: '0px solid !important' }}>
                  <td colSpan={7}>
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                  </td>
                </tr>
              )}
              {!this.state.isLoading &&
                _.map(filterSearchList, (batchItem) => (
                  <BatchDetailItemRow
                    batchItem={batchItem}
                    history={history}
                    key={batchItem.batchId}
                    downloadCSVFile={this._downloadCSVFile}
                  />
                ))}
              <div id="scroll" />
            </tbody>
          </table>
        </div>

        {!this.state.isLoading && _.isEmpty(filterSearchList) && <BatchDetailEmptyState />}

        <div className="flex-1 bg-white">&nbsp;</div>

        {!this.state.isLoading && !this.state.showActionSheet && (
          <ItemCountSheet itemCount={filterSearchList.length} itemLabel="Invoice" />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBatchDetail: state.billingsStore.selectedBatchDetail,
  batchDetail: state.billingsStore.batchDetail,
  batchDetailList: state.billingsStore.batchDetailList,
  batchDetailFilter: state.billingsStore.batchDetailFilter,
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedBatchDetail: dispatch.billingsStore.setSelectedBatchDetail,
  setBatchDetailList: dispatch.billingsStore.setBatchDetailList,
  setBatchDetail: dispatch.billingsStore.setBatchDetail,
  doFetchBatchDetail: dispatch.billingsStore.doFetchBatchDetail,
  doFetchBatchTenciaFile: dispatch.billingsStore.doFetchBatchTenciaFile,
  doDownloadNDIAcsv: dispatch.billingsStore.doDownloadNDIAcsv,
  doResendEmail: dispatch.billingsStore.doResendEmail
});

export default connect(mapState, mapDispatch)(PayListBatchDetailsView);
