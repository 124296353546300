import React from 'react';

interface GridLineProps {
  type: 'dotted' | 'solid' | string;
  offset: number;
  height: string;
}

export function GridLine({ type, offset, height = '100vh' }: GridLineProps) {
  const left = `${offset}px`;

  if (type === 'solid') {
    return (
      <div
        className="position-absolute border-standard-gray bordered-left"
        style={{
          height,
          width: '1px',
          zIndex: 5,
          top: 0,
          left
        }}
      />
    );
  } else if (type === 'dotted') {
    return (
      <div
        className="position-absolute"
        style={{
          height,
          width: '1px',
          zIndex: 5,
          left,
          top: 0,
          // top: '86px',
          backgroundImage: 'linear-gradient(#eeeeee 50%, rgba(255,255,255,0) 0%)',
          backgroundPosition: 'right',
          backgroundSize: '24px 12px',
          backgroundRepeat: 'repeat-y'
        }}
      />
    );
  }
}
