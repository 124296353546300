import React, { Component } from 'react';
import { Col, Row, Form, notification, Input } from 'antd';
import { FieldLabel, Paragraph, SubTitle, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Warning } from 'common-components/alerts';
import { FormComponentProps } from 'antd/es/form';
import { dispatch, IRootDispatch, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { IServiceAgreement } from 'interfaces/customer-interfaces';
import { PaymentSourceType } from 'utilities/enum-utils';

interface IEditServiceAgreementServiceItemsModalProps extends FormComponentProps {
  closeEdit: () => void;
  companyData: typeof state.companyStore.companyDataLite;
  isOpen: boolean;
  serviceAgreement: IServiceAgreement;
  doUpdateServiceAgreementDate: typeof dispatch.customersStore.doUpdateServiceAgreementDate;
  doCheckServiceAgreementDateValidity: typeof dispatch.customersStore.doCheckServiceAgreementDateValidity;
}

interface IEditServiceAgreementServiceItemsModalState {
  isLoading: boolean;
  startDate: Date;
  endDate: Date;
  isErrorEndDate: boolean;
  isQuoteModalOpen: boolean;
  isProceedModalOpen: boolean;
  isOverlap: boolean;
  overlappingStartDate: Date | null;
  overlappingEndDate: Date | null;
  hasGap: boolean;
  willQuoteChange: boolean;
}

class EditServiceAgreementDatesModal extends Component<
  IEditServiceAgreementServiceItemsModalProps,
  IEditServiceAgreementServiceItemsModalState
> {
  state = {
    isLoading: false,
    startDate: null,
    endDate: null,
    isErrorEndDate: false,
    isQuoteModalOpen: false,
    isProceedModalOpen: false,
    isOverlap: false,
    overlappingStartDate: null,
    overlappingEndDate: null,
    hasGap: false,
    willQuoteChange: false
  };

  private _closeEdit = () => {
    this._closeProceedModal();
    this._closeQuoteModal();
    this.props.closeEdit();
  };

  private _saveNewDates = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      this.setState({ isLoading: true });
      try {
        await this.props.doUpdateServiceAgreementDate({
          serviceAgreementId: this.props.serviceAgreement.userServiceAgreementId,
          startDate: form.getFieldValue('startDate'),
          endDate: form.getFieldValue('endDate'),
          poNumber: form.getFieldValue('poNumber')
        });
        notification.success({ message: 'Service agreement updated successfully.' });
        this._closeEdit();
        this._closeProceedModal();
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
      this.setState({ isLoading: false });
    }
  };

  private _openProceedModal = () => {
    this.setState({ isProceedModalOpen: true });
  };

  private _openQuoteModal = () => {
    this.setState({ isQuoteModalOpen: true });
  };

  private _closeQuoteModal = () => {
    this.setState({ isQuoteModalOpen: false });
  };

  private _closeProceedModal = () => {
    this.setState({
      isOverlap: false,
      overlappingStartDate: null,
      overlappingEndDate: null,
      hasGap: false,
      willQuoteChange: false,
      isProceedModalOpen: false
    });
  };

  private _validateEndDate = (rule, value, callback) => {
    try {
      const { companyData } = this.props;
      if (!value) {
        throw Error('Select an end date.');
      } else if (
        moment.tz(value, companyData.timezone) <=
        moment.tz(this.props.form.getFieldValue('startDate'), companyData.timezone)
      ) {
        throw Error('End date must be after the start date.');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  private _validateStartEndDate = async (dateType, value) => {
    let { startDate, endDate } = this.state;
    if (dateType === 'startDate') {
      startDate = value;
      this.setState({ startDate });
    } else {
      endDate = value;
      this.setState({ endDate });
    }
    if (startDate && endDate) {
      const result: any = await this.props.doCheckServiceAgreementDateValidity({
        startDateTime: startDate,
        endDateTime: endDate,
        serviceAgreementId: this.props.serviceAgreement.userServiceAgreementId,
        paymentSourceType: this.props.serviceAgreement.paymentSourceType
      });
      this.setState({
        isOverlap: result && result.isOverlap,
        hasGap: result && result.hasGap,
        overlappingEndDate: result && result.overlappingEndDate,
        overlappingStartDate: result && result.overlappingStartDate,
        willQuoteChange: result && result.quoteChange
      });
    }
  };

  componentDidUpdate(
    prevProps: Readonly<IEditServiceAgreementServiceItemsModalProps>,
    prevState: Readonly<IEditServiceAgreementServiceItemsModalState>
  ) {
    if (prevProps.serviceAgreement !== this.props.serviceAgreement) {
      const { companyData, serviceAgreement } = this.props;
      this.setState({
        startDate: moment(moment.tz(serviceAgreement.startDate, companyData.timezone).format('DD MMM YYYY')).toDate(),
        endDate: moment(moment.tz(serviceAgreement.endDate, companyData.timezone).format('DD MMM YYYY')).toDate()
      });
    }
  }

  render() {
    const { serviceAgreement, form, companyData } = this.props;
    const { getFieldDecorator } = form;
    const { overlappingEndDate, overlappingStartDate, isOverlap, hasGap, willQuoteChange } = this.state;
    return (
      <>
        <ActionModal
          isOpen={this.state.isProceedModalOpen}
          onClose={this._closeProceedModal}
          title={'Editing a signed service agreement'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You are making a change to a <b>signed</b> service agreement. Doing so will revert the status of this
            service agreement to <b>unsigned. Are you sure you want to make this changes?</b>
          </Text>
          <br />
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._closeProceedModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._saveNewDates}>
              Save changes
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.state.isQuoteModalOpen}
          onClose={this._closeQuoteModal}
          title={'Changes to quote'}
          showCloseButton={true}
        >
          <Paragraph>
            As a result of new changes you have made to the services being delivered, the <b>value of the quote</b> for
            this service agreement will be <b>recalculated</b> in order to remain accurate.
          </Paragraph>
          <Paragraph>
            This may result in <b>significant changes</b> to the value of the quote. Are you sure you wish to continue?
          </Paragraph>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._closeQuoteModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              size="large"
              loading={this.state.isLoading}
              onClick={
                serviceAgreement && serviceAgreement.signedStatus === 'SIGNED'
                  ? this._openProceedModal
                  : this._saveNewDates
              }
            >
              Continue
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this._closeEdit}
          title={
            <>
              Edit <b>general information</b>
            </>
          }
        >
          {serviceAgreement && (
            <>
              <Paragraph>Edit the general information of this service agreement.</Paragraph>
              <Row gutter={24} className="mt-large">
                <Col span={6}>
                  <FieldLabel text={'Start Date'} />
                  <Form.Item className="mb-none">
                    {getFieldDecorator('startDate', {
                      initialValue:
                        serviceAgreement && serviceAgreement.startDate
                          ? moment(
                              moment.tz(serviceAgreement.startDate, companyData.timezone).format('DD MMM YYYY')
                            ).toDate()
                          : null,
                      valuePropName: 'selected',
                      rules: [
                        {
                          required: true,
                          message: 'Select a start date'
                        }
                      ]
                    })(
                      <DatePicker
                        onChange={(value) => this._validateStartEndDate('startDate', value)}
                        className="gh-datepicker gh-datepicker-flat rounded"
                        calendarClassName="gh-datepicker-calendar"
                        dateFormat="dd/MM/yyyy"
                        placeholderText={'Select a date'}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={18}>
                  <FieldLabel text={'End Date'} />
                  <Form.Item className="mb-none">
                    {getFieldDecorator('endDate', {
                      initialValue:
                        //TODO: Revisit timezone
                        serviceAgreement && serviceAgreement.endDate
                          ? moment(
                              moment.tz(serviceAgreement.endDate, companyData.timezone).format('DD MMM YYYY')
                            ).toDate()
                          : null,
                      valuePropName: 'selected',
                      rules: [
                        {
                          validator: this._validateEndDate
                        }
                      ]
                    })(
                      <DatePicker
                        onChange={(value) => this._validateStartEndDate('endDate', value)}
                        className="gh-datepicker gh-datepicker-flat rounded"
                        calendarClassName="gh-datepicker-calendar"
                        dateFormat="dd/MM/yyyy"
                        placeholderText={'Select a date'}
                        minDate={moment
                          .tz(form.getFieldValue('startDate'), companyData.timezone)
                          .add(1, 'day')
                          .toDate()}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {isOverlap && (
                <Warning
                  color="red-dark"
                  borderNone
                  content={
                    <Text color="red-dark">
                      The dates selected overlap with an existing active service agreement. <br />
                      Dates of overlapping service agreement:{' '}
                      <b>
                        {moment.tz(overlappingStartDate, companyData.timezone).format('DD/MM/YYYY')} -{' '}
                        {moment.tz(overlappingEndDate, companyData.timezone).format('DD/MM/YYYY')}
                      </b>
                    </Text>
                  }
                />
              )}
              {hasGap && (
                <Warning
                  borderNone
                  content={
                    <Text color="warning-orange">The dates selected result in a gap between service agreements</Text>
                  }
                />
              )}
              {serviceAgreement && serviceAgreement.paymentSourceType === PaymentSourceType.VCP && (
                <div className="mt-large">
                  <FieldLabel text={'Unique Code(Optional)'} />
                  <Form.Item>
                    {getFieldDecorator('poNumber', {
                      initialValue: serviceAgreement && serviceAgreement.poNumber ? serviceAgreement.poNumber : null
                    })(
                      <Input size={'large'} style={{ width: '400px' }} maxLength={50} placeholder="Enter PO number" />
                    )}
                  </Form.Item>
                </div>
              )}
              <ActionModalFooter>
                <SecondaryButton
                  size="large"
                  onClick={this._closeEdit}
                  className="mr-medium"
                  disabled={this.state.isLoading}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  disabled={isOverlap}
                  onClick={
                    willQuoteChange
                      ? this._openQuoteModal
                      : serviceAgreement.signedStatus === 'SIGNED'
                      ? this._openProceedModal
                      : this._saveNewDates
                  }
                  loading={this.state.isLoading}
                >
                  Save
                </PrimaryButton>
              </ActionModalFooter>
            </>
          )}
        </ActionModal>
      </>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateServiceAgreementDate: dispatch.customersStore.doUpdateServiceAgreementDate,
  doCheckServiceAgreementDateValidity: dispatch.customersStore.doCheckServiceAgreementDateValidity
});

export default connect(
  null,
  mapDispatch
)(Form.create<IEditServiceAgreementServiceItemsModalProps>()(EditServiceAgreementDatesModal));
