import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FieldLabel, Paragraph, SubTitle, Text } from 'common-components/typography';
import { Avatar, Icon, notification } from 'antd';
import _ from 'lodash';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { worker } from 'cluster';

interface IReEnableUserModalProps {
  isOpen: any;
  onClose: any;
  worker: any;
  doReEnableTeamMember: typeof dispatch.teamStore.doReEnableTeamMember;
}

interface IReEnableUserModalState {
  title: any;
  step: number;
  isLoading: boolean;
}

class ReEnableUserModal extends Component<IReEnableUserModalProps, IReEnableUserModalState> {
  state = { title: 'Re-enable', step: 1, isLoading: false };

  private _onCloseModal = () => {
    this.setState({ title: 'Re-enable', step: 1, isLoading: false });
    this.props.onClose();
  };

  private _saveAction = async () => {
    try {
      const { doReEnableTeamMember, worker } = this.props;
      this.setState({ isLoading: true });
      await doReEnableTeamMember({ supportWorkerId: worker.supportWorkerId });
      this.setState({
        title: (
          <>
            {worker.firstName + ' ' + worker.lastName} successfully re-enabled
            <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
          </>
        ),
        step: 2
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { isOpen, worker } = this.props;
    const { step, title, isLoading } = this.state;
    return (
      <ActionModal
        title={!_.isEmpty(worker) ? (step === 2 ? title : `${title} ${worker.firstName} ${worker.lastName}`) : ''}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        canCloseOutside={!isLoading}
        width="large"
      >
        {!_.isEmpty(worker) &&
          (step === 1 && (
            <div className="mv-medium anim-slide-left">
              <div>
                <Paragraph>
                  Are you sure you want to re-enable this user. Doing so will allow them to re-acces the GoodHuman
                  app/Workspace for your business.
                </Paragraph>
                <Paragraph>
                  The details of this user have been maintained and we will send them an email notification informing
                  them that they can re-access their account.
                </Paragraph>
              </div>

              <ActionModalFooter>
                <SecondaryButton size="large" disabled={isLoading} className="mr-medium" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" loading={isLoading} onClick={this._saveAction}>
                  Re-enable
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          ))}

        {!_.isEmpty(worker) &&
          (step === 2 && (
            <>
              <div className="mv-medium">
                <div>
                  <div className="mb-small">
                    <FieldLabel text={'USER'} />
                  </div>
                  <div className={'flex-row align-center'}>
                    <Avatar shape={'square'} src={worker.attachmentUrl} className={'mr-medium'} />
                    <Text size="x3-large">{worker.firstName + ' ' + worker.lastName}</Text>
                    <div className={'ml-medium'}>
                      <Text size={'regular'} color={'green'}>
                        RE-ENABLED
                      </Text>
                    </div>
                  </div>
                </div>
              </div>

              <ActionModalFooter>
                <PrimaryButton size="large" onClick={this._onCloseModal}>
                  Done
                </PrimaryButton>
              </ActionModalFooter>
              {/* </div> */}
            </>
          ))}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doReEnableTeamMember: dispatch.teamStore.doReEnableTeamMember
});

export default connect(
  null,
  mapDispatch
)(ReEnableUserModal);
