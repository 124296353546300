import React, { PureComponent } from 'react';
import { Text } from 'common-components/typography';
import { PlanManagementApprovalStatus, PlanManagementApprovalStatusDisplay } from 'utilities/enum-utils';

interface IApprovalStatusTagProps {
  status: PlanManagementApprovalStatus;
}

export default class ApprovalStatusTag extends PureComponent<IApprovalStatusTagProps, any> {
  TextPaymentMethodColors = {
    NOT_SENT: 'secondary',
    SENT: 'black',
    APPROVED: 'green',
    REJECTED: 'red-dark'
  };

  render() {
    const { status } = this.props;

    const textColor = this.TextPaymentMethodColors[status];
    const textLabel = PlanManagementApprovalStatusDisplay[status];

    return <Text className={`text-color-${textColor}`}>{textLabel}</Text>;
  }
}
