import React, { Component } from 'react';
import { Avatar, Checkbox, Col, notification, Row } from 'antd';
import { connect } from 'react-redux';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Title from 'antd/lib/typography/Title';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import moment from 'moment-timezone';
import _ from 'lodash';
import CommonUtils from 'utilities/common-utils';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';

interface ISchedulesStepPanelProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setCustomerToSchedule?: typeof dispatch.servicesStore.setCustomerToSchedule;
  doFetchGroupServiceSchedules?: typeof dispatch.servicesStore.doFetchGroupServiceSchedules;
  customerToSchedule: typeof state.servicesStore.customerToSchedule;
  groupServiceSchedules: typeof state.servicesStore.groupServiceSchedules;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  history?: any;
}

interface ISchedulesStepPanelState {
  isLoading: boolean;
  scheduleTimeSlotIds: string[];
  isNotScheduleSelectedError: boolean;
}

class SchedulesStepPanel extends Component<ISchedulesStepPanelProps, ISchedulesStepPanelState> {
  state = {
    isLoading: false,
    scheduleTimeSlotIds: this.props.customerToSchedule.scheduleTimeSlotIds
      ? this.props.customerToSchedule.scheduleTimeSlotIds
      : [],
    isNotScheduleSelectedError: false
  };

  private _goToNext = async () => {
    const { setCustomerToSchedule, customerToSchedule } = this.props;
    const { scheduleTimeSlotIds } = this.state;

    let isSelectionValid = true;
    if (scheduleTimeSlotIds.length === 0) {
      isSelectionValid = false;
      this.setState({ isNotScheduleSelectedError: true });
    }

    if (isSelectionValid) {
      try {
        await setCustomerToSchedule({
          ...customerToSchedule,
          scheduleTimeSlotIds
        });
        this.props.onNextStep();
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong! Please try again.' });
      }
    }
  };

  private _selectTimeSlot = (scheduleTimeSlotId) => {
    let newSelectedTimeSlotIds = this.state.scheduleTimeSlotIds;
    if (!_.find(newSelectedTimeSlotIds, (selectedTimeSlotId) => selectedTimeSlotId === scheduleTimeSlotId)) {
      newSelectedTimeSlotIds.push(scheduleTimeSlotId);
    } else {
      newSelectedTimeSlotIds = _.filter(this.state.scheduleTimeSlotIds, (timeSlot) => timeSlot !== scheduleTimeSlotId);
    }
    this.setState({ scheduleTimeSlotIds: newSelectedTimeSlotIds, isNotScheduleSelectedError: false });
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    await this.props.doFetchGroupServiceSchedules({
      serviceId: this.props.selectedGroupService.serviceId,
      finishAfter: new Date()
    });
    this.setState({ isLoading: false });
  };

  render() {
    const { customerToSchedule, groupServiceSchedules, selectedGroupService } = this.props;
    const { isLoading, scheduleTimeSlotIds, isNotScheduleSelectedError } = this.state;

    const scheduleWithoutTz = _.map(groupServiceSchedules, (schedule) => {
      return {
        ...schedule,
        scheduleStartDate: moment(
          moment.tz(schedule.scheduleStartDate, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm')
        ),
        scheduleEndDate: moment(
          moment.tz(schedule.scheduleEndDate, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm')
        ),
        scheduleConfig: {
          ...schedule.scheduleConfig,
          timeSlots: _.map(schedule.scheduleConfig.timeSlots, (timeSlot) => {
            return {
              ...timeSlot,
              startDateTime: moment(
                moment.tz(timeSlot.startDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm')
              ),
              endDateTime: moment(
                moment.tz(timeSlot.endDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm')
              )
            };
          })
        }
      };
    });

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>Select a schedule</Title>
              <Paragraph>
                Select which schedules you want to add this customer to. You can select multiple sessions if you would
                like.
              </Paragraph>
              {customerToSchedule && customerToSchedule.selectedCustomer ? (
                <div className={'p-medium bg-white rounded-big'}>
                  <SubTitle>Selected customer</SubTitle>
                  <div className={'flex-row align-center mt-small'}>
                    <Avatar
                      className={'mr-small'}
                      shape={'circle'}
                      icon={'user'}
                      src={customerToSchedule.selectedCustomer.attachmentUrl}
                    />{' '}
                    {customerToSchedule.selectedCustomer.firstName} {customerToSchedule.selectedCustomer.lastName}
                  </div>
                </div>
              ) : (
                <Text color={'secondary'}>No customer selected</Text>
              )}
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
              <Title level={4}>Add to schedule</Title>
              <Paragraph>
                Select the <b>schedule</b> that you'd like to add this customer to.
              </Paragraph>
              {isNotScheduleSelectedError && <Text color={'red-dark'}>Please select at least one schedule.</Text>}
              <div className={!isNotScheduleSelectedError && 'mt-large'}>
                {isLoading ? (
                  <SpinningLoader size={150} message={'Fetching schedules...'} />
                ) : (
                  _.map(scheduleWithoutTz, (schedule) => {
                    return (
                      <div className={'mb-large'}>
                        <div className={'p-medium bg-tertiary mb-x-small'}>
                          <Text weight={'bold'}>
                            {selectedGroupService.serviceName} - {schedule.scheduleName} (
                            {moment(schedule.scheduleStartDate).format('DD MMMM')} -{' '}
                            {moment(schedule.scheduleEndDate).format('DD MMMM')})
                          </Text>
                        </div>
                        {_.map(schedule.scheduleConfig && schedule.scheduleConfig.timeSlots, (timeSlot) => {
                          const isSelected = !!_.find(
                            scheduleTimeSlotIds,
                            (selectedTimeSlot) => selectedTimeSlot === timeSlot.scheduleTimeSlotId
                          );
                          return (
                            <div
                              className={`mb-x-small cursor-pointer flex-row p-medium bordered bordered-standard-grey ${isSelected &&
                                'bg-blue-lightest'}`}
                              onClick={() => this._selectTimeSlot(timeSlot.scheduleTimeSlotId)}
                            >
                              <div>
                                <Checkbox checked={isSelected} className={'mr-medium'} />
                              </div>
                              <div>
                                <Text>
                                  {CommonUtils.getRecurringPatternLabel(timeSlot.recurringPattern)} -{' '}
                                  {moment(timeSlot.startDateTime).format('dddd')},{' '}
                                  {moment(timeSlot.startDateTime).format('hh:mm A')} to{' '}
                                  {moment(timeSlot.endDateTime).format('hh:mm A')}
                                </Text>
                                <br />
                                <Text size={'regular'}>{timeSlot.description ? timeSlot.description : '-'}</Text>
                                <br />
                                <Text weight={'bold'}>{timeSlot.upcomingSession}</Text>{' '}
                                <Text size={'regular'} color={'secondary'}>
                                  available upcoming sessions
                                </Text>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <div className="pv-medium width-full bg-tertiary" style={{ position: 'sticky', bottom: 0 }}>
              <Row gutter={0} type="flex" align="middle" justify={'space-between'} className="bg-transparent">
                <div className="text-align-right pv-medium">
                  <SecondaryButton size="large" disabled={isLoading} onClick={this.props.onPreviousStep}>
                    Back
                  </SecondaryButton>
                </div>
                <div className="text-align-right pv-medium">
                  <PrimaryButton size="large" loading={isLoading} onClick={this._goToNext}>
                    Next
                  </PrimaryButton>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setCustomerToSchedule: dispatch.servicesStore.setCustomerToSchedule,
  doFetchGroupServiceSchedules: dispatch.servicesStore.doFetchGroupServiceSchedules
});

const mapState = (state: IRootState) => ({
  customerToSchedule: state.servicesStore.customerToSchedule,
  groupServiceSchedules: state.servicesStore.groupServiceSchedules,
  selectedGroupService: state.groupServiceStore.selectedGroupService
});

export default connect(
  mapState,
  mapDispatch
)(SchedulesStepPanel);
