/* eslint-disable */
import React, { PureComponent } from 'react';
import _ from 'lodash';
import { Text } from 'common-components/typography';

interface IPaymentSourceTagProps {
  paymentSource: 'NDIS' | 'VCP';
  size?: 'small' | 'default' | 'large';
  className?: any;
  textClassName?: any;
  rounded?: boolean;
  overrideDefaultTagColors?: any;
}

export default class PaymentSourceTag extends PureComponent<IPaymentSourceTagProps, any> {
  BackgroundColor = {
    NDIS: 'bordered bg-violet-dark',
    VCP: 'bordered bg-orange'
  };

  render() {
    const {
      paymentSource,
      className = '',
      textClassName,
      size = 'default',
      rounded = false,
      overrideDefaultTagColors
    } = this.props;

    let [backgroundColor, textColor, borderColor, hasBorder] = [
      this.BackgroundColor[paymentSource],
      'white',
      '',
      false
    ];

    if (overrideDefaultTagColors) {
      if (overrideDefaultTagColors[paymentSource].backgroundColor) {
        backgroundColor = overrideDefaultTagColors[paymentSource].backgroundColor;
      }
      if (overrideDefaultTagColors[paymentSource].textColor) {
        textColor = overrideDefaultTagColors[paymentSource].textColor;
      }
      if (overrideDefaultTagColors[paymentSource].borderColor) {
        borderColor = overrideDefaultTagColors[paymentSource].borderColor;
      }
      if (overrideDefaultTagColors[paymentSource].hasBorder) {
        hasBorder = overrideDefaultTagColors[paymentSource].hasBorder;
      }
    }

    const roundedClass = rounded ? 'rounded' : '';

    let sizeClasses = '';

    if (size === 'default') {
      sizeClasses = 'pv-x-small ph-small text-size-regular text-weight-bold';
    } else if (size === 'large') {
      sizeClasses = 'pv-small ph-small text-size-large text-weight-bold';
    } else if (size === 'small') {
      sizeClasses = 'pv-x3-small ph-x-small text-size-small text-weight-bold';
    }

    const containerClassNames = `text-align-center ph-small ${sizeClasses} rounded inline-block ${roundedClass} ${
      hasBorder ? 'bordered' : ''
    }  ${backgroundColor} ${className}`;

    const textClassNames = `text-color-${textColor} ${textClassName}`;
    return (
      <div className={containerClassNames} style={hasBorder ? { borderColor } : {}}>
        <Text className={textClassNames}>
          {_.chain(paymentSource)
            .upperCase()
            .value()}
        </Text>
      </div>
    );
  }
}
