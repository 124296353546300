import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text, FieldLabel } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';

import moment from 'moment-timezone';

import _ from 'lodash';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { Spinner } from '@blueprintjs/core';
import { ISession } from 'interfaces/session-interfaces';

interface IRemoveShiftSlotModalProps {
  isOpen: boolean;
  shiftSlot: IShiftSlot;
  onClose: any;
  doRemoveShiftSlot: any;
  session: ISession;
}

interface IRemoveShiftSlotModalState {
  step: number;
  canManuallyClose: boolean;
  refreshShiftSlots: boolean;
}

class RemoveShiftSlotModal extends Component<IRemoveShiftSlotModalProps, IRemoveShiftSlotModalState> {
  state = {
    step: 1,
    canManuallyClose: true,
    refreshShiftSlots: false
  };

  onRemove = async () => {
    const { doRemoveShiftSlot, session, shiftSlot } = this.props;
    const { serviceDateTimeId, serviceId } = session;
    const { supportWorkerAttendanceId } = shiftSlot;
    this.setState({ step: 2, canManuallyClose: false });
    const request = {
      serviceDateTimeId,
      serviceId,
      supportWorkerAttendanceIds: [supportWorkerAttendanceId],
      isRemoveTeamMemberOnly: false
    };
    await doRemoveShiftSlot(request);
    this.setState({ step: 3, canManuallyClose: true, refreshShiftSlots: true });
  };

  reset = () => {
    this.setState({ step: 1 });
  };

  onClose = () => {
    this.reset();
    const { onClose } = this.props;
    onClose({ targetFlag: 'isRemoveShiftSlotOpen' }, this.state.refreshShiftSlots);
  };

  render() {
    const { shiftSlot } = this.props;

    if (_.isEmpty(shiftSlot)) {
      return <></>;
    }

    const { shiftEndDateTime, shiftStartDateTime } = shiftSlot;

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title="Remove shift"
        onClose={this.onClose}
        canCloseOutside={this.state.canManuallyClose}
      >
        {this.state.step === 1 && (
          <div>
            <div className="mb-medium">
              <Text>You are about to remove following shift :</Text>
            </div>

            <div className="mb-medium">
              <FieldLabel text="shift time" />

              <Text>
                {moment.tz(shiftStartDateTime, this.props.session.timezone).format('h:mm A')} -{' '}
                {moment.tz(shiftEndDateTime, this.props.session.timezone).format('h:mm A, D MMMM YYYY')}
              </Text>
            </div>

            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium">
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this.onRemove}>
                Remove shift
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {this.state.step === 2 && (
          <div className="line-height-135 anim-slide-left">
            <div className="flex-column align-center mv-large justify-center">
              <div className="mb-medium">
                <Spinner size={80} />
              </div>
              <div className="text-align-center">
                <Text color="secondary" weight="bold">
                  Removing shift...
                </Text>
                <br />
                <Text color="secondary">This won't take long.</Text>
              </div>
            </div>
          </div>
        )}

        {this.state.step === 3 && (
          <div className="anim-fade-in-fast">
            <div className="mb-medium">
              <Text>
                The following shift have been <b>removed</b>.
              </Text>
            </div>

            <div className="mb-medium">
              <FieldLabel text="removed shift" />
              <Text>
                {moment.tz(shiftStartDateTime, this.props.session.timezone).format('h:mm A')} -{' '}
                {moment.tz(shiftEndDateTime, this.props.session.timezone).format('h:mm A, D MMMM YYYY')}
              </Text>
            </div>

            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this.onClose}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRemoveShiftSlot: dispatch.groupServiceStore.doRemoveShiftSlot
});

export default connect(
  mapState,
  mapDispatch
)(RemoveShiftSlotModal);
