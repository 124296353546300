// noinspection DuplicatedCode
import React, { Component } from 'react';
import _ from 'lodash';
import { Avatar, Col, Divider, Row } from 'antd';
import moment from 'moment-timezone';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { Spinner } from '@blueprintjs/core';
import { ISession } from 'interfaces/session-interfaces';
import { ISessionConflictShiftSlot, IShiftSlot } from 'interfaces/shift-interfaces';
import SpinningLoader from 'common-components/loading/SpinningLoader';

interface IConfirmShiftSlotModalProps {
  isOpen: boolean;
  shiftSlot: IShiftSlot;
  onClose: any;
  doShiftConfirmSlot: typeof dispatch.groupServiceStore.doShiftConfirmSlot;
  doRemoveShiftSlot: typeof dispatch.groupServiceStore.doRemoveShiftSlot;
  session: ISession;
  conflictShiftSlots: typeof state.groupServiceStore.conflictShiftSlots;
  doFetchConflictShiftSlots: typeof dispatch.groupServiceStore.doFetchConflictShiftSlots;
}

interface IConfirmShiftSlotModalState {
  step: number;
  canManuallyClose: boolean;
  isLoading: boolean;
  isConflict: boolean;
  shiftSlot: IShiftSlot;
  refreshShiftSlots: boolean;
}

class ConfirmShiftSlotModal extends Component<IConfirmShiftSlotModalProps, IConfirmShiftSlotModalState> {
  state = {
    step: 1,
    canManuallyClose: true,
    shiftSlot: this.props.shiftSlot,
    isLoading: false,
    isConflict: this.props.conflictShiftSlots && this.props.conflictShiftSlots.length > 0,
    refreshShiftSlots: false
  };

  reset = () =>
    this.setState({
      step: 1,
      canManuallyClose: true,
      isLoading: false,
      shiftSlot: this.props.shiftSlot,
      isConflict: this.props.conflictShiftSlots && this.props.conflictShiftSlots.length > 0,
      refreshShiftSlots: false
    });

  onConfirm = async () => {
    const { doShiftConfirmSlot, session, shiftSlot } = this.props;
    const { serviceDateTimeId, serviceId } = session;
    const { supportWorkerAttendanceId } = shiftSlot;
    this.setState({ step: 2, canManuallyClose: false });

    const request = { serviceDateTimeId, serviceId, supportWorkerAttendanceIds: [supportWorkerAttendanceId] };
    await doShiftConfirmSlot(request);
    this.setState({ step: 3, canManuallyClose: true, refreshShiftSlots: true });
  };

  onRemoveTeamMember = async () => {
    const { doRemoveShiftSlot, session, shiftSlot } = this.props;
    const { serviceDateTimeId, serviceId } = session;
    const { supportWorkerAttendanceId } = shiftSlot;
    this.setState({ step: 2, canManuallyClose: false, refreshShiftSlots: true });
    const request = {
      serviceDateTimeId,
      serviceId,
      supportWorkerAttendanceIds: [supportWorkerAttendanceId],
      isRemoveTeamMemberOnly: true
    };
    await doRemoveShiftSlot(request);
    this.setState({ step: 3, canManuallyClose: true });
  };

  onClose = () => {
    const { onClose } = this.props;
    this.reset();
    onClose({ targetFlag: 'isConfirmShiftSlotOpen' }, this.state.refreshShiftSlots);
  };

  componentDidUpdate = async (prevProps) => {
    // Check conflicts every time the modal is open.
    if (this.props.isOpen && !prevProps.isOpen) {
      const { session, shiftSlot, doFetchConflictShiftSlots } = this.props;
      const { serviceDateTimeId, serviceId } = session;
      const { supportWorkerAttendanceId } = shiftSlot;
      const payload = { serviceDateTimeId, serviceId, supportWorkerAttendanceIds: [supportWorkerAttendanceId] };
      this.setState({ isLoading: true });
      await doFetchConflictShiftSlots(payload);
      this.setState({ isLoading: false });
    }
    if (this.props.conflictShiftSlots !== prevProps.conflictShiftSlots) {
      this.setState({ isConflict: this.props.conflictShiftSlots && this.props.conflictShiftSlots.length > 0 });
    }
    if (this.props.shiftSlot !== prevProps.shiftSlot) {
      this.setState({ shiftSlot: this.props.shiftSlot });
    }
  };

  render() {
    const { session } = this.props;
    const { step, isLoading, isConflict, shiftSlot } = this.state;

    if (!shiftSlot) {
      return <></>;
    }

    const { shiftStartDateTime, shiftEndDateTime } = shiftSlot;
    const { timezone } = session;
    const startDateTime = moment.tz(shiftStartDateTime, timezone);
    const endDateTime = moment.tz(shiftEndDateTime, timezone);
    const displayDateTime = `${startDateTime.format('hh:mm A')} - ${endDateTime.format(
      'hh:mm A'
    )}, ${endDateTime.format('D MMMM YYYY')}`;
    const workerFullName = `${shiftSlot.firstName || ''} ${shiftSlot.lastName || ''}`;
    const modalTitle =
      step === 3 ? 'Shift confirmed' : step === 1 && isConflict ? 'Team member conflicts' : 'Confirm shift';

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title={modalTitle}
        onClose={this.onClose}
        canCloseOutside={this.state.canManuallyClose}
      >
        {isLoading ? (
          <SpinningLoader size={150} message={'Loading...'} />
        ) : (
          <>
            {step === 1 && !isConflict && (
              <div>
                <div className="mb-medium">
                  <Text>You are about to confirm the following shift : </Text>
                </div>

                <div className="line-height-135 mb-medium">
                  <FieldLabel text={'SHIFT TIME'} />
                  <Text lineHeight={135}>{displayDateTime}</Text>
                </div>

                <div className="line-height-135 mb-medium">
                  <FieldLabel text={'ASSIGNED TO'} />
                  <div className="flex-row line-height-135 align-center">
                    <Avatar icon="user" src={shiftSlot.attachmentUrl} className="mr-small" />
                    <Text lineHeight={135}>{workerFullName}</Text>
                  </div>
                </div>

                <ActionModalFooter align="right">
                  <div className="flex-row align-center justify-end">
                    <SecondaryButton size="large" className="mr-medium" onClick={this.onClose}>
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton size="large" onClick={this.onConfirm}>
                      Confirm shift
                    </PrimaryButton>
                  </div>
                </ActionModalFooter>
              </div>
            )}

            {step === 1 && isConflict && (
              <>
                <div className="anim-fade-in-fast">
                  <div className="mb-medium">
                    <Text>
                      The selected team member cannot be confirmed for this shift as they are confirmed for another
                      shift at a conflicting time.
                      <br />
                      <br />
                      You can either resolve the conflict, or remove this team member and re-assign it to another team
                      member.
                    </Text>
                  </div>
                  <Row className="text-color-secondary text-uppercase pt-medium">
                    <Col span={7} className="ph-medium">
                      Team member
                    </Col>
                    <Col span={7} className="ph-medium">
                      Shift slot time
                    </Col>
                    <Col span={10} className="pl-medium">
                      conflicting session/booking
                    </Col>
                  </Row>
                  <Divider className="mv-medium mb-none" />
                  {_.map(this.props.conflictShiftSlots, (shiftSlot) => (
                    <ConflictResult conflictShiftSlot={shiftSlot} session={session} />
                  ))}
                </div>

                <ActionModalFooter align="right">
                  <div className="flex-row align-center justify-end">
                    <SecondaryButton size="large" className="mr-medium" onClick={this.onClose}>
                      Cancel
                    </SecondaryButton>
                    <PrimaryButton size="large" onClick={this.onRemoveTeamMember}>
                      Remove team member from shift slot
                    </PrimaryButton>
                  </div>
                </ActionModalFooter>
              </>
            )}

            {step === 2 && (
              <div className="line-height-135 anim-slide-left">
                <div className="flex-column align-center mv-large justify-center">
                  <div className="mb-medium">
                    <Spinner size={80} />
                  </div>
                  <div className="text-align-center">
                    <Text color="secondary" weight="bold">
                      {isConflict ? 'Removing team member...' : 'Confirming shift...'}
                    </Text>
                    <br />
                    <Text color="secondary">This won't take long.</Text>
                  </div>
                </div>
              </div>
            )}

            {step === 3 && !isConflict && (
              <div className="anim-fade-in-fast">
                <div className="mb-medium">
                  <Text>You have successfully confirmed this shift slot.</Text>
                </div>

                <div className="line-height-135 mb-medium">
                  <FieldLabel text={'SHIFT TIME'} />
                  <Text lineHeight={135}>{displayDateTime}</Text>
                </div>

                <div className="line-height-135 mb-medium">
                  <FieldLabel text={'ASSIGNED TO'} />
                  <div className="flex-row line-height-135 align-center mt-x2-small">
                    <Avatar icon="user" src={shiftSlot.attachmentUrl} className="mr-small" />
                    <Text lineHeight={135}>{workerFullName} (Confirmed)</Text>
                  </div>
                </div>

                <ActionModalFooter align="right">
                  <PrimaryButton size="large" onClick={this.onClose}>
                    Close
                  </PrimaryButton>
                </ActionModalFooter>
              </div>
            )}

            {step === 3 && isConflict && (
              <div className="anim-fade-in-fast">
                <div className="mb-medium">
                  <Text>
                    You have removed <b>{workerFullName}</b> from this shift slot.
                  </Text>
                </div>

                <ActionModalFooter align="right">
                  <PrimaryButton size="large" onClick={this.onClose}>
                    Close
                  </PrimaryButton>
                </ActionModalFooter>
              </div>
            )}
          </>
        )}
      </ActionModal>
    );
  }
}

interface IConflictResultProps {
  conflictShiftSlot: ISessionConflictShiftSlot;
  session: ISession;
}

function ConflictResult(props: IConflictResultProps) {
  const { conflictShiftSlot, session } = props;
  const { firstName, lastName, startDateTime, endDateTime } = conflictShiftSlot;
  const { timezone } = session;
  const workerFullName = `${firstName || ''} ${lastName || ''}`;
  const shiftStartDateTime = moment.tz(startDateTime, timezone);
  const shiftEndDateTime = moment.tz(endDateTime, timezone);

  return (
    <Row style={{ height: '103px' }} className="mb-medium">
      <Col span={7} className="ph-medium height-full">
        <div className="flex-row height-full align-center">
          <Avatar icon="user" src={conflictShiftSlot.attachmentUrl} className="mr-small" />
          <Text lineHeight={135} weight="bold">
            {workerFullName}
          </Text>
        </div>
      </Col>
      <Col span={7} className="ph-medium  height-full">
        <div className="height-full flex-row align-center">
          <Text>
            {shiftStartDateTime.format('D MMMM YYYY')}
            <br />
            {shiftStartDateTime.format('hh:mm A')} - {shiftEndDateTime.format('hh:mm A')}
          </Text>
        </div>
      </Col>
      <Col span={10} className="ph-medium  height-full">
        <div className="height-full flex-row align-center">
          <Text>
            {conflictShiftSlot.referenceId && (
              <Text color="blue-action" weight="bold">
                {conflictShiftSlot.referenceId}
              </Text>
            )}
            {shiftStartDateTime.format('D MMMM YYYY')} <br /> {shiftStartDateTime.format('hh:mm A')} -{' '}
            {shiftEndDateTime.format('hh:mm A')}
          </Text>
        </div>
      </Col>
    </Row>
  );
}

const mapState = (state: IRootState) => ({
  conflictShiftSlots: state.groupServiceStore.conflictShiftSlots
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doShiftConfirmSlot: dispatch.groupServiceStore.doShiftConfirmSlot,
  doFetchConflictShiftSlots: dispatch.groupServiceStore.doFetchConflictShiftSlots,
  doRemoveShiftSlot: dispatch.groupServiceStore.doRemoveShiftSlot
});

export default connect(
  mapState,
  mapDispatch
)(ConfirmShiftSlotModal);
