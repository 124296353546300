import React, { Component } from 'react';
import { Paragraph, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import TermsConditionsEditor from 'views/customers/details/tabs-panel/service-agreement/TermsConditionsEditor';
import { Warning } from 'common-components/alerts';
import { notification } from 'antd';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { IServiceAgreement } from 'interfaces/customer-interfaces';

interface IEditServiceAgreementTermConditionsModalProps {
  closeEdit: () => void;
  isOpen: boolean;
  serviceAgreement: IServiceAgreement;
  doUpdateServiceAgreementTermsCondition: typeof dispatch.customersStore.doUpdateServiceAgreementTermsCondition;
}

interface IEditServiceAgreementTermConditionsModalState {
  isLoading: boolean;
  isErrorEndDate: boolean;
  isProceedModalOpen: boolean;
}

class EditServiceAgreementTermConditionsModal extends Component<
  IEditServiceAgreementTermConditionsModalProps,
  IEditServiceAgreementTermConditionsModalState
> {
  state = {
    isLoading: false,
    isErrorEndDate: false,
    isProceedModalOpen: false
  };
  private editor: any;

  private _saveNewTAndC = async () => {
    this.setState({ isLoading: true });
    try {
      const termsValidationResult = this.editor.validate();
      if (await termsValidationResult.isValid) {
        await this.props.doUpdateServiceAgreementTermsCondition({
          serviceAgreementId: this.props.serviceAgreement.userServiceAgreementId,
          termsConditions: termsValidationResult.content
        });
      }
      notification.success({ message: 'Service agreement updated successfully.' });
      this.props.closeEdit();
      this._closeProceedModal();
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isLoading: false });
  };

  private _openProceedModal = () => {
    this.setState({ isProceedModalOpen: true });
  };

  private _closeProceedModal = () => {
    this.setState({ isProceedModalOpen: false });
  };

  render() {
    const { serviceAgreement } = this.props;

    return (
      <>
        <ActionModal
          isOpen={this.state.isProceedModalOpen}
          onClose={this._closeProceedModal}
          title={'Editing a signed service agreement'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You are making a change to a <b>signed</b> service agreement. Doing so will revert the status of this
            service agreement to <b>unsigned. Are you sure you want to make this change?</b>
          </Text>
          <br />
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._closeProceedModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._saveNewTAndC}>
              Save changes
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this.props.closeEdit}
          width={'x-large'}
          title={
            <>
              Edit <b>Terms & Conditions</b>
            </>
          }
        >
          {serviceAgreement && (
            <>
              <Paragraph>
                Please enter your <b>terms and conditions</b> for this <b>Service Agreement</b>.
              </Paragraph>
              <div className="mt-large mb-x2-large">
                {/* Content here */}
                <TermsConditionsEditor
                  contentHtml={
                    this.props.serviceAgreement.termsConditions ? this.props.serviceAgreement.termsConditions : ''
                  }
                  minHeight={'200px'}
                  ref={(element) => {
                    this.editor = element;
                  }}
                />
              </div>
              <ActionModalFooter>
                <SecondaryButton size="large" onClick={this.props.closeEdit} className="mr-medium">
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  onClick={serviceAgreement.signedStatus === 'SIGNED' ? this._openProceedModal : this._saveNewTAndC}
                  loading={this.state.isLoading}
                >
                  Save
                </PrimaryButton>
              </ActionModalFooter>
            </>
          )}
        </ActionModal>
      </>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateServiceAgreementTermsCondition: dispatch.customersStore.doUpdateServiceAgreementTermsCondition
});

export default connect(
  null,
  mapDispatch
)(EditServiceAgreementTermConditionsModal);
