import { Tooltip2 } from '@blueprintjs/popover2';
import { DatePicker, Empty, notification, Select, Skeleton } from 'antd';
import { IconButton, PrimaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import ActionModal from 'common-components/modal/ActionModal';
import { ItemCountSheet } from 'common-components/Sheets/ItemCountSheet';
import { parse } from 'json2csv';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import ShiftItemRow from 'views/billings/payments/components/details/ShiftItemRow';
import { SubTitle, Text, Title } from '../../../../common-components/typography';

const fileDownload = require('js-file-download');
const { RangePicker } = DatePicker;

interface IShiftHoursSectionProps {
  shiftHoursList?: typeof state.billingsStore.shiftHoursList;
  shiftHoursFilter?: typeof state.billingsStore.shiftHoursFilter;
  currentFilterConfig?: any;
  history?: any;

  doFetchShiftHours: typeof dispatch.billingsStore.doFetchShiftHours;
  setShiftHours?: typeof dispatch.billingsStore.setShiftHours;
  setShiftHoursFilter?: typeof dispatch.billingsStore.setShiftHoursFilter;

  doFetchServicesLite: typeof dispatch.servicesStore.doFetchServicesLite;
  servicesLite: typeof state.servicesStore.servicesLite;
  portalUser: typeof state.authStore.portalUser;
  doFetchCustomTimesheet: typeof dispatch.billingsStore.doFetchCustomTimesheet;
  setZetCareTimesheet: typeof dispatch.billingsStore.setZestCareTimesheet;
  timesheet: typeof state.billingsStore.timesheet;
  entitlements: typeof state.billingsStore.entitlements;
}

interface IShiftHoursSectionState {
  topHeight: number;
  showFilters: boolean;
  isLoading: boolean;
  isSearching: boolean;
  filters: any;
  isExporting: boolean;
  isExportTimesheet: boolean;
}

const ShiftHoursEmptyState = () => (
  <div className="flex-1 bg-white  align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No Shifts found.
    </Text>{' '}
    <br /> <br />
    <Text color="secondary">All shifts under this filter will appear here.</Text>
    <Text color="secondary">Try adjusting your filter.</Text>
  </div>
);

class ShiftHoursSection extends PureComponent<IShiftHoursSectionProps, IShiftHoursSectionState> {
  // topHeight is used to control sticky
  state = {
    topHeight: 0,
    showFilters: false,
    isLoading: false,
    isSearching: false,
    filters: [],
    isExporting: false,
    isExportTimesheet: false
  };

  _headerElement = null;

  private _handleHeaderHeight = () => {
    if (this._headerElement) {
      this.setState({ topHeight: this._headerElement.offsetHeight - 1 });
    }
  };

  private _addfilterDateRange = async (e) => {
    const { setShiftHoursFilter, shiftHoursFilter, doFetchShiftHours, setZetCareTimesheet } = this.props;
    this.setState({ isLoading: true });

    const newFilters = { ...shiftHoursFilter };
    if (e[0] && e[1]) {
      newFilters['startDate'] = e[0].toDate().toISOString();
      newFilters['endDate'] = e[1].toDate().toISOString();
    }
    await setShiftHoursFilter(newFilters);
    setZetCareTimesheet({ workerShiftData: [], workerTransportData: [] });
    await doFetchShiftHours(this.props.shiftHoursFilter);
    this.setState({ isLoading: false }, () => this._handleHeaderHeight());
  };

  private _addfilterServices = async (e) => {
    const { setShiftHoursFilter, shiftHoursFilter, doFetchShiftHours, setZetCareTimesheet } = this.props;
    const newFilters = { ...shiftHoursFilter };
    if (e !== 'ALL') {
      newFilters['serviceIds'] = [e];
    } else {
      newFilters['serviceIds'] = [];
    }
    await setShiftHoursFilter(newFilters);
    setZetCareTimesheet({ workerShiftData: [], workerTransportData: [] });
    await doFetchShiftHours(this.props.shiftHoursFilter);
    this.setState({ isLoading: false }, () => this._handleHeaderHeight());
  };

  private _refreshListings = async () => {
    const { doFetchShiftHours, shiftHoursFilter, setShiftHours, setZetCareTimesheet } = this.props;
    this.setState({ isLoading: true });

    setShiftHours([]);
    setZetCareTimesheet({ workerShiftData: [], workerTransportData: [] });
    await doFetchShiftHours({
      ...shiftHoursFilter
    });
    this.setState({ isLoading: false });
  };

  private _downloadCSVFile = async () => {
    const { shiftHoursList } = this.props;
    try {
      const csvData = parse(
        _.map(shiftHoursList, (shift) => {
          return {
            workerName: shift.firstName + ' ' + shift.lastName,
            shiftStartDate: moment.tz(shift.startDateTime, shift.timezone).format('DD-MM-YY HH:mm'),
            shiftEndDate: moment.tz(shift.endDateTime, shift.timezone).format('DD-MM-YY HH:mm'),
            serviceName: shift.serviceName,
            serviceStockNumber: shift.serviceStockNumber,
            duration: shift.duration
          };
        }),
        { eol: '\r\n\r\n' }
      );
      // Generate the csv
      fileDownload(csvData, `shift-hours-export-${moment().format('DD-MM-YYYY-HH-mm')}.csv`);
    } catch (e) {
      notification.error({
        message: 'Error while generating the export shift hours file',
        description: 'The file generation failed, please try again.'
      });
    }
  };

  private _downloadEntitlementsFile = async () => {
    this.setState({ isExporting: true, isExportTimesheet: false });
    const { shiftHoursFilter, doFetchCustomTimesheet, entitlements } = this.props;

    let csvData;
    if (entitlements.length === 0) {
      const resp: any = await doFetchCustomTimesheet(shiftHoursFilter);

      let data = resp.entitlenmentData;
      if (data.length === 0) {
        data = [
          {
            EmployeeCode: null,
            ManualEntitlementCode: null,
            StartDateandTime: null,
            EndDateandTime: null,
            'Units/Hours': null,
            IsReversal: null,
            Comments: null
          }
        ];
      }

      csvData = parse(data, { quote: '' });
    } else {
      csvData = parse(entitlements, { quote: '' });
    }
    try {
      // Generate the csv
      csvData += '\r\n';
      fileDownload(csvData, `Entitlement${moment().format('YYYYMMDDHHmm')}.csv`);
      this.setState({ isExporting: false });
    } catch (e) {
      notification.error({
        message: 'Error while generating the export entitlements file',
        description: 'The file generation failed, please try again.'
      });
    }
  };

  private _downloadZestCareTimesheetFile = async () => {
    this.setState({ isExporting: true, isExportTimesheet: true });

    const { shiftHoursFilter, doFetchCustomTimesheet, timesheet } = this.props;

    let csvData;
    if (timesheet.length === 0) {
      const resp: any = await doFetchCustomTimesheet(shiftHoursFilter);

      let data = resp.workerShiftData;
      if (data.length === 0) {
        data = [
          {
            EmployeeCode: null,
            ClockingType: null,
            DateandTimeofClocking: null
          }
        ];
      }
      csvData = parse(data, { quote: '' });
    } else {
      csvData = parse(timesheet, { quote: '' });
    }

    try {
      // Generate the csv
      csvData += '\r\n';
      fileDownload(csvData, `Clocking${moment().format('YYYYMMDDHHmm')}.csv`);
      this.setState({ isExporting: false });
    } catch (e) {
      notification.error({
        message: 'Error while generating the export timesheet file',
        description: 'The file generation failed, please try again.'
      });
    }
  };

  private _downloadEmploymentHeroTimesheetFile = async () => {
    this.setState({ isExporting: true, isExportTimesheet: true });

    const { shiftHoursFilter, doFetchCustomTimesheet, timesheet } = this.props;

    let csvData;
    if (timesheet.length === 0) {
      const resp: any = await doFetchCustomTimesheet(shiftHoursFilter);

      let data = resp.workerShiftData;
      if (data.length === 0) {
        data = [
          {
            'Employee ID': null,
            Date: null,
            'Start Time': null,
            'End Time': null,
            Units: null,
            'Work Type': null,
            'Work Type External ID': null
          }
        ];
      }
      csvData = parse(data, { quote: '' });
    } else {
      csvData = parse(timesheet, { quote: '' });
    }

    try {
      // Generate the csv
      csvData += '\r\n';
      fileDownload(csvData, `EmploymentHero${moment().format('YYYYMMDDHHmm')}.csv`);
      this.setState({ isExporting: false });
    } catch (e) {
      notification.error({
        message: 'Error while generating the export timesheet file',
        description: 'The file generation failed, please try again.'
      });
    }
  };

  //region Component Lifecycle Methods
  componentDidMount = async () => {
    // Automatically set the top height for the top panel. This is required for sticky.
    this._handleHeaderHeight();
    const {
      doFetchShiftHours,
      currentFilterConfig,
      shiftHoursFilter,
      setShiftHoursFilter,
      doFetchServicesLite,
      setZetCareTimesheet
    } = this.props;

    const appliedFilters = _.isEmpty(shiftHoursFilter) ? currentFilterConfig.filters : shiftHoursFilter;

    if (_.isEmpty(shiftHoursFilter)) {
      setShiftHoursFilter(currentFilterConfig.filters);
    }
    await this.setState({ isLoading: true });

    setZetCareTimesheet({ workerShiftData: [], workerTransportData: [] });
    await doFetchShiftHours(appliedFilters);
    await doFetchServicesLite({});
    this.setState({ isLoading: false });
  };

  componentDidUpdate = async (prevProps) => {
    const { currentFilterConfig } = this.props;

    if (prevProps.currentFilterConfig.key !== currentFilterConfig.key) {
      this.setState({
        isLoading: true,
        showFilters: false
      });
    }
  };

  render() {
    const { shiftHoursList, shiftHoursFilter, servicesLite, history, currentFilterConfig, portalUser } = this.props;

    const { isExporting, isExportTimesheet } = this.state;

    const groupedList = [];
    _.map(shiftHoursList, (shift) => {
      const user = _.find(groupedList, (group) => group.userId === shift.userId);
      if (user) {
        user.items.push(shift);
      } else {
        groupedList.push({
          firstName: shift.firstName,
          lastName: shift.lastName,
          userId: shift.userId,
          supportWorkerId: shift.supportWorkerId,
          items: [shift]
        });
      }
    });

    return (
      <div className="bg-white flex-1 width-full flex-column" style={{ overflowY: 'auto', position: 'relative' }}>
        <ActionModal
          title={`Export ${isExportTimesheet ? 'timesheet' : 'entitlements'}`}
          isOpen={isExporting}
          width="medium"
          showCloseButton={false}
        >
          {/* <div className="anim-slide-left"> */}

          <SpinningLoader size={150} message={'Exporting...'} />
        </ActionModal>
        <div>
          <div className="booking-header" ref={(com) => (this._headerElement = com)}>
            <div className="flex-row justify-between align-center">
              <div>
                <Title level={3} className="mv-none" lineHeight={150}>
                  {currentFilterConfig.title}
                </Title>
                <Text color="secondary">{currentFilterConfig.description}</Text>
              </div>
              <div className="flex-column align-center">
                <div className="pr-x-small">
                  <Tooltip2 content="Refresh this view" position="top">
                    <IconButton
                      color="white"
                      onClick={this._refreshListings}
                      iconColor="black"
                      size="large"
                      icon="reload"
                    />
                  </Tooltip2>
                </div>
              </div>
            </div>

            <div className="flex-row align-center justify-between mt-large">
              <div className="flex-row align-center flex-wrap">
                <div className="mr-x2-large mb-small">
                  <Text weight="bold">Date Range</Text>
                  <br />
                  <RangePicker
                    showTime={{
                      hideDisabledOptions: true
                    }}
                    format="DD/MM/YYYY"
                    allowClear={false}
                    defaultValue={
                      shiftHoursFilter.startDate && shiftHoursFilter.endDate
                        ? [moment(shiftHoursFilter.startDate), moment(shiftHoursFilter.endDate)]
                        : [
                            moment()
                              .add(-4, 'weeks')
                              .startOf('day'),
                            moment().endOf('day')
                          ]
                    }
                    onChange={this._addfilterDateRange}
                  />
                </div>
                <div className="mr-large mb-small">
                  <Text weight="bold">Services</Text>
                  <br />
                  <Select
                    defaultValue={shiftHoursFilter.services ? shiftHoursFilter.services[0] : 'ALL'}
                    className="mr-small"
                    onChange={this._addfilterServices}
                    style={{ width: '300px' }}
                  >
                    <Select.Option value="ALL">All services</Select.Option>
                    {_.map(servicesLite, (service) => (
                      <Select.Option value={service.serviceId}>{service.serviceName}</Select.Option>
                    ))}
                  </Select>
                </div>
                {portalUser.integrationConfig.timesheetExport &&
                portalUser.integrationConfig.timesheetExport.style === 'ZESTCARE-CONNX' ? (
                  <div className="flex-row align-center">
                    <div className="mr-small">
                      <PrimaryButton className={'mt-medium'} onClick={this._downloadZestCareTimesheetFile}>
                        Export Timesheet
                      </PrimaryButton>
                    </div>
                    <PrimaryButton className={'mt-medium'} onClick={this._downloadEntitlementsFile}>
                      Export Entitlements
                    </PrimaryButton>
                  </div>
                ) : portalUser.integrationConfig.timesheetExport &&
                  portalUser.integrationConfig.timesheetExport.style === 'EMPLOYMENT_HERO' ? (
                  <div className="flex-row align-center">
                    <div className="mr-small">
                      <PrimaryButton className={'mt-medium'} onClick={this._downloadEmploymentHeroTimesheetFile}>
                        Export as CSV
                      </PrimaryButton>
                    </div>
                  </div>
                ) : (
                  <PrimaryButton className={'mt-medium'} onClick={this._downloadCSVFile}>
                    Export as CSV
                  </PrimaryButton>
                )}
              </div>
            </div>
          </div>

          <table className="payment-listing">
            <thead>
              <tr>
                <th style={{ top: `${this.state.topHeight}px` }} className="ellipsis header">
                  <SubTitle>Team Member</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px`, textAlign: 'right' }} className="header nowrap">
                  <SubTitle>Number of Shift</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px`, textAlign: 'right' }} className="header nowrap">
                  <SubTitle>Total KM&apos;s</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px`, textAlign: 'right' }} className="header nowrap">
                  <SubTitle>Total Shift Hours</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px`, width: '50px' }} />
              </tr>
            </thead>

            <tbody>
              {this.state.isLoading && (
                <tr style={{ borderBottom: '0px solid !important' }}>
                  <td colSpan={7}>
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                  </td>
                </tr>
              )}

              {!this.state.isLoading && _.isEmpty(groupedList) && (
                <tr style={{ cursor: 'default' }}>
                  <td colSpan={7} style={{ borderBottom: '0px solid' }}>
                    <ShiftHoursEmptyState />
                  </td>
                </tr>
              )}

              {!this.state.isLoading &&
                _.map(groupedList, (shiftItem) => (
                  <ShiftItemRow shiftItem={shiftItem} history={history} key={shiftItem.userId} />
                ))}
            </tbody>
          </table>
        </div>

        {/* Filler */}
        <div className="flex-1 bg-white">&nbsp;</div>

        {!this.state.isLoading && shiftHoursList.length > 0 && (
          <ItemCountSheet itemCount={shiftHoursList.length} itemLabel="Shift" />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  servicesLite: state.servicesStore.servicesLite,
  portalUser: state.authStore.portalUser,
  timesheet: state.billingsStore.timesheet,
  entitlements: state.billingsStore.entitlements
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServicesLite: dispatch.servicesStore.doFetchServicesLite,
  doFetchCustomTimesheet: dispatch.billingsStore.doFetchCustomTimesheet,
  setZetCareTimesheet: dispatch.billingsStore.setZestCareTimesheet
});

export default connect(mapState, mapDispatch)(ShiftHoursSection);
