import React, { Component } from 'react';
import { Col, notification, Row } from 'antd';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import { Paragraph, Text, Title } from 'common-components/typography';
// import { StepPanelProps } from 'views/account-management/add-new-member/add-member-interfaces';
import { ActionSelectSection } from 'views/account-management/add-new-member/panels/invite-panel/ActionSelectSection';
import { INewMemberData } from 'interfaces/account-interfaces';
import { Spinner } from '@blueprintjs/core';
import { dispatch, IRootDispatch, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';

/* Spinner while create Team Member process */
class CreatingTeamPanel extends Component<{ newTeamMemberName: string }> {
  render() {
    return (
      <div className="anim-slide-left select-none">
        <Row className="ph-x2-large">
          {/* Left Column */}
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 142px)', overflow: 'auto' }}>
            <div style={{ maxWidth: '80%', minHeight: 'calc(100vh - 262px)' }}>
              {/* <-- ! Left Content Starts here ! --> */}
            </div>
          </Col>

          {/* Right Column */}
          <Col span={18} style={{ minHeight: 'calc(100vh - 142px)', maxWidth: '820px' }} className="pl-large">
            <div style={{ minHeight: 'calc(100vh - 262px)' }} className="flex-column">
              {/* <-- ! Right Content Starts here ! --> */}
              <Title level={2} weight="bolder" className="line-height-100 mb-x-large">
                <span className="text-weight-regular">Adding a new </span> Team Member
              </Title>
              <div className="flex-row align-center mb-x-large">
                <div className="mr-x-large">
                  <Spinner size={120} />
                </div>
                <div>
                  <Paragraph>
                    Adding the new team member: <b>{this.props.newTeamMemberName}</b>.
                  </Paragraph>
                  <Paragraph weight="bold">Finalizing team member details...</Paragraph>
                  <Paragraph>This won't take long.</Paragraph>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

interface IStatusAndInvitePanelProps {
  onStepNext: any;
  onStepPrevious: any;
  newMemberData?: INewMemberData;
  setNewMemberData?: any;
  doCreateNewTeamMember?: typeof dispatch.teamStore.doCreateNewTeamMember;
}

interface IStatusAndInvitePanelState {
  isLoading: boolean;
}

class StatusAndInvitePanel extends React.Component<IStatusAndInvitePanelProps, IStatusAndInvitePanelState> {
  state = { isLoading: false };

  onSelectAction = (action) => {
    const { newMemberData, setNewMemberData } = this.props;
    const updatedData: INewMemberData = { ...newMemberData, createMode: action };
    setNewMemberData(updatedData);
  };

  onConfirmNext = async () => {
    const { newMemberData } = this.props;
    try {
      this.setState({ isLoading: true });
      const payload = {
        firstName: newMemberData.firstName,
        lastName: newMemberData.lastName,
        email: newMemberData.email,
        mobile: newMemberData.mobileNumber,
        mobileCountryCode: 'AU',
        isDraft: newMemberData.createMode === 'draft',
        hasWorkerAppAccess: newMemberData.permissions.hasWorkerAppAccess,
        hasPortalAccess: newMemberData.permissions.hasPortalAccess,
        permissionRoles: newMemberData.permissions.permissionRoles
      };
      const supportWorkerId = await this.props.doCreateNewTeamMember(payload);
      await this.props.setNewMemberData({ ...newMemberData, supportWorkerId });
      this.setState({ isLoading: false });
      this.props.onStepNext();
    } catch (e) {
      this.setState({ isLoading: false });
      if (e.meta.message === 'email must be an email') {
        notification.error({
          message: 'Invalid email',
          description: 'The email entered is invalid, please go back and update it.'
        });
      } else {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
  };

  render() {
    let { onStepPrevious, newMemberData } = this.props;

    const { createMode } = newMemberData;

    if (this.state.isLoading) {
      return <CreatingTeamPanel newTeamMemberName={newMemberData.firstName + ' ' + newMemberData.lastName} />;
    }

    return (
      <div className="anim-slide-left select-none">
        <Row className="ph-x2-large">
          {/* Left Column */}
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 142px)', overflow: 'auto' }}>
            <div style={{ maxWidth: '80%', minHeight: 'calc(100vh - 262px)' }}>
              {/* <-- ! Left Content Starts here ! --> */}

              {/* Left Title */}
              <Title level={3} weight="regular">
                Invite to join
              </Title>

              {/* Left Description */}
              {/*<Text size="large">*/}
              {/*  Visit the <HyperlinkButton>Help Center</HyperlinkButton> to learn more.*/}
              {/*</Text>*/}
            </div>

            {/* Left Button */}
            <div
              className="pv-x2-large width-full text-align-left"
              style={{ backgroundColor: 'rgba(248,249,250,0.7)', position: 'sticky', bottom: 0 }}
            >
              <PrimaryButton size="large" onClick={onStepPrevious}>
                Back
              </PrimaryButton>
            </div>
          </Col>

          {/* Right Column */}
          <Col span={18} style={{ minHeight: 'calc(100vh - 142px)', maxWidth: '820px' }} className="pl-large">
            <div style={{ minHeight: 'calc(100vh - 262px)' }}>
              {/* <-- ! Right Content Starts here ! --> */}
              <div className="bg-white p-large bordered border-standard-gray rounded">
                {/*  White Panel stuff*/}
                <div className="mb-small">
                  <Text size="x2-large">Create account now</Text>
                </div>
                <div className="mb-x-large">
                  <Text>
                    Do you want to create and invite this team member now or save as a draft to complete later.
                  </Text>
                </div>

                <ActionSelectSection selectedAction={createMode} onSelectAction={this.onSelectAction} />
              </div>
            </div>

            {/* Right Button */}
            <div
              className="pv-x2-large width-full text-align-right"
              style={{ backgroundColor: 'rgba(248,249,250,0.7)', position: 'sticky', bottom: 0 }}
            >
              <PrimaryButton disabled={_.isEmpty(newMemberData.createMode)} size="large" onClick={this.onConfirmNext}>
                Next
              </PrimaryButton>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateNewTeamMember: dispatch.teamStore.doCreateNewTeamMember
});

export default connect(
  null,
  mapDispatch
)(StatusAndInvitePanel);
