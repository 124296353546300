import React, { PureComponent } from 'react';
import { BookingType, ServiceType } from 'utilities/enum-utils';
import { FaUserFriends, FaUsers, FaUserSlash } from 'react-icons/fa';

interface IBookingTypeIconProps {
  size?: 'small' | 'default' | 'large';
  className?: any;
  type: 'GROUP' | 'INDIVIDUAL' | 'ACTIVITY_RECORD';
  style?: any;
}

export default class BookingTypeIcon extends PureComponent<IBookingTypeIconProps, any> {
  private _getIcon = (type, className, style) => {
    if (type === ServiceType.GROUP) {
      return <FaUsers className={className} />;
    } else if (type === ServiceType.INDIVIDUAL) {
      return <FaUserFriends className={className} />;
    } else if (type === BookingType.ACTIVITY_RECORD) {
      return <FaUserSlash className={className} />;
    }
  };
  render() {
    const { className, size = 'default', type, style = {} } = this.props;

    const iconColor = {
      GROUP: 'text-color-indigo',
      INDIVIDUAL: 'text-color-lime',
      ACTIVITY_RECORD: 'text-color-gold'
    };

    let sizeClasses = '';
    if (size === 'large') {
      sizeClasses = 'text-size-large';
    } else if (size === 'small') {
      sizeClasses = 'text-size-small ';
    }
    const iconClassNames = `${iconColor[type]} ${sizeClasses} ${className}`;

    return this._getIcon(type, iconClassNames, style);
  }
}
