import React, { PureComponent } from 'react';
import CommonUtils from 'utilities/common-utils';
import { Avatar } from 'antd';

interface IInitialAvatarProps {
  size?: 'small' | 'default' | 'large' | number;
  firstName: string;
  lastName?: any;
  shape?: 'circle' | 'square';
  textColor?: string;
  className?: string;
  weight?: string;
}

export default class InitialAvatar extends PureComponent<IInitialAvatarProps, any> {
  render() {
    const {
      size = 'default',
      firstName,
      lastName = null,
      shape = 'circle',
      textColor = 'white',
      className = '',
      weight = 'regular'
    } = this.props;

    let sizeClasses = 20;
    if (size === 'default') {
      sizeClasses = 20;
    } else if (size === 'large') {
      sizeClasses = 25;
    } else if (size === 'small') {
      sizeClasses = 18;
    }

    return (
      <Avatar
        size={sizeClasses}
        className={`text-${textColor} ${className} weight-${weight}`}
        style={{
          backgroundColor: CommonUtils.getBackgroundColor(firstName + lastName)
        }}
        shape={shape}
      >
        {firstName && firstName[0].toUpperCase()} {lastName && lastName[0].toUpperCase()}
      </Avatar>
    );
  }
}
