import React, { Component } from 'react';
import { Warning, Error } from 'common-components/alerts';
import { Text } from 'common-components/typography';
import { PrimaryButton } from 'common-components/buttons';
import { Icon } from 'antd';
import { LineItemsTable } from 'views/group-services/manage-booking-modal/panels/billings/LineItemsTable';
import { IBillingLineItem } from 'interfaces/booking-interfaces';
import _ from 'lodash';
import { BookingErrorType, BookingStatus, PaymentStatus } from 'utilities/enum-utils';
import FundingUtils from 'utilities/funding-utils';

interface ILineItemsDisplaySectionProps {
  lineItems: IBillingLineItem[];
  tabType: 'session' | 'transport-before' | 'transport-after' | string;
  onEditLineItem: any;
  onOpenAddModal: any;
  onDeleteLineItem: any;
  bookingErrors: any;
  fundedCategories: any;
  paymentStatus: string;
  attendanceId: string;
  bookingStatus: string;
}

interface ILineItemsDisplaySectionState {
  isState: string;
}

export class LineItemsDisplaySection extends Component<ILineItemsDisplaySectionProps, ILineItemsDisplaySectionState> {
  render() {
    const {
      lineItems,
      tabType,
      onEditLineItem,
      onDeleteLineItem,
      bookingErrors,
      fundedCategories,
      paymentStatus,
      bookingStatus,
      attendanceId,
    } = this.props;

    let paymentMethodError;
    let fundingPackageError;

    if (tabType === 'session') {
      fundingPackageError = _.find(
        bookingErrors,
        (error) => error.bookingErrorType === BookingErrorType.FundingPackageWarning,
      );

      paymentMethodError = _.find(
        bookingErrors,
        (error) => error.bookingErrorType === BookingErrorType.PaymentMethodError,
      );
    } else {
      let isNotFundedCount = 0;
      let emptyPaymentMethodCount = 0;

      _.forEach(lineItems, (lineItem) => {
        const isFunded = !_.isEmpty(
          FundingUtils.isLineItemExistInFundingPackage(
            lineItem.supportType,
            lineItem.supportCategoryNumber,
            lineItem.supportItemNumber,
            fundedCategories,
          ),
        );
        if (!isFunded) {
          isNotFundedCount += 1;
        }
        if (!lineItem.paymentMethod) {
          emptyPaymentMethodCount += 1;
        }
      });

      if (isNotFundedCount !== 0) {
        fundingPackageError = {
          booingErrorType: BookingErrorType.FundingPackageWarning,
          number: isNotFundedCount,
        };
      }
      if (emptyPaymentMethodCount !== 0) {
        paymentMethodError = {
          booingErrorType: BookingErrorType.PaymentMethodError,
          number: emptyPaymentMethodCount,
        };
      }
    }

    const buttonLabel =
      tabType === 'session'
        ? 'session '
        : tabType === 'transport-before'
        ? 'Transport Before'
        : tabType === 'transport-after'
        ? 'Transport after'
        : '';

    const canAddEditLineItem =
      (paymentStatus === PaymentStatus.INITIAL || paymentStatus === PaymentStatus.REQUIRES_APPROVAL) &&
      (bookingStatus !== BookingStatus.CUSTOMER_CANCELLED &&
        bookingStatus !== BookingStatus.CUSTOMER_CANCELLED_WITH_FEE &&
        bookingStatus !== BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE &&
        bookingStatus !== BookingStatus.BUSINESS_CANCELLED);

    return (
      <div className="anim-fade-in-fast">
        {fundingPackageError && (
          <Warning
            content={`${fundingPackageError.number} line item${
              fundingPackageError.number > 1 ? ' are' : ' is'
            } not part of the customers funding package.`}
            displayType="block"
            className="mb-small"
          />
        )}
        {paymentMethodError && (
          <Error
            content={`${paymentMethodError.number} line item${
              paymentMethodError.number > 1 ? ' are' : ' is'
            } missing a management method.`}
            displayType="block"
            className="mb-small"
          />
        )}

        {/* Add line item button */}
        <section className="mt-small">
          {canAddEditLineItem && (
            <PrimaryButton icon={'plus'} onClick={() => this.props.onOpenAddModal(attendanceId)}>
              Add <b> {buttonLabel} </b> line item
            </PrimaryButton>
          )}
        </section>

        {/* Line item table */}
        <section className="mt-large ph-medium pt-small pb-medium bordered border-standard-gray rounded-big">
          <LineItemsTable
            tabType={tabType}
            lineItems={lineItems}
            onEditLineItem={onEditLineItem}
            onDeleteLineItem={onDeleteLineItem}
            fundedCategories={fundedCategories}
            canAddEditLineItem={canAddEditLineItem}
          />
        </section>
      </div>
    );
  }
}
