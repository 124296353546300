/* eslint-disable */
import React from 'react';
import { Route } from 'react-router-dom';
import BookingBackupView from '../views/bookings/BookingLandingBackup';
import ServicesBackupView from '../views/services/ServiceLandingBackupView';
import TestCssView from '../views/wip/TestCSSView';

// Design related WIP; probably not related to portal (?)
import StyleGuideMainView from '../views/styleguide/StyleGuideMainView';

import WipComponentView from '../views/styleguide/WIPComponentView';

const TestRoutes = ({ isAuthenticated }) => (
  <>
    <Route path={'/test-css'} component={TestCssView} />

    <Route path={'/style-guide'} component={StyleGuideMainView} />

    <Route path={'/design/components'} component={WipComponentView} />

    <Route path={'/backup-booking'} component={BookingBackupView} />
    <Route path={'/backup-service'} component={ServicesBackupView} />
  </>
);

export default TestRoutes;
