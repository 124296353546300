import React, { Component } from 'react';
import { Col, Icon, Input, notification, Row, Checkbox } from 'antd';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { dispatch, state, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { SubTitle, Text } from 'common-components/typography';
import ActionModal from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import moment from 'moment-timezone';
import { timeZone } from 'interfaces/timezone-type';
import { Warning } from 'common-components/alerts';

const { TextArea } = Input;

interface IEditDocumentModalProps {
  closeEditDocumentModal: () => void;
  isOpen: boolean;
  selectedDocument: any;
  selectedItemId: string;
  itemType: string;
  doEditCustomerDocument: typeof dispatch.customersStore.doEditCustomerDocument;
  doEditWorkerDocument: typeof dispatch.teamStore.doEditWorkerDocument;
  doEditBookingDocument: typeof dispatch.bookingsStore.doEditBookingDocument;
  portalUser: typeof state.authStore.portalUser;
  serviceProviderTimezone: timeZone;
  resetDocumentList: () => void;
  useAttachmentText: boolean;
  isExpiryDisplayed?: boolean;
}

interface IEditDocumentModalState {
  isLoading: boolean;
  isActionModalOpen: boolean;
  isUploading: boolean;
  isInitialise: boolean;
  selectedFile: any;
  progress: number;
  description: any;
  expiryDate: Date;
  isViewableOnApp: boolean;
  isCompliant: boolean;
}

class EditDocumentModal extends Component<IEditDocumentModalProps, IEditDocumentModalState> {
  state = {
    isLoading: false,
    isActionModalOpen: false,
    selectedFile: null,
    progress: 0,
    isUploading: false,
    isInitialise: false,
    description: '',
    expiryDate: null,
    isViewableOnApp: this.props.selectedDocument && this.props.selectedDocument.isViewableOnApp,
    isCompliant: this.props.selectedDocument && this.props.selectedDocument.isCompliant,
  };

  private _closeActionModal = () => {
    this.props.closeEditDocumentModal();
    this.setState({ isActionModalOpen: false });
  };

  private _closeActionEditDocumentModal = () => {
    this.setState({ isActionModalOpen: false, selectedFile: null, description: null, progress: 0 });
    this.props.closeEditDocumentModal();
  };

  private _updateDescription = (event) => {
    this.setState({ description: event.target.value });
  };

  private _updateExpiryDate = (event) => {
    this.setState({ expiryDate: event });
  };

  private _editDocument = async () => {
    const { selectedDocument, selectedItemId } = this.props;
    try {
      this.props.itemType === 'customer'
        ? await this.props.doEditCustomerDocument({
            userId: selectedItemId,
            documentId: selectedDocument.documentId,
            description: this.state.description,
            expiryDate: this.state.expiryDate,
            isViewableOnApp: this.state.isViewableOnApp,
          })
        : this.props.itemType === 'worker'
        ? await this.props.doEditWorkerDocument({
            supportWorkerId: selectedItemId,
            documentId: selectedDocument.documentId,
            description: this.state.description,
            expiryDate: this.state.expiryDate,
            isCompliant: this.state.isCompliant,
          })
        : await this.props.doEditBookingDocument({
            bookingId: selectedItemId,
            documentId: selectedDocument.documentId,
            description: this.state.description,
          });
      notification.success({ message: 'Document edited successfully.' });
      await this.props.resetDocumentList();
      this.props.closeEditDocumentModal();
    } catch (e) {
      notification.error({ message: 'Oops, an error has occurred, please try again.' });
    }
  };

  private _onChangeIsViewableOnApp = (e) => {
    this.setState({ isViewableOnApp: e.target.checked });
  };

  private _onChangeIsComplianceDocument = (e) => {
    this.setState({ isCompliant: e.target.checked });
  };

  componentDidUpdate(
    prevProps: Readonly<IEditDocumentModalProps>,
    prevState: Readonly<IEditDocumentModalState>,
    snapshot?: any,
  ) {
    const { selectedDocument } = this.props;
    if (selectedDocument && selectedDocument !== prevProps.selectedDocument) {
      this.setState({
        description: selectedDocument.description,
        expiryDate: selectedDocument.expiryDate,
        isViewableOnApp: selectedDocument.isViewableOnApp,
        isCompliant: selectedDocument.isCompliant,
      });
    }
  }

  render() {
    const { selectedDocument, useAttachmentText } = this.props;

    return (
      <div>
        <ActionModal
          title={`Edit ${useAttachmentText ? 'attachment' : 'document'}`}
          isOpen={this.props.isOpen}
          onClose={this._closeActionEditDocumentModal}
          width="medium"
        >
          <div className="anim-slide-left">
            {this.state.isLoading ? (
              <SpinningLoader size={150} message={'Editing...'} />
            ) : (
              <>
                <Row className="mb-medium">
                  <Row className="mb-x-small">
                    <SubTitle>{useAttachmentText ? 'Attachment' : 'Document'}</SubTitle>
                  </Row>
                  <Row>
                    <Icon type="file" className="mr-x-small" />
                    <b>{selectedDocument && selectedDocument.documentName}</b>
                  </Row>
                </Row>
                {this.props.itemType === 'customer' && (
                  <Row className="mb-medium">
                    <Checkbox
                      onChange={this._onChangeIsViewableOnApp}
                      checked={this.state.isViewableOnApp}
                      className="text-color-secondary"
                    >
                      Can be viewed on the business app
                    </Checkbox>
                  </Row>
                )}
                {this.state.isViewableOnApp && (
                  <Row>
                    <Warning
                      content={
                        <>
                          <Text weight={'bold'}>Only certain file types can be accessed from the mobile app</Text>
                          <br></br>
                          <Text>
                            The preferred file types for mobile access are PDF, JPEG & PNG. Making any other file types
                            avalible on the business app may result in that document not being able to be viewed on some
                            devices.
                          </Text>
                        </>
                      }
                      className="mb-large"
                    />
                  </Row>
                )}
                {this.props.itemType === 'worker' && (
                  <Row className="mb-medium">
                    <Checkbox
                      onChange={this._onChangeIsComplianceDocument}
                      checked={this.state.isCompliant}
                      className="text-color-secondary"
                    >
                      Is this a compliance document?
                    </Checkbox>
                  </Row>
                )}
                <Row className="mb-x2-large">
                  <Row className="mb-x-small">
                    <SubTitle>Description (60 characters max)</SubTitle>
                  </Row>
                  <Row>
                    <TextArea
                      maxLength={60}
                      autoSize={{ minRows: 5 }}
                      value={this.state.description}
                      onChange={this._updateDescription}
                    />
                  </Row>
                </Row>
                {this.props.isExpiryDisplayed && (
                  <Row className="mb-x2-large">
                    <Row className="mb-x-small">
                      <SubTitle>Expiry date</SubTitle>
                    </Row>
                    <Row>
                      <DatePicker
                        className="gh-datepicker rounded"
                        calendarClassName="gh-datepicker-calendar"
                        dateFormat="d/M/yyyy"
                        onChange={this._updateExpiryDate}
                        placeholderText={'No expiry date'}
                        isClearable={true}
                        selected={
                          this.state.expiryDate &&
                          new Date(
                            moment
                              .tz(this.state.expiryDate, this.props.serviceProviderTimezone)
                              .format('YYYY/MM/DD HH:mm'),
                          )
                        }
                      />
                    </Row>
                  </Row>
                )}
                <div className={'mb-small'}>
                  <Row type={'flex'} className={'justify-end'}>
                    <Col className="mr-large">
                      <GhostButton size="large" onClick={this._closeActionModal}>
                        Cancel
                      </GhostButton>
                    </Col>
                    <Col>
                      <PrimaryButton size="large" onClick={this._editDocument}>
                        Save
                      </PrimaryButton>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div>
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditCustomerDocument: dispatch.customersStore.doEditCustomerDocument,
  doEditWorkerDocument: dispatch.teamStore.doEditWorkerDocument,
  doEditBookingDocument: dispatch.bookingsStore.doEditBookingDocument,
});

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

export default connect(
  mapState,
  mapDispatch,
)(EditDocumentModal);
