import React, { Component } from 'react';
import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import _ from 'lodash';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import moment from 'moment';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Spinner } from '@blueprintjs/core';

const { Option } = Select;

interface IGeneralEditInfoDetailRowProps extends FormComponentProps {
  doUpdateFundingPackageGeneralInfo: typeof dispatch.customersStore.doUpdateFundingPackageGeneralInfo;
  closeCreatePackageModal: () => void;
  packageId: string;
  selectedCustomer: any;
}

interface IGeneralEditInfoDetailRowState {
  isActionModalOpen: boolean;
  isSaving: boolean;
}

class GeneralEditInfoDetailRow extends Component<IGeneralEditInfoDetailRowProps, IGeneralEditInfoDetailRowState> {
  state = {
    isSaving: false,
    isActionModalOpen: false
  };

  private _validateMobileNumber = (rule, value, callback) => {
    const region = 'AU';
    if (value !== '' && value !== null) {
      let phoneNumber = parsePhoneNumberFromString(value, region);
      try {
        if (!phoneNumber.isValid()) {
          throw new Error('Invalid Number');
        }
      } catch (err) {
        callback(err);
        return;
      }
      callback();
    } else {
      callback();
    }
  };

  private _closeActionModal = () => {
    this.setState({ isActionModalOpen: false });
  };

  private _closeModalWithAction = () => {
    const { selectedCustomer, packageId, form } = this.props;
    let selectedPackage = _.find(
      selectedCustomer.funding.NDISFundingPackages,
      (fundpackage) => fundpackage.fundingPackageId === packageId
    );
    if (
      selectedPackage.agency !== form.getFieldValue('agencyName') ||
      selectedPackage.agencyAddress !== form.getFieldValue('agencyAddress') ||
      selectedPackage.agencyEmail !== form.getFieldValue('agencyEmail') ||
      selectedPackage.agencyContactNumber !== form.getFieldValue('agencyNumber') ||
      selectedPackage.expiredDate !== moment(form.getFieldValue('expiryDate')).format('YYYY-MM-DD') ||
      selectedPackage.startDate !== moment(form.getFieldValue('startDate')).format('YYYY-MM-DD')
    ) {
      this.setState({ isActionModalOpen: true });
    } else {
      this.props.closeCreatePackageModal();
    }
  };

  private _onSave = async () => {
    const { selectedCustomer, form, doUpdateFundingPackageGeneralInfo, packageId } = this.props;
    let isFormValid = true;

    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      let payload = {
        userId: selectedCustomer.userId,
        fundingPackageId: packageId,
        agency: form.getFieldValue('agencyName'),
        startDate: moment(form.getFieldValue('startDate')).format('YYYY-MM-DD'),
        expiredDate: moment(form.getFieldValue('expiryDate')).format('YYYY-MM-DD'),
        agencyAddress: form.getFieldValue('agencyAddress'),
        agencyContactCountryCode:
          form.getFieldValue('phoneNumberCountryCode') !== '' &&
          form.getFieldValue('phoneNumberCountryCode') !== undefined
            ? form.getFieldValue('phoneNumberCountryCode')
            : 'AU',
        agencyContactNumber: form.getFieldValue('agencyNumber'),
        agencyEmail: form.getFieldValue('agencyEmail')
      };
      this.setState({ isSaving: true });
      await doUpdateFundingPackageGeneralInfo(payload);
      this.setState({ isSaving: false });
      this.props.closeCreatePackageModal();
    }
  };

  render() {
    const { form, selectedCustomer, packageId } = this.props;
    const { getFieldDecorator } = form;
    let fundpackages = selectedCustomer.funding.NDISFundingPackages;
    const selectedPackage = _.find(fundpackages, (fundpackage) => fundpackage.fundingPackageId === packageId);
    let disabledAgency = false;
    if (this.props.form.getFieldValue('startDate') !== undefined) {
      if (this.props.form.getFieldValue('startDate') < moment()) {
        disabledAgency = true;
      }
    } else {
      if (moment(selectedPackage.startDate) < moment()) {
        disabledAgency = true;
      }
    }
    return (
      <div>
        {this.state.isSaving ? (
          <div>
            <div className="mt-x-large">
              <div className="flex-row align-center mb-x-large">
                <div className="mr-x-large">
                  <Spinner size={120} />
                </div>
                <div>
                  <Paragraph>Loading.......</Paragraph>
                  <Paragraph weight="bold">Editing General Package Information.</Paragraph>
                  <Paragraph>This won't take long.</Paragraph>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <ActionModal
              isOpen={this.state.isActionModalOpen}
              onClose={this._closeActionModal}
              title={'Confirmation'}
              showCloseButton={true}
            >
              <Text className={'mb-medium'}>You are closing the Funding Package creation wizard without saving.</Text>
              <br />
              <Text className={'mb-medium'}>Do you want to proceed?</Text>
              <ActionModalFooter>
                <PrimaryButton className="mr-medium" size="large" onClick={this._closeActionModal}>
                  Cancel
                </PrimaryButton>
                <GhostButton size="large" onClick={this.props.closeCreatePackageModal}>
                  Proceed
                </GhostButton>
              </ActionModalFooter>
            </ActionModal>
            <div
              className={'customScrollDiv'}
              // style={{ height: '45vh', overflowY: 'auto', overflowX: 'hidden' }}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <SubTitle>Agency Name</SubTitle>
                  <Form.Item>
                    {getFieldDecorator('agencyName', {
                      rules: [{ required: true, message: 'Agency Name is required' }],
                      initialValue:
                        selectedPackage.agency !== null && selectedPackage.agency !== ''
                          ? selectedPackage.agency
                          : 'NDIS'
                    })(
                      <Select placeholder={'Please select Agency'} disabled={disabledAgency}>
                        <Option value={'NDIS'}>NDIS</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <SubTitle>Start Date tt</SubTitle>
                  <Form.Item>
                    {getFieldDecorator('startDate', {
                      initialValue: selectedPackage.startDate !== null ? moment(selectedPackage.startDate) : '',
                      rules: [
                        {
                          required: true,
                          message: 'Please select start date'
                        }
                      ]
                    })(
                      <DatePicker
                        size="large"
                        placeholder={'Start Date'}
                        allowClear={false}
                        format="DD-MM-YYYY"
                        className={'width-full'}
                        disabledDate={(current) => {
                          const endValue = this.props.form.getFieldValue('expiryDate');
                          if (endValue !== null && endValue !== '') {
                            return current.valueOf() >= moment(endValue.startOf('day')).valueOf();
                          }
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <SubTitle>Plan Review Date</SubTitle>
                  <Form.Item>
                    {getFieldDecorator('expiryDate', {
                      initialValue: selectedPackage.expiredDate != null ? moment(selectedPackage.expiredDate) : '',
                      rules: [
                        {
                          required: true,
                          message: 'Please select expiry date'
                        }
                      ]
                    })(
                      <DatePicker
                        size="large"
                        placeholder={'Plan Review Date'}
                        allowClear={false}
                        format="DD-MM-YYYY"
                        className={'width-full'}
                        disabledDate={(current) => {
                          const startValue = this.props.form.getFieldValue('startDate');
                          // const endValue = this.props.form.getFieldValue('expiryDate');
                          if (startValue !== null && startValue !== '') {
                            return current.valueOf() < moment(startValue.endOf('day')).valueOf();
                          }
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <SubTitle>Agency Address</SubTitle>
                  <Form.Item>
                    {getFieldDecorator('agencyAddress', {
                      initialValue: selectedPackage.agencyAddress
                    })(<Input placeholder={'Address'} maxLength={255} name={'agencyAddress'} size="large" />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <SubTitle>Agency Contact Number</SubTitle>
                  <Row gutter={16}>
                    <Col span={4}>
                      <Form.Item>
                        {getFieldDecorator('phoneNumberCountryCode', {
                          rules: [{ required: true, message: 'Country code is required' }],
                          initialValue:
                            selectedPackage.agencyContactCountryCode !== '' ||
                            selectedPackage.agencyContactCountryCode !== null
                              ? selectedPackage.agencyContactCountryCode
                              : 'AU'
                        })(
                          <Select dropdownMatchSelectWidth={true} size="large" className={'width-full'}>
                            <Option value={'AU'}>+61 (AU)</Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={20}>
                      <Form.Item>
                        {getFieldDecorator('agencyNumber', {
                          initialValue: selectedPackage.agencyContactNumber,
                          rules: [
                            {
                              validator: this._validateMobileNumber,
                              message: 'Invalid Agency number.'
                            }
                          ]
                        })(
                          <Input
                            size="large"
                            className={'width-full'}
                            name={'agencyNumber'}
                            placeholder={'Agency Contact Number'}
                          />
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <SubTitle>Agency Email</SubTitle>
                  <Form.Item>
                    {getFieldDecorator('agencyEmail', {
                      rules: [
                        {
                          type: 'email',
                          message: 'Please enter valid Email'
                        }
                      ],
                      initialValue: selectedPackage.agencyEmail
                    })(<Input placeholder={'Email'} name={'agencyEmail'} size="large" />)}
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className={'mt-x2-large flex-row justify-end align-center'}>
              <GhostButton className={'mr-small'} onClick={this._closeModalWithAction}>
                Cancel
              </GhostButton>
              <PrimaryButton size="large" icon={'save'} onClick={this._onSave}>
                Save Changes
              </PrimaryButton>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateFundingPackageGeneralInfo: dispatch.customersStore.doUpdateFundingPackageGeneralInfo
});

export default connect(
  null,
  mapDispatch
)(Form.create<IGeneralEditInfoDetailRowProps>()(GeneralEditInfoDetailRow));
