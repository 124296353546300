import React, { Component } from 'react';
import _ from 'lodash';
import { Checkbox, Icon } from 'antd';
import CommonUtils from 'utilities/common-utils';
import { PaymentMethodTag } from 'common-components/tags';
import { GhostButton, HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootState, state } from 'stores/rematch/root-store';
import { NdisClaimType, NdisClaimTypeLabel } from 'utilities/enum-utils';
import { Menu, MenuItem, Popover } from '@blueprintjs/core';
import { Text } from 'common-components/typography';
import moment from 'moment';
import { connect } from 'react-redux';
import PermissionUtils from 'utilities/permission-utils';
import { ndisHelper } from 'variables/data-helpers';

interface ILineItemRow {
  lineItem?: any;
  history?: any;
  openActionModal?: any;
  // errorTypes?: any;
  // setSelectedBillingLineItem?: typeof dispatch.billingsStore.setSelectedBillingLineItem;
  isLast?: boolean;
  portalUser: typeof state.authStore.portalUser;
  canProcess: boolean;
  subActions: Array<any>;
  type: string;
}

const claimTypeOptions = [
  { value: NdisClaimType.STANDARD, label: NdisClaimTypeLabel.STANDARD },
  { value: NdisClaimType.CANCELLATION, label: NdisClaimTypeLabel.CANCELLATION },
  { value: NdisClaimType.PROVIDER_TRAVEL, label: NdisClaimTypeLabel.PROVIDER_TRAVEL },
  { value: NdisClaimType.REPORT_WRITING, label: NdisClaimTypeLabel.REPORT_WRITING },
  { value: NdisClaimType.NON_FACE_TO_FACE, label: NdisClaimTypeLabel.NON_FACE_TO_FACE },
  { value: NdisClaimType.TELEHEALTH_SUPPORTS, label: NdisClaimTypeLabel.TELEHEALTH_SUPPORTS },
  { value: NdisClaimType.IRREGULAR_SIL_SUPPORTS, label: NdisClaimTypeLabel.IRREGULAR_SIL_SUPPORTS }
];

const unit = {
  H: 'H',
  EA: 'EA',
  D: 'D',
  WK: 'WK',
  MO: 'MO',
  YR: 'YR'
};

const unitOptions = [
  { value: unit.H, label: ' hr' },
  { value: unit.EA, label: 'ea' },
  { value: unit.D, label: 'dy' },
  { value: unit.WK, label: 'wk' },
  { value: unit.MO, label: 'mth' },
  { value: unit.YR, label: 'yr' }
];

class LineItemRow extends Component<ILineItemRow> {
  state = {
    showSubLines: false,
    openAction: false,
    attendanceId: null
  };

  render() {
    const { lineItem, isLast, openActionModal, portalUser, subActions, type } = this.props;

    if (_.isEmpty(lineItem)) {
      return <></>;
    }

    const { total, supportItemNumber, price, claimType, unit, qty } = lineItem;

    const NDISLineItemData = ndisHelper.getBySupportItemNumber(supportItemNumber);

    const popoverContent = (
      <Menu>
        <div style={{ padding: '8px' }}>
          <Text strong type={'secondary'}>
            Line item actions
          </Text>
        </div>
        {_.find(subActions, (action) => action === 'EDIT') && (
          <Menu.Item
            text={
              <div onClick={() => openActionModal({ action: 'EDIT', additionalData: lineItem })}>
                <Text>Edit line item</Text>
              </div>
            }
            className="hover-bg-gray-lightest"
          />
        )}
        {_.find(subActions, (action) => action === 'RESEND_TO_PAYMENT') && (
          <MenuItem
            text={
              <div onClick={() => openActionModal({ action: 'RESEND_TO_PAYMENT', additionalData: lineItem })}>
                <Text>Re-send to payments</Text>
              </div>
            }
            className="hover-bg-gray-lightest"
          />
        )}
        {_.find(subActions, (action) => action === 'VIEW_REJECTION') && (
          <MenuItem
            text={
              <div onClick={() => openActionModal({ action: 'VIEW_REJECTION', additionalData: lineItem })}>
                <Text>View reject reason</Text>
              </div>
            }
            className="hover-bg-gray-lightest"
          />
        )}
        {_.find(subActions, (action) => action === 'WAIVE_LINE_ITEM') && (
          <Menu.Item
            text={
              <div
                onClick={() =>
                  openActionModal({ action: 'WAIVE_LINE_ITEM', additionalData: { type: 'LINE_ITEM', item: lineItem } })
                }
              >
                <Text>Waive line item</Text>
              </div>
            }
            className="hover-bg-gray-lightest"
          />
        )}
        {_.find(subActions, (action) => action === 'REJECT') && (
          <Menu.Item
            text={
              <div onClick={() => openActionModal({ action: 'REJECT', additionalData: null })}>
                <Text color="red">Reject</Text>
              </div>
            }
            className="hover-bg-gray-lightest"
          />
        )}
        {_.find(subActions, (action) => action === 'REJECT_LINE_ITEMS') && (
          <Menu.Item
            text={
              <div
                onClick={() =>
                  openActionModal({
                    action: 'REJECT_LINE_ITEMS',
                    additionalData: { type: 'REJECT_LINE_ITEMS', selectSpecificLine: true, item: lineItem }
                  })
                }
              >
                <Text color="red">Reject line item</Text>
              </div>
            }
            className="hover-bg-gray-lightest"
          />
        )}
        {_.find(subActions, (action) => action === 'EDIT_LINE_ITEM') && (
          <Menu.Item
            text={
              <div
                onClick={() =>
                  openActionModal({
                    action: 'EDIT_LINE_ITEM',
                    additionalData: { type: 'EDIT_LINE_ITEM', item: lineItem }
                  })
                }
              >
                <Text>Edit line item</Text>
              </div>
            }
            className="hover-bg-gray-lightest"
          />
        )}
        {_.find(subActions, (action) => action === 'VIEW_WAIVE_REASON') && (
          <Menu.Item
            text={
              <div
                onClick={() =>
                  openActionModal({
                    action: 'VIEW_WAIVE_REASON',
                    additionalData: { type: 'VIEW_WAIVE_REASON', item: lineItem }
                  })
                }
              >
                <Text>View waive reason</Text>
              </div>
            }
            className="hover-bg-gray-lightest"
          />
        )}
        {_.find(subActions, (action) => action === 'DELETE') && (
          <Menu.Item
            text={
              <div onClick={() => openActionModal({ action: 'DELETE', additionalData: lineItem })}>
                <Text color="red">Delete</Text>
              </div>
            }
            className="hover-bg-gray-lightest"
          />
        )}
      </Menu>
    );
    return (
      <>
        <tr>
          {(type === 'CLAIMED' || type === 'PAID' || type === 'PAYMENT_ISSUES') && (
            <>
              <td className="ellipsis">
                <Text color={'secondary'}>{lineItem.claimReferenceNumber}</Text>
              </td>
              <td className="ellipsis">
                <Text color={'secondary'}>{moment(lineItem.updatedOn).format('DD/MM/YYYY')}</Text>
              </td>
            </>
          )}
          <td className="ellipsis">
            <Text color={'secondary'}>{supportItemNumber}</Text>
            <br />
            {NDISLineItemData.SupportItem}
          </td>
          <td className="ellipsis">
            {claimType ? claimTypeOptions.find((item) => item.value === claimType).label : NdisClaimTypeLabel.STANDARD}
          </td>
          {/*<td className="ellipsis">*/}
          {/*  <HyperlinkButton onClick={this._onClickBooking}>*/}
          {/*    {moment(startDateTime).format('DD/MM/YYYY')}*/}
          {/*  </HyperlinkButton>*/}
          {/*</td>*/}
          <td className="ellipsis">
            {CommonUtils.formatPrice(price)} x {qty}
            {unitOptions.find((item) => item.value === unit)
              ? unitOptions.find((item) => item.value === unit).label
              : unit}
          </td>
          <td className="ellipsis text-align-right">
            <div className="text-align-right">{CommonUtils.formatPrice(total)}</div>
          </td>
          {subActions && subActions.length > 0 && (
            <td className="fluid-cell">
              <Popover content={<Menu>{popoverContent}</Menu>} position={'bottom-right'}>
                <Icon type="ellipsis" className="text-size-x4-large" />
              </Popover>
            </td>
          )}
        </tr>
        {/*{isError && (*/}
        {/*  <tr className={`${isLast && 'no-border'}`}>*/}
        {/*    <td />*/}
        {/*    <td colSpan={7} className="text-color-red text-weight-bold pt-none">*/}
        {/*      <Icon type="warning" className="mr-x-small" />*/}
        {/*      {this._getErrorMessage()}{' '}*/}
        {/*      {PermissionUtils.validatePermission('ViewCustomerProfile', portalUser.permissions.permissionRoles) && (*/}
        {/*        <HyperlinkButton onClick={this._onClickCustomer}>View Customer Details</HyperlinkButton>*/}
        {/*      )}*/}
        {/*    </td>*/}
        {/*  </tr>*/}
        {/*)}*/}
      </>
    );
  }
}

const mapState = (state: IRootState) => ({ portalUser: state.authStore.portalUser });

export default connect(
  mapState,
  null
)(LineItemRow);
