import React, { Component } from 'react';
import _ from 'lodash';
import Title from 'antd/lib/typography/Title';
import { Checkbox, Col, Row } from 'antd';
import { Paragraph, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import moment from 'moment-timezone';
import CommonUtils from 'utilities/common-utils';
import { IGroupServiceTimeSlot } from 'interfaces/service-interfaces';

interface ISelectScheduleStepPanelProps {
  onPreviousStep: () => void;
  onNextStep: () => void;
  onSetSelectedTimeSlot: (v) => void;
  groupServiceTimeSlots: IGroupServiceTimeSlot[];
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  doFetchGroupServiceTimeSlots: typeof dispatch.groupServiceStore.doFetchGroupServiceTimeSlots;
  setGroupServiceTimeSlots: typeof dispatch.groupServiceStore.setGroupServiceTimeSlots;
}

interface ISelectScheduleStepPanelState {
  isLoading: boolean;
  isNotScheduleSelectedError: boolean;
  selectedTimeSlot: IGroupServiceTimeSlot;
  serviceDateTimes: IGroupServiceTimeSlot[];
}

class SelectScheduleStepPanel extends Component<ISelectScheduleStepPanelProps, ISelectScheduleStepPanelState> {
  state = {
    isLoading: false,
    isNotScheduleSelectedError: false,
    selectedTimeSlot: null,
    serviceDateTimes: [],
  };

  private _goToNext = () => {
    if (!this.state.selectedTimeSlot) {
      this.setState({ isNotScheduleSelectedError: true });
      return;
    }

    this.props.onSetSelectedTimeSlot(this.state.selectedTimeSlot);
    this.props.onNextStep();
  };

  private _selectTimeSlot = (slot: IGroupServiceTimeSlot) => {
    const { selectedTimeSlot } = this.state;
    if (selectedTimeSlot && slot.serviceScheduleId === selectedTimeSlot.serviceScheduleId) {
      this.setState({ selectedTimeSlot: null });
    } else {
      this.setState({ selectedTimeSlot: slot });
    }
  };

  private _groupTimeSlots = () => {
    const { groupServiceTimeSlots } = this.props;
    if (_.isEmpty(groupServiceTimeSlots)) return [];

    // Filtered out the past sessions in response.
    const availableTimeSlots = groupServiceTimeSlots.map((slot) => {
      const newServiceDateTimes = slot.serviceDateTimes.filter((session) => moment().isBefore(session.startDateTime));
      slot.serviceDateTimes = newServiceDateTimes;
      return slot;
    });
    // Filtered out time slots with no available upcomming sessions.
    const filteredSlots = availableTimeSlots.filter((slot) => slot.serviceDateTimes.length > 0);

    // Group the timeslots by schedule id to iterate in render function.
    const groupedSlotsObject = _.groupBy(filteredSlots, (slot) => slot.serviceScheduleId);
    return Object.values(groupedSlotsObject);
  };

  componentDidMount = async () => {
    this.props.setGroupServiceTimeSlots([]);
    this.setState({ isLoading: true });
    await this.props.doFetchGroupServiceTimeSlots({ serviceId: this.props.selectedGroupService.serviceId });
    this.setState({ isLoading: false });
  };

  render() {
    const { selectedGroupService, groupServiceTimeSlots } = this.props;
    const { isLoading, isNotScheduleSelectedError, selectedTimeSlot } = this.state;
    if (!groupServiceTimeSlots) return null;
    const { timezone } = selectedGroupService;
    const groupedSlots = this._groupTimeSlots();

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>Select which schedules you want to add this customer to</Title>
              <Paragraph>
                You can select the time slots you want to add this customer to. You can manually de-select sessions from
                individual time-slots in the next step.
              </Paragraph>
              <Paragraph>Visit the Help Center to learn more.</Paragraph>
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white rounded-big ph-large pt-large pb-x2-large" style={{ minWidth: '250px' }}>
              <Title level={4}>Assign to schedule</Title>
              <Paragraph className="mt-large">
                Select the <b>schedule</b> that you’d like to assign the team members to.{' '}
              </Paragraph>
              {isNotScheduleSelectedError && <Text color={'red-dark'}>Please select at least one schedule.</Text>}
              <div className={!isNotScheduleSelectedError && 'mt-large'}>
                {isLoading ? (
                  <SpinningLoader size={150} message={'Fetching schedules...'} />
                ) : (
                  _.map(groupedSlots, (slots) => {
                    return (
                      <div className={'mb-large'}>
                        <div className={'p-medium bg-tertiary mb-x-small'}>
                          <Text weight={'bold'}>
                            {selectedGroupService.serviceName} - {slots[0].scheduleName} (
                            {moment.tz(slots[0].scheduleStartDate, timezone).format('DD MMMM')} -{' '}
                            {moment.tz(slots[0].scheduleEndDate, timezone).format('DD MMMM')})
                          </Text>
                        </div>
                        {_.map(slots, (slot, index) => {
                          const isSelected =
                            selectedTimeSlot && slot.scheduleTimeSlotId === selectedTimeSlot.scheduleTimeSlotId;
                          const { timeslot } = slot;
                          return (
                            <div
                              key={index}
                              className={`mb-x-small cursor-pointer flex-row p-medium bordered bordered-standard-grey ${isSelected &&
                                'bg-blue-lightest'}`}
                              onClick={() => this._selectTimeSlot(slot)}
                            >
                              <div>
                                <Checkbox
                                  checked={isSelected}
                                  className={'mr-medium'}
                                  disabled={!isSelected && selectedTimeSlot !== null}
                                />
                              </div>
                              <div>
                                <Text>
                                  {CommonUtils.getRecurringPatternLabel(timeslot.recurringPattern)} -{' '}
                                  {moment.tz(timeslot.startDateTime, timezone).format('dddd')},{' '}
                                  {moment.tz(timeslot.startDateTime, timezone).format('hh:mm A')} to{' '}
                                  {moment.tz(timeslot.endDateTime, timezone).format('hh:mm A')}
                                </Text>
                                <br />
                                <Text size={'regular'}>{timeslot.description ? timeslot.description : '-'}</Text>
                                <br />
                                <Text weight={'bold'}>{slot.serviceDateTimes.length}</Text>{' '}
                                <Text size={'regular'} color={'secondary'}>
                                  available upcoming sessions
                                </Text>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    );
                  })
                )}
              </div>
            </div>

            <ActionModalFooter align="right" className="mt-small mb-x2-large pr-large">
              <SecondaryButton size="large" className="mr-medium" onClick={this.props.onPreviousStep}>
                Back
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._goToNext}>
                Next
              </PrimaryButton>
            </ActionModalFooter>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceTimeSlots: state.groupServiceStore.groupServiceTimeSlots,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupServiceTimeSlots: dispatch.groupServiceStore.doFetchGroupServiceTimeSlots,
  setGroupServiceTimeSlots: dispatch.groupServiceStore.setGroupServiceTimeSlots,
});

export default connect(mapState, mapDispatch)(SelectScheduleStepPanel);
