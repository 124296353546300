import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Checkbox, Col, Form, Icon, Input, notification, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { Information } from 'common-components/alerts';

interface IEditDisplayNameModalProps extends FormComponentProps {
  isOpen: any;
  onClose: () => void;
  doEditDisplayName: typeof dispatch.companyStore.doEditDisplayName;
  currentDisplayName: string;
}

interface IEditDisplayNameModalState {
  title: any;
  isCreated: boolean;
  newDisplayName: string;
}

class EditDisplayNameModal extends Component<IEditDisplayNameModalProps, IEditDisplayNameModalState> {
  state = { title: 'Change display name', isCreated: false, newDisplayName: null };

  private _onCloseModal = () => {
    this.setState({ title: 'Change display name', isCreated: false, newDisplayName: null });
    this.props.onClose();
  };

  private _validateAcknowledge = (rule, value, callback) => {
    try {
      if (!value) {
        throw Error('In order to continue you must acknowledge this statement.');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  private _validateMatchingName = (rule, value, callback) => {
    try {
      if (value !== this.props.form.getFieldValue('newDisplayName')) {
        throw Error('Name does not match.');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  private _editDisplayName = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        const displayName = form.getFieldValue('newDisplayName');
        await this.props.doEditDisplayName({ displayName });
        this.setState({
          title: (
            <>
              Change display name successful
              <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
            </>
          ),
          isCreated: true,
          newDisplayName: displayName
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
  };

  render() {
    const { isOpen, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {this.state.isCreated ? (
          <>
            <div className="mv-medium anim-slide-left">
              <SubTitle>You are now display as</SubTitle>
              <Text size={'x-large'}>{this.state.newDisplayName}</Text>
            </div>

            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Done
              </PrimaryButton>
            </ActionModalFooter>
          </>
        ) : (
          <>
            <div className="mv-medium anim-slide-left">
              <div>
                <Paragraph>
                  Your display name is the public name of your business. Shown to customers and used in the workspace.
                </Paragraph>
                <Information
                  content={
                    <div>
                      <b>Don't change your display name unless absolutely necessary</b>
                      <br />
                      Changing your display name has a large impact on all parts of your business, including in the
                      workspace and what customers see as your business name on all communications and service listings.
                    </div>
                  }
                />
                <div className={'mt-large'}>
                  <SubTitle>Current display name</SubTitle>
                  <Text>{this.props.currentDisplayName}</Text>
                </div>
                <div className={'mt-large'}>
                  <Row gutter={32}>
                    <Col span={12}>
                      <SubTitle>New display name</SubTitle>
                      <Form.Item className="width-full m-none">
                        {getFieldDecorator('newDisplayName', {
                          rules: [{ required: true, message: 'Please enter a new name.' }]
                        })(<Input size={'large'} />)}
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <SubTitle>Retype New display name</SubTitle>
                      <Form.Item className="width-full m-none">
                        {getFieldDecorator('newDisplayNameCheck', {
                          rules: [{ validator: this._validateMatchingName }]
                        })(<Input size={'large'} />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <Form.Item className="width-full">
                  {getFieldDecorator('acknowledgment', { rules: [{ validator: this._validateAcknowledge }] })(
                    <Checkbox>I understand that this action is permanent and cannot be undone</Checkbox>
                  )}
                </Form.Item>
              </div>

              <ActionModalFooter>
                <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._editDisplayName}>
                  Change display name
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditDisplayName: dispatch.companyStore.doEditDisplayName
});

export default connect(
  null,
  mapDispatch
)(Form.create<IEditDisplayNameModalProps>()(EditDisplayNameModal));
