import {
  red,
  blue,
  amber,
  black,
  blueGrey,
  brown,
  cyan,
  darkIcons,
  darkText,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lightIcons,
  lightText,
  lime,
  orange,
  pink,
  purple,
  teal,
  white,
  yellow
} from 'material-colors';

export default {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
  black,
  white,
  darkText,
  lightText,
  darkIcons,
  lightIcons
};
