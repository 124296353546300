import { Icon, message, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import { UploadListType } from 'antd/es/upload/interface';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { Component } from 'react';
import firebaseApp from 'stores/firebase-app';
import { HyperlinkButton } from '../buttons';

interface UploadAvatarProps {
  style?: any;
  className?: string;
  url: string;
  onFinishUpload: ({ url, urlPath }) => void;
  uploadPath: string;
  listType?: UploadListType;
  uploadButton?: number;
}

class UploadImage extends Component<UploadAvatarProps, any> {
  state = {
    loading: false,
    imageUrl: null
  };

  componentDidMount() {
    const { url } = this.props;
    if (url) {
      this.setState({ imageUrl: url });
    }
  }

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  static beforeUpload(file) {
    const isJpgOrPng =
      file.type.toLowerCase() === 'image/jpeg' ||
      file.type.toLowerCase() === 'image/jpg' ||
      file.type.toLowerCase() === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG or PNG files.');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB.');
    }
    return isJpgOrPng && isLt2M;
  }

  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      this.getBase64(info.file.originFileObj, (imageUrl) =>
        this.setState({
          imageUrl,
          loading: false
        })
      );
    }
  };

  customUpload = async ({ onError, onSuccess, file }) => {
    const { onFinishUpload, uploadPath } = this.props;
    try {
      const imageRef = ref(firebaseApp.storage, `${uploadPath}/${Date.now()}.jpg`);
      const uploadFile = uploadBytesResumable(imageRef, file);

      await uploadFile.on(
        'state_changed',
        () => ({}),
        () => ({}),
        () => {
          getDownloadURL(uploadFile.snapshot.ref)
            .then((url) => {
              onFinishUpload({ url: url, urlPath: uploadFile.snapshot.ref.fullPath });
              onSuccess(null, uploadFile);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      );
    } catch (e) {
      onError(e);
    }
  };

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    const uploadButton2 = (
      <HyperlinkButton>
        <span className="text-size-x2-large"> + Select image...</span>
      </HyperlinkButton>
    );

    const { imageUrl } = this.state;
    const { style, className } = this.props;
    const listType = this.props.listType ? this.props.listType : 'picture-card';
    const finalUploadButton = this.props.uploadButton === 2 ? uploadButton2 : uploadButton;

    return (
      <ImgCrop>
        <Upload
          name="avatar"
          listType={listType}
          style={style}
          className={className}
          showUploadList={false}
          customRequest={this.customUpload}
          beforeUpload={UploadImage.beforeUpload}
          onChange={this.handleChange}
        >
          {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : finalUploadButton}
        </Upload>
      </ImgCrop>
    );
  }
}

export default UploadImage;
