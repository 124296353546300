import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import React from 'react';
import { timeZone } from 'interfaces/timezone-type';
import moment from 'moment-timezone';
import { IGroupServiceActivityLog } from 'interfaces/service-interfaces';

interface IActivityItemProps {
  isLast: boolean;
  activityLog: IGroupServiceActivityLog;
  timezone: timeZone;
}

export function ActivityItem(props: IActivityItemProps) {
  const { isLast, activityLog, timezone } = props;
  const { action, firstName, lastName, createdOn, actionType } = activityLog;
  const description = `${firstName} ${lastName} has ${action}`;
  const actionTime = moment.tz(createdOn, timezone);

  // TODO: temp comment out action type from activity logs
  return (
    <div className="flex-row mb-small">
      <div className="flex-column justify-center align-center">
        <Icon
          type={'user'}
          style={{ fontSize: '16px', padding: '4px' }}
          className="bg-blue-lighter mb-x-small rounded text-color-white"
        />
        <div className={`${isLast ? 'bg-white' : 'bg-standard-gray'} flex-1`} style={{ width: '1px' }} />
      </div>
      <div className="flex-column ml-medium line-height-135">
        <div className="mb-x2-small">
          <Text size="small" color="secondary" className="text-uppercase" lineHeight={150}>
            {actionTime.format('h:mm a Do MMMM YYYY')}
          </Text>
        </div>
        <div>
          {/*<Text weight="bold" lineHeight={135}>*/}
          {/*  {actionType}*/}
          {/*</Text>*/}
        </div>
        <div className="mb-large">
          <Text lineHeight={135}>{description}</Text>
        </div>
      </div>
    </div>
  );
}
