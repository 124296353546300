import React, { Component } from 'react';
import { Col, Form, Input, notification, Row, Select } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { SubTitle, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import TextArea from 'antd/lib/input/TextArea';
import isNumeric from 'antd/es/_util/isNumeric';

interface IAddEditSupplierModalProps extends FormComponentProps {
  closeModal: (refreshList?: boolean) => void;
  isOpen: boolean;
  isEdit: boolean;
  selectedSupplier: any;
  doAddPlanManagementSuppliers: typeof dispatch.planManagementStore.doAddPlanManagementSuppliers;
  doEditPlanManagementSuppliers: typeof dispatch.planManagementStore.doEditPlanManagementSuppliers;
}

interface IAddEditSupplierModalState {
  isLoading: boolean;
  isCancelVerificationModalOpen: boolean;
}

class AddEditSupplierModal extends Component<IAddEditSupplierModalProps, IAddEditSupplierModalState> {
  state = {
    isLoading: false,
    isCancelVerificationModalOpen: false
  };

  private _closeModalCheck = () => {
    this.setState({ isCancelVerificationModalOpen: true });
  };

  private _closeModal = () => {
    this.setState({
      isLoading: false,
      isCancelVerificationModalOpen: false
    });
    this.props.closeModal();
  };

  private _closeCancelVerificationModal = () => {
    this.setState({ isCancelVerificationModalOpen: false });
  };

  private _saveSupplierData = async () => {
    const {
      selectedSupplier,
      closeModal,
      doAddPlanManagementSuppliers,
      doEditPlanManagementSuppliers,
      isEdit,
      form
    } = this.props;
    try {
      let isFormValid = true;
      form.validateFields((err) => {
        if (err) {
          isFormValid = false;
        }
      });
      if (isFormValid) {
        this.setState({ isLoading: true });
        const payload = {
          supplierName: form.getFieldValue('supplierName'),
          abnNumber: form.getFieldValue('abnNumber'),
          email: form.getFieldValue('email'),
          contactNumber: form.getFieldValue('contactNumber'),
          preferredPaymentMethod: form.getFieldValue('preferredPaymentMethod'),
          additionalNote: form.getFieldValue('additionalNote')
        };
        if (isEdit) {
          await doEditPlanManagementSuppliers({
            planManagementSupplierId: selectedSupplier.planManagementSupplierId,
            ...payload
          });
          notification.success({ message: 'Provider updated successfully.' });
          closeModal();
        } else {
          await doAddPlanManagementSuppliers(payload);
          notification.success({ message: 'New supplier created successfully.' });
          closeModal(true);
        }
        this.setState({ isLoading: false });
      }
    } catch (e) {
      this.setState({ isLoading: false });
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
  };

  private _validateMobileNumber = (rule, value, callback) => {
    const region = 'AU';

    try {
      if (!_.isEmpty(value)) {
        let phoneNumber = parsePhoneNumberFromString(value, region);
        if (phoneNumber === undefined || !phoneNumber || !phoneNumber.isValid()) {
          throw new Error('Please enter a valid phone number');
        }
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  private _validateABN = (rule, value, callback) => {
    try {
      console.log(isNumeric(value));
      if (!value || !isNumeric(value) || value.length !== 11) {
        throw Error('Please enter a valid ABN.');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  private _validateSupplierName = (rule, value, callback) => {
    try {
      console.log(isNumeric(value));
      if (!_.trim(value)) {
        throw Error('Please enter a provider name.');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  render() {
    const { isEdit, selectedSupplier, form } = this.props;
    const { getFieldDecorator } = form;
    const { isLoading, isCancelVerificationModalOpen } = this.state;

    return (
      <div>
        <ActionModal
          isOpen={isCancelVerificationModalOpen}
          onClose={this._closeModal}
          title={'Cancel changes'}
          showCloseButton={true}
          width={'small'}
        >
          <Text className={'mb-medium'}>Your changes hasn't been saved, proceeding will discard it.</Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeCancelVerificationModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size="large" onClick={this._closeModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          title={`${isEdit ? 'Edit' : 'Add new'} Provider`}
          isOpen={this.props.isOpen}
          onClose={this._closeModalCheck}
          width="x-large"
          verticalAlignment={'highest'}
        >
          <div>
            {!isEdit && <Text>Please enter the new Provider details below.</Text>}
            <Row gutter={24} className={'mt-large'}>
              <Col span={12}>
                <SubTitle>Provider name</SubTitle>
                <Form.Item>
                  {getFieldDecorator('supplierName', {
                    rules: [{ required: true, validator: this._validateSupplierName }],
                    initialValue: selectedSupplier ? selectedSupplier.supplierName : undefined
                  })(<Input size={'large'} placeholder={'Input name..'} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <SubTitle>ABN</SubTitle>
                <Form.Item>
                  {getFieldDecorator('abnNumber', {
                    rules: [{ validator: this._validateABN }],
                    initialValue: selectedSupplier ? selectedSupplier.abnNumber : undefined
                  })(<Input size={'large'} maxLength={11} placeholder={'Input ABN..'} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <div>
                  <SubTitle>Email</SubTitle>
                  <Form.Item>
                    {getFieldDecorator('email', {
                      rules: [{ required: true, type: 'email', message: 'Please enter a valid email' }],
                      initialValue: selectedSupplier ? selectedSupplier.email : undefined
                    })(<Input size={'large'} placeholder={'Input email..'} />)}
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <SubTitle>Contact Number (Optional)</SubTitle>
                <div className={'flex-row'}>
                  <Select
                    disabled
                    size={'large'}
                    className="mr-small"
                    style={{ fontSize: '16px', width: '110px' }}
                    defaultValue={'AU'}
                  >
                    <Select.Option value={'AU'}>+61 (AU)</Select.Option>
                  </Select>
                  <Form.Item className={'width-full'}>
                    {getFieldDecorator('contactNumber', {
                      rules: [{ validator: this._validateMobileNumber }],
                      initialValue: selectedSupplier ? selectedSupplier.contactNumber : undefined
                    })(<Input size={'large'} placeholder={'Input number..'} className={'width-full'} />)}
                  </Form.Item>
                </div>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <SubTitle>Preferred payment method (Optional)</SubTitle>
                <Form.Item>
                  {getFieldDecorator('preferredPaymentMethod', {
                    initialValue: selectedSupplier ? selectedSupplier.preferredPaymentMethod : undefined
                  })(<Input size={'large'} placeholder={'Input preferred payment method..'} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <SubTitle>Additional Notes (Optional)</SubTitle>
                <Form.Item>
                  {getFieldDecorator('additionalNote', {
                    initialValue: selectedSupplier ? selectedSupplier.additionalNote : undefined
                  })(<TextArea autoSize={{ maxRows: 6, minRows: 4 }} placeholder={'Input notes..'} />)}
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div>
            <div className={'mb-small mt-x2-large'}>
              <Row type={'flex'} className={'justify-end'}>
                <Col className="mr-large">
                  <GhostButton size="large" onClick={this._closeModalCheck}>
                    Cancel
                  </GhostButton>
                </Col>
                <Col>
                  <PrimaryButton size="large" loading={isLoading} onClick={this._saveSupplierData}>
                    Save
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </div>
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddPlanManagementSuppliers: dispatch.planManagementStore.doAddPlanManagementSuppliers,
  doEditPlanManagementSuppliers: dispatch.planManagementStore.doEditPlanManagementSuppliers
});

const mapState = (state: IRootState) => ({
  // if needed
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IAddEditSupplierModalProps>()(AddEditSupplierModal));
