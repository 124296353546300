import asyncDelay from 'utilities/asyncDelay';
import apiClient from 'utilities/api-client';
import {
  IGroupServiceActivityLog,
  IGroupServiceCustomer,
  IGroupServiceNote,
  IGroupServiceOverview,
  IGroupServiceOverviewWarnings,
  IGroupServiceServiceAgreement,
  IGroupServiceSession,
  IGroupServiceSupportWorkersWithConflicts,
  IGroupServiceTimesheetSession,
  IGroupServiceTimesheetShiftSlot,
  IGroupServiceTimeSlot,
  IPendingShiftSlots
} from 'interfaces/service-interfaces';
import {
  ICustomerSessionToBeRemoved,
  ISession,
  ISessionCustomer,
  ISessionCustomerDetail,
  ISessionOverview,
  ISessionSupportWorker
} from 'interfaces/session-interfaces';
import {
  BookingStatus,
  GroupServiceSessionStatus,
  ServicePublishStatus,
  TimezoneSelectorMode
} from 'utilities/enum-utils';
import _ from 'lodash';
import { ISessionConflictShiftSlot, ISessionShiftSlotOverview, IShiftSlot } from 'interfaces/shift-interfaces';
import Utils from '../../../utilities/Utils';

interface IGroupServiceStoreState {
  selectedGroupService?: IGroupServiceOverview;
  sessions?: IGroupServiceSession[];

  // Added by Jir : bookings per session. Array with serviceDateTimeId as a key for bookings.
  sessionBookings?: [
    {
      serviceDateTimeId: string;
      bookings: ISessionCustomer[];
    }
  ];

  sessionFilters?: any;

  sessionListDisplayTzMode: TimezoneSelectorMode;
  sessionListDisplayTzCustom: any;

  selectedSession?: ISession;
  groupServiceServiceAgreements: IGroupServiceServiceAgreement[];
  sessionCustomerWarnings: any;
  sessionCustomerDetails: ISessionCustomerDetail;
  sessionCustomerCustomerBookings: ISessionCustomer[];
  groupServiceSessionNotes: IGroupServiceNote[];
  groupServiceSessionCustomers: IGroupServiceCustomer[];
  groupServiceOverviewWarnings?: IGroupServiceOverviewWarnings;
  groupServiceSessionActivityLogs: IGroupServiceActivityLog[];
  sessionOverview?: ISessionOverview;
  scheduleTimeSlotId?: string;
  resetSessionCustomers?: boolean;
  sessionShiftSlotOverview: ISessionShiftSlotOverview;
  sessionSupportWorkers: ISessionSupportWorker[];
  shiftSlotActivityLogs: IGroupServiceActivityLog[];
  conflictShiftSlots: ISessionConflictShiftSlot[];
  groupServiceSupportWorkers: IGroupServiceSupportWorkersWithConflicts[];
  groupServiceSessions: IGroupServiceSession[];
  groupServiceTimeSlots: IGroupServiceTimeSlot[];
  timesheetSessionList: IGroupServiceTimesheetSession[];
  timesheetShiftSlotList: IGroupServiceTimesheetShiftSlot[];
  allPendingShiftSlots: IPendingShiftSlots[];
  selectedShiftSlots: IShiftSlot[];
  selectedTabKey: any;
  selectedSessionTabKey: string;
  customerBookingToOpen: string;
  customerSessionToBeRemoveList: ICustomerSessionToBeRemoved[];
}

const initialState: IGroupServiceStoreState = {
  selectedGroupService: null,
  groupServiceOverviewWarnings: null,
  groupServiceSessionNotes: null,
  groupServiceSessionCustomers: null,
  groupServiceSessionActivityLogs: [],
  sessions: [],
  sessionBookings: null,
  sessionListDisplayTzMode: TimezoneSelectorMode.MyTimezone,
  sessionListDisplayTzCustom: null,

  sessionFilters: [],
  selectedSession: null,
  groupServiceServiceAgreements: [],
  sessionCustomerWarnings: null,
  sessionCustomerDetails: null,
  sessionCustomerCustomerBookings: [],
  resetSessionCustomers: false,
  sessionShiftSlotOverview: null,
  sessionSupportWorkers: [],
  shiftSlotActivityLogs: [],
  conflictShiftSlots: [],
  groupServiceSupportWorkers: [],
  groupServiceSessions: [],
  groupServiceTimeSlots: [],
  timesheetSessionList: [],
  timesheetShiftSlotList: [],
  allPendingShiftSlots: [],
  selectedShiftSlots: [],
  selectedTabKey: null,
  selectedSessionTabKey: 'OVERVIEW',
  customerBookingToOpen: null,
  customerSessionToBeRemoveList: []
};

const groupServiceStore = {
  state: { ...initialState },
  reducers: {
    setSessions: (state, payload) => ({ ...state, sessions: payload }),
    // Added by Jir : This is a list of bookings per session; used for session listings.
    setSessionBookings: (state, payload) => ({ ...state, sessionBookings: payload }),

    setSessionFilters: (state, payload) => ({ ...state, sessionFilters: payload }),

    setSessionListDisplayTzMode: (state, payload) => ({ ...state, sessionListDisplayTzMode: payload }),
    setSessionListDisplayTzCustom: (state, payload) => ({ ...state, sessionListDisplayTzCustom: payload }),

    setSelectedSession: (state, payload) => ({ ...state, selectedSession: payload }),
    setGroupServiceServiceAgreements: (state, payload) => ({ ...state, groupServiceServiceAgreements: payload }),
    setGroupServiceSessionNotes: (state, payload) => ({ ...state, groupServiceSessionNotes: payload }),
    setGroupServiceSessionCustomers: (state, payload) => ({ ...state, groupServiceSessionCustomers: payload }),
    setSelectedGroupService: (state, payload) => ({ ...state, selectedGroupService: payload }),
    setGroupServiceOverviewWarnings: (state, payload) => ({ ...state, groupServiceOverviewWarnings: payload }),
    setCustomerBookingToOpen: (state, payload) => ({ ...state, customerBookingToOpen: payload }),
    setCustomerSessionToBeRemoveList: (state, payload) => ({
      ...state,
      customerSessionToBeRemoveList: payload
    }),
    updateGroupServiceStatus: (state, payload) => {
      return {
        ...state,
        selectedGroupService: {
          ...state.selectedGroupService,
          status: payload.publishStatus,
          updatedOn: new Date()
        }
      };
    },
    setGroupServiceSessionActivityLogs: (state, payload) => ({ ...state, groupServiceSessionActivityLogs: payload }),
    updateGroupServiceSessionActivityLogs: (state, payload) => {
      const selectedSessionActivityLogs = [...payload, ...state.groupServiceSessionActivityLogs];
      return { ...state, groupServiceSessionActivityLogs: selectedSessionActivityLogs };
    },
    setSessionCustomerWarnings: (state, payload) => ({ ...state, sessionCustomerWarnings: payload }),
    setSessionCustomerDetails: (state, payload) => {
      let customerUserIds = [];
      let numberOfBookingNotConfirmed = 0;
      _.forEach(payload.customers, (customer) => {
        if (customer.status === BookingStatus.ACCEPTED) {
          numberOfBookingNotConfirmed++;
        }
        customerUserIds.push(customer.customerUserId);
      });
      return {
        ...state,
        sessionCustomerDetails: {
          capacity: payload.capacity,
          bookedCapacity: payload.bookedCapacity,
          totalProcessedAmount: Number(payload.totalProcessedAmount),
          fullCustomerList: payload.customers,
          customerUserIds,
          numberOfBookingNotConfirmed
        }
      };
    },
    setSessionCustomerBookings: (state, payload) => ({ ...state, sessionCustomerCustomerBookings: payload }),
    setSessionOverview: (state, payload) => ({ ...state, sessionOverview: payload }),
    setResetSessionCustomers: (state, payload) => ({ ...state, resetSessionCustomers: payload }),
    updateSelectedGroupServiceDetail: (state, payload) => {
      return {
        ...state,
        selectedGroupService: {
          ...state.selectedGroupService,
          ...payload
        }
      };
    },
    setSessionsShiftSlotOverview: (state, payload) => ({ ...state, sessionShiftSlotOverview: payload }),
    setSessionSupportWorkers: (state, payload) => ({ ...state, sessionSupportWorkers: payload }),
    setShiftSlotActivityLogs: (state, payload) => ({ ...state, shiftSlotActivityLogs: payload }),
    setConflictShiftSlots: (state, payload) => ({ ...state, conflictShiftSlots: payload }),
    setgroupServiceSupportWorkers: (state, payload) => {
      const supportWorkersWithAssignedSessions = _.map(payload, (worker) => {
        worker.assignedSessions = [];
        return worker;
      });
      return { ...state, groupServiceSupportWorkers: supportWorkersWithAssignedSessions };
    },
    setGroupServiceSessions: (state, payload) => ({ ...state, groupServiceSessions: payload }),
    setGroupServiceTimeSlots: (state, payload) => ({ ...state, groupServiceTimeSlots: payload }),
    setTimesheetSessionList: (state, payload) => ({ ...state, timesheetSessionList: payload }),
    setTimesheetShiftSlotList: (state, payload) => ({ ...state, timesheetShiftSlotList: payload }),
    setAllPendingShiftSlots: (state, payload) => ({ ...state, allPendingShiftSlots: payload }),
    setSelectedShiftSlots: (state, payload) => ({ ...state, selectedShiftSlots: payload }),
    setSelectedTabKey: (state, payload) => ({ ...state, selectedTabKey: payload }),
    setSelectedSessionTabKey: (state, payload) => ({ ...state, selectedSessionTabKey: payload })
  },
  effects: (dispatch) => ({
    async doFetchSessions(payload, rootState) {
      // Edited by Jir : This method will fetch sessions across multiple services.
      const endpoint = `api/portal/group-services/sessions/list`;

      try {
        // TODO Remove
        // await asyncDelay(1000);

        const response = await apiClient.post(endpoint, payload);
        const sessions = _.get(response, 'data.sessions');

        // Do fetch session
        const sessionsToUpdate = payload.page === 1 ? sessions : [...sessions, ...rootState.groupServiceStore.sessions];

        dispatch.groupServiceStore.setSessions(sessionsToUpdate);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchBookingsForSession(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/list`;

      try {
        // TODO Remove
        // await asyncDelay(1000);

        const response = await apiClient.post(endpoint, payload);

        const customerBookings = _.get(response, 'data.customers');

        const sessionBooking = {
          serviceDateTimeId: payload.serviceDateTimeId,
          bookings: customerBookings
        };

        // check if the store has existing entries.
        const storeHasBookings = !_.isEmpty(rootState.groupServiceStore.sessionBookings);

        // Remove the current entry in the booking.
        const filteredSessionBookings = _.filter(
          rootState.groupServiceStore.sessionBookings,
          (sessionBooking) => sessionBooking.serviceDateTimeId !== payload.serviceDateTimeId
        );

        // Update current store.
        const updatedBooking = storeHasBookings ? [...filteredSessionBookings, sessionBooking] : [sessionBooking];

        dispatch.groupServiceStore.setSessionBookings(updatedBooking);
      } catch (e) {
        throw e;
      }
    },

    async doResetSessions(payload, rootState) {
      dispatch.groupServiceStore.setSessions([]);
    },

    // Note from Jir : This doesn't belong here.
    setSelectedGroupService: (state, payload) => ({ ...state, selectedGroupService: payload }),

    async doFetchGroupServiceOverview(payload, rootState) {
      try {
        const endpoint = `api/portal/group-services/${payload.serviceId}/overview`;
        const result = await apiClient.get(endpoint, payload);
        dispatch.groupServiceStore.setSelectedGroupService(result.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchGroupBookingOverviewWarning(payload, rootState) {
      try {
        const result = await apiClient.get(`api/portal/group-services/${payload.serviceId}/warnings`);
        dispatch.groupServiceStore.setGroupServiceOverviewWarnings(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doPublishGroupService(payload) {
      const endPoint = `/api/portal/group-services/${payload.serviceId}/publish`;
      try {
        await apiClient.put(endPoint, payload);
        dispatch.groupServiceStore.updateGroupServiceStatus({
          serviceId: payload.serviceId,
          publishStatus: ServicePublishStatus.PUBLISHED
        });
      } catch (e) {
        throw e;
      }
    },

    async doFetchGroupServiceSessionNotes(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/booking-notes/list`;
      try {
        const result = await apiClient.post(endpoint, payload);

        if (payload.page === 1) {
          dispatch.groupServiceStore.setGroupServiceSessionNotes(result.data.notes);
        } else {
          const newNotes = [...rootState.groupServiceStore.groupServiceSessionNotes, ...result.data.notes];
          dispatch.groupServiceStore.setGroupServiceSessionNotes(newNotes);
        }
      } catch (e) {
        throw e;
      }
    },

    async doRemoveGroupServiceSessionNote(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/booking-notes/${payload.noteId}`;

      try {
        apiClient.delete(endpoint);
        const newNotes = _.filter(rootState.groupServiceStore.groupServiceSessionNotes, (note) => {
          return note.noteId !== payload.noteId;
        });

        dispatch.groupServiceStore.setGroupServiceSessionNotes(newNotes);
      } catch (e) {
        throw e;
      }
    },

    async doAddGroupServiceSessionNote(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/booking-notes`;

      try {
        await apiClient.post(endpoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doUnpublishGroupService(payload) {
      const endPoint = `/api/portal/group-services/${payload.serviceId}/unpublish`;
      try {
        await apiClient.put(endPoint, payload);
        dispatch.groupServiceStore.updateGroupServiceStatus({
          serviceId: payload.serviceId,
          publishStatus: ServicePublishStatus.UNPUBLISHED
        });
      } catch (e) {
        throw e;
      }
    },

    async doEditGroupServiceSessionNote(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/booking-notes/${payload.noteId}`;

      try {
        await apiClient.put(endpoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doFetchGroupServiceCustomer(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/customers/list`;
      try {
        const response = await apiClient.post(endpoint, payload);
        const newCustomers = [];
        _.map(response.data, (customer) => {
          const newUser = {
            taggedUserId: customer.customerUserId,
            taggedUserFirstName: customer.firstName,
            taggedUserLastName: customer.lastName,
            taggedUserAvatarUrl: customer.customerAvatarUrl
          };

          newCustomers.push(newUser);
        });

        dispatch.groupServiceStore.setGroupServiceSessionCustomers(newCustomers);
      } catch (e) {
        throw e;
      }
    },

    async doArchiveGroupService(payload, rootState) {
      try {
        await apiClient.post(`/api/portal/group-services/${payload.serviceId}/archive`, payload);
        dispatch.groupServiceStore.updateGroupServiceStatus({
          serviceId: payload.serviceId,
          publishStatus: ServicePublishStatus.ARCHIVED
        });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchGroupServiceSessionActivityLogs(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/activity-logs`;
      try {
        const response = await apiClient.get(endpoint);
        dispatch.groupServiceStore.setGroupServiceSessionActivityLogs(response.data.sessionHistories);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchSingleSession(payload, rootStore) {
      try {
        const result = await apiClient.get(
          `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}`
        );
        dispatch.groupServiceStore.setSelectedSession(result.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchGroupServiceServiceAgreements(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/customers/${payload.customerUserId}/sessions/check-service-agreement`;
      try {
        const result = await apiClient.post(endPoint, payload);
        dispatch.groupServiceStore.setGroupServiceServiceAgreements(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doFetchSessionWarning(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/warnings`;
      try {
        const result = await apiClient.get(endPoint, payload);
        dispatch.groupServiceStore.setSessionCustomerWarnings(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doFetchSessionCustomerDetails(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/list`;
      try {
        const result = await apiClient.post(endPoint, payload);
        dispatch.groupServiceStore.setSessionCustomerBookings(result.data.customers);
      } catch (e) {
        throw e;
      }
    },

    async doFetchSessionCustomerDetailsFull(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/bookings/list`;
      try {
        const result = await apiClient.post(endPoint, payload);
        dispatch.groupServiceStore.setSessionCustomerBookings(result.data.customers);
        dispatch.groupServiceStore.setSessionCustomerDetails(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doFetchSessionOverview(payload) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}`;
      try {
        const result = await apiClient.get(endPoint, payload);
        dispatch.groupServiceStore.setSessionCustomerBookings(result.data.customers);
        dispatch.groupServiceStore.setSessionCustomerDetails(result.data);
      } catch (e) {
        throw e;
      }
    },

    async updateSessionCapacity(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/capacity`;
      try {
        await apiClient.put(endPoint, payload);
        dispatch.groupServiceStore.setSelectedSession({
          ...rootStore.groupServiceStore.selectedSession,
          capacity: payload.capacity
        });
        return true;
      } catch (e) {
        throw e;
      }
    },

    async doUpdateSessionLocation(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/schedules/${payload.serviceScheduleId}/sessions/${payload.serviceDateTimeId}/edit-location`;
      try {
        const result = await apiClient.put(endPoint, payload);
        dispatch.groupServiceStore.setSelectedSession({
          ...rootStore.groupServiceStore.selectedSession,
          address: payload.location
        });
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doUpdateSessionDates(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/schedules/${payload.serviceScheduleId}/sessions/${payload.serviceDateTimeId}/edit-start-end-time`;
      try {
        const result = await apiClient.put(endPoint, payload);
        dispatch.groupServiceStore.setSelectedSession({
          ...rootStore.groupServiceStore.selectedSession,
          startDateTime: payload.startDateTime,
          endDateTime: payload.endDateTime
        });
        dispatch.groupServiceStore.doFetchBookingsForSession({
          serviceId: payload.serviceId,
          serviceDateTimeId: payload.serviceDateTimeId
        });
        return true;
      } catch (e) {
        throw e;
      }
    },

    async doCancelSession(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/cancel`;
      try {
        const result = await apiClient.put(endPoint, payload);
        dispatch.groupServiceStore.setSelectedSession({
          ...rootStore.groupServiceStore.selectedSession,
          sessionStatus: GroupServiceSessionStatus.CANCELLED
        });

        const newGroupServiceSessions = _.map(rootStore.servicesStore.groupServiceSessions, (session) => {
          return {
            ...session,
            sessionStatus:
              session.serviceDateTimeId === payload.serviceDateTimeId
                ? GroupServiceSessionStatus.CANCELLED
                : session.sessionStatus
          };
        });

        dispatch.servicesStore.setGroupServiceSessions(newGroupServiceSessions);
        dispatch.groupServiceStore.setGroupServiceSessionActivityLogs(result.data.sessionHistories);
        return true;
      } catch (e) {
        throw e;
      }
    },

    async doRevertSessionStatus(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/status`;
      try {
        const result = await apiClient.put(endPoint, payload);

        if (result && result.data) {
          dispatch.groupServiceStore.setSelectedSession({
            ...rootStore.groupServiceStore.selectedSession,
            sessionStatus: payload.sessionStatus
          });

          const newGroupServiceSessions = _.map(rootStore.servicesStore.groupServiceSessions, (session) => {
            return {
              ...session,
              sessionStatus:
                session.serviceDateTimeId === payload.serviceDateTimeId ? payload.sessionStatus : session.sessionStatus
            };
          });

          dispatch.servicesStore.setGroupServiceSessions(newGroupServiceSessions);
          dispatch.groupServiceStore.updateGroupServiceSessionActivityLogs(result.data.sessionHistories);
        }
        return true;
      } catch (e) {
        throw e;
      }
    },

    async doCloseSession(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/close`;
      try {
        await apiClient.put(endPoint, payload);
        dispatch.groupServiceStore.setSelectedSession({
          ...rootStore.groupServiceStore.selectedSession,
          sessionStatus: GroupServiceSessionStatus.CLOSED
        });
        return true;
      } catch (e) {
        throw e;
      }
    },

    async doCheckCloseSession(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/check-close`;
      try {
        const result = await apiClient.post(endPoint, payload);
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doCheckStartValidity(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/start-data`;
      try {
        const result = await apiClient.post(endPoint, payload);
        return result && result.data;
      } catch (e) {
        throw e;
      }
    },

    async doStartSession(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/start`;
      try {
        const result = await apiClient.put(endPoint, payload);
        dispatch.groupServiceStore.setSelectedSession({
          ...rootStore.groupServiceStore.selectedSession,
          sessionStatus: GroupServiceSessionStatus.INPROGRESS,
          sessionStartDateTime: payload.startDateTime
        });
        return true;
      } catch (e) {
        throw e;
      }
    },

    async doEndSession(payload, rootStore) {
      const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/finish`;
      try {
        const result = await apiClient.put(endPoint, payload);
        dispatch.groupServiceStore.setSelectedSession({
          ...rootStore.groupServiceStore.selectedSession,
          sessionStatus: GroupServiceSessionStatus.COMPLETED,
          sessionEndDateTime: payload.endDateTime
        });
        return true;
      } catch (e) {
        throw e;
      }
    },

    async doFetchSessionShiftSlotOverview(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/list`;

      try {
        const result = await apiClient.post(endpoint, payload);
        dispatch.groupServiceStore.setSessionsShiftSlotOverview(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doFetchSessionSupportWorkers(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/workers`;

      try {
        const result = await apiClient.post(endpoint, payload);
        dispatch.groupServiceStore.setSessionSupportWorkers(result.data);
      } catch (e) {
        throw e;
      }
    },

    // Shift actions
    async doAddShiftSlots(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots`;
      try {
        //await asyncDelay(1000);
        const result = await apiClient.post(endpoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doAssignWorkerToShift(payload) {
      try {
        const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/assign`;
        await apiClient.put(endpoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doEditShiftTimes(payload) {
      try {
        const apiEndpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/date-time`;
        await apiClient.put(apiEndpoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doCheckConflictForEditShiftTimes(payload) {
      try {
        const apiEndpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/date-time/conflict-check`;
        const result = await apiClient.post(apiEndpoint, payload);
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doRemoveShiftSlot(payload) {
      try {
        const endpoint = `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots`;
        await apiClient.delete(endpoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doStartShift(payload) {
      const endpoint = `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/start`;
      try {
        await apiClient.put(endpoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doFinishShift(payload) {
      try {
        const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/finish`;
        await apiClient.put(endPoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doMarkShiftAsNoShow(payload) {
      try {
        const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/no-show`;
        await apiClient.put(endPoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doMarkShiftAsAttended(payload) {
      try {
        const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/attended`;
        await apiClient.put(endPoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doEditShiftStartTime(payload) {
      const endpoint = `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/start-date-time`;
      try {
        const requestPayload = {
          startDateTime: payload.startDateTime
        };
        const result = await apiClient.put(endpoint, requestPayload);
      } catch (e) {
        throw e;
      }
    },

    async doRevokeShiftApproval(payload) {
      try {
        // TODO API call here
        // const apiEndpoint = `/api/portal/session/`;
        // const response = await apiClient.get();

        console.log('doRevokeShiftApproval');
        await asyncDelay(1000);
      } catch (e) {
        throw e;
      }
    },

    async doApproveShift(payload) {
      try {
        const endPoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/approve`;
        await apiClient.put(endPoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doEditShiftStartEndTime(payload) {
      try {
        const endpoint = `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/start-end-date-time`;
        const requestPayload = {
          startDateTime: payload.startDateTime,
          endDateTime: payload.endDateTime
        };
        const result = await apiClient.put(endpoint, requestPayload);
      } catch (e) {
        throw e;
      }
    },

    async doShiftConfirmSlot(payload) {
      try {
        const endpoint = `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots`;
        await apiClient.put(endpoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doFetchShiftSlotActivityLogs(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/${payload.supportWorkerAttendanceId}/activity-logs`;
      try {
        const result = await apiClient.get(endpoint);
        dispatch.groupServiceStore.setShiftSlotActivityLogs(result.data.shiftSlotHistories);
      } catch (e) {
        throw e;
      }
    },

    async doFetchConflictShiftSlots(payload) {
      const endpoint = `/api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/check-conflict`;
      try {
        const result = await apiClient.post(endpoint, payload);
        dispatch.groupServiceStore.setConflictShiftSlots(result.data.conflictResult);
      } catch (e) {
        throw e;
      }
    },

    async doFetchGroupServiceSessions(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/check-availability`;
      try {
        const result = await apiClient.post(endpoint);
        dispatch.groupServiceStore.setGroupServiceSessions(result.data.sessions);
      } catch (e) {
        throw e;
      }
    },

    async doFetchGroupServiceTimeSlots(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/schedules/check-availability`;

      try {
        const result = await apiClient.post(endpoint, payload);
        dispatch.groupServiceStore.setGroupServiceTimeSlots(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doFetchGroupServiceWorkerForAssignment(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/search-workers-with-availability-indicator`;

      try {
        const result = await apiClient.post(endpoint, payload);
        dispatch.groupServiceStore.setgroupServiceSupportWorkers(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doAddTeamMemberToSession(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/add-team-member-to-session`;

      try {
        const result = apiClient.put(endpoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doBatchStartShifts(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/bulk-start`;
      try {
        await apiClient.put(endpoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doFetchGroupServiceTimesheetSessions(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/timesheet`;
      try {
        let body = { ...payload };
        if (body && body.filters) {
          // Transform the filters array into objects and remove the empty filters.
          const filters = _.chain(_.filter(body.filters, (filter) => !Utils.isEmpty(filter.values)))
            .keyBy('filter')
            .mapValues('values')
            .value();
          // Merge back the filters into the payload
          body = { ...body, ...filters };
          // Delete the 'filters' props to have a lighter payload.
          delete body.filters;
        }

        const result = await apiClient.post(endpoint, body);

        if (result && result.data) {
          dispatch.groupServiceStore.setTimesheetSessionList(result.data);
          dispatch.groupServiceStore.setTimesheetShiftSlotList([]);
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchGroupServiceTimesheetShiftSlots(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/timesheet/${payload.sessionId}/shift-slots`;
      try {
        dispatch.groupServiceStore.setTimesheetShiftSlotList([]);

        const result = await apiClient.post(endpoint, { shiftSlotStatus: payload.shiftSlotStatus });

        if (result && result.data) {
          dispatch.groupServiceStore.setTimesheetShiftSlotList(result.data.shiftSlots);
        }
      } catch (e) {
        throw e;
      }
    },

    async doEditShiftSlotsStartEndTime(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/timesheet/${payload.sessionId}/edit-start-end-date-time`;
      try {
        await apiClient.put(endpoint, { supportWorkerAttendances: payload.shiftSlots });
      } catch (e) {
        throw e;
      }
    },

    async doApproveShiftSlots(payload, rootState) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/timesheet/${payload.sessionId}/approve-shift-slot`;

      try {
        const body = {
          supportWorkerAttendanceIds: _.map(payload.shiftSlots, (shiftSlot) => shiftSlot.supportWorkerAttendanceId)
        };

        const newAllList = _.filter(
          rootState.groupServiceStore.allPendingShiftSlots,
          (item) =>
            !_.find(
              payload.shiftSlots,
              (payloadItem) => payloadItem.supportWorkerAttendanceId === item.supportWorkerAttendanceId
            )
        );

        dispatch.groupServiceStore.setAllPendingShiftSlots(newAllList);

        await apiClient.put(endpoint, body);
      } catch (e) {
        throw e;
      }
    },

    async doRevokeShiftSlotsApproval(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/timesheet/${payload.sessionId}/revoke-shift-slot-approval`;

      try {
        const body = {
          supportWorkerAttendanceIds: _.map(payload.shiftSlots, (shiftSlot) => shiftSlot.supportWorkerAttendanceId)
        };

        await apiClient.put(endpoint, body);
      } catch (e) {
        throw e;
      }
    },

    async doBatchFinishShifts(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/bulk-finish`;
      try {
        await apiClient.put(endpoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doGetAllPendingShiftSlots(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/get-all-approved-timesheet`;
      try {
        const result = await apiClient.get(endpoint);

        if (result && result.data) {
          dispatch.groupServiceStore.setAllPendingShiftSlots(result.data);
        }
      } catch (e) {
        throw e;
      }
    },

    async doBatchApproveShifts(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/sessions/${payload.serviceDateTimeId}/shift-slots/bulk-approve`;
      try {
        await apiClient.put(endpoint, payload);
      } catch (e) {
        throw e;
      }
    },

    async doApproveAllPendingShiftSlots(payload) {
      const endpoint = `api/portal/group-services/${payload.serviceId}/approve-all-shift-slot`;
      try {
        const body = { supportWorkerAttendanceIds: payload.supportWorkerAttendanceIds };

        await apiClient.put(endpoint, body);
      } catch (e) {
        throw e;
      }
    },

    async doGetCustomerSessionToBeRemoveList(payload, rootState) {
      try {
        const result = await apiClient.post(
          `api/portal/group-bookings/${payload.customerUserId}/schedule/${payload.serviceScheduleId}/not-started/list`,
          payload
        );
        if (result && result.data) {
          dispatch.groupServiceStore.setCustomerSessionToBeRemoveList(result.data);
        }
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doRemoveCustomerFromSchedule(payload, rootState) {
      try {
        await apiClient.delete(
          `/api/portal/group-bookings/${payload.customerUserId}/schedule/${payload.serviceScheduleId}/not-started/delete`,
          payload
        );
        dispatch.servicesStore.updateGroupServiceSession({
          attendanceIds: payload.attendanceIds
        });
        dispatch.customersStore.updateGroupServiceCustomerUpcomingCounts({
          attendanceIds: payload.attendanceIds,
          customerUserId: payload.customerUserId
        });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    }
  })
};

export default groupServiceStore;
