import React, { Component } from 'react';
import { Paragraph, FieldLabel, Text } from 'common-components/typography';
import { Icon, Radio, Row, Col } from 'antd';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { BookingStatus, EditRecurringMode } from 'utilities/enum-utils';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { ErrorSVG } from 'assets/UndrawSVG';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import _ from 'lodash';

interface IBookingCancelReInstateModalProps {
  onClose: () => void;
  selectedBooking: typeof state.bookingsStore.selectedBookingItem;
  recurringBookingList: typeof state.bookingsStore.recurringBookingList;
  isOpen: boolean;
  doUpdateBookingCancelBooking: typeof dispatch.bookingsStore.doUpdateBookingCancelBooking;
  doFetchNumberOfBookingsForRecurrence: typeof dispatch.bookingsStore.doFetchNumberOfBookingsForRecurrence;
  doUncancelBooking: typeof dispatch.bookingsStore.doUncancelBooking;
  doFetchUncancelConflicts: typeof dispatch.bookingsStore.doFetchUncancelConflicts;
}

class BookingCancelReInstateModal extends Component<IBookingCancelReInstateModalProps, any> {
  state = {
    step: 2,
    title: 'Reinstate booking?',
    canManuallyClose: true,
    selectedOption: EditRecurringMode.Current,
    totalNumberOfImpactedBookings: 0,
    numberOfBookings: 1,
    workerConflicts: []
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;

    // Do any manual clean up; reset to initial state
    this._reset();

    onClose();
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _doReinstateBooking = async () => {
    this.setState({ step: 2, canManuallyClose: false });
    try {
      await this.props.doUncancelBooking({
        editRecurringMode: this.state.selectedOption,
        bookingStatus: this.props.selectedBooking.status,
        bookingRequestId: this.props.selectedBooking.bookingRequestId,
        isRecurring: this.props.selectedBooking.isRecurring
      });
      this.setState({
        title: (
          <>
            Booking reinstated
            <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
          </>
        ),
        step: 3,
        canManuallyClose: true
      });
    } catch (e) {
      this.setState({ step: 4, canManuallyClose: true });
    }
  };

  private _checkRecurringBookingCustomerScheduleArchive = () => {
    const { recurringBookingList, selectedBooking } = this.props;
    if (selectedBooking.customerScheduleArchiveDate) {
      if (this.state.selectedOption === EditRecurringMode.Current) {
        return moment
          .tz(selectedBooking.endDateTime, selectedBooking.timezone)
          .isSameOrAfter(moment.tz(selectedBooking.customerScheduleArchiveDate, selectedBooking.timezone));
      } else {
        return !!_.find(recurringBookingList, (booking) =>
          moment
            .tz(booking.endDateTime, selectedBooking.timezone)
            .isSameOrAfter(moment.tz(selectedBooking.customerScheduleArchiveDate, selectedBooking.timezone))
        );
      }
    }
    return false;
  };

  private _checkForConflicts = async () => {
    try {
      let workerConflicts = null;
      if (this.props.selectedBooking.workerUserId) {
        workerConflicts = await this.props.doFetchUncancelConflicts({
          editRecurringMode: this.state.selectedOption,
          bookingRequestId: this.props.selectedBooking.bookingRequestId,
          isRecurring: this.props.selectedBooking.isRecurring
        });
      }
      await this.setState({ workerConflicts: workerConflicts, step: 1 });
    } catch (e) {
      await this.setState({ step: 4, canManuallyClose: true });
    }
  };

  private _reset = () =>
    this.setState({
      step: 2,
      title: 'Reinstate booking?',
      canManuallyClose: true,
      selectedOption: EditRecurringMode.Current,
      totalNumberOfImpactedBookings: 0,
      numberOfBookings: 1,
      hasConflict: false
    });

  componentDidMount = async () => {
    this.setState({ step: 2 });
    const { selectedBooking } = this.props;

    if (
      selectedBooking &&
      moment
        .tz(selectedBooking.endDateTime, selectedBooking.timezone)
        .isSameOrAfter(moment.tz(selectedBooking.customerScheduleArchiveDate, selectedBooking.timezone))
    ) {
      this.setState({ step: 6, title: 'Cannot re-instate booking' });
    } else {
      const relatedBookings: any = await this.props.doFetchNumberOfBookingsForRecurrence({
        bookingRequestId: selectedBooking.bookingRequestId,
        status: selectedBooking.status
      });
      const totalNumberOfImpactedBookings = 1 + relatedBookings.length;
      if (!this.props.selectedBooking.isRecurring || totalNumberOfImpactedBookings === 1) {
        this._checkForConflicts();
      }
      this.setState({
        totalNumberOfImpactedBookings,
        step: this.props.selectedBooking.isRecurring && totalNumberOfImpactedBookings > 1 ? 5 : 1
      });
    }
  };

  componentDidUpdate = async (prevProps: Readonly<IBookingCancelReInstateModalProps>) => {
    if (!prevProps.isOpen && this.props.isOpen) {
      const { selectedBooking } = this.props;

      // if current booking endDateTime is after customer schedule archive date
      if (
        moment
          .tz(selectedBooking.endDateTime, selectedBooking.timezone)
          .isSameOrAfter(moment.tz(selectedBooking.customerScheduleArchiveDate, selectedBooking.timezone))
      ) {
        this.setState({ step: 6, title: 'Cannot re-instate booking' });
      } else {
        const relatedBookings: any = await this.props.doFetchNumberOfBookingsForRecurrence({
          bookingRequestId: selectedBooking.bookingRequestId,
          status: selectedBooking.status
        });
        const totalNumberOfImpactedBookings = 1 + relatedBookings.length;
        if (!this.props.selectedBooking.isRecurring || totalNumberOfImpactedBookings === 1) {
          this._checkForConflicts();
        }
        this.setState({
          totalNumberOfImpactedBookings,
          step: this.props.selectedBooking.isRecurring && totalNumberOfImpactedBookings > 1 ? 5 : 1
        });
      }
    }
  };

  private _renderView = () => {
    const { step, totalNumberOfImpactedBookings } = this.state;
    const { selectedBooking } = this.props;

    if (step === 1) {
      return (
        <div>
          <div className="anim-slide-left">
            <div className="mb-medium">
              <Paragraph>
                Are you sure you want to <b>reinstate</b>{' '}
                {this.state.selectedOption === EditRecurringMode.Current
                  ? 'this booking'
                  : `those ${totalNumberOfImpactedBookings} bookings`}
                ?
              </Paragraph>
              {selectedBooking.workerUserId &&
                (this.state.workerConflicts && this.state.workerConflicts.length > 0 ? (
                  <>
                    {this.state.workerConflicts.length === 1 ? (
                      <Paragraph>
                        The assigned worker for this booking,{' '}
                        <b>
                          {selectedBooking.workerFirstName} {selectedBooking.workerLastName}
                        </b>
                        , has been assigned to another booking which conflicts with the time of this booking.
                      </Paragraph>
                    ) : (
                      <Paragraph>
                        Several of the assigned workers on the bookings being reinstated have been assigned to other
                        booking with <b>time conflicts</b>.
                      </Paragraph>
                    )}
                    <Paragraph>
                      If you choose to reinstate this booking, you will have to assign another worker to this booking
                      after it has been reinstated.
                    </Paragraph>
                  </>
                ) : (
                  <Paragraph>
                    The assigned worker for this booking,{' '}
                    <b>
                      {selectedBooking.workerFirstName} {selectedBooking.workerLastName}
                    </b>
                    , is available and will be assigned as the worker for this booking, pending their confirmation.
                  </Paragraph>
                ))}
            </div>
          </div>

          <ActionModalFooter>
            <GhostButton size="large" className="mr-medium" onClick={this._onCloseModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" onClick={this._doReinstateBooking}>
              Reinstate booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div>
          <div style={{ minHeight: 317 }}>
            <SpinningLoader size={150} message={'Loading'} />
          </div>
        </div>
      );
    }
    if (step === 3) {
      return (
        <div>
          <div>
            <div className="mb-medium">
              <Paragraph>
                You have successfully reinstated{' '}
                {this.state.selectedOption === EditRecurringMode.Current ? 'this booking' : 'all bookings'}.
              </Paragraph>
              {this.state.workerConflicts && this.state.workerConflicts.length > 0 && (
                <Paragraph>
                  You will have to assign a new worker to{' '}
                  {this.state.selectedOption === EditRecurringMode.Current
                    ? 'this booking'
                    : 'all bookings reinstated with a conflict'}{' '}
                  as{' '}
                  <b>
                    {selectedBooking.workerFirstName} {selectedBooking.workerLastName}
                  </b>{' '}
                  has been removed.
                </Paragraph>
              )}
            </div>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (step === 4) {
      return (
        <div>
          <div style={{ minHeight: 317 }} className="flex-column justify-center text-align-center">
            <div className="pv-medium flex-column justify-center">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium flex-column justify-center ">
              <Paragraph>Oops something has gone wrong, please try again</Paragraph>
            </div>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Back to booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (step === 5) {
      return (
        <>
          <Paragraph>
            This booking is part of a recurring series. There are{' '}
            <b>
              {totalNumberOfImpactedBookings} booking{totalNumberOfImpactedBookings !== 1 && 's'}
            </b>{' '}
            in this series that are currently{' '}
            {selectedBooking.status === BookingStatus.BUSINESS_CANCELLED
              ? "'Cancelled by Business'."
              : selectedBooking.status === BookingStatus.CUSTOMER_CANCELLED_WITH_FEE
              ? "'Cancelled by Customer (Fee)'"
              : "'Cancelled by Customer (No Fee)'"}
            .
          </Paragraph>
          <div>
            <Radio.Group value={this.state.selectedOption} onChange={this._onChangeOption} className="ml-medium">
              <Radio
                value={EditRecurringMode.Current}
                className={`${this.state.selectedOption === EditRecurringMode.Current && 'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Reinstate this booking only.
                </div>
              </Radio>
              <br />
              <Radio
                value={EditRecurringMode.CurrentAll}
                className={`${this.state.selectedOption === EditRecurringMode.CurrentAll &&
                  'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Reinstate all {totalNumberOfImpactedBookings} bookings.
                </div>
              </Radio>
            </Radio.Group>
          </div>

          <ActionModalFooter>
            <GhostButton size="large" className="mr-medium" onClick={this._onCloseModal}>
              Cancel
            </GhostButton>
            <PrimaryButton
              size="large"
              onClick={() =>
                this._checkRecurringBookingCustomerScheduleArchive()
                  ? this.setState({ step: 7, title: 'Bookings unable to be re-instated' })
                  : this._checkForConflicts()
              }
            >
              Continue
            </PrimaryButton>
          </ActionModalFooter>
        </>
      );
    }
    if (step === 6) {
      const momentStartDateTime = moment.tz(selectedBooking.startDateTime, selectedBooking.timezone);
      const momentEndDateTime = moment.tz(selectedBooking.endDateTime, selectedBooking.timezone);
      return (
        <>
          <Paragraph className="mb-medium">
            This booking cannot be re-instated as the selected customer is scheduled to be archived on{' '}
            <Text weight="bold">
              {moment.tz(selectedBooking.customerScheduleArchiveDate, selectedBooking.timezone).format('DD/MM/YYYY')}
            </Text>
            .
          </Paragraph>
          <FieldLabel text={'SCHEDULED BOOKING TIME'} />
          {moment
            .tz(selectedBooking.startDateTime, selectedBooking.timezone)
            .isSame(moment.tz(selectedBooking.endDateTime, selectedBooking.timezone), 'day') ? (
            <Text>
              {momentStartDateTime.format('DD/MM/YYYY')}, {momentStartDateTime.format('LT')} -{' '}
              {momentEndDateTime.format('LT')}
            </Text>
          ) : (
            <Text>
              {momentStartDateTime.format('DD/MM/YYYY LT')} - {momentEndDateTime.format('DD/MM/YYYY LT')}
            </Text>
          )}
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </>
      );
    }
    if (step === 7) {
      const cannotReInstateBookings = _.chain(this.props.recurringBookingList)
        .filter((booking) => {
          return moment
            .tz(booking.startDateTime, selectedBooking.timezone)
            .isSameOrAfter(moment.tz(selectedBooking.customerScheduleArchiveDate, selectedBooking.timezone));
        })
        .orderBy('startDateTime')
        .value();
      return (
        <>
          <Paragraph>
            This bookings cannot be re-instated as the selected customer is scheduled to be archived on or before the
            date of the booking.
          </Paragraph>
          <Paragraph className="mb-medium">The following bookings listed below will not be re-instated.</Paragraph>
          <FieldLabel text={'SCHEDULED SCHEDULED TO BE ARCHIVED ON'} />
          <div className="mb-medium">
            <Text>
              {moment.tz(selectedBooking.customerScheduleArchiveDate, selectedBooking.timezone).format('DD/MM/YYYY')}
            </Text>
          </div>
          <Row className="pv-medium mh-medium bordered-bottom border-standard-gray">
            <Col>
              <Text color={'secondary'} size="small">
                BOOKING TIME
              </Text>
            </Col>
          </Row>
          {_.map(cannotReInstateBookings, (booking: any) => (
            <Row className="mv-medium mh-medium">
              <Col>
                {moment
                  .tz(booking.startDateTime, selectedBooking.timezone)
                  .isSame(moment.tz(booking.endDateTime, selectedBooking.timezone), 'day') ? (
                  <Text>
                    {moment.tz(booking.startDateTime, selectedBooking.timezone).format('DD MMMM YYYY - LT')} -{' '}
                    {moment.tz(booking.endDateTime, selectedBooking.timezone).format('LT')}
                  </Text>
                ) : (
                  <Text>
                    {moment.tz(booking.startDateTime, selectedBooking.timezone).format('DD MMMM YYYY LT')} -{' '}
                    {moment.tz(booking.endDateTime, selectedBooking.timezone).format('DD MMMM YYYY LT')}
                  </Text>
                )}
              </Col>
            </Row>
          ))}
          <ActionModalFooter>
            <GhostButton size="large" className="mr-medium" onClick={this._onCloseModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" onClick={this._doReinstateBooking}>
              Continue
            </PrimaryButton>
          </ActionModalFooter>
        </>
      );
    }
  };

  render() {
    const { isOpen } = this.props;
    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="large"
        verticalAlignment="highest"
      >
        {this._renderView()}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBooking: state.bookingsStore.selectedBookingItem,
  recurringBookingList: state.bookingsStore.recurringBookingList
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateBookingCancelBooking: dispatch.bookingsStore.doUpdateBookingCancelBooking,
  doFetchNumberOfBookingsForRecurrence: dispatch.bookingsStore.doFetchNumberOfBookingsForRecurrence,
  doUncancelBooking: dispatch.bookingsStore.doUncancelBooking,
  doFetchUncancelConflicts: dispatch.bookingsStore.doFetchUncancelConflicts
});

export default connect(
  mapState,
  mapDispatch
)(BookingCancelReInstateModal);
