import { Tooltip2 } from '@blueprintjs/popover2';
import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import React, { PureComponent } from 'react';

interface IPinnedAlertIconProps {
  customerUserId: string;
  pinnedAlertNumber: number;
  usePortal?: boolean;
  containerClassName?: string;
}

interface IPinnedAlertIconState {
  isPinnedAlertModalOpen: boolean;
}

class PinnedAlertIcon extends PureComponent<IPinnedAlertIconProps, IPinnedAlertIconState> {
  state = {
    isPinnedAlertModalOpen: false
  };

  render() {
    const { pinnedAlertNumber, usePortal = false } = this.props;

    return (
      <>
        {pinnedAlertNumber > 0 && (
          <Tooltip2
            content={
              <div>
                <Icon
                  type={pinnedAlertNumber > 0 ? 'pushpin' : 'check-circle'}
                  theme={'filled'}
                  className={`mr-small text-color-warning-orange`}
                />
                <Text className={'text-color-warning-orange'}>Pinned alerts</Text>
                <br />
                <Text>
                  The customer has{' '}
                  <b>
                    {pinnedAlertNumber} pinned alert{pinnedAlertNumber !== 1 && 's'}.
                  </b>
                </Text>
              </div>
            }
            position={'bottom'}
            usePortal={usePortal}
            className={'white-tooltip'}
          >
            <Icon type={'pushpin'} theme={'filled'} className={`mh-small text-color-warning-orange`} />
          </Tooltip2>
        )}
      </>
    );
  }
}

export default PinnedAlertIcon;
