import React, { Component } from 'react';
import { Icon, Skeleton } from 'antd';
import { Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import {
  IGroupBookingDetails,
  ITransportBookingDetails
} from 'views/group-services/manage-booking-modal/interfaces/group-booking-interfaces';
import _ from 'lodash';
import EditTransportBookingModal from 'views/group-services/manage-booking-modal/panels/transport/EditTransportBookingModal';
import TransportBookingDetailsPanel from 'views/group-services/manage-booking-modal/panels/transport/TransportBookingDetailsPanel';
import InfoPanel from 'common-components/alerts/InfoPanel';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import EditTransportBookingTransportModal from '../../common-components/EditTransportBookingTransportModel';
import { TransportAttendanceType } from '../../../../../utilities/enum-utils';
import { ModalLoading } from 'views/group-services/manage-booking-modal/common-components/ModalLoading';

interface ITransportOptionsPanel {
  booking: IGroupBookingDetails;
  isLoading: boolean;
}

interface ITransportOptionsPanelState {
  isEditModalOpen: boolean;
  editMode: 'add' | 'edit' | string;
  editType: 'before' | 'after' | string;
  editDetails?: ITransportBookingDetails;
  isOpenTransportEdit: boolean;
}

class TransportOptionsPanel extends Component<ITransportOptionsPanel, ITransportOptionsPanelState> {
  state = {
    isEditModalOpen: false,
    editMode: '',
    editType: '',
    editDetails: null,
    isLoading: false,
    isOpenTransportEdit: false
  };

  //region Modal handlers
  onOpenEditModal = ({ editMode, editType, editDetails }) =>
    this.setState({ editMode, editType, editDetails, isEditModalOpen: true });

  onOpenEditTransportModal = ({ editMode, editType, editDetails }) =>
    this.setState({ editMode, editType, editDetails, isOpenTransportEdit: true });

  onCloseEditModal = () => this.setState({ isEditModalOpen: false });

  onCloseEditTransportModal = () => this.setState({ isOpenTransportEdit: false });
  //endregion

  render() {
    const { booking, isLoading } = this.props;

    // Loading
    if (isLoading) {
      return <ModalLoading text={'Fetching transport options...'} />;
    }

    const { transportBeforeBooking, transportAfterBooking, isTravelEnabled } = booking;

    const hasTransportBefore = !_.isEmpty(transportBeforeBooking);
    const hasTransportAfter = !_.isEmpty(transportAfterBooking);

    return (
      <div className="anim-fade-in-fast">
        {isLoading ? (
          <div>
            <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
          </div>
        ) : (
          <>
            {!isTravelEnabled ? (
              <div className="anim-fade-in-fast">
                <section className="mb-large">
                  <InfoPanel
                    text={
                      <Text>
                        Transport before/after session has been <b>disabled</b> for this service.
                      </Text>
                    }
                  />
                </section>
              </div>
            ) : (
              <>
                {/* Main content panel */}
                <div className="anim-fade-in-fast">
                  {isTravelEnabled && (
                    <section className="mb-large">
                      <InfoPanel
                        text={
                          'If the customer requires transport to/from the session you can add a booking for transport here.'
                        }
                      />
                    </section>
                  )}

                  {/* Transport before section */}
                  <section className="p-medium flex-row bordered border-standard-gray rounded-big">
                    <div className="pv-large" style={{ width: '172px' }}>
                      <Text>Transport before</Text>
                    </div>
                    <div className="flex-1 flex-column">
                      {!hasTransportBefore && (
                        <div className="bg-quaternary p-large rounded-big">
                          <Text color="secondary">No transport before booking.</Text>
                        </div>
                      )}

                      {hasTransportBefore && (
                        <TransportBookingDetailsPanel
                          transportDetails={transportBeforeBooking}
                          transportType={'before'}
                          onEditDetails={this.onOpenEditModal}
                          onEditTravel={this.onOpenEditTransportModal}
                        />
                      )}

                      {!hasTransportBefore && (
                        <div className="mt-medium">
                          <HyperlinkButton
                            className={'mt-medium'}
                            onClick={() =>
                              this.onOpenEditModal({
                                editMode: 'add',
                                editType: 'before',
                                editDetails: transportBeforeBooking
                              })
                            }
                          >
                            <Icon type={'plus'} className="mr-x-small" />
                            Add transport before...
                          </HyperlinkButton>
                        </div>
                      )}
                    </div>
                  </section>

                  {/* Transport after section */}

                  <section className="mt-large p-medium flex-row bordered border-standard-gray rounded-big">
                    <div className="pv-large" style={{ width: '172px' }}>
                      <Text>Transport after</Text>
                    </div>
                    <div className="flex-1 flex-column">
                      {!hasTransportAfter && (
                        <div className="bg-quaternary p-large rounded-big">
                          <Text color="secondary">No transport after.</Text>
                        </div>
                      )}

                      {hasTransportAfter && (
                        <TransportBookingDetailsPanel
                          transportDetails={transportAfterBooking}
                          transportType={'after'}
                          onEditDetails={this.onOpenEditModal}
                          onEditTravel={this.onOpenEditTransportModal}
                        />
                      )}

                      {!hasTransportAfter && (
                        <HyperlinkButton
                          className={'mt-medium'}
                          onClick={() =>
                            this.onOpenEditModal({
                              editMode: 'add',
                              editType: 'after',
                              editDetails: transportAfterBooking
                            })
                          }
                        >
                          <Icon type={'plus'} className="mr-x-small" />
                          Add transport after...
                        </HyperlinkButton>
                      )}
                    </div>
                  </section>
                </div>

                {/* Modals */}
                <EditTransportBookingModal
                  isOpen={this.state.isEditModalOpen}
                  onClose={this.onCloseEditModal}
                  editType={this.state.editType}
                  editMode={this.state.editMode}
                  editDetails={this.state.editDetails}
                />

                <EditTransportBookingTransportModal
                  booking={this.state.editDetails}
                  attendanceType={
                    this.state.editType === 'before'
                      ? TransportAttendanceType.PRE_SESSION
                      : TransportAttendanceType.POST_SESSION
                  }
                  isOpen={this.state.isOpenTransportEdit}
                  onClose={this.onCloseEditTransportModal}
                />
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({});
const mapDispatch = (dispatch: IRootDispatch) => ({});

export default connect(
  mapState,
  mapDispatch
)(TransportOptionsPanel);
