import { timeZone } from 'interfaces/timezone-type';
import React, { Component } from 'react';
import { Avatar, Col, Row } from 'antd';
import { Text } from 'common-components/typography';
import { DocumentExpiryStatus } from 'utilities/enum-utils';
import { HyperlinkButton } from 'common-components/buttons';
import _ from 'lodash';
import moment from 'moment-timezone';

interface CustomerRowItemProps {
  documentData: any;
  goToCustomer: (userId, selectedTab) => void;
  serviceProviderTimezone: timeZone;
}

class CustomerRowItem extends Component<CustomerRowItemProps> {
  render() {
    const { documentData } = this.props;
    return (
      <div className="ph-large bordered-top pv-medium">
        <Row type={'flex'} align="middle" gutter={8}>
          <Col span={8}>
            <div className="flex-row align-center" title={documentData.firstName + ' ' + documentData.lastName}>
              <Avatar icon="user" className="mr-medium" src={documentData.attachmentUrl} />

              <div>
                <Text weight={'bold'}>
                  {documentData.firstName} {documentData.lastName}
                </Text>
                <br />
                <Text color={'secondary'}>{documentData.locality}</Text>
              </div>
            </div>
          </Col>
          <Col span={8}>
            <div className="text-align-left" title={documentData.documentName}>
              <Text color={'secondary'}>
                {documentData.expiryStatus === DocumentExpiryStatus.EXPIRED
                  ? 'Document expired:'
                  : 'Document expiring soon:'}
              </Text>
              <br />
              <Text>{documentData.documentName}</Text>
            </div>
          </Col>
          <Col span={4}>
            <div className="text-align-left">
              {documentData.expiryStatus === DocumentExpiryStatus.EXPIRED ? 'Expired ' : 'Expiring '}{' '}
              {moment.tz(documentData.expiryDate, this.props.serviceProviderTimezone).format('DD/MM/YYYY')}
            </div>
          </Col>
          <Col span={4}>
            <div className="flex-row justify-end align-center">
              <HyperlinkButton onClick={() => this.props.goToCustomer(documentData.userId, 'documents')}>
                Go to profile
              </HyperlinkButton>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

class CustomersGlanceSection extends Component<any, any> {
  render() {
    const { customersAtAGlance } = this.props;
    return (
      <div>
        <div className="mb-medium ph-large">
          <Text>Outstanding items for customers.</Text>
        </div>

        <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '500px' }}>
          {customersAtAGlance &&
          (customersAtAGlance.expiredDocuments.length > 0 || customersAtAGlance.expiringSoonDocuments.length > 0) ? (
            <>
              {_.map(customersAtAGlance.expiredDocuments, (expiredDocument) => (
                <CustomerRowItem
                  documentData={expiredDocument}
                  goToCustomer={this.props.goToCustomer}
                  serviceProviderTimezone={this.props.serviceProviderTimezone}
                />
              ))}
              {_.map(customersAtAGlance.expiringSoonDocuments, (expiringDocument) => (
                <CustomerRowItem
                  documentData={expiringDocument}
                  goToCustomer={this.props.goToCustomer}
                  serviceProviderTimezone={this.props.serviceProviderTimezone}
                />
              ))}
            </>
          ) : (
            // TODO: See with product team for the correct sentence and design
            <div className={'pv-medium pl-large'}>No customers with outstanding items.</div>
          )}
        </div>
      </div>
    );
  }
}

export default CustomersGlanceSection;
