import React, { Component } from 'react';
import { Form, Input, notification } from 'antd';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { SubTitle, Text } from 'common-components/typography';
import { ICustomer } from 'interfaces/customer-interfaces';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import CareLevelSelector from 'views/customers/details/components/CareLevelSelector';
import Utils from 'utilities/Utils';
import { AlertLevel, CareInformationType } from 'utilities/enum-utils';

const { TextArea } = Input;

interface ICreateEditSensitivityModalProps extends FormComponentProps {
  closeCreateEditSensitivityModal: () => void;
  isOpen: boolean;
  isNew: boolean;
  selectedCustomer: ICustomer;
  selectedSensitivity: any;
  doCreateCustomerCareInfo: typeof dispatch.customersStore.doCreateCustomerCareInfo;
  doUpdateCustomerCareInfo: typeof dispatch.customersStore.doUpdateCustomerCareInfo;
}

interface ICreateEditSensitivityModalState {
  isLoadingFilter: boolean;
  isActionModalOpen: boolean;
  selectedLevel: string;
  isLoading: boolean;
}

class CreateEditSensitivityModal extends Component<ICreateEditSensitivityModalProps, ICreateEditSensitivityModalState> {
  state = {
    isLoadingFilter: false,
    isActionModalOpen: false,
    selectedLevel: '',
    isLoading: false
  };

  private _closeWithActionModal = () => {
    const { form, selectedSensitivity, isNew } = this.props;
    const { selectedLevel } = this.state;
    let hasChanged = false;
    const formValues = form.getFieldsValue();

    // Check if it's an Add or Edit modal
    if (isNew) {
      if (selectedLevel || !Utils.isEmpty(formValues.sensitivityName) || !Utils.isEmpty(formValues.description)) {
        hasChanged = true;
      }
    } else if (
      formValues.sensitivityName !== selectedSensitivity.sensitivityName ||
      selectedLevel !== selectedSensitivity.alertLevel ||
      formValues.description !== selectedSensitivity.description
    ) {
      hasChanged = true;
    }

    if (hasChanged) {
      this.setState({ isActionModalOpen: true });
    } else {
      this._closeActionCreateModal();
    }
  };

  private _closeActionCreateModal = () => {
    this.setState({
      isLoadingFilter: false,
      isLoading: false,
      isActionModalOpen: false,
      selectedLevel: _.isEmpty(this.props.selectedSensitivity)
        ? ''
        : AlertLevel[this.props.selectedSensitivity.alertLevel]
    });
    this.props.closeCreateEditSensitivityModal();
  };

  private _changeLevel = (level) => {
    const { form } = this.props;
    form.setFieldsValue({ alertLevel: level });
    this.setState({ selectedLevel: level });
  };

  private _submitSensitivity = async () => {
    const {
      form,
      doCreateCustomerCareInfo,
      doUpdateCustomerCareInfo,
      selectedSensitivity,
      isNew,
      selectedCustomer
    } = this.props;
    const { selectedLevel } = this.state;

    this.setState({ isLoading: true });

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      try {
        const formValues = form.getFieldsValue();
        const payload = isNew
          ? {
              careInformationType: CareInformationType.GENERAL,
              alertLevel: AlertLevel[selectedLevel],
              name: formValues.sensitivityName,
              description: formValues.description,
              userId: selectedCustomer.userId
            }
          : {
              careInformationType: CareInformationType.GENERAL,
              alertLevel: AlertLevel[selectedLevel],
              careInformationId: selectedSensitivity.careInformationId,
              name: formValues.sensitivityName,
              description: formValues.description,
              userId: selectedCustomer.userId
            };
        isNew ? await doCreateCustomerCareInfo(payload) : doUpdateCustomerCareInfo(payload);
        notification.success({ message: `General information ${isNew ? 'added' : 'updated'} successfully.` });
        this._closeActionCreateModal();
      } catch (e) {
        notification.error({ message: 'Oops something went wrong! Please try again.' });
      }
    }

    this.setState({ isLoading: false });
  };

  componentDidUpdate(
    prevProps: Readonly<ICreateEditSensitivityModalProps>,
    prevState: Readonly<ICreateEditSensitivityModalState>,
    snapshot?: any
  ) {
    if (prevProps.selectedSensitivity !== this.props.selectedSensitivity) {
      this.setState({
        selectedLevel: this.props.selectedSensitivity ? AlertLevel[this.props.selectedSensitivity.alertLevel] : ''
      });
    }
  }

  render() {
    const { form, selectedSensitivity, isNew } = this.props;
    const { selectedLevel, isLoading } = this.state;
    const { getFieldDecorator } = form;

    return (
      <div>
        <ActionModal
          isOpen={this.state.isActionModalOpen}
          onClose={this._closeActionCreateModal}
          title={'Discard changes'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, proceeding will discard these changes.
          </Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeActionCreateModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size="large" onClick={this._closeActionCreateModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this._closeWithActionModal}
          title={
            <>
              {isNew ? 'Add' : 'Edit'} a <b>General information</b>
            </>
          }
          showCloseButton={true}
          verticalAlignment={'center'}
          width={'medium'}
        >
          {isNew && (
            <div className="mb-large">
              <Text>
                Search and select a <b>general care information</b> from the list provided.
              </Text>
            </div>
          )}
          <div className="mb-large">
            <SubTitle>General information</SubTitle>
            <Form.Item className="mb-large">
              {getFieldDecorator('sensitivityName', {
                rules: [{ required: true, message: 'General care information name required' }],
                initialValue: selectedSensitivity ? selectedSensitivity.name : null
              })(<Input placeholder="Enter a genral care information name" style={{ width: '400px' }} />)}
            </Form.Item>
          </div>
          <div className="mb-large">
            <div className="flex-row align-center mb-x-small">
              <SubTitle>ALERT LEVEL</SubTitle>
              <Text className="ml-x-small" size="regular">
                (Select one)
              </Text>
            </div>
            <CareLevelSelector selectedLevel={selectedLevel} changeLevel={this._changeLevel} />
            <Form.Item className="p-none">
              {getFieldDecorator('alertLevel', {
                rules: [{ required: true, message: 'Choose one alert level' }],
                initialValue: selectedLevel
              })(<Input type="hidden" />)}
            </Form.Item>
          </div>
          <div className="mb-x-large">
            <div className="flex-row align-center mb-x-small">
              <SubTitle>Description</SubTitle>
              <Text className="ml-x-small" size="regular">
                (Optional)
              </Text>
            </div>
            <Form.Item className="mb-large">
              {getFieldDecorator('description', {
                rules: [],
                initialValue: selectedSensitivity ? selectedSensitivity.description : null
              })(
                <TextArea
                  placeholder={
                    'Describe this general care information, or leave a remark about this general care information here.'
                  }
                />
              )}
            </Form.Item>
          </div>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._closeWithActionModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._submitSensitivity} loading={isLoading}>
              {isNew ? 'Add' : 'Save'} General care information
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateCustomerCareInfo: dispatch.customersStore.doCreateCustomerCareInfo,
  doUpdateCustomerCareInfo: dispatch.customersStore.doUpdateCustomerCareInfo
});

export default connect(
  null,
  mapDispatch
)(Form.create<ICreateEditSensitivityModalProps>()(CreateEditSensitivityModal));
