import React, { Component } from 'react';
import { Col, Form, Row, Icon } from 'antd';
import { Text } from 'common-components/typography';
import moment from 'moment-timezone';
import { FormComponentProps } from 'antd/es/form';
import { Select } from 'antd';
import _ from 'lodash';
import TimeInput from 'common-components/time-input/TimeInput';
interface IWorkerDetailTimeAvailabilityItemProps extends FormComponentProps {
  day: any;
  data: any;
  onChangeAvailableTime: (value) => void;
  avblTimezone: string;
}

const { Option } = Select;
class WorkerDetailTimeAvailabilityItem extends Component<IWorkerDetailTimeAvailabilityItemProps, any> {
  private _onChangeAvailableType = (value) => {
    const { data, onChangeAvailableTime, avblTimezone } = this.props;
    const newData = { ...data };
    if (value === 'unavailable') {
      newData.timeRange = [];
      newData.isAvailableAllDay = false;
    } else if (value === 'available-all-day') {
      newData.timeRange = [];
      newData.isAvailableAllDay = true;
    } else {
      newData.isAvailableAllDay = false;
      const localStartDateTime = moment(9, 'hh').set('weekday', newData.number);
      const localEndDateTime = moment(17, 'hh').set('weekday', newData.number);
      newData.timeRange = [
        {
          localStartDateTime: localStartDateTime,
          localEndDateTime: localEndDateTime,
          startDateTime: moment(localStartDateTime).tz(avblTimezone, true),
          endDateTime: moment(localEndDateTime).tz(avblTimezone, true)
        }
      ];
    }

    onChangeAvailableTime(newData);
  };

  private _onChangeAvailableTime = (value, type, index) => {
    const { data, onChangeAvailableTime, avblTimezone } = this.props;
    const newData = { ...data };
    const momentTime = moment(value).tz(avblTimezone, true);

    if (type === 'startTime') {
      const endDateTime = moment(newData.timeRange[index].endDateTime);

      if (momentTime.isSameOrAfter(endDateTime)) {
        newData.timeRange[index].error = 'earlier';
      } else if (this._validateOverlappingTimeBlock(momentTime, endDateTime, newData.timeRange, index)) {
        newData.timeRange[index].error = 'overlaps';
      } else {
        newData.timeRange[index].error = null;
      }

      newData.timeRange[index].startDateTime = momentTime;
    } else {
      const startDateTime = moment(newData.timeRange[index].startDateTime);

      const timeString = momentTime.format('hh:mm a');
      const endOfDayTime = moment(momentTime)
        .set('weekday', newData.number)
        .endOf('days');
      const endOfDayTimeString = endOfDayTime.add(1, 'seconds').format('hh:mm a');

      if (momentTime.isSameOrBefore(startDateTime) && !(timeString === endOfDayTimeString)) {
        newData.timeRange[index].error = 'earlier';
      } else if (this._validateOverlappingTimeBlock(startDateTime, momentTime, newData.timeRange, index)) {
        newData.timeRange[index].error = 'overlaps';
      } else {
        newData.timeRange[index].error = null;
      }

      if (timeString === endOfDayTimeString) {
        newData.timeRange[index].endDateTime = endOfDayTime.add(1, 'seconds');
      } else {
        newData.timeRange[index].endDateTime = momentTime;
      }

      newData.timeRange[index].endDateTime = momentTime;
    }

    onChangeAvailableTime(newData);
  };

  private _validateOverlappingTimeBlock = (startTime, endTime, timeRanges, currentIndex) => {
    const timeRangesExcludeCurrentIdx = _.filter(timeRanges, (_, index) => {
      return index !== currentIndex;
    });

    const conflictingTime = _.find(timeRangesExcludeCurrentIdx, (timeRange) => {
      const existingStartTime = moment(timeRange.startDateTime);
      const existingEndTime = moment(timeRange.endDateTime);

      const startTimeInBetween = moment(startTime).isBetween(existingStartTime, existingEndTime);
      const endTimeInBetween = moment(endTime).isBetween(existingStartTime, existingEndTime);

      const startTimeOverlap =
        startTimeInBetween || (moment(startTime).isSame(existingStartTime) && startTimeInBetween);
      const endTimeOverlap = endTimeInBetween || (moment(endTime).isSame(existingEndTime) && endTimeInBetween);

      const existingStartTimeInBetween = moment(existingStartTime).isBetween(startTime, endTime);
      const existingEndTimeInBetween = moment(existingEndTime).isBetween(startTime, endTime);
      const existingStartTimeOverlap =
        existingStartTimeInBetween || (existingEndTimeInBetween && moment(existingStartTime).isSame(startTime));
      const existingEndTimeOverlap =
        existingEndTimeInBetween || (existingEndTimeInBetween && moment(existingEndTime).isSame(endTime));

      const duplicateTime = moment(existingStartTime).isSame(startTime) && moment(existingEndTime).isSame(endTime);

      return startTimeOverlap || endTimeOverlap || existingStartTimeOverlap || existingEndTimeOverlap || duplicateTime;
    });

    return conflictingTime;
  };

  private _addNewAvailableTime = () => {
    const { data, onChangeAvailableTime } = this.props;
    const newData = { ...data };
    const newTimeRange = [...data.timeRange];
    const localTimeZone = moment.tz.guess();

    const sortedTimeRange = data.timeRange ? data.timeRange.sort((a, b) => a.endDateTime - b.endDateTime) : [];
    const currentLastTime = sortedTimeRange[sortedTimeRange.length - 1];

    const endDayTime = moment(currentLastTime.endDateTime)
      .set('weekday', newData.number)
      .endOf('days');

    if (endDayTime.diff(moment(currentLastTime.endDateTime)) > 0) {
      let newStartTime = moment(currentLastTime.endDateTime).add(1, 'hours');
      let newEndTime = moment(currentLastTime.endDateTime).add(2, 'hours');
      let error = null;

      if (endDayTime.diff(newStartTime) <= 0) {
        newStartTime = endDayTime.add(1, 'seconds');
      }

      if (endDayTime.diff(newEndTime) <= 0) {
        newEndTime = endDayTime.add(1, 'seconds');
      }

      if (newStartTime.diff(newEndTime) === 0) {
        error = 'overlaps';
      }

      const newAvailableTime = {
        startDateTime: newStartTime,
        endDateTime: newEndTime,
        localStartDateTime: moment(newStartTime).tz(localTimeZone, true),
        localEndDateTime: moment(newEndTime).tz(localTimeZone, true),
        error: error
      };

      newTimeRange.push(newAvailableTime);
      newData.timeRange = newTimeRange;
      onChangeAvailableTime(newData);
    } else if (endDayTime.add(1, 'seconds').diff(moment(currentLastTime.endDateTime)) === 0) {
      const newStartDate = endDayTime.add(1, 'seconds');
      const newEndDate = endDayTime.add(1, 'seconds');
      const newAvailableTime = {
        startDateTime: newStartDate,
        endDateTime: newEndDate,
        localStartDateTime: moment(newStartDate).tz(localTimeZone, true),
        localEndDateTime: moment(newEndDate).tz(localTimeZone, true),
        error: 'overlaps'
      };

      newTimeRange.push(newAvailableTime);
      newData.timeRange = newTimeRange;
      onChangeAvailableTime(newData);
    }
  };

  private _removeAvailableTime = (index) => {
    const { data, onChangeAvailableTime } = this.props;
    const newData = { ...data };

    const newTimeRange = _.filter(data.timeRange, (time, idx) => idx !== index);
    const currentErrorIdxs = _.chain(newTimeRange)
      .map((timeRange, index) => ({ ...timeRange, index }))
      .filter((timeRange) => timeRange.error)
      .map((timeRange) => timeRange.index)
      .value();

    _.forEach(currentErrorIdxs, (idx) => {
      const error = this._validateOverlappingTimeBlock(
        newTimeRange[idx].startDateTime,
        newTimeRange[idx].endDateTime,
        newTimeRange,
        idx
      );

      const earlierError = newTimeRange[idx].error === 'earlier';

      if (!earlierError && !error) {
        newTimeRange[idx].error = null;
      }
    });

    newData.timeRange = newTimeRange;

    onChangeAvailableTime(newData);
  };

  private _renderValidateError = (error) => {
    switch (error) {
      case 'overlaps':
        return 'This time frame overlaps with a previous entry.';
      case 'earlier':
        return 'End time is earlier than start time.';
      default:
        return '';
    }
  };

  private _errorClassName = (type, error) => {
    if (error) {
      switch (error) {
        case 'earlier':
          return type === 'startTime' ? '' : 'bordered border-red rounded';
        default:
          return 'bordered border-red rounded';
      }
    }

    return '';
  };

  render() {
    let { day, data } = this.props;

    return (
      <Row
        type="flex"
        align={data.timeRange.length > 1 ? 'top' : 'middle'}
        gutter={24}
        className="bordered-bottom pv-medium"
      >
        <Col span={4}>
          <Text weight="bold">{day}</Text>
        </Col>
        <Col span={8}>
          <Select
            className="width-full"
            size="large"
            defaultValue="unavailable"
            suffixIcon={<Icon type="caret-down" className="ml-small mr-small text-size-small text-color-secondary" />}
            onChange={this._onChangeAvailableType}
            value={
              data.isAvailableAllDay
                ? 'available-all-day'
                : data.timeRange.length > 0
                ? 'specify-availability'
                : 'unavailable'
            }
          >
            <Option value="specify-availability">
              <Text>Specific hours</Text>
            </Option>
            <Option value="unavailable">
              <Text className="text-color-orange-dark">Unavailable</Text>
            </Option>
            <Option value="available-all-day">
              <Text className="text-color-green">Available (24 hours)</Text>
            </Option>
          </Select>
        </Col>
        {!data.isAvailableAllDay && (
          <Col span={12}>
            {data.timeRange.length > 0 &&
              _.map(data.timeRange, (time, index) => (
                <div className={data.timeRange.length > 1 ? 'mb-small' : ''}>
                  <div className="flex align-center">
                    <TimeInput
                      size="large"
                      value={time.localStartDateTime}
                      onChange={(time) => {
                        this._onChangeAvailableTime(time, 'startTime', index);
                      }}
                      className={this._errorClassName('startTime', time.error)}
                    />
                    <Text className="text-color-secondary mh-small">to</Text>
                    <TimeInput
                      size="large"
                      value={time.localEndDateTime}
                      onChange={(time) => {
                        this._onChangeAvailableTime(time, 'endTime', index);
                      }}
                      className={this._errorClassName('endTime', time.error)}
                    />
                    <div className="flex ml-small">
                      <Icon
                        className="text-color-red text-size-regular cursor-pointer"
                        type="minus-circle"
                        onClick={() => {
                          this._removeAvailableTime(index);
                        }}
                      />
                      {index + 1 === data.timeRange.length && (
                        <Icon
                          className={`text-color-blue-action text-size-regular cursor-pointer ml-large`}
                          type="plus-circle"
                          onClick={this._addNewAvailableTime}
                        />
                      )}
                    </div>
                  </div>
                  {time.error && (
                    <div className="mt-x-small ml-small">
                      <Icon type="exclamation-circle" theme="filled" className="text-size-regular text-color-red" />
                      <Text className="text-color-red ml-x-small">{this._renderValidateError(time.error)}</Text>
                    </div>
                  )}
                </div>
              ))}
          </Col>
        )}
      </Row>
    );
  }
}

export default Form.create<IWorkerDetailTimeAvailabilityItemProps>()(WorkerDetailTimeAvailabilityItem);
