import React, { PureComponent } from 'react';
import { FieldLabel, Text } from 'common-components/typography';
import ActionModal from 'common-components/modal/ActionModal';
import { IconButton } from 'common-components/buttons';
import { Avatar, Divider, notification, Tabs } from 'antd';
import BookingOverviewPanel from 'views/group-services/manage-booking-modal/panels/overview/BookingOverviewPanel';
import TransportOptionsPanel from 'views/group-services/manage-booking-modal/panels/transport/TransportOptionsPanel';
import { BillingsPanel } from 'views/group-services/manage-booking-modal/panels/billings/BillingsPanel';
import InstructionsPanel from 'views/group-services/manage-booking-modal/panels/instructions/InstructionsPanel';
import NotesPanel from 'views/group-services/manage-booking-modal/panels/notes/NotesPanel';
import ActivityLogPanel from 'views/group-services/manage-booking-modal/panels/activity-log/ActivityLogPanel';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import * as H from 'history';
import CustomerRatioPanel from 'views/group-services/manage-booking-modal/panels/customer-ratio/CustomerRatioPanel';
import _ from 'lodash';

const { TabPane } = Tabs;

//region Interfaces
interface IGroupBookingModalProps {
  isOpen: boolean;
  history: H.History;
  selectedBookingId: string;
  initialSelectedTab?: { key: string; name: string } | null;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem; // booking: IGroupBookingDetails;
  doFetchGroupBookingOverview: typeof dispatch.groupBookingsStore.doFetchGroupBookingOverview; // booking: IGroupBookingDetails;
  doDeleteGroupBookingSingleBillingItem: typeof dispatch.groupBookingsStore.doDeleteGroupBookingSingleBillingItem;
  doFetchGroupBookingCustomerRatio: typeof dispatch.groupBookingsStore.doFetchGroupBookingCustomerRatio;
  doFetchGroupBookingTransportBookings: typeof dispatch.groupBookingsStore.doFetchGroupBookingTransportBookings;
  doFetchGroupBookingInstructions: typeof dispatch.groupBookingsStore.doFetchGroupBookingInstructions;
  doFetchGroupBookingNotes: typeof dispatch.groupBookingsStore.doFetchGroupBookingNotes;
  doFetchGroupBookingActivityLogs: typeof dispatch.groupBookingsStore.doFetchGroupBookingActivityLogs;

  onClose: any;
}

interface IGroupBookingModalState {
  currentTabKey: string;
  currentTabName: string;
  isLoading: boolean;
  notePage: number;
  notePageSize: number;
  notePageTimestamp: Date;
}
//endregion

class GroupBookingModal extends PureComponent<IGroupBookingModalProps, IGroupBookingModalState> {
  state = {
    isLoading: true,
    currentTabKey: 'overview',
    currentTabName: 'Overview',
    notePage: 1,
    notePageSize: 20,
    notePageTimestamp: new Date()
  };

  // Navigate to content
  onNavigate = async ({ key, name }) => {
    const {
      doFetchGroupBookingOverview,
      doFetchGroupBookingCustomerRatio,
      doFetchGroupBookingTransportBookings,
      doFetchGroupBookingInstructions,
      selectedBookingId,
      doFetchGroupBookingActivityLogs,
      doFetchGroupBookingNotes
    } = this.props;
    this.setState({
      isLoading: true
    });
    try {
      switch (key) {
        case 'overview':
          await doFetchGroupBookingOverview({
            bookingId: selectedBookingId
          });
          break;
        case 'customer-ratio':
          await doFetchGroupBookingCustomerRatio({
            bookingId: selectedBookingId
          });
          break;
        case 'transport-options':
          await doFetchGroupBookingTransportBookings(selectedBookingId);
          break;
        case 'billings':
          await doFetchGroupBookingOverview({
            bookingId: selectedBookingId
          });
          await doFetchGroupBookingTransportBookings(selectedBookingId);
          break;
        case 'instructions':
          await doFetchGroupBookingInstructions({ bookingId: selectedBookingId });
          break;
        case 'notes':
          this.setState({
            notePage: 1,
            notePageSize: 20,
            notePageTimestamp: new Date()
          });
          await doFetchGroupBookingNotes({
            bookingId: selectedBookingId,
            page: this.state.notePage,
            pageSize: this.state.notePageSize,
            pageTimestamp: this.state.notePageTimestamp
          });
          break;
        case 'activity-log':
          await doFetchGroupBookingActivityLogs({ bookingId: selectedBookingId });
          break;
      }
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
    this.setState({ currentTabKey: key, currentTabName: name, isLoading: false });
  };

  resetModalState = () => {
    this.setState({ isLoading: true, currentTabKey: 'overview', currentTabName: 'Overview' });
  };

  onTriggerClose = () => {
    const { onClose } = this.props;
    this.resetModalState();
    onClose();
  };

  private _doFetchMoreNotes = async () => {
    await this.props.doFetchGroupBookingNotes({
      bookingId: this.props.selectedBookingId,
      page: this.state.notePage + 1,
      pageSize: this.state.notePageSize,
      pageTimestamp: this.state.notePageTimestamp
    });
    this.setState({ notePage: this.state.notePage + 1 });
  };

  private _doLoadFreshNotes = async () => {
    this.setState({ notePage: 1, notePageTimestamp: new Date() });
    await this.props.doFetchGroupBookingNotes({
      bookingId: this.props.selectedBookingId,
      page: this.state.notePage,
      pageSize: this.state.notePageSize,
      pageTimestamp: this.state.notePageTimestamp
    });
  };

  async componentDidUpdate(prevProps: Readonly<IGroupBookingModalProps>, prevState: Readonly<IGroupBookingModalState>) {
    if (!prevProps.isOpen && this.props.isOpen && this.props.selectedBookingId) {
      try {
        this.setState({ isLoading: true });
        const { doFetchGroupBookingOverview, selectedBookingId } = this.props;
        await doFetchGroupBookingOverview({
          bookingId: selectedBookingId
        });
        this.setState({ isLoading: false });
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong, please try again.' });
        console.log('e', e);
        this.onTriggerClose();
      }
    }
    if (prevProps.initialSelectedTab !== this.props.initialSelectedTab) {
      const selectedTab: { key: string; name: string } = this.props.initialSelectedTab
        ? this.props.initialSelectedTab
        : { key: 'overview', name: 'Overview' };
      this.onNavigate(selectedTab);
    }
  }

  render() {
    const { isOpen, selectedGroupBookingItem } = this.props;
    const { isLoading } = this.state;

    // TODO @ JIR - Adjust the min height for the booking modal
    const minHeight = '75vh';

    const defaultModalProps = {
      canCloseOutside: true,
      hasModalPadding: false,
      showHeader: false,
      width: 'x3-large',
      minHeight: isLoading ? '' : minHeight
    };

    // TODO : Need to tweak this a bit for initial loading of screens.
    if (_.isEmpty(selectedGroupBookingItem)) {
      return <></>;
    }

    return (
      <ActionModal isOpen={isOpen} onClose={this.onTriggerClose} {...defaultModalProps} verticalAlignment={'center'}>
        <div className="flex-column bg-black">
          <div className="rounded flex-row">
            {/* Navigation panel */}
            <section
              className="bg-quaternary rounded-left bordered-right border-standard-gray"
              style={{ width: '192px', minHeight }}
            >
              {/* Avatar section */}
              <div className="p-medium flex-row line-height-100 align-center">
                <div>
                  <Avatar icon="user" className="avatar-medium" src={selectedGroupBookingItem.attachmentUrl} />
                </div>
                <div className="pl-small">
                  <Text
                    lineHeight={120}
                  >{`${selectedGroupBookingItem.firstName} ${selectedGroupBookingItem.lastName}`}</Text>
                </div>
              </div>

              {/* Navigation section */}
              <div>
                <div className="pv-small ph-medium">
                  <FieldLabel text={'booking details'} />
                </div>

                {/* Navigations */}
                <ModalNavButton
                  onNavigate={this.onNavigate}
                  currentTab={this.state.currentTabKey}
                  targetTab="overview"
                  text="Overview"
                />

                <ModalNavButton
                  onNavigate={this.onNavigate}
                  currentTab={this.state.currentTabKey}
                  targetTab="transport-options"
                  text="Transport options"
                />

                <ModalNavButton
                  onNavigate={this.onNavigate}
                  currentTab={this.state.currentTabKey}
                  targetTab="customer-ratio"
                  text="Customer ratio"
                />

                <ModalNavButton
                  onNavigate={this.onNavigate}
                  currentTab={this.state.currentTabKey}
                  targetTab="billings"
                  text="Billings"
                />

                <ModalNavButton
                  onNavigate={this.onNavigate}
                  currentTab={this.state.currentTabKey}
                  targetTab="instructions"
                  text="Instructions"
                />

                <ModalNavButton
                  onNavigate={this.onNavigate}
                  currentTab={this.state.currentTabKey}
                  targetTab="notes"
                  text="Notes"
                />

                <ModalNavButton
                  onNavigate={this.onNavigate}
                  currentTab={this.state.currentTabKey}
                  targetTab="activity-log"
                  text="Activity log"
                />
              </div>
            </section>

            {/* Content panel */}
            <section className="bg-white flex-1 rounded-right">
              <div className="pt-large ph-large pb-x2-large">
                {/* Header section */}
                <div className="flex-row align-center justify-between">
                  <Text size="x2-large" weight="bold">
                    {this.state.currentTabName}
                  </Text>
                  <div>
                    {/*<IconButton*/}
                    {/*  icon={'left'}*/}
                    {/*  onClick={() => console.log('not implemented yet')}*/}
                    {/*  iconColor={'blue-action'}*/}
                    {/*  color={'white'}*/}
                    {/*  bordered={true}*/}
                    {/*/>*/}

                    {/*<IconButton*/}
                    {/*  icon={'right'}*/}
                    {/*  onClick={() => console.log('not implemented yet')}*/}
                    {/*  iconColor={'blue-action'}*/}
                    {/*  color={'white'}*/}
                    {/*  className="ml-small"*/}
                    {/*  bordered={true}*/}
                    {/*/>*/}
                    <IconButton
                      icon={'close'}
                      onClick={this.onTriggerClose}
                      iconColor={'black'}
                      color={'white'}
                      className="ml-large"
                    />
                  </div>
                </div>

                <Divider className="divider-large" />

                <Tabs renderTabBar={() => <div />} activeKey={this.state.currentTabKey} animated={false}>
                  <TabPane tab={'overview'} key={'overview'}>
                    <BookingOverviewPanel
                      onNavigate={this.onNavigate}
                      history={this.props.history}
                      isLoading={this.state.isLoading}
                    />
                  </TabPane>

                  <TabPane tab={'customer-ratio'} key={'customer-ratio'}>
                    <CustomerRatioPanel booking={selectedGroupBookingItem} isLoading={this.state.isLoading} />
                  </TabPane>

                  <TabPane tab={'transport-options'} key={'transport-options'}>
                    <TransportOptionsPanel booking={selectedGroupBookingItem} isLoading={this.state.isLoading} />
                  </TabPane>

                  <TabPane tab={'billings'} key={'billings'}>
                    <BillingsPanel
                      booking={selectedGroupBookingItem}
                      doDeleteGroupBookingSingleBillingItem={this.props.doDeleteGroupBookingSingleBillingItem}
                      isLoading={this.state.isLoading}
                    />
                  </TabPane>

                  <TabPane tab={'instructions'} key={'instructions'}>
                    <InstructionsPanel isLoading={this.state.isLoading} />
                  </TabPane>

                  <TabPane tab={'notes'} key={'notes'}>
                    <NotesPanel
                      booking={selectedGroupBookingItem}
                      doFetchMoreNotes={this._doFetchMoreNotes}
                      doLoadFreshNotes={this._doLoadFreshNotes}
                      page={this.state.notePage}
                      pageSize={this.state.notePageSize}
                      pageTimestamp={this.state.notePageTimestamp}
                      isLoading={this.state.isLoading}
                    />
                  </TabPane>

                  <TabPane tab={'activity-log'} key={'activity-log'}>
                    <ActivityLogPanel booking={selectedGroupBookingItem} isLoading={this.state.isLoading} />
                  </TabPane>
                </Tabs>
              </div>
            </section>
          </div>
        </div>
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupBookingOverview: dispatch.groupBookingsStore.doFetchGroupBookingOverview,
  doDeleteGroupBookingSingleBillingItem: dispatch.groupBookingsStore.doDeleteGroupBookingSingleBillingItem,
  doFetchGroupBookingCustomerRatio: dispatch.groupBookingsStore.doFetchGroupBookingCustomerRatio,
  doFetchGroupBookingTransportBookings: dispatch.groupBookingsStore.doFetchGroupBookingTransportBookings,
  doFetchGroupBookingInstructions: dispatch.groupBookingsStore.doFetchGroupBookingInstructions,
  doFetchGroupBookingNotes: dispatch.groupBookingsStore.doFetchGroupBookingNotes,
  doFetchGroupBookingActivityLogs: dispatch.groupBookingsStore.doFetchGroupBookingActivityLogs
});

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem
});

export default connect(
  mapState,
  mapDispatch
)(GroupBookingModal);

// Local components
function ModalNavButton({
  text,
  targetTab,
  currentTab,
  onNavigate
}: {
  // isSelected: boolean;
  targetTab: string;
  currentTab: string;
  text: string;
  onNavigate?: any;
}) {
  const isSelected = targetTab === currentTab;

  return (
    <div
      className={`select-none cursor-pointer flex-row line-height-100 ${
        isSelected ? 'bg-secondary hover-bg-secondary' : 'hover-bg-tertiary'
      }`}
      onClick={() => onNavigate({ key: targetTab, name: text })}
    >
      {/* Sideline */}
      <div className={isSelected ? `bg-blue-lighter` : 'hover-bg-lighter'} style={{ width: '4px' }} />
      <div className="pv-small" style={{ paddingLeft: '12px', paddingRight: '12px' }}>
        <Text lineHeight={100}>{text}</Text>
      </div>
    </div>
  );
}
