import React, { Component } from 'react';
import moment from 'moment-timezone';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import { Form } from 'antd';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { BookingSVG, SuccessSVG } from 'assets/UndrawSVG';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import DatePicker from 'react-datepicker';
import TimeInput from 'common-components/time-input/TimeInput';
import CommonUtils from 'utilities/common-utils';
import { TimezoneIndicator } from 'common-components/timezone';
import { timeZone } from 'interfaces/timezone-type';

interface IBookingCheckinActionModelProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  selectedBookingItem: typeof state.bookingsStore.selectedBookingItem;
  doCheckinBooking: typeof dispatch.bookingsStore.doCheckinBooking;
  displayTimezone: timeZone;
}

interface CheckInActionModalState {
  isLoading: boolean;
  isSuccess: boolean;
  title: string;
}

class BookingCheckinActionModel extends Component<IBookingCheckinActionModelProps, CheckInActionModalState> {
  state = {
    isLoading: false,
    isSuccess: false,
    title: 'Start Shift'
  };
  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ title: 'Start Shift' });
    if (this.state.isLoading) {
    } else {
      onClose();
    }

    // Do any manual clean up.
  };

  _onSubmitCheckin = () => {
    const { form, doCheckinBooking, displayTimezone } = this.props;
    form.validateFields(async (err, value) => {
      if (!err) {
        this.setState({ isLoading: true });

        try {
          await doCheckinBooking({
            checkInTime: moment.tz(moment(value.Date).format('YYYY-MM-DD HH:mm'), displayTimezone).toISOString()
          });
          // await asyncDelay(4000);
          this.setState({ isLoading: false, isSuccess: true, title: 'Booking successfully started' });
        } catch (e) {
          this.setState({ isLoading: false });
        }
      }
    });
  };

  private _onChangeDate = (value) => {
    const { form } = this.props;

    const newTime = moment(form.getFieldValue('Time'));
    const newDate = moment(value);

    newDate.set('hour', newTime.hours());
    newDate.set('minutes', newTime.minutes());

    setTimeout(() => {
      form.setFieldsValue({
        Time: newDate.toDate(),
        Date: newDate.toDate()
      });
    }, 0);
  };

  private _roundTime = (value) => {
    const { form } = this.props;
    const roundedTime = CommonUtils.formatCeilingDateTime(value);
    setTimeout(() => {
      form.setFieldsValue({
        Time: roundedTime.toDate(),
        Date: roundedTime.toDate()
      });
    }, 0);
  };

  componentDidMount() {
    this.setState({
      isSuccess: false,
      isLoading: false
    });
  }

  render() {
    const { isOpen, form, selectedBookingItem, displayTimezone } = this.props;
    const { getFieldDecorator } = form;

    const { startDateTime } = selectedBookingItem;
    const roundedDate = moment(moment.tz(startDateTime, displayTimezone).format('YYYY-MM-DD HH:mm'));

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this.onCloseModal}
        width="medium"
        verticalAlignment="highest"
      >
        {!this.state.isLoading && !this.state.isSuccess && (
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>
                You are starting this booking on behalf of{' '}
                <span className="text-weight-bold">
                  {selectedBookingItem.workerFirstName + ' ' + selectedBookingItem.workerLastName}
                </span>{' '}
                with the following date {'&'} time.
              </Paragraph>
            </div>

            <div className="flex-row align-center justify-center mb-medium">
              {getFieldDecorator('Date', {
                initialValue: roundedDate.toDate(),
                valuePropName: 'selected',
                rules: [{ required: true, message: 'Please enter a check-in date!' }]
              })(
                // <DatePicker size="large" format={'ddd, D MMM YYYY'} />
                // @ts-ignore
                <DatePicker
                  className="gh-datepicker rounded"
                  calendarClassName="gh-datepicker-calendar"
                  dateFormat="d/M/yyyy"
                  onChange={this._onChangeDate}
                />
              )}

              <Text className="mh-medium">at</Text>
              {getFieldDecorator('Time', { initialValue: roundedDate })(
                <TimeInput size="large" onChange={this._roundTime} />
              )}
            </div>

            <div className="text-align-center">
              <Text size="small" color="secondary">
                current timezone{' '}
                <span className="ml-small">
                  <TimezoneIndicator hasIcon={false} showTzName={false} bordered={false} timezone={displayTimezone} />
                </span>
              </Text>
            </div>
          </div>
        )}
        {this.state.isLoading && <SpinningLoader size={100} message={'Loading'} />}
        {!this.state.isLoading && this.state.isSuccess && (
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>
                You have successfully started this booking on behalf of{' '}
                <span className="text-weight-bold">
                  {selectedBookingItem.workerFirstName + ' ' + selectedBookingItem.workerLastName}
                </span>{' '}
                with the following date {'&'} time.
              </Paragraph>
            </div>

            <div className="flex-row align-center justify-left">
              <Paragraph size={'large'} weight="bold">
                {moment
                  .tz(selectedBookingItem.workerCheckedInDateTime, displayTimezone)
                  .format('dddd DD MMMM YYYY h:mm A')}
                <TimezoneIndicator showTzName={false} timezone={displayTimezone} />
              </Paragraph>
            </div>
          </div>
        )}

        {!this.state.isSuccess && (
          <ActionModalFooter>
            <GhostButton size="large" onClick={this.onCloseModal} className="mr-medium" disabled={this.state.isLoading}>
              Not now
            </GhostButton>
            <PrimaryButton size="large" onClick={this._onSubmitCheckin} loading={this.state.isLoading}>
              Start Shift
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {this.state.isSuccess && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({ selectedBookingItem: state.bookingsStore.selectedBookingItem });

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCheckinBooking: dispatch.bookingsStore.doCheckinBooking
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IBookingCheckinActionModelProps>()(BookingCheckinActionModel));
