import React, { Component } from 'react';
import { Col, Row, Avatar } from 'antd';
import { Paragraph, Text, Title } from 'common-components/typography';
import { connect } from 'react-redux';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';

import { IAddCustomerGuardianDetailWizard, ICustomer } from 'src/interfaces/customer-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';

import AddLegalGuardianModal from 'views/customers/listing/components/AddLegalGuardianModal';
import ActionModal from 'common-components/modal/ActionModal';

interface IAddLegalGuardianStepSelectGuardianProps {
  onNextStep: (stepData?: any) => void;
  setAddLegalGuardianDetailWizard: typeof dispatch.customersStore.setAddLegalGuardianDetailWizard;
  setSelectedCustomer?: typeof dispatch.customersStore.setSelectedCustomer;
  addLegalGuardianDetailWizard: typeof state.customersStore.addLegalGuardianDetailWizard;
  selectedCustomer: ICustomer;
}

interface IAddLegalGuardianStepSelectGuardianState {
  guardians: IAddCustomerGuardianDetailWizard[];
  openAddLegalGuardianModal: boolean;
  legalGuardianRequiredError: boolean;
  openRemoveGuardianModal: boolean;
  legalGuardianToRemoveEmail: string;
}

class AddLegalGuardianStepSelectGuardian extends Component<
  IAddLegalGuardianStepSelectGuardianProps,
  IAddLegalGuardianStepSelectGuardianState
> {
  state = {
    guardians: this.props.addLegalGuardianDetailWizard,
    openAddLegalGuardianModal: false,
    legalGuardianRequiredError: false,
    openRemoveGuardianModal: false,
    legalGuardianToRemoveEmail: null
  };

  private _validateBeforeNextStep = async () => {
    const { onNextStep, setAddLegalGuardianDetailWizard } = this.props;
    const { guardians } = this.state;

    if (guardians.length === 0) {
      this.setState({ legalGuardianRequiredError: true });
    } else {
      setAddLegalGuardianDetailWizard(guardians);
      onNextStep();
    }
  };

  private _openRemoveGuardianModal = (email) => {
    this.setState({ openRemoveGuardianModal: true, legalGuardianToRemoveEmail: email });
  };

  private _closeRemoveGuardianModal = () => {
    this.setState({ openRemoveGuardianModal: false, legalGuardianToRemoveEmail: null });
  };

  private _onRemoveGuardian = async () => {
    const { guardians } = this.state;

    const uppdatedGuardians = _.filter(guardians, (guardian) => {
      return guardian.email !== this.state.legalGuardianToRemoveEmail;
    });

    this.props.setAddLegalGuardianDetailWizard(uppdatedGuardians);
    this.setState({ guardians: uppdatedGuardians, openRemoveGuardianModal: false });
  };

  private _openAddLegalGuardianModal = async () => {
    this.props.setSelectedCustomer(this.props.selectedCustomer);
    this.setState({ openAddLegalGuardianModal: true, legalGuardianRequiredError: false });
  };
  private _closeAddLegalGuardianModal = async () => {
    this.setState({ openAddLegalGuardianModal: false });
  };

  render() {
    const { selectedCustomer } = this.props;
    const { guardians, openAddLegalGuardianModal, openRemoveGuardianModal } = this.state;
    const { _closeRemoveGuardianModal, _onRemoveGuardian } = this;

    return (
      <>
        {openRemoveGuardianModal && (
          <ActionModal
            isOpen={openRemoveGuardianModal}
            title="Remove legal guardian"
            width="large"
            onClose={_closeRemoveGuardianModal}
            verticalAlignment="highest"
          >
            <>
              <div>
                <Paragraph>Are you sure you want to remove this user as a legal guardian ? </Paragraph>
              </div>

              <div className="pd-large pb- width-full" style={{ position: 'sticky', bottom: 0 }}>
                <Row gutter={0} type="flex" align="middle" className="bg-transparent">
                  <Col className="bg-transparent" span={24}>
                    <div className="text-align-right">
                      <SecondaryButton size="large" className="mr-medium" onClick={_closeRemoveGuardianModal}>
                        Cancel
                      </SecondaryButton>
                      <PrimaryButton size="large cursor-pointer" onClick={_onRemoveGuardian}>
                        Remove
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          </ActionModal>
        )}
        {openAddLegalGuardianModal && (
          <AddLegalGuardianModal
            isOpen={this.state.openAddLegalGuardianModal}
            onClose={this._closeAddLegalGuardianModal}
            isCreateNewCustomer={false}
            selectedCustomer={selectedCustomer}
          />
        )}
        <div className="anim-slide-left  pt-x3-large">
          <Row className="ph-x3-large">
            <Col span={6} style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div>
                <Paragraph size="x3-large" weight="bold">
                  Add a legal guardian
                </Paragraph>
                <Paragraph className="mb-none">
                  <Text color="gray">Customer</Text>
                </Paragraph>
                <Paragraph className="mt-none mb-large">
                  {selectedCustomer.firstName + ' ' + selectedCustomer.lastName}
                </Paragraph>

                <Paragraph>
                  If this customer is dependent/ has their care managed by someone other than themselves (A
                  parent/parent/guardian/family member), then please indicate that here.
                </Paragraph>
                <Paragraph>
                  Visit the <HyperlinkButton>Help Center</HyperlinkButton> to learn more.
                </Paragraph>
              </div>
            </Col>
            <Col span={18} className="pl-x-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
                <div className="mb-large">
                  <Title level={4} className="mb-small">
                    Select a legal guardian
                  </Title>
                  {_.map(guardians, (legalGuardian) => {
                    return (
                      <div key={legalGuardian.guardianUserId} className="flex-row align-center pt-small">
                        <Avatar size="large" icon="user" className="mr-medium" src={legalGuardian.attachmentUrl} />

                        <Text weight="bold">{legalGuardian.firstName + ' ' + legalGuardian.lastName}</Text>

                        <Text
                          className="pl-medium text-underline cursor-pointer"
                          onClick={() => this._openRemoveGuardianModal(legalGuardian.email)}
                          size="small"
                          color="red-darker"
                        >
                          Remove
                        </Text>
                      </div>
                    );
                  })}
                  <div className="pt-large">
                    <Text onClick={this._openAddLegalGuardianModal}>
                      <HyperlinkButton>+ Add a legal guardian</HyperlinkButton>
                    </Text>
                  </div>

                  {this.state.legalGuardianRequiredError && (
                    <div className="pt-large">
                      <Paragraph color="red">Please add legal guardian</Paragraph>
                    </div>
                  )}
                </div>
              </div>
              <div className="pv-medium width-full" style={{ position: 'sticky', bottom: 0 }}>
                <Row gutter={0} type="flex" align="middle" className="bg-transparent">
                  <Col className="bg-transparent" span={24}>
                    <div className="text-align-right pv-medium">
                      <PrimaryButton size="large" onClick={this._validateBeforeNextStep}>
                        Next
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  addLegalGuardianDetailWizard: state.customersStore.addLegalGuardianDetailWizard
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setAddLegalGuardianDetailWizard: dispatch.customersStore.setAddLegalGuardianDetailWizard,
  setSelectedCustomer: dispatch.customersStore.setSelectedCustomer
});

export default connect(
  mapState,
  mapDispatch
)(AddLegalGuardianStepSelectGuardian);
