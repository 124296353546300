import { IShiftCustomerAssignment } from 'interfaces/assign-customer-interfaces';
import React, { useState } from 'react';
import { Col, Icon, Input, Row } from 'antd';
import { Text } from 'common-components/typography';
import moment from 'moment';
import TimeInput from 'common-components/time-input/TimeInput';
import { HyperlinkButton } from 'common-components/buttons';

export function TimeAssignmentRow({
  assignment,
  onEditRow,
  onSaveRow,
  onDeleteRow,
  onUpdateRow
}: {
  assignment: IShiftCustomerAssignment;
  onEditRow: any;
  onSaveRow: any;
  onDeleteRow: any;
  onUpdateRow: any;
}) {
  const [errorMessage, setErrorMessage] = useState('Start date cannot be before end date');

  const { isEditing, startDateTime, endDateTime } = assignment;

  function saveRow() {
    const { shiftCustomerAssignmentId } = assignment;
    onSaveRow({ rowId: shiftCustomerAssignmentId });
  }

  function editRow() {
    const { shiftCustomerAssignmentId } = assignment;
    onEditRow({ rowId: shiftCustomerAssignmentId });
  }

  function deleteRow() {
    const { shiftCustomerAssignmentId } = assignment;
    onDeleteRow({ rowId: shiftCustomerAssignmentId });
  }

  // check if start time is before end time
  const hasError = moment(endDateTime).isSameOrBefore(startDateTime);

  return (
    <div className="pv-x2-small">
      <Row>
        <Col span={8}>
          {!isEditing && (
            <div className="anim-fade-in-fast">
              <Text size="regular">
                {/*9:30 am - 1:30 pm, 12 Dec 2021*/}
                {moment(assignment.startDateTime).format('h:mm a')} - {moment(assignment.endDateTime).format('h:mm a')},{' '}
                {moment(assignment.startDateTime).format('D MMM YYYY')}
              </Text>
            </div>
          )}
          {isEditing && (
            <div className="flex-row anim-fade-in-fast">
              <TimeInput
                containerStyle={{ height: '32px' }}
                className="mr-x-small"
                onChange={(dt) =>
                  onUpdateRow({ rowId: assignment.shiftCustomerAssignmentId, field: 'startDateTime', value: dt })
                }
                value={moment(startDateTime)}
              />
              <TimeInput
                containerStyle={{ height: '32px' }}
                onChange={(dt) =>
                  onUpdateRow({ rowId: assignment.shiftCustomerAssignmentId, field: 'endDateTime', value: dt })
                }
                value={moment(endDateTime)}
              />
            </div>
          )}
        </Col>
        <Col span={14}>
          {!isEditing && (
            <div className="anim-fade-in-fast">
              <Text size="regular">{assignment.description}</Text>
            </div>
          )}
          {isEditing && (
            <div className="flex-row anim-fade-in-fast">
              <Input
                placeholder={'Enter description here...'}
                style={{ height: '32px' }}
                value={assignment.description}
                onChange={(e) =>
                  onUpdateRow({
                    rowId: assignment.shiftCustomerAssignmentId,
                    field: 'description',
                    value: e.target.value
                  })
                }
              />
            </div>
          )}
        </Col>
        <Col span={2}>
          <div className="flex-row justify-end align-center mt-x-small mr-x2-small">
            {isEditing && (
              <HyperlinkButton onClick={saveRow}>
                <Icon type="save" className="" />
              </HyperlinkButton>
            )}

            {!isEditing && (
              <HyperlinkButton onClick={editRow}>
                <Icon type="edit" className="" />
              </HyperlinkButton>
            )}

            {/*<Popconfirm*/}
            {/*  title="Delete this row?"*/}
            {/*  okText={'Delete'}*/}
            {/*  cancelText={'Cancel'}*/}
            {/*  placement={'bottomRight'}*/}
            {/*  onConfirm={deleteRow}*/}
            {/*>*/}
            {/*  <HyperlinkButton>*/}
            {/*    <Icon type="delete" className="text-color-red" />*/}
            {/*  </HyperlinkButton>*/}
            {/*</Popconfirm>*/}
          </div>
        </Col>
      </Row>
      {hasError && (
        <Text color="red" size="x-small">
          * {errorMessage}
        </Text>
      )}
    </div>
  );
}
