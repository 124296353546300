import React, { Component } from 'react';
import CenteredLayout from 'layouts/CenteredLayout';
import { Col, notification, Icon, Row, Skeleton, Tabs, Button } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { Text, Title, FieldLabel, Paragraph } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Menu, MenuItem, ProgressBar } from '@blueprintjs/core';
import moment from 'moment-timezone';
import * as H from 'history';
import {
  PlanManagementApprovalStatus,
  PlanManagementApprovalStatusDisplay,
  PlanManagementClaimStatus,
  PlanManagementPaymentStatus,
  PlanManagementPaymentStatusDisplay,
  BookingStatusString
} from 'utilities/enum-utils';
import PlanManagementInvoiceActivityLogPanel from './PlanManagementInvoiceActivityLogPanel';
import AddEditInvoiceDetailsModal from 'common-components/plan-management/AddEditInvoiceDetailsModal';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import ActionModal from 'common-components/modal/ActionModal';
import SendInvoiceForApprovalModal from 'views/plan-management/components/SendInvoiceForApprovalModal';
import _ from 'lodash';
import PlanManagementInvoiceClaimPanel from 'views/plan-management/invoices/PlanManagementInvoiceClaimPanel';

interface IPlanManagementInvoiceParams {
  planManagementInvoiceId: string;
}

interface IPlanManagementInvoiceDetailsProps extends RouteComponentProps<IPlanManagementInvoiceParams> {
  history: H.History;
  selectedPlanManagementInvoiceDetails: any;
  doFetchPlanManagementInvoiceDetails: typeof dispatch.planManagementStore.doFetchPlanManagementInvoiceDetails;
  doCancelPlanManagementInvoice: typeof dispatch.planManagementStore.doCancelPlanManagementInvoice;
  doFetchCompanyLite: typeof dispatch.companyStore.doFetchCompanyLite;
  doMarkInvoiceAs: typeof dispatch.planManagementStore.doMarkInvoiceAs;
  doRemoveApproval: typeof dispatch.planManagementStore.doRemoveApproval;
  doDownloadInvoiceDocument: typeof dispatch.planManagementStore.doDownloadInvoiceDocument;
  doFetchInvoiceBookingList: typeof dispatch.billingsStore.doFetchInvoiceBookingList;
  companyDataLite: any;
  isAddEditModalOpen: boolean;
  isCancelInvoiceModalOpen: boolean;
  isSendForApprovalModalOpen: boolean;
  portalUser: any;
}

class PlanManagementInvoiceDetails extends Component<IPlanManagementInvoiceDetailsProps, any> {
  state = {
    isLoading: true,
    selectedTab: 'CLAIMS',
    isLoadingCancellation: false,
    isAddEditModalOpen: false,
    isCancelInvoiceModalOpen: false,
    isSendForApprovalModalOpen: false,
    associatedBookings: null,
    isAssociatedBookingsModalOpen: false
  };

  private _changeTab = (event) => {
    this.setState({ selectedTab: event });
  };

  private _onPressCancelInvoice = async () => {
    try {
      this.setState({ isLoadingCancellation: true });
      await this.props.doCancelPlanManagementInvoice({
        planManagementInvoiceId: this.props.selectedPlanManagementInvoiceDetails.planManagementInvoiceId
      });
      await this.props.doFetchPlanManagementInvoiceDetails({
        planManagementInvoiceId: this.props.selectedPlanManagementInvoiceDetails.planManagementInvoiceId
      });
      notification.success({ message: 'Invoice cancelled successfully.' });
      this.setState({
        isCancelInvoiceModalOpen: false,
        isLoadingCancellation: false
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  private _markAs = async (approvalStatus) => {
    const { doMarkInvoiceAs, selectedPlanManagementInvoiceDetails } = this.props;
    try {
      await doMarkInvoiceAs({
        planManagementInvoiceId: selectedPlanManagementInvoiceDetails.planManagementInvoiceId,
        approvalStatus
      });
      notification.success({ message: 'Status changed successfully.' });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  private _removeApproval = async () => {
    const { doRemoveApproval, selectedPlanManagementInvoiceDetails } = this.props;
    try {
      await doRemoveApproval({
        planManagementInvoiceId: selectedPlanManagementInvoiceDetails.planManagementInvoiceId
      });
      notification.success({ message: 'Status changed successfully.' });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  private _getActionMenu = (activeFields = null) => {
    return (
      <Menu>
        {(!activeFields || !!_.find(activeFields, (active) => active === PlanManagementApprovalStatus.APPROVED)) && (
          <MenuItem
            text={
              <>
                Mark as <b>Customer Approved</b>
              </>
            }
            onClick={() => this._markAs(PlanManagementApprovalStatus.APPROVED)}
            className={'pv-small'}
          />
        )}
        {(!activeFields || !!_.find(activeFields, (active) => active === PlanManagementApprovalStatus.REJECTED)) && (
          <MenuItem
            text={
              <>
                Mark as <b>Customer Rejected</b>
              </>
            }
            onClick={() => this._markAs(PlanManagementApprovalStatus.REJECTED)}
            className={'pv-small'}
          />
        )}
        {!!_.find(activeFields, (active) => active === 'REMOVE_APPROVAL') && (
          <MenuItem
            text={
              <>
                Remove <b>Approval</b>
              </>
            }
            onClick={this._removeApproval}
            className={'pv-small'}
          />
        )}
      </Menu>
    );
  };

  private _downloadInvoiceDocument = () => {
    const { selectedPlanManagementInvoiceDetails } = this.props;
    this.props.doDownloadInvoiceDocument({
      uniqueId: selectedPlanManagementInvoiceDetails.uniqueId,
      documentName: selectedPlanManagementInvoiceDetails.documentName,
      documentUrl: selectedPlanManagementInvoiceDetails.documentUrl
    });
  };

  private _viewInvoiceBookings = async () => {
    const { doFetchInvoiceBookingList, selectedPlanManagementInvoiceDetails } = this.props;
    const bookings = await doFetchInvoiceBookingList({
      invoiceId: selectedPlanManagementInvoiceDetails.invoiceId
    });
    this.setState({
      associatedBookings: bookings,
      isAssociatedBookingsModalOpen: true
    });
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    await this.props.doFetchCompanyLite([]);
    await this.props.doFetchPlanManagementInvoiceDetails({
      planManagementInvoiceId: this.props.match.params.planManagementInvoiceId
    });
    this.setState({ isLoading: false });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.match.params.planManagementInvoiceId !== this.props.match.params.planManagementInvoiceId) {
      this.setState({ isLoading: true });
      await this.props.doFetchCompanyLite([]);
      await this.props.doFetchPlanManagementInvoiceDetails({
        planManagementInvoiceId: this.props.match.params.planManagementInvoiceId
      });
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { selectedPlanManagementInvoiceDetails } = this.props;
    const { isSendForApprovalModalOpen } = this.state;

    const approvalStatusColor = selectedPlanManagementInvoiceDetails
      ? selectedPlanManagementInvoiceDetails.approvalStatus === PlanManagementApprovalStatus.SENT
        ? 'warning-orange'
        : selectedPlanManagementInvoiceDetails.approvalStatus === PlanManagementApprovalStatus.APPROVED
        ? 'green'
        : selectedPlanManagementInvoiceDetails.approvalStatus === PlanManagementApprovalStatus.REJECTED
        ? 'red-dark'
        : 'black'
      : 'black';

    const supplierPaymentStatusColor = selectedPlanManagementInvoiceDetails
      ? selectedPlanManagementInvoiceDetails.supplierPaymentStatus === PlanManagementPaymentStatus.PAID
        ? 'green'
        : selectedPlanManagementInvoiceDetails.supplierPaymentStatus === PlanManagementPaymentStatus.UNPAID
        ? 'red-dark'
        : 'black'
      : 'black';

    const serviceProviderTimezone = this.props.companyDataLite ? this.props.companyDataLite.timezone : null;

    const hasLineItemsWithProcessedPaymentStatus = selectedPlanManagementInvoiceDetails
      ? _.find(selectedPlanManagementInvoiceDetails.lineItems, (lineItem) => {
          return (
            lineItem.claimStatus === PlanManagementClaimStatus.SENT_TO_PAYMENTS ||
            lineItem.claimStatus === PlanManagementClaimStatus.CLAIMED ||
            lineItem.claimStatus === PlanManagementClaimStatus.REJECTED
          );
        })
      : false;

    const hasLineItemsWithPaymentStatusClaimed = selectedPlanManagementInvoiceDetails
      ? _.find(selectedPlanManagementInvoiceDetails.lineItems, (lineItem) => {
          return lineItem.claimStatus === PlanManagementClaimStatus.CLAIMED;
        })
      : false;

    const canRemoveApproval = selectedPlanManagementInvoiceDetails
      ? !_.find(selectedPlanManagementInvoiceDetails.lineItems, (lineItem) => {
          return (
            lineItem.claimStatus === PlanManagementClaimStatus.SENT_TO_PAYMENTS ||
            lineItem.claimStatus === PlanManagementClaimStatus.CLAIMED ||
            lineItem.claimStatus === PlanManagementClaimStatus.REJECTED ||
            lineItem.claimStatus === PlanManagementClaimStatus.WAIVED
          );
        })
      : false;

    const displayTimezone = this.props.portalUser.timezone;

    return (
      <CenteredLayout backgroundColor="white" bordered>
        {this.state.isAddEditModalOpen && (
          <AddEditInvoiceDetailsModal
            isOpen={this.state.isAddEditModalOpen}
            isAutoGenerated={!!selectedPlanManagementInvoiceDetails.invoiceId}
            modalMode={'EDIT'}
            onClose={() => this.setState({ isAddEditModalOpen: false })}
          />
        )}
        {this.state.isAssociatedBookingsModalOpen && (
          <ActionModal
            title={'Bookings in this invoice'}
            isOpen={this.state.isAssociatedBookingsModalOpen}
            width="large"
            onClose={() => this.setState({ isAssociatedBookingsModalOpen: false })}
          >
            <div className="anim-slide-left">
              <Paragraph>The following bookings are associated with this invoice.</Paragraph>
              <Row className="text-weight-bold bordered-bottom border-standard-gray p-small">
                <Col span={6}>
                  <FieldLabel text="BOOKING ID" />
                </Col>
                <Col span={8}>
                  <FieldLabel text="DATE" />
                </Col>
                <Col span={6}>
                  <FieldLabel text="STATUS" />
                </Col>
                <Col span={4} />
              </Row>
              {_.map(this.state.associatedBookings, (booking) => {
                return (
                  <Row className="bordered-bottom border-standard-gray p-small">
                    <Col span={6}>
                      <Text color="blue-action">{booking.referenceId}</Text>
                    </Col>
                    <Col span={8}>
                      {moment
                        .tz(booking.startDateTime, displayTimezone)
                        .startOf('day')
                        .isSame(moment.tz(booking.endDateTime, displayTimezone).startOf('day')) ? (
                        <text>
                          <b>{moment.tz(booking.startDateTime, displayTimezone).format('DD/MM/YYYY')}</b>
                          <br />
                          <Text>
                            {moment.tz(booking.startDateTime, displayTimezone).format('hh:mm a')} -{' '}
                            {moment.tz(booking.endDateTime, displayTimezone).format('hh:mm a')}
                          </Text>
                        </text>
                      ) : (
                        <text>
                          <b>{moment.tz(booking.startDateTime, displayTimezone).format('DD/MM/YYYY')}</b>{' '}
                          {moment.tz(booking.startDateTime, displayTimezone).format('hh:mm a')}
                          <br />
                          <b>{moment.tz(booking.endDateTime, displayTimezone).format('DD/MM/YYYY')}</b>{' '}
                          {moment.tz(booking.endDateTime, displayTimezone).format('hh:mm a')}
                        </text>
                      )}
                    </Col>
                    <Col span={6}>
                      <Text>{BookingStatusString[booking.status]}</Text>
                    </Col>
                    <Col span={4}>
                      <HyperlinkButton
                        onClick={() =>
                          this.props.history.push(`/bookings/details/${booking.attendanceId}`, { refresh: true })
                        }
                      >
                        View booking
                      </HyperlinkButton>
                    </Col>
                  </Row>
                );
              })}
              <div className="flex-row justify-end mt-large">
                <PrimaryButton size="large" onClick={() => this.setState({ isAssociatedBookingsModalOpen: false })}>
                  Close
                </PrimaryButton>
              </div>
            </div>
          </ActionModal>
        )}
        <ActionModal
          title={hasLineItemsWithPaymentStatusClaimed ? 'Invoice contains processed line items' : 'Cancel invoice'}
          isOpen={this.state.isCancelInvoiceModalOpen}
          width="large"
          onClose={() => this.setState({ isCancelInvoiceModalOpen: false })}
        >
          <div className="anim-slide-left">
            {hasLineItemsWithPaymentStatusClaimed ? (
              <Text>
                This invoice contains line items that have already been processed. Invoices with processed line items
                cannot be cancelled.
              </Text>
            ) : (
              <Text>
                Are you sure you want to cancel this invoice? Any line items attached to it will be waived.
                <br />
                <br />
                This action is permanent and cannot be undone.
              </Text>
            )}
          </div>
          <div className="flex-row justify-end mt-large">
            <PrimaryButton
              size="large"
              loading={this.state.isLoadingCancellation}
              onClick={
                hasLineItemsWithPaymentStatusClaimed
                  ? () => this.setState({ isCancelInvoiceModalOpen: false })
                  : this._onPressCancelInvoice
              }
              className="ml-medium"
            >
              OK
            </PrimaryButton>
          </div>
        </ActionModal>
        {/* Top Actions Panel */}
        {/*<BookingTopActions />*/}
        {/* Booking Nav Menu */}
        {(this.state.isLoading || !selectedPlanManagementInvoiceDetails) && (
          <>
            <div className="item-container">
              <div className="pb-medium">
                <Text>Fetching invoice data...</Text>
              </div>
              <ProgressBar />
            </div>
            <Skeleton loading={this.state.isLoading} active avatar title paragraph />
          </>
        )}
        {!this.state.isLoading && !!selectedPlanManagementInvoiceDetails && (
          <div>
            <Row>
              {/* Booking Content */}
              <Col sm={24} md={24}>
                {/* Actual Content */}
                <div className="mb-medium">
                  <HyperlinkButton onClick={() => this.props.history.push('/plan-management')}>
                    Back to invoice listing
                  </HyperlinkButton>
                </div>
                <div className={'bordered rounded p-large mb-x4-large'}>
                  <div className="flex-row justify-between align-center">
                    <Title level={4} className={'m-none'}>
                      Invoice Details
                    </Title>
                    {selectedPlanManagementInvoiceDetails.claimStatus !== 'CANCELLED' && (
                      <div>
                        <SecondaryButton
                          onClick={() => this.setState({ isAddEditModalOpen: true })}
                          className={
                            hasLineItemsWithProcessedPaymentStatus
                              ? 'text-color-grey-action border-grey-action mr-medium'
                              : 'text-color-blue-action border-blue-action mr-medium'
                          }
                          disabled={hasLineItemsWithProcessedPaymentStatus}
                        >
                          Edit Invoice...
                        </SecondaryButton>
                        {hasLineItemsWithProcessedPaymentStatus && (
                          <Tooltip2
                            position="bottom"
                            content={
                              <Text color="white">
                                You cannot edit an invoice containing line items that have been sent to payments.
                                <br />
                                Please revoke or waive any line items in order to edit the invoice.
                              </Text>
                            }
                          >
                            <Icon
                              type="question-circle"
                              style={{ marginLeft: '-8px' }}
                              className="text-size-x-large mr-medium text-color-blue"
                            />
                          </Tooltip2>
                        )}
                        <SecondaryButton
                          className="mr-medium"
                          color="red"
                          onClick={() => {
                            this.setState({
                              isCancelInvoiceModalOpen: true
                            });
                          }}
                        >
                          Cancel Invoice
                        </SecondaryButton>
                      </div>
                    )}
                  </div>
                  <div className="mt-large bordered-top">
                    <Row gutter={16} className="mt-x-large">
                      <Col span={8}>
                        <FieldLabel text="INVOICE NO" />
                        <Text size="large">{selectedPlanManagementInvoiceDetails.invoiceNumber}</Text>
                      </Col>
                      <Col span={8}>
                        {selectedPlanManagementInvoiceDetails.claimStatus === 'CANCELLED' ? (
                          <>
                            <FieldLabel text="STATUS" />
                            <Text size="large" color="red">
                              CANCELLED
                            </Text>
                          </>
                        ) : selectedPlanManagementInvoiceDetails.invoiceId ? (
                          <>
                            <FieldLabel text="STATUS" />
                            <HyperlinkButton onClick={this._viewInvoiceBookings}>
                              View associated bookings...
                            </HyperlinkButton>
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                      <Col span={8}>
                        {selectedPlanManagementInvoiceDetails.claimStatus === 'CANCELLED' &&
                        selectedPlanManagementInvoiceDetails.invoiceId ? (
                          <>
                            <FieldLabel text="STATUS" />
                            <HyperlinkButton onClick={this._viewInvoiceBookings}>
                              View associated bookings...
                            </HyperlinkButton>
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                    <Row gutter={16} className="mt-x-large">
                      <Col span={8}>
                        <FieldLabel text="INVOICE DATE" />
                        <Text size="large">
                          {moment(selectedPlanManagementInvoiceDetails.invoiceIssuedDate).format('DD/MM/YYYY')}
                        </Text>
                      </Col>
                      <Col span={8}>
                        <FieldLabel text="DUE ON" />
                        <Text size="large">
                          {moment(selectedPlanManagementInvoiceDetails.invoiceDueDate).format('DD/MM/YYYY')}
                        </Text>
                      </Col>
                      <Col span={8}>
                        {selectedPlanManagementInvoiceDetails.paidDate && (
                          <>
                            <FieldLabel text="PAID on" />
                            <Text size="large">
                              {moment(selectedPlanManagementInvoiceDetails.paidDate).format('DD/MM/YYYY')}
                            </Text>
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row gutter={16} className="mt-x-large">
                      <Col span={8}>
                        <FieldLabel text="SERVICE PROVIDER" />
                        <Text size="large">{selectedPlanManagementInvoiceDetails.supplierName}</Text>
                      </Col>
                      <Col span={8}>
                        <FieldLabel text="CUSTOMER" />
                        <Text size="large">
                          {selectedPlanManagementInvoiceDetails.firstName}{' '}
                          {selectedPlanManagementInvoiceDetails.lastName}
                        </Text>
                      </Col>
                      <Col span={8} />
                    </Row>
                    <Row gutter={16} className="mt-x-large">
                      <Col span={8}>
                        <FieldLabel
                          text={
                            selectedPlanManagementInvoiceDetails.includesGst
                              ? 'INVOICE AMOUNT (Exc. GST)'
                              : 'AMOUNT DUE'
                          }
                        />
                        <Text size="large">${selectedPlanManagementInvoiceDetails.invoiceTotal}</Text>
                      </Col>
                      <Col span={8}>
                        {selectedPlanManagementInvoiceDetails.includesGst && (
                          <>
                            <FieldLabel text="GST AMOUNT" />
                            <Text size="large">${selectedPlanManagementInvoiceDetails.gstAmount}</Text>
                          </>
                        )}
                      </Col>
                      <Col span={8} />
                    </Row>
                    <Row gutter={16} className="mt-x-large">
                      <Col span={8}>
                        <div
                          className={`bg-quaternary p-medium bordered-left border-width-large rounded border-${
                            approvalStatusColor === 'black' ? 'gray' : approvalStatusColor
                          }`}
                          style={{ width: '250px' }}
                        >
                          <FieldLabel text="CUSTOMER APPROVAL" />
                          <Text size="large" color={approvalStatusColor}>
                            {PlanManagementApprovalStatusDisplay[selectedPlanManagementInvoiceDetails.approvalStatus]}
                          </Text>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div
                          className={`bg-quaternary p-medium bordered-left border-width-large rounded border-${
                            supplierPaymentStatusColor === 'black' ? 'gray' : supplierPaymentStatusColor
                          }`}
                          style={{ width: '250px' }}
                        >
                          <FieldLabel text="SUPPLIER STATUS" />
                          <Text size="large" color={supplierPaymentStatusColor}>
                            {
                              PlanManagementPaymentStatusDisplay[
                                selectedPlanManagementInvoiceDetails.supplierPaymentStatus
                              ]
                            }
                          </Text>
                        </div>
                      </Col>
                      <Col span={8} />
                    </Row>
                    {selectedPlanManagementInvoiceDetails.approvalStatus === PlanManagementApprovalStatus.NOT_SENT && (
                      <>
                        <SendInvoiceForApprovalModal
                          isOpen={isSendForApprovalModalOpen}
                          closeModal={() => this.setState({ isSendForApprovalModalOpen: false })}
                          selectedInvoice={selectedPlanManagementInvoiceDetails}
                        />
                        <Button.Group>
                          <PrimaryButton
                            className="mt-x-large"
                            onClick={() => this.setState({ isSendForApprovalModalOpen: true })}
                          >
                            <>
                              Send for <b>Approval</b>
                            </>
                          </PrimaryButton>{' '}
                          <Popover2 content={this._getActionMenu()} position={'bottom-right'}>
                            <PrimaryButton className={'rounded-right'}>
                              <Icon type={'down'} />
                            </PrimaryButton>
                          </Popover2>
                        </Button.Group>
                      </>
                    )}
                    {selectedPlanManagementInvoiceDetails.approvalStatus === PlanManagementApprovalStatus.SENT && (
                      <Popover2 content={this._getActionMenu()} position={'bottom-right'}>
                        <Button.Group>
                          <PrimaryButton className="mt-x-large">Mark as...</PrimaryButton>{' '}
                          <PrimaryButton>
                            <Icon type={'down'} />
                          </PrimaryButton>
                        </Button.Group>
                      </Popover2>
                    )}
                    {selectedPlanManagementInvoiceDetails.approvalStatus === PlanManagementApprovalStatus.REJECTED && (
                      <>
                        <SendInvoiceForApprovalModal
                          isOpen={isSendForApprovalModalOpen}
                          closeModal={() => this.setState({ isSendForApprovalModalOpen: false })}
                          selectedInvoice={selectedPlanManagementInvoiceDetails}
                        />
                        <Button.Group>
                          <PrimaryButton
                            className="mt-x-large"
                            onClick={() => this.setState({ isSendForApprovalModalOpen: true })}
                          >
                            <>
                              Send for <b>Approval</b>
                            </>
                          </PrimaryButton>{' '}
                          <Popover2
                            content={this._getActionMenu([PlanManagementApprovalStatus.APPROVED])}
                            position={'bottom-right'}
                          >
                            <PrimaryButton className={'rounded-right'}>
                              <Icon type={'down'} />
                            </PrimaryButton>
                          </Popover2>
                        </Button.Group>
                      </>
                    )}
                    {selectedPlanManagementInvoiceDetails.approvalStatus === PlanManagementApprovalStatus.APPROVED &&
                      canRemoveApproval && (
                        <Popover2 content={this._getActionMenu(['REMOVE_APPROVAL'])} position={'bottom-left'}>
                          <SecondaryButton className="mt-x-large">
                            <Icon type="ellipsis" />
                          </SecondaryButton>
                        </Popover2>
                      )}
                    <div className="flex-column mt-x-large">
                      <FieldLabel text="ATTACHMENT" />
                      {selectedPlanManagementInvoiceDetails.documentId &&
                        !!selectedPlanManagementInvoiceDetails.documentStatus &&
                        selectedPlanManagementInvoiceDetails.documentStatus === 'SCANNED' && (
                          <div className="flex-row">
                            <HyperlinkButton onClick={this._downloadInvoiceDocument}>
                              Download attachment...
                            </HyperlinkButton>
                          </div>
                        )}
                      {selectedPlanManagementInvoiceDetails.documentId &&
                        !!selectedPlanManagementInvoiceDetails.documentStatus &&
                        selectedPlanManagementInvoiceDetails.documentStatus !== 'SCANNED' && (
                          <div className="flex-row">
                            <Text>View attachment...</Text>
                            <Tooltip2
                              position="bottom"
                              className="ml-medium"
                              content={
                                selectedPlanManagementInvoiceDetails.documentStatus === 'SCANNING' ? (
                                  <Text color="white">
                                    Document scanning in progress. You will only be able to download the document after
                                    it has been scanned.
                                  </Text>
                                ) : (
                                  <Text color="white">
                                    Document scanning failed. Please try and reupload the file again.
                                  </Text>
                                )
                              }
                            >
                              <Icon
                                type="question-circle"
                                style={{ marginLeft: '-8px' }}
                                className="text-size-x-large mr-medium text-color-blue"
                              />
                            </Tooltip2>
                          </div>
                        )}

                      {!selectedPlanManagementInvoiceDetails.documentId && <Text>No attachment</Text>}
                    </div>
                    <div className="flex-column mt-x-large">
                      <FieldLabel text="COMMENTS" />
                      <Text size="large">
                        {selectedPlanManagementInvoiceDetails.comments
                          ? selectedPlanManagementInvoiceDetails.comments
                          : 'No comments set...'}
                      </Text>
                    </div>
                  </div>
                  <div className="mt-x-large">
                    <Tabs defaultActiveKey={'CLAIMS'} animated={true} onChange={this._changeTab}>
                      <Tabs.TabPane tab="Claims" key="CLAIMS" />
                      <Tabs.TabPane tab="Activity" key="ACTIVITY" />
                    </Tabs>
                    {this.state.selectedTab === 'CLAIMS' && (
                      <PlanManagementInvoiceClaimPanel invoice={selectedPlanManagementInvoiceDetails} />
                    )}
                    {this.state.selectedTab === 'ACTIVITY' && (
                      <>
                        <PlanManagementInvoiceActivityLogPanel
                          invoiceAuditLogs={selectedPlanManagementInvoiceDetails.invoiceAuditLogs}
                          timezoneData={serviceProviderTimezone}
                        />
                      </>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </CenteredLayout>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedPlanManagementInvoiceDetails: state.planManagementStore.selectedPlanManagementInvoiceDetails,
  companyDataLite: state.companyStore.companyDataLite,
  portalUser: state.authStore.portalUser
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchPlanManagementInvoiceDetails: dispatch.planManagementStore.doFetchPlanManagementInvoiceDetails,
  doCancelPlanManagementInvoice: dispatch.planManagementStore.doCancelPlanManagementInvoice,
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite,
  doMarkInvoiceAs: dispatch.planManagementStore.doMarkInvoiceAs,
  doRemoveApproval: dispatch.planManagementStore.doRemoveApproval,
  doDownloadInvoiceDocument: dispatch.planManagementStore.doDownloadInvoiceDocument,
  doFetchInvoiceBookingList: dispatch.billingsStore.doFetchInvoiceBookingList
});

export default connect(mapState, mapDispatch)(PlanManagementInvoiceDetails);
