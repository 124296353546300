import React, { Component } from 'react';
import { SubTitle, Text, Title } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { Empty, Skeleton } from 'antd';
import * as H from 'history';
import Search from 'antd/es/input/Search';
import _ from 'lodash';
import '../css/plan-management-listing.css';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { PrimaryButton } from 'common-components/buttons';
import AddEditSupplierModal from 'views/plan-management/components/AddEditSupplierModal';
import { IPMSupplier } from 'src/interfaces/plan-management-interfaces';

interface ITeamManagementViewProps {
  planManagementSuppliers: typeof state.planManagementStore.planManagementSuppliers;
  planManagementSupplierFilters: typeof state.planManagementStore.planManagementSupplierFilters;
  setPlanManagementSupplierFilters: typeof dispatch.planManagementStore.setPlanManagementSupplierFilters;
  doFetchPlanManagementSuppliers: typeof dispatch.planManagementStore.doFetchPlanManagementSuppliers;
  history: H.History;
}

interface ITeamManagementViewState {
  isSearching: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  isAddModalOpen: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  isEdit: boolean;
  selectedSupplier: IPMSupplier;
}

const SuppliersEmptyState = () => (
  <tr>
    <td colSpan={4} className="text-align-center mb-x-large">
      <div className="">
        <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
      <Text size="x2-large" color="secondary" weight="bold">
        No Providers found.
      </Text>{' '}
      <br /> <br />
      <Text color="secondary">All providers under this filter will appear here. </Text>
      <Text color="secondary">Try adjusting your filter.</Text>
    </td>
  </tr>
);

class PlanManagementSupplierOverviewPanel extends Component<ITeamManagementViewProps, ITeamManagementViewState> {
  state = {
    isSearching: false,
    isLoading: false,
    isLoadingMore: false,
    isAddModalOpen: false,
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    isEdit: false,
    selectedSupplier: null
  };

  private _applyFilter = async (refreshList = false) => {
    this.setState({
      page: 1,
      isLoading: true,
      pageTimestamp: refreshList ? new Date() : this.state.pageTimestamp
    });
    const requestFilter = this._formatFilterQuery();
    await this.props.doFetchPlanManagementSuppliers({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });
    this.setState({ isLoading: false });
  };

  private _formatFilterQuery = () => {
    const requestFilter: any = {};
    _.forEach(this.props.planManagementSupplierFilters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'searchString':
            requestFilter.searchString = filter.values;
        }
      }
    });
    return requestFilter;
  };

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _searchText = async (txt) => {
    const { planManagementSupplierFilters, setPlanManagementSupplierFilters } = this.props;
    let newPlanManagementSupplierFilters = _.clone(planManagementSupplierFilters);
    const existingSearchIndex = _.findIndex(
      planManagementSupplierFilters,
      (filter: any) => filter.filter === 'searchString'
    );
    if (existingSearchIndex > -1) {
      if (txt === '') {
        newPlanManagementSupplierFilters.splice(existingSearchIndex, 1);
      } else {
        newPlanManagementSupplierFilters[existingSearchIndex].values = txt;
      }
    } else {
      newPlanManagementSupplierFilters.push({ filter: 'searchString', values: txt });
    }
    setPlanManagementSupplierFilters(newPlanManagementSupplierFilters);
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _fetchMorePlanManagementSuppliers = async () => {
    const { doFetchPlanManagementSuppliers } = this.props;
    await this.setState({ page: this.state.page + 1, isLoadingMore: true });
    const requestFilter = this._formatFilterQuery();
    await doFetchPlanManagementSuppliers({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });
    this.setState({ isLoadingMore: false });
  };

  private _closeAddSupplierModal = async (refreshListing = false) => {
    this.setState({ isAddModalOpen: false });
    if (refreshListing) await this._applyFilter(true);
  };

  private _openAddSupplierModal = () => {
    this.setState({ isEdit: false, isAddModalOpen: true, selectedSupplier: null });
  };

  private _openEditSupplierModal = (selectedSupplier) => {
    this.setState({ isEdit: true, selectedSupplier, isAddModalOpen: true });
  };

  private _goToSupplierDetailView = (planManagementSupplierId) => {
    this.props.history.push(`/plan-management/provider/details/${planManagementSupplierId}`);
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.planManagementSupplierFilters !== this.props.planManagementSupplierFilters) {
      await this._applyFilter();
    }
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    await this.props.doFetchPlanManagementSuppliers({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp
    });
    this.setState({ isLoading: false });
  };

  render() {
    const { planManagementSuppliers, planManagementSupplierFilters } = this.props;
    const { isLoading, isLoadingMore, isAddModalOpen, isEdit, selectedSupplier } = this.state;

    return (
      <div className={'pt-x-large'}>
        <AddEditSupplierModal
          closeModal={this._closeAddSupplierModal}
          isOpen={isAddModalOpen}
          isEdit={isEdit}
          selectedSupplier={selectedSupplier}
        />
        <div className={'flex-row align-center justify-between'}>
          <Title level={4} className="mb-none">
            Providers overview
          </Title>
          <PrimaryButton size="large" icon={'plus'} onClick={this._openAddSupplierModal}>
            New Provider
          </PrimaryButton>
        </div>

        <div className={'flex-row align-center justify-between mv-large'}>
          <Search
            placeholder="Search for provider.."
            key={'provider_search'}
            onChange={this._onEnterSearchText}
            loading={this.state.isSearching}
            style={{ width: '250px' }}
            defaultValue={
              planManagementSupplierFilters.searchString ? planManagementSupplierFilters.searchString : undefined
            }
            allowClear={true}
          />
        </div>

        <div className="height-full bg-white" style={{ overflowY: 'hidden' }}>
          <table className={'width-full plan-management-listing'}>
            <tbody>
              <tr className={'bordered-bottom border-color-quaternary'}>
                <th style={{ width: '30%' }} className={'pb-small pl-medium'}>
                  <SubTitle>Provider</SubTitle>
                </th>
                <th style={{ width: '20%' }} className={'pb-small'}>
                  <SubTitle>ABN</SubTitle>
                </th>
                <th style={{ width: '20%' }} className={'pb-small'}>
                  <SubTitle>No. of customers</SubTitle>
                </th>
                <th style={{ width: '30%' }} className={'pb-small'}>
                  <SubTitle>Outstanding invoices</SubTitle>
                </th>
              </tr>
              {_.isEmpty(planManagementSuppliers) ? (
                <SuppliersEmptyState />
              ) : isLoading ? (
                <tr>
                  <td colSpan={4}>
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} />
                  </td>
                </tr>
              ) : (
                <>
                  <InfiniteScrollLoading
                    hasMore={planManagementSuppliers.length >= this.state.page * this.state.pageSize}
                    loadingElementId={'content-container'}
                    loadMore={this._fetchMorePlanManagementSuppliers}
                    loadingOffSet={100}
                    loaderColSpan={4}
                  >
                    {_.map(planManagementSuppliers, (provider, key) => {
                      return (
                        <tr
                          className={'evenodd cursor-pointer'}
                          onClick={() => this._goToSupplierDetailView(provider.planManagementSupplierId)}
                          key={key}
                        >
                          <td className={'pv-medium pl-medium'}>{provider.supplierName}</td>
                          <td className={'pv-medium'}>{provider.abnNumber}</td>
                          <td className={'pv-medium'}>{provider.numberOfCustomers}</td>
                          <td className={'pv-medium'}>{provider.numberOfOutstandingInvoices}</td>
                        </tr>
                      );
                    })}
                  </InfiniteScrollLoading>
                  {isLoadingMore && (
                    <tr>
                      <td colSpan={4}>
                        <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} />
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  planManagementSuppliers: state.planManagementStore.planManagementSuppliers,
  planManagementSupplierFilters: state.planManagementStore.planManagementSupplierFilters
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setPlanManagementSupplierFilters: dispatch.planManagementStore.setPlanManagementSupplierFilters,
  doFetchPlanManagementSuppliers: dispatch.planManagementStore.doFetchPlanManagementSuppliers
});

export default connect(
  mapState,
  mapDispatch
)(PlanManagementSupplierOverviewPanel);
