import React, { Component } from 'react';
import { Col, Form, Row, Avatar } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { PrimaryButton } from 'common-components/buttons';
import _ from 'lodash';

import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import ActionModal from 'common-components/modal/ActionModal';
interface IAddLegalGuardianModalProps extends FormComponentProps {
  isOpen: boolean;
  onClose: () => void;
  duplicateUser: any;
}

class AddLegalGuardianModal extends Component<IAddLegalGuardianModalProps> {
  private _renderView = () => {
    const { form, duplicateUser, onClose } = this.props;

    return (
      <>
        <div>
          <Paragraph>
            The following user who has already been added to your workspace with an email matching the one you have
            entered for this customer
          </Paragraph>
        </div>
        <div className="flex-row align-center">
          <Avatar size="large" icon="user" className="mr-medium" src={duplicateUser.attachmentUrl} />
          <div className="flex-column">
            <Text className="line-height-135 text-size-large">
              {duplicateUser.firstName} {duplicateUser.lastName}
            </Text>

            <Text color="secondary" className="text-size-x-small">
              {duplicateUser.email}
            </Text>
          </div>
        </div>
        <div className="pt-medium">
          <Paragraph>Please enter another email address to invite this customer to the GoodHuman app</Paragraph>
        </div>

        <div className="pd-large pb- width-full" style={{ position: 'sticky', bottom: 0 }}>
          <Row gutter={0} type="flex" align="middle" className="bg-transparent">
            <Col className="bg-transparent" span={24}>
              <div className="text-align-right">
                <PrimaryButton size="large" onClick={onClose}>
                  Close
                </PrimaryButton>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  render() {
    let { isOpen, onClose } = this.props;

    return (
      <ActionModal isOpen={isOpen} title="Email duplicate found" width="large" onClose={onClose}>
        {this._renderView()}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  addCustomerDetailWizard: state.customersStore.addCustomerDetailWizard
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGuardianLite: dispatch.customersStore.doFetchGuardianLite,
  setAddCustomerDetailWizard: dispatch.customersStore.setAddCustomerDetailWizard,
  doCreateCustomer: dispatch.customersStore.doCreateCustomer
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IAddLegalGuardianModalProps>()(AddLegalGuardianModal));
