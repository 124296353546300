import React, { Component } from 'react';
import { SubTitle, Title, Text } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { Avatar, Col, Empty, Row, Skeleton } from 'antd';
import * as H from 'history';
import '../css/plan-management-listing.css';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import CenteredLayout from 'layouts/CenteredLayout';
import { RouteComponentProps } from 'react-router-dom';
import { LeftNavItem } from 'common-components/navigation/LeftNavItem';
import AddEditSupplierModal from 'views/plan-management/components/AddEditSupplierModal';
import StatisticDisplayCard from 'common-components/cards/StatisticDisplayCard';
import { FilterSection } from 'common-components/filter';
import _ from 'lodash';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import PermissionUtils from 'utilities/permission-utils';
import ApprovalStatusTag from 'common-components/tags/ApprovalStatusTag';
import ClaimStatusTag from 'common-components/tags/ClaimStatusTag';
import SupplierStatusTag from 'common-components/tags/SupplierStatusTag';
import CommonUtils from 'utilities/common-utils';
import {
  FilterType,
  PlanManagementApprovalStatus,
  PlanManagementClaimStatus,
  PlanManagementPaymentStatus
} from 'utilities/enum-utils';
import moment from 'moment-timezone';
import AddEditInvoiceDetailsModal from 'common-components/plan-management/AddEditInvoiceDetailsModal';

interface ISupplierUrlParams {
  planManagementSupplierId: string;
}

interface IPlanManagementSupplierDetailViewProps extends RouteComponentProps<ISupplierUrlParams> {
  history: H.History;
  portalUser: typeof state.authStore.portalUser;
  planManagementSupplierDetail: typeof state.planManagementStore.planManagementSupplierDetail;
  planManagementInvoices: typeof state.planManagementStore.planManagementInvoices;
  planManagementInvoiceFilters: typeof state.planManagementStore.planManagementInvoiceFilters;
  doFetchPlanManagementSupplierDetail: typeof dispatch.planManagementStore.doFetchPlanManagementSupplierDetail;
  doFetchPlanManagementInvoiceStatistics: typeof dispatch.planManagementStore.doFetchPlanManagementInvoiceStatistics;
  planManagementInvoiceStatistics: typeof state.planManagementStore.planManagementInvoiceStatistics;
  setPlanManagementInvoiceFilters: typeof dispatch.planManagementStore.setPlanManagementInvoiceFilters;
  doFetchPlanManagementInvoices: typeof dispatch.planManagementStore.doFetchPlanManagementInvoices;
}

interface IPlanManagementSupplierDetailViewState {
  selectedTab: string;
  isAddModalOpen: boolean;
  isLoading: boolean;
  isLoadingProvider: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  isLoadingMore: boolean;
  isAddInvoiceModalOpen: boolean;
}

const availableFilters = [
  FilterType.DATE_RANGE,
  FilterType.CUSTOMER,
  FilterType.SUPPLIER_PAYMENT_STATUS,
  FilterType.APPROVAL_STATUS,
  FilterType.CLAIM_STATUS
];

const INVOICELIST_FILTERCONFIGS = {
  DEFAULT: {
    key: 'DEFAULT',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
        selectionLabel: CommonUtils.getFilterText(FilterType.DATE_RANGE, [
          moment().startOf('isoWeek'),
          moment().endOf('isoWeek')
        ])
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      }
    ]
  },
  APPROVAL: {
    key: 'APPROVAL',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      },
      {
        filter: FilterType.APPROVAL_STATUS,
        values: [
          PlanManagementApprovalStatus.NOT_SENT,
          PlanManagementApprovalStatus.SENT,
          PlanManagementApprovalStatus.REJECTED
        ],
        selectionLabel: '3 status'
      },
      {
        filter: FilterType.SUPPLIER_PAYMENT_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.SUPPLIER_PAYMENT_STATUS).fullSelectionName
      },
      {
        filter: FilterType.CLAIM_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CLAIM_STATUS).fullSelectionName
      }
    ]
  },
  CLAIM: {
    key: 'CLAIM',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      },
      {
        filter: FilterType.CLAIM_STATUS,
        values: [
          PlanManagementClaimStatus.UNCLAIMED,
          PlanManagementClaimStatus.SENT_TO_PAYMENTS,
          PlanManagementClaimStatus.REJECTED
        ],
        selectionLabel: '3 status'
      },
      {
        filter: FilterType.APPROVAL_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.APPROVAL_STATUS).fullSelectionName
      },
      {
        filter: FilterType.SUPPLIER_PAYMENT_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.SUPPLIER_PAYMENT_STATUS).fullSelectionName
      }
    ]
  },
  PAYMENT: {
    key: 'PAYMENT',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      },
      {
        filter: FilterType.SUPPLIER_PAYMENT_STATUS,
        values: [PlanManagementPaymentStatus.UNPAID, PlanManagementPaymentStatus.FAILED],
        selectionLabel: '2 status'
      },
      {
        filter: FilterType.APPROVAL_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.APPROVAL_STATUS).fullSelectionName
      }
    ]
  }
};

const InvoiceEmptyState = () => (
  <tr>
    <td colSpan={8} className="text-align-center mb-x-large">
      <div className="">
        <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
      <Text size="x2-large" color="secondary" weight="bold">
        No Invoices found.
      </Text>{' '}
      <br /> <br />
      <Text color="secondary">All invoices under this filter will appear here. </Text>
      <Text color="secondary">Try adjusting your filter.</Text>
    </td>
  </tr>
);

class PlanManagementSupplierDetailView extends Component<
  IPlanManagementSupplierDetailViewProps,
  IPlanManagementSupplierDetailViewState
> {
  state = {
    selectedTab: 'GENERAL',
    isAddModalOpen: false,
    isLoadingProvider: false,
    isLoading: false,
    page: 1,
    pageSize: 10,
    pageTimestamp: new Date(),
    isLoadingMore: false,
    isAddInvoiceModalOpen: false
  };

  private _backToProviderListing = () => {
    this.props.history.push(`/plan-management`, { refresh: true, selectedTab: 'PROVIDERS' });
  };

  private _openEditSupplierModal = () => {
    this.setState({ isAddModalOpen: true });
  };

  private _closeAddSupplierModal = async () => {
    this.setState({ isAddModalOpen: false });
  };

  private _formatFilterQuery = () => {
    const requestFilter: any = {
      planManagementSupplierIds: [this.props.planManagementSupplierDetail.planManagementSupplierId]
    };
    _.forEach(this.props.planManagementInvoiceFilters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'startDate':
            requestFilter.invoiceIssuedStartDate = filter.values[0].toDate();
            requestFilter.invoiceIssuedEndDate = filter.values[1].toDate();
            break;
          case 'customerUserIds':
            requestFilter.customerUserIds = _.map(filter.values, (customer) => {
              return customer.value;
            });
            break;
          case 'supplierPaymentStatus':
            requestFilter.supplierPaymentStatus = filter.values;
            break;
          case 'approvalStatus':
            requestFilter.approvalStatus = filter.values;
            break;
          case 'claimStatus':
            requestFilter.claimStatus = filter.values;
            break;
        }
      }
    });
    return requestFilter;
  };

  private _applyFilter = async (refreshList = false) => {
    this.setState({
      page: 1,
      isLoading: true,
      pageTimestamp: refreshList ? new Date() : this.state.pageTimestamp
    });
    const requestFilter = await this._formatFilterQuery();
    await this.props.doFetchPlanManagementInvoiceStatistics({
      planManagementSupplierId: this.props.match.params.planManagementSupplierId
    });
    await this.props.doFetchPlanManagementInvoices({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });
    this.setState({ isLoading: false });
  };

  private _applyFixedFilters = async (type) => {
    await this.props.setPlanManagementInvoiceFilters(INVOICELIST_FILTERCONFIGS[type].filters);
    await this._applyFilter();
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setPlanManagementInvoiceFilters(filters);
  };

  private _goToCustomer = (e, customerUserId) => {
    const { history } = this.props;
    e.stopPropagation();
    e.preventDefault();
    history.push(`/customer/details/${customerUserId}`);
  };

  private _goToInvoiceDetailView = (planManagementInvoiceId) => {
    this.props.history.push(`/plan-management/invoice/details/${planManagementInvoiceId}`);
  };

  private _fetchMorePlanManagementInvoices = async () => {
    const { doFetchPlanManagementInvoices } = this.props;
    await this.setState({ page: this.state.page + 1, isLoadingMore: true });
    const requestFilter = this._formatFilterQuery();
    await doFetchPlanManagementInvoices({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });
    this.setState({ isLoadingMore: false });
  };

  private _closeAddInvoiceModal = async (refreshListing = false) => {
    this.setState({ isAddInvoiceModalOpen: false });
    if (refreshListing) await this._applyFilter(true);
  };

  private _openAddInvoiceModal = () => {
    this.setState({ isAddInvoiceModalOpen: true });
  };

  async componentDidMount() {
    this.setState({ isLoadingProvider: true });
    await this.props.doFetchPlanManagementSupplierDetail({
      planManagementSupplierId: this.props.match.params.planManagementSupplierId
    });
    await this.props.setPlanManagementInvoiceFilters(INVOICELIST_FILTERCONFIGS.DEFAULT.filters);
    const requestFilter = this._formatFilterQuery();
    await this.props.doFetchPlanManagementInvoiceStatistics({
      planManagementSupplierId: this.props.planManagementSupplierDetail.planManagementSupplierId
    });
    await this.props.doFetchPlanManagementInvoices({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });
    this.setState({ isLoadingProvider: false });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.planManagementInvoiceFilters !== this.props.planManagementInvoiceFilters) {
      await this._applyFilter();
    }
  }

  render() {
    const {
      planManagementSupplierDetail,
      planManagementInvoiceStatistics,
      portalUser,
      planManagementInvoices
    } = this.props;
    const { isAddModalOpen, isAddInvoiceModalOpen, selectedTab, isLoading, isLoadingMore } = this.state;

    return (
      <CenteredLayout>
        <div id="content-container">
          <AddEditSupplierModal
            closeModal={this._closeAddSupplierModal}
            isOpen={isAddModalOpen}
            isEdit={true}
            selectedSupplier={planManagementSupplierDetail}
          />
          <AddEditInvoiceDetailsModal
            isOpen={isAddInvoiceModalOpen}
            modalMode={'ADD'}
            onClose={this._closeAddInvoiceModal}
            selectedSupplierId={
              planManagementSupplierDetail ? planManagementSupplierDetail.planManagementSupplierId : null
            }
          />
          <div className={'mv-large'}>
            <HyperlinkButton onClick={this._backToProviderListing}>Back to provider listing</HyperlinkButton>
          </div>
          {this.state.isLoadingProvider && (
            <>
              <div className="item-container">
                <div className="pb-medium">
                  <Text>Fetching provider data...</Text>
                </div>
              </div>
              <Skeleton loading={this.state.isLoadingProvider} />
            </>
          )}
          {planManagementSupplierDetail && !this.state.isLoadingProvider && (
            <div className={'flex-row'}>
              <div style={{ width: '250px' }} className={'mr-medium'}>
                <Title level={4} className="mb-none">
                  {planManagementSupplierDetail.supplierName}
                </Title>

                <SubTitle>Provider profile</SubTitle>
                <div className={'mt-medium'}>
                  <LeftNavItem
                    filterConfig={{ key: 'GENERAL', title: 'General Information' }}
                    isSelected={selectedTab === 'GENERAL'}
                    onClick={() => this.setState({ selectedTab: 'GENERAL' })}
                  />
                  <LeftNavItem
                    filterConfig={{ key: 'INVOICES', title: 'Invoices' }}
                    isSelected={selectedTab === 'INVOICES'}
                    onClick={() => this.setState({ selectedTab: 'INVOICES' })}
                  />
                </div>
              </div>
              {selectedTab === 'GENERAL' && (
                <div className={'bg-white p-large width-full rounded-big bordered'}>
                  <div className={'flex-row align-center justify-between'}>
                    <Title level={4} className="mb-none">
                      General Information
                    </Title>
                    <SecondaryButton
                      size="large"
                      className={'ml-large'}
                      onClick={this._openEditSupplierModal}
                      icon={'edit'}
                    >
                      Edit details
                    </SecondaryButton>
                  </div>
                  <Row gutter={24} className={'mt-large'}>
                    <Col span={24}>
                      <SubTitle>Provider name</SubTitle>
                      {planManagementSupplierDetail.supplierName ? (
                        planManagementSupplierDetail.supplierName
                      ) : (
                        <Text color={'secondary'}>N/A</Text>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={24} className={'mt-large'}>
                    <Col span={12}>
                      <SubTitle>ABN</SubTitle>
                      {planManagementSupplierDetail.abnNumber ? (
                        planManagementSupplierDetail.abnNumber
                      ) : (
                        <Text color={'secondary'}>N/A</Text>
                      )}
                    </Col>
                    <Col span={12}>
                      <SubTitle>Preferred payment method</SubTitle>
                      {planManagementSupplierDetail.preferredPaymentMethod ? (
                        planManagementSupplierDetail.preferredPaymentMethod
                      ) : (
                        <Text color={'secondary'}>N/A</Text>
                      )}
                    </Col>
                  </Row>
                  <Row gutter={24} className={'mt-large'}>
                    <Col span={12}>
                      <SubTitle>Contact No.</SubTitle>
                      {planManagementSupplierDetail.contactNumber ? (
                        planManagementSupplierDetail.contactNumber
                      ) : (
                        <Text color={'secondary'}>N/A</Text>
                      )}
                    </Col>
                    <Col span={12}>
                      <SubTitle>Email</SubTitle>
                      {planManagementSupplierDetail.email ? (
                        planManagementSupplierDetail.email
                      ) : (
                        <Text color={'secondary'}>N/A</Text>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} className={'mt-large'}>
                      <SubTitle>Notes</SubTitle>
                      {planManagementSupplierDetail.additionalNote ? (
                        <Text style={{ whiteSpace: 'pre-line' }}>{planManagementSupplierDetail.additionalNote}</Text>
                      ) : (
                        <Text color={'secondary'}>N/A</Text>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
              {selectedTab === 'INVOICES' && (
                <div className={'bg-white p-large width-full rounded-big bordered'}>
                  <div className={'flex-row mb-large align-center justify-between'}>
                    <Title level={4} className="mb-none">
                      Invoices for {planManagementSupplierDetail.supplierName}
                    </Title>
                    <PrimaryButton
                      size="large"
                      className={'ml-large'}
                      onClick={this._openAddInvoiceModal}
                      icon={'plus'}
                    >
                      New invoice
                    </PrimaryButton>
                  </div>
                  <div className={'flex-row mt-medium'}>
                    <StatisticDisplayCard
                      value={
                        planManagementInvoiceStatistics ? Number(planManagementInvoiceStatistics.pendingApproval) : 0
                      }
                      className={'mr-large'}
                      isLinkDisplayed={
                        planManagementInvoiceStatistics && Number(planManagementInvoiceStatistics.pendingApproval) > 0
                      }
                      linkFunction={() => this._applyFixedFilters('APPROVAL')}
                      titleText={
                        <>
                          Invoice pending <b>approval</b>
                        </>
                      }
                      linkText={'View all awaiting approval'}
                    />
                    <StatisticDisplayCard
                      value={
                        planManagementInvoiceStatistics ? Number(planManagementInvoiceStatistics.awaitingClaim) : 0
                      }
                      className={'mr-large'}
                      isLinkDisplayed={
                        planManagementInvoiceStatistics && Number(planManagementInvoiceStatistics.awaitingClaim) > 0
                      }
                      linkFunction={() => this._applyFixedFilters('CLAIM')}
                      titleText={
                        <>
                          Invoice awaiting <b>claim</b>
                        </>
                      }
                      linkText={'View all awaiting claim'}
                    />
                    <StatisticDisplayCard
                      value={
                        planManagementInvoiceStatistics ? Number(planManagementInvoiceStatistics.awaitingPayment) : 0
                      }
                      isLinkDisplayed={
                        planManagementInvoiceStatistics && Number(planManagementInvoiceStatistics.awaitingPayment) > 0
                      }
                      linkFunction={() => this._applyFixedFilters('PAYMENT')}
                      titleText={
                        <>
                          Invoice awaiting <b>payment</b>
                        </>
                      }
                      linkText={'View all awaiting payment'}
                    />
                  </div>

                  <div className={'mv-x-large'}>
                    <FilterSection
                      availableFilters={availableFilters}
                      filters={this.props.planManagementInvoiceFilters}
                      onChangeFilter={this._onChangeFilter}
                      displayTimezone={portalUser.timezone}
                    />
                  </div>

                  <div>
                    <table className={'width-full plan-management-listing'}>
                      <tbody>
                        <tr className={'bordered-bottom border-color-quaternary'}>
                          <th style={{ width: '15%' }} className={'pb-small pl-medium pr-small'}>
                            <SubTitle>Number/date</SubTitle>
                          </th>
                          <th style={{ width: '30%' }} className={'pb-small pr-small'}>
                            <SubTitle>Customer</SubTitle>
                          </th>
                          <th style={{ width: '40%' }} className={'pb-small pr-small'}>
                            {/*<SubTitle>Approval status</SubTitle>*/}
                            <SubTitle>Status</SubTitle>
                          </th>
                          {/*<th style={{ width: '10%' }} className={'pb-small pr-small'}>*/}
                          {/*  <SubTitle>Claim status</SubTitle>*/}
                          {/*</th>*/}
                          {/*<th style={{ width: '10%' }} className={'pb-small pr-small'}>*/}
                          {/*  <SubTitle>Supplier status</SubTitle>*/}
                          {/*</th>*/}
                          <th style={{ width: '15%' }} className={'pb-small text-align-right pr-medium'}>
                            <SubTitle>Amount</SubTitle>
                          </th>
                        </tr>
                        {_.isEmpty(planManagementInvoices) ? (
                          <InvoiceEmptyState />
                        ) : isLoading ? (
                          <tr>
                            <td colSpan={8}>
                              <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} />
                            </td>
                          </tr>
                        ) : (
                          <>
                            <InfiniteScrollLoading
                              hasMore={planManagementInvoices.length >= this.state.page * this.state.pageSize}
                              loadingElementId={'content-container'}
                              loadMore={this._fetchMorePlanManagementInvoices}
                              loadingOffSet={100}
                              loaderColSpan={4}
                            >
                              {_.map(planManagementInvoices, (invoice, key) => {
                                return (
                                  <tr
                                    className={'evenodd cursor-pointer'}
                                    onClick={() => this._goToInvoiceDetailView(invoice.planManagementInvoiceId)}
                                    key={key}
                                  >
                                    <td className={'pv-medium pl-medium pr-small'}>
                                      <Text color={'secondary'}>{invoice.invoiceNumber}</Text>
                                      <br />
                                      {moment.tz(invoice.invoiceIssuedDate, portalUser.timezone).format('DD/MM/YYYY')}
                                    </td>
                                    {/*<td className={'pv-medium pr-small'}>{invoice.invoiceNumber}</td>*/}
                                    <td className={'pv-medium pr-small'}>
                                      <div className={'flex-row align-center mv-small'}>
                                        <Avatar size="default" src={invoice.attachmentUrl} className={'mr-small'} />
                                        <div>
                                          <Text>
                                            {PermissionUtils.validatePermission(
                                              'ViewCustomerProfile',
                                              portalUser.permissions.permissionRoles
                                            ) ? (
                                              <HyperlinkButton
                                                onClick={(e) => this._goToCustomer(e, invoice.customerUserId)}
                                                color="black"
                                              >
                                                {invoice.firstName} {invoice.lastName}
                                              </HyperlinkButton>
                                            ) : (
                                              invoice.firstName + ' ' + invoice.lastName
                                            )}
                                          </Text>
                                        </div>
                                      </div>
                                    </td>
                                    <td className={'pv-medium pr-small'}>
                                      {/*<ApprovalStatusTag status={invoice.approvalStatus} />*/}
                                      <Text color={'secondary'}>
                                        Approval status: <ApprovalStatusTag status={invoice.approvalStatus} />
                                        <br />
                                        Claim status: <ClaimStatusTag status={invoice.claimStatus} />
                                        <br />
                                        Supplier status: <SupplierStatusTag status={invoice.supplierPaymentStatus} />
                                      </Text>
                                    </td>
                                    {/*<td className={'pv-medium pr-small'}>*/}
                                    {/*  <ClaimStatusTag status={invoice.claimStatus} />*/}
                                    {/*</td>*/}
                                    {/*<td className={'pv-medium pr-small'}>*/}
                                    {/*  <SupplierStatusTag status={invoice.supplierPaymentStatus} />*/}
                                    {/*</td>*/}
                                    <td className={'pv-medium text-align-right pr-medium'}>
                                      {CommonUtils.formatPrice(invoice.invoiceTotal)}
                                    </td>
                                  </tr>
                                );
                              })}
                            </InfiniteScrollLoading>
                            {isLoadingMore && (
                              <tr>
                                <td colSpan={8}>
                                  <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} />
                                </td>
                              </tr>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </CenteredLayout>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  planManagementSupplierDetail: state.planManagementStore.planManagementSupplierDetail,
  planManagementInvoiceStatistics: state.planManagementStore.planManagementInvoiceStatistics,
  planManagementInvoices: state.planManagementStore.planManagementInvoices,
  planManagementInvoiceFilters: state.planManagementStore.planManagementInvoiceFilters
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchPlanManagementSupplierDetail: dispatch.planManagementStore.doFetchPlanManagementSupplierDetail,
  doFetchPlanManagementInvoiceStatistics: dispatch.planManagementStore.doFetchPlanManagementInvoiceStatistics,
  setPlanManagementInvoiceFilters: dispatch.planManagementStore.setPlanManagementInvoiceFilters,
  doFetchPlanManagementInvoices: dispatch.planManagementStore.doFetchPlanManagementInvoices
});

export default connect(
  mapState,
  mapDispatch
)(PlanManagementSupplierDetailView);
