import React, { Component } from 'react';
import { Col, Form, Input, Radio, Row, Skeleton, Avatar, notification } from 'antd';
import { Card, ProgressBar } from '@blueprintjs/core';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';

import { IAddCustomerDetailWizard, ICustomer } from 'src/interfaces/customer-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';

import ActionModal from 'common-components/modal/ActionModal';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import LegalGuardianItemRow from 'views/customers/listing/components/LegalGuardianItemRow';

const { Search } = Input;

interface IAddLegalGuardianModalProps extends FormComponentProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCustomer?: ICustomer;
  isCreateNewCustomer: boolean;
  doFetchGuardianLite: typeof dispatch.customersStore.doFetchGuardianLite;
  setAddCustomerDetailWizard: typeof dispatch.customersStore.setAddCustomerDetailWizard;
  setAddLegalGuardianDetailWizard: typeof dispatch.customersStore.setAddLegalGuardianDetailWizard;
  addCustomerDetailWizard: typeof state.customersStore.addCustomerDetailWizard;
  addLegalGuardianDetailWizard: typeof state.customersStore.addLegalGuardianDetailWizard;
  doCheckCustomerEmailConflict: typeof dispatch.customersStore.doCheckCustomerEmailConflict;
}

interface IAddLegalGuardianModalState {
  customerDetails: IAddCustomerDetailWizard; // use this if creating new customer
  selectedCustomer: ICustomer;
  isLoading: boolean;
  isSearching: boolean;
  step: number;
  canManuallyClose: boolean;
  selectedGuardian: any;
  isExistingGuardian: boolean;
  searchLegalGuardian: string;
  foundGuardians: any;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  isLoadingInfiniteScrolling: boolean;
  relationship: string | null;
  duplicateUser: any;
}

class AddLegalGuardianModal extends Component<IAddLegalGuardianModalProps, IAddLegalGuardianModalState> {
  state: IAddLegalGuardianModalState = {
    canManuallyClose: true,
    isLoading: false,
    isSearching: false,
    step: 1,
    customerDetails: this.props.addCustomerDetailWizard,
    selectedCustomer: this.props.selectedCustomer,
    selectedGuardian: null,
    isExistingGuardian: true,
    searchLegalGuardian: '',
    foundGuardians: [],
    page: 1,
    pageSize: 10,
    pageTimestamp: new Date(),
    isLoadingInfiniteScrolling: false,
    relationship: null,
    duplicateUser: null
  };

  private _updateRelationship = (relationship) => {
    this.setState({ relationship: relationship });
  };

  private _updateLegalGuardianDetail = (fieldName, value) => {
    const { selectedGuardian } = this.state;
    this.setState({ selectedGuardian: { ...selectedGuardian, [fieldName]: _.trim(value) } });
  };

  private _isHasMore = () => {
    const { foundGuardians } = this.state;
    const { addLegalGuardianDetailWizard, addCustomerDetailWizard } = this.props;

    let lengthFilteredOut = !_.isEmpty(addLegalGuardianDetailWizard)
      ? addLegalGuardianDetailWizard.length
      : addCustomerDetailWizard !== null && addCustomerDetailWizard.hasGuardians
      ? addCustomerDetailWizard.guardians.length
      : 0;

    return foundGuardians.length + lengthFilteredOut >= this.state.page * this.state.pageSize;
  };

  private _filterGuardians = (fetchedGuardians) => {
    const { addLegalGuardianDetailWizard, addCustomerDetailWizard } = this.props;
    let finalGuardians = fetchedGuardians;

    if (!_.isEmpty(addLegalGuardianDetailWizard)) {
      finalGuardians = _.filter(
        finalGuardians,
        (guardian) =>
          !_.find(addLegalGuardianDetailWizard, (exitingGuardian) => exitingGuardian.email === guardian.email)
      );
    } else if (addCustomerDetailWizard !== null && addCustomerDetailWizard.hasGuardians) {
      finalGuardians = _.filter(
        finalGuardians,
        (guardian) =>
          !_.find(addCustomerDetailWizard.guardians, (exitingGuardian) => exitingGuardian.email === guardian.email)
      );
    }

    return finalGuardians;
  };

  private _loadContent = async () => {
    const { doFetchGuardianLite } = this.props;
    const payload = {
      search: this.state.searchLegalGuardian,
      sort: [['firstName', 'asc'], ['lastName', 'asc']],
      userType: 'PARENT_GUARDIANS',
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp
    };
    try {
      let result: any = await doFetchGuardianLite(payload);

      this.setState({ isLoading: false, isSearching: false, foundGuardians: this._filterGuardians(result) });
      this.setState({ isSearching: false });
    } catch (e) {
      this.setState({ isLoading: false, isSearching: false });
      throw e;
    }
  };

  private _searchLegalGuardian = async () => {
    try {
      const payload = {
        page: this.state.page,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
        search: this.state.searchLegalGuardian
      };
      const { doFetchGuardianLite } = this.props;
      this.setState({ isSearching: true });

      let result: any = await doFetchGuardianLite(payload);

      this.setState({ isSearching: false, foundGuardians: this._filterGuardians(result) });
      this.setState({ isSearching: false });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  debounceSearch = _.debounce(this._searchLegalGuardian, 250);

  private _handleSearchLegalGuardianName = (event) => {
    let searchText = event.target.value;
    this.setState({ searchLegalGuardian: searchText, isSearching: true });
    if (_.isEmpty(searchText)) {
      this._loadContent();
    } else if (searchText.length >= 3) {
      this.debounceSearch();
    }
  };

  private _onPreviousStep = () => {
    this.setState({ step: 1 });
  };

  private _fetchMoreGuardians = async () => {
    try {
      const { doFetchGuardianLite } = this.props;
      this.setState({ isLoadingInfiniteScrolling: true, page: this.state.page + 1 });
      let result: any = await doFetchGuardianLite({
        page: this.state.page,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp
      });
      let finalGuardians = this.state.foundGuardians.concat(result);
      this.setState({ foundGuardians: this._filterGuardians(finalGuardians) });
      this.setState({ isLoadingInfiniteScrolling: false });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  private _changeIsExistingGuardian = (value) => {
    this.setState({ selectedGuardian: null, isExistingGuardian: value });
  };

  private _onSelectDifferentGuardian = () => {
    this.setState({ selectedGuardian: null });
  };

  private _setSelectedGuardians = async (selectedUserId) => {
    const { foundGuardians } = this.state;

    const toBeSetSelectedGuardian = _.find(foundGuardians, (guardian) => guardian.userId === selectedUserId);

    if (toBeSetSelectedGuardian) {
      this.setState({ selectedGuardian: toBeSetSelectedGuardian });
    }
  };

  private _onAddLegalGuardian = async () => {
    try {
      const {
        form,
        setAddCustomerDetailWizard,
        setAddLegalGuardianDetailWizard,
        isCreateNewCustomer,
        addLegalGuardianDetailWizard,
        doCheckCustomerEmailConflict
      } = this.props;
      const { customerDetails, isExistingGuardian, selectedCustomer } = this.state;
      let isFormValid = true;
      let selectedGuardian = this.state.selectedGuardian;

      form.validateFields(async (err, value) => {
        if (err) {
          isFormValid = false;
        }
      });

      if (isFormValid) {
        if (!isExistingGuardian) {
          const firstName = form.getFieldValue('firstName');
          const lastName = form.getFieldValue('lastName');
          const email = form.getFieldValue('email');
          const relation = form.getFieldValue('relationship');
          const isExisting = isExistingGuardian; //*Should be false here
          selectedGuardian = { firstName, lastName, email, relation, isExisting };
          let duplicateFound;

          // Check for local duplicates
          if (isCreateNewCustomer) {
            duplicateFound = _.find(customerDetails.guardians, (guardian) => guardian.email === email);
          } else {
            duplicateFound = _.find(selectedCustomer.guardiansInfo, (guardian) => guardian.email === email);
          }

          // Check dulpicates in db
          if (!duplicateFound) {
            const conflicts = await doCheckCustomerEmailConflict({ email: email });
            if (!_.isEmpty(conflicts)) {
              duplicateFound = {
                ...conflicts[0],
                email: email
              };
            }
          }

          if (duplicateFound) {
            this.setState({
              step: 2,
              selectedGuardian,
              duplicateUser: duplicateFound
            });
          } else {
            if (selectedCustomer) {
              // add legal guardian from customer profile
              const guardians = addLegalGuardianDetailWizard;
              guardians.push(selectedGuardian);
              setAddLegalGuardianDetailWizard(guardians);
            } else {
              customerDetails.guardians.push(selectedGuardian);
              setAddCustomerDetailWizard(customerDetails);
            }
            this._onCloseModal();
          }
        } else {
          if (selectedGuardian) {
            const relation = form.getFieldValue('relationship');
            selectedGuardian.relation = relation;
            selectedGuardian.isExisting = isExistingGuardian; //*Should be true here
            if (isCreateNewCustomer) {
              customerDetails.hasGuardians = true;
              customerDetails.guardians.push(selectedGuardian);
              setAddCustomerDetailWizard(customerDetails);
              this._onCloseModal();
            } else {
              // add legal guardian from customer profile
              const guardians = addLegalGuardianDetailWizard;
              guardians.push(selectedGuardian);
              setAddLegalGuardianDetailWizard(guardians);
              this._onCloseModal();
            }
          }
        }
      }
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  private _onAddExistingDuplicateLegalGuardian = async () => {
    const { customerDetails, duplicateUser } = this.state;
    const {
      setAddCustomerDetailWizard,
      setAddLegalGuardianDetailWizard,
      isCreateNewCustomer,
      addLegalGuardianDetailWizard
    } = this.props;
    if (isCreateNewCustomer) {
      customerDetails.guardians.push(duplicateUser);
      customerDetails.hasGuardians = true;
      setAddCustomerDetailWizard(customerDetails);
      this._onCloseModal();
    } else {
      // add legal guardian from customer profile
      const guardians = addLegalGuardianDetailWizard;
      guardians.push(duplicateUser);
      setAddLegalGuardianDetailWizard(guardians);
      this._onCloseModal();
    }
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    this._reset();
    onClose();
  };

  private _reset = () =>
    this.setState({
      step: 1,
      canManuallyClose: true
    });

  private _renderView = () => {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const {
      state,
      _onCloseModal,
      _onSelectDifferentGuardian,
      _onAddLegalGuardian,
      _onPreviousStep,
      _onAddExistingDuplicateLegalGuardian
    } = this;
    const { isExistingGuardian, selectedGuardian, foundGuardians, step, duplicateUser } = state;
    if (step === 1) {
      return (
        <>
          <div className="mb-large">
            <Row className="mb-large">
              <Col>
                <Form.Item className={'m-none'}>
                  {getFieldDecorator('isExistingGuardian', {
                    initialValue: isExistingGuardian,
                    rules: [
                      {
                        required: true,
                        message: 'Please select an option.'
                      }
                    ]
                  })(
                    <Radio.Group
                      defaultValue={false}
                      onChange={(value) => this._changeIsExistingGuardian(value.target.value)}
                    >
                      <Radio className="mb-medium" value={true}>
                        Select an existing guardian
                      </Radio>
                      <br />
                      <Radio value={false}>Add a new legal guardian</Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>

          {selectedGuardian && form.getFieldValue('isExistingGuardian') ? (
            <>
              <div className="pb-small">
                <SubTitle>Selected Legal guardian</SubTitle>
              </div>
              <div className="flex-row align-center">
                <Avatar size="large" icon="user" className="mr-medium" src={selectedGuardian.attachmentUrl} />
                <Text weight="bold">{selectedGuardian.firstName + ' ' + selectedGuardian.lastName}</Text>
                <Text
                  className="pl-medium text-underline"
                  onClick={_onSelectDifferentGuardian}
                  size="small"
                  color="red-darker"
                >
                  Select a different legal guardian{' '}
                </Text>
              </div>

              <div className="pt-medium">
                <div className="pb-4">
                  <SubTitle>Relationship</SubTitle>
                </div>

                <Form.Item className={'m-none'}>
                  {getFieldDecorator('relationship', {
                    initialValue: this.state.relationship,
                    rules: [
                      {
                        required: true,
                        message: 'Please enter a relationship'
                      }
                    ]
                  })(
                    <Input
                      style={{ width: '250px' }}
                      onChange={(value) => this._updateRelationship(value.target.value)}
                      type="text"
                      size="large"
                      placeholder="Enter relationship here..."
                    />
                  )}
                </Form.Item>
              </div>
            </>
          ) : form.getFieldValue('isExistingGuardian') && !selectedGuardian ? (
            <>
              <div className="flex-row mb-medium">
                <Search
                  placeholder={'Search for an existing parent/guardian...'}
                  value={this.state.searchLegalGuardian}
                  onChange={(e) => this._handleSearchLegalGuardianName(e)}
                  loading={this.state.isLoading}
                  size="large"
                  allowClear
                />
              </div>

              {this.state.isLoading && (
                <Card className="anim-fade-in-fast">
                  <div className="mb-small">
                    <Text>Fetching guardians...</Text>
                  </div>
                  <ProgressBar animate={true} />
                </Card>
              )}

              {!this.state.isLoading && foundGuardians.length > 0 && (
                <div
                  style={{ overflowY: 'auto', overflowX: 'hidden', height: '40vh' }}
                  className="mb-x-large bordered anim-slide-down rounded"
                  id="scroll-modal"
                >
                  <InfiniteScrollLoading
                    hasMore={this._isHasMore()}
                    loadingElementId={'scroll-modal'}
                    loadMore={this._fetchMoreGuardians}
                    loaderColSpan={7}
                    loadingOffSet={60}
                  >
                    {_.map(foundGuardians, (legalGuardian, i) => {
                      return (
                        <LegalGuardianItemRow
                          legalGuardianName={legalGuardian.firstName + ' ' + legalGuardian.lastName}
                          attachmentUrl={legalGuardian.attachmentUrl}
                          email={legalGuardian.email}
                          key={i}
                          legalGuardianId={legalGuardian.userId}
                          setSelectedGuardians={() => this._setSelectedGuardians(legalGuardian.userId)}
                        />
                      );
                    })}
                  </InfiniteScrollLoading>
                  {this.state.isLoadingInfiniteScrolling && (
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                  )}
                </div>
              )}

              <div className="flex-row mb-medium">
                <HyperlinkButton className="text-underline">
                  If you can’t find who you are looking for add a new parent/guardian profile instead
                </HyperlinkButton>
              </div>
            </>
          ) : (
            <>
              <div className="mb-large flex-row flex-wrap">
                <div style={{ maxWidth: '300px' }} className="mr-large">
                  <SubTitle>First Name</SubTitle>
                  <Form.Item className={'m-none'}>
                    {getFieldDecorator('firstName', {
                      initialValue: selectedGuardian ? selectedGuardian.firstName : null,
                      rules: [
                        {
                          required: true,
                          message: 'Please enter a first Name'
                        }
                      ]
                    })(
                      <Input
                        style={{ width: '250px' }}
                        onChange={(value) => this._updateLegalGuardianDetail('firstName', value.target.value)}
                        type="text"
                        size="large"
                        placeholder="Input name..."
                      />
                    )}
                  </Form.Item>
                </div>
                <div>
                  <SubTitle>Last Name</SubTitle>
                  <Form.Item className={'m-none'}>
                    {getFieldDecorator('lastName', {
                      initialValue: selectedGuardian ? selectedGuardian.lastName : null,
                      rules: [
                        {
                          required: true,
                          message: 'Please enter a last Name'
                        }
                      ]
                    })(
                      <Input
                        style={{ width: '250px' }}
                        onChange={(value) => this._updateLegalGuardianDetail('lastName', value.target.value)}
                        type="text"
                        size="large"
                        placeholder="Input name..."
                      />
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="mb-large flex-row flex-wrap">
                <div style={{ maxWidth: '300px' }} className="mr-large">
                  <SubTitle>Email</SubTitle>
                  <Form.Item className={'m-none'}>
                    {getFieldDecorator('email', {
                      initialValue: selectedGuardian ? selectedGuardian.email : null,
                      rules: [
                        {
                          required: true,
                          message: 'Please enter an email address'
                        },
                        {
                          type: 'email',
                          message: 'The input is not valid email!'
                        }
                      ]
                    })(
                      <Input
                        style={{ width: '250px' }}
                        onChange={(value) => this._updateLegalGuardianDetail('email', value.target.value)}
                        size="large"
                        placeholder="Enter email here..."
                      />
                    )}
                  </Form.Item>
                </div>
              </div>
              <div className="mb-large flex-row flex-wrap">
                <div style={{ maxWidth: '300px' }} className="mr-large">
                  <SubTitle>Relationship</SubTitle>
                  <Form.Item className={'m-none'}>
                    {getFieldDecorator('relationship', {
                      initialValue: selectedGuardian ? selectedGuardian.relationship : null,
                      rules: [
                        {
                          required: true,
                          message: 'Please enter a relationship'
                        }
                      ]
                    })(
                      <Input
                        style={{ width: '250px' }}
                        onChange={(value) => this._updateLegalGuardianDetail('relationship', value.target.value)}
                        type="text"
                        size="large"
                        placeholder="Enter relationship here..."
                      />
                    )}
                  </Form.Item>
                </div>
              </div>
            </>
          )}

          <div className="pv-medium width-full" style={{ position: 'sticky', bottom: 0 }}>
            <Row gutter={0} type="flex" align="middle" className="bg-transparent">
              <Col className="bg-transparent" span={24}>
                <div className="text-align-right pv-medium">
                  <SecondaryButton size="large" className="mr-medium" onClick={_onCloseModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size="large" onClick={_onAddLegalGuardian}>
                    Add legal guardian
                  </PrimaryButton>
                </div>
              </Col>
            </Row>
          </div>
        </>
      );
    } else if (step === 2) {
      let titleMessage;
      let descriptionMessage;
      let descriptionMessage2;

      if (duplicateUser.userType === 'customer') {
        titleMessage = 'The following user who has already been added to your workspace as a customer';
        descriptionMessage =
          'Customers cannot be assigned as legal guardians. Please add another user as a legal guardian.';
      } else if (duplicateUser.userType === 'legalGuardian') {
        titleMessage =
          'The following user who has already been added to your workspace as a legal guardian has the same email. ';
        descriptionMessage =
          'If this is the user you are attempting to add as the legal guardian for this customer then you can assign them as the legal guardian by pressing the button below';

        descriptionMessage2 = 'If not please go back and double check the email address you entered';
      } else {
        // exist locally
        titleMessage = (
          <div>
            You have already added a Legal Guardian with the email address{' '}
            <span className={'text-weight-bolder'}>{duplicateUser.email}</span> for this customer.
          </div>
        );
        descriptionMessage = 'Please assign a unique email for each legal guardian you are adding for this customer.';
      }
      return (
        <>
          <div>
            <Paragraph>{titleMessage}</Paragraph>
          </div>
          <div className="flex-row align-center">
            <Avatar size="large" icon="user" className="mr-medium" src={duplicateUser.attachmentUrl} />
            <div>
              <Text weight="bold">{duplicateUser.firstName + ' ' + duplicateUser.lastName}</Text> <br />
              <Text size="small" color="secondary">
                {duplicateUser.email}
              </Text>
            </div>
          </div>
          <div className="pt-medium">
            <Paragraph>{descriptionMessage}</Paragraph>
            <Paragraph>{descriptionMessage2}</Paragraph>
          </div>

          <div className="width-full" style={{ position: 'sticky', bottom: 0 }}>
            <Row gutter={0} type="flex" align="middle" className="bg-transparent">
              <Col className="bg-transparent" span={24}>
                <div className="text-align-right pv-none">
                  <SecondaryButton size="large" className="mr-medium" onClick={_onPreviousStep}>
                    Back
                  </SecondaryButton>
                  {duplicateUser.userType === 'legalGuardian' && (
                    <PrimaryButton size="large" onClick={_onAddExistingDuplicateLegalGuardian}>
                      Add legal guardian
                    </PrimaryButton>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  };

  render() {
    let { isOpen } = this.props;
    const { step } = this.state;
    const title = step === 1 ? 'Add a legal guardian' : 'Email duplicate found';
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="large"
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        {this._renderView()}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  addCustomerDetailWizard: state.customersStore.addCustomerDetailWizard,
  addLegalGuardianDetailWizard: state.customersStore.addLegalGuardianDetailWizard
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGuardianLite: dispatch.customersStore.doFetchGuardianLite,
  setAddCustomerDetailWizard: dispatch.customersStore.setAddCustomerDetailWizard,
  setAddLegalGuardianDetailWizard: dispatch.customersStore.setAddLegalGuardianDetailWizard,
  doCheckCustomerEmailConflict: dispatch.customersStore.doCheckCustomerEmailConflict
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IAddLegalGuardianModalProps>()(AddLegalGuardianModal));
