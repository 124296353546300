import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { ErrorSVG, SuccessSVG } from 'assets/UndrawSVG';
import { Paragraph } from 'common-components/typography';
import { Form, notification } from 'antd';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { PrimaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import PlanManagementBillingLineItem from 'views/plan-management/components/PlanManagementBillingLineItem';
import AddNDISLineItemModal from 'views/bookings/details/sections/content-section/tabs-panel/AddNDISLineItemModal';
import { ndisHelper } from 'variables/data-helpers';
import ErrorPriceExceedingInvoiceModal from 'views/plan-management/components/ErrorPriceExceedingInvoiceModal';

interface IEditLineItemActionModalProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  type?: string;
  selectedItem: typeof state.planManagementStore.selectedItem;
  doEditInvoiceLineItem: typeof dispatch.planManagementStore.doEditInvoiceLineItem;
}

class EditInvoiceLineItemActionModal extends Component<IEditLineItemActionModalProps, any> {
  state = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    isOpenAddLineItem: false,
    isErrorPriceExceedingInvoice: false,
    localLineItem: this.props.selectedItem
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({
        isLoading: false,
        isSuccess: false,
        isError: false,
        isOpenAddLineItem: false,
        isErrorPriceExceedingInvoice: false
      });
      onClose();
    }
  };

  private _onChangeSupportItemNumber = (value) => {
    const { localLineItem } = this.state;
    if (value) {
      const newSupportItem = ndisHelper.getBySupportItemNumber(value);
      this.setState({
        localLineItem: { ...localLineItem, supportItemNumber: value, unit: newSupportItem.UnitOfMeasure }
      });
      this._closeLineItemModal();
    }
  };

  private _closeLineItemModal = () => {
    this.setState({ isOpenAddLineItem: false });
  };

  private _openLineItemModal = () => {
    this.setState({ isOpenAddLineItem: true });
  };

  private _saveInvoiceLineItem = async (payload) => {
    const { doEditInvoiceLineItem, selectedItem } = this.props;
    try {
      this.setState({ isLoading: true });
      await doEditInvoiceLineItem({
        ...payload,
        invoiceId: selectedItem.planManagementInvoiceId,
        updateInvoiceDetail: true
      });
      this.setState({ isLoading: false });
      this._onCloseModal();
    } catch (e) {
      if (e.meta.message === 'Line item sum total cannot exceed invoice total') {
        this.setState({ isErrorPriceExceedingInvoice: true, isLoading: false });
      } else {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
        this.setState({ isLoading: false });
      }
    }
  };

  componentDidUpdate(prevProps: Readonly<IEditLineItemActionModalProps>, prevState: Readonly<any>, snapshot?: any) {
    if (prevProps.selectedItem !== this.props.selectedItem) {
      this.setState({ localLineItem: this.props.selectedItem });
    }
  }

  render() {
    const { isOpen } = this.props;
    const { localLineItem, isLoading } = this.state;

    return (
      <ActionModal
        title="Edit Line Item"
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="x3-large"
        verticalAlignment="highest"
      >
        <AddNDISLineItemModal
          closeLineItemModal={this._closeLineItemModal}
          isOpen={this.state.isOpenAddLineItem}
          hasABTLineItems={false}
          hasPTNLCLineItems={false}
          onChangeSupportItemNumber={this._onChangeSupportItemNumber}
          hideWarningFunding={true}
          filterFunded={false}
          isFilterOutTravelItem={false}
        />
        <ErrorPriceExceedingInvoiceModal
          isOpen={this.state.isErrorPriceExceedingInvoice}
          closeModal={() => this.setState({ isErrorPriceExceedingInvoice: false })}
          includesGst={localLineItem ? localLineItem.includesGst : false}
        />
        {!this.state.isLoading && !this.state.isSuccess && !this.state.isError && localLineItem && (
          <div className="text-align-left mb-large">
            <PlanManagementBillingLineItem
              lineItemIndex={0}
              lineItem={localLineItem}
              displayMode={'EDIT'}
              disabledEdit={false}
              openLineItemModal={this._openLineItemModal}
              closeLineItemModal={this._closeLineItemModal}
              saveInvoiceLineItem={this._saveInvoiceLineItem}
              isLoading={isLoading}
              cancelEdit={this._onCloseModal}
            />
          </div>
        )}
        {this.state.isLoading && <SpinningLoader size={100} message={'Loading'} />}
        {!this.state.isLoading && this.state.isSuccess && (
          <div className="text-align-center">
            <div className="pv-medium">
              <img src={SuccessSVG} alt={'Success'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium">
              <Paragraph>Line Item updated successfully.</Paragraph>
            </div>
          </div>
        )}

        {!this.state.isSuccess && !this.state.isError && (
          <ActionModalFooter>
            {/*<GhostButton*/}
            {/*  size="large"*/}
            {/*  onClick={this._onCloseModal}*/}
            {/*  className="mr-medium"*/}
            {/*  disabled={this.state.isLoading}*/}
            {/*>*/}
            {/*  Cancel*/}
            {/*</GhostButton>*/}
            {/*<PrimaryButton size="large" onClick={this._onSubmitEdit} loading={this.state.isLoading}>*/}
            {/*  Save*/}
            {/*</PrimaryButton>*/}
          </ActionModalFooter>
        )}
        {this.state.isSuccess && !this.state.isError && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {!this.state.isLoading && this.state.isError && (
          <div className="text-align-center">
            <div className="pv-medium">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium">
              <Paragraph>An error has occured.</Paragraph>
            </div>
          </div>
        )}

        {this.state.isError && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedItem: state.planManagementStore.selectedItem
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditInvoiceLineItem: dispatch.planManagementStore.doEditInvoiceLineItem
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IEditLineItemActionModalProps>()(EditInvoiceLineItemActionModal));
