import { Form, Input, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import { TimeBasedAlertConfigType } from 'utilities/enum-utils';

interface ITimeBasedAlertsConfigurationModalProps extends FormComponentProps {
  isOpen: any;
  configType: TimeBasedAlertConfigType;
  onClose: () => void;
  dayConfigured?: number;
  doUpdateAlertConfiguration: typeof dispatch.accountStore.doUpdateAlertConfiguration;
}

interface ITimeBasedAlertsConfigurationModalState {
  title: any;
  content: string;
  isEditing: boolean;
  isPlanManagementOn: boolean;
  inputValue: number;
  invoiceEmail: string;
  isSaveDisabled: boolean;
}

class TimeBasedAlertsConfigurationModal extends Component<
  ITimeBasedAlertsConfigurationModalProps,
  ITimeBasedAlertsConfigurationModalState
> {
  state = {
    title: '',
    content: '',
    isEditing: true,
    isPlanManagementOn: false,
    inputValue: 0,
    invoiceEmail: '',
    isSaveDisabled: false
  };

  private _onCloseModal = async () => {
    await this.props.onClose();
  };

  private _onPressSave = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        let type = '';
        if (this.props.configType === TimeBasedAlertConfigType.DOCUMENT_EXPIRY) type = 'Document';
        if (this.props.configType === TimeBasedAlertConfigType.SERVICE_AGREEMENT_EXPIRY) type = 'ServiceAgreement';

        await this.props.doUpdateAlertConfiguration({
          type,
          value: parseInt(form.getFieldValue('timeBasedAlertDayNumber'))
        });

        this.setState({
          isEditing: false,
          title: `${this.state.title} updated`
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
  };

  private _daysInputValidator = (rule, value, callback) => {
    try {
      if (!value || value === '') {
        throw Error('Please enter a days');
      }
      if (value <= 0 || value > 100) {
        throw Error('Please enter a value between 1-100');
      }
      this.setState({ isSaveDisabled: false });
      callback();
    } catch (e) {
      this.setState({ isSaveDisabled: true });
      callback(e);
    }
  };

  componentDidMount() {
    switch (this.props.configType) {
      case TimeBasedAlertConfigType.DOCUMENT_EXPIRY: {
        this.setState({ title: 'Document expiry', content: 'document' });
        break;
      }
      case TimeBasedAlertConfigType.SERVICE_AGREEMENT_EXPIRY: {
        this.setState({ title: 'Service agreement expiry', content: 'service agreement' });
        break;
      }
      default:
        break;
    }
  }

  render() {
    const { isOpen, form, dayConfigured } = this.props;
    const { getFieldDecorator } = form;
    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="small">
        {this.state.isEditing ? (
          <>
            <div className="anim-slide-left">
              <div className={'mb-x-medium'}>
                <Paragraph
                  className={'mt-small'}
                >{`Configure the time before expiry where a ${this.state.content} is considered 'Expiring soon'`}</Paragraph>
                <div className={'mt-medium'}>
                  <Form.Item className="width-full m-none mt-small">
                    {getFieldDecorator('timeBasedAlertDayNumber', {
                      initialValue: dayConfigured > 0 ? dayConfigured : 14,
                      rules: [{ required: true, validator: this._daysInputValidator }]
                    })(
                      <Input
                        style={{ width: '200px', textAlign: 'right' }}
                        maxLength={12}
                        size={'large'}
                        addonBefore={'Days'}
                        type={'number'}
                      />
                    )}
                  </Form.Item>
                </div>
              </div>

              <ActionModalFooter>
                <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._onPressSave} disabled={this.state.isSaveDisabled}>
                  Save
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        ) : (
          <>
            <div className="anim-slide-left">
              <div className={'mb-x-medium'}>
                <Paragraph className={'mt-small'}>
                  {`You have updated the ${this.state.content} expiry alert to`}{' '}
                  <b>{form.getFieldValue('timeBasedAlertDayNumber')} days.</b>
                </Paragraph>
                <div className={'mt-medium'}></div>
              </div>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyFundingAndPayment: state.companyStore.companyFundingAndPayment
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditNDISConfig: dispatch.companyStore.doEditNDISConfig,
  doUpdateAlertConfiguration: dispatch.accountStore.doUpdateAlertConfiguration
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<ITimeBasedAlertsConfigurationModalProps>()(TimeBasedAlertsConfigurationModal));
