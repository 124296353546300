/* eslint-disable */
import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { ServiceType } from 'utilities/enum-utils';
import ServiceStatusTag from 'common-components/tags/ServiceStatusTag';
import { Avatar } from 'antd';
import { BookingTypeIcon } from 'common-components/tags';

// Some interfaces for module definition. Not important but useful to have
export interface ServiceListItemModel {
  serviceId: string;
  serviceName: string;
  status: string;
  serviceDepartmentName: string;
  serviceType: ServiceType;
  attachmentUrl: string;
  serviceDescription: any;
}

export interface ServiceListItemProps {
  data: ServiceListItemModel;
  handleItemClick: (id: string, serviceType: ServiceType) => void;
}

interface IServiceListItemState {}

export default class ServiceListItem extends Component<ServiceListItemProps, IServiceListItemState> {
  render() {
    const { handleItemClick, data } = this.props;

    const {
      serviceId,
      status,
      serviceName,
      serviceDepartmentName,
      serviceType,
      attachmentUrl,
      serviceDescription
    } = data;

    return (
      <tr onClick={() => handleItemClick(serviceId, serviceType)} className={`anim-fade-in bg-white evenodd`}>
        <td className="width-1/2">
          <div className={'flex-row align-center'}>
            <div className={'mr-medium'}>
              <Avatar className="rounded" size={80} alt="Service image" src={attachmentUrl} />
            </div>
            <div className="overflow-line-clamp-3 mr-medium line-height-135">
              <Text size={'x2-large'} lineHeight={135}>
                {serviceName}
              </Text>
              <br />
              <Text color={'secondary'} size={'regular'} lineHeight={135}>
                {serviceDescription}
              </Text>
            </div>
          </div>
        </td>
        <td className={'width-1/6'}>
          <Text size="large" style={{ whiteSpace: 'nowrap' }}>
            <ServiceStatusTag status={status} size={'small'} />
          </Text>
        </td>
        <td className={'width-1/6'}>
          <div className={'flex-row align-center'}>
            <BookingTypeIcon type={serviceType} className={'mr-small mv-x-small'} />
            <div>
              <Text size="large" style={{ whiteSpace: 'nowrap' }}>
                {serviceType === ServiceType.INDIVIDUAL ? 'Support Service' : 'Group Service'}
              </Text>
            </div>
          </div>
        </td>
        <td className={'width-1/6'}>
          <Text size="large" style={{ whiteSpace: 'nowrap' }}>
            {serviceDepartmentName}
          </Text>
        </td>
      </tr>
    );
  }
}
