import React, { Component } from 'react';
import Search from 'antd/es/input/Search';
import { PrimaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import _ from 'lodash';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import { ICustomView } from 'src/interfaces/custom-views-interface';
import { CustomViewsModalType } from 'utilities/enum-utils';

export interface ICustomViewPopoverProps {
  onCreateNewView: (modalType: CustomViewsModalType) => void;
  onDisplayTab: (visible: boolean) => void;
  pageViews: ICustomView[];
  displayedPageListingTabs: ICustomView[];
  setDisplayedPageListingTabs: (action) => void;
  setPageListingActiveTab: (action) => void;
}

export interface ICustomViewPopoverState {
  isLoading: boolean;
  isSearching: boolean;
  filteredViews: ICustomView[];
}

class CustomViewPopover extends Component<ICustomViewPopoverProps, ICustomViewPopoverState> {
  state = {
    isLoading: true,
    isSearching: false,
    filteredViews: []
  };

  private _separateListByOwner = (pageViews) => {
    const createdByMe = [];
    const createdByOthers = [];

    pageViews &&
      pageViews.forEach((view) => {
        if (view.isOwner) {
          createdByMe.push(view);
        } else {
          createdByOthers.push(view);
        }
      });

    return {
      createdByMe,
      createdByOthers
    };
  };

  private _searchText = async (txt) => {
    const targetStr = txt
      .toLowerCase()
      .split(' ')
      .filter(function(el) {
        return el.length !== 0;
      });

    const result =
      targetStr.length > 0
        ? _.filter(this.props.pageViews, (item) =>
            _.some(targetStr, (string) => item.name.toLowerCase().indexOf(string) >= 0)
          )
        : this.props.pageViews;

    this.setState({ filteredViews: result, isSearching: false });
  };

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _onDisplayTabHandler = async (tab) => {
    const { displayedPageListingTabs, setDisplayedPageListingTabs, setPageListingActiveTab, onDisplayTab } = this.props;
    onDisplayTab(false);
    await setDisplayedPageListingTabs([...displayedPageListingTabs, tab]);
    setPageListingActiveTab(tab);
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  componentDidMount() {
    this.setState({ filteredViews: this.props.pageViews, isLoading: false });
  }

  componentDidUpdate(prevProps: Readonly<ICustomViewPopoverProps>) {
    if (!_.isEqual(prevProps.pageViews, this.props.pageViews)) {
      this.setState({ filteredViews: this.props.pageViews, isLoading: false });
    }
  }

  render() {
    const { onCreateNewView } = this.props;
    const filteredList = this.state.filteredViews;
    const { createdByMe, createdByOthers } = this._separateListByOwner(filteredList);
    return (
      <div style={{ width: '400px', margin: '-12px -16px' }}>
        <div className="p-medium">
          <Text size={'x-large'} weight={'bold'}>
            Add view
          </Text>
        </div>
        <div className="pv-small ph-medium bordered-bottom border-standard-gray">
          <Search placeholder="Search for..." allowClear={true} onChange={this._onEnterSearchText} />
        </div>
        <div
          style={{ maxHeight: '200px', overflow: 'auto' }}
          className={'bordered-bottom border-standard-gray pv-medium'}
        >
          {this.state.isLoading ? (
            <SpinningLoader size={25} message={''} />
          ) : filteredList && filteredList.length > 0 ? (
            <>
              <div className={`pv-small ph-medium`}>
                <b>Created by me ({createdByMe.length || 0})</b>
              </div>
              {_.map(createdByMe, (item, key) => (
                <div
                  className={`pv-x-small ph-medium pl-large hover-bg-tertiary cursor-pointer`}
                  key={key}
                  onClick={() => this._onDisplayTabHandler(item)}
                >
                  {item.name}
                </div>
              ))}
              <div className={`pv-small ph-medium`}>
                <b>Created by others ({createdByOthers.length || 0})</b>
              </div>
              {_.map(createdByOthers, (item, key) => (
                <div
                  className={`pv-x-small ph-medium pl-large hover-bg-tertiary cursor-pointer`}
                  key={key}
                  onClick={() => this._onDisplayTabHandler(item)}
                >
                  {item.name}
                </div>
              ))}
            </>
          ) : (
            <div className={'pv-x-small ph-medium'}>No views found.</div>
          )}
        </div>
        <div className="p-medium">
          <div className={'flex-row justify-end'}>
            <div>
              <PrimaryButton
                color={'blue-action'}
                className={'bp3-popover-dismiss border-blue-action'}
                onClick={() => onCreateNewView(CustomViewsModalType.CREATE)}
              >
                Create new view
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  workerListLite: state.teamStore.workerListLite
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doFetchWorkerListLite: dispatch.teamStore.doFetchWorkerListLite
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomViewPopover);
