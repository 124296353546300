/* eslint-disable */
import React, { Component } from 'react';

import { Button, Form, Icon, Input, notification, Select, Tag } from 'antd';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { IServiceDetailsItem } from 'interfaces/service-interfaces';
import { ServDetailsContentRow } from '../common/ServDetailsContentRow';
import { ServDetailsSectionHeader } from '../common/ServDetailsSectionHeader';
import moment from 'moment';
import _ from 'lodash';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import UploadImage from 'common-components/upload/UploadImage';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/es/form';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { ServiceType } from 'utilities/enum-utils';
import { Information } from 'common-components/alerts';
import { Tooltip2 } from '@blueprintjs/popover2';

interface IServiceDetailsTabProps extends FormComponentProps {
  selectedService: IServiceDetailsItem;
  doUpdateIndividualServiceDetail: typeof dispatch.servicesStore.doUpdateIndividualServiceDetail;
  setFilteredServices: typeof dispatch.servicesStore.setFilteredServices;
  setSelectedService: typeof dispatch.servicesStore.setSelectedService;
  filteredServices: typeof state.servicesStore.filteredServices;
  serviceDepartmentList: typeof state.servicesStore.serviceDepartmentListLite;
  hasEditPermission: boolean;
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;
}

interface IServiceDetailsTabState {
  isEdit: boolean;
  serviceName: string;
  serviceDescription: string;
  serviceDepartmentId: string;
  bannerUrl: string;
  bannerUrlPath: string;
  isLoading: boolean;
  isProceedModalOpen: boolean;
  isChangeDepartmentModalOpen: boolean;
}

const serviceTypes = [
  { tag: ServiceType.INDIVIDUAL, label: 'Support Service' },
  { tag: ServiceType.GROUP, label: 'Group Service' }
];

class ServiceDetailsTab extends Component<IServiceDetailsTabProps, IServiceDetailsTabState> {
  state = {
    isEdit: false,
    serviceName: null,
    serviceDescription: null,
    serviceDepartmentId: null,
    bannerUrl: null,
    bannerUrlPath: null,
    isLoading: false,
    isProceedModalOpen: false,
    isChangeDepartmentModalOpen: false
  };

  private _changeView = () => {
    this.setState({ isProceedModalOpen: false, isEdit: !this.state.isEdit });
  };

  componentDidMount() {
    this.props.setSelectedSideNavMenuKeys(['/services']);
    this._formatData();
  }

  private _closeProceedModal = () => {
    this.setState({ isProceedModalOpen: false });
  };

  private _onChangeServiceDepartment = (event) => {
    this.setState({ serviceDepartmentId: event });
  };

  private _checkServiceDepartmentBeforeSaving = () => {
    const { selectedService } = this.props;

    if (this.state.serviceDepartmentId !== selectedService.serviceDepartmentId) {
      this.setState({ isChangeDepartmentModalOpen: true });
    } else {
      this._onSubmitChange();
    }
  };

  private _cancelChangeDepartmentModal = () => {
    this.setState({
      serviceDepartmentId: this.props.selectedService.serviceDepartmentId,
      isChangeDepartmentModalOpen: false
    });
    this.props.form.resetFields();
  };

  private _closeChangeDepartmentModal = () => {
    this.setState({ isChangeDepartmentModalOpen: false });
  };

  private _formatData = () => {
    const { selectedService } = this.props;
    this.setState({
      serviceName: selectedService.serviceName,
      serviceDescription: selectedService.serviceDescription,
      bannerUrl: selectedService.bannerUrl.attachmentUrl,
      bannerUrlPath: selectedService.bannerUrl.attachmentPath,
      serviceDepartmentId: selectedService.serviceDepartmentId
    });
  };

  private _discardChanges = () => {
    const { selectedService } = this.props;

    const hasUnsavedData =
      this.state.serviceName !== selectedService.serviceName ||
      this.state.serviceDescription !== selectedService.serviceDescription ||
      this.state.serviceDepartmentId !== selectedService.serviceDepartmentId ||
      this.state.bannerUrl !== selectedService.bannerUrl.attachmentUrl;

    if (hasUnsavedData) {
      this.setState({ isProceedModalOpen: true });
    } else {
      this._changeView();
    }
  };

  private _onSubmitChange = async () => {
    const {
      setSelectedService,
      setFilteredServices,
      form,
      selectedService,
      doUpdateIndividualServiceDetail
    } = this.props;

    let isFormValid = true;

    form.validateFields(async (err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      this.setState({ isLoading: true, isChangeDepartmentModalOpen: false });
      const payload = {
        serviceName: this.state.serviceName,
        serviceDescription: this.state.serviceDescription,
        bannerUrl: this.state.bannerUrl,
        bannerUrlPath: this.state.bannerUrlPath,
        locations: selectedService.locations,
        serviceDirection: selectedService.serviceDirection,
        workerSelectionType: selectedService.workerSelectionType,
        serviceStockNumber: selectedService.serviceStockNumber ? selectedService.serviceStockNumber : '',
        isPublicMarketplace: selectedService.isPublicMarketplace,
        capacity: selectedService.capacity ? Number(selectedService.capacity) : 0,
        timezone: selectedService.timezone,
        serviceDepartmentId: this.state.serviceDepartmentId,
        servicePublishDate: moment(selectedService.servicePublishDate),
        isUseWorkerTimeForAutoBilling: selectedService.isUseWorkerTimeForAutoBilling,
        workerTimeLeeway: selectedService.workerTimeLeeway,
        canWorkerEditCheckInCheckOutTime: selectedService.canWorkerEditCheckInCheckOutTime,
        bookingTimeWarningSource: selectedService.bookingTimeWarningSource
      };
      try {
        await doUpdateIndividualServiceDetail(payload);

        notification.success({
          message: 'Service details updated'
        });
        const updatedServiceList = _.map(this.props.filteredServices, (service) => {
          if (service.serviceId === selectedService.serviceId) {
            return {
              ...service,
              serviceName: this.state.serviceName,
              serviceDescription: this.state.serviceDescription
            };
          } else {
            return { ...service };
          }
        });
        setFilteredServices(updatedServiceList);
        this.setState({ isLoading: false, isEdit: false });
      } catch (e) {
        this.setState({ isLoading: false });
        notification.error({
          message: 'Service details update failed',
          description: 'Please try again'
        });
        throw e;
      }
    }
  };

  private _onSuccessUploadImage = (item) => {
    this.setState({ bannerUrl: item.url, bannerUrlPath: item.urlPath });
  };

  render = () => {
    const { selectedService, serviceDepartmentList, form } = this.props;
    const { isEdit } = this.state;
    const { getFieldDecorator } = form;

    if (!isEdit) {
      return (
        <div className="mt-small">
          {/* Basic Information Section */}
          <ServDetailsSectionHeader
            title={'Service Details'}
            hasEditButton={this.props.hasEditPermission}
            editAction={this._changeView}
            subtitle={'Basic information about your service.'}
          />

          {/* Service Name*/}
          <ServDetailsContentRow
            label={'Service Name'}
            leftColSpan={8}
            rightColSpan={16}
            subLabel={'The name of this service as it appears on the listing sections in the GoodHuman App.'}
          >
            <Text>{selectedService.serviceName}</Text>
          </ServDetailsContentRow>

          {/*  Service Description */}
          <ServDetailsContentRow
            label={'Service Description'}
            subLabel={
              'Tell us about your service! This description will be shown on the listing for this service in the GoodHuman App.'
            }
            leftColSpan={8}
            rightColSpan={16}
          >
            <Paragraph style={{ whiteSpace: 'pre-line' }} ellipsis={{ rows: 5 }}>
              {selectedService.serviceDescription}
            </Paragraph>
          </ServDetailsContentRow>

          <ServDetailsContentRow
            label={'Service Type'}
            subLabel={'Is this service an individual or a group type service?'}
            leftColSpan={8}
            rightColSpan={16}
          >
            <Tag color={'blue'}>
              {_.find(serviceTypes, (type) => type.tag === selectedService.serviceType)
                ? _.find(serviceTypes, (type) => type.tag === selectedService.serviceType).label
                : selectedService.serviceType}
            </Tag>
          </ServDetailsContentRow>

          <ServDetailsContentRow
            label={'Service Department'}
            subLabel={'Which service department is this service assigned to?'}
            leftColSpan={8}
            rightColSpan={16}
          >
            {selectedService.serviceDepartmentId ? selectedService.serviceDepartmentName : 'No department set.'}
          </ServDetailsContentRow>

          {/* Banner Image*/}
          <ServDetailsContentRow
            label={'Banner Image'}
            subLabel={'Attach an image that will be shown on the listing for this service.'}
            leftColSpan={8}
            rightColSpan={16}
          >
            <img alt="Service banner" style={{ maxWidth: '100%' }} src={selectedService.bannerUrl.attachmentUrl} />
          </ServDetailsContentRow>
        </div>
      );
    } else {
      return (
        <div className="mt-small">
          <ActionModal
            isOpen={this.state.isProceedModalOpen}
            onClose={this._closeProceedModal}
            title={'Discard changes'}
            showCloseButton={true}
          >
            <Text className={'mb-medium'}>
              You have <b>unsaved data</b>, proceeding will discard these changes.
            </Text>
            <br />
            <Text className={'mb-medium'}>Do you want to proceed?</Text>
            <ActionModalFooter>
              <PrimaryButton className="mr-medium" size="large" onClick={this._closeProceedModal}>
                Cancel
              </PrimaryButton>
              <GhostButton size="large" onClick={this._changeView}>
                Proceed
              </GhostButton>
            </ActionModalFooter>
          </ActionModal>
          <ActionModal
            isOpen={this.state.isChangeDepartmentModalOpen}
            onClose={this._closeChangeDepartmentModal}
            title={'Re-assign service'}
            showCloseButton={true}
          >
            <Information
              content={
                <>
                  <Text weight={'bold'}>
                    Assigning this service to a different Service Department will change the users who are able to view
                    and manage this service.
                  </Text>
                  <br />
                  Please double-check and ensure that all users that need to view this service have permissions for the
                  new service department.
                </>
              }
              className={'mb-large'}
            />
            <SubTitle>Current service department</SubTitle>
            <Title level={4} className={'mb-large'}>
              {selectedService.serviceDepartmentName}
            </Title>
            <SubTitle>New service department</SubTitle>
            <Title level={4}>
              {this.state.serviceDepartmentId
                ? _.find(
                    serviceDepartmentList,
                    (department) => department.serviceDepartmentId === this.state.serviceDepartmentId
                  ).serviceDepartmentName
                : ''}
            </Title>
            <ActionModalFooter>
              <SecondaryButton className="mr-medium" size="large" onClick={this._cancelChangeDepartmentModal}>
                Cancel the change
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onSubmitChange}>
                Re-assign Service
              </PrimaryButton>
            </ActionModalFooter>
          </ActionModal>
          {/* Basic Information Section */}
          <ServDetailsSectionHeader title={'Service Details'} subtitle={'Basic information about your service.'} />

          {/* Service Name*/}
          <ServDetailsContentRow
            label={'Service Name'}
            leftColSpan={8}
            rightColSpan={16}
            subLabel={'The name of this service as it appears on the listing sections in the GoodHuman App.'}
          >
            <Form.Item className={'m-none'}>
              {getFieldDecorator('serviceName', {
                initialValue: this.state.serviceName,
                rules: [
                  {
                    required: true,
                    message: 'Please enter a name.'
                  }
                ]
              })(
                <Input
                  // value={this.state.serviceName}
                  onChange={(event) => this.setState({ serviceName: event.target.value })}
                />
              )}
            </Form.Item>
          </ServDetailsContentRow>

          {/*  Service Description */}
          <ServDetailsContentRow
            label={'Service Description'}
            subLabel={
              'Tell us about your service! This description will be shown on the listing for this service in the GoodHuman App.'
            }
            leftColSpan={8}
            rightColSpan={16}
          >
            <Form.Item className={'m-none'}>
              {getFieldDecorator('serviceDescription', {
                initialValue: this.state.serviceDescription,
                rules: [{ required: true, message: 'Please enter a description.' }]
              })(
                <Input.TextArea
                  // value={this.state.serviceDescription}
                  rows={5}
                  onChange={(event) => this.setState({ serviceDescription: event.target.value })}
                />
              )}
            </Form.Item>
          </ServDetailsContentRow>

          <ServDetailsContentRow
            label={'Service Type'}
            subLabel={'Is this service an individual or a group type service?'}
            leftColSpan={8}
            rightColSpan={16}
          >
            <Tag color={'blue'}>
              {_.find(serviceTypes, (type) => type.tag === selectedService.serviceType)
                ? _.find(serviceTypes, (type) => type.tag === selectedService.serviceType).label
                : selectedService.serviceType}
            </Tag>
          </ServDetailsContentRow>

          <ServDetailsContentRow
            label={'Service department'}
            subLabel={'Which service department is this service assigned to?'}
            leftColSpan={8}
            rightColSpan={16}
          >
            <Form.Item className={'m-none'}>
              {getFieldDecorator('serviceDepartment', {
                initialValue: this.state.serviceDepartmentId,
                rules: [{ required: true, message: 'Please select a department.' }]
              })(
                <Select
                  placeholder="Select a service department."
                  size={'large'}
                  className={'width-1/2'}
                  onChange={(event) => this._onChangeServiceDepartment(event)}
                >
                  {_.map(serviceDepartmentList, (department) => {
                    return (
                      <Select.Option value={department.serviceDepartmentId}>
                        {department.serviceDepartmentName}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
              <Tooltip2
                position="top"
                content={'You can only assign services to service departments you are the manager of.'}
              >
                <Icon type="question-circle" className="text-color-blue ml-medium" />
              </Tooltip2>
            </Form.Item>
          </ServDetailsContentRow>

          {/* Banner Image*/}
          <ServDetailsContentRow
            label={'Banner Image'}
            subLabel={'Attach an image that will be shown on the listing for this service.'}
            leftColSpan={8}
            rightColSpan={16}
          >
            {!_.isEmpty(this.state.bannerUrl) && this.state.bannerUrlPath && (
              <div>
                <UploadImage
                  style={{
                    width: '200px',
                    objectFit: 'cover',
                    padding: '5px',
                    marginRight: '8px',
                    borderRadius: '4px'
                  }}
                  url={this.state.bannerUrl}
                  onFinishUpload={this._onSuccessUploadImage}
                  uploadPath={`services/${selectedService.serviceId}`}
                />
              </div>
            )}
          </ServDetailsContentRow>

          <ServDetailsContentRow align={'right'} leftColSpan={8} rightColSpan={16}>
            <Button.Group>
              <GhostButton onClick={this._discardChanges}>Discard Changes</GhostButton>

              <PrimaryButton
                loading={this.state.isLoading}
                onClick={this._checkServiceDepartmentBeforeSaving}
                icon={'save'}
              >
                Save
              </PrimaryButton>
            </Button.Group>
          </ServDetailsContentRow>

          {/* Edit Modal */}
          {/*<ObsoleteStandardModal*/}
          {/*  visible={this.state.showEditModal}*/}
          {/*  onOk={this._handleSuccess}*/}
          {/*  onCancel={this._handleCancel}*/}
          {/*>*/}
          {/*  <p>Test</p>*/}
          {/*</ObsoleteStandardModal>*/}
        </div>
      );
    }
  };
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys
});

const mapState = (state: IRootState) => ({
  serviceDepartmentList: state.servicesStore.serviceDepartmentListLite
});

export default connect(mapState, mapDispatch)(Form.create<IServiceDetailsTabProps>()(ServiceDetailsTab));

// import { Upload, Icon, message } from 'antd';
// import firebase from 'firebase';
//
//
// interface UploadAvatarProps {
//   style?: any;
//   className?: string;
//   url: string;
//   onFinishUpload: ({ url, urlPath }) => void;
// }
//
// class UploadAvatar extends Component<UploadAvatarProps, any> {
//   state = {
//     loading: false,
//     imageUrl: null
//   };
//
//   componentDidMount() {
//     const { url } = this.props;
//     if (url) {
//       this.setState({ imageUrl: url });
//     }
//   }
//
//   getBase64(img, callback) {
//     const reader = new FileReader();
//     reader.addEventListener('load', () => callback(reader.result));
//     reader.readAsDataURL(img);
//   }
//
//   beforeUpload(file) {
//     const isJpgOrPng = file.type === 'image/jpeg';
//     if (!isJpgOrPng) {
//       message.error('You can only upload JPG file!');
//     }
//     const isLt2M = file.size / 1024 / 1024 < 2;
//     if (!isLt2M) {
//       message.error('Image must smaller than 2MB!');
//     }
//     return isJpgOrPng && isLt2M;
//   }
//
//   handleChange = (info) => {
//     if (info.file.status === 'uploading') {
//       this.setState({ loading: true });
//       return;
//     }
//     if (info.file.status === 'done') {
//       // Get this url from response in real world.
//       this.getBase64(info.file.originFileObj, (imageUrl) =>
//         this.setState({
//           imageUrl,
//           loading: false
//         })
//       );
//     }
//   };
//
//   customUpload = async ({ onError, onSuccess, file }) => {
//     const { onFinishUpload } = this.props;
//     try {
//       const image = await firebase
//         .storage()
//         .ref(`images/${file.name}`)
//         .put(file);
//
//       const imageUrl = await image.ref.getDownloadURL();
//       onFinishUpload({ url: imageUrl, urlPath: image.ref.fullPath });
//       onSuccess(null, image);
//     } catch (e) {
//       onError(e);
//     }
//   };
//
//   render() {
//     const uploadButton = (
//       <div>
//         <Icon type={this.state.loading ? 'loading' : 'plus'} />
//         <div className="ant-upload-text">Upload</div>
//       </div>
//     );
//
//     const { imageUrl } = this.state;
//     const { style, className } = this.props;
//     return (
//       <Upload
//         name="avatar"
//         listType="picture-card"
//         style={style}
//         className={className}
//         showUploadList={false}
//         // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
//         customRequest={this.customUpload}
//         beforeUpload={this.beforeUpload}
//         onChange={this.handleChange}
//       >
//         {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
//       </Upload>
//     );
//   }
// }
