import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { Text } from 'common-components/typography';
import { Button, Checkbox, Col, Row } from 'antd';
import ndisHelper from 'variables/data/ndis-helper';
import { IBillingBooking, IBillingLineItem } from 'interfaces/booking-interfaces';
import BookingApproveEditLineItemDetails from './BookingApproveEditLineItemDetails';
import CommonUtils from 'utilities/common-utils';
import _ from 'lodash';
import moment from 'moment';

interface BookingApproveLineItemProps {
  index: number;
  billingBooking: IBillingBooking;
  setIndividualBillingBookingSelected: typeof dispatch.billingsStore.setIndividualBillingBookingSelected;
  doUpdateBookingBillingItemsInApprove: typeof dispatch.billingsStore.doUpdateBookingBillingItemsInApprove;
}

interface BookingApproveLineItemState {
  modalVisible: boolean;
  isEdit: boolean;
  isRemove: boolean;
  isExpand: boolean;
  billingLineItems: IBillingLineItem[];
  billingSupportItems: any[];
}

class BookingApproveLineItem extends Component<BookingApproveLineItemProps, BookingApproveLineItemState> {
  state = {
    modalVisible: false,
    isEdit: false,
    isRemove: false,
    isExpand: false,
    billingLineItems: [],
    billingSupportItems: []
  };

  _onClickEdit = () => {
    this.setState({ isEdit: true });
  };

  _onClickRemove = () => {
    this.setState({ isRemove: true });
  };

  _onEditSave = () => {
    this.setState({ isEdit: false });
  };

  _onEditCancel = () => {
    this.setState({ isEdit: false });
  };

  _onClickExpand = () => {
    this.setState({ isExpand: !this.state.isExpand });
  };

  private _onChangeSelect = (e) => {
    this.props.setIndividualBillingBookingSelected({
      bookingId: this.props.billingBooking.bookingId,
      selected: e.target.checked
    });
  };

  _renderLineItemHeader = () => {};

  componentDidMount = () => {
    const { billingBooking } = this.props;
    const billingSupportItems: any = _.map(_.cloneDeep(billingBooking.serviceBillingLineItems), (serviceLineItem) => {
      const ndisSupportItem = ndisHelper.getBySupportItemNumber(serviceLineItem.supportItemNumber);
      serviceLineItem.supportItem = ndisSupportItem.SupportItem;
      return serviceLineItem;
    });
    this.setState({ billingSupportItems });
  };

  render() {
    const { billingBooking, doUpdateBookingBillingItemsInApprove } = this.props;
    const { isExpand, billingSupportItems } = this.state;

    const boxClassName = isExpand ? 'ph-medium shadow-without-top' : 'ph-medium';
    return (
      <div className={'bordered-bottom pv-medium'}>
        <div className={boxClassName}>
          <Row gutter={32}>
            <Col span={1}>
              <Checkbox checked={billingBooking.selected} onChange={this._onChangeSelect} />
            </Col>
            <Col span={3}>
              {billingBooking.firstName} {billingBooking.lastName}
            </Col>
            <Col span={4}>{moment(billingBooking.startDateTime).format('DD-MM-YYYY hh:mm A')}</Col>
            <Col span={4}>{moment(billingBooking.endDateTime).format('DD-MM-YYYY hh:mm A')}</Col>
            <Col span={7}>
              <div>
                <Text type={'secondary'}>{billingBooking.serviceName}</Text>
              </div>
            </Col>
            <Col span={2}>
              <Text>{CommonUtils.formatStatusString(billingBooking.bookingStatus)}</Text>
            </Col>
            <Col span={2}>
              <Text>${billingBooking.total}</Text>
            </Col>
            <Col span={1}>
              <Button
                icon={isExpand ? 'down' : 'right'}
                type={'link'}
                style={{ float: 'right' }}
                onClick={this._onClickExpand}
              />
            </Col>
          </Row>

          <BookingApproveEditLineItemDetails
            billingBooking={billingBooking}
            billingSupportItems={billingSupportItems}
            isExpand={isExpand}
            doUpdateBookingBillingItemsInApprove={doUpdateBookingBillingItemsInApprove}
          />
        </div>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setIndividualBillingBookingSelected: dispatch.billingsStore.setIndividualBillingBookingSelected,
  doUpdateBookingBillingItemsInApprove: dispatch.billingsStore.doUpdateBookingBillingItemsInApprove
});

export default connect(
  null,
  mapDispatch
)(BookingApproveLineItem);
