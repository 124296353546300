import React from 'react';

import AuthRoutes from './authorized-routes';
import NonAuthRoutes from './non-authorized-routes';

// To remove below from production later.
import TestRoutes from './test-routes';

// TODO Add default if no routes matched.
const Routes = ({ isAuthenticated }) => (
  <>
    <AuthRoutes isAuthenticated={isAuthenticated} />
    <NonAuthRoutes isAuthenticated={isAuthenticated} />
    <TestRoutes isAuthenticated={isAuthenticated} />
  </>
);

export default Routes;
