import { IPermissions, IServiceDepartment } from 'interfaces/account-interfaces';
import React, { Component } from 'react';
import { AppPermissionsPanel } from 'views/account-management/add-new-member/panels/permissions-panel/app/AppPermissionsPanel';
import { PortalPermissionsPanel } from 'views/account-management/add-new-member/panels/permissions-panel/portal/PortalPermissionsPanel';
import { IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';
import { UserPermissionRole } from 'utilities/enum-utils';

interface PermissionsControlPanelProps {
  permissions: IPermissions;
  serviceDepartments?: IServiceDepartment[];
  onChangePermissions?: any;
  onChangeRole?: any;
  portalUser: typeof state.authStore.portalUser;
  hasEditPermission?: boolean;
  companyDataLite: any;
}

interface PermissionsControlPanelState {
  serviceModalOpen: boolean;
}

class PermissionsControlPanel extends Component<PermissionsControlPanelProps, PermissionsControlPanelState> {
  state = { serviceModalOpen: false };

  // Update permissions
  onChangePermissions = (permissions) => {
    this.props.onChangePermissions(permissions);
  };

  private _onChangeRole = (role) => {
    const { permissions } = this.props;
    if (role === 'owner') {
      this.onChangePermissions({
        hasPortalAccess: true,
        permissionRoles: [
          {
            serviceId: null,
            permissionRole: 2,
            serviceDepartmentId: null
          }
        ],
        hasWorkerAppAccess: permissions.hasWorkerAppAccess
      });
    } else {
      this.onChangePermissions({
        hasPortalAccess: true,
        permissionRoles: [
          {
            serviceId: null,
            permissionRole: 4,
            serviceDepartmentId: null
          },
          {
            serviceId: null,
            permissionRole: 6,
            serviceDepartmentId: null
          }
        ],
        hasWorkerAppAccess: permissions.hasWorkerAppAccess
      });
    }
  };

  render() {
    let { permissions, serviceDepartments, hasEditPermission = true } = this.props;

    const isPrimaryOwner = !!_.find(
      this.props.portalUser.permissions.permissionRoles,
      (role) => role.permissionRole === UserPermissionRole.PrimaryOwner
    );
    const isOwner = !!_.find(
      this.props.portalUser.permissions.permissionRoles,
      (role) => role.permissionRole === UserPermissionRole.Owner
    );
    // const isPrimaryOwner = false;
    // const isOwner = true;
    const isTargetUserPrimaryOwner = !!_.find(
      permissions.permissionRoles,
      (role) => role.permissionRole === UserPermissionRole.PrimaryOwner
    );
    const isTargetUserOwner = !!_.find(
      permissions.permissionRoles,
      (role) => role.permissionRole === UserPermissionRole.Owner
    );

    return (
      <div>
        {/* Display panels */}
        {/* App Permissions */}
        <AppPermissionsPanel
          permissions={permissions}
          onChangePermissions={this.onChangePermissions}
          isPrimaryOwner={isPrimaryOwner}
          disabled={
            !hasEditPermission || (isTargetUserPrimaryOwner || (isTargetUserOwner && !isPrimaryOwner && !isOwner))
          }
        />

        {/* Portal Permissions */}
        <PortalPermissionsPanel
          hasPlanManagement={this.props.companyDataLite.hasPlanManagement}
          permissions={permissions}
          serviceDepartments={serviceDepartments}
          onChangePermissions={this.onChangePermissions}
          onChangeRole={this._onChangeRole}
          isPrimaryOwner={isPrimaryOwner}
          isTargetUserPrimaryOwner={isTargetUserPrimaryOwner}
          isTargetUserOwner={isTargetUserOwner}
          hasEditPermission={hasEditPermission}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  companyDataLite: state.companyStore.companyDataLite
});

export default connect(
  mapState,
  null
)(PermissionsControlPanel);
