/* eslint-disable */
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Avatar, Carousel, Col, DatePicker, Icon, notification, Pagination, Row } from 'antd';

import moment from 'moment-timezone';

import DefaultLayout from 'layouts/DefaultLayout';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import _ from 'lodash';

import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from '../../common-components/buttons';
import { StatusTag } from '../../common-components/tags';
import { BusinessPlanSVG } from '../../assets/UndrawSVG';
import FilterUtils from '../../utilities/filter-utils';
import NotificationListingSection from './components/NotificationListingSection';
import { DocumentExpiryStatus, ServiceType } from 'utilities/enum-utils';
import ActionModal from 'common-components/modal/ActionModal';

import introductionSlide1 from 'assets/onboarding/intoduction_graphic_step1.png';
import introductionSlide2 from 'assets/onboarding/intoduction_graphic_step2.png';
import introductionSlide3 from 'assets/onboarding/intoduction_graphic_step3.png';
import introductionSlide4 from 'assets/onboarding/intoduction_graphic_step4.png';
import CustomersGlanceSection from 'views/dashboard/components/CustomerGlanceSection';
import CustomersGlanceDemoSection from 'views/dashboard/components/CustomersGlanceDemoSection';
import PermissionUtils from 'utilities/permission-utils';

const dataSource = [
  {
    key: '1',
    name: 'Mike',
    location: 'South Yarra',
    address: '10 Downing Street'
  },
  {
    key: '2',
    name: 'John',
    location: 'Mitcham',
    address: '10 Downing Street'
  }
];

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (props) => (
      <div>
        <Avatar icon={'user'} style={{ marginRight: '8px' }} /> {props}
      </div>
    )
  },
  {
    title: 'Location',
    dataIndex: 'location',
    key: 'location'
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address'
  }
];

interface BookingRowItemProps {
  bookingDate: string;
  bookingTime: string;
  bookerName: string;
  profileImage: string;
  serviceName: string;
  workerName: string;
  status: string;
  serviceType: string;
}

class BookingRowItem extends Component<BookingRowItemProps> {
  state = { isHovered: false };

  setHovered = () => this.setState({ isHovered: true });
  setUnhovered = () => this.setState({ isHovered: false });

  render() {
    const {
      bookingDate,
      bookingTime,
      bookerName,
      serviceName,
      profileImage,
      workerName,
      status,
      serviceType
    } = this.props;

    return (
      <Row
        className="bordered-top ph-large cursor-pointer hover-bg-tertiary"
        type="flex"
        align="middle"
        onMouseEnter={this.setHovered}
        onMouseLeave={this.setUnhovered}
      >
        <Col span={6}>
          <div className="pv-medium">
            <Text weight={'bold'}>
              {/*Monday, 15 Sep 2019*/}
              {bookingDate}
            </Text>
            <br />
            <Text>
              {/*9.30 AM - 3.30 PM*/}
              {bookingTime}
            </Text>
          </div>
        </Col>
        <Col span={6}>
          <div className="pv-medium">
            <Avatar icon="user" className="mr-medium" size="large" src={profileImage} />
            <Text weight="black">
              {/*Andrew Murray*/}
              {bookerName}
            </Text>
          </div>
        </Col>
        <Col span={6}>
          <div className="pv-medium">
            <Text weight="black" color="secondary">
              {/*Youth Jobs Now*/}
              {serviceName}
            </Text>
            <br />
            {!_.isEmpty(workerName) ? (
              <>
                <Text color="secondary">Assigned to </Text>
                <Text weight="black" color="secondary">
                  {/*Tom Mitchell*/}
                  {workerName}
                </Text>
              </>
            ) : serviceType === ServiceType.INDIVIDUAL ? (
              <Text color="secondary">Not Assigned </Text>
            ) : (
              <></>
            )}
          </div>
        </Col>
        <Col span={6}>
          <div className="pv-medium text-align-right">
            <StatusTag status={status} />
          </div>
        </Col>
      </Row>
    );
  }
}

class BookingListingSection extends Component<any, any> {
  state = {
    currentPage: 1,
    pageSize: 10,
    selected: 'PENDING',
    displayList: []
  };

  onChange = async (page) => {
    await this.setState({
      currentPage: page
    });
  };

  onShowSizeChange = (current, size) => {
    this.setState({ pageSize: size });
  };

  onClickAll = () => {
    const { dashboardData } = this.props;
    this.setState({ selected: 'ALL', displayList: dashboardData.bookingList, currentPage: 1 });
  };

  onClickPending = () => {
    const { dashboardData } = this.props;
    this.setState({ selected: 'PENDING', displayList: dashboardData.pendingList, currentPage: 1 });
  };

  onClickAccepted = () => {
    const { dashboardData } = this.props;
    this.setState({ selected: 'ACCEPTED', displayList: dashboardData.acceptedList, currentPage: 1 });
  };

  onClickCompleted = () => {
    const { dashboardData } = this.props;
    this.setState({ selected: 'COMPLETED', displayList: dashboardData.completedList, currentPage: 1 });
  };

  renderText = () => {
    const { dashboardData } = this.props;
    switch (this.state.selected) {
      case 'ALL':
        return (
          <Text size="large" color="black">
            You have{' '}
            <span className="text-weight-bold text-weight-black">
              {dashboardData ? dashboardData.bookingList.length : '-'}
            </span>{' '}
            bookings for your attention.
          </Text>
        );
      case 'PENDING':
        return (
          <Text size="large" color="black">
            {!_.isEmpty(this.state.displayList) ? (
              <>
                <span className="text-weight-bold text-weight-black">{this.state.displayList.length}</span> pending
                booking{this.state.displayList.length > 1 ? 's' : ''} requiring approval.
              </>
            ) : (
              <>No pending bookings.</>
            )}
          </Text>
        );
      case 'ACCEPTED':
        return (
          <Text size="large" color="black">
            {!_.isEmpty(this.state.displayList) ? (
              <>
                <span className="text-weight-bold text-weight-black">{this.state.displayList.length}</span> accepted
                booking{this.state.displayList.length > 1 ? 's' : ''} without a Team Member confirmed.
              </>
            ) : (
              <>No bookings waiting to be confirmed</>
            )}
          </Text>
        );
      case 'COMPLETED':
        return (
          <Text size="large" color="black">
            {!_.isEmpty(this.state.displayList) ? (
              <>
                <span className="text-weight-bold text-weight-black">{this.state.displayList.length}</span> completed
                booking{this.state.displayList.length > 1 ? 's' : ''} not yet billed.
              </>
            ) : (
              <>No bookings waiting to be billed.</>
            )}
          </Text>
        );
      default:
        return <div />;
    }
  };

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
    const { dashboardData } = this.props;
    if (!_.isEmpty(dashboardData)) {
      if (prevProps.dashboardData !== dashboardData) {
        this.setState({ displayList: dashboardData.pendingList });
      }
    }
  }

  render() {
    const { dashboardData, portalUser } = this.props;
    // console.log(this.props.dashboardData);
    return (
      <div>
        <div className="mb-medium ph-large">
          <Text size="large" color="black">
            Filter your Bookings by status.
          </Text>
        </div>

        <div className="mb-medium ph-large">
          {/*{this.state.selected === 'ALL' ? (*/}
          {/*  <PrimaryButton className="mr-medium" onClick={this.onClickAll}>*/}
          {/*    All ({dashboardData ? dashboardData.bookingList.length : '-'})*/}
          {/*  </PrimaryButton>*/}
          {/*) : (*/}
          {/*  <GhostButton className="mr-medium" onClick={this.onClickAll}>*/}
          {/*    All ({dashboardData ? dashboardData.bookingList.length : '-'})*/}
          {/*  </GhostButton>*/}
          {/*)}*/}

          {this.state.selected === 'PENDING' ? (
            <PrimaryButton className="mr-medium" onClick={this.onClickPending}>
              Pending ({dashboardData ? dashboardData.pendingList.length : '-'})
            </PrimaryButton>
          ) : (
            <GhostButton className="mr-medium" onClick={this.onClickPending}>
              Pending ({dashboardData ? dashboardData.pendingList.length : '-'})
            </GhostButton>
          )}

          {this.state.selected === 'ACCEPTED' ? (
            <PrimaryButton className="mr-medium" onClick={this.onClickAccepted}>
              Accepted ({dashboardData ? dashboardData.acceptedList.length : '-'})
            </PrimaryButton>
          ) : (
            <GhostButton className="mr-medium" onClick={this.onClickAccepted}>
              Accepted ({dashboardData ? dashboardData.acceptedList.length : '-'})
            </GhostButton>
          )}

          {this.state.selected === 'COMPLETED' ? (
            <PrimaryButton className="mr-medium" onClick={this.onClickCompleted}>
              Completed ({dashboardData ? dashboardData.completedList.length : '-'})
            </PrimaryButton>
          ) : (
            <GhostButton className="mr-medium" onClick={this.onClickCompleted}>
              Completed ({dashboardData ? dashboardData.completedList.length : '-'})
            </GhostButton>
          )}

          {/*<PrimaryButton className="mr-large">All (23)</PrimaryButton>*/}

          {/*<HyperlinkButton className="mr-large" color={'green-darker'}>*/}
          {/*  Pending (5)*/}
          {/*</HyperlinkButton>*/}

          {/*<HyperlinkButton className="mr-large" color={'green-darker'}>*/}
          {/*  Accepted (5)*/}
          {/*</HyperlinkButton>*/}
          {/*<HyperlinkButton className="mr-large" color={'green-darker'}>*/}
          {/*  Completed (5)*/}
          {/*</HyperlinkButton>*/}
        </div>

        <div className="mb-medium ph-large">{this.renderText()}</div>

        {/*<div className="pl-large">*/}
        {/*  <Text color="secondary" size="regular">*/}
        {/*    Bookings*/}
        {/*  </Text>*/}
        {/*</div>*/}

        {/* Booking Items */}
        {_.chain(this.state.displayList)
          .slice((this.state.currentPage - 1) * this.state.pageSize, this.state.currentPage * this.state.pageSize)
          .map((booking) => {
            return (
              <div onClick={() => this.props.history.push(`/bookings/details/${booking.bookingId}`)}>
                <BookingRowItem
                  bookingDate={moment(booking.startDateTime).format('dddd, d MMM YYYY')}
                  bookingTime={
                    moment(booking.startDateTime).format('h.mm A') +
                    ' - ' +
                    moment(booking.endDateTime).format('h.mm A')
                  }
                  bookerName={booking.firstName + ' ' + booking.lastName}
                  profileImage={booking.attachmentUrl}
                  serviceName={booking.serviceName}
                  workerName={booking.workerFirstName ? booking.workerFirstName + ' ' + booking.lastName : ''}
                  status={booking.status}
                  serviceType={booking.serviceType}
                />
              </div>
            );
          })
          .value()}
        {/*<BookingRowItem*/}
        {/*  bookerName={'Andrew Murray'}*/}
        {/*  bookingTime={'9.30 AM - 11.30 AM'}*/}
        {/*  status={'PENDING'}*/}
        {/*  bookingDate={'Monday, 4 Nov 2019'}*/}
        {/*  profileImage={''}*/}
        {/*  serviceName={'Youth Jobs Now!'}*/}
        {/*  workerName={'Tom Smith'}*/}
        {/*/>*/}

        {/*<BookingRowItem*/}
        {/*  bookerName={'Kurt Grole'}*/}
        {/*  bookingTime={'10.30 AM - 12.30 PM'}*/}
        {/*  status={'PENDING'}*/}
        {/*  bookingDate={'Tuesday, 5 Nov 2019'}*/}
        {/*  profileImage={''}*/}
        {/*  serviceName={'My Supports'}*/}
        {/*  workerName={'Will Ian'}*/}
        {/*/>*/}

        {/*<BookingRowItem*/}
        {/*  bookerName={'Marion Grant'}*/}
        {/*  bookingTime={'2.30 PM - 3.30 PM'}*/}
        {/*  status={'CONFIRMED'}*/}
        {/*  bookingDate={'Wed, 6 Nov 2019'}*/}
        {/*  profileImage={''}*/}
        {/*  serviceName={'My Supports'}*/}
        {/*  workerName={'Tom Smith'}*/}
        {/*/>*/}

        <div className="width-full pt-large bordered-top ph-large">
          <div className="flex-row justify-between">
            <div>
              {/* Left Content*/}
              {/*<Text className="mr-medium" color="tertiary">*/}
              {/*  Can't find your booking here? You can find the full booking listings on the right.*/}
              {/*</Text>*/}

              {!_.isEmpty(dashboardData) && (
                <Pagination
                  total={this.state.displayList.length}
                  defaultPageSize={this.state.pageSize}
                  pageSizeOptions={['5', '6', '8', '10']}
                  // showSizeChanger
                  // onShowSizeChange={this.onShowSizeChange}
                  // showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                  onChange={this.onChange}
                />
              )}
            </div>
            <div>
              {/* Right Content */}
              {/*<PrimaryButton*/}
              {/*  shape="default"*/}
              {/*  className="ml-medium"*/}
              {/*  size="large"*/}
              {/*  icon="right"*/}
              {/*  iconPosition="right"*/}
              {/*  paddingSize="medium"*/}
              {/*>*/}
              {/*  View All*/}
              {/*</PrimaryButton>*/}

              <HyperlinkButton onClick={() => this.props.history.push('/bookings')}>View all bookings</HyperlinkButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class CompanyItemRow extends Component<{ companyName: any; customers: any; services: any; rating: any; revenue: any }> {
  render() {
    const { companyName, customers, services, rating, revenue } = this.props;

    return (
      <Route
        render={({ history }) => (
          <Row
            className="bordered-top pv-medium hover-bg-tertiary cursor-pointer"
            align="middle"
            type="flex"
            onClick={() => history.push('/account/company-details')}
          >
            <Col span={5} className="pl-medium">
              <HyperlinkButton fontSize="x2-large" weight="bold">
                {companyName}
              </HyperlinkButton>
            </Col>
            <Col span={5}>
              <Text size="x-large" weight="bold">
                {customers}
              </Text>
            </Col>
            <Col span={5} className="pr-medium">
              <Text size="x-large" weight="bold">
                {services}
              </Text>
            </Col>
            <Col span={5}>
              <Text size="x-large" weight="bold">
                {rating}
              </Text>
            </Col>
            <Col span={4} className="text-align-right pr-x3-large">
              <Text size="x-large">{revenue}</Text>
            </Col>
          </Row>
        )}
      />
    );
  }
}

class CompaniesOverviewSection extends Component {
  render() {
    return (
      <div>
        <div className="mb-x-large ph-medium flex-row justify-between align-center">
          <Text>
            Here are the latest statistics for the week ending on{' '}
            <span className="text-weight-black text-color-rose">25 October 2019</span>
          </Text>

          <DatePicker placeholder="Select overview period" />
        </div>

        <div>
          <Row className="pb-small">
            <Col span={5} className="pl-medium">
              <Text weight="bold">Company Name</Text>
            </Col>
            <Col span={5}>
              <Text weight="bold">Active Customers</Text>
            </Col>
            <Col span={5}>
              <Text weight="bold">Active Services</Text>
            </Col>
            <Col span={5}>
              <Text weight="bold">Satisfaction Rating</Text>
            </Col>
            <Col span={4} className="pr-x2-large text-align-right">
              <Text weight="bold">MTD Revenue ($)</Text>
            </Col>
          </Row>

          <CompanyItemRow
            companyName={'BUPA Insurance'}
            customers={
              <span>
                309 <Icon type="caret-up" className="ml-small text-color-forest" /> <br />
                <span className="text-size-small text-color-secondary">(Up from 307)</span>
              </span>
            }
            services={68}
            rating={
              <span>
                86% <br />
                <span className="text-size-small text-color-secondary">(no change)</span>
              </span>
            }
            revenue={'$1,200,000'}
          />

          <CompanyItemRow
            companyName={'AIA Services'}
            customers={
              <span>
                430 <br />
                <span className="text-size-small text-color-secondary">(No change)</span>
              </span>
            }
            services={77}
            rating={
              <span>
                78% <Icon type="caret-down" className="ml-small text-color-red" /> <br />
                <span className="text-size-small text-color-secondary">(Down from 79%)</span>
              </span>
            }
            revenue={'$900,000'}
          />

          <CompanyItemRow
            companyName={'Greenwood Care'}
            customers={
              <span>
                64 <Icon type="caret-up" className="ml-small text-color-forest" /> <br />
                <span className="text-size-small text-color-secondary">(Up from 50)</span>
              </span>
            }
            services={533}
            rating={
              <span>
                95% <Icon type="caret-up" className="ml-small text-color-forest" /> <br />
                <span className="text-size-small text-color-secondary">(Up from 80%)</span>
              </span>
            }
            revenue={'$64,000'}
          />

          <CompanyItemRow
            companyName={'Medibank'}
            customers={
              <span>
                55,609 <br />
                <span className="text-size-small text-color-secondary">(No change)</span>
              </span>
            }
            services={533}
            rating={
              <span>
                77% <Icon type="caret-down" className="ml-small text-color-red" /> <br />
                <span className="text-size-small text-color-secondary">(Down from 80%)</span>
              </span>
            }
            revenue={'$13,679,000'}
          />

          <CompanyItemRow
            companyName={'Allied Health'}
            customers={
              <span>
                2,110 <Icon type="caret-up" className="ml-small text-color-forest" /> <br />
                <span className="text-size-small text-color-secondary">(Up from 2,100)</span>
              </span>
            }
            services={533}
            rating={
              <span>
                77.67% <br />
                <span className="text-size-small text-color-secondary">(no change)</span>
              </span>
            }
            revenue={'$6,200,000'}
          />
        </div>

        <div className="bordered-top ph-medium flex-row justify-between pt-medium">
          <Text color="tertiary">
            Inactive companies are hidden from the list above. Click on <b>View All Companies</b> to see them.
          </Text>
          <HyperlinkButton>View All Companies</HyperlinkButton>
        </div>
      </div>
    );
  }
}

class TestDashboardView extends Component<any, any> {
  state = { isInstructionOpen: false };
  async componentDidMount() {
    this.props.setSelectedSideNavMenuKeys(['/dashboard']);
    const { doFetchDashboardData, doFetchCustomersAtAGlance, doFetchCompanyLite } = this.props;
    if (this.props.portalUser && this.props.portalUser.hasPortalInstruction) {
      this.setState({ isInstructionOpen: true });
    }
    await doFetchCompanyLite([]);
    await doFetchDashboardData();
    await doFetchCustomersAtAGlance();
  }

  private _onCloseInstructionModal = async () => {
    try {
      await this.props.doSetInstructionToViewed({ userId: this.props.portalUser.userId });
      this.setState({ isInstructionOpen: false });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
  };

  onSetBookingFilter = (key) => {
    const { setBookingFilter, history } = this.props;
    if (!_.isEmpty(key)) {
      const filter = [FilterUtils.bookingStatusToFilter('status', [key])];
      setBookingFilter(filter);
    }
    history.push('/bookings');
  };

  private _goToCustomer = async (userId, selectedTab = null) => {
    const { history } = this.props;
    history.push(`/customer/details/${userId}`, { refresh: true, selectedTab });
  };

  render() {
    const { portalUser, dashboardData } = this.props;

    return (
      portalUser && (
        <DefaultLayout containerStyle={{ margin: '0px 0px 48px 0px' }} bordered>
          <ActionModal
            isOpen={this.state.isInstructionOpen}
            onClose={this._onCloseInstructionModal}
            canCloseOutside={false}
            width="x2-large"
            verticalAlignment="center"
          >
            <div className="align-center text-align-left bg-color-secondary select-none">
              <Carousel
                className="text-align-center"
                arrows={true}
                draggable={true}
                nextArrow={<Icon type="right-circle" theme={'filled'} />}
                prevArrow={<Icon type="left-circle" theme={'filled'} />}
              >
                <div>
                  <Title level={1} className={'mb-small'}>
                    You're a GoodHuman, {portalUser.firstName}!
                  </Title>
                  <Text size={'x2-large'} className="pt-x-small text-color-secondary">
                    Welcome to your connected network. GoodHuman is where
                    <br /> your customers, team and services interact.
                  </Text>
                  <div className={'ph-x-large pv-large text-align-center'}>
                    <img src={introductionSlide1} style={{ display: 'initial' }} />
                  </div>
                </div>
                <div>
                  <Title level={1} className={'mb-small'}>
                    Stay connected in real-time
                  </Title>
                  <Text size={'x2-large'} className="pt-x-small text-color-secondary">
                    The GoodHuman app is for team members and customers
                    <br /> and helps keep everyone in the loop. Say goodbye to emails!
                  </Text>
                  <div className={'ph-x-large pv-large text-align-center'}>
                    <img src={introductionSlide2} style={{ display: 'initial' }} />
                  </div>
                </div>
                <div>
                  <Title level={1} className={'mb-small'}>
                    Ditch the paperwork
                  </Title>
                  <Text size={'x2-large'} className="pt-x-small text-color-secondary">
                    Accept, confirm and process customer bookings all in one platform.
                  </Text>
                  <div className={'ph-x-large pv-large text-align-center'}>
                    <img src={introductionSlide3} style={{ display: 'initial' }} />
                  </div>
                </div>
                <div>
                  <Title level={1} className={'mb-small'}>
                    We'll do the math for you
                  </Title>
                  <Text size={'x2-large'} className="pt-x-small text-color-secondary">
                    Add the NDIS line items relevant to a service and they
                    <br /> will be automatically included in the customer’s invoice.
                  </Text>
                  <div className={'ph-x2-large pt-small pb-none text-align-center'}>
                    <img src={introductionSlide4} style={{ display: 'initial' }} />
                  </div>
                  <PrimaryButton color="blue-action" size="large" onClick={this._onCloseInstructionModal}>
                    Get started
                  </PrimaryButton>
                </div>
              </Carousel>
            </div>
          </ActionModal>

          {/* Announcement Panel */}
          {/* <Row>
            <Col
              span={24}
              className=" ph-x2-large pv-medium bordered-top bordered-bottom bg-orange-lightest border-orange-lightest"
            >
              <div className="flex-row">
                <div className="pt-x-small pr-medium">
                  <Icon type={'warning'} theme="filled" className="text-size-x3-large text-color-warning-orange" />
                </div>

                <div>
                  <Title level={4} weight="bold" color="black" className="mb-none">
                    NDIS Price Guide Update
                  </Title>

                  <Text color="black">
                    Your GoodHuman workspace has been automatically updated with the new NDIA 2021-22 price limits{' '}
                    <b>(effective from July 1, 2021)</b>.
                    <br />
                    To learn more about the 2021-22 Price Guide,{' '}
                    <HyperlinkButton
                      onClick={() => {
                        window.open('https://www.ndis.gov.au/providers/price-guides-and-pricing', '_blank');
                      }}
                    >
                      click here
                    </HyperlinkButton>
                    .
                  </Text>
                </div>
              </div>
            </Col>
          </Row> */}

          {/* Welcome Panel */}
          <Row>
            <Col span={24} className="bg-white ph-x2-large pt-x-large pb-medium ">
              <Row type={'flex'} align={'bottom'} justify={'center'}>
                <Col span={12} className="" style={{ height: '100%' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
                    <div>
                      <Title level={3} className="mt-none mb-x-small line-height-150" weight="light" font="lato">
                        Hello,
                        <span className="text-weight-bold">&nbsp;{portalUser.firstName}!</span>
                      </Title>

                      <Text size="large" className="line-height-150">
                        You are logged in to
                        <Text weight="bold">&nbsp;{portalUser.serviceProviderName}</Text>
                      </Text>
                    </div>
                  </div>
                </Col>
                <Col span={12}>
                  {/* <div className="text-align-right">
                  <div className="flex-row justify-end">
                    <Text size="large" className="hover-text-underline cursor-pointer">
                      You have{' '}
                      <Text weight="black" size="x-large">
                        23
                      </Text>{' '}
                      unread notifications.
                    </Text>
                  </div>
                </div> */}
                </Col>
              </Row>

              {/*<Divider />*/}
            </Col>
          </Row>

          {/* Panel Widgets */}
          <div style={{ margin: '4px 40px' }}>
            <Row gutter={24}>
              {/* Left Panels */}
              <Col span={14}>
                <Row gutter={8}>
                  <Col span={24}>
                    {PermissionUtils.validatePermission(
                      'ViewBookingListing',
                      portalUser.permissions.permissionRoles
                    ) && (
                      <>
                        <div className="mt-x-large mb-small">
                          <SubTitle>SUMMARY FOR THIS WEEK</SubTitle>
                        </div>

                        <div className="ph-medium pt-large pb-large bg-white rounded shadow-box">
                          <Row type={'flex'}>
                            <Col span={8}>
                              <Route
                                render={() => (
                                  <div
                                    className="text-align-center"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.onSetBookingFilter('PENDING')}
                                  >
                                    <Text className="text-color-secondary letter-spacing-wide" size="regular">
                                      PENDING BOOKINGS
                                    </Text>
                                    <br />
                                    <Text size="x6-large" weight="black" className="line-height-135">
                                      {!_.isEmpty(dashboardData) ? dashboardData.pendingNumber : '--'}
                                    </Text>
                                  </div>
                                )}
                              />
                            </Col>
                            <Col span={8}>
                              <Route
                                render={() => (
                                  <div
                                    className="text-align-center"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.onSetBookingFilter('CONFIRMED')}
                                  >
                                    <Text className="text-color-secondary letter-spacing-wide" size="regular">
                                      ACTIVE BOOKINGS
                                    </Text>
                                    <br />
                                    <Text size="x6-large" weight="black" className="line-height-135">
                                      {!_.isEmpty(dashboardData) ? dashboardData.confirmedNumber : '--'}
                                    </Text>
                                  </div>
                                )}
                              />
                            </Col>
                            <Col span={8}>
                              <Route
                                render={() => (
                                  <div
                                    className="text-align-center"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.onSetBookingFilter('COMPLETED')}
                                  >
                                    <Text className="text-color-secondary letter-spacing-wide" size="regular">
                                      COMPLETED BOOKINGS
                                    </Text>
                                    <br />
                                    <Text size="x6-large" weight="black" className="line-height-135">
                                      {!_.isEmpty(dashboardData) ? dashboardData.completeNumber : '--'}
                                    </Text>
                                  </div>
                                )}
                              />
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>

                {/*<div className="mt-x-large mb-small">*/}
                {/*  <SubTitle>COMPANIES OVERVIEW</SubTitle>*/}
                {/*</div>*/}

                {/*<div className="ph-medium pv-large bg-white rounded shadow-box">*/}
                {/*  <CompaniesOverviewSection />*/}
                {/*</div>*/}

                {/* {PermissionUtils.validatePermission('ViewBookingListing', portalUser.permissions.permissionRoles) && (
                  <>
                    <div className="mt-x-large mb-small">
                      <SubTitle>BOOKINGS AT A GLANCE</SubTitle>
                    </div>

                    <div className="ph-medium pv-large bg-white rounded shadow-box">
                      <BookingListingSection
                        dashboardData={dashboardData}
                        history={this.props.history}
                        portalUser={portalUser}
                      />
                    </div>
                  </>
                )} */}

                {PermissionUtils.validatePermission('ViewCustomerListing', portalUser.permissions.permissionRoles) && (
                  <>
                    <div className="mt-x-large mb-small">
                      <SubTitle>CUSTOMERS AT A GLANCE</SubTitle>
                    </div>

                    <div className="ph-medium pv-large bg-white rounded shadow-box">
                      <CustomersGlanceSection
                        customersAtAGlance={this.props.customersAtAGlance}
                        goToCustomer={this._goToCustomer}
                        serviceProviderTimezone={this.props.companyDataLite && this.props.companyDataLite.timezone}
                      />
                      {/*<CustomersGlanceDemoSection />*/}
                    </div>
                  </>
                )}

                {/*<div*/}
                {/*  style={{ border: '1px dashed', backgroundColor: 'transparent' }}*/}
                {/*  className="border-gray-lighter mt-x3-large ph-medium rounded pv-small"*/}
                {/*>*/}
                {/*  <div className="flex-row align-center">*/}
                {/*    <div className="flex-row pr-large">*/}
                {/*      <img src={AirSupportSVG} alt="" className="width-full" style={{ height: '250px' }} />*/}
                {/*    </div>*/}
                {/*    <div className="flex-column flex-grow">*/}
                {/*      <div className="pb-medium">*/}
                {/*        <Title level={3}>New to GoodHuman?</Title>*/}
                {/*        <Text>*/}
                {/*          Don't fret. Take a tour to discover our latest features, and fast track your business with ease.*/}
                {/*        </Text>*/}
                {/*      </div>*/}
                {/*      <div className="text-align-left">*/}
                {/*        <SecondaryButton size="large" icon="right" iconPosition="right" color="black">*/}
                {/*          Get Started*/}
                {/*        </SecondaryButton>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </Col>

              {/* Right Panels */}
              <Col span={10}>
                <div className="mt-x-large mb-small">
                  <SubTitle>Notifications at a glance</SubTitle>
                </div>
                {/* <div className="mt-x-large mb-small"></div> */}
                <NotificationListingSection />
                <br />
                {/*<div className="bg-transparent mt-x-large rounded pv-medium ph-large mt-large">*/}
                {/*  <div className="text-align-center">*/}
                {/*    <Title level={3}>Impact Assessment</Title>*/}
                {/*  </div>*/}

                {/*  <div className="pv-large">*/}
                {/*    <img src={BusinessPlanSVG} alt="" className="width-full" style={{ height: '250px' }} />*/}
                {/*  </div>*/}

                {/*  <div className="text-align-center pb-large">*/}
                {/*    <Paragraph>See how you measure up.</Paragraph>*/}

                {/*    <Paragraph>*/}
                {/*      Take a real time assessment of the impact your company <br />*/}
                {/*      is having in your community.*/}
                {/*    </Paragraph>*/}
                {/*  </div>*/}

                {/*  <div className="text-align-center">*/}
                {/*    <SecondaryButton*/}
                {/*      color="black"*/}
                {/*      size="large"*/}
                {/*      icon={'right'}*/}
                {/*      iconPosition="right"*/}
                {/*      paddingSize="x-large"*/}
                {/*    >*/}
                {/*      Find out more*/}
                {/*    </SecondaryButton>*/}
                {/*  </div>*/}
                {/*</div>*/}

                {/*<div*/}
                {/*  // style={{ border: '1px dashed', backgroundColor: 'transparent' }}*/}
                {/*  className="border-gray-lighter mt-x3-large pv-large ph-x-large rounded bg-white"*/}
                {/*>*/}
                {/*  <div className="flex-row align-center">*/}
                {/*    /!*<div className="flex-row pr-large">*!/*/}
                {/*    /!*  <img src={AirSupportSVG} alt="" className="width-full" style={{ height: '250px' }} />*!/*/}
                {/*    /!*</div>*!/*/}
                {/*    <div className="flex-column flex-grow">*/}
                {/*      <div className="pb-medium">*/}
                {/*        <Title level={3}>New to GoodHuman?</Title>*/}
                {/*        <Text>*/}
                {/*          Don't fret. Take a tour to discover our latest features, and fast track your business with*/}
                {/*          ease.*/}
                {/*        </Text>*/}
                {/*      </div>*/}
                {/*      <div className="text-align-right">*/}
                {/*        <SecondaryButton size="large" icon="right" iconPosition="right" color="black">*/}
                {/*          Get Started*/}
                {/*        </SecondaryButton>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </Col>
            </Row>
          </div>
        </DefaultLayout>
      )
    );
  }
}

const mapState = (state: IRootState) => ({
  state: state,
  currentUser: state.authStore.currentUser,
  portalUser: state.authStore.portalUser,
  dashboardData: state.bookingsStore.dashboardData,
  customersAtAGlance: state.customersStore.customersAtAGlance,
  companyDataLite: state.companyStore.companyDataLite
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchDashboardData: dispatch.bookingsStore.doFetchDashboardData,
  doFetchCustomersAtAGlance: dispatch.customersStore.doFetchCustomersAtAGlance,
  setBookingFilter: dispatch.bookingsStore.setBookingsFilter,
  doSetInstructionToViewed: dispatch.teamStore.doSetInstructionToViewed,
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite,
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys
});

export default connect(
  mapState,
  mapDispatch
)(TestDashboardView);
