import { Icon } from 'antd';
import React, { PureComponent } from 'react';
import { Popover2 } from '@blueprintjs/popover2';
import CommonUtils from 'utilities/common-utils';
import { timeZone } from 'interfaces/timezone-type';
import { FilterItemMenu } from 'common-components/filter';
import { IFilter } from 'interfaces/filter-interfaces';

interface IActiveFilterItemProps {
  filter: IFilter;
  isOpen: boolean;
  setIsOpen: (filterType, isOpen) => void;
  saveNewFilterValue: (filterType, newFilterValue, selectionLabel) => void;
  removeFilter: (filterType) => void;
  displayTimezone: timeZone;
  usePortal: boolean;
  canRemove?: boolean;
}

interface IActiveFilterItemState {
  loading: boolean;
}

class ActiveFilterItem extends PureComponent<IActiveFilterItemProps, IActiveFilterItemState> {
  state = { loading: false, isOpen: false };

  render() {
    const { filter, usePortal } = this.props;

    return (
      <Popover2
        content={
          <FilterItemMenu
            filter={this.props.filter}
            saveNewFilterValue={this.props.saveNewFilterValue}
            removeFilter={this.props.removeFilter}
            canRemove={this.props.canRemove}
            displayTimezone={this.props.displayTimezone}
          />
        }
        popoverClassName={'mb-medium'}
        position={'bottom-left'}
        isOpen={this.props.isOpen}
        interactionKind="click"
        usePortal={usePortal}
        onInteraction={(nextOpenState) => this.props.setIsOpen(filter.filter, nextOpenState)}
      >
        <div
          className={`${
            !this.props.isOpen
              ? 'bg-blue-action-lightest text-color-blue-action mb-small'
              : 'bg-blue-action text-color-white border-blue-action'
          } mh-x-small ph-small pv-x-small inline-block cursor-pointer rounded select-none`}
        >
          <b>{CommonUtils.getFilterSettings(filter.filter).title}:</b> {filter.selectionLabel}
          <Icon type={'down'} className={'text-size-small ml-small'} />
        </div>
      </Popover2>
    );
  }
}

export default ActiveFilterItem;
