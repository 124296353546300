import React, { Component } from 'react';
import DefaultLayout from 'layouts/DefaultLayout';
import PermissionUtils from 'utilities/permission-utils';
import { userPermissions } from 'interfaces/user-permission-type';
import { UserPermission } from 'src/interfaces/user-permission-type';
import _ from 'lodash';
import { Col, Row } from 'antd';
import { Title, Text } from 'common-components/typography';

class TestPermissionView extends Component<any, any> {
  render() {
    const permissionRoles = [
      // { permissionRole: 1, serviceDepartmentId: null, serviceId: null },
      { permissionRole: 9, serviceDepartmentId: '1', serviceId: null },
      { permissionRole: 5, serviceDepartmentId: '2', serviceId: 's1' }
    ];

    return (
      <DefaultLayout backgroundColor={'white'}>
        <Title level={4}>Test Permission:</Title>

        <div style={{ top: 0, position: 'sticky', zIndex: 20 }}>
          <pre>{JSON.stringify(permissionRoles)}</pre>
        </div>
        <br />
        {_.map(userPermissions, (p, key: UserPermission) => {
          return (
            <Row className="bordered-bottom pv-small" style={{ maxWidth: '800px' }}>
              <Col span={10}>{key.toString()}</Col>
              <Col span={6}>{JSON.stringify(p.permissionRoles)}</Col>
              <Col span={4}>{p.requireValidateServiceAccess ? 'True' : 'False'}</Col>
              <Col span={4}>
                {PermissionUtils.validatePermission(key, permissionRoles, '2', 's1') ? (
                  <Text color={'green'}>Passed</Text>
                ) : (
                  <Text color={'red'}>denied</Text>
                )}
              </Col>
            </Row>
          );
        })}
      </DefaultLayout>
    );
  }
}

export default TestPermissionView;
