import React, { Component } from 'react';
import { Text, Title } from 'common-components/typography';
import { Information, Warning } from 'common-components/alerts';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Input } from 'antd';
import _ from 'lodash';
import GroupServiceTeamMemberListingSection from './GroupServiceTeamMemberListingSection';
import * as H from 'history';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { IGroupServiceTeamMember } from 'interfaces/service-interfaces';
import AddTeamMemberToRoasterModal from './AddTeamMemberToRosterModal';
import DeleteTeamMemberFromRosterModal from './DeleteTeamMemberFromRosterModal';
import AssignTeamMemberToSessionModal from './AssignTeamMemberToSessionModal';
import InfoPanel from 'common-components/alerts/InfoPanel';

const { Search } = Input;

interface IGroupServiceTeamMemberPanelProps {
  history: H.History;
  serviceId: string;
  groupServiceWorkerList: typeof state.servicesStore.groupServiceWorkerList;
  filteredGroupServiceWorkerList: typeof state.servicesStore.filteredGroupServiceWorkerList;
  doFetchGroupServiceTeamMembers: typeof dispatch.servicesStore.doFetchGroupServiceTeamMembers;
  doSearchGroupServiceTeamMembers: typeof dispatch.servicesStore.doSearchGroupServiceTeamMembers;
  doRemoveTeamMemberFromRoster: typeof dispatch.servicesStore.doRemoveTeamMemberFromRoster;
  setGroupServiceWorkerList: typeof dispatch.servicesStore.setGroupServiceWorkerList;
}

interface IGroupServiceTeamMemberPanelState {
  isSearching: boolean;
  isLoading: boolean;
  isOpenAddTeamMemberToRoasterModal: boolean;
  isOpenDeleteTeamMemberFromRosterModal: boolean;
  isOpenAssignTeamMemberToSessionModal: boolean;
  selectedTeamMember: IGroupServiceTeamMember;
  searchText: string;
}

class GroupServiceTeamMemberPanel extends Component<
  IGroupServiceTeamMemberPanelProps,
  IGroupServiceTeamMemberPanelState
> {
  state = {
    isSearching: false,
    isLoading: false,
    isOpenAddTeamMemberToRoasterModal: false,
    isOpenDeleteTeamMemberFromRosterModal: false,
    isOpenAssignTeamMemberToSessionModal: false,
    selectedTeamMember: null,
    searchText: null
  };

  private _searchText = async (txt) => {
    this.setState({ isSearching: true, searchText: txt, isLoading: true });
    const payload = { searchString: txt, serviceId: this.props.serviceId };
    await this.props.doSearchGroupServiceTeamMembers(payload);
    this.setState({ isSearching: false, isLoading: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _onOpenAddTeamMemberToRoasterModal = () => {
    this.setState({ isOpenAddTeamMemberToRoasterModal: true });
  };

  private _onOpenAssignTeamMemberToSessionModal = () => {
    this.setState({ isOpenAssignTeamMemberToSessionModal: true });
  };

  private _onCloseAddTeamMemberToRoasterModal = () => {
    this.setState({ isOpenAddTeamMemberToRoasterModal: false });
  };

  private _onCloseDeleteTeamMemberToRoasterModal = () => {
    this.setState({ isOpenDeleteTeamMemberFromRosterModal: false, selectedTeamMember: null });
  };

  private _onCloseAssignTeamMemberToSessiionModal = () => {
    this.setState({ isOpenAssignTeamMemberToSessionModal: false });
  };

  private _onOpenRemoveTeamMemberModal = (member: IGroupServiceTeamMember) => {
    this.setState({ selectedTeamMember: member, isOpenDeleteTeamMemberFromRosterModal: true });
  };

  private _onRemoveTeamMember = () => {
    const selectedTeamMember = this.state.selectedTeamMember;
    const payload = { serviceId: this.props.serviceId, selectedTeamMember };
    this.props.doRemoveTeamMemberFromRoster(payload);
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    await this.props.doFetchGroupServiceTeamMembers({ serviceId: this.props.serviceId });
    this.setState({ isLoading: false });
  };

  render() {
    const { groupServiceWorkerList, filteredGroupServiceWorkerList } = this.props;
    const isEmptyGroupServiceWorkerList = _.isEmpty(groupServiceWorkerList);

    const warningContent = (
      <>
        <Text color="orange">No team members in roster</Text>
        <br />
        <Text>Click the ‘Add to roster’ button to add team members.</Text>
      </>
    );

    const informationContent = (
      <span className="text-color-secondary">
        Team members that are added to this roster can then be assigned to
        <span className="text-weight-bold"> shifts</span> in <span className="text-weight-bold"> sessions</span>.
      </span>
    );

    return (
      <div className="pt-x-large pl-medium width-full pb-x3-large">
        <AddTeamMemberToRoasterModal
          isOpen={this.state.isOpenAddTeamMemberToRoasterModal}
          onClose={this._onCloseAddTeamMemberToRoasterModal}
          groupServiceWorkerList={groupServiceWorkerList}
          serviceId={this.props.serviceId}
          searchText={this.state.searchText}
        />
        <DeleteTeamMemberFromRosterModal
          isOpen={this.state.isOpenDeleteTeamMemberFromRosterModal}
          onClose={this._onCloseDeleteTeamMemberToRoasterModal}
          selectedTeamMember={this.state.selectedTeamMember}
          onRemoveMember={this._onRemoveTeamMember}
        />
        <AssignTeamMemberToSessionModal
          isOpen={this.state.isOpenAssignTeamMemberToSessionModal}
          onClose={this._onCloseAssignTeamMemberToSessiionModal}
        />
        {/*<Title level={4} className="mb-large">*/}
        {/*  Team Members*/}
        {/*</Title>*/}
        {!this.state.isLoading && isEmptyGroupServiceWorkerList && (
          <Warning content={warningContent} className="width-full mb-x-large" />
        )}

        <div className="bg-white ph-large pt-large pb-x2-large shadow-container" style={{ borderRadius: '16px' }}>
          <div className="flex-row justify-between align-center">
            <div>
              <Text weight="bold">Team member roster</Text> <br />
              <Text>Team members for this service</Text>
            </div>
            <div className="flex-row align-center">
              <SecondaryButton
                size="large"
                disabled={isEmptyGroupServiceWorkerList}
                onClick={this._onOpenAssignTeamMemberToSessionModal}
              >
                Assign to sessions
              </SecondaryButton>

              <PrimaryButton
                size="large"
                icon={'plus'}
                className="ml-medium"
                onClick={this._onOpenAddTeamMemberToRoasterModal}
              >
                Add to roster
              </PrimaryButton>
            </div>
          </div>

          {/*<Information content={informationContent} className="mv-large width-full" />*/}
          <div className="mv-large">
            <InfoPanel text={informationContent} />
          </div>

          <Search
            placeholder="Search for ...."
            size="large"
            onChange={this._onEnterSearchText}
            loading={this.state.isSearching}
            // style={{ width: '280px' }}
            allowClear
          />

          <GroupServiceTeamMemberListingSection
            filteredGroupServiceWorkerList={filteredGroupServiceWorkerList}
            isLoading={this.state.isLoading}
            history={this.props.history}
            onOpenRemoveTeamMemberModal={this._onOpenRemoveTeamMemberModal}
          />
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceWorkerList: state.servicesStore.groupServiceWorkerList,
  filteredGroupServiceWorkerList: state.servicesStore.filteredGroupServiceWorkerList
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupServiceTeamMembers: dispatch.servicesStore.doFetchGroupServiceTeamMembers,
  doSearchGroupServiceTeamMembers: dispatch.servicesStore.doSearchGroupServiceTeamMembers,
  doRemoveTeamMemberFromRoster: dispatch.servicesStore.doRemoveTeamMemberFromRoster,
  setGroupServiceWorkerList: dispatch.servicesStore.setGroupServiceWorkerList
});

export default connect(
  mapState,
  mapDispatch
)(GroupServiceTeamMemberPanel);
