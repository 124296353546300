import { ProgressBar } from '@blueprintjs/core';
import { Col, Icon, Row, Skeleton, Tabs } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import DocumentsPanel from 'common-components/documents/DocumentsPanel';
import { Text } from 'common-components/typography';
import * as H from 'history';
import CenteredLayout from 'layouts/CenteredLayout';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { BookingStatus, BookingType, TimezoneSelectorMode } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import ActivityRecordOverviewTab from './sections/ActivityRecordOverviewTab';
import BookingDetailOverviewTabV2 from './sections/BookingDetailOverviewTabV2';
import BookingActivityLogPanel from './sections/content-section/tabs-panel/BookingActivityLogPanel';
import BookingBillingPanel from './sections/content-section/tabs-panel/BookingBillingPanel';
import BookingNotesPanel from './sections/content-section/tabs-panel/BookingNotesPanel';

const { TabPane } = Tabs;

interface IBookingUrlParams {
  bookingId: string;
}

interface IBookingDetailsViewV2Props extends RouteComponentProps<IBookingUrlParams, {}, { from?: any; refresh?: any }> {
  doFetchBooking: typeof dispatch.bookingsStore.doFetchSingleBooking;
  doAddNote: typeof dispatch.bookingsStore.doAddNote;
  doEditNote: typeof dispatch.bookingsStore.doEditNote;
  selectedBookingItem: any;
  history: H.History;
  portalUser: typeof state.authStore.portalUser;
  bookingDisplayTzMode: typeof state.bookingsStore.bookingDisplayTzMode;
  bookingDisplayTzCustom: typeof state.bookingsStore.bookingDisplayTzCustom;
  setBookingDocuments: typeof dispatch.bookingsStore.setBookingDocuments;
  doDownloadDocument: typeof dispatch.bookingsStore.doDownloadDocument;
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;
}

class BookingDetailsViewV2 extends Component<IBookingDetailsViewV2Props, any> {
  state = {
    isLoading: false,
    activeKey: 'overview',
    bookingDisplayTzCustom: this.props.bookingDisplayTzCustom
      ? this.props.bookingDisplayTzCustom
      : this.props.portalUser.timezone,
    mode: this.props.bookingDisplayTzMode ? this.props.bookingDisplayTzMode : TimezoneSelectorMode.BookingTimezone,
    timezone: this.props.portalUser.timezone
  };

  private _getDisplayTimezone = () => {
    if (this.props.bookingDisplayTzMode === TimezoneSelectorMode.BookingTimezone) {
      return null;
    } else if (
      this.props.bookingDisplayTzMode === TimezoneSelectorMode.CustomTimezone &&
      this.props.bookingDisplayTzCustom
    ) {
      return this.props.bookingDisplayTzCustom;
    } else {
      return this.props.portalUser.timezone;
    }
  };

  private _onRefresh = async () => {
    const { match, doFetchBooking } = this.props;
    const { params } = match;
    const selectedBookingId = params.bookingId;
    await doFetchBooking({ bookingId: selectedBookingId });
  };

  async componentDidMount() {
    const { match, doFetchBooking } = this.props;
    const { params } = match;
    const selectedBookingId = params.bookingId;
    this.props.setSelectedSideNavMenuKeys(['/bookings']);
    this.setState({ timezone: this._getDisplayTimezone(), isLoading: true });
    await doFetchBooking({ bookingId: selectedBookingId });
    await this.setState({ isLoading: false });
  }

  async componentDidUpdate(prevProps) {
    const { match, doFetchBooking } = this.props;
    const previousMatch = prevProps.match;
    const { state }: { state: any } = this.props.location;

    if ((previousMatch && previousMatch.params.bookingId !== match.params.bookingId) || state?.refresh) {
      this.setState({ isLoading: true });

      // lets reset the state here so that the componentDidUpdate will not loop forever
      if (state?.refresh) {
        this.props.history.replace(this.props.location.pathname, {});
      }
      await doFetchBooking({ bookingId: match.params.bookingId });
      await this.setState({ isLoading: false });
    }

    if (
      prevProps &&
      prevProps.selectedBookingItem &&
      (prevProps.selectedBookingItem.startDateTime !== this.props.selectedBookingItem.startDateTime ||
        prevProps.selectedBookingItem.endDateTime !== this.props.selectedBookingItem.endDateTime)
    ) {
      await doFetchBooking({ bookingId: match.params.bookingId });
      await this.setState({ isLoading: false });
    }
  }

  _navigateTab = (tabKey) => {
    this.setState({ activeKey: tabKey });
  };

  private _onChangeTimezone = async (type, value) => {
    if (type === 'mode') {
      this.setState({
        mode: value,
        timezone:
          value === TimezoneSelectorMode.MyTimezone
            ? this.props.portalUser.timezone
            : value === TimezoneSelectorMode.BookingTimezone
            ? null
            : this.state.bookingDisplayTzCustom
            ? this.state.bookingDisplayTzCustom
            : this.state.timezone
      });
    } else if (type === 'customTimezone') {
      this.setState({ timezone: value });
    }
  };

  render() {
    const { history, selectedBookingItem, doAddNote } = this.props;
    let timezoneData = null;
    if (selectedBookingItem) {
      // TimezoneData for display in subSections
      const displayTimezone = this.state.timezone ? this.state.timezone : selectedBookingItem.timezone;
      timezoneData = {
        displayTimezone,
        isTimezoneIndicatorDisplayed:
          this.state.mode === TimezoneSelectorMode.BookingTimezone &&
          moment.tz(this.props.portalUser.timezone).format('Z') !== moment.tz(displayTimezone).format('Z'),
        bookingTimezone: selectedBookingItem.timezone
      };
    }

    const from = this.props.location?.state?.from as any;

    const isBookingArchived = selectedBookingItem ? selectedBookingItem.status === BookingStatus.ARCHIVED : false;

    const hasEditBookingNotesPermission = selectedBookingItem
      ? PermissionUtils.validatePermission(
          'EditBookingCaseNote',
          this.props.portalUser.permissions.permissionRoles,
          selectedBookingItem.serviceDepartmentId,
          selectedBookingItem.serviceId
        ) && selectedBookingItem.status !== BookingStatus.ARCHIVED
      : false;
    return (
      <CenteredLayout backgroundColor="white" bordered>
        {/* Top Actions Panel */}
        {/*<BookingTopActions />*/}
        {/* Booking Nav Menu */}
        {this.state.isLoading && (
          <>
            <div className="item-container">
              <div className="pb-medium">
                <Text>Fetching booking data...</Text>
              </div>
              <ProgressBar />
            </div>
            <Skeleton loading={this.state.isLoading} active avatar title paragraph />
          </>
        )}
        {!this.state.isLoading && !_.isEmpty(selectedBookingItem) && (
          <>
            <Row>
              {/* Booking Content */}
              <Col sm={24} md={24}>
                {/* Actual Content */}
                <div className="mb-medium">
                  {!_.isEmpty(from) ? (
                    <HyperlinkButton onClick={() => history.push(from.url)}>
                      <Icon type="left" /> {from.linkLabel}
                    </HyperlinkButton>
                  ) : (
                    <HyperlinkButton onClick={() => history.push('/bookings')}>
                      <Icon type="left" /> Back to Booking List
                    </HyperlinkButton>
                  )}
                </div>

                {/*<Tabs></Tabs>*/}
                <Tabs renderTabBar={() => <div />} activeKey={this.state.activeKey} type="card" animated={false}>
                  <TabPane key="overview" tab="overview">
                    {selectedBookingItem.bookingType === BookingType.BOOKING ? (
                      <BookingDetailOverviewTabV2
                        history={history}
                        selectedBookingItem={selectedBookingItem}
                        navigateTab={this._navigateTab}
                        mode={this.state.mode}
                        timezone={this.state.timezone}
                        userTimezone={this.props.portalUser.timezone}
                        bookingDisplayTzCustom={this.state.bookingDisplayTzCustom}
                        onChangeTimezone={this._onChangeTimezone}
                        isBookingArchived={isBookingArchived}
                      />
                    ) : (
                      <ActivityRecordOverviewTab
                        history={history}
                        selectedBookingItem={selectedBookingItem}
                        navigateTab={this._navigateTab}
                        mode={this.state.mode}
                        timezone={this.state.timezone}
                        userTimezone={this.props.portalUser.timezone}
                        bookingDisplayTzCustom={this.state.bookingDisplayTzCustom}
                        onChangeTimezone={this._onChangeTimezone}
                        isBookingArchived={isBookingArchived}
                      />
                    )}
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
            <Row className="mb-x2-large">
              <Col>
                <Tabs type="card">
                  <TabPane
                    key="billing"
                    tab="Billing"
                    className="bordered ph-x-large pv-large"
                    style={{ marginTop: '-17px' }}
                  >
                    <BookingBillingPanel
                      bookingItem={selectedBookingItem}
                      showSidebar={false}
                      timezoneData={timezoneData}
                      hasEditBillingLineItemPermission={hasEditBookingNotesPermission}
                      isBookingArchived={isBookingArchived}
                    />
                  </TabPane>
                  <TabPane
                    key="notes"
                    tab="Notes"
                    className="bordered ph-x-large pv-large"
                    style={{ marginTop: '-2px' }}
                  >
                    <BookingNotesPanel
                      onAddNote={doAddNote}
                      bookingNotes={selectedBookingItem.notes}
                      onRefresh={this._onRefresh}
                      timezoneData={timezoneData}
                      hasEditBookingNotesPermission={hasEditBookingNotesPermission}
                      serviceName={selectedBookingItem.serviceName}
                      bookingStartDateTime={selectedBookingItem.startDateTime}
                      selectedBookingItem={selectedBookingItem}
                      bookingId={this.props.match.params.bookingId}
                      isBookingArchived={isBookingArchived}
                      noteType={
                        selectedBookingItem.bookingType === BookingType.ACTIVITY_RECORD
                          ? BookingType.ACTIVITY_RECORD
                          : BookingType.BOOKING
                      }
                    />
                  </TabPane>
                  <TabPane
                    key="attachment"
                    tab="Attachments"
                    className="bordered ph-x-large pv-large"
                    style={{ marginTop: '-2px' }}
                  >
                    <DocumentsPanel
                      selectedItemId={selectedBookingItem.bookingId}
                      itemType={
                        selectedBookingItem.bookingType === BookingType.ACTIVITY_RECORD ? 'activity_record' : 'booking'
                      }
                      itemDocuments={_.map(selectedBookingItem.bookingDocuments, (document) => {
                        if (document.noteId) {
                          return {
                            ...document,
                            noteDetail: _.find(selectedBookingItem.notes, (note) => note.noteId === document.noteId)
                          };
                        } else {
                          return { ...document };
                        }
                      })}
                      setDocuments={this.props.setBookingDocuments}
                      doDownloadDocument={this.props.doDownloadDocument}
                      hasEditPermission={hasEditBookingNotesPermission}
                      isDateAddedDisplayed={false}
                      isExpiryDisplayed={false}
                      isSearchable={false}
                      useAttachmentText={true}
                    />
                  </TabPane>
                  <TabPane
                    key="activity-log"
                    tab="Activity Logs"
                    className="bordered ph-x-large pv-large"
                    style={{ marginTop: '-2px' }}
                  >
                    <BookingActivityLogPanel
                      bookingHistory={selectedBookingItem.bookingHistories}
                      onRefresh={this._onRefresh}
                      timezoneData={timezoneData}
                      isActivityRecord={selectedBookingItem.bookingType === BookingType.ACTIVITY_RECORD}
                    />
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </>
        )}
      </CenteredLayout>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBookingItem: state.bookingsStore.selectedBookingItem,
  portalUser: state.authStore.portalUser,
  bookingDisplayTzMode: state.bookingsStore.bookingDisplayTzMode,
  bookingDisplayTzCustom: state.bookingsStore.bookingDisplayTzCustom
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchBooking: dispatch.bookingsStore.doFetchSingleBooking,
  doAddNote: dispatch.bookingsStore.doAddNote,
  doEditNote: dispatch.bookingsStore.doEditNote,
  doDownloadDocument: dispatch.bookingsStore.doDownloadDocument,
  setBookingDocuments: dispatch.bookingsStore.setBookingDocuments,
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys
});

export default connect(mapState, mapDispatch)(BookingDetailsViewV2);
