import React, { Component } from 'react';
import { Col, Row, Icon, Popover } from 'antd';
import { FieldLabel, Text } from 'common-components/typography';
import { AgreementStatusTag } from 'views/customers/details/tabs-panel/new-service-agreements/agreements-listing/AgreementStatusTag';
import { SecondaryButton } from 'common-components/buttons';
import { ServiceAgreementDetails } from 'views/customers/details/tabs-panel/new-service-agreements/agreements-listing/ServiceAgreementDetails';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import asyncDelay from 'utilities/asyncDelay';
import { IServiceAgreement } from 'interfaces/customer-interfaces';
import ViewServiceAgreementsLineItemsModal from '../common-modals/ViewServiceAgreementLineItemsModal';
import { CustomerAlertType, MmmGroup, ServiceAgreementStatus, ServiceLocationType } from 'utilities/enum-utils';
import ServiceAgreementHistoryListModal from './ServiceAgreementHistoryListModal';
import _ from 'lodash';

interface IServiceAgreementItemProps {
  item: IServiceAgreement;
  onClickActionModal: (item, action, value) => void;
  doFetchServiceAgreementDetails: typeof dispatch.customersStore.doFetchServiceAgreementDetails;
  hasEditPermission: boolean;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  companyDataLite: typeof state.companyStore.companyDataLite;
  isServiceAgreementDetailsOpen: boolean;
}

interface IServiceAgreementItemState {
  isServiceAgreementDetailsOpen: boolean;
  isViewModalOpen: boolean;
  selectedService: any;
  isHistoryModalOpen: boolean;
  locationMmmGroup: MmmGroup;
  locationState: string;
}

class ServiceAgreementItem extends Component<IServiceAgreementItemProps, IServiceAgreementItemState> {
  state = {
    isServiceAgreementDetailsOpen: false,
    isViewModalOpen: false,
    selectedService: null,
    isHistoryModalOpen: false,
    locationMmmGroup: MmmGroup.NonRemote,
    locationState: null
  };
  divRef = null;

  private _toggleOpen = () =>
    this.setState({ isServiceAgreementDetailsOpen: !this.state.isServiceAgreementDetailsOpen });

  // Action for clicking on expand button.
  private _onClickOpenToggle = async () => {
    if (!this.state.isServiceAgreementDetailsOpen) {
      await this.props.doFetchServiceAgreementDetails({
        serviceAgreementId: this.props.item.userServiceAgreementId
      });
    }
    this._toggleOpen();
    await asyncDelay(0); // wait required to allow the view  to change to new height before scrolling
    this.divRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  private _onClickOpen = async () => {
    if (!this.state.isServiceAgreementDetailsOpen) {
      await this.props.doFetchServiceAgreementDetails({
        serviceAgreementId: this.props.item.userServiceAgreementId
      });

      this._toggleOpen();
      await asyncDelay(0); // wait required to allow the view  to change to new height before scrolling
      this.divRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  private _openViewModal = (service) => {
    this.setState({ isViewModalOpen: true, selectedService: service });
  };

  private _onCloseViewModal = () => {
    this.setState({ isViewModalOpen: false, selectedService: null });
  };

  private _setLocationGroupAndState = () => {
    const { selectedCustomer } = this.props;
    const { selectedService } = this.state;

    let locationMmmGroup = MmmGroup.NonRemote;
    let locationState = null;
    if (selectedService) {
      if (selectedService.serviceDirection === ServiceLocationType.FIXEDVENUE) {
        locationMmmGroup = selectedService.mmmGroup;
        locationState = selectedService.state;
      } else {
        const customerPrimaryAddress = _.find(selectedCustomer.addresses, (address) => address.isPrimary);
        if (customerPrimaryAddress && customerPrimaryAddress.state) {
          locationMmmGroup = customerPrimaryAddress.mmmGroup;
          locationState = customerPrimaryAddress.state;
        }
      }
    }
    this.setState({ locationMmmGroup, locationState });
  };

  private _alertDescription(alertType: CustomerAlertType) {
    if (alertType === CustomerAlertType.OverBudgetActual) {
      return (
        <>
          This service agreement is <b>overbudget.</b>
          <br />
        </>
      );
    }
    if (alertType === CustomerAlertType.OverBudgetForecasted) {
      return (
        <>
          This service agreement is <b>forecasted to be overbudget.</b>
          <br />
        </>
      );
    }
    return '-';
  }

  componentDidMount() {
    if (this.props.isServiceAgreementDetailsOpen && !this.state.isServiceAgreementDetailsOpen) {
      this._onClickOpen();
    }
  }

  componentDidUpdate(
    prevProps: Readonly<IServiceAgreementItemProps>,
    prevState: Readonly<IServiceAgreementItemState>,
    snapshot?: any
  ) {
    if (prevState.selectedService !== this.state.selectedService) {
      this._setLocationGroupAndState();
    }
    if (this.props.item && this.props.item !== prevProps.item) {
      this.setState({ isServiceAgreementDetailsOpen: false });
    }
  }

  render() {
    const { locationState, locationMmmGroup } = this.state;
    const closeClasses = 'bg-quaternary cursor-pointer';
    const openClasses = 'bg-white shadow-box';

    const viewClasses = this.state.isServiceAgreementDetailsOpen ? openClasses : closeClasses;
    const { item, hasEditPermission } = this.props;
    const customerAlerts =
      item.alertTypes &&
      item.alertTypes.filter(
        (alertType) =>
          alertType === CustomerAlertType.OverBudgetActual || alertType === CustomerAlertType.OverBudgetForecasted
      );

    return (
      <div
        className={`border-standard-gray bordered justify-between rounded p-large align-center mb-large ${viewClasses}`}
        onClick={(e) => {
          e.stopPropagation();
          this._onClickOpen();
        }}
        ref={(r) => (this.divRef = r)}
      >
        {this.state.isHistoryModalOpen && (
          <ServiceAgreementHistoryListModal
            isOpen={this.state.isHistoryModalOpen}
            serviceAgreementId={item.userServiceAgreementId}
            onClose={() => this.setState({ isHistoryModalOpen: false })}
            openViewModal={this._openViewModal}
            onClickActionModal={this.props.onClickActionModal}
          />
        )}
        <ViewServiceAgreementsLineItemsModal
          selectedServiceLineItems={this.state.selectedService ? this.state.selectedService.lineItems : []}
          additionalChargeItems={this.state.selectedService ? this.state.selectedService.additionalCharges : null}
          isOpen={this.state.isViewModalOpen}
          onClose={this._onCloseViewModal}
          displayMode={'VIEW'}
          locationMmmGroup={locationMmmGroup}
          locationState={locationState}
          paymentSourceType={item.paymentSourceType}
        />
        {/* Top Content */}
        <div className="flex-row">
          <div className="flex-row flex-1">
            <Row type="flex" className="flex-1" align="middle" gutter={16}>
              {/* Date */}
              <Col span={9}>
                {item.startDate ? (
                  <Text size="x-large">
                    {moment.tz(item.startDate, this.props.companyDataLite.timezone).format('D MMMM YYYY') +
                      ' - ' +
                      moment.tz(item.endDate, this.props.companyDataLite.timezone).format('D MMMM YYYY')}
                  </Text>
                ) : (
                  <Text color={'secondary'}>No date set.</Text>
                )}
                <br />
                {item.status === 'EXPIRING_SOON' ? (
                  <>
                    <Text size="medium" className="text-color-red-dark">
                      (Expiring soon)
                    </Text>
                    <br />
                  </>
                ) : (
                  ''
                )}
                <Text size="x-large" weight="bold">
                  {item.paymentSourceType}
                </Text>
              </Col>

              {/* Status */}
              <Col span={3}>
                <div>
                  <FieldLabel text="Status" />
                </div>

                {/* Status Tag */}
                <AgreementStatusTag status={item.status} />
              </Col>

              {/* Signed */}
              <Col span={3}>
                <div>
                  <FieldLabel text="Signed" />
                </div>

                {/* Status Tag */}
                {item.signedStatus === ServiceAgreementStatus.SIGNED && (
                  <>
                    <Icon type="check" className="text-color-blue" />
                    <Text className="ml-x-small">Yes</Text>
                  </>
                )}
                {item.signedStatus !== ServiceAgreementStatus.SIGNED && (
                  <>
                    <Icon type="clock-circle" />
                    <Text className="ml-x-small">No</Text>
                  </>
                )}
              </Col>

              {/* Services */}
              <Col span={7}>
                <FieldLabel text="Number of service(s)" />
                <Text>
                  <b>{item.numberOfServices}</b>{' '}
                  <Text color="secondary">Service{item.numberOfServices === 1 ? '' : 's'}</Text>
                </Text>
              </Col>

              {/* Alerts */}
              <Col span={2} className="text-align-center">
                {customerAlerts && customerAlerts.length > 0 ? (
                  <Popover
                    content={customerAlerts.map((alertType) => this._alertDescription(alertType))}
                    title={<b>Alerts</b>}
                  >
                    <Text className="cursor-pointer">
                      <Icon
                        className="mh-small text-color-warning-orange text-size-x2-large"
                        type="warning"
                        theme="filled"
                      />{' '}
                    </Text>
                  </Popover>
                ) : (
                  ''
                )}
              </Col>
            </Row>
          </div>

          <div style={{ width: '120px' }} className="text-align-right">
            <SecondaryButton
              icon="down"
              iconPosition="right"
              color="secondary"
              onClick={(e) => {
                e.stopPropagation();
                this._onClickOpenToggle();
              }}
            >
              {this.state.isServiceAgreementDetailsOpen ? 'Hide' : 'Show'}
            </SecondaryButton>
          </div>
        </div>

        {/* Bottom Content */}
        {this.state.isServiceAgreementDetailsOpen && (
          <div
            className="mt-large bordered-top"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <ServiceAgreementDetails
              item={item}
              onClickActionModal={this.props.onClickActionModal}
              onClickViewVersionHistory={() =>
                this.setState({
                  isHistoryModalOpen: true
                })
              }
              hasEditPermission={hasEditPermission}
              openViewModal={this._openViewModal}
              companyData={this.props.companyDataLite}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedCustomer: state.customersStore.selectedCustomer,
  companyDataLite: state.companyStore.companyDataLite
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServiceAgreementDetails: dispatch.customersStore.doFetchServiceAgreementDetails
});

export default connect(
  mapState,
  mapDispatch
)(ServiceAgreementItem);
