import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { notification } from 'antd';
import { Paragraph, FieldLabel, Text } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import DatePicker from 'react-datepicker';
import TimeInput from 'common-components/time-input/TimeInput';
import moment from 'moment-timezone';
import CommonUtils from 'utilities/common-utils';

interface StartGroupBookingModalProps {
  isOpen: any;
  onClose: any;
  booking: any;
  doStartGroupBooking: any;
}
interface StartGroupBookingModalState {
  step: number;
  canManuallyClose: boolean;
  title: string;
  startDateTime: Date;
  isLoading: boolean;
}

export class StartGroupBookingModal extends Component<StartGroupBookingModalProps, StartGroupBookingModalState> {
  state = { step: 1, canManuallyClose: true, title: '', startDateTime: null, isLoading: false };

  private _updateStartDate = (event) => {
    this.setState({
      startDateTime: event
    });
  };

  private _onChangeStartTime = async (date) => {
    const { booking } = this.props;
    const startDateTime = moment.tz(CommonUtils.formatCeilingDateTime(date), booking.timezone);

    this.setState({
      startDateTime: startDateTime.toDate()
    });
  };

  renderView = () => {
    const { step } = this.state;
    const { booking } = this.props;
    const { firstName, lastName, timezone } = booking;
    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <Paragraph>Please input the date/time the customer started this session.</Paragraph>

          <div className="mt-small line-height-120 bg-quaternary p-medium bordered border-standard-gray rounded-big">
            <div className="flex-row">
              <div className="flex-1 mr-large">
                <div className="flex-row mt-x2-small">
                  <div>
                    <FieldLabel text={'start date'} />
                    <DatePicker
                      dateFormat="d/M/yyyy"
                      className="gh-datepicker rounded mr-small"
                      calendarClassName="gh-datepicker-calendar"
                      placeholderText="Start date"
                      onChange={this._updateStartDate}
                      selected={this.state.startDateTime}
                    />
                  </div>
                  <div>
                    <FieldLabel text={'customer start time'} />
                    <TimeInput
                      size="large"
                      className="bg-white"
                      value={moment.tz(this.state.startDateTime, timezone)}
                      onChange={this._onChangeStartTime}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal} disabled={this.state.isLoading}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" icon="check" onClick={this.onAccept} disabled={this.state.isLoading}>
              Start booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <Paragraph>
            You have started {firstName} {lastName}'s booking for this session.
          </Paragraph>

          <FieldLabel text={'start time'} />
          <Text weight="bold">{moment.tz(this.state.startDateTime, timezone).format('hh:mm a, DD MMM YYYY')}</Text>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen && this.props.booking) {
      const { booking } = this.props;
      this.setState({
        step: 1,
        title: 'Start booking',
        startDateTime: moment
          .tz(CommonUtils.formatCeilingDateTime(new Date(booking.startDateTime)), booking.timezone)
          .toDate()
      });
    }
  }

  onAccept = async () => {
    const { booking } = this.props;
    try {
      this.setState({ canManuallyClose: false, isLoading: true });
      await this.props.doStartGroupBooking({
        serviceId: booking.serviceId,
        serviceDateTimeId: booking.serviceDateTimeId,
        attendances: [
          {
            attendanceId: booking.bookingId,
            startDateTime: this.state.startDateTime
          }
        ]
      });
      this.setState({ step: 2, canManuallyClose: true, title: 'Booking started', isLoading: false });
    } catch (e) {
      this.setState({ canManuallyClose: true, isLoading: false });
      if (e.meta.message === 'This session is not in status INPROGRESS or COMPLETED') {
        notification.error({ message: 'Please check the session status before starting a booking.' });
      } else {
        notification.error({ message: 'Oops, something went wrong, please try again.' });
      }
    }
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, canManuallyClose: true, title: '' }, onClose);
  };

  render() {
    let { isOpen } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this.renderView()}</div>
      </ActionModal>
    );
  }
}
