import React, { PureComponent } from 'react';
import CommonUtils from '../../utilities/common-utils';
import TextTag from 'common-components/tags/TextTag';
import { GroupServiceSessionStatus } from 'utilities/enum-utils';

interface ISessionStatusTagProps {
  status: string;
  textSize?: string;
  className?: any;
  textClassName?: any;
  rounded?: boolean;
  weight?: string;
  size?: 'default' | 'small' | string;
}

export default class SessionStatusTag extends PureComponent<ISessionStatusTagProps, any> {
  BgStatusColors = {
    SCHEDULED: 'bg-green-lightest',
    INPROGRESS: 'bg-orange-lightest',
    COMPLETED: 'bg-blue-lightest',
    CLOSED: 'bg-secondary',
    CANCELLED: 'bg-red'
  };

  MainColors = {
    SCHEDULED: 'green',
    INPROGRESS: 'orange',
    COMPLETED: 'blue',
    CLOSED: 'white',
    CANCELLED: 'white'
  };

  render() {
    // const { status, textSize = 'x-large', className = '', textClassName = '', rounded = true } = this.props;
    //
    // const bgColor = this.BgStatusColors[status];
    // const mainColor = this.MainColors[status];
    // const roundedClass = rounded ? 'rounded' : '';
    //
    // let sizeClasses = 'ph-small ';
    //
    // const containerClassNames = `text-align-center ${sizeClasses} inline-block bordered border-${mainColor} ${roundedClass} ${bgColor} ${className}`;
    // const textClassNames = `text-color-${mainColor} ${textClassName}`;
    //
    // return (
    //   <div className={containerClassNames}>
    //     <Text size={textSize} className={textClassNames}>
    //       {CommonUtils.formatSessionStatusString(status)}
    //     </Text>
    //   </div>
    // );

    const { status, textSize = 'x-large', size = 'small', weight = 'regular', className } = this.props;

    let color = 'blue';
    let theme = 'light';

    switch (status) {
      case GroupServiceSessionStatus.SCHEDULED:
        color = 'green';
        theme = 'light';
        break;
      case GroupServiceSessionStatus.INPROGRESS:
        color = 'orange';
        theme = 'light';
        break;
      case GroupServiceSessionStatus.COMPLETED:
        color = 'blue';
        theme = 'light';
        break;
      case GroupServiceSessionStatus.CLOSED:
        color = 'blue';
        theme = 'dark';
        break;
      case GroupServiceSessionStatus.CANCELLED:
        color = 'red';
        theme = 'dark';
        break;
    }

    return (
      <TextTag
        content={CommonUtils.formatSessionStatusString(status)}
        color={color}
        theme={theme}
        className={className}
        size={size}
        weight={weight}
        textSize={textSize}
      />
    );
  }
}
