// App Permissions Panel
import { IPermissions } from 'interfaces/account-interfaces';
import { Text } from 'common-components/typography';
import { SegmentedItem } from 'views/account-management/add-new-member/panels/permissions-panel/portal/SegmentedItem';
import React from 'react';

export function AppPermissionsPanel({
  permissions,
  onChangePermissions,
  disabled
}: {
  permissions: IPermissions;
  onChangePermissions: any;
  isPrimaryOwner: boolean;
  disabled?: boolean;
}) {
  function selectWorkerAccess(value) {
    const updatedPermissions: IPermissions = { ...permissions, hasWorkerAppAccess: value };
    onChangePermissions(updatedPermissions);
  }

  return (
    <div className="bg-white p-large bordered border-standard-gray rounded mb-large">
      <div className="flex-row justify-between">
        <div>
          <div className="mb-medium">
            <Text size="x2-large">Business App</Text>
          </div>
          <Text>
            Can this person access the Business app and provide <br /> services to the Customers?
          </Text>
        </div>

        {/* Selection : Allowed / not allowed */}

        <div className="flex-row">
          {(!disabled || permissions.hasWorkerAppAccess) && (
            <SegmentedItem
              text={'Allowed'}
              isSelected={permissions.hasWorkerAppAccess}
              onSelect={disabled ? () => false : () => selectWorkerAccess(true)}
            />
          )}
          {(!disabled || !permissions.hasWorkerAppAccess) && (
            <SegmentedItem
              text={'Not Allowed'}
              // value={'not-allowed'}
              isSelected={!permissions.hasWorkerAppAccess}
              onSelect={disabled ? () => false : () => selectWorkerAccess(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
