import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { Checkbox, Form, Icon, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Information } from 'common-components/alerts';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

interface IArchiveServiceDepartmentModalProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  selectedServiceDepartment: any;
  resetServiceDepartmentList: () => void;
  doArchiveServiceDepartment: typeof dispatch.servicesStore.doArchiveServiceDepartment;
}

interface IArchiveServiceDepartmentModalState {
  title: any;
  isArchived: boolean;
}

class ArchiveServiceDepartmentModal extends Component<
  IArchiveServiceDepartmentModalProps,
  IArchiveServiceDepartmentModalState
> {
  state = { title: 'Archive service department', isArchived: false };

  private _onCloseModal = () => {
    this.setState({ title: 'Archive service department', isArchived: false });
    this.props.onClose();
  };

  private _archiveDepartment = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        await this.props.doArchiveServiceDepartment({
          serviceDepartmentId: this.props.selectedServiceDepartment.serviceDepartmentId
        });
        this.setState({
          title: (
            <>
              Service department archived successfully
              <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
            </>
          ),
          isArchived: true
        });
        this.props.resetServiceDepartmentList();
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
  };

  private _validateAcknowledge = (rule, value, callback) => {
    try {
      if (!value) {
        throw Error('In order to continue you must acknowledge this statement.');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  render() {
    const { isOpen, selectedServiceDepartment, form } = this.props;
    const { getFieldDecorator } = form;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {this.state.isArchived ? (
          <>
            <div className="mv-medium anim-slide-left">
              <div>
                <SubTitle>Service department name</SubTitle>
                <Title level={4}>{selectedServiceDepartment.serviceDepartmentName}</Title>
              </div>

              <ActionModalFooter>
                <PrimaryButton size="large" onClick={this._onCloseModal}>
                  Done
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        ) : selectedServiceDepartment && selectedServiceDepartment.hasActiveServices ? (
          <>
            <div className="mv-medium anim-slide-left">
              <div>
                <Information
                  content={
                    <>
                      <Paragraph weight={'bold'}>
                        You cannot archive a service department which has active services in it.
                      </Paragraph>
                      <Paragraph>
                        In order to archive this service department please either assign all existing services to other
                        and/or archive all services in this department
                      </Paragraph>
                    </>
                  }
                />
              </div>

              <ActionModalFooter>
                <PrimaryButton size="large" onClick={this._onCloseModal}>
                  Ok
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        ) : (
          <>
            <div className="mv-medium anim-slide-left">
              <div>
                <Text>
                  You are choosing to archive the{' '}
                  <b>{selectedServiceDepartment ? selectedServiceDepartment.departmentName : ''}</b> service department.
                </Text>

                <Form.Item className="width-full">
                  {getFieldDecorator('acknowledgment', { rules: [{ validator: this._validateAcknowledge }] })(
                    <Checkbox>I understand that this action is permanent and cannot be undone</Checkbox>
                  )}
                </Form.Item>
              </div>

              <ActionModalFooter>
                <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._archiveDepartment}>
                  Archive service department
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doArchiveServiceDepartment: dispatch.servicesStore.doArchiveServiceDepartment
});

export default connect(
  null,
  mapDispatch
)(Form.create<IArchiveServiceDepartmentModalProps>()(ArchiveServiceDepartmentModal));
