/* eslint-disable */
import React, { Component } from 'react';

import { Button, Checkbox, Col, DatePicker, Drawer, Row } from 'antd';

import { Text, Title } from 'common-components/typography';
import SectionContainer from 'common-components/containers/SectionContainer';
import CheckboxGroup from 'antd/lib/checkbox/Group';
import moment, { Moment } from 'moment';
import { connect } from 'react-redux';

import { IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';

const { RangePicker } = DatePicker;

interface IBookingApproveFilterDrawerProps {
  onApplyFilter: (filter: any) => void;
  onCloseFilterDrawer: () => void;
  filterDrawerVisible: boolean;
  billingBookingsFilter: any;
}

interface IBookingApproveFilterDrawerState {
  bookingStatus: Array<string>;
  bookingDateRange: Array<Moment>;
  serviceId: Array<any>;
  customerUserId: Array<any>;
}

class BookingApproveFilterDrawer extends Component<IBookingApproveFilterDrawerProps, IBookingApproveFilterDrawerState> {
  state = {
    bookingStatus: [],
    bookingDateRange: [],
    serviceId: [],
    customerUserId: []
  };

  _onChangeBookingStatus = (value) => {
    this.setState({ bookingStatus: value });
  };

  _onChangeDateRange = (value) => {
    let date = [];
    if (value[0]) {
      date = [value[0].startOf('day'), value[1].endOf('day')];
    }

    this.setState({ bookingDateRange: date });
  };

  _onChangeCustomerName = (value) => {
    this.setState({ customerUserId: value });
  };

  _onChangeServiceList = (value) => {
    this.setState({ serviceId: value });
  };

  _onClickClearList = () => {
    this.setState({ serviceId: [], customerUserId: [], bookingDateRange: [], bookingStatus: [] });
  };

  _onApplyFilter = async () => {
    const { onCloseFilterDrawer } = this.props;

    this.props.onApplyFilter({
      bookingStatus: this.state.bookingStatus,
      bookingDateRange: this.state.bookingDateRange
    });
    onCloseFilterDrawer();
  };

  componentDidMount = () => {
    const { billingBookingsFilter } = this.props;
    let bookingDateRange = [];
    if (billingBookingsFilter.dateRange.length > 0) {
      bookingDateRange = [
        moment(billingBookingsFilter.dateRange[0].value),
        moment(billingBookingsFilter.dateRange[1].value)
      ];
    }
    this.setState({
      bookingStatus: billingBookingsFilter.status.map((i) => i.value),
      bookingDateRange: bookingDateRange
    });
  };

  render() {
    const { onCloseFilterDrawer, filterDrawerVisible } = this.props;

    return (
      <Drawer title={'FILTER OPTIONS'} onClose={onCloseFilterDrawer} visible={filterDrawerVisible} width={'50vw'}>
        <Title level={3}>Show bookings with : </Title>
        <SectionContainer bordered>
          <Row>
            <Col span={8}>
              <Text>Booking Start Date Range</Text>
            </Col>
            <Col span={16}>
              <RangePicker onChange={(value) => this._onChangeDateRange(value)} value={this.state.bookingDateRange} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col span={8}>
              <Text>Booking Status</Text>
            </Col>
            <Col span={16}>
              <CheckboxGroup onChange={(value) => this._onChangeBookingStatus(value)} value={this.state.bookingStatus}>
                <Checkbox value={'COMPLETED'}>{CommonUtils.formatStatusString('COMPLETED')}</Checkbox>
                <Checkbox value={'APPROVED'}>{CommonUtils.formatStatusString('APPROVED')}</Checkbox>
                <Checkbox value={'BILLING_FAILED'}>{CommonUtils.formatStatusString('BILLING_FAILED')}</Checkbox>
                <Row type={'flex'} justify={'start'} />
              </CheckboxGroup>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col>
              <Button.Group>
                <Button onClick={this._onApplyFilter} type={'primary'}>
                  Apply Filter
                </Button>
                <Button onClick={this._onClickClearList}>Clear Filter</Button>
              </Button.Group>
            </Col>
          </Row>
        </SectionContainer>
      </Drawer>
    );
  }
}

const mapState = (state: IRootState) => ({
  billingBookingsFilter: state.billingsStore.billingBookingsFilter
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchBillingLineItemsToApprove: dispatch.billingsStore.doFetchBillingLineItemsToApprove,
  setBillingBookingFilter: dispatch.billingsStore.setBillingBookingsFilter
});

export default connect(
  mapState,
  mapDispatch
)(BookingApproveFilterDrawer);
