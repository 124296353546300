import React, { Component } from 'react';
import { Col, DatePicker, Icon, notification, Row, Tooltip, Divider } from 'antd';
import { connect } from 'react-redux';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Title from 'antd/lib/typography/Title';
import _ from 'lodash';
import moment from 'moment';
import CommonUtils from 'utilities/common-utils';
import AddEditTimeSlotsModal from 'views/group-services/service-details/Create/components/AddEditTimeSlotsModal';
import { IGroupServiceTimeslot } from 'interfaces/service-interfaces';

interface IScheduleTimeSlotsStepProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setNewGroupServiceSchedule?: typeof dispatch.servicesStore.setNewGroupServiceSchedule;
  newGroupServiceSchedule: typeof state.servicesStore.newGroupServiceSchedule;
  history?: any;
}

interface IScheduleTimeSlotsStepState {
  isLoading: boolean;
  timeSlots: IGroupServiceTimeslot[];
  scheduleStartDate: any;
  scheduleEndDate: any;
  isAddEditTimeSlotOpen: boolean;
  isEdit: boolean;
  selectedTimeslot: IGroupServiceTimeslot;
  isNoTimeSlotsError: boolean;
  isEndDateExceedMaxError: boolean;
}

class ScheduleTimeSlotsStep extends Component<IScheduleTimeSlotsStepProps, IScheduleTimeSlotsStepState> {
  state = {
    isLoading: false,
    scheduleStartDate: this.props.newGroupServiceSchedule.scheduleStartDate
      ? moment(this.props.newGroupServiceSchedule.scheduleStartDate)
      : moment(),
    scheduleEndDate: this.props.newGroupServiceSchedule.scheduleEndDate
      ? moment(this.props.newGroupServiceSchedule.scheduleEndDate)
      : moment().add(1, 'week'),
    timeSlots: this.props.newGroupServiceSchedule.timeSlots ? this.props.newGroupServiceSchedule.timeSlots : [],
    isAddEditTimeSlotOpen: false,
    isEdit: false,
    selectedTimeslot: null,
    isNoTimeSlotsError: false,
    isEndDateExceedMaxError: false
  };

  private _onChangeStartingDate = (startingDate) => {
    let scheduleEndDate = moment(this.state.scheduleEndDate);
    let isEndDateExceedMaxError = false;
    if (scheduleEndDate < startingDate) {
      scheduleEndDate = moment(startingDate).add(1, 'day');
    } else if (moment(startingDate).add(2, 'year') < scheduleEndDate) {
      scheduleEndDate = moment(startingDate).add(2, 'year');
      isEndDateExceedMaxError = true;
    }
    this.setState({ scheduleStartDate: moment(startingDate), scheduleEndDate, isEndDateExceedMaxError });
  };

  private _onChangeEndingDate = (endingDate) => {
    this.setState({ scheduleEndDate: endingDate, isEndDateExceedMaxError: false });
  };

  private _openTimeslotModal = (isEdit = false, selectedTimeslot = null) => {
    this.setState({ isAddEditTimeSlotOpen: true, isEdit, selectedTimeslot });
  };

  private _closeTimeslotModal = () => {
    this.setState({ isAddEditTimeSlotOpen: false });
  };

  private _saveTimeSlot = (newTimeSlot, isEdit = false) => {
    if (isEdit) {
      this.setState({
        timeSlots: _.map(this.state.timeSlots, (timeslot) => {
          if (newTimeSlot.scheduleTimeSlotId === timeslot.scheduleTimeSlotId) {
            return { ...newTimeSlot };
          } else {
            return { ...timeslot };
          }
        })
      });
    } else {
      const timeSlots = _.clone(this.state.timeSlots);
      timeSlots.push(newTimeSlot);
      this.setState({ timeSlots, isNoTimeSlotsError: false });
    }
    this._closeTimeslotModal();
  };

  private _deleteTimeSlot = (scheduleTimeSlotId) => {
    this.setState({
      timeSlots: _.filter(this.state.timeSlots, (timeslot) => timeslot.scheduleTimeSlotId !== scheduleTimeSlotId)
    });
  };

  private _goToNext = async () => {
    const { setNewGroupServiceSchedule, newGroupServiceSchedule } = this.props;
    const { timeSlots, scheduleStartDate, scheduleEndDate, isLoading, isEndDateExceedMaxError } = this.state;

    let isFormValid = true;
    if (!timeSlots || timeSlots.length === 0) {
      isFormValid = false;
      this.setState({ isNoTimeSlotsError: true });
    }

    if (isEndDateExceedMaxError) {
      isFormValid = false;
    }

    if (isFormValid) {
      try {
        await setNewGroupServiceSchedule({
          ...newGroupServiceSchedule,
          scheduleStartDate: moment(scheduleStartDate).startOf('day'),
          scheduleEndDate: moment(scheduleEndDate).endOf('day'),
          timeSlots: _.map(timeSlots, (timeSlot) => {
            const timeSlotDay = moment(timeSlot.startDateTime).day();
            const scheduledDay = moment(scheduleStartDate).day();
            const scheduleDateAdjusted =
              scheduledDay > timeSlotDay ? moment(scheduleStartDate).add(1, 'week') : moment(scheduleStartDate);
            return {
              ...timeSlot,
              startDateTime: moment(timeSlot.startDateTime).set({
                date: moment(scheduleDateAdjusted)
                  .isoWeekday(timeSlotDay)
                  .date(),
                month: moment(scheduleDateAdjusted).month(),
                year: moment(scheduleDateAdjusted).year()
              }),
              endDateTime: moment(timeSlot.endDateTime).set({
                date: moment(scheduleDateAdjusted)
                  .isoWeekday(timeSlotDay)
                  .date(),
                month: moment(scheduleDateAdjusted).month(),
                year: moment(scheduleDateAdjusted).year()
              })
            };
          })
        });
        this.props.onNextStep();
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong! Please try again.' });
      }
    }
  };

  render() {
    const {
      isLoading,
      scheduleStartDate,
      scheduleEndDate,
      timeSlots,
      isAddEditTimeSlotOpen,
      isEdit,
      selectedTimeslot,
      isEndDateExceedMaxError
    } = this.state;

    const TimeSlotEmptyState = ({ onClick }) => (
      <div className="flex-1 bg-quaternary align-center flex-column bordered p-large rounded-big">
        <Text size="x-large" color="secondary">
          No sessions added yet.
        </Text>{' '}
        {/*<Text color="blue-action">Add a new time slot now.</Text>*/}
        <HyperlinkButton onClick={onClick} className="mt-x-small">
          <Icon type={'plus'} className={'mr-x-small'} />
          Add new sessions
        </HyperlinkButton>
      </div>
    );

    return (
      <div className="anim-slide-left">
        <AddEditTimeSlotsModal
          isOpen={isAddEditTimeSlotOpen}
          isEdit={isEdit}
          onCloseModal={this._closeTimeslotModal}
          onSaveTimeslot={this._saveTimeSlot}
          selectedTimeslot={selectedTimeslot}
        />
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>Create your schedule</Title>
              <Paragraph>
                Let us know how long this schedule goes for and then tell us when each session will occur.
              </Paragraph>
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white rounded-big p-large" style={{ minWidth: '250px' }}>
              <Title level={4}>Sessions</Title>
              <Paragraph>
                Please enter the <b>start/end date</b> for this schedule.
              </Paragraph>
              <div className="mb-large flex-row align-center">
                <div className={`mr-large ${isEndDateExceedMaxError && 'mb-large'}`}>
                  <SubTitle>Start date</SubTitle>
                  <div className="flex-row align-left">
                    <DatePicker
                      size="large"
                      format={'DD/MM/YYYY'}
                      allowClear={false}
                      value={scheduleStartDate}
                      disabledDate={(current) => {
                        return current < moment().startOf('day');
                      }}
                      onChange={(event) => this._onChangeStartingDate(event)}
                      className="mr-small"
                    />
                  </div>
                </div>
                <div>
                  <SubTitle>End date</SubTitle>
                  <div className="flex-row align-left">
                    <DatePicker
                      size="large"
                      value={scheduleEndDate}
                      allowClear={false}
                      format={'DD/MM/YYYY'}
                      disabledDate={(current) => {
                        return (
                          current < moment(scheduleStartDate).startOf('day') ||
                          current > moment(scheduleStartDate).add(2, 'years')
                        );
                      }}
                      onChange={(event) => this._onChangeEndingDate(event)}
                      className="mr-small"
                    />
                    <Tooltip
                      title={
                        <div className="p-medium">
                          <Text className="text-size-regular text-color-white">
                            You cannot create a schedule on more than 2 years.
                          </Text>
                        </div>
                      }
                      overlayStyle={{ opacity: 1 }}
                    >
                      <Icon
                        type="info-circle"
                        className="text-color-blue text-weight-bold"
                        style={{ marginTop: '12px' }}
                      />
                    </Tooltip>
                  </div>
                  {isEndDateExceedMaxError && (
                    <Text color={'red-dark'}>Date cannot be more than 2 years after from date.</Text>
                  )}
                </div>
              </div>
              <div className={'mt-large'}>
                <Divider className="divider-medium" />

                <Paragraph>The following sessions for this schedule will be created :</Paragraph>

                <div className="mb-x-small">
                  <SubTitle>Sessions</SubTitle>
                </div>
                {timeSlots && timeSlots.length > 0 ? (
                  _.map(timeSlots, (timeSlot, index) => {
                    return (
                      <div className={'mb-medium bordered border-standard-gray rounded-big bg-quaternary p-medium'}>
                        <div className={'flex-row justify-between'}>
                          <div>
                            <Text>
                              <Text color="black" weight="bold">
                                {CommonUtils.getRecurringPatternLabel(timeSlot.recurringPattern)}
                              </Text>{' '}
                              <br />{' '}
                              <span className="text-color-black">{moment(timeSlot.startDateTime).format('dddd')}</span>{' '}
                              from{' '}
                              <span className="text-color-black">
                                {moment(timeSlot.startDateTime).format('h:mm A')} to{' '}
                                {moment(timeSlot.endDateTime).format('h:mm A')}
                              </span>
                            </Text>
                          </div>
                          <div>
                            <HyperlinkButton
                              className={'mr-medium'}
                              onClick={() => this._openTimeslotModal(true, timeSlot)}
                            >
                              Edit
                            </HyperlinkButton>
                            <HyperlinkButton
                              color={'red'}
                              onClick={() => this._deleteTimeSlot(timeSlot.scheduleTimeSlotId)}
                            >
                              Delete
                            </HyperlinkButton>
                          </div>
                        </div>
                        <div>
                          <Text size="regular">
                            Capacity -{' '}
                            {timeSlot.capacity > 0 ? (
                              <Text size="regular">{timeSlot.capacity}</Text>
                            ) : (
                              <Text size="regular">No capacity limit</Text>
                            )}
                          </Text>
                        </div>
                        {timeSlot.description && <div className={'mt-medium'}>{timeSlot.description}</div>}
                      </div>
                    );
                  })
                ) : (
                  <TimeSlotEmptyState onClick={() => this._openTimeslotModal(false)} />
                )}
              </div>
              {timeSlots && timeSlots.length > 0 && (
                <HyperlinkButton className={'mt-small'} onClick={() => this._openTimeslotModal(false)}>
                  <Icon type={'plus'} className={'mr-small'} />
                  Add more sessions
                </HyperlinkButton>
              )}
              {this.state.isNoTimeSlotsError && (
                <div className={'mt-small'}>
                  <Text color={'red-dark'}>Please add at least one time slot.</Text>
                </div>
              )}
            </div>
            <div className="pv-medium width-full bg-tertiary" style={{ position: 'sticky', bottom: 0 }}>
              <Row gutter={0} type="flex" align="middle" justify={'space-between'} className="bg-transparent">
                <div className="text-align-right pv-medium">
                  <SecondaryButton size="large" disabled={isLoading} onClick={this.props.onPreviousStep}>
                    Back
                  </SecondaryButton>
                </div>
                <div className="text-align-right pv-medium">
                  <PrimaryButton size="large" loading={isLoading} onClick={this._goToNext}>
                    Next
                  </PrimaryButton>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewGroupServiceSchedule: dispatch.servicesStore.setNewGroupServiceSchedule
});

const mapState = (state: IRootState) => ({
  newGroupServiceSchedule: state.servicesStore.newGroupServiceSchedule
});

export default connect(
  mapState,
  mapDispatch
)(ScheduleTimeSlotsStep);
