import React, { Component } from 'react';
import { SubTitle, Text, Title } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import TermsConditionsEditor from 'views/customers/details/tabs-panel/service-agreement/TermsConditionsEditor';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Select } from 'antd';
import { Information } from 'common-components/alerts';

interface IInputTermsPanelProps {
  onPrevStep: any;
  onNextStep: any;
  newServiceAgreement: typeof state.customersStore.newServiceAgreement;
  setNewServiceAgreement: typeof dispatch.customersStore.setNewServiceAgreement;
  serviceAgreementTemplates: typeof state.accountStore.serviceAgreementTemplates;
  doGetServiceAgreementTemplates: typeof dispatch.accountStore.doGetServiceAgreementTemplates;
}

interface IInputTermsPanelState {
  isLoading: boolean;
  termsAndConditions: any;
}

class InputTermsPanel extends Component<IInputTermsPanelProps, IInputTermsPanelState> {
  state = {
    isLoading: false,
    termsAndConditions: ''
  };
  private editor: any;

  private _goToNextStep = async () => {
    const { setNewServiceAgreement, newServiceAgreement, onNextStep } = this.props;
    this.setState({ isLoading: true });

    const termsValidationResult = this.editor.validate();
    await setNewServiceAgreement({
      ...newServiceAgreement,
      termsConditions: termsValidationResult.content
    });

    onNextStep();
    this.setState({ isLoading: false });
  };

  private _onChangeTemplate = async (termsConditionsTemplateId) => {
    const selectedTemplate = _.find(
      this.props.serviceAgreementTemplates,
      (template) => template.termsConditionsTemplateId === termsConditionsTemplateId
    );
    this.setState({ termsAndConditions: selectedTemplate ? selectedTemplate.data : '' });
  };

  componentDidMount = async () => {
    const { newServiceAgreement } = this.props;
    await this.props.doGetServiceAgreementTemplates({});
    this.setState({
      termsAndConditions:
        newServiceAgreement && newServiceAgreement.termsConditions ? newServiceAgreement.termsConditions : ''
    });
  };

  render() {
    const { onPrevStep, serviceAgreementTemplates } = this.props;
    const { termsAndConditions } = this.state;

    return (
      <div className="anim-slide-left">
        <Title level={2}>
          Create <b>Service Agreement</b>
        </Title>
        <Text>
          Please enter your <b>terms & conditions</b> for this <b>Service Agreement</b>.
        </Text>

        <div className="mt-large mb-x2-large">
          {/* Content here */}
          <div className={'mb-large'}>
            <SubTitle>Select a template</SubTitle>
            {!_.isEmpty(serviceAgreementTemplates) ? (
              <>
                <Information
                  className={'mb-small'}
                  content={'Selecting a template will replace all text you have already entered.'}
                />
                <Select
                  size={'large'}
                  style={{ width: '530px' }}
                  onChange={this._onChangeTemplate}
                  placeholder={'Select a template...'}
                >
                  {_.map(serviceAgreementTemplates, (template: any) => (
                    <Select.Option value={template.termsConditionsTemplateId}>{template.name}</Select.Option>
                  ))}
                </Select>
              </>
            ) : (
              <div>
                <Text color={'secondary'}>
                  No templates created. Templates can be created in the account section of GoodHuman.
                </Text>
              </div>
            )}
          </div>
          {/* TODO : Make this taller. */}
          <SubTitle>Terms and conditions</SubTitle>
          <TermsConditionsEditor
            contentHtml={termsAndConditions ? termsAndConditions : ''}
            key={termsAndConditions}
            minHeight={'250px'}
            ref={(element) => {
              this.editor = element;
            }}
          />
        </div>

        <div className="mb-x2-large flex-row justify-between mt-x2-large">
          <SecondaryButton size="large" onClick={onPrevStep} className="mr-medium" icon="left" iconPosition={'left'}>
            Previous
          </SecondaryButton>

          <PrimaryButton size="large" onClick={this._goToNextStep} icon={'right'} iconPosition={'right'}>
            Next
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  newServiceAgreement: state.customersStore.newServiceAgreement,
  serviceAgreementTemplates: state.accountStore.serviceAgreementTemplates
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewServiceAgreement: dispatch.customersStore.setNewServiceAgreement,
  doGetServiceAgreementTemplates: dispatch.accountStore.doGetServiceAgreementTemplates
});

export default connect(
  mapState,
  mapDispatch
)(InputTermsPanel);
