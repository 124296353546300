import React, { Component } from 'react';
import { Avatar, Form, Input, notification, Select } from 'antd';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { SubTitle, Text } from 'common-components/typography';
import { ICustomer, IPreference } from 'interfaces/customer-interfaces';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { TeamStatus } from 'utilities/enum-utils';

const { TextArea } = Input;

interface IAddEditPreferencesModalProps extends FormComponentProps {
  closeAddEditPreferencesModal: () => void;
  isOpen: boolean;
  selectedCustomer: ICustomer;
  isEdit?: boolean;
  selectedPreference?: IPreference;
  preferenceMode: string;
  doAddCustomerPreference?: typeof dispatch.customersStore.doAddCustomerPreference;
  doUpdateCustomerPreference?: typeof dispatch.customersStore.doUpdateCustomerPreference;
  workerListLite: typeof state.teamStore.workerListLite;
  doFetchWorkerListLite: typeof dispatch.teamStore.doFetchWorkerListLite;
}

interface IAddEditPreferencesModalState {
  isLoading: boolean;
  isSearching: boolean;
  isValidationModalOpen: boolean;
}

class AddEditPreferencesModal extends Component<IAddEditPreferencesModalProps, IAddEditPreferencesModalState> {
  state = {
    isLoading: false,
    isSearching: true,
    isValidationModalOpen: false
  };

  private _closeModal = () => {
    this.setState({
      isLoading: false,
      isSearching: true,
      isValidationModalOpen: false
    });
    this.props.closeAddEditPreferencesModal();
  };

  private _checkChangesBeforeClose = () => {
    const { isEdit, form, selectedPreference } = this.props;
    if (isEdit) {
      if (form.getFieldValue('description') !== selectedPreference.description) {
        this.setState({ isValidationModalOpen: true });
      } else {
        this._closeModal();
      }
    } else {
      const supportWorkerId = form.getFieldValue('supportWorkerId');
      const description = form.getFieldValue('description');
      if (supportWorkerId && description && description !== '') {
        this.setState({ isValidationModalOpen: true });
      } else {
        this._closeModal();
      }
    }
  };

  private _closeValidationModal = () => {
    this.setState({ isValidationModalOpen: false });
  };

  private _savePreference = async () => {
    const {
      selectedCustomer,
      form,
      isEdit,
      selectedPreference,
      doUpdateCustomerPreference,
      doAddCustomerPreference,
      preferenceMode
    } = this.props;

    this.setState({ isLoading: true });

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      const formValues = form.getFieldsValue();
      try {
        const payload = isEdit
          ? {
              customerUserId: selectedCustomer.userId,
              carerPreferenceId: selectedPreference.carerPreferenceId,
              description: formValues.description,
              preference: preferenceMode
            }
          : {
              customerUserId: selectedCustomer.userId,
              supportWorkerId: formValues.supportWorkerId,
              description: formValues.description,
              preference: preferenceMode
            };
        isEdit ? await doUpdateCustomerPreference(payload) : await doAddCustomerPreference(payload);
        notification.success({ message: `Preference ${isEdit ? 'updated' : 'added'} successfully.` });
        this._closeModal();
      } catch (e) {
        notification.error({ message: 'Oops something went wrong! Please try again.' });
      }
    }
    this.setState({ isLoading: false });
  };

  componentDidMount = async () => {
    await this.props.doFetchWorkerListLite({
      hasAppAccess: ['WORKER'],
      supportWorkerStatus: [TeamStatus.ENABLED, TeamStatus.DRAFT, TeamStatus.BLOCKED]
    });
    this.setState({ isSearching: false });
  };

  render() {
    const { form, selectedCustomer, selectedPreference, isEdit, preferenceMode, workerListLite } = this.props;
    const { isLoading } = this.state;
    const { getFieldDecorator } = form;

    const isPreferred = preferenceMode === 'PREFERRED';
    const filteredWorkerList = _.filter(
      workerListLite,
      (worker) =>
        !_.find(
          selectedCustomer.preferences.preferredSupportWorkers,
          (preferred) => preferred.supportWorkerId === worker.supportWorkerId
        ) &&
        !_.find(
          selectedCustomer.preferences.blockedSupportWorkers,
          (blocked) => blocked.supportWorkerId === worker.supportWorkerId
        )
    );

    return (
      <div>
        <ActionModal
          isOpen={this.state.isValidationModalOpen}
          onClose={this._closeValidationModal}
          title={'Discard changes'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, proceeding will discard these changes.
          </Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeValidationModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size="large" onClick={this._closeModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this._checkChangesBeforeClose}
          title={`${isEdit ? 'Edit' : 'Add'} ${isPreferred ? 'preferred' : 'blocked'} team member`}
          showCloseButton={true}
          canCloseOutside={false}
          verticalAlignment={'center'}
          width={'large'}
        >
          <div className="mb-large">
            <div className={'mb-medium'}>
              <Text>
                {!isEdit
                  ? `Search for and add a ${isPreferred ? 'preferred' : 'blocked'} team member for this customer.`
                  : `Edit the comments for the ${isPreferred ? 'preferred' : 'blocked'} team member`}
              </Text>
            </div>
            {!isEdit ? (
              <>
                <SubTitle>Select team member</SubTitle>
                <Form.Item className="mb-large">
                  {getFieldDecorator('supportWorkerId', {
                    rules: [{ required: true, message: 'Please select a team member' }]
                  })(
                    <Select
                      showSearch={true}
                      placeholder="Search for..."
                      filterOption={true}
                      optionFilterProp={'children'}
                      style={{ width: '400px' }}
                      size={'large'}
                    >
                      {_.map(filteredWorkerList, (worker) => (
                        <Select.Option key={worker.supportWorkerId}>
                          {worker.firstName} {worker.lastName}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </>
            ) : (
              <>
                <SubTitle>Team member</SubTitle>
                <div className={'flex-row align-center'}>
                  <Avatar icon="user" className="mr-small" src={selectedPreference.attachmentUrl} />
                  {`${selectedPreference.firstName} ${selectedPreference.lastName}`}
                </div>
              </>
            )}
          </div>
          <div className="mb-x-large">
            <SubTitle>Comment (Optional)</SubTitle>
            <Form.Item className="m-none">
              {getFieldDecorator('description', {
                rules: [],
                initialValue: selectedPreference ? selectedPreference.description : null
              })(
                <TextArea
                  maxLength={250}
                  placeholder={'Add a comment here...'}
                  autoSize={{ minRows: 4, maxRows: 20 }}
                />
              )}
            </Form.Item>
            <Text color="secondary" size={'regular'}>
              Maximum characters: 250
            </Text>
          </div>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._checkChangesBeforeClose}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._savePreference} loading={isLoading}>
              Save
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  workerListLite: state.teamStore.workerListLite
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddCustomerPreference: dispatch.customersStore.doAddCustomerPreference,
  doUpdateCustomerPreference: dispatch.customersStore.doUpdateCustomerPreference,
  doFetchWorkerListLite: dispatch.teamStore.doFetchWorkerListLite
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IAddEditPreferencesModalProps>()(AddEditPreferencesModal));
