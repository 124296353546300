import { useQuery } from 'react-query';

import { fetchCustomerBookings, fetchShiftSlots } from 'stores/queries/group-services/group-services-queries';

export function useFetchCustomerBookingInSession({ serviceId, serviceDateTimeId }, enabled) {
  const { data, remove, refetch, isFetching, isLoading, status, error } = useQuery(
    ['customer-bookings', serviceDateTimeId],
    () => fetchCustomerBookings({ serviceId, serviceDateTimeId }),
    {
      enabled
    }
  );

  return { data, remove, refetch, isFetching, isLoading, status, error };
}

export function useFetchShiftSlotsInSession({ serviceId, serviceDateTimeId }, enabled) {
  const { data, remove, refetch, isFetching, isLoading, status, error } = useQuery(
    ['shift-slots', serviceDateTimeId],
    () => fetchShiftSlots({ serviceId, serviceDateTimeId }),
    {
      enabled
    }
  );

  return { data, remove, refetch, isFetching, isLoading, status, error };
}
