import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Avatar, Card, Checkbox, Form, Icon, Input, notification } from 'antd';
import { FieldLabel, Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import PermissionUtils from 'utilities/permission-utils';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import _ from 'lodash';
import moment from 'moment-timezone';
import { CustomerStatusType } from 'utilities/enum-utils';
import DatePicker from 'react-datepicker';
import { addDays } from '@fullcalendar/react';
import InfoPanel from 'common-components/alerts/InfoPanel';

interface IManageCustomerActionModelProps extends FormComponentProps {
  customerDetails: typeof state.customersStore.selectedCustomer;
  portalUser: typeof state.authStore.portalUser;
  companyDataLite: typeof state.companyStore.companyDataLite;
  isOpen: boolean;
  onClose: () => void;
  doAcceptEnquiry: typeof dispatch.customersStore.doAcceptEnquiry;
  doRevertEnquiry: typeof dispatch.customersStore.doRevertEnquiry;
  doSaveCustomerSource: typeof dispatch.customersStore.doSaveCustomerSource;
  doArchiveCustomer: typeof dispatch.customersStore.doArchiveCustomer;
  doReactivateCustomer: typeof dispatch.customersStore.doReactivateCustomer;
  doCancelScheduledArchive: typeof dispatch.customersStore.doCancelScheduledArchive;
}

class ManageCustomerActionModel extends Component<IManageCustomerActionModelProps, any> {
  /**
   * steps
   * 1    Landing
   * 2    Mark as Active
   * 3    Mark as Enquiry
   * 4    Edit Customer Source
   * 5    Success Marked As Active
   * 6    Success Marked As Enquiry
   */
  state = {
    step:
      this.props.customerDetails && this.props.customerDetails.customerStatus === CustomerStatusType.ARCHIVED
        ? 9
        : this.props.customerDetails.customerStatus === CustomerStatusType.SCHEDULED_TO_BE_ARCHIVED
        ? 11
        : 1,
    isAcceptingEnquiry: false,
    isRevertingEnquiry: false,
    isSavingCustomerSource: false,
    isArchivingCustomer: false,
    isReactivateCustomer: false,
    isCancellingScheduleArchive: false,
    archiveReason: null,
    isScheduleArchive: this.props.customerDetails.customerStatus === CustomerStatusType.SCHEDULED_TO_BE_ARCHIVED,
    archiveDate: this.props.customerDetails.archivedOn
      ? this.props.customerDetails.archivedOn
      : moment()
          .add(1, 'days')
          .toDate(),
    isEditArchiveDate: false
  };

  private _onCloseModel = () => {
    this.setState({ step: 1, isEditArchiveDate: false });
    this.props.onClose();
  };

  private _acceptEnquiry = async () => {
    try {
      this.setState({ isAcceptingEnquiry: true });
      await this.props.doAcceptEnquiry({ customerUserId: this.props.customerDetails.userId });
      this.setState({ isAcceptingEnquiry: false, step: 5 });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isAcceptingEnquiry: false });
    }
  };

  private _revertEnquiry = async () => {
    try {
      this.setState({ isRevertingEnquiry: true });
      await this.props.doRevertEnquiry({ customerUserId: this.props.customerDetails.userId });
      this.setState({ isRevertingEnquiry: false, step: 6 });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isRevertingEnquiry: false });
    }
  };

  private _onSaveCustomerSource = async () => {
    const { form, doSaveCustomerSource, customerDetails } = this.props;
    try {
      form.validateFieldsAndScroll(async (error, value) => {
        if (!error) {
          this.setState({ isSavingCustomerSource: true });
          let customerSource = form.getFieldValue('customerSource');
          if (customerSource === '') {
            customerSource = null;
          }
          const payload = {
            customerUserId: customerDetails.userId,
            customerSource
          };
          await doSaveCustomerSource(payload);
          this.setState({ isSavingCustomerSource: false, step: 1 });
        }
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isSavingCustomerSource: false });
    }
  };

  private _onArchiveCustomer = async () => {
    const { form, doArchiveCustomer, customerDetails, companyDataLite } = this.props;
    const { isScheduleArchive, archiveDate, archiveReason, step } = this.state;
    try {
      form.validateFieldsAndScroll(async (error, value) => {
        if (!error) {
          // if (this.state.isScheduleArchive || this.state.isEditArchiveDate) {
          if (isScheduleArchive && (step === 7 || step === 12)) {
            this.setState({ step: 15 });
          } else {
            this.setState({ isArchivingCustomer: true });
            const payload = {
              archiveReason: value.archiveReason ? value.archiveReason : archiveReason,
              customerUserId: customerDetails.userId,
              isScheduleArchive: isScheduleArchive,
              archiveDate: value.archiveDate
                ? moment.tz(value.archiveDate, companyDataLite.timezone).toISOString()
                : moment.tz(archiveDate, companyDataLite.timezone).toISOString()
            };
            await doArchiveCustomer(payload);
            this.setState({
              isArchivingCustomer: false,
              step: isScheduleArchive ? 14 : 8,
              archiveReason: value.archiveReason ? value.archiveReason : archiveReason,
              archiveDate: value.archiveDate
                ? moment.tz(value.archiveDate, companyDataLite.timezone)
                : moment.tz(archiveDate, companyDataLite.timezone)
            });
          }
        }
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isSavingCustomerSource: false });
    }
  };

  private _onReactivateCustomer = async () => {
    const { form, doReactivateCustomer, customerDetails } = this.props;
    try {
      form.validateFieldsAndScroll(async (error, value) => {
        if (!error) {
          this.setState({ isReactivateCustomer: true });
          await doReactivateCustomer({
            customerUserId: customerDetails.userId
          });
          this.setState({ isReactivateCustomer: false, step: 1 });
        }
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isSavingCustomerSource: false });
    }
  };

  private _onCancelScheduledArchive = async () => {
    const { form, doCancelScheduledArchive, customerDetails } = this.props;
    try {
      this.setState({ isCancellingScheduleArchive: true });
      await doCancelScheduledArchive({
        customerUserId: customerDetails.userId
      });
      this.setState({
        isCancellingScheduleArchive: false,
        archiveDate: moment()
          .add(1, 'days')
          .toDate(),
        step: 16
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isSavingCustomerSource: false });
    }
  };

  private _validateSourceInput = (rule, value, callback) => {
    const { form } = this.props;
    const formValue = form.getFieldValue('customerSource');
    if (formValue.length > 250) {
      callback('Please enter less than 250 characters.');
    } else {
      callback();
    }
  };

  private _validateArchiveReason = (rule, value, callback) => {
    try {
      if (_.isEmpty(_.trim(value)) || _.trim(value).length < 5) {
        throw Error('Please enter at least 5 characters');
      }
      this.setState({ archiveReason: value });
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _renderCurrentStatus = () => {
    const { customerDetails, portalUser } = this.props;

    const hasEditPermission = PermissionUtils.validatePermission(
      'EditCustomerProfile',
      portalUser.permissions.permissionRoles
    );

    if (customerDetails.customerStatus === CustomerStatusType.ENQUIRY) {
      return (
        <div>
          <div className="mt-x2-small">
            <Icon type={'phone'} />
            <Title level={4} className={'inline-block ml-x-small'} weight={'regular'}>
              Enquiry
            </Title>
          </div>
          {hasEditPermission && (
            <PrimaryButton onClick={() => this.setState({ step: 2 })} className={'mr-medium'}>
              Mark as active
            </PrimaryButton>
          )}
          {hasEditPermission && (
            <SecondaryButton onClick={() => this.setState({ step: 7 })}>Archive customer</SecondaryButton>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <div className={'mt-x2-small'}>
            <Icon type={'check'} />
            <Title level={4} className={'inline-block ml-x-small'} weight={'regular'}>
              Active
            </Title>
          </div>
          {hasEditPermission && (
            <PrimaryButton onClick={() => this.setState({ step: 7 })} className={'mr-medium'}>
              Archive customer
            </PrimaryButton>
          )}
          {hasEditPermission && (
            <SecondaryButton onClick={() => this.setState({ step: 3 })}>Mark as enquiry</SecondaryButton>
          )}
        </div>
      );
    }
  };

  private _renderCustomerSource = () => {
    const { customerDetails, portalUser } = this.props;

    const hasEditPermission = PermissionUtils.validatePermission(
      'EditCustomerProfile',
      portalUser.permissions.permissionRoles
    );

    if (customerDetails.customerSource !== null && customerDetails.customerSource !== '') {
      return (
        <div>
          <Title level={4} weight={'regular'} className={'mt-x2-small mb-none'}>
            {customerDetails.customerSource}
          </Title>
          {hasEditPermission && (
            <GhostButton paddingSize={'none'} icon={'edit'} onClick={() => this.setState({ step: 4 })}>
              Edit...
            </GhostButton>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <Title level={4} weight={'regular'} className={'mt-x2-small mb-none'} color={'secondary'}>
            Not set
          </Title>
          {hasEditPermission && (
            <GhostButton paddingSize={'none'} icon={'edit'} onClick={() => this.setState({ step: 4 })}>
              Add...
            </GhostButton>
          )}
        </div>
      );
    }
  };

  private _renderModalTitle = () => {
    const { step } = this.state;
    switch (step) {
      case 1:
        return 'Manage Customer';
      case 2:
        return 'Mark customer as Active';
      case 3:
        return 'Mark customer as Enquiry';
      case 4:
        return 'Manage Customer';
      case 5:
        return 'Customer marked as Active';
      case 6:
        return 'Customer marked as Enquiry';
      case 7:
        return 'Archive customer';
      case 8:
        return 'Customer successfully archived';
      case 9:
        return 'Manage Customer';
      case 10:
        return 'Reactivate Customer';
      case 11:
        return 'Manage Customer';
      case 12:
        return 'Archive customer';
      case 13:
        return 'Cancel scheduled archiving';
      case 14:
        return 'Scheduled archive date changed';
      case 15:
        return 'Archive customer at a future date';
      case 16:
        return 'Scheduled archiving cancelled';
      default:
        return '';
    }
  };

  private _renderModalContent = () => {
    const { step, archiveReason } = this.state;
    const { form, customerDetails } = this.props;
    const { getFieldDecorator } = form;
    switch (step) {
      case 1:
        return (
          <Card>
            <div>
              <FieldLabel text={'CURRENT STATUS'} />
              {this._renderCurrentStatus()}
            </div>
            <div className="mt-large">
              <FieldLabel text={'CUSTOMER SOURCE'} />
              {this._renderCustomerSource()}
            </div>
          </Card>
        );
      case 2:
        return (
          <div>
            <Text>
              Are you sure you want to mark this customer as Active? Doing so will move them to active section of
              GoodHuman
            </Text>
          </div>
        );

      case 3:
        return (
          <div>
            <Text>
              Are you sure you want to mark this customer as Enquiry? Doing so will move them to enquiry section of
              GoodHuman
            </Text>
          </div>
        );

      case 4:
        return (
          <div>
            <FieldLabel text={'customer source'} />
            <Form.Item>
              {getFieldDecorator('customerSource', {
                initialValue: customerDetails.customerSource,
                rules: [{ validator: this._validateSourceInput }]
              })(
                <Input
                  size={'large'}
                  placeholder={'Enter the source for this customer, eg Facebook...'}
                  className="mt-small"
                  style={{ width: 411 }}
                />
              )}
            </Form.Item>
          </div>
        );

      case 5:
        return (
          <div>
            <div>
              <Text>You have successfully marked this customer as Active.</Text>
            </div>
            <div className="mt-medium">
              <Text>The customer record can still be viewed in the Active section of GoodHuman.</Text>
            </div>
          </div>
        );

      case 6:
        return (
          <div>
            <div>
              <Text>You have successfully marked this customer as Enquiry.</Text>
            </div>
            <div>
              <Text>The customer record can be viewed in the Enquiry section of GoodHuman.</Text>
            </div>
          </div>
        );

      case 7:
        return (
          <div>
            <Paragraph>
              All upcoming bookings for the customer will be archived including group sessions they have been booked
              into.
            </Paragraph>
            <Paragraph>
              All historical data related to this customer will be maintaned and you can re-activate their account at a
              later date if you wish to.
            </Paragraph>
            <Form.Item>
              {getFieldDecorator('isScheduleArchive', {
                initialValue: this.state.isScheduleArchive
              })(
                <Text>
                  <Checkbox
                    defaultChecked={this.state.isScheduleArchive}
                    className="mr-small"
                    onChange={this._onCheckBoxChange}
                  />
                  Schedule this customer to be archived on the future date below
                </Text>
              )}
            </Form.Item>
            {this.state.isScheduleArchive && (
              <>
                <Form.Item>
                  {getFieldDecorator('archiveDate', {
                    initialValue: moment()
                      .add(1, 'days')
                      .startOf('day')
                  })(
                    <DatePicker
                      className="gh-datepicker rounded"
                      calendarClassName="gh-datepicker-calendar"
                      dateFormat="d/M/yyyy"
                      isClearable={false}
                      selected={
                        this.state.archiveDate
                          ? this.state.archiveDate
                          : moment()
                              .add(1, 'days')
                              .toDate()
                      }
                      onChange={this._udpateArchiveDate}
                      minDate={moment()
                        .add(1, 'days')
                        .toDate()}
                    />
                  )}
                </Form.Item>
                <InfoPanel
                  text={`The customer will be archived on the chosen date at 12:00am (UTC ${
                    moment.tz(this.state.archiveDate, this.props.companyDataLite.timezone).utcOffset() > 0 ? '+' : ''
                  }${moment.tz(this.state.archiveDate, this.props.companyDataLite.timezone).utcOffset() / 60} ).`}
                />
              </>
            )}

            <div className={'mt-large'}>
              <SubTitle>Reason</SubTitle>
              <Form.Item className="m-none">
                {getFieldDecorator('archiveReason', {
                  rules: [{ validator: this._validateArchiveReason }],
                  initialValue: undefined
                })(<TextArea placeholder={'Enter reason for archiving user'} autoSize={{ minRows: 4, maxRows: 20 }} />)}
              </Form.Item>
            </div>
          </div>
        );

      case 8:
        return (
          <div>
            <SubTitle containerClassName={'mb-small'}>User</SubTitle>
            <div className="flex-row align-center">
              <Avatar icon="user" shape={'square'} size={'default'} src={customerDetails.attachmentUrl} />
              <Text className="ml-small mr-large" size={'x2-large'}>
                {customerDetails.firstName} {customerDetails.lastName}
              </Text>
              <Text color={'red-dark'}>ARCHIVED</Text>
            </div>
            <div className={'mt-large'}>
              <SubTitle containerClassName={'mb-small'}>Reason</SubTitle>
              {archiveReason ? (
                <Text className={'whitespace-pre-wrap'}>{archiveReason}</Text>
              ) : (
                <Text color={'secondary'}>Not set</Text>
              )}
            </div>
          </div>
        );

      case 9:
        return (
          <Card>
            <div>
              <SubTitle>Current status</SubTitle>

              <Title level={4} weight={'regular'} className={'mt-x2-small mb-none'}>
                <Icon type={'stop'} className={'mr-small'} />
                Archived
              </Title>
            </div>
            <div className={'mt-large'}>
              <SubTitle>Archived on</SubTitle>
              <Title level={4} weight={'regular'} className={'mt-x2-small mb-none'}>
                {moment.tz(customerDetails.archivedOn, this.props.portalUser.timezone).format('DD/MM/YYYY')}
              </Title>
            </div>
            <div className={'mt-large'}>
              <SubTitle>Reason for archiving</SubTitle>
              {customerDetails.archiveReason ? (
                <Text className={'whitespace-pre-wrap'}>{customerDetails.archiveReason}</Text>
              ) : (
                <Text color={'secondary'}>Not set</Text>
              )}
            </div>
            <SecondaryButton className={'mt-large'} onClick={() => this.setState({ step: 10 })}>
              Re-activate customer
            </SecondaryButton>
            <div className="mt-large">
              <FieldLabel text={'CUSTOMER SOURCE'} />
              {this._renderCustomerSource()}
            </div>
          </Card>
        );

      case 10:
        return (
          <div>
            <Paragraph>Are you sure you want to reactivate this customer?</Paragraph>
          </div>
        );

      case 11:
        return (
          <Card>
            <div>
              <SubTitle>Current status</SubTitle>

              <Title level={4} weight={'regular'} className={'mt-x2-small mb-none'}>
                <Icon type={'stop'} className={'mr-small'} />
                Scheduled to be archived
              </Title>
            </div>
            <div className={'mt-large'}>
              <SubTitle>To be archived on</SubTitle>
              <Title level={4} weight={'regular'} className={'mt-x2-small mb-none'}>
                {moment.tz(customerDetails.archivedOn, this.props.portalUser.timezone).format('DD/MM/YYYY')}
              </Title>
            </div>
            <div className={'mt-large'}>
              <SubTitle>Reason for archiving</SubTitle>
              {customerDetails.archiveReason ? (
                <Text className={'whitespace-pre-wrap'}>{customerDetails.archiveReason}</Text>
              ) : (
                <Text color={'secondary'}>Not set</Text>
              )}
            </div>
            <PrimaryButton
              className={'mt-large mr-medium'}
              onClick={() => this.setState({ step: 12, isEditArchiveDate: true })}
            >
              Edit scheduled archive date
            </PrimaryButton>
            <SecondaryButton className={'mt-large'} onClick={() => this.setState({ step: 13 })}>
              Cancel scheduled archiving
            </SecondaryButton>
            <div className="mt-large">
              <FieldLabel text={'CUSTOMER SOURCE'} />
              {this._renderCustomerSource()}
            </div>
          </Card>
        );
      case 12:
        return (
          <div>
            <Paragraph>
              All upcoming bookings for the customer after the new scheduled archive date will be cancelled and they
              will be removed from any group sessions they have been booked into.
            </Paragraph>
            <Paragraph>
              The customer is currently scheduled to be archived on{' '}
              <b>{moment.tz(customerDetails.archivedOn, this.props.portalUser.timezone).format('DD/MM/YYYY')}</b>
            </Paragraph>

            <Form.Item>
              <SubTitle>New scheduled archive date</SubTitle>
              <DatePicker
                onChange={this._udpateArchiveDate}
                selected={moment.tz(this.state.archiveDate, this.props.portalUser.timezone).toDate()}
                className="gh-datepicker gh-datepicker-flat rounded"
                calendarClassName="gh-datepicker-calendar"
                dateFormat="dd/MM/yyyy"
                minDate={addDays(new Date(), 1)}
                placeholderText={'Select a date'}
              />
            </Form.Item>

            <div className={'mt-large'}>
              <SubTitle>Reason</SubTitle>
              <Form.Item className="m-none">
                {getFieldDecorator('archiveReason', {
                  rules: [{ validator: this._validateArchiveReason }],
                  initialValue: customerDetails.archiveReason ? customerDetails.archiveReason : undefined
                })(<TextArea placeholder={'Enter reason for archiving user'} autoSize={{ minRows: 4, maxRows: 20 }} />)}
              </Form.Item>
            </div>
          </div>
        );
      case 13:
        return (
          <div>
            <Paragraph>
              Are you sure you want to cancel the scheduled archiving of this customer? The customers status will be
              reverted back to {customerDetails.isEnquiry ? 'Enquiry' : 'Active'}.
            </Paragraph>
            <Paragraph>
              The customer is scheduled to be archived on{' '}
              <b>{moment.tz(customerDetails.archivedOn, this.props.portalUser.timezone).format('DD/MM/YYYY')}</b>
            </Paragraph>
          </div>
        );
      case 14:
        return (
          <div>
            {this.state.isEditArchiveDate ? (
              <Paragraph>
                The date the team customer is scheduled to be archived has been updated to{' '}
                <b>{moment.tz(this.state.archiveDate, this.props.portalUser.timezone).format('DD/MM/YYYY')}</b>
              </Paragraph>
            ) : (
              <Paragraph>
                The customer is scheduled to be archived on{' '}
                <b>{moment.tz(this.state.archiveDate, this.props.portalUser.timezone).format('DD/MM/YYYY')}</b>
              </Paragraph>
            )}
          </div>
        );
      case 15:
        return (
          <div>
            <Paragraph>
              All bookings for the customer (Incuding group bookings) that occur on or after{' '}
              <b>{moment.tz(this.state.archiveDate, this.props.portalUser.timezone).format('DD/MM/YYYY')}</b>. will be
              archived. This action is permanent and these bookings will have to be re-created if you change your mind
              in the future. Are you sure you wish to continue?
            </Paragraph>
            <Paragraph>Are you sure you wish to continue?</Paragraph>
          </div>
        );

      case 16:
        return <Paragraph>The scheduled archiving of the customer has been cancelled.</Paragraph>;

      default:
        return <></>;
    }
  };

  private _renderModalFooter = () => {
    const { step } = this.state;
    switch (step) {
      case 1:
        return (
          <PrimaryButton size={'large'} onClick={this._onCloseModel}>
            Close
          </PrimaryButton>
        );
      case 2:
        return (
          <>
            <SecondaryButton
              size={'large'}
              onClick={() => this.setState({ step: 1 })}
              disabled={this.state.isAcceptingEnquiry}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              size={'large'}
              className="ml-medium"
              onClick={this._acceptEnquiry}
              loading={this.state.isAcceptingEnquiry}
            >
              Mark as Active
            </PrimaryButton>
          </>
        );
      case 3:
        return (
          <>
            <SecondaryButton
              size={'large'}
              onClick={() => this.setState({ step: 1 })}
              disabled={this.state.isRevertingEnquiry}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              size={'large'}
              className="ml-medium"
              onClick={this._revertEnquiry}
              loading={this.state.isRevertingEnquiry}
            >
              Mark as Enquiry
            </PrimaryButton>
          </>
        );
      case 4:
        return (
          <>
            <SecondaryButton
              size={'large'}
              onClick={() => this.setState({ step: 1 })}
              disabled={this.state.isSavingCustomerSource}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              size={'large'}
              className="ml-medium"
              onClick={this._onSaveCustomerSource}
              loading={this.state.isSavingCustomerSource}
            >
              Save
            </PrimaryButton>
          </>
        );
      case 5:
      case 6:
      case 8:
      case 9:
        return (
          <PrimaryButton size={'large'} onClick={this._onCloseModel}>
            Close
          </PrimaryButton>
        );

      case 7:
        return (
          <>
            <SecondaryButton
              size={'large'}
              onClick={() => this.setState({ step: 1 })}
              disabled={this.state.isSavingCustomerSource}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              size={'large'}
              className="ml-medium"
              onClick={this._onArchiveCustomer}
              loading={this.state.isArchivingCustomer}
            >
              Archive customer
            </PrimaryButton>
          </>
        );

      case 10:
        return (
          <>
            <SecondaryButton
              size={'large'}
              onClick={() => this.setState({ step: 1 })}
              disabled={this.state.isSavingCustomerSource}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              size={'large'}
              className="ml-medium"
              onClick={this._onReactivateCustomer}
              loading={this.state.isReactivateCustomer}
            >
              Reactivate
            </PrimaryButton>
          </>
        );
      case 11:
        return (
          <PrimaryButton size={'large'} onClick={this._onCloseModel}>
            Close
          </PrimaryButton>
        );
      case 12:
        return (
          <>
            <SecondaryButton
              size={'large'}
              onClick={() => this.setState({ step: 11 })}
              disabled={this.state.isSavingCustomerSource}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton size={'large'} className="ml-medium" onClick={this._onArchiveCustomer}>
              Save
            </PrimaryButton>
          </>
        );
      case 13:
        return (
          <>
            {' '}
            <SecondaryButton
              size={'large'}
              onClick={() => this.setState({ step: 11 })}
              disabled={this.state.isSavingCustomerSource}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              size={'large'}
              className="ml-medium"
              onClick={this._onCancelScheduledArchive}
              loading={this.state.isCancellingScheduleArchive}
            >
              Confirm
            </PrimaryButton>
          </>
        );
      case 14:
        return;
      case 15:
        return (
          <>
            <SecondaryButton
              size={'large'}
              onClick={() =>
                this.setState({
                  step:
                    this.props.customerDetails.customerStatus === CustomerStatusType.SCHEDULED_TO_BE_ARCHIVED ? 11 : 1
                })
              }
              disabled={this.state.isSavingCustomerSource}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton
              size={'large'}
              className="ml-medium"
              onClick={this._onArchiveCustomer}
              loading={this.state.isArchivingCustomer}
            >
              Confirm
            </PrimaryButton>
          </>
        );

      default:
        return <></>;
    }
  };

  private _onCheckBoxChange = () => {
    // const { form } = this.props;
    this.setState({ isScheduleArchive: !this.state.isScheduleArchive });
  };

  private _udpateArchiveDate = (event) => {
    console.log('event', event);
    this.setState({
      archiveDate: event
    });
  };

  componentDidUpdate(prevProps: Readonly<IManageCustomerActionModelProps>, prevState: Readonly<any>, snapshot?: any) {
    if (this.props.customerDetails && !prevProps.isOpen && this.props.isOpen) {
      if (this.props.customerDetails && this.props.customerDetails.customerStatus === CustomerStatusType.ARCHIVED) {
        this.setState({ step: 9 });
      } else if (
        this.props.customerDetails &&
        this.props.customerDetails.customerStatus === CustomerStatusType.SCHEDULED_TO_BE_ARCHIVED
      ) {
        this.setState({ step: 11 });
      }
    }
  }

  render() {
    const { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title={this._renderModalTitle()}
        verticalAlignment={'high'}
        onClose={this._onCloseModel}
      >
        {this._renderModalContent()}
        <ActionModalFooter className="mt-large">{this._renderModalFooter()}</ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  customerDetails: state.customersStore.selectedCustomer,
  portalUser: state.authStore.portalUser,
  companyDataLite: state.companyStore.companyDataLite
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAcceptEnquiry: dispatch.customersStore.doAcceptEnquiry,
  doRevertEnquiry: dispatch.customersStore.doRevertEnquiry,
  doSaveCustomerSource: dispatch.customersStore.doSaveCustomerSource,
  doArchiveCustomer: dispatch.customersStore.doArchiveCustomer,
  doReactivateCustomer: dispatch.customersStore.doReactivateCustomer,
  doCancelScheduledArchive: dispatch.customersStore.doCancelScheduledArchive
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IManageCustomerActionModelProps>()(ManageCustomerActionModel));
