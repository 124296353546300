import React, { Component } from 'react';
import { Text, Title } from 'common-components/typography';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';
// Panels
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import _ from 'lodash';
import SessionTypeStepPanel from 'views/group-services/service-details/Create/wizard-steps/SessionTypeStepPanel';
import SessionDetailsStepPanel from 'views/group-services/service-details/Create/wizard-steps/SessionDetailsStepPanel';
import SummaryStepPanel from 'views/group-services/service-details/Create/wizard-steps/SummaryStepPanel';
import { ScheduleType, ServiceLocationType } from 'utilities/enum-utils';
import CreationCompletedStep from 'views/group-services/service-details/Create/wizard-steps/CreationCompletedStepPanel';
import ScheduleDetails from 'views/group-services/service-details/Create/wizard-steps/ScheduleDetailsStepPanel';
import ScheduleLocationStep from 'views/group-services/service-details/Create/wizard-steps/ScheduleLocationStepPanel';
import ScheduleTimeSlotsStep from 'views/group-services/service-details/Create/wizard-steps/ScheduleTimeSlotsStepPanel';
import SessionReviewStepPanel from 'views/group-services/service-details/Create/wizard-steps/SessionsReviewStepPanel';

interface IAddNewSessionModalProps {
  isOpen: boolean;
  history: any;
  closeAddSessionModal: (sessionData?: any) => void;
  doCreateNewSession: typeof dispatch.servicesStore.doCreateNewSession;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  setNewGroupServiceSchedule: typeof dispatch.servicesStore.setNewGroupServiceSchedule;
  newGroupServiceSchedule: typeof state.servicesStore.newGroupServiceSchedule;
}

interface IAddNewSessionModalState {
  step: number;
  totalStep: number;
  isProceedOpen: boolean;
  scheduleType: ScheduleType | null;
}

class AddNewSessionModal extends Component<IAddNewSessionModalProps, IAddNewSessionModalState> {
  state = {
    step: 1,
    totalStep: 4,
    isProceedOpen: false,
    scheduleType: null
  };
  private _onNextStep = () => {
    this.setState({ step: this.state.step + 1 });
  };

  private _onPreviousStep = () => {
    this.setState({ step: this.state.step - 1 });
  };

  private _updateScheduleType = (scheduleType) => {
    this.setState({ scheduleType, totalStep: scheduleType === ScheduleType.ONE_OFF ? 4 : 7 });
  };

  private _goToSession = async (serviceDateTimeId) => {
    const { history, selectedGroupService } = this.props;
    history.push(`/group-service/${selectedGroupService.serviceId}/session/details/${serviceDateTimeId}`);
    this._resetAndCloseModal();
  };

  private _closeCreateSessionModal = async () => {
    const { newGroupServiceSchedule } = this.props;
    const { step } = this.state;

    let hasUnsavedData = false;

    const isFinalStep =
      newGroupServiceSchedule &&
      ((newGroupServiceSchedule.scheduleType === ScheduleType.ONE_OFF && step === 4) ||
        (newGroupServiceSchedule.scheduleType === ScheduleType.SCHEDULE && step === 7));

    if (newGroupServiceSchedule && !isFinalStep) {
      hasUnsavedData =
        !_.isEmpty(newGroupServiceSchedule.scheduleStartDate) ||
        !_.isEmpty(newGroupServiceSchedule.scheduleEndDate) ||
        !_.isEmpty(
          newGroupServiceSchedule.scheduleDirection === ServiceLocationType.FIXEDVENUE &&
            newGroupServiceSchedule.address
        ) ||
        !_.isEmpty(newGroupServiceSchedule.scheduleName);
    }

    if (hasUnsavedData) {
      this.setState({ isProceedOpen: true });
    } else {
      this._resetAndCloseModal();
    }
  };

  private _resetAndCloseModal = () => {
    this.setState({ isProceedOpen: false, step: 1 });
    this.props.closeAddSessionModal();
    this.props.setNewGroupServiceSchedule([]);
  };

  private _renderStepContent = () => {
    const { history } = this.props;
    const { step, scheduleType } = this.state;

    const isScheduleType = scheduleType === ScheduleType.SCHEDULE;

    if (step === 1) {
      return <SessionTypeStepPanel onNextStep={this._onNextStep} updateScheduleType={this._updateScheduleType} />;
    } else if (step === 2) {
      return isScheduleType ? (
        <ScheduleDetails onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} history={history} />
      ) : (
        <SessionDetailsStepPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          history={history}
        />
      );
    } else if (step === 3) {
      return isScheduleType ? (
        <ScheduleLocationStep onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />
      ) : (
        <SummaryStepPanel onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />
      );
    } else if (step === 4) {
      return isScheduleType ? (
        <ScheduleTimeSlotsStep onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />
      ) : (
        <CreationCompletedStep closeWizard={this._closeCreateSessionModal} goToSession={this._goToSession} />
      );
    } else if (step === 5) {
      return <SessionReviewStepPanel onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />;
    } else if (step === 6) {
      return <SummaryStepPanel onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />;
    } else if (step === 7) {
      return <CreationCompletedStep closeWizard={this._closeCreateSessionModal} goToSession={this._goToSession} />;
    }
  };

  private _closeProceedModal = () => {
    this.setState({ isProceedOpen: false });
  };

  render() {
    const { scheduleType } = this.state;

    let headerTitle = (
      <Title level={2} className="m-none">
        Create {scheduleType && scheduleType === ScheduleType.SCHEDULE ? 'Schedule' : 'Session'}
      </Title>
    );

    let steps = [];
    for (let i = 1; i <= this.state.totalStep; i++) {
      let stepObj = {};
      let stepTitle = 'Select type';
      if (scheduleType && scheduleType === ScheduleType.SCHEDULE) {
        stepTitle =
          i === 1
            ? 'Select type'
            : i === 2
            ? 'Details'
            : i === 3
            ? 'Location'
            : i === 4
            ? 'Sessions'
            : i === 5
            ? 'Review'
            : i === 6
            ? 'Summary'
            : 'Done!';
      } else {
        stepTitle = i === 1 ? 'Select type' : i === 2 ? 'Details' : i === 3 ? 'Summary' : 'Done!';
      }

      stepObj['stepNumber'] = i;
      stepObj['stepLabel'] = i;
      stepObj['stepTitle'] = stepTitle;
      steps.push(stepObj);
    }

    return (
      <>
        <ActionModal
          isOpen={this.state.isProceedOpen}
          onClose={this._closeProceedModal}
          title={'Cancelling creating sessions'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, are you sure you want to <b>cancel</b> now ?
          </Text>
          <br />
          <Text className={'mb-medium'} weight={'bold'}>
            Your progress will not be saved.
          </Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeProceedModal}>
              Back
            </PrimaryButton>
            <GhostButton size="large" onClick={this._resetAndCloseModal}>
              Cancel
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <FullScreenScrollableModal
          isOpen={this.props.isOpen}
          onClose={this._closeCreateSessionModal}
          width={'full'}
          headerTitle={headerTitle}
          bgColor="tertiary"
          canCloseEsc={false}
          fixedHeight={false}
          noMarginBottom={true}
          contentPadding={false}
          hasFooter={false}
          hasSteps={true}
          currentStep={this.state.step}
          steps={steps}
        >
          {this._renderStepContent()}
        </FullScreenScrollableModal>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  newGroupServiceSchedule: state.servicesStore.newGroupServiceSchedule,
  selectedGroupService: state.groupServiceStore.selectedGroupService
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewGroupServiceSchedule: dispatch.servicesStore.setNewGroupServiceSchedule,
  doCreateNewSession: dispatch.servicesStore.doCreateNewSession
});

export default connect(
  mapState,
  mapDispatch
)(AddNewSessionModal);
