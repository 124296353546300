/* eslint-disable */
import React, { PureComponent, ReactNode } from 'react';
import { Icon } from 'antd';
import { PORTAL_COLORS } from 'src/interfaces/common-ui-types';

interface IFilterWarningProps {
  className?: string;
  icon?: string;
  color?: PORTAL_COLORS;
  content: ReactNode;
  displayType?: 'inline-block' | 'inline' | 'block';
  borderNone?: boolean;
}

export default class Error extends PureComponent<IFilterWarningProps, any> {
  render() {
    const {
      className = '',
      borderNone = false,
      icon = 'close-circle',
      content,
      color = 'red',
      displayType = 'inline-block'
    } = this.props;

    let finalClassName = borderNone
      ? `${displayType} `
      : `bordered-left border border-${color} border-width-x-large ${displayType} `;

    finalClassName += className;

    return (
      <div className={finalClassName} style={{ backgroundColor: 'rgba(255,235,235,0.5)' }}>
        <div className="flex p-small">
          <div>
            <Icon type={icon} theme="filled" className={`text-size-x2-large text-color-${color}`} />
          </div>
          <div className="ml-small pr-x-large">{content}</div>
        </div>
      </div>
    );
  }
}
