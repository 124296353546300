import { Form, Input, message, Upload } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import _ from 'lodash';
import React, { Component } from 'react';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from '../../../../../common-components/buttons/index';
import ActionModal, { ActionModalFooter } from '../../../../../common-components/modal/ActionModal';
import { FieldLabel, Text } from '../../../../../common-components/typography/index';
import { CustomerNoteVisibleType } from '../../../../../utilities/enum-utils';

interface IActivityRecordAddEditNoteWhenCreateModalProps extends FormComponentProps {
  isOpen: boolean;
  editType: boolean;
  onClose: () => void;
  onApplyNote: (note: any) => void;
  note: any;
}

interface IActivityRecordAddEditNoteWhenCreateModalState {
  isIncident: boolean;
  noteContent: string;
  visibleType: CustomerNoteVisibleType;
  attachment: any;
}

class ActivityRecordAddEditNoteWhenCreateModal extends Component<
  IActivityRecordAddEditNoteWhenCreateModalProps,
  IActivityRecordAddEditNoteWhenCreateModalState
> {
  state = {
    isIncident: false,
    noteContent: '',
    visibleType: CustomerNoteVisibleType.PORTAL,
    attachment: null
  };

  private _onCloseModel = () => {
    const { onClose } = this.props;
    this.setState({
      isIncident: false,
      noteContent: '',
      visibleType: CustomerNoteVisibleType.PORTAL,
      attachment: null
    });
    onClose();
  };

  private _noteDetailsValidator = (rule, value, callback) => {
    try {
      if (!value || value === '') {
        throw Error('Please fill in the note details.');
      }
      if (_.trim(value).length < 1) {
        throw Error('Please enter at least 1 characters');
      }
      if (_.trim(value).length > 50001) {
        throw Error('Please enter at most 50000 characters');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _hasExtension = (file) => {
    return new RegExp(
      '(' +
        [
          '.jpeg',
          '.jpg',
          '.png',
          '.gif',
          '.pdf',
          '.txt',
          '.ppt',
          '.pptx',
          '.doc',
          '.docx',
          '.csv',
          '.xls',
          '.xlsx',
          '.xlsm'
        ]
          .join('|')
          .replace(/\./g, '\\.') +
        ')$'
    ).test(file.name.toLowerCase());
  };

  private _validateFile = (file) => {
    const isValidType = this._hasExtension(file);
    if (!isValidType) {
      message.error('This file extension is not supported, please choose another format.');
    }
    const isLt2M = file.size / 1024 / 1024 < 25;
    if (!isLt2M) {
      message.error('File must be smaller than 25MB!');
    }

    if (isLt2M && isValidType) {
      this.setState({ attachment: file });
    }

    return false;
  };

  private _removeAttachment = () => {
    this.setState({ attachment: null });
  };

  private _onAddNote = () => {
    this.props.form.validateFieldsAndScroll((err, value) => {
      if (!err) {
        this.props.onApplyNote({
          isIncident: this.state.isIncident,
          noteContent: this.state.noteContent,
          visibleType: CustomerNoteVisibleType.PORTAL,
          attachment: this.state.attachment
        });
        this._onCloseModel();
      }
    });
  };

  componentDidMount() {
    const { note } = this.props;
    if (note) {
      this.setState({
        isIncident: note.isIncident,
        noteContent: note.noteContent,
        attachment: note.attachment,
        visibleType: note.visibleType
      });
    }
  }

  render() {
    const { isOpen, editType, form } = this.props;
    const { isIncident } = this.state;
    const { getFieldDecorator } = form;
    return (
      <ActionModal
        isOpen={isOpen}
        title={editType ? 'Edit note' : 'Add note'}
        width="medium"
        onClose={this._onCloseModel}
        verticalAlignment="highest"
        canCloseOutside={false}
      >
        <div>
          <Text>Does this note relate to an incident?</Text>
        </div>
        <div className={'mt-medium'}>
          {!isIncident && (
            <>
              <PrimaryButton className="rounded mr-medium" onClick={() => this.setState({ isIncident: false })}>
                No
              </PrimaryButton>

              <SecondaryButton
                className="rounded"
                color="secondary"
                onClick={() => this.setState({ isIncident: true })}
              >
                Yes - This note relate to an incident
              </SecondaryButton>
            </>
          )}

          {isIncident && (
            <>
              <SecondaryButton
                className="rounded mr-medium"
                color="secondary"
                onClick={() => this.setState({ isIncident: false })}
              >
                No
              </SecondaryButton>
              <PrimaryButton className="rounded" color="red-dark" onClick={() => this.setState({ isIncident: true })}>
                Yes - This note relate to an incident
              </PrimaryButton>
            </>
          )}
        </div>
        <div className={'mt-large'}>
          <FieldLabel text={'NOTE'} />
          <Form.Item className={'m-none'}>
            {getFieldDecorator('noteContent', {
              initialValue: this.state.noteContent,
              rules: [{ validator: this._noteDetailsValidator }]
            })(
              <Input.TextArea
                placeholder={'Enter note details...'}
                autoSize={{ minRows: 4, maxRows: 4 }}
                onChange={(e) => this.setState({ noteContent: e.target.value })}
              />
            )}
          </Form.Item>
        </div>
        <div className={'mt-large'}>
          <FieldLabel text={'ATTACHMENT (OPTIONAL)'} />
          <div
            className={'p-medium'}
            style={{
              backgroundColor: ' #FAFAFA',
              border: '1px solid #EEEEEE',
              boxSizing: 'border-box',
              borderRadius: '8px'
            }}
          >
            {!this.state.attachment && (
              <>
                <Text>No Attachments</Text>
                <Upload
                  multiple={false}
                  beforeUpload={this._validateFile}
                  showUploadList={false}
                  className={'ml-small'}
                >
                  <HyperlinkButton>Select attachment...</HyperlinkButton>
                </Upload>
              </>
            )}
            {this.state.attachment && (
              <>
                <Text>{this.state.attachment.name}</Text>
                <HyperlinkButton onClick={this._removeAttachment} className={'ml-small'}>
                  Remove attachment...
                </HyperlinkButton>
              </>
            )}
          </div>
        </div>

        <ActionModalFooter className={'mt-x2-large'}>
          <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModel}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={this._onAddNote}>
            Add note
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

export default Form.create<IActivityRecordAddEditNoteWhenCreateModalProps>()(ActivityRecordAddEditNoteWhenCreateModal);
