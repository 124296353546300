import React, { Component } from 'react';
import _ from 'lodash';
import { Text } from 'common-components/typography';
import { GhostButton } from 'common-components/buttons';
import { Menu, Popover } from '@blueprintjs/core';
import moment from 'moment-timezone';
import { IGroupServiceSchedule } from 'interfaces/service-interfaces';
import { RecurringBookingPattern } from 'utilities/enum-utils';

interface IScheduleListItemProps {
  scheduleItem: IGroupServiceSchedule;
  timezone: string;
  onDeleteSchedule: () => void;
}

interface IScheduleListItemState {
  schedulePeriodText: string;
  timeSlotsTexts: string[];
}

export default class ScheduleListItem extends Component<IScheduleListItemProps, IScheduleListItemState> {
  state = {
    schedulePeriodText: null,
    timeSlotsTexts: []
  };

  private _getPopoverContent = () => {
    return (
      <div>
        <Menu className="pv-small ph-none shadow-container" style={{ width: '200px' }}>
          <Menu.Item
            label={''}
            className="hover-bg-secondary"
            text={'Delete schedule'}
            onClick={this.props.onDeleteSchedule}
          />
        </Menu>
      </div>
    );
  };

  private _parseSchedulePeriod = () => {
    const { timezone } = this.props;
    const scheduleConfig = this.props.scheduleItem.scheduleConfig;
    const startDate = moment.tz(scheduleConfig.scheduleStartDate, timezone);
    const endDate = moment.tz(scheduleConfig.scheduleEndDate, timezone);

    const startDateString =
      startDate.year() === endDate.year() ? startDate.format('DD MMMM') : startDate.format('DD MMMM YYYY');
    const endDateString = endDate.format('DD MMMM YYYY');

    const newSchedulePeriodText = `${startDateString} - ${endDateString}`;

    this.setState({ schedulePeriodText: newSchedulePeriodText });
  };

  private _parseTimeSlot = () => {
    const timeSlot = this.props.scheduleItem.scheduleConfig.timeSlots;
    const newSlotTexts = [];

    _.map(timeSlot, (ts) => {
      const recurringPattern = this._parseRecurringPatter(ts.recurringPattern);
      const weekDay = moment.tz(ts.startDateTime, ts.timezone).format('dddd');
      const startTime = moment.tz(ts.startDateTime, ts.timezone).format('hh:mm A');
      const endTime = moment.tz(ts.endDateTime, ts.timezone).format('hh:mm A');
      const newText = `${recurringPattern} ${weekDay} - ${startTime} to ${endTime}`;
      newSlotTexts.push(newText);
    });

    this.setState({ timeSlotsTexts: newSlotTexts });
  };

  private _parseRecurringPatter = (recurringPattern: number) => {
    switch (recurringPattern) {
      case RecurringBookingPattern.EveryWeek:
        return '';
      case RecurringBookingPattern.EveryFortnight:
        return '2nd';
      case RecurringBookingPattern.EveryFourWeeks:
        return '4th';
      case RecurringBookingPattern.EveryThreeWeeks:
        return '3rd';
      default:
        return 'N/A';
    }
  };

  componentDidMount = () => {
    this._parseSchedulePeriod();
    this._parseTimeSlot();
  };

  render() {
    const { scheduleItem } = this.props;

    return (
      <div className="bg-white p-medium rounded-big mb-medium relative bordered border-standard-gray">
        <Text weight="bold" className="line-height-120">
          {scheduleItem.scheduleName}
        </Text>
        <div className="mb-medium ">
          <Text className="line-height-120">{this.state.schedulePeriodText}</Text>
        </div>

        {_.map(this.state.timeSlotsTexts, (timeSlotText) => (
          <div
            className="bg-quaternary mb-small p-x-small rounded"
            style={{ width: 'min-content', whiteSpace: 'nowrap' }}
          >
            <Text size="regular">
              <span className="text-color-secondary">Every</span> {timeSlotText}
            </Text>
          </div>
        ))}

        <div className="absolute" style={{ top: '16px', right: '16px' }}>
          <Popover
            content={this._getPopoverContent()}
            popoverClassName={'mb-medium'}
            position={'bottom-right'}
            interactionKind="click"
          >
            <div
              className="bordered border-standard-gray rounded text-align-center"
              style={{ width: '28px', height: '28px' }}
            >
              <GhostButton icon={'ellipsis'} size={'small'} paddingSize={'x-small'} />
            </div>
          </Popover>
        </div>
      </div>
    );
  }
}
