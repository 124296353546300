import React, { PureComponent } from 'react';
import { Text } from 'common-components/typography';
import { PlanManagementClaimStatus, PlanManagementClaimStatusDisplay } from 'utilities/enum-utils';

interface IClaimStatusTagProps {
  status: PlanManagementClaimStatus;
}

export default class ClaimStatusTag extends PureComponent<IClaimStatusTagProps, any> {
  TextPaymentMethodColors = {
    UNCLAIMED: 'secondary',
    CANCELLED: 'orange',
    WAIVED: 'indigo',
    REJECTED: 'red-dark',
    CLAIMED: 'green',
    SENT_TO_PAYMENTS: 'black'
  };

  render() {
    const { status } = this.props;

    const textColor = this.TextPaymentMethodColors[status];
    const textLabel = PlanManagementClaimStatusDisplay[status];

    return <Text className={`text-color-${textColor}`}>{textLabel}</Text>;
  }
}
