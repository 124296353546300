import moment from 'moment';
import { FilterType, PaymentStatus, ServiceType } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';

// all possibilities of filters:

// status: [x,y,z]
// paymentStatus: [x,y,z]
// workerStatus: [x,y,z]
// startDate: [startDate, endDate]
// serviceName: ''
// sort: []
// sort filter format is [col,asc] eg ['desc','asc']

// this serves as the filter configurations. Should expand this later to a proper interface
// to add a filter item, add here as well as BookingListingNavigation.
const BOOKINGLIST_FILTERCONFIGS = {
  NEW_REQUESTS: {
    key: 'NEW_REQUESTS',
    title: 'New requests',
    description: 'Booking requests from customer.',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      },
      {
        filter: FilterType.WORKER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.WORKER).fullSelectionName
      },
      {
        filter: FilterType.BOOKING_STATUS,
        values: ['PENDING'],
        selectionLabel: 'Pending'
      },
      {
        filter: FilterType.SERVICE_TYPE,
        values: [ServiceType.INDIVIDUAL],
        selectionLabel: 'Support services'
      },
      { filter: 'sort', values: [['startDateTime', 'asc']] }
    ],
    customFields: [
      {
        header: 'Received Date',
        field: 'RECEIVED_DATE'
      },
      {
        header: 'Booking Date',
        field: 'BOOKING_DATE'
      },
      {
        header: 'Client Name',
        field: 'CUSTOMER'
      },
      {
        header: 'Service',
        field: 'SERVICE'
      },
      {
        header: 'Team member',
        field: 'WORKER'
      },
      {
        header: 'Forecast',
        field: 'TOTAL'
      },
      {
        header: '',
        field: 'ALERTS'
      }
    ]
  },

  // URGENT_ACTIONS: {
  //   key: 'URGENT_ACTIONS',
  //   title: 'Urgent actions required.',
  //   description:
  //     'Bookings scheduled to start in less than 1 week that require acceptance, confirmation or worker confirmation.',
  //   filters: {
  //     status: ['PENDING', 'ACCEPTED'],
  //     workerStatus: ['NO_WORKER'],
  //     startDate: [
  //       moment()
  //         .startOf('day')
  //         .toDate(),
  //       moment()
  //         .add(7, 'days')
  //         .endOf('day')
  //         .toDate()
  //     ],
  //     sort: [['startDateTime', 'asc']]
  //   }
  // },

  NO_WORKER_CONFIRMED: {
    key: 'NO_WORKER_CONFIRMED',
    title: 'No worker confirmed',
    description: "Bookings that don't have a confirmed worker.",
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      },
      {
        filter: FilterType.WORKER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.WORKER).fullSelectionName
      },
      {
        filter: FilterType.SHIFT_SLOT_STATUS,
        values: ['UNASSIGNED', 'PENDING'],
        selectionLabel: CommonUtils.getFilterText(FilterType.SHIFT_SLOT_STATUS, ['UNASSIGNED', 'PENDING'])
      },
      {
        filter: FilterType.BOOKING_STATUS,
        values: ['ACCEPTED', 'CONFIRMED', 'PENDING'],
        selectionLabel: CommonUtils.getFilterText(FilterType.BOOKING_STATUS, ['ACCEPTED', 'CONFIRMED', 'PENDING'])
      },
      {
        filter: FilterType.SERVICE_TYPE,
        values: [ServiceType.INDIVIDUAL],
        selectionLabel: 'Support services'
      },
      { filter: 'sort', values: [['startDateTime', 'asc']] }
    ]
  },

  OVERDUE: {
    key: 'OVERDUE',
    title: 'Overdue',
    description: 'Booking scheduled to start before today but no action yet.',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [moment().add(-1, 'year'), moment().startOf('day')],
        selectionLabel: CommonUtils.getFilterText(FilterType.DATE_RANGE, [
          moment().add(-1, 'year'),
          moment().startOf('day')
        ])
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      },
      {
        filter: FilterType.WORKER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.WORKER).fullSelectionName
      },
      {
        filter: FilterType.BOOKING_STATUS,
        values: ['PENDING', 'ACCEPTED', 'CONFIRMED'],
        selectionLabel: CommonUtils.getFilterText(FilterType.BOOKING_STATUS, ['PENDING', 'ACCEPTED', 'CONFIRMED'])
      },
      {
        filter: FilterType.SERVICE_TYPE,
        values: [ServiceType.INDIVIDUAL],
        selectionLabel: 'Support services'
      },
      { filter: 'sort', values: [['startDateTime', 'asc']] }
    ]
  },

  // NOT IMPLEMENTED
  // LATE_START_OR_FINISH: {
  //   key: 'LATE_START_OR_FINISH',
  //   title: 'Bookings with a late start or finish.',
  //   description: "Bookings that should have started or finished but haven't yet.",
  //   filters: {} // cannot do I think?
  // },
  //
  // TODAY: {
  //   key: 'TODAY',
  //   title: 'Today',
  //   description: 'Bookings that are scheduled to happen today.',
  //   filters: {
  //     startDate: [
  //       moment()
  //         .startOf('day')
  //         .toDate(),
  //       moment()
  //         .endOf('day')
  //         .toDate()
  //     ],
  //     sort: [['startDateTime', 'asc']]
  //   }
  // },
  //
  // UPCOMING: {
  //   key: 'UPCOMING',
  //   title: 'Upcoming',
  //   description: 'Bookings that are upcoming in the future.',
  //   filters: {
  //     status: ['PENDING', 'ACCEPTED', 'CONFIRMED'],
  //     sort: [['startDateTime', 'asc']],
  //     startDate: [
  //       moment()
  //         .startOf('day')
  //         .toDate(),
  //       moment()
  //         .add(1, 'year')
  //         .toDate()
  //     ]
  //   }
  // },
  //
  // IN_PROGRESS: {
  //   key: 'IN_PROGRESS',
  //   title: 'In Progress',
  //   description: 'Bookings that are in progress.',
  //   filters: {
  //     status: ['INPROGRESS'],
  //     sort: [['startDateTime', 'asc']]
  //   }
  // },
  //
  // ALL_ENDED: {
  //   key: 'ALL_ENDED',
  //   title: 'Completed',
  //   description: 'Bookings that have finished or ended.',
  //   filters: { status: ['COMPLETED'], sort: [['startDateTime', 'asc']] }
  // },
  //
  // CANCELLED: {
  //   key: 'CANCELLED',
  //   title: 'Cancelled',
  //   description: 'Bookings that have finished or ended.',
  //   filters: {
  //     status: [
  //       'CUSTOMER_CANCELLED',
  //       'CUSTOMER_CANCELLED_WITH_FEE',
  //       'CUSTOMER_CANCELLED_WITHOUT_FEE',
  //       'BUSINESS_CANCELLED'
  //     ],
  //     sort: [['startDateTime', 'asc']]
  //   }
  // },

  REQUIRED_APPROVAL: {
    key: 'REQUIRED_APPROVAL',
    title: 'Requires approval',
    description: 'Bookings that are complete in some way and require you to make a payment decision.',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      },
      {
        filter: FilterType.WORKER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.WORKER).fullSelectionName
      },
      {
        filter: FilterType.PAYMENT_STATUS,
        values: ['REQUIRES_APPROVAL'],
        selectionLabel: 'Requires approval'
      },
      {
        filter: FilterType.SERVICE_TYPE,
        values: [ServiceType.INDIVIDUAL],
        selectionLabel: 'Support services'
      },
      { filter: 'sort', values: [['startDateTime', 'asc']] }
    ]
  },

  // SENT_TO_PAYMENTS: {
  //   key: 'SENT_TO_PAYMENTS',
  //   title: 'Sent to Payments',
  //   description: 'Bookings that are sent to finance.',
  //   filters: { paymentStatus: ['SEND_TO_FINANCE'], sort: [['startDateTime', 'asc']] }
  // },

  REMOVE_TEAM_MEMBER: {
    key: 'REMOVE_TEAM_MEMBER',
    title: 'Remove team members',
    description: 'Bookings that can remove assigned team members',
    customFields: [
      {
        header: 'Bookings',
        field: 'BOOKINGS'
      },
      {
        header: 'Customer',
        field: 'CUSTOMER'
      },
      {
        header: 'Team member',
        field: 'WORKER'
      }
    ]
  },

  ALL: {
    key: 'ALL',
    title: 'All bookings',
    description: 'Shows all bookings.',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
        selectionLabel: CommonUtils.getFilterText(FilterType.DATE_RANGE, [
          moment().startOf('isoWeek'),
          moment().endOf('isoWeek')
        ])
      },
      { filter: 'sort', values: [['startDateTime', 'asc']] },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      },
      {
        filter: FilterType.WORKER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.WORKER).fullSelectionName
      },
      {
        filter: FilterType.SHIFT_SLOT_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.SHIFT_SLOT_STATUS).fullSelectionName
      },
      {
        filter: FilterType.SERVICE_TYPE,
        values: [ServiceType.INDIVIDUAL],
        selectionLabel: 'Support services'
      }
    ]
  }

  // PROCESSED: {
  //   key: 'PROCESSED',
  //   title: 'Processed',
  //   description: 'Shows all processed bookings.',
  //   filters: {
  //     paymentStatus: ['PROCESSED'],
  //     sort: [['startDateTime', 'desc']]
  //   }
  // }
};
export { BOOKINGLIST_FILTERCONFIGS };

/*



  PAYMENT_STATUS: {
    INITIAL: STATUSSTRING.INITIAL,
    READY_FOR_BILLING: STATUSSTRING.READY_FOR_BILLING,
    SEND_TO_FINANCE: STATUSSTRING.SEND_TO_FINANCE,
    PROCESSED: STATUSSTRING.PROCESSED,
    BILLING_FAILED: STATUSSTRING.BILLING_FAILED,
    WAIVED: STATUSSTRING.WAIVED,
    NO_CHARGE: STATUSSTRING.NO_CHARGE,
    REQUIRES_APPROVAL: STATUSSTRING.REQUIRES_APPROVAL
  },
  BOOKING_STATUS: {
    PENDING: STATUSSTRING.PENDING, // Pending
    ACCEPTED: STATUSSTRING.ACCEPTED,
    CONFIRMED: STATUSSTRING.CONFIRMED,
    IN_PROGRESS: STATUSSTRING.IN_PROGRESS, // INPROGRESS
    COMPLETED: STATUSSTRING.COMPLETED,
    CUSTOMER_CANCELLED: STATUSSTRING.CUSTOMER_CANCELLED,
    BUSINESS_CANCELLED: STATUSSTRING.BUSINESS_CANCELLED,
    REJECTED: STATUSSTRING.REJECTED,
    CANCEL_SHORT_NOTICE: 'CANCEL_SHORT_NOTICE' // this status is for portal user decide charge customer or not
  },


	PUBLISHED: STATUSSTRING.PUBLISHED,
    CLAIMED: STATUSSTRING.CLAIMED,

  }

 */
