import React, { PureComponent } from 'react';
import { Text, Title, SubTitle } from 'common-components/typography';
import { Checkbox, Empty, Input, Skeleton, DatePicker, Row, Col } from 'antd';
import _ from 'lodash';
import { state, IRootState, IRootDispatch, dispatch } from 'stores/rematch/root-store';
import { Tooltip } from '@blueprintjs/core';
import { BottomActionSheet } from 'common-components/Sheets/BottomActionSheet';
import { ItemCountSheet } from 'common-components/Sheets/ItemCountSheet';
import { IconButton, PrimaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import PlanPaymentInvoiceRow from 'views/plan-management/payments/components/PlanPaymentInvoiceRow';
import { FilterSection } from 'common-components/filter';
import { FilterType } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';
import moment from 'moment';
import Search from 'antd/es/input/Search';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import PlanPaymentRejectInvoiceModal from '../components/PlanPaymentRejectInvoiceModal';
import PayClaimedInvoicesActionModal from '../components/PayClaimedInvoicesActionModal';
import PlanPaymentWaivedReasonModal from '../components/PlanPaymentWaivedReasonModal';
import EditInvoiceLineItemActionModal from '../components/EditInvoiceLineItemActionModal';
import ReSendToPaymentActionModal from '../../components/ReSendToPaymentActionModal';
import PlanPayListDeleteLineItemModal from '../components/PlanPayListDeleteLineItemModal';
interface ListPanelProps {
  setSelectedRejectPlanManagementLineItems: typeof dispatch.planManagementStore.setSelectedRejectPlanManagementLineItems;
  setPlanManagementInvoiceListFilter: typeof dispatch.planManagementStore.setPlanManagementInvoiceListFilter;
  setPlanManagementInvoiceList: typeof dispatch.planManagementStore.setPlanManagementInvoiceList;
  doFetchWaivedInvoiceList: typeof dispatch.planManagementStore.doFetchWaivedInvoiceList;
  planManagementInvoiceList: typeof state.planManagementStore.planManagementInvoiceList;
  planManagementInvoiceListFilter: typeof state.planManagementStore.planManagementInvoiceListFilter;
  selectedItem: typeof state.planManagementStore.selectedItem;
  portalUser: typeof state.authStore.portalUser;
  currentFilterConfig?: any;
  doFetchPlanManagementInvoiceDetails: typeof dispatch.planManagementStore.doFetchPlanManagementInvoiceDetails;
  planManagementLineItemList: typeof state.planManagementStore.planManagementLineItemList;
  history?: any;
  planManagementOverviewData: typeof state.planManagementStore.planManagementOverviewData;
  setSelectedItem: typeof dispatch.planManagementStore.setSelectedItem;
  selectedPlanManagementInvoiceDetails: typeof state.planManagementStore.selectedPlanManagementInvoiceDetails;
}

interface ListPanelState {
  topHeight: number;
  // showFilters: boolean;
  showActionSheet: boolean;
  checkAllIndicator: boolean;
  indeterminateCheck: boolean;
  isLoading: boolean;
  isSearching: boolean;
  searchString: string;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  action: string;
  openAction: boolean;
  openEditAction: boolean;
}

const PaymentEmptyState = () => (
  <div className="flex-1 bg-white align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No Invoices found.
    </Text>{' '}
    <br /> <br />
    <Text color="secondary">All invoices under this filter will appear here.</Text>
    <Text color="secondary">Try adjusting your filter, or clicking on another view.</Text>
  </div>
);

const availableFilters = [FilterType.DATE_RANGE, FilterType.PROVIDER, FilterType.CUSTOMER];

class PlanPayListWaivedSection extends PureComponent<ListPanelProps, ListPanelState> {
  // topHeight is used to control sticky
  state = {
    topHeight: 0,
    showFilters: false,
    showActionSheet: false,
    checkAllIndicator: false,
    indeterminateCheck: false,
    isLoading: false,
    searchString: '',
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    action: '',
    openAction: false,
    openEditAction: false,
    isSearching: false
  };

  // ref element for the header; used to determine the height of the header
  _headerElement = null;

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setPlanManagementInvoiceListFilter(filters);
  };

  private _openActionModal = async ({ action, additionalData }) => {
    const { setSelectedItem } = this.props;

    setSelectedItem(additionalData);

    this.setState({ action, openAction: true });
  };

  private getActionModal = () => {
    if (this.state.action === 'VIEW_WAIVE_REASON') {
      return PlanPaymentWaivedReasonModal;
    } else if (this.state.action === 'EDIT') {
      return EditInvoiceLineItemActionModal;
    } else if (this.state.action === 'RESEND_TO_PAYMENT') {
      return ReSendToPaymentActionModal;
    } else if (this.state.action === 'DELETE') {
      return PlanPayListDeleteLineItemModal;
    } else {
      return () => <></>;
    }
  };

  private _closeActionModal = () => this.setState({ openAction: false });

  private _handleHeaderHeight = () => {
    if (this._headerElement) {
      this.setState({ topHeight: this._headerElement.offsetHeight - 1 });
    }
  };

  private _onActionDeselect = () => {};

  private _refreshListings = async () => {
    const { doFetchWaivedInvoiceList } = this.props;
    this.setState({ isLoading: true, checkAllIndicator: false, indeterminateCheck: false, showActionSheet: false });

    const requestFilter = this._formatFilterQuery();
    await doFetchWaivedInvoiceList({
      page: 1,
      pageTimestamp: new Date(),
      pageSize: this.state.pageSize,
      ...requestFilter
    });
    this.setState({ isLoading: false, pageTimestamp: new Date(), page: 1 });
  };

  private _fetchMoreInvoice = async () => {
    const { doFetchWaivedInvoiceList } = this.props;
    this.setState({ isLoading: true });
    const requestFilter = this._formatFilterQuery();
    await doFetchWaivedInvoiceList({
      page: this.state.page + 1,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });
    this.setState({ isLoading: false, page: this.state.page + 1 });
  };

  private _searchText = async (txt) => {
    const { planManagementInvoiceListFilter, setPlanManagementInvoiceListFilter } = this.props;
    let newPlanPaymentsFilter = _.clone(planManagementInvoiceListFilter);
    const existingSearchIndex = _.findIndex(newPlanPaymentsFilter, (filter: any) => filter.filter === 'searchString');
    if (existingSearchIndex > -1) {
      if (txt === '') {
        newPlanPaymentsFilter.splice(existingSearchIndex, 1);
      } else {
        newPlanPaymentsFilter[existingSearchIndex].values = txt;
      }
    } else {
      newPlanPaymentsFilter.push({ filter: 'searchString', values: txt });
    }
    setPlanManagementInvoiceListFilter(newPlanPaymentsFilter);
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _formatFilterQuery = () => {
    const requestFilter: any = {};
    _.forEach(this.props.planManagementInvoiceListFilter, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'startDate':
            requestFilter.invoiceDate = [filter.values[0].toDate(), filter.values[1].toDate()];
            break;
          case 'planManagementSupplierIds':
            requestFilter.providerIds = filter.values;
            break;
          case 'customerUserIds':
            requestFilter.customerUserIds = _.map(filter.values, (customer) => {
              return customer.value;
            });
            break;
          case 'searchString':
            requestFilter.searchString = filter.values;
            break;
        }
      }
    });
    return requestFilter;
  };

  //region Component Lifecycle Methods
  componentDidMount = async () => {
    this.setState({ isLoading: true });
    this._handleHeaderHeight();
    const { doFetchWaivedInvoiceList, currentFilterConfig, setPlanManagementInvoiceListFilter } = this.props;
    await setPlanManagementInvoiceListFilter(currentFilterConfig.filters);
    const requestFilter = this._formatFilterQuery();
    await doFetchWaivedInvoiceList({
      page: this.state.page,
      pageTimestamp: this.state.pageTimestamp,
      pageSize: this.state.pageSize,
      ...requestFilter
    });
    this.setState({ isLoading: false });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { currentFilterConfig, setPlanManagementInvoiceListFilter, doFetchWaivedInvoiceList } = this.props;

    if (prevState.showFilters !== this.state.showFilters) {
      this._handleHeaderHeight();
    }

    if (prevProps.currentFilterConfig.key !== currentFilterConfig.key) {
      this.setState({
        isLoading: true,
        checkAllIndicator: false,
        indeterminateCheck: false,
        showActionSheet: false
      });
      await setPlanManagementInvoiceListFilter(currentFilterConfig.filters);
    }

    if (prevProps.planManagementInvoiceListFilter !== this.props.planManagementInvoiceListFilter) {
      this.setState({ isLoading: true });
      const requestFilter = this._formatFilterQuery();
      await doFetchWaivedInvoiceList({
        page: 1,
        pageTimestamp: new Date(),
        pageSize: this.state.pageSize,
        ...requestFilter
      });
      this.setState({ isLoading: false, page: 1, pageTimestamp: new Date() });
    }
  };

  render() {
    const { planManagementInvoiceList, selectedItem } = this.props;

    let TargetActionModal: any = this.getActionModal();

    const { currentFilterConfig } = this.props;

    return (
      <div
        className="bg-white flex-1 width-full flex-column"
        style={{ overflowY: 'auto', position: 'relative' }}
        id="scroll"
      >
        {this.state.openAction && (
          <TargetActionModal
            isOpen={this.state.openAction}
            onClose={this._closeActionModal}
            deselect={this._onActionDeselect}
            refreshListing={this._refreshListings}
            selectedItem={selectedItem}
          />
        )}

        {/* Do NOT remove this container div. It's required for Safari sticky to work. Why it works, I have no idea.*/}
        <div>
          <div className="plan-management-header" ref={(com) => (this._headerElement = com)}>
            {/* Header */}
            <div className="flex-row justify-between align-center">
              <div>
                <Title level={3} className="mv-none" lineHeight={150}>
                  {currentFilterConfig.title}
                </Title>
                <Text color="secondary">{currentFilterConfig.description}</Text>
              </div>
              <div className="flex-column align-center">
                <div className="pr-x-small">
                  {/*{this.props.portalUser &&*/}
                  {/*  globalConfig.theBridgeProviderId === this.props.portalUser.serviceProviderId && (*/}
                  {/*    <Tooltip content="Export the line items in CSV">*/}
                  {/*      <IconButton*/}
                  {/*        color="white"*/}
                  {/*        onClick={() => this._openActionModal({ action: 'EXPORT', additionalData: null })}*/}
                  {/*        iconColor="black"*/}
                  {/*        size="large"*/}
                  {/*        disabled={paymentsList.length === 0}*/}
                  {/*        icon="download"*/}
                  {/*        className={'mr-small'}*/}
                  {/*      />*/}
                  {/*    </Tooltip>*/}
                  {/*  )}*/}
                  <Tooltip content="Refresh this view">
                    <IconButton
                      color="white"
                      onClick={this._refreshListings}
                      iconColor="black"
                      size="large"
                      icon="reload"
                    />
                  </Tooltip>
                </div>
              </div>
            </div>

            <div className={'flex-row align-center'}>
              <Search
                placeholder="Search by name"
                onChange={this._onEnterSearchText}
                loading={this.state.isSearching}
                style={{ width: '250px' }}
                className={'mr-large mb-small'}
                allowClear={true}
              />
              <FilterSection
                availableFilters={availableFilters}
                filters={this.props.planManagementInvoiceListFilter}
                onChangeFilter={this._onChangeFilter}
                displayTimezone={this.props.portalUser.timezone}
              />
            </div>
          </div>

          <table className="plan-payment-listing">
            <thead>
              <tr>
                {/* <th className="nowrap check-all" style={{ top: `${this.state.topHeight}px` }} /> */}
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap" />
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <SubTitle>Invoice date</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <SubTitle>Invoice number</SubTitle>
                </th>{' '}
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <SubTitle>Customer</SubTitle>
                </th>{' '}
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <SubTitle>Provider</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap text-align-right">
                  <SubTitle containerClassName="text-align-right">Total</SubTitle>
                </th>
              </tr>
            </thead>

            <tbody>
              {this.state.isLoading && (
                <tr style={{ borderBottom: '0px solid !important' }}>
                  <td colSpan={7}>
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                  </td>
                </tr>
              )}

              {!this.state.isLoading && _.isEmpty(planManagementInvoiceList) && (
                <tr style={{ cursor: 'default' }}>
                  <td colSpan={7} style={{ borderBottom: '0px solid' }}>
                    <PaymentEmptyState />
                  </td>
                </tr>
              )}
              <InfiniteScrollLoading
                hasMore={planManagementInvoiceList.length >= this.state.page * this.state.pageSize}
                loadingElementId={'scroll'}
                loadMore={this._fetchMoreInvoice}
                loaderColSpan={6}
                loadingOffSet={60}
              >
                {!this.state.isLoading &&
                  _.map(planManagementInvoiceList, (invoiceItem) => (
                    <PlanPaymentInvoiceRow
                      invoiceItem={invoiceItem}
                      // onCheckItem={this._checkItem}
                      history={this.props.history}
                      key={invoiceItem.planManagementInvoiceId}
                      openActionModal={this._openActionModal}
                      canProcess={false}
                      displayCheckbox={false}
                      type={'WAIVED'}
                      actions={null}
                      subActions={['EDIT', 'RESEND_TO_PAYMENT', 'VIEW_WAIVE_REASON', 'DELETE']}
                      doFetchPlanManagementInvoiceDetails={this.props.doFetchPlanManagementInvoiceDetails}
                      planManagementLineItemList={this.props.planManagementLineItemList}
                    />
                  ))}
              </InfiniteScrollLoading>
            </tbody>
          </table>
        </div>

        {/* Filler */}
        <div className="flex-1 bg-white">&nbsp;</div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  selectedPlanManagementInvoiceDetails: state.planManagementStore.selectedPlanManagementInvoiceDetails
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchWaivedInvoiceList: dispatch.planManagementStore.doFetchWaivedInvoiceList,
  setSelectedRejectPlanManagementLineItems: dispatch.planManagementStore.setSelectedRejectPlanManagementLineItems
});

export default connect(
  mapState,
  mapDispatch
)(PlanPayListWaivedSection);
