import React, { Component } from 'react';
import { Text, Title } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import { Col, Divider, Empty, Icon, Row, Skeleton } from 'antd';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import asyncDelay from 'utilities/asyncDelay';
import EditGroupServiceDetailModal, { EditGroupServiceDetailModalType } from './EditGroupServiceDetailModal';
import AddAddressModal from 'common-components/addresses/AddAddressModal';
import Script from 'react-load-script';
import globalConfig from 'variables/global-config';
import { PriceFrenquency } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';
import { GroupServiceClassification } from 'utilities/enum-utils';

interface IGroupServiceDetailPanelProps {
  serviceId: string;
  selectedServiceDetail: typeof state.servicesStore.selectedServiceDetail;
  doFetchGroupServiceDetail: typeof dispatch.servicesStore.doFetchGroupServiceDetail;
  doUpdateGroupServiceDetail: typeof dispatch.servicesStore.doUpdateGroupServiceDetail;
}

interface IGroupServiceDetailPanelState {
  isLoading: boolean;
  isOpenEditModal: boolean;
  isOpenAddAddressModal: boolean;
  editSection: EditGroupServiceDetailModalType;
}

const GroupDetailEmptyState = () => (
  <div className="mt-large mh-large mb-x2-large align-center flex-column">
    <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mt-x4-large mb-small" />
    <div className="text-align-center" style={{ width: '365px' }}>
      <Text size="x2-large" color="secondary" weight="bold">
        No group service details found.
      </Text>
    </div>
  </div>
);

class GroupServiceDetailPanel extends Component<IGroupServiceDetailPanelProps, IGroupServiceDetailPanelState> {
  state = {
    isLoading: false,
    isOpenEditModal: false,
    isOpenAddAddressModal: false,
    editSection: null
  };

  private _openEditModal = (editSection: EditGroupServiceDetailModalType) => {
    this.setState({ editSection, isOpenEditModal: true });
  };

  private _closeEditModal = () => {
    this.setState({ editSection: null, isOpenEditModal: false });
  };

  private _openAddAddressModal = () => {
    this.setState({ isOpenAddAddressModal: true });
  };

  private _closeAddAddressModal = () => {
    this.setState({ isOpenAddAddressModal: false });
  };

  private _onSaveAddress = async (location) => {
    const payload = { serviceId: this.props.serviceId, location };
    await this.props.doUpdateGroupServiceDetail(payload);
    this._closeAddAddressModal();
  };

  componentDidMount = async () => {
    const { serviceId, doFetchGroupServiceDetail } = this.props;
    const payload = { serviceId };
    this.setState({ isLoading: true });
    //await asyncDelay(1000);
    await doFetchGroupServiceDetail(payload);
    this.setState({ isLoading: false });
  };

  render() {
    const { selectedServiceDetail } = this.props;
    const { isLoading } = this.state;

    return (
      <div className="pt-x-large pl-medium width-full pb-x3-large">
        {isLoading ? (
          <Skeleton paragraph={{ rows: 5, width: '100%' }} active={true} className="anim-slide-left" />
        ) : !selectedServiceDetail ? (
          <GroupDetailEmptyState />
        ) : (
          <>
            <Script
              url={'https://maps.googleapis.com/maps/api/js?key=' + globalConfig.googleMapApiKey + '&libraries=places'}
            />
            <AddAddressModal
              isOpen={this.state.isOpenAddAddressModal}
              closeCreateEditAddressModal={this._closeAddAddressModal}
              address={selectedServiceDetail.location}
              saveCustomerAddress={this._onSaveAddress}
            />
            <EditGroupServiceDetailModal
              isOpen={this.state.isOpenEditModal}
              editSection={this.state.editSection}
              onClose={this._closeEditModal}
              selectedServiceDetail={this.props.selectedServiceDetail}
              serviceId={this.props.serviceId}
            />
            <Title level={4} className="mb-large">
              Service details
            </Title>
            <Row>
              <Col span={8} className="pr-large pv-medium">
                <div className="mb-medium">
                  <Text weight="bold">General service information</Text>
                </div>
                <div>
                  <Text>General information about this service.</Text>
                </div>
              </Col>
              <Col span={16} className="bg-white p-medium rounded-big shadow-container">
                <Text color="secondary">Service name</Text>
                <br />
                <Text>{selectedServiceDetail.serviceName}</Text>
                <div className="mt-x2-small mb-large">
                  <HyperlinkButton onClick={() => this._openEditModal(EditGroupServiceDetailModalType.NAME)}>
                    <Icon type="edit" /> Edit...
                  </HyperlinkButton>
                </div>

                <Text color="secondary">Service description</Text>
                <br />
                <div style={{ whiteSpace: 'pre-line' }}>{selectedServiceDetail.serviceDescription}</div>
                <div className="mt-x2-small mb-large">
                  <HyperlinkButton onClick={() => this._openEditModal(EditGroupServiceDetailModalType.DESCRIPTION)}>
                    <Icon type="edit" /> Edit...
                  </HyperlinkButton>
                </div>

                <Text color="secondary">Service category</Text>
                <br />
                <Text>
                  {!selectedServiceDetail.groupServiceClassification
                    ? ''
                    : selectedServiceDetail.groupServiceClassification === GroupServiceClassification.COMMUNITY
                    ? 'Community'
                    : 'Centre-based'}
                </Text>
                <div className="mt-x2-small">
                  <HyperlinkButton onClick={() => this._openEditModal(EditGroupServiceDetailModalType.CATEGORY)}>
                    <Icon type="edit" /> Edit...
                  </HyperlinkButton>
                </div>
              </Col>
            </Row>

            <Divider className="mv-large bg-standard-gray" />

            <Row>
              <Col span={8} className="pr-large pv-medium">
                <div className="mb-medium">
                  <Text weight="bold">Banner image</Text>
                </div>
                <div>
                  <Text>This image will appear in the GoodHuman marketplace when customers view this service.</Text>
                </div>
              </Col>
              <Col span={16} className="bg-white p-medium rounded-big shadow-container">
                <div className="flex-row align-center">
                  <div style={{ width: '152px' }}>
                    <img
                      src={selectedServiceDetail.bannerUrl.attachmentUrl}
                      className="rounded-big"
                      style={{ width: '152px', height: '107px' }}
                    />
                  </div>
                  <div className="ml-large line-height-120 text-size-regular">
                    <Text weight="bold" size="regular">
                      Image guidelines
                    </Text>
                    <ul className="pl-medium mt-medium ">
                      <li>Must be square, logo only (no text)</li>
                      <li>Fit to edge, trimming whitespace where possible</li>
                      <li>Transparent background for best results</li>
                      <li>Minimum width/height: 400px × 400px</li>
                    </ul>
                  </div>
                </div>
                <div className="mt-x2-small">
                  <HyperlinkButton onClick={() => this._openEditModal(EditGroupServiceDetailModalType.BANNER)}>
                    <Icon type="edit" /> Edit...
                  </HyperlinkButton>
                </div>
              </Col>
            </Row>
  
            <Divider className="mv-large bg-standard-gray" />

            <Row>
              <Col span={8} className="pr-large pv-medium">
                <div className="mb-medium">
                  <Text weight="bold">Location</Text>
                </div>
                <div>
                  <Text>Default location for sessions for this service</Text>
                </div>
              </Col>
              <Col span={16} className="bg-white p-medium rounded-big shadow-container">
                <Text color="secondary">Primary location</Text>
                <br />
                <Text className="line-height-120" style={{ whiteSpace: 'pre-line' }}>
                  {!selectedServiceDetail.location
                    ? ''
                    : typeof selectedServiceDetail.location === 'string'
                    ? selectedServiceDetail.location
                    : selectedServiceDetail.location.fullAddress}
                </Text>
                <div className="mt-x2-small">
                  <HyperlinkButton onClick={this._openAddAddressModal}>
                    <Icon type="edit" /> Edit...
                  </HyperlinkButton>
                </div>
              </Col>
            </Row>
  
            <Divider className="mv-large bg-standard-gray" />

            <Row>
              <Col span={8} className="pr-large pv-medium">
                <div className="mb-medium">
                  <Text weight="bold">Service department</Text>
                </div>
                <div>
                  <Text>Which service department does this service belong to within your organisation</Text>
                </div>
              </Col>
              <Col span={16} className="bg-white p-medium rounded-big shadow-container">
                <Text color="secondary">Service department</Text>
                <br />
                <Text>{selectedServiceDetail.serviceDepartmentName}</Text>
                <div className="mt-x2-small">
                  <HyperlinkButton onClick={() => this._openEditModal(EditGroupServiceDetailModalType.DEPARTMENT)}>
                    <Icon type="edit" /> Edit...
                  </HyperlinkButton>
                </div>
              </Col>
            </Row>
  
            <Divider className="mv-large bg-standard-gray" />

            <Row>
              <Col span={8} className="pr-large pv-medium">
                <div className="mb-medium">
                  <Text weight="bold">Display price</Text>
                </div>
                <div>
                  <Text>This is the price that will be displayed in the GoodHuman marketplace for this service</Text>
                </div>
              </Col>
              <Col span={16} className="bg-white p-large rounded-big shadow-container">
                <Text color="secondary">Display price</Text>
                <br />
                <Text>
                  ${selectedServiceDetail.price}{' '}
                  {CommonUtils.formatPriceFrequencyString(selectedServiceDetail.priceFrequency)}
                </Text>
                <div className="mt-x2-small">
                  <HyperlinkButton onClick={() => this._openEditModal(EditGroupServiceDetailModalType.PRICE)}>
                    <Icon type="edit" /> Edit...
                  </HyperlinkButton>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedServiceDetail: state.servicesStore.selectedServiceDetail
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupServiceDetail: dispatch.servicesStore.doFetchGroupServiceDetail,
  doUpdateGroupServiceDetail: dispatch.servicesStore.doUpdateGroupServiceDetail
});

export default connect(
  mapState,
  mapDispatch
)(GroupServiceDetailPanel);
