import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { ErrorSVG } from 'assets/UndrawSVG';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { Col, Row } from 'antd';

interface IBookingApproveTeamMemberPayForCancelledBookingState {
  canManuallyClose: boolean;
  step: number;
  title: string;
  isSendToFinance: boolean;
}

interface IBookingApproveTeamMemberPayForCancelledBookingProps {
  isOpen: boolean;
  selectedBooking: typeof state.bookingsStore.selectedBookingItem;
  isApproved: boolean;
  onClose: () => void;
  doApproveTeamMemberPayHoursForCancelledBooking: typeof dispatch.bookingsStore.doApproveTeamMemberPayHoursForCancelledBooking;
  doRevokeTeamMemberPayHoursForCancelledBooking: typeof dispatch.bookingsStore.doRevokeTeamMemberPayHoursForCancelledBooking;
}

class BookingApproveTeamMemberPayForCancelledBooking extends Component<
  IBookingApproveTeamMemberPayForCancelledBookingProps,
  IBookingApproveTeamMemberPayForCancelledBookingState
> {
  state = {
    canManuallyClose: true,
    isLoading: false,
    step: 0,
    title: '',
    isSendToFinance: false
  };

  private _updateTeamMemberPayStatus = async () => {
    const {
      doRevokeTeamMemberPayHoursForCancelledBooking,
      doApproveTeamMemberPayHoursForCancelledBooking,
      selectedBooking
    } = this.props;
    this.setState({ step: 4 });
    /** check if team member is paid, if yes, then call doRevokeTeamMemberPayForCancelledBooking else
     * doApproveTeamMemberPayForCancelledBooking */
    let payload = { supportWorkerIds: [selectedBooking.workerId] };
    let title = '';
    try {
      if (this.state.isSendToFinance) {
        // approve team members pay
        title = 'Team member payment approved';
        doApproveTeamMemberPayHoursForCancelledBooking(payload);
      } else {
        //revoke team member
        title = 'Approval revoked for team member payment';
        doRevokeTeamMemberPayHoursForCancelledBooking(payload);
      }
      this.setState({
        step: 2,
        title
      });
    } catch (err) {
      this.setState({ step: 3, canManuallyClose: true });
    }
  };

  private _onChangeTeamMemberPayStatus = () => {
    this.setState({ isSendToFinance: !this.props.isApproved }, () => this._updateTeamMemberPayStatus());
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    this._reset();
    onClose();
  };

  private _reset = () =>
    this.setState({
      step: 1,
      title: '',
      canManuallyClose: true
    });

  private _renderView = () => {
    const { step, isSendToFinance } = this.state;
    const { isApproved } = this.props;
    if (step === 1) {
      return (
        <div className="anim-slide-left">
          <Row className={'mt-small'}>
            <Text>
              Are you sure you want to {!isApproved ? 'approve' : 'revoke approval for '} the payment for this team
              member?
            </Text>
          </Row>
          <Row className={'mt-x2-large'}>
            <Col span={isApproved ? 14 : 16}></Col>
            <Col span={4}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                Cancel
              </SecondaryButton>
            </Col>
            <Col span={4}>
              <PrimaryButton size="large" onClick={this._onChangeTeamMemberPayStatus}>
                {!isApproved ? 'Approve' : 'Revoke Approval'}
              </PrimaryButton>
            </Col>
          </Row>
        </div>
      );
    }
    if (step === 2) {
      return (
        <>
          <div className={'mt-large'}>
            You have successfully {isSendToFinance ? 'approved' : 'revoked approval for'} payment for this team member.
          </div>
          <Row className={'mt-x2-large'}>
            <Col span={4} push={20}>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Close
              </PrimaryButton>
            </Col>
          </Row>
        </>
      );
    }
    if (step === 3) {
      return (
        <div>
          <div style={{ minHeight: 317 }} className="flex-column justify-center text-align-center">
            <div className="pv-medium flex-column justify-center">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium flex-column justify-center ">
              <Paragraph>Oops something has gone wrong, please try again</Paragraph>
            </div>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Back to booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (step === 4) {
      return (
        <div>
          <div style={{ minHeight: 317 }}>
            <SpinningLoader size={150} message={'Loading'} />
          </div>
        </div>
      );
    }
  };

  componentDidMount() {
    let { isApproved } = this.props;
    this.setState({
      title: !isApproved ? 'Approve team member payment' : 'Revoke approval for team member payment',
      step: 1
    });
  }

  render() {
    let { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title={this.state.title}
        width="large"
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        {this._renderView()}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doApproveTeamMemberPayHoursForCancelledBooking: dispatch.bookingsStore.doApproveTeamMemberPayHoursForCancelledBooking,
  doRevokeTeamMemberPayHoursForCancelledBooking: dispatch.bookingsStore.doRevokeTeamMemberPayHoursForCancelledBooking
});

export default connect(
  null,
  mapDispatch
)(BookingApproveTeamMemberPayForCancelledBooking);
