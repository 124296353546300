import React, { Component } from 'react';
import { SubTitle, Text } from 'common-components/typography';
import { Button, Col, Divider, Form, Icon, InputNumber, Row, Select } from 'antd';
import { ThemeType } from 'antd/lib/icon';
import { FormComponentProps } from 'antd/lib/form/Form';
import { IBillingLineItem } from 'interfaces/booking-interfaces';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import CommonUtils from 'utilities/common-utils';
import BookingBillingLineItemWaiveActionModel from 'views/bookings/components/BookingBillingLineItemWaiveActionModel';
import BookingBillingLineItemDeleteActionModel from 'views/bookings/components/BookingBillingLineItemDeleteActionModel';
import {
  BillingAutoGeneratedType,
  BillingPaymentStatus,
  BookingStatus,
  MmmGroup,
  NdisClaimType,
  PaymentSources,
  PaymentStatus,
} from 'utilities/enum-utils';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Menu, MenuItem, Popover } from '@blueprintjs/core';
import { ndisHelper } from 'variables/data-helpers';
import AddNDISLineItemModal from '../../bookings/details/sections/content-section/tabs-panel/AddNDISLineItemModal';
import FundingUtils from 'utilities/funding-utils';
import Utils from 'utilities/Utils';
import { Information } from 'common-components/alerts';
import PaymentSourceTag from 'common-components/tags/PaymentSourceTag';
import AddVCPLineItemModal from 'views/bookings/details/sections/content-section/tabs-panel/AddVCPLineItemModal';
import { dispatch, IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

const { Option } = Select;

interface IBillingLineItemProps extends FormComponentProps {
  index: number;
  isRemoving: boolean;
  billingLineItem: IBillingLineItem;
  claimTravelData?: any;
  bookingStatus?: string;
  billingSupportItems: any[];
  serviceBillingLineItems: any[];
  fundedCategories: any[];
  travelDistance: number;
  isCancelled: boolean;
  cancellationCode: string;
  cancellationReason: string;
  serviceAgreementItems?: any[];
  showLineItemLabel?: boolean;
  showDeleteIcon?: boolean;
  showRemoveReasonCheckbox?: boolean;
  showRemoveReason?: boolean;
  showPaymentMethod?: boolean;
  showActionButtons?: boolean;
  isEditPaymentLineItem?: boolean;
  onChangeLineItem?: (index: number, billingLineItem: any) => void;
  onWaiveLineItem?: (index: number) => void;
  onUnwaiveLineItem?: (index: number) => void;
  onDeleteLineItem?: (index: number) => void;
  onEditLineItem?: (index: number) => void;
  onSaveLineItem?: (index: number) => void;
  onCancelLineItem?: (index: number) => void;
  checkForDuplicate?: (lineItem: any) => boolean;
  timezoneData?: any;
  hasEditBillingLineItemPermission?: boolean;
  hasABTLineItems: boolean;
  hasPTNLCLineItems: boolean;
  isFilterOutTravelItem?: boolean;
  isLineItemLocked?: boolean;
  mmmGroup?: MmmGroup;
  locationState?: string;
  preferredPaymentSourceType?: string;
  paymentSourceAvailableTypes?: Array<any>;
  isSaveButtonOutside?: boolean;
  serviceType?: string;
  updateBillingItem?: (item, index?) => void;
  hideWarningFunding?: boolean;
  isBulkAddLineItem?: boolean;
  doGetVCPItems: typeof dispatch.servicesStore.doGetVCPItems;
  hideManagementMethod?: boolean;
  hideSelectionOptionLineItemWarning?: boolean;
  hideLineItemCost?: boolean;
  isAutoChargeLineItems?: boolean;
  hideBottomLineItemName?: boolean;
  hideInputLabels?: boolean;
  disableUnitSelection?: boolean;
}

interface IBillingLineItemState {
  selectedSupportCategorySupportItems: { value: string; label: string }[];
  supportCategory: string;
  supportItem: string;
  serviceId: string;
  supportItemOptions: any[];
  showMileageBilling: boolean;
  showMileage: boolean;
  isRejectSelected: boolean;
  showQuantity: boolean;
  action: string;
  isOpenModal: boolean;
  isOpenAddLineItem: boolean;
  localBillingItem: any;
  isOpenVCPItem: boolean;
}

const claimType = {
  DIRECT_SERVICE: 'STD',
  CANCELLATION: 'CANC',
  TRAVEL: 'TRAN',
  REPORT_WRITING: 'REPW',
  NON_FACE_TO_FACE: 'NF2F',
  TELEHEALTH_SUPPORTS: 'THLT',
  IRREGULAR_SIL_SUPPORTS: 'IRSS',
};

const billingAutoGeneratedLabel = [
  'schedule time of the booking',
  "worker's start & finish time",
  'worker travel before the booking',
  'worker travel during the booking',
  "worker's start & finish time",
  'customers service agreement (Non face-to-face charges)',
  'customers service agreement (Auto-charged line item)',
];

const unitOptions = [{ value: 'EA', label: 'Each' }, { value: 'H', label: 'Hourly' }];

const paymentMethodOptions = [
  { value: 'NDIA', label: 'NDIA Managed' },
  { value: 'SELF', label: 'Self Managed' },
  { value: 'PLAN', label: 'Plan Managed' },
];

const claimTypeOptions = [
  { value: claimType.DIRECT_SERVICE, label: 'Direct Service' },
  { value: claimType.CANCELLATION, label: 'Cancellation' },
  { value: claimType.TRAVEL, label: 'Travel' },
  { value: claimType.REPORT_WRITING, label: 'Report Writing' },
  { value: claimType.NON_FACE_TO_FACE, label: 'Non Face-to-face' },
  { value: claimType.TELEHEALTH_SUPPORTS, label: 'Telehealth supports' },
  { value: claimType.IRREGULAR_SIL_SUPPORTS, label: 'Irregular SIL supports' },
];

let supportItemAddedNumbers = [];

interface IBillingLineItemOptionMenuProps {
  onClickWaive: () => void;
  onClickDelete: () => void;
  lineItemPaymentStatus: string;
  bookingStatus: string;
}

class BillingLineItemOptionMenu extends Component<IBillingLineItemOptionMenuProps> {
  render() {
    const { onClickWaive, onClickDelete, lineItemPaymentStatus, bookingStatus } = this.props;
    return (
      <Menu>
        <MenuItem
          text={
            <>
              <Text weight="bold" color="red">
                Delete
              </Text>
              <br />
              <Text size="regular" color="secondary">
                Permanently delete this line item
              </Text>
            </>
          }
          className="hover-bg-green-lightest"
          onClick={onClickDelete}
        />
      </Menu>
    );
  }
}

class BillingLineItemStatus extends Component<{ paymentStatus: string }> {
  render() {
    const { paymentStatus } = this.props;
    let paymentStatusDisplay: { icon: string; iconTheme: ThemeType; iconColor: string; statusText: string } = {
      icon: '',
      iconTheme: 'filled',
      iconColor: '',
      statusText: '',
    };

    switch (paymentStatus) {
      case BillingPaymentStatus.REQUIRES_APPROVAL:
        paymentStatusDisplay.icon = 'clock-circle';
        paymentStatusDisplay.iconColor = 'gray';
        paymentStatusDisplay.statusText = 'Waiting Approval';
        break;
      case BillingPaymentStatus.READY_FOR_BILLING:
        paymentStatusDisplay.icon = 'check-circle';
        paymentStatusDisplay.iconColor = 'gray';
        paymentStatusDisplay.statusText = 'Approved for Payment';
        break;
      case BillingPaymentStatus.SEND_TO_FINANCE:
        paymentStatusDisplay.icon = 'clock-circle';
        paymentStatusDisplay.iconColor = 'blue';
        paymentStatusDisplay.statusText = 'Sent for Payment';
        break;
      case BillingPaymentStatus.PROCESSED:
        paymentStatusDisplay.icon = 'check';
        paymentStatusDisplay.iconTheme = 'outlined';
        paymentStatusDisplay.iconColor = 'green';
        paymentStatusDisplay.statusText = 'Processed';
        break;
      case BillingPaymentStatus.REJECTED:
        paymentStatusDisplay.icon = 'exclamation-circle';
        paymentStatusDisplay.iconColor = 'red';
        paymentStatusDisplay.statusText = 'Rejected';
        break;
      case BillingPaymentStatus.WAIVED:
        paymentStatusDisplay.icon = 'minus-circle';
        paymentStatusDisplay.iconColor = 'indigo';
        paymentStatusDisplay.statusText = 'Waived';
        break;
      case BillingPaymentStatus.NO_CHARGE:
        paymentStatusDisplay.icon = 'close-circle';
        paymentStatusDisplay.iconColor = 'gray';
        paymentStatusDisplay.statusText = 'Not charged';
        break;
    }

    return (
      <div>
        <Text weight="bold" size="x-large" color={paymentStatusDisplay.iconColor} className="mr-medium">
          <Icon
            type={paymentStatusDisplay.icon}
            theme={paymentStatusDisplay.iconTheme}
            className="mr-x-small text-size-x2-large"
          />
          {paymentStatusDisplay.statusText}
        </Text>
      </div>
    );
  }
}

class BillingLineItemV2 extends Component<IBillingLineItemProps, IBillingLineItemState> {
  state = {
    selectedSupportCategorySupportItems: [],
    supportCategory: '',
    supportItem: '',
    serviceId: '',
    supportItemOptions: [],
    showMileageBilling: false,
    showMileage: false,
    isRejectSelected: false,
    showQuantity: true,
    action: '',
    isOpenModal: false,
    isOpenAddLineItem: false,
    isEditPaymentLineItem: false,
    additionalCost: 0,
    localBillingItem: null,
    isOpenVCPItem: false,
  };

  private _showMileage = (lineItem) => {
    return (
      lineItem.ruleType && (lineItem.ruleType === 'ABT' || lineItem.ruleType === 'PTNLC') && lineItem.isAutoGenerated
    );
  };

  private _onChangeSupportItemNumber = async (value) => {
    const {
      mmmGroup,
      locationState,
      doGetVCPItems,
      isSaveButtonOutside,
      hideSelectionOptionLineItemWarning = false,
    } = this.props;
    const { localBillingItem, supportItemOptions } = this.state;

    if (localBillingItem.paymentSourceType === PaymentSources.NDIS) {
      // find supportItem
      const findItem = this.props.billingSupportItems.find((supportItem) => supportItem.supportItemNumber === value);

      // If the Support Item is not in the Service Line Item list, use the ndisHelper ( Be careful the name of the fields are different between them two)
      const supportItem = findItem ? findItem : ndisHelper.getBySupportItemNumber(value);

      localBillingItem.additionalCost = localBillingItem.additionalCost ? localBillingItem.additionalCost : 0;
      if (findItem) {
        localBillingItem.supportItemNumber = value;
        localBillingItem.isAutoGenerated = false;
        localBillingItem.supportCategoryNumber = supportItem.supportCategoryNumber;
        localBillingItem.supportItemNumberArray = value;
        localBillingItem.supportItem = supportItem.supportItem;
        localBillingItem.unitPrice =
          mmmGroup === MmmGroup.NonRemote
            ? CommonUtils.findStandardPrice(supportItem, locationState, 'API')
            : mmmGroup === MmmGroup.Remote
            ? supportItem.remotePrice
            : mmmGroup === MmmGroup.VeryRemote
            ? supportItem.veryRemotePrice
            : 0;
        localBillingItem.unit = supportItem.unit === 'H' ? 'H' : 'EA';
        localBillingItem.paymentMethod = supportItem.paymentMethod;
        localBillingItem.claimType = localBillingItem.claimType ? localBillingItem.claimType : 'STD';
        localBillingItem.isFunded = findItem.isFunded;
        localBillingItem.ruleType = supportItem.ruleType;
      } else {
        const fundedCategory = FundingUtils.isLineItemExistInFundingPackage(
          supportItem.SupportPurposeType,
          supportItem.SupportCategoryNumber,
          supportItem.SupportItemNumber,
          this.props.fundedCategories,
        );

        localBillingItem.isAutoGenerated = false;
        localBillingItem.supportItemNumber = value;
        localBillingItem.supportCategoryNumber = supportItem.SupportCategoryNumber;
        localBillingItem.supportItem = supportItem.SupportItem;
        localBillingItem.ruleType = supportItem.DateType;
        localBillingItem.supportItemNumberArray = value;
        localBillingItem.unitPrice =
          mmmGroup === MmmGroup.NonRemote
            ? CommonUtils.findStandardPrice(supportItem, locationState, 'JSON')
            : mmmGroup === MmmGroup.Remote
            ? supportItem.NationalRemote
            : mmmGroup === MmmGroup.VeryRemote
            ? supportItem.NationalVeryRemote
            : 0;
        localBillingItem.unit = supportItem.UnitOfMeasure === 'H' ? 'H' : 'EA';
        localBillingItem.paymentMethod = fundedCategory ? fundedCategory.paymentMethod : '';
        localBillingItem.claimType = 'STD';
      }
      const ruleType = supportItem.ruleType ? supportItem.ruleType : supportItem.DateType;
      localBillingItem.isNotInServiceAgreement = this._isNotInServiceAgreement(localBillingItem);
      // Depending if the lineItem comes from the Service list or ndisHelper, the structure is different
      if (ruleType === 'ABT' || ruleType === 'PTNLC') {
        // localBillingItem.qty = 1;
        // localBillingItem.billingQty = 1;
        localBillingItem.claimType = 'TRAN';
        localBillingItem.mileagePrice =
          ruleType === 'ABT'
            ? this.props.claimTravelData.transportPriceDuringBooking
            : this.props.claimTravelData.transportPriceBeforeBooking;
        localBillingItem.unitPrice = 1;
        localBillingItem.billingPrice = 1;
        localBillingItem.unit = 'EA';
        localBillingItem.additionalCost = 0;
        await this.setState({ showMileage: true, showMileageBilling: true, showQuantity: false });

        this._calculateMileagePrice(localBillingItem);
        this.setState({
          localBillingItem: { ...localBillingItem },
        });
      } else {
        await this._calculateTotalPrice(localBillingItem);
        await this.setState({
          localBillingItem: { ...localBillingItem },
          showMileage: false,
          showMileageBilling: false,
          showQuantity: true,
        });
      }
    } else if (localBillingItem.paymentSourceType === PaymentSources.VCP) {
      const findItem = _.find(this.props.billingSupportItems, (supportItem) => supportItem.supportItemNumber === value);
      const newItem = findItem
        ? findItem
        : _.isString(value)
        ? await doGetVCPItems({ searchString: value, isReturnOnly: true })
        : value;
      localBillingItem.supportItemNumber = newItem.supportItemNumber;
      localBillingItem.supportItem = newItem.supportItem;
      localBillingItem.isAutoGenerated = false;
      localBillingItem.unit = newItem.unit === 'H' ? 'H' : 'EA';
      localBillingItem.supportItemNumberArray = value;
      localBillingItem.unitPrice = newItem.unitPrice ? Number(newItem.unitPrice) : Number(newItem.price.nonRemotePrice);
      localBillingItem.supportItemName = newItem.supportItemName;
    }

    const lineItemSupportNumber = _.isString(value) ? value : value.supportItemNumber;
    // If the Line Items hasn't already been added to the select
    if (!_.find(supportItemAddedNumbers, (number) => number === lineItemSupportNumber)) {
      const newSupportItemOptions = supportItemOptions;
      newSupportItemOptions.push(
        <Option
          key={supportItemOptions.length}
          value={lineItemSupportNumber}
          title={
            lineItemSupportNumber + ' - ' + localBillingItem.supportItemName
              ? localBillingItem.supportItemName
              : localBillingItem.supportItem
          }
        >
          <>
            <Text weight={'bold'}>
              {lineItemSupportNumber}
              {localBillingItem.paymentSourceType === PaymentSources.NDIS && !hideSelectionOptionLineItemWarning && (
                <>
                  {' '}
                  ({localBillingItem.customerSupportLevel === '1' ? 'Standard' : 'High Needs'})
                  {!localBillingItem.isFunded && localBillingItem.isNotInServiceAgreement && (
                    <Text color="orange" className="ml-small">
                      (Not funded/in service agreement)
                    </Text>
                  )}
                  {!localBillingItem.isFunded && !localBillingItem.isNotInServiceAgreement && (
                    <Text color="orange" className="ml-small">
                      (Not funded)
                    </Text>
                  )}
                  {localBillingItem.isFunded && localBillingItem.isNotInServiceAgreement && (
                    <Text color="orange" className="ml-small">
                      (Not in service agreement)
                    </Text>
                  )}
                </>
              )}
            </Text>
            <br />
            <Text ellipsis={true} style={{ width: '100%' }}>
              {localBillingItem.paymentSourceType === PaymentSources.VCP
                ? localBillingItem.supportItemName
                  ? localBillingItem.supportItemName
                  : localBillingItem.supportItem
                : localBillingItem.supportItem}
            </Text>
          </>
        </Option>,
      );
      supportItemAddedNumbers.push(lineItemSupportNumber);
      this.props.form.setFieldsValue({
        qty: localBillingItem.qty ? localBillingItem.qty : 1,
        billingQty: localBillingItem.billingQty ? localBillingItem.billingQty : 1,
      });
      this.setState({ supportItemOptions: newSupportItemOptions });
    }

    await this._calculateTotalPrice(localBillingItem);
    await this.setState({
      localBillingItem: { ...localBillingItem },
      showMileage: false,
      showMileageBilling: false,
      showQuantity: true,
    });

    if (isSaveButtonOutside) {
      this.props.updateBillingItem(localBillingItem, this.props.index);
    }

    this.setState({ isOpenAddLineItem: false });
  };

  private _onChangeUnit = (value) => {
    const { localBillingItem } = this.state;
    localBillingItem.unit = value;
    this.setState({ localBillingItem });

    if (this.props.isSaveButtonOutside) {
      this.props.updateBillingItem(localBillingItem, this.props.index);
    }
  };

  private _onChangePaymentMethod = (value) => {
    const { localBillingItem } = this.state;
    localBillingItem.paymentMethod = value;
    this.setState({ localBillingItem });
    if (this.props.isSaveButtonOutside) {
      this.props.updateBillingItem(localBillingItem, this.props.index);
    }
  };

  private _onChangeClaimType = (value) => {
    const { localBillingItem } = this.state;
    localBillingItem.claimType = value;

    this.setState({
      localBillingItem: { ...localBillingItem },
    });
    if (this.props.isSaveButtonOutside) {
      this.props.updateBillingItem(localBillingItem, this.props.index);
    }
  };

  private _renderFormField = (editValue, displayValue, canEdit = true) => {
    return this.props.billingLineItem.isEditing && canEdit ? editValue : <Text>{displayValue}</Text>;
  };

  private _onChangeTravelDistance = () => {
    // const { localBillingItem } = this.state;
    const newLocalBillingItem = _.cloneDeep(this.state.localBillingItem);
    const billingLineItem = this._getBillingLineItemValue();
    this._calculateMileagePrice(billingLineItem);

    newLocalBillingItem.unitPrice = billingLineItem.unitPrice;
    newLocalBillingItem.total = billingLineItem.total;
    newLocalBillingItem.qty = billingLineItem.qty;
    newLocalBillingItem.billingPrice = billingLineItem.billingPrice;
    newLocalBillingItem.billingTotal = billingLineItem.billingTotal;
    newLocalBillingItem.billingQty = billingLineItem.billingQty;
    newLocalBillingItem.travelDistance = Number(billingLineItem.travelDistance);
    newLocalBillingItem.mileagePrice = billingLineItem.mileagePrice;
    newLocalBillingItem.additionalCost = billingLineItem.additionalCost;

    this.setState({ localBillingItem: newLocalBillingItem });
    if (this.props.isSaveButtonOutside) {
      this.props.updateBillingItem(newLocalBillingItem, this.props.index);
    }
  };

  // TODO: Revisit for cancellation calculation
  private _onChangeCalculateTotalPrice = () => {
    // const { localBillingItem } = this.state;
    const newLocalBillingItem = _.cloneDeep(this.state.localBillingItem);
    const billingLineItem = this._getBillingLineItemValue();
    this._calculateTotalPrice(billingLineItem);

    newLocalBillingItem.unitPrice = billingLineItem.unitPrice;
    newLocalBillingItem.total = billingLineItem.total;
    newLocalBillingItem.qty = billingLineItem.qty;
    newLocalBillingItem.billingPrice = billingLineItem.billingPrice;
    newLocalBillingItem.billingTotal = billingLineItem.billingTotal;
    newLocalBillingItem.billingQty = billingLineItem.billingQty;

    this.setState({ localBillingItem: newLocalBillingItem });
    if (this.props.isSaveButtonOutside) {
      this.props.updateBillingItem(newLocalBillingItem, this.props.index);
    }
  };

  private _calculateMileagePrice = (billingLineItem) => {
    const mileagePrice = Number(billingLineItem.mileagePrice);
    let mileageTotal = 0;

    billingLineItem.additionalCost = billingLineItem.additionalCost ? billingLineItem.additionalCost : 0;

    if (mileagePrice > 0 && Number(billingLineItem.travelDistance) > 0) {
      mileageTotal = Number(
        (mileagePrice * Number(billingLineItem.travelDistance) + Number(billingLineItem.additionalCost)).toFixed(2),
      );
      billingLineItem.unitPrice = mileageTotal;
      billingLineItem.total = mileageTotal;
      billingLineItem.billingTotal = billingLineItem.total;
      billingLineItem.billingPrice = billingLineItem.unitPrice;
      billingLineItem.billingQty = 1;
      billingLineItem.qty = 1;
    } else {
      billingLineItem.billingPrice = 0;
      billingLineItem.billingQty = billingLineItem.qty = 0;
    }

    // calculate total
    let lineTotal = 0;

    if (billingLineItem.unitPrice > 0 && billingLineItem.billingQty > 0) {
      lineTotal = billingLineItem.unitPrice * billingLineItem.billingQty;
    }
    billingLineItem.billingTotal = billingLineItem.total = Number(lineTotal.toFixed(2));

    this.props.form.setFieldsValue({ unitPrice: billingLineItem.unitPrice });
  };

  private _calculateTotalPrice = (billingLineItem) => {
    // set billingQty and billingPrice same as qty, unitPrice
    billingLineItem.billingQty = billingLineItem.qty;
    billingLineItem.billingPrice = billingLineItem.unitPrice;

    if (billingLineItem.unitPrice && billingLineItem.qty) {
      // cancellation policy calculate
      if (this.props.isCancelled) {
        let billingPrice = Number(billingLineItem.unitPrice);
        billingLineItem.billingTotal = Number(billingPrice) * billingLineItem.qty;
        billingLineItem.total = Number(billingLineItem.unitPrice) * billingLineItem.qty;
      } else {
        billingLineItem.billingTotal = billingLineItem.total = Number(billingLineItem.unitPrice) * billingLineItem.qty;
      }
    } else {
      billingLineItem.billingTotal = billingLineItem.total = 0;
    }
  };

  private _getBillingLineItemValue = () => {
    return this.props.form.getFieldsValue();
  };

  private _onSaveLineItem = async () => {
    const { onSaveLineItem, onChangeLineItem, index, form } = this.props;
    const { localBillingItem } = this.state;

    if (!this.props.checkForDuplicate(localBillingItem)) {
      form.validateFieldsAndScroll(async (error) => {
        if (!error) {
          await onChangeLineItem(index, localBillingItem);
          await onSaveLineItem(index);
        }
      });
    }
  };

  public onValidate = async () => {
    const { form } = this.props;

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  private _onCancelLineItem = async () => {
    const { onCancelLineItem, index } = this.props;
    onCancelLineItem(index);
  };

  private _renderActionButtons = () => {
    const {
      index,
      showActionButtons,
      onEditLineItem,
      billingLineItem,
      bookingStatus,
      hasEditBillingLineItemPermission = true,
      isSaveButtonOutside = false,
    } = this.props;

    if (billingLineItem.isEditing) {
      if (!isSaveButtonOutside) {
        return (
          <Button.Group className="mt-x-small">
            <PrimaryButton onClick={this._onSaveLineItem}>Save</PrimaryButton>
            <SecondaryButton onClick={this._onCancelLineItem}>Cancel</SecondaryButton>
          </Button.Group>
        );
      }
    } else {
      if (
        showActionButtons &&
        !(
          bookingStatus === BookingStatus.CUSTOMER_CANCELLED ||
          bookingStatus === BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE ||
          bookingStatus === BookingStatus.BUSINESS_CANCELLED
        ) &&
        (billingLineItem.paymentStatus === BillingPaymentStatus.INITIAL ||
          billingLineItem.paymentStatus === BillingPaymentStatus.REQUIRES_APPROVAL ||
          billingLineItem.paymentStatus === BillingPaymentStatus.READY_FOR_BILLING) &&
        hasEditBillingLineItemPermission
      ) {
        return (
          <>
            <SecondaryButton
              className="mh-x-small"
              icon="edit"
              onClick={() => {
                onEditLineItem(index);
              }}
            >
              Edit
            </SecondaryButton>
            <Popover
              content={
                <BillingLineItemOptionMenu
                  bookingStatus={this.props.bookingStatus}
                  lineItemPaymentStatus={billingLineItem.paymentStatus}
                  onClickWaive={() => {
                    this._openActionModal({ action: 'WAIVE' });
                  }}
                  onClickDelete={() => {
                    this._openActionModal({ action: 'DELETE' });
                  }}
                />
              }
              position={'bottom-right'}
            >
              <SecondaryButton icon={'ellipsis'} onClick={() => {}} />
            </Popover>
          </>
        );
      }
    }
  };

  private _openActionModal = ({ action }) => {
    this.setState({ action, isOpenModal: true });
  };

  private _closeActionModal = () => this.setState({ isOpenModal: false });

  private _getActionModal = () => {
    // Depending on the action target type, use the correct modal.
    if (this.state.action === 'WAIVE') {
      return BookingBillingLineItemWaiveActionModel;
    }
    if (this.state.action === 'DELETE') {
      return BookingBillingLineItemDeleteActionModel;
    } else {
      return () => <></>;
    }
  };

  private _getActionModalOnAction = () => {
    const { onWaiveLineItem, onUnwaiveLineItem, onDeleteLineItem } = this.props;
    switch (this.state.action) {
      case 'WAIVE':
        return onWaiveLineItem;
      case 'DELETE':
        return onDeleteLineItem;
      case 'UNWAIVE':
        return onUnwaiveLineItem;
    }
  };

  private _closeLineItemModal = () => {
    this.setState({ isOpenAddLineItem: false });
  };

  private _openLineItemModal = () => {
    this.setState({ isOpenAddLineItem: true });
  };

  private _closeVCPModal = () => {
    this.setState({ isOpenVCPItem: false });
  };

  private _openVCPModal = () => {
    this.setState({ isOpenVCPItem: true });
  };

  public getBillingLineItem = () => {
    return this.state.localBillingItem;
  };

  private _validateUnitPrice = (rule, value, callback) => {
    try {
      if (Utils.isEmpty(value) || value === '0' || value === 0) {
        throw Error('Please input unit price');
      }
      if (value < 0) {
        throw Error('unit price should not less than 0');
      }
      if (value > 100000) {
        throw Error('unit price should not greater than 100000');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _validateQty = (rule, value, callback) => {
    try {
      if (Utils.isEmpty(value) || value === '0' || value === 0) {
        throw Error('Please input quantity');
      }
      if (value < 0) {
        throw Error('quantity should not less than 0');
      }
      if (value > 1000) {
        throw Error('quantity should not greater than 1000');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _validateTravelDistance = (rule, value, callback) => {
    try {
      if (Utils.isEmpty(value) || value === '0' || value === 0) {
        throw Error('Please input kilometers travelled');
      }
      if (value < 0) {
        throw Error('kilometers travelled should not less than 0');
      }
      if (value > 10000) {
        throw Error('kilometers travelled should not greater than 10000');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _validateTransportPrice = (rule, value, callback) => {
    try {
      if (Utils.isEmpty(value) || value === '0' || value === 0) {
        throw Error('Please input cost per kilometres');
      }
      if (value < 0) {
        throw Error('cost per kilometres should not less than 0');
      }
      if (value > 10000) {
        throw Error('cost per kilometres should not greater than 10000');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _validateAdditionalCost = (rule, value, callback) => {
    try {
      if (value < 0) {
        throw Error('additional cost should not less than 0');
      }
      if (value > 10000) {
        throw Error('additional cost should not greater than 10000');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _getInformationText = (lineItem) => {
    const { displayTimezone } = this.props.timezoneData;
    let informationText = (
      <>
        This line item has been automatically generated based on the{' '}
        <b>{billingAutoGeneratedLabel[lineItem.generatedBy]}</b>.
      </>
    );
    let additionalText = null;
    if (
      lineItem.generatedBy === BillingAutoGeneratedType.BeforeBookingTravel ||
      lineItem.generatedBy === BillingAutoGeneratedType.DuringBookingTravel
    ) {
      additionalText = (
        <>
          <br />
          This line item is not directly editable. To edit this line item you will need to change the travel inputs in
          the Billing header.
        </>
      );
    } else {
      additionalText = (
        <>
          <br />
          Generated for the date/time period of:
          <br />
          <b>
            {moment
              .tz(lineItem.startDateTime, displayTimezone)
              .startOf('day')
              .isSame(moment.tz(lineItem.endDateTime, displayTimezone).startOf('day')) ? (
              <>
                {moment.tz(lineItem.startDateTime, displayTimezone).format('dddd, D MMMM YYYY h:mma')} -{' '}
                {moment.tz(lineItem.endDateTime, displayTimezone).format('h:mma')}
              </>
            ) : (
              <>
                {moment.tz(lineItem.startDateTime, displayTimezone).format('dddd, D MMMM YYYY h:mma')} -{' '}
                {moment.tz(lineItem.endDateTime, displayTimezone).format('dddd, D MMMM YYYY h:mma')}
              </>
            )}
          </b>
        </>
      );
    }
    return (
      <>
        {informationText}
        {additionalText}
      </>
    );
  };

  private _onChangePaymentSourceType = async (e) => {
    const newLocalBillingItem = {
      ...this.state.localBillingItem,
      supportItemNumber: null,
      supportItemNumberArray: [],
      qty: 0,
      unitPrice: 0,
      unit: null,
      description: null,
      total: 0,
      paymentMethod: null,
      ruleType: null,
      isTravel: false,
      travelDistance: 0,
      mileagePrice: 0,
      claimType: '',
      paymentSourceType: e,
    };
    this.setState({
      localBillingItem: newLocalBillingItem,
      supportItemOptions: await this._getSupportItemOptions(e),
    });

    if (this.props.isSaveButtonOutside) {
      this.props.updateBillingItem(newLocalBillingItem, this.props.index);
    }
    supportItemAddedNumbers = [];
  };

  private _getSupportItemOptions = (paymentSourceType) => {
    const {
      billingSupportItems,
      isEditPaymentLineItem = false,
      hideSelectionOptionLineItemWarning = false,
      isAutoChargeLineItems = false,
    } = this.props;

    const filteredBillingSupportItems = _.filter(
      billingSupportItems,
      (lineItem) => lineItem.paymentSourceType === paymentSourceType,
    );

    return isEditPaymentLineItem && !isAutoChargeLineItems
      ? []
      : _.map(filteredBillingSupportItems, (billingSupportItem, key) => {
          return (
            <Option
              key={key}
              value={billingSupportItem.supportItemNumber}
              title={
                billingSupportItem.supportItemNumber + '-' + billingSupportItem.supportItemName
                  ? billingSupportItem.supportItemName
                  : billingSupportItem.supportItem
              }
            >
              <>
                <Text weight={'bold'}>
                  {billingSupportItem.supportItemNumber}
                  {billingSupportItem.paymentSourceType === PaymentSources.NDIS && !hideSelectionOptionLineItemWarning && (
                    <>
                      {' '}
                      ({billingSupportItem.customerSupportLevel === '1' ? 'Standard' : 'High Needs'})
                      {!billingSupportItem.isFunded && billingSupportItem.isNotInServiceAgreement && (
                        <Text color="orange" className="ml-small">
                          (Not funded/in service agreement)
                        </Text>
                      )}
                      {!billingSupportItem.isFunded && !billingSupportItem.isNotInServiceAgreement && (
                        <Text color="orange" className="ml-small">
                          (Not funded)
                        </Text>
                      )}
                      {billingSupportItem.isFunded && billingSupportItem.isNotInServiceAgreement && (
                        <Text color="orange" className="ml-small">
                          (Not in service agreement)
                        </Text>
                      )}
                    </>
                  )}
                </Text>
                <br />
                <Text>
                  {billingSupportItem.paymentSourceType === PaymentSources.VCP
                    ? billingSupportItem.supportItemName
                      ? billingSupportItem.supportItemName
                      : billingSupportItem.supportItem
                    : billingSupportItem.supportItem}
                </Text>
              </>
            </Option>
          );
        });
  };

  private _isNotInServiceAgreement = (lineItem) => {
    return !_.find(this.props.serviceAgreementItems, {
      supportItemNumber: lineItem.supportItemNumber,
    });
  };

  componentDidMount = () => {
    const {
      billingLineItem,
      claimTravelData,
      billingSupportItems,
      fundedCategories,
      preferredPaymentSourceType,
      hideSelectionOptionLineItemWarning = false,
    } = this.props;
    // let tobeSetBillingItem: any = _.cloneDeep(billingLineItem);
    let tobeSetBillingItem: any = { ...billingLineItem };

    tobeSetBillingItem.paymentSourceType = tobeSetBillingItem.paymentSourceType
      ? tobeSetBillingItem.paymentSourceType
      : preferredPaymentSourceType;

    if (tobeSetBillingItem.paymentSourceType === PaymentSources.NDIS) {
      tobeSetBillingItem.isFunded = !_.isEmpty(
        FundingUtils.isLineItemExistInFundingPackage(
          tobeSetBillingItem.supportType,
          tobeSetBillingItem.supportCategoryNumber,
          tobeSetBillingItem.supportItemNumber,
          fundedCategories,
        ),
      );

      tobeSetBillingItem.isNotInServiceAgreement = this._isNotInServiceAgreement(tobeSetBillingItem);
    }

    if (!tobeSetBillingItem.supportItem) {
      const supportItem = ndisHelper.getBySupportItemNumber(tobeSetBillingItem.supportItemNumber);
      // If is adding new billing line item then supportItem will be undefined
      if (supportItem) {
        tobeSetBillingItem.supportItem = supportItem.SupportItem;
      }
    }

    _.forEach(billingSupportItems, (item) => {
      // Add supportItem for every billingSupportItems
      if (!item.supportItem && item.paymentSourceType === PaymentSources.NDIS) {
        item.supportItem = ndisHelper.getBySupportItemNumber(item.supportItemNumber).SupportItem;
      }
    });

    // Initialise supportItemAddedNumbers
    supportItemAddedNumbers = _.map(
      _.filter(billingSupportItems, (lineItem) => lineItem.paymentSourceType === tobeSetBillingItem.paymentSourceType),
      (supportItem) => supportItem.supportItemNumber,
    );

    const supportItemOptions = this._getSupportItemOptions(tobeSetBillingItem.paymentSourceType);

    // If the saved line is not from the Service list, we add it to the select.
    if (
      tobeSetBillingItem.supportItemNumber &&
      !_.find(billingSupportItems, (item) => tobeSetBillingItem.supportItemNumber === item.supportItemNumber)
    ) {
      // const newItem = ndisHelper.getBySupportItemNumber(tobeSetBillingItem.supportItemNumber);
      // if (newItem) {
      supportItemAddedNumbers.push(tobeSetBillingItem.supportItemNumber);
      supportItemOptions.push(
        <Option
          key={supportItemOptions.length}
          value={tobeSetBillingItem.supportItemNumber}
          disabled={false}
          title={
            tobeSetBillingItem.supportItemNumber + '-' + tobeSetBillingItem.supportItemName
              ? tobeSetBillingItem.supportItemName
              : tobeSetBillingItem.supportItem
          }
        >
          <>
            <Text weight={'bold'}>
              {tobeSetBillingItem.supportItemNumber}
              {tobeSetBillingItem.paymentSourceType === PaymentSources.NDIS && !hideSelectionOptionLineItemWarning && (
                <>
                  {' '}
                  ({tobeSetBillingItem.customerSupportLevel === '1' ? 'Standard' : 'High Needs'})
                  {!tobeSetBillingItem.isFunded && tobeSetBillingItem.isNotInServiceAgreement && (
                    <Text color="orange" className="ml-small">
                      (Not funded/in service agreement)
                    </Text>
                  )}
                  {!tobeSetBillingItem.isFunded && !tobeSetBillingItem.isNotInServiceAgreement && (
                    <Text color="orange" className="ml-small">
                      (Not funded)
                    </Text>
                  )}
                  {tobeSetBillingItem.isFunded && tobeSetBillingItem.isNotInServiceAgreement && (
                    <Text color="orange" className="ml-small">
                      (Not in service agreement)
                    </Text>
                  )}
                </>
              )}
            </Text>
            <br />
            <Text ellipsis={true} style={{ width: '100%' }}>
              {tobeSetBillingItem.paymentSourceType === PaymentSources.VCP
                ? tobeSetBillingItem.supportItemName
                  ? tobeSetBillingItem.supportItemName
                  : tobeSetBillingItem.supportItem
                : tobeSetBillingItem.supportItem}
            </Text>
          </>
        </Option>,
      );
      // }
    }

    // If the ABT or the PTNLC are claimable, they are added to the list based on the selected line item.

    if (billingLineItem.ruleType === 'ABT') {
      tobeSetBillingItem.travelDistance = tobeSetBillingItem.isAutoGenerated
        ? claimTravelData.travelDistanceDuringBooking
        : tobeSetBillingItem.travelDistance;
      tobeSetBillingItem.mileagePrice = tobeSetBillingItem.mileagePrice;
      tobeSetBillingItem.additionalCost = tobeSetBillingItem.isAutoGenerated
        ? claimTravelData.additionalCostDuringBooking
        : tobeSetBillingItem.total - tobeSetBillingItem.mileagePrice * tobeSetBillingItem.travelDistance;
    }
    if (billingLineItem.ruleType === 'PTNLC') {
      tobeSetBillingItem.travelDistance = tobeSetBillingItem.isAutoGenerated
        ? claimTravelData.travelDistanceBeforeBooking
        : tobeSetBillingItem.travelDistance;
      tobeSetBillingItem.mileagePrice = tobeSetBillingItem.mileagePrice;
      tobeSetBillingItem.additionalCost = tobeSetBillingItem.isAutoGenerated
        ? claimTravelData.additionalCostBeforeBooking
        : tobeSetBillingItem.total - tobeSetBillingItem.mileagePrice * tobeSetBillingItem.travelDistance;
    }

    this.setState({
      supportItemOptions,
      showQuantity: true,
      showMileage: this._showMileage(billingLineItem),
      localBillingItem: tobeSetBillingItem,
    });
  };

  componentDidUpdate(prevProps: Readonly<IBillingLineItemProps>, prevState: Readonly<IBillingLineItemState>): void {
    const { claimTravelData } = this.props;
    if (prevState.localBillingItem !== this.state.localBillingItem && this.props.billingLineItem.isEditing) {
      const { localBillingItem } = this.state;
      // set antd form to latest value
      const fieldValues: any = {};
      if (localBillingItem.paymentSourceType === PaymentSources.NDIS) {
        fieldValues.supportItemNumber = localBillingItem.supportItemNumber;
        fieldValues.paymentMethod = localBillingItem.paymentMethod;
        fieldValues.unitPrice = localBillingItem.unitPrice;
        fieldValues.unit = localBillingItem.unit;
      } else {
        fieldValues.supportItemNumber = localBillingItem.supportItemNumber;
        fieldValues.unitPrice = localBillingItem.unitPrice;
      }
      this.props.form.setFieldsValue(fieldValues);
    }

    if (prevProps.billingLineItem !== this.props.billingLineItem && this.props.billingLineItem.supportItemNumber) {
      const billingLineItem: any = { ...this.props.billingLineItem };

      if (this.props.billingLineItem.paymentSourceType === PaymentSources.NDIS) {
        billingLineItem.supportType = ndisHelper.getBySupportItemNumber(
          billingLineItem.supportItemNumber,
        ).SupportPurposeType;

        if (billingLineItem.ruleType === 'ABT') {
          billingLineItem.travelDistance = billingLineItem.isAutoGenerated
            ? claimTravelData.travelDistanceDuringBooking
            : billingLineItem.travelDistance;
          billingLineItem.mileagePrice = billingLineItem.isAutoGenerated
            ? claimTravelData.transportPriceDuringBooking
            : billingLineItem.mileagePrice;
          billingLineItem.additionalCost = billingLineItem.isAutoGenerated
            ? claimTravelData.additionalCostDuringBooking
            : billingLineItem.total - billingLineItem.mileagePrice * billingLineItem.travelDistance;
        }
        if (billingLineItem.ruleType === 'PTNLC') {
          billingLineItem.travelDistance = billingLineItem.isAutoGenerated
            ? claimTravelData.travelDistanceBeforeBooking
            : billingLineItem.travelDistance;
          billingLineItem.mileagePrice = billingLineItem.isAutoGenerated
            ? claimTravelData.transportPriceBeforeBooking
            : billingLineItem.mileagePrice;
          billingLineItem.additionalCost = billingLineItem.isAutoGenerated
            ? claimTravelData.additionalCostBeforeBooking
            : billingLineItem.total - billingLineItem.mileagePrice * billingLineItem.travelDistance;
        }

        billingLineItem.isFunded = !_.isEmpty(
          FundingUtils.isLineItemExistInFundingPackage(
            billingLineItem.supportType,
            billingLineItem.supportCategoryNumber,
            billingLineItem.supportItemNumber,
            this.props.fundedCategories,
          ),
        );

        billingLineItem.isNotInServiceAgreement = this._isNotInServiceAgreement(billingLineItem);
        // this.props.onChangeLineItem(this.props.index, this.props.billingLineItem);
        this.setState({
          localBillingItem: billingLineItem,
          showQuantity: true,
          showMileage: this._showMileage(billingLineItem),
        });
      } else {
        this.setState({
          localBillingItem: billingLineItem,
        });
      }
    }
  }

  render() {
    const {
      billingSupportItems,
      showLineItemLabel = true,
      index,
      isCancelled,
      cancellationCode,
      cancellationReason,
      isEditPaymentLineItem = false,
      claimTravelData,
      form,
      paymentSourceAvailableTypes,
      hideWarningFunding = false,
      serviceType = 'SUPPORT',
      hideManagementMethod = false,
      hideLineItemCost = false,
      hideBottomLineItemName = false,
      hideInputLabels = false,
      disableUnitSelection = false,
    } = this.props;

    const { isEditing } = this.props.billingLineItem;

    const { showQuantity, showMileage, isOpenModal, localBillingItem, supportItemOptions } = this.state;

    const { getFieldDecorator } = form;

    const isNDISLineItem =
      localBillingItem && localBillingItem.paymentSourceType
        ? localBillingItem.paymentSourceType === PaymentSources.NDIS
        : false;

    let selectedUnitOption;
    if (localBillingItem && isNDISLineItem) {
      selectedUnitOption = unitOptions.find((i) => i.value === localBillingItem.unit);
    }

    let TargetActionModal: any = this._getActionModal();

    if (localBillingItem) {
      const isInServiceBillingItems = billingSupportItems.find(
        (supportItem) => supportItem.supportItemNumber === localBillingItem.supportItemNumber,
      );

      if (isNDISLineItem && !isInServiceBillingItems && !_.isEmpty(localBillingItem.supportItemNumber)) {
        localBillingItem.supportItem = ndisHelper.getBySupportItemNumber(
          localBillingItem.supportItemNumber,
        ).SupportItem;
      }

      if (localBillingItem.claimType === '') {
        localBillingItem.claimType = claimType.DIRECT_SERVICE;
      }

      const isCentreCapitalCostLineItem = localBillingItem.supportItemNumber === '04_599_0136_6_1';

      const isGroupTransport = serviceType === 'GROUP' && localBillingItem.claimType === 'TRAN';

      const isTravelClaim = localBillingItem.ruleType === 'PTNLC' || localBillingItem.ruleType === 'ABT';

      const lineItemInformationContent = localBillingItem.isAutoGenerated
        ? this._getInformationText(localBillingItem)
        : null;

      const quantityDisplay = CommonUtils.formatQuantityDisplay(localBillingItem.qty);

      return (
        <>
          <AddNDISLineItemModal
            closeLineItemModal={this._closeLineItemModal}
            isOpen={this.state.isOpenAddLineItem}
            fundedCategories={this.props.fundedCategories}
            serviceBillingItems={this.props.serviceBillingLineItems}
            hasABTLineItems={this.props.hasABTLineItems}
            hasPTNLCLineItems={this.props.hasPTNLCLineItems}
            onChangeSupportItemNumber={this._onChangeSupportItemNumber}
            filterFunded={!isEditPaymentLineItem}
            isFilterOutTravelItem={this.props.isFilterOutTravelItem}
            serviceAgreementItems={this.props.serviceAgreementItems}
            hideWarningFunding={this.props.hideWarningFunding}
          />
          <AddVCPLineItemModal
            closeLineItemModal={this._closeVCPModal}
            isOpen={this.state.isOpenVCPItem}
            onChangeSupportItemNumber={this._onChangeSupportItemNumber}
          />
          <TargetActionModal
            isOpen={isOpenModal}
            onClose={this._closeActionModal}
            onAction={() => {
              let action = this._getActionModalOnAction();
              action(index);
            }}
          />

          <div className={`${!isEditPaymentLineItem && 'bordered bg-white rounded-big pv-x-large ph-large mb-medium'}`}>
            {showLineItemLabel && (
              <Row className="mb-large" type="flex" align="middle">
                <Col span={17} className={'flex-row align-center'}>
                  {isEditing && paymentSourceAvailableTypes && paymentSourceAvailableTypes.length > 1 ? (
                    <Form.Item className="m-none">
                      {getFieldDecorator('paymentSourceType', {
                        initialValue: localBillingItem.paymentSourceType,
                        rules: [
                          {
                            required: true,
                            message: 'Please select a payment method',
                          },
                        ],
                      })(
                        <Select size={'large'} style={{ width: '200px' }} onChange={this._onChangePaymentSourceType}>
                          {_.map(paymentSourceAvailableTypes, (paymentSourceType) => {
                            return <Select.Option value={paymentSourceType}>{paymentSourceType}</Select.Option>;
                          })}
                        </Select>,
                      )}
                    </Form.Item>
                  ) : (
                    <>
                      <PaymentSourceTag
                        className={'mr-large'}
                        paymentSource={
                          localBillingItem.paymentSourceType ? localBillingItem.paymentSourceType : PaymentSources.NDIS
                        }
                        size={'large'}
                      />
                      {!isEditing && (
                        <Text className="text-weight-bold" size="x2-large">
                          {localBillingItem.paymentStatus &&
                            localBillingItem.paymentStatus !== PaymentStatus.INITIAL && (
                              <BillingLineItemStatus paymentStatus={localBillingItem.paymentStatus} />
                            )}
                        </Text>
                      )}
                    </>
                  )}
                </Col>
                <Col span={7} className="text-align-right">
                  {this._renderActionButtons()}
                </Col>
              </Row>
            )}
            {/* Cancellation Reason if the booking is Cancelled */}
            {isCancelled && (
              <div className="mv-large">
                <SubTitle>Cancellation Code</SubTitle>
                <Text>{cancellationCode}</Text>
                {/* If cancellation code is NSDO (Other) */}
                {cancellationCode === 'NSDO' && (
                  <>
                    <br />
                    <SubTitle>Cancellation Reason</SubTitle>
                    <Text>{cancellationReason}</Text>
                  </>
                )}
              </div>
            )}

            {/* Reject Reason */}
            {localBillingItem.paymentStatus === BillingPaymentStatus.REJECTED && (
              <div className="mv-large">
                <SubTitle>
                  <Text color={'red-dark'}>Reject Reason</Text>
                </SubTitle>
                <Text>{localBillingItem.rejectReason}</Text>
              </div>
            )}

            {localBillingItem.isAutoGenerated && !this.props.isLineItemLocked && (
              <div className={'mb-large'}>
                <Information content={<Text>{lineItemInformationContent}</Text>} className={'width-full'} />
              </div>
            )}
            {!hideInputLabels && (
              <Row gutter={24} className="bordered-bottom pb-small">
                <Col span={hideManagementMethod ? 11 : 7}>
                  <b>Support Item</b>
                </Col>
                {isNDISLineItem && (
                  <>
                    <Col span={4}>
                      <b>Claim Type</b>
                    </Col>
                    {!hideManagementMethod && (
                      <Col span={4}>
                        <b>Management</b>
                      </Col>
                    )}
                    <Col span={3}>
                      <b>Unit</b>
                    </Col>
                  </>
                )}
                <Col span={3}>
                  <b>Price</b>
                </Col>
                {showQuantity && (
                  <Col span={3}>
                    <b>Quantity</b>
                  </Col>
                )}
              </Row>
            )}
            <Row gutter={24} className="pv-medium" type="flex" align={'top'}>
              <Col span={hideManagementMethod ? 11 : 7}>
                {this._renderFormField(
                  <Form.Item className="m-none">
                    {getFieldDecorator('supportItemNumber', {
                      initialValue: localBillingItem.supportItemNumberArray,
                      // valuePropName: 'defaultValue',
                      rules: [
                        {
                          required: true,
                          message: 'Please select a support item number',
                        },
                      ],
                    })(
                      <Select
                        allowClear={false}
                        size="large"
                        showSearch={true}
                        filterOption
                        optionFilterProp={'title'}
                        optionLabelProp={'value'}
                        disabled={isCentreCapitalCostLineItem || isGroupTransport}
                        dropdownRender={(menu) => (
                          <div>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <div
                              style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              <HyperlinkButton
                                className="text-color-green"
                                onClick={!isNDISLineItem ? this._openVCPModal : this._openLineItemModal}
                              >
                                <Icon type={'plus'} /> Select another Line Item
                              </HyperlinkButton>
                            </div>
                          </div>
                        )}
                        dropdownMatchSelectWidth={false}
                        style={{ width: '100%', whiteSpace: 'pre-wrap' }}
                        onChange={(event) => this._onChangeSupportItemNumber(event)}
                      >
                        {supportItemOptions}
                      </Select>,
                    )}
                  </Form.Item>,
                  <>
                    {localBillingItem.supportItemNumber && (
                      <Text size="large">
                        {localBillingItem.supportItemNumber ? <b>{localBillingItem.supportItemNumber}</b> : '-'}
                        <br />
                        {localBillingItem.paymentSourceType === PaymentSources.VCP
                          ? localBillingItem.supportItemName
                          : !_.isEmpty(isInServiceBillingItems)
                          ? isInServiceBillingItems.supportItem
                          : localBillingItem.supportItem
                          ? localBillingItem.supportItem
                          : ''}
                      </Text>
                    )}
                  </>,
                  !isTravelClaim || (!this.props.isFilterOutTravelItem && !localBillingItem.isAutoGenerated),
                )}
              </Col>
              {isNDISLineItem && (
                <>
                  <Col span={4}>
                    {isCancelled && this.props.bookingStatus !== BookingStatus.CUSTOMER_CANCELLED_WITH_FEE
                      ? claimTypeOptions.find((item) => item.value === localBillingItem.claimType)
                        ? claimTypeOptions.find((item) => item.value === localBillingItem.claimType).label
                        : 'Direct Service'
                      : this._renderFormField(
                          <Form.Item className="m-none">
                            {getFieldDecorator('claimType', {
                              initialValue: localBillingItem.claimType,
                              rules: [
                                {
                                  required: true,
                                  message: 'Please select a claim type',
                                },
                              ],
                            })(
                              <Select
                                size="large"
                                showSearch
                                style={{ width: '160px' }}
                                onChange={this._onChangeClaimType}
                                filterOption={true}
                                disabled={isCentreCapitalCostLineItem || isGroupTransport}
                                optionFilterProp={'children'}
                                dropdownMatchSelectWidth={false}
                              >
                                {_.filter(
                                  claimTypeOptions,
                                  (claimTypeOption) =>
                                    claimTypeOption.value !== claimType.CANCELLATION &&
                                    (claimTypeOption.value === 'TRAN' || localBillingItem.ruleType !== 'ABT'),
                                ).map((option) => {
                                  return (
                                    <Select.Option key={option.value} value={option.value}>
                                      {option.label}
                                    </Select.Option>
                                  );
                                })}
                              </Select>,
                            )}
                          </Form.Item>,
                          <div className={`${isEditing ? 'mt-small' : ''}`}>
                            {localBillingItem.claimType
                              ? claimTypeOptions.find((item) => item.value === localBillingItem.claimType).label
                              : '-'}
                          </div>,
                          !isTravelClaim || (isTravelClaim && !localBillingItem.isAutoGenerated),
                        )}
                  </Col>
                  {!hideManagementMethod && (
                    <Col span={4}>
                      {!this.props.isBulkAddLineItem ? (
                        this._renderFormField(
                          <Form.Item className="m-none">
                            {getFieldDecorator('paymentMethod', {
                              initialValue: localBillingItem.paymentMethod,
                              rules: [
                                {
                                  required: true,
                                  message: 'Please select payment method',
                                },
                              ],
                            })(
                              <Select
                                size="large"
                                showSearch
                                style={{ width: '160px' }}
                                onChange={this._onChangePaymentMethod}
                                filterOption={true}
                                optionFilterProp={'children'}
                              >
                                {paymentMethodOptions.map((option) => {
                                  return (
                                    <Select.Option key={option.value} value={option.value}>
                                      {option.label}
                                    </Select.Option>
                                  );
                                })}
                              </Select>,
                            )}
                          </Form.Item>,
                          localBillingItem.paymentMethod
                            ? paymentMethodOptions.find((item) => item.value === localBillingItem.paymentMethod).label
                            : '-',
                        )
                      ) : (
                        <div className={'mt-small'}>-</div>
                      )}
                    </Col>
                  )}
                  <Col span={3}>
                    {this.state.showMileage && (
                      <div className={`${isEditing ? 'mt-small' : ''}`}>
                        {selectedUnitOption ? selectedUnitOption.label : '-'}
                      </div>
                    )}
                    {!this.state.showMileage &&
                      this._renderFormField(
                        <Form.Item className="m-none">
                          {getFieldDecorator('unit', {
                            initialValue: localBillingItem.unit,
                            rules: [
                              {
                                required: true,
                                message: 'Please select unit',
                              },
                            ],
                          })(
                            <Select
                              size="large"
                              style={{ width: '100%' }}
                              onChange={this._onChangeUnit}
                              disabled={isCentreCapitalCostLineItem || isGroupTransport || disableUnitSelection}
                            >
                              {unitOptions.map((option) => {
                                return (
                                  <Select.Option key={option.value} value={option.value}>
                                    {option.label}
                                  </Select.Option>
                                );
                              })}
                            </Select>,
                          )}
                        </Form.Item>,
                        <div className={`${isEditing ? 'mt-small' : ''}`}>
                          {selectedUnitOption ? selectedUnitOption.label : '-'}
                        </div>,
                        !isTravelClaim || !localBillingItem.isAutoGenerated,
                      )}
                  </Col>
                </>
              )}
              <Col span={3}>
                {this.state.showMileage && (
                  <div className={`${isEditing ? 'mt-small' : ''}`}>
                    {localBillingItem.unitPrice ? CommonUtils.formatPrice(localBillingItem.unitPrice) : '-'}
                  </div>
                )}
                {!this.state.showMileage &&
                  this._renderFormField(
                    <>
                      <Form.Item className="m-none">
                        {getFieldDecorator('unitPrice', {
                          initialValue: localBillingItem.unitPrice,
                          rules: [
                            {
                              validator: this._validateUnitPrice,
                            },
                          ],
                        })(
                          <InputNumber
                            disabled={isCentreCapitalCostLineItem || isGroupTransport}
                            size="large"
                            precision={2}
                            onBlur={this._onChangeCalculateTotalPrice}
                            style={{ width: '100%' }}
                          />,
                        )}
                      </Form.Item>
                    </>,
                    <div className={`${isEditing ? 'mt-small' : ''}`}>
                      {localBillingItem.unitPrice ? `${CommonUtils.formatPrice(localBillingItem.unitPrice)}` : '-'}
                    </div>,
                    !isTravelClaim && !localBillingItem.isAutoGenerated,
                  )}
              </Col>
              {showQuantity && (
                <Col span={3}>
                  {this.state.showMileage && quantityDisplay}
                  {!this.state.showMileage &&
                    this._renderFormField(
                      <>
                        <Form.Item className="m-none">
                          {getFieldDecorator('qty', {
                            initialValue: localBillingItem.qty,
                            rules: [
                              {
                                validator: this._validateQty,
                              },
                            ],
                          })(
                            <InputNumber
                              disabled={isCentreCapitalCostLineItem || isGroupTransport}
                              size="large"
                              precision={localBillingItem.claimType === 'NF2F' ? 8 : 2}
                              onBlur={this._onChangeCalculateTotalPrice}
                              style={{ width: '100%' }}
                            />,
                          )}
                        </Form.Item>
                      </>,
                      <div className={`${isEditing ? 'mt-small' : ''}`}>
                        {/* {localBillingItem.qty ? localBillingItem.qty : '-'} */}
                        {quantityDisplay}
                      </div>,
                      !isTravelClaim || !localBillingItem.isAutoGenerated,
                    )}
                </Col>
              )}
            </Row>
            {localBillingItem.supportItem && !hideBottomLineItemName && (
              <Row className="mb-medium">
                <Col span={24}>
                  <Text>{localBillingItem.supportItem}</Text>
                </Col>
              </Row>
            )}
            {isNDISLineItem && localBillingItem.supportItemNumber && (
              <Row>
                <Col span={24}>
                  {!localBillingItem.paymentMethod && !hideWarningFunding && (
                    <Text color="red-dark">
                      <Icon type="close-circle" className="mr-x-small" /> This line item does not have a specified
                      management method.
                      <br />
                    </Text>
                  )}
                  {!localBillingItem.isFunded && !hideWarningFunding && (
                    <Text color="orange">
                      <Icon type="exclamation-circle" className="mr-x-small" /> The customer does not have this line
                      item in their active funding package.
                      <br />
                    </Text>
                  )}
                  {localBillingItem.isNotInServiceAgreement && !hideWarningFunding && (
                    <Text color="orange">
                      <Icon type="exclamation-circle" className="mr-x-small" /> The customer does not have this line
                      item in their active service agreement.
                    </Text>
                  )}
                </Col>
              </Row>
            )}
            {/* travel claim type */}
            {isNDISLineItem && showMileage && (
              <>
                <Row gutter={24} className="bordered-bottom pb-small mt-x-large" type="flex" align="middle">
                  <Col span={5}>
                    <b>Kilometers Travelled</b>
                  </Col>
                  <Col span={5}>
                    <b>Cost per Kilometres</b>
                  </Col>
                  <Col span={5}>
                    <b>Additional Cost</b>
                  </Col>
                </Row>
                <Row gutter={24} className="mt-small" type="flex" align={localBillingItem.isEditing ? 'top' : 'middle'}>
                  <Col span={5}>
                    {this._renderFormField(
                      <Form.Item className="m-none">
                        {getFieldDecorator('travelDistance', {
                          initialValue: localBillingItem.travelDistance,
                          rules: [
                            {
                              validator: this._validateTravelDistance,
                            },
                          ],
                        })(
                          <InputNumber
                            style={{ width: '100%' }}
                            size="large"
                            className="width-full"
                            onBlur={this._onChangeTravelDistance}
                          />,
                        )}
                      </Form.Item>,
                      <div className={`${isEditing ? 'mt-small' : ''}`}>
                        {localBillingItem.travelDistance ? localBillingItem.travelDistance : '-'}
                      </div>,
                      !isTravelClaim || !localBillingItem.isAutoGenerated,
                    )}
                  </Col>
                  <Col span={5}>
                    {' '}
                    {this._renderFormField(
                      <>
                        <Form.Item className="m-none">
                          {getFieldDecorator('mileagePrice', {
                            initialValue: localBillingItem.mileagePrice,
                            rules: [
                              {
                                validator: this._validateTransportPrice,
                              },
                            ],
                          })(
                            <InputNumber
                              style={{ width: '100%' }}
                              size="large"
                              precision={2}
                              onBlur={this._onChangeTravelDistance}
                            />,
                          )}
                        </Form.Item>
                        {/*Only display the information about default price if the claimTravelData exist. (Doesn't exist in Payment)*/}
                        {localBillingItem.ruleType === 'ABT' &&
                          claimTravelData &&
                          Number(localBillingItem.mileagePrice) !==
                            Number(claimTravelData.transportPriceDuringBooking) && (
                            <Text color={'orange'}>
                              Set transport price:{' '}
                              {CommonUtils.formatPrice(claimTravelData.transportPriceDuringBooking)}
                            </Text>
                          )}
                        {localBillingItem.ruleType === 'PTNLC' &&
                          claimTravelData &&
                          Number(localBillingItem.mileagePrice) !==
                            Number(claimTravelData.transportPriceBeforeBooking) && (
                            <Text color={'orange'}>
                              Set transport price:{' '}
                              {CommonUtils.formatPrice(claimTravelData.transportPriceBeforeBooking)}
                            </Text>
                          )}
                      </>,
                      <div className={`${isEditing ? 'mt-small' : ''}`}>
                        {localBillingItem.mileagePrice ? CommonUtils.formatPrice(localBillingItem.mileagePrice) : '-'}
                      </div>,
                      !isTravelClaim || !localBillingItem.isAutoGenerated,
                    )}
                  </Col>
                  <Col span={5}>
                    {this._renderFormField(
                      <Form.Item className="m-none">
                        {getFieldDecorator('additionalCost', {
                          initialValue: localBillingItem.additionalCost,
                          rules: [{ validator: this._validateAdditionalCost }],
                        })(
                          <InputNumber
                            style={{ width: '100%' }}
                            size={'large'}
                            precision={2}
                            onBlur={this._onChangeTravelDistance}
                          />,
                        )}
                      </Form.Item>,
                      <div className={`${isEditing ? 'mt-small' : ''}`}>
                        {localBillingItem.additionalCost
                          ? CommonUtils.formatPrice(localBillingItem.additionalCost)
                          : '-'}
                      </div>,
                      !isTravelClaim || !localBillingItem.isAutoGenerated,
                    )}
                  </Col>
                </Row>
              </>
            )}
            {!hideLineItemCost && (
              <div className="mt-large text-align-right">
                <Text>Line Item cost:</Text>{' '}
                <Text weight="bold" size="x-large">
                  {localBillingItem.paymentStatus === BillingPaymentStatus.WAIVED
                    ? 0.0
                    : localBillingItem.total
                    ? CommonUtils.formatPrice(localBillingItem.total)
                    : '-'}
                </Text>
                {/*{isCancelled && (*/}
                {/*  <>*/}
                {/*    <br />*/}
                {/*    <Text size="regular" color="secondary">*/}
                {/*      Reduced price (90%) due to cancellation :{' '}*/}
                {/*      <b>*/}
                {/*        {localBillingItem.billingTotal ? CommonUtils.formatPrice(localBillingItem.billingTotal) : '-'}*/}
                {/*      </b>*/}
                {/*    </Text>*/}
                {/*  </>*/}
                {/*)}*/}
                {localBillingItem.paymentStatus === BillingPaymentStatus.WAIVED && (
                  <>
                    <br />
                    <Text size="regular" color="secondary">
                      Waived from :{' '}
                      <b>
                        {localBillingItem.billingTotal ? CommonUtils.formatPrice(localBillingItem.billingTotal) : '-'}
                      </b>
                    </Text>
                  </>
                )}
              </div>
            )}
          </div>
        </>
      );
    } else {
      return <></>;
    }
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetVCPItems: dispatch.servicesStore.doGetVCPItems,
});

export default connect(
  null,
  mapDispatch,
)(Form.create<IBillingLineItemProps>()(BillingLineItemV2));
