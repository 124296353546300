import React, { Component } from 'react';
import { Text, Title } from 'common-components/typography';
import { ICustomer } from 'interfaces/customer-interfaces';
import FundGoalDetailPanel from 'views/customers/details/tabs-panel/funding/components/FundGoalDetailPanel';

interface IFundGoalsPanelProps {
  onNextStep: (stepData: any) => void;
  closeCreatePackageModal: () => void;
  onPreviousStep: (stepData: any) => void;
  selectedCustomer: ICustomer;
}

interface IFundGoalsPanelState {
  isLoading: boolean;
}

class FundGoalsPanel extends Component<IFundGoalsPanelProps, IFundGoalsPanelState> {
  state = {
    isLoading: false
  };

  private _onNextStep = (stepData) => {
    this.props.onNextStep(stepData);
  };

  private _goalInfoSection = () => {
    return (
      <FundGoalDetailPanel
        onNextStep={this._onNextStep}
        onPreviousStep={this.props.onPreviousStep}
        closeCreatePackageModal={this.props.closeCreatePackageModal}
      />
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="anim-slide-left">
          {' '}
          <Title level={2} className="text-weight-regular">
            Add client <b>Goals</b>
          </Title>
        </div>
        <div>
          <Text>Please add the Goals relevant to this Package.</Text>
        </div>
        <div className={'mt-small'}>{this._goalInfoSection()}</div>
      </React.Fragment>
    );
  }
}

export default FundGoalsPanel;
