import React, { Component } from 'react';
import { FieldLabel, Paragraph, Text, Title } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Col, notification, Row } from 'antd';
import _ from 'lodash';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import moment from 'moment';
import { Spinner } from '@blueprintjs/core';
import ViewServiceAgreementsLineItemsModal from '../../common-modals/ViewServiceAgreementLineItemsModal';
import CommonUtils from 'utilities/common-utils';
import { ServiceType } from 'utilities/enum-utils';

export interface IAgreementSummaryPanelProps {
  onPrevStep: any;
  onNextStep: any;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  newServiceAgreement: typeof state.customersStore.newServiceAgreement;
  doCreateServiceAgreement: typeof dispatch.customersStore.doCreateServiceAgreement;
}

export interface IAgreementSummaryPanelState {
  isLoading: boolean;
  isViewModalOpen: boolean;
  selectedService: null;
}

function AgreementServiceItem({ service, openViewModal }) {
  return (
    <div className="pv-medium evenodd">
      <Row gutter={16}>
        <Col span={6}>
          <div className="ph-medium">{service.serviceName}</div>
        </Col>
        <Col span={6}>
          <div className="ph-medium">
            {service.lineItems && service.lineItems.length > 0 ? (
              <HyperlinkButton onClick={() => openViewModal(service)}>
                {service.lineItems.length} line item
                {service.lineItems.length !== 1 ? 's' : ''} selected
              </HyperlinkButton>
            ) : (
              '0 line item selected'
            )}
          </div>
        </Col>
        <Col span={12}>
          <div className="ph-medium" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {service.comment}
          </div>
        </Col>
      </Row>
    </div>
  );
}

function AwaitingCreateAgreementPanel() {
  return (
    <div className="anim-slide-left">
      <Title level={2} weight="bolder" className="line-height-100 mb-x-large">
        <span className="text-weight-regular">Creating a new </span> Service Agreement
      </Title>
      <div className="flex-row align-center mb-x-large">
        <div className="mr-x-large">
          <Spinner size={120} />
        </div>
        <div>
          <Paragraph>Finalizing content...</Paragraph>

          <Paragraph>This won't take long.</Paragraph>
        </div>
      </div>
    </div>
  );
}

class AgreementSummaryPanel extends Component<IAgreementSummaryPanelProps, IAgreementSummaryPanelState> {
  state = {
    isLoading: false,
    isViewModalOpen: false,
    selectedService: null
  };

  private _openViewModal = (service) => {
    this.setState({ isViewModalOpen: true, selectedService: service });
  };

  private _onCloseViewModal = () => {
    this.setState({ isViewModalOpen: false, selectedService: null });
  };

  private _createNewServiceAgreement = async () => {
    const { doCreateServiceAgreement, newServiceAgreement, selectedCustomer, onNextStep } = this.props;
    try {
      const { services, ...newServiceAgreementDetails } = newServiceAgreement;
      const newServices = _.map(services, (service) => {
        const { serviceId, comment, lineItems, additionalCharges } = service;
        const updatedLineItems = _.map(lineItems, (item) => {
          const { supportItemNumber, supportCategoryNumber, priority, groupCode } = item;
          return {
            supportItemNumber,
            supportCategoryNumber,
            priority,
            groupCode
          };
        });
        if (service.serviceType === ServiceType.GROUP) {
          return {
            serviceId,
            comment,
            teamMemberCustomerRatio: service.teamMemberCustomerRatio && service.teamMemberCustomerRatio.ndis,
            serviceDateTimeIds: service.serviceDateTimeIds,
            lineItems: updatedLineItems,
            additionalCharges
          };
        } else {
          return {
            serviceId,
            comment,
            lineItems: updatedLineItems,
            additionalCharges
          };
        }
      });
      const manualQuotations = _.map(
        _.filter(newServiceAgreement.quotations, (quote) => quote.isManualQuote),
        (quotation) => {
          return {
            serviceId: quotation.serviceId,
            isManualTotal: quotation.isManualTotal,
            manualQuotationDetail: quotation.isManualTotal
              ? [
                  {
                    quoteAmount: quotation.quoteAmount,
                    spentAmount: quotation.spentAmount,
                    supportCategoryNumber: null
                  }
                ]
              : quotation.subQuotes
          };
        }
      );
      this.setState({ isLoading: true });

      await doCreateServiceAgreement({
        ...newServiceAgreementDetails,
        quotations: _.filter(newServiceAgreement.quotations, (quote) => !quote.isManualQuote),
        manualQuotations,
        customerUserId: selectedCustomer.userId,
        services: newServices
      });

      onNextStep();
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
  };

  render() {
    const { onPrevStep, newServiceAgreement } = this.props;
    const quotations = [
      {
        serviceId: '706fe9aa-a586-4efc-acc6-b939a5d62b51',
        serviceName: 'Your Support - Performance Test',
        quoteAmount: 270000,
        additionalCost: '0.00',
        isManualQuote: true,
        isManualTotal: true,
        spentAmount: 11000,
        timeSlots: [],
        subQuotes: []
      }
    ];
    return (
      <>
        <ViewServiceAgreementsLineItemsModal
          selectedServiceLineItems={this.state.selectedService ? this.state.selectedService.lineItems : null}
          additionalChargeItems={this.state.selectedService ? this.state.selectedService.additionalCharges : null}
          isOpen={this.state.isViewModalOpen}
          onClose={this._onCloseViewModal}
          displayMode={'VIEW'}
          paymentSourceType={newServiceAgreement.paymentSourceType}
        />
        {this.state.isLoading ? (
          <AwaitingCreateAgreementPanel />
        ) : (
          <div className="anim-slide-left">
            <Title level={2}>
              Create <b>Service Agreement</b>
            </Title>
            <Text>
              Please review the details for this <b>service agreement</b>.
            </Text>

            <div className="mt-large mb-x2-large">
              {/*  Content */}
              {/*  -=== Start Date ===- */}
              <div className="flex-row mb-large">
                <div className="mr-large">
                  <FieldLabel text={'START DATE'} />
                  <Text>{moment(newServiceAgreement.startDate).format('DD MMMM YYYY')}</Text>
                </div>

                <div>
                  <FieldLabel text={'END DATE'} />
                  <Text>{moment(newServiceAgreement.endDate).format('DD MMMM YYYY')}</Text>
                </div>
              </div>

              {/*  -=== Terms & Conditions ===- */}
              <div className="mb-large">
                <FieldLabel text={'TERMS & CONDITIONS'} />
                {newServiceAgreement.termsConditions ? (
                  <div
                    className="bordered p-medium mt-small"
                    style={{ maxHeight: '200px', overflowY: 'auto' }}
                    dangerouslySetInnerHTML={{
                      __html: newServiceAgreement.termsConditions ? newServiceAgreement.termsConditions : ''
                    }}
                  />
                ) : (
                  <div>
                    <Text color={'secondary'}>No Terms and Conditions set.</Text>
                  </div>
                )}
              </div>

              {/*  -=== Schedule of Supports ===- */}
              <div className="">
                <FieldLabel text={'SCHEDULE OF SUPPORT(S)'} />

                <div className="p-small bordered mt-small">
                  {/* Header */}
                  <div className="bordered-bottom pb-medium">
                    <Row gutter={8}>
                      <Col span={6}>
                        <div className="ph-medium">
                          <FieldLabel text={'SERVICE NAME'} />
                        </div>
                      </Col>
                      <Col span={6}>
                        <div className="ph-medium">
                          <FieldLabel text={'LINE ITEM(S)'} />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="ph-medium">
                          <FieldLabel text={'COMMENTS'} />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* Items */}
                  <div>
                    {/* Sample Item */}
                    {_.map(newServiceAgreement.services, (service, idx) => (
                      <AgreementServiceItem service={service} openViewModal={this._openViewModal} key={idx} />
                    ))}
                  </div>
                </div>
              </div>

              {/*  -=== Schedule of Supports ===- */}
              {newServiceAgreement && newServiceAgreement.quotations && newServiceAgreement.quotations.length > 0 && (
                <div className="mt-large">
                  <FieldLabel text={'QUOTE'} />

                  <div className="p-small bordered mt-small">
                    {/* Header */}
                    <div className="bordered-bottom pb-medium">
                      <Row gutter={8}>
                        <Col span={10}>
                          <div className="ph-medium">
                            <FieldLabel text={'SERVICE NAME'} />
                          </div>
                        </Col>
                        <Col span={4} className={'text-align-right'}>
                          <div className="ph-medium">
                            <FieldLabel text={'Quote amount'} />
                          </div>
                        </Col>
                        <Col span={10} />
                      </Row>
                    </div>

                    {/* Items */}
                    <div>
                      {/* Sample Item */}
                      {_.map(newServiceAgreement.quotations, (quotation, idx) => {
                        return (
                          <Row key={idx} className={'pv-medium evenodd'}>
                            <Col span={10} className={'ph-medium'}>
                              {quotation.serviceName}
                            </Col>
                            <Col span={4} className={'text-align-right ph-medium'}>
                              {CommonUtils.formatPrice(quotation.quoteAmount)}
                            </Col>
                            <Col span={10} />
                          </Row>
                        );
                      })}
                      <Row key={'TOTAL'} className={'pv-medium bordered-top '}>
                        <Col span={10} className={'ph-medium'}>
                          <Text weight={'bold'}>Total</Text>
                        </Col>
                        <Col span={4} className={'text-align-right ph-medium'}>
                          {newServiceAgreement.quotations ? (
                            <Text weight={'bold'}>
                              {CommonUtils.formatPrice(
                                _.sumBy(newServiceAgreement.quotations, (item: any) => Number(item.quoteAmount))
                              )}
                            </Text>
                          ) : (
                            0
                          )}
                        </Col>
                        <Col span={10} />
                      </Row>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="mb-x2-large">
              <SecondaryButton
                size="large"
                onClick={onPrevStep}
                className="mr-medium"
                icon="left"
                iconPosition={'left'}
              >
                Previous
              </SecondaryButton>

              <PrimaryButton size="large" onClick={this._createNewServiceAgreement}>
                Finish
              </PrimaryButton>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedCustomer: state.customersStore.selectedCustomer,
  newServiceAgreement: state.customersStore.newServiceAgreement
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateServiceAgreement: dispatch.customersStore.doCreateServiceAgreement
});

export default connect(
  mapState,
  mapDispatch
)(AgreementSummaryPanel);
