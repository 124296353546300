import React, { Component } from 'react';
import { Col, Form, Input, Radio, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, SubTitle, FieldLabel, Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import _ from 'lodash';

import { IAddCustomerDetailWizard } from 'src/interfaces/customer-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Title from 'antd/lib/typography/Title';
import CommonUtils from 'utilities/common-utils';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

interface IAddCustomerStepCustomerDetailProps extends FormComponentProps {
  portalUser: typeof state.authStore.portalUser;
  onNextStep: (stepData?: any) => void;
  setAddCustomerDetailWizard: typeof dispatch.customersStore.setAddCustomerDetailWizard;
  addCustomerDetailWizard: typeof state.customersStore.addCustomerDetailWizard; // rename this
}

interface IAddCustomerStepCustomerDetailState {
  customerDetails: IAddCustomerDetailWizard;
}

class AddCustomerStepCustomerDetail extends Component<
  IAddCustomerStepCustomerDetailProps,
  IAddCustomerStepCustomerDetailState
> {
  state = {
    customerDetails: !_.isEmpty(this.props.addCustomerDetailWizard)
      ? this.props.addCustomerDetailWizard
      : {
          firstName: null,
          lastName: null,
          hasGuardians: false,
          isEnquiry: false,
          customerSource: null,
          guardians: [],
          isInviteToApp: false,
          email: null,
          isIndependent: true
        }
  };

  private _validateBeforeNextStep = async () => {
    const { form, setAddCustomerDetailWizard, onNextStep } = this.props;

    let isFormValid = true;

    form.validateFields(async (err, value) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      await setAddCustomerDetailWizard({ ...this.state.customerDetails, mobileCountryCode: 'AU' });
      onNextStep();
    }
  };

  private _updateCustomerDetail = (fieldName, value) => {
    const { customerDetails } = this.state;
    this.setState({ customerDetails: { ...customerDetails, [fieldName]: _.trim(value) } });
  };

  private _changeIsEnquiry = (value) => {
    const { customerDetails } = this.state;

    const newCustomerDetails = { ...customerDetails, isEnquiry: value };

    this.setState({ customerDetails: newCustomerDetails });
  };

  private _validateMobileNumber = (rule, value, callback) => {
    const region = 'AU';

    try {
      if (!_.isEmpty(value)) {
        let phoneNumber = parsePhoneNumberFromString(value, region);
        if (!phoneNumber || !phoneNumber.isValid()) {
          throw new Error('Invalid Mobile Number');
        }
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  render() {
    const { form } = this.props;
    const { customerDetails } = this.state;
    const { getFieldDecorator } = form;

    return (
      <>
        <div className="anim-slide-left pt-x3-large">
          <Row className="ph-x3-large">
            <Col span={6} style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div>
                <Paragraph size="x3-large" weight="bold">
                  We’ll guide you through adding a customer to your
                </Paragraph>
                <Paragraph className="mb-small">
                  First we need some basic information about this customer including their name and their NDIS number if
                  you have this on hand.
                </Paragraph>
                <Paragraph>
                  If you would like to add this customer as an enquiry please indicate that this is the case
                </Paragraph>
                <Paragraph>
                  Visit the{' '}
                  <HyperlinkButton onClick={() => CommonUtils.sendToZendesk(this.props.portalUser)}>
                    Help Center
                  </HyperlinkButton>{' '}
                  to learn more.
                </Paragraph>
              </div>
            </Col>
            <Col span={18} className="pl-x-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
                <Row>
                  <Col>
                    <Title className="mb-large" level={4}>
                      Customer Information
                    </Title>
                    <Paragraph className="mb-medium">
                      We just need the basics to begin with. Additional information can be added once this record is
                      created.
                    </Paragraph>
                  </Col>
                </Row>
                <div className="mb-large flex-row flex-wrap">
                  <div style={{ maxWidth: '300px' }} className="mr-large">
                    <SubTitle textClassName="mb-x-small">First Name</SubTitle>
                    <Form.Item className={'m-none'}>
                      {getFieldDecorator('firstName', {
                        initialValue: customerDetails.firstName,
                        rules: [
                          {
                            required: true,
                            message: 'Please enter a First Name'
                          }
                        ]
                      })(
                        <Input
                          style={{ width: '250px' }}
                          onChange={(value) => this._updateCustomerDetail('firstName', value.target.value)}
                          type="text"
                          size="large"
                          placeholder="Input name..."
                        />
                      )}
                    </Form.Item>
                  </div>
                  <div>
                    <SubTitle textClassName="mb-x-small">Last Name</SubTitle>
                    <Form.Item className={'m-none'}>
                      {getFieldDecorator('lastName', {
                        initialValue: customerDetails.lastName,
                        rules: [
                          {
                            required: true,
                            message: 'Please enter a Last Name'
                          }
                        ]
                      })(
                        <Input
                          style={{ width: '250px' }}
                          onChange={(value) => this._updateCustomerDetail('lastName', value.target.value)}
                          type="text"
                          size="large"
                          placeholder="Input name..."
                        />
                      )}
                    </Form.Item>
                  </div>
                </div>
                <div className="mb-large flex-row flex-wrap">
                  <div style={{ maxWidth: '300px' }} className="mr-large">
                    <div className="flex-row align-center">
                      <SubTitle>Mobile Number</SubTitle>
                      <Text className="ml-x-small" size="regular">
                        (Optional)
                      </Text>
                    </div>
                    <Form.Item className={'m-none'}>
                      {getFieldDecorator('mobile', {
                        initialValue: customerDetails.mobile,
                        rules: [
                          {
                            validator: this._validateMobileNumber
                          }
                        ]
                      })(
                        <Input
                          style={{ width: '250px' }}
                          // type="number"
                          size="large"
                          addonBefore="+61(AU)"
                          onChange={(value) => this._updateCustomerDetail('mobile', value.target.value)}
                          placeholder="Mobile number"
                        />
                      )}
                    </Form.Item>
                  </div>
                  <div>
                    <div className="flex-row align-center">
                      <SubTitle>Email Address</SubTitle>
                      <Text className="ml-x-small" size="regular">
                        (Optional)
                      </Text>
                    </div>
                    <Form.Item className={'m-none'}>
                      {getFieldDecorator('email', {
                        initialValue: customerDetails.email,
                        rules: [
                          {
                            type: 'email',
                            message: 'Please enter valid Email'
                          }
                        ]
                      })(
                        <Input
                          size="large"
                          style={{ width: '250px' }}
                          onChange={(value) => this._updateCustomerDetail('email', value.target.value)}
                          placeholder="Enter Email"
                        />
                      )}
                    </Form.Item>
                  </div>
                </div>
                <div className="mb-x-large">
                  <Col>
                    <div className="flex-row align-center">
                      <SubTitle>NDIS Number</SubTitle>
                      <Text className="ml-x-small" size="regular">
                        (Optional)
                      </Text>
                    </div>
                    <Form.Item className={'m-none'}>
                      {getFieldDecorator('ndisNumber', {
                        initialValue: customerDetails.ndisNumber
                      })(
                        <Input
                          style={{ width: '250px' }}
                          onChange={(value) => this._updateCustomerDetail('ndisNumber', value.target.value)}
                          type="text"
                          size="large"
                        />
                      )}
                    </Form.Item>
                  </Col>
                </div>
                <div className="mb-large">
                  <Col>
                    <Title level={4} className="mb-large">
                      Is this Customer an Enquiry?
                    </Title>
                    <Form.Item className={'m-none'}>
                      {getFieldDecorator('isEnquiry', {
                        initialValue: customerDetails.isEnquiry,
                        rules: [
                          {
                            required: true,
                            message: 'Please select an option.'
                          }
                        ]
                      })(
                        <Radio.Group
                          defaultValue={false}
                          onChange={(value) => this._changeIsEnquiry(value.target.value)}
                        >
                          <Radio className="mb-medium" value={false}>
                            No
                          </Radio>
                          <br />
                          <Radio value={true}>Yes</Radio>
                        </Radio.Group>
                      )}
                    </Form.Item>
                    {form.getFieldValue('isEnquiry') === true && (
                      <Row>
                        <Col>
                          <FieldLabel text={'SOURCE (optional)'} />
                          {getFieldDecorator('customerSource', {
                            initialValue: customerDetails.customerSource
                          })(
                            <Input
                              style={{ width: '250px' }}
                              size="large"
                              onChange={(value) => this._updateCustomerDetail('customerSource', value.target.value)}
                            />
                          )}
                        </Col>
                      </Row>
                    )}
                  </Col>
                </div>
              </div>
              <div className="pv-large width-full" style={{ position: 'sticky', bottom: 0 }}>
                <Row gutter={0} type="flex" align="middle" className="bg-transparent">
                  <Col className="bg-transparent" span={24}>
                    <div className="text-align-right pv-medium">
                      <PrimaryButton size="large" onClick={this._validateBeforeNextStep}>
                        Next
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  addCustomerDetailWizard: state.customersStore.addCustomerDetailWizard
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setAddCustomerDetailWizard: dispatch.customersStore.setAddCustomerDetailWizard
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IAddCustomerStepCustomerDetailProps>()(AddCustomerStepCustomerDetail));
