import React, { Component } from 'react';
import { Col, Icon, notification, Row } from 'antd';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { Paragraph } from 'common-components/typography';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import ActionModal from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';

interface IUnArchiveDocumentModalProps {
  closeUnArchiveDocumentModal: () => void;
  isOpen: boolean;
  selectedDocument: any;
  selectedItemId: string;
  itemType: string;
  doUnArchiveCustomerDocument: typeof dispatch.customersStore.doUnArchiveCustomerDocument;
  resetDocumentList: () => void;
  useAttachmentText: boolean;
}

class UnArchiveDocumentModal extends Component<IUnArchiveDocumentModalProps> {
  state = {
    isLoading: false,
    title: `Un-archive ${this.props.useAttachmentText ? 'attachment' : 'document'}`,
    step: 1
  };

  private _archiveDocument = async () => {
    const { selectedDocument, selectedItemId, useAttachmentText } = this.props;
    try {
      await this.props.doUnArchiveCustomerDocument({
        userId: selectedItemId,
        documentId: selectedDocument.documentId
      });
      this.props.resetDocumentList();
      this.setState({
        title: (
          <>
            {useAttachmentText ? 'Attachment' : 'Document'} un-archived
            <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
          </>
        ),
        step: 2
      });
    } catch (e) {
      notification.error({ message: 'Oops, an error has occurred, please try again.' });
    }
  };

  private _closeModal = () => {
    this.setState({ title: 'Un-archive document', step: 1 });
    this.props.closeUnArchiveDocumentModal();
  };

  render() {
    const { useAttachmentText } = this.props;
    const { step } = this.state;
    return (
      <div>
        <ActionModal
          title={this.state.title}
          isOpen={this.props.isOpen}
          onClose={this.props.closeUnArchiveDocumentModal}
          width="medium"
        >
          {step === 1 && (
            <div className="anim-slide-left">
              <SpinningLoadingActionModel isOpen={this.state.isLoading} verticalAlignment={'highest'} />
              {this.props.itemType === 'customer' ? (
                <>
                  <Paragraph>
                    Are you sure you want to un-archive this {useAttachmentText ? 'attachment' : 'document'}?
                  </Paragraph>
                </>
              ) : (
                <Paragraph>
                  Are you sure you want to archive this {useAttachmentText ? 'attachment' : 'document'}? This action is
                  permanent and cannot be undone. The
                  {useAttachmentText ? 'attachment' : 'document'} can still be accessed from this users profile.
                </Paragraph>
              )}

              <div className={'mb-small'}>
                <Row type={'flex'} className={'justify-end'}>
                  <Col className="mr-large">
                    <GhostButton size="large" onClick={this.props.closeUnArchiveDocumentModal}>
                      Cancel
                    </GhostButton>
                  </Col>
                  <Col>
                    <PrimaryButton size="large" color="red" onClick={this._archiveDocument}>
                      Un-archive
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="anim-slide-left">
              <Paragraph>
                You have successfully un-archived this {useAttachmentText ? 'attachment' : 'document'}.
              </Paragraph>
              <div className={'mb-small'}>
                <Row type={'flex'} className={'justify-end'}>
                  <Col>
                    <PrimaryButton size="large" onClick={this._closeModal}>
                      Close
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUnArchiveCustomerDocument: dispatch.customersStore.doUnArchiveCustomerDocument
});

export default connect(
  null,
  mapDispatch
)(UnArchiveDocumentModal);
