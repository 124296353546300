import React, { Component } from 'react';
import { Avatar, Col, notification, Row } from 'antd';
import { connect } from 'react-redux';
import { Paragraph, SubTitle, Title, Text } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import GroupBookingSessionsModal from 'views/group-services/service-details/customer-schedule/components/GroupBookingSessionsModal';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Spinner } from '@blueprintjs/core';

interface IConnectionStepPanelProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  customerToSchedule: typeof state.servicesStore.customerToSchedule;
  doAddCustomerToSession: typeof dispatch.servicesStore.doAddCustomerToSession;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  setIsSuccessAddedCustomerToSession: () => void;
}

interface IConnectionStepPanelState {
  isLoading: boolean;
  isPreviewSessionsOpen: boolean;
}

/* Spinner while adding to session process */
class AddingCustomerToSessionView extends Component<{}> {
  render() {
    return (
      <Row className="ph-x4-large">
        <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
          <div className="width-3/4">
            <Title level={4}>Review</Title>
            <Paragraph>Review the details before you add the customer to the selected sessions.</Paragraph>
          </div>
        </Col>
        <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
          <div className="anim-slide-left">
            <div className="mr-x-large text-align-center">
              <Spinner size={80} />
              <Text size={'x2-large'}>Add customer to sessions</Text>
              <Paragraph>This won't take long.</Paragraph>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

class SummaryStepPanel extends Component<IConnectionStepPanelProps, IConnectionStepPanelState> {
  state = {
    isLoading: false,
    isPreviewSessionsOpen: false
  };

  private _closePreviewSession = () => {
    this.setState({ isPreviewSessionsOpen: false });
  };

  private _openPreviewSession = () => {
    this.setState({ isPreviewSessionsOpen: true });
  };

  private _createSession = async () => {
    const { customerToSchedule, doAddCustomerToSession, selectedGroupService } = this.props;

    try {
      this.setState({ isLoading: true });
      await doAddCustomerToSession({
        serviceId: selectedGroupService.serviceId,
        customerUserId: customerToSchedule.selectedCustomer.userId,
        sessions: _.map(customerToSchedule.sessions, (session) => {
          let correspondingRatio: any = _.find(
            customerToSchedule.ratios,
            (ratio) =>
              moment(session.startDateTime).isSameOrAfter(moment(ratio.startDateTime)) &&
              moment(session.endDateTime).isSameOrBefore(moment(ratio.endDateTime))
          );
          correspondingRatio = {
            isCustomRatio: correspondingRatio.isCustomRatio,
            customRatio: _.map(correspondingRatio.customRatio, (customTime) => {
              return {
                ...customTime,
                startDateTime: correspondingRatio.isCustomRatio
                  ? moment.tz(
                      moment(session.startDateTime)
                        .set({
                          hours: moment(customTime.startDateTime).hour(),
                          minutes: moment(customTime.startDateTime).minutes()
                        })
                        .format('YYYY-MM-DD HH:mm'),
                      selectedGroupService.timezone
                    )
                  : moment.tz(moment(session.startDateTime).format('YYYY-MM-DD HH:mm'), selectedGroupService.timezone),
                endDateTime: correspondingRatio.isCustomRatio
                  ? moment.tz(
                      moment(session.endDateTime)
                        .set({
                          hours: moment(customTime.endDateTime).hour(),
                          minutes: moment(customTime.endDateTime).minutes()
                        })
                        .format('YYYY-MM-DD HH:mm'),
                      selectedGroupService.timezone
                    )
                  : moment.tz(moment(session.endDateTime).format('YYYY-MM-DD HH:mm'), selectedGroupService.timezone)
              };
            })
          };
          return { serviceDateTimeId: session.serviceDateTimeId, ...correspondingRatio };
        })
      });
      this.props.onNextStep();
      this.props.setIsSuccessAddedCustomerToSession();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong! Please try again.' });
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { customerToSchedule } = this.props;
    const { isLoading, isPreviewSessionsOpen } = this.state;

    if (this.state.isLoading) {
      return <AddingCustomerToSessionView />;
    }

    return (
      <div className="anim-slide-left">
        <GroupBookingSessionsModal
          isOpen={isPreviewSessionsOpen}
          onCloseModal={this._closePreviewSession}
          sessions={customerToSchedule.sessions}
        />
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>Review</Title>
              <Paragraph>Review the details before you add the customer to the selected sessions.</Paragraph>
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
              <Title level={4}>Add customer to sessions summary</Title>
              <Paragraph>You'll be adding the following customer to the following sessions:</Paragraph>

              <div className={'mt-large'}>
                <SubTitle>Customer</SubTitle>
                {customerToSchedule && customerToSchedule.selectedCustomer ? (
                  <>
                    <Avatar
                      className={'mr-small'}
                      shape={'circle'}
                      icon={'user'}
                      src={customerToSchedule.selectedCustomer.attachmentUrl}
                    />
                    {customerToSchedule.selectedCustomer.firstName} {customerToSchedule.selectedCustomer.lastName}
                  </>
                ) : (
                  <Text color={'secondary'}>No customer selected</Text>
                )}
              </div>

              <div className={'mt-large'}>
                <SubTitle>Sessions</SubTitle>
                {customerToSchedule && customerToSchedule.sessions && customerToSchedule.sessions.length} session
                {customerToSchedule && customerToSchedule.sessions && customerToSchedule.sessions.length !== 1 && 's'}
                <br />
                <HyperlinkButton onClick={this._openPreviewSession}>View sessions...</HyperlinkButton>
              </div>
            </div>
            <div
              className="pv-medium width-full"
              style={{ backgroundColor: 'rgba(248,249,250,0.7)', position: 'sticky', bottom: 0 }}
            >
              <Row gutter={0} type="flex" align="middle" justify={'space-between'} className="bg-transparent">
                <div className="text-align-right pv-medium">
                  <SecondaryButton size="large" disabled={isLoading} onClick={this.props.onPreviousStep}>
                    Back
                  </SecondaryButton>
                </div>
                <div className="text-align-right pv-medium">
                  <PrimaryButton size="large" loading={isLoading} onClick={this._createSession}>
                    Create session
                  </PrimaryButton>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  customerToSchedule: state.servicesStore.customerToSchedule,
  selectedGroupService: state.groupServiceStore.selectedGroupService
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddCustomerToSession: dispatch.servicesStore.doAddCustomerToSession
});

export default connect(
  mapState,
  mapDispatch
)(SummaryStepPanel);
