import { Avatar, Checkbox, Col, DatePicker, Icon, notification, Radio, Row, Select } from 'antd';
import { Tooltip2 } from '@blueprintjs/popover2';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons/index';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component, ReactNode } from 'react';
import Script from 'react-load-script';
import { connect } from 'react-redux';
import AddAddressModal from '../../../../../common-components/addresses/AddAddressModal';
import NumberInput from '../../../../../common-components/inputs/NumberInput';
import { LocationDisplay } from '../../../../../common-components/locations/index';
import TimeInput from '../../../../../common-components/time-input/TimeInput';
import { FieldLabel, Paragraph, Text, Title } from '../../../../../common-components/typography/index';
import { INewActivityRecordData } from '../../../../../interfaces/booking-interfaces';
import { IServiceListItemLite } from '../../../../../interfaces/service-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../../stores/rematch/root-store';
import CommonUtils from '../../../../../utilities/common-utils';
import { BookingType } from '../../../../../utilities/enum-utils';
import globalConfig from '../../../../../variables/global-config';
import BookingAssignWorkerModal from '../../../components/BookingAssignWorkerModal';

const { Option } = Select;

interface IActivityRecordStep2SelectDetailsProps {
  newActivityRecordData: INewActivityRecordData;
  setNewActivityRecordData: typeof dispatch.bookingsStore.setNewActivityRecordData;
  servicesLite: IServiceListItemLite[];
  doFetchServicesLite: typeof dispatch.servicesStore.doFetchServicesLite;
  doFetchSingleService: typeof dispatch.servicesStore.doFetchSingleService;
  setSelectedCreateService: typeof dispatch.servicesStore.setSelectedCreateService;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  bookSelectedService: typeof state.servicesStore.bookSelectedService;
  history: any;
  onNextStep: () => void;
  onPreviousStep: () => void;
}

interface IActivityRecordStep2SelectDetailsState {
  innerStep: number;
  isLoading: boolean;
  userAddressId: string;
  openCreateAddress: boolean;
  openSelectWorker: boolean;
  timeSelectOption: string;
  hours: number;
  minutes: number;
  startDate: Date;
  startDateTime: Date;
  finishDateTime: Date;
  addresses: any[];
  checkSendToTimesheet: boolean;
}

interface IAddressItemProps {
  isSelected: string;
  description: string;
  bordered?: boolean;
  address: string | ReactNode;
  isCRM: boolean;
  disabledAddress: boolean;
  userAddressId: string;
  addPadding: boolean;
  handleAddressId: (userAddressId) => void;
}

class AddressItem extends Component<IAddressItemProps> {
  render() {
    const {
      isSelected,
      description,
      address,
      disabledAddress,
      bordered,
      isCRM,
      userAddressId,
      handleAddressId,
      addPadding,
    } = this.props;

    return (
      <Col
        span={6}
        onClick={() => (!disabledAddress ? handleAddressId(userAddressId) : false)}
        className={`${addPadding && 'pl-small'}`}
      >
        <Row
          className={`${bordered && 'bordered'} ${
            userAddressId === isSelected ? 'bg-blue-action-lightest' : disabledAddress ? 'bg-quaternary' : 'bg-white'
          } p-medium rounded cursor-pointer ${isSelected ? '' : 'hover-bg-tertiary'} mb-small`}
          style={{ minHeight: '104px' }}
          type={'flex'}
        >
          <Col span={3}>
            <Radio value={userAddressId} disabled={disabledAddress} />
          </Col>
          <Col span={21}>
            <b>{description}</b>
            <br />
            {address}
            {disabledAddress && (
              <Text color={'secondary'} size={'regular'}>
                <br />
                <Icon type={'info-circle'} className="mr-x-small" />
                This address is empty. {isCRM && 'You can update this in the profile.'}
              </Text>
            )}
          </Col>
        </Row>
      </Col>
    );
  }
}

class ActivityRecordStep2SelectDetails extends Component<
  IActivityRecordStep2SelectDetailsProps,
  IActivityRecordStep2SelectDetailsState
> {
  state = {
    innerStep: 1,
    isLoading: false,
    userAddressId: '',
    openCreateAddress: false,
    openSelectWorker: false,
    timeSelectOption: 'duration',
    hours: 1,
    minutes: 0,
    startDate: null,
    startDateTime: null,
    finishDateTime: null,
    addresses: [],
    checkSendToTimesheet: false,
  };

  private _setSelectedService = async (serviceId) => {
    const {
      servicesLite,
      newActivityRecordData,
      setNewActivityRecordData,
      doFetchSingleService,
      setSelectedCreateService,
    } = this.props;
    setSelectedCreateService(null);
    const selectedService = _.find(servicesLite, (s) => s.serviceId === serviceId);

    await setNewActivityRecordData({
      ...newActivityRecordData,
      selectedServiceId: serviceId,
      selectedService,
      selectedWorkerId: null,
    });

    await doFetchSingleService({ serviceId });

    const addresses = this._onUpdateAddresses();

    this.setState({ addresses });
  };

  private _handleAddressId = (userAddressId) => {
    this.setState({ userAddressId });
    const { newActivityRecordData, setNewActivityRecordData } = this.props;
    const { addresses } = this.state;

    const foundInUserAddresses = _.find(addresses, (address) => address.userAddressId === userAddressId);
    const selectedAddress = foundInUserAddresses ? foundInUserAddresses : newActivityRecordData.manuallyAddedAddress;

    setNewActivityRecordData({
      ...newActivityRecordData,
      bookLocation: selectedAddress,
    });
  };

  private _closeCreateAddressModal = () => {
    this.setState({ openCreateAddress: false });
  };

  private _openCreateAddressModal = () => {
    this.setState({ openCreateAddress: true });
  };

  private _saveBookingAddress = (location) => {
    const { newActivityRecordData, setNewActivityRecordData } = this.props;

    const manuallyAddedId = 'manuallySelectedAddress';
    const newBookLocation = {
      ...location,
      userAddressId: manuallyAddedId,
      isManual: true,
    };
    setNewActivityRecordData({
      ...newActivityRecordData,
      bookLocation: location,
      manuallyAddedAddress: newBookLocation,
    });
    this.setState({ userAddressId: manuallyAddedId });
  };

  private _openSelectWorkerModal = () => this.setState({ openSelectWorker: true });

  private _closeSelectWorkerModal = () => this.setState({ openSelectWorker: false });

  private _onSelectSupportWorker = async (
    selectedWorker,
    editRecurringMode,
    shiftSlotStatus,
    isRemovePendingShiftSlots,
    conflicts = null,
  ) => {
    const { newActivityRecordData, setNewActivityRecordData } = this.props;
    await setNewActivityRecordData({
      ...newActivityRecordData,
      selectedWorkerId: selectedWorker.supportWorkerId,
      selectedWorker,
      shiftSlotStatus,
      isRemovePendingShiftSlots,
      conflictWorkerBookings: conflicts,
    });
    return 4;
  };

  private _onNextInnerStep = () => {
    this.setState({ innerStep: 2 });
  };

  private _onPreviousInnerStep = () => {
    this.setState({ innerStep: 1 });
  };

  private _setStartDate = (date) => {
    const roundedDate = CommonUtils.formatCeilingDateTime(date);
    const { timeSelectOption } = this.state;
    const startDate = moment(roundedDate)
      .set('hours', 9)
      .set('minutes', 0)
      .set('seconds', 0)
      .set('ms', 0);
    const endDate =
      timeSelectOption === 'duration'
        ? moment(roundedDate)
            .set('hours', 10)
            .set('minutes', 0)
            .set('seconds', 0)
            .set('ms', 0)
        : moment(roundedDate)
            .set('hours', 17)
            .set('minutes', 0)
            .set('seconds', 0)
            .set('ms', 0);

    this.setState({
      startDate: startDate.toDate(),
      startDateTime: startDate.toDate(),
      finishDateTime: endDate.toDate(),
      hours: 1,
      minutes: 0,
    });
  };

  private _setStartTime = (date) => {
    const roundedDate = CommonUtils.formatCeilingDateTime(date);

    const startDate = roundedDate.set('seconds', 0).set('ms', 0);

    if (moment(this.state.finishDateTime).isBefore(startDate)) {
      this.setState({
        startDateTime: startDate.toDate(),
        finishDateTime: moment(startDate)
          .add(1, 'hours')
          .toDate(),
      });
    } else {
      this.setState({ startDateTime: startDate.toDate() });
    }
  };

  private _setEndTime = (date) => {
    const roundedDate = CommonUtils.formatCeilingDateTime(date);

    const endDate = roundedDate.set('seconds', 0).set('ms', 0);

    if (moment(this.state.startDateTime).isAfter(endDate)) {
      this.setState({
        startDateTime: moment(endDate)
          .add(-1, 'hours')
          .toDate(),
        finishDateTime: endDate.toDate(),
      });
    } else {
      this.setState({ finishDateTime: endDate.toDate() });
    }
  };

  private _onChangeHours = (value) => {
    const { minutes, startDate } = this.state;

    const endDate = moment(startDate)
      .add(value, 'hours')
      .add(minutes, 'minutes')
      .toDate();

    this.setState({ hours: value, finishDateTime: endDate });
  };

  private _onChangeMinutes = (value) => {
    const { hours, startDate } = this.state;

    const endDate = moment(startDate)
      .add(hours, 'hours')
      .add(value, 'minutes');

    this.setState({ minutes: value, finishDateTime: endDate.toDate() });
  };

  private _onChangeTimeSelectOption = (e) => {
    const timeSelectOption = e.target.value;
    const startDate = moment(this.state.startDateTime)
      .set('hours', 9)
      .set('minutes', 0)
      .set('seconds', 0)
      .set('ms', 0)
      .toDate();
    const endDate =
      timeSelectOption === 'duration'
        ? moment()
            .set('hours', 10)
            .set('minutes', 0)
            .set('seconds', 0)
            .set('ms', 0)
            .toDate()
        : moment(this.state.finishDateTime)
            .set('hours', 17)
            .set('minutes', 0)
            .set('seconds', 0)
            .set('ms', 0)
            .toDate();

    this.setState({
      hours: 1,
      minutes: 0,
      startDateTime: startDate,
      finishDateTime: endDate,
      timeSelectOption: timeSelectOption,
    });
  };

  private _removeSelectedWorker = async () => {
    await this.props.setNewActivityRecordData({
      ...this.props.newActivityRecordData,
      selectedWorkerId: null,
      selectedWorker: null,
      conflictWorkerBookings: null,
    });
  };

  private _onNextStep = () => {
    const { newActivityRecordData, setNewActivityRecordData, bookSelectedService } = this.props;

    const startDateTime = moment
      .tz(moment(this.state.startDateTime).format('YYYY-MM-DD HH:mm'), newActivityRecordData.selectedService.timezone)
      .format('X');

    const serviceDateTimeObject = _.find(bookSelectedService.timeAvailabilities, (time) => {
      const itemStartStr = moment(time.startDateTime).format('X');
      const itemEndStr = moment(time.endDateTime).format('X');
      return itemStartStr <= startDateTime && itemEndStr >= startDateTime;
    });

    if (_.isEmpty(serviceDateTimeObject)) {
      notification.error({
        message: 'Selected time for this service is not available.',
      });
      return;
    }

    setNewActivityRecordData({
      ...newActivityRecordData,
      bookingTimeSelection: this.state.timeSelectOption,
      bookingDate: this.state.startDate,
      bookingStartDate: this.state.startDateTime,
      bookingEndDate: this.state.finishDateTime,
      isDuration: this.state.timeSelectOption === 'duration',
      durationHours: this.state.hours,
      durationMinutes: this.state.minutes,
      serviceDateTimeId: serviceDateTimeObject.serviceDateTimeId,
      timeSlots: [
        {
          startDateTime: moment(this.state.startDateTime).toDate(),
          endDateTime: moment(this.state.finishDateTime).toDate(),
          serviceDateTimeId: serviceDateTimeObject.serviceDateTimeId,
        },
      ],
      isShiftSlotSentToTimesheet: this.state.checkSendToTimesheet,
    });
    this.props.onNextStep();
  };

  private _onChangeCheckbox = (event) => {
    const value = event.target.checked;
    if (value) {
      this.setState({ timeSelectOption: 'datetime', checkSendToTimesheet: true });
    } else {
      this.setState({ checkSendToTimesheet: false });
    }
  };

  private _onUpdateAddresses = (): any[] => {
    const { newActivityRecordData, bookSelectedService, selectedCustomer } = this.props;
    const { manuallyAddedAddress } = newActivityRecordData;
    let addresses;
    if (bookSelectedService) {
      if (selectedCustomer) {
        const userAddresses = selectedCustomer.addresses;
        userAddresses.sort((a, b) => b.isPrimary - a.isPrimary);
        addresses = manuallyAddedAddress
          ? [manuallyAddedAddress].concat(bookSelectedService.locations).concat(userAddresses)
          : bookSelectedService.locations
          ? bookSelectedService.locations.concat(userAddresses)
          : userAddresses;
      } else {
        addresses = manuallyAddedAddress
          ? [manuallyAddedAddress].concat(bookSelectedService.locations)
          : bookSelectedService.locations;
      }
    } else {
      if (selectedCustomer) {
        const userAddresses = selectedCustomer.addresses;
        userAddresses.sort((a, b) => b.isPrimary - a.isPrimary);
        addresses = manuallyAddedAddress ? [manuallyAddedAddress].concat(userAddresses) : userAddresses;
      } else {
        addresses = manuallyAddedAddress ? [manuallyAddedAddress] : [];
      }
    }

    return addresses;
  };

  componentDidMount() {
    const { newActivityRecordData } = this.props;
    const { timeSelectOption, startDate, hours, minutes } = this.state;

    const addresses = this._onUpdateAddresses();
    const selectedAddress =
      newActivityRecordData.bookLocation &&
      _.find(addresses, (address) => address.fullAddress === newActivityRecordData.bookLocation.fullAddress);

    this.setState({
      startDate: newActivityRecordData.bookingDate
        ? newActivityRecordData.bookingDate
        : moment()
            .set('hours', 9)
            .set('minutes', 0)
            .set('seconds', 0)
            .set('ms', 0)
            .toDate(),
      startDateTime: newActivityRecordData.bookingStartDate
        ? newActivityRecordData.bookingStartDate
        : moment()
            .set('hours', 9)
            .set('minutes', 0)
            .set('seconds', 0)
            .set('ms', 0)
            .toDate(),
      finishDateTime: newActivityRecordData.bookingEndDate
        ? newActivityRecordData.bookingEndDate
        : timeSelectOption === 'duration'
        ? moment()
            .set('hours', 10)
            .set('minutes', 0)
            .set('seconds', 0)
            .set('ms', 0)
            .toDate()
        : moment()
            .set('hours', 17)
            .set('minutes', 0)
            .set('seconds', 0)
            .set('ms', 0)
            .toDate(),
      timeSelectOption: newActivityRecordData.bookingTimeSelection
        ? newActivityRecordData.bookingTimeSelection
        : 'duration',
      hours: newActivityRecordData.durationHours ? newActivityRecordData.durationHours : 1,
      minutes: newActivityRecordData.durationMinutes ? newActivityRecordData.durationMinutes : 0,
      checkSendToTimesheet: newActivityRecordData.isShiftSlotSentToTimesheet
        ? newActivityRecordData.isShiftSlotSentToTimesheet
        : false,
      userAddressId: newActivityRecordData.bookLocation
        ? selectedAddress.userAddressId
          ? selectedAddress.userAddressId
          : _.indexOf(
              addresses,
              (address) => address.fullAddress === newActivityRecordData.bookLocation.fullAddress,
            ).toString()
        : null,
      addresses,
    });
  }

  render() {
    const { servicesLite, newActivityRecordData, selectedCustomer, bookSelectedService, onPreviousStep } = this.props;
    const { selectedServiceId, manuallyAddedAddress } = newActivityRecordData;

    let serviceUnavailableDays = [];
    if (this.state.innerStep === 2) {
      if (bookSelectedService && bookSelectedService.serviceConfig && bookSelectedService.serviceConfig.dataArray) {
        serviceUnavailableDays = _.map(
          _.filter(bookSelectedService.serviceConfig.dataArray, (date) => !date.selected),
          (filteredDate) => {
            return moment.tz(filteredDate.startDateTime, bookSelectedService.timezone).day() + 1;
          },
        );
      }
    }

    let addresses = [];
    if (selectedCustomer) {
      const userAddresses = selectedCustomer.addresses;
      userAddresses.sort((a, b) => b.isPrimary - a.isPrimary);
      addresses = manuallyAddedAddress ? [manuallyAddedAddress].concat(userAddresses) : userAddresses;
    } else {
      addresses = manuallyAddedAddress ? [manuallyAddedAddress] : null;
    }

    return (
      <div className="anim-slide-left">
        <Script
          url={'https://maps.googleapis.com/maps/api/js?key=' + globalConfig.googleMapApiKey + '&libraries=places'}
        />
        <AddAddressModal
          closeCreateEditAddressModal={() => {
            this._closeCreateAddressModal();
          }}
          isOpen={this.state.openCreateAddress}
          saveCustomerAddress={this._saveBookingAddress}
          title={
            <>
              Add a New <b>Address</b>
            </>
          }
          subTitle={
            <>
              You are adding a new Address for{' '}
              <b>
                {selectedCustomer.firstName} {selectedCustomer.lastName}
              </b>{' '}
              profile. Please enter the following details.
            </>
          }
        />
        {this.state.openSelectWorker && (
          <BookingAssignWorkerModal
            isOpen={this.state.openSelectWorker}
            onClose={this._closeSelectWorkerModal}
            onSubmitAssign={this._onSelectSupportWorker}
            serviceId={newActivityRecordData.selectedServiceId}
            isRecurring={false}
            address={
              newActivityRecordData.bookLocation
                ? {
                    ...newActivityRecordData.bookLocation,
                    geoLat: Number(newActivityRecordData.bookLocation.geoLat),
                    geoLng: Number(newActivityRecordData.bookLocation.geoLng),
                  }
                : null
            }
            customerUserIds={[newActivityRecordData.selectedCustomerId]}
            displayTimezone={newActivityRecordData.selectedService.timezone}
            history={this.props.history}
            isEditingBooking={false}
            bookingType={BookingType.ACTIVITY_RECORD}
          />
        )}
        <Row className="ph-x4-large mt-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 184px)', overflow: 'auto' }}>
            <div className="width-3/4">
              {this.state.innerStep === 1 && (
                <>
                  <Paragraph>
                    Please assign the relevant <b>team member</b> and <b>location</b> this activity took place.
                  </Paragraph>
                  <Paragraph>Note that activity records will not be used for team member conflict checks.</Paragraph>
                  <Paragraph>
                    Bt default, the <b>location</b> will be set to your organisation's billing address. You may opt to
                    select a specific location that this activity takes place in.
                  </Paragraph>
                  {/*<Paragraph>*/}
                  {/*  Visit the <a>Help Center</a> to learn more.*/}
                  {/*</Paragraph>*/}
                </>
              )}
              {this.state.innerStep === 2 && (
                <>
                  <Paragraph>
                    Next, fill in the <b>details</b> for this activity record.
                  </Paragraph>
                  <Paragraph>
                    Activity records require a <b>date</b> and <b>duration</b>.
                  </Paragraph>
                  <Paragraph>
                    <b>Start times</b> are optional, but it will allow us to generate the line items for this activity
                    record more accurately that information is relevant for this record.
                  </Paragraph>
                  {/*<Paragraph>*/}
                  {/*  Visit the <a>Help Center</a> to learn more.*/}
                  {/*</Paragraph>*/}
                </>
              )}
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 184px)' }}>
            <div className="bg-white rounded-big p-large" style={{ minWidth: '250px', minHeight: '463px' }}>
              <Title level={4} className={'mb-large'}>
                Add details for activity record
              </Title>
              {this.state.innerStep === 1 && (
                <>
                  <FieldLabel text={'SELECT SERVICE'} />
                  <Select
                    className="pr-medium mt-small mb-large"
                    size="large"
                    showSearch
                    filterOption
                    optionFilterProp="label"
                    style={{ width: '500px' }}
                    onChange={this._setSelectedService}
                    value={selectedServiceId}
                  >
                    {!this.state.isLoading &&
                      _.map(servicesLite, (service) => (
                        <Option key={service.serviceId} value={service.serviceId} label={service.serviceName}>
                          {service.serviceName}
                        </Option>
                      ))}
                  </Select>
                  <FieldLabel text={'LOCATION'} />
                  <Radio.Group value={this.state.userAddressId} className={'width-full mt-small'}>
                    {addresses.length > 0 ? (
                      addresses.map((address, i) => (
                        <AddressItem
                          key={address.userAddressId}
                          bordered={true}
                          isCRM={selectedCustomer.isCrm}
                          isSelected={this.state.userAddressId}
                          userAddressId={address.userAddressId}
                          handleAddressId={this._handleAddressId}
                          address={<LocationDisplay location={address.fullAddress} />}
                          disabledAddress={!address.fullAddress}
                          addPadding={i % 3 !== 0}
                          description={
                            address.isManual
                              ? 'Manually added address'
                              : address.serviceLocationId
                              ? 'Business Address'
                              : address.isPrimary
                              ? 'Customer primary address'
                              : 'Alternate Address ' + i + ''
                          }
                        />
                      ))
                    ) : (
                      <Text>
                        <Icon type="close-circle" theme="filled" className="mr-x-small text-color-red" />
                        No address set. Please add an address on the customer's profile.
                      </Text>
                    )}
                  </Radio.Group>
                  <div className="mt-large">
                    <HyperlinkButton onClick={this._openCreateAddressModal}>
                      Search for a different address
                    </HyperlinkButton>
                  </div>
                  <div className={'mt-large'}>
                    <FieldLabel text={'TEAM MEMBER (OPTIONAL)'} />
                    {newActivityRecordData.selectedWorker && (
                      <>
                        <div className={'mt-small'}>
                          <Avatar src={newActivityRecordData.selectedWorker.attachmentUrl} />
                          <Text className={'ml-small'}>
                            {newActivityRecordData.selectedWorker.firstName +
                              ' ' +
                              newActivityRecordData.selectedWorker.lastName}
                          </Text>
                        </div>
                        <div className={'mt-small'}>
                          <HyperlinkButton color={'red'} onClick={this._removeSelectedWorker} className={'mt-small'}>
                            Remove team member
                          </HyperlinkButton>
                        </div>
                        <div className={'mt-large mb-x2-large'}>
                          <Checkbox checked={this.state.checkSendToTimesheet} onChange={this._onChangeCheckbox}>
                            Send team member hours to timesheet
                          </Checkbox>
                        </div>
                      </>
                    )}
                    {!newActivityRecordData.selectedWorker &&
                      (!newActivityRecordData.selectedService || !newActivityRecordData.bookLocation) && (
                        <div className={'mt-x-small'}>
                          <Text type={'secondary'} className={'mt-x-small'}>
                            Search team member
                          </Text>
                          <Tooltip2
                            content={
                              <div style={{ width: '250px' }}>
                                <Text color={'white'}>
                                  You must select a service & address before you can assign a team member
                                </Text>
                              </div>
                            }
                            position={'top'}
                            className={'ml-small'}
                          >
                            <Icon style={{ color: 'gray' }} type="question-circle" />
                          </Tooltip2>
                        </div>
                      )}
                    {!newActivityRecordData.selectedWorker &&
                      newActivityRecordData.selectedService &&
                      newActivityRecordData.bookLocation && (
                        <HyperlinkButton onClick={this._openSelectWorkerModal} className={'mt-x-small'}>
                          Search team member
                        </HyperlinkButton>
                      )}
                  </div>
                </>
              )}
              {this.state.innerStep === 2 && (
                <>
                  <FieldLabel text={'ACTIVITY DATE'} />
                  <DatePicker
                    size="large"
                    placeholder="Start Date"
                    format="DD/MM/YYYY"
                    value={moment(this.state.startDate)}
                    onChange={this._setStartDate}
                    allowClear={false}
                    disabledDate={(current) => {
                      return (
                        current > moment.tz(bookSelectedService.availableTo, bookSelectedService.timezone) ||
                        _.find(serviceUnavailableDays, (day) => day === current.day() + 1)
                      );
                    }}
                  />

                  {!(this.props.newActivityRecordData.selectedWorker && this.state.checkSendToTimesheet) && (
                    <div className={'mt-large'}>
                      <Radio.Group
                        size={'large'}
                        value={this.state.timeSelectOption}
                        onChange={(e) => {
                          this._onChangeTimeSelectOption(e);
                        }}
                      >
                        <Radio style={{ display: 'block' }} value={'duration'}>
                          Select a duration
                        </Radio>
                        <Radio style={{ display: 'block' }} className={'mt-small'} value={'datetime'}>
                          Select a start and finish time
                        </Radio>
                      </Radio.Group>
                    </div>
                  )}

                  {this.state.timeSelectOption === 'duration' &&
                    !(this.props.newActivityRecordData.selectedWorker && this.state.checkSendToTimesheet) && (
                      <div className={'mt-large'}>
                        <FieldLabel text={'DURATION OF ACTIVITY'} />
                        <div className={'mt-small'}>
                          <NumberInput
                            min={0}
                            max={23}
                            value={this.state.hours}
                            onChange={this._onChangeHours}
                            placeholder={'1'}
                            size={'large'}
                            style={{ width: '120px' }}
                            precision={0}
                          />
                          <Text className={'ml-small mr-medium'}>Hours</Text>
                          <Select
                            size={'large'}
                            style={{ width: '120px' }}
                            value={this.state.minutes}
                            onChange={this._onChangeMinutes}
                          >
                            <Option value={0}>0</Option>
                            <Option value={5}>5</Option>
                            <Option value={10}>10</Option>
                            <Option value={15}>15</Option>
                            <Option value={20}>20</Option>
                            <Option value={25}>25</Option>
                            <Option value={30}>30</Option>
                            <Option value={35}>35</Option>
                            <Option value={40}>40</Option>
                            <Option value={45}>45</Option>
                            <Option value={50}>50</Option>
                            <Option value={55}>55</Option>
                          </Select>
                          <Text className={'ml-small'}>Minutes</Text>
                        </div>
                      </div>
                    )}
                  {(this.state.timeSelectOption === 'datetime' ||
                    (this.props.newActivityRecordData.selectedWorker && this.state.checkSendToTimesheet)) && (
                    <div className={'mt-large flex-row'}>
                      <div>
                        <FieldLabel text={'SELECT START TIME'} />
                        <TimeInput
                          size="large"
                          value={moment(this.state.startDateTime)}
                          onChange={this._setStartTime}
                          className={'mt-small'}
                        />
                      </div>
                      <div className={'ml-large'}>
                        <FieldLabel text={'SELECT FINISH TIME'} />
                        <TimeInput
                          size="large"
                          value={moment(this.state.finishDateTime)}
                          onChange={this._setEndTime}
                          className={'mt-small'}
                        />
                      </div>
                    </div>
                  )}
                  {this.props.newActivityRecordData.selectedWorker && this.state.checkSendToTimesheet && (
                    <div
                      style={{
                        backgroundColor: ' #FAFAFA',
                        border: '1px solid #EEEEEE',
                        boxSizing: 'border-box',
                        borderRadius: '8px',
                      }}
                      className={'flex-row p-large mt-large'}
                    >
                      <Icon type="info-circle" style={{ fontSize: '21px', color: '#696969' }} />
                      <Text className={'ml-large'}>
                        You have chosen to add the assigned team member hours to their timesheet. As a result you must
                        add a start and finish time.
                      </Text>
                    </div>
                  )}
                </>
              )}
            </div>
            {this.state.innerStep === 1 && (
              <div className="flex-row justify-end mt-x2-large">
                <SecondaryButton size="large" onClick={onPreviousStep}>
                  Previous
                </SecondaryButton>

                <PrimaryButton
                  size="large"
                  disabled={
                    _.isEmpty(newActivityRecordData.selectedService) || _.isEmpty(newActivityRecordData.bookLocation)
                  }
                  onClick={this._onNextInnerStep}
                  className={'ml-medium'}
                >
                  Next
                </PrimaryButton>
              </div>
            )}
            {this.state.innerStep === 2 && (
              <div className="flex-row justify-end mt-x2-large">
                <SecondaryButton size="large" onClick={this._onPreviousInnerStep}>
                  Previous
                </SecondaryButton>

                <PrimaryButton
                  size="large"
                  disabled={this.state.startDate === null}
                  onClick={this._onNextStep}
                  className={'ml-medium'}
                >
                  Next
                </PrimaryButton>
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  servicesLite: state.servicesStore.servicesLite,
  portalUser: state.authStore.portalUser,
  newActivityRecordData: state.bookingsStore.newActivityRecordData,
  selectedCustomer: state.customersStore.selectedCustomer,
  bookSelectedService: state.servicesStore.bookSelectedService,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServicesLite: dispatch.servicesStore.doFetchServicesLite,
  doFetchSingleService: dispatch.servicesStore.doFetchSingleService,
  setNewActivityRecordData: dispatch.bookingsStore.setNewActivityRecordData,
  setSelectedCreateService: dispatch.servicesStore.setSelectedCreateService,
});

export default connect(mapState, mapDispatch)(ActivityRecordStep2SelectDetails);
