import React, { Component } from 'react';
import _ from 'lodash';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Avatar, Col, Empty, notification, Row, Tabs } from 'antd';
import { ICustomer, IPreference } from 'interfaces/customer-interfaces';
import Title from 'antd/lib/typography/Title';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import AddEditPreferencesModal from 'views/customers/details/tabs-panel/carer-preferences/AddEditPreferencesModal';
import { Menu, MenuItem, Popover } from '@blueprintjs/core';

interface ICarerPreferencesPanelProps {
  selectedCustomer: ICustomer;
  doDeleteCustomerPreference: typeof dispatch.customersStore.doDeleteCustomerPreference;
  hasEditPermission: boolean;
}

interface ICarerPreferencesPanelState {
  isOpen: boolean;
  isDeleteModalOpen: boolean;
  isAddEditModalOpen: boolean;
  selectedDisplayMode: any;
  selectedTab: string;
  selectedPreference: IPreference;
}

const PreferenceEmptyState = ({ selectedTab }) => (
  <div className="flex-1 bg-white align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No {selectedTab === 'PREFERRED' ? 'preferred' : 'blocked'} team member found.
    </Text>
  </div>
);

class CarerPreferencesPanel extends Component<ICarerPreferencesPanelProps, ICarerPreferencesPanelState> {
  state = {
    isOpen: false,
    isDeleteModalOpen: false,
    isAddEditModalOpen: false,
    selectedDisplayMode: null,
    selectedTab: 'PREFERRED',
    selectedPreference: null
  };

  private _closeAddEditPreferenceModal = () => {
    this.setState({ isAddEditModalOpen: false });
  };

  private _openAddPreferenceEditModal = (selectedDisplayMode, selectedPreference = null) => {
    this.setState({ isAddEditModalOpen: true, selectedDisplayMode, selectedPreference });
  };

  private _closeDeletePreferenceModal = () => {
    this.setState({ isDeleteModalOpen: false });
  };

  private _openDeletePreferenceModal = (selectedPreference) => {
    this.setState({ isDeleteModalOpen: true, selectedPreference });
  };

  private _changeSelectedTab = (event) => {
    this.setState({ selectedTab: event });
  };

  private _deletePreference = async () => {
    const { doDeleteCustomerPreference, selectedCustomer } = this.props;
    try {
      await doDeleteCustomerPreference({
        customerUserId: selectedCustomer.userId,
        carerPreferenceId: this.state.selectedPreference && this.state.selectedPreference.carerPreferenceId,
        preference: this.state.selectedTab
      });
      notification.success({
        message: `${this.state.selectedTab === 'PREFERRED' ? 'Preferred' : 'Blocked'} team member deleted successfully.`
      });
      this._closeDeletePreferenceModal();
    } catch (e) {
      notification.error({ message: 'Oops something went wrong! Please try again.' });
    }
  };

  private _getPopoverContent = (preference) => {
    return (
      <Menu>
        <MenuItem
          label={''}
          className="hover-bg-blue-lightest mv-medium ml-small"
          text={'Edit'}
          onClick={() => this._openAddPreferenceEditModal('EDIT', preference)}
        />
        <MenuItem
          label={''}
          className="hover-bg-blue-lightest text-color-red-dark mv-medium ml-small"
          text={'Delete'}
          onClick={() => this._openDeletePreferenceModal(preference)}
        />
      </Menu>
    );
  };

  render() {
    const { selectedCustomer, hasEditPermission } = this.props;
    const { selectedDisplayMode, selectedTab } = this.state;

    const preferenceList = selectedCustomer.preferences
      ? selectedTab === 'PREFERRED'
        ? selectedCustomer.preferences.preferredSupportWorkers
        : selectedCustomer.preferences.blockedSupportWorkers
      : [];

    if (!_.isEmpty(selectedCustomer)) {
      return (
        <div>
          <ActionModal
            isOpen={this.state.isDeleteModalOpen}
            onClose={this._closeDeletePreferenceModal}
            title={`Delete ${selectedTab === 'PREFERRED' ? 'Preferred' : 'Blocked'} team member`}
            showCloseButton={true}
            width={'medium'}
            verticalAlignment={'high'}
          >
            <Paragraph>
              Are you sure you want to remove this team member as{' '}
              {selectedTab === 'PREFERRED' ? 'Preferred' : 'Blocked'} for this customer?
            </Paragraph>
            <ActionModalFooter>
              <SecondaryButton className="mr-medium" size="large" onClick={this._closeDeletePreferenceModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" color="red" onClick={this._deletePreference}>
                Delete
              </PrimaryButton>
            </ActionModalFooter>
          </ActionModal>
          <AddEditPreferencesModal
            closeAddEditPreferencesModal={this._closeAddEditPreferenceModal}
            selectedCustomer={selectedCustomer}
            isOpen={this.state.isAddEditModalOpen}
            selectedPreference={this.state.selectedPreference}
            preferenceMode={this.state.selectedTab}
            isEdit={selectedDisplayMode === 'EDIT'}
          />
          <Row>
            <Col>
              <Title level={2} className={'m-none'}>
                Carer Preferences
              </Title>
              <Text type={'secondary'}>Information about team members this customer prefers.</Text>
            </Col>
          </Row>
          <Tabs className={'inner-tab mv-large'} onChange={this._changeSelectedTab}>
            <Tabs.TabPane key="PREFERRED" tab="Preferred team members" />
            <Tabs.TabPane key="BLOCKED" tab="Blocked team members" />
          </Tabs>
          <Row type={'flex'} justify={'space-between'} align={'middle'}>
            <Col>
              <Title level={4} className={'m-none'}>
                {selectedTab === 'PREFERRED' ? 'Preferred' : 'Blocked'} team members
              </Title>
              <Text type={'secondary'}>
                Team members this customer {selectedTab === 'PREFERRED' ? 'prefers' : 'want to block'}.
              </Text>
            </Col>

            <Col className={'align-center'}>
              <div className={'flex-row-reverse align-center'}>
                {hasEditPermission && (
                  <PrimaryButton icon={'plus'} onClick={() => this._openAddPreferenceEditModal('ADD')}>
                    Add {selectedTab === 'PREFERRED' ? 'Preferred' : 'Blocked'} team member
                  </PrimaryButton>
                )}
              </div>
            </Col>
          </Row>
          <div className={'pv-large'}>
            <div className="mb-medium">
              {preferenceList.length > 0 ? (
                <>
                  <Row className={'pb-small bordered-bottom border-standard-gray'}>
                    <Col span={8} className={'pl-medium'}>
                      <SubTitle>Team member</SubTitle>
                    </Col>
                    <Col span={13}>
                      <SubTitle>Comments</SubTitle>
                    </Col>
                    <Col span={3} />
                  </Row>
                  {_.map(preferenceList, (preference) => {
                    return (
                      <Row className={'pv-medium evenodd'} id={preference.carerPreferenceId}>
                        <Col span={8} className={'flex-row align-center pl-medium'}>
                          <Avatar icon="user" className="mr-small" src={preference.attachmentUrl} />
                          {`${preference.firstName} ${preference.lastName}`}
                        </Col>
                        <Col span={13} style={{ overflowX: 'hidden' }}>
                          <Text style={{ whiteSpace: 'pre-wrap' }}>{preference.description}</Text>
                        </Col>
                        <Col span={3} className={'text-align-center'}>
                          <Popover
                            content={this._getPopoverContent(preference)}
                            popoverClassName={'mb-medium'}
                            position={'bottom'}
                            interactionKind="click"
                          >
                            <GhostButton icon={'ellipsis'} />
                          </Popover>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              ) : (
                <div className="mt-x7-large">
                  <PreferenceEmptyState selectedTab={selectedTab} />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doDeleteCustomerPreference: dispatch.customersStore.doDeleteCustomerPreference
});

export default connect(
  null,
  mapDispatch
)(CarerPreferencesPanel);
