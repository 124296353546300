import React from 'react';
import { Avatar, Col, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { INewMemberData } from 'src/interfaces/account-interfaces';
import CommonUtils from 'utilities/common-utils';

interface IFinishedPanelProps {
  newMemberData?: INewMemberData;
  ownerRole?: number;
  addAnotherTeamMember?: () => void;
  goToNewTeamMember?: () => void;
  canAccessTeamMemberProfile?: boolean;
}

function FinishedPanel({
  newMemberData,
  ownerRole,
  addAnotherTeamMember,
  goToNewTeamMember,
  canAccessTeamMemberProfile
}: IFinishedPanelProps) {
  return (
    <div className="anim-slide-left select-none">
      <Row className="ph-x2-large">
        {/* Left Column */}
        <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 142px)', overflow: 'auto' }}>
          <div style={{ maxWidth: '80%', minHeight: 'calc(100vh - 262px)' }}>
            {/* <-- ! Left Content Starts here ! --> */}
          </div>
        </Col>

        {/* Right Column */}
        <Col span={18} style={{ minHeight: 'calc(100vh - 142px)', maxWidth: '820px' }} className="pl-large">
          <div style={{ minHeight: 'calc(100vh - 262px)' }} className="flex-column">
            {/* <-- ! Right Content Starts here ! --> */}
            <div className="mt-x8-large mb-large">
              {/* Text */}
              <div className="mb-medium">
                <Text size="x2-large">Team member {newMemberData.createMode === 'draft' && 'draft'} created!</Text>
              </div>

              {/* Worker Card */}
              <div
                className="bg-white rounded p-large flex-row bordered border-standard-gray"
                style={{ maxWidth: '480px' }}
              >
                <div className="mr-medium">
                  <Avatar className="avatar-medium" shape={'square'} />
                </div>
                <div>
                  <div className="flex-row align-center">
                    <Text size="x-large" className="mr-small">
                      {newMemberData.firstName} {newMemberData.lastName}
                    </Text>
                    <Text size="regular" color={'secondary'}>
                      {newMemberData.createMode === 'draft' ? 'DRAFT' : 'ENABLED'}
                    </Text>
                  </div>
                  <div>
                    <Text size="regular">{newMemberData.email}</Text> <br />
                    <Text size="regular">
                      {CommonUtils.teamMemberPermissionTag(
                        ownerRole,
                        newMemberData.permissions.hasPortalAccess,
                        newMemberData.permissions.hasWorkerAppAccess
                      )}
                    </Text>
                  </div>
                </div>
              </div>
            </div>

            {/* Buttons */}
            <div>
              <SecondaryButton size="large" className="mr-x2-large" onClick={() => addAnotherTeamMember()}>
                Add another team member
              </SecondaryButton>
              {canAccessTeamMemberProfile && (
                <PrimaryButton size="large" onClick={() => goToNewTeamMember()}>
                  View full profile
                </PrimaryButton>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default FinishedPanel;
