import React, { Component } from 'react';
import * as H from 'history';
import { GridRow } from 'common-components/grids';
import { Avatar, Col } from 'antd';
import { GhostButton, HyperlinkButton } from 'common-components/buttons';
import { Menu, MenuItem, Popover } from '@blueprintjs/core';
import { Text } from 'common-components/typography';
import { IGroupServiceTeamMember } from 'interfaces/service-interfaces';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';

interface ITeamMemberListItemProps {
  history: H.History;
  teamMemberItem: IGroupServiceTeamMember;
  onOpenRemoveTeamMemberModal: (i) => void;
}

class TeamMemberListItem extends Component<ITeamMemberListItemProps> {
  private _getPopoverContent = () => {
    return (
      <div>
        <ActionMenu className="pv-small">
          {/*<ActionMenuItem label={''} className="hover-bg-secondary pv-small ph-medium" text={'View schedule'} />*/}
          <ActionMenuItem
            label={''}
            className="text-color-red hover-bg-secondary pv-small ph-medium"
            text={'Remove from roster'}
            onClick={() => this.props.onOpenRemoveTeamMemberModal(this.props.teamMemberItem)}
          />
        </ActionMenu>
      </div>
    );
  };

  private _viewTeamMemberProfile = () => {
    const newUrl = `/team/details/${this.props.teamMemberItem.supportWorkerId}`;
    this.props.history.push(newUrl);
  };

  render() {
    const { teamMemberItem } = this.props;

    return (
      <GridRow containerClassName={'bordered rounded mt-medium'}>
        <Col span={7}>
          <div className="flex-row align-center">
            <Avatar className="mr-medium" size="large" icon="user" src={teamMemberItem.teamMemberAvatarUrl} />
            <HyperlinkButton
              onClick={this._viewTeamMemberProfile}
            >{`${teamMemberItem.teamMemberFirstName} ${teamMemberItem.teamMemberLastName}`}</HyperlinkButton>
          </div>
        </Col>
        <Col span={5}>
          <Text>{teamMemberItem.upcomingShiftSlotCount}</Text>
        </Col>
        <Col span={6}>
          <Text>{teamMemberItem.completedShiftSlotCount}</Text>
        </Col>
        <Col span={6} className="text-align-right">
          <Popover
            content={this._getPopoverContent()}
            popoverClassName={'mb-medium'}
            position={'bottom-right'}
            interactionKind="click"
          >
            <div
              className="bordered border-standard-gray rounded text-align-center"
              style={{ width: '28px', height: '28px' }}
            >
              <GhostButton icon={'ellipsis'} size={'small'} paddingSize={'x-small'} />
            </div>
          </Popover>
        </Col>
      </GridRow>
    );
  }
}

export default TeamMemberListItem;
