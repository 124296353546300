import React, { Component } from 'react';
import { Checkbox, Col, Form, Icon, InputNumber, Row, Select, Switch } from 'antd';
import _ from 'lodash';
import { Paragraph, Text, Title, SubTitle } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, state } from 'stores/rematch/root-store';
import { FormComponentProps } from 'antd/es/form';
import PermissionUtils from 'utilities/permission-utils';
import { PriceFrenquency, ServiceType } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';

const { Option } = Select;

interface IPaymentMethodStepPanelProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setNewService: typeof dispatch.servicesStore.setNewService;
  newService: typeof state.servicesStore.newService;
}

interface IPaymentMethodStepPanelState {
  isOpen: boolean;
  isNDISEnabled: boolean;
  isVCPEnabled: boolean;
  showPaymentMethodError: boolean;
  isServiceProviderVCPEnabled: boolean;
  isGroupService: boolean;
}

class PaymentMethodStepPanel extends Component<IPaymentMethodStepPanelProps, IPaymentMethodStepPanelState> {
  state = {
    isOpen: false,
    isNDISEnabled: false,
    isVCPEnabled: false,
    showPaymentMethodError: false,
    isServiceProviderVCPEnabled: false,
    isGroupService: !!(this.props.newService.serviceType && this.props.newService.serviceType === ServiceType.GROUP)
  };

  private _onNextStep = async () => {
    const { setNewService, onNextStep, newService, form } = this.props;
    const { isVCPEnabled, isNDISEnabled, isGroupService } = this.state;

    let isFormValid = true;

    form.validateFields(async (err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      let price: number = form.getFieldValue('serviceApproxPrice');
      let priceFrequency = form.getFieldValue('servicePriceFrequency');
      const paymentSources = [];
      if (isVCPEnabled) {
        paymentSources.push('VCP');
      }
      if (isNDISEnabled || isGroupService) {
        paymentSources.push('NDIS');
      }

      if (!_.isEmpty(paymentSources)) {
        await setNewService({
          ...newService,
          paymentSources,
          price,
          priceFrequency,
          currency: 'AUD'
        });
        onNextStep();
      } else {
        this.setState({ showPaymentMethodError: true });
      }
    }
  };

  async componentDidMount() {
    const { newService } = this.props;
    const hasNDIS = _.find(newService.paymentSources, (source) => source === 'NDIS');
    const hasVCP = _.find(newService.paymentSources, (source) => source === 'VCP');
    if (hasNDIS) this.setState({ isNDISEnabled: true });
    if (hasVCP) this.setState({ isVCPEnabled: true });

    const isVCPEnabled = await PermissionUtils.getIsServiceProviderVCPEnabled();
    if (isVCPEnabled) {
      this.setState({ isServiceProviderVCPEnabled: true });
    } else {
      this.setState({ isServiceProviderVCPEnabled: false });
    }
  }

  render() {
    const { onPreviousStep, form, newService } = this.props;
    const { isVCPEnabled, isNDISEnabled, isGroupService } = this.state;
    const { getFieldDecorator } = form;

    return (
      <div className="anim-slide-left mb-x-large">
        <Title level={2} weight="bolder" className="line-height-100">
          {isGroupService ? 'General pricing information' : 'Payment method'}
        </Title>

        <Paragraph>
          {isGroupService
            ? 'Tell us about the approximate price of this service.'
            : 'Tell us about the costs related to this service.'}
        </Paragraph>

        <Row className="mb-large">
          <Col span={4} style={{ marginTop: '6px' }}>
            <SubTitle>{isGroupService ? 'DISPLAY PRICE' : 'Approximate Price'}</SubTitle>
          </Col>
          <Col span={20} className="flex-row align-center">
            <Form.Item className={'m-none'}>
              <Text style={{ width: '50px' }}>$</Text>{' '}
              {getFieldDecorator('serviceApproxPrice', {
                initialValue: newService.price ? newService.price : '',
                rules: [
                  {
                    required: true,
                    type: 'number',
                    min: 0,
                    max: 999999,
                    message: 'Please enter a valid Price.'
                  }
                ]
              })(<InputNumber precision={2} />)}
              {getFieldDecorator('servicePriceFrequency', {
                initialValue: newService.priceFrequency ? newService.priceFrequency : 'PERHOUR',
                rules: [
                  {
                    required: true,
                    message: 'Please enter a Frequency.'
                  }
                ]
              })(
                isGroupService ? (
                  <Select style={{ width: '150px' }} className="ml-small">
                    {_.map(PriceFrenquency, (frequency) => (
                      <Select.Option value={frequency}>
                        {CommonUtils.formatPriceFrequencyString(frequency)}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <Select style={{ width: '150px' }} className="ml-small">
                    <Option key={1} value="PEREACH">
                      Flat Fee
                    </Option>
                    <Option key={2} value="PERHOUR">
                      Hourly
                    </Option>
                  </Select>
                )
              )}
            </Form.Item>
          </Col>
          {isGroupService && (
            <Text color="secondary">
              This will be displayed on the service listing in the GoodHuman marketplace to act as a general guide for
              customers.
            </Text>
          )}
          {!isGroupService && (
            <>
              <Paragraph>What payment methods are eligible to be claimed for this service</Paragraph>

              <div>
                <Switch
                  className={isNDISEnabled ? 'bg-green-light' : null}
                  checked={isNDISEnabled}
                  onChange={(value) => this.setState({ isNDISEnabled: value, showPaymentMethodError: false })}
                />
                <Text className="ml-medium">National Disability Insurance Scheme (NDIS)</Text>
              </div>
            </>
          )}
        </Row>

        {!isGroupService && this.state.isServiceProviderVCPEnabled && (
          <div>
            <Switch
              className={isVCPEnabled ? 'bg-green-light' : null}
              checked={isVCPEnabled}
              onChange={(value) => this.setState({ isVCPEnabled: value, showPaymentMethodError: false })}
            />
            <Text className="ml-medium">Vulnerable Children's Program (VCP)</Text>
          </div>
        )}

        {!isGroupService && this.state.showPaymentMethodError && (
          <div className="mt-medium">
            <Text color="red">Please enable at least 1 payment method.</Text>
          </div>
        )}
        <div className="flex-row align-center mv-x2-large">
          <SecondaryButton size="large" onClick={onPreviousStep} icon="left" className="mr-large">
            Previous
          </SecondaryButton>

          <PrimaryButton size="large" onClick={this._onNextStep} icon="right" iconPosition="right">
            Next
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

export default Form.create<IPaymentMethodStepPanelProps>()(PaymentMethodStepPanel);
