import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Avatar, Icon } from 'antd';
import { TagUserAvatar } from 'common-components/notes/TagUserAvatar';
import TextTag from 'common-components/tags/TextTag';
import { Text } from 'common-components/typography';
import * as H from 'history';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { GhostButton, HyperlinkButton } from '../buttons';

interface INoteCardItemProps {
  noteType: string;
  noteItem: any;
  serviceName: string;
  bookingId: string;
  onPressEditNote: (note) => void;
  onPressDeleteNote: (note) => void;
  bookingStartDateTime?: any;
  timezone: string;
  history?: H.History;
  isBookingArchived?: boolean;
  downloadFile?: (document) => void;
}

class NoteCardItem extends Component<INoteCardItemProps, any> {
  private element = null;
  state = {
    readMore: false,
    hasTextOverflown: true,
    textOffsetHeight: 0
  };

  private _getPopoverContent = () => {
    return (
      <Menu>
        {/* bookingStartDateTime does not exist means we are not in booking details, which means we are in customer details page*/}
        {!this.props.bookingStartDateTime && this.props.noteItem.attendanceId && (
          <MenuItem
            label={''}
            className="hover-bg-blue-lightest mv-medium"
            text={'Go to related booking'}
            onClick={() => this.props.history.push(`/bookings/details/${this.props.bookingId}`)}
          />
        )}
        <MenuItem
          label={''}
          className="hover-bg-blue-lightest mv-medium"
          text={'Edit note'}
          onClick={() => this.props.onPressEditNote(this.props.noteItem)}
        />
        <MenuItem
          label={''}
          className="hover-bg-blue-lightest text-color-red-dark mv-medium"
          text={'Delete note'}
          onClick={() => this.props.onPressDeleteNote(this.props.noteItem)}
        />
      </Menu>
    );
  };

  componentDidMount() {
    const { element } = this;
    const hasTextOverflown = element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
    this.setState({
      hasTextOverflown,
      textOffsetHeight: element.offsetHeight
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.noteItem.body !== this.props.noteItem.body) {
      const { element } = this;
      const hasTextOverflown =
        this.state.textOffsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
      this.setState({
        hasTextOverflown,
        readMore: false
      });
    }
  }

  render() {
    const { noteItem, noteType, serviceName, bookingStartDateTime } = this.props;

    return (
      <div className="bordered ph-medium mb-medium rounded-big bg-white">
        <div className="pb-large mt-medium flex-row">
          <div style={{ minWidth: '25%' }} className="flex-row align-start">
            <div className="flex-row align-center">
              <Avatar src={noteItem.authorImage} alt="avatar" size={40} shape={'square'} />
              <div className="mh-small">
                <Text weight="bold">
                  {noteItem.firstName} {noteItem.lastName}
                </Text>
                <br />
                <Text size="regular" color="secondary">
                  {moment.tz(noteItem.createdOn, this.props.timezone).format('DD MMM YYYY, hh:mm A')}
                </Text>
              </div>
            </div>
          </div>
          <div className="ml-x2-large width-full rounded">
            <div className="mb-medium flex-row">
              {noteItem.isIncident && (
                <div className="mr-small">
                  <TextTag
                    rounded={true}
                    color={'tertiary'}
                    content={
                      <Text color="red" className="mh-small" size="regular">
                        Incident
                      </Text>
                    }
                  />
                </div>
              )}
              {noteType === 'BOOKING' ? (
                <TextTag
                  rounded={true}
                  color={'tertiary'}
                  content={
                    <>
                      <Icon type="calendar" className="mr-x-small" />
                      <Text size="regular" weight="bold">
                        {serviceName} &nbsp;
                      </Text>
                      <Text size="regular" weight="regular" className="mr-small">
                        @{' '}
                        {moment
                          .tz(
                            noteItem.startDateTime ? noteItem.startDateTime : bookingStartDateTime,
                            this.props.timezone
                          )
                          .format('DD MMM YYYY, hh:mma')}
                      </Text>
                    </>
                  }
                />
              ) : (
                <TextTag
                  rounded={true}
                  color={'tertiary'}
                  content={
                    <Text size="regular" weight="bold" className="mr-small">
                      General
                    </Text>
                  }
                />
              )}
            </div>
            <div
              ref={(ref) => (this.element = ref)}
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'pre-line',
                height: this.state.readMore || !this.state.hasTextOverflown ? null : '3.6em'
              }}
              className="text-size-large line-height-120"
            >
              {noteItem.body}
            </div>
            {this.state.hasTextOverflown && (
              <div
                style={{ color: '#0083FF' }}
                className="cursor-pointer mt-small"
                onClick={() => this.setState({ readMore: !this.state.readMore })}
              >
                {this.state.readMore ? 'Read less' : 'Read more'}
              </div>
            )}

            {noteItem.documentId && (
              <div className="mt-large bg-tertiary rounded-big">
                <div className="p-small">
                  <Text size="small" color="secondary" lineHeight={100}>
                    Attachment
                  </Text>
                  <br />
                  <span title={noteItem.documentName}>
                    <Text
                      color={
                        noteItem.documentStatus === 'SCANNING'
                          ? 'secondary'
                          : noteItem.documentStatus === 'SCANNED'
                          ? 'green'
                          : 'red'
                      }
                    >
                      {noteItem.documentStatus === 'SCANNED' ? (
                        <HyperlinkButton
                          onClick={() =>
                            this.props.downloadFile({
                              uniqueId: noteItem.uniqueId,
                              documentUrl: noteItem.documentUrl,
                              documentName: noteItem.documentName
                            })
                          }
                        >
                          <Icon type={'download'} className="mr-x-small text-color-blue" />
                          {noteItem.documentName}
                        </HyperlinkButton>
                      ) : (
                        <>
                          <Tooltip2
                            content={
                              noteItem.documentStatus === 'SCANNING'
                                ? 'This document is being scanned.'
                                : 'This document failed to upload.'
                            }
                          >
                            <Icon
                              type="question-circle"
                              className={`text-size-x-large mr-x-small text-color-${
                                noteItem.documentStatus === 'SCANNING' ? 'blue' : 'red'
                              }`}
                            />
                          </Tooltip2>
                          {noteItem.documentName}
                        </>
                      )}
                    </Text>
                  </span>
                </div>
              </div>
            )}

            {/* Tag to */}
            {noteItem.taggedTo && (
              <div className="mt-medium">
                <div className="mb-x-small">
                  <Text size="small" color="secondary" lineHeight={100}>
                    Tagged to
                  </Text>
                </div>
                <div className="pt-medium ph-medium bordered border-standard-gray bg-quaternary rounded-big line-height-100">
                  <div className="flex-row flex-wrap">
                    {_.map(noteItem.taggedTo, (customer) => (
                      <TagUserAvatar customer={customer} />
                    ))}
                  </div>
                </div>
              </div>
            )}

            {noteItem.updatedByWorkerFirstName && (
              <div className="mt-medium flex-row align-center">
                <Avatar src={noteItem.updatedByWorkerImage} alt="avatar" size={15} shape={'square'} />
                <Text size="regular" color="secondary" className="mh-small">
                  Edited By &nbsp;
                  <b>
                    {noteItem.updatedByWorkerFirstName} {noteItem.updatedByWorkerLastName}
                  </b>
                  &nbsp; on &nbsp;
                  <b>{moment.tz(noteItem.updatedOn, this.props.timezone).format('DD MMM YYYY, hh:mm A')}</b>
                </Text>
              </div>
            )}
          </div>
          <div className="flex justify-end" style={{ flex: 'auto' }}>
            {!this.props.isBookingArchived && (
              <Popover2
                content={this._getPopoverContent()}
                popoverClassName={'mb-medium'}
                position={'bottom'}
                interactionKind="click"
              >
                <GhostButton icon={'ellipsis'} />
              </Popover2>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default NoteCardItem;
