import React, { Component } from 'react';

import { Form, Input, InputNumber, notification, Select } from 'antd';

import { Paragraph, SubTitle, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { ndisHelper } from 'variables/data-helpers';
import NDISLineItemGrid from 'common-components/line-items/NDISLineItemGrid';
import {
  MmmGroup,
  PaymentSourceType,
  ServiceLocationType,
  ServiceType,
  TeamMemberCustomerRatio,
} from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';
import servicesStore from 'src/stores/rematch/models/services-store';
import { ITeamMemberCustomerRatio } from 'src/interfaces/service-interfaces';
import AdditionalChargesSection from '../../../components/AdditionalChargesSection';
import { IBillingLineItem } from 'interfaces/booking-interfaces';
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;
const { TextArea } = Input;

interface IServiceItemInputModalProps extends FormComponentProps {
  onClose: any;
  isOpen: boolean;
  servicesLite: typeof state.servicesStore.servicesLite;
  isEdit: boolean;
  addNewService: (service) => void;
  editService: (service) => void;
  existingServices: Array<any>;
  doFetchServicesLite: typeof dispatch.servicesStore.doFetchServicesLite;
  selectedService?: any;
  selectedCustomer?: typeof state.customersStore.selectedCustomer;
  paymentSourceType: string;
  doFetchServiceAgreementServiceBillingLineItems: typeof dispatch.customersStore.doFetchServiceAgreementServiceBillingLineItems;
}

interface IServiceItemInputModalState {
  isSaving: boolean;
  isLoading: boolean;
  newSelectedService: any;
  newComment: any;
  step: number;
  selectedLineItems: any;
  userServiceAgreementLineItems: any;
  locationMmmGroup: MmmGroup;
  locationState: string;
  teamMemberCustomerRatio: ITeamMemberCustomerRatio;
  supportLineItems: any;
  isNonFaceToFace: boolean;
  nonFaceToFaceMinutes: number;
  autoChargeLineItems: any[];
}

const unitOptions = [
  { value: 'EA', label: 'Each' },
  { value: 'H', label: 'Hourly' },
  { value: 'D', label: 'Daily' },
  { value: 'WK', label: 'Weekly' },
  { value: 'MON', label: 'Monthly' },
  { value: 'YR', label: 'Yearly' },
];

class ServiceItemInputModal extends Component<IServiceItemInputModalProps, IServiceItemInputModalState> {
  state = {
    isSaving: false,
    isLoading: false,
    newSelectedService: null,
    newComment: '',
    step: 1,
    selectedLineItems: [],
    userServiceAgreementLineItems: null,
    locationMmmGroup: null,
    locationState: null,
    teamMemberCustomerRatio: null,
    supportLineItems: [],
    isNonFaceToFace: false,
    nonFaceToFaceMinutes: null,
    autoChargeLineItems: [],
  };

  private additionalChargesValidateRef: any = null;

  private _setRef = (ref) => {
    this.additionalChargesValidateRef = ref;
  };

  private _onNonFaceToFaceChange = (e) => {
    this.setState({ isNonFaceToFace: e.target.checked });
  };

  private _onClose = () => {
    this.setState({
      isSaving: false,
      isLoading: false,
      newComment: null,
      selectedLineItems: [],
      newSelectedService: null,
      userServiceAgreementLineItems: null,
      isNonFaceToFace: false,
      autoChargeLineItems: [],
      step: 1,
      teamMemberCustomerRatio: null,
    });
    this.props.onClose();
  };

  private _onContinue = async () => {
    const { form } = this.props;
    const { userServiceAgreementLineItems, selectedLineItems, locationMmmGroup, locationState, step } = this.state;
    if (step === 1) {
      let isFormValid = true;
      form.validateFields((err) => {
        if (err) {
          isFormValid = false;
        }
      });
      if (isFormValid) {
        try {
          const selectedService = this.props.selectedService
            ? this.props.selectedService
            : _.find(this.props.servicesLite, (service) => service.serviceId === form.getFieldValue('serviceId'));
          const newSelectedService = selectedService ? selectedService : this.state.newSelectedService;
          this._setMmmGroupAndState(newSelectedService);
          this.setState(
            {
              newSelectedService,
              newComment: form.getFieldValue('comment'),
              teamMemberCustomerRatio: { ndis: form.getFieldValue('teamMemberCustomerRatio') },
              step: 2,
            },
            async () => {
              if (!this.props.isEdit) {
                this.setState({ isLoading: true });
                const userServiceAgreementLineItems = await this.props.doFetchServiceAgreementServiceBillingLineItems({
                  serviceId: newSelectedService.serviceId,
                  customerSupportLevel: this.props.selectedCustomer.customerSupportLevel,
                  paymentSourceType: this.props.paymentSourceType,
                });
                this.setState({
                  userServiceAgreementLineItems: userServiceAgreementLineItems,
                  isLoading: false,
                });
              }
            },
          );
        } catch (e) {
          console.log(e);
          notification.error({ message: 'Oops! Something went wrong, please try again' });
        }
      }
    } else if (step === 2) {
      try {
        if (this.props.isEdit) {
          const supportLineItems = _.chain(userServiceAgreementLineItems)
            .filter((lineItem) => {
              return _.find(
                selectedLineItems,
                (selectedLineItem) => selectedLineItem.supportItemNumber === lineItem.supportItemNumber,
              );
            })
            .map((lineItem) => {
              const item = ndisHelper.getBySupportItemNumber(lineItem.supportItemNumber);
              const agreementPrice =
                locationMmmGroup === MmmGroup.NonRemote
                  ? CommonUtils.findStandardPrice(lineItem, locationState, 'API')
                  : locationMmmGroup === MmmGroup.Remote
                  ? lineItem.price.remotePrice
                  : lineItem.price.veryRemotePrice;
              return { ...lineItem, agreementPrice, description: item ? item.SupportItem : '-' };
            })
            .value();
          this.setState({ supportLineItems: supportLineItems, step: 3 });
        } else {
          let isFormValid = true;
          form.validateFields((err) => {
            if (err) {
              isFormValid = false;
            }
          });
          if (isFormValid) {
            const supportLineItems = _.chain(userServiceAgreementLineItems)
              .filter((lineItem) => {
                return _.find(
                  selectedLineItems,
                  (selectedLineItem) => selectedLineItem.supportItemNumber === lineItem.supportItemNumber,
                );
              })
              .map((lineItem) => {
                const item = ndisHelper.getBySupportItemNumber(lineItem.supportItemNumber);
                const agreementPrice =
                  locationMmmGroup === MmmGroup.NonRemote
                    ? CommonUtils.findStandardPrice(lineItem, locationState, 'API')
                    : locationMmmGroup === MmmGroup.Remote
                    ? lineItem.price.remotePrice
                    : lineItem.price.veryRemotePrice;
                return {
                  ...lineItem,
                  agreementPrice,
                  description: item ? item.SupportItem : '-',
                };
              })
              .value();
            this.setState({ supportLineItems: supportLineItems, step: 3 });
          }
        }
      } catch (e) {
        console.log(e);
        notification.error({ message: 'Oops! Something went wrong, please try again' });
      }
    }
  };

  private _goPreviousStep = () => {
    this.setState((prevState) => {
      return { step: prevState.step - 1 };
    });
  };

  private _addAutoChargeLineItem = () => {
    const newAutoChargeLineItem: IBillingLineItem = {
      bookingBillingLineItemId: uuidv4(),
      startDateTime: null,
      endDateTime: null,
      supportItemNumber: null,
      supportItemNumberArray: [],
      qty: 1,
      unitPrice: 0,
      unit: null,
      description: null,
      total: 0,
      paymentMethod: null,
      ruleType: null,
      isTravel: false,
      travelDistance: 0,
      mileagePrice: 0,
      claimType: '',
      isEditing: true,
      paymentSourceType: PaymentSourceType.NDIS,
    };

    this.setState({ autoChargeLineItems: [...this.state.autoChargeLineItems, newAutoChargeLineItem] });
  };

  private _updateAutoChargeLineItem = (autoChargeItem, index) => {
    const autoChargeLineItems = this.state.autoChargeLineItems;
    autoChargeLineItems[index] = autoChargeItem;
    this.setState({ autoChargeLineItems: autoChargeLineItems });
  };

  private _removeAutoChargeLineItem = (index) => {
    this.setState({
      autoChargeLineItems: this.state.autoChargeLineItems.filter((item, arrayIndex) => {
        return index !== arrayIndex;
      }),
    });
  };

  private _onSave = async () => {
    const { selectedService, paymentSourceType } = this.props;
    const {
      userServiceAgreementLineItems,
      selectedLineItems,
      newComment,
      locationMmmGroup,
      locationState,
      teamMemberCustomerRatio,
      isNonFaceToFace,
      autoChargeLineItems,
    } = this.state;
    this.setState({ isSaving: true });

    try {
      if (this.props.isEdit) {
        let isFormValid = true;
        let nonFaceToFaceMinutes;
        if (isNonFaceToFace) {
          nonFaceToFaceMinutes = this.additionalChargesValidateRef.onValidateNonFaceToFaceMinutes();
          if (!nonFaceToFaceMinutes) {
            isFormValid = false;
          }
        }
        if (autoChargeLineItems.length > 0) {
          const isAutoChargedLineItemsValide = await this.additionalChargesValidateRef.onValidateAutoChargeItems();
          isFormValid = isAutoChargedLineItemsValide ? isFormValid : false;
        }
        if (isFormValid) {
          const supportLineItems = _.chain(userServiceAgreementLineItems)
            .filter((lineItem) => {
              return _.find(
                selectedLineItems,
                (selectedLineItem) => selectedLineItem.supportItemNumber === lineItem.supportItemNumber,
              );
            })
            .map((lineItem) => {
              const item = ndisHelper.getBySupportItemNumber(lineItem.supportItemNumber);
              const agreementPrice =
                locationMmmGroup === MmmGroup.NonRemote
                  ? CommonUtils.findStandardPrice(lineItem, locationState, 'API')
                  : locationMmmGroup === MmmGroup.Remote
                  ? lineItem.price.remotePrice
                  : lineItem.price.veryRemotePrice;
              return { ...lineItem, agreementPrice, description: item ? item.SupportItem : '-' };
            })
            .value();

          //additional Charges

          const autoChargedBillingItems = _.map(autoChargeLineItems, (item) => {
            return {
              price: item.total ? item.total : item.price,
              unitPrice: typeof item.unitPrice === 'string' ? parseFloat(item.unitPrice) : item.unitPrice,
              unit: item.unit,
              supportItemNumber: item.supportItemNumber,
              qty: item.qty,
              claimType: item.claimType,
            };
          });

          const additionalCharges = {
            isChargeNonFaceToFace: isNonFaceToFace,
            nonFaceToFaceMinutes: nonFaceToFaceMinutes ? nonFaceToFaceMinutes : 0,
            autoChargedBillingItems: autoChargedBillingItems ? autoChargedBillingItems : [],
          };
          this.props.editService({
            serviceId: selectedService.serviceId,
            serviceClaimConfig: selectedService.serviceClaimConfig,
            serviceName: selectedService.serviceName,
            comment: newComment,
            numberOfItems: supportLineItems ? supportLineItems.length : 0,
            lineItems: supportLineItems,
            mmmGroup: selectedService.mmmGroup,
            state: selectedService.state,
            serviceDirection: selectedService.serviceDirection,
            timezone: selectedService.timezone,
            teamMemberCustomerRatio: selectedService.serviceType === ServiceType.GROUP ? teamMemberCustomerRatio : null,
            serviceType: selectedService.serviceType,
            paymentSourceType,
            additionalCharges,
          });
          this._onClose();
          notification.success({ message: 'Service edited successfully.' });
        }
      } else {
        const { newSelectedService } = this.state;
        let isFormValid = true;
        let nonFaceToFaceMinutes;
        if (isNonFaceToFace) {
          nonFaceToFaceMinutes = this.additionalChargesValidateRef.onValidateNonFaceToFaceMinutes();
          if (!nonFaceToFaceMinutes) {
            isFormValid = false;
          }
        }
        if (autoChargeLineItems.length > 0) {
          const isAutoChargedLineItemsValide = await this.additionalChargesValidateRef.onValidateAutoChargeItems();
          isFormValid = isAutoChargedLineItemsValide ? isFormValid : false;
        }

        if (isFormValid) {
          const supportLineItems = _.chain(userServiceAgreementLineItems)
            .filter((lineItem) => {
              return _.find(
                selectedLineItems,
                (selectedLineItem) => selectedLineItem.supportItemNumber === lineItem.supportItemNumber,
              );
            })
            .map((lineItem) => {
              const item = ndisHelper.getBySupportItemNumber(lineItem.supportItemNumber);
              const agreementPrice =
                locationMmmGroup === MmmGroup.NonRemote
                  ? CommonUtils.findStandardPrice(lineItem, locationState, 'API')
                  : locationMmmGroup === MmmGroup.Remote
                  ? lineItem.price.remotePrice
                  : lineItem.price.veryRemotePrice;
              return {
                ...lineItem,
                agreementPrice,
                description: item ? item.SupportItem : '-',
              };
            })
            .value();
          const autoChargedBillingItems = _.map(autoChargeLineItems, (item) => {
            return {
              price: item.total,
              unitPrice: typeof item.unitPrice === 'string' ? parseFloat(item.unitPrice) : item.unitPrice,
              unit: item.unit,
              supportItemNumber: item.supportItemNumber,
              qty: item.qty,
              claimType: item.claimType,
            };
          });

          const additionalCharges = {
            isChargeNonFaceToFace: isNonFaceToFace,
            nonFaceToFaceMinutes: nonFaceToFaceMinutes ? nonFaceToFaceMinutes : 0,
            autoChargedBillingItems: autoChargedBillingItems ? autoChargedBillingItems : [],
          };
          this.props.addNewService({
            serviceId: newSelectedService.serviceId,
            serviceClaimConfig: newSelectedService.serviceClaimConfig,
            serviceName: newSelectedService.serviceName,
            comment: newComment,
            numberOfItems: supportLineItems ? supportLineItems.length : 0,
            lineItems: supportLineItems,
            mmmGroup: newSelectedService.mmmGroup,
            state: newSelectedService.state,
            serviceDirection: newSelectedService.serviceDirection,
            timezone: newSelectedService.timezone,
            teamMemberCustomerRatio:
              newSelectedService.serviceType === ServiceType.GROUP ? teamMemberCustomerRatio : null,
            serviceType: newSelectedService.serviceType,
            paymentSourceType,
            additionalCharges,
          });
          this._onClose();
        }
      }
      this.setState({ isSaving: false });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again' });
      this.setState({ isSaving: false });
    }
  };

  private _setMmmGroupAndState = (service) => {
    const { selectedCustomer } = this.props;

    let locationMmmGroup = null;
    let locationState = null;
    if (service) {
      if (service.serviceDirection === ServiceLocationType.FIXEDVENUE) {
        locationMmmGroup = service.mmmGroup ? service.mmmGroup : MmmGroup.NonRemote;
        locationState = service.state;
      } else if (service.serviceDirection === ServiceLocationType.CUSTOMERLOCATION) {
        const customerPrimaryAddress = _.find(selectedCustomer.addresses, (address) => address.isPrimary);
        if (customerPrimaryAddress && customerPrimaryAddress.state) {
          locationMmmGroup = customerPrimaryAddress.mmmGroup ? customerPrimaryAddress.mmmGroup : MmmGroup.NonRemote;
          locationState = customerPrimaryAddress.state;
        } else {
          locationMmmGroup = MmmGroup.NonRemote;
          locationState = null;
        }
      }
    }
    this.setState({ locationMmmGroup, locationState });
  };

  private _changeTeamMemberCustomerRatio = (event) => {
    this.setState({ teamMemberCustomerRatio: { ndis: event } });
  };

  private _changeSelectedService = (serviceId) => {
    this.setState({
      newSelectedService: _.find(this.props.servicesLite, (service) => service.serviceId === serviceId),
    });
  };

  componentDidMount = async () => {
    const { selectedService, paymentSourceType } = this.props;
    await this.props.doFetchServicesLite({ paymentSourceType: paymentSourceType });
    if (this.props.selectedService) {
      this.setState({ newSelectedService: this.props.selectedService });
    }
    if (this.props.isEdit) {
      this.setState({ step: selectedService && selectedService.serviceType === ServiceType.GROUP ? 3 : 2 });
    }
  };

  componentDidUpdate = async (
    prevProps: Readonly<IServiceItemInputModalProps>,
    prevState: Readonly<IServiceItemInputModalState>,
    snapshot?: any,
  ) => {
    const { selectedCustomer, selectedService, isEdit, paymentSourceType } = this.props;
    const { newSelectedService } = this.state;

    if (prevProps.paymentSourceType !== paymentSourceType && isEdit) {
      await this.props.doFetchServicesLite({ paymentSourceType: this.props.paymentSourceType });
      if (this.props.selectedService) {
        this.setState({ newSelectedService: this.props.selectedService });
      }
    }

    // If we are editing an existing service
    if (selectedService && isEdit && !prevProps.isOpen && this.props.isOpen) {
      this._setMmmGroupAndState(selectedService);
      this.setState({
        isLoading: true,
      });
      const userServiceAgreementLineItems = await this.props.doFetchServiceAgreementServiceBillingLineItems({
        serviceId: selectedService.serviceId,
        customerSupportLevel: selectedCustomer.customerSupportLevel,
        paymentSourceType: paymentSourceType,
      });

      const selectedLineItems = _.filter(selectedService.lineItems, (selectedLineItem) => {
        return _.find(userServiceAgreementLineItems, (lineItem) => {
          return selectedLineItem.supportItemNumber === lineItem.supportItemNumber;
        });
      });

      const unselectedLineItems = _.filter(userServiceAgreementLineItems, (lineItem) => {
        return !_.find(selectedService.lineItems, (selectedLineItem) => {
          return selectedLineItem.supportItemNumber === lineItem.supportItemNumber;
        });
      });

      this.setState({
        selectedLineItems: selectedService.lineItems,
        teamMemberCustomerRatio:
          selectedService.serviceType === ServiceType.GROUP ? selectedService.teamMemberCustomerRatio : null,
        userServiceAgreementLineItems: [...selectedLineItems, ...unselectedLineItems],
        isLoading: false,
        isNonFaceToFace: selectedService.additionalCharges.isChargeNonFaceToFace,
        autoChargeLineItems: selectedService.additionalCharges.autoChargedBillingItems,
        nonFaceToFaceMinutes: selectedService.additionalCharges.nonFaceToFaceMinutes,
      });
    }
  };

  render() {
    const { isOpen, selectedService, isEdit, form, paymentSourceType } = this.props;
    const {
      step,
      newSelectedService,
      newComment,
      selectedLineItems,
      userServiceAgreementLineItems,
      locationMmmGroup,
      locationState,
      teamMemberCustomerRatio,
      isNonFaceToFace,
      nonFaceToFaceMinutes,
      autoChargeLineItems,
    } = this.state;
    const { getFieldDecorator } = form;

    let lineItems = [];
    if (autoChargeLineItems) {
      lineItems = _.map(autoChargeLineItems, (item) => {
        const supportItem = item.supportItemNumber
          ? ndisHelper.getBySupportItemNumber(item.supportItemNumber).SupportItem
          : null;
        return {
          ...item,
          paymentSourceType: this.props.paymentSourceType,
          isEditing: this.props.isEdit,
          supportItem,
        };
      });
    }

    const noItems = _.isEmpty(userServiceAgreementLineItems);
    let title;
    if (step === 1) {
      title = (
        <>
          {!isEdit ? 'Add' : 'Edit'} <b>service</b>
        </>
      );
    } else if (step === 2) {
      title = (
        <>
          Select the <b>line items</b> for this service
        </>
      );
    } else if (step === 3) {
      title = <>Auto-charge line items (optional)</>;
    }

    return (
      <ActionModal isOpen={isOpen} onClose={this._onClose} width={'x3-large'} title={title}>
        {step === 1 && (
          <>
            <div className="mb-large">
              <Text>
                The service selected will be added to the customers <b>service agreement</b>.
              </Text>
            </div>

            <div className="mb-large">
              <div className="mb-x-small">
                <Text weight="bold">Service</Text>
              </div>
              <Form.Item>
                {getFieldDecorator('serviceId', {
                  initialValue:
                    selectedService && isEdit
                      ? selectedService.serviceName
                      : newSelectedService
                      ? newSelectedService.serviceName
                      : undefined,
                  rules: [
                    {
                      required: true,
                      message: 'Select a service',
                    },
                  ],
                })(
                  <Select
                    placeholder="Select..."
                    size={'large'}
                    style={{ width: '396px' }}
                    showSearch={true}
                    optionFilterProp={'children'}
                    onChange={this._changeSelectedService}
                    disabled={isEdit}
                  >
                    {_.map(
                      _.filter(
                        this.props.servicesLite,
                        (service) =>
                          !_.find(this.props.existingServices, (existing) => existing.serviceId === service.serviceId),
                      ),
                      (service) => (
                        <Option key={service.serviceId}>{service.serviceName}</Option>
                      ),
                    )}
                  </Select>,
                )}
              </Form.Item>
            </div>

            {((selectedService && selectedService.serviceType === ServiceType.GROUP) ||
              (newSelectedService && newSelectedService.serviceType === ServiceType.GROUP)) && (
              <div className="mb-large">
                <div className="mb-x-small">
                  <Text weight="bold">Preferred team member to customer ratio</Text>
                </div>
                <Form.Item>
                  {getFieldDecorator('teamMemberCustomerRatio', {
                    initialValue:
                      selectedService && selectedService.teamMemberCustomerRatio
                        ? selectedService.teamMemberCustomerRatio.ndis
                        : undefined,
                    rules: [
                      {
                        required: true,
                        message: 'Select a service',
                      },
                    ],
                  })(
                    <Select
                      placeholder={'Select a ratio...'}
                      onChange={this._changeTeamMemberCustomerRatio}
                      style={{ width: '200px' }}
                      size={'large'}
                    >
                      {_.map(TeamMemberCustomerRatio, (ratio) => {
                        return <Select.Option value={ratio}>{ratio}</Select.Option>;
                      })}
                    </Select>,
                  )}
                </Form.Item>
              </div>
            )}

            <div className="mb-large">
              <div className="mb-x-small">
                <Text weight="bold">Comments (optional)</Text>
              </div>
              {getFieldDecorator('comment', {
                initialValue: selectedService && isEdit ? selectedService.comment : newComment ? newComment : null,
              })(
                <TextArea
                  placeholder="Add comments to this item, eg a brief description of what support items you'll be charging against."
                  rows={4}
                  maxLength={500}
                  style={{ maxHeight: '300px' }}
                />,
              )}
            </div>

            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onContinue}>
                Continue
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 2 && (
          <>
            <div className="mb-large">
              <Text>
                Please select the line items that you wish to charge this customer for this service. You can adjust the
                priority order of the line items using the arrows. Line items at the top of the list will be the first
                ones used when a booking is created for this service.
              </Text>
            </div>

            <NDISLineItemGrid
              lineItems={userServiceAgreementLineItems}
              selectedLineItems={selectedLineItems}
              displayMode={'ADD'}
              isSelectable
              isLoading={this.state.isLoading}
              isSequenceAdjustable
              onClickLineItemCheckbox={(selectedLineItems) => this.setState({ selectedLineItems: selectedLineItems })}
              onSequenceChange={(lineItems) => this.setState({ userServiceAgreementLineItems: lineItems })}
              mmmGroup={locationMmmGroup}
              state={locationState}
              paymentSourceType={this.props.paymentSourceType}
            />
            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this._goPreviousStep}>
                Back
              </SecondaryButton>
              <PrimaryButton
                size="large"
                onClick={this.props.paymentSourceType === PaymentSourceType.NDIS ? this._onContinue : this._onSave}
              >
                {this.props.paymentSourceType === PaymentSourceType.NDIS ? 'Continue' : 'Finish'}
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 3 && (
          <>
            <div className="mb-large">
              <Paragraph>
                You can opt to <b>allocate pre-defined line items to be automatically charged</b> to each bookings under
                this service. <br />
                These line items will be <b>automatically added</b> to the billing sections of the customer’s bookings.
              </Paragraph>

              <Paragraph>
                Additionally, you can also set <b>non face-to-face</b> costs that will be automatically charged to the
                customer’s bookings.
                <br /> Non face-to-face time will be calculated by the relevant line item rate{' '}
                <b>based on the time/location of the session.</b>
              </Paragraph>
            </div>

            <AdditionalChargesSection
              wrappedComponentRef={this._setRef}
              isEdit={true}
              isNonFaceToFace={isNonFaceToFace}
              nonFaceToFaceMinutes={nonFaceToFaceMinutes}
              onNonFaceToFaceChange={(e) => this._onNonFaceToFaceChange(e)}
              addAutoChargeLineItem={this._addAutoChargeLineItem}
              updateAutoChargeLineItem={this._updateAutoChargeLineItem}
              removeAutoChargeLineItem={this._removeAutoChargeLineItem}
              mmmGroup={locationMmmGroup}
              state={locationState}
              serviceLineItems={selectedLineItems}
              autoChargeLineItems={lineItems}
              paymentSourceType={this.props.paymentSourceType}
            />

            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this._goPreviousStep}>
                Back
              </SecondaryButton>
              <PrimaryButton size="large" loading={this.state.isSaving} onClick={this._onSave}>
                {!isEdit ? 'Finish' : 'Save'}
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  newServiceAgreement: state.customersStore.newServiceAgreement,
  servicesLite: state.servicesStore.servicesLite,
  selectedCustomer: state.customersStore.selectedCustomer,
  userServiceAgreementBillingLineItems: state.customersStore.userServiceAgreementBillingLineItems,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServicesLite: dispatch.servicesStore.doFetchServicesLite,
  doFetchServiceAgreementServiceBillingLineItems:
    dispatch.customersStore.doFetchServiceAgreementServiceBillingLineItems,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IServiceItemInputModalProps>()(ServiceItemInputModal));
