import React, { Component } from 'react';
import CenteredLayout from 'layouts/CenteredLayout';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import * as H from 'history';
import { Avatar } from 'antd';
import CommonUtils from 'utilities/common-utils';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { TimezoneIndicator } from 'common-components/timezone';
import AssignNewPrimaryOwner from 'views/account-management/components/CompanyDetailModals/AssignNewPrimaryOwnerModal';
import EditDisplayNameModal from 'views/account-management/components/CompanyDetailModals/EditDisplayNameModal';
import EditCompanyAddressModal from 'views/account-management/components/CompanyDetailModals/EditCompanyAddressModal';
import EditTimezoneModal from 'views/account-management/components/CompanyDetailModals/EditTimezoneModal';
import EditBusinessDetailsModal from 'views/account-management/components/CompanyDetailModals/EditBusinessDetailsModal';
import globalConfig from 'variables/global-config';
import Script from 'react-load-script';
import RegistrationDetailView from 'views/account-management/components/RegistrationDetailView';
import EditReplyToEmailModal from './components/CompanyDetailModals/EditReplyToEmailModal';

interface ICompanyDetailsViewProps {
  doFetchCompany: typeof dispatch.companyStore.doFetchCompany;
  getCompanyRegistrationDetails: typeof dispatch.companyStore.getCompanyRegistrationDetails;
  companyData: typeof state.companyStore.companyData;
  companyRegistrationDetails: typeof state.companyStore.companyRegistrationDetails;
  portalUser: typeof state.authStore.portalUser;
  history: H.History;
}

interface ICompanyDetailsViewState {
  isLoading: boolean;
  isLoadingRegistrationDetails: boolean;
  isAssignPrimaryOwnerOpen: boolean;
  isEditDisplayNameOpen: boolean;
  isEditCompanyAddressOpen: boolean;
  isEditTimezoneOpen: boolean;
  isEditBusinessDetailsOpen: boolean;
  errorWithRegistrationDetails: boolean;
  isEditReplyToEmailModalOpen: boolean;
}

class CompanyDetailsView extends Component<ICompanyDetailsViewProps, ICompanyDetailsViewState> {
  state = {
    isLoading: true,
    isLoadingRegistrationDetails: true,
    isAssignPrimaryOwnerOpen: false,
    isEditDisplayNameOpen: false,
    isEditCompanyAddressOpen: false,
    isEditTimezoneOpen: false,
    isEditBusinessDetailsOpen: false,
    errorWithRegistrationDetails: false,
    isEditReplyToEmailModalOpen: false
  };

  private _goToLandingPage = () => {
    this.props.history.push('/account/landing');
  };

  private _openAssignPrimaryOwner = () => {
    this.setState({ isAssignPrimaryOwnerOpen: true });
  };

  private _closeAssignPrimaryOwner = () => {
    this.setState({ isAssignPrimaryOwnerOpen: false });
  };

  private _openEditReplyToEmailModal = () => {
    this.setState({ isEditReplyToEmailModalOpen: true });
  };

  private _closeEditReplyToEmailModal = () => {
    this.setState({ isEditReplyToEmailModalOpen: false });
  };

  private _openEditDisplayName = () => {
    this.setState({ isEditDisplayNameOpen: true });
  };

  private _closeEditDisplayName = () => {
    this.setState({ isEditDisplayNameOpen: false });
  };

  private _openEditCompanyAddress = () => {
    this.setState({ isEditCompanyAddressOpen: true });
  };

  private _closeEditCompanyAddress = () => {
    this.setState({ isEditCompanyAddressOpen: false });
  };

  private _openEditTimezone = () => {
    this.setState({ isEditTimezoneOpen: true });
  };

  private _closeEditTimezone = () => {
    this.setState({ isEditTimezoneOpen: false });
  };

  private _openEditBusinessDetails = () => {
    this.setState({ isEditBusinessDetailsOpen: true });
  };

  private _closeEditBusinessDetails = () => {
    this.setState({ isEditBusinessDetailsOpen: false });
  };

  componentDidMount = async () => {
    await this.props.doFetchCompany({});
    this.setState({ isLoading: false });
    try {
      await this.props.getCompanyRegistrationDetails({
        businessRegistrationNumber: this.props.companyData.businessRegistrationNumber,
        businessRegistrationNumberType: this.props.companyData.businessRegistrationNumber.length === 9 ? 'ACR' : 'ABN',
        isLookup: false
      });
      this.setState({ isLoadingRegistrationDetails: false });
    } catch (e) {
      if (e.meta.message === 'Business registration number does not exist') {
        this.setState({ isLoadingRegistrationDetails: false });
      } else {
        this.setState({ errorWithRegistrationDetails: true, isLoadingRegistrationDetails: false });
      }
    }
  };

  render() {
    const { companyData, companyRegistrationDetails } = this.props;

    return (
      <CenteredLayout backgroundColor="white" bordered={true} className={'ph-small'}>
        <Script
          url={'https://maps.googleapis.com/maps/api/js?key=' + globalConfig.googleMapApiKey + '&libraries=places'}
        />
        <AssignNewPrimaryOwner
          isOpen={this.state.isAssignPrimaryOwnerOpen}
          onClose={this._closeAssignPrimaryOwner}
          goToLandingPage={this._goToLandingPage}
          currentPrimaryOwner={
            companyData && {
              firstName: companyData.firstName,
              lastName: companyData.lastName,
              attachmentUrl: companyData.attachmentUrl
            }
          }
        />
        <EditDisplayNameModal
          isOpen={this.state.isEditDisplayNameOpen}
          onClose={this._closeEditDisplayName}
          currentDisplayName={companyData && companyData.displayName}
        />
        <EditCompanyAddressModal
          isOpen={this.state.isEditCompanyAddressOpen}
          onClose={this._closeEditCompanyAddress}
          address={companyData && companyData.address ? companyData.address : ''}
        />
        <EditTimezoneModal
          isOpen={this.state.isEditTimezoneOpen}
          onClose={this._closeEditTimezone}
          currentTimezone={companyData && companyData.timezone}
        />
        <EditBusinessDetailsModal
          isOpen={this.state.isEditBusinessDetailsOpen}
          onClose={this._closeEditBusinessDetails}
        />
        <EditReplyToEmailModal
          isOpen={this.state.isEditReplyToEmailModalOpen}
          onClose={this._closeEditReplyToEmailModal}
        />
        {this.state.isLoading ? (
          <SpinningLoader size={100} message={'Fetching company details...'} />
        ) : (
          <div className={'pb-x4-large'}>
            <div className="mt-x-large mb-medium">
              <Title level={3} weight="regular" className="mv-none">
                <span className={'cursor-pointer text-color-blue-action'} onClick={this._goToLandingPage}>
                  {'Account >'}
                </span>{' '}
                Company Details
              </Title>
            </div>
            <div className="mt-x-large">
              <Title level={4}>Primary Owner</Title>
              <div className="bordered border-standard-gray p-medium inline-block mv-large" style={{ width: '500px' }}>
                <div className={'flex-row align-center'}>
                  <Avatar size="large" shape={'square'} src={companyData.attachmentUrl} className={'mr-medium'} />
                  <div>
                    <Text>
                      {companyData.firstName} {companyData.lastName}
                    </Text>
                    <br />
                    <Text color={'secondary'}>
                      {companyData.userLocality ? companyData.userLocality : 'No location set'} -{' '}
                      {companyData.userEmail ? companyData.userEmail : 'No email set'}
                    </Text>
                  </div>
                </div>
                <div className={'mt-small'}>
                  <HyperlinkButton onClick={this._openAssignPrimaryOwner}>Assign new Primary Owner...</HyperlinkButton>
                </div>
              </div>
              <Paragraph>
                <b>You</b> are the Primary Owner of this GoodHuman instance.
              </Paragraph>
              <Paragraph>
                There is only ever one primary owner per GoodHuman instance.
                <br />
                As the Primary Owner you can make changes to company details or relinquish this role by assigning a new
                primary owner.
              </Paragraph>
              <Paragraph>
                Visit the <HyperlinkButton>Help Center</HyperlinkButton> to learn more.
              </Paragraph>
            </div>
            <div className="mt-x-large">
              <Title level={4}>Company</Title>
              <div className={'mt-large'}>
                <SubTitle>Display name</SubTitle>
                <Text>Public name of your business. Shown to customers and used in the workspace.</Text>
                <br />
                <div
                  className="bordered p-medium border-standard-gray inline-block mt-large"
                  style={{ width: '500px' }}
                >
                  <Text size={'x-large'}>{companyData.serviceProviderName}</Text>
                  <br />
                  <div className={'mt-small'}>
                    <HyperlinkButton onClick={this._openEditDisplayName}>Change display name...</HyperlinkButton>
                  </div>
                </div>
              </div>
              <div className={'mt-large'}>
                <SubTitle>Business address</SubTitle>
                <Text>Primary location of you business. Used in invoices.</Text>
                <br />
                <div
                  className="bordered p-medium border-standard-gray mt-large flex-row justify-between"
                  style={{ width: '500px' }}
                >
                  <div>
                    <Text style={{ whiteSpace: 'pre-line' }}>
                      {companyData.address && companyData.address.fullAddress !== ''
                        ? companyData.address.fullAddress
                        : 'Not set.'}
                    </Text>
                  </div>
                  <HyperlinkButton onClick={this._openEditCompanyAddress}>Modify...</HyperlinkButton>
                </div>
              </div>
              <div className={'mt-large'}>
                <SubTitle>Registered business details</SubTitle>
                <div className="bordered p-medium border-standard-gray" style={{ width: '500px' }}>
                  <div className={'mb-small flex-row justify-between'}>
                    <div>
                      <SubTitle>Country</SubTitle>
                      <Text>Australia</Text>
                    </div>
                    <HyperlinkButton onClick={this._openEditBusinessDetails}>Modify...</HyperlinkButton>
                  </div>
                  <SubTitle>ABN/ACN Number</SubTitle>
                  <Text>{companyData.businessRegistrationNumber}</Text>
                  <div className="p-medium bg-secondary width-full mt-small">
                    {this.state.isLoadingRegistrationDetails ? (
                      <SpinningLoader size={50} message={''} />
                    ) : this.state.errorWithRegistrationDetails ? (
                      'An error occurred while retrieving the details.'
                    ) : companyRegistrationDetails ? (
                      <RegistrationDetailView companyRegistrationDetails={companyRegistrationDetails} />
                    ) : (
                      'No match found.'
                    )}
                  </div>
                </div>
              </div>
              <div className={'mt-large'}>
                <SubTitle>Default timezone</SubTitle>
                <Text>Default timezone for your business.</Text>
                <br />
                <div className="bordered p-medium border-standard-gray mt-large" style={{ width: '500px' }}>
                  <div>
                    <Text>{<TimezoneIndicator timezone={companyData.timezone} />}</Text>
                  </div>
                  <div className={'mt-small'}>
                    <HyperlinkButton onClick={this._openEditTimezone}>Change default timezone...</HyperlinkButton>
                  </div>
                </div>
              </div>
              <div className={'mt-large'}>
                <SubTitle>reply-to email address</SubTitle>
                <Text>
                  This email address will be used to handle any replies sent from users who click reply to the
                  notifications emails that we send out from GoodHuman.
                </Text>
                <br />
                <div className="bordered p-medium border-standard-gray mt-large" style={{ width: '500px' }}>
                  <div>
                    <Text weight="bold">GoodHuman replies</Text>
                  </div>
                  <div className={'mt-small'}>
                    <Text>
                      {companyData && companyData.replyEmailConfig
                        ? companyData.replyEmailConfig.replyEmailAddress
                        : ''}
                    </Text>
                  </div>
                  <div className={'mt-small'}>
                    <HyperlinkButton onClick={this._openEditReplyToEmailModal}>
                      Change reply-to details...
                    </HyperlinkButton>
                  </div>
                </div>
              </div>
              <div className={'mt-large'}>
                <SubTitle>Storage capacity</SubTitle>
                <Text>How much storage your business is using in GoodHuman.</Text>
                <br />
                <div
                  className="bordered p-medium border-standard-gray flex-row justify-between"
                  style={{ width: '500px' }}
                >
                  <Text size={'x-large'}>{Number(companyData.totalUsedStorageSizeGb).toFixed(2)}GB</Text>
                </div>
              </div>
            </div>
          </div>
        )}
      </CenteredLayout>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyData: state.companyStore.companyData,
  companyRegistrationDetails: state.companyStore.companyRegistrationDetails,
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchCompany: dispatch.companyStore.doFetchCompany,
  getCompanyRegistrationDetails: dispatch.companyStore.getCompanyRegistrationDetails
});

export default connect(mapState, mapDispatch)(CompanyDetailsView);
