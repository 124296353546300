import React, { Component } from 'react';
import CenteredLayout from 'layouts/CenteredLayout';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import * as H from 'history';
import { Col, Row, notification } from 'antd';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import ExportCustomerDataModal from 'views/account-management/components/ExportDataModals/ExportCustomerDataModal';
import ExportBookingDataModal from 'views/account-management/components/ExportDataModals/ExportBookingDataModal';
import PermissionUtils from 'utilities/permission-utils';
import ExportTeamMemberGeneralInfoDataModal from './components/ExportDataModals/ExportTeamMemberGeneralInfoDataModal';
import ExportTeamMemberTagsDataModal from './components/ExportDataModals/ExportTeamMemberTagsDataModal';
import ExportTeamMemberAvailabilitiesDataModal from './components/ExportDataModals/ExportTeamMemberAvailabilitiesDataModal';
import ExportTeamMemberDocumentDataModal from './components/ExportDataModals/ExportTeamMemberDocumentDataModal';
import ExportSettingsNDIADebtorIdModal from './components/ExportDataModals/ExportSettingsNDIADebtorIdModal';

interface IAccountExportDataViewProps {
  doFetchCompanyLite: typeof dispatch.companyStore.doFetchCompanyLite;
  doGetNDIADebtorId: typeof dispatch.accountStore.doGetNDIADebtorId;
  portalUser: typeof state.authStore.portalUser;
  history: H.History;
}

interface IAccountExportDataViewState {
  isLoading: boolean;
  isExportCustomerDataModalOpen: boolean;
  isExportBookingDataModalOpen: boolean;
  isExportTeamMemberGeneralInfoDataModalOpen: boolean;
  isExportTeamMemberTagsModalOpen: boolean;
  isExportTeamMemberAvailabilitiesDataModalOpen: boolean;
  isExportTeamMemberDocumentModalOpen: boolean;
  isExportSettingsNDIADebtorIdModalOpen: boolean;
  ndiaDebtorId: string;
}

class AccountExportDataView extends Component<IAccountExportDataViewProps, IAccountExportDataViewState> {
  state = {
    isLoading: true,
    isExportCustomerDataModalOpen: false,
    isExportBookingDataModalOpen: false,
    isExportTeamMemberGeneralInfoDataModalOpen: false,
    isExportTeamMemberTagsModalOpen: false,
    isExportTeamMemberAvailabilitiesDataModalOpen: false,
    isExportTeamMemberDocumentModalOpen: false,
    isExportSettingsNDIADebtorIdModalOpen: false,
    ndiaDebtorId: null
  };

  private _goToLandingPage = () => {
    this.props.history.push('/account/landing');
  };

  private _openExportCustomerDataModal = () => {
    this.setState({ isExportCustomerDataModalOpen: true });
  };

  private _closeExportCustomerDataModal = async () => {
    this.setState({ isExportCustomerDataModalOpen: false });
  };

  private _openExportBookingDataModal = () => {
    this.setState({ isExportBookingDataModalOpen: true });
  };

  private _closeExportBookingDataModal = async () => {
    this.setState({ isExportBookingDataModalOpen: false });
  };

  private _openExportTeamMemberGeneralInfoDataModal = () => {
    this.setState({ isExportTeamMemberGeneralInfoDataModalOpen: true });
  };

  private _openExportTeamMemberTagsModal = () => {
    this.setState({ isExportTeamMemberTagsModalOpen: true });
  };

  private _closeExportTeamMemberGeneralInfoDataModal = async () => {
    this.setState({ isExportTeamMemberGeneralInfoDataModalOpen: false });
  };

  private _closeExportTeamMemberTagsModal = () => {
    this.setState({ isExportTeamMemberTagsModalOpen: false });
  };

  private _openExportTeamMemberAvailabilitiesDataModal = () => {
    this.setState({ isExportTeamMemberAvailabilitiesDataModalOpen: true });
  };

  private _closeExportTeamMemberAvailabilitiesDataModal = async () => {
    this.setState({ isExportTeamMemberAvailabilitiesDataModalOpen: false });
  };

  private _openExportTeamMemberDocumentModal = () => {
    this.setState({ isExportTeamMemberDocumentModalOpen: true });
  };

  private _closeExportTeamMemberDocumentModal = async () => {
    this.setState({ isExportTeamMemberDocumentModalOpen: false });
  };

  private _openExportSettingsNDIADebtorIdModal = () => {
    this.setState({ isExportSettingsNDIADebtorIdModalOpen: true });
  };

  private _closeExportSettingsNDIADebtorIdModal = () => {
    this.setState({ isExportSettingsNDIADebtorIdModalOpen: false });
  };

  private _onUpdateNDIADebtorId = (newNdiaDebtorId) => {
    this.setState({ ndiaDebtorId: newNdiaDebtorId });
  };

  componentDidMount = async () => {
    const { portalUser, history, doGetNDIADebtorId } = this.props;
    this.setState({ isLoading: false });

    if (!PermissionUtils.validatePermission('ExportPlatformData', portalUser.permissions.permissionRoles)) {
      history.push('/access-denied');
    }
    try {
      const result: any = await doGetNDIADebtorId();
      if (result) {
        this.setState({ ndiaDebtorId: result.ndiaDebtorId });
      }
    } catch {
      notification.error({ message: 'Could not fetch NDIA Debtor ID' });
    }
  };

  render() {
    const { ndiaDebtorId } = this.state;
    return (
      <CenteredLayout backgroundColor="white" bordered={true} className={'ph-small'}>
        <ExportSettingsNDIADebtorIdModal
          ndiaDebtorId={ndiaDebtorId ? ndiaDebtorId : ''}
          isOpen={this.state.isExportSettingsNDIADebtorIdModalOpen}
          onClose={this._closeExportSettingsNDIADebtorIdModal}
          onUpdateNDIADebtorId={this._onUpdateNDIADebtorId}
        />
        {this.state.isExportCustomerDataModalOpen && (
          <ExportCustomerDataModal
            isOpen={this.state.isExportCustomerDataModalOpen}
            onClose={this._closeExportCustomerDataModal}
          />
        )}
        {this.state.isExportBookingDataModalOpen && (
          <ExportBookingDataModal
            isOpen={this.state.isExportBookingDataModalOpen}
            onClose={this._closeExportBookingDataModal}
          />
        )}
        {this.state.isExportTeamMemberGeneralInfoDataModalOpen && (
          <ExportTeamMemberGeneralInfoDataModal
            isOpen={this.state.isExportTeamMemberGeneralInfoDataModalOpen}
            onClose={this._closeExportTeamMemberGeneralInfoDataModal}
          />
        )}
        {this.state.isExportTeamMemberTagsModalOpen && (
          <ExportTeamMemberTagsDataModal
            isOpen={this.state.isExportTeamMemberTagsModalOpen}
            onClose={this._closeExportTeamMemberTagsModal}
          />
        )}
        {this.state.isExportTeamMemberAvailabilitiesDataModalOpen && (
          <ExportTeamMemberAvailabilitiesDataModal
            isOpen={this.state.isExportTeamMemberAvailabilitiesDataModalOpen}
            onClose={this._closeExportTeamMemberAvailabilitiesDataModal}
          />
        )}
        {this.state.isExportTeamMemberDocumentModalOpen && (
          <ExportTeamMemberDocumentDataModal
            isOpen={this.state.isExportTeamMemberDocumentModalOpen}
            onClose={this._closeExportTeamMemberDocumentModal}
          />
        )}
        <div className={'pb-x4-large'}>
          <div className="mt-x-large mb-medium">
            <Title level={3} weight="regular" className="mv-none">
              <span className={'cursor-pointer text-color-blue-action'} onClick={this._goToLandingPage}>
                Account {'>'}
              </span>{' '}
              Export Data
            </Title>
          </div>

          <div className="mt-x-large width-2/3">
            <Paragraph>
              This CSV export enables you to export most data from your GoodHuman Workspace, including data for booking,
              services, team member and customer information.
            </Paragraph>
            <Title level={4} weight="regular">
              Export Settings
            </Title>
            <Paragraph>
              Configure the below fields to ensure exports meet the import requirements of your organisations'
              operational systems.
            </Paragraph>
            <div className="bordered p-medium rounded width-half">
              <Row type={'flex'} className={'justify-between align-center'}>
                <Col>
                  <div>
                    <div className={'mb-small'}>
                      <SubTitle weight={'bold'}>NDIA debtor ID</SubTitle>
                    </div>
                    <Text>{ndiaDebtorId ? ndiaDebtorId : 'Not set...'}</Text>
                  </div>
                </Col>
                <Col>
                  <HyperlinkButton onClick={this._openExportSettingsNDIADebtorIdModal}>Edit...</HyperlinkButton>
                </Col>
              </Row>
            </div>
            <div className="mv-x-large">
              <Title level={4} weight="regular">
                Export Datesets
              </Title>
              {/* Content Box */}
              <div className="bordered p-medium rounded">
                <Row type={'flex'} className={'align-center'}>
                  <Col span={20}>
                    <div>
                      <div className={'mb-small'}>
                        <Text weight={'bold'}>Customers</Text>
                      </div>
                      <Text>Export data related to customers</Text>
                    </div>
                  </Col>
                  <Col span={4}>
                    <PrimaryButton onClick={this._openExportCustomerDataModal}>Export data</PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="mv-x-large">
              {/* Content Box */}
              <div className="bordered p-medium rounded">
                <Row type={'flex'} className={'align-center'}>
                  <Col span={20}>
                    <div>
                      <div className={'mb-small'}>
                        <Text weight={'bold'}>Bookings</Text>
                      </div>
                      <Text>Export data related to bookings</Text>
                    </div>
                  </Col>
                  <Col span={4}>
                    <PrimaryButton onClick={this._openExportBookingDataModal}>Export data</PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="mv-x-large">
              {/* Content Box */}
              <div className="bordered p-medium rounded">
                <Row type={'flex'} className={'align-center'}>
                  <Col span={20}>
                    <div>
                      <div className={'mb-small'}>
                        <Text weight={'bold'}>Team Members: General Information</Text>
                      </div>
                      <Text>Export data related to team members' general information</Text>
                    </div>
                  </Col>
                  <Col span={4}>
                    <PrimaryButton onClick={this._openExportTeamMemberGeneralInfoDataModal}>Export data</PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="mv-x-large">
              {/* Content Box */}
              <div className="bordered p-medium rounded">
                <Row type={'flex'} className={'align-center'}>
                  <Col span={20}>
                    <div>
                      <div className={'mb-small'}>
                        <Text weight={'bold'}>Team Members: Availabilities</Text>
                      </div>
                      <Text>Export data related to team members' availabilities</Text>
                    </div>
                  </Col>
                  <Col span={4}>
                    <PrimaryButton onClick={this._openExportTeamMemberAvailabilitiesDataModal}>
                      Export data
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="mv-x-large">
              {/* Content Box */}
              <div className="bordered p-medium rounded">
                <Row type={'flex'} className={'align-center'}>
                  <Col span={20}>
                    <div>
                      <div className={'mb-small'}>
                        <Text weight={'bold'}>Team Members: Tags</Text>
                      </div>
                      <Text>Export data related to team members' tags</Text>
                    </div>
                  </Col>
                  <Col span={4}>
                    <PrimaryButton onClick={this._openExportTeamMemberTagsModal}>Export data</PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="mv-x-large">
              {/* Content Box */}
              <div className="bordered p-medium rounded">
                <Row type={'flex'} className={'align-center'}>
                  <Col span={20}>
                    <div>
                      <div className={'mb-small'}>
                        <Text weight={'bold'}>Team Members: Documents</Text>
                      </div>
                      <Text>Export data related to team members' documents</Text>
                    </div>
                  </Col>
                  <Col span={4}>
                    <PrimaryButton onClick={this._openExportTeamMemberDocumentModal}>Export data</PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </CenteredLayout>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite,
  doGetNDIADebtorId: dispatch.accountStore.doGetNDIADebtorId
});

export default connect(
  mapState,
  mapDispatch
)(AccountExportDataView);
