import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Avatar, Col, Divider, notification, Row } from 'antd';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { SubTitle, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { ISessionConflictShiftSlot, IShiftSlot } from 'interfaces/shift-interfaces';
import { timeZone } from 'interfaces/timezone-type';
import moment from 'moment-timezone';
import { ShiftSlotStatus } from 'utilities/enum-utils';
import { ShiftStatusTag } from '../shift-slot-table/ShiftStatusTag';

interface IBulkConfirmShiftSlotsModalProps {
  isOpen: boolean;
  onClose: () => void;
  onTaskSuccess: () => void;
  toBeConfirmedShiftSlots: IShiftSlot[];
  serviceId: string;
  serviceDateTimeId: string;
  timezone: timeZone;
  conflictShiftSlots: typeof state.groupServiceStore.conflictShiftSlots;
  doFetchConflictShiftSlots: typeof dispatch.groupServiceStore.doFetchConflictShiftSlots;
  doRemoveShiftSlot: typeof dispatch.groupServiceStore.doRemoveShiftSlot;
  doShiftConfirmSlot: typeof dispatch.groupServiceStore.doShiftConfirmSlot;
}

interface IBulkConfirmShiftSlotsModalState {
  isLoading: boolean;
  isConflict: boolean;
  step: number;
  conflictMode: ShiftSlotStatus;
  toBeRemovedShiftSlots: ISessionConflictShiftSlot[];
}

class BulkConfirmShiftSlotsModal extends Component<IBulkConfirmShiftSlotsModalProps, IBulkConfirmShiftSlotsModalState> {
  state = {
    isLoading: false,
    isConflict: false,
    step: 1,
    conflictMode: null,
    toBeRemovedShiftSlots: []
  };

  private _reset = () => {
    this.setState({
      isLoading: false,
      isConflict: false,
      step: 1,
      toBeRemovedShiftSlots: []
    });
  };

  private _onConfirmShiftSlots = async () => {
    if (this.state.isConflict) {
      this.setState({ step: 2 });
      return;
    }

    const { toBeConfirmedShiftSlots, serviceId, serviceDateTimeId, doShiftConfirmSlot, onTaskSuccess } = this.props;
    const supportWorkerAttendanceIds = _.map(
      toBeConfirmedShiftSlots,
      (shiftSlot) => shiftSlot.supportWorkerAttendanceId
    );

    this.setState({ isLoading: true });
    try {
      await doShiftConfirmSlot({ serviceId, serviceDateTimeId, supportWorkerAttendanceIds });
      this.setState({ step: 2 });
      onTaskSuccess();
    } catch (e) {
      console.error(e);
      notification.error({
        message: <Text weight="bold">Bulk actions failed.</Text>,
        description: (
          <Text className="mt-medium">
            Bulk actions for <b>Confirming Team member</b> complete has encounter an error. Please try again.
          </Text>
        )
      });
    }
    this.setState({ isLoading: false });
  };

  private _checkConflict = async () => {
    const { serviceId, serviceDateTimeId, toBeConfirmedShiftSlots, doFetchConflictShiftSlots } = this.props;
    const supportWorkerAttendanceIds = _.map(
      toBeConfirmedShiftSlots,
      (shiftSlot) => shiftSlot.supportWorkerAttendanceId
    );
    this.setState({ isLoading: true });
    await doFetchConflictShiftSlots({ serviceDateTimeId, serviceId, supportWorkerAttendanceIds });
    this.setState({ isLoading: false });
  };

  private _onRemoveTeamMember = async () => {
    const { serviceDateTimeId, serviceId, doRemoveShiftSlot, onTaskSuccess } = this.props;
    const { toBeRemovedShiftSlots } = this.state;
    const supportWorkerAttendanceIds = _.map(
      toBeRemovedShiftSlots,
      (shiftSlot: ISessionConflictShiftSlot) => shiftSlot.supportWorkerAttendanceId
    );
    const payload = {
      serviceDateTimeId,
      serviceId,
      supportWorkerAttendanceIds,
      isRemoveTeamMemberOnly: true
    };
    this.setState({ isLoading: true });
    await doRemoveShiftSlot(payload);
    this.setState({ isLoading: false, step: 3 });
    onTaskSuccess();
  };

  private _getModalTitle = () => {
    const { step, isConflict, conflictMode } = this.state;
    const statusText = conflictMode === ShiftSlotStatus.PENDING ? 'pending' : 'confirmed';
    switch (step) {
      case 1:
        return 'Confirm team members';
      case 2:
        return isConflict ? `Team member assigned to the following ${statusText} shifts` : 'Team members confirmed';
      case 3:
        return 'Team member removed';
      default:
        return null;
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.isOpen && !prevProps.isOpen) {
      this._reset();
      await this._checkConflict();
    }
    if (this.props.conflictShiftSlots !== prevProps.conflictShiftSlots) {
      const conflictMode = this.props.conflictShiftSlots.some(
        (slot) => slot.shiftSlotStatus === ShiftSlotStatus.CONFIRMED
      )
        ? ShiftSlotStatus.CONFIRMED
        : ShiftSlotStatus.PENDING;
      const toBeRemovedShiftSlots = this.props.conflictShiftSlots.filter(
        (slot) => slot.shiftSlotStatus === conflictMode
      );

      const uniqToBeRemovedShiftSlots = _.uniqBy(toBeRemovedShiftSlots, (slot) => slot.supportWorkerAttendanceId);
      this.setState({
        isConflict: this.props.conflictShiftSlots && this.props.conflictShiftSlots.length > 0,
        toBeRemovedShiftSlots: uniqToBeRemovedShiftSlots,
        conflictMode
      });
    }
  };

  render() {
    const { isConflict, step, toBeRemovedShiftSlots } = this.state;
    const itemCount = this.props.toBeConfirmedShiftSlots.length;
    const conflictCount = toBeRemovedShiftSlots.length;

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title={this._getModalTitle()}
        onClose={this.props.onClose}
        canCloseOutside={false}
        width={'large'}
      >
        {step === 1 && (
          <div>
            <Text>
              You are confirming the attendance of{' '}
              <b>
                {itemCount} team member{itemCount === 1 ? '' : 's'}
              </b>{' '}
              on their behalf.
            </Text>
            <SubTitle containerClassName="mt-medium mb-small">Team members being confirmed</SubTitle>
            {_.map(this.props.toBeConfirmedShiftSlots, (shiftSlot) => (
              <div className="flex-row align-center mb-medium">
                <Avatar icon={'user'} shape={'circle'} src={shiftSlot.attachmentUrl} />
                <Text className="ml-small">{shiftSlot.firstName || '' + ' ' + shiftSlot.lastName || ''}</Text>
              </div>
            ))}
            <Text>Are you sure you want to continue?</Text>
            <ActionModalFooter align="right" className="mt-large">
              <SecondaryButton size="large" className="mr-medium" onClick={this.props.onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                size="large"
                onClick={this._onConfirmShiftSlots}
                className="rounded-left"
                loading={this.state.isLoading}
              >
                Confirm team members
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {step === 2 && !isConflict && (
          <div>
            <Text>
              You have confirmed{' '}
              <b>
                {itemCount} team member{itemCount !== 1 && 's'}
              </b>{' '}
              for their shifts.
            </Text>
            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this.props.onClose} className="rounded-left">
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {step === 2 && isConflict && (
          <>
            <div className="anim-fade-in-fast">
              <div className="mb-medium">
                <Text>
                  The selected team members are assigned to the following shifts that have time conflict with the shift
                  you're attempting to confirm them for.
                  <br />
                  <br />
                  If you elect to continue, the team members will be removed from the following shifts that are still
                  pending their approval.
                </Text>
              </div>
              <Row className="text-color-secondary text-uppercase text-size-regular pt-medium">
                <Col span={10} className="ph-medium">
                  Conflicting session/booking
                </Col>
                <Col span={7} className="ph-medium">
                  Team member
                </Col>
                <Col span={7} className="pl-medium">
                  Team member status
                </Col>
              </Row>
              <Divider className="mv-medium mb-none" />
              {_.map(this.state.toBeRemovedShiftSlots, (shiftSlot) => (
                <ConflictResult conflictShiftSlot={shiftSlot} timezone={this.props.timezone} />
              ))}
            </div>

            <ActionModalFooter align="right">
              <div className="flex-row align-center justify-end">
                <SecondaryButton size="large" className="mr-medium" onClick={this.props.onClose}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._onRemoveTeamMember}>
                  Remove team member from shifts
                </PrimaryButton>
              </div>
            </ActionModalFooter>
          </>
        )}

        {step === 3 && isConflict && (
          <div className="anim-fade-in-fast">
            <div className="mb-medium">
              <Text>
                You have removed{' '}
                <b>
                  {conflictCount} team member{conflictCount !== 1 && 's'}
                </b>{' '}
                from this shift.
              </Text>
              <br />
              <br />
              <Text>
                <b>
                  {conflictCount} team member{conflictCount !== 1 && 's'}
                </b>{' '}
                has been removed from the session due to the shift clash.
              </Text>
            </div>

            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this.props.onClose}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

interface IConflictResultProps {
  conflictShiftSlot: ISessionConflictShiftSlot;
  timezone: timeZone;
}

function ConflictResult(props: IConflictResultProps) {
  const { conflictShiftSlot, timezone } = props;
  const { firstName, lastName, startDateTime, endDateTime, shiftSlotStatus } = conflictShiftSlot;
  const workerFullName = `${firstName || ''} ${lastName || ''}`;
  const shiftStartDateTime = moment.tz(startDateTime, timezone);
  const shiftEndDateTime = moment.tz(endDateTime, timezone);

  return (
    <Row type="flex" align="middle" className="pv-medium evenodd">
      <Col span={10} className="ph-medium height-full">
        <div className="flex-col">
          <div>
            <Text color="blue" weight="bold">
              {conflictShiftSlot.serviceName}
            </Text>
          </div>
          <div>
            <Text>{shiftStartDateTime.format('D MMMM, YYYY')}</Text>
          </div>
          <div>
            <Text>
              {shiftStartDateTime.format('h:mm A')} - {shiftEndDateTime.format('h:mm A')}
            </Text>
          </div>
        </div>
      </Col>
      <Col span={7} className="ph-medium  height-full">
        <div className="flex-row height-full align-center">
          <Avatar icon="user" src={conflictShiftSlot.attachmentUrl} className="mr-small" />
          <Text lineHeight={135} weight="bold">
            {workerFullName}
          </Text>
        </div>
      </Col>
      <Col span={7} className="ph-medium  height-full">
        <ShiftStatusTag shiftStatus={shiftSlotStatus} changeUnassigned={false} />
      </Col>
    </Row>
  );
}

const mapState = (state: IRootState) => ({
  conflictShiftSlots: state.groupServiceStore.conflictShiftSlots
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchConflictShiftSlots: dispatch.groupServiceStore.doFetchConflictShiftSlots,
  doRemoveShiftSlot: dispatch.groupServiceStore.doRemoveShiftSlot,
  doShiftConfirmSlot: dispatch.groupServiceStore.doShiftConfirmSlot
});

export default connect(
  mapState,
  mapDispatch
)(BulkConfirmShiftSlotsModal);
