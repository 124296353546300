import React, { Component } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { connect } from 'react-redux';
import { GhostButton } from 'common-components/buttons';

const { Option } = Select;

interface IKeyContactDetailRowProps extends FormComponentProps {
  removeKeyContactInputRow: (rowID: number) => void;
  keyContactsInputs: any;
  handleInputData: (any) => void;
  handleDropDownClick: (value: string, name: string) => void;
  rowID: number;
}

interface IKeyContactDetailRowState {
  isActionModalOpen: boolean;
}

class KeyContactDetailRow extends Component<IKeyContactDetailRowProps, IKeyContactDetailRowState> {
  state = {
    isActionModalOpen: false
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.keyContactsInputs !== this.props.keyContactsInputs) {
      const { keyContactsInputs } = this.props;

      // set antd form to latest value
      const fieldValues: any = {
        name: keyContactsInputs.name,
        role: keyContactsInputs.role,
        email: keyContactsInputs.email,
        mobileNumber: keyContactsInputs.mobileNumber,
        mobileCountryCode: keyContactsInputs.mobileCountryCode,
        officeNumber: keyContactsInputs.officeNumber,
        officeCountryCode: keyContactsInputs.officeCountryCode
      };

      this.props.form.setFieldsValue(fieldValues);
    }
  };

  private _validateMobileNumber = (rule, value, callback) => {
    const region = 'AU';
    let phoneNumber = parsePhoneNumberFromString(value, region);
    if (value !== '' && value !== null) {
      try {
        if (!phoneNumber.isValid()) {
          throw new Error('Invalid Number');
        }
      } catch (err) {
        callback(err);
        return;
      }
      callback();
    } else {
      callback();
    }
  };

  private _removeKeyContactInputRow = (rowID) => {
    this.props.removeKeyContactInputRow(rowID);
  };

  private _handleInputData = (event) => {
    this.props.handleInputData(event);
  };

  private _handleDropDownClick = (value, name) => {
    this.props.handleDropDownClick(value, name);
  };

  render() {
    const { form, keyContactsInputs } = this.props;
    const { getFieldDecorator } = form;

    return (
      <React.Fragment>
        <Row gutter={16} className={'mb-small'}>
          <Col span={22}>
            <Form.Item>
              {getFieldDecorator('name', {
                rules: [{ required: true, message: 'Name is required' }],
                initialValue: keyContactsInputs.name
              })(
                <Input name="name" maxLength={255} placeholder={'Name'} onChange={this._handleInputData} size="large" />
              )}
            </Form.Item>
          </Col>
          <Col span={2}>
            <GhostButton
              icon="close"
              size="large"
              paddingSize="medium"
              className="ml-small"
              color="black"
              onClick={() => this._removeKeyContactInputRow(this.props.rowID)}
            />
          </Col>
        </Row>
        <Row gutter={16} className={'mb-small'}>
          <Col span={11}>
            <Form.Item>
              {getFieldDecorator('role', {
                rules: [{ required: true, message: 'Role is required' }],
                initialValue: keyContactsInputs.role
              })(
                <Input name="role" maxLength={255} placeholder={'Role'} onChange={this._handleInputData} size="large" />
              )}
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item>
              {getFieldDecorator('email', {
                rules: [
                  keyContactsInputs.mobileNumber === '' && keyContactsInputs.officeNumber === ''
                    ? { required: true, message: 'Email is required' }
                    : {},
                  keyContactsInputs.mobileNumber === '' && keyContactsInputs.officeNumber === ''
                    ? {
                        type: 'email',
                        message: 'Please enter valid Email'
                      }
                    : {}
                ],
                initialValue: keyContactsInputs.email
              })(
                <Input
                  name="email"
                  maxLength={255}
                  placeholder={'Email'}
                  onChange={this._handleInputData}
                  size="large"
                />
              )}
            </Form.Item>
          </Col>
          <Col span={2} />
        </Row>
        <Row gutter={16} className={'mb-small'}>
          <Col span={11}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('mobileCountryCode', {
                    rules: [{ required: true, message: 'Country code is required' }],
                    initialValue:
                      keyContactsInputs.mobileCountryCode !== '' && keyContactsInputs.mobileCountryCode !== null
                        ? keyContactsInputs.mobileCountryCode
                        : 'AU'
                  })(
                    <Select
                      dropdownMatchSelectWidth={true}
                      onChange={(event) => this._handleDropDownClick(event, 'mobileCountryCode')}
                      size="large"
                      className={'width-full'}
                    >
                      <Option value={'AU'}>+61 (AU)</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item>
                  {getFieldDecorator('mobileNumber', {
                    initialValue: keyContactsInputs.mobileNumber,
                    rules: [
                      keyContactsInputs.email === '' && keyContactsInputs.officeNumber === ''
                        ? { required: true, message: 'Mobile Number is required' }
                        : {},
                      {
                        validator: this._validateMobileNumber,
                        message: 'Invalid Mobile number.'
                      }
                    ]
                  })(
                    <Input
                      size="large"
                      name="mobileNumber"
                      className={'width-full'}
                      placeholder={'Mobile Number'}
                      onChange={this._handleInputData}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={11}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('officeCountryCode', {
                    rules: [{ required: true, message: 'Country code is required' }],
                    initialValue:
                      keyContactsInputs.officeCountryCode !== '' && keyContactsInputs.officeCountryCode !== null
                        ? keyContactsInputs.officeCountryCode
                        : 'AU'
                  })(
                    <Select
                      dropdownMatchSelectWidth={true}
                      onChange={(event) => this._handleDropDownClick(event, 'officeCountryCode')}
                      size="large"
                      className={'width-full'}
                    >
                      <Option value={'AU'}>+61 (AU)</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item>
                  {getFieldDecorator('officeNumber', {
                    initialValue: keyContactsInputs.officeNumber,
                    rules: [
                      keyContactsInputs.mobileNumber === '' && keyContactsInputs.email === ''
                        ? { required: true, message: 'Office Number is required' }
                        : {},
                      {
                        validator: this._validateMobileNumber,
                        message: 'Invalid Office number.'
                      }
                    ]
                  })(
                    <Input
                      size="large"
                      name="officeNumber"
                      className={'width-full'}
                      placeholder={'Office Number'}
                      onChange={this._handleInputData}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={2} />
        </Row>
        <Row />
      </React.Fragment>
    );
  }
}

export default connect(null, null)(Form.create<IKeyContactDetailRowProps>()(KeyContactDetailRow));
