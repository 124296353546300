import React, { Component } from 'react';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';
import CenteredLayout from 'layouts/CenteredLayout';
import { Text, Title } from 'common-components/typography';
import { Col, Icon, Row, Skeleton, Tabs } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { ProgressBar } from '@blueprintjs/core';
import { RouteComponentProps } from 'react-router-dom';
import DebtorGeneralPanel from './components/DebtorManagementComponents/DebtorGeneralPanel';
import DebtorCustomersPanel from './components/DebtorManagementComponents/DebtorCustomersPanel';
import DebtorInvoicesPanel from './components/DebtorManagementComponents/DebtorInvoicesPanel';
import Script from 'react-load-script';
import globalConfig from 'variables/global-config';
import TextTag from 'common-components/tags/TextTag';
import * as H from 'history';

const { TabPane } = Tabs;

interface IDebtorUrlParams {
  debtorId: string;
}

interface IDebtorDetailsViewProps extends RouteComponentProps<IDebtorUrlParams> {
  history: H.History;
  debtor: typeof state.accountStore.debtor;
  doViewDebtorGeneralInfo: typeof dispatch.accountStore.doViewDebtorGeneralInfo;
}

interface IDebtorDetailsViewState {
  isLoading: boolean;
  activeKey: string;
}

class DebtorDetailsView extends Component<IDebtorDetailsViewProps, IDebtorDetailsViewState> {
  state = {
    isLoading: false,
    activeKey: 'generalinfo'
  };

  async componentDidMount() {
    const { match, doViewDebtorGeneralInfo } = this.props;
    const { params } = match;
    const selectedDebtorId = params.debtorId;
    this.setState({ isLoading: true });
    await doViewDebtorGeneralInfo({ debtorId: selectedDebtorId });

    this.setState({ isLoading: false });
  }
  private changeTab = (key) => {
    this.setState({
      activeKey: key
    });
  };

  render() {
    const { debtor, history } = this.props;
    if (this.state.isLoading) {
      return (
        <CenteredLayout backgroundColor="white" bordered>
          <div className="item-container">
            <div className="pb-medium">
              <Text>Fetching team member details...</Text>
            </div>
            <ProgressBar />
          </div>
          <Skeleton loading={this.state.isLoading} active avatar title paragraph />
        </CenteredLayout>
      );
    } else {
      return (
        <CenteredLayout backgroundColor="white" bordered>
          <Script
            url={'https://maps.googleapis.com/maps/api/js?key=' + globalConfig.googleMapApiKey + '&libraries=places'}
          />
          <div className="mb-x7-large pb-large" id="scroll">
            <Row>
              <div className="mb-medium">
                <HyperlinkButton onClick={() => history.push('/account/debtor-management')}>
                  <Icon type="left" /> Debtor Listing
                </HyperlinkButton>
              </div>
            </Row>
            <Title level={2}>Debtor Details</Title>
            {debtor && debtor.isArchived ? (
              <Row className="mv-medium" type={'flex'} align={'middle'}>
                <Col className="mr-medium">
                  <Title level={3} weight="regular" className="mv-none">
                    {debtor ? debtor.debtorName : ''}
                  </Title>
                </Col>
                <Col>
                  <TextTag theme={'light'} color={'red'} content={'Archived'} />
                </Col>
              </Row>
            ) : (
              <Title level={3} weight="regular" className="mv-none">
                {debtor ? debtor.debtorName : ''}
              </Title>
            )}

            <div className="mv-x-large  align-center">
              <Row className={'mt-medium'}>
                <Col>
                  <Tabs
                    type="card"
                    className={'customer-tab'}
                    activeKey={this.state.activeKey}
                    onChange={this.changeTab}
                  >
                    <TabPane key="generalinfo" tab="General Information">
                      <DebtorGeneralPanel history={history} debtor={debtor} />
                    </TabPane>
                    {debtor && !debtor.isArchived && (
                      <TabPane key="customers" tab="Customers">
                        <DebtorCustomersPanel debtor={debtor} history={this.props.history} />
                      </TabPane>
                    )}

                    <TabPane key="inovices" tab="Invoices">
                      <DebtorInvoicesPanel debtor={debtor} history={history} />
                    </TabPane>
                  </Tabs>
                </Col>
              </Row>
            </div>
          </div>
        </CenteredLayout>
      );
    }
  }
}

const mapState = (state: IRootState) => ({ debtor: state.accountStore.debtor });

const mapDispatch = (dispatch: IRootDispatch) => ({
  doViewDebtorGeneralInfo: dispatch.accountStore.doViewDebtorGeneralInfo
});

export default connect(
  mapState,
  mapDispatch
)(DebtorDetailsView);
