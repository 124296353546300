import React, { Component } from 'react';
import { Row, Col, Icon, Form, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import _ from 'lodash';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import moment from 'moment-timezone';
import * as H from 'history';
import { BookingStatus, ShiftSlotStatus } from 'utilities/enum-utils';
import { Warning } from 'common-components/alerts';
import { FormComponentProps } from 'antd/es/form';
import { TimezoneIndicator } from 'common-components/timezone';
import { timeZone } from 'interfaces/timezone-type';

interface IConflictingBookingsModalProps extends FormComponentProps {
  isOpen: boolean;
  history: H.History;
  conflictList: Array<any>;
  closeConflictingBookingsModal: () => void;
  onNextStep: () => void;
  timezone: timeZone;
}

interface IConflictingBookingsModalState {
  isGoToBookingOpen: boolean;
  selectedAttendanceId: string;
  acknowledgeCancelAllConflict: boolean;
}

class ConflictingBookingsModal extends Component<IConflictingBookingsModalProps, IConflictingBookingsModalState> {
  state = {
    isGoToBookingOpen: false,
    selectedAttendanceId: null,
    acknowledgeCancelAllConflict: false
  };

  private _goToBooking = () => {
    const { history } = this.props;
    this.setState({ isGoToBookingOpen: false });
    history.push(`/bookings/details/${this.state.selectedAttendanceId}`);
  };

  private _closeProceedModal = () => {
    this.setState({ isGoToBookingOpen: false });
  };

  private _openProceedModal = (attendanceId) => {
    this.setState({ selectedAttendanceId: attendanceId, isGoToBookingOpen: true });
  };

  private _validateCancelConflictingBooking = (rule, value, callback) => {
    try {
      if (!value) {
        throw Error('In order to continue you must elect to cancel all conflicting bookings.');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  private _continueToNextStep = () => {
    const { form } = this.props;

    let isFormValid = true;

    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      this.props.onNextStep();
    }
  };

  render() {
    const { conflictList, timezone, form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div>
        <ActionModal
          isOpen={this.state.isGoToBookingOpen}
          onClose={this._closeProceedModal}
          title={'Discard changes'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            Everything you have entered in this wizard will be discard if you go to this booking.
          </Text>
          <br />
          <Text className={'mb-medium'}>Are your sure you want to continue?</Text>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._closeProceedModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._goToBooking}>
              Ok
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>

        <ActionModal
          isOpen={this.props.isOpen}
          title="View conflicting bookings"
          onClose={this.props.closeConflictingBookingsModal}
          verticalAlignment={'highest'}
          width="x-large"
        >
          <div className="anim-slide-left">
            <Warning
              content={
                <Text>
                  The bookings being created have time conflicts with bookings the customer already has. In order to
                  create this recurring booking you will have to <b>edit the time</b> or <b>cancel</b> all the
                  conflicting bookings.
                </Text>
              }
              className="mb-large"
            />
            <Row>
              <Col span={8}>
                <Text weight={'bold'} size="x-large">
                  {conflictList ? conflictList.length : 0} conflicting booking
                  {conflictList ? (conflictList.length !== 1 ? 's' : '') : 's'}
                </Text>
              </Col>
              <Col span={16} className="text-align-right">
                <TimezoneIndicator timezone={timezone} />
              </Col>
            </Row>
            <Row className="text-weight-bold bordered-bottom border-standard-gray p-small mt-large">
              <Col span={5}>Date</Col>
              <Col span={6}>Service</Col>
              <Col span={4}>Status</Col>
              <Col span={5}>Worker assigned</Col>
              <Col span={4} />
            </Row>
            <div
              style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '35vh' }}
              className="bordered-bottom border-standard-gray"
            >
              {(!conflictList || conflictList.length === 0) && (
                <Row className="pv-medium">
                  <Text>No conflicts found.</Text>
                </Row>
              )}
              {_.map(conflictList, (conflict) => (
                <Row type="flex" align="middle" className="p-small border-secondary evenodd">
                  <Col span={5}>
                    {moment
                      .tz(conflict.startDateTime, timezone)
                      .startOf('day')
                      .isSame(moment.tz(conflict.endDateTime, timezone).startOf('day')) ? (
                      <text>
                        <b>{moment.tz(conflict.startDateTime, timezone).format('DD/MM/YYYY')}</b>
                        <br />
                        <Text>
                          {moment.tz(conflict.startDateTime, timezone).format('hh:mm a')} -{' '}
                          {moment.tz(conflict.endDateTime, timezone).format('hh:mm a')}
                        </Text>
                      </text>
                    ) : (
                      <text>
                        <b>{moment.tz(conflict.startDateTime, timezone).format('DD/MM/YYYY')}</b>{' '}
                        {moment.tz(conflict.startDateTime, timezone).format('hh:mm a')}
                        <br />
                        <b>{moment.tz(conflict.endDateTime, timezone).format('DD/MM/YYYY')}</b>{' '}
                        {moment.tz(conflict.endDateTime, timezone).format('hh:mm a')}
                      </text>
                    )}
                  </Col>
                  <Col span={6} title={conflict.serviceName} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {conflict.serviceName}
                  </Col>
                  <Col span={4}>
                    {conflict.status === BookingStatus.INPROGRESS
                      ? 'In progress'
                      : _.startCase(_.snakeCase(conflict.status))}
                  </Col>
                  <Col
                    span={5}
                    title={conflict.shiftSlotStatus}
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {conflict.shiftSlotStatus === ShiftSlotStatus.UNASSIGNED ? (
                      <Text color={'red-dark'}>No worker assigned</Text>
                    ) : conflict.shiftSlotStatus === ShiftSlotStatus.PUBLISHING ? (
                      <Text color={'violet-light'}>
                        <Icon type="wifi" className="mr-x-small" />
                        Published
                      </Text>
                    ) : (
                      <Text>
                        {conflict.firstName} {conflict.lastName}
                      </Text>
                    )}
                  </Col>
                  <Col span={4}>
                    <HyperlinkButton onClick={() => this._openProceedModal(conflict.attendanceId)}>
                      Go to booking
                    </HyperlinkButton>
                  </Col>
                </Row>
              ))}
            </div>
            <div className="mt-large">
              If you choose to <b>cancel</b> the conflicting bookings, they will only be cancelled once you have
              finished creating the recurring booking.
            </div>
            <Form.Item>
              {getFieldDecorator('acknowledgeCancelAllConflict', {
                initialValue: this.state.acknowledgeCancelAllConflict,
                rules: [{ validator: this._validateCancelConflictingBooking }]
              })(
                <Checkbox className={'mt-small'}>
                  <b>Cancel all conflicting bookings.</b>
                </Checkbox>
              )}
            </Form.Item>
          </div>
          <div className={'mb-small'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this.props.closeConflictingBookingsModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._continueToNextStep}>
                Continue
              </PrimaryButton>
            </Row>
          </div>
        </ActionModal>
      </div>
    );
  }
}

export default Form.create<IConflictingBookingsModalProps>()(ConflictingBookingsModal);
