import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { backgroundColor, lightGreyVariant1 } from 'theme/theme-variables';

// Main App Default Layout
class DefaultLayout extends Component<any, any> {
  // Prop types
  static propTypes = {
    containerStyle: PropTypes.object,
    bordered: PropTypes.bool,
    padding: PropTypes.string,
    backgroundColor: PropTypes.string,
    className: PropTypes.string
  };

  render() {
    return (
      <div
        style={
          this.props.containerStyle || {
            borderTop: this.props.bordered ? `1px ${lightGreyVariant1} solid` : '0px',
            height: '100%',
            padding: this.props.padding || '16px 24px',
            backgroundColor: this.props.backgroundColor || backgroundColor
          }
        }
        className={this.props.className}
      >
        {this.props.children}
      </div>
    );
  }
}

export default withRouter(DefaultLayout);
