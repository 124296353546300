import React, { Component } from 'react';
import { Avatar, Col, notification, Row } from 'antd';
import { connect } from 'react-redux';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Title from 'antd/lib/typography/Title';
import { IGroupServiceSession } from 'interfaces/service-interfaces';
import GroupServiceSessionsPanel from 'views/group-services/service-details/components/GroupServiceSessionsPanel';
import _ from 'lodash';
import moment from 'moment-timezone';
import { GroupBookingActionType, SessionStatus } from 'utilities/enum-utils';

interface ISessionReviewStepPanelProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setCustomerToSchedule?: typeof dispatch.servicesStore.setCustomerToSchedule;
  doFetchGroupServiceSessions?: typeof dispatch.servicesStore.doFetchGroupServiceSessions;
  customerToSchedule: typeof state.servicesStore.customerToSchedule;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  groupServiceSessions: typeof state.servicesStore.groupServiceSessions;
  history?: any;
}

interface ISessionReviewStepPanelState {
  isLoading: boolean;
  sessions: IGroupServiceSession[] | any;
  isNoSessionSelectedError: boolean;
  periodFilter: { year: string; month: string } | null;
  selectedSessions: IGroupServiceSession[];
}

class SessionReviewStepPanel extends Component<ISessionReviewStepPanelProps, ISessionReviewStepPanelState> {
  state = {
    isLoading: true,
    sessions: this.props.groupServiceSessions ? this.props.groupServiceSessions : [],
    isNoSessionSelectedError: false,
    periodFilter: null,
    selectedSessions: this.props.customerToSchedule.sessions ? this.props.customerToSchedule.sessions : []
  };

  private _onSelect = (session) => {
    const { selectedSessions } = this.state;
    let newSelectedSession = selectedSessions;
    if (
      _.find(selectedSessions, (selectedSession) => selectedSession.serviceDateTimeId === session.serviceDateTimeId)
    ) {
      newSelectedSession = _.filter(
        selectedSessions,
        (selectedSession) => selectedSession.serviceDateTimeId !== session.serviceDateTimeId
      );
    } else {
      newSelectedSession.push(session);
    }
    this.setState({ selectedSessions: newSelectedSession, isNoSessionSelectedError: false });
  };

  private _onChangePeriodFilter = async (filter) => {
    const { selectedGroupService, customerToSchedule } = this.props;
    if (customerToSchedule.actionType === GroupBookingActionType.INDIVIDUAL_SESSIONS) {
      this.setState({ isLoading: true });
      let sessions = await this.props.doFetchGroupServiceSessions({
        serviceId: selectedGroupService.serviceId,
        customerUserId: customerToSchedule.selectedCustomer.userId,
        isSchedule: false,
        yearMonth: (filter.month + 1).toString().padStart(2, '0') + '-' + filter.year.toString(),
        timezone: selectedGroupService.timezone
      });
      this.setState({
        sessions: _.map(sessions, (session) => {
          return {
            ...session,
            startDateTime: moment(
              moment.tz(session.startDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm')
            ),
            endDateTime: moment(
              moment.tz(session.endDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm')
            )
          };
        }),
        periodFilter: filter,
        isLoading: false
      });
    }
  };

  private _goToNext = async () => {
    const { setCustomerToSchedule, customerToSchedule } = this.props;
    const { selectedSessions } = this.state;

    let isFormValid = true;
    if (selectedSessions.length === 0) {
      isFormValid = false;
      this.setState({ isNoSessionSelectedError: true });
    }

    if (isFormValid) {
      try {
        await setCustomerToSchedule({
          ...customerToSchedule,
          sessions: selectedSessions
        });
        this.props.onNextStep();
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong! Please try again.' });
      }
    }
  };

  private _goToPrevious = async () => {
    const { setCustomerToSchedule, customerToSchedule, onPreviousStep } = this.props;
    const newCustomerToSchedule = _.cloneDeep(customerToSchedule);
    delete newCustomerToSchedule['sessions'];
    await setCustomerToSchedule({
      ...newCustomerToSchedule
    });
    onPreviousStep();
  };

  componentDidMount = async () => {
    const { selectedGroupService, customerToSchedule } = this.props;
    try {
      let sessions = await this.props.doFetchGroupServiceSessions({
        serviceId: selectedGroupService.serviceId,
        customerUserId: customerToSchedule.selectedCustomer.userId,
        isSchedule: customerToSchedule.actionType === GroupBookingActionType.SCHEDULES,
        scheduleTimeSlotIds:
          customerToSchedule.actionType === GroupBookingActionType.SCHEDULES && customerToSchedule.scheduleTimeSlotIds,
        yearMonth:
          customerToSchedule.actionType === GroupBookingActionType.INDIVIDUAL_SESSIONS
            ? (moment().month() + 1).toString().padStart(2, '0') +
              '-' +
              moment()
                .year()
                .toString()
            : null,
        timezone: selectedGroupService.timezone
      });
      let sessionsWithoutTimezone = [];
      let selectedSessions = [];
      _.forEach(sessions, (session) => {
        const hasError =
          session.alreadyInSession ||
          (session.capacity && session.bookedCapacity !== undefined && session.bookedCapacity >= session.capacity) ||
          session.sessionStatus === SessionStatus.CANCELLED ||
          session.sessionStatus === SessionStatus.CLOSED;
        const sessionWithoutTimezone = {
          ...session,
          startDateTime: moment(
            moment.tz(session.startDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm')
          ),
          endDateTime: moment(moment.tz(session.endDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm'))
        };
        sessionsWithoutTimezone.push(sessionWithoutTimezone);
        if (customerToSchedule && customerToSchedule.actionType === GroupBookingActionType.SCHEDULES && !hasError) {
          selectedSessions.push(sessionWithoutTimezone);
        }
      });
      this.setState({
        sessions: sessionsWithoutTimezone,
        selectedSessions: customerToSchedule.sessions ? customerToSchedule.sessions : selectedSessions,
        isLoading: false
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong! Please try again.' });
    }
  };

  render() {
    const { selectedGroupService, customerToSchedule } = this.props;
    const { isLoading, sessions, selectedSessions, isNoSessionSelectedError } = this.state;

    const filterWarnings = customerToSchedule.actionType === GroupBookingActionType.SCHEDULES ? [] : null;
    if (filterWarnings && sessions) {
      _.forEach(sessions, (session) => {
        const isCapacityFull =
          session.capacity && session.bookedCapacity !== undefined && session.bookedCapacity >= session.capacity;
        const hasError =
          isCapacityFull ||
          session.alreadyInSession ||
          session.sessionStatus === SessionStatus.CANCELLED ||
          session.sessionStatus === SessionStatus.CLOSED;
        if (hasError) {
          filterWarnings.push(moment(session.startDateTime).format('MM-YYYY'));
        }
      });
    }

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>Select which schedules you want to add this customer to</Title>
              <Paragraph>
                Based on your selections in the previous step the customer will be added to the following sessions.
              </Paragraph>
              <Paragraph>
                If the customer is <b>already part of a session</b> OR the session has <b>reached full capacity</b> then
                the customer will not be able to be added to the session.
              </Paragraph>
              {customerToSchedule && customerToSchedule.selectedCustomer ? (
                <div className={'p-medium bg-white rounded-big'}>
                  <SubTitle>Selected customer</SubTitle>
                  <div className={'flex-row align-center mt-small'}>
                    <Avatar
                      className={'mr-small'}
                      shape={'circle'}
                      icon={'user'}
                      src={customerToSchedule.selectedCustomer.attachmentUrl}
                    />{' '}
                    {customerToSchedule.selectedCustomer.firstName} {customerToSchedule.selectedCustomer.lastName}
                  </div>
                </div>
              ) : (
                <Text color={'secondary'}>No customer selected</Text>
              )}
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
              <Title level={4}>Sessions for {selectedGroupService.serviceName}</Title>
              <Paragraph>You may omit any sessions from being created by unchecking it below.</Paragraph>
              {isNoSessionSelectedError && <Text color={'red-dark'}>Please select at least one session.</Text>}
              <GroupServiceSessionsPanel
                sessions={_.map(sessions, (session) => {
                  return {
                    ...session,
                    isSelected: !!_.find(
                      selectedSessions,
                      (selectedSession) => session.serviceDateTimeId === selectedSession.serviceDateTimeId
                    )
                  };
                })}
                onSelect={this._onSelect}
                timezone={selectedGroupService.timezone}
                displayWarnings={true}
                filterWarnings={filterWarnings}
                onChangeFilter={this._onChangePeriodFilter}
                isLoading={isLoading}
                hideEmptyDates={true}
              />
            </div>
            <div className="pv-medium width-full bg-tertiary" style={{ position: 'sticky', bottom: 0 }}>
              <Row gutter={0} type="flex" align="middle" justify={'space-between'} className="bg-transparent">
                <div className="text-align-right pv-medium">
                  <SecondaryButton size="large" onClick={this._goToPrevious}>
                    Back
                  </SecondaryButton>
                </div>
                <div className="text-align-right pv-medium">
                  <PrimaryButton size="large" loading={isLoading} onClick={this._goToNext}>
                    Next
                  </PrimaryButton>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setCustomerToSchedule: dispatch.servicesStore.setCustomerToSchedule,
  doFetchGroupServiceSessions: dispatch.servicesStore.doFetchGroupServiceSessions
});

const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService,
  customerToSchedule: state.servicesStore.customerToSchedule,
  groupServiceSessions: state.servicesStore.groupServiceSessions
});

export default connect(
  mapState,
  mapDispatch
)(SessionReviewStepPanel);
