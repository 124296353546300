import React, { Component } from 'react';
import { Paragraph } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { Icon, Radio } from 'antd';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { EditRecurringMode } from 'utilities/enum-utils';
import { ErrorSVG } from 'assets/UndrawSVG';
import { connect } from 'react-redux';

interface IBookingNoFeeModalProps {
  onClose: () => void;
  selectedBooking: typeof state.bookingsStore.selectedBookingItem;
  isOpen: boolean;
  doChangeToCustomerCancelled: typeof dispatch.bookingsStore.doChangeToCustomerCancelled;
  doFetchNumberOfBookingsForRecurrence: typeof dispatch.bookingsStore.doFetchNumberOfBookingsForRecurrence;
}

class BookingNoFeeModal extends Component<IBookingNoFeeModalProps, any> {
  state = {
    step: 1,
    title: 'Do not charge cancellation fee?',
    canManuallyClose: true
  };

  onCloseModal = () => {
    const { onClose } = this.props;

    // Do any manual clean up; reset to initial state
    this._reset();

    onClose();
  };

  private _doRemoveCancellationFee = async () => {
    const { selectedBooking } = this.props;
    this.setState({ step: 2, canManuallyClose: false });
    try {
      await this.props.doChangeToCustomerCancelled({
        editRecurringMode: EditRecurringMode.Current,
        bookingRequestId: selectedBooking.bookingRequestId,
        isRecurring: selectedBooking.isRecurring,
        isCharge: false,
        numberOfBookingsToCharge: 0,
        reason: selectedBooking.cancellationReason,
        cancellationReason: selectedBooking.cancellationCode
      });
      this.setState({
        title: (
          <>
            Cancellation fee not applied
            <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
          </>
        ),
        step: 3,
        canManuallyClose: true
      });
    } catch (e) {
      this.setState({ step: 4, canManuallyClose: true });
    }
  };

  private _reset = () =>
    this.setState({
      step: 1,
      title: 'Do not charge cancellation fee?',
      canManuallyClose: true
    });

  private _renderView = () => {
    const { step } = this.state;

    if (step === 1) {
      return (
        <div>
          <div className="anim-slide-left">
            <div className="mb-medium">
              <Paragraph>Are you sure you want to remove the cancellation fee for this booking?</Paragraph>
              <Paragraph>You can choose to charge a fee at a later date if you change your mind.</Paragraph>
            </div>
          </div>

          <ActionModalFooter>
            <GhostButton size="large" className="mr-medium" onClick={this.onCloseModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" onClick={this._doRemoveCancellationFee}>
              Do not charge fee
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div>
          <div>
            <SpinningLoader size={150} message={'Loading'} />
          </div>
        </div>
      );
    }
    if (step === 3) {
      return (
        <div>
          <div>
            <div className="mb-medium">
              <Paragraph>The customer has not been charged a cancellation fee for this booking.</Paragraph>
              <Paragraph>
                The booking requires <b>no further action</b>.
              </Paragraph>
            </div>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (step === 4) {
      return (
        <div>
          <div style={{ minHeight: 317 }} className="flex-column justify-center text-align-center">
            <div className="pv-medium flex-column justify-center">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium flex-column justify-center ">
              <Paragraph>Oops something has gone wrong, please try again</Paragraph>
            </div>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Back to booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  render() {
    const { isOpen } = this.props;

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this.onCloseModal}
        width="large"
        verticalAlignment="highest"
      >
        {this._renderView()}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBooking: state.bookingsStore.selectedBookingItem
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doChangeToCustomerCancelled: dispatch.bookingsStore.doChangeToCustomerCancelled,
  doFetchNumberOfBookingsForRecurrence: dispatch.bookingsStore.doFetchNumberOfBookingsForRecurrence
});

export default connect(
  mapState,
  mapDispatch
)(BookingNoFeeModal);
