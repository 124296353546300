/* eslint-disable */
import React, { Component } from 'react';
import { ICustomer, ICustomerNotes } from 'interfaces/customer-interfaces';
import { Text, Title } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import NoteCardItem from 'common-components/notes/NoteCardItem';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { FilterSection } from 'common-components/filter';
import { FilterType } from 'utilities/enum-utils';
import { Empty, Row } from 'antd';
import _ from 'lodash';
import AddEditNoteModal from 'common-components/notes/AddEditNoteModal';
import ExportNoteModal from 'common-components/notes/ExportNoteModal';
import * as H from 'history';
import CannotEditOrDeleteNoteModal from './CannotEditOrDeleteNoteModal';
import DeleteNoteModal from 'common-components/notes/DeleteNoteModal';

interface INotesPanelProps {
  customerNotes: ICustomerNotes[];
  customerNotesFilters: typeof state.customersStore.customerNotesFilters;
  portalUser: typeof state.authStore.portalUser;
  selectedCustomerUserId: string;
  selectedCustomer: ICustomer;
  customerNotePage: number;
  customerNotePageSize: number;
  customerNotePageTimeStamp: Date;
  dofetchCustomerNotes: typeof dispatch.customersStore.dofetchCustomerNotes;
  setCustomerNotesFilters: typeof dispatch.customersStore.setCustomerNotesFilters;
  dofetchMoreCustomerNotes: typeof dispatch.customersStore.dofetchMoreCustomerNotes;
  setCustomerNotesPageInfo: typeof dispatch.customersStore.setCustomerNotesPageInfo;
  resetCustomerNotesPageInfo: typeof dispatch.customersStore.resetCustomerNotesPageInfo;
  doFetchBooking: typeof dispatch.bookingsStore.doFetchSingleBooking;
  selectedBookingItem: any;
  history: H.History;
}
interface INotesPanelState {
  editingNoteBookingId: string | null;
  displayNoteModal: boolean;
  isEditNote: boolean;
  editingNote: any;
  editingNoteType: string;
  isDeleteNoteModalOpen: boolean;
  deletingNote: any;
  isExportNoteModalOpen: boolean;
  isCannotEditModalOpen: boolean;
  isEditingNote: boolean;
  noteServiceId: string;
  noteServiceDateTimeId: string;
}

const availableFilters = [
  FilterType.NOTE_AUTHOR,
  FilterType.DATE_RANGE,
  FilterType.NOTE_TYPE,
  FilterType.IS_INCIDENT,
  FilterType.NOTE_SERVICE
];

const NoteEmptyPanel = () => (
  <div className="flex-1 bg-white align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No notes found.
    </Text>
    <Text color="secondary">All notes under this filter will appear here.</Text>
  </div>
);

class CustomerDetailsNotesPanel extends Component<INotesPanelProps, INotesPanelState> {
  state = {
    editingNoteBookingId: null,
    displayNoteModal: false,
    isEditNote: false,
    editingNote: null,
    editingNoteType: 'GENERAL',
    isDeleteNoteModalOpen: false,
    deletingNote: null,
    isExportNoteModalOpen: false,
    isCannotEditModalOpen: false,
    isEditingNote: false,
    noteServiceId: null,
    noteServiceDateTimeId: null
  };

  private _onPressEditNote = (note) => {
    this.setState({
      isCannotEditModalOpen: true,
      isEditingNote: true,
      noteServiceId: note.serviceId,
      noteServiceDateTimeId: note.serviceDateTimeId
    });
  };

  private _onPressDeleteNote = async (note) => {
    if (note.attendanceId || (!note.attendanceId && !note.serviceDateTimeId)) {
      this.setState({ isDeleteNoteModalOpen: true, deletingNote: note });
    } else {
      this.setState({
        isCannotEditModalOpen: true,
        isEditingNote: false,
        noteServiceId: note.serviceId,
        noteServiceDateTimeId: note.serviceDateTimeId
      });
    }
  };

  private _onPressCloseDeleteNoteModal = () => {
    this.setState({ isDeleteNoteModalOpen: false });
  };

  private _onEditNote = (note) => {
    this.setState({
      displayNoteModal: true,
      editingNoteBookingId: note.attendanceId,
      isEditNote: true,
      editingNote: note,
      editingNoteType: note.attendanceId ? 'BOOKING' : 'GENERAL'
    });
  };

  private _onChangeFilter = async (filters: Array<any>, doNotUpdateFilterList: boolean = false) => {
    if (!doNotUpdateFilterList) {
      const { resetCustomerNotesPageInfo, dofetchCustomerNotes, setCustomerNotesFilters } = this.props;
      await resetCustomerNotesPageInfo({});
      await setCustomerNotesFilters(filters);
      await dofetchCustomerNotes({
        customerUserId: this.props.selectedCustomerUserId
      });
    }
  };

  private _onAddNote = () => {
    this.setState({
      displayNoteModal: true,
      editingNoteBookingId: null,
      isEditNote: false,
      editingNote: null,
      editingNoteType: 'GENERAL'
    });
  };

  private _onClose = () => {
    this.setState({
      editingNoteBookingId: null,
      displayNoteModal: false,
      isEditNote: false,
      editingNote: null,
      editingNoteType: 'GENERAL'
    });
  };

  private _onCloseCannotEditModal = () => {
    this.setState({
      isCannotEditModalOpen: false,
      isEditingNote: false
    });
  };

  private _onGoToSession = () => {
    const { history } = this.props;
    const { noteServiceDateTimeId, noteServiceId } = this.state;
    this.setState({
      isCannotEditModalOpen: false
    });
    history.push(`/group-service/${noteServiceId}/session/details/${noteServiceDateTimeId}`);
  };

  private _fetchMoreCustomerNotes = async () => {
    const {
      dofetchMoreCustomerNotes,
      setCustomerNotesPageInfo,
      customerNotePage,
      customerNotePageSize,
      customerNotePageTimeStamp
    } = this.props;

    setCustomerNotesPageInfo({
      customerNotePage: customerNotePage + 1,
      customerNotePageSize,
      customerNotePageTimeStamp
    });

    await dofetchMoreCustomerNotes({
      customerUserId: this.props.selectedCustomerUserId
    });
  };

  componentDidMount = async () => {
    const { resetCustomerNotesPageInfo } = this.props;
    resetCustomerNotesPageInfo({});
    await this.props.setCustomerNotesFilters([
      { filter: FilterType.DATE_RANGE, values: [], selectionLabel: 'All' },
      { filter: FilterType.IS_INCIDENT, values: [], selectionLabel: 'Both' },
      { filter: FilterType.NOTE_TYPE, values: [], selectionLabel: 'All' }
    ]);
    await this.props.dofetchCustomerNotes({
      customerUserId: this.props.selectedCustomerUserId
    });
  };

  componentWillUnmount = async () => {
    const { setCustomerNotesPageInfo, customerNotePageSize, customerNotePageTimeStamp } = this.props;
    setCustomerNotesPageInfo({
      customerNotePage: 1,
      customerNotePageSize,
      customerNotePageTimeStamp
    });
  };

  render() {
    return (
      <div>
        <AddEditNoteModal
          bookingId={this.state.editingNoteBookingId}
          isOpen={this.state.displayNoteModal}
          customerUserId={this.props.selectedCustomerUserId}
          onClose={this._onClose}
          editNote={this.state.isEditNote}
          editingNote={this.state.editingNote}
          noteType={this.state.editingNoteType}
        />
        <DeleteNoteModal
          noteItem={this.state.deletingNote}
          isOpen={this.state.isDeleteNoteModalOpen}
          doCloseDeleteNoteModal={this._onPressCloseDeleteNoteModal}
          isCustomerDetailsScreen={true}
          customerUserId={this.props.selectedCustomerUserId}
        />
        <CannotEditOrDeleteNoteModal
          isOpen={this.state.isCannotEditModalOpen}
          isEditingNote={this.state.isEditingNote}
          onClose={this._onCloseCannotEditModal}
          onGoToSession={this._onGoToSession}
        />
        <ExportNoteModal
          isOpen={this.state.isExportNoteModalOpen}
          onClose={() => this.setState({ isExportNoteModalOpen: false })}
        />
        <div className="flex-row align-end">
          <div className="width-full">
            <Title level={2} className="m-none">
              Notes
            </Title>
            <Text type={'secondary'}>Notes related to this customer and their bookings.</Text>
          </div>
          <div className="flex justify-end width-full">
            <SecondaryButton
              color={'blue-action'}
              className={'mr-medium'}
              onClick={() => this.setState({ isExportNoteModalOpen: true })}
            >
              Export Notes
            </SecondaryButton>
            <PrimaryButton color={'blue-action'} icon={'plus'} onClick={() => this._onAddNote()}>
              Add Note
            </PrimaryButton>
          </div>
        </div>
        <FilterSection
          availableFilters={availableFilters}
          filters={this.props.customerNotesFilters}
          onChangeFilter={this._onChangeFilter}
          displayTimezone={this.props.portalUser.timezone}
        />
        {!_.isEmpty(this.props.customerNotes) ? (
          <div className="mt-large">
            <InfiniteScrollLoading
              hasMore={this.props.customerNotes.length >= this.props.customerNotePage * this.props.customerNotePageSize}
              loadingElementId={'content-container'}
              loadingOffSet={60}
              loaderColSpan={7}
              loadMore={this._fetchMoreCustomerNotes}
            >
              {this.props.customerNotes.map((note) => (
                <NoteCardItem
                  noteItem={note}
                  key={note.noteId}
                  noteType={
                    note.attendanceId
                      ? 'BOOKING'
                      : !note.attendanceId && !note.serviceDateTimeId
                      ? 'GENERAL'
                      : 'BOOKING'
                  }
                  serviceName={note.serviceName}
                  onPressEditNote={() =>
                    note.attendanceId
                      ? this._onEditNote(note)
                      : !note.attendanceId && !note.serviceDateTimeId
                      ? this._onEditNote(note)
                      : this._onPressEditNote(note)
                  }
                  bookingId={note.attendanceId}
                  onPressDeleteNote={() => this._onPressDeleteNote(note)}
                  timezone={this.props.selectedCustomer.timezone}
                  history={this.props.history}
                />
              ))}
            </InfiniteScrollLoading>
          </div>
        ) : (
          <Row>
            <NoteEmptyPanel />
          </Row>
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  customerNotes: state.customersStore.customerNotes,
  portalUser: state.authStore.portalUser,
  customerNotesFilters: state.customersStore.customerNotesFilters,
  selectedCustomerUserId: state.customersStore.selectedCustomerUserId,
  selectedCustomer: state.customersStore.selectedCustomer,
  customerNotePage: state.customersStore.customerNotePage,
  customerNotePageSize: state.customersStore.customerNotePageSize,
  customerNotePageTimeStamp: state.customersStore.customerNotePageTimeStamp,
  selectedBookingItem: state.bookingsStore.selectedBookingItem
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  dofetchCustomerNotes: dispatch.customersStore.dofetchCustomerNotes,
  setCustomerNotesFilters: dispatch.customersStore.setCustomerNotesFilters,
  setCustomerNotesPageInfo: dispatch.customersStore.setCustomerNotesPageInfo,
  resetCustomerNotesPageInfo: dispatch.customersStore.resetCustomerNotesPageInfo,
  dofetchMoreCustomerNotes: dispatch.customersStore.dofetchMoreCustomerNotes,
  doFetchBooking: dispatch.bookingsStore.doFetchSingleBooking
});

export default connect(
  mapState,
  mapDispatch
)(CustomerDetailsNotesPanel);
