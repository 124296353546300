/* eslint-disable */
import React, { PureComponent } from 'react';
import { Icon } from 'antd';
import { HyperlinkButton } from '../buttons';
import { CustomerStatusType } from 'utilities/enum-utils';

interface CustomerTagProps {
  size?: 'small' | 'default' | 'large';
  className?: any;
  icon?: string;
  rounded?: boolean;
  type: string;
  onClickButton?: () => void;
  showConnectButton?: boolean;
  showEnquiryButton?: boolean;
}

export default class CustomerTag extends PureComponent<CustomerTagProps, any> {
  label = {
    NOT_CONNECTED: 'Not connected (internal record only)',
    CONNECTED: 'Connected',
    PENDING: 'Not connected (request sent)',
    HAS_GUARDIANS: 'Parent/Guardian managed',
    ENQUIRY: 'Enquiry',
    ACTIVE: 'Active',
    ARCHIVED: 'Archived',
    SCHEDULED_TO_BE_ARCHIVED: 'Scheduled to be archived'
  };
  icon = {
    NOT_CONNECTED: 'disconnect',
    CONNECTED: 'link',
    PENDING: 'disconnect',
    HAS_GUARDIANS: 'team',
    ENQUIRY: 'book',
    ACTIVE: 'book',
    ARCHIVED: 'setting',
    SCHEDULED_TO_BE_ARCHIVED: 'setting'
  };

  render() {
    const {
      type,
      className = '',
      size = 'default',
      rounded = true,
      onClickButton,
      showConnectButton,
      showEnquiryButton
    } = this.props;

    const roundedClass = rounded ? 'rounded' : '';

    let sizeClasses = '';

    if (size === 'default') {
      sizeClasses = 'pv-x-small ph-small text-size-large';
    } else if (size === 'large') {
      sizeClasses = 'pv-small ph-large text-size-x-large';
    } else if (size === 'small') {
      sizeClasses = 'pv-x3-small ph-x-small text-size-small';
    }

    const containerClassNames = `bg-secondary ph-small pv-x-small inline-block ${sizeClasses} ${roundedClass} ${className}`;

    return (
      <div className={containerClassNames}>
        <Icon type={this.icon[type]} className="mr-x-small" />
        {this.label[type]}
        {type !== 'HAS_GUARDIANS' && showConnectButton && (
          <HyperlinkButton className="ml-medium" onClick={onClickButton}>
            {type === 'NOT_CONNECTED' ? 'Connect' : 'View detail'}
          </HyperlinkButton>
        )}
        {(type === CustomerStatusType.ENQUIRY ||
          type === CustomerStatusType.ARCHIVED ||
          type === CustomerStatusType.ACTIVE ||
          type === CustomerStatusType.SCHEDULED_TO_BE_ARCHIVED) &&
          showEnquiryButton && (
            <HyperlinkButton className="ml-medium" onClick={onClickButton}>
              Manage customer
            </HyperlinkButton>
          )}
      </div>
    );
  }
}
