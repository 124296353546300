import React, { Component } from 'react';
import { Avatar, Col, Divider, Form, notification, Row } from 'antd';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import moment from 'moment-timezone';
import { Spinner } from '@blueprintjs/core';
import {
  GroupServiceClassification,
  ServiceLocationType,
  ServiceRecurringType,
  ServiceTimeType,
  ServiceType
} from 'utilities/enum-utils';
import { LocationDisplay } from 'common-components/locations';
import { TimezoneIndicator } from 'common-components/timezone';

/* Spinner while create Service process */
class CreatingServicePanel extends Component<{ serviceName: string }> {
  render() {
    return (
      <div className="anim-slide-left">
        <Title level={2} weight="bolder" className="line-height-100 mb-x-large">
          <span className="text-weight-regular">Creating a new </span> service
        </Title>
        <div className="flex-row align-center mb-x-large">
          <div className="mr-x-large">
            <Spinner size={120} />
          </div>
          <div>
            <Paragraph>
              Creating the new Service: <b>{this.props.serviceName}</b>.
            </Paragraph>
            <Paragraph weight="bold">Finalizing service details...</Paragraph>
            <Paragraph>This won't take long.</Paragraph>
          </div>
        </div>
      </div>
    );
  }
}

interface ISummaryStepPanelProps extends FormComponentProps {
  resetAndCloseModal: (serviceId?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  onNextStep: () => void;
  setNewService: typeof dispatch.servicesStore.setNewService;
  newService: typeof state.servicesStore.newService;
  doFetchSelectedWorker: typeof dispatch.teamStore.doFetchSelectedWorker;
  doCreateNewService?: typeof dispatch.servicesStore.doCreateNewService;
  doCreateNewGroupService?: typeof dispatch.servicesStore.doCreateNewGroupService;
  serviceDepartmentList: typeof state.servicesStore.serviceDepartmentListLite;
}

interface ISummaryStepPanelState {
  isLoading: boolean;
  isGroupService: boolean;
}

const frequencyOptions = [{ value: 'PEREACH', label: 'Each' }, { value: 'PERHOUR', label: 'per Hour' }];

const unitOptions = [{ value: 'EA', label: 'Each' }, { value: 'H', label: 'Hourly' }];

class SummaryStepPanel extends Component<ISummaryStepPanelProps, ISummaryStepPanelState> {
  state = {
    isLoading: false,
    isGroupService: !!(this.props.newService.serviceType && this.props.newService.serviceType === ServiceType.GROUP)
  };

  private _changeBannerFormat = (service) => {
    // using a function to use await, to avoid the doCreateNewService to be trigger before the copy is completed.
    return { ...service, bannerUrl: service.bannerUrl.attachmentUrl, bannerUrlPath: service.bannerUrl.attachmentPath };
  };

  private _createService = async () => {
    const { newService, doCreateNewService, doCreateNewGroupService, onNextStep } = this.props;
    const isGroupService = !!(newService.serviceType === ServiceType.GROUP);

    try {
      this.setState({ isLoading: true });
      let newServiceWithBanner;

      if (!isGroupService) {
        // Cast all date data to the selected timezone.
        const timezone = newService.timezone;
        const { serviceClaimConfig, ...serviceInfo } = newService;
        const newServiceWithTimezone = {
          ...serviceInfo,
          vcpClaims: {
            ...serviceClaimConfig.vcpClaims,
            isChargeTransportDuringBooking: serviceClaimConfig.isChargeVcpTransportDuringBooking
          },
          ndisClaims: {
            ...serviceClaimConfig.ndisClaims,
            isChargeTransportBeforeBooking: serviceClaimConfig.isChargeNdisTransportBeforeBooking,
            isChargeTransportDuringBooking: serviceClaimConfig.isChargeNdisTransportDuringBooking
          },
          availableFrom: moment.tz(moment(newService.availableFrom).format('YYYY-MM-DD HH:mm'), timezone),
          availableTo: moment.tz(moment(newService.availableTo).format('YYYY-MM-DD HH:mm'), timezone),
          servicePublishDate: moment.tz(moment(newService.servicePublishDate).format('YYYY-MM-DD HH:mm'), timezone),
          serviceConfig: {
            ...newService.serviceConfig,
            availableFrom: moment.tz(moment(newService.availableFrom).format('YYYY-MM-DD HH:mm'), timezone),
            availableTo: moment.tz(moment(newService.availableTo).format('YYYY-MM-DD HH:mm'), timezone),
            dataArray: newService.serviceConfig.dataArray
              ? _.map(newService.serviceConfig.dataArray, (data) => {
                  return {
                    ...data,
                    startDateTime: moment.tz(moment(data.startDateTime).format('YYYY-MM-DD HH:mm'), timezone),
                    endDateTime: moment.tz(moment(data.endDateTime).format('YYYY-MM-DD HH:mm'), timezone)
                  };
                })
              : null
          },
          timeAvailabilities: _.map(newService.timeAvailabilities, (availability) => {
            return {
              ...availability,
              startDateTime: moment
                .tz(moment(availability.startDateTime).format('YYYY-MM-DD HH:mm'), timezone)
                .toISOString(),
              endDateTime: moment
                .tz(moment(availability.endDateTime).format('YYYY-MM-DD HH:mm'), timezone)
                .toISOString()
            };
          })
        };
        newServiceWithBanner = await this._changeBannerFormat(newServiceWithTimezone);
        // Temporary solution until the API is changed to cater the new structure.
        await doCreateNewService(newServiceWithBanner);
      } else {
        newServiceWithBanner = await this._changeBannerFormat(newService);
        await doCreateNewGroupService(newServiceWithBanner);
      }

      this.setState({ isLoading: false });
      onNextStep();
    } catch (e) {
      this.setState({ isLoading: false });
      console.log(e);
      notification.error({
        message: 'Error while creating your booking',
        description: 'Please try again.'
      });
    }
  };

  render() {
    const { onPreviousStep, newService } = this.props;
    const { isGroupService } = this.state;

    const hasNDIS = _.find(newService.paymentSources, (source) => source === 'NDIS');
    const hasVCP = _.find(newService.paymentSources, (source) => source === 'VCP');
    const totalNumberOfSessions = newService.timeAvailabilities ? newService.timeAvailabilities.length : 0;

    if (this.state.isLoading) {
      return <CreatingServicePanel serviceName={newService.serviceName} />;
    }

    return (
      <div className="anim-slide-left mb-x-large">
        <Title level={2} weight="bolder" className="line-height-100">
          {isGroupService ? 'Service overview' : 'Review Service'}
        </Title>

        <Paragraph>
          {isGroupService
            ? 'You’ve done it! Review the details of the service and press the ‘Create service’ button when you are ready'
            : 'Review the service details before you create.'}
        </Paragraph>

        <Row className="mt-large">
          <Col span={isGroupService ? 6 : 4} className={isGroupService ? '' : 'pt-large'}>
            <SubTitle>{isGroupService ? 'GENERAL INFORMATION' : 'General'}</SubTitle>
          </Col>
          <Col
            span={isGroupService ? 18 : 20}
            className={isGroupService ? 'bordered rounded pt-medium' : 'bordered rounded pv-large ph-medium'}
          >
            <Row className={isGroupService ? 'mb-medium mh-medium' : 'mb-medium'}>
              <Col span={6}>
                <Text weight={'bold'}>Service Type</Text>
              </Col>
              <Col span={18}>{isGroupService ? 'Group Service' : 'Support Service'}</Col>
            </Row>
            {isGroupService && <Divider className="mv-medium" />}
            <Row className={isGroupService ? 'mb-medium mh-medium' : 'mb-medium'}>
              <Col span={6}>
                <Text weight={'bold'}>Service Name</Text>
              </Col>
              <Col span={18}>{newService.serviceName}</Col>
            </Row>
            {isGroupService && <Divider className="mv-medium" />}
            <Row className={isGroupService ? 'mb-medium mh-medium' : 'mb-medium'}>
              <Col span={6}>
                <Text weight={'bold'}>Service Description</Text>
              </Col>
              <Col span={18}>
                <Paragraph style={{ whiteSpace: 'pre-line' }} ellipsis={{ rows: 5 }} className="m-none">
                  {newService.serviceDescription}
                </Paragraph>
              </Col>
            </Row>
            {isGroupService && <Divider className="mv-medium" />}
            {!isGroupService && (
              <>
                <Row className="mb-medium">
                  <Col span={6}>
                    <Text weight={'bold'}>Service Department</Text>
                  </Col>
                  <Col span={18}>
                    {
                      _.find(
                        this.props.serviceDepartmentList,
                        (department) => department.serviceDepartmentId === newService.serviceDepartmentId
                      ).serviceDepartmentName
                    }
                  </Col>
                </Row>

                <Row className={isGroupService ? 'mb-medium mh-medium' : 'mb-medium'}>
                  <Col span={6}>
                    <Text weight="bold">Service Time Zone</Text>
                  </Col>
                  <Col span={18}>
                    <TimezoneIndicator timezone={newService.timezone} />
                  </Col>
                </Row>
              </>
            )}

            <Row className={isGroupService ? 'mb-medium mh-medium' : 'mb-medium'}>
              <Col span={6}>
                <Text weight={'bold'}>Banner Image</Text>
              </Col>
              <Col span={18}>
                <img alt="Service's Banner" style={{ maxWidth: '80%' }} src={newService.bannerUrl.attachmentUrl} />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mt-large">
          <Col span={isGroupService ? 6 : 4} className={isGroupService ? '' : 'pt-large'}>
            <SubTitle>Additional Information</SubTitle>
          </Col>
          <Col
            span={isGroupService ? 18 : 20}
            className={isGroupService ? 'bordered rounded pt-medium' : 'bordered rounded pv-large ph-medium'}
          >
            {' '}
            {isGroupService && (
              <>
                <Row className="mb-medium mh-medium">
                  <Col span={6}>
                    <Text weight={'bold'}>Service Classification</Text>
                  </Col>
                  <Col span={18}>
                    {newService.groupServiceClassification === GroupServiceClassification.COMMUNITY
                      ? 'Community'
                      : 'Centre-based'}
                  </Col>
                </Row>
                <Divider className="mv-medium" />
              </>
            )}
            <Row className={isGroupService ? 'mb-medium mh-medium' : ''}>
              <Col span={6}>
                <Text weight={'bold'}>{isGroupService ? 'Default location' : 'Location'}</Text>
              </Col>
              <Col span={18}>
                {newService.serviceDirection === ServiceLocationType.ONLINE ? (
                  'Online'
                ) : newService.serviceDirection === ServiceLocationType.CUSTOMERLOCATION ? (
                  "At customer's chosen location"
                ) : newService.locations ? (
                  <LocationDisplay location={newService.locations[0].fullAddress} />
                ) : (
                  'Not set'
                )}
              </Col>
            </Row>
            {newService.capacity > 0 && (
              <>
                <Divider className="mv-medium" />
                <Row className={isGroupService ? 'mb-medium mh-medium' : 'mb-medium'}>
                  <Col span={6}>
                    <Text weight={'bold'}>{isGroupService ? 'Default capacity' : 'Capacity'}</Text>
                  </Col>
                  <Col span={18}>
                    {newService.capacity} {isGroupService ? '' : 'person max'}
                  </Col>
                </Row>
              </>
            )}
            {isGroupService && (
              <>
                <Divider className="mv-medium" />
                <Row className="mb-medium mh-medium">
                  <Col span={6}>
                    <Text weight={'bold'}>Transport</Text>
                  </Col>
                  <Col span={18}>
                    {newService.serviceClaimConfig.isChargeNdisTransportDuringBooking ? 'Enabled' : 'Disabled'}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
        {!isGroupService && (
          <Row className="mt-large">
            <Col span={4} className="pt-large">
              <SubTitle>Availability</SubTitle>
            </Col>
            <Col span={20} className="bordered rounded pv-large ph-medium">
              <Row>
                <Col span={6}>
                  <Text weight={'bold'}>Availability</Text>
                </Col>
                <Col span={18}>
                  {newService.serviceType === ServiceType.GROUP &&
                    newService.recurringType === ServiceRecurringType.ONEOFF && (
                      <div>
                        <b>
                          {moment(newService.timeAvailabilities[0].startDateTime).format('dddd')}{' '}
                          {moment(newService.timeAvailabilities[0].startDateTime).format('DD/MM/YY')}
                        </b>{' '}
                        {moment(newService.timeAvailabilities[0].startDateTime).format('hh:mm A')} -{' '}
                        {!moment(newService.timeAvailabilities[0].startDateTime)
                          .startOf('day')
                          .isSame(moment(newService.timeAvailabilities[0].endDateTime).startOf('day')) ? (
                          <b>
                            {moment(newService.timeAvailabilities[0].startDateTime).format('dddd')}{' '}
                            {moment(newService.timeAvailabilities[0].endDateTime).format('DD/MM/YY')}{' '}
                          </b>
                        ) : (
                          ''
                        )}
                        {moment(newService.timeAvailabilities[0].endDateTime).format('hh:mm A')}
                      </div>
                    )}
                  {newService.serviceType === ServiceType.GROUP &&
                    newService.recurringType === ServiceRecurringType.RECURRING && (
                      <>
                        {_.map(newService.serviceConfig.dataArray, (time) => {
                          if (time.selected) {
                            const numberOfSessions = _.filter(
                              newService.timeAvailabilities,
                              (date) => date.dayOfWeek === time.dayOfWeek
                            ).length;
                            return (
                              <div>
                                <b>{time.day}</b>: {moment(time.startDateTime).format('hh:mm A')} -{' '}
                                {moment(time.endDateTime).format('hh:mm A')} ({numberOfSessions} session
                                {numberOfSessions > 1 && 's'}){' '}
                              </div>
                            );
                          }
                        })}

                        <div className="mt-medium">
                          <b>Total</b>: {totalNumberOfSessions} Sessions. From the{' '}
                          {moment(newService.availableFrom).format('DD/MM/YY')} to the{' '}
                          {moment(newService.availableTo).format('DD/MM/YY')}
                        </div>
                      </>
                    )}
                  {newService.serviceType === ServiceType.INDIVIDUAL &&
                    newService.bookingTimeType === ServiceTimeType.FIXEDTIME && (
                      <>
                        {_.map(newService.serviceConfig.dataArray, (time) => {
                          if (time.selected) {
                            return (
                              <div>
                                <b>{time.day}</b>:{' '}
                                {time.isAllDay
                                  ? 'All day'
                                  : moment(time.startDateTime).format('hh:mm A') +
                                    ' - ' +
                                    moment(time.endDateTime).format('hh:mm A')}
                              </div>
                            );
                          }
                        })}
                      </>
                    )}
                  {newService.serviceType === ServiceType.INDIVIDUAL &&
                    newService.bookingTimeType === ServiceTimeType.OPENTIME && <div>All days and times.</div>}
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {isGroupService && (
          <Row className="mt-large">
            <Col span={6}>
              <SubTitle>PRICING SETTINGS (NDIS)</SubTitle>
            </Col>
            <Col span={18} className="bordered rounded pt-medium">
              <Row className="mb-medium mh-medium">
                <Col span={6}>
                  <Text weight={'bold'}>Line items selected</Text>
                </Col>
                <Col span={18}>
                  {newService.ndisServiceBillingItems ? newService.ndisServiceBillingItems.length : 0} line item
                  {newService.ndisServiceBillingItems && newService.ndisServiceBillingItems.length !== 1 && 's'}{' '}
                  selected
                </Col>
              </Row>
              <Divider className="mv-medium" />
              <Row className="mb-medium mh-medium">
                <Col span={6}>
                  <Text weight={'bold'}>Default ratio</Text>
                </Col>
                <Col span={18}>{newService.teamMemberCustomerRatio.ndis}</Col>
              </Row>
              <Divider className="mv-medium" />

              <Row className="mb-medium mh-medium">
                <Col span={6}>
                  <Text weight={'bold'}>Centre based capital</Text>
                </Col>
                <Col span={18}>
                  {newService.serviceClaimConfig.ndisClaims.isChargeCentreCapitalCosts ? 'Claimable' : 'Non-claimable'}
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {!isGroupService && (
          <Row className="mt-large">
            <Col span={4} className="pt-large">
              <SubTitle>Payment methods</SubTitle>
            </Col>
            <Col span={20} className="bordered rounded pv-large ph-medium">
              <Row>
                <Col span={6}>
                  <Text weight={'bold'}>Enabled Payment methods</Text>
                </Col>
                <Col span={18}>
                  {hasNDIS && <Text>NDIS</Text>}
                  {hasVCP && (
                    <div>
                      <Text>VCP</Text>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        <Row className="mt-large">
          <Col span={isGroupService ? 6 : 4} className={isGroupService ? '' : 'pt-large'}>
            <SubTitle>{isGroupService ? 'PUBLISH SETTINGS' : 'Publish rules'}</SubTitle>
          </Col>
          <Col
            span={isGroupService ? 18 : 20}
            className={isGroupService ? 'bordered rounded pt-medium' : 'bordered rounded pv-large ph-medium'}
          >
            {' '}
            <Row className={isGroupService ? 'mb-medium mh-medium' : 'mb-medium'}>
              <Col span={6}>
                <Text weight={'bold'}>Publish Date</Text>
              </Col>
              <Col span={18}>{moment(newService.servicePublishDate).format('dddd DD/MM/YY')}</Col>
            </Row>
            {isGroupService && <Divider className="mv-medium" />}
            <Row className={isGroupService ? 'mb-medium mh-medium' : ''}>
              <Col span={6}>
                <Text weight={'bold'}>{isGroupService ? 'Publish settings' : 'Publish Strategy'}</Text>
              </Col>
              <Col span={18}>
                {newService.status === 'DRAFT'
                  ? 'This service will be saved as a draft'
                  : newService.isPublicMarketplace
                  ? 'This service will be available to every customer.'
                  : 'This service will be available to your customers.'}
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="flex-row align-center mv-x2-large">
          <SecondaryButton size="large" onClick={onPreviousStep} icon="left" className="mr-large">
            Previous
          </SecondaryButton>

          <PrimaryButton size="large" onClick={this._createService} icon="right" iconPosition="right">
            Create Service
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  serviceDepartmentList: state.servicesStore.serviceDepartmentListLite
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchSelectedWorker: dispatch.teamStore.doFetchSelectedWorker
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<ISummaryStepPanelProps>()(SummaryStepPanel));
