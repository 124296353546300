import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Spinner } from '@blueprintjs/core';
import DatePicker from 'react-datepicker';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import TimeInput from 'common-components/time-input/TimeInput';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { ISession } from 'interfaces/session-interfaces';
import { Moment } from 'moment';
import CommonUtils from 'utilities/common-utils';

interface IEditStartTimeModalProps {
  isOpen: boolean;
  shiftSlot: IShiftSlot;
  onClose: (targetFlag, refreshShiftSlots?: boolean) => void;
  doEditShiftStartTime: typeof dispatch.groupServiceStore.doEditShiftStartTime;
  session: ISession;
}

interface IEditStartTimeModalState {
  step: number;
  canManuallyClose: boolean;

  shiftStartDate: Moment;
  refreshShiftSlots: boolean;
}

class EditStartTimeModal extends Component<IEditStartTimeModalProps, IEditStartTimeModalState> {
  state = { step: 1, canManuallyClose: true, shiftStartDate: moment(), refreshShiftSlots: false };

  private _reset = () => {
    const { shiftSlot, session } = this.props;
    const { workerCheckedInDateTime } = shiftSlot;

    this.setState({
      step: 1,
      shiftStartDate: moment(moment.tz(workerCheckedInDateTime, session.timezone).format('YYYY-MM-DD HH:mm')),
      refreshShiftSlots: false
    });
  };

  private _onSave = async () => {
    const { doEditShiftStartTime, shiftSlot, session } = this.props;
    this.setState({ step: 2, canManuallyClose: false });

    const request = {
      serviceId: session.serviceId,
      serviceDateTimeId: session.serviceDateTimeId,
      supportWorkerAttendanceId: shiftSlot.supportWorkerAttendanceId,
      startDateTime: moment.tz(this.state.shiftStartDate.format('YYYY-MM-DD HH:mm'), session.timezone)
    };
    await doEditShiftStartTime(request);

    this.setState({ step: 3, canManuallyClose: true, refreshShiftSlots: true });
  };

  private _onClose = () => {
    const { onClose } = this.props;
    onClose({ targetFlag: 'isEditStartTimeOpen' }, this.state.refreshShiftSlots);
    this._reset();
  };

  private _onChangeStartDate = (date) => {
    this.setState({ shiftStartDate: moment(CommonUtils.formatCeilingDateTime(date)) });
  };

  componentDidUpdate(
    prevProps: Readonly<IEditStartTimeModalProps>,
    prevState: Readonly<IEditStartTimeModalState>,
    snapshot?: any
  ) {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.isOpen) {
        const { shiftSlot } = this.props;
        const { workerCheckedInDateTime } = shiftSlot;

        this.setState({
          shiftStartDate: moment(
            moment.tz(workerCheckedInDateTime, this.props.session.timezone).format('YYYY-MM-DD HH:mm')
          )
        });
      }
    }
  }

  render() {
    const { shiftSlot } = this.props;

    if (_.isEmpty(shiftSlot)) {
      return <></>;
    }

    const { shiftStartDateTime, shiftEndDateTime } = shiftSlot;

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title="Edit start time"
        onClose={this._onClose}
        width={'small'}
        canCloseOutside={this.state.canManuallyClose}
      >
        {this.state.step === 1 && (
          <div>
            <div className="mb-medium line-height-135">
              <Text lineHeight={135}>
                Edit <b>start time</b> for this shift.
              </Text>
            </div>

            <div className="mb-large">
              <FieldLabel text={'START TIME'} />

              <div className="flex-1 mr-medium mt-x2-small">
                <div className="flex-row">
                  <DatePicker
                    className="gh-datepicker rounded mr-medium"
                    calendarClassName="gh-datepicker-calendar"
                    onChange={this._onChangeStartDate}
                    dateFormat={'dd/MM/yyyy'}
                    isClearable={false}
                    selected={this.state.shiftStartDate.toDate()}
                    minDate={shiftStartDateTime}
                  />

                  <TimeInput size="large" value={this.state.shiftStartDate} onChange={this._onChangeStartDate} />
                </div>
              </div>
            </div>

            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onSave}>
                Save
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {this.state.step === 2 && (
          <div className="line-height-135 anim-slide-left">
            <div className="flex-column align-center mv-large justify-center">
              <div className="mb-medium">
                <Spinner size={80} />
              </div>
              <div className="text-align-center">
                <Text color="secondary" weight="bold">
                  Updating shift...
                </Text>
                <br />
                <Text color="secondary">This won't take long.</Text>
              </div>
            </div>
          </div>
        )}

        {this.state.step === 3 && (
          <div className="anim-fade-in-fast">
            <div className="mb-medium">
              <Text>Start time for this shift successfully updated.</Text>
            </div>

            <div className="mb-medium">
              <FieldLabel text="UPDATED START/FINISH TIME" />
              <div className="mt-x2-small line-height-135">
                <Text lineHeight={135}>
                  {this.state.shiftStartDate.format('h:mm A')} -{' '}
                  {moment(shiftEndDateTime).format('h:mm A, D MMMM YYYY')}
                </Text>
              </div>
            </div>

            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this._onClose}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditShiftStartTime: dispatch.groupServiceStore.doEditShiftStartTime
});

export default connect(
  mapState,
  mapDispatch
)(EditStartTimeModal);
