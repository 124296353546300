import React, { Component } from 'react';
import { Title } from 'common-components/typography';
import _ from 'lodash';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { IconButton, PrimaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { FilterSection } from 'common-components/filter';
import '../css/new-service-listing.css';
import { FilterType } from 'utilities/enum-utils';
import { Tooltip2 } from '@blueprintjs/popover2';
import Search from 'antd/es/input/Search';

interface IServicelistNavigationSectionProps {
  showServiceModal: () => void;
  setServicesFilter: typeof dispatch.servicesStore.setServicesFilter;
  servicesFilter: any;
  timezone: string;
  refreshListings: any;
  applyFilter: () => void;
}

interface IServiceListHeaderSectionState {
  isSearching: boolean;
}

const availableFilters = [FilterType.SERVICE_STATUS, FilterType.SERVICE_DEPARTMENT, FilterType.SERVICE_TYPE];

class ServiceListHeaderSection extends Component<IServicelistNavigationSectionProps, IServiceListHeaderSectionState> {
  state = {
    isSearching: false
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setServicesFilter(filters);
  };

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _searchText = async (txt) => {
    const { servicesFilter, setServicesFilter } = this.props;
    let newServiceFilters = _.clone(servicesFilter);
    const existingSearchIndex = _.findIndex(servicesFilter, (filter: any) => filter.filter === 'searchString');
    if (existingSearchIndex > -1) {
      if (txt === '') {
        newServiceFilters.splice(existingSearchIndex, 1);
      } else {
        newServiceFilters[existingSearchIndex].values = txt;
      }
    } else {
      newServiceFilters.push({ filter: 'searchString', values: txt });
    }
    setServicesFilter(newServiceFilters);
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.servicesFilter !== this.props.servicesFilter) {
      await this.props.applyFilter();
    }
  }

  render() {
    const { showServiceModal } = this.props;

    return (
      <div>
        <div>
          {/* Header */}
          <div className="flex-row justify-between align-center">
            <div className={'flex-row align-center'}>
              <Title level={3} className="mv-none" lineHeight={150}>
                Services
              </Title>
            </div>

            <div className="flex-row align-center">
              <div>
                <Tooltip2 position="top" content="Refresh this view">
                  <IconButton
                    color={'white'}
                    iconColor={'secondary'}
                    size={'large'}
                    onClick={this.props.refreshListings}
                    icon="reload"
                  />
                </Tooltip2>
              </div>

              <div>
                <div className={'ml-medium'}>
                  <PrimaryButton icon={'plus'} size="large" onClick={showServiceModal}>
                    New Service
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>

          <div className={'flex-row align-center pv-large'}>
            <Search
              placeholder="Search by name"
              onChange={this._onEnterSearchText}
              loading={this.state.isSearching}
              style={{ width: '250px' }}
              className={'mr-large mb-small'}
              allowClear={true}
            />

            <FilterSection
              availableFilters={availableFilters}
              filters={this.props.servicesFilter}
              onChangeFilter={this._onChangeFilter}
              displayTimezone={this.props.timezone}
              containerClassName="flex-row justify-between"
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  servicesFilter: state.servicesStore.servicesFilter,
  timezone: state.authStore.portalUser.timezone
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setServicesFilter: dispatch.servicesStore.setServicesFilter
});

export default connect(mapState, mapDispatch)(ServiceListHeaderSection);
