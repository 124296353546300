import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { PrimaryButton } from 'common-components/buttons';
import { ActivityItem } from 'views/group-services/manage-booking-modal/panels/activity-log/ActivityItem';
import { Divider } from 'antd';
import _ from 'lodash';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { ISession } from 'interfaces/session-interfaces';
import SpinningLoader from 'common-components/loading/SpinningLoader';

interface IViewActivityLogModalProps {
  isOpen: boolean;
  shiftSlot: IShiftSlot;
  onClose: any;
  session: ISession;
  shiftSlotActivityLogs: typeof state.groupServiceStore.shiftSlotActivityLogs;
  doFetchShiftSlotActivityLogs: typeof dispatch.groupServiceStore.doFetchShiftSlotActivityLogs;
}

interface IViewActivityLogModalState {
  isLoading: boolean;
}

class ViewActivityLogModal extends Component<IViewActivityLogModalProps, IViewActivityLogModalState> {
  state = {
    isLoading: false
  };

  onClose = () => {
    const { onClose } = this.props;
    onClose({ targetFlag: 'isViewActivityLogOpen' });
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.isOpen && !prevProps.isOpen) {
      const { session, shiftSlot, doFetchShiftSlotActivityLogs } = this.props;
      const { serviceDateTimeId, serviceId } = session;
      const { supportWorkerAttendanceId } = shiftSlot;
      this.setState({ isLoading: true });
      await doFetchShiftSlotActivityLogs({ serviceDateTimeId, serviceId, supportWorkerAttendanceId });
      this.setState({ isLoading: false });
    }
  };

  render() {
    // Activities items
    const { shiftSlotActivityLogs, session } = this.props;
    const { timezone } = session;
    const hasActivities = !_.isEmpty(shiftSlotActivityLogs);
    const totalItems = hasActivities ? shiftSlotActivityLogs.length : 0;

    return (
      <ActionModal isOpen={this.props.isOpen} title="Shift activity log" onClose={this.onClose}>
        {this.state.isLoading ? (
          <SpinningLoader size={150} message="Loading..." />
        ) : (
          <>
            <Divider className="divider-medium" />

            {/* Activity listing */}
            <section className="p-medium" style={{ maxHeight: '70vh', overflowY: 'auto' }}>
              <section>
                {_.map(shiftSlotActivityLogs, (log, idx) => (
                  <ActivityItem isLast={idx === totalItems - 1} activityLog={log} timezone={timezone} />
                ))}
                {!hasActivities && (
                  <div>
                    <Text size="large" color="tertiary">
                      No activities.
                    </Text>
                  </div>
                )}
              </section>
            </section>

            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this.onClose}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  shiftSlotActivityLogs: state.groupServiceStore.shiftSlotActivityLogs
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchShiftSlotActivityLogs: dispatch.groupServiceStore.doFetchShiftSlotActivityLogs
});

export default connect(
  mapState,
  mapDispatch
)(ViewActivityLogModal);
