import React, { PureComponent } from 'react';
import { FilterType } from 'utilities/enum-utils';
import { timeZone } from 'interfaces/timezone-type';
import { FilterMenuDateRange, FilterMenuSearchLookup, FilterMenuCheckboxList } from 'common-components/filter';
import { IFilter } from 'interfaces/filter-interfaces';

interface IFilterItemMenuProps {
  filter: IFilter;
  canRemove?: boolean;
  saveNewFilterValue: (filterType, newFilterValue, selectionLabel) => void;
  removeFilter: (filterType) => void;
  displayTimezone: timeZone;
}

interface IFilterItemMenuState {
  searchedItemList: Array<any>;
}

class FilterItemMenu extends PureComponent<IFilterItemMenuProps, IFilterItemMenuState> {
  state = {
    searchedItemList: []
  };

  render() {
    const { filter, canRemove, displayTimezone } = this.props;
    const filterType = filter.filter;

    if (
      filterType === FilterType.DATE_RANGE ||
      filterType === FilterType.INVOICE_DATE ||
      filterType === FilterType.DATE_PAID
    ) {
      return (
        <FilterMenuDateRange
          filter={filter}
          canRemove={canRemove}
          displayTimezone={displayTimezone}
          saveNewFilterValue={this.props.saveNewFilterValue}
          removeFilter={this.props.removeFilter}
        />
      );
    } else if (
      filterType === FilterType.CUSTOMER ||
      filterType === FilterType.WORKER ||
      filterType === FilterType.BATCH_AUTHOR ||
      filterType === FilterType.PREFERRED_SUPPORT_WORKER ||
      filterType === FilterType.BLOCKED_SUPPORT_WORKER ||
      filterType === FilterType.SERVICE_DATE_TIMES ||
      filterType === FilterType.GROUP_SERVICE_SCHEDULES ||
      filterType === FilterType.LOCATION_BY_SUBURBS ||
      filterType === FilterType.SUPPORT_COORDINATOR ||
      filterType === FilterType.CASE_MANAGER
    ) {
      return (
        <FilterMenuSearchLookup
          filter={filter}
          canRemove={canRemove}
          saveNewFilterValue={this.props.saveNewFilterValue}
          removeFilter={this.props.removeFilter}
        />
      );
    } else {
      return (
        <FilterMenuCheckboxList
          {...this.props}
          saveNewFilterValue={this.props.saveNewFilterValue}
          removeFilter={this.props.removeFilter}
          canRemove={canRemove}
        />
      );
    }
  }
}

export default FilterItemMenu;
