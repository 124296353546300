import React, { Component } from 'react';
import { FieldLabel, Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { IRootState, state } from 'src/stores/rematch/root-store';
import moment from 'moment-timezone';

interface IActualDetailsTabSectionProps {
  onEditDetails: any;
  transportDetails: any;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
  transportType: 'before' | 'after' | string;
}

class ActualDetailsTabSection extends Component<IActualDetailsTabSectionProps> {
  render() {
    const { onEditDetails, transportDetails, selectedGroupBookingItem, transportType } = this.props;
    const { timezone } = selectedGroupBookingItem;

    return (
      <div>
        {/* Recorded start/end time */}
        <section className="flex-row mb-large mt-small">
          <div className="mr-medium flex-1 line-height-120">
            <div className="mb-x2-small">
              <FieldLabel text="RECORDED START TIME" />
            </div>
            <Text lineHeight={100}>
              <Text size="x-large" lineHeight={100}>
                {moment.tz(transportDetails.workerCheckedInDateTime, timezone).format('h:mm A')}
              </Text>
              <br />
              <Text size="large" lineHeight={100}>
                {moment.tz(transportDetails.workerCheckedInDateTime, timezone).format('D MMMM YYYY')}
              </Text>
            </Text>
          </div>
          <div className="flex-1 line-height-120">
            <FieldLabel text="RECORDED FINISH TIME" />
            {transportDetails.workerCheckedOutDateTime ? (
              <Text lineHeight={120}>
                <Text size="x-large" lineHeight={100}>
                  {moment.tz(transportDetails.workerCheckedOutDateTime, timezone).format('h:mm A')}
                </Text>
                <br />
                <Text size="large" lineHeight={100}>
                  {moment.tz(transportDetails.workerCheckedOutDateTime, timezone).format('D MMMM YYYY')}
                </Text>
              </Text>
            ) : (
              <Text lineHeight={120}>N/A</Text>
            )}
          </div>
        </section>

        {/* Actual start/ end time */}
        <section className="flex-row mb-large">
          <div className="mr-medium flex-1 line-height-120">
            <div className="mb-x2-small">
              <FieldLabel text="ACTUAL START TIME" />
            </div>
            <Text lineHeight={100}>
              <Text size="x-large" lineHeight={100}>
                {moment.tz(transportDetails.actualCheckedInDateTime, timezone).format('h:mm A')}
              </Text>
              <br />
              <Text size="large" lineHeight={100}>
                {moment.tz(transportDetails.actualCheckedInDateTime, timezone).format('D MMMM YYYY')}
              </Text>
            </Text>
          </div>
          <div className="flex-1 line-height-120">
            <FieldLabel text="ACTUAL FINISH TIME" />
            {transportDetails.actualCheckedInDateTime ? (
              <Text lineHeight={120}>
                <Text size="x-large" lineHeight={100}>
                  {moment.tz(transportDetails.actualCheckedOutDateTime, timezone).format('h:mm A')}
                </Text>
                <br />
                <Text size="large" lineHeight={100}>
                  {moment.tz(transportDetails.actualCheckedOutDateTime, timezone).format('D MMMM YYYY')}
                </Text>
              </Text>
            ) : (
              <Text lineHeight={120}>N/A</Text>
            )}
          </div>
        </section>

        <section className="flex-row mb-large">
          <div className="mr-medium flex-1 line-height-120">
            <div className="mb-x2-small">
              <FieldLabel text="KILOMETRES TRAVELLED" />
            </div>
            <Text lineHeight={100}>
              <Text size="x-large" lineHeight={100}>
                {transportDetails.travelDistanceDuringBooking ? transportDetails.travelDistanceDuringBooking : '-'}
              </Text>
            </Text>
          </div>
          <div className="flex-1 line-height-120">
            <FieldLabel text="ADDITIONAL COSTS" />
            <Text lineHeight={120}>
              <Text size="x-large" lineHeight={100}>
                {transportDetails.additionalCostDuringBooking ? transportDetails.additionalCostDuringBooking : '-'}
              </Text>
            </Text>
          </div>
        </section>

        <section>
          <HyperlinkButton
            onClick={() => onEditDetails({ editMode: 'edit', editType: transportType, editDetails: transportDetails })}
          >
            Edit actual details...
          </HyperlinkButton>
        </section>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem
});

export default connect(
  mapState,
  null
)(ActualDetailsTabSection);
