/* eslint-disable */
import apiClient from 'utilities/api-client';
import apiCloudFunctionClient from 'utilities/api-cloud-function-client';

import {
  IBatch,
  IBatchDetail,
  IBatchItem,
  IBatchLineItem,
  IBatchList,
  IBillingBooking,
  IBillingLineItem,
  IBillingReviewInvoice,
  IInvoice,
  IInvoiceItem,
  IPaymentBillingLineItem,
  IPaymentLineItemMMM,
  IShiftHours
} from 'interfaces/booking-interfaces';
import _ from 'lodash';
import { IRootState } from 'stores/rematch/root-store';
import { PaymentSources } from 'utilities/enum-utils';
import globalConfig from '../../../variables/global-config';

// Interface
interface IBillingsStoreState {
  billingLineItems: IBillingLineItem[];
  paymentsList: IPaymentBillingLineItem[];
  invoiceList: IInvoice[];
  shiftHoursList: IShiftHours[];
  batchList: IBatch[];
  batchDetail: IBatchDetail;
  batchDetailList: IBatchList[];
  rejectedList: IInvoice[];
  overviewPayments: any;
  billingLineItemsFilter: any;
  paymentsFilter: any;
  invoicesFilter: any;
  shiftHoursFilter: any;
  batchesFilter: any;
  rejectedFilter: any;
  batchDetailFilter: any;
  billingLineItemsSelectedAll: boolean;
  paymentsSelectedAll: boolean;
  reviewInvoiceBillingLineItems: IBillingReviewInvoice[];
  reviewInvoiceBillingLineItemsFilter: any;
  reviewInvoiceBillingLineItemsSelectedAll: boolean;
  selectedBillingLineItemId: string;
  selectedBillingInvoiceNumber: string;
  billingBookings: IBillingBooking[];
  billingBookingsFilter: any;
  billingBookingsSelectedAll: boolean;
  exportBatches: IBatchItem[];
  exportTotal: number;
  selectedBatch: IBatchItem;
  selectedBatchLineItems: IBatchLineItem[];
  exportInvoices: IInvoiceItem[];
  selectedInvoice: IInvoiceItem;
  selectedInvoiceLineItems: IBatchLineItem[];
  selectedHistoryIds: Array<string>;
  selectedRejectBillingItems: string[];
  selectedFilterKey: any;
  selectedPayments: any;
  selectedBatchDetail: any;
  selectedBillingLineItem: any;
  selectedRejected: any;
  selectedFundedCategories: Array<any>;
  selectedLineItemMmmData: IPaymentLineItemMMM;
  timesheet: any;
  entitlements: any;
  batchAuthors: any;
  batchAuthorsFilter: any;
  selectedInvoices: IInvoiceItem[];
  markAsPaidType: 'MULTIPLE' | 'SINGLE';
  markAsUnPaidType: 'MULTIPLE' | 'SINGLE';
  fetchInvoicesRequest: any;
  isCheckAll: boolean;
}

const initialState: IBillingsStoreState = {
  billingLineItems: [],
  paymentsList: [],
  invoiceList: [],
  shiftHoursList: [],
  batchList: [],
  rejectedList: [],
  batchDetail: null,
  batchDetailList: [],
  overviewPayments: [],
  billingLineItemsFilter: {
    dateRange: []
  },
  paymentsFilter: [],
  invoicesFilter: [],
  shiftHoursFilter: [],
  batchesFilter: [],
  batchDetailFilter: {
    dateRange: []
  },
  rejectedFilter: [],
  billingLineItemsSelectedAll: false,
  paymentsSelectedAll: false,
  reviewInvoiceBillingLineItems: [],
  reviewInvoiceBillingLineItemsFilter: {
    dateRange: []
  },
  reviewInvoiceBillingLineItemsSelectedAll: false,
  selectedBillingLineItemId: null,
  selectedBillingInvoiceNumber: null,
  billingBookings: [],
  billingBookingsFilter: {
    status: [],
    dateRange: []
  },
  billingBookingsSelectedAll: false,
  exportBatches: [],
  exportTotal: 0,
  selectedBatch: null,
  selectedBatchLineItems: [],
  exportInvoices: [],
  selectedInvoice: null,
  selectedInvoiceLineItems: [],
  selectedHistoryIds: [],
  selectedRejectBillingItems: [],
  selectedFilterKey: null,
  selectedPayments: [],
  selectedBatchDetail: [],
  selectedRejected: [],
  selectedBillingLineItem: null,
  selectedFundedCategories: [],
  selectedLineItemMmmData: { mmmGroup: null, locationState: null },
  timesheet: [],
  entitlements: [],
  batchAuthors: [],
  batchAuthorsFilter: [],
  selectedInvoices: [],
  markAsPaidType: 'SINGLE',
  markAsUnPaidType: 'SINGLE',
  fetchInvoicesRequest: null,
  isCheckAll: false
};

const billingsStore = {
  state: initialState,
  reducers: {
    setSelectedFilterKey: (state, payload) => ({ ...state, selectedFilterKey: payload }),
    setBillingLineItems(state, payload) {
      payload = _.map(payload, (billinglineItem) => {
        return { ...billinglineItem, claimType: billinglineItem.claimType === '' ? 'STD' : billinglineItem.claimType };
      });

      return {
        ...state,
        billingBookingsSelectedAll: false,
        billingLineItems: payload,
        exportTotal: 0
      };
    },

    setPayments(state, payload) {
      payload = _.map(payload, (payment) => {
        return { ...payment, claimType: payment.claimType === '' ? 'STD' : payment.claimType };
      });

      return {
        ...state,
        paymentsSelectedAll: false,
        paymentsList: payload,
        exportTotal: 0
      };
    },

    setOverviewPayments: (state, payload) => ({ ...state, overviewPayments: payload }),

    setInvoices: (state, payload) => ({ ...state, invoiceList: payload }),

    setShiftHours: (state, payload) => ({ ...state, shiftHoursList: payload }),

    setZestCareTimesheet: (state, payload) => ({
      ...state,
      timesheet: payload.workerShiftData,
      entitlements: payload.workerTransportData
    }),

    setRejected: (state, payload) => ({ ...state, rejectedList: payload }),

    setBatches: (state, payload) => ({ ...state, batchList: payload }),

    setBatchDetail: (state, payload) => ({ ...state, batchDetail: payload }),

    setBatchDetailList: (state, payload) => ({ ...state, batchDetailList: payload }),

    setBillingLineItemsFilter: (state, payload) => ({ ...state, billingLineItemsFilter: payload }),

    setBatchesFilter: (state, payload) => ({ ...state, batchesFilter: payload }),

    setBatchDetailFilter: (state, payload) => ({ ...state, batchDetailFilter: payload }),

    setSelectedFundedCategories: (state, payload) => ({ ...state, selectedFundedCategories: payload }),

    setSelectedLineItemMmmData: (state, payload) => ({ ...state, selectedLineItemMmmData: payload }),

    setSelectedHistoryIds: (state, payload) => ({ ...state, selectedHistoryIds: payload }),

    setSelectedRejectBillingItems: (state, payload) => ({ ...state, selectedRejectBillingItems: payload }),

    setBillingLineItemsSelected(state, selected: boolean) {
      const billingLineItems = _.map(state.billingLineItems, (billinglineItem) => {
        billinglineItem.selected = selected;
        return { ...billinglineItem };
      });

      const total = _.reduce(
        billingLineItems,
        (total, billingLineItem) => {
          return billingLineItem.selected ? Number(total) + Number(billingLineItem.total) : Number(total);
        },
        0
      );

      return {
        ...state,
        billingLineItems: billingLineItems,
        billingLineItemsSelectedAll: selected,
        exportTotal: total
      };
    },

    setPaymentsSelected(state, selected: boolean) {
      const payments = _.map(state.payments, (payment) => {
        payment.selected = selected;
        return { ...payment };
      });

      const total = _.reduce(
        payments,
        (total, payment) => {
          return payment.selected ? Number(total) + Number(payment.total) : Number(total);
        },
        0
      );

      return {
        ...state,
        paymentsList: payments,
        paymentsSelectedAll: selected,
        exportTotal: total
      };
    },

    setReviewInvoiceBillingLineItems: (state, payload) => ({
      ...state,
      billingBookingsSelectedAll: false,
      reviewInvoiceBillingLineItems: payload,
      exportTotal: 0
    }),

    setReviewInvoiceBillingLineItemsFilter: (state, payload) => ({
      ...state,
      reviewInvoiceBillingLineItemsFilter: payload
    }),

    setReviewInvoiceBillingLineItemsSelected(state, selected: boolean) {
      const reviewInvoiceBillingLineItems = _.map(state.reviewInvoiceBillingLineItems, (billinglineItem) => {
        billinglineItem.selected = selected;
        return { ...billinglineItem };
      });

      const total = _.reduce(
        reviewInvoiceBillingLineItems,
        (total, billingLineItem) => {
          return billingLineItem.selected ? Number(total) + Number(billingLineItem.total) : Number(total);
        },
        0
      );

      return {
        ...state,
        reviewInvoiceBillingLineItems: reviewInvoiceBillingLineItems,
        reviewInvoiceBillingLineItemsSelectedAll: selected,
        exportTotal: total
      };
    },

    setSelectedReviewInvoiceNumber(state, payload) {
      return { ...state, selectedBillingInvoiceNumber: payload.invoiceNumber };
    },

    setPaymentsSelectedAll(state, payload) {
      return { ...state, paymentsSelectedAll: payload };
    },

    setIndividualBillingLineItemSelected(state, payload) {
      const billingLineItems = _.map(state.billingLineItems, (billingLineItem) => {
        if (billingLineItem.bookingBillingLineItemId === payload.bookingBillingLineItemId) {
          billingLineItem.selected = payload.selected;
          return { ...billingLineItem };
        }
        return billingLineItem;
      });

      const selected = _.every(billingLineItems, ['selected', true]);

      const total = _.reduce(
        billingLineItems,
        (total, billingLineItem) => {
          return billingLineItem.selected ? Number(total) + Number(billingLineItem.total) : Number(total);
        },
        0
      );

      return { ...state, billingLineItems, billingLineItemsSelectedAll: selected, exportTotal: total };
    },

    // set selected billing line item for modal
    setSelectedBillingLineItemId(state, payload) {
      return { ...state, selectedBillingLineItemId: payload.billingLineItemId };
    },

    // set selected billing invoice for modal
    setSelectedBillingInvoiceNumber(state, payload) {
      return { ...state, selectedBillingInvoiceNumber: payload.invoiceNumber };
    },

    // ! filter out list of exported billing line items
    updateBillingLineItems(state, payload) {
      const billingLineItems = _.filter(state.billingLineItems, (billingLineItem) => {
        return !_.find(payload, (i) => i === billingLineItem.bookingBillingLineItemId);
      });

      const selected = _.every(billingLineItems, ['selected', true]);
      const total = _.reduce(
        billingLineItems,
        (total, billingLineItem) => {
          return billingLineItem.selected ? Number(total) + Number(billingLineItem.total) : Number(total);
        },
        0
      );

      return { ...state, billingLineItems, billingLineItemsSelectedAll: selected, exportTotal: total };
    },

    updateRejectedList(state, payload) {
      const updatedRejectedList = _.map(payload, (rejected) => {
        rejected.numberOfItems = rejected.billingItems.length;

        // Check if the user still exist
        if (rejected.numberOfItems > 0) {
          // Update Number of Bookings
          rejected.numberOfBooking = _(rejected.billingItems)
            .groupBy('attendanceId')
            .map((items, attendanceId) => ({ attendanceId }))
            .value().length;

          rejected.total = 0;
          rejected.billingTotal = 0;

          _.forEach(rejected.billingItems, (lineItem) => {
            rejected.total += Number(lineItem.total);
            rejected.billingTotal += Number(lineItem.billingTotal);
          });

          return { ...rejected };
        }
      });

      const filteredUpdatedRejectedList = _.filter(updatedRejectedList, (rejected) => {
        return rejected !== undefined;
      });

      return { ...state, rejectedList: filteredUpdatedRejectedList };
    },

    updatePaymentsList(state, payload) {
      const updatedPaymentList = payload.map((payment) => {
        payment.numberOfItems = payment.billingItems.length;

        // Check if the user still exist
        if (payment.numberOfItems > 0) {
          // Update Number of Bookings
          payment.numberOfBooking = _(payment.billingItems)
            .groupBy('attendanceId')
            .map((items, attendanceId) => ({ attendanceId }))
            .value().length;

          payment.total = 0;
          payment.billingTotal = 0;

          _.forEach(payment.billingItems, (lineItem) => {
            payment.total += Number(lineItem.total);
            payment.billingTotal += Number(lineItem.billingTotal);
          });

          // Update Payment Method List
          payment.paymentMethods = _.chain(payment.billingItems)
            .uniqBy('paymentMethod')
            .map((item) => item.paymentMethod)
            .value();

          return { ...payment };
        }
      });

      const filteredUpdatedPaymentList = _.filter(updatedPaymentList, (payment) => {
        return payment !== undefined;
      });

      return { ...state, paymentsList: filteredUpdatedPaymentList };
    },

    updateIndividualBillingLineItem(state, payload: IBillingLineItem[]) {
      const billingLineItems = _.map(state.billingLineItems, (billingLineItem) => {
        if (
          _.find(
            payload,
            (payloadItem) => billingLineItem.bookingBillingLineItemId === payloadItem.bookingBillingLineItemId
          )
        ) {
          return { ...billingLineItem, ...payload };
        }

        return billingLineItem;
      });

      return { ...state, billingLineItems };
    },

    removeInvoiceBillingLineItems(state, payload) {
      const billingLineItems = _.filter(state.billingLineItems, (billingLineItem) => {
        return payload !== billingLineItem.invoiceNumber;
      });

      return { ...state, billingLineItems };
    },

    setExportBatches: (state, payload) => ({ ...state, exportBatches: payload }),

    // manage store's invoice billing line items
    setInvoiceBillingLineItemsSelected(state, payload) {
      const reviewInvoiceBillingLineItems = _.map(state.reviewInvoiceBillingLineItems, (billingLineItem) => {
        if (billingLineItem.invoiceNumber === payload.invoiceNumber) {
          billingLineItem.selected = payload.selected;
          return { ...billingLineItem };
        }
        return billingLineItem;
      });

      const selected = _.every(reviewInvoiceBillingLineItems, ['selected', true]);

      const total = _.reduce(
        reviewInvoiceBillingLineItems,
        (total, billingLineItem) => {
          return billingLineItem.selected ? Number(total) + Number(billingLineItem.total) : Number(total);
        },
        0
      );

      return {
        ...state,
        reviewInvoiceBillingLineItems,
        reviewInvoiceBillingLineItemsSelectedAll: selected,
        exportTotal: total
      };
    },

    setBillingBookings: (state, payload) => ({ ...state, billingBookingsSelectedAll: false, billingBookings: payload }),

    setBillingBookingsFilter: (state, payload) => ({ ...state, billingBookingsFilter: payload }),

    setPaymentsFilter: (state, payload) => ({ ...state, paymentsFilter: payload }),

    setInvoicesFilter: (state, payload) => ({ ...state, invoicesFilter: payload }),

    setShiftHoursFilter: (state, payload) => ({ ...state, shiftHoursFilter: payload }),

    setRejectedFilter: (state, payload) => ({ ...state, rejectedFilter: payload }),

    setBillingBookingsSelected(state, selected: boolean) {
      const billingBookings = _.map(state.billingBookings, (booking) => {
        booking.selected = selected;
        return { ...booking };
      });

      return { ...state, billingBookings, billingBookingsSelectedAll: selected };
    },

    setIndividualBillingBookingSelected(state, payload) {
      const billingBookings = _.map(state.billingBookings, (booking) => {
        if (booking.bookingId === payload.bookingId) {
          booking.selected = payload.selected;
          return { ...booking };
        }
        return booking;
      });

      const selected = _.every(billingBookings, ['selected', true]);

      return { ...state, billingBookings, billingBookingsSelectedAll: selected };
    },

    // ! filter out list of changed bookings (status)
    updateBillingBookings(state, payload) {
      const billingBookings = _.filter(state.billingBookings, (booking) => {
        return !_.find(payload, (i) => i === booking.bookingId);
      });

      const selected = _.every(billingBookings, ['selected', true]);

      return { ...state, billingBookings, billingBookingsSelectedAll: selected };
    },
    setSelectedBatchItem(state, payload) {
      return { ...state, selectedBatch: payload };
    },
    setSelectedBatchLineItems(state, payload) {
      return { ...state, selectedBatchLineItems: payload };
    },
    setSelectedBatchDetail(state, payload) {
      return { ...state, setSelectedBatchDetail: payload };
    },
    setSelectedBatchLineItemsChecked(state, payload) {
      let newBatchLineItems = _.map(state.selectedBatchLineItems, (lineItem: IBatchLineItem) => {
        if (lineItem.historyId === payload) {
          lineItem.isChecked = true;
        }
        return lineItem;
      });
      return { ...state, selectedBatchLineItems: newBatchLineItems };
    },

    setSelectedBatchLineItemsUnChecked(state, payload) {
      let newBatchLineItems = _.map(state.selectedBatchLineItems, (lineItem: IBatchLineItem) => {
        if (lineItem.historyId === payload) {
          lineItem.isChecked = false;
          lineItem.rejectReason = null;
        }
        return lineItem;
      });
      return { ...state, selectedBatchLineItems: newBatchLineItems };
    },

    setSelectedBatchLineItemRejectReason(state, payload) {
      let newBatchLineItems = _.map(state.selectedBatchLineItems, (lineItem: IBatchLineItem) => {
        if (lineItem.historyId === payload.historyId) {
          lineItem.isChecked = true;
          lineItem.rejectReason = payload.rejectReason;
        }
        return lineItem;
      });
      return { ...state, selectedBatchLineItems: newBatchLineItems };
    },

    resetSelectedBatchLineItem(state) {
      let newBatchLineItems = _.map(state.selectedBatchLineItems, (lineItem: IBatchLineItem) => {
        lineItem.isChecked = false;
        return lineItem;
      });
      return { ...state, selectedBatchLineItems: newBatchLineItems };
    },

    setExportInvoice: (state, payload) => ({ ...state, exportInvoices: payload }),
    setSelectedInvoice: (state, payload) => ({ ...state, selectedInvoice: payload }),
    setSelectedInvoices: (state, payload) => ({ ...state, selectedInvoices: payload }),
    setIsCheckAll: (state, payload) => ({ ...state, isCheckAll: payload }),
    setFetchInvoicesRequest: (state, payload) => ({ ...state, fetchInvoicesRequest: payload }),
    setMarkAsPaidType: (state, payload) => ({ ...state, markAsPaidType: payload }),
    setMarkAsUnPaidType: (state, payload) => ({ ...state, markAsUnPaidType: payload }),
    setSelectedRejected: (state, payload) => ({ ...state, selectedRejected: payload }),
    setSelectedInvoiceLineItems: (state, payload) => ({ ...state, selectedInvoiceLineItems: payload }),
    rejectSelectedInvoiceLineItems: (state) => {
      const selectedInvoiceItems = state.selectedInvoiceLineItems;
      const newItems = _.map(selectedInvoiceItems, (item) => {
        item.isRejected = true;
        return item;
      });
      return { ...state, selectedInvoiceLineItems: newItems };
    },
    setSelectedBillingLineItem: (state, payload) => ({ ...state, selectedBillingLineItem: payload }),
    setBatchAuthors: (state, payload) => ({ ...state, batchAuthors: payload }),
    setBatchAuthorsFilter: (state, payload) => ({ ...state, batchAuthorsFilter: payload })
  },
  effects: (dispatch) => ({
    async doFetchBillingLineItemsToReview(payload, rootState) {
      const fullUrl = '/api/portal/billing/review';
      // ! If filter's dateRange is empty, set billingLineItems to empty and no need to make api request
      if (_.isEmpty(payload)) {
        dispatch.billingsStore.setBillingLineItems([]);
        return true;
      } else {
        const filter: any = {};
        filter.paymentStatus = ['SEND_TO_FINANCE'];
        filter.paymentMethod = ['NDIA', 'PLAN', 'SELF'];
        // if (!_.isEmpty(payload.filters)) {
        //   filter.startDate = payload.filters[0].value[0];
        //   filter.endDate = payload.filters[0].value[1];
        // }
        filter.startDate = new Date('01/01/2019');
        filter.endDate = new Date('01/01/2021');

        try {
          const resp = await apiClient.post(fullUrl, filter);

          if (resp.status === 200) {
            dispatch.billingsStore.setBillingLineItems(resp.data);
            return true;
          }
        } catch (err) {
          console.error(err);
          throw err;
        }
      }
    },

    async doFetchWaitingPayments(payload, rootState) {
      const fullUrl = '/api/portal/billing/review';

      const filter = payload ? payload : {};
      filter.paymentStatus = ['SEND_TO_FINANCE'];

      try {
        const resp = await apiClient.post(fullUrl, filter);

        if (resp.status === 200) {
          dispatch.billingsStore.setPayments(resp.data);

          // Update overview by adding a isFiltered props.
          const overviewPayments = rootState.billingsStore.overviewPayments;
          const foundLineItems = [];
          _.map(resp.data, (customer) =>
            _.map(customer.billingItems, (lineItem) => foundLineItems.push(lineItem.bookingBillingLineItemId))
          );
          const updatedOverviewPayments = overviewPayments.map((overviewLineItem) => {
            if (_.find(foundLineItems, (lineItem) => overviewLineItem.bookingBillingLineItemId === lineItem)) {
              return { ...overviewLineItem, isFiltered: false };
            } else {
              return { ...overviewLineItem, isFiltered: true };
            }
          });

          dispatch.billingsStore.setOverviewPayments(updatedOverviewPayments);
          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchWaivedPayments(payload) {
      const fullUrl = '/api/portal/billing/review';

      const filter = payload ? payload : {};
      filter.paymentStatus = ['WAIVED'];

      try {
        const resp = await apiClient.post(fullUrl, filter);

        if (resp.status === 200) {
          dispatch.billingsStore.setPayments(resp.data);
          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchInvoices(payload, rootState) {
      const fullUrl = '/api/portal/billing/invoices/list';

      const filter = payload ? payload : {};

      const { selectedInvoices, invoiceList, isCheckAll } = rootState.billingsStore;

      dispatch.billingsStore.setFetchInvoicesRequest(filter);

      try {
        const resp = await apiClient.post(fullUrl, filter);

        if (resp.status === 200) {
          let invoices = resp.data;
          if (payload.page > 1) {
            invoices = invoiceList.concat(invoices);

            if (isCheckAll) {
              //filter invoice list without all items rejected invoices
              const filteredInvoicesList = _.filter(invoices, (invoice) => {
                const items = invoice.items;
                const rejectedItems = _.filter(items, (item) => item.isRejected);
                return rejectedItems.length < items.length;
              });
              dispatch.billingsStore.setSelectedInvoices(filteredInvoicesList);
            }
          }
          dispatch.billingsStore.setInvoices(invoices);
          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchShiftHours(payload) {
      const fullUrl = 'api/portal/support-worker/shift-time';

      const filter = payload ? payload : {};

      try {
        const resp = await apiClient.post(fullUrl, filter);

        if (resp.status === 200) {
          dispatch.billingsStore.setShiftHours(resp.data);
          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchCustomTimesheet(payload) {
      const fullUrl = 'api/portal/support-worker/shift-time/download';

      const filter = payload ? payload : {};

      try {
        const resp = await apiClient.post(fullUrl, filter);

        if (resp.status === 200) {
          dispatch.billingsStore.setZestCareTimesheet(resp.data);
          return resp.data;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchRejected(payload, rootState) {
      const fullUrl = '/api/portal/billing/rejected';

      const filter = payload ? payload : {};

      try {
        const resp = await apiClient.post(fullUrl, filter);

        if (resp.status === 200) {
          let rejected = resp.data;
          if (payload.page > 1) {
            rejected = rootState.billingsStore.batchList.concat(rejected);
          }
          dispatch.billingsStore.setRejected(rejected);
          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchBatches(payload, rootState) {
      const fullUrl = '/api/portal/billing/batch/list';

      const filter = payload ? payload : {};

      try {
        const resp = await apiClient.post(fullUrl, filter);

        if (resp.status === 200) {
          let batches = resp.data;
          if (payload.page > 1) {
            batches = rootState.billingsStore.batchList.concat(batches);
          }
          dispatch.billingsStore.setBatches(batches);
          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchBatchTenciaFile(payload) {
      const fullUrl = `api/portal/billing/batch/${payload}/tencia`;

      const filter = payload ? payload : {};

      try {
        const resp = await apiClient.post(fullUrl, filter);

        if (resp.status === 200) {
          return resp.data;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchExportWaitingPayments(payload, rootState) {
      const fullUrl = '/api/portal/billing/review/export';

      const filter = rootState.billingsStore.paymentsFilter ? rootState.billingsStore.paymentsFilter : {};
      filter.paymentStatus = ['SEND_TO_FINANCE'];

      try {
        const resp = await apiClient.post(fullUrl, filter);
        if (resp.status === 200) {
          return resp.data;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doDownloadNDIAcsv(payload) {
      const endpoint = `/api/portal/billing/batch/${payload}`;
      try {
        let result = await apiClient.post(endpoint);
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doResendEmail(payload) {
      const fullUrl = `/api/portal/billing/invoice/ndis/resend`;

      const filter = payload ? payload : {};

      try {
        const resp = await apiClient.post(fullUrl, filter);

        if (resp.status === 200) {
          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchBatchDetail(payload) {
      const fullUrl = `/api/portal/billing/batch/${payload.batchId}`;

      const filter = payload ? payload : {};

      try {
        const resp = await apiClient.get(fullUrl, filter);

        if (resp.status === 200) {
          dispatch.billingsStore.setBatchDetail(resp.data);
          dispatch.billingsStore.setBatchDetailList(resp.data.listOfInvoice);
          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchOverviewWaitingPayments(payload) {
      const fullUrl = '/api/portal/billing/review/all';

      try {
        const resp = await apiClient.post(fullUrl, payload);
        dispatch.billingsStore.setOverviewPayments(resp.data);
        return true;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchBillingInvoiceLineItemsToReview(payload) {
      const fullUrl = '/api/portal/billing/review';
      // ! If filter's dateRange is empty, set billingLineItems to empty and no need to make api request
      if (_.isEmpty(payload)) {
        dispatch.billingsStore.setReviewInvoiceBillingLineItems([]);
        return true;
      } else {
        const filter: any = {};
        filter.paymentStatus = ['SEND_TO_FINANCE'];
        filter.paymentMethod = payload.paymentMethod;
        if (!_.isEmpty(payload.filters)) {
          filter.startDate = payload.filters[0].value[0];
          filter.endDate = payload.filters[0].value[1];
        }

        try {
          const resp = await apiClient.post(fullUrl, filter);

          if (resp.status === 200) {
            dispatch.billingsStore.setReviewInvoiceBillingLineItems(resp.data);
            return true;
          }
        } catch (err) {
          console.error(err);
          throw err;
        }
      }
    },

    // ! Fetch list of bookings base on filter
    async doFetchBillingLineItemsToApprove(payload) {
      const fullUrl = '/api/portal/billing/approve';

      const filter: any = {};
      if (!_.isEmpty(payload.status)) {
        filter.status = _.map(payload.status, (i) => i.value);
      }

      // ! If filter's dateRange is empty, set billingBookings to empty and no need to make api request
      if (_.isEmpty(payload.dateRange)) {
        dispatch.billingsStore.setBillingBookings([]);
        return true;
      } else {
        filter.dateRange = _.map(payload.dateRange, (i) => i.value);

        try {
          const resp = await apiClient.put(fullUrl, filter);
          if (resp.status === 200) {
            dispatch.billingsStore.setBillingBookings(resp.data);
            return true;
          }
        } catch (err) {
          throw err;
        }
      }
    },

    async doApproveBillingLineItems(payload, rootState) {
      // const fullUrl = '/api/portal/billing/approve';
      const fullUrl = '/api/portal/billing/send-to-finance';

      const bookingIds = _.chain(rootState.billingsStore.billingBookings)
        .filter((booking) => booking.selected === true)
        .map((booking) => {
          return booking.bookingId;
        })
        .value();

      try {
        const resp = await apiClient.put(fullUrl, { bookingIds: bookingIds });
        if (resp.status === 200) {
          //TODO need to check against booking status to hide/show
          dispatch.billingsStore.updateBillingBookings(bookingIds);
          return true;
        }
      } catch (err) {
        throw err;
      }
    },

    async doSendToFinanceBillingLineItems(payload, rootState) {
      const fullUrl = '/api/portal/billing/send-to-finance';

      const bookingIds = _.chain(rootState.billingsStore.billingBookings)
        .filter((booking) => booking.selected === true)
        .map((booking) => {
          return booking.bookingId;
        })
        .value();

      try {
        const resp = await apiClient.put(fullUrl, { bookingIds: bookingIds });
        if (resp.status === 200) {
          dispatch.billingsStore.updateBillingBookings(bookingIds);
          return true;
        }
      } catch (err) {
        throw err;
      }
    },

    async doUpdateBillingLineItem(payload: IBillingLineItem[]) {
      const fullUrl = '/api/portal/billing/item/edit';
      try {
        const billingLineItems = _.map(payload, (lineItem) => {
          lineItem.unitPrice = Number(lineItem.unitPrice);
          lineItem.billingPrice = Number(lineItem.billingPrice);
          lineItem.qty = Number(lineItem.qty);
          lineItem.billingQty = Number(lineItem.billingQty);
          lineItem.total = Number(lineItem.total);
          lineItem.billingTotal = Number(lineItem.billingTotal);
          lineItem.mileagePrice = Number(lineItem.mileagePrice);
          lineItem.travelDistance = Number(lineItem.travelDistance);
          lineItem.isTravel = Boolean(lineItem.isTravel);
          return lineItem;
        });

        const resp = await apiClient.put(fullUrl, { billingLineItems });
        if (resp.status === 200) {
          dispatch.billingsStore.updateIndividualBillingLineItem(payload);
          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doRemoveBillingLineItem(payload, rootState) {
      const fullUrl = '/api/portal/billing/item/remove';

      try {
        const resp = await apiClient.put(fullUrl, payload);

        if (resp.status === 200) {
          const paymentsList = rootState.billingsStore.paymentsList;

          const newPaymentsList = paymentsList.map((payment) => {
            const newBillingItems = payment.billingItems.filter((lineItem) => {
              return lineItem.bookingBillingLineItemId !== payload.bookingBillingLineItemId;
            });
            return { ...payment, serviceBillingItems: newBillingItems };
          });

          dispatch.billingsStore.updatePaymentsList(newPaymentsList);

          const overviewPayments = rootState.billingsStore.overviewPayments;

          const updatedOverviewPayments = overviewPayments.filter(
            (lineItem) => lineItem.bookingBillingLineItemId !== payload.bookingBillingLineItemId
          );

          dispatch.billingsStore.setOverviewPayments(updatedOverviewPayments);

          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doRejectInvoice(payload, rootState) {
      const fullUrl = '/api/portal/billing/invoice/reject';

      try {
        const resp = await apiClient.put(fullUrl, payload);

        if (resp.status === 200) {
          _.map(rootState.billingsStore.invoiceList, (invoice) => {
            if (invoice.invoiceId === payload.invoiceId) {
              _.map(invoice.items, (lineItem) => {
                lineItem.isRejected = true;
              });
            }
          });
          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doRejectInvoiceLineItem(payload, rootState) {
      const fullUrl = '/api/portal/billing/invoice/item/reject';

      try {
        const resp = await apiClient.put(fullUrl, payload);

        if (resp.status === 200) {
          _.map(rootState.billingsStore.invoiceList, (invoice) => {
            _.map(invoice.items, (lineItem) => {
              if (lineItem.historyId === payload.historyId) {
                lineItem.isRejected = true;
              }
            });
          });
          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doRejectBillingLineItemsFromProcessed(payload, rootState) {
      const fullUrl = '/api/portal/billing/booking-line-item/reject';

      try {
        const resp = await apiClient.post(fullUrl, payload);

        if (resp.status === 200) {
          const newInvoiceList = [...rootState.billingsStore.invoiceList];
          const attendanceIds = _.map(payload.billingLineItems, (rejectLineItem) => rejectLineItem.attendanceId);
          const billingLineItemIds = _.map(
            payload.billingLineItems,
            (rejectLineItem) => rejectLineItem.billingLineItemId
          );

          _.forEach(newInvoiceList, (invoice) => {
            if (attendanceIds.includes(invoice.attendanceId)) {
              _.forEach(invoice.items, (lineItem) => {
                if (_.includes(billingLineItemIds, lineItem.bookingBillingLineItemId)) {
                  lineItem.isRejected = true;
                }
              });
            }
          });

          dispatch.billingsStore.setInvoices(newInvoiceList);

          return resp.data;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doProcessPayments(payload, rootState) {
      const fullUrl =
        payload.paymentSourceType === PaymentSources.NDIS
          ? '/api/portal/billing/batch/process-ndis'
          : '/api/portal/billing/batch/process-vcp';

      try {
        const resp = await apiClient.post(fullUrl, { billingLineItems: payload.billingLineItems });

        if (resp.status === 200) {
          const paymentsList = rootState.billingsStore.paymentsList;

          const newPaymentsList = paymentsList.map((payment) => {
            const newBillingItems = payment.billingItems.filter((lineItem) => {
              return !_.find(
                payload.billingLineItems,
                (i) => i.bookingBillingLineItemId === lineItem.bookingBillingLineItemId
              );
            });
            return { ...payment, billingItems: newBillingItems };
          });

          dispatch.billingsStore.updatePaymentsList(newPaymentsList);

          const overviewPayments = rootState.billingsStore.overviewPayments;

          const updatedOverviewPayments = overviewPayments.filter((lineItem) => {
            return !_.find(
              payload.billingLineItems,
              (i) => i.bookingBillingLineItemId === lineItem.bookingBillingLineItemId
            );
          });

          dispatch.billingsStore.setOverviewPayments(updatedOverviewPayments);

          return resp.data;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doRejectLineItem(payload) {
      const fullUrl = '/api/portal/billing/invoice/item/reject';

      try {
        const resp = await apiClient.put(fullUrl, payload);
        if (resp.status === 200) {
          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doRemoveInvoiceBillingLineItem(payload) {
      const fullUrl = '/api/portal/billing/item/list/remove';

      try {
        const resp = await apiClient.put(fullUrl, { billingLineItems: payload.billingLineItems });
        if (resp.status === 200) {
          dispatch.billingsStore.removeInvoiceBillingLineItems(payload.invoiceNumber);
          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doFetchExportBatches(payload) {
      const fullUrl = '/api/portal/billing/batch/list';
      if (!_.isEmpty(payload)) {
        let filter: any = {
          exportedDate: payload
        };
        payload = filter;
      }
      try {
        const resp = await apiClient.post(fullUrl, payload);

        if (resp.status === 200) {
          dispatch.billingsStore.setExportBatches(resp.data);
          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doGetExportBatch(payload) {
      const fullUrl = `/api/portal/billing/batch/${payload.batchId}`;
      try {
        const resp = await apiClient.get(fullUrl, payload);
        if (resp.status === 200) {
          dispatch.billingsStore.setBillingLineItems(resp.data);
          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doRemoveBillingLineItemFromBatch(payload, rootState: IRootState) {
      const fullUrl = '/api/portal/billing/batch/reject';

      try {
        const resp = await apiClient.put(fullUrl, payload);
        if (resp.status === 200) {
          const newList = _.map(rootState.billingsStore.selectedBatchLineItems, (item) => {
            if (_.findIndex(payload.items, (ids: any) => ids.historyId === item.historyId) > -1) {
              item.isRejected = true;
            }
            return item;
          });

          dispatch.billingsStore.setSelectedBatchLineItems(newList);
          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },

    async doGetTestBatchExportData(payload, rootState) {
      const fullUrl = '/api/portal/billing/batch/test';

      const items = _.chain(rootState.billingsStore.billingLineItems)
        .filter((lineItem) => lineItem.selected === true)
        .map((lineItem) => {
          return {
            bookingBillingLineItemId: lineItem.bookingBillingLineItemId,
            attendanceId: lineItem.attendanceId
          };
        })
        .value();

      try {
        const resp = await apiClient.post(fullUrl, { items });
        return resp.data;
      } catch (e) {
        throw e;
      }
    },

    async doCreateBatch(payload, rootState) {
      const fullUrl = '/api/portal/billing/batch/create';

      const items = _.chain(rootState.billingsStore.billingLineItems)
        .filter((lineItem) => lineItem.selected === true)
        .map((lineItem) => {
          return {
            bookingBillingLineItemId: lineItem.bookingBillingLineItemId,
            attendanceId: lineItem.attendanceId
          };
        })
        .value();

      try {
        const resp = await apiClient.post(fullUrl, { items });

        return resp.data;
      } catch (e) {
        throw e;
      }
    },

    async doUpdateBookingBillingItemsInApprove(payload, rootState) {
      const endPoint = `/api/portal/bookings/billing`;
      const request = { bookingId: payload.bookingId, lineItems: payload.lineItems };
      try {
        let result = await apiClient.put(endPoint, request);
        let billingBookings = rootState.billingsStore.billingBookings;
        let newBillingBookings = _.map(billingBookings, (booking) => {
          if (booking.bookingId === payload.bookingId) {
            booking.billingLineItems = payload.lineItems;
          }
          return booking;
        });
        dispatch.billingsStore.setBillingBookings(newBillingBookings);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    // fetch funded Categories

    async doFetchFundedCategories(payload, rootState) {
      const { bookingId } = payload;

      try {
        let result = await apiClient.get(`/api/portal/billing/item/funding/${bookingId}`);

        if (!_.isEmpty(result.data)) {
          dispatch.billingsStore.setSelectedFundedCategories(result.data.fundedCategories);
          dispatch.billingsStore.setSelectedLineItemMmmData({
            mmmGroup: result.data.mmmGroup,
            locationState: result.data.state
          });
        } else {
          throw new Error('No funding categories found.');
        }
        return result.data;
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    // Update Line Item

    async doUpdateLineItem(payload, rootState) {
      try {
        const payloadLineItem = payload.lineItem;
        const type = payload.type;
        let result = await apiClient.put(`api/portal/billing/item/update`, payloadLineItem);

        if (result.status === 200) {
          if (type === 'payment') {
            const newPaymentsList = _.cloneDeep(rootState.billingsStore.paymentsList);
            const payment = _.find(
              newPaymentsList,
              (payment) => !!_.find(payment.billingItems, (item) => item.attendanceId === payloadLineItem.attendanceId)
            );
            payment.billingItems = _.map(payment.billingItems, (lineItem) => {
              if (lineItem.bookingBillingLineItemId === payloadLineItem.bookingBillingLineItemId)
                return { ...payloadLineItem };
              else return { ...lineItem };
            });
            dispatch.billingsStore.updatePaymentsList(newPaymentsList);

            const overviewPayments = rootState.billingsStore.overviewPayments;
            const updatedOverviewPayments = _.map(overviewPayments, (item) => {
              if (item.bookingBillingLineItemId === payloadLineItem.bookingBillingLineItemId) {
                return { ...payloadLineItem };
              } else {
                return { ...item };
              }
            });
            dispatch.billingsStore.setOverviewPayments(updatedOverviewPayments);
          } else if (type === 'rejected') {
            const newRejectedList = _.cloneDeep(rootState.billingsStore.rejectedList);
            const rejected = _.find(
              newRejectedList,
              (rejected) =>
                !!_.find(rejected.billingItems, (item) => item.attendanceId === payloadLineItem.attendanceId)
            );
            rejected.billingItems = _.map(rejected.billingItems, (lineItem) => {
              if (lineItem.bookingBillingLineItemId === payloadLineItem.bookingBillingLineItemId)
                return { ...payloadLineItem };
              else return { ...lineItem };
            });
            dispatch.billingsStore.updateRejectedList(newRejectedList);
          }
        }
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    // Re-Approve Line Item

    async doReApproveLineItems(payload, rootState) {
      try {
        let result = await apiClient.put(`api/portal/billing/items/reapprove`, payload);

        if (result.status === 200) {
          const newRejectedList = _.cloneDeep(rootState.billingsStore.rejectedList);
          _.forEach(payload.billingLineItems, (changedLineItem) => {
            const rejected = _.find(
              newRejectedList,
              (rejected) =>
                !!_.find(rejected.billingItems, (item) => item.attendanceId === changedLineItem.attendanceId)
            );
            rejected.billingItems = _.filter(
              rejected.billingItems,
              (lineItem) =>
                !_.find(
                  payload.billingLineItems,
                  (line) => lineItem.bookingBillingLineItemId === line.bookingBillingLineItemId
                )
            );
          });
          dispatch.billingsStore.updateRejectedList(newRejectedList);
        }
      } catch (err) {
        console.warn(err);
        throw err;
      }
    },

    /*
     *
     * Billing Line Items
     *
     */

    async doCreateBookingBillingLineItem(payload, rootState) {
      const endPoint = `/api/portal/billing/booking/${rootState.bookingsStore.selectedBookingItem.bookingId}/billing`;
      try {
        let result = await apiClient.post(endPoint, payload);

        dispatch.bookingsStore.setSelectedBookingItem({
          ...rootState.bookingsStore.selectedBookingItem,
          ...result.data
        });
        return result.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doUpdateBookingBillingLineItem(payload, rootState) {
      const endPoint = `/api/portal/billing/booking/${rootState.bookingsStore.selectedBookingItem.bookingId}/billing/${payload.bookingBillingLineItemId}`;
      try {
        let result = await apiClient.put(endPoint, payload);

        dispatch.bookingsStore.setSelectedBookingItem({
          ...rootState.bookingsStore.selectedBookingItem,
          ...result.data
        });

        return result.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doDeleteBookingBillingLineItem(payload, rootState) {
      const endPoint = `/api/portal/billing/booking/${rootState.bookingsStore.selectedBookingItem.bookingId}/billing/${payload.bookingBillingLineItemId}`;
      try {
        let result = await apiClient.delete(endPoint);

        dispatch.bookingsStore.setSelectedBookingItem({
          ...rootState.bookingsStore.selectedBookingItem,
          ...result.data
        });
        return result.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doWaiveBookingBillingLineItem(payload, rootState) {
      const endPoint = `/api/portal/billing/booking/${rootState.bookingsStore.selectedBookingItem.bookingId}/billing/${payload.bookingBillingLineItemId}/waive`;
      try {
        let result = await apiClient.put(endPoint);

        dispatch.bookingsStore.setPaymentStatus(result.data);
        dispatch.bookingsStore.setBillingLineItems(result.data.billingLineItems);
        return result.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doUnwaiveBookingBillingLineItem(payload, rootState) {
      const endPoint = `/api/portal/billing/booking/${rootState.bookingsStore.selectedBookingItem.bookingId}/billing/${payload.bookingBillingLineItemId}/un-waive`;
      try {
        let result = await apiClient.put(endPoint);

        dispatch.bookingsStore.setPaymentStatus(result.data);
        dispatch.bookingsStore.setBillingLineItems(result.data.billingLineItems);
        return result.data;
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    /**
     * payload will be  {list: [{bookingBillingLineItemId,attendanceId,waiveReason}]}
     * @param payload
     * @param rootState
     */
    async doWaiveBillingLineItem(payload, rootState) {
      const endpoint = `/api/portal/billing/items/waive`;
      try {
        const payloadLineItem = { list: payload.list };
        const type = payload.type;

        let result = await apiClient.put(endpoint, payloadLineItem);

        if (result.status === 200) {
          if (type === 'payment' || type === 'preview') {
            const newPaymentsList = _.cloneDeep(rootState.billingsStore.paymentsList);
            _.forEach(payload.list, (changedLineItem) => {
              const payment = _.find(
                newPaymentsList,
                (payment) =>
                  !!_.find(payment.billingItems, (item) => item.attendanceId === changedLineItem.attendanceId)
              );
              if (payment) {
                payment.billingItems = _.filter(
                  payment.billingItems,
                  (lineItem) =>
                    !_.find(payload.list, (line) => lineItem.bookingBillingLineItemId === line.bookingBillingLineItemId)
                );
              }
            });
            dispatch.billingsStore.updatePaymentsList(newPaymentsList);
            const overviewPayments = rootState.billingsStore.overviewPayments;
            const updatedOverviewPayments = _.filter(
              overviewPayments,
              (item) =>
                !_.find(payload.list, (lineItem) => lineItem.bookingBillingLineItemId === item.bookingBillingLineItemId)
            );
            dispatch.billingsStore.setOverviewPayments(updatedOverviewPayments);
          } else if (type === 'rejected') {
            const newRejectedList = _.cloneDeep(rootState.billingsStore.rejectedList);
            _.forEach(payload.list, (changedLineItem) => {
              const rejected = _.find(
                newRejectedList,
                (rejected) =>
                  !!_.find(rejected.billingItems, (item) => item.attendanceId === changedLineItem.attendanceId)
              );
              rejected.billingItems = _.filter(
                rejected.billingItems,
                (lineItem) =>
                  !_.find(payload.list, (line) => lineItem.bookingBillingLineItemId === line.bookingBillingLineItemId)
              );
            });
            dispatch.billingsStore.updateRejectedList(newRejectedList);
          }
        }
        if (type === 'preview') {
          const newSelectedLineItems = _.filter(
            _.cloneDeep(rootState.billingsStore.selectedBillingLineItem),
            (lineItem) =>
              !_.find(
                payload.list,
                (changedLineItem) => lineItem.bookingBillingLineItemId === changedLineItem.bookingBillingLineItemId
              )
          );
          dispatch.billingsStore.setSelectedBillingLineItem(newSelectedLineItems);
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    /**
     * payload will be  {list: [{bookingBillingLineItemId,attendanceId}]}
     * @param payload
     * @param rootState
     */
    async doUnwaiveBillingLineItem(payload, rootState) {
      const endpoint = `/api/portal/billing/items/un-waive`;
      try {
        let result = await apiClient.put(endpoint, payload);
        if (result.status === 200) {
          const newPaymentsList = _.cloneDeep(rootState.billingsStore.paymentsList);
          _.forEach(payload.list, (changedLineItem) => {
            const payment = _.find(
              newPaymentsList,
              (payment) => !!_.find(payment.billingItems, (item) => item.attendanceId === changedLineItem.attendanceId)
            );
            payment.billingItems = _.filter(
              payment.billingItems,
              (lineItem) =>
                !_.find(payload.list, (line) => lineItem.bookingBillingLineItemId === line.bookingBillingLineItemId)
            );
          });
          dispatch.billingsStore.updatePaymentsList(newPaymentsList);
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doDeleteBillingLineItem(payload, rootState) {
      const { bookingBillingLineItemId, attendanceId } = payload;
      const endPoint = `/api/portal/billing/booking/${attendanceId}/billing/${bookingBillingLineItemId}`;
      try {
        let result = await apiClient.delete(endPoint, payload);
        if (result.status === 200) {
          const paymentsList = rootState.billingsStore.paymentsList;

          const newPaymentsList = paymentsList.map((payment) => {
            const newBillingItems = _.filter(
              payment.billingItems,
              (lineItem) => lineItem.bookingBillingLineItemId !== bookingBillingLineItemId
            );
            return { ...payment, billingItems: newBillingItems };
          });
          dispatch.billingsStore.updatePaymentsList(newPaymentsList);
          dispatch.billingsStore.setOverviewPayments(newPaymentsList);
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doFetchBatchItemListItems(payload, rootState) {
      const selectedBatchItem = rootState.billingsStore.selectedBatch;
      const endpoint = `/api/portal/billing/batch/${selectedBatchItem.batchId}`;
      try {
        let result = await apiClient.get(endpoint);
        const exportedLineItem = result.data;
        dispatch.billingsStore.setSelectedBatchLineItems(exportedLineItem);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doReDownloadBatchData(payload, rootState) {
      const selectedBatchItem = rootState.billingsStore.selectedBatch;
      const endpoint = `/api/portal/billing/batch/${selectedBatchItem.batchId}`;
      try {
        let result = await apiClient.post(endpoint);
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doFetchExportedInvoice(payload) {
      const fullUrl = '/api/portal/billing/invoice/list';
      if (!_.isEmpty(payload)) {
        let filter: any = {
          startDateRange: payload.startDateRange,
          issueDate: payload.issueDate,
          invoiceNumber: payload.invoiceNumber,
          firstName: payload.firstName,
          lastName: payload.lastName,
          serviceName: payload.serviceName
        };
        payload = filter;
      }
      try {
        const resp = await apiClient.post(fullUrl, payload);

        if (resp.status === 200) {
          dispatch.billingsStore.setExportInvoice(resp.data);
          return true;
        }
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async doFetchSelectedInvoiceLineItems(payload, rootState) {
      const selectedInvoice = rootState.billingsStore.selectedInvoice;
      const endpoint = `/api/portal/billing/invoice/${selectedInvoice.invoiceId}`;
      try {
        let result = await apiClient.get(endpoint);
        if (!_.isEmpty(result.data)) {
          dispatch.billingsStore.setSelectedInvoiceLineItems(result.data);
        }
      } catch (e) {
        throw e;
      }
    },

    async doRemoveSelectedInvoiceItem(payload) {
      const endpoint = `/api/portal/billing/invoice/items/reject`;
      try {
        let result = await apiClient.put(endpoint, payload);
        dispatch.billingsStore.rejectSelectedInvoiceLineItems();
      } catch (e) {
        throw e;
      }
    },

    async doCreateInvoiceBatch(payload, rootState) {
      const fullUrl = '/api/portal/billing/invoice/batch';

      const items = _.chain(rootState.billingsStore.reviewInvoiceBillingLineItems)
        .filter((lineItem) => lineItem.selected === true)
        .map((lineItem) => {
          return {
            bookingBillingLineItemId: lineItem.bookingBillingLineItemId,
            attendanceId: lineItem.attendanceId
          };
        })
        .value();

      try {
        const resp = await apiClient.post(fullUrl, { items });

        return resp.data;
      } catch (e) {
        throw e;
      }
    },

    async doFetchPreviewInvoicePDF(payload) {
      try {
        const endpoint = `/viewpdf/portal/invoice/preview/${payload.invoiceNumber}`;
        const result = await apiCloudFunctionClient.get(endpoint);

        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doFetchViewInvoicePDF(payload) {
      try {
        const endpoint = `/viewpdf/portal/invoice/view/${payload.invoiceId}`;
        const result = await apiClient.get(endpoint);

        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doFetchPreviewInvoiceByLineItemPDF(payload) {
      try {
        const endpoint = `/api/portal/billing/invoice/preview`;
        const result = await apiClient.post(endpoint, payload);

        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doFetchInvoiceBookingList(payload) {
      try {
        const endpoint = `/api/portal/billing/invoice/${payload.invoiceId}/bookings/list`;
        const result = await apiClient.get(endpoint);
        return result.data;
      } catch (e) {
        throw e;
      }
    },

    async doFetchBatchAuthors(payload, rootState) {
      try {
        const filter = rootState.billingsStore.batchAuthorsFilter;
        let filterData = {};
        if (!_.isEmpty(filter)) {
          _.map(filter, (filterObject) => {
            filterData = { ...filterData, [filterObject.key]: filterObject.value };
          });
        }
        const result = await apiClient.post(`/api/portal/billing/batch/authors`, filterData);
        dispatch.billingsStore.setBatchAuthors(result.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchPreviewCreditNoteByLineItemPDF(payload, rootState) {
      try {
        const endpoint = `/viewpdf/portal/billing/credit-note/preview`;
        const result = await apiClient.post(endpoint, payload);

        return result.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doMarkAsPaidInvoice(payload, rootState) {
      try {
        const endpoint = `api/portal/billing/invoices/paid`;
        const result = await apiClient.put(endpoint, payload);

        dispatch.billingsStore.doFetchInvoices({
          ...rootState.billingsStore.fetchInvoicesRequest,
          page: 1
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doMarkAsUnPaidInvoice(payload, rootState) {
      try {
        const endpoint = `api/portal/billing/invoices/unpaid`;
        await apiClient.put(endpoint, payload);

        dispatch.billingsStore.doFetchInvoices({
          ...rootState.billingsStore.fetchInvoicesRequest,
          page: 1
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    }
  })
};

export default billingsStore;
