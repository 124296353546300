import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { Col, Icon, Radio, Row, Tag } from 'antd';
import ActionSelectItem from 'common-components/items/ActionSelectItem';
import { ServicePermissionType } from 'utilities/enum-utils';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { tagColors } from 'theme/theme-variables';
import _ from 'lodash';
import SpinningLoader from 'common-components/loading/SpinningLoader';

interface ICustomFlag {
  serviceId: string;
  accessFlag: ServicePermissionType;
}

interface IServicesSectionModalProps {
  isOpen: any;
  onClose: any;
  setSelectedOption: (servicePermission) => any;
  departmentId: string;
  servicePermission?: ServicePermissionType;
  doFetchServiceDepartmentDetailsLite: typeof dispatch.servicesStore.doFetchServiceDepartmentDetailsLite;
  departmentCustomFlags?: any;
}

interface IServicesSectionModalState {
  isLoading: boolean;
  isSaving: boolean;
  servicePermission: string;
  customFlags: any;
  services: any;
}

class ServicesSectionModal extends Component<IServicesSectionModalProps, IServicesSectionModalState> {
  state = {
    isLoading: false,
    isSaving: false,
    servicePermission: ServicePermissionType.NO_ACCESS,
    customFlags: [],
    services: []
  };

  private initProps = (services, initServicePermission = false) => {
    const { departmentCustomFlags } = this.props;

    this.setState({
      servicePermission: initServicePermission ? this.props.servicePermission : this.state.servicePermission,
      customFlags: _.map(services, (service) => {
        const existingCustomFlag = _.find(departmentCustomFlags, (flag) => flag.serviceId === service.serviceId);
        if (existingCustomFlag) {
          return existingCustomFlag;
        } else {
          return { serviceId: service.serviceId, accessFlag: ServicePermissionType.NO_ACCESS };
        }
      }),
      services,
      isLoading: false
    });
  };

  private _onCloseModal = () => {
    this.setState({
      isLoading: false,
      isSaving: false,
      servicePermission: ServicePermissionType.NO_ACCESS,
      customFlags: [],
      services: []
    });
    this.props.onClose();
  };

  private _setSelectedOption = async () => {
    this.setState({ isSaving: true });
    await this.props.setSelectedOption({
      servicePermission: this.state.servicePermission,
      departmentId: this.props.departmentId,
      customFlags: _.filter(this.state.customFlags, (flag) => flag.accessFlag !== ServicePermissionType.NO_ACCESS)
    });
    this._onCloseModal();
  };

  private _getServiceDepartmentServices = async () => {
    const result: any = await this.props.doFetchServiceDepartmentDetailsLite({
      serviceDepartmentId: this.props.departmentId
    });
    return result.data.services;
  };

  private _changePermissionMode = async (newMode) => {
    this.setState({ servicePermission: newMode });
    if (newMode === ServicePermissionType.CUSTOM && _.isEmpty(this.state.services)) {
      this.setState({ isLoading: true });
      this.initProps(await this._getServiceDepartmentServices());
    }
  };

  private _changeCustomPermission = (serviceId, newAccessFlag) => {
    this.setState({
      customFlags: _.map(this.state.customFlags, (customFlag) => {
        return customFlag.serviceId === serviceId ? { ...customFlag, accessFlag: newAccessFlag } : { ...customFlag };
      })
    });
  };

  resetPermissions = () => {};

  componentDidMount = async () => {
    this.initProps([], true);
  };

  componentDidUpdate = async (
    prevProps: Readonly<IServicesSectionModalProps>,
    prevState: Readonly<IServicesSectionModalState>,
    snapshot?: any
  ) => {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
      this.initProps(
        _.isEmpty(this.state.services) && this.props.servicePermission === ServicePermissionType.CUSTOM
          ? await this._getServiceDepartmentServices()
          : [],
        true
      );
    }
  };

  render() {
    const { servicePermission, services, isLoading } = this.state;

    return (
      <ActionModal
        title={
          <>
            <Icon type={'apartment'} rotate={90} className={'mr-small text-size-x2-large'} />
            Service section
          </>
        }
        isOpen={this.props.isOpen}
        onClose={this._onCloseModal}
        width="x-large"
      >
        <div className="mv-small anim-slide-left">
          <div className={'mb-x-large'}>
            <div className={'mb-small'}>
              <Text size={'x-large'}>General permissions</Text>
            </div>

            <ActionSelectItem
              selectedAction={servicePermission}
              value={ServicePermissionType.NO_ACCESS}
              title={'No access (default)'}
              description={'Cannot see or access this section'}
              onSetAction={this._changePermissionMode}
            />
            <ActionSelectItem
              selectedAction={servicePermission}
              value={ServicePermissionType.MEMBER}
              title={
                <>
                  Member of <i>all</i> services
                </>
              }
              description={'Can see and perform basic actions for all services within this section'}
              onSetAction={this._changePermissionMode}
            />
            <ActionSelectItem
              selectedAction={servicePermission}
              value={ServicePermissionType.MANAGER}
              title={
                <>
                  Manager of <i>all</i> services
                </>
              }
              description={'Can see and perform all actions for all services within this section'}
              onSetAction={this._changePermissionMode}
            />
            <ActionSelectItem
              selectedAction={servicePermission}
              value={ServicePermissionType.CUSTOM}
              title={'Custom'}
              description={'Assign custom permissions on a per-service basis'}
              onSetAction={this._changePermissionMode}
            />
          </div>

          {servicePermission === ServicePermissionType.CUSTOM &&
            (isLoading ? (
              <SpinningLoader size={100} message={'Fetching services...'} />
            ) : !_.isEmpty(services) ? (
              <div>
                <div className={'mb-medium'}>
                  <Text size={'x-large'}>Custom service settings</Text>
                </div>
                <div>
                  <Row className="pb-small bordered-bottom">
                    <Col span={11}>
                      <b>Service</b>
                    </Col>
                    <Col span={4} />
                    <Col span={3}>
                      <b>Manager</b>
                    </Col>
                    <Col span={3}>
                      <b>Member</b>
                    </Col>
                    <Col span={3}>
                      <b>No access</b>
                    </Col>
                  </Row>
                  <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '25vh' }}>
                    {_.map(services, (service, key) => {
                      const modeSelected = _.find(
                        this.state.customFlags,
                        (flag) => flag.serviceId === service.serviceId
                      ).accessFlag;
                      return (
                        <Row key={key} className="bordered-bottom" type={'flex'} align="middle">
                          <Col
                            span={11}
                            title={service.serviceName}
                            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                          >
                            {service.serviceName}
                          </Col>
                          <Col span={4}>
                            <Tag
                              color={
                                service.status === 'DRAFT'
                                  ? tagColors.ORANGE
                                  : service.status === 'PUBLISHED'
                                  ? tagColors.GREEN
                                  : tagColors.COBALT
                              }
                            >
                              {service.status}
                            </Tag>
                          </Col>
                          <Col
                            span={3}
                            className={`text-align-center pv-small cursor-pointer ${
                              modeSelected === ServicePermissionType.MANAGER ? 'bg-blue-lightest' : 'hover-bg-tertiary'
                            }`}
                            onClick={() =>
                              this._changeCustomPermission(service.serviceId, ServicePermissionType.MANAGER)
                            }
                          >
                            {modeSelected === ServicePermissionType.MANAGER ? (
                              <div>
                                <Icon
                                  type="check-circle"
                                  style={{ fontSize: '16px' }}
                                  theme="filled"
                                  className="text-color-blue-light"
                                />
                              </div>
                            ) : (
                              <Radio
                                onClick={() =>
                                  this._changeCustomPermission(service.serviceId, ServicePermissionType.MANAGER)
                                }
                              />
                            )}
                          </Col>
                          <Col
                            span={3}
                            className={`text-align-center pv-small cursor-pointer ${
                              modeSelected === ServicePermissionType.MEMBER ? 'bg-blue-lightest' : 'hover-bg-tertiary'
                            }`}
                            onClick={() =>
                              this._changeCustomPermission(service.serviceId, ServicePermissionType.MEMBER)
                            }
                          >
                            {modeSelected === ServicePermissionType.MEMBER ? (
                              <Icon
                                type="check-circle"
                                style={{ fontSize: '16px' }}
                                theme="filled"
                                className="text-color-blue-light"
                              />
                            ) : (
                              <Radio
                                onClick={() =>
                                  this._changeCustomPermission(service.serviceId, ServicePermissionType.MEMBER)
                                }
                              />
                            )}
                          </Col>
                          <Col
                            span={3}
                            className={`text-align-center pv-small cursor-pointer ${
                              modeSelected === ServicePermissionType.NO_ACCESS
                                ? 'bg-blue-lightest'
                                : 'hover-bg-tertiary'
                            }`}
                            onClick={() =>
                              this._changeCustomPermission(service.serviceId, ServicePermissionType.NO_ACCESS)
                            }
                          >
                            {modeSelected === ServicePermissionType.NO_ACCESS ? (
                              <Icon
                                type="check-circle"
                                style={{ fontSize: '16px' }}
                                theme="filled"
                                className="text-color-blue-light"
                              />
                            ) : (
                              <Radio
                                onClick={() =>
                                  this._changeCustomPermission(service.serviceId, ServicePermissionType.NO_ACCESS)
                                }
                              />
                            )}
                          </Col>
                        </Row>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <>No Services</>
            ))}

          <ActionModalFooter>
            <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" loading={this.state.isSaving} onClick={this._setSelectedOption}>
              Save changes
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServiceDepartmentDetailsLite: dispatch.servicesStore.doFetchServiceDepartmentDetailsLite
});

export default connect(
  null,
  mapDispatch
)(ServicesSectionModal);
