import React, { Component } from 'react';
import _ from 'lodash';
import { Paragraph, Text, Title } from 'common-components/typography';
import { GhostButton, PrimaryButton } from '../../../../../../common-components/buttons';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Form, notification, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Spinner } from '@blueprintjs/core';
import FundingEditCategoriesPanel from 'views/customers/details/tabs-panel/funding/components/FundingEditCategoriesPanel';
import EditFundingWarningModal from 'views/customers/details/tabs-panel/funding/components/EditFundingWarningModal';

interface IFundEditSupportCategoryPanelProps extends FormComponentProps {
  closeEditCategoryModal: () => void;
  packageId: string;
  selectedCustomer: any;
  doUpdateFundingPackageCategory: typeof dispatch.customersStore.doUpdateFundingPackageCategory;
}

interface IFundEditSupportCategoryPanelState {
  isLoading: boolean;
  categoryData: any[];
  isDeleteActionModalOpen: boolean;
  isCancelActionModalOpen: boolean;
  rowID: number;
  isAddNewCategory: boolean;
  isSaving: boolean;
  isActionModalOpen: boolean;
  isEditWarningModalOpen: boolean;
  errorType: any;
}

class FundEditSupportCategoryPanel extends Component<
  IFundEditSupportCategoryPanelProps,
  IFundEditSupportCategoryPanelState
> {
  private categoryDataRefs: any[] = [];

  state = {
    isLoading: false,
    isDeleteActionModalOpen: false,
    isCancelActionModalOpen: false,
    isAddNewCategory: false,
    rowID: -1,
    categoryData: this.props.selectedCustomer.funding.NDISFundingPackages,
    isSaving: false,
    isActionModalOpen: false,
    isEditWarningModalOpen: false,
    errorType: null
  };

  componentWillMount() {
    const { selectedCustomer, packageId } = this.props;
    let fundpackages = selectedCustomer.funding.NDISFundingPackages;
    const selectedPackage = _.find(fundpackages, (fundpackage) => fundpackage.fundingPackageId === packageId);
    let categoryData = selectedPackage.fundingNDISSupportCategories;

    this.setState({ categoryData: categoryData });
  }

  private _closeCancelActionModal = () => {
    this.setState({ isCancelActionModalOpen: false });
  };

  private _closeCancelModalWithAction = () => {
    const { selectedCustomer, packageId } = this.props;
    let fundpackages = selectedCustomer.funding.NDISFundingPackages;
    const selectedPackage = _.find(fundpackages, (fundpackage) => fundpackage.fundingPackageId === packageId);
    let previousCategoryData = selectedPackage.fundingNDISSupportCategories;

    if (!_.isEqual(this.state.categoryData, previousCategoryData)) {
      this.setState({ isCancelActionModalOpen: true });
    } else {
      this.props.closeEditCategoryModal();
    }
  };

  private _setRef = (ref) => {
    if (ref) {
      this.categoryDataRefs.push(ref);
    }
  };

  private _onSaveWarning = () => {
    const { categoryData } = this.state;

    let isFormValid = true;

    if (categoryData.length === 0) {
      isFormValid = false;
      this.setState({ isActionModalOpen: true, errorType: 'empty' });
    } else {
      const supportTypes = _.groupBy(categoryData, (data) => data.supportType);
      _.map(supportTypes, (supportType) => {
        const funding = _.sumBy(supportType, (data) => data.funding);
        // @ts-ignore
        if (funding === 0 || funding === '0' || funding === '') {
          isFormValid = false;
          this.setState({ isActionModalOpen: true, errorType: 'funding' });
        }
      });
    }

    if (this.categoryDataRefs.length > 0) {
      _.forEach(this.categoryDataRefs, (supportCategoryInputRef) => {
        supportCategoryInputRef.props.form.validateFields((err, value) => {
          if (err) {
            isFormValid = false;
          }
        });
      });
    }

    if (isFormValid) {
      this.setState({ isEditWarningModalOpen: true });
    }
  };

  private _onCloseEditWarningModal = () => {
    this.setState({ isEditWarningModalOpen: false });
  };

  private _onSave = async () => {
    const { selectedCustomer, packageId, doUpdateFundingPackageCategory } = this.props;

    if (this.state.categoryData.length > 0) {
      let payload = {
        userId: selectedCustomer.userId,
        fundingPackageId: packageId,
        supportCategories: this.state.categoryData
      };
      this.setState({ isSaving: true });
      await doUpdateFundingPackageCategory(payload);
      this.setState({ isSaving: false });
      this.props.closeEditCategoryModal();
    } else {
      notification.error({ message: 'Validate', description: 'At least one category is required.' });
    }
  };

  private _updateCategoryData = (categoryData) => {
    this.setState({ categoryData });
  };
  private _closeActionModal = () => {
    this.setState({
      isActionModalOpen: false
    });
  };

  render() {
    const { selectedCustomer } = this.props;

    return (
      <React.Fragment>
        <ActionModal
          isOpen={this.state.isActionModalOpen}
          onClose={this._closeActionModal}
          title={'Invalid Funding Data'}
          showCloseButton={true}
        >
          {this.state.errorType === 'empty' && (
            <>
              {' '}
              <Paragraph className={'mb-medium'}>The funding package needs to have an allocated budget.</Paragraph>
              <Paragraph className={'mb-medium'}>Please add a least one category with a positive budget.</Paragraph>
            </>
          )}
          {this.state.errorType === 'funding' && (
            <>
              <Paragraph className={'mb-medium'}>
                All selected support Type needs to have a budget greater than 0.
              </Paragraph>
              <Paragraph className={'mb-medium'}>
                Note: A category can have a budget at 0 if it has at least 1 stated support with a positive budget.
              </Paragraph>
            </>
          )}
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeActionModal}>
              Ok
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.state.isCancelActionModalOpen}
          onClose={this._closeCancelActionModal}
          title={'Confirmation'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>You are closing the editing page without saving.</Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeCancelActionModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size="large" onClick={this.props.closeEditCategoryModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <EditFundingWarningModal
          isOpen={this.state.isEditWarningModalOpen}
          closeModal={this._onCloseEditWarningModal}
          onProceed={this._onSave}
        />
        <div className="anim-slide-left">
          {' '}
          <Title level={2} className="text-weight-regular">
            Edit <b>Funding Package</b>
          </Title>
        </div>
        <div>
          <Text>
            You are editing the Budgets from{' '}
            <b>
              {selectedCustomer.firstName} {selectedCustomer.lastName}
            </b>{' '}
            Funding Package. Please enter the following details Funding Package.
          </Text>
        </div>
        {this.state.isSaving ? (
          <div>
            <div className="mt-x-large">
              <div className="flex-row align-center mb-x-large">
                <div className="mr-x-large">
                  <Spinner size={120} />
                </div>
                <div>
                  <Paragraph>Loading.......</Paragraph>
                  <Paragraph weight="bold">Editing Categories.</Paragraph>
                  <Paragraph>This won't take long.</Paragraph>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <FundingEditCategoriesPanel
              categoryData={this.state.categoryData}
              updateCategoryData={this._updateCategoryData}
              wrappedComponentRef={this._setRef}
            />
            <div className={'mt-x2-large flex-row justify-end align-center'}>
              <GhostButton className={'mr-small'} onClick={this._closeCancelModalWithAction}>
                Cancel
              </GhostButton>
              <PrimaryButton size="large" icon={'save'} onClick={this._onSaveWarning}>
                Save Changes
              </PrimaryButton>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateFundingPackageCategory: dispatch.customersStore.doUpdateFundingPackageCategory
});

export default connect(
  null,
  mapDispatch
)(Form.create<IFundEditSupportCategoryPanelProps>()(FundEditSupportCategoryPanel));
