import React, { Component } from 'react';
import * as H from 'history';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState, dispatch, state } from 'src/stores/rematch/root-store';
import CustomAllViews from 'common-components/custom-views/all-views';
import { FilterType, ServiceType } from 'utilities/enum-utils';
import moment from 'moment';

interface IBookingAllViewsProps {
  doFetchBookingViews: typeof dispatch.bookingsStore.doFetchBookingViews;
  setBookingListingActiveTab: typeof dispatch.bookingsStore.setBookingListingActiveTab;
  doAddBookingView: typeof dispatch.bookingsStore.doAddBookingView;
  doUpdateBookingView: typeof dispatch.bookingsStore.doUpdateBookingView;
  doDuplicateBookingView: typeof dispatch.bookingsStore.doDuplicateBookingView;
  doDeleteBookingView: typeof dispatch.bookingsStore.doDeleteBookingView;
  setBookingsFilter: typeof dispatch.bookingsStore.setBookingsFilter;
  bookingViews: typeof state.bookingsStore.bookingViews;
  bookingsFilter: typeof state.bookingsStore.bookingsFilter;
  bookingListingActiveTab: typeof state.bookingsStore.bookingListingActiveTab;
  createdByMeViews: typeof state.bookingsStore.bookingViews;
  createdByOthersViews: typeof state.bookingsStore.bookingViews;
  defaultBookingViews: typeof state.bookingsStore.bookingViews;
  history: H.History;
}

const defaultFilterValue = [
  {
    [FilterType.DATE_RANGE]: [
      moment()
        .startOf('isoWeek')
        .toISOString(),
      moment()
        .endOf('isoWeek')
        .toISOString()
    ]
  },
  { sort: [['startDateTime', 'asc']] },
  { [FilterType.CUSTOMER]: [] },
  { [FilterType.WORKER]: [] },
  { [FilterType.SHIFT_SLOT_STATUS]: [] },
  { [FilterType.SERVICE_TYPE]: [ServiceType.INDIVIDUAL] }
];

class BookingAllViews extends Component<IBookingAllViewsProps, null> {
  render() {
    const {
      defaultBookingViews,
      bookingViews,
      bookingsFilter,
      bookingListingActiveTab,
      doFetchBookingViews,
      doAddBookingView,
      doUpdateBookingView,
      doDuplicateBookingView,
      doDeleteBookingView,
      setBookingsFilter,
      setBookingListingActiveTab
    } = this.props;

    return (
      <CustomAllViews
        pageName="bookings"
        pageNavigation="/bookings"
        pageViews={bookingViews}
        pageFilter={bookingsFilter}
        pageListingActiveTab={bookingListingActiveTab}
        defaultViews={defaultBookingViews}
        doFetchPageViews={doFetchBookingViews}
        setPageListingActiveTab={setBookingListingActiveTab}
        doAddView={doAddBookingView}
        doDeleteView={doDeleteBookingView}
        doUpdateViewTab={doUpdateBookingView}
        doDuplicateView={doDuplicateBookingView}
        setPageFilter={setBookingsFilter}
        defaultFilterValue={defaultFilterValue}
        {...this.props}
      ></CustomAllViews>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  defaultBookingViews: state.bookingsStore.defaultBookingViews,
  createdByMeViews:
    state.bookingsStore.bookingViews && state.bookingsStore.bookingViews.filter((view) => view.isOwner === true),
  createdByOthersViews:
    state.bookingsStore.bookingViews && state.bookingsStore.bookingViews.filter((view) => view.isOwner === false),
  displayedBookingListingTabs: state.bookingsStore.displayedBookingListingTabs,
  bookingViews: state.bookingsStore.bookingViews,
  bookingsFilter: state.bookingsStore.bookingsFilter,
  bookingListingActiveTab: state.bookingsStore.bookingListingActiveTab
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doFetchBookingViews: dispatch.bookingsStore.doFetchBookingViews,
  doAddBookingView: dispatch.bookingsStore.doAddBookingView,
  doUpdateBookingView: dispatch.bookingsStore.doUpdateBookingView,
  doDuplicateBookingView: dispatch.bookingsStore.doDuplicateBookingView,
  doDeleteBookingView: dispatch.bookingsStore.doDeleteBookingView,
  setBookingsFilter: dispatch.bookingsStore.setBookingsFilter,
  setDisplayedBookingListingTabs: dispatch.bookingsStore.setDisplayedBookingListingTabs,
  setBookingListingActiveTab: dispatch.bookingsStore.setBookingListingActiveTab
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingAllViews);
