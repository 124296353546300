import React, { PureComponent } from 'react';
import { Col, Row, Tag } from 'antd';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import moment from 'moment';
import styles from 'views/team/styles/worker-details.module.css';
import { bookingStatusColors } from 'theme/theme-variables';
import { ISupportWorkerBooking } from 'interfaces/supportWorker-interfaces';
import CommonUtils from 'utilities/common-utils';

interface IWorkerDetailsBookingItemProps {
  booking: ISupportWorkerBooking;
}

interface IWorkerDetailsBookingItemState {}

class WorkerDetailsBookingItem extends PureComponent<IWorkerDetailsBookingItemProps, IWorkerDetailsBookingItemState> {
  render() {
    const { booking } = this.props;
    const bookingStartDateTime = moment(booking.startDateTime);
    const bookingEndDateTime = moment(booking.endDateTime);

    const endTimeText = !bookingStartDateTime.isSame(bookingEndDateTime, 'day')
      ? `(${bookingEndDateTime.format('D MMMM')})`
      : '';

    return (
      <Row type={'flex'} justify={'space-between'} gutter={8}>
        <Col>
          <ul className={'flat-container'} style={{ textAlign: 'center' }}>
            <li className={styles['booking-list-display-day']}>{bookingStartDateTime.format('DD')}</li>
            <li className={styles['booking-list-display-month']}>{bookingStartDateTime.format('MMM')}</li>
          </ul>
        </Col>
        <Col span={16}>
          <Text>{`${bookingStartDateTime.format('h:mma')} - ${moment(booking.endDateTime).format(
            'h:mma'
          )} ${endTimeText}`}</Text>
          <br />
          <Text className={'text-weight-bold'}>{booking.serviceName}</Text>
          <br />
          <Text
            className={'small-text'}
          >{`Deliver service to ${booking.customerFirstName} ${booking.customerLastName}`}</Text>
          <br />
          <Text className={'small-text'}>{2 + ' Notes on this booking'}</Text>
        </Col>
        <Col span={4}>
          <div className="flex-row justify-end">
            <Tag color={bookingStatusColors[_.toLower(booking.status)]}>
              {CommonUtils.formatStatusString(booking.status)}
            </Tag>
          </div>
        </Col>
      </Row>
    );
  }
}

export default WorkerDetailsBookingItem;
