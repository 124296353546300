import React from 'react';

import moment from 'moment';
import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import { Popover2 as Popover } from '@blueprintjs/popover2';

import {
  getTimelineCardWidth,
  getTimelineOffset,
} from 'common-components/roster-control/roster-daily/timeline-pane/common/card-width-utils';

import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';

const { HOUR_BLOCK_WIDTH } = ROSTER_DAILY_CONFIG;

interface WorkergroupCardProps {
  assigned: number;
  required: number;

  startDateTime?: Date;
  endDateTime?: Date;
}

export function WorkerTimelineMarker({ assigned = 0, required = 0, startDateTime, endDateTime }: WorkergroupCardProps) {
  const mStartDate = moment(startDateTime);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const mEndDate = moment(endDateTime);

  const cardWidth = getTimelineCardWidth(startDateTime, endDateTime);
  const leftOffset = getTimelineOffset(mStartDate);

  const isSmallCard = cardWidth > HOUR_BLOCK_WIDTH / 4;
  const isFilled = assigned === required;

  const { textColor, borderColor } = getStyles(isFilled);

  return (
    <div
      className={`position-absolute bg-white rounded-full bordered text-align-center line-height-100 ${borderColor}`}
      style={{ left: `${leftOffset}px`, top: '10px', width: `${cardWidth}px`, height: '34px' }}
    >
      <Popover content={<GroupCardPopover assigned={assigned} required={required} />} position="bottom-right">
        <div
          className={`cursor-pointer whitespace-nowrap overflow-hidden text-overflow-ellipsis text-color-gold-dark ${
            isSmallCard ? 'p-small' : 'pv-small ph-x2-small'
          }`}
          style={{ width: `${cardWidth}px` }}
        >
          <Icon type={'team'} className={`mr-small text-color-${textColor}`} />
          {isSmallCard && (
            <Text lineHeight={100} color={textColor}>
              {assigned}/{required}
            </Text>
          )}
        </div>
      </Popover>
    </div>
  );
}

function getStyles(isFilled) {
  let textColor = '';
  let borderColor = '';

  if (isFilled) {
    textColor = 'blue-dark';
    borderColor = 'border-blue';
  } else {
    textColor = 'gold-dark';
    borderColor = 'border-gold';
  }

  return { textColor, borderColor };
}

function GroupCardPopover({ assigned, required }) {
  return (
    <div className="p-medium">
      <Text color="secondary">
        <b>
          {assigned} / {required}
        </b>{' '}
        slots filled
      </Text>
      {/*{mStartDate.format('h:mm A')} - {mEndDate.format('h:mm A')}*/}
    </div>
  );
}
