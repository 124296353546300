import { IRosterBooking } from 'common-components/roster-control/interfaces/roster-interfaces';
import moment from 'moment-timezone';
import * as H from 'history';
import { FieldLabel, Text } from 'common-components/typography';
import { Avatar, Icon } from 'antd';
import { HyperlinkButton, IconButton, PrimaryButton } from 'common-components/buttons';
import React, { useState } from 'react';
import { BookingTypeIcon, StatusTag } from 'common-components/tags';
import { BookingStatus, BookingType, ServiceType, ShiftSlotStatus } from 'utilities/enum-utils';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { useDispatch, useSelector } from 'react-redux';
import CommonUtils from 'utilities/common-utils';
import { Popover } from '@blueprintjs/core';
import PermissionUtils from 'utilities/permission-utils';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import BookingAssignWorkerModal from 'views/bookings/components/BookingAssignWorkerModal';
import BookingRemoveWorkerActionModal from 'views/bookings/components/BookingRemoveWorkerActionModal';
import _ from 'lodash';
import TextTag from 'common-components/tags/TextTag';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { RecommendedPanel } from 'common-components/roster-control/common/RecommendedPanel';
import { useFetchSessionCapacity } from 'stores/hooks/query-hooks/use-query-fetch-session-capacity';
import { useFetchCustomerRatio } from 'stores/hooks/query-hooks/use-query-fetch-customer-ratio';

function BookingCardPopover({
  booking,
  history,
  refreshAndCloseData,
  setCanClosePopover
}: {
  booking: IRosterBooking;
  history: H.History;
  refreshAndCloseData: () => void;
  setCanClosePopover: (value) => void;
}) {
  const {
    status,
    startDateTime,
    endDateTime,
    serviceName,
    attachmentUrl,
    workerAttachmentUrl,
    customerFirstName,
    customerLastName,
    workerFirstName,
    workerLastName,
    bookingId,
    serviceId,
    serviceDateTimeId
  } = booking;

  // State selectors
  const authStore = useSelector((state: IRootState) => state.authStore);
  const { portalUser } = authStore;

  // Actions dispatch
  const {
    groupServiceStore: groupServiceDispatch,
    navigationStore: navigationDispatch,
    bookingsStore: bookingDispatch
  } = useDispatch<IRootDispatch>();
  const { setCustomerBookingToOpen } = groupServiceDispatch;
  const { setSelectedSideNavMenuKeys } = navigationDispatch;
  const { doAssignWorker, setSelectedBookingItem } = bookingDispatch;

  // const dateDisplay = 'Monday, 12 April 2021';
  const dateDisplay = moment(startDateTime).format('dddd, D MMMM YYYY');

  const [openAssignWorker, setOpenAssignWorker] = useState<boolean>(false);
  const [openRemoveWorker, setOpenRemoveWorker] = useState<boolean>(false);

  // Get customer ratio
  let customerRatio = '1:1';
  const { data: ratioData, isLoading: ratioIsLoading } = useFetchCustomerRatio(
    {
      bookingId
    },
    booking.serviceType === ServiceType.GROUP
  );
  const { data: sessionData, isLoading: sessionIsLoading } = useFetchSessionCapacity(
    {
      serviceId,
      serviceDateTimeId
    },
    booking.serviceType === ServiceType.GROUP
  );

  if (booking.serviceType === ServiceType.GROUP) {
    const defaultRatio = ratioData && ratioData.data && ratioData.data.defaultRatio;
    const ratios = ratioData && ratioData.data && ratioData.data.ratios;

    if (_.isEmpty(ratios)) {
      customerRatio = defaultRatio;
    } else if (ratios.length === 1) {
      customerRatio = !_.isEmpty(ratios) ? ratios[0].teamMemberCustomerRatio : defaultRatio;
    } else {
      customerRatio = ratios;
    }
  }

  // const timeDisplay = '8:30 AM - 5:30 PM';
  const timeDisplay = (
    <>
      <b>{moment(startDateTime).format('h:mm a')}</b> - {moment(endDateTime).format('h:mm a')}
    </>
  );

  const customerFullName = `${customerFirstName} ${customerLastName}`;
  const workerFullName = workerFirstName ? `${workerFirstName} ${workerLastName}` : null;
  const canAssignWorker =
    !!_.find([ShiftSlotStatus.UNASSIGNED], (status) => status === booking.shiftSlotStatus) &&
    ((booking.serviceType === ServiceType.INDIVIDUAL &&
      (booking.status === BookingStatus.PENDING ||
        booking.status === BookingStatus.ACCEPTED ||
        booking.status === BookingStatus.CONFIRMED)) ||
      booking.serviceType === ServiceType.GROUP);
  const canRemoveWorker =
    !!_.find([ShiftSlotStatus.PENDING, ShiftSlotStatus.CONFIRMED], (status) => status === booking.shiftSlotStatus) &&
    ((booking.serviceType === ServiceType.INDIVIDUAL &&
      (booking.status === BookingStatus.PENDING ||
        booking.status === BookingStatus.ACCEPTED ||
        booking.status === BookingStatus.CONFIRMED)) ||
      booking.serviceType === ServiceType.GROUP);
  const isCancelled = !!_.find(
    [
      BookingStatus.BUSINESS_CANCELLED,
      BookingStatus.CUSTOMER_CANCELLED,
      BookingStatus.CUSTOMER_CANCELLED_WITH_FEE,
      BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE
    ],
    (status) => status === booking.status
  );

  const goToBooking = async () => {
    if (booking.serviceType === ServiceType.GROUP) {
      await setCustomerBookingToOpen(booking.bookingId);
      history.push(`/group-service/${booking.serviceId}/session/details/${booking.serviceDateTimeId}`, {
        bookingId: booking.bookingId
      });
      setSelectedSideNavMenuKeys(['/services']);
    } else {
      history.push(`/bookings/details/${booking.bookingId}`, {
        from: { url: '/bookings/calendar', linkLabel: 'Back to Calendar' }
      });
      setSelectedSideNavMenuKeys(['/bookings']);
    }
  };

  const goToService = (serviceId, serviceType) => {
    setSelectedSideNavMenuKeys(['/services']);
    return serviceType === ServiceType.GROUP
      ? history.push(`/group-service/details/${serviceId}`)
      : history.push(`/service/details/${serviceId}`);
  };

  const goToSession = (serviceId, serviceDateTimeId) => {
    setSelectedSideNavMenuKeys(['/services']);
    return history.push(`/group-service/${serviceId}/session/details/${serviceDateTimeId}`);
  };

  const openAssignWorkerModal = () => {
    setCanClosePopover(false);
    setOpenAssignWorker(true);
  };

  const closeAssignWorkerModal = (doRefreshData = false) => {
    setCanClosePopover(true);
    setOpenAssignWorker(false);
    doRefreshData && refreshAndCloseData();
  };

  const openRemoveWorkerModal = async () => {
    await setSelectedBookingItem(booking);
    setOpenRemoveWorker(true);
  };

  const doCloseAndRefresh = async (doRefreshData = false) => {
    setCanClosePopover(true);
    setOpenRemoveWorker(false);
    await setSelectedBookingItem({});
    doRefreshData && refreshAndCloseData();
  };

  const onSubmitAssign = async (selectedWorker, editRecurringMode, shiftSlotStatus, isRemovePendingShiftSlots) => {
    const data = {
      bookingId: booking.bookingId,
      bookingRequestId: booking.bookingRequestId,
      supportWorkerId: selectedWorker.supportWorkerId,
      startDateTime: booking.startDateTime,
      isRecurring: booking.isRecurring,
      shiftSlotStatus,
      isRemovePendingShiftSlots,
      editRecurringMode,
      bookingType: booking.bookingType,
      doNotSetBookingItem: true
    };
    await doAssignWorker(data);
  };

  const hasEditWorkerPermissions = PermissionUtils.validatePermission(
    'EditWorkerInBooking',
    portalUser.permissions.permissionRoles,
    booking.serviceDepartmentId,
    booking.serviceId
  );

  const getActionMenuItems = () => {
    return (
      <ActionMenu>
        {hasEditWorkerPermissions && canAssignWorker && !isCancelled && (
          <ActionMenuItem onClick={openAssignWorkerModal} text={'Assign team member'} />
        )}
        {hasEditWorkerPermissions && booking.status && canRemoveWorker && (
          <ActionMenuItem className={'mr-small'} onClick={openRemoveWorkerModal} text={'Remove team member'} />
        )}
      </ActionMenu>
    );
  };

  return (
    <div className="p-large flex-column line-height-100 select-none" style={{ width: '360px' }}>
      {booking.address && booking.serviceType === ServiceType.INDIVIDUAL && canAssignWorker && (
        <BookingAssignWorkerModal
          isOpen={openAssignWorker}
          onClose={closeAssignWorkerModal}
          serviceId={booking.serviceId}
          bookingId={booking.bookingId}
          bookingRequestId={booking.bookingRequestId}
          onSubmitAssign={onSubmitAssign}
          isRecurring={booking.isRecurring}
          address={booking.address}
          sessions={[]}
          isEditingBooking={true}
          startDateTime={booking.startDateTime}
          endDateTime={booking.endDateTime}
          customerUserIds={[booking.bookerUserId]}
          displayTimezone={booking.timezone}
          history={history}
          bookingType={booking.bookingType}
        />
      )}
      {hasEditWorkerPermissions && booking.serviceType === ServiceType.INDIVIDUAL && canRemoveWorker && (
        <BookingRemoveWorkerActionModal
          bookingType={booking.bookingType}
          isOpen={openRemoveWorker}
          onClose={doCloseAndRefresh}
        />
      )}
      <div className="inline-block mb-x-small line-height-100">
        <StatusTag status={status} size="small" />
      </div>
      <div className="line-height-120 mb-medium">
        <Text size="x-large" lineHeight={135} weight="bold">
          {dateDisplay}
        </Text>
        <br />
        <Text size="x-large" lineHeight={135}>
          {timeDisplay}
        </Text>
        <br />
        <Text size="x-large" lineHeight={135}>
          <Text size="x-large" color={'secondary'}>
            {CommonUtils.formatDurationString(moment(startDateTime), moment(endDateTime))}
          </Text>
        </Text>

        <div className={'flex-row align-center mt-medium'}>
          {' '}
          <BookingTypeIcon
            type={
              booking.bookingType === BookingType.ACTIVITY_RECORD ? BookingType.ACTIVITY_RECORD : booking.serviceType
            }
            className={'mr-small'}
          />
          <Text color={'tertiary'}>
            {booking.bookingType === BookingType.ACTIVITY_RECORD
              ? 'Activity record'
              : booking.serviceType === ServiceType.INDIVIDUAL
              ? 'Support service'
              : 'Group service'}
          </Text>
        </div>
        {booking.serviceType === ServiceType.INDIVIDUAL || !booking.scheduleName ? (
          <HyperlinkButton color={'black'} onClick={() => goToService(booking.serviceId, booking.serviceType)}>
            {serviceName}
          </HyperlinkButton>
        ) : (
          <Text>
            {booking.scheduleName}{' '}
            <HyperlinkButton color={'secondary'} onClick={() => goToService(booking.serviceId, booking.serviceType)}>
              ({booking.serviceName})
            </HyperlinkButton>
          </Text>
        )}
        {booking.serviceType === ServiceType.GROUP && (
          <div>
            <HyperlinkButton onClick={() => goToSession(booking.serviceId, booking.serviceDateTimeId)}>
              Go to session
            </HyperlinkButton>
          </div>
        )}
      </div>

      {booking.serviceType === ServiceType.GROUP && (
        <div className={'mb-large'}>
          <div className="mt-x-small">
            {sessionIsLoading ? (
              <SpinningLoader size={20} message={'Fetching recommended capacity...'} />
            ) : (
              sessionData &&
              sessionData.data && (
                <RecommendedPanel
                  recommendedTotalShiftSlot={sessionData.data.recommendedTotalShiftSlot}
                  totalAssignedShiftSlot={sessionData.data.totalAssignedShiftSlot}
                />
              )
            )}
          </div>
        </div>
      )}

      <div className="mb-large">
        <FieldLabel text={'CUSTOMER'} />
        <div className="flex-row align-center mt-x-small">
          <Avatar icon="user" className="mr-small" src={attachmentUrl} />
          <Text>{customerFullName}</Text>
        </div>
      </div>

      {booking.serviceType === ServiceType.GROUP && (
        <div className="mb-large">
          <FieldLabel text={'Customer ratio'} />
          <div className="mt-x-small">
            {ratioIsLoading ? (
              <SpinningLoader size={20} message={'Fetching ratios...'} />
            ) : _.isString(customerRatio) ? (
              <TextTag
                content={customerRatio}
                className={'mr-x-small'}
                size={'regular'}
                theme={'light'}
                color={'blue'}
              />
            ) : (
              _.map(customerRatio, (ratio: any, index) => {
                return (
                  <>
                    <div className={`flex-row mt-x-small ${index !== 0 && 'mt-small'} `}>
                      <div className={'mr-small'}>
                        <TextTag
                          content={ratio.teamMemberCustomerRatio}
                          size={'small'}
                          theme={'light'}
                          color={'blue'}
                          className={'inline-block'}
                        />
                      </div>
                      <div>
                        <div style={{ marginTop: '-1px' }}>
                          <Text size={'large'}>
                            {moment.tz(ratio.startDateTime, booking.timezone).format('h:mm a')}
                            {' - '}
                            {moment.tz(ratio.endDateTime, booking.timezone).format('h:mm a')}
                          </Text>
                        </div>
                        {ratio.comments && (
                          <div>
                            <Text color={'secondary'} size={'regular'}>
                              {ratio.comments}
                            </Text>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                );
              })
            )}
          </div>
        </div>
      )}

      {booking.serviceType === ServiceType.GROUP && booking.shiftCustomerAssignments && (
        <div className="mb-large">
          <FieldLabel text={'TEAM MEMBER'} />
          <div className="mt-x-small" style={{ overflow: 'auto', maxHeight: '120px' }}>
            {_.map(booking.shiftCustomerAssignments, (assignment) => {
              return (
                <div className="flex-row align-center mt-x-small">
                  <div className="flex-row line-height-120 mb-small">
                    <Avatar icon="user" size="small" className="mr-small" src={assignment.customerAttachmentUrl} />
                    <Text size="regular">
                      <span className={'text-weight-bold'}>
                        {assignment.supportWorkerFirstName} {assignment.supportWorkerLastName}
                      </span>
                      <br />
                      <span>
                        {moment.tz(assignment.startDateTime, booking.timezone).format('h:mm a')}{' '}
                        {moment.tz(assignment.endDateTime, booking.timezone).format('h:mm a')}
                      </span>
                    </Text>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {booking.serviceType === ServiceType.INDIVIDUAL && (
        <div className="mb-large">
          <FieldLabel text={'TEAM MEMBER'} />
          <div className="flex-row align-center mt-x-small">
            {workerFullName ? (
              <>
                <Avatar icon="user" className="mr-small" shape="square" src={workerAttachmentUrl} />
                <Text>{workerFullName}</Text>
              </>
            ) : booking.shiftSlotStatus === ShiftSlotStatus.PUBLISHING ? (
              <Text>
                <Icon type="wifi" className="text-color-violet-light mr-x-small" />
                Published
              </Text>
            ) : (
              <Text color="secondary">No team member assigned</Text>
            )}
          </div>
        </div>
      )}

      <div className={'mb-large'}>
        <FieldLabel text={'TOTAL'} />
        <div className="flex-row align-center mt-x-small">
          <Text>{CommonUtils.formatPrice(Number(booking.billingTotal))}</Text>
        </div>
      </div>

      <div className="flex-row justify-end">
        <PrimaryButton onClick={goToBooking}>View booking</PrimaryButton>
        {hasEditWorkerPermissions &&
          !isCancelled &&
          booking.serviceType === ServiceType.INDIVIDUAL &&
          (canAssignWorker || canRemoveWorker) && (
            <Popover
              content={getActionMenuItems()}
              onOpening={() => setCanClosePopover(false)}
              onClose={() => setCanClosePopover(!openAssignWorker && !openRemoveWorker)}
              interactionKind={'hover'}
              position={'bottom-left'}
            >
              <IconButton
                icon="ellipsis"
                color="white"
                iconColor="blue-action"
                bordered={true}
                className="border-blue-action ml-small"
              />
            </Popover>
          )}
      </div>
    </div>
  );
}

export default BookingCardPopover;
