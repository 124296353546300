import React, { Component } from 'react';
import _ from 'lodash';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { SubTitle, Text } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { Avatar, Icon, Input, Tabs, Tag } from 'antd';
import { IServiceRosterUser, MessageSubscriberType } from 'interfaces/message-interfaces';

const { TabPane } = Tabs;
const { Search } = Input;

interface ICreateConversationModalProps {
  isCreateModalOpen: boolean;
  onCloseCreateModal: any;
  onTriggerCreateConversation: any;
  currentServiceName: string;
  currentServiceId: string;
  selectedServiceRoster: IServiceRosterUser[];
  customers: any[];
}

interface ICreateConversationModalState {
  selectedUserId?: string;
  searchString?: any;
  isCreating: boolean;
  subscriberType: MessageSubscriberType;
}

export class CreateConversationModal extends Component<ICreateConversationModalProps, ICreateConversationModalState> {
  state = {
    selectedUserId: null,
    searchString: '',
    isCreating: false,
    subscriberType: MessageSubscriberType.SupportWorker // default tab is support worker
  };

  resetModal = () =>
    this.setState({
      selectedUserId: null,
      searchString: '',
      isCreating: false,
      subscriberType: MessageSubscriberType.SupportWorker
    });

  // Input Search
  onChangeSearchString = (e) => {
    if (e) {
      this.setState({ searchString: e.target.value });
    }
  };

  // cancel without selecting.
  onCancelClose = () => {
    this.resetModal();
    this.props.onCloseCreateModal();
  };

  onChangeTab = (key) => {
    if (key === 'workers') {
      this.setState({ subscriberType: MessageSubscriberType.SupportWorker });
    } else if (key === 'customers') {
      this.setState({ subscriberType: MessageSubscriberType.Customer });
    }
  };

  // select user
  onSelectUser = async (userId) => {
    this.setState({ selectedUserId: userId });
  };

  // Go!
  onClickGo = async () => {
    const { onTriggerCreateConversation, currentServiceId, onCloseCreateModal } = this.props;
    const { selectedUserId } = this.state;

    // TODO : Make this into a user selectable service later.
    const selectedServiceId = currentServiceId;

    // TODO : Update subscriber type with the correct one
    const subscriberType = this.state.subscriberType;

    this.setState({ isCreating: true });

    await onTriggerCreateConversation({ selectedUserId, selectedServiceId, subscriberType });

    this.resetModal();

    onCloseCreateModal();
  };

  render() {
    const { isCreateModalOpen, selectedServiceRoster = [], currentServiceName, customers = [] } = this.props;

    const rawSupportWorkers = [...selectedServiceRoster];
    const rawCustomers = [...customers];

    function filterByName(users, searchString) {
      return _.filter(
        users,
        (user) =>
          user.firstName
            .trim()
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) >= 0 ||
          user.lastName
            .trim()
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) >= 0 ||
          `${user.firstName.trim().toLowerCase()} ${user.lastName.trim().toLowerCase()}`.indexOf(
            searchString.toLowerCase()
          ) >= 0
      );
    }

    const sortedWorkers =
      this.state.searchString.length >= 3
        ? filterByName(rawSupportWorkers, this.state.searchString)
        : [...rawSupportWorkers];

    const sortedCustomers =
      this.state.searchString.length >= 3 ? filterByName(rawCustomers, this.state.searchString) : [...rawCustomers];

    return (
      <ActionModal
        isOpen={isCreateModalOpen}
        title="Create a conversation"
        width={'medium'}
        verticalAlignment={'highest'}
        onClose={this.onCancelClose}
        canCloseOutside={!this.state.isCreating}
      >
        <div className="pb-large">
          <Text>Create a conversation with a worker, or a customer.</Text>
        </div>

        <div className="pb-medium">
          <SubTitle>For service</SubTitle>
          <Text size="large" weight="bold">
            {currentServiceName}
          </Text>
          {/*<Select defaultValue={'your-supports'} size="large" className="width-full">*/}
          {/*  <Option key={'your-supports'}># Your Supports</Option>*/}
          {/*  <Option key={'YouthJobsNow'}># Youth Jobs Now!</Option>*/}
          {/*</Select>*/}
        </div>

        {/* Search Input */}
        <div className="pb-x-large">
          <SubTitle>To</SubTitle>
          <Search size="large" placeholder="Search for a person" onChange={this.onChangeSearchString} />
        </div>

        {/*<Text size="small" color="secondary">*/}
        {/*  Workers/Customers*/}
        {/*</Text>*/}

        {/* Main users listing */}

        <Tabs defaultActiveKey={'worker'} type="card" onChange={this.onChangeTab}>
          <TabPane key={'workers'} tab={'Workers'}>
            <div
              style={{ height: '25vh', overflowY: 'auto', marginTop: '-16px' }}
              className="bordered width-full mb-medium rounded cursor-pointer flex-column"
            >
              {_.isEmpty(sortedWorkers) && <EmptyListState type="support workers" />}

              {_.map(sortedWorkers, (user) => (
                <ConversationUserItem
                  userId={user.userId}
                  displayName={`${user.firstName} ${user.lastName}`}
                  isSelected={this.state.selectedUserId && this.state.selectedUserId === user.userId}
                  locality={user.locality}
                  userType={'worker'}
                  onSelectUser={this.onSelectUser}
                  avatarUrl={user.attachmentUrl}
                  key={user.userId}
                />
              ))}
            </div>
          </TabPane>

          <TabPane key={'customers'} tab={'Customers'}>
            <div
              style={{ height: '30vh', overflowY: 'auto', marginTop: '-16px' }}
              className="bordered width-full mb-medium rounded flex-1 flex-column cursor-pointer"
            >
              {_.isEmpty(sortedCustomers) && <EmptyListState type="customers" />}

              {_.map(sortedCustomers, (user) => (
                <ConversationUserItem
                  userId={user.userId}
                  displayName={`${user.firstName} ${user.lastName}`}
                  isSelected={this.state.selectedUserId && this.state.selectedUserId === user.userId}
                  userType={'customer'}
                  locality={user.locality}
                  onSelectUser={this.onSelectUser}
                  avatarUrl={user.attachmentUrl}
                  key={user.userId}
                />
              ))}
            </div>
          </TabPane>
        </Tabs>

        <ActionModalFooter>
          <GhostButton
            size="large"
            color={'blue'}
            className="mr-small"
            onClick={this.onCancelClose}
            disabled={this.state.isCreating}
          >
            Cancel
          </GhostButton>

          <PrimaryButton
            size="large"
            color={'blue'}
            disabled={_.isEmpty(this.state.selectedUserId)}
            onClick={this.onClickGo}
            loading={this.state.isCreating}
          >
            Go
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

function EmptyListState({ type }) {
  return (
    <div className="ph-medium pv-medium flex-1 flex-column align-center justify-center">
      <Text color="secondary">No matching {type} found.</Text>
      <Text color="secondary" size="regular">
        Try searching with another name.
      </Text>
    </div>
  );
}

function ConversationUserItem({ userId, userType, displayName, isSelected, onSelectUser, avatarUrl, locality }) {
  const isSelectedClass = isSelected ? 'bg-blue-lightest' : 'hover-bg-tertiary';

  return (
    <div
      className={`bordered-bottom flex-row pv-small ph-medium align-center ${isSelectedClass}`}
      onClick={() => onSelectUser(userId)}
    >
      <Avatar shape="square" size="large" src={avatarUrl} />
      <div className="flex-row ml-medium justify-between width-full align-center">
        <div className="flex-column">
          <div className="flex-row">
            <Text weight="bold" lineHeight={135} className="select-none mr-small">
              {displayName}{' '}
              {locality && (
                <Text weight={'regular'} color={'tertiary'}>
                  {' '}
                  ({locality})
                </Text>
              )}
            </Text>
            {userType === 'worker' && <Tag color={'blue'}>Worker</Tag>}
            {userType === 'customer' && <Tag color={'green'}>Customer</Tag>}
          </div>
          {/*<Text size="regular" lineHeight={135} className="select-none" color={'secondary'}>*/}
          {/*  {description}*/}
          {/*</Text>*/}
        </div>
        {isSelected && <Icon type="check-circle" theme="filled" className="text-size-x2-large text-color-blue-light" />}
      </div>
    </div>
  );
}
