import React, { Component } from 'react';
import CenteredLayout from '../../layouts/CenteredLayout';
import { RouteComponentProps } from 'react-router-dom';
import TeamListingV2 from './listing/TeamListingV2';

interface ITeamListingViewV2Props extends RouteComponentProps {}

class TeamListingViewV2 extends Component<ITeamListingViewV2Props, any> {
  render() {
    const { history, match, location } = this.props;

    return (
      <CenteredLayout backgroundColor="white" bordered>
        <TeamListingV2 history={history} match={match} location={location} />
      </CenteredLayout>
    );
  }
}

export default TeamListingViewV2;
