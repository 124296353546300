import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { SubTitle, Text } from 'common-components/typography';
import firebaseApp from 'stores/firebase-app';
import { Input, Row, Col } from 'antd';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { Form } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { 
  reauthenticateWithCredential,
  updatePassword,
  EmailAuthProvider
} from 'firebase/auth';

interface IChangePasswordModelProps extends FormComponentProps {
  isOpen: boolean;
  onClose: () => void;
}

class ChangePasswordModel extends Component<IChangePasswordModelProps, any> {
  state = {
    oldPassword: null,
    isValidatePasswordError: false,
    isLoading: false,
    isTooManyAttempt: false,
    isSucceed: false,
    isLengthMatch: true,
    isUpperCaseMatch: true,
    isNumberMatch: true
  };
  private _onCloseModel = () => {
    const { onClose } = this.props;
    this.setState({
      oldPassword: null,
      isValidatePasswordError: false,
      isLoading: false,
      isTooManyAttempt: false,
      isSucceed: false,
      isLengthMatch: true,
      isUpperCaseMatch: true,
      isNumberMatch: true
    });
    onClose();
  };

  private _handleInputPassword = (event) => {
    this.setState({ oldPassword: event.target.value });
  };

  private _onConfirmOldPassword = async () => {
    try {
      const user = firebaseApp.auth.currentUser;
      const credential = await EmailAuthProvider.credential(
        user.email,
        this.state.oldPassword
      );
      await reauthenticateWithCredential(user, credential);
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        this.setState({ isValidatePasswordError: true, isLoading: false, isTooManyAttempt: false });
      }
      if (error.code === 'auth/too-many-requests') {
        this.setState({ isTooManyAttempt: true, isLoading: false, isValidatePasswordError: false });
      }
    }
  };

  private _onConfirmChangePassword = async () => {
    const { form } = this.props;
    try {
      const user = firebaseApp.auth.currentUser;
      this.setState({ isLoading: true });
      await this._onConfirmOldPassword();
      form.validateFieldsAndScroll(async (error, value) => {
        if (!error) {
          await updatePassword(user, value.newPassword);
          this.setState({ isLoading: false, isSucceed: true });
        } else {
          this.setState({ isLoading: false });
        }
      });
    } catch (error) {
      this.setState({ oldPassword: null, isValidatePasswordError: false });
    }
  };

  private compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('Passwords do not match');
    } else {
      callback();
    }
  };

  private validateEnteredPassword = (rule, value, callback) => {
    const { form } = this.props;
    const formValue = form.getFieldValue('newPassword');
    const eightDigitRegExp = RegExp('(?=.{8,})');
    const passwordStrengthRegExp = RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])');

    const upperCaseRegExp = RegExp('^(?=.*[A-Z])');
    const numberRegExp = RegExp('^(?=.*[0-9])');

    const isLengthMatch = eightDigitRegExp.test(formValue);
    const isUpperCaseMatch = upperCaseRegExp.test(formValue);
    const isNumberMatch = numberRegExp.test(formValue);

    this.setState({ isLengthMatch, isUpperCaseMatch, isNumberMatch });

    // const invalidSymbolRegExp = RegExp('^[a-zA-Z0-9!@#$%^&*~()]*$');
    if (!eightDigitRegExp.test(formValue)) {
      callback('Your password length must be 8 or more characters.');
    } else {
      if (!passwordStrengthRegExp.test(formValue)) {
        callback('Your password must have Uppercase letters, lowercase letters and numbers.');
        // callback('Must have at least 1 Upper case, 1 lower case and 1 digit');
      } else {
        // if (!invalidSymbolRegExp.test(formValue)) {
        //   callback('Must only have following symbol: ! @ # $ % ^ & * ~ ( )');
        // } else {
        callback();
        // }
      }
    }
  };

  private _formValidationText = (): string => {
    let text = '';
    if (this.state.isValidatePasswordError) {
      text = 'Current password is incorrect';
    } else if (this.state.isTooManyAttempt) {
      text = 'Too many attempt, try again later';
    }
    return text;
  };

  render() {
    const { form, isOpen } = this.props;
    const { getFieldDecorator } = form;
    return (
      <ActionModal isOpen={isOpen} title={'Change your password'} onClose={this._onCloseModel} verticalAlignment="high">
        {!this.state.isSucceed && (
          <>
            <div>
              <Text>Please enter your current password, along with the new password that you wish to set</Text>
              <Row className="mt-large">
                <Col span={12}>
                  <SubTitle color="tertiary" weight="black">
                    Current Password
                  </SubTitle>
                  <Form.Item
                    validateStatus={this.state.isValidatePasswordError || this.state.isTooManyAttempt ? 'error' : ''}
                    help={this._formValidationText()}
                    className="mt-x-small"
                  >
                    <Input.Password
                      placeholder={'Current Password'}
                      onChange={this._handleInputPassword}
                      size={'large'}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <SubTitle color="tertiary" weight="black">
                    Enter new password
                  </SubTitle>
                  <Form.Item help={''} className="mt-x-small">
                    {getFieldDecorator('newPassword', { rules: [{ validator: this.validateEnteredPassword }] })(
                      <Input.Password placeholder={'New Password'} size={'large'} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <SubTitle color="tertiary" weight="black">
                    Confirm new password
                  </SubTitle>
                  <Form.Item className="mt-x-small">
                    {getFieldDecorator('confirmPassword', { rules: [{ validator: this.compareToFirstPassword }] })(
                      <Input.Password placeholder={'Confirm Password'} size={'large'} />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row className="mt-x-large">
                <Col span={12}>
                  <Text>
                    Your <b>Password</b> must have:
                  </Text>
                  <ul>
                    <li className={!this.state.isLengthMatch ? 'text-color-red' : ''}>
                      a length of at lease <b>8 characters</b>
                    </li>
                    <li className={!this.state.isUpperCaseMatch ? 'text-color-red' : ''}>
                      at least one <b>Uppercase</b> letter
                    </li>
                    <li className={!this.state.isNumberMatch ? 'text-color-red' : ''}>
                      at least <b>one number</b>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
            <ActionModalFooter className="mt-large">
              <GhostButton size="large" onClick={this._onCloseModel} disabled={this.state.isLoading}>
                Cancel
              </GhostButton>
              <PrimaryButton
                className="mr-medium"
                size="large"
                onClick={this._onConfirmChangePassword}
                loading={this.state.isLoading}
              >
                Change Password
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {this.state.isSucceed && (
          <>
            <div>
              <Row>
                <Text>Success!</Text>
              </Row>
              <Row className="mt-large">
                <Text>Your password has been successfully changed</Text>
              </Row>
            </div>
            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModel}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
      </ActionModal>
    );
  }
}

export default Form.create<IChangePasswordModelProps>()(ChangePasswordModel);
