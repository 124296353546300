import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, notification, Tabs } from 'antd';
import { Text, Title } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import GroupServiceSessionListingPanel from './GroupServiceSessionListingPanel';
import GroupServiceScheduleListingPanel from './GroupServiceScheduleListingPanel';
import * as H from 'history';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import _ from 'lodash';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { message } from 'antd/es';
import { GroupServiceSessionStatus, SessionStatus } from 'utilities/enum-utils';
import { BottomActionSheet } from 'common-components/bulk-actions/BottomActionSheet';
import {
  SESSION_AVAILABLE_BULK_ACTIONS,
  SESSION_CUSTOMER_AVAILABLE_BULK_ACTIONS
} from 'common-components/bulk-actions/availableActions';

const { TabPane } = Tabs;

interface IGroupServiceSessionPanelProps {
  selectedServiceId: string;
  onOpenCreateSessionModal: () => void;
  selectedFilter: string;
  history: H.History;
  groupServiceSessions?: typeof state.servicesStore.groupServiceSessions;
  setGroupServiceSessions: typeof dispatch.servicesStore.setGroupServiceSessions;
  doBulkCancelGroupServiceSession: typeof dispatch.servicesStore.doBulkCancelGroupServiceSession;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
}

interface IGroupServiceSessionPanelState {
  selectedTab: string;
  showActionSheet: boolean;
  checkAllSessions: boolean;
  isCancelScheduledSessionModalOpen: boolean;
  isCancelSuccessModalOpen: boolean;
  toBeCancelSessionCount: number;
}

class GroupServiceSessionPanel extends Component<IGroupServiceSessionPanelProps, IGroupServiceSessionPanelState> {
  state = {
    selectedTab: 'Sessions',
    showActionSheet: false,
    checkAllSessions: false,
    isCancelScheduledSessionModalOpen: false,
    isCancelSuccessModalOpen: false,
    toBeCancelSessionCount: 0
  };

  private _onChangeTab = (event) => {
    this.setState({ selectedTab: event, showActionSheet: false });
  };

  private _onActionDeselect = () => {
    const { groupServiceSessions, setGroupServiceSessions } = this.props;

    // Unselect all
    const newGroupServiceSessions = groupServiceSessions.map((session) => {
      return { ...session, isChecked: false };
    });
    setGroupServiceSessions(newGroupServiceSessions);
    this.setState({
      showActionSheet: false,
      checkAllSessions: false
    });
  };

  private _onCheckItem = (sessionItem) => {
    const { groupServiceSessions, setGroupServiceSessions } = this.props;

    const newGroupServiceSessions = groupServiceSessions.map((session) =>
      session.serviceDateTimeId === sessionItem.serviceDateTimeId
        ? { ...session, isChecked: !session.isChecked }
        : { ...session }
    );

    const checkSessions = _.filter(newGroupServiceSessions, (s) => s.isChecked === true);

    setGroupServiceSessions(newGroupServiceSessions);
    this.setState({
      showActionSheet: checkSessions.length > 0,
      checkAllSessions: checkSessions.length === newGroupServiceSessions.length
    });
  };

  private _setCheckAllSessions = () => {
    const { groupServiceSessions, setGroupServiceSessions } = this.props;

    const newCheckAllSessions = !this.state.checkAllSessions;

    const newGroupServiceSessions = groupServiceSessions.map((session) => {
      return { ...session, isChecked: newCheckAllSessions };
    });

    const checkSessions = _.filter(newGroupServiceSessions, (s) => s.isChecked === true);

    setGroupServiceSessions(newGroupServiceSessions);

    this.setState({ checkAllSessions: newCheckAllSessions, showActionSheet: checkSessions.length > 0 });
  };

  private _onCloseCancelScheduledSessionModal = () => {
    this.setState({ isCancelScheduledSessionModalOpen: false });
  };

  private _onOpenCancelScheduledSessionModal = () => {
    const { groupServiceSessions } = this.props;

    const toBeCancelSessionCount = _.filter(
      groupServiceSessions,
      (session) => session.isChecked && session.sessionStatus === GroupServiceSessionStatus.SCHEDULED
    ).length;

    this.setState({ isCancelScheduledSessionModalOpen: true, toBeCancelSessionCount: toBeCancelSessionCount });
  };

  private _onCloseCancelSuccessModal = () => {
    this.setState({ isCancelSuccessModalOpen: false, toBeCancelSessionCount: 0 });
  };

  private _onOpenCancelSuccessModal = () => {
    this.setState({ isCancelSuccessModalOpen: true });
  };

  private _assignFunction = (actionName) => {
    if (actionName === 'CANCEL') {
      return this._onOpenCancelScheduledSessionModal;
    }
  };

  private _onClickCancelScheduledSession = async () => {
    const { doBulkCancelGroupServiceSession, selectedGroupService, groupServiceSessions } = this.props;
    const dismissAction = message.loading('Executing bulk actions...', 0);

    try {
      const serviceDateTimeIds = _.filter(
        groupServiceSessions,
        (session) => session.isChecked && session.sessionStatus === SessionStatus.SCHEDULED
      ).map((filteredSession) => {
        return filteredSession.serviceDateTimeId;
      });

      await doBulkCancelGroupServiceSession({
        serviceId: selectedGroupService.serviceId,
        serviceDateTimeIds: serviceDateTimeIds
      });

      notification.success({
        message: <Text weight="bold">Bulk actions successfully completed.</Text>,
        description: (
          <Text className="mt-medium">
            Bulk actions for <span className="text-weight-bold">Cancel Sessions</span> complete.
          </Text>
        )
      });
      this._onCloseCancelScheduledSessionModal();
      this._onOpenCancelSuccessModal();
      this._onActionDeselect();
    } catch (e) {
      console.error(e);
      notification.error({
        message: <Text weight="bold">Bulk actions failed.</Text>,
        description: (
          <Text className="mt-medium">
            Bulk actions for <span className="text-weight-bold">Cancel Sessions</span> has encounter an error. Please
            try again.
          </Text>
        )
      });
    }
    dismissAction();
  };

  render() {
    const { selectedFilter = null, history, groupServiceSessions } = this.props;

    const groupedSessionByStatus = _.groupBy(
      _.filter(groupServiceSessions, (customer) => customer.isChecked),
      'sessionStatus'
    );
    let selectedItems =
      !_.isEmpty(groupedSessionByStatus) &&
      _.map(groupedSessionByStatus, (groupedSessions, itemLabel) => {
        const availableActionsFound = _.find(
          SESSION_AVAILABLE_BULK_ACTIONS,
          (action) => action.itemLabel === itemLabel
        );
        return !!availableActionsFound
          ? {
              itemLabel,
              itemCount: groupedSessions && groupedSessions.length,
              actions:
                availableActionsFound &&
                _.map(availableActionsFound.actions, (action) => {
                  return { ...action, actionOnClick: this._assignFunction(action.actionName) };
                }),
              children: []
            }
          : {
              itemLabel,
              itemCount: groupedSessions && groupedSessions.length,
              actions: null,
              children: []
            };
      });

    selectedItems = !_.isEmpty(selectedItems) && selectedItems;

    return (
      <div className="pl-medium pt-x-large width-full pb-x3-large">
        {/*<div className="mb-large">*/}
        {/*  <Title level={4} className="mv-none">*/}
        {/*    Sessions*/}
        {/*  </Title>*/}
        {/*</div>*/}
        <Tabs
          animated={true}
          onChange={this._onChangeTab}
          className="bg-white customer-tab inner-tab rounded-big-top shadow-container"
        >
          <TabPane tab="Sessions" key="Sessions" className="ph-large" />
          <TabPane tab="Schedule" key="Schedule" />
        </Tabs>

        {this.state.selectedTab === 'Sessions' && (
          <>
            <ActionModal
              isOpen={this.state.isCancelScheduledSessionModalOpen}
              onClose={this._onCloseCancelScheduledSessionModal}
              title={'Cancel sessions'}
              showCloseButton={true}
            >
              <Text className={'mb-medium'}>
                Are you sure you want to cancel {this.state.toBeCancelSessionCount > 1 ? 'these ' : 'this '}
                {this.state.toBeCancelSessionCount > 1 ? (
                  <b>{this.state.toBeCancelSessionCount} sessions</b>
                ) : (
                  <b>session</b>
                )}
                .{' '}
              </Text>
              <br />
              <Text className={'mb-medium'}>All customers and team members will be notified</Text>
              <ActionModalFooter>
                <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseCancelScheduledSessionModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._onClickCancelScheduledSession}>
                  Cancel
                </PrimaryButton>
              </ActionModalFooter>
            </ActionModal>
            <ActionModal
              isOpen={this.state.isCancelSuccessModalOpen}
              onClose={this._onCloseCancelSuccessModal}
              title={'Sessions successfully cancelled'}
              showCloseButton={true}
            >
              <Text className={'mb-medium'}>
                You have successfully cancelled{' '}
                <b>
                  {this.state.toBeCancelSessionCount > 1
                    ? `${this.state.toBeCancelSessionCount} sessions`
                    : 'this session'}
                </b>
                .{' '}
              </Text>
              <ActionModalFooter>
                <PrimaryButton size="large" onClick={this._onCloseCancelSuccessModal}>
                  Close
                </PrimaryButton>
              </ActionModalFooter>
            </ActionModal>
            <div
              className="bg-white mt-medium ph-large pt-large pb-x2-large shadow-container"
              style={{ borderRadius: '16px' }}
            >
              <div className="flex-row justify-between align-center mb-large">
                <div>
                  <Text weight="bold">Sessions</Text> <br />
                  <Text>All sessions for this service</Text>
                </div>
                <div>
                  <PrimaryButton size="large" icon={'plus'} onClick={this.props.onOpenCreateSessionModal}>
                    Create session(s)
                  </PrimaryButton>
                </div>
              </div>

              <Divider className="m-none" />

              <GroupServiceSessionListingPanel
                serviceId={this.props.selectedServiceId}
                selectedFilter={selectedFilter}
                history={history}
                onCheckSession={this._onCheckItem}
                onCheckAllSessions={this._setCheckAllSessions}
                isIndeterminate={!_.isEmpty(selectedItems) && !this.state.checkAllSessions}
                checkAllSessions={this.state.checkAllSessions}
                onActionDeselect={this._onActionDeselect}
              />
            </div>
            {this.state.showActionSheet && (
              <BottomActionSheet
                selectedItems={selectedItems}
                onDeselect={this._onActionDeselect}
                label={'session'}
                labelPlural={'sessions'}
                noLeftMargin={true}
              />
            )}
          </>
        )}

        {this.state.selectedTab === 'Schedule' && (
          <div
            className="bg-white mt-large ph-large pt-large pb-x2-large shadow-container"
            style={{ borderRadius: '16px' }}
          >
            <div className="flex-row justify-between align-center mb-large">
              <div>
                <Title level={4} className="mb-none">
                  Schedules
                </Title>
                <Text>Schedules created for this service.</Text>
              </div>
              <div>
                <PrimaryButton size="large" icon={'plus'} onClick={this.props.onOpenCreateSessionModal}>
                  {' '}
                  Create session(s)
                </PrimaryButton>
              </div>
            </div>

            <GroupServiceScheduleListingPanel serviceId={this.props.selectedServiceId} />
          </div>
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceSessions: state.servicesStore.groupServiceSessions,
  selectedGroupService: state.groupServiceStore.selectedGroupService
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setGroupServiceSessions: dispatch.servicesStore.setGroupServiceSessions,
  doBulkCancelGroupServiceSession: dispatch.servicesStore.doBulkCancelGroupServiceSession
});

export default connect(
  mapState,
  mapDispatch
)(GroupServiceSessionPanel);
