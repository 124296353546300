import React, { Component } from 'react';
import ActionModal from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { notification, Radio, Row } from 'antd';
import { BookingStatus, EditRecurringMode, PaymentStatus } from 'utilities/enum-utils';
import _ from 'lodash';

interface IBookingArchiveModalProps {
  isOpen: boolean;
  onClose: () => void;
  doArchiveBooking: typeof dispatch.bookingsStore.doArchiveBooking;
  selectedBooking: typeof state.bookingsStore.selectedBookingItem;
  recurringBookingList: typeof state.bookingsStore.recurringBookingList;
}

interface IBookingArchiveModalState {
  title: string;
  step: number;
  selectedOption: any;
  canManuallyClose: boolean;
  isLoading: boolean;
}

class BookingArchiveModal extends Component<IBookingArchiveModalProps, IBookingArchiveModalState> {
  state = {
    title: this.props.selectedBooking
      ? this.props.selectedBooking.isShiftPublished
        ? 'Warning'
        : 'Archive booking'
      : 'Archive booking',
    step: this.props.selectedBooking ? (this.props.selectedBooking.isShiftPublished ? 4 : 1) : 1,
    selectedOption: EditRecurringMode.Current,
    canManuallyClose: true,
    isLoading: false
  };

  private _onArchive = async () => {
    const { selectedBooking } = this.props;
    this.setState({ isLoading: true });
    try {
      const { doArchiveBooking } = this.props;
      await doArchiveBooking({
        bookingId: selectedBooking.bookingId,
        isRecurring: selectedBooking.isRecurring,
        editRecurringMode: this.state.selectedOption,
        bookingRequestId: selectedBooking.bookingRequestId
      });
      this.setState({ isLoading: false, step: 3, title: 'Booking successfully archived' });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isLoading: false });
    }
  };

  private _onCloseModal = () => {
    const { onClose, selectedBooking } = this.props;
    this.setState({
      title: selectedBooking.isShiftPublished ? 'Warning' : 'Archive booking',
      step: selectedBooking.isShiftPublished ? 4 : 1,
      selectedOption: EditRecurringMode.Current,
      canManuallyClose: true,
      isLoading: false
    });
    onClose();
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _goToRecurringStep = () => {
    this.setState({ step: 2, title: 'Archive booking' });
  };

  componentDidUpdate(
    prevProps: Readonly<IBookingArchiveModalProps>,
    prevState: Readonly<IBookingArchiveModalState>,
    snapshot?: any
  ) {
    const { selectedBooking } = this.props;
    if (selectedBooking && prevProps.selectedBooking !== this.props.selectedBooking) {
      this.setState({
        title: selectedBooking.isShiftPublished ? 'Warning' : 'Archive booking',
        step: selectedBooking.isShiftPublished ? 4 : 1
      });
    }
  }

  render() {
    let { isOpen, selectedBooking } = this.props;
    let { title, step, selectedOption, isLoading } = this.state;

    const isRecurring = selectedBooking ? selectedBooking.isRecurring : false;
    const numberOfBookingInRecurrenceThatCanBeArchived =
      selectedBooking && isRecurring
        ? _.countBy(
            this.props.recurringBookingList,
            (booking) =>
              !(
                booking.status === BookingStatus.INPROGRESS ||
                booking.status === BookingStatus.COMPLETED ||
                (booking.paymentStatus !== PaymentStatus.REQUIRES_APPROVAL &&
                  booking.paymentStatus !== PaymentStatus.INITIAL &&
                  booking.paymentStatus !== PaymentStatus.WAIVED &&
                  booking.paymentStatus !== PaymentStatus.NO_CHARGE)
              )
          ).true
        : 0;

    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        {step === 1 ? (
          <>
            <div className="anim-slide-left">
              <Paragraph>Are you sure you want to archive this booking?</Paragraph>

              <Paragraph>This action is permanent and cannot be undone.</Paragraph>
            </div>
            <div className={'mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton
                  className="mr-medium"
                  size="large"
                  onClick={this._onCloseModal}
                  disabled={this.state.isLoading}
                >
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  color={!isRecurring && 'red-dark'}
                  onClick={isRecurring ? this._goToRecurringStep : this._onArchive}
                  loading={this.state.isLoading}
                >
                  {isRecurring ? 'Continue' : 'Archive'}
                </PrimaryButton>
              </Row>
            </div>
          </>
        ) : step === 2 ? (
          <>
            <div className="anim-slide-left">
              <Paragraph>
                This booking is part of a recurring series. There are {numberOfBookingInRecurrenceThatCanBeArchived}{' '}
                bookings in this series that have not been completed/approved. Please select from one of the options
                below.
              </Paragraph>
            </div>
            <div>
              <Radio.Group value={selectedOption} onChange={this._onChangeOption} className="ml-medium flex-wrap">
                <Radio
                  value={EditRecurringMode.Current}
                  className={`${selectedOption === EditRecurringMode.Current && 'text-weight-bold'} mb-small `}
                >
                  <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                    Archive this booking only.
                  </div>
                </Radio>
                <br />
                <Radio
                  value={EditRecurringMode.CurrentAll}
                  className={`${selectedOption === EditRecurringMode.CurrentAll && 'text-weight-bold'} mb-small `}
                >
                  <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                    Archive all {numberOfBookingInRecurrenceThatCanBeArchived} booking
                    {numberOfBookingInRecurrenceThatCanBeArchived !== 1 && 's'} that have not been approved/completed.
                  </div>
                </Radio>
              </Radio.Group>
            </div>
            <div className={'mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" color={'red-dark'} onClick={this._onArchive} loading={isLoading}>
                  Archive
                </PrimaryButton>
              </Row>
            </div>
          </>
        ) : step === 3 ? (
          <>
            <div className="anim-slide-left">
              <Paragraph>There are no further actions required.</Paragraph>
            </div>
            <div className={'mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <PrimaryButton size="large" onClick={this._onCloseModal}>
                  Done
                </PrimaryButton>
              </Row>
            </div>
          </>
        ) : step === 4 ? (
          <>
            <div className="anim-slide-left">
              <Paragraph>You cannot archive a booking that is currently being published to workers.</Paragraph>
              <Paragraph>Please un-publish the booking if you wish to archive it.</Paragraph>
            </div>
            <div className={'mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <PrimaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                  Ok
                </PrimaryButton>
              </Row>
            </div>
          </>
        ) : (
          <></>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  recurringBookingList: state.bookingsStore.recurringBookingList,
  selectedBooking: state.bookingsStore.selectedBookingItem
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doArchiveBooking: dispatch.bookingsStore.doArchiveBooking
});

export default connect(
  mapState,
  mapDispatch
)(BookingArchiveModal);
