import { Tooltip2 } from '@blueprintjs/popover2';
import { Checkbox, Empty, Input, Skeleton, Tabs } from 'antd';
import { IconButton } from 'common-components/buttons';
import { FilterSection } from 'common-components/filter';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { BottomActionSheet } from 'common-components/Sheets/BottomActionSheet';
import { ItemCountSheet } from 'common-components/Sheets/ItemCountSheet';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FilterType, PaymentSources } from 'utilities/enum-utils';
import { SubTitle, Text, Title } from '../../../../common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../stores/rematch/root-store';
import InvoicePaidItemRow from '../components/details/InvoicePaidItemRow';
import MarkAsUnPaidActionModal from '../components/MarkAsUnPaidActionModal';

const { Search } = Input;

interface IPayListPaidSectionProps {
  invoicesList?: typeof state.billingsStore.invoiceList;
  invoicesFilter?: typeof state.billingsStore.invoicesFilter;
  currentFilterConfig?: any;
  history?: any;
  isServiceProviderVCPEnabled: boolean;
  doFetchInvoices: typeof dispatch.billingsStore.doFetchInvoices;
  setInvoices?: typeof dispatch.billingsStore.setInvoices;
  setInvoicesFilter?: typeof dispatch.billingsStore.setInvoicesFilter;
  setSelectedInvoiceLineItems?: typeof dispatch.billingsStore.setSelectedInvoiceLineItems;
  setSelectedInvoice?: typeof dispatch.billingsStore.setSelectedInvoice;
  setSelectedInvoices?: typeof dispatch.billingsStore.setSelectedInvoices;
  setSelectedHistoryIds?: typeof dispatch.billingsStore.setSelectedHistoryIds;
  portalUser: typeof state.authStore.portalUser;
  selectedInvoice: typeof state.billingsStore.selectedInvoice;
  selectedInvoices: typeof state.billingsStore.selectedInvoices;
  setMarkAsUnPaidType: typeof dispatch.billingsStore.setMarkAsUnPaidType;
  setIsCheckAll: typeof dispatch.billingsStore.setIsCheckAll;
  isCheckAll: typeof state.billingsStore.isCheckAll;
}

interface IPayListPaidSectionState {
  topHeight: number;
  showFilters: boolean;
  showActionSheet: boolean;
  checkAllIndicator: boolean;
  indeterminateCheck: boolean;
  isLoading: boolean;
  isSearching: boolean;
  searchString: string;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  action: string;
  openAction: boolean;
  filters: any;
  paymentSourceType: string;
  availableFilters: Array<any>;
}

const ProcessedEmptyState = () => (
  <div className="flex-1 bg-white  align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No Invoices found.
    </Text>{' '}
    <br /> <br />
    <Text color="secondary">All invoices under this filter will appear here.</Text>
    <Text color="secondary">Try adjusting your filter, or clicking on another view.</Text>
  </div>
);

const isPaid = true;

class PayListPaidSection extends PureComponent<IPayListPaidSectionProps, IPayListPaidSectionState> {
  // topHeight is used to control sticky
  state = {
    topHeight: 0,
    showFilters: false,
    showActionSheet: false,
    checkAllIndicator: false,
    indeterminateCheck: false,
    isLoading: false,
    isSearching: false,
    searchString: '',
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    action: '',
    openAction: false,
    filters: [],
    paymentSourceType: PaymentSources.NDIS,
    availableFilters: [FilterType.CUSTOMER, FilterType.PAYMENT_METHODS, FilterType.INVOICE_DATE, FilterType.DATE_PAID]
  };

  _headerElement = null;

  openActionModal = ({ action, additionalData, selected = null }) => {
    const { setSelectedInvoice, setMarkAsUnPaidType } = this.props;
    if (action === 'MARK_AS_UNPAID') {
      setMarkAsUnPaidType('SINGLE');
      setSelectedInvoice(additionalData);
    }
    this.setState({ action, openAction: true });
  };

  getActionModal = () => {
    const { action } = this.state;
    return action === 'MARK_AS_UNPAID' ? MarkAsUnPaidActionModal : () => <></>;
  };

  closeActionModal = () => this.setState({ openAction: false });

  private _handleHeaderHeight = () => {
    if (this._headerElement) {
      this.setState({ topHeight: this._headerElement.offsetHeight - 1 });
    }
  };

  private _formatFilterQuery = (appliedFilters = this.props.invoicesFilter) => {
    const requestFilter: any = {};
    _.forEach(appliedFilters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'invoiceDate':
            requestFilter.issueDate = [filter.values[0].toDate(), filter.values[1].toDate()];
            break;
          case 'datePaid':
            requestFilter.paidDate = [filter.values[0].toDate(), filter.values[1].toDate()];
            break;
          case 'paymentMethods':
            requestFilter.paymentMethods = filter.values;
            break;
          case 'customerUserIds':
            requestFilter.customerUserIds = _.map(filter.values, (customer) => {
              return customer.value;
            });
            break;
          case 'search':
            requestFilter.search = filter.values;
            break;
        }
      }
    });
    return requestFilter;
  };

  private _applySearchFilter = async (searchStr) => {
    const { invoicesFilter, setInvoicesFilter } = this.props;
    const newPlanPaymentsFilter = _.clone(invoicesFilter);
    const existingSearchIndex = _.findIndex(newPlanPaymentsFilter, (filter: any) => filter.filter === 'search');
    if (existingSearchIndex > -1) {
      if (searchStr === '') {
        newPlanPaymentsFilter.splice(existingSearchIndex, 1);
      } else {
        newPlanPaymentsFilter[existingSearchIndex].values = searchStr;
      }
    } else {
      newPlanPaymentsFilter.push({ filter: 'search', values: searchStr });
    }
    setInvoicesFilter(newPlanPaymentsFilter);
    this.setState({ isSearching: false });
  };

  private _searchText = (txt) => {
    this._applySearchFilter(txt);
    this.setState({ isSearching: false, searchString: txt });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });

    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setInvoicesFilter(filters);
  };

  _refreshListings = async () => {
    const { doFetchInvoices } = this.props;
    const { paymentSourceType, page, pageTimestamp, pageSize } = this.state;
    this.setState({ isLoading: true, checkAllIndicator: false, indeterminateCheck: false, showActionSheet: false });
    this.setState({ isLoading: false, pageTimestamp: new Date(), page: 1 });

    await doFetchInvoices({
      ...this._formatFilterQuery(),
      paymentSourceType,
      page,
      pageTimestamp,
      pageSize,
      isPaid
    });
    this.setState({ isLoading: false });
  };

  private _fetchMorePayment = async () => {
    const { doFetchInvoices } = this.props;
    const { paymentSourceType, page, pageTimestamp, pageSize } = this.state;
    const newPage = page + 1;
    this.setState({ isLoading: true, page: newPage });
    await doFetchInvoices({
      ...this._formatFilterQuery(),
      paymentSourceType,
      page: newPage,
      pageSize,
      pageTimestamp,
      isPaid
    });
    this.setState({ isLoading: false });
  };

  private _changeTab = (e) => {
    this.props.setInvoicesFilter([]);
    this.setState(
      {
        paymentSourceType: e,
        availableFilters:
          e === PaymentSources.NDIS
            ? [FilterType.CUSTOMER, FilterType.PAYMENT_METHODS, FilterType.INVOICE_DATE, FilterType.DATE_PAID]
            : [FilterType.CUSTOMER, FilterType.INVOICE_DATE, FilterType.DATE_PAID]
      },
      () => this._refreshListings()
    );
  };

  private _checkedInvoice = (invoiceItem) => {
    const { selectedInvoices } = this.props;
    const isSelected = _.filter(selectedInvoices, (invoice) => invoice.invoiceId === invoiceItem.invoiceId);
    return isSelected.length > 0;
  };

  private _onCheckInvoice = (invoiceItem) => {
    const { selectedInvoices, setSelectedInvoices, isCheckAll, setIsCheckAll, invoicesList } = this.props;
    let newSelectedInvoice;

    const isExisted = _.filter(selectedInvoices, (invoice) => invoice.invoiceId === invoiceItem.invoiceId);
    if (isExisted.length !== 0) {
      newSelectedInvoice = _.filter(selectedInvoices, (invoice) => invoice.invoiceId !== invoiceItem.invoiceId);
    } else {
      newSelectedInvoice = [...selectedInvoices, invoiceItem];
    }

    if (isCheckAll) {
      setIsCheckAll(!isCheckAll);
    } else {
      const unRejectedInvoices = _.filter(invoicesList, (invoice) => {
        const items = invoice.items;
        const rejectedItems = _.filter(items, (item) => item.isRejected);
        return rejectedItems.length < items.length;
      });
      if (unRejectedInvoices.length === newSelectedInvoice.length) {
        setIsCheckAll(isCheckAll);
      }
    }

    setSelectedInvoices(newSelectedInvoice);
  };

  private _onCheckAll = () => {
    const { setSelectedInvoices, invoicesList, setIsCheckAll, isCheckAll } = this.props;

    const filteredInvoicesList = _.filter(invoicesList, (invoice) => {
      const items = invoice.items;
      const rejectedItems = _.filter(items, (item) => item.isRejected);
      return rejectedItems.length < items.length;
    });

    const newSelectedInvoice = isCheckAll ? [] : [...filteredInvoicesList];
    setIsCheckAll(!isCheckAll);
    setSelectedInvoices(newSelectedInvoice);
  };

  private _openMultipleActionModal = () => {
    this.props.setMarkAsUnPaidType('MULTIPLE');
    this.setState({ action: 'MARK_AS_UNPAID', openAction: true });
  };

  private _onActionDeselect = () => {
    this.props.setSelectedInvoices([]);
  };

  //region Component Lifecycle Methods
  componentDidMount = async () => {
    // Automatically set the top height for the top panel. This is required for sticky.
    this._handleHeaderHeight();

    const { doFetchInvoices, currentFilterConfig, invoicesFilter } = this.props;
    const { paymentSourceType, page, pageTimestamp, pageSize } = this.state;

    const appliedFilters = _.isEmpty(invoicesFilter) ? currentFilterConfig.filters : invoicesFilter;

    this.setState({ isLoading: true });

    await doFetchInvoices({
      ...this._formatFilterQuery(appliedFilters),
      paymentSourceType,
      page,
      pageTimestamp,
      pageSize,
      isPaid
    });

    this.setState({ isLoading: false });
  };

  componentDidUpdate = async (prevProps) => {
    const { currentFilterConfig, doFetchInvoices, invoicesFilter } = this.props;

    if (prevProps.currentFilterConfig.key !== currentFilterConfig.key) {
      this.setState({
        isLoading: true,
        searchString: '',
        showFilters: false
      });
    }

    if (prevProps.invoicesFilter !== invoicesFilter) {
      this.setState({ isLoading: true, page: 1 });
      const { paymentSourceType, page, pageTimestamp, pageSize } = this.state;

      await doFetchInvoices({
        ...this._formatFilterQuery(),
        paymentSourceType,
        page,
        pageSize,
        pageTimestamp,
        isPaid
      });
      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount = async () => {
    const { setSelectedInvoices, setInvoices, setIsCheckAll } = this.props;
    setSelectedInvoices([]);
    setInvoices([]);
    setIsCheckAll(false);
  };

  render() {
    const { invoicesList, invoicesFilter, portalUser, history, currentFilterConfig, selectedInvoices } = this.props;
    const {
      openAction,
      isSearching,
      availableFilters,
      topHeight,
      isLoading,
      page,
      pageSize,
      showActionSheet
    } = this.state;

    const TargetActionModal: any = this.getActionModal();

    const thTableStyle = { top: `${topHeight}px` };

    return (
      <div
        id={'scroll-container'}
        className="bg-white flex-1 width-full flex-column"
        style={{ overflowY: 'auto', position: 'relative' }}
      >
        <TargetActionModal isOpen={openAction} onClose={this.closeActionModal} />
        <div>
          <div className="booking-header" ref={(com) => (this._headerElement = com)}>
            <div className="flex-row justify-between align-center">
              <div>
                <Title level={3} className="mv-none" lineHeight={150}>
                  {currentFilterConfig.title}
                </Title>
                <Text color="secondary">{currentFilterConfig.description}</Text>
              </div>
              <div className="flex-column align-center">
                <div className="pr-x-small">
                  <Tooltip2 content="Refresh this view">
                    <IconButton
                      color="white"
                      onClick={this._refreshListings}
                      iconColor="black"
                      size="large"
                      icon="reload"
                    />
                  </Tooltip2>
                </div>
              </div>
            </div>

            <Tabs defaultActiveKey={'ALL'} animated={true} onChange={this._changeTab} className={'mt-large'}>
              <Tabs.TabPane tab={PaymentSources.NDIS} key={PaymentSources.NDIS} />
              {this.props.isServiceProviderVCPEnabled && (
                <Tabs.TabPane tab={PaymentSources.VCP} key={PaymentSources.VCP} />
              )}
            </Tabs>

            <div className="flex-row pb-medium align-center justify-between">
              <div className="mr-x2-large" style={{ minWidth: '300px' }}>
                <Search
                  onChange={this._onEnterSearchText}
                  loading={isSearching}
                  placeholder="Name, Invoice number or Batch number"
                />
              </div>
              <FilterSection
                availableFilters={availableFilters}
                filters={invoicesFilter ? invoicesFilter : []}
                onChangeFilter={this._onChangeFilter}
                displayTimezone={portalUser.timezone}
                containerClassName={'mv-small'}
              />
            </div>
          </div>

          <table className="payment-listing">
            <thead>
              <tr>
                <th className="nowrap check-all" style={thTableStyle}>
                  <Checkbox
                    onClick={this._onCheckAll}
                    checked={
                      !isLoading && selectedInvoices.length !== 0 && selectedInvoices.length === invoicesList.length
                    }
                    indeterminate={
                      !isLoading && selectedInvoices.length < invoicesList.length && selectedInvoices.length !== 0
                    }
                  />
                </th>
                <th style={thTableStyle} className="nowrap">
                  <SubTitle>INVOICE DATE</SubTitle>
                </th>
                <th style={thTableStyle} className="nowrap">
                  <SubTitle>DATE PAID</SubTitle>
                </th>
                <th style={thTableStyle} className="nowrap">
                  <SubTitle>INVOICE NUMBER</SubTitle>
                </th>
                <th style={thTableStyle} className="nowrap">
                  <SubTitle>CUSTOMER</SubTitle>
                </th>
                <th style={thTableStyle} className="nowrap text-align-right">
                  <SubTitle containerClassName="text-align-right">Total</SubTitle>
                </th>
                <th style={{ ...thTableStyle, width: '100px' }} />
                <th style={{ ...thTableStyle, width: '50px' }} />
              </tr>
            </thead>

            <tbody>
              {!isLoading && _.isEmpty(invoicesList) && (
                <tr style={{ cursor: 'default' }}>
                  <td colSpan={7} style={{ borderBottom: '0px solid' }}>
                    <ProcessedEmptyState />
                  </td>
                </tr>
              )}

              <InfiniteScrollLoading
                hasMore={invoicesList.length >= page * pageSize}
                loadingElementId={'scroll-container'}
                loadMore={this._fetchMorePayment}
                loaderColSpan={7}
                loadingOffSet={60}
              >
                {_.map(invoicesList, (invoiceItem) => (
                  <InvoicePaidItemRow
                    invoiceItem={invoiceItem}
                    history={history}
                    key={invoiceItem.invoiceId}
                    openActionModal={this.openActionModal}
                    onCheckInvoice={this._onCheckInvoice}
                    isChecked={this._checkedInvoice(invoiceItem)}
                  />
                ))}
              </InfiniteScrollLoading>
              {isLoading && (
                <tr style={{ borderBottom: '0px solid !important' }}>
                  <td colSpan={7}>
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Filler */}
        <div className="flex-1 bg-white">&nbsp;</div>

        {selectedInvoices.length > 0 && (
          <BottomActionSheet
            paymentsList={selectedInvoices}
            onDeselect={this._onActionDeselect}
            refreshPaymentListings={this._refreshListings}
            openActionModal={this._openMultipleActionModal}
            hasAdditionalButton={false}
            primaryButtonTitle={`Mark as unpaid`}
          />
        )}

        {!isLoading && !showActionSheet && invoicesList.length > 0 && (
          <ItemCountSheet itemCount={invoicesList.length} itemLabel="Invoice" />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBillingLineItem: state.billingsStore.selectedBillingLineItem,
  selectedInvoice: state.billingsStore.selectedInvoice,
  selectedInvoices: state.billingsStore.selectedInvoices,
  isCheckAll: state.billingsStore.isCheckAll
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedInvoiceLineItems: dispatch.billingsStore.setSelectedInvoiceLineItems,
  setSelectedInvoice: dispatch.billingsStore.setSelectedInvoice,
  setSelectedHistoryIds: dispatch.billingsStore.setSelectedHistoryIds,
  setSelectedInvoices: dispatch.billingsStore.setSelectedInvoices,
  setMarkAsUnPaidType: dispatch.billingsStore.setMarkAsUnPaidType,
  setIsCheckAll: dispatch.billingsStore.setIsCheckAll
});

export default connect(mapState, mapDispatch)(PayListPaidSection);
