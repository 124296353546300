import { Col, Icon, notification, Row, Select } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Warning } from '../../../../../common-components/alerts/index';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from '../../../../../common-components/buttons/index';
import NDISLineItemGrid from '../../../../../common-components/line-items/NDISLineItemGrid';
import SpinningLoader from '../../../../../common-components/loading/SpinningLoader';
import { Paragraph, SubTitle, Text, Title } from '../../../../../common-components/typography/index';
import { INewActivityRecordData } from '../../../../../interfaces/booking-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../../stores/rematch/root-store';
import { PaymentSources } from '../../../../../utilities/enum-utils';
import SelectLineItemToChargeModal from '../SelectLineItemToChargeModal';

interface IActivityRecordStep3BillingDetailsProps {
  customerBookingPaymentDetail: typeof state.bookingsStore.customerBookingPaymentDetail;
  doFetchBookingSuggestionPaymentDetail: typeof dispatch.bookingsStore.doFetchBookingSuggestionPaymentDetail;
  customerBookingSuggestionPaymentDetail: typeof state.bookingsStore.customerBookingSuggestionPaymentDetail;
  newActivityRecordData: INewActivityRecordData;
  portalUser: typeof state.authStore.portalUser;
  setNewActivityRecordData: typeof dispatch.bookingsStore.setNewActivityRecordData;
  doFetchBookingPaymentDetail: typeof dispatch.bookingsStore.doFetchBookingPaymentDetail;
  onNextStep: () => void;
  doCheckServiceAgreementDate: typeof dispatch.bookingsStore.doCheckServiceAgreementDate;
  onPreviousStep: () => void;
}

interface IActivityRecordStep3BillingDetailsState {
  isLoading: boolean;
  isSelectLineItemOpen: boolean;
  selectedPaymentSourceType: string;
  showActiveAgreementWarning: boolean;
}

class ActivityRecordStep3BillingDetails extends Component<
  IActivityRecordStep3BillingDetailsProps,
  IActivityRecordStep3BillingDetailsState
> {
  state = {
    isLoading: false,
    isSelectLineItemOpen: false,
    selectedPaymentSourceType: null,
    showActiveAgreementWarning: false
  };

  private _onNextStep = () => {
    const { newActivityRecordData, setNewActivityRecordData } = this.props;
    setNewActivityRecordData({ ...newActivityRecordData, paymentSourceType: this.state.selectedPaymentSourceType });
    this.props.onNextStep();
  };

  private _closeSelectLineItem = () => {
    this.setState({ isSelectLineItemOpen: false });
  };

  private _openSelectLineItem = () => {
    this.setState({ isSelectLineItemOpen: true });
  };

  private _getServiceAgreementTitle = (billingPeriod) => {
    const { customerBookingSuggestionPaymentDetail } = this.props;
    const { timezone } = this.props.portalUser;
    if (customerBookingSuggestionPaymentDetail) {
      if (
        customerBookingSuggestionPaymentDetail.billingSuggestion &&
        customerBookingSuggestionPaymentDetail.billingSuggestion.length === 1
      ) {
        if (!_.isEmpty(billingPeriod.serviceAgreementBillingItems)) {
          return 'Line items being charged (From service agreement)';
        } else {
          return 'Line items being charged (From service)';
        }
      } else {
        if (!_.isEmpty(billingPeriod.serviceAgreementBillingItems)) {
          return (
            <>
              <b>{moment.tz(billingPeriod.startDateTime, timezone).format('DD/MM/YYYY')}</b> to{' '}
              <b>{moment.tz(billingPeriod.endDateTime, timezone).format('DD/MM/YYYY')}</b> (From service agreement)
            </>
          );
        } else {
          return (
            <>
              <b>{moment.tz(billingPeriod.startDateTime, timezone).format('DD/MM/YYYY')}</b> to{' '}
              <b>{moment.tz(billingPeriod.endDateTime, timezone).format('DD/MM/YYYY')}</b> (From service)
            </>
          );
        }
      }
    }
  };

  private _checkCustomerActiveServiceAgreement = async () => {
    const {
      selectedServiceId,
      selectedCustomerId,
      selectedService,
      bookingStartDate,
      bookingEndDate
    } = this.props.newActivityRecordData;
    const { timezone } = selectedService;
    if (
      bookingStartDate &&
      bookingEndDate &&
      selectedServiceId &&
      selectedService.servicePaymentSources &&
      selectedService.servicePaymentSources.length === 1
    ) {
      const fullStartDate = moment.tz(bookingStartDate, timezone).toDate();
      const fullEndDate = moment.tz(bookingEndDate, timezone).toDate();
      const result: any = await this.props.doCheckServiceAgreementDate({
        startDateTime: fullStartDate,
        endDateTime: fullEndDate,
        serviceId: selectedServiceId,
        customerUserId: selectedCustomerId,
        paymentSourceType: selectedService.servicePaymentSources[0]
      });
      if (result && !result.conflict) {
        this.setState({
          showActiveAgreementWarning: true
        });
      } else {
        this.setState({
          showActiveAgreementWarning: false
        });
      }
    }
  };

  private _onSaveSelection = (selectedLineItems) => {
    const selectedBillingItems = selectedLineItems.map((item) => {
      const selectedLineItemNameArray = item.selectedLineItems.map((lineItem) => lineItem.supportItemNumber);
      return {
        startDateTime: item.startDateTime,
        endDateTime: item.endDateTime,
        selectedLineItems: selectedLineItemNameArray
      };
    });

    this.props.setNewActivityRecordData({
      ...this.props.newActivityRecordData,
      selectedLineItems,
      selectedBillingItems
    });
  };

  private _onChangePaymentSourceType = (e) => {
    this._initialisePaymentSuggestions(e);
    this.setState({ selectedPaymentSourceType: e });
  };

  private _initialisePaymentSuggestions = async (paymentSourceType) => {
    const { doFetchBookingPaymentDetail, doFetchBookingSuggestionPaymentDetail, newActivityRecordData } = this.props;
    this.setState({ isLoading: true });
    try {
      const payload = {
        serviceId: newActivityRecordData.selectedServiceId,
        customerUserId: newActivityRecordData.selectedCustomerId,
        timeSlots: newActivityRecordData.timeSlots,
        address: {
          ...newActivityRecordData.bookLocation,
          geoLat: Number(newActivityRecordData.bookLocation.geoLat),
          geoLng: Number(newActivityRecordData.bookLocation.geoLng)
        },
        paymentSourceType
      };

      await Promise.all([
        doFetchBookingSuggestionPaymentDetail(payload),
        doFetchBookingPaymentDetail(payload),
        this._checkCustomerActiveServiceAgreement()
      ]);
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong! Please try again.' });
    }
    this.setState({ isLoading: false });
  };

  componentDidMount = async () => {
    const { newActivityRecordData } = this.props;
    const paymentSourceTypes = newActivityRecordData.selectedService.servicePaymentSources;
    const selectedPaymentSourceType =
      newActivityRecordData && newActivityRecordData.paymentSourceType
        ? newActivityRecordData.paymentSourceType
        : paymentSourceTypes.length > 1
        ? null
        : paymentSourceTypes[0];
    this.setState({
      selectedPaymentSourceType
    });
    selectedPaymentSourceType && this._initialisePaymentSuggestions(selectedPaymentSourceType);
  };

  render() {
    const {
      customerBookingPaymentDetail,
      customerBookingSuggestionPaymentDetail,
      newActivityRecordData,
      onPreviousStep
    } = this.props;
    const { isSelectLineItemOpen, selectedPaymentSourceType, isLoading } = this.state;

    const paymentSourceTypes =
      newActivityRecordData &&
      newActivityRecordData.selectedService &&
      newActivityRecordData.selectedService.servicePaymentSources;

    return (
      <div className="anim-slide-left">
        <SelectLineItemToChargeModal
          isOpen={isSelectLineItemOpen}
          billingPeriods={customerBookingPaymentDetail && customerBookingPaymentDetail.billingSuggestion}
          onClose={this._closeSelectLineItem}
          locationMmmGroup={customerBookingSuggestionPaymentDetail && customerBookingSuggestionPaymentDetail.mmmGroup}
          locationState={
            newActivityRecordData && newActivityRecordData.bookLocation && newActivityRecordData.bookLocation.state
          }
          getServiceAgreementTitle={this._getServiceAgreementTitle}
          onSaveSelection={this._onSaveSelection}
          paymentSourceType={selectedPaymentSourceType}
        />
        <Row className="ph-x4-large mt-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 184px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Paragraph>
                Displayed here are the <b>line item(s)</b> that will be used for the billing of this activity record.
              </Paragraph>
              <Paragraph>
                These line item(s) are based on the <b>line items</b> specified in service being provided & the{' '}
                <b>customer's service agreement</b>.
              </Paragraph>
              <Paragraph>
                You can choose to charge different line items with the '<b>Charge different line items</b>' button.
              </Paragraph>
              {/*<Paragraph>*/}
              {/*  Visit the <a>Help Center</a> to learn more.*/}
              {/*</Paragraph>*/}
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 184px)' }}>
            <div className="bg-white rounded-big p-large" style={{ minWidth: '250px', minHeight: '463px' }}>
              <Title level={4}>Select how you want to charge for this activity record</Title>

              {paymentSourceTypes && paymentSourceTypes.length > 1 && (
                <div className={'mb-medium'}>
                  <SubTitle>Funding type</SubTitle>
                  <Select
                    size={'large'}
                    style={{ width: '200px' }}
                    value={selectedPaymentSourceType}
                    onChange={this._onChangePaymentSourceType}
                  >
                    {_.map(paymentSourceTypes, (paymentSource) => (
                      <Select.Option value={PaymentSources[paymentSource]}>
                        {PaymentSources[paymentSource]}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}

              {isLoading ? (
                <SpinningLoader size={150} message={'Retrieving line items...'} />
              ) : (
                <>
                  {this.state.showActiveAgreementWarning && (
                    <Warning
                      borderNone
                      content={
                        <Text color="warning-orange">
                          The customer does not have active service agreement for every bookings being created in this
                          pattern.
                        </Text>
                      }
                      className="mb-large"
                    />
                  )}

                  {selectedPaymentSourceType &&
                    customerBookingSuggestionPaymentDetail &&
                    _.map(customerBookingSuggestionPaymentDetail.billingSuggestion, (billingPeriod, index) => {
                      const periodSelectedLineItems = _.find(
                        newActivityRecordData.selectedLineItems,
                        (period) => period.startDateTime === billingPeriod.startDateTime
                      );
                      return (
                        <>
                          <div className={'mb-small'}>{this._getServiceAgreementTitle(billingPeriod)}</div>
                          <div className={'mb-x2-large pt-medium bordered rounded-big shadow-container'} key={index}>
                            <NDISLineItemGrid
                              lineItems={
                                periodSelectedLineItems
                                  ? periodSelectedLineItems.selectedLineItems
                                  : !_.isEmpty(billingPeriod.serviceAgreementBillingItems)
                                  ? billingPeriod.serviceAgreementBillingItems
                                  : !_.isEmpty(billingPeriod.serviceBillingItems)
                                  ? billingPeriod.serviceBillingItems
                                  : null
                              }
                              displayMode={'VIEW'}
                              isServiceAgreementLineItems={
                                (periodSelectedLineItems &&
                                  !_.isEmpty(periodSelectedLineItems.selectedLineItems) &&
                                  periodSelectedLineItems.selectedLineItems[0].agreementPrice) ||
                                !_.isEmpty(billingPeriod.serviceAgreementBillingItems)
                              }
                              mmmGroup={customerBookingSuggestionPaymentDetail.mmmGroup}
                              state={
                                newActivityRecordData &&
                                newActivityRecordData.bookLocation &&
                                newActivityRecordData.bookLocation.state
                              }
                              noMargin={true}
                              paymentSourceType={selectedPaymentSourceType}
                            />
                          </div>
                        </>
                      );
                    })}

                  <div className={'mt-medium'}>
                    {selectedPaymentSourceType && (
                      <HyperlinkButton onClick={this._openSelectLineItem}>
                        <Icon type={'plus'} className={'mr-x-small'} />
                        Charge different line items
                      </HyperlinkButton>
                    )}
                  </div>
                </>
              )}
            </div>

            {!this.state.isLoading && (
              <div className="flex-row justify-end mt-x2-large">
                <SecondaryButton size="large" onClick={onPreviousStep}>
                  Previous
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  disabled={_.isEmpty(newActivityRecordData.selectedCustomerId) || _.isEmpty(selectedPaymentSourceType)}
                  onClick={this._onNextStep}
                  className={'ml-medium'}
                >
                  Next
                </PrimaryButton>
              </div>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  newActivityRecordData: state.bookingsStore.newActivityRecordData,
  customerBookingPaymentDetail: state.bookingsStore.customerBookingPaymentDetail,
  customerBookingSuggestionPaymentDetail: state.bookingsStore.customerBookingSuggestionPaymentDetail
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchBookingPaymentDetail: dispatch.bookingsStore.doFetchBookingPaymentDetail,
  doFetchBookingSuggestionPaymentDetail: dispatch.bookingsStore.doFetchBookingSuggestionPaymentDetail,
  setNewActivityRecordData: dispatch.bookingsStore.setNewActivityRecordData,
  doCheckServiceAgreementDate: dispatch.bookingsStore.doCheckServiceAgreementDate
});

export default connect(
  mapState,
  mapDispatch
)(ActivityRecordStep3BillingDetails);
