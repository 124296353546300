import React, { Component } from 'react';
import { PermissionRowBase } from 'views/account-management/add-new-member/panels/permissions-panel/portal/PermissionRowBase';
import { IPermissionRole, IPermissions } from 'interfaces/account-interfaces';
import PlanManagementSectionModal from 'views/account-management/components/PlanManagementSectionModal';
import {
  getPlanManagementPermissionName,
  getPlanManagementRole
} from 'views/account-management/utils/permission-utils';
import { UserPermissionRole } from 'utilities/enum-utils';

interface PlanManagementPermissionRowProps {
  permissions: IPermissions;
  onChangePermissions: any;
  actionsDisabled: boolean;
  hasAllAccess: boolean;
}

interface PlanManagementPermissionRowState {
  planManagementModalOpen: boolean;
}

class PlanManagementPermissionRow extends Component<
  PlanManagementPermissionRowProps,
  PlanManagementPermissionRowState
> {
  state = { planManagementModalOpen: false };

  private _onOpenPlanManagement = () => this.setState({ planManagementModalOpen: true });
  private _onClosePlanManagement = () => this.setState({ planManagementModalOpen: false });

  // Triggered whenever a selection is made.
  private _onSelectPlanManagement = (result) => {
    const { permissions, onChangePermissions } = this.props;
    const { permissionRoles } = permissions;

    // filter out payment permissions first
    const filteredRoles = permissionRoles.filter((role) => role.permissionRole !== UserPermissionRole.PlanManager);

    // Remap back to UserPermissionRole
    const targetRole = getPlanManagementRole(result.planManagementPermission);

    if (targetRole) {
      const newCustomerRole: IPermissionRole = {
        permissionRole: targetRole,
        serviceDepartmentId: null,
        serviceId: null
      };
      const updatedPermissions = { ...permissions, permissionRoles: [...filteredRoles, newCustomerRole] };
      onChangePermissions(updatedPermissions);
    } else {
      const updatedPermissions = { ...permissions, permissionRoles: filteredRoles };
      onChangePermissions(updatedPermissions);
    }
  };

  render() {
    const { permissions } = this.props;

    const planManagementPermissionName = getPlanManagementPermissionName(permissions, this.props.hasAllAccess);

    return (
      <>
        {/* Display Row */}
        <PermissionRowBase
          iconName={'contacts'}
          title={'Plan Management'}
          description={'Access to plan management area of GoodHuman'}
          currentFlag={planManagementPermissionName}
          onClickModify={this._onOpenPlanManagement}
          actionsDisabled={this.props.actionsDisabled}
        />

        {/* Modify Edit dialog */}
        <PlanManagementSectionModal
          isOpen={this.state.planManagementModalOpen}
          onClose={this._onClosePlanManagement}
          setSelectedOption={this._onSelectPlanManagement}
          permissions={permissions}
        />
      </>
    );
  }
}

export default PlanManagementPermissionRow;
