import React, { Component } from 'react';
import { Avatar, Col } from 'antd';
import { Text } from 'common-components/typography';
import * as H from 'history';
import { GridRow } from 'common-components/grids';
import PermissionUtils from 'utilities/permission-utils';
import { IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import TeamListingAlerts from 'views/team/listing/components/TeamListingAlerts';

interface IWorkerListingItemV2Props {
  history: H.History;
  workerItem: any;
  portalUser: typeof state.authStore.portalUser;
}

class WorkerListingItemV2 extends Component<IWorkerListingItemV2Props, any> {
  state = { isHovered: false };

  setHovered = () => this.setState({ isHovered: true });

  setUnhovered = () => this.setState({ isHovered: false });

  _onClick = () => {
    const { workerItem, history } = this.props;
    history.push(`/team/details/${workerItem.supportWorkerId}`);
  };

  render() {
    const { workerItem } = this.props;

    return (
      <GridRow
        onClick={
          PermissionUtils.validatePermission('ViewTeamMemberProfile', this.props.portalUser.permissions.permissionRoles)
            ? this._onClick
            : () => false
        }
        onMouseEnter={this.setHovered}
        onMouseLeave={this.setUnhovered}
      >
        <Col span={5}>
          <div className="flex-row align-center">
            <Avatar className="mr-medium" size="large" icon="user" src={workerItem.attachmentUrl} />
            <Text weight="bold">{workerItem.firstName + ' ' + workerItem.lastName}</Text>
          </div>
        </Col>
        <Col span={4}>
          <Text>{workerItem.userLocationByState}</Text>
        </Col>
        <Col span={15}>
          <TeamListingAlerts alerts={workerItem.alerts} />
        </Col>
      </GridRow>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser
});

export default connect(
  mapState,
  null
)(WorkerListingItemV2);
