import React, { Component } from 'react';
import { Col, notification, Row } from 'antd';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { Paragraph } from 'common-components/typography';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import ActionModal from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { PlanManagerType } from 'utilities/enum-utils';
import { Information } from 'common-components/alerts';

interface IRemovePlanManagerModalProps {
  closeRemovePlanManagerModal: () => void;
  isOpen: boolean;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  planManagerType: string;
  doRemoveCustomerDebtor: typeof dispatch.customersStore.doRemoveCustomerDebtor;
}

class RemovePlanManagerModal extends Component<IRemovePlanManagerModalProps> {
  state = { isLoading: true, title: 'Remove plan manager', step: 1 };

  private _removePlanManager = async () => {
    const { doRemoveCustomerDebtor, selectedCustomer, planManagerType } = this.props;
    try {
      this.setState({
        isLoading: true
      });

      await doRemoveCustomerDebtor({
        customerUserId: selectedCustomer.userId,
        debtorType: selectedCustomer.planManager.type,
        debtorId: planManagerType === PlanManagerType.EXTERNAL ? selectedCustomer.planManager.debtorId : 'INTERNAL'
      });

      this.setState({
        isLoading: false,
        title:
          this.props.planManagerType === PlanManagerType.INTERNAL
            ? 'Plan management removed'
            : 'Plan manager details successfully removed',
        step: 2
      });
    } catch (e) {
      notification.error({ message: 'Oops, an error has occurred, please try again.' });
    }
  };

  private _closeModal = () => {
    this.setState({ title: 'Remove plan manager', step: 1, isLoading: true });
    this.props.closeRemovePlanManagerModal();
  };

  private _renderStep1 = () => {
    return this.props.planManagerType === PlanManagerType.INTERNAL ? (
      <div className="anim-slide-left">
        <SpinningLoadingActionModel isOpen={this.state.isLoading} verticalAlignment={'highest'} />
        <Information
          content={
            <>
              <Paragraph weight={'bold'}>
                This customer has active invoices that are being managed in the Plan Management section of GoodHuman.
              </Paragraph>
              <Paragraph>
                All active invoices can continue to be managed, but new invoices won't be uploaded to the plan
                management section of your workspace.
              </Paragraph>
              <Paragraph>All plan management invoices and records for this customer will be retained.</Paragraph>
              <Paragraph>Are you sure you want to continue?</Paragraph>
            </>
          }
        />
        <div className={'mb-small mt-x2-large'}>
          <Row type={'flex'} className={'justify-end'}>
            <Col className="mr-large">
              <GhostButton size="large" onClick={this.props.closeRemovePlanManagerModal}>
                Cancel
              </GhostButton>
            </Col>
            <Col>
              <PrimaryButton size="large" color="red" onClick={this._removePlanManager}>
                Remove
              </PrimaryButton>
            </Col>
          </Row>
        </div>
      </div>
    ) : (
      <div className="anim-slide-left">
        <SpinningLoadingActionModel isOpen={this.state.isLoading} verticalAlignment={'highest'} />
        <Paragraph>Are you sure you want to remove this plan manager from this customer profile?</Paragraph>
        <div className={'mb-small mt-x2-large'}>
          <Row type={'flex'} className={'justify-end'}>
            <Col className="mr-large">
              <GhostButton size="large" onClick={this.props.closeRemovePlanManagerModal}>
                Cancel
              </GhostButton>
            </Col>
            <Col>
              <PrimaryButton size="large" color="red" onClick={this._removePlanManager}>
                Remove
              </PrimaryButton>
            </Col>
          </Row>
        </div>
      </div>
    );
  };

  componentDidUpdate(prevProps: Readonly<IRemovePlanManagerModalProps>) {
    const { isOpen } = this.props;
    if (isOpen !== prevProps.isOpen) {
      this.setState({
        title:
          this.props.planManagerType === PlanManagerType.EXTERNAL
            ? 'Remove NDIS external plan manager'
            : 'Remove internal plan manager',
        isLoading: false
      });
    }
  }

  render() {
    const { step } = this.state;
    return (
      <div>
        <ActionModal title={this.state.title} isOpen={this.props.isOpen} onClose={this._closeModal} width="medium">
          {step === 1 && this._renderStep1()}
          {step === 2 && (
            <div className="anim-slide-left">
              <Paragraph>
                {this.props.planManagerType === PlanManagerType.INTERNAL
                  ? 'This customer is no longer plan managed internally by your business.'
                  : "You have successfully deleted the details of this customer's selected plan manager."}
              </Paragraph>
              <div className={'mb-small mt-x2-large'}>
                <Row type={'flex'} className={'justify-end'}>
                  <Col>
                    <PrimaryButton size="large" onClick={this._closeModal}>
                      Close
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </ActionModal>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedCustomer: state.customersStore.selectedCustomer
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRemoveCustomerDebtor: dispatch.customersStore.doRemoveCustomerDebtor
});

export default connect(
  mapState,
  mapDispatch
)(RemovePlanManagerModal);
