import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FieldLabel, Paragraph, Text } from 'common-components/typography';
import { Avatar, Form, Input, notification, Checkbox } from 'antd';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';

interface IPermanentlyRemoveUserModalProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  worker: any;
  doRemoveTeamMember: typeof dispatch.teamStore.doRemoveTeamMember;
  timezone: typeof state.companyStore.companyDataLite.timezone;
}

interface IPermanentlyRemoveUserModalState {
  title: any;
  step: number;
  isLoading: boolean;
  removeReason: string;
  isSelectFutureDate: boolean;
  removalDate: any;
}

class PermanentlyRemoveUserModal extends Component<IPermanentlyRemoveUserModalProps, IPermanentlyRemoveUserModalState> {
  state = {
    title: 'Remove',
    step: 1,
    isLoading: false,
    removeReason: '',
    isSelectFutureDate: false,
    removalDate: null
  };

  private _onCloseModal = () => {
    this.setState({
      title: 'Remove',
      step: 1,
      isLoading: false,
      removeReason: '',
      removalDate: null,
      isSelectFutureDate: false
    });
    this.props.onClose();
  };

  private _saveAction = async () => {
    const { form } = this.props;
    const { isSelectFutureDate } = this.state;

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      this.setState({ isLoading: true });

      try {
        const payload = isSelectFutureDate
          ? {
              removeReason: form.getFieldValue('removeReason'),
              supportWorkerId: this.props.worker.supportWorkerId,
              removedOn: moment.tz(form.getFieldValue('removalDate'), this.props.timezone).toISOString()
            }
          : { removeReason: form.getFieldValue('removeReason'), supportWorkerId: this.props.worker.supportWorkerId };
        await this.props.doRemoveTeamMember(payload);
        if (isSelectFutureDate) {
          this.setState({ removalDate: form.getFieldValue('removalDate') });
        }
        this.setState({
          title: !isSelectFutureDate ? 'User successfully removed' : 'User successfully scheduled for removal',
          step: 2,
          removeReason: form.getFieldValue('removeReason')
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
      this.setState({ isLoading: false });
    }
  };

  private _onCheckBoxChange = (e) => {
    this.setState({ isSelectFutureDate: e.target.checked });
  };

  private _onChangeRemovalDate = (date) => {
    const { form } = this.props;
    form.setFieldsValue({ removalDate: date });
  };

  render() {
    const { isOpen, worker, form } = this.props;
    const { getFieldDecorator } = form;
    const { title, isLoading, step, isSelectFutureDate } = this.state;

    return (
      <ActionModal
        title={!_.isEmpty(worker) ? (step === 2 ? title : `${title} ${worker.firstName} ${worker.lastName}`) : ''}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        canCloseOutside={!isLoading}
        width="large"
      >
        {!_.isEmpty(worker) &&
          (step === 1 && (
            <>
              <div className="mv-medium">
                <div>
                  <Paragraph>
                    The user will be removed from all upcoming shifts and sessions that they have been assigned to (That
                    have not been completed) in addition to any rosters they have been assigned to.
                  </Paragraph>
                  <Paragraph>
                    All historical data related to this user will be maintaned and you can re-instate their account at a
                    later date.
                  </Paragraph>
                </div>
                {!worker.removedOn && (
                  <Checkbox className="mb-small" onChange={this._onCheckBoxChange}>
                    Select a future date to remove user
                  </Checkbox>
                )}
                {isSelectFutureDate && (
                  <>
                    <div className="mb-x2-small">
                      <FieldLabel text="REMOVAL DATE" />
                    </div>
                    <Form.Item className="width-full">
                      {getFieldDecorator('removalDate', {
                        initialValue: new Date(
                          moment()
                            .add(1, 'days')
                            .format('YYYY-MM-DD HH:mm')
                        ),
                        rules: [{ required: true, message: 'Please select removal date' }]
                      })(
                        <DatePicker
                          className="gh-datepicker-plan-management rounded "
                          calendarClassName="gh-datepicker-calendar"
                          dateFormat="dd/MM/yyyy"
                          onChange={this._onChangeRemovalDate}
                          selected={form.getFieldValue('removalDate')}
                          minDate={
                            new Date(
                              moment()
                                .add(1, 'days')
                                .format('YYYY-MM-DD HH:mm')
                            )
                          }
                        />
                      )}
                    </Form.Item>
                  </>
                )}
                <div className="mb-x2-small">
                  <FieldLabel text={'REASON'} />
                </div>
                <div>
                  <Form.Item className="width-full">
                    {getFieldDecorator('removeReason', {
                      rules: [{ required: true, min: 5, message: 'Please enter at least 5 characters.' }]
                    })(<Input.TextArea rows={6} placeholder={'Enter reason for removing user....'} />)}
                  </Form.Item>
                </div>
                <ActionModalFooter>
                  <SecondaryButton size="large" disabled={isLoading} className="mr-medium" onClick={this._onCloseModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size="large" loading={isLoading} onClick={this._saveAction}>
                    Save
                  </PrimaryButton>
                </ActionModalFooter>
              </div>
            </>
          ))}

        {!_.isEmpty(worker) &&
          (step === 2 && (
            <>
              <div className="mv-medium">
                <div className="mb-small">
                  <FieldLabel text={'USER'} />
                </div>
                <div className={'flex-row align-center'}>
                  <Avatar shape={'square'} src={worker.attachmentUrl} className={'mr-medium'} />
                  <Text size="x3-large">{worker.firstName + ' ' + worker.lastName}</Text>
                  {!isSelectFutureDate && (
                    <div className={'ml-medium'}>
                      <Text size={'regular'} color={'red-dark'}>
                        REMOVED
                      </Text>
                    </div>
                  )}
                </div>
              </div>
              {isSelectFutureDate && (
                <>
                  <div className="mb-x2-small">
                    <FieldLabel text={'SCHEDULED REMOVAL DATE'} />
                  </div>
                  <Paragraph>{moment(this.state.removalDate).format('DD/MM/YYYY')}</Paragraph>
                </>
              )}

              <div className="mb-x2-small">
                <FieldLabel text={'REASON'} />
              </div>

              <Paragraph>{this.state.removeReason}</Paragraph>

              <ActionModalFooter>
                <PrimaryButton size="large" onClick={this._onCloseModal}>
                  Done
                </PrimaryButton>
              </ActionModalFooter>
            </>
          ))}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  timezone: state.companyStore.companyDataLite ? state.companyStore.companyDataLite.timezone : ''
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRemoveTeamMember: dispatch.teamStore.doRemoveTeamMember
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IPermanentlyRemoveUserModalProps>()(PermanentlyRemoveUserModal));
