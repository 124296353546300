import React from 'react';
import { IShiftSlotActions } from 'views/group-services/session-details/team-members/SessionTeamMembersPanel';
import { ActionMenu, ActionMenuDivider, ActionMenuItem } from 'common-components/action-menu';

import { ShiftSlotStatus } from 'utilities/enum-utils';
import { IShiftSlot } from 'interfaces/shift-interfaces';

export class ShiftSlotActionButtons extends React.Component<
  {
    shiftSlot: IShiftSlot;
    actions: IShiftSlotActions;
  },
  any
> {
  render() {
    const { shiftSlot, actions } = this.props;

    return (
      <ActionMenu>
        {shiftSlot.shiftSlotStatus === ShiftSlotStatus.UNASSIGNED && (
          <>
            <ActionMenuItem text={'Remove shift'} onClick={() => actions.onRemoveShiftSlot({ shiftSlot })} />
            <ActionMenuDivider />
            <ActionMenuItem text={'Edit shift times'} onClick={() => actions.onEditSlotTimes({ shiftSlot })} />
            <ActionMenuItem text={'View activity log'} onClick={() => actions.onViewActivityLog({ shiftSlot })} />
          </>
        )}

        {shiftSlot.shiftSlotStatus === ShiftSlotStatus.PENDING && (
          <>
            <ActionMenuItem text={'Remove team member'} onClick={() => actions.onRemoveTeamMember({ shiftSlot })} />
            <ActionMenuDivider />
            <ActionMenuItem text={'Assign to customer'} onClick={() => actions.onAssignToCustomer({ shiftSlot })} />
            <ActionMenuItem text={'Edit shift times'} onClick={() => actions.onEditSlotTimes({ shiftSlot })} />
            <ActionMenuItem text={'View activity log'} onClick={() => actions.onViewActivityLog({ shiftSlot })} />
          </>
        )}

        {shiftSlot.shiftSlotStatus === ShiftSlotStatus.CONFIRMED && (
          <>
            <ActionMenuItem text={'Start shift'} onClick={() => actions.onStartShift({ shiftSlot })} />
            <ActionMenuDivider />
            <ActionMenuItem text={'Assign to customer'} onClick={() => actions.onAssignToCustomer({ shiftSlot })} />
            <ActionMenuItem text={'Mark as no-show'} onClick={() => actions.onMarkAsNoShow({ shiftSlot })} />
            <ActionMenuItem text={'Remove team member'} onClick={() => actions.onRemoveTeamMember({ shiftSlot })} />
            <ActionMenuDivider />
            <ActionMenuItem text={'Edit shift times'} onClick={() => actions.onEditSlotTimes({ shiftSlot })} />
            <ActionMenuItem text={'View activity log'} onClick={() => actions.onViewActivityLog({ shiftSlot })} />
          </>
        )}

        {shiftSlot.shiftSlotStatus === ShiftSlotStatus.CHECKED_IN && (
          <>
            <ActionMenuItem text={'Finish shift'} onClick={() => actions.onFinishShift({ shiftSlot })} />
            <ActionMenuDivider />
            <ActionMenuItem text={'Assign to customer'} onClick={() => actions.onAssignToCustomer({ shiftSlot })} />
            <ActionMenuItem text={'Edit start time'} onClick={() => actions.onEditStartTime({ shiftSlot })} />
            <ActionMenuItem text={'Mark as no-show'} onClick={() => actions.onMarkAsNoShow({ shiftSlot })} />
            <ActionMenuDivider />
            <ActionMenuItem text={'View activity log'} onClick={() => actions.onViewActivityLog({ shiftSlot })} />
          </>
        )}

        {shiftSlot.shiftSlotStatus === ShiftSlotStatus.CHECKED_OUT && (
          <>
            <ActionMenuItem
              text={'Edit start/finish time'}
              onClick={() => actions.onEditStartFinishTime({ shiftSlot })}
            />
            <ActionMenuItem text={'Mark as no-show'} onClick={() => actions.onMarkAsNoShow({ shiftSlot })} />
            <ActionMenuDivider />
            <ActionMenuItem text={'View activity log'} onClick={() => actions.onViewActivityLog({ shiftSlot })} />
          </>
        )}

        {shiftSlot.shiftSlotStatus === ShiftSlotStatus.APPROVED && (
          <>
            {/* TODO: Finalise the rule for revoke approval and make an API for this button function */}
            {/* <ActionMenuItem text={'Revoke approval'} onClick={() => actions.onRevokeApproval({ shiftSlot })} />
            <ActionMenuDivider /> */}
            <ActionMenuItem text={'View activity log'} onClick={() => actions.onViewActivityLog({ shiftSlot })} />
          </>
        )}

        {shiftSlot.shiftSlotStatus === ShiftSlotStatus.NO_SHOW && (
          <>
            <ActionMenuItem text={'Mark as attended'} onClick={() => actions.onMarkAsAttended({ shiftSlot })} />
            <ActionMenuDivider />
            <ActionMenuItem text={'Edit shift times'} onClick={() => actions.onEditSlotTimes({ shiftSlot })} />
            <ActionMenuItem text={'View activity log'} onClick={() => actions.onViewActivityLog({ shiftSlot })} />
          </>
        )}

        {shiftSlot.shiftSlotStatus === ShiftSlotStatus.DECLINED && (
          <>
            <ActionMenuItem text={'Remove shift'} onClick={() => actions.onRemoveShiftSlot({ shiftSlot })} />
            <ActionMenuDivider />
            <ActionMenuItem text={'Edit shift times'} onClick={() => actions.onEditSlotTimes({ shiftSlot })} />
            <ActionMenuItem text={'View activity log'} onClick={() => actions.onViewActivityLog({ shiftSlot })} />
          </>
        )}

        {/* Additional shift actions */}
        {shiftSlot.shiftSlotStatus === ShiftSlotStatus.REJECTED && (
          <>
            <ActionMenuItem text={'Remove shift'} onClick={() => actions.onRemoveShiftSlot({ shiftSlot })} />
            <ActionMenuDivider />
            <ActionMenuItem text={'Edit shift times'} onClick={() => actions.onEditSlotTimes({ shiftSlot })} />
            <ActionMenuItem text={'View activity log'} onClick={() => actions.onViewActivityLog({ shiftSlot })} />
          </>
        )}

        {shiftSlot.shiftSlotStatus === ShiftSlotStatus.CANCELLED && (
          <>
            <ActionMenuItem text={'View activity log'} onClick={() => actions.onViewActivityLog({ shiftSlot })} />
          </>
        )}

        {shiftSlot.shiftSlotStatus === ShiftSlotStatus.PUBLISHING && (
          <>
            <ActionMenuItem text={'Remove shift'} onClick={() => actions.onRemoveShiftSlot({ shiftSlot })} />
            <ActionMenuDivider />
            <ActionMenuItem text={'Edit shift times'} onClick={() => actions.onEditSlotTimes({ shiftSlot })} />
            <ActionMenuItem text={'View activity log'} onClick={() => actions.onViewActivityLog({ shiftSlot })} />
          </>
        )}

        {shiftSlot.shiftSlotStatus === ShiftSlotStatus.CLAIMED && (
          <>
            <ActionMenuItem text={'Remove shift'} onClick={() => actions.onRemoveShiftSlot({ shiftSlot })} />
            <ActionMenuDivider />
            <ActionMenuItem text={'Edit shift times'} onClick={() => actions.onEditSlotTimes({ shiftSlot })} />
            <ActionMenuItem text={'View activity log'} onClick={() => actions.onViewActivityLog({ shiftSlot })} />
          </>
        )}
      </ActionMenu>
    );
  }
}
