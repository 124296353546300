import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { Paragraph } from 'common-components/typography';
import ActionModal from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';

interface IRevokeClaimModalProps {
  closeModal: () => void;
  revokeClaim: () => void;
  isOpen: boolean;
}

class RevokeClaimModal extends Component<IRevokeClaimModalProps> {
  render() {
    return (
      <div>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this.props.closeModal}
          title={'Revoke claim'}
          showCloseButton={true}
          width={'medium'}
        >
          <div>
            <Paragraph>
              Are you sure you want to revoke the claims for this invoice? This will remove all line items that have
              been sent to payments form the payments section of plan management.
            </Paragraph>
            <Paragraph>Claimed, waived and Rejected line items will not be affected.</Paragraph>
            <div className={'mb-small mt-x2-large'}>
              <Row type={'flex'} className={'justify-end'}>
                <Col className="mr-large">
                  <GhostButton size="large" onClick={this.props.closeModal}>
                    Cancel
                  </GhostButton>
                </Col>
                <Col>
                  <PrimaryButton size="large" onClick={this.props.revokeClaim}>
                    Revoke claim
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </div>
        </ActionModal>
      </div>
    );
  }
}

export default RevokeClaimModal;
