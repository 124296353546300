import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from '../../../../common-components/modal/ActionModal';
import { Paragraph, Text } from '../../../../common-components/typography';
import { GhostButton, PrimaryButton } from '../../../../common-components/buttons';
import { notification } from 'antd';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../stores/rematch/root-store';
import { PaymentSourceType, PaymentStatus, TransportAttendanceType } from '../../../../utilities/enum-utils';
import BillingLineItemV2 from 'views/billings/components/BillingLineItemV2';
import { IBillingLineItem } from 'interfaces/booking-interfaces';
import _ from 'lodash';

interface IAddEditGroupBookingLineItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentBookingId: string;
  doUpdateGroupBookingSingleBillingItem: typeof dispatch.groupBookingsStore.doUpdateGroupBookingSingleBillingItem;
  doCreateGroupBookingBillingItem: typeof dispatch.groupBookingsStore.doCreateGroupBookingBillingItem;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
  editingLineItem: IBillingLineItem;
  editBookingType: 'before' | 'after' | string;
}

interface IAddEditGroupBookingLineItemModalState {
  step: number;
  title: string;
  canManuallyClose: boolean;
  newBillingLineItem: IBillingLineItem;
  displayLineItemError: boolean;
  isLoading: boolean;
}

class AddEditGroupBookingLineItemModal extends Component<
  IAddEditGroupBookingLineItemModalProps,
  IAddEditGroupBookingLineItemModalState
> {
  state = {
    step: 1,
    title: 'Add line item',
    canManuallyClose: true,
    newBillingLineItem: null,
    displayLineItemError: false,
    isLoading: false
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, canManuallyClose: true, title: 'Add line item' }, onClose);
  };

  onSubmit = async () => {
    const { newBillingLineItem } = this.state;
    const {
      editingLineItem,
      doUpdateGroupBookingSingleBillingItem,
      selectedGroupBookingItem,
      doCreateGroupBookingBillingItem,
      currentBookingId
    } = this.props;
    try {
      this.setState({ canManuallyClose: false, isLoading: true });
      if (this._validateLineItemFields()) {
        if (editingLineItem) {
          await doUpdateGroupBookingSingleBillingItem({
            bookingId: currentBookingId,
            ...newBillingLineItem,
            qty: Number(newBillingLineItem.qty),
            billingQty: Number(newBillingLineItem.billingQty),
            claimType: newBillingLineItem.claimType === 'STD' ? '' : newBillingLineItem.claimType,
            billingPrice: Number(newBillingLineItem.billingPrice),
            additionalCost: Number(newBillingLineItem.additionalCost),
            mileagePrice: Number(newBillingLineItem.mileagePrice),
            unitPrice: Number(newBillingLineItem.unitPrice),
            total: Number(newBillingLineItem.total),
            billingTotal: Number(newBillingLineItem.billingTotal),
            travelDistance:
              newBillingLineItem.claimType !== 'TRAN'
                ? null
                : newBillingLineItem.travelDistance
                ? Number(newBillingLineItem.travelDistance)
                : 0
          });
        } else {
          await doCreateGroupBookingBillingItem({
            bookingId: currentBookingId,
            ...newBillingLineItem,
            billingPrice: Number(newBillingLineItem.billingPrice),
            additionalCost: Number(newBillingLineItem.additionalCost),
            mileagePrice: Number(newBillingLineItem.mileagePrice),
            unitPrice: Number(newBillingLineItem.unitPrice),
            total: Number(newBillingLineItem.total),
            billingTotal: Number(newBillingLineItem.billingTotal),
            qty: Number(newBillingLineItem.qty),
            billingQty: Number(newBillingLineItem.billingQty),
            claimType: newBillingLineItem.claimType === 'STD' ? '' : newBillingLineItem.claimType
          });
        }
        this.setState({ isLoading: false });
        this.onCloseModal();
      } else {
        this.setState({ displayLineItemError: true, isLoading: false });
      }
    } catch (e) {
      this.setState({ canManuallyClose: true, isLoading: false });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  private _validateLineItemFields = () => {
    const { newBillingLineItem } = this.state;
    if (
      !newBillingLineItem.supportItemNumber ||
      !newBillingLineItem.claimType ||
      !newBillingLineItem.paymentMethod ||
      !newBillingLineItem.unit ||
      !newBillingLineItem.unitPrice ||
      !newBillingLineItem.qty ||
      !newBillingLineItem.total ||
      !newBillingLineItem.billingTotal
    ) {
      this.setState({ displayLineItemError: true });
      return false;
    }
    return true;
  };

  private _updateNewBillingLineItems = (item) => {
    this.setState({ newBillingLineItem: item, displayLineItemError: false });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      const { selectedGroupBookingItem, editingLineItem } = this.props;
      if (editingLineItem) {
        this.setState({
          newBillingLineItem: {
            ...editingLineItem,
            isEditing: true,
            claimType: editingLineItem.claimType === '' ? 'STD' : editingLineItem.claimType
          },
          displayLineItemError: false
        });
      } else {
        const newBillingLineItem: IBillingLineItem = {
          bookingBillingLineItemId: Date.now().toString(),
          startDateTime: selectedGroupBookingItem.startDateTime,
          endDateTime: selectedGroupBookingItem.endDateTime,
          supportItemNumber: null,
          supportItemNumberArray: [],
          qty: 1,
          unitPrice: 0,
          unit: null,
          description: null,
          total: 0,
          paymentMethod: null,
          ruleType: null,
          isTravel: false,
          travelDistance: 0,
          mileagePrice: 0,
          claimType: '',
          isEditing: true,
          paymentSourceType: PaymentSourceType.NDIS
        };
        this.setState({
          newBillingLineItem,
          displayLineItemError: false,
          isLoading: false
        });
      }
    }
  }

  renderView = () => {
    const { step, newBillingLineItem } = this.state;
    const { selectedGroupBookingItem, editBookingType } = this.props;

    const claimTravelData = {
      travelDistanceDuringBooking:
        editBookingType === 'before'
          ? selectedGroupBookingItem.transportBeforeBooking.travelDistanceDuringBooking
          : editBookingType === 'after'
          ? selectedGroupBookingItem.transportAfterBooking.travelDistanceDuringBooking
          : selectedGroupBookingItem,
      additionalCostDuringBooking:
        editBookingType === 'before'
          ? selectedGroupBookingItem.transportBeforeBooking.additionalCostDuringBooking
          : editBookingType === 'after'
          ? selectedGroupBookingItem.transportAfterBooking.additionalCostDuringBooking
          : selectedGroupBookingItem,
      travelDistanceBeforeBooking:
        editBookingType === 'before'
          ? selectedGroupBookingItem.transportBeforeBooking.travelDistanceDuringBooking
          : editBookingType === 'after'
          ? selectedGroupBookingItem.transportAfterBooking.travelDistanceDuringBooking
          : selectedGroupBookingItem,
      additionalCostBeforeBooking:
        editBookingType === 'before'
          ? selectedGroupBookingItem.transportBeforeBooking.additionalCostDuringBooking
          : editBookingType === 'after'
          ? selectedGroupBookingItem.transportAfterBooking.additionalCostDuringBooking
          : selectedGroupBookingItem,
      transportPriceBeforeBooking: newBillingLineItem ? newBillingLineItem.mileagePrice : 0,
      transportPriceDuringBooking: newBillingLineItem ? newBillingLineItem.mileagePrice : 0
    };
    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <BillingLineItemV2
            index={0}
            isRemoving={false}
            billingLineItem={newBillingLineItem}
            billingSupportItems={selectedGroupBookingItem.serviceBillingLineItems}
            serviceBillingLineItems={selectedGroupBookingItem.serviceBillingLineItems}
            fundedCategories={selectedGroupBookingItem.fundedCategories}
            bookingStatus={selectedGroupBookingItem.status}
            isCancelled={!_.isEmpty(selectedGroupBookingItem.cancellationCode)}
            cancellationCode={selectedGroupBookingItem.cancellationCode}
            cancellationReason={selectedGroupBookingItem.cancellationReason}
            showRemoveReason={true}
            isSaveButtonOutside={true}
            travelDistance={0}
            showActionButtons={
              selectedGroupBookingItem.paymentStatus === PaymentStatus.INITIAL ||
              selectedGroupBookingItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL
            }
            hasABTLineItems
            hasPTNLCLineItems
            showLineItemLabel={true}
            updateBillingItem={this._updateNewBillingLineItems}
            timezoneData={{
              displayTimezone: selectedGroupBookingItem.timezone,
              isTimezoneIndicatorDisplayed: false,
              bookingTimezone: selectedGroupBookingItem.timezone
            }}
            serviceType={'GROUP'}
            claimTravelData={claimTravelData}
            mmmGroup={selectedGroupBookingItem.address.mmmGroup}
            locationState={selectedGroupBookingItem.address.state}
          />
          {this.state.displayLineItemError && (
            <div className="flex justify-end">
              <Text color="red">Please fill in the missing informations</Text>
            </div>
          )}
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal} disabled={this.state.isLoading}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" onClick={this.onSubmit} disabled={this.state.isLoading}>
              Save
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  render() {
    let { isOpen } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="x3-large"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this.renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateGroupBookingSingleBillingItem: dispatch.groupBookingsStore.doUpdateGroupBookingSingleBillingItem,
  doCreateGroupBookingBillingItem: dispatch.groupBookingsStore.doCreateGroupBookingBillingItem
});

export default connect(
  mapState,
  mapDispatch
)(AddEditGroupBookingLineItemModal);
