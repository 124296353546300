import React, { PureComponent } from 'react';
import { Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';

import _ from 'lodash';
import { BookingActionsHandler } from './action-sheets/BookingActionsHandler';

interface IBottomActionSheetProps {
  selectedBookings?: any;
  onDeselect?: any;
  refreshBookingListings: any;
  openBulkRemoveTeamMemberModal: (bookingList, status: string) => void;
  openCancelBookingModal: (bookingList, status: string) => void;
  openBulkApproveWarningModal: (missingPaymentMethodBookings, bookingList) => void;
}

class BottomActionSheet extends PureComponent<IBottomActionSheetProps> {
  state = { open: true };

  _onDeselect = () => {
    this.setState({ open: false });
  };

  _onHideSheet = () => {
    this.setState({ open: false });
  };

  _animationEnd = () => {
    if (!this.state.open) {
      this.props.onDeselect();
    }
  };

  render() {
    const { selectedBookings, refreshBookingListings } = this.props;
    const animClass = this.state.open ? 'action-anim-slide-in' : 'action-anim-slide-out';

    const selectedBookingsList = _.filter(selectedBookings, (b) => b.selected === true);

    const itemCount =
      !_.isEmpty(selectedBookingsList) && _.filter(selectedBookingsList, (b) => b.selected === true).length;

    const bookingFullStatusList = _.map(selectedBookingsList, (booking) => {
      // For booking with a requires_approval, the payment status will be added with a __ to the status to determine what actions can be perform for them depending on the payment status.
      // This structure is used in MultiActionHandler, SingleActionHandler and actions.ts
      return {
        fullStatus:
          booking.paymentStatus === 'REQUIRES_APPROVAL'
            ? booking.status + '__' + booking.paymentStatus
            : booking.status,
        list: [booking.status].push(booking.paymentStatus)
      };
    });

    const finalStatusList = _.chain(bookingFullStatusList)
      .countBy('fullStatus')
      .value();

    return (
      <div
        className={animClass}
        // style={{ bottom: 0, position: 'fixed', zIndex: 20, width: 'calc(100% - 300px)' }}
        style={{ bottom: 0, position: 'sticky', zIndex: 20, width: 'inherit' }}
        onAnimationEnd={this._animationEnd}
      >
        <div
          className="bg-blue"
          style={{
            width: '12px',
            height: '12px',
            position: 'absolute',
            top: '-6px',
            left: '58px',
            transform: 'rotate(45deg)'
          }}
        />
        <div className="bg-blue pv-medium ph-large flex-row justify-between align-center">
          <div>
            <Text color="white" weight="bold" className="mr-medium">
              {itemCount} item{itemCount > 1 ? 's' : ''} selected
            </Text>
            <HyperlinkButton color="white" className="dim" onClick={this._onDeselect}>
              Deselect all
            </HyperlinkButton>
          </div>
          <div>
            <BookingActionsHandler
              statusList={finalStatusList}
              onHideSheet={this._onHideSheet}
              refreshBookingListing={refreshBookingListings}
              openBulkApproveWarningModal={this.props.openBulkApproveWarningModal}
              openCancelBookingModal={this.props.openCancelBookingModal}
              openBulkRemoveTeamMemberModal={this.props.openBulkRemoveTeamMemberModal}
              bookingsList={selectedBookingsList}
            />
          </div>
        </div>
      </div>
    );
  }
}

export { BottomActionSheet };
