/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Switch, withRouter } from 'react-router-dom';
import { Icon, message, notification, Typography } from 'antd';
import { HyperlinkButton } from './common-components/buttons';
import PubNub from 'pubnub';
import moment from 'moment-timezone';

// Routes
import Routes from './routes';

import firebaseApp from './stores/firebase-app';
import { Card, ProgressBar } from '@blueprintjs/core';

import AppContainer from './layouts/AppContainer';
import EmptyLayout from './layouts/EmptyLayout';

// React hot reloader
import { hot, setConfig } from 'react-hot-loader';
import globalConfig from './variables/global-config';
import rootStore from './stores/rematch/root-store';
import { INotification } from './interfaces/notification-interfaces';
import NotificationUtils from './utilities/notification-utils';
import { IMessage, MessageOrigin } from './interfaces/message-interfaces';
import _ from 'lodash';
import { TeamStatus } from './utilities/enum-utils';
import SessionExpiredModal from './views/sessions/details/SessionExpiredModal';
import IdleTimer from 'react-idle-timer';
import { Paragraph, Text } from './common-components/typography';

// 30 * 60 * 1000 ms
const SESSION_TIME_OUT = 1800000;

const Loading = () => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Card style={{ width: '25%', textAlign: 'center' }} className="shadow-box bordered p-large">
      <div style={{ marginBottom: '16px' }}>
        <Typography.Text style={{ fontSize: '16px' }} className="text-color-secondary">
          Loading...
        </Typography.Text>
      </div>
      <ProgressBar animate={true} />
    </Card>
  </div>
);

class App extends Component<any, any> {
  private pubnub;
  private pubnubListener;
  private storeSubscriber;
  private idleTimer = null;

  state = {
    isSessionExpiredModalOpen: false
  };
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.setAppLoaded(false);
    firebaseApp.attachAuthListener(this._handleAuthStateChange);

    // ! create store listener
    this.storeSubscriber = rootStore.subscribe(() => {
      // ! Get auth state from store
      const authState = rootStore.getState().authStore;
      if (authState && authState.portalUser) {
        // ! Setup PubNub channel if user login
        this._setupPubNubChannels(authState.portalUser);
      }
    });
  }

  componentWillMount() {}

  componentWillUnmount = () => {
    // Get portalUser and if exists remove pubnub listeners
    if (this.props.authStore.portalUser) {
      // Remove pubnub listeners and subscription
      this.pubnub.removeListener(this.pubnubListener);
      this.pubnub.unsubscribeAll();
    }
  };

  _setupPubNubChannels = (portalUser: any) => {
    // Todo: Temporarily hack to check if pubnub is empty, needs to check why authState change twice
    if (!this.pubnub) {
      // setup pubnub subscribe channel
      this.pubnub = new PubNub({
        subscribeKey: globalConfig.pubnubSubscribeKey,
        uuid: portalUser.userId,
        restore: true,
        listenToBrowserNetworkEvents: true
      });

      this.pubnubListener = {
        status: (statusEvent) => {
          if (statusEvent.category === 'PNNetworkUpCategory') {
            this.pubnub.reconnect();
          }
        },
        message: (notificationMessage) => {
          // future, may need to handle different message from different channel
          this._handleNotification(notificationMessage.message);
        }
      };

      this.pubnub.addListener(this.pubnubListener);

      this.pubnub.subscribe({
        channels: [portalUser.userId + '-webpush']
      });
    }

    // unsubscribe store listener
    if (this.storeSubscriber) {
      this.storeSubscriber();
      this.storeSubscriber = null;
    }
  };

  _handleNotification = async (notificationMessage: INotification) => {
    const {
      setDashboardNotificationItems,
      doGetNotificationBadgeCount,
      authStore,
      setCustomerDocumentStatus,
      setWorkerDocumentStatus,
      setBookingDocumentStatus,
      doGetChannelDetail,
      appendSingleMessageToChannel,
      doGetAssignedServices,
      history,
      setNewPlanManagementInvoiceDocumentRoute
    } = this.props;

    doGetNotificationBadgeCount();

    // Ignore receive message if different service provider id
    if (notificationMessage.serviceProviderId === authStore.portalUser.serviceProviderId) {
      if (notificationMessage.actionType === 'ACTION') {
        //append notification to dashboard notification list
        setDashboardNotificationItems([notificationMessage]);
      }

      //TODO: append notification to notification listing ?
      if (notificationMessage.notificationType === 'DocumentStatusUpdate') {
        notificationMessage.data.customerUserId
          ? setCustomerDocumentStatus({
              documentId: notificationMessage.data.documentId,
              status: notificationMessage.data.status,
              documentUrl: notificationMessage.data.documentUrl,
              uniqueId: notificationMessage.data.uniqueId
            })
          : notificationMessage.data.supportWorkerId
          ? setWorkerDocumentStatus({
              documentId: notificationMessage.data.documentId,
              status: notificationMessage.data.status,
              documentUrl: notificationMessage.data.documentUrl,
              uniqueId: notificationMessage.data.uniqueId
            })
          : notificationMessage.data.bookingId
          ? setBookingDocumentStatus({
              documentId: notificationMessage.data.documentId,
              status: notificationMessage.data.status,
              documentUrl: notificationMessage.data.documentUrl,
              uniqueId: notificationMessage.data.uniqueId
            })
          : setNewPlanManagementInvoiceDocumentRoute({
              planManagementInvoiceId: notificationMessage.data.planManagementInvoiceId,
              documentUrl: notificationMessage.data.documentUrl,
              uniqueId: notificationMessage.data.uniqueId,
              documentId: notificationMessage.data.documentId,
              status: notificationMessage.data.status
            });
      }

      // Added by Jir : Handle incoming notifications for messaging
      if (notificationMessage.notificationType === 'messaging') {
        // -=== • Messaging notification • ===-
        let parsedMessage: IMessage = notificationMessage.data && notificationMessage.data.data;
        // Do we need special handling if the current screen is messaging...?
        // we need to fetch services unread count as well.
        doGetAssignedServices();

        doGetChannelDetail({
          messageChannelId: parsedMessage.messageChannelId
        });

        appendSingleMessageToChannel({
          message: parsedMessage,
          messageChannelId: parsedMessage.messageChannelId
        });

        // Do we need to specially handle if current screen = messaging...?
        const currentLocation = this.props.location.pathname;

        notification.open({
          key: notificationMessage.notificationId,
          message: (
            <div>
              <Text weight="bold" className="select-none">
                Incoming message
              </Text>
            </div>
          ),
          description: (
            <div>
              <Text size="small" color={'secondary'} weight={'bold'} className="select-none">
                # {parsedMessage.serviceName}
              </Text>
              <br />
              <Paragraph
                size="regular"
                ellipsis={{ rows: 2 }}
                style={{ width: '100%', whiteSpace: 'pre-wrap' }}
                className="select-none"
              >
                {parsedMessage.content.text}
              </Paragraph>
              <div className="flex-row">
                <HyperlinkButton
                  onClick={() => {
                    notification.close(notificationMessage.notificationId);

                    this.props.setIncomingMessageNotification({
                      messageChannelId: parsedMessage.messageChannelId,
                      messageId: parsedMessage.messageId
                    });

                    if (currentLocation !== '/messaging') {
                      history.push({
                        pathname: '/messaging',
                        state: {
                          messageChannelId: parsedMessage.messageChannelId,
                          origin: MessageOrigin.Notification
                        }
                      });
                    }
                  }}
                  className="select-none"
                >
                  View full message
                </HyperlinkButton>
              </div>
            </div>
          ),
          icon: <Icon type="message" className="pt-small text-color-blue-dark text-size-x2-large" />,
          duration: 5
        });
      } else {
        // -=== • Other notifications • ===-

        doGetNotificationBadgeCount();

        //TODO: append notification to notification listing ?
        if (document.hidden) {
          // when user not focus on browser's tab
          if (Notification.permission === 'granted') {
            this._createBrowserNotification(notificationMessage);
          } else if (Notification.permission !== 'denied') {
            Notification.requestPermission(function(permission) {
              if (permission === 'granted') {
                this._createBrowserNotification(notificationMessage);
              }
            });
          }
        } else {
          notification.open({
            key: notificationMessage.notificationId,
            message: (
              <Text weight="bold" size="x2-large">
                {notificationMessage.title}
              </Text>
            ),
            description: (
              <div>
                <Text size="large">
                  {/* Notification Text here */}
                  {notificationMessage.body}
                </Text>
                <br />

                <div className="text-align-right mt-medium">
                  {notificationMessage.notificationType === 'DocumentStatusUpdate' ? (
                    notificationMessage.data.supportWorkerId ? (
                      <HyperlinkButton fontSize="x-large">Go to the documents</HyperlinkButton>
                    ) : (
                      <HyperlinkButton fontSize="x-large">Go to the documents</HyperlinkButton>
                    )
                  ) : notificationMessage.data.numberOfSession ? (
                    <HyperlinkButton fontSize="x-large">Go to Session listing</HyperlinkButton>
                  ) : notificationMessage.data.serviceDateTimeId ? (
                    <HyperlinkButton fontSize="x-large">Go to Session</HyperlinkButton>
                  ) : (
                    <HyperlinkButton fontSize="x-large">Go to Booking</HyperlinkButton>
                  )}
                </div>
              </div>
            ),
            onClick: () => {
              NotificationUtils.Navigate(this.props.history, notificationMessage, false);
              notification.close(notificationMessage.notificationId);
              if (notificationMessage.actionType === 'ACTION') {
                this.props.doDimissNotification({ notificationId: notificationMessage.notificationId });
              }
            },
            duration: 5
          });
        }
      }
    }
  };

  _createBrowserNotification = (receivedNotification: INotification) => {
    const browserNotification = new Notification(receivedNotification.title);
    browserNotification.onclick = () => {
      this.props.doDimissNotification({ notificationId: receivedNotification.notificationId });
      NotificationUtils.Navigate(this.props.history, receivedNotification, false);
      window.focus();
    };
  };

  _onClickBadge = () => {
    const { history, doResetNotificationBadgeCount } = this.props;
    doResetNotificationBadgeCount();
    history.push('/notifications');
  };

  _onClickMessage = () => {
    const { history } = this.props;
    history.push('/messaging');
  };

  private _checkSessionTimeOut = (): boolean => {
    return localStorage.getItem('isSessionTimeOut') === 'true';
  };

  // Handles the Auth state changes. This is triggered whenever someone logs in / out.
  _handleAuthStateChange = async (state) => {
    const {
      doFetchPortalUserList,
      setCurrentPortalUser,
      doSignOutUsers,
      doSignInUsingToken,
      setIsUserBlocked,
      authStore,
      setIsSignOut,
      setIsForgetPassword
    } = this.props;

    this._checkSessionTimeOut();

    const { pathname } = this.props.history.location;

    if (state != null) {
      // firebase authenticated
      if (!this._checkSessionTimeOut()) {
        try {
          const result = await doFetchPortalUserList();

          if (_.isEmpty(result)) {
            // not authenticated
            message.error(
              'You are not authorized to login to any service provider. Please contact your administrator.'
            );

            doSignOutUsers();
            this.props.history.push('/login');
          } else {
            const portalUserResult = result[0];
            if (portalUserResult.status !== TeamStatus.ENABLED) {
              setIsUserBlocked(true);
              setIsSignOut(false);
              doSignOutUsers();
            } else {
              // TODO This doesn't look very safe.
              setCurrentPortalUser(result[0]);
              setIsUserBlocked(false);
              setIsForgetPassword(false);

              // Update portal user's local timezone if autodetect is true and the timezone is different than the one saved
              const displayTimezone = moment.tz.guess();
              if (
                displayTimezone !== result[0].timezone &&
                result[0].displayTimezoneSetting &&
                result[0].displayTimezoneSetting.autoDetect
              ) {
                await this.props.doUpdatePortalUserTimezone({ timezone: displayTimezone });
              }

              message.success(`Sign in successful for ${result[0].email}.`, 2);
              // Add here to auto redirect to dashboard.
              if (pathname === '/' || pathname === '/login') {
                this.props.history.push('/dashboard');
              }
            }
          }
        } catch (e) {
          // not authenticated
          message.error('You are not authorized to login to any service provider. Please contact your administrator.');
          setIsForgetPassword(false);
          await doSignOutUsers();
          window.location.href = `${globalConfig.loginUrl}`;
        }
      } else {
        setIsForgetPassword(false);
        await doSignOutUsers();
        window.location.href = `${globalConfig.loginUrl}`;
      }
    } else {
      // not authenticated
      this.props.setPortalUserList(null);
      this.props.setCurrentPortalUser(null);
      const { location } = this.props;
      const data = new URLSearchParams(location.search);
      const loginToken = data.get('token');
      if (loginToken) {
        setIsForgetPassword(false);
        try {
          localStorage.setItem('isSessionTimeOut', 'false');
          await doSignInUsingToken({ token: loginToken });

          const result = await doFetchPortalUserList();
          if (_.isEmpty(result)) {
            // not authenticated
            message.error(
              'You are not authorized to login to any service provider. Please contact your administrator.'
            );
            doSignOutUsers();
          } else {
            const portalUserResult = result[0];
            if (portalUserResult.status !== TeamStatus.ENABLED) {
              setIsUserBlocked(true);
              setIsSignOut(false);
              doSignOutUsers();
            } else {
              // TODO This doesn't look very safe.
              setCurrentPortalUser(result[0]);
              setIsUserBlocked(false);

              // Update portal user's local timezone if autodetect is true and the timezone is different than the one saved
              const displayTimezone = moment.tz.guess();
              if (
                displayTimezone !== result[0].timezone &&
                result[0].displayTimezoneSetting &&
                result[0].displayTimezoneSetting.autoDetect
              ) {
                await this.props.doUpdatePortalUserTimezone({ timezone: displayTimezone });
              }

              // Add here to auto redirect to dashboard.
              if (pathname === '/') {
                this.props.history.push('/dashboard');
              } else {
                // Add here to auto redirect to path name.
                this.props.history.push(pathname);
              }
            }
          }
        } catch (error) {
          window.location.href = `${globalConfig.loginUrl + pathname}`;
        }
      } else {
        if (authStore.isSignOut === true) {
          if (authStore.isForgetPassword === true) {
            window.location.href = `${globalConfig.loginUrl}&action=reset`;
          } else {
            window.location.href = `${globalConfig.loginUrl}`;
          }
        } else {
          window.location.href = `${globalConfig.loginUrl + pathname}${
            authStore.isUserBlocked === true ? '&isUserBlocked=true' : ''
          }`;
        }
      }
    }
    this.props.setAppLoaded(true);
  };

  setRef = (ref) => {
    this.idleTimer = ref;
  };

  private _closeSessionExpiredModal = () => {
    this.setState({ isSessionExpiredModalOpen: false });
    localStorage.setItem('isSessionTimeOut', 'false');
  };

  private _handleOnAction = (event) => {
    localStorage.setItem('lastActive', this.idleTimer.getLastActiveTime());
  };

  private _handleOnIdle = (event) => {
    this.setState({ isSessionExpiredModalOpen: true });
    localStorage.setItem('isSessionTimeOut', 'true');
  };

  render() {
    const { authStore, badgeCount, messagingBadgeCount } = this.props;

    // Waiting for initialization
    if (!authStore.isAppLoaded) {
      return <Loading />;
    } else {
      const { portalUser } = authStore;

      if (!portalUser) {
        // Default view
        return (
          <Switch>
            <Routes isAuthenticated={false} />
          </Switch>
        );
      } else {
        const { pathname } = this.props.history.location;
        switch (pathname) {
          case '/pdf':
          case '/redirect': {
            return (
              <EmptyLayout>
                <Switch>
                  <Routes isAuthenticated={true} />
                </Switch>
              </EmptyLayout>
            );
          }
          case '/register':
          case '/login':
          case '/reset': {
            return (
              <Switch>
                <Routes isAuthenticated={true} />
              </Switch>
            );
          }
          default: {
            return (
              <AppContainer
                portalUser={portalUser}
                badgeCount={badgeCount}
                messagingBadgeCount={messagingBadgeCount}
                onClickBadge={this._onClickBadge}
                onClickMessage={this._onClickMessage}
                companyDataLite={this.props.companyDataLite}
                doFetchCompanyLite={this.props.doFetchCompanyLite}
              >
                <IdleTimer
                  ref={this.setRef}
                  timeout={SESSION_TIME_OUT}
                  // onActive={this.handleOnActive}
                  onIdle={this._handleOnIdle}
                  onAction={this._handleOnAction}
                  throttle={2000}
                  // stopOnIdle={this.state.stopOnIdle}
                />
                <SessionExpiredModal
                  isSessionExpiredOpen={this.state.isSessionExpiredModalOpen}
                  closeSessionExpired={this._closeSessionExpiredModal}
                  history={this.props.history}
                />
                <Switch>
                  <Routes isAuthenticated={true} />
                </Switch>
              </AppContainer>
            );
          }
        }
      }
    }
  }
}

const mapState = (state) => ({
  authStore: state.authStore,
  companyDataLite: state.companyStore.companyDataLite,

  // notifications badge count
  badgeCount: state.notificationsStore.badgeCount,

  // messages badge count
  messagingBadgeCount: state.notificationsStore.messagingBadgeCount
});

const mapDispatch = (dispatch) => ({
  setAppLoaded: dispatch.authStore.setAppLoaded,
  doSignOutUsers: dispatch.authStore.doSignOutUsers,
  setCurrentPortalUser: dispatch.authStore.setCurrentPortalUser,
  doSignInUsingToken: dispatch.authStore.doSignInUsingToken,
  setPortalUserList: dispatch.authStore.setPortalUserList,
  doFetchPortalUserList: dispatch.authStore.doFetchPortalUserList,
  doUpdatePortalUserTimezone: dispatch.authStore.doUpdatePortalUserTimezone,
  setDashboardNotificationItems: dispatch.notificationsStore.setDashboardNotificationItems,
  setCustomerDocumentStatus: dispatch.customersStore.setCustomerDocumentStatus,
  setWorkerDocumentStatus: dispatch.teamStore.setWorkerDocumentStatus,
  setBookingDocumentStatus: dispatch.bookingsStore.setBookingDocumentStatus,
  doDimissNotification: dispatch.notificationsStore.doDimissNotification,
  doGetNotificationBadgeCount: dispatch.notificationsStore.doGetNotificationBadgeCount,
  doResetNotificationBadgeCount: dispatch.notificationsStore.doResetNotificationBadgeCount,
  setIsUserBlocked: dispatch.authStore.setIsUserBlocked,
  setIsSignOut: dispatch.authStore.setIsSignOut,
  setIsForgetPassword: dispatch.authStore.setIsForgetPassword,
  setNewPlanManagementInvoiceDocumentRoute: dispatch.planManagementStore.setNewPlanManagementInvoiceDocumentRoute,
  // for messaging
  doGetChannelDetail: dispatch.channelsStore.doGetChannelDetail,
  appendSingleMessageToChannel: dispatch.messagesStore.appendSingleMessageToChannel,
  doGetAssignedServices: dispatch.channelsStore.doGetAssignedServices,
  setIncomingMessageNotification: dispatch.messagesStore.setIncomingMessageNotification,
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite
});

const connectDispatch = connect(mapState, mapDispatch);

// @ts-ignore
setConfig({ showReactDomPatchNotification: false });

export default hot(module)(compose(connectDispatch, withRouter)(App));
