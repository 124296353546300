import React, { Component } from 'react';
import { Route } from 'react-router-dom';

// Dashboard
import DashboardView from 'views/dashboard/DashboardLandingView';
// Services
import ServiceLandingView from 'views/services/ServiceLandingView';
import ServiceDetailsView from 'views/services/details/ServiceDetailsView';
import GroupServiceDetailsView from 'views/group-services/service-details/GroupServiceDetailsView';
import GroupServiceSessionView from 'views/group-services/session-details/GroupServiceSessionView';
// Team
import TeamListingViewV2 from 'views/team/TeamListingViewV2';
import TeamDetailsViewV2 from 'views/team/details/TeamDetailsViewV2';
import TeamAllViewsV2 from 'views/team/all-views/TeamAllViewsV2';
// Bookings
import BookingListingV3 from '../views/bookings/BookingListingV3View';
import BookingAllViews from '../views/bookings/BookingViews/BookingAllViews';

// Customers
import CustomerLandingView from 'views/customers/CustomerLandingView';
// Stories
import StoriesLandingView from 'views/stories/StoriesLandingView';
// Profile
import MyProfileView from 'views/account/MyProfileView';

// Notifications
import NotificationsLandingView from 'views/notifications/NotificationsLandingView';
// Billing review
import BookingApproveView from 'views/bookings/BookingApproveView';
import PDFPreviewView from '../views/pdf-viewer/PDFPreviewView';

// import BookingActualView from '../views/wip/design-wip/booking/BookingActualView';
import BookingDetailsViewV2 from '../views/bookings/details/BookingDetailsViewV2';

// Previous Booking Listings
// import BookingListingV2View from '../views/wip/design-wip/booking/BookingListingV2View';
import PaymentListingV2View from '../views/billings/PaymentListingV2View';
import PayListBatchDetailsView from '../views/billings/BatchDetailView';

//Customer Views
import CustomerAllViews from '../views/customers/CustomerViews/CustomerAllViews';

import CustomerDetailsPanel from '../views/customers/details/CustomerDetailsPanel';
// import PaymentListingV2View from '../views/billings/details/PaymentListingV2View';
// Invite Users
import InviteUsersLandingView from 'views/invite-users/InviteUsersLandingView';
import PendingInvitationListingView from 'views/invite-users/listing/PendingInvitationListingView';

// -=== Messaging ===-
import MessagingLandingView from 'views/messaging/MessagingLandingView';
import RedirectToView from 'views/redirect-page/RedirectToView';

// -=== Account management ===-
import AccountLandingView from 'views/account-management/AccountLandingView';
import AccountAddMemberView from 'views/account-management/AccountAddMemberView';
import ServiceDepartmentsView from 'views/account-management/ServiceDepartmentsView';
import TeamManagementView from 'views/account-management/TeamManagementView';
import TestPermissionView from 'views/wip/TestPermissionView';
import CompanyDetailsView from 'views/account-management/CompanyDetailsView';
import FeatureConfigurationView from 'views/account-management/FeatureConfigurationView';
import FundingAndPaymentView from 'views/account-management/FundingAndPaymentView';
import AccountExportDataView from 'views/account-management/AccountExportDataView';
import DebtorManagementView from 'views/account-management/DebtorManagementView';
import DebtorDetailsView from 'views/account-management/DebtorDetailsView';

// -=== Access denied ===-
import AccessDeniedView from 'views/exceptions/AccessDeniedView';

// -=== Plan Management ===-
import PlanManagementView from 'views/plan-management/listing/PlanManagementView';
import PlanManagementSupplierDetailView from 'views/plan-management/listing/PlanManagementSupplierDetailView';
import PlanManagementInvoiceDetails from 'views/plan-management/invoices/PlanManagementInvoiceDetails';
import PlanPaymentView from 'views/plan-management/payments/PlanPaymentView';
import PlanManagementBatchDetail from 'views/plan-management/payments/components/PlanManagementBatchDetail';
import PlanManagementCustomerDetailView from 'views/plan-management/listing/PlanManagementCustomerDetailView';
import ServiceAgreementPaymentMethodsView from 'views/account-management/ServiceAgreementPaymentMethodsView';
import ServiceAgreementTemplatesView from 'views/account-management/ServiceAgreementTemplatesView';
import GroupSessionsListView from 'views/group-services/session-listings/GroupSessionsListingView';

// -=== Roster view ===-
import RosterLandingView from 'views/bookings/roster-view/RosterLandingView';
import TimeBasedAlertsConfigureView from 'views/account-management/TimeBasedAlertsConfigureView';

// Authorized Routes
class AuthorizedRoutes extends Component<{ isAuthenticated?: boolean }, any> {
  render() {
    // const { isAuthenticated } = this.props;

    return (
      <>
        {/* Dashboard */}
        <Route path={'/dashboard'} component={DashboardView} />

        {/* Services */}
        <Route path={'/services'} component={ServiceLandingView} />
        <Route exact path={'/service/details/:serviceId'} component={ServiceDetailsView} />
        <Route exact path={'/group-service/details/:serviceId'} component={GroupServiceDetailsView} />
        <Route
          exact
          path={'/group-service/:serviceId/session/details/:serviceDateTimeId'}
          component={GroupServiceSessionView}
        />

        {/* Session listings for group services */}
        <Route path="/group-service/sessions" component={GroupSessionsListView} />

        {/* Teams */}
        <Route exact path={'/team'} component={TeamListingViewV2} />
        <Route exact path={'/team/details/:workerId'} component={TeamDetailsViewV2} />
        <Route exact path={'/team/all-views'} component={TeamAllViewsV2} />

        {/* Bookings */}
        <Route exact path={'/bookings-approve'} component={BookingApproveView} />
        {/*<Route exact path={'/bookings'} component={BookingLandingView} />*/}
        {/*<Route exact path={'/bookings'} component={BookingListingV2View} />*/}
        {/*<Route exact path={'/bookings'} component={BookingListingViewV2} />*/}
        <Route exact path={'/bookings'} component={BookingListingV3} />

        <Route path={'/bookings/all-views'} component={BookingAllViews} />

        {/*<Route exact path={'/bookings/details/:bookingId'} component={BookingActualView} />*/}
        <Route exact path={'/bookings/details/:bookingId'} component={BookingDetailsViewV2} />

        <Route exact path={'/batch/details/:batchId'} component={PayListBatchDetailsView} />

        <Route path={'/bookings/calendar'} component={RosterLandingView} />

        {/* Customers */}
        <Route exact path={'/customers'} component={CustomerLandingView} />

        {/* Customer Views */}
        <Route exact path={'/customers/all-views'} component={CustomerAllViews} />

        {/* Stories */}
        <Route path={'/stories'} component={StoriesLandingView} />

        {/* Notifications */}
        <Route path={'/notifications'} component={NotificationsLandingView} />

        {/* Invite users */}
        <Route path={'/invite-users'} component={InviteUsersLandingView} />
        <Route path={'/pending-invitations'} component={PendingInvitationListingView} />

        {/* Billings */}
        <Route path={'/payments'} component={PaymentListingV2View} />

        {/* Profile */}
        <Route path={'/my-profile'} component={MyProfileView} />
        <Route path={'/company'} component={CompanyDetailsView} />

        <Route path={'/pdf'} component={PDFPreviewView} />

        <Route path={'/redirect'} component={RedirectToView} />

        <Route exact path={'/customer/details/:userId'} component={CustomerDetailsPanel} />

        {/* Messaging */}
        <Route path={'/messaging'} component={MessagingLandingView} />

        {/*   Added by Jir : Account Management */}
        {/* TODO : Change to a better url maybe */}
        <Route path={'/account/landing'} component={AccountLandingView} />

        {/* Account management */}
        <Route path={'/account/add-user'} component={AccountAddMemberView} />
        <Route path={'/account/service-department'} component={ServiceDepartmentsView} />
        <Route path={'/account/team-management'} component={TeamManagementView} />
        <Route path={'/account/funding-and-payment'} component={FundingAndPaymentView} />
        <Route path={'/account/testPermissions'} component={TestPermissionView} />
        <Route exact path={'/account/debtor-management'} component={DebtorManagementView} />
        <Route exact path={'/account/debtor-management/:debtorId'} component={DebtorDetailsView} />
        <Route
          path={'/account/features/service-agreement-templates/:paymentMethod'}
          component={ServiceAgreementTemplatesView}
        />
        <Route
          exact
          path={'/account/features/service-agreement-templates'}
          component={ServiceAgreementPaymentMethodsView}
        />
        <Route
          path={'/account/features/service-time-based-alerts-configure'}
          component={TimeBasedAlertsConfigureView}
        />
        <Route exact path={'/account/features'} component={FeatureConfigurationView} />
        <Route path={'/account/export-data'} component={AccountExportDataView} />

        {/* Plan management */}
        <Route exact path={'/plan-management'} component={PlanManagementView} />
        <Route
          exact
          path={'/plan-management/provider/details/:planManagementSupplierId'}
          component={PlanManagementSupplierDetailView}
        />
        <Route
          exact
          path={'/plan-management/customer/details/:customerUserId'}
          component={PlanManagementCustomerDetailView}
        />
        <Route
          exact
          path={'/plan-management/invoice/details/:planManagementInvoiceId'}
          component={PlanManagementInvoiceDetails}
        />
        <Route path={'/plan-management/payments'} component={PlanPaymentView} />

        {/* Access denied */}
        <Route path={'/access-denied'} component={AccessDeniedView} />

        <Route path={'/plan-management/batches/:batchId'} component={PlanManagementBatchDetail} />
      </>
    );
  }
}

export default AuthorizedRoutes;
