const SESSION_CUSTOMER_AVAILABLE_BULK_ACTIONS = [
  {
    itemLabel: 'COMPLETED',
    actions: null,
    children: [
      {
        itemLabel: 'REQUIRES_APPROVAL',
        actions: [
          {
            actionName: 'APPROVE',
            actionLabel: 'Approve',
            buttonType: 'PRIMARY'
          },
          {
            actionName: 'WAIVE_BOOKING',
            actionLabel: 'Waive',
            buttonType: 'SECONDARY',
            buttonBackgroundColor: 'red-dark'
          }
        ]
      },
      {
        itemLabel: 'SEND_TO_FINANCE',
        actions: [
          {
            actionName: 'REVOKE_APPROVAL',
            actionLabel: 'Revoke approval',
            buttonType: 'PRIMARY'
          }
        ]
      }
    ]
  },
  {
    itemLabel: 'CONFIRMED',
    actions: [
      {
        actionName: 'START_BOOKING',
        actionLabel: 'Start booking',
        buttonType: 'PRIMARY'
      }
    ],
    children: []
  },
  {
    itemLabel: 'INPROGRESS',
    actions: [
      {
        actionName: 'END_BOOKING',
        actionLabel: 'Finish booking',
        buttonType: 'PRIMARY'
      }
    ],
    children: []
  },
  {
    itemLabel: 'CUSTOMER_CANCELLED_WITH_FEE',
    actions: null,
    children: [
      {
        itemLabel: 'REQUIRES_APPROVAL',
        actions: [
          {
            actionName: 'APPROVE',
            actionLabel: 'Approve',
            buttonType: 'PRIMARY'
          },
          {
            actionName: 'WAIVE_BOOKING',
            actionLabel: 'Waive',
            buttonType: 'SECONDARY',
            buttonBackgroundColor: 'red-dark'
          }
        ]
      },
      {
        itemLabel: 'SEND_TO_FINANCE',
        actions: [
          {
            actionName: 'REVOKE_APPROVAL',
            actionLabel: 'Revoke approval',
            buttonType: 'PRIMARY'
          }
        ]
      }
    ]
  }
];

const SESSION_TEAM_MEMBER_AVAILABLE_BULK_ACTIONS = [
  {
    itemLabel: 'UNASSIGNED',
    actions: [
      {
        actionName: 'REMOVE_SLOTS',
        actionLabel: 'Remove slot(s)',
        buttonType: 'SECONDARY'
      }
    ],
    children: []
  },
  {
    itemLabel: 'PENDING',
    actions: [
      {
        actionName: 'CONFIRM',
        actionLabel: 'Mark as confirmed',
        buttonType: 'PRIMARY'
      },
      {
        actionName: 'REMOVE_TEAM_MEMBER',
        actionLabel: 'Remove team member',
        buttonType: 'SECONDARY'
      }
    ],
    children: []
  },
  {
    itemLabel: 'CONFIRMED',
    actions: [
      {
        actionName: 'START_SHIFT',
        actionLabel: 'Start shift',
        buttonType: 'PRIMARY'
      },
      {
        actionName: 'REMOVE_TEAM_MEMBER',
        actionLabel: 'Remove team member',
        buttonType: 'SECONDARY'
      }
    ],
    children: []
  },
  {
    itemLabel: 'CHECKED_IN',
    actions: [
      {
        actionName: 'FINISH_SHIFT',
        actionLabel: 'Finish shift',
        buttonType: 'PRIMARY'
      }
    ],
    children: []
  },
  {
    itemLabel: 'CHECKED_OUT',
    actions: [
      {
        actionName: 'APPROVE',
        actionLabel: 'Approve shift',
        buttonType: 'PRIMARY'
      }
    ],
    children: []
  }
];

const SESSION_AVAILABLE_BULK_ACTIONS = [
  {
    itemLabel: 'SCHEDULED',
    actions: [
      {
        actionName: 'CANCEL',
        actionLabel: 'Cancel',
        buttonType: 'PRIMARY'
      }
    ],
    children: []
  }
];

export {
  SESSION_CUSTOMER_AVAILABLE_BULK_ACTIONS,
  SESSION_TEAM_MEMBER_AVAILABLE_BULK_ACTIONS,
  SESSION_AVAILABLE_BULK_ACTIONS
};
