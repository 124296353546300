import React, { Component } from 'react';
import { Text, Title } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import { Divider, Skeleton } from 'antd';
import _ from 'lodash';
import GroupServicePricingConfigModal from './GroupServicePricingConfigModal';

import { australianFlag } from 'assets/Icons/country-flags';

interface IGroupServicePricingPanelProps {
  serviceId: string;
}

class GroupServicePricingPanel extends Component<IGroupServicePricingPanelProps> {
  state = {
    isOpenConfigModal: false,
    isLoading: false
  };

  private _openConfigModal = () => {
    this.setState({ isOpenConfigModal: true });
  };

  private _closeCreateServiceModal = () => {
    this.setState({ isOpenConfigModal: false });
  };

  render() {
    const { isLoading, isOpenConfigModal } = this.state;
    return (
      <div className="pt-x-large pl-medium width-full pb-x3-large">
        {isLoading ? (
          <Skeleton paragraph={{ rows: 5, width: '100%' }} active={true} className="anim-slide-left" />
        ) : (
          <>
            <GroupServicePricingConfigModal
              isOpen={isOpenConfigModal}
              onClose={this._closeCreateServiceModal}
              serviceId={this.props.serviceId}
            />

            <div className="bg-white mt-large ph-large pt-large pb-x2-large shadow-container rounded-big">
              <div>
                <Text weight="bold">Pricing for this service</Text>
                <br />
                <Text>Configure the payment methods and claims for this service</Text>
                <Divider />
                <div className="p-x-large rounded-big shadow-container bordered flex-column">
                  <Title level={4} className="mb-none">
                    National disability insurance scheme
                  </Title>
                  <div className="flex-row mt-medium align-center">
                    <img
                      src={australianFlag}
                      height={'8px'}
                      alt={'Australian National Flag'}
                      className={'mr-x-small'}
                    />
                    <Text>Australia</Text>
                  </div>
                  <Text className="mt-medium">
                    The National Disability Insurance Scheme is a scheme of the Australian Government that funds costs
                    associated with disability.
                  </Text>
                  <HyperlinkButton className="mt-medium" onClick={this._openConfigModal}>
                    Configure NDIS pricings...
                  </HyperlinkButton>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default GroupServicePricingPanel;
