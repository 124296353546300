/* eslint-disable */
import React, { Component } from 'react';
import { Layout, Row, Col, Typography, Button, Icon } from 'antd';

import { MaterialColors, BlueprintColors } from 'variables/colors';

import styled from 'styled-components';
import { HyperlinkButton, PrimaryButton } from '../common-components/buttons';

// import blackLogo from 'assets/logo'
// import { logoBlack } from 'assets/logo';

const currentYear = new Date().getFullYear();

const { Text } = Typography;
const { Header: AntHeader, Content: AntContent, Footer: AntFooter } = Layout;

// styled components
const FooterHeaderText = styled(Text)`
  font-weight: 800;
`;

const LinkText = styled(Text)`
  color: ${BlueprintColors.BLACK};
  font-weight: 600;
  font-size: 14px;
`;

const Header = styled(AntHeader)`
  background-color: white !important;
  color: black;
`;

const Content = styled(AntContent)`
  background-color: white;
  padding: 24px;
`;

const Footer = styled(AntFooter)`
  background-color: white !important;
`;

// Non Auth Layout
class NotAuthorizedLayout extends Component<any, any> {
  goToLink = (link) => (window.location.href = link);

  render() {
    return (
      <div>
        <Layout style={{ backgroundColor: 'white', minHeight: '100vh', width: '99vw' }}>
          {/* Header */}
          <Header>
            <div className="flex flex-row ph-x-large pt-medium justify-between">
              <div className="flex justify-center" style={{ flex: 1 }}>
                <HyperlinkButton weight="bold" onClick={() => (window.location.href = '/')}>
                  Home
                </HyperlinkButton>
              </div>
              <div className="flex justify-center" style={{ flex: 1 }}>
                {/*<HyperlinkButton weight="bold">Sign In</HyperlinkButton>*/}
              </div>
              <div className="flex justify-center" style={{ flex: 1 }}>
                <PrimaryButton color="black" paddingSize="large">
                  Get the App
                </PrimaryButton>
              </div>
            </div>

            {/*<Row type={'flex'} justify={'end'} gutter={16}>*/}
            {/*  <Col md={3} xs={8}>*/}
            {/*    <a href="#">*/}
            {/*      <LinkText>Home</LinkText>*/}
            {/*    </a>*/}
            {/*  </Col>*/}
            {/*  <Col md={3} xs={8}>*/}
            {/*    <a href="#">*/}
            {/*      <LinkText>Sign In</LinkText>*/}
            {/*    </a>*/}
            {/*  </Col>*/}
            {/*  <Col md={3} xs={8}>*/}
            {/*    <Button*/}
            {/*      type={'ghost'}*/}
            {/*      style={{*/}
            {/*        borderRadius: 64,*/}
            {/*        backgroundColor: BlueprintColors.BLACK,*/}
            {/*        borderColor: BlueprintColors.GRAY1*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <LinkText style={{ color: 'white' }}>Get the App</LinkText>*/}
            {/*    </Button>*/}
            {/*  </Col>*/}
            {/*</Row>*/}
          </Header>

          {/* Content goes here */}
          <Content>{this.props.children}</Content>

          {/* Footer */}
          <Footer>
            <Row gutter={16} justify={'center'} type={'flex'} className="ph-x5-large pb-medium">
              <Col span={8}>
                <div style={{ marginBottom: '8px' }}>
                  <FooterHeaderText>Schedule a conversation</FooterHeaderText>
                </div>
                <div style={{ marginBottom: '8px' }}>
                  <Icon type={'mail'} />
                  <span style={{ marginLeft: '8px' }}>
                    <HyperlinkButton>helllo@goodhuman.me</HyperlinkButton>
                  </span>
                </div>
                {/*<div>*/}
                {/*  <Icon type={'phone'} />*/}
                {/*  <span style={{ marginLeft: '8px' }}>*/}
                {/*    <Text style={{ fontSize: '14px', fontWeight: 700 }}>+61 40000000</Text>*/}
                {/*  </span>*/}
                {/*</div>*/}
              </Col>
              <Col span={8} className="text-align-center">
                <div style={{ marginBottom: '8px' }}>
                  <FooterHeaderText>Legal</FooterHeaderText>
                </div>
                <div>
                  <HyperlinkButton onClick={() => this.goToLink('https://www.goodhuman.me/privacy.html')}>
                    Privacy Policy
                  </HyperlinkButton>
                </div>
                <div>
                  <HyperlinkButton onClick={() => this.goToLink('https://www.goodhuman.me/term-of-use.html')}>
                    Terms of Use
                  </HyperlinkButton>
                </div>
                <br />
                {/*<div>*/}
                {/*  <HyperlinkButton color="green">Disclosure & Licensing</HyperlinkButton>*/}
                {/*</div>*/}
              </Col>
              <Col span={8} className="text-align-right">
                {/*<FooterHeaderText>{currentYear.toString()} © GoodHuman All copyrights reserved</FooterHeaderText>*/}
                <FooterHeaderText>
                  Copyright © {currentYear.toString()} GOODHUMAN INC, All rights reserved.
                </FooterHeaderText>
              </Col>
            </Row>
          </Footer>
        </Layout>
      </div>
    );
  }
}

export default NotAuthorizedLayout;
