import React, { Component } from 'react';
import CenteredLayout from 'layouts/CenteredLayout';

import { Text, Title } from 'common-components/typography';
import PermissionUtils from 'utilities/permission-utils';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import * as H from 'history';

function AccountNavItem({
  title = '',
  isComingSoon = false,
  description = '',
  targetUrl = '',
  onClickItem = (url) => {}
}) {
  return (
    <div
      style={{ width: '25%', paddingLeft: '12px', paddingRight: '12px' }}
      className={`mb-large ${!isComingSoon && 'cursor-pointer'} select-none`}
      onClick={() => !isComingSoon && onClickItem(targetUrl)}
    >
      <div className="bordered rounded p-large" style={{ height: '220px' }}>
        <div className="mb-large">
          <Text size="x2-large" color={isComingSoon ? 'black' : 'blue-action'}>
            {title}
          </Text>
        </div>
        {isComingSoon && (
          <div className={'mb-small'}>
            <Text size="large">COMING SOON</Text>
          </div>
        )}
        <Text size="large">{description}</Text>
      </div>
    </div>
  );
}

interface IAccountLandingViewProps {
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;
  portalUser: typeof state.authStore.portalUser;
  history: H.History;
}

class AccountLandingView extends Component<IAccountLandingViewProps, any> {
  onClickItem = (url) => {
    const { history } = this.props;
    history.push(url);
  };

  componentDidMount() {
    this.props.setSelectedSideNavMenuKeys(['/account/landing']);
  }

  render() {
    const { portalUser } = this.props;
    return (
      <CenteredLayout backgroundColor="white" bordered={true}>
        <div className="mb-medium">
          <Title level={3} className="mv-none" lineHeight={150}>
            Account management
          </Title>
        </div>

        <div>
          <div className="flex-row justify-center mb-x-large">
            <div className="p-large bordered rounded select-none" style={{ maxWidth: '678px' }}>
              <div className="pb-medium">
                <Text size={'x2-large'} color={'black'}>
                  GoodHuman Subscription
                </Text>
              </div>

              <div className="pb-small">
                <Text color={'green-status'} size="x3-large">
                  ACTIVE
                </Text>
              </div>

              <Text size="large">
                Manage your GoodHuman subscription and invoices. Team members that login at least once in a given month
                are considered billable.
              </Text>
            </div>
          </div>

          {/* Items container */}
          <div className="flex-row flex-wrap">
            {PermissionUtils.validatePermission('ViewCompanyDetail', portalUser.permissions.permissionRoles) && (
              <AccountNavItem
                title="Company Details"
                description={'Essential company details including the Primary Owner and trading information.'}
                isComingSoon={false}
                onClickItem={this.onClickItem}
                targetUrl={'/company'}
              />
            )}

            <AccountNavItem
              title="Branding"
              description={'Branding assets used in the portal and in generated communications.'}
              isComingSoon={true}
              onClickItem={this.onClickItem}
              targetUrl={'/account/branding'}
            />

            <AccountNavItem
              title="Funding and Payment"
              description={'Funding sources and customer payment options.'}
              isComingSoon={false}
              onClickItem={this.onClickItem}
              targetUrl={'/account/funding-and-payment'}
            />

            <AccountNavItem
              title="Feature configuration"
              description={'GoodHuman functionality and options.'}
              isComingSoon={false}
              onClickItem={this.onClickItem}
              targetUrl={'/account/features'}
            />
            {PermissionUtils.validatePermission('ViewTeamManagement', portalUser.permissions.permissionRoles) && (
              <AccountNavItem
                title="Team Management"
                description={'Add, deactivate and assign permissions to people in your business.'}
                isComingSoon={false}
                onClickItem={this.onClickItem}
                targetUrl={'/account/team-management'}
              />
            )}
            {PermissionUtils.validatePermission(
              'ViewServiceDepartmentListing',
              portalUser.permissions.permissionRoles
            ) && (
              <AccountNavItem
                title="Service Departments"
                description={'Create and configure your businesses service departments.'}
                isComingSoon={false}
                onClickItem={this.onClickItem}
                targetUrl={'/account/service-department'}
              />
            )}
            {PermissionUtils.validatePermission('ExportPlatformData', portalUser.permissions.permissionRoles) && (
              <AccountNavItem
                title="Export Data"
                description={'Access your company data on your terms.'}
                isComingSoon={false}
                onClickItem={this.onClickItem}
                targetUrl={'/account/export-data'}
              />
            )}
            {PermissionUtils.validatePermission('ViewDebtorManagement', portalUser.permissions.permissionRoles) && (
              <AccountNavItem
                title="Debtor Management"
                description={'Manage customer debtors'}
                isComingSoon={false}
                onClickItem={this.onClickItem}
                targetUrl={'/account/debtor-management'}
              />
            )}
          </div>
        </div>
      </CenteredLayout>
    );
  }
}

const mapState = (state: IRootState) => ({ portalUser: state.authStore.portalUser });

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys
});

export default connect(
  mapState,
  mapDispatch
)(AccountLandingView);
