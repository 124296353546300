import React, { Component } from 'react';

import { Title, Paragraph, Text, SubTitle } from 'common-components/typography';
import { Col, Icon, Row } from 'antd';
import { Collapse } from '@blueprintjs/core';

function TypeScaleRow(props) {
  const { title, size, family, comments } = props;

  return (
    <Row className="bordered-bottom pv-medium" type="flex" align="middle">
      <Col span={10}>{title}</Col>
      <Col span={3}>
        <Text>{size}</Text>
      </Col>
      <Col span={5}>
        <Text>{family}</Text>
      </Col>
      <Col span={6}>{comments}</Col>
    </Row>
  );
}

function TypeScaleSection() {
  return (
    <div>
      <Title level={3}>Type Scales</Title>

      <div className="mb-x-large">
        <TypeScaleRow
          title={
            <Title level={1} className="mv-none">
              Heading One
            </Title>
          }
          size={'40px'}
          family={'Poppins Bold (600)'}
          comments={
            <Text>
              <code>{'<Title level={1} />'}</code>
            </Text>
          }
        />
        <TypeScaleRow
          title={
            <Title level={2} className="mv-none">
              Heading Two
            </Title>
          }
          size={'28px'}
          family={'Poppins Bold (600)'}
          comments={
            <Text>
              <code>{'<Title level={2} />'}</code>
            </Text>
          }
        />
        <TypeScaleRow
          title={
            <Title level={3} className="mv-none">
              Heading Three
            </Title>
          }
          size={'24px'}
          family={'Lato Bold (600)'}
          comments={
            <Text>
              <code>{'<Title level={3} />'}</code>
            </Text>
          }
        />
        <TypeScaleRow
          title={
            <Title level={4} className="mv-none">
              Heading Four
            </Title>
          }
          size={'19px'}
          family={'Lato Bold (600)'}
          comments={
            <Text>
              <code>{'<Title level={4} />'}</code>
            </Text>
          }
        />

        <TypeScaleRow
          title={<SubTitle>Subtitle</SubTitle>}
          size={'13px'}
          family={'Lato Bold (600)'}
          comments={
            <Text>
              <code>{'<SubTitle />'}</code>
            </Text>
          }
        />
        <TypeScaleRow
          title={<Text weight="bold">Body Bold</Text>}
          size={'16px'}
          family={'Lato Bold (600)'}
          comments={
            <Text>
              <code>{'<Text weight="bold" />'}</code>
            </Text>
          }
        />
        <TypeScaleRow
          title={<Text>Body</Text>}
          size={'16px'}
          family={'Lato Regular (400)'}
          comments={
            <Text>
              <code>{'<Text />'}</code>
            </Text>
          }
        />
        <TypeScaleRow
          title={<Text size="small">Print / Meta</Text>}
          size={'13px'}
          family={'Lato Regular (600)'}
          comments={
            <Text>
              <code>{'<Text size="small" />'}</code>
            </Text>
          }
        />
      </div>
    </div>
  );
}

class TypeNoteSection extends Component {
  state = { isOpen: true };

  render() {
    return (
      <div>
        <div
          className="mb-medium flex-row align-center cursor-pointer"
          onClick={() => this.setState({ isOpen: !this.state.isOpen })}
        >
          <Title level={3} className="mv-none mr-small">
            Typography Notes
          </Title>
          <Icon type={this.state.isOpen ? 'caret-up' : 'caret-down'} />
        </div>

        <Collapse isOpen={this.state.isOpen}>
          <Paragraph className="max-width-640">
            <Text weight="bold">Heading One</Text> & <Text weight="bold">Heading Two</Text> are mainly used as an intro
            header on a new page. The choice of using either Heading One or Heading Two largely depends on the amount of
            content found in the page; if there is a lot of content found on the page, use Heading Two to save vertical
            space.
          </Paragraph>

          <Paragraph className="max-width-640">
            <Text weight="bold">Heading Three</Text> & <Text weight="bold">Heading Four</Text> are meant to be used to
            describe individual sections in the page. Heading Four may be used to describe smaller sections.
          </Paragraph>

          <Paragraph className="max-width-640">
            Body text should always be <code>16px</code> for all content, and every paragraph should be capped at a
            maximum width of <code>640 px</code> for optimal reading experience. The paragraphs on this page are a good
            example of capped width @ 640px.
          </Paragraph>

          <Paragraph className="max-width-640">
            <b>SubTitle</b> headers may be used to indicate smaller sections of the page, such as a menu navigation
            section (see menu on the left for examples).
          </Paragraph>
        </Collapse>
      </div>
    );
  }
}

export function TypographyPageView() {
  return (
    <div className="mb-x7-large">
      <div className="mb-x-large">
        <Title level={1}>Typography</Title>
      </div>

      <div className="mb-x-large max-width-640">
        <Text>This section illustrates the different typography variants used throughout the GoodHuman portal.</Text>
      </div>

      <TypeScaleSection />

      <TypeNoteSection />
    </div>
  );
}
