import React, { HTMLProps, PureComponent } from 'react';
import { CustomerAlertType } from 'utilities/enum-utils';
import { AlertTag } from 'common-components/tags';

export interface CustomerAlertTagProps extends HTMLProps<any> {
  alertType: CustomerAlertType;
  numberOfAlerts: number;
  onClickView?: () => void;
  onClickIgnore?: () => void;
}

export interface CustomerAlertTagState {}

class CustomerAlertTag extends PureComponent<CustomerAlertTagProps, CustomerAlertTagState> {
  private _alertDescription(alertType: CustomerAlertType, numberOfAlerts: number) {
    const agreements = numberOfAlerts !== 1 ? 'agreements' : 'agreement';
    const documents = numberOfAlerts !== 1 ? 'documents' : 'document';

    if (alertType === CustomerAlertType.OverBudgetActual) {
      return `service ${agreements} exceed quote amount.`;
    }
    if (alertType === CustomerAlertType.OverBudgetForecasted) {
      return `service ${agreements} is forcasted to exceed quote amount.`;
    }
    if (alertType === CustomerAlertType.ServiceAgreementExpired) {
      return `No active/upcoming service ${agreements}.`;
    }
    if (alertType === CustomerAlertType.ServiceAgreementExpiringSoon) {
      return `service ${agreements} expiring soon.`;
    }
    if (alertType === CustomerAlertType.DocumentExpired) {
      return `${documents} expired.`;
    }
    if (alertType === CustomerAlertType.DocumentExpiringSoon) {
      return `${documents} expiring soon.`;
    }
  }
  private _alertColor(alertType: CustomerAlertType) {
    if (alertType === CustomerAlertType.OverBudgetActual || alertType === CustomerAlertType.DocumentExpired) {
      return 'error';
    }
    return 'warning';
  }

  render() {
    const { onClickView, onClickIgnore, numberOfAlerts, alertType, ...props } = this.props;
    return (
      <AlertTag
        type={this._alertColor(alertType)}
        onClickView={onClickView}
        onClickIgnore={onClickIgnore}
        text={
          <>
            {alertType === CustomerAlertType.ServiceAgreementExpired ? '' : <b>{numberOfAlerts}</b>}{' '}
            {this._alertDescription(alertType, numberOfAlerts)}
          </>
        }
        {...props}
      />
    );
  }
}

export default CustomerAlertTag;
