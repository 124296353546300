import React, { Component } from 'react';
import CenteredLayout from 'layouts/CenteredLayout';
import { HyperlinkButton } from 'common-components/buttons';
import { Text, Title } from 'common-components/typography';

// import Undraw from 'react-undraw';

class WipComponentView extends Component<any, any> {
  render() {
    return (
      <CenteredLayout backgroundColor="white">
        {/*<SplashExample />*/}

        <ColorsExample />
      </CenteredLayout>
    );
  }
}

export default WipComponentView;

function ColorsExample() {
  return (
    <div>
      <Title level={2}>Text Legibility</Title>

      <div className="flex-row mb-x-large">
        <div className="p-medium rounded mr-medium bg-navy-blue">
          <Text color="white">Legible text on background</Text>
        </div>
      </div>

      <div className="flex-row mb-x-large">
        <div className="p-medium rounded mr-medium bg-green-dark">
          <Text color="white">Legible text on background</Text>
        </div>

        <div className="p-medium rounded mr-medium bg-blue-dark">
          <Text color="white">Legible text on background</Text>
        </div>

        <div className="p-medium rounded mr-medium bg-indigo-dark">
          <Text color="white">Legible text on background</Text>
        </div>

        <div className="p-medium rounded mr-medium bg-red-dark">
          <Text color="white">Legible text on background</Text>
        </div>
      </div>

      <div className="flex-row mb-x-large">
        <div className="p-medium rounded mr-medium bg-gold-darker">
          <Text color="white">Legible text on background</Text>
        </div>

        <div className="p-medium rounded mr-medium bg-cobalt">
          <Text color="white">Legible text on background</Text>
        </div>

        <div className="p-medium rounded mr-medium bg-violet">
          <Text color="white">Legible text on background</Text>
        </div>

        <div className="p-medium rounded mr-medium bg-rose">
          <Text color="white">Legible text on background</Text>
        </div>
      </div>

      <Title level={2}>Theme Primary Colors</Title>

      <div className="flex-row mb-x-large">
        <div style={{ width: '100px', height: '100px' }} className="bg-green mr-medium rounded-full">
          &nbsp;
        </div>

        <div style={{ width: '100px', height: '100px' }} className="bg-blue mr-medium rounded-full">
          &nbsp;
        </div>

        <div style={{ width: '100px', height: '100px' }} className="bg-indigo mr-medium rounded-full">
          &nbsp;
        </div>

        <div style={{ width: '100px', height: '100px' }} className="bg-red mr-medium rounded-full">
          &nbsp;
        </div>
      </div>

      <Title level={2}>Primary Colors</Title>

      <div className="flex-row mb-x-large">
        <div style={{ width: '100px', height: '100px' }} className="bg-gold mr-medium rounded-full">
          &nbsp;
        </div>

        <div style={{ width: '100px', height: '100px' }} className="bg-cobalt mr-medium rounded-full">
          &nbsp;
        </div>

        <div style={{ width: '100px', height: '100px' }} className="bg-violet mr-medium rounded-full">
          &nbsp;
        </div>

        <div style={{ width: '100px', height: '100px' }} className="bg-rose mr-medium rounded-full">
          &nbsp;
        </div>
      </div>
    </div>
  );
}

function SplashExample() {
  return (
    <div>
      {/*<Title level={1} weight="bold">*/}
      {/*  Typography*/}
      {/*</Title>*/}

      <div className="flex-row align-center mt-x10-large mb-x10-large">
        <div className="mr-x5-large">
          <div className="mb-medium">
            <Text className="text-uppercase letter-spacing-wide" weight="bolder" size="small" color="gray-darker">
              Navigation
            </Text>
          </div>

          <div className="mb-medium">
            <Title level={1} className="mv-none">
              Get there faster
            </Title>
          </div>

          <div className="mb-medium">
            <Text size="x-large">
              Amaze your users and change your industry with realtime routing and asset tracking, backed by ultra-fast,
              traffic-aware ETAs.
            </Text>
          </div>

          <div>
            <HyperlinkButton fontSize="x-large">Learn more</HyperlinkButton>
          </div>
        </div>
        <div style={{ height: '240px', width: '400px' }}>
          {/*<Undraw name="map" style={{ height: '240px' }} />*/}
        </div>
      </div>

      <div className="flex-row align-center">
        <div style={{ height: '240px', width: '480px' }}>
          {/*<Undraw name="bookmarks" style={{ height: '240px' }} />*/}
        </div>

        <div className="ml-x5-large">
          <div className="mb-medium">
            <Text className="text-uppercase letter-spacing-wide" weight="bolder" size="small" color="gray-darker">
              Augmented Reality
            </Text>
          </div>

          <div className="mb-medium">
            {/*<Title level={1} className="mv-none">*/}
            {/*  Defy reality*/}
            {/*</Title>*/}
          </div>

          <div className="mb-medium">
            <Title level={3} className="mv-none" weight="black">
              Incredibly snappy performance at your fingertips
            </Title>
          </div>

          <div className="mb-medium">
            <Text size="x-large">
              Map augmented reality to the physical world. Points of interest, live location data, and global object
              persistence for multi-user AR experiences and games.
            </Text>
          </div>

          <div>
            <HyperlinkButton fontSize="x-large">Learn more</HyperlinkButton>
          </div>
        </div>
      </div>
    </div>
  );
}
