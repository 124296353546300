import { Spinner } from '@blueprintjs/core';
import { Checkbox, Col, Icon, Input, message, notification, Progress, Row, Upload } from 'antd';
import { Warning } from 'common-components/alerts';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { SubTitle, Text } from 'common-components/typography';
import { ref, uploadBytesResumable } from 'firebase/storage';
import _ from 'lodash';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import firebaseApp from 'stores/firebase-app';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';

const { TextArea } = Input;

interface IAddDocumentModalProps {
  closeAddDocumentModal: () => void;
  isOpen: boolean;
  selectedItemId: string;
  itemType: string;
  doAddCustomerDocument: typeof dispatch.customersStore.doAddCustomerDocument;
  doAddWorkerDocument: typeof dispatch.teamStore.doAddWorkerDocument;
  doAddBookingDocument: typeof dispatch.bookingsStore.doAddBookingDocument;
  portalUser: typeof state.authStore.portalUser;
  resetDocumentList: () => void;
  useAttachmentText: boolean;
  isExpiryDisplayed?: boolean;
}

interface IAddDocumentModalState {
  isLoading: boolean;
  isActionModalOpen: boolean;
  isUploading: boolean;
  isInitialise: boolean;
  selectedFile: any;
  progress: number;
  description: any;
  expiryDate: Date;
  isViewableOnApp: boolean;
  isCompliant: boolean;
}

class AddDocumentModal extends Component<IAddDocumentModalProps, IAddDocumentModalState> {
  state = {
    isLoading: false,
    isActionModalOpen: false,
    selectedFile: null,
    progress: 0,
    isUploading: false,
    isInitialise: false,
    description: '',
    expiryDate: null,
    isViewableOnApp: false,
    isCompliant: false
  };

  private _closeWithActionModal = () => {
    if (_.isEmpty(this.state.selectedFile)) {
      this.props.closeAddDocumentModal();
    } else {
      this.setState({ isActionModalOpen: true });
    }
  };

  private _closeActionModal = () => {
    this.setState({ isActionModalOpen: false });
  };

  private _closeActionAddDocumentModal = () => {
    this.setState({
      isActionModalOpen: false,
      selectedFile: null,
      description: null,
      progress: 0,
      expiryDate: null,
      isViewableOnApp: false
    });
    this.props.closeAddDocumentModal();
  };

  private _hasExtension = (file) => {
    return new RegExp(
      '(' +
        [
          '.jpeg',
          '.jpg',
          '.png',
          '.gif',
          '.pdf',
          '.txt',
          '.ppt',
          '.pptx',
          '.doc',
          '.docx',
          '.csv',
          '.xls',
          '.xlsx',
          '.xlsm'
        ]
          .join('|')
          .replace(/\./g, '\\.') +
        ')$'
    ).test(file.name.toLowerCase());
  };

  private _validateFile = (file) => {
    const isValidType = this._hasExtension(file);
    if (!isValidType) {
      message.error('This file extension is not supported, please choose another format.');
    }
    const isLt2M = file.size / 1024 / 1024 < 25;
    if (!isLt2M) {
      message.error('File must be smaller than 25MB.');
    }

    if (isLt2M && isValidType) {
      this.setState({ selectedFile: file });
    }

    return false;
  };

  private _resetSelectedFile = () => {
    this.setState({ selectedFile: null });
  };

  private _updateDescription = (event) => {
    this.setState({ description: event.target.value });
  };

  private _updateExpiryDate = (event) => {
    this.setState({ expiryDate: event });
  };

  private _uploadFile = async () => {
    const {
      selectedItemId,
      doAddCustomerDocument,
      doAddWorkerDocument,
      doAddBookingDocument,
      portalUser,
      itemType
    } = this.props;
    const { selectedFile, description, expiryDate, isViewableOnApp, isCompliant } = this.state;

    this.setState({ isInitialise: true });
    try {
      const addedDocument: any =
        itemType === 'customer'
          ? await doAddCustomerDocument({
              customerUserId: selectedItemId,
              documentName: selectedFile.name,
              expiryDate,
              description,
              isViewableOnApp
            })
          : itemType === 'worker'
          ? await doAddWorkerDocument({
              supportWorkerId: selectedItemId,
              documentName: selectedFile.name,
              expiryDate,
              description,
              isCompliant
            })
          : await doAddBookingDocument({
              bookingId: selectedItemId,
              documentName: selectedFile.name,
              description
            });

      try {
        const metadata = {
          customMetadata: {
            documentId: addedDocument.documentId,
            customerUserId: itemType === 'customer' ? selectedItemId : null,
            supportWorkerId: itemType === 'worker' ? selectedItemId : null,
            serviceProviderId: portalUser.serviceProviderId,
            attendanceId: itemType !== 'customer' && itemType !== 'worker' ? selectedItemId : null
          }
        };
        this.setState({ isLoading: true });

        const storageRef = ref(firebaseApp.storage, `${addedDocument.uploadBucketUrl}/${selectedFile.name}`);
        const uploadFile = uploadBytesResumable(storageRef, selectedFile, metadata);

        await uploadFile.on(
          'state_changed',
          (snapshot) => {
            const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            this.setState({ progress });
          },
          (error) => {
            this.setState({ isLoading: false });
            this._closeActionAddDocumentModal();
            notification.error({ message: 'Upload failed! Please try again.', description: error });
          },
          () => {
            this.setState({ isLoading: false });
            this._closeActionAddDocumentModal();
            notification.success({
              message: `${this.props.useAttachmentText ? 'Attachment' : 'Document'} is currently scanning.`
            });
          }
        );
      } catch (e) {
        notification.error({ message: 'Upload failed! Please try again.', description: e });
      }
    } catch (e) {
      notification.error({ message: 'The format of the file has been rejected. Please try again.' });
    }
    this.setState({ isInitialise: false });
  };

  private _onChangeIsViewableOnApp = (e) => {
    this.setState({ isViewableOnApp: e.target.checked });
  };
  private _onChangeIsComplianceDocument = (e) => {
    this.setState({ isCompliant: e.target.checked });
  };

  render() {
    const { useAttachmentText } = this.props;
    const { selectedFile, progress } = this.state;

    return (
      <div>
        <ActionModal
          isOpen={this.state.isActionModalOpen}
          onClose={this._closeWithActionModal}
          title={`Resume add ${useAttachmentText ? 'attachment' : 'document'}`}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            Your {useAttachmentText ? 'attachment' : 'document'} hasn't been added, proceeding will discard it.
          </Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeActionModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size="large" onClick={this._closeActionAddDocumentModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          title={`Add a ${useAttachmentText ? 'attachment' : 'document'}`}
          isOpen={this.props.isOpen}
          onClose={this._closeActionAddDocumentModal}
          width="medium"
        >
          <div className="anim-slide-left">
            {this.state.isLoading ? (
              <div className="mv-x2-large">
                <div className="text-align-center">
                  <Progress type="circle" percent={progress} className="mb-medium" />
                </div>
                <div className="text-align-center">
                  <Text size="x2-large">Uploading...</Text>
                </div>
              </div>
            ) : this.state.isInitialise ? (
              <div className="mv-x2-large">
                <div className="text-align-center">
                  <Spinner size={80} />
                </div>
                <div className="text-align-center">
                  <Text size="x2-large">Initializing...</Text>
                </div>
              </div>
            ) : (
              <>
                <Row className="mb-medium">
                  <Row className="mb-x-small">
                    <SubTitle>{useAttachmentText ? 'Attachment' : 'Document'}</SubTitle>
                  </Row>
                  <Row>
                    {_.isEmpty(selectedFile) ? (
                      <Upload multiple={false} beforeUpload={this._validateFile} showUploadList={false}>
                        <SecondaryButton size={'large'} icon={'upload'}>
                          Upload file...
                        </SecondaryButton>
                      </Upload>
                    ) : (
                      <>
                        <Icon type="file" className="mr-x-small" />
                        <b>{selectedFile.name}</b>
                        <br />
                        <HyperlinkButton onClick={this._resetSelectedFile}>Select another file</HyperlinkButton>
                      </>
                    )}
                  </Row>
                </Row>
                {this.props.itemType === 'customer' && (
                  <Row className="mb-medium">
                    <Checkbox
                      onChange={this._onChangeIsViewableOnApp}
                      value={this.state.isViewableOnApp}
                      className="text-color-secondary"
                    >
                      Can be viewed on the business app
                    </Checkbox>
                  </Row>
                )}
                {this.state.isViewableOnApp && (
                  <Row>
                    <Warning
                      content={
                        <>
                          <Text weight={'bold'}>Only certain file types can be accessed from the mobile app</Text>
                          <br></br>
                          <Text>
                            The preferred file types for mobile access are PDF, JPEG & PNG. Making any other file types
                            avalible on the business app may result in that document not being able to be viewed on some
                            devices.
                          </Text>
                        </>
                      }
                      className="mb-large"
                    />
                  </Row>
                )}
                {this.props.itemType === 'worker' && (
                  <Row className="mb-medium">
                    <Checkbox
                      onChange={this._onChangeIsComplianceDocument}
                      value={this.state.isCompliant}
                      className="text-color-secondary"
                    >
                      Is this a compliance document?
                    </Checkbox>
                  </Row>
                )}
                <Row className="mb-x2-large">
                  <Row className="mb-x-small">
                    <SubTitle>Description (60 characters max)</SubTitle>
                  </Row>
                  <Row>
                    <TextArea
                      maxLength={60}
                      autoSize={{ minRows: 5 }}
                      value={this.state.description}
                      onChange={this._updateDescription}
                    />
                  </Row>
                </Row>
                {this.props.isExpiryDisplayed && (
                  <Row className="mb-x2-large">
                    <Row className="mb-x-small">
                      <SubTitle>Expiry date</SubTitle>
                    </Row>
                    <Row>
                      <DatePicker
                        className="gh-datepicker rounded"
                        calendarClassName="gh-datepicker-calendar"
                        dateFormat="d/M/yyyy"
                        onChange={this._updateExpiryDate}
                        placeholderText={'No expiry date'}
                        isClearable={true}
                        selected={this.state.expiryDate}
                      />
                    </Row>
                  </Row>
                )}
                <div className={'mb-small'}>
                  <Row type={'flex'} className={'justify-end'}>
                    <Col className="mr-large">
                      <GhostButton size="large" onClick={this._closeWithActionModal}>
                        Cancel
                      </GhostButton>
                    </Col>
                    <Col>
                      <PrimaryButton size="large" disabled={_.isEmpty(selectedFile)} onClick={this._uploadFile}>
                        Add Document
                      </PrimaryButton>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div>
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddCustomerDocument: dispatch.customersStore.doAddCustomerDocument,
  doAddWorkerDocument: dispatch.teamStore.doAddWorkerDocument,
  doAddBookingDocument: dispatch.bookingsStore.doAddBookingDocument
});

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser
});

export default connect(mapState, mapDispatch)(AddDocumentModal);
