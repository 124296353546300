import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Avatar, Col, Empty, Icon, Popover, Skeleton } from 'antd';
import { FieldLabel, Text } from 'common-components/typography';
import { YearAndMonthFilter } from 'common-components/filter';
import moment from 'moment-timezone';
import _ from 'lodash';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { IGroupServiceCustomerListItem } from 'interfaces/customer-interfaces';
import { HyperlinkButton, GhostButton, PrimaryButton } from 'common-components/buttons';
import { GridHeader, GridRow } from 'common-components/grids';
import SessionStatusTag from 'common-components/tags/SessionStatusTag';
import { IGroupServiceSession } from 'interfaces/service-interfaces';
import { IYearAndMonthFilter } from 'interfaces/filter-interfaces';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import RemoveCustomerFromScheduleModal from 'views/group-services/service-details/components/RemoveCustomerFromScheduleModal';
import { BookingStatus } from 'utilities/enum-utils';
import * as H from 'history';
import { StatusTag } from 'common-components/tags';

interface IViewCustomerScheduleModalProps {
  isOpen: boolean;
  onClose: () => void;
  history: H.History;
  selectedCustomer: IGroupServiceCustomerListItem;
  groupServiceSessions: typeof state.servicesStore.groupServiceSessions;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  doFetchGroupServiceCustomerSessions: typeof dispatch.servicesStore.doFetchGroupServiceCustomerSessions;
  setGroupServiceSessions: typeof dispatch.servicesStore.setGroupServiceSessions;
}

interface IViewCustomerScheduleModalState {
  isLoading: boolean;
  sessions: IGroupServiceSession[];
  filter: IYearAndMonthFilter;
  isRemoveModalOpen: boolean;
  removeModalType: string;
  selectedSession: any;
}

class ViewCustomerScheduleModal extends Component<IViewCustomerScheduleModalProps, IViewCustomerScheduleModalState> {
  state = {
    isLoading: false,
    sessions: this.props.groupServiceSessions,
    filter: {
      year: moment(this.props.groupServiceSessions[0] && this.props.groupServiceSessions[0].startDateTime).year(),
      month: moment(this.props.groupServiceSessions[0] && this.props.groupServiceSessions[0].startDateTime).month()
    },
    isRemoveModalOpen: false,
    removeModalType: 'individual',
    selectedSession: null
  };

  private _updateFilter = (e) => {
    this.setState({ filter: e });
  };

  private _loadContent = async () => {
    const { selectedGroupService, selectedCustomer, doFetchGroupServiceCustomerSessions } = this.props;
    if (!selectedCustomer || !selectedGroupService) return;

    this.setState({ isLoading: true });
    const payload = {
      serviceId: selectedGroupService.serviceId,
      customerUserId: selectedCustomer.customerUserId
    };
    doFetchGroupServiceCustomerSessions(payload);
    this.setState({ isLoading: false });
  };

  private _onClose = async () => {
    this.props.onClose();
    await this.props.setGroupServiceSessions([]);
    this.setState({ sessions: [], filter: { year: moment().year(), month: moment().month() } });
  };

  private _openRemoveModal = (session, isRemoveSelectedBookingOnly = false) => {
    this.setState({
      isRemoveModalOpen: true,
      selectedSession: session,
      removeModalType: isRemoveSelectedBookingOnly ? 'individual' : 'schedule'
    });
  };

  private _closeRemoveModal = () => {
    this.setState({
      isRemoveModalOpen: false,
      selectedSession: null,
      removeModalType: 'individual'
    });
  };

  private _goToSession = async (serviceDateTimeId) => {
    const { history, selectedGroupService } = this.props;
    history.push(`/group-service/${selectedGroupService.serviceId}/session/details/${serviceDateTimeId}`);
  };

  componentDidMount = async () => {
    await this.props.setGroupServiceSessions([]);
  };

  componentDidUpdate = async (prevPops) => {
    if (this.props.selectedCustomer !== prevPops.selectedCustomer) {
      await this._loadContent();
    }

    if (this.props.groupServiceSessions !== prevPops.groupServiceSessions) {
      const sortedSessions = _.sortBy(this.props.groupServiceSessions, 'startDateTime');
      this.setState({
        sessions: sortedSessions,
        filter: {
          year: moment(sortedSessions[0] && sortedSessions[0].startDateTime).year(),
          month: moment(sortedSessions[0] && sortedSessions[0].startDateTime).month()
        }
      });
    }
  };

  render() {
    const { filter, isLoading, sessions, isRemoveModalOpen, removeModalType, selectedSession } = this.state;
    const { selectedCustomer, selectedGroupService } = this.props;
    const customerFullName = selectedCustomer
      ? `${selectedCustomer.customerFirstName} ${selectedCustomer.customerLastName}`
      : '';
    const timezone = selectedGroupService.timezone;
    const filteredSessionList = _.filter(
      sessions,
      (session) =>
        moment(session.startDateTime).year() === filter.year && moment(session.startDateTime).month() === filter.month
    );

    const SessionEmptyState = () => (
      <div className="flex-1 bg-white mt-x2-large align-center flex-column">
        <div className="">
          <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
        </div>
        <Text size="x2-large" color="secondary" weight="bold">
          No session found for the selected month.
        </Text>
      </div>
    );

    return (
      <ActionModal width="x2-large" isOpen={this.props.isOpen} onClose={this._onClose} title={'View Schedule'}>
        <RemoveCustomerFromScheduleModal
          removeModalType={removeModalType}
          isOpen={isRemoveModalOpen}
          customerUserId={selectedCustomer && selectedCustomer.customerUserId}
          selectedSession={selectedSession}
          onClose={this._closeRemoveModal}
        />
        {isLoading ? (
          <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
        ) : (
          <>
            {' '}
            <Text>You’re currently viewing the schedule for this following customer :</Text>
            <div className="mt-medium flex-row align-center">
              <Avatar
                size="large"
                className="mr-small"
                icon="user"
                src={selectedCustomer && selectedCustomer.customerAvatarUrl}
              />
              <Text weight="bold">{customerFullName}</Text>
            </div>
            <YearAndMonthFilter
              filter={filter}
              startDateTime={moment(sessions[0] && sessions[0].startDateTime)}
              endDateTime={sessions.length > 0 && _.last(sessions) ? moment(_.last(sessions).endDateTime) : moment()}
              updateFilter={this._updateFilter}
              hideEmptyDates={true}
              dateList={sessions}
            />
            <GridHeader bordered containerClassName="border-width-medium border-secondary">
              <Col span={6} className=" bg-white">
                <FieldLabel text="Session date" />
              </Col>
              <Col span={4} className=" bg-white">
                <FieldLabel text={'Status'} />
              </Col>
              <Col span={4} className=" bg-white">
                <FieldLabel text={'CAPACITY'} />
              </Col>
              <Col span={6} className=" bg-white">
                <FieldLabel text={'SCHEDULE'} />
              </Col>
              <Col span={4} className=" bg-white"></Col>
            </GridHeader>
            {filteredSessionList.length === 0 ? (
              <SessionEmptyState />
            ) : (
              _.map(filteredSessionList, (session) => {
                const date = moment.tz(session.startDateTime, timezone);
                const startDate = moment.tz(session.startDateTime, timezone);
                const endTime = moment.tz(session.endDateTime, timezone);
                const capacityStr = session.capacity ? `${session.bookedCapacity}/${session.capacity}` : '-';

                return (
                  <GridRow>
                    <Col span={6}>
                      <HyperlinkButton onClick={() => this._goToSession(session.serviceDateTimeId)}>
                        {date.format('ddd, D MMM YYYY')}
                      </HyperlinkButton>
                      <br />
                      <Text type="secondary">
                        {startDate.format('HH:mm A')} - {endTime.format('HH:mm A')}
                      </Text>
                    </Col>
                    <Col span={4}>
                      <StatusTag status={session.status} size="small" />
                    </Col>
                    <Col span={4}>{capacityStr}</Col>
                    <Col span={6}>{session.scheduleName}</Col>
                    <Col span={4} className={'text-align-right pr-large'}>
                      <Popover
                        placement="bottomRight"
                        className="cursor-pointer"
                        content={
                          <ActionMenu>
                            {!!_.find(
                              [BookingStatus.PENDING, BookingStatus.ACCEPTED, BookingStatus.CONFIRMED],
                              (status) => session.status === status
                            ) && (
                              <ActionMenuItem
                                text={'Remove from this session'}
                                onClick={() => this._openRemoveModal(session, true)}
                              />
                            )}
                            <ActionMenuItem
                              text={'Remove from schedule'}
                              onClick={() => this._openRemoveModal(session, false)}
                            />
                          </ActionMenu>
                        }
                      >
                        <div
                          className="bordered border-standard-gray rounded text-align-center"
                          style={{ width: '28px', height: '28px', float: 'right' }}
                        >
                          <GhostButton icon={'ellipsis'} size={'small'} paddingSize={'x-small'} />
                        </div>
                      </Popover>
                    </Col>
                  </GridRow>
                );
              })
            )}
            <ActionModalFooter className="mt-medium">
              <PrimaryButton size="large" onClick={this._onClose}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupServiceCustomerSessions: dispatch.servicesStore.doFetchGroupServiceCustomerSessions,
  setGroupServiceSessions: dispatch.servicesStore.setGroupServiceSessions
});

const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService,
  groupServiceSessions: state.servicesStore.groupServiceSessions,
  customerToSchedule: state.servicesStore.customerToSchedule
});

export default connect(
  mapState,
  mapDispatch
)(ViewCustomerScheduleModal);
