import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, SubTitle } from 'common-components/typography';
import { Form, notification } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import _ from 'lodash';
import DatePicker from 'react-datepicker';

interface IMarkAsPaidActionModalProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  doMarkAsPaidInvoice: typeof dispatch.billingsStore.doMarkAsPaidInvoice;
  selectedInvoice: typeof state.billingsStore.selectedInvoice;
  selectedInvoices: typeof state.billingsStore.selectedInvoices;
  markAsPaidType: typeof state.billingsStore.markAsPaidType;
  setSelectedInvoices: typeof dispatch.billingsStore.setSelectedInvoices;
}

interface IMarkAsPaidActionModalState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

class MarkAsPaidActionModal extends Component<IMarkAsPaidActionModalProps, IMarkAsPaidActionModalState> {
  state = {
    isLoading: false,
    isSuccess: false,
    isError: false
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false, isError: false });
      onClose();
    }
  };

  private _onSubmit = () => {
    const {
      form,
      selectedInvoice,
      selectedInvoices,
      doMarkAsPaidInvoice,
      onClose,
      markAsPaidType,
      setSelectedInvoices
    } = this.props;

    const invoiceList = markAsPaidType === 'MULTIPLE' ? selectedInvoices : selectedInvoice;

    form.validateFields(async (err, value) => {
      if (!err) {
        this.setState({ isLoading: true });
        const invoiceIds = _.uniq(_.map(invoiceList, (invoice) => _.get(invoice, 'invoiceId', '')));

        try {
          await doMarkAsPaidInvoice({
            invoiceIds: invoiceIds,
            datePaid: form.getFieldValue('datePaid')
          });
          this.setState({ isLoading: false, isSuccess: true });
          onClose();
          if (markAsPaidType === 'MULTIPLE') {
            setSelectedInvoices([]);
          }
          notification.success({
            message: 'Invoice marked as paid',
            description: (
              <p>
                You have marked{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {invoiceIds.length} invoice{invoiceIds.length > 1 ? 's' : ''}
                </span>{' '}
                as paid.
              </p>
            )
          });
        } catch (e) {
          this.setState({ isLoading: false, isError: true });
        }
      }
    });
  };

  private _onChangeDate = (date) => {
    this.props.form.setFieldsValue({ datePaid: date });
  };

  render() {
    const { isOpen, form } = this.props;
    const { getFieldDecorator, getFieldValue } = form;

    return (
      <ActionModal
        title="Mark invoice as paid"
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="medium"
        verticalAlignment="highest"
      >
        <div className="text-align-left">
          <div className="mb-medium">
            <Paragraph className="mb-x-small">
              Marking an invoice as paid will mark all non-rejected line items as paid.
            </Paragraph>
            <Paragraph> Rejected line items will remain in the rejected area of payments.</Paragraph>
          </div>

          <div className="flex-column mb-x-large">
            <SubTitle>DATE PAID</SubTitle>
            <Form.Item>
              {getFieldDecorator('datePaid', {
                initialValue: new Date(moment.tz().format('YYYY-MM-DD HH:mm')),
                rules: [{ required: true, message: 'Please select the date paid.' }]
              })(
                <DatePicker
                  className="gh-datepicker rounded"
                  calendarClassName="gh-datepicker-calendar"
                  dateFormat="d/M/yyyy"
                  onChange={this._onChangeDate}
                  selected={getFieldValue('datePaid')}
                  maxDate={new Date(moment.tz().format('YYYY-MM-DD HH:mm'))}
                />
              )}
            </Form.Item>
          </div>
        </div>

        <ActionModalFooter>
          <SecondaryButton
            size="large"
            onClick={this._onCloseModal}
            className="mr-medium"
            disabled={this.state.isLoading}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={this._onSubmit} loading={this.state.isLoading}>
            Mark as paid
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedInvoice: state.billingsStore.selectedInvoice,
  selectedInvoices: state.billingsStore.selectedInvoices,
  markAsPaidType: state.billingsStore.markAsPaidType
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doMarkAsPaidInvoice: dispatch.billingsStore.doMarkAsPaidInvoice,
  setSelectedInvoices: dispatch.billingsStore.setSelectedInvoices
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IMarkAsPaidActionModalProps>()(MarkAsPaidActionModal));
