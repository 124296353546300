import React from 'react';

import _ from 'lodash';
import * as H from 'history';
import { Collapse } from '@blueprintjs/core';

import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';
import { CustomersTimelineHeader } from 'common-components/roster-control/roster-daily/timeline-pane/customers/CustomersTimelineHeader';
import { Gridlines } from 'common-components/roster-control/roster-daily/timeline-pane/common/Gridlines';
import { TimelineRow } from 'common-components/roster-control/roster-daily/timeline-pane/common/TimelineRow';
import { CustomerBookingCard } from 'common-components/roster-control/roster-daily/timeline-pane/customers/CustomerBookingCard';

import {
  IRosterBooking,
  IRosterCustomer,
  IRosterCustomerWithBookings
} from 'common-components/roster-control/interfaces/roster-interfaces';

interface CustomersTimelineProps {
  hours: string[];
  customers: IRosterCustomer[];
  bookings: IRosterCustomerWithBookings[];
  isCustomersOpen: boolean;
  currentDate?: Date;
  refreshData?: () => void;
  onAddBooking?: any;
  history: H.History;
}

// Change this to show empty rows whenever there's no data. A bit buggy, so setting it to 0 for now.
const EMPTY_ROW_COUNT = 1;

export function CustomersTimeline({
  hours,
  customers,
  bookings,
  isCustomersOpen,
  currentDate = new Date(),
  refreshData,
  onAddBooking,
  history
}: CustomersTimelineProps) {
  const { HOUR_BLOCK_WIDTH, TIMELINE_ROW_HEIGHT } = ROSTER_DAILY_CONFIG;
  const rowWidth = _.size(hours) * HOUR_BLOCK_WIDTH;

  // Row count is used to render the grid lines.
  // let gridRowCount = _.size(customers);
  let gridRowCount = _.reduce(bookings, (acc, customer) => acc + _.size(customer.bookings), 0);

  const hasCustomers = _.size(customers) > 0;

  if (!hasCustomers) {
    gridRowCount = 2;
  }

  return (
    <div>
      <CustomersTimelineHeader rowWidth={rowWidth} />

      <Collapse isOpen={isCustomersOpen} keepChildrenMounted={false}>
        <div className="flex-column position-relative">
          <Gridlines hours={hours} rowCount={gridRowCount} />

          {/* If there's no customers, show empty rows instead as a place holder. */}
          {!hasCustomers && (
            <>
              {_.times(EMPTY_ROW_COUNT, (idx) => (
                <TimelineRow rowWidth={rowWidth} key={idx} backgroundColor={'tertiary'} />
              ))}
            </>
          )}

          {/* Customers rendering */}
          {_.map(customers, (customer, idx) => {
            const customerBookings = _.find(bookings, (booking) => booking.customerId === customer.customerId);

            const bookingGroups =
              customerBookings && !_.isEmpty(customerBookings.bookings) ? customerBookings.bookings : [];

            const rowHeight =
              _.size(bookingGroups) > 0 ? TIMELINE_ROW_HEIGHT * _.size(bookingGroups) : TIMELINE_ROW_HEIGHT;

            return (
              <TimelineRow
                rowWidth={rowWidth}
                rowHeight={rowHeight}
                items={<CustomerItems bookings={bookingGroups} refreshData={refreshData} history={history} />}
                customer={customer}
                currentDate={currentDate}
                hasAddAction={true}
                onAddBooking={onAddBooking}
                key={idx}
              />
            );
          })}
        </div>
      </Collapse>
    </div>
  );
}

// Local component for filtering out bookings for the selected day.
interface CustomerItemsProps {
  bookings?: IRosterBooking[][];
  refreshData?: () => void;
  history: H.History;
}

function CustomerItems({ bookings, refreshData, history }: CustomerItemsProps) {
  return (
    <>
      {_.map(bookings, (bookingGroup, rowIdx) =>
        _.map(bookingGroup, (booking, bookingIdx) => (
          <CustomerBookingCard
            booking={booking}
            key={bookingIdx}
            refreshData={refreshData}
            history={history}
            rowIndex={rowIdx}
          />
        ))
      )}
    </>
  );
}
