import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from '../../../../common-components/modal/ActionModal';
import { Paragraph, SubTitle, Text } from '../../../../common-components/typography';
import SpinningLoader from '../../../../common-components/loading/SpinningLoader';
import { HyperlinkButton, PrimaryButton } from '../../../../common-components/buttons';
import { dispatch, IRootDispatch, state } from 'stores/rematch/root-store';
import _ from 'lodash';
import { Col, Icon, Row } from 'antd';
import { PaymentMethodTag } from 'common-components/tags';
import CommonUtils from 'utilities/common-utils';
import { FilterSection } from 'common-components/filter';
import { FilterType } from 'utilities/enum-utils';
import { connect } from 'react-redux';
import { Menu, Popover } from '@blueprintjs/core';
import WaiveLineItemActionModel from 'views/billings/payments/components/WaiveLineItemActionModel';
import moment from 'moment';

interface IPreviewInvoicesModalProps {
  isOpen: any;
  onClose: any;
  selectedBillingLineItem: typeof state.billingsStore.selectedBillingLineItem;
  setSelectedCustomersForFilter: typeof dispatch.customersStore.setSelectedCustomersForFilter;
}

interface IPreviewInvoicesModalState {
  isLoading: boolean;
  isWaiveModalOpen: boolean;
  invoices: any;
  selectedLineItems: any;
  title: string;
  activeFilters: Array<any>;
  selectedInvoice: any;
}

const availableFilters = [FilterType.CUSTOM_CUSTOMER, FilterType.PAYMENT_METHODS];

class PreviewInvoicesModal extends Component<IPreviewInvoicesModalProps, IPreviewInvoicesModalState> {
  state = {
    selectedLineItems: [],
    isLoading: false,
    isWaiveModalOpen: false,
    title: 'Preview invoices',
    invoices: [],
    activeFilters: [
      {
        filter: FilterType.CUSTOM_CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOM_CUSTOMER).fullSelectionName
      },
      {
        filter: FilterType.PAYMENT_METHODS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.PAYMENT_METHODS).fullSelectionName
      }
    ],
    selectedInvoice: null
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({
        isLoading: false,
        activeFilters: [
          {
            filter: FilterType.CUSTOM_CUSTOMER,
            values: [],
            selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOM_CUSTOMER).fullSelectionName
          },
          {
            filter: FilterType.PAYMENT_METHODS,
            values: [],
            selectionLabel: CommonUtils.getFilterSettings(FilterType.PAYMENT_METHODS).fullSelectionName
          }
        ]
      });
      onClose();
    }
  };

  private _onChangeFilter = (filters) => {
    this.setState({ activeFilters: filters });
  };

  private _filterInvoices = (activeFilters) => {
    const { selectedBillingLineItem } = this.props;
    const customerFilter = _.find(activeFilters, (filter) => filter.filter === FilterType.CUSTOM_CUSTOMER);
    const paymentMethodFilter = _.find(activeFilters, (filter) => filter.filter === FilterType.PAYMENT_METHODS);
    const filteredByCustomerLineItems =
      customerFilter && customerFilter.values.length > 0
        ? _.filter(selectedBillingLineItem, (lineItem) =>
            _.find(customerFilter.values, (customerUserId) => customerUserId === lineItem.userId)
          )
        : selectedBillingLineItem;
    const filteredLineItems =
      paymentMethodFilter && paymentMethodFilter.values.length > 0
        ? _.filter(filteredByCustomerLineItems, (lineItem) =>
            _.find(paymentMethodFilter.values, (paymentMethod) => paymentMethod === lineItem.paymentMethod)
          )
        : filteredByCustomerLineItems;
    return _.orderBy(
      _.groupBy(filteredLineItems, (lineItem) => lineItem.paymentMethod + '|' + lineItem.userId),
      'lastName'
    );
  };

  private _openInvoicePreview = async (lineItems) => {
    const currentTime = moment().format('mm-ss-SSS');
    localStorage.setItem(`previewInvoiceLineItems-${currentTime}`, lineItems);
    window.open(`/pdf?type=preview-invoice-by-line-items&time=${currentTime}`, '_blank');
  };

  private _openWaiveInvoiceModal = (invoice) => {
    console.log('INVOICE: ', invoice);
    this.setState({ isWaiveModalOpen: true, selectedInvoice: invoice });
  };
  private _closeWaiveInvoiceModal = () => {
    this.setState({ isWaiveModalOpen: false });
  };

  componentDidMount = async () => {
    const { setSelectedCustomersForFilter, selectedBillingLineItem } = this.props;
    const { activeFilters } = this.state;
    this.setState({ isLoading: true });
    await setSelectedCustomersForFilter(
      _.map(_.uniqBy(selectedBillingLineItem, 'userId'), (lineItem: any) => {
        return {
          userId: lineItem.userId,
          lastName: lineItem.lastName,
          firstName: lineItem.firstName
        };
      })
    );
    this.setState({ isLoading: false, invoices: this._filterInvoices(activeFilters) });
  };

  componentDidUpdate = async (
    prevProps: Readonly<IPreviewInvoicesModalProps>,
    prevState: Readonly<IPreviewInvoicesModalState>,
    snapshot?: any
  ) => {
    const { setSelectedCustomersForFilter, selectedBillingLineItem } = this.props;
    const { activeFilters } = this.state;
    if (prevProps.selectedBillingLineItem !== selectedBillingLineItem) {
      const invoices = _.orderBy(
        _.groupBy(selectedBillingLineItem, (lineItem) => lineItem.paymentMethod + '|' + lineItem.userId),
        'lastName'
      );
      await setSelectedCustomersForFilter(
        _.map(_.uniqBy(selectedBillingLineItem, 'userId'), (lineItem: any) => {
          return {
            userId: lineItem.userId,
            lastName: lineItem.lastName,
            firstName: lineItem.firstName
          };
        })
      );
      this.setState({ isLoading: false, invoices });
    }
    if (
      prevProps.selectedBillingLineItem &&
      prevProps.selectedBillingLineItem.length > 0 &&
      (!selectedBillingLineItem || selectedBillingLineItem.length === 0)
    ) {
      this._onCloseModal();
    }
    if (prevState.activeFilters !== activeFilters) {
      this.setState({ invoices: this._filterInvoices(activeFilters) });
    }
  };

  render() {
    const { isOpen, selectedBillingLineItem } = this.props;
    const { invoices } = this.state;

    const numberOfLineItems = selectedBillingLineItem.length;

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width={'x2-large'}
        verticalAlignment="highest"
      >
        <WaiveLineItemActionModel
          isOpen={this.state.isWaiveModalOpen}
          onClose={this._closeWaiveInvoiceModal}
          type={'preview'}
          lineItems={this.state.selectedInvoice}
        />
        {!this.state.isLoading && (
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>
                This selection have {numberOfLineItems} line item{numberOfLineItems > 1 && 's'}.
              </Paragraph>
              <FilterSection
                availableFilters={availableFilters}
                filters={this.state.activeFilters}
                onChangeFilter={this._onChangeFilter}
                displayTimezone={null}
                usePortal={false}
                containerClassName={'mv-small'}
              />
              <div
                style={{ maxHeight: 'calc(100vh - 500px)', overflow: 'auto' }}
                className={'bordered rounded-big mb-large'}
              >
                <Row className={'pv-medium bordered-bottom'}>
                  <Col span={8} className={'pl-medium'}>
                    <SubTitle>Customer</SubTitle>
                  </Col>
                  <Col span={4}>
                    <SubTitle>Payment Method</SubTitle>
                  </Col>
                  <Col span={3}>
                    <SubTitle>Items</SubTitle>
                  </Col>
                  <Col span={4} className={'text-align-right'}>
                    <SubTitle>Total</SubTitle>
                  </Col>
                  <Col span={3} />
                  <Col span={2} />
                </Row>
                {_.map(invoices, (invoice) => {
                  return (
                    <Row className={'pv-medium evenodd'}>
                      <Col span={8} className={'pl-medium'}>
                        {invoice[0].firstName} {invoice[0].lastName}
                      </Col>
                      <Col span={4}>
                        <PaymentMethodTag
                          paymentMethod={invoice[0].paymentMethod}
                          className="mr-small"
                          rounded={true}
                          size="small"
                        />
                      </Col>
                      <Col span={3}>
                        {invoice.length} line item{invoice.length !== 1 && 's'}
                      </Col>
                      <Col span={4} className={'text-align-right'}>
                        {CommonUtils.formatPrice(_.sumBy(invoice, (lineItem: any) => Number(lineItem.total)))}
                      </Col>
                      <Col span={3} className={'text-align-center'}>
                        <HyperlinkButton
                          onClick={() => {
                            this._openInvoicePreview(_.map(invoice, (lineItem) => lineItem.bookingBillingLineItemId));
                          }}
                        >
                          View Invoice
                        </HyperlinkButton>
                      </Col>
                      <Col span={2} className={'text-align-center'}>
                        <Popover
                          content={
                            <Menu>
                              <Menu.Item
                                text={
                                  <div onClick={() => this._openWaiveInvoiceModal(invoice)}>
                                    <Text>Waive invoice</Text>
                                  </div>
                                }
                                className="hover-bg-gray-lightest"
                              />
                            </Menu>
                          }
                          position={'bottom-right'}
                          usePortal={false}
                        >
                          <Icon type="ellipsis" className="text-size-x4-large cursor-pointer" />
                        </Popover>
                      </Col>
                    </Row>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {this.state.isLoading && <SpinningLoader size={100} message={'Loading'} />}
        <ActionModalFooter>
          <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
            Close
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedCustomersForFilter: dispatch.customersStore.setSelectedCustomersForFilter
});

export default connect(
  null,
  mapDispatch
)(PreviewInvoicesModal);
