/* eslint-disable */
import React, { Component } from 'react';
import { IRootDispatch, IRootState, dispatch, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { ICustomerAlert, ICustomerListItem } from 'src/interfaces/customer-interfaces';
import { ICustomView } from 'src/interfaces/custom-views-interface';
import { Text, FieldLabel } from 'common-components/typography';
import { GridHeader, GridRow } from '../../../common-components/grids';
import _ from 'lodash';
import { Col, Form, Avatar, Input, Tabs, Skeleton, Empty, Checkbox, Alert, Popover, Icon } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import * as H from 'history';
const { Search } = Input;
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import PermissionUtils from 'utilities/permission-utils';
import { ProgressBar } from '@blueprintjs/core';
import CustomerStatusTags from 'common-components/tags/CustomerStatusTags';
import PaymentSourceTag from 'common-components/tags/PaymentSourceTag';
import PinnedAlertIcon from 'common-components/pinned-alerts/PinnedAlertIcon';
import CustomerConnectionStatusTag from 'common-components/tags/CustomerConnectionStatusTags';
import { CustomerAlertType, CustomViewsModalType, FilterType } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';
import { FilterSection } from 'common-components/filter';
import { HyperlinkButton, SecondaryButton } from 'common-components/buttons';
import CreateNewBookingModal from 'views/bookings/listings/components/CreateNewBookingModal';
import { INewBookingData } from 'interfaces/booking-interfaces';
import CustomViewsModal from 'common-components/custom-views/modals/CustomViewsModal';
import CustomViewPopover from 'common-components/custom-views/popover/CustomViewPopover';
import CustomerViewTabPopover from '../CustomerViews/components/CustomerViewTabPopover';

const { TabPane } = Tabs;

interface ICustomerListingPanelProps extends FormComponentProps {
  doGetCustomer?: typeof dispatch.customersStore.doGetCustomer;
  doGetCustomers?: typeof dispatch.customersStore.doGetCustomers;
  doGetCustomerParentGuardiansList?: typeof dispatch.customersStore.doGetCustomerParentGuardiansList;
  filteredCustomers?: ICustomerListItem[];
  customerFilter: typeof state.customersStore.customerFilter;
  portalUser: typeof state.authStore.portalUser;
  setCustomerFilter: typeof dispatch.customersStore.setCustomerFilter;
  setHasCustomerFilterChanged: typeof dispatch.customersStore.setHasCustomerFilterChanged;
  setRefreshList: typeof dispatch.customersStore.setRefreshList;
  setCustomers: typeof dispatch.customersStore.setCustomers;
  setSelectedSideNavMenuKeys: typeof dispatch.navigationStore.setSelectedSideNavMenuKeys;
  doFetchCustomerViews: typeof dispatch.customersStore.doFetchCustomerViews;
  customerViews: typeof state.customersStore.customerViews;
  defaultCustomerViews: typeof state.customersStore.defaultCustomerViews;
  setDisplayedCustomerListingTabs: typeof dispatch.customersStore.setDisplayedCustomerListingTabs;
  displayedCustomerListingTabs: typeof state.customersStore.displayedCustomerListingTabs;
  setCustomerListingActiveTab: typeof dispatch.customersStore.setCustomerListingActiveTab;
  customerListingActiveTab: typeof state.customersStore.customerListingActiveTab;
  refreshList: typeof state.customersStore.refreshList;
  customers?: ICustomerListItem[];
  doSetNewBookingData?: any;
  newBookingData?: INewBookingData | any;
  history: H.History;
  doAddCustomerView: (action: any) => Promise<any>;
  doUpdateCustomerViewTab: (action: any) => Promise<any>;
  doDuplicateCustomerView: (action: any) => Promise<any>;
  doDeleteCustomerView: (action: any) => Promise<any>;
}

interface ICustomerListingPanelState {
  isLoading: boolean;
  isLoadingInfiniteScrolling: boolean;
  isFilterOpen: boolean;
  isSearching: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  displayedTabs: string[];
  selectedRows: string[];
  isAllRowsSelected: boolean;
  showCreateBookingModal: boolean;
  showCustomerViewModal: boolean;
  showCustomerViewPopover: boolean;
  showTabIconPopover: string;
  viewModalType: CustomViewsModalType;
  customerViews: any[];
}

const availableFilters = [
  FilterType.BLOCKED_SUPPORT_WORKER,
  FilterType.CUSTOMER_ALERTS,
  FilterType.CUSTOMER_CONNECTION_STATUS,
  FilterType.CUSTOMER_MANAGED_BY,
  FilterType.CUSTOMER_STATUS,
  FilterType.MANAGEMENT_METHOD,
  FilterType.PAYMENT_SOURCES,
  FilterType.PERMANENT_CONDITIONS,
  FilterType.PINNED_ALERTS,
  FilterType.PREFERRED_SUPPORT_WORKER,
  FilterType.USER_LOCATION_BY_STATE,
  FilterType.CASE_MANAGER,
  FilterType.SUPPORT_COORDINATOR,
];

const defaultFilterValue = [
  { [FilterType.CUSTOMER]: [] },
  { [FilterType.SERVICE]: [] },
  { [FilterType.CUSTOMER_STATUS]: [] },
];

const ListEmptyState = () => (
  <div className="flex-1 bg-white  align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No Customer found.
    </Text>{' '}
    <br /> <br />
    <Text color="secondary">All customers under this filter will appear here.</Text>
    <Text color="secondary">Try adjusting your filter, or clicking on another tab.</Text>
  </div>
);

class CustomerListingPanel extends Component<ICustomerListingPanelProps, ICustomerListingPanelState> {
  state = {
    isLoading: false,
    isLoadingInfiniteScrolling: false,
    isFilterOpen: false,
    isSearching: false,
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    displayedTabs: [],
    selectedRows: [],
    isAllRowsSelected: false,
    showCreateBookingModal: false,
    viewModalType: CustomViewsModalType.CREATE,
    showCustomerViewModal: false,
    showCustomerViewPopover: false,
    showTabIconPopover: '',
    customerViews: [],
  };

  private _loadContent = async () => {
    const {
      doGetCustomers,
      doFetchCustomerViews,
      setCustomerListingActiveTab,
      setCustomers,
      setDisplayedCustomerListingTabs,
      defaultCustomerViews,
      displayedCustomerListingTabs,
      customerListingActiveTab,
      history,
    } = this.props;
    this.setState({ isLoading: true });
    doFetchCustomerViews({});
    const showActiveTab = _.get(history, 'location.state.showActiveTab');
    if (showActiveTab && customerListingActiveTab) {
      !customerListingActiveTab.isDefault &&
        (await setDisplayedCustomerListingTabs([...displayedCustomerListingTabs, customerListingActiveTab]));
    } else {
      setCustomerListingActiveTab(defaultCustomerViews[0]);
    }
    let appliedFilters = this._generateFilters();
    await this.props.setCustomerFilter(appliedFilters);
    setCustomers([]);
    await doGetCustomers({
      filters: this.props.customerFilter,
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
    });
    this.setState({ isLoading: false });
  };

  private _generateFilters = () => {
    const { customerListingActiveTab, defaultCustomerViews } = this.props;
    let activeTab = defaultCustomerViews[0];
    if (!_.isEmpty(customerListingActiveTab) && _.get(customerListingActiveTab, 'filterValue')) {
      activeTab = customerListingActiveTab;
    }
    return activeTab.filterValue
      .filter((filter) => !_.isEmpty(filter))
      .map((filter) => {
        const [[key, value]] = Object.entries(filter);
        return {
          filter: key,
          values: value || [],
          selectionLabel: CommonUtils.getFilterText(key, value),
        };
      });
  };

  private _goCustomerDetails = (customerID) => {
    const { history } = this.props;
    history.push(`/customer/details/${customerID}`);
  };

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setHasCustomerFilterChanged(true);
    this.props.setCustomerFilter(filters);
  };

  private _searchText = async (txt) => {
    const {
      doGetCustomers,
      setCustomerFilter,
      customerFilter,
      doGetCustomerParentGuardiansList,
      customerListingActiveTab,
    } = this.props;
    this.setState({ page: 1 });
    const filterSearchIndex = customerFilter.findIndex((filter) => filter['search']);
    filterSearchIndex > -1
      ? (customerFilter[filterSearchIndex] = { search: txt })
      : customerFilter.push({ search: txt });
    setCustomerFilter([...customerFilter]);
    if (_.get(customerListingActiveTab, 'isCustomer')) {
      await doGetCustomers({
        page: 1,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
        sortByRelevance: true,
      });
    } else {
      await doGetCustomerParentGuardiansList({
        page: 1,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
        sortByRelevance: true,
      });
    }
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _changeTab = async (selectedTabId) => {
    const {
      setCustomerFilter,
      customerFilter,
      setCustomerListingActiveTab,
      displayedCustomerListingTabs,
      defaultCustomerViews,
    } = this.props;
    const displayedTabs = [...defaultCustomerViews, ...displayedCustomerListingTabs];
    const activeTab = displayedTabs.find((tab) => tab.customViewId === selectedTabId);
    setCustomerListingActiveTab(activeTab);
    this.setState({ isLoading: true, page: 1, selectedRows: [] });
    setCustomerFilter({ ...customerFilter, userType: event });
    this.setState({ isLoading: false });
  };

  private _fetchMoreCustomers = async () => {
    const { doGetCustomers, doGetCustomerParentGuardiansList, customerListingActiveTab } = this.props;
    const nextPage = this.state.page + 1;
    this.setState({ isLoadingInfiniteScrolling: true, page: nextPage });

    if (_.get(customerListingActiveTab, 'isCustomer')) {
      await doGetCustomers({
        filters: this.props.customerFilter,
        page: nextPage,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
      });
    } else {
      await doGetCustomerParentGuardiansList({
        filters: this.props.customerFilter,
        page: nextPage,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp,
      });
    }
    this.setState({ isLoadingInfiniteScrolling: false });
  };

  private _renderViewTabIcon = (tab: ICustomView) => {
    const togglePopOver = (value) => {
      const showTabIconPopover = value ? tab.customViewId : '';
      this.setState({ showTabIconPopover });
    };
    return (
      <Popover
        content={
          <CustomerViewTabPopover
            onHidePopOver={togglePopOver}
            currentTab={tab}
            onShowCustomerViewModal={this._openCustomerViewModal}
          />
        }
        visible={this.state.showTabIconPopover === tab.customViewId}
        onVisibleChange={togglePopOver}
        placement="bottom"
        trigger="click"
      >
        <Icon className="ml-small mr-none" type="caret-down" />
      </Popover>
    );
  };

  private _toggleAddViewPopOverChange = (visible) => {
    this.setState({ showCustomerViewPopover: visible });
  };

  private _renderExtraTabs = () => {
    const {
      displayedCustomerListingTabs,
      customerViews,
      setDisplayedCustomerListingTabs,
      setCustomerListingActiveTab,
    } = this.props;
    return (
      <>
        <Popover
          content={
            <CustomViewPopover
              pageViews={customerViews}
              displayedPageListingTabs={displayedCustomerListingTabs}
              onCreateNewView={() => this._openCustomerViewModal(CustomViewsModalType.CREATE)}
              onDisplayTab={this._toggleAddViewPopOverChange}
              setDisplayedPageListingTabs={setDisplayedCustomerListingTabs}
              setPageListingActiveTab={setCustomerListingActiveTab}
            />
          }
          trigger="click"
          visible={this.state.showCustomerViewPopover}
          onVisibleChange={this._toggleAddViewPopOverChange}
        >
          <HyperlinkButton className="ph-large">+ Add view</HyperlinkButton>
        </Popover>
        <HyperlinkButton className="ph-large" onClick={this._goCustomerAllViews}>
          All views
        </HyperlinkButton>
      </>
    );
  };

  private _goCustomerAllViews = () => {
    const { history } = this.props;
    history.push(`/customers/all-views`);
  };

  private _openCustomerViewModal = (type: CustomViewsModalType) => {
    this.setState({ showCustomerViewPopover: false });
    this.setState({ viewModalType: type, showCustomerViewModal: true });
  };

  private _closeCustomerViewModal = () => {
    this.setState({ viewModalType: CustomViewsModalType.CREATE, showCustomerViewModal: false });
  };

  private _updateSelectedRows = (e, customerId: string) => {
    e.stopPropagation();
    const { selectedRows } = this.state;
    const customerIdIndex = selectedRows.indexOf(customerId);
    const isExisting = customerIdIndex > -1;
    if (isExisting) {
      selectedRows.splice(customerIdIndex, 1);
    } else {
      selectedRows.push(customerId);
    }
    const isAllRowsSelected = selectedRows.length > 0 && selectedRows.length === this.props.filteredCustomers.length;
    this.setState({ isAllRowsSelected, selectedRows });
  };

  private _selectAllRows() {
    const selectedRows = this.props.filteredCustomers.map((customer) => customer.userId);
    this.setState({ isAllRowsSelected: true, selectedRows });
  }

  private _clearSelectedRows = () => {
    this.setState({ isAllRowsSelected: false, selectedRows: [] });
  };

  private _toggleSelectedRows = () => {
    if (this.state.selectedRows.length < this.props.filteredCustomers.length) {
      this._selectAllRows();
    } else {
      this._clearSelectedRows();
    }
  };

  private _openBookingModal = async () => {
    const selectedCustomerId = this.state.selectedRows[0];
    const { customers, newBookingData, doSetNewBookingData, doGetCustomer } = this.props;
    const selectedCustomer = _.find(customers, (customer) => customer.userId === selectedCustomerId);
    if (selectedCustomer) {
      await doGetCustomer({ userId: selectedCustomerId });
      await doSetNewBookingData({ ...newBookingData, selectedCustomerId, selectedCustomer });
    }
    this.setState({ showCreateBookingModal: true });
  };

  private _closeBookingModal = () => this.setState({ showCreateBookingModal: false });

  private _onSaveView = (tab: ICustomView) => {
    if (tab && tab.isOwner) {
      this._openCustomerViewModal(CustomViewsModalType.SAVE_VIEW);
    } else if (tab && tab.isDefault) {
      this._openCustomerViewModal(CustomViewsModalType.SAVE_DEFAULT_VIEW);
    } else {
      this._openCustomerViewModal(CustomViewsModalType.SAVE_AS_NEW_COPY_FROM_OTHERS);
    }
  };

  private _customerAlertDescription(alertType: CustomerAlertType, numberOfAlerts: number) {
    const agreements = numberOfAlerts !== 1 ? 'agreements' : 'agreement';
    const documents = numberOfAlerts !== 1 ? 'documents' : 'document';

    if (alertType === CustomerAlertType.OverBudgetActual) {
      return `service ${agreements} exceed quote amount.`;
    }
    if (alertType === CustomerAlertType.OverBudgetForecasted) {
      return `service ${agreements} is forcasted to exceed quote amount.`;
    }
    if (alertType === CustomerAlertType.ServiceAgreementExpired) {
      return `No active/upcoming service ${agreements}.`;
    }
    if (alertType === CustomerAlertType.ServiceAgreementExpiringSoon) {
      return `service ${agreements} expiring soon.`;
    }
    if (alertType === CustomerAlertType.DocumentExpired) {
      return `${documents} expired.`;
    }
    if (alertType === CustomerAlertType.DocumentExpiringSoon) {
      return `${documents} expiring soon.`;
    }
  }

  private _renderCustomerAlertPopoverContent(alerts: ICustomerAlert[]) {
    return alerts.map(({ alertType, numberOfAlerts }) => (
      <>
        {alertType === CustomerAlertType.ServiceAgreementExpired ? '' : <b>{numberOfAlerts}</b>}{' '}
        {this._customerAlertDescription(alertType, numberOfAlerts)} <br />
      </>
    ));
  }

  componentDidMount() {
    this.props.setSelectedSideNavMenuKeys(['/customers']);
    this._loadContent();
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const {
      doGetCustomers,
      setCustomerFilter,
      doGetCustomerParentGuardiansList,
      setCustomers,
      filteredCustomers,
      customerListingActiveTab,
      refreshList,
    } = this.props;

    const shouldUpdateSelectedRows =
      !_.isEqual(prevProps.filteredCustomers, filteredCustomers) && this.state.isAllRowsSelected;
    if (shouldUpdateSelectedRows) {
      this._selectAllRows();
    }

    if (!_.isEqual(prevProps.customerListingActiveTab, customerListingActiveTab)) {
      await setCustomerFilter(this._generateFilters());
    }

    if (!_.isEqual(prevProps.customerFilter, this.props.customerFilter)) {
      const pageTimestamp = new Date();
      this.setState({ isLoading: true, page: 1, pageTimestamp });
      setCustomers([]);
      if (_.get(customerListingActiveTab, 'isCustomer')) {
        await doGetCustomers({
          filters: this.props.customerFilter,
          page: 1,
          pageSize: this.state.pageSize,
          pageTimestamp,
        });
      } else {
        await doGetCustomerParentGuardiansList({
          filters: this.props.customerFilter,
          page: 1,
          pageSize: this.state.pageSize,
          pageTimestamp,
        });
      }
      this.setState({ isLoading: false });
    }
    if (!_.isEqual(prevProps.refreshList, refreshList)) {
      const pageTimestamp = new Date();
      this.setState({ isLoading: true, page: 1, pageTimestamp });
      setCustomers([]);
      if (_.get(customerListingActiveTab, 'isCustomer')) {
        await doGetCustomers({
          filters: this.props.customerFilter,
          page: this.state.page,
          pageSize: this.state.pageSize,
          pageTimestamp,
        });
      } else {
        await doGetCustomerParentGuardiansList({
          filters: this.props.customerFilter,
          page: 1,
          pageSize: this.state.pageSize,
          pageTimestamp,
        });
      }
      this.setState({ isLoading: false });
      this.props.setRefreshList(false);
    }
  };

  componentWillUnmount() {
    const { setCustomerFilter, setDisplayedCustomerListingTabs } = this.props;
    setCustomerFilter([]);
    setDisplayedCustomerListingTabs([]);
  }

  render() {
    const {
      filteredCustomers,
      portalUser,
      displayedCustomerListingTabs,
      defaultCustomerViews,
      customerListingActiveTab,
      customerViews,
      customerFilter,
      doAddCustomerView,
      doDeleteCustomerView,
      doUpdateCustomerViewTab,
      doDuplicateCustomerView,
      setCustomerFilter,
    } = this.props;
    const { isAllRowsSelected, selectedRows, viewModalType } = this.state;
    const renderExtraTabs = this._renderExtraTabs();
    const paymentSourceTagColors = {
      NDIS: {
        backgroundColor: 'bg-quaternary',
        textColor: 'secondary',
        borderColor: '#696969',
        hasBorder: true,
      },
      VCP: {
        backgroundColor: 'bg-quaternary',
        textColor: 'secondary',
        borderColor: '#696969',
        hasBorder: true,
      },
    };
    const isNotGuardian = (selectedCustomer) => selectedCustomer.isDependent || selectedCustomer.isIndependent;
    const generateActiveTabClassName = (activeTab, tab) =>
      _.get(activeTab, 'customViewId') === tab.customViewId ? `text-color-blue-action` : 'text-color-secondary';

    return (
      <div className="bg-white width-full mt-large">
        <CreateNewBookingModal
          isOpen={this.state.showCreateBookingModal}
          history={this.props.history}
          closeCreateBookingModal={this._closeBookingModal}
        />
        <CustomViewsModal
          isOpen={this.state.showCustomerViewModal}
          onCloseViewModal={this._closeCustomerViewModal}
          onUpdateViewModal={this._openCustomerViewModal}
          type={viewModalType}
          pageViews={customerViews}
          pageFilter={customerFilter}
          pageListingActiveTab={customerListingActiveTab}
          defaultViews={defaultCustomerViews}
          defaultFilterValue={defaultFilterValue}
          setPageFilter={setCustomerFilter}
          doAddView={doAddCustomerView}
          doDeleteView={doDeleteCustomerView}
          doUpdateViewTab={doUpdateCustomerViewTab}
          doDuplicateView={doDuplicateCustomerView}
        />
        <Tabs
          type="card"
          tabBarExtraContent={renderExtraTabs}
          activeKey={_.get(customerListingActiveTab, 'customViewId')}
          defaultActiveKey="CUSTOMERS"
          animated={true}
          onChange={this._changeTab}
        >
          {defaultCustomerViews.map((tab) => {
            return (
              <TabPane
                tab={<span className={generateActiveTabClassName(customerListingActiveTab, tab)}>{tab.name}</span>}
                key={tab.customViewId}
              />
            );
          })}
          {displayedCustomerListingTabs.map((tab: ICustomView) => {
            return (
              <TabPane
                tab={
                  <span className={generateActiveTabClassName(customerListingActiveTab, tab)}>
                    {tab.isPinned && <Icon type="pushpin" theme={'filled'} className={`mr-small`} />}
                    {tab.name}
                    {this._renderViewTabIcon(tab)}
                  </span>
                }
                key={tab.customViewId}
              />
            );
          })}
        </Tabs>
        <div className="flex-row align-center justify-start">
          <div>
            <Search
              placeholder="Search for..."
              onChange={this._onEnterSearchText}
              loading={this.state.isSearching}
              style={{ width: '250px', height: '32px' }}
              allowClear={true}
            />
          </div>
          <div className="ml-small">
            <FilterSection
              availableFilters={availableFilters}
              filters={this.props.customerFilter}
              onChangeFilter={this._onChangeFilter}
              displayTimezone={portalUser.timezone}
              displayMoreFilter={_.get(customerListingActiveTab, 'isCustomer') ? true : false}
            />
          </div>
          <div className="flex-row flex-grow justify-end">
            <SecondaryButton
              className="text-color-blue-action mr-x-small ml-small"
              onClick={() => this._onSaveView(customerListingActiveTab)}
            >
              <Icon className="ml-small mr-none" type="save" /> Save view
            </SecondaryButton>
          </div>
        </div>
        {selectedRows.length > 0 && (
          <Alert
            className="anim-slide-down shadow-container bordered-none bg-blue-lightest mb-medium"
            message={
              <div className="flex-row align-center p-x-small">
                <b>{selectedRows.length} customer selected</b>
                <HyperlinkButton className="mh-large" onClick={this._clearSelectedRows}>
                  Clear selection
                </HyperlinkButton>
                <div className="flex-grow"></div>
                {selectedRows.length === 1 && (
                  <SecondaryButton onClick={this._openBookingModal}>Create booking</SecondaryButton>
                )}
              </div>
            }
            type="info"
          />
        )}
        <GridHeader bordered containerClassName="border-width-medium border-secondary">
          {_.get(customerListingActiveTab, 'isCustomer') ? (
            <>
              <Col span={1} className=" bg-white">
                <Checkbox
                  indeterminate={!!selectedRows.length && !isAllRowsSelected}
                  checked={!!selectedRows.length}
                  onClick={this._toggleSelectedRows}
                />
              </Col>
              <Col span={5} className=" bg-white">
                <FieldLabel text="Customer" />
              </Col>
              <Col span={3} className=" bg-white">
                <FieldLabel text="status" />
              </Col>
              <Col span={3} className=" bg-white">
                <FieldLabel text="funding" />
              </Col>
              <Col span={4} className=" bg-white">
                <FieldLabel text="location" />
              </Col>
              <Col span={3} className=" bg-white">
                <FieldLabel text="Alerts" />
              </Col>
              <Col span={5} className=" bg-white">
                <FieldLabel text="legal guardian(s)" />
              </Col>
            </>
          ) : (
            <>
              <Col span={8} className=" bg-white">
                <FieldLabel text="legal guardian name" />
              </Col>
              <Col span={7} className=" bg-white">
                <FieldLabel text="connection status" />
              </Col>
              <Col span={8} className=" bg-white">
                <FieldLabel text="managed customers" />
              </Col>
            </>
          )}
        </GridHeader>
        {this.state.isLoading ? (
          <div className="">
            <div className="pv-large">
              <ProgressBar />
            </div>
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
          </div>
        ) : !_.isEmpty(filteredCustomers) ? (
          <InfiniteScrollLoading
            hasMore={filteredCustomers.length >= this.state.page * this.state.pageSize}
            loadingElementId={'content-container'}
            loadMore={this._fetchMoreCustomers}
            loaderColSpan={7}
            loadingOffSet={60}
          >
            {filteredCustomers.map((customer, index) => {
              return (
                <GridRow
                  key={index}
                  onClick={
                    PermissionUtils.validatePermission('ViewCustomerProfile', portalUser.permissions.permissionRoles)
                      ? () => this._goCustomerDetails(customer.userId)
                      : () => false
                  }
                >
                  {_.get(customerListingActiveTab, 'isCustomer') ? (
                    <>
                      <Col span={1}>
                        {isNotGuardian(customer) && (
                          <Checkbox
                            checked={selectedRows.indexOf(customer.userId) > -1}
                            onClick={(e) => this._updateSelectedRows(e, customer.userId)}
                          />
                        )}
                      </Col>
                      <Col span={5}>
                        <div className="flex-row align-center cursor-pointer">
                          <div>
                            <Avatar className="mr-medium" size="large" icon="user" src={customer.attachmentUrl} />
                          </div>
                          <div
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'pre-line',
                            }}
                          >
                            <Text>{customer.firstName + ' ' + customer.lastName}</Text>
                          </div>

                          {customer.pinnedAlertNumber > 0 && (
                            <PinnedAlertIcon
                              pinnedAlertNumber={customer.pinnedAlertNumber}
                              customerUserId={customer.userId}
                            />
                          )}
                        </div>
                      </Col>
                      <Col span={3}>
                        <CustomerStatusTags
                          key={customer.userId}
                          customerStatus={customer.customerStatus ? customer.customerStatus : 'CONNECTED'}
                        />
                      </Col>
                      <Col span={3}>
                        {!_.isEmpty(customer.serviceAgreementPaymentSourceType) ? (
                          _.map(customer.serviceAgreementPaymentSourceType, (type) => (
                            <PaymentSourceTag
                              className={'mr-small mv-x-small'}
                              paymentSource={type}
                              size={'default'}
                              overrideDefaultTagColors={paymentSourceTagColors}
                              textClassName={'text-weight-regular'}
                            />
                          ))
                        ) : (
                          <Text>-</Text>
                        )}
                      </Col>
                      <Col span={4}>
                        {customer.locality ? (
                          <Text>{customer.locality}</Text>
                        ) : (
                          <Text color={'tertiary'}>Locality not set</Text>
                        )}{' '}
                      </Col>
                      <Col span={3}>
                        {customer && !_.isEmpty(customer.customerAlerts) ? (
                          <Popover
                            content={this._renderCustomerAlertPopoverContent(customer.customerAlerts)}
                            title={<b>Alerts</b>}
                          >
                            <Text className="cursor-pointer">
                              <Icon
                                className="mh-small text-color-red-light text-size-x-large"
                                type="minus-circle"
                                theme="filled"
                              />{' '}
                              <b>{customer.customerAlerts.length}</b>{' '}
                              {customer.customerAlerts.length !== 1 ? 'alerts' : 'alert'}
                            </Text>
                          </Popover>
                        ) : (
                          '-'
                        )}
                      </Col>
                      <Col span={5}>
                        {!_.isEmpty(customer.guardians) ? (
                          <>
                            <div className="flex-row flex-wrap justify-between">
                              {_.chain(customer.guardians)
                                .slice(0, 2)
                                .map((guardian, index) => {
                                  return (
                                    <div
                                      key={guardian.userId + index}
                                      className="flex-row align-center mr-medium mv-x2-small"
                                    >
                                      <div>
                                        <Avatar
                                          className="mr-small"
                                          size="small"
                                          icon="user"
                                          src={guardian.attachmentUrl}
                                        />
                                      </div>
                                      {PermissionUtils.validatePermission(
                                        'ViewCustomerProfile',
                                        portalUser.permissions.permissionRoles,
                                      ) ? (
                                        <HyperlinkButton
                                          color={'black'}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            this._goCustomerDetails(guardian.userId);
                                          }}
                                        >
                                          {guardian.firstName + ' ' + guardian.lastName}
                                        </HyperlinkButton>
                                      ) : (
                                        <Text>{guardian.firstName + ' ' + guardian.lastName}</Text>
                                      )}
                                    </div>
                                  );
                                })
                                .value()}
                            </div>
                            <div>
                              {customer.guardians && customer.guardians.length > 2 && (
                                <Text color={'secondary'}>({customer.guardians.length - 2} more)</Text>
                              )}
                            </div>
                          </>
                        ) : (
                          <Text>-</Text>
                        )}
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={8}>
                        <div
                          className="flex-row align-center cursor-pointer"
                          onClick={
                            PermissionUtils.validatePermission(
                              'ViewCustomerProfile',
                              portalUser.permissions.permissionRoles,
                            )
                              ? () => this._goCustomerDetails(customer.userId)
                              : () => false
                          }
                        >
                          <div>
                            <Avatar className="mr-medium" size="large" icon="user" src={customer.attachmentUrl} />
                          </div>
                          <div
                            style={{
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'pre-line',
                            }}
                          >
                            <Text>{customer.firstName + ' ' + customer.lastName}</Text>
                          </div>

                          {customer.pinnedAlertNumber > 0 && (
                            <PinnedAlertIcon
                              pinnedAlertNumber={customer.pinnedAlertNumber}
                              customerUserId={customer.userId}
                            />
                          )}
                        </div>
                      </Col>
                      <Col span={7}>
                        <CustomerConnectionStatusTag customerStatus={customer.connected} />
                      </Col>
                      <Col span={8}>
                        {!_.isEmpty(customer.dependentUsers) ? (
                          <div className="flex-row">
                            {_.chain(customer.dependentUsers)
                              .slice(0, 2)
                              .map((dependentUser) => {
                                return (
                                  <div
                                    className="flex-row align-center cursor-pointer mr-medium"
                                    onClick={
                                      PermissionUtils.validatePermission(
                                        'ViewCustomerProfile',
                                        portalUser.permissions.permissionRoles,
                                      )
                                        ? () => this._goCustomerDetails(dependentUser.userId)
                                        : () => false
                                    }
                                  >
                                    <div>
                                      <Avatar
                                        className="mr-small"
                                        size="small"
                                        icon="user"
                                        src={dependentUser.attachmentUrl}
                                      />
                                    </div>
                                    <Text>{dependentUser.firstName + ' ' + dependentUser.lastName}</Text>
                                  </div>
                                );
                              })
                              .value()}
                            <div>
                              {customer.dependentUsers && customer.dependentUsers.length > 2 && (
                                <Text>({customer.dependentUsers.length - 2} more)</Text>
                              )}
                            </div>
                          </div>
                        ) : (
                          <Text>-</Text>
                        )}
                      </Col>
                    </>
                  )}
                </GridRow>
              );
            })}
            <div id="scroll" />
          </InfiniteScrollLoading>
        ) : (
          <div style={{ borderBottom: '0px solid' }}>
            <ListEmptyState />
          </div>
        )}
        {this.state.isLoadingInfiniteScrolling && (
          <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  filteredCustomers: state.customersStore.filteredCustomers,
  customers: state.customersStore.customers,
  customerFilter: state.customersStore.customerFilter,
  refreshList: state.customersStore.refreshList,
  portalUser: state.authStore.portalUser,
  newBookingData: state.bookingsStore.newBookingData,
  customerViews: state.customersStore.customerViews,
  displayedCustomerListingTabs: state.customersStore.displayedCustomerListingTabs,
  customerListingActiveTab: state.customersStore.customerListingActiveTab,
  defaultCustomerViews: state.customersStore.defaultCustomerViews,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetCustomers: dispatch.customersStore.doGetCustomers,
  doGetCustomerParentGuardiansList: dispatch.customersStore.doGetCustomerParentGuardiansList,
  setCustomerFilter: dispatch.customersStore.setCustomerFilter,
  setHasCustomerFilterChanged: dispatch.customersStore.setHasCustomerFilterChanged,
  setRefreshList: dispatch.customersStore.setRefreshList,
  setCustomers: dispatch.customersStore.setCustomers,
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys,
  doSetNewBookingData: dispatch.bookingsStore.doSetNewBookingData,
  doGetCustomer: dispatch.customersStore.doGetCustomer,
  doFetchCustomerViews: dispatch.customersStore.doFetchCustomerViews,
  doAddCustomerView: dispatch.customersStore.doAddCustomerView,
  doUpdateCustomerViewTab: dispatch.customersStore.doUpdateCustomerView,
  doDuplicateCustomerView: dispatch.customersStore.doDuplicateCustomerView,
  doDeleteCustomerView: dispatch.customersStore.doDeleteCustomerView,
  setDisplayedCustomerListingTabs: dispatch.customersStore.setDisplayedCustomerListingTabs,
  setCustomerListingActiveTab: dispatch.customersStore.setCustomerListingActiveTab,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<ICustomerListingPanelProps>()(CustomerListingPanel));
