import React from 'react';
import { Tabs, notification } from 'antd';
import _ from 'lodash';
import { IBillingLineItem } from 'interfaces/booking-interfaces';

import { FieldLabel, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import TextTag from 'common-components/tags/TextTag';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';

import { IGroupBookingDetails } from 'views/group-services/manage-booking-modal/interfaces/group-booking-interfaces';
import { LineItemsDisplaySection } from 'views/group-services/manage-booking-modal/panels/billings/LineItemsDisplaySection';
import { PaymentMethods } from 'utilities/enum-utils';
import AddEditGroupBookingLineItemModal from '../../common-components/AddEditGroupBookingLineItemModal';
import { dispatch } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { ModalLoading } from 'views/group-services/manage-booking-modal/common-components/ModalLoading';

interface IBillingsPanelProps {
  booking: any;
  doDeleteGroupBookingSingleBillingItem: typeof dispatch.groupBookingsStore.doDeleteGroupBookingSingleBillingItem;
  isLoading: boolean;
}

interface BillingsPanelState {
  isAddEditModalOpen: boolean;
  isDeleteModalOpen: boolean;
  currentBookingId: string;
  targetLineItem?: IBillingLineItem;
  editBookingType: 'before' | 'after' | string;
}

export class BillingsPanel extends React.Component<IBillingsPanelProps, BillingsPanelState> {
  state = {
    isDeleteModalOpen: false,
    targetLineItem: null,
    isAddEditModalOpen: false,
    currentBookingId: '',
    editBookingType: ''
  };

  //region Action handlers
  onEditLineItem = (targetLineItem, bookingId, editBookingType) => {
    this.onOpenEditModal({ targetLineItem, bookingId, editBookingType });
  };
  onDeleteLineItem = (targetLineItem, bookingId) => {
    this.onOpenDeleteModal({ targetLineItem, bookingId });
  };
  //endregion

  //region Modal handlers
  onOpenEditModal = ({ targetLineItem, bookingId, editBookingType = '' }) =>
    this.setState({
      isAddEditModalOpen: true,
      targetLineItem,
      currentBookingId: bookingId,
      editBookingType: editBookingType
    });
  onCloseEditModal = () => this.setState({ isAddEditModalOpen: false, currentBookingId: '' });

  onOpenAddModal = (bookingId) =>
    this.setState({ isAddEditModalOpen: true, targetLineItem: null, currentBookingId: bookingId });
  onCloseAddModal = () => this.setState({ isAddEditModalOpen: false, targetLineItem: null });

  onOpenDeleteModal = ({ targetLineItem, bookingId }) =>
    this.setState({ isDeleteModalOpen: true, targetLineItem, currentBookingId: bookingId });
  onCloseDeleteModal = () => this.setState({ isDeleteModalOpen: false, currentBookingId: '' });
  //endregion

  render() {
    const { booking, isLoading } = this.props;

    // Loading
    if (isLoading) {
      return <ModalLoading text={'Fetching billings...'} />;
    }

    const { billingLineItems, bookingErrors, fundedCategories } = booking;

    const sessionLineItems = [];
    const transportBeforeLineItems = [];
    const transportAfterLineItems = [];

    _.forEach(billingLineItems, (lineItem) => {
      switch (lineItem.lineItemAttendanceType) {
        case 'PRE_SESSION':
          transportBeforeLineItems.push(lineItem);
          break;
        case 'POST_SESSION':
          transportAfterLineItems.push(lineItem);
          break;
        case 'SESSION':
          sessionLineItems.push(lineItem);
          break;
        default:
          sessionLineItems.push(lineItem);
      }
    });

    return (
      <>
        {/* Main content */}
        <div className="anim-fade-in-fast">
          <section>
            <Tabs defaultActiveKey={'session'} size={'small'} type={'card'}>
              <Tabs.TabPane tab={<Text className="select-none">Session</Text>} key={'session'}>
                <LineItemsDisplaySection
                  tabType={'session'}
                  lineItems={sessionLineItems}
                  onEditLineItem={this.onEditLineItem}
                  onDeleteLineItem={this.onDeleteLineItem}
                  onOpenAddModal={this.onOpenAddModal}
                  bookingErrors={bookingErrors}
                  fundedCategories={fundedCategories}
                  paymentStatus={booking.paymentStatus}
                  bookingStatus={booking.status}
                  attendanceId={booking.bookingId}
                />
              </Tabs.TabPane>

              {!_.isEmpty(transportBeforeLineItems) && (
                <Tabs.TabPane tab={<Text className="select-none">Transport before</Text>} key={'transport-before'}>
                  <LineItemsDisplaySection
                    tabType="transport-before"
                    lineItems={transportBeforeLineItems}
                    onEditLineItem={(targetBillingItem, bookingId) =>
                      this.onEditLineItem(targetBillingItem, bookingId, 'before')
                    }
                    onDeleteLineItem={this.onDeleteLineItem}
                    onOpenAddModal={this.onOpenAddModal}
                    bookingErrors={bookingErrors}
                    fundedCategories={fundedCategories}
                    paymentStatus={booking.transportBeforeBooking.paymentStatus}
                    bookingStatus={booking.status}
                    attendanceId={booking.bookingId}
                  />
                </Tabs.TabPane>
              )}
              {!_.isEmpty(transportAfterLineItems) && (
                <Tabs.TabPane tab={<Text className="select-none">Transport after</Text>} key={'transport-after'}>
                  <LineItemsDisplaySection
                    tabType="transport-after"
                    lineItems={transportAfterLineItems}
                    onEditLineItem={(targetBillingItem, bookingId) =>
                      this.onEditLineItem(targetBillingItem, bookingId, 'after')
                    }
                    onDeleteLineItem={this.onDeleteLineItem}
                    onOpenAddModal={this.onOpenAddModal}
                    bookingErrors={bookingErrors}
                    fundedCategories={fundedCategories}
                    paymentStatus={booking.transportAfterBooking.paymentStatus}
                    bookingStatus={booking.status}
                    attendanceId={booking.bookingId}
                  />
                </Tabs.TabPane>
              )}
            </Tabs>
          </section>
        </div>

        {this.state.isDeleteModalOpen && (
          <DeleteLineItemModal
            isOpen={this.state.isDeleteModalOpen}
            onClose={this.onCloseDeleteModal}
            booking={booking}
            doDeleteGroupBookingSingleBillingItem={this.props.doDeleteGroupBookingSingleBillingItem}
            lineItem={this.state.targetLineItem}
            currentBookingId={this.state.currentBookingId}
          />
        )}

        <AddEditGroupBookingLineItemModal
          currentBookingId={this.state.currentBookingId}
          isOpen={this.state.isAddEditModalOpen}
          onClose={this.onCloseAddModal}
          editingLineItem={this.state.targetLineItem}
          editBookingType={this.state.editBookingType}
        />
      </>
    );
  }
}

class DeleteLineItemModal extends React.Component<
  {
    isOpen: boolean;
    onClose: any;
    lineItem?: any;
    booking: any;
    currentBookingId: string;
    doDeleteGroupBookingSingleBillingItem: typeof dispatch.groupBookingsStore.doDeleteGroupBookingSingleBillingItem;
  },
  { isLoading: boolean }
> {
  state = {
    isLoading: false
  };

  getNdisUnitLabel = (unit) => {
    return unit === 'H' ? 'hr.' : 'EA' ? 'each' : '';
  };

  getPaymentMethodTag = (paymentMethod) => {
    if (paymentMethod === PaymentMethods.NDIA) {
      return <TextTag content={'NDIS'} color={'violet'} size="small" />;
    } else if (paymentMethod === PaymentMethods.PLAN) {
      return <TextTag content={'Plan'} color={'green'} size="small" />;
    } else if (paymentMethod === PaymentMethods.SELF) {
      return <TextTag content={'Self'} color={'blue'} size="small" />;
    }
  };

  private _onSave = async () => {
    try {
      this.setState({ isLoading: true });
      await this.props.doDeleteGroupBookingSingleBillingItem({
        bookingId: this.props.currentBookingId,
        bookingBillingLineItemId: this.props.lineItem.bookingBillingLineItemId
      });
      notification.success({ message: 'Successfully deleted line item.' });
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
    this.props.onClose();
  };

  private _onCancel = () => {
    this.props.onClose();
  };

  render() {
    const { onClose, isOpen, lineItem } = this.props;
    return (
      <ActionModal onClose={onClose} isOpen={isOpen} title={'Delete line item from booking'} width="x-large">
        <section className="line-height-135 mb-medium">
          <Text lineHeight={135}>
            You are about to delete the following line item. <br />
            <br /> Are you sure you want to continue?
          </Text>
        </section>

        <section className="mb-medium">
          <div className="mb-x2-small">
            <FieldLabel text="Line item details" />
          </div>
          <div className="flex-row ph-small pv-small bordered border-standard-gray rounded-big align-center">
            {/* line item */}
            <div className="pv-small pl-small pr-medium" style={{ width: '288px' }}>
              <Text size="regular" color="secondary" lineHeight={120}>
                {lineItem.supportItemNumber}
              </Text>
              <br />
              <Text lineHeight={120}>{lineItem.supportItemName}</Text>
            </div>

            {/* type  */}
            <div className="p-small flex-1 line-height-135">
              <Text lineHeight={135}>{lineItem.claimType ? lineItem.claimType : 'Standard Support'}</Text> <br />
              {this.getPaymentMethodTag(lineItem.paymentMethod)}
            </div>

            {/* cost  */}
            <div className="p-small flex-1">
              <Text>
                {CommonUtils.formatPrice(lineItem.unitPrice)} x ${lineItem.qty} {this.getNdisUnitLabel(lineItem.unit)}
              </Text>
            </div>

            {/* total */}
            <div className="p-small text-align-right flex-1">
              <Text>{CommonUtils.formatPrice(lineItem.total)}</Text>
            </div>
          </div>
        </section>

        <ActionModalFooter>
          <SecondaryButton className="mr-medium" size="large" onClick={this._onCancel} disabled={this.state.isLoading}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={this._onSave} disabled={this.state.isLoading}>
            Save
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}
