import React, { Component } from 'react';
import { Col, Form, Icon, Row, Avatar } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';

import { IAddCustomerDetailWizard } from 'src/interfaces/customer-interfaces';
import { IRootDispatch, IRootState, state } from 'stores/rematch/root-store';

interface IAddCustomerStepSuccessProps extends FormComponentProps {
  addNewCustomer: () => void;
  goToCustomer: (userId?: string) => void;
  selectedCustomerUserId: typeof state.customersStore.selectedCustomerUserId;
  addCustomerDetailWizard: typeof state.customersStore.addCustomerDetailWizard;
}

interface IAddCustomerStepSuccessState {
  customerDetails: IAddCustomerDetailWizard;
}

class AddCustomerStepSuccess extends Component<IAddCustomerStepSuccessProps, IAddCustomerStepSuccessState> {
  state = {
    customerDetails: this.props.addCustomerDetailWizard
  };
  render() {
    const { addNewCustomer, goToCustomer, selectedCustomerUserId } = this.props;
    const { customerDetails } = this.state;

    return (
      <>
        <div className="anim-slide-left pt-x3-large">
          <Row className="ph-x3-large">
            <Col span={6} style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div>
                <Paragraph size="x3-large" className="mb-medium" weight="bold">
                  Done
                </Paragraph>
                <Paragraph>You have successfully added a customer!</Paragraph>
              </div>
            </Col>
            <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
                <div className="mb-large ">
                  <Text className="text-color-blue-action mb-large text-size-x2-large flex-row align-center">
                    <Icon className="mr-12 text-size-x4-large" type={'check-circle'} theme={'filled'} />
                    Customer successfully added
                  </Text>
                </div>
                <div className="mb-large">
                  <SubTitle>Customer</SubTitle>
                  <div className="flex-row align-center pt-small">
                    <Avatar size="large" icon="user" className="mr-medium" src={'TBD'} />
                    <Text weight="bold">{customerDetails.firstName + ' ' + customerDetails.lastName}</Text>
                  </div>
                </div>

                {customerDetails.hasGuardians ? (
                  <div className="mb-x5-large">
                    <SubTitle>Legal Guardian</SubTitle>
                    {_.map(customerDetails.guardians, (guardian) => {
                      return (
                        <div className="flex-row align-center pt-small">
                          <Avatar size="large" icon="user" className="mr-medium" src={guardian.attachmentUrl} />
                          <Text weight="bold">{guardian.firstName + ' ' + guardian.lastName}</Text>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <div className="mb-x5-large">
                    <SubTitle textClassName="mb-small">Invitation Status</SubTitle>
                    <Text>{customerDetails.isInviteToApp ? 'Invited' : 'Not invited'}</Text>
                  </div>
                )}
              </div>

              <div className="pv-medium width-full" style={{ position: 'sticky', bottom: 0 }}>
                <Row gutter={0} type="flex" align="middle" className="bg-transparent">
                  <Col className="bg-transparent" span={24}>
                    <div className="text-align-right pv-large">
                      <SecondaryButton size="large" className="mr-medium" onClick={addNewCustomer}>
                        Add another customer
                      </SecondaryButton>
                      <PrimaryButton size="large" onClick={() => goToCustomer(selectedCustomerUserId)}>
                        View profile
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  addCustomerDetailWizard: state.customersStore.addCustomerDetailWizard,
  selectedCustomerUserId: state.customersStore.selectedCustomerUserId
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setAddCustomerDetailWizard: dispatch.customersStore.setAddCustomerDetailWizard
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IAddCustomerStepSuccessProps>()(AddCustomerStepSuccess));
