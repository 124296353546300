import React, { Component } from 'react';
import { Form, Input, Skeleton } from 'antd';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, Text, Title } from 'common-components/typography';
import { PrimaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { ICustomerListItem } from 'interfaces/customer-interfaces';
import { Card, ProgressBar } from '@blueprintjs/core';
import CustomerItemRow from '../CustomerItemRow';
import SelectedCustomerRow from '../../../components/SelectedCustomerRow';
import { INewBookingData } from 'interfaces/booking-interfaces';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import PinnedAlertItem from 'common-components/pinned-alerts/PinnedAlertItem';
import { ServiceType } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';

const { isEmpty } = _;
const { Search } = Input;

const defaultBookingData: INewBookingData = {
  bookCustomNote: '',
  bookStartDate: null,
  bookStartTime: null,
  bookEndDate: null,
  bookEndTime: null,
  bookLocation: null,
  hasMileageClaims: false,
  isCancelledBooking: false,
  cancellationReason: '',
  cancellationCode: 'NSDT',
  isFutureBooking: true,
  mileageTravelled: 0,
  selectedCustomer: null,
  selectedCustomerId: null,
  selectedService: null,
  selectedServiceId: null,
  selectedWorker: null,
  selectedWorkerId: null
};

interface IStep1SelectCustomerPanelProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  doGetCustomersLite?: typeof dispatch.customersStore.doGetCustomersLite;
  doFetchServicesLite?: typeof dispatch.servicesStore.doFetchServicesLite;
  doSetNewBookingData?: any;
  customers?: ICustomerListItem[];
  newBookingData?: INewBookingData | any;
  doGetCustomer?: typeof dispatch.customersStore.doGetCustomer;
  setCustomerFilterLite: typeof dispatch.customersStore.setCustomerFilterLite;
  portalUser: typeof state.authStore.portalUser;
}

interface IStep1SelectCustomerPanelState {
  isLoading: boolean;
  isLoadingInfiniteScrolling: boolean;
  isSearching: boolean;
  searchCustomer: string;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
}

class Step1SelectCustomerPanel extends Component<IStep1SelectCustomerPanelProps, IStep1SelectCustomerPanelState> {
  state = {
    isLoading: true,
    isLoadingInfiniteScrolling: false,
    isSearching: false,
    searchCustomer: '',
    page: 1,
    pageSize: 10,
    pageTimestamp: new Date()
  };

  private _loadContent = async () => {
    const { doGetCustomersLite, doFetchServicesLite, setCustomerFilterLite } = this.props;
    try {
      // TODO: Optimize the call later.
      doFetchServicesLite({ serviceType: ServiceType.INDIVIDUAL });
      setCustomerFilterLite({});
      await doGetCustomersLite({
        page: this.state.page,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp
      });
      this.setState({ isLoading: false, isSearching: false });
    } catch (e) {
      this.setState({ isLoading: false, isSearching: false });
      throw e;
    }
  };

  private _onNextStep = () => {
    this.props.onNextStep();
  };

  private _searchCustomer = async () => {
    let payload = { search: this.state.searchCustomer, sort: [['firstName', 'asc'], ['lastName', 'asc']] };
    const { doGetCustomersLite, setCustomerFilterLite } = this.props;
    this.setState({ isSearching: true, page: 1 });
    setCustomerFilterLite(payload);
    await doGetCustomersLite({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      sortByRelevance: true
    });
    this.setState({ isSearching: false });
  };

  debounceSearch = _.debounce(this._searchCustomer, 250);

  componentDidMount() {
    const { newBookingData, doSetNewBookingData } = this.props;

    if (_.isEmpty(newBookingData)) {
      const freshBookingData: INewBookingData = { ...defaultBookingData };
      doSetNewBookingData(freshBookingData);
    }

    this._loadContent();
  }

  private _setSelectedCustomerId = async (selectedCustomerId) => {
    const { customers, newBookingData, doSetNewBookingData, doGetCustomer } = this.props;

    const selectedCustomer = _.find(customers, (customer) => customer.userId === selectedCustomerId);
    // TODO : When selected a customer, load the customer's location as well for the Step 3 screen.
    // load it in the background, do not await for it.

    if (selectedCustomer) {
      await doGetCustomer({ userId: selectedCustomerId });
      doSetNewBookingData({ ...newBookingData, selectedCustomerId, selectedCustomer });
    }
  };

  private _removeSelectedCustomer = async () => {
    const { doSetNewBookingData, newBookingData } = this.props;

    doSetNewBookingData({ ...newBookingData, selectedCustomerId: '', selectedCustomer: null });
  };

  private _handleSearchCustomerName = (event) => {
    let searchText = event.target.value;
    this.setState({ searchCustomer: searchText, isSearching: true });
    if (_.isEmpty(searchText)) {
      this._loadContent();
    } else if (searchText.length >= 3) {
      this.debounceSearch();
    }
  };

  private _fetchMoreCustomers = async () => {
    const { doGetCustomersLite } = this.props;
    await this.setState({ isLoadingInfiniteScrolling: true, page: this.state.page + 1 });
    await doGetCustomersLite({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp
    });
    this.setState({ isLoadingInfiniteScrolling: false });
  };

  render() {
    const { customers, newBookingData, portalUser } = this.props;

    const hasAccessToCustomerDetails = PermissionUtils.validatePermission(
      'ViewCustomerProfile',
      portalUser.permissions.permissionRoles
    );

    return (
      <div className="anim-slide-left">
        <Title level={2} weight="bolder" className="line-height-100">
          <span className="text-weight-regular">Create a new booking for a </span> customer
        </Title>

        <Paragraph>
          Select the <b>customer</b> you’d like to make a booking for.
        </Paragraph>

        <div className="flex-row mb-medium">
          <Search
            placeholder={'Enter customer name here...'}
            value={this.state.searchCustomer}
            onChange={this._handleSearchCustomerName}
            loading={this.state.isSearching}
            size="large"
            allowClear
          />
        </div>

        {newBookingData.selectedCustomerId !== '' ? (
          <>
            <SelectedCustomerRow
              selectedCustomerData={newBookingData.selectedCustomer}
              removeSelectedCustomer={this._removeSelectedCustomer}
            />
            {newBookingData.selectedCustomer && newBookingData.selectedCustomer.pinnedAlertNumber > 0 && (
              <PinnedAlertItem
                pinnedAlertNumber={newBookingData.selectedCustomer.pinnedAlertNumber}
                customerUserId={newBookingData.selectedCustomerId}
                isViewOnly={true}
              />
            )}
          </>
        ) : null}

        {/*<div>*/}
        <div className="ph-medium pb-small">
          <Text size="regular" color="secondary">
            Customers
          </Text>
        </div>

        {this.state.isLoading && (
          <Card className="anim-fade-in-fast">
            <div className="mb-small">
              <Text>Fetching customers...</Text>
            </div>
            <ProgressBar animate={true} />
          </Card>
        )}

        {!this.state.isLoading && (
          <div
            style={{ overflowY: 'auto', overflowX: 'hidden', height: '40vh' }}
            className="mb-x-large bordered anim-slide-down rounded"
            id="scroll-modal"
          >
            <InfiniteScrollLoading
              hasMore={customers.length >= this.state.page * this.state.pageSize}
              loadingElementId={'scroll-modal'}
              loadMore={this._fetchMoreCustomers}
              loaderColSpan={7}
              loadingOffSet={60}
            >
              {_.map(customers, (customer, i) => {
                // If the user is not a Guardian
                if (customer.isDependent || customer.isIndependent) {
                  return (
                    <CustomerItemRow
                      customer={customer}
                      key={i}
                      setSelectedCustomerId={this._setSelectedCustomerId}
                      hasAccessToCustomerDetails={hasAccessToCustomerDetails}
                    />
                  );
                }
              })}
            </InfiniteScrollLoading>
            {this.state.isLoadingInfiniteScrolling && (
              <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
            )}
          </div>
        )}

        {!this.state.isLoading && (
          <div className="flex-row justify-end">
            <PrimaryButton
              size="large"
              disabled={isEmpty(newBookingData.selectedCustomerId)}
              onClick={this._onNextStep}
              icon="right"
              iconPosition="right"
            >
              Next
            </PrimaryButton>
          </div>
        )}
        {/*</div>*/}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  customers: state.customersStore.customersLite,
  newBookingData: state.bookingsStore.newBookingData,
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetCustomersLite: dispatch.customersStore.doGetCustomersLite,
  doGetCustomer: dispatch.customersStore.doGetCustomer,
  setCustomerFilterLite: dispatch.customersStore.setCustomerFilterLite,
  doFetchServicesLite: dispatch.servicesStore.doFetchServicesLite,
  doSetNewBookingData: dispatch.bookingsStore.doSetNewBookingData
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IStep1SelectCustomerPanelProps>()(Step1SelectCustomerPanel));
