import { Col, DatePicker, Divider, Form, Icon, Radio, Row, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import TimeInput from 'common-components/time-input/TimeInput';
import { TimezoneInput } from 'common-components/timezone';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { timeZone } from 'interfaces/timezone-type';
import _ from 'lodash';
import moment from 'moment';
import { extendMoment } from 'moment-range';
import React, { Component } from 'react';
import { dispatch, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { ServiceRecurringType, ServiceTimeType, ServiceType } from 'utilities/enum-utils';
import ServiceTimeRecurrenceItem from 'views/services/listing/components/ServiceTimeRecurrenceItem';
import ServiceAvailableDayItem from 'views/services/serviceAvailableDayItem';
// @ts-ignore
const extMoment = extendMoment(moment);

interface IWhenStepPanelProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setNewService: typeof dispatch.servicesStore.setNewService;
  newService: typeof state.servicesStore.newService;
}

interface IWhenStepPanelState {
  recurringType: string;
  oneOffStartingDate: any;
  oneOffEndingDate: any;
  recurringStartingDate: any;
  recurringEndingDate: any;
  dataArray: any;
  timeAvailabilities: any;
  isNoRecurrence: boolean;
  bookingTimeType: string;
  timezone: timeZone;
}

const dayOfWeekList = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

class WhenStepPanel extends Component<IWhenStepPanelProps, IWhenStepPanelState> {
  state = {
    isNoRecurrence: false,
    recurringType: this.props.newService.recurringType
      ? this.props.newService.recurringType
      : this.props.newService.serviceType === ServiceType.GROUP
      ? ServiceRecurringType.ONEOFF
      : ServiceRecurringType.RECURRING,
    oneOffStartingDate: this.props.newService.availableFrom
      ? moment(this.props.newService.availableFrom)
      : CommonUtils.formatCeilingDateTime(moment()),
    oneOffEndingDate: this.props.newService.availableTo
      ? moment(this.props.newService.availableTo)
      : CommonUtils.formatCeilingDateTime(moment().add(1, 'hour')),
    recurringStartingDate: this.props.newService.availableFrom ? moment(this.props.newService.availableFrom) : moment(),
    recurringEndingDate: this.props.newService.availableTo
      ? moment(this.props.newService.availableTo)
      : moment().add(6, 'month'),
    timeAvailabilities: [],
    bookingTimeType: this.props.newService.bookingTimeType
      ? this.props.newService.bookingTimeType
      : ServiceTimeType.FIXEDTIME,
    timezone: this.props.newService.timezone,
    dataArray:
      this.props.newService.serviceConfig && this.props.newService.serviceConfig.dataArray
        ? this.props.newService.serviceConfig.dataArray
        : [
            {
              dayOfWeek: 'MON',
              dayNumber: 1,
              startDateTime: moment(9, 'hh').isoWeekday(1),
              endDateTime: moment(17, 'hh').isoWeekday(1),
              selected: false,
              billableHours: 8,
              day: 'Monday',
              isValidate: true,
              isAllDay: false,
            },
            {
              dayOfWeek: 'TUE',
              dayNumber: 2,
              startDateTime: moment(9, 'hh').isoWeekday(2),
              endDateTime: moment(17, 'hh').isoWeekday(2),
              selected: false,
              billableHours: 8,
              day: 'Tuesday',
              isValidate: true,
              isAllDay: false,
            },
            {
              dayOfWeek: 'WED',
              dayNumber: 3,
              startDateTime: moment(9, 'hh').isoWeekday(3),
              endDateTime: moment(17, 'hh').isoWeekday(3),
              selected: false,
              billableHours: 8,
              day: 'Wednesday',
              isValidate: true,
              isAllDay: false,
            },
            {
              dayOfWeek: 'THU',
              dayNumber: 4,
              startDateTime: moment(9, 'hh').isoWeekday(4),
              endDateTime: moment(17, 'hh').isoWeekday(4),
              selected: false,
              billableHours: 8,
              day: 'Thursday',
              isValidate: true,
              isAllDay: false,
            },
            {
              dayOfWeek: 'FRI',
              dayNumber: 5,
              startDateTime: moment(9, 'hh').isoWeekday(5),
              endDateTime: moment(17, 'hh').isoWeekday(5),
              selected: false,
              billableHours: 8,
              day: 'Friday',
              isValidate: true,
              isAllDay: false,
            },
            {
              dayOfWeek: 'SAT',
              dayNumber: 6,
              startDateTime: moment(9, 'hh').isoWeekday(6),
              endDateTime: moment(17, 'hh').isoWeekday(6),
              selected: false,
              billableHours: 8,
              day: 'Saturday',
              isValidate: true,
              isAllDay: false,
            },
            {
              dayOfWeek: 'SUN',
              dayNumber: 7,
              startDateTime: moment(9, 'hh').isoWeekday(7),
              endDateTime: moment(17, 'hh').isoWeekday(7),
              selected: false,
              billableHours: 8,
              day: 'Sunday',
              isValidate: true,
              isAllDay: false,
            },
          ],
  };

  private _changeRecurringType = (e) => {
    this.setState({ recurringType: e.target.value });
  };

  private _onChangeOneOffStartingDate = (startingDate) => {
    const roundedValue = CommonUtils.formatCeilingDateTime(moment(startingDate));
    let newEndingDate = this.state.oneOffEndingDate;
    if (newEndingDate < roundedValue) {
      newEndingDate = moment(roundedValue).add(1, 'hour');
    }
    this.setState({ oneOffStartingDate: moment(roundedValue), oneOffEndingDate: newEndingDate });
  };

  private _onChangeRecurringStartingDate = (startingDate) => {
    let newEndingDate = this.state.recurringEndingDate;
    if (newEndingDate < startingDate) {
      newEndingDate = moment(startingDate).add(1, 'month');
    }
    this.setState({ recurringStartingDate: moment(startingDate), recurringEndingDate: newEndingDate }, () => {
      this._updateTimeAvailabilityArray();
    });
  };

  private _onChangeRecurringEndingDate = (endingDate) => {
    this.setState({ recurringEndingDate: endingDate }, () => {
      this._updateTimeAvailabilityArray();
    });
  };

  private _onChangeOneOffEndingDate = (endingDate) => {
    const roundedValue = CommonUtils.formatCeilingDateTime(moment(endingDate));
    this.setState({ oneOffEndingDate: roundedValue });
  };

  private _onChangeOneOffEndingTime = (endingTime) => {
    const { oneOffStartingDate, oneOffEndingDate } = this.state;
    const roundedValue = CommonUtils.formatCeilingDateTime(moment(endingTime));
    let newEndingDate = moment(oneOffEndingDate);
    if (roundedValue > oneOffStartingDate) {
      newEndingDate = moment(roundedValue);
    }
    this.setState({ oneOffEndingDate: newEndingDate });
  };

  private _changeTimezone = (event) => {
    this.setState({ timezone: event });
  };

  private _eventHandler = (type, dayOfWeek, value = '') => {
    const newDataArray = this.state.dataArray;

    if (type === 'checkbox') {
      newDataArray.map((time) => {
        if (time.dayOfWeek === dayOfWeek) {
          time.selected = !time.selected;
        }
      });
    }

    if (type === 'startDate') {
      newDataArray.map((time) => {
        if (time.dayOfWeek === dayOfWeek) {
          const roundedValue = CommonUtils.formatCeilingDateTime(moment(value));
          if (moment(roundedValue).hours() >= 23 && moment(roundedValue).minutes() >= 55) {
            time.startDateTime = moment(roundedValue).set({ hours: 23, minutes: 55, seconds: 0 });
          } else {
            time.startDateTime = roundedValue;
          }
          if (time.startDateTime >= time.endDateTime) {
            let newEndingTime = moment(roundedValue).add(1, 'hour');
            if (
              !moment(time.startDateTime)
                .startOf('day')
                .isSame(moment(newEndingTime).startOf('day'))
            ) {
              newEndingTime = moment(roundedValue).set({ hours: 24, minutes: 0, seconds: 0 });
            }
            time.endDateTime = newEndingTime;
          }
          time.billableHours = moment.duration(time.endDateTime.diff(time.startDateTime)).asHours();
        }
      });
    }

    if (type === 'endDate') {
      newDataArray.map((time) => {
        if (time.dayOfWeek === dayOfWeek) {
          let endDate = time.endDateTime;
          if (moment(value) > time.startDateTime) {
            endDate = CommonUtils.formatCeilingDateTime(moment(value));
            time.billableHours = moment.duration(endDate.diff(time.startDateTime)).asHours();
          }
          time.endDateTime = endDate;
        }
      });
    }

    if (type === 'billableHours') {
      newDataArray.map((time) => {
        if (time.dayOfWeek === dayOfWeek) {
          time.billableHours = value;
        }
      });
    }

    this._updateTimeAvailabilityArray();
    this.setState({ dataArray: newDataArray });
  };

  private _updateTimeAvailabilityArray = () => {
    const { recurringStartingDate, recurringEndingDate } = this.state;

    const list = extMoment.range(moment(recurringStartingDate), moment(recurringEndingDate));
    const arrayList = Array.from(list.by('day'));

    const dataArray = _.map(this.state.dataArray, (selection) => {
      if (selection.selected) {
        return _.chain(arrayList)
          .filter((date) => moment(date).isoWeekday() === selection.dayNumber)
          .map((date) => {
            if (selection.isAllDay) {
              const startDateTime = date.startOf('day').toDate();
              const endDateTime = date.endOf('day').toDate();
              return {
                startDateTime,
                endDateTime,
                isAllDay: true,
                dayOfWeek: selection.dayOfWeek,
                billableHour: 24,
              };
            } else {
              const startDateTime = moment(
                date.format('YYYY-MM-DD') + ' ' + moment(selection.startDateTime).format('HH:mm'),
              ).toDate();
              const endDateTime = moment(
                date.format('YYYY-MM-DD') + ' ' + moment(selection.endDateTime).format('HH:mm'),
              ).toDate();
              return {
                startDateTime,
                endDateTime,
                dayOfWeek: selection.dayOfWeek,
                isAllDay: false,
                billableHour: selection.billableHours,
              };
            }
          })
          .value();
      } else {
        return [];
      }
    });
    const toBeSentArray = _.flatMap(dataArray);

    const isNoRecurrence = toBeSentArray.length > 0 && this.state.isNoRecurrence ? false : this.state.isNoRecurrence;

    this.setState({ timeAvailabilities: toBeSentArray, isNoRecurrence });
  };

  // INDIVIDUAL SERVICE FUNCTIONS

  private _doUpdateAndValidate = async (dataObject) => {
    if (!dataObject.selected) {
      dataObject.isValidate = true;
    } else {
      if (dataObject.isAllDay) {
        dataObject.isValidate = true;
      } else {
        dataObject.isValidate = !_.isEmpty(dataObject.startDateTime) && !_.isEmpty(dataObject.endDateTime);
      }
    }
    const oldArray = this.state.dataArray;
    oldArray[_.findIndex(oldArray, (obj: any) => obj.dayOfWeek === dataObject.dayOfWeek)] = dataObject;
    await this._updateTimeAvailabilityArray();
    this.setState({ dataArray: oldArray });
  };

  private _onUpdateIsAllDay = (payload) => {
    const { dayOfWeek, isAllDay } = payload;
    const oldArray = this.state.dataArray;
    const oldData = oldArray[_.findIndex(oldArray, (data: any) => data.dayOfWeek === dayOfWeek)];
    oldData.isAllDay = isAllDay;
    this._doUpdateAndValidate(oldData);
  };

  private _onUpdateSelect = (payload) => {
    const { dayOfWeek, selected } = payload;
    const oldArray = this.state.dataArray;
    const oldData = oldArray[_.findIndex(oldArray, (data: any) => data.dayOfWeek === dayOfWeek)];
    oldData.selected = selected;
    this._doUpdateAndValidate(oldData);
  };

  private _onUpdateStartDateTime = (payload) => {
    const { dayOfWeek, startDateTime } = payload;
    this._eventHandler('startDate', dayOfWeek, startDateTime);
  };

  private _onUpdateEndDateTime = (payload) => {
    const { dayOfWeek, endDateTime } = payload;
    this._eventHandler('endDate', dayOfWeek, endDateTime);
  };

  private _doChangeServiceTimeType = (value) => {
    let dataArray;
    if (value.target.value === ServiceTimeType.OPENTIME) {
      dataArray = _.map(this.state.dataArray, (data) => {
        data.selected = true;
        data.isAllDay = true;
        return data;
      });
    } else {
      dataArray = _.map(this.state.dataArray, (data) => {
        data.isAllDay = false;
        return data;
      });
    }

    this._updateTimeAvailabilityArray();

    this.setState({ bookingTimeType: value.target.value, dataArray: dataArray });
  };

  private _onNextStep = async () => {
    const { setNewService, onNextStep, newService } = this.props;
    const {
      oneOffStartingDate,
      oneOffEndingDate,
      recurringStartingDate,
      recurringEndingDate,
      bookingTimeType,
      timezone,
    } = this.state;

    let isValid = true;

    const recurringType = this.state.recurringType;

    if (
      (recurringType === ServiceRecurringType.RECURRING || newService.serviceType === ServiceType.INDIVIDUAL) &&
      this.state.timeAvailabilities.length === 0
    ) {
      isValid = false;
      this.setState({ isNoRecurrence: true });
    }

    if (isValid) {
      const availableFrom =
        recurringType === ServiceRecurringType.ONEOFF
          ? oneOffStartingDate
          : moment(recurringStartingDate).startOf('day');
      const availableTo =
        recurringType === ServiceRecurringType.ONEOFF ? oneOffEndingDate : moment(recurringEndingDate).endOf('day');
      const dataArray = recurringType === ServiceRecurringType.ONEOFF ? '' : this.state.dataArray;
      const serviceConfig =
        newService.serviceType === ServiceType.GROUP
          ? recurringType === ServiceRecurringType.RECURRING
            ? { dataArray, availableFrom, availableTo, isFixedTime: true }
            : { availableFrom, availableTo, isFixedTime: true }
          : bookingTimeType === ServiceTimeType.FIXEDTIME
          ? { dataArray, availableFrom, availableTo, isFixedTime: true }
          : { dataArray, availableFrom, availableTo, isFixedTime: false };

      const timeAvailabilities =
        recurringType === ServiceRecurringType.ONEOFF
          ? [
              {
                startDateTime: oneOffStartingDate,
                endDateTime: oneOffEndingDate,
                dayOfWeek: dayOfWeekList[moment(oneOffStartingDate).isoWeekday() - 1],
                billableHour: moment.duration(oneOffEndingDate.diff(oneOffStartingDate)).asHours(),
              },
            ]
          : this.state.timeAvailabilities;

      await setNewService({
        ...newService,
        recurringType,
        availableFrom,
        availableTo,
        bookingTimeType,
        dataArray,
        timeAvailabilities,
        serviceConfig,
        timezone,
      });
      onNextStep();
    }
  };

  componentDidMount() {
    this._updateTimeAvailabilityArray();
  }

  render() {
    const { onPreviousStep, newService } = this.props;
    const { recurringType, timeAvailabilities, dataArray, bookingTimeType } = this.state;

    return (
      <div className="anim-slide-left mb-x-large">
        <Title level={2} weight="bolder" className="line-height-100">
          {newService.serviceType === ServiceType.INDIVIDUAL ? 'Support service availability' : 'Group service type'}
        </Title>

        <Paragraph>
          {newService.serviceType === ServiceType.INDIVIDUAL
            ? 'What times during the week is this service available??'
            : 'Is this a regular service, or are you offering it only once?'}
        </Paragraph>

        {newService.serviceType === ServiceType.GROUP && (
          <Row className="mt-x2-large mb-large">
            <Col span={4} style={{ marginTop: '4px' }}>
              <SubTitle>Recurrence Type</SubTitle>
            </Col>
            <Col span={20}>
              <div className="mb-medium">
                <Radio.Group onChange={this._changeRecurringType}>
                  <Radio value={ServiceRecurringType.ONEOFF} checked={recurringType === ServiceRecurringType.ONEOFF}>
                    <b>One-Off.</b> <Text color="secondary">This service is a one-off and will not occur again.</Text>
                  </Radio>
                  <br />
                  <Radio
                    value={ServiceRecurringType.RECURRING}
                    checked={recurringType === ServiceRecurringType.RECURRING}
                  >
                    <b>Recurring</b> <Text color="secondary">This service will occur multiple times.</Text>
                  </Radio>
                </Radio.Group>
              </div>
            </Col>
          </Row>
        )}
        {newService.serviceType === ServiceType.INDIVIDUAL && (
          <Row className="mt-x2-large mb-large">
            <Col span={4} style={{ marginTop: '4px' }}>
              <SubTitle>Availabilities</SubTitle>
            </Col>
            <Col span={20}>
              <div className="mb-medium">
                <Radio.Group onChange={this._doChangeServiceTimeType}>
                  <Radio value={ServiceTimeType.FIXEDTIME} checked={bookingTimeType === ServiceTimeType.FIXEDTIME}>
                    <b>Available at certain times</b>{' '}
                    <Text color="secondary">This service has specific operating hours.</Text>
                  </Radio>
                  <br />
                  <Radio value={ServiceTimeType.OPENTIME} checked={bookingTimeType === ServiceTimeType.OPENTIME}>
                    <b>Available 24/7</b> <Text color="secondary">This service can be booked at any time.</Text>
                  </Radio>
                  <br />
                </Radio.Group>
              </div>
            </Col>
          </Row>
        )}

        {recurringType === ServiceRecurringType.ONEOFF && (
          <>
            <Row>
              <Col span={24} className="mb-medium">
                <Text weight="bold">Choose the Date and Time for this Service</Text>
              </Col>
            </Row>
            <Row className="mb-large">
              <Col span={12}>
                <div className="flex-row align-left">
                  <DatePicker
                    size="large"
                    format={'ddd, D MMM YYYY'}
                    allowClear={false}
                    value={this.state.oneOffStartingDate}
                    disabledDate={(current) => {
                      return current < moment().startOf('day');
                    }}
                    onChange={(event) => this._onChangeOneOffStartingDate(event)}
                    className="mr-small"
                  />
                  <TimeInput
                    size="large"
                    value={moment(this.state.oneOffStartingDate)}
                    onChange={(event) => this._onChangeOneOffStartingDate(event)}
                  />
                </div>
                <Text color="secondary">Start Date/Time</Text>
              </Col>
              <Col span={12}>
                <div className="flex-row align-left">
                  <DatePicker
                    size="large"
                    value={this.state.oneOffEndingDate}
                    allowClear={false}
                    format={'ddd, D MMM YYYY'}
                    disabledDate={(current) => {
                      return current < moment(this.state.oneOffEndingDate).startOf('day');
                    }}
                    onChange={(event) => this._onChangeOneOffEndingDate(event)}
                    className="mr-small"
                  />
                  <TimeInput
                    size="large"
                    value={this.state.oneOffEndingDate}
                    onChange={(event) => this._onChangeOneOffEndingTime(event)}
                  />
                </div>
                <Text color="secondary">End Date/Time</Text>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  Timezone
                  <br />
                  <TimezoneInput value={this.state.timezone} onChange={this._changeTimezone} size={'large'} />
                </div>
              </Col>
            </Row>
          </>
        )}

        {((newService.serviceType === ServiceType.GROUP && recurringType === ServiceRecurringType.RECURRING) ||
          newService.serviceType === ServiceType.INDIVIDUAL) && (
          <>
            <Row className="mb-large">
              <Col span={24}>
                <Text weight="bold">
                  {bookingTimeType === ServiceTimeType.OPENTIME
                    ? 'Select the date range this service is available from.'
                    : 'Please indicate the exact availabilities of this service.'}
                </Text>
                <div className="flex-row align-left mt-medium">
                  <div className="mr-x-large">
                    Start Date
                    <br />
                    <DatePicker
                      size="large"
                      format={'ddd, D MMM YYYY'}
                      value={this.state.recurringStartingDate}
                      allowClear={false}
                      disabledDate={(current) => {
                        return current < moment().startOf('day');
                      }}
                      onChange={(event) => this._onChangeRecurringStartingDate(event)}
                    />
                  </div>
                  <div className="mr-x-large">
                    End Date
                    <br />
                    <DatePicker
                      size="large"
                      format={'ddd, D MMM YYYY'}
                      value={this.state.recurringEndingDate}
                      allowClear={false}
                      disabledDate={(current) => {
                        return current < moment(this.state.recurringStartingDate).endOf('day');
                      }}
                      onChange={(event) => this._onChangeRecurringEndingDate(event)}
                    />
                  </div>
                  <div>
                    Timezone
                    <br />
                    <TimezoneInput value={this.state.timezone} onChange={this._changeTimezone} size={'large'} />
                  </div>
                </div>
              </Col>
            </Row>

            {newService.serviceType === ServiceType.GROUP && (
              <Row>
                <Col span={24}>
                  <b>Weekly Recurrence</b>
                  <br />
                  <Text size="regular" color="regular">
                    Select the days and times for the services.
                  </Text>
                  {this.state.isNoRecurrence && (
                    <div className={'mt-medium'}>
                      <Text color={'red'}>
                        <Icon type="exclamation-circle" className={'mr-x-small'} />
                        You need to have at least one session.
                      </Text>
                    </div>
                  )}
                  <table id="test" className="width-full mt-x-large">
                    <tbody>
                      <tr>
                        <th style={{ width: '30px' }} />
                        <th>Day</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Total Hours</th>
                        <th>
                          Billable Hours{' '}
                          <Tooltip
                            title={
                              <div className="p-medium">
                                <Paragraph className="text-size-regular text-color-white">
                                  Number of hours that will be billed to the Customer. By default it's the number of
                                  hours between the Start and End times selected.
                                </Paragraph>
                                <Paragraph className="text-size-regular text-color-white">
                                  Note: You can manually lower the amount (for example to signify breaks).
                                </Paragraph>
                              </div>
                            }
                          >
                            <Icon
                              type="question-circle"
                              className="mr-x-small text-size-x-large ml-small text-color-blue cursor-pointer"
                            />
                          </Tooltip>
                        </th>
                        <th>
                          Sessions{' '}
                          <Tooltip
                            title={
                              <div className="p-medium">
                                <Paragraph className="text-size-regular text-color-white">
                                  The number of Sessions that will be created for this service depending on the Start
                                  and End dates above and the selected days.
                                </Paragraph>
                                <Paragraph className="text-size-regular text-color-white">
                                  If Sessions is ‘0’ your Start and End dates may be less than a week apart.
                                </Paragraph>
                              </div>
                            }
                          >
                            <Icon
                              type="question-circle"
                              className="mr-x-small text-size-x-large ml-small text-color-blue cursor-pointer"
                            />
                          </Tooltip>
                        </th>
                      </tr>
                      {_.map(dataArray, (item, key) => {
                        return (
                          <ServiceTimeRecurrenceItem
                            key={key}
                            item={item}
                            timeAvailabilities={timeAvailabilities}
                            eventHandler={this._eventHandler}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                </Col>
              </Row>
            )}
            {newService.serviceType === ServiceType.INDIVIDUAL && bookingTimeType === ServiceTimeType.FIXEDTIME && (
              <>
                <Row gutter={24} className="mb-medium">
                  <Col span={5}>
                    <span className="ml-large">
                      <Text>
                        <b>Day</b>
                      </Text>
                    </span>
                  </Col>
                  <Col span={6}>{/*<Text>Is full Day?</Text>*/}</Col>
                  <Col span={6}>
                    <Text>
                      <b>Start Time</b>
                    </Text>
                  </Col>
                  <Col span={6}>
                    <Text>
                      <b>End Time</b>
                    </Text>
                  </Col>
                </Row>

                {/*  Available Days*/}
                <Divider className="divider-medium" />
                {_.map(this.state.dataArray, (date) => {
                  return (
                    <ServiceAvailableDayItem
                      dateObject={date}
                      onUpdateEndDateTime={this._onUpdateEndDateTime}
                      onUpdateIsAllDay={this._onUpdateIsAllDay}
                      onUpdateSelect={this._onUpdateSelect}
                      onUpdateStartDateTime={this._onUpdateStartDateTime}
                      isFixedTime={true}
                      key={date.dayOfWeek}
                    />
                  );
                })}
              </>
            )}
          </>
        )}

        <div className="flex-row align-center mv-x2-large">
          <SecondaryButton size="large" onClick={onPreviousStep} icon="left" className="mr-large">
            Previous
          </SecondaryButton>

          <PrimaryButton size="large" onClick={this._onNextStep} icon="right" iconPosition="right">
            Next
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

export default Form.create<IWhenStepPanelProps>()(WhenStepPanel);
