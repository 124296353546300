import React, { Component, ReactNode } from 'react';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { Checkbox, Col, Input, Radio, Row, Select, InputNumber, Icon, notification } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { LocationDisplay } from 'common-components/locations';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { IServiceDetailsItem } from 'interfaces/service-interfaces';
import Script from 'react-load-script';
import _ from 'lodash';
import { INewBookingData } from 'interfaces/booking-interfaces';
import { ICustomer } from 'interfaces/customer-interfaces';
import { ServiceLocationType, ServiceType } from 'utilities/enum-utils';
import AddAddressModal from 'common-components/addresses/AddAddressModal';
import globalConfig from 'variables/global-config';
import ConflictingBookingsModal from 'views/bookings/listings/components/ConflictingBookingsModal';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import * as H from 'history';
import Utils from 'utilities/Utils';
import moment from 'moment-timezone';

const { Option } = Select;

interface IAddressItemProps {
  isSelected: string;
  description: string;
  bordered?: boolean;
  address: string | ReactNode;
  isCRM: boolean;
  disabledAddress: boolean;
  userAddressId: string;
  addPadding: boolean;
  handleAddressId: (userAddressId) => void;
}

class AddressItem extends Component<IAddressItemProps> {
  render() {
    const {
      isSelected,
      description,
      address,
      disabledAddress,
      bordered,
      isCRM,
      userAddressId,
      handleAddressId,
      addPadding
    } = this.props;

    return (
      <Col
        span={8}
        onClick={() => (!disabledAddress ? handleAddressId(userAddressId) : false)}
        className={`${addPadding && 'pl-small'}`}
      >
        <Row
          className={`${bordered && 'bordered'} ${
            userAddressId === isSelected ? 'bg-blue-action-lightest' : disabledAddress ? 'bg-quaternary' : 'bg-white'
          } p-medium rounded cursor-pointer ${isSelected ? '' : 'hover-bg-tertiary'} mb-small`}
          style={{ minHeight: '160px' }}
          type={'flex'}
        >
          <Col span={3}>
            <Radio value={userAddressId} disabled={disabledAddress} />
          </Col>
          <Col span={21}>
            <b>{description}</b>
            <br />
            {address}
            {disabledAddress && (
              <Text color={'secondary'} size={'regular'}>
                <br />
                <Icon type={'info-circle'} className="mr-x-small" />
                This address is empty. {isCRM && 'You can update this in the profile.'}
              </Text>
            )}
          </Col>
        </Row>
      </Col>
    );
  }
}

interface Step3BookingLocationPanelProps {
  onNextStep: any;
  onPreviousStep: any;
  newBookingData: INewBookingData | any;
  doSetNewBookingData?: any;
  bookSelectedService: IServiceDetailsItem;
  selectedCustomer: ICustomer;
  doGetCustomer?: typeof dispatch.customersStore.doGetCustomer;
  doCheckRecurringPattern: typeof dispatch.bookingsStore.doCheckRecurringPattern;
  history: H.History;
}

interface Step3BookingLocationPanelState {
  userAddressId: string;
  isOtherReason: boolean;
  openPublishSettings: boolean;
  openCreateAddress: boolean;
  isVerifying: boolean;
  isConflicts: boolean;
  acknowledgeCancelAllConflict: boolean;
  conflictList: Array<any>;
  sessionList: Array<any>;
  isConflictsOpen: boolean;
  isCancellingConflictsOpen: boolean;
}

class Step3BookingLocationPanel extends Component<Step3BookingLocationPanelProps, Step3BookingLocationPanelState> {
  state = {
    userAddressId: '',
    isOtherReason: false,
    openPublishSettings: false,
    openSelectWorker: false,
    openCreateAddress: false,
    isVerifying: false,
    isConflicts: false,
    acknowledgeCancelAllConflict: false,
    conflictList: null,
    sessionList: null,
    isConflictsOpen: false,
    isCancellingConflictsOpen: false
  };

  private _handleAddressId = (userAddressId) => {
    this.setState({ userAddressId });
    const { newBookingData, doSetNewBookingData, selectedCustomer } = this.props;

    const foundInUserAddresses = _.find(
      selectedCustomer.addresses,
      (address) => address.userAddressId === userAddressId
    );
    let selectedAddress = foundInUserAddresses ? foundInUserAddresses : newBookingData.manuallyAddedAddress;

    doSetNewBookingData({
      ...newBookingData,
      bookLocation: selectedAddress
    });
  };

  private _onCheckCancelledBooking = async (e) => {
    const { newBookingData, doSetNewBookingData } = this.props;

    const hasMileageClaims = false;

    doSetNewBookingData({
      ...newBookingData,
      isCancelledBooking: e.target.checked,
      hasMileageClaims,
      cancellationCode: e.target.checked ? 'NSDT' : null
    });

    this.setState({ isOtherReason: false });
  };

  private _verifyRecurrence = async () => {
    const { newBookingData, bookSelectedService, doCheckRecurringPattern } = this.props;

    this.setState({ isVerifying: true });
    try {
      const result: any = await doCheckRecurringPattern({
        serviceId: bookSelectedService.serviceId,
        startDateTime: moment.tz(newBookingData.bookStartDate, bookSelectedService.timezone).toISOString(),
        endDateTime: moment.tz(newBookingData.bookEndDate, bookSelectedService.timezone).toISOString(),
        recurringPattern: newBookingData.recurringPattern.recurringPattern,
        recurringTo: moment.tz(newBookingData.recurringPattern.recurringTo, bookSelectedService.timezone),
        serviceDirection: bookSelectedService.serviceDirection,
        timezone: bookSelectedService.timezone,
        address: newBookingData.bookLocation
          ? {
              ...newBookingData.bookLocation,
              geoLat: Number(newBookingData.bookLocation.geoLat),
              geoLng: Number(newBookingData.bookLocation.geoLng)
            }
          : null,
        userId: newBookingData.selectedCustomerId
      });
      if (result.data.conflicts.length === 0) {
        this.setState(
          {
            isVerifying: false,
            conflictList: result.data.conflicts,
            sessionList: result.data.sessions
          },
          () => this._onNextStep()
        );
      } else {
        this.setState({
          isVerifying: false,
          isConflicts: true,
          conflictList: result.data.conflicts,
          sessionList: result.data.sessions,
          isConflictsOpen: true
        });
      }
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
      this.setState({
        isVerifying: false
      });
    }
  };

  private _closeConflictsModal = () => {
    this.setState({ isConflictsOpen: false });
  };

  private _closeCancellingConflictsModal = () => {
    this.setState({ isCancellingConflictsOpen: false });
  };

  private _onSelectCancellationCode = async (val) => {
    if (val === 'NSDO') {
      this.setState({ isOtherReason: true });
    } else {
      this.setState({ isOtherReason: false });
    }
    const { newBookingData, doSetNewBookingData } = this.props;
    doSetNewBookingData({ ...newBookingData, cancellationCode: val });
  };

  private _onEnterOtherReason = async (event) => {
    const { newBookingData, doSetNewBookingData } = this.props;
    doSetNewBookingData({ ...newBookingData, cancellationReason: event.target.value });
  };

  private _onEnterMileageInput = async (e) => {
    const { newBookingData, doSetNewBookingData } = this.props;
    console.log(e);
    doSetNewBookingData({ ...newBookingData, mileageTravelled: e });
  };

  // TODO : Need help to finish this up.
  private _onSelectLocation = async () => {};

  // Check to see if form is valid or not.
  private _isValidForm = () => {
    const { newBookingData, selectedCustomer, bookSelectedService } = this.props;
    const {
      isFutureBooking,
      hasMileageClaims,
      mileageTravelled = '',
      cancellationReason,
      isCancelledBooking
    } = newBookingData;

    if (isFutureBooking) {
      return (
        (selectedCustomer.addresses.length !== 0 &&
          newBookingData.bookLocation !== null &&
          newBookingData.bookLocation !== undefined &&
          !Utils.isEmpty(newBookingData.bookLocation.fullAddress)) ||
        bookSelectedService.serviceDirection !== ServiceLocationType.CUSTOMERLOCATION
      );
    } else {
      // Past booking. The following conditions must be met:
      // Check if mileage is ticked; if so, there must have mileage keyed in.
      if (
        (hasMileageClaims && mileageTravelled === 0) ||
        (hasMileageClaims && mileageTravelled === '') ||
        (hasMileageClaims && !mileageTravelled)
      ) {
        return false;
      }

      if (
        (selectedCustomer.addresses.length === 0 ||
          newBookingData.bookLocation === null ||
          newBookingData.bookLocation === undefined ||
          Utils.isEmpty(newBookingData.bookLocation.fullAddress)) &&
        bookSelectedService.serviceDirection === ServiceLocationType.CUSTOMERLOCATION
      ) {
        return false;
      }

      // Check if other reason selected and reason is empty.
      if (isCancelledBooking && (cancellationReason === '' || cancellationReason === undefined)) {
        return false;
      }

      // Return true if conditions met
      return true;
    }
  };

  private _saveBookingAddress = (location) => {
    const { doSetNewBookingData, newBookingData } = this.props;

    const manuallyAddedId = 'manuallySelectedAddress';
    const newBookLocation = {
      ...location,
      userAddressId: manuallyAddedId,
      isManual: true
    };
    doSetNewBookingData({
      ...newBookingData,
      bookLocation: location,
      manuallyAddedAddress: newBookLocation
    });
    this.setState({ userAddressId: manuallyAddedId });
  };

  private _closeCreateAddressModal = () => {
    this.setState({ openCreateAddress: false });
  };

  private _openCreateAddressModal = () => {
    this.setState({ openCreateAddress: true });
  };

  private _onNextStep = async () => {
    this.props.doSetNewBookingData({
      ...this.props.newBookingData,
      conflictBookings: this.state.conflictList,
      timeSlots: this.state.sessionList
    });
    this.setState({ isConflictsOpen: false });
    this.props.onNextStep();
  };

  componentDidMount() {
    const { selectedCustomer, newBookingData, doSetNewBookingData, bookSelectedService } = this.props;
    if (
      selectedCustomer.addresses.length > 0 &&
      bookSelectedService.serviceDirection === ServiceLocationType.CUSTOMERLOCATION
    ) {
      let selectedAddress;
      if (
        newBookingData.bookLocation !== null &&
        newBookingData.bookLocation !== '' &&
        newBookingData.bookLocation !== undefined
      ) {
        const foundInUserAddresses = _.find(
          selectedCustomer.addresses,
          (address) => address.userAddressId === newBookingData.bookLocation.userAddressId
        );
        selectedAddress = foundInUserAddresses ? foundInUserAddresses : newBookingData.manuallyAddedAddress;
      } else {
        selectedAddress = _.find(selectedCustomer.addresses, (address) => address.isPrimary === true);
        if (Utils.isEmpty(selectedAddress)) {
          selectedAddress = _.head(
            _.filter(selectedCustomer.addresses, (address) => !Utils.isEmpty(address.fullAddress))
          );
        }
      }

      if (selectedAddress) {
        this.setState({ userAddressId: selectedAddress.userAddressId });
      }

      doSetNewBookingData({
        ...newBookingData,
        bookLocation: selectedAddress
      });
    }

    if (bookSelectedService.serviceDirection === ServiceLocationType.FIXEDVENUE) {
      doSetNewBookingData({ ...newBookingData, bookLocation: bookSelectedService.locations[0] });
    }

    const { cancellationCode } = newBookingData;

    if (cancellationCode === 'NSDO') {
      this.setState({ isOtherReason: true });
    }
  }

  render() {
    const { onNextStep, onPreviousStep, bookSelectedService, newBookingData, selectedCustomer } = this.props;

    const {
      isFutureBooking,
      hasMileageClaims,
      mileageTravelled,
      isCancelledBooking,
      cancellationReason,
      cancellationCode,
      manuallyAddedAddress
    } = newBookingData;

    let addresses = [];
    if (selectedCustomer) {
      let userAddresses = selectedCustomer.addresses;
      userAddresses.sort((a, b) => b.isPrimary - a.isPrimary);
      addresses = manuallyAddedAddress ? [manuallyAddedAddress].concat(userAddresses) : userAddresses;
    } else {
      addresses = manuallyAddedAddress ? [manuallyAddedAddress] : null;
    }

    if (!_.isEmpty(bookSelectedService)) {
      bookSelectedService.supportWorkers = _.orderBy(
        bookSelectedService.supportWorkers,
        [
          (supportWorker) => supportWorker.firstName.toLowerCase(),
          (supportWorker) => supportWorker.lastName.toLowerCase()
        ],
        'asc'
      );
    }

    const isValidForm = this._isValidForm();

    const location =
      bookSelectedService.locations[0] && bookSelectedService.locations[0].postcode
        ? bookSelectedService.locations[0]
        : null;

    return (
      <div className="anim-slide-left">
        <ConflictingBookingsModal
          isOpen={this.state.isConflictsOpen}
          conflictList={this.state.conflictList}
          closeConflictingBookingsModal={this._closeConflictsModal}
          onNextStep={this._onNextStep}
          timezone={bookSelectedService.timezone}
          history={this.props.history}
        />
        <ActionModal
          isOpen={this.state.isCancellingConflictsOpen}
          onClose={this._closeCancellingConflictsModal}
          title={'Cancelling conflicting bookings'}
          showCloseButton={true}
        >
          <Paragraph>
            You have selected to cancel all conflicting bookings. This will cancel
            {this.state.conflictList ? this.state.conflictList.length : 0} booking
            {this.state.conflictList && this.state.conflictList.length !== 1 && 's'} after you have finished creating
            this recurring booking.
          </Paragraph>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._closeCancellingConflictsModal}>
              Ok
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <Script
          url={'https://maps.googleapis.com/maps/api/js?key=' + globalConfig.googleMapApiKey + '&libraries=places'}
        />
        <AddAddressModal
          closeCreateEditAddressModal={() => {
            this._closeCreateAddressModal();
          }}
          isOpen={this.state.openCreateAddress}
          saveCustomerAddress={this._saveBookingAddress}
          title={
            <>
              Add a New <b>Address</b>
            </>
          }
          subTitle={
            <>
              You are adding a new Address for{' '}
              <b>
                {selectedCustomer.firstName} {selectedCustomer.lastName}
              </b>{' '}
              profile. Please enter the following details.
            </>
          }
        />
        <div className="mb-x-large">
          <Title level={2}>
            <span className="text-weight-regular">Select a </span> location
          </Title>
          <Text>
            Search for an <b>address</b> or choose a <b>location</b> from the list.
          </Text>
        </div>
        <div>
          <div className={`anim-fade-in mb-x-large`}>
            <Row>
              <Col span={4}>
                <SubTitle>Location</SubTitle>
              </Col>
              <Col span={20}>
                <Row>
                  {/* TODO : Load the customer's addresses here. */}
                  <Col style={{ maxHeight: 'calc(100vh - 550px)', overflowY: 'auto', overflowX: 'hidden' }}>
                    {bookSelectedService.serviceDirection === ServiceLocationType.CUSTOMERLOCATION && (
                      <>
                        <PrimaryButton icon={'search'} className="mb-small" onClick={this._openCreateAddressModal}>
                          Search for address
                        </PrimaryButton>
                        <Radio.Group value={this.state.userAddressId} className={'width-full'}>
                          {addresses.length > 0 ? (
                            addresses.map((address, i) => (
                              <AddressItem
                                key={address.userAddressId}
                                bordered={true}
                                isCRM={selectedCustomer.isCrm}
                                isSelected={this.state.userAddressId}
                                userAddressId={address.userAddressId}
                                handleAddressId={this._handleAddressId}
                                address={<LocationDisplay location={address.fullAddress} />}
                                disabledAddress={!address.fullAddress}
                                addPadding={i % 3 !== 0}
                                description={
                                  address.isManual
                                    ? 'Manually Selected Address'
                                    : address.isPrimary
                                    ? 'Primary Address'
                                    : 'Alternate Address ' + i + ''
                                }
                              />
                            ))
                          ) : (
                            <Text>
                              <Icon type="close-circle" theme="filled" className="mr-x-small text-color-red" />
                              No address set. Please add an address on the customer's profile.
                            </Text>
                          )}
                        </Radio.Group>
                      </>
                    )}
                    {bookSelectedService.serviceDirection === ServiceLocationType.ONLINE && <div>Online</div>}
                    {bookSelectedService.serviceDirection === ServiceLocationType.FIXEDVENUE && (
                      <>
                        <div>
                          {location ? (
                            <LocationDisplay location={location.fullAddress} />
                          ) : (
                            <Text color="secondary">
                              No Address selected. Please choose an address in the Service detail page.
                            </Text>
                          )}
                        </div>
                      </>
                    )}
                  </Col>
                  {/* Ignore this for now
                <AddressItem
                  bordered={true}
                  isSelected={false}
                  address={
                    <div className="flex-row">
                      <Input.TextArea style={{ width: '100%' }} placeholder={'Custom address'} />
                    </div>
                  }
                  description="Custom"
                />
                */}
                </Row>
                {newBookingData.isRecurring && (
                  <div className="mt-medium">
                    The location selected here will be applied to all bookings being created.
                  </div>
                )}
              </Col>
            </Row>
          </div>
          {!isFutureBooking && (
            <div className="mb-x-large">
              <Row>
                <Col span={4}>
                  <SubTitle>Additional Options</SubTitle>
                </Col>

                <Col span={20}>
                  <Checkbox className="mb-small" checked={isCancelledBooking} onChange={this._onCheckCancelledBooking}>
                    This booking is a cancelled booking.
                  </Checkbox>

                  {isCancelledBooking ? (
                    <React.Fragment>
                      <br />
                      <div className="flex-row align-center">
                        <Select
                          value={cancellationCode}
                          defaultValue="NSDT"
                          className="width-1/3 mr-medium"
                          onChange={this._onSelectCancellationCode}
                        >
                          <Option key="Transport Unavailable (NSDT)" value="NSDT">
                            Transport Unavailable (NSDT)
                          </Option>
                          <Option key="Health Reasons (NSDH)" value="NSDH">
                            Health Reasons (NSDH)
                          </Option>
                          <Option key="Family Reasons (NSDF)" value="NSDF">
                            Family Reasons (NSDF)
                          </Option>
                          <Option key="Other (NSDO)" value="NSDO">
                            Other (NSDO)
                          </Option>
                        </Select>
                        <React.Fragment>
                          {/*<Text className="mr-small">Reason</Text>*/}
                          <Input
                            className="width-1/2 inline-block anim-fade-in-fast"
                            style={{ width: '300px' }}
                            value={cancellationReason}
                            onChange={this._onEnterOtherReason}
                            placeholder={'Describe this reason...'}
                          />
                        </React.Fragment>
                      </div>
                    </React.Fragment>
                  ) : null}

                  {hasMileageClaims && (
                    <>
                      <br />
                      <InputNumber
                        type="number"
                        className="width-1/2 inline-block anim-fade-in-fast mb-large mr-small"
                        size="large"
                        defaultValue={0}
                        min={0}
                        max={10000}
                        style={{ width: '200px' }}
                        value={mileageTravelled}
                        onChange={this._onEnterMileageInput}
                        disabled={isCancelledBooking}
                        precision={2}
                      />{' '}
                      km
                    </>
                  )}
                  <br />
                </Col>
              </Row>
            </div>
          )}
        </div>

        <div className="flex-row justify-between align-center">
          <SecondaryButton size="large" onClick={onPreviousStep} icon="left">
            Previous
          </SecondaryButton>

          <PrimaryButton
            size="large"
            onClick={
              bookSelectedService &&
              bookSelectedService.serviceType === ServiceType.INDIVIDUAL &&
              newBookingData.isRecurring
                ? this._verifyRecurrence
                : onNextStep
            }
            icon="right"
            loading={this.state.isVerifying}
            iconPosition="right"
            disabled={
              !isValidForm ||
              (bookSelectedService.serviceDirection === ServiceLocationType.CUSTOMERLOCATION && addresses.length === 0)
            }
          >
            Next
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  bookSelectedService: state.servicesStore.bookSelectedService,
  selectedCustomer: state.customersStore.selectedCustomer,
  newBookingData: state.bookingsStore.newBookingData
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doSetNewBookingData: dispatch.bookingsStore.doSetNewBookingData,
  doGetCustomer: dispatch.customersStore.doGetCustomer,
  doCheckRecurringPattern: dispatch.bookingsStore.doCheckRecurringPattern
});

export default connect(
  mapState,
  mapDispatch
)(Step3BookingLocationPanel);
