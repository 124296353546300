import React from 'react';

import _ from 'lodash';
import moment from 'moment';
import * as H from 'history';

import { IRosterShift, IRosterWorker } from 'common-components/roster-control/interfaces/roster-interfaces';
import WorkerDetailsColumn from 'common-components/roster-control/roster-weekly/workers/WorkerDetailsColumn';
import { DayColumn } from 'common-components/roster-control/roster-weekly/common/DayColumn';
import { AssignedShiftCard } from 'common-components/roster-control/roster-weekly/workers/AssignedShiftCard';
import { NoShiftIndicator } from 'common-components/roster-control/roster-weekly/common/NoShiftIndicator';
import { ShiftSlotStatus } from 'utilities/enum-utils';

interface WorkerItemRowProps {
  worker: IRosterWorker;
  shifts: IRosterShift[];
  days: Date[];
  history: H.History;
  refreshData: () => void;
}

export function WorkerItemRow({ worker, shifts, refreshData, days, history }: WorkerItemRowProps) {
  // TODO : Change this to detect whether there are any shifts in the current day range.
  const hasShifts = true;
  const totalDuration = _.reduce(
    _.filter(
      shifts,
      (shift) =>
        shift.supportWorkerId === worker.supportWorkerId &&
        !_.find(
          [ShiftSlotStatus.CANCELLED, ShiftSlotStatus.CANCELLED_UNPAID],
          (filteredStatus) => shift.shiftSlotStatus === filteredStatus
        )
    ),
    (acc, item) => {
      return (
        acc + Number(moment.duration(moment(item.shiftEndDateTime).diff(moment(item.shiftStartDateTime))).asSeconds())
      );
    },
    0
  );

  return (
    <div className="flex-row flex-1 position-relative">
      <WorkerDetailsColumn costTotal={0} totalDuration={totalDuration} worker={worker} history={history} />

      <div className="flex-row flex-1 position-relative">
        {!hasShifts && <NoShiftIndicator type="worker" />}

        {_.map(days, (day, idx) => (
          <DayColumn
            content={
              <WorkerShiftCardList
                day={day}
                worker={worker}
                refreshData={refreshData}
                shifts={shifts}
                history={history}
              />
            }
            key={idx}
          />
        ))}
      </div>
    </div>
  );
}

function WorkerShiftCardList({
  worker,
  shifts,
  day,
  refreshData,
  history
}: {
  worker: IRosterWorker;
  shifts: IRosterShift[];
  day: Date;
  refreshData: () => void;
  history: H.History;
}) {
  const workerShifts = _.chain(shifts)
    .filter(
      (shift) => shift.supportWorkerId === worker.supportWorkerId && moment(shift.shiftStartDateTime).isSame(day, 'day')
    )
    .value();

  return (
    <>
      {_.map(workerShifts, (shift, idx) => (
        <AssignedShiftCard shift={shift} key={idx} refreshData={refreshData} history={history} />
      ))}
    </>
  );
}
