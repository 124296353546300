import React, { Component } from 'react';
import ActionModal from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import _ from 'lodash';
import { Form, Input, notification, Radio, InputNumber } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import Utils from '../../../utilities/Utils';
import { EditRecurringMode } from '../../../utilities/enum-utils';

interface IBookingEditInstructionsModelProps extends FormComponentProps {
  isOpen: boolean;
  selectedBookingItem: typeof state.bookingsStore.selectedBookingItem;
  doEditBookingInstruction: typeof dispatch.bookingsStore.doEditBookingInstruction;
  onClose: () => void;
}

interface IBookingEditInstructionsModelState {
  editingInstructions: string;
  step: number;
  selectedOption: EditRecurringMode;
  numberOfBookings: number;
}

class BookingEditInstructionsModel extends Component<
  IBookingEditInstructionsModelProps,
  IBookingEditInstructionsModelState
> {
  state = {
    editingInstructions: '',
    step: 1,
    selectedOption: EditRecurringMode.Current,
    numberOfBookings: 1
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ editingInstructions: '', step: 1 });
    onClose();
  };

  private _onChangeNumberOfBookings = (event) => {
    this.setState({ numberOfBookings: event });
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _onSaveChanges = async () => {
    const { doEditBookingInstruction, selectedBookingItem } = this.props;
    try {
      if (!selectedBookingItem.isRecurring || (selectedBookingItem.isRecurring && this.state.step === 2)) {
        let payload;
        if (!selectedBookingItem.isRecurring) {
          payload = {
            instructions: this.state.editingInstructions,
            bookingRequestId: selectedBookingItem.bookingRequestId,
            bookingId: selectedBookingItem.bookingId,
            isRecurring: false
          };
        } else {
          payload = {
            instructions: this.state.editingInstructions,
            bookingRequestId: selectedBookingItem.bookingRequestId,
            bookingId: selectedBookingItem.bookingId,
            isRecurring: true,
            editRecurringMode: this.state.selectedOption,
            numberOfBookings: this.state.numberOfBookings,
            startDateTime: this.props.selectedBookingItem.startDateTime
          };
        }
        await doEditBookingInstruction(payload);
        this._onCloseModal();
      } else {
        this.setState({ step: 2 });
      }
    } catch (e) {
      notification.error({ message: 'Edit booking instruction failed! Please try again.' });
    }
  };

  componentDidMount() {
    this.setState({ editingInstructions: this.props.selectedBookingItem.instructions });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      const { selectedBookingItem } = this.props;
      if (selectedBookingItem.instructions && Utils.isEmpty(this.state.editingInstructions) && this.props.isOpen) {
        this.setState({
          editingInstructions: selectedBookingItem.instructions
        });
      }
    }
  }

  render() {
    const { isOpen, selectedBookingItem } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title={
          <Text style={{ fontSize: 20 }}>
            Edit <b>instructions</b>
          </Text>
        }
        width="medium"
        onClose={this._onCloseModal}
        canCloseOutside
        showCloseButton
        verticalAlignment="highest"
      >
        {this.state.step === 1 && (
          <>
            <Text>
              You are editing instructions for{' '}
              <b>
                {selectedBookingItem.firstName} {selectedBookingItem.lastName}
              </b>
              's booking.
            </Text>
            <div className="mt-medium">
              <Form.Item className={'m-none'}>
                <Input.TextArea
                  placeholder={'Enter booking instructions...'}
                  autoSize={{ minRows: 10, maxRows: 10 }}
                  value={this.state.editingInstructions}
                  onChange={(e) => this.setState({ editingInstructions: e.target.value })}
                />
              </Form.Item>
            </div>
          </>
        )}
        {this.state.step === 2 && (
          <div>
            <div className="mb-small">
              <Text>
                The selected booking is part of a recurring booking series. Please select one of the following options
                for editing this booking.
              </Text>
            </div>
            <Radio.Group
              value={this.state.selectedOption}
              onChange={this._onChangeOption}
              className="ml-medium mt-large"
            >
              <Radio
                value={EditRecurringMode.Current}
                className={`${this.state.selectedOption === EditRecurringMode.Current && 'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Apply edits to this booking only
                </div>
              </Radio>
              <br />
              <Radio
                value={EditRecurringMode.CurrentAll}
                className={`${this.state.selectedOption === EditRecurringMode.CurrentAll &&
                  'text-weight-bold'} mb-small  mt-medium`}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Apply edits for this booking and all following bookings
                </div>
              </Radio>
              <br />
              <Radio
                value={EditRecurringMode.CurrentNext}
                className={`${this.state.selectedOption === EditRecurringMode.CurrentNext &&
                  'text-weight-bold'} mb-small  mt-medium`}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Apply edits for this booking and the next{' '}
                  <InputNumber
                    className="mh-x-small"
                    style={{ width: '50px' }}
                    min={1}
                    max={this.props.selectedBookingItem.numberOfBookingLeft}
                    value={this.state.numberOfBookings}
                    onChange={this._onChangeNumberOfBookings}
                  />{' '}
                  booking
                  {this.state.numberOfBookings !== 1 && 's'}.
                </div>
                <br />
                <Text size={'regular'} color="secondary" weight="regular" className="ml-x2-large">
                  (There {this.props.selectedBookingItem.numberOfBookingLeft !== 1 ? 'are' : 'is'}{' '}
                  {this.props.selectedBookingItem.numberOfBookingLeft} left in the recurring series after the currently
                  selected booking)
                </Text>
              </Radio>
            </Radio.Group>
          </div>
        )}
        <div className="flex justify-end mt-large">
          <div>
            <GhostButton onClick={this._onCloseModal} size="large">
              Cancel
            </GhostButton>
          </div>
          <div className="ml-medium">
            <PrimaryButton onClick={this._onSaveChanges} size="large">
              Save changes
            </PrimaryButton>
          </div>
        </div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({ selectedBookingItem: state.bookingsStore.selectedBookingItem });

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditBookingInstruction: dispatch.bookingsStore.doEditBookingInstruction
});

export default connect(
  mapState,
  mapDispatch
)(BookingEditInstructionsModel);
