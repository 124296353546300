import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import qs from 'query-string';
import SpinningLoadingActionModel from '../../common-components/loading/SpinningLoadingActionModel';
import _ from 'lodash';

class PdfPreviewView extends Component<any, any> {
  state = {
    pdfData: null,
    onLoadSuccess: false,
    page: 1
  };

  async componentDidMount() {
    const {
      doFetchCustomerServiceAgreementPDF,
      doFetchPreviewInvoicePDF,
      doFetchViewInvoicePDF,
      doFetchPreviewInvoiceByLineItemPDF,
      location,
      doFetchPreviewCreditNoteByLineItemPDF
    } = this.props;

    const query = qs.parse(location.search);
    let data;

    switch (query.type) {
      case 'service-agreement':
        data = await doFetchCustomerServiceAgreementPDF({
          userServiceAgreementId: query.agreement,
          historyId: query.historyId ? query.historyId : null
        });
        break;
      case 'preview-invoice':
        data = await doFetchPreviewInvoicePDF({ invoiceNumber: query.invoicenumber });
        break;
      case 'view-invoice':
        data = await doFetchViewInvoicePDF({ invoiceId: query.invoiceId });
        break;
      case 'preview-invoice-by-line-items':
        const localStorageTag = `previewInvoiceLineItems-${query.time}`;

        data = await doFetchPreviewInvoiceByLineItemPDF({
          billingLineItemIds: _.split(localStorage.getItem(localStorageTag), ',')
        });
        localStorage.removeItem(localStorageTag);
        break;

      case 'preview-credit-note-by-line-items':
        const localStorageCreditNote = `previewCreditNoteLineItems-${query.time}`;
        data = await doFetchPreviewCreditNoteByLineItemPDF(JSON.parse(localStorage.getItem(localStorageCreditNote)));
        localStorage.removeItem(localStorageCreditNote);
        break;
    }

    data = 'data:application/pdf;base64,' + data;

    this.setState({ pdfData: data, onLoadSuccess: true });
  }

  render() {
    if (this.state.pdfData) {
      return (
        <div style={{ width: '100%', height: '100vh', minHeight: '100%' }}>
          <embed
            src={this.state.pdfData}
            style={{ width: '100%', height: '100%' }}
            type="application/pdf"
            width={'100%'}
            height={'100%'}
          />
        </div>
      );
    }
    return (
      <div>
        <SpinningLoadingActionModel isOpen={true} />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({ selectedCustomer: state.customersStore.selectedCustomer });
const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchCustomerServiceAgreementPDF: dispatch.customersStore.doFetchCustomerServiceAgreementPDF,
  doFetchPreviewInvoicePDF: dispatch.billingsStore.doFetchPreviewInvoicePDF,
  doFetchViewInvoicePDF: dispatch.billingsStore.doFetchViewInvoicePDF,
  doFetchPreviewInvoiceByLineItemPDF: dispatch.billingsStore.doFetchPreviewInvoiceByLineItemPDF,
  doFetchPreviewCreditNoteByLineItemPDF: dispatch.billingsStore.doFetchPreviewCreditNoteByLineItemPDF
});

export default connect(
  mapState,
  mapDispatch
)(PdfPreviewView);
