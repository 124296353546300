// noinspection DuplicatedCode

import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Text } from 'common-components/typography';
import { IconButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Avatar, Checkbox, Col, Row } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { Popover, Spinner } from '@blueprintjs/core';
import { ISession } from 'interfaces/session-interfaces';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { GridHeader, GridRow } from 'common-components/grids';
import { StatusTag } from 'common-components/tags';
import { ShiftStatusTag } from '../shift-slot-table/ShiftStatusTag';
import { IShiftSlot } from 'interfaces/shift-interfaces';

interface IRemoveTeamMemberModalProps {
  isOpen: boolean;
  shiftSlot: IShiftSlot | any;
  onClose: any;
  doRemoveShiftSlot: typeof dispatch.groupServiceStore.doRemoveShiftSlot;
  session: ISession | any;
}

interface IRemoveTeamMemberModalState {
  step: number;
  canManuallyClose: boolean;
  refreshShiftSlots: boolean;
}

class RemoveTeamMemberModal extends Component<IRemoveTeamMemberModalProps, IRemoveTeamMemberModalState> {
  state = { step: 1, canManuallyClose: true, refreshShiftSlots: false };

  reset = () => this.setState({ step: 1, canManuallyClose: true, refreshShiftSlots: false });

  onRemoveTeamMember = async () => {
    const { doRemoveShiftSlot, session, shiftSlot } = this.props;
    const { serviceDateTimeId, serviceId } = session;
    const { supportWorkerAttendanceId } = shiftSlot;
    this.setState({ step: 2, canManuallyClose: false });
    const request = {
      serviceDateTimeId,
      serviceId,
      supportWorkerAttendanceIds: [supportWorkerAttendanceId],
      isRemoveTeamMemberOnly: true
    };

    await doRemoveShiftSlot(request);
    this.setState({ step: 3, canManuallyClose: true, refreshShiftSlots: true });
  };

  onClose = () => {
    const { onClose } = this.props;
    onClose({ targetFlag: 'isRemoveTeamMemberOpen' }, this.state.refreshShiftSlots);
    this.reset();
  };

  render() {
    const { shiftSlot, session } = this.props;
    const { timezone } = session;

    if (_.isEmpty(shiftSlot)) {
      return <></>;
    }
    const { shiftStartDateTime, shiftEndDateTime } = shiftSlot;

    const dateDisplay =
      `${moment.tz(shiftStartDateTime, timezone).format('hh:mm A')}` +
      `- ${moment.tz(shiftEndDateTime, timezone).format('hh:mm A, Do MMMM YYYY')}`;
    const workerFullName = `${shiftSlot.firstName || ''} ${shiftSlot.lastName || ''}`;
    const modalTitle = 'Remove team member';

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title={modalTitle}
        onClose={this.onClose}
        canCloseOutside={this.state.canManuallyClose}
      >
        {this.state.step === 1 && (
          <div>
            <div className="mb-medium">
              <Text>You are removing a team member from the following shift : </Text>
            </div>

            <div className="line-height-135 mb-medium">
              <FieldLabel text={'SHIFT TIME'} />
              <Text lineHeight={135}>{dateDisplay}</Text>
            </div>

            <div className="line-height-135 mb-large">
              <FieldLabel text={'TEAM MEMBER TO REMOVE'} />
              <div className="flex-row line-height-135 align-center mt-x2-small">
                <Avatar icon="user" src={shiftSlot.attachmentUrl} className="mr-small" />
                <Text lineHeight={135}>{workerFullName}</Text>
              </div>
            </div>

            <ActionModalFooter align="right">
              <div className="flex-row align-center justify-end">
                <SecondaryButton size="large" className="mr-medium" onClick={this.onClose}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this.onRemoveTeamMember}>
                  Remove team member
                </PrimaryButton>
                {/*
                  <Popover
                  content={
                    <ActionMenu>
                      <ActionMenuItem text={'Assign to team member'} />
                    </ActionMenu>
                  }
                  position="top-left"
                  usePortal={false}
                  minimal={true}
                >
                  <IconButton size="large" icon={'down'} className="ml-x2-small rounded-right" />
                </Popover>
                  */}
              </div>
            </ActionModalFooter>
          </div>
        )}

        {this.state.step === 2 && (
          <div className="line-height-135 anim-slide-left">
            <div className="flex-column align-center mv-large justify-center">
              <div className="mb-medium">
                <Spinner size={80} />
              </div>
              <div className="text-align-center">
                <Text color="secondary" weight="bold">
                  Removing team member and updating shift...
                </Text>
                <br />
                <Text color="secondary">This won't take long.</Text>
              </div>
            </div>
          </div>
        )}

        {this.state.step === 3 && (
          <div className="anim-fade-in-fast">
            <div className="mb-medium">
              <Text>
                You have successfully removed <b>{workerFullName}</b> from the following shift :
              </Text>
            </div>

            <div className="line-height-135 mb-medium">
              <FieldLabel text={'SHIFT TIME'} />
              <Text lineHeight={135}>{dateDisplay}</Text>
            </div>

            <div className="line-height-135 mb-large">
              <FieldLabel text={'REMOVED TEAM MEMBER'} />
              <div className="flex-row line-height-135 align-center mt-x2-small">
                <Avatar icon="user" src={shiftSlot.attachmentUrl} className="mr-small" />
                <Text lineHeight={135}>{workerFullName}</Text>
              </div>
            </div>

            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this.onClose}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {/* step 4 and after are used for other two branches in user story 10, not used for now. */}
        {this.state.step === 4 && (
          <div className="anim-fade-in-fast">
            <div className="mb-medium">
              <Text>
                This team member is also assigned to the following shifts. Please select if you would like to remove
                this team member from any of the following sessions in addition to the one you already selected.
              </Text>
            </div>
            <div className="line-height-135 mb-large width-full bordered shadow-container bg-tertiary p-small rounded-big">
              <div className="flex-row line-height-135 align-center mt-x2-small">
                <Avatar icon="user" src={shiftSlot.attachmentUrl} className="mr-small" />
                <Text lineHeight={135}>{workerFullName}</Text>
              </div>
            </div>
            <div className="bordered rounded-big shadow-container mb-medium">
              <GridHeader bordered containerClassName="border-width-small border-secondary ph-none">
                <Col span={2} className=" bg-white">
                  <Checkbox />
                </Col>
                <Col span={6} className=" bg-white">
                  <FieldLabel text="SESSION DATE" />
                </Col>
                <Col span={6} className=" bg-white pl-medium">
                  <FieldLabel text="SHIFT TIME" />
                </Col>
                <Col span={10} className=" bg-white pl-medium">
                  <FieldLabel text="STATUS" />
                </Col>
              </GridHeader>
              <GridRow>
                <Col span={2}>
                  <Checkbox />
                </Col>
                <Col span={6}>
                  <Text>Date</Text>
                </Col>
                <Col span={6}>
                  <Text>Time</Text>
                </Col>
                <Col span={10}>
                  <ShiftStatusTag shiftStatus="CONFIRMED" />
                </Col>
              </GridRow>
              <GridRow>
                <Col span={2}>
                  <Checkbox />
                </Col>
                <Col span={6}>
                  <Text>Date</Text>
                </Col>
                <Col span={6}>
                  <Text>Time</Text>
                </Col>
                <Col span={10}>
                  <ShiftStatusTag shiftStatus="PENDING" />
                </Col>
              </GridRow>
            </div>
            <ActionModalFooter align="right">
              <div className="flex-row align-center justify-end">
                <SecondaryButton size="large" className="mr-medium" onClick={this.onClose}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large">Finish</PrimaryButton>
              </div>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRemoveShiftSlot: dispatch.groupServiceStore.doRemoveShiftSlot
});

export default connect(
  mapState,
  mapDispatch
)(RemoveTeamMemberModal);
