import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { ErrorSVG } from 'assets/UndrawSVG';
import { Paragraph, Text, SubTitle } from 'common-components/typography';
import { PrimaryButton, SecondaryButton, HyperlinkButton } from 'common-components/buttons';
import { Spinner } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import WorkerStatusTag from 'common-components/tags/WorkerStatusTag';
import { Avatar, Col, Divider, notification, Row } from 'antd';
import { EditRecurringMode, ServiceType, ShiftSlotStatus } from 'utilities/enum-utils';
import moment from 'moment-timezone';
import _ from 'lodash';
import ShiftSlotStatusTag from 'common-components/tags/ShiftSlotStatusTag';

class BookingConfirmShiftForWorkerActionModel extends Component<any, any> {
  state = {
    step: 1,
    canManuallyClose: true,
    title: 'Confirm shift on behalf of Worker',
    conflicts: null,
    width: 'medium'
  };

  private _goToBooking = (bookingId) => {
    const { history } = this.props;
    history.push(`/bookings/details/${bookingId}`);
  };

  private _goToSession = (serviceId, serviceDateTime) => {
    const { history } = this.props;
    history.push(`/group-service/${serviceId}/session/details/${serviceDateTime}`);
  };

  private _renderView = () => {
    const { step, conflicts } = this.state;
    const { selectedBooking, displayTimezone } = this.props;

    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <div>
            <Paragraph>
              The assigned Worker for this shift has not yet confirmed. You can confirm on their behalf if they are
              unable to do so (the Worker will still receive notification).
            </Paragraph>
          </div>
          <div className="text-align-center pv-x-large">
            <WorkerStatusTag shiftSlotStatus={selectedBooking.shiftSlotStatus} size="x-large" />
            <div className="mt-medium">
              <Avatar
                icon="user"
                size={120}
                className="mb-small"
                shape="square"
                src={selectedBooking.workerAttachmentUrl}
              />
              <br />
              <b>
                <Text size="x-large">
                  {selectedBooking.workerFirstName} {selectedBooking.workerLastName}
                </Text>
              </b>
            </div>
          </div>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" icon="check" onClick={this._checkForConflict}>
              Confirm on behalf of Worker
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <div className="text-align-center">
            <div className="pv-large">
              <Spinner size={150} />
            </div>

            <Paragraph>Confirming this booking, won't be long...</Paragraph>
          </div>
        </div>
      );
    }

    if (step === 3) {
      return (
        <div className="anim-fade-in">
          <div className="text-align-left">
            <Paragraph>
              You have successfully confirmed this booking on behalf of {selectedBooking.workerFirstName}{' '}
              {selectedBooking.workerLastName}
            </Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 4) {
      return (
        <div className="anim-fade-in">
          <div className="pv-medium">
            <img src={ErrorSVG} alt="ERROR" style={{ width: '100%', height: '200px' }} />
          </div>
          <div className="text-align-center">
            <Paragraph>Oops something has gone wrong, please try again</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Go Back to Booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 5) {
      return (
        <>
          <div className="mb-medium">
            <Paragraph>
              The team member you have selected is assigned to the following shift(s), that occur at the same time as
              the booking you are attempting to assign them to.
            </Paragraph>
            <Paragraph>
              As these shift(s) are still <b>pending team member confirmation</b>, assigning the team member to the
              selected booking will remove them from the conflicting shift(s) listed below.
            </Paragraph>
            <div className={'mt-medium'}>
              <SubTitle>Selected booking</SubTitle>
              {moment.tz(selectedBooking.startDateTime, displayTimezone).format('DD MMMM YYYY')}
              <br />
              {moment.tz(selectedBooking.startDateTime, displayTimezone).format('HH:mm A')} -{' '}
              {moment.tz(selectedBooking.endDateTime, displayTimezone).format('HH:mm A')}
            </div>
          </div>
          <Row className="text-color-secondary text-uppercase pt-medium">
            <Col span={12} className="ph-medium">
              <SubTitle>Conflicting session/Booking</SubTitle>
            </Col>
            <Col span={12} className="ph-medium">
              <SubTitle>Team member status</SubTitle>
            </Col>
          </Row>
          <Divider className="mv-medium mb-none" />
          <div style={{ overflow: 'auto', maxHeight: '35vh' }} className={'mb-large'}>
            {_.map(conflicts, (conflict) => (
              <Row className="pv-large evenodd" type={'flex'} align={'middle'}>
                <Col span={12} className="ph-medium height-full">
                  <div>
                    <HyperlinkButton
                      color={'red-dark'}
                      weight={'bold'}
                      onClick={
                        conflict.conflictShift.serviceType === ServiceType.INDIVIDUAL
                          ? () => this._goToBooking(conflict.conflictShift.attendanceId)
                          : () =>
                              this._goToSession(
                                conflict.conflictShift.serviceId,
                                conflict.conflictShift.serviceDateTimeId
                              )
                      }
                    >
                      {conflict.conflictShift.serviceName}
                    </HyperlinkButton>
                    <br />
                    <Text>
                      {moment.tz(conflict.conflictShift.startDateTime, displayTimezone).format('D MMMM YYYY')}
                      <br />
                      {moment.tz(conflict.conflictShift.startDateTime, displayTimezone).format('hh:mm A')} -{' '}
                      {moment.tz(conflict.conflictShift.endDateTime, displayTimezone).format('hh:mm A')}
                    </Text>
                  </div>
                </Col>
                <Col span={12} className="ph-medium height-full">
                  <ShiftSlotStatusTag status={ShiftSlotStatus.PENDING} textSize={'x-large'} />
                </Col>
              </Row>
            ))}
          </div>
          <div className={'mb-small'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onAccept}>
                Continue
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
  };

  private _checkForConflict = async () => {
    const { selectedBooking, doCheckAssignWorker } = this.props;
    let conflicts = null;
    try {
      const result = await doCheckAssignWorker({
        bookingRequestId: selectedBooking.bookingRequestId,
        bookingId: selectedBooking.bookingId,
        editRecurringMode: EditRecurringMode.Current,
        workerId: selectedBooking.workerId,
        startDateTime: selectedBooking.startDateTime
      });
      conflicts = result.data;
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    if (conflicts && (conflicts.pendingShiftConflicts && conflicts.pendingShiftConflicts.length > 0)) {
      this.setState({
        step: 5,
        conflicts: conflicts.pendingShiftConflicts,
        title: 'Team member assigned to conflicting shift(s)',
        width: 'x-large'
      });
    } else {
      this._onAccept();
    }
  };

  private _onAccept = async () => {
    const { doConfirmShiftForWorker, selectedBooking } = this.props;
    this.setState({ step: 2, canManuallyClose: false, width: 'medium' });
    try {
      await doConfirmShiftForWorker({ bookingId: selectedBooking.bookingId, isRemovePendingShiftSlots: true });
      this._onComplete();
    } catch (e) {
      this._onError();
    }
  };

  private _onComplete = () => {
    this.setState({ step: 3, canManuallyClose: true, title: 'Booking successfully confirmed', width: 'medium' });
  };

  private _onError = () => {
    this.setState({ step: 4, canManuallyClose: true, width: 'medium' });
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    // Reset back to step 1
    this.setState({ step: 1, title: 'Confirm shift on behalf of Worker', width: 'medium' }, onClose);
  };

  render() {
    let { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title={this.state.title}
        width={this.state.width}
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this._renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBooking: state.bookingsStore.selectedBookingItem
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doConfirmShiftForWorker: dispatch.bookingsStore.doConfirmShiftForWorker,
  doCheckAssignWorker: dispatch.bookingsStore.doCheckAssignWorker
});

export default connect(
  mapState,
  mapDispatch
)(BookingConfirmShiftForWorkerActionModel);
