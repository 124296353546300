import { Tooltip2 } from '@blueprintjs/popover2';
import { Icon, Skeleton } from 'antd';
import { PrimaryButton } from 'common-components/buttons';
import { Text, Title } from 'common-components/typography';
import { History } from 'history';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import { CustomerStatusType } from 'utilities/enum-utils';
import ServiceAgreementsList from 'views/customers/details/tabs-panel/new-service-agreements/agreements-listing/ServiceAgreementsList';
import CreateAgreementModal from 'views/customers/details/tabs-panel/new-service-agreements/create-agreement/CreateAgreementModal';

interface INewServiceAgreementsPanelProps {
  doFetchServiceAgreements: typeof dispatch.customersStore.doFetchServiceAgreements;
  doFetchCompanyLite: typeof dispatch.companyStore.doFetchCompanyLite;
  hasEditPermission: boolean;
  selectedCustomer: any;
  history: History;
}

interface INewServiceAgreementsPanelState {
  isCreateModalOpen: boolean;
  isItemModalOpen: boolean;
  hasAddress: boolean;
  isLoading: boolean;
  selectedTab: string;
  openedServiceAgreementId?: string;
}

class NewServiceAgreementsPanel extends Component<INewServiceAgreementsPanelProps, INewServiceAgreementsPanelState> {
  state = {
    isCreateModalOpen: false,
    isItemModalOpen: false,
    hasAddress: true,
    isLoading: false,
    selectedTab: 'ALL',
    openedServiceAgreementId: null
  };

  private _onOpenCreateModal = () => this.setState({ isCreateModalOpen: true });
  private _onCloseCreateModal = () => this.setState({ isCreateModalOpen: false });

  async componentDidMount() {
    this.setState({ isLoading: true });

    if (this.props.history.location.state && this.props.history.location.state.openedServiceAgreementId) {
      this.setState({ openedServiceAgreementId: this.props.history.location.state.openedServiceAgreementId });
    }

    await this.props.doFetchCompanyLite([]);
    await this.props.doFetchServiceAgreements({});
    const customerPrimaryAddress = _.find(
      this.props.selectedCustomer.addresses,
      (address) => address.isPrimary && address.fullAddress
    );
    if (customerPrimaryAddress) {
      this.setState({ hasAddress: true });
    } else {
      this.setState({ hasAddress: false });
    }
    this.setState({ isLoading: false });
  }

  componentDidUpdate(prevProps: Readonly<INewServiceAgreementsPanelProps>) {
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      const customerPrimaryAddress = _.find(
        this.props.selectedCustomer.addresses,
        (address) => address.isPrimary && address.fullAddress
      );
      if (customerPrimaryAddress) {
        this.setState({ hasAddress: true });
      } else {
        this.setState({ hasAddress: false });
      }
    }
  }

  render() {
    const { hasEditPermission, selectedCustomer } = this.props;
    const { isLoading, selectedTab, openedServiceAgreementId } = this.state;
    return (
      <div>
        {/* Top Header */}
        <div className="flex-row justify-between align-end pb-large">
          <div>
            <Title level={2} className="mv-none">
              Service Agreements
            </Title>
            <Text color="secondary">Manage service agreements for this customer.</Text>
          </div>
          {!isLoading && (
            <div className="flex-column justify-end">
              {hasEditPermission && selectedCustomer.customerStatus !== CustomerStatusType.ARCHIVED && (
                <div className="flex-row align-center">
                  <PrimaryButton
                    icon="plus"
                    size="large"
                    onClick={this._onOpenCreateModal}
                    disabled={!this.state.hasAddress}
                  >
                    Create Service Agreement
                  </PrimaryButton>
                  {!this.state.hasAddress && (
                    <Tooltip2 position="top" content={'Customer missing primary address'}>
                      <Icon type="question-circle" className={`text-size-x-large ml-medium text-color-red`} />
                    </Tooltip2>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {isLoading ? (
          <Skeleton paragraph={{ rows: 5, width: '100%' }} active={true} className="anim-slide-left" />
        ) : (
          <div id="content-container">
            <ServiceAgreementsList
              openedServiceAgreementId={openedServiceAgreementId}
              hasEditPermission={hasEditPermission}
              paymentSourceFilter={selectedTab}
            />
          </div>
        )}

        {/* Modals */}
        <CreateAgreementModal onClose={this._onCloseCreateModal} isOpen={this.state.isCreateModalOpen} />
      </div>
    );
  }
}
const mapState = (state: IRootState) => ({
  selectedCustomer: state.customersStore.selectedCustomer
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServiceAgreements: dispatch.customersStore.doFetchServiceAgreements,
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite
});

export default connect(mapState, mapDispatch)(NewServiceAgreementsPanel);
