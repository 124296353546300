import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { Spinner } from '@blueprintjs/core';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { notification } from 'antd';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { connect } from 'react-redux';

interface IFundingDeleteModalProps {
  isOpen: boolean;
  onClose: () => void;
  fundingPackageId: string;
  doDeleteFundingPackage: typeof dispatch.customersStore.doDeleteFundingPackage;
}

class FundingDeleteModal extends Component<IFundingDeleteModalProps, any> {
  state = { step: 1, title: 'Delete funding package?', canManuallyClose: false };

  private _renderView = () => {
    const { step } = this.state;

    if (step === 1) {
      return (
        <div className="anim-fade-in mt-small">
          <Paragraph>Are you sure you want to delete funding package?</Paragraph>
          <Paragraph>
            Deleting a funding package may impact the generation of billing line items for this customer.
          </Paragraph>
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this._onCloseModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" color={'red'} onClick={this._onDelete}>
              Delete
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right text-align-center">
          <div className="pv-large">
            <Spinner size={100} />
          </div>

          <Paragraph>Deleting this funding, won't be long...</Paragraph>
        </div>
      );
    }

    if (step === 3) {
      return (
        <div className="anim-fade-in mt-small">
          <Paragraph>This funding package has been successfully deleted.</Paragraph>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  private _onDelete = async () => {
    this.setState({ step: 2, canManuallyClose: false });
    try {
      await this.props.doDeleteFundingPackage({ fundingPackageId: this.props.fundingPackageId });
      this.setState({ step: 3, title: 'Funding package deleted' });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    // Reset back to step 1
    this.setState({ step: 1, title: 'Delete funding package?' });
    onClose();
  };

  render() {
    let { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title={this.state.title}
        width="medium"
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>
          <div>{this._renderView()}</div>
        </div>
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doDeleteFundingPackage: dispatch.customersStore.doDeleteFundingPackage
});

export default connect(
  null,
  mapDispatch
)(FundingDeleteModal);
