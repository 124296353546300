import {
  IRosterCustomer,
  IRosterCustomerWithBookings
} from 'common-components/roster-control/interfaces/roster-interfaces';
import { ColumnGroupHeader } from 'common-components/roster-control/roster-daily/content-pane/customers/ColumnGroupHeader';
import { Collapse } from '@blueprintjs/core';
import { CustomersColumn } from 'common-components/roster-control/roster-daily/content-pane/customers/CustomersColumn';
import React from 'react';

import _ from 'lodash';
import { EmptySideColumn } from 'common-components/roster-control/roster-daily/content-pane/common/EmptySideColumn';
import * as H from 'history';

interface CustomersSideColumnProps {
  customers: IRosterCustomer[];
  isCustomersOpen: boolean;
  setCustomersOpen: any;
  bookings: IRosterCustomerWithBookings[];
  history: H.History;
}

export function CustomersSideColumn({
  customers = [],
  setCustomersOpen = () => {},
  isCustomersOpen = true,
  bookings = [],
  history
}: CustomersSideColumnProps) {
  const hasCustomers = _.size(customers) > 0;

  return (
    <div>
      {/* Customers header */}
      <ColumnGroupHeader text="Customers" onClick={() => setCustomersOpen(!isCustomersOpen)} isOpen={isCustomersOpen} />

      <Collapse isOpen={isCustomersOpen} keepChildrenMounted={false}>
        <div className="flex-column">
          {!hasCustomers && <EmptySideColumn />}

          {/*{_.map(customers, (customer, idx) => {*/}
          {/*  const revenueAmount = _.reduce(*/}
          {/*    _.filter(*/}
          {/*      bookings,*/}
          {/*      (booking) =>*/}
          {/*        booking.bookerUserId === customer.customerId &&*/}
          {/*        !_.find(*/}
          {/*          [*/}
          {/*            BookingStatus.CUSTOMER_CANCELLED,*/}
          {/*            BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE,*/}
          {/*            BookingStatus.BUSINESS_CANCELLED*/}
          {/*          ],*/}
          {/*          (filteredStatus) => booking.status === filteredStatus*/}
          {/*        )*/}
          {/*    ),*/}
          {/*    (acc, item) => {*/}
          {/*      return acc + Number(item.billingTotal);*/}
          {/*    },*/}
          {/*    0*/}
          {/*  );*/}
          {/*  return (*/}
          {/*    <CustomersColumn*/}
          {/*      key={customer.customerId}*/}
          {/*      customer={customer}*/}
          {/*      revenueAmount={revenueAmount}*/}
          {/*      history={history}*/}
          {/*    />*/}
          {/*  );*/}
          {/*})}*/}

          {_.map(customers, (customer) => (
            <CustomersColumn
              key={customer.customerId}
              customer={customer}
              history={history}
              bookings={getCustomerBookings(customer, bookings)}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
}

function getCustomerBookings(customer, bookings) {
  return _.get(_.find(bookings, (booking) => booking.customerId === customer.customerId), 'bookings');
}
