import React, { Component } from 'react';
import { Col, Form, Input, Radio, Row, Avatar, notification } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';

import { IAddCustomerDetailWizard, IAddCustomerGuardianDetailWizard } from 'src/interfaces/customer-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Title from 'antd/lib/typography/Title';
import DuplicateEmailFoundModal from 'views/customers/listing/components/DuplicateEmailFoundModal';
import InfoPanel from 'common-components/alerts/InfoPanel';

interface IAddCustomerStepInviteToAppProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setAddCustomerDetailWizard: typeof dispatch.customersStore.setAddCustomerDetailWizard;
  setSelectedCustomerUserId: typeof dispatch.customersStore.setSelectedCustomerUserId;
  doCheckCustomerEmailConflict: typeof dispatch.customersStore.doCheckCustomerEmailConflict;
  addCustomerDetailWizard: typeof state.customersStore.addCustomerDetailWizard;
  doCreateCustomer: typeof dispatch.customersStore.doCreateCustomer;
  doCreateConnectionRequest: typeof dispatch.customersStore.doCreateConnectionRequest;
}

interface IAddCustomerStepInviteToAppState {
  customerDetails: IAddCustomerDetailWizard;
  openEmailConflictModal: boolean;
  duplicateUser: IAddCustomerGuardianDetailWizard | null;
}

class AddCustomerStepInviteToApp extends Component<IAddCustomerStepInviteToAppProps, IAddCustomerStepInviteToAppState> {
  state = {
    customerDetails: this.props.addCustomerDetailWizard,
    openEmailConflictModal: false,
    duplicateUser: null
  };

  private _closeEmailConflictModal = () => {
    this.setState({ openEmailConflictModal: false });
  };

  private _changeIsInviteToApp = (value) => {
    const { customerDetails } = this.state;

    const newCustomerDetails = { ...customerDetails, isInviteToApp: value };

    this.setState({ customerDetails: newCustomerDetails });
  };

  private _updateCustomerEmail = (fieldName, value) => {
    const { customerDetails } = this.state;
    this.setState({ customerDetails: { ...customerDetails, [fieldName]: _.trim(value) } });
  };

  private _validateBeforeNextStep = async () => {
    try {
      const {
        form,
        onNextStep,
        setAddCustomerDetailWizard,
        setSelectedCustomerUserId,
        doCheckCustomerEmailConflict,
        doCreateCustomer,
        doCreateConnectionRequest
      } = this.props;
      const { customerDetails } = this.state;

      let isFormValid = true;

      form.validateFields(async (err, value) => {
        if (err) {
          isFormValid = false;
        }
      });

      if (isFormValid) {
        if (!customerDetails.hasGuardians && form.getFieldValue('IsInviteToApp')) {
          const conflicts = await doCheckCustomerEmailConflict({ email: customerDetails.email });

          if (!_.isEmpty(conflicts)) {
            this.setState({
              openEmailConflictModal: true,
              duplicateUser: { ...conflicts[0], email: customerDetails.email }
            });
          } else {
            //*For case INVITE the customer
            setAddCustomerDetailWizard(customerDetails);
            const userId = await doCreateCustomer(customerDetails);
            setSelectedCustomerUserId({ customerUserId: userId });

            onNextStep();
          }
        } else {
          const customerDetailsRequest = { ...customerDetails };
          setAddCustomerDetailWizard(customerDetailsRequest);
          const userId = await doCreateCustomer(customerDetailsRequest);
          setSelectedCustomerUserId({ customerUserId: userId });
          onNextStep();
        }
      }
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.meta.message });
    }
  };

  render() {
    const { form, onPreviousStep } = this.props;
    const { customerDetails, openEmailConflictModal, duplicateUser } = this.state;

    const { getFieldDecorator } = form;

    return (
      <>
        {openEmailConflictModal && (
          <DuplicateEmailFoundModal
            isOpen={this.state.openEmailConflictModal}
            onClose={this._closeEmailConflictModal}
            duplicateUser={duplicateUser}
          />
        )}
        <div className="anim-slide-left pt-x3-large">
          <Row className="ph-x3-large">
            <Col span={6} style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div>
                <Paragraph size="x3-large" weight="bold">
                  Coming soon, GoodHuman’s customer app
                </Paragraph>
                <Paragraph className="mb-none">
                  <Text size="small" color="gray">
                    Customer
                  </Text>
                </Paragraph>
                <Paragraph className="mt-none mb-large">
                  {customerDetails.firstName + ' ' + customerDetails.lastName}
                </Paragraph>

                <Paragraph className="list-bullet">
                  Soon, customers will be able to access the
                  <span className={'text-weight-bolder'}> GoodHuman customer app </span> to:
                  <ul>
                    <li className="mv-large">Manage their bookings and profile information</li>
                    <li className="mv-large">Message you directly via the GoodHuman app</li>
                    <li className="mv-large">Track spend with each provider</li>
                  </ul>
                </Paragraph>
              </div>
            </Col>
            <Col span={18} className="pl-x-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
                <div className="mb-large">
                  <Title level={4} className="mb-large">
                    Invite to GoodHuman app?
                  </Title>
                  <Form.Item className={'m-none mb-large'}>
                    {getFieldDecorator('IsInviteToApp', {
                      initialValue: customerDetails.isInviteToApp,
                      rules: [
                        {
                          required: true,
                          message: 'Please select an option.'
                        }
                      ]
                    })(
                      <Radio.Group
                        defaultValue={false}
                        onChange={(value) => this._changeIsInviteToApp(value.target.value)}
                      >
                        <Radio className="mb-medium" value={false}>
                          No
                        </Radio>
                        <br />
                        <Radio disabled value={true}>
                          Yes
                        </Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>
                  <InfoPanel
                    text={
                      <div>
                        The GoodHuman customer app isn’t ready just yet. We’re working on the best experience for your
                        customers. Hold tight, you’ll be able to invite them soon.
                      </div>
                    }
                  />
                </div>
                {form.getFieldValue('IsInviteToApp') && !customerDetails.hasGuardians && (
                  <>
                    <div>
                      <Paragraph>
                        In order to invite the customer to the GoodHuman app we need an email address to act as their
                        login key.
                      </Paragraph>
                    </div>
                    <div className="mb-large flex-row flex-wrap">
                      <div style={{ maxWidth: '300px' }} className="mr-large">
                        <SubTitle>Email</SubTitle>
                        <Form.Item className={'m-none'}>
                          {getFieldDecorator('email', {
                            initialValue: customerDetails.email,
                            rules: [
                              {
                                required: true,
                                message: 'Please enter valid email'
                              }
                            ]
                          })(
                            <Input
                              style={{ width: '250px' }}
                              onChange={(value) => this._updateCustomerEmail('email', value.target.value)}
                              type="text"
                              size="large"
                              placeholder="Enter email here..."
                            />
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  </>
                )}
                {form.getFieldValue('IsInviteToApp') && customerDetails.hasGuardians && (
                  <>
                    <SubTitle>Invitation Details</SubTitle>
                    {_.map(this.state.customerDetails.guardians, (legalGuardian) => {
                      return (
                        <div className="flex-row align-center pt-small">
                          <Avatar size="large" icon="user" className="mr-medium" src={legalGuardian.attachmentUrl} />
                          <Text weight="bold">{legalGuardian.firstName + ' ' + legalGuardian.lastName}</Text>
                          <Text className="pl-medium text-underline" size="small" color="gray">
                            {legalGuardian.connected ? 'Already Invited' : legalGuardian.email}
                          </Text>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>

              <div className="pv-medium width-full" style={{ position: 'sticky', bottom: 0 }}>
                <Row gutter={0} type="flex" align="middle" className="bg-transparent">
                  <Col className="bg-transparent" span={24}>
                    <div className="text-align-right pv-large">
                      <SecondaryButton size="large" className="mr-medium" onClick={onPreviousStep}>
                        Back
                      </SecondaryButton>
                      <PrimaryButton size="large" onClick={this._validateBeforeNextStep}>
                        Create customer
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  addCustomerDetailWizard: state.customersStore.addCustomerDetailWizard
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setAddCustomerDetailWizard: dispatch.customersStore.setAddCustomerDetailWizard,
  setSelectedCustomerUserId: dispatch.customersStore.setSelectedCustomerUserId,
  doCheckCustomerEmailConflict: dispatch.customersStore.doCheckCustomerEmailConflict,
  doCreateCustomer: dispatch.customersStore.doCreateCustomer,
  doCreateConnectionRequest: dispatch.customersStore.doCreateConnectionRequest
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IAddCustomerStepInviteToAppProps>()(AddCustomerStepInviteToApp));
