import React, { Component } from 'react';
import { Form, Icon } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, Text, Title } from 'common-components/typography';
import { connect } from 'react-redux';
import { dispatch, IRootState, state } from 'stores/rematch/root-store';
import { ServiceType } from 'utilities/enum-utils';
import ServiceUtils from 'utilities/service-utils';
import { timeZone } from 'interfaces/timezone-type';
import globalConfig from '../../../../../variables/global-config';
import _ from 'lodash';

interface IServiceTypeStepPanelProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  setNewService: typeof dispatch.servicesStore.setNewService;
  newService: typeof state.servicesStore.newService;
  serviceProviderId: string;
  timezone: timeZone;
  portalUser: typeof state.authStore.portalUser;
}

interface ISelectCustomerStepPanelState {}

class ServiceTypeStepPanel extends Component<IServiceTypeStepPanelProps, ISelectCustomerStepPanelState> {
  state = {};

  private _GoToNextStepAndSetType = async (type) => {
    const { setNewService, onNextStep, timezone, serviceProviderId } = this.props;

    const resetedNewService = ServiceUtils.createNewEmptyService(serviceProviderId, timezone);
    setNewService({
      ...resetedNewService,
      serviceType: type
    });

    onNextStep();
  };

  render() {
    return (
      <div className="anim-slide-left mb-x-large">
        <Title level={2} weight="bolder" className="line-height-100">
          <span className="text-weight-regular">Create a new</span> Service
        </Title>

        <Paragraph>Tell us what type of service you would like to create.</Paragraph>

        <div
          className="mt-x3-large pv-medium ph-large bordered rounded shadow-box cursor-pointer hover-bg-tertiary flex-row align-center flex-grow"
          onClick={() => this._GoToNextStepAndSetType(ServiceType.INDIVIDUAL)}
        >
          <div className="width-full">
            {' '}
            <Title level={4}>Support Service</Title>
            <Text>
              Service such as providing care workers for participants to assist them at home or in the community.
              <br />
              For Support Service, customer can book by choosing a time in an availability window set by the Service
              Provider.
            </Text>
          </div>
          <div style={{ width: '25px' }}>
            <Icon type="right" className="text-size-x3-large text-color-tertiary" />
          </div>
        </div>

        <div
          className="mt-x3-large pv-medium ph-large bordered rounded shadow-box cursor-pointer hover-bg-tertiary flex-row align-center flex-grow"
          onClick={() => this._GoToNextStepAndSetType(ServiceType.GROUP)}
        >
          <div className="width-full">
            <Title level={4}>Group Service</Title>
            <Text>
              Services such as workshops, classrooms that are designed for groups.
              <br />
              For Group Service, customer can book available sessions created by the Service Provider.
            </Text>
          </div>
          <div style={{ width: '25px' }}>
            <Icon type="right" className="text-size-x3-large text-color-tertiary" />
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({ portalUser: state.authStore.portalUser });

export default connect(mapState)(Form.create<IServiceTypeStepPanelProps>()(ServiceTypeStepPanel));
