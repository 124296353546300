import { Text } from 'common-components/typography';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';
import { Col, Icon, Row } from 'antd';
import React, { PureComponent } from 'react';
import Search from 'antd/es/input/Search';
import CommonUtils from 'utilities/common-utils';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import Utils from 'utilities/Utils';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { FilterType, TeamStatus } from 'utilities/enum-utils';
import { IFilter } from 'interfaces/filter-interfaces';
import { Tooltip2 } from '@blueprintjs/popover2';
import moment from 'moment-timezone';
import { CustomerAdditionalInfos } from 'common-components/tooltips';

interface IFilterMenuSearchLookupProps {
  filter: IFilter;
  canRemove?: boolean;
  saveNewFilterValue: (filterType, newFilterValue, selectionLabel) => void;
  removeFilter: (filterType) => void;

  // Customers functions
  doGetCustomersLite: typeof dispatch.customersStore.doGetCustomersLite;
  setCustomerFilterLite: typeof dispatch.customersStore.setCustomerFilter;
  setCustomersLite: typeof dispatch.customersStore.setCustomersLite;
  customersLite: typeof state.customersStore.customersLite;

  // Worker functions
  doFetchWorkerList: typeof dispatch.teamStore.doFetchWorkerList;
  setWorkerFilter: typeof dispatch.teamStore.setWorkerFilter;
  setWorkerList: typeof dispatch.teamStore.setWorkerList;
  workerList: typeof state.teamStore.workerList;

  // Portal User functions
  doFetchBatchAuthors: typeof dispatch.billingsStore.doFetchBatchAuthors;
  setBatchAuthorsFilter: typeof dispatch.billingsStore.setBatchAuthorsFilter;
  setBatchAuthors: typeof dispatch.billingsStore.setBatchAuthors;
  batchAuthors: typeof state.billingsStore.batchAuthors;

  // Service Date Times functions
  doFetchSessions: typeof dispatch.groupServiceStore.doFetchSessions;
  setSessions: typeof dispatch.groupServiceStore.setSessions;
  sessions: typeof state.groupServiceStore.sessions;

  // Service Schedule functions
  doFetchGroupServiceSchedules: typeof dispatch.servicesStore.doFetchGroupServiceSchedules;
  setGroupServiceSchedules: typeof dispatch.servicesStore.setGroupServiceSchedules;
  groupServiceSchedules: typeof state.servicesStore.groupServiceSchedules;

  // Suburbs
  doFetchSuburbs: typeof dispatch.bookingsStore.doFetchSuburbs;
  setSuburbs: typeof dispatch.bookingsStore.setSuburbs;
  suburbs: typeof state.bookingsStore.suburbs;

  // Support Coordinators
  setSupportCoordinatorFilter: typeof dispatch.customersStore.setSupportCoordinatorFilter;
  doFetchSupportCoordinators: typeof dispatch.customersStore.doFetchSupportCoordinators;
  setSupportCoordinators: typeof dispatch.customersStore.setSupportCoordinators;
  supportCoordinators: typeof state.customersStore.supportCoordinators;

  // Case Managers
  setCaseManagerFilter: typeof dispatch.customersStore.setCaseManagerFilter;
  doFetchCaseManagers: typeof dispatch.customersStore.doFetchCaseManagers;
  setCaseManagers: typeof dispatch.customersStore.setCaseManagers;
  caseManagers: typeof state.customersStore.caseManagers;
}

interface IFilterMenuSearchLookupState {
  isSearching: boolean;
  localItemList: any;
  localSelectedItemList: any;
  searchedItemList: any;
}

class FilterMenuSearchLookup extends PureComponent<IFilterMenuSearchLookupProps, IFilterMenuSearchLookupState> {
  state = {
    isSearching: false,
    localItemList: [],
    localSelectedItemList: this.props.filter ? _.clone(this.props.filter.values) : [],
    searchedItemList: []
  };

  private _addSelection = (item) => {
    const { localSelectedItemList } = this.state;
    const newFilter = _.clone(localSelectedItemList);
    newFilter.push(item);
    this.setState({ localSelectedItemList: [...newFilter] });
  };

  private _closePopover = () => {
    this.setState({
      isSearching: false,
      localSelectedItemList: this.props.filter ? _.clone(this.props.filter.values) : []
    });
  };

  private _searchText = async (txt) => {
    this.setState({ isSearching: true });
    if (this.props.filter.filter === FilterType.CUSTOMER) {
      await this._getCustomerList(txt);
    } else if (
      this.props.filter.filter === FilterType.WORKER ||
      this.props.filter.filter === FilterType.PREFERRED_SUPPORT_WORKER ||
      this.props.filter.filter === FilterType.BLOCKED_SUPPORT_WORKER
    ) {
      await this._getWorkerList(txt);
    } else if (this.props.filter.filter === FilterType.BATCH_AUTHOR) {
      await this._getBatchAuthorList(txt);
    } else if (this.props.filter.filter === FilterType.SERVICE_DATE_TIMES) {
      await this._getServiceDateTimeList(txt);
    } else if (this.props.filter.filter === FilterType.GROUP_SERVICE_SCHEDULES) {
      await this._getGroupServiceSchedule(txt);
    } else if (this.props.filter.filter === FilterType.LOCATION_BY_SUBURBS) {
      await this._getSuburbs(txt);
    } else if (this.props.filter.filter === FilterType.SUPPORT_COORDINATOR) {
      await this._getSupportCoordinators(txt);
    } else if (this.props.filter.filter === FilterType.CASE_MANAGER) {
      await this._getCaseManagers(txt);
    }

    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    const searchLength = _.trim(e.target.value).length;
    if (searchLength >= 3) {
      this._debounceSearch(e.target.value);
    } else if (searchLength === 0) {
      this.setState({ localItemList: [] });
    }
  };

  private _applySelection = async () => {
    this.props.saveNewFilterValue(
      this.props.filter.filter,
      this.state.localSelectedItemList,
      CommonUtils.getFilterText(this.props.filter.filter, this.state.localSelectedItemList, this.state.localItemList)
    );
  };

  private _removeSelection = (value) => {
    this.setState({
      localSelectedItemList: _.filter(this.state.localSelectedItemList, (item) => item.value !== value)
    });
  };

  private _getCustomerList = async (searchTxt) => {
    const { setCustomerFilterLite, setCustomersLite, doGetCustomersLite } = this.props;
    if (Utils.isEmpty(searchTxt)) {
      setCustomerFilterLite({});
      setCustomersLite({});
      this.setState({ searchedItemList: [] });
    } else {
      await setCustomerFilterLite({ search: searchTxt });
      await doGetCustomersLite({ sortByRelevance: true });
    }
  };

  private _getWorkerList = async (searchTxt) => {
    const { setWorkerFilter, setWorkerList, doFetchWorkerList } = this.props;
    if (Utils.isEmpty(searchTxt)) {
      setWorkerFilter({});
      setWorkerList({});
      this.setState({ searchedItemList: [] });
    } else {
      setWorkerFilter([
        {
          key: 'search',
          value: searchTxt
        },
        {
          key: 'supportWorkerStatus',
          value: [TeamStatus.ENABLED, TeamStatus.DRAFT, TeamStatus.BLOCKED]
        }
      ]);
      await doFetchWorkerList({ sortByRelevance: true });
    }
  };

  private _getBatchAuthorList = async (searchTxt) => {
    const { setBatchAuthorsFilter, setBatchAuthors, doFetchBatchAuthors } = this.props;
    if (Utils.isEmpty(searchTxt)) {
      setBatchAuthorsFilter({});
      setBatchAuthors({});
      this.setState({ searchedItemList: [] });
    } else {
      setBatchAuthorsFilter([{ key: 'search', value: searchTxt }]);
      await doFetchBatchAuthors({});
    }
  };

  private _getServiceDateTimeList = async (searchTxt) => {
    const { setSessions, doFetchSessions } = this.props;
    if (Utils.isEmpty(searchTxt)) {
      setSessions({});
      this.setState({ searchedItemList: [] });
    } else {
      await doFetchSessions({ search: searchTxt, page: 1, pageSize: 100, pageTimestamp: new Date() });
    }
  };

  private _getGroupServiceSchedule = async (searchTxt) => {
    const { setGroupServiceSchedules, doFetchGroupServiceSchedules } = this.props;
    if (Utils.isEmpty(searchTxt)) {
      setGroupServiceSchedules({});
      this.setState({ searchedItemList: [] });
    } else {
      await doFetchGroupServiceSchedules({ search: searchTxt, page: 1, pageSize: 100, pageTimestamp: new Date() });
    }
  };

  private _getSuburbs = async (searchTxt) => {
    const { setSuburbs, doFetchSuburbs } = this.props;
    if (Utils.isEmpty(searchTxt)) {
      setSuburbs({});
      this.setState({ searchedItemList: [] });
    } else {
      await doFetchSuburbs({ searchString: searchTxt });
    }
  };

  private _getSupportCoordinators = async (searchTxt) => {
    const { setSupportCoordinatorFilter, setSupportCoordinators, doFetchSupportCoordinators } = this.props;
    if (Utils.isEmpty(searchTxt)) {
      setSupportCoordinatorFilter(null);
      setSupportCoordinators([]);
      this.setState({ searchedItemList: [] });
    } else {
      setSupportCoordinatorFilter(searchTxt);
      await doFetchSupportCoordinators({});
    }
  };

  private _getCaseManagers = async (searchTxt) => {
    const { setCaseManagerFilter, setCaseManagers, doFetchCaseManagers } = this.props;
    if (Utils.isEmpty(searchTxt)) {
      setCaseManagerFilter(null);
      setCaseManagers([]);
      this.setState({ searchedItemList: [] });
    } else {
      setCaseManagerFilter(searchTxt);
      await doFetchCaseManagers({});
    }
  };

  componentDidUpdate(prevProps: Readonly<IFilterMenuSearchLookupProps>) {
    const {
      customersLite,
      workerList,
      batchAuthors,
      suburbs,
      sessions,
      groupServiceSchedules,
      caseManagers,
      supportCoordinators
    } = this.props;
    if (prevProps.customersLite !== customersLite) {
      this.setState({
        searchedItemList: _.map(customersLite, (customer) => {
          return {
            name: customer.firstName + ' ' + customer.lastName,
            displayText: (
              <>
                {customer.firstName + ' ' + customer.lastName}
                <Text color={'secondary'}>{customer.locality && ' (' + customer.locality + ')'}</Text>
              </>
            ),
            value: customer.userId,
            tooltip: <CustomerAdditionalInfos customer={customer} getOnlyContent={true} />
          };
        })
      });
    }
    if (prevProps.workerList !== workerList) {
      this.setState({
        searchedItemList: _.map(workerList, (worker) => {
          return { displayText: worker.firstName + ' ' + worker.lastName, value: worker.supportWorkerId };
        })
      });
    }
    if (prevProps.batchAuthors !== batchAuthors) {
      this.setState({
        searchedItemList: _.map(batchAuthors, (portalUser) => {
          return { displayText: portalUser.firstName + ' ' + portalUser.lastName, value: portalUser.userId };
        })
      });
    }
    if (prevProps.suburbs !== suburbs) {
      this.setState({
        searchedItemList: _.map(suburbs, (suburb) => {
          return {
            displayText: (
              <>
                {suburb.suburb} ({suburb.postcode}) - <Text color={'secondary'}>{suburb.state}</Text>
              </>
            ),
            value: suburb.postcode
          };
        })
      });
    }
    if (prevProps.sessions !== sessions) {
      this.setState({
        searchedItemList: _.map(sessions, (session) => {
          return {
            displayText: (
              <>
                <Text>{moment.tz(session.startDateTime, session.timezone).format('DD/MM/YY')}</Text>{' '}
                <Text color={'secondary'}>
                  {moment.tz(session.startDateTime, session.timezone).format('h:mm a') +
                    ' - ' +
                    moment.tz(session.endDateTime, session.timezone).format('h:mm a')}
                </Text>
                <br />
                <Text>
                  {session.description ? session.description : session.scheduleName ? session.scheduleName : 'One-off'}{' '}
                  - {session.serviceName}
                </Text>
              </>
            ),
            value: session.serviceDateTimeId,
            tooltip: (
              <div className={'p-medium'} style={{ minWidth: '300px' }}>
                <div className={'mb-medium'}>
                  <Text size={'regular'} weight={'bold'} color={'white'}>
                    Session times
                  </Text>
                  <br />
                  {moment
                    .tz(session.startDateTime, session.timezone)
                    .startOf('day')
                    .isSame(moment.tz(session.endDateTime, session.timezone).startOf('day')) ? (
                    <Text color={'white'}>
                      {moment.tz(session.startDateTime, session.timezone).format('ddd, DD MMM YYYY')}
                      <br />
                      {moment.tz(session.startDateTime, session.timezone).format('h:mm a - ')}
                      {moment.tz(session.endDateTime, session.timezone).format('h:mm a')}
                    </Text>
                  ) : (
                    <Text color={'white'}>
                      {moment.tz(session.startDateTime, session.timezone).format('ddd, DD MMM YYYY h:mm a')}
                      <br />
                      {moment.tz(session.endDateTime, session.timezone).format('ddd, DD MMM YYYY h:mm a')}
                    </Text>
                  )}
                </div>
                <div className={'mb-medium'}>
                  <Text size={'regular'} weight={'bold'} color={'white'}>
                    Schedule
                  </Text>
                  <br />
                  <Text color={'white'}>{session.scheduleName ? session.scheduleName : 'One-off'}</Text>
                </div>
                <div className={'mb-medium'}>
                  <Text size={'regular'} weight={'bold'} color={'white'}>
                    Session description
                  </Text>
                  <br />
                  <Text color={'white'}>{session.description ? session.description : '-'}</Text>
                </div>
                <div className={'mb-medium'}>
                  <Text size={'regular'} weight={'bold'} color={'white'}>
                    Service
                  </Text>
                  <br />
                  <Text color={'white'}>{session.serviceName}</Text>
                </div>
                <div className={'mb-medium'}>
                  <Text size={'regular'} weight={'bold'} color={'white'}>
                    Location
                  </Text>
                  <br />
                  <Text color={'white'} style={{ whiteSpace: 'pre-line' }}>
                    {session.address ? CommonUtils.formatFullAddress(session.address) : '-'}
                  </Text>
                </div>
              </div>
            )
          };
        })
      });
    }
    if (prevProps.groupServiceSchedules !== groupServiceSchedules) {
      this.setState({
        searchedItemList: _.map(groupServiceSchedules, (schedule) => {
          return {
            displayText: (
              <>
                <Text>
                  {moment.tz(schedule.scheduleStartDate, schedule.timezone).format('DD/MM/YY')} -{' '}
                  {moment.tz(schedule.scheduleEndDate, schedule.timezone).format('DD/MM/YY')}
                </Text>
                <br />
                <Text>
                  {schedule.scheduleName ? schedule.scheduleName : 'One-off'} - {schedule.serviceName}
                </Text>
              </>
            ),
            value: schedule.serviceScheduleId,
            tooltip: (
              <div className={'p-medium'} style={{ minWidth: '300px' }}>
                <div className={'mb-medium'}>
                  <Text size={'regular'} weight={'bold'} color={'white'}>
                    Schedule dates
                  </Text>
                  <br />
                  <Text color={'white'}>
                    {moment.tz(schedule.scheduleStartDate, schedule.timezone).format('ddd, DD MMM YYYY')}
                    <br />
                    {moment.tz(schedule.scheduleEndDate, schedule.timezone).format('ddd, DD MMM YYYY')}
                  </Text>
                </div>
                <div className={'mb-medium'}>
                  <Text size={'regular'} weight={'bold'} color={'white'}>
                    Name
                  </Text>
                  <br />
                  <Text color={'white'}>{schedule.scheduleName ? schedule.scheduleName : 'One-off'}</Text>
                </div>
                <div className={'mb-medium'}>
                  <Text size={'regular'} weight={'bold'} color={'white'}>
                    Service
                  </Text>
                  <br />
                  <Text color={'white'}>{schedule.serviceName}</Text>
                </div>
                <div className={'mb-medium'}>
                  <Text size={'regular'} weight={'bold'} color={'white'}>
                    Location
                  </Text>
                  <br />
                  <Text color={'white'} style={{ whiteSpace: 'pre-line' }}>
                    {schedule.address ? CommonUtils.formatFullAddress(schedule.address) : '-'}
                  </Text>
                </div>
              </div>
            )
          };
        })
      });
    }
    if (prevProps.caseManagers !== caseManagers) {
      this.setState({
        searchedItemList: _.map(caseManagers, (caseManager) => ({
          displayText: caseManager.fullName,
          value: caseManager.userContactId
        }))
      });
    }
    if (prevProps.supportCoordinators !== supportCoordinators) {
      this.setState({
        searchedItemList: _.map(supportCoordinators, (supportCoordinator) => ({
          displayText: supportCoordinator.fullName,
          value: supportCoordinator.userContactId
        }))
      });
    }
  }

  render() {
    const { searchedItemList, localSelectedItemList } = this.state;
    const filterSettings = CommonUtils.getFilterSettings(this.props.filter.filter);
    const filteredSearchedItemList = _.filter(
      searchedItemList,
      (filter) => !_.find(localSelectedItemList, (item) => filter.value === item.value)
    );

    return (
      <div style={{ width: '800px' }}>
        <div className={'pv-small ph-medium'}>
          <Text size={'x-large'} weight={'bold'}>
            {filterSettings.title}
          </Text>
        </div>
        <Row>
          <Col span={12}>
            <div className="ph-medium pt-small bordered border-standard-gray" style={{ height: '42px' }}>
              <Text>
                Filtered {filterSettings.title} ({localSelectedItemList.length})
              </Text>
            </div>
            <div
              style={{ height: '200px', overflow: 'auto' }}
              className={' bordered-bottom bordered-right border-standard-gray'}
            >
              {localSelectedItemList && localSelectedItemList.length > 0 ? (
                _.map(localSelectedItemList, (item, key) => (
                  <div
                    className={`flex-row justify-between pv-x-small ph-medium align-center ${(this.props.filter
                      .filter === FilterType.GROUP_SERVICE_SCHEDULES ||
                      this.props.filter.filter === FilterType.SERVICE_DATE_TIMES) &&
                      'bordered-bottom border-color-quaternary'}`}
                    key={key}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '320px'
                      }}
                    >
                      {item.displayText}
                    </div>
                    {!_.isEmpty(item.tooltip) && (
                      <Tooltip2 content={item.tooltip} position={'bottom-right'}>
                        <div className={'cursor-help mh-small'}>
                          <Icon type={'info-circle'} className={'text-color-tertiary'} />
                        </div>
                      </Tooltip2>
                    )}
                    <div>
                      <HyperlinkButton
                        color={'blue-action'}
                        className={'border-blue-action'}
                        onClick={() => this._removeSelection(item.value)}
                      >
                        <Icon type={'close'} />
                      </HyperlinkButton>
                    </div>
                  </div>
                ))
              ) : (
                <div className={'pv-large text-align-center'}>
                  <Text color={'secondary'}>
                    No {filterSettings.title} selected.
                    <br /> Search and add on the right.
                  </Text>
                </div>
              )}
            </div>
          </Col>
          <Col span={12}>
            <div className="pv-x-small ph-medium bordered-top bordered-bottom border-standard-gray">
              {filterSettings.canSearch && (
                <Search
                  placeholder={filterSettings.searchPlaceHolder}
                  onChange={this._onEnterSearchText}
                  loading={this.state.isSearching}
                  allowClear={true}
                  autoFocus={true}
                />
              )}
            </div>
            <div
              style={{ height: '200px', overflow: 'auto' }}
              className={' bordered-bottom bordered-right border-standard-gray'}
            >
              {this.state.isSearching ? (
                <SpinningLoader size={50} message={''} />
              ) : filteredSearchedItemList && filteredSearchedItemList.length > 0 ? (
                _.map(filteredSearchedItemList, (item, key) => (
                  <div
                    className={`flex-row justify-between pv-x-small ph-medium hover-bg-secondary cursor-pointer align-center ${(this
                      .props.filter.filter === FilterType.GROUP_SERVICE_SCHEDULES ||
                      this.props.filter.filter === FilterType.SERVICE_DATE_TIMES) &&
                      'bordered-bottom border-color-quaternary'}`}
                    key={key}
                    onClick={() => this._addSelection(item)}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        width: '320px'
                      }}
                    >
                      {item.displayText}
                    </div>
                    {!_.isEmpty(item.tooltip) && (
                      <Tooltip2 content={item.tooltip} position={'bottom-right'}>
                        <div className={'cursor-help mh-small'}>
                          <Icon type={'info-circle'} className={'text-color-tertiary'} />
                        </div>
                      </Tooltip2>
                    )}
                    <div>
                      <HyperlinkButton color={'blue-action'} className={'border-blue-action'}>
                        Add
                      </HyperlinkButton>
                    </div>
                  </div>
                ))
              ) : searchedItemList && searchedItemList.length === 0 ? (
                <div className={'pv-large text-align-center'}>
                  <Text color={'secondary'}>No result found.</Text>
                </div>
              ) : (
                <></>
              )}
            </div>
          </Col>
        </Row>
        <div className="p-medium">
          <div className={'flex-row justify-between'}>
            <div>
              {this.props.canRemove && (
                <GhostButton color={'red'} onClick={() => this.props.removeFilter(this.props.filter.filter)}>
                  Remove filter
                </GhostButton>
              )}
            </div>
            <div>
              <SecondaryButton
                color={'blue-action'}
                className={'mr-medium bp3-popover-dismiss'}
                onClick={this._closePopover}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                color={'blue-action'}
                className={'bp3-popover-dismiss border-blue-action'}
                onClick={this._applySelection}
              >
                Apply
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  customersLite: state.customersStore.customersLite,
  workerList: state.teamStore.workerList,
  batchAuthors: state.billingsStore.batchAuthors,
  sessions: state.groupServiceStore.sessions,
  groupServiceSchedules: state.servicesStore.groupServiceSchedules,
  suburbs: state.bookingsStore.suburbs,
  caseManagers: state.customersStore.caseManagers,
  supportCoordinators: state.customersStore.supportCoordinators
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetCustomersLite: dispatch.customersStore.doGetCustomersLite,
  setCustomerFilterLite: dispatch.customersStore.setCustomerFilterLite,
  setCustomersLite: dispatch.customersStore.setCustomersLite,
  doFetchWorkerList: dispatch.teamStore.doFetchWorkerList,
  setWorkerFilter: dispatch.teamStore.setWorkerFilter,
  setWorkerList: dispatch.teamStore.setWorkerList,
  doFetchBatchAuthors: dispatch.billingsStore.doFetchBatchAuthors,
  setBatchAuthorsFilter: dispatch.billingsStore.setBatchAuthorsFilter,
  setBatchAuthors: dispatch.billingsStore.setBatchAuthors,
  doFetchSessions: dispatch.groupServiceStore.doFetchSessions,
  setSessions: dispatch.groupServiceStore.setSessions,
  doFetchGroupServiceSchedules: dispatch.servicesStore.doFetchGroupServiceSchedules,
  setGroupServiceSchedules: dispatch.servicesStore.setGroupServiceSchedules,
  doFetchSuburbs: dispatch.bookingsStore.doFetchSuburbs,
  setSuburbs: dispatch.bookingsStore.setSuburbs,
  setSupportCoordinatorFilter: dispatch.customersStore.setSupportCoordinatorFilter,
  setSupportCoordinators: dispatch.customersStore.setSupportCoordinators,
  doFetchSupportCoordinators: dispatch.customersStore.doFetchSupportCoordinators,
  setCaseManagerFilter: dispatch.customersStore.setCaseManagerFilter,
  setCaseManagers: dispatch.customersStore.setCaseManagers,
  doFetchCaseManagers: dispatch.customersStore.doFetchCaseManagers
});

export default connect(mapState, mapDispatch)(FilterMenuSearchLookup);
