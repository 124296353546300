import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { Paragraph } from 'common-components/typography';
import ActionModal from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';

interface IDeleteLineItemModalProps {
  closeModal: () => void;
  deleteLineItem: (selectedLineItemForDelete) => void;
  selectedLineItem: string;
  isOpen: boolean;
}

class DeleteLineItemModal extends Component<IDeleteLineItemModalProps> {
  render() {
    return (
      <div>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this.props.closeModal}
          title={'Delete line item'}
          showCloseButton={true}
          width={'medium'}
        >
          <div>
            <Paragraph>Are you sure you want to delete this line item?</Paragraph>
            <div className={'mb-small mt-x2-large'}>
              <Row type={'flex'} className={'justify-end'}>
                <Col className="mr-large">
                  <GhostButton size="large" onClick={this.props.closeModal}>
                    Cancel
                  </GhostButton>
                </Col>
                <Col>
                  <PrimaryButton
                    size="large"
                    color={'red-dark'}
                    onClick={() => this.props.deleteLineItem(this.props.selectedLineItem)}
                  >
                    Delete
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </div>
        </ActionModal>
      </div>
    );
  }
}

export default DeleteLineItemModal;
