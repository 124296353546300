export const ROSTER_DAILY_CONFIG = {
  // left content panel
  CONTENT_PANEL_WIDTH: 320,

  // top hour block height/width
  HOUR_BLOCK_HEIGHT: 34,
  HOUR_BLOCK_WIDTH: 120,

  // content group
  CONTENT_GROUP_HEIGHT: 56,

  // Timeline row
  TIMELINE_ROW_HEIGHT: 80,
  TIMELINE_CARD_HEIGHT: 80 - 8,
  TIMELINE_CARD_PADDING: 4,

  // Footer
  FOOTER_BLOCK_HEIGHT: 18
};
