/* eslint-disable */
import React, { PureComponent } from 'react';

import { Button, Col, Divider, message, Row, Tag, Tooltip } from 'antd';
import CustomerDetailsBookingItem from './booking/CustomerDetailsBookingItem';
import { ICustomer } from 'interfaces/customer-interfaces';

import { Text, Title } from 'common-components/typography';
import moment from 'moment';
import _ from 'lodash';
import { RouteComponentProps } from 'react-router-dom';
import CustomerBookingListFilterPanel from 'views/customers/details/tabs-panel/booking/CustomerBookingListFilterPanel';
import Utils from 'utilities/Utils';

interface ICustomerDetailsBookingPanelProps extends RouteComponentProps {
  selectedCustomer?: ICustomer;
  onRefresh: () => void;
}

interface ICustomerDetailsBookingPanelState {
  isLoading: boolean;
  bookingsFilter: any;
}

class CustomerDetailsBookingPanel extends PureComponent<
  ICustomerDetailsBookingPanelProps,
  ICustomerDetailsBookingPanelState
> {
  state = {
    isLoading: false,
    filterStatus: null,
    bookingsFilter: { status: '', startDate: null, endDate: null }
  };

  _onRefresh = async () => {
    const { onRefresh } = this.props;
    this.setState({ isLoading: true });
    // message.info('Fetching latest data...');
    const dismissMessage = message.loading('Fetching latest bookings...', 20);
    await onRefresh();
    // message.info('Refresh Successful');
    dismissMessage();
    message.success('Bookings refreshed.');
    this.setState({ isLoading: false });
  };

  private _handleStatus = async (event) => {
    this.setState({ bookingsFilter: { ...this.state.bookingsFilter, status: event } });
  };

  private _handleStartDate = async (startDate) => {
    this.setState({ bookingsFilter: { ...this.state.bookingsFilter, startDate } });
  };

  private _handleEndDate = async (endDate) => {
    this.setState({ bookingsFilter: { ...this.state.bookingsFilter, endDate } });
  };

  private _filterBookings = () => {
    const { bookingsFilter } = this.state;
    let filteredBookings = _.filter(
      this.props.selectedCustomer.bookings,
      (booking) =>
        (Utils.isEmpty(bookingsFilter.status) || booking.bookingStatus === bookingsFilter.status) &&
        (!bookingsFilter.startDate ||
          moment(booking.startDateTime).isSameOrAfter(moment(bookingsFilter.startDate).startOf('day'))) &&
        (!bookingsFilter.endDate ||
          moment(booking.endDateTime).isSameOrBefore(moment(bookingsFilter.endDate).endOf('day')))
    );

    return _.orderBy(filteredBookings, 'startDateTime', 'desc');
  };

  render() {
    const { selectedCustomer, match, location, history } = this.props;

    if (_.isEmpty(selectedCustomer)) {
      return <></>;
    }

    const filteredBookings = this._filterBookings();

    return (
      <div>
        <Title level={2} className={'m-none'}>
          Bookings at a glance
        </Title>

        <Text type={'secondary'}>Bookings overview for this customer.</Text>
        <div className="bg-white mt-large">
          <CustomerBookingListFilterPanel
            filters={this.state.bookingsFilter}
            handleStatus={this._handleStatus}
            handleStartDate={this._handleStartDate}
            handleEndDate={this._handleEndDate}
          />
        </div>
        <br />

        <Divider className="divider-medium" />

        <Row type={'flex'} justify={'space-between'} align={'middle'} className="mb-medium">
          <Col>
            <div className="">
              <Text className="text-size-small">
                <Tag>{filteredBookings.length} bookings found.</Tag>
              </Text>
            </div>
          </Col>
          <Col className={'text-align-right'}>
            <Button.Group>
              <Tooltip title={'Refresh Bookings'}>
                <Button
                  icon={'reload'}
                  onClick={() => this._onRefresh()}
                  disabled={this.state.isLoading}
                  loading={this.state.isLoading}
                />
              </Tooltip>
            </Button.Group>
          </Col>
        </Row>

        {/*<Divider className="divider-medium" />*/}

        <div className="">
          {filteredBookings.map((booking) => (
            <CustomerDetailsBookingItem
              booking={booking}
              key={booking.attendanceId}
              location={location}
              match={match}
              history={history}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default CustomerDetailsBookingPanel;
