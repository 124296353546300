import { SubTitle, Text } from 'common-components/typography';
import React from 'react';
import RecommendedStatusIcon from 'common-components/icons/RecommendedStatusIcon';
import { Col, Row } from 'antd';

interface IRecommendedPanelProps {
  recommendedTotalShiftSlot: number;
  totalAssignedShiftSlot: number;
}

export function RecommendedPanel({
  recommendedTotalShiftSlot = 0,
  totalAssignedShiftSlot = 0
}: IRecommendedPanelProps) {
  return (
    <>
      <SubTitle>Team members assigned to this session</SubTitle>
      <Row className={'mv-x-small'}>
        <Col span={12}>
          <Text weight={'bold'} size={'x2-large'}>
            {recommendedTotalShiftSlot ? recommendedTotalShiftSlot : 0}
          </Text>{' '}
          <Text weight={'light'}>recommended</Text>
        </Col>
        <Col span={12}>
          <Text weight={'bold'} size={'x2-large'}>
            {totalAssignedShiftSlot ? totalAssignedShiftSlot : 0}
          </Text>{' '}
          <Text weight={'light'}>assigned</Text>
        </Col>
      </Row>
      <div>
        <RecommendedStatusIcon
          recommendStatus={
            recommendedTotalShiftSlot > totalAssignedShiftSlot
              ? 'under'
              : recommendedTotalShiftSlot === totalAssignedShiftSlot
              ? 'recommended'
              : 'over'
          }
          size={'18'}
        />
        {recommendedTotalShiftSlot > totalAssignedShiftSlot ? (
          <Text color="orange-dark" size="small">
            This session seems to be <b>under-staffed</b> based on the customers' ratios.
          </Text>
        ) : recommendedTotalShiftSlot === totalAssignedShiftSlot ? (
          <Text color="green-dark" size="small">
            You have the <b>optimal number</b> of team members assigned to this session.
          </Text>
        ) : (
          <Text color="red-dark" size="small">
            This session seems to be <b>over-staffed</b> based on the customers' ratios.
          </Text>
        )}
      </div>
    </>
  );
}
