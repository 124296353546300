import React, { Component } from 'react';
import { Text, Title } from 'common-components/typography';
import { Button, Col, Divider, notification, Row } from 'antd';
import { IBillingBooking } from 'interfaces/booking-interfaces';
import BookingApproveEditLineItem from './BookingApproveEditLineItem';

import _ from 'lodash';
import moment from 'moment';
import { dispatch } from 'src/stores/rematch/root-store';

interface IBookingApproveEditLineItemDetailsProps {
  isExpand: boolean;
  billingBooking: IBillingBooking;
  billingSupportItems: any[];
  doUpdateBookingBillingItemsInApprove: typeof dispatch.billingsStore.doUpdateBookingBillingItemsInApprove;
}

interface IBookingApproveEditLineItemDetailsState {
  modalVisible: boolean;
  isEdit: boolean;
  isRemove: boolean;
  isValidate: boolean;
  bookingTotal: number;
  isLoading: boolean;
}

class BookingApproveEditLineItemDetails extends Component<
  IBookingApproveEditLineItemDetailsProps,
  IBookingApproveEditLineItemDetailsState
> {
  private lineItemRefs: any[] = [];

  state = {
    modalVisible: false,
    isEdit: false,
    isRemove: false,
    isValidate: false,
    bookingTotal: 0,
    isLoading: false
  };

  _onClickEdit = () => {
    this.setState({ isEdit: true });
  };

  _onClickRemove = () => {
    this.setState({ isRemove: true });
  };

  _onEditSave = async () => {
    // validate and save
    const { doUpdateBookingBillingItemsInApprove, billingBooking } = this.props;
    let isFormValid = true;
    let toBeUpdateItemList = [];

    // validate line items tab
    if (this.lineItemRefs.length > 0) {
      _.forEach(this.lineItemRefs, (lineItemRef) => {
        lineItemRef.props.form.validateFields((err, values) => {
          if (err) {
            isFormValid = false;
          }
          toBeUpdateItemList.push(values);
        });
      });
    }

    if (isFormValid) {
      this.setState({ isLoading: true });

      let newList = _.map(toBeUpdateItemList, (item) => {
        return {
          bookingBillingLineItemId: item.bookingBillingLineItemId,
          startDateTime: item.startDate.set({
            hours: item.startTime.get('hour'),
            minute: item.startTime.get('minute')
          }),
          endDateTime: item.endDate.set({ hours: item.endTime.get('hour'), minute: item.endTime.get('minute') }),
          supportItemNumber: item.supportItemNumber,
          qty: _.round(Number(item.qty), 2),
          unitPrice: _.round(Number(item.unitPrice), 2),
          unit: item.unit,
          description: item.description,
          paymentMethod: item.paymentMethod,
          billingQty: _.round(Number(item.billingQty), 2),
          total: _.round(item.unitPrice * item.billingQty, 2)
        };
      });
      try {
        await doUpdateBookingBillingItemsInApprove({
          bookingId: billingBooking.bookingId,
          lineItems: newList
        });
        notification.success({
          message: 'Billing line items updated',
          description: 'The billing line items has been updated for this booking.'
        });
        this.setState({ isLoading: false, isEdit: false });
      } catch (error) {
        notification.error({ description: error.meta.message, message: error.message });
        this.setState({ isLoading: false });
      }
    }
  };

  _onEditCancel = () => {
    this.setState({ isEdit: false });
  };

  // _onClickExpand = () => {
  //   this.setState({ isExpand: !this.state.isExpand });
  // };

  _setRef = (ref) => {
    this.lineItemRefs.push(ref);
  };

  _onClickAddBillingLineItem = () => {
    // make a copy of existing billingItem to duplicate when add new
    // const newBillingLineItem: IBillingLineItem = {
    //   bookingBillingLineItemId: Date.now().toString(),
    //   startDateTime: this.props.billingBooking.startDateTime,
    //   endDateTime: this.props.billingBooking.endDateTime,
    //   supportItemNumber: null,
    //   qty: 0,
    //   unitPrice: 0,
    //   unit: null,
    //   description: null,
    //   total: 0,
    //   paymentMethod: null,
    //   ruleType: null,
    //   isTravel: false,
    //   travelDistance: 0,
    //   mileagePrice: 0
    // };
    // const billingLineItems = [...this.state.billingLineItems];
    // billingLineItems.push(newBillingLineItem);
    // //push to redux
    // this.setState({ bookingTotal: this._calculateBookingTotal(billingLineItems) });
  };

  _onRemoveLineItem = (removeIndex) => {
    let billingLineItems = [];
    _.forEach(this.lineItemRefs, (lineItemRef, index) => {
      let lineItem = lineItemRef.props.form.getFieldsValue();
      if (index !== removeIndex) {
        billingLineItems.push(lineItem);
      }
    });

    // remove from redux
    //this.setState({ billingLineItems, bookingTotal: this._calculateBookingTotal(billingLineItems) });
  };

  _onChangeLineItem = () => {
    // const billingLineItems = [...this.state.billingLineItems];
    // billingLineItems[index] = { ...billingLineItems[index], ...billingLineItem };
    // this.setState({ billingLineItems, bookingTotal: this._calculateBookingTotal(billingLineItems) });
  };

  _calculateBookingTotal = (bookingBillingLineItems) => {
    return _.reduce(
      bookingBillingLineItems,
      (total, billingLineItem) => {
        return Number(total) + Number(billingLineItem.total);
      },
      0
    );
  };

  _onClickSave = () => {};
  _onClickCancel = () => {};

  componentDidMount = () => {
    //const bookingBillingLineItems = [];
    //this.setState({ billingLineItems: _.cloneDeep(this.props.billingBooking.billingLineItems) });
  };

  render() {
    const leftSpan = 5;
    const rightSpan = 7;
    const { isEdit } = this.state;
    const { billingBooking, isExpand } = this.props;
    const { billingLineItems } = billingBooking;

    return (
      <div className={isExpand ? 'p-large' : 'none'}>
        <Row type={'flex'} justify={'start'} gutter={32}>
          {billingBooking.workerCheckedInDateTime && (
            <>
              <BookingBillingRow leftSpan={leftSpan} rightSpan={rightSpan} label={'Worker Start Time'}>
                <Text>{moment(billingBooking.workerCheckedInDateTime).format('DD-MM-YYYY hh:mma')}</Text>
              </BookingBillingRow>
              <BookingBillingRow leftSpan={leftSpan} rightSpan={rightSpan} label={'Worker End Time'}>
                <Text>{moment(billingBooking.workerCheckedOutDateTime).format('DD-MM-YYYY hh:mma')}</Text>
              </BookingBillingRow>
            </>
          )}
        </Row>
        <div className="flex-row align-center pt-medium">
          <Row type={'flex'} justify={'end'}>
            <Col span={16}>
              <Title level={3} className="mv-none pv-none pr-large">
                Billing Lines
              </Title>
            </Col>
            <Col span={8}>
              {isEdit ? (
                <Button icon={'plus'} size={'small'} onClick={this._onClickAddBillingLineItem}>
                  Add New Billing Line
                </Button>
              ) : (
                <Button type={'link'} icon={'edit'} size={'small'} onClick={this._onClickEdit}>
                  Edit
                </Button>
              )}
            </Col>
          </Row>
        </div>
        <Divider className="divider-medium" />

        {_.map(billingLineItems, (bookingBillingLineItem, index) => {
          return (
            <BookingApproveEditLineItem
              key={index}
              wrappedComponentRef={this._setRef}
              bookingBillingLineItem={bookingBillingLineItem}
              isEdit={isEdit}
              index={index}
              billingSupportItems={this.props.billingSupportItems}
              isValidate={this.state.isValidate}
              onRemoveLineItem={this._onRemoveLineItem}
              onChangeLineItem={this._onChangeLineItem}
            />
          );
        })}
        {billingLineItems.length > 0 && (
          <Row type={'flex'} justify={'start'} gutter={32} className={'pb-large'}>
            <Col span={leftSpan}>
              <Text>
                <b>Total</b>
              </Text>
            </Col>
            <Col span={rightSpan} className={'align-end'}>
              <Text>$ {this._calculateBookingTotal(billingLineItems)}</Text>
            </Col>
          </Row>
        )}
        {isEdit && (
          <Row type={'flex'} justify={'start'} gutter={32}>
            <Col span={leftSpan} className={'pt-large pb-large'} />
            <Col span={24 - leftSpan}>
              <Button.Group>
                <Button onClick={this._onEditSave} type={'primary'} icon={'save'}>
                  Save
                </Button>
                <Button onClick={this._onEditCancel} icon={'close'}>
                  Cancel
                </Button>
              </Button.Group>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const BookingBillingRow = ({ leftSpan, rightSpan, label, children }) => (
  <>
    <Col span={leftSpan} className={'mb-large'}>
      <Text>
        <b>{label}</b>
      </Text>
    </Col>
    <Col span={rightSpan}>{children}</Col>
  </>
);

export default BookingApproveEditLineItemDetails;
