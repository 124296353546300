import {
  yellow,
  purple,
  lime,
  grey,
  green,
  cyan,
  blue,
  red,
  geekblue,
  generate,
  gold,
  magenta,
  presetPalettes,
  presetPrimaryColors,
  volcano
} from '@ant-design/colors';

export default {
  yellow,
  purple,
  lime,
  grey,
  green,
  cyan,
  blue,
  red,
  geekblue,
  generate,
  gold,
  magenta,
  presetPalettes,
  presetPrimaryColors,
  volcano
};
