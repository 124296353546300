import React, { Component } from 'react';
import { Col, notification, Row } from 'antd';
import { connect } from 'react-redux';
import { Paragraph } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Title from 'antd/lib/typography/Title';
import { IGroupServiceSession } from 'interfaces/service-interfaces';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import GroupServiceSessionsPanel from 'views/group-services/service-details/components/GroupServiceSessionsPanel';
import _ from 'lodash';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';

interface ISessionReviewStepPanelProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setNewGroupServiceSchedule?: typeof dispatch.servicesStore.setNewGroupServiceSchedule;
  doGeneratedSessions?: typeof dispatch.servicesStore.doGeneratedSessions;
  newGroupServiceSchedule: typeof state.servicesStore.newGroupServiceSchedule;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  history?: any;
}

interface ISessionReviewStepPanelState {
  isLoading: boolean;
  isGenerating: boolean;
  sessions: IGroupServiceSession[] | any;
  isNoSessionSelectedError: boolean;
}

class SessionReviewStepPanel extends Component<ISessionReviewStepPanelProps, ISessionReviewStepPanelState> {
  state = {
    isLoading: false,
    isGenerating: true,
    sessions: this.props.newGroupServiceSchedule.sessions ? this.props.newGroupServiceSchedule.sessions : [],
    isNoSessionSelectedError: false,
  };

  private _onSelect = (session) => {
    this.setState({
      sessions: _.map(this.state.sessions, (currentSession) => {
        if (session.serviceDateTimeId === currentSession.serviceDateTimeId) {
          return { ...currentSession, isSelected: !currentSession.isSelected };
        } else {
          return { ...currentSession };
        }
      }),
    });
  };

  private _goToNext = async () => {
    const { setNewGroupServiceSchedule, newGroupServiceSchedule } = this.props;
    const { sessions } = this.state;

    let isFormValid = true;
    const selectedSessions = _.filter(sessions, (session) => session.isSelected);
    if (selectedSessions.length === 0) {
      isFormValid = false;
      this.setState({ isNoSessionSelectedError: true });
    }

    if (isFormValid) {
      try {
        await setNewGroupServiceSchedule({
          ...newGroupServiceSchedule,
          sessions,
        });
        this.props.onNextStep();
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong! Please try again.' });
      }
    }
  };

  componentDidMount = async () => {
    const { selectedGroupService, newGroupServiceSchedule } = this.props;
    try {
      let sessions = await this.props.doGeneratedSessions({
        serviceId: selectedGroupService.serviceId,
        serviceDepartmentId: selectedGroupService.serviceDepartmentId,
        scheduleStartDate: moment.tz(
          moment(newGroupServiceSchedule.scheduleStartDate).format('YYYY-MM-DD HH:mm:ss'),
          selectedGroupService.timezone,
        ),
        scheduleEndDate: moment.tz(
          moment(newGroupServiceSchedule.scheduleEndDate).format('YYYY-MM-DD HH:mm:ss'),
          selectedGroupService.timezone,
        ),
        timeSlots: _.map(newGroupServiceSchedule.timeSlots, (timeSlot) => {
          return {
            ...timeSlot,
            startDateTime: moment.tz(
              moment(timeSlot.startDateTime).format('YYYY-MM-DD HH:mm'),
              selectedGroupService.timezone,
            ),
            endDateTime: moment.tz(
              moment(timeSlot.endDateTime).format('YYYY-MM-DD HH:mm'),
              selectedGroupService.timezone,
            ),
            timezone: selectedGroupService.timezone,
          };
        }),
      });
      this.setState({
        sessions: _.map(sessions, (session) => {
          const schedule = _.find(
            newGroupServiceSchedule.timeSlots,
            (timeSlot) => timeSlot.scheduleTimeSlotId === session.scheduleTimeSlotId,
          );
          return {
            ...session,
            startDateTime: moment(
              moment.tz(session.startDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm'),
            ),
            endDateTime: moment(
              moment.tz(session.endDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm'),
            ),
            isSelected: true,
            description: schedule ? schedule.description : null,
            capacity: schedule ? schedule.capacity : null,
            timezone: selectedGroupService.timezone,
            serviceDateTimeId: uuidv4(),
          };
        }),
        isGenerating: false,
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong! Please try again.' });
    }
  };

  render() {
    const { selectedGroupService } = this.props;
    const { isLoading, sessions, isGenerating } = this.state;

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>Review the sessions being created.</Title>
              {!isGenerating ? (
                <Paragraph>
                  Based on the information you gave us in the last step we will be creating{' '}
                  <b>
                    {sessions.length} session{sessions.length !== 1 && 's'}
                  </b>
                  . If you don't want to create some of these sessions, simply uncheck the corresponding sessions and we
                  won't generate it!
                </Paragraph>
              ) : (
                <Paragraph>Please wait until we generate the sessions that will be created.</Paragraph>
              )}
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white p-large rounde-big" style={{ minWidth: '250px' }}>
              <Title level={4}>Sessions for {selectedGroupService.serviceName}</Title>
              <Paragraph>You may omit any sessions from being created by unchecking it below.</Paragraph>
              {isGenerating ? (
                <SpinningLoader size={100} message={'Generating the sessions...'} />
              ) : (
                <GroupServiceSessionsPanel
                  sessions={sessions}
                  onSelect={this._onSelect}
                  timezone={moment.tz.guess()}
                  displayTimeSlotDescription={true}
                  hideEmptyDates={true}
                />
              )}
            </div>
            <div className="pv-medium width-full bg-tertiary" style={{ position: 'sticky', bottom: 0 }}>
              <Row gutter={0} type="flex" align="middle" justify={'space-between'} className="bg-transparent">
                <div className="text-align-right pv-medium">
                  <SecondaryButton size="large" disabled={isLoading} onClick={this.props.onPreviousStep}>
                    Back
                  </SecondaryButton>
                </div>
                <div className="text-align-right pv-medium">
                  <PrimaryButton size="large" loading={isLoading} onClick={this._goToNext}>
                    Next
                  </PrimaryButton>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewGroupServiceSchedule: dispatch.servicesStore.setNewGroupServiceSchedule,
  doGeneratedSessions: dispatch.servicesStore.doGeneratedSessions,
});

const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService,
  newGroupServiceSchedule: state.servicesStore.newGroupServiceSchedule,
});

export default connect(
  mapState,
  mapDispatch,
)(SessionReviewStepPanel);
