import React, { Component } from 'react';
import _ from 'lodash';
import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import moment from 'moment';
import CommonUtils from 'utilities/common-utils';
import { PaymentMethodTag } from 'common-components/tags';
import { HyperlinkButton } from 'common-components/buttons';
import { dispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { Popover, MenuItem, Menu } from '@blueprintjs/core';
import PermissionUtils from 'utilities/permission-utils';
import { connect } from 'react-redux';
import { ServiceType } from 'utilities/enum-utils';

interface IInvoiceItemRow {
  rejectedItem?: any;
  history?: any;
  openActionModal?: any;
  onClickReject?: any;
  setSelectedBillingLineItem?: typeof dispatch.billingsStore.setSelectedBillingLineItem;
  portalUser?: typeof state.authStore.portalUser;
}

const claimType = {
  STANDARD_SERVICE: 'STD',
  CANCELLATION: 'CANC',
  TRAVEL: 'TRAN',
  REPORT_WRITING: 'REPW',
  NO_FACE_TO_FACE: 'NF2F',
  TELEHEALTH_SUPPORTS: 'THLT',
  IRREGULAR_SIL_SUPPORTS: 'IRSS',
};

const claimTypeOptions = [
  { value: claimType.STANDARD_SERVICE, label: 'Standard Service' },
  { value: claimType.CANCELLATION, label: 'Cancellation' },
  { value: claimType.TRAVEL, label: 'Travel' },
  { value: claimType.REPORT_WRITING, label: 'Report Writing' },
  { value: claimType.NO_FACE_TO_FACE, label: 'Non Face-to-face' },
  { value: claimType.TELEHEALTH_SUPPORTS, label: 'Telehealth supports' },
  { value: claimType.IRREGULAR_SIL_SUPPORTS, label: 'Irregular SIL supports' },
];

const unit = {
  HOURS: 'H',
  EACH: 'EA',
};

const unitOptions = [{ value: unit.HOURS, label: 'hours' }, { value: unit.EACH, label: 'each' }];

class RejectedItemRow extends Component<IInvoiceItemRow> {
  _checkbox = null;
  state = {
    customerId: null,
    showSubLines: false,
    openAction: false,
  };

  _onClickBooking = (attendanceId) => {
    const { history } = this.props;
    history.push(`/bookings/details/${attendanceId}`);
  };

  _onClickOpen = async () => {
    this.setState({ showSubLines: true });
  };

  _onClickClose = async () => {
    this.setState({ showSubLines: false });
  };

  _onClickBatch = (batchId) => {
    const { history } = this.props;
    history.push(`/batch/details/${batchId}`);
  };

  _onClickCustomer = (userId) => {
    const { history } = this.props;
    history.push(`/customer/details/${userId}`);
  };

  componentDidMount = () => {
    const { rejectedItem } = this.props;

    if (!_.isEmpty(rejectedItem)) {
      const { invoiceId } = rejectedItem;
      this.setState({ invoiceId });
    }
  };

  render() {
    const { rejectedItem, openActionModal, portalUser } = this.props;

    if (_.isEmpty(rejectedItem)) {
      return <></>;
    }

    const {
      attendanceId,
      rejectedDate,
      billingTotal,
      firstName,
      lastName,
      userId,
      invoiceId,
      invoiceNumber,
      numberOfItems,
      paymentMethod,
      billingItems,
    } = rejectedItem;

    const numberOfRejectedBooleans = _.countBy(rejectedItem.items, (item) => {
      return item.isRejected;
    });

    const numberOfRejected = numberOfRejectedBooleans.true ? numberOfRejectedBooleans.true : 0;

    return (
      <>
        <tr
          onClick={this.state.showSubLines ? this._onClickClose : this._onClickOpen}
          className={`anim-fade-in bg-white ${this.state.showSubLines && 'no-border'}`}
        >
          <td className="fluid-cell">{moment(rejectedDate).format('DD/MM/YYYY')}</td>
          <td className="ellipsis">
            <PaymentMethodTag paymentMethod={paymentMethod} className="mr-small" rounded={true} size="small" />
            <HyperlinkButton
              onClick={(e) => {
                e.stopPropagation();
                window.open(`/pdf?type=view-invoice&invoiceId=${invoiceId}`, '_blank');
              }}
              style={{ whiteSpace: 'nowrap' }}
            >
              {invoiceNumber}
            </HyperlinkButton>{' '}
            ({numberOfItems} item{numberOfItems > 1 && 's'} rejected
            {numberOfRejected > 0 && (
              <Text color="red-dark" className="text-weight-bold">
                {' '}
                ({numberOfRejected} rejected)
              </Text>
            )}
            )
          </td>
          <td className="ellipsis">
            <div className="pr-small">
              <Text style={{ whiteSpace: 'nowrap' }} onClick={(e) => e.stopPropagation()}>
                {PermissionUtils.validatePermission('ViewCustomerProfile', portalUser.permissions.permissionRoles) ? (
                  <HyperlinkButton onClick={() => this._onClickCustomer(userId)} color="black">
                    {firstName} {lastName}
                  </HyperlinkButton>
                ) : (
                  firstName + ' ' + lastName
                )}
              </Text>
            </div>
          </td>
          <td className="ellipsis" />
          <td className="fluid-cell">
            <div className="pr-small text-align-right">
              <Text style={{ whiteSpace: 'nowrap' }}>{CommonUtils.formatPrice(billingTotal)}</Text>
            </div>
          </td>
          {/*<td style={{ width: '100px', textAlign: 'center' }}></td>*/}
          <td style={{ width: '80px' }} onClick={this.state.showSubLines ? this._onClickClose : this._onClickOpen}>
            <div className="text-align-right">
              {this.state.showSubLines ? (
                <Icon className="size-x-large" type="up" />
              ) : (
                <Icon className="size-x-large" type="down" />
              )}
            </div>
          </td>
        </tr>
        <tr>
          {this.state.showSubLines && (
            <>
              <td colSpan={7} className="p-none pb-small">
                <table className="subLines" style={{ width: '100%' }}>
                  {_.map(billingItems, (lineItem, key) => (
                    <>
                      <tr className={`text-align-middle ${billingItems.length === key + 1 && 'no-border'}`}>
                        <td className="fluid-cell">
                          {lineItem.serviceType !== ServiceType.GROUP ? (
                            <HyperlinkButton onClick={() => this._onClickBooking(lineItem.attendanceId)}>
                              {moment(lineItem.startDateTime).format('DD/MM/YYYY')}
                            </HyperlinkButton>
                          ) : (
                            moment(lineItem.startDateTime).format('DD/MM/YYYY')
                          )}
                        </td>
                        <td className="ellipsis">
                          {lineItem.paymentMethod !== paymentMethod && (
                            <PaymentMethodTag
                              paymentMethod={lineItem.paymentMethod}
                              className="mr-small"
                              rounded={true}
                              size="small"
                            />
                          )}
                          {lineItem.supportItemNumber}
                        </td>
                        <td className="ellipsis">
                          {lineItem.claimType
                            ? claimTypeOptions.find((item) => item.value === lineItem.claimType).label
                            : 'Standard Service'}
                        </td>
                        <td className="ellipsis">{lineItem.claimReference}</td>
                        <td className="ellipsis" style={{ textAlign: 'right' }}>
                          {CommonUtils.formatQuantityDisplay(lineItem.qty)} (
                          {unitOptions.find((item) => item.value === lineItem.unit).label}) x{' '}
                          {CommonUtils.formatPrice(lineItem.unitPrice)}
                        </td>
                        <td className="fluid-cell text-align-right">
                          {CommonUtils.formatPrice(lineItem.billingTotal)}
                        </td>
                        <td style={{ width: '80px', textAlign: 'center' }}>
                          <Popover
                            content={
                              <Menu>
                                {PermissionUtils.validatePermission(
                                  'EditPaymentBillingLineItem',
                                  portalUser.permissions.permissionRoles,
                                ) && (
                                  <MenuItem
                                    text={
                                      <div
                                        onClick={() => openActionModal({ action: 'EDIT', additionalData: lineItem })}
                                      >
                                        <HyperlinkButton>Edit</HyperlinkButton>
                                      </div>
                                    }
                                    className="hover-bg-gray-lightest"
                                  />
                                )}
                                {PermissionUtils.validatePermission(
                                  'ReApproveBillingLineItem',
                                  portalUser.permissions.permissionRoles,
                                ) && (
                                  <MenuItem
                                    text={
                                      <div
                                        onClick={() =>
                                          openActionModal({ action: 'RE_APPROVE', additionalData: lineItem })
                                        }
                                      >
                                        <HyperlinkButton>Re-approve</HyperlinkButton>
                                      </div>
                                    }
                                    className="hover-bg-gray-lightest"
                                  />
                                )}
                                <MenuItem
                                  text={
                                    <div onClick={() => openActionModal({ action: 'VIEW', additionalData: lineItem })}>
                                      <HyperlinkButton>View reject reason</HyperlinkButton>
                                    </div>
                                  }
                                  className="hover-bg-gray-lightest"
                                />
                                <MenuItem
                                  text={
                                    <div onClick={() => openActionModal({ action: 'WAIVE', additionalData: lineItem })}>
                                      <HyperlinkButton color="red">Waive</HyperlinkButton>
                                    </div>
                                  }
                                  className="hover-bg-gray-lightest"
                                />
                              </Menu>
                            }
                            position={'bottom-right'}
                          >
                            <Icon type="ellipsis" className="text-size-x4-large" />
                          </Popover>
                        </td>
                      </tr>
                    </>
                  ))}
                </table>
              </td>
            </>
          )}
        </tr>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
});

export default connect(
  mapState,
  null,
)(RejectedItemRow);
