import React, { Component } from 'react';
import { Text, Paragraph } from 'common-components/typography';
import { Button, Col, notification, Row, Switch } from 'antd';
import _ from 'lodash';
import { ndisHelper } from 'variables/data-helpers';
import { dispatch, state } from 'stores/rematch/root-store';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { ServDetailsSectionHeader } from 'views/services/details/sections/content-section/common/ServDetailsSectionHeader';
import { australianFlag } from 'assets/Icons/country-flags';
import ServiceNDISLineItemsModal from 'views/plan-management/payments/components/ServiceNDISLineItemsModal';
import ServiceVCPLineItemsModal from 'views/plan-management/payments/components/ServiceVCPLineItemsModal';
import { Warning } from 'common-components/alerts';
import PermissionUtils from 'utilities/permission-utils';
interface IServicePaymentTabProps {
  selectedService?: typeof state.servicesStore.selectedService;
  doUpdateServicePricing?: typeof dispatch.servicesStore.doUpdateServicePricing;
  doUpdateServicePaymentSources: typeof dispatch.servicesStore.doUpdateServicePaymentSources;
  hasEditPermission: boolean;
}

class ServicePaymentTab extends Component<IServicePaymentTabProps> {
  state = {
    isEdit: false,
    isLoading: false,
    serviceBillingItems: null,
    isNDISLineItemsOpen: false,
    isVCPLineItemsOpen: false,
    isNDISEnabled: false,
    isVCPEnabled: false,
    editNDISEnabled: false,
    editVCPEnabled: false,
    isShowEditPaymentMethodError: false,
    disablingPaymentType: '',
    enablingPaymentType: '',
    editPaymentMethodStep: 1,
    isServiceProviderVCPEnabled: false
  };

  private _getAdditionalLineItemData = (serviceBillingItems) => {
    return _.map(serviceBillingItems, (lineItem) => {
      const item = ndisHelper.getBySupportItemNumber(lineItem.supportItemNumber);
      return {
        ...lineItem,
        tax: 0,
        label: item.SupportItem,
        mileagePrice: Number(lineItem.mileagePrice)
      };
    });
  };

  private _formatData = async () => {
    const { selectedService } = this.props;

    let serviceBillingItems = selectedService.ndisServiceBillingItems ? selectedService.ndisServiceBillingItems : [];
    serviceBillingItems = await this._getAdditionalLineItemData(serviceBillingItems);

    this.setState({
      serviceBillingItems: serviceBillingItems
    });
  };

  private _onCloseServiceNDISLineItemsModal = () => {
    this.setState({ isNDISLineItemsOpen: false });
  };

  private _onCloseServiceVCPLineItemsModal = () => {
    this.setState({ isVCPLineItemsOpen: false });
  };

  private _changeView = () => {
    this.setState({ isEdit: true });
  };

  private _onCloseEditPaymentMethodModal = () => {
    this.setState({ isEdit: false });
  };

  private _cancelEditPaymentMethod = () => {
    const { isNDISEnabled, isVCPEnabled } = this.state;
    this.setState({
      isEdit: false,
      editNDISEnabled: isNDISEnabled,
      editVCPEnabled: isVCPEnabled,
      editPaymentMethodStep: 1
    });
  };

  private _onSaveEditPaymentMethod = () => {
    const { editVCPEnabled, editNDISEnabled, isNDISEnabled, isVCPEnabled } = this.state;
    if (!editVCPEnabled && !editNDISEnabled) {
      this.setState({
        isShowEditPaymentMethodError: true
      });
    } else if (!editVCPEnabled && isVCPEnabled) {
      this.setState({ editPaymentMethodStep: 2, disablingPaymentType: 'VCP' });
    } else if (!editNDISEnabled && isNDISEnabled) {
      this.setState({ editPaymentMethodStep: 2, disablingPaymentType: 'NDIS' });
    } else {
      this._saveEditPaymentMethod();
    }
  };

  private _saveEditPaymentMethod = async () => {
    const { editVCPEnabled, editNDISEnabled, isNDISEnabled, isVCPEnabled } = this.state;
    try {
      await this.props.doUpdateServicePaymentSources({
        enableNDIS: editNDISEnabled,
        enableVCP: editVCPEnabled
      });

      if (editVCPEnabled && !isVCPEnabled) {
        this.setState({
          editPaymentMethodStep: 3,
          enablingPaymentType: 'VCP'
        });
      } else if (editNDISEnabled && !isNDISEnabled) {
        this.setState({
          editPaymentMethodStep: 3,
          enablingPaymentType: 'NDIS'
        });
      } else {
        this._resetEditPaymentMethod();
      }
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
    }
  };

  private _resetEditPaymentMethod = () => {
    const { selectedService } = this.props;
    const hasNDIS = _.find(selectedService.paymentSources, (source) => source === 'NDIS');
    const hasVCP = _.find(selectedService.paymentSources, (source) => source === 'VCP');

    this.setState({
      isNDISEnabled: !!hasNDIS,
      isVCPEnabled: !!hasVCP,
      editNDISEnabled: !!hasNDIS,
      editVCPEnabled: !!hasVCP,
      isShowEditPaymentMethodError: false,
      disablingPaymentType: '',
      enablingPaymentType: '',
      editPaymentMethodStep: 1,
      isEdit: false
    });
  };

  async componentDidMount() {
    this._formatData();
    const { selectedService } = this.props;
    const hasNDIS = _.find(selectedService.paymentSources, (source) => source === 'NDIS');
    const hasVCP = _.find(selectedService.paymentSources, (source) => source === 'VCP');
    if (hasNDIS) this.setState({ isNDISEnabled: true, editNDISEnabled: true });
    if (hasVCP) this.setState({ isVCPEnabled: true, editVCPEnabled: true });
    const isVCPEnabled = await PermissionUtils.getIsServiceProviderVCPEnabled();
    this.setState({
      isServiceProviderVCPEnabled: !!isVCPEnabled
    });
  }

  render() {
    const {
      isEdit,
      isNDISEnabled,
      isVCPEnabled,
      editNDISEnabled,
      editVCPEnabled,
      isShowEditPaymentMethodError,
      editPaymentMethodStep,
      disablingPaymentType,
      enablingPaymentType,
      isServiceProviderVCPEnabled
    } = this.state;
    const { selectedService } = this.props;
    return (
      <div className="mt-small">
        <ActionModal
          isOpen={isEdit}
          title={
            editPaymentMethodStep === 1
              ? 'Edit payment methods for service'
              : editPaymentMethodStep === 2
              ? `Warning: Disabling ${disablingPaymentType}`
              : `${enablingPaymentType} enabled`
          }
          onClose={this._onCloseEditPaymentMethodModal}
          width="large"
        >
          {editPaymentMethodStep === 1 && (
            <>
              <div className="mt-medium">
                <Switch
                  className={editNDISEnabled ? 'bg-green-light' : null}
                  checked={editNDISEnabled}
                  onChange={(value) => this.setState({ editNDISEnabled: value, showPaymentMethodError: false })}
                />
                <Text className="ml-medium">National Disability Insurance Scheme (NDIS)</Text>
              </div>
              {isServiceProviderVCPEnabled && (
                <div className="mt-medium">
                  <Switch
                    className={editVCPEnabled ? 'bg-green-light' : null}
                    checked={editVCPEnabled}
                    onChange={(value) => this.setState({ editVCPEnabled: value, showPaymentMethodError: false })}
                  />
                  <Text className="ml-medium">Vulnerable Children's Program (VCP)</Text>
                </div>
              )}
              {isShowEditPaymentMethodError && (
                <div className="mt-medium">
                  <Text color="red">You must have at least one payment method enabled</Text>
                </div>
              )}
              <ActionModalFooter>
                <PrimaryButton className="mr-medium" size="large" onClick={this._cancelEditPaymentMethod}>
                  Cancel
                </PrimaryButton>
                <GhostButton size="large" onClick={this._onSaveEditPaymentMethod}>
                  Save
                </GhostButton>
              </ActionModalFooter>
            </>
          )}
          {editPaymentMethodStep === 2 && (
            <>
              <div className="anim-slide-left">
                <Paragraph>
                  This service currently has active upcoming bookings which are using {disablingPaymentType} as a
                  payment method.
                </Paragraph>
                <Paragraph>
                  In order to disable {disablingPaymentType} as a payment method all upcoming bookings using{' '}
                  {disablingPaymentType} will be cancelled.
                </Paragraph>
                <Paragraph>Are you sure you want to do this?</Paragraph>
              </div>
              <ActionModalFooter>
                <PrimaryButton
                  className="mr-medium"
                  size="large"
                  onClick={() => this.setState({ editPaymentMethodStep: 1 })}
                >
                  Go back
                </PrimaryButton>
                <GhostButton size="large" onClick={this._saveEditPaymentMethod}>
                  Cancel bookings and disable
                </GhostButton>
              </ActionModalFooter>
            </>
          )}
          {editPaymentMethodStep === 3 && (
            <>
              <div className="anim-slide-left">
                <Paragraph>You have successfully enabled {enablingPaymentType}.</Paragraph>
                <Paragraph>
                  You can now add items to the {enablingPaymentType} payments and adjust the claims settings.
                </Paragraph>
              </div>
              <ActionModalFooter>
                <PrimaryButton className="mr-medium" size="large" onClick={this._resetEditPaymentMethod}>
                  Close
                </PrimaryButton>
              </ActionModalFooter>
            </>
          )}
        </ActionModal>

        <ServDetailsSectionHeader
          title={'Payment method'}
          subtitle={'Edit which payment methods this service can be paid for with'}
          hasEditButton={this.props.hasEditPermission}
          editAction={this._changeView}
          editButtonTitle={'Edit payment methods'}
        />
        <ServiceNDISLineItemsModal
          onClose={this._onCloseServiceNDISLineItemsModal}
          isOpen={this.state.isNDISLineItemsOpen}
          {...this.props}
        />
        <ServiceVCPLineItemsModal
          onClose={this._onCloseServiceVCPLineItemsModal}
          isOpen={this.state.isVCPLineItemsOpen}
          {...this.props}
        />
        {isNDISEnabled && (
          <Row
            className={'bordered-bottom border-standard-gray p-medium cursor-pointer hover-bg-quaternary'}
            gutter={24}
            onClick={() => this.setState({ isNDISLineItemsOpen: true })}
          >
            <Col span={24}>
              <div className={'mb-small'}>
                <Text weight={'bold'}>National Disability Insurance Scheme (NDIS)</Text>
              </div>
              <div className={'mb-small'}>
                <img src={australianFlag} height={'8px'} alt={'Australian National Flag'} className={'mr-x-small'} />
                <Text size={'regular'}>Australia</Text>
              </div>
              <Text color={'secondary'}>
                The National Disability Insurance Scheme is a scheme of the Australia Goverment that funds costs
                associated with disability.
              </Text>
              {_.isEmpty(selectedService.ndisServiceBillingItems) && (
                <div className={'mt-small'}>
                  <Warning content={<Text>No NDIS items have been added to this service.</Text>} className="mb-large" />
                </div>
              )}
            </Col>
          </Row>
        )}
        {isVCPEnabled && (
          <Row
            className={'bordered-top border-standard-gray p-medium cursor-pointer hover-bg-quaternary'}
            gutter={24}
            onClick={() => this.setState({ isVCPLineItemsOpen: true })}
          >
            <Col span={24}>
              <div className={'mb-small'}>
                <Text weight={'bold'}>Vulnerable Children's Program (VCP)</Text>
              </div>
              <div className={'mb-small'}>
                <img src={australianFlag} height={'8px'} alt={'Australian National Flag'} className={'mr-x-small'} />
                <Text size={'regular'}>Australia</Text>
              </div>
              <Text color={'secondary'}>
                The Vulnerable Children's Program is a Australia Government scheme aimed at supporting at risk children
                in the community.
              </Text>
              {_.isEmpty(selectedService.vcpServiceBillingItems) && (
                <div className={'mt-small'}>
                  <Warning content={<Text>No VCP items have been added to this service.</Text>} className="mb-large" />
                </div>
              )}
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

export default ServicePaymentTab;
