import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Form, Icon, Input, notification, Switch, Divider } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';
import { australianFlag } from 'assets/Icons/country-flags';

interface IEditPlanManagementModalProps extends FormComponentProps {
  isOpen: any;
  onClose: () => void;
  doEditNDISConfig: typeof dispatch.companyStore.doEditNDISConfig;
  companyFundingAndPayment: typeof state.companyStore.companyFundingAndPayment;
  doUpdatePlanManagementConfigurations: typeof dispatch.companyStore.doUpdatePlanManagementConfigurations;
}

interface IEditPlanManagementModalState {
  title: any;
  isCreated: boolean;
  isPlanManagementOn: boolean;
  providerNumber: number;
  invoiceEmail: string;
}

class EditPlanManagementModal extends Component<IEditPlanManagementModalProps, IEditPlanManagementModalState> {
  state = {
    title: 'Plan Management',
    isCreated: false,
    isPlanManagementOn: false,
    providerNumber: null,
    invoiceEmail: ''
  };

  private _onCloseModal = async () => {
    await this.props.onClose();
    this.setState({ title: 'Plan Management', isCreated: false });
  };

  private _onPressEdit = async () => {
    const { form } = this.props;
    let isFormValid = true;
    if (this.state.isPlanManagementOn) {
      form.validateFields((err) => {
        if (err) {
          isFormValid = false;
        }
      });
    }
    if (isFormValid) {
      try {
        const { isPlanManagementOn } = this.state;

        await this.props.doUpdatePlanManagementConfigurations({
          hasPlanManagement: isPlanManagementOn,
          planManagementProviderNumber: isPlanManagementOn ? form.getFieldValue('providerNumber') : null,
          planManagementProviderEmail: isPlanManagementOn ? form.getFieldValue('invoiceEmail') : null
        });

        this.setState({
          title: (
            <>
              {isPlanManagementOn ? 'Plan Management activated' : 'Plan Management de-activated'}
              <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
            </>
          ),
          isCreated: true
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
  };

  private _providerNumberValidator = (rule, value, callback) => {
    try {
      if (this.state.isPlanManagementOn) {
        if (!value || value === '') {
          throw Error('Please enter a provider number.');
        }
        if (_.trim(value).length < 9 || _.trim(value).length > 10) {
          throw Error('Provider number must be 9 or 10 digits');
        }
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  componentDidMount() {
    const { planManagement } = this.props.companyFundingAndPayment;
    if (planManagement) {
      this.setState({
        isPlanManagementOn: planManagement.hasPlanManagement,
        invoiceEmail: planManagement.planManagementProviderEmail,
        providerNumber: planManagement.planManagementProviderNumber
      });
    }
  }

  render() {
    const { isOpen, form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {this.state.isCreated ? (
          <>
            {this.state.isPlanManagementOn && (
              <div className="mv-medium anim-slide-left">
                <SubTitle>PROVIDER NUMBER</SubTitle>
                <Text size={'x-large'}>{form.getFieldValue('providerNumber')}</Text>
                <div className={'mt-large'}>
                  <SubTitle>DESIGNATED EMAIL ADDRESS</SubTitle>
                  <Text size={'x-large'}>{form.getFieldValue('invoiceEmail')}</Text>
                </div>
              </div>
            )}
            {!this.state.isPlanManagementOn && (
              <div className="mv-medium anim-slide-left">
                <Text>
                  You have deactivated the plan management section of GoodHuman.
                  <br />
                  <br />
                  All records have been maintained and plan management can be reactivated at any time.
                </Text>
              </div>
            )}
            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Done
              </PrimaryButton>
            </ActionModalFooter>
          </>
        ) : (
          <>
            <div className="anim-slide-left">
              <div className={'mb-x-large'}>
                <img src={australianFlag} height={'8px'} alt={'Australian National Flag'} className={'mr-x-small'} />
                <Text>Australia</Text>
                <Paragraph className={'mt-small'}>
                  Turning on Plan Management will enable the Plan Management Invoice management and payments
                  functionality in GoodHuman
                </Paragraph>
                <div className="mt-large flex-row align-center">
                  <Switch
                    className={this.state.isPlanManagementOn ? 'bg-green-light' : null}
                    checked={this.state.isPlanManagementOn}
                    onChange={(value) => this.setState({ isPlanManagementOn: value })}
                  />
                  <b className="ml-medium">{this.state.isPlanManagementOn ? 'ON' : 'OFF'}</b>
                </div>
                <Divider />

                <div className={'mt-large'}>
                  <SubTitle>PROVIDER NUMBER</SubTitle>
                  <Form.Item className="width-full m-none mt-small">
                    {getFieldDecorator('providerNumber', {
                      initialValue: this.state.providerNumber,
                      rules: [{ required: true, validator: this._providerNumberValidator }]
                    })(
                      <Input
                        style={{ width: '250px' }}
                        maxLength={12}
                        size={'large'}
                        disabled={!this.state.isPlanManagementOn}
                      />
                    )}
                  </Form.Item>
                </div>
                <div className={'mt-large'}>
                  <SubTitle>EMAIL WHICH WILL RECEIVE INVOICES</SubTitle>
                  <Form.Item className="width-full m-none mt-small">
                    {getFieldDecorator('invoiceEmail', {
                      initialValue: this.state.invoiceEmail,
                      rules: [
                        {
                          required: this.state.isPlanManagementOn,
                          type: 'email',
                          message:
                            'Please indicate which email where you want to receive invoices from providers servicing your plan-managed customers.'
                        }
                      ]
                    })(
                      <Input
                        style={{ width: '250px' }}
                        maxLength={320}
                        size={'large'}
                        disabled={!this.state.isPlanManagementOn}
                      />
                    )}
                  </Form.Item>
                </div>
              </div>

              <ActionModalFooter>
                <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._onPressEdit}>
                  Save changes
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyFundingAndPayment: state.companyStore.companyFundingAndPayment
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditNDISConfig: dispatch.companyStore.doEditNDISConfig,
  doUpdatePlanManagementConfigurations: dispatch.companyStore.doUpdatePlanManagementConfigurations
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IEditPlanManagementModalProps>()(EditPlanManagementModal));
