import { TimezonePicker } from '@blueprintjs/timezone';
import { FaGlobeAsia } from 'react-icons/fa';
import { Text } from 'common-components/typography';
import { Icon } from 'antd';
import React from 'react';
import moment from 'moment-timezone';

export default function TimezoneInput({
  value = moment.tz.guess(),
  onChange = (value: any) => {},
  size = 'medium',
  disabled = false,
  usePortal = true
}) {
  const height = size === 'large' ? { height: '40px' } : {};
  return (
    <TimezonePicker onChange={onChange} popoverProps={{ usePortal, hasBackdrop: true }} disabled={disabled}>
      <div className="bordered rounded cursor-pointer pv-x-small ph-small flex-row align-center" style={height}>
        <FaGlobeAsia className="mr-x-small text-size-small text-color-tertiary" />
        {value && (
          <>
            <Text className="select-none">
              {value.replace('_', ' ')}
              {' (' + moment.tz(value).zoneAbbr() + ')'}
            </Text>
            <Text className="select-none ml-small" size="regular" color="secondary">
              UTC{moment.tz(value).format('Z')}
            </Text>
          </>
        )}
        <Icon type="caret-down" className="ml-small text-size-small" />
      </div>
    </TimezonePicker>
  );
}
