import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Paragraph, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Avatar, Col, notification, Row, Tooltip } from 'antd';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment-timezone';
import TimeInput from 'common-components/time-input/TimeInput';
import { IGroupServiceTimesheetShiftSlot } from 'interfaces/service-interfaces';
import TimesheetShiftSlotRow from './TimesheetShiftSlotRow';

interface IEditTimeSheetModalProps {
  isOpen: boolean;
  onClose: (isRefresh: boolean) => void;
  selectedShiftSlot: IGroupServiceTimesheetShiftSlot;
  selectedSessionId: string;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  doEditShiftSlotsStartEndTime: typeof dispatch.groupServiceStore.doEditShiftSlotsStartEndTime;
}

interface IEditTimeSheetModalState {
  step: number;
  title: string;
  isSaving: boolean;
  canCloseOutside: boolean;
  startDateTime: Moment;
  endDateTime: Moment;
  isRefreshAfterClose: boolean;
}

class EditTimeSheetModal extends Component<IEditTimeSheetModalProps, IEditTimeSheetModalState> {
  state = {
    step: 1,
    title: 'Edit timesheet',
    isSaving: false,
    canCloseOutside: true,
    startDateTime: moment(),
    endDateTime: moment(),
    isRefreshAfterClose: false
  };

  private _onCloseModal = () => {
    this.props.onClose(this.state.isRefreshAfterClose);
    this.setState({
      step: 1,
      title: 'Edit timesheet',
      isSaving: false,
      canCloseOutside: true,
      isRefreshAfterClose: false
    });
  };

  private _onSave = async () => {
    const { doEditShiftSlotsStartEndTime, selectedShiftSlot, selectedGroupService, selectedSessionId } = this.props;
    const { startDateTime, endDateTime } = this.state;
    this.setState({ isSaving: true, canCloseOutside: false });

    try {
      const payload = {
        serviceId: selectedGroupService.serviceId,
        sessionId: selectedSessionId,
        shiftSlots: [
          {
            startDateTime: moment
              .tz(startDateTime.format('YYYY-MM-DD HH:mm'), selectedGroupService.timezone)
              .toISOString(),
            endDateTime: moment.tz(endDateTime.format('YYYY-MM-DD HH:mm'), selectedGroupService.timezone).toISOString(),
            supportWorkerAttendanceId: selectedShiftSlot.supportWorkerAttendanceId
          }
        ]
      };

      await doEditShiftSlotsStartEndTime(payload);

      this.setState({
        isSaving: false,
        canCloseOutside: true,
        step: 2,
        title: 'Timesheet edited',
        isRefreshAfterClose: true
      });
    } catch (e) {
      notification.error({ message: 'Oops, an error has occurred, please try again.' });
      this.setState({ isSaving: false, canCloseOutside: true });
    }
  };

  componentDidMount() {
    const { selectedGroupService, selectedShiftSlot } = this.props;

    const startDateTime = moment(
      moment.tz(selectedShiftSlot.workerCheckedInDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm')
    );

    const endDateTime = moment(
      moment.tz(selectedShiftSlot.workerCheckedOutDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm')
    );

    this.setState({ startDateTime, endDateTime });
  }

  private _onChangeStartDate = (value) => {
    this.setState({ startDateTime: moment(value) });
  };

  private _onChangeEndDate = (value) => {
    this.setState({ endDateTime: moment(value) });
  };

  private _renderContent = () => {
    const { step } = this.state;
    const { selectedGroupService, selectedShiftSlot } = this.props;
    const { startDateTime, endDateTime } = this.state;
    if (step === 1) {
      const shiftStartMoment = moment(
        moment.tz(selectedShiftSlot.shiftStartDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm')
      );
      const shiftEndMoment = moment(
        moment.tz(selectedShiftSlot.shiftEndDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm')
      );

      const isInWarning = !startDateTime.isSame(shiftStartMoment);
      const isOutWarning = !endDateTime.isSame(shiftEndMoment);

      const scheduleDiff = shiftEndMoment.diff(shiftStartMoment);
      const scheduleDifference = moment.duration(scheduleDiff);

      const actualDiff = endDateTime.diff(startDateTime);
      const actualDifference = moment.duration(actualDiff);

      const isActualDiffWarning = scheduleDiff !== actualDiff;

      const differences = moment.duration(Math.abs(scheduleDiff - actualDiff), 'ms');

      let containerClassName = 'text-size-x-large align-center pv-medium';

      if (isInWarning || isOutWarning || isActualDiffWarning) {
        containerClassName += ' bg-orange-lightest';
      }

      const title = (
        <span>
          scheduled time:
          <br /> {moment
            .tz(selectedShiftSlot.shiftStartDateTime, selectedGroupService.timezone)
            .format('h:mm A')} -{' '}
          {moment.tz(selectedShiftSlot.shiftEndDateTime, selectedGroupService.timezone).format('h:mm A')}
        </span>
      );
      return (
        <>
          <div>
            <div className="mb-large">
              <Text>Edit the timesheet for the following team member.</Text>
            </div>
            <div
              className="rounded-big bg-quaternary flex-row mb-large align-center"
              style={{ height: '56px', paddingLeft: '12px' }}
            >
              <Avatar src={selectedShiftSlot.attachmentUrl} icon="user" className="mr-small" />
              <Text weight="bold">
                {selectedShiftSlot.firstName} {selectedShiftSlot.lastName}
              </Text>
            </div>
            <div className="rounded-big bordered">
              <Row className="bordered-bottom">
                <Col span={4} className="p-medium">
                  <FieldLabel text="DATE" />
                </Col>
                <Col span={4} className="p-medium">
                  <FieldLabel text="IN" />
                </Col>
                <Col span={4} className="p-medium">
                  <FieldLabel text="OUT" />
                </Col>
                <Col span={4} className="p-medium text-align-right">
                  <FieldLabel text="SCHEDULE" />
                </Col>
                <Col span={4} className="p-medium text-align-right">
                  <FieldLabel text="ACTUAL" />
                </Col>
                <Col span={4} className="p-medium text-align-right">
                  <FieldLabel text="DIFF." />
                </Col>
              </Row>
              <Row className={containerClassName} type="flex">
                <Col span={4} className="ph-medium">
                  <Text>
                    {moment
                      .tz(selectedShiftSlot.shiftStartDateTime, selectedGroupService.timezone)
                      .format('D MMM YYYY')}
                  </Text>
                </Col>
                <Col span={4} className="ph-medium">
                  <TimeInput
                    size="large"
                    className={isInWarning ? ' text-color-orange' : ''}
                    value={startDateTime}
                    onChange={this._onChangeStartDate}
                    displayIcon={false}
                  />
                </Col>
                <Col span={4} className="ph-medium">
                  <TimeInput
                    size="large"
                    className={isOutWarning ? ' text-color-orange' : ''}
                    value={endDateTime}
                    onChange={this._onChangeEndDate}
                    displayIcon={false}
                  />
                </Col>
                <Col span={4} className="ph-medium text-align-right">
                  <Tooltip title={title}>
                    <Text>{TimesheetShiftSlotRow.formatDuration(scheduleDifference)}</Text>
                  </Tooltip>
                </Col>
                <Col span={4} className="ph-medium text-align-right">
                  <Text>{TimesheetShiftSlotRow.formatDuration(actualDifference, isActualDiffWarning)}</Text>
                </Col>
                <Col span={4} className="ph-medium text-align-right">
                  {isActualDiffWarning ? (
                    TimesheetShiftSlotRow.formatDuration(differences, isActualDiffWarning)
                  ) : (
                    <>
                      <Text>
                        <b>0</b>
                      </Text>
                      <Text color="secondary"> hrs</Text>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <ActionModalFooter className="mt-large">
            <SecondaryButton
              className="mr-medium"
              size="large"
              onClick={this._onCloseModal}
              disabled={this.state.isSaving}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._onSave} loading={this.state.isSaving}>
              Save
            </PrimaryButton>
          </ActionModalFooter>
        </>
      );
    }

    if (step === 2) {
      return (
        <>
          <Paragraph>
            You've successfully edited the timesheet for{' '}
            <b>
              {selectedShiftSlot.firstName} {selectedShiftSlot.lastName}
            </b>
          </Paragraph>
          <ActionModalFooter className="mt-large">
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </>
      );
    }
  };

  render() {
    return (
      <ActionModal
        isOpen={this.props.isOpen}
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canCloseOutside}
        width="x-large"
        className="p-small"
        title={this.state.title}
      >
        {this.props.selectedShiftSlot && this._renderContent()}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditShiftSlotsStartEndTime: dispatch.groupServiceStore.doEditShiftSlotsStartEndTime
});

export default connect(
  mapState,
  mapDispatch
)(EditTimeSheetModal);
