import React, { Component } from 'react';
import { Col, Form, notification, Row } from 'antd';
import { connect } from 'react-redux';
import { Text, SubTitle } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import _ from 'lodash';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { FormComponentProps } from 'antd/es/form';
import ConnectHeaderWarning from 'views/customers/components/ConnectWarningHeader';
import ConnectionRow from 'views/customers/listing/components/ConnectionRow';
import { Title } from 'common-components/typography';
import CustomerConnectionCard from '../../components/CustomerConnectionCard';
import Utils from 'utilities/Utils';

interface IConnectionStepPanelProps extends FormComponentProps {
  selectedCustomer: any;
  onPressConnectLater: any;
  doCreateCustomerConnection: typeof dispatch.customersStore.doCreateCustomerConnection;
  setSelectedCustomer: typeof dispatch.customersStore.setSelectedCustomer;
}

interface IConnectionStepPanelState {
  isLoadingWithoutRequest: boolean;
  isLoadingWithRequest: boolean;
  isLoading: boolean;
}

class AddCustomerConnectionModal extends Component<IConnectionStepPanelProps, IConnectionStepPanelState> {
  private requestsRefs: any[] = [];

  state = {
    isLoadingWithoutRequest: false,
    isLoadingWithRequest: false,
    isLoading: false
  };

  private _setRef = (ref) => {
    if (ref) {
      this.requestsRefs.push(ref);
    }
  };

  private _sendConnection = async () => {
    const { doCreateCustomerConnection, selectedCustomer } = this.props;
    const { userId } = selectedCustomer;
    this.setState({ isLoading: true, isLoadingWithRequest: true });
    let customerConnectionRequest = _.map(this.requestsRefs, (requestsRef) => {
      const formValues = requestsRef.props.form.getFieldsValue();
      if (formValues.mobile && formValues.email) {
        const request: any = {
          userId: userId,
          targetUserId: formValues.targetUserId,
          mobile: formValues.mobile,
          mobileCountryCode: 'AU'
        };
        if (formValues.email) {
          request.email = formValues.email;
        }
        return request;
      }
    });

    customerConnectionRequest = _.filter(customerConnectionRequest, (request) => !_.isEmpty(request));

    try {
      if (customerConnectionRequest.length > 0) {
        await doCreateCustomerConnection({ customerConnectionRequests: customerConnectionRequest });
        notification.success({
          message: 'Customer connection request successfully sent.'
        });
        this.setState({ isLoading: false, isLoadingWithRequest: false, isLoadingWithoutRequest: false });
        // const newSelectedCustomer = selectedCustomer;
        // newSelectedCustomer.connected = 'PENDING';
        // TODO: get the new list of connection from the API's response and append it to selectedCustomer.customerConnectionRequests
        // this.props.setSelectedCustomer(newSelectedCustomer);
        this.props.onPressConnectLater();
      } else {
        this.setState({ isLoading: false, isLoadingWithRequest: false, isLoadingWithoutRequest: false });
      }
    } catch (e) {
      this.setState({ isLoading: false, isLoadingWithRequest: false, isLoadingWithoutRequest: false });
      notification.error({
        message: 'Oops, something went wrong, please try again.',
        description: e.error
      });
    }
  };

  private _removeGuardian = async (guardianUserId) => {
    const { selectedCustomer, setSelectedCustomer } = this.props;

    const newGuardiansInfo = _.map(selectedCustomer.guardiansInfo, (info) => {
      if (guardianUserId === info.guardianUserId) return { ...info, isRemoved: true };
      else return { ...info };
    });

    const newCustomerInfo = { ...selectedCustomer, guardiansInfo: newGuardiansInfo };

    await setSelectedCustomer(newCustomerInfo);
  };

  private _validateMobileNumber = (rule, value, callback) => {
    const region = 'AU';

    try {
      if (_.isEmpty(value)) {
        throw Error('Mobile Number is required');
      }
      let phoneNumber = parsePhoneNumberFromString(value, region);
      if (!phoneNumber || !phoneNumber.isValid()) {
        throw new Error('Invalid Mobile Number');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  render() {
    const { isLoading, isLoadingWithoutRequest, isLoadingWithRequest } = this.state;
    const { selectedCustomer } = this.props;
    const hasGuardians = !Utils.isEmpty(selectedCustomer.guardiansInfo);
    selectedCustomer.hasGuardians = hasGuardians;

    const filteredGuardianList = _.filter(selectedCustomer.guardiansInfo, (guardian) => !guardian.isRemoved);
    const displayRemoveButton = filteredGuardianList.length > 1;

    return (
      <div className="anim-slide-left">
        <Row>
          <Col className="ph-x4-large">
            <ConnectHeaderWarning className="pb-large" />
            <Title level={4}>Customer Record</Title>
            <CustomerConnectionCard
              connectionStatus={selectedCustomer.connected}
              ndisNumber={selectedCustomer.funding.NDISNumber}
              firstName={selectedCustomer.firstName}
              lastName={selectedCustomer.lastName}
              attachmentUrl={selectedCustomer.attachmentUrl}
              hasGuardian={!_.isEmpty(selectedCustomer.guardiansInfo)}
              customerStatus={selectedCustomer.customerStatus}
            />

            <div className="mt-large">
              <Title level={4}>Connect customer record to the following people</Title>
              <Text>
                Your customer may be legally overseen by one or more Parents or Guardians who manage the Customer’s
                account on the GoodHuman app on their behalf.
              </Text>
              <div className="mt-large width-full">
                <Row className="mb-small">
                  <Col span={8}>
                    <SubTitle>Person</SubTitle>
                  </Col>
                  <Col span={6}>
                    <SubTitle>PHONE NUMBER</SubTitle>
                  </Col>
                  <Col span={9}>
                    <SubTitle>EMAIL</SubTitle>
                  </Col>
                  <Col span={1} />
                </Row>
                {hasGuardians ? (
                  _.map(filteredGuardianList, (guardian, key) => {
                    return (
                      <ConnectionRow
                        key={key}
                        customerDetail={selectedCustomer}
                        guardianDetail={guardian}
                        validateMobileNumber={this._validateMobileNumber}
                        wrappedComponentRef={this._setRef}
                        hasRemoveButton={displayRemoveButton}
                        removeGuardian={this._removeGuardian}
                      />
                    );
                  })
                ) : (
                  <ConnectionRow
                    customerDetail={selectedCustomer}
                    validateMobileNumber={this._validateMobileNumber}
                    wrappedComponentRef={this._setRef}
                  />
                )}
              </div>
            </div>
            <div
              className="pv-medium width-full"
              style={{ backgroundColor: 'rgba(248,249,250,0.7)', position: 'sticky', bottom: 0 }}
            >
              <Row gutter={0} type="flex" align="middle" className="bg-transparent">
                <div className="flex-row align-center justify-between width-full">
                  <div className="bg-transparent">
                    <div className="text-align-left pv-medium" />
                  </div>

                  <div className="bg-transparent mr-small text-align-left pv-medium flex-row align-center">
                    <div className="mr-small">
                      <SecondaryButton
                        size="large"
                        loading={isLoadingWithoutRequest}
                        disabled={!isLoadingWithoutRequest && isLoading}
                        onClick={() => this.props.onPressConnectLater()}
                      >
                        Connect later
                      </SecondaryButton>
                    </div>
                    <div className="text-align-left pv-medium">
                      <PrimaryButton
                        size="large"
                        disabled={!isLoadingWithRequest && isLoading}
                        loading={isLoadingWithRequest}
                        onClick={this._sendConnection}
                      >
                        Send connection request
                      </PrimaryButton>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedCustomer: state.customersStore.selectedCustomer
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateCustomerConnection: dispatch.customersStore.doCreateCustomerConnection,
  setSelectedCustomer: dispatch.customersStore.setSelectedCustomer
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IConnectionStepPanelProps>()(AddCustomerConnectionModal));
