import { Avatar, Col, Empty, Input, Row, Skeleton } from 'antd';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { TeamStatus } from 'utilities/enum-utils';

const { Search } = Input;

interface IAddTeamMemberToRosterModalProps {
  isOpen: boolean;
  onClose: () => void;
  serviceId: string;
  workerListLite: any[];
  groupServiceWorkerList: typeof state.servicesStore.groupServiceWorkerList;
  doFetchWorkerListLite: typeof dispatch.teamStore.doFetchWorkerListLite;
  doAddTeamMemberToRoster: typeof dispatch.servicesStore.doAddTeamMemberToRoster;
  searchText: string;
}

interface IAddTeamMemberToRosterModalState {
  isSearching: boolean;
  isSaving: boolean;
  isLoading: boolean;
  selectedMembers: any[];
  step: number;
  addedMemberCount: number;
}

class AddTeamMemberToRosterModal extends Component<IAddTeamMemberToRosterModalProps, IAddTeamMemberToRosterModalState> {
  state = {
    isSearching: false,
    isSaving: false,
    isLoading: false,
    selectedMembers: [],
    step: 1,
    addedMemberCount: 0,
  };

  private _loadContent = async (payload) => {
    this.setState({ isLoading: true });
    // await asyncDelay(2000);
    await this.props.doFetchWorkerListLite({
      ...payload,
      hasAppAccess: ['WORKER'],
      supportWorkerStatus: [TeamStatus.ENABLED, TeamStatus.BLOCKED, TeamStatus.DRAFT],
      sortByRelevance: true,
    });
    this.setState({ isLoading: false });
  };

  private _selectWorker = (worker) => {
    const newSelectedWorker = _.clone(this.state.selectedMembers);
    newSelectedWorker.push(worker);
    this.setState({ selectedMembers: newSelectedWorker });
  };

  private _removeSelectedWorker = (worker) => {
    const newSelectedWorker = _.clone(this.state.selectedMembers);
    _.pull(newSelectedWorker, worker);
    this.setState({ selectedMembers: newSelectedWorker });
  };

  private _searchText = async (txt) => {
    this.setState({ isSearching: true });
    const payload = { search: txt };
    await this._loadContent(payload);
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _onCloseModal = () => {
    this.setState({ step: 1, selectedMembers: [], addedMemberCount: 0 });
    this.props.onClose();
  };

  private _onNextStep = async () => {
    const { selectedMembers } = this.state;
    const { serviceId, searchText, doAddTeamMemberToRoster } = this.props;
    this.setState({ isSaving: true });
    await doAddTeamMemberToRoster({ serviceId, selectedMembers, searchText });
    const newMemberCount = selectedMembers.length;
    this.setState({ step: 2, selectedMembers: [], addedMemberCount: newMemberCount, isSaving: false });
  };

  componentDidMount = async () => {
    await this._loadContent(null);
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.isOpen && this.props.isOpen !== prevProps.isOpen) {
      await this._loadContent(null);
    }
  };

  render() {
    const { workerListLite, groupServiceWorkerList } = this.props;
    const { selectedMembers, step, isLoading, isSaving, addedMemberCount } = this.state;
    const title = step === 1 ? 'Add team members to roster' : 'Team member successfully added to roster';
    const modalWidth = step === 1 ? 'x2-large' : 'medium';

    const WorkerEmptyState = () => (
      <div className="flex-1 bg-white mt-x2-large align-center flex-column">
        <div className="">
          <Empty
            description={
              <Text size="x2-large" color="secondary" weight="bold">
                No team members found
              </Text>
            }
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="mv-none"
          />
        </div>
      </div>
    );

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        onClose={this._onCloseModal}
        showCloseButton={step === 2}
        width={modalWidth}
        className="p-small"
        title={title}
      >
        {isSaving ? (
          <SpinningLoader size={150} message={'Saving...'} />
        ) : (
          <>
            {step === 1 && (
              <div className="flex-column anim-slide-left">
                <Text className="mb-x-large">Select the team members you wish to add to this roster</Text>
                <Search
                  placeholder="Search for a team member..."
                  size="large"
                  onChange={this._onEnterSearchText}
                  loading={this.state.isSearching}
                  className="mb-small"
                  style={{ width: '280px' }}
                  allowClear
                />
                <Row>
                  <Col span={12} className="pr-medium">
                    <Text color="secondary" className="mb-small">
                      Team members ({workerListLite.length})
                    </Text>
                    <div className="bordered flex-coloumn p-small" style={{ height: '360px', overflowY: 'scroll' }}>
                      {isLoading ? (
                        <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                      ) : workerListLite.length === 0 ? (
                        <WorkerEmptyState />
                      ) : (
                        _.map(workerListLite, (worker, index) => (
                          <div key={index} className="flex-row justify-between p-small mb-small align-center">
                            <div>
                              <Avatar src={worker.attachmentUrl} size="large" className="mr-medium" />
                              <Text weight="bold" size="regular">
                                {worker.firstName + ' ' + worker.lastName}
                              </Text>
                            </div>
                            {/*Compare worker id instead of worker object to avoid bugs after refetching workers */}
                            {/* Need to compare both the members already in roster and the members in temporary array*/}
                            {_.findIndex(selectedMembers, { supportWorkerId: worker.supportWorkerId }) >= 0 ||
                            _.findIndex(groupServiceWorkerList, { supportWorkerId: worker.supportWorkerId }) >= 0 ? (
                              <Text size="regular" color="tertiary">
                                In session
                              </Text>
                            ) : (
                              <HyperlinkButton
                                underline={true}
                                fontSize="regular"
                                onClick={() => this._selectWorker(worker)}
                              >
                                Add to roster
                              </HyperlinkButton>
                            )}
                          </div>
                        ))
                      )}
                    </div>
                  </Col>

                  <Col span={12} className="pl-medium">
                    <Text color="secondary" className="mb-small">
                      Team members ({selectedMembers.length})
                    </Text>
                    <div className="bordered flex-coloumn p-small" style={{ height: '360px', overflowY: 'scroll' }}>
                      {!isLoading &&
                        _.map(selectedMembers, (worker) => (
                          <div className="flex-row justify-between p-small mb-small align-center">
                            <div>
                              <Avatar src={worker.attachmentUrl} size="large" className="mr-medium" />
                              <Text weight="bold" size="regular">
                                {worker.firstName + ' ' + worker.lastName}
                              </Text>
                            </div>
                            <HyperlinkButton
                              color="red"
                              fontSize="regular"
                              onClick={() => this._removeSelectedWorker(worker)}
                            >
                              Remove
                            </HyperlinkButton>
                          </div>
                        ))}
                    </div>
                  </Col>
                </Row>

                <Row type="flex" justify="end" className="mt-x2-large">
                  <SecondaryButton size="large" onClick={this._onCloseModal} className="mr-large" disabled={isSaving}>
                    Cancel
                  </SecondaryButton>

                  <PrimaryButton
                    size="large"
                    disabled={selectedMembers.length === 0 || isSaving}
                    onClick={this._onNextStep}
                  >
                    {' '}
                    Add to roster
                  </PrimaryButton>
                </Row>
              </div>
            )}

            {step === 2 && (
              <div className="anim-slide-left mt-medium flex-column">
                <Text>
                  You have successfully add{' '}
                  <span className="text-weight-bold">
                    {' '}
                    {addedMemberCount} team member{addedMemberCount !== 1 && 's'}
                  </span>{' '}
                  to the roster for this service.{' '}
                </Text>
                <Text className="mt-medium">
                  {addedMemberCount === 1 ? 'That team member' : 'Those team members'} can now be assigned to sessions
                  for this service.
                </Text>
                <ActionModalFooter className="mt-large">
                  <PrimaryButton size="large" onClick={this._onCloseModal}>
                    Close
                  </PrimaryButton>
                </ActionModalFooter>
              </div>
            )}
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  workerListLite: state.teamStore.workerListLite,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchWorkerListLite: dispatch.teamStore.doFetchWorkerListLite,
  doAddTeamMemberToRoster: dispatch.servicesStore.doAddTeamMemberToRoster,
});

export default connect(mapState, mapDispatch)(AddTeamMemberToRosterModal);
