import React from 'react';
import { IInstructionsDetails } from 'views/group-services/manage-booking-modal/interfaces/group-booking-interfaces';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';

interface IDeleteInstructionsModalProps {
  isOpen: boolean;
  instructions: IInstructionsDetails;
  onClose: any;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
  doDeleteGroupBookingInstructions: typeof dispatch.groupBookingsStore.doDeleteGroupBookingInstructions;
}

interface IDeleteInstructionsModalState {
  deleteConfirmed: boolean;
}

class DeleteInstructionsModal extends React.Component<IDeleteInstructionsModalProps, IDeleteInstructionsModalState> {
  state = { deleteConfirmed: false };

  resetModal = () => this.setState({ deleteConfirmed: false });

  onCancel = () => {
    const { onClose } = this.props;
    // TODO Cancel logic (if any)
    onClose();
  };

  onDelete = async () => {
    const { selectedGroupBookingItem, doDeleteGroupBookingInstructions, instructions } = this.props;

    await doDeleteGroupBookingInstructions({
      bookingId: selectedGroupBookingItem.bookingId,
      attendanceInstructionId: instructions.attendanceInstructionId
    });

    this.setState({ deleteConfirmed: true });
  };

  onClose = () => {
    const { onClose } = this.props;
    this.resetModal();
    onClose();
  };

  render() {
    const { isOpen, onClose } = this.props;

    return (
      <ActionModal
        isOpen={isOpen}
        title={this.state.deleteConfirmed ? 'Instructions successfully deleted' : 'Delete instructions'}
        onClose={onClose}
      >
        {/* Pending user confirmation */}
        {!this.state.deleteConfirmed && (
          <section className="line-height-120 mb-medium">
            <Text lineHeight={120}>
              Are you sure you want to delete this instruction?
              <br />
              <br />
              This action is permanent and cannot be undone.
            </Text>
          </section>
        )}

        {/* Delete action confirmed */}
        {this.state.deleteConfirmed && (
          <section className="anim-fade-in-fast mb-medium">
            <Text>Instructions successfully deleted from this booking.</Text>
          </section>
        )}

        <ActionModalFooter align={'right'}>
          {/* Pending user confirmation */}
          {!this.state.deleteConfirmed && (
            <>
              <SecondaryButton onClick={this.onCancel} className="mr-medium" size="large">
                Cancel
              </SecondaryButton>
              <PrimaryButton color="red" onClick={this.onDelete} size="large">
                Delete
              </PrimaryButton>
            </>
          )}

          {/* Delete action confirmed */}
          {this.state.deleteConfirmed && (
            <>
              <PrimaryButton onClick={this.onClose} size="large">
                Close
              </PrimaryButton>
            </>
          )}
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doDeleteGroupBookingInstructions: dispatch.groupBookingsStore.doDeleteGroupBookingInstructions
});

export default connect(
  mapState,
  mapDispatch
)(DeleteInstructionsModal);
