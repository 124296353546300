import { DataItem } from './data-interfaces';
import _ from 'lodash';

// TODO Add Memoize
export default class CommonDataHelper {
  data: DataItem[];

  constructor(inputData: DataItem[]) {
    this.data = inputData;
  }

  // get by property
  get fullData() {
    return this.data;
  }

  get categories() {
    const rawCategories = this.data.map((item) => item.category);
    return _.uniq(rawCategories).sort();
  }

  get subCategories() {
    const rawSubCategories = this.data.map((item) => item.subCategory);
    return _.uniq(rawSubCategories).sort();
  }

  // get by specific function
  getBySubcategory(subCategory) {
    return this.data.filter((item) => item.subCategory === subCategory);
  }

  getByTagid(tagId) {
    return _.head(this.data.filter((item) => item.tagId === tagId));
  }
}
