import React, { Component } from 'react';
import moment, { Moment } from 'moment-timezone';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, SubTitle, Text, FieldLabel } from 'common-components/typography';
import { Avatar, Col, Divider, Icon, InputNumber, notification, Radio, Row } from 'antd';
import { HyperlinkButton, IconButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { ErrorSVG } from 'assets/UndrawSVG';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import CommonUtils from 'utilities/common-utils';
import _, { isEmpty } from 'lodash';
import * as H from 'history';

import DatePicker from 'react-datepicker';
import TimeInput from 'common-components/time-input/TimeInput';
import { TimezoneIndicator } from 'common-components/timezone';
import { Information } from 'common-components/alerts';
import {
  BookingStatus,
  EditRecurringMode,
  RecurringBookingPattern,
  ServiceType,
  ShiftSlotStatus,
} from 'utilities/enum-utils';
import { timeZone } from 'interfaces/timezone-type';
import ShiftSlotStatusTag from 'common-components/tags/ShiftSlotStatusTag';
import { Popover } from '@blueprintjs/core';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { ConflictResult } from 'views/bookings/components/BookingConfictItem';
import AvailabilityConflictWorker from 'views/team/details/tabs-panel/availability/components/AvailabilityConflictWorker';

interface IBookingEditBookingDatesActionModelProps {
  isOpen: any;
  onClose: any;
  selectedBookingItem: typeof state.bookingsStore.selectedBookingItem;
  recurringBookingList: typeof state.bookingsStore.recurringBookingList;
  doUpdateBookingDates: typeof dispatch.bookingsStore.doUpdateBookingDates;
  doUpdateBookingDatesAndRemoveWorker: typeof dispatch.bookingsStore.doUpdateBookingDatesAndRemoveWorker;
  doCheckEditTimes: typeof dispatch.bookingsStore.doCheckEditTimes;
  displayTimezone?: timeZone;
  history: H.History;
  doCheckServiceAgreementDate: typeof dispatch.bookingsStore.doCheckServiceAgreementDate;
}

interface IEditBookingDatesActionModalState {
  title: any;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  isRecurringOptions: boolean;
  isCustomerConflictList: boolean;
  isWorkerPendingConflictList: boolean;
  isWorkerConfirmedConflictList: boolean;
  isOverlapConflictList: boolean;
  isErrorAvailability: boolean;
  showAvailabilityErrorStep: boolean;
  startDateTime: Moment;
  endDateTime: Moment;
  conflictTimes: any[];
  isErrorEndDate: boolean;
  selectedOption: number;
  numberOfBookings: number;
  isVerifying: boolean;
  sameSeriesConflicts: any;
  workerPendingConflicts: any;
  workerConfirmedConflicts: any;
  overlapConflicts: any;
  cancelAllConflicts: boolean;
  errorConfirmConflicts: boolean;
  modalWidth: string;
  numberOfBookingError: boolean;
  showActiveAgreementWarningModal: boolean;
  isRemovePendingShiftSlots: boolean;
  isAfterCustomerScheduledArchiveDate: boolean;
  showCustomerScheduleArchiveError: boolean;
  scheduledRemoveConflict: any;
  showUnavailableBookings: boolean;
  showTeamMemberScheduledRemove: boolean;
  showUpdateSuccessWithRemovedMember: boolean;
  showUpdateSuccessWithRemovedMemberForMultiple: boolean;
  showConflictBookings: boolean;
  everyDayMultiDayBookingTooLongError: boolean;
  showOutsideAvailabilityWorkers: boolean;
  showOutsideAvailabilityBooking: boolean;
  outsideAvailabilityConflicts: any;
  selectedShiftsToKeep: any[];
}

class BookingEditBookingDatesActionModel extends Component<
  IBookingEditBookingDatesActionModelProps,
  IEditBookingDatesActionModalState
> {
  state = {
    title: 'Edit booking time',
    isLoading: false,
    isSuccess: false,
    isError: false,
    isRecurringOptions: false,
    isCustomerConflictList: false,
    isWorkerPendingConflictList: false,
    isWorkerConfirmedConflictList: false,
    isOverlapConflictList: false,
    isErrorAvailability: false,
    showAvailabilityErrorStep: false,
    startDateTime: null,
    endDateTime: null,
    conflictTimes: [],
    isErrorEndDate: false,
    selectedOption: EditRecurringMode.Current,
    numberOfBookings: 1,
    isVerifying: false,
    sameSeriesConflicts: null,
    workerPendingConflicts: null,
    workerConfirmedConflicts: null,
    overlapConflicts: null,
    cancelAllConflicts: false,
    errorConfirmConflicts: false,
    modalWidth: 'medium',
    numberOfBookingError: false,
    showActiveAgreementWarningModal: false,
    isRemovePendingShiftSlots: false,
    isAfterCustomerScheduledArchiveDate: false,
    showCustomerScheduleArchiveError: false,
    scheduledRemoveConflict: null,
    showUnavailableBookings: false,
    showTeamMemberScheduledRemove: false,
    showUpdateSuccessWithRemovedMember: false,
    showUpdateSuccessWithRemovedMemberForMultiple: false,
    showConflictBookings: false,
    everyDayMultiDayBookingTooLongError: false,
    showOutsideAvailabilityBooking: false,
    showOutsideAvailabilityWorkers: false,
    outsideAvailabilityConflicts: null,
    selectedShiftsToKeep: null,
  };

  private _resetModal = () => {
    this.setState({
      title: 'Edit booking time',
      isLoading: false,
      isSuccess: false,
      isError: false,
      isCustomerConflictList: false,
      isWorkerPendingConflictList: false,
      isWorkerConfirmedConflictList: false,
      isRecurringOptions: false,
      showAvailabilityErrorStep: false,
      cancelAllConflicts: false,
      modalWidth: 'medium',
      errorConfirmConflicts: false,
      isErrorAvailability: false,
      sameSeriesConflicts: null,
      workerPendingConflicts: null,
      workerConfirmedConflicts: null,
      selectedOption: EditRecurringMode.Current,
      numberOfBookings: 1,
      startDateTime: moment.tz(this.props.selectedBookingItem.startDateTime, this.props.displayTimezone),
      endDateTime: moment.tz(this.props.selectedBookingItem.endDateTime, this.props.displayTimezone),
      isRemovePendingShiftSlots: false,
      isAfterCustomerScheduledArchiveDate: false,
      showCustomerScheduleArchiveError: false,
      scheduledRemoveConflict: null,
      showUnavailableBookings: false,
      showTeamMemberScheduledRemove: false,
      showUpdateSuccessWithRemovedMember: false,
      showUpdateSuccessWithRemovedMemberForMultiple: false,
      isVerifying: false,
      showOutsideAvailabilityBooking: false,
      showOutsideAvailabilityWorkers: false,
      outsideAvailabilityConflicts: null,
      selectedShiftsToKeep: null,
    });
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this._resetModal();
      onClose();
    }

    // Do any manual clean up.
  };

  private _goToBooking = (bookingId) => {
    const { history } = this.props;
    history.push(`/bookings/details/${bookingId}`);
  };

  private _goToSession = (serviceId, serviceDateTime) => {
    const { history } = this.props;
    history.push(`/group-service/${serviceId}/session/details/${serviceDateTime}`);
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _onChangeNumberOfBookings = (event) => {
    this.setState({ numberOfBookings: event });
  };

  private _displayRecurringOptions = () => {
    this.setState({ isRecurringOptions: true });
  };

  private _onCancelConflicts = async () => {
    this.setState({
      isCustomerConflictList: false,
      isWorkerPendingConflictList: false,
      isWorkerConfirmedConflictList: false,
      title: 'Edit booking time',
    });
  };

  private checkCurrentBookingEditDateTime = async () => {
    const { selectedBookingItem, doCheckEditTimes } = this.props;
    const { startDateTime, endDateTime } = this.state;
    const payload = {
      bookingRequestId: selectedBookingItem.bookingRequestId,
      bookingId: selectedBookingItem.bookingId,
      editRecurringMode: EditRecurringMode.Current,
      startDateTime: moment.tz(startDateTime, selectedBookingItem.timezone),
      endDateTime: moment.tz(endDateTime, selectedBookingItem.timezone),
      isRecurring: selectedBookingItem.isRecurring,
    };
    try {
      const result: any = await doCheckEditTimes(payload);
      this.setState({
        isErrorAvailability: !!result.data.serviceUnavailable,
      });
      return { canContinue: !result.data.serviceUnavailable, result: result.data };
    } catch (e) {
      notification.error({
        message: 'Oops, something went wrong, please try again.',
        description: _.get(e.meta, 'message') || e.message,
      });
      this.setState({
        isErrorAvailability: true,
      });
      return { canContinue: false };
    }
  };

  private _applyRecurringOption = async (isInProgressOrCompleted: boolean = false) => {
    if (isInProgressOrCompleted) {
      await this.setState({ selectedOption: EditRecurringMode.Current });
    }

    const { selectedBookingItem, doCheckEditTimes, recurringBookingList } = this.props;
    const { startDateTime, endDateTime, selectedOption, numberOfBookings } = this.state;

    const sortedRecurringBookingList = _.orderBy(recurringBookingList, 'startDateTime');

    const lastBookingToBeUpdated =
      selectedOption === EditRecurringMode.Current
        ? _.find(sortedRecurringBookingList, { attendanceId: selectedBookingItem.bookingId })
        : selectedOption === EditRecurringMode.CurrentAll
        ? _.last(sortedRecurringBookingList)
        : sortedRecurringBookingList[
            _.findIndex(sortedRecurringBookingList, { attendanceId: selectedBookingItem.bookingId }) + numberOfBookings
          ];

    const newEndDateDiffInDays = moment
      .tz(endDateTime, selectedBookingItem.timezone)
      .diff(moment.tz(selectedBookingItem.endDateTime, selectedBookingItem.timezone), 'minutes');

    if (
      selectedOption === EditRecurringMode.CurrentNext &&
      (!numberOfBookings || selectedBookingItem.numberOfBookingLeft < numberOfBookings)
    ) {
      this.setState({
        numberOfBookingError: true,
      });
    } else if (
      selectedBookingItem.customerScheduleArchiveDate &&
      lastBookingToBeUpdated &&
      moment
        .tz(lastBookingToBeUpdated.endDateTime, selectedBookingItem.timezone)
        .add(newEndDateDiffInDays, 'minutes')
        .isSameOrAfter(moment.tz(selectedBookingItem.customerScheduleArchiveDate, selectedBookingItem.timezone))
    ) {
      this.setState({
        showCustomerScheduleArchiveError: true,
        title: 'Customer scheduled to be archived',
      });
    } else {
      try {
        this.setState({ isVerifying: true });
        const payload = {
          bookingRequestId: selectedBookingItem.bookingRequestId,
          bookingId: selectedBookingItem.bookingId,
          editRecurringMode: this.state.selectedOption,
          numberOfBookings: this.state.numberOfBookings,
          startDateTime: moment.tz(startDateTime, selectedBookingItem.timezone),
          endDateTime: moment.tz(endDateTime, selectedBookingItem.timezone),
          isRecurring: selectedBookingItem.isRecurring,
        };
        const result: any = await doCheckEditTimes(payload);
        const workerPendingConflicts = result.data.workerConflicts && result.data.workerConflicts.pendingShiftConflicts;
        const workerConfirmedConflicts =
          result.data.workerConflicts && result.data.workerConflicts.confirmedShiftConflicts;
        if (result && result.data.workerScheduleRemoved.length > 0) {
          const data = result.data.workerScheduleRemoved[0];
          if (selectedOption === EditRecurringMode.Current) {
            this.setState({
              showTeamMemberScheduledRemove: true,
              title: 'Team member scheduled to be removed',
              scheduledRemoveConflict: {
                worker: { firstName: data.firstName, lastName: data.lastName },
                removeDate: data.removedOn,
                bookingDate: [data.bookings[0].startDateTime, data.bookings[0].endDateTime],
              },
              isLoading: false,
            });
          } else {
            this.setState({
              showUnavailableBookings: true,
              title: 'Team member unavailable for bookings',
              modalWidth: 'x-large',
              scheduledRemoveConflict: {
                worker: { firstName: data.firstName, lastName: data.lastName, attachmentUrl: data.attachmentUrl },
                removeDate: data.removedOn,
                bookingDate: [data.bookings[0].startDateTime, data.bookings[0].endDateTime],
                bookings: data.bookings,
              },
              isLoading: false,
            });
          }
        } else if (result.data.sameSeriesConflicts.length > 0) {
          this.setState({
            isVerifying: false,
            sameSeriesConflicts: result.data.sameSeriesConflicts,
            workerPendingConflicts,
            workerConfirmedConflicts,
            isRecurringOptions: false,
            modalWidth: 'x-large',
            isCustomerConflictList: true,
            title: 'Overlapping bookings!',
          });
        } else if (
          result.data.workerConflicts &&
          (workerPendingConflicts.length > 0 || workerPendingConflicts.length > 0)
        ) {
          this.setState({
            isVerifying: false,
            workerPendingConflicts,
            workerConfirmedConflicts,
            isRecurringOptions: false,
            modalWidth: 'x-large',
            isWorkerPendingConflictList: workerPendingConflicts.length > 0,
            isWorkerConfirmedConflictList: workerConfirmedConflicts.length > 0,
            title: 'Team member assigned to conflicting shift(s)',
          });
        } else if (result.data.serviceUnavailable) {
          this.setState({
            isVerifying: false,
            isRecurringOptions: false,
            showAvailabilityErrorStep: true,
            title: 'Service availability',
          });
        } else if (!_.isEmpty(result.data.workerOutsideAvailability)) {
          if (this.state.selectedOption === EditRecurringMode.Current) {
            const outsideAvailabilityConflict = _.find(
              result.data.workerOutsideAvailability,
              (supportWorker) => supportWorker.supportWorkerId === this.props.selectedBookingItem.workerId,
            );
            outsideAvailabilityConflict.bookings = _.chain(outsideAvailabilityConflict.bookings)
              .filter((booking) => booking.attendanceId === this.props.selectedBookingItem.bookingId)
              .take(1)
              .value();

            this.setState({
              isVerifying: false,
              isRecurringOptions: false,
              showAvailabilityErrorStep: false,
              title: `Booking outside team member's scheduled availability`,
              showOutsideAvailabilityBooking: true,
              outsideAvailabilityConflicts: outsideAvailabilityConflict,
            });
          } else {
            this.setState({
              isVerifying: false,
              isRecurringOptions: false,
              showAvailabilityErrorStep: false,
              showOutsideAvailabilityWorkers: true,
              isLoading: false,
              outsideAvailabilityConflicts: result.data.workerOutsideAvailability,
              title: 'Team member unavailable for bookings',
              modalWidth: 'x-large',
            });
          }
        } else {
          this.setState({ isVerifying: false, isRecurringOptions: false, showAvailabilityErrorStep: false });
          await this._onSubmitEdit();
        }
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong, please try again.', description: e.message });
        this.setState({
          isVerifying: false,
        });
      }
    }
  };

  private _getShiftsSelectedToBeKept = (isRemoveTeamMember, isReccuring) => {
    const { outsideAvailabilityConflicts, selectedShiftsToKeep } = this.state;
    let recurringBooking = [];
    let oneOffBooking = [];

    if (!isReccuring) {
      oneOffBooking = _.map(outsideAvailabilityConflicts.bookings, (booking) => ({
        serviceDateTimeId: booking.serviceDateTimeId,
        attendanceId: booking.attendanceId,
        supportWorkerAttendanceId: booking.supportWorkerAttendanceId,
      }));
    } else {
      if (selectedShiftsToKeep) {
        const arrayList = Object.values(selectedShiftsToKeep);
        recurringBooking = _.flatten(arrayList);
      }
    }
    return !isRemoveTeamMember ? (!isReccuring ? oneOffBooking : recurringBooking) : [];
  };

  private _onSubmitEdit = async (isRemoveTeamMember?: boolean) => {
    const { doUpdateBookingDates, selectedBookingItem } = this.props;
    const {
      startDateTime,
      endDateTime,
      selectedOption,
      numberOfBookings,
      isRemovePendingShiftSlots,
      outsideAvailabilityConflicts,
    } = this.state;

    let isSuccess = true;
    let isError = false;
    this.setState({ isLoading: true });

    const payload = {
      startDateTime: startDateTime,
      endDateTime: endDateTime,
      removeWorkerAndSave: true,
      isRecurring: selectedBookingItem.isRecurring,
      bookingId: selectedBookingItem.bookingId,
      bookingRequestId: selectedBookingItem.bookingRequestId,
      editRecurringMode: selectedOption,
      timezone: selectedBookingItem.timezone,
      numberOfBookings,
      isRemovePendingShiftSlots,
      shiftsSelectedToBeKept: !_.isEmpty(outsideAvailabilityConflicts)
        ? this._getShiftsSelectedToBeKept(
            isRemoveTeamMember,
            selectedBookingItem.isRecurring && selectedOption !== EditRecurringMode.Current,
          )
        : null,
    };

    try {
      const result: any = await doUpdateBookingDates(payload);
      if (result.conflict) {
        isSuccess = false;
        isError = true;
      }
      this.setState({
        isLoading: false,
        isSuccess,
        title: 'Booking time successfully changed',
        isError,
        showActiveAgreementWarningModal: false,
      });
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong, please try again.' });
      this.setState({ isLoading: false, isSuccess: false, isError: true, showActiveAgreementWarningModal: false });
    }
  };

  private _onChangeStartDate = async (date) => {
    const { selectedBookingItem } = this.props;
    const startDateTime = moment.tz(CommonUtils.formatCeilingDateTime(date), selectedBookingItem.timezone);
    const dateDifference = startDateTime.diff(this.state.startDateTime, 'days');

    const isAfterCustomerScheduledArchiveDate =
      selectedBookingItem.customerScheduleArchiveDate &&
      startDateTime.isSameOrAfter(
        moment.tz(selectedBookingItem.customerScheduleArchiveDate, selectedBookingItem.timezone),
      );

    const hoursDiff = moment
      .tz(this.state.endDateTime, selectedBookingItem.timezone)
      .diff(moment.tz(startDateTime, selectedBookingItem.timezone), 'hours');

    this.setState({
      startDateTime,
      endDateTime: this.state.endDateTime.add(dateDifference, 'days'),
      isErrorEndDate: false,
      isErrorAvailability: false,
      isAfterCustomerScheduledArchiveDate,
      everyDayMultiDayBookingTooLongError:
        selectedBookingItem.recurringPattern.recurringPattern === RecurringBookingPattern.EveryDay && hoursDiff >= 24
          ? true
          : false,
    });
  };

  private _onChangeEndDate = async (date) => {
    const { startDateTime } = this.state;
    const { selectedBookingItem } = this.props;

    let isErrorEndDate = false;

    let endDateTime = moment.tz(CommonUtils.formatCeilingDateTime(date), selectedBookingItem.timezone);

    if (endDateTime <= startDateTime) {
      endDateTime = moment.tz(startDateTime, selectedBookingItem.timezone).add(1, 'hour');
      isErrorEndDate = true;
    }

    const isAfterCustomerScheduledArchiveDate =
      selectedBookingItem.customerScheduleArchiveDate &&
      endDateTime.isSameOrAfter(
        moment.tz(selectedBookingItem.customerScheduleArchiveDate, selectedBookingItem.timezone),
      );

    const hoursDiff = moment
      .tz(endDateTime, selectedBookingItem.timezone)
      .diff(moment.tz(startDateTime, selectedBookingItem.timezone), 'hours');

    this.setState({
      endDateTime,
      isErrorEndDate: isErrorEndDate,
      isErrorAvailability: false,
      isAfterCustomerScheduledArchiveDate,
      everyDayMultiDayBookingTooLongError:
        selectedBookingItem.recurringPattern.recurringPattern === RecurringBookingPattern.EveryDay && hoursDiff >= 24
          ? true
          : false,
    });
  };

  private _goToService = () => {
    const { selectedBookingItem, history } = this.props;
    history.push(`/service/details/${selectedBookingItem.serviceId}`);
  };

  private _getMaxDate = (dateType) => {
    const { displayTimezone, selectedBookingItem } = this.props;

    const savedStartDate = selectedBookingItem.startDateTime;
    const savedEndDate = this.state.endDateTime;
    const { recurringPattern } = selectedBookingItem.recurringPattern;

    if (recurringPattern === RecurringBookingPattern.EveryWeek) {
      return moment
        .tz(dateType === 'startDate' ? savedStartDate : savedEndDate, displayTimezone)
        .add(6, 'days')
        .toDate();
    } else if (recurringPattern === RecurringBookingPattern.EveryFortnight) {
      return moment
        .tz(dateType === 'startDate' ? savedStartDate : savedEndDate, displayTimezone)
        .add(13, 'days')
        .toDate();
    } else if (recurringPattern === RecurringBookingPattern.EveryFourWeeks) {
      return moment
        .tz(dateType === 'startDate' ? savedStartDate : savedEndDate, displayTimezone)
        .add(27, 'days')
        .toDate();
    } else {
      return moment().toDate();
    }
  };

  private _onContinuePending = async (isRemovePendingShiftSlots) => {
    this.setState({ isRemovePendingShiftSlots, isWorkerPendingConflictList: false }, async () => {
      if (isEmpty(this.state.isWorkerConfirmedConflictList)) {
        await this._onSubmitEdit();
      }
    });
  };

  private _onContinueConfirmed = async () => {
    this.setState({ isWorkerConfirmedConflictList: false }, async () => {
      if (isEmpty(this.state.isWorkerPendingConflictList)) {
        await this._onSubmitEdit();
      }
    });
  };

  private _getMinDate = (dateType) => {
    const { displayTimezone, selectedBookingItem } = this.props;

    const savedStartDate = selectedBookingItem.startDateTime;
    const savedEndDate = selectedBookingItem.endDateTime;
    const { recurringPattern } = selectedBookingItem.recurringPattern;

    if (dateType === 'endDate') {
      return moment.tz(this.state.startDateTime, displayTimezone).toDate();
    } else if (recurringPattern === RecurringBookingPattern.EveryWeek) {
      return moment
        .tz(dateType === 'startDate' ? savedStartDate : savedEndDate, displayTimezone)
        .add(-6, 'days')
        .toDate();
    } else if (recurringPattern === RecurringBookingPattern.EveryFortnight) {
      return moment
        .tz(dateType === 'startDate' ? savedStartDate : savedEndDate, displayTimezone)
        .add(-13, 'days')
        .toDate();
    } else if (recurringPattern === RecurringBookingPattern.EveryFourWeeks) {
      return moment
        .tz(dateType === 'startDate' ? savedStartDate : savedEndDate, displayTimezone)
        .add(-27, 'days')
        .toDate();
    } else {
      return moment().toDate();
    }
  };

  private _getInformationPatternText = () => {
    const { selectedBookingItem } = this.props;
    switch (selectedBookingItem.recurringPattern.recurringPattern) {
      case RecurringBookingPattern.EveryWeek:
        return (
          <>
            This booking is part of a <b>weekly</b> recurring pattern and dates can only be changed within a week of the
            booking.
          </>
        );
      case RecurringBookingPattern.EveryFortnight:
        return (
          <>
            This booking is part of a <b>once every two weeks</b> recurring pattern and dates can only be changed within
            two weeks of the booking.
          </>
        );
      case RecurringBookingPattern.EveryFourWeeks:
        return (
          <>
            This booking is part of a <b>once every four weeks</b> recurring pattern and dates can only be changed
            within four weeks of the booking.
          </>
        );

      case RecurringBookingPattern.EveryDay:
        return (
          <>
            This booking is part of a <b>daily</b> recurring pattern. The start date and finish date must be on the same
            day.
          </>
        );
    }
  };

  private _onUpdateWithRemovedMember = async () => {
    await this._onSubmitEdit();
    this.setState({ showUpdateSuccessWithRemovedMember: true, title: 'Scheduled time of booking changed' });
  };

  private _onUpdateWithRemoveMemberForMultiple = async () => {
    await this._onSubmitEdit();
    this.setState({ showUpdateSuccessWithRemovedMemberForMultiple: true, title: 'Scheduled time of booking changed' });
  };

  private _showConflictBookings = () => {
    this.setState({
      showConflictBookings: !this.state.showConflictBookings,
    });
  };

  private _submitOutsideConflict = async (isRemove) => {
    this._onSubmitEdit(isRemove);
  };

  private _onChangeShiftsToKeep = (shifts, worker) => {
    this.setState({
      selectedShiftsToKeep: { ...this.state.selectedShiftsToKeep, [worker.supportWorkerId]: shifts },
    });
  };

  componentDidMount() {
    const { selectedBookingItem, displayTimezone } = this.props;
    this.setState({
      isSuccess: false,
      isLoading: false,
      isError: false,
      startDateTime: moment.tz(selectedBookingItem.startDateTime, displayTimezone),
      endDateTime: moment.tz(selectedBookingItem.endDateTime, displayTimezone),
    });
  }

  render() {
    const { isOpen, selectedBookingItem, displayTimezone } = this.props;
    const {
      startDateTime,
      endDateTime,
      workerPendingConflicts,
      workerConfirmedConflicts,
      overlapConflicts,
      showActiveAgreementWarningModal,
      scheduledRemoveConflict,
    } = this.state;
    const informationPatternText =
      selectedBookingItem && selectedBookingItem.recurringPattern && this._getInformationPatternText();
    const isInProgressOrCompleted =
      selectedBookingItem.status === BookingStatus.INPROGRESS || selectedBookingItem.status === BookingStatus.COMPLETED;

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width={this.state.modalWidth}
        verticalAlignment="highest"
      >
        <ActionModal
          title="No service agreement for selected dates"
          onClose={() => this.setState({ showActiveAgreementWarningModal: false })}
          isOpen={showActiveAgreementWarningModal}
        >
          <Text>
            The customer does not have a service agreement for the dates you have selected. Are you sure you want to
            change the date?
          </Text>
          <br />
          <ActionModalFooter>
            <SecondaryButton
              className="mr-medium"
              size="large"
              onClick={() => this.setState({ showActiveAgreementWarningModal: false })}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" loading={this.state.isLoading} onClick={this._onSubmitEdit}>
              Save changes
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        {!this.state.isLoading &&
          !this.state.isSuccess &&
          !this.state.isError &&
          !this.state.isRecurringOptions &&
          !this.state.isCustomerConflictList &&
          !this.state.showAvailabilityErrorStep &&
          !this.state.isOverlapConflictList &&
          !this.state.isWorkerConfirmedConflictList &&
          !this.state.isWorkerPendingConflictList &&
          !this.state.showUnavailableBookings &&
          !this.state.showTeamMemberScheduledRemove &&
          !this.state.showOutsideAvailabilityBooking &&
          !this.state.showOutsideAvailabilityWorkers && (
            <div className="text-align-left mt-small">
              {selectedBookingItem.isRecurring && <Information content={informationPatternText} />}

              <div className="mt-medium mb-x-large">
                <Row type="flex" align="middle" gutter={24}>
                  <Col span={6}>
                    <Text weight={'bold'}>Start Date</Text>
                  </Col>
                  <Col span={7}>
                    {/* <div className="flex-row align-center justify-center mb-x-large"> */}
                    <DatePicker
                      className="gh-datepicker rounded"
                      calendarClassName="gh-datepicker-calendar"
                      dateFormat="d/M/yyyy"
                      selected={new Date(moment.tz(startDateTime, displayTimezone).format('YYYY/MM/DD HH:mm'))}
                      maxDate={
                        selectedBookingItem && selectedBookingItem.recurringPattern && this._getMaxDate('startDate')
                      }
                      minDate={
                        selectedBookingItem && selectedBookingItem.recurringPattern && this._getMinDate('startDate')
                      }
                      onChange={this._onChangeStartDate}
                      disabled={
                        selectedBookingItem &&
                        selectedBookingItem.recurringPattern &&
                        selectedBookingItem.recurringPattern.recurringPattern === RecurringBookingPattern.EveryDay
                      }
                    />
                  </Col>
                  <Col span={11}>
                    <TimeInput
                      size="large"
                      value={moment.tz(this.state.startDateTime, displayTimezone)}
                      onChange={this._onChangeStartDate}
                    />
                  </Col>
                </Row>
                {/* </div> */}
                {/* <div className="mb-medium">
              <Paragraph>Please select an End Date and Time</Paragraph>
            </div> */}
                <Row type="flex" align="middle" className="mt-medium" gutter={24}>
                  <Col span={6}>
                    <Text weight={'bold'}>Finish Date</Text>
                  </Col>
                  <Col span={7}>
                    <DatePicker
                      className="gh-datepicker rounded"
                      calendarClassName="gh-datepicker-calendar"
                      dateFormat="d/M/yyyy"
                      maxDate={
                        selectedBookingItem && selectedBookingItem.recurringPattern && this._getMaxDate('endDate')
                      }
                      minDate={
                        selectedBookingItem && selectedBookingItem.recurringPattern && this._getMinDate('endDate')
                      }
                      selected={new Date(moment.tz(endDateTime, displayTimezone).format('YYYY/MM/DD HH:mm'))}
                      onChange={this._onChangeEndDate}
                      disabled={
                        selectedBookingItem &&
                        selectedBookingItem.recurringPattern &&
                        selectedBookingItem.recurringPattern.recurringPattern === RecurringBookingPattern.EveryDay
                      }
                    />
                  </Col>
                  <Col span={11}>
                    <TimeInput
                      size="large"
                      value={moment.tz(this.state.endDateTime, displayTimezone)}
                      onChange={this._onChangeEndDate}
                    />
                  </Col>
                </Row>
                <div className="text-align-center mv-medium">
                  <Text size="small" color="secondary">
                    CURRENT TIMEZONE{' '}
                    <span className="ml-small">
                      <TimezoneIndicator
                        hasIcon={false}
                        showTzName={false}
                        bordered={false}
                        timezone={this.props.displayTimezone}
                      />
                    </span>
                  </Text>
                </div>
                {this.state.everyDayMultiDayBookingTooLongError && (
                  <Row>
                    <Col span={6}></Col>
                    <Col span={18}>
                      <div className="text-color-red mt-small ml-small">
                        <Icon type="exclamation-circle" className="mr-x-small" />
                        Bookings must be less than 24 hours.{' '}
                      </div>
                    </Col>
                  </Row>
                )}
                {this.state.isErrorEndDate && (
                  <Row>
                    <Col span={6}></Col>
                    <Col span={18}>
                      <div className="text-color-red mt-small ml-small">
                        <Icon type="exclamation-circle" className="mr-x-small" />
                        End time cannot be before Start time{' '}
                      </div>
                    </Col>
                  </Row>
                )}
                {this.state.isErrorAvailability && (
                  <Row>
                    <Col span={6}></Col>
                    <Col span={18}>
                      <div className="text-color-red-dark mt-small ml-small">
                        <Icon type="exclamation-circle" className="mr-x-small" />
                        The time selected is unavailable for this service.{' '}
                      </div>
                    </Col>
                  </Row>
                )}

                {(this.state.isAfterCustomerScheduledArchiveDate || selectedBookingItem.isRecurring) && (
                  <Row>
                    {this.state.isAfterCustomerScheduledArchiveDate ? (
                      <div className="text-color-red flex-row">
                        <div className="flex align-center justify-center">
                          <Icon type="warning" theme="filled" className="mr-12 text-size-large" />
                        </div>
                        <Text className="text-color-red">
                          The customer selected is scheduled to be archived on{' '}
                          <b>
                            {moment
                              .tz(selectedBookingItem.customerScheduleArchiveDate, selectedBookingItem.timezone)
                              .format('DD/MM/YYYY')}
                          </b>
                          . Bookings cannot be made on or past this date.
                        </Text>
                      </div>
                    ) : (
                      <Text>You can select options for changing other bookings in the next step</Text>
                    )}
                  </Row>
                )}
              </div>
            </div>
          )}
        {this.state.isLoading && <SpinningLoader size={100} message={'Loading'} />}
        {!this.state.isLoading &&
          this.state.isSuccess &&
          !this.state.isError &&
          !this.state.showUpdateSuccessWithRemovedMember &&
          !this.state.showUpdateSuccessWithRemovedMemberForMultiple && (
            <div className="">
              <div className="mb-large">
                <Paragraph>
                  The time has been successfully changed for the selected booking
                  {selectedBookingItem.isRecurring &&
                    this.state.selectedOption === EditRecurringMode.CurrentAll &&
                    ' and all following bookings'}
                  {selectedBookingItem.isRecurring &&
                    this.state.selectedOption === EditRecurringMode.CurrentNext &&
                    ` and the next ${this.state.numberOfBookings} booking${this.state.numberOfBookings !== 1 && 's'}`}
                  .
                </Paragraph>
                {!isInProgressOrCompleted &&
                  !selectedBookingItem.isRecurring &&
                  selectedBookingItem.workerUserId &&
                  !this.state.isRemovePendingShiftSlots && (
                    <Paragraph>
                      The assigned team member,{' '}
                      <b>
                        {selectedBookingItem.workerFirstName} {selectedBookingItem.workerLastName}
                      </b>
                      , has been removed from any conflicting shifts.
                    </Paragraph>
                  )}
              </div>
            </div>
          )}
        {!this.state.isLoading && this.state.isError && (
          <div className="anim-fade-in">
            <div className="pv-medium">
              <img src={ErrorSVG} alt="ERROR" style={{ width: '100%', height: '200px' }} />
            </div>
            <div className="text-align-center">
              <Paragraph>Oops something has gone wrong, please try again</Paragraph>
            </div>

            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Go Back to Booking
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
        {!this.state.isLoading && this.state.showAvailabilityErrorStep && (
          <div className="anim-fade-in">
            <Paragraph>
              As a result of the changes, bookings in this series will now occur on dates that are after the end date of
              this services availability.
            </Paragraph>
            <Paragraph>
              To resolve this issue you can extend the availability of this service or make it indefinitely available.
            </Paragraph>
            <HyperlinkButton onClick={this._goToService}>Go to service</HyperlinkButton>

            <ActionModalFooter>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._resetModal}>
                Go Back
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
        {!this.state.isLoading &&
          this.state.isRecurringOptions &&
          !this.state.showCustomerScheduleArchiveError &&
          !this.state.showUnavailableBookings &&
          !this.state.showTeamMemberScheduledRemove &&
          !isInProgressOrCompleted && (
            <>
              <div className="anim-slide-left">
                <Paragraph>
                  The booking you are editing is part of a recurring booking series. Please select one of the following
                  options for editing this bookings.
                </Paragraph>
              </div>
              <div>
                <div className="mb-small">
                  <Text weight="bold">Edit time for:</Text>
                </div>
                <Radio.Group value={this.state.selectedOption} onChange={this._onChangeOption} className="ml-medium">
                  <Radio
                    value={EditRecurringMode.Current}
                    className={`${this.state.selectedOption === EditRecurringMode.Current &&
                      'text-weight-bold'} mb-small `}
                  >
                    <div className="ml-medium inline-box inline-flex" style={{ whiteSpace: 'normal' }}>
                      This booking only.
                    </div>
                  </Radio>
                  <br />
                  <Radio
                    value={EditRecurringMode.CurrentAll}
                    className={`${this.state.selectedOption === EditRecurringMode.CurrentAll &&
                      'text-weight-bold'} mb-small `}
                  >
                    <div className="ml-medium inline-box inline-flex" style={{ whiteSpace: 'normal' }}>
                      This booking and all following bookings.
                    </div>
                  </Radio>
                  <br />
                  <Radio
                    value={EditRecurringMode.CurrentNext}
                    className={`${this.state.selectedOption === EditRecurringMode.CurrentNext &&
                      'text-weight-bold'} mb-small `}
                  >
                    <div
                      className="ml-medium inline-box inline-flex align-center"
                      style={{ whiteSpace: 'normal' }}
                      color={this.state.numberOfBookingError ? 'red' : null}
                    >
                      This booking and the next{' '}
                      <InputNumber
                        className="mh-x-small"
                        style={{ width: '50px' }}
                        min={1}
                        max={selectedBookingItem.numberOfBookingLeft}
                        value={this.state.numberOfBookings}
                        onChange={this._onChangeNumberOfBookings}
                      />{' '}
                      booking
                      {this.state.numberOfBookings !== 1 && 's'}.
                    </div>
                    <br />
                    {this.state.numberOfBookingError && (
                      <>
                        <Text size={'regular'} color="red" weight="regular" className="ml-x2-large">
                          Please select a number between 1 - {selectedBookingItem.numberOfBookingLeft}
                        </Text>
                        <br />
                      </>
                    )}
                    <Text size={'regular'} color="secondary" weight="regular" className="ml-x2-large">
                      (There {selectedBookingItem.numberOfBookingLeft !== 1 ? 'are' : 'is'}{' '}
                      {selectedBookingItem.numberOfBookingLeft} left in the recurring series after the currently
                      selected booking)
                    </Text>
                  </Radio>
                </Radio.Group>
              </div>
              <div className={'mt-large'}>
                <Row type={'flex'} justify={'end'}>
                  <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton
                    size="large"
                    loading={this.state.isVerifying}
                    onClick={() => this._applyRecurringOption(isInProgressOrCompleted)}
                  >
                    Continue
                  </PrimaryButton>
                </Row>
              </div>
            </>
          )}
        {!this.state.isLoading && this.state.isRecurringOptions && this.state.showCustomerScheduleArchiveError && (
          <>
            <div className="anim-slide-left">
              <Paragraph>
                The selected customer is scheduled to be archived on{' '}
                <b>
                  {moment
                    .tz(selectedBookingItem.customerScheduleArchiveDate, selectedBookingItem.timezone)
                    .format('DD/MM/YYYY')}
                </b>
                .
              </Paragraph>
              <Paragraph>
                As a result of the change you have selected, some of the bookings in this series will now occur after
                this date, thus the selected change has not been saved.
              </Paragraph>
            </div>
            <div className={'mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <PrimaryButton size="large" onClick={this._onCloseModal}>
                  Close
                </PrimaryButton>
              </Row>
            </div>
          </>
        )}
        {!this.state.isSuccess &&
          !this.state.isError &&
          !this.state.isRecurringOptions &&
          !this.state.isCustomerConflictList &&
          !this.state.showAvailabilityErrorStep &&
          !this.state.isWorkerConfirmedConflictList &&
          !this.state.isWorkerPendingConflictList &&
          !this.state.showUnavailableBookings &&
          !this.state.showTeamMemberScheduledRemove &&
          !this.state.showOutsideAvailabilityBooking &&
          !this.state.showOutsideAvailabilityWorkers && (
            <ActionModalFooter>
              <SecondaryButton
                size="large"
                onClick={this._onCloseModal}
                className="mr-medium"
                disabled={this.state.isLoading}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                size="large"
                onClick={async () => {
                  const result = await this.checkCurrentBookingEditDateTime();
                  if (result.canContinue) {
                    if (selectedBookingItem.isRecurring) {
                      if (selectedBookingItem.numberOfBookingLeft > 0 && !isInProgressOrCompleted) {
                        this._displayRecurringOptions();
                      } else {
                        this._applyRecurringOption(isInProgressOrCompleted);
                      }
                    } else {
                      if (result.result.workerScheduleRemoved && result.result.workerScheduleRemoved.length > 0) {
                        const data = result.result.workerScheduleRemoved[0];
                        this.setState({
                          title: 'Team member scheduled to be removed',
                          showTeamMemberScheduledRemove: true,
                          scheduledRemoveConflict: {
                            worker: { firstName: data.firstName, lastName: data.lastName },
                            removeDate: data.removedOn,
                            bookingDate: [data.bookings[0].startDateTime, data.bookings[0].endDateTime],
                          },
                        });
                      } else if (
                        result.result.workerConflicts &&
                        (!_.isEmpty(result.result.workerConflicts.pendingShiftConflicts) ||
                          !_.isEmpty(result.result.workerConflicts.confirmedShiftConflicts))
                      ) {
                        this.setState({
                          isVerifying: false,
                          workerPendingConflicts: result.result.workerConflicts.pendingShiftConflicts,
                          workerConfirmedConflicts: result.result.workerConflicts.confirmedShiftConflicts,
                          isRecurringOptions: false,
                          modalWidth: 'x-large',
                          isWorkerPendingConflictList: result.result.workerConflicts.pendingShiftConflicts.length > 0,
                          isWorkerConfirmedConflictList:
                            result.result.workerConflicts.confirmedShiftConflicts.length > 0,
                          title: 'Team member assigned to conflicting shift(s).',
                        });
                      } else if (!_.isEmpty(result.result.workerOutsideAvailability)) {
                        this.setState({
                          isVerifying: false,
                          isRecurringOptions: false,
                          showAvailabilityErrorStep: false,
                          title: `Booking outside team member's scheduled availability`,
                          showOutsideAvailabilityBooking: true,
                          outsideAvailabilityConflicts: result.result.workerOutsideAvailability[0],
                        });
                      } else {
                        this.setState({
                          isVerifying: false,
                          isRecurringOptions: false,
                          showAvailabilityErrorStep: false,
                        });
                        await this._onSubmitEdit();
                      }
                    }
                  }
                }}
                loading={this.state.isLoading}
                disabled={
                  this.state.isErrorAvailability ||
                  this.state.isAfterCustomerScheduledArchiveDate ||
                  this.state.everyDayMultiDayBookingTooLongError
                }
              >
                {selectedBookingItem.isRecurring ? 'Next' : 'Save'}
              </PrimaryButton>
            </ActionModalFooter>
          )}
        {this.state.isSuccess &&
          !this.state.showUpdateSuccessWithRemovedMember &&
          !this.state.showUpdateSuccessWithRemovedMember &&
          !this.state.showUpdateSuccessWithRemovedMemberForMultiple && (
            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
                Ok
              </PrimaryButton>
            </ActionModalFooter>
          )}
        {this.state.isCustomerConflictList && (
          <>
            <div className="anim-slide-left">
              <Text>
                As a result of the changes being made, several bookings in this recurring series now overlap. Please
                review and alter the changes being made and try again.
              </Text>
              <div className="mt-x-large">
                <Text size="large" weight="bold">
                  Overlapping bookings ({this.state.sameSeriesConflicts.length})
                </Text>
              </div>

              <Row className="text-weight-bold bordered-bottom border-standard-gray p-small mt-large">
                <Col span={8}>Current Date</Col>
                <Col span={8}>New date (Based on changes)</Col>
                <Col span={8}>Assigned worker</Col>
              </Row>
              <div
                style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '35vh' }}
                className="bordered-bottom border-standard-gray"
              >
                {(!this.state.sameSeriesConflicts || this.state.sameSeriesConflicts.length === 0) && (
                  <Row className="pv-medium">
                    <Text>No conflicts found.</Text>
                  </Row>
                )}
                {_.map(this.state.sameSeriesConflicts, (conflict) => (
                  <Row type="flex" align="middle" className="p-small border-secondary evenodd">
                    <Col span={8}>
                      {moment
                        .tz(conflict.currentStartDateTime, selectedBookingItem.timezone)
                        .startOf('day')
                        .isSame(moment.tz(conflict.currentEndDateTime, selectedBookingItem.timezone).startOf('day')) ? (
                        <Text>
                          <b>
                            {moment
                              .tz(conflict.currentStartDateTime, selectedBookingItem.timezone)
                              .format('DD/MM/YYYY')}
                          </b>
                          <br />
                          <Text>
                            {moment.tz(conflict.currentStartDateTime, selectedBookingItem.timezone).format('hh:mm a')} -{' '}
                            {moment.tz(conflict.currentEndDateTime, selectedBookingItem.timezone).format('hh:mm a')}
                          </Text>
                        </Text>
                      ) : (
                        <Text>
                          <b>
                            {moment
                              .tz(conflict.currentStartDateTime, selectedBookingItem.timezone)
                              .format('DD/MM/YYYY')}
                          </b>{' '}
                          {moment.tz(conflict.currentStartDateTime, selectedBookingItem.timezone).format('hh:mm a')}
                          <br />
                          <b>
                            {moment.tz(conflict.currentEndDateTime, selectedBookingItem.timezone).format('DD/MM/YYYY')}
                          </b>{' '}
                          {moment.tz(conflict.currentEndDateTime, selectedBookingItem.timezone).format('hh:mm a')}
                        </Text>
                      )}
                    </Col>

                    {/*  new date based on changes */}
                    <Col span={8}>
                      {moment
                        .tz(conflict.startDateTime, selectedBookingItem.timezone)
                        .startOf('day')
                        .isSame(moment.tz(conflict.endDateTime, selectedBookingItem.timezone).startOf('day')) ? (
                        <Text>
                          <b>{moment.tz(conflict.startDateTime, selectedBookingItem.timezone).format('DD/MM/YYYY')}</b>
                          <br />
                          <Text>
                            {moment.tz(conflict.startDateTime, selectedBookingItem.timezone).format('hh:mm a')} -{' '}
                            {moment.tz(conflict.endDateTime, selectedBookingItem.timezone).format('hh:mm a')}
                          </Text>
                        </Text>
                      ) : (
                        <Text>
                          <b>{moment.tz(conflict.startDateTime, selectedBookingItem.timezone).format('DD/MM/YYYY')}</b>{' '}
                          {moment.tz(conflict.startDateTime, selectedBookingItem.timezone).format('hh:mm a')}
                          <br />
                          <b>
                            {moment.tz(conflict.endDateTime, selectedBookingItem.timezone).format('DD/MM/YYYY')}
                          </b>{' '}
                          {moment.tz(conflict.endDateTime, selectedBookingItem.timezone).format('hh:mm a')}
                        </Text>
                      )}
                    </Col>

                    <Col
                      span={8}
                      title={conflict.shiftSlotStatus}
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {!conflict.supportWorkerId && <Text color={'red-dark'}>No worker assigned</Text>}
                      {conflict.supportWorkerId && (
                        <Text>
                          <Avatar className="mr-medium" size={'small'} src={conflict.attachmentUrl} />{' '}
                          {conflict.firstName} {conflict.lastName}
                        </Text>
                      )}
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
            <div className={'mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._onCancelConflicts}>
                  Go Back
                </PrimaryButton>
              </Row>
            </div>
          </>
        )}
        {this.state.isWorkerPendingConflictList && !selectedBookingItem.isRecurring && (
          <>
            <div className="mb-medium">
              <Paragraph>
                The team member you have selected is assigned to the following shift(s), that occur at the same time as
                the booking you are attempting to assign them to.
              </Paragraph>
              <Paragraph>
                As these shift(s) are still <b>pending team member confirmation</b>, assigning the team member to the
                selected booking will remove them from the conflicting shift(s) listed below.
              </Paragraph>
              <div className={'mt-medium'}>
                <SubTitle>Selected booking</SubTitle>
                {moment.tz(startDateTime, displayTimezone).format('DD MMMM YYYY')}
                <br />
                {moment.tz(startDateTime, displayTimezone).format('HH:mm A')} -{' '}
                {moment.tz(endDateTime, displayTimezone).format('HH:mm A')}
              </div>
            </div>
            <Row className="text-color-secondary text-uppercase pt-medium">
              <Col span={12} className="ph-medium">
                <SubTitle>Conflicting session/Booking</SubTitle>
              </Col>
              <Col span={12} className="ph-medium">
                <SubTitle>Team member status</SubTitle>
              </Col>
            </Row>
            <Divider className="mv-medium mb-none" />
            <div style={{ overflow: 'auto', maxHeight: '35vh' }} className={'mb-large'}>
              {_.map(workerPendingConflicts, (conflict) => (
                <Row className="pv-large evenodd" type={'flex'} align={'middle'}>
                  <Col span={12} className="ph-medium height-full">
                    <div>
                      <HyperlinkButton
                        color={'red-dark'}
                        weight={'bold'}
                        onClick={
                          conflict.conflictShift.serviceType === ServiceType.INDIVIDUAL
                            ? () => this._goToBooking(conflict.conflictShift.attendanceId)
                            : () =>
                                this._goToSession(
                                  conflict.conflictShift.serviceId,
                                  conflict.conflictShift.serviceDateTimeId,
                                )
                        }
                      >
                        {conflict.conflictShift.serviceName}
                      </HyperlinkButton>
                      <br />
                      <Text>
                        {moment.tz(conflict.conflictShift.startDateTime, displayTimezone).format('D MMMM YYYY')}
                        <br />
                        {moment.tz(conflict.conflictShift.startDateTime, displayTimezone).format('hh:mm A')} -{' '}
                        {moment.tz(conflict.conflictShift.endDateTime, displayTimezone).format('hh:mm A')}
                      </Text>
                    </div>
                  </Col>
                  <Col span={12} className="ph-medium height-full">
                    <ShiftSlotStatusTag status={ShiftSlotStatus.PENDING} textSize={'x-large'} />
                  </Col>
                </Row>
              ))}
            </div>
            <div className={'mb-small'}>
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => this._onContinuePending(true)}
                  size="large"
                  loading={this.state.isLoading}
                >
                  Continue & remove conflicts
                </PrimaryButton>

                <Popover
                  content={
                    <ActionMenu>
                      <ActionMenuItem
                        text="Continue without removing conflicts"
                        onClick={() => this._onContinuePending(false)}
                      />
                    </ActionMenu>
                  }
                  position="bottom-left"
                  usePortal={false}
                >
                  <IconButton className="rounded-right ml-x2-small" icon="down" size="large" />
                </Popover>
              </Row>
            </div>
          </>
        )}
        {this.state.isWorkerConfirmedConflictList && !selectedBookingItem.isRecurring && (
          <>
            <div className="mb-medium">
              <Paragraph>
                The selected team member cannot be confirmed for this shift as they are already confirmed for the
                following shift that occurs at a conflicting time.
              </Paragraph>
              <Paragraph>Opting to continue will remove the team member from the selected booking.</Paragraph>
              <div className={'mt-medium'}>
                <SubTitle>Selected booking</SubTitle>
                {moment.tz(startDateTime, displayTimezone).format('DD MMMM YYYY')}
                <br />
                {moment.tz(startDateTime, displayTimezone).format('HH:mm A')} -{' '}
                {moment.tz(endDateTime, displayTimezone).format('HH:mm A')}
              </div>
            </div>
            <Row className="text-color-secondary text-uppercase pt-medium">
              <Col span={12} className="ph-medium">
                <SubTitle>Conflicting session/Booking</SubTitle>
              </Col>
              <Col span={12} className="ph-medium">
                <SubTitle>Team member status</SubTitle>
              </Col>
            </Row>
            <Divider className="mv-medium mb-none" />
            <div style={{ overflow: 'auto', maxHeight: '35vh' }} className={'mb-large'}>
              {_.map(workerConfirmedConflicts, (conflict) => (
                <Row className="pv-large evenodd" type={'flex'} align={'middle'}>
                  <Col span={12} className="ph-medium height-full">
                    <div>
                      <HyperlinkButton
                        color={'red-dark'}
                        weight={'bold'}
                        onClick={
                          conflict.conflictShift.serviceType === ServiceType.INDIVIDUAL
                            ? () => this._goToBooking(conflict.conflictShift.attendanceId)
                            : () =>
                                this._goToSession(
                                  conflict.conflictShift.serviceId,
                                  conflict.conflictShift.serviceDateTimeId,
                                )
                        }
                      >
                        {conflict.conflictShift.serviceName}
                      </HyperlinkButton>
                      <br />
                      <Text>
                        {moment.tz(conflict.conflictShift.startDateTime, displayTimezone).format('D MMMM YYYY')}
                        <br />
                        {moment.tz(conflict.conflictShift.startDateTime, displayTimezone).format('hh:mm A')} -{' '}
                        {moment.tz(conflict.conflictShift.endDateTime, displayTimezone).format('hh:mm A')}
                      </Text>
                    </div>
                  </Col>
                  <Col span={12} className="ph-medium height-full">
                    <ShiftSlotStatusTag status={ShiftSlotStatus.CONFIRMED} textSize={'x-large'} />
                  </Col>
                </Row>
              ))}
            </div>
            <div className={'mb-small'}>
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton onClick={() => this._onContinueConfirmed()} size="large" loading={this.state.isLoading}>
                  Remove from booking
                </PrimaryButton>
              </Row>
            </div>
          </>
        )}
        {this.state.isWorkerConfirmedConflictList && selectedBookingItem.isRecurring && (
          <>
            <div className="mb-medium">
              <Paragraph>
                Based on the changes being made, the following team members are unavailable for the bookings in this
                recurring series due to shift conflicts.
              </Paragraph>
              <Paragraph>
                If you elect to continue the team member will be removed from{' '}
                <b>the bookings in this recurring series</b> that are listed below.
              </Paragraph>
            </div>
            <Row className="text-color-secondary text-uppercase pt-medium">
              <Col span={8} className="ph-medium">
                <SubTitle>Session in selected series</SubTitle>
              </Col>
              <Col span={8} className="ph-medium">
                <SubTitle>Conflicting session/booking</SubTitle>
              </Col>
              <Col span={8} className="ph-medium">
                <SubTitle>Team member</SubTitle>
              </Col>
            </Row>
            <Divider className="mv-medium mb-none" />
            <div style={{ overflow: 'auto', maxHeight: '35vh' }} className={'mb-large'}>
              {_.map(workerConfirmedConflicts, (conflict) => (
                <ConflictResult
                  conflict={conflict}
                  timezone={displayTimezone}
                  displayTeamMember={true}
                  goToBooking={this._goToBooking}
                  goToSession={this._goToSession}
                />
              ))}
            </div>
            <div className={'mb-small'}>
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._onContinueConfirmed}>
                  Continue
                </PrimaryButton>
              </Row>
            </div>
          </>
        )}
        {this.state.isWorkerPendingConflictList && selectedBookingItem.isRecurring && (
          <>
            <div className="mb-medium">
              <Paragraph>
                Based on the changes being made, the following team members have pending shifts that occur at the same
                time as the updated booking time for bookings in this series.
              </Paragraph>
              <Paragraph>
                You can choose to continue and remove the conflicting shifts listed below or continue without removing
                the conflicts.
              </Paragraph>
            </div>
            <Row className="text-color-secondary text-uppercase pt-medium">
              <Col span={8} className="ph-medium">
                <SubTitle>Session in selected series</SubTitle>
              </Col>
              <Col span={8} className="ph-medium">
                <SubTitle>Conflicting session/booking</SubTitle>
              </Col>
              <Col span={8} className="ph-medium">
                <SubTitle>Team member</SubTitle>
              </Col>
            </Row>
            <Divider className="mv-medium mb-none" />
            <div style={{ overflow: 'auto', maxHeight: '35vh' }} className={'mb-large'}>
              {_.map(workerPendingConflicts, (conflict) => (
                <ConflictResult
                  conflict={conflict}
                  timezone={displayTimezone}
                  displayTeamMember={true}
                  goToBooking={this._goToBooking}
                  goToSession={this._goToSession}
                />
              ))}
            </div>
            <div className={'mb-small'}>
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  onClick={() => this._onContinuePending(true)}
                  size="large"
                  loading={this.state.isLoading}
                >
                  Continue & remove conflicts
                </PrimaryButton>

                <Popover
                  content={
                    <ActionMenu>
                      <ActionMenuItem
                        text="Continue without removing conflicts"
                        onClick={() => this._onContinuePending(false)}
                      />
                    </ActionMenu>
                  }
                  position="bottom-left"
                  usePortal={false}
                >
                  <IconButton className="rounded-right ml-x2-small" icon="down" size="large" />
                </Popover>
              </Row>
            </div>
          </>
        )}
        {this.state.isOverlapConflictList && (
          <>
            <div className="anim-slide-left">
              <Paragraph>
                As a result of the changes being made, several bookings in this recurring series now overlap.
                <br />
                Please review and alter the changes being made and try again.
              </Paragraph>
              <Row>
                <Col span={8}>
                  <Text weight={'bold'} size="x-large">
                    {overlapConflicts ? overlapConflicts.length : 0} overlapping booking
                    {overlapConflicts ? (overlapConflicts.length !== 1 ? 's' : '') : 's'}
                  </Text>
                </Col>
                <Col span={16} className="text-align-right">
                  <TimezoneIndicator timezone={selectedBookingItem.timezone} />
                </Col>
              </Row>
              <Row className="text-weight-bold bordered-bottom border-standard-gray p-small mt-large">
                <Col span={5}>Current date</Col>
                <Col span={8}>New date (Based on changes)</Col>
                <Col span={11}>Assigned Team member</Col>
              </Row>
              <div
                style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '35vh' }}
                className="bordered-bottom border-standard-gray"
              >
                {(!overlapConflicts || overlapConflicts.length === 0) && (
                  <Row className="pv-medium">
                    <Text>No conflicts found.</Text>
                  </Row>
                )}
                {_.map(overlapConflicts, (conflict) => (
                  <Row type="flex" align="middle" className="p-small border-secondary evenodd">
                    <Col span={6}>
                      {moment
                        .tz(conflict.startDateTime, selectedBookingItem.timezone)
                        .startOf('day')
                        .isSame(moment.tz(conflict.endDateTime, selectedBookingItem.timezone).startOf('day')) ? (
                        <text>
                          <b>{moment.tz(conflict.startDateTime, selectedBookingItem.timezone).format('DD/MM/YYYY')}</b>
                          <br />
                          <Text>
                            {moment.tz(conflict.startDateTime, selectedBookingItem.timezone).format('hh:mm a')} -{' '}
                            {moment.tz(conflict.endDateTime, selectedBookingItem.timezone).format('hh:mm a')}
                          </Text>
                        </text>
                      ) : (
                        <text>
                          <b>{moment.tz(conflict.startDateTime, selectedBookingItem.timezone).format('DD/MM/YYYY')}</b>{' '}
                          {moment.tz(conflict.startDateTime, selectedBookingItem.timezone).format('hh:mm a')}
                          <br />
                          <b>
                            {moment.tz(conflict.endDateTime, selectedBookingItem.timezone).format('DD/MM/YYYY')}
                          </b>{' '}
                          {moment.tz(conflict.endDateTime, selectedBookingItem.timezone).format('hh:mm a')}
                        </text>
                      )}
                    </Col>
                    <Col span={6} title={conflict.serviceName} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {moment
                        .tz(conflict.startDateTime, selectedBookingItem.timezone)
                        .startOf('day')
                        .isSame(moment.tz(conflict.endDateTime, selectedBookingItem.timezone).startOf('day')) ? (
                        <text>
                          <b>{moment.tz(conflict.startDateTime, selectedBookingItem.timezone).format('DD/MM/YYYY')}</b>
                          <br />
                          <Text>
                            {moment.tz(conflict.startDateTime, selectedBookingItem.timezone).format('hh:mm a')} -{' '}
                            {moment.tz(conflict.endDateTime, selectedBookingItem.timezone).format('hh:mm a')}
                          </Text>
                        </text>
                      ) : (
                        <text>
                          <b>{moment.tz(conflict.startDateTime, selectedBookingItem.timezone).format('DD/MM/YYYY')}</b>{' '}
                          {moment.tz(conflict.startDateTime, selectedBookingItem.timezone).format('hh:mm a')}
                          <br />
                          <b>
                            {moment.tz(conflict.endDateTime, selectedBookingItem.timezone).format('DD/MM/YYYY')}
                          </b>{' '}
                          {moment.tz(conflict.endDateTime, selectedBookingItem.timezone).format('hh:mm a')}
                        </text>
                      )}
                    </Col>
                    <Col
                      span={12}
                      title={conflict.shiftSlotStatus}
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {conflict.shiftSlotStatus === ShiftSlotStatus.UNASSIGNED ? (
                        <Text color={'red-dark'}>No worker assigned</Text>
                      ) : conflict.shiftSlotStatus === ShiftSlotStatus.PUBLISHING ? (
                        <Text color={'violet-light'}>
                          <Icon type="wifi" className="mr-x-small" />
                          Published
                        </Text>
                      ) : (
                        <>
                          <Avatar
                            className="mr-medium"
                            shape={'square'}
                            size={'small'}
                            src={conflict.workerAttachmentUrl}
                          />{' '}
                          {conflict.workerFirstName} {conflict.workerLastName}
                        </>
                      )}
                    </Col>
                  </Row>
                ))}
              </div>
            </div>
            <div className={'mt-large'}>
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._resetModal}>
                  Go Back
                </PrimaryButton>
              </Row>
            </div>
          </>
        )}
        {this.state.showUnavailableBookings &&
          !this.state.showUpdateSuccessWithRemovedMemberForMultiple &&
          !this.state.isLoading && (
            <>
              <Paragraph>
                The selected team member is unavailable for some booking you are attempting to assign them to. The team
                member will not be assigned to the following
              </Paragraph>
              <div
                className="p-medium bordered border-standard-gray rounded-big bg-quaternary line-height-135 flex-column"
                style={{ maxHeight: '300px', overflow: 'auto' }}
              >
                <div className="flex-row">
                  <div className="bg-red-lightest text-color-red text-weight-bold">
                    TEAM MEMBER SCHEDULED TO LEAVE COMPANY
                  </div>
                  <div className="ml-large flex-row">
                    <Avatar icon="user" size={25} shape="square" src={scheduledRemoveConflict.worker.attachmentUrl} />
                    <b className="ml-small">
                      <Text className="text-color-tertiary text-size-regular">
                        {`${scheduledRemoveConflict.worker.firstName} ${scheduledRemoveConflict.worker.lastName}`}
                      </Text>
                    </b>
                  </div>
                </div>
                <Paragraph>
                  <Text weight="bold">
                    {`${scheduledRemoveConflict.worker.firstName} ${scheduledRemoveConflict.worker.lastName}`}
                  </Text>{' '}
                  is scheduled to leave the company on{' '}
                  {moment.tz(scheduledRemoveConflict.removeDate, this.props.displayTimezone).format('Do of MMMM')}
                  and will not be assigned to the following{' '}
                  <Text weight="bold">
                    {scheduledRemoveConflict.bookings.length} booking
                    {scheduledRemoveConflict.bookings.length > 1 ? 's' : ''}
                  </Text>
                </Paragraph>
                <HyperlinkButton onClick={this._showConflictBookings}>
                  {this.state.showConflictBookings ? 'Hide bookings...' : 'Show bookings...'}
                </HyperlinkButton>
                {this.state.showConflictBookings && (
                  <div className="mt-large">
                    {scheduledRemoveConflict.bookings.map((session) => (
                      <Paragraph key={session.serviceDateTimeId}>
                        <Text weight="bold">
                          {moment.tz(session.startDateTime, this.props.displayTimezone).format('DD MMMM YYYY')}
                        </Text>
                        <Text className="ml-small">
                          {moment.tz(session.startDateTime, this.props.displayTimezone).format('hh:mm A')} -{' '}
                          {moment.tz(session.endDateTime, this.props.displayTimezone).format('hh:mm A')}
                        </Text>
                      </Paragraph>
                    ))}
                  </div>
                )}
              </div>
              <div className="mt-x3-large">
                <Row type={'flex'} justify={'end'}>
                  <SecondaryButton size="large" onClick={this._onCloseModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size="large" className="ml-small" onClick={this._onUpdateWithRemoveMemberForMultiple}>
                    Continue
                  </PrimaryButton>
                </Row>
              </div>
            </>
          )}
        {this.state.showTeamMemberScheduledRemove &&
          !this.state.showUpdateSuccessWithRemovedMember &&
          !this.state.isLoading && (
            <>
              <Paragraph>
                The selected team member,{' '}
                <Text weight="bold">
                  {this.state.scheduledRemoveConflict.worker.firstName +
                    ' ' +
                    this.state.scheduledRemoveConflict.worker.lastName}
                </Text>
                , is scheduled to be remove on or before the scheduled booking time
              </Paragraph>
              <Paragraph className="mt-small">
                As a result{' '}
                {this.state.scheduledRemoveConflict.worker.firstName +
                  ' ' +
                  this.state.scheduledRemoveConflict.worker.lastName}{' '}
                will not be assigned to this booking
              </Paragraph>
              <div className="mb-x2-small">
                <FieldLabel text={'TEAM MEMBER SCHEDULE TO BE REMOVE ON...'} />
              </div>
              <Paragraph>
                {moment
                  .tz(this.state.scheduledRemoveConflict.removeDate, this.props.displayTimezone)
                  .format('DD MMMM YYYY')}
              </Paragraph>

              <div className="mb-x2-small">
                <FieldLabel text={'BOOKING DATE'} />
              </div>
              <div className="flex-column">
                <Text>
                  {moment
                    .tz(this.state.scheduledRemoveConflict.bookingDate[0], this.props.displayTimezone)
                    .format('DD MMMM YYYY')}
                </Text>
                <Text>
                  {moment
                    .tz(this.state.scheduledRemoveConflict.bookingDate[0], this.props.displayTimezone)
                    .format('hh:mm A')}{' '}
                  -{' '}
                  {moment
                    .tz(this.state.scheduledRemoveConflict.bookingDate[1], this.props.displayTimezone)
                    .format('hh:mm A')}
                </Text>
              </div>
              <div className="mt-x3-large">
                <Row type={'flex'} justify={'end'}>
                  <SecondaryButton size="large" onClick={this._onCloseModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size="large" className="ml-small" onClick={this._onUpdateWithRemovedMember}>
                    Confirm
                  </PrimaryButton>
                </Row>
              </div>
            </>
          )}
        {this.state.showUpdateSuccessWithRemovedMember && (
          <>
            <Paragraph>You have successfully changed the scheduled time for this booking.</Paragraph>
            <Paragraph>
              The assigned team member,{' '}
              <Text weight="bold">
                {this.state.scheduledRemoveConflict.worker.firstName +
                  ' ' +
                  this.state.scheduledRemoveConflict.worker.lastName}
              </Text>
              , has been removed from booking.
            </Paragraph>
            <div className="mt-x3-large">
              <Row type={'flex'} justify={'end'}>
                <PrimaryButton size="large" className="ml-small" onClick={this._onCloseModal}>
                  Close
                </PrimaryButton>
              </Row>
            </div>
          </>
        )}
        {this.state.showUpdateSuccessWithRemovedMemberForMultiple && (
          <>
            <Paragraph>You have successfully changed the scheduled time for this booking.</Paragraph>
            <div className="mt-x3-large">
              <Row type={'flex'} justify={'end'}>
                <PrimaryButton size="large" className="ml-small" onClick={this._onCloseModal}>
                  Close
                </PrimaryButton>
              </Row>
            </div>
          </>
        )}
        {this.state.showOutsideAvailabilityBooking && !this.state.isLoading && !this.state.isSuccess && (
          <>
            <Paragraph>
              As a result of the changes being made, the booking is now outside of the team member&#39;s scheduled
              availability.
            </Paragraph>
            <Paragraph>You can choose whether you want to keep or remove the team member from this booking.</Paragraph>
            <div className="mb-x2-small">
              <FieldLabel text="NEW BOOKING DATE" />
            </div>
            <div className="flex-column">
              {_.map(this.state.outsideAvailabilityConflicts.bookings, (booking) => (
                <>
                  <Text>{moment.tz(booking.startDateTime, this.props.displayTimezone).format('DD MMMM YYYY')}</Text>
                  <Text>
                    {moment.tz(booking.startDateTime, this.props.displayTimezone).format('hh:mm A')} -{' '}
                    {moment.tz(booking.endDateTime, this.props.displayTimezone).format('hh:mm A')}
                  </Text>
                </>
              ))}
            </div>
            <div className="mt-x3-large">
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton size="large" onClick={this._onCloseModal} disabled={this.state.isLoading}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  className="ml-small"
                  onClick={() => this._submitOutsideConflict(true)}
                  loading={this.state.isLoading}
                >
                  Continue and remove team member
                </PrimaryButton>
                <Popover
                  content={
                    <ActionMenu>
                      <ActionMenuItem text="Keep team member" onClick={() => this._submitOutsideConflict(false)} />
                    </ActionMenu>
                  }
                  position="bottom-left"
                  usePortal={false}
                >
                  <IconButton className="rounded-right ml-x2-small" icon="down" size="large" />
                </Popover>
              </Row>
            </div>
          </>
        )}
        {this.state.showOutsideAvailabilityWorkers && !this.state.isLoading && !this.state.isSuccess && (
          <>
            <Paragraph>
              The new session time conflicts with the team member’s <Text weight="bold">General availability</Text>.
              Please select which shifts (if any) you wish to keep for the team member or continue without selecting
              any.
            </Paragraph>
            <div className="bg-quaternary pv-x-large ph-12 mt-x-large">
              {_.map(this.state.outsideAvailabilityConflicts, (worker) => (
                <AvailabilityConflictWorker
                  worker={worker}
                  timezone={this.props.displayTimezone}
                  onSelectShifts={(shifts) => this._onChangeShiftsToKeep(shifts, worker)}
                  type="SHIFT"
                />
              ))}
            </div>
            <div className="mt-x3-large flex justify-end">
              <SecondaryButton size="large" onClick={this._onCloseModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" className="ml-small" onClick={() => this._submitOutsideConflict(false)}>
                Done
              </PrimaryButton>
            </div>
          </>
        )}
        {/* {this.state.isError && (
          <ActionModalFooter>
            <GhostButton size="large" onClick={this._onSelectTimeModal} className="mr-medium">
              Choose a different time
            </GhostButton>
            <PrimaryButton size="large" onClick={this._onSubmitRemoveAndEdit} loading={this.state.isLoading}>
              Remove Team Member and Save
            </PrimaryButton>
          </ActionModalFooter>
        )} */}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBookingItem: state.bookingsStore.selectedBookingItem,
  recurringBookingList: state.bookingsStore.recurringBookingList,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateBookingDates: dispatch.bookingsStore.doUpdateBookingDates,
  doUpdateBookingDatesAndRemoveWorker: dispatch.bookingsStore.doUpdateBookingDatesAndRemoveWorker,
  doCheckEditTimes: dispatch.bookingsStore.doCheckEditTimes,
  doCheckServiceAgreementDate: dispatch.bookingsStore.doCheckServiceAgreementDate,
});

export default connect(
  mapState,
  mapDispatch,
)(BookingEditBookingDatesActionModel);
