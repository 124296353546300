import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, Marker, withScriptjs } from 'react-google-maps';
import globalConfig from 'variables/global-config';

interface IMapProps {
  lattitude: any;
  longitude: any;
}

const mapOptions = {
  minZoom: 4,
  maxZoom: 18
};

class Map extends Component<IMapProps, any> {
  render() {
    const GoogleMapConst = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          options={mapOptions}
          center={{ lat: this.props.lattitude, lng: this.props.longitude }}
          defaultZoom={14}
        >
          <Marker position={{ lat: this.props.lattitude, lng: this.props.longitude }} />
        </GoogleMap>
      ))
    );
    return (
      <div>
        <GoogleMapConst
          loadingElement={<div style={{ height: `200px` }} />}
          containerElement={<div style={{ height: `200px` }} />}
          mapElement={<div style={{ height: `200px` }} />}
          googleMapURL={
            'https://maps.googleapis.com/maps/api/js?key=' + globalConfig.googleMapApiKey + '&libraries=places'
          }
        />
      </div>
    );
  }
}
export default Map;
