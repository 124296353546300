import React, { Component } from 'react';
import { DatePicker, Form, Radio } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, state } from 'stores/rematch/root-store';
import moment from 'moment';
import { ServicePublishStatus } from 'utilities/enum-utils';

interface IPublishStepPanelProps extends FormComponentProps {
  onPreviousStep: (stepData?: any) => void;
  onNextStep: (stepData?: any) => void;
  setNewService: typeof dispatch.servicesStore.setNewService;
  newService: typeof state.servicesStore.newService;
}

interface IPublishStepPanelState {
  publishType: string;
}

class PublishStepPanel extends Component<IPublishStepPanelProps, IPublishStepPanelState> {
  state = {
    publishType: this.props.newService.isPublicMarketplace
      ? 'PUBLISHPUBLIC'
      : this.props.newService.status === ServicePublishStatus.DRAFT
      ? ServicePublishStatus.DRAFT
      : 'PUBLISHPRIVATE'
  };

  private _onChangePublishType = (newType) => {
    this.setState({ publishType: newType });
  };

  private _onNextStep = async () => {
    const { form, newService, setNewService, onNextStep } = this.props;

    const servicePublishDate = form.getFieldValue('publishDate');
    const isPublicMarketplace = this.state.publishType === 'PUBLISHPUBLIC';
    const status =
      this.state.publishType === ServicePublishStatus.DRAFT
        ? ServicePublishStatus.DRAFT
        : ServicePublishStatus.PUBLISHED;

    await setNewService({
      ...newService,
      servicePublishDate,
      isPublicMarketplace,
      status
    });
    onNextStep();
  };

  render() {
    const { form, newService, onPreviousStep } = this.props;
    const { getFieldDecorator } = form;
    const { publishType } = this.state;

    return (
      <div className="anim-slide-left mb-x-large">
        <Title level={2} weight="bolder" className="line-height-100">
          Publish Service
        </Title>

        <Paragraph>
          Almost there! The last step before publishing your service is to choose when and where you want it to be seen.
        </Paragraph>

        <div>
          <SubTitle>Publish Date</SubTitle>
          <Text size="regular" color={'secondary'}>
            Customers will be able to view and book this service from this date.
          </Text>
          <Form.Item className={'mt-small'}>
            {getFieldDecorator('publishDate', {
              initialValue: newService.servicePublishDate ? newService.servicePublishDate : moment(),
              rules: [
                {
                  required: true,
                  message: 'Please enter a publish date.'
                }
              ]
            })(
              <DatePicker
                size="large"
                format={'ddd, D MMM YYYY'}
                allowClear={false}
                disabledDate={(current) => {
                  return current < moment().startOf('day');
                }}
              />
            )}
          </Form.Item>
        </div>

        <Radio.Group className="width-4/5" value={publishType}>
          <div
            className="mt-x-large pv-medium ph-large bordered rounded shadow-box cursor-pointer hover-bg-tertiary flex-row align-center flex-grow"
            onClick={() => this._onChangePublishType('PUBLISHPRIVATE')}
          >
            <div style={{ width: '40px' }}>
              <Radio value="PUBLISHPRIVATE" />
            </div>
            <div className="width-full">
              <Title level={4}>Publish to your customers.</Title>
              <Text>Only your current customers will be able to view and book this service.</Text>
            </div>
          </div>

          {/* <div
            className="mt-large mb-large pv-medium ph-large bordered rounded shadow-box cursor-pointer hover-bg-tertiary flex-row align-center flex-grow"
            onClick={() => this._onChangePublishType('PUBLISHPUBLIC')}
          >
            <div style={{ width: '40px' }}>
              <Radio value="PUBLISHPUBLIC" />
            </div>
            <div className="width-full">
              <Title level={4}>Publish to marketplace.</Title>
              <Text>Anyone on the GoodHuman marketplace can view and book this service.</Text>
            </div>
          </div> */}

          <div
            className="mt-large mb-large pv-medium ph-large bordered rounded shadow-box cursor-pointer hover-bg-tertiary flex-row align-center flex-grow"
            onClick={() => this._onChangePublishType(ServicePublishStatus.DRAFT)}
          >
            <div style={{ width: '40px' }}>
              <Radio value="DRAFT" />
            </div>
            <div className="width-full">
              <Title level={4}>Save as a Draft.</Title>
              <Text>Save this service without publishing it.</Text>
            </div>
          </div>
        </Radio.Group>

        <div className="flex-row align-center mv-x2-large">
          <SecondaryButton size="large" onClick={onPreviousStep} icon="left" className="mr-large">
            Previous
          </SecondaryButton>

          <PrimaryButton size="large" onClick={this._onNextStep} icon="right" iconPosition="right">
            Next
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

export default Form.create<IPublishStepPanelProps>()(PublishStepPanel);
