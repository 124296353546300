import React, { Component } from 'react';
import { SubTitle, Text, Title } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { Avatar, Empty, Skeleton } from 'antd';
import * as H from 'history';
import _ from 'lodash';
import '../css/plan-management-listing.css';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import { IPMInvoice } from 'src/interfaces/plan-management-interfaces';
import moment from 'moment-timezone';
import CommonUtils from 'utilities/common-utils';
import AddEditInvoiceDetailsModal from 'common-components/plan-management/AddEditInvoiceDetailsModal';
import ApprovalStatusTag from 'common-components/tags/ApprovalStatusTag';
import ClaimStatusTag from 'common-components/tags/ClaimStatusTag';
import SupplierStatusTag from 'common-components/tags/SupplierStatusTag';
import StatisticDisplayCard from 'common-components/cards/StatisticDisplayCard';
import { FilterSection } from 'common-components/filter';
import {
  FilterType,
  PlanManagementApprovalStatus,
  PlanManagementClaimStatus,
  PlanManagementPaymentStatus
} from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';

interface IPlanManagementInvoiceOverviewPanelProps {
  setPlanManagementInvoiceFilters: typeof dispatch.planManagementStore.setPlanManagementInvoiceFilters;
  doFetchPlanManagementInvoices: typeof dispatch.planManagementStore.doFetchPlanManagementInvoices;
  doFetchPlanManagementInvoiceDetails: typeof dispatch.planManagementStore.doFetchPlanManagementInvoiceDetails;
  doFetchPlanManagementInvoiceStatistics: typeof dispatch.planManagementStore.doFetchPlanManagementInvoiceStatistics;
  portalUser: typeof state.authStore.portalUser;
  planManagementInvoices: typeof state.planManagementStore.planManagementInvoices;
  planManagementInvoiceFilters: typeof state.planManagementStore.planManagementInvoiceFilters;
  planManagementInvoiceStatistics: typeof state.planManagementStore.planManagementInvoiceStatistics;
  history: H.History;
}

interface IPlanManagementInvoiceOverviewPanelState {
  isSearching: boolean;
  isLoading: boolean;
  isLoadingMore: boolean;
  isAddModalOpen: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  isEdit: boolean;
  selectedInvoice: IPMInvoice;
  selectedFilterConfig: string;
}

const availableFilters = [
  FilterType.DATE_RANGE,
  FilterType.PROVIDER,
  FilterType.CUSTOMER,
  FilterType.SUPPLIER_PAYMENT_STATUS,
  FilterType.APPROVAL_STATUS,
  FilterType.CLAIM_STATUS
];

const INVOICELIST_FILTERCONFIGS = {
  DEFAULT: {
    key: 'DEFAULT',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
        selectionLabel: CommonUtils.getFilterText(FilterType.DATE_RANGE, [
          moment().startOf('isoWeek'),
          moment().endOf('isoWeek')
        ])
      },
      {
        filter: FilterType.PROVIDER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.PROVIDER).fullSelectionName
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      }
    ]
  },
  APPROVAL: {
    key: 'APPROVAL',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName
      },
      {
        filter: FilterType.PROVIDER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.PROVIDER).fullSelectionName
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      },
      {
        filter: FilterType.APPROVAL_STATUS,
        values: [
          PlanManagementApprovalStatus.NOT_SENT,
          PlanManagementApprovalStatus.SENT,
          PlanManagementApprovalStatus.REJECTED
        ],
        selectionLabel: '3 status'
      },
      {
        filter: FilterType.SUPPLIER_PAYMENT_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.SUPPLIER_PAYMENT_STATUS).fullSelectionName
      },
      {
        filter: FilterType.CLAIM_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CLAIM_STATUS).fullSelectionName
      }
    ]
  },
  CLAIM: {
    key: 'CLAIM',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName
      },
      {
        filter: FilterType.PROVIDER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.PROVIDER).fullSelectionName
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      },
      {
        filter: FilterType.CLAIM_STATUS,
        values: [
          PlanManagementClaimStatus.UNCLAIMED,
          PlanManagementClaimStatus.SENT_TO_PAYMENTS,
          PlanManagementClaimStatus.REJECTED
        ],
        selectionLabel: '3 status'
      },
      {
        filter: FilterType.APPROVAL_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.APPROVAL_STATUS).fullSelectionName
      },
      {
        filter: FilterType.SUPPLIER_PAYMENT_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.SUPPLIER_PAYMENT_STATUS).fullSelectionName
      }
    ]
  },
  PAYMENT: {
    key: 'PAYMENT',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName
      },
      {
        filter: FilterType.PROVIDER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.PROVIDER).fullSelectionName
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      },
      {
        filter: FilterType.SUPPLIER_PAYMENT_STATUS,
        values: [PlanManagementPaymentStatus.UNPAID, PlanManagementPaymentStatus.FAILED],
        selectionLabel: '2 status'
      },
      {
        filter: FilterType.APPROVAL_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.APPROVAL_STATUS).fullSelectionName
      }
    ]
  }
};

const InvoiceEmptyState = () => (
  <tr>
    <td colSpan={8} className="text-align-center mb-x-large">
      <div className="">
        <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
      <Text size="x2-large" color="secondary" weight="bold">
        No Invoices found.
      </Text>{' '}
      <br /> <br />
      <Text color="secondary">All invoices under this filter will appear here. </Text>
      <Text color="secondary">Try adjusting your filter.</Text>
    </td>
  </tr>
);

class PlanManagementInvoiceOverviewPanel extends Component<
  IPlanManagementInvoiceOverviewPanelProps,
  IPlanManagementInvoiceOverviewPanelState
> {
  state = {
    isSearching: false,
    isLoading: false,
    isLoadingMore: false,
    isAddModalOpen: false,
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    isEdit: false,
    selectedInvoice: null,
    selectedFilterConfig: 'DEFAULT'
  };

  private _applyFilter = async (refreshList = false) => {
    this.setState({
      page: 1,
      isLoading: true,
      pageTimestamp: refreshList ? new Date() : this.state.pageTimestamp
    });
    const requestFilter = await this._formatFilterQuery();
    await this.props.doFetchPlanManagementInvoiceStatistics({});
    await this.props.doFetchPlanManagementInvoices({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });
    this.setState({ isLoading: false });
  };

  private _formatFilterQuery = () => {
    const requestFilter: any = {};
    _.forEach(this.props.planManagementInvoiceFilters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'startDate':
            requestFilter.invoiceIssuedStartDate = filter.values[0].toDate();
            requestFilter.invoiceIssuedEndDate = filter.values[1].toDate();
            break;
          case 'planManagementSupplierIds':
            requestFilter.planManagementSupplierIds = filter.values;
            break;
          case 'customerUserIds':
            requestFilter.customerUserIds = _.map(filter.values, (customer) => {
              return customer.value;
            });
            break;
          case 'supplierPaymentStatus':
            requestFilter.supplierPaymentStatus = filter.values;
            break;
          case 'approvalStatus':
            requestFilter.approvalStatus = filter.values;
            break;
          case 'claimStatus':
            requestFilter.claimStatus = filter.values;
            break;
        }
      }
    });
    return requestFilter;
  };

  private _fetchMorePlanManagementInvoices = async () => {
    const { doFetchPlanManagementInvoices } = this.props;
    await this.setState({ page: this.state.page + 1, isLoadingMore: true });
    const requestFilter = this._formatFilterQuery();
    await doFetchPlanManagementInvoices({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });
    this.setState({ isLoadingMore: false });
  };

  private _closeAddInvoiceModal = async (refreshListing = false) => {
    this.setState({ isAddModalOpen: false });
    if (refreshListing) await this._applyFilter(true);
  };

  private _openAddInvoiceModal = () => {
    this.setState({ isEdit: false, isAddModalOpen: true, selectedInvoice: null });
  };

  private _goToInvoiceDetailView = (planManagementInvoiceId) => {
    this.props.history.push(`/plan-management/invoice/details/${planManagementInvoiceId}`);
  };

  private _applyFixedFilters = async (type) => {
    await this.props.setPlanManagementInvoiceFilters(INVOICELIST_FILTERCONFIGS[type].filters);
    await this._applyFilter();
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setPlanManagementInvoiceFilters(filters);
  };

  private _goToCustomer = (e, customerUserId) => {
    const { history } = this.props;
    e.stopPropagation();
    e.preventDefault();
    history.push(`/customer/details/${customerUserId}`);
  };

  private _goToSupplier = (e, planManagementSupplierId) => {
    const { history } = this.props;
    e.stopPropagation();
    e.preventDefault();
    history.push(`/plan-management/provider/details/${planManagementSupplierId}`);
  };

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.planManagementInvoiceFilters !== this.props.planManagementInvoiceFilters) {
      await this._applyFilter();
    }
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    await this.props.setPlanManagementInvoiceFilters(INVOICELIST_FILTERCONFIGS.DEFAULT.filters);
    const requestFilter = this._formatFilterQuery();
    await this.props.doFetchPlanManagementInvoiceStatistics({});
    await this.props.doFetchPlanManagementInvoices({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });
    this.setState({ isLoading: false });
  };

  render() {
    const { planManagementInvoices, planManagementInvoiceStatistics, portalUser } = this.props;
    const { isLoading, isLoadingMore, isAddModalOpen } = this.state;

    return (
      <div className={'pt-x-large'}>
        <AddEditInvoiceDetailsModal isOpen={isAddModalOpen} modalMode={'ADD'} onClose={this._closeAddInvoiceModal} />
        <div className={'flex-row align-center justify-between'}>
          <Title level={4} className="mb-none">
            Invoice Listing
          </Title>
          <PrimaryButton size="large" icon={'plus'} onClick={this._openAddInvoiceModal}>
            New Invoice
          </PrimaryButton>
        </div>

        <div className={'flex-row mt-medium'}>
          <StatisticDisplayCard
            value={planManagementInvoiceStatistics ? Number(planManagementInvoiceStatistics.pendingApproval) : 0}
            className={'mr-large'}
            isLinkDisplayed={
              planManagementInvoiceStatistics && Number(planManagementInvoiceStatistics.pendingApproval) > 0
            }
            linkFunction={() => this._applyFixedFilters('APPROVAL')}
            titleText={
              <>
                Invoice pending <b>approval</b>
              </>
            }
            linkText={'View all awaiting approval'}
          />
          <StatisticDisplayCard
            value={planManagementInvoiceStatistics ? Number(planManagementInvoiceStatistics.awaitingClaim) : 0}
            className={'mr-large'}
            isLinkDisplayed={
              planManagementInvoiceStatistics && Number(planManagementInvoiceStatistics.awaitingClaim) > 0
            }
            linkFunction={() => this._applyFixedFilters('CLAIM')}
            titleText={
              <>
                Invoice awaiting <b>claim</b>
              </>
            }
            linkText={'View all awaiting claim'}
          />
          <StatisticDisplayCard
            value={planManagementInvoiceStatistics ? Number(planManagementInvoiceStatistics.awaitingPayment) : 0}
            isLinkDisplayed={
              planManagementInvoiceStatistics && Number(planManagementInvoiceStatistics.awaitingPayment) > 0
            }
            linkFunction={() => this._applyFixedFilters('PAYMENT')}
            titleText={
              <>
                Invoice awaiting <b>payment</b>
              </>
            }
            linkText={'View all awaiting payment'}
          />
        </div>

        <div className={'mv-x-large'}>
          <FilterSection
            availableFilters={availableFilters}
            filters={this.props.planManagementInvoiceFilters}
            onChangeFilter={this._onChangeFilter}
            displayTimezone={portalUser.timezone}
          />
        </div>

        <div className="height-full bg-white">
          <table className={'width-full plan-management-listing'}>
            <tbody>
              <tr className={'bordered-bottom border-color-quaternary'}>
                <th style={{ width: '10%' }} className={'pb-small pl-medium pr-small'}>
                  <SubTitle>Invoice date</SubTitle>
                </th>
                <th style={{ width: '10%' }} className={'pb-small pr-small'}>
                  <SubTitle>Invoice No</SubTitle>
                </th>
                <th style={{ width: '15%' }} className={'pb-small pr-small'}>
                  <SubTitle>Customer</SubTitle>
                </th>
                <th style={{ width: '10%' }} className={'pb-small pr-small'}>
                  <SubTitle>Provider</SubTitle>
                </th>
                <th style={{ width: '10%' }} className={'pb-small pr-small'}>
                  <SubTitle>Approval status</SubTitle>
                </th>
                <th style={{ width: '10%' }} className={'pb-small pr-small'}>
                  <SubTitle>Claim status</SubTitle>
                </th>
                <th style={{ width: '10%' }} className={'pb-small pr-small'}>
                  <SubTitle>Supplier status</SubTitle>
                </th>
                <th style={{ width: '10%' }} className={'pb-small text-align-right pr-medium'}>
                  <SubTitle>Amount</SubTitle>
                </th>
              </tr>
              {_.isEmpty(planManagementInvoices) ? (
                <InvoiceEmptyState />
              ) : isLoading ? (
                <tr>
                  <td colSpan={8}>
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} />
                  </td>
                </tr>
              ) : (
                <>
                  <InfiniteScrollLoading
                    hasMore={planManagementInvoices.length >= this.state.page * this.state.pageSize}
                    loadingElementId={'content-container'}
                    loadMore={this._fetchMorePlanManagementInvoices}
                    loadingOffSet={100}
                    loaderColSpan={4}
                  >
                    {_.map(planManagementInvoices, (invoice, key) => {
                      return (
                        <tr
                          className={'evenodd cursor-pointer'}
                          onClick={() => this._goToInvoiceDetailView(invoice.planManagementInvoiceId)}
                          key={key}
                        >
                          <td className={'pv-medium pl-medium pr-small'}>
                            {moment.tz(invoice.invoiceIssuedDate, portalUser.timezone).format('DD/MM/YYYY')}
                          </td>
                          <td className={'pv-medium pr-small'}>{invoice.invoiceNumber}</td>
                          <td className={'pv-medium pr-small'}>
                            <div className={'flex-row align-center mv-small'}>
                              <Avatar
                                size="default"
                                shape={'square'}
                                src={invoice.attachmentUrl}
                                className={'mr-small'}
                              />
                              <div>
                                <Text>
                                  {PermissionUtils.validatePermission(
                                    'ViewCustomerProfile',
                                    portalUser.permissions.permissionRoles
                                  ) ? (
                                    <HyperlinkButton
                                      onClick={(e) => this._goToCustomer(e, invoice.customerUserId)}
                                      color="black"
                                    >
                                      {invoice.firstName} {invoice.lastName}
                                    </HyperlinkButton>
                                  ) : (
                                    invoice.firstName + ' ' + invoice.lastName
                                  )}
                                </Text>
                              </div>
                            </div>
                          </td>
                          <td className={'pv-medium pr-small'}>
                            <HyperlinkButton
                              onClick={(e) => this._goToSupplier(e, invoice.planManagementSupplierId)}
                              color="black"
                            >
                              {invoice.supplierName}
                            </HyperlinkButton>
                          </td>
                          <td className={'pv-medium pr-small'}>
                            <ApprovalStatusTag status={invoice.approvalStatus} />
                          </td>
                          <td className={'pv-medium pr-small'}>
                            <ClaimStatusTag status={invoice.claimStatus} />
                          </td>
                          <td className={'pv-medium pr-small'}>
                            <SupplierStatusTag status={invoice.supplierPaymentStatus} />
                          </td>
                          <td className={'pv-medium text-align-right pr-medium'}>
                            {CommonUtils.formatPrice(invoice.invoiceTotal)}
                          </td>
                        </tr>
                      );
                    })}
                  </InfiniteScrollLoading>
                  {isLoadingMore && (
                    <tr>
                      <td colSpan={8}>
                        <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} />
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  planManagementInvoices: state.planManagementStore.planManagementInvoices,
  planManagementInvoiceFilters: state.planManagementStore.planManagementInvoiceFilters,
  planManagementInvoiceStatistics: state.planManagementStore.planManagementInvoiceStatistics,
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setPlanManagementInvoiceFilters: dispatch.planManagementStore.setPlanManagementInvoiceFilters,
  doFetchPlanManagementInvoices: dispatch.planManagementStore.doFetchPlanManagementInvoices,
  doFetchPlanManagementInvoiceDetails: dispatch.planManagementStore.doFetchPlanManagementInvoiceDetails,
  doFetchPlanManagementInvoiceStatistics: dispatch.planManagementStore.doFetchPlanManagementInvoiceStatistics
});

export default connect(
  mapState,
  mapDispatch
)(PlanManagementInvoiceOverviewPanel);
