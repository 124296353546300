import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { Form, notification } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/es/form';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import _ from 'lodash';

interface IWaiveInvoiceActionModalProps extends FormComponentProps {
  isOpen: boolean;
  selectedItem: typeof state.planManagementStore.selectedItem;
  onClose: () => void;
  doWaiveInvoice: typeof dispatch.planManagementStore.doWaiveInvoice;
  doWaiveLineItem: typeof dispatch.planManagementStore.doWaiveLineItem;
  type: string;
}

interface IWaiveInvoiceActionModalState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

class WaiveInvoiceActionModal extends Component<IWaiveInvoiceActionModalProps, IWaiveInvoiceActionModalState> {
  state = {
    isLoading: false,
    isSuccess: false,
    isError: false
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false, isError: false });
      onClose();
    }
  };

  private _validateWaiveReason = (rule, value, callback) => {
    try {
      if (!value || value === '') {
        throw Error('You must add a waive reason.');
      } else if (_.trim(value).length < 5) {
        throw Error('Please enter at least 5 characters.');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _onSubmitWaive = async () => {
    const { form, doWaiveInvoice, doWaiveLineItem } = this.props;
    const { type, item } = this.props.selectedItem;

    const reason = form.getFieldValue('note');

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        this.setState({ isLoading: true });
        (await type) === 'INVOICE'
          ? doWaiveInvoice({
              invoiceId: item.planManagementInvoiceId,
              waivedReason: reason
            })
          : doWaiveLineItem({
              invoiceId: item.planManagementInvoiceId,
              planManagementLineItemId: item.planManagementLineItemId,
              total: item.total,
              waivedReason: reason
            });
        this.setState({ isLoading: false });
        this._onCloseModal();
      } catch (e) {
        this.setState({ isLoading: false });
        notification.error({ message: 'Error', description: 'Oops. Something wrong, please try again later' });
      }
    }
  };

  render() {
    const { isOpen, form } = this.props;
    const { type } = this.props.selectedItem;
    const { getFieldDecorator } = form;

    return (
      <ActionModal
        title={type === 'INVOICE' ? 'Waive Invoice' : 'Waive Line Item'}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="medium"
        verticalAlignment="highest"
      >
        <div className="text-align-left">
          <div className="mb-medium">
            {type === 'INVOICE' ? (
              <>
                <Paragraph>
                  Waiving the invoice will send all line items associated with this invoice to the waived section of
                  payments.
                </Paragraph>
                <Paragraph>
                  Waived line items can be un-waived if you wish to charge the consumer for the line item. You can also
                  waive individual line items if you don't wish to waive the entire invoice.
                </Paragraph>
              </>
            ) : (
              <>
                <Paragraph>Waiving the line item will send it to the waived section of payments.</Paragraph>
                <Paragraph>
                  Waived line items can be un-waived if you wish to charge the consumer for the line item.
                </Paragraph>
              </>
            )}
          </div>
          <div>
            <Paragraph>
              Please provide a reason for waiving this {type === 'INVOICE' ? 'invoice' : 'line item'}
            </Paragraph>
            <Form.Item>
              {getFieldDecorator('note', { rules: [{ validator: this._validateWaiveReason }] })(
                <TextArea placeholder="Waive Reason(s)" autoSize={{ minRows: 3, maxRows: 3 }} />
              )}
            </Form.Item>
          </div>
        </div>
        <ActionModalFooter>
          <GhostButton size="large" onClick={this._onCloseModal} className="mr-medium" disabled={this.state.isLoading}>
            Not now
          </GhostButton>
          <PrimaryButton size="large" onClick={this._onSubmitWaive} loading={this.state.isLoading}>
            Waive
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedItem: state.planManagementStore.selectedItem
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doWaiveInvoice: dispatch.planManagementStore.doWaiveInvoice,
  doWaiveLineItem: dispatch.planManagementStore.doWaiveLineItem
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IWaiveInvoiceActionModalProps>()(WaiveInvoiceActionModal));
