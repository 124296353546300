// all possibilities of filters:

// status: [x,y,z]
// paymentStatus: [x,y,z]
// workerStatus: [x,y,z]
// startDate: [startDate, endDate]
// serviceName: ''
// sort: []
// sort filter format is [col,asc] eg ['desc','asc']

// this serves as the filter configurations. Should expand this later to a proper interface
// to add a filter item, add here as well as BookingListingNavigation.
import moment from 'moment';
import { FilterType } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';

const PLAN_PAYMENTLIST_FILTERCONFIGS = {
  READY_FOR_CLAIM: {
    key: 'READY_FOR_CLAIM',
    title: 'Ready for NDIS claim',
    description: 'Get paid for invoices that have been approved for payment.',
    type: 'READY_FOR_CLAIM',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
        selectionLabel: CommonUtils.getFilterText(FilterType.DATE_RANGE, [
          moment().startOf('isoWeek'),
          moment().endOf('isoWeek')
        ])
      },
      {
        filter: FilterType.PROVIDER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.PROVIDER).fullSelectionName
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      }
    ]
  },

  CLAIMED: {
    key: 'CLAIMED',
    title: 'Claimed (Ready for payment)',
    description: 'Invoices that have been claimed, and payments are ready to be released to the respective suppliers.',
    type: 'CLAIMED',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
        selectionLabel: CommonUtils.getFilterText(FilterType.DATE_RANGE, [
          moment().startOf('isoWeek'),
          moment().endOf('isoWeek')
        ])
      },
      {
        filter: FilterType.PROVIDER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.PROVIDER).fullSelectionName
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      }
    ]
  },

  PAID: {
    key: 'PAID',
    title: 'Paid invoices',
    description: 'Invoices that have been successfully claimed and paid to the supplier.',
    type: 'PAID',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName
      },
      {
        filter: FilterType.PROVIDER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.PROVIDER).fullSelectionName
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      }
    ]
  },

  PAYMENT_ISSUES: {
    key: 'PAYMENT_ISSUES',
    title: 'Payment issues',
    description: 'Invoices that have been flagged with payment issues.',
    type: 'PAYMENT_ISSUES',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName
      },
      {
        filter: FilterType.PROVIDER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.PROVIDER).fullSelectionName
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      }
    ]
  },

  CLAIM_BATCHES: {
    key: 'CLAIM_BATCHES',
    title: 'Claim batches',
    description: 'Invoice batches that have been exported successfully.',
    type: 'CLAIM_BATCHES',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [moment().startOf('isoWeek'), moment().endOf('isoWeek')],
        selectionLabel: CommonUtils.getFilterText(FilterType.DATE_RANGE, [
          moment().startOf('isoWeek'),
          moment().endOf('isoWeek')
        ])
      }
    ]
  },

  REJECTED: {
    key: 'REJECTED',
    title: 'Rejected',
    description: 'Invoices with rejected line items.',
    type: 'REJECTED',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName
      },
      {
        filter: FilterType.PROVIDER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.PROVIDER).fullSelectionName
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      }
    ]
  },

  WAIVED: {
    key: 'WAIVED',
    title: 'Waived',
    description: 'Invoices with waived line items.',
    type: 'WAIVED',
    filters: [
      {
        filter: FilterType.DATE_RANGE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.DATE_RANGE).fullSelectionName
      },
      {
        filter: FilterType.PROVIDER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.PROVIDER).fullSelectionName
      },
      {
        filter: FilterType.CUSTOMER,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
      }
    ]
  }
};

export { PLAN_PAYMENTLIST_FILTERCONFIGS };
