import React from 'react';
import { Route } from 'react-router-dom';

// Views
import LoginView from 'views/non-auth/LoginView';
import RegisterView from 'views/non-auth/RegisterView';
import ResetPasswordView from '../views/non-auth/ResetPasswordView';

// Non Authorized Routes
const NonAuthRoutes = ({ isAuthenticated }) => (
  <>
    {/* Login view */}
    <Route path={'/login'} component={LoginView} />
    <Route path={'/register'} component={RegisterView} />
    <Route path={'/reset'} component={ResetPasswordView} />
  </>
);

export default NonAuthRoutes;
