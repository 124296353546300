import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Divider, notification, Input, Avatar } from 'antd';
import { Paragraph, FieldLabel, Text } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import DatePicker from 'react-datepicker';
import TimeInput from 'common-components/time-input/TimeInput';
import moment from 'moment-timezone';
import CommonUtils from 'utilities/common-utils';
import { TransportAttendanceType } from '../../../../utilities/enum-utils';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../stores/rematch/root-store';
import NumberInput from '../../../../common-components/inputs/NumberInput';

interface FinishGroupBookingModalProps {
  isOpen: any;
  onClose: any;
  booking: any;
  attendanceType: TransportAttendanceType;
  doFinishGroupTransportBooking: typeof dispatch.groupBookingsStore.doFinishGroupTransportBooking;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
}
interface FinishGroupBookingModalState {
  step: number;
  canManuallyClose: boolean;
  title: string;
  endDateTime: Date;
  isTimeError: boolean;
  isDistanceError: boolean;
  travelDistance: number;
  additionalCost: number;
}

class FinishTransportBookingModal extends Component<FinishGroupBookingModalProps, FinishGroupBookingModalState> {
  state = {
    step: 1,
    canManuallyClose: true,
    title: 'Finish transport booking',
    endDateTime: null,
    isTimeError: false,
    isDistanceError: false,
    travelDistance: 0,
    additionalCost: 0
  };

  private _updateEndDate = (event) => {
    this.setState({
      endDateTime: event
    });
  };

  private _onChangeEndTime = async (date) => {
    const { booking } = this.props;
    const endDateTime = moment.tz(CommonUtils.formatCeilingDateTime(date), booking.timezone);

    this.setState({
      endDateTime: endDateTime.toDate()
    });
  };

  private _onChangeDistance = (event) => {
    this.setState({ travelDistance: event });
  };

  private _onChangeAdditionalCost = (event) => {
    this.setState({ additionalCost: event });
  };

  renderView = () => {
    const { step } = this.state;
    const { selectedGroupBookingItem, booking } = this.props;
    const { firstName, lastName, attachmentUrl, timezone } = selectedGroupBookingItem;

    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <Paragraph>Please input the date/time this transport booking finished.</Paragraph>
          <Paragraph>
            This time and additional details will be used for the calculation of the billing for this transport booking,
            along with the designated start time.
          </Paragraph>

          <div className="mb-medium">
            <div className="flex-row align-center">
              <Avatar src={attachmentUrl} icon="user" className="mr-small" />
              <Text>{`${firstName} ${lastName}`}</Text>
            </div>
          </div>

          <div className="flex-1 mr-medium">
            <FieldLabel text="Booking start time" />
            <div>
              <Text lineHeight={120}>
                {moment
                  .tz(booking.workerCheckedInDateTime, selectedGroupBookingItem.timezone)
                  .format('h:mm A, d/M/YYYY')}
              </Text>
            </div>
          </div>

          <div className="mt-small line-height-120 bg-quaternary p-medium bordered border-standard-gray rounded-big">
            <div className="flex-row">
              <div className="flex-1 mr-large">
                <div className="flex-row mt-x2-small">
                  <div>
                    <FieldLabel text={'finish date'} />
                    <DatePicker
                      dateFormat="d/M/yyyy"
                      className="gh-datepicker rounded mr-small"
                      calendarClassName="gh-datepicker-calendar"
                      placeholderText="End date"
                      onChange={this._updateEndDate}
                      selected={this.state.endDateTime}
                    />
                  </div>
                  <div>
                    <FieldLabel text={'customer finish time'} />
                    <TimeInput
                      size="large"
                      className="bg-white"
                      value={moment.tz(this.state.endDateTime, timezone)}
                      onChange={this._onChangeEndTime}
                    />
                  </div>
                </div>
                {this.state.isTimeError && (
                  <div className="text-color-red mt-x-small">Finish time cannot be before the booking start time</div>
                )}
              </div>
            </div>

            <Divider className="divider-large" />

            <div className="mb-large">
              <div>
                <FieldLabel text={'Distance Travelled (km)'} />
                <NumberInput
                  size={'large'}
                  onChange={this._onChangeDistance}
                  value={this.state.travelDistance}
                  min={0}
                  max={10000}
                />
              </div>
              {this.state.isDistanceError && (
                <div className="text-color-red">
                  You must indicate distance travelled in order to finish this booking
                </div>
              )}
            </div>

            <div>
              <FieldLabel text={'additional cost (optional)'} />
              <NumberInput
                size={'large'}
                onChange={this._onChangeAdditionalCost}
                value={this.state.additionalCost}
                min={0}
                max={10000}
              />
            </div>
          </div>

          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" icon="check" onClick={this.onAccept}>
              Finish booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <Paragraph>
            You have finished {firstName} {lastName} transport booking for this session.
          </Paragraph>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen && this.props.booking) {
      const { booking } = this.props;
      this.setState({
        step: 1,
        title: 'Finish booking',
        endDateTime: moment
          .tz(CommonUtils.formatCeilingDateTime(new Date(booking.endDateTime)), booking.timezone)
          .toDate()
      });
    }
  }

  onAccept = async () => {
    const { booking, doFinishGroupTransportBooking, selectedGroupBookingItem, attendanceType } = this.props;
    const { endDateTime } = this.state;
    try {
      let timeError = true;
      let distanceError = true;

      if (moment(this.state.endDateTime).isAfter(moment(booking.startDateTime))) {
        timeError = false;
      }

      if (this.state.travelDistance > 0) {
        distanceError = false;
      }

      if (!timeError && !distanceError) {
        this.setState({ canManuallyClose: false, isTimeError: timeError, isDistanceError: distanceError });

        await doFinishGroupTransportBooking({
          bookingId: selectedGroupBookingItem.bookingId,
          transportBookingId: booking.attendanceId,
          endDateTime: endDateTime ? endDateTime : booking.endDateTime,
          attendanceType,
          travelDistanceDuringBooking: this.state.travelDistance,
          additionalCostDuringBooking: this.state.additionalCost
        });

        this.setState({ step: 2, canManuallyClose: true, title: 'Transport booking finished' });
      } else {
        this.setState({ isTimeError: timeError, isDistanceError: distanceError });
      }
    } catch (e) {
      this.setState({ canManuallyClose: true });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, canManuallyClose: true, title: 'Finish transport booking' }, onClose);
  };

  render() {
    let { isOpen } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this.renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFinishGroupTransportBooking: dispatch.groupBookingsStore.doFinishGroupTransportBooking
});

export default connect(
  mapState,
  mapDispatch
)(FinishTransportBookingModal);
