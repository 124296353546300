import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton } from 'common-components/buttons';
import NDISLineItemGrid from 'common-components/line-items/NDISLineItemGrid';
import { MmmGroup } from 'utilities/enum-utils';
import { Tabs } from 'antd';
import AdditionalChargesSection from '../../../components/AdditionalChargesSection';
import { IServiceAgreementAdditionalCharges } from 'interfaces/customer-interfaces';
import _ from 'lodash';
const { TabPane } = Tabs;
interface IViewServiceAgreementsLineItemsModalProps {
  onClose: any;
  isOpen: boolean;
  selectedServiceLineItems?: Array<any>;
  additionalChargeItems?: IServiceAgreementAdditionalCharges;
  displayMode: string;
  locationMmmGroup?: MmmGroup;
  locationState?: string;
  paymentSourceType: string;
}

interface IViewServiceAgreementsLineItemsModalState {}

class ViewServiceAgreementsLineItemsModal extends Component<
  IViewServiceAgreementsLineItemsModalProps,
  IViewServiceAgreementsLineItemsModalState
> {
  private _onClose = (e) => {
    e.stopPropagation();
    this.props.onClose();
  };

  render() {
    const {
      isOpen,
      selectedServiceLineItems,
      displayMode,
      locationMmmGroup,
      locationState,
      paymentSourceType,
      additionalChargeItems
    } = this.props;

    let lineItems = [];
    if (additionalChargeItems) {
      lineItems = _.map(additionalChargeItems.autoChargedBillingItems, (item) => {
        return {
          ...item,
          paymentSourceType: paymentSourceType,
          isEditing: false
        };
      });
    }
    return (
      <ActionModal
        isOpen={isOpen}
        onClose={this._onClose}
        width={'x2-large'}
        title={this.props.displayMode === 'VIEW' && <>View line items</>}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="mb-large">
            <Text>The following are the line items you have selected to charge this customer for this service</Text>
          </div>
          <Tabs defaultActiveKey={'line-items'}>
            <TabPane key={'line-items'} tab={'Line items'}>
              <NDISLineItemGrid
                lineItems={selectedServiceLineItems ? selectedServiceLineItems : null}
                displayMode={displayMode}
                mmmGroup={locationMmmGroup}
                state={locationState}
                isServiceAgreementLineItems={displayMode === 'VIEW'}
                paymentSourceType={paymentSourceType}
              />
            </TabPane>

            <TabPane key={'additional-charges'} tab={'Additional Charges'}>
              <AdditionalChargesSection
                isEdit={false}
                isNonFaceToFace={additionalChargeItems ? additionalChargeItems.isChargeNonFaceToFace : false}
                nonFaceToFaceMinutes={additionalChargeItems ? additionalChargeItems.nonFaceToFaceMinutes : null}
                mmmGroup={locationMmmGroup}
                state={locationState}
                autoChargeLineItems={lineItems}
                paymentSourceType={paymentSourceType}
              />
            </TabPane>
          </Tabs>
          <ActionModalFooter align="right">
            <PrimaryButton size="large" className="mr-medium" onClick={this._onClose}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      </ActionModal>
    );
  }
}

export default ViewServiceAgreementsLineItemsModal;
