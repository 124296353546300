import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IRootDispatch, dispatch } from 'src/stores/rematch/root-store';
import { Row } from 'antd';
import { ICustomView } from 'src/interfaces/custom-views-interface';
import { CustomViewsModalType } from 'utilities/enum-utils';

export interface IWorkerViewTabPopoverProps {
  currentTab: ICustomView;
  onHidePopOver: (isOpen: boolean) => void;
  onShowWorkerViewModal: (viewModalType?: CustomViewsModalType) => void;
  doUpdateWorkerViewTab: typeof dispatch.teamStore.doUpdateWorkerViewTab;
}

export interface IWorkerViewTabPopoverState {
  isLoading: boolean;
  isSearching: boolean;
  filteredViews: any[];
}

export class WorkerViewTabPopover extends Component<IWorkerViewTabPopoverProps, IWorkerViewTabPopoverState> {
  private _onTogglePinView = () => {
    const { doUpdateWorkerViewTab, currentTab, onHidePopOver } = this.props;
    onHidePopOver(false);
    doUpdateWorkerViewTab({ ...currentTab, isPinned: !currentTab.isPinned, isTogglePinned: true });
  };

  private _onManageView = () => {
    const { onShowWorkerViewModal, onHidePopOver } = this.props;
    onHidePopOver(false);
    onShowWorkerViewModal(CustomViewsModalType.MANAGE);
  };

  private _onDuplicateView = () => {
    const { onShowWorkerViewModal, onHidePopOver } = this.props;
    onHidePopOver(false);
    onShowWorkerViewModal(CustomViewsModalType.DUPLICATE);
  };

  render() {
    const { currentTab } = this.props;

    return (
      <div style={{ margin: '-4px -16px' }}>
        <Row
          className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
          onClick={this._onTogglePinView}
        >
          {currentTab.isPinned ? 'Unpin view' : 'Pin view'}
        </Row>
        {currentTab.isOwner && (
          <Row
            className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
            onClick={this._onManageView}
          >
            Manage view
          </Row>
        )}
        <Row
          className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
          onClick={this._onDuplicateView}
        >
          Duplicate view
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doUpdateWorkerViewTab: dispatch.teamStore.doUpdateWorkerViewTab
});

export default connect(
  null,
  mapDispatchToProps
)(WorkerViewTabPopover);
