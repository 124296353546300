import { Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 as Popover } from '@blueprintjs/popover2';
import { Checkbox, Icon } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { PaymentMethodTag } from 'common-components/tags';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootState, state } from 'src/stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { PaymentSources, ServiceType } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';

interface IPaymentItemRow {
  lineItem?: any;
  customerId?: any;
  history?: any;
  openActionModal?: any;
  errorTypes?: any;
  setSelectedBillingLineItem?: typeof dispatch.billingsStore.setSelectedBillingLineItem;
  onCheckItem?: (bookingBillingLineItemId) => void;
  isLast?: boolean;
  portalUser: typeof state.authStore.portalUser;
  canProcess: boolean;
}

const claimType = {
  STANDARD_SERVICE: 'STD',
  CANCELLATION: 'CANC',
  TRAVEL: 'TRAN',
  REPORT_WRITING: 'REPW',
  NO_FACE_TO_FACE: 'NF2F',
  TELEHEALTH_SUPPORTS: 'THLT',
  IRREGULAR_SIL_SUPPORTS: 'IRSS',
};

const claimTypeOptions = [
  { value: claimType.STANDARD_SERVICE, label: 'Standard Service' },
  { value: claimType.CANCELLATION, label: 'Cancellation' },
  { value: claimType.TRAVEL, label: 'Travel' },
  { value: claimType.REPORT_WRITING, label: 'Report Writing' },
  { value: claimType.NO_FACE_TO_FACE, label: 'Non Face-to-face' },
  { value: claimType.TELEHEALTH_SUPPORTS, label: 'Telehealth supports' },
  { value: claimType.IRREGULAR_SIL_SUPPORTS, label: 'Irregular SIL supports' },
];

const unit = {
  HOURS: 'H',
  EACH: 'EA',
};

const unitOptions = [
  { value: unit.HOURS, label: 'hours' },
  { value: unit.EACH, label: 'each' },
];

class PaymentItemRow extends Component<IPaymentItemRow> {
  _checkbox = null;
  state = {
    bookingBillingLineItemId: null,
    showSubLines: false,
    openAction: false,
    attendanceId: null,
  };

  _onClickBooking = () => {
    const { history } = this.props;
    history.push(`/bookings/details/${this.state.attendanceId}`);
  };

  _onClickCustomer = () => {
    const { history } = this.props;
    history.push(`/customer/details/${this.props.customerId}`);
  };

  _onCheckItem = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { onCheckItem } = this.props;

    onCheckItem(this.state.bookingBillingLineItemId);
  };

  componentDidMount = () => {
    const { lineItem } = this.props;

    if (!_.isEmpty(lineItem)) {
      const { bookingBillingLineItemId, attendanceId } = lineItem;
      this.setState({ bookingBillingLineItemId, attendanceId });
    }
  };

  _getErrorMessage = () => {
    const { errorTypes, lineItem } = this.props;

    const paymentErrorArray = [];

    if (
      _.find(errorTypes, (o) => {
        return o === 'NDIA';
      })
    ) {
      paymentErrorArray.push('No NDIS Number set.');
    } else if (
      _.find(errorTypes, (o) => {
        return o === 'VCP';
      })
    ) {
      paymentErrorArray.push('No email set for the Case Manager.');
    }

    if (lineItem.paymentMethod === 'PLAN') {
      paymentErrorArray.push('No email set for the Plan Manager.');
    }
    if (lineItem.paymentMethod === 'SELF') {
      paymentErrorArray.push('No email set for the Self Manager.');
    }

    return _.map(paymentErrorArray).join(' ');
  };

  render() {
    const { lineItem, isLast, openActionModal, portalUser } = this.props;

    if (_.isEmpty(lineItem)) {
      return <></>;
    }

    const {
      billingTotal,
      selected,
      paymentMethod,
      supportItemNumber,
      unitPrice,
      claimType,
      unit,
      isError,
      qty,
      startDateTime,
      paymentSourceType,
      supportItemName,
      serviceType,
    } = lineItem;

    return (
      <>
        <tr className={`${(isError || isLast) && 'no-border'}`}>
          <td
            className="fluid-cell check-all"
            onClick={this._onCheckItem}
            onMouseEnter={() => this._checkbox.focus()}
            onMouseLeave={() => this._checkbox.blur()}
          >
            {this.props.canProcess && (
              <Checkbox checked={selected} disabled={isError} ref={(c) => (this._checkbox = c)} />
            )}
          </td>
          {paymentSourceType === PaymentSources.NDIS && (
            <td className="fluid-cell">
              <PaymentMethodTag paymentMethod={paymentMethod} className="mr-medium" rounded={true} size="small" />{' '}
            </td>
          )}
          <td className="ellipsis">
            {paymentSourceType === PaymentSources.NDIS ? supportItemNumber : supportItemName}
          </td>
          {paymentSourceType === PaymentSources.NDIS && (
            <td className="ellipsis">
              {claimType ? claimTypeOptions.find((item) => item.value === claimType)?.label : 'Standard Service'}
            </td>
          )}
          <td className="ellipsis">
            {serviceType !== ServiceType.GROUP ? (
              <HyperlinkButton onClick={this._onClickBooking}>
                {moment(startDateTime).format('DD/MM/YYYY')}
              </HyperlinkButton>
            ) : (
              moment(startDateTime).format('DD/MM/YYYY')
            )}
          </td>
          <td className="ellipsis">
            {CommonUtils.formatQuantityDisplay(qty)} ({_.get(unitOptions.find((item) => item.value === unit), 'label')})
            x {CommonUtils.formatPrice(unitPrice)}
          </td>
          <td className="fluid-cell">
            <div className="text-align-right">{CommonUtils.formatPrice(billingTotal)}</div>
          </td>
          <td className="fluid-cell">
            {/*<HyperlinkButton*/}
            {/*  color="red"*/}
            {/*  onClick={() => this.props.openActionModal({ action: 'REJECT_LINEITEM', additionalData: lineItem })}*/}
            {/*>*/}
            {/*  Reject*/}
            {/*</HyperlinkButton>*/}
            <Popover
              content={
                <Menu>
                  {PermissionUtils.validatePermission(
                    'EditPaymentBillingLineItem',
                    portalUser.permissions.permissionRoles,
                  ) && (
                    <MenuItem
                      text={
                        <Text weight="bold" color="green">
                          Edit
                        </Text>
                      }
                      className="hover-bg-green-lightest"
                      onClick={() => openActionModal({ action: 'EDIT', additionalData: lineItem })}
                    />
                  )}
                  {PermissionUtils.validatePermission(
                    'WaivePaymentBillingLineItem',
                    portalUser.permissions.permissionRoles,
                  ) && (
                    <MenuItem
                      text={
                        <Text weight="bold" color="red">
                          Waive
                        </Text>
                      }
                      className="hover-bg-red-lightest"
                      onClick={() => openActionModal({ action: 'WAIVE', additionalData: lineItem })}
                    />
                  )}
                </Menu>
              }
              position={'bottom-right'}
            >
              <Icon type="ellipsis" />
            </Popover>
          </td>
        </tr>
        {isError && (
          <tr className={`${isLast && 'no-border'}`}>
            <td />
            <td colSpan={7} className="text-color-red text-weight-bold pt-none">
              <Icon type="warning" className="mr-x-small" />
              {this._getErrorMessage()}{' '}
              {PermissionUtils.validatePermission('ViewCustomerProfile', portalUser.permissions.permissionRoles) && (
                <HyperlinkButton onClick={this._onClickCustomer}>View Customer Details</HyperlinkButton>
              )}
            </td>
          </tr>
        )}
      </>
    );
  }
}

const mapState = (state: IRootState) => ({ portalUser: state.authStore.portalUser });

export default connect(
  mapState,
  null,
)(PaymentItemRow);
