import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { SuccessSVG, ErrorSVG } from '../../../../assets/UndrawSVG';
import { Paragraph, Text, SubTitle } from 'common-components/typography';
import { Checkbox, Col, Form, Icon, Row } from 'antd';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';
import _ from 'lodash';
import moment from 'moment';
import CommonUtils from 'utilities/common-utils';
import { PlanManagementClaimStatus } from 'utilities/enum-utils';
import { ndisHelper } from 'variables/data-helpers';

interface IPlanPaymentRejectInvoiceModalProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  refreshListing: any;
  setSelectedRejectPlanManagementLineItems: typeof dispatch.planManagementStore.setSelectedRejectPlanManagementLineItems;
  selectedRejectPlanManagementLineItems: typeof state.planManagementStore.selectedRejectPlanManagementLineItems;
  setSelectedRejectBillingItems: typeof dispatch.billingsStore.setSelectedRejectBillingItems;
  selectedPlanManagementInvoiceDetails: typeof state.planManagementStore.selectedPlanManagementInvoiceDetails;
  doRejectClaimedInvoiceLineItems: typeof dispatch.planManagementStore.doRejectClaimedInvoiceLineItems;
}

const unit = {
  H: 'H',
  EA: 'EA',
  D: 'D',
  WK: 'WK',
  MO: 'MO',
  YR: 'YR'
};

const unitOptions = [
  { value: unit.H, label: ' hr' },
  { value: unit.EA, label: 'ea' },
  { value: unit.D, label: 'dy' },
  { value: unit.WK, label: 'wk' },
  { value: unit.MO, label: 'mth' },
  { value: unit.YR, label: 'yr' }
];

class PlanPaymentRejectInvoiceModal extends Component<IPlanPaymentRejectInvoiceModalProps, any> {
  state = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    step: 1,
    title: 'Reject Invoice'
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false, isError: false, step: 1, title: 'Reject Invoice Line Item' });
      onClose();
    }
  };

  private _onSubmitReject = () => {
    const { form, doRejectClaimedInvoiceLineItems, selectedRejectPlanManagementLineItems } = this.props;
    form.validateFields(async (err, value) => {
      if (!err) {
        this.setState({ isLoading: true });
        const rejectReason = value.note;
        try {
          const lineItems = _.map(selectedRejectPlanManagementLineItems, (selectedLineItem) => {
            return {
              planManagementLineItemId: selectedLineItem.planManagementLineItemId,
              rejectReason: rejectReason
            };
          });
          const resp: any = await doRejectClaimedInvoiceLineItems({
            planManagementInvoiceId: selectedRejectPlanManagementLineItems[0].planManagementInvoiceId,
            lineItem: lineItems
          });

          this.props.refreshListing && (await this.props.refreshListing());
          this.setState({ isLoading: false, isSuccess: true, title: 'Line item(s) successfully rejected.' });
        } catch (e) {
          console.log(e);
          this.setState({ isLoading: false, isError: true });
        }
      }
    });
  };

  private _onClickSelect = async (lineItem) => {
    const { selectedRejectPlanManagementLineItems, setSelectedRejectPlanManagementLineItems } = this.props;

    const selectedLineItem = _.find(selectedRejectPlanManagementLineItems, (selectedLineItem) => {
      return selectedLineItem.planManagementLineItemId === lineItem.planManagementLineItemId;
    });

    if (selectedLineItem) {
      const newSelectedLineItems = _.filter(selectedRejectPlanManagementLineItems, (selectedLineItem) => {
        return selectedLineItem.planManagementLineItemId !== lineItem.planManagementLineItemId;
      });
      await setSelectedRejectPlanManagementLineItems(newSelectedLineItems);
    } else {
      await setSelectedRejectPlanManagementLineItems([
        ...selectedRejectPlanManagementLineItems,
        {
          planManagementLineItemId: lineItem.planManagementLineItemId,
          planManagementInvoiceId: lineItem.planManagementInvoiceId
        }
      ]);
    }
  };

  private _selectAll = async () => {
    const {
      selectedRejectPlanManagementLineItems,
      setSelectedRejectPlanManagementLineItems,
      selectedPlanManagementInvoiceDetails
    } = this.props;

    if (selectedRejectPlanManagementLineItems.length === selectedPlanManagementInvoiceDetails.lineItems.length) {
      await setSelectedRejectPlanManagementLineItems([]);
    } else {
      const newSelectedItems = _.map(selectedPlanManagementInvoiceDetails.lineItems, (lineItem) => {
        return {
          planManagementLineItemId: lineItem.planManagementLineItemId,
          planManagementInvoiceId: lineItem.planManagementInvoiceId
        };
      });
      await setSelectedRejectPlanManagementLineItems(newSelectedItems);
    }
  };

  private _isSelected = (lineItem) => {
    const { selectedRejectPlanManagementLineItems } = this.props;
    return !!_.find(
      selectedRejectPlanManagementLineItems,
      (selectedItem) => selectedItem.planManagementLineItemId === lineItem.planManagementLineItemId
    );
  };

  private _nextStep = () => {
    this.setState({ step: 2, title: 'Reject line items' });
  };

  private _previousStep = () => {
    this.setState({ step: 1, title: 'Reject Invoice' });
  };

  private _validateRejectReason = (rule, value, callback) => {
    try {
      if (_.isEmpty(_.trim(value))) {
        throw Error('Please enter a rejection reason');
      }
      if (_.trim(value).length < 5) {
        throw Error('Please enter at least 5 characters');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  render() {
    // const { isOpen, selectedInvoiceLineItems, selectedRejectBillingItems, form } = this.props;
    const { isOpen, form, selectedPlanManagementInvoiceDetails, selectedRejectPlanManagementLineItems } = this.props;
    const { getFieldDecorator } = form;

    const invoiceLineItems = _.filter(selectedPlanManagementInvoiceDetails.lineItems, (lineItem) => {
      return lineItem.claimStatus === PlanManagementClaimStatus.CLAIMED;
    });

    const isAllSelected =
      selectedRejectPlanManagementLineItems.length === selectedPlanManagementInvoiceDetails.lineItems.length;
    const indeterminate = !isAllSelected && selectedRejectPlanManagementLineItems.length > 0;

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="x2-large"
        verticalAlignment="highest"
      >
        {!this.state.isLoading &&
          !this.state.isSuccess &&
          !this.state.isError &&
          this.state.step === 1 &&
          invoiceLineItems.length > 0 && (
            <div className="text-align-left" style={{ minHeight: '250px' }}>
              <div className="mb-medium">
                <Paragraph>
                  Please select the line items from the invoice which have been rejected by the NDIS
                </Paragraph>
                <div className="bordered border-standard-gray rounded">
                  <Row gutter={12} className="pv-small bordered-bottom">
                    <Col span={2} className={'ph-large'}>
                      <Checkbox checked={isAllSelected} indeterminate={indeterminate} onClick={this._selectAll} />
                    </Col>
                    <Col span={4}>
                      <SubTitle>CLAIM REF NO</SubTitle>
                    </Col>
                    <Col span={3}>
                      <SubTitle>CLAIMED ON</SubTitle>
                    </Col>
                    <Col span={6}>
                      <SubTitle>LINE ITEM</SubTitle>
                    </Col>
                    <Col span={6}>
                      <SubTitle>COST</SubTitle>
                    </Col>
                    <Col span={3} className={'pr-large text-align-right'}>
                      <SubTitle>Total</SubTitle>
                    </Col>
                  </Row>
                  {_.map(invoiceLineItems, (lineItem: any) => {
                    const NDISLineItemData = ndisHelper.getBySupportItemNumber(lineItem.supportItemNumber);
                    return (
                      <Row
                        gutter={12}
                        className="pv-small bordered-bottom border-secondary"
                        type={'flex'}
                        align={'middle'}
                      >
                        <Col span={2} className="ph-large">
                          {!lineItem.isRejected && (
                            <Checkbox
                              checked={this._isSelected(lineItem)}
                              onClick={() => this._onClickSelect(lineItem)}
                            />
                          )}
                        </Col>
                        <Col span={4}>{lineItem.claimReferenceNumber}</Col>
                        <Col span={3}>{moment(lineItem.startDateTime).format('DD/MM/YYYY')}</Col>
                        <Col span={6} style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                          <Text color={'secondary'}>{lineItem.supportItemNumber}</Text>
                          <br />
                          {NDISLineItemData.SupportItem}
                        </Col>
                        <Col span={6}>
                          {CommonUtils.formatPrice(lineItem.price)} x {lineItem.qty}
                          {unitOptions.find((item) => item.value === lineItem.unit)
                            ? unitOptions.find((item) => item.value === lineItem.unit).label
                            : unit}
                        </Col>
                        <Col span={3} className={'pr-large text-align-right'}>
                          {CommonUtils.formatPrice(lineItem.total)}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        {!this.state.isLoading && !this.state.isSuccess && !this.state.isError && this.state.step === 2 && (
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>
                You have decided to reject{' '}
                <b>
                  {selectedRejectPlanManagementLineItems.length} line item
                  {selectedRejectPlanManagementLineItems.length &&
                    selectedRejectPlanManagementLineItems.length > 1 &&
                    's'}
                </b>{' '}
                from this invoice. Please ensure you are rejecting the right line items before you proceed.
              </Paragraph>
            </div>

            <div className="flex-row align-left mb-medium">
              <Text>Please provider a reason for rejecting these line items.</Text>
            </div>
            <div className="flex-row align-center justify-center mb-x-large">
              <Form.Item className="width-full">
                {getFieldDecorator('note', {
                  rules: [{ validator: this._validateRejectReason }]
                })(<TextArea placeholder="Rejection Reason(s)" autoSize={{ minRows: 4, maxRows: 4 }} />)}
              </Form.Item>
            </div>
          </div>
        )}
        {this.state.isLoading && <SpinningLoader size={100} message={'Loading'} />}
        {!this.state.isLoading && this.state.isSuccess && (
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>You have successfully rejected the selected line items</Paragraph>
              <Paragraph>All rejected line items have been moved to the rejected section of payments.</Paragraph>
            </div>
          </div>
        )}

        {!this.state.isSuccess && !this.state.isError && this.state.step === 1 && (
          <ActionModalFooter>
            <GhostButton
              size="large"
              onClick={this._onCloseModal}
              className="mr-medium"
              disabled={this.state.isLoading}
            >
              Close
            </GhostButton>
            <PrimaryButton
              size="large"
              disabled={_.isEmpty(this.props.selectedRejectPlanManagementLineItems)}
              onClick={this._nextStep}
              loading={this.state.isLoading}
            >
              Reject line items
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {!this.state.isSuccess && !this.state.isError && this.state.step === 2 && (
          <ActionModalFooter>
            <GhostButton
              size="large"
              onClick={this._onCloseModal}
              className="mr-medium"
              disabled={this.state.isLoading}
            >
              Close
            </GhostButton>
            {invoiceLineItems.length > 1 && (
              <SecondaryButton
                size="large"
                onClick={this._previousStep}
                className="mr-medium"
                disabled={this.state.isLoading}
              >
                Change selection
              </SecondaryButton>
            )}
            <PrimaryButton size="large" onClick={this._onSubmitReject} loading={this.state.isLoading}>
              Reject
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {this.state.isSuccess && !this.state.isError && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {!this.state.isLoading && this.state.isError && (
          <div className="text-align-center">
            <div className="pv-medium">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium">
              <Paragraph>An error has occured.</Paragraph>
            </div>
          </div>
        )}

        {this.state.isError && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedPlanManagementInvoiceDetails: state.planManagementStore.selectedPlanManagementInvoiceDetails,
  selectedRejectPlanManagementLineItems: state.planManagementStore.selectedRejectPlanManagementLineItems
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedRejectPlanManagementLineItems: dispatch.planManagementStore.setSelectedRejectPlanManagementLineItems,
  doRejectClaimedInvoiceLineItems: dispatch.planManagementStore.doRejectClaimedInvoiceLineItems
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IPlanPaymentRejectInvoiceModalProps>()(PlanPaymentRejectInvoiceModal));
