import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { BookingSVG, ConfirmedSVG, ErrorSVG } from 'assets/UndrawSVG';
import { Paragraph } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { Spinner } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';

class BookingConfirmActionModel extends Component<any, any> {
  state = { step: 1, canManuallyClose: true, title: 'Confirm Booking Request' };

  renderView = () => {
    const { step } = this.state;

    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <div className="text-align-left">
            <Paragraph>Are you sure you want to confirm this booking?</Paragraph>
            <Paragraph>The customer will be notified that you have confirmed the booking.</Paragraph>
          </div>
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal}>
              Not Now
            </GhostButton>
            <PrimaryButton size="large" icon="check" onClick={this.onAccept}>
              Confirm Booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <div className="text-align-center">
            <div className="pv-large">
              <Spinner size={150} />
            </div>

            <Paragraph>Confirming this booking, won’t be long…</Paragraph>
          </div>
        </div>
      );
    }

    if (step === 3) {
      return (
        <div className="anim-fade-in">
          <div className="text-align-left">
            <Paragraph>You have successfully confirmed this booking and the customer has been notified.</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 4) {
      return (
        <div className="anim-fade-in">
          <div className="pv-medium">
            <img src={ErrorSVG} alt="ERROR" style={{ width: '100%', height: '200px' }} />
          </div>
          <div className="text-align-center">
            <Paragraph>Oops something has gone wrong, please try again</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Go Back to Booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    // Reset back to step 1
    this.setState({ step: 1, title: 'Confirm Booking Request' }, onClose);
  };

  onComplete = () => {
    this.setState({ step: 3, canManuallyClose: true, title: 'Booking confirmed' });
  };

  onError = () => {
    this.setState({ step: 4, canManuallyClose: true });
  };

  onAccept = async () => {
    const { doConfirmBooking, selectedBooking } = this.props;
    this.setState({ step: 2, canManuallyClose: false });
    try {
      await doConfirmBooking({ bookingId: selectedBooking.bookingId });
      this.onComplete();
    } catch (e) {
      this.onError();
    }
  };

  render() {
    let { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title={this.state.title}
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>
          <div className="flex-column justify-center">{this.renderView()}</div>
        </div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({ selectedBooking: state.bookingsStore.selectedBookingItem });

const mapDispatch = (dispatch: IRootDispatch) => ({
  doConfirmBooking: dispatch.bookingsStore.doConfirmBooking
});

export default connect(
  mapState,
  mapDispatch
)(BookingConfirmActionModel);
