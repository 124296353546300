import React, { Component } from 'react';
import { Text, Title } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import { Col, Input, Row, Icon, Divider, Skeleton } from 'antd';
import _ from 'lodash';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import asyncDelay from 'utilities/asyncDelay';
import { IGroupServiceSettings, IServiceDetailsItem } from 'interfaces/service-interfaces';
import { TimezoneIndicator } from 'common-components/timezone';
import EditGroupServiceSettingsModal, { EditGroupServiceSettingsModalType } from './EditGroupServiceSettingsModal';
import { ServicePublishStatus } from 'utilities/enum-utils';

interface IGroupServiceSettingsPanelProps {
  selectedServiceSettings: IGroupServiceSettings;
  serviceId: string;
  doGetGroupServiceSettings: typeof dispatch.servicesStore.doGetGroupServiceSettings;
}

interface IGroupServiceSettingsPanelState {
  isLoading: boolean;
  isOpenEditModal: boolean;
  editSection: EditGroupServiceSettingsModalType;
}

class GroupServiceSettingsPanel extends Component<IGroupServiceSettingsPanelProps, IGroupServiceSettingsPanelState> {
  state = {
    isLoading: false,
    isOpenEditModal: false,
    editSection: null
  };

  private _openEditModal = (editSection: EditGroupServiceSettingsModalType) => {
    this.setState({ editSection, isOpenEditModal: true });
  };

  private _closeEditModal = () => {
    this.setState({ editSection: null, isOpenEditModal: false });
  };

  componentDidMount = async () => {
    const { serviceId } = this.props;
    this.setState({ isLoading: true });
    await this.props.doGetGroupServiceSettings({ serviceId });
    this.setState({ isLoading: false });
  };

  render() {
    const { selectedServiceSettings } = this.props;
    const { isLoading } = this.state;
    const timezone = selectedServiceSettings && selectedServiceSettings.timezone;

    return (
      <div className="pt-x-large pl-medium width-full pb-x3-large">
        {isLoading ? (
          <Skeleton paragraph={{ rows: 5, width: '100%' }} active={true} className="anim-slide-left" />
        ) : !selectedServiceSettings ? (
          <div>Service settings not found</div>
        ) : (
          <>
            <EditGroupServiceSettingsModal
              isOpen={this.state.isOpenEditModal}
              editSection={this.state.editSection}
              onClose={this._closeEditModal}
              selectedServiceSettings={selectedServiceSettings}
              serviceId={this.props.serviceId}
            />
            <Title level={4} className="mb-large">
              Service settings
            </Title>

            <Row>
              <Col span={8} className="pr-large pv-medium">
                <div className="mb-medium">
                  <Text weight="bold">Service timezone</Text>
                </div>
                <div>
                  <Text>Default timezone used for this service</Text>
                </div>
              </Col>
              <Col span={16} className="bg-white p-medium rounded-big shadow-container">
                <Text color="secondary">Timezone</Text>
                <br />
                <TimezoneIndicator timezone={timezone} bordered={false} hasIcon={false} />
                <div className="mt-x2-small">
                  <HyperlinkButton onClick={() => this._openEditModal(EditGroupServiceSettingsModalType.TIMEZONE)}>
                    <Icon type="edit" /> Edit...
                  </HyperlinkButton>
                </div>
              </Col>
            </Row>
  
            <Divider className="mv-large bg-standard-gray" />

            {selectedServiceSettings.status !== ServicePublishStatus.DRAFT &&
              selectedServiceSettings.status !== ServicePublishStatus.ARCHIVED && (
                <>
                  <Row>
                    <Col span={8} className="pr-large pv-medium">
                      <div className="mb-medium">
                        <Text weight="bold">Publish settings</Text>
                      </div>
                      <div>
                        <Text>How would you like this service to be published.</Text>
                      </div>
                    </Col>
                    <Col span={16} className="bg-white p-medium rounded-big shadow-container">
                      <Col span={10}>
                        <Text color="secondary">Publish date</Text>
                        <br />
                        <Text size="regular">Available from</Text>
                        <br />
                        <Text>
                          {selectedServiceSettings.servicePublishDate
                            ? moment.tz(selectedServiceSettings.servicePublishDate, timezone).format('DD/MM/yyyy')
                            : 'Publish date not set'}
                        </Text>
                        {selectedServiceSettings.status === ServicePublishStatus.UNPUBLISHED && (
                          <div className="mt-x2-small">
                            <HyperlinkButton
                              onClick={() => this._openEditModal(EditGroupServiceSettingsModalType.PUBLISH_DATE)}
                            >
                              <Icon type="edit" /> Edit...
                            </HyperlinkButton>
                          </div>
                        )}
                      </Col>
                      <Col span={10}>
                        <Text color="secondary">Publication rules</Text>
                        <br />
                        <Text>
                          {selectedServiceSettings.isPublicMarketplace ? 'Public marketplace' : 'Private Marketplace'}
                        </Text>

                        {selectedServiceSettings.status === ServicePublishStatus.UNPUBLISHED && (
                          <div className="mt-x2-small">
                            <HyperlinkButton
                              onClick={() => this._openEditModal(EditGroupServiceSettingsModalType.PUBLISH_RULE)}
                            >
                              <Icon type="edit" /> Edit...
                            </HyperlinkButton>
                          </div>
                        )}
                      </Col>
                      <Col span={4}></Col>
                    </Col>
                  </Row>
  
                  <Divider className="mv-large bg-standard-gray" />
                </>
              )}

            <Row>
              <Col span={8} className="pr-large pv-medium">
                <div className="mb-medium">
                  <Text weight="bold">Service code</Text>
                </div>
                <div>
                  <Text>Unique code for this service</Text>
                </div>
              </Col>
              <Col span={16} className="bg-white p-medium rounded-big shadow-container">
                <Text color="secondary">Service code</Text>
                <br />
                <Text>
                  {selectedServiceSettings.serviceStockNumber
                    ? selectedServiceSettings.serviceStockNumber
                    : 'No service code set'}
                </Text>
                <div className="mt-x2-small">
                  <HyperlinkButton onClick={() => this._openEditModal(EditGroupServiceSettingsModalType.SERVICE_CODE)}>
                    <Icon type="edit" /> Edit...
                  </HyperlinkButton>
                </div>
              </Col>
            </Row>
  
            <Divider className="mv-large bg-standard-gray" />

            <Row>
              <Col span={8} className="pr-large pv-medium">
                <div className="mb-medium">
                  <Text weight="bold">Default capacity</Text>
                </div>
                <div>
                  <Text>Default capacity settings for this service</Text>
                </div>
              </Col>
              <Col span={16} className="bg-white p-medium rounded-big shadow-container">
                <Text color="secondary">Capacity</Text>
                <br />
                <Text>
                  {selectedServiceSettings.capacity > 0 ? selectedServiceSettings.capacity : 'No capacity set'}
                </Text>
                <div className="mt-x2-small">
                  <HyperlinkButton onClick={() => this._openEditModal(EditGroupServiceSettingsModalType.CAPACITY)}>
                    <Icon type="edit" /> Edit...
                  </HyperlinkButton>
                </div>
              </Col>
            </Row>
  
            <Divider className="mv-large bg-standard-gray" />

            <Row>
              <Col span={8} className="pr-large pv-medium">
                <div className="mb-medium">
                  <Text weight="bold">Start/finish threshold</Text>
                </div>
                <div>
                  <Text>
                    Warnings/alerts will be triggered automatically if team members start/finish outside of this
                    threshold
                  </Text>
                </div>
              </Col>
              <Col span={16} className="bg-white p-medium rounded-big shadow-container">
                <Text color="secondary">Threshold</Text>
                <br />
                <Text>{selectedServiceSettings.workerTimeLeeway} Minutes</Text>
                <div className="mt-x2-small">
                  <HyperlinkButton onClick={() => this._openEditModal(EditGroupServiceSettingsModalType.THRESHOLD)}>
                    <Icon type="edit" /> Edit...
                  </HyperlinkButton>
                </div>
              </Col>
            </Row>
  
            <Divider className="mv-large bg-standard-gray" />

            <Row>
              <Col span={8} className="pr-large pv-medium">
                <div className="mb-medium">
                  <Text weight="bold">Allow team member time modification</Text>
                  <br />
                </div>
                <div>
                  <Text>Allow team members to modify their start/finish times? </Text> <br />
                  {/* <HyperlinkButton>How this setting works</HyperlinkButton> */}
                </div>
              </Col>
              <Col span={16} className="bg-white p-medium rounded-big shadow-container">
                <Text color="secondary">Modification allowed</Text>
                <br />
                <Text weight="bold" color={selectedServiceSettings.canWorkerEditCheckInCheckOutTime ? 'green' : 'red'}>
                  {selectedServiceSettings.canWorkerEditCheckInCheckOutTime ? 'YES' : 'NO'}
                </Text>
                <div className="mt-x2-small">
                  <HyperlinkButton
                    onClick={() => this._openEditModal(EditGroupServiceSettingsModalType.TIME_MODIFICATION)}
                  >
                    <Icon type="edit" /> Edit...
                  </HyperlinkButton>
                </div>
              </Col>
            </Row>
  
            <Divider className="mv-large bg-standard-gray" />

            <Row>
              <Col span={8} className="pr-large pv-medium">
                <div className="mb-medium">
                  <Text weight="bold">Transport before/after bookings</Text>
                </div>
                <div>
                  <Text>Allow transport before/after bookings for customers </Text>
                </div>
              </Col>
              <Col span={16} className="bg-white p-medium rounded-big shadow-container">
                <Col span={selectedServiceSettings.isOfferTransport ? 11 : 22}>
                  <Text color="secondary">Transport before/after booking</Text>
                  <br />
                  {selectedServiceSettings.isOfferTransport ? (
                    <Text>
                      <span className="text-color-green text-weight-bold">YES</span> - Transport before/after bookings
                      can be made for attending customers.
                    </Text>
                  ) : (
                    <Text>
                      <span className="text-color-red text-weight-bold">NO</span> - Transport has been disabled for this
                      service.
                    </Text>
                  )}
                  <div className="mt-x2-small">
                    <HyperlinkButton onClick={() => this._openEditModal(EditGroupServiceSettingsModalType.TRANSPORT)}>
                      <Icon type="edit" /> Edit...
                    </HyperlinkButton>
                  </div>
                </Col>
                {selectedServiceSettings.isOfferTransport && (
                  <Col span={11} className="pl-medium">
                    <>
                      <Text color="secondary">Cost per km</Text>
                      <br />
                      <Text>
                        $
                        {selectedServiceSettings.transportPricePerKm
                          ? selectedServiceSettings.transportPricePerKm
                          : '0.00'}
                      </Text>
                    </>
                  </Col>
                )}

                <Col span={2}></Col>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedServiceSettings: state.servicesStore.selectedServiceSettings
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetGroupServiceSettings: dispatch.servicesStore.doGetGroupServiceSettings
});

export default connect(
  mapState,
  mapDispatch
)(GroupServiceSettingsPanel);
