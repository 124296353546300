// all possibilities of filters:

// status: [x,y,z]
// paymentStatus: [x,y,z]
// workerStatus: [x,y,z]
// startDate: [startDate, endDate]
// serviceName: ''
// sort: []
// sort filter format is [col,asc] eg ['desc','asc']

// this serves as the filter configurations. Should expand this later to a proper interface
// to add a filter item, add here as well as BookingListingNavigation.
import moment from 'moment';

const PAYMENTLIST_FILTERCONFIGS = {
  WAITING_TO_BE_PROCESSED: {
    key: 'WAITING_TO_BE_PROCESSED',
    title: 'Waiting to be processed',
    description: 'Payments that are awaiting to be processed.',
    type: 'WAITING_TO_BE_PROCESSED',
    filters: []
  },

  PROCESSED: {
    key: 'PROCESSED',
    title: 'Processed',
    description: 'All processed payments.',
    type: 'PROCESSED',
    filters: []
  },

  PAID: {
    key: 'PAID',
    title: 'Paid',
    description: 'Invoices which have been paid by the recipient',
    type: 'PAID',
    filters: []
  },

  BATCH: {
    key: 'BATCH',
    title: 'Batches',
    description: 'All processed batches.',
    type: 'BATCH',
    filters: []
  },

  REJECTED: {
    key: 'REJECTED',
    title: 'Rejected Items',
    description: 'All rejected line items & invoices.',
    type: 'REJECTED',
    filters: []
  },

  WAIVED: {
    key: 'WAIVED',
    title: 'Waived Line Items',
    description: 'All line items that has been waived for payments.',
    type: 'WAIVED',
    filters: {}
  },

  SHIFT_HOURS: {
    key: 'SHIFT_HOURS',
    title: 'Shift Hours',
    description: 'Hours worked by your team.',
    type: 'SHIFT_HOURS',
    filters: {
      serviceIds: [],
      startDate: moment()
        .add(-4, 'weeks')
        .startOf('day')
        .toDate(),
      endDate: moment()
        .endOf('day')
        .toDate()
    }
  }
};
export { PAYMENTLIST_FILTERCONFIGS };

/*



  PAYMENT_STATUS: {
    INITIAL: STATUSSTRING.INITIAL,
    READY_FOR_BILLING: STATUSSTRING.READY_FOR_BILLING,
    SEND_TO_FINANCE: STATUSSTRING.SEND_TO_FINANCE,
    PROCESSED: STATUSSTRING.PROCESSED,
    BILLING_FAILED: STATUSSTRING.BILLING_FAILED,
    WAIVED: STATUSSTRING.WAIVED,
    NO_CHARGE: STATUSSTRING.NO_CHARGE,
    REQUIRES_APPROVAL: STATUSSTRING.REQUIRES_APPROVAL
  },
  PAYMENT_STATUS: {
    PENDING: STATUSSTRING.PENDING, // Pending
    ACCEPTED: STATUSSTRING.ACCEPTED,
    CONFIRMED: STATUSSTRING.CONFIRMED,
    IN_PROGRESS: STATUSSTRING.IN_PROGRESS, // INPROGRESS
    COMPLETED: STATUSSTRING.COMPLETED,
    CUSTOMER_CANCELLED: STATUSSTRING.CUSTOMER_CANCELLED,
    BUSINESS_CANCELLED: STATUSSTRING.BUSINESS_CANCELLED,
    REJECTED: STATUSSTRING.REJECTED,
    CANCEL_SHORT_NOTICE: 'CANCEL_SHORT_NOTICE' // this status is for portal user decide charge customer or not
  },


	PUBLISHED: STATUSSTRING.PUBLISHED,
    CLAIMED: STATUSSTRING.CLAIMED,

  }

 */
