import React, { Component } from 'react';
import { Text, Title } from 'common-components/typography';
import { Tabs } from 'antd';
import ServiceTimesheetPendingPanel from '../service-timesheet/ServiceTimesheetPendingPanel';
import ServiceTimesheetApprovedPanel from '../service-timesheet/ServiceTimesheetApprovedPanel';

class GroupServiceTimesheetPanel extends Component<any, any> {
  state = {
    selectedTab: 'PENDING'
  };

  private _changeTab = (event) => {
    this.setState({ selectedTab: event });
  };

  render() {
    return (
      <div className="pt-large pl-large width-full pb-x3-large">
        <div className="bg-white ph-large pt-large pb-x2-large shadow-container" style={{ borderRadius: '16px' }}>
          <div className="flex-row justify-between align-center">
            <div className="line-height-120">
              <Text weight="bold">Timesheets</Text> <br />
              <Text>Manage timesheets for your team members in this service</Text>
            </div>
          </div>

          <Tabs defaultActiveKey={'PENDING'} animated={true} onChange={this._changeTab} className="mt-medium">
            <Tabs.TabPane tab="Pending" key="PENDING" />
            <Tabs.TabPane tab="Approved" key="APPROVED" />
          </Tabs>

          {this.state.selectedTab === 'PENDING' ? (
            <ServiceTimesheetPendingPanel serviceId={this.props.serviceId} />
          ) : (
            <ServiceTimesheetApprovedPanel serviceId={this.props.serviceId} />
          )}
        </div>
      </div>
    );
  }
}

export default GroupServiceTimesheetPanel;
