import React, { Component } from 'react';
import { IRootState, state } from 'stores/rematch/root-store';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { HeaderFieldItem } from '../new-listings/components/details/HeaderFieldsItem';
import { BOOKINGLIST_FILTERCONFIGS } from '../new-listings/components/bookListFilterConfig';
import { timeZone } from 'src/interfaces/timezone-type';
import { Skeleton } from 'antd';
import { FieldItem } from '../new-listings/components/details/FieldItem';

interface IBulkRemoveTeamMemberModalProps {
  isOpen: boolean;
  onClose: any;
  topHeight: number;
  bookingList: any;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  displayTimezone: timeZone;
  isLoading: boolean;
  portalUser: typeof state.authStore.portalUser;

  onRemove: any;
}

class BookingBulkRemoveTeamMemberModal extends Component<IBulkRemoveTeamMemberModalProps> {
  state = {
    canManuallyClose: true,
    step: 1,
    removeSuccessfully: false
  };

  private _onRemoveTeamMembers = () => {
    if (this.props.onRemove()) this.setState({ step: 2, canManuallyClose: false, removeSuccessfully: true });
  };

  private _doClose = () => {
    this.props.onClose(this.state.removeSuccessfully);
  };

  private _reset = () => {
    this.setState({
      isLoading: false,
      step: 1
    });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.isOpen && !prevProps.isOpen) {
      this._reset();
    }
  };

  render() {
    const { bookingList } = this.props;
    const itemCount = bookingList.length;

    const currentFilterConfig = BOOKINGLIST_FILTERCONFIGS['REMOVE_TEAM_MEMBER'];
    const modalTitle = 'Remove team members';

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title={modalTitle}
        onClose={this._doClose}
        canCloseOutside={this.state.canManuallyClose}
      >
        {this.state.step === 1 && (
          <div>
            <div className="mb-medium">
              <Text>
                You are electing to remove the assigned teams member from the following{' '}
                <b>
                  {itemCount} booking{itemCount === 1 ? '' : 's'}
                </b>
              </Text>
            </div>

            <table className="booking-listing ph-none">
              <thead>
                <tr>
                  <HeaderFieldItem customFields={currentFilterConfig.customFields} topHeight={this.props.topHeight} />
                </tr>
              </thead>
              <tbody>
                {this.props.isLoading && (
                  <tr style={{ borderBottom: '0px solid !important' }}>
                    <td colSpan={7}>
                      <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                    </td>
                  </tr>
                )}
                {!_.isEmpty(bookingList) &&
                  _.map(bookingList, (bookingItem, index) => (
                    <tr key={index}>
                      {!_.isEmpty(currentFilterConfig.customFields) &&
                        _.map(currentFilterConfig.customFields, (fields, idx) => (
                          <FieldItem
                            bookingItem={bookingItem}
                            timezone={this.props.displayTimezone}
                            showTimezoneIndicator={
                              !this.props.displayTimezone &&
                              moment.tz(bookingItem.timezone).format('Z') !==
                                moment.tz(this.props.portalUser.timezone).format('Z')
                            }
                            fieldName={fields.field}
                            //openRecurringListModal={this.props.openRecurringListModal}
                            //setFilter={setFilter}
                            key={idx}
                          />
                        ))}
                    </tr>
                  ))}
              </tbody>
            </table>

            <ActionModalFooter align="right">
              <div className="flex-row align-center justify-end">
                <SecondaryButton size="large" className="mr-medium" onClick={this._doClose}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._onRemoveTeamMembers}>
                  Remove team member
                </PrimaryButton>
              </div>
            </ActionModalFooter>
          </div>
        )}

        {this.state.step === 2 && (
          <div>
            <Text>
              You have removed{' '}
              <b>
                {itemCount} team member{itemCount === 1 ? '' : 's'}
              </b>{' '}
              from{' '}
              <b>
                {itemCount} booking{itemCount === 1 ? '' : 's'}
              </b>
            </Text>
            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this._doClose} className="rounded-left">
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser
});

export default connect(mapState)(BookingBulkRemoveTeamMemberModal);
