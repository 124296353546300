import { INotification } from 'interfaces/notification-interfaces';

class NotificationUtils {
  static Navigate = (history, notification: INotification, parseData: boolean) => {
    const data = parseData ? JSON.parse(notification.data) : notification.data;
    // use route to navigate to target route
    switch (notification.route) {
      case 'BookingDetails':
        history.push(`/bookings/details/${data.bookingId}`, { refresh: true });
        break;

      case 'CustomerDetails':
        if (notification.subRoute === 'Documents') {
          history.push(`/customer/details/${data.customerUserId}`, { refresh: true, selectedTab: 'documents' });
        } else {
          history.push(`/customer/details/${data.customerUserId}`);
        }
        break;

      case 'WorkerDetails':
        if (notification.subRoute === 'Documents') {
          history.push(`/team/details/${data.supportWorkerId}`, { refresh: true, selectedTab: 'documents' });
        } else {
          history.push(`/team/details/${data.supportWorkerId}`, { refresh: true });
        }
        break;

      case 'SessionDetails':
        history.push(`/group-service/${data.serviceId}/session/details/${data.serviceDateTimeId}`, { refresh: true });
        break;

      case 'Sessions':
        history.push(`/group-service/sessions`, { refresh: true });
        break;

      default:
        break;
    }
  };
}

export default NotificationUtils;
