/* eslint-disable */
import apiClient from 'utilities/api-client';
import _ from 'lodash';
import { PaymentSourceType, UserPermissionRole } from 'utilities/enum-utils';
import Utils from 'utilities/Utils';

const companyStore = {
  state: {
    companyList: [],
    companyData: null,
    companyDataLite: null,
    ownerList: null,
    companyRegistrationDetails: null,
    temporaryRegistrationDetails: null,
    companyFundingAndPayment: null,
    companyFundingAndPaymentLite: null
  },
  reducers: {
    setCompanyData: (state, payload) => ({ ...state, companyData: payload }),
    setCompanyDataLite: (state, payload) => ({ ...state, companyDataLite: payload }),
    setOwnerList: (state, payload) => ({ ...state, ownerList: payload }),
    setCompanyRegistrationData: (state, payload) => ({ ...state, companyRegistrationDetails: payload }),
    setTemporaryRegistrationData: (state, payload) => ({ ...state, temporaryRegistrationDetails: payload }),
    setCompanyFundingAndPayment: (state, payload) => ({ ...state, companyFundingAndPayment: payload }),
    setCompanyFundingAndPaymentLite: (state, payload) => ({ ...state, companyFundingAndPaymentLite: payload }),
    setPlanManagementData: (state, payload) => ({
      ...state,
      companyFundingAndPayment: {
        ...state.companyFundingAndPayment,
        planManagement: {
          hasPlanManagement: payload.hasPlanManagement,
          planManagementProviderEmail: payload.planManagementProviderEmail,
          planManagementProviderNumber: payload.planManagementProviderNumber
        }
      }
    }),
    updateInvoiceConfigList: (state, payload) => {
      const paymentSources = _.map(state.companyFundingAndPayment.paymentSources, (funding) => {
        if (funding.paymentSourceType === PaymentSourceType.INVOICE) {
          return {
            ...funding,
            invoicePrefix: payload.invoicePrefix,
            paymentSourceConfiguration: { invoiceTerms: payload.invoiceTerms, invoiceDueDate: payload.invoiceDueDate }
          };
        } else {
          return { ...funding };
        }
      });
      return { ...state, companyFundingAndPayment: { ...state.companyFundingAndPayment, paymentSources } };
    },
    updateCompanyFundingPaymentSources: (state, payload) => {
      const paymentSources = _.map(state.companyFundingAndPayment.paymentSources, (funding) => {
        if (funding.paymentSourceType === PaymentSourceType.NDIS) {
          return {
            ...funding,
            paymentSourceConfiguration: {
              isTtpApproved: payload.isTtpApproved,
              ndisRegistrationNumber: payload.ndisRegistrationNumber
            }
          };
        } else if (funding.paymentSourceType === PaymentSourceType.VCP) {
          return {
            ...funding,
            paymentSourceConfiguration: {
              vcpEnabled: payload.vcpEnabled
            }
          };
        } else {
          return { ...funding };
        }
      });
      return { ...state, companyFundingAndPayment: { ...state.companyFundingAndPayment, paymentSources } };
    }
    // increment: (state, payload) => ({ ...state, currentCounter: state.currentCounter + payload })
    // setServices: (state, payload) => ({ ...state, services: payload })
  },
  effects: (dispatch) => ({
    async doFetchCompany() {
      try {
        const endpoint = 'api/portal/service-provider/view';
        const result = await apiClient.get(endpoint);
        if (result.data) {
          dispatch.companyStore.setCompanyData(result.data);
        }
      } catch (e) {
        throw e;
      }
      // dispatch.userStore.increment(payload);
      // console.log('fetching services...');
      // // Mock data
      // dispatch.servicesStore.setServices(mockServicesData);
      // console.log('services fetched.');
    },

    async doFetchCompanyFundingAndPayment() {
      try {
        const endpoint = '/api/portal/service-provider/payment-sources/list';
        const result = await apiClient.get(endpoint);
        if (result.data) {
          dispatch.companyStore.setCompanyFundingAndPayment(result.data);
          return result.data;
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchCompanyFundingAndPaymentLite() {
      try {
        const endpoint = '/api/portal/service-provider/payment-sources/lite';
        const result = await apiClient.get(endpoint);
        if (result.data) {
          dispatch.companyStore.setCompanyFundingAndPaymentLite(result.data);
          return result.data;
        }
      } catch (e) {
        throw e;
      }
    },

    async doFetchCompanyLite() {
      try {
        const endpoint = 'api/portal/service-provider/lite';
        const result = await apiClient.get(endpoint);
        if (result.data) {
          dispatch.companyStore.setCompanyDataLite(result.data);
        }
      } catch (e) {
        throw e;
      }
    },

    async getCompanyRegistrationDetails(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/business-registration-number/view';
        const result = await apiClient.post(endpoint, payload);
        payload.isLookingUp
          ? dispatch.companyStore.setTemporaryRegistrationData(result.data)
          : dispatch.companyStore.setCompanyRegistrationData(result.data);
      } catch (e) {
        payload.isLookingUp
          ? dispatch.companyStore.setTemporaryRegistrationData(null)
          : dispatch.companyStore.setCompanyRegistrationData(null);
        throw e;
      }
    },

    async doUpdateCompanyDetail(payload, rootState) {
      try {
        const endpoint = 'api/portal/service-provider/update';
        const result = await apiClient.put(endpoint, payload);

        const companyData = rootState.companyStore.companyData;
        let updatedData = { ...companyData, ...payload };
        dispatch.companyStore.setCompanyData(updatedData);
      } catch (e) {
        throw e;
      }
    },

    async doFetchOwnerList(payload, rootState) {
      try {
        const endpoint = '/api/portal/account/owners/list';
        const result = await apiClient.get(endpoint, payload);

        dispatch.companyStore.setOwnerList(result.data);
      } catch (e) {
        throw e;
      }
    },

    async doEditBusinessDetails(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/registered-business-details';
        await apiClient.put(endpoint, payload);
        const companyData = rootState.companyStore.companyData;
        let updatedData = { ...companyData, businessRegistrationNumber: payload.businessRegistrationNumber };
        dispatch.companyStore.setCompanyData(updatedData);
        dispatch.companyStore.getCompanyRegistrationDetails({
          businessRegistrationNumber: payload.businessRegistrationNumber,
          businessRegistrationNumberType: payload.businessRegistrationNumber.length === 9 ? 'ACR' : 'ABN',
          isLookingUp: false
        });
      } catch (e) {
        throw e;
      }
    },

    async doAssignNewPrimaryOwner(payload, rootState) {
      try {
        const endpoint = '/api/portal/account/primary-owners/switch';
        await apiClient.post(endpoint, payload);
        dispatch.authStore.setCurrentPortalUser({
          ...rootState.authStore.portalUser,
          permissions: {
            hasPortalAccess: true,
            hasWorkerAccess: true,
            permissionRoles: [
              {
                serviceId: null,
                permissionRole: UserPermissionRole.Owner,
                serviceDepartmentId: null
              }
            ]
          }
        });
      } catch (e) {
        throw e;
      }
    },

    async doEditDisplayName(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/display-name';
        await apiClient.put(endpoint, payload);
        let updatedData = { ...rootState.companyStore.companyData, serviceProviderName: payload.displayName };
        dispatch.companyStore.setCompanyData(updatedData);
      } catch (e) {
        throw e;
      }
    },

    async doEditCompanyAddress(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/primary-address';
        await apiClient.put(endpoint, payload);
        let updatedData = { ...rootState.companyStore.companyData, address: payload };
        dispatch.companyStore.setCompanyData(updatedData);
      } catch (e) {
        throw e;
      }
    },

    async doEditCompanyTimezone(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/timezone';
        await apiClient.put(endpoint, payload);
        let updatedData = { ...rootState.companyStore.companyData, timezone: payload.timezone };
        dispatch.companyStore.setCompanyData(updatedData);
      } catch (e) {
        throw e;
      }
    },

    async doEditInvoiceConfig(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/invoice-configurations';
        await apiClient.put(endpoint, payload);
        dispatch.companyStore.updateInvoiceConfigList(payload);
      } catch (e) {
        throw e;
      }
    },

    async doEditNDISConfig(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/ndis-configurations';
        await apiClient.put(endpoint, payload);
        payload.paymentSourceType = PaymentSourceType.NDIS;
        dispatch.companyStore.updateCompanyFundingPaymentSources(payload);
      } catch (e) {
        throw e;
      }
    },

    async doEditVCPConfig(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/vcp-configurations';
        const resp = await apiClient.put(endpoint, payload);
        if (Utils.isEmpty(resp.meta.errorCode)) {
          payload.paymentSourceType = PaymentSourceType.VCP;
          dispatch.companyStore.updateCompanyFundingPaymentSources(payload);
        }
        return resp;
      } catch (e) {
        throw e;
      }
    },

    async doUpdatePlanManagementConfigurations(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/plan-management-configurations';
        const resp = await apiClient.put(endpoint, payload);
        if (resp.status === 200) {
          dispatch.companyStore.setPlanManagementData(payload);
        }
      } catch (e) {
        throw e;
      }
    },

    async doUpdateReplyEmailConfig(payload, rootState) {
      try {
        const endpoint = '/api/portal/service-provider/reply-email-address-config';
        const resp = await apiClient.put(endpoint, payload);
        if (resp.status === 200) {
          dispatch.companyStore.setCompanyData({
            ...rootState.companyStore.companyData,
            replyEmailConfig: {
              replyEmailName: payload.replyEmailName,
              replyEmailAddress: payload.replyEmailAddress
            }
          });
        }
      } catch (e) {
        throw e;
      }
    }
  })
};

export default companyStore;
