import { Popover2 } from '@blueprintjs/popover2';
import { Icon, Tooltip } from 'antd';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { IconButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import React from 'react';
import { BookingStatus, PaymentStatus, TransportAttendanceType } from 'utilities/enum-utils';

function renderButtons({
  status,
  shiftSlotStatus,
  paymentStatus,
  attendanceType,
  onAcceptBooking,
  onDeclineBooking,
  onStartBooking,
  onConfirmBooking,
  onFinishBooking,
  onCancelBooking,
  onWaiveBooking,
  onReinstateBooking,
  onChangeToCustomerCancel,
  onRevertToConfirm,
  onChargeCancellationFee,
  onApprove,
  onRevokePayment,
  onRemoveCancelCharge,
  onBusinessCancel,
  hasBillingErrors,
}) {
  switch (status) {
    case BookingStatus.PENDING:
      return (
        <>
          <PrimaryButton size="large" className="mr-medium" onClick={onAcceptBooking}>
            Accept booking
          </PrimaryButton>
          <PrimaryButton color="red" size="large" className="mr-medium" onClick={onDeclineBooking}>
            Decline booking
          </PrimaryButton>
        </>
      );

    case BookingStatus.ACCEPTED:
      return (
        <>
          <PrimaryButton size="large" className="mr-medium" onClick={onConfirmBooking}>
            Confirm booking
          </PrimaryButton>
          <SecondaryButton color="red" size="large" className="mr-medium" onClick={onCancelBooking}>
            Cancel booking
          </SecondaryButton>
        </>
      );

    case BookingStatus.CONFIRMED:
      return (
        <>
          <PrimaryButton
            size="large"
            onClick={onStartBooking}
            disabled={
              (attendanceType === TransportAttendanceType.PRE_SESSION ||
                attendanceType === TransportAttendanceType.POST_SESSION) &&
              (shiftSlotStatus === 'UNASSIGNED' || shiftSlotStatus === 'PENDING')
            }
          >
            Start booking
          </PrimaryButton>
          {(attendanceType === TransportAttendanceType.PRE_SESSION ||
            attendanceType === TransportAttendanceType.POST_SESSION) &&
            (shiftSlotStatus === 'UNASSIGNED' || shiftSlotStatus === 'PENDING') && (
              <Tooltip title="A team member must be assigned to this shift in order to start it.">
                <Icon type="question-circle" className="text-size-x-large text-color-blue ml-medium" />
              </Tooltip>
            )}
          <SecondaryButton color="red" size="large" className="ml-medium" onClick={onCancelBooking}>
            Cancel booking
          </SecondaryButton>
        </>
      );

    case BookingStatus.INPROGRESS:
      return (
        <>
          <PrimaryButton size="large" className="mr-medium" onClick={onFinishBooking}>
            Finish booking
          </PrimaryButton>
          <Popover2
            usePortal={false}
            position={'top'}
            content={
              <ActionMenu>
                <ActionMenuItem text={'Revert to confirmed'} onClick={onRevertToConfirm} />
              </ActionMenu>
            }
          >
            <IconButton
              icon="ellipsis"
              size="large"
              iconColor={'blue-action'}
              bordered={true}
              color={'white'}
              className="border-blue-action"
            />
          </Popover2>
        </>
      );

    case BookingStatus.COMPLETED:
      return (
        <>
          {paymentStatus === PaymentStatus.REQUIRES_APPROVAL && (
            <>
              <PrimaryButton size="large" className="mr-medium" onClick={onApprove} disabled={hasBillingErrors}>
                Approve for payment
              </PrimaryButton>
              <SecondaryButton size="large" className="mr-medium" onClick={onWaiveBooking} disabled={hasBillingErrors}>
                Waive payment
              </SecondaryButton>
              <Popover2
                usePortal={false}
                position={'top'}
                content={
                  <ActionMenu>
                    <ActionMenuItem text={'Undo completion'} onClick={onRevertToConfirm} />
                  </ActionMenu>
                }
              >
                <IconButton
                  icon="ellipsis"
                  size="large"
                  iconColor={'blue-action'}
                  bordered={true}
                  color={'white'}
                  className="border-blue-action"
                />
              </Popover2>
              {hasBillingErrors && (
                <Tooltip title="You cannot approve/waive the booking until you have resolved the billing issues.">
                  <Icon type="question-circle" className="text-size-x-large text-color-blue ml-medium" />
                </Tooltip>
              )}
            </>
          )}
          {(paymentStatus === PaymentStatus.SEND_TO_FINANCE || paymentStatus === PaymentStatus.WAIVED) && (
            <>
              <SecondaryButton size="large" className="mr-medium" onClick={onRevokePayment}>
                Revoke approval
              </SecondaryButton>
            </>
          )}
        </>
      );

    case BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE:
      return (
        <>
          <Popover2
            usePortal={false}
            position={'top'}
            content={
              <ActionMenu>
                <ActionMenuItem text={'Charge cancellation fee'} onClick={onChargeCancellationFee} />
                <ActionMenuItem
                  text={
                    <Text>
                      Change to <b>'Cancelled by business'</b>
                    </Text>
                  }
                  onClick={onBusinessCancel}
                />
                <ActionMenuItem text={'Re-instate booking'} onClick={onReinstateBooking} />
              </ActionMenu>
            }
          >
            <SecondaryButton size="large" className="mr-medium">
              Cancellation options
            </SecondaryButton>
          </Popover2>
        </>
      );

    case BookingStatus.CUSTOMER_CANCELLED_WITH_FEE:
      return (
        <>
          {paymentStatus !== PaymentStatus.WAIVED && (
            <>
              <PrimaryButton size="large" className="mr-medium" onClick={onApprove}>
                Approve for payment
              </PrimaryButton>
              <SecondaryButton size="large" className="mr-medium" onClick={onWaiveBooking}>
                Waive payment
              </SecondaryButton>
            </>
          )}
          <Popover2
            usePortal={false}
            position={'top'}
            content={
              <ActionMenu>
                <ActionMenuItem text={'Remove cancellation fee'} onClick={onRemoveCancelCharge} />
                <ActionMenuItem
                  text={
                    <Text>
                      Change to <b>'Cancelled by business'</b>
                    </Text>
                  }
                  onClick={onBusinessCancel}
                />
                <ActionMenuItem text={'Re-instate booking'} onClick={onReinstateBooking} />
              </ActionMenu>
            }
          >
            {paymentStatus !== PaymentStatus.WAIVED ? (
              <IconButton
                icon="ellipsis"
                size="large"
                iconColor={'blue-action'}
                bordered={true}
                color={'white'}
                className="border-blue-action"
              />
            ) : (
              <SecondaryButton size="large" className="mr-medium">
                Cancellation options
              </SecondaryButton>
            )}
          </Popover2>
        </>
      );

    case BookingStatus.BUSINESS_CANCELLED:
      return (
        <>
          <Popover2
            usePortal={false}
            position={'top'}
            content={
              <ActionMenu>
                <ActionMenuItem
                  onClick={onChangeToCustomerCancel}
                  text={
                    <Text>
                      Change to <b>'Cancelled by customer'</b>
                    </Text>
                  }
                />
                <ActionMenuItem text={'Re-instate booking'} onClick={onReinstateBooking} />
              </ActionMenu>
            }
          >
            <SecondaryButton size="large" className="mr-medium">
              Cancellation options
            </SecondaryButton>
          </Popover2>
        </>
      );

    case BookingStatus.REJECTED:
      return (
        <>
          <PrimaryButton size="large" className="mr-medium" onClick={onAcceptBooking}>
            Accept booking
          </PrimaryButton>
        </>
      );

    case BookingStatus.ARCHIVED:
      return (
        <>
          <Text color="secondary">
            <Icon type="close-circle" className="text-color-tertiary mr-x-small" /> No actions available.
          </Text>
        </>
      );

    default:
      return (
        <>
          <Text color="secondary">
            <Icon type="close-circle" className="text-color-tertiary mr-x-small" /> No actions available.
          </Text>
        </>
      );
  }
}

export function BookingActionButtons({
  booking,
  onAcceptBooking,
  onDeclineBooking,
  onStartBooking,
  onConfirmBooking,
  onFinishBooking,
  onCancelBooking,
  onWaiveBooking,
  onReinstateBooking,
  onChangeToCustomerCancel,
  onRevertToConfirm,
  onChargeCancellationFee,
  onApprove,
  onRevokePayment,
  onRemoveCancelCharge,
  onBusinessCancel,
  hasBillingErrors,
}) {
  const { status, shiftSlotStatus, paymentStatus, attendanceType } = booking;

  return (
    <div className="bg-quaternary p-medium rounded-big">
      {renderButtons({
        status,
        shiftSlotStatus,
        paymentStatus,
        attendanceType,
        onAcceptBooking,
        onDeclineBooking,
        onStartBooking,
        onConfirmBooking,
        onFinishBooking,
        onCancelBooking,
        onWaiveBooking,
        onReinstateBooking,
        onChangeToCustomerCancel,
        onRevertToConfirm,
        onChargeCancellationFee,
        onApprove,
        onRevokePayment,
        onRemoveCancelCharge,
        onBusinessCancel,
        hasBillingErrors,
      })}
    </div>
  );
}
