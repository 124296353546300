import React, { Component } from 'react';
import CenteredLayout from 'layouts/CenteredLayout';
import _ from 'lodash';
import { Paragraph, Title } from 'common-components/typography';
import DepartmentCard from 'views/account-management/components/DepartmentCard';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import CreateServiceDepartmentModal from 'views/account-management/components/CreateServiceDepartmentModal';
import EditServiceDepartmentModal from 'views/account-management/components/EditServiceDepartmentModal';
import ArchiveServiceDepartmentModal from 'views/account-management/components/ArchiveServiceDepartmentModal';
import ViewServicesInDepartmentModal from 'views/account-management/components/ViewServicesInDepartmentModal';
import ViewArchivedServiceDepartmentsModal from 'views/account-management/components/ViewArchivedServiceDepartmentsModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import * as H from 'history';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { Col, Skeleton } from 'antd';
import PermissionUtils from 'utilities/permission-utils';

interface IServiceDepartmentsViewProps {
  doFetchServiceDepartments: typeof dispatch.servicesStore.doFetchServiceDepartments;
  serviceDepartmentList: typeof state.servicesStore.serviceDepartmentList;
  portalUser: typeof state.authStore.portalUser;
  history: H.History;
}

interface IServiceDepartmentsViewState {
  isLoading: boolean;
  isCreateDepartmentOpen: boolean;
  isEditDepartmentOpen: boolean;
  isArchiveDepartmentOpen: boolean;
  isViewServicesInDepartmentOpen: boolean;
  isViewArchivedDepartmentsOpen: boolean;
  isTestOpen: boolean;
  selectedServiceDepartment: any;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
}

class ServiceDepartmentsView extends Component<IServiceDepartmentsViewProps, IServiceDepartmentsViewState> {
  state = {
    isLoading: false,
    isCreateDepartmentOpen: false,
    isEditDepartmentOpen: false,
    isArchiveDepartmentOpen: false,
    isViewServicesInDepartmentOpen: false,
    isViewArchivedDepartmentsOpen: false,
    isTestOpen: false,
    selectedServiceDepartment: null,
    page: 1,
    pageSize: 5,
    pageTimestamp: new Date()
  };

  private _goToLandingPage = () => {
    this.props.history.push('/account/landing');
  };

  private _openCreateDepartment = () => {
    this.setState({ isCreateDepartmentOpen: true });
  };

  private _closeCreateDepartment = () => {
    this.setState({ isCreateDepartmentOpen: false });
  };

  private _openEditDepartment = (serviceDepartment) => {
    this.setState({ isEditDepartmentOpen: true, selectedServiceDepartment: serviceDepartment });
  };

  private _closeEditDepartment = () => {
    this.setState({ isEditDepartmentOpen: false });
  };

  private _openArchiveDepartment = (serviceDepartment) => {
    this.setState({ isArchiveDepartmentOpen: true, selectedServiceDepartment: serviceDepartment });
  };

  private _closeArchiveDepartment = () => {
    this.setState({ isArchiveDepartmentOpen: false });
  };

  private _openViewServicesInDepartment = (serviceDepartment) => {
    this.setState({ isViewServicesInDepartmentOpen: true, selectedServiceDepartment: serviceDepartment });
  };

  private _closeViewServicesInDepartment = () => {
    this.setState({ isViewServicesInDepartmentOpen: false });
  };

  private _openViewArchivedServiceDepartments = () => {
    this.setState({ isViewArchivedDepartmentsOpen: true });
  };

  private _closeViewArchivedServiceDepartments = () => {
    this.setState({ isViewArchivedDepartmentsOpen: false });
  };

  private _fetchMoreServiceDepartments = async () => {
    const { doFetchServiceDepartments } = this.props;
    await this.setState({ isLoading: true, page: this.state.page + 1 });
    await doFetchServiceDepartments({
      isArchived: false,
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp
    });
    this.setState({ isLoading: false });
  };

  private _resetServiceDepartmentList = async () => {
    this.setState(
      { page: 1 },
      async () =>
        await this.props.doFetchServiceDepartments({
          isArchived: false,
          page: this.state.page,
          pageSize: this.state.pageSize,
          pageTimestamp: this.state.pageTimestamp
        })
    );
  };

  componentDidMount = async () => {
    await this.props.doFetchServiceDepartments({
      isArchived: false,
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp
    });
  };

  render() {
    const { serviceDepartmentList } = this.props;

    const canEditServiceDepartments = PermissionUtils.validatePermission(
      'EditServiceDepartment',
      this.props.portalUser.permissions.permissionRoles
    );
    const canViewServiceDepartments = PermissionUtils.validatePermission(
      'ViewServiceDepartment',
      this.props.portalUser.permissions.permissionRoles
    );

    return (
      <CenteredLayout backgroundColor="white" bordered={true}>
        <CreateServiceDepartmentModal
          isOpen={this.state.isCreateDepartmentOpen}
          onClose={this._closeCreateDepartment}
          serviceDepartmentList={serviceDepartmentList}
          resetServiceDepartmentList={this._resetServiceDepartmentList}
        />
        <EditServiceDepartmentModal
          isOpen={this.state.isEditDepartmentOpen}
          onClose={this._closeEditDepartment}
          selectedServiceDepartment={this.state.selectedServiceDepartment}
          serviceDepartmentList={serviceDepartmentList}
        />
        <ArchiveServiceDepartmentModal
          isOpen={this.state.isArchiveDepartmentOpen}
          onClose={this._closeArchiveDepartment}
          selectedServiceDepartment={this.state.selectedServiceDepartment}
          resetServiceDepartmentList={this._resetServiceDepartmentList}
        />
        <ViewServicesInDepartmentModal
          isOpen={this.state.isViewServicesInDepartmentOpen}
          onClose={this._closeViewServicesInDepartment}
          selectedServiceDepartment={this.state.selectedServiceDepartment}
        />
        <ViewArchivedServiceDepartmentsModal
          isOpen={this.state.isViewArchivedDepartmentsOpen}
          onClose={this._closeViewArchivedServiceDepartments}
        />
        <div className="mt-x-large mb-medium">
          <Title level={3} weight="regular" className="mv-none">
            <span className={'cursor-pointer text-color-blue-action'} onClick={this._goToLandingPage}>
              {'Account >'}
            </span>{' '}
            Service Departments
          </Title>
        </div>

        <div className="mb-medium">
          <Title level={4}>Service departments</Title>
          <div id="overflow-container" style={{ maxHeight: '400px', overflow: 'auto' }}>
            {!serviceDepartmentList ? (
              'Retrieving service departments...'
            ) : (
              <InfiniteScrollLoading
                hasMore={serviceDepartmentList.length >= this.state.page * this.state.pageSize}
                loadingElementId={'overflow-container'}
                loadMore={this._fetchMoreServiceDepartments}
                loaderColSpan={7}
                loadingOffSet={60}
              >
                {_.map(serviceDepartmentList, (serviceDepartment) => {
                  return (
                    <DepartmentCard
                      serviceDepartment={serviceDepartment}
                      canArchive={
                        serviceDepartmentList.length > 1 &&
                        PermissionUtils.validatePermission(
                          'ArchiveServiceDepartment',
                          this.props.portalUser.permissions.permissionRoles
                        )
                      }
                      editAction={this._openEditDepartment}
                      archiveAction={this._openArchiveDepartment}
                      viewAction={this._openViewServicesInDepartment}
                      canEditServiceDepartments={canEditServiceDepartments}
                      canViewServiceDepartments={canViewServiceDepartments}
                    />
                  );
                })}
                {this.state.isLoading && (
                  <Skeleton paragraph={{ rows: 1, width: '50%' }} active={true} className="anim-slide-left" />
                )}
              </InfiniteScrollLoading>
            )}
          </div>
        </div>
        <div className="mb-large">
          <HyperlinkButton onClick={this._openViewArchivedServiceDepartments}>
            View archived service departments
          </HyperlinkButton>
        </div>
        {canEditServiceDepartments && (
          <PrimaryButton size={'large'} className="mb-x-large" onClick={this._openCreateDepartment}>
            Create a service department
          </PrimaryButton>
        )}
        <Paragraph>
          As an owner you can create service departments to suit your companies organisational structure.
        </Paragraph>
        <Paragraph>
          When you create a service you will be asked to place it under one of the existing service departments your
          business has in GoodHuman and you can assign permissions based on these departments.
        </Paragraph>
        <Paragraph>All services must be assigned to a department</Paragraph>
        <br />
        {/*<Paragraph>*/}
        {/*  Visit the <HyperlinkButton>Help Center</HyperlinkButton> to learn more.*/}
        {/*</Paragraph>*/}
      </CenteredLayout>
    );
  }
}

const mapState = (state: IRootState) => ({
  serviceDepartmentList: state.servicesStore.serviceDepartmentList,
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServiceDepartments: dispatch.servicesStore.doFetchServiceDepartments
});

export default connect(mapState, mapDispatch)(ServiceDepartmentsView);
