import React, { Component } from 'react';
import _ from 'lodash';
import { Icon } from 'antd';
import CommonUtils from 'utilities/common-utils';
import { PaymentMethodTag } from 'common-components/tags';
import { GhostButton, HyperlinkButton } from 'common-components/buttons';
import { dispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { Menu, MenuItem, Popover } from '@blueprintjs/core';
import { Text } from 'common-components/typography';
import { connect } from 'react-redux';
import PermissionUtils from 'utilities/permission-utils';
import { ServiceType } from 'utilities/enum-utils';
import moment from 'moment';

interface IPaymentItemRow {
  lineItem?: any;
  customerId?: any;
  history?: any;
  openActionModal?: any;
  errorTypes?: any;
  setSelectedBillingLineItem?: typeof dispatch.billingsStore.setSelectedBillingLineItem;
  portalUser?: typeof state.authStore.portalUser;
}

const claimType = {
  STANDARD_SERVICE: 'STD',
  CANCELLATION: 'CANC',
  TRAVEL: 'TRAN',
  REPORT_WRITING: 'REPW',
  NO_FACE_TO_FACE: 'NF2F',
  TELEHEALTH_SUPPORTS: 'THLT',
  IRREGULAR_SIL_SUPPORTS: 'IRSS',
};

const claimTypeOptions = [
  { value: claimType.STANDARD_SERVICE, label: 'Standard Service' },
  { value: claimType.CANCELLATION, label: 'Cancellation' },
  { value: claimType.TRAVEL, label: 'Travel' },
  { value: claimType.REPORT_WRITING, label: 'Report Writing' },
  { value: claimType.NO_FACE_TO_FACE, label: 'Non Face-to-face' },
  { value: claimType.TELEHEALTH_SUPPORTS, label: 'Telehealth supports' },
  { value: claimType.IRREGULAR_SIL_SUPPORTS, label: 'Irregular SIL supports' },
];

const unit = {
  HOURS: 'H',
  EACH: 'EA',
};

const unitOptions = [{ value: unit.HOURS, label: 'hours' }, { value: unit.EACH, label: 'each' }];

class PayWaivedItemRow extends Component<IPaymentItemRow> {
  _checkbox = null;
  state = {
    bookingBillingLineItemId: null,
    showSubLines: false,
    openAction: false,
    attendanceId: null,
  };

  _onClickBooking = () => {
    const { history } = this.props;
    history.push(`/bookings/details/${this.state.attendanceId}`);
  };

  _onClickCustomer = () => {
    const { history } = this.props;
    history.push(`/customer/details/${this.props.customerId}`);
  };

  componentDidMount = () => {
    const { lineItem } = this.props;

    if (!_.isEmpty(lineItem)) {
      const { bookingBillingLineItemId, attendanceId } = lineItem;
      this.setState({ bookingBillingLineItemId, attendanceId });
    }
  };

  _getErrorMessage = () => {
    const { errorTypes, lineItem } = this.props;

    let paymentErrorArray = [];

    if (
      _.find(errorTypes, (o) => {
        return o === 'NDIA';
      })
    ) {
      paymentErrorArray.push('No NDIS Number set.');
    }
    if (lineItem.paymentMethod === 'PLAN') {
      paymentErrorArray.push('No email set for the Plan Manager.');
    }
    if (lineItem.paymentMethod === 'SELF') {
      paymentErrorArray.push('No email set for the Customer.');
    }

    return _.map(paymentErrorArray).join(' ');
  };

  render() {
    const { lineItem, openActionModal, portalUser } = this.props;

    if (_.isEmpty(lineItem)) {
      return <></>;
    }

    const {
      billingTotal,
      selected,
      paymentMethod,
      supportItemNumber,
      referenceId,
      unitPrice,
      claimType,
      unit,
      isError,
      qty,
      serviceType,
    } = lineItem;

    const displayBorder = !isError ? { borderBottom: '1px solid #ccc' } : {};
    return (
      <>
        <tr style={displayBorder}>
          <td className="fluid-cell check-all" />
          {paymentMethod && paymentMethod !== '' && (
            <td className="fluid-cell">
              <PaymentMethodTag paymentMethod={paymentMethod} className="mr-medium" rounded={true} size="small" />{' '}
            </td>
          )}
          <td colSpan={paymentMethod && paymentMethod !== '' ? 1 : 2} className="ellipsis">
            {supportItemNumber}
          </td>
          <td className="ellipsis">
            {claimType ? claimTypeOptions.find((item) => item.value === claimType).label : 'Standard Service'}
          </td>
          <td className="ellipsis">
            {serviceType !== ServiceType.GROUP ? (
              <HyperlinkButton onClick={this._onClickBooking}>{referenceId}</HyperlinkButton>
            ) : (
              referenceId
            )}
          </td>
          <td className="ellipsis">
            {CommonUtils.formatQuantityDisplay(qty)} ({unitOptions.find((item) => item.value === unit).label}) x{' '}
            {CommonUtils.formatPrice(unitPrice)}
          </td>
          <td className="fluid-cell">
            <div className="text-align-right">{CommonUtils.formatPrice(billingTotal)}</div>
          </td>
          <td className="fluid-cell">
            <Popover
              content={
                <Menu>
                  {PermissionUtils.validatePermission(
                    'UnwaivePaymentBillingLineItem',
                    portalUser.permissions.permissionRoles,
                  ) && (
                    <MenuItem
                      text={
                        <Text weight="bold" color="green">
                          Unwaive
                        </Text>
                      }
                      className="hover-bg-green-lightest"
                      onClick={() => openActionModal({ action: 'UNWAIVE', additionalData: lineItem })}
                    />
                  )}
                  <MenuItem
                    text={
                      <Text weight="bold" color="green">
                        View waive reason
                      </Text>
                    }
                    className="hover-bg-green-lightest"
                    onClick={() => openActionModal({ action: 'VIEW', additionalData: lineItem })}
                  />
                  {PermissionUtils.validatePermission(
                    'DeletePaymentBillingLineItem',
                    portalUser.permissions.permissionRoles,
                  ) && (
                    <MenuItem
                      text={
                        <Text weight="bold" color="red">
                          Delete
                        </Text>
                      }
                      className="hover-bg-red-lightest"
                      onClick={() => openActionModal({ action: 'DELETE', additionalData: lineItem })}
                    />
                  )}
                </Menu>
              }
              position={'bottom-right'}
            >
              <Icon type="ellipsis" className="text-size-x4-large" />
            </Popover>
          </td>
        </tr>
        {isError && (
          <tr style={{ borderBottom: '1px solid #ccc' }}>
            <td />
            <td colSpan={7} className="text-color-red text-weight-bold pt-none">
              <Icon type="warning" className="mr-x-small" />
              {this._getErrorMessage()}{' '}
              {PermissionUtils.validatePermission('ViewCustomerProfile', portalUser.permissions.permissionRoles) && (
                <HyperlinkButton onClick={this._onClickCustomer}>View Customer Details</HyperlinkButton>
              )}
            </td>
          </tr>
        )}
      </>
    );
  }
}

const mapState = (state: IRootState) => ({ portalUser: state.authStore.portalUser });

export default connect(
  mapState,
  null,
)(PayWaivedItemRow);
