/* eslint-disable */
import React, { Component } from 'react';

import { Button, Col, Divider, Dropdown, Icon, Menu, message, Popover, Row, Tabs, Tag, Timeline } from 'antd';

import { Text, Title } from 'common-components/typography';

import styled from 'styled-components';

import QueueAnim from 'rc-queue-anim';

import { times } from 'lodash';

import themeVariables from 'theme/theme-variables';

import { Colors } from '@blueprintjs/core';

import ColumnedLayout, { ColumnPanel } from 'layouts/ColumnedLayout';

const { TabPane } = Tabs;

const BookingCard: any = styled.div`
  background-color: white;
  border: 1px ${themeVariables.lightGreyVariant2} solid;
  padding: 16px 24px;
  border-radius: 4px;
  margin-bottom: 16px;

  &:hover {
    background-color: ${themeVariables.lightGreyVariant2};
  }
`;

const BookingRowItem = ({ onClick }) => {
  return (
    <BookingCard onClick={onClick}>
      <Row type={'flex'} justify={'space-between'}>
        {/* Date Column*/}
        <Col span={7}>
          {/*<Icon type={'clock-circle'} style={{ color: themeVariables.textColorSecondary }} />*/}
          <Text strong className={'small-text'} style={{ paddingLeft: '4px' }}>
            3.30pm - 5.30pm
          </Text>
        </Col>

        {/* Client column */}
        <Col span={12}>
          <Text strong className={'small-text'}>
            Jonathan Reeves Murray
          </Text>
          <br />
          <Text type={'secondary'} className={'small-text'}>
            Youth Jobs Now
          </Text>{' '}
          <br />
          <br />
          <Text className={'small-text'} type={'secondary'}>
            Assigned to <Text>Jir Wong</Text>
          </Text>
        </Col>

        {/*  Status column*/}
        <Col span={4} style={{ textAlign: 'right' }}>
          <Tag className={'small-text'} color={'green'}>
            Pending
          </Tag>
        </Col>
      </Row>
      {/*<div style={{ textAlign: 'right' }}>*/}
      {/*  <Button.Group>*/}
      {/*    <Button type={'primary'}>Message</Button>*/}
      {/*    <Button>Confirm</Button>*/}
      {/*    <Button>...</Button>*/}
      {/*  </Button.Group>*/}
      {/*</div>*/}
    </BookingCard>
  );
};

// Booking Listings Components
const BookingListingItem = ({ handleHideMenu }) => (
  <>
    <div
      className={'ant-tag'}
      style={{
        fontSize: '15px',
        // marginTop: '8px',
        marginBottom: '8px',
        backgroundColor: Colors.LIGHT_GRAY5,
        border: '0px',
        padding: '8px'
      }}
    >
      <Text style={{ color: Colors.DARK_GRAY3 }}>
        <Icon type={'calendar'} style={{ paddingRight: '8px' }} />
        Today - 5 Bookings
      </Text>
    </div>

    <Row>
      {/* Date Column */}
      <Col span={3}>
        <div style={{ padding: '12px 16px' }}>
          <ul style={{ textAlign: 'center', margin: '0px 0px 0px 0px', padding: '0px' }}>
            <li style={{ fontSize: '28px', listStyleType: 'none', fontWeight: 300, lineHeight: '28px' }}>05</li>
            <li style={{ fontSize: '14px', listStyleType: 'none', fontWeight: 300 }}>MAY</li>
          </ul>
        </div>
      </Col>

      {/* Booking Rows*/}
      <Col span={21}>
        {/* Row 1*/}
        <BookingRowItem key={'item1'} onClick={handleHideMenu} />
        <BookingRowItem key={'item2'} onClick={handleHideMenu} />
        <BookingRowItem key={'item3'} onClick={handleHideMenu} />
        <BookingRowItem key={'item4'} onClick={handleHideMenu} />
      </Col>
    </Row>
    <Divider style={{ marginTop: '12px', marginBottom: '12px' }} />
  </>
);

const BookingDetailItem = ({ label, children }) => (
  <>
    <Col span={9} style={{ textAlign: 'right', marginBottom: '16px' }}>
      <Text type={'secondary'} style={{ fontWeight: 500, fontSize: '13px' }}>
        {label}
      </Text>
    </Col>
    <Col span={13}>{children}</Col>
  </>
);

const BookingDetailsSection = () => (
  <QueueAnim>
    <Row type={'flex'} style={{ marginTop: '32px' }} justify={'space-between'} key={'details'}>
      <BookingDetailItem label={'Client'} key={'client'}>
        <Popover
          placement={'right'}
          trigger={'click'}
          content={
            <div>
              <Title level={4}>Jon Murray</Title>
              <Text>Email - jonmurray@gmail.com</Text>
              <br />
              <a href={'#'}>View Profile</a>
            </div>
          }
        >
          <a href="#" onClick={(e) => e.preventDefault()} style={{ color: themeVariables.black }}>
            Jon Murray
          </a>
        </Popover>
      </BookingDetailItem>

      <BookingDetailItem label={'Service'}>
        <a href="#" onClick={(e) => e.preventDefault()} style={{ color: themeVariables.black }}>
          Youth Jobs Now
        </a>
      </BookingDetailItem>

      <BookingDetailItem label={'Support Worker'}>
        <a href="#" onClick={(e) => e.preventDefault()} style={{ color: themeVariables.black }}>
          Jir Wong
        </a>
      </BookingDetailItem>

      <BookingDetailItem label={'Status'}>
        <Tag>Pending</Tag>
        <a>?</a>
      </BookingDetailItem>

      <BookingDetailItem label={'Location'}>
        14 Ellis Street <br />
        South Yarra <br />
        3132 Melbourne <br />
        <br />
        <Popover placement={'right'} content={'Google Maps'} trigger={'click'}>
          <a href="#">View on Map</a>
        </Popover>
      </BookingDetailItem>
    </Row>
  </QueueAnim>
);

// End Booking Details Components

class BookingLandingView extends Component<any, any> {
  state = {
    showFilter: false,
    showBookingList: true
  };

  _handleMenuClick = () => {
    message.info('thesih');
  };

  _toggleFilter = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  render = () => {
    const menu = (
      <Menu>
        <Menu.Item onClick={this._handleMenuClick} disabled>
          <Icon type={'user'} />
          Assign Team member
        </Menu.Item>
        <Menu.Item onClick={this._handleMenuClick}>
          <Icon type={'check'} />
          Confirm Booking
        </Menu.Item>
        <Menu.Item onClick={this._handleMenuClick}>
          <Icon type={'close'} />
          Cancel Booking
        </Menu.Item>
        <Menu.Item onClick={this._handleMenuClick}>
          <Icon type={'mail'} />
          Message Client
        </Menu.Item>
      </Menu>
    );

    const { showBookingList } = this.state;

    return (
      <ColumnedLayout bordered>
        {/* NOTE Left Panel - Booking List */}
        <ColumnPanel
          span={showBookingList ? 10 : 1}
          backgroundColor={showBookingList ? themeVariables.backgroundColor : Colors.LIGHT_GRAY3}
          overflow={showBookingList ? 'auto' : 'hidden'}
          padding={'16px 24px'}
          transition={'all 0.2s'}
        >
          <div style={{ display: showBookingList ? 'block' : 'none' }}>
            <Row style={{ marginBottom: '16px' }}>
              <Col span={9}>
                <div style={{ marginTop: '2px' }}>
                  <Title level={2} style={{ marginBottom: '0px', color: Colors.DARK_GRAY2 }}>
                    Bookings List
                  </Title>
                </div>
              </Col>
              <Col span={15} style={{ textAlign: 'right' }}>
                <Button type={'default'} style={{ marginRight: '8px' }}>
                  <Icon type={'filter'} />
                  <span>Filter</span>
                </Button>
                <Button type={'primary'} icon={'plus'}>
                  <span>Add New Booking</span>
                </Button>
              </Col>
            </Row>

            {/*<div>*/}
            {/*  <Text type={'secondary'}>Showing for date period of 01 MAY 2019 to 08 MAY 2019</Text>*/}
            {/*</div>*/}

            <Divider />

            <div style={{ padding: '0px' }}>
              {times(5, (i) => (
                <BookingListingItem
                  key={'bookingitem' + i}
                  handleHideMenu={() => this.setState({ showBookingList: false })}
                />
              ))}
            </div>
          </div>
        </ColumnPanel>

        {/* NOTE Right Panel - Booking Details */}
        <ColumnPanel span={showBookingList ? 14 : 23} overflow={'auto'} transition={'all 0.2s'} padding={'16px 24px'}>
          {/* NOTE Top Actions Section */}
          <Row type={'flex'} justify={'space-between'} align={'bottom'}>
            <Col span={10}>
              <Title level={2} style={{ color: Colors.BLACK, marginBottom: '0px' }}>
                <Button
                  type={showBookingList ? 'default' : 'ghost'}
                  onClick={() => this.setState({ showBookingList: !this.state.showBookingList })}
                  style={{ marginRight: '16px' }}
                >
                  {showBookingList ? 'Hide' : 'Back to '} List <Icon type={showBookingList ? 'left' : 'right'} />
                </Button>
                {/*Booking Details*/}
              </Title>
            </Col>
            <Col span={14} style={{ textAlign: 'right' }}>
              <Button.Group>
                <Button type={'primary'} disabled>
                  <Icon type={'check'} />
                  Confirm Booking
                </Button>
                <Button type={'danger'} disabled>
                  <Icon type={'close'} />
                  Cancel Booking
                </Button>
                <Dropdown overlay={menu}>
                  <Button>
                    {/*<Icon type="ellipsis" />*/}
                    Actions
                    <Icon type="more" />
                  </Button>
                </Dropdown>
              </Button.Group>
            </Col>
          </Row>

          <Divider style={{ backgroundColor: 'transparent' }} />

          {/* NOTE Booking Details Section */}
          <Row style={{ marginTop: '8px' }}>
            <Col span={10} style={{ padding: '8px 48px 16px 24px' }}>
              {/* Date */}
              <div style={{ marginTop: '8px' }}>
                <Title level={3}>Wednesday, 31st May 2019</Title>
              </div>
              <div style={{ paddingTop: '0px' }}>
                <Title level={3} style={{ fontWeight: 500, color: themeVariables.textColorSecondary }}>
                  11.30am - 12.30pm
                </Title>
              </div>
              <BookingDetailsSection />
            </Col>
            <Col span={14} style={{ paddingRight: '0px' }}>
              <Tabs defaultActiveKey={'activity'} animated={false}>
                <TabPane key={'activity'} tab={'Activity'}>
                  <div style={{ padding: '12px 16px' }}>
                    <Timeline>
                      <QueueAnim>
                        <Timeline.Item
                          key={'tl1'}
                          dot={
                            <Icon
                              type={'calendar'}
                              style={{
                                backgroundColor: Colors.GOLD5,
                                padding: '4px',
                                color: 'white',
                                borderRadius: '4px',
                                fontSize: '12px'
                              }}
                            />
                          }
                        >
                          <div style={{ paddingLeft: '8px' }}>
                            <Text strong>New Booking</Text>
                            <br />

                            <Text>Jon Murray has made a new booking.</Text>
                            <br />
                            <br />

                            <Text type={'secondary'} style={{ fontSize: '12px' }}>
                              {' '}
                              TUE 3 MAY 2019, 3.30 PM
                            </Text>
                          </div>
                        </Timeline.Item>
                        <Timeline.Item
                          key={'tl2'}
                          dot={
                            <Icon
                              type={'clock-circle'}
                              style={{
                                backgroundColor: Colors.GREEN5,
                                padding: '4px',
                                color: 'white',
                                borderRadius: '4px',
                                fontSize: '12px'
                              }}
                            />
                          }
                        >
                          <div style={{ paddingLeft: '8px' }}>
                            <Text strong>
                              Booking Accepted <br />
                            </Text>
                            <Text>
                              Jir Wong has accepted this booking.
                              <br />
                              <br />
                              {/*<br />*/}
                            </Text>
                            <Text type={'secondary'} style={{ fontSize: '12px' }}>
                              TUE 3 MAY 2019, 3.30 PM
                            </Text>
                          </div>
                        </Timeline.Item>
                        <Timeline.Item
                          key={'tl3'}
                          dot={
                            <Icon
                              type={'clock-circle'}
                              style={{
                                backgroundColor: Colors.FOREST5,
                                padding: '4px',
                                color: 'white',
                                borderRadius: '4px',
                                fontSize: '12px'
                              }}
                            />
                          }
                        >
                          <div style={{ paddingLeft: '8px' }}>
                            <Text strong>
                              Booking Checked In <br />
                            </Text>
                            <Text>
                              Jir Wong has checked into this booking.
                              <br />
                              <br />
                              {/*<br />*/}
                            </Text>
                            <Text type={'secondary'} style={{ fontSize: '12px' }}>
                              TUE 3 MAY 2019, 3.30 PM
                            </Text>
                          </div>
                        </Timeline.Item>
                        <Timeline.Item
                          key={'tl4'}
                          color={'green'}
                          dot={
                            <Icon
                              type={'check'}
                              style={{
                                backgroundColor: Colors.BLUE5,
                                padding: '4px',
                                color: 'white',
                                borderRadius: '4px',
                                fontSize: '12px'
                              }}
                            />
                          }
                        >
                          <div style={{ paddingLeft: '8px' }}>
                            <Text strong>
                              Booking completed <br />
                            </Text>
                            <Text>
                              Jonno has closed this booking and paid.
                              <br />
                              <br />
                              {/*<br />*/}
                            </Text>
                            <Text type={'secondary'} style={{ fontSize: '12px' }}>
                              TUE 3 MAY 2019, 3.30 PM
                            </Text>
                          </div>
                        </Timeline.Item>
                      </QueueAnim>
                    </Timeline>
                  </div>
                </TabPane>
                <TabPane key={'contactdetails'} tab={'Contact Details'}>
                  <div style={{ padding: '12px 16px' }}>Contact Details</div>
                </TabPane>
                <TabPane key={'notes'} tab={'Notes'}>
                  <div style={{ padding: '12px 16px' }}>Notes</div>
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </ColumnPanel>
      </ColumnedLayout>
    );
  };
}

export default BookingLandingView;
