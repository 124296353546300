import React, { Component } from 'react';
import _ from 'lodash';
import { Form, Row } from 'antd';
import { Text } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import * as H from 'history';
import { ArchiveDebtorFrom } from 'utilities/enum-utils';

interface IUnarchiveDebtorModalProps {
  debtor: any;
  history: H.History;
  isOpen: boolean;
  unarchiveDebtorFrom: string;
  closeUnarchiveModal: () => void;
  doUnarchiveDebtor: typeof dispatch.accountStore.doUnarchiveDebtor;
}

interface IUnarchiveDebtorModalState {
  isUnarchiving: boolean;
}

class UnarchiveDebtorModal extends Component<IUnarchiveDebtorModalProps, IUnarchiveDebtorModalState> {
  state = {
    isUnarchiving: false
  };

  private closeModal = () => {
    this.setState({ isUnarchiving: false });
    this.props.closeUnarchiveModal();
  };

  private _onUnarchive = async () => {
    const { doUnarchiveDebtor, debtor, history, unarchiveDebtorFrom } = this.props;
    this.setState({ isUnarchiving: true });
    const result = await doUnarchiveDebtor({ debtorId: debtor.debtorId, unarchiveDebtorFrom: unarchiveDebtorFrom });
    if (result) {
      this.setState({ isUnarchiving: false });
      if (unarchiveDebtorFrom === ArchiveDebtorFrom.LISTING) {
        history.push(`/account/debtor-management/${debtor.debtorId}`);
      }
      this.closeModal();
    }
  };

  render() {
    const { isUnarchiving } = this.state;
    const { isOpen } = this.props;
    return (
      <ActionModal title={'Unarchive debtor'} isOpen={isOpen} onClose={() => this.closeModal()} width="small">
        <Row>
          <Text>Are you sure you want to unarchive this debtor?</Text>
        </Row>
        <br />
        <Row>
          <Text>This will allow you to link this debtor to your customers.</Text>
        </Row>
        <ActionModalFooter>
          <GhostButton className="mr-medium" size="large" onClick={() => this.closeModal()}>
            Cancel
          </GhostButton>
          <PrimaryButton loading={isUnarchiving} size="large" onClick={this._onUnarchive}>
            Unarchive
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUnarchiveDebtor: dispatch.accountStore.doUnarchiveDebtor
});

export default connect(
  mapState,
  mapDispatch
)(UnarchiveDebtorModal);
