import { Paragraph, SubTitle, Text } from 'common-components/typography';
import React, { Component } from 'react';
import _ from 'lodash';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import AddEditScheduleToQuoteModal from 'views/customers/details/tabs-panel/new-service-agreements/common-modals/AddEditScheduleToQuoteModal';
import { Col, Empty, Icon, Row, Select } from 'antd';
import CommonUtils from 'utilities/common-utils';
import { IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import RadioGroup from 'antd/lib/radio/group';
import Radio from 'antd/es/radio';
import Form, { FormComponentProps } from 'antd/es/form';
import AddManualQuoteModal from 'views/customers/details/tabs-panel/new-service-agreements/common-modals/AddManualQuoteModal';
import ViewQuotationServiceView from './ViewQuotationServiceView';
import AddEditGroupServiceSessionsModal from 'views/customers/details/tabs-panel/new-service-agreements/common-modals/AddEditGroupServiceSessionsModal';
import { ServiceType } from 'utilities/enum-utils';

interface IViewEditQuoteViewProps extends FormComponentProps {
  displayMode: 'VIEW' | 'ADD' | 'EDIT';
  deleteServiceSchedule?: (serviceId) => void;
  portalUser: typeof state.authStore.portalUser;
  availableServices?: any;
  quotations?: any;
  addEditQuotation?: (quotation) => void;
  maxEndDateTime: Date;
  minStartDateTime: Date;
  paymentSourceType: string;
  isNewServiceAgreement: boolean;
  userServiceAgreementId?: string;
}

interface IViewEditQuoteViewState {
  isAddModalOpen: boolean;
  isViewModalOpen: boolean;
  isEdit: boolean;
  selectedQuotation: any;
  openServices: Array<String>;
  isDeleteModalOpen: boolean;
  isOpenSelectModel: boolean;
  isOpenManualQuote: boolean;
  isOpenGroupServiceAutoQuote: boolean;
  selectedServiceId: string;
}

const QuoteEmptyState = () => (
  <div className="flex-1 bg-white mt-x2-large align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No schedule added.
    </Text>
    <Text color="secondary">Press 'Add a service schedule' to add a schedule.</Text>
  </div>
);

class ViewEditQuoteView extends Component<IViewEditQuoteViewProps, IViewEditQuoteViewState> {
  state = {
    isAddModalOpen: false,
    isViewModalOpen: false,
    isEdit: false,
    selectedQuotation: null,
    openServices: [],
    isDeleteModalOpen: false,
    isOpenSelectModel: false,
    isOpenManualQuote: false,
    isOpenGroupServiceAutoQuote: false,
    selectedServiceId: null
  };

  private _onOpenAddModal = () => this.setState({ isEdit: false, selectedQuotation: null, isAddModalOpen: true });
  private _onCloseAddModal = () => this.setState({ isAddModalOpen: false, isEdit: false });

  private _onOpenDeleteModal = (quotation) => this.setState({ selectedQuotation: quotation, isDeleteModalOpen: true });
  private _onCloseDeleteModal = () => this.setState({ selectedQuotation: null, isDeleteModalOpen: false });

  private _onOpenSelectModal = (isEdit?: boolean) =>
    this.setState({
      isOpenSelectModel: true,
      isEdit,
      selectedQuotation: isEdit ? this.state.selectedQuotation : null,
      selectedServiceId: isEdit ? this.state.selectedServiceId : null
    });
  private _onCloseSelectModal = () => this.setState({ isOpenSelectModel: false });

  private _onCloseManualQuotationModal = () => this.setState({ isOpenManualQuote: false });
  private _onOpenManualQuotationModal = () =>
    this.setState({ isEdit: false, selectedQuotation: null, isOpenManualQuote: true });

  private _onCloseGroupServiceAutoModal = () => this.setState({ isOpenGroupServiceAutoQuote: false });
  private _onOpenGroupServiceAutoModal = () =>
    this.setState({ isEdit: false, selectedQuotation: null, isOpenGroupServiceAutoQuote: true });

  private _onSubmitSelect = () => {
    const { form } = this.props;
    const selectedService = _.find(
      this.props.availableServices,
      (service) => service.serviceId === this.state.selectedServiceId
    );
    form.validateFieldsAndScroll((err, value) => {
      if (!err) {
        switch (value.quoteMethod) {
          case 1:
            this._onCloseSelectModal();
            this._onOpenManualQuotationModal();
            break;
          case 2:
            this._onCloseSelectModal();
            selectedService.serviceType === ServiceType.GROUP
              ? this._onOpenGroupServiceAutoModal()
              : this._onOpenAddModal();
            break;
        }
      }
    });
  };

  private _openEditServiceSchedule = (quotation) => {
    if (quotation.isManualQuote) {
      this.setState({
        isEdit: true,
        selectedQuotation: quotation,
        selectedServiceId: quotation.serviceId,
        isOpenManualQuote: true
      });
    } else if (quotation.isGroupService || quotation.serviceType === ServiceType.GROUP) {
      this.setState({
        isEdit: true,
        selectedQuotation: quotation,
        selectedServiceId: quotation.serviceId,
        isOpenGroupServiceAutoQuote: true
      });
    } else {
      this.setState({
        isEdit: true,
        selectedQuotation: quotation,
        selectedServiceId: quotation.serviceId,
        isAddModalOpen: true
      });
    }
  };

  private _selectServiceId = (selectedServiceId) => {
    this.setState({ selectedServiceId });
  };

  private _deleteServiceSchedule = (serviceId) => {
    this.props.deleteServiceSchedule(serviceId);
    this._onCloseDeleteModal();
  };

  render() {
    const { displayMode, availableServices, quotations, form } = this.props;
    const { getFieldDecorator } = form;
    const { selectedQuotation, isOpenSelectModel, selectedServiceId } = this.state;

    const selectedService = _.find(this.props.availableServices, (service) => service.serviceId === selectedServiceId);
    const noItems = _.isEmpty(quotations);
    const totalQuoteAmount = _.sumBy(quotations, (item: any) => Number(item.quoteAmount));
    const availableServiceFiltered = _.filter(
      availableServices,
      (availableService) => !_.find(quotations, (service) => service.serviceId === availableService.serviceId)
    );

    return (
      <>
        <ActionModal
          isOpen={this.state.isDeleteModalOpen}
          onClose={this._onCloseDeleteModal}
          title={<>Delete service quote</>}
          showCloseButton={true}
        >
          <Paragraph className={'mb-medium'}>Are you sure you want to delete this service quote?</Paragraph>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseDeleteModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              size="large"
              color={'red'}
              onClick={() => this._deleteServiceSchedule(selectedQuotation.serviceId)}
            >
              Delete
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <AddEditScheduleToQuoteModal
          isOpen={this.state.isAddModalOpen}
          onClose={this._onCloseAddModal}
          isEdit={this.state.isEdit}
          quotation={selectedQuotation ? selectedQuotation : null}
          selectedService={selectedService}
          addEditQuotation={this.props.addEditQuotation}
          maxEndDateTime={this.props.maxEndDateTime}
          minStartDateTime={this.props.minStartDateTime}
          paymentSourceType={this.props.paymentSourceType}
          isNewServiceAgreement={this.props.isNewServiceAgreement}
          userServiceAgreementId={this.props.userServiceAgreementId}
        />
        <AddEditGroupServiceSessionsModal
          isOpen={this.state.isOpenGroupServiceAutoQuote}
          onClose={this._onCloseGroupServiceAutoModal}
          isEdit={this.state.isEdit}
          quotation={selectedQuotation ? selectedQuotation : null}
          selectedService={selectedService}
          addEditQuotation={this.props.addEditQuotation}
          isNewServiceAgreement={this.props.isNewServiceAgreement}
          userServiceAgreementId={this.props.userServiceAgreementId}
        />
        <AddManualQuoteModal
          isOpen={this.state.isOpenManualQuote}
          isEdit={this.state.isEdit}
          selectedService={selectedService}
          onAddEditQuotation={this.props.addEditQuotation}
          onClose={this._onCloseManualQuotationModal}
          quotation={selectedQuotation ? selectedQuotation : null}
          paymentSourceType={this.props.paymentSourceType}
        />
        <ActionModal
          isOpen={isOpenSelectModel}
          title={'Quote type'}
          onClose={this._onCloseSelectModal}
          canCloseOutside={false}
        >
          <Paragraph className={'mb-medium'}>Select how you would like to create the quote</Paragraph>
          <Form.Item>
            {getFieldDecorator('serviceId', {
              initialValue: selectedServiceId ? selectedServiceId : undefined,
              rules: [{ required: true, message: 'please select a service' }]
            })(
              <Select
                placeholder="Select a Service"
                size={'large'}
                className={'width-1/2'}
                disabled={this.state.isEdit}
                onChange={this._selectServiceId}
              >
                {_.map(availableServices, (service) => {
                  return <Select.Option value={service.serviceId}>{service.serviceName}</Select.Option>;
                })}
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('quoteMethod', {
              rules: [{ required: true, message: 'please select create quotation method' }]
            })(
              <RadioGroup>
                <Radio
                  style={{
                    display: 'block',
                    height: '30px',
                    lineHeight: '30px'
                  }}
                  value={1}
                >
                  Create quote manually
                </Radio>
                <Radio
                  style={{
                    display: 'block',
                    height: '30px',
                    lineHeight: '30px'
                  }}
                  value={2}
                >
                  Build a schedule to automatically calculate quote amount
                </Radio>
              </RadioGroup>
            )}
          </Form.Item>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseSelectModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._onSubmitSelect}>
              Next
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
        <div className="anim-slide-left">
          <div className="mt-large mb-x2-large">
            {/* Content here*/}
            {displayMode !== 'VIEW' && availableServiceFiltered.length > 0 && (
              <div className="mb-medium">
                <HyperlinkButton onClick={() => this._onOpenSelectModal(false)}>
                  <Icon type="plus" />
                  &nbsp;Add service schedule
                </HyperlinkButton>
              </div>
            )}
            <Row className={'mt-large ph-medium'}>
              <Col span={1} />
              <Col span={11} className={'pl-medium'}>
                <SubTitle>Service/Schedule</SubTitle>
              </Col>
              <Col span={9} className={'text-align-right'}>
                <SubTitle>Quote amount</SubTitle>
              </Col>
              <Col span={2} className={'pr-medium'} />
            </Row>
            {noItems ? (
              <QuoteEmptyState />
            ) : (
              <>
                {_.map(quotations, (quotation) => {
                  return (
                    <ViewQuotationServiceView
                      quotation={quotation}
                      displayMode={displayMode}
                      onOpenDeleteModal={this._onOpenDeleteModal}
                      openEditServiceSchedule={this._openEditServiceSchedule}
                    />
                  );
                })}
                <Row className={'mt-large'}>
                  <Col span={20} className={'text-align-right'}>
                    <Text weight={'bold'}>Total quote amount</Text>
                  </Col>
                  <Col span={4} className={'text-align-right'}>
                    {CommonUtils.formatPrice(totalQuoteAmount)}
                  </Col>
                </Row>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser
});

export default connect(
  mapState,
  null
)(Form.create<IViewEditQuoteViewProps>()(ViewEditQuoteView));
