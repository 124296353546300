import React, { Component } from 'react';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import BreadcrumbNav from 'common-components/navigation/BreadcrumbNav';
import * as H from 'history';
import { Text } from 'common-components/typography';
import { Skeleton } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { ICrumb } from 'interfaces/common-interface';
import CenteredLayout from 'layouts/CenteredLayout';
import GroupServiceHeader from 'views/group-services/service-details/components/GroupServiceHeader';
import GroupServiceNavigationPanel from 'views/group-services/service-details/components/GroupServiceNavigationPanel';
import GroupServiceViewCustomerPanel from './components/GroupServiceViewCustomerPanel';
import GroupServiceTeamMemberPanel from './components/GroupServiceTeamMemberPanel';
import GroupServiceSessionPanel from './components/GroupServiceSessionPanel';
import GroupServiceSettingsPanel from './components/GroupServiceSettingsPanel';
import GroupServiceDetailPanel from './components/GroupServiceDetailPanel';
import AddNewSessionModal from 'views/group-services/service-details/Create/AddNewSessionModal';
import GroupServicePricingPanel from './components/GroupServicePricingPanel';
import GroupServiceOverviewPanel from 'views/group-services/service-details/panels/GroupServiceOverviewPanel';
import AddCustomerToSessionModal from 'views/group-services/service-details/customer-schedule/AddCustomerToSessionModal';
import '../css/group-service-listing.css';
import GroupServiceTimesheetPanel from './components/GroupServiceTimesheetPanel';
import { ProgressBar } from '@blueprintjs/core';

interface IServiceDetailsUrlParams {
  serviceId: string;
}

interface IGroupServiceDetailsViewProps extends RouteComponentProps<IServiceDetailsUrlParams> {
  history: H.History;
  doFetchGroupServiceOverview: typeof dispatch.groupServiceStore.doFetchGroupServiceOverview;
  doFetchGroupBookingOverviewWarning: typeof dispatch.groupServiceStore.doFetchGroupBookingOverviewWarning;
  doFetchGroupServiceDetail: typeof dispatch.servicesStore.doFetchGroupServiceDetail;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  selectedServiceDetail: typeof state.servicesStore.selectedServiceDetail;
  selectedTabKey: typeof state.groupServiceStore.selectedTabKey;
  setSelectedTabKey: typeof dispatch.groupServiceStore.setSelectedTabKey;
  setGroupServiceSessionFilter: typeof dispatch.servicesStore.setGroupServiceSessionFilter;
}

interface IGroupServiceDetailsViewState {
  isCreateSessionModalOpen: boolean;
  iAddCustomerToSessionsModalOpen: boolean;
  isSuccessAddedCustomerToSession: boolean;
  isNeedFetchCustomerList: boolean;
  selectedSessionFilter: string;
  isLoading: boolean;
}

class GroupServiceDetailsView extends Component<IGroupServiceDetailsViewProps, IGroupServiceDetailsViewState> {
  state = {
    isCreateSessionModalOpen: false,
    iAddCustomerToSessionsModalOpen: false,
    isSuccessAddedCustomerToSession: false,
    isNeedFetchCustomerList: false,
    selectedSessionFilter: null,
    isLoading: false
  };

  private _onChangeTab = (selectedTab) => {
    if (!this.state.isLoading) {
      this.props.setSelectedTabKey(selectedTab);
    }
  };

  private _setIsSuccessAddedCustomerToSession = () => {
    this.setState({ isSuccessAddedCustomerToSession: true });
  };

  private _clearIsNeedFetchCustomerList = () => {
    this.setState({ isNeedFetchCustomerList: false });
  };

  private _openCreateSessionModal = () => {
    this.setState({ isCreateSessionModalOpen: true });
  };

  private _closeCreateSessionModal = () => {
    this.setState({ isCreateSessionModalOpen: false });
  };

  private _openAddCustomerToASessionsModal = () => {
    this.setState({ iAddCustomerToSessionsModalOpen: true });
  };

  private _closeAddCustomerToASessionsModal = () => {
    this.setState({ iAddCustomerToSessionsModalOpen: false });
  };

  private _onGoToTab = (selectedTab, filter = null) => {
    this.setState({ selectedSessionFilter: filter });
    this.props.setSelectedTabKey(selectedTab);
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    await Promise.all([
      this.props.doFetchGroupServiceOverview({ serviceId: this.props.match.params.serviceId }),
      this.props.doFetchGroupBookingOverviewWarning({ serviceId: this.props.match.params.serviceId }),
      this.props.doFetchGroupServiceDetail({ serviceId: this.props.match.params.serviceId })
    ]);

    if (!this.props.selectedTabKey) {
      this.props.setSelectedTabKey('OVERVIEW');
    }

    this.setState({ isLoading: false });
  };

  componentDidUpdate = (prevProps, prevState) => {
    // After user closes the `Add customer to session modal`, if a customer was successfully added, then new customer list needs to be fetched.
    // Doing this here because if the fetch takes place right after the `doAddCustomerToSession` operation finishes,
    // Sometimes the new data are not in the response.
    // Fetching data after the modal is closed should be safe.
    if (
      !this.state.iAddCustomerToSessionsModalOpen &&
      this.state.iAddCustomerToSessionsModalOpen !== prevState.iAddCustomerToSessionsModalOpen &&
      this.state.isSuccessAddedCustomerToSession
    ) {
      this.setState({ isSuccessAddedCustomerToSession: false, isNeedFetchCustomerList: true });
    }
    if (
      prevProps.selectedGroupService &&
      this.props.selectedGroupService &&
      prevProps.selectedGroupService.serviceId !== this.props.selectedGroupService.serviceId
    ) {
      this.props.setSelectedTabKey('OVERVIEW');
      this.props.setGroupServiceSessionFilter(null);
    }
  };

  render = () => {
    const { history, selectedGroupService, selectedTabKey } = this.props;
    const { selectedSessionFilter, isLoading } = this.state;

    const crumbs: ICrumb[] = [
      {
        title: 'Services',
        target: '/services'
      },
      {
        title: selectedGroupService ? selectedGroupService.serviceName : '-'
      }
    ];

    if (isLoading) {
      return (
        <div className="view-container">
          <div className="pb-medium">
            <div className="pb-medium">
              <Text>Fetching service details...</Text>
            </div>
            <ProgressBar />
          </div>
          <Skeleton loading={this.state.isLoading} />
        </div>
      );
    }

    return (
      <>
        {/* Main container */}
        <div style={{ overflow: 'auto', height: 'calc(100vh - 64px)' }} id="content-container">
          <BreadcrumbNav history={history} icon={'home'} theme={'filled'} crumbs={crumbs} />

          <div className="width-full">
            {/* Header */}
            <GroupServiceHeader />

            {/* Content */}
            <div className="flex-row justify-center height-full bg-quaternary">
              <div className="width-full" style={{ maxWidth: '1200px' }}>
                <div className={'flex-row'}>
                  <GroupServiceNavigationPanel changeSelectedTab={this._onChangeTab} />
                  {selectedTabKey === 'TEAM_MEMBERS' && (
                    <GroupServiceTeamMemberPanel serviceId={this.props.match.params.serviceId} history={history} />
                  )}
                  {selectedTabKey === 'OVERVIEW' && (
                    <GroupServiceOverviewPanel
                      serviceId={this.props.match.params.serviceId}
                      onOpenCreateSessionModal={this._openCreateSessionModal}
                      onAddCustomerToSession={this._openAddCustomerToASessionsModal}
                      goToTab={this._onGoToTab}
                    />
                  )}
                  {selectedTabKey === 'SETTINGS' && (
                    <GroupServiceSettingsPanel serviceId={this.props.match.params.serviceId} />
                  )}
                  {selectedTabKey === 'DETAILS' && (
                    <GroupServiceDetailPanel serviceId={this.props.match.params.serviceId} />
                  )}
                  {selectedTabKey === 'SESSIONS' && (
                    <GroupServiceSessionPanel
                      selectedServiceId={this.props.match.params.serviceId}
                      onOpenCreateSessionModal={this._openCreateSessionModal}
                      selectedFilter={selectedSessionFilter}
                      history={history}
                    />
                  )}
                  {selectedTabKey === 'PRICING' && (
                    <GroupServicePricingPanel serviceId={this.props.match.params.serviceId} />
                  )}
                  {selectedTabKey === 'CUSTOMERS' && (
                    <GroupServiceViewCustomerPanel
                      history={history}
                      onOpenAddCustomerModal={this._openAddCustomerToASessionsModal}
                      serviceId={this.props.match.params.serviceId}
                      isNeedFetchCustomerList={this.state.isNeedFetchCustomerList}
                      clearIsNeedFetchCustomerList={this._clearIsNeedFetchCustomerList}
                    />
                  )}

                  {selectedTabKey === 'TIMESHEET' && (
                    <GroupServiceTimesheetPanel history={history} serviceId={this.props.match.params.serviceId} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modals */}
        <AddNewSessionModal
          isOpen={this.state.isCreateSessionModalOpen}
          history={history}
          closeAddSessionModal={this._closeCreateSessionModal}
        />
        <AddCustomerToSessionModal
          isOpen={this.state.iAddCustomerToSessionsModalOpen}
          history={history}
          closeCustomerToSessionModal={this._closeAddCustomerToASessionsModal}
          setIsSuccessAddedCustomerToSession={this._setIsSuccessAddedCustomerToSession}
        />
      </>
    );
  };
}

const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService,
  selectedServiceDetail: state.servicesStore.selectedServiceDetail,
  selectedTabKey: state.groupServiceStore.selectedTabKey
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupServiceOverview: dispatch.groupServiceStore.doFetchGroupServiceOverview,
  doFetchGroupBookingOverviewWarning: dispatch.groupServiceStore.doFetchGroupBookingOverviewWarning,
  doFetchGroupServiceDetail: dispatch.servicesStore.doFetchGroupServiceDetail,
  setSelectedTabKey: dispatch.groupServiceStore.setSelectedTabKey,
  setGroupServiceSessionFilter: dispatch.servicesStore.setGroupServiceSessionFilter
});

export default connect(
  mapState,
  mapDispatch
)(GroupServiceDetailsView);
