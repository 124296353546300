import React, { Component } from 'react';
import _ from 'lodash';
import Title from 'antd/lib/typography/Title';
import { Col, Row } from 'antd';
import { Paragraph, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import { IGroupServiceSession } from 'interfaces/service-interfaces';
import AssignTeamMemberSessionListPanel from './AssignTeamMemberSessionListPanel';

interface ISelectSessionsStepPanelProps {
  onPreviousStep: () => void;
  onNextStep: () => void;
  onSetSelectedSessions: (v: IGroupServiceSession[]) => void;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  groupServiceSessions: typeof state.groupServiceStore.groupServiceSessions;
  doFetchGroupServiceSessions: typeof dispatch.groupServiceStore.doFetchGroupServiceSessions;
  setGroupServiceSessions: typeof dispatch.groupServiceStore.setGroupServiceSessions;
}

interface ISelectSessionsStepPanelState {
  isLoading: boolean;
  isNoSessionSelectedError: boolean;
  groupServiceSessions: typeof state.servicesStore.groupServiceSessions;
  selectedSessions: typeof state.servicesStore.groupServiceSessions;
}

class SelectSessionsStepPanel extends Component<ISelectSessionsStepPanelProps, ISelectSessionsStepPanelState> {
  state = {
    isLoading: false,
    isNoSessionSelectedError: false,
    groupServiceSessions: this.props.groupServiceSessions ? this.props.groupServiceSessions : [],
    selectedSessions: []
  };

  private _onSelect = (session) => {
    const { selectedSessions } = this.state;
    let newSelectedSession = selectedSessions;
    if (
      _.find(selectedSessions, (selectedSession) => selectedSession.serviceDateTimeId === session.serviceDateTimeId)
    ) {
      newSelectedSession = _.filter(
        selectedSessions,
        (selectedSession) => selectedSession.serviceDateTimeId !== session.serviceDateTimeId
      );
    } else {
      newSelectedSession.push(session);
    }
    this.setState({ selectedSessions: newSelectedSession, isNoSessionSelectedError: false });
  };

  private _onChangePeriodFilter = () => {};

  private _goToNext = () => {
    if (this.state.selectedSessions.length === 0) {
      this.setState({ isNoSessionSelectedError: true });
      return;
    }

    this.props.onSetSelectedSessions(this.state.selectedSessions);
    this.props.onNextStep();
  };

  private _loadSessions = async () => {
    const { doFetchGroupServiceSessions, selectedGroupService, setGroupServiceSessions } = this.props;
    const { serviceId } = selectedGroupService;

    setGroupServiceSessions([]);
    this.setState({ isLoading: true });
    await doFetchGroupServiceSessions({ serviceId });
    this.setState({ isLoading: false });
  };

  componentDidMount = async () => {
    if (this.props.selectedGroupService) {
      await this._loadSessions();
    }
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props.groupServiceSessions !== prevProps.groupServiceSessions) {
      this.setState({ groupServiceSessions: this.props.groupServiceSessions, selectedSessions: [] });
    }
  };

  render() {
    const { selectedGroupService } = this.props;
    const { isLoading, isNoSessionSelectedError, groupServiceSessions, selectedSessions } = this.state;

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={3}>Select which sessions you want to add team members to</Title>
              <Paragraph>You can select as many sessions as you would like.</Paragraph>

              <Paragraph>
                Sessions are selected first so we can display team member availability to you in the next step.
              </Paragraph>
              <Paragraph>Visit the Help Center to learn more.</Paragraph>
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white rounded-big ph-large pt-large pb-x2-large" style={{ minWidth: '250px' }}>
              <Title level={4}>Select sessions for assigning team members</Title>
              <Paragraph className="mv-large">
                Please select the sessions that you wish to assign team members to.
              </Paragraph>
              {isNoSessionSelectedError && <Text color={'red-dark'}>Please select at least one session.</Text>}
              <AssignTeamMemberSessionListPanel
                sessions={_.map(groupServiceSessions, (session) => {
                  const isSelected =
                    selectedSessions.findIndex((s) => s.serviceDateTimeId === session.serviceDateTimeId) >= 0;
                  return {
                    ...session,
                    isSelected
                  };
                })}
                onSelect={this._onSelect}
                timezone={selectedGroupService.timezone}
                onChangeFilter={this._onChangePeriodFilter}
                isLoading={isLoading}
              />
            </div>

            <ActionModalFooter align="right" className="mt-small mb-x2-large pr-large">
              <SecondaryButton size="large" className="mr-medium" onClick={this.props.onPreviousStep}>
                Back
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._goToNext}>
                Select sessions
              </PrimaryButton>
            </ActionModalFooter>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceSessions: state.groupServiceStore.groupServiceSessions
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchGroupServiceSessions: dispatch.groupServiceStore.doFetchGroupServiceSessions,
  setGroupServiceSessions: dispatch.groupServiceStore.setGroupServiceSessions
});

export default connect(
  mapState,
  mapDispatch
)(SelectSessionsStepPanel);
