import React, { Component } from 'react';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/es/form';
import { Col, DatePicker, Form, Icon, notification, Row } from 'antd';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { Spinner } from '@blueprintjs/core';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import moment from 'moment';
import _ from 'lodash';

interface IFundEditDatesPanelProps extends FormComponentProps {
  closeEditDateModal: () => void;
  packageId: string;
  selectedCustomer: any;
  startDate: any;
  reviewDate: any;
  doUpdateFundingPackageDates: typeof dispatch.customersStore.doUpdateFundingPackageDates;
}

interface IFundEditDatesPanelState {
  isLoading: boolean;
  startDate: any;
  reviewDate: any;
  rowID: number;
  isSaving: boolean;
  isCancelActionModalOpen: boolean;
  isDeleteActionModalOpen: boolean;
  warnings: Array<string>;
}

class FundEditDatesPanel extends Component<IFundEditDatesPanelProps, IFundEditDatesPanelState> {
  state = {
    isLoading: false,
    rowID: -1,
    startDate: this.props.startDate,
    reviewDate: this.props.reviewDate,
    isSaving: false,
    isCancelActionModalOpen: false,
    isDeleteActionModalOpen: false,
    warnings: []
  };

  private _closeCancelModalWithAction = () => {
    if (this.state.startDate !== this.props.startDate || this.state.reviewDate !== this.props.reviewDate) {
      this.setState({ isCancelActionModalOpen: true });
    } else {
      this.props.closeEditDateModal();
    }
  };

  private _closeCancelActionModal = () => {
    this.setState({ isCancelActionModalOpen: false });
  };

  private _handleDateData = (event, date, name) => {
    if (name === 'startDate') {
      this.setState(
        {
          startDate: event.format('YYYY-MM-DD')
        },
        () => this._warningMessages()
      );
    } else {
      this.setState(
        {
          reviewDate: event.format('YYYY-MM-DD')
        },
        () => this._warningMessages()
      );
    }
  };

  private _onSave = async () => {
    const { selectedCustomer, packageId, doUpdateFundingPackageDates, form } = this.props;
    let isFormValid = true;

    form.validateFields(async (err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      let payload = {
        userId: selectedCustomer.userId,
        fundingPackageId: packageId,
        startDate: this.state.startDate,
        expiredDate: this.state.reviewDate
      };
      try {
        this.setState({ isSaving: true });
        await doUpdateFundingPackageDates(payload);
        this.setState({ isSaving: false });
        this.props.closeEditDateModal();
        notification.success({ message: 'Dates updated successfully' });
      } catch (e) {
        this.setState({ isSaving: false });
        notification.error({ message: 'Oops, something went wrong, please try again!' });
      }
    }
  };

  private _warningMessages = () => {
    const { selectedCustomer } = this.props;
    const { startDate, reviewDate } = this.state;
    let warnings = [];

    const fundPackages = _.filter(selectedCustomer.funding.NDISFundingPackages, (fundPackage) => {
      return fundPackage.fundingPackageId !== this.props.packageId;
    });

    // Search for the latest finished package before the new startDate
    const latestPreviousPackage = _.find(
      _.orderBy(fundPackages, 'expiredDate', 'desc'),
      (fundPackage) =>
        moment(fundPackage.expiredDate).endOf('day') <
        moment(startDate)
          .add(-1, 'day')
          .endOf('day')
    );

    // If there is a package before the new start date and there is no package that would cover the difference
    if (
      latestPreviousPackage &&
      !_.find(
        fundPackages,
        (fundPackage) =>
          moment(fundPackage.startDate).endOf('day') < moment(startDate).endOf('day') &&
          moment(fundPackage.expiredDate).endOf('day') >= moment(startDate).endOf('day') &&
          fundPackage.status !== 'EXPIRED'
      )
    ) {
      warnings.push(
        `The dates selected will result in there being no funding package for this customer for the dates ${moment(
          latestPreviousPackage.expiredDate
        ).format('DD/MM/YYYY')} to ${moment(startDate).format('DD/MM/YYYY')}`
      );
    }

    // Search for any funding that would overlap with the new dates
    const overlapingPackages = _.find(
      fundPackages,
      (fundPackage) =>
        moment(fundPackage.startDate).endOf('day') <= moment(reviewDate).endOf('day') &&
        moment(fundPackage.expiredDate).endOf('day') >= moment(startDate).endOf('day')
    );

    if (overlapingPackages) {
      warnings.push(
        `The dates selected overlap with another of the current funding package on the customer's profile. Bookings that occur in the overlap dates will be updated to reflect the details of this plan.`
      );
    }

    this.setState({ warnings });
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div>
        {this.state.isSaving ? (
          <div>
            <div className="mt-x-large">
              <div className="flex-row align-center mb-x-large">
                <div className="mr-x-large">
                  <Spinner size={120} />
                </div>
                <div>
                  <Paragraph>Loading.......</Paragraph>
                  <Paragraph weight="bold">Editing Dates.</Paragraph>
                  <Paragraph>This won't take long.</Paragraph>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <React.Fragment>
            <ActionModal
              isOpen={this.state.isCancelActionModalOpen}
              onClose={this._closeCancelActionModal}
              title={'Confirmation'}
              showCloseButton={true}
            >
              <Text className={'mb-medium'}>You are closing the editing page without saving.</Text>
              <br />
              <Text className={'mb-medium'}>Do you want to proceed?</Text>
              <ActionModalFooter>
                <PrimaryButton className="mr-medium" size="large" onClick={this._closeCancelActionModal}>
                  Cancel
                </PrimaryButton>
                <GhostButton size="large" onClick={this.props.closeEditDateModal}>
                  Proceed
                </GhostButton>
              </ActionModalFooter>
            </ActionModal>
            <Row gutter={24} className="mt-x-large">
              <Col span={12}>
                <SubTitle>Start Date</SubTitle>
                <Form.Item>
                  {getFieldDecorator('startDate', {
                    initialValue: this.props.startDate !== '' ? moment(this.props.startDate) : '',
                    rules: [
                      {
                        required: true,
                        message: 'Please select start date'
                      }
                    ]
                  })(
                    <DatePicker
                      size="large"
                      placeholder={'Start Date'}
                      allowClear={false}
                      format="DD/MM/YYYY"
                      className={'width-full'}
                      onChange={(date, dateString) => this._handleDateData(date, dateString, 'startDate')}
                      disabledDate={(current) => {
                        const endValue = this.props.form.getFieldValue('expiryDate');
                        if (endValue !== null && endValue !== '') {
                          return current.valueOf() > endValue.startOf('day').valueOf();
                        }
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <SubTitle>Plan review date</SubTitle>
                <Form.Item>
                  {getFieldDecorator('expiryDate', {
                    initialValue: this.props.reviewDate !== '' ? moment(this.props.reviewDate) : '',
                    rules: [
                      {
                        required: true,
                        message: 'Please select plan review date'
                      }
                    ]
                  })(
                    <DatePicker
                      size="large"
                      placeholder={'Plan Review Date'}
                      allowClear={false}
                      format="DD/MM/YYYY"
                      className={'width-full'}
                      onChange={(date, dateString) => this._handleDateData(date, dateString, 'expiryDate')}
                      disabledDate={(current) => {
                        const startValue = this.props.form.getFieldValue('startDate');
                        const minEndDate = moment(startValue).add(1, 'day');
                        if (startValue !== null && startValue !== '') {
                          return current.valueOf() < minEndDate.valueOf();
                        }
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            {this.state.warnings.length > 0 &&
              _.map(this.state.warnings, (warning) => (
                <Paragraph className="text-color-warning-orange">
                  <Icon type="calendar" className="mr-x-small" />
                  {warning}
                </Paragraph>
              ))}

            <div className={'mt-x2-large flex-row justify-end align-center'}>
              <GhostButton className={'mr-small'} onClick={this._closeCancelModalWithAction}>
                Cancel
              </GhostButton>
              <PrimaryButton size="large" icon={'save'} onClick={this._onSave}>
                Save Changes
              </PrimaryButton>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateFundingPackageDates: dispatch.customersStore.doUpdateFundingPackageDates
});

export default connect(
  null,
  mapDispatch
)(Form.create<IFundEditDatesPanelProps>()(FundEditDatesPanel));
