import { notification } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { IGroupServiceCustomerRatio } from 'interfaces/service-interfaces';
import { timeZone } from 'interfaces/timezone-type';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import CustomerRatioSelectionPanel from 'views/group-services/components/CustomerRatioSelectioPanel';

interface IEditCustomerRatioModalProps {
  isOpen: boolean;
  bookingTimes: { bookingId: string; startDateTime: Date; endDateTime: Date } | null;
  onClose: any;
  timezone: timeZone;
  currentRatio: IGroupServiceCustomerRatio[];
  doEditBookingCustomerRatio: typeof dispatch.groupBookingsStore.doEditBookingCustomerRatio;
}

interface IEditCustomerRatioModalState {
  isSaving: boolean;
}

class EditCustomerRatioModal extends React.Component<IEditCustomerRatioModalProps, IEditCustomerRatioModalState> {
  private customerRatioRef: any;
  state = {
    isSaving: false
  };

  private _onCancel = () => {
    const { onClose } = this.props;
    onClose();
  };

  private _onSave = () => {
    this.customerRatioRef._validateRatios();
  };

  private _setRef = (ref) => {
    if (ref) {
      this.customerRatioRef = ref;
    }
  };

  private _saveRatio = async (ratios) => {
    const { bookingTimes, timezone } = this.props;

    // A single booking can only have one ratio;
    const selectedRatio = {
      ...ratios[0],
      startDateTime: moment.tz(moment(ratios[0].startDateTime).format('YYYY-MM-DD HH:mm'), timezone),
      endDateTime: moment.tz(moment(ratios[0].endDateTime).format('YYYY-MM-DD HH:mm'), timezone)
    };
    try {
      this.setState({ isSaving: true });
      await this.props.doEditBookingCustomerRatio({
        bookingId: bookingTimes.bookingId,
        isCustomRatio: selectedRatio.isCustomRatio,
        teamMemberCustomerRatio: selectedRatio.isCustomRatio
          ? null
          : selectedRatio.customRatio[0].teamMemberCustomerRatio,
        customRatios: selectedRatio.isCustomRatio
          ? _.map(selectedRatio.customRatio, (customTime) => {
              return {
                ...customTime,
                comments: customTime.comments ? customTime.comments : '',
                teamMemberCustomerRatio: customTime.teamMemberCustomerRatio.ndis
                  ? customTime.teamMemberCustomerRatio.ndis
                  : customTime.teamMemberCustomerRatio,
                startDateTime: moment.tz(
                  moment(bookingTimes.startDateTime)
                    .set({
                      hours: moment(customTime.startDateTime).hour(),
                      minutes: moment(customTime.startDateTime).minutes()
                    })
                    .format('YYYY-MM-DD HH:mm'),
                  timezone
                ),
                endDateTime: moment.tz(
                  moment(bookingTimes.endDateTime)
                    .set({
                      hours: moment(customTime.endDateTime).hour(),
                      minutes: moment(customTime.endDateTime).minutes()
                    })
                    .format('YYYY-MM-DD HH:mm'),
                  timezone
                )
              };
            })
          : null
      });
      this.props.onClose();
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isSaving: false });
  };

  render() {
    const { isOpen, bookingTimes, timezone, currentRatio, onClose } = this.props;

    return (
      <ActionModal
        isOpen={isOpen}
        onClose={onClose}
        title={'Customer ratio'}
        width="x-large"
        // verticalAlignment="highest"
      >
        {/* Description section */}
        <section className="mb-large">
          <Text>Set up customer ratios for this customer.</Text>
        </section>

        <section>
          <CustomerRatioSelectionPanel
            sessions={bookingTimes ? [bookingTimes] : null}
            wrappedComponentRef={this._setRef}
            groupServiceServiceAgreements={null}
            isBookingDisplay={true}
            saveRatios={this._saveRatio}
            timezone={timezone}
            isEdit={true}
            currentRatios={currentRatio}
          />
        </section>

        <ActionModalFooter align={'right'}>
          <SecondaryButton size="large" className="mr-medium" onClick={this._onCancel}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={this._onSave} loading={this.state.isSaving}>
            Save
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditBookingCustomerRatio: dispatch.groupBookingsStore.doEditBookingCustomerRatio
});

export default connect(null, mapDispatch)(EditCustomerRatioModal);
