import React, { Component } from 'react';
import { connect } from 'react-redux';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { Paragraph } from 'common-components/typography';
import { Col, Form, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { ErrorSVG, SuccessSVG } from 'assets/UndrawSVG';
import { BookingStatus } from 'utilities/enum-utils';

interface IBookingEditCancellationReasonActionModelProps extends FormComponentProps {
  isOpen: boolean;
  onClose: () => void;
  selectedBookingItem: typeof state.bookingsStore.selectedBookingItem;
  doUpdateBookingCancellationReason: typeof dispatch.bookingsStore.doUpdateBookingCancellationReason;
}

interface IBookingEditCancellationReasonActionModelState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

class BookingEditCancellationReasonActionModel extends Component<
  IBookingEditCancellationReasonActionModelProps,
  IBookingEditCancellationReasonActionModelState
> {
  state = {
    isLoading: false,
    isSuccess: false,
    isError: false
  };

  private _onCloseModel = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false, isError: false });
      onClose();
    }
  };

  private _onSubmitEdit = async () => {
    const { form, doUpdateBookingCancellationReason } = this.props;

    form.validateFieldsAndScroll(async (err, value) => {
      if (!err) {
        this.setState({ isLoading: true });
        try {
          await doUpdateBookingCancellationReason(value);
          this.setState({ isLoading: false, isSuccess: true });
        } catch (e) {
          this.setState({ isLoading: false, isError: true });
        }
      }
    });
  };

  componentDidMount() {
    this.setState({
      isSuccess: false,
      isLoading: false,
      isError: false
    });
  }
  render() {
    const { isOpen, form, selectedBookingItem } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    return (
      <ActionModal
        title="Edit Cancellation Reason"
        isOpen={isOpen}
        onClose={this._onCloseModel}
        width="medium"
        verticalAlignment="highest"
        canCloseOutside={false}
      >
        {!this.state.isLoading && !this.state.isSuccess && !this.state.isError && (
          <div className="text-align-left" style={{ minHeight: '200px' }}>
            <div className="mt-large">{/* <img src={BookingSVG} alt={'booking'} style={{ height: '200px' }} /> */}</div>
            <div className="flex-row align-center justify-center mt-large mb-x-large">
              <Row gutter={24} className="width-full">
                {selectedBookingItem.status !== BookingStatus.BUSINESS_CANCELLED && (
                  <>
                    <Col span={8}>
                      <Paragraph>Cancellation Code</Paragraph>
                    </Col>
                    <Col span={16}>
                      <Form.Item>
                        {getFieldDecorator('cancellationReason', {
                          initialValue: selectedBookingItem.cancellationCode,
                          rules: [{ required: true, message: 'Please select cancellation reason' }]
                        })(
                          <Select className="width-full" size="large">
                            <Select.Option value="NSDH">No show due to health reason.</Select.Option>
                            <Select.Option value="NSDF">No show due to family issues.</Select.Option>
                            <Select.Option value="NSDT">No show due to unavailability of transport.</Select.Option>
                            <Select.Option value="NSDO">Other</Select.Option>
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                  </>
                )}
                {((selectedBookingItem.status !== BookingStatus.BUSINESS_CANCELLED &&
                  getFieldValue('cancellationReason') === 'NSDO') ||
                  selectedBookingItem.status === BookingStatus.BUSINESS_CANCELLED) && (
                  <>
                    <Col span={8}>
                      <Paragraph>
                        {selectedBookingItem.status === BookingStatus.CUSTOMER_CANCELLED_WITH_FEE ||
                        selectedBookingItem.status === BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE
                          ? 'Other Reason'
                          : 'Reason'}
                      </Paragraph>
                    </Col>
                    <Col span={16}>
                      <Form.Item>
                        {getFieldDecorator('reason', {
                          initialValue: selectedBookingItem.cancellationReason,
                          rules: [{ required: true, message: 'Please specify reason' }]
                        })(<TextArea className="width-full" />)}
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>
            </div>
          </div>
        )}
        {this.state.isLoading && (
          <div style={{ minHeight: '200px' }}>
            <SpinningLoader size={100} message={'Loading'} />
          </div>
        )}

        {!this.state.isLoading && this.state.isError && (
          <div className="anim-fade-in">
            <div className="pv-medium">
              <img src={ErrorSVG} alt="ERROR" style={{ width: '100%', height: '200px' }} />
            </div>
            <div className="text-align-center">
              <Paragraph>Oops something has gone wrong, please try again</Paragraph>
            </div>

            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModel}>
                Go Back to Booking
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {!this.state.isLoading && this.state.isSuccess && !this.state.isError && (
          <div className="text-align-center">
            <div className="pv-medium">
              <img src={SuccessSVG} alt={'Success'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium">
              <Paragraph>You have successfully change the Cancellation to:</Paragraph>
            </div>

            {(selectedBookingItem.status === BookingStatus.CUSTOMER_CANCELLED_WITH_FEE ||
              selectedBookingItem.status === BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE) && (
              <div className="flex-row align-center justify-center mt-large">
                <Paragraph size={'large'}>Cancellation Code: {selectedBookingItem.cancellationCode}</Paragraph>
              </div>
            )}
            <div className="flex-row align-center justify-center mb-large">
              {(selectedBookingItem.cancellationCode === 'NSDO' ||
                selectedBookingItem.status === BookingStatus.BUSINESS_CANCELLED) && (
                <Paragraph size={'large'}> Reason: {selectedBookingItem.cancellationReason}</Paragraph>
              )}
            </div>
          </div>
        )}

        {!this.state.isSuccess && !this.state.isError && (
          <ActionModalFooter>
            <GhostButton
              size="large"
              onClick={this._onCloseModel}
              className="mr-medium"
              disabled={this.state.isLoading}
            >
              Cancel
            </GhostButton>
            <PrimaryButton size="large" onClick={this._onSubmitEdit} loading={this.state.isLoading}>
              Save Changes
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {this.state.isSuccess && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModel} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({ selectedBookingItem: state.bookingsStore.selectedBookingItem });

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateBookingCancellationReason: dispatch.bookingsStore.doUpdateBookingCancellationReason
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IBookingEditCancellationReasonActionModelProps>()(BookingEditCancellationReasonActionModel));
