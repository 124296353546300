import { Spinner } from '@blueprintjs/core';
import { Col, Icon, notification, Row } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { LocationDisplay } from 'common-components/locations';
import { TimezoneIndicator } from 'common-components/timezone';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { INewBookingData } from 'interfaces/booking-interfaces';
import { IServiceDetailsItem } from 'interfaces/service-interfaces';
import _ from 'lodash';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { RecurringBookingPattern, ServiceLocationType, ServiceType, ShiftSlotStatus } from 'utilities/enum-utils';

/* Spinner while create booking process */
class Step5CreatingBookingView extends Component<{ customerName: string }> {
  render() {
    return (
      <div className="anim-slide-left">
        <Title level={2} weight="bolder" className="line-height-100 mb-x-large">
          <span className="text-weight-regular">Creating a new </span> booking
        </Title>
        <div className="flex-row align-center mb-x-large">
          <div className="mr-x-large">
            <Spinner size={120} />
          </div>
          <div>
            <Paragraph>
              Creating a new booking for <b>{this.props.customerName}</b>.
            </Paragraph>
            <Paragraph weight="bold">Finalizing booking details...</Paragraph>
            <Paragraph>This won't take long.</Paragraph>
          </div>
        </div>
      </div>
    );
  }
}

interface Step5SummaryPanelProps {
  onNextStep: any;
  onPreviousStep: any;
  newBookingData: INewBookingData | any;
  doSetNewBookingData: any;
  doCreateNewBooking?: typeof dispatch.bookingsStore.doCreateNewBooking;
  bookSelectedService: IServiceDetailsItem;
}

class Step6SummaryPanel extends Component<Step5SummaryPanelProps, any> {
  state = { isLoading: false };

  private _formatDate = (bookStartDate, bookEndDate) => {
    const { bookSelectedService } = this.props;
    const bookingTimezone = bookSelectedService.timezone;
    const startDate = moment.tz(bookStartDate, bookingTimezone);
    const endDate = moment.tz(bookEndDate, bookingTimezone);

    let isSameDay = moment
      .tz(startDate, bookingTimezone)
      .startOf('day')
      .isSame(moment.tz(endDate, bookingTimezone).startOf('day'));

    // isSameDay = false;
    if (isSameDay) {
      // Target format = friday 15 December 2020, 3:30PM to 5:30PM
      return `${startDate.format('dddd DD MMMM')}, ${startDate.format('h:mm A')} to ${endDate.format('h:mm A')}`;
    } else {
      // Target format = 15 -20 December 2020, 3:30PM to 5:30PM
      return (
        startDate.format('DD') +
        ' - ' +
        endDate.format('DD MMMM YYYY') +
        ', ' +
        startDate.format('h:mm A') +
        ' to ' +
        endDate.format('h:mm A')
      );
    }
  };

  private _onSubmit = async () => {
    // TODO : Submit the request to the API to create a new booking here.
    try {
      const { onNextStep, newBookingData, doCreateNewBooking, bookSelectedService } = this.props;
      const isGroupService = bookSelectedService.serviceType === ServiceType.GROUP;

      this.setState({ isLoading: true });

      let payload = {
        serviceId: newBookingData.selectedService.serviceId,
        timezone: bookSelectedService.timezone,
        bookingType: bookSelectedService.serviceType,
        serviceProviderId: newBookingData.selectedService.serviceProviderId,
        bookerUserId: newBookingData.selectedCustomer.userId,
        timeSlots: newBookingData.timeSlots,
        timeSlotsOutSideCanBeAssign: newBookingData.timeSlotsOutSideCanBeAssign,
        locationId: !isGroupService && newBookingData.bookLocation ? newBookingData.bookLocation.userAddressId : null,
        address: newBookingData.bookLocation
          ? {
              state: newBookingData.bookLocation.state,
              geoLat: newBookingData.bookLocation.geoLat,
              geoLng: newBookingData.bookLocation.geoLng,
              country: newBookingData.bookLocation.country,
              locality: newBookingData.bookLocation.locality,
              postcode: newBookingData.bookLocation.postcode,
              fullAddress: newBookingData.bookLocation.fullAddress,
              streetAddress1: newBookingData.bookLocation.streetAddress1,
              streetAddress2: newBookingData.bookLocation.streetAddress2
            }
          : {},
        noteContent: !newBookingData.isFutureBooking ? newBookingData.bookCustomNote : null,
        instructions: newBookingData.isFutureBooking ? newBookingData.bookCustomNote : null,
        workerId:
          newBookingData.selectedWorker && newBookingData.selectedWorker.supportWorkerId !== null
            ? newBookingData.selectedWorker.supportWorkerId
            : null,
        isPastBooking: !newBookingData.isFutureBooking,
        isCancelledBooking: newBookingData.isCancelledBooking,
        cancellationReason: newBookingData.cancellationCode,
        reason: newBookingData.cancellationReason,
        isTravel: newBookingData.hasMileageClaims,
        travelDistance: parseInt(newBookingData.mileageTravelled),
        isShiftPublished: newBookingData.isShiftPublished ? newBookingData.isShiftPublished : false,
        supportWorkers: newBookingData.supportWorkers ? newBookingData.supportWorkers : null,
        workerGroups: newBookingData.workerGroups ? newBookingData.workerGroups : null,
        isRecurring: newBookingData.isRecurring,
        recurringPattern: newBookingData.recurringPattern ? newBookingData.recurringPattern : null,
        isTravelBeforeBooking: newBookingData.isTravelBeforeBooking ? newBookingData.isTravelBeforeBooking : false,
        shiftSlotStatus: newBookingData.shiftSlotStatus ? newBookingData.shiftSlotStatus : ShiftSlotStatus.CONFIRMED,
        isRemovePendingShiftSlots: !!newBookingData.isRemovePendingShiftSlots,
        travelDistanceBeforeBooking: newBookingData.travelDistanceBeforeBooking
          ? newBookingData.travelDistanceBeforeBooking
          : 0,
        travelTimeBeforeBooking: newBookingData.travelTimeBeforeBooking ? newBookingData.travelTimeBeforeBooking : 0,
        additionalCostBeforeBooking: newBookingData.additionalCostBeforeBooking
          ? newBookingData.additionalCostBeforeBooking
          : 0,
        isTravelDuringBooking: newBookingData.isTravelDuringBooking ? newBookingData.isTravelDuringBooking : false,
        travelDistanceDuringBooking: newBookingData.travelDistanceDuringBooking
          ? newBookingData.travelDistanceDuringBooking
          : 0,
        additionalCostDuringBooking: newBookingData.additionalCostDuringBooking
          ? newBookingData.additionalCostDuringBooking
          : 0,
        paymentSourceType: newBookingData.paymentSourceType,
        conflictBookings: newBookingData.conflictBookings
          ? _.map(newBookingData.conflictBookings, (conflict) => {
              return { attendanceId: conflict.attendanceId, bookingRequestId: conflict.bookingRequestId };
            })
          : null,
        conflictWorkerBookings: newBookingData.conflictWorkerBookings
          ? _.map(newBookingData.conflictWorkerBookings, (conflict) => {
              return { supportWorkerAttendanceId: conflict.supportWorkerAttendanceId };
            })
          : null,
        userSelectedBillingItems: newBookingData.selectedLineItems
          ? _.map(newBookingData.selectedLineItems, (period) => {
              return {
                ...period,
                selectedLineItems: _.map(period.selectedLineItems, (lineItem) => {
                  return lineItem.supportItemNumber;
                })
              };
            })
          : []
      };

      await doCreateNewBooking(payload);

      this.setState({ isLoading: false });

      onNextStep();
    } catch (error) {
      this.setState({ isLoading: false });

      notification.error({
        description: error.meta ? error.meta.message : JSON.stringify(error.meta),
        message: error.message
      });
    }
  };

  private _renderDuration = (startDateTime: Moment, endDateTime: Moment) => {
    const duration = CommonUtils.formatDuration(startDateTime, endDateTime);
    let durationDisplays = [];

    if (duration.days) {
      durationDisplays.push(
        <>
          <b>{duration.days.value}</b> {`${duration.days.label} `}
        </>
      );
    }
    if (duration.hours) {
      durationDisplays.push(
        <>
          <b>{duration.hours.value}</b> {`${duration.hours.label} `}
        </>
      );
    }

    if (duration.minutes) {
      durationDisplays.push(
        <>
          <b>{duration.minutes.value}</b> {duration.minutes.label}
        </>
      );
    }
    return (
      <Text color="secondary">
        {_.map(durationDisplays, (display) => {
          return display;
        })}
      </Text>
    );
  };

  render() {
    const { onPreviousStep, newBookingData, bookSelectedService } = this.props;

    let {
      selectedCustomer,
      selectedService,
      selectedWorker,
      isShiftPublished,
      bookCustomNote,
      bookStartDate,
      bookEndDate,
      isCancelledBooking,
      cancellationReason,
      hasMileageClaims,
      mileageTravelled,
      cancellationCode,
      isRecurring,
      recurringPattern,
      paymentSourceType
    } = newBookingData;

    const { firstName, lastName } = selectedCustomer;
    const { serviceName } = selectedService;

    if (_.isEmpty(selectedWorker)) {
      selectedWorker = { firstName: null, lastName: null };
    }

    const { firstName: workerFirstName, lastName: workerLastName } = selectedWorker;

    if (this.state.isLoading) {
      return <Step5CreatingBookingView customerName={selectedCustomer.firstName + ' ' + selectedCustomer.lastName} />;
    }

    const location =
      bookSelectedService.locations[0] && bookSelectedService.locations[0].postcode
        ? bookSelectedService.locations[0]
        : null;

    return (
      <div className="anim-slide-left">
        <div className="mb-medium">
          <Title level={2}>
            <span className="text-weight-regular">Booking</span> Summary
          </Title>

          <Text>Please confirm the booking details below for this booking.</Text>
        </div>

        <div className="mb-x3-large" style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '40vh' }}>
          <Row className="mb-medium">
            <Col span={12}>
              <SubTitle>Customer</SubTitle>
              <Text weight="black" size="x-large">
                {`${firstName}  ${lastName}`}
              </Text>
            </Col>
            <Col span={12}>
              <SubTitle>Support Worker</SubTitle>
              {isShiftPublished ? (
                <Text weight="bold" className="mr-x2-large">
                  <Icon type="wifi" className="text-color-violet-light mr-x-small" />
                  To be published to workers
                </Text>
              ) : (
                <Text weight="bold" size="x-large">
                  {_.isEmpty(workerFirstName) ? 'No worker selected' : `${workerFirstName} ${workerLastName}`}
                </Text>
              )}
            </Col>
          </Row>
          <Row className="mb-medium">
            <Col span={12}>
              <SubTitle>Service Name</SubTitle>
              <Text weight="black" size="x-large">
                {serviceName}
              </Text>
            </Col>
            <Col span={12}>
              <SubTitle>Preferred Payment method</SubTitle>
              <Text weight="black" size="x-large">
                {paymentSourceType}
              </Text>
            </Col>
          </Row>
          <Row className="mb-medium">
            <Col span={12}>
              <SubTitle>Booking Time</SubTitle>
              <Text weight="black" size="x-large">
                {/*15 December 2020, 5:30 PM - 7:30 PM*/}
                {this._formatDate(bookStartDate, bookEndDate)}
              </Text>
              <br />
              <TimezoneIndicator
                hasIcon={false}
                bordered={false}
                showTzName={false}
                timezone={bookSelectedService.timezone}
              />
              <br />
              <Text weight="regular" size="x-large">
                {this._renderDuration(moment(bookStartDate), moment(bookEndDate))}
              </Text>
            </Col>
            <Col span={12}>
              <SubTitle>Location</SubTitle>
              {bookSelectedService.serviceDirection === ServiceLocationType.CUSTOMERLOCATION && (
                <Paragraph weight="bold" size="x-large" ellipsis={{ rows: 1 }}>
                  {/* TODO : Need help here as well. */}
                  {newBookingData.bookLocation.fullAddress}
                </Paragraph>
              )}
              {bookSelectedService.serviceDirection === ServiceLocationType.ONLINE && (
                <Paragraph weight="bold" size="x-large" ellipsis={{ rows: 1 }}>
                  Online
                </Paragraph>
              )}
              {bookSelectedService.serviceDirection === ServiceLocationType.FIXEDVENUE && (
                <>
                  <Paragraph weight="bold" size="x-large" ellipsis={{ rows: 1 }}>
                    {location ? (
                      <LocationDisplay location={location.fullAddress} />
                    ) : (
                      <Text color="secondary">
                        No Address selected. Please choose an address in the Service detail page.
                      </Text>
                    )}
                  </Paragraph>
                </>
              )}
            </Col>
          </Row>
          {isRecurring && (
            <Row className="mb-medium">
              <Col span={24}>
                <SubTitle>Recurrence summary</SubTitle>
                <Paragraph>
                  This booking will happen{' '}
                  <b>
                    every{' '}
                    {recurringPattern.recurringPattern === RecurringBookingPattern.EveryDay
                      ? 'day'
                      : recurringPattern.recurringPattern === RecurringBookingPattern.EveryWeek
                      ? 'week'
                      : recurringPattern.recurringPattern === RecurringBookingPattern.EveryFortnight
                      ? 'fortnight'
                      : 'month'}
                  </b>
                  , starting on the <b>{moment.tz(bookStartDate, bookSelectedService.timezone).format('DD/MM/YYYY')}</b>{' '}
                  and finishing on the{' '}
                  <b>{moment.tz(recurringPattern.recurringTo, bookSelectedService.timezone).format('DD/MM/YYYY')}</b>{' '}
                  for a total of <b>{newBookingData.timeSlots ? newBookingData.timeSlots.length : 0}</b> booking
                  {newBookingData.timeSlots && newBookingData.timeSlots.length !== 1 && 's'}
                </Paragraph>
              </Col>
            </Row>
          )}
          {isCancelledBooking && (
            <Row className="mb-medium">
              <Col span={24}>
                <SubTitle>Cancellation Code</SubTitle>
                <Text weight="bold" size="x-large">
                  {cancellationCode}
                </Text>
              </Col>
            </Row>
          )}
          {isCancelledBooking && cancellationReason !== null && cancellationReason !== '' && (
            <Row className="mb-medium">
              <Col span={24}>
                <SubTitle>Other Reason</SubTitle>
                <Text weight="bold" size="x-large">
                  {cancellationReason}
                </Text>
              </Col>
            </Row>
          )}

          {hasMileageClaims && (
            <Row className="mb-medium">
              <Col span={12}>
                <SubTitle>Mileage Travelled</SubTitle>
                <Text weight="bold" size="x-large">
                  {mileageTravelled} km
                </Text>
              </Col>
            </Row>
          )}
          <Row className="">
            <Col span={24}>
              <SubTitle>{newBookingData.isFutureBooking ? 'Instructions' : 'Booking note'}</SubTitle>
              <Paragraph weight="bold" size="x-large" ellipsis={{ rows: 1 }} color="secondary">
                {bookCustomNote ? (
                  bookCustomNote
                ) : (
                  <Text color={'tertiary'} weight={'regular'}>
                    No instructions included. You can always add instructions in later.
                  </Text>
                )}
              </Paragraph>
            </Col>
          </Row>
        </div>

        <div className="flex-row justify-between align-center">
          <SecondaryButton size="large" onClick={onPreviousStep} icon="left">
            Previous
          </SecondaryButton>

          <PrimaryButton size="large" onClick={this._onSubmit} icon="check" iconPosition="right">
            Create Booking Now
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  bookSelectedService: state.servicesStore.bookSelectedService,
  newBookingData: state.bookingsStore.newBookingData
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateNewBooking: dispatch.bookingsStore.doCreateNewBooking,
  doSetNewBookingData: dispatch.bookingsStore.doSetNewBookingData
});

export default connect(mapState, mapDispatch)(Step6SummaryPanel);
