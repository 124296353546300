import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Text } from 'common-components/typography';
import { Avatar, Icon, Input, Select } from 'antd';
import { CustomerNoteVisibleType } from 'utilities/enum-utils';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import TagNoteCustomersModal from 'views/group-services/components/booking-notes/TagNoteCustomersModal';
import { IGroupServiceCustomer, IGroupServiceNote } from 'interfaces/service-interfaces';
import Form, { FormComponentProps } from 'antd/lib/form';
import { dispatch, IRootDispatch, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import asyncDelay from 'utilities/asyncDelay';
import _ from 'lodash';

const { Option } = Select;

function IncidentSelector({ onSelectIncident, isSelected }: { onSelectIncident: any; isSelected: boolean }) {
  return (
    <div className="flex-row line-height-100">
      <div
        className={`rounded pv-small mr-medium cursor-pointer select-none ${
          isSelected ? 'bg-white border-standard-gray bordered ' : 'bg-blue-action'
        }`}
        style={{ paddingLeft: '12px', paddingRight: '12px' }}
        onClick={() => onSelectIncident(false)}
      >
        <Text lineHeight={100} color={isSelected ? 'secondary' : 'white'}>
          No
        </Text>
      </div>
      <div
        className={`rounded pv-small cursor-pointer select-none ${
          isSelected ? 'bg-red' : 'border-standard-gray bordered'
        }`}
        style={{ paddingLeft: '12px', paddingRight: '12px' }}
        onClick={() => onSelectIncident(true)}
      >
        <Text color={isSelected ? 'white' : 'secondary'} lineHeight={100}>
          Yes - This note relates to an incident
        </Text>
      </div>
    </div>
  );
}

interface IAddEditNoteModalProps extends FormComponentProps {
  isOpen: boolean;
  onClose: () => void;
  onSaveNoteAction: () => void;
  noteMode: string;
  isAllowSelecteCustomer: boolean;
  selectedSession?: typeof state.groupServiceStore.selectedSession;
  booking?: typeof state.groupBookingsStore.selectedGroupBookingItem;
  selectedCustomers?: IGroupServiceCustomer[];
  currentCustomer?: IGroupServiceCustomer;
  targetNote: IGroupServiceNote;
  doAddGroupServiceSessionNote: typeof dispatch.groupServiceStore.doAddGroupServiceSessionNote;
  doEditGroupServiceSessionNote: typeof dispatch.groupServiceStore.doEditGroupServiceSessionNote;
  doAddGroupBookingNote: typeof dispatch.groupBookingsStore.doAddGroupBookingNote;
  doEditGroupBookingNote: typeof dispatch.groupBookingsStore.doEditGroupBookingNote;
}

interface IAddEditNoteModalState {
  isSaving: boolean;
  isTagCustomerModalOpen: boolean;
  isNoTagCustomerWarning: boolean;
  incidentSelected: boolean;
  visibleType: CustomerNoteVisibleType;
  selectedCustomers: IGroupServiceCustomer[];
}

class AddEditGroupNoteModal extends Component<IAddEditNoteModalProps, IAddEditNoteModalState> {
  state = {
    isSaving: false,
    isNoTagCustomerWarning: false,
    isTagCustomerModalOpen: false,
    incidentSelected:
      this.props.noteMode === 'add' ? false : this.props.targetNote ? this.props.targetNote.isIncident : false,
    selectedCustomers:
      this.props.noteMode === 'add'
        ? this.props.selectedCustomers
        : this.props.targetNote
        ? this.props.targetNote.taggedTo
        : [],
    visibleType:
      this.props.noteMode === 'add'
        ? CustomerNoteVisibleType.PORTAL
        : this.props.targetNote && this.props.targetNote.visibleType === 'PORTAL'
        ? CustomerNoteVisibleType.PORTAL
        : CustomerNoteVisibleType.PORTAL_AND_APP
  };

  onChangeVisibleType = (e) => {
    this.setState({ visibleType: e });
  };

  //region Action handlers
  // Handler for whenever the incident flag is selected.
  onSelectIncident = (incidentSelected) => {
    this.setState({ incidentSelected });
  };

  onSelectCustomer = (customers) => {
    this.setState({ selectedCustomers: customers });
  };

  validateForm = () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (!this.state.selectedCustomers || this.state.selectedCustomers.length === 0) {
      isFormValid = false;
      this.setState({ isNoTagCustomerWarning: true });
    }

    return isFormValid;
  };

  onSave = async () => {
    if (!this.validateForm()) return;
    const {
      onSaveNoteAction,
      currentCustomer,
      noteMode,
      form,
      targetNote,
      doAddGroupServiceSessionNote,
      doEditGroupServiceSessionNote,
      doAddGroupBookingNote,
      doEditGroupBookingNote,
      selectedSession,
      booking
    } = this.props;
    const { incidentSelected, visibleType, selectedCustomers } = this.state;

    let userIds = _.map(selectedCustomers, (customer) => customer.taggedUserId);
    if (currentCustomer) {
      userIds.push(currentCustomer.taggedUserId);
    }

    const payload = {
      isIncident: incidentSelected,
      visibleType,
      taggedTo: userIds,
      body: form.getFieldValue('noteBody')
    };

    let finalPayload;
    if (selectedSession) {
      const { serviceId, serviceDateTimeId } = selectedSession;
      finalPayload = { ...payload, serviceId, serviceDateTimeId };
    } else {
      const { bookingId } = booking;
      finalPayload = { ...payload, bookingId };
    }

    this.setState({ isSaving: true });
    if (noteMode === 'add' && selectedSession) {
      await doAddGroupServiceSessionNote(finalPayload);
    } else if (noteMode === 'add' && booking) {
      await doAddGroupBookingNote(finalPayload);
    } else if (noteMode === 'edit' && selectedSession) {
      await doEditGroupServiceSessionNote({ ...finalPayload, noteId: targetNote.noteId });
    } else if (noteMode === 'edit' && booking) {
      await doEditGroupBookingNote({ ...finalPayload, noteId: targetNote.noteId });
    }

    // await asyncDelay(1000);
    this.setState({ isSaving: false, selectedCustomers: [] });
    onSaveNoteAction();
  };

  //endregion

  //region Modal handlers
  onOpenTagModal = () => this.setState({ isTagCustomerModalOpen: true, isNoTagCustomerWarning: false });
  onCloseTagModal = () => this.setState({ isTagCustomerModalOpen: false });
  //endregion

  componentDidUpdate = (prevProps) => {
    if (this.props.isOpen && this.props.isOpen !== prevProps.isOpen) {
      this.setState({
        isSaving: false,
        isNoTagCustomerWarning: false,
        isTagCustomerModalOpen: false,
        incidentSelected:
          this.props.noteMode === 'add' ? false : this.props.targetNote ? this.props.targetNote.isIncident : false,
        selectedCustomers:
          this.props.noteMode === 'add'
            ? this.props.selectedCustomers
            : this.props.targetNote
            ? this.props.targetNote.taggedTo
            : null,
        visibleType:
          this.props.noteMode === 'add'
            ? CustomerNoteVisibleType.PORTAL
            : this.props.targetNote && this.props.targetNote.visibleType === 'PORTAL'
            ? CustomerNoteVisibleType.PORTAL
            : CustomerNoteVisibleType.PORTAL_AND_APP
      });
    }
    if (this.props.selectedCustomers && this.props.selectedCustomers !== prevProps.selectedCustomers) {
      this.setState({ selectedCustomers: this.props.selectedCustomers });
    }
  };

  render() {
    const {
      noteMode,
      isOpen,
      onClose,
      isAllowSelecteCustomer,
      form,
      targetNote,
      currentCustomer,
      selectedSession
    } = this.props;
    const { selectedCustomers, isSaving } = this.state;
    const { getFieldDecorator } = form;
    const modalTitle = noteMode === 'add' ? 'Add Note' : noteMode === 'edit' ? 'Edit Note' : '';

    return (
      <>
        <ActionModal isOpen={isOpen} onClose={onClose} title={modalTitle} canCloseOutside={false} width="x-large">
          {isSaving ? (
            <SpinningLoader size={150} message="Saving..." />
          ) : (
            <>
              {/* Incident options section */}
              <section className="mb-large">
                <div className="mb-medium">
                  <Text>Does this note relate to an incident?</Text>
                </div>

                <IncidentSelector onSelectIncident={this.onSelectIncident} isSelected={this.state.incidentSelected} />
              </section>

              {/* Notes section */}
              <section className="mb-large">
                <FieldLabel text={'Note'} />
                <Form.Item>
                  {getFieldDecorator('noteBody', {
                    initialValue: noteMode === 'edit' && targetNote ? targetNote.body : undefined,
                    rules: [{ required: true, min: 5, message: 'Please enter at least 5 characters.' }]
                  })(<Input.TextArea rows={3} placeholder={'Enter your note content here...'} />)}
                </Form.Item>
              </section>

              {/* Privacy section */}
              <section className="mb-large">
                <FieldLabel text={'PRIVACY'} />

                <Select
                  size="large"
                  className={'width-full'}
                  optionLabelProp="label"
                  defaultValue={CustomerNoteVisibleType.PORTAL}
                  onChange={this.onChangeVisibleType}
                >
                  <Option value={CustomerNoteVisibleType.PORTAL} label="Visible on the workspace only">
                    <Text style={{ whiteSpace: 'pre-wrap' }} lineHeight={100}>
                      <b>Visible on the workspace only</b>
                      <br />
                      <Text size="regular" color="secondary">
                        This note will be visible on the GoodHuman workspace only. Team members on the business app
                        cannot view this note.
                      </Text>
                    </Text>
                  </Option>
                  <Option
                    value={CustomerNoteVisibleType.PORTAL_AND_APP}
                    label="Visible on the workspace &amp; the business app"
                  >
                    <Text style={{ whiteSpace: 'pre-wrap' }}>
                      <b>Visible on the workspace &amp; the business app</b>
                      <br />
                      <Text size="regular" color="secondary">
                        This note will be visible on the GoodHuman workspace. Team members assigned to the booking can
                        also view this on the GoodHuman business app.
                      </Text>
                    </Text>
                  </Option>
                </Select>
              </section>

              <section className="mb-large">
                <div className="mt-medium">
                  <div className="mb-x-small">
                    <Text size="small" color="secondary" lineHeight={100}>
                      Tagged to
                    </Text>
                  </div>
                  <div className="p-medium bordered border-standard-gray bg-quaternary rounded-big line-height-100">
                    {/* Customers */}
                    {((selectedCustomers && selectedCustomers.length > 0) || currentCustomer) && (
                      <div className="flex-row align-center">
                        {currentCustomer && (
                          <div className="mr-medium">
                            {' '}
                            <Avatar className="mr-small" size="small" src={currentCustomer.taggedUserAvatarUrl || ''} />
                            <Text size="regular">{`${currentCustomer.taggedUserFirstName ||
                              ''} ${currentCustomer.taggedUserLastName || ''}`}</Text>
                          </div>
                        )}

                        {_.map(selectedCustomers, (customer) => (
                          <div className="mr-medium">
                            {' '}
                            <Avatar className="mr-small" size="small" src={customer.taggedUserAvatarUrl || ''} />
                            <Text size="regular">{`${customer.taggedUserFirstName ||
                              ''} ${customer.taggedUserLastName || ''}`}</Text>
                          </div>
                        ))}

                        {isAllowSelecteCustomer && (
                          <HyperlinkButton className="ml-small" fontSize="regular" onClick={this.onOpenTagModal}>
                            {currentCustomer ? 'Add other customers to note' : 'Edit customers'}
                          </HyperlinkButton>
                        )}
                      </div>
                    )}{' '}
                    {!currentCustomer && (!selectedCustomers || selectedCustomers.length === 0) && (
                      <>
                        <Text>No one</Text>
                        {selectedSession && (
                          <HyperlinkButton className="ml-small" fontSize="regular" onClick={this.onOpenTagModal}>
                            Select customers
                          </HyperlinkButton>
                        )}
                      </>
                    )}
                    {!isAllowSelecteCustomer && (
                      <div>
                        <Text size="regular" color="secondary">
                          You cannot tag additional customers to a note that is created from the manage customer modal.
                          Please go to the 'Notes' section of the session to create a note and tag multiple customers.
                        </Text>
                      </div>
                    )}
                  </div>
                  {this.state.isNoTagCustomerWarning && (
                    <div className="mt-small">
                      <Text color="red-dark">Please select a customer.</Text>
                    </div>
                  )}
                </div>
              </section>

              <ActionModalFooter align={'right'}>
                <SecondaryButton size="large" className="mr-medium" onClick={this.props.onClose}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this.onSave}>
                  Add note
                </PrimaryButton>
              </ActionModalFooter>
            </>
          )}
        </ActionModal>
        <TagNoteCustomersModal
          isOpen={this.state.isTagCustomerModalOpen}
          onSelectCustomer={this.onSelectCustomer}
          selectedCustomers={this.state.selectedCustomers}
          onClose={this.onCloseTagModal}
          selectedSession={this.props.selectedSession}
          booking={this.props.booking}
          currentCustomer={this.props.currentCustomer}
        />
      </>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doAddGroupServiceSessionNote: dispatch.groupServiceStore.doAddGroupServiceSessionNote,
  doEditGroupServiceSessionNote: dispatch.groupServiceStore.doEditGroupServiceSessionNote,
  doAddGroupBookingNote: dispatch.groupBookingsStore.doAddGroupBookingNote,
  doEditGroupBookingNote: dispatch.groupBookingsStore.doEditGroupBookingNote
});

export default connect(
  null,
  mapDispatch
)(Form.create<IAddEditNoteModalProps>()(AddEditGroupNoteModal));
