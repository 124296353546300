import React from 'react';
import _ from 'lodash';

import moment from 'moment-timezone';

import { Text } from 'common-components/typography';
import { SessionCard } from 'views/group-services/session-listings/week-view/components/SessionCard';
import { Icon } from 'antd';

export function SessionsColumn({ day, sessions, isFetching = false, history, timezone }) {
  const hasSessions = _.size(sessions) > 0;

  const dayOfWeek = moment.tz(day, timezone).day();

  const cellBgColor = isFetching ? 'bg-white' : 'bg-white';

  return (
    <div className="flex-1 bordered-left" style={{ width: 'calc(100vw / 7)' }}>
      {/* Day display container */}
      <div className="bordered-top bordered-left bg-quaternary pv-12 flex-row justify-center line-height-100">
        <Text lineHeight={100}>{moment.tz(day, timezone).format('ddd,  D MMM')}</Text>
      </div>

      {/* Items container */}

      {/* TODO : Figure out a better way to calculate minHeight. 55vh is probably not the best option here */}
      <div className={`bordered-top ${cellBgColor}`} style={{ minHeight: '55vh' }}>
        {/* Empty state */}
        {!hasSessions && !isFetching && (
          <div className="mt-x5-large text-align-center">
            <Text color="secondary" size="regular">
              No sessions
            </Text>
          </div>
        )}

        {/* Fetching indicator */}
        {isFetching && dayOfWeek === 3 && (
          <div className="text-align-center">
            <div className="mt-x-large p-medium bordered rounded inline-block bg-white shadow-box anim-fade-in-fast">
              <Icon type="loading" className="text-color-blue-lighter text-size-small mr-small" />
              <Text size="large" color="secondary">
                Fetching sessions...
              </Text>
            </div>
          </div>
        )}

        {/* Containers */}
        {hasSessions && (
          <div>
            {/* Item */}
            {_.map(sessions, (session) => (
              <SessionCard session={session} history={history} timezone={timezone} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
