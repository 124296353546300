import React, { Component } from 'react';
import { LeftNavItem } from 'common-components/navigation/LeftNavItem';
import { PAYMENTLIST_FILTERCONFIGS } from '../components/payListFilterConfig';

interface IPaylistNavigationSectionProps {
  currentFilterConfig: any;
  setFilter: any;
}

class PayListNavigationSection extends Component<IPaylistNavigationSectionProps, any> {
  _handleChangeFilter = (filterKey) => {
    const { setFilter } = this.props;
    setFilter(filterKey);
  };

  render() {
    const { currentFilterConfig } = this.props;

    return (
      <div className="bg-quaternary" style={{ width: '300px', overflowY: 'auto' }}>
        <div className="mt-x3-large mh-large">
          <div>
            <LeftNavItem
              filterConfig={PAYMENTLIST_FILTERCONFIGS.WAITING_TO_BE_PROCESSED}
              onClick={this._handleChangeFilter}
              isSelected={currentFilterConfig.key === PAYMENTLIST_FILTERCONFIGS.WAITING_TO_BE_PROCESSED.key}
            />
          </div>

          <div>
            <LeftNavItem
              filterConfig={PAYMENTLIST_FILTERCONFIGS.PROCESSED}
              onClick={this._handleChangeFilter}
              isSelected={currentFilterConfig.key === PAYMENTLIST_FILTERCONFIGS.PROCESSED.key}
            />
          </div>

          <div className="mb-large">
            <LeftNavItem
              filterConfig={PAYMENTLIST_FILTERCONFIGS.PAID}
              onClick={this._handleChangeFilter}
              isSelected={currentFilterConfig.key === PAYMENTLIST_FILTERCONFIGS.PAID.key}
            />
          </div>

          <div className="mb-large">
            <LeftNavItem
              filterConfig={PAYMENTLIST_FILTERCONFIGS.BATCH}
              onClick={this._handleChangeFilter}
              isSelected={currentFilterConfig.key === PAYMENTLIST_FILTERCONFIGS.BATCH.key}
            />
          </div>

          <div>
            <LeftNavItem
              filterConfig={PAYMENTLIST_FILTERCONFIGS.REJECTED}
              onClick={this._handleChangeFilter}
              isSelected={currentFilterConfig.key === PAYMENTLIST_FILTERCONFIGS.REJECTED.key}
            />
          </div>

          <div className="mb-large">
            <LeftNavItem
              filterConfig={PAYMENTLIST_FILTERCONFIGS.WAIVED}
              onClick={this._handleChangeFilter}
              isSelected={currentFilterConfig.key === PAYMENTLIST_FILTERCONFIGS.WAIVED.key}
            />
          </div>

          <div className="mb-large">
            <LeftNavItem
              filterConfig={PAYMENTLIST_FILTERCONFIGS.SHIFT_HOURS}
              onClick={this._handleChangeFilter}
              isSelected={currentFilterConfig.key === PAYMENTLIST_FILTERCONFIGS.SHIFT_HOURS.key}
            />
          </div>
        </div>
      </div>
    );
  }
}

export { PayListNavigationSection };
