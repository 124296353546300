import { Input, Upload, Icon } from 'antd';
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from '../../../../../common-components/buttons/index';
import ActionModal, { ActionModalFooter } from '../../../../../common-components/modal/ActionModal';
import { FieldLabel, Text } from '../../../../../common-components/typography/index';

interface IActivityRecordAddEditAttachmentWhenCreateModalProps {
  isOpen: boolean;
  editType: boolean;
  onClose: () => void;
  onApplyAttachment: (attachment) => void;
  attachment: any;
}

interface IActivityRecordAddEditAttachmentWhenCreateModalState {
  attachment: any;
  description: string;
  attachmentId: string;
  errorMessage: string;
}

class ActivityRecordAddEditAttachmentWhenCreateModal extends Component<
  IActivityRecordAddEditAttachmentWhenCreateModalProps,
  IActivityRecordAddEditAttachmentWhenCreateModalState
> {
  state = {
    attachment: null,
    description: '',
    attachmentId: uuidv4(),
    errorMessage: null
  };

  private _onCloseModel = () => {
    this.setState({
      attachment: null,
      description: '',
      attachmentId: uuidv4(),
      errorMessage: null
    });
    this.props.onClose();
  };

  private _onAddAttachment = () => {
    const { onApplyAttachment } = this.props;
    onApplyAttachment({
      attachment: this.state.attachment,
      description: this.state.description,
      attachmentId: this.state.attachmentId
    });

    this._onCloseModel();
  };

  private _hasExtension = (file) => {
    return new RegExp(
      '(' +
        [
          '.jpeg',
          '.jpg',
          '.png',
          '.gif',
          '.pdf',
          '.txt',
          '.ppt',
          '.pptx',
          '.doc',
          '.docx',
          '.csv',
          '.xls',
          '.xlsx',
          '.xlsm'
        ]
          .join('|')
          .replace(/\./g, '\\.') +
        ')$'
    ).test(file.name.toLowerCase());
  };

  private _validateFile = (file) => {
    const isValidType = this._hasExtension(file);
    this.setState({ errorMessage: null });
    if (!isValidType) {
      this.setState({ errorMessage: 'This file extension is not supported, please choose another format.' });
      return;
    } else {
      const isLt2M = file.size / 1024 / 1024 < 25;
      if (!isLt2M) {
        this.setState({ errorMessage: 'File must be smaller than 25MB!' });
        return;
      } else {
        this.setState({ attachment: file });
        return false;
      }
    }
  };

  private _onChangeDescription = (event) => {
    this.setState({ description: event.target.value });
  };

  componentDidMount() {
    const { attachment } = this.props;
    if (attachment) {
      this.setState({
        attachment: attachment.attachment,
        description: attachment.description,
        attachmentId: attachment.attachmentId
      });
    }
  }

  render() {
    const { isOpen, editType } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title={editType ? 'Edit attachment' : 'Add attachment'}
        width="medium"
        onClose={this._onCloseModel}
        verticalAlignment="highest"
        canCloseOutside={false}
      >
        <div>
          <FieldLabel text={'ATTACHMENT'} />
        </div>
        <div>
          <div className={'mt-small'}>
            {!this.state.attachment && <Text type={'secondary'}>No attachment</Text>}
            {this.state.attachment && <Text>{this.state.attachment.name}</Text>}
          </div>
          <div className={'mt-small'}>
            <Upload multiple={false} beforeUpload={this._validateFile} showUploadList={false}>
              {!this.state.attachment && (
                <SecondaryButton icon={'upload'} iconPosition={'left'}>
                  Select file
                </SecondaryButton>
              )}
              {this.state.attachment && (
                <HyperlinkButton>
                  <Icon type={'edit'} /> Select another file
                </HyperlinkButton>
              )}
            </Upload>
          </div>
        </div>
        {this.state.errorMessage && (
          <div className={'mt-medium'}>
            <Text color={'red'}>{this.state.errorMessage}</Text>
          </div>
        )}

        <div className={'mt-medium'}>
          <FieldLabel text={'DESCRIPTION'} />
          <Input
            placeholder={'Attachment description here...'}
            onChange={this._onChangeDescription}
            value={this.state.description}
            className={'mt-small'}
          />
        </div>

        <ActionModalFooter className={'mt-x-large'}>
          <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModel}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            size="large"
            onClick={this._onAddAttachment}
            disabled={!this.state.attachment || this.state.errorMessage}
          >
            Save
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

export default ActivityRecordAddEditAttachmentWhenCreateModal;
