import React, { Component } from 'react';
import _ from 'lodash';
import Title from 'antd/lib/typography/Title';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FieldLabel, Paragraph, Text } from 'common-components/typography';
import { Col, Row, Checkbox, Avatar, Icon, Tooltip } from 'antd';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import {
  OutsideAvailabilityType,
  ScheduleType,
  SupportWorkerAvailability,
  WorkerAssignmentWarningType
} from 'utilities/enum-utils';
import {
  IGroupServiceOverview,
  IGroupServiceSession,
  IGroupServiceSupportWorkersWithConflicts,
  IGroupServiceTimeSlot,
} from 'interfaces/service-interfaces';
import moment from 'moment-timezone';
import SpinningLoader from 'common-components/loading/SpinningLoader';

interface IManageSessionStepPanelProps {
  scheduleType: ScheduleType;
  selectedGroupService: IGroupServiceOverview;
  selectedWorkers: IGroupServiceSupportWorkersWithConflicts[];
  selectedSessions: IGroupServiceSession[];
  selectedTimeSlot: IGroupServiceTimeSlot;
  onPreviousStep: () => void;
  onNextStep: () => void;
  onSetSelectedWorker: (v) => void;
}

interface IManageSessionStepPanelState {
  focusedWorker: IGroupServiceSupportWorkersWithConflicts;
  selectedWorkers: IGroupServiceSupportWorkersWithConflicts[];
  isLoading: boolean;
}

class ManageSessionStepPanel extends Component<IManageSessionStepPanelProps, IManageSessionStepPanelState> {
  state = {
    focusedWorker: _.isEmpty(this.props.selectedWorkers) ? null : this.props.selectedWorkers[0],
    selectedWorkers: this.props.selectedWorkers,
    isLoading: false,
  };

  private _changeFocusWorker = (worker) => {
    if (worker.userId !== this.state.focusedWorker.userId) {
      this.setState({ focusedWorker: worker });
    }
  };

  private _addSessionToWorker = (session: IGroupServiceSession) => {
    const { focusedWorker } = this.state;
    if (!this._findSessionInWorker(session)) {
      focusedWorker.assignedSessions.push(session);
    }
  };

  private _removeSessionFromWorker = (session: IGroupServiceSession) => {
    const { focusedWorker } = this.state;
    if (this._findSessionInWorker(session)) {
      const newSessions = focusedWorker.assignedSessions.filter(
        (assignedSession) => assignedSession.serviceDateTimeId !== session.serviceDateTimeId,
      );
      focusedWorker.assignedSessions = newSessions;
    }

    this.setState({ focusedWorker });
  };

  private _findSessionInWorker = (session: IGroupServiceSession): boolean => {
    const { focusedWorker } = this.state;
    const findAssignedSessionInWorker = _.find(
      focusedWorker.assignedSessions,
      (assignedSession) => session.serviceDateTimeId === assignedSession.serviceDateTimeId,
    );

    return !!findAssignedSessionInWorker;
  };

  private _checkIsConflictSession = (
    session: IGroupServiceSession,
    worker: IGroupServiceSupportWorkersWithConflicts,
  ): boolean => {
    const findConflictSessionForWorker = _.find(worker.conflicts, (conflict) => {
      return conflict.serviceDateTimeId === session.serviceDateTimeId;
    });

    return findConflictSessionForWorker && findConflictSessionForWorker.warning !== WorkerAssignmentWarningType.CAUTION;
  };

  private _onNextStep = () => {
    const { onSetSelectedWorker, onNextStep } = this.props;
    const mappedSelectedWorker = _.map(this.state.selectedWorkers, (worker) => {
      const { removedOn } = worker;
      const removeDate = moment(removedOn);

      return {
        ...worker,
        assignedSessions: _.filter(worker.assignedSessions, (session) => {
          const diff = removeDate.diff(session.startDateTime, 'days');
          return removedOn ? !(removedOn && diff < 0) : session;
        }),
      };
    });

    onSetSelectedWorker(mappedSelectedWorker);
    onNextStep();
  };

  componentDidMount = () => {
    const { selectedSessions, selectedWorkers } = this.props;
    if (!selectedSessions || !selectedWorkers) return;
    this.setState({ isLoading: true });
    const newWorkers = _.map(selectedWorkers, (worker) => {
      worker.assignedSessions = _.filter(selectedSessions, (session) => !this._checkIsConflictSession(session, worker));
      return worker;
    });

    this.setState({ selectedWorkers: newWorkers, isLoading: false });
  };

  render() {
    const { selectedSessions } = this.props;
    const { focusedWorker, selectedWorkers, isLoading } = this.state;

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={3}>Session preview</Title>
              <Paragraph>
                Here are all the team members you have selected and the sessions you want to assign them to.
              </Paragraph>
              <Paragraph>
                You can remove team members from any specific sessions by unchecking the box of the corresponding
                session
              </Paragraph>
            </div>
          </Col>
          {isLoading ? (
            <SpinningLoader size={100} message="Loading..." />
          ) : (
            <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div className="bg-white ph-large pt-large pb-x2-large rounded-big" style={{ minWidth: '250px' }}>
                <Title level={4}>Team members assignment review</Title>
                <Paragraph className="mv-large">
                  Please review the following sessions for the assigned team members
                </Paragraph>
                <div className="bordered rounded-big mt-large">
                  <Row>
                    <Col span={8} className="flex-col  bordered-right" style={{ marginRight: '-1px' }}>
                      <Row style={{ height: '48px' }} type="flex" align="middle" className="pl-small bordered-bottom">
                        <Text size="regular" color="secondary">
                          {selectedWorkers.length} team member{selectedWorkers.length > 1 && 's'}
                        </Text>
                      </Row>
                      {_.map(selectedWorkers, (worker, index) => (
                        <WorkerItem
                          key={index}
                          worker={worker}
                          isFocus={focusedWorker.userId === worker.userId}
                          onChangeFocus={() => this._changeFocusWorker(worker)}
                        />
                      ))}
                    </Col>
                    <Col span={16} className="bordered-left">
                      <Row style={{ height: '48px' }} type="flex" align="middle" className="bordered-bottom ph-medium">
                        <Col span={2}></Col>
                        <Col span={9}>
                          <FieldLabel text="SESSION DATE" />
                        </Col>
                        <Col span={8}>
                          <FieldLabel text="TIME" />
                        </Col>
                        <Col span={5}></Col>
                      </Row>
                      {_.map(_.sortBy(selectedSessions, 'startDateTime'), (session, index) => (
                        <SessionItem
                          key={index}
                          selectedGroupService={this.props.selectedGroupService}
                          session={session}
                          focusedWorker={focusedWorker}
                          isChecked={this._findSessionInWorker(session)}
                          isConflict={this._checkIsConflictSession(session, focusedWorker)}
                          onAddSession={this._addSessionToWorker}
                          onRemoveSession={this._removeSessionFromWorker}
                        />
                      ))}
                    </Col>
                  </Row>
                </div>
              </div>

              <ActionModalFooter align="right" className="mt-small pr-large">
                <SecondaryButton size="large" className="mr-medium" onClick={this.props.onPreviousStep}>
                  Back
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._onNextStep}>
                  Next
                </PrimaryButton>
              </ActionModalFooter>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

interface IWorkerItemProps {
  worker: IGroupServiceSupportWorkersWithConflicts;
  isFocus: boolean;
  onChangeFocus: (v) => void;
}

function WorkerItem(props: IWorkerItemProps) {
  const { worker, isFocus, onChangeFocus } = props;
  const workerFullName = `${worker.firstName || ''} ${worker.lastName || ''}`;
  let style = 'flex-row justify-between align-center p-small';
  style += isFocus ? ' bg-blue-action-lightest' : ' cursor-pointer';

  const getWarningIcon = () => {
    switch (worker.availability) {
      case SupportWorkerAvailability.NOT_AVAILABLE:
        return <Icon type="warning" className="text-color-warning-orange mr-x-small" theme={'filled'} />;
      case SupportWorkerAvailability.LIMITED_AVAILABILITY:
        return <Icon type="info-circle" className="text-color-secondary mr-x-small" theme={'outlined'} />;
      default:
        return null;
    }
  };

  return (
    <div style={{ height: '48px' }} className={style} onClick={onChangeFocus}>
      <div>
        <Avatar src={worker.attachmentUrl} icon="user" className="mr-small" />
        <Text>{workerFullName}</Text>
      </div>
      <div>
        {worker.removedOn ? (
          <Icon type="warning" className="text-color-warning-orange mr-x-small" theme={'filled'} />
        ) : (
          getWarningIcon()
        )}
      </div>
    </div>
  );
}

interface ISessionItemProps {
  session: IGroupServiceSession;
  isChecked: boolean;
  isConflict: boolean;
  selectedGroupService: IGroupServiceOverview;
  focusedWorker: IGroupServiceSupportWorkersWithConflicts;
  onAddSession: (v) => void;
  onRemoveSession: (v) => void;
}

class SessionItem extends Component<ISessionItemProps> {
  private _onChangeCheckStatus = (e) => {
    const { onAddSession, onRemoveSession, session } = this.props;
    if (e.target.value) {
      onAddSession(session);
    } else {
      onRemoveSession(session);
    }
  };

  private _renderWarningTag = (isUnavailable = false) => {
    const { focusedWorker, session } = this.props;

    let warnSession = null;
    if (isUnavailable) {
      warnSession = { warning: WorkerAssignmentWarningType.UNAVAILABLE };
    } else {
      warnSession = _.find(
        focusedWorker.conflicts,
        (conflict) => conflict.serviceDateTimeId === session.serviceDateTimeId,
      );
    }

    if (!warnSession) return null;

    switch (warnSession.warning) {
      case WorkerAssignmentWarningType.UNAVAILABLE:
        return (
          <>
            <Icon type="warning" className="text-color-warning-orange mr-x-small" theme={'filled'} />
            <Text size="regular">Unavailable</Text>
          </>
        );
      case WorkerAssignmentWarningType.IN_SESSION:
        return (
          <>
            <Icon type="warning" className="text-color-secondary mr-x-small" theme={'filled'} />
            <Text size="regular">In session</Text>
          </>
        );
      case WorkerAssignmentWarningType.CAUTION:
        return (
          <>
            <Tooltip
              title={
                warnSession.note && warnSession.note === OutsideAvailabilityType.UNAVAILABILITY
                  ? 'This session occurs during a time where the team member has indicated they are unavailable. You can still assign them to this shift.'
                  : `This session is outside the team member's general availability.`
              }
              placement="top"
            >
              <Icon type="info-circle" className="text-color-secondary mr-x-small" theme={'outlined'} />
            </Tooltip>
            <Text size="regular">Caution</Text>
          </>
        );
      default:
        return null;
    }
  };

  render() {
    const { session, selectedGroupService, isChecked, isConflict, focusedWorker } = this.props;
    const { startDateTime, endDateTime } = session;
    const { timezone } = selectedGroupService;
    const sessionStart = moment.tz(startDateTime, timezone);
    const sessionEnd = moment.tz(endDateTime, timezone);
    const removeDate = moment(focusedWorker.removedOn);
    const diff = removeDate.diff(startDateTime, 'days');
    const isUnavailable = focusedWorker.removedOn && diff < 0;
    const opacity = isConflict || isUnavailable ? 0.5 : 1;

    const warnSession = _.find(
      focusedWorker.conflicts,
      (conflict) => conflict.serviceDateTimeId === session.serviceDateTimeId
    );

    return (
      <Row style={{ height: '48px', opacity }} type="flex" align="middle" className="ph-medium evenodd">
        <Col span={2}>
          <Checkbox
            checked={
              !isUnavailable ? (warnSession && warnSession.warning === WorkerAssignmentWarningType.CAUTION ? false : isChecked) : false
            }
            onChange={this._onChangeCheckStatus}
            disabled={!isUnavailable ? isConflict : true}
          />
        </Col>
        <Col span={9}>
          <Text size="regular">{sessionStart.format('ddd, D MMMM YYYY')}</Text>
        </Col>
        <Col span={8}>
          <Text size="regular">
            {sessionStart.format('h:mm A')} - {sessionEnd.format('h:mm A')}
          </Text>
        </Col>
        <Col span={5}>{this._renderWarningTag(isUnavailable)}</Col>
      </Row>
    );
  }
}

export default ManageSessionStepPanel;
