import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { SubTitle, Text } from 'common-components/typography';
import { Col, Form, notification, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import { parse } from 'json2csv';
import moment from 'moment';

const fileDownload = require('js-file-download');

interface IExportTeamMemberTagsDataModalProps extends FormComponentProps {
  isOpen: any;
  onClose: () => void;
  doFetchExportTeamMemberTags: typeof dispatch.accountStore.doFetchExportTeamMemberTags;
}

interface IExportTeamMemberTagsDataModalState {
  isExporting: boolean;
  exportStartDate: any;
  exportEndDate: any;
}

class ExportTeamMemberTagsDataModal extends Component<
  IExportTeamMemberTagsDataModalProps,
  IExportTeamMemberTagsDataModalState
> {
  state = {
    isExporting: false,
    exportStartDate: moment()
      .add(-6, 'months')
      .toDate(),
    exportEndDate: moment().toDate()
  };

  private _onCloseModal = () => {
    this.setState({ isExporting: false });
    this.props.onClose();
  };

  private _onChangeExportStartDate = (date) => {
    this.setState({ exportStartDate: date });
  };

  private _onChangeExportEndDate = (date) => {
    this.setState({ exportEndDate: date });
  };

  private _onClickExportData = async () => {
    this.setState({ isExporting: true });
    const { exportStartDate, exportEndDate } = this.state;
    const { doFetchExportTeamMemberTags } = this.props;

    let csvData;
    let csvRows: any = await doFetchExportTeamMemberTags({
      startDate: moment(exportStartDate)
        .startOf('day')
        .toDate(),
      endDate: moment(exportEndDate)
        .endOf('day')
        .toDate()
    });

    csvData = parse(csvRows);

    try {
      // Generate the csv
      fileDownload(csvData, `ExportTeamMemberTags${moment().format('YYYYMMDDHHmm')}.csv`);
    } catch (e) {
      notification.error({
        message: 'Error while generating the export team members tags file',
        description: 'The file generation failed, please try again.'
      });
    }
    this.setState({ isExporting: false });
  };

  render() {
    const { isOpen } = this.props;
    const { exportStartDate, exportEndDate, isExporting } = this.state;

    return (
      <ActionModal title={`Export team members' tags`} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        <div className="mv-medium anim-slide-left">
          <Text>
            You are about to export team members' tags based on selected date range as a CSV. This file will be
            available to you as a direct download.
          </Text>
          <div className="mt-x-large mb-x-large">
            <Row type="flex" align="middle" gutter={24}>
              <Col span={12}>
                <div>
                  <SubTitle>Start Date</SubTitle>
                  <DatePicker
                    className="gh-datepicker-plan-management rounded "
                    calendarClassName="gh-datepicker-calendar"
                    dateFormat="dd/MM/yyyy"
                    onChange={this._onChangeExportStartDate}
                    placeholderText={'Input start date'}
                    selected={exportStartDate}
                    disabled={isExporting}
                  />
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <SubTitle>End Date</SubTitle>
                  <DatePicker
                    className="gh-datepicker-plan-management rounded "
                    calendarClassName="gh-datepicker-calendar"
                    dateFormat="dd/MM/yyyy"
                    onChange={this._onChangeExportEndDate}
                    placeholderText={'Input end date'}
                    selected={exportEndDate}
                    disabled={isExporting}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <ActionModalFooter>
          <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal} disabled={isExporting}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" onClick={this._onClickExportData} disabled={isExporting} loading={isExporting}>
            Export
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchExportTeamMemberTags: dispatch.accountStore.doFetchExportTeamMemberTags
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IExportTeamMemberTagsDataModalProps>()(ExportTeamMemberTagsDataModal));
