import { Avatar, Col, Form, Icon, Input, notification, Radio, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import Title from 'antd/lib/typography/Title';
import { ErrorBanner, Information, Warning } from 'common-components/alerts';
import Validation from 'common-components/alerts/Validation';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import TimeInput from 'common-components/time-input/TimeInput';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { IGroupServiceCustomerRatio } from 'interfaces/service-interfaces';
import _ from 'lodash';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { TeamMemberCustomerRatio } from 'utilities/enum-utils';
import { v4 as uuidv4 } from 'uuid';

const moment = extendMoment(Moment);
interface IRadiosStepPanelProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setCustomerToSchedule?: typeof dispatch.servicesStore.setCustomerToSchedule;
  doFetchGroupServiceServiceAgreements?: typeof dispatch.groupServiceStore.doFetchGroupServiceServiceAgreements;
  customerToSchedule: typeof state.servicesStore.customerToSchedule;
  groupServiceServiceAgreements: typeof state.groupServiceStore.groupServiceServiceAgreements;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  history?: any;
}

interface IRadiosStepPanelState {
  isLoading: boolean;
  ratios: IGroupServiceCustomerRatio[];
}

class RadiosStepPanel extends Component<IRadiosStepPanelProps, IRadiosStepPanelState> {
  state = {
    isLoading: false,
    ratios: this.props.customerToSchedule.ratios ? this.props.customerToSchedule.ratios : null,
  };

  private _goToNext = async () => {
    const { setCustomerToSchedule, customerToSchedule, form } = this.props;
    const { ratios } = this.state;

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (this._checkTimeErrors()) {
      isFormValid = false;
    }

    if (isFormValid) {
      try {
        await setCustomerToSchedule({
          ...customerToSchedule,
          ratios,
        });
        this.props.onNextStep();
      } catch (e) {
        notification.error({ message: 'Oops, something went wrong! Please try again.' });
      }
    }
  };

  private _goToPrevious = async () => {
    const { setCustomerToSchedule, customerToSchedule, onPreviousStep } = this.props;
    const newCustomerToSchedule = _.cloneDeep(customerToSchedule);
    delete newCustomerToSchedule['ratios'];
    await setCustomerToSchedule({
      ...newCustomerToSchedule,
    });
    onPreviousStep();
  };

  private _checkTimeErrors = () => {
    const { customerToSchedule } = this.props;
    const { ratios } = this.state;
    let isThereSomeErrors = false;
    this.setState({
      ratios: _.map(ratios, (ratio) => {
        if (ratio.isCustomRatio) {
          const errors: any = { isAnyGap: false, isConflict: false };

          // Making sure the first custom ratio is the earliest ratio
          const orderedCustomTimes = _.sortBy(ratio.customRatio, 'startDateTime', 'ASC');

          const customRatio = _.map(orderedCustomTimes, (customTime, index) => {
            let isAnyGap = false;
            const isTheLast = index === orderedCustomTimes.length - 1;

            // Check that the custom time is within the session's times
            //For customRatio, the sessions times has to be the same, so we are using the first session's time for the checks
            const isStartingBeforeSessionStart =
              index === 0 &&
              moment(customTime.startDateTime).isBefore(moment(customerToSchedule.sessions[0].startDateTime));
            const isEndingAfterSessionEnd =
              isTheLast && moment(customTime.endDateTime).isAfter(moment(customerToSchedule.sessions[0].endDateTime));

            // Check for any conflict between the custom ranges
            const isConflict = _.some(
              _.filter(orderedCustomTimes, (c) => c.customTimeId !== customTime.customTimeId),
              (filteredList) =>
                moment
                  .range([customTime.startDateTime, customTime.endDateTime])
                  .overlaps(moment.range([filteredList.startDateTime, filteredList.endDateTime])),
            );
            if (isConflict) {
              errors.isConflict = true;
            }
            if (
              index === 0 &&
              !moment(customTime.startDateTime).isSameOrBefore(moment(customerToSchedule.sessions[0].startDateTime))
            ) {
              isAnyGap = true;
            }
            if (
              isTheLast &&
              !moment(customTime.endDateTime).isSameOrAfter(moment(customerToSchedule.sessions[0].endDateTime))
            ) {
              isAnyGap = true;
            }
            if (
              !isTheLast &&
              moment(customTime.endDateTime).isBefore(moment(orderedCustomTimes[index + 1].startDateTime))
            ) {
              isAnyGap = true;
            }
            if (isAnyGap) {
              errors.isAnyGap = true;
            }
            if (isAnyGap || isStartingBeforeSessionStart || isEndingAfterSessionEnd || isConflict) {
              isThereSomeErrors = true;
            }
            return { ...customTime, timeErrors: { isStartingBeforeSessionStart, isEndingAfterSessionEnd } };
          });

          return { ...ratio, customRatio, errors };
        } else {
          return { ...ratio };
        }
      }),
    });
    return isThereSomeErrors;
  };

  private _setRatioType = (group, isCustomRatio) => {
    this.setState({
      ratios: _.map(this.state.ratios, (currentGroup) => {
        if (
          group.startDateTime === currentGroup.startDateTime &&
          group.userServiceAgreementId === currentGroup.userServiceAgreementId
        ) {
          return {
            ...group,
            isCustomRatio,
          };
        } else {
          return { ...group };
        }
      }),
    });
  };

  private _changeRatio = (value, group, customTimeId = null) => {
    const ratioData = group.isCustomRatio
      ? _.map(group.customRatio, (customTime) => {
          if (customTime.customTimeId === customTimeId) {
            return { ...customTime, teamMemberCustomerRatio: value };
          } else {
            return { ...customTime };
          }
        })
      : value;
    this.setState({
      ratios: _.map(this.state.ratios, (currentGroup) => {
        if (
          group.startDateTime === currentGroup.startDateTime &&
          group.userServiceAgreementId === currentGroup.userServiceAgreementId
        ) {
          return {
            ...group,
            customRatio: group.isCustomRatio
              ? ratioData
              : [
                  {
                    ...group.customRatio[0],
                    teamMemberCustomerRatio: ratioData,
                  },
                ],
          };
        } else {
          return currentGroup;
        }
      }),
    });
  };

  private _onChangeComment = (event, group, customTimeId) => {
    this.setState({
      ratios: _.map(this.state.ratios, (currentGroup) => {
        if (
          group.startDateTime === currentGroup.startDateTime &&
          group.userServiceAgreementId === currentGroup.userServiceAgreementId
        ) {
          return {
            ...group,
            customRatio: _.map(group.customRatio, (customTime) => {
              if (customTime.customTimeId === customTimeId) {
                return { ...customTime, comments: event.target.value };
              } else {
                return { ...customTime };
              }
            }),
          };
        } else {
          return { ...group };
        }
      }),
    });
  };

  private _setDate = (event, group, formName, customTimeId, dateType) => {
    const roundedValue = CommonUtils.formatCeilingDateTime(moment(event));
    this.setState(
      {
        ratios: _.map(this.state.ratios, (currentGroup) => {
          if (
            group.startDateTime === currentGroup.startDateTime &&
            group.userServiceAgreementId === currentGroup.userServiceAgreementId
          ) {
            return {
              ...group,
              errors: null,
              customRatio: _.map(group.customRatio, (customTime) => {
                if (customTime.customTimeId === customTimeId) {
                  return {
                    ...customTime,
                    [dateType]: roundedValue,
                    timeErrors: null,
                  };
                } else {
                  return { ...customTime };
                }
              }),
            };
          } else {
            return { ...group };
          }
        }),
      },
      () => this.props.form.setFieldsValue({ [formName]: roundedValue }),
    );
  };

  private _deleteCustomTime = (group, customTimeId) => {
    this.setState({
      ratios: _.map(this.state.ratios, (currentGroup) => {
        if (
          group.startDateTime === currentGroup.startDateTime &&
          group.userServiceAgreementId === currentGroup.userServiceAgreementId
        ) {
          return {
            ...group,
            customRatio: _.filter(group.customRatio, (customTime) => customTime.customTimeId !== customTimeId),
          };
        } else {
          return { ...group };
        }
      }),
    });
  };

  private _addTimeSlot = (group) => {
    const { customerToSchedule } = this.props;
    const newCustomTimes = group.customRatio;
    const lastCustomTime = newCustomTimes[newCustomTimes.length - 1];
    newCustomTimes.push({
      customTimeId: uuidv4(),
      startDateTime: moment(lastCustomTime.endDateTime),
      endDateTime: customerToSchedule.sessions[0].endDateTime,
      teamMemberCustomerRatio: group.teamMemberCustomerRatio && group.teamMemberCustomerRatio.ndis,
    });
    this.setState({
      ratios: _.map(this.state.ratios, (currentGroup) => {
        if (
          group.startDateTime === currentGroup.startDateTime &&
          group.userServiceAgreementId === currentGroup.userServiceAgreementId
        ) {
          return {
            ...group,
            customRatio: newCustomTimes,
          };
        } else {
          return { ...group };
        }
      }),
    });
  };

  componentDidMount = async () => {
    const { selectedGroupService, customerToSchedule } = this.props;
    this.setState({ isLoading: true });
    await this.props.doFetchGroupServiceServiceAgreements({
      serviceId: this.props.selectedGroupService.serviceId,
      customerUserId: customerToSchedule.selectedCustomer.userId,
      serviceDateTimeIds: _.map(customerToSchedule.sessions, (session) => session.serviceDateTimeId),
    });
    this.setState({
      isLoading: false,
      ratios: customerToSchedule.ratios
        ? customerToSchedule.ratios
        : _.map(this.props.groupServiceServiceAgreements, (group) => {
            return {
              ...group,
              startDateTime: moment(
                moment.tz(group.startDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm'),
              ).toDate(),
              endDateTime: moment(
                moment.tz(group.endDateTime, selectedGroupService.timezone).format('YYYY-MM-DD HH:mm'),
              ).toDate(),
              isCustomRatio: false,
              customRatio: [
                {
                  customTimeId: uuidv4(),
                  startDateTime: customerToSchedule.sessions[0].startDateTime,
                  endDateTime: customerToSchedule.sessions[0].endDateTime,
                  teamMemberCustomerRatio: group.teamMemberCustomerRatio && group.teamMemberCustomerRatio.ndis,
                },
              ],
            };
          }),
    });
  };

  render() {
    const { customerToSchedule, form } = this.props;
    const { isLoading, ratios } = this.state;
    const { getFieldDecorator } = form;

    const areAllSessionTimeTheSame =
      customerToSchedule &&
      customerToSchedule.sessions &&
      customerToSchedule.sessions.length > 0 &&
      _.every(
        customerToSchedule.sessions,
        (session) =>
          moment(session.startDateTime).format('HH:mm') ===
            moment(customerToSchedule.sessions[0].startDateTime).format('HH:mm') &&
          moment(session.endDateTime).format('HH:mm') ===
            moment(customerToSchedule.sessions[0].endDateTime).format('HH:mm'),
      );
    const isOneOfTheSessionOnMultipleDays =
      customerToSchedule &&
      customerToSchedule.sessions &&
      customerToSchedule.sessions.length > 0 &&
      _.some(customerToSchedule.sessions, (ratio) =>
        moment(ratio.startDateTime)
          .startOf('day')
          .isSame(moment(ratio.endDateTime).endOf('day')),
      );

    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>Select customer ratio</Title>
              <Paragraph>Select the ratio you want to apply for the sessions the customer is added to.</Paragraph>
              <Paragraph>This can be changed at a later date if required.</Paragraph>
              {customerToSchedule && customerToSchedule.selectedCustomer ? (
                <div className={'p-medium bg-white rounded-big'}>
                  <SubTitle>Selected customer</SubTitle>
                  <div className={'flex-row align-center mt-small'}>
                    <Avatar
                      className={'mr-small'}
                      shape={'circle'}
                      icon={'user'}
                      src={customerToSchedule.selectedCustomer.attachmentUrl}
                    />{' '}
                    {customerToSchedule.selectedCustomer.firstName} {customerToSchedule.selectedCustomer.lastName}
                  </div>
                </div>
              ) : (
                <Text color={'secondary'}>No customer selected</Text>
              )}
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
              <Title level={4}>Customer ratio</Title>
              <Paragraph>
                Please specify the customer ratio for this customer. This ratio can be changed at anytime when managing
                this customers booking.
              </Paragraph>
              <div className={'mt-large'}>
                {isLoading ? (
                  <SpinningLoader size={50} message={'Fetching the service agreement(s)..'} />
                ) : (
                  <>
                    {ratios && (
                      <div className={'mb-medium'}>
                        {ratios.length === 1 && ratios[0].userServiceAgreementId && (
                          <Validation
                            content={'The customer has a service agreement for the dates of the sessions.'}
                            className={'mb-small'}
                          />
                        )}
                        {ratios.length > 1 && _.some(ratios, (group) => !group.userServiceAgreementId) && (
                          <Warning
                            content={
                              'The customer does not have a service agreement for certain dates of the sessions being added.'
                            }
                            className={'mb-small'}
                          />
                        )}
                        {ratios.length > 1 && _.every(ratios, (group) => group.userServiceAgreementId) && (
                          <Warning
                            content={'The selected sessions fall under multiple service agreements.'}
                            className={'mb-small'}
                          />
                        )}
                        {_.every(ratios, (group) => !group.userServiceAgreementId) && (
                          <Warning
                            content={
                              'The customer does not have any service agreements for any of the sessions selected.'
                            }
                            className={'mb-small'}
                          />
                        )}
                      </div>
                    )}
                    {!areAllSessionTimeTheSame && (
                      <Information
                        content={
                          <Text>
                            The ability to apply custom ratios is not available as you have selected sessions that have
                            differing start/end times.
                            <br />
                            To apply custom ratios, selected sessions must have the same start/end times.
                          </Text>
                        }
                      />
                    )}
                    {isOneOfTheSessionOnMultipleDays && (
                      <Information
                        content={
                          <Text>
                            Custom ratios cannot be applied if you have selected sessions which run over multiple days.
                          </Text>
                        }
                      />
                    )}
                    <div className={'mt-large'} style={{ maxWidth: '1000px' }}>
                      {_.map(ratios, (group, index) => {
                        return (
                          <div className={'mb-large rounded-big bordered border-secondary border-width-medium'}>
                            <div className={'bg-quaternary p-medium'}>
                              {ratios.length === 1 ? (
                                <Text>
                                  Default ratio based on{' '}
                                  <b>
                                    {group.userServiceAgreementId ? `customer's service agreement` : `service ratio`}
                                  </b>
                                </Text>
                              ) : (
                                <>
                                  <Text>
                                    Ratio for sessions from <b>{moment(group.startDateTime).format('DD/MM/YYYY')}</b> to{' '}
                                    <b>{moment(group.endDateTime).format('DD/MM/YYYY')}</b>
                                  </Text>
                                  <br />
                                  <Text size={'regular'}>
                                    Based on{' '}
                                    {group.userServiceAgreementId ? `customer's service agreement` : `service ratio`}
                                  </Text>
                                </>
                              )}
                            </div>
                            <div className={'p-medium'}>
                              {group.errors && group.errors.isAnyGap && (
                                <ErrorBanner
                                  className={'mv-small'}
                                  content={'There cannot be any gap between timeslots or with the service times'}
                                />
                              )}
                              {group.errors && group.errors.isConflict && (
                                <ErrorBanner className={'mv-small'} content={'Timeslots cannot overlap'} />
                              )}
                              <div>
                                <Radio checked={!group.isCustomRatio} onClick={() => this._setRatioType(group, false)}>
                                  Use single ratio for entire session
                                </Radio>
                                {!group.isCustomRatio && (
                                  <div className={'mt-x-small ml-large'}>
                                    <SubTitle>Customer ratio</SubTitle>
                                    <Form.Item>
                                      {getFieldDecorator(group.scheduleId + '_default_ratio' + index, {
                                        initialValue: group.customRatio[0].teamMemberCustomerRatio,
                                        rules: [
                                          {
                                            required: true,
                                            message: 'Select ratio',
                                          },
                                        ],
                                      })(
                                        <Select
                                          onChange={(event) => this._changeRatio(event, group)}
                                          style={{ width: '100px' }}
                                        >
                                          {_.map(TeamMemberCustomerRatio, (ratio) => {
                                            return <Select.Option value={ratio}>{ratio}</Select.Option>;
                                          })}
                                        </Select>,
                                      )}
                                    </Form.Item>
                                  </div>
                                )}
                              </div>
                              <div className={'mt-large'}>
                                <Radio
                                  checked={group.isCustomRatio}
                                  onClick={() => this._setRatioType(group, true)}
                                  disabled={!areAllSessionTimeTheSame || isOneOfTheSessionOnMultipleDays}
                                >
                                  Use custom ratio by hour
                                </Radio>
                                {group.isCustomRatio && (
                                  <div className={'rounded-big mt-x-small ml-large bg-tertiary p-medium'}>
                                    {_.map(group.customRatio, (custom, index) => {
                                      const isATimeError =
                                        custom.timeErrors &&
                                        (custom.timeErrors.isStartingBeforeSessionStart ||
                                          custom.timeErrors.isEndingAfterSessionEnd);
                                      return (
                                        <div
                                          className={
                                            'bg-white rounded-big bordered border-standard-grey p-medium mb-large'
                                          }
                                        >
                                          <Row gutter={24}>
                                            <Col span={10}>
                                              <SubTitle>Time</SubTitle>
                                              <div className={'flex-row'}>
                                                <div className={'mr-small'}>
                                                  {getFieldDecorator(group.scheduleId + '_startDateTime_' + index, {
                                                    initialValue: custom.startDateTime,
                                                  })(
                                                    <TimeInput
                                                      size={'large'}
                                                      className={isATimeError && 'border-red-dark'}
                                                      onChange={(event) =>
                                                        this._setDate(
                                                          event,
                                                          group,
                                                          group.scheduleId + '_startDateTime_' + index,
                                                          custom.customTimeId,
                                                          'startDateTime',
                                                        )
                                                      }
                                                    />,
                                                  )}
                                                </div>
                                                <div>
                                                  {getFieldDecorator(group.scheduleId + '_endDateTime_' + index, {
                                                    initialValue: custom.endDateTime,
                                                  })(
                                                    <TimeInput
                                                      size={'large'}
                                                      className={isATimeError && 'border-red-dark'}
                                                      onChange={(event) =>
                                                        this._setDate(
                                                          event,
                                                          group,
                                                          group.scheduleId + '_endDateTime_' + index,
                                                          custom.customTimeId,
                                                          'endDateTime',
                                                        )
                                                      }
                                                    />,
                                                  )}
                                                </div>
                                              </div>
                                              {custom.timeErrors && custom.timeErrors.isStartingBeforeSessionStart && (
                                                <Text color={'red-dark'}>
                                                  Start time cannot be before the session&apos;s start.
                                                  <br />
                                                </Text>
                                              )}
                                              {custom.timeErrors && custom.timeErrors.isEndingAfterSessionEnd && (
                                                <Text color={'red-dark'}>
                                                  End time cannot be after the session&apos;s end.
                                                </Text>
                                              )}
                                            </Col>
                                            <Col span={4}>
                                              <SubTitle>Ratio</SubTitle>
                                              <Form.Item className={'m-none'}>
                                                {getFieldDecorator(group.scheduleId + '_ratio_' + index, {
                                                  initialValue: custom.teamMemberCustomerRatio,
                                                  rules: [
                                                    {
                                                      required: true,
                                                      message: 'Select ratio',
                                                    },
                                                  ],
                                                })(
                                                  <Select
                                                    onChange={(event) =>
                                                      this._changeRatio(event, group, custom.customTimeId)
                                                    }
                                                    size={'large'}
                                                    style={{ width: '100px' }}
                                                  >
                                                    {_.map(TeamMemberCustomerRatio, (ratio) => {
                                                      return <Select.Option value={ratio}>{ratio}</Select.Option>;
                                                    })}
                                                  </Select>,
                                                )}
                                              </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                              <SubTitle>Comments</SubTitle>
                                              <Form.Item className={'m-none'}>
                                                {getFieldDecorator(group.scheduleId + '_comment_' + index, {
                                                  initialValue: custom.comments ? custom.comments : undefined,
                                                })(
                                                  <Input
                                                    type={'text'}
                                                    maxLength={100}
                                                    onChange={(event) =>
                                                      this._onChangeComment(event, group, custom.customTimeId)
                                                    }
                                                    size={'large'}
                                                    placeholder={'Add comments...'}
                                                  />,
                                                )}
                                              </Form.Item>
                                            </Col>
                                            <Col span={2} className={'text-align-right'}>
                                              {group.customRatio.length > 1 &&
                                                Number(index) === group.customRatio.length - 1 && (
                                                  <Icon
                                                    type={'delete'}
                                                    theme={'filled'}
                                                    onClick={() => this._deleteCustomTime(group, custom.customTimeId)}
                                                    className={'text-color-blue-action cursor-pointer'}
                                                    style={{ marginTop: '32px' }}
                                                  />
                                                )}
                                            </Col>
                                          </Row>
                                        </div>
                                      );
                                    })}
                                    {/*In customRatio is available all session have the same start and end date.*/}
                                    {/*If the last customRatio endDate is equal or greater than the sessions' endDate, slot cannot be created.*/}
                                    {group.customRatio &&
                                    group.customRatio[group.customRatio.length - 1].endDateTime >=
                                      customerToSchedule.sessions[0].endDateTime ? (
                                      <Text color={'secondary'}>
                                        <Icon type={'plus'} className={'mr-small'} /> Add slot
                                      </Text>
                                    ) : (
                                      <HyperlinkButton onClick={() => this._addTimeSlot(group)}>
                                        <Icon type={'plus'} className={'mr-small'} />
                                        Add slot
                                      </HyperlinkButton>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              className="pv-medium width-full"
              style={{ backgroundColor: 'rgba(248,249,250,0.7)', position: 'sticky', bottom: 0 }}
            >
              <Row gutter={0} type="flex" align="middle" justify={'space-between'} className="bg-transparent">
                <div className="text-align-right pv-medium">
                  <SecondaryButton size="large" disabled={isLoading} onClick={this._goToPrevious}>
                    Back
                  </SecondaryButton>
                </div>
                <div className="text-align-right pv-medium">
                  <PrimaryButton size="large" loading={isLoading} onClick={this._goToNext}>
                    Next
                  </PrimaryButton>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setCustomerToSchedule: dispatch.servicesStore.setCustomerToSchedule,
  doFetchGroupServiceServiceAgreements: dispatch.groupServiceStore.doFetchGroupServiceServiceAgreements,
});

const mapState = (state: IRootState) => ({
  customerToSchedule: state.servicesStore.customerToSchedule,
  groupServiceServiceAgreements: state.groupServiceStore.groupServiceServiceAgreements,
  selectedGroupService: state.groupServiceStore.selectedGroupService,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IRadiosStepPanelProps>()(RadiosStepPanel));
