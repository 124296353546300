import { Icon, Menu } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import PermissionUtils from 'utilities/permission-utils';

const SubMenu = Menu.SubMenu;
const MenuItem = Menu.Item;

class SideNavMenu extends Component<any, any> {
  _handleClick = async (e) => {
    const { history } = this.props;
    const { key } = e;

    key !== 'no_redirect' && (await this.props.setSelectedSideNavMenuKeys([key]));
    // For now, it will navigate to the value of the key.
    // Not sure if there's a better way to do this.

    if (key === '/bookings') {
      const { doResetBookingSearch } = this.props;
      // doResetBookingSearch({});
      // const filter = [
      //   FilterUtils.bookingStatusToFilter('status', [
      //     'PENDING',
      //     'ACCEPTED',
      //     'CONFIRMED',
      //     'INPROGRESS',
      //     'CANCEL_SHORT_NOTICE'
      //   ])
      // ];
      // setBookingsFilter(filter);
    }

    // TODO Change this to resolve the actual names instead
    key !== 'no_redirect' && history.push(key);
  };

  render() {
    const { portalUser, companyDataLite } = this.props;

    return (
      <Menu
        theme={'dark'}
        mode={'inline'}
        style={{ width: '216px' }}
        onClick={this._handleClick}
        selectedKeys={this.props.selectedSideNavMenuKeys}
      >
        <MenuItem key={'/dashboard'}>
          <Icon type={'dashboard'} />
          <span>Dashboard</span>
        </MenuItem>

        {/*{PermissionUtils.validatePermission('ViewBookingListing', portalUser.permissions.permissionRoles) && (*/}
        {/*  <MenuItem key={'/bookings'}>*/}
        {/*    <Icon type={'book'} />*/}
        {/*    <span>Bookings</span>*/}
        {/*  </MenuItem>*/}
        {/*)}*/}

        {PermissionUtils.validatePermission('ViewBookingListing', portalUser.permissions.permissionRoles) && (
          <MenuItem key={'/bookings'}>
            <Icon type={'book'} />
            <span>Bookings</span>
          </MenuItem>
        )}

        {PermissionUtils.validatePermission('ViewBookingListing', portalUser.permissions.permissionRoles) && (
          <MenuItem key={'/bookings/calendar'}>
            <Icon type={'calendar'} />
            <span>Calendar</span>
          </MenuItem>
        )}

        {PermissionUtils.validatePermission('ViewGroupSessions', portalUser.permissions.permissionRoles) && (
          <MenuItem key={'/group-service/sessions'}>
            <Icon type={'book'} />
            <span>Group sessions</span>
          </MenuItem>
        )}

        {PermissionUtils.validatePermission('ViewPlanManagement', portalUser.permissions.permissionRoles) &&
          companyDataLite &&
          companyDataLite.hasPlanManagement && (
            <SubMenu
              key="plan-management"
              title={
                <>
                  <Icon type="credit-card" />
                  <span>Plan Management</span>
                </>
              }
            >
              <MenuItem key={'/plan-management'}>
                <Icon type={'reconciliation'} />
                <span>Overview</span>
              </MenuItem>
              <MenuItem key={'/plan-management/payments'}>
                <Icon type={'bank'} />
                <span>Payments</span>
              </MenuItem>
            </SubMenu>
          )}
        {PermissionUtils.validatePermission('ViewTeamListing', portalUser.permissions.permissionRoles) && (
          <MenuItem key={'/team'}>
            <Icon type={'team'} />
            <span>Team</span>
          </MenuItem>
        )}
        {PermissionUtils.validatePermission('ViewCustomerListing', portalUser.permissions.permissionRoles) && (
          <MenuItem key={'/customers'}>
            <Icon type={'contacts'} />
            <span>Customers</span>
          </MenuItem>
        )}
        {PermissionUtils.validatePermission('ViewServiceListing', portalUser.permissions.permissionRoles) && (
          <MenuItem key={'/services'}>
            <Icon type={'apartment'} />
            <span>Services</span>
          </MenuItem>
        )}
        {PermissionUtils.validatePermission('ViewServiceMessagesListing', portalUser.permissions.permissionRoles) && (
          <MenuItem key={'/messaging'}>
            <Icon type={'mail'} />
            <span>Messaging</span>
          </MenuItem>
        )}
        {/*<MenuItem key={'/stories'}>*/}
        {/*  <Icon type={'picture'} />*/}
        {/*  <span>Stories</span>*/}
        {/*</MenuItem>*/}
        {/*<MenuItem key={'/messages'}>*/}
        {/*  <Icon type={'message'} />*/}
        {/*  <span>Messaging</span>*/}
        {/*</MenuItem>*/}
        {/* TODO Do we really need this here?  */}
        <MenuItem key={'/notifications'}>
          <Icon type={'bell'} />
          <span>Notifications</span>
        </MenuItem>
        {/* Temporarily menu */}
        {/* <MenuItem key={'/bookings-approve'}>
          <Icon type={'audit'} />
          <span>Booking Approval</span>
        </MenuItem> */}
        {/* <SubMenu
          key="payment"
          title={
            <>
              <Icon type="money-collect" />
              <span>Payment</span>
            </>
          }
        > */}
        {PermissionUtils.validatePermission('ViewPayments', portalUser.permissions.permissionRoles) && (
          <MenuItem key={'/payments'}>
            <Icon type={'bank'} />
            <span>Payments</span>
          </MenuItem>
        )}
        {PermissionUtils.validatePermission('ViewTeamManagement', portalUser.permissions.permissionRoles) && (
          <MenuItem key={'/account/landing'}>
            <Icon type={'setting'} />
            <span>Account</span>
          </MenuItem>
        )}
        <MenuItem key={'no_redirect'} onClick={() => CommonUtils.sendToZendesk(this.props.portalUser)}>
          <Icon type={'profile'} />
          <span>Support</span>
        </MenuItem>
      </Menu>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  companyDataLite: state.companyStore.companyDataLite,
  selectedSideNavMenuKeys: state.navigationStore.selectedSideNavMenuKeys,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setBookingsFilter: dispatch.bookingsStore.setBookingsFilter,
  setSelectedSideNavMenuKeys: dispatch.navigationStore.setSelectedSideNavMenuKeys,
  // doResetBookingSearch: dispatch.bookingsStore.doResetBookingSearch
});

export default connect(mapState, mapDispatch)(withRouter(SideNavMenu));
