import React, { Component } from 'react';
import { Text, Title } from 'common-components/typography';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';
// Panels
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import SelectCustomerStepPanel from 'views/group-services/service-details/customer-schedule/wizard-steps/SelectCustomerStepPanel';
import ActionTypeStepPanel from 'views/group-services/service-details/customer-schedule/wizard-steps/ActionTypeStepPanel';
import SessionReviewStepPanel from 'views/group-services/service-details/customer-schedule/wizard-steps/SessionsReviewStepPanel';
import SummaryStepPanel from 'views/group-services/service-details/customer-schedule/wizard-steps/SummaryStepPanel';
import AddCompletedStepPanel from 'views/group-services/service-details/customer-schedule/wizard-steps/AddCompletedStepPanel';
import SchedulesStepPanel from 'views/group-services/service-details/customer-schedule/wizard-steps/SchedulesStepPanel';
import RatiosStepPanel from 'views/group-services/service-details/customer-schedule/wizard-steps/RatiosStepPanel';
import { GroupBookingActionType } from 'utilities/enum-utils';
import _ from 'lodash';

interface IAddCustomerToSessionModalProps {
  isOpen: boolean;
  history: any;
  closeCustomerToSessionModal: (sessionData?: any) => void;
  doAddCustomerToSession?: typeof dispatch.servicesStore.doAddCustomerToSession;
  setCustomerToSchedule?: typeof dispatch.servicesStore.setCustomerToSchedule;
  customerToSchedule: typeof state.servicesStore.customerToSchedule;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  setIsSuccessAddedCustomerToSession: () => void;
}

interface IAddCustomerToSessionModalState {
  step: number;
  totalStep: number;
  isProceedOpen: boolean;
  actionType: string;
}

class AddCustomerToSessionModal extends Component<IAddCustomerToSessionModalProps, IAddCustomerToSessionModalState> {
  state = {
    step: 1,
    totalStep: 6,
    isProceedOpen: false,
    scheduleType: null,
    actionType: GroupBookingActionType.INDIVIDUAL_SESSIONS
  };
  private _onNextStep = () => {
    this.setState({ step: this.state.step + 1 });
  };

  private _onPreviousStep = () => {
    this.setState({ step: this.state.step - 1 });
  };

  private _goToSession = async (serviceDateTimeId) => {
    const { history, selectedGroupService } = this.props;
    history.push(`/group-service/${selectedGroupService.serviceId}/session/details/${serviceDateTimeId}`);
    this._resetAndCloseModal();
  };

  private _closeCreateSessionModal = async () => {
    const { customerToSchedule } = this.props;
    const { step } = this.state;

    let hasUnsavedData = false;

    if (
      customerToSchedule &&
      step !== (customerToSchedule.actionType === GroupBookingActionType.INDIVIDUAL_SESSIONS ? 6 : 7)
    ) {
      hasUnsavedData =
        !_.isEmpty(customerToSchedule.selectedCustomer) ||
        !_.isEmpty(customerToSchedule.actionType) ||
        !_.isEmpty(customerToSchedule.sessions) ||
        !_.isEmpty(customerToSchedule.ratios);
    }

    if (hasUnsavedData) {
      this.setState({ isProceedOpen: true });
    } else {
      this._resetAndCloseModal();
    }
  };

  private _resetAndCloseModal = () => {
    this.setState({ isProceedOpen: false, step: 1 });
    this.props.closeCustomerToSessionModal();
    this.props.setCustomerToSchedule([]);
  };

  private _renderStepContent = () => {
    const { step, actionType } = this.state;

    if (step === 1) {
      return <SelectCustomerStepPanel onNextStep={this._onNextStep} />;
    } else if (step === 2) {
      return (
        <ActionTypeStepPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          updateActionType={this._updateActionType}
        />
      );
    } else if (step === 3) {
      return actionType === GroupBookingActionType.INDIVIDUAL_SESSIONS ? (
        <SessionReviewStepPanel onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />
      ) : (
        <SchedulesStepPanel onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />
      );
    } else if (step === 4) {
      return actionType === GroupBookingActionType.INDIVIDUAL_SESSIONS ? (
        <RatiosStepPanel onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />
      ) : (
        <SessionReviewStepPanel onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />
      );
    } else if (step === 5) {
      return actionType === GroupBookingActionType.INDIVIDUAL_SESSIONS ? (
        <SummaryStepPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          setIsSuccessAddedCustomerToSession={this.props.setIsSuccessAddedCustomerToSession}
        />
      ) : (
        <RatiosStepPanel onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />
      );
    } else if (step === 6) {
      return actionType === GroupBookingActionType.INDIVIDUAL_SESSIONS ? (
        <AddCompletedStepPanel closeWizard={this._closeCreateSessionModal} goToSession={this._goToSession} />
      ) : (
        <SummaryStepPanel
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          setIsSuccessAddedCustomerToSession={this.props.setIsSuccessAddedCustomerToSession}
        />
      );
    } else if (step === 7) {
      return <AddCompletedStepPanel closeWizard={this._closeCreateSessionModal} goToSession={this._goToSession} />;
    }
  };

  private _updateActionType = (actionType) => {
    this.setState({ actionType, totalStep: actionType === GroupBookingActionType.INDIVIDUAL_SESSIONS ? 6 : 7 });
  };

  private _closeProceedModal = () => {
    this.setState({ isProceedOpen: false });
  };

  render() {
    const { actionType } = this.state;
    let headerTitle = (
      <Title level={2} className="m-none">
        Add customer to sessions
      </Title>
    );

    let steps = [];
    for (let i = 1; i <= this.state.totalStep; i++) {
      let stepObj = {};
      let stepTitle = 'Select customer';

      if (actionType && actionType === GroupBookingActionType.INDIVIDUAL_SESSIONS) {
        stepTitle =
          i === 1
            ? 'Select customer'
            : i === 2
            ? 'Action type'
            : i === 3
            ? 'Sessions'
            : i === 4
            ? 'Ratios'
            : i === 5
            ? 'Review'
            : 'Done!';
      } else {
        stepTitle =
          i === 1
            ? 'Select customer'
            : i === 2
            ? 'Action type'
            : i === 3
            ? 'Schedules'
            : i === 4
            ? 'Sessions'
            : i === 5
            ? 'Ratios'
            : i === 6
            ? 'Review'
            : 'Done!';
      }

      stepObj['stepNumber'] = i;
      stepObj['stepLabel'] = i;
      stepObj['stepTitle'] = stepTitle;
      steps.push(stepObj);
    }

    return (
      <>
        <ActionModal
          isOpen={this.state.isProceedOpen}
          onClose={this._closeProceedModal}
          title={'Cancelling adding customer to sessions'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, are you sure you want to <b>cancel</b> now ?
          </Text>
          <br />
          <Text className={'mb-medium'} weight={'bold'}>
            Your progress will not be saved.
          </Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeProceedModal}>
              Back
            </PrimaryButton>
            <GhostButton size="large" onClick={this._resetAndCloseModal}>
              Cancel
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <FullScreenScrollableModal
          isOpen={this.props.isOpen}
          onClose={this._closeCreateSessionModal}
          width={'full'}
          headerTitle={headerTitle}
          bgColor="tertiary"
          canCloseEsc={false}
          fixedHeight={false}
          noMarginBottom={true}
          contentPadding={false}
          hasFooter={false}
          hasSteps={true}
          currentStep={this.state.step}
          steps={steps}
        >
          {this._renderStepContent()}
        </FullScreenScrollableModal>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  customerToSchedule: state.servicesStore.customerToSchedule,
  selectedGroupService: state.groupServiceStore.selectedGroupService
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setCustomerToSchedule: dispatch.servicesStore.setCustomerToSchedule,
  doAddCustomerToSession: dispatch.servicesStore.doAddCustomerToSession
});

export default connect(
  mapState,
  mapDispatch
)(AddCustomerToSessionModal);
