import React, { Component } from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import { Col, Row } from 'antd';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import GeneralAvailability from './components/Availability/GeneralAvailability';
import UnavailableTimes from './components/Unavailability/UnavailableTimes';
import EditGeneralAvailabilityModal from './components/Availability/EditGeneralAvailabilityModal';
import UnavailableTimeModal from './components/Unavailability/UnavailableTimeModal';

interface IWorkerDetailTimeAvailabilityPanelProps {
  doGetTimeAvailability: typeof dispatch.teamStore.doGetTimeAvailability;
  selectedWorkerItem: any;
  hasEditPermission: boolean;
  companyDataLite: typeof state.companyStore.companyDataLite;
  memberAvailabilities: typeof state.teamStore.memberAvailabilities;
}

interface IWorkerDetailTimeAvailabilityPanelState {
  isEdit: boolean;
  isLoading: boolean;
  isActionModalOpen: boolean;
  showEditAvailability: boolean;
  showUnavailableModal: boolean;
  selectedUnavailableItem: any;
}

class WorkerDetailTimeAvailabilityPanel extends Component<
  IWorkerDetailTimeAvailabilityPanelProps,
  IWorkerDetailTimeAvailabilityPanelState
> {
  state = {
    isEdit: false,
    isLoading: false,
    isActionModalOpen: false,
    showEditAvailability: false,
    showUnavailableModal: false,
    selectedUnavailableItem: null,
  };

  private _weekDayString = (day) => {
    switch (day) {
      case 'MON':
        return 'Monday';
      case 'TUE':
        return 'Tuesday';
      case 'WED':
        return 'Wednesday';
      case 'THU':
        return 'Thursday';
      case 'FRI':
        return 'Friday';
      case 'SAT':
        return 'Saturday';
      case 'SUN':
        return 'Sunday';
      default:
        return '';
    }
  };

  private _availabilitiesMapping = (availabilities) => {
    const {
      memberAvailabilities: { availabilityTimezone },
    } = this.props;
    const localTimeZone = moment.tz.guess();
    const mappedList = availabilities
      ? _.map(availabilities, (item) => ({
          day: this._weekDayString(item.day),
          number: item.number,
          timeRange: _.map(item.timeRange, (time) => ({
            startDateTime: moment.tz(time.startTime, availabilityTimezone),
            endDateTime: moment.tz(time.endTime, availabilityTimezone),
            localStartDateTime: moment.tz(time.startTime, availabilityTimezone).tz(localTimeZone, true),
            localEndDateTime: moment.tz(time.endTime, availabilityTimezone).tz(localTimeZone, true),
            error: null,
          })),
          isAvailableAllDay: item.isAllDay,
          week: item.weeklyCycle,
        }))
      : [];
    const sunday = _.filter(mappedList, (item) => item.number === 0);
    const listWithoutSunday = _.filter(mappedList, (item) => item.number !== 0);
    const sortedList = _.sortBy(listWithoutSunday, (item) => item.number);

    return sortedList.concat(sunday);
  };

  private _showEditGeneralAvailability = () => {
    this.setState({ showEditAvailability: true });
  };

  private _closeEditGeneralAvailability = () => {
    this.setState({ showEditAvailability: false });
  };

  private _showUnavailableModal = (selectedItem?: any) => {
    if (selectedItem) {
      this.setState({ showUnavailableModal: true, selectedUnavailableItem: selectedItem });
    } else {
      this.setState({ showUnavailableModal: true, selectedUnavailableItem: null });
    }
  };

  private _closeUnavailableModal = () => {
    this.setState({ showUnavailableModal: false, selectedUnavailableItem: null });
  };

  private _availabilityTimezone = () => {
    const { memberAvailabilities } = this.props;

    return {
      type: memberAvailabilities.userTimezone === memberAvailabilities.availabilityTimezone ? 'PREFERRED' : 'CUSTOM',
      value: memberAvailabilities.availabilityTimezone,
    };
  };

  private getTimeAvailability = async () => {
    this.setState({ isLoading: true });
    try {
      const result = await this.props.doGetTimeAvailability({});
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  componentDidMount() {
    this.getTimeAvailability();
  }

  render() {
    const { memberAvailabilities, companyDataLite } = this.props;
    const mappedAvailabilities = this._availabilitiesMapping(memberAvailabilities.availabilities);

    return (
      <React.Fragment>
        {this.state.showEditAvailability && (
          <EditGeneralAvailabilityModal
            isOpen={this.state.showEditAvailability}
            onClose={this._closeEditGeneralAvailability}
            dateArray={mappedAvailabilities}
            userTimezone={memberAvailabilities.userTimezone}
            availabilityTimezone={memberAvailabilities.availabilityTimezone}
            cycle={memberAvailabilities.availabilityCycle}
          />
        )}
        {this.state.showUnavailableModal && (
          <UnavailableTimeModal
            isOpen={this.state.showUnavailableModal}
            onClose={this._closeUnavailableModal}
            selectedItem={this.state.selectedUnavailableItem}
            timezone={memberAvailabilities.userTimezone}
          />
        )}
        <Row type={'flex'} gutter={20}>
          <Col span={12}>
            <GeneralAvailability
              hasEditPermission={this.props.hasEditPermission}
              availableTimes={mappedAvailabilities}
              onEdit={this._showEditGeneralAvailability}
              timezone={this._availabilityTimezone() as any}
              cycle={memberAvailabilities.availabilityCycle}
              activeWeek={memberAvailabilities.availabilityActiveWeek}
              activeDate={memberAvailabilities.activeDate}
              isLoading={this.state.isLoading}
            />
          </Col>
          <Col span={12}>
            <UnavailableTimes
              hasEditPermission={this.props.hasEditPermission}
              unavailableTimes={memberAvailabilities.unavailableTimes}
              openUnavailableModal={this._showUnavailableModal}
              isLoading={this.state.isLoading}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyDataLite: state.companyStore.companyDataLite,
  memberAvailabilities: state.teamStore.memberAvailabilities,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetTimeAvailability: dispatch.teamStore.doGetTimeAvailability,
});

export default connect(mapState, mapDispatch)(WorkerDetailTimeAvailabilityPanel);
