import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Paragraph, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Col, Form, Icon, Radio, Row, Select } from 'antd';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { ErrorSVG } from 'assets/UndrawSVG';
import { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import { EditRecurringMode } from 'utilities/enum-utils';
import _ from 'lodash';
import OptionsSelectorRow from 'views/bookings/components/OptionsSelectorRow';

interface IBookingCancelChangeToCustomerCancelledModalProps extends FormComponentProps {
  onClose: () => void;
  selectedBooking: typeof state.bookingsStore.selectedBookingItem;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
  doChangeGroupBookingToCustomerCancel: typeof dispatch.groupBookingsStore.doChangeGroupBookingToCustomerCancel;
  serviceType: string;
  isOpen: boolean;
  doChangeToCustomerCancelled: typeof dispatch.bookingsStore.doChangeToCustomerCancelled;
  doFetchNumberOfBookingsForRecurrence: typeof dispatch.bookingsStore.doFetchNumberOfBookingsForRecurrence;
}

class BookingCancelChangeToCustomerCancelledModal extends Component<
  IBookingCancelChangeToCustomerCancelledModalProps,
  any
> {
  state = {
    step: 2,
    title: 'Cancel on behalf of the customer',
    canManuallyClose: true,
    selectedOption: EditRecurringMode.Current,
    numberOfBookings: 1,
    selectedCustomerCancelledOption: 1,
    numberOfBookingsToCharge: 1,
    formValues: null,
    isCharge: false,
    isChargeError: false,
    totalNumberOfImpactedBookings: 0
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;

    // Do any manual clean up; reset to initial state
    this._reset();

    onClose();
  };

  private _displayRecurringOptions = () => {
    this.setState({ step: 5 });
  };

  private _checkIfRecurring = () => {
    this.props.form.validateFieldsAndScroll(async (error, value) => {
      if (!error) {
        this.setState({ formValues: value });
        if (
          this.props.serviceType === 'SUPPORT' &&
          this.props.selectedBooking.isRecurring &&
          this.state.totalNumberOfImpactedBookings > 1
        ) {
          this._displayRecurringOptions();
        } else {
          this._goToChargeStep();
        }
      }
    });
  };

  private _doChangeToCustomerCancelled = async () => {
    this.setState({ step: 2, canManuallyClose: false });
    try {
      if (this.props.serviceType === 'SUPPORT') {
        await this.props.doChangeToCustomerCancelled({
          editRecurringMode: this.state.selectedOption,
          bookingRequestId: this.props.selectedBooking.bookingRequestId,
          isRecurring: this.props.selectedBooking.isRecurring,
          isCharge: this.state.isCharge,
          numberOfBookingsToCharge:
            this.state.selectedCustomerCancelledOption === 1
              ? 0
              : this.state.selectedCustomerCancelledOption === 2
              ? 1
              : this.state.totalNumberOfImpactedBookings,
          ...this.state.formValues
        });
      } else {
        const { selectedGroupBookingItem } = this.props;
        const { formValues } = this.state;
        const isCustomerNoShowValue = this.props.form.getFieldValue('chargeCustomerCancellationOption');
        await this.props.doChangeGroupBookingToCustomerCancel({
          isCharge: this.state.isCharge,
          serviceId: selectedGroupBookingItem.serviceId,
          attendanceIds: [selectedGroupBookingItem.bookingId],
          serviceDateTimeId: selectedGroupBookingItem.serviceDateTimeId,
          cancellationReason: formValues.reason,
          cancellationCode: formValues.cancellationReason,
          isCustomerNoShow: isCustomerNoShowValue === 1 ? true : isCustomerNoShowValue === 0 ? false : null
        });
      }
      this.setState({
        title: (
          <>
            {this.props.serviceType === 'SUPPORT' ? (
              <>
                Booking changed to Cancelled by Customer
                <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
              </>
            ) : (
              <>Booking status changed</>
            )}
          </>
        ),
        step: 3,
        canManuallyClose: true
      });
    } catch (e) {
      this.setState({ step: 4, canManuallyClose: true });
    }
  };

  private _goToChargeStep = () => {
    this.setState({
      step: this.state.selectedOption !== EditRecurringMode.Current ? 7 : 6
    });
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _selectOption = (isCharge) => {
    this.setState({ isCharge }, () => {
      if (
        isCharge &&
        this.props.serviceType === 'SUPPORT' &&
        this.props.selectedBooking.isRecurring &&
        this.state.selectedOption === EditRecurringMode.CurrentAll
      ) {
        this.setState({ step: 7 });
      } else {
        this._doChangeToCustomerCancelled();
      }
    });
  };

  private _validateCancellationReason = (rule, value, callback) => {
    try {
      if (!value || value === '') {
        throw Error('You must add a cancellation reason.');
      } else if (_.trim(value).length < 5) {
        throw Error('Please enter at least 5 characters.');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _onChangeCustomerCancelledOption = (event) => {
    this.setState({
      selectedCustomerCancelledOption: event.target.value,
      isCharge: event.target.value > 1
    });
  };

  private _reset = () =>
    this.setState({
      step: 2,
      title: 'Cancel on behalf of the customer',
      canManuallyClose: true,
      selectedOption: EditRecurringMode.Current,
      numberOfBookings: 1,
      numberOfBookingsToCharge: 1,
      selectedCustomerCancelledOption: 1,
      formValues: null,
      isCharge: false,
      totalNumberOfImpactedBookings: 0
    });

  private _onChangeIsChargeOption = (event) => {
    this.setState({ isCharge: event.target.value, isChargeError: false });
  };

  componentDidMount = async () => {
    const { selectedBooking, serviceType } = this.props;
    if (serviceType === 'SUPPORT') {
      const relatedBookings: any = await this.props.doFetchNumberOfBookingsForRecurrence({
        bookingRequestId: selectedBooking.bookingRequestId,
        status: selectedBooking.status
      });
      this.setState({ totalNumberOfImpactedBookings: 1 + relatedBookings.length, step: 1 });
    } else {
      this.setState({ totalNumberOfImpactedBookings: 1, step: 1 });
    }
  };

  componentDidUpdate = async (prevProps: Readonly<IBookingCancelChangeToCustomerCancelledModalProps>) => {
    if (prevProps.isOpen === false && this.props.isOpen) {
      const { selectedBooking, serviceType } = this.props;
      if (serviceType === 'SUPPORT') {
        const relatedBookings: any = await this.props.doFetchNumberOfBookingsForRecurrence({
          bookingRequestId: selectedBooking.bookingRequestId,
          status: selectedBooking.status
        });
        this.setState({ totalNumberOfImpactedBookings: 1 + relatedBookings.length, step: 1 });
      } else {
        this.setState({ totalNumberOfImpactedBookings: 1, step: 1 });
      }
    }
  };

  private _renderView = () => {
    const { step, totalNumberOfImpactedBookings } = this.state;
    const { selectedBooking, form, serviceType, selectedGroupBookingItem } = this.props;
    const { getFieldDecorator } = form;

    const currentBooking = serviceType === 'SUPPORT' ? selectedBooking : selectedGroupBookingItem;

    if (step === 1) {
      return (
        <div className="mv-medium anim-slide-left">
          <div style={{ minHeight: 317 }} className="mt-medium">
            <Paragraph>
              Please select the reason the customer asked you to <b>cancel</b> this booking.
            </Paragraph>

            <div className="">
              <Row className="mb-small" type="flex" align="top">
                <Col span={24}>
                  <Form.Item>
                    {getFieldDecorator('cancellationReason', {
                      initialValue: currentBooking.cancellationCode ? currentBooking.cancellationCode : undefined,
                      rules: [{ required: true, message: 'Please select cancellation reason' }]
                    })(
                      <Select className="width-2/3" size={'large'} placeholder={'Cancellation reason'}>
                        <Select.Option value="NSDH">No show due to health reasons (NSDH)</Select.Option>
                        <Select.Option value="NSDF">No show due to family reasons (NSDF)</Select.Option>
                        <Select.Option value="NSDT">No show due to unavailability of transport (NSDT)</Select.Option>
                        <Select.Option value="NSDO">Other</Select.Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              {form.getFieldValue('cancellationReason') === 'NSDO' && (
                <Row className="mb-small" type="flex" align="top">
                  <Col span={24}>
                    <div className={'mb-medium'}>
                      <Text>Please specify the reason</Text>
                    </div>
                    <Form.Item>
                      {getFieldDecorator('reason', {
                        initialValue: currentBooking.cancellationReason,
                        rules: [{ validator: this._validateCancellationReason }]
                      })(
                        <TextArea
                          className="width-full"
                          placeholder={'Cancellation reason'}
                          autoSize={{ minRows: 3, maxRows: 6 }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>
          </div>
          <ActionModalFooter>
            <GhostButton size="large" className="mr-medium" onClick={this._reset}>
              Back
            </GhostButton>
            <PrimaryButton size="large" onClick={this._checkIfRecurring}>
              Proceed
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (step === 2) {
      return (
        <div>
          <div style={{ minHeight: 317 }}>
            <SpinningLoader size={150} message={'Loading'} />
          </div>
        </div>
      );
    }
    if (step === 3) {
      return (
        <div>
          <div>
            {this.props.serviceType === 'SUPPORT' ? (
              <div className="mb-medium">
                {!currentBooking.isRecurring ? (
                  <Paragraph>
                    You have successfully changed the status of this booking so that it has been <b>cancelled</b> by the
                    customer and you will <b>{!this.state.isCharge && 'not '}</b>be charging a <b>cancellation fee</b>.
                  </Paragraph>
                ) : (
                  <>
                    {' '}
                    <Paragraph>
                      You have successfully changed the status of{' '}
                      {this.state.selectedOption === EditRecurringMode.Current
                        ? 'this booking'
                        : this.state.totalNumberOfImpactedBookings + ' bookings'}{' '}
                      to 'Cancelled by Customer'.
                    </Paragraph>
                    <Paragraph>
                      {this.state.isCharge
                        ? this.state.selectedCustomerCancelledOption === 3
                          ? `A cancellation fee has been applied to all of these bookings`
                          : `A cancellation fee has been applied to this booking`
                        : `No cancellation fee has been applied`}
                      .
                    </Paragraph>
                  </>
                )}
              </div>
            ) : (
              <div className="mb-medium">
                <Paragraph>
                  You have successfully changed this customers booking status from <b>Business cancelled</b> to{' '}
                  <b>Customer cancelled - {this.state.isCharge ? 'Fee' : 'No fee'}</b>
                </Paragraph>
              </div>
            )}
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (step === 4) {
      return (
        <div>
          <div style={{ minHeight: 317 }} className="flex-column justify-center text-align-center">
            <div className="pv-medium flex-column justify-center">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium flex-column justify-center ">
              <Paragraph>Oops something has gone wrong, please try again</Paragraph>
            </div>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Back to booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (this.state.step === 5) {
      return (
        <>
          <div className="anim-slide-left">
            <Paragraph>
              This booking is part of a recurring series. There are{' '}
              <b>
                {totalNumberOfImpactedBookings} booking{totalNumberOfImpactedBookings !== 1 && 's'}
              </b>{' '}
              that are currently 'Cancelled by Business'.
            </Paragraph>
          </div>
          <div>
            <Radio.Group value={this.state.selectedOption} onChange={this._onChangeOption} className="ml-medium">
              <Radio
                value={EditRecurringMode.Current}
                className={`${this.state.selectedOption === EditRecurringMode.Current && 'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Change to 'Cancelled by Customer' for this booking only.
                </div>
              </Radio>
              <br />
              <Radio
                value={EditRecurringMode.CurrentAll}
                className={`${this.state.selectedOption === EditRecurringMode.CurrentAll &&
                  'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Change to 'Cancelled by Customer' for all {totalNumberOfImpactedBookings} bookings.
                </div>
              </Radio>
            </Radio.Group>
          </div>
          <div className={'mt-large'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._goToChargeStep}>
                Continue
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (step === 6) {
      return (
        <div>
          {this.props.serviceType === 'SUPPORT' ? (
            <>
              <div className="anim-slide-left">
                <div className="mb-medium">
                  <Paragraph>Please select one of the following options. You can change your mind later.</Paragraph>
                </div>

                <div className="text-align-left mb-x-large">
                  <OptionsSelectorRow
                    title={'Charge a cancellation fee'}
                    description={'Select this option if you wish to charge a cancellation fee for this booking.'}
                    onClick={() => this._selectOption(true)}
                  />
                  <OptionsSelectorRow
                    title={'Do not charge a cancellation fee'}
                    description={'Select this option if you do not wish to charge a cancellation fee for this booking.'}
                    onClick={() => this._selectOption(false)}
                  />

                  <div className="bordered-top" />
                </div>
              </div>

              <ActionModalFooter>
                <GhostButton size="large" onClick={this._onCloseModal}>
                  Back
                </GhostButton>
              </ActionModalFooter>
            </>
          ) : (
            <>
              <div className="anim-slide-left">
                <div className="mb-medium">
                  <Paragraph>Please specify the cancellation reason for cancelling this booking.</Paragraph>
                </div>
                <div className=" mb-x-large">
                  <Radio
                    value={true}
                    onChange={this._onChangeIsChargeOption}
                    checked={this.state.isCharge}
                    className={`${this.state.isCharge === true && 'text-weight-bold'} mb-small width-full`}
                  >
                    <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                      Charge a cancellation fee
                    </div>
                  </Radio>
                  {this.state.isCharge === true && (
                    <Form.Item className="mt-small">
                      {getFieldDecorator('chargeCustomerCancellationOption', {
                        initialValue: undefined,
                        rules: [{ required: true, message: 'Please select a charge cancellation option' }]
                      })(
                        <Select
                          className="width-2/3"
                          size="large"
                          placeholder={'Select a charge cancellation option'}
                          dropdownMatchSelectWidth={true}
                        >
                          <Select.Option value={1}>Customer was a no show for the booking</Select.Option>
                          <Select.Option value={0}>Customer cancelled ahead of time</Select.Option>
                        </Select>
                      )}
                    </Form.Item>
                  )}
                  <Radio
                    value={false}
                    onChange={this._onChangeIsChargeOption}
                    checked={this.state.isCharge === false}
                    className={`${this.state.isCharge === false && 'text-weight-bold'} mb-small `}
                  >
                    <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                      Do not charge a cancellation fee
                    </div>
                  </Radio>
                  {this.state.isChargeError && <div className="text-color-red-dark">Please select an option</div>}
                </div>
              </div>
              <ActionModalFooter>
                <GhostButton size="large" onClick={this._onCloseModal}>
                  Back
                </GhostButton>
                <PrimaryButton size="large" onClick={this._doChangeToCustomerCancelled}>
                  Confirm
                </PrimaryButton>
              </ActionModalFooter>
            </>
          )}
        </div>
      );
    }
    if (this.state.step === 7) {
      return (
        <>
          <div className="anim-slide-left">
            <Paragraph>
              You are changing {totalNumberOfImpactedBookings} booking
              {totalNumberOfImpactedBookings && 's'} from 'Cancelled by Business' to 'Cancelled by Customer'. Do you
              want to charge a cancellation fee for these bookings?
            </Paragraph>
          </div>
          <div>
            <Radio.Group
              value={this.state.selectedCustomerCancelledOption}
              onChange={this._onChangeCustomerCancelledOption}
              className="ml-medium"
            >
              <Radio
                value={1}
                className={`${this.state.selectedCustomerCancelledOption === 1 && 'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Do not charge a cancellation fee for any bookings.
                </div>
              </Radio>
              <br />
              <Radio
                value={2}
                className={`${this.state.selectedCustomerCancelledOption === 2 && 'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Charge cancellation fee for this booking only.
                </div>
              </Radio>
              <br />
              <Radio
                value={3}
                className={`${this.state.selectedCustomerCancelledOption === 3 && 'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Charge cancellation fee for all {totalNumberOfImpactedBookings} booking
                  {totalNumberOfImpactedBookings !== 1 && 's'}.
                </div>
              </Radio>
            </Radio.Group>
          </div>
          <div className={'mt-large'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={() => this.setState({ step: 5 })}>
                Back
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._doChangeToCustomerCancelled}>
                Continue
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
  };

  render() {
    const { isOpen } = this.props;

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="large"
        verticalAlignment="highest"
      >
        {this._renderView()}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBooking: state.bookingsStore.selectedBookingItem,
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doChangeToCustomerCancelled: dispatch.bookingsStore.doChangeToCustomerCancelled,
  doChangeGroupBookingToCustomerCancel: dispatch.groupBookingsStore.doChangeGroupBookingToCustomerCancel,
  doFetchNumberOfBookingsForRecurrence: dispatch.bookingsStore.doFetchNumberOfBookingsForRecurrence
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IBookingCancelChangeToCustomerCancelledModalProps>()(BookingCancelChangeToCustomerCancelledModal));
