/* eslint-disable */
import { Card, Divider, Icon, message, Typography } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IRootState } from 'src/stores/rematch/root-store';
import firebaseApp from 'stores/firebase-app';
import CommonUtils from 'utilities/common-utils';

const { Text } = Typography;

class ProfileMenu extends Component<any, any> {
  _onClickLogout = async () => {
    const { history } = this.props;
    history.push('/sign-out');
    this.props.setIsSignOut(true);
    this.props.setIsForgetPassword(false);
    await message.loading('Signing out...', 1);
    await firebaseApp.auth.signOut();

    this.props.setCurrentUser(null);
    this.props.setCurrentPortalUser(null);
    this.props.setPortalUserList(null);

    message.success('Signed out.');
  };

  render() {
    const { history, portalUser } = this.props;

    return (
      <Card style={{ marginTop: '-5px', marginRight: '4px' }} bodyStyle={{ padding: '0px' }}>
        {/*<div style={{ marginLeft: '16px' }} />*/}
        <div style={{ padding: '8px 24px' }}>
          <Text strong>Hello, {portalUser.firstName && portalUser.firstName}!</Text>
        </div>
        <Divider style={{ marginTop: '0px', marginBottom: '0px' }} />
        <div style={{ padding: '8px 24px' }}>
          <Text>
            Signed in to <Text strong>{portalUser.serviceProviderName}</Text>
          </Text>
          {/*<br />*/}
          {/*<Text>*/}
          {/*  Role: <Text strong>{portalUser.role && _.capitalize(portalUser.role)}</Text>*/}
          {/*</Text>*/}
        </div>
        <Divider style={{ marginTop: '0px', marginBottom: '0px' }} />
        <div className="mv-small mh-large">
          <HyperlinkButton onClick={() => CommonUtils.sendToZendesk(portalUser)}>Help Center</HyperlinkButton>
          <br />
          <HyperlinkButton
            onClick={() =>
              CommonUtils.sendToZendesk(
                portalUser,
                'https://goodhuman.zendesk.com/hc/en-au/articles/900002375743-Workspace-Updates',
              )
            }
          >
            What's New
          </HyperlinkButton>
        </div>

        <Divider style={{ marginTop: '0px', marginBottom: '0px' }} />

        <div className="mv-small mh-large">
          <HyperlinkButton onClick={() => history.push('/my-profile')}>
            <Icon type={'user'} /> View My Profile
          </HyperlinkButton>
          <br />
          <HyperlinkButton onClick={this._onClickLogout}>
            <Icon type={'logout'} /> Sign out
          </HyperlinkButton>
        </div>
        {/*<Menu>*/}
        {/*  <Menu.Item>*/}
        {/*    <Icon type={'user'} /> View My Profile*/}
        {/*  </Menu.Item>*/}
        {/*  <Menu.Item onClick={() => this._onClickLogout()}>*/}
        {/*    <Icon type={'logout'} /> Sign out*/}
        {/*  </Menu.Item>*/}
        {/*</Menu>*/}
      </Card>
    );
  }
}

const mapState = (state: IRootState) => ({
  authStore: state.authStore,
  currentUser: state.authStore.currentUser,
});

const mapDispatch = (dispatch) => ({
  // setAppLoaded: dispatch.authStore.setAppLoaded,
  setIsSignOut: dispatch.authStore.setIsSignOut,
  setCurrentUser: dispatch.authStore.setCurrentUser,
  setCurrentPortalUser: dispatch.authStore.setCurrentPortalUser,
  setPortalUserList: dispatch.authStore.setPortalUserList,
  setIsForgetPassword: dispatch.authStore.setIsForgetPassword,
});

export default connect(mapState, mapDispatch)(ProfileMenu);
