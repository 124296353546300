import { Avatar, notification, Select, Spin } from 'antd';
import Form, { FormComponentProps } from 'antd/es/form';
import Error from 'common-components/alerts/BookingErrorBanner';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { CustomerAdditionalInfos } from 'common-components/tooltips';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { CustomerType } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import CustomerRatioSelectionPanel from 'views/group-services/components/CustomerRatioSelectioPanel';

interface IAddCustomerModalProps extends FormComponentProps {
  isOpen: boolean;
  history: any;
  onClose: (resetList?: boolean) => void;
  doGetCustomersLite: typeof dispatch.customersStore.doGetCustomersLite;
  setCustomersLite: typeof dispatch.customersStore.setCustomersLite;
  doFetchGroupServiceServiceAgreements: typeof dispatch.groupServiceStore.doFetchGroupServiceServiceAgreements;
  doAddCustomerToSession: typeof dispatch.servicesStore.doAddCustomerToSession;
  selectedSession: typeof state.groupServiceStore.selectedSession;
  customersLite: typeof state.customersStore.customersLite;
  groupServiceServiceAgreements: typeof state.groupServiceStore.groupServiceServiceAgreements;
  sessionCustomerDetails: typeof state.groupServiceStore.sessionCustomerDetails;
  portalUser: typeof state.authStore.portalUser;
}

interface IAddCustomerModalState {
  step: number;
  isLoading: boolean;
  isSearching: boolean;
  selectedCustomer: any;
  selectedRatioText: any;
  isDisplayScheduledToBeArchivedMessage: boolean;
}

class AddCustomerModal extends Component<IAddCustomerModalProps, IAddCustomerModalState> {
  private customerRatioRef: any;

  state = {
    step: 1,
    isLoading: false,
    isSearching: false,
    selectedCustomer: null,
    selectedRatioText: null,
    isDisplayScheduledToBeArchivedMessage: false
  };

  private _setRef = (ref) => {
    if (ref) {
      this.customerRatioRef = ref;
    }
  };

  private _onNextStep = async () => {
    this.setState({ isLoading: true });
    const { form } = this.props;
    const { step, selectedCustomer } = this.state;

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      if (step === 1) {
        await this.props.doFetchGroupServiceServiceAgreements({
          serviceId: this.props.selectedSession.serviceId,
          customerUserId: selectedCustomer.userId,
          serviceDateTimeIds: [this.props.selectedSession.serviceDateTimeId]
        });
      }
      this.setState({ step: this.state.step + 1 });
    }
    this.setState({ isLoading: false });
  };

  private _onPreviousStep = () => {
    this.setState({ step: this.state.step - 1 });
  };

  private _onCloseModal = () => {
    this.props.onClose(this.state.step === 3);
    this.setState({
      step: 1,
      selectedRatioText: null,
      selectedCustomer: null,
      isDisplayScheduledToBeArchivedMessage: false
    });
  };

  private _searchText = async (txt) => {
    const { doGetCustomersLite } = this.props;
    await doGetCustomersLite({
      search: txt,
      userType: CustomerType.CUSTOMERS,
      sortByRelevance: true
    });
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    if (e.length >= 1) {
      this.setState({ isSearching: true });
      this._debounceSearch(e);
    } else if (e.length === 0) {
      this.props.setCustomersLite({});
    }
  };

  private _changeSelectedCustomer = (customerId) => {
    const { selectedSession, customersLite } = this.props;
    const selectedCustomer = _.find(customersLite, (customer) => customer.userId === customerId);
    this.setState({
      selectedCustomer,
      isDisplayScheduledToBeArchivedMessage:
        selectedCustomer.scheduleArchiveDate &&
        moment
          .tz(selectedSession.endDateTime, selectedSession.timezone)
          .isSameOrAfter(moment.tz(selectedCustomer.scheduleArchiveDate, selectedSession.timezone))
    });
  };

  private _addCustomerToSession = () => {
    this.customerRatioRef._validateRatios();
  };

  private _getSelectedSessionWithoutTimezone = () => {
    const { selectedSession } = this.props;
    return {
      ...selectedSession,
      startDateTime: moment(
        moment.tz(selectedSession.startDateTime, selectedSession.timezone).format('YYYY-MM-DD HH:mm')
      ).toDate(),
      endDateTime: moment(
        moment.tz(selectedSession.endDateTime, selectedSession.timezone).format('YYYY-MM-DD HH:mm')
      ).toDate()
    };
  };

  private _saveRatio = async (ratios) => {
    const { selectedSession } = this.props;
    const { selectedCustomer } = this.state;

    const selectedSessionWithTimezone = this._getSelectedSessionWithoutTimezone();

    // A single session can only have one ratio;
    const selectedRatio = {
      ...ratios[0],
      startDateTime: moment.tz(moment(ratios[0].startDateTime).format('YYYY-MM-DD HH:mm'), selectedSession.timezone),
      endDateTime: moment.tz(moment(ratios[0].endDateTime).format('YYYY-MM-DD HH:mm'), selectedSession.timezone)
    };
    try {
      this.setState({ isLoading: true });
      await this.props.doAddCustomerToSession({
        serviceId: selectedSession.serviceId,
        customerUserId: selectedCustomer.userId,
        sessions: [
          {
            serviceDateTimeId: selectedSession.serviceDateTimeId,
            isCustomRatio: selectedRatio.isCustomRatio,
            customRatio: _.map(selectedRatio.customRatio, (customTime) => {
              return {
                ...customTime,
                teamMemberCustomerRatio: customTime.teamMemberCustomerRatio.ndis
                  ? customTime.teamMemberCustomerRatio.ndis
                  : customTime.teamMemberCustomerRatio,
                startDateTime: selectedRatio.isCustomRatio
                  ? moment.tz(
                      moment(selectedSessionWithTimezone.startDateTime)
                        .set({
                          hours: customTime.startDateTime.hour(),
                          minutes: customTime.startDateTime.minutes()
                        })
                        .format('YYYY-MM-DD HH:mm'),
                      selectedSession.timezone
                    )
                  : moment.tz(
                      moment(selectedSessionWithTimezone.startDateTime).format('YYYY-MM-DD HH:mm'),
                      selectedSession.timezone
                    ),
                endDateTime: selectedRatio.isCustomRatio
                  ? moment.tz(
                      moment(selectedSessionWithTimezone.endDateTime)
                        .set({
                          hours: customTime.endDateTime.hour(),
                          minutes: customTime.endDateTime.minutes()
                        })
                        .format('YYYY-MM-DD HH:mm'),
                      selectedSession.timezone
                    )
                  : moment.tz(
                      moment(selectedSessionWithTimezone.endDateTime).format('YYYY-MM-DD HH:mm'),
                      selectedSession.timezone
                    )
              };
            })
          }
        ]
      });
      this.setState({
        step: 3,
        selectedRatioText:
          selectedRatio && selectedRatio.customRatio.length > 0 && selectedRatio.isCustomRatio
            ? 'custom ratio'
            : selectedRatio.customRatio[0].teamMemberCustomerRatio
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { form, customersLite, selectedSession, sessionCustomerDetails } = this.props;
    const { step, selectedCustomer, isSearching, isLoading, isDisplayScheduledToBeArchivedMessage } = this.state;
    const { getFieldDecorator } = form;

    const title = step === 1 ? 'Add booking' : step === 2 ? 'Customer options' : 'Customer added to session';

    const markedCustomerList = _.map(customersLite, (customer) => {
      if (
        _.find(
          sessionCustomerDetails && sessionCustomerDetails.customerUserIds,
          (customerUserId) => customerUserId === customer.userId
        )
      ) {
        return { ...customer, isAlreadyInSession: true };
      } else {
        return { ...customer };
      }
    });

    const hasAccessToCustomerDetails = PermissionUtils.validatePermission(
      'ViewCustomerProfile',
      this.props.portalUser.permissions.permissionRoles
    );

    return (
      <>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this._onCloseModal}
          title={title}
          showCloseButton={true}
          width={step === 1 ? 'medium' : 'x-large'}
          canCloseOutside={!isLoading}
        >
          {step === 1 && (
            <>
              <div className="mb-large">
                <Paragraph>Please select the customer that you would like create a booking for.</Paragraph>
              </div>
              {/*{sessionCustomerDetails && sessionCustomerDetails.capacity && sessionCustomerDetails.capacity > 0 && (*/}
              {/*  <div className="mv-large p-medium rounded-big bg-quaternary">*/}
              {/*    <SubTitle>Session capacity</SubTitle>*/}
              {/*    <Text size={'x2-large'}>*/}
              {/*      {sessionCustomerDetails.bookedCapacity ? sessionCustomerDetails.bookedCapacity : 0}/*/}
              {/*      {sessionCustomerDetails.capacity}*/}
              {/*    </Text>*/}
              {/*  </div>*/}
              {/*)}*/}
              <Form.Item>
                <SubTitle>CUSTOMER</SubTitle>
                {getFieldDecorator('customerUserId', {
                  rules: [{ required: true, message: 'Please select a customer' }],
                  initialValue: selectedCustomer ? selectedCustomer.userId : undefined
                })(
                  <Select
                    showSearch={true}
                    placeholder="Search for customers..."
                    notFoundContent={isSearching ? <Spin size="small" /> : null}
                    onSearch={this._onEnterSearchText}
                    onChange={this._changeSelectedCustomer}
                    filterOption={false}
                    size={'large'}
                    style={{ width: '592px' }}
                  >
                    {_.map(markedCustomerList, (customer) => (
                      <Select.Option
                        key={customer.userId}
                        value={customer.userId}
                        disabled={customer.isAlreadyInSession}
                      >
                        <Avatar
                          icon={'user'}
                          size={'small'}
                          className={'mr-small'}
                          shape={'circle'}
                          src={customer.attachmentUrl}
                        />{' '}
                        {customer.firstName} {customer.lastName}
                        {hasAccessToCustomerDetails && !customer.isAlreadyInSession && customer.locality && (
                          <Text color={'secondary'}> ({customer.locality})</Text>
                        )}
                        {customer.isAlreadyInSession && ' (Already in session)'}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
              {selectedCustomer &&
                hasAccessToCustomerDetails &&
                (selectedCustomer.ndisNumber || selectedCustomer.dateOfBirth) && (
                  <CustomerAdditionalInfos
                    customer={selectedCustomer}
                    getOnlyContent={true}
                    noMarginTop={true}
                    hideInformation={['locality']}
                  />
                )}
              {isDisplayScheduledToBeArchivedMessage && selectedCustomer.scheduleArchiveDate && (
                <Error
                  errorMessage={
                    <Text>
                      This customer is scheduled to be archived on{' '}
                      <b>
                        {moment.tz(selectedCustomer.scheduleArchiveDate, selectedSession.timezone).format('DD/MM/YYYY')}
                      </b>{' '}
                      and cannot be added to this session.
                    </Text>
                  }
                  hideErrorTitle
                  className="mv-small align-center"
                />
              )}

              <ActionModalFooter align={'right'}>
                <SecondaryButton onClick={this._onCloseModal} size={'large'} disabled={isLoading} className="mr-medium">
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  onClick={this._onNextStep}
                  size={'large'}
                  loading={isLoading}
                  disabled={isDisplayScheduledToBeArchivedMessage}
                >
                  Next
                </PrimaryButton>
              </ActionModalFooter>
            </>
          )}
          {step === 2 && (
            <>
              <Paragraph>Please define the ratio for this customer.</Paragraph>
              <div className={'mv-large p-medium bg-tertiary rounded-big'}>
                <Avatar className={'mr-small'} shape={'circle'} icon={'user'} src={selectedCustomer.attachmentUrl} />{' '}
                {selectedCustomer.firstName} {selectedCustomer.lastName}
              </div>
              <div>
                <CustomerRatioSelectionPanel
                  sessions={[this._getSelectedSessionWithoutTimezone()]}
                  wrappedComponentRef={this._setRef}
                  groupServiceServiceAgreements={this.props.groupServiceServiceAgreements}
                  saveRatios={this._saveRatio}
                  timezone={selectedSession.timezone}
                />
              </div>

              <ActionModalFooter>
                <SecondaryButton
                  onClick={this._onPreviousStep}
                  size={'large'}
                  disabled={isLoading}
                  className="mr-medium"
                >
                  Back
                </SecondaryButton>
                <PrimaryButton onClick={this._addCustomerToSession} size={'large'} loading={isLoading}>
                  Add customer
                </PrimaryButton>
              </ActionModalFooter>
            </>
          )}
          {step === 3 && (
            <>
              <Paragraph>
                You have successfully add the following customer to the sessions with a ratio of{' '}
                <b>{this.state.selectedRatioText}</b>
              </Paragraph>
              <div className={'flex-row ml-large mb-x2-large'}>
                {' '}
                <Avatar
                  icon={'user'}
                  size={'small'}
                  className={'mr-small'}
                  shape={'circle'}
                  src={selectedCustomer.attachmentUrl}
                />{' '}
                {selectedCustomer.firstName} {selectedCustomer.lastName}
              </div>

              <ActionModalFooter>
                <PrimaryButton onClick={this._onCloseModal} size={'large'}>
                  Close
                </PrimaryButton>
              </ActionModalFooter>
            </>
          )}
        </ActionModal>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession,
  customersLite: state.customersStore.customersLite,
  groupServiceServiceAgreements: state.groupServiceStore.groupServiceServiceAgreements,
  sessionCustomerDetails: state.groupServiceStore.sessionCustomerDetails,
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetCustomersLite: dispatch.customersStore.doGetCustomersLite,
  setCustomersLite: dispatch.customersStore.setCustomersLite,
  doFetchGroupServiceServiceAgreements: dispatch.groupServiceStore.doFetchGroupServiceServiceAgreements,
  doAddCustomerToSession: dispatch.servicesStore.doAddCustomerToSession
});

export default connect(mapState, mapDispatch)(Form.create<IAddCustomerModalProps>()(AddCustomerModal));
