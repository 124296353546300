import React, { Component } from 'react';
import { Title } from 'common-components/typography';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';
import ActivityRecordStep1SelectCustomer from './activity-record-wizard-steps/ActivityRecordStep1SelectCustomer';
import ActivityRecordStep2SelectDetails from './activity-record-wizard-steps/ActivityRecordStep2SelectDetails';
import ActivityRecordStep3BillingDetails from './activity-record-wizard-steps/ActivityRecordStep3BillingDetails';
import ActivityRecordStep4NotesAttachmentsPanel from './activity-record-wizard-steps/ActivityRecordStep4NotesAttachmentsPanel';
import ActivityRecordStep5ReviewPanel from './activity-record-wizard-steps/ActivityRecordStep5ReviewPanel';
import ActivityRecordStep6SummaryPanel from './activity-record-wizard-steps/ActivityRecordStep6SummaryPanel';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { connect } from 'react-redux';

interface ICreateNewActivityRecordModalProps {
  isOpen: boolean;
  onClose: () => void;
  history: any;
  setNewActivityRecordData: typeof dispatch.bookingsStore.setNewActivityRecordData;
}

interface ICreateNewActivityRecordModalState {
  canClose: boolean;
  step: number;
}

const steps = [
  { stepNumber: 1, stepLabel: 1, stepTitle: 'SelectCustomer' },
  { stepNumber: 2, stepLabel: 2, stepTitle: 'Add Details' },
  { stepNumber: 3, stepLabel: 3, stepTitle: 'Billing' },
  { stepNumber: 4, stepLabel: 4, stepTitle: 'Notes' },
  { stepNumber: 5, stepLabel: 5, stepTitle: 'Review' },
  { stepNumber: 6, stepLabel: 6, stepTitle: 'Done!' }
];

class CreateNewActivityRecordModal extends Component<
  ICreateNewActivityRecordModalProps,
  ICreateNewActivityRecordModalState
> {
  state = {
    step: 1,
    canClose: true
  };

  private _onNextStep = () => {
    this.setState({ step: this.state.step + 1 });
  };

  private _onPreviousStep = () => {
    this.setState({ step: this.state.step - 1 });
  };

  private _onClose = async () => {
    this.props.onClose();
    this.setState({ step: 1 }, () => this.props.setNewActivityRecordData({}));
  };

  private _renderContent = () => {
    switch (this.state.step) {
      case 1:
        return <ActivityRecordStep1SelectCustomer onNextStep={this._onNextStep} />;
      case 2:
        return (
          <ActivityRecordStep2SelectDetails
            history={this.props.history}
            onNextStep={this._onNextStep}
            onPreviousStep={this._onPreviousStep}
          />
        );
      case 3:
        return (
          <ActivityRecordStep3BillingDetails onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />
        );
      case 4:
        return (
          <ActivityRecordStep4NotesAttachmentsPanel
            onNextStep={this._onNextStep}
            onPreviousStep={this._onPreviousStep}
          />
        );
      case 5:
        return <ActivityRecordStep5ReviewPanel onNextStep={this._onNextStep} onPreviousStep={this._onPreviousStep} />;
      case 6:
        return <ActivityRecordStep6SummaryPanel history={this.props.history} />;
    }
  };

  render() {
    const { isOpen } = this.props;

    return (
      <FullScreenScrollableModal
        isOpen={isOpen}
        onClose={this._onClose}
        width={'full'}
        headerTitle={
          <Title level={2} className="m-none">
            Create Activity Record
          </Title>
        }
        bgColor={'tertiary'}
        canCloseEsc={false}
        fixedHeight={false}
        noMarginBottom={true}
        contentPadding={false}
        hasFooter={false}
        hasSteps={true}
        currentStep={this.state.step}
        steps={steps}
      >
        {this._renderContent()}
      </FullScreenScrollableModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewActivityRecordData: dispatch.bookingsStore.setNewActivityRecordData
});

export default connect(
  null,
  mapDispatch
)(CreateNewActivityRecordModal);
