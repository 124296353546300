import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { SubTitle, Text } from 'common-components/typography';
import { Avatar, Icon, notification } from 'antd';
import _ from 'lodash';
import { Information } from 'common-components/alerts';
import moment from 'moment';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

interface IReSendInvitationModalProps {
  isOpen: any;
  onClose: any;
  invite: any;
  doResendTeamInvitation: typeof dispatch.teamStore.doResendTeamInvitation;
  doResendRevokedTeamInvitation: typeof dispatch.teamStore.doResendRevokedTeamInvitation;
}

interface IReSendInvitationModalState {
  title: any;
  isDone: boolean;
  email: string;
  isSaving: boolean;
}

class ReSendInvitationModal extends Component<IReSendInvitationModalProps, IReSendInvitationModalState> {
  state = { title: 'Re-send invitation', isDone: false, email: null, isSaving: false };

  private _onCloseModal = () => {
    this.setState({ title: 'Re-send invitation', isDone: false });
    this.props.onClose();
  };

  private _saveAction = async () => {
    const { invite } = this.props;
    try {
      this.setState({ isSaving: true });
      await this.props.doResendTeamInvitation({ token: invite.token });
      this.setState({
        title: (
          <>
            Invite successfully re-sent
            <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
          </>
        ),
        isDone: true
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isSaving: false });
  };

  render() {
    const { isOpen, invite } = this.props;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {!_.isEmpty(invite) &&
          (this.state.isDone ? (
            <>
              <div className="mv-medium ml-x4-large anim-slide-left">
                <div>
                  <SubTitle>User</SubTitle>
                  <div className={'flex-row align-center'}>
                    <Avatar shape={'square'} src={invite.attachmentUrl} className={'mr-medium'} />
                    <Text>{invite.firstName + ' ' + invite.lastName}</Text>
                  </div>
                </div>
                <div className={'mt-large'}>
                  <SubTitle>Invite sent to</SubTitle>
                  {invite.email}
                </div>

                <ActionModalFooter>
                  <PrimaryButton size="large" onClick={this._onCloseModal}>
                    Done
                  </PrimaryButton>
                </ActionModalFooter>
              </div>
            </>
          ) : (
            <>
              <div className="mv-medium anim-slide-left">
                <div>
                  <div>
                    <SubTitle>User</SubTitle>
                    <div className={'flex-row align-center'}>
                      <Avatar shape={'square'} src={invite.attachmentUrl} className={'mr-medium'} />
                      <Text>{invite.firstName + ' ' + invite.lastName}</Text>
                    </div>
                  </div>
                  <div className={'mt-large'}>
                    <SubTitle>Current invitation details</SubTitle>
                    {invite.email}
                    <br />
                    Sent on {moment(invite.createdOn).format('DD/MM/YYYY')}
                  </div>
                </div>
                <div className={'mt-large mb-x2-large'}>
                  <Information
                    content={
                      <>
                        <Text weight={'bold'}>
                          If this email address doesn't look right you can modify the invitation instead of resending
                          it.
                        </Text>
                        <br />
                        Close this modal and select the ‘Modify Invitation’ option in the action menu for this user.
                      </>
                    }
                  />
                </div>

                <ActionModalFooter>
                  <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size="large" loading={this.state.isSaving} onClick={this._saveAction}>
                    Re-send Invite
                  </PrimaryButton>
                </ActionModalFooter>
              </div>
            </>
          ))}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doResendTeamInvitation: dispatch.teamStore.doResendTeamInvitation,
  doResendRevokedTeamInvitation: dispatch.teamStore.doResendRevokedTeamInvitation
});

export default connect(
  null,
  mapDispatch
)(ReSendInvitationModal);
