import { Spinner } from '@blueprintjs/core';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import TimeInput from 'common-components/time-input/TimeInput';
import { FieldLabel, Text } from 'common-components/typography';
import { ISession } from 'interfaces/session-interfaces';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';

interface IStartShiftModalProps {
  isOpen: boolean;
  shiftSlot: IShiftSlot;
  onClose: any;
  session: ISession;
  doStartShift: typeof dispatch.groupServiceStore.doStartShift;
}

interface IStartShiftModalState {
  step: number;
  canManuallyClose: boolean;
  shiftStartDate: Moment;
  refreshShiftSlots: boolean;
}

class StartShiftModal extends Component<IStartShiftModalProps, IStartShiftModalState> {
  state = {
    step: 1,
    canManuallyClose: true,
    shiftStartDate:
      this.props.shiftSlot &&
      moment(
        moment.tz(this.props.shiftSlot.shiftStartDateTime, this.props.session.timezone).format('YYYY-MM-DD HH:mm')
      ),
    refreshShiftSlots: false
  };

  reset = () => {
    this.setState({
      step: 1,
      shiftStartDate: moment(
        moment.tz(this.props.shiftSlot.shiftStartDateTime, this.props.session.timezone).format('YYYY-MM-DD HH:mm')
      ),
      refreshShiftSlots: false
    });
  };

  onStartShift = async () => {
    const { doStartShift, session, shiftSlot } = this.props;
    const { shiftStartDate } = this.state;
    const { serviceDateTimeId, serviceId } = session;
    const { supportWorkerAttendanceId } = shiftSlot;

    this.setState({ step: 2, canManuallyClose: false });
    const request = {
      serviceDateTimeId,
      serviceId,
      supportWorkerAttendanceId,
      startDateTime: moment.tz(moment(shiftStartDate).format('YYYY-MM-DD HH:mm'), session.timezone).toDate()
    };
    await doStartShift(request);
    this.setState({ step: 3, canManuallyClose: true, refreshShiftSlots: true });
  };

  onClose = () => {
    const { onClose } = this.props;
    this.reset();

    onClose({ targetFlag: 'isStartShiftOpen' }, this.state.refreshShiftSlots);
  };

  onChangeStartDate = (date) => {
    this.setState({ shiftStartDate: moment(CommonUtils.formatCeilingDateTime(date)) });
  };

  componentDidUpdate(prevProps: Readonly<IStartShiftModalProps>) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.reset();
    }
  }

  render() {
    const { shiftSlot } = this.props;
    const { shiftStartDate } = this.state;

    if (_.isEmpty(shiftSlot)) {
      return <></>;
    }

    const { firstName, lastName } = shiftSlot;
    const workerFullName = `${firstName || ''} ${lastName || ''}`;

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title="Start shift"
        onClose={this.onClose}
        canCloseOutside={this.state.canManuallyClose}
      >
        {this.state.step === 1 && (
          <div>
            <div className="line-height-135 mb-medium">
              <Text lineHeight={135}>
                You are about to start this shift on behalf of <b>{workerFullName}</b>. <br />
                <br />
                Please confirm the start time below.
              </Text>
            </div>

            <div className="mb-large flex-row line-height-135">
              <div className="flex-1 mr-large">
                <FieldLabel text={'START TIME'} />

                <div className="flex-row mt-x2-small">
                  <DatePicker
                    className="gh-datepicker rounded mr-medium"
                    calendarClassName="gh-datepicker-calendar"
                    onChange={this.onChangeStartDate}
                    dateFormat={'dd/MM/yyyy'}
                    isClearable={false}
                    selected={moment(shiftStartDate).toDate()}
                    minDate={moment
                      .tz(this.props.session.timezone)
                      .add(1, 'day')
                      .startOf('day')
                      .toDate()}
                  />

                  <TimeInput size="large" value={moment(this.state.shiftStartDate)} onChange={this.onChangeStartDate} />
                </div>
              </div>
            </div>

            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this.onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this.onStartShift}>
                Start shift
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {this.state.step === 2 && (
          <div className="line-height-135 anim-slide-left">
            <div className="flex-column align-center mv-large justify-center">
              <div className="mb-medium">
                <Spinner size={80} />
              </div>
              <div className="text-align-center">
                <Text color="secondary" weight="bold">
                  Starting shift...
                </Text>
                <br />
                <Text color="secondary">This won&apos;t take long.</Text>
              </div>
            </div>
          </div>
        )}

        {this.state.step === 3 && (
          <div className="anim-fade-in-fast">
            <div>
              <div className="line-height-135 mb-medium">
                <Text lineHeight={135}>
                  You have successfully <b>started</b> this shift for <b>{workerFullName}</b>.
                </Text>
              </div>

              <div className="mb-large flex-row line-height-135">
                <div className="flex-1 mr-large">
                  <FieldLabel text={'START TIME'} />

                  <div className="flex-row mt-x2-small">
                    <Text>{moment(this.state.shiftStartDate).format('h:mm A D MMMM YYYY')}</Text>
                  </div>
                </div>
              </div>

              <ActionModalFooter align="right">
                <PrimaryButton size="large" onClick={this.onClose}>
                  Close
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </div>
        )}

        {this.state.step === 4 && (
          <div className="anim-fade-in-fast">
            <div>
              <div className="line-height-135 mb-medium">
                <Text lineHeight={135}>You can only start shifts after the session starts.</Text>
              </div>

              <ActionModalFooter align="right">
                <PrimaryButton size="large" onClick={this.onClose}>
                  Close
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doStartShift: dispatch.groupServiceStore.doStartShift
});

export default connect(null, mapDispatch)(StartShiftModal);
