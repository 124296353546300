import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Avatar, Col, notification, Row, TimePicker } from 'antd';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { FieldLabel, SubTitle, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { IShiftSlot } from 'interfaces/shift-interfaces';
import DatePicker from 'react-datepicker';
import { timeZone } from 'interfaces/timezone-type';
import moment, { Moment } from 'moment-timezone';
import CommonUtils from 'utilities/common-utils';
import TimeInput from 'common-components/time-input/TimeInput';

interface IBulkFinishShiftModalProps {
  isOpen: boolean;
  onClose: () => void;
  onTaskSuccess: () => void;
  toBeFinishedShiftSlots: IShiftSlot[];
  serviceId: string;
  serviceDateTimeId: string;
  timezone: timeZone;
  doBatchFinishShifts: typeof dispatch.groupServiceStore.doBatchFinishShifts;
}

interface IBulkFinishShiftModalState {
  isLoading: boolean;
  step: number;
  selectedShiftSlots: IShiftSlot[];
  editingShiftSlot: IShiftSlot;
  editingSlotCheckOutTime: Moment;
  isWarnCheckOutTimeBeforeCheckIn: boolean;
}

class BulkFinishShiftModal extends Component<IBulkFinishShiftModalProps, IBulkFinishShiftModalState> {
  state = {
    isLoading: false,
    step: 1,
    selectedShiftSlots: [],
    editingShiftSlot: null,
    editingSlotCheckOutTime: null,
    isWarnCheckOutTimeBeforeCheckIn: false
  };

  private _reset = () => {
    this.setState({
      isLoading: false,
      step: 1,
      selectedShiftSlots: [],
      editingShiftSlot: null,
      editingSlotCheckOutTime: null,
      isWarnCheckOutTimeBeforeCheckIn: false
    });
  };

  private _onChangeEndDate = (date) => {
    this.setState({ editingSlotCheckOutTime: moment(CommonUtils.formatCeilingDateTime(date)) });
  };

  private _onBack = () => {
    this.setState({ step: 1, editingShiftSlot: null });
  };

  private _onEditEndDateTime = (shiftSlot: IShiftSlot) => {
    const editingSlotCheckOutTime = moment(
      moment.tz(shiftSlot.workerCheckedOutDateTime, this.props.timezone).format('YYYY-MM-DD HH:mm')
    );
    this.setState({
      step: 2,
      editingShiftSlot: shiftSlot,
      editingSlotCheckOutTime,
      isWarnCheckOutTimeBeforeCheckIn: false
    });
  };

  private _onBulkFinishShifts = async () => {
    if (!this._validateSlotEndTime()) {
      this.setState({ isWarnCheckOutTimeBeforeCheckIn: true });
      return;
    }

    const { serviceId, serviceDateTimeId, doBatchFinishShifts, onTaskSuccess } = this.props;
    const { selectedShiftSlots } = this.state;
    const shiftSlots = _.map(selectedShiftSlots, (shiftSlot) => ({
      supportWorkerAttendanceId: shiftSlot.supportWorkerAttendanceId,
      endDateTime: shiftSlot.workerCheckedOutDateTime
    }));
    this.setState({ isLoading: true });
    try {
      await doBatchFinishShifts({
        serviceDateTimeId,
        serviceId,
        shiftSlots
      });
      this.setState({ step: 3 });
      onTaskSuccess();
    } catch (e) {
      console.error(e);
      notification.error({
        message: <Text weight="bold">Bulk actions failed.</Text>,
        description: (
          <Text className="mt-medium">
            Bulk actions for <b>Finishing shifts</b> complete has encounter an error. Please try again.
          </Text>
        )
      });
    }
    this.setState({ isLoading: false });
  };

  private _validateSlotEndTime = () => {
    const { selectedShiftSlots } = this.state;
    let validate = true;
    _.forEach(selectedShiftSlots, (slot: IShiftSlot) => {
      if (moment(slot.workerCheckedOutDateTime).isSameOrBefore(slot.workerCheckedInDateTime)) {
        validate = false;
      }
    });

    return validate;
  };

  private _formatFullName = (shiftSlot: IShiftSlot) => {
    if (!shiftSlot) return null;

    return `${shiftSlot.firstName || ''} ${shiftSlot.lastName || ''}`;
  };

  private _onSaveNewEndDate = () => {
    const { timezone } = this.props;
    const { editingShiftSlot, editingSlotCheckOutTime } = this.state;
    const checkoutDateTime = moment.tz(editingSlotCheckOutTime.format('YYYY-MM-DD HH:mm'), timezone);
    editingShiftSlot.workerCheckedOutDateTime = checkoutDateTime;
    this.setState({ step: 1 });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.isOpen && !prevProps.isOpen) {
      this._reset();
    }
    if (this.props.toBeFinishedShiftSlots !== prevProps.toBeFinishedShiftSlots) {
      const newShiftSlots = _.cloneDeep(this.props.toBeFinishedShiftSlots);
      const newShiftSlotsWithCheckOut = _.map(newShiftSlots, (slot) => {
        if (!slot.workerCheckedOutDateTime) {
          slot.workerCheckedOutDateTime = slot.shiftEndDateTime;
        }

        return slot;
      });

      this.setState({ selectedShiftSlots: newShiftSlotsWithCheckOut });
    }
  };

  render() {
    const { timezone } = this.props;
    const { step, editingShiftSlot, editingSlotCheckOutTime, selectedShiftSlots } = this.state;
    const modalTitle = step === 1 ? 'Finish shifts' : step === 2 ? 'Edit shift finish time' : 'Shifts finished';
    const itemCount = selectedShiftSlots.length;

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title={modalTitle}
        onClose={this.props.onClose}
        canCloseOutside={false}
        width={'large'}
      >
        {step === 1 && (
          <div>
            <Text>You are finishing the shift on behalf of the following team members.</Text>
            <div className="bordered border-secondary mt-medium rounded-big shadow-container">
              <Row style={{ borderBottom: '1px solid #ebebeb' }}>
                <Col span={9} className="p-medium">
                  <FieldLabel text="Team member" />
                </Col>
                <Col span={9} className="p-medium">
                  <FieldLabel text="Finish time" />
                </Col>
                <Col span={6} className="p-medium">
                  <FieldLabel text="" />
                </Col>
              </Row>
              {_.map(selectedShiftSlots, (shiftSlot: IShiftSlot) => (
                <Row>
                  <Col span={9} className="p-medium">
                    <div className="flex-row align-center">
                      <Avatar icon={'user'} shape={'square'} src={shiftSlot.attachmentUrl} />
                      <Text className="ml-small">{this._formatFullName(shiftSlot)}</Text>
                    </div>
                  </Col>
                  <Col span={9} className="p-medium">
                    {moment.tz(shiftSlot.workerCheckedOutDateTime, timezone).format("D MMMM 'YY. h:mmA")}
                  </Col>
                  <Col span={6} className="p-medium">
                    <HyperlinkButton onClick={() => this._onEditEndDateTime(shiftSlot)}>
                      Edit finish time...
                    </HyperlinkButton>
                  </Col>
                </Row>
              ))}
            </div>
            {this.state.isWarnCheckOutTimeBeforeCheckIn && (
              <Text color={'red-dark'}>Finish time must be after start time.</Text>
            )}
            <ActionModalFooter align="right" className="mt-large">
              <SecondaryButton size="large" className="mr-medium" onClick={this.props.onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                size="large"
                onClick={this._onBulkFinishShifts}
                className="rounded"
                loading={this.state.isLoading}
              >
                Finish shifts
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {step === 2 && editingShiftSlot && (
          <div>
            <Text>Edit the finish time for this team member's shift</Text>
            <SubTitle containerClassName="mt-x-large">Team member</SubTitle>
            <div className="flex-row align-center">
              <Avatar icon={'user'} shape={'circle'} src={editingShiftSlot.attachmentUrl || ''} />
              <Text className="ml-small">{this._formatFullName(editingShiftSlot)}</Text>
            </div>
            <SubTitle containerClassName="mt-x-large">scheduled shift time</SubTitle>
            <Text>
              {' '}
              {moment.tz(editingShiftSlot.shiftStartDateTime, timezone).format('D/M/YYYY, h:mmA') +
                ' - ' +
                moment.tz(editingShiftSlot.shiftEndDateTime, timezone).format('h:mmA')}
            </Text>
            <div className="bordered border-secondary rounded-big p-large mt-x-large">
              <SubTitle>Finish date/time</SubTitle>
              <div className="flex-row align-center">
                <DatePicker
                  className="gh-datepicker rounded width-full"
                  calendarClassName="gh-datepicker-calendar"
                  onChange={this._onChangeEndDate}
                  dateFormat={'dd/MM/yyyy'}
                  isClearable={false}
                  selected={moment(editingSlotCheckOutTime).toDate()}
                  minDate={moment(
                    moment.tz(editingShiftSlot.workerCheckedInDateTime, timezone).format('YYYY-MM-DD HH:mm')
                  ).toDate()}
                />

                <TimeInput
                  className="ml-medium"
                  size="large"
                  value={moment(editingSlotCheckOutTime)}
                  onChange={this._onChangeEndDate}
                />
              </div>
            </div>
            <ActionModalFooter align="right" className="mt-large">
              <SecondaryButton size="large" className="mr-medium" onClick={this._onBack}>
                Cancel
              </SecondaryButton>
              <PrimaryButton
                size="large"
                className="rounded"
                loading={this.state.isLoading}
                onClick={this._onSaveNewEndDate}
              >
                Save
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {step === 3 && (
          <div>
            <Text>
              You have finished the shifts for{' '}
              <b>
                {itemCount} team member{itemCount === 1 ? '' : 's'}
              </b>{' '}
              on their behalf.
            </Text>
            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this.props.onClose}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doBatchFinishShifts: dispatch.groupServiceStore.doBatchFinishShifts
});

export default connect(null, mapDispatch)(BulkFinishShiftModal);
