import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { IRootState, state } from 'src/stores/rematch/root-store';
import { ICustomer } from 'interfaces/customer-interfaces';
import { Avatar, Col, Empty, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Popover } from '@blueprintjs/core';
import { SubTitle, Text } from 'common-components/typography';
import { HyperlinkButton, IconButton } from 'common-components/buttons';
import moment from 'moment';
import PermissionUtils from 'utilities/permission-utils';
import { ActionMenu, ActionMenuItem, ActionMenuDivider } from 'common-components/action-menu';
import CustomerStatusTags from 'common-components/tags/CustomerStatusTags';
import RemoveCustomerModal from './components/RemoveCustomerModal';

const ManagedAccountsEmptyPanel = () => (
  <div className="flex-1 bg-white align-center flex-column">
    <div className="mb-small">
      <Empty className="m-none" description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No managed accounts
    </Text>
    <Text color="secondary">This customer has no managed accounts</Text>
  </div>
);

interface IGuardianManagedAccountsPanelProps {
  selectedCustomer: ICustomer;
  history: any;
  portalUser: typeof state.authStore.portalUser;
}

interface IGuardianManagedAccountsPanelState {
  isRemoveCustomerModalOpen: boolean;
  tobeRemovedCustomer: any;
}

class GuardianManagedAccountsPanel extends Component<
  IGuardianManagedAccountsPanelProps,
  IGuardianManagedAccountsPanelState
> {
  state = {
    isRemoveCustomerModalOpen: false,
    tobeRemovedCustomer: null
  };
  private _closeRemoveCustomerModal = () => {
    this.setState({ isRemoveCustomerModalOpen: false, tobeRemovedCustomer: null });
  };

  private _openRemoveCustomerModal = (account) => {
    this.setState({ isRemoveCustomerModalOpen: true, tobeRemovedCustomer: account });
  };

  private _goToCustomer = async (userId) => {
    const { history } = this.props;
    history.push(`/customer/details/${userId}`);
  };

  render() {
    const { selectedCustomer, portalUser } = this.props;
    const managedAccounts = selectedCustomer.managedAccounts;

    return (
      <>
        <RemoveCustomerModal
          isRemoveCustomerModalOpen={this.state.isRemoveCustomerModalOpen}
          closeRemoveCustomerModal={this._closeRemoveCustomerModal}
          tobeRemovedCustomer={this.state.tobeRemovedCustomer}
        />

        <Row type={'flex'} justify={'space-between'} align={'bottom'} className="mb-x-large">
          <Col>
            <Title level={2} className={'m-none'}>
              Managed accounts
            </Title>
            <Text type={'secondary'}>This customers manages their own account</Text>
          </Col>
        </Row>
        {!_.isEmpty(managedAccounts) ? (
          <div className="mb-x2-large">
            <Row className="pv-small">
              <Col span={8}>
                <SubTitle>MANAGED ACCOUNTS</SubTitle>
              </Col>
              <Col span={8}>
                <SubTitle>DATE ADDED</SubTitle>
              </Col>
              <Col span={4}>
                <SubTitle>STATUS</SubTitle>
              </Col>
              <Col span={4} />
            </Row>

            {managedAccounts.map((account) => (
              <Row className="bordered-top pv-medium">
                <Col span={8}>
                  <Avatar className="mr-medium" size="large" icon="user" />
                  {PermissionUtils.validatePermission('ViewCustomerProfile', portalUser.permissions.permissionRoles) ? (
                    <HyperlinkButton onClick={() => this._goToCustomer(account.userId)}>
                      {account.firstName} {account.lastName}
                    </HyperlinkButton>
                  ) : (
                    account.firstName + ' ' + account.lastName
                  )}
                </Col>
                <Col span={8}>{moment(account.dateAdded).format('DD MMMM YYYY')}</Col>
                <Col span={4}>
                  <CustomerStatusTags customerStatus={account.status} />
                </Col>
                <Col span={4} className="text-align-right pr-small">
                  <Popover
                    position="bottom-right"
                    content={
                      <ActionMenu>
                        <ActionMenuItem text={'View Profile'} onClick={() => this._goToCustomer(account.userId)} />
                        <ActionMenuDivider />
                        <ActionMenuItem
                          className="text-color-red"
                          text={'Remove customer'}
                          onClick={() => this._openRemoveCustomerModal(account)}
                        />
                      </ActionMenu>
                    }
                  >
                    <IconButton
                      icon="ellipsis"
                      size="small"
                      className="bg-white"
                      bordered={true}
                      iconColor="secondary"
                      onClick={() => {}}
                    />
                  </Popover>
                </Col>
              </Row>
            ))}
          </div>
        ) : (
          <div className="mb-x10-large mt-x4-large">
            <ManagedAccountsEmptyPanel />
          </div>
        )}
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser
});
const mapDispatch = {};

export default connect(
  mapState,
  null
)(GuardianManagedAccountsPanel);
