import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { Icon, Popover } from 'antd';
import _ from 'lodash';
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { MdChangeHistory, MdRadioButtonUnchecked } from 'react-icons/md';
import { AlertLevel } from 'utilities/enum-utils';

interface ICareInformationFilterProps {
  itemList: any;
  filterValue: AlertLevel | string;
  itemName: string;
  setFilter: (filterName) => void;
}

interface ICareInformationFilterState {}

class CareInformationFilter extends Component<ICareInformationFilterProps, ICareInformationFilterState> {
  levelColors = {
    ALL: 'secondary',
    HIGH: 'red',
    MEDIUM: 'orange-light',
    LOW: 'green-light'
  };

  render() {
    const { itemList, filterValue, itemName, setFilter } = this.props;

    const filtersCount = _.countBy(itemList, 'alertLevel');

    const filterMenu = (
      <Menu>
        <MenuDivider title={<Text>Filter {itemName} by level</Text>} />
        <MenuItem
          text={
            <Text>
              <Icon type="check" className="mr-medium text-color-blue" />
              All
            </Text>
          }
          label={itemList.length}
          onClick={() => setFilter(AlertLevel.ALL)}
          className="hover-bg-green-lightest"
        />
        <MenuItem
          text={
            <Text>
              <MdChangeHistory className="mr-medium text-color-red" />
              High
            </Text>
          }
          label={filtersCount[AlertLevel.HIGH] ? filtersCount[AlertLevel.HIGH].toString() : '0'}
          disabled={!filtersCount[AlertLevel.HIGH]}
          onClick={() => setFilter(AlertLevel.HIGH)}
          className="hover-bg-green-lightest"
        />
        <MenuItem
          text={
            <Text>
              <MdRadioButtonUnchecked className="mr-medium text-color-orange-light" />
              Medium
            </Text>
          }
          label={filtersCount[AlertLevel.MEDIUM] ? filtersCount[AlertLevel.MEDIUM].toString() : '0'}
          disabled={!filtersCount[AlertLevel.MEDIUM]}
          onClick={() => setFilter(AlertLevel.MEDIUM)}
          className="hover-bg-green-lightest"
        />
        <MenuItem
          text={
            <div className="flex-row align-center inline-flex">
              <MdChangeHistory className="mr-medium text-color-green-light" style={{ transform: 'rotateX(180deg)' }} />
              <Text>Low</Text>
            </div>
          }
          label={filtersCount[AlertLevel.LOW] ? filtersCount[AlertLevel.LOW].toString() : '0'}
          disabled={!filtersCount[AlertLevel.LOW]}
          onClick={() => setFilter(AlertLevel.LOW)}
          className="hover-bg-green-lightest"
        />
      </Menu>
    );

    return (
      <Popover placement="bottom" content={filterMenu} trigger="hover">
        <Text color="secondary" className="cursor-pointer">
          Showing{' '}
          <Text color={this.levelColors[AlertLevel[filterValue]]} weight="bold">
            {AlertLevel[filterValue]}
          </Text>{' '}
          levels
          <Icon type="down" className="ml-x-small" />
        </Text>
      </Popover>
    );
  }
}

export default CareInformationFilter;
