import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import moment from 'moment-timezone';
import { IGroupServiceTimesheetSession } from 'interfaces/service-interfaces';
import { timeZone } from 'interfaces/timezone-type';

interface ITimesheetSessionRowProps {
  session: IGroupServiceTimesheetSession;
  isSelected: boolean;
  onSelectSession: (sessionId: string) => void;
  timezone: timeZone;
}

interface ITimesheetSessionRowState {}

class TimesheetSessionRow extends Component<ITimesheetSessionRowProps, ITimesheetSessionRowState> {
  render() {
    const { session, isSelected, timezone } = this.props;

    let containerClassName = 'text-align-center';
    let textClassName = '';

    if (isSelected) {
      containerClassName += ' bg-blue-action-lightest';
      textClassName += ' text-color-blue-action';
    }

    return (
      <div
        className={containerClassName}
        onClick={() => this.props.onSelectSession(session.serviceDateTimeId)}
        style={{ height: 60, paddingTop: 18 }}
      >
        <Text className={textClassName}>{moment.tz(session.startDateTime, timezone).format('DD MMM YYYY')}</Text>
      </div>
    );
  }
}

export default TimesheetSessionRow;
