import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Avatar, Icon, notification } from 'antd';
import _ from 'lodash';
import { Warning } from 'common-components/alerts';
import Utils from 'utilities/Utils';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

interface IInviteUserModalProps {
  isOpen: any;
  onClose: any;
  worker: any;
  doInviteTeamMember: typeof dispatch.teamStore.doInviteTeamMember;
}

interface IInviteUserModalState {
  title: any;
  isDone: boolean;
  isSaving: boolean;
}

class InviteUserModal extends Component<IInviteUserModalProps, IInviteUserModalState> {
  state = { title: 'Invite User', isDone: false, isSaving: false };

  private _onCloseModal = () => {
    this.setState({ title: 'Invite User', isDone: false });
    this.props.onClose();
  };

  private _saveAction = async () => {
    try {
      this.setState({ isSaving: true });
      await this.props.doInviteTeamMember({ supportWorkerId: this.props.worker.supportWorkerId });
      this.setState({
        title: (
          <>
            User successfully invited
            <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
          </>
        ),
        isDone: true
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isSaving: false });
  };

  render() {
    const { isOpen, worker } = this.props;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {!_.isEmpty(worker) &&
          (this.state.isDone ? (
            <>
              <div className="mv-medium ml-x4-large anim-slide-left">
                <div>
                  <SubTitle>User</SubTitle>
                  <div className={'flex-row align-center'}>
                    <Avatar shape={'square'} src={worker.attachmentUrl} className={'mr-medium'} />
                    <Text>{worker.firstName + ' ' + worker.lastName}</Text>
                    <div className={'ml-medium'}>
                      <Text size={'small'} color={'green'}>
                        ENABLED
                      </Text>
                    </div>
                  </div>
                </div>

                <ActionModalFooter>
                  <PrimaryButton size="large" onClick={this._onCloseModal}>
                    Done
                  </PrimaryButton>
                </ActionModalFooter>
              </div>
            </>
          ) : (
            <>
              <div className="mv-medium anim-slide-left">
                <div>
                  {!Utils.isEmpty(worker.email) ? (
                    <>
                      <Paragraph>
                        Are you sure you want to invite <b>{worker.firstName + ' ' + worker.lastName}</b>. This will
                        enable their profile and allow them to be assigned to rosters and bookings.
                      </Paragraph>
                      <Paragraph>
                        The invite will be sent to the following email: <b>{worker.email}</b>
                      </Paragraph>
                    </>
                  ) : (
                    <Warning content="This user has no email set, please add an email to this user's profile before inviting them." />
                  )}
                </div>

                <ActionModalFooter>
                  <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                    Cancel
                  </SecondaryButton>
                  {!Utils.isEmpty(worker.email) && (
                    <PrimaryButton size="large" loading={this.state.isSaving} onClick={this._saveAction}>
                      Invite User
                    </PrimaryButton>
                  )}
                </ActionModalFooter>
              </div>
            </>
          ))}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doInviteTeamMember: dispatch.teamStore.doInviteTeamMember
});

export default connect(
  null,
  mapDispatch
)(InviteUserModal);
