/* eslint-disable */
import React, { PureComponent } from 'react';
import _ from 'lodash';
import { Text } from 'common-components/typography';

interface IPaymentMethodTagProps {
  paymentMethod: 'NDIA' | 'PLAN' | 'SELF';
  size?: 'small' | 'default' | 'large';
  className?: any;
  textClassName?: any;
  rounded?: boolean;
}

export default class PaymentMethodTag extends PureComponent<IPaymentMethodTagProps, any> {
  BorderPaymentMethodColors = {
    NDIA: 'bordered border-green',
    PLAN: 'bordered border-indigo',
    SELF: 'bordered border-blue'
  };

  TextPaymentMethodColors = {
    NDIA: 'text-color-green',
    PLAN: 'text-color-indigo',
    SELF: 'text-color-blue'
  };

  render() {
    const { paymentMethod, className, textClassName, size = 'default', rounded = true } = this.props;

    const borderColor = this.BorderPaymentMethodColors[paymentMethod];
    const textColor = this.TextPaymentMethodColors[paymentMethod];

    const roundedClass = rounded ? 'rounded' : '';

    let sizeClasses = '';

    if (size === 'default') {
      sizeClasses = 'pv-x-small ph-small text-size-regular text-weight-bold  letter-spacing-wide';
    } else if (size === 'large') {
      sizeClasses = 'pv-small ph-large text-size-large text-weight-bold  letter-spacing-wide';
    } else if (size === 'small') {
      sizeClasses = 'pv-x3-small ph-x-small text-size-small text-weight-bold  letter-spacing-wide';
    }

    const containerClassNames = `text-align-center ph-small bg-white rounded-full ${sizeClasses} inline-block ${roundedClass} ${borderColor} ${className}`;
    const textClassNames = `${textColor} ${textClassName}`;

    return (
      <div className={containerClassNames}>
        <Text className={textClassNames}>
          {_.chain(paymentMethod)
            .upperCase()
            .value()}
        </Text>
      </div>
    );
  }
}
