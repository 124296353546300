import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { notification } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import _ from 'lodash';
import moment from 'moment-timezone';
import { ndisHelper } from 'variables/data-helpers';
import { parse } from 'json2csv';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { connect } from 'react-redux';
import CommonUtils from 'utilities/common-utils';
const fileDownload = require('js-file-download');

interface IExportLineItemActionModalProps extends FormComponentProps {
  isOpen: boolean;
  doFetchExportWaitingPayments?: typeof dispatch.billingsStore.doFetchExportWaitingPayments;
  onClose: () => void;
}

interface IExportLineItemActionModalState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  title: string;
}

class ExportLineItemActionModal extends Component<IExportLineItemActionModalProps, IExportLineItemActionModalState> {
  state = {
    isLoading: true,
    isSuccess: false,
    isError: false,
    title: 'Download file'
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ isLoading: false, isSuccess: false, isError: false });
    onClose();
  };

  private _exportLineItemCSVFile = async () => {
    const { doFetchExportWaitingPayments } = this.props;
    try {
      this.setState({ isLoading: true });
      const exportWaitingPayments = await doFetchExportWaitingPayments({});

      let lineItemsList = [];
      _.map(exportWaitingPayments, (customer) => {
        return _.map(customer.billingItems, (lineItems) => {
          lineItemsList.push({
            ['NDIS Number']: lineItems.ndisNumber,
            ['Customer Name']: customer.firstName + ' ' + customer.lastName,
            ['Booking Start Date']: moment.tz(lineItems.startDateTime, lineItems.timezone).format('DD/MM/YY'),
            ['Booking End date']: moment.tz(lineItems.endDateTime, lineItems.timezone).format('DD/MM/YY'),
            ['Support Item Number']: lineItems.supportItemNumber,
            ['Support Item Name']: ndisHelper.getBySupportItemNumber(lineItems.supportItemNumber).SupportItem,
            ['Total Cost']: CommonUtils.formatPrice(lineItems.total),
            ['Management Method']: lineItems.paymentMethod,
            ['Plan Start Date']: lineItems.planStartDate
              ? moment.tz(lineItems.planStartDate, lineItems.timezone).format('DD/MM/YY')
              : null,
            ['Plan Review Date']: lineItems.planReviewDate
              ? moment.tz(lineItems.planReviewDate, lineItems.timezone).format('DD/MM/YY')
              : null
          });
        });
      });

      const csvData = parse(lineItemsList);
      // Generate the csv
      fileDownload(csvData, `line-items-export-${moment().format('DD-MM-YYYY-HH-mm')}.csv`);
      this.setState({ isLoading: false, isSuccess: true, title: 'File successfully downloaded' });
    } catch (e) {
      notification.error({
        message: 'Error while generating the export shift hours file',
        description: 'The file generation failed, please try again.'
      });
      this.setState({ isLoading: false, isError: true });
    }
  };

  componentDidMount() {
    this._exportLineItemCSVFile();
  }

  componentDidUpdate(
    prevProps: Readonly<IExportLineItemActionModalProps>,
    prevState: Readonly<IExportLineItemActionModalState>,
    snapshot?: any
  ) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this._exportLineItemCSVFile();
    }
  }

  render() {
    const { isOpen } = this.props;
    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="medium"
        verticalAlignment="highest"
      >
        {this.state.isLoading && (
          <div className="text-align-left">
            <div className="mv-x2-large">
              <SpinningLoader size={100} message={'Please wait'} />
            </div>
          </div>
        )}
        {this.state.isSuccess && (
          <>
            <div className="text-align-left">
              <div className="mb-medium">
                <Paragraph>Please check your downloads.</Paragraph>
              </div>
            </div>
            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal} className="mr-medium">
                Ok
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {this.state.isError && (
          <>
            <div className="text-align-left">
              <div className="mb-medium">
                <Paragraph>Oops! Something went wrong. Please try again.</Paragraph>
              </div>
            </div>
            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal} className="mr-medium">
                Ok
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchExportWaitingPayments: dispatch.billingsStore.doFetchExportWaitingPayments
});

export default connect(
  null,
  mapDispatch
)(ExportLineItemActionModal);
