import React, { Component } from 'react';
import { Col, Row, Form, DatePicker, Input, Switch } from 'antd';
import { Text, FieldLabel } from 'common-components/typography';
import moment from 'moment-timezone';
import _ from 'lodash';
import UnavailableTimesItem from './UnavailableTimeItem';
import { CustomUnavailabilityOption, UnavailabilityType } from 'utilities/enum-utils';
import TimeInput from 'common-components/time-input/TimeInput';
interface IModalSubContentProps {
  form: any;
  scheduleType: number;
  selectedItem?: any;
  selectedTimezone: string;
  onChangeCustomUnavailableTime?: (customUnavailableTime) => void;
}

interface IModalSubContentState {
  unavailableTimes: any[];
}

const defaultTimes = [
  {
    day: 'Monday',
    number: 1,
    timeRange: [],
    isAvailableAllDay: false,
    isInRange: true
  },
  {
    day: 'Tuesday',
    number: 2,
    timeRange: [],
    isAvailableAllDay: false,
    isInRange: true
  },
  {
    day: 'Wednesday',
    number: 3,
    timeRange: [],
    isAvailableAllDay: false,
    isInRange: true
  },
  {
    day: 'Thursday',
    number: 4,
    timeRange: [],
    isAvailableAllDay: false,
    isInRange: true
  },
  {
    day: 'Friday',
    number: 5,
    timeRange: [],
    isAvailableAllDay: false,
    isInRange: true
  },
  {
    day: 'Saturday',
    number: 6,
    timeRange: [],
    isAvailableAllDay: false,
    isInRange: true
  },
  {
    day: 'Sunday',
    number: 0,
    timeRange: [],
    isAvailableAllDay: false,
    isInRange: true
  }
];

const defaultDate = moment();
const defaultTime = moment('12:00 AM', ['h:mm A']);

class ModalSubContent extends Component<IModalSubContentProps, IModalSubContentState> {
  state = {
    unavailableTimes: defaultTimes
  };

  componentWillUnmount(): void {
    this.setState({ unavailableTimes: defaultTimes });
  }

  private _getDisableDate = (currentDate, type) => {
    const { form } = this.props;
    const startDate = form.getFieldValue('startDate');

    if (type === 'endDate') {
      return moment(currentDate).isBefore(startDate);
    }

    return moment().isAfter(currentDate);
  };

  private _handleStartDate = (rule, value, callback) => {
    const { form } = this.props;
    const endDate = form.getFieldValue('endDate');

    if (endDate && moment(value).isAfter(endDate)) {
      callback('Start date must occur before the end date.');
    }
    callback();
  };

  private _handleEndDate = (rule, value, callback) => {
    const { form } = this.props;
    const startDate = form.getFieldValue('startDate');

    if (!startDate && value) {
      callback('End date must occur after the start date.');
    }
    callback();
  };

  private _validateDate = (rule, value, callback) => {
    const { form } = this.props;
    const { field, message } = rule;

    const startDate = moment(form.getFieldValue('startDate')).format('YYYY-MM-DD');
    const startTime = moment(form.getFieldValue('startTime')).format('HH:mm');
    const endDate = moment(form.getFieldValue('endDate')).format('YYYY-MM-DD');
    const endTime = moment(form.getFieldValue('endTime')).format('HH:mm');
    const startDateTime = moment(startDate + ' ' + startTime);
    const endDateTime = moment(endDate + ' ' + endTime);

    if (field === 'startDate') {
      form.validateFields(['endDate']);
    } else if (field === 'endDate') {
      form.validateFields(['startDate']);
    }

    if (moment(startDateTime).isSameOrAfter(endDateTime)) {
      callback(message);
    }

    callback();
  };

  private _validateTime = () => {
    const { form } = this.props;
    form.validateFields(['startDate', 'endDate']);
  };

  private _onChangeUnavailableAllDay = (value) => {
    const { form } = this.props;
    form.setFieldsValue({ isAllDay: value });
  };

  private _onChangeUnavailableTimes = (newTime) => {
    const { unavailableTimes } = this.state;
    const { onChangeCustomUnavailableTime } = this.props;
    const newUnavailableTimes = [...unavailableTimes];
    const index = _.findIndex(newUnavailableTimes, (time) => time.number === newTime.number);
    newUnavailableTimes.splice(index, 1, newTime);
    onChangeCustomUnavailableTime(newUnavailableTimes);
    this.setState({ unavailableTimes: newUnavailableTimes });
  };

  private _generateNewAvailableDays = (startDate, endDate) => {
    const { form, onChangeCustomUnavailableTime, selectedItem } = this.props;
    let availableDays = [];

    const initAvailableDays = _.map(defaultTimes, (time) => ({ ...time, isInRange: false }));
    let tempDay = moment(startDate);

    const dayNumbers = [];

    while (tempDay.isSameOrBefore(endDate)) {
      dayNumbers.push(tempDay.day());
      tempDay = tempDay.add(1, 'days');
    }

    availableDays = _.map(initAvailableDays, (time) =>
      _.includes(dayNumbers, time.number) ? { ...time, isInRange: true } : time
    );
    if (selectedItem && !_.isEmpty(selectedItem.customUnavailabilityWeekDays)) {
      const customUnavailableTimes = this._mapCustomUnavailability(selectedItem.customUnavailabilityWeekDays);
      availableDays = _.map(availableDays, (day) => {
        const isInRange = day.isInRange;
        const existingCustomUnavailableTime = _.find(customUnavailableTimes, (i) => i.number === day.number);
        if (isInRange && existingCustomUnavailableTime) {
          day = existingCustomUnavailableTime;
          day.isInRange = isInRange;
        } else {
          day.timeRange = [];
        }
        return day;
      });
    }
    onChangeCustomUnavailableTime(availableDays);
    this.setState({ unavailableTimes: availableDays });
    form.setFieldsValue({ startDate, endDate });
  };

  private _onCustomDayChange = (value, field) => {
    const { form } = this.props;
    const startDate = form.getFieldValue('startDate');
    const endDate = form.getFieldValue('endDate');

    if (field === 'startDate') {
      if (endDate) {
        this._generateNewAvailableDays(value, endDate);
      }
    } else {
      if (startDate) {
        this._generateNewAvailableDays(startDate, value);
      }
    }
  };

  private _mapCustomUnavailability = (customUnavailabilities: any[]) => {
    const { unavailableTimes } = this.state;
    const { selectedTimezone } = this.props;
    let defaultCustomUnavailabilities = unavailableTimes;
    const mapByWeekDayId = _.reduce(
      customUnavailabilities,
      (map, day) => {
        map[day.weekDayId] = day;
        return map;
      },
      {}
    );

    const res = _.map(defaultCustomUnavailabilities, (day) => {
      const customUnavailabilityDay = mapByWeekDayId[day.number];
      if (!customUnavailabilityDay) {
        day.isInRange = false;
        day.timeRange = [];
        day.isAvailableAllDay = false;
        return day;
      } else {
        day.isInRange = true;
        day.isAvailableAllDay =
          customUnavailabilityDay.unavailabilityOption === CustomUnavailabilityOption.AvailableAllDay;
        day.timeRange =
          customUnavailabilityDay.unavailabilityOption === CustomUnavailabilityOption.UnavailableSpecificHours
            ? _.map(customUnavailabilityDay.timeRange, (time) => {
                return {
                  startTime: moment.tz(time.startTime, selectedTimezone),
                  endTime: moment.tz(time.endTime, selectedTimezone)
                };
              })
            : [];
        return day;
      }
    });
    return res;
  };

  private _renderContent = () => {
    const { scheduleType, form, selectedItem, selectedTimezone } = this.props;
    const { getFieldDecorator } = form;
    const { unavailableTimes } = this.state;

    const curTz = selectedItem ? selectedItem.timezone : selectedTimezone;
    const customUnavailabilities = unavailableTimes;
    const isAllDay =
      selectedItem && selectedItem.unavailabilityType === UnavailabilityType.ONE_OFF
        ? !(selectedItem.endTime && selectedItem.startTime)
        : true;

    switch (scheduleType) {
      case 1:
        return (
          <>
            <Form.Item>
              {getFieldDecorator('isAllDay', {
                initialValue: isAllDay
              })(<Switch checked={form.getFieldValue('isAllDay')} onChange={this._onChangeUnavailableAllDay} />)}
              <Text className="ml-small">Unavailable all day</Text>
            </Form.Item>
            {!form.getFieldValue('isAllDay') ? (
              <>
                <Row>
                  <Col span={10}>
                    <Row gutter={[8, 0]}>
                      <Col span={13}>
                        <FieldLabel text="Start date" />
                      </Col>
                      <Col span={11}>
                        <FieldLabel text="Start time" />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={3} />
                  <Col span={11}>
                    <Row gutter={[8, 0]}>
                      <Col span={12}>
                        <FieldLabel text="End date" />
                      </Col>
                      <Col span={10}>
                        <FieldLabel text="End time" />
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={10}>
                    <Form.Item>
                      <Row gutter={[8, 0]}>
                        <Col span={13}>
                          {getFieldDecorator('startDate', {
                            initialValue: moment.tz(_.get(selectedItem, 'startDate', defaultDate), curTz),
                            rules: [
                              { required: true, message: 'Please select start date/time' },
                              {
                                validator: _.debounce(
                                  (rule, value, callback) => this._validateDate(rule, value, callback),
                                  100
                                ),
                                message: 'Start date/time must occur before the end date/time.'
                              }
                            ]
                          })(
                            <DatePicker
                              className="width-full"
                              size="large"
                              format="D MMM YYYY"
                              disabledDate={(current) => this._getDisableDate(current, 'startDate')}
                            />
                          )}
                        </Col>
                        <Col span={11}>
                          {getFieldDecorator('startTime', {
                            initialValue: (!_.isEmpty(_.get(selectedItem, 'startTime'))
                              ? moment.tz(_.get(selectedItem, 'startTime'), curTz)
                              : defaultTime.tz(curTz, true)
                            ).format('YYYY-MM-DD HH:mm:ss'),
                            rules: [
                              { required: true, message: 'Please select start date/time' },
                              {
                                validator: this._validateTime,
                                message: 'Start date/time must occur before the end date/time.'
                              }
                            ]
                          })(
                            <TimeInput
                              size="large"
                              className="width-full"
                              containerStyle={{ height: '40px', overflow: 'hidden' }}
                            />
                          )}
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                  <Col span={3} className="text-align-center">
                    <div className="mt-small">
                      <Text className="text-color-secondary">to</Text>
                    </div>
                  </Col>
                  <Col span={11}>
                    <Form.Item>
                      <Row gutter={[8, 0]}>
                        <Col span={12}>
                          {getFieldDecorator('endDate', {
                            initialValue: moment.tz(_.get(selectedItem, 'endDate', defaultDate), curTz),
                            rules: [
                              { required: true, message: 'Please select end date/time' },
                              {
                                validator: _.debounce(
                                  (rule, value, callback) => this._validateDate(rule, value, callback),
                                  100
                                ),
                                message: 'End date/time must occur after the start date/time.'
                              }
                            ]
                          })(
                            <DatePicker
                              className="width-full"
                              size="large"
                              format="D MMM YYYY"
                              disabledDate={(current) => this._getDisableDate(current, 'endDate')}
                            />
                          )}
                        </Col>
                        <Col span={10}>
                          {getFieldDecorator('endTime', {
                            initialValue: (!_.isEmpty(_.get(selectedItem, 'endTime'))
                              ? moment.tz(_.get(selectedItem, 'endTime'), curTz)
                              : defaultTime.tz(curTz, true)
                            ).format('YYYY-MM-DD HH:mm:ss'),
                            rules: [
                              { required: true, message: 'Please select end date/time' },
                              {
                                validator: this._validateTime,
                                message: 'End date/time must occur after the start date/time.'
                              }
                            ]
                          })(
                            <TimeInput
                              size="large"
                              className="width-full"
                              containerStyle={{ height: '40px', overflow: 'hidden' }}
                            />
                          )}
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row>
                  <Col span={8}>
                    <FieldLabel text="Start date" />
                  </Col>
                  <Col span={2} />
                  <Col span={8}>
                    <FieldLabel text="End date" />
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('startDate', {
                        initialValue: moment.tz(_.get(selectedItem, 'startDate', defaultDate), curTz),
                        rules: [
                          { required: true, message: 'Please select start date' },
                          { validator: this._handleStartDate }
                        ]
                      })(
                        <DatePicker
                          className="width-full"
                          size="large"
                          format="D MMM YYYY"
                          disabledDate={(current) => this._getDisableDate(current, 'startDate')}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={2} className="text-align-center">
                    <div className="mt-small">
                      <Text className="text-color-secondary">to</Text>
                    </div>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator('endDate', {
                        initialValue: moment.tz(_.get(selectedItem, 'endDate', defaultDate), curTz),
                        rules: [
                          { required: true, message: 'Please select end date' },
                          { validator: this._handleEndDate }
                        ]
                      })(
                        <DatePicker
                          className="width-full"
                          size="large"
                          format="D MMM YYYY"
                          disabledDate={(current) => this._getDisableDate(current, 'endDate')}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
            <Form.Item>
              <FieldLabel text="Comments" />
              {getFieldDecorator('comments', {
                initialValue: !selectedItem ? '' : selectedItem.comment,
                rules: [{ required: true, message: 'Please enter comments' }]
              })(<Input.TextArea rows={3} placeholder={'Include any notes about this unavailability...'} />)}
            </Form.Item>
          </>
        );
      case 2:
        return (
          <>
            <FieldLabel text="Date" />
            <Row className="mt-small">
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('startDate', {
                    initialValue: moment.tz(_.get(selectedItem, 'startDate', defaultDate), curTz),
                    rules: [
                      { required: true, message: 'Please select start date' },
                      { validator: this._handleStartDate }
                    ]
                  })(
                    <DatePicker
                      className="width-full"
                      size="large"
                      format="D MMM YYYY"
                      disabledDate={(current) => this._getDisableDate(current, 'startDate')}
                      onChange={(value) => this._onCustomDayChange(value, 'startDate')}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={2} className="text-align-center">
                <div className="mt-small">
                  <Text className="text-color-secondary">to</Text>
                </div>
              </Col>
              <Col span={8}>
                <Form.Item>
                  {getFieldDecorator('endDate', {
                    initialValue: moment.tz(_.get(selectedItem, 'endDate', defaultDate), curTz),
                    rules: [{ required: true, message: 'Please select end date' }, { validator: this._handleEndDate }]
                  })(
                    <DatePicker
                      className="width-full"
                      size="large"
                      format="D MMM YYYY"
                      disabledDate={(current) => this._getDisableDate(current, 'endDate')}
                      onChange={(value) => this._onCustomDayChange(value, 'endDate')}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            {form.getFieldValue('startDate') && form.getFieldValue('endDate') && (
              <>
                {_.map(customUnavailabilities, (data) => {
                  return (
                    <UnavailableTimesItem
                      day={data.day}
                      data={data}
                      key={data.number}
                      onChangeAvailableTime={this._onChangeUnavailableTimes}
                    />
                  );
                })}
              </>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  componentDidMount(): void {
    const { selectedItem } = this.props;
    if (selectedItem && selectedItem.unavailabilityType === UnavailabilityType.CUSTOM) {
      this._generateNewAvailableDays(moment(selectedItem.startDate), moment(selectedItem.endDate));
    }
  }

  render() {
    return this._renderContent();
  }
}

export default ModalSubContent;
