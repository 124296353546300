import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { CustomerConnectionStatus, CustomerStatusType } from 'utilities/enum-utils';

interface ICustomerStatusTagsProps {
  customerStatus: string;
}

class CustomerStatusTags extends Component<ICustomerStatusTagsProps, any> {
  render() {
    const { customerStatus } = this.props;
    let label, bgColor, textColor, borderColor;

    if (customerStatus === CustomerStatusType.ARCHIVED) {
      label = 'Archived';
      bgColor = 'bg-red-dark';
      textColor = 'text-color-white';
      borderColor = '#c23030';
    } else if (customerStatus === CustomerConnectionStatus.CONNECTED || customerStatus === CustomerStatusType.ACTIVE) {
      label = 'Active';
      bgColor = 'bg-green';
      textColor = 'text-color-white';
      borderColor = '#0f9960';
    } else {
      label = 'Enquiry';
      bgColor = 'bg-blue-lightest';
      textColor = 'text-color-blue';
      borderColor = '#48AFF0';
    }

    const containerClassNames = `text-align-center pv-x-small ph-small text-size-regular inline-block rounded ${bgColor} bordered`;
    const textClassNames = `${textColor}`;
    return (
      <div
        className={containerClassNames}
        style={
          borderColor
            ? {
                borderColor
              }
            : {}
        }
      >
        <Text size={'regular'} className={textClassNames}>
          {label}
        </Text>
      </div>
    );
  }
}

export default CustomerStatusTags;
