import React from 'react';

import _ from 'lodash';

import { Collapse } from '@blueprintjs/core';

import { ColumnGroupHeader } from 'common-components/roster-control/roster-daily/content-pane/customers/ColumnGroupHeader';
import { WorkersColumn } from 'common-components/roster-control/roster-daily/content-pane/workers/WorkersColumn';
import { OpenShiftsSideColumn } from 'common-components/roster-control/roster-daily/content-pane/workers/OpenShiftsSideColumn';

import { EmptySideColumn } from 'common-components/roster-control/roster-daily/content-pane/common/EmptySideColumn';

import {
  IRosterShift,
  IRosterWorker,
  IRosterWorkerWithShifts
} from 'common-components/roster-control/interfaces/roster-interfaces';

import * as H from 'history';

interface WorkersSideColumnProps {
  unassignedShifts: IRosterShift[][];
  assignedShifts: IRosterWorkerWithShifts[];

  workers: IRosterWorker[];

  isWorkersOpen: boolean;
  setWorkersOpen: any;

  history: H.History;
}

export function WorkersSideColumn({
  unassignedShifts = [],
  assignedShifts = [],

  workers = [],
  setWorkersOpen = () => {},
  isWorkersOpen = true,

  history
}: WorkersSideColumnProps) {
  const hasOpenShifts = !_.isEmpty(unassignedShifts);
  const hasWorkers = !_.isEmpty(workers);

  return (
    <div>
      <ColumnGroupHeader text="Team members" onClick={() => setWorkersOpen(!isWorkersOpen)} isOpen={isWorkersOpen} />

      <Collapse isOpen={isWorkersOpen} keepChildrenMounted={false}>
        <div className="flex-column">
          {!hasWorkers && !hasOpenShifts && <EmptySideColumn type="worker" />}

          {/* Open shifts */}
          {hasOpenShifts && <OpenShiftsSideColumn unassignedShifts={unassignedShifts} />}

          {/* Workers */}
          {/* TODO : Re-do this logic for calculating totalDuration */}
          {/*{_.map(workers, (worker, idx) => {*/}
          {/*  const totalDuration = _.reduce(*/}
          {/*    _.filter(*/}
          {/*      shifts,*/}
          {/*      (shift) =>*/}
          {/*        shift.supportWorkerId === worker.supportWorkerId &&*/}
          {/*        !_.find(*/}
          {/*          [ShiftSlotStatus.CANCELLED, ShiftSlotStatus.CANCELLED_UNPAID],*/}
          {/*          (filteredStatus) => shift.shiftSlotStatus === filteredStatus*/}
          {/*        )*/}
          {/*    ),*/}
          {/*    (acc, item) => {*/}
          {/*      return (*/}
          {/*        acc +*/}
          {/*        Number(*/}
          {/*          moment.duration(moment(item.shiftEndDateTime).diff(moment(item.shiftStartDateTime))).asSeconds()*/}
          {/*        )*/}
          {/*      );*/}
          {/*    },*/}
          {/*    0*/}
          {/*  );*/}
          {/*  return <WorkersColumn key={idx} worker={worker} totalDuration={totalDuration} history={history} />;*/}
          {/*})}*/}

          {_.map(workers, (worker, idx) => (
            <WorkersColumn
              key={idx}
              worker={worker}
              history={history}
              // totalDuration={0}
              assignedShifts={assignedShifts}
            />
          ))}
        </div>
      </Collapse>
    </div>
  );
}
