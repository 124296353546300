import React from 'react';

import { Title } from 'common-components/typography';
import { StatusTag } from 'common-components/tags';

export function BookingStatusPageView() {
  return (
    <div>
      <Title level={2}>Booking Status</Title>

      <StatusTag status={'PENDING'} />
    </div>
  );
}
