import React, { useCallback, useEffect, useMemo } from 'react';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { Text } from 'common-components/typography';
import { IconButton, PrimaryButton } from 'common-components/buttons';
import RosterWeekly from 'common-components/roster-control/roster-weekly/RosterWeekly';
import RosterDaily from 'common-components/roster-control/roster-daily/RosterDaily';
import { FilterSection } from 'common-components/filter';

import { bodyHeight } from 'theme/theme-variables';

import { RosterViewSelector } from 'views/bookings/roster-view/common/RosterViewSelector';
import { DaySelector } from 'views/bookings/roster-view/common/DaySelector';
import { RosterLoadingIndicator } from 'views/bookings/roster-view/common/RosterLoadingIndicator';
import { LoaderElement } from 'views/bookings/roster-view/common/LoaderElement';
import { AVAILABLE_FILTERS } from 'views/bookings/roster-view/common/roster-common-config';
import { ROSTER_DISPLAY_MODE, ROSTER_TAB } from 'views/bookings/roster-view/common/roster-common-enums';
import { DisplayModeSelector } from 'views/bookings/roster-view/common/DisplayModeSelector';

import CreateNewBookingModal from 'views/bookings/listings/components/CreateNewBookingModal';

import { useQueryInfiniteShifts } from 'stores/hooks/query-hooks/use-query-infinite-shifts';
import { useQueryInfiniteBookings } from 'stores/hooks/query-hooks/use-query-infinite-bookings';

import { ShiftSlotStatus } from 'utilities/enum-utils';

import { flattenPages } from 'stores/hooks/common/common-hook-utils';

import { IRootDispatch, IRootState } from 'stores/rematch/root-store';

import * as H from 'history';

// TODO : Remove this
const IS_DEBUG = false;

interface RosterLandingViewProps {
  history: H.History;
}

function RosterLandingView({ history }: RosterLandingViewProps) {
  // STATE SELECTORS
  const rosterStore = useSelector((state: IRootState) => state.rosterStore);

  const { filters, dateFilter, selectedTab, selectedDate, selectedMode } = rosterStore;

  // DISPATCH ACTIONS
  const {
    rosterStore: rosterDispatch,
    navigationStore: navigationDispatch,
    customersStore: customersDispatch
  } = useDispatch<IRootDispatch>();

  const { setViewFilters, setSelectedMode, setSelectedTab, setSelectedDate, setDateFilter } = rosterDispatch;
  const { setSelectedSideNavMenuKeys } = navigationDispatch;
  const { doGetCustomer } = customersDispatch;

  // Booking modals states
  const [showBookingModal, setShowBookingModal] = React.useState(false);
  const [selectedCustomer, setSelectedCustomer] = React.useState({});
  const [selectedCreateDate, setSelectedCreateDate] = React.useState(new Date());

  // currently selected week
  const selectedWeek: [Date, Date] = useMemo(() => [dateFilter.values[0], dateFilter.values[1]], [dateFilter]);

  // Determines what filter to use. If it's daily, use selectedDate, else use selectedWeek. This is used for the daily/weekly tabs
  const queryDateFilter = useMemo(() => {
    if (selectedTab === ROSTER_TAB.DAILY) {
      return {
        filter: 'startDate',
        values: [
          moment(selectedDate)
            .startOf('day')
            .toDate(),
          moment(selectedDate)
            .endOf('day')
            .toDate()
        ],
        selectionLabel: moment(selectedDate).format('dddd, DD MMM YYYY')
      };
    } else {
      return {
        filter: 'startDate',
        values: selectedWeek,
        selectionLabel: `${moment(selectedWeek[0]).format('dddd, DD MMM YYYY')} - ${moment(selectedWeek[1]).format(
          'dddd, DD MMM YYYY'
        )}`
      };
    }
  }, [selectedDate, selectedWeek, selectedTab]);

  const combinedFilters = [...filters, queryDateFilter];

  // ---===[ Queries ]===---

  // SHIFTS
  // Fetch infinite query for UNASSIGNED SHIFTS
  const {
    data: unassignedShiftsData,
    fetchNextPage: fetchNextUnassignedShifts,
    hasNextPage: hasNextUnassignedShifts,
    isFetching: isFetchingNextUnassignedShifts,
    refetch: refetchUnassignedShifts
    // remove: removeUnassignedShifts
  } = useQueryInfiniteShifts({
    fetchUnassignedShifts: true,
    filters: combinedFilters,
    showOnlyAssigned: false,
    enabled: true
  });

  // hasNextPage by default is initialised as undefined on page load.
  // Defer loading for unassigned shifts until hasNextPage is an actual boolean value (true/false).
  const shouldFetchShifts = hasNextUnassignedShifts === undefined ? false : !hasNextUnassignedShifts;

  // Fetch infinite query for ASSIGNED SHIFTS
  const {
    data: shiftsData,
    fetchNextPage: fetchNextShifts,
    hasNextPage: hasNextShifts,
    isFetching: isFetchingNextShifts,
    refetch: refetchShifts
    // remove: removeShifts
  } = useQueryInfiniteShifts({
    filters: combinedFilters,
    showOnlyAssigned: true,
    enabled: shouldFetchShifts
  });

  // BOOKINGS
  // fetch query for BOOKINGS
  const {
    data: bookingsData,
    fetchNextPage: fetchNextBookings,
    hasNextPage: hasNextBookings,
    isFetching: isFetchingNextBookings,
    refetch: refetchBookings
    // remove: removeBookings
  } = useQueryInfiniteBookings({ filters: combinedFilters, enabled: true });

  // Expanded memo-ised list of shifts/bookings from response.
  const assignedShifts = useMemo(() => flattenPages(shiftsData), [shiftsData]);
  const unassignedShifts = useMemo(() => flattenPages(unassignedShiftsData), [unassignedShiftsData]);
  const bookings = useMemo(() => flattenPages(bookingsData), [bookingsData]);

  // Combined shifts for rendering.
  const shifts = [...unassignedShifts, ...assignedShifts];

  // Has more rows to fetch (depending on which mode is selected)
  const hasMore =
    selectedMode === ROSTER_DISPLAY_MODE.TEAM_MEMBERS
      ? hasNextUnassignedShifts || hasNextShifts
      : selectedMode === ROSTER_DISPLAY_MODE.CUSTOMERS
      ? hasNextBookings
      : selectedMode === ROSTER_DISPLAY_MODE.ALL
      ? hasNextUnassignedShifts || hasNextShifts || hasNextBookings
      : false;

  // Are any queries running?
  const isFetching =
    selectedMode === ROSTER_DISPLAY_MODE.TEAM_MEMBERS
      ? isFetchingNextUnassignedShifts || isFetchingNextShifts
      : selectedMode === ROSTER_DISPLAY_MODE.CUSTOMERS
      ? isFetchingNextBookings
      : selectedMode === ROSTER_DISPLAY_MODE.ALL
      ? isFetchingNextUnassignedShifts || isFetchingNextShifts || isFetchingNextBookings
      : false;

  // Fetch more data when the user scrolls to the bottom of the list/request a reload / etc. Triggered by loadingElement.
  function fetchMore() {
    if (selectedTab === ROSTER_TAB.WEEKLY) {
      if (selectedMode === ROSTER_DISPLAY_MODE.TEAM_MEMBERS) {
        // Fetch unassigned shifts first, then assigned shifts.
        if (hasNextUnassignedShifts) {
          fetchNextUnassignedShifts();
        } else if (hasNextShifts) {
          fetchNextShifts();
        }
      }

      if (selectedMode === ROSTER_DISPLAY_MODE.CUSTOMERS) {
        fetchNextBookings();
      }

      if (selectedMode === ROSTER_DISPLAY_MODE.ALL) {
        // Fetch bookings, unassigned shifts, then assigned shifts in that order.
        if (hasNextBookings) {
          fetchNextBookings();
        } else if (hasNextUnassignedShifts) {
          fetchNextUnassignedShifts();
        } else if (hasNextShifts) {
          fetchNextShifts();
        }
      }
    } else if (selectedTab === ROSTER_TAB.DAILY) {
      // TBC
    }
  }

  // Go to the selected mode (Team members/customers/all)
  const goToMode = (mode) => {
    setSelectedMode(mode);
  };

  // Go to the selected tab (Weekly/Daily)
  const goToTab = (tab) => {
    setSelectedTab(tab);
  };

  // Go to a specific date.
  const goToDate = useCallback(
    (targetDate) => {
      if (targetDate !== null) {
        setSelectedDate(targetDate);

        const startDateFilter = dateFilter.values[0];
        const endDateFilter = dateFilter.values[1];

        const mTargetDate = moment(targetDate);

        const isBefore = mTargetDate.isSameOrAfter(startDateFilter);
        const isAfter = mTargetDate.isSameOrBefore(endDateFilter);

        const isWithin = isBefore && isAfter;

        if (!isWithin) {
          const updatedDateFilter = {
            ...dateFilter,
            values: [mTargetDate.startOf('isoWeek').toDate(), mTargetDate.endOf('isoWeek').toDate()]
          };

          setDateFilter(updatedDateFilter);
        }
      }
    },
    [dateFilter, setDateFilter, setSelectedDate]
  );

  // Go to a specific day and switch to the daily view. Used for handling date header clicks.
  const goToDailyDate = useCallback(
    (date) => {
      setSelectedTab(ROSTER_TAB.DAILY);
      goToDate(date);
    },
    [goToDate, setSelectedTab]
  );

  // Move to the previous/next week or day, depending on the selected tab
  function navigateByDate(offset) {
    let offSetDate = new Date();

    // If we're on the daily tab, we need to move by 1 day as a unit.
    if (selectedTab === ROSTER_TAB.DAILY) {
      offSetDate = moment(selectedDate)
        .add(offset, 'day')
        .toDate();
    }

    // If we're on the weekly tab, we need to move by 1 week as a unit.
    if (selectedTab === ROSTER_TAB.WEEKLY) {
      offSetDate = moment(selectedWeek[0])
        .add(offset, 'week')
        .toDate();
    }

    goToDate(offSetDate);
  }

  // Function to refresh the data
  function refreshData() {
    // Remove from cache. Not sure if we should remove from cache, or just initiate a refetch.
    // removeBookings();
    // removeUnassignedShifts();
    // removeShifts();

    // Initiate refetch
    if (selectedMode === ROSTER_DISPLAY_MODE.TEAM_MEMBERS) {
      refetchUnassignedShifts();
      refetchShifts();
    }

    if (selectedMode === ROSTER_DISPLAY_MODE.CUSTOMERS) {
      refetchBookings();
    }

    if (selectedMode === ROSTER_DISPLAY_MODE.ALL) {
      refetchUnassignedShifts();
      refetchShifts();
      refetchBookings();
    }
  }

  // Used to create booking within the daily/weekly cells.
  // TODO :Daily only for now.
  async function createBookingForCustomer({ customerId, startDateTime }: { customerId: string; startDateTime?: Date }) {
    const selectedCustomer = await doGetCustomer({ userId: customerId });
    setSelectedCustomer(selectedCustomer);
    setSelectedCreateDate(startDateTime);
    setShowBookingModal(true);
  }

  // Close the booking modal, and do a quick refresh just in case
  function closeBookingModal() {
    refreshData();
    setShowBookingModal(false);
  }

  useEffect(() => {
    setSelectedSideNavMenuKeys(['/bookings/calendar']);
  }, [setSelectedSideNavMenuKeys]);

  return (
    <div className="bg-white bordered-top position-relative" style={{ minHeight: `${bodyHeight}` }}>
      {isFetching && <RosterLoadingIndicator />}

      <div className="mt-medium">
        <div id="sub-content">
          {/* Header */}
          <div className="ph-medium pt-small pb-medium">
            <div className="flex-row align-center justify-between">
              {/* Left column - Time navigator */}
              <div className="flex-row align-center flex-1">
                <div className="mr-small">
                  <RosterViewSelector onSelectTab={goToTab} selectedTab={selectedTab} />
                </div>

                <PrimaryButton size="large" className="mr-small" onClick={() => goToDate(new Date())}>
                  Today
                </PrimaryButton>

                <div className="mr-small whitespace-nowrap">
                  <IconButton size="large" icon="left" className="rounded-left" onClick={() => navigateByDate(-1)} />
                  <IconButton size="large" icon="right" className="rounded-right" onClick={() => navigateByDate(1)} />
                </div>
              </div>

              {/* Middle column - Day selector */}
              <div className="flex-row justify-center flex-1">
                <DaySelector
                  selectedDate={selectedDate}
                  selectedWeek={selectedWeek}
                  onChangeDate={goToDate}
                  mode={selectedTab}
                />
              </div>

              {/* Right column - Empty holder */}
              <div className="flex-row flex-1 justify-end align-center text-align-right whitespace-nowrap">
                <IconButton
                  icon={'reload'}
                  className="mr-medium"
                  bordered={true}
                  iconColor={'blue-action'}
                  color="white"
                  onClick={refreshData}
                />
                <DisplayModeSelector selectedMode={selectedMode} setSelectedMode={goToMode} />
              </div>
            </div>
          </div>

          {/* TODO : Change to international timezone selector */}
          <div className="ph-medium mb-small">
            <FilterSection
              availableFilters={AVAILABLE_FILTERS}
              filters={filters}
              displayTimezone={'Australia/Melbourne'}
              onChangeFilter={setViewFilters}
              containerClassName="flex-row justify-between"
            />
          </div>

          {/* Tabs for daily / weekly view */}
          <Tabs
            animated={false}
            renderTabBar={() => <div />}
            activeKey={selectedTab}
            style={{ alignSelf: 'stretch', overflowY: 'auto' }}
            className="flex-column flex-1"
            destroyInactiveTabPane={true}
          >
            {/* Daily tab */}
            <Tabs.TabPane tab="Daily" key={ROSTER_TAB.DAILY}>
              <RosterDaily
                bookings={bookings}
                assignedShifts={assignedShifts}
                unassignedShifts={unassignedShifts}
                selectedDate={selectedDate}
                showCustomers={
                  selectedMode === ROSTER_DISPLAY_MODE.CUSTOMERS || selectedMode === ROSTER_DISPLAY_MODE.ALL
                }
                showWorkers={
                  selectedMode === ROSTER_DISPLAY_MODE.TEAM_MEMBERS ||
                  (selectedMode === ROSTER_DISPLAY_MODE.ALL && !hasNextBookings) // Hide the team members section until bookings has completely loaded
                }
                onAddBooking={createBookingForCustomer}
                showOpenSlots={true}
                refreshData={refreshData}
                history={history}
              />

              {/* Load more element. This element will fetch more data whenever it's visible. */}
              <LoaderElement isFetching={isFetching} onFetch={fetchMore} hasMore={hasMore} />
            </Tabs.TabPane>

            {/* Weekly tab */}
            <Tabs.TabPane tab="Weekly" key={ROSTER_TAB.WEEKLY}>
              <RosterWeekly
                bookings={bookings}
                shifts={shifts}
                selectedWeek={selectedWeek}
                onClickHeaderDay={goToDailyDate}
                showCustomers={
                  selectedMode === ROSTER_DISPLAY_MODE.CUSTOMERS || selectedMode === ROSTER_DISPLAY_MODE.ALL
                }
                showWorkers={
                  selectedMode === ROSTER_DISPLAY_MODE.TEAM_MEMBERS ||
                  (selectedMode === ROSTER_DISPLAY_MODE.ALL && !hasNextBookings) // Hide the team members section until bookings has completely loaded
                }
                isFetching={isFetching}
                refreshData={refreshData}
                history={history}
              />

              {/* Load more element */}
              <LoaderElement isFetching={isFetching} onFetch={fetchMore} hasMore={hasMore} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>

      {IS_DEBUG && (
        <div className="position-fixed bg-secondary p-small rounded-big" style={{ top: 10, right: 20 }}>
          <Text size="small">
            hasNextShifts: {String(hasNextShifts)}, hasNextUnassignedShifts: {String(hasNextUnassignedShifts)},
          </Text>
          <br />
          <Text size="small">hasNextBookings: {String(hasNextBookings)}</Text>
          <br />
          <Text size="small">
            isFetching: {String(isFetching)}, hasMore: {String(hasMore)}
          </Text>
          <br /> <br />
          {/* Customer id - Adalberto : 1f527bc4-db22-46b2-9f97-a42928a08756 */}
          <PrimaryButton
            onClick={() => createBookingForCustomer({ customerId: '1f527bc4-db22-46b2-9f97-a42928a08756' })}
          >
            Show booking wizard
          </PrimaryButton>
          <br /> <br />
        </div>
      )}

      <CreateNewBookingModal
        isOpen={showBookingModal}
        history={history}
        incomingCustomer={selectedCustomer}
        incomingStartDateTime={selectedCreateDate}
        closeCreateBookingModal={closeBookingModal}
      />
    </div>
  );
}

export default RosterLandingView;
