import _ from 'lodash';
import { NdisSupportTypes } from './enum-utils';

class FundingUtils {
  static isLineItemExistInFundingPackage = (supportType, supportCategoryNumber, supportItemNumber, fundingPackage) => {
    // First check if the item is a Stated Support.
    const fundStatedSupport = _.find(fundingPackage, (category) => {
      return supportItemNumber === category.supportItemNumber;
    });

    // If not a Stated Support, take the parent Category funding.
    return fundStatedSupport
      ? fundStatedSupport
      : _.find(fundingPackage, (category) => {
          return (
            supportType.toUpperCase() === NdisSupportTypes.CORE ||
            (supportCategoryNumber === category.supportCategoryNumber &&
              !category.supportItemNumber &&
              Number(category.funding) > 0)
          );
        });
  };
}

export default FundingUtils;
