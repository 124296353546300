import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { notification } from 'antd';
import { connect } from 'react-redux';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';

interface IPlanPayListDeleteLineItemModalProps {
  isOpen: boolean;
  refreshListing?: any;
  selectedItem: any;
  onClose: () => void;
  doDeleteInvoiceLineItem: typeof dispatch.planManagementStore.doDeleteInvoiceLineItem;
}

interface IPlanPayListDeleteLineItemModalState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

class PlanPayListDeleteLineItemModal extends Component<
  IPlanPayListDeleteLineItemModalProps,
  IPlanPayListDeleteLineItemModalState
> {
  state = {
    isLoading: false,
    isSuccess: false,
    isError: false
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false, isError: false });
      onClose();
    }
  };

  private _onSubmitDelete = async () => {
    const { selectedItem, doDeleteInvoiceLineItem, refreshListing } = this.props;

    try {
      await doDeleteInvoiceLineItem({
        invoiceId: selectedItem.planManagementInvoiceId,
        planManagementLineItemId: selectedItem.planManagementLineItemId
      });

      if (refreshListing) {
        await refreshListing();
      }

      this._onCloseModal();
    } catch (e) {
      notification.error({ message: 'Error', description: 'Oops. Something went wrong, please try again later' });
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { isOpen, selectedItem } = this.props;
    return (
      selectedItem && (
        <ActionModal
          title="Delete Line Item"
          isOpen={isOpen}
          onClose={this._onCloseModal}
          width="medium"
          verticalAlignment="center"
        >
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>Are you sure you want to delete this line item?</Paragraph>
            </div>
            <div className="mb-medium mt-large">
              <Paragraph>This action is permanent and cannot be undone.</Paragraph>
            </div>
          </div>
          <ActionModalFooter>
            <GhostButton
              size="large"
              onClick={this._onCloseModal}
              className="mr-medium"
              disabled={this.state.isLoading}
            >
              Close
            </GhostButton>
            <PrimaryButton size="large" onClick={this._onSubmitDelete} loading={this.state.isLoading}>
              Delete
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      )
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doDeleteInvoiceLineItem: dispatch.planManagementStore.doDeleteInvoiceLineItem
});

export default connect(
  null,
  mapDispatch
)(PlanPayListDeleteLineItemModal);
