import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from '../../../../common-components/modal/ActionModal';
import { ErrorSVG } from '../../../../assets/UndrawSVG';
import { Paragraph } from '../../../../common-components/typography';
import SpinningLoader from '../../../../common-components/loading/SpinningLoader';
import { GhostButton, PrimaryButton, SecondaryButton } from '../../../../common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';
import { parse } from 'json2csv';
import PreviewInvoicesModal from 'views/billings/payments/components/PreviewInvoicesModal';
import { PaymentSources } from 'utilities/enum-utils';

const fileDownload = require('js-file-download');

interface IProcessPaymentActionModalProps {
  isOpen: any;
  onClose: any;
  deselect: any;
  paymentsList: typeof state.billingsStore.paymentsList;
  selectedBillingLineItem: typeof state.billingsStore.selectedBillingLineItem;
  paymentsSelectedAll: typeof state.billingsStore.paymentsSelectedAll;
  doProcessPayments: typeof dispatch.billingsStore.doProcessPayments;
  setPaymentsSelectedAll: typeof dispatch.billingsStore.setPaymentsSelectedAll;
  paymentSourceType: string;
}

class ProcessPaymentActionModal extends Component<IProcessPaymentActionModalProps, any> {
  state = {
    selectedLineItems: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    counts: {},
    title: 'Process line items',
    isPreviewModalOpen: false
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({
        isLoading: false,
        isSuccess: false,
        selectedLineItems: [],
        paymentsSelectedAll: false,
        isError: false
      });
      onClose();
    }
  };

  private _openPreviewModal = () => {
    this.setState({ isPreviewModalOpen: true });
  };

  private _closePreviewModal = () => {
    this.setState({ isPreviewModalOpen: false });
  };

  private _onSubmitProcess = async () => {
    const { doProcessPayments, deselect, selectedBillingLineItem, paymentSourceType } = this.props;

    this.setState({ isLoading: true });

    try {
      const resp: any = await doProcessPayments({ billingLineItems: selectedBillingLineItem, paymentSourceType });

      if (!_.isEmpty(resp.csvData) && paymentSourceType === PaymentSources.NDIS) {
        const csvData = parse(resp.csvData, { quote: '' });
        fileDownload(csvData, `ndia_export.csv`);
      }

      deselect();
      this.setState({ isLoading: false, counts: 0, isSuccess: true, title: 'Line items successfully processed' });
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false, isError: true });
    }
  };

  render() {
    const { isOpen, selectedBillingLineItem, paymentSourceType } = this.props;

    const numberOfLineItems = selectedBillingLineItem.length;

    const numberOfNDIALineItems = selectedBillingLineItem.filter((line) => {
      return line.paymentMethod === 'NDIA';
    }).length;

    const planManagedLineItems = selectedBillingLineItem.filter((line) => {
      return line.paymentMethod === 'PLAN';
    });
    const numberOfPlanManagedInvoices = planManagedLineItems ? _.uniqBy(planManagedLineItems, 'userId').length : 0;

    const selfManagedLineItems = selectedBillingLineItem.filter((line) => {
      return line.paymentMethod === 'SELF';
    });
    const numberOfSelfManagedInvoices = selfManagedLineItems ? _.uniqBy(selfManagedLineItems, 'userId').length : 0;

    const isVCP = paymentSourceType === PaymentSources.VCP;

    const numberOfCaseManagedLineItems = isVCP && _.uniqBy(selectedBillingLineItem, 'userId').length;

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="medium"
        verticalAlignment="highest"
      >
        <PreviewInvoicesModal
          isOpen={this.state.isPreviewModalOpen}
          onClose={this._closePreviewModal}
          selectedBillingLineItem={selectedBillingLineItem}
        />
        {!this.state.isLoading && !this.state.isSuccess && !this.state.isError && (
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>
                You are about to process {numberOfLineItems} {paymentSourceType} item{numberOfLineItems > 1 && 's'}.
              </Paragraph>
              {!isVCP && numberOfNDIALineItems > 0 && (
                <Paragraph>
                  A CSV with{' '}
                  <b>
                    {numberOfNDIALineItems} {paymentSourceType} line item{numberOfNDIALineItems > 1 && 's'}
                  </b>{' '}
                  will be downloaded.
                </Paragraph>
              )}
              {!isVCP && numberOfPlanManagedInvoices > 0 && (
                <Paragraph>
                  <b>
                    {numberOfPlanManagedInvoices} invoice{numberOfPlanManagedInvoices > 1 && 's'}
                  </b>{' '}
                  will be created and sent to the relevant <b>plan manager{numberOfPlanManagedInvoices > 1 && 's'}</b>.
                </Paragraph>
              )}
              {!isVCP && numberOfSelfManagedInvoices > 0 && (
                <Paragraph>
                  <b>
                    {numberOfSelfManagedInvoices} invoice{numberOfSelfManagedInvoices > 1 && 's'}
                  </b>{' '}
                  will be created and sent to relevant <b>self manager{numberOfSelfManagedInvoices > 1 && 's'}</b>.
                </Paragraph>
              )}
              {isVCP && numberOfCaseManagedLineItems > 0 && (
                <Paragraph>
                  <b>
                    {numberOfCaseManagedLineItems} invoice{numberOfCaseManagedLineItems > 1 && 's'}
                  </b>{' '}
                  will be generated and sent to relevant <b>case manager{numberOfCaseManagedLineItems > 1 && 's'}</b>.
                </Paragraph>
              )}
            </div>
          </div>
        )}
        {this.state.isLoading && <SpinningLoader size={100} message={'Loading'} />}
        {!this.state.isLoading && this.state.isSuccess && (
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>
                You have successfully processed {numberOfLineItems} {paymentSourceType} Item
                {numberOfLineItems > 1 && 's'}{' '}
                {isVCP && `for ${numberOfCaseManagedLineItems} customer${numberOfCaseManagedLineItems > 1 ? 's' : ''}`}.
              </Paragraph>
              {!isVCP && numberOfNDIALineItems > 0 && (
                <Paragraph>
                  A CSV with{' '}
                  <b>
                    {numberOfNDIALineItems} {paymentSourceType} line item{numberOfNDIALineItems > 1 && 's'}
                  </b>{' '}
                  will be downloaded shortly.{' '}
                  {paymentSourceType === PaymentSources.NDIS && 'Please upload this CSV file to PRODA'}.
                </Paragraph>
              )}
              {!isVCP && numberOfPlanManagedInvoices > 0 && (
                <Paragraph>
                  <b>
                    {numberOfPlanManagedInvoices} invoice{numberOfPlanManagedInvoices > 1 && 's'}
                  </b>{' '}
                  {numberOfPlanManagedInvoices > 1 ? 'have' : 's'} has been created and sent to the relevant{' '}
                  <b>plan managers</b>.
                </Paragraph>
              )}
              {!isVCP && numberOfSelfManagedInvoices > 0 && (
                <Paragraph>
                  <b>
                    {numberOfSelfManagedInvoices} invoice{numberOfSelfManagedInvoices > 1 && 's'}
                  </b>{' '}
                  has been created and will be send to the relevant <b>self managers</b>.
                </Paragraph>
              )}
            </div>
          </div>
        )}

        {!this.state.isSuccess && !this.state.isError && (
          <ActionModalFooter>
            <GhostButton
              size="large"
              onClick={this._onCloseModal}
              className="mr-medium"
              disabled={this.state.isLoading}
            >
              Not now
            </GhostButton>
            <SecondaryButton
              size="large"
              onClick={this._openPreviewModal}
              loading={this.state.isLoading}
              className={'mr-medium'}
            >
              Preview invoices
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._onSubmitProcess} loading={this.state.isLoading}>
              Process line item{numberOfLineItems > 1 && 's'}
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {this.state.isSuccess && !this.state.isError && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {!this.state.isLoading && this.state.isError && (
          <div className="text-align-center">
            <div className="pv-medium">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium">
              <Paragraph>An error has occured.</Paragraph>
            </div>
          </div>
        )}

        {this.state.isError && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  paymentsList: state.billingsStore.paymentsList
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doProcessPayments: dispatch.billingsStore.doProcessPayments
});

export default connect(
  mapState,
  mapDispatch
)(ProcessPaymentActionModal);
