const navigationStore = {
  state: {
    // currentLocation: 0
    selectedSideNavMenuKeys: [],
    sideMenuCollapsed: true
  },
  reducers: {
    // increment: (state, payload) => ({ ...state, currentCounter: state.currentCounter + payload })
    setSelectedSideNavMenuKeys: (state, payload) => ({ ...state, selectedSideNavMenuKeys: payload }),
    setSideMenuCollapsed: (state, payload) => ({ ...state, sideMenuCollapsed: payload })
  },
  effects: (dispatch) => ({
    // async incrementAsync(payload, rootState) {
    //   dispatch.navigationStore.increment(payload);
    // }
  })
};

export default navigationStore;
