import React, { Component } from 'react';

import _ from 'lodash';

import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import { Spinner, Popover } from '@blueprintjs/core';

import { IShiftSlot } from 'interfaces/shift-interfaces';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';

import { ShiftSlotStatus } from 'utilities/enum-utils';

import InfoPanel from 'common-components/alerts/InfoPanel';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, SubTitle, Text, Paragraph } from 'common-components/typography';
import { PrimaryButton, SecondaryButton, IconButton } from 'common-components/buttons';
import TimeInput from 'common-components/time-input/TimeInput';
import { ISession } from 'interfaces/session-interfaces';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import CommonUtils from 'utilities/common-utils';
import { Avatar, Row } from 'antd';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';

interface IEditSlotTimesModalProps {
  isOpen: boolean;
  shiftSlot: IShiftSlot;
  onClose: (targetFlag, refreshShiftSlots?: boolean) => void;
  doEditShiftTimes: typeof dispatch.groupServiceStore.doEditShiftTimes;
  doCheckConflictForEditShiftTimes: typeof dispatch.groupServiceStore.doCheckConflictForEditShiftTimes;
  session: ISession;
}

interface IEditSlotTimesModalState {
  step: number;
  canManuallyClose: boolean;

  shiftStartDate: Moment;
  shiftEndDate: Moment;

  refreshShiftSlots: boolean;
  conflicts: IShiftSlot[];
  scheduledRemoveData: any;
}

class EditSlotTimesModal extends Component<IEditSlotTimesModalProps, IEditSlotTimesModalState> {
  state = {
    step: 1,
    canManuallyClose: true,
    shiftStartDate: moment(),
    shiftEndDate: moment(),
    refreshShiftSlots: false,
    conflicts: null,
    scheduledRemoveData: null,
  };

  private _reset = () => {
    this.setState({
      step: 1,
      refreshShiftSlots: false,
      shiftStartDate: moment(),
      shiftEndDate: moment(),
      scheduledRemoveData: null,
    });
  };

  private _onSave = async (checkConflict = false) => {
    const { doEditShiftTimes, doCheckConflictForEditShiftTimes, session, shiftSlot } = this.props;
    const { shiftEndDate, shiftStartDate } = this.state;

    this.setState({ step: 2, canManuallyClose: false });

    const request = {
      serviceId: session.serviceId,
      serviceDateTimeId: session.serviceDateTimeId,
      supportWorkerAttendanceId: shiftSlot.supportWorkerAttendanceId,
      startDateTime: moment.tz(moment(shiftStartDate).format('YYYY-MM-DD HH:mm'), session.timezone),
      endDateTime: moment.tz(moment(shiftEndDate).format('YYYY-MM-DD HH:mm'), session.timezone),
    };

    let conflictingShiftSlot = null;
    if (checkConflict) {
      conflictingShiftSlot = await doCheckConflictForEditShiftTimes(request);
    }
    if (conflictingShiftSlot && !_.isEmpty(conflictingShiftSlot.workerScheduleRemove)) {
      const {
        firstName,
        lastName,
        removedOn,
        shiftEndDateTime,
        shiftStartDateTime,
      } = conflictingShiftSlot.workerScheduleRemove;
      this.setState({
        step: 5,
        scheduledRemoveData: {
          firstName,
          lastName,
          removeDate: removedOn,
          shiftDate: [shiftStartDateTime, shiftEndDateTime],
        },
      });
    } else if (conflictingShiftSlot && !conflictingShiftSlot.status) {
      this.setState({ step: 4, conflicts: conflictingShiftSlot.conflicts });
    } else if (conflictingShiftSlot.outsideAvailability) {
      this.setState({
        step: 6,
        canManuallyClose: true,
      });
    } else {
      await doEditShiftTimes(request);
      this.setState({ step: 3, canManuallyClose: true, refreshShiftSlots: true });
    }
  };

  private _onClose = () => {
    const { onClose } = this.props;
    onClose({ targetFlag: 'isEditSlotTimesOpen' }, this.state.refreshShiftSlots);
    this._reset();
  };

  private _onChangeStartDate = (date) => {
    this.setState({ shiftStartDate: moment(CommonUtils.formatCeilingDateTime(date)) });
    const minEndDate = moment(date).add(1, 'hour');

    if (this.state.shiftEndDate < minEndDate) {
      this.setState({ shiftEndDate: moment(minEndDate) });
    }
  };

  private _onChangeEndDate = (date) => {
    if (moment(date).isAfter(this.state.shiftStartDate)) {
      this.setState({ shiftEndDate: moment(CommonUtils.formatCeilingDateTime(date)) });
    } else {
      this.setState({ shiftEndDate: this.state.shiftEndDate });
    }
  };

  private _goToEditTimesStep = () => {
    this.setState({ step: 1 });
  };

  private _editShiftTimeWithOutsideAvailability = async (keepTeamMember) => {
    const { session, shiftSlot, doEditShiftTimes } = this.props;
    const { shiftEndDate, shiftStartDate } = this.state;
    this.setState({ step: 2, canManuallyClose: false });

    const request = {
      serviceId: session.serviceId,
      serviceDateTimeId: session.serviceDateTimeId,
      supportWorkerAttendanceId: shiftSlot.supportWorkerAttendanceId,
      startDateTime: moment.tz(moment(shiftStartDate).format('YYYY-MM-DD HH:mm'), session.timezone),
      endDateTime: moment.tz(moment(shiftEndDate).format('YYYY-MM-DD HH:mm'), session.timezone),
      keepTeamMember,
    };

    await doEditShiftTimes(request);
    this.setState({ step: 3, canManuallyClose: true, refreshShiftSlots: true });
  };

  componentDidUpdate(
    prevProps: Readonly<IEditSlotTimesModalProps>,
    prevState: Readonly<IEditSlotTimesModalState>,
    snapshot?: any,
  ) {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.isOpen) {
        const { shiftSlot } = this.props;
        const { shiftStartDateTime, shiftEndDateTime } = shiftSlot;
        this.setState({
          shiftStartDate: moment(moment.tz(shiftStartDateTime, this.props.session.timezone).format('YYYY-MM-DD HH:mm')),
          shiftEndDate: moment(moment.tz(shiftEndDateTime, this.props.session.timezone).format('YYYY-MM-DD HH:mm')),
        });
      }
    }
  }

  render() {
    const { shiftSlot, session } = this.props;
    const { conflicts } = this.state;

    if (_.isEmpty(shiftSlot)) {
      return <></>;
    }

    const { shiftStartDateTime, shiftEndDateTime, shiftSlotStatus } = shiftSlot;

    const shiftDatesWithoutTimezone = {
      shiftStartDateTime: moment(moment.tz(shiftStartDateTime, session.timezone).format('YYYY-MM-DD HH:mm')),
      shiftEndDateTime: moment(moment.tz(shiftEndDateTime, session.timezone).format('YYYY-MM-DD HH:mm')),
    };

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title={
          this.state.step === 5
            ? 'Team member scheduled to be removed'
            : this.state.step === 4
            ? 'Team member schedule conflict'
            : this.state.step === 3
            ? 'Time successfully changed'
            : this.state.step === 6
            ? 'Team member unavailable for shift'
            : 'Edit Slot Times'
        }
        onClose={this._onClose}
        width="medium"
        canCloseOutside={this.state.canManuallyClose}
      >
        {this.state.step === 1 && (
          <div>
            <div className="mb-medium line-height-135">
              <Text lineHeight={135}>Edit the scheduled time of this slot.</Text>
            </div>

            <div className="mb-large flex-row line-height-135">
              <div className="flex-1 mr-large">
                <FieldLabel text={'START TIME'} />

                <div className="flex-row">
                  {/*<DatePicker size="large" style={{ width: '144px' }} className="mr-medium" />*/}
                  <DatePicker
                    className="gh-datepicker rounded mr-medium"
                    calendarClassName="gh-datepicker-calendar"
                    onChange={this._onChangeStartDate}
                    dateFormat={'dd/MM/yyyy'}
                    isClearable={false}
                    selected={this.state.shiftStartDate.toDate()}
                    minDate={shiftDatesWithoutTimezone.shiftStartDateTime.toDate()}
                  />

                  <TimeInput
                    size="large"
                    value={moment(this.state.shiftStartDate)}
                    onChange={this._onChangeStartDate}
                  />
                </div>
              </div>
              <div className="flex-1">
                <FieldLabel text={'END TIME'} />

                <div className="flex-row">
                  <DatePicker
                    className="gh-datepicker rounded mr-medium"
                    calendarClassName="gh-datepicker-calendar"
                    onChange={this._onChangeEndDate}
                    dateFormat={'dd/MM/yyyy'}
                    isClearable={false}
                    selected={this.state.shiftEndDate.toDate()}
                    minDate={shiftDatesWithoutTimezone.shiftEndDateTime.toDate()}
                  />

                  <TimeInput size="large" value={moment(this.state.shiftEndDate)} onChange={this._onChangeEndDate} />
                </div>
              </div>
            </div>

            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={() => this._onSave(true)}>
                Save
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {this.state.step === 2 && (
          <div className="line-height-135 anim-slide-left">
            <div className="flex-column align-center mv-large justify-center">
              <div className="mb-medium">
                <Spinner size={80} />
              </div>
              <div className="text-align-center">
                <Text color="secondary" weight="bold">
                  Updating slot time...
                </Text>
                <br />
                <Text color="secondary">This won't take long.</Text>
              </div>
            </div>
          </div>
        )}

        {this.state.step === 3 && (
          <div className="anim-fade-in-fast">
            <div className="mb-medium line-height-135">
              <Text lineHeight={135}>You’ve successfully changed the time of this shift to :</Text>
            </div>

            <div className="line-height-135 mb-large">
              <FieldLabel text={'New slot time'} />
              <Text lineHeight={135}>
                {moment(this.state.shiftStartDate).format('h:mm A')} -{' '}
                {moment(this.state.shiftEndDate).format('h:mm A, D MMMM YYYY')}
              </Text>
            </div>

            {shiftSlotStatus === ShiftSlotStatus.UNASSIGNED && (
              <InfoPanel text={'This slot is currently unassigned; you will need to assign a team member to it.'} />
            )}

            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this._onClose}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {this.state.step === 4 && conflicts && (
          <div>
            <div className="mb-large line-height-135">
              <Text lineHeight={135}>
                As a result of the changes you have made to the shift times, the worker assigned now has a schedule
                conflict with another shift they are assigned to. If you choose to continue the team member will be
                removed from this shift.
              </Text>
            </div>

            <div className="mb-large line-height-135">
              <div className={'mb-large'}>
                <SubTitle>Team member</SubTitle>
                <div className={'flex-row align-center'}>
                  <Avatar icon="user" className="mr-small" src={shiftSlot.attachmentUrl} />
                  {`${shiftSlot.firstName} ${shiftSlot.lastName}`}
                </div>
              </div>
              <div className={'mb-large'}>
                <SubTitle>Conflicting shift time</SubTitle>
                <div className="flex-column">
                  {_.map(conflicts, (conflict) => {
                    return (
                      <Text>
                        {moment.tz(conflict.startDateTime, session.timezone).format('DD/MM/YYYY, hh:mmA - ')}
                        {moment.tz(conflict.endDateTime, session.timezone).format('hh:mmA')}
                      </Text>
                    );
                  })}
                </div>
              </div>
            </div>

            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this._goToEditTimesStep}>
                Go back
              </SecondaryButton>
              <PrimaryButton size="large" onClick={() => this._onSave(false)}>
                Remove team member & save
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
        {this.state.step === 5 && this.state.scheduledRemoveData && (
          <>
            <Paragraph>
              The selected team member, <Text weight="bold">{shiftSlot.firstName + ' ' + shiftSlot.lastName}</Text>, is
              scheduled to be removed on or before the scheduled booking time
            </Paragraph>
            <Paragraph className="mt-small">
              As a result {shiftSlot.firstName + ' ' + shiftSlot.lastName} will be removed as the assigned team member
              for this shift slot.
            </Paragraph>
            <div className="mb-x2-small">
              <FieldLabel text={'TEAM MEMBER SCHEDULE TO BE REMOVE ON...'} />
            </div>
            <Paragraph>
              {moment.tz(this.state.scheduledRemoveData.removeDate, session.timezone).format('DD MMMM YYYY')}
            </Paragraph>

            <div className="mb-x2-small">
              <FieldLabel text={'BOOKING DATE'} />
            </div>
            <div className="flex-column">
              <Text>
                {moment.tz(this.state.scheduledRemoveData.shiftDate[0], session.timezone).format('DD MMMM YYYY')}
              </Text>
              <Text>
                {moment.tz(this.state.scheduledRemoveData.shiftDate[0], session.timezone).format('HH:mm A')} -{' '}
                {moment.tz(this.state.scheduledRemoveData.shiftDate[1], session.timezone).format('HH:mm A')}
              </Text>
            </div>
            <ActionModalFooter align="right">
              <SecondaryButton size="large" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton className="ml-small" size="large" onClick={() => this._onSave(false)}>
                Confirm
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {this.state.step === 6 && (
          <>
            <Paragraph>This new shift time conflicts with a team member’s availability.</Paragraph>
            <Paragraph>
              Choose whether to keep or remove this team member from the session to continue with your shift changes.
            </Paragraph>
            <div className="mb-x2-small">
              <FieldLabel text="New shift time" />
            </div>
            <div className="flex-column">
              <Text>{moment(this.state.shiftStartDate).format('DD MMMM YYYY')}</Text>
              <Text>
                {moment(this.state.shiftStartDate).format('hh:mm A')} -{' '}
                {moment(this.state.shiftEndDate).format('hh:mm A')}
              </Text>
            </div>
            <div className="mt-x3-large">
              <Row type={'flex'} justify={'end'}>
                <SecondaryButton size="large" onClick={this._onClose} disabled={!this.state.canManuallyClose}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  className="ml-small"
                  onClick={() => this._editShiftTimeWithOutsideAvailability(false)}
                  loading={!this.state.canManuallyClose}
                >
                  Remove team member
                </PrimaryButton>
                <Popover
                  content={
                    <ActionMenu>
                      <ActionMenuItem
                        text="Keep team member"
                        onClick={() => this._editShiftTimeWithOutsideAvailability(true)}
                      />
                    </ActionMenu>
                  }
                  position="bottom-left"
                  usePortal={false}
                >
                  <IconButton className="rounded-right ml-x2-small" icon="down" size="large" />
                </Popover>
              </Row>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditShiftTimes: dispatch.groupServiceStore.doEditShiftTimes,
  doCheckConflictForEditShiftTimes: dispatch.groupServiceStore.doCheckConflictForEditShiftTimes,
});

export default connect(mapState, mapDispatch)(EditSlotTimesModal);
