import { Text } from 'common-components/typography';
import React from 'react';
import { Icon } from 'antd';

interface IRecommendStatusIconProps {
  recommendStatus?: 'recommended' | 'under' | 'over' | string;
}

function RecommendStatusIcon({ recommendStatus }: IRecommendStatusIconProps) {
  let iconType = 'check';
  let bgClass = 'bg-green-lightest';
  let txtClass = 'text-color-green';

  switch (recommendStatus) {
    case 'recommended':
      iconType = 'check';
      bgClass = 'bg-green-lightest';
      txtClass = 'text-color-green';
      break;
    case 'under':
      iconType = 'arrow-down';
      bgClass = 'bg-orange-lightest';
      txtClass = 'text-color-orange';
      break;
    case 'over':
      iconType = 'arrow-up';
      bgClass = 'bg-red-lightest';
      txtClass = 'text-color-red';
      break;
  }

  return (
    <div
      className={`inline-block rounded-full flex-column align-center justify-center text-align-center line-height-135 mr-small ${bgClass}`}
      style={{ height: '24px', width: '24px' }}
    >
      <Icon type={iconType} className={txtClass} style={{ fontSize: '12px' }} />
    </div>
  );
}

interface IRecommendedTeamMembersPaneProps {
  recommendedTotalShiftSlot: number;
  totalAssignedShiftSlot: number;
}

export function RecommendedTeamMembersPane(props: IRecommendedTeamMembersPaneProps) {
  const { recommendedTotalShiftSlot, totalAssignedShiftSlot } = props;

  function getRecommendedStatus(recommended: number, total: number) {
    if (recommended === null || total === null) {
      return null;
    } else if (recommended > total) {
      return 'under';
    } else if (recommended < total) {
      return 'over';
    } else {
      return 'recommended';
    }
  }

  //const recommendStatus: string = 'over';
  const recommendStatus: string = getRecommendedStatus(recommendedTotalShiftSlot, totalAssignedShiftSlot);

  let recommendMsg = null;

  switch (recommendStatus) {
    case 'recommended':
      recommendMsg = (
        <Text lineHeight={120} color="green-dark" size="small">
          You have the <b>optimal number</b> of team members assigned to this session.
        </Text>
      );
      break;
    case 'over':
      recommendMsg = (
        <Text lineHeight={120} color="red-dark" size="small">
          You seem to be <b>over-staffed</b> based on your assigned team members ({totalAssignedShiftSlot}).
        </Text>
      );
      break;
    case 'under':
      recommendMsg = (
        <Text lineHeight={120} color="orange-dark" size="small">
          You seem to be <b>under-staffed</b> based on your assigned team members ({totalAssignedShiftSlot}).
        </Text>
      );
      break;
  }

  return (
    <div
      className="p-medium rounded shadow-container bg-white flex-1 mb-large line-height-120 bordered-left"
      style={{ borderLeftWidth: '4px', borderLeftColor: '#0083FF' }}
    >
      <div>
        <Text size="regular" lineHeight={120} color="secondary">
          Recommended team members for this session
        </Text>
      </div>

      {/* Counter */}
      <div className="flex-row align-center mb-x-small">
        <RecommendStatusIcon recommendStatus={recommendStatus} />

        <Text size="x4-large" weight="bold" lineHeight={120}>
          {recommendedTotalShiftSlot}
        </Text>
      </div>

      <div>{recommendMsg}</div>
    </div>
  );
}
