import _ from 'lodash';
import { IPermissions } from 'interfaces/account-interfaces';
import {
  CustomerPermissionType,
  PaymentPermissionType,
  PlanManagementPermissionType,
  ServicePermissionType,
  TeamPermissionType,
  UserPermissionRole
} from 'utilities/enum-utils';

// quick wrapper for lodash
function permissionsLookup(permissions) {
  return (role) =>
    permissions && permissions.permissionRoles && _.some(permissions.permissionRoles, ['permissionRole', role]);
}

// Note from Jir : I don't like the way the following functions are written,  to be honest.
// It's a bit messy and unintuitive. To refactor later to remove these functions into maybe dictionary later on.

// Converts permission roles to user permission types
export function getPaymentPermissionName(permissions: IPermissions, hasAllAccess: boolean = false) {
  const permissionsContain = permissionsLookup(permissions);

  if (hasAllAccess) {
    return PaymentPermissionType.FULL_ACCESS;
  } else if (permissionsContain(UserPermissionRole.PaymentManager)) {
    return PaymentPermissionType.MANAGER;
  } else if (permissionsContain(UserPermissionRole.PaymentMember)) {
    return PaymentPermissionType.MEMBER;
  } else {
    return PaymentPermissionType.NO_ACCESS;
  }
}

// Converts permission types to user permission roles
export function getPaymentRole(permissionName: string) {
  if (permissionName === PaymentPermissionType.MANAGER) {
    return UserPermissionRole.PaymentManager;
  } else if (permissionName === PaymentPermissionType.MEMBER) {
    return UserPermissionRole.PaymentMember;
  } else {
    return null;
  }
}

// Converts team role to team permission type
export function getTeamPermissionName(permissions: IPermissions, hasAllAccess: boolean = false) {
  const permissionsContain = permissionsLookup(permissions);

  if (hasAllAccess) {
    return PaymentPermissionType.FULL_ACCESS;
  } else if (permissionsContain(UserPermissionRole.TeamManager)) {
    return TeamPermissionType.MANAGER;
  } else if (permissionsContain(UserPermissionRole.TeamViewOnly)) {
    return TeamPermissionType.VIEW_ONLY;
  } else {
    return TeamPermissionType.VIEW_ONLY;
  }
}

// Converts team permission to team roles
export function getTeamRole(permissionName: string) {
  if (permissionName === TeamPermissionType.MANAGER) {
    return UserPermissionRole.TeamManager;
  } else if (permissionName === TeamPermissionType.VIEW_ONLY) {
    return UserPermissionRole.TeamViewOnly;
  } else {
    return null;
  }
}

export function getCustomerPermissionName(permissions: IPermissions, hasAllAccess: boolean = false) {
  const permissionsContain = permissionsLookup(permissions);

  if (hasAllAccess) {
    return PaymentPermissionType.FULL_ACCESS;
  } else if (permissionsContain(UserPermissionRole.CustomerManager)) {
    return CustomerPermissionType.MANAGER;
  } else if (permissionsContain(UserPermissionRole.CustomerViewOnly)) {
    return CustomerPermissionType.VIEW_ONLY;
  } else {
    return CustomerPermissionType.VIEW_ONLY;
  }
}

export function getPlanManagementPermissionName(permissions: IPermissions, hasAllAccess: boolean = false) {
  const permissionsContain = permissionsLookup(permissions);

  if (hasAllAccess || permissionsContain(UserPermissionRole.PlanManager)) {
    return PlanManagementPermissionType.ACCESS;
  } else {
    return PlanManagementPermissionType.NO_ACCESS;
  }
}

export function getCustomerRole(permissionName: string) {
  if (permissionName === CustomerPermissionType.MANAGER) {
    return UserPermissionRole.CustomerManager;
  } else if (permissionName === CustomerPermissionType.VIEW_ONLY) {
    return UserPermissionRole.CustomerViewOnly;
  } else {
    return null;
  }
}

export function getPlanManagementRole(permissionName: string) {
  if (permissionName === PlanManagementPermissionType.ACCESS) {
    return UserPermissionRole.PlanManager;
  } else {
    return null;
  }
}

export function getServicePermission(permissionRole: UserPermissionRole, hasAllAccess: boolean = false) {
  if (hasAllAccess) {
    return PaymentPermissionType.FULL_ACCESS;
  } else if (permissionRole === UserPermissionRole.ServiceAdmin) {
    return ServicePermissionType.MANAGER;
  } else if (permissionRole === UserPermissionRole.ServiceMember) {
    return ServicePermissionType.MEMBER;
  } else {
    return ServicePermissionType.NO_ACCESS;
  }
}

export function getServiceRole(permissionName: string) {
  if (permissionName === ServicePermissionType.MANAGER) {
    return UserPermissionRole.ServiceAdmin;
  } else if (permissionName === ServicePermissionType.MEMBER) {
    return UserPermissionRole.ServiceMember;
    // } else if (permissionName === ServicePermissionType.CUSTOM) {
    //   return UserPermissionRole.ServiceMemberCustom
  } else {
    // No access
    return null;
  }
}
