import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from '../../../../common-components/modal/ActionModal';
import { ErrorSVG } from '../../../../assets/UndrawSVG';
import { Paragraph } from '../../../../common-components/typography';
import SpinningLoader from '../../../../common-components/loading/SpinningLoader';
import { GhostButton, PrimaryButton } from '../../../../common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';
import { parse } from 'json2csv';
import CommonUtils from 'utilities/common-utils';

const fileDownload = require('js-file-download');

interface IPayClaimedInvoicesActionModalProps {
  isOpen: any;
  onClose: any;
  deselect: any;
  planManagementInvoiceList: typeof state.planManagementStore.planManagementInvoiceList;
  selectedItem: typeof state.planManagementStore.selectedItem;
  doMarkInvoicesAsPaid: typeof dispatch.planManagementStore.doMarkInvoicesAsPaid;
  refreshListing: any;
  type?: any;
}

class PayClaimedInvoicesActionModal extends Component<IPayClaimedInvoicesActionModalProps, any> {
  state = {
    selectedInvoices: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    counts: {},
    title: 'Mark Invoices as paid'
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({
        isLoading: false,
        isSuccess: false,
        selectedInvoices: [],
        paymentsSelectedAll: false,
        isError: false
      });
      onClose();
    }
  };

  private _onSubmitProcess = async () => {
    const { doMarkInvoicesAsPaid, deselect, selectedItem } = this.props;

    this.setState({ isLoading: true });

    try {
      const invoiceIds = _.uniq(
        _.map(selectedItem.items, (item) => {
          return item.planManagementInvoiceId;
        })
      );
      const resp: any = await doMarkInvoicesAsPaid({ invoiceIds });
      deselect();
      await this.props.refreshListing();
      this.setState({ isLoading: false, counts: 0, isSuccess: true, title: 'Invoices successfully processed' });
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false, isError: true });
    }
  };

  render() {
    const { isOpen, selectedItem } = this.props;

    const numberOfInvoices = selectedItem.items.length;
    let numberOfLineItems = 0;
    if (selectedItem.numberOfLineItems) {
      numberOfLineItems = selectedItem.numberOfLineItems;
    } else {
      _.forEach(selectedItem.items, (lineItem) => {
        numberOfLineItems += Number(lineItem.numberOfItem);
      });
    }
    const numberOfCustomers = selectedItem.numberOfCustomers
      ? selectedItem.numberOfLineItems
      : _(selectedItem.items)
          .groupBy('customerUserId')
          .map((items, customerUserId) => ({ customerUserId }))
          .value().length;

    const payTotals = _.reduce(
      selectedItem.items,
      (sum, item) => {
        const total = item.invoiceTotal ? Number(item.invoiceTotal) : Number(item.total);
        return sum + total;
      },
      0
    );

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="medium"
        verticalAlignment="highest"
      >
        {!this.state.isLoading && !this.state.isSuccess && !this.state.isError && (
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>You are about to mark as paid:</Paragraph>
              <Paragraph>
                {numberOfInvoices} invoice{numberOfInvoices > 1 && 's'} for {numberOfCustomers} Customer
                {numberOfCustomers > 1 && 's'} for a total of {CommonUtils.formatPrice(payTotals)}
              </Paragraph>
              {this.props.type !== 'PAYMENT_ISSUES' && (
                <Paragraph>
                  Once an invoice has been marked as paid, its line items can no longer be rejected. Are you sure you
                  want to proceed?
                </Paragraph>
              )}
            </div>
          </div>
        )}
        {this.state.isLoading && <SpinningLoader size={100} message={'Loading'} />}
        {!this.state.isLoading && this.state.isSuccess && (
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>You have successfully paid invoices.</Paragraph>
            </div>
          </div>
        )}

        {!this.state.isSuccess && !this.state.isError && (
          <ActionModalFooter>
            <GhostButton
              size="large"
              onClick={this._onCloseModal}
              className="mr-medium"
              disabled={this.state.isLoading}
            >
              Close
            </GhostButton>
            <PrimaryButton size="large" onClick={this._onSubmitProcess} loading={this.state.isLoading}>
              Mark as paid
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {this.state.isSuccess && !this.state.isError && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {!this.state.isLoading && this.state.isError && (
          <div className="text-align-center">
            <div className="pv-medium">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium">
              <Paragraph>An error has occured.</Paragraph>
            </div>
          </div>
        )}

        {this.state.isError && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  paymentsList: state.billingsStore.paymentsList
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doMarkInvoicesAsPaid: dispatch.planManagementStore.doMarkInvoicesAsPaid
});

export default connect(
  mapState,
  mapDispatch
)(PayClaimedInvoicesActionModal);
