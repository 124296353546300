import React, { Component } from 'react';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';

import { IServiceListItemLite } from 'interfaces/service-interfaces';
import { ICustomer } from 'interfaces/customer-interfaces';

import CenteredLayout from 'layouts/CenteredLayout';
import { FieldLabel, FieldValueText, SubTitle, Text, Title } from 'common-components/typography';
import { Avatar, Col, Icon, notification, Row, Skeleton, Tabs, Tag } from 'antd';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';

import { ProgressBar } from '@blueprintjs/core';
import { RouteComponentProps } from 'react-router-dom';
import { FormComponentProps } from 'antd/es/form';
import moment from 'moment-timezone';
import { tagColors } from 'theme/theme-variables';

import CustomerDetailsFundingPanel from './tabs-panel/funding/CustomerDetailsFundingPanel';
import CustomerDetailsContactsPanel from './tabs-panel/contacts/CustomerDetailsContactsPanel';
import CustomerDetailsGeneralPanel from './tabs-panel/general/CustomerDetailsGeneralPanel';

import { parsePhoneNumberFromString } from 'libphonenumber-js';
import CommonUtils from 'utilities/common-utils';
import CustomerTag from '../../../common-components/tags/CustomerTag';
import ViewConnectionRequestsModal from 'views/customers/details/components/ViewConnectionRequestsModal';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';
import AddCustomerConnectionModal from './components/AddCustomerConnectionModal';
import CareInformationPanel from './tabs-panel/care-information';
import DocumentsPanel from 'common-components/documents/DocumentsPanel';
import CustomerDetailsBookingPanel from 'views/customers/details/tabs-panel/CustomerDetailsBookingPanel';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import PermissionUtils from 'utilities/permission-utils';
import CustomerDetailsNotesPanel from 'views/customers/details/tabs-panel/CustomerDetailsNotesPanel';
import NewServiceAgreementsPanel from 'views/customers/details/tabs-panel/new-service-agreements/NewServiceAgreementsPanel';
import ManageCustomerActionModel from 'views/customers/details/components/ManageCustomerActionModel';
import { CustomerStatusType, PlanManagerType } from 'utilities/enum-utils';
import CarerPreferencesPanel from 'views/customers/details/tabs-panel/carer-preferences/CarerPreferencesPanel';
import PinnedAlertItem from 'common-components/pinned-alerts/PinnedAlertItem';
import { Error } from 'common-components/alerts';
import CustomerDetailsLinkedAccountsPanel from './tabs-panel/linkaccounts-app/CustomerDetailsLinkedAccountsPanel';
import GuardianManagedAccountsPanel from './tabs-panel/managed-accounts/GuardianManagedAccountsPanel';

const { TabPane } = Tabs;

interface ICustomerUrlParams {
  userId: string;
}

interface ICustomerDetailsPanelProps
  extends FormComponentProps,
    RouteComponentProps<ICustomerUrlParams, {}, { selectedTab: any }> {
  toggleSidebar: () => void;
  showSidebar: boolean;
  doGetCustomer: typeof dispatch.customersStore.doGetCustomer;
  selectedCustomerUserId: string;
  selectedCustomer: ICustomer;
  doSubmitCustomerSetting: typeof dispatch.customersStore.doSubmitCustomerSetting;
  servicesLite: IServiceListItemLite[];
  doFetchServicesLite: typeof dispatch.servicesStore.doFetchServicesLite;
  doCreateUserServiceAgreement: typeof dispatch.customersStore.doCreateUserServiceAgreement;
  doUpdateUserServiceAgreement: typeof dispatch.customersStore.doUpdateUserServiceAgreement;
  doUpdateCustomerNDISInfo: typeof dispatch.customersStore.doUpdateCustomerNDISInfo;
  doUpdateCustomerProfile: typeof dispatch.customersStore.doUpdateCustomerProfile;
  setSelectedCustomerUserId?: typeof dispatch.customersStore.setSelectedCustomerUserId;
  portalUser: typeof state.authStore.portalUser;
  customerDocuments: typeof state.customersStore.customerDocuments;
  doFetchCustomerDocuments: typeof dispatch.customersStore.doFetchCustomerDocuments;
  doFetchMoreDocuments: typeof dispatch.customersStore.doFetchMoreDocuments;
  setCustomerDocuments: typeof dispatch.customersStore.setCustomerDocuments;
  doDownloadDocument: typeof dispatch.customersStore.doDownloadDocument;
  doAcceptEnquiry: typeof dispatch.customersStore.doAcceptEnquiry;
  resetUserServiceAgreementBillingLineItems: typeof dispatch.customersStore.resetUserServiceAgreementBillingLineItems;
  doAddCustomerNotes: typeof dispatch.customersStore.doAddCustomerNotes;
  doEditCustomerNote: typeof dispatch.customersStore.doEditCustomerNote;
  doFetchBooking: typeof dispatch.bookingsStore.doFetchSingleBooking;
  doFetchDisabilityList: typeof dispatch.customersStore.doFetchDisabilityList;
  doFetchCompanyLite: typeof dispatch.companyStore.doFetchCompanyLite;
  companyDataLite: typeof state.companyStore.companyDataLite;
}

interface ICustomerDetailsPanelState {
  isLoading: boolean;
  selectedTab: any;
  isAgreementEdit: boolean;
  isOpenConnectUser: boolean;
  openSendConnectionModel: boolean;
  isAcceptEnquiryOpen: boolean;
  isAcceptingEnquiry: boolean;
  isCustomerManageOpen: boolean;
  disabilities: any;
}

class CustomerDetailsPanel extends Component<ICustomerDetailsPanelProps, ICustomerDetailsPanelState> {
  state = {
    selectedTab:
      this.props.location && this.props.location.state && this.props.location.state.selectedTab
        ? this.props.location.state.selectedTab
        : 'generalinfo',

    isLoading: false,
    isAgreementEdit: false,
    isOpenConnectUser: false,
    openSendConnectionModel: false,
    isAcceptEnquiryOpen: false,
    isAcceptingEnquiry: false,
    isCustomerManageOpen: false,
    disabilities: null
  };

  private _addFundingPackage = () => {
    this.setState({ selectedTab: 'fundings' });
  };

  private _setSelectedTab = (tab) => {
    this.setState({ selectedTab: tab });
  };

  private _renderDisability = () => {
    const { selectedCustomer } = this.props;
    const { disabilities } = this.state;

    const list = _.map(selectedCustomer.disabilities, (tag) => {
      return disabilities.find((item) => item.tagId === tag);
    });

    return (
      <div>
        {_.map(list, (item) => {
          return (
            <Tag color={tagColors.BLUE} className={'mb-small'} key={item && item.tagId}>
              <Text color="white" weight="bold" size="large">
                {item ? item.disabilityName : 'Not found'}
              </Text>
            </Tag>
          );
        })}
      </div>
    );
  };

  private _openConnectionManager = (connection) => {
    if (connection === 'NOT_CONNECTED') {
      this.setState({ openSendConnectionModel: true });
    } else {
      this.setState({ isOpenConnectUser: true });
    }
  };

  private _renderParentGuardianBadge = () => {
    const { selectedCustomer } = this.props;
    const { isDependent } = selectedCustomer;
    return isDependent && <CustomerTag type="HAS_GUARDIANS" className={'mr-small'} />;
  };

  private _renderConnectionBadge = (hasEditPermission) => {
    const { selectedCustomer } = this.props;
    const { connected } = selectedCustomer;
    return (
      <CustomerTag
        type={connected}
        // showConnectButton={
        //   hasEditPermission &&
        //   (selectedCustomer.isDependent || selectedCustomer.isIndependent) &&
        //   selectedCustomer.customerStatus !== CustomerStatusType.ARCHIVED
        // }
        // onClickButton={() => this._openConnectionManager(connected)}
      />
    );
  };

  private _onRefresh = async () => {
    const { doGetCustomer, match } = this.props;
    const { params } = match;
    const selectedCustomerId = params.userId;
    await doGetCustomer({ userId: selectedCustomerId });
  };

  async componentDidMount() {
    const {
      doGetCustomer,
      match,
      setSelectedCustomerUserId,
      resetUserServiceAgreementBillingLineItems,
      doFetchDisabilityList,
      doFetchCompanyLite
    } = this.props;
    const { params } = match;
    const selectedCustomerId = params.userId;

    setSelectedCustomerUserId(selectedCustomerId);

    this.setState({ isLoading: true });

    const disabilities: any = await doFetchDisabilityList({});
    this.setState({ disabilities });

    // fetch customer
    await doGetCustomer({ userId: selectedCustomerId });

    await doFetchCompanyLite();

    resetUserServiceAgreementBillingLineItems({});

    this.setState({ isLoading: false });
  }

  async componentDidUpdate(prevProps, prevState, snapshot?) {
    const { match, doGetCustomer, resetUserServiceAgreementBillingLineItems } = this.props;
    const previousMatch = prevProps.match;
    if (previousMatch) {
      const { params } = match;
      const previousParams = previousMatch.params;
      const selectedCustomerId = params.userId;
      const previousSelectedId = previousParams.userId;

      /**
       * This will check if there is an existing selected tab from the history.
       * (ie: when user select a notification it will be redirected to the set selectedTab or when user click on alerts view details.)
       */
      if (
        this.state.selectedTab === prevState.selectedTab &&
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.selectedTab !== this.state.selectedTab
      ) {
        this.setState({ selectedTab: this.props.location.state.selectedTab });
      }

      if (selectedCustomerId !== previousSelectedId) {
        this.setState({ isLoading: true });

        await doGetCustomer({ userId: selectedCustomerId });

        resetUserServiceAgreementBillingLineItems({});

        this.setState({ isLoading: false, selectedTab: 'generalinfo' });
      }
    }
  }

  private _onCloseConnectUser = () => {
    this.setState({ isOpenConnectUser: false });
  };

  private _onCloseAcceptEnquiryModal = () => {
    this.setState({ isAcceptEnquiryOpen: false });
  };

  private _onOpenCustomerManageModal = () => {
    this.setState({ isCustomerManageOpen: true });
  };

  private _onCloseCustomerManageModal = () => {
    this.setState({ isCustomerManageOpen: false });
  };

  private _acceptEnquiry = async () => {
    try {
      this.setState({ isAcceptingEnquiry: true });
      await this.props.doAcceptEnquiry({ customerUserId: this.props.selectedCustomer.userId });
      notification.success({ message: 'Enquiry successfully accepted!' });
      this._onCloseAcceptEnquiryModal();
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isAcceptingEnquiry: false });
  };

  render() {
    if (this.state.isLoading) {
      return (
        <CenteredLayout backgroundColor="white" bordered>
          <div className="item-container">
            <div className="pb-medium">
              <Text>Fetching customer details...</Text>
            </div>
            <ProgressBar />
          </div>
          <Skeleton loading={this.state.isLoading} active avatar title paragraph />
        </CenteredLayout>
      );
    }

    const { selectedCustomer, portalUser, history, location, match } = this.props;
    let dateDiff = 0;
    let diffText;
    let activePackage;
    let upcomingPackage;
    let totalBudget;
    let primaryCarer;
    let primaryAddress;
    let age;

    if (!_.isEmpty(selectedCustomer)) {
      primaryCarer = _.find(selectedCustomer.emergencyContacts, (contact) => {
        return contact.isPrimaryCarer;
      });
      primaryAddress = _.find(selectedCustomer.addresses, (address) => {
        return address.isPrimary === true;
      });

      age = selectedCustomer.dateOfBirth ? moment().diff(moment(selectedCustomer.dateOfBirth), 'years', false) : null;

      if (selectedCustomer.funding.NDISFundingPackages.length > 0) {
        let exppackages = selectedCustomer.funding.NDISFundingPackages.sort((a, b) =>
          a.expiredDate
            .split('-')
            .join()
            .localeCompare(b.expiredDate.split('-').join())
        );

        if (exppackages.length > 0) {
          activePackage = _.find(exppackages, (exppackage) => {
            const cur = moment().startOf('day');
            return cur.isBefore(exppackage.expiredDate) && cur.isSameOrAfter(exppackage.startDate);
          });

          if (activePackage) {
            const duration = CommonUtils.formatDuration(moment(), moment(activePackage.expiredDate));

            if (duration.years) {
              dateDiff = duration.years.value;
              diffText = duration.years.label;
            } else if (duration.months) {
              dateDiff = duration.months.value;
              diffText = duration.months.label;
            } else if (duration.days) {
              dateDiff = duration.days.value;
              diffText = duration.days.label;
            }

            totalBudget = _.reduce(
              activePackage.fundingNDISSupportCategories,
              (acc, category) => {
                acc = acc + Number(category.funding);
                return acc;
              },
              0
            ).toFixed(2);
          } else {
            upcomingPackage = _.find(exppackages, (exppackage) => {
              const cur = moment().startOf('day');
              return cur.isBefore(exppackage.startDate);
            });

            if (upcomingPackage) {
              const duration = CommonUtils.formatDuration(moment(), moment(upcomingPackage.startDate));

              if (duration.years) {
                dateDiff = duration.years.value;
                diffText = duration.years.label;
              } else if (duration.months) {
                dateDiff = duration.months.value;
                diffText = duration.months.label;
              } else if (duration.days) {
                dateDiff = duration.days.value;
                diffText = duration.days.label;
              }

              totalBudget = _.reduce(
                upcomingPackage.fundingNDISSupportCategories,
                (acc, category) => {
                  acc = acc + Number(category.funding);
                  return acc;
                },
                0
              ).toFixed(2);
            }
          }
        }
      }
    }

    const hasEditPermission = PermissionUtils.validatePermission(
      'EditCustomerProfile',
      portalUser.permissions.permissionRoles
    );

    const isNotGuardian = () => {
      return selectedCustomer.isDependent || selectedCustomer.isIndependent;
    };
    return (
      <CenteredLayout backgroundColor="white" bordered>
        <ViewConnectionRequestsModal isOpen={this.state.isOpenConnectUser} onClose={this._onCloseConnectUser} />

        {!_.isEmpty(selectedCustomer) ? (
          <div className="mb-x7-large" id="scroll">
            {selectedCustomer.customerStatus === CustomerStatusType.ENQUIRY && (
              <ActionModal
                isOpen={this.state.isAcceptEnquiryOpen}
                onClose={this._onCloseAcceptEnquiryModal}
                title={'Accept customer enquiry.'}
                showCloseButton={true}
              >
                {this.state.isAcceptingEnquiry ? (
                  <SpinningLoader size={100} message={'Accepting this enquiry...'} />
                ) : (
                  <>
                    <Text className={'mb-medium'}>
                      You are about to accept customer enquiry. Doing so will add this customer to your CRM and enable
                      some customer functions. Please only do so if this has been agreed upon with the customer.
                    </Text>
                    <ActionModalFooter>
                      <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseAcceptEnquiryModal}>
                        Cancel
                      </SecondaryButton>
                      <PrimaryButton size="large" onClick={this._acceptEnquiry}>
                        Accept Enquiry
                      </PrimaryButton>
                    </ActionModalFooter>
                  </>
                )}
              </ActionModal>
            )}
            <ManageCustomerActionModel
              isOpen={this.state.isCustomerManageOpen}
              onClose={this._onCloseCustomerManageModal}
            />
            <Row>
              <div className="mb-medium">
                <HyperlinkButton onClick={() => this.props.history.push('/customers')}>
                  <Icon type="left" /> Customer List
                </HyperlinkButton>
              </div>
            </Row>
            <Title level={2}>Customer Details</Title>

            <div className="mv-x-large  align-center">
              <Row>
                <Col span={3} className="text-align-right mr-large">
                  <Avatar
                    icon={'user'}
                    shape={'circle'}
                    // className="avatar-x3-large"
                    size={100}
                    src={selectedCustomer.attachmentUrl}
                  />
                </Col>
                <Col span={20}>
                  <div className="text-align-left pr-medium">
                    <Row>
                      <Col>
                        <Title level={3} color="blue-action" className={'align-center flex mb-x-small'}>
                          {`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}
                        </Title>
                        {!selectedCustomer.isDependent && !selectedCustomer.isIndependent && (
                          <div className={'flex mb-x-small'}>
                            <Text>Parent/Guardian</Text>
                          </div>
                        )}
                        <CustomerTag
                          type={selectedCustomer.customerStatus}
                          showEnquiryButton={hasEditPermission}
                          className="mr-small mb-small"
                          onClickButton={this._onOpenCustomerManageModal}
                        />
                        {this._renderParentGuardianBadge()}
                        {!_.isEmpty(selectedCustomer.connected) && this._renderConnectionBadge(hasEditPermission)}
                        {selectedCustomer &&
                          selectedCustomer.customerStatus &&
                          selectedCustomer.customerStatus === CustomerStatusType.ARCHIVED && (
                            <Error
                              content={
                                <>
                                  This customer was archived on{' '}
                                  <b>
                                    {moment.tz(selectedCustomer.archivedOn, portalUser.timezone).format('DD/MM/YYYY')}
                                  </b>
                                </>
                              }
                              displayType="block"
                              className="mb-small mt-small"
                            />
                          )}
                        <PinnedAlertItem
                          customerUserId={selectedCustomer.userId}
                          pinnedAlertNumber={selectedCustomer.pinnedAlertNumber}
                        />
                      </Col>
                    </Row>
                    <Row className={'mt-medium'}>
                      {primaryCarer === undefined ? (
                        <Col span={12}>
                          <SubTitle>Contact Details</SubTitle>
                          <Row type={'flex'}>
                            <Col span={1}>
                              <Icon type={'phone'} />
                            </Col>
                            <Col span={11} className={'mr-x-small'}>
                              <FieldValueText
                                text={
                                  selectedCustomer.mobile
                                    ? parsePhoneNumberFromString(selectedCustomer.mobile, 'AU').format('INTERNATIONAL')
                                    : 'Not Set'
                                }
                              />
                            </Col>
                          </Row>
                          <Row type={'flex'}>
                            <Col span={1}>
                              <Icon type={'mail'} />
                            </Col>
                            <Col span={11} className={'mr-x-small'}>
                              <FieldValueText text={selectedCustomer.email ? selectedCustomer.email : 'Not Set'} />
                            </Col>
                          </Row>
                        </Col>
                      ) : (
                        <Col span={12}>
                          <SubTitle>Primary Carer</SubTitle>
                          <Row>
                            <Col>
                              <FieldValueText
                                text={
                                  primaryCarer.fullName
                                    ? primaryCarer.fullName + ' (' + primaryCarer.relationToUser + ')'
                                    : null
                                }
                              />
                            </Col>
                          </Row>
                          <Row type={'flex'}>
                            <Col span={1} className={'mr-x-small'}>
                              <Icon type={'phone'} />
                            </Col>
                            <Col span={11}>
                              <FieldValueText text={primaryCarer.phoneNumber} />
                            </Col>
                          </Row>
                          <Row type={'flex'}>
                            <Col span={1} className={'mr-x-small'}>
                              <Icon type={'mail'} />
                            </Col>
                            <Col span={11}>
                              <FieldValueText text={primaryCarer.email ? primaryCarer.email : 'Not Set'} />
                            </Col>
                          </Row>
                        </Col>
                      )}
                      <Col span={12}>
                        <SubTitle>Funding</SubTitle>
                        {selectedCustomer.funding && selectedCustomer.funding.NDISNumber && (
                          <>
                            <Text>{selectedCustomer.funding.NDISNumber}</Text>{' '}
                            <Text color={'tertiary'}>(NDIS number)</Text>
                          </>
                        )}
                        {activePackage && dateDiff !== 0 ? (
                          <div>
                            <Text>
                              <b>{activePackage.agency}</b> plan of <b>{CommonUtils.formatPrice(totalBudget)}</b>{' '}
                              expiring in <b>{dateDiff + ' ' + diffText}</b>
                            </Text>
                          </div>
                        ) : upcomingPackage && dateDiff !== 0 ? (
                          <div>
                            <Text>
                              <b>{upcomingPackage.agency}</b> plan of <b>{CommonUtils.formatPrice(totalBudget)}</b>{' '}
                              starting in <b>{dateDiff + ' ' + diffText}</b>
                            </Text>
                          </div>
                        ) : (
                          <div>
                            <Text>
                              <Icon type="close" className="mr-x-small" style={{ color: '#cc5657' }} />
                              <b>No NDIS funding set</b>{' '}
                              {hasEditPermission && <GhostButton onClick={this._addFundingPackage}>+ Add</GhostButton>}
                            </Text>
                          </div>
                        )}
                      </Col>
                    </Row>
                    {selectedCustomer.funding.isPlanManager ? (
                      <Row className={'mt-medium'}>
                        <Col span={12}>
                          {selectedCustomer.funding.planManagerType === PlanManagerType.INTERNAL ? (
                            <>
                              <FieldLabel text={'Plan Manager'} />
                              <FieldValueText text={'Internally managed'} />
                            </>
                          ) : (
                            <>
                              {selectedCustomer.funding.isPlanManager ? <FieldLabel text={'Plan Manager'} /> : null}
                              <div>
                                {selectedCustomer.funding.isPlanManager ? (
                                  <FieldValueText text={selectedCustomer.funding.planManagerName} />
                                ) : null}
                              </div>
                              {selectedCustomer.funding.planManagerMobileNumber &&
                              selectedCustomer.funding.planManagerMobileNumber !== '' ? (
                                <Row type={'flex'}>
                                  <Col span={1} className={'mr-x-small'}>
                                    <Icon type={'phone'} />
                                  </Col>
                                  <Col span={11}>
                                    <FieldValueText
                                      text={parsePhoneNumberFromString(
                                        selectedCustomer.funding.planManagerMobileNumber,
                                        'AU'
                                      ).format('INTERNATIONAL')}
                                    />
                                  </Col>
                                </Row>
                              ) : null}
                              {selectedCustomer.funding.planManagerEmail &&
                              selectedCustomer.funding.planManagerEmail !== '' ? (
                                <Row type={'flex'}>
                                  <Col span={1} className={'mr-x-small'}>
                                    <Icon type={'mail'} />
                                  </Col>
                                  <Col span={11}>
                                    <FieldValueText text={selectedCustomer.funding.planManagerEmail} />
                                  </Col>
                                </Row>
                              ) : null}
                            </>
                          )}
                        </Col>
                      </Row>
                    ) : null}
                    <Row className={'mt-medium'}>
                      <SubTitle>Permanent conditions</SubTitle>
                      <div>
                        {this.state.disabilities && selectedCustomer.disabilities.length > 0
                          ? this._renderDisability()
                          : 'Not Set'}
                      </div>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row className={'mt-medium'}>
                <Col>
                  <Tabs
                    type="card"
                    className={'customer-tab mb-x-large'}
                    activeKey={this.state.selectedTab}
                    onChange={this._setSelectedTab}
                  >
                    <TabPane key="generalinfo" tab="General Information">
                      <CustomerDetailsGeneralPanel
                        history={history}
                        selectedCustomer={selectedCustomer}
                        hasEditPermission={hasEditPermission}
                        onRefresh={this._onRefresh}
                      />
                    </TabPane>
                    {!selectedCustomer.isDependent && !selectedCustomer.isIndependent && (
                      <TabPane key="managed-accounts" tab="Managed accounts">
                        <GuardianManagedAccountsPanel selectedCustomer={selectedCustomer} history={history} />
                      </TabPane>
                    )}
                    {!(!selectedCustomer.isDependent && !selectedCustomer.isIndependent) && (
                      <TabPane key="linkedaccounts-app" tab="Linked Accounts + App">
                        <CustomerDetailsLinkedAccountsPanel selectedCustomer={selectedCustomer} history={history} />
                      </TabPane>
                    )}

                    {isNotGuardian() && (
                      <TabPane key="contacts" tab="Contacts">
                        <CustomerDetailsContactsPanel
                          selectedCustomer={selectedCustomer}
                          history={history}
                          openConnectionManager={this._openConnectionManager}
                          hasEditPermission={hasEditPermission}
                        />
                      </TabPane>
                    )}
                    {isNotGuardian() && (
                      <TabPane key="fundings" tab="Funding">
                        <CustomerDetailsFundingPanel
                          portalUser={portalUser}
                          selectedCustomer={selectedCustomer}
                          hasEditPermission={hasEditPermission}
                          history={history}
                        />
                      </TabPane>
                    )}
                    {isNotGuardian() && (
                      <TabPane key="new_service_agreements" tab="Service Agreement(s)">
                        <NewServiceAgreementsPanel history={history} hasEditPermission={hasEditPermission} />
                      </TabPane>
                    )}

                    {isNotGuardian() && (
                      <TabPane key="documents" tab="Documents">
                        <DocumentsPanel
                          selectedItemId={selectedCustomer.userId}
                          itemDocuments={this.props.customerDocuments}
                          doFetchDocuments={this.props.doFetchCustomerDocuments}
                          doFetchMoreDocuments={this.props.doFetchMoreDocuments}
                          setDocuments={this.props.setCustomerDocuments}
                          doDownloadDocument={this.props.doDownloadDocument}
                          hasEditPermission={hasEditPermission}
                          itemType="customer"
                        />
                      </TabPane>
                    )}
                    {isNotGuardian() && (
                      <TabPane key="notes" tab="Notes">
                        <CustomerDetailsNotesPanel history={history} />
                      </TabPane>
                    )}
                    {isNotGuardian() && (
                      <TabPane key="careInformation" tab="Care Information">
                        <CareInformationPanel
                          selectedCustomer={selectedCustomer}
                          hasEditPermission={hasEditPermission}
                        />
                      </TabPane>
                    )}
                    {isNotGuardian() && (
                      <TabPane key="carer_preferences" tab="Carer preferences">
                        <CarerPreferencesPanel
                          selectedCustomer={selectedCustomer}
                          hasEditPermission={hasEditPermission}
                        />
                      </TabPane>
                    )}
                    {isNotGuardian() &&
                      PermissionUtils.validatePermission(
                        'ViewBookingListing',
                        portalUser.permissions.permissionRoles
                      ) && (
                        <TabPane key="bookings" tab="Bookings">
                          <CustomerDetailsBookingPanel
                            selectedCustomer={selectedCustomer}
                            onRefresh={this._onRefresh}
                            history={history}
                            location={location}
                            match={match}
                          />
                        </TabPane>
                      )}
                  </Tabs>
                </Col>
              </Row>
            </div>
          </div>
        ) : null}
        <FullScreenScrollableModal
          isOpen={this.state.openSendConnectionModel}
          onClose={() => this.setState({ openSendConnectionModel: false })}
          width={'full'}
          bgColor="tertiary"
          canCloseEsc={false}
          fixedHeight={false}
          noMarginBottom={true}
          hasFooter={false}
          headerTitle={
            <Title level={2} className="m-none">
              Connect your record to a Customer’s own record via the GoodHuman app
            </Title>
          }
        >
          <AddCustomerConnectionModal onPressConnectLater={() => this.setState({ openSendConnectionModel: false })} />
        </FullScreenScrollableModal>
      </CenteredLayout>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedCustomer: state.customersStore.selectedCustomer,
  selectedCustomerUserId: state.customersStore.selectedCustomerUserId,
  servicesLite: state.servicesStore.servicesLite,
  portalUser: state.authStore.portalUser,
  customerDocuments: state.customersStore.customerDocuments
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doGetCustomer: dispatch.customersStore.doGetCustomer,
  doSubmitCustomerSetting: dispatch.customersStore.doSubmitCustomerSetting,
  doFetchServicesLite: dispatch.servicesStore.doFetchServicesLite,
  doCreateUserServiceAgreement: dispatch.customersStore.doCreateUserServiceAgreement,
  doUpdateUserServiceAgreement: dispatch.customersStore.doUpdateUserServiceAgreement,
  doUpdateCustomerNDISInfo: dispatch.customersStore.doUpdateCustomerNDISInfo,
  doUpdateCustomerProfile: dispatch.customersStore.doUpdateCustomerProfile,
  doFetchCustomerDocuments: dispatch.customersStore.doFetchCustomerDocuments,
  doFetchMoreDocuments: dispatch.customersStore.doFetchMoreDocuments,
  setCustomerDocuments: dispatch.customersStore.setCustomerDocuments,
  doDownloadDocument: dispatch.customersStore.doDownloadDocument,
  doAddCustomerNotes: dispatch.customersStore.doAddCustomerNotes,
  doFetchBooking: dispatch.bookingsStore.doFetchSingleBooking,
  doEditCustomerNote: dispatch.customersStore.doEditCustomerNote,
  doAcceptEnquiry: dispatch.customersStore.doAcceptEnquiry,
  doFetchDisabilityList: dispatch.customersStore.doFetchDisabilityList,
  resetUserServiceAgreementBillingLineItems: dispatch.customersStore.resetUserServiceAgreementBillingLineItems,
  setSelectedCustomerUserId: (customerUserId: string) =>
    dispatch.customersStore.setSelectedCustomerUserId({ customerUserId }),
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite
});

export default connect(mapState, mapDispatch)(CustomerDetailsPanel);
