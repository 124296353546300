import React, { PureComponent } from 'react';
import { Text } from 'common-components/typography';
import { PlanManagementClaimStatus, PlanManagementClaimStatusDisplay } from 'utilities/enum-utils';

interface PlanManagementClaimTagProps {
  status: PlanManagementClaimStatus;
  size?: 'small' | 'default' | 'large';
  textSize?: string;
  className?: any;
  textClassName?: any;
  rounded?: boolean;
}

export default class PlanManagementClaimTag extends PureComponent<PlanManagementClaimTagProps, any> {
  BgStatusColors = {
    UNCLAIMED: 'tertiary',
    CANCELLED: 'red-lightest',
    WAIVED: 'indigo-lightest',
    REJECTED: 'red-lightest',
    CLAIMED: 'green-lightest',
    SENT_TO_PAYMENTS: 'orange-lightest',
    PARTIALLY_REJECTED: 'red-lightest',
    PARTIALLY_SENT_TO_PAYMENTS: 'orange-lightest',
    PARTIALLY_CLAIMED: 'green-lightest',
    READY_TO_BE_PAID: 'blue-lightest',
    PARTIALLY_UNCLAIMED: 'gray-lightest'
  };

  TextStatusColors = {
    UNCLAIMED: 'gray',
    CANCELLED: 'red-dark',
    WAIVED: 'indigo',
    REJECTED: 'dark-red',
    CLAIMED: 'green',
    SENT_TO_PAYMENTS: 'orange',
    PARTIALLY_REJECTED: 'red-dark',
    PARTIALLY_SENT_TO_PAYMENTS: 'orange',
    PARTIALLY_CLAIMED: 'green',
    READY_TO_BE_PAID: 'blue',
    PARTIALLY_UNCLAIMED: 'gray'
  };

  render() {
    const { status, className, textClassName, textSize = 'large', size = 'default', rounded = true } = this.props;

    const bgColor = this.BgStatusColors[status];
    const textColor = this.TextStatusColors[status];
    const roundedClass = rounded ? 'rounded' : '';

    let sizeClasses = '';

    if (size === 'default') {
      sizeClasses = 'pv-x-small ph-small text-size-regular ';
    } else if (size === 'large') {
      sizeClasses = 'pv-small ph-large text-size-large ';
    } else if (size === 'small') {
      sizeClasses = 'pv-x3-small ph-x-small text-size-small ';
    }

    const containerClassNames = `text-align-center ${sizeClasses} inline-block ${roundedClass} bg-${bgColor} ${className}`;
    const textClassNames = `text-color-${textColor} ${textClassName}`;

    return (
      <div className={containerClassNames}>
        <Text size={textSize} className={textClassNames}>
          {PlanManagementClaimStatusDisplay[status]}
        </Text>
      </div>
    );
  }
}
