import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { BookingSVG, ConfirmedSVG, ErrorSVG } from 'assets/UndrawSVG';
import { Paragraph } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { Spinner } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';

class BookingAcceptActionModel extends Component<any, any> {
  state = { step: 1, canManuallyClose: true, title: 'Accept Booking Request' };

  renderView = () => {
    const { step } = this.state;

    if (step === 1) {
      return (
        <div className="anim-fade-in" style={{ height: '100%' }}>
          <div className="text-align-left pt-small">
            <Paragraph>Are you sure you want to accept this booking request from {this.props.userName}?</Paragraph>
            <Paragraph>You should only accept booking requests which you have capacity to service.</Paragraph>
          </div>
          <div className="justify-end align-end">
            <ActionModalFooter>
              <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal}>
                Not Now
              </GhostButton>
              <PrimaryButton size="large" icon="check" onClick={this.onAccept}>
                Accept Booking
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <div className="text-align-center">
            <div className="pv-large">
              <Spinner size={150} />
            </div>

            <Paragraph>Accepting this booking, won't be long...</Paragraph>
          </div>
        </div>
      );
    }

    if (step === 3) {
      return (
        <div className="anim-fade-in">
          <div className="text-align-left">
            <Paragraph>You have successfully accepted this booking.</Paragraph>
            <Paragraph>The customer has been notified that their request has been accepted.</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 4) {
      return (
        <div className="anim-fade-in">
          <div className="pv-medium">
            <img src={ErrorSVG} alt="ERROR" style={{ width: '100%', height: '200px' }} />
          </div>
          <div className="text-align-center">
            <Paragraph>Oops something has gone wrong, please try again</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Go Back to Booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  onAccept = async () => {
    const { doAcceptBooking, selectedBooking } = this.props;
    this.setState({ step: 2, canManuallyClose: false });
    try {
      await doAcceptBooking({ bookingId: selectedBooking.bookingId });
      this.onComplete();
    } catch (e) {
      this.onError();
    }
  };

  onComplete = () => {
    this.setState({ step: 3, canManuallyClose: true, title: 'Booking Successfully accepted' });
  };

  onError = () => {
    this.setState({ step: 4, canManuallyClose: true });
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    // Reset back to step 1
    this.setState({ step: 1, title: 'Accept Booking Request' }, onClose);
  };

  render() {
    let { isOpen } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div className="flex-column">{this.renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBooking: state.bookingsStore.selectedBookingItem
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doAcceptBooking: dispatch.bookingsStore.doAcceptBooking
});

export default connect(
  mapState,
  mapDispatch
)(BookingAcceptActionModel);
