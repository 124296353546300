import { Title } from 'common-components/typography';
import React, { Component } from 'react';
import { IAssignedService } from 'interfaces/message-interfaces';
import { InboxItem } from 'views/messaging/inbox/components/InboxItem';
import { RevealContainer } from 'views/messaging/inbox/components/RevealContainer';

interface IInboxPanelProps {
  services: IAssignedService[];
  onClickService: any;
  currentServiceId: string;
}

interface IInboxPanelState {}

export class InboxPanel extends Component<IInboxPanelProps, IInboxPanelState> {
  onClickService = ({ serviceId }) => {
    // Do we really need this local function...?
    const { onClickService } = this.props;
    onClickService({ serviceId });
  };

  render() {
    const { services, currentServiceId } = this.props;

    return (
      <div className="bg-light-gray-lighter flex-column" style={{ width: '240px', overflowY: 'auto' }}>
        {/* -•=== Header ===•- */}
        <div className="pv-medium ph-medium flex-row justify-between align-center" style={{ height: '72px' }}>
          <div className="flex-row align-baseline">
            <Title level={3} className="mv-none" lineHeight={150}>
              Inbox
            </Title>

            {/* TODO - Services Settings */}
            {/*<div className="ml-small">*/}
            {/*  <Icon type="setting" className="text-size-regular" />*/}
            {/*</div>*/}
          </div>

          {/* TODO : Hide Inbox  */}
          {/*<Icon type="double-left" />*/}
        </div>
        {/* END HEADER */}

        {/*<div className="pv-medium ph-medium flex-row justify-center">*/}
        {/*  <SecondaryButton color="blue-dark" shape="round" icon="plus">*/}
        {/*    New Conversation*/}
        {/*  </SecondaryButton>*/}
        {/*</div>*/}

        {/* -•=== Services ===•- */}
        <div className="pv-medium">
          <RevealContainer title={'SERVICES'}>
            {services &&
              services.map((service) => (
                <InboxItem
                  isSelected={service.serviceId === currentServiceId}
                  text={`${service.serviceName}`}
                  // TODO : handle service counts in Inbox
                  // count={service.sum}
                  count={0}
                  onClick={() => this.onClickService({ serviceId: service.serviceId })}
                  key={service.serviceId}
                />
              ))}
          </RevealContainer>
        </div>

        {/* Commented - this should be team inboxes later on. */}

        {/* -•=== Team Inboxes ===•- */}

        {/*<div className="pb-large">*/}
        {/*  <RevealContainer title="MY TEAM INBOXES">*/}
        {/*    <InboxItem text={'# Youth Jobs Now! team'} />*/}
        {/*    <InboxItem text={'# The Bridge Dandenong'} />*/}
        {/*  </RevealContainer>*/}
        {/*</div>*/}

        {/* -•=== Team members ===•- */}

        {/*<div className="pb-large">*/}
        {/*  <RevealContainer title={'TEAM MEMBERS'}>*/}
        {/*    <UserItem userName="Tom Morello" />*/}
        {/*    <UserItem userName="Richard Rider" />*/}
        {/*    <UserItem userName="Zak De La Rocha" />*/}
        {/*  </RevealContainer>*/}
        {/*</div>*/}
      </div>
    );
  }
}
