import React, { Component } from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';
import { Spinner } from '@blueprintjs/core';

import { IShiftSlot } from 'interfaces/shift-interfaces';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';

import { GroupServiceSessionStatus } from 'utilities/enum-utils';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { FieldLabel, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';

import moment from 'moment-timezone';
import { ISession } from 'interfaces/session-interfaces';

interface IApproveSlotModalProps {
  isOpen: boolean;
  shiftSlot: IShiftSlot;
  onClose: (targetFlag, refreshShiftSlots?: boolean) => void;
  doApproveShift: typeof dispatch.groupServiceStore.doApproveShift;
  session: ISession;
}

interface IApproveSlotModalState {
  step: number;
  canManuallyClose: boolean;
  refreshShiftSlots: boolean;
}

class ApproveSlotModal extends Component<IApproveSlotModalProps, IApproveSlotModalState> {
  state = {
    step: this.props.session && this.props.session.sessionStatus === GroupServiceSessionStatus.COMPLETED ? 1 : 4,
    canManuallyClose: true,
    refreshShiftSlots: false
  };

  private _reset = () => {
    this.setState({
      step: this.props.session && this.props.session.sessionStatus === GroupServiceSessionStatus.COMPLETED ? 1 : 4,
      refreshShiftSlots: false
    });
  };

  private _onSave = async () => {
    const { doApproveShift, session, shiftSlot } = this.props;

    this.setState({ step: 2, canManuallyClose: false });

    const request = {
      serviceId: session.serviceId,
      serviceDateTimeId: session.serviceDateTimeId,
      supportWorkerAttendanceId: shiftSlot.supportWorkerAttendanceId
    };

    await doApproveShift(request);

    this.setState({ step: 3, canManuallyClose: true, refreshShiftSlots: true });
  };

  private _onClose = () => {
    const { onClose } = this.props;
    onClose({ targetFlag: 'isApproveShiftOpen' }, this.state.refreshShiftSlots);
    this._reset();
  };

  componentDidUpdate = async (_prevProps, prevState) => {
    const newStep = 1;
    if (prevState.step !== newStep && prevState.step !== 2 && prevState.step !== 3) {
      this.setState({
        step: newStep
      });
    }
  };

  render() {
    const { shiftSlot, session } = this.props;

    if (_.isEmpty(shiftSlot)) {
      return <></>;
    }

    const { shiftStartDateTime, shiftEndDateTime } = shiftSlot;

    const shiftDatesWithoutTimezone = {
      shiftStartDateTime: moment(moment.tz(shiftStartDateTime, session.timezone).format('YYYY-MM-DD HH:mm')),
      shiftEndDateTime: moment(moment.tz(shiftEndDateTime, session.timezone).format('YYYY-MM-DD HH:mm'))
    };

    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title={this.state.step === 4 ? 'Cannot approve shift' : 'Approve shift'}
        onClose={this._onClose}
        width="medium"
        canCloseOutside={this.state.canManuallyClose}
      >
        {this.state.step === 1 && (
          <div>
            <div className="mb-large line-height-135">
              <FieldLabel text={'START TIME'} />
              <Text lineHeight={135}>
                {shiftDatesWithoutTimezone.shiftStartDateTime.format('hh:mm A, DD MMMM YYYY - ')}
                {shiftDatesWithoutTimezone.shiftEndDateTime.format('hh:mm A, DD MMMM YYYY')}
              </Text>
            </div>

            <div className="mb-large line-height-135">
              <FieldLabel text={'TEAM MEMBER'} />
              <Text>
                {shiftSlot.firstName} {shiftSlot.lastName}
              </Text>
            </div>

            <ActionModalFooter align="right">
              <SecondaryButton size="large" className="mr-medium" onClick={this._onClose}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onSave}>
                Approve shift
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {this.state.step === 2 && (
          <div className="line-height-135 anim-slide-left">
            <div className="flex-column align-center mv-large justify-center">
              <div className="mb-medium">
                <Spinner size={80} />
              </div>
              <div className="text-align-center">
                <Text color="secondary" weight="bold">
                  Approving shift(s)...
                </Text>
                <br />
                <Text color="secondary">This won't take long.</Text>
              </div>
            </div>
          </div>
        )}

        {this.state.step === 3 && (
          <div className="anim-fade-in-fast">
            <div className="mb-medium line-height-135">
              <Text lineHeight={135}>The following shifts have been approved :</Text>
            </div>

            <div className="mb-large line-height-135">
              <FieldLabel text={'START TIME'} />
              <Text lineHeight={135}>
                {shiftDatesWithoutTimezone.shiftStartDateTime.format('hh:mm A, DD MMMM YYYY - ')}
                {shiftDatesWithoutTimezone.shiftEndDateTime.format('hh:mm A, DD MMMM YYYY')}
              </Text>
            </div>

            <div className="mb-large line-height-135">
              <FieldLabel text={'TEAM MEMBER'} />
              <Text>
                {shiftSlot.firstName} {shiftSlot.lastName}
              </Text>
            </div>

            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this._onClose}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}

        {this.state.step === 4 && (
          <div className="anim-fade-in-fast">
            <div className="mb-medium line-height-135">
              <Text lineHeight={135}>Shifts cannot be approved until the session has been completed.</Text>
            </div>

            <ActionModalFooter align="right">
              <PrimaryButton size="large" onClick={this._onClose}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doApproveShift: dispatch.groupServiceStore.doApproveShift
});

export default connect(
  mapState,
  mapDispatch
)(ApproveSlotModal);
