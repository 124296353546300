import React, { Component } from 'react';
import { Checkbox, Col, Form, InputNumber, Radio, Row, Select } from 'antd';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, state } from 'stores/rematch/root-store';
import Script from 'react-load-script';
import AddAddressModal from 'common-components/addresses/AddAddressModal';
import globalConfig from 'variables/global-config';
import { ServiceLocationType, ServiceType } from 'utilities/enum-utils';
import { LocationDisplay } from 'common-components/locations';
import NumberInput from 'common-components/inputs/NumberInput';

interface ILocationStepPanelProps extends FormComponentProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setNewService: typeof dispatch.servicesStore.setNewService;
  newService: typeof state.servicesStore.newService;
}

interface ILocationStepPanelState {
  isOpen: boolean;
  locationType: string;
  location: any;
  isNoAddressError: boolean;
  isNoStreetNameInAddress: boolean;
  portalUserList: any;
  isFixedCapacity: boolean;
  isWarningTravelPriceBefore: boolean;
  isChargeTransportDuringBooking: boolean;
  transportPriceDuringBooking: number;
}

class LocationStepPanel extends Component<ILocationStepPanelProps, ILocationStepPanelState> {
  state = {
    isOpen: false,
    locationType: this.props.newService.serviceDirection
      ? this.props.newService.serviceDirection
      : ServiceLocationType.FIXEDVENUE,
    location:
      this.props.newService.locations && this.props.newService.locations[0] ? this.props.newService.locations[0] : null,
    isNoAddressError: false,
    isNoStreetNameInAddress: false,
    isWarningTravelPriceBefore: false,
    portalUserList: [],
    isFixedCapacity: !!this.props.newService.isFixedCapacity,
    isChargeTransportDuringBooking: !!this.props.newService.serviceClaimConfig.isChargeNdisTransportDuringBooking,
    transportPriceDuringBooking: this.props.newService.serviceClaimConfig.ndisClaims.transportPriceDuringBooking
  };

  private _onNextStep = async () => {
    const { form, setNewService, onNextStep, newService } = this.props;
    const {
      location,
      locationType,
      isFixedCapacity,
      isChargeTransportDuringBooking,
      transportPriceDuringBooking
    } = this.state;
    const isGroupService = newService.serviceType === ServiceType.GROUP;

    let isFormValid = true;

    if (_.isEmpty(location) && locationType === ServiceLocationType.FIXEDVENUE) {
      isFormValid = false;
      this.setState({ isNoAddressError: true });
    } else if (locationType === ServiceLocationType.FIXEDVENUE && !location.streetAddress1) {
      isFormValid = false;
      this.setState({ isNoStreetNameInAddress: true });
    }

    form.validateFields(async (err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      let locations = location ? [location] : [];
      let serviceDirection = locationType;

      if (isGroupService) {
        let capacity = Number(form.getFieldValue('serviceMaximumCustomer'))
          ? Number(form.getFieldValue('serviceMaximumCustomer'))
          : 0;
        const newServiceClaimConfig = _.cloneDeep(newService.serviceClaimConfig);
        newServiceClaimConfig.isChargeNdisTransportBeforeBooking = isChargeTransportDuringBooking;
        newServiceClaimConfig.isChargeNdisTransportDuringBooking = isChargeTransportDuringBooking;
        newServiceClaimConfig.ndisClaims.transportPriceDuringBooking = transportPriceDuringBooking;
        newServiceClaimConfig.ndisClaims.transportPriceBeforeBooking = transportPriceDuringBooking;
        setNewService({
          ...newService,
          capacity,
          isFixedCapacity,
          locations,
          serviceDirection,
          serviceClaimConfig: newServiceClaimConfig
        });
      } else {
        setNewService({ ...newService, locations, serviceDirection, capacity: 0 });
      }

      onNextStep();
    }
  };

  private _closeCreateEditAddressModal = () => {
    this.setState({ isOpen: false });
  };

  private _openCreateEditAddressModal = () => {
    this.setState({ isOpen: true });
  };

  private _saveCustomerAddress = (location) => {
    this._closeCreateEditAddressModal();
    this.setState({ location, isNoAddressError: false, isNoStreetNameInAddress: false });
  };

  private _changeLocationType = (e) => {
    this.setState({ locationType: e.target.value });
  };

  private _changeIsFixedCapacity = () => {
    const newIsFixedCapacity = !this.state.isFixedCapacity;
    this.setState({ isFixedCapacity: newIsFixedCapacity });
  };

  private _changeIsChargeTransport = () => {
    const newIsChargeTransport = !this.state.isChargeTransportDuringBooking;
    this.setState({ isChargeTransportDuringBooking: newIsChargeTransport });
    if (!newIsChargeTransport) {
      this.setState({ transportPriceDuringBooking: 0 });
    }
  };

  private _onChangeTravelPriceBefore(event) {
    if (event > 0 && event > 0.85) {
      this.setState({ isWarningTravelPriceBefore: true });
    } else {
      this.setState({ isWarningTravelPriceBefore: false });
    }
    this.setState({ transportPriceDuringBooking: event });
  }

  render() {
    const { onPreviousStep, newService, form } = this.props;
    const {
      locationType,
      location,
      isNoAddressError,
      isNoStreetNameInAddress,
      isFixedCapacity,
      isChargeTransportDuringBooking,
      transportPriceDuringBooking
    } = this.state;
    const { getFieldDecorator } = form;
    const isGroupService = newService.serviceType && newService.serviceType === ServiceType.GROUP ? true : false;

    return (
      <div className="anim-slide-left mb-x-large">
        <Script
          url={'https://maps.googleapis.com/maps/api/js?key=' + globalConfig.googleMapApiKey + '&libraries=places'}
        />
        <AddAddressModal
          closeCreateEditAddressModal={() => {
            this._closeCreateEditAddressModal();
          }}
          isOpen={this.state.isOpen}
          saveCustomerAddress={this._saveCustomerAddress}
        />
        {!isGroupService && (
          <Title level={2} weight="bolder" className="line-height-100">
            Additional Information
          </Title>
        )}
        {isGroupService && (
          <Title level={2} weight="bolder" className="line-height-100">
            <span className="text-weight-regular">Service</span> location &amp; capacity
          </Title>
        )}

        <Paragraph>{isGroupService ? 'Tell us about this service.' : 'Tell us more about this service.'}</Paragraph>

        <Row className="mt-x2-large mb-large">
          <Col span={4} style={{ marginTop: '4px' }}>
            <SubTitle>{isGroupService ? 'Default location' : 'Location'}</SubTitle>
          </Col>
          <Col span={20}>
            <div className="mb-medium mt-x2-small">
              <Radio.Group onChange={this._changeLocationType} value={locationType}>
                <Radio value={ServiceLocationType.FIXEDVENUE}>
                  {isGroupService ? 'Fixed venue' : 'Physical location'}
                </Radio>
                <br />
                {newService.serviceType === ServiceType.INDIVIDUAL && (
                  <>
                    <Radio value={ServiceLocationType.CUSTOMERLOCATION}>
                      Occurs at a location the customer chooses
                    </Radio>
                    {/* <br /> */}
                    {/*TODO: Move back outside when Online option is available for group service*/}
                    {/* <Radio value={ServiceLocationType.ONLINE}>{isGroupService ? 'Online' : 'Online delivery'}</Radio> */}
                  </>
                )}
              </Radio.Group>
            </div>
            {locationType === ServiceLocationType.FIXEDVENUE && (
              <div>
                <div className="mb-medium">
                  {location ? (
                    <LocationDisplay location={location.fullAddress} />
                  ) : (
                    <Text color="secondary">No Address selected</Text>
                  )}
                </div>
                <SecondaryButton icon={!location && 'plus'} onClick={this._openCreateEditAddressModal}>
                  {location ? 'Edit location' : 'Add a location'}
                </SecondaryButton>
                {isGroupService && !isNoAddressError && (
                  <div className="mb-medium">
                    <Text color="secondary" style={{ fontSize: '14px' }}>
                      This will be the default location used for each session created for this service; You can adjust
                      this later in individual sessions.
                    </Text>
                  </div>
                )}
                {isNoAddressError && (
                  <div>
                    <Text color="red-dark">Please add a Location.</Text>
                  </div>
                )}
                {isNoStreetNameInAddress && (
                  <div>
                    <Text color="red">Please select a Location with a street name.</Text>
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>
        {isGroupService && (
          <>
            <Row className="mb-large">
              <Col span={4} style={{ marginTop: '6px' }}>
                <SubTitle>Capacity settings</SubTitle>
              </Col>
              <Col span={20}>
                <Checkbox defaultChecked={isFixedCapacity} onChange={this._changeIsFixedCapacity}>
                  This service has a maximum capacity for bookings.
                </Checkbox>

                <br />

                <Text size="regular" color="secondary">
                  When this option is ticked, this service will have a maximum capacity for the sessions. Check this if
                  you wish to limit the number of bookings for the sessions.
                </Text>

                {isFixedCapacity && (
                  <div className="mt-small bg-quaternary pv-medium pl-medium rounded-big" style={{ width: '432px' }}>
                    <Form.Item className={'m-none'}>
                      {getFieldDecorator('serviceMaximumCustomer', {
                        initialValue: newService.capacity ? newService.capacity : '',
                        rules: [
                          {
                            required: true,
                            type: 'number',
                            min: 1,
                            max: 500,
                            message: 'Please enter a valid capacity between 1 and 500.'
                          }
                        ]
                      })(<NumberInput size="default" className="mr-small" precision={0} placeholder={'eg 50'} />)}{' '}
                      <Text>maximum capacity</Text>
                    </Form.Item>
                  </div>
                )}
              </Col>
            </Row>

            <Row className="mb-large">
              <Col span={4} style={{ marginTop: '6px' }}>
                <SubTitle>Transport settings</SubTitle>
              </Col>
              <Col span={20}>
                <Checkbox defaultChecked={isChargeTransportDuringBooking} onChange={this._changeIsChargeTransport}>
                  This service offers transport before/after bookings.
                </Checkbox>
                <br />
                <Text size="regular" color="secondary">
                  When this option is ticked, this service will have the ability to provide optional transport
                  before/after bookings to the attending customers as an additional service.
                </Text>
                {isChargeTransportDuringBooking && (
                  <>
                    <div
                      className="bg-quaternary pv-small pl-small rounded mt-medium flex-row"
                      style={{ width: '418px', height: '48px' }}
                    >
                      <Form.Item className={'m-none'}>
                        {getFieldDecorator('transportPriceDuringBooking', {
                          initialValue: transportPriceDuringBooking,
                          rules: [
                            {
                              required: true,
                              message: 'Please enter a number.'
                            }
                          ]
                        })(
                          <NumberInput
                            size="default"
                            step={0.01}
                            min={0.0}
                            max={99999}
                            className={'line-height-100'}
                            addonBefore={'$'}
                            style={{ width: '100px' }}
                            onChange={(e) => this._onChangeTravelPriceBefore(e)}
                            placeholder={'eg 0.85'}
                          />
                        )}
                      </Form.Item>
                      <div>
                        <Text className="ml-small" style={{ verticalAlign: 'middle' }}>
                          price per km.
                        </Text>
                      </div>
                    </div>
                    {this.state.isWarningTravelPriceBefore && (
                      <div className={'text-color-orange text-size-regular'} style={{ lineHeight: 'initial' }}>
                        The NDIS <b>recommends</b> that cost per km do not exceed $0.85 for vehicles not modified for
                        accessibility.
                      </div>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </>
        )}

        <div className="flex-row align-center mv-x2-large">
          <SecondaryButton size="large" onClick={onPreviousStep} icon="left" className="mr-large">
            Previous
          </SecondaryButton>

          <PrimaryButton size="large" onClick={this._onNextStep} icon="right" iconPosition="right">
            Next
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

export default Form.create<ILocationStepPanelProps>()(LocationStepPanel);
