import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import ScheduleListItem from './ScheduleListItem';
import { IGroupServiceSchedule } from 'interfaces/service-interfaces';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { Empty, Skeleton } from 'antd';
import { Text } from 'common-components/typography';
import DeleteScheduleModal from './DeleteScheduleModal';

interface IGroupServiceScheduleListingPanelProps {
  groupServiceSchedules: IGroupServiceSchedule[];
  timezone: string;
  serviceId: string;
  doSearchGroupSeviceSchedules: typeof dispatch.servicesStore.doSearchGroupSeviceSchedules;
  setGroupServiceSchedules: typeof dispatch.servicesStore.setGroupServiceSchedules;
}

interface GroupServiceScheduleListingPanelState {
  isLoading: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  isOpenDeleteScheduleModal: boolean;
  selectedSchedule?: IGroupServiceSchedule;
}

const ScheduleEmptyState = () => (
  <div className="flex-1 mv-x2-large align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
    </div>
    <div className="text-align-center" style={{ width: '223px' }}>
      <Text size="x2-large" color="secondary" weight="bold">
        No schedules created.
      </Text>{' '}
      <Text color="secondary">There are no schedules created for this group service yet.</Text>
    </div>
  </div>
);

class GroupServiceScheduleListingPanel extends Component<
  IGroupServiceScheduleListingPanelProps,
  GroupServiceScheduleListingPanelState
> {
  state = {
    isLoading: false,
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    isOpenDeleteScheduleModal: false,
    selectedSchedule: null
  };

  private _fetchMoreSchedules = async () => {
    const { doSearchGroupSeviceSchedules } = this.props;
    const newPage = this.state.page + 1;
    this.setState({ isLoading: true, page: newPage });

    await doSearchGroupSeviceSchedules({
      serviceId: this.props.serviceId,
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp
    });
    this.setState({ isLoading: false });
  };

  private _loadContent = async () => {
    const { doSearchGroupSeviceSchedules, serviceId, setGroupServiceSchedules } = this.props;
    const { pageSize, pageTimestamp } = this.state;
    //This method is only used to fetch fresh content.
    const newPage = 1;

    setGroupServiceSchedules([]);
    this.setState({ page: newPage });
    this.setState({ isLoading: true });

    //await asyncDelay(3000);
    await doSearchGroupSeviceSchedules({
      serviceId,
      page: newPage,
      pageSize,
      pageTimestamp
    });
    this.setState({ isLoading: false });
  };

  private _closeDeleteScheduleModal = () => {
    this.setState({ selectedSchedule: null, isOpenDeleteScheduleModal: false });
  };

  private _onDeleteSchedule = (schedule: IGroupServiceSchedule) => {
    this.setState({ selectedSchedule: schedule, isOpenDeleteScheduleModal: true });
  };

  componentDidMount = () => {
    this._loadContent();
  };

  render() {
    const { groupServiceSchedules, timezone } = this.props;

    return (
      <div className="bg-quaternary ph-medium pt-medium bordered border-standard-gray">
        <DeleteScheduleModal
          isOpen={this.state.isOpenDeleteScheduleModal}
          serviceId={this.props.serviceId}
          selectedSchedule={this.state.selectedSchedule}
          resetScheduleList={this._loadContent}
          closeDeleteScheduleModal={this._closeDeleteScheduleModal}
          timezone={timezone}
        />
        <InfiniteScrollLoading
          hasMore={groupServiceSchedules.length >= this.state.page * this.state.pageSize}
          loadingElementId={'content-container'}
          loadMore={this._fetchMoreSchedules}
          loaderColSpan={7}
          loadingOffSet={60}
        >
          {_.map(groupServiceSchedules, (schedule) => (
            <ScheduleListItem
              scheduleItem={schedule}
              timezone={timezone}
              onDeleteSchedule={() => this._onDeleteSchedule(schedule)}
            />
          ))}
        </InfiniteScrollLoading>

        {this.state.isLoading && (
          <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
        )}
        {!this.state.isLoading && _.isEmpty(groupServiceSchedules) && <ScheduleEmptyState />}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  groupServiceSchedules: state.servicesStore.groupServiceSchedules,
  timezone: state.groupServiceStore.selectedGroupService && state.groupServiceStore.selectedGroupService.timezone
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doSearchGroupSeviceSchedules: dispatch.servicesStore.doSearchGroupSeviceSchedules,
  setGroupServiceSchedules: dispatch.servicesStore.setGroupServiceSchedules
});

export default connect(
  mapState,
  mapDispatch
)(GroupServiceScheduleListingPanel);
