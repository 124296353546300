/* eslint-disable */
import React, { Component } from 'react';
import { FilterType, ServiceType } from 'utilities/enum-utils';
import ServiceListHeaderSection from './sections/ServiceListHeaderSection';
import ServiceListItemsSection from './sections/ServiceListItemsSection';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { IServiceListItem } from 'interfaces/service-interfaces';
import _ from 'lodash';
import CommonUtils from 'utilities/common-utils';

interface IServiceListingsPanelProps {
  doSearchService: typeof dispatch.servicesStore.doSearchService;
  setServicesFilter: typeof dispatch.servicesStore.setServicesFilter;
  setFilteredServices: typeof dispatch.servicesStore.setFilteredServices;
  filteredServiceList?: IServiceListItem[];
  serviceList?: IServiceListItem[];
  setSelectedServiceId: (selectedServiceId: string, serviceType: ServiceType) => void;
  doFetchTeamList: typeof dispatch.servicesStore.doFetchTeamListForServiceContact;
  showServiceModal: () => void;
  servicesFilter: any;
}
interface IServiceListingsPanelState {
  isLoading: boolean;
  isLoadingMore: boolean;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  topHeight: number;
}

const SERVICELIST_FILTERCONFIGS = {
  ALL: {
    key: 'ALL',
    title: 'All Services',
    description: 'Shows all services.',
    filters: [
      {
        filter: FilterType.SERVICE_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.SERVICE_STATUS).fullSelectionName
      },
      {
        filter: FilterType.SERVICE_DEPARTMENT,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.SERVICE_DEPARTMENT).fullSelectionName
      },
      {
        filter: FilterType.SERVICE_TYPE,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.SERVICE_TYPE).fullSelectionName
      }
    ]
  }
};

class ServiceListingsPanel extends Component<IServiceListingsPanelProps, IServiceListingsPanelState> {
  state = {
    topHeight: 0,
    isLoading: false,
    isLoadingMore: false,
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date()
  };

  // ref element for the header; used to determine the height of the header
  private _headerElement = null;

  // height change handler.
  private _handleHeaderHeight = () => {
    if (this._headerElement) {
      this.setState({ topHeight: this._headerElement.offsetHeight - 1 });
    }
  };

  private _refreshListings = async () => {
    const newPageTimestamp = new Date();
    this.setState({ page: 1, pageTimestamp: newPageTimestamp }, this._loadContent);
    this.props.setFilteredServices([]);
  };

  private _loadContent = async () => {
    const { doSearchService, doFetchTeamList } = this.props;
    const { page, pageSize, pageTimestamp } = this.state;

    this.setState({ isLoading: true });
    await doSearchService({
      page,
      pageSize,
      pageTimestamp
    });
    await doFetchTeamList({});
    this.props.setServicesFilter(SERVICELIST_FILTERCONFIGS.ALL.filters);
    this.setState({ isLoading: false });
  };

  private _fetchMoreServices = async () => {
    const { doSearchService } = this.props;
    this.setState({ isLoadingMore: true, page: this.state.page + 1 });
    const requestFilter = this._formatFilterQuery();
    await doSearchService({
      page: this.state.page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter
    });
    this.setState({ isLoadingMore: false });
  };

  private _applyFilter = async () => {
    const page = 1;
    this.setState({ page, isLoading: true });
    const requestFilter = this._formatFilterQuery();
    await this.props.doSearchService({
      page,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      sortByRelevance: true,
      ...requestFilter
    });
    this.setState({ isLoading: false });
  };

  private _formatFilterQuery = () => {
    const requestFilter: any = {};
    _.forEach(this.props.servicesFilter, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'supportWorkerIds':
            const supportWorkerIds = _.map(filter.values, (supportWorker) => {
              return supportWorker.value;
            });
            requestFilter.supportWorkerIds = supportWorkerIds;
            break;
          case 'serviceIds':
            requestFilter.serviceIds = filter.values;
            break;
          case 'serviceStatus':
            requestFilter.statuses = filter.values;
            break;
          case 'serviceDepartmentIds':
            requestFilter.serviceDepartmentIds = filter.values;
            break;
          case 'serviceType':
            requestFilter.serviceType = filter.values;
            break;
          case 'searchString':
            requestFilter.searchString = filter.values;
        }
      }
    });
    return requestFilter;
  };

  componentDidMount() {
    // Automatically set the top height for the top panel. This is required for sticky.
    this._handleHeaderHeight();
    this._loadContent();
  }

  render() {
    const { filteredServiceList, setSelectedServiceId, showServiceModal } = this.props;

    return (
      <div className="height-full">
        <div className="service-header" ref={(com) => (this._headerElement = com)}>
          <ServiceListHeaderSection
            showServiceModal={showServiceModal}
            applyFilter={this._applyFilter}
            refreshListings={this._refreshListings}
          />
        </div>
        <ServiceListItemsSection
          fetchMoreServices={this._fetchMoreServices}
          page={this.state.page}
          pageSize={this.state.pageSize}
          filteredServiceList={filteredServiceList}
          setSelectedServiceId={setSelectedServiceId}
          isLoading={this.state.isLoading}
          isLoadingMore={this.state.isLoadingMore}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  filteredServiceList: state.servicesStore.filteredServices,
  servicesFilter: state.servicesStore.servicesFilter
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doSearchService: dispatch.servicesStore.doSearchService,
  doFetchTeamList: dispatch.servicesStore.doFetchTeamListForServiceContact,
  setServicesFilter: dispatch.servicesStore.setServicesFilter,
  setFilteredServices: dispatch.servicesStore.setFilteredServices
});

export default connect(
  mapState,
  mapDispatch
)(ServiceListingsPanel);
