import { notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PrimaryButton, SecondaryButton } from '../../../common-components/buttons/index';
import ActionModal, { ActionModalFooter } from '../../../common-components/modal/ActionModal';
import { Text } from '../../../common-components/typography/index';
import { dispatch, IRootDispatch } from '../../../stores/rematch/root-store';
import { ShiftSlotStatus } from '../../../utilities/enum-utils';

interface IActivityRecordRemoveShiftFromTimeSheetActionModalProps {
  isOpen: boolean;
  onClose: () => void;
  doEditActivityRecordHours: typeof dispatch.bookingsStore.doEditActivityRecordHours;
  booking: any;
}

interface IActivityRecordRemoveShiftFromTimeSheetActionModalState {
  title: string;
  isLoading: boolean;
}

class ActivityRecordRemoveShiftFromTimeSheetActionModal extends Component<
  IActivityRecordRemoveShiftFromTimeSheetActionModalProps,
  IActivityRecordRemoveShiftFromTimeSheetActionModalState
> {
  state = {
    isLoading: false,
    title: 'Remove hours from timesheet'
  };

  private _onCloseModal = () => {
    this.props.onClose();
  };

  private _onSubmit = async () => {
    const { booking, doEditActivityRecordHours } = this.props;
    this.setState({ isLoading: true });
    try {
      await doEditActivityRecordHours({
        bookingId: booking.bookingId,
        startDateTime: booking.startDateTime,
        endDateTime: booking.endDateTime,
        shiftSlotStatus: ShiftSlotStatus.CONFIRMED,
        isDuration: booking.isDuration
      });
      notification.success({
        message: 'Hours removed from timesheet',
        description: 'This activity record has been removed from the team members timesheet pending record approved.'
      });
      this.setState({ isLoading: false });
      this._onCloseModal();
    } catch (e) {
      notification.error({
        message: 'Oops, something went wrong, please try again.'
      });
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isOpen } = this.props;
    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width={'medium'}
        verticalAlignment="highest"
      >
        <div>
          <Text>
            Are you sure you want to remove this activity record to assigned team members timesheet? (Pending record
            approval)
          </Text>
          <ActionModalFooter>
            <SecondaryButton size={'large'} onClick={this._onCloseModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size={'large'} className={'ml-large'} onClick={this._onSubmit}>
              Remove from timesheet
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditActivityRecordHours: dispatch.bookingsStore.doEditActivityRecordHours
});

export default connect(
  null,
  mapDispatch
)(ActivityRecordRemoveShiftFromTimeSheetActionModal);
