// Team Permissions
import { PermissionRowBase } from 'views/account-management/add-new-member/panels/permissions-panel/portal/PermissionRowBase';
import React from 'react';
import { IPermissionRole, IPermissions } from 'interfaces/account-interfaces';
import TeamSectionModal from 'views/account-management/components/TeamSectionModal';
import { getTeamPermissionName, getTeamRole } from 'views/account-management/utils/permission-utils';
import { UserPermissionRole } from 'utilities/enum-utils';

interface TeamPermissionRowProps {
  permissions: IPermissions;
  onChangePermissions: any;
  actionsDisabled: boolean;
  hasAllAccess: boolean;
}

interface TeamPermissionRowState {
  teamModalOpen: boolean;
}

export class TeamPermissionsRow extends React.Component<TeamPermissionRowProps, TeamPermissionRowState> {
  state = {
    teamModalOpen: false
  };

  onOpenTeam = () => this.setState({ teamModalOpen: true });
  onCloseTeam = () => this.setState({ teamModalOpen: false });

  // Triggered whenever a selection is made.
  onSelectTeam = (result) => {
    const { permissions, onChangePermissions } = this.props;
    const { permissionRoles } = permissions;

    // filter out payment permissions first
    const filteredRoles = permissionRoles.filter(
      (role) =>
        role.permissionRole !== UserPermissionRole.TeamViewOnly &&
        role.permissionRole !== UserPermissionRole.TeamManager
    );

    // Remap back to UserPermissionRole
    const targetRole = getTeamRole(result.teamPermission);

    // Create new role to be inserted
    const newTeamRole: IPermissionRole = { permissionRole: targetRole, serviceDepartmentId: null, serviceId: null };

    // Updated permissions object
    const updatedPermissions = { ...permissions, permissionRoles: [...filteredRoles, newTeamRole] };

    // Update in redux store.
    onChangePermissions(updatedPermissions);
  };

  render() {
    const { permissions } = this.props;

    const teamPermissionName = getTeamPermissionName(permissions, this.props.hasAllAccess);

    return (
      <>
        {/* Display row */}
        <PermissionRowBase
          iconName={'team'}
          title={'Team'}
          description={'Team member profiles and data'}
          currentFlag={teamPermissionName}
          onClickModify={this.onOpenTeam}
          actionsDisabled={this.props.actionsDisabled}
        />

        {/* Modify dialog modal */}
        <TeamSectionModal
          isOpen={this.state.teamModalOpen}
          onClose={this.onCloseTeam}
          setSelectedOption={this.onSelectTeam}
          permissions={permissions}
        />
      </>
    );
  }
}
