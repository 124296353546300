import React, { HTMLProps, PureComponent } from 'react';
import { Text } from 'common-components/typography';
import { ICrumb } from 'interfaces/common-interface';
import { Icon } from 'antd';
import { ThemeType } from 'antd/es/icon';
import _ from 'lodash';
import { HyperlinkButton } from 'common-components/buttons';
import * as H from 'history';

interface IBreadcrumbNavProps extends HTMLProps<HTMLDivElement> {
  crumbs: ICrumb[];
  icon: string;
  history: H.History;
  theme?: ThemeType;
  disabled?: boolean;
  isHidden?: boolean;
  isBordered?: boolean;
}

export default class BreadcrumbNav extends PureComponent<IBreadcrumbNavProps> {
  private _goToPage = (target) => {
    this.props.history.push(target);
  };

  render() {
    const {
      crumbs,
      icon = null,
      theme = null,
      disabled = false,
      isHidden = false,
      isBordered = true,
      className = '',
      ...props
    } = this.props;
    return (
      !isHidden && (
        <div
          className={`pv-medium bg-white ${isBordered ? 'bordered-top bordered-bottom' : ''} ${className}`}
          {...props}
        >
          {icon && <Icon type={icon} className={'ml-medium mr-small text-color-tertiary'} theme={theme} />}
          {_.map(crumbs, (crumb, index) => {
            const isCurrentPage = index === crumbs.length - 1;
            return (
              <Text color={'secondary'} weight={isCurrentPage ? 'regular' : 'regular'} size="large" key={index}>
                {crumb.icon && <Icon type={crumb.icon} className={'mr-medium'} theme={crumb.theme} />}{' '}
                {crumb.disabled || disabled || !crumb.target ? (
                  crumb.title
                ) : (
                  <HyperlinkButton onClick={() => this._goToPage(crumb.target)} fontSize={'large'}>
                    {crumb.title}
                  </HyperlinkButton>
                )}
                {!isCurrentPage && ' / '}
              </Text>
            );
          })}
        </div>
      )
    );
  }
}
