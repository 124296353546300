import React, { Component } from 'react';
import { Col, Form, Radio, Row, Avatar } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { Paragraph, Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';

import { IAddCustomerDetailWizard } from 'src/interfaces/customer-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Title from 'antd/lib/typography/Title';

import AddLegalGuardianModal from 'views/customers/listing/components/AddLegalGuardianModal';
import ActionModal from 'common-components/modal/ActionModal';
import CommonUtils from 'utilities/common-utils';

interface IAddCustomerStepAddLegalGuardianProps extends FormComponentProps {
  portalUser: typeof state.authStore.portalUser;
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setAddCustomerDetailWizard: typeof dispatch.customersStore.setAddCustomerDetailWizard;
  addCustomerDetailWizard: typeof state.customersStore.addCustomerDetailWizard;
}

interface IAddCustomerStepAddLegalGuardianState {
  customerDetails: IAddCustomerDetailWizard;
  openAddLegalGuardianModal: boolean;
  legalGuardianRequiredError: boolean;
  openRemoveGuardianModal: boolean;
  legalGuardianToRemoveEmail: string;
}

class AddCustomerStepAddLegalGuardian extends Component<
  IAddCustomerStepAddLegalGuardianProps,
  IAddCustomerStepAddLegalGuardianState
> {
  state = {
    customerDetails: this.props.addCustomerDetailWizard,
    openAddLegalGuardianModal: false,
    legalGuardianRequiredError: false,
    openRemoveGuardianModal: false,
    legalGuardianToRemoveEmail: null
  };

  private _changeHasGuardians = (value) => {
    const { customerDetails } = this.state;

    const newCustomerDetails = { ...customerDetails, hasGuardians: value };

    this.setState({ customerDetails: newCustomerDetails });
  };

  private _validateBeforeNextStep = async () => {
    const { form, onNextStep, setAddCustomerDetailWizard } = this.props;
    const { customerDetails } = this.state;

    let isFormValid = true;

    form.validateFields(async (err, value) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (isFormValid) {
      if (customerDetails.hasGuardians && _.isEmpty(customerDetails.guardians)) {
        this.setState({ legalGuardianRequiredError: true });
      } else {
        if (customerDetails.hasGuardians) {
          customerDetails.isIndependent = false;
          setAddCustomerDetailWizard(customerDetails);
        }

        onNextStep();
      }
    }
  };

  private _openRemoveGuardianModal = (email) => {
    this.setState({ openRemoveGuardianModal: true, legalGuardianToRemoveEmail: email });
  };

  private _closeRemoveGuardianModal = () => {
    this.setState({ openRemoveGuardianModal: false, legalGuardianToRemoveEmail: null });
  };

  private _onRemoveGuardian = async () => {
    const { customerDetails } = this.state;

    const guardians = _.filter(customerDetails.guardians, (guardian) => {
      return guardian.email !== this.state.legalGuardianToRemoveEmail;
    });
    const updatedCustomerDetails = {
      ...customerDetails,
      guardians
    };

    this.props.setAddCustomerDetailWizard(updatedCustomerDetails);
    this.setState({ customerDetails: updatedCustomerDetails, openRemoveGuardianModal: false });
  };

  private _openAddLegalGuardianModal = async () => {
    this.setState({ openAddLegalGuardianModal: true, legalGuardianRequiredError: false });
  };
  private _closeAddLegalGuardianModal = async () => {
    this.setState({ openAddLegalGuardianModal: false });
  };

  private _updateCustomerDetail = (fieldName, value) => {
    const { customerDetails } = this.state;
    this.setState({ customerDetails: { ...customerDetails, [fieldName]: _.trim(value) } });
  };

  render() {
    const { form, onPreviousStep } = this.props;
    const { customerDetails, openAddLegalGuardianModal, openRemoveGuardianModal } = this.state;
    const { getFieldDecorator } = form;
    const { _closeRemoveGuardianModal, _onRemoveGuardian } = this;

    return (
      <>
        {openRemoveGuardianModal && (
          <ActionModal
            isOpen={openRemoveGuardianModal}
            title="Remove legal guardian"
            width="large"
            onClose={_closeRemoveGuardianModal}
            verticalAlignment="highest"
          >
            <>
              <div>
                <Paragraph>Are you sure you want to remove this user as a legal guardian ? </Paragraph>
              </div>

              <div className="pd-large pb- width-full" style={{ position: 'sticky', bottom: 0 }}>
                <Row gutter={0} type="flex" align="middle" className="bg-transparent">
                  <Col className="bg-transparent" span={24}>
                    <div className="text-align-right">
                      <SecondaryButton size="large" className="mr-medium" onClick={_closeRemoveGuardianModal}>
                        Cancel
                      </SecondaryButton>
                      <PrimaryButton size="large" onClick={_onRemoveGuardian}>
                        Remove
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          </ActionModal>
        )}
        {openAddLegalGuardianModal && (
          <AddLegalGuardianModal
            isOpen={this.state.openAddLegalGuardianModal}
            onClose={this._closeAddLegalGuardianModal}
            isCreateNewCustomer={true}
          />
        )}
        <div className="anim-slide-left  pt-x3-large">
          <Row className="ph-x3-large">
            <Col span={6} style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div>
                <Paragraph size="x3-large" weight="bold">
                  Add a legal guardian
                </Paragraph>
                <Paragraph className="mb-none">
                  <Text size="small" color="gray">
                    Customer
                  </Text>
                </Paragraph>
                <Paragraph className="mt-none mb-large">
                  {customerDetails.firstName + ' ' + customerDetails.lastName}
                </Paragraph>

                <Paragraph>
                  If this customer is dependent/ has their care managed by someone other than themselves (A
                  parent/guardian/family member), then please indicate that here.
                </Paragraph>
                <Paragraph>
                  Visit the{' '}
                  <HyperlinkButton onClick={() => CommonUtils.sendToZendesk(this.props.portalUser)}>
                    Help Center
                  </HyperlinkButton>{' '}
                  to learn more.
                </Paragraph>
              </div>
            </Col>
            <Col span={18} className="pl-x-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
              <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
                <div className="mb-large">
                  <Title level={4} className="mb-large">
                    Is this customer's care managed by someone other than themselves?
                  </Title>
                  <Form.Item className={'m-none'}>
                    {getFieldDecorator('hasGuardians', {
                      initialValue: customerDetails.hasGuardians,
                      rules: [
                        {
                          required: true,
                          message: 'Please select an option.'
                        }
                      ]
                    })(
                      <Radio.Group
                        defaultValue={false}
                        onChange={(value) => this._changeHasGuardians(value.target.value)}
                      >
                        <Radio className="mb-medium" value={false}>
                          No
                        </Radio>
                        <br />
                        <Radio value={true}>Yes</Radio>
                      </Radio.Group>
                    )}
                  </Form.Item>

                  {form.getFieldValue('hasGuardians') && (
                    <>
                      <Title level={4} className="mb-none mt-large">
                        Add a legal guardian
                      </Title>
                      {_.map(this.state.customerDetails.guardians, (legalGuardian) => {
                        return (
                          <div className="pt-small">
                            <Row>
                              <Col span={6}>
                                <Row>
                                  <Col xs={24} md={6}>
                                    <Avatar
                                      size="large"
                                      icon="user"
                                      className="mr-medium"
                                      src={legalGuardian.attachmentUrl}
                                    />
                                  </Col>
                                  <Col xs={24} md={18}>
                                    <Text weight="bold">{legalGuardian.firstName + ' ' + legalGuardian.lastName}</Text>{' '}
                                    <br />
                                    <Text size="small" color="secondary" className="text-capitalize">
                                      {legalGuardian.relation}
                                    </Text>
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={4}>
                                <Text
                                  className="pl-medium text-underline cursor-pointer"
                                  onClick={() => this._openRemoveGuardianModal(legalGuardian.email)}
                                  size="small"
                                  color="red-darker"
                                >
                                  Remove
                                </Text>
                              </Col>
                            </Row>
                          </div>
                        );
                      })}
                      <div className="pt-large">
                        <Text onClick={this._openAddLegalGuardianModal}>
                          <HyperlinkButton>+ Add a legal guardian</HyperlinkButton>
                        </Text>
                      </div>
                    </>
                  )}
                  {form.getFieldValue('hasGuardians') && this.state.legalGuardianRequiredError && (
                    <div className="pt-large">
                      <Paragraph color="red">Please add legal guardian</Paragraph>
                    </div>
                  )}
                </div>
              </div>
              <div className="pv-medium width-full" style={{ position: 'sticky', bottom: 0 }}>
                <Row gutter={0} type="flex" align="middle" className="bg-transparent">
                  <Col className="bg-transparent" span={24}>
                    <div className="text-align-right pv-large">
                      <SecondaryButton size="large" className="mr-medium" onClick={onPreviousStep}>
                        Back
                      </SecondaryButton>
                      <PrimaryButton size="large" onClick={this._validateBeforeNextStep}>
                        Next
                      </PrimaryButton>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  addCustomerDetailWizard: state.customersStore.addCustomerDetailWizard
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setAddCustomerDetailWizard: dispatch.customersStore.setAddCustomerDetailWizard
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IAddCustomerStepAddLegalGuardianProps>()(AddCustomerStepAddLegalGuardian));
