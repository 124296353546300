import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { Text } from 'common-components/typography';
import CommonUtils from 'utilities/common-utils';

class ViewQuotationAdditionalCostView extends Component<any, any> {
  render() {
    const { quotation } = this.props;
    return (
      <Row
        className={
          'pv-medium bordered-bottom bordered-left bordered-right bg-blue-action-lightest border-standard-gray ph-medium'
        }
        type={'flex'}
        align={'middle'}
      >
        <Col span={12} className={'pl-medium'}>
          <Text weight={'bold'}>Additional cost</Text>
        </Col>
        <Col span={9} className={'text-align-right'}>
          <Text weight={'bold'}>{CommonUtils.formatPrice(Number(quotation.additionalCost))}</Text>
        </Col>
        <Col span={3} />
      </Row>
    );
  }
}

export default ViewQuotationAdditionalCostView;
