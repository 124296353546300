import React, { Component } from 'react';
import { Text, Title } from 'common-components/typography';
import { IBookingNoteItem } from './IBookingNoteItem';
import NoteCardItem from 'common-components/notes/NoteCardItem';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';
import AddEditNoteModal from 'common-components/notes/AddEditNoteModal';
import DeleteNoteModal from 'common-components/notes/DeleteNoteModal';
import ExportNoteModal from 'common-components/notes/ExportNoteModal';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { connect } from 'react-redux';

interface IBookingNotesPanelProps {
  onAddNote: (payload) => void;
  bookingNotes: IBookingNoteItem[];
  onRefresh: () => void;
  timezoneData?: any;
  hasEditBookingNotesPermission?: boolean;
  serviceName: string;
  bookingStartDateTime: string;
  bookingId: string;
  selectedBookingItem: any;
  isBookingArchived: boolean;
  noteType: string;
  doDownloadDocument: typeof dispatch.bookingsStore.doDownloadDocument;
}

interface IBookingNotesPanelState {
  noteValue: string;
  noteSharing: boolean;
  isLoading: boolean;
  displayNoteModal: boolean;
  editNote: boolean;
  editingNote: any;
  isDeleteNoteModalOpen: boolean;
  deletingNote: any;
  isExportNoteModalOpen: boolean;
}

class BookingNotesPanel extends Component<IBookingNotesPanelProps, IBookingNotesPanelState> {
  state = {
    noteValue: '',
    noteSharing: false,
    isLoading: false,
    displayNoteModal: false,
    editNote: false,
    editingNote: null,
    isDeleteNoteModalOpen: false,
    deletingNote: null,
    isExportNoteModalOpen: false
  };

  private _onPressAddNote = () => {
    this.setState({ displayNoteModal: true, editNote: false });
  };

  private _onPressEditNote = (note) => {
    this.setState({ editNote: true, editingNote: note, displayNoteModal: true });
  };

  private _onPressCloseNote = () => {
    this.setState({ displayNoteModal: false });
  };

  private _onPressCloseDeleteNoteModal = () => {
    this.setState({ isDeleteNoteModalOpen: false });
  };

  private _onPressDeleteNote = (noteItem) => {
    this.setState({ isDeleteNoteModalOpen: true, deletingNote: noteItem });
  };

  private _downloadFile = (document) => {
    const { doDownloadDocument } = this.props;
    doDownloadDocument(document);
  };

  render() {
    const { bookingNotes, isBookingArchived, noteType } = this.props;

    return (
      <div className="">
        <AddEditNoteModal
          bookingId={this.props.bookingId}
          isOpen={this.state.displayNoteModal}
          onClose={this._onPressCloseNote}
          editNote={this.state.editNote}
          editingNote={this.state.editingNote}
          noteType={noteType}
        />
        <DeleteNoteModal
          noteItem={this.state.deletingNote}
          isOpen={this.state.isDeleteNoteModalOpen}
          doCloseDeleteNoteModal={this._onPressCloseDeleteNoteModal}
        />
        <ExportNoteModal
          isOpen={this.state.isExportNoteModalOpen}
          isBookingNote={true}
          onClose={() => this.setState({ isExportNoteModalOpen: false })}
        />
        <div className="flex-row">
          <div className="width-full">
            <Title level={3} className="mb-none" weight="bold">
              Notes
            </Title>
            <Text size="large" type={'secondary'}>
              Notes related to this {noteType === 'BOOKING' ? 'booking' : 'activity record'}.
            </Text>
          </div>
          <div className="flex justify-end width-full">
            <SecondaryButton
              color={'blue-action'}
              className={'mr-medium'}
              onClick={() => this.setState({ isExportNoteModalOpen: true })}
            >
              Export Notes
            </SecondaryButton>
            {!isBookingArchived && (
              <PrimaryButton color={'blue-action'} icon={'plus'} onClick={this._onPressAddNote}>
                Add Note
              </PrimaryButton>
            )}
          </div>
        </div>
        <div className="mt-large bg-quaternary p-small">
          {bookingNotes.map((note) => (
            <NoteCardItem
              noteItem={note}
              key={note.noteId}
              noteType="BOOKING"
              serviceName={this.props.serviceName}
              onPressEditNote={this._onPressEditNote}
              bookingId={this.props.bookingId}
              onPressDeleteNote={this._onPressDeleteNote}
              bookingStartDateTime={this.props.bookingStartDateTime}
              timezone={this.props.selectedBookingItem.timezone}
              isBookingArchived={isBookingArchived}
              downloadFile={this._downloadFile}
            />
          ))}
        </div>
        <br />
        <br />
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doDownloadDocument: dispatch.bookingsStore.doDownloadDocument
});

export default connect(
  null,
  mapDispatch
)(BookingNotesPanel);
