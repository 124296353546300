/* eslint-disable */
import { Tooltip2 } from '@blueprintjs/popover2';
import { Alert, Button, DatePicker, Icon, Input, InputNumber, notification, Select, Switch } from 'antd';
import { Radio } from 'antd/es';
import RadioGroup from 'antd/lib/radio/group';
import AddAddressModal from 'common-components/addresses/AddAddressModal';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { LocationDisplay } from 'common-components/locations';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import { IServiceDetailsItem } from 'interfaces/service-interfaces';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import Script from 'react-load-script';
import { dispatch, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import {
  BookingErrorTimeSource,
  ServiceLocationType,
  ServiceType,
  ServiceWorkerSelectionType
} from 'utilities/enum-utils';
import globalConfig from 'variables/global-config';
import { ServDetailsContentRow } from '../common/ServDetailsContentRow';
import { ServDetailsSectionHeader } from '../common/ServDetailsSectionHeader';

export class ServiceSettingsTab extends Component<
  {
    selectedService: IServiceDetailsItem;
    doUpdateIndividualServiceDetail: typeof dispatch.servicesStore.doUpdateIndividualServiceDetail;
    hasEditPermission: boolean;
    portalUser: typeof state.authStore.portalUser;
  },
  any
> {
  state = {
    isEdit: '',
    isOpen: false,
    serviceDirection: '',
    workerSelectionType: '',
    location: null,
    isLoading: false,
    serviceStockNumber: '',
    capacity: null,
    isPublicMarketplaceLabel: 'PRIVATE',
    servicePublishDate: moment(),
    isProceedModalOpen: false,
    isUseWorkerTimeForAutoBilling: false,
    isWorkerRecalculationModalOpen: false,
    workerTimeLeeway: 0,
    canWorkerEditCheckInCheckOutTime: false,
    bookingTimeWarningSource: BookingErrorTimeSource.RECORD_TIME,
    siteName: null,
    siteCode: null
  };

  private _formatData = () => {
    const { selectedService } = this.props;

    this.setState({
      serviceDirection: selectedService.serviceDirection,
      workerSelectionType: selectedService.workerSelectionType,
      location: selectedService.locations[0] ? selectedService.locations[0] : null,
      serviceStockNumber: selectedService.serviceStockNumber ? selectedService.serviceStockNumber : '',
      capacity: selectedService.capacity ? selectedService.capacity : null,
      isPublicMarketplaceLabel: selectedService.isPublicMarketplace ? 'PUBLIC' : 'PRIVATE',
      servicePublishDate: selectedService.servicePublishDate,
      isUseWorkerTimeForAutoBilling: selectedService.isUseWorkerTimeForAutoBilling,
      workerTimeLeeway: selectedService.workerTimeLeeway,
      canWorkerEditCheckInCheckOutTime: selectedService.canWorkerEditCheckInCheckOutTime,
      bookingTimeWarningSource: selectedService.bookingTimeWarningSource,
      siteName: selectedService.siteName,
      siteCode: selectedService.siteCode
    });
  };

  componentDidMount() {
    this._formatData();
  }

  private _changeView = () => {
    this.setState({ isEdit: !this.state.isEdit, isProceedModalOpen: false });
  };

  private _onSubmitChange = async () => {
    this.setState({ isLoading: true });
    const { selectedService, doUpdateIndividualServiceDetail } = this.props;
    const payload = {
      serviceName: selectedService.serviceName,
      serviceDescription: selectedService.serviceDescription,
      bannerUrl: selectedService.bannerUrl.attachmentUrl,
      bannerUrlPath: selectedService.bannerUrl.attachmentPath,
      servicePublishDate: moment.tz(this.state.servicePublishDate, selectedService.timezone),
      serviceDirection: this.state.serviceDirection.toUpperCase(),
      workerSelectionType: this.state.workerSelectionType,
      locations: [this.state.location],
      serviceStockNumber: this.state.serviceStockNumber,
      capacity: Number(this.state.capacity),
      isPublicMarketplace: this.state.isPublicMarketplaceLabel === 'PUBLIC',
      timezone: selectedService.timezone,
      serviceDepartmentId: selectedService.serviceDepartmentId,
      isUseWorkerTimeForAutoBilling: this.state.isUseWorkerTimeForAutoBilling,
      workerTimeLeeway: this.state.workerTimeLeeway,
      canWorkerEditCheckInCheckOutTime: this.state.canWorkerEditCheckInCheckOutTime,
      bookingTimeWarningSource: this.state.bookingTimeWarningSource,
      siteName: this.state.siteName,
      siteCode: this.state.siteCode
    };
    try {
      await doUpdateIndividualServiceDetail(payload);
      notification.success({
        message: 'Settings updated successfully'
      });
      this.setState({ isLoading: false, isEdit: false });
    } catch (e) {
      notification.error({
        message: 'Update failed',
        description: 'Please try again.'
      });
      this.setState({ isLoading: false });
      throw e;
    }
  };

  private _closeProceedModal = () => {
    this.setState({ isProceedModalOpen: false });
  };

  private _closeWorkerRecalculationModal = () => {
    this.setState({ isWorkerRecalculationModalOpen: false });
  };

  private _changeWorkerRecalculation = () => {
    this.setState({
      isUseWorkerTimeForAutoBilling: !this.state.isUseWorkerTimeForAutoBilling,
      isWorkerRecalculationModalOpen: false
    });
  };

  private _onWorkerTimeLeewayChange = (value) => {
    this.setState({ workerTimeLeeway: value });
  };

  private _onBookingTimeWarningSource = (value) => {
    this.setState({ bookingTimeWarningSource: value });
  };

  private _discardChanges = () => {
    const { selectedService } = this.props;

    const hasUnsavedData =
      !moment(this.state.servicePublishDate).isSame(moment(selectedService.servicePublishDate)) ||
      this.state.serviceDirection !== selectedService.serviceDirection ||
      (this.state.location && this.state.location.fullAddress !== selectedService.locations[0].fullAddress) ||
      this.state.workerSelectionType !== selectedService.workerSelectionType ||
      Number(this.state.capacity) !== Number(selectedService.capacity) ||
      this.state.isPublicMarketplaceLabel !== (selectedService.isPublicMarketplace ? 'PUBLIC' : 'PRIVATE') ||
      this.state.isUseWorkerTimeForAutoBilling !== selectedService.isUseWorkerTimeForAutoBilling ||
      this.state.canWorkerEditCheckInCheckOutTime !== selectedService.canWorkerEditCheckInCheckOutTime;

    if (hasUnsavedData) {
      this.setState({ isProceedModalOpen: true });
    } else {
      this._changeView();
    }
  };

  private _openCreateEditAddressModal = () => {
    this.setState({ isOpen: true });
  };

  private _closeCreateEditAddressModal = () => {
    this.setState({ isOpen: false });
  };

  private _saveCustomerAddress = (location) => {
    this.setState({ location, isNoAddressError: false });
  };

  render() {
    const { selectedService, portalUser } = this.props;
    const { isEdit, location } = this.state;

    const selectedLocation = selectedService.locations[0];

    if (!isEdit) {
      return (
        <div className="mt-small">
          <ServDetailsSectionHeader
            title={'Service Settings'}
            subtitle={'Settings that control your service behaviour.'}
            hasEditButton={this.props.hasEditPermission}
            editAction={this._changeView}
          />
          <ServDetailsContentRow
            label={'Publish Date'}
            subLabel={'From when customer will be able to see this service.'}
          >
            {moment.tz(selectedService.servicePublishDate, selectedService.timezone).format('ddd, D/MM/YYYY')}
          </ServDetailsContentRow>
          <ServDetailsContentRow
            label={'Location'}
            subLabel={
              'Does this service take place at a fixed locations, or are customers allowed to choose where they would like to consume this service?'
            }
          >
            {selectedService.serviceDirection === ServiceLocationType.FIXEDVENUE && (
              <LocationDisplay location={selectedLocation.fullAddress} />
            )}
            {selectedService.serviceDirection === ServiceLocationType.ONLINE && <Text>Online</Text>}
            {selectedService.serviceDirection === ServiceLocationType.CUSTOMERLOCATION && (
              <Text>Customer's location</Text>
            )}
          </ServDetailsContentRow>
          <ServDetailsContentRow
            label={'Site name'}
            subLabel={'Site name for this service in order to track this service in finance export files'}
          >
            {selectedService.siteName ? (
              <Text>{selectedService.siteName}</Text>
            ) : (
              <Text color={'secondary'}>Not set</Text>
            )}
          </ServDetailsContentRow>
          <ServDetailsContentRow
            label={'Site code'}
            subLabel={'Unique site code for this service in order to track this service in finance export files'}
          >
            {selectedService.siteCode ? (
              <Text>{selectedService.siteCode}</Text>
            ) : (
              <Text color={'secondary'}>Not set</Text>
            )}
          </ServDetailsContentRow>
          <ServDetailsContentRow
            label={'Worker selection'}
            subLabel={
              'Are customers allowed to choose their own support worker from those that are available? (By allowing this function, customers will select which worker they want to service them when booking this service.)'
            }
          >
            {selectedService.workerSelectionType === ServiceWorkerSelectionType.SELECTABLE && (
              <Text color={'green'}>
                <Icon type="check" className="mr-x-small" />
                Enabled
              </Text>
            )}
            {selectedService.workerSelectionType === ServiceWorkerSelectionType.ASSIGNED && (
              <Text color={'red'}>
                <Icon type="close" className="mr-x-small" />
                Disabled
              </Text>
            )}
          </ServDetailsContentRow>
          {selectedService.serviceType === ServiceType.GROUP && (
            <ServDetailsContentRow label={'Capacity'} subLabel={'Maximum capacity for each Sessions'}>
              <Text>
                {selectedService.capacity} customer{selectedService.capacity > 1 && 's'} per sessions
              </Text>
            </ServDetailsContentRow>
          )}
          <ServDetailsContentRow label={'Publication Rules'} subLabel={'Where is the Service published.'}>
            <Text>
              {selectedService.isPublicMarketplace ? (
                <Text>
                  Public Marketplace: This service {selectedService.status === 'PUBLISHED' ? 'is' : 'will be'} visible
                  by all GoodHuman user
                </Text>
              ) : (
                <Text>
                  Private Marketplace: This service {selectedService.status === 'PUBLISHED' ? 'is' : 'will be'} only
                  visible by your customers
                </Text>
              )}
            </Text>
          </ServDetailsContentRow>
          <ServDetailsContentRow
            label={'Service Stock Code'}
            subLabel={'Enter a unique code for this service in order to track this service in finance export files'}
          >
            <Text>
              {selectedService.serviceStockNumber ? (
                selectedService.serviceStockNumber
              ) : (
                <>
                  <Tooltip2
                    position="top"
                    content="Without a Stock Number, finance files won't be generated at Payment."
                  >
                    <Icon type="exclamation-circle" style={{ color: 'orange' }} className="mr-x-small" />
                  </Tooltip2>
                  Not set
                </>
              )}
            </Text>
          </ServDetailsContentRow>
          <ServDetailsContentRow
            label={'Scheduled time or Start/Finish time'}
            subLabel={
              'Calculate the line items in a booking based on the bookings scheduled time or workers start and finish time.)'
            }
          >
            {selectedService.isUseWorkerTimeForAutoBilling ? (
              <Text color={'green'}>
                <Icon type="check" className="mr-x-small" />
                Enabled
              </Text>
            ) : (
              <Text color={'red'}>
                <Icon type="close" className="mr-x-small" />
                Disabled
              </Text>
            )}
          </ServDetailsContentRow>
          <ServDetailsContentRow
            label={'Worker time leeway'}
            subLabel={
              'How much time can the worker start/finish a booking outside of the scheduled time without triggering a warning'
            }
          >
            {this.state.workerTimeLeeway} Minute{this.state.workerTimeLeeway === 0 ? '' : 's'}
          </ServDetailsContentRow>
          <ServDetailsContentRow
            label={'Worker time warnings'}
            subLabel={
              <>
                What worker time should the Worker time validation warning be generated for
                <br />
                <HyperlinkButton
                  onClick={() =>
                    CommonUtils.sendToZendesk(
                      portalUser,
                      'https://goodhuman.zendesk.com/hc/en-au/articles/900002394566#h_01F5EXRXXSTRC27WCDZ1QWZWM4'
                    )
                  }
                >
                  How this setting works?
                </HyperlinkButton>
              </>
            }
          >
            {selectedService.bookingTimeWarningSource === BookingErrorTimeSource.RECORD_TIME
              ? 'Recorded time'
              : 'Actual time'}
          </ServDetailsContentRow>

          <ServDetailsContentRow
            label={'Allow worker time modification'}
            subLabel={
              <>
                Allow the worker to modify their start/finish times?
                <br />
                <HyperlinkButton
                  onClick={() =>
                    CommonUtils.sendToZendesk(
                      portalUser,
                      'https://goodhuman.zendesk.com/hc/en-au/articles/900002394566#h_01F5FH9VV7PS3155EXFQEVXFJW'
                    )
                  }
                >
                  How this setting works?
                </HyperlinkButton>
              </>
            }
          >
            {selectedService.canWorkerEditCheckInCheckOutTime ? (
              <Text color={'green'}>Yes</Text>
            ) : (
              <Text color={'red-dark'}>No</Text>
            )}
          </ServDetailsContentRow>
          {/*<ServDetailsContentRow*/}
          {/*  label={'Point of Contact'}*/}
          {/*  subLabel={'Indicates the main point of contact for the service.'}*/}
          {/*>*/}
          {/*  {}*/}
          {/*</ServDetailsContentRow>*/}
        </div>
      );
    } else {
      return (
        <div className="mt-small">
          <Script
            url={'https://maps.googleapis.com/maps/api/js?key=' + globalConfig.googleMapApiKey + '&libraries=places'}
          />
          <AddAddressModal
            closeCreateEditAddressModal={() => {
              this._closeCreateEditAddressModal();
            }}
            isOpen={this.state.isOpen}
            address={location}
            saveCustomerAddress={this._saveCustomerAddress}
          />
          <ActionModal
            isOpen={this.state.isProceedModalOpen}
            onClose={this._closeProceedModal}
            title={'Discard changes'}
            showCloseButton={true}
          >
            <Text className={'mb-medium'}>
              You have <b>unsaved data</b>, proceeding will discard these changes.
            </Text>
            <br />
            <Text className={'mb-medium'}>Do you want to proceed?</Text>
            <ActionModalFooter>
              <PrimaryButton className="mr-medium" size="large" onClick={this._closeProceedModal}>
                Cancel
              </PrimaryButton>
              <GhostButton size="large" onClick={this._changeView}>
                Proceed
              </GhostButton>
            </ActionModalFooter>
          </ActionModal>
          <ActionModal
            isOpen={this.state.isWorkerRecalculationModalOpen}
            onClose={this._closeWorkerRecalculationModal}
            title={'Worker recalculation toggle'}
            showCloseButton={true}
          >
            {this.state.isUseWorkerTimeForAutoBilling ? (
              <div>
                <Paragraph>
                  You are choosing to <b>turn off</b> the worker recalculation toggle. Doing so means that the billings
                  for this service will <b>no longer update</b> based on the workers start/finish time.
                </Paragraph>
                <Paragraph>This may result in some billings being inaccurate.</Paragraph>
              </div>
            ) : (
              <div>
                <Paragraph>
                  You are choosing to <b>turn on</b> the worker recalculation toggle. Doing so means that the billings
                  for support service bookings will <b>update</b> based on the workers start/finish time.
                </Paragraph>
              </div>
            )}
            <Paragraph>Are you sure you wish to continue?</Paragraph>
            <ActionModalFooter>
              <SecondaryButton className="mr-medium" size="large" onClick={this._closeWorkerRecalculationModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._changeWorkerRecalculation}>
                {this.state.isUseWorkerTimeForAutoBilling ? 'Turn off' : 'Turn on'}
              </PrimaryButton>
            </ActionModalFooter>
          </ActionModal>
          <ServDetailsSectionHeader
            title={'Service Settings'}
            subtitle={'Settings that control your service behaviour.'}
          />

          <ServDetailsContentRow
            label={'Publish Date'}
            subLabel={'From when customer will be able to see this service.'}
          >
            <DatePicker
              value={moment.tz(this.state.servicePublishDate, selectedService.timezone)}
              format={'DD/MM/YYYY'}
              allowClear={false}
              onChange={(value) => this.setState({ servicePublishDate: value })}
            />
          </ServDetailsContentRow>

          <ServDetailsContentRow
            label={'Location'}
            subLabel={
              'Does this service take place at a fixed location, or are customers allowed to choose where they would like to consume this service?'
            }
          >
            <Select
              value={this.state.serviceDirection}
              style={{ minWidth: '300px' }}
              onChange={(value) => this.setState({ serviceDirection: value })}
            >
              <Select.Option value={ServiceLocationType.FIXEDVENUE}>Fixed Venue</Select.Option>
              <Select.Option value={ServiceLocationType.CUSTOMERLOCATION}>
                At customer's appointed location
              </Select.Option>
            </Select>
            {this.state.serviceDirection === ServiceLocationType.FIXEDVENUE && (
              <>
                <div className="mv-medium">
                  {location ? (
                    <LocationDisplay location={location.fullAddress} />
                  ) : (
                    <Text color="secondary">No Address selected</Text>
                  )}
                </div>
                <SecondaryButton icon="plus_circle" onClick={this._openCreateEditAddressModal}>
                  {location ? 'Edit location' : 'Add a location'}
                </SecondaryButton>
              </>
            )}
          </ServDetailsContentRow>

          <ServDetailsContentRow
            label={'Site name'}
            subLabel={'Enter a site name for this service in order to track this service in finance export files'}
          >
            <Input
              style={{ width: '250px' }}
              value={this.state.siteName}
              onChange={(value) => {
                this.setState({ siteName: value.target.value });
              }}
            />
          </ServDetailsContentRow>

          <ServDetailsContentRow
            label={'Site code'}
            subLabel={'Enter a site code for this service in order to track this service in finance export files'}
          >
            <Input
              style={{ width: '250px' }}
              value={this.state.siteCode}
              onChange={(value) => {
                this.setState({ siteCode: value.target.value });
              }}
            />
          </ServDetailsContentRow>

          <ServDetailsContentRow
            label={'Worker selection'}
            subLabel={
              'Are customers allowed to choose their own support worker from those that are available? (By allowing this function, customers will select which worker they want to service them when booking this service.)'
            }
          >
            <Switch
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="close" />}
              checked={this.state.workerSelectionType === 'SELECTABLE'}
              onChange={(value) => {
                if (value) {
                  this.setState({ workerSelectionType: 'SELECTABLE' });
                } else {
                  this.setState({ workerSelectionType: 'ASSIGNED' });
                }
              }}
            />
            <span className="ml-small">
              <Text className="text-size-small">
                <b>Allow customers to select a Support Worker when they book this service?</b>
              </Text>
            </span>

            <br />
            <div style={{ display: 'block' }} className="mt-medium">
              <Alert
                message={
                  'The list of support workers available for selection will be from the Roster for this service.'
                }
              />
            </div>
          </ServDetailsContentRow>

          {/* Group Booking  */}
          {selectedService.serviceType === ServiceType.GROUP && (
            <ServDetailsContentRow label={'Capacity'} subLabel={'Maximum capacity for this service.'}>
              <InputNumber
                size="large"
                step={1}
                min={1}
                max={1000}
                style={{ width: '100px' }}
                className="mr-small text-align-center"
                precision={0}
                value={this.state.capacity}
              />{' '}
              Customer(s)
            </ServDetailsContentRow>
          )}

          <ServDetailsContentRow label={'Publication Rules'} subLabel={'Where is the Service Published'}>
            <Select
              defaultValue={this.state.isPublicMarketplaceLabel}
              style={{ minWidth: '300px' }}
              onChange={(value) => {
                this.setState({ isPublicMarketplaceLabel: value });
              }}
            >
              {/* <Select.Option value="PUBLIC">Public Marketplace</Select.Option> */}
              <Select.Option value="PRIVATE">Private Marketplace</Select.Option>
            </Select>
          </ServDetailsContentRow>

          <ServDetailsContentRow
            label={'Service Stock Code'}
            subLabel={'Enter a unique code for this service in order to track this service in finance export files'}
          >
            <Input
              style={{ width: '250px' }}
              value={this.state.serviceStockNumber}
              onChange={(value) => {
                this.setState({ serviceStockNumber: value.target.value });
              }}
            />
          </ServDetailsContentRow>

          <ServDetailsContentRow
            label={'Scheduled time or Start/Finish time'}
            subLabel={
              'Calculate the line items in a booking based on the bookings scheduled time or workers start and finish time.)'
            }
          >
            <RadioGroup onChange={(event) => this.setState({ isWorkerRecalculationModalOpen: event })}>
              <Radio
                style={{ display: 'block', height: '30px', lineHeight: '30px' }}
                value={true}
                checked={!this.state.isUseWorkerTimeForAutoBilling}
              >
                Schedule time
              </Radio>
              <Radio
                style={{ display: 'block', height: '30px', lineHeight: '30px' }}
                value={false}
                checked={this.state.isUseWorkerTimeForAutoBilling}
              >
                Workers start and finish time
              </Radio>
            </RadioGroup>
          </ServDetailsContentRow>

          <ServDetailsContentRow
            label={'Worker time leeway'}
            subLabel={
              'How much time can the worker start/finish a booking outside of the scheduled time without triggering a warning'
            }
          >
            <Select
              defaultValue={this.state.workerTimeLeeway}
              style={{ minWidth: '300px' }}
              onChange={this._onWorkerTimeLeewayChange}
            >
              <Select.Option value={0}>0 Minute</Select.Option>
              <Select.Option value={5}>5 Minutes</Select.Option>
              <Select.Option value={10}>10 Minutes</Select.Option>
              <Select.Option value={15}>15 Minutes</Select.Option>
              <Select.Option value={20}>20 Minutes</Select.Option>
              <Select.Option value={30}>30 Minutes</Select.Option>
            </Select>
          </ServDetailsContentRow>

          <ServDetailsContentRow
            label={'Worker time warnings'}
            subLabel={
              <>
                What worker time should the Worker time validation warning be generated for
                <br />
                <HyperlinkButton
                  onClick={() =>
                    CommonUtils.sendToZendesk(
                      portalUser,
                      'https://goodhuman.zendesk.com/hc/en-au/articles/900002394566#h_01F5EXRXXSTRC27WCDZ1QWZWM4'
                    )
                  }
                >
                  How this setting works?
                </HyperlinkButton>
              </>
            }
          >
            <Select
              defaultValue={this.state.bookingTimeWarningSource}
              style={{ minWidth: '300px' }}
              onChange={this._onBookingTimeWarningSource}
            >
              <Select.Option value={BookingErrorTimeSource.RECORD_TIME}>Recorded time</Select.Option>
              <Select.Option value={BookingErrorTimeSource.ACTUAL_TIME}>Actual time</Select.Option>
            </Select>
          </ServDetailsContentRow>

          <ServDetailsContentRow
            label={'Allow worker time modification'}
            subLabel={
              <>
                Allow the worker to modify their start/finish times?
                <br />
                <HyperlinkButton
                  onClick={() =>
                    CommonUtils.sendToZendesk(
                      portalUser,
                      'https://goodhuman.zendesk.com/hc/en-au/articles/900002394566#h_01F5FH9VV7PS3155EXFQEVXFJW'
                    )
                  }
                >
                  How this setting works?
                </HyperlinkButton>
              </>
            }
          >
            <Switch
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="close" />}
              checked={this.state.canWorkerEditCheckInCheckOutTime}
              onChange={(value) => {
                if (value) {
                  this.setState({ canWorkerEditCheckInCheckOutTime: true });
                } else {
                  this.setState({ canWorkerEditCheckInCheckOutTime: false });
                }
              }}
            />
          </ServDetailsContentRow>

          <ServDetailsContentRow align={'right'} leftColSpan={8} rightColSpan={16}>
            <Button.Group>
              <GhostButton onClick={this._discardChanges}>Discard Changes</GhostButton>

              <PrimaryButton loading={this.state.isLoading} onClick={this._onSubmitChange} icon={'save'}>
                Save
              </PrimaryButton>
            </Button.Group>
          </ServDetailsContentRow>
          {/*<ServDetailsContentRow*/}
          {/*  label={'Point of Contact'}*/}
          {/*  subLabel={'Indicates the main point of contact for the service.'}*/}
          {/*>*/}
          {/*  {}*/}
          {/*</ServDetailsContentRow>*/}
        </div>
      );
    }
  }
}
