import React from 'react';

import _ from 'lodash';
import { connect } from 'react-redux';
import { MessagesHeader } from './components/MessagesHeader';
import { MessagesInput } from './components/MessagesInput';
import MessagesContent from './components/MessagesContent';
import {
  CreateChannelIndicator,
  EmptyMessages,
  FetchMoreIndicator,
  LoadingIndicator,
  NewMessagesIndicator
} from './components/Indicators';

import { INewChannelAttributes } from '../MessagingLandingView';

import {
  CreateChannelRequest,
  IChannel,
  IExtendedMessage,
  IMessage,
  ISubscriber,
  MessageSubscriberType
} from 'interfaces/message-interfaces';

import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { MessageChannelType } from 'utilities/enum-utils';

const initialPaginationOptions = {
  page: 1,
  pageSize: 20,
  pageTimestamp: new Date(),
  hasMore: true
};

interface IMessagePanelProps {
  // redux state
  channelMessages: any;
  channels: IChannel[];

  portalUser: any;

  // redux dispatch
  doGetChannelsForService: any;
  doResetUnreadCount: any;
  doCreateNewChannel: any;

  doGetMessagesForChannel: any;
  doAddNewMessage: any;
  doFetchMoreMessages: any;
  doDeleteMessage: any;

  // passed in props
  currentChannel: IChannel;
  currentService: any;
  currentChannelId: string;
  currentChannelMode: string;
  newChannelAttributes: INewChannelAttributes;
  onChannelCreated: any;
  setMessagingBadgeCount: any;
}

interface IMessagePanelState {
  // dispatch states
  isLoading: boolean;
  isFetchingMore: boolean;
  isDeleting: boolean;
  isSending: boolean;
  isCreatingChannel: boolean;
  hasMore: boolean;

  // Pagination
  page: number;
  pageSize: number;
  pageTimestamp: Date;

  // scroll position
  previousScrollPos: number;

  isScrolledToBottom: boolean;
}

class MessagesBasePanel extends React.PureComponent<IMessagePanelProps, IMessagePanelState> {
  state = {
    isLoading: false,
    isFetchingMore: false,
    isDeleting: false,
    isSending: false,
    isCreatingChannel: false,
    ...initialPaginationOptions,
    previousScrollPos: 0,
    isScrolledToBottom: true
  };

  // Reference to content panel for scrolling triggers.
  contentRef = null;

  // Scroll the content to the bottom.
  scrollToBottom = ({ smooth = false }) => {
    if (this.contentRef) {
      this.contentRef.scrollToBottom({ smooth });
    }
  };

  // Send message
  onSendMessage = async ({ messageText }) => {
    const {
      doAddNewMessage,
      portalUser: { userId: authorUserId },
      currentChannelId: messageChannelId,
      currentChannel,
      doGetChannelsForService,
      currentChannelMode
    } = this.props;

    if (currentChannelMode === 'existing') {
      // -•=== Existing Channel : Send New Message ===•-
      this.setState({ isSending: true });

      await doAddNewMessage({ messageChannelId, text: messageText, authorUserId, scrollToBottom: this.scrollToBottom });

      this.setState({ isSending: false });

      const { serviceId } = currentChannel;

      // refresh main channels once done.
      doGetChannelsForService({ serviceId });
    } else if (currentChannelMode === 'new') {
      // -•=== New Channel : Create New Channel ===•-
      const { portalUser, newChannelAttributes, doCreateNewChannel, currentService, onChannelCreated } = this.props;

      // TODO : Channel type to be expanded later
      const channelType =
        newChannelAttributes.subscriberType === MessageSubscriberType.SupportWorker
          ? MessageChannelType.ProviderToWorker
          : newChannelAttributes.subscriberType === MessageSubscriberType.Customer
          ? MessageChannelType.ProviderToConsumer
          : MessageChannelType.IndividualChat;

      const createRequest: CreateChannelRequest = {
        channelType,
        subscriberType: newChannelAttributes.subscriberType,
        serviceProviderId: portalUser.serviceProviderId,
        serviceId: newChannelAttributes.serviceId,
        subscribers: [newChannelAttributes.userId],
        serviceDepartmentId: currentService.serviceDepartmentId,
        message: { content: { attachments: [], text: messageText }, isAnonymous: false }
      };

      this.setState({ isCreatingChannel: true });

      const newChannel = await doCreateNewChannel(createRequest);

      this.setState({ isCreatingChannel: false });

      onChannelCreated({ channel: newChannel, channelId: newChannel.messageChannelId });
    }
  };

  // Fetch More Messages
  onFetchMoreMessages = async () => {
    const { currentChannelMode } = this.props;

    // fetch more only if current channel mode is existing.
    if (currentChannelMode === 'existing') {
      // Is this still fetching, and are there anymore rows to be fetched...?
      if (!this.state.isFetchingMore && this.state.hasMore) {
        // console.log(this.contentRef.getScrollHeight());
        const currentHeight = this.contentRef.getScrollHeight();

        this.setState({ isFetchingMore: true });

        const {
          currentChannel: { messageChannelId },
          doFetchMoreMessages
        } = this.props;

        const { page, pageSize, pageTimestamp: timeStamp } = this.state;

        let fetchPage = page + 1;
        const fetchRequest = { page: fetchPage, pageSize, timeStamp, messageChannelId };
        const fetchResults = await doFetchMoreMessages(fetchRequest);

        const newHeight = this.contentRef.getScrollHeight();
        this.contentRef.scrollTo(newHeight - currentHeight);

        this.setState({ isFetchingMore: false, page: fetchPage, hasMore: fetchResults.hasMore });
      }
    }
  };

  onDeleteMessage = async ({ messageId }) => {
    const { currentChannelId, doDeleteMessage } = this.props;

    this.setState({ isDeleting: true });
    await doDeleteMessage({ messageChannelId: currentChannelId, messageId });
    this.setState({ isDeleting: false });
  };

  onClickUnreadIndicator = async () => {
    this.scrollToBottom({ smooth: true });
  };

  onNotBottomReached = async () => {
    if (this.state.isScrolledToBottom) {
      this.setState({ isScrolledToBottom: false });
    }
  };

  onBottomReached = async () => {
    const { currentChannelId: messageChannelId, doResetUnreadCount, setMessagingBadgeCount } = this.props;

    doResetUnreadCount({ messageChannelId, callApi: true });
    setMessagingBadgeCount(0);

    this.setState({ isScrolledToBottom: true });
  };

  componentDidMount = () => {};

  componentDidUpdate = async (
    prevProps: Readonly<IMessagePanelProps>,
    prevState: Readonly<IMessagePanelState>,
    snapshot?: any
  ) => {
    // -=== New message retrieved ===-
    if (prevProps.channelMessages !== this.props.channelMessages) {
      // only smooth scroll to the bottom if it's sending.
      if (this.state.isSending) {
        this.scrollToBottom({ smooth: true });
      } else if (this.state.isScrolledToBottom) {
        // smooth scroll to bottom if it's all the way at the bottom.
        this.scrollToBottom({ smooth: true });
      } else {
        // this.contentRef.scrollTo(1000);
      }
    } else if (prevProps.currentChannelId !== this.props.currentChannelId) {
      // -===Channel changed; reload panel. ===-
      if (this.props.currentChannelMode === 'existing' && !_.isEmpty(this.props.currentChannelId)) {
        // Mode => Existing Channel. Retrieve from scratch.
        this.setState({ isLoading: true });

        let timeStamp = new Date();

        // fetch from scratch again.
        const fetchRequest = { ...initialPaginationOptions, timeStamp, messageChannelId: this.props.currentChannelId };

        await this.props.doGetMessagesForChannel(fetchRequest);

        this.setState(
          {
            page: initialPaginationOptions.page,
            pageSize: initialPaginationOptions.pageSize,
            hasMore: true,
            isLoading: false,
            pageTimestamp: timeStamp
          },
          () => this.scrollToBottom({ smooth: false })
        );
      } else {
        // Do what? if new channel...?
      }
    }
  };

  render() {
    const {
      currentChannel: inputChannel,
      channelMessages,
      portalUser,
      currentChannelMode,
      currentChannelId,
      newChannelAttributes
    } = this.props;

    const isEmpty = _.isEmpty(inputChannel);

    const currentChannel: IChannel =
      currentChannelMode === 'existing'
        ? inputChannel
        : {
            unreadCount: 0,
            subscribers: [],
            messageChannelId: '',
            lastMessagedOn: new Date(),
            channelName: newChannelAttributes.userName,
            serviceName: newChannelAttributes.serviceName,
            channelType: MessageChannelType.ProviderToWorker // TODO : Need to figure out worker or customer
          };

    // Empty state; no channel ID passed in.
    if (isEmpty && currentChannelMode === 'existing') {
      return <EmptyMessages />;
    }

    // TODO : Do we need loading...?
    // Loading. Currently fetching messages...
    // if (this.state.isLoading) {
    //   return <LoadingState />;
    // }

    const { channelName, unreadCount, messageChannelId } = currentChannel;

    const rawMessages: IMessage[] = !_.isEmpty(channelMessages) ? channelMessages[messageChannelId] : [];

    const messages: IExtendedMessage[] = _.map(rawMessages, (message) => ({
      ...message,
      currentChannelName: channelName,
      subscriber: _.find(currentChannel.subscribers, (sub: ISubscriber) => sub.userId === message.authorUserId)
    }));

    return (
      <div className="bg-white flex-1 flex-column relative">
        {/* Fetch more loading indicator */}
        {this.state.isFetchingMore && <FetchMoreIndicator />}

        {/* Loading Indicator */}
        {this.state.isLoading && <LoadingIndicator />}

        {/* Creating channel indicator */}
        {this.state.isCreatingChannel && <CreateChannelIndicator />}

        {/* Unread count indicator */}
        {unreadCount > 0 && !this.state.isScrolledToBottom && (
          <NewMessagesIndicator unreadCount={unreadCount} onClick={this.onClickUnreadIndicator} />
        )}

        {/* Message Conversation Header */}
        <MessagesHeader channel={currentChannel} />

        {/* Message Content Section */}
        <MessagesContent
          ref={(c) => (this.contentRef = c)}
          messages={messages}
          currentUser={portalUser}
          isFetchingMore={this.state.isFetchingMore}
          isLoading={this.state.isLoading}
          fetchMoreMessages={this.onFetchMoreMessages}
          deleteMessage={this.onDeleteMessage}
          onBottomReached={this.onBottomReached}
          onNotBottomReached={this.onNotBottomReached}
          currentChannelMode={currentChannelMode}
        />

        {/* Input Box Section */}
        <MessagesInput
          onSendMessage={this.onSendMessage}
          isCreatingChannel={this.state.isCreatingChannel}
          currentChannelId={currentChannelId}
          currentChannelStatus={currentChannel.status}
        />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  channels: state.channelsStore.channels,
  channelMessages: state.messagesStore.channelMessages,

  // Customers
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  // channels Store
  doGetChannelsForService: dispatch.channelsStore.doGetChannelsForService,
  doResetUnreadCount: dispatch.channelsStore.doResetUnreadCount,
  doCreateNewChannel: dispatch.channelsStore.doCreateNewChannel,

  // messages store
  doGetMessagesForChannel: dispatch.messagesStore.doGetMessagesForChannel,
  doAddNewMessage: dispatch.messagesStore.doAddNewMessage,
  doFetchMoreMessages: dispatch.messagesStore.doFetchMoreMessages,
  doDeleteMessage: dispatch.messagesStore.doDeleteMessage
});

const MessagesPanel = connect(
  mapState,
  mapDispatch
)(MessagesBasePanel);

export { MessagesPanel };
