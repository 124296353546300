import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Text } from 'common-components/typography';
import { Icon } from 'antd';
import ActionSelectItem from 'common-components/items/ActionSelectItem';
import { PermissionType, TeamPermissionType } from 'utilities/enum-utils';
import { getTeamPermissionName } from 'views/account-management/utils/permission-utils';
import { IPermissions } from 'interfaces/account-interfaces';

interface ITeamSectionModalProps {
  isOpen: any;
  onClose: any;
  setSelectedOption: (servicePermission) => any;
  permissions?: IPermissions;
}

interface ITeamSectionModalState {
  teamPermission: string;
}

class TeamSectionModal extends Component<ITeamSectionModalProps, ITeamSectionModalState> {
  state = { teamPermission: PermissionType.VIEW_ONLY };

  private _onCloseModal = () => {
    this.props.onClose();
  };

  private _setSelectedOption = async () => {
    await this.props.setSelectedOption({ teamPermission: this.state.teamPermission });
    this._onCloseModal();
  };

  private _changePermissionMode = (newMode) => {
    this.setState({ teamPermission: newMode });
  };

  resetPermissions = () => {
    const teamPermission = getTeamPermissionName(this.props.permissions);
    this.setState({ teamPermission });
  };

  componentDidMount() {
    this.resetPermissions();
  }

  componentDidUpdate(
    prevProps: Readonly<ITeamSectionModalProps>,
    prevState: Readonly<ITeamSectionModalState>,
    snapshot?: any
  ) {
    if (this.props.isOpen !== prevProps.isOpen) {
      if (this.props.isOpen) {
        this.resetPermissions();
      }
    }
  }

  render() {
    const { teamPermission } = this.state;

    return (
      <ActionModal
        title={
          <>
            <Icon type={'team'} className={'mr-small text-size-x2-large'} />
            Team section
          </>
        }
        isOpen={this.props.isOpen}
        onClose={this._onCloseModal}
        width="medium"
      >
        <div className="mv-medium anim-slide-left">
          <div className={'mb-x-large'}>
            <div className={'mb-large'}>
              <Text size={'x-large'}>General permissions</Text>
            </div>

            <ActionSelectItem
              selectedAction={teamPermission}
              value={TeamPermissionType.VIEW_ONLY}
              title={'View only (default)'}
              description={'Can only see basic team member information'}
              onSetAction={this._changePermissionMode}
            />
            <ActionSelectItem
              selectedAction={teamPermission}
              value={TeamPermissionType.MANAGER}
              title={'Manager'}
              description={'Can see and perform all actions within this section'}
              onSetAction={this._changePermissionMode}
            />
          </div>

          <ActionModalFooter>
            <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._setSelectedOption}>
              Save changes
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      </ActionModal>
    );
  }
}

export default TeamSectionModal;
