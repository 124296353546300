import React, { Component } from 'react';
import { Text, Title, Paragraph } from 'common-components/typography';
import { Col, notification, Row, Empty, Icon } from 'antd';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import ActionModal from 'common-components/modal/ActionModal';
import _ from 'lodash';
import { Spinner } from '@blueprintjs/core';
interface IUnavailableTimesProps {
  hasEditPermission: boolean;
  unavailableTimes: any[];
  openUnavailableModal: (selectedTime?: any) => void;
  doDeleteUnavailableTime: typeof dispatch.teamStore.doDeleteUnavailableTime;
  isLoading: boolean;
}

interface IUnavailableTimesState {
  showDeleteConfirm: boolean;
  selectedDeleteItem: any;
  isLoading: boolean;
}

const weekDayParser = (number) => {
  switch (number) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return '';
  }
};

const renderFullDayItems = (list, unavailableItem) => {
  const fullDayItems = _.filter(list, (day) => day.unavailabilityOption === 0);
  const daysString = _.map(fullDayItems, (day) => weekDayParser(day.weekDayId)).join(' & ');

  return (
    !_.isEmpty(fullDayItems) && (
      <>
        <Text className="mb-x2-small text-size-regular">Full day(s)</Text>
        <Text className="mb-large text-size-regular">
          Every {daysString}{' '}
          <Text type="secondary" className="text-size-regular">
            (until {moment(unavailableItem.endDate).format('DD MMM YYYY')})
          </Text>
        </Text>
      </>
    )
  );
};

const renderAvailableItems = (list, unavailableItem) => {
  const daysString = _.map(list, (day) => weekDayParser(day.weekDayId)).join(' & ');

  return (
    !_.isEmpty(list) && (
      <>
        <Text className="mb-x2-small text-size-regular">Available all day</Text>
        <Text className="mb-large text-size-regular">
          Every {daysString}{' '}
          <Text type="secondary" className="text-size-regular">
            (until {moment(unavailableItem.endDate).format('DD MMM YYYY')})
          </Text>
        </Text>
      </>
    )
  );
};

const renderSpecificItems = (list, unavailableItem) => {
  const specificItems = _.filter(list, (day) => day.unavailabilityOption !== 0);
  const timeRangeLengthEqualOne = _.filter(specificItems, (day) => day.timeRange.length === 1);
  const theRest = _.filter(specificItems, (day) => day.timeRange.length !== 1);
  const singleTimeRangeList = {};

  _.forEach(timeRangeLengthEqualOne, (day) => {
    const timeRange = day.timeRange;

    const hourString =
      moment.tz(timeRange[0].startTime, unavailableItem.timezone).format('h:mm a') +
      ' - ' +
      moment.tz(timeRange[0].endTime, unavailableItem.timezone).format('h:mm a');

    if (singleTimeRangeList[hourString]) {
      const newList = [...singleTimeRangeList[hourString]];
      newList.push(weekDayParser(day.weekDayId));
      singleTimeRangeList[hourString] = newList;
    } else {
      singleTimeRangeList[hourString] = [weekDayParser(day.weekDayId)];
    }
  });

  return !_.isEmpty(specificItems) ? (
    <>
      {_.map(theRest, (day) => (
        <>
          <Text className="mb-x2-small text-size-regular">
            {_.map(day.timeRange, (time, index) => (
              <Text key={index} className="text-size-regular block">
                {moment.tz(time.startTime, unavailableItem.timezone).format('h:mm a')} -{' '}
                {moment.tz(time.endTime, unavailableItem.timezone).format('h:mm a')}
              </Text>
            ))}
          </Text>
          <Text className="mb-large text-size-regular">
            Every {weekDayParser(day.weekDayId)}{' '}
            <Text type="secondary" className="text-size-regular">
              (until {moment(unavailableItem.endDate).format('DD MMM YYYY')})
            </Text>
          </Text>
        </>
      ))}
      {_.map(Object.keys(singleTimeRangeList), (timeRange) => (
        <>
          <Text className="mb-x2-small text-size-regular">{timeRange}</Text>
          <Text className="mb-large text-size-regular">
            Every {singleTimeRangeList[timeRange].join(' & ')}{' '}
            <Text type="secondary" className="text-size-regular">
              (until {moment(unavailableItem.endDate).format('DD MMM YYYY')})
            </Text>
          </Text>
        </>
      ))}
    </>
  ) : (
    <></>
  );
};

const singleDayRender = (list, unavailableItem) => {
  return !_.isEmpty(list) ? (
    <>
      <Text className="mb-x2-small text-size-regular">
        {list[0].unavailabilityOption === 0
          ? 'Full day(s)'
          : list[0].unavailabilityOption === 1
          ? 'Available all day'
          : _.map(list[0].timeRange, (time, index) => (
              <Text key={index} className="text-size-regular block">
                {moment.tz(time.startTime, unavailableItem.timezone).format('h:mm a')} -{' '}
                {moment.tz(time.endTime, unavailableItem.timezone).format('h:mm a')}
              </Text>
            ))}
      </Text>
      <Text className="mb-large text-size-regular">
        {weekDayParser(list[0].weekDayId)}{' '}
        <Text className="text-size-regular">
          {moment.tz(unavailableItem.endDate, unavailableItem.timezone).format('DD MMM YYYY')}
        </Text>
      </Text>
    </>
  ) : (
    <></>
  );
};

const renderUnavailableDateTime = (item) => {
  switch (item.unavailabilityType) {
    case 1:
      return !(item.startTime && item.endTime) ? (
        <>
          <Text className="text-size-regular">Full day(s)</Text>
          <Text className="text-size-regular">
            {moment.tz(item.startDate, item.timezone).format('ddd, DD MMM YYYY')}{' '}
            <Text className="text-color-secondary text-size-regular">to</Text>{' '}
            {moment.tz(item.endDate, item.timezone).format('ddd, DD MMM YYYY')}
          </Text>
        </>
      ) : (
        <Text className="text-size-regular">
          {moment.tz(item.startTime, item.timezone).format('h:mm a')}{' '}
          {moment.tz(item.startDate, item.timezone).format('ddd, DD MMM YYYY')}{' '}
          <Text className="text-color-secondary text-size-regular">to</Text>{' '}
          {moment.tz(item.endTime, item.timezone).format('h:mm a')}{' '}
          {moment.tz(item.endDate, item.timezone).format('ddd, DD MMM YYYY')}
        </Text>
      );
    case 2:
      const timeListWithoutAvailable = _.filter(
        item.customUnavailabilityWeekDays,
        (day) => day.unavailabilityOption !== 1
      );

      const availableTimeList = _.filter(item.customUnavailabilityWeekDays, (day) => day.unavailabilityOption === 1);

      const period = moment(item.endDate).diff(item.startDate, 'days');

      return period > 0 ? (
        <>
          {renderFullDayItems(timeListWithoutAvailable, item)}
          {renderSpecificItems(timeListWithoutAvailable, item)}
          {renderAvailableItems(availableTimeList, item)}
        </>
      ) : (
        <>{singleDayRender(item.customUnavailabilityWeekDays, item)}</>
      );

    default:
      return <></>;
  }
};

const UnavailableItems = ({ item, onEdit, onDelete }) => {
  let itemTitle = 'Unavailable';
  if (item.unavailabilityType === 2) {
    const availableTimeList = _.filter(item.customUnavailabilityWeekDays, (day) => day.unavailabilityOption === 1);
    if (availableTimeList.length === item.customUnavailabilityWeekDays.length) {
      itemTitle = 'Available';
    }
  }
  return (
    <div
      className="pv-12 ph-medium rounded-big mb-medium bg-white"
      style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.05)' }}
    >
      <div className="flex-row justify-between">
        <Text className="text-size-large" weight="bold">
          {itemTitle}
        </Text>
        <div className="action flex-row">
          <Text
            className="text-color-blue-action cursor-pointer text-size-regular"
            onClick={() => {
              onEdit(item);
            }}
          >
            Edit
          </Text>
          <Text
            className="text-color-red cursor-pointer text-size-regular ml-medium"
            onClick={() => {
              onDelete(item);
            }}
          >
            Delete
          </Text>
        </div>
      </div>
      <div className="date-time mt-small flex-column">{renderUnavailableDateTime(item)}</div>
      {item.comment && (
        <div className="description mt-small text-color-secondary text-size-regular">{item.comment}</div>
      )}
    </div>
  );
};

class UnavailableTimes extends Component<IUnavailableTimesProps, IUnavailableTimesState> {
  state = {
    showDeleteConfirm: false,
    selectedDeleteItem: null,
    isLoading: false
  };

  private _addNewUnavailableTime = () => {
    this.props.openUnavailableModal();
  };

  private _editUnavailableTime = (item) => {
    this.props.openUnavailableModal(item);
  };

  private _deleteUnavailableTime = async () => {
    const { selectedDeleteItem } = this.state;
    this.setState({ isLoading: true });

    try {
      const result = await this.props.doDeleteUnavailableTime({
        unavailabilityId: selectedDeleteItem.supportWorkerUnavailabilityId
      });
      notification.success({
        message: 'Unavailability deleted',
        description: 'You have successfully deleted an unavailabilty for this team member.'
      });
      this.setState({ showDeleteConfirm: false, isLoading: false });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ showDeleteConfirm: false, isLoading: false });
    }
  };

  private _closeActionModal = () => {
    this.setState({ showDeleteConfirm: false });
  };

  private _openActionModal = (item) => {
    this.setState({ showDeleteConfirm: true, selectedDeleteItem: item });
  };

  render() {
    const { hasEditPermission, unavailableTimes, isLoading } = this.props;

    return (
      <div className="pv-large ph-medium rounded-big shadow-container bg-quaternary">
        <ActionModal
          isOpen={this.state.showDeleteConfirm}
          title="Delete unavailability"
          onClose={this._closeActionModal}
          width="medium"
        >
          <Paragraph>You're about to cancel this team member's scheduled unavailability.</Paragraph>
          <Paragraph>Are you sure? </Paragraph>
          <div className={'mt-large'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._closeActionModal}>
                No
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._deleteUnavailableTime}>
                Yes
              </PrimaryButton>
            </Row>
          </div>
        </ActionModal>
        <Row type={'flex'} justify={'space-between'} align={'top'} className="mb-12">
          <Col>
            <Title level={4} className="m-none">
              Scheduled unavailability
            </Title>
          </Col>
          <Col className={'align-center'}>
            {hasEditPermission && (
              <div className="text-color-blue-action cursor-pointer" onClick={this._addNewUnavailableTime}>
                <Icon type="plus" /> Add
              </div>
            )}
          </Col>
        </Row>
        <div className="mb-medium">
          <Text type="secondary">Schedule upcoming unavailability, remove or change dates anytime.</Text>
        </div>
        {isLoading ? (
          <Spinner size={80} />
        ) : unavailableTimes.length === 0 ? (
          <>
            <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            <div className="text-align-center text-color-secondary text-size-x2-large">No scheduled unavailability</div>
          </>
        ) : (
          unavailableTimes.map((availableTime, index) => (
            <UnavailableItems
              key={index}
              item={availableTime}
              onEdit={this._editUnavailableTime}
              onDelete={this._openActionModal}
            />
          ))
        )}
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doDeleteUnavailableTime: dispatch.teamStore.doDeleteUnavailableTime
});

export default connect(
  null,
  mapDispatch
)(UnavailableTimes);
