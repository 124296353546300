import { Tooltip2 } from '@blueprintjs/popover2';
import { Checkbox, Empty, Input, notification, Skeleton, Tabs } from 'antd';
import { IconButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FilterSection } from 'common-components/filter';
import { BottomActionSheet } from 'common-components/Sheets/BottomActionSheet';
import { ItemCountSheet } from 'common-components/Sheets/ItemCountSheet';
import { SubTitle, Text, Title } from 'common-components/typography';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { FilterType, PaymentSources, ServiceAgreementStatus } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';
import { ndisHelper } from 'variables/data-helpers';
import globalConfig from 'variables/global-config';
import ExportLineItemActionModal from 'views/billings/payments/components/ExportLineItemActionModal';
import PreviewInvoicesModal from 'views/billings/payments/components/PreviewInvoicesModal';
import PaymentCustomerRow from '../components/details/PaymentCustomerRow';
import EditLineItemActionModal from '../components/EditLineItemActionModal';
import ProcessPaymentActionModal from '../components/ProcessPaymentActionModal';
import RejectPaymentActionModal from '../components/RejectPaymentActionModal';
import WaiveLineItemActionModel from '../components/WaiveLineItemActionModel';

const { Search } = Input;

interface ListPanelProps {
  paymentsList?: typeof state.billingsStore.paymentsList;
  overviewPayments?: typeof state.billingsStore.overviewPayments;
  paymentsFilter?: typeof state.billingsStore.paymentsFilter;
  paymentsSelectedAll?: typeof state.billingsStore.paymentsSelectedAll;
  selectedBillingLineItem?: typeof state.billingsStore.selectedBillingLineItem;
  portalUser: typeof state.authStore.portalUser;
  currentFilterConfig?: any;
  history?: any;
  isServiceProviderVCPEnabled: boolean;
  doFetchPayments: typeof dispatch.billingsStore.doFetchWaitingPayments;
  doFetchOverview: typeof dispatch.billingsStore.doFetchOverviewWaitingPayments;
  setPayments?: typeof dispatch.billingsStore.setPayments;
  setOverviewPayments?: typeof dispatch.billingsStore.setOverviewPayments;
  setPaymentsFilter?: typeof dispatch.billingsStore.setPaymentsFilter;
  setSelectedBillingLineItem?: typeof dispatch.billingsStore.setSelectedBillingLineItem;
  setPaymentsSelectedAll?: typeof dispatch.billingsStore.setPaymentsSelectedAll;
  doFetchFundedCategories?: typeof dispatch.billingsStore.doFetchFundedCategories;
  doFetchServiceAgreements: typeof dispatch.customersStore.doFetchServiceAgreements;
  doFetchServiceAgreementDetails: typeof dispatch.customersStore.doFetchServiceAgreementDetails;
}

interface ListPanelState {
  topHeight: number;
  showFilters: boolean;
  showActionSheet: boolean;
  checkAllIndicator: boolean;
  indeterminateCheck: boolean;
  isLoading: boolean;
  isSearching: boolean;
  searchString: string;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  action: string;
  openAction: boolean;
  openEditAction: boolean;
  filters: any;
  displayInvoicePreview: boolean;
  paymentSourceType: string;
  availableFilters: Array<any>;
  serviceAgreementServiceBillingLineItems: Array<any>;
}

const PaymentEmptyState = () => (
  <div className="flex-1 bg-white align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No Payments found.
    </Text>{' '}
    <br /> <br />
    <Text color="secondary">All payments under this filter will appear here.</Text>
    <Text color="secondary">Try adjusting your filter, or clicking on another view.</Text>
  </div>
);

class PayListDetailsSection extends PureComponent<ListPanelProps, ListPanelState> {
  // topHeight is used to control sticky
  state = {
    topHeight: 0,
    showFilters: false,
    showActionSheet: false,
    checkAllIndicator: false,
    indeterminateCheck: false,
    isLoading: false,
    isSearching: false,
    searchString: '',
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    action: '',
    openAction: false,
    openEditAction: false,
    filters: [],
    displayInvoicePreview: false,
    paymentSourceType: PaymentSources.NDIS,
    availableFilters: [FilterType.DATE_RANGE, FilterType.CUSTOMER, FilterType.PAYMENT_METHODS],
    serviceAgreementServiceBillingLineItems: []
  };

  // ref element for the header; used to determine the height of the header
  _headerElement = null;

  _openActionModal = async ({ action, additionalData }) => {
    const {
      paymentsList,
      overviewPayments,
      setSelectedBillingLineItem,
      doFetchFundedCategories,
      doFetchServiceAgreements,
      doFetchServiceAgreementDetails
    } = this.props;
    const { paymentSourceType } = this.state;
    let serviceAgreementServiceBillingLineItems = [];
    if (action === 'REJECT_LINEITEM' || action === 'WAIVE') {
      setSelectedBillingLineItem(additionalData);
    } else if (action === 'EDIT') {
      // TODO: duplicate with BookingBillingPanel
      const newBillingLineItem = _.cloneDeep(additionalData);
      const ndisSupportItem =
        paymentSourceType === PaymentSources.NDIS &&
        ndisHelper.getBySupportItemNumber(newBillingLineItem.supportItemNumber);
      newBillingLineItem.ruleType = paymentSourceType === PaymentSources.NDIS && ndisSupportItem.DateType;
      newBillingLineItem.isEditing = true;
      newBillingLineItem.supportItemNumberArray = [newBillingLineItem.supportItemNumber];
      newBillingLineItem.claimType = newBillingLineItem.claimType === '' ? 'STD' : newBillingLineItem.claimType;

      setSelectedBillingLineItem(newBillingLineItem);

      // Find userId
      const currentBillingItem = _.filter(
        overviewPayments,
        (payment) => payment.bookingBillingLineItemId === newBillingLineItem.bookingBillingLineItemId
      );
      const userId = currentBillingItem[0].userId;
      // Set serviceAgreementServiceBillingLineItems
      try {
        const result = await Promise.all([
          doFetchFundedCategories({ bookingId: additionalData.attendanceId }),
          doFetchServiceAgreements({ userId: userId })
        ]);

        const serviceAgreements: any = result[1];
        const activeServiceAgreement = _.find(
          serviceAgreements,
          (agreement) =>
            agreement.status === ServiceAgreementStatus.ACTIVE ||
            agreement.status === ServiceAgreementStatus.EXPIRING_SOON
        );
        if (activeServiceAgreement) {
          const serviceAgreementDetails: any = await doFetchServiceAgreementDetails({
            customerUserId: userId,
            serviceAgreementId: activeServiceAgreement.userServiceAgreementId
          });

          const serviceDetails = _.find(
            serviceAgreementDetails.services,
            (service) => service.serviceId === newBillingLineItem.serviceId
          );
          serviceAgreementServiceBillingLineItems = serviceDetails ? serviceDetails.lineItems : [];
        }
      } catch (e) {
        notification.error({
          message: 'Oops, something went wrong. Please try again.'
        });
        throw e;
      }
    } else if (action === 'PROCESS_ALL' || action === 'PREVIEW_ALL') {
      const newSelectedBillingLineItem = [];
      paymentsList.map((payment) =>
        _.map(payment.billingItems, (lineItem) => {
          if (!lineItem.isError)
            newSelectedBillingLineItem.push({
              firstName: payment.firstName,
              lastName: payment.lastName,
              userId: payment.userId,
              ...lineItem
            });
        })
      );
      await setSelectedBillingLineItem(newSelectedBillingLineItem);
    } else if (action === 'PROCESS' || action === 'PROCESS_ADDITIONAL') {
      const newSelectedBillingLineItem = [];
      _.map(paymentsList, (payment) =>
        _.map(payment.billingItems, (lineItem) => {
          if (lineItem.selected)
            newSelectedBillingLineItem.push({
              firstName: payment.firstName,
              lastName: payment.lastName,
              userId: payment.userId,
              ...lineItem
            });
        })
      );
      await setSelectedBillingLineItem(newSelectedBillingLineItem);
    }
    this.setState({
      action,
      openAction: true,
      serviceAgreementServiceBillingLineItems
    });
  };

  private _formatFilterQuery = (appliedFilters = this.props.paymentsFilter) => {
    const requestFilter: any = {};
    _.forEach(appliedFilters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'startDate':
            requestFilter.startDate = filter.values[0].toDate();
            requestFilter.endDate = filter.values[1].toDate();
            break;
          case 'paymentMethods':
            requestFilter.paymentMethods = filter.values;
            break;
          case 'customerUserIds':
            requestFilter.customerUserIds = _.map(filter.values, (customer) => {
              return customer.value;
            });
            break;
          case 'search':
            requestFilter.search = filter.values;
            break;
        }
      }
    });
    return requestFilter;
  };

  private _changeTab = (e) => {
    this.props.setPaymentsFilter([]);
    this.setState(
      {
        paymentSourceType: e,
        availableFilters:
          e === PaymentSources.NDIS
            ? [FilterType.DATE_RANGE, FilterType.CUSTOMER, FilterType.PAYMENT_METHODS]
            : [FilterType.DATE_RANGE, FilterType.CUSTOMER]
      },
      () => this._refreshListings()
    );
  };

  getActionModal = () => {
    if (this.state.action === 'REJECT_LINEITEM') {
      return RejectPaymentActionModal;
    } else if (this.state.action === 'PROCESS' || this.state.action === 'PROCESS_ALL') {
      return ProcessPaymentActionModal;
    } else if (this.state.action === 'EDIT') {
      return EditLineItemActionModal;
    } else if (this.state.action === 'WAIVE') {
      return WaiveLineItemActionModel;
    } else if (this.state.action === 'EXPORT') {
      return ExportLineItemActionModal;
    } else if (this.state.action === 'PROCESS_ADDITIONAL' || this.state.action === 'PREVIEW_ALL') {
      return PreviewInvoicesModal;
    } else {
      return () => <></>;
    }
  };

  private _closeActionModal = () => {
    this._refreshGlobalCheckboxes();
    this.setState({ openAction: false });
  };

  _handleHeaderHeight = () => {
    if (this._headerElement) {
      this.setState({ topHeight: this._headerElement.offsetHeight - 1 });
    }
  };

  _checkCustomer = (userId) => {
    const { paymentsList, setPayments } = this.props;

    // Toggle selected
    const newPaymentsList = paymentsList.map((payment) => {
      let isIndeterminate = false;
      if (payment.userId === userId) {
        payment.billingItems = payment.billingItems.map((lineItem) => {
          if (!lineItem.isError) {
            return { ...lineItem, selected: !payment.checkAllIndicator };
          } else {
            if (!payment.checkAllIndicator && !isIndeterminate) isIndeterminate = true;
            return { ...lineItem };
          }
        });

        return { ...payment, checkAllIndicator: !payment.checkAllIndicator, indeterminateCheck: isIndeterminate };
      } else {
        return { ...payment };
      }
    });

    const someRowsHaveCheck = newPaymentsList.some(
      (b) => b.checkAllIndicator === true || b.indeterminateCheck === true
    );
    const indeterminateCheck = someRowsHaveCheck ? !newPaymentsList.every((b) => b.checkAllIndicator === true) : false;
    const checkAllIndicator = someRowsHaveCheck ? newPaymentsList.every((b) => b.checkAllIndicator === true) : false;

    setPayments(newPaymentsList);

    this.setState({ showActionSheet: someRowsHaveCheck, indeterminateCheck, checkAllIndicator });
  };

  _checkItem = (bookingBillingLineItemId) => {
    const { paymentsList, setPayments } = this.props;

    // Toggle selected
    const newPaymentsList = paymentsList.map((payment) => {
      const newBillingItems = payment.billingItems.map((lineItem) => {
        if (lineItem.bookingBillingLineItemId === bookingBillingLineItemId) {
          return { ...lineItem, selected: !lineItem.selected };
        } else {
          return { ...lineItem };
        }
      });

      const someRowsHaveCheck = newBillingItems.some((b) => b.selected === true);
      const indeterminateCheck = someRowsHaveCheck && !newBillingItems.every((b) => b.selected === true);
      const checkAllIndicator = newBillingItems.every((b) => b.selected === true);

      return { ...payment, billingItems: newBillingItems, someRowsHaveCheck, indeterminateCheck, checkAllIndicator };
    });

    const someRowsHaveCheck = newPaymentsList.some(
      (b) => b.checkAllIndicator === true || b.indeterminateCheck === true
    );
    const indeterminateCheck = someRowsHaveCheck ? !newPaymentsList.every((b) => b.checkAllIndicator === true) : false;
    const checkAllIndicator = someRowsHaveCheck ? newPaymentsList.every((b) => b.checkAllIndicator === true) : false;

    setPayments(newPaymentsList);

    this.setState({ showActionSheet: someRowsHaveCheck, indeterminateCheck, checkAllIndicator });
  };

  _onCheckAll = () => {
    const { checkAllIndicator } = this.state;
    const { paymentsList, setPayments } = this.props;

    let isIndeterminate = false;

    if (_.isEmpty(paymentsList)) {
      return;
    }

    const newCheckAll = !checkAllIndicator;
    const newSelectedBookings = paymentsList.map((b) => {
      let isCustomerIndeterminate = false;
      if (b.billingItems.every((line) => line.isError)) {
        if (!checkAllIndicator) isIndeterminate = true;
        return { ...b };
      } else {
        const newBillingItems = b.billingItems.map((c) => {
          if (c.isError) {
            if (!b.checkAllIndicator && !isCustomerIndeterminate) isCustomerIndeterminate = true;
            return { ...c };
          } else {
            return { ...c, selected: newCheckAll };
          }
        });
        if (isCustomerIndeterminate) isIndeterminate = true;
        return {
          ...b,
          billingItems: newBillingItems,
          checkAllIndicator: newCheckAll,
          indeterminateCheck: isCustomerIndeterminate
        };
      }
    });

    setPayments(newSelectedBookings);

    this.setState({
      checkAllIndicator: newCheckAll,
      showActionSheet: newCheckAll,
      indeterminateCheck: isIndeterminate
    });
  };

  _onActionDeselect = () => {
    const { paymentsList, setPayments } = this.props;

    const newSelectedBookings = _.map(paymentsList, (payment) => {
      const uncheckedBillingItems = _.map(payment.billingItems, (lineItem) => {
        return { ...lineItem, selected: false };
      });

      return { ...payment, billingItems: uncheckedBillingItems, indeterminateCheck: false, checkAllIndicator: false };
    });

    this.setState({ showActionSheet: false, indeterminateCheck: false, checkAllIndicator: false });

    setPayments(newSelectedBookings);
  };

  private _refreshGlobalCheckboxes = () => {
    const { paymentsList } = this.props;

    const someRowsHaveCheck = paymentsList.some((b) => b.checkAllIndicator === true || b.indeterminateCheck === true);
    const indeterminateCheck = someRowsHaveCheck ? !paymentsList.every((b) => b.checkAllIndicator === true) : false;
    const checkAllIndicator = someRowsHaveCheck ? paymentsList.every((b) => b.checkAllIndicator === true) : false;

    this.setState({
      showActionSheet: someRowsHaveCheck,
      indeterminateCheck: indeterminateCheck,
      checkAllIndicator: checkAllIndicator
    });
  };

  private _applySearchFilter = async (searchStr) => {
    const { paymentsFilter, setPaymentsFilter } = this.props;
    let newPlanPaymentsFilter = _.clone(paymentsFilter);
    const existingSearchIndex = _.findIndex(newPlanPaymentsFilter, (filter: any) => filter.filter === 'search');
    if (existingSearchIndex > -1) {
      if (searchStr === '') {
        newPlanPaymentsFilter.splice(existingSearchIndex, 1);
      } else {
        newPlanPaymentsFilter[existingSearchIndex].values = searchStr;
      }
    } else {
      newPlanPaymentsFilter.push({ filter: 'search', values: searchStr });
    }
    setPaymentsFilter(newPlanPaymentsFilter);
    this.setState({ isSearching: false });
  };

  _searchText = (txt) => {
    this._applySearchFilter(txt);
    this.setState({ isSearching: false, searchString: txt });
  };

  _debounceSearch = _.debounce(this._searchText, 500);

  _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });

    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setPaymentsFilter(filters);
  };

  _refreshListings = async () => {
    const { doFetchPayments, doFetchOverview, paymentsFilter, setPayments } = this.props;
    const { paymentSourceType } = this.state;
    this.setState({ isLoading: true, checkAllIndicator: false, indeterminateCheck: false, showActionSheet: false });
    this.setState({ isLoading: false, pageTimestamp: new Date(), page: 1 });

    setPayments([]);
    await doFetchOverview({ ...this._formatFilterQuery(), paymentSourceType });
    await doFetchPayments({
      ...this._formatFilterQuery(),
      paymentSourceType
    });
    this.setState({ isLoading: false });
  };

  //region Component Lifecycle Methods
  componentDidMount = async () => {
    this._handleHeaderHeight();
    const { doFetchPayments, doFetchOverview, currentFilterConfig, paymentsFilter } = this.props;
    const { paymentSourceType } = this.state;

    let appliedFilters = _.isEmpty(paymentsFilter) ? currentFilterConfig.filters : this._formatFilterQuery();

    await this.setState({ isLoading: true });
    await doFetchOverview({ ...this._formatFilterQuery(), paymentSourceType });
    await doFetchPayments({ ...appliedFilters, paymentSourceType });
    this.setState({ isLoading: false });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { currentFilterConfig, setPaymentsFilter, doFetchPayments } = this.props;

    if (prevState.showFilters !== this.state.showFilters) {
      this._handleHeaderHeight();
    }

    if (prevProps.currentFilterConfig.key !== currentFilterConfig.key) {
      this.setState({
        isLoading: true,
        searchString: '',
        checkAllIndicator: false,
        indeterminateCheck: false,
        showActionSheet: false,
        showFilters: false
      });
      await setPaymentsFilter(currentFilterConfig.filters);
    }

    if (prevProps.paymentsFilter !== this.props.paymentsFilter) {
      this.setState({ isLoading: true, page: 1 });
      await doFetchPayments({
        ...this._formatFilterQuery(),
        paymentSourceType: this.state.paymentSourceType,
        page: this.state.page,
        pageSize: this.state.pageSize,
        pageTimestamp: this.state.pageTimestamp
      });
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { paymentsList, overviewPayments, paymentsFilter, selectedBillingLineItem, portalUser, history } = this.props;

    let TargetActionModal: any = this.getActionModal();

    const filteredOverviewPayments = overviewPayments.filter((payment) => !payment.isFiltered);

    const { currentFilterConfig } = this.props;

    const canProcess = PermissionUtils.validatePermission(
      'ProcessPaymentBillingLineItem',
      portalUser.permissions.permissionRoles
    );

    const selectedLineItem = _.filter(
      _.map(paymentsList, (payment) => {
        return _.filter(payment.billingItems, (lineItem) => lineItem.selected);
      }),
      (filtered) => filtered.length
    );

    return (
      <div className="bg-white flex-1 width-full flex-column" style={{ overflowY: 'auto', position: 'relative' }}>
        <TargetActionModal
          isOpen={this.state.openAction}
          onClose={this._closeActionModal}
          deselect={this._onActionDeselect}
          selectedBillingLineItem={selectedBillingLineItem}
          displayInvoicePreview={this.state.displayInvoicePreview}
          paymentSourceType={this.state.paymentSourceType}
          serviceAgreementServiceBillingLineItems={this.state.serviceAgreementServiceBillingLineItems}
        />

        {/* Do NOT remove this container div. It's required for Safari sticky to work. Why it works, I have no idea.*/}
        <div>
          <div className="booking-header" ref={(com) => (this._headerElement = com)}>
            {/* Header */}
            <div className="flex-row justify-between align-center">
              <div>
                <Title level={3} className="mv-none" lineHeight={150}>
                  {currentFilterConfig.title}
                </Title>
                <Text color="secondary">{currentFilterConfig.description}</Text>
              </div>
              <div className="flex-column align-center">
                <div className="pr-x-small">
                  {this.props.portalUser &&
                    globalConfig.theBridgeProviderId === this.props.portalUser.serviceProviderId && (
                      <Tooltip2 content="Export the line items in CSV" position="bottom">
                        <IconButton
                          color="white"
                          onClick={() => this._openActionModal({ action: 'EXPORT', additionalData: null })}
                          iconColor="black"
                          size="large"
                          disabled={paymentsList.length === 0}
                          icon="download"
                          className={'mr-small'}
                        />
                      </Tooltip2>
                    )}
                  <Tooltip2 content="Refresh this view" position="bottom">
                    <IconButton
                      color="white"
                      onClick={this._refreshListings}
                      iconColor="black"
                      size="large"
                      icon="reload"
                    />
                  </Tooltip2>
                </div>
              </div>
            </div>

            <Tabs defaultActiveKey={'ALL'} animated={true} onChange={this._changeTab} className={'mt-large'}>
              <Tabs.TabPane tab={PaymentSources.NDIS} key={PaymentSources.NDIS} />
              {this.props.isServiceProviderVCPEnabled && (
                <Tabs.TabPane tab={PaymentSources.VCP} key={PaymentSources.VCP} />
              )}
            </Tabs>

            <div className="ph-large pv-medium bg-quaternary mt-medium mb-large rounded" style={{ margin: 'auto' }}>
              <div className="flex-row justify-between">
                <div className="flex-row">
                  <div style={{ width: '150px' }}>
                    <SubTitle>Bookings</SubTitle>
                    <div className="text-size-x2-large" style={{ lineHeight: '26px' }}>
                      {
                        _(filteredOverviewPayments)
                          .groupBy('attendanceId')
                          .keys()
                          .value().length
                      }
                    </div>
                  </div>
                  <div style={{ width: '150px' }}>
                    <SubTitle>Customers</SubTitle>
                    <div className="text-size-x2-large" style={{ lineHeight: '26px' }}>
                      {
                        _(filteredOverviewPayments)
                          .groupBy('userId')
                          .keys()
                          .value().length
                      }
                    </div>
                  </div>
                  <div style={{ width: '200px' }}>
                    <SubTitle>Total</SubTitle>
                    <div className="text-size-x2-large" style={{ lineHeight: '26px' }}>
                      {CommonUtils.formatPrice(
                        _.reduce(
                          filteredOverviewPayments,
                          (total, billingLineItem) => {
                            return Number(total) + Number(billingLineItem.billingTotal);
                          },
                          0
                        )
                      )}
                    </div>
                  </div>
                </div>
                {/* Flex stretch doesnt work with ant design Col, couldn't vertical align middle */}
                <div className="text-align-right width-full" style={{ marginTop: '5px' }}>
                  {canProcess && (
                    <>
                      <SecondaryButton
                        size="large"
                        disabled={filteredOverviewPayments.length <= 0}
                        className={'mv-x-small'}
                        onClick={() => this._openActionModal({ action: 'PREVIEW_ALL', additionalData: null })}
                      >
                        Preview Invoices
                      </SecondaryButton>
                      <PrimaryButton
                        size="large"
                        className={'ml-medium mv-x-small'}
                        disabled={filteredOverviewPayments.length <= 0}
                        onClick={() => this._openActionModal({ action: 'PROCESS_ALL', additionalData: null })}
                      >
                        Process All
                      </PrimaryButton>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="flex-row pb-medium align-center justify-between">
              <div className="mr-x2-large" style={{ minWidth: '300px' }}>
                <Search
                  onChange={this._onEnterSearchText}
                  loading={this.state.isSearching}
                  defaultValue={paymentsFilter.search ? paymentsFilter.search : ''}
                  placeholder="Search for customer..."
                  allowClear={true}
                />
              </div>
              <FilterSection
                availableFilters={this.state.availableFilters}
                filters={paymentsFilter ? paymentsFilter : []}
                onChangeFilter={this._onChangeFilter}
                displayTimezone={portalUser.timezone}
                containerClassName={'mv-small'}
              />
            </div>
          </div>

          <table className="payment-listing">
            <thead>
              <tr>
                <th className="nowrap check-all" style={{ top: `${this.state.topHeight}px` }}>
                  {canProcess && (
                    <Checkbox
                      onClick={this._onCheckAll}
                      checked={this.state.checkAllIndicator}
                      indeterminate={this.state.indeterminateCheck}
                    />
                  )}
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <SubTitle>Customer</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                  <SubTitle>Line Items (Bookings)</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap text-align-right">
                  <SubTitle containerClassName="text-align-right">Total</SubTitle>
                </th>
                <th style={{ top: `${this.state.topHeight}px` }} className="nowrap" />
              </tr>
            </thead>

            <tbody>
              {this.state.isLoading && (
                <tr style={{ borderBottom: '0px solid !important' }}>
                  <td colSpan={7}>
                    <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                  </td>
                </tr>
              )}

              {!this.state.isLoading && _.isEmpty(paymentsList) && (
                <tr style={{ cursor: 'default' }}>
                  <td colSpan={7} style={{ borderBottom: '0px solid' }}>
                    <PaymentEmptyState />
                  </td>
                </tr>
              )}
              {!this.state.isLoading &&
                _.map(paymentsList, (paymentItem, index) => (
                  <PaymentCustomerRow
                    paymentItem={paymentItem}
                    onCheckCustomer={this._checkCustomer}
                    onCheckItem={this._checkItem}
                    history={history}
                    key={index}
                    openActionModal={this._openActionModal}
                    canProcess={canProcess}
                  />
                ))}
            </tbody>
          </table>
        </div>

        {/* Filler */}
        <div className="flex-1 bg-white">&nbsp;</div>

        {this.state.showActionSheet && selectedLineItem && selectedLineItem.length > 0 && (
          <BottomActionSheet
            paymentsList={selectedLineItem}
            onDeselect={this._onActionDeselect}
            refreshPaymentListings={this._refreshListings}
            openActionModal={this._openActionModal}
            hasAdditionalButton={true}
            additionalButtonLabel={`Preview Invoices`}
          />
        )}

        {!this.state.isLoading && !this.state.showActionSheet && paymentsList.length > 0 && (
          <ItemCountSheet itemCount={paymentsList.length} itemLabel="Customer" />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBillingLineItem: state.billingsStore.selectedBillingLineItem
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedBillingLineItem: dispatch.billingsStore.setSelectedBillingLineItem,
  doFetchFundedCategories: dispatch.billingsStore.doFetchFundedCategories,
  setPayments: dispatch.billingsStore.setPayments,
  setOverviewPayments: dispatch.billingsStore.setOverviewPayments,
  doFetchServiceAgreements: dispatch.customersStore.doFetchServiceAgreements,
  doFetchServiceAgreementDetails: dispatch.customersStore.doFetchServiceAgreementDetails
});

export default connect(mapState, mapDispatch)(PayListDetailsSection);
