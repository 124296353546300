import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { ErrorSVG } from 'assets/UndrawSVG';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Spinner } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState, dispatch } from 'stores/rematch/root-store';
import { Col, Divider, Radio, Row } from 'antd';
import { EditRecurringMode, ShiftSlotStatus } from 'utilities/enum-utils';
import _ from 'lodash';
import * as H from 'history';
import { ConflictResult } from 'views/bookings/components/BookingConfictItem';

interface IRecurringBookingConfirmShiftForWorkerActionState {
  step: number;
  canManuallyClose: boolean;
  title: string;
  isLoading: boolean;
  selectedOption: EditRecurringMode;
  confirmedShiftConflicts: any;
  pendingShiftConflicts: any;
}

interface IRecurringBookingConfirmShiftForWorkerActionProps {
  selectedBooking: any;
  doConfirmRecurringShiftForWorker: typeof dispatch.bookingsStore.doConfirmRecurringShiftForWorker;
  onClose: any;
  doCheckAssignWorker: typeof dispatch.bookingsStore.doCheckAssignWorker;
  history: H.History;
  isOpen: boolean;
}

class RecurringBookingConfirmShiftForWorkerActionModel extends Component<
  IRecurringBookingConfirmShiftForWorkerActionProps,
  IRecurringBookingConfirmShiftForWorkerActionState
> {
  state = {
    step: 1,
    canManuallyClose: true,
    title: 'Confirm worker',
    selectedOption: EditRecurringMode.Current,
    isLoading: false,
    confirmedShiftConflicts: null,
    pendingShiftConflicts: null
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _goToBooking = (bookingId) => {
    const { history } = this.props;
    history.push(`/bookings/details/${bookingId}`);
  };

  private _goToSession = (serviceId, serviceDateTime) => {
    const { history } = this.props;
    history.push(`/group-service/${serviceId}/session/details/${serviceDateTime}`);
  };

  private _renderView = () => {
    const { step, selectedOption, confirmedShiftConflicts, pendingShiftConflicts } = this.state;
    const { selectedBooking } = this.props;
    const workerName = `${selectedBooking.workerFirstName} ${selectedBooking.workerLastName}`;

    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <div>
            <Paragraph>
              The booking you are editing is part of a recurring booking series. Please select one of the following
              options for editing this booking.
            </Paragraph>
          </div>
          <Radio.Group value={selectedOption} onChange={this._onChangeOption} className="ml-medium">
            <Radio
              value={EditRecurringMode.Current}
              className={`${selectedOption === EditRecurringMode.Current && 'text-weight-bold'} mb-medium `}
            >
              <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                Confirm {workerName} as the worker for this booking only.
              </div>
            </Radio>
            <br />
            <Radio
              value={EditRecurringMode.CurrentAll}
              className={`${selectedOption === EditRecurringMode.CurrentAll && 'text-weight-bold'} mb-medium `}
            >
              <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                Confirm {workerName} as the worker for this booking and all following bookings that are awaiting
                confirmation.
              </div>
            </Radio>
            <Radio
              value={EditRecurringMode.Upcoming}
              className={`${selectedOption === EditRecurringMode.Upcoming && 'text-weight-bold'} mb-medium `}
            >
              <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                Confirm {workerName} as the worker for this booking and all upcoming bookings that are awaiting
                confirmation.
              </div>
            </Radio>
          </Radio.Group>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._checkForConflict} loading={this.state.isLoading}>
              Confirm
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <div className="text-align-center">
            <div className="pv-large">
              <Spinner size={150} />
            </div>

            <Paragraph>Confirming this booking, won't be long...</Paragraph>
          </div>
        </div>
      );
    }

    if (step === 3) {
      let additionalText;
      console.log('selectedOptions', selectedOption);
      switch (selectedOption) {
        case EditRecurringMode.Current:
          additionalText = '';
          break;
        case EditRecurringMode.CurrentAll:
          additionalText = ' and all following bookings in the recurring series';
          break;
        case EditRecurringMode.Upcoming:
          additionalText = ' and all upcoming bookings in the recurring series';
          break;
      }
      return (
        <div className="anim-fade-in">
          <div className="text-align-left">
            <Paragraph>
              You have successfully confirmed {workerName} for this booking{additionalText}.
            </Paragraph>
            {(confirmedShiftConflicts || pendingShiftConflicts) && (
              <Paragraph>The team member has also been removed from conflicting bookings.</Paragraph>
            )}
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 4) {
      return (
        <div className="anim-fade-in">
          <div className="pv-medium">
            <img src={ErrorSVG} alt="ERROR" style={{ width: '100%', height: '200px' }} />
          </div>
          <div className="text-align-center">
            <Paragraph>Oops something has gone wrong, please try again</Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Go Back to Booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (this.state.step === 5) {
      return (
        <>
          <div className="mb-medium">
            <Text>
              The selected team member cannot be confirmed for the following shifts as they are <b>confirmed</b> for the
              following shift(s) at the same time.
            </Text>
          </div>
          <Row className="text-color-secondary text-uppercase pt-medium">
            <Col span={12} className="ph-medium">
              <SubTitle>Booking in selected series</SubTitle>
            </Col>
            <Col span={12} className="ph-medium">
              <SubTitle>Conflicting session/booking</SubTitle>
            </Col>
          </Row>
          <Divider className="mv-medium mb-none" />
          <div style={{ overflow: 'auto', maxHeight: '35vh' }} className={'mb-large'}>
            {_.map(confirmedShiftConflicts, (conflict) => (
              <ConflictResult
                conflict={conflict}
                timezone={selectedBooking.timezone}
                goToBooking={this._goToBooking}
                goToSession={this._goToSession}
              />
            ))}
          </div>
          <div className={'mb-small'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onAccept}>
                Continue
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (this.state.step === 6) {
      return (
        <>
          <div className="mb-medium">
            <Paragraph>
              The selected team member is assigned as <b>pending</b> to the following shift(s) that have time conflicts
              with the shift you are attempting to assign them to.
            </Paragraph>
            <Paragraph>
              If you elect to continue the team member will be removed from the following conflicting shifts.
            </Paragraph>
          </div>
          <Row className="text-color-secondary text-uppercase pt-medium">
            <Col span={12} className="ph-medium">
              <SubTitle>Booking in selected series</SubTitle>
            </Col>
            <Col span={12} className="ph-medium">
              <SubTitle>Conflicting session/booking</SubTitle>
            </Col>
          </Row>
          <Divider className="mv-medium mb-none" />
          <div style={{ overflow: 'auto', maxHeight: '35vh' }} className={'mb-large'}>
            {_.map(pendingShiftConflicts, (conflict) => (
              <ConflictResult
                conflict={conflict}
                timezone={selectedBooking.timezone}
                goToBooking={this._goToBooking}
                goToSession={this._goToSession}
              />
            ))}
          </div>
          <div className={'mb-small'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onAccept}>
                Continue
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
  };

  private _checkForConflict = async () => {
    const { doCheckAssignWorker, selectedBooking } = this.props;

    this.setState({ isLoading: true });

    try {
      const result: any = await doCheckAssignWorker({
        bookingRequestId: selectedBooking.bookingRequestId,
        bookingId: selectedBooking.bookingId,
        editRecurringMode: this.state.selectedOption,
        workerId: selectedBooking.workerId,
        startDateTime: selectedBooking.startDateTime
      });
      if (result && result.data.confirmedShiftConflicts && result.data.confirmedShiftConflicts.length > 0) {
        this.setState({
          confirmedShiftConflicts: result.data.confirmedShiftConflicts,
          pendingShiftConflicts: result.data.pendingShiftConflicts,
          step: 5,
          isLoading: false
        });
      } else if (result && result.data.pendingShiftConflicts && result.data.pendingShiftConflicts.length > 0) {
        this.setState({
          confirmedShiftConflicts: result.data.confirmedShiftConflicts,
          pendingShiftConflicts: result.data.pendingShiftConflicts,
          step: 6,
          isLoading: false
        });
      } else {
        this._onAccept();
      }
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ step: 5 });
    }
  };

  private _onAccept = async () => {
    const { doConfirmRecurringShiftForWorker, selectedBooking } = this.props;
    this.setState({ step: 2, canManuallyClose: false });
    try {
      await doConfirmRecurringShiftForWorker({
        bookingId: selectedBooking.bookingId,
        bookingRequestId: selectedBooking.bookingRequestId,
        editRecurringMode: this.state.selectedOption,
        isRemovePendingShiftSlots: true
      });
      this._onComplete();
    } catch (e) {
      this._onError();
    }
  };

  private _onComplete = () => {
    this.setState({ step: 3, canManuallyClose: true, title: 'Worker Successfully confirmed' });
  };

  private _onError = () => {
    this.setState({ step: 4, canManuallyClose: true });
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    this.setState(
      {
        step: 1,
        title: 'Confirm worker',
        isLoading: false,
        confirmedShiftConflicts: null,
        pendingShiftConflicts: null
      },
      onClose
    );
  };

  render() {
    let { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title={this.state.title}
        width="medium"
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this._renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBooking: state.bookingsStore.selectedBookingItem
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doConfirmRecurringShiftForWorker: dispatch.bookingsStore.doConfirmRecurringShiftForWorker,
  doCheckAssignWorker: dispatch.bookingsStore.doCheckAssignWorker
});

export default connect(
  mapState,
  mapDispatch
)(RecurringBookingConfirmShiftForWorkerActionModel);
