import React, { Component } from 'react';
import { Icon } from 'antd';

import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface TermsConditionsEditorProps {
  contentHtml: string;
  minHeight?: string;
}

interface TermsConditionsEditorState {
  isEditorFullscreen: boolean;
  editorState: any;
}

class FullscreenToolbarButton extends Component<any, any> {
  render() {
    const { onClick, isFullscreen } = this.props;

    return (
      <div className="rdw-fullscreen-wrapper">
        <div className="rdw-option-wrapper" title={'Fullscreen'} onClick={onClick}>
          {isFullscreen ? <Icon type="fullscreen-exit" /> : <Icon type="fullscreen" />}
        </div>
      </div>
    );
  }
}

class TermsConditionsEditor extends Component<TermsConditionsEditorProps, TermsConditionsEditorState> {
  state = {
    isEditorFullscreen: false,
    editorState: EditorState.createEmpty()
  };

  _onClickEditorFullscreen = () => {
    this.setState({ isEditorFullscreen: !this.state.isEditorFullscreen });
  };

  _onEditorStateChange = (editorState) => {
    const contentState = editorState.getCurrentContent();

    this.setState({
      editorState
    });
  };

  validate = () => {
    const content = this.state.editorState.getCurrentContent();
    return {
      isValid: true,
      content: content.getPlainText().trim().length === 0 ? null : draftToHtml(convertToRaw(content))
    };
  };

  componentDidMount = () => {
    let editorState = EditorState.createEmpty();

    const contentBlock = htmlToDraft(this.props.contentHtml);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      editorState = EditorState.createWithContent(contentState);
    }

    this.setState({
      editorState
    });
  };

  render() {
    return (
      <>
        <Editor
          editorState={this.state.editorState}
          onEditorStateChange={this._onEditorStateChange}
          wrapperClassName={this.state.isEditorFullscreen ? 'draft-editor-wrapper-fullscreen' : 'draft-editor-wrapper'}
          editorClassName={this.state.isEditorFullscreen ? 'draft-editor-fullscreen' : 'draft-editor'}
          toolbarCustomButtons={[
            <FullscreenToolbarButton
              onClick={this._onClickEditorFullscreen}
              isFullscreen={this.state.isEditorFullscreen}
            />
          ]}
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history'],
            inline: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['bold', 'italic', 'underline']
            }
          }}
          placeholder={'Please fill the terms & conditions here.'}
          editorStyle={{ lineHeight: '125%', minHeight: this.props.minHeight ? this.props.minHeight : '0px' }}
        />
      </>
    );
  }
}

export default TermsConditionsEditor;
