import React, { Component } from 'react';
import { Form, Input, notification, Select, Spin } from 'antd';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch } from 'stores/rematch/root-store';
import { SubTitle, Text } from 'common-components/typography';
import { ICustomer } from 'interfaces/customer-interfaces';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import CareLevelSelector from 'views/customers/details/components/CareLevelSelector';
import Utils from 'utilities/Utils';
import { AlertLevel, CareInformationType } from 'utilities/enum-utils';

const { TextArea } = Input;

interface ICreateEditDisabilityModalProps extends FormComponentProps {
  closeCreateEditDisabilityModal: () => void;
  isOpen: boolean;
  isNew: boolean;
  selectedCustomer: ICustomer;
  selectedDisability: any;
  doCreateCustomerCareInfo: typeof dispatch.customersStore.doCreateCustomerCareInfo;
  doUpdateCustomerCareInfo: typeof dispatch.customersStore.doUpdateCustomerCareInfo;
  doFetchCustomerDisabilityList?: typeof dispatch.customersStore.doFetchCustomerDisabilityList;
}

interface ICreateEditDisabilityModalState {
  isLoadingFilter: boolean;
  isActionModalOpen: boolean;
  selectedLevel: string;
  disabilityList: any;
  isSearching: boolean;
  isLoading: boolean;
}

class CreateEditDisabilityModal extends Component<ICreateEditDisabilityModalProps, ICreateEditDisabilityModalState> {
  state = {
    isLoadingFilter: false,
    isActionModalOpen: false,
    selectedLevel: '',
    disabilityList: [],
    isSearching: false,
    isLoading: false
  };

  private _closeWithActionModal = () => {
    const { form, selectedDisability, isNew } = this.props;
    const { selectedLevel } = this.state;
    let hasChanged = false;
    const formValues = form.getFieldsValue();

    // Check if it's an Add or Edit modal
    if (isNew) {
      if (selectedLevel || !Utils.isEmpty(formValues.disability) || !Utils.isEmpty(formValues.description)) {
        hasChanged = true;
      }
    } else if (
      selectedLevel !== selectedDisability.alertLevel ||
      formValues.description !== selectedDisability.description
    ) {
      hasChanged = true;
    }

    if (hasChanged) {
      this.setState({ isActionModalOpen: true });
    } else {
      this._closeActionCreateModal();
    }
  };

  private _closeActionCreateModal = () => {
    this.setState({
      isActionModalOpen: false,
      isLoadingFilter: false,
      isSearching: false,
      isLoading: false,
      disabilityList: [],
      selectedLevel: _.isEmpty(this.props.selectedDisability)
        ? ''
        : AlertLevel[this.props.selectedDisability.alertLevel]
    });
    this.props.closeCreateEditDisabilityModal();
  };

  private _changeLevel = (level) => {
    const { form } = this.props;
    form.setFieldsValue({ alertLevel: level });
    this.setState({ selectedLevel: level });
  };

  private _submitDisability = async () => {
    const {
      form,
      doCreateCustomerCareInfo,
      doUpdateCustomerCareInfo,
      isNew,
      selectedCustomer,
      selectedDisability
    } = this.props;
    const { selectedLevel } = this.state;

    this.setState({ isLoading: true });

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      const formValues = form.getFieldsValue();
      try {
        const payload = isNew
          ? {
              careInformationType: CareInformationType.PERMANENT,
              disabilityLookupId: formValues.disability,
              alertLevel: AlertLevel[selectedLevel],
              description: formValues.description,
              userId: selectedCustomer.userId
            }
          : {
              careInformationType: CareInformationType.PERMANENT,
              careInformationId: selectedDisability.careInformationId,
              alertLevel: AlertLevel[selectedLevel],
              description: formValues.description,
              userId: selectedCustomer.userId
            };
        isNew ? await doCreateCustomerCareInfo(payload) : await doUpdateCustomerCareInfo(payload);
        notification.success({ message: `Disability ${isNew ? 'added' : 'updated'} successfully.` });
        this._closeActionCreateModal();
      } catch (e) {
        notification.error({ message: 'Oops something went wrong! Please try again.' });
      }
    }
    this.setState({ isLoading: false });
  };

  private _searchText = async (txt) => {
    const { selectedCustomer, doFetchCustomerDisabilityList } = this.props;
    const result = await doFetchCustomerDisabilityList({
      userId: selectedCustomer.userId,
      searchString: txt
    });
    this.setState({ isSearching: false, disabilityList: result });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    if (e.length >= 1) {
      this.setState({ isSearching: true });
      this._debounceSearch(e);
    } else if (e.length === 0) {
      this.setState({ disabilityList: [] });
    }
  };

  componentDidUpdate(
    prevProps: Readonly<ICreateEditDisabilityModalProps>,
    prevState: Readonly<ICreateEditDisabilityModalState>,
    snapshot?: any
  ) {
    if (prevProps.selectedDisability !== this.props.selectedDisability) {
      this.setState({
        selectedLevel: this.props.selectedDisability ? AlertLevel[this.props.selectedDisability.alertLevel] : ''
      });
    }
  }

  render() {
    const { form, selectedDisability, isNew } = this.props;
    const { selectedLevel, disabilityList, isSearching, isLoading } = this.state;
    const { getFieldDecorator } = form;

    return (
      <div>
        <ActionModal
          isOpen={this.state.isActionModalOpen}
          onClose={this._closeActionCreateModal}
          title={'Discard changes'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, proceeding will discard these changes.
          </Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton
              className="mr-medium"
              size="large"
              onClick={() => {
                this.setState({ isActionModalOpen: false });
              }}
            >
              Cancel
            </PrimaryButton>
            <GhostButton size="large" onClick={this._closeActionCreateModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this._closeWithActionModal}
          title={
            isNew ? (
              <>
                Add a <b>Permanent Condition</b>
              </>
            ) : (
              <>
                Edit a <b>Condition</b>
              </>
            )
          }
          showCloseButton={true}
          verticalAlignment={'center'}
          width={'medium'}
        >
          <div className="mb-large">
            {isNew ? (
              <Text>
                Search and select a <b>condition</b> from the list provided.
              </Text>
            ) : (
              <SubTitle>Condition</SubTitle>
            )}
            {isNew ? (
              <Form.Item className="mb-large">
                {getFieldDecorator('disability', {
                  rules: [{ required: true, message: 'Condition required' }]
                })(
                  <Select
                    showSearch={true}
                    placeholder="Enter a condition name"
                    notFoundContent={isSearching ? <Spin size="small" /> : null}
                    onSearch={this._onEnterSearchText}
                    filterOption={false}
                    style={{ width: '400px' }}
                  >
                    {_.map(disabilityList, (disability) => (
                      <Select.Option key={disability.disabilityLookupId}>{disability.disabilityName}</Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            ) : (
              selectedDisability.name
            )}
          </div>
          <div className="mb-large">
            <div className="flex-row align-center mb-x-small">
              <SubTitle>ALERT LEVEL</SubTitle>
              <Text className="ml-x-small" size="regular">
                (Select one)
              </Text>
            </div>
            <CareLevelSelector selectedLevel={selectedLevel} changeLevel={this._changeLevel} />
            <Form.Item className="p-none">
              {getFieldDecorator('alertLevel', {
                rules: [{ required: true, message: 'Choose one alert level' }],
                initialValue: selectedLevel
              })(<Input type="hidden" />)}
            </Form.Item>
          </div>
          <div className="mb-x-large">
            <div className="flex-row align-center mb-x-small">
              <SubTitle>Description</SubTitle>
              <Text className="ml-x-small" size="regular">
                (Optional)
              </Text>
            </div>
            <Form.Item className="mb-large">
              {getFieldDecorator('description', {
                rules: [],
                initialValue: selectedDisability ? selectedDisability.description : null
              })(<TextArea placeholder={'Describe this condition, or leave a remark about this condition here.'} />)}
            </Form.Item>
          </div>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._closeWithActionModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._submitDisability} loading={isLoading}>
              {isNew ? 'Add Condition' : 'Save Condition'}
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateCustomerCareInfo: dispatch.customersStore.doCreateCustomerCareInfo,
  doUpdateCustomerCareInfo: dispatch.customersStore.doUpdateCustomerCareInfo,
  doFetchCustomerDisabilityList: dispatch.customersStore.doFetchCustomerDisabilityList
});

export default connect(
  null,
  mapDispatch
)(Form.create<ICreateEditDisabilityModalProps>()(CreateEditDisabilityModal));
