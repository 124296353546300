import { Popover2, Tooltip2 } from '@blueprintjs/popover2';
import { Avatar, Col, Icon } from 'antd';
import PinnedAlertIcon from 'common-components/pinned-alerts/PinnedAlertIcon';
import { ActivityRecordStatusTag, BookingTypeIcon, StatusTag } from 'common-components/tags';
import { TimezoneIndicator } from 'common-components/timezone';
import { Text } from 'common-components/typography';
import UserAvatar from 'common-components/user-avatar';
import { timeZone } from 'interfaces/timezone-type';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { PureComponent } from 'react';
import CommonUtils from 'utilities/common-utils';
import { BookingStatus, BookingType, ServiceType, ShiftSlotStatus } from 'utilities/enum-utils';
import BookingFilterShortcuts from 'views/bookings/new-listings/components/details/BookingFilterShortcuts';
import BookingErrorIndicator from './BookingErrorIndicator';

export class FieldItem extends PureComponent<{
  bookingItem: any;
  fieldName: any;
  timezone: timeZone;
  showTimezoneIndicator: boolean;
  openRecurringListModal?: (e, bookingItem) => void;
  setFilter?: (filters) => void;
}> {
  render() {
    const { bookingItem, fieldName, timezone } = this.props;
    const displayTimezone = timezone ? timezone : bookingItem.timezone;

    const startDateTime = moment.tz(bookingItem.startDateTime, displayTimezone);
    const endDateTime = moment.tz(bookingItem.endDateTime, displayTimezone);

    const dateLineText = !startDateTime.isSame(endDateTime, 'day')
      ? !startDateTime.isSame(endDateTime, 'month')
        ? !startDateTime.isSame(endDateTime, 'year')
          ? startDateTime.format("ddd D MMM 'YY") + ' - ' + endDateTime.format("ddd D MMM 'YY")
          : startDateTime.format('ddd D MMM') + ' - ' + endDateTime.format("ddd D MMM 'YY")
        : startDateTime.format('ddd D') + ' - ' + endDateTime.format("ddd D MMM 'YY")
      : startDateTime.format("ddd D MMM 'YY");

    switch (fieldName) {
      case 'RECEIVED_DATE':
        return (
          <td className="fluid-cell">
            <Text style={{ whiteSpace: 'nowrap' }}>{moment(bookingItem.createdOn).fromNow()}</Text>
          </td>
        );
      case 'BOOKING_DATE':
        return (
          <>
            <td className="ph-small" style={{ width: '16px' }}>
              {bookingItem.status === BookingStatus.ARCHIVED ? (
                <Tooltip2 position="top" content={'This booking has been archived.'}>
                  <Icon type="cloud-server" className="ml-small text-color-secondary" />
                </Tooltip2>
              ) : (
                bookingItem.isRecurring && (
                  <Tooltip2 position="top" content={'This booking is part of a recurring series.'}>
                    <Icon
                      type="history"
                      className="ml-small text-color-secondary"
                      // Edit by Jir : Temporarily disabled the recurring modal due to it not working properly
                      // onClick={(e) => this.props.openRecurringListModal(e, bookingItem)}
                    />
                  </Tooltip2>
                )
              )}
            </td>
            <td className="fluid-cell">
              <div className="pr-small">
                <Text style={{ whiteSpace: 'nowrap' }} weight="bold" size="regular">
                  {dateLineText}
                </Text>
                <br />
                <div className="flex-row">
                  <Text size="large" color="secondary" style={{ whiteSpace: 'nowrap' }}>
                    {startDateTime.format('h:mm a') + ' – ' + endDateTime.format('h:mm a')}
                  </Text>
                  {/* Show timezone */}
                </div>
                {this.props.showTimezoneIndicator && (
                  <TimezoneIndicator showTzName={false} showAbbr={false} timezone={bookingItem.timezone} />
                )}
              </div>
            </td>
          </>
        );
      case 'BOOKINGS':
        const dateLineBookingRemoveTeamMemberText = !startDateTime.isSame(endDateTime, 'day')
          ? !startDateTime.isSame(endDateTime, 'month')
            ? !startDateTime.isSame(endDateTime, 'year')
              ? startDateTime.format('D MMM YYYY') + ' - ' + endDateTime.format('D MMM YYYY')
              : startDateTime.format('D MMM') + ' - ' + endDateTime.format('D MMM YYYY')
            : startDateTime.format('D') + ' - ' + endDateTime.format('D MMM YYYY')
          : startDateTime.format('D MMM YYYY');
        return (
          <>
            <td className="fluid-cell">
              <Text size={'large'} color={'blue-action'} weight={'bold'}>
                {bookingItem.serviceName}
              </Text>
              <br />
              <div className="pr-small">
                <Text style={{ whiteSpace: 'nowrap' }} size="regular">
                  {dateLineBookingRemoveTeamMemberText}
                  {', '}
                  {startDateTime.format('H:MM A') + ' – ' + endDateTime.format('H:MM A')}
                </Text>
                {this.props.showTimezoneIndicator && (
                  <TimezoneIndicator showTzName={false} showAbbr={false} timezone={bookingItem.timezone} />
                )}
              </div>
            </td>
          </>
        );
      case 'CUSTOMER':
        return (
          <td className="ellipsis">
            <Avatar icon="user" className="mr-small" src={bookingItem.attachmentUrl} />
            {`${bookingItem.customerFirstName} ${bookingItem.customerLastName}`}
            {bookingItem.pinnedAlertNumber > 0 && (
              <PinnedAlertIcon
                pinnedAlertNumber={bookingItem.pinnedAlertNumber}
                customerUserId={bookingItem.bookerUserId}
              />
            )}
          </td>
        );
      case 'SERVICE':
        return (
          <td className="ellipsis">
            <Popover2
              position={'bottom-right'}
              interactionKind="hover"
              usePortal={false}
              content={
                <div className={'p-medium'} style={{ minWidth: '290px' }}>
                  <Text size={'x-large'} color={'secondary'} weight={'bold'}>
                    {bookingItem.serviceName}
                  </Text>
                  <br />
                  <Text>
                    <i>
                      {bookingItem.bookingType === BookingType.ACTIVITY_RECORD
                        ? 'Activity record'
                        : bookingItem.serviceType === ServiceType.GROUP
                        ? 'Group service'
                        : 'Support service'}
                    </i>
                  </Text>
                  <br />
                  <br />
                  {bookingItem.serviceType === ServiceType.GROUP && (
                    <>
                      {bookingItem.scheduleName &&
                        bookingItem.description &&
                        bookingItem.description !== bookingItem.scheduleName && (
                          <div className={'mb-small'}>
                            <Text color={'tertiary'}>Schedule</Text>
                            <br />
                            <Text>{bookingItem.scheduleName}</Text>
                          </div>
                        )}
                      <div>
                        <Text color={'tertiary'}>Session</Text>
                        <br />
                        <Text>
                          {bookingItem.description
                            ? bookingItem.description
                            : moment.tz(bookingItem.sessionStartDateTime, timezone).format('h:mm a') +
                              ' - ' +
                              moment.tz(bookingItem.sessionEndDateTime, timezone).format('h:mm a')}
                        </Text>
                      </div>
                      <br />
                    </>
                  )}
                  <div className={'flex-row align-center'}>
                    <Text weight={'bold'}>Status: </Text>
                    {bookingItem.bookingType === BookingType.ACTIVITY_RECORD ? (
                      <>
                        <ActivityRecordStatusTag
                          size="small"
                          className={'ml-small'}
                          paymentStatus={bookingItem.paymentStatus}
                        />
                      </>
                    ) : (
                      <StatusTag className={'ml-small'} status={bookingItem.status} size="small" />
                    )}
                  </div>
                </div>
              }
            >
              <div className={'flex-row align-center mb-x-small'}>
                <BookingTypeIcon
                  type={
                    bookingItem.bookingType === BookingType.ACTIVITY_RECORD
                      ? BookingType.ACTIVITY_RECORD
                      : bookingItem.serviceType
                  }
                  className={'mr-small'}
                />
                <div>{`${bookingItem.serviceName}`}</div>
              </div>
            </Popover2>
            <br />
            {bookingItem.bookingType === BookingType.ACTIVITY_RECORD ? (
              <>
                <ActivityRecordStatusTag size="small" paymentStatus={bookingItem.paymentStatus} />
              </>
            ) : (
              <StatusTag status={bookingItem.status} size="small" />
            )}
          </td>
        );
      case 'PAYMENT_STATUS':
        return <td className="ellipsis">{bookingItem.paymentStatus}</td>;
      case 'WORKER':
        return (
          <td className="ellipsis">
            {bookingItem.serviceType === ServiceType.GROUP ? (
              ''
            ) : !_.isEmpty(bookingItem.workerFirstName) ? (
              <Col className="flex justify-start align-start">
                <UserAvatar
                  className="mr-small"
                  avatarSrc={bookingItem.workerAttachmentUrl}
                  icon={
                    bookingItem.shiftSlotStatus === ShiftSlotStatus.CONFIRMED
                      ? 'check-circle'
                      : bookingItem.shiftSlotStatus === ShiftSlotStatus.PENDING
                      ? 'clock-circle'
                      : null
                  }
                  iconClassName={
                    bookingItem.shiftSlotStatus === ShiftSlotStatus.CONFIRMED
                      ? 'text-color-blue-lighter'
                      : 'text-color-warning-orange'
                  }
                  tooltipContent={
                    <>
                      {bookingItem.workerFirstName} {bookingItem.workerLastName} has{' '}
                      {bookingItem.shiftSlotStatus === ShiftSlotStatus.CONFIRMED ? (
                        <b>confirmed</b>
                      ) : (
                        <b>not confirmed</b>
                      )}{' '}
                      this shift.
                    </>
                  }
                />
                {`${bookingItem.workerFirstName} ${bookingItem.workerLastName}`}
              </Col>
            ) : bookingItem.shiftSlotStatus === ShiftSlotStatus.PUBLISHING ? (
              <>
                <Icon type="wifi" className="text-color-violet-light mr-x-small" style={{ fontSize: 20 }} /> Published
              </>
            ) : (
              <Text color="red-darker">No worker assigned</Text>
            )}
          </td>
        );
      case 'TOTAL':
        return (
          <td style={{ textAlign: 'right' }} className="fluid-cell">
            {CommonUtils.formatPrice(bookingItem.total)}
          </td>
        );
      case 'ALERTS':
        return (
          <td>
            {_.isEmpty(bookingItem.bookingErrors) ? (
              <></>
            ) : (
              <BookingErrorIndicator bookingErrors={bookingItem.bookingErrors} />
            )}
          </td>
        );
      case 'FILTER_SHORTCUT':
        return (
          <td style={{ textAlign: 'right' }} className="mr-small fluid-cell" onClick={(e) => e.stopPropagation()}>
            <BookingFilterShortcuts bookingItem={bookingItem} setFilter={this.props.setFilter} />
          </td>
        );
      default:
        return <td />;
    }
  }
}
