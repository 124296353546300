import React, { Component } from 'react';
import { SubTitle, Text, Title } from 'common-components/typography';
import { Tooltip2 } from '@blueprintjs/popover2';
import { HyperlinkButton, IconButton, PrimaryButton } from 'common-components/buttons';
import { FilterSection } from 'common-components/filter';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Empty, Icon, Row, Skeleton } from 'antd';
import _ from 'lodash';
import CommonUtils from 'utilities/common-utils';
import { FilterType } from 'utilities/enum-utils';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import PlanPaymentInvoiceRow from 'views/plan-management/payments/components/PlanPaymentInvoiceRow';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { parse } from 'json2csv';
import moment from 'moment-timezone';

const fileDownload = require('js-file-download');

interface IPMBatchUrlParams {
  batchId: string;
}

interface IPlanManagementBatchDetailProps extends RouteComponentProps<IPMBatchUrlParams> {
  planManagementBatchDetailFilter: typeof state.planManagementStore.planManagementBatchDetailFilter;
  planManagementBatchDetail: typeof state.planManagementStore.planManagementBatchDetail;
  doFetchBatchDetail: typeof dispatch.planManagementStore.doFetchBatchDetail;
  setPlanManagementBatchDetailFilter: typeof dispatch.planManagementStore.setPlanManagementBatchDetailFilter;
  portalUser: typeof state.authStore.portalUser;
  doFetchPlanManagementBatchInvoiceDetails: typeof dispatch.planManagementStore.doFetchPlanManagementBatchInvoiceDetails;
  planManagementBatchInvoiceDetails: typeof state.planManagementStore.planManagementBatchInvoiceDetails;
  doGetDownloadNDISFileData: typeof dispatch.planManagementStore.doGetDownloadNDISFileData;
}

const availableFilters = [FilterType.DATE_RANGE, FilterType.PROVIDER, FilterType.CUSTOMER];
const currentFilterConfig = {
  filters: [
    {
      filter: FilterType.DATE_RANGE,
      values: [],
      selectionLabel: CommonUtils.getFilterText(FilterType.DATE_RANGE, [])
    },
    {
      filter: FilterType.PROVIDER,
      values: [],
      selectionLabel: CommonUtils.getFilterSettings(FilterType.PROVIDER).fullSelectionName
    },
    {
      filter: FilterType.CUSTOMER,
      values: [],
      selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
    }
  ]
};

const PaymentEmptyState = () => (
  <div className="flex-1 bg-white align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No Invoices found.
    </Text>{' '}
    <br /> <br />
    <Text color="secondary">All invoices under this filter will appear here.</Text>
    <Text color="secondary">Try adjusting your filter, or clicking on another view.</Text>
  </div>
);

class PlanManagementBatchDetail extends Component<IPlanManagementBatchDetailProps, any> {
  state = {
    topHeight: 0,
    showFilters: false,
    showActionSheet: false,
    checkAllIndicator: false,
    indeterminateCheck: false,
    isLoading: false,
    searchString: '',
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    action: '',
    openAction: false,
    openEditAction: false,
    isSearching: false,
    isLoadingScreen: false
  };

  _headerElement = null;

  private _refreshListings = async () => {
    this.setState({ isLoading: true });
    const { doFetchBatchDetail, setPlanManagementBatchDetailFilter, match } = this.props;
    const { params } = match;
    await setPlanManagementBatchDetailFilter(currentFilterConfig.filters);

    const requestFilter = this._formatFilterQuery();
    await doFetchBatchDetail({
      page: this.state.page,
      pageTimestamp: this.state.pageTimestamp,
      pageSize: this.state.pageSize,
      ...requestFilter,
      batchId: params.batchId
    });
    this.setState({ isLoading: false });
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setPlanManagementBatchDetailFilter(filters);
  };

  private _handleHeaderHeight = () => {
    if (this._headerElement) {
      this.setState({ topHeight: this._headerElement.offsetHeight - 1 });
    }
  };

  private _formatFilterQuery = () => {
    const requestFilter: any = {};
    _.forEach(this.props.planManagementBatchDetailFilter, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'startDate':
            requestFilter.invoiceDate = [filter.values[0].toDate(), filter.values[1].toDate()];
            break;
          case 'planManagementSupplierIds':
            requestFilter.supplierIds = filter.values;
            break;
          case 'customerUserIds':
            requestFilter.customerIds = _.map(filter.values, (customer) => {
              return customer.value;
            });
            break;
          case 'searchString':
            requestFilter.searchString = filter.values;
            break;
        }
      }
    });
    return requestFilter;
  };

  private _fetchMoreInvoice = async () => {
    const { doFetchBatchDetail } = this.props;
    this.setState({ isLoading: true });
    const requestFilter = this._formatFilterQuery();
    await doFetchBatchDetail({
      page: this.state.page + 1,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      ...requestFilter,
      batchId: this.props.match.params.batchId
    });
    this.setState({ isLoading: false, page: this.state.page + 1 });
  };

  private _checkItem = () => {};

  private _openActionModal = () => {};

  private _hasMore = (): boolean => {
    const { planManagementBatchDetail } = this.props;
    if (!planManagementBatchDetail || _.isEmpty(planManagementBatchDetail)) {
      return false;
    } else {
      return planManagementBatchDetail.invoices.length >= this.state.page * this.state.pageSize;
    }
  };

  private downloadNDISFile = async () => {
    const { match, doGetDownloadNDISFileData, planManagementBatchDetail } = this.props;
    const { params } = match;
    try {
      const data = await doGetDownloadNDISFileData({ batchId: params.batchId });
      const csvData = parse(data, { quote: '' });
      fileDownload(csvData, `${planManagementBatchDetail.batch.batchNumber}.csv`);
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount = async () => {
    this.setState({ isLoadingScreen: true });
    this._handleHeaderHeight();
    const { doFetchBatchDetail, setPlanManagementBatchDetailFilter, match } = this.props;
    const { params } = match;
    await setPlanManagementBatchDetailFilter(currentFilterConfig.filters);

    const requestFilter = this._formatFilterQuery();
    await doFetchBatchDetail({
      page: this.state.page,
      pageTimestamp: this.state.pageTimestamp,
      pageSize: this.state.pageSize,
      ...requestFilter,
      batchId: params.batchId
    });
    this.setState({ isLoadingScreen: false });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { doFetchBatchDetail, planManagementBatchDetailFilter } = this.props;

    if (prevState.showFilters !== this.state.showFilters) {
      this._handleHeaderHeight();
    }

    if (prevProps.planManagementBatchDetailFilter !== planManagementBatchDetailFilter) {
      this.setState({ isLoading: true });
      const requestFilter = this._formatFilterQuery();
      await doFetchBatchDetail({
        page: 1,
        pageTimestamp: new Date(),
        pageSize: this.state.pageSize,
        ...requestFilter,
        batchId: this.props.match.params.batchId
      });
      this.setState({ isLoading: false, page: 1, pageTimestamp: new Date() });
    }
  };

  render() {
    const { history, planManagementBatchDetail, portalUser } = this.props;
    return (
      <div
        className="bg-white flex-1 width-full flex-column"
        style={{ overflowY: 'auto', position: 'relative' }}
        id="scroll"
      >
        {!this.state.isLoadingScreen && planManagementBatchDetail && (
          <div>
            <div className="plan-management-header" ref={(com) => (this._headerElement = com)}>
              <div className="mb-medium">
                <HyperlinkButton onClick={() => history.push('/plan-management/payments')}>
                  <Icon type="left" /> Back to Batch List
                </HyperlinkButton>
              </div>
              <div className="flex-row justify-between align-center">
                <div>
                  <Title level={3} className="mv-none" lineHeight={150}>
                    Batch details
                  </Title>
                </div>
              </div>
              <div className="flex-row justify-between align-center mt-medium">
                <div>
                  <SubTitle>Batch Number</SubTitle>
                  <div>{planManagementBatchDetail.batch.batchNumber}</div>
                </div>
                <div className="flex-column align-center">
                  <div className="pr-x-small">
                    <Tooltip2 position="top" content="Refresh this view">
                      <IconButton
                        color="white"
                        onClick={this._refreshListings}
                        iconColor="black"
                        size="large"
                        icon="reload"
                      />
                    </Tooltip2>
                  </div>
                </div>
              </div>

              <div className="ph-large pv-medium bg-quaternary mt-medium mb-medium rounded" style={{ margin: 'auto' }}>
                <Row className="flex-row align-stretch justify-between">
                  <Col span={6}>
                    <SubTitle>Exported date</SubTitle>
                    <div className="text-size-x2-large" style={{ lineHeight: '26px' }}>
                      {moment
                        .tz(planManagementBatchDetail.batch.invoiceIssuedDate, portalUser.timezone)
                        .format('D/M/YYYY')}
                    </div>
                  </Col>
                  <Col span={6}>
                    <SubTitle>Number of Invoices</SubTitle>
                    <div className="text-size-x3-large" style={{ lineHeight: '26px' }}>
                      {planManagementBatchDetail.batch.numberOfInvoices}
                    </div>
                  </Col>
                  <Col span={6} />
                  <Col>
                    <PrimaryButton size="large" className={'ml-large mt-small'} onClick={this.downloadNDISFile}>
                      Re-download NDIS export file
                    </PrimaryButton>
                  </Col>
                </Row>
              </div>

              <div className={'flex-row align-center'}>
                <FilterSection
                  availableFilters={availableFilters}
                  filters={this.props.planManagementBatchDetailFilter}
                  onChangeFilter={this._onChangeFilter}
                  displayTimezone={this.props.portalUser.timezone}
                />
              </div>
            </div>

            <table className="plan-payment-listing">
              <thead>
                <tr>
                  <th className="nowrap check-all" style={{ top: `${this.state.topHeight}px` }} />
                  <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                    <SubTitle>Invoice date</SubTitle>
                  </th>
                  <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                    <SubTitle>Invoice number</SubTitle>
                  </th>{' '}
                  <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                    <SubTitle>Customer</SubTitle>
                  </th>{' '}
                  <th style={{ top: `${this.state.topHeight}px` }} className="nowrap">
                    <SubTitle>Provider</SubTitle>
                  </th>
                  <th style={{ top: `${this.state.topHeight}px` }} className="nowrap text-align-right">
                    <SubTitle containerClassName="text-align-right">Total</SubTitle>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.isLoading && (
                  <tr style={{ borderBottom: '0px solid !important' }}>
                    <td colSpan={7}>
                      <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
                    </td>
                  </tr>
                )}

                {!this.state.isLoading && _.isEmpty(planManagementBatchDetail) && (
                  <tr style={{ cursor: 'default' }}>
                    <td colSpan={7} style={{ borderBottom: '0px solid' }}>
                      <PaymentEmptyState />
                    </td>
                  </tr>
                )}

                <InfiniteScrollLoading
                  hasMore={this._hasMore()}
                  loadingElementId={'scroll'}
                  loadMore={this._fetchMoreInvoice}
                  loaderColSpan={7}
                  loadingOffSet={60}
                >
                  {!this.state.isLoading &&
                    !_.isEmpty(planManagementBatchDetail) &&
                    _.map(planManagementBatchDetail.invoices, (invoiceItem) => (
                      <PlanPaymentInvoiceRow
                        invoiceItem={invoiceItem}
                        onCheckItem={this._checkItem}
                        history={this.props.history}
                        key={invoiceItem.planManagementInvoiceId}
                        openActionModal={this._openActionModal}
                        canProcess={true}
                        displayCheckbox={false}
                        type={'CLAIMED'}
                        actions={[]}
                        subActions={[]}
                        doFetchPlanManagementInvoiceDetails={this.props.doFetchPlanManagementBatchInvoiceDetails}
                        planManagementLineItemList={this.props.planManagementBatchInvoiceDetails}
                      />
                    ))}
                </InfiniteScrollLoading>
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  planManagementBatchDetailFilter: state.planManagementStore.planManagementBatchDetailFilter,
  planManagementBatchDetail: state.planManagementStore.planManagementBatchDetail,
  planManagementBatchInvoiceDetails: state.planManagementStore.planManagementBatchInvoiceDetails,
  portalUser: state.authStore.portalUser
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchBatchDetail: dispatch.planManagementStore.doFetchBatchDetail,
  setPlanManagementBatchDetailFilter: dispatch.planManagementStore.setPlanManagementBatchDetailFilter,
  doFetchPlanManagementBatchInvoiceDetails: dispatch.planManagementStore.doFetchPlanManagementBatchInvoiceDetails,
  doGetDownloadNDISFileData: dispatch.planManagementStore.doGetDownloadNDISFileData
});

export default connect(mapState, mapDispatch)(PlanManagementBatchDetail);
