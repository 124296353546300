import React, { Component } from 'react';
import { Checkbox, Form, Icon, Input, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import { GhostButton, HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import Utils from 'utilities/Utils';
import _ from 'lodash';
import CommonUtils from 'utilities/common-utils';

const FormItem = Form.Item;

const searchAutocompleteOptions = {
  componentRestrictions: { country: ['au'] }
};

interface ICreateEditAddressModalProps extends FormComponentProps {
  closeCreateEditAddressModal: () => void;
  isOpen: boolean;
  selectedWorkerItem: any;
  selectedAddressIndex: number;
  isCreateNewAddress: boolean;
  workerAddress: any;
  saveWorkerAddress: (addressLocation: any) => void;
}

interface ICreateEditAddressModalState {
  isLoadingFilter: boolean;
  isActionModalOpen: boolean;
  isManual: boolean;
  address: string;
  selectedAddress: string;
  selectedLocation: boolean;
  selectedLattitude: any;
  selectedLongitude: any;
  address_components: any;
  isInitialAddressPrimary: boolean;
  temporarySelectedAddress: any;
}

class CreateEditAddressModal extends Component<ICreateEditAddressModalProps, ICreateEditAddressModalState> {
  state = {
    isLoadingFilter: false,
    isActionModalOpen: false,
    isManual: false,
    address: '',
    selectedAddress: '',
    selectedLocation: false,
    selectedLattitude: '',
    selectedLongitude: '',
    address_components: [],
    isInitialAddressPrimary:
      this.props.selectedAddressIndex > -1
        ? this.props.workerAddress[this.props.selectedAddressIndex].isPrimary
        : _.isEmpty(this.props.workerAddress),
    temporarySelectedAddress: null
  };

  private _saveWorkerAddress = () => {
    let addressLocation;
    let isFormValid = false;
    const { form, saveWorkerAddress } = this.props;
    let isPrimary = form.getFieldValue('isPrimary');
    if (this.state.isManual) {
      form.validateFieldsAndScroll(async (err) => {
        if (!err) {
          let streetaddress = form.getFieldValue('streetaddress');
          let streetaddress2 = form.getFieldValue('streetaddress2');
          let suburb = form.getFieldValue('suburb');
          let state = form.getFieldValue('state');
          let postcode = form.getFieldValue('postcode');
          let country = form.getFieldValue('country');

          addressLocation = {
            streetaddress: streetaddress,
            streetaddress2: streetaddress2,
            suburb: suburb,
            state: state,
            postcode: postcode,
            country: country,
            isPrimary: isPrimary !== undefined ? isPrimary : false,
            isManual: true
          };
          isFormValid = true;
        }
      });
    } else {
      form.validateFieldsAndScroll(async (err) => {
        if (!err) {
          addressLocation = {
            selectedLattitude: this.state.selectedLattitude,
            selectedLongitude: this.state.selectedLongitude,
            address: this.state.address,
            address_components: this.state.address_components,
            isPrimary: isPrimary !== undefined ? isPrimary : false,
            isManual: false
          };
          isFormValid = true;
        }
      });
    }
    if (isFormValid) {
      saveWorkerAddress(addressLocation);
      this._closeActionCreateModal();
    }
  };

  private _handleChange = (address) => {
    this.setState({ address });
    if (address === '') {
      this.setState({ selectedLocation: false });
    }
  };

  private _handleSelect = (address) => {
    this.setState({ address: address, selectedAddress: address });
    geocodeByAddress(address)
      .then((results) =>
        this.setState({
          address_components: results[0].address_components,
          temporarySelectedAddress: CommonUtils.createAddressFromGoogleMap(results[0].address_components)
        })
      )
      .catch(() => this.setState({ selectedLocation: false }));
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => this.setState({ selectedLocation: true, selectedLattitude: lat, selectedLongitude: lng }))
      .catch(() => this.setState({ selectedLocation: false }));
  };

  private _closeActionCreateModal = () => {
    this.setState({ isActionModalOpen: false, address: '', selectedLocation: false, isManual: false });
    this.props.closeCreateEditAddressModal();
  };

  private _switchToManual = () => {
    this.setState({ isManual: true, selectedLocation: false, address: '' });
  };

  private _switchToAuto = () => {
    this.setState({ isManual: false, selectedLocation: false, address: '' });
  };

  private _validatePostcode = (rule, value, callback) => {
    try {
      if (!value.match(new RegExp('^\\d{4}$'))) {
        throw Error('Postcode is required (4 Digits)');
      }
    } catch (e) {
      callback(e);
      return;
    }
    callback();
  };

  componentDidUpdate(
    prevProps: Readonly<ICreateEditAddressModalProps>,
    prevState: Readonly<ICreateEditAddressModalState>,
    snapshot?: any
  ) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({
        isInitialAddressPrimary:
          this.props.selectedAddressIndex > -1
            ? this.props.workerAddress[this.props.selectedAddressIndex].isPrimary
            : _.isEmpty(this.props.workerAddress)
      });
    }
  }

  render() {
    const { selectedWorkerItem, form, isCreateNewAddress, selectedAddressIndex } = this.props;
    const { getFieldDecorator } = form;
    const addressIndex = selectedAddressIndex !== undefined ? selectedAddressIndex : -1;
    let selectedWorkerAddress = this.state.temporarySelectedAddress
      ? this.state.temporarySelectedAddress
      : this.props.workerAddress[addressIndex];

    return (
      <div>
        <ActionModal
          title={
            <>
              {!isCreateNewAddress ? 'Edit' : 'Add a New'} <b>Address</b>
            </>
          }
          isOpen={this.props.isOpen}
          onClose={this.props.closeCreateEditAddressModal}
          width={'x-large'}
          verticalAlignment={'highest'}
        >
          <div className="anim-slide-left">
            <SpinningLoadingActionModel isOpen={this.state.isLoadingFilter} verticalAlignment={'highest'} />
            {!isCreateNewAddress ? (
              <Paragraph>
                You are editing a Address for{' '}
                <span className="text-weight-bold">
                  {selectedWorkerItem.firstName} {selectedWorkerItem.lastName}
                </span>{' '}
                contact. Please enter the following details.
              </Paragraph>
            ) : (
              <Paragraph>
                You are adding a new Address for{' '}
                <span className="text-weight-bold">
                  {selectedWorkerItem.firstName} {selectedWorkerItem.lastName}
                </span>{' '}
                profile. Please enter the following details.
              </Paragraph>
            )}

            <React.Fragment>
              <div className="mb-medium">
                <SubTitle>Address Type</SubTitle>
                <Text type={'secondary'}>A Team member can and must have only one Primary Address</Text>
                <FormItem className={'m-none'}>
                  {getFieldDecorator('isPrimary', {
                    valuePropName: 'checked',
                    initialValue: this.state.isInitialAddressPrimary
                  })(
                    <Checkbox>
                      <b>Is this the Primary Address?</b>
                    </Checkbox>
                  )}
                </FormItem>
              </div>
            </React.Fragment>

            {!this.state.isManual ? (
              <React.Fragment>
                <div className="mb-small" style={{ minHeight: '320px' }}>
                  <SubTitle>Lookup Address</SubTitle>
                  <div className="mb-small">
                    <Text type={'secondary'} size={'regular'}>
                      Type the address that you would like to search for. We will try to automatically lookup your
                      address here.
                      <br />
                      If you can’t find it, you can enter it manually by clicking below.
                    </Text>
                  </div>
                  <FormItem className={'m-none'}>
                    <PlacesAutocomplete
                      value={this.state.address}
                      onSelect={this._handleSelect}
                      onChange={this._handleChange}
                      shouldFetchSuggestions={this.state.address.length > 2}
                      searchOptions={searchAutocompleteOptions}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <input
                            value={this.state.address}
                            {...getInputProps({
                              placeholder: 'type your address ...',
                              className: 'location-search-input width-full pl-small'
                            })}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                              const style = suggestion.active
                                ? {
                                    backgroundColor: '#fafafa',
                                    cursor: 'pointer',
                                    borderTop: '1px ridge grey',
                                    borderLeft: '1px ridge grey',
                                    borderRight: '1px ridge grey'
                                  }
                                : {
                                    backgroundColor: '#ffffff',
                                    cursor: 'pointer',
                                    borderTop: '1px ridge grey',
                                    borderLeft: '1px ridge grey',
                                    borderRight: '1px ridge grey'
                                  };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style
                                  })}
                                >
                                  <span>
                                    <Icon type={'environment'} /> {suggestion.description}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </FormItem>
                </div>
                <div>
                  <SubTitle>Selected address</SubTitle>
                  <Text>
                    {!Utils.isEmpty(this.state.selectedAddress)
                      ? this.state.selectedAddress
                      : 'No address selected yet.'}
                  </Text>
                </div>
                <ActionModalFooter>
                  {!this.state.isManual ? (
                    <HyperlinkButton onClick={this._switchToManual} style={{ float: 'left' }} className={'mt-small'}>
                      Enter the address manually
                    </HyperlinkButton>
                  ) : (
                    <HyperlinkButton onClick={this._switchToAuto} style={{ float: 'left' }} className={'mt-small'}>
                      Address lookup tool
                    </HyperlinkButton>
                  )}
                  <GhostButton size={'large'} onClick={this._closeActionCreateModal}>
                    Cancel
                  </GhostButton>
                  <PrimaryButton
                    size={'large'}
                    disabled={!this.state.selectedAddress}
                    onClick={this._saveWorkerAddress}
                  >
                    {!isCreateNewAddress ? 'Save' : 'Add Address'}
                  </PrimaryButton>
                </ActionModalFooter>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className={'customScrollDiv'}>
                  <div className="mb-small">
                    <SubTitle>Street Address</SubTitle>
                    <FormItem className={'m-none pr-small'}>
                      {getFieldDecorator('streetaddress', {
                        initialValue: selectedWorkerAddress !== undefined ? selectedWorkerAddress.streetAddress1 : null,
                        rules: [
                          {
                            required: true,
                            message: 'street address is required'
                          }
                        ]
                      })(<Input placeholder="Enter Street Address" />)}
                    </FormItem>
                  </div>
                  <div className="mb-small">
                    <SubTitle>Street Address 2</SubTitle>
                    <FormItem className={'m-none pr-small'}>
                      {getFieldDecorator('streetaddress2', {
                        initialValue: selectedWorkerAddress !== undefined ? selectedWorkerAddress.streetAddress2 : null
                      })(<Input placeholder="Enter Street Address 2" />)}
                    </FormItem>
                  </div>
                  <div className="mb-small">
                    <SubTitle>Suburb</SubTitle>
                    <FormItem className={'m-none pr-small'}>
                      {getFieldDecorator('suburb', {
                        initialValue: selectedWorkerAddress !== undefined ? selectedWorkerAddress.locality : null,
                        rules: [
                          {
                            required: true,
                            message: 'suburb is required'
                          }
                        ]
                      })(<Input placeholder="Enter Suburb" />)}
                    </FormItem>
                  </div>
                  <div className="mb-small">
                    <SubTitle>State</SubTitle>
                    <FormItem className={'m-none pr-small'}>
                      {getFieldDecorator('state', {
                        initialValue: selectedWorkerAddress !== undefined ? selectedWorkerAddress.state : null,
                        rules: [
                          {
                            required: true,
                            message: 'state is required'
                          }
                        ]
                      })(
                        <Select
                          placeholder="Select a State"
                          style={{ width: '200px' }}
                          showSearch={true}
                          optionFilterProp={'children'}
                        >
                          <Select.Option value={'ACT'}>Australian Capital Territory</Select.Option>
                          <Select.Option value={'NSW'}>New South Wales</Select.Option>
                          <Select.Option value={'NT'}>Northern Territory</Select.Option>
                          <Select.Option value={'QLD'}>Queensland</Select.Option>
                          <Select.Option value={'SA'}>South Australia</Select.Option>
                          <Select.Option value={'TAS'}>Tasmania</Select.Option>
                          <Select.Option value={'VIC'}>Victoria</Select.Option>
                          <Select.Option value={'WA'}>Western Australia</Select.Option>
                        </Select>
                      )}
                    </FormItem>
                  </div>
                  <div className="mb-small">
                    <SubTitle>Postcode</SubTitle>
                    <FormItem className={'m-none pr-small'}>
                      {getFieldDecorator('postcode', {
                        initialValue: selectedWorkerAddress !== undefined ? selectedWorkerAddress.postcode : null,
                        rules: [{ validator: this._validatePostcode }]
                      })(<Input placeholder="Enter Postcode" style={{ width: '200px' }} maxLength={4} />)}
                    </FormItem>
                  </div>
                  <div className="mb-small">
                    <SubTitle>Country</SubTitle>
                    <FormItem className={'m-none pr-small'}>
                      <Select size={'large'} style={{ width: '200px' }} defaultValue={'AU'} disabled>
                        <Select.Option value={'AU'}>Australia</Select.Option>
                      </Select>
                    </FormItem>
                  </div>
                </div>
                <ActionModalFooter>
                  {!this.state.isManual ? (
                    <HyperlinkButton onClick={this._switchToManual} style={{ float: 'left' }} className={'mt-small'}>
                      Enter the address manually
                    </HyperlinkButton>
                  ) : (
                    <HyperlinkButton onClick={this._switchToAuto} style={{ float: 'left' }} className={'mt-small'}>
                      Address lookup tool
                    </HyperlinkButton>
                  )}
                  <GhostButton size={'large'} onClick={this._closeActionCreateModal}>
                    Cancel
                  </GhostButton>
                  <PrimaryButton size="large" onClick={this._saveWorkerAddress}>
                    {!isCreateNewAddress ? 'Edit Address' : 'Add Address'}
                  </PrimaryButton>
                </ActionModalFooter>
              </React.Fragment>
            )}
          </div>
        </ActionModal>
      </div>
    );
  }
}

export default connect(
  null,
  null
)(Form.create<ICreateEditAddressModalProps>()(CreateEditAddressModal));
