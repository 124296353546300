import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { Form, notification } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/es/form';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import moment from 'moment';

interface IUnwaiveLineItemActionModelProps extends FormComponentProps {
  isOpen: boolean;
  selectedBillingLineItem: any;
  onClose: () => void;
  doUnwaiveBillingLineItem: typeof dispatch.billingsStore.doUnwaiveBillingLineItem;
}

interface IUnwaiveLineItemActionModelState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

class UnwaiveLineItemActionModel extends Component<IUnwaiveLineItemActionModelProps, IUnwaiveLineItemActionModelState> {
  state = {
    isLoading: false,
    isSuccess: false,
    isError: false
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false, isError: false });
      onClose();
    }
  };

  private _onSubmitWaive = async () => {
    const { selectedBillingLineItem, doUnwaiveBillingLineItem } = this.props;

    const payload = {
      list: [
        {
          bookingBillingLineItemId: selectedBillingLineItem.bookingBillingLineItemId,
          attendanceId: selectedBillingLineItem.attendanceId
        }
      ]
    };

    try {
      this.setState({ isLoading: true });
      await doUnwaiveBillingLineItem(payload);
      this.setState({ isLoading: false });
      this._onCloseModal();
    } catch (e) {
      this.setState({ isLoading: false });
      notification.error({ message: 'Error', description: 'Oops. Something went wrong, please try again later' });
    }
  };

  render() {
    const { isOpen, selectedBillingLineItem } = this.props;

    return (
      <ActionModal
        title="Unwaive Line Item"
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="medium"
        verticalAlignment="center"
      >
        {selectedBillingLineItem && (
          <>
            <div className="text-align-left">
              <div className="mb-medium">
                <Paragraph>
                  Are you sure you want to un-waive this line item?
                  <br />
                  It was originally waived by{' '}
                  <b>
                    {selectedBillingLineItem.waivedByFirstName + ' ' + selectedBillingLineItem.waivedByLastName} on{' '}
                    {moment(selectedBillingLineItem.waivedOn ? selectedBillingLineItem.waivedOn : new Date()).format(
                      'DD/MM/YYYY'
                    )}
                    : {selectedBillingLineItem.waivedReason}
                  </b>
                </Paragraph>
              </div>
              <div className="mb-medium mt-large">
                <Paragraph>Unwaiveing this line item will allow the line item to be processed again.</Paragraph>
              </div>
            </div>
            <ActionModalFooter>
              <GhostButton
                size="large"
                onClick={this._onCloseModal}
                className="mr-medium"
                disabled={this.state.isLoading}
              >
                Not now
              </GhostButton>
              <PrimaryButton size="large" onClick={this._onSubmitWaive} loading={this.state.isLoading}>
                Unwaive
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBillingLineItem: state.billingsStore.selectedBillingLineItem
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUnwaiveBillingLineItem: dispatch.billingsStore.doUnwaiveBillingLineItem
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IUnwaiveLineItemActionModelProps>()(UnwaiveLineItemActionModel));
