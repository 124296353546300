import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from '../../../../../common-components/modal/ActionModal';
import { Text } from '../../../../../common-components/typography';
import { Avatar, Checkbox, Icon, notification, Skeleton, Switch } from 'antd';
import { PrimaryButton, SecondaryButton } from '../../../../../common-components/buttons';
import _ from 'lodash';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../../stores/rematch/root-store';
import { FilterSection } from '../../../../../common-components/filter';
import { FilterType, TransportAttendanceType } from '../../../../../utilities/enum-utils';

interface IAssignTeamMemberModalProps {
  onClose: any;
  isOpen: boolean;
  onSelectWorker: (worker) => void;
  doFetchSupportWorkerList: typeof dispatch.groupBookingsStore.doFetchSupportWorkerList;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
  transportBooking: any;
  modelType: 'assign' | 'edit' | string;
  doAssignTransportBookingWorker: typeof dispatch.groupBookingsStore.doAssignTransportBookingWorker;
  attendanceType?: TransportAttendanceType;
}

interface IAssignTeamMemberModalState {
  isTimeAvailable: boolean;
  isAlreadyInSession: boolean;
  isLoading: boolean;
  listFilters: any;
  supportWorkerList: any;
  checkedWorker: any;
  isLoadingButton: boolean;
  isCustomerPreferred: boolean;
}

const availableFilters = [
  FilterType.QUALIFICATIONS,
  FilterType.RELIGIONS,
  FilterType.GENDER,
  FilterType.SKILLS,
  FilterType.LANGUAGES,
  FilterType.INTEREST
];

class AssignTeamMemberModal extends Component<IAssignTeamMemberModalProps, IAssignTeamMemberModalState> {
  state = {
    isTimeAvailable: true,
    isAlreadyInSession: true,
    isLoading: false,
    listFilters: null,
    supportWorkerList: null,
    checkedWorker: null,
    isLoadingButton: false,
    isCustomerPreferred: false
  };

  private _onChangeTimeFilter = (value) => {
    this.setState({ isTimeAvailable: value }, this._fetchWorker);
  };

  private _onChangeAlreadyInSession = (value) => {
    this.setState({ isAlreadyInSession: value }, this._fetchWorker);
  };

  private _onChangeCustomerPreferred = (value) => {
    this.setState({ isCustomerPreferred: value }, this._fetchWorker);
  };

  private _onCloseModel = () => {
    const { onClose } = this.props;
    this.setState(
      {
        isTimeAvailable: true,
        isAlreadyInSession: true,
        isLoading: false,
        listFilters: null,
        supportWorkerList: null,
        checkedWorker: null,
        isCustomerPreferred: false
      },
      onClose
    );
  };

  private _formatFilterQuery = () => {
    const requestFilter: any = {};
    _.forEach(this.state.listFilters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        requestFilter[filter.filter] = filter.values;
      }
    });
    return requestFilter;
  };

  private _fetchWorker = async () => {
    const { doFetchSupportWorkerList, selectedGroupBookingItem, transportBooking } = this.props;
    this.setState({ isLoading: true });
    const requestFilters = this._formatFilterQuery();
    const payload = {
      serviceId: selectedGroupBookingItem.serviceId,
      customerUserId: selectedGroupBookingItem.userId,
      bookingId: selectedGroupBookingItem.bookingId,
      startDateTime: transportBooking.startDateTime,
      endDateTime: transportBooking.endDateTime,
      address: transportBooking.address,
      isAvailable: this.state.isTimeAvailable,
      isAssigned: this.state.isAlreadyInSession,
      isCustomerPreferred: this.state.isCustomerPreferred,
      ...requestFilters
    };
    const workerResult = await doFetchSupportWorkerList(payload);

    this.setState({ supportWorkerList: workerResult, isLoading: false });
  };

  private _onChangeListFilter = (filters: Array<any>) => {
    this.setState({ listFilters: filters }, this._fetchWorker);
  };

  private _onClickWorker = (worker) => {
    if (_.isEmpty(this.state.checkedWorker) || worker.supportWorkerId === this.state.checkedWorker.supportWorkerId) {
      if (_.isEmpty(this.state.checkedWorker)) {
        this.setState({ checkedWorker: worker });
      } else {
        this.setState({ checkedWorker: null });
      }
    }
  };

  private onSubmitForm = async () => {
    const {
      modelType,
      doAssignTransportBookingWorker,
      onSelectWorker,
      selectedGroupBookingItem,
      transportBooking,
      attendanceType
    } = this.props;
    const { checkedWorker } = this.state;

    if (modelType === 'edit') {
      onSelectWorker(checkedWorker);
      this._onCloseModel();
    } else {
      this.setState({ isLoadingButton: true });
      try {
        await doAssignTransportBookingWorker({
          worker: checkedWorker,
          bookingId: selectedGroupBookingItem.bookingId,
          transportBookingId: transportBooking.attendanceId,
          attendanceType
        });

        this.setState({ isLoadingButton: false }, this._onCloseModel);
      } catch (e) {
        this.setState({ isLoadingButton: false });
        notification.error({ message: 'Oops, something went wrong, please try again.' });
      }
    }
  };

  async componentDidUpdate(
    prevProps: Readonly<IAssignTeamMemberModalProps>,
    prevState: Readonly<IAssignTeamMemberModalState>,
    snapshot?: any
  ) {
    if (prevProps.isOpen !== this.props.isOpen && this.props.isOpen) {
      await this._fetchWorker();
    }
  }

  render() {
    const { onClose, isOpen } = this.props;
    const { listFilters, supportWorkerList } = this.state;

    return (
      <ActionModal isOpen={isOpen} onClose={onClose} width="x2-large" title="Assign team member">
        {/*<SelectWorkerSearchModal*/}
        {/*  isOpen={isSearchOpen}*/}
        {/*  selectedWorker={selectedWorker}*/}
        {/*  bookingId={this.props.bookingId}*/}
        {/*  startDateTime={this.props.startDateTime}*/}
        {/*  endDateTime={this.props.endDateTime}*/}
        {/*  address={this.props.address}*/}
        {/*  customerUserIds={this.props.customerUserIds}*/}
        {/*  serviceId={this.props.serviceId}*/}
        {/*  onCloseSearchModal={this._onCloseSearchModal}*/}
        {/*  selectWorker={this._onSelectWorker}*/}
        {/*  onAssignWorker={this._onAssignWorker}*/}
        {/*/>*/}

        <section className="mb-large">
          <Text>Filter and select a team member for this travel booking :</Text>
        </section>

        <section className="flex-row mb-large line-height-120">
          {/* Left panel - Filters */}
          <div className="rounded-big p-medium mr-large bg-quaternary" style={{ width: '400px' }}>
            <div className="mb-small">
              <Text size="large" weight="bold">
                Filters
              </Text>
            </div>

            {/* Options */}
            <div className="mb-medium">
              <div className="flex-row align-center mb-x2-small">
                <Switch
                  size="small"
                  className="mr-small"
                  onChange={this._onChangeTimeFilter}
                  checked={this.state.isTimeAvailable}
                  disabled={this.state.isLoading}
                />
                <Text size="regular">Are available during the scheduled travel time</Text>
              </div>

              <div className="flex-row align-center">
                <Switch
                  size="small"
                  className="mr-small"
                  onChange={this._onChangeAlreadyInSession}
                  checked={this.state.isAlreadyInSession}
                  disabled={this.state.isLoading}
                />
                <Text size="regular">Are already assigned to the session</Text>
              </div>

              <div className="flex-row align-center">
                <Switch
                  size="small"
                  className="mr-small"
                  onChange={this._onChangeCustomerPreferred}
                  checked={this.state.isCustomerPreferred}
                  disabled={this.state.isLoading}
                />
                <Text size="regular">Are preferred by the customer</Text>
              </div>
            </div>

            <FilterSection
              availableFilters={availableFilters}
              filters={listFilters}
              onChangeFilter={this._onChangeListFilter}
              displayTimezone={null}
              usePortal={false}
              verticalDisplay={true}
            />
          </div>

          {/* Right panel - Team members */}
          <div className="flex-1">
            <div className="flex-row justify-between mb-small">
              <Text>Team members {supportWorkerList ? `(${supportWorkerList.length})` : ''}</Text>
              {/*<HyperlinkButton>*/}
              {/*  <Icon type="search" className="mr-small" />*/}
              {/*  Find workers...*/}
              {/*</HyperlinkButton>*/}
            </div>

            <div
              className="flex-1 bordered border-standard-gray rounded-big"
              style={{ height: '50vh', overflowY: 'auto' }}
            >
              {/* list of team members */}
              {this.state.isLoading ? (
                <div>
                  <Skeleton
                    avatar={true}
                    paragraph={{ rows: 1, width: '70%' }}
                    active={true}
                    className="anim-slide-left"
                  />
                  <Skeleton
                    avatar={true}
                    paragraph={{ rows: 1, width: '70%' }}
                    active={true}
                    className="anim-slide-left"
                  />
                  <Skeleton
                    avatar={true}
                    paragraph={{ rows: 1, width: '70%' }}
                    active={true}
                    className="anim-slide-left"
                  />
                  <Skeleton
                    avatar={true}
                    paragraph={{ rows: 1, width: '70%' }}
                    active={true}
                    className="anim-slide-left"
                  />
                </div>
              ) : !_.isEmpty(this.state.supportWorkerList) ? (
                _.map(supportWorkerList, (worker) => (
                  <div
                    className="flex-row ph-medium align-center hover-bg-quaternary cursor-pointer"
                    style={{ paddingTop: '12px', paddingBottom: '12px' }}
                    // TODO Click handler here
                    onClick={() => this._onClickWorker(worker)}
                  >
                    <Checkbox
                      checked={
                        this.state.checkedWorker && worker.supportWorkerId === this.state.checkedWorker.supportWorkerId
                      }
                      disabled={
                        !_.isEmpty(this.state.checkedWorker) &&
                        worker.supportWorkerId !== this.state.checkedWorker.supportWorkerId
                      }
                    />
                    <Avatar src={worker.attachmentUrl} size="large" className="ml-medium mr-medium" />
                    <div style={{ width: '220px', flexDirection: 'column' }} className="line-height-150">
                      <Text lineHeight={150}>
                        {worker.firstName} {worker.lastName}
                      </Text>
                      <br />
                      <div className="line-height-100 align-center flex-row">
                        {worker.availability === 'AVAILABLE' && (
                          <>
                            <Icon
                              type="check"
                              className="mr-x-small text-color-green-dark bg-green-lightest rounded-full p-x-small"
                              style={{ fontSize: '10px' }}
                            />
                            <Text size="regular">Available</Text>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="flex-row align-center line-height-100">
                      {worker.isAssigned && (
                        <>
                          <Icon type="info-circle" className="mr-x-small text-color-secondary" />
                          <Text lineHeight={100} size="regular" color="secondary">
                            Assigned to session
                          </Text>
                        </>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="mt-x2-large text-align-center" style={{ margin: 'auto' }}>
                  <Text weight="bold">No Team member found</Text>
                  <br />
                  <Text>Select a filter or find Team member to select.</Text>
                </div>
              )}
            </div>
          </div>
        </section>

        <ActionModalFooter>
          <SecondaryButton size="large" className="mr-medium" onClick={onClose} disabled={this.state.isLoadingButton}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            size="large"
            onClick={this.onSubmitForm}
            disabled={_.isEmpty(this.state.checkedWorker)}
            loading={this.state.isLoadingButton}
          >
            Next
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchSupportWorkerList: dispatch.groupBookingsStore.doFetchSupportWorkerList,
  doAssignTransportBookingWorker: dispatch.groupBookingsStore.doAssignTransportBookingWorker
});

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem
});

export default connect(
  mapState,
  mapDispatch
)(AssignTeamMemberModal);
