import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { notification } from 'antd';
import { Paragraph } from 'common-components/typography';
import { GhostButton, PrimaryButton } from 'common-components/buttons';

interface DeclineGroupBookingModalProps {
  isOpen: any;
  onClose: any;
  booking: any;
  doDeclineGroupBooking: any;
}
interface DeclineGroupBookingModalState {
  step: number;
  canManuallyClose: boolean;
  title: string;
  isLoading: boolean;
}

export class DeclineGroupBookingModal extends Component<DeclineGroupBookingModalProps, DeclineGroupBookingModalState> {
  state = { step: 1, canManuallyClose: true, title: '', isLoading: false };

  renderView = () => {
    const { step } = this.state;
    const { booking } = this.props;
    const { firstName, lastName, timezone } = booking;
    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <Paragraph>
            Are you sure you want to decline {firstName} {lastName} booking request?
          </Paragraph>
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal} disabled={this.state.isLoading}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" icon="close" onClick={this.onAccept} disabled={this.state.isLoading}>
              Decline
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <Paragraph>
            You have declined {firstName} {lastName} booking request
          </Paragraph>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this.onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({ step: 1, title: 'Decline booking request' });
    }
  }

  onAccept = async () => {
    const { booking } = this.props;
    try {
      this.setState({ canManuallyClose: false, isLoading: true });
      await this.props.doDeclineGroupBooking({
        serviceId: booking.serviceId,
        serviceDateTimeId: booking.serviceDateTimeId,
        attendanceIds: [booking.bookingId]
      });
      this.setState({ step: 2, canManuallyClose: true, title: 'Booking request declined', isLoading: false });
    } catch (e) {
      this.setState({ canManuallyClose: true, isLoading: false });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, canManuallyClose: true, title: '' }, onClose);
  };

  render() {
    let { isOpen } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this.renderView()}</div>
      </ActionModal>
    );
  }
}
