import React, { Component } from 'react';
import { SubTitle, Text } from 'common-components/typography';
import { HyperlinkButton, SecondaryButton, IconButton } from 'common-components/buttons';
import { GroupServiceSessionStatus } from 'utilities/enum-utils';
import { IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import SessionStatusTag from 'common-components/tags/SessionStatusTag';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { Popover } from '@blueprintjs/core';
import CancelSessionModal from 'views/group-services/session-details/components/CancelSessionModal';

import CommonUtils from 'utilities/common-utils';
import { Icon } from 'antd';
import RevertSessionModal from 'views/group-services/session-details/components/RevertSessionModal';

interface IGroupServiceSessionHeaderProps {
  selectedSession: typeof state.groupServiceStore.selectedSession;
  resetCustomerList: () => void;
  goToTab: any;
  history: any;
}

interface IGroupServiceSessionHeaderState {
  isCancelSessionOpen: boolean;
  isStartSessionOpen: boolean;
  isEndSessionOpen: boolean;
  isCloseSessionOpen: boolean;
  isRevertOpen: boolean;
  revertedToStatus: GroupServiceSessionStatus;
}

class GroupServiceSessionHeader extends Component<IGroupServiceSessionHeaderProps, IGroupServiceSessionHeaderState> {
  state = {
    isCancelSessionOpen: false,
    isStartSessionOpen: false,
    isEndSessionOpen: false,
    isCloseSessionOpen: false,
    isRevertOpen: false,
    revertedToStatus: null
  };

  private _getActionButtons = () => {
    const { selectedSession } = this.props;
    if (selectedSession) {
      if (selectedSession.sessionStatus === GroupServiceSessionStatus.SCHEDULED) {
        return (
          <>
            <Popover
              content={
                <ActionMenu>
                  <ActionMenuItem text="Cancel session" onClick={this._openCancelSession} />
                </ActionMenu>
              }
              position={'bottom-right'}
            >
              <IconButton
                icon={'down'}
                size="large"
                iconColor={'blue-action'}
                color={'white'}
                bordered={true}
                className="border-standard-gray"
              />
            </Popover>
          </>
        );
      } else if (selectedSession.sessionStatus === GroupServiceSessionStatus.INPROGRESS) {
        return (
          <>
            <Popover
              content={
                <ActionMenu>
                  <ActionMenuItem text="Revert to scheduled" onClick={this._openRevertToSchedule} />
                </ActionMenu>
              }
              position={'bottom-right'}
            >
              <SecondaryButton size={'large'}>
                Session actions <Icon type={'down'} className={'ml-x-small'} />
              </SecondaryButton>
            </Popover>
          </>
        );
      } else if (selectedSession.sessionStatus === GroupServiceSessionStatus.COMPLETED) {
        return (
          <>
            <Popover
              content={
                <ActionMenu>
                  <ActionMenuItem text="Revert to in progress" onClick={this._openRevertToInProgress} />
                  <ActionMenuItem text="Revert to scheduled" onClick={this._openRevertToSchedule} />
                </ActionMenu>
              }
              position={'bottom-right'}
            >
              <SecondaryButton size={'large'}>
                Session actions <Icon type={'down'} className={'ml-x-small'} />
              </SecondaryButton>
            </Popover>
          </>
        );
      }
    }
  };

  private _openCancelSession = () => {
    this.setState({ isCancelSessionOpen: true });
  };

  private _closeCancelSession = () => {
    this.setState({ isCancelSessionOpen: false });
  };

  private _openRevertToSchedule = () => {
    this.setState({ isRevertOpen: true, revertedToStatus: GroupServiceSessionStatus.SCHEDULED });
  };

  private _closeRevert = () => {
    this.setState({ isRevertOpen: false, revertedToStatus: null });
  };

  private _openRevertToInProgress = () => {
    this.setState({ isRevertOpen: true, revertedToStatus: GroupServiceSessionStatus.INPROGRESS });
  };
  private _navToService = () => {
    const { history, selectedSession } = this.props;
    history.push(`/group-service/details/${selectedSession.serviceId}`);
  };

  render = () => {
    const { selectedSession, history, goToTab } = this.props;
    const startDateTime = selectedSession && moment.tz(selectedSession.startDateTime, selectedSession.timezone);
    const endDateTime = selectedSession && moment.tz(selectedSession.endDateTime, selectedSession.timezone);

    // 3:00 AM - 5:30 PM (3 hours)
    const momentStartTime = startDateTime;
    const momentEndTime = endDateTime;

    const duration = CommonUtils.formatDurationString(momentStartTime, momentEndTime);

    const timeDisplay = (
      <>
        {momentStartTime.format('h:mm A')} - {momentEndTime.format('h:mm A')}{' '}
        <span className="ml-small text-color-tertiary">{duration}</span>
      </>
    );

    // console.log(selectedSession);

    return (
      <>
        {/* Centering container */}
        <div className="flex-row justify-center height-full bg-white bordered-bottom pb-x-large">
          <div className="width-full" style={{ maxWidth: '1200px' }}>
            {/* Session content */}
            <div className="pt-large pb-x-large">
              <div className="mb-small">
                <SubTitle color={'tertiary'}>Session overview </SubTitle>
              </div>

              {/* session container */}
              <div className="ph-none flex-row align-center justify-between">
                {selectedSession && (
                  <>
                    <div className={'flex-row align-center justify-center'}>
                      {/* Day item container */}
                      <div
                        className="bg-white rounded-big text-align-right flex-column justify-center align-end line-height-120 shadow-box"
                        style={{ width: '80px', height: '80px', overflow: 'hidden' }}
                      >
                        <div className="bg-blue-action width-full" style={{ height: '4px' }} />

                        <div className="pv-small ph-medium flex-column width-full flex-1 justify-center align-center line-height-120">
                          <div>
                            <Text size={'x2-large'} color={'secondary'} lineHeight={120}>
                              {moment.tz(selectedSession.startDateTime, selectedSession.timezone).format('DD')}
                            </Text>
                          </div>

                          <div>
                            <Text size={'x2-large'} color={'secondary'} lineHeight={120}>
                              {startDateTime.format('MMM').toUpperCase()}
                            </Text>
                          </div>
                        </div>
                      </div>

                      <div className="pl-x2-large line-height-135">
                        <div className="mb-x-small flex-row align-center">
                          <HyperlinkButton
                            fontSize="x3-large"
                            // weight="bold"
                            className="mr-small"
                            lineHeight={100}
                            onClick={this._navToService}
                          >
                            {selectedSession.serviceName}
                          </HyperlinkButton>

                          <SessionStatusTag status={selectedSession.sessionStatus} />
                        </div>

                        <div className="flex-row flex-1">
                          <div className="line-height-135" style={{ width: '280px' }}>
                            <Text className={'mr-large'} size="x-large" lineHeight={135}>
                              {timeDisplay}
                            </Text>
                            <br />
                            <Text className={'mr-large'} size="large" lineHeight={135}>
                              {momentStartTime.format('dddd, D MMMM YYYY')}
                            </Text>
                          </div>

                          <div className="line-height-120" style={{ paddingLeft: '144px' }}>
                            <div
                              className="flex-row align-center hover-text-underline cursor-pointer"
                              onClick={() => goToTab('CUSTOMERS')}
                            >
                              <Icon type={'team'} className="mr-small" />
                              <Text lineHeight={135}>
                                {selectedSession.bookedCapacity}
                                {selectedSession.capacity ? '/' + selectedSession.capacity : ''} customer
                                {selectedSession.bookedCapacity !== 1 && 's'}
                              </Text>
                            </div>
                            <div
                              className="flex-row align-center hover-text-underline cursor-pointer"
                              onClick={() => goToTab('TEAM-MEMBERS')}
                            >
                              <Icon type={'team'} className="mr-small" />
                              <Text lineHeight={135}>
                                {selectedSession.totalAssignedShiftSlot}
                                {selectedSession.totalShiftSlot ? '/' + selectedSession.totalShiftSlot : ''} shift
                                {selectedSession.totalShiftSlot !== 1 && 's'}
                              </Text>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={'text-align-right'}>{this._getActionButtons()}</div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Modals */}
        <CancelSessionModal isOpen={this.state.isCancelSessionOpen} closeModal={this._closeCancelSession} />
        <RevertSessionModal
          isOpen={this.state.isRevertOpen}
          revertedToStatus={this.state.revertedToStatus}
          closeModal={this._closeRevert}
        />
      </>
    );
  };
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession
});

export default connect(
  mapState,
  null
)(GroupServiceSessionHeader);
