import { UserPermissionRole } from 'utilities/enum-utils';

const {
  Owner,
  PrimaryOwner,
  TeamManager,
  ServiceAdmin,
  CustomerManager,
  CustomerViewOnly,
  PaymentManager,
  PaymentMember,
  ServiceMember,
  TeamViewOnly,
  PlanManager,
} = UserPermissionRole;

export const userPermissions = {
  ViewTeamListing: {
    permissionRoles: [PrimaryOwner, Owner, TeamManager, TeamViewOnly],
    requireValidateServiceAccess: false,
  },

  ViewBookingListing: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: false,
  },
  ViewGroupSessions: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: false,
  },
  EditServiceDepartment: {
    permissionRoles: [PrimaryOwner],
    requireValidateServiceAccess: false,
  },
  ViewServiceDepartment: {
    permissionRoles: [PrimaryOwner],
    requireValidateServiceAccess: false,
  },
  ArchiveServiceDepartment: {
    permissionRoles: [PrimaryOwner],
    requireValidateServiceAccess: false,
  },
  ViewBookingDetail: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: true,
  },
  WaivePaymentBillingLineItem: {
    permissionRoles: [PrimaryOwner, Owner, PaymentManager, PaymentMember],
    requireValidateServiceAccess: false,
  },
  UnwaivePaymentBillingLineItem: {
    permissionRoles: [PrimaryOwner, Owner, PaymentManager, PaymentMember],
    requireValidateServiceAccess: false,
  },
  ApproveBooking: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin],
    requireValidateServiceAccess: true,
  },
  WaiveBooking: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin],
    requireValidateServiceAccess: true,
  },
  ResendInvoice: {
    permissionRoles: [PrimaryOwner, Owner, PaymentManager, PaymentMember],
    requireValidateServiceAccess: false,
  },
  RejectPaymentBillingLineItem: {
    permissionRoles: [PrimaryOwner, Owner, PaymentManager, PaymentMember],
    requireValidateServiceAccess: false,
  },
  ReApproveBillingLineItem: {
    permissionRoles: [PrimaryOwner, Owner, PaymentManager, PaymentMember],
    requireValidateServiceAccess: false,
  },
  EditPaymentBillingLineItem: {
    permissionRoles: [PrimaryOwner, Owner, PaymentManager, PaymentMember],
    requireValidateServiceAccess: false,
  },
  DeletePaymentBillingLineItem: {
    permissionRoles: [PrimaryOwner, Owner, PaymentManager],
    requireValidateServiceAccess: false,
  },
  ProcessPaymentBillingLineItem: {
    permissionRoles: [PrimaryOwner, Owner, PaymentManager],
    requireValidateServiceAccess: false,
  },
  CreateBookingBillingLineItem: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: true,
  },
  DownloadNDISFile: {
    permissionRoles: [PrimaryOwner, Owner, PaymentManager, PaymentMember],
    requireValidateServiceAccess: false,
  },
  DownloadFinanceFile: {
    permissionRoles: [PrimaryOwner, Owner, PaymentManager, PaymentMember],
    requireValidateServiceAccess: false,
  },
  ViewPayments: {
    permissionRoles: [PrimaryOwner, Owner, PaymentManager, PaymentMember],
    requireValidateServiceAccess: false,
  },
  ViewInvoices: {
    permissionRoles: [PrimaryOwner, Owner, PaymentManager, PaymentMember],
    requireValidateServiceAccess: false,
  },
  MarkInvoicesAsPaid: {
    permissionRoles: [PrimaryOwner, Owner, PaymentManager, PaymentMember],
    requireValidateServiceAccess: false,
  },
  MarkInvoicesAsUnPaid: {
    permissionRoles: [PrimaryOwner, Owner, PaymentManager, PaymentMember],
    requireValidateServiceAccess: false,
  },
  EditBookingCaseNote: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: true,
  },
  CreateBooking: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: true,
  },
  EditWorkerInBooking: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: true,
  },
  EditBookingLocation: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: true,
  },
  EditBookingBillingLineItems: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: true,
  },
  CancelBooking: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: true,
  },
  EditBookingTime: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: true,
  },
  AcceptBooking: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: true,
  },
  StartBooking: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: true,
  },
  FinishBooking: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: true,
  },
  ConfirmBooking: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: true,
  },
  DeclineBooking: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: true,
  },
  ViewServiceDepartmentListing: {
    permissionRoles: [PrimaryOwner],
    requireValidateServiceAccess: false,
  },
  ViewServiceDepartmentLiteListing: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin],
    requireValidateServiceAccess: false,
  },
  ChangePrimaryOwner: {
    permissionRoles: [PrimaryOwner],
    requireValidateServiceAccess: false,
  },
  EditServiceDetail: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin],
    requireValidateServiceAccess: true,
  },
  CreateService: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin],
    requireValidateServiceAccess: true,
  },
  PublishService: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin],
    requireValidateServiceAccess: true,
  },
  UnpublishService: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin],
    requireValidateServiceAccess: true,
  },
  ViewServiceListing: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: false,
  },
  ViewServiceLiteListing: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: false,
  },
  ViewServiceDetail: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin],
    requireValidateServiceAccess: true,
  },
  EditCustomerProfile: {
    permissionRoles: [PrimaryOwner, Owner, CustomerManager],
    requireValidateServiceAccess: false,
  },
  AddNewTeamMember: {
    permissionRoles: [PrimaryOwner, Owner, TeamManager],
    requireValidateServiceAccess: false,
  },
  ViewCustomerProfile: {
    permissionRoles: [PrimaryOwner, Owner, CustomerManager, CustomerViewOnly],
    requireValidateServiceAccess: false,
  },
  ViewCustomerListing: {
    permissionRoles: [PrimaryOwner, Owner, CustomerManager, CustomerViewOnly],
    requireValidateServiceAccess: false,
  },
  EditTeamMemberProfile: {
    permissionRoles: [PrimaryOwner, Owner, TeamManager],
    requireValidateServiceAccess: false,
  },
  ViewTeamManagement: {
    permissionRoles: [PrimaryOwner, Owner, TeamManager],
    requireValidateServiceAccess: false,
  },
  ViewTeamMemberProfile: {
    permissionRoles: [PrimaryOwner, Owner, TeamManager, TeamViewOnly],
    requireValidateServiceAccess: false,
  },
  EditCompanyDetail: {
    permissionRoles: [PrimaryOwner],
    requireValidateServiceAccess: false,
  },
  ViewCompanyDetail: {
    permissionRoles: [PrimaryOwner],
    requireValidateServiceAccess: false,
  },
  ViewServiceMessagesListing: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: false,
  },
  ViewServiceMessages: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: true,
  },
  DeleteDraftTeamMember: {
    permissionRoles: [PrimaryOwner, Owner, TeamManager],
    requireValidateServiceAccess: false,
  },
  RemoveTeamMember: {
    permissionRoles: [PrimaryOwner, Owner, TeamManager],
    requireValidateServiceAccess: false,
  },
  EditRemovalDate: {
    permissionRoles: [PrimaryOwner, Owner, TeamManager],
    requireValidateServiceAccess: false,
  },
  CancelRemovalDate: {
    permissionRoles: [PrimaryOwner, Owner, TeamManager],
    requireValidateServiceAccess: false,
  },
  BlockTeamMember: {
    permissionRoles: [PrimaryOwner, Owner, TeamManager],
    requireValidateServiceAccess: false,
  },
  InviteDraftTeamMember: {
    permissionRoles: [PrimaryOwner, Owner, TeamManager],
    requireValidateServiceAccess: false,
  },
  ResendInvites: {
    permissionRoles: [PrimaryOwner, Owner, TeamManager],
    requireValidateServiceAccess: false,
  },
  RevokeInvites: {
    permissionRoles: [PrimaryOwner, Owner, TeamManager],
    requireValidateServiceAccess: false,
  },
  UnblockTeamMember: {
    permissionRoles: [PrimaryOwner, Owner, TeamManager],
    requireValidateServiceAccess: false,
  },
  UpdateInvites: {
    permissionRoles: [PrimaryOwner, Owner, TeamManager],
    requireValidateServiceAccess: false,
  },
  EditTeamMemberPermissions: {
    permissionRoles: [PrimaryOwner, Owner, TeamManager],
    requireValidateServiceAccess: false,
  },
  ViewTeamMemberPermissions: {
    permissionRoles: [PrimaryOwner, Owner, TeamManager],
    requireValidateServiceAccess: false,
  },
  ViewCustomerLiteListing: {
    permissionRoles: [PrimaryOwner, Owner, CustomerManager, CustomerViewOnly, ServiceAdmin, ServiceMember],
    requireValidateServiceAccess: false,
  },
  ViewPlanManagement: {
    permissionRoles: [PrimaryOwner, Owner, PlanManager],
    requireValidateServiceAccess: false,
  },
  ViewFundingAndPayment: {
    permissionRoles: [PrimaryOwner, Owner],
    requireValidateServiceAccess: false,
  },
  ExportPlatformData: {
    permissionRoles: [PrimaryOwner, Owner],
    requireValidateServiceAccess: false,
  },
  ApproveCancelledBookingTeamMemberPay: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin],
    requireValidateServiceAccess: true,
  },
  EditCancelledBookingTeamMemberPayHours: {
    permissionRoles: [PrimaryOwner, Owner, ServiceAdmin],
    requireValidateServiceAccess: true,
  },
  ViewDebtorManagement: {
    permissionRoles: [PrimaryOwner, Owner, PaymentManager, PaymentMember],
    requireValidateServiceAccess: false,
  },
  EditCustomerDebtor: {
    permissionRoles: [PrimaryOwner, Owner, PaymentManager, PaymentMember, CustomerManager],
    requireValidateServiceAccess: false,
  },
};

export type UserPermission = keyof typeof userPermissions;
