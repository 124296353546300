import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { ErrorSVG } from '../../../../assets/UndrawSVG';
import { Paragraph, Text } from 'common-components/typography';
import { Checkbox, Col, Form, Icon, Row } from 'antd';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import TextArea from 'antd/es/input/TextArea';
import _ from 'lodash';
import moment from 'moment';
import CommonUtils from 'utilities/common-utils';
import { parse } from 'json2csv';

const fileDownload = require('js-file-download');

interface IRejectInvoiceLineActionModalProps extends FormComponentProps {
  isOpen: any;
  onClose: any;
  selectedRejectBillingItems: any[];
  doRejectBillingLineItemsFromProcessed: typeof dispatch.billingsStore.doRejectBillingLineItemsFromProcessed;
  selectedInvoiceLineItems: typeof state.billingsStore.selectedInvoiceLineItems;
  setSelectedRejectBillingItems: typeof dispatch.billingsStore.setSelectedRejectBillingItems;
}

const unit = {
  HOURS: 'H',
  EACH: 'EA',
};

const unitOptions = [{ value: unit.HOURS, label: 'hours' }, { value: unit.EACH, label: 'each' }];

class RejectInvoiceLineActionModal extends Component<IRejectInvoiceLineActionModalProps, any> {
  state = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    step: 1,
    title: 'Reject Invoice Line Item',
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false, isError: false, step: 1, title: 'Reject Invoice Line Item' });
      onClose();
    }
  };

  private _onSubmitReject = () => {
    const {
      form,
      doRejectBillingLineItemsFromProcessed,
      selectedRejectBillingItems,
      setSelectedRejectBillingItems,
    } = this.props;
    form.validateFields(async (err, value) => {
      if (!err) {
        this.setState({ isLoading: true });

        const rejectReason = value.note;
        try {
          const resp: any = await doRejectBillingLineItemsFromProcessed({
            billingLineItems: selectedRejectBillingItems,
            rejectReason: rejectReason,
          });
          setSelectedRejectBillingItems([]);

          if (resp.csvData) {
            fileDownload(parse(resp.csvData, { quote: '' }), `finance-rejection-file.csv`);
          }

          this.setState({ isLoading: false, isSuccess: true, title: 'Line item(s) successfully rejected.' });
        } catch (e) {
          console.log(e);
          this.setState({ isLoading: false, isError: true });
        }
      }
    });
  };

  private _onClickSelect = async (lineItem) => {
    const { selectedRejectBillingItems, setSelectedRejectBillingItems } = this.props;
    const { bookingBillingLineItemId, attendanceId, historyId } = lineItem;
    let newSelectedBillingItemIds = _.clone(selectedRejectBillingItems);
    const isAlreadySelected = _.find(
      selectedRejectBillingItems,
      (selectedItem) => selectedItem.billingLineItemId === bookingBillingLineItemId,
    );
    if (isAlreadySelected) {
      newSelectedBillingItemIds = _.filter(
        selectedRejectBillingItems,
        (lineItem) => lineItem.billingLineItemId !== bookingBillingLineItemId,
      );
    } else {
      const newItem = {
        billingLineItemId: bookingBillingLineItemId,
        attendanceId: attendanceId,
        historyId,
      };
      newSelectedBillingItemIds.push(newItem);
    }
    await setSelectedRejectBillingItems(newSelectedBillingItemIds);
  };

  private _selectAll = () => {
    const { selectedRejectBillingItems, selectedInvoiceLineItems, setSelectedRejectBillingItems } = this.props;
    const selectedInvoiceLineItemsWithoutRejected = _.filter(
      selectedInvoiceLineItems,
      (lineItem) => !lineItem.isRejected,
    );
    if (selectedInvoiceLineItemsWithoutRejected.length === selectedRejectBillingItems.length) {
      setSelectedRejectBillingItems([]);
    } else {
      const newSelectedBillingItemIds = [];
      _.map(selectedInvoiceLineItemsWithoutRejected, (lineItem) => {
        const newItem = {
          billingLineItemId: lineItem.bookingBillingLineItemId,
          attendanceId: lineItem.attendanceId,
        };
        newSelectedBillingItemIds.push(newItem);
      });
      setSelectedRejectBillingItems(newSelectedBillingItemIds);
    }
  };

  private _isSelected = (lineItem) => {
    const { selectedRejectBillingItems } = this.props;
    return !!_.find(
      selectedRejectBillingItems,
      (selectedItem) => selectedItem.billingLineItemId === lineItem.bookingBillingLineItemId,
    );
  };

  private _nextStep = () => {
    this.setState({ step: 2 });
  };

  private _previousStep = () => {
    this.setState({ step: 1 });
  };

  private _validateRejectReason = (rule, value, callback) => {
    try {
      if (_.isEmpty(_.trim(value))) {
        throw Error('Please enter a rejection reason');
      }
      if (_.trim(value).length < 5) {
        throw Error('Please enter at least 5 characters');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _onPreviewCreditNote = () => {
    const currentTime = moment().format('mm-ss-SSS');
    const rejectionReason = this.props.form.getFieldValue('note') ? this.props.form.getFieldValue('note') : 'N/A';
    const rejectedItemsData = {
      historyIds: _.map(this.props.selectedRejectBillingItems, (lineItem) => lineItem.historyId),
      rejectionReason,
    };
    localStorage.setItem(`previewCreditNoteLineItems-${currentTime}`, JSON.stringify(rejectedItemsData));
    window.open(`/pdf?type=preview-credit-note-by-line-items&time=${currentTime}`, '_blank');
  };

  render() {
    const { isOpen, selectedInvoiceLineItems, selectedRejectBillingItems, form } = this.props;
    const { getFieldDecorator } = form;

    const selectedInvoiceLineItemsWithoutRejected = _.filter(
      selectedInvoiceLineItems,
      (lineItem) => !lineItem.isRejected,
    );

    const isAllSelected = selectedInvoiceLineItemsWithoutRejected.length === selectedRejectBillingItems.length;
    const indeterminate = !isAllSelected && selectedRejectBillingItems.length > 0;

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="x-large"
        verticalAlignment="highest"
      >
        {!this.state.isLoading &&
          !this.state.isSuccess &&
          !this.state.isError &&
          this.state.step === 1 &&
          this.props.selectedInvoiceLineItems.length > 1 && (
            <div className="text-align-left" style={{ height: '50vh' }}>
              <div className="mb-medium">
                <Paragraph>Select the line items that you want to reject:</Paragraph>
                <div style={{ overflow: 'auto', height: 440 }} className="pv-large">
                  <Row className="pb-small bordered-bottom">
                    <Col span={1}>
                      <Checkbox checked={isAllSelected} indeterminate={indeterminate} onClick={this._selectAll} />
                    </Col>
                    <Col span={4}>Date</Col>
                    <Col span={5}>Line Item</Col>
                    <Col span={5}>Claim Reference</Col>
                    <Col span={3} className="text-align-right">
                      Detail
                    </Col>
                    <Col span={3} className="text-align-right">
                      Total
                    </Col>
                    <Col span={3} />
                  </Row>
                  {_.map(selectedInvoiceLineItems, (lineItem: any) => {
                    return (
                      <Row className="pv-small bordered-bottom border-secondary" type={'flex'} align={'middle'}>
                        <Col span={1}>
                          {!lineItem.isRejected && (
                            <Checkbox
                              checked={this._isSelected(lineItem)}
                              onClick={() => this._onClickSelect(lineItem)}
                            />
                          )}
                        </Col>
                        <Col span={4}>{moment(lineItem.startDateTime).format('DD/MM/YYYY')}</Col>
                        <Col span={5}>{lineItem.supportItemNumber}</Col>
                        <Col span={3}>{lineItem.claimReference}</Col>
                        <Col span={5} className="text-align-right">
                          {CommonUtils.formatQuantityDisplay(lineItem.qty)} (
                          {unitOptions.find((item) => item.value === lineItem.unit).label}) x{' '}
                          {CommonUtils.formatPrice(lineItem.unitPrice)}
                        </Col>
                        <Col span={3} className="text-align-right">
                          {CommonUtils.formatPrice(lineItem.billingTotal)}
                        </Col>
                        <Col span={3} className="text-align-right">
                          {lineItem.isRejected && (
                            <Text color="red" weight="bold">
                              <Icon type="close-circle" className="mr-x-small" theme="filled" />
                              Rejected
                            </Text>
                          )}
                        </Col>
                      </Row>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        {!this.state.isLoading &&
          !this.state.isSuccess &&
          !this.state.isError &&
          (this.state.step === 2 || this.props.selectedInvoiceLineItems.length === 1) && (
            <div className="text-align-left">
              <div className="mb-medium">
                <Paragraph>
                  You have decided to reject{' '}
                  <b>
                    {selectedRejectBillingItems.length} line item
                    {selectedRejectBillingItems.length && selectedRejectBillingItems.length > 1 && 's'}
                  </b>{' '}
                  from this invoice. This will move the line items to the ‘Rejected’ section of payments.
                </Paragraph>
              </div>

              <div className="flex-row align-left mb-medium">
                <Text>In order to reject this line item you must provide a rejection reason.</Text>
              </div>
              <div className="flex-row align-center justify-center mb-x-large">
                <Form.Item className="width-full">
                  {getFieldDecorator('note', {
                    rules: [{ validator: this._validateRejectReason }],
                  })(<TextArea placeholder="Rejection Reason(s)" autoSize={{ minRows: 4, maxRows: 4 }} />)}
                </Form.Item>
              </div>
            </div>
          )}
        {this.state.isLoading && <SpinningLoader size={100} message={'Loading'} />}
        {!this.state.isLoading && this.state.isSuccess && (
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>You have successfully rejected the selected line items</Paragraph>
              <Paragraph>All rejected line items have been moved to the rejected section of payments.</Paragraph>
            </div>
          </div>
        )}

        {!this.state.isSuccess &&
          !this.state.isError &&
          this.state.step === 1 &&
          this.props.selectedInvoiceLineItems.length > 1 && (
            <ActionModalFooter>
              <GhostButton
                size="large"
                onClick={this._onCloseModal}
                className="mr-medium"
                disabled={this.state.isLoading}
              >
                Close
              </GhostButton>
              <PrimaryButton
                size="large"
                disabled={_.isEmpty(this.props.selectedRejectBillingItems)}
                onClick={this._nextStep}
                loading={this.state.isLoading}
              >
                Next
              </PrimaryButton>
            </ActionModalFooter>
          )}
        {!this.state.isSuccess &&
          !this.state.isError &&
          (this.state.step === 2 || this.props.selectedInvoiceLineItems.length === 1) && (
            <ActionModalFooter>
              {this.props.selectedInvoiceLineItems.length > 1 ? (
                <GhostButton
                  size="large"
                  onClick={this._previousStep}
                  className="mr-medium"
                  disabled={this.state.isLoading}
                >
                  Go back
                </GhostButton>
              ) : (
                <GhostButton
                  size="large"
                  onClick={this._onCloseModal}
                  className="mr-medium"
                  disabled={this.state.isLoading}
                >
                  Close
                </GhostButton>
              )}
              <SecondaryButton
                size="large"
                className="mr-medium"
                disabled={this.state.isLoading}
                onClick={this._onPreviewCreditNote}
              >
                Preview credit note
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onSubmitReject} loading={this.state.isLoading}>
                Reject
              </PrimaryButton>
            </ActionModalFooter>
          )}
        {this.state.isSuccess && !this.state.isError && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {!this.state.isLoading && this.state.isError && (
          <div className="text-align-center">
            <div className="pv-medium">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium">
              <Paragraph>An error has occured.</Paragraph>
            </div>
          </div>
        )}

        {this.state.isError && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedRejectBillingItems: state.billingsStore.selectedRejectBillingItems,
  selectedInvoiceLineItems: state.billingsStore.selectedInvoiceLineItems,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRejectBillingLineItemsFromProcessed: dispatch.billingsStore.doRejectBillingLineItemsFromProcessed,
  setSelectedRejectBillingItems: dispatch.billingsStore.setSelectedRejectBillingItems,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<IRejectInvoiceLineActionModalProps>()(RejectInvoiceLineActionModal));
