import { IGroupServiceNote } from 'interfaces/service-interfaces';
import _ from 'lodash';
import { Avatar, Icon } from 'antd';
import { Text } from 'common-components/typography';
import moment from 'moment';
import { TagUserAvatar } from 'common-components/notes/TagUserAvatar';
import { Popover } from '@blueprintjs/core';
import { ActionMenu, ActionMenuItem } from 'common-components/action-menu';
import { IconButton } from 'common-components/buttons';
import React from 'react';
import TextTag from 'common-components/tags/TextTag';

export function BookingNoteItem({
  note,
  onEditNote,
  onDeleteNote
}: {
  note?: IGroupServiceNote;
  onEditNote: any;
  onDeleteNote: any;
}) {
  const hasEditor = !_.isEmpty(note.updatedBy);
  const hastaggedCustomers = !_.isEmpty(note.taggedTo);
  const authorName = `${note.authorFirstName || ''} ${note.authorLastName || ''}`;
  const editorName = `${note.updatedByFirstName || ''} ${note.updatedByLastName || ''}`;

  return (
    <div className="mb-medium p-medium bordered border-standard-gray rounded-big flex-row bg-white">
      {/* User avatar section */}
      <section className="flex-row mr-x-large" style={{ width: '200px' }}>
        <div className="mr-medium">
          <Avatar icon="user" size="large" shape="square" className="rounded" />
        </div>
        <div className="flex-column">
          <Text weight="bold">{authorName}</Text>
          <Text size="regular" color="secondary" className="whitespace-nowrap">
            {moment(note.createdOn).format('D MMM YYYY h:mm:ss A')}
          </Text>
        </div>
      </section>

      {/* Note content section */}
      <section className="mh-large flex-1">
        {/* Note tags*/}
        <div className="flex-row mb-medium">
          {/* Incident tag */}
          {/* TODO @ JIR Replace this with proper tags */}
          {note.isIncident && <TextTag content={'Incident'} color="red" className="mr-small" />}

          {/* Booking Note tag */}
          <TextTag
            content={
              <>
                <Icon type="calendar" className="mr-small" />
                <Text lineHeight={100} size={'regular'}>
                  Booking note
                </Text>
              </>
            }
            color={'tertiary'}
          />
        </div>

        {/* Note contents */}
        <div className="mv-small">
          <Text style={{ whiteSpace: 'pre-line' }} lineHeight={120}>
            {note.body}
          </Text>
        </div>

        {/* Tagged to */}
        {hastaggedCustomers && (
          <div className="mt-medium">
            <div className="mb-x-small">
              <Text size="small" color="secondary" lineHeight={100}>
                Tagged to
              </Text>
            </div>
            <div className="pt-medium ph-medium bordered border-standard-gray bg-quaternary rounded-big line-height-100">
              {/* Customers */}
              <div className="flex-row flex-wrap">
                {_.map(note.taggedTo, (customer) => (
                  <TagUserAvatar customer={customer} />
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Edited author/date display */}
        {hasEditor && (
          <div className="mt-medium flex-row align-center">
            {/*<Avatar icon="user" size="small" shape={'circle'} className="rounded mr-small" src={editorAvatar} />*/}
            <Text size="regular" lineHeight={100} color={'secondary'}>
              Edited by <b>{editorName}</b> on {moment(note.updatedOn).format('D MMM YYYY h:mm:ss A')}
            </Text>
          </div>
        )}
      </section>

      {/* Action button section */}
      <section>
        <Popover
          content={
            <ActionMenu>
              <ActionMenuItem text={'Edit note'} onClick={() => onEditNote(note)} />
              <ActionMenuItem className="text-color-red" text={'Delete note'} onClick={() => onDeleteNote(note)} />
            </ActionMenu>
          }
          position={'bottom-right'}
          usePortal={false}
        >
          <IconButton
            icon={'ellipsis'}
            size="default"
            iconColor={'blue-action'}
            color={'white'}
            bordered={true}
            className="border-standard-gray"
          />
        </Popover>
      </section>
    </div>
  );
}
