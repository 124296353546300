import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { IRootDispatch, dispatch } from 'src/stores/rematch/root-store';
import { Row } from 'antd';
import { ICustomView } from 'src/interfaces/custom-views-interface';
import { CustomViewsModalType } from 'utilities/enum-utils';

export interface ICustomerViewTabPopoverProps {
  currentTab: ICustomView;
  onHidePopOver: (isOpen: boolean) => void;
  onShowCustomerViewModal: (viewModalType?: CustomViewsModalType) => void;
  doUpdateCustomerViewTab: typeof dispatch.customersStore.doUpdateCustomerView;
}

export interface ICustomerViewTabPopoverState {
  isLoading: boolean;
  isSearching: boolean;
  filteredViews: any[];
}

export class CustomerViewTabPopover extends Component<ICustomerViewTabPopoverProps, ICustomerViewTabPopoverState> {
  private _onTogglePinView = () => {
    const { doUpdateCustomerViewTab, currentTab, onHidePopOver } = this.props;
    onHidePopOver(false);
    doUpdateCustomerViewTab({ ...currentTab, isPinned: !currentTab.isPinned, isTogglePinned: true });
  };

  private _onManageView = () => {
    const { onShowCustomerViewModal, onHidePopOver } = this.props;
    onHidePopOver(false);
    onShowCustomerViewModal(CustomViewsModalType.MANAGE);
  };

  private _onDuplicateView = () => {
    const { onShowCustomerViewModal, onHidePopOver } = this.props;
    onHidePopOver(false);
    onShowCustomerViewModal(CustomViewsModalType.DUPLICATE);
  };

  render() {
    const { currentTab } = this.props;

    return (
      <div style={{ margin: '-4px -16px' }}>
        <Row
          className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
          onClick={this._onTogglePinView}
        >
          {currentTab.isPinned ? 'Unpin view' : 'Pin view'}
        </Row>
        {currentTab.isOwner && (
          <Row
            className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
            onClick={this._onManageView}
          >
            Manage view
          </Row>
        )}
        <Row
          className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
          onClick={this._onDuplicateView}
        >
          Duplicate view
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doUpdateCustomerViewTab: dispatch.customersStore.doUpdateCustomerView
});

export default connect(
  null,
  mapDispatchToProps
)(CustomerViewTabPopover);
