import React, { Component } from 'react';
import { FieldLabel, Text, Title } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import DatePicker from 'react-datepicker';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { Form, Input, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Warning } from 'common-components/alerts';
import { PaymentSources, PaymentSourceType } from 'utilities/enum-utils';
import PermissionUtils from 'utilities/permission-utils';

interface IInputInfoPanelProps extends FormComponentProps {
  onPrevStep: any;
  onNextStep: any;
  newServiceAgreement: typeof state.customersStore.newServiceAgreement;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  companyDataLite: typeof state.companyStore.companyDataLite;
  setNewServiceAgreement: typeof dispatch.customersStore.setNewServiceAgreement;
  doCheckServiceAgreementDateValidity: typeof dispatch.customersStore.doCheckServiceAgreementDateValidity;
}

interface IInputInfoPanelState {
  isLoading: boolean;
  isOverlap: boolean;
  overlappingStartDate: Date | null;
  overlappingEndDate: Date | null;
  startDate: Date | null;
  endDate: Date | null;
  hasGap: boolean;
  isVCPEnabled: boolean;
}

class InputInfoPanel extends Component<IInputInfoPanelProps, IInputInfoPanelState> {
  state = {
    isLoading: false,
    isOverlap: false,
    overlappingStartDate: null,
    overlappingEndDate: null,
    startDate: null,
    endDate: null,
    hasGap: false,
    isVCPEnabled: false
  };

  private _validateEndDate = (rule, value, callback) => {
    try {
      const serviceProviderTimezone = this.props.companyDataLite ? this.props.companyDataLite.timezone : null;
      if (!value) {
        throw Error('Select an end date');
      } else if (
        moment.tz(value, serviceProviderTimezone) <=
        moment.tz(this.props.form.getFieldValue('startDate'), serviceProviderTimezone)
      ) {
        throw Error('End date must be after the start date.');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  private _validateStartEndDate = async (dateType, value) => {
    const { companyDataLite, form } = this.props;
    let { startDate, endDate } = this.state;
    const serviceProviderTimezone = companyDataLite ? companyDataLite.timezone : null;
    const paymentSourceType = form.getFieldValue('paymentSourceType');
    if (dateType === 'startDate') {
      startDate = moment.tz(value, serviceProviderTimezone);
      this.setState({ startDate }, () =>
        this._checkValidity(this.state.startDate, this.state.endDate, paymentSourceType)
      );
    } else {
      endDate = moment.tz(value, serviceProviderTimezone);
      this.setState({ endDate }, () =>
        this._checkValidity(this.state.startDate, this.state.endDate, paymentSourceType)
      );
    }
  };

  private _checkValidity = async (startDate, endDate, paymentSourceType) => {
    if (startDate && endDate && paymentSourceType) {
      this.setState({ isLoading: true });
      const result: any = await this.props.doCheckServiceAgreementDateValidity({
        startDateTime: startDate,
        endDateTime: endDate,
        paymentSourceType
      });
      this.setState({
        isOverlap: result && result.isOverlap,
        hasGap: result && result.hasGap,
        overlappingEndDate: result && result.overlappingEndDate,
        overlappingStartDate: result && result.overlappingStartDate,
        isLoading: false
      });
    }
  };

  private _goToNextStep = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    const { companyDataLite } = this.props;
    const serviceProviderTimezone = companyDataLite ? companyDataLite.timezone : null;

    const startDate = this.state.startDate
      ? this.state.startDate
      : moment.tz(this.props.form.getFieldValue('startDate'), serviceProviderTimezone);
    const endDate = this.state.endDate
      ? this.state.endDate
      : moment.tz(this.props.form.getFieldValue('endDate'), serviceProviderTimezone);
    const paymentSourceType = this.props.form.getFieldValue('paymentSourceType');
    const poNumber = this.props.form.getFieldValue('poNumber');

    if (isFormValid) {
      const { setNewServiceAgreement, newServiceAgreement, onNextStep } = this.props;
      this.setState({ isLoading: true });
      await setNewServiceAgreement({
        ...newServiceAgreement,
        startDate: startDate,
        endDate: endDate,
        paymentSourceType,
        poNumber
      });
      onNextStep();
      this.setState({ isLoading: false });
    }
  };

  private _onChangePaymentSourceType = async (event) => {
    const { setNewServiceAgreement, newServiceAgreement } = this.props;
    const startDate = this.state.startDate;
    const endDate = this.state.startDate;
    if (newServiceAgreement) {
      await setNewServiceAgreement({
        ...newServiceAgreement,
        quotations: [],
        services: []
      });
    }
    await this._checkValidity(startDate, endDate, event);
  };

  async componentDidMount() {
    const isVCPEnabled = await PermissionUtils.getIsServiceProviderVCPEnabled();
    if (isVCPEnabled) {
      this.setState({ isVCPEnabled: true });
    } else {
      this.setState({ isVCPEnabled: false });
    }
  }

  render() {
    const { newServiceAgreement, form, selectedCustomer, companyDataLite } = this.props;
    const { getFieldDecorator } = form;
    const { isOverlap, overlappingStartDate, overlappingEndDate, hasGap } = this.state;
    const serviceProviderTimezone = companyDataLite ? companyDataLite.timezone : null;

    const paymentSourceType = form.getFieldValue('paymentSourceType');

    return (
      <div className="anim-slide-left">
        <Title level={2}>
          Create <b>Service Agreement</b>
        </Title>
        <Text>
          You're creating a new <b>Service Agreement</b> for{' '}
          <b>
            {selectedCustomer.firstName} {selectedCustomer.lastName}
          </b>
          . Please fill out the following details.
        </Text>

        <div className="mt-large mr-large">
          <FieldLabel text={'Funding type'} />
          <Form.Item>
            {getFieldDecorator('paymentSourceType', {
              initialValue:
                newServiceAgreement && newServiceAgreement.paymentSourceType
                  ? newServiceAgreement.paymentSourceType
                  : null,
              rules: [
                {
                  required: true,
                  message: 'Select funding type'
                }
              ]
            })(
              <Select size={'large'} style={{ width: '200px' }} onChange={this._onChangePaymentSourceType}>
                <Select.Option value={PaymentSources.NDIS}>{PaymentSources.NDIS}</Select.Option>
                {this.state.isVCPEnabled && (
                  <Select.Option value={PaymentSources.VCP}>{PaymentSources.VCP}</Select.Option>
                )}
              </Select>
            )}
          </Form.Item>
        </div>

        <div className="mt-large flex-row">
          {/*  Content */}
          <div className="mr-large">
            <FieldLabel text={'START DATE'} />
            <Form.Item className="mb-none">
              {getFieldDecorator('startDate', {
                initialValue:
                  newServiceAgreement && newServiceAgreement.startDate
                    ? moment.tz(newServiceAgreement.startDate, serviceProviderTimezone).toDate()
                    : null,
                valuePropName: 'selected',
                rules: [
                  {
                    required: true,
                    message: 'Select a start date'
                  }
                ]
              })(
                <DatePicker
                  onChange={(value) => this._validateStartEndDate('startDate', value)}
                  className="gh-datepicker gh-datepicker-flat rounded"
                  calendarClassName="gh-datepicker-calendar"
                  dateFormat="dd/MM/yyyy"
                  placeholderText={'Select a date'}
                  autoComplete={null}
                />
              )}
            </Form.Item>
          </div>

          <div>
            <FieldLabel text={'END DATE'} />
            <Form.Item className="mb-none">
              {getFieldDecorator('endDate', {
                initialValue:
                  newServiceAgreement && newServiceAgreement.endDate
                    ? moment.tz(newServiceAgreement.endDate, serviceProviderTimezone).toDate()
                    : null,
                valuePropName: 'selected',
                rules: [
                  {
                    validator: this._validateEndDate
                  }
                ]
              })(
                <DatePicker
                  onChange={(value) => this._validateStartEndDate('endDate', value)}
                  className="gh-datepicker gh-datepicker-flat rounded"
                  calendarClassName="gh-datepicker-calendar"
                  dateFormat="dd/MM/yyyy"
                  placeholderText={'Select a date'}
                  autoComplete={null}
                  minDate={moment
                    .tz(form.getFieldValue('startDate'), serviceProviderTimezone)
                    .add(1, 'day')
                    .toDate()}
                />
              )}
            </Form.Item>
          </div>
        </div>
        {isOverlap && (
          <Warning
            color="red-dark"
            borderNone
            content={
              <Text color="red-dark">
                The dates selected overlap with an existing active <b>{paymentSourceType}</b> service agreement. <br />
                Dates of overlapping service agreement:{' '}
                <b>
                  {moment.tz(overlappingStartDate, serviceProviderTimezone).format('DD/MM/YYYY')} -{' '}
                  {moment.tz(overlappingEndDate, serviceProviderTimezone).format('DD/MM/YYYY')}
                </b>
              </Text>
            }
          />
        )}
        {hasGap && (
          <Warning
            borderNone
            content={<Text color="warning-orange">The dates selected result in a gap between service agreements</Text>}
          />
        )}
        {paymentSourceType === PaymentSourceType.VCP && (
          <div className="mt-large">
            <FieldLabel text={'Unique Code(Optional)'} />
            <Form.Item>
              {getFieldDecorator('poNumber', {
                initialValue: newServiceAgreement && newServiceAgreement.poNumber ? newServiceAgreement.poNumber : null
              })(<Input size={'large'} style={{ width: '400px' }} maxLength={50} placeholder="Enter PO number" />)}
            </Form.Item>
          </div>
        )}

        <div className="mb-x2-large flex-row justify-between mt-x2-large">
          <SecondaryButton size="large" onClick={this.props.onPrevStep} icon="left" iconPosition={'left'}>
            Previous
          </SecondaryButton>
          <PrimaryButton
            disabled={isOverlap}
            size="large"
            onClick={this._goToNextStep}
            loading={this.state.isLoading}
            icon="right"
            iconPosition={'right'}
          >
            Next
          </PrimaryButton>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  newServiceAgreement: state.customersStore.newServiceAgreement,
  selectedCustomer: state.customersStore.selectedCustomer,
  companyDataLite: state.companyStore.companyDataLite
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewServiceAgreement: dispatch.customersStore.setNewServiceAgreement,
  doCheckServiceAgreementDateValidity: dispatch.customersStore.doCheckServiceAgreementDateValidity
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IInputInfoPanelProps>()(InputInfoPanel));
