import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { connect } from 'react-redux';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { IRootState, state } from 'stores/rematch/root-store';
import moment from 'moment';

interface IViewWaivedReasonActionModelProps {
  isOpen: boolean;
  selectedItem: typeof state.planManagementStore.selectedItem;
  onClose: () => void;
}

interface IViewWaivedReasonActionModelState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
}

class ViewInvoiceRejectReasonActionModal extends Component<
  IViewWaivedReasonActionModelProps,
  IViewWaivedReasonActionModelState
> {
  state = {
    isLoading: false,
    isSuccess: false,
    isError: false
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false, isError: false });
      onClose();
    }
  };

  render() {
    const { isOpen, selectedItem } = this.props;

    return (
      selectedItem && (
        <ActionModal
          title="Rejection Reason"
          isOpen={isOpen}
          onClose={this._onCloseModal}
          width="medium"
          verticalAlignment="center"
        >
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>
                <b>
                  Rejected{' '}
                  {selectedItem.rejectedByFirstName &&
                    `by ${selectedItem.rejectedByFirstName} ${selectedItem.rejectedByLastName} `}
                </b>{' '}
                on <b>{moment(selectedItem.rejectedOn ? selectedItem.rejectedOn : new Date()).format('DD/MM/YYYY')}</b>
              </Paragraph>
            </div>
            <div className="mb-medium">
              <Paragraph>"{selectedItem.rejectReason}"</Paragraph>
            </div>
          </div>
          <ActionModalFooter>
            <PrimaryButton
              size="large"
              onClick={this._onCloseModal}
              className="mr-medium"
              disabled={this.state.isLoading}
            >
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      )
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedItem: state.planManagementStore.selectedItem
});

export default connect(
  mapState,
  null
)(ViewInvoiceRejectReasonActionModal);
