import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { Form, notification } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/es/form';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { PlanManagementClaimStatus } from 'utilities/enum-utils';
import _ from 'lodash';

interface IReSendToPaymentActionModalProps extends FormComponentProps {
  isOpen: boolean;
  selectedItem: typeof state.planManagementStore.selectedItem;
  selectedPlanManagementInvoiceDetails: typeof state.planManagementStore.selectedPlanManagementInvoiceDetails;
  onClose: () => void;
  refreshListing?: any;
  doReSendToPayments: typeof dispatch.planManagementStore.doReSendToPayments;
  doUnwaivePlanManagedInvoiceLineItems: typeof dispatch.planManagementStore.doUnwaivePlanManagedInvoiceLineItems;
  doFetchPlanManagementInvoiceDetails: typeof dispatch.planManagementStore.doFetchPlanManagementInvoiceDetails;
}

interface IReSendToPaymentActionModalState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  step: number;
}

class ReSendToPaymentActionModal extends Component<IReSendToPaymentActionModalProps, IReSendToPaymentActionModalState> {
  state = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    step: 1
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({ isLoading: false, isSuccess: false, isError: false, step: 1 });
      onClose();
    }
  };

  private _onSubmitReApprove = async () => {
    const {
      selectedPlanManagementInvoiceDetails,
      selectedItem,
      doReSendToPayments,
      doUnwaivePlanManagedInvoiceLineItems,
      doFetchPlanManagementInvoiceDetails
    } = this.props;
    try {
      await doFetchPlanManagementInvoiceDetails({
        planManagementInvoiceId: selectedItem.planManagementInvoiceId
      });

      const invoiceLineItemTotalWithoutCurrentLineItem = _.chain(selectedPlanManagementInvoiceDetails.lineItems)
        .filter((lineItem) => {
          return (
            lineItem.planManagementLineItemId !== selectedItem.planManagementLineItemId &&
            (lineItem.claimStatus === PlanManagementClaimStatus.UNCLAIMED ||
              lineItem.claimStatus === PlanManagementClaimStatus.SENT_TO_PAYMENTS ||
              lineItem.claimStatus === PlanManagementClaimStatus.REJECTED ||
              lineItem.claimStatus === PlanManagementClaimStatus.CLAIMED)
          );
        })
        .reduce((acc, item) => {
          return acc + Number(item.total);
        }, 0)
        .value();

      if (
        invoiceLineItemTotalWithoutCurrentLineItem + Number(selectedItem.total) >
        Number(selectedPlanManagementInvoiceDetails.invoiceTotal)
      ) {
        this.setState({ step: 2 });
      } else {
        this.setState({ isLoading: true });
        if (selectedItem.claimStatus === PlanManagementClaimStatus.WAIVED) {
          await doUnwaivePlanManagedInvoiceLineItems({
            invoiceId: selectedItem.planManagementInvoiceId,
            planManagementLineItemId: selectedItem.planManagementLineItemId
          });
        } else {
          await doReSendToPayments({
            invoiceId: selectedItem.planManagementInvoiceId,
            planManagementLineItemId: selectedItem.planManagementLineItemId,
            total: selectedItem.total
          });
        }
        if (this.props.refreshListing) {
          await this.props.refreshListing();
        }
        this.setState({ isLoading: false });
        this._onCloseModal();
      }
    } catch (e) {
      this.setState({ isLoading: false });
      notification.error({ message: 'Error', description: 'Oops. Something went wrong, please try again later' });
    }
  };

  render() {
    const { isOpen } = this.props;
    return (
      <ActionModal
        title={this.state.step === 1 ? 'Re-send line item to payments' : 'Error'}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="medium"
        verticalAlignment="highest"
      >
        {this.state.step === 1 && (
          <>
            <div className="text-align-left">
              <div className="mb-medium">
                <Paragraph>Are you sure you want to re-send this line item to payments?</Paragraph>
              </div>
              <Paragraph>
                This will allow it to be re-processed. Please ensure you have made changes so that it will be accepted
                by the NDIS.
              </Paragraph>
            </div>
            <ActionModalFooter>
              <GhostButton
                size="large"
                onClick={this._onCloseModal}
                className="mr-medium"
                disabled={this.state.isLoading}
              >
                Not now
              </GhostButton>
              <PrimaryButton size="large" onClick={this._onSubmitReApprove} loading={this.state.isLoading}>
                Re-send to payments
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {this.state.step === 2 && (
          <>
            <div className="text-align-left">
              <div className="mb-medium">
                <Paragraph>
                  The line item you are trying to re-send to payments will result in the total value of claims for this
                  invoice being greater than the invoice total.
                </Paragraph>
              </div>
              <Paragraph>
                Please double check the claims/invoice total before adding this line item. (Waived line items do not
                contribute to the claim total).
              </Paragraph>
            </div>
            <ActionModalFooter>
              <GhostButton
                size="large"
                onClick={this._onCloseModal}
                className="mr-medium"
                disabled={this.state.isLoading}
              >
                Close
              </GhostButton>
            </ActionModalFooter>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedItem: state.planManagementStore.selectedItem,
  selectedPlanManagementInvoiceDetails: state.planManagementStore.selectedPlanManagementInvoiceDetails
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doReSendToPayments: dispatch.planManagementStore.doReSendToPayments,
  doUnwaivePlanManagedInvoiceLineItems: dispatch.planManagementStore.doUnwaivePlanManagedInvoiceLineItems,
  doFetchPlanManagementInvoiceDetails: dispatch.planManagementStore.doFetchPlanManagementInvoiceDetails
});

export default connect(
  mapState,
  mapDispatch
)(ReSendToPaymentActionModal);
