/* eslint-disable */
import apiClient from 'utilities/api-client';
import _ from 'lodash';
import { message } from 'antd';
import rootStore from 'stores/rematch/root-store';
import Axios, { AxiosRequestConfig } from 'axios';
import {
  DocumentExpiryStatus,
  InvitationStatus,
  ServiceType,
  TeamStatus,
  UserPermissionRole,
  FilterType,
  CustomViewsType
} from 'utilities/enum-utils';
import moment from 'moment';

const fileDownload = require('js-file-download');

const mapWorkerViewFilterPayload = (filterValue) => {
  // ignore search object in filterValue
  // re map filter type customer
  const payload =
    filterValue &&
    _.cloneDeep(filterValue)
      .filter((filter) => !Object.keys(filter).includes('search'))
      .map((filter) => {
        if (Object.keys(filter).includes(FilterType.CUSTOMER)) {
          filter[FilterType.CUSTOMER] = _.map(filter[FilterType.CUSTOMER], (customer) => {
            const { name, value, displayText } = customer;
            return { displayText: name || displayText, value };
          });
        }
        return filter;
      });
  return payload;
};

const teamStore = {
  state: {
    teamList: [],
    workerList: [],
    workerPageList: [],
    workerListLite: [],
    accountTeamList: [],
    invitesTeamList: null,
    fuzzyMatchTeamMember: null,
    filteredWorkerList: [],
    selectedWorker: null,
    selectedPortalUser: null,
    newSupportWorker: '',
    workerFilter: null,
    workerPageFilter: null,
    hasWorkerFilterChanged: false,
    accountTeamFilter: {
      filter: {
        status: { isEnabled: false, isBlocked: false, isDraft: false, isRemoved: false },
        permission: { isOwner: false, hasPortalAccess: false, hasWorkerAppAccess: false },
        billable: { isBillable: false, isNotBillable: false }
      },
      search: null
    },
    invitesTeamFilter: null,
    adminList: [],
    selectedAdmin: null,
    filteredAdminList: [],
    pendingInvitations: [],
    SentWorkerInvitations: [],
    SentConsumerInvitations: [],
    SentPortalUserInvitations: [],
    totalTeamBookingList: 0,
    workerDocuments: [],
    workerViews: [],
    defaultWorkerViews: [
      {
        customViewId: 'TEAM_MEMBERS',
        name: 'Team members',
        isPinned: true,
        isDefault: true,
        isCustomer: false,
        viewType: CustomViewsType.EVERYONE,
        filterValue: [{ [FilterType.WORKER]: [] }, { [FilterType.SERVICE]: [] }]
      }
    ],
    displayedWorkerListingTabs: [],
    workerListingActiveTab: null,
    memberAvailabilities: {
      availabilities: [],
      unavailableTimes: [],
      availabilityTimezone: '',
      userTimezone: '',
      availabilityCycle: 'weekly',
      availabilityActiveWeek: 1,
      activeDate: null
    }
  },
  reducers: {
    setTeamList: (state, payload) => ({ ...state, teamList: payload }),
    setWorkerList: (state, payload) => ({ ...state, workerList: payload }),
    setWorkerPageList: (state, payload) => ({ ...state, workerPageList: payload }),
    setWorkerListLite: (state, payload) => ({ ...state, workerListLite: payload }),
    setAccountTeamList: (state, payload) => ({ ...state, accountTeamList: payload }),
    setInvitesTeamList: (state, payload) => ({ ...state, invitesTeamList: payload }),
    setSelectedWorker: (state, payload) => ({ ...state, selectedWorker: payload }),
    setNewSupportWorker: (state, payload) => ({ ...state, newSupportWorker: payload }),
    setFilteredWorkerList: (state, payload) => ({ ...state, filteredWorkerList: payload }),
    setWorkerFilter: (state, payload) => ({ ...state, workerFilter: payload }),
    setWorkerPageFilter: (state, payload) => ({ ...state, workerPageFilter: payload }),
    setHasWorkerFilterChanged: (state, payload) => ({ ...state, hasWorkerFilterChanged: payload }),
    setAccountTeamFilter: (state, payload) => ({ ...state, accountTeamFilter: payload }),
    setInvitesTeamFilter: (state, payload) => ({ ...state, invitesTeamFilter: payload }),
    setAdminList: (state, payload) => ({ ...state, adminList: payload }),
    setFilteredAdminList: (state, payload) => ({ ...state, filteredAdminList: payload }),
    setPendingInvitations: (state, payload) => ({ ...state, pendingInvitations: payload }),
    setSentWorkerInvitations: (state, payload) => ({ ...state, SentWorkerInvitations: payload }),
    setSentConsumerInvitations: (state, payload) => ({ ...state, SentConsumerInvitations: payload }),
    setSentPortalUserInvitations: (state, payload) => ({ ...state, SentPortalUserInvitations: payload }),
    setTotalTeamBookingList: (state, payload) => ({ ...state, totalTeamBookingList: payload }),
    setWorkerDocuments: (state, payload) => ({ ...state, workerDocuments: payload }),
    setFuzzyMatchTeamMember: (state, payload) => ({ ...state, fuzzyMatchTeamMember: payload }),
    setWorkerViews: (state, payload) => ({ ...state, workerViews: payload }),
    setDisplayedWorkerListingTabs: (state, payload) => {
      const { workerViews } = state;
      const pinnedViews = workerViews.filter((view) => view.isPinned);
      const views = [...pinnedViews, ...payload];
      const uniqueTabs = _.uniqBy(views, 'customViewId');
      return { ...state, displayedWorkerListingTabs: uniqueTabs };
    },
    setWorkerListingActiveTab: (state, payload) => ({ ...state, workerListingActiveTab: payload }),
    removeWorkerDocument: (state, payload) => ({
      ...state,
      workerDocuments: _.filter(state.workerDocuments, (document) => document.documentId !== payload)
    }),
    archiveWorkerDocument: (state, payload) => ({
      ...state,
      workerDocuments: _.map(state.workerDocuments, (document) => {
        if (document.documentId === payload) {
          return { ...document, isArchived: true };
        } else {
          return { ...document };
        }
      })
    }),
    addWorkerDocument: (state, payload) => {
      const existingDocuments = state.workerDocuments;
      const addedDocument = [
        {
          documentId: payload.documentId,
          documentName: payload.documentName,
          description: payload.description,
          createdOn: new Date(),
          documentUrl: null,
          status: 'SCANNING',
          firstName: payload.firstName,
          lastName: payload.lastName,
          expiryDate: payload.expiryDate,
          expiryStatus: payload.expiryStatus,
          isArchived: false,
          isCompliant: payload.isCompliant
        }
      ];
      return { ...state, workerDocuments: addedDocument.concat(existingDocuments) };
    },
    editWorkerDocument: (state, payload) => {
      return {
        ...state,
        workerDocuments: _.map(state.workerDocuments, (document) => {
          if (document.documentId === payload.documentId) {
            return {
              ...document,
              description: payload.description,
              expiryDate: payload.expiryDate,
              expiryStatus: !payload.expiryDate
                ? 'NOT_EXPIRED'
                : payload.expiryDate < moment().startOf('day')
                ? 'EXPIRED'
                : moment(payload.expiryDate)
                    .startOf('day')
                    .diff(moment().startOf('day'), 'days') <= 7
                ? 'EXPIRING_SOON'
                : 'NOT_EXPIRED'
            };
          } else {
            return { ...document };
          }
        })
      };
    },
    appendWorkerDocuments: (state, payload) => {
      const existingDocuments = state.workerDocuments;
      return { ...state, workerDocuments: existingDocuments.concat(payload) };
    },
    setWorkerDocumentStatus: (state, payload) => {
      const updatedDocuments = _.map(state.workerDocuments, (document) => {
        if (document.documentId === payload.documentId) {
          return {
            ...document,
            status: payload.status,
            documentUrl: payload.documentUrl,
            uniqueId: payload.uniqueId
          };
        } else {
          return { ...document };
        }
      });
      return { ...state, workerDocuments: updatedDocuments };
    },
    updateTeamMemberStatus: (state, payload) => {
      let updatedAccountTeamList = { ...state.accountTeamList };
      updatedAccountTeamList = _.map(updatedAccountTeamList, (teamMember) => {
        if (teamMember.supportWorkerId === payload.supportWorkerId) {
          return {
            ...teamMember,
            status: payload.status,
            invitationStatus: payload.invitationStatus ? payload.invitationStatus : teamMember.invitationStatus
          };
        } else {
          return { ...teamMember };
        }
      });
      return { ...state, accountTeamList: updatedAccountTeamList };
    },
    updateTeamMemberRemoveReason: (state, payload) => {
      const updatedAccountTeamList = _.map(state.accountTeamList, (teamMember) => {
        return teamMember.supportWorkerId === payload.supportWorkerId
          ? {
              ...teamMember,
              removeReason: payload.removeReason
            }
          : { ...teamMember };
      });
      return { ...state, accountTeamList: updatedAccountTeamList };
    },
    updateTeamMemberPermissions: (state, payload) => {
      let updatedAccountTeamList = { ...state.accountTeamList };
      updatedAccountTeamList = _.map(updatedAccountTeamList, (teamMember) => {
        if (teamMember.supportWorkerId === payload.supportWorkerId) {
          const ownerRole = _.find(
            payload.permissionRoles,
            (role) => role.permissionRole === UserPermissionRole.PrimaryOwner
          )
            ? UserPermissionRole.PrimaryOwner
            : _.find(payload.permissionRoles, (role) => role.permissionRole === UserPermissionRole.Owner)
            ? UserPermissionRole.Owner
            : 999;
          return {
            ...teamMember,
            hasPortalAccess: payload.hasPortalAccess,
            hasWorkerAppAccess: payload.hasWorkerAppAccess,
            ownerRole
          };
        } else {
          return { ...teamMember };
        }
      });
      return { ...state, accountTeamList: updatedAccountTeamList };
    },
    changeInvitationFromRevokedToPending: (state, payload) => {
      let updatedInvitesTeamList = { ...state.invitesTeamList };
      updatedInvitesTeamList.pendingList.push(payload.newInvite);
      updatedInvitesTeamList.revokedList = _.filter(
        updatedInvitesTeamList.revokedList,
        (invite) => invite.token !== payload.oldToken
      );
      return { ...state, invitesTeamList: updatedInvitesTeamList };
    },
    changeInvitationFromPendingToRevoked: (state, payload) => {
      let updatedInvitesTeamList = { ...state.invitesTeamList };
      updatedInvitesTeamList.revokedList.push(payload.newInvite);
      updatedInvitesTeamList.pendingList = _.filter(
        updatedInvitesTeamList.pendingList,
        (invite) => invite.token !== payload.oldToken
      );
      return { ...state, invitesTeamList: updatedInvitesTeamList };
    },
    modifyInvitation: (state, payload) => {
      const updatedInvitesTeamList = {
        ...state.invitesTeamList,
        pendingList: _.map({ ...state.invitesTeamList.pendingList }, (invite) => {
          if (invite.token === payload.oldToken) {
            return { ...payload.newInvite };
          } else {
            return { ...invite };
          }
        })
      };
      return { ...state, invitesTeamList: updatedInvitesTeamList };
    },
    updateMemberAvailabilities: (state, payload) => {
      return {
        ...state,
        memberAvailabilities: {
          availabilities: payload.availabilities,
          unavailableTimes: payload.unavailableTimes,
          availabilityTimezone: payload.availabilityTimezone,
          userTimezone: payload.userTimezone,
          availabilityCycle: payload.availabilityCycle,
          availabilityActiveWeek: payload.availabilityActiveWeek,
          activeDate: payload.activeDate
        }
      };
    }
  },
  effects: (dispatch) => ({
    async doFetchWorkerList(payload, rootState) {
      const filter = rootState.teamStore.workerFilter;
      let filterData = {};

      if (!_.isEmpty(filter)) {
        _.map(filter, (filterObject) => {
          filterData = { ...filterData, [filterObject.key]: filterObject.value };
        });
      }

      if (payload.sortByRelevance) {
        filterData = { ...filterData, sortByRelevance: payload.sortByRelevance };
      }

      let result = await apiClient.post(`/api/portal/support-worker/list`, filterData);

      if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
        const workerList = _.map(result.data, (item) => {
          let firstName;
          let lastName;
          if (item.firstName) {
            firstName = item.firstName;
            lastName = item.lastName;
          } else {
            firstName = item.firstName;
            lastName = item.lastName;
          }
          return {
            ...item,
            firstName: firstName,
            lastName: lastName
          };
        });

        const workerData = workerList;
        // const workerData = _.orderBy(workerList, 'firstName', 'desc');

        dispatch.teamStore.setWorkerList(workerData);
        dispatch.teamStore.setFilteredWorkerList(workerData);
      } else {
        dispatch.teamStore.setWorkerList();
        dispatch.teamStore.setFilteredWorkerList();
      }

      // console.log('filterData', filterData);

      // dispatch.userStore.increment(payload);
      // console.log('fetching services...');
      // // Mock data
      // dispatch.servicesStore.setServices(mockServicesData);
      // console.log('services fetched.');
    },

    async doFetchWorkerPageList(payload, rootState) {
      const filter = rootState.teamStore.workerPageFilter;
      let filterData = {};

      if (!_.isEmpty(filter)) {
        _.map(filter, (filterObject) => {
          if (filterObject.filter === 'supportWorkerIds') {
            filterData = {
              ...filterData,
              [filterObject.filter]: _.map(filterObject.values, (worker) => {
                return worker.value;
              })
            };
          } else {
            filterData = { ...filterData, [filterObject.filter]: filterObject.values };
          }
        });
        if (filter.search) {
          filterData = { ...filterData, ['search']: filter.search };
        }
      }
      if (payload.sortByRelevance) {
        filterData = { ...filterData, sortByRelevance: payload.sortByRelevance };
      }

      let result = await apiClient.post(`/api/portal/support-worker/list`, filterData);

      if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
        const workerList = _.map(result.data, (item) => {
          const firstName = item.firstName;
          const lastName = item.lastName;

          return {
            ...item,
            firstName,
            lastName
          };
        });

        dispatch.teamStore.setWorkerPageList(workerList);
      } else {
        dispatch.teamStore.setWorkerPageList();
      }
    },

    //The original method doesn't use the payload, I changed it to an optional argument
    //so that it can be used for search feature.
    async doFetchWorkerListLite(payload = null, rootState) {
      let result = await apiClient.post(`/api/portal/support-worker/lite-list`, payload);

      if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
        const workerList = _.map(result.data, (item) => {
          let firstName;
          let lastName;
          if (item.firstName) {
            firstName = item.firstName;
            lastName = item.lastName;
          } else {
            firstName = item.firstName;
            lastName = item.lastName;
          }
          return {
            ...item,
            firstName: firstName,
            lastName: lastName
          };
        });

        dispatch.teamStore.setWorkerListLite(workerList);
      } else {
        dispatch.teamStore.setWorkerListLite([]);
      }
    },

    async doFetchAccountTeamList(payload, rootState) {
      const filterList = rootState.teamStore.accountTeamFilter;
      payload.filter = filterList.filter;
      if (payload && !payload.search && filterList.search !== undefined) {
        payload.search = filterList.search;
      }

      let result = await apiClient.post(`/api/portal/account/workers/list`, payload);
      let accountTeamData = result.data;

      if (payload.page > 1) {
        accountTeamData = rootState.teamStore.accountTeamList.concat(accountTeamData);
      }

      dispatch.teamStore.setAccountTeamList(accountTeamData);
    },

    async doFetchInvitations(payload, rootState) {
      const filterList = rootState.teamStore.invitesTeamFilter;
      if (payload && !payload.search && filterList && filterList.search !== undefined) {
        payload.search = filterList.search;
      }
      let result = await apiClient.post(`/api/portal/account/invitations`, payload);
      let inviteTeamData = result.data;

      dispatch.teamStore.setInvitesTeamList(inviteTeamData);
    },

    async doFetchSelectedWorker(payload, rootState) {
      const { supportWorkerId } = payload;
      const endpoint = `/api/portal/support-worker/${supportWorkerId}`;
      try {
        const result = await apiClient.get(endpoint);
        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          dispatch.teamStore.setSelectedWorker(result.data);
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doCheckIfUserExists(payload, rootState) {
      const { mobile } = payload;
      // Replace 04 numbers with +61
      let finalMobile = mobile;
      if (mobile.substr(0, 2) === '04') {
        finalMobile = `+61${mobile.trim().substr(1, 20)}`;
      }

      // check to see if mobile no starts with +. If not, append + to the front.
      if (finalMobile.substr(0, 1) !== '+') {
        finalMobile = '+' + finalMobile;
      }
      const endpoint = `/api/portal/support-worker/search?mobile=${encodeURIComponent(finalMobile)}`;
      try {
        const result = await apiClient.get(endpoint);
        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          if (_.isEmpty(result.data.uid)) {
            dispatch.teamStore.setNewSupportWorker(null);
          } else {
            dispatch.teamStore.setNewSupportWorker(result.data);
          }
        } else {
          dispatch.teamStore.setNewSupportWorker(null);
        }
      } catch (e) {
        console.log(e);
        dispatch.teamStore.setNewSupportWorker(null);
        throw e;
      }
    },

    /* Send invitations to support worker */

    async doSendInvitation(payload, rootState) {
      const endPoint = `/api/portal/support-worker/invite`;
      const body = payload;
      // console.log(body);
      try {
        const result = await apiClient.post(endPoint, body);
        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          dispatch.teamStore.setSentWorkerInvitations(result.data);
        } else {
          return result;
        }
      } catch (e) {
        message.error(e.message);
        throw e;
      }
    },

    /* Fetch pending invitations */

    async doFetchPendingInvitation(body, rootState) {
      const endPoint = `/api/portal/user/invite/list`;

      try {
        const result = await apiClient.post(endPoint, body);
        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          dispatch.teamStore.setPendingInvitations(result.data);
        } else {
          return result;
        }
      } catch (e) {
        message.error(e.message);
        throw e;
      }
    },

    /* Fetch team members booking list */
    async doFetchTeamBookingList(payload, rootState) {
      payload.supportWorkerIds = [rootState.teamStore.selectedWorker.supportWorkerId];
      payload.sort = [['startDateTime', 'desc']];
      payload.serviceType = [ServiceType.INDIVIDUAL];
      // Pagination is handled in the front end for now
      // TODO Change pagination to being handled in the backend
      // payload.page = payload.page !== undefined ? payload.page : 1;
      // payload.pageSize = payload.pageSize !== undefined ? payload.pageSize : 10;
      // payload.pageTimestamp = payload.pageTimestamp !== undefined ? payload.pageTimestamp : moment().format();
      let result = await apiClient.post(`/api/portal/bookings/list`, payload);

      try {
        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          //dispatch.teamStore.setTotalTeamBookingList();
          return result.data;
        } else {
          return [];
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    /* Update team member profile */

    async doUpdateTeamProfile(payload, rootState) {
      const endPoint = `/api/portal/support-worker/${rootState.teamStore.selectedWorker.supportWorkerId}`;

      try {
        const result = await apiClient.put(endPoint, payload);
        if (!_.isEmpty(result)) {
          await this.doFetchSelectedWorker({ supportWorkerId: rootState.teamStore.selectedWorker.supportWorkerId });
        }
      } catch (e) {
        message.error(e.message);
        return 0;
      }
    },

    /* Portal User Cancel User Invitation */

    async doCancelInvitation(payload, rootState) {
      const endPoint = `/api/portal/service-provider/invitation`;
      let body = {
        invitationTokens: payload
      };
      try {
        const result = await apiClient.delete(endPoint, body);
        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          return 1;
        } else {
          return 0;
        }
      } catch (e) {
        message.error(e.message);
        return 0;
      }
    },

    /* Portal User Resend User Invitation */

    async doResendInvitation(payload, rootState) {
      const endPoint = `/api/portal/service-provider/invitation`;
      let body = {
        invitationTokens: payload
      };
      try {
        const result = await apiClient.put(endPoint, body);
        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          return 1;
        } else {
          return 0;
        }
      } catch (e) {
        message.error(e.message);
        return 0;
      }
    },

    async doSendInvitationToMobile(payload, rootState) {
      const { mobile } = payload;
      let finalMobile = mobile;
      if (mobile.substr(0, 2) === '04') {
        finalMobile = `+61${mobile.trim().substr(1, 20)}`;
      }

      // check to see if mobile no starts with +. If not, append + to the front.
      if (finalMobile.substr(0, 1) !== '+') {
        finalMobile = '+' + finalMobile;
      }

      const endPoint = `/api/portal/support-worker/invitation`;
      const body = {
        mobile: finalMobile
      };
      try {
        const result = await apiClient.post(endPoint, body);
        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          return result.data;
        } else {
          return result;
        }
      } catch (e) {
        console.error(e);
        return e;
      }
    },

    async doSendInvitationToUserId(payload, rootState) {
      const { userId, mobile } = payload;
      let finalMobile = mobile;
      if (mobile.substr(0, 2) === '04') {
        finalMobile = `+61${mobile.trim().substr(1, 20)}`;
      }

      // check to see if mobile no starts with +. If not, append + to the front.
      if (finalMobile.substr(0, 1) !== '+') {
        finalMobile = '+' + finalMobile;
      }
      const endpoint = `/api/portal/support-worker/add`;
      const body = {
        mobile: finalMobile,
        userId
      };
      try {
        const result = await apiClient.post(endpoint, body);
        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          return result.data;
        } else {
          return result;
        }
      } catch (e) {
        console.error(e);
        return e;
      }
    },

    doApplyWorkerFilter(payload, rootState) {
      if (_.isEmpty(payload)) {
        dispatch.teamStore.setWorkerFilter(null);
        dispatch.teamStore.setFilteredWorkerList(rootState.teamStore.workerList);
      } else {
        const filters = _.chain(payload)
          .keys()
          .filter((key) => !_.isEmpty(payload[key]))
          .map((key) => {
            let filterFunction = (item) => true;

            const value = _.map(payload[key], (item) => {
              return item;
            });

            if (key === 'firstName') {
              filterFunction = (item) => _.includes(value, item.firstName);
            }

            if (key === 'lastName') {
              filterFunction = (item) => _.includes(value, item.lastName);
            }
            return filterFunction;
          })
          .value();

        let list = rootState.teamStore.workerList;

        for (let filter of filters) {
          list = list.filter(filter);
        }

        dispatch.teamStore.setFilteredWorkerList(list);
        dispatch.teamStore.setWorkerFilter(payload);
      }
    },

    async doGetTimeAvailability(payload, rootState) {
      const endPoint = `/api/portal/support-worker/${rootState.teamStore.selectedWorker.supportWorkerId}/time-availability`;
      try {
        const result = await apiClient.get(endPoint);

        dispatch.teamStore.updateMemberAvailabilities({
          availabilities: result.data.timeAvailability,
          unavailableTimes: result.data.timeUnavailability,
          availabilityTimezone: result.data.availabilityTimezone,
          userTimezone: result.data.userTimezone,
          availabilityCycle: result.data.availabilityWeekType,
          availabilityActiveWeek: result.data.weeklyCycleActive,
          activeDate: result.data.activeFrom
        });
      } catch (e) {
        dispatch.teamStore.updateMemberAvailabilities({
          availabilities: [],
          unavailableTimes: [],
          availabilityTimezone: '',
          userTimezone: '',
          availabilityCycle: 'weekly',
          availabilityActiveWeek: 1,
          activeDate: null
        });
        console.error(e);
        throw e;
      }
    },

    async doUpdateTimeAvailability(payload, rootState) {
      if (!_.isEmpty(payload)) {
        const endPoint = `/api/portal/support-worker/${rootState.teamStore.selectedWorker.supportWorkerId}/time-availability`;
        const data = {
          timeAvailability: payload.timeAvailability,
          shiftsSelectedToBeKept: payload.shiftsToBeKept,
          timezone: payload.timezone
        };
        try {
          const result = await apiClient.put(endPoint, data);
          dispatch.teamStore.doGetTimeAvailability({});
        } catch (e) {
          console.error(e);
          throw e;
        }
      }
    },

    async doSwitchAvailabilityCycle(payload, rootState) {
      if (!_.isEmpty(payload)) {
        const endPoint = `/api/portal/support-worker/${
          rootState.teamStore.selectedWorker.supportWorkerId
        }/time-availability/${payload.type === 'fortnightly' ? 'switch-two-week-cycle' : 'switch-one-week-cycle'}`;
        try {
          if (payload.type === 'fortnightly') {
            await apiClient.post(endPoint);
          } else {
            await apiClient.post(endPoint, {
              defaultAvailabilityFromWeek: payload.selectedWeek,
              shiftsSelectedToBeKept: payload.type === 'fornightly' ? [] : payload.selectedShiftsToBeKept
            });
          }
          dispatch.teamStore.doGetTimeAvailability({});
        } catch (e) {
          console.error(e);
          throw e;
        }
      }
    },

    async doCheckAvailabilityConflict(payload, rootState) {
      if (!_.isEmpty(payload)) {
        const endPoint = `/api/portal/support-worker/${rootState.teamStore.selectedWorker.supportWorkerId}/check-support-worker-time-availabilities-unavailabilities
        `;
        const data = payload;
        try {
          const result = await apiClient.post(endPoint, data);
          return result.data.shiftsOutsideTimeAvailabilities;
        } catch (e) {
          console.error(e);
          throw e;
        }
      }
    },

    async doAddUnavailableTime(payload, rootState) {
      if (!_.isEmpty(payload)) {
        const endPoint = `/api/portal/support-worker/${rootState.teamStore.selectedWorker.supportWorkerId}/time-unavailability`;
        const data = {
          unavailability: payload.unavailability,
          shiftsSelectedToBeKept: payload.shiftToBeKept
        };
        try {
          const result = await apiClient.post(endPoint, data);
          dispatch.teamStore.doGetTimeAvailability({});
        } catch (e) {
          console.error(e);
          throw e;
        }
      }
    },

    async doUpdateUnavailableTime(payload, rootState) {
      if (!_.isEmpty(payload)) {
        const endPoint = `/api/portal/support-worker/${rootState.teamStore.selectedWorker.supportWorkerId}/time-unavailability/${payload.timeUnavailabilityId}`;
        const data = {
          unavailability: payload.unavailability,
          shiftsSelectedToBeKept: payload.shiftToBeKept
        };
        try {
          const result = await apiClient.put(endPoint, data);
          dispatch.teamStore.doGetTimeAvailability({});
        } catch (e) {
          console.error(e);
          throw e;
        }
      }
    },

    async doDeleteUnavailableTime(payload, rootState) {
      const endPoint = `/api/portal/support-worker/${rootState.teamStore.selectedWorker.supportWorkerId}/time-unavailability/${payload.unavailabilityId}`;
      try {
        const result = await apiClient.delete(endPoint);
        dispatch.teamStore.doGetTimeAvailability({});
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doFetchPortalUserList(payload, rootState) {
      try {
        const endpoint = `/api/portal/service-provider/list`;
        const result = await apiClient.get(endpoint);
        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          dispatch.teamStore.setAdminList(result.data);
          dispatch.teamStore.setFilteredAdminList(result.data);
        }
      } catch (e) {
        message.error(e.message);
        throw e;
      }
    },

    // DOCUMENT STORAGE

    async doFetchWorkerDocuments(payload, rootState) {
      try {
        const result = await apiClient.post(`/api/portal/support-worker/${payload.userId}/documents/list`, payload);
        dispatch.teamStore.setWorkerDocuments(result.data.documents);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchMoreDocuments(payload, rootState) {
      let result = await apiClient.post(`/api/portal/support-worker/${payload.userId}/documents/list`, payload);

      try {
        if (!_.isEmpty(result) && !_.isEmpty(result.data)) {
          dispatch.teamStore.appendWorkerDocuments(result.data.documents);
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    async doAddWorkerDocument(payload, rootState) {
      try {
        const portalUser = rootStore.getState().authStore.portalUser;
        const request = { ...payload, addedBy: portalUser.userId };
        const result = await apiClient.post(
          `/api/portal/support-worker/${payload.supportWorkerId}/documents/add`,
          request
        );
        dispatch.teamStore.addWorkerDocument({
          ...payload,
          documentId: result.data.documentId,
          firstName: portalUser.firstName,
          lastName: portalUser.lastName,
          expiryDate: payload.expiryDate,
          expiryStatus: !payload.expiryDate
            ? DocumentExpiryStatus.NOT_EXPIRED
            : payload.expiryDate < moment().startOf('day')
            ? DocumentExpiryStatus.EXPIRED
            : moment(payload.expiryDate)
                .startOf('day')
                .diff(moment().startOf('day'), 'days') <= 7
            ? DocumentExpiryStatus.EXPIRING_SOON
            : DocumentExpiryStatus.NOT_EXPIRED
        });
        dispatch.teamStore.setSelectedWorker({
          ...rootState.teamStore.selectedWorker,
          alerts: result.data && result.data.alerts
        });
        return result.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doEditWorkerDocument(payload, rootState) {
      try {
        const result = await apiClient.put(
          `/api/portal/support-worker/${payload.supportWorkerId}/documents/${payload.documentId}`,
          payload
        );
        dispatch.teamStore.editWorkerDocument({
          ...payload,
          documentId: payload.documentId,
          expiryDate: payload.expiryDate
        });
        dispatch.teamStore.setSelectedWorker({
          ...rootState.teamStore.selectedWorker,
          alerts: result.data && result.data.alerts
        });
        return result.data;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doRemoveWorkerDocument(payload, rootState) {
      try {
        await apiClient.delete(`/api/portal/support-worker/${payload.supportWorkerId}/documents/${payload.documentId}`);
        dispatch.teamStore.removeWorkerDocument(payload.documentId);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doArchiveWorkerDocument(payload, rootState) {
      try {
        const result = await apiClient.delete(
          `/api/portal/support-worker/${payload.supportWorkerId}/documents/${payload.documentId}/archive`
        );
        dispatch.teamStore.archiveWorkerDocument(payload.documentId);
        dispatch.teamStore.setSelectedWorker({
          ...rootState.teamStore.selectedWorker,
          alerts: result.data && result.data.alerts
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doDownloadDocument(payload, rootState) {
      try {
        const config: AxiosRequestConfig = {
          responseType: 'blob',
          headers: {
            uniqueId: payload.uniqueId
          }
        };
        Axios.get(payload.documentUrl, config).then((res) => {
          fileDownload(res.data, payload.documentName);
        });
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doUpdateTeamMemberPermissions(payload, rootState) {
      try {
        await apiClient.put(`/api/portal/account/workers/${payload.supportWorkerId}/permissions`, payload);
        dispatch.teamStore.updateTeamMemberPermissions(payload);
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchFuzzyMatchTeamMember(payload, rootState) {
      try {
        const result = await apiClient.post(`/api/portal/account/workers/fuzzy-match`, payload);
        dispatch.teamStore.setFuzzyMatchTeamMember(result.data);
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doCreateNewTeamMember(payload, rootState) {
      try {
        const result = await apiClient.post(`/api/portal/account/workers`, payload);
        return result.data.supportWorkerId;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doBlockTeamMember(payload, rootState) {
      try {
        await apiClient.post(`api/portal/account/workers/${payload.supportWorkerId}/block`);
        dispatch.teamStore.updateTeamMemberStatus({
          supportWorkerId: payload.supportWorkerId,
          status: TeamStatus.BLOCKED
        });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doUnblockTeamMember(payload, rootState) {
      try {
        await apiClient.post(`api/portal/account/workers/${payload.supportWorkerId}/unblock`);
        dispatch.teamStore.updateTeamMemberStatus({
          supportWorkerId: payload.supportWorkerId,
          status: TeamStatus.ENABLED
        });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doReEnableTeamMember(payload, rootState) {
      try {
        await apiClient.put(`api/portal/account/workers/${payload.supportWorkerId}/re-enable`);
        dispatch.teamStore.updateTeamMemberStatus({
          supportWorkerId: payload.supportWorkerId,
          status: TeamStatus.ENABLED,
          invitationStatus: InvitationStatus.PENDING
        });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doInviteTeamMember(payload, rootState) {
      try {
        await apiClient.post(`api/portal/account/workers/${payload.supportWorkerId}/draft/invite`);
        dispatch.teamStore.updateTeamMemberStatus({
          supportWorkerId: payload.supportWorkerId,
          status: TeamStatus.ENABLED,
          invitationStatus: InvitationStatus.PENDING
        });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doDeleteDraftTeamMember(payload, rootState) {
      try {
        await apiClient.delete(`api/portal/account/workers/${payload.supportWorkerId}/draft`);
        dispatch.teamStore.updateTeamMemberStatus({
          supportWorkerId: payload.supportWorkerId,
          status: TeamStatus.REMOVED
        });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doRemoveTeamMember(payload, rootState) {
      const updatedPayload = _.omit(payload, ['supportWorkerId']);
      try {
        await apiClient.delete(`api/portal/account/workers/${payload.supportWorkerId}`, updatedPayload);
        if (!payload.removedOn) {
          dispatch.teamStore.updateTeamMemberStatus({
            supportWorkerId: payload.supportWorkerId,
            status: TeamStatus.REMOVED
          });
        }
        dispatch.teamStore.updateTeamMemberRemoveReason({
          supportWorkerId: payload.supportWorkerId,
          removeReason: payload.removeReason
        });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doEditRemovalDate(payload, rootState) {
      const updatedPayload = _.omit(payload, ['supportWorkerId']);
      try {
        await apiClient.delete(`api/portal/account/workers/${payload.supportWorkerId}`, updatedPayload);
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doCancelRemovalDate(payload, rootState) {
      try {
        await apiClient.put(`api/portal/account/workers/${payload.supportWorkerId}/cancel-removal`);
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doResendTeamInvitation(payload, rootState) {
      try {
        await apiClient.put(`api/portal/account/invitations/${payload.token}/resend`);
        dispatch.teamStore.updateTeamMemberStatus({
          supportWorkerId: payload.supportWorkerId,
          status: TeamStatus.ENABLED,
          invitationStatus: InvitationStatus.PENDING
        });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doResendRevokedTeamInvitation(payload, rootState) {
      try {
        const result = await apiClient.put(`api/portal/account/invitations/${payload.token}/resend-revoked`, payload);
        dispatch.teamStore.changeInvitationFromRevokedToPending({
          oldToken: payload.token,
          newInvite: result.data
        });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doModifyTeamInvitation(payload, rootState) {
      try {
        const result = await apiClient.put(`api/portal/account/invitations/${payload.token}`, payload);
        dispatch.teamStore.modifyInvitation({ oldToken: payload.token, newInvite: result.data });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doRevokeTeamInvitation(payload, rootState) {
      try {
        await apiClient.delete(`api/portal/account/invitations/${payload.token}`, payload);
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doRevokeInvitation(payload, rootState) {
      try {
        const result = await apiClient.delete(`api/portal/account/invitations/${payload.token}`, payload);
        dispatch.teamStore.changeInvitationFromPendingToRevoked({ oldToken: payload.token, newInvite: result.data });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doSetInstructionToViewed(payload, rootState) {
      try {
        await apiClient.put(`/api/portal/user/instructions`, payload);
        dispatch.authStore.setCurrentPortalUser({ ...rootState.authStore.portalUser, hasPortalInstruction: false });
        return true;
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    async doFetchWorkerViews(payload, rootState) {
      const endpoint = `api/portal/custom-views/team/list`;
      const { displayedWorkerListingTabs } = rootState.teamStore;
      try {
        const response = await apiClient.post(endpoint, payload);
        await dispatch.teamStore.setWorkerViews(response.data);
        await dispatch.teamStore.setDisplayedWorkerListingTabs([...displayedWorkerListingTabs]);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doAddWorkerView({ sharedWith, filterValue, customViewId, ...payload }, rootState) {
      const endpoint = `/api/portal/custom-views/team`;
      const { workerViews, displayedWorkerListingTabs } = rootState.teamStore;
      try {
        const sharedWithIds = sharedWith.map((user) => user && user.supportWorkerId);

        payload = {
          ...payload,
          sharedWithIds,
          filterValue: mapWorkerViewFilterPayload(filterValue)
        };

        const response = await apiClient.post(endpoint, payload);
        const newTab = {
          ...payload,
          ...response.data,
          sharedWith,
          isOwner: true,
          isPinned: false
        };
        dispatch.teamStore.setWorkerViews([...workerViews, newTab]);
        await dispatch.teamStore.setDisplayedWorkerListingTabs([...displayedWorkerListingTabs, newTab]);
        dispatch.teamStore.setWorkerListingActiveTab(newTab);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doDuplicateWorkerView({ sharedWith, customViewId, ...payload }, rootState) {
      const endpoint = `/api/portal/custom-views/team/${customViewId}/duplicate`;
      const { workerViews, displayedWorkerListingTabs } = rootState.teamStore;
      try {
        const sharedWithIds = sharedWith.map((user) => user && user.supportWorkerId);
        payload = {
          ...payload,
          sharedWithIds
        };

        const response = await apiClient.post(endpoint, payload);
        const newTab = {
          ...payload,
          ...response.data,
          sharedWith,
          isOwner: true,
          isPinned: false
        };
        dispatch.teamStore.setWorkerViews([...workerViews, newTab]);
        await dispatch.teamStore.setDisplayedWorkerListingTabs([...displayedWorkerListingTabs, newTab]);
        dispatch.teamStore.setWorkerListingActiveTab(newTab);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doUpdateWorkerViewTab({ sharedWith, filterValue, customViewId, isTogglePinned, ...payload }, rootState) {
      const endpoint = `/api/portal/custom-views/team/${customViewId}`;
      const { workerViews, displayedWorkerListingTabs } = rootState.teamStore;
      try {
        let requestPayload = { ...payload };
        if (isTogglePinned) {
          requestPayload = {
            isPinned: payload.isPinned
          };
        } else {
          if (sharedWith) {
            const sharedWithIds = sharedWith.map((user) => user && user.supportWorkerId);
            requestPayload = {
              ...payload,
              sharedWithIds,
              filterValue: mapWorkerViewFilterPayload(filterValue)
            };
          }
        }

        await apiClient.put(endpoint, requestPayload);
        const newView = {
          ...payload,
          customViewId,
          filterValue,
          sharedWith
        };
        const newWorkerViews = workerViews.map((view) => {
          return view.customViewId === newView.customViewId ? { ...view, ...newView } : view;
        });

        await dispatch.teamStore.setWorkerViews(newWorkerViews);

        // if current view is displayed, update the displayed view
        const displayedIndex = displayedWorkerListingTabs.findIndex(
          (view) => view.customViewId === newView.customViewId
        );

        if (displayedIndex > -1) {
          displayedWorkerListingTabs[displayedIndex] = newView;
          await dispatch.teamStore.setDisplayedWorkerListingTabs([...displayedWorkerListingTabs]);
        }

        dispatch.teamStore.setWorkerListingActiveTab(newView);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },

    async doDeleteWorkerView({ customViewId, ...payload }, rootState) {
      const endpoint = `/api/portal/custom-views/team/${customViewId}`;
      const { workerViews, displayedWorkerListingTabs, defaultWorkerViews } = rootState.teamStore;
      try {
        await apiClient.delete(endpoint);
        const newView = {
          ...payload,
          customViewId
        };
        const newWorkerViews = workerViews.filter((view) => view.customViewId !== newView.customViewId);

        await dispatch.teamStore.setWorkerViews(newWorkerViews);

        // if current view is displayed, update the displayed view
        const displayedIndex = displayedWorkerListingTabs.findIndex(
          (view) => view.customViewId === newView.customViewId
        );

        if (displayedIndex > -1) {
          displayedWorkerListingTabs.splice(displayedIndex, 1);
          await dispatch.teamStore.setDisplayedWorkerListingTabs([...displayedWorkerListingTabs]);
        }

        dispatch.teamStore.setWorkerListingActiveTab(_.first(defaultWorkerViews));
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  })
};

export default teamStore;
