import React, { Component } from 'react';
import { Col, notification, Row } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { Paragraph } from 'common-components/typography';
import ActionModal from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';

interface ISendInvoiceForApprovalModalProps {
  closeModal: () => void;
  isOpen: boolean;
  selectedInvoice: any;
  doSendInvoiceForApproval: typeof dispatch.planManagementStore.doSendInvoiceForApproval;
}

interface ISendInvoiceForApprovalModalState {
  isLoading: boolean;
  isSuccess: boolean;
  title: string;
}

class SendInvoiceForApprovalModal extends Component<
  ISendInvoiceForApprovalModalProps,
  ISendInvoiceForApprovalModalState
> {
  state = {
    isLoading: false,
    isSuccess: false,
    title: 'Send for approval'
  };

  private _closeModal = () => {
    this.setState({
      isLoading: false,
      isSuccess: false,
      title: 'Send for approval'
    });
    this.props.closeModal();
  };

  private _sendForApproval = async () => {
    const { doSendInvoiceForApproval, selectedInvoice } = this.props;
    try {
      await doSendInvoiceForApproval({
        planManagementInvoiceId: selectedInvoice.planManagementInvoiceId
      });
      this._closeModal();
      this.setState({ isLoading: false, isSuccess: true, title: 'Invoice successfully sent for approval' });
    } catch (e) {
      this.setState({ isLoading: false });
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
  };

  render() {
    const { selectedInvoice } = this.props;
    const { title, isLoading, isSuccess } = this.state;

    return (
      <div>
        <ActionModal title={title} isOpen={this.props.isOpen} onClose={this._closeModal} width="x-large">
          {isSuccess ? (
            <Paragraph>
              The invoice has been successfully sent for approval. We will notify you when it has been approved by the
              customer.
            </Paragraph>
          ) : (
            <>
              <Paragraph weight={'bold'}>Are you sure you want to send this invoice for approval?</Paragraph>
              {selectedInvoice.connected === 'CONNECTED' ? (
                selectedInvoice.guardians.length > 0 ? (
                  selectedInvoice.guardians.length > 1 ? (
                    <Paragraph>
                      <b>
                        {selectedInvoice.firstName} {selectedInvoice.lastName}
                      </b>{' '}
                      has{' '}
                      <b>
                        {_.map(selectedInvoice.guardians, (guardian, key: number) => {
                          const isLastGuardian = key === selectedInvoice.guardians.length - 1;
                          return `${key > 0 && !isLastGuardian ? ', ' : ' '}${isLastGuardian ? 'and ' : ' '}${
                            guardian.firstName
                          } ${guardian.lastName} `;
                        })}
                      </b>{' '}
                      listed as legal guardians.
                      <br />
                      The legal guardians will receive either an in-app notification or an email indicating that there
                      is a new invoice ready for approval.
                    </Paragraph>
                  ) : (
                    <Paragraph>
                      <b>
                        {selectedInvoice.firstName} {selectedInvoice.lastName}
                      </b>{' '}
                      has{' '}
                      <b>
                        {selectedInvoice.guardians[0].firstName} {selectedInvoice.guardians[0].lastName}
                      </b>{' '}
                      listed as a legal guardian.
                      <br />
                      <b>
                        {selectedInvoice.guardians[0].firstName} {selectedInvoice.guardians[0].lastName}
                      </b>{' '}
                      will receive either an in-app notification or an email indicating that there is a new invoice
                      ready for approval.
                    </Paragraph>
                  )
                ) : (
                  <Paragraph>
                    <b>
                      {selectedInvoice.firstName} {selectedInvoice.lastName}
                    </b>{' '}
                    has access to the GoodHuman app and will receive a notification in-app that there is a new invoice
                    ready for approval.
                  </Paragraph>
                )
              ) : selectedInvoice.guardians.length > 0 ? (
                selectedInvoice.guardians.length > 1 ? (
                  <Paragraph>
                    <b>
                      {selectedInvoice.firstName} {selectedInvoice.lastName}
                    </b>{' '}
                    has{' '}
                    <b>
                      {_.map(selectedInvoice.guardians, (guardian, key: number) => {
                        const isLastGuardian = key === selectedInvoice.guardians.length - 1;
                        return `${key > 0 && !isLastGuardian && ', '}${isLastGuardian && 'and '}${guardian.firstName} ${
                          guardian.lastName
                        } `;
                      })}
                    </b>{' '}
                    listed as legal guardians.
                    <br />
                    The legal guardians will receive either an in-app notification or an email indicating that there is
                    a new invoice ready for approval.
                  </Paragraph>
                ) : (
                  <Paragraph>
                    <b>
                      {selectedInvoice.firstName} {selectedInvoice.lastName}
                    </b>{' '}
                    has{' '}
                    <b>
                      {selectedInvoice.guardians[0].firstName} {selectedInvoice.guardians[0].lastName}
                    </b>{' '}
                    listed as a legal guardian.
                    <br />
                    <b>
                      {selectedInvoice.guardians[0].firstName} {selectedInvoice.guardians[0].lastName}
                    </b>{' '}
                    will receive an email indicating that there is a new invoice ready for approval.
                  </Paragraph>
                )
              ) : (
                <Paragraph>
                  <b>
                    {selectedInvoice.firstName} {selectedInvoice.lastName}
                  </b>{' '}
                  does not have access to the GoodHuman app and will receive an email indication that there is a new
                  invoice ready for approval.
                </Paragraph>
              )}
            </>
          )}
          <div>
            <div className={'mb-small mt-x2-large'}>
              <Row type={'flex'} className={'justify-end'}>
                <Col className="mr-large">
                  <GhostButton size="large" onClick={this._closeModal}>
                    Cancel
                  </GhostButton>
                </Col>
                <Col>
                  <PrimaryButton size="large" loading={isLoading} onClick={this._sendForApproval}>
                    Send for approval
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </div>
        </ActionModal>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doSendInvoiceForApproval: dispatch.planManagementStore.doSendInvoiceForApproval
});

const mapState = (state: IRootState) => ({
  // if needed
});

export default connect(
  mapState,
  mapDispatch
)(SendInvoiceForApprovalModal);
