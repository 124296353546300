import React, { Component } from 'react';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { Col, Icon, Row } from 'antd';
import { PrimaryButton } from 'common-components/buttons';

import { dispatch, IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { INewBookingData } from 'interfaces/booking-interfaces';
import _ from 'lodash';
import moment from 'moment-timezone';

interface Step6ConfirmationPanelProps {
  onNextStep: any;
  onPreviousStep: any;
  onCloseModal: any;
  newBookingData: INewBookingData | any;
  history: any;
  bookingResponseData: any;
  doSetNewBookingData: typeof dispatch.bookingsStore.doSetNewBookingData;
  setBookSelectedService: typeof dispatch.servicesStore.setBookSelectedService;
}

class Step7ConfirmationPanel extends Component<Step6ConfirmationPanelProps> {
  state = { isLoading: true, isLoadingNavigate: false };

  private _formatDate = (bookStartDate, bookEndDate) => {
    const startDate = moment.tz(bookStartDate, this.props.newBookingData.selectedService.timezone);
    const endDate = moment.tz(bookEndDate, this.props.newBookingData.selectedService.timezone);

    const isSameDay = startDate.format('DDMMYYYY') === endDate.format('DDMMYYYY');

    if (isSameDay) {
      // Target format = 15 December 2020, 3:30PM to 5:30PM
      return `${startDate.format('D MMMM YYYY, h:mm A')} to ${endDate.format('h:mm A')}`;
    } else {
      // Target format = 15 -20 December 2020, 3:30PM to 5:30PM
      return (
        startDate.format('D') +
        ' - ' +
        endDate.format('D MMMM YYYY') +
        ', ' +
        startDate.format('h:mm A') +
        ' to ' +
        endDate.format('h:mm A')
      );
    }
  };

  private _getDuration = (bookStartDate, bookEndDate) => moment(bookEndDate).diff(moment(bookStartDate), 'hour');

  private _onNavigateAndClose = () => {
    const { history, bookingResponseData, doSetNewBookingData } = this.props;
    this.setState({ isLoadingNavigate: true });
    doSetNewBookingData({
      bookCustomNote: '',
      bookStartDate: null,
      bookStartTime: null,
      bookEndDate: moment()
        .add(1, 'hour')
        .toDate(),
      bookEndTime: moment()
        .add(1, 'hour')
        .toDate(),
      bookLocation: null,
      hasMileageClaims: false,
      isCancelledBooking: false,
      cancellationReason: '',
      cancellationCode: 'NSDT',
      isFutureBooking: true,
      mileageTravelled: 0,
      selectedCustomer: null,
      selectedCustomerId: null,
      selectedService: null,
      selectedServiceId: null,
      selectedWorker: null,
      selectedWorkerId: null
    });
    this.props.setBookSelectedService({});
    this.setState({ isLoadingNavigate: false });
    history.push('/bookings/details/' + bookingResponseData[0].bookingId);
  };

  render() {
    const { onCloseModal, newBookingData } = this.props;

    let {
      selectedCustomer = { firstName: '', lastName: '' },
      selectedService = { serviceName: '' },
      selectedWorker = { firstName: null, lastName: null },
      bookCustomNote,
      bookStartDate,
      bookEndDate,
      bookLocation,
      isCancelledBooking,
      cancellationReason,
      hasMileageClaims,
      mileageTravelled,
      cancellationCode
    } = newBookingData;

    if (_.isEmpty(selectedWorker)) {
      selectedWorker = { firstName: null, lastName: null };
    }

    if (_.isEmpty(selectedCustomer)) {
      selectedCustomer = { firstName: '', lastName: '' };
    }

    if (_.isEmpty(selectedService)) {
      selectedService = { serviceName: '' };
    }

    const { firstName, lastName } = selectedCustomer;
    const { serviceName } = selectedService;

    const { firstName: workerFirstName, lastName: workerLastName } = selectedWorker;

    return (
      <div>
        <div className="anim-slide-left">
          <Title level={2} weight="bolder" className="line-height-100 mb-x-medium" color="blue-action">
            <Icon type="check" className="mr-small text-color-green-dark" />
            Booking <span className="text-weight-regular">successfully created</span>
          </Title>

          <Paragraph>A new booking has been created with the following details.</Paragraph>

          <Row className="mb-medium">
            <Col span={12}>
              <SubTitle>Customer Name</SubTitle>
              <Text size="x-large" weight="black">
                {`${firstName} ${lastName}`}
              </Text>
            </Col>
            <Col span={12} />
          </Row>

          <Row className="mb-medium">
            <Col span={12}>
              <SubTitle>Service Name</SubTitle>
              <Text size="x-large" weight="black">
                {`${serviceName}`}
              </Text>
            </Col>
            <Col span={12}>
              <SubTitle>Support Worker</SubTitle>
              <Text size="x-large" weight="black">
                {_.isEmpty(workerFirstName) ? 'No worker selected' : `${workerFirstName} ${workerLastName}`}
              </Text>
            </Col>
          </Row>

          <Row className="mb-medium">
            <Col span={12}>
              <SubTitle>Booking Date</SubTitle>
              <Text weight="black" size="x-large">
                {/*15 December 2020, 5:30 PM - 7:30 PM*/}
                {this._formatDate(bookStartDate, bookEndDate)}
                <br />
                <Text weight="regular" size="x-large">
                  {this._getDuration(bookStartDate, bookEndDate)} hour(s)
                </Text>
              </Text>
            </Col>
            <Col span={12}>
              <SubTitle>Location</SubTitle>
              <Paragraph ellipsis={{ rows: 1 }} size="x-large" weight="black">
                {!_.isEmpty(bookLocation) && bookLocation.fullAddress}
              </Paragraph>
            </Col>
          </Row>

          {isCancelledBooking && (
            <Row className="mb-medium">
              <Col span={24}>
                <SubTitle>Cancellation Code</SubTitle>
                <Text weight="bold" size="x-large">
                  {cancellationCode}
                </Text>
              </Col>
            </Row>
          )}
          {isCancelledBooking && cancellationReason !== null && cancellationReason !== '' && (
            <Row className="mb-medium">
              <Col span={24}>
                <SubTitle>Other Reason</SubTitle>
                <Text weight="bold" size="x-large">
                  {cancellationReason}
                </Text>
              </Col>
            </Row>
          )}

          {hasMileageClaims && (
            <Row className="mb-medium">
              <Col span={12}>
                <SubTitle>Mileage Travelled</SubTitle>
                <Text weight="bold" size="x-large">
                  {mileageTravelled} km
                </Text>
              </Col>
            </Row>
          )}

          <Row className="mb-x-large">
            <Col span={24}>
              <SubTitle>Notes</SubTitle>
              <Paragraph weight="bold" size="x-large" ellipsis={{ rows: 1 }} color="secondary">
                {bookCustomNote}
              </Paragraph>
            </Col>
          </Row>

          <div className="flex-row justify-start">
            <PrimaryButton
              size="large"
              loading={this.state.isLoadingNavigate}
              onClick={this._onNavigateAndClose}
              icon="close"
              className="mr-medium cursor-pointer"
            >
              Go to Booking Now
            </PrimaryButton>

            <PrimaryButton size="large" onClick={onCloseModal} className="cursor-pointer" icon="close">
              Close
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  newBookingData: state.bookingsStore.newBookingData,
  bookingResponseData: state.bookingsStore.bookingResponseData
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doSetNewBookingData: dispatch.bookingsStore.doSetNewBookingData,
  setBookSelectedService: dispatch.servicesStore.setBookSelectedService
});

export default connect(
  mapState,
  mapDispatch
)(Step7ConfirmationPanel);
