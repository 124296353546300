import React, { useEffect, useMemo, useRef, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';

import ScrollContainer from 'react-indiana-drag-scroll';

import { SearchInput } from 'common-components/roster-control/roster-daily/content-pane/common/SearchInput';
import { HoursDisplay } from 'common-components/roster-control/common/HoursDisplay';
import { FooterSpacer } from 'common-components/roster-control/common/FooterSpacer';
import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';
import { WorkersTimeline } from 'common-components/roster-control/roster-daily/timeline-pane/workers/WorkersTimeline';
import { CustomersSideColumn } from 'common-components/roster-control/roster-daily/content-pane/customers/CustomersSideColumn';
import { WorkersSideColumn } from 'common-components/roster-control/roster-daily/content-pane/workers/WorkersSideColumn';
import { CustomersTimeline } from 'common-components/roster-control/roster-daily/timeline-pane/customers/CustomersTimeline';

import {
  collateDuplicateShifts,
  generateHours,
  groupWithoutOverlaps,
  uniqueCustomers,
  uniqueWorkers
} from 'common-components/roster-control/common/roster-utilities';

import {
  IRosterBooking,
  IRosterCustomer,
  IRosterCustomerWithBookings,
  IRosterShift,
  IRosterWorker,
  IRosterWorkerWithShifts
} from 'common-components/roster-control/interfaces/roster-interfaces';

import * as H from 'history';

const { CONTENT_PANEL_WIDTH, HOUR_BLOCK_HEIGHT } = ROSTER_DAILY_CONFIG;

/*
  Some notes about the display mode properties :
  
   Display Mode :
   • Dynamic - list of customers/workers will be derived from the bookings/shifts (unique customers/workers).
   • Fixed   - list of customers/workers will be displayed based on the list of customers/workers passed in as props.

 */

interface RosterTimelineProps {
  bookings?: IRosterBooking[];
  // shifts: IRosterShift[]; // <- Deprecated; shifts are now split into assigned/unassigned shifts

  assignedShifts?: IRosterShift[];
  unassignedShifts?: IRosterShift[];

  selectedDate?: Date;

  showOpenSlots?: boolean;
  showCustomers?: boolean;
  showWorkers?: boolean;

  customersList?: IRosterCustomer[];
  customersDisplayMode?: 'dynamic' | 'fixed';

  workersList?: IRosterWorker[];
  workersDisplayMode?: 'dynamic' | 'fixed';

  refreshData?: () => void;

  onAddBooking?: any;

  history: H.History;
}

/*
  TODO :
  - (LATER) Grouping by services
 */
function RosterDaily({
  bookings,

  unassignedShifts = [],
  assignedShifts = [],

  selectedDate,

  customersList,
  workersList,

  showOpenSlots = true,
  showCustomers = true,
  showWorkers = true,

  refreshData,

  customersDisplayMode = 'dynamic',
  workersDisplayMode = 'dynamic',

  onAddBooking,

  history
}: RosterTimelineProps) {
  // States
  const [isCustomersOpen, setCustomersOpen] = useState(true);
  const [isWorkersOpen, setWorkersOpen] = useState(true);

  const scrollContainerRef = useRef(null);

  // Array of how many hours to display
  const hoursToDisplay = useMemo(() => generateHours(0, 23), []);

  // Bookings & shifts for selected date
  const combinedShifts = showOpenSlots ? [...unassignedShifts, ...assignedShifts] : [...assignedShifts];

  // Display mode = 'fixed'   -> use the passed in customers list.
  // Display mode = 'dynamic' -> get unique list of customers/workers in the bookings/shifts
  const customers = useMemo(() => (customersDisplayMode === 'fixed' ? customersList : uniqueCustomers(bookings)), [
    bookings,
    customersList,
    customersDisplayMode
  ]);

  const workers = useMemo(() => (workersDisplayMode === 'fixed' ? workersList : uniqueWorkers(combinedShifts)), [
    combinedShifts,
    workersList,
    workersDisplayMode
  ]);

  console.log(workers);

  // Check to see if there are any open slots.
  // const hasOpenShifts = !_.isEmpty(unassignedShifts);

  // OVERLAP CHECKS & GROUPINGS
  // The following functions checks for overlaps in shifts, and groups them into multiple rows to avoid overlaps.

  // Open slots
  const unassignedWorkerShifts: IRosterShift[][] = useMemo(() => groupUnassignedShifts(unassignedShifts), [
    unassignedShifts
  ]);

  // Assigned shifts
  const assignedWorkerShifts: IRosterWorkerWithShifts[] = useMemo(() => groupAssignedShifts(workers, assignedShifts), [
    workers,
    assignedShifts
  ]);

  // Bookings
  const customerBookings: IRosterCustomerWithBookings[] = useMemo(() => groupBookings(customers, bookings), [
    customers,
    bookings
  ]);

  // Where should the timeline begin at; offset in hours. Default is 8am
  let scrollToHour = 8;

  // Minimum date for the entire shift/booking.
  // Commented out because it's a weird experience.
  const earliestSlot = getEarliestSlot(unassignedShifts, assignedShifts, bookings);

  // If there are any earliest slots found, scroll to the target hour.
  if (!_.isEmpty(earliestSlot)) {
    scrollToHour = moment(earliestSlot.startDateTime).hour() - 1;
  }

  // Initial scroll
  useEffect(() => {
    if (!_.isEmpty(scrollContainerRef) && !_.isEmpty(scrollContainerRef.current)) {
      const element = scrollContainerRef.current.getElement();
      if (!_.isEmpty(element)) {
        element.scrollTo({ left: 120 * scrollToHour });
      }
    }
  }, [scrollToHour]);

  return (
    <div className="select-none bordered-top bordered-bottom">
      {/* Main container; left & right panels */}
      <div className="flex-row" id="main-container">
        {/* ---•=== START @ User (Content) panel ===•--- */}
        <div style={{ width: `${CONTENT_PANEL_WIDTH}px`, minHeight: '1vh' }} className="bg-white" id="left-container">
          {/* TODO : Search input. Disabled for now */}
          <SearchInput height={HOUR_BLOCK_HEIGHT} />

          {/* Content rows are on the left panel */}
          {showCustomers && (
            <CustomersSideColumn
              customers={customers}
              isCustomersOpen={isCustomersOpen}
              setCustomersOpen={setCustomersOpen}
              bookings={customerBookings}
              history={history}
            />
          )}

          {showWorkers && (
            <WorkersSideColumn
              assignedShifts={assignedWorkerShifts}
              unassignedShifts={unassignedWorkerShifts}
              workers={workers}
              isWorkersOpen={isWorkersOpen}
              setWorkersOpen={setWorkersOpen}
              history={history}
            />
          )}

          {/* Footer */}
          <FooterSpacer width={CONTENT_PANEL_WIDTH} />
        </div>
        {/* ---•=== END @ Left content panel ===•--- */}

        {/* ---•=== START @ Timeline panel ===•--- */}
        {/* Scroll container for the right timeline. ScrollContainer = container that you can drag with mouse */}
        <ScrollContainer
          className="bg-tertiary cursor-all-scroll"
          style={{ width: `calc(100vw - ${CONTENT_PANEL_WIDTH}px)` }}
          hideScrollbars={false}
          vertical={false}
          ref={scrollContainerRef}
        >
          {/* Top display for the hours, eg 12AM, 1AM, ...*/}
          <HoursDisplay hours={hoursToDisplay} />

          {/* Timeline for the customer */}
          {showCustomers && (
            <CustomersTimeline
              hours={hoursToDisplay}
              customers={customers}
              bookings={customerBookings}
              isCustomersOpen={isCustomersOpen}
              currentDate={selectedDate}
              refreshData={refreshData}
              onAddBooking={onAddBooking}
              history={history}
            />
          )}

          {/* Timeline for the worker */}
          {showWorkers && (
            <WorkersTimeline
              hours={hoursToDisplay}
              assignedShifts={assignedWorkerShifts}
              unassignedShifts={unassignedWorkerShifts}
              workers={workers}
              isWorkersOpen={isWorkersOpen}
              refreshData={refreshData}
              history={history}
            />
          )}

          {/*<FooterSpacing width={_.size(hoursToDisplay) * 100 + 16} />*/}
        </ScrollContainer>
        {/* ---•=== END @ Timeline panel ===•--- */}
      </div>
    </div>
  );
}

export default RosterDaily;

// Local functions
function groupUnassignedShifts(unassignedShifts) {
  // Collate/combine duplicate shifts based on serviceDateTimeId, then group overlapping shifts into multiple rows
  return groupWithoutOverlaps(collateDuplicateShifts(unassignedShifts), {
    startDateKey: 'shiftStartDateTime',
    endDateKey: 'shiftEndDateTime'
  });
}

function groupAssignedShifts(workers, assignedShifts) {
  // Find all assigned shifts for each worker, then group overlapping shifts into multiple rows
  return _.map(workers, (worker) => ({
    ...worker,
    shifts: groupWithoutOverlaps(
      _.filter(assignedShifts, (shift) => shift.supportWorkerId === worker.supportWorkerId),
      {
        startDateKey: 'shiftStartDateTime',
        endDateKey: 'shiftEndDateTime'
      }
    )
  }));
}

function groupBookings(customers, bookings) {
  // Find all bookings for each customer, then group overlapping bookings into multiple rows

  return _.map(customers, (customer) => ({
    ...customer,
    bookings: groupWithoutOverlaps(_.filter(bookings, (booking) => booking.bookerUserId === customer.customerId), {})
  }));
}

function getEarliestSlot(unassignedShifts, assignedShifts, bookings) {
  return _.minBy(
    [
      ..._.map(unassignedShifts, (s) => ({ startDateTime: s.shiftStartDateTime })),
      ..._.map(assignedShifts, (s) => ({ startDateTime: s.shiftStartDateTime })),
      ..._.map(bookings, (s) => ({ startDateTime: s.startDateTime }))
    ],
    (o) => o.startDateTime
  );
}
