import React, { Component } from 'react';
import { FieldLabel, FieldValueText, SubTitle, Text, Title } from 'common-components/typography';
import { Col, Divider, Form, Icon, notification, Row, Tabs } from 'antd';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import _ from 'lodash';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { IBillingLineItem } from 'interfaces/booking-interfaces';
import ndisHelper from 'variables/data/ndis-helper';
import BillingLineItemV2 from 'views/billings/components/BillingLineItemV2';
import CommonUtils from 'utilities/common-utils';
import { FormComponentProps } from 'antd/es/form';
import { BillingPaymentStatus, BookingStatus, BookingType, PaymentSources, PaymentStatus } from 'utilities/enum-utils';
import FundingUtils from 'utilities/funding-utils';
import PermissionUtils from 'utilities/permission-utils';
import BillingModifyTravelClaimModal from 'views/billings/components/BillingModifyTravelClaimModal';
import DuplicateModal from 'views/bookings/details/sections/content-section/tabs-panel/DuplicateModal';
import { Information } from 'common-components/alerts';
import SelectLineItemToChargeModal from 'views/bookings/listings/components/SelectLineItemToChargeModal';
import PaymentSourceTag from 'common-components/tags/PaymentSourceTag';
import ChangePreferredPaymentMethodModal from 'views/bookings/listings/components/ChangePreferredPaymentMethodModal';

interface BookingBillingPanelProps extends FormComponentProps {
  showSidebar: boolean;
  bookingItem: any;
  doCreateBookingBillingLineItem: typeof dispatch.billingsStore.doCreateBookingBillingLineItem;
  doUpdateBookingBillingLineItem: typeof dispatch.billingsStore.doUpdateBookingBillingLineItem;
  doDeleteBookingBillingLineItem: typeof dispatch.billingsStore.doDeleteBookingBillingLineItem;
  doWaiveBookingBillingLineItem: typeof dispatch.billingsStore.doWaiveBookingBillingLineItem;
  doUnwaiveBookingBillingLineItem: typeof dispatch.billingsStore.doUnwaiveBookingBillingLineItem;
  portalUser: typeof state.authStore.portalUser;
  timezoneData?: any;
  hasEditBillingLineItemPermission?: boolean;
  customerBookingPaymentDetail: typeof state.bookingsStore.customerBookingPaymentDetail;
  doFetchBookingPaymentDetail: typeof dispatch.bookingsStore.doFetchBookingPaymentDetail;
  doUpdateBookingSelectedPaymentDetail: typeof dispatch.bookingsStore.doUpdateBookingSelectedPaymentDetail;
  doChangePreferredPaymentSource: typeof dispatch.bookingsStore.doChangePreferredPaymentSource;
  isBookingArchived: boolean;
  recurringBookingList: typeof state.bookingsStore.recurringBookingList;
}

interface BookingBillingPanelState {
  supportCategoryOptions: any[];
  isEditing: boolean;
  isEditingBillingLineItem: boolean;
  isSaving: boolean;
  isLoading: boolean;
  bookingBillingLineItems: IBillingLineItem[];
  billingSupportItems: any[];
  bookingTotal: number;
  isCancelled: boolean;
  isOpenAddLineItem: boolean;
  selectedIndex: any;
  selectedTab: string;
  isEditTravelClaimsOpen: boolean;
  isDuplicateModalOpen: boolean;
  duplicateMode: string;
  travelClaimType: string;
  isSelectLineItemOpen: boolean;
  isChangePaymentMethodModalOpen: boolean;
}

class BookingBillingPanel extends Component<BookingBillingPanelProps, BookingBillingPanelState> {
  private lineItemRefs: any[] = [];
  private bottomRef: any = React.createRef();

  state = {
    supportCategoryOptions: [],
    isEditing: false,
    isEditingBillingLineItem: false,
    serviceAgreement: null,
    isSaving: false,
    isLoading: false,
    bookingBillingLineItems: [],
    billingSupportItems: [],
    bookingTotal: 0,
    isCancelled: false,
    isOpenAddLineItem: false,
    selectedIndex: null,
    selectedTab: 'TIMES',
    isEditTravelClaimsOpen: false,
    isDuplicateModalOpen: false,
    duplicateMode: null,
    travelClaimType: null,
    isSelectLineItemOpen: false,
    isChangePaymentMethodModalOpen: false,
  };

  private _onClickAddBillingLineItem = async () => {
    // make a copy of existing billingItem to duplicate when add new
    const newBillingLineItem: IBillingLineItem = {
      bookingBillingLineItemId: Date.now().toString(),
      startDateTime: this.props.bookingItem.startDateTime,
      endDateTime: this.props.bookingItem.endDateTime,
      supportItemNumber: null,
      supportItemNumberArray: [],
      qty: 1,
      unitPrice: 0,
      unit: null,
      description: null,
      total: 0,
      paymentMethod: null,
      ruleType: null,
      isTravel: false,
      travelDistance: 0,
      mileagePrice: 0,
      claimType: '',
      isEditing: true,
    };

    const bookingBillingLineItems = [...this.state.bookingBillingLineItems];
    bookingBillingLineItems.push(newBillingLineItem);
    await this.setState({
      isEditingBillingLineItem: true,
      bookingBillingLineItems,
      bookingTotal: this._calculateBookingTotal(bookingBillingLineItems),
    });
    this.executeScroll();
  };

  private _openEditTravelClaims = (travelClaimType) => {
    this.setState({ travelClaimType, isEditTravelClaimsOpen: true });
  };

  private _closeEditTravelClaims = () => {
    this.setState({ travelClaimType: null, isEditTravelClaimsOpen: false });
  };

  private _closeDuplicateModal = () => {
    this.setState({ duplicateMode: null, isDuplicateModalOpen: false });
  };

  _setRef = (ref) => {
    if (ref) {
      this.lineItemRefs.push(ref);
    }
  };

  private _onEditLineItem = (index) => {
    const bookingBillingLineItems = [...this.state.bookingBillingLineItems];
    bookingBillingLineItems[index] = { ...bookingBillingLineItems[index], isEditing: true };

    this.setState({ bookingBillingLineItems, isEditingBillingLineItem: true });
  };

  private _onDeleteLineItem = async (removeIndex) => {
    const { doDeleteBookingBillingLineItem } = this.props;

    await doDeleteBookingBillingLineItem(this.state.bookingBillingLineItems[removeIndex]);
    const existingBookingBillingLineItems = [...this.state.bookingBillingLineItems];

    _.remove(existingBookingBillingLineItems, (item, index) => {
      return index === removeIndex;
    });
    _.remove(this.lineItemRefs, (item, index) => {
      return index === removeIndex;
    });

    this.setState({
      bookingBillingLineItems: existingBookingBillingLineItems,
      bookingTotal: this._calculateBookingTotal(existingBookingBillingLineItems),
    });
  };

  private _checkForDuplicate = (lineItem) => {
    const isSameDayBooking =
      moment(this.props.bookingItem.startDateTime).format('YYYY-MM-DD') ===
      moment(this.props.bookingItem.endDateTime).format('YYYY-MM-DD');

    const { bookingBillingLineItems } = this.state;
    const isDuplicate = _.find(
      _.filter(bookingBillingLineItems, (i) => i.bookingBillingLineItemId !== lineItem.bookingBillingLineItemId),
      (item) =>
        item.supportItemNumber === lineItem.supportItemNumber &&
        isSameDayBooking &&
        (item.claimType === lineItem.claimType || (!item.claimType && lineItem.claimType === 'STD')),
    );
    if (isDuplicate) {
      this.setState({ isDuplicateModalOpen: true });
      return true;
    } else {
      if (lineItem.ruleType === 'ABT' || lineItem.ruleType === 'PTNLC') {
        if (
          _.find(
            _.filter(bookingBillingLineItems, (i) => i.bookingBillingLineItemId !== lineItem.bookingBillingLineItemId),
            (item) => item.ruleType === lineItem.ruleType,
          )
        ) {
          this.setState({ isDuplicateModalOpen: true, duplicateMode: 'TRAVEL_' + lineItem.ruleType });
          return true;
        }
      }
      return false;
    }
  };

  private executeScroll = () => this.bottomRef.scrollIntoView();

  private _onSaveLineItem = async (index) => {
    // validate and save
    const { doCreateBookingBillingLineItem, doUpdateBookingBillingLineItem } = this.props;

    // validate line items tab
    if (this.lineItemRefs.length > 0) {
      try {
        let bookingBillingLineItems;
        const lineItem = _.cloneDeep(this.state.bookingBillingLineItems[index]);

        lineItem.unitPrice = Number(lineItem.unitPrice);
        lineItem.billingPrice = Number(lineItem.billingPrice);
        lineItem.qty = Number(lineItem.qty);
        lineItem.billingQty = Number(lineItem.billingQty);
        lineItem.mileagePrice = lineItem.mileagePrice ? Number(lineItem.mileagePrice) : 0;
        lineItem.travelDistance = Number(lineItem.travelDistance);
        lineItem.total = Number(lineItem.total);
        lineItem.billingTotal = Number(lineItem.billingTotal);
        lineItem.additionalCost = Number(lineItem.additionalCost);
        lineItem.claimType = lineItem.claimType === 'STD' ? '' : lineItem.claimType;
        lineItem.paymentSourceType = lineItem.paymentSourceType ? lineItem.paymentSourceType : PaymentSources.NDIS;

        let result = null;
        if (lineItem.bookingBillingLineItemId.indexOf('-') <= 0) {
          result = await doCreateBookingBillingLineItem(lineItem);
        } else {
          result = await doUpdateBookingBillingLineItem(lineItem);
        }
        if (result) {
          bookingBillingLineItems = _.map(result.billingLineItems, (billingLineItem) => {
            const newBillingLineItem = _.cloneDeep(billingLineItem);
            const ndisSupportItem =
              billingLineItem.paymentSourceType === PaymentSources.NDIS &&
              ndisHelper.getBySupportItemNumber(billingLineItem.supportItemNumber);
            newBillingLineItem.ruleType =
              billingLineItem.paymentSourceType === PaymentSources.NDIS && ndisSupportItem.DateType;
            newBillingLineItem.isEditing = false;
            newBillingLineItem.supportItemNumberArray = [billingLineItem.supportItemNumber];
            newBillingLineItem.claimType = newBillingLineItem.claimType === '' ? 'STD' : billingLineItem.claimType;
            return newBillingLineItem;
          });
          this.setState({ bookingBillingLineItems, isEditingBillingLineItem: false });
        }

        notification.success({
          message: 'Billing line items updated',
          description: 'The billing line items has been updated for this booking.',
        });
        this.setState({ isEditing: false, isSaving: false });
      } catch (error) {
        notification.error({ description: error.meta && error.meta.message, message: error.message });
        this.setState({ isSaving: false });
      }
    }
  };

  private _onCancelLineItem = (index) => {
    const lineItem = this.state.bookingBillingLineItems[index];

    let bookingBillingLineItems = [...this.state.bookingBillingLineItems];

    // if '-' exists in bookingBillingLineItemId means is uuid
    if (lineItem.bookingBillingLineItemId.indexOf('-') <= 0) {
      // if bookingBillingLineItemId is not uuid, means is new
      // delete lineItem

      _.remove(bookingBillingLineItems, (item, i) => {
        return i === index;
      });
      _.remove(this.lineItemRefs, (item, i) => {
        return i === index;
      });
    } else {
      bookingBillingLineItems[index] = { ...bookingBillingLineItems[index], isEditing: false };
    }

    this.setState({
      bookingBillingLineItems: bookingBillingLineItems,
      bookingTotal: this._calculateBookingTotal(bookingBillingLineItems),
      isEditingBillingLineItem: false,
    });
  };

  private _onChangeLineItem = (index, billingLineItem) => {
    const bookingBillingLineItems = [...this.state.bookingBillingLineItems];
    bookingBillingLineItems[index] = { ...bookingBillingLineItems[index], ...billingLineItem };

    this.setState({ bookingBillingLineItems, bookingTotal: this._calculateBookingTotal(bookingBillingLineItems) });
  };

  private _onWaiveLineItem = async (index) => {
    const { doWaiveBookingBillingLineItem } = this.props;

    await doWaiveBookingBillingLineItem(this.state.bookingBillingLineItems[index]);
    const bookingBillingLineItems = [...this.state.bookingBillingLineItems];
    bookingBillingLineItems[index] = { ...bookingBillingLineItems[index], paymentStatus: BillingPaymentStatus.WAIVED };

    this.setState({ bookingBillingLineItems, bookingTotal: this._calculateBookingTotal(bookingBillingLineItems) });
  };

  private _onUnwaiveLineItem = async (index) => {
    const { doUnwaiveBookingBillingLineItem } = this.props;

    await doUnwaiveBookingBillingLineItem(this.state.bookingBillingLineItems[index]);
    const bookingBillingLineItems = [...this.state.bookingBillingLineItems];
    bookingBillingLineItems[index] = {
      ...bookingBillingLineItems[index],
      paymentStatus: BillingPaymentStatus.READY_FOR_BILLING,
    };

    this.setState({ bookingBillingLineItems, bookingTotal: this._calculateBookingTotal(bookingBillingLineItems) });
  };

  private _calculateBookingTotal = (bookingBillingLineItems) => {
    const total = _.reduce(
      bookingBillingLineItems,
      (total, billingLineItem) => {
        if (billingLineItem.billingTotal && billingLineItem.paymentStatus !== BillingPaymentStatus.WAIVED) {
          return Number(total) + Number(billingLineItem.billingTotal);
        } else {
          return Number(total);
        }
      },
      0,
    );

    return _.round(Number(total), 2);
  };

  private getTravelBillingItem = () => {
    const ABTItems = ndisHelper.getByDateType('ABT');
    const PTNLCItems = ndisHelper.getByDateType('PTNLC');
    const allTravelItems = ABTItems.concat(PTNLCItems);

    const uniqueBillingLineItemCombination = _.uniqBy(
      _.filter(this.props.bookingItem.serviceBillingLineItems, (item) => item.billingProvider === 'NDIS'),
      function(elem: any) {
        const helperSupportItem = ndisHelper.getBySupportItemNumber(elem.supportItemNumber);
        return helperSupportItem.RegistrationGroupNumber + '_' + helperSupportItem.SupportCategoryNumber;
      },
    );

    let newLineItems = [];

    _.map(uniqueBillingLineItemCombination, (lineItem) => {
      const registrationNumber = Number(_.split(lineItem.supportItemNumber, '_')[2]);
      const supportCategoryNumber = Number(_.split(lineItem.supportItemNumber, '_')[0]);
      const foundABTItems = _.filter(
        allTravelItems,
        (item) =>
          item.RegistrationGroupNumber === registrationNumber &&
          item.SupportCategoryNumber === supportCategoryNumber &&
          item.DateType === 'ABT',
      );
      const foundPTNLCItems = _.filter(
        allTravelItems,
        (item) =>
          item.RegistrationGroupNumber === registrationNumber &&
          item.SupportCategoryNumber === supportCategoryNumber &&
          item.DateType === 'PTNLC',
      );
      if (foundABTItems.length === 0) {
        newLineItems.push(_.find(ABTItems, (item) => item.SupportItemNumber === '04_590_0125_6_1'));
      } else {
        _.map(foundABTItems, (i) => {
          newLineItems.push(i);
        });
      }
      if (foundPTNLCItems.length === 0) {
        newLineItems.push(_.find(PTNLCItems, (item) => item.SupportItemNumber === '01_799_0117_8_1'));
      } else {
        _.map(foundPTNLCItems, (i) => {
          newLineItems.push(i);
        });
      }
    });

    return _.uniqBy(newLineItems, 'SupportItemNumber');
  };

  private _isNotInServiceAgreement = (serviceLineItem) => {
    return !_.find(this.props.bookingItem.serviceAgreementItems, {
      supportItemNumber: serviceLineItem.supportItemNumber,
    });
  };

  private _initBillingSupportItems = (bookingItem) => {
    const serviceBillingLineItems = _.cloneDeep(bookingItem.serviceBillingLineItems);
    const travelLineItems = this.getTravelBillingItem();
    _.map(travelLineItems, (lineItem) => {
      if (!_.find(serviceBillingLineItems, (item) => item.supportItemNumber === lineItem.SupportItemNumber)) {
        serviceBillingLineItems.push({
          billingProvider: 'NDIS',
          customerSupportLevel: this.props.bookingItem.customerSupportLevel,
          price: '0.00',
          supportCategoryNumber: lineItem.SupportCategoryNumber,
          supportItemNumber: lineItem.SupportItemNumber,
          tax: '0.00',
          unit: lineItem.UnitOfMeasure,
        });
      }
    });

    const billingSupportItems = _.map(serviceBillingLineItems, (serviceLineItem) => {
      if (serviceLineItem.billingProvider === 'NDIS') {
        const helperSupportItem = ndisHelper.getBySupportItemNumber(serviceLineItem.supportItemNumber);
        if (helperSupportItem) {
          serviceLineItem.supportType = helperSupportItem.SupportPurposeType;

          const fundedCategory = FundingUtils.isLineItemExistInFundingPackage(
            serviceLineItem.supportType,
            serviceLineItem.supportCategoryNumber,
            serviceLineItem.supportItemNumber,
            bookingItem.fundedCategories,
          );

          const ndisSupportItem = ndisHelper.getBySupportItemNumber(serviceLineItem.supportItemNumber);
          serviceLineItem.supportItem = ndisSupportItem.SupportItem;
          serviceLineItem.ruleType = ndisSupportItem.DateType;
          serviceLineItem.paymentMethod = !_.isEmpty(fundedCategory) ? fundedCategory.paymentMethod : '';
          serviceLineItem.isFunded = !_.isEmpty(fundedCategory);
          serviceLineItem.isNotInServiceAgreement = this._isNotInServiceAgreement(serviceLineItem);
          return serviceLineItem;
        }
      } else {
        return serviceLineItem;
      }
    });

    const bookingBillingLineItems = _.map(bookingItem.billingLineItems, (billingLineItem) => {
      const newBillingLineItem = _.cloneDeep(billingLineItem);
      if (billingLineItem.billingProvider === 'NDIS') {
        const ndisSupportItem = ndisHelper.getBySupportItemNumber(billingLineItem.supportItemNumber);
        newBillingLineItem.ruleType = ndisSupportItem.DateType;
        newBillingLineItem.isEditing = false;
        newBillingLineItem.supportItemNumberArray = [billingLineItem.supportItemNumber];
        newBillingLineItem.claimType = newBillingLineItem.claimType === '' ? 'STD' : billingLineItem.claimType;
        return newBillingLineItem;
      }
      return newBillingLineItem;
    });

    this.setState({
      billingSupportItems,
      bookingBillingLineItems: bookingBillingLineItems,
      bookingTotal: this._calculateBookingTotal(bookingBillingLineItems),
      // TODO need to change to bookingItem.bookingStatus
      isCancelled: false,
    });
  };

  private _changeTab = (event) => {
    this.setState({ selectedTab: event });
  };

  private _getServiceAgreementTitle = (billingPeriod) => {
    const { customerBookingPaymentDetail } = this.props;
    const { timezone } = this.props.portalUser;
    if (customerBookingPaymentDetail) {
      if (
        customerBookingPaymentDetail.billingSuggestion &&
        customerBookingPaymentDetail.billingSuggestion.length === 1
      ) {
        if (!_.isEmpty(billingPeriod.serviceAgreementBillingItems)) {
          return 'Line items being charged (From service agreement)';
        } else {
          return 'Line items being charged (From service)';
        }
      } else {
        if (!_.isEmpty(billingPeriod.serviceAgreementBillingItems)) {
          return (
            <>
              <b>{moment.tz(billingPeriod.startDateTime, timezone).format('DD/MM/YYYY')}</b> to{' '}
              <b>{moment.tz(billingPeriod.endDateTime, timezone).format('DD/MM/YYYY')}</b> (From service agreement)
            </>
          );
        } else {
          return (
            <>
              <b>{moment.tz(billingPeriod.startDateTime, timezone).format('DD/MM/YYYY')}</b> to{' '}
              <b>{moment.tz(billingPeriod.endDateTime, timezone).format('DD/MM/YYYY')}</b> (From service)
            </>
          );
        }
      }
    }
  };

  private _openSelectLineItem = () => {
    this.setState({ isSelectLineItemOpen: true });
  };

  private _closeSelectLineItem = () => {
    this.setState({ isSelectLineItemOpen: false });
  };

  private _onSaveSelection = async (selectedLineItems, selectedOption) => {
    const { bookingItem, doUpdateBookingSelectedPaymentDetail } = this.props;
    await doUpdateBookingSelectedPaymentDetail({
      bookingId: bookingItem.bookingId,
      bookingRequestId: bookingItem.bookingRequestId,
      isRecurring: bookingItem.isRecurring,
      editRecurringMode: selectedOption,
      userSelectedBillingLineItems: selectedLineItems
        ? _.map(selectedLineItems, (period) => {
            return {
              ...period,
              selectedLineItems: _.map(period.selectedLineItems, (lineItem) => {
                return lineItem.supportItemNumber;
              }),
            };
          })
        : [],
    });
  };

  private _openChangePreferredPaymentMethodModal = () => {
    this.setState({ isChangePaymentMethodModalOpen: true });
  };

  private _closeChangePreferredPaymentMethodModal = () => {
    this.setState({ isChangePaymentMethodModalOpen: false });
  };

  private _changePreferredPaymentMethod = async (selectedPaymentSourceType, selectedLineItems, selectedOption) => {
    const { bookingItem } = this.props;
    await this.props.doChangePreferredPaymentSource({
      bookingId: bookingItem.bookingId,
      paymentSourceType: selectedPaymentSourceType,
      userSelectedBillingItems: selectedLineItems,
      isRecurring: bookingItem.isRecurring,
      bookingRequestId: bookingItem.bookingRequestId,
      editRecurringMode: selectedOption,
    });
    this.setState({ isEditingBillingLineItem: false });
  };

  componentDidMount = async () => {
    const { doFetchBookingPaymentDetail, bookingItem } = this.props;
    this._initBillingSupportItems(bookingItem);
    await doFetchBookingPaymentDetail({
      serviceId: bookingItem.serviceId,
      customerUserId: bookingItem.bookerUserId,
      timeSlots: [
        {
          startDateTime: bookingItem.startDateTime,
          endDateTime: bookingItem.endDateTime,
          serviceDateTimeId: bookingItem.bookingId,
        },
      ],
      address: bookingItem.address,
      paymentSourceType: bookingItem.paymentSourceType,
    });
  };

  componentDidUpdate = async (
    prevProps: Readonly<BookingBillingPanelProps>,
    prevState: Readonly<BookingBillingPanelState>,
    snapshot?: any,
  ) => {
    const { doFetchBookingPaymentDetail, bookingItem } = this.props;
    const previousBookingItem = prevProps.bookingItem;
    if (bookingItem !== previousBookingItem) {
      this._initBillingSupportItems(bookingItem);
      await doFetchBookingPaymentDetail({
        serviceId: bookingItem.serviceId,
        customerUserId: bookingItem.bookerUserId,
        timeSlots: [
          {
            startDateTime: bookingItem.startDateTime,
            endDateTime: bookingItem.endDateTime,
            serviceDateTimeId: bookingItem.bookingId,
          },
        ],
        address: bookingItem.address,
        paymentSourceType: bookingItem.paymentSourceType,
      });
    }
  };

  render() {
    const { bookingItem, form, timezoneData, portalUser, customerBookingPaymentDetail, isBookingArchived } = this.props;
    const { isEditing, isEditingBillingLineItem, isSelectLineItemOpen } = this.state;
    const { getFieldDecorator, getFieldValue } = form;

    const isLineItemLocked =
      bookingItem.status === BookingStatus.COMPLETED &&
      (bookingItem.paymentStatus === PaymentStatus.SEND_TO_FINANCE ||
        bookingItem.paymentStatus === PaymentStatus.PROCESSED ||
        bookingItem.paymentStatus === PaymentStatus.REJECTED ||
        bookingItem.paymentStatus === PaymentStatus.WAIVED);

    //TODO: Temporary value
    const servicePaymentSourceTypes = bookingItem.servicePaymentSourceTypes;

    const isActivityRecord = bookingItem.bookingType === BookingType.ACTIVITY_RECORD;

    return (
      <div>
        <BillingModifyTravelClaimModal
          isOpen={this.state.isEditTravelClaimsOpen}
          onClose={this._closeEditTravelClaims}
          selectedBookingItem={bookingItem}
          travelClaimType={this.state.travelClaimType}
        />
        <DuplicateModal
          isOpen={this.state.isDuplicateModalOpen}
          onClose={this._closeDuplicateModal}
          duplicateMode={this.state.duplicateMode}
        />
        <SelectLineItemToChargeModal
          isOpen={isSelectLineItemOpen}
          billingPeriods={customerBookingPaymentDetail && customerBookingPaymentDetail.billingSuggestion}
          onClose={this._closeSelectLineItem}
          locationMmmGroup={bookingItem && bookingItem.mmmGroup}
          locationState={bookingItem && bookingItem.address && bookingItem.address.state}
          getServiceAgreementTitle={this._getServiceAgreementTitle}
          onSaveSelection={this._onSaveSelection}
          displayRecurringModeStep={!!bookingItem.isRecurring}
          recurringBookingList={this.props.recurringBookingList}
          paymentSourceType={bookingItem.paymentSourceType}
          bookingTimezone={bookingItem.timezone}
        />
        <ChangePreferredPaymentMethodModal
          onClose={this._closeChangePreferredPaymentMethodModal}
          isOpen={this.state.isChangePaymentMethodModalOpen}
          locationMmmGroup={bookingItem && bookingItem.mmmGroup}
          locationState={bookingItem && bookingItem.address && bookingItem.address.state}
          getServiceAgreementTitle={this._getServiceAgreementTitle}
          paymentSourceAvailableTypes={servicePaymentSourceTypes}
          onSaveSelection={this._changePreferredPaymentMethod}
          displayRecurringModeStep={!!bookingItem.isRecurring}
          bookingItem={bookingItem}
        />
        <Row type={'flex'} justify={'end'}>
          <Col span={16}>
            <div>
              <Title className="mb-none" level={3}>
                Billings
              </Title>
              <Text size="large" type={'secondary'}>
                Manage customer billings for this {isActivityRecord ? 'activity record' : 'booking'}
              </Text>
            </div>
          </Col>
          <Col span={8} className="text-align-right" />
        </Row>
        <div className={'mt-large mb-medium'}>
          <SubTitle>Preferred payment method</SubTitle>
          <PaymentSourceTag
            className={'mr-small mt-x-small'}
            paymentSource={bookingItem.paymentSourceType}
            size={'large'}
          />
          {servicePaymentSourceTypes &&
            servicePaymentSourceTypes.length > 1 &&
            bookingItem.paymentStatus !== PaymentStatus.SEND_TO_FINANCE &&
            bookingItem.paymentStatus !== PaymentStatus.PROCESSED && (
              <HyperlinkButton className={'mr-small'} onClick={this._openChangePreferredPaymentMethodModal}>
                Change
              </HyperlinkButton>
            )}
        </div>
        {isLineItemLocked && (
          <Information
            className={'mv-medium'}
            content={
              <div>
                <b>This booking has been approved and sent to payments</b>
                <br />
                You can no longer edit the line items in this booking. Any edits must be done in the payment section of
                GoodHuman..
              </div>
            }
          />
        )}
        {!isActivityRecord && (
          <>
            <Tabs defaultActiveKey={'TIMES'} animated={true} onChange={this._changeTab}>
              <Tabs.TabPane tab="Start/End Times" key="TIMES" />
              <Tabs.TabPane tab="Travel Claims" key="TRAVEL_CLAIMS" />
            </Tabs>
            {this.state.selectedTab === 'TIMES' ? (
              <Row>
                <Col span={11}>
                  <SubTitle>Worker start Date/Time</SubTitle>
                  <Row className={'p-medium mt-x-small bg-tertiary rounded'}>
                    <Col span={12}>
                      <Text color={'secondary'} size={'small'}>
                        RECORDED TIME
                      </Text>
                      <br />
                      {bookingItem.workerCheckedInDateTime ? (
                        <Text weight={'bold'}>
                          {moment
                            .tz(
                              bookingItem.portalCheckedInDateTime
                                ? bookingItem.portalCheckedInDateTime
                                : bookingItem.workerCheckedInDateTime,
                              timezoneData.displayTimezone,
                            )
                            .format('h:mmA, D MMMM YYYY')}
                        </Text>
                      ) : (
                        <Text color={'tertiary'}>
                          <Icon type={'stop'} className={'mr-x-small'} />
                          Not recorded yet.
                        </Text>
                      )}
                    </Col>
                    <Col span={12}>
                      <Text color={'secondary'} size={'small'}>
                        ACTUAL TIME
                      </Text>
                      <br />
                      {bookingItem.actualCheckedInDateTime ? (
                        <Text weight={'bold'}>
                          {moment
                            .tz(bookingItem.actualCheckedInDateTime, timezoneData.displayTimezone)
                            .format('h:mmA, D MMMM YYYY')}
                        </Text>
                      ) : (
                        <Text color={'tertiary'}>
                          <Icon type={'stop'} className={'mr-x-small'} />
                          Not recorded yet.
                        </Text>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col span={1} />
                <Col span={12}>
                  <SubTitle>Worker end Date/Time</SubTitle>
                  <Row className={'p-medium mt-x-small bg-tertiary rounded'}>
                    <Col span={12}>
                      <Text color={'secondary'} size={'small'}>
                        RECORDED TIME
                      </Text>
                      <br />
                      {bookingItem.workerCheckedOutDateTime ? (
                        <Text weight={'bold'}>
                          {moment
                            .tz(
                              bookingItem.portalCheckedOutDateTime
                                ? bookingItem.portalCheckedOutDateTime
                                : bookingItem.workerCheckedOutDateTime,
                              timezoneData.displayTimezone,
                            )
                            .format('h:mmA, D MMMM YYYY')}
                        </Text>
                      ) : (
                        <Text color={'tertiary'}>
                          <Icon type={'stop'} className={'mr-x-small'} />
                          Not recorded yet.
                        </Text>
                      )}
                    </Col>
                    <Col span={12}>
                      <Text color={'secondary'} size={'small'}>
                        ACTUAL TIME
                      </Text>
                      <br />
                      {bookingItem.actualCheckedOutDateTime ? (
                        <Text weight={'bold'}>
                          {moment
                            .tz(bookingItem.actualCheckedOutDateTime, timezoneData.displayTimezone)
                            .format('h:mmA, D MMMM YYYY')}
                        </Text>
                      ) : (
                        <Text color={'tertiary'}>
                          <Icon type={'stop'} className={'mr-x-small'} />
                          Not recorded yet.
                        </Text>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Row>
                {bookingItem.serviceClaimConfig.isChargeNdisTransportBeforeBooking &&
                  bookingItem.paymentSourceType === 'NDIS' && (
                    <Col span={24} className={'mb-large'}>
                      <div className={'flex-row justify-between'}>
                        <SubTitle>Claim for travel on the way to booking</SubTitle>
                        {this.props.hasEditBillingLineItemPermission &&
                          bookingItem.status === BookingStatus.COMPLETED &&
                          bookingItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL && (
                            <HyperlinkButton onClick={() => this._openEditTravelClaims('BEFORE_BOOKING')}>
                              Modify...
                            </HyperlinkButton>
                          )}
                      </div>
                      <Row className={'p-medium mt-x-small bg-tertiary rounded'}>
                        <Col span={8}>
                          <Text color={'secondary'} size={'small'}>
                            DISTANCE TRAVELLED
                          </Text>
                          <br />
                          <b>
                            {bookingItem.travelDistanceBeforeBooking ? bookingItem.travelDistanceBeforeBooking : '-'}{' '}
                            kilometre
                            {Number(bookingItem.travelDistanceBeforeBooking) !== 1 && 's'}
                          </b>
                        </Col>
                        <Col span={8}>
                          <Text color={'secondary'} size={'small'}>
                            TRAVEL TIME
                          </Text>
                          <br />
                          <b>
                            {bookingItem.travelTimeBeforeBooking
                              ? Number(bookingItem.travelTimeBeforeBooking).toFixed(0)
                              : '-'}{' '}
                            minute
                            {Number(bookingItem.travelTimeBeforeBooking) !== 1 && 's'}
                          </b>
                        </Col>
                        <Col span={8}>
                          <Text color={'secondary'} size={'small'}>
                            ADDITIONAL EXPENSES
                          </Text>
                          <br />
                          <b>
                            {bookingItem.additionalCostBeforeBooking
                              ? CommonUtils.formatPrice(bookingItem.additionalCostBeforeBooking)
                              : '-'}
                          </b>
                        </Col>
                      </Row>
                    </Col>
                  )}
                {((bookingItem.serviceClaimConfig.isChargeNdisTransportDuringBooking &&
                  bookingItem.paymentSourceType === 'NDIS') ||
                  (bookingItem.serviceClaimConfig.isChargeVcpTransportDuringBooking &&
                    bookingItem.paymentSourceType === 'VCP')) && (
                  <Col span={24}>
                    <div className={'flex-row justify-between'}>
                      <SubTitle>Claim for travels during booking</SubTitle>
                      {this.props.hasEditBillingLineItemPermission &&
                        bookingItem.status === BookingStatus.COMPLETED &&
                        bookingItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL && (
                          <HyperlinkButton onClick={() => this._openEditTravelClaims('DURING_BOOKING')}>
                            Modify...
                          </HyperlinkButton>
                        )}
                    </div>
                    <Row className={'p-medium mt-x-small bg-tertiary rounded'}>
                      <Col span={8}>
                        <Text color={'secondary'} size={'small'}>
                          DISTANCE TRAVELLED
                        </Text>
                        <br />
                        <b>
                          {bookingItem.travelDistanceDuringBooking ? bookingItem.travelDistanceDuringBooking : '-'}{' '}
                          kilometre
                          {bookingItem.travelDistanceDuringBooking !== 1 && 's'}
                        </b>
                      </Col>
                      <Col span={8} />
                      <Col span={8}>
                        <Text color={'secondary'} size={'small'}>
                          ADDITIONAL EXPENSES
                        </Text>
                        <br />
                        <b>
                          {bookingItem.additionalCostDuringBooking
                            ? CommonUtils.formatPrice(bookingItem.additionalCostDuringBooking)
                            : '-'}
                        </b>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            )}
          </>
        )}
        <div className="flex-row align-center justify-between pt-large">
          <Title level={3} className="mv-none pv-none pr-large">
            Billing Lines
          </Title>
          <div>
            {(bookingItem.paymentStatus === BillingPaymentStatus.INITIAL ||
              bookingItem.paymentStatus === BillingPaymentStatus.REQUIRES_APPROVAL) &&
              (bookingItem.status !== BookingStatus.CUSTOMER_CANCELLED &&
                bookingItem.status !== BookingStatus.CUSTOMER_CANCELLED_WITH_FEE &&
                bookingItem.status !== BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE &&
                bookingItem.status !== BookingStatus.BUSINESS_CANCELLED) &&
              PermissionUtils.validatePermission(
                'CreateBookingBillingLineItem',
                portalUser.permissions.permissionRoles,
                bookingItem.serviceDepartmentId,
                bookingItem.serviceId,
              ) &&
              !isBookingArchived && (
                <>
                  <PrimaryButton
                    icon={'plus'}
                    size={'large'}
                    className={'mr-medium'}
                    disabled={isEditingBillingLineItem}
                    onClick={this._onClickAddBillingLineItem}
                  >
                    Add New Billing Line
                  </PrimaryButton>
                  <SecondaryButton
                    icon={'edit'}
                    size={'large'}
                    disabled={isEditingBillingLineItem}
                    onClick={this._openSelectLineItem}
                  >
                    Edit automatically generated line items
                  </SecondaryButton>
                </>
              )}
          </div>
        </div>
        <div className={'bg-quaternary bordered p-large shadow-box mt-large mb-large'}>
          <div>
            {_.map(this.state.bookingBillingLineItems, (bookingBillingLineItem, index) => {
              return (
                <BillingLineItemV2
                  key={index}
                  wrappedComponentRef={this._setRef}
                  billingLineItem={bookingBillingLineItem}
                  bookingStatus={bookingItem.status}
                  isRemoving={false}
                  claimTravelData={{
                    transportPriceBeforeBooking:
                      bookingItem.serviceClaimConfig && bookingItem.paymentSourceType === PaymentSources.NDIS
                        ? bookingItem.serviceClaimConfig.ndisClaims &&
                          bookingItem.serviceClaimConfig.ndisClaims.transportPriceBeforeBooking
                        : null,
                    transportPriceDuringBooking:
                      bookingItem.paymentSourceType === PaymentSources.NDIS
                        ? bookingItem.serviceClaimConfig &&
                          bookingItem.serviceClaimConfig.ndisClaims &&
                          bookingItem.serviceClaimConfig.ndisClaims.transportPriceDuringBooking
                        : bookingItem.serviceClaimConfig &&
                          bookingItem.serviceClaimConfig.vcpClaims &&
                          bookingItem.serviceClaimConfig.vcpClaims.transportPriceDuringBooking,
                    travelDistanceBeforeBooking: bookingItem.travelDistanceBeforeBooking,
                    additionalCostBeforeBooking: bookingItem.additionalCostBeforeBooking,
                    travelDistanceDuringBooking: bookingItem.travelDistanceDuringBooking,
                    additionalCostDuringBooking: bookingItem.additionalCostDuringBooking,
                  }}
                  index={index}
                  isCancelled={!_.isEmpty(bookingItem.cancellationCode)}
                  cancellationCode={bookingItem.cancellationCode}
                  cancellationReason={bookingItem.cancellationReason}
                  fundedCategories={bookingItem.fundedCategories}
                  serviceBillingLineItems={bookingItem.serviceBillingLineItems}
                  showRemoveReason={true}
                  showActionButtons={
                    /* only allow initial and requires approval payment status to edit the billing item */
                    !isEditingBillingLineItem &&
                    (bookingItem.paymentStatus === PaymentStatus.INITIAL ||
                      bookingItem.paymentStatus === PaymentStatus.REQUIRES_APPROVAL)
                  }
                  billingSupportItems={this.state.billingSupportItems}
                  travelDistance={10}
                  onChangeLineItem={this._onChangeLineItem}
                  onWaiveLineItem={this._onWaiveLineItem}
                  onUnwaiveLineItem={this._onUnwaiveLineItem}
                  onEditLineItem={this._onEditLineItem}
                  onDeleteLineItem={this._onDeleteLineItem}
                  onSaveLineItem={this._onSaveLineItem}
                  onCancelLineItem={this._onCancelLineItem}
                  checkForDuplicate={this._checkForDuplicate}
                  timezoneData={timezoneData}
                  hasEditBillingLineItemPermission={PermissionUtils.validatePermission(
                    'EditBookingBillingLineItems',
                    this.props.portalUser.permissions.permissionRoles,
                    bookingItem.serviceDepartmentId,
                    bookingItem.serviceId,
                  )}
                  hasABTLineItems={_.find(
                    this.state.bookingBillingLineItems,
                    (lineItem) => lineItem.ruleType === 'ABT',
                  )}
                  hasPTNLCLineItems={_.find(
                    this.state.bookingBillingLineItems,
                    (lineItem) => lineItem.ruleType === 'PTNLC',
                  )}
                  isLineItemLocked={isLineItemLocked}
                  mmmGroup={bookingItem.address.mmmGroup}
                  locationState={bookingItem.address.state}
                  preferredPaymentSourceType={bookingItem.paymentSourceType}
                  paymentSourceAvailableTypes={servicePaymentSourceTypes}
                  serviceAgreementItems={bookingItem.serviceAgreementItems}
                  // openLineItemModal={this._openLineItemModal}
                />
              );
            })}
          </div>
          {this.state.bookingBillingLineItems.length > 0 && (
            <div className="flex width-full mb-medium justify-end">
              <div className="mr-medium">
                <FieldLabel text="Total" />
              </div>
              <FieldValueText text={`${CommonUtils.formatPrice(this.state.bookingTotal)}`} />
            </div>
          )}
        </div>
        <div ref={(ref) => (this.bottomRef = ref)} className="width-full mb-x-large text-align-right" />
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  customerBookingPaymentDetail: state.bookingsStore.customerBookingPaymentDetail,
  recurringBookingList: state.bookingsStore.recurringBookingList,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateBookingBillingLineItem: dispatch.billingsStore.doCreateBookingBillingLineItem,
  doUpdateBookingBillingLineItem: dispatch.billingsStore.doUpdateBookingBillingLineItem,
  doDeleteBookingBillingLineItem: dispatch.billingsStore.doDeleteBookingBillingLineItem,
  doWaiveBookingBillingLineItem: dispatch.billingsStore.doWaiveBookingBillingLineItem,
  doUnwaiveBookingBillingLineItem: dispatch.billingsStore.doUnwaiveBookingBillingLineItem,
  doFetchBookingPaymentDetail: dispatch.bookingsStore.doFetchBookingPaymentDetail,
  doUpdateBookingSelectedPaymentDetail: dispatch.bookingsStore.doUpdateBookingSelectedPaymentDetail,
  doChangePreferredPaymentSource: dispatch.bookingsStore.doChangePreferredPaymentSource,
});

export default connect(
  mapState,
  mapDispatch,
)(Form.create<BookingBillingPanelProps>()(BookingBillingPanel));
