import React, { Component } from 'react';
import { connect } from 'react-redux';
import BillingLineItemV2 from 'views/billings/components/BillingLineItemV2';
import { IBillingLineItem } from 'interfaces/booking-interfaces';
import { Spinner } from '@blueprintjs/core';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { PaymentSourceType } from 'utilities/enum-utils';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import { notification } from 'antd';
import _ from 'lodash';

interface IBookingBulkAddLineItemModalProps {
  isOpen: boolean;
  onClose: (refreshListing?: boolean) => void;
  doCreateGroupBookingBillingItem: typeof dispatch.groupBookingsStore.doCreateGroupBookingBillingItem;
  selectedItems: any;
  selectedSession: typeof state.groupServiceStore.selectedSession;
}

interface IBookingBulkAddLineItemModalState {
  step: number;
  title: string;
  canManuallyClose: boolean;
  newBillingLineItem: IBillingLineItem;
  displayLineItemError: boolean;
  isLoading: boolean;
}

class BookingBulkAddLineItemModal extends Component<
  IBookingBulkAddLineItemModalProps,
  IBookingBulkAddLineItemModalState
> {
  state = {
    step: 1,
    title: 'Add line item',
    canManuallyClose: true,
    newBillingLineItem: null,
    displayLineItemError: false,
    isLoading: false
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    onClose(this.state.step === 3 || this.state.step === 4);
    this.setState({ step: 1, canManuallyClose: true, title: 'Add line item' });
  };

  private _onAddLineItem = async () => {
    const { newBillingLineItem } = this.state;
    const { selectedItems, doCreateGroupBookingBillingItem } = this.props;
    try {
      this.setState({ canManuallyClose: false, isLoading: true });
      if (this._validateLineItemFields()) {
        await doCreateGroupBookingBillingItem({
          bookingIds: _.map(selectedItems, (selectedItem) => selectedItem.attendanceId),
          ...newBillingLineItem,
          qty: Number(newBillingLineItem.qty),
          billingQty: Number(newBillingLineItem.billingQty),
          claimType: newBillingLineItem.claimType === 'STD' ? '' : newBillingLineItem.claimType
        });
        this.setState({ isLoading: false });
      } else {
        this.setState({ displayLineItemError: true, isLoading: false });
      }
    } catch (e) {
      this.setState({ canManuallyClose: true, isLoading: false });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  private _validateLineItemFields = () => {
    const { newBillingLineItem } = this.state;
    if (
      !newBillingLineItem.supportItemNumber ||
      !newBillingLineItem.claimType ||
      !newBillingLineItem.unit ||
      !newBillingLineItem.unitPrice ||
      !newBillingLineItem.qty ||
      !newBillingLineItem.total ||
      !newBillingLineItem.billingTotal
    ) {
      this.setState({ displayLineItemError: true });
      return false;
    }
    return true;
  };

  private _updateNewBillingLineItems = (item) => {
    this.setState({ newBillingLineItem: item, displayLineItemError: false });
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      const newBillingLineItem: IBillingLineItem = {
        bookingBillingLineItemId: Date.now().toString(),
        startDateTime: null,
        endDateTime: null,
        supportItemNumber: null,
        supportItemNumberArray: [],
        qty: 1,
        unitPrice: 0,
        unit: null,
        description: null,
        total: 0,
        paymentMethod: null,
        ruleType: null,
        isTravel: false,
        travelDistance: 0,
        mileagePrice: 0,
        claimType: '',
        isEditing: true,
        paymentSourceType: PaymentSourceType.NDIS
      };
      this.setState({
        newBillingLineItem,
        displayLineItemError: false,
        isLoading: false
      });
    }
  }

  renderView = () => {
    const { step, newBillingLineItem } = this.state;
    const { selectedItems, selectedSession } = this.props;

    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <BillingLineItemV2
            index={0}
            isRemoving={false}
            billingLineItem={newBillingLineItem}
            billingSupportItems={[]}
            serviceBillingLineItems={[]}
            fundedCategories={null}
            bookingStatus={selectedItems && selectedItems[0] && selectedItems[0].status}
            isCancelled={false}
            cancellationCode={null}
            cancellationReason={null}
            showRemoveReason={false}
            isSaveButtonOutside={true}
            travelDistance={0}
            showActionButtons={false}
            hasABTLineItems
            hasPTNLCLineItems
            showLineItemLabel={true}
            updateBillingItem={this._updateNewBillingLineItems}
            timezoneData={selectedSession.timezone}
            serviceType={'GROUP'}
            claimTravelData={null}
            hideWarningFunding={true}
            isBulkAddLineItem={true}
          />
          {this.state.displayLineItemError && (
            <div className="flex justify-end">
              <Text color="red">Please fill in the missing information</Text>
            </div>
          )}
          <ActionModalFooter>
            <GhostButton
              className="mr-medium"
              size="large"
              onClick={this._onCloseModal}
              disabled={this.state.isLoading}
            >
              Cancel
            </GhostButton>
            <PrimaryButton size="large" onClick={this._onAddLineItem} disabled={this.state.isLoading}>
              Add
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <div className="text-align-center">
            <div className="pv-large">
              <Spinner size={150} />
            </div>

            <Paragraph>Adding the line item, won't be long...</Paragraph>
          </div>
        </div>
      );
    }

    if (step === 3) {
      return (
        <div className="anim-fade-in">
          <div className="text-align-left">
            <Paragraph>
              You have successfully added a line item to{' '}
              <b>
                {selectedItems && selectedItems.length} customer booking
                {selectedItems && selectedItems.length !== 1 ? 's' : ''}
              </b>
              .
            </Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  render() {
    let { isOpen } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="x3-large"
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this.renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateGroupBookingBillingItem: dispatch.groupBookingsStore.doCreateGroupBookingBillingItem
});

export default connect(
  mapState,
  mapDispatch
)(BookingBulkAddLineItemModal);
