import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Paragraph } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { Icon, Radio } from 'antd';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { ErrorSVG } from 'assets/UndrawSVG';
import { EditRecurringMode } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';

interface IBookingChargeFeeModalProps {
  onClose: () => void;
  selectedBooking: typeof state.bookingsStore.selectedBookingItem;
  isOpen: boolean;
  doChangeToCustomerCancelled: typeof dispatch.bookingsStore.doChangeToCustomerCancelled;
  doFetchNumberOfBookingsForRecurrence: typeof dispatch.bookingsStore.doFetchNumberOfBookingsForRecurrence;
}

class BookingChargeFeeModal extends Component<IBookingChargeFeeModalProps, any> {
  state = {
    step: 1,
    title: 'Charge cancellation fee?',
    canManuallyClose: true
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;

    // Do any manual clean up; reset to initial state
    this._reset();

    onClose();
  };

  private _doChargeCancellationFee = async () => {
    const { selectedBooking } = this.props;
    this.setState({ step: 2, canManuallyClose: false });
    try {
      await this.props.doChangeToCustomerCancelled({
        editRecurringMode: EditRecurringMode.Current,
        bookingRequestId: selectedBooking.bookingRequestId,
        isRecurring: selectedBooking.isRecurring,
        isCharge: true,
        numberOfBookingsToCharge: 1,
        reason: selectedBooking.cancellationReason,
        cancellationReason: selectedBooking.cancellationCode
      });
      this.setState({
        title: (
          <>
            Cancellation fee successfully applied
            <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
          </>
        ),
        step: 3,
        canManuallyClose: true
      });
    } catch (e) {
      this.setState({ step: 4, canManuallyClose: true });
    }
  };

  private _reset = () =>
    this.setState({
      step: 1,
      title: 'Charge cancellation fee?',
      canManuallyClose: true
    });

  private _renderView = () => {
    const { step } = this.state;
    const { selectedBooking } = this.props;

    if (step === 1) {
      return (
        <div>
          <div className="anim-slide-left">
            <div className="mb-medium">
              <Paragraph>Are you sure you want to charge cancellation fee for this booking?</Paragraph>
              <Paragraph>
                The total cancellation fee based on the line items attached to the booking is:{' '}
                <b>{CommonUtils.formatPrice(selectedBooking.total)}</b>
              </Paragraph>
              <Paragraph>The booking will have to be approved before it is sent to payments.</Paragraph>
            </div>
          </div>

          <ActionModalFooter>
            <GhostButton size="large" className="mr-medium" onClick={this._onCloseModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" onClick={this._doChargeCancellationFee}>
              Charge fee
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div>
          <div style={{ minHeight: 317 }}>
            <SpinningLoader size={150} message={'Loading'} />
          </div>
        </div>
      );
    }
    if (step === 3) {
      return (
        <div>
          <div>
            <div className="mb-medium">
              <Paragraph>The customer will be charged a cancellation fee for this booking.</Paragraph>
              <Paragraph>
                The booking is now <b>awaiting approval</b>.
              </Paragraph>
            </div>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (step === 4) {
      return (
        <div>
          <div style={{ minHeight: 317 }} className="flex-column justify-center text-align-center">
            <div className="pv-medium flex-column justify-center">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium flex-column justify-center ">
              <Paragraph>Oops something has gone wrong, please try again</Paragraph>
            </div>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Back to booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  render() {
    const { isOpen } = this.props;

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="large"
        verticalAlignment="highest"
      >
        {this._renderView()}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBooking: state.bookingsStore.selectedBookingItem
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doChangeToCustomerCancelled: dispatch.bookingsStore.doChangeToCustomerCancelled,
  doFetchNumberOfBookingsForRecurrence: dispatch.bookingsStore.doFetchNumberOfBookingsForRecurrence
});

export default connect(
  mapState,
  mapDispatch
)(BookingChargeFeeModal);
