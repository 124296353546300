import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from '../../../../../common-components/buttons/index';
import { FieldLabel, Paragraph, Title, Text } from '../../../../../common-components/typography/index';
import { INewActivityRecordData } from '../../../../../interfaces/booking-interfaces';
import { IRootDispatch, IRootState, dispatch, state } from '../../../../../stores/rematch/root-store';
import { Row, Col, Icon } from 'antd';
import ActivityRecordAddEditAttachmentWhenCreateModal from './ActivityRecordAddEditAttachmentWhenCreateModal';
import ActivityRecordAddEditNoteWhenCreateModal from './ActivityRecordAddEditNoteWhenCreateModal';
import _ from 'lodash';

interface IActivityRecordStep4NotesAttachmentsPanelProps {
  setNewActivityRecordData: typeof dispatch.bookingsStore.setNewActivityRecordData;
  newActivityRecordData: INewActivityRecordData;
  onNextStep: () => void;
  onPreviousStep: () => void;
}

interface IActivityRecordStep4NotesAttachmentsPanelState {
  note: any;
  attachments: any[];
  openNoteModal: boolean;
  isEditNote: boolean;
  openAttachmentModal: boolean;
  editAttachment: any;
  isEditAttachment: boolean;
}

class ActivityRecordStep4NotesAttachmentsPanel extends Component<
  IActivityRecordStep4NotesAttachmentsPanelProps,
  IActivityRecordStep4NotesAttachmentsPanelState
> {
  state = {
    note: null,
    attachments: [],
    openNoteModal: false,
    isEditNote: false,
    openAttachmentModal: false,
    isEditAttachment: false,
    editAttachment: null
  };

  private _onNextStep = () => {
    this.props.setNewActivityRecordData({
      ...this.props.newActivityRecordData,
      note: this.state.note,
      attachments: this.state.attachments
    });
    this.props.onNextStep();
  };

  private _onOpenNoteModal = () => {
    this.setState({ openNoteModal: true });
  };

  private _onCloseNoteModal = () => {
    this.setState({ openNoteModal: false });
  };

  private _onEditNote = () => {
    this.setState({ isEditNote: true, openNoteModal: true });
  };

  private _onDeleteNote = () => {
    this.setState({ note: null });
  };

  private _onApplyNote = (note) => {
    this.setState({ note: note });
  };

  private _onOpenAddAttachmentModal = () => {
    this.setState({ openAttachmentModal: true });
  };

  private _onCloseAttachmentModal = () => {
    this.setState({ openAttachmentModal: false });
  };

  private _onApplyAttachment = (attachment) => {
    const { attachments } = this.state;
    let newAttachments;
    if (_.findIndex(attachments, (localAttachment) => localAttachment.attachmentId === attachment.attachmentId) >= 0) {
      newAttachments = _.map(attachments, (localAttachment) => {
        if (localAttachment.attachmentId === attachment.attachmentId) {
          return attachment;
        } else {
          return localAttachment;
        }
      });
    } else {
      newAttachments = attachments.concat(attachment);
    }
    this.setState({ attachments: newAttachments, editAttachment: null, isEditAttachment: false });
  };

  private _onDeleteAttachment = (attachment) => {
    const newAttachments = _.filter(
      this.state.attachments,
      (localAttachment) => localAttachment.attachmentId !== attachment.attachmentId
    );

    this.setState({ attachments: newAttachments });
  };

  private _onEditAttachment = (attachment) => {
    this.setState({ editAttachment: attachment, isEditAttachment: true, openAttachmentModal: true });
  };

  componentDidMount() {
    const { newActivityRecordData } = this.props;
    this.setState({ note: newActivityRecordData.note, attachments: newActivityRecordData.attachments });
  }

  render() {
    const { onPreviousStep } = this.props;
    return (
      <div className="anim-slide-left">
        {this.state.openNoteModal && (
          <ActivityRecordAddEditNoteWhenCreateModal
            onClose={this._onCloseNoteModal}
            isOpen={this.state.openNoteModal}
            onApplyNote={this._onApplyNote}
            editType={this.state.isEditNote}
            note={this.state.note}
          />
        )}
        {this.state.openAttachmentModal && (
          <ActivityRecordAddEditAttachmentWhenCreateModal
            isOpen={this.state.openAttachmentModal}
            editType={this.state.isEditAttachment}
            onClose={this._onCloseAttachmentModal}
            onApplyAttachment={this._onApplyAttachment}
            attachment={this.state.editAttachment}
          />
        )}
        <Row className="ph-x4-large mt-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 184px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Paragraph>
                you may opt to enter a <b>note</b> to this activity record for recording keeping purpose.
              </Paragraph>
              <Paragraph>
                and <b>optional attachment</b> can be added to this note as well.
              </Paragraph>
              {/*<Paragraph>*/}
              {/*  Visit the <a>Help Center</a> to learn more.*/}
              {/*</Paragraph>*/}
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 184px)' }}>
            <div className="bg-white rounded-big p-large" style={{ minWidth: '250px', minHeight: '496px' }}>
              <Title level={4}>Additional details for activity record</Title>
              <div className={'mt-large'}>
                <FieldLabel text={'NOTE (OPTIONAL)'} />

                {this.state.note && (
                  <div className={'mt-small'}>
                    <Paragraph>{this.state.note.noteContent}</Paragraph>
                    {this.state.note.attachment && (
                      <div
                        style={{
                          backgroundColor: '#FAFAFA',
                          border: '1px solid #EEEEEE',
                          boxSizing: 'border-box',
                          borderRadius: '8px'
                        }}
                        className={'mt-medium p-medium'}
                      >
                        <div>
                          <Text>Attachments</Text>
                        </div>
                        <div className={'mt-x2-small'}>
                          <HyperlinkButton>{this.state.note.attachment.name}</HyperlinkButton>
                        </div>
                      </div>
                    )}
                    <div className={'mt-medium'}>
                      <HyperlinkButton onClick={this._onEditNote}>Edit note</HyperlinkButton>
                      <HyperlinkButton color={'red'} className={'ml-medium'} onClick={this._onDeleteNote}>
                        Delete note
                      </HyperlinkButton>
                    </div>
                  </div>
                )}

                {!this.state.note && (
                  <div className={'mt-small'}>
                    <HyperlinkButton onClick={this._onOpenNoteModal}>
                      <Icon type={'plus'} className={'font-size-large'} /> Add a note...
                    </HyperlinkButton>
                  </div>
                )}
              </div>

              <div className={'mt-x2-large'}>
                <FieldLabel text={'ATTACHMENTS (OPTIONAL)'} />
                {this.state.attachments.length < 1 && (
                  <div className={'mt-small'}>
                    <Text type={'secondary'}>No attachments...</Text>
                  </div>
                )}

                {this.state.attachments.length >= 1 &&
                  _.map(this.state.attachments, (attachment) => (
                    <div className={'mt-medium'} key={attachment.attachmentId}>
                      <Text>{attachment.attachment.name}</Text>
                      <div className={'mt-x2-small'}>
                        <HyperlinkButton onClick={() => this._onEditAttachment(attachment)}>
                          Edit attachment
                        </HyperlinkButton>
                        <HyperlinkButton
                          color={'red'}
                          className={'ml-medium'}
                          onClick={() => this._onDeleteAttachment(attachment)}
                        >
                          Delete attachment
                        </HyperlinkButton>
                      </div>
                    </div>
                  ))}
                {this.state.attachments.length < 1 && (
                  <div className={'mt-small'}>
                    <HyperlinkButton onClick={this._onOpenAddAttachmentModal}>
                      <Icon type={'plus'} className={'font-size-large'} /> Add an attachment...
                    </HyperlinkButton>
                  </div>
                )}
                {this.state.attachments.length >= 1 && (
                  <div className={'mt-x2-large'}>
                    <HyperlinkButton onClick={this._onOpenAddAttachmentModal}>
                      <Icon type={'plus'} className={'font-size-large'} /> Add another attachment...
                    </HyperlinkButton>
                  </div>
                )}
              </div>
            </div>

            <div className="flex-row justify-end mt-x2-large">
              <SecondaryButton size="large" onClick={onPreviousStep}>
                Previous
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onNextStep} className={'ml-medium'}>
                Next
              </PrimaryButton>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({ newActivityRecordData: state.bookingsStore.newActivityRecordData });

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewActivityRecordData: dispatch.bookingsStore.setNewActivityRecordData
});

export default connect(
  mapState,
  mapDispatch
)(ActivityRecordStep4NotesAttachmentsPanel);
