import React, { Component } from 'react';
import { Checkbox, Col, Row } from 'antd';
import TimeInput from 'common-components/time-input/TimeInput';
import moment from 'moment-timezone';

interface IServiceAvailableDayTimeProps {
  dateObject: {
    dayOfWeek: string;
    isAllDay: boolean;
    dayNumber: number;
    startDateTime;
    endDateTime;
    selected: boolean;
    isValidate: boolean;
    day: string;
  };
  onUpdateSelect;
  onUpdateStartDateTime;
  onUpdateEndDateTime;
  onUpdateIsAllDay;
  isFixedTime: boolean;
}

class ServiceAvailableDayItem extends Component<IServiceAvailableDayTimeProps, any> {
  state = {
    isActive: false,
    startDate: new Date(),
    endDate: new Date()
  };

  render() {
    let {
      dateObject,
      onUpdateSelect,
      onUpdateIsAllDay,
      onUpdateStartDateTime,
      onUpdateEndDateTime,
      isFixedTime
    } = this.props;

    const displayStartDate = moment(dateObject.startDateTime.format('YYYY-MM-DD HH:mm'));
    const displayEndDate = moment(dateObject.endDateTime.format('YYYY-MM-DD HH:mm'));

    return (
      <Row gutter={24} className="mb-medium" type={'flex'} align={'middle'}>
        <Col span={5}>
          <Checkbox
            checked={dateObject.selected}
            disabled={!isFixedTime}
            onChange={(event) => {
              const payload = { dayOfWeek: dateObject.dayOfWeek, selected: event.target.checked };
              onUpdateSelect(payload);
            }}
          >
            {dateObject.day}
          </Checkbox>
        </Col>
        <Col span={6}>
          <Checkbox
            checked={dateObject.isAllDay}
            disabled={!dateObject.selected || !isFixedTime}
            onChange={(event) => {
              const payload = { dayOfWeek: dateObject.dayOfWeek, isAllDay: event.target.checked };
              onUpdateIsAllDay(payload);
            }}
          >
            Available whole day
          </Checkbox>
        </Col>

        <Col span={6}>
          <TimeInput
            size="large"
            disabled={!dateObject.selected || dateObject.isAllDay || !isFixedTime}
            value={displayStartDate}
            // format="hh:mm A"
            // allowClear={false}
            // use12Hours={true}
            onChange={(time) => {
              const payload = {
                dayOfWeek: dateObject.dayOfWeek,
                startDateTime: time
              };
              onUpdateStartDateTime(payload);
            }}
          />
        </Col>
        <Col span={6}>
          <TimeInput
            size="large"
            disabled={!dateObject.selected || dateObject.isAllDay || !isFixedTime}
            value={displayEndDate}
            // format="HH:mm A"
            // use12Hours
            onChange={(time) => {
              const payload = {
                dayOfWeek: dateObject.dayOfWeek,
                endDateTime: time
              };
              onUpdateEndDateTime(payload);
            }}
          />
        </Col>
      </Row>
    );
  }
}

export default ServiceAvailableDayItem;
