import { PORTAL_COLORS } from 'interfaces/common-ui-types';
import { ITagFilter } from 'interfaces/filter-interfaces';
import * as constants from 'variables/data/constants.json';
import _ from 'lodash';
import { Moment } from 'moment';

class FilterUtils {
  static paymentStatusToFilter(
    key: string,
    paymentStatus: string[],
    tagIcon: string = 'calendar',
    tagColor: PORTAL_COLORS = 'blue-darker'
  ): ITagFilter {
    return {
      key: key,
      display: this.getDisplayList(paymentStatus, constants.paymentStatus),
      value: paymentStatus,
      tagIcon,
      tagColor
    };
  }

  static workerStatusToFilter(
    key: string,
    workerStatus: string[],
    tagIcon: string = 'calendar',
    tagColor: PORTAL_COLORS = 'blue-darker'
  ): ITagFilter {
    return {
      key: key,
      display: this.getDisplayList(workerStatus, constants.workerStatus),
      value: workerStatus,
      tagIcon,
      tagColor
    };
  }

  static bookingStatusToFilter(
    key: string,
    bookingStatus: string[],
    tagIcon: string = 'calendar',
    tagColor: PORTAL_COLORS = 'blue-darker'
  ): ITagFilter {
    return {
      key: key,
      display: this.getDisplayList(bookingStatus, constants.bookingStatus),
      value: bookingStatus,
      tagIcon,
      tagColor
    };
  }

  static toFilter(
    key: string,
    value: string,
    tagIcon: string = 'user',
    tagColor: PORTAL_COLORS = 'blue-darker'
  ): ITagFilter {
    return {
      key: key,
      display: value,
      value: value,
      tagIcon,
      tagColor
    };
  }

  static dateRangeToFilter(
    key: string,
    value: Moment[],
    tagIcon: string = 'schedule',
    tagColor: PORTAL_COLORS = 'blue-darker'
  ): ITagFilter {
    return {
      key: key,
      display: value ? `${value[0].format('DD/MM/YYYY')} - ${value[1].format('DD/MM/YYYY')}` : '',
      value: value,
      tagIcon,
      tagColor
    };
  }

  private static getDisplayList(values: string[], lookup: any) {
    const displayList = _.map(values, (value) => {
      return _.find(lookup, (item) => item.value === value).display;
    });

    return _.join(displayList, ', ');
  }
}

export default FilterUtils;
