import React, { Component } from 'react';
import CenteredLayout from 'layouts/CenteredLayout';
import { Paragraph, Text, Title } from 'common-components/typography';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import * as H from 'history';
import { Col, Icon, Row } from 'antd';
import EditInvoiceModal from 'views/account-management/components/FundingAndPaymentModals/EditInvoiceModal';
import EditNDISModal from 'views/account-management/components/FundingAndPaymentModals/EditNDISModal';
import { australianFlag } from 'assets/Icons/country-flags';
import EditVCPModal from 'views/account-management/components/FundingAndPaymentModals/EditVCPModal';
import _ from 'lodash';
import { PaymentSourceType } from 'utilities/enum-utils';
import { isConstructorDeclaration } from 'typescript';

interface IFundingAndPaymentViewProps {
  doFetchCompanyFundingAndPayment: typeof dispatch.companyStore.doFetchCompanyFundingAndPayment;
  doFetchCompanyLite: typeof dispatch.companyStore.doFetchCompanyLite;
  companyFundingAndPayment: typeof state.companyStore.companyFundingAndPayment;
  portalUser: typeof state.authStore.portalUser;
  history: H.History;
}

interface IFundingAndPaymentViewState {
  isLoading: boolean;
  isEditInvoiceConfigOpen: boolean;
  isEditNDISConfigOpen: boolean;
  isEditVCPConfigOpen: boolean;
}

class FundingAndPaymentView extends Component<IFundingAndPaymentViewProps, IFundingAndPaymentViewState> {
  state = {
    isLoading: true,
    isEditInvoiceConfigOpen: false,
    isEditNDISConfigOpen: false,
    isEditVCPConfigOpen: false
  };

  private _goToLandingPage = () => {
    this.props.history.push('/account/landing');
  };

  private _openEditInvoiceConfig = () => {
    this.setState({ isEditInvoiceConfigOpen: true });
  };

  private _closeEditInvoiceConfig = () => {
    this.setState({ isEditInvoiceConfigOpen: false });
  };

  private _openEditNDISConfig = () => {
    this.setState({ isEditNDISConfigOpen: true });
  };

  private _closeEditNDISConfig = () => {
    this.setState({ isEditNDISConfigOpen: false });
  };

  private _openEditVCPConfig = () => {
    this.setState({ isEditVCPConfigOpen: true });
  };

  private _closeEditVCPConfig = () => {
    this.setState({ isEditVCPConfigOpen: false });
  };

  componentDidMount = async () => {
    await this.props.doFetchCompanyFundingAndPayment({});
    this.setState({ isLoading: false });
  };

  render() {
    const { companyFundingAndPayment } = this.props;
    const VCPConfig = this.props.companyFundingAndPayment
      ? _.find(
          this.props.companyFundingAndPayment.paymentSources,
          (funding) => funding.paymentSourceType === PaymentSourceType.VCP
        )
      : null;

    return (
      <CenteredLayout backgroundColor="white" bordered={true} className={'ph-small'}>
        <EditInvoiceModal isOpen={this.state.isEditInvoiceConfigOpen} onClose={this._closeEditInvoiceConfig} />
        <EditNDISModal isOpen={this.state.isEditNDISConfigOpen} onClose={this._closeEditNDISConfig} />
        <EditVCPModal isOpen={this.state.isEditVCPConfigOpen} onClose={this._closeEditVCPConfig} />
        <div className={'pb-x4-large'}>
          <div className="mt-x-large mb-medium">
            <Title level={3} weight="regular" className="mv-none">
              <span className={'cursor-pointer text-color-blue-action'} onClick={this._goToLandingPage}>
                {'Account >'}
              </span>{' '}
              Funding and Payment
            </Title>
          </div>
          <Paragraph>
            Get paid faster and easier by deciding on payment methods available to customers, including external funding
            bodies you're member of.
          </Paragraph>
          <div className="mt-x-large bordered-bottom border-standard-gray width-2/3">
            <Row
              className={'bordered-top border-standard-gray pv-medium cursor-pointer hover-bg-quaternary'}
              gutter={24}
              onClick={this._openEditInvoiceConfig}
            >
              <Col span={18}>
                <div className={'mb-small'}>
                  <Text weight={'bold'}>Invoice</Text>
                </div>
                <Text color={'secondary'}>Generate an invoice containing payment terms.</Text>
              </Col>
              <Col span={6} className={'text-align-right'}>
                <Text>
                  Configure <Icon type={'right'} className={'ml-large'} />
                </Text>
              </Col>
            </Row>
            <Row
              className={'bordered-top border-standard-gray pv-medium cursor-pointer hover-bg-quaternary'}
              gutter={24}
              onClick={this._openEditNDISConfig}
            >
              <Col span={18}>
                <div className={'mb-small'}>
                  <Text weight={'bold'}>National Disability Insurance Scheme (NDIS)</Text>
                </div>
                <div className={'mb-small'}>
                  <img src={australianFlag} height={'8px'} alt={'Australian National Flag'} className={'mr-x-small'} />
                  <Text size={'regular'}>Australia</Text>
                </div>
                <Text color={'secondary'}>
                  The National Insurance Scheme is a scheme of the Australian Government that funds costs associated
                  with disability.
                </Text>
              </Col>
              <Col span={6} className={'text-align-right'}>
                <Text>
                  <Text color="blue-action">ON</Text> <Icon type={'right'} className={'ml-large'} />
                </Text>
              </Col>
            </Row>
            {VCPConfig && (
              <Row
                className={'bordered-top border-standard-gray pv-medium cursor-pointer hover-bg-quaternary'}
                gutter={24}
                onClick={this._openEditVCPConfig}
              >
                <Col span={18}>
                  <div className={'mb-small'}>
                    <Text weight={'bold'}>Vulnerable Children's Program (VCP)</Text>
                  </div>
                  <div className={'mb-small'}>
                    <img
                      src={australianFlag}
                      height={'8px'}
                      alt={'Australian National Flag'}
                      className={'mr-x-small'}
                    />
                    <Text size={'regular'}>Australia</Text>
                  </div>
                  <Text color={'secondary'}>
                    The Vulnerable Children's Program is an Australian Government scheme aimed at supporting at-risk
                    children in the community.
                  </Text>
                </Col>
                <Col span={6} className={'text-align-right'}>
                  <Text>
                    <Text color="blue-action">{VCPConfig.paymentSourceConfiguration.vcpEnabled ? 'ON' : 'OFF'}</Text>
                    <Icon type={'right'} className={'ml-large'} />
                  </Text>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </CenteredLayout>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyFundingAndPayment: state.companyStore.companyFundingAndPayment,
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchCompanyFundingAndPayment: dispatch.companyStore.doFetchCompanyFundingAndPayment,
  doFetchCompanyLite: dispatch.companyStore.doFetchCompanyLite
});

export default connect(mapState, mapDispatch)(FundingAndPaymentView);
