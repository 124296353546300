/* eslint-disable */
import React, { Component } from 'react';

import { Col, Row, Divider } from 'antd';

import { Title, Text } from 'common-components/typography';
import DefaultLayout from 'layouts/DefaultLayout';

class StoriesLandingView extends Component<any, any> {
  render() {
    return (
      <DefaultLayout>
        <Row type={'flex'} justify={'center'} style={{ height: '100%' }}>
          <Col span={12}>
            <Divider>
              <Title level={2}>Stories</Title>
              <Text>Coming soon</Text>
            </Divider>
          </Col>
        </Row>
      </DefaultLayout>
    );
  }
}

export default StoriesLandingView;
