import { IRootState } from 'stores/rematch/root-store';
import apiClient from 'utilities/api-client';

import _ from 'lodash';
import { CreateChannelRequest, IAssignedService, IChannel } from 'interfaces/message-interfaces';
import { TeamStatus } from 'utilities/enum-utils';

// Not sure if assigned services should be in this store, or services store.
// TODO Typing for state.
const initialState = {
  channels: [],
  assignedServices: [],
  selectedServiceRoster: []
};

// -•=== Reducers ===•-
const reducers = {
  // Set assigned services
  setAssignedServices: (state, { services }: { services: IAssignedService[] }) => {
    return { ...state, assignedServices: services };
  },

  // Append channels array to current state. Find and replace channel if found; insert if it's not.
  appendListOfChannels: (state, { channels }: { channels: IChannel[] }) => {
    let setChannels = [...state.channels];

    channels.forEach((channel) => {
      const idx = _.findIndex(setChannels, (c) => c.messageChannelId === channel.messageChannelId);

      if (idx < 0) {
        // New channel. Insert.
        setChannels.push(channel);
      } else {
        // merge with updated data. Reason for this is because of localStates that should be preserved
        setChannels[idx] = { ...setChannels[idx], ...channel };
      }
    });

    return { ...state, channels: [...setChannels] };
  },

  // Blindly replace channel based on messageChannelId
  setChannel: (state, { channel }) => ({
    ...state,
    channels: [...state.channels.filter((c) => c.messageChannelId !== channel.messageChannelId), channel]
  }),

  setSelectedServiceRoster: (state, { selectedServiceRoster }) => ({
    ...state,
    selectedServiceRoster: [...selectedServiceRoster]
  })
};

// -•=== Full Store ===•-
const channelsStore = {
  state: { ...initialState },

  reducers,

  effects: (dispatch) => ({
    // Get list of assigned services for current user.
    doGetAssignedServices: async (payload: any, state: IRootState) => {
      try {
        const endpoint = `/api/portal/messaging/services`;
        const result = await apiClient.get(endpoint);
        if (result.status === 200) {
          const { data }: { data: IAssignedService[] } = result;
          dispatch.channelsStore.setAssignedServices({ services: data });
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    // get
    doGetChannelsForService: async (payload: { serviceId: string }, state: IRootState) => {
      try {
        const endpoint = `/api/portal/messaging/channels`;
        const { serviceId } = payload;
        const request = { serviceId };
        const result = await apiClient.post(endpoint, request);

        if (result.status === 200) {
          const { data }: { data: IChannel[] } = result;

          dispatch.channelsStore.appendListOfChannels({ channels: data });
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    doGetChannelDetail: async (payload: { messageChannelId: string }, state: IRootState) => {
      try {
        const { messageChannelId } = payload;
        const endpoint = `/api/portal/messaging/channels/${messageChannelId}`;
        const result = await apiClient.get(endpoint);
        if (result.status === 200) {
          const { data }: { data: IChannel } = result;

          dispatch.channelsStore.setChannel({ channel: data });

          return data;
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    doResetUnreadCount: async (payload: { messageChannelId: string; callApi?: boolean }, state: IRootState) => {
      const { messageChannelId, callApi = false } = payload;

      // Reset channel to 0
      const channel = _.find(state.channelsStore.channels, (c) => c.messageChannelId === messageChannelId);

      if (!_.isEmpty(channel)) {
        const updatedChannel = { ...channel, unreadCount: 0 };
        dispatch.channelsStore.setChannel({ channel: updatedChannel });

        if (callApi) {
          dispatch.messagesStore.doGetMessagesForChannel({ messageChannelId, addToStore: false });
        }
      }
    },

    doCreateNewChannel: async (payload: CreateChannelRequest, state: IRootState) => {
      try {
        // longwinded in case
        const {
          serviceProviderId,
          serviceId,
          serviceDepartmentId,
          subscriberType,
          channelType,
          bookingId = null,
          message,
          subscribers
        } = payload;

        const createChannelRequest = {
          serviceProviderId,
          serviceId,
          subscriberType,
          channelType,
          bookingId,
          message,
          subscribers,
          serviceDepartmentId
        };

        const endpoint = `/api/portal/messaging/channels/create`;

        const result = await apiClient.post(endpoint, createChannelRequest);

        if (result.status === 200) {
          const { data } = result;

          const { channel }: { channel: IChannel } = data;

          dispatch.channelsStore.setChannel({ channel });

          dispatch.messagesStore.doGetMessagesForChannel({ messageChannelId: channel.messageChannelId });

          return channel;
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    doSearchForChannel: async (payload: any, state: IRootState) => {
      // TODO !!! Not implemented yet...
      // TODO Finish this
      try {
        const endpoint = ``;
        const result = await apiClient.get(endpoint);
        if (result.status === 200) {
          const { data }: { data: IAssignedService[] } = result;
          dispatch.channelsStore.setAssignedServices({ services: data });
        }
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    doGetServiceRoster: async (payload: { serviceId: string }, state: IRootState) => {
      try {
        const { serviceId } = payload;
        const supportWorkerStatus = [TeamStatus.ENABLED, TeamStatus.DRAFT, TeamStatus.BLOCKED];
        const endpoint = `api/portal/services/${serviceId}/workers`;
        const result = await apiClient.post(endpoint, { supportWorkerStatus });

        if (result.status === 200) {
          const { data } = result;
          dispatch.channelsStore.setSelectedServiceRoster({ selectedServiceRoster: data });
        }
      } catch (err) {}
    }
  })
};

export default channelsStore;
