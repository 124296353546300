import React from 'react';

import moment from 'moment';

import { HyperlinkButton } from 'common-components/buttons';
import { ROSTER_WEEKLY_CONFIG } from 'common-components/roster-control/roster-weekly/roster-weekly-config';
import { useSelector } from 'react-redux';
import { IRootState } from 'src/stores/rematch/root-store';

const { CONTENT_PANEL_WIDTH, HOUR_BLOCK_HEIGHT, SIDEBAR_WIDTH } = ROSTER_WEEKLY_CONFIG;

export function HeaderDayDisplay({ day, onClickDay }: { day: Date; onClickDay: any }) {
  const navigationStore = useSelector((state: IRootState) => state.navigationStore);

  const { sideMenuCollapsed } = navigationStore;

  const sideWidth = sideMenuCollapsed ? CONTENT_PANEL_WIDTH : CONTENT_PANEL_WIDTH + SIDEBAR_WIDTH;

  return (
    <div
      style={{
        // width: 'calc(100%/7)',
        width: `calc((100vw - ${sideWidth}px) / 7)`,
        height: `${HOUR_BLOCK_HEIGHT}px`
      }}
      className="bg-quaternary pv-small flex-column justify-center align-center line-height-100 bordered-right bordered-bottom"
    >
      <HyperlinkButton color="black" onClick={() => onClickDay(day)}>
        {moment(day).format('ddd, D MMM')}
      </HyperlinkButton>

      {/*<Text lineHeight={100}>{moment(day).format('ddd, D MMM')}</Text>*/}
    </div>
  );
}
