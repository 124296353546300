import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { RouteComponentProps } from 'react-router-dom';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { IBillingBooking } from 'interfaces/booking-interfaces';
import themeVariables from 'theme/theme-variables';
// Custom CSS
import './new-listings/css/new-booking-listing.css';

import BookingLisingPanel from './new-listings/sections/BookingListingPanel';
import CommonUtils from 'utilities/common-utils';
import * as H from 'history';
// Default Body Height
const { bodyHeight } = themeVariables;

//endregion

interface IBookingListingsV3Props extends RouteComponentProps, FormComponentProps {
  bookingsList: typeof state.bookingsStore.bookingsList;
  doFetchBookings: typeof dispatch.bookingsStore.doFetchBookings;
  doFetchMoreBookings: typeof dispatch.bookingsStore.doFetchMoreBookings;
  doSetNewBookingData?: typeof dispatch.bookingsStore.doSetNewBookingData;
  selectedFilterKey: typeof state.bookingsStore.selectedFilterKey;
  setSelectedFilterKey: typeof dispatch.bookingsStore.setSelectedFilterKey;
  bookingsFilter: typeof state.bookingsStore.bookingsFilter;
  setBookingsFilter: typeof dispatch.bookingsStore.setBookingsFilter;
  setSelectedBookings: typeof dispatch.bookingsStore.setSelectedBookings;
  doFetchFilterCounters: typeof dispatch.bookingsStore.doFetchFilterCounters;
  isSelectedAll: boolean;
  selectedBookings: IBillingBooking[];

  doBatchAcceptBookings: typeof dispatch.bookingsStore.doBatchAcceptBookings;
  doBatchAcceptConfirmBookings: typeof dispatch.bookingsStore.doBatchAcceptConfirmBookings;
  doBatchConfirmBookings: typeof dispatch.bookingsStore.doBatchConfirmBookings;
  doBatchRejectBookings: typeof dispatch.bookingsStore.doBatchRejectBookings;
  doBatchApproveBookings: typeof dispatch.bookingsStore.doBatchApproveBookings;
  doBatchSendToFinanceBookings: typeof dispatch.bookingsStore.doBatchSendToFinanceBookings;

  setBookingList: typeof dispatch.bookingsStore.setBookingList;
  setBookingListingActiveTab: typeof dispatch.bookingsStore.setBookingListingActiveTab;
  history: H.History;
  defaultBookingViews: typeof state.bookingsStore.bookingViews;
  setDisplayedBookingListingTabs: typeof dispatch.bookingsStore.setDisplayedBookingListingTabs;
}

interface IBookingListingsV3State {
  showFilterSection: boolean;
  isLoading: boolean;
}

class BookingListingV3View extends Component<IBookingListingsV3Props, IBookingListingsV3State> {
  // filter config key
  state = {
    showFilterSection: true,
    isLoading: false,
  };

  _setFilter = async (filterKey) => {
    // this.setState({ currentFilter: filterKey });
    const { setSelectedFilterKey } = this.props;
    // If the user re-click on the selected filter, reset the filters as default
    if (this.props.selectedFilterKey === filterKey) {
      this.props.defaultBookingViews.find((tab) => tab.customViewId === filterKey);
      this.props.setBookingsFilter(
        this.props.defaultBookingViews.find((tab) => tab.customViewId === filterKey).filterValue,
      );
    }
    setSelectedFilterKey(filterKey);
  };

  private _hideFilterTabToggle = () => {
    this.setState({ showFilterSection: !this.state.showFilterSection });
  };

  componentDidMount = async () => {
    const {
      selectedFilterKey,
      setSelectedFilterKey,
      setBookingsFilter,
      setBookingListingActiveTab,
      defaultBookingViews,
      doFetchFilterCounters,
    } = this.props;

    // default it to TODAY if nothing is selected.
    if (_.isEmpty(selectedFilterKey)) {
      const defaultFilterKey = 'ALL';
      await setBookingListingActiveTab(defaultBookingViews[0]);
      await setSelectedFilterKey(defaultFilterKey);
      await setBookingsFilter(defaultBookingViews[0].filterValue);
    }

    await doFetchFilterCounters([]);
  };

  componentWillUnmount() {
    const {
      setBookingsFilter,
      setDisplayedBookingListingTabs,
      // setBookingListingActiveTab,
      // defaultBookingViews
    } = this.props;
    // setBookingsFilter([]);
    setDisplayedBookingListingTabs([]);
    // setBookingListingActiveTab(defaultBookingViews[0]);
  }

  render() {
    // Used to determine what filter to apply.
    let { selectedFilterKey = 'ALL' } = this.props;

    if (_.isEmpty(selectedFilterKey)) {
      selectedFilterKey = 'ALL';
    }

    const currentFilterConfig = this.props.defaultBookingViews.find((view) => view.customViewId === selectedFilterKey);

    const searchFilter = CommonUtils.findFilter('search', this.props.bookingsFilter);
    if (searchFilter) {
      currentFilterConfig.filterValue.search = searchFilter;
    }

    return (
      <div className="bordered-top border-tertiary" style={{ height: bodyHeight, borderWidth: '2px' }}>
        <div className="flex-row height-full">
          <BookingLisingPanel
            {...this.props}
            currentFilterConfig={currentFilterConfig}
            hideFilterTabToggle={this._hideFilterTabToggle}
            setFilter={this._setFilter}
            showFilterSection={this.state.showFilterSection}
          />
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  bookingsList: state.bookingsStore.bookingsList,
  selectedFilterKey: state.bookingsStore.selectedFilterKey,
  bookingsFilter: state.bookingsStore.bookingsFilter,
  isSelectedAll: state.bookingsStore.bookingsSelectedAll,
  selectedBookings: state.bookingsStore.selectedBookings,
  defaultBookingViews: state.bookingsStore.defaultBookingViews,
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchBookings: dispatch.bookingsStore.doFetchBookings,
  doFetchFilterCounters: dispatch.bookingsStore.doFetchFilterCounters,
  doFetchMoreBookings: dispatch.bookingsStore.doFetchMoreBookings,
  setSelectedFilterKey: dispatch.bookingsStore.setSelectedFilterKey,
  setBookingsFilter: dispatch.bookingsStore.setBookingsFilter,
  setSelectedBookings: dispatch.bookingsStore.setSelectedBookings,

  // Batch update methods
  doBatchAcceptBookings: dispatch.bookingsStore.doBatchAcceptBookings,
  doBatchAcceptConfirmBookings: dispatch.bookingsStore.doBatchAcceptConfirmBookings,
  doBatchConfirmBookings: dispatch.bookingsStore.doBatchConfirmBookings,
  doBatchRejectBookings: dispatch.bookingsStore.doBatchRejectBookings,
  doBatchApproveBookings: dispatch.bookingsStore.doBatchApproveBookings,
  doBatchSendToFinanceBookings: dispatch.bookingsStore.doBatchSendToFinanceBookings,

  setBookingList: dispatch.bookingsStore.setBookingList,
  setBookingListingActiveTab: dispatch.bookingsStore.setBookingListingActiveTab,
  setDisplayedBookingListingTabs: dispatch.bookingsStore.setDisplayedBookingListingTabs,
});

export default connect(
  mapState,
  mapDispatch,
)(BookingListingV3View);
