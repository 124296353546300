import React, { Component } from 'react';
import { Icon } from 'antd';

import _ from 'lodash';
import moment from 'moment-timezone';

import './time-input.css';
import { timeZone } from 'src/interfaces/timezone-type';

const numberReg = /^-?[0-9]*(\.[0-9]*)?$/;
// const ampmReg = /^-?[apAP]*$/;

const KEYACTIONS = {
  UP: 38,
  DOWN: 40,
  LEFT: 37,
  RIGHT: 39,
  A: 65,
  P: 80
};

const checkIsNumberInput = (input) => !isNaN(input) && numberReg.test(input);
// const checkIsAMPMInput = (input) => ampmReg.test(input);

interface TimeInputProps {
  size?: 'default' | 'large' | string;
  value?: any;
  onChange?: any;
  defaultValue?: any;
  className?: any;
  containerStyle?: any;
  disabled?: boolean;
  timezone?: timeZone;
  displayIcon?: boolean;
}

interface TimeInputState {
  focus: string;
  inputBuffer: any;
  date: any;
}

class TimeInput extends Component<TimeInputProps, TimeInputState> {
  _hourInput = null;
  _minuteInput = null;
  _ampmInput = null;

  state = { focus: '', inputBuffer: ' ', date: this.props.timezone ? moment.tz(this.props.timezone) : moment() };

  // Key handlers
  _onKeyHour = (e) => {
    const { inputBuffer, date } = this.state;

    let hours = parseInt(inputBuffer);

    if (isNaN(hours)) {
      hours = 0;
    }

    if (e.keyCode === KEYACTIONS.UP) {
      hours++;
      if (hours > 12) {
        hours = hours % 12;
        this.setState({ inputBuffer: hours, date: date.add(date.format('a') === 'am' ? 12 : -12, 'hour') });
      } else {
        this.setState({ inputBuffer: hours });
      }
    }
    if (e.keyCode === KEYACTIONS.DOWN) {
      hours--;
      if (hours < 1) {
        hours = 12;
      }
      this.setState({ inputBuffer: hours });
    }
    if (e.keyCode === KEYACTIONS.RIGHT) {
      // const { inputBuffer } = this.state;
      // const { selectionStart } = this._hourInput;
      // disabled for now.
      // If it's at the end of the string, move to next column
      // if (inputBuffer.length === selectionStart) {
      //   this._minuteInput.focus();
      //   setTimeout(this._minuteInput.setSelectionRange(-1, 0), 0);
      // }
    }
  };

  _onKeyMinutes = async (e) => {
    const { inputBuffer } = this.state;
    let minutes = parseInt(inputBuffer);

    if (isNaN(minutes)) {
      minutes = 0;
    }

    if (e.keyCode === KEYACTIONS.DOWN) {
      minutes--;
      if (minutes < 0) {
        minutes = 59;
      }
      await this.setState({ inputBuffer: minutes.toString().padStart(2, '0') });
      this._minuteInput.focus();
    }

    if (e.keyCode === KEYACTIONS.UP) {
      minutes++;
      if (minutes > 59) {
        minutes = 0;
      }
      await this.setState({ inputBuffer: minutes.toString().padStart(2, '0') });
      this._minuteInput.focus();
    }
  };

  _onKeyAMPM = (e) => {
    if (e.keyCode === KEYACTIONS.A) {
      this._ampmInput.select();
      this._setAMPM('am');
    }
    if (e.keyCode === KEYACTIONS.P) {
      this._ampmInput.select();
      this._setAMPM('pm');
    }

    if (e.keyCode === KEYACTIONS.UP || e.keyCode === KEYACTIONS.DOWN) {
      const { date } = this.state;
      // this.setState({ date: date.add(date.format('a') === 'am' ? 12 : -12, 'hour') }, this.callOnChange);
      if (date.format('a') === 'am') {
        this._setAMPM('pm');
      } else {
        this._setAMPM('am');
      }
    }
  };

  _onChangeHour = (e) => {
    if (checkIsNumberInput(e.target.value)) {
      let targetHour = e.target.value;

      const targetHourArray = String(targetHour).split('');
      // If the user add a third number to the time by using the manual selector.
      if (targetHourArray.length === 3) {
        const bufferHourArray = String(this.state.inputBuffer).split('');
        // The newly added number is determined by doing the sum of the new number minus the sum of the old one and then set as the new time.
        targetHour =
          targetHourArray.reduce((a, b) => Number(a) + Number(b), 0) -
          bufferHourArray.reduce((a, b) => Number(a) + Number(b), 0);
      }

      if (targetHour > 23) {
        targetHour = 23;
      }

      this.setState({ inputBuffer: targetHour }, () => {
        // move smartly based on input. hopefully not annoying.
        if (this.state.inputBuffer.length >= 2 || (targetHour > 2 && targetHour < 10)) {
          this._minuteInput.focus();
        }

        // cursor moves only when hour input is 2 characters.
        // if (this.state.inputBuffer.length >= 2) {
        //   this._minuteInput.focus();
        // }
      });
    }
  };

  _onChangeMinutes = (e) => {
    if (checkIsNumberInput(e.target.value)) {
      let targetMinute = e.target.value;

      if (targetMinute < 0) {
        targetMinute = 0;
      }

      const targetMinuteArray = String(targetMinute).split('');
      // If the user add a third number to the time by using the manual selector.
      if (targetMinuteArray.length === 3) {
        const bufferMinuteArray = String(this.state.inputBuffer).split('');
        // The newly added number is determined by doing the sum of the new number minus the sum of the old one and then set as the new time.
        targetMinute =
          targetMinuteArray.reduce((a, b) => Number(a) + Number(b), 0) -
          bufferMinuteArray.reduce((a, b) => Number(a) + Number(b), 0);
      }

      if (targetMinute > 59) {
        targetMinute = 59;
      }

      this.setState({ inputBuffer: targetMinute }, () => {
        if (this.state.inputBuffer.length >= 2) {
          this._ampmInput.focus();
        }
      });
    }
  };

  _onChangeAMPM = (targetAmPm) => {
    // don't do anything; let key input handle it. It will only handle A and P.
  };

  _setAMPM = (targetAmPm) => {
    const { date } = this.state;
    const currentAmPm = date.format('a');

    if (currentAmPm === 'am' && targetAmPm === 'pm') {
      this.setState({ date: date.add(12, 'hour') }, this.callOnChange);
    }

    if (currentAmPm === 'pm' && targetAmPm === 'am') {
      this.setState({ date: date.add(-12, 'hour') }, this.callOnChange);
    }
  };

  _onFocus = (target, e) => {
    const { date } = this.state;
    if (target === 'hour') {
      e.target.select();
      this.setState({ focus: target, inputBuffer: date.format('hh') });
    }
    if (target === 'minutes') {
      e.target.select();
      this.setState({ focus: target, inputBuffer: date.format('mm') });
    }
    if (target === 'ampm') {
      e.target.select();
      this.setState({ focus: target });
    }
  };

  _onBlur = (target) => {
    const { date, inputBuffer } = this.state;

    if (target === 'hour') {
      let targetHour = parseInt(inputBuffer);

      const amIndicator = date.format('a');

      // Retain PM if it's PM.
      if (targetHour > 0 && amIndicator === 'pm') {
        if (targetHour < 12) {
          targetHour += 12;
        }
      }

      this.setState({ date: date.hour(targetHour), focus: '' }, this.callOnChange);
    }

    if (target === 'minutes') {
      const targetMinutes = parseInt(inputBuffer);
      this.setState({ date: date.minute(targetMinutes), focus: '' }, this.callOnChange);
    }

    if (target === 'ampm') {
      this.setState({ focus: '' });
    }
  };

  callOnChange = () => {
    const { onChange } = this.props;

    if (typeof onChange === 'function') {
      onChange(this.state.date.toDate());
    }
  };

  componentDidMount(): void {
    const { value, defaultValue, timezone } = this.props;

    // If there's a value, use that as precedence.
    if (!_.isEmpty(value)) {
      this.setState({ date: timezone ? moment.tz(value, timezone) : moment(value) });
    } else {
      // if there's a default value, use that instead.
      if (!_.isEmpty(defaultValue)) {
        this.setState({ date: timezone ? moment.tz(defaultValue, timezone) : moment(defaultValue) }, this.callOnChange);
      } else {
        this.setState({ date: timezone ? moment.tz(timezone) : moment() }, this.callOnChange);
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<TimeInputProps>, prevState: Readonly<TimeInputState>, snapshot?: any) {
    const { value, timezone } = this.props;

    if (value !== prevProps.value) {
      this.setState({ date: timezone ? moment.tz(value, timezone) : moment(value) });
    }
  }

  render() {
    const { size = 'default', className = '', disabled = false, displayIcon = true } = this.props;

    // Input buffer is used to store the current input. This experience is much better than directly changing it.
    // Focus is used to indicate which control is currently highlighted.
    const { date, inputBuffer, focus } = this.state;

    return (
      <div
        className={`timepicker-container ${!_.isEmpty(focus) && 'focused'} ${
          disabled ? 'bg-tertiary' : 'bg-white'
        } ${size} ${className}`}
        style={this.props.containerStyle}
      >
        <input
          value={focus === 'hour' ? inputBuffer || '' : date.format('hh')}
          placeholder="HH"
          disabled={disabled}
          onKeyDown={this._onKeyHour}
          onChange={this._onChangeHour}
          onFocus={(e) => this._onFocus('hour', e)}
          onBlur={() => this._onBlur('hour')}
          ref={(c) => (this._hourInput = c)}
          className="hour-input"
        />
        <span>:</span>
        <input
          value={this.state.focus === 'minutes' ? inputBuffer : date.format('mm')}
          placeholder="MM"
          disabled={disabled}
          onKeyDown={this._onKeyMinutes}
          onChange={this._onChangeMinutes}
          onFocus={(e) => this._onFocus('minutes', e)}
          onBlur={() => this._onBlur('minutes')}
          ref={(c) => (this._minuteInput = c)}
          className="minutes-input"
        />
        <input
          value={this.state.date.format('a')}
          placeholder="AM/PM"
          disabled={disabled}
          onKeyDown={this._onKeyAMPM}
          onChange={this._onChangeAMPM}
          onFocus={(e) => this._onFocus('ampm', e)}
          onBlur={() => this._onBlur('ampm')}
          ref={(c) => (this._ampmInput = c)}
          className="ampm-input"
        />
        {displayIcon && (
          <Icon type="clock-circle" className="ml-small text-color-tertiary" style={{ fontSize: '14px' }} />
        )}
      </div>
    );
  }
}

export default TimeInput;
