import React, { Component } from 'react';
import _ from 'lodash';
import { Text } from 'common-components/typography';
import moment from 'moment';
import CommonUtils from 'utilities/common-utils';
import { HyperlinkButton } from 'common-components/buttons';

interface IBatchItemRow {
  batchItem?: any;
  history?: any;
}

class BatchItemRow extends Component<IBatchItemRow> {
  state = {
    batchId: null
  };

  _onClickBatch = (batchId) => {
    const { history } = this.props;
    history.push(`/batch/details/${batchId}`);
  };

  componentDidMount = () => {
    const { batchItem } = this.props;

    if (!_.isEmpty(batchItem)) {
      const { batchId } = batchItem;
      this.setState({ batchId });
    }
  };

  render() {
    const { batchItem } = this.props;

    if (_.isEmpty(batchItem)) {
      return <></>;
    }

    const { batchId, exportedDate, firstName, lastName, numberOfInvoice, batchNumber, batchBillingTotal } = batchItem;

    return (
      <>
        <tr onClick={() => this._onClickBatch(batchId)} className="anim-fade-in bg-white">
          <td className="fluid-cell">{moment(exportedDate).format('DD/MM/YYYY')}</td>
          <td className="ellipsis">
            {firstName} {lastName}
          </td>
          <td className="ellipsis">
            <div className="pr-small">
              <Text style={{ whiteSpace: 'nowrap' }} weight="bold" size="regular">
                {batchNumber}
              </Text>
            </div>
          </td>
          <td className="ellipsis">
            <div className="pr-small">
              <Text style={{ whiteSpace: 'nowrap' }} weight="bold" size="regular">
                {numberOfInvoice} Invoice{numberOfInvoice > 1 && 's'}
              </Text>
            </div>
          </td>
          <td className="fluid-cell text-align-right">
            <div className="pr-small text-align-right">
              <Text style={{ whiteSpace: 'nowrap' }} weight="bold" size="regular">
                {CommonUtils.formatPrice(batchBillingTotal)}
              </Text>
            </div>
          </td>

          <td className="fluid-cell" onClick={() => this._onClickBatch(batchId)}>
            <div className="pr-small">
              <HyperlinkButton>Details</HyperlinkButton>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

export default BatchItemRow;
