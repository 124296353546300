import React, { Component } from 'react';

import { Paragraph, Title, Text } from 'common-components/typography';
import PrimaryButton from 'common-components/buttons/PrimaryButton';
import SecondaryButton from 'common-components/buttons/SecondaryButton';
import { Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';
import GhostButton from 'common-components/buttons/GhostButton';
import { Col, Icon, Row } from 'antd';
import { HyperlinkButton, IconButton } from 'common-components/buttons';

function ButtonPriorityRow({ button, description, code }) {
  return (
    <Row className="bordered-bottom pv-medium" type="flex" align="middle">
      <Col span={6}>
        <div className="ml-small">{button}</div>
      </Col>
      <Col span={6}>{description}</Col>
      <Col span={12}>
        <Text>
          <code>{code}</code>
        </Text>
      </Col>
    </Row>
  );
}

function ButtonPrioritySection() {
  return (
    <div className="mb-x-large">
      <div className="mb-medium">
        <Title level={3}>Priority for Buttons</Title>
      </div>

      <div className="bordered-bottom">
        <Paragraph>Buttons are categories by priority, in the following order: </Paragraph>
      </div>

      <div className="mb-x-large">
        <ButtonPriorityRow
          button={<PrimaryButton>Action</PrimaryButton>}
          description={'Primary Actions'}
          code={'<PrimaryButton>Action</PrimaryButton>'}
        />
        <ButtonPriorityRow
          button={<SecondaryButton>Action</SecondaryButton>}
          description={'Secondary Actions'}
          code={'<SecondaryButton>Action</SecondaryButton>'}
        />

        <ButtonPriorityRow
          button={<GhostButton>Action</GhostButton>}
          description={'Tertiary Actions'}
          code={'<GhostButton>Action</GhostButton>'}
        />

        <ButtonPriorityRow
          button={<IconButton icon={'ellipsis'} />}
          description={'Menu Actions'}
          code={"{<IconButton icon={'ellipsis'} />}"}
        />

        <ButtonPriorityRow
          button={<HyperlinkButton className="ml-medium">Action</HyperlinkButton>}
          description={'Info Actions'}
          code={'<HyperlinkButton>Action</HyperlinkButton>'}
        />
      </div>

      <Paragraph className="max-width-640">
        <b>Primary Buttons</b> are used for the primary actions on the page, in which is usually identified as the main
        call to action. Such actions include Save, Submit, Approve, and so forth.
      </Paragraph>

      <Paragraph className="max-width-640">
        <b>Secondary Buttons</b> are used for the secondary actions on the page, in which is usually identified as the
        secondary call to actions. This is typically used in conjunction with Primary Buttons. Such actions include
        Edit, Filter, Sort, etc.
      </Paragraph>

      <Paragraph className="max-width-640">
        <b>Ghost Buttons</b> are used for the least important actions on the page. This is typically used in conjunction
        with Primary Buttons. This is usually used for cancelling previous actions, or exiting from a modal.
      </Paragraph>

      <Paragraph className="max-width-640">
        <b>Icon Buttons</b> are used for mainly for navigational actions on the page. This is typically used in
        conjunction with Primary Buttons. This is usually used to hide away another set of options that will be revealed
        when this button is clicked.
      </Paragraph>

      <Paragraph className="max-width-640">
        <b>Hyperlink Buttons</b> are used for the least important actions on the page. This is typically used in
        conjunction with Hyperlink Buttons. This is usually used for displaying additional information, eg "Learn more"
        or "Find out more" on a section.
      </Paragraph>
    </div>
  );
}

function ButtonsExampleSection() {
  return (
    <div className="pl-small">
      <Title level={3}>Examples</Title>

      {/* Sizes */}
      <Title level={4}>Sizes</Title>

      <Paragraph className="max-width-640">
        All buttons (except for HyperlinkButton) has three sizes: <code>small</code>, <code>default</code> and{' '}
        <code>large</code>.
      </Paragraph>

      <Paragraph className="max-width-640">
        As a guideline, buttons in the main pages should always be a <code>large</code> size, whereas buttons in a Modal
        should be <code>default</code> sizes. Buttons in a listing page (eg filter / edit) should be a{' '}
        <code>small</code>
        size.
      </Paragraph>

      <div className="flex-row mb-x-large">
        <PrimaryButton size="small" className="mr-medium">
          Small
        </PrimaryButton>
        <SecondaryButton size="small" className="mr-medium">
          Small
        </SecondaryButton>
        <GhostButton size="small" className="mr-medium">
          Small
        </GhostButton>
        <PrimaryButton size="default" className="mr-medium">
          Default
        </PrimaryButton>
        <SecondaryButton size="default" className="mr-medium">
          Default
        </SecondaryButton>
        <GhostButton size="default" className="mr-medium">
          Default
        </GhostButton>
        <PrimaryButton size="large" className="mr-medium">
          Large
        </PrimaryButton>
        <SecondaryButton size="large" className="mr-medium">
          Large
        </SecondaryButton>
        <GhostButton size="large" className="mr-medium">
          Large
        </GhostButton>
      </div>

      {/*  Colors*/}
      <div className="mb-x-large">
        <Title level={4}>Colors</Title>
        <Paragraph className="max-width-640">
          The buttons may have a color assigned to it, as based on the colors available (see Colors section).
        </Paragraph>
        <Paragraph className="max-width-640">
          Note - use color sparingly; do not deviate from the standard colors (Green for primary, Red for danger) unless
          the design calls for it.
        </Paragraph>
        <div className="flex-row mb-medium">
          <PrimaryButton className="mr-medium">Default</PrimaryButton>
          <PrimaryButton color="rose" className="mr-medium">
            Rose
          </PrimaryButton>
          <PrimaryButton color="orange" className="mr-medium">
            Orange
          </PrimaryButton>
          <PrimaryButton color="blue" className="mr-medium">
            Blue
          </PrimaryButton>
        </div>
        <div className="flex-row mb-medium">
          <SecondaryButton className="mr-medium">Default</SecondaryButton>
          <SecondaryButton color="rose" className="mr-medium">
            Rose
          </SecondaryButton>
          <SecondaryButton color="orange" className="mr-medium">
            Orange
          </SecondaryButton>
          <SecondaryButton color="blue" className="mr-medium">
            Blue
          </SecondaryButton>
        </div>
        <div className="flex-row mb-x-large">
          <GhostButton className="mr-medium">Default</GhostButton>
          <GhostButton color="rose" className="mr-medium">
            Rose
          </GhostButton>
          <GhostButton color="orange" className="mr-medium">
            Orange
          </GhostButton>
          <GhostButton color="blue" className="mr-medium">
            Blue
          </GhostButton>
        </div>

        {/* Icons */}
        <div className="mb-x-large">
          <Title level={4}>Icons</Title>
          <Paragraph className="max-width-640">
            Buttons can have an icon placed inside them. The position of the icon can be specified with the{' '}
            <code>iconPosition</code> property.
          </Paragraph>
          <div className="flex-row mb-x-large">
            <PrimaryButton icon="check" className="mr-medium">
              Approve
            </PrimaryButton>
            <SecondaryButton icon="edit" className="mr-medium">
              Edit
            </SecondaryButton>
            <PrimaryButton icon="delete" className="mr-medium" color="rose">
              Delete
            </PrimaryButton>

            <PrimaryButton className="mr-medium" icon="search" iconPosition="right">
              Find out more
            </PrimaryButton>
          </div>
        </div>

        <div className="mb-x-large">
          <Title level={4}>Disabled/Loading Buttons</Title>

          <Paragraph className="max-width-640">
            Buttons can be disabled or marked as loading with the <code>disabled</code> / <code>loading</code> property.
            <br />
            <i>Hyperlink buttons cannot be disabled.</i>
            <br />
          </Paragraph>

          <div className="flex-row mb-medium">
            <PrimaryButton className="mr-medium" disabled>
              Disabled Primary
            </PrimaryButton>
            <SecondaryButton className="mr-medium" disabled>
              Disabled Secondary
            </SecondaryButton>
            <GhostButton className="mr-medium" disabled>
              Disabled Ghost
            </GhostButton>
            <IconButton icon="menu" disabled />
          </div>

          <div className="flex-row mb-x-large">
            <PrimaryButton className="mr-medium" loading>
              Disabled Primary
            </PrimaryButton>
            <SecondaryButton className="mr-medium" loading>
              Disabled Secondary
            </SecondaryButton>
            <GhostButton className="mr-medium" loading>
              Disabled Ghost
            </GhostButton>
            <IconButton icon="menu" loading />
          </div>

          <div className="mb-medium">
            <Text>
              <i>The iconPosition will determine where the loading indicator will appear.</i>
            </Text>
          </div>

          <div>
            <PrimaryButton className="mr-medium" loading icon="check" iconPosition="right">
              Disabled Primary
            </PrimaryButton>
          </div>
        </div>

        <div className="mb-x-large">
          <Title level={4}>Button Shapes</Title>

          <Paragraph className="max-width-640">
            Buttons may be rounded to give it a different contrast / look & feel.
          </Paragraph>

          <div className="flex-row">
            <PrimaryButton className="mr-medium">Submit Now</PrimaryButton>
            <SecondaryButton className="mr-medium">Send for Review</SecondaryButton>
            <IconButton shape="circle" icon="menu" className="mr-medium" />

            <IconButton
              icon="ellipsis"
              shape="circle"
              color="white"
              bordered={true}
              className="hover-bg-tertiary border-blue-action"
            />
          </div>
        </div>

        {/* Gallery*/}
        <div>
          <Title level={4}>Buttons Gallery</Title>
        </div>

        <div>
          <Text weight="bold">Navigational Buttons</Text>
        </div>

        <Popover
          position="bottom"
          content={
            <Menu>
              <MenuDivider title={<Text>Group Items By</Text>} />
              <MenuItem
                text={
                  <Text>
                    <Icon type="book" className="mr-medium text-color-tertiary" />
                    Status
                  </Text>
                }
                label={'1'}
                className="hover-bg-green-lightest"
              />
              <MenuItem
                text={
                  <Text>
                    <Icon type="calendar" className="mr-medium text-color-tertiary" />
                    Date
                  </Text>
                }
                label={'2'}
                className="hover-bg-green-lightest"
              />
              <MenuItem
                text={
                  <Text>
                    <Icon type="schedule" className="mr-medium text-color-tertiary" />
                    Service
                  </Text>
                }
                label={'3'}
                className="hover-bg-green-lightest"
              />
            </Menu>
          }
        >
          <GhostButton icon={'caret-down'} iconPosition={'right'} paddingSize="small" className="mr-medium">
            <Icon type="calendar" />
            Group by
          </GhostButton>
        </Popover>

        <br />
        <br />

        <Popover
          position="bottom-left"
          content={
            <div className="p-large" style={{ maxWidth: '480px' }}>
              <Title level={4}>Cancel Booking</Title>
              <div className="mb-medium">
                <Paragraph>Are you sure you want to cancel this booking?</Paragraph>
                <Paragraph>There will be no undo action from this target action.</Paragraph>
              </div>

              <div className="flex-row justify-end">
                <PrimaryButton className="mr-small" shape="default">
                  OK
                </PrimaryButton>
                <GhostButton shape="default">Maybe later</GhostButton>
              </div>
            </div>
          }
        >
          <SecondaryButton color="rose">Cancel Booking</SecondaryButton>
        </Popover>

        <div style={{ marginBottom: '500px' }}>{/* Whitespace filler */}</div>
      </div>
    </div>
  );
}

export class ButtonsPageView extends Component {
  state = { isLoading: false };

  render() {
    return (
      <div className="mb-x7-large">
        <Title level={1}>Buttons</Title>
        <Paragraph>The following illustrates the various types of buttons used throughout this portal.</Paragraph>
        <ButtonPrioritySection />

        <ButtonsExampleSection />
      </div>
    );
  }
}
