import React, { Component } from 'react';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, state } from 'stores/rematch/root-store';
import { ICareInfo } from 'interfaces/customer-interfaces';
import { notification } from 'antd';
import { CareInformationType } from 'utilities/enum-utils';
import _ from 'lodash';

interface DeleteCareInformationProps {
  isOpen: boolean;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  selectedCareInfo: ICareInfo; // TODO: Update Props
  onClose: () => void;
  doDeleteCustomerCareInfo: typeof dispatch.customersStore.doDeleteCustomerCareInfo;
}

interface DeleteCareInformationState {}

class DeleteCareInfoModal extends Component<DeleteCareInformationProps, DeleteCareInformationState> {
  private _onDeleteHandler = async () => {
    const { selectedCareInfo, selectedCustomer, onClose, doDeleteCustomerCareInfo } = this.props;
    const careInformationType = _.get(selectedCareInfo, 'careInformationType');
    let careInfoType = 'Care information';

    if (careInformationType === CareInformationType.PERMANENT) careInfoType = 'Disability';
    if (careInformationType === CareInformationType.MEDICATION) careInfoType = 'Medication';
    if (careInformationType === CareInformationType.SUPPORTNEEDS) careInfoType = 'Support need';
    if (careInformationType === CareInformationType.BEHAVIOUR) careInfoType = 'Behaviour';
    if (careInformationType === CareInformationType.GENERAL) careInfoType = 'General information';
    try {
      await doDeleteCustomerCareInfo({
        userId: selectedCustomer.userId,
        careInformationId: selectedCareInfo.careInformationId
      });
      await notification.success({ message: `${careInfoType} deleted successfully.` });
      onClose();
    } catch (e) {
      notification.error({ message: 'Oops something went wrong! Please try again.' });
    }
  };

  private _generateDeleteMessage = () => {
    const { selectedCareInfo } = this.props;
    const careInformationType = _.get(selectedCareInfo, 'careInformationType');

    if (careInformationType === CareInformationType.PERMANENT) return 'permanent condition';
    if (careInformationType === CareInformationType.MEDICATION) return 'medication';
    if (careInformationType === CareInformationType.SUPPORTNEEDS) return 'support need';
    if (careInformationType === CareInformationType.BEHAVIOUR) return 'behaviour';
    if (careInformationType === CareInformationType.GENERAL) return 'general information';
    return 'care information';
  };

  render() {
    const { isOpen, onClose } = this.props;
    const conditionName = this._generateDeleteMessage();
    return (
      <ActionModal
        isOpen={isOpen}
        onClose={onClose}
        title={'Delete Permanent condition'}
        showCloseButton={true}
        width={'medium'}
        verticalAlignment={'high'}
      >
        <Paragraph>
          This <b>{conditionName}</b> and the corresponding information will be removed from this consumer’s profile,
          and your support workers will no longer be able to view this information.
        </Paragraph>
        <br />
        <Text className={'mb-medium'}>Do you want to proceed?</Text>
        <ActionModalFooter>
          <SecondaryButton className="mr-medium" size="large" onClick={onClose}>
            Cancel
          </SecondaryButton>
          <PrimaryButton size="large" color="red" onClick={this._onDeleteHandler}>
            Delete
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCustomer: state.customersStore.selectedCustomer
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doDeleteCustomerCareInfo: dispatch.customersStore.doDeleteCustomerCareInfo
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteCareInfoModal);
