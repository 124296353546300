/* eslint-disable */
import React, { Component } from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';

import { Divider, Tag, message, Button, Tabs, Icon, Row, Col } from 'antd';
import { Title, Text } from 'common-components/typography';

import { tagColors } from 'theme/theme-variables';

import _ from 'lodash';

import themeVariables from 'theme/theme-variables';
import { StatusTag } from '../../common-components/tags';

const { TabPane } = Tabs;

class TextColorsView extends Component<any, any> {
  state = {
    isBold: true
  };

  render() {
    const colors = [
      'red',
      'green',
      'forest',
      'blue',
      'dark-gray',
      'gray',
      'light-gray',
      'orange',
      'gold',
      'lime',
      'turquoise',
      'cobalt',
      'sepia',
      'rose',
      'indigo',
      'violet',
      'vermilion'
    ];

    const variants = ['-darker', '-dark', '', '-light', '-lighter', '-lightest'];

    return (
      <div>
        <Divider className="divider-medium" orientation="left">
          <Title level={3}>Standard / Secondary Colors</Title>
        </Divider>

        <div className="flex-row">
          <Text className="mr-medium ">Primary</Text>
          <Text className="mr-medium text-color-secondary">Secondary</Text>
          <Text className="mr-medium text-color-tertiary">Tertiary</Text>
        </div>
        <div className="flex-row">
          <Text className="text-weight-bold mr-medium">Primary</Text>
          <Text className="text-weight-bold mr-medium text-color-secondary">Secondary</Text>
          <Text className="text-weight-bold mr-medium text-color-tertiary">Tertiary</Text>
        </div>
        <div className="flex-row">
          <Text className="text-weight-black mr-medium">Primary</Text>
          <Text className="text-weight-black mr-medium text-color-secondary">Secondary</Text>
          <Text className="text-weight-black mr-medium text-color-tertiary">Tertiary</Text>
        </div>

        <Title level={2}>
          <Divider className="divider-medium" orientation="left">
            <Title level={3}>Text Colors</Title>
          </Divider>

          <span className={'mh-medium'}>
            <Button onClick={() => this.setState({ isBold: !this.state.isBold })}>Bold/Unbold</Button>
          </span>
          <Text>{this.state.isBold ? 'Bold' : 'Not Bold'}</Text>
        </Title>

        {colors.map((color) => (
          <div key={color}>
            {variants.map((variant) => (
              <div
                className={'pv-small ph-medium mr-medium mb-medium'}
                style={{ display: 'inline-block', width: '200px' }}
                key={variant}
              >
                {this.state.isBold && (
                  <b>
                    <Text className={'text-color-' + color + variant}>{'text-color-' + color + variant}</Text>
                  </b>
                )}
                {!this.state.isBold && (
                  <Text className={'text-color-' + color + variant}>{'text-color-' + color + variant}</Text>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

class TagColorsView extends Component<{ strings: string[]; callbackfn: (color) => any }> {
  render() {
    return (
      <div>
        <Divider className="divider-medium" orientation="left">
          <Title level={2}>Tag Colors</Title>
        </Divider>

        <div>{this.props.strings.map(this.props.callbackfn)}</div>

        <br />

        <Divider className="divider-medium" orientation="left">
          <Title level={2}>Date Tags</Title>
        </Divider>

        <div style={{ display: 'inline-block' }}>
          {/* Date Wells */}
          <Title level={4}>
            <b>Date only</b>
          </Title>

          <div className="">
            <div className="mr-large mb-small">
              <Text className="text-color-secondary text-size-x-small">
                <Icon type="calendar" className="text-color-tertiary mr-x-small" />
                Tue 5 Sep 2019
              </Text>
            </div>

            <div className="mr-large mb-small">
              <Text className="text-color-secondary text-size-small">
                <Icon type="calendar" className="text-color-tertiary mr-x-small" />
                Tue 5 Sep 2019
              </Text>
            </div>

            <div className="mr-large mb-small">
              <Text className="text-color-secondary">
                <Icon type="calendar" className="text-color-tertiary mr-x-small" />
                Tue 5 Sep 2019
              </Text>
            </div>

            <div className="mr-large bordered ph-x-small rounded mb-small inline-block">
              <Text className="text-color-secondary text-size-x-small">
                <Icon type="calendar" className="text-color-tertiary mr-x-small" />
                Tue 5 Sep 2019
              </Text>
            </div>

            <br />

            <div className="mr-large bordered ph-small rounded mb-small inline-block">
              <Text className="text-color-secondary text-size-regular">
                <Icon type="calendar" className="text-color-tertiary mr-x-small" />
                Tue 5 Sep 2019
              </Text>
            </div>

            <br />

            <div className="mr-large bordered ph-small rounded mb-small inline-block">
              <Text className="text-color-secondary text-size-large">
                <Icon type="calendar" className="text-color-tertiary mr-x-small" />
                Tue 5 Sep 2019
              </Text>
            </div>
          </div>

          <br />

          <Title level={4}>Date & Time</Title>

          <div className="mr-large mb-medium">
            <Text className="text-color-secondary text-size-x-small mr-large">
              <Icon type="calendar" className="text-color-tertiary mr-x-small" />
              Tue 5 Sep 2019 moment().format(CONSTANTS.shortTimeDate) moment().format(CONSTANTS.longTimeDate)
              moment().format(CONSTANTS.shortTime) moment().format(CONSTANTS.shortDate)
            </Text>

            <Text className="text-color-secondary text-size-x-small">
              <Icon type="clock-circle" className="text-color-tertiary mr-x-small" />
              9.00 AM - 3.30 PM
            </Text>
          </div>

          <div className="mr-large mb-medium">
            <Text className="text-color-secondary text-size-small mr-large">
              <Icon type="calendar" className="text-color-tertiary mr-x-small" />
              Tuesday 5 Sep 2019
            </Text>

            <Text className="text-color-secondary text-size-small">
              <Icon type="clock-circle" className="text-color-tertiary mr-x-small" />
              9.00 AM - 3.30 PM
            </Text>
          </div>

          <div className="mr-large mb-medium">
            <Text className="text-color-secondary text-size-regular mr-large">
              <Icon type="calendar" className="text-color-tertiary mr-x-small" />
              Tuesday 5 Sep 2019
            </Text>

            <Text className="text-color-secondary text-size-regular">
              <Icon type="clock-circle" className="text-color-tertiary mr-x-small" />
              9.00 AM - 3.30 PM
            </Text>
          </div>

          <div className="mr-large ">
            <Text className="text-color-tertiary text-size-x-small mr-medium">9.00 AM - 3.30 PM</Text>
            <Text className="text-color-tertiary text-size-x-small">Tuesday 5 Sep 2019</Text>
          </div>

          <div className="mr-large ">
            <Text className="text-color-tertiary text-size-small mr-medium">9.00 AM - 3.30 PM</Text>
            <Text className="text-color-tertiary text-size-small">Tuesday 5 Sep 2019</Text>
          </div>

          <div className="mr-large">
            <Text className="text-color-tertiary text-size-regular mr-medium">9.00 AM - 3.30 PM</Text>
            <Text className="text-color-tertiary text-size-regular">Tuesday 5 Sep 2019</Text>
          </div>

          <br />

          <Title level={4}>Time only</Title>

          <div className="mr-large mb-medium">
            <Text className="text-color-secondary text-size-x-small">
              <Icon type="clock-circle" className="text-color-tertiary mr-x-small" />
              9.00 AM - 3.30 PM
            </Text>
          </div>

          <div className="mr-large mb-medium">
            <Text className="text-color-secondary text-size-small">
              <Icon type="clock-circle" className="text-color-tertiary mr-x-small" />
              9.00 AM - 3.30 PM
            </Text>
          </div>

          <div className="mr-large mb-medium">
            <Text className="text-color-secondary text-size-regular">
              <Icon type="clock-circle" className="text-color-tertiary mr-x-small" />
              9.00 AM - 3.30 PM
            </Text>
          </div>

          <div className="mr-large ">
            <Text className="text-color-tertiary text-size-x-small mr-medium">9.00 AM - 3.30 PM</Text>
          </div>

          <div className="mr-large ">
            <Text className="text-color-tertiary text-size-small mr-medium">9.00 AM - 3.30 PM</Text>
          </div>

          <div className="mr-large">
            <Text className="text-color-tertiary text-size-regular mr-medium">9.00 AM - 3.30 PM</Text>
          </div>
        </div>

        <Divider className="divider-medium" orientation="left">
          <Title level={2}>Status Tags</Title>
        </Divider>

        <Divider className="divider-medium" orientation="left">
          <Title level={4}>Alternate</Title>
        </Divider>

        <div className="bg-green rounded text-align-center inline-block ph-small pv-x2-small">
          <Text className="text-color-white">PENDING</Text>
        </div>

        <Divider orientation="left" className="divider-medium">
          <Title level={2}>Actual Status Components</Title>
        </Divider>

        <div className="flex-row flex-wrap">
          <StatusTag status="PENDING" />
          <StatusTag status="ACCEPTED" />
          <StatusTag status="CANCELLED" />
          <StatusTag status="IN_PROGRESS" />
          <StatusTag status="COMPLETED" />
          <StatusTag status="APPROVED" />
          <StatusTag status="READY_FOR_BILLING" />
          <StatusTag status="BILLED" />
          <StatusTag status="BILLING_FAILED" />
          <StatusTag status="REJECTED" />
        </div>
      </div>
    );
  }
}

class BackgroundColorsView extends Component {
  render() {
    const colors = [
      'red',
      'green',
      'forest',
      'blue',
      'dark-gray',
      'gray',
      'light-gray',
      'orange',
      'gold',
      'lime',
      'turquoise',
      'cobalt',
      'sepia',
      'rose',
      'indigo',
      'violet',
      'vermilion'
    ];

    const variants = ['-darker', '-dark', '', '-light', '-lighter', '-lightest'];

    return (
      <div>
        <Divider className="divider-medium" orientation="left">
          <Title level={2}>
            <b>Background Colors</b>
          </Title>
        </Divider>
        {colors.map((color) => (
          <div className="mb-large">
            <div className="mb-small">
              <Divider>
                <Text className="text-weight-bold text-size-regular">{_.toUpper(color)}</Text>
              </Divider>
            </div>

            {variants.map((variant) => (
              <div
                className={'rounded pv-small ph-medium mr-medium mb-medium bg-' + color + variant}
                style={{ display: 'inline-block', width: '200px' }}
              >
                <Text>{color + variant}</Text>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

class ShadowsView extends Component {
  render() {
    return (
      <div>
        <Title level={2} className="mv-none">
          <b>Shadows</b>
        </Title>

        <br />

        <div className="pv-small ph-medium rounded shadow-up mr-medium" style={{ display: 'inline-block' }}>
          Shadow Up
        </div>

        <div className="pv-small ph-medium rounded shadow-down mr-medium" style={{ display: 'inline-block' }}>
          Shadow Down
        </div>

        <div className="pv-small ph-medium rounded shadow-left mr-medium" style={{ display: 'inline-block' }}>
          Shadow Left
        </div>

        <div className="pv-small ph-medium rounded shadow-right mr-medium" style={{ display: 'inline-block' }}>
          Shadow Right
        </div>

        <div className="pv-small ph-medium rounded shadow-double mr-medium" style={{ display: 'inline-block' }}>
          Shadow Double
        </div>
      </div>
    );
  }
}

class AltFontsView extends Component {
  render() {
    return (
      <div>
        <Title level={3}>
          <b>Lato</b>
        </Title>

        <Title style={{ fontFamily: 'Lato' }}>
          <b>This is a paragraph header</b>
        </Title>

        <Text>
          Use tag <Icon /> to create an icon and set its type in the type prop. Specify the spin property to show
          spinning animation and the theme property to switch between different themes.{' '}
        </Text>

        <br />
        <br />

        <Title level={3}>
          <b>Poppins</b>
        </Title>

        <Title style={{ fontFamily: 'Poppins' }}>This is a paragraph header</Title>

        <Text className="poppins">
          Use tag <Icon /> to create an icon and set its type in the type prop. Specify the spin property to show
          spinning animation and the theme property to switch between different themes.{' '}
        </Text>

        <br />
        <br />

        <Title level={3}>
          <b>Mixed Styles</b>
        </Title>

        <Title>This is a paragraph header</Title>

        <Text>
          Use tag <Icon /> to create an icon and set its type in the type prop. Specify the spin property to show
          spinning animation and the theme property to switch between different themes.{' '}
        </Text>

        <br />
        <br />
        <br />

        <Title className="text-weight-black">This is a paragraph header</Title>
        <Text>
          Use tag <Icon /> to create an icon and set its type in the type prop. Specify the spin property to show
          spinning animation and the theme property to switch between different themes.{' '}
        </Text>
      </div>
    );
  }
}

class TextStylesView extends Component {
  render() {
    return (
      <div>
        <Divider className="divider-small" orientation="left">
          <Title>Titles</Title>
        </Divider>

        <div className="flex-row mb-medium">
          <Title level={4} className="mr-x-large">
            Level 4
          </Title>

          <Title level={4} className="mv-none mr-x-large text-weight-light">
            This is a title (Thin)
          </Title>
          <Title level={4} className="mv-none mr-x-large text-weight-regular">
            This is a title (Regular)
          </Title>
          <Title level={4} className="mv-none mr-x-large text-weight-bold">
            This is a title (Bold)
          </Title>
          <Title level={4} className="mv-none mr-x-large text-weight-black">
            This is a title(Black)
          </Title>
        </div>

        <div className="flex-row mb-medium">
          <Title level={4} className="mr-x-large">
            Level 3
          </Title>

          <Title level={3} className="mv-none mr-x-large text-weight-light">
            This is a title (Thin)
          </Title>
          <Title level={3} className="mv-none mr-x-large text-weight-regular">
            This is a title (Regular)
          </Title>
          <Title level={3} className="mv-none mr-x-large text-weight-bold">
            This is a title (Bold)
          </Title>
          <Title level={3} className="mv-none mr-x-large text-weight-black">
            This is a title(Black)
          </Title>
        </div>

        <div className="flex-row mb-medium">
          <Title level={4} className="mr-x-large">
            Level 2
          </Title>

          <Title level={2} className="mv-none mr-x-large text-weight-light">
            This is a title (Thin)
          </Title>
          <Title level={2} className="mv-none mr-x-large text-weight-regular">
            This is a title (Regular)
          </Title>
          <Title level={2} className="mv-none mr-x-large text-weight-bold">
            This is a title (Bold)
          </Title>
          <Title level={2} className="mv-none mr-x-large text-weight-black">
            This is a title(Black)
          </Title>
        </div>

        <div className="flex-row mb-medium">
          <Title level={4} className="mr-x-large">
            Level 1
          </Title>

          <Title level={1} className="mv-none mr-x-large text-weight-light">
            This is a title (Thin)
          </Title>
          <Title level={1} className="mv-none mr-x-large text-weight-regular">
            This is a title (Regular)
          </Title>
          <Title level={1} className="mv-none mr-x-large text-weight-bold">
            This is a title (Bold)
          </Title>
          <Title level={1} className="mv-none mr-x-large text-weight-black">
            This is a title(Black)
          </Title>
        </div>

        <br />

        <Divider className="divider-small" orientation="left">
          <Title>Paragraphs</Title>
        </Divider>

        <Title level={4}>Small</Title>
        <Text className="text-size-x-small">
          This is a relatively long line of wording, used to test if paragraphing is sufficiently well designed to cover
          all spacings.
        </Text>
        <br />
        <Text className="text-size-x-small text-weight-bold">
          This is a relatively long line of wording, used to test if paragraphing is sufficiently well designed to cover
          all spacings.
        </Text>
        <br />
        <Text className="text-size-x-small text-weight-black">
          This is a relatively long line of wording, used to test if paragraphing is sufficiently well designed to cover
          all spacings.
        </Text>

        <Title level={4}>Small</Title>
        <Text className="text-size-small">
          This is a relatively long line of wording, used to test if paragraphing is sufficiently well designed to cover
          all spacings.
        </Text>
        <br />
        <Text className="text-size-small text-weight-bold">
          This is a relatively long line of wording, used to test if paragraphing is sufficiently well designed to cover
          all spacings.
        </Text>
        <br />
        <Text className="text-size-small text-weight-black">
          This is a relatively long line of wording, used to test if paragraphing is sufficiently well designed to cover
          all spacings.
        </Text>

        <Title level={4}>Regular</Title>
        <Text className="text-size-regular">
          This is a relatively long line of wording, used to test if paragraphing is sufficiently well designed to cover
          all spacings.
        </Text>
        <br />
        <Text className="text-size-regular text-weight-bold">
          This is a relatively long line of wording, used to test if paragraphing is sufficiently well designed to cover
          all spacings.
        </Text>
        <br />
        <Text className="text-size-regular text-weight-black">
          This is a relatively long line of wording, used to test if paragraphing is sufficiently well designed to cover
          all spacings.
        </Text>

        <Title level={4}>Large</Title>
        <Text className="text-size-large">
          This is a relatively long line of wording, used to test if paragraphing is sufficiently well designed to cover
          all spacings.
        </Text>
        <br />
        <Text className="text-size-large text-weight-bold">
          This is a relatively long line of wording, used to test if paragraphing is sufficiently well designed to cover
          all spacings.
        </Text>
        <br />
        <Text className="text-size-large text-weight-black">
          This is a relatively long line of wording, used to test if paragraphing is sufficiently well designed to cover
          all spacings.
        </Text>

        <Title level={4}>X Large</Title>
        <Text className="text-size-x-large">
          This is a relatively long line of wording, used to test if paragraphing is sufficiently well designed to cover
          all spacings.
        </Text>
        <br />
        <Text className="text-size-x-large text-weight-bold">
          This is a relatively long line of wording, used to test if paragraphing is sufficiently well designed to cover
          all spacings.
        </Text>
        <br />
        <Text className="text-size-x-large text-weight-black">
          This is a relatively long line of wording, used to test if paragraphing is sufficiently well designed to cover
          all spacings.
        </Text>
      </div>
    );
  }
}

class CssAnimationsView extends Component {
  render() {
    return (
      <div>
        <Divider className="divider-medium">
          <Title level={2}>CSS Animations</Title>
        </Divider>

        <Text>Test</Text>
      </div>
    );
  }
}

class TestCssView extends Component<any, any> {
  render() {
    const colors = _.keys(tagColors);

    const defaultActiveKey = 'tags';

    return (
      <DefaultLayout backgroundColor={'white'}>
        <div style={{ maxWidth: '1280px', margin: '0 auto' }}>
          <Tabs defaultActiveKey={defaultActiveKey} tabPosition={'left'} animated={false}>
            <TabPane key={'text-weights'} tab={'Text Weights'}>
              <TextStylesView />
            </TabPane>

            <TabPane key={'tags'} tab={'Tags'}>
              <TagColorsView strings={colors} callbackfn={(color) => <Tag color={tagColors[color]}>{color}</Tag>} />
            </TabPane>

            <TabPane key={'shadows'} tab={'Shadows'}>
              <div className={'pb-x-large'}>
                <ShadowsView />
              </div>
            </TabPane>

            <TabPane key={'background-colors'} tab={'Background Colors'}>
              <BackgroundColorsView />
            </TabPane>

            <TabPane key={'text-colors'} tab={'Text Colors'}>
              <TextColorsView />
            </TabPane>

            <TabPane key={'alt-fonts'} tab={'Alt Font Titles'}>
              <AltFontsView />
            </TabPane>
          </Tabs>
        </div>
      </DefaultLayout>
    );
  }
}

export default TestCssView;
