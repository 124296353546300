import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { connect } from 'react-redux';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { Spinner } from '@blueprintjs/core';
import { notification } from 'antd';
import _ from 'lodash';

interface IBookingBulkWaiveModalProps {
  isOpen: boolean;
  selectedItems: any;
  onClose: (refreshListing?: boolean) => void;
  doRevokeGroupBookingsApproval: typeof dispatch.groupBookingsStore.doRevokeGroupBookingsApproval;
  selectedSession: typeof state.groupServiceStore.selectedSession;
}

interface IBookingBulkWaiveModalState {
  canManuallyClose: boolean;
  step: Number;
  title: String;
  isLoading: boolean;
}

class BookingBulkWaiveModal extends Component<IBookingBulkWaiveModalProps, IBookingBulkWaiveModalState> {
  state = {
    canManuallyClose: true,
    step: 1,
    title: 'Revoke booking approvals',
    isLoading: false
  };

  private _renderView = () => {
    const { step } = this.state;
    const { selectedItems } = this.props;

    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <div className="text-align-left">
            <Paragraph>
              You are revoking the approval for{' '}
              <b>
                {selectedItems && selectedItems.length} customer booking
                {selectedItems && selectedItems.length !== 1 ? 's' : ''}
              </b>
              .
            </Paragraph>
            <Paragraph>This will remove the bookings from payments and allow them to be edited again.</Paragraph>
          </div>
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this._onCloseModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" icon="check" onClick={this._onRevoke}>
              Revoke approval
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <div className="text-align-center">
            <div className="pv-large">
              <Spinner size={150} />
            </div>

            <Paragraph>Waiving the bookings, won't be long...</Paragraph>
          </div>
        </div>
      );
    }
    if (step === 3) {
      return (
        <div className="anim-fade-in">
          <div className="text-align-left">
            <Paragraph>
              You have successfully waived{' '}
              <b>
                {selectedItems && selectedItems.length} customer booking
                {selectedItems && selectedItems.length !== 1 ? 's' : ''}
              </b>
              .
            </Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ canManuallyClose: true, step: 1, title: 'Revoke booking approvals' });
    onClose(this.state.step === 3);
  };

  private _onRevoke = async () => {
    const { selectedItems, selectedSession } = this.props;
    try {
      this.setState({ step: 2, canManuallyClose: false, isLoading: true });
      await this.props.doRevokeGroupBookingsApproval({
        serviceId: selectedSession && selectedSession.serviceId,
        serviceDateTimeId: selectedSession && selectedSession.serviceDateTimeId,
        bookingIds: _.map(selectedItems, (selectedItem) => selectedItem.attendanceId)
      });
      this.setState({ step: 3, canManuallyClose: true, title: 'Booking approvals revoked', isLoading: false });
    } catch (e) {
      this.setState({ canManuallyClose: true, isLoading: false });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  render() {
    const { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title={this.state.title}
        width="medium"
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div className="flex-column justify-center">{this._renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({ selectedSession: state.groupServiceStore.selectedSession });

const mapDispatch = (dispatch: IRootDispatch) => ({
  doRevokeGroupBookingsApproval: dispatch.groupBookingsStore.doRevokeGroupBookingsApproval
});

export default connect(
  mapState,
  mapDispatch
)(BookingBulkWaiveModal);
