import React, { Component } from 'react';
import { ServDetailsSectionHeader } from 'views/services/details/sections/content-section/common/ServDetailsSectionHeader';
import { Avatar, Col, Divider, Row } from 'antd';
import { Text } from 'common-components/typography';
import _ from 'lodash';
import { connect } from 'react-redux';
import { IRootState } from 'stores/rematch/root-store';

class ServiceManagersTab extends Component<any, any> {
  render() {
    const { selectedService } = this.props;
    return (
      <div>
        <ServDetailsSectionHeader
          title={'Managers'}
          subtitle={'View the managers and members of this service.'}
          hasDivider={false}
        />
        <Row>
          <Col span={24}>
            <div>
              {/*<Divider className="mv-small" />*/}
              <Row type={'flex'} justify="center" align="middle" className="mt-small mb-small">
                <Col span={12}>
                  <Row type={'flex'} justify={'start'}>
                    <Text>
                      <b>Managers</b>
                    </Text>
                  </Row>
                </Col>
                <Col span={12} />
              </Row>
              <Divider className="mv-small" />
              {selectedService.managers.length <= 0 ? (
                <div className="mv-medium">
                  <Row className="mt-small">
                    <Text color="secondary" className="mt-small">
                      No managers set.
                    </Text>
                  </Row>
                </div>
              ) : (
                _.map(
                  _.sortBy(
                    selectedService.managers,
                    [(manager) => manager.firstName.toLowerCase(), (manager) => manager.lastName.toLowerCase()],
                    'desc'
                  ),
                  (manager) => {
                    return (
                      <div className="mv-medium">
                        <Row type={'flex'} justify={'start'} align={'middle'} className="mt-small" key={manager.userId}>
                          <Col span={24}>
                            <div className="flex-row align-center">
                              <Avatar src={manager.attachmentUrl} className="mr-medium" />
                              <Text>{manager.firstName + ' ' + manager.lastName}</Text>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  }
                )
              )}
            </div>
          </Col>
          <Col span={24}>
            <div>
              {/*<Divider className="mv-small" />*/}
              <Row type={'flex'} justify="center" align="middle" className="mt-small mb-small">
                <Col span={12}>
                  <Row type={'flex'} justify={'start'}>
                    <Text>
                      <b>Members</b>
                    </Text>
                  </Row>
                </Col>
                <Col span={12} />
              </Row>
              <Divider className="mv-small" />
              {selectedService.serviceMembers.length <= 0 ? (
                <div className="mv-medium">
                  <Row className="mt-small">
                    <Text color="secondary" className="mt-small">
                      No members set.
                    </Text>
                  </Row>
                </div>
              ) : (
                _.map(
                  _.sortBy(
                    selectedService.serviceMembers,
                    [(customer) => customer.firstName.toLowerCase(), (customer) => customer.lastName.toLowerCase()],
                    'desc'
                  ),
                  (manager) => {
                    return (
                      <div className="mv-medium">
                        <Row type={'flex'} justify={'start'} align={'middle'} className="mt-small" key={manager.userId}>
                          <Col span={24}>
                            <div className="flex-row align-center">
                              <Avatar src={manager.attachmentUrl} className="mr-medium" />
                              <Text>{manager.firstName + ' ' + manager.lastName}</Text>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    );
                  }
                )
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedService: state.servicesStore.selectedService
});

export default connect(
  mapState,
  null
)(ServiceManagersTab);
