import React from 'react';

import _ from 'lodash';
import * as H from 'history';

import { Collapse } from '@blueprintjs/core';

import { ROSTER_DAILY_CONFIG } from 'common-components/roster-control/roster-daily/roster-daily-config';
import { Gridlines } from 'common-components/roster-control/roster-daily/timeline-pane/common/Gridlines';
import { TimelineRow } from 'common-components/roster-control/roster-daily/timeline-pane/common/TimelineRow';
import { WorkerShiftCard } from 'common-components/roster-control/roster-daily/timeline-pane/workers/WorkerShiftCard';
import { WorkersTimelineHeader } from 'common-components/roster-control/roster-daily/timeline-pane/workers/WorkersTimelineHeader';
import OpenShiftCard from 'common-components/roster-control/roster-daily/timeline-pane/workers/OpenShiftCard';

import {
  IRosterShift,
  IRosterWorker,
  IRosterWorkerWithShifts
} from 'common-components/roster-control/interfaces/roster-interfaces';
import { WorkerTimelineMarker } from './WorkerTimelineMarker';

// import { WorkerTimelineMarker } from 'common-components/roster-control/roster-daily/timeline-pane/workers/WorkerTimelineMarker';

// Change this to show empty rows whenever there's no data. A bit buggy, so setting it to 1 for now.
const EMPTY_ROW_COUNT = 1;

const { TIMELINE_ROW_HEIGHT } = ROSTER_DAILY_CONFIG;

interface WorkersTimelineProps {
  hours: string[];
  assignedShifts: IRosterWorkerWithShifts[];
  unassignedShifts: IRosterShift[][];
  workers: IRosterWorker[];
  isWorkersOpen: boolean;
  refreshData?: () => void;
  history: H.History;
}

export function WorkersTimeline({
  hours,
  assignedShifts = [],
  unassignedShifts = [],
  workers = [],
  isWorkersOpen,
  refreshData,
  history
}: WorkersTimelineProps) {
  const { HOUR_BLOCK_WIDTH } = ROSTER_DAILY_CONFIG;

  const rowWidth = _.size(hours) * HOUR_BLOCK_WIDTH;

  const hasOpenShifts = !_.isEmpty(unassignedShifts);

  const workersCount = _.size(workers);
  const hasWorkers = workersCount > 0;

  const openShiftsHeight = _.size(unassignedShifts) * TIMELINE_ROW_HEIGHT;

  // Row count is used to render the grid lines.
  // In this case, if there's open shifts, it should add one more additional row to render on top
  // let rowCount = hasOpenShifts ? workersCount + 1 : workersCount;

  // No workers; generate X rows of empty rows
  // if (!hasWorkers) {
  //   rowCount = hasOpenShifts ? EMPTY_ROW_COUNT + 1 : EMPTY_ROW_COUNT;
  // }

  // New code to calculate grid lines.
  const unassignedRowCount = _.size(unassignedShifts);
  const assignedRowCount = _.reduce(assignedShifts, (acc, shiftGroup) => acc + _.size(shiftGroup.shifts), 0);

  const totalRowCount = unassignedRowCount + assignedRowCount;

  // TODO : Group markers for timeline
  // See code at the bottom of this file for examples
  const headerMarkers = [];

  return (
    <div>
      <WorkersTimelineHeader rowWidth={rowWidth} markers={headerMarkers} />

      <Collapse isOpen={isWorkersOpen} keepChildrenMounted={false}>
        <div className="flex-column position-relative">
          <Gridlines hours={hours} rowCount={totalRowCount} />

          {/* If there's no workers, show empty rows instead as a place holder. */}
          {!hasWorkers && !hasOpenShifts && (
            <>
              {_.times(EMPTY_ROW_COUNT, (idx) => (
                <TimelineRow rowWidth={rowWidth} key={idx} backgroundColor={'tertiary'} />
              ))}
            </>
          )}

          {/* Open shifts*/}
          {hasOpenShifts && (
            <TimelineRow
              rowWidth={rowWidth}
              items={<OpenShiftsList unassignedShifts={unassignedShifts} history={history} />}
              rowHeight={openShiftsHeight}
            />
          )}

          {/* Workers */}

          {/* Assigned shifts (Shifts with workers) */}
          {_.map(workers, (worker, idx) => {
            const workerShifts = _.find(assignedShifts, (shifts) => shifts.supportWorkerId === worker.supportWorkerId);

            const shiftGroups = workerShifts && !_.isEmpty(workerShifts.shifts) ? workerShifts.shifts : [];

            const rowHeight = _.size(shiftGroups) > 0 ? TIMELINE_ROW_HEIGHT * _.size(shiftGroups) : TIMELINE_ROW_HEIGHT;

            return (
              <TimelineRow
                rowWidth={rowWidth}
                key={idx}
                rowHeight={rowHeight}
                items={<WorkerItemsList shifts={shiftGroups} refreshData={refreshData} history={history} />}
              />
            );
          })}
        </div>
      </Collapse>
    </div>
  );
}

function OpenShiftsList({ unassignedShifts, history }: { unassignedShifts: IRosterShift[][]; history: H.History }) {
  return (
    <>
      {_.map(unassignedShifts, (shiftGroup, rowIdx) =>
        _.map(shiftGroup, (shift, shiftIdx) => (
          <OpenShiftCard shift={shift} key={shiftIdx} history={history} rowIndex={rowIdx} />
        ))
      )}
    </>
  );
}

function WorkerItemsList({
  shifts,
  refreshData,
  history
}: {
  shifts: IRosterShift[][];
  refreshData?: () => void;
  history: H.History;
}) {
  return (
    <>
      {_.map(shifts, (shiftGroup, rowIdx) =>
        _.map(shiftGroup, (shift, shiftIdx) => (
          <WorkerShiftCard shift={shift} key={shiftIdx} refreshData={refreshData} history={history} rowIndex={rowIdx} />
        ))
      )}
    </>
  );
}

// Sample code for group timeline markers
const headerMarkers = [
  <WorkerTimelineMarker
    assigned={1}
    required={1}
    startDateTime={new Date(2021, 9, 4, 10, 45)}
    endDateTime={new Date(2021, 9, 4, 12, 45)}
    key={1}
  />,
  <WorkerTimelineMarker
    assigned={1}
    required={1}
    startDateTime={new Date(2021, 9, 4, 14, 45)}
    endDateTime={new Date(2021, 9, 4, 16, 15)}
    key={2}
  />,
  <WorkerTimelineMarker
    assigned={0}
    required={1}
    startDateTime={new Date(2021, 9, 4, 16, 15)}
    endDateTime={new Date(2021, 9, 4, 17, 15)}
    key={3}
  />,
  <WorkerTimelineMarker
    assigned={0}
    required={1}
    startDateTime={new Date(2021, 9, 4, 17, 15)}
    endDateTime={new Date(2021, 9, 4, 17, 45)}
    key={4}
  />
];
