import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Form, Icon, Input, InputNumber, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import TextArea from 'antd/lib/input/TextArea';
import _ from 'lodash';
import { PaymentSourceType } from 'utilities/enum-utils';

interface IAssignNewPrimaryOwnerProps extends FormComponentProps {
  isOpen: any;
  onClose: () => void;
  doEditInvoiceConfig: typeof dispatch.companyStore.doEditInvoiceConfig;
  companyFundingAndPayment: typeof state.companyStore.companyFundingAndPayment;
}

interface IAssignNewPrimaryOwnerState {
  title: any;
  isCreated: boolean;
}

class EditInvoiceModal extends Component<IAssignNewPrimaryOwnerProps, IAssignNewPrimaryOwnerState> {
  state = { title: 'Invoice Configuration', isCreated: false };

  private _onCloseModal = () => {
    this.setState({ title: 'Invoice Configuration', isCreated: false });
    this.props.onClose();
  };

  private _validatePrefix = (rule, value, callback) => {
    try {
      const onlyLetters = /^[a-zA-Z]+$/;
      if (!onlyLetters.test(value)) {
        throw Error('Invoice prefix can only contains letters.');
      }
      if (value.length === 0) {
        throw Error('Please enter at least 1 letter.');
      }
    } catch (err) {
      callback(err);
      return;
    }
    callback();
  };

  private _editInvoiceConfig = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        await this.props.doEditInvoiceConfig({
          invoicePrefix: form.getFieldValue('invoicePrefix'),
          invoiceDueDate: form.getFieldValue('invoiceDueDate'),
          invoiceTerms: form.getFieldValue('invoiceTerms') ? form.getFieldValue('invoiceTerms') : ''
        });
        this.setState({
          title: (
            <>
              Invoice successfully edited
              <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
            </>
          ),
          isCreated: true
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
  };

  render() {
    const { isOpen, form } = this.props;
    const { getFieldDecorator } = form;

    const InvoiceConfig = this.props.companyFundingAndPayment
      ? _.find(
          this.props.companyFundingAndPayment.paymentSources,
          (funding) => funding.paymentSourceType === PaymentSourceType.INVOICE
        )
      : null;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {this.state.isCreated ? (
          <>
            <div className="mv-medium anim-slide-left">
              <SubTitle>Invoice prefix</SubTitle>
              <Text size={'x-large'}>{InvoiceConfig ? InvoiceConfig.invoicePrefix : '-'}</Text>
              <div className={'mt-large'}>
                <SubTitle>Invoice due after</SubTitle>
                <Text size={'x-large'}>
                  {InvoiceConfig
                    ? InvoiceConfig.paymentSourceConfiguration.invoiceDueDate +
                      ' DAY' +
                      (InvoiceConfig.paymentSourceConfiguration.invoiceDueDate !== 1 ? 'S' : '')
                    : '-'}
                </Text>
              </div>
              <div className={'mt-large'}>
                <SubTitle>Invoice terms & conditions</SubTitle>
                <Text style={{ whiteSpace: 'pre-line' }}>
                  {InvoiceConfig ? InvoiceConfig.paymentSourceConfiguration.invoiceTerms : '-'}
                </Text>
              </div>
            </div>

            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Done
              </PrimaryButton>
            </ActionModalFooter>
          </>
        ) : (
          <>
            <div className="anim-slide-left">
              <div className={'mb-x-large'}>
                <Paragraph>Customise aspects of invoices that are generate in GoodHuman.</Paragraph>
                <div className={'mt-large'}>
                  <SubTitle>Invoice prefix</SubTitle>
                  <Form.Item className="width-full m-none mt-small">
                    {getFieldDecorator('invoicePrefix', {
                      initialValue: InvoiceConfig && InvoiceConfig.invoicePrefix,
                      rules: [{ validator: this._validatePrefix }]
                    })(<Input style={{ width: '100px' }} maxLength={5} size={'large'} />)}
                  </Form.Item>
                </div>
                <div className={'mt-large'}>
                  <SubTitle>Invoice due after</SubTitle>
                  <Form.Item className="width-full m-none mt-small">
                    {getFieldDecorator('invoiceDueDate', {
                      initialValue: InvoiceConfig && InvoiceConfig.paymentSourceConfiguration.invoiceDueDate,
                      rules: [{ required: true, message: 'Please enter a due date in days for the invoices.' }]
                    })(<InputNumber min={1} max={365} size={'large'} style={{ width: '80px' }} />)}
                    <span className={'ml-medium'}>Days</span>
                  </Form.Item>
                </div>
                <div className={'mt-large'}>
                  <SubTitle>Invoice terms & conditions</SubTitle>
                  <Form.Item className="width-full m-none mt-small">
                    {getFieldDecorator('invoiceTerms', {
                      initialValue: InvoiceConfig && InvoiceConfig.paymentSourceConfiguration.invoiceTerms
                    })(
                      <TextArea
                        placeholder="Add terms & conditions to your invoices"
                        autoSize={{ minRows: 4, maxRows: 4 }}
                      />
                    )}
                  </Form.Item>
                </div>
              </div>

              <ActionModalFooter>
                <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton size="large" onClick={this._editInvoiceConfig}>
                  Save changes
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyFundingAndPayment: state.companyStore.companyFundingAndPayment
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditInvoiceConfig: dispatch.companyStore.doEditInvoiceConfig
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IAssignNewPrimaryOwnerProps>()(EditInvoiceModal));
