import { Text } from 'common-components/typography';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Col, Row } from 'antd';
import React, { PureComponent } from 'react';
import CommonUtils from 'utilities/common-utils';
import { DateRangePicker } from '@blueprintjs/datetime';
import { timeZone } from 'interfaces/timezone-type';
import moment from 'moment-timezone';
import { DateFilterPeriodModes, FilterType } from 'utilities/enum-utils';
import { IFilter } from 'interfaces/filter-interfaces';
import { Moment } from 'moment';

interface IFilterMenuDateRangeProps {
  filter: IFilter;
  canRemove?: boolean;
  displayTimezone: timeZone;
  saveNewFilterValue: (filterType, newFilter, selectionLabel) => void;
  removeFilter: (filterType) => void;
}

interface IFilterMenuDateRangeState {
  isSearching: boolean;
  startDate: Moment;
  endDate: Moment;
  isCustom: boolean;
}

class FilterMenuDateRange extends PureComponent<IFilterMenuDateRangeProps, IFilterMenuDateRangeState> {
  state = {
    isSearching: false,
    startDate: this.props.filter.values
      ? moment(moment.tz(this.props.filter.values[0], this.props.displayTimezone).format('YYYY-MM-DD HH:mm'))
      : null,
    endDate: this.props.filter.values
      ? moment(moment.tz(this.props.filter.values[1], this.props.displayTimezone).format('YYYY-MM-DD HH:mm'))
      : null,
    isCustom: false
  };

  private _addSelection = (event) => {
    const { displayTimezone } = this.props;
    this.setState(
      {
        startDate: event[0] ? moment(event[0]).startOf('day') : null,
        endDate: event[1] ? moment(event[1]).endOf('day') : null
      },
      () => console.log('RESULT: ', moment(this.state.startDate).format('DD/MM/YYYY HH:mm ss'))
    );
  };

  private _applySelection = async () => {
    const { displayTimezone, saveNewFilterValue } = this.props;
    const { startDate, endDate } = this.state;
    saveNewFilterValue(
      this.props.filter.filter,
      !startDate
        ? []
        : [
            moment.tz(moment(startDate).format('YYYY-MM-DD HH:mm'), displayTimezone),
            endDate
              ? moment.tz(moment(endDate).format('YYYY-MM-DD HH:mm'), displayTimezone)
              : moment.tz(
                  moment(startDate)
                    .endOf('day')
                    .format('YYYY-MM-DD HH:mm'),
                  displayTimezone
                )
          ],
      CommonUtils.getFilterText(FilterType.DATE_RANGE, [startDate, endDate], null, displayTimezone)
    );
  };

  private _closePopover = () => {
    this.setState({
      startDate: null,
      endDate: null
    });
  };

  private _getSelectedOption = () => {
    const { displayTimezone } = this.props;
    const { startDate, endDate } = this.state;

    if (!startDate && !endDate) {
      return this.state.isCustom ? DateFilterPeriodModes.CUSTOM : DateFilterPeriodModes.ALL;
    }
    if (!startDate) {
      return null;
    }
    if (
      startDate.isSame(moment.tz(displayTimezone), 'date') &&
      (!endDate || endDate.isSame(moment.tz(displayTimezone), 'date'))
    ) {
      return DateFilterPeriodModes.TODAY;
    }
    if (startDate && endDate) {
      if (
        startDate.isSame(moment.tz(displayTimezone).startOf('isoWeek'), 'date') &&
        endDate.isSame(moment.tz(displayTimezone).endOf('isoWeek'), 'date')
      ) {
        return DateFilterPeriodModes.THIS_WEEK;
      }
      if (
        startDate.isSame(moment.tz(displayTimezone).startOf('month'), 'date') &&
        endDate.isSame(moment.tz(displayTimezone).endOf('month'), 'date')
      ) {
        return DateFilterPeriodModes.THIS_MONTH;
      }
      if (
        startDate.isSame(moment.tz(displayTimezone).add(-7, 'day'), 'date') &&
        endDate.isSame(moment.tz(displayTimezone), 'date')
      ) {
        return DateFilterPeriodModes.LAST_7_DAYS;
      }
      if (
        startDate.isSame(moment.tz(displayTimezone).add(-30, 'day'), 'date') &&
        endDate.isSame(moment.tz(displayTimezone), 'date')
      ) {
        return DateFilterPeriodModes.LAST_30_DAYS;
      }
    }
    return DateFilterPeriodModes.CUSTOM;
  };

  private _selectShortcut = (shortcut) => {
    // const { displayTimezone } = this.props;

    if (shortcut === DateFilterPeriodModes.ALL) {
      this.setState({ startDate: null, endDate: null, isCustom: false });
    } else if (shortcut === DateFilterPeriodModes.TODAY) {
      this.setState({
        startDate: moment().startOf('day'),
        endDate: moment().endOf('day')
      });
    } else if (shortcut === DateFilterPeriodModes.THIS_WEEK) {
      this.setState({
        startDate: moment().startOf('isoWeek'),
        endDate: moment().endOf('isoWeek')
      });
    } else if (shortcut === DateFilterPeriodModes.THIS_MONTH) {
      this.setState({
        startDate: moment().startOf('month'),
        endDate: moment().endOf('month')
      });
    } else if (shortcut === DateFilterPeriodModes.LAST_7_DAYS) {
      this.setState({
        startDate: moment()
          .startOf('day')
          .add(-7, 'day'),
        endDate: moment().endOf('day')
      });
    } else if (shortcut === DateFilterPeriodModes.LAST_30_DAYS) {
      this.setState({
        startDate: moment()
          .startOf('day')
          .add(-30, 'day'),
        endDate: moment().endOf('day')
      });
    } else if (shortcut === DateFilterPeriodModes.CUSTOM) {
      this.setState({ startDate: null, endDate: null, isCustom: true });
    }
  };

  render() {
    const { startDate, endDate } = this.state;
    const filterSettings = CommonUtils.getFilterSettings(this.props.filter.filter);

    const selectedOption = this._getSelectedOption();

    return (
      <div>
        <div className={'bordered-bottom flex-row'}>
          <div style={{ width: '130px' }} className={'pv-small ph-medium'}>
            <Text size={'x-large'} weight={'bold'}>
              {filterSettings.title}
            </Text>
          </div>
          <div className={'pv-small'}>
            {startDate ? (
              !endDate || endDate.isSame(startDate, 'date') ? (
                <>
                  <Text color={'secondary'}>On </Text>
                  <b>{startDate.format('D MMM YYYY')}</b>
                </>
              ) : (
                <>
                  <Text color={'secondary'}>From </Text>
                  <b>{startDate.format('D MMM YYYY')}</b>
                  <Text color={'secondary'}> to </Text>
                  <b>{endDate.format('D MMM YYYY')}</b>
                </>
              )
            ) : (
              <Text color={'secondary'}>All dates selected</Text>
            )}
          </div>
        </div>
        <div className={'flex-row bordered-bottom border-standard-gray'}>
          <div style={{ width: '130px' }} className={'bordered-right border-standard-gray'}>
            <Row>
              <Col
                className={`${
                  selectedOption === DateFilterPeriodModes.ALL
                    ? 'text-color-blue-action text-weight-bold bg-blue-lightest'
                    : ''
                } pv-x-small hover-bg-tertiary cursor-pointer ph-small`}
                onClick={() => this._selectShortcut(DateFilterPeriodModes.ALL)}
              >
                All
              </Col>
              <Col
                className={`${
                  selectedOption === DateFilterPeriodModes.TODAY
                    ? 'text-color-blue-action text-weight-bold bg-blue-lightest'
                    : ''
                } pv-x-small hover-bg-tertiary cursor-pointer ph-small`}
                onClick={() => this._selectShortcut(DateFilterPeriodModes.TODAY)}
              >
                Today
              </Col>
              <Col
                className={`${
                  selectedOption === DateFilterPeriodModes.THIS_WEEK
                    ? 'text-color-blue-action text-weight-bold bg-blue-lightest'
                    : ''
                } pv-x-small hover-bg-tertiary cursor-pointer ph-small`}
                onClick={() => this._selectShortcut(DateFilterPeriodModes.THIS_WEEK)}
              >
                This week
              </Col>
              <Col
                className={`${
                  selectedOption === DateFilterPeriodModes.THIS_MONTH
                    ? 'text-color-blue-action text-weight-bold bg-blue-lightest'
                    : ''
                } pv-x-small hover-bg-tertiary cursor-pointer ph-small`}
                onClick={() => this._selectShortcut(DateFilterPeriodModes.THIS_MONTH)}
              >
                This month
              </Col>
              <Col
                className={`${
                  selectedOption === DateFilterPeriodModes.LAST_7_DAYS
                    ? 'text-color-blue-action text-weight-bold bg-blue-lightest'
                    : ''
                } pv-x-small hover-bg-tertiary cursor-pointer ph-small`}
                onClick={() => this._selectShortcut(DateFilterPeriodModes.LAST_7_DAYS)}
              >
                Last 7 days
              </Col>
              <Col
                className={`${
                  selectedOption === DateFilterPeriodModes.LAST_30_DAYS
                    ? 'text-color-blue-action text-weight-bold bg-blue-lightest'
                    : ''
                } pv-x-small hover-bg-tertiary cursor-pointer ph-small`}
                onClick={() => this._selectShortcut(DateFilterPeriodModes.LAST_30_DAYS)}
              >
                Last 30 days
              </Col>
              <Col
                className={`${
                  selectedOption === DateFilterPeriodModes.CUSTOM
                    ? 'text-color-blue-action text-weight-bold bg-blue-lightest'
                    : ''
                } pv-x-small hover-bg-tertiary cursor-pointer ph-small`}
                onClick={() => this._selectShortcut(DateFilterPeriodModes.CUSTOM)}
              >
                Custom
              </Col>
            </Row>
          </div>
          <div>
            <DateRangePicker
              shortcuts={false}
              value={[
                this.state.startDate ? this.state.startDate.toDate() : null,
                this.state.endDate ? this.state.endDate.toDate() : null
              ]}
              onChange={this._addSelection}
              allowSingleDayRange={true}
              maxDate={moment
                .tz(this.props.displayTimezone)
                .add(10, 'year')
                .toDate()}
              contiguousCalendarMonths={false}
              className={'focus-none'}
            />
          </div>
        </div>
        <div className="p-medium">
          <div className={'flex-row justify-between'}>
            <div>
              {this.props.canRemove && (
                <GhostButton
                  color={'red'}
                  className={'bp3-popover-dismiss'}
                  onClick={() => this.props.removeFilter(this.props.filter.filter)}
                >
                  Remove filter
                </GhostButton>
              )}
            </div>
            <div>
              <SecondaryButton
                color={'blue-action'}
                className={'mr-medium bp3-popover-dismiss'}
                onClick={this._closePopover}
              >
                Cancel
              </SecondaryButton>
              <PrimaryButton
                color={'blue-action'}
                className={'bp3-popover-dismiss border-blue-action'}
                disabled={!this.state.startDate && this.state.endDate}
                onClick={this._applySelection}
              >
                Apply
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FilterMenuDateRange;
