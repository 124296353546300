/* eslint-disable */

import React, { Component } from 'react';
import { Row, Col, notification, Icon, Form, Divider, Input } from 'antd';
import { Title, FieldLabel, Paragraph, Text } from 'common-components/typography';
import { PrimaryButton, HyperlinkButton } from 'common-components/buttons';
import InviteConsumerDetailRow from './details/InviteConsumerDetailRow';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { IInviteConsumer } from 'src/interfaces/user-interfaces';
import FullScreenModal, { FullScreenModalFooter } from 'common-components/modal/FullScreenModal';
import { Spinner } from '@blueprintjs/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

interface IInviteConsumerViewProps extends FormComponentProps {
  doSendConsumerInvitation: typeof dispatch.customersStore.doSendConsumerInvitation;
  closeInviteConsumerModal: () => void;
  SentConsumerInvitations?: IInviteConsumer[];
  isOpen: boolean;
}

interface IInviteConsumerViewState {
  consumerInputs: any[];
  step: number;
  sentNoOfInvite: number;
  inviteConsumerList: any[];
  isLoading: boolean;
  additionalNote: string;
}

class InviteConsumerView extends Component<IInviteConsumerViewProps, IInviteConsumerViewState> {
  private consumerInputRefs: any[] = [];

  state = {
    consumerInputs: [{ firstName: '', lastName: '', phoneNumber: '', phoneNumberCountryCode: 'AU', email: '' }],
    step: 1,
    sentNoOfInvite: 0,
    inviteConsumerList: [],
    isLoading: false,
    additionalNote: ''
  };

  private _handleInputClick = (event, key) => {
    this.state.consumerInputs[key][event.target.name] = event.target.value;
  };

  private _onCloseModel = () => {
    const { closeInviteConsumerModal } = this.props;
    this.setState({
      consumerInputs: [{ firstName: '', lastName: '', phoneNumber: '', phoneNumberCountryCode: 'AU', email: '' }],
      step: 1,
      sentNoOfInvite: 0,
      inviteConsumerList: [],
      isLoading: false
    });
    closeInviteConsumerModal();
  };

  private _addConsumerInputRow = () => {
    this.setState((prevState) => ({
      consumerInputs: [
        ...prevState.consumerInputs,
        { firstName: '', lastName: '', phoneNumber: '', phoneNumberCountryCode: 'AU', email: '' }
      ]
    }));
  };

  private _handleDropDownClick = (event, key, name) => {
    this.state.consumerInputs[key][name] = event;
  };

  private _createConsumerInputRow = () => {
    return this.state.consumerInputs.map((el, i) => (
      <InviteConsumerDetailRow
        rowKey={i}
        key={i}
        wrappedComponentRef={this._setRef}
        consumerInputItem={el}
        handleDelete={this._handleDelete}
        handleInputClick={() => {
          this._handleInputClick(event, i);
        }}
        handleDropDownClick={this._handleDropDownClick}
      />
    ));
  };

  private _handleDelete = (rowID) => {
    const existingconsumerInputs = [...this.state.consumerInputs];

    _.remove(existingconsumerInputs, (item, index) => {
      return index === rowID;
    });

    this.setState({
      consumerInputs: existingconsumerInputs
    });
  };

  private _onClickSubmit = async () => {
    //validate and save
    const { doSendConsumerInvitation } = this.props;
    let isFormValid = true;

    if (this.consumerInputRefs.length > 0) {
      _.forEach(this.consumerInputRefs, (consumerInputRef) => {
        consumerInputRef.props.form.validateFields((err) => {
          if (err) {
            isFormValid = false;
          }
        });
      });
    }
    if (isFormValid && this.state.consumerInputs.length > 0) {
      try {
        this.setState({ isLoading: true, step: 2 });

        const payload = {
          list: this.state.consumerInputs,
          message: this.state.additionalNote
        };

        await doSendConsumerInvitation(payload);

        const SentConsumerInvitations = this.props.SentConsumerInvitations;
        this.setState({
          consumerInputs: [{ firstName: '', lastName: '', phoneNumber: '', phoneNumberCountryCode: '', email: '' }],
          sentNoOfInvite: SentConsumerInvitations.length,
          step: 3,
          inviteConsumerList: SentConsumerInvitations,
          isLoading: false
        });
      } catch (error) {
        this.setState({ isLoading: false, step: 1 });
        notification.error({ description: error.meta.message, message: error.message });
      }
    } else {
      notification.error({ message: 'Validate', description: 'At least one invitation is required.' });
    }
  };

  private _handleAdditionalNote = (event) => {
    this.setState({ additionalNote: event.target.value });
  };

  private _setRef = (ref) => {
    if (ref) {
      this.consumerInputRefs.push(ref);
    }
  };

  renderStep = () => {
    const { step } = this.state;

    if (step == 1) {
      return (
        <div>
          <Title level={2} weight="regular">
            Invite new <b>Consumers to GoodHuman</b>
          </Title>
          <Paragraph className="mb-x-large">
            Portal users are users who can access this company & administrate various functions on this portal.
          </Paragraph>
          <div className="mb-medium">
            <Row type="flex" align="middle" gutter={16} className="mb-small">
              <Col span={5}>
                <FieldLabel text="First Name" />
              </Col>
              <Col span={5}>
                <FieldLabel text="Last Name" />
              </Col>
              <Col span={7}>
                <FieldLabel text="Mobile" />
              </Col>
              <Col span={7}>
                <FieldLabel text="Email" />
              </Col>
            </Row>

            {/* inputs of invite consumers */}
            {this._createConsumerInputRow()}
          </div>
          <div className="mb-x-large">
            <HyperlinkButton onClick={this._addConsumerInputRow}>
              <Icon type="plus-circle" className="mr-small" /> Add more
            </HyperlinkButton>
          </div>

          <div className="mb-x-large">
            <Paragraph color="secondary" size="regular">
              Customers will receive an email inviting them to download the GoodHuman app (IOS/Android) and connect to
              and consumer your services.
            </Paragraph>
            <Input.TextArea
              rows={3}
              placeholder="Enter your custom invite message here"
              name="additionalNote"
              value={this.state.additionalNote}
              onChange={this._handleAdditionalNote}
            />
          </div>

          <div className="flex-row justify-end width-full">
            <PrimaryButton
              size="large"
              icon="mail"
              iconPosition="right"
              onClick={this._onClickSubmit}
              loading={this.state.isLoading}
            >
              Send Invite(s)
            </PrimaryButton>
          </div>
        </div>
      );
    } else if (step === 2) {
      return (
        <div>
          <Title level={2} weight="regular">
            Invite new <b>Consumers to GoodHuman</b>
          </Title>
          <div className="mt-x-large">
            <div className="flex-row align-center mb-x-large">
              <div className="mr-x-large">
                <Spinner size={120} />
              </div>
              <div>
                <Paragraph>Loading.......</Paragraph>
                <Paragraph weight="bold">Adding Customer.</Paragraph>
                <Paragraph>This won't take long.</Paragraph>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (step == 3) {
      const inviteConsumerList = this.state.inviteConsumerList;
      return (
        <div>
          <Row type={'flex'} justify={'center'}>
            <Col>
              <Title level={2} weight="bolder">
                You have invited
              </Title>
            </Col>
          </Row>
          <Row type={'flex'} justify={'center'}>
            <Col>
              <Paragraph>
                <span className="text-weight-bold">{this.state.sentNoOfInvite} new users</span> to Good Human
              </Paragraph>
            </Col>
          </Row>
          <div className="mt-large">
            <Row type={'flex'} justify={'center'}>
              <Col span={6}>
                <FieldLabel text="Mobile" />
              </Col>
              <Col span={6}>
                <FieldLabel text="Name" />
              </Col>
            </Row>
            <Row type={'flex'} justify={'center'}>
              <Col span={12}>
                <Divider className={'divider-medium'} style={{ background: 'grey' }} />
              </Col>
            </Row>
            <div className={'mb-x2-large'}>
              {inviteConsumerList.map((inviteConsumer) => (
                <Row type={'flex'} justify={'center'}>
                  <Col span={6}>{parsePhoneNumberFromString(inviteConsumer.mobile, 'AU').number}</Col>
                  <Col span={6}>
                    {inviteConsumer.firstName} {inviteConsumer.lastName}
                  </Col>
                </Row>
              ))}
            </div>
            <Row type={'flex'} justify={'center'} className={'text-align-right'}>
              <Col span={12}>
                <PrimaryButton size="large" icon="check" iconPosition="right" onClick={this._onCloseModel}>
                  Done
                </PrimaryButton>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
  };

  render() {
    const { isOpen } = this.props;
    return (
      <FullScreenModal
        isOpen={isOpen}
        onClose={this._onCloseModel}
        width={'large'}
        footerContent={
          <FullScreenModalFooter>
            <Text>
              Hint : Press <code>Esc</code> to close this screen.
            </Text>
          </FullScreenModalFooter>
        }
        canClose={!this.state.isLoading}
      >
        <div style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '80vh' }}>{this.renderStep()}</div>
      </FullScreenModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doSendConsumerInvitation: dispatch.customersStore.doSendConsumerInvitation
});

const mapState = (state: IRootState) => ({
  SentConsumerInvitations: state.teamStore.SentConsumerInvitations
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IInviteConsumerViewProps>()(InviteConsumerView));
