import { Icon, Popover, Tabs } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import CustomViewsModal from 'common-components/custom-views/modals/CustomViewsModal';
import CustomViewPopover from 'common-components/custom-views/popover/CustomViewPopover';
import * as H from 'history';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ICustomView } from 'src/interfaces/custom-views-interface';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { CustomViewsModalType, FilterType, ServiceType } from 'utilities/enum-utils';
import { BookingViewTabPopover } from '../../BookingViews/components/BookingViewTabPopover';

const { TabPane } = Tabs;

interface IBooklistNavigationSectionProps {
  portalUser: typeof state.authStore.portalUser;
  showFilterSection: boolean;
  hasBookingListingFilterChanged: typeof state.bookingsStore.hasBookingListingFilterChanged;
  setHasBookingListingFilterChanged: typeof dispatch.bookingsStore.setHasBookingListingFilterChanged;
  setBookingListingActiveTab: typeof dispatch.bookingsStore.setBookingListingActiveTab;
  bookingListingActiveTab: typeof state.bookingsStore.bookingListingActiveTab;
  history: H.History;
  defaultBookingViews: typeof state.bookingsStore.bookingViews;
  filterCounters: typeof state.bookingsStore.filterCounters;
  displayedBookingListingTabs: typeof state.bookingsStore.displayedBookingListingTabs;
  bookingsFilter: typeof state.bookingsStore.bookingsFilter;
  setBookingsFilter: typeof dispatch.bookingsStore.setBookingsFilter;
  doUpdateBookingView: typeof dispatch.bookingsStore.doUpdateBookingView;
  doFetchBookings: typeof dispatch.bookingsStore.doFetchBookings;
  setBookingList: typeof dispatch.bookingsStore.setBookingList;
  doAddBookingView: typeof dispatch.bookingsStore.doAddBookingView;
  doDuplicateBookingView: typeof dispatch.bookingsStore.doDuplicateBookingView;
  doDeleteBookingView: typeof dispatch.bookingsStore.doDeleteBookingView;
  bookingViews: ICustomView[];
  setDisplayedBookingListingTabs: typeof dispatch.bookingsStore.setDisplayedBookingListingTabs;
  isBookingListingLoading: typeof state.bookingsStore.isBookingListingLoading;
}

interface IBooklistNavigationSectionStates {
  viewModalType: CustomViewsModalType;
  showBookingPopover: boolean;
  showBookingViewModal: boolean;
  showTabIconPopover: string;
  showBookingViewPopover: boolean;
}

const defaultFilterValue = [
  {
    [FilterType.DATE_RANGE]: [
      moment()
        .startOf('isoWeek')
        .toISOString(),
      moment()
        .endOf('isoWeek')
        .toISOString(),
    ],
  },
  { sort: [['startDateTime', 'asc']] },
  { [FilterType.CUSTOMER]: [] },
  { [FilterType.WORKER]: [] },
  { [FilterType.SHIFT_SLOT_STATUS]: [] },
  { [FilterType.SERVICE_TYPE]: [ServiceType.INDIVIDUAL] },
];

class BookListNavigationSection extends Component<IBooklistNavigationSectionProps, IBooklistNavigationSectionStates> {
  state = {
    viewModalType: CustomViewsModalType.CREATE,
    showBookingPopover: false,
    showBookingViewModal: false,
    showTabIconPopover: '',
    showBookingViewPopover: false,
  };

  private _changeTab = async (selectedTabId) => {
    const {
      setBookingListingActiveTab,
      setHasBookingListingFilterChanged,
      defaultBookingViews,
      displayedBookingListingTabs,
      setBookingsFilter,
    } = this.props;

    const activeTab = [...defaultBookingViews, ...displayedBookingListingTabs].find(
      (tab) => tab.customViewId === selectedTabId,
    );
    setBookingListingActiveTab(activeTab);
    setBookingsFilter(activeTab.filterValue);
    setHasBookingListingFilterChanged(false);
  };

  private _goBookingAllViews = () => {
    const { history } = this.props;
    history.push(`/bookings/all-views`);
  };

  private _openBookingViewModal = (type: CustomViewsModalType) => {
    this.setState({
      showBookingPopover: false,
      viewModalType: type,
      showBookingViewModal: true,
      showBookingViewPopover: false,
    });
  };

  private _closeBookingViewModal = () => {
    this.setState({ viewModalType: CustomViewsModalType.CREATE, showBookingViewModal: false });
  };
  private _toggleAddViewPopOverChange = (visible) => {
    this.setState({ showBookingViewPopover: visible });
  };
  private _renderExtraTabs = () => {
    const {
      bookingViews,
      displayedBookingListingTabs,
      setDisplayedBookingListingTabs,
      setBookingListingActiveTab,
    } = this.props;
    return (
      <>
        <Popover
          content={
            <CustomViewPopover
              pageViews={bookingViews}
              displayedPageListingTabs={displayedBookingListingTabs}
              onCreateNewView={() => this._openBookingViewModal(CustomViewsModalType.CREATE)}
              onDisplayTab={this._toggleAddViewPopOverChange}
              setDisplayedPageListingTabs={setDisplayedBookingListingTabs}
              setPageListingActiveTab={setBookingListingActiveTab}
            />
          }
          trigger="click"
          visible={this.state.showBookingViewPopover}
          onVisibleChange={this._toggleAddViewPopOverChange}
        >
          <HyperlinkButton className="ph-large">+ Add view</HyperlinkButton>
        </Popover>
        <HyperlinkButton className="ph-large" onClick={this._goBookingAllViews}>
          All views
        </HyperlinkButton>
      </>
    );
  };

  private _renderViewTabIcon = (tab: ICustomView) => {
    const togglePopOver = (value) => {
      const showTabIconPopover = value ? tab.customViewId : '';
      this.setState({ showTabIconPopover });
    };
    return (
      <Popover
        content={
          <BookingViewTabPopover
            onHidePopOver={togglePopOver}
            currentTab={tab}
            onShowBookingViewModal={this._openBookingViewModal}
            doUpdateBookingViewTab={this.props.doUpdateBookingView}
          />
        }
        visible={this.state.showTabIconPopover === tab.customViewId}
        onVisibleChange={togglePopOver}
        placement="bottom"
        trigger="click"
      >
        <Icon className="ml-small mr-none" type="caret-down" />
      </Popover>
    );
  };

  render() {
    const {
      bookingListingActiveTab,
      showFilterSection,
      defaultBookingViews,
      displayedBookingListingTabs,
      bookingsFilter,
      setBookingsFilter,
      doUpdateBookingView,
      doAddBookingView,
      doDeleteBookingView,
      doDuplicateBookingView,
      bookingViews,
      filterCounters,
      isBookingListingLoading,
    } = this.props;

    const renderExtraTabs = this._renderExtraTabs();
    const generateActiveTabClassName = (activeTab, tab) =>
      _.get(activeTab, 'customViewId') === tab.customViewId ? `text-color-blue-action` : 'text-color-secondary';

    const getLoadingIcon = (activeTab, tab) => {
      if (_.get(activeTab, 'customViewId') === tab.customViewId && isBookingListingLoading) {
        return <Icon type="loading" className="ml-x-small mr-none" />;
      } else {
        return <Icon type="loading" className="ml-x-small mr-none" style={{ visibility: 'hidden' }} />;
      }
    };

    return (
      <div>
        {showFilterSection && (
          <div className="bg-white width-full mt-large">
            <CustomViewsModal
              isOpen={this.state.showBookingViewModal}
              onCloseViewModal={this._closeBookingViewModal}
              onUpdateViewModal={this._openBookingViewModal}
              type={this.state.viewModalType}
              pageViews={bookingViews}
              pageFilter={bookingsFilter}
              pageListingActiveTab={bookingListingActiveTab}
              setPageFilter={setBookingsFilter}
              doAddView={doAddBookingView}
              doDeleteView={doDeleteBookingView}
              doUpdateViewTab={doUpdateBookingView}
              doDuplicateView={doDuplicateBookingView}
              defaultFilterValue={defaultFilterValue}
              defaultViews={defaultBookingViews}
            />
            <Tabs
              type="card"
              tabBarExtraContent={renderExtraTabs}
              defaultActiveKey="ALL"
              activeKey={_.get(bookingListingActiveTab, 'customViewId')}
              animated={true}
              onChange={this._changeTab}
            >
              {defaultBookingViews.map((tab) => {
                const counter = _.find(filterCounters, (counter) => counter.filterName === tab.customViewId);
                return (
                  <TabPane
                    disabled={isBookingListingLoading}
                    tab={
                      <span className={generateActiveTabClassName(bookingListingActiveTab, tab)}>
                        {tab.name} {counter !== undefined && `(${counter.count})`}
                        {getLoadingIcon(bookingListingActiveTab, tab)}
                      </span>
                    }
                    key={tab.customViewId}
                  />
                );
              })}

              {displayedBookingListingTabs.map((tab: ICustomView) => (
                <TabPane
                  disabled={isBookingListingLoading}
                  tab={
                    <span className={generateActiveTabClassName(bookingListingActiveTab, tab)}>
                      {tab.isPinned && <Icon type="pushpin" theme="filled" className="mr-small" />}
                      {tab.name}
                      {this._renderViewTabIcon(tab)}
                      {getLoadingIcon(bookingListingActiveTab, tab)}
                    </span>
                  }
                  key={tab.customViewId}
                />
              ))}
            </Tabs>
          </div>
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser,
  hasBookingListingFilterChanged: state.bookingsStore.hasBookingListingFilterChanged,
  bookingListingActiveTab: state.bookingsStore.bookingListingActiveTab,
  defaultBookingViews: state.bookingsStore.defaultBookingViews,
  displayedBookingListingTabs: state.bookingsStore.displayedBookingListingTabs,
  bookingsFilter: state.bookingsStore.bookingsFilter,
  bookingViews: state.bookingsStore.bookingViews,
  filterCounters: state.bookingsStore.filterCounters,
  isBookingListingLoading: state.bookingsStore.isBookingListingLoading,
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  setHasBookingListingFilterChanged: dispatch.bookingsStore.setHasBookingListingFilterChanged,
  setBookingListingActiveTab: dispatch.bookingsStore.setBookingListingActiveTab,
  setBookingsFilter: dispatch.bookingsStore.setBookingsFilter,
  doAddBookingView: dispatch.bookingsStore.doAddBookingView,
  doDuplicateBookingView: dispatch.bookingsStore.doDuplicateBookingView,
  doDeleteBookingView: dispatch.bookingsStore.doDeleteBookingView,
  doUpdateBookingView: dispatch.bookingsStore.doUpdateBookingView,
  setBookingList: dispatch.bookingsStore.setBookingList,
  doFetchBookings: dispatch.bookingsStore.doFetchBookings,
  setDisplayedBookingListingTabs: dispatch.bookingsStore.setDisplayedBookingListingTabs,
});

export default connect(
  mapState,
  mapDispatch,
)(BookListNavigationSection);
