import React, { Component } from 'react';
import moment from 'moment';
import { Col, DatePicker, Row, Select } from 'antd';
import { BookingStatus } from 'utilities/enum-utils';
import { SubTitle } from 'common-components/typography';

const Option = Select.Option;

const statusOptions = [
  { label: 'All Status', val: '' },
  { label: BookingStatus.PENDING, val: BookingStatus.PENDING },
  { label: BookingStatus.ACCEPTED, val: BookingStatus.ACCEPTED },
  { label: BookingStatus.CONFIRMED, val: BookingStatus.CONFIRMED },
  { label: BookingStatus.INPROGRESS, val: BookingStatus.INPROGRESS },
  { label: BookingStatus.COMPLETED, val: BookingStatus.COMPLETED },
  { label: BookingStatus.REJECTED, val: BookingStatus.REJECTED },
  { label: BookingStatus.CUSTOMER_CANCELLED, val: BookingStatus.CUSTOMER_CANCELLED },
  { label: BookingStatus.CUSTOMER_CANCELLED_WITH_FEE, val: BookingStatus.CUSTOMER_CANCELLED_WITH_FEE },
  { label: BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE, val: BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE },
  { label: BookingStatus.BUSINESS_CANCELLED, val: BookingStatus.BUSINESS_CANCELLED }
];

interface ICustomerBookingListFilterPanelProps {
  filters: any;
  handleStatus: (status: string) => void;
  handleStartDate: (startDate: any) => void;
  handleEndDate: (endDate: any) => void;
}

interface ICustomerBookingListFilterPanelState {
  bookingStatus: string;
  searchName: string;
  startDate: any;
  endDate: any;
}

class CustomerBookingListFilterPanel extends Component<
  ICustomerBookingListFilterPanelProps,
  ICustomerBookingListFilterPanelState
> {
  state = { bookingStatus: '', searchName: '', startDate: '', endDate: '' };

  private _handleStatus = (status) => {
    this.props.handleStatus(status);
    this.setState({ bookingStatus: status });
  };

  private _handleStartDate = (startDate) => {
    this.setState({ startDate: moment(startDate) });
    this.props.handleStartDate(startDate);
  };

  private _handleEndDate = (endDate) => {
    this.setState({ endDate: moment(endDate) });
    this.props.handleEndDate(endDate);
  };

  render() {
    const { filters } = this.props;

    return (
      <Row type={'flex'} gutter={30}>
        <Col span={4}>
          <Select
            className="bg-tertiary focus-bg-white width-full"
            onChange={this._handleStatus}
            placeholder={'Select Status'}
            value={filters.status}
          >
            {statusOptions.map((statusOption) => (
              <Option value={statusOption.val}>{statusOption.label}</Option>
            ))}
          </Select>
        </Col>
        <Col span={8}>
          <Row type={'flex'}>
            <Col span={4} className={'mt-small'}>
              <SubTitle>From</SubTitle>
            </Col>
            <Col>
              <DatePicker
                size="large"
                placeholder={'Start Date'}
                allowClear={true}
                format="DD/MM/YYYY"
                className={'width-full'}
                name={'startDate'}
                value={filters.startDate}
                onChange={(event) => this._handleStartDate(event)}
                disabledDate={(current) => {
                  if (filters.endDate !== null && filters.endDate !== '' && current > moment()) {
                    return current.valueOf() >= filters.endDate.valueOf();
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row type={'flex'}>
            <Col span={3} className={'mt-small'}>
              <SubTitle>To</SubTitle>
            </Col>
            <Col>
              <DatePicker
                size="large"
                placeholder={'End Date'}
                allowClear={true}
                format="DD/MM/YYYY"
                className={'width-full'}
                name={'endDate'}
                value={filters.endDate}
                onChange={(event) => this._handleEndDate(event)}
                disabledDate={(current) => {
                  if (filters.startDate !== null && filters.startDate !== '') {
                    return current.valueOf() < filters.startDate.valueOf();
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default CustomerBookingListFilterPanel;
