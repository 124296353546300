import React, { Component } from 'react';
import { ServDetailsSectionHeader } from '../common/ServDetailsSectionHeader';

import { Paragraph, Text } from 'common-components/typography';
import { Button, Col, DatePicker, Divider, Icon, notification, Row, Switch, Tooltip } from 'antd';
import { ServDetailsContentRow } from '../common/ServDetailsContentRow';

import moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import _ from 'lodash';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import ServiceTimeRecurrenceItem from 'views/services/listing/components/ServiceTimeRecurrenceItem';
import { ServiceRecurringType, ServiceType } from 'utilities/enum-utils';
import TimeInput from 'common-components/time-input/TimeInput';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import ServiceAvailableDayItem from 'views/services/serviceAvailableDayItem';
import CommonUtils from 'utilities/common-utils';
import { TimezoneIndicator, TimezoneInput } from 'common-components/timezone';
import { timeZone } from 'interfaces/timezone-type';

// @ts-ignore
const extMoment = extendMoment(moment);

// Translate tag into dayNumber
const dayOfWeekList = [
  { tag: 'MON', value: 1 },
  { tag: 'TUE', value: 2 },
  { tag: 'WED', value: 3 },
  { tag: 'THU', value: 4 },
  { tag: 'FRI', value: 5 },
  { tag: 'SAT', value: 6 },
  { tag: 'SUN', value: 7 }
];

class AvailableDatesContent extends Component<{
  availableFrom: any;
  availableTo: any;
  onChangeAvailableFrom: any;
  onChangeAvailableTo: any;
  isRecurring: boolean;
  timezone: timeZone;
}> {
  render() {
    const {
      availableFrom,
      availableTo,
      onChangeAvailableTo,
      onChangeAvailableFrom,
      isRecurring,
      timezone
    } = this.props;

    return (
      <ServDetailsContentRow
        label={'Service Availability Dates'}
        subLabel={'From what date will this service be available?'}
      >
        <Row gutter={24}>
          <Col span={6}>
            <div className="mb-x-small">
              <Text className="text-size-small">
                <b>Start Date</b>
              </Text>
            </div>

            <DatePicker
              format="DD-MM-YYYY"
              defaultValue={moment.tz(availableFrom, timezone)}
              onChange={onChangeAvailableFrom}
              disabledDate={(current) => {
                return current < moment().startOf('day');
              }}
            />
          </Col>
          <Col span={6}>
            <div className="mb-x-small">
              <Text className="text-size-small">
                <b>End Date</b>
              </Text>
            </div>
            <DatePicker
              format="DD-MM-YYYY"
              defaultValue={moment.tz(availableTo, timezone)}
              onChange={onChangeAvailableTo}
              disabledDate={(current) => {
                return isRecurring
                  ? current < moment.tz(availableFrom, timezone).endOf('day')
                  : current < moment.tz(availableFrom, timezone).startOf('day');
              }}
            />
          </Col>
        </Row>
      </ServDetailsContentRow>
    );
  }
}

export class ServiceAvailabilitiesTab extends Component<any, any> {
  state = {
    isEdit: false,
    isLoading: false,
    isProceedModalOpen: false,
    availableFrom: moment.tz(new Date(), this.props.selectedService.timezone).startOf('day'),
    availableTo: moment
      .tz(new Date(), this.props.selectedService.timezon)
      .add(12, 'months')
      .startOf('day'),
    timezone: null,
    dataArray: [
      {
        dayOfWeek: 'MON',
        dayNumber: 1,
        isAllDay: false,
        startDateTime: moment(9, 'hh').isoWeekday(1),
        endDateTime: moment(17, 'hh').isoWeekday(1),
        selected: false,
        isValidate: true,
        billableHours: 8,
        day: 'Monday'
      },
      {
        dayOfWeek: 'TUE',
        dayNumber: 2,
        isAllDay: false,
        startDateTime: moment(9, 'hh').isoWeekday(2),
        endDateTime: moment(17, 'hh').isoWeekday(2),
        selected: false,
        isValidate: true,
        billableHours: 8,
        day: 'Tuesday'
      },
      {
        dayOfWeek: 'WED',
        dayNumber: 3,
        isAllDay: false,
        startDateTime: moment(9, 'hh').isoWeekday(3),
        endDateTime: moment(17, 'hh').isoWeekday(3),
        selected: false,
        isValidate: true,
        billableHours: 8,
        day: 'Wednesday'
      },
      {
        dayOfWeek: 'THU',
        dayNumber: 4,
        isAllDay: false,
        startDateTime: moment(9, 'hh').isoWeekday(4),
        endDateTime: moment(17, 'hh').isoWeekday(4),
        selected: false,
        isValidate: true,
        billableHours: 8,
        day: 'Thursday'
      },
      {
        dayOfWeek: 'FRI',
        dayNumber: 5,
        isAllDay: false,
        startDateTime: moment(9, 'hh').isoWeekday(5),
        endDateTime: moment(17, 'hh').isoWeekday(5),
        selected: false,
        isValidate: true,
        billableHours: 8,
        day: 'Friday'
      },
      {
        dayOfWeek: 'SAT',
        dayNumber: 6,
        isAllDay: false,
        startDateTime: moment(9, 'hh').isoWeekday(6),
        endDateTime: moment(17, 'hh').isoWeekday(6),
        selected: false,
        isValidate: true,
        billableHours: 8,
        day: 'Saturday'
      },
      {
        dayOfWeek: 'SUN',
        dayNumber: 7,
        isAllDay: false,
        startDateTime: moment(9, 'hh').isoWeekday(7),
        endDateTime: moment(17, 'hh').isoWeekday(7),
        selected: false,
        isValidate: true,
        billableHours: 8,
        day: 'Sunday'
      }
    ],
    timeAvailabilities: this.props.selectedService.timeAvailabilities
      ? this.props.selectedService.timeAvailabilities
      : [],
    isFixedTime: true,
    minBookingMinutes: 60,
    maxBookingMinutes: 180,
    gapMinutes: 0
  };

  private _changeView = () => {
    this.setState({ isEdit: !this.state.isEdit, isProceedModalOpen: false });
  };

  private _closeProceedModal = () => {
    this.setState({ isProceedModalOpen: false });
  };

  private _discardChanges = () => {
    const { selectedService } = this.props;

    const hasUnsavedData =
      (selectedService.recurringType !== ServiceRecurringType.ONEOFF &&
        !_.isEqual(this.state.dataArray, selectedService.serviceConfig.dataArray)) ||
      !moment(this.state.availableFrom).isSame(moment(selectedService.availableFrom)) ||
      !moment(this.state.availableTo).isSame(moment(selectedService.availableTo));

    if (hasUnsavedData) {
      this.setState({ isProceedModalOpen: true });
    } else {
      this._changeView();
    }
  };

  private _formatData = () => {
    const { selectedService } = this.props;

    let originSelectedService = _.clone(selectedService);

    let availableFrom = originSelectedService.availableFrom;
    let availableTo = originSelectedService.availableTo;
    let isFixedTime = this.state.isFixedTime;
    let minBookingMinutes = this.state.minBookingMinutes;
    let maxBookingMinutes = this.state.maxBookingMinutes;
    let gapMinutes = this.state.gapMinutes;
    let dataArray = this.state.dataArray;
    let timezone = originSelectedService.timezone;

    if (!_.isEmpty(originSelectedService.serviceConfig)) {
      const config = originSelectedService.serviceConfig;
      if (!_.isEmpty(config.isFixedTime)) {
        isFixedTime = config.isFixedTime;
      }
      if (config.minBookingMinutes) {
        minBookingMinutes = config.minBookingMinutes;
      }

      maxBookingMinutes = config.maxBookingMinutes;

      if (config.gapMinutes) {
        gapMinutes = config.gapMinutes;
      }
      if (!_.isEmpty(config.dataArray)) {
        dataArray = config.dataArray;
        dataArray = _.map(dataArray, (item) => {
          item.startDateTime = moment.tz(item.startDateTime, selectedService.timezone);
          item.endDateTime = moment.tz(item.endDateTime, selectedService.timezone);
          // This line help change the structure of old recording ( In the old version dayOfWeek was a number when the API needs a string for timeAvailabilities
          if (Number(item.dayOfWeek))
            item.dayOfWeek = _.find(dayOfWeekList, (day) => Number(item.dayOfWeek) === day.value).tag;
          return item;
        });
      }
    } else {
      if (!_.isEmpty(originSelectedService.availableFrom)) {
        availableFrom = moment(originSelectedService.availableFrom);
        if (!_.isEmpty(originSelectedService.availableTo)) {
          availableTo = moment(originSelectedService.availableTo);
        } else {
          availableTo = null;
        }
      }
      if (originSelectedService.minBookingMinutes) {
        minBookingMinutes = originSelectedService.minBookingMinutes;
      }
      if (originSelectedService.maxBookingMinutes) {
        maxBookingMinutes = originSelectedService.maxBookingMinutes;
      }
      if (originSelectedService.gapMinutes) {
        gapMinutes = originSelectedService.gapMinutes;
      }
      if (!_.isEmpty(originSelectedService.bookingTimeType)) {
        isFixedTime = originSelectedService.bookingTimeType === 'FIXEDTIME';
      }
    }

    this.setState({
      availableFrom,
      availableTo,
      isFixedTime,
      minBookingMinutes,
      maxBookingMinutes,
      gapMinutes,
      dataArray,
      timezone
    });
  };

  private _roundDate(date) {
    return moment(CommonUtils.formatCeilingDateTime(date));
  }

  private _onChangeOneOffStartingDate = (startingDate) => {
    const roundedValue = this._roundDate(moment(startingDate));
    let newEndingDate = moment(this.state.availableTo);
    if (newEndingDate < roundedValue) {
      newEndingDate = moment(roundedValue).add(1, 'hour');
    }
    this.setState({ availableFrom: moment(roundedValue), availableTo: newEndingDate });
  };

  private _onChangeOneOffEndingDate = (endingDate) => {
    const roundedValue = this._roundDate(moment(endingDate));
    this.setState({ availableTo: roundedValue });
  };

  private _onChangeOneOffEndingTime = (endingTime) => {
    const { availableFrom, availableTo } = this.state;
    const roundedValue = this._roundDate(moment(endingTime));
    let newEndingDate = moment(availableTo);
    if (roundedValue > moment(availableFrom)) {
      newEndingDate = moment(roundedValue);
    }
    this.setState({ availableTo: newEndingDate });
  };

  private _eventHandler = (type, dayOfWeek, value = '') => {
    let newDataArray = [];

    if (type === 'checkbox') {
      newDataArray = this.state.dataArray.map((time) => {
        if (time.dayOfWeek === dayOfWeek) {
          // time.selected = !time.selected;
          return { ...time, selected: !time.selected };
        } else {
          return { ...time };
        }
      });
    }

    if (type === 'startDate') {
      newDataArray = this.state.dataArray.map((time) => {
        let startDateTime = time.startDateTime;
        let endDateTime = time.endDateTime;
        let billableHours = time.billableHours;
        if (time.dayOfWeek === dayOfWeek) {
          const roundedValue = this._roundDate(moment(value));
          if (moment(roundedValue).hours() >= 23 && moment(roundedValue).minutes() >= 55) {
            startDateTime = moment(roundedValue).set({ hours: 23, minutes: 55, seconds: 0 });
          } else {
            startDateTime = roundedValue;
          }
          if (startDateTime >= endDateTime) {
            let newEndingTime = moment(roundedValue).add(1, 'hour');
            if (
              !moment(startDateTime)
                .startOf('day')
                .isSame(moment(newEndingTime).startOf('day'))
            ) {
              newEndingTime = moment(roundedValue).set({ hours: 24, minutes: 0, seconds: 0 });
            }
            endDateTime = newEndingTime;
          }
          billableHours = moment.duration(endDateTime.diff(startDateTime)).asHours();

          return { ...time, startDateTime, endDateTime, billableHours };
        } else {
          return { ...time };
        }
      });
    }

    if (type === 'endDate') {
      newDataArray = this.state.dataArray.map((time) => {
        if (time.dayOfWeek === dayOfWeek) {
          let billableHours = time.billableHours;
          let endDateTime = time.endDateTime;
          if (moment(value) > time.startDateTime) {
            endDateTime = this._roundDate(moment(value));
            billableHours = moment.duration(endDateTime.diff(time.startDateTime)).asHours();
          }
          return { ...time, endDateTime, billableHours };
        } else {
          return { ...time };
        }
      });
    }

    if (type === 'billableHours') {
      newDataArray = this.state.dataArray.map((time) => {
        if (time.dayOfWeek === dayOfWeek) {
          let billableHours = Number(value);
          return { ...time, billableHours };
        } else {
          return { ...time };
        }
      });
    }

    this.setState({
      dataArray: newDataArray,
      timeAvailabilities: this._getNewTimeAvailabilityArray(
        newDataArray,
        this.state.availableFrom,
        this.state.availableTo
      )
    });
  };

  componentDidMount() {
    this._formatData();
  }

  private _onSubmitData = async () => {
    this.setState({ isLoading: true });
    const { doUpdateIndividualServiceTime, selectedService } = this.props;
    const { timezone } = this.state;

    let dataArray = null;
    let availableFrom =
      selectedService.recurringType !== ServiceRecurringType.ONEOFF
        ? moment.tz(
            moment(this.state.availableFrom)
              .startOf('day')
              .format('YYYY-MM-DD HH:mm'),
            timezone
          )
        : moment.tz(moment(this.state.availableFrom).format('YYYY-MM-DD HH:mm'), timezone);
    let availableTo =
      selectedService.recurringType !== ServiceRecurringType.ONEOFF
        ? moment.tz(
            moment(this.state.availableTo)
              .endOf('day')
              .format('YYYY-MM-DD HH:mm'),
            timezone
          )
        : moment.tz(moment(this.state.availableTo).format('YYYY-MM-DD HH:mm'), timezone);

    //Recreate dataArray with the timezone
    if (this.state.dataArray) {
      dataArray = _.map(this.state.dataArray, (data) => {
        return {
          ...data,
          startDateTime: moment.tz(moment(data.startDateTime).format('YYYY-MM-DD HH:mm'), timezone),
          endDateTime: moment.tz(moment(data.endDateTime).format('YYYY-MM-DD HH:mm'), timezone)
        };
      });
    }

    const timeAvailabilities: any =
      selectedService.recurringType !== ServiceRecurringType.ONEOFF
        ? await this._getNewTimeAvailabilityArray(
            this.state.dataArray,
            this.state.availableFrom,
            this.state.availableTo
          )
        : [
            {
              startDateTime: this.state.availableFrom,
              endDateTime: this.state.availableTo,
              isAllDay: false,
              dayOfWeek: _.find(dayOfWeekList, (day) => moment(this.state.availableFrom).isoWeekday() === day.value)
                .tag,
              billableHour: moment.duration(moment(this.state.availableTo).diff(this.state.availableFrom)).asHours()
            }
          ];

    const payload = {
      minBookingMinutes: this.state.minBookingMinutes,
      gapMinutes: this.state.gapMinutes,
      maxBookingMinutes: this.state.maxBookingMinutes,
      servicePublishDate: selectedService.servicePublishDate,
      availableFrom,
      availableTo,
      timeAvailabilities: _.map(timeAvailabilities, (availability) => {
        return {
          ...availability,
          startDateTime: moment
            .tz(moment(availability.startDateTime).format('YYYY-MM-DD HH:mm'), timezone)
            .toISOString(),
          endDateTime: moment.tz(moment(availability.endDateTime).format('YYYY-MM-DD HH:mm'), timezone).toISOString()
        };
      }),
      timezone,
      serviceConfig: {
        availableTo,
        availableFrom,
        maxBookingMinutes: this.state.maxBookingMinutes,
        minBookingMinutes: this.state.minBookingMinutes,
        gapMinutes: this.state.gapMinutes,
        dataArray,
        isFixedTime: this.state.isFixedTime
      }
    };

    try {
      await doUpdateIndividualServiceTime(payload);
      notification.success({
        message: 'Service time availabilities updated'
        // description: 'Service time availability updated successfully.'
      });
      this.setState({ isLoading: false, isEdit: false });
    } catch (e) {
      notification.error({
        message: 'Service time availabilities failed',
        description: 'Please try again.'
      });
      this.setState({ isLoading: false });
      console.log(e);
      throw e;
    }
  };

  private _getNewTimeAvailabilityArray = (dataArray, availableFrom, availableTo) => {
    const list = extMoment.range(availableFrom, availableTo);
    const arrayList = Array.from(list.by('day'));

    const newTimeAvailabilities = _.map(dataArray, (selection) => {
      if (selection.selected) {
        const dayNumber = _.find(dayOfWeekList, (day) => selection.dayOfWeek === day.tag).value;
        return _.chain(arrayList)
          .filter((date) => date.isoWeekday() === dayNumber)
          .map((date) => {
            if (selection.isAllDay) {
              const startDateTime = date.startOf('day').toDate();
              const endDateTime = date.endOf('day').toDate();
              return {
                startDateTime,
                endDateTime,
                isAllDay: true,
                dayOfWeek: selection.dayOfWeek,
                billableHour: 24
              };
            } else {
              const startDateTime = moment(
                date.format('YYYY-MM-DD') + ' ' + selection.startDateTime.format('HH:mm')
              ).toDate();
              const endDateTime = moment(
                date.format('YYYY-MM-DD') + ' ' + selection.endDateTime.format('HH:mm')
              ).toDate();
              return {
                startDateTime,
                endDateTime,
                isAllDay: false,
                dayOfWeek: selection.dayOfWeek,
                billableHour:
                  this.props.selectedService.serviceType === ServiceType.GROUP
                    ? selection.billableHours
                    : moment.duration(moment(selection.endDateTime).diff(moment(selection.startDateTime))).asHours()
              };
            }
          })
          .value();
      } else {
        return [];
      }
    });

    return _.flatMap(newTimeAvailabilities);
  };

  private _onUpdateSelect = (payload) => {
    const { dayOfWeek, selected } = payload;
    let oldArray = this.state.dataArray;
    let oldData = oldArray[_.findIndex(oldArray, (data) => data.dayOfWeek === dayOfWeek)];
    oldData.selected = selected;
    this._doUpdateAndValidate(oldData);
  };

  private _onUpdateIsAllDay = (payload) => {
    const { dayOfWeek, isAllDay } = payload;
    let oldArray = this.state.dataArray;
    let oldData = oldArray[_.findIndex(oldArray, (data) => data.dayOfWeek === dayOfWeek)];
    oldData.isAllDay = isAllDay;
    this._doUpdateAndValidate(oldData);
  };

  private _onUpdateStartDateTime = (payload) => {
    const { dayOfWeek, startDateTime } = payload;
    this._eventHandler('startDate', dayOfWeek, startDateTime);
  };

  private _onUpdateEndDateTime = (payload) => {
    const { dayOfWeek, endDateTime } = payload;
    this._eventHandler('endDate', dayOfWeek, endDateTime);
  };

  private _doUpdateAndValidate = (dataObject) => {
    if (!dataObject.selected) {
      dataObject.isValidate = true;
    } else {
      if (dataObject.isAllDay) {
        dataObject.isValidate = true;
      } else {
        dataObject.isValidate = !_.isEmpty(dataObject.startDateTime) && !_.isEmpty(dataObject.endDateTime);
      }
    }
    let oldArray = this.state.dataArray;
    oldArray[_.findIndex(oldArray, (obj) => obj.dayOfWeek === dataObject.dayOfWeek)] = dataObject;
    this.setState({ dataArray: oldArray });
  };

  private _doChangeServiceTimeType = (value) => {
    let dataArray;
    if (!value) {
      dataArray = _.map(this.state.dataArray, (data) => {
        data.selected = true;
        data.isAllDay = true;
        return data;
      });
    } else {
      dataArray = _.map(this.state.dataArray, (data) => {
        data.isAllDay = false;
        return data;
      });
    }

    this.setState({ isFixedTime: value, dataArray: dataArray });
  };

  private _onChangeAvailableFrom = (value, isRecurring = false) => {
    let availableTo = this.state.availableFrom;
    if (availableTo < value) {
      availableTo = isRecurring ? moment(value).add(1, 'month') : moment(value).endOf('day');
    }
    const availableFrom = moment(value);
    this.setState({
      availableFrom,
      availableTo,
      timeAvailabilities: this._getNewTimeAvailabilityArray(this.state.dataArray, availableFrom, availableTo)
    });
  };

  private _onChangeAvailableTo = (value) => {
    this.setState({
      availableTo: value,
      timeAvailabilities: this._getNewTimeAvailabilityArray(this.state.dataArray, this.state.availableFrom, value)
    });
  };

  render() {
    const { selectedService } = this.props;
    const { isEdit } = this.state;

    if (!isEdit) {
      return (
        <div className="mt-small">
          <ServDetailsSectionHeader
            title={'Service Times'}
            hasDivider={true}
            subtitle={'Service availability for the service.'}
            hasEditButton={this.props.hasEditPermission}
            editAction={this._changeView}
          />
          <ServDetailsContentRow label={'Service Timezone'} subLabel={'The default timezone used for this service.'}>
            <TimezoneIndicator timezone={selectedService.timezone} bordered={false} />
          </ServDetailsContentRow>
          {selectedService.serviceType === ServiceType.GROUP && (
            <ServDetailsContentRow
              label={'Service Recurring Type'}
              subLabel={'Will this service occurs multiple times?'}
            >
              <Text>
                {selectedService.recurringType === ServiceRecurringType.ONEOFF ? (
                  <>
                    <b>One-Off:</b> <Text color="secondary">This service is a one-off and will not occur again.</Text>
                  </>
                ) : (
                  <>
                    <b>Recurring:</b> <Text color="secondary">This service will occur multiple times.</Text>
                  </>
                )}
              </Text>
            </ServDetailsContentRow>
          )}
          {selectedService.recurringType !== ServiceRecurringType.ONEOFF && (
            <>
              <ServDetailsContentRow
                label={'Service Availability Dates'}
                subLabel={'From an to what dates will this service be available?'}
              >
                <Text>
                  From <b>{moment.tz(selectedService.availableFrom, selectedService.timezone).format('DD/MM/YYYY')}</b>{' '}
                  to <b>{moment.tz(selectedService.availableTo, selectedService.timezone).format('DD/MM/YYYY')}</b>
                </Text>
              </ServDetailsContentRow>
              <ServDetailsContentRow
                label={
                  selectedService.serviceType === ServiceType.GROUP ? 'Service Recurrency' : 'Service Availability'
                }
                subLabel={
                  selectedService.serviceType === ServiceType.GROUP
                    ? 'Which Day and Times are available to be booked for.'
                    : 'What times during the week is this service is available?'
                }
              >
                {selectedService.serviceType === ServiceType.INDIVIDUAL && selectedService.serviceConfig && (
                  <div className="mb-x-large">
                    <table className="width-full">
                      <tbody>
                        <tr className="bordered-bottom mb-small">
                          <th style={{ width: '150px' }}>Day</th>
                          <th>Available times</th>
                        </tr>
                        {_.map(selectedService.serviceConfig.dataArray, (day) => {
                          return (
                            <tr className="pb-x-small">
                              <td>{day.day}</td>
                              <td>
                                {day.selected
                                  ? day.isAllDay
                                    ? 'Available all day'
                                    : `From ${moment
                                        .tz(day.startDateTime, selectedService.timezone)
                                        .format('hh:mm A')} to ${moment
                                        .tz(day.endDateTime, selectedService.timezone)
                                        .format('hh:mm A')}`
                                  : 'Unavailable'}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>

                    <br />
                  </div>
                )}
                {selectedService.serviceType === ServiceType.GROUP &&
                  selectedService.recurringType === ServiceRecurringType.RECURRING &&
                  selectedService.serviceConfig && (
                    <div className="mb-x-large">
                      <table className="width-full">
                        <tbody>
                          <tr>
                            <th style={{ width: '150px' }}>Day</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th>Total Hours</th>
                            <th>
                              Billable Hours{' '}
                              <Tooltip
                                title={
                                  <div className="p-medium">
                                    <Paragraph className="text-size-regular text-color-white">
                                      Number of hours that will be billed to the Customer. By default it's the number of
                                      hours between the Start and End times selected.
                                    </Paragraph>
                                    <Paragraph className="text-size-regular text-color-white">
                                      Note: You can manually lower that amount. (To signify breaks for example)
                                    </Paragraph>
                                  </div>
                                }
                              >
                                <Icon
                                  type="question-circle"
                                  className="mr-x-small text-size-x-large ml-small text-color-blue cursor-pointer"
                                />
                              </Tooltip>
                            </th>
                            <th>
                              Sessions{' '}
                              <Tooltip
                                title={
                                  <div className="p-medium">
                                    <Paragraph className="text-size-regular text-color-white">
                                      The number of Sessions that will be created for this service depending on the
                                      Start and End dates above and the selected days.
                                    </Paragraph>
                                    <Paragraph className="text-size-regular text-color-white">
                                      If sessions is at 0 it's usually because your Start and End dates are less than a
                                      week closer together.
                                    </Paragraph>
                                  </div>
                                }
                              >
                                <Icon
                                  type="question-circle"
                                  className="mr-x-small text-size-x-large ml-small text-color-blue cursor-pointer"
                                />
                              </Tooltip>
                            </th>
                          </tr>
                          {_.map(selectedService.serviceConfig.dataArray, (day) => {
                            if (day.selected) {
                              const numberOfSession = _.filter(selectedService.timeAvailabilities, (time) => {
                                return time.dayOfWeek === day.dayOfWeek;
                              }).length;
                              return (
                                <tr>
                                  <td>{day.day}</td>
                                  <td>{moment.tz(day.startDateTime, selectedService.timezone).format('hh:mm A')}</td>
                                  <td>{moment.tz(day.endDateTime, selectedService.timezone).format('hh:mm A')}</td>
                                  <td>
                                    {moment
                                      .duration(moment(day.endDateTime).diff(moment(day.startDateTime)))
                                      .asHours()
                                      .toFixed(2)}
                                  </td>
                                  <td>{day.billableHours.toFixed(2)}</td>
                                  <td>{numberOfSession}</td>
                                </tr>
                              );
                            }
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
              </ServDetailsContentRow>
            </>
          )}
          {selectedService.serviceType === ServiceType.GROUP &&
            selectedService.recurringType === ServiceRecurringType.ONEOFF && (
              <ServDetailsContentRow label={'Service Date'} subLabel={'When this service will happened.'}>
                <Text>
                  <b>Starting Date:</b>
                  <br />
                  {moment.tz(selectedService.availableFrom, selectedService.timezone).format('ddd, D/MM/YYYY')}
                  <br />
                  {moment.tz(selectedService.availableFrom, selectedService.timezone).format('hh:mm a')}
                  <br />
                  <br />
                  <b>Ending Date:</b>
                  <br />
                  {moment.tz(selectedService.availableFrom, selectedService.timezone).format('ddd, D/MM/YYYY')}
                  <br />
                  {moment.tz(selectedService.availableTo, selectedService.timezone).format('hh:mm a')}
                </Text>
              </ServDetailsContentRow>
            )}
        </div>
      );
    } else {
      return (
        <div className="mt-small">
          <ActionModal
            isOpen={this.state.isProceedModalOpen}
            onClose={this._closeProceedModal}
            title={'Discard changes'}
            showCloseButton={true}
          >
            <Text className={'mb-medium'}>
              You have <b>unsaved data</b>, proceeding will discard these changes.
            </Text>
            <br />
            <Text className={'mb-medium'}>Do you want to proceed?</Text>
            <ActionModalFooter>
              <PrimaryButton className="mr-medium" size="large" onClick={this._closeProceedModal}>
                Cancel
              </PrimaryButton>
              <GhostButton size="large" onClick={this._changeView}>
                Proceed
              </GhostButton>
            </ActionModalFooter>
          </ActionModal>
          <ServDetailsSectionHeader
            title={'Service Times'}
            hasDivider={true}
            subtitle={'Service availability for the service.'}
          />

          {selectedService.recurringType !== ServiceRecurringType.ONEOFF && (
            <>
              <ServDetailsContentRow
                label={'Service Timezone'}
                subLabel={'The default timezone used for this service.'}
              >
                <TimezoneInput value={this.state.timezone} onChange={(value) => this.setState({ timezone: value })} />
              </ServDetailsContentRow>

              <AvailableDatesContent
                availableFrom={this.state.availableFrom}
                availableTo={this.state.availableTo}
                onChangeAvailableFrom={this._onChangeAvailableFrom}
                onChangeAvailableTo={this._onChangeAvailableTo}
                isRecurring={this.props.selectedService.recurringType === ServiceRecurringType.RECURRING}
                timezone={this.props.selectedService.timezone}
              />

              <ServDetailsContentRow
                label={
                  selectedService.serviceType === ServiceType.GROUP ? 'Service Recurrency' : 'Service Availability'
                }
                subLabel={
                  selectedService.serviceType === ServiceType.GROUP
                    ? 'Which Day and Times are available to be booked for.'
                    : 'What times during the week is this service is available?'
                }
              >
                {selectedService.serviceType === ServiceType.INDIVIDUAL && (
                  <>
                    <div className="mb-x-large">
                      <Switch
                        checkedChildren={<Icon type="check" />}
                        unCheckedChildren={<Icon type="close" />}
                        checked={this.state.isFixedTime}
                        onChange={(event) => {
                          this._doChangeServiceTimeType(event);
                        }}
                      />
                      <span className="ml-small">
                        <Text>
                          <b>{this.state.isFixedTime ? 'This service has a fixed service time.' : 'Available 24/7'}</b>
                        </Text>
                      </span>
                    </div>

                    <Row gutter={24} className="mb-medium">
                      <Col span={5}>
                        <span className="ml-large">
                          <Text>
                            <b>Day</b>
                          </Text>
                        </span>
                      </Col>
                      <Col span={6}>{/*<Text>Is full Day?</Text>*/}</Col>
                      <Col span={6}>
                        <Text>
                          <b>Start Time</b>
                        </Text>
                      </Col>
                      <Col span={6}>
                        <Text>
                          <b>End Time</b>
                        </Text>
                      </Col>
                    </Row>

                    {/*  Available Days*/}
                    <Divider className="divider-medium" />
                    {_.map(this.state.dataArray, (date) => {
                      return (
                        <ServiceAvailableDayItem
                          dateObject={date}
                          onUpdateEndDateTime={this._onUpdateEndDateTime}
                          onUpdateIsAllDay={this._onUpdateIsAllDay}
                          onUpdateSelect={this._onUpdateSelect}
                          onUpdateStartDateTime={this._onUpdateStartDateTime}
                          isFixedTime={this.state.isFixedTime}
                          key={date.dayOfWeek}
                        />
                      );
                    })}
                  </>
                )}
                {selectedService.serviceType === ServiceType.GROUP && (
                  <table id="test" className="width-full mt-x-large">
                    <tbody>
                      <tr>
                        <th style={{ width: '30px' }} />
                        <th>Day</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Total Hours</th>
                        <th>
                          Billable Hours{' '}
                          <Tooltip
                            title={
                              <div className="p-medium">
                                <Paragraph className="text-size-regular text-color-white">
                                  Number of hours that will be billed to the Customer. By default it's the number of
                                  hours between the Start and End times selected.
                                </Paragraph>
                                <Paragraph className="text-size-regular text-color-white">
                                  Note: You can manually lower that amount. (To signify breaks for example)
                                </Paragraph>
                              </div>
                            }
                          >
                            <Icon
                              type="question-circle"
                              className="mr-x-small text-size-x-large ml-small text-color-blue cursor-pointer"
                            />
                          </Tooltip>
                        </th>
                        <th>
                          Sessions{' '}
                          <Tooltip
                            title={
                              <div className="p-medium">
                                <Paragraph className="text-size-regular text-color-white">
                                  The number of Sessions that will be created for this service depending on the Start
                                  and End dates above and the selected days.
                                </Paragraph>
                                <Paragraph className="text-size-regular text-color-white">
                                  If sessions is at 0 it's usually because your Start and End dates are less than a week
                                  closer together.
                                </Paragraph>
                              </div>
                            }
                          >
                            <Icon
                              type="question-circle"
                              className="mr-x-small text-size-x-large ml-small text-color-blue cursor-pointer"
                            />
                          </Tooltip>
                        </th>
                      </tr>
                      {_.map(this.state.dataArray, (item, key) => {
                        return (
                          <ServiceTimeRecurrenceItem
                            key={key}
                            item={item}
                            timeAvailabilities={this.state.timeAvailabilities}
                            eventHandler={this._eventHandler}
                          />
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </ServDetailsContentRow>
            </>
          )}

          {selectedService.serviceType === ServiceType.GROUP &&
            selectedService.recurringType === ServiceRecurringType.ONEOFF && (
              <>
                <ServDetailsContentRow
                  label={'Service Timezone'}
                  subLabel={'The default timezone used for this service.'}
                >
                  <TimezoneInput value={this.state.timezone} onChange={(value) => this.setState({ timezone: value })} />
                </ServDetailsContentRow>
                <ServDetailsContentRow label={'Service Date'} subLabel={'When this service will happen.'}>
                  <Text>
                    <b>Starting Date:</b>
                    <br />
                    <div className="flex-row align-left">
                      <DatePicker
                        size="large"
                        format={'ddd, D MMM YYYY'}
                        allowClear={false}
                        value={moment.tz(this.state.availableFrom, selectedService.timezone)}
                        disabledDate={(current) => {
                          return current < moment().startOf('day');
                        }}
                        onChange={(event) => this._onChangeOneOffStartingDate(event)}
                        className="mr-small"
                      />
                      <TimeInput
                        size="large"
                        value={moment.tz(this.state.availableFrom, selectedService.timezone)}
                        onChange={(event) => this._onChangeOneOffStartingDate(event)}
                      />
                    </div>
                    <br />
                    <br />
                    <b>Ending Date:</b>
                    <br />
                    <div className="flex-row align-left">
                      <DatePicker
                        size="large"
                        value={moment.tz(this.state.availableTo, selectedService.timezone)}
                        allowClear={false}
                        format={'ddd, D MMM YYYY'}
                        disabledDate={(current) => {
                          return current < moment.tz(this.state.availableFrom, selectedService.timezone).startOf('day');
                        }}
                        onChange={(event) => this._onChangeOneOffEndingDate(event)}
                        className="mr-small"
                      />
                      <TimeInput
                        size="large"
                        value={moment.tz(this.state.availableTo, selectedService.timezone)}
                        onChange={(event) => this._onChangeOneOffEndingTime(event)}
                      />
                    </div>
                  </Text>
                </ServDetailsContentRow>
              </>
            )}

          <ServDetailsContentRow align={'right'} leftColSpan={8} rightColSpan={16}>
            <Button.Group>
              <GhostButton onClick={this._discardChanges}>Discard Changes</GhostButton>

              <PrimaryButton loading={this.state.isLoading} onClick={this._onSubmitData} icon={'save'}>
                Save
              </PrimaryButton>
            </Button.Group>
          </ServDetailsContentRow>
        </div>
      );
    }
  }
}
