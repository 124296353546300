import React, { Component } from 'react';
import FullScreenScrollableModal from 'common-components/modal/FullScreenScrollableModal';
import { FieldLabel, Paragraph, Text, Title } from 'common-components/typography';
import { Col, notification, Skeleton } from 'antd';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import CreateConnectionRequestActionModel from 'views/customers/details/components/CreateConnectionRequestActionModel';
import { GridHeader } from 'common-components/grids';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import ActiveConnectionRow from 'views/customers/details/components/ActiveConnectionRow';
import PreviousConnectionRow from 'views/customers/details/components/PreviousConnectionRow';
import ConnectHeaderWarning from 'views/customers/components/ConnectWarningHeader';
import CustomerConnectionCard from 'views/customers/components/CustomerConnectionCard';
import moment from 'moment';
import _ from 'lodash';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';

interface IViewConnectionRequestsModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCustomer: typeof state.customersStore.selectedCustomer;
  doFetchCustomerConnectRequest: typeof dispatch.customersStore.doFetchCustomerConnectRequest;
  doCancelCustomerConnectionRequest: typeof dispatch.customersStore.doCancelCustomerConnectionRequest;
}

interface IViewConnectionRequestsModalState {
  openActionModel: boolean;
  isLoadingRequest: boolean;
  isRemoveActionModalOpen: boolean;
  selectedConnection: string;
  isLoadingCancel: boolean;
}

class ViewConnectionRequestsModal extends Component<
  IViewConnectionRequestsModalProps,
  IViewConnectionRequestsModalState
> {
  state = {
    openActionModel: false,
    isLoadingRequest: false,
    isRemoveActionModalOpen: false,
    selectedConnection: null,
    isLoadingCancel: false
  };
  private _closeCreateServiceModal = () => {
    const { onClose } = this.props;
    onClose();
  };

  private _openActionModel = () => {
    this.setState({ openActionModel: true });
  };

  private _closeActionModel = () => {
    this.setState({ openActionModel: false });
  };

  async componentDidMount() {
    // const { doFetchCustomerConnectRequest, selectedCustomer } = this.props;
    // console.log('TRIGGER');
    // this.setState({ isLoadingRequest: true });
    // if (selectedCustomer) {
    //   await doFetchCustomerConnectRequest({ userId: selectedCustomer.userId });
    //   this.setState({ isLoadingRequest: false });
    // }
  }

  private _openCancelConfirmation = (selectedConnection) => {
    this.setState({ isRemoveActionModalOpen: true, selectedConnection });
  };

  private _closeDeleteActionModal = () => {
    this.setState({
      isRemoveActionModalOpen: false
    });
  };

  private _onCancelConnectionRequest = async () => {
    const { doCancelCustomerConnectionRequest } = this.props;
    this.setState({ isLoadingCancel: true });
    try {
      await doCancelCustomerConnectionRequest({ customerConnectionRequestId: this.state.selectedConnection });
      this.setState({ isLoadingCancel: false });
      this._closeDeleteActionModal();
    } catch (e) {
      notification.error({ message: 'Ops, something wrong' });
      this.setState({ isLoadingCancel: false });
    }
  };

  render() {
    const headerTitle = (
      <Title level={2} className="m-none">
        Connect this record to a GoodHuman Customer
      </Title>
    );

    const { selectedCustomer } = this.props;

    let previousConnectionList = [];
    let activeConnectionRequest = [];

    if (!_.isEmpty(selectedCustomer)) {
      const now = moment();
      previousConnectionList = _.filter(selectedCustomer.customerConnectionRequests, (request) => {
        const expiredDate = moment(request.tokenExpireDate);
        return now.isAfter(expiredDate) || request.status === 'CANCELLED';
      });

      activeConnectionRequest = _.filter(selectedCustomer.customerConnectionRequests, (request) => {
        const expiredDate = moment(request.tokenExpireDate);
        return (now.isBefore(expiredDate) && request.status === 'PENDING') || request.connected === 'CONNECTED';
      });
    }

    const showCreateInviteButton = selectedCustomer
      ? (selectedCustomer.isIndependent && activeConnectionRequest.length === 0) ||
        (selectedCustomer.isDependent && activeConnectionRequest.length < selectedCustomer.guardiansInfo.length)
      : false;

    return (
      selectedCustomer && (
        <FullScreenScrollableModal
          isOpen={this.props.isOpen}
          onClose={this._closeCreateServiceModal}
          width={'full'}
          headerTitle={headerTitle}
          bgColor="tertiary"
          canCloseEsc={false}
          fixedHeight={false}
          noMarginBottom={true}
          hasFooter={false}
          contentPadding={false}
        >
          <CreateConnectionRequestActionModel
            isOpen={this.state.openActionModel}
            onClose={this._closeActionModel}
            customer={selectedCustomer}
          />
          <div className="ph-x4-large">
            <ConnectHeaderWarning />
          </div>
          <div>
            <div className="ph-medium pv-large">
              <div className="ph-x2-large">
                <Title level={4} className="mb-small">
                  Customer record
                </Title>
                <CustomerConnectionCard
                  connectionStatus={selectedCustomer.connected}
                  ndisNumber={selectedCustomer.funding.NDISNumber}
                  firstName={selectedCustomer.firstName}
                  lastName={selectedCustomer.lastName}
                  attachmentUrl={selectedCustomer.attachmentUrl}
                  hasGuardian={!_.isEmpty(selectedCustomer.guardiansInfo)}
                  customerStatus={selectedCustomer.customerStatus}
                />
              </div>
            </div>
            <ActionModal
              isOpen={this.state.isRemoveActionModalOpen}
              onClose={this._closeDeleteActionModal}
              canCloseOutside={!this.state.isLoadingCancel}
              title={'Cancel Connection Request'}
              showCloseButton={true}
            >
              <Paragraph className={'mb-medium'}>You are about to remove the connection request.</Paragraph>
              <Paragraph className={'mb-medium'}>Do you want to proceed?</Paragraph>
              <ActionModalFooter>
                <PrimaryButton
                  className="mr-medium"
                  size="large"
                  onClick={this._closeDeleteActionModal}
                  disabled={this.state.isLoadingCancel}
                >
                  Cancel
                </PrimaryButton>
                <GhostButton
                  size="large"
                  onClick={this._onCancelConnectionRequest}
                  loading={this.state.isLoadingCancel}
                >
                  Proceed
                </GhostButton>
              </ActionModalFooter>
            </ActionModal>

            <div className="ph-medium">
              <div className="ph-x2-large pt-large">
                {this.state.isLoadingRequest && <Skeleton loading={true} />}
                {!this.state.isLoadingRequest && (
                  <>
                    <div>
                      <Title level={4}>Connect customer record to the following people</Title>
                      <div>
                        <Text>
                          Your customer may be legally overseen by one or more Parents or Guardians who manage the
                          Customer’s account on the GoodHuman app on their behalf.
                        </Text>
                      </div>
                      {showCreateInviteButton && (
                        <PrimaryButton size="large" className="mt-medium" onClick={this._openActionModel}>
                          Create connection request
                        </PrimaryButton>
                      )}
                      {_.isEmpty(activeConnectionRequest) && (
                        <div className="mt-medium">
                          <Text>There are no active connect requests for this Customer</Text>
                        </div>
                      )}
                      {!_.isEmpty(activeConnectionRequest) && (
                        <>
                          <GridHeader>
                            <Col span={5}>
                              <FieldLabel text={'Customer Name'} />
                            </Col>
                            <Col span={11}>
                              <FieldLabel text={'Connection Request sent to'} />
                            </Col>
                            <Col span={3}>
                              <FieldLabel text={'SENT on'} />
                            </Col>
                            <Col span={4}>
                              <FieldLabel text={'Expires On'} />
                            </Col>
                            <Col span={1} />
                          </GridHeader>
                          {_.map(activeConnectionRequest, (request, index) => {
                            return (
                              <ActiveConnectionRow
                                connection={request}
                                key={index}
                                openCancelConfirmation={this._openCancelConfirmation}
                              />
                            );
                          })}
                        </>
                      )}
                    </div>

                    <div className="mb-x4-large mt-x-large">
                      <Title level={4}>Previous connection requests</Title>
                      {_.isEmpty(previousConnectionList) && (
                        <div className="mt-medium">
                          <Text>This Customer has not previously been sent a connection request.</Text>
                        </div>
                      )}
                      {!_.isEmpty(previousConnectionList) && (
                        <>
                          <div>
                            <Text>
                              These requests were previously sent but did not result in a connection being made.
                            </Text>
                          </div>
                          <GridHeader>
                            <Col span={5}>
                              <FieldLabel text={'Customer Name'} />
                            </Col>
                            <Col span={11}>
                              <FieldLabel text={'Connection Request sent to'} />
                            </Col>
                            <Col span={3}>
                              <FieldLabel text={'SENT on'} />
                            </Col>
                            <Col span={4}>
                              <FieldLabel text={'status'} />
                            </Col>
                            <Col span={1} />
                          </GridHeader>
                          {_.map(previousConnectionList, (request, index) => {
                            return <PreviousConnectionRow connection={request} key={index} />;
                          })}
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </FullScreenScrollableModal>
      )
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedCustomer: state.customersStore.selectedCustomer
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchCustomerConnectRequest: dispatch.customersStore.doFetchCustomerConnectRequest,
  doCancelCustomerConnectionRequest: dispatch.customersStore.doCancelCustomerConnectionRequest
});

export default connect(
  mapState,
  mapDispatch
)(ViewConnectionRequestsModal);
