import React, { Component } from 'react';
import { Col, Form, InputNumber, notification, Radio, Row } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { Paragraph, Text } from 'common-components/typography';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import ActionModal from 'common-components/modal/ActionModal';
import { EditRecurringMode, ScheduleType } from 'utilities/enum-utils';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';

interface ICancelSessionModalProps extends FormComponentProps {
  closeModal: () => void;
  isOpen: boolean;
  selectedSession: typeof state.groupServiceStore.selectedSession;
  doCancelSession: typeof dispatch.groupServiceStore.doCancelSession;
}

interface ICancelSessionModalState {
  title: string;
  isLoading: boolean;
  displayRecurringOptions: boolean;
  selectedOption: number;
  numberOfSessions: number;
  isSuccess: boolean;
}

class CancelSessionModal extends Component<ICancelSessionModalProps, ICancelSessionModalState> {
  state = {
    title: 'Cancel session',
    isLoading: false,
    displayRecurringOptions: false,
    selectedOption: EditRecurringMode.Current,
    numberOfSessions: 1,
    isSuccess: false
  };

  private _closeModal = () => {
    this.setState({
      title: 'Cancel session',
      isLoading: false,
      displayRecurringOptions:
        this.props.selectedSession.scheduleType === ScheduleType.SCHEDULE &&
        this.props.selectedSession.upcomingSession >= 1,
      selectedOption: EditRecurringMode.Current,
      numberOfSessions: 1,
      isSuccess: false
    });
    this.props.closeModal();
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _onChangeNumberOfSessions = (event) => {
    this.setState({ numberOfSessions: event });
  };

  private _cancelSession = async () => {
    const { selectedSession } = this.props;
    const { selectedOption, numberOfSessions } = this.state;
    this.setState({ isLoading: true });
    try {
      await this.props.doCancelSession({
        serviceId: selectedSession.serviceId,
        serviceDateTimeId: selectedSession.serviceDateTimeId,
        recurringPattern: selectedOption,
        recurringNext: numberOfSessions
      });
      this.setState({ isSuccess: true });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
      this.setState({ isLoading: false });
    }
  };

  componentDidUpdate(
    prevProps: Readonly<ICancelSessionModalProps>,
    prevState: Readonly<ICancelSessionModalState>,
    snapshot?: any
  ) {
    if (prevProps.selectedSession !== this.props.selectedSession && this.props.selectedSession) {
      const { selectedSession } = this.props;
      const displayRecurringOptions =
        selectedSession.scheduleType === ScheduleType.SCHEDULE && selectedSession.upcomingSession >= 1;
      this.setState({
        displayRecurringOptions,
        title: displayRecurringOptions ? 'Schedule options' : 'Cancel session'
      });
    }
  }

  render() {
    const { selectedSession } = this.props;
    const { title, isLoading, isSuccess } = this.state;

    return (
      <div>
        <ActionModal isOpen={this.props.isOpen} title={title} onClose={this._closeModal} width={'large'}>
          {selectedSession && (
            <>
              {this.state.displayRecurringOptions && !isSuccess ? (
                <>
                  <div>
                    <Radio.Group
                      value={this.state.selectedOption}
                      onChange={this._onChangeOption}
                      className="ml-medium"
                    >
                      <Radio
                        value={EditRecurringMode.Current}
                        className={`${this.state.selectedOption === EditRecurringMode.Current &&
                          'text-weight-bold'} mb-small `}
                      >
                        <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                          Cancel this session only.
                        </div>
                      </Radio>
                      <br />
                      <Radio
                        value={EditRecurringMode.CurrentAll}
                        className={`${this.state.selectedOption === EditRecurringMode.CurrentAll &&
                          'text-weight-bold'} mb-small `}
                      >
                        <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                          Cancel this session and all following sessions.
                        </div>
                      </Radio>
                      <br />
                      <Radio
                        value={EditRecurringMode.CurrentNext}
                        className={`${this.state.selectedOption === EditRecurringMode.CurrentNext &&
                          'text-weight-bold'} mb-small `}
                      >
                        <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                          Cancel this session and the next{' '}
                          <InputNumber
                            className="mh-x-small"
                            style={{ width: '50px' }}
                            min={1}
                            max={selectedSession.upcomingSession}
                            value={this.state.numberOfSessions}
                            onChange={this._onChangeNumberOfSessions}
                          />{' '}
                          session
                          {this.state.numberOfSessions !== 1 && 's'}.
                        </div>
                        <br />
                        <Text color="secondary" weight="regular">
                          <i>
                            There {selectedSession.upcomingSession !== 1 ? 'are' : 'is'}{' '}
                            {selectedSession.upcomingSession} upcoming sessions in this schedule
                          </i>
                        </Text>
                      </Radio>
                    </Radio.Group>
                  </div>
                  <div className={'mt-large'}>
                    <Row type={'flex'} justify={'end'}>
                      <SecondaryButton className="mr-medium" size="large" onClick={this._closeModal}>
                        Cancel
                      </SecondaryButton>
                      <PrimaryButton size="large" loading={isLoading} onClick={this._cancelSession}>
                        Continue
                      </PrimaryButton>
                    </Row>
                  </div>
                </>
              ) : isSuccess ? (
                <>
                  <div className="mb-large">
                    <Paragraph>
                      You have successfully cancelled this session
                      {this.state.selectedOption === EditRecurringMode.CurrentAll && ' and all following sessions'}
                      {this.state.selectedOption === EditRecurringMode.CurrentNext &&
                        ` and the next ${this.state.numberOfSessions} session${
                          this.state.numberOfSessions !== 1 ? 's' : ''
                        }`}
                      .
                    </Paragraph>
                  </div>
                  <div className={'mt-large'}>
                    <Row type={'flex'} justify={'end'}>
                      <PrimaryButton size="large" onClick={this._closeModal}>
                        Close
                      </PrimaryButton>
                    </Row>
                  </div>
                </>
              ) : (
                <div className="anim-slide-left">
                  <Paragraph>Are you sure you want to cancel this session?</Paragraph>
                  <Paragraph>
                    This action will cancel the associated bookings & shifts, and notify the respective customers & team
                    members.
                  </Paragraph>

                  <div className={'mb-small'}>
                    <Row type={'flex'} justify={'end'}>
                      <Col>
                        <GhostButton size="large" onClick={this._closeModal} className="mr-medium">
                          Back
                        </GhostButton>
                      </Col>
                      <Col>
                        <PrimaryButton loading={isLoading} onClick={this._cancelSession} size="large">
                          Cancel
                        </PrimaryButton>
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </>
          )}
        </ActionModal>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCancelSession: dispatch.groupServiceStore.doCancelSession
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<ICancelSessionModalProps>()(CancelSessionModal));
