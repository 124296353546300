import React, { Component } from 'react';
import { Col, notification, Radio, Row } from 'antd';
import { connect } from 'react-redux';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import Title from 'antd/lib/typography/Title';
import { ServiceLocationType } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';
import AddAddressModal from 'common-components/addresses/AddAddressModal';
import globalConfig from 'variables/global-config';
import Script from 'react-load-script';

interface IScheduleLocationStepProps {
  onNextStep: (stepData?: any) => void;
  onPreviousStep: (stepData?: any) => void;
  setNewGroupServiceSchedule?: typeof dispatch.servicesStore.setNewGroupServiceSchedule;
  newGroupServiceSchedule: typeof state.servicesStore.newGroupServiceSchedule;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  history?: any;
}

interface IScheduleLocationStepState {
  isLoading: boolean;
  scheduleDirection: string;
  address: any;
  isEditLocationModalOpen: boolean;
}

class ScheduleLocationStep extends Component<IScheduleLocationStepProps, IScheduleLocationStepState> {
  state = {
    isLoading: false,
    scheduleDirection: this.props.newGroupServiceSchedule.scheduleDirection
      ? this.props.newGroupServiceSchedule.scheduleDirection
      : this.props.selectedGroupService && this.props.selectedGroupService.serviceDirection,
    address: this.props.newGroupServiceSchedule.address
      ? this.props.newGroupServiceSchedule.address
      : this.props.selectedGroupService && this.props.selectedGroupService.location,
    isEditLocationModalOpen: false
  };

  private _selectLocationType = (serviceDirection) => {
    this.setState({ scheduleDirection: serviceDirection });
  };

  private _openLocationModal = () => {
    this.setState({ isEditLocationModalOpen: true });
  };

  private _closeLocationModal = () => {
    this.setState({ isEditLocationModalOpen: false });
  };

  private _saveSessionAddress = async (addressLocation) => {
    this.setState({ address: addressLocation });
  };

  private _goToNext = async () => {
    const { setNewGroupServiceSchedule, newGroupServiceSchedule } = this.props;
    const { scheduleDirection, address } = this.state;

    try {
      await setNewGroupServiceSchedule({
        ...newGroupServiceSchedule,
        scheduleDirection,
        address: scheduleDirection === ServiceLocationType.ONLINE ? null : address
      });
      this.props.onNextStep();
    } catch (e) {
      notification.error({ message: 'Oops, something went wrong! Please try again.' });
    }
  };

  render() {
    const { isLoading, scheduleDirection, address } = this.state;

    return (
      <div className="anim-slide-left">
        <Script
          url={'https://maps.googleapis.com/maps/api/js?key=' + globalConfig.googleMapApiKey + '&libraries=places'}
        />
        <AddAddressModal
          closeCreateEditAddressModal={this._closeLocationModal}
          isOpen={this.state.isEditLocationModalOpen}
          address={address}
          saveCustomerAddress={this._saveSessionAddress}
        />
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>Where does this session occur?</Title>
              <Paragraph>
                What's the location of this session? If the session occurs online you can select that option as well.
              </Paragraph>
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white rounded-big p-large" style={{ minWidth: '250px' }}>
              <Title level={4}>Session Location</Title>
              <Paragraph>
                This session will be held at the <b>designated location</b> below :
              </Paragraph>
              <div
                className={`mb-medium bordered border-standard-gray rounded-big shadow-container cursor-pointer p-medium flex-row ${scheduleDirection ===
                  ServiceLocationType.FIXEDVENUE && 'bg-blue-lightest'}`}
                onClick={() => this._selectLocationType(ServiceLocationType.FIXEDVENUE)}
                style={{ maxWidth: '600px' }}
              >
                <div className={'pr-medium'}>
                  <Radio
                    checked={scheduleDirection === ServiceLocationType.FIXEDVENUE}
                    value={ServiceLocationType.FIXEDVENUE}
                  />
                </div>
                <div>
                  <b>Designated location</b>
                  <br />
                  <SubTitle>Location</SubTitle>
                  {address ? (
                    <Text style={{ whiteSpace: 'pre-line' }}>{CommonUtils.formatFullAddress(address)}</Text>
                  ) : (
                    <Text color={'secondary'}>No address set</Text>
                  )}
                  <br />
                  <HyperlinkButton onClick={this._openLocationModal}>Edit location...</HyperlinkButton>
                </div>
              </div>
              {/* Hidden until we handle online bookings*/}
              {/*  <div*/}
              {/*    className={`bordered border-standard-gray rounded-big shadow-box cursor-pointer p-medium flex-row ${scheduleDirection ===*/}
              {/*      ServiceLocationType.ONLINE && 'bg-blue-lightest'}`}*/}
              {/*    onClick={() => this._selectLocationType(ServiceLocationType.ONLINE)}*/}
              {/*    style={{ maxWidth: '600px' }}*/}
              {/*  >*/}
              {/*    <div className={'pr-medium'}>*/}
              {/*      <Radio*/}
              {/*        checked={scheduleDirection === ServiceLocationType.ONLINE}*/}
              {/*        value={ServiceLocationType.ONLINE}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*    <div>*/}
              {/*      <b>Online</b>*/}
              {/*      <br />*/}
              {/*      This session will be held online.*/}
              {/*    </div>*/}
              {/*  </div>*/}
            </div>
            <div className="pv-medium width-full bg-tertiary" style={{ position: 'sticky', bottom: 0 }}>
              <Row gutter={0} type="flex" align="middle" justify={'space-between'} className="bg-transparent">
                <div className="text-align-right pv-medium">
                  <SecondaryButton size="large" disabled={isLoading} onClick={this.props.onPreviousStep}>
                    Back
                  </SecondaryButton>
                </div>
                <div className="text-align-right pv-medium">
                  <PrimaryButton
                    size="large"
                    loading={isLoading}
                    disabled={scheduleDirection === ServiceLocationType.FIXEDVENUE && !address}
                    onClick={this._goToNext}
                  >
                    Next
                  </PrimaryButton>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  setNewGroupServiceSchedule: dispatch.servicesStore.setNewGroupServiceSchedule
});

const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService,
  newGroupServiceSchedule: state.servicesStore.newGroupServiceSchedule
});

export default connect(
  mapState,
  mapDispatch
)(ScheduleLocationStep);
