import React, { Component } from 'react';
import { ICustomer } from 'interfaces/customer-interfaces';
import { FieldLabel, FieldValueText, SubTitle, Text } from 'common-components/typography';
import { Col, Collapse, Icon, Row } from 'antd';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import CreateNewPackageModal from './components/CreateNewPackageModal';
import EditFundingPackage from './components/EditFundingPackage';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import moment from 'moment';
import _ from 'lodash';
import FundingViewCategoriesPanel from 'views/customers/details/tabs-panel/funding/components/FundingViewCategoriesPanel';
import FundingDeleteModal from 'views/customers/details/tabs-panel/funding/components/FundingDeleteModal';
import { CustomerStatusType } from 'utilities/enum-utils';

const { Panel } = Collapse;

interface CustomerFundingPackageListPanelProps {
  selectedCustomer: ICustomer;
  fundingData: typeof state.customersStore.fundingData;
  doSetFundingData?: typeof dispatch.customersStore.doSetFundingData;
  hasEditPermission: boolean;
}
interface CustomerFundingPackageListPanelState {
  activeKey: number;
  isCreatePackageModalOpen: boolean;
  isDeleteFundingModalOpen: boolean;
  step: number;
  totalStep: number;
  isEditPackage: boolean;
  packageType: string;
  packageId: string;
}

class CustomerFundingPackageListPanel extends Component<
  CustomerFundingPackageListPanelProps,
  CustomerFundingPackageListPanelState
> {
  state = {
    activeKey: 0,
    isCreatePackageModalOpen: false,
    isDeleteFundingModalOpen: false,
    step: 1,
    totalStep: 4,
    isEditPackage: false,
    packageType: '',
    packageId: ''
  };

  private _closeCreatePackageModal = async () => {
    await this.props.doSetFundingData({});
    this.setState({ step: 1 });
    this.setState({ isCreatePackageModalOpen: false });
  };

  private _onNextStep = async (stepData) => {
    await this.props.doSetFundingData(stepData);
    this.setState({ step: this.state.step + 1 });
  };

  private _onPreviousStep = async (stepData) => {
    await this.props.doSetFundingData(stepData);
    this.setState({ step: this.state.step - 1 });
  };

  private _openCreatePackageModal = () => {
    this.setState({ isCreatePackageModalOpen: true });
  };

  private _toggleExpandCollapse = (key) => {
    this.setState({ activeKey: key, isEditPackage: false });
  };

  private _showEditPackage = (packageType, packageId) => {
    this.setState({ isEditPackage: true, packageType, packageId });
  };

  private _closeEditFundingPackage = () => {
    this.setState({ isEditPackage: false });
  };

  private _openDeleteFundingModal = (e, packageId) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ isDeleteFundingModalOpen: true, packageId });
  };

  private _closeDeleteFundingModal = () => {
    this.setState({ isDeleteFundingModalOpen: false });
  };

  private _switchGoalType = (goalType: string): string => {
    switch (goalType) {
      case 'longTerm':
        return 'Medium/Long Term';
      case 'shortTerm':
        return 'Short Term';
      default:
        return goalType;
    }
  };

  render() {
    const { selectedCustomer, hasEditPermission } = this.props;
    let NDISFundingPackages = selectedCustomer.funding.NDISFundingPackages;
    let sum = (a) => a.reduce((x, y) => x + y);

    return (
      <React.Fragment>
        {this.state.isEditPackage ? (
          <EditFundingPackage
            selectedCustomer={selectedCustomer}
            isOpen={this.state.isEditPackage}
            closeEditFundingPackage={this._closeEditFundingPackage}
            packageType={this.state.packageType}
            packageId={this.state.packageId}
          />
        ) : null}
        <CreateNewPackageModal
          selectedCustomer={selectedCustomer}
          closeCreatePackageModal={() => {
            this._closeCreatePackageModal();
          }}
          onNextStep={this._onNextStep}
          onPreviousStep={this._onPreviousStep}
          isOpen={this.state.isCreatePackageModalOpen}
          step={this.state.step}
          totalStep={this.state.totalStep}
        />
        <FundingDeleteModal
          isOpen={this.state.isDeleteFundingModalOpen}
          onClose={this._closeDeleteFundingModal}
          fundingPackageId={this.state.packageId}
        />
        <Row type={'flex'} justify={'space-between'} align={'bottom'}>
          <Col>
            <Text type={'secondary'} size={'regular'}>
              {NDISFundingPackages.length} Funding packages found.
            </Text>
          </Col>
          <Col className={'align-center'}>
            <div className={'flex-row-reverse align-center'}>
              {hasEditPermission && selectedCustomer.customerStatus !== CustomerStatusType.ARCHIVED && (
                <PrimaryButton size={'large'} icon={'plus'} onClick={this._openCreatePackageModal}>
                  Create New Package
                </PrimaryButton>
              )}
            </div>
          </Col>
        </Row>
        <Collapse onChange={this._toggleExpandCollapse} accordion className={'mv-small'}>
          {_.map(NDISFundingPackages, (fundpackage) => {
            let totalBudget = sum(fundpackage.fundingNDISSupportCategories.map((x) => Number(x.funding))).toFixed(2);
            const categoryData = fundpackage.fundingNDISSupportCategories;
            let today = moment();
            let expiredDate = moment(fundpackage.expiredDate);
            let packageStatus = fundpackage.status;
            let packageStatusCls =
              packageStatus === 'EXPIRED'
                ? 'bg-red inline-block ph-small ml-small rounded'
                : packageStatus === 'UPCOMING'
                ? 'bg-orange inline-block ph-small ml-small rounded'
                : 'bg-green-dark inline-block ph-small ml-small rounded';
            let today1 = moment(today.format('YYYY-MM-DD'));
            let dateDiff = expiredDate.diff(today1, 'days');

            let diffText;
            if (dateDiff < 7) {
              diffText = 'day(s)';
            } else if (dateDiff >= 7 && dateDiff < 30) {
              dateDiff = expiredDate.diff(today1, 'week');
              diffText = 'week(s)';
            } else if (dateDiff >= 30) {
              dateDiff = expiredDate.diff(today1, 'month');
              diffText = 'month(s)';
            }

            let totalGoals = fundpackage.fundingNDISGoals.filter((item) => item.type !== null).length;

            return (
              <Panel
                header={
                  <Row gutter={16} className={'pv-x-large ph-medium'}>
                    <Col span={8}>
                      <div className="inline-block">
                        <FieldValueText text={fundpackage.agency} />
                      </div>
                      <div className={packageStatusCls}>
                        <Text size="large" weight="bold" color="white">
                          {packageStatus}
                        </Text>
                      </div>
                      <div className="inline-block">
                        <Text>
                          From <b>{moment(fundpackage.startDate, 'YYYY-MM-DD').format('D MMMM YYYY')}</b> to {''}
                          <b>{moment(fundpackage.expiredDate, 'YYYY-MM-DD').format('D MMMM YYYY')}</b>
                        </Text>
                      </div>
                      {packageStatus === 'Active' ? (
                        <div className="inline-block">
                          <Text>
                            Expires in <b>{dateDiff + ' ' + diffText}</b>
                          </Text>
                        </div>
                      ) : null}
                    </Col>
                    <Col span={6}>
                      <div>
                        <FieldLabel text={'Total funds'} />
                      </div>
                      <div className="text-weight-black  text-size-x-large line-height-150">
                        <FieldValueText text={'$ ' + totalBudget} />
                      </div>
                    </Col>
                    <Col span={6}>
                      <div>
                        <Text>
                          <b>{fundpackage.fundingNDISSupportCategories.length}</b> Support Categor
                          {fundpackage.fundingNDISSupportCategories.length !== 1 ? 'ies' : 'y'} funded
                        </Text>
                      </div>
                      <div>
                        <Text>
                          <b>{totalGoals}</b> Goals Set
                        </Text>
                      </div>
                    </Col>
                    <Col span={2}>
                      <div>
                        <SecondaryButton color={'secondary'}>
                          {this.state.activeKey === fundpackage.fundingPackageId ? 'Collapse' : 'Expand'}
                        </SecondaryButton>
                      </div>
                    </Col>
                    <Col span={2} className="text-align-center">
                      <GhostButton onClick={(e) => this._openDeleteFundingModal(e, fundpackage.fundingPackageId)}>
                        <Icon type={'delete'} theme={'filled'} className={'text-color-secondary'} />
                      </GhostButton>
                    </Col>
                  </Row>
                }
                key={fundpackage.fundingPackageId}
                showArrow={false}
              >
                <Collapse
                  bordered={false}
                  defaultActiveKey={['agency_info']}
                  expandIcon={({ isActive }) => <Icon type={isActive ? 'minus' : 'plus'} />}
                  expandIconPosition={'right'}
                >
                  {/*<Panel header="Agency Information" key="agency_info">*/}
                  {/*  <FieldValueText text={fundpackage.agency} />*/}
                  {/*  <Row type={'flex'}>*/}
                  {/*    <Col>*/}
                  {/*      <Icon type={'environment'} />*/}
                  {/*    </Col>*/}
                  {/*    <Col className={'ml-small'}>*/}
                  {/*      <FieldValueText*/}
                  {/*        text={fundpackage.agencyAddress !== null ? fundpackage.agencyAddress : 'Not Set'}*/}
                  {/*      />*/}
                  {/*    </Col>*/}
                  {/*  </Row>*/}
                  {/*  <Row type={'flex'}>*/}
                  {/*    <Col>*/}
                  {/*      <Icon type={'phone'} />*/}
                  {/*    </Col>*/}
                  {/*    <Col className={'ml-small'}>*/}
                  {/*      <FieldValueText*/}
                  {/*        text={*/}
                  {/*          fundpackage.agencyContactNumber !== null && fundpackage.agencyContactNumber !== ''*/}
                  {/*            ? parsePhoneNumberFromString(fundpackage.agencyContactNumber, 'AU').format(*/}
                  {/*                'INTERNATIONAL'*/}
                  {/*              )*/}
                  {/*            : 'Not Set'*/}
                  {/*        }*/}
                  {/*      />*/}
                  {/*    </Col>*/}
                  {/*  </Row>*/}
                  {/*  <Row type={'flex'}>*/}
                  {/*    <Col>*/}
                  {/*      <Icon type={'mail'} />*/}
                  {/*    </Col>*/}
                  {/*    <Col className={'ml-small'}>*/}
                  {/*      <FieldValueText*/}
                  {/*        text={fundpackage.agencyEmail !== null ? fundpackage.agencyEmail : 'No Agency Email Set'}*/}
                  {/*      />*/}
                  {/*    </Col>*/}
                  {/*  </Row>*/}
                  {/*  <div className={'mt-small'}>*/}
                  {/*    <PrimaryButton*/}
                  {/*      icon={'edit'}*/}
                  {/*      shape={'round'}*/}
                  {/*      onClick={() => this._showEditPackage('agency', fundpackage.fundingPackageId)}*/}
                  {/*    >*/}
                  {/*      Edit*/}
                  {/*    </PrimaryButton>*/}
                  {/*  </div>*/}
                  {/*</Panel>*/}
                  {/*<Panel header={'Key Contacts (' + fundpackage.fundingContacts.length + ')'} key="key_contacts">*/}
                  {/*  {fundpackage.fundingContacts.map((contact) => (*/}
                  {/*    <Row className="align-center flex pv-small" key={contact.fundingContactId}>*/}
                  {/*      <Col span={8}>*/}
                  {/*        <FieldValueText text={contact.name} />*/}
                  {/*        <div>*/}
                  {/*          <Text>{contact.role}</Text>*/}
                  {/*        </div>*/}
                  {/*      </Col>*/}
                  {/*      <Col span={8}>*/}
                  {/*        <FieldLabel text={'Contact Information'} />*/}
                  {/*        <Row type={'flex'}>*/}
                  {/*          <Col>*/}
                  {/*            <Icon type={'mobile'} />*/}
                  {/*          </Col>*/}
                  {/*          <Col className={'ml-small'}>*/}
                  {/*            <FieldValueText*/}
                  {/*              text={*/}
                  {/*                contact.mobileNumber !== null*/}
                  {/*                  ? parsePhoneNumberFromString(contact.mobileNumber, 'AU').format('INTERNATIONAL')*/}
                  {/*                  : 'Not Set'*/}
                  {/*              }*/}
                  {/*            />*/}
                  {/*          </Col>*/}
                  {/*        </Row>*/}
                  {/*        <Row type={'flex'}>*/}
                  {/*          <Col>*/}
                  {/*            <Icon type={'phone'} />*/}
                  {/*          </Col>*/}
                  {/*          <Col className={'ml-small'}>*/}
                  {/*            <FieldValueText*/}
                  {/*              text={*/}
                  {/*                contact.officeNumber !== null*/}
                  {/*                  ? parsePhoneNumberFromString(contact.officeNumber, 'AU').format('INTERNATIONAL')*/}
                  {/*                  : 'Not Set'*/}
                  {/*              }*/}
                  {/*            />*/}
                  {/*          </Col>*/}
                  {/*        </Row>*/}
                  {/*      </Col>*/}
                  {/*      <Col span={8}>*/}
                  {/*        <FieldLabel text={'Email'} />*/}
                  {/*        <Row type={'flex'}>*/}
                  {/*          <Col>*/}
                  {/*            <Icon type={'mail'} />*/}
                  {/*          </Col>*/}
                  {/*          <Col className={'ml-small'}>*/}
                  {/*            <FieldValueText text={contact.email !== null ? contact.email : 'No Email Set'} />*/}
                  {/*          </Col>*/}
                  {/*        </Row>*/}
                  {/*      </Col>*/}
                  {/*    </Row>*/}
                  {/*  ))}*/}
                  {/*  <div className={'mt-small'}>*/}
                  {/*    <PrimaryButton*/}
                  {/*      icon={'edit'}*/}
                  {/*      shape={'round'}*/}
                  {/*      onClick={() => this._showEditPackage('contact', fundpackage.fundingPackageId)}*/}
                  {/*    >*/}
                  {/*      Edit*/}
                  {/*    </PrimaryButton>*/}
                  {/*  </div>*/}
                  {/*</Panel>*/}
                  <Panel header={'Dates'} key="dates">
                    <Row>
                      <Col span={5}>
                        <SubTitle>Plan Start Date:</SubTitle>
                        {moment(fundpackage.startDate).format('DD/MM/YYYY')}
                      </Col>
                      <Col span={19}>
                        <SubTitle>Plan Review Date:</SubTitle>
                        {moment(fundpackage.expiredDate).format('DD/MM/YYYY')}
                      </Col>
                    </Row>

                    {hasEditPermission && (
                      <div className={'mt-small'}>
                        <PrimaryButton
                          icon={'edit'}
                          onClick={() => this._showEditPackage('dates', fundpackage.fundingPackageId)}
                        >
                          Edit dates
                        </PrimaryButton>
                      </div>
                    )}
                  </Panel>
                  <Panel header={'Goals (' + totalGoals + ')'} key="goals">
                    {totalGoals > 0
                      ? fundpackage.fundingNDISGoals.map((fundGoal) => (
                          <Row className="align-center flex pv-small" key={fundGoal.fundingNDISGoalId}>
                            <Col span={6}>
                              <FieldLabel text={this._switchGoalType(fundGoal.type) + ' Goal'} />
                            </Col>
                            <Col span={6}>
                              <FieldValueText text={fundGoal.goalTitle} />
                            </Col>
                            <Col span={12}>
                              <Text>{fundGoal.goalDescription}</Text>
                            </Col>
                          </Row>
                        ))
                      : 'No goal set'}
                    {hasEditPermission && (
                      <div className={'mt-small'}>
                        <PrimaryButton
                          icon={'edit'}
                          onClick={() => this._showEditPackage('goal', fundpackage.fundingPackageId)}
                        >
                          Edit
                        </PrimaryButton>
                      </div>
                    )}
                  </Panel>
                  <Panel
                    header={'Support Category Budgets (' + fundpackage.fundingNDISSupportCategories.length + ')'}
                    key="category_budget"
                  >
                    <FundingViewCategoriesPanel categoryData={categoryData} />
                    {hasEditPermission && (
                      <div className={'mt-small'}>
                        <PrimaryButton
                          icon={'edit'}
                          onClick={() => this._showEditPackage('category', fundpackage.fundingPackageId)}
                        >
                          Edit
                        </PrimaryButton>
                      </div>
                    )}
                  </Panel>
                </Collapse>
              </Panel>
            );
          })}
        </Collapse>
      </React.Fragment>
    );
  }
}

const mapState = (state: IRootState) => ({
  fundingData: state.customersStore.fundingData
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doSetFundingData: dispatch.customersStore.doSetFundingData
});

export default connect(
  mapState,
  mapDispatch
)(CustomerFundingPackageListPanel);
