// Customer Permissions
import React, { Component } from 'react';

import { PermissionRowBase } from 'views/account-management/add-new-member/panels/permissions-panel/portal/PermissionRowBase';
import { IPermissionRole, IPermissions } from 'interfaces/account-interfaces';
import CustomerSectionModal from 'views/account-management/components/CustomerSectionModal';
import { getCustomerPermissionName, getCustomerRole } from 'views/account-management/utils/permission-utils';
import { UserPermissionRole } from 'utilities/enum-utils';

interface CustomerPermissionRowProps {
  permissions: IPermissions;
  onChangePermissions: any;
  actionsDisabled: boolean;
  hasAllAccess: boolean;
}

interface CustomerPermissionRowState {
  customerModalOpen: boolean;
}

class CustomerPermissionRow extends Component<CustomerPermissionRowProps, CustomerPermissionRowState> {
  state = { customerModalOpen: false };

  // Customers
  onOpenCustomer = () => this.setState({ customerModalOpen: true });
  onCloseCustomer = () => this.setState({ customerModalOpen: false });

  // Triggered whenever a selection is made.
  onSelectCustomer = (result) => {
    const { permissions, onChangePermissions } = this.props;
    const { permissionRoles } = permissions;

    // filter out payment permissions first
    const filteredRoles = permissionRoles.filter(
      (role) =>
        role.permissionRole !== UserPermissionRole.CustomerViewOnly &&
        role.permissionRole !== UserPermissionRole.CustomerManager
    );

    // Remap back to UserPermissionRole
    const targetRole = getCustomerRole(result.customerPermission);

    const newCustomerRole: IPermissionRole = { permissionRole: targetRole, serviceDepartmentId: null, serviceId: null };

    const updatedPermissions = { ...permissions, permissionRoles: [...filteredRoles, newCustomerRole] };

    onChangePermissions(updatedPermissions);
  };

  render() {
    const { permissions } = this.props;

    const customerPermissionName = getCustomerPermissionName(permissions, this.props.hasAllAccess);

    return (
      <>
        {/* Display Row */}
        <PermissionRowBase
          iconName={'contacts'}
          title={'Customer'}
          description={'Customer profiles and data'}
          currentFlag={customerPermissionName}
          onClickModify={this.onOpenCustomer}
          actionsDisabled={this.props.actionsDisabled}
        />

        {/* Modify Edit dialog */}
        <CustomerSectionModal
          isOpen={this.state.customerModalOpen}
          onClose={this.onCloseCustomer}
          setSelectedOption={this.onSelectCustomer}
          permissions={permissions}
        />
      </>
    );
  }
}

export default CustomerPermissionRow;
