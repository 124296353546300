import React, { Component } from 'react';
import { FieldLabel, Text } from 'common-components/typography';
import { IRootState } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { YearAndMonthFilter } from 'common-components/filter';
import moment from 'moment-timezone';
import { IYearAndMonthFilter } from 'interfaces/filter-interfaces';
import { Checkbox, Col, Empty, Icon } from 'antd';
import { GridHeader, GridRow } from 'common-components/grids';
import { timeZone } from 'interfaces/timezone-type';
import _ from 'lodash';
import { IGroupServiceSession } from 'interfaces/service-interfaces';
import { SessionStatus } from 'utilities/enum-utils';
import SpinningLoader from 'common-components/loading/SpinningLoader';

interface ISelectAllHandler {
  onCheckAll: (filteredSessionList: IGroupServiceSession[]) => void;
  checkAllIndicator: boolean;
  indeterminateCheck: boolean;
}
interface IGroupServiceSessionsPanelProps {
  sessions: IGroupServiceSession[];
  onSelect?: (session: IGroupServiceSession, filteredSessionList: IGroupServiceSession[]) => void;
  timezone: timeZone;
  displayTimeSlotDescription?: boolean;
  displayWarnings?: boolean;
  hideEmptyDates?: boolean;
  filterWarnings?: Array<string> | null;
  onChangeFilter?: (filteredSessionList) => void;
  isLoading?: boolean;

  selectAllHandler?: ISelectAllHandler; //used for select/deselect all checkbox
}

interface IGroupServiceSessionsPanelState {
  filter: IYearAndMonthFilter;
  filteredSessionList: IGroupServiceSession[];
}

class GroupServiceSessionsPanel extends Component<IGroupServiceSessionsPanelProps, IGroupServiceSessionsPanelState> {
  state = {
    filter: {
      year: moment(this.props.sessions[0] && this.props.sessions[0].startDateTime).year(),
      month: moment(this.props.sessions[0] && this.props.sessions[0].startDateTime).month()
    },
    filteredSessionList: []
  };

  private _updateFilter = (filter) => {
    this.setState({ filter });
  };

  private _filterSessionList = () => {
    const { sessions } = this.props;
    const { filter } = this.state;
    const filteredSessionList = _.filter(
      sessions,
      (session) =>
        moment(session.startDateTime).year() === filter.year && moment(session.startDateTime).month() === filter.month
    );
    this.setState({ filteredSessionList }, () => {
      if (this.props.onChangeFilter) {
        this.props.onChangeFilter(filteredSessionList);
      }
    });
  };

  componentDidMount(): void {
    this._filterSessionList();
  }

  componentDidUpdate(
    prevProps: Readonly<IGroupServiceSessionsPanelProps>,
    prevState: Readonly<IGroupServiceSessionsPanelState>
  ): void {
    if (prevState.filter !== this.state.filter) {
      this._filterSessionList();
    }
    if (prevProps.sessions !== this.props.sessions) {
      const { sessions } = this.props;
      const { filter } = this.state;
      const filteredSessionList = _.filter(
        sessions,
        (session) =>
          moment(session.startDateTime).year() === filter.year && moment(session.startDateTime).month() === filter.month
      );
      this.setState({ filteredSessionList });
    }
  }

  render = () => {
    const {
      sessions,
      timezone,
      displayTimeSlotDescription = false,
      displayWarnings = false,
      hideEmptyDates = false,
      filterWarnings = null,
      onSelect = null,
      isLoading = false
    } = this.props;
    const { filter, filteredSessionList } = this.state;

    const SessionEmptyState = () => (
      <div className="flex-1 bg-white mt-x2-large align-center flex-column">
        <div className="">
          <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
        </div>
        <Text size="x2-large" color="secondary" weight="bold">
          No session found for the selected month.
        </Text>
      </div>
    );

    return (
      <div>
        {sessions && (
          <YearAndMonthFilter
            filter={filter}
            startDateTime={moment(sessions[0] && sessions[0].startDateTime)}
            endDateTime={sessions.length > 0 && _.last(sessions) ? moment(_.last(sessions).endDateTime) : moment()}
            updateFilter={this._updateFilter}
            hideEmptyDates={hideEmptyDates}
            dateList={hideEmptyDates && sessions}
            filterWarnings={filterWarnings}
          />
        )}
        <GridHeader bordered containerClassName="border-width-medium border-secondary">
          {this.props.selectAllHandler && (
            <Col span={2} className=" bg-white">
              <Checkbox
                onClick={() => this.props.selectAllHandler.onCheckAll(filteredSessionList)}
                checked={this.props.selectAllHandler.checkAllIndicator}
                indeterminate={this.props.selectAllHandler.indeterminateCheck}
                disabled={_.isEmpty(filteredSessionList)}
              />
            </Col>
          )}
          <Col span={onSelect ? 6 : 10} className=" bg-white">
            <FieldLabel text="Session date" />
          </Col>
          <Col span={displayWarnings ? 3 : 6} className=" bg-white">
            <FieldLabel text="Capacity" />
          </Col>
          <Col span={displayWarnings ? 7 : 8} className=" bg-white">
            <FieldLabel text={displayTimeSlotDescription ? 'Time slot description' : 'Schedule'} />
          </Col>
          {displayWarnings && (
            <Col span={6} className=" bg-white">
              <FieldLabel text="" />
            </Col>
          )}
        </GridHeader>
        {isLoading ? (
          <SpinningLoader size={100} message={'Generating the sessions...'} />
        ) : filteredSessionList.length === 0 ? (
          <SessionEmptyState />
        ) : (
          _.map(filteredSessionList, (session: any, index) => {
            const startDateTime = moment.tz(session.startDateTime, timezone);
            const endDateTime = moment.tz(session.endDateTime, timezone);
            const isCapacityFull =
              session.capacity && session.bookedCapacity !== undefined && session.bookedCapacity >= session.capacity;
            const statusCannotAdd =
              session.sessionStatus === SessionStatus.CANCELLED || session.sessionStatus === SessionStatus.CLOSED;
            const isDisabled =
              displayWarnings &&
              (isCapacityFull || session.alreadyInSession || statusCannotAdd || session.isCustomerArchived);

            const dateLineText = !startDateTime.isSame(endDateTime, 'day')
              ? !startDateTime.isSame(endDateTime, 'month')
                ? !startDateTime.isSame(endDateTime, 'year')
                  ? startDateTime.format("ddd D MMM 'YY") + ' - ' + endDateTime.format("ddd D MMM 'YY")
                  : startDateTime.format('ddd D MMM') + ' - ' + endDateTime.format("ddd D MMM 'YY")
                : startDateTime.format('ddd D') + ' - ' + endDateTime.format("ddd D MMM 'YY")
              : startDateTime.format("ddd D MMM 'YY");

            return (
              <GridRow
                onClick={isDisabled ? () => false : () => this.props.onSelect(session, filteredSessionList)}
                key={index}
              >
                {onSelect && (
                  <Col span={2}>
                    <Checkbox checked={!isDisabled && session.isSelected} disabled={isDisabled} />
                  </Col>
                )}
                <Col span={onSelect ? 6 : 10} className={isDisabled ? 'text-color-tertiary' : ''}>
                  <div>
                    <Text
                      style={{ whiteSpace: 'nowrap' }}
                      color={isDisabled && 'tertiary'}
                      weight="bold"
                      size="regular"
                    >
                      {dateLineText}
                    </Text>
                    <br />
                    <div className="flex-row">
                      <Text size="large" color={isDisabled ? 'tertiary' : 'secondary'} style={{ whiteSpace: 'nowrap' }}>
                        {startDateTime.format('h:mmA') + ' – ' + endDateTime.format('h:mmA')}
                      </Text>
                    </div>
                  </div>
                </Col>
                <Col
                  span={displayWarnings ? 3 : 6}
                  className={isCapacityFull ? (isDisabled ? 'text-color-secondary' : 'text-color-red-dark') : ''}
                >
                  {session.capacity && session.bookedCapacity !== undefined
                    ? session.bookedCapacity + '/' + session.capacity
                    : session.capacity
                    ? session.capacity
                    : 'No capacity'}
                </Col>
                <Col span={displayWarnings ? 7 : 8} className={isDisabled ? 'text-color-tertiary' : ''}>
                  {displayTimeSlotDescription
                    ? session.description
                      ? session.description
                      : '-'
                    : session.scheduleName
                    ? session.scheduleName
                    : '-'}
                </Col>
                {displayWarnings && (
                  <Col span={6}>
                    {session.alreadyInSession && (
                      <>
                        <Text className={'text-color-tertiary'}>
                          <Icon type={'warning'} theme={'filled'} className={'mr-small'} />
                          Already in session
                        </Text>
                        <br />
                      </>
                    )}
                    {statusCannotAdd && (
                      <>
                        <Text className={'text-color-red-dark'}>
                          <Icon type={'warning'} theme={'filled'} className={'mr-small'} />
                          Session {session.sessionStatus === SessionStatus.CANCELLED ? 'cancelled' : 'closed'}
                        </Text>
                        <br />
                      </>
                    )}
                    {isCapacityFull && (
                      <Text className={'text-color-red-dark'}>
                        <Icon type={'warning'} theme={'filled'} className={'mr-small'} />
                        Full capacity
                      </Text>
                    )}
                    {session.isCustomerArchived && (
                      <Text className={'text-color-red-dark'}>
                        <Icon type={'warning'} theme={'filled'} className={'mr-small'} />
                        Customer archived
                      </Text>
                    )}
                  </Col>
                )}
              </GridRow>
            );
          })
        )}
      </div>
    );
  };
}

const mapState = (state: IRootState) => ({
  selectedService: state.servicesStore.selectedService
});

export default connect(
  mapState,
  null
)(GroupServiceSessionsPanel);
