import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { connect } from 'react-redux';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { Spinner } from '@blueprintjs/core';
import { Avatar, Col, Icon, notification, Row } from 'antd';
import _ from 'lodash';
import { BookingErrorType, PaymentStatus } from 'utilities/enum-utils';
import BookingErrorIndicator from 'views/bookings/new-listings/components/details/BookingErrorIndicator';
import CommonUtils from 'utilities/common-utils';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { LineItemsTable } from 'views/group-services/manage-booking-modal/panels/billings/LineItemsTable';

interface IBookingBulkApproveModalProps {
  isOpen: boolean;
  selectedItems: any;
  onClose: (refreshListing?: boolean) => void;
  doApproveGroupBookingsPayment: typeof dispatch.groupBookingsStore.doApproveGroupBookingsPayment;
  selectedSession: typeof state.groupServiceStore.selectedSession;
  sessionBookingsBilling: typeof state.groupBookingsStore.sessionBookingsBilling;
  doFetchSessionBookingBillings: typeof dispatch.groupBookingsStore.doFetchSessionBookingBillings;
}

interface IBookingBulkApproveModalState {
  canManuallyClose: boolean;
  step: Number;
  title: String;
  errorBookings: Array<any>;
  isLoading: boolean;
  openedBookingBillings: string[];
  isLoadingBookingBillings: boolean;
  resultProcessed: null | {
    sessionBookings: number;
    transportBookings: number;
  };
}

class BookingBulkApproveModal extends Component<IBookingBulkApproveModalProps, IBookingBulkApproveModalState> {
  state = {
    canManuallyClose: true,
    step: 1,
    title: 'Approve Bookings',
    errorBookings: null,
    openedBookingBillings: [],
    isLoading: false,
    resultProcessed: null,
    isLoadingBookingBillings: false
  };

  private _openBookingBillingPanel = async (attendanceId) => {
    this.setState({ isLoadingBookingBillings: true });
    const newOpenedBookingBillings = _.clone(this.state.openedBookingBillings);
    newOpenedBookingBillings.push(attendanceId);
    this.setState({ openedBookingBillings: newOpenedBookingBillings });
    await this.props.doFetchSessionBookingBillings({ bookingId: attendanceId });
    this.setState({ isLoadingBookingBillings: false });
  };

  private _closeBookingBillingPanel = (attendanceId) => {
    this.setState({
      openedBookingBillings: _.filter(this.state.openedBookingBillings, (booking) => booking !== attendanceId)
    });
  };

  private _renderView = () => {
    const { step, resultProcessed, openedBookingBillings } = this.state;
    const { selectedItems } = this.props;

    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <div className="text-align-left">
            <Paragraph>
              You are approving{' '}
              <b>
                {selectedItems && selectedItems.length} customer booking
                {selectedItems && selectedItems.length !== 1 ? 's' : ''}
              </b>
              .
            </Paragraph>
            <Paragraph>
              <Icon type={'info-circle'} className={'mr-x-small'} /> <b>Approving</b> these bookings will send it over
              for <b>payment processing & invoice generation</b>.
            </Paragraph>
            <div className={'mt-large rounded-big bordered border-color-quaternary mb-x-large'}>
              {_.map(selectedItems, (booking, index) => {
                const isOpened = !!_.find(
                  openedBookingBillings,
                  (attendanceId) => attendanceId === booking.attendanceId
                );
                const allBillings = _.find(
                  this.props.sessionBookingsBilling,
                  (bookingBilling) => bookingBilling.attendanceId === booking.attendanceId
                );
                const RequireApprovalLineItems = allBillings
                  ? _.filter(
                      allBillings.billingLineItems,
                      (billing) => billing.paymentStatus === PaymentStatus.REQUIRES_APPROVAL
                    )
                  : null;
                const transportBookingsTotal = [];
                if (
                  booking.transportBeforeBooking &&
                  booking.transportBeforeBooking.paymentStatus === PaymentStatus.REQUIRES_APPROVAL
                )
                  transportBookingsTotal.push(Number(booking.transportBeforeBooking.billingTotal));
                if (
                  booking.transportAfterBooking &&
                  booking.transportAfterBooking.paymentStatus === PaymentStatus.REQUIRES_APPROVAL
                )
                  transportBookingsTotal.push(Number(booking.transportAfterBooking.billingTotal));
                return (
                  <>
                    <Row
                      type={'flex'}
                      align={'middle'}
                      key={index}
                      className={`p-medium ${Number(index) < selectedItems.length - 1 && 'bordered-bottom'}`}
                    >
                      <Col span={10}>
                        <div className="flex-row align-center pr-small">
                          <Avatar
                            className={'mr-small'}
                            shape={'circle'}
                            icon={'user'}
                            src={booking.customerAvatarUrl}
                          />
                          <Paragraph className="mv-none" ellipsis={{ rows: 1 }}>
                            {booking.firstName} {booking.lastName}
                          </Paragraph>
                        </div>
                      </Col>
                      <Col span={7}>
                        {transportBookingsTotal.length > 0 && (
                          <Text>
                            {transportBookingsTotal.length} transport booking
                            {transportBookingsTotal.length !== 1 && 's'}
                          </Text>
                        )}
                      </Col>
                      <Col span={1}>
                        {_.isEmpty(booking.bookingErrors) ? (
                          <></>
                        ) : (
                          <BookingErrorIndicator bookingErrors={booking.bookingErrors} usePortal={false} />
                        )}
                      </Col>
                      <Col span={3} className={'text-align-right'}>
                        {CommonUtils.formatPrice(Number(booking.billingTotal) + _.sum(transportBookingsTotal))}
                      </Col>
                      <Col span={3} className={'text-align-right'}>
                        <SecondaryButton
                          onClick={
                            isOpened
                              ? () => this._closeBookingBillingPanel(booking.attendanceId)
                              : () => this._openBookingBillingPanel(booking.attendanceId)
                          }
                          size={'small'}
                          disabled={this.state.isLoadingBookingBillings}
                        >
                          Billings <Icon type={isOpened ? 'up' : 'down'} />
                        </SecondaryButton>
                      </Col>
                    </Row>
                    {isOpened && (
                      <div className={'bg-tertiary p-medium'}>
                        {RequireApprovalLineItems === null ? (
                          <SpinningLoader size={20} message={'Retrieving billing...'} />
                        ) : (
                          <>
                            <SubTitle>Line items for this customer</SubTitle>
                            <section className="mt-medium ph-medium pt-small pb-medium bordered border-standard-gray rounded-big shadow-container bg-white">
                              <LineItemsTable
                                tabType={'session'}
                                lineItems={RequireApprovalLineItems}
                                onEditLineItem={() => false}
                                onDeleteLineItem={() => false}
                                fundedCategories={() => false}
                                canAddEditLineItem={false}
                              />
                            </section>
                          </>
                        )}
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this._onCloseModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" icon="check" onClick={this._onApprove}>
              Approve
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (step === 2) {
      return (
        <div className="anim-slide-right">
          <div className="text-align-center">
            <div className="pv-large">
              <Spinner size={150} />
            </div>

            <Paragraph>Approving the bookings, won't be long...</Paragraph>
          </div>
        </div>
      );
    }
    if (step === 3) {
      return (
        <div className="anim-fade-in">
          <div className="text-align-left">
            <Paragraph>
              You have successfully approved{' '}
              <b>
                {selectedItems && selectedItems.length} customer booking
                {selectedItems && selectedItems.length !== 1 ? 's' : ''}
              </b>
              .
            </Paragraph>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 4) {
      return (
        <div className="anim-fade-in">
          <div className="anim-slide-left">
            <Paragraph>
              {resultProcessed && (resultProcessed.sessionBookings > 0 || resultProcessed.transportBookings > 0) ? (
                <>
                  You have successfully approved{' '}
                  <b>
                    {resultProcessed.sessionBookings} customer booking{resultProcessed.sessionBookings !== 1 ? 's' : ''}
                  </b>{' '}
                  {resultProcessed.transportBookings > 0 && (
                    <>
                      {' '}
                      and{' '}
                      <b>
                        {resultProcessed.transportBookings} transport booking
                        {resultProcessed.transportBookings !== 1 ? 's' : ''}
                      </b>
                    </>
                  )}
                </>
              ) : (
                'Warning - No bookings have been approved.'
              )}
            </Paragraph>
            <Paragraph>
              The following bookings were{' '}
              <span className="text-color-red text-weight-bold">unable to be approved due to unresolved errors</span>.{' '}
              <br /> Please rectify these errors before approving the bookings for payment.
            </Paragraph>

            <Row className="text-weight-bold bordered-bottom border-standard-gray p-small mt-large">
              <Col span={8}>Customer</Col>
              <Col span={6}>Booking type</Col>
              <Col span={10}>Error</Col>
            </Row>
            <div
              style={{ overflowY: 'auto', overflowX: 'hidden', maxHeight: '35vh' }}
              className="bordered-bottom border-standard-gray"
            >
              {_.map(this.state.errorBookings, (error) => (
                <Row type="flex" align="middle" className="p-small border-secondary evenodd">
                  <Col span={8}>
                    <Text>
                      <Avatar className="mr-medium" size={'small'} src={error.attachmentUrl} /> {error.firstName}{' '}
                      {error.lastName}
                    </Text>
                  </Col>

                  <Col span={6}>{error.attendanceType ? 'Transport' : 'Session'}</Col>

                  <Col
                    span={10}
                    title={'Missing payment method'}
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {_.find(
                      error.bookingWarning,
                      (bookingWarning) => bookingWarning.bookingErrorType === BookingErrorType.PaymentMethodError
                    ) && 'Line item(s) missing management method'}
                  </Col>
                </Row>
              ))}
            </div>
          </div>

          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    onClose(this.state.step === 3 || this.state.step === 4);
    this.setState({ canManuallyClose: true, step: 1, title: 'Approve Booking' });
  };

  private _onApprove = async () => {
    const { selectedItems, selectedSession } = this.props;
    try {
      this.setState({ step: 2, canManuallyClose: false, isLoading: true });
      const result: any = await this.props.doApproveGroupBookingsPayment({
        serviceId: selectedSession && selectedSession.serviceId,
        serviceDateTimeId: selectedSession && selectedSession.serviceDateTimeId,
        bookingIds: _.map(selectedItems, (selectedItem) => selectedItem.attendanceId)
      });
      if (result && result.errorBookings && result.errorBookings.length > 0) {
        this.setState({
          step: 4,
          canManuallyClose: true,
          title: 'Booking approval',
          isLoading: false,
          errorBookings: result.errorBookings,
          resultProcessed: result.processed
        });
      } else {
        this.setState({
          step: 3,
          canManuallyClose: true,
          title: 'Booking approved',
          isLoading: false,
          errorBookings: null,
          resultProcessed: result.processed
        });
      }
    } catch (e) {
      this.setState({ canManuallyClose: true, isLoading: false });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  render() {
    const { isOpen } = this.props;
    return (
      <ActionModal
        isOpen={isOpen}
        title={this.state.title}
        width="x2-large"
        onClose={this._onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div className="flex-column justify-center">{this._renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession,
  sessionBookingsBilling: state.groupBookingsStore.sessionBookingsBilling
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doApproveGroupBookingsPayment: dispatch.groupBookingsStore.doApproveGroupBookingsPayment,
  doFetchSessionBookingBillings: dispatch.groupBookingsStore.doFetchSessionBookingBillings
});

export default connect(
  mapState,
  mapDispatch
)(BookingBulkApproveModal);
