import React, { Component } from 'react';
import { Icon } from 'antd';
import { Text, Title } from 'common-components/typography';
import { IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { Popover } from '@blueprintjs/core';
import { SupportWorkerAlertType } from 'utilities//enum-utils';

interface ITeamListingAlertsProps {
  alerts: any;
}

const alertText = {
  1: 'Compliance document(s) expired',
  2: 'Document(s) expired',
  3: 'Compliance document(s) expiring soon',
  4: 'Document(s) expiring soon'
};
export default class TeamListingAlerts extends Component<ITeamListingAlertsProps, any> {
  state = {
    showItem: []
  };
  private _getPopoverContent = () => {
    const { alerts } = this.props;
    const moreAlerts = alerts.slice(2, alerts.length);
    return (
      <div className="ph-x-large pv-medium" style={{ width: '400px' }}>
        <div className="mb-medium">
          <Text size={'x2-large text-weight-bold'}>Alerts</Text>
        </div>
        {moreAlerts.map((alert) => {
          return (
            <div>
              <Text>
                <b>{alert.numberOfAlerts}</b> {alertText[alert.supportWorkerAlertType]}
              </Text>
            </div>
          );
        })}
      </div>
    );
  };
  componentDidMount = () => {
    const { alerts } = this.props;
    if (this.props.alerts) {
      const sortedAlerts = alerts.sort((a, b) => (a.supportWorkerAlertType > b.supportWorkerAlertType ? 1 : -1));
      this.setState({ showItem: sortedAlerts && sortedAlerts.length > 2 ? sortedAlerts.slice(0, 2) : sortedAlerts });
    }
  };
  render() {
    const { showItem } = this.state;
    const { alerts } = this.props;
    return (
      <div className="flex-row">
        {!alerts || alerts.length === 0 ? (
          <div>-</div>
        ) : (
          <>
            {showItem.map((alert) => {
              return (
                <div>
                  {alert.supportWorkerAlertType === SupportWorkerAlertType.ComplianceDocumentExpired ||
                  alert.supportWorkerAlertType === SupportWorkerAlertType.DocumentExpired ? (
                    <Icon
                      type={'minus-circle'}
                      theme="filled"
                      className={`text-size-x2-large text-color-red mr-x-small`}
                    />
                  ) : (
                    <Icon
                      type={'warning'}
                      theme="filled"
                      className={`text-size-x2-large text-color-warning-orange mr-x-small`}
                    />
                  )}

                  <Text className="mr-small">
                    <b>{alert.numberOfAlerts}</b> {alertText[alert.supportWorkerAlertType]}
                  </Text>
                </div>
              );
            })}
            {alerts.length > 2 && (
              <Text className="text-weight-bold ml-small">
                {alerts.length - 2}{' '}
                <Popover content={this._getPopoverContent()} interactionKind="hover" position={'top'}>
                  more...
                </Popover>
              </Text>
            )}
          </>
        )}
      </div>
    );
  }
}
