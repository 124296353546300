import React, { Component } from 'react';
import _ from 'lodash';
import { Col, Input, Skeleton, Empty, Avatar } from 'antd';
import { FieldLabel, Text, Title } from 'common-components/typography';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import { GridHeader, GridRow } from 'common-components/grids';
import { FilterType } from 'utilities/enum-utils';
import { FilterSection } from 'common-components/filter';
import { ProgressBar } from '@blueprintjs/core';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import CommonUtils from 'utilities/common-utils';
import { HyperlinkButton } from 'common-components/buttons';
import * as H from 'history';

const { Search } = Input;

const ListEmptyState = () => (
  <div className="flex-1 bg-white  align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No Customer found.
    </Text>{' '}
    <br /> <br />
    <Text color="secondary">All customers under this filter will appear here.</Text>
    <Text color="secondary">Try adjusting your filter, or refreshing the page.</Text>
  </div>
);

const availableFilters = [
  FilterType.CUSTOMER,
  FilterType.SERVICE,
  FilterType.CUSTOMER_STATUS,
  FilterType.CUSTOMER_CONNECTION_STATUS,
  FilterType.USER_LOCATION_BY_STATE,
  FilterType.CUSTOMER_MANAGED_BY,
  FilterType.MANAGEMENT_METHOD,
  FilterType.PAYMENT_METHODS
];

interface IDebtorCustomersPanelProps {
  history: H.History;
  debtor: any;
  debtorCustomers: typeof state.accountStore.debtorCustomers;
  doViewDebtorCustomerList: typeof dispatch.accountStore.doViewDebtorCustomerList;
}

interface IDebtorCustomersPanelState {
  isSearching: boolean;
  isLoading: boolean;
  isLoadingInfiniteScrolling: boolean;
  customerFilters: any;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
}

const defaultFilters = [
  {
    filter: FilterType.CUSTOMER,
    values: [],
    selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
  },
  {
    filter: FilterType.SERVICE,
    values: [],
    selectionLabel: CommonUtils.getFilterSettings(FilterType.SERVICE).fullSelectionName
  },
  {
    filter: FilterType.CUSTOMER_STATUS,
    values: ['ACTIVE', 'ENQUIRY'],
    selectionLabel: 'Active, Enquiry'
  }
];

class DebtorCustomersPanel extends Component<IDebtorCustomersPanelProps, IDebtorCustomersPanelState> {
  state = {
    isSearching: false,
    isLoading: false,
    isLoadingInfiniteScrolling: false,
    customerFilters: [],
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date()
  };

  private _searchText = async (txt) => {
    this.setState({ isSearching: false });
  };
  private _debounceSearch = _.debounce(this._searchText, 500);
  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.setState({ customerFilters: filters });
  };

  private _fetchMoreCustomers = async () => {
    const { doViewDebtorCustomerList } = this.props;
    const { customerFilters, page, pageSize, pageTimestamp } = this.state;
    const nextPage = page + 1;
    this.setState({ isLoadingInfiniteScrolling: true, page: nextPage });

    await doViewDebtorCustomerList({
      filters: customerFilters,
      page: nextPage,
      pageSize: pageSize,
      pageTimestamp: pageTimestamp
    });
    this.setState({ isLoadingInfiniteScrolling: false });
  };

  private _goCustomerDetails = (customerID) => {
    const { history } = this.props;
    history.push(`/customer/details/${customerID}`);
  };

  componentDidMount = async () => {
    const { doViewDebtorCustomerList, debtor } = this.props;
    const { page, pageSize, pageTimestamp } = this.state;
    this.setState({ isLoading: true, customerFilters: defaultFilters });
    await doViewDebtorCustomerList({
      debtorId: debtor.debtorId,
      filters: defaultFilters,
      page: page,
      pageSize: pageSize,
      pageTimestamp: pageTimestamp
    });
    this.setState({ isLoading: false });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { doViewDebtorCustomerList, debtor } = this.props;
    const { customerFilters } = this.state;

    if (!_.isEqual(prevState.customerFilters, this.state.customerFilters)) {
      this.setState({ isLoading: true });
      const pageTimestamp = new Date();
      this.setState({ isLoading: true, page: 1, pageTimestamp });

      await doViewDebtorCustomerList({
        debtorId: debtor.debtorId,
        filters: customerFilters,
        page: 1,
        pageSize: this.state.pageSize,
        pageTimestamp
      });

      this.setState({ isLoading: false });
    }
  };

  render() {
    const { isSearching, customerFilters } = this.state;
    const { debtorCustomers } = this.props;
    return (
      <div className={'x2-large'}>
        <div>
          <Title level={2} className={'m-none'}>
            Customers
          </Title>
          <Text type={'secondary'}>Customers managed by this debtor</Text>
        </div>
        <div className="flex-row align-center justify-start mv-medium">
          <div>
            <Search
              size="large"
              placeholder="Search"
              onChange={this._onEnterSearchText}
              loading={isSearching}
              style={{ width: '250px' }}
              allowClear={true}
            />
          </div>

          <div className="ml-small">
            <FilterSection
              availableFilters={availableFilters}
              filters={customerFilters}
              onChangeFilter={this._onChangeFilter}
              displayTimezone={null}
              displayMoreFilter={true}
            />
          </div>
        </div>
        <GridHeader bordered containerClassName="border-width-medium border-secondary">
          <Col span={6} className=" bg-white">
            <FieldLabel text="Customer" />
          </Col>
          <Col span={3} className=" bg-white">
            <FieldLabel text="Funding" />
          </Col>
          <Col span={4} className=" bg-white">
            <FieldLabel text="Location" />
          </Col>
          <Col span={11} className=" bg-white">
            <FieldLabel text="Legal guardian(s)" />
          </Col>
        </GridHeader>
        {this.state.isLoading ? (
          <div className="">
            <div className="pv-large">
              <ProgressBar />
            </div>
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
            <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
          </div>
        ) : !_.isEmpty(debtorCustomers) ? (
          <InfiniteScrollLoading
            hasMore={debtorCustomers.length >= this.state.page * this.state.pageSize}
            loadingElementId={'content-container'}
            loadMore={this._fetchMoreCustomers}
            loaderColSpan={7}
            loadingOffSet={60}
          >
            {debtorCustomers.map((customer, index) => {
              return (
                <GridRow key={index}>
                  <Col span={6}>
                    <div className="flex-row align-center">
                      <div>
                        <Avatar className="mr-medium" size="large" icon="user" src={customer.attachmentUrl} />
                      </div>
                      <div
                        style={{
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'pre-line'
                        }}
                      >
                        <HyperlinkButton onClick={() => this._goCustomerDetails(customer.customerUserId)}>
                          {customer.firstName + ' ' + customer.lastName}
                        </HyperlinkButton>
                      </div>
                    </div>
                  </Col>
                  <Col span={3}>
                    <Text>{customer.serviceAgreementPaymentSourceType}</Text>
                  </Col>
                  <Col span={4}>
                    <Text>{customer.locality}</Text>
                  </Col>
                  <Col span={11}>
                    <div className="flex-row align-center">
                      {_.map(customer.guardians, (guardian, index) => {
                        if (parseInt(index) < 2) {
                          return (
                            <div key={index}>
                              <div>
                                <Avatar className="mr-medium" size="large" icon="user" src={guardian.attachmentUrl} />
                              </div>
                              <div
                                style={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'pre-line'
                                }}
                                className="mr-medium"
                              >
                                <Text>{guardian.firstName + ' ' + guardian.lastName}</Text>
                              </div>
                            </div>
                          );
                        } else {
                          return <Text style={{ whiteSpace: 'pre' }}>{customer.guardians.length - 2} more</Text>;
                        }
                      })}
                    </div>
                  </Col>
                </GridRow>
              );
            })}
            <div id="scroll" />
          </InfiniteScrollLoading>
        ) : (
          <div style={{ borderBottom: '0px solid' }}>
            <ListEmptyState />
          </div>
        )}
        {this.state.isLoadingInfiniteScrolling && (
          <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  debtorCustomers: state.accountStore.debtorCustomers
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doViewDebtorCustomerList: dispatch.accountStore.doViewDebtorCustomerList
});

export default connect(
  mapState,
  mapDispatch
)(DebtorCustomersPanel);
