import React, { Component } from 'react';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import TermsConditionsEditor from 'views/customers/details/tabs-panel/service-agreement/TermsConditionsEditor';
import { DatePicker, Form, Input, notification } from 'antd';
import { dispatch, IRootDispatch, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/es/form';
import _ from 'lodash';

interface IServiceAgreementTemplateModalProps extends FormComponentProps {
  onClose: () => void;
  isOpen: boolean;
  template: any;
  displayMode: string;
  existingNames: Array<string>;
  doCreateServiceAgreementTemplates: typeof dispatch.accountStore.doCreateServiceAgreementTemplates;
  doUpdateServiceAgreementTemplates: typeof dispatch.accountStore.doUpdateServiceAgreementTemplates;
}

interface IServiceAgreementTemplateModalState {
  isLoading: boolean;
}

class ServiceAgreementTemplateModal extends Component<
  IServiceAgreementTemplateModalProps,
  IServiceAgreementTemplateModalState
> {
  state = {
    isLoading: false
  };
  private editor: any;

  private _clickSave = async () => {
    const { displayMode, template, form } = this.props;
    this.setState({ isLoading: true });
    const termsValidationResult = this.editor.validate();
    try {
      if (displayMode === 'CREATE') {
        this.props.doCreateServiceAgreementTemplates({
          name: _.trim(form.getFieldValue('name')),
          data: termsValidationResult.content,
          paymentSourceType: 'NDIS'
        });
      } else if (displayMode === 'EDIT') {
        this.props.doUpdateServiceAgreementTemplates({
          templateId: template.termsConditionsTemplateId,
          name: template.name,
          data: termsValidationResult.content,
          paymentSourceType: 'NDIS'
        });
      }
      this.props.onClose();
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      notification.error({ message: 'Oops, something went wrong! Please try again.' });
    }
  };

  private _validateName = (rule, value, callback) => {
    try {
      if (value !== undefined) {
        if (!value || value === '') {
          throw Error('Enter a name.');
        } else if (_.find(this.props.existingNames, (name) => name === _.trim(value))) {
          throw Error('Template name already exist.');
        } else if (_.trim(value).length < 1) {
          throw Error('Please enter at least 1 character.');
        } else if (value.length > 50) {
          throw Error('Please enter less than 50 character.');
        }

        callback();
      }
    } catch (e) {
      callback(e);
    }
  };

  render() {
    const { isOpen, onClose, template, displayMode } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <ActionModal
        isOpen={isOpen}
        onClose={onClose}
        width={'x-large'}
        title={
          <>
            {displayMode === 'CREATE' ? 'Create template' : displayMode === 'EDIT' ? 'Edit template' : 'Template'} for{' '}
            <b>Terms & Conditions of a service agreement</b>
          </>
        }
      >
        <div className="mt-large mb-x2-large">
          <div className={'mb-large'}>
            <SubTitle>Template Name</SubTitle>
            {displayMode === 'CREATE' ? (
              <Form.Item>
                {getFieldDecorator('name', {
                  initialValue: undefined,
                  rules: [
                    {
                      validator: this._validateName
                    }
                  ]
                })(<Input size={'large'} maxLength={50} placeholder="Enter name..." style={{ width: '540px' }} />)}
              </Form.Item>
            ) : (
              template && template.name
            )}
          </div>
          {/* Content here */}
          <SubTitle>terms and conditions</SubTitle>
          {displayMode === 'VIEW' ? (
            <Paragraph>
              {template && template.data && template.data !== '' ? (
                <div
                  style={{ maxHeight: '50vh', overflowY: 'auto' }}
                  dangerouslySetInnerHTML={{
                    __html: template.data
                  }}
                />
              ) : (
                <Text color={'secondary'}>No terms and conditions set.</Text>
              )}
            </Paragraph>
          ) : (
            <TermsConditionsEditor
              contentHtml={template && template.data ? template.data : ''}
              minHeight={'200px'}
              ref={(element) => {
                this.editor = element;
              }}
            />
          )}
        </div>
        {displayMode === 'VIEW' ? (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={onClose}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        ) : (
          <ActionModalFooter>
            <SecondaryButton size="large" onClick={onClose} className="mr-medium">
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._clickSave} loading={this.state.isLoading}>
              Save
            </PrimaryButton>
          </ActionModalFooter>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateServiceAgreementTemplates: dispatch.accountStore.doCreateServiceAgreementTemplates,
  doUpdateServiceAgreementTemplates: dispatch.accountStore.doUpdateServiceAgreementTemplates
});

export default connect(
  null,
  mapDispatch
)(Form.create<IServiceAgreementTemplateModalProps>()(ServiceAgreementTemplateModal));
