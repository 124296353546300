import React, { Component } from 'react';
import { Avatar, Col, Divider, Icon, notification, Row } from 'antd';
import ActionModal from 'common-components/modal/ActionModal';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { dispatch, IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import { SubTitle, Text, Paragraph } from 'common-components/typography';
import moment from 'moment-timezone';
import CommonUtils from 'utilities/common-utils';
import { timeZone } from 'interfaces/timezone-type';
import { Moment } from 'moment';
import DatePicker from 'react-datepicker';
import { RecurringBookingPattern } from 'utilities/enum-utils';
import _ from 'lodash';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import TextArea from 'antd/es/input/TextArea';
import BookingAssignWorkerModal from 'views/bookings/components/BookingAssignWorkerModal';
import { ICustomerBooking } from 'interfaces/customer-interfaces';
import { ISupportWorker } from 'interfaces/supportWorker-interfaces';
import { ConflictResult } from 'views/bookings/components/BookingConfictItem';
import * as H from 'history';

interface IExtendRecurringBookingsModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  bookingItem: any;
  bookingRecurringPattern: any;
  doCheckRecurringPattern: typeof dispatch.bookingsStore.doCheckRecurringPattern;
  doCheckWorker: typeof dispatch.bookingsStore.doCheckWorker;
  doExtendRecurrence: typeof dispatch.bookingsStore.doExtendRecurrence;
  displayTimezone: timeZone;
  history: H.History;
}

interface IExtendRecurringBookingsModalState {
  isLoading: boolean;
  title: string;
  step: number;
  startDateTimeWithoutTz: Moment;
  endDateTimeWithoutTz: Moment;
  recurringToWithoutTz: Moment;
  extendedEndDate: Moment;
  isErrorExtendedDate: boolean;
  canGoNext: boolean;
  newBookings: ICustomerBooking[];
  isCalculatingNewBooking: boolean;
  selectedWorker: ISupportWorker;
  openAssignWorker: boolean;
  conflicts: any;
  instruction: any;
  bookingsOutsideAvailabilityCanBeAssigned: string[],
  bookingsOutsideAvailability: any[]
}

class ExtendRecurringBookingsModal extends Component<
  IExtendRecurringBookingsModalProps,
  IExtendRecurringBookingsModalState
> {
  state = {
    isLoading: false,
    title: 'Edit recurring series',
    step: 1,
    startDateTimeWithoutTz: null,
    endDateTimeWithoutTz: null,
    recurringToWithoutTz: null,
    extendedEndDate: null,
    isErrorExtendedDate: false,
    canGoNext: false,
    newBookings: null,
    isCalculatingNewBooking: false,
    selectedWorker: null,
    openAssignWorker: false,
    conflicts: null,
    instruction: null,
    bookingsOutsideAvailabilityCanBeAssigned: [],
    bookingsOutsideAvailability: []
  };

  private _onCloseModal = () => {
    this.props.onCloseModal();
    this.setState({
      isLoading: false,
      step: 1,
      title: 'Edit recurring series',
      startDateTimeWithoutTz: null,
      endDateTimeWithoutTz: null,
      recurringToWithoutTz: null,
      extendedEndDate: null,
      isErrorExtendedDate: false,
      canGoNext: false,
      newBookings: null,
      isCalculatingNewBooking: false,
      selectedWorker: null,
      openAssignWorker: false,
      conflicts: null,
      instruction: null,
      bookingsOutsideAvailabilityCanBeAssigned: [],
      bookingsOutsideAvailability: []
    });
  };

  private _goToBooking = (bookingId) => {
    const { history } = this.props;
    history.push(`/bookings/details/${bookingId}`);
  };

  private _goToSession = (serviceId, serviceDateTime) => {
    const { history } = this.props;
    history.push(`/group-service/${serviceId}/session/details/${serviceDateTime}`);
  };

  private _checkTeamMemberConflicts = async () => {
    const { bookingItem } = this.props;
    if (this.state.selectedWorker) {
      const result: any = await this.props.doCheckWorker({
        serviceId: bookingItem.serviceId,
        workerId: this.state.selectedWorker.supportWorkerId,
        address: bookingItem.address
          ? {
              ...bookingItem.address,
              geoLat: Number(bookingItem.address.geoLat),
              geoLng: Number(bookingItem.address.geoLng)
            }
          : null,
        sessions: this.state.newBookings,
        customerUserId: bookingItem.bookerUserId
      });
      this.setState({
        conflicts: result.data,
        step: result.data.confirmedShiftConflicts.length > 0 ? 2 : result.data.pendingShiftConflicts.length > 0 ? 3 : 4,
        title:
          result.data.confirmedShiftConflicts.length > 0
            ? 'Team member assigned to the following confirmed shifts.'
            : result.data.pendingShiftConflicts.length > 0
            ? 'Team member assigned to the following pending shifts.'
            : 'Edit recurring series'
      });
    } else {
      this.setState({
        conflicts: null,
        step: 4,
        title: 'Edit recurring series'
      });
    }
  };

  private _checkNewEndDateValidity = (e) => {
    const { bookingRecurringPattern } = this.props;
    const { startDateTimeWithoutTz } = this.state;
    if (!e) {
      this.setState({ extendedEndDate: undefined, newBookings: null, canGoNext: false });
    } else if (
      bookingRecurringPattern.recurringPattern === RecurringBookingPattern.EveryDay &&
      moment(e) > moment(startDateTimeWithoutTz).add(1, 'year')
    ) {
      this.setState({ extendedEndDate: moment(e), isErrorExtendedDate: true, canGoNext: false, newBookings: null });
    } else if (moment(e) > moment(startDateTimeWithoutTz).add(3, 'year')) {
      this.setState({ extendedEndDate: moment(e), isErrorExtendedDate: true, canGoNext: false, newBookings: null });
    } else {
      this.setState(
        {
          extendedEndDate: moment(e),
          isErrorExtendedDate: false,
          canGoNext: true
        },
        () => this._calculateNumberOfNewRecurrences()
      );
    }
  };

  private _calculateNumberOfNewRecurrences = async () => {
    const { bookingRecurringPattern, doCheckRecurringPattern, bookingItem } = this.props;
    const { startDateTimeWithoutTz, endDateTimeWithoutTz, recurringToWithoutTz, extendedEndDate } = this.state;

    const currentStartDay = moment(startDateTimeWithoutTz).day();
    const newStartDateTime =
      bookingRecurringPattern.recurringPattern === RecurringBookingPattern.EveryDay
        ? moment(recurringToWithoutTz)
            .set('hours', startDateTimeWithoutTz.hours())
            .set('minutes', startDateTimeWithoutTz.minutes())
            .add(1, 'day')
        : currentStartDay < moment(recurringToWithoutTz).day()
        ? moment(recurringToWithoutTz)
            .set('hours', startDateTimeWithoutTz.hours())
            .set('minutes', startDateTimeWithoutTz.minutes())
            .add(1, 'week')
            .isoWeekday(currentStartDay)
        : moment(recurringToWithoutTz)
            .set('hours', startDateTimeWithoutTz.hours())
            .set('minutes', startDateTimeWithoutTz.minutes())
            .isoWeekday(currentStartDay);
    const newOldDateTime = moment(newStartDateTime).add(
      moment(endDateTimeWithoutTz).diff(startDateTimeWithoutTz, 'seconds'),
      'seconds'
    );

    this.setState({ isCalculatingNewBooking: true });
    try {
      const result: any = await doCheckRecurringPattern({
        serviceId: bookingItem.serviceId,
        startDateTime: moment
          .tz(moment(newStartDateTime).format('YYYY-MM-DD HH:mm'), bookingItem.timezone)
          .toISOString(),
        endDateTime: moment.tz(moment(newOldDateTime).format('YYYY-MM-DD HH:mm'), bookingItem.timezone).toISOString(),
        recurringPattern: bookingRecurringPattern.recurringPattern,
        recurringTo: moment.tz(moment(extendedEndDate).format('YYYY-MM-DD HH:mm'), bookingItem.timezone).toISOString(),
        serviceDirection: bookingItem.serviceDirection,
        timezone: bookingItem.timezone,
        address: bookingItem.address
          ? {
              ...bookingItem.address,
              geoLat: Number(bookingItem.address.geoLat),
              geoLng: Number(bookingItem.address.geoLng)
            }
          : null,
        userId: bookingItem.bookerUserId
      });
      this.setState({
        isCalculatingNewBooking: false,
        newBookings: result.data.sessions,
        canGoNext: result.data.sessions && result.data.sessions.length > 0
      });
    } catch (e) {
      this.setState({
        isCalculatingNewBooking: false,
        newBookings: [],
        canGoNext: false
      });
    }
  };

  private _openAssignWorkerModal = () => {
    this.setState({ openAssignWorker: true });
  };

  private _closeAssignWorkerModal = () => this.setState({ openAssignWorker: false });

  private _onSubmitAssign = async (selectedWorker, editRecurringMode, conflicts = null) => {
    this.setState({ selectedWorker });
  };

  private _onSelectSupportWorker = async (
    selectedWorker,
    editRecurringMode,
    shiftSlotStatus,
    isRemovePendingShiftSlots,
    startDateTime,
    endDateTime,
    conflicts = null,
    bookingsOutsideAvailabilityCanBeAssigned = [],
    bookingsOutsideAvailability = []
  ) => {
    this.setState({
      bookingsOutsideAvailability, 
      bookingsOutsideAvailabilityCanBeAssigned,
      selectedWorker
    })
  };

  private _onExtendRecurrence = async () => {
    const { bookingItem } = this.props;
    const { newBookings, selectedWorker, instruction, extendedEndDate, bookingsOutsideAvailabilityCanBeAssigned } = this.state;
    this.setState({ isLoading: true });
    try {
      const timeSlotsOutsideCanBeAssignedIds = _.map(bookingsOutsideAvailabilityCanBeAssigned, serviceDateTime => serviceDateTime.serviceDateTimeId);
      await this.props.doExtendRecurrence({
        bookingId: bookingItem.bookingId,
        newEndDate: moment.tz(moment(extendedEndDate).format('YYYY-MM-DD HH:mm'), bookingItem.timezone),
        bookerUserId: bookingItem.bookerUserId,
        serviceId: bookingItem.serviceId,
        paymentSourceType: bookingItem.paymentSourceType,
        timeSlots: newBookings,
        timeSlotsOutsideCanBeAssigned: timeSlotsOutsideCanBeAssignedIds,
        supportWorkerId: selectedWorker && selectedWorker.supportWorkerId,
        instruction
      });
      this.setState({ isLoading: false, step: 5, title: 'Booking successfully created!' });
    } catch (e) {
      this.setState({ isLoading: false });
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
  };

  private _goToNextStep = async () => {
    const { conflicts, step } = this.state;
    if (step === 2 && conflicts.pendingShiftConflicts && conflicts.pendingShiftConflicts.length > 0) {
      this.setState({ step: 3, title: 'Team member assigned to the following pending shifts.' });
    } else {
      this.setState({ step: 4, title: 'Edit recurring series' });
    }
  };

  private _goToFirstStep = () => {
    this.setState({ step: 1 });
  };

  private _updateInstruction = (event) => {
    this.setState({ instruction: event.target.value });
  };

  componentDidUpdate(
    prevProps: Readonly<IExtendRecurringBookingsModalProps>,
    prevState: Readonly<IExtendRecurringBookingsModalState>,
    snapshot?: any
  ) {
    if (this.props.bookingRecurringPattern && !prevProps.isOpen && this.props.isOpen) {
      this.setState({
        startDateTimeWithoutTz: moment(
          moment
            .tz(
              this.props.bookingRecurringPattern && this.props.bookingRecurringPattern.startDateTime,
              this.props.bookingItem.timezone
            )
            .format('YYYY-MM-DD HH:mm')
        ),
        endDateTimeWithoutTz: moment(
          moment
            .tz(
              this.props.bookingRecurringPattern && this.props.bookingRecurringPattern.endDateTime,
              this.props.bookingItem.timezone
            )
            .format('YYYY-MM-DD HH:mm')
        ),
        recurringToWithoutTz: moment(
          moment
            .tz(
              this.props.bookingRecurringPattern && this.props.bookingRecurringPattern.recurringTo,
              this.props.bookingItem.timezone
            )
            .format('YYYY-MM-DD HH:mm')
        )
      });
    }
  }

  render() {
    const { bookingRecurringPattern, bookingItem, displayTimezone } = this.props;
    const {
      isLoading,
      title,
      step,
      startDateTimeWithoutTz,
      recurringToWithoutTz,
      extendedEndDate,
      isErrorExtendedDate,
      isCalculatingNewBooking,
      newBookings,
      selectedWorker,
      conflicts,
      instruction,
      bookingsOutsideAvailabilityCanBeAssigned,
      bookingsOutsideAvailability
    } = this.state;

    const bookingsNotSelectedToBeAssigned =  _.filter(bookingsOutsideAvailability, (booking) => {
      return !_.find(bookingsOutsideAvailabilityCanBeAssigned, b => b.serviceDateTimeId === booking.serviceDateTimeId)
    });

    if (!bookingRecurringPattern) {
      return <></>;
    }

    const numberOfAssignedBookings =
      conflicts &&
      _.filter(
        newBookings,
        (booking: any) =>
          !_.find(
            conflicts.confirmedShiftConflicts,
            (conflict) =>
              moment(booking.startDateTime).format('YYYY-MM-DD HH:mm') ===
              moment(conflict.startDateTime).format('YYYY-MM-DD HH:mm')
          )
      ).length;

    return (
      <div>
        {this.state.openAssignWorker && (
          <BookingAssignWorkerModal
            isOpen={this.state.openAssignWorker}
            onClose={this._closeAssignWorkerModal}
            onSubmitAssign={this._onSelectSupportWorker}
            serviceId={bookingItem.serviceId}
            isRecurring={bookingItem.isRecurring}
            address={bookingItem.address}
            customerUserIds={[bookingItem.bookerUserId]}
            startDateTime={bookingItem.startDateTime}
            endDateTime={bookingItem.endDateTime}
            sessions={newBookings}
            noValidationStep={true}
            isAssignedTeamMemberConfirmed={true}
            history={this.props.history}
            bookingType={bookingItem.bookingType}
            displayTimezone={displayTimezone}
          />
        )}
        <ActionModal isOpen={this.props.isOpen} title={title} onClose={this._onCloseModal} width="x-large">
          {step === 1 && (
            <>
              <div>
                <Row className={'mb-large'}>
                  <Col span={24}>
                    <SubTitle>Recurring Pattern</SubTitle>
                    {bookingRecurringPattern &&
                      CommonUtils.getRecurringPatternLabel(bookingRecurringPattern.recurringPattern)}
                  </Col>
                </Row>
                <Row className={'mb-large'}>
                  <Col span={12}>
                    <SubTitle>Start date</SubTitle>
                    {moment(startDateTimeWithoutTz).format('DD/MM/YYYY')}
                  </Col>
                  <Col span={12}>
                    <SubTitle>Current end date</SubTitle>
                    {moment(recurringToWithoutTz).format('DD/MM/YYYY')}
                  </Col>
                </Row>
                <Row className={'mb-large'}>
                  <Col span={24}>
                    <SubTitle>Extend series until</SubTitle>
                    <DatePicker
                      className={`gh-datepicker-large ${isErrorExtendedDate && 'border-red-dark'}`}
                      showPopperArrow={false}
                      placeholderText={'Select a date'}
                      selected={extendedEndDate && extendedEndDate.toDate()}
                      minDate={
                        recurringToWithoutTz &&
                        moment(recurringToWithoutTz)
                          .add(1, 'day')
                          .startOf('day')
                          .toDate()
                      }
                      calendarClassName="gh-datepicker-calendar"
                      dateFormat="d/M/yyyy"
                      onChange={this._checkNewEndDateValidity}
                    />
                    <br />
                    {isErrorExtendedDate && (
                      <Text color={'red-dark'}>
                        You cannot extend the date of this series for{' '}
                        <b>more than {bookingRecurringPattern.recurringPattern === 1 ? '1 year' : '3 years'}</b>
                      </Text>
                    )}
                    {newBookings &&
                      !isCalculatingNewBooking &&
                      (newBookings.length === 0 ? (
                        <Text>This change does not add any new booking.</Text>
                      ) : (
                        <Text>
                          This change will create <b>{newBookings.length}</b> new booking
                          {newBookings.length !== 1 && 's'}
                        </Text>
                      ))}
                  </Col>
                </Row>
                {isCalculatingNewBooking ? (
                  <SpinningLoader size={100} message={'Calculating new bookings...'} />
                ) : (
                  newBookings && (
                    <>
                      <div className={'mb-large'}>
                        <SubTitle>Team member (optional)</SubTitle>
                        {selectedWorker ? (
                          <div className={'flex-row align-center'}>
                            <Avatar
                              icon="user"
                              className="small mr-small"
                              shape="square"
                              src={selectedWorker.attachmentUrl}
                            />
                            <Text>{selectedWorker.firstName + ' ' + selectedWorker.lastName}</Text>
                          </div>
                        ) : (
                          <div>
                            <Icon type={'warning'} className={'mr-small text-color-warning-orange'} theme={'filled'} />
                            No team member selected
                          </div>
                        )}
                        <div className={'mt-x-small'}>
                          <HyperlinkButton onClick={this._openAssignWorkerModal}>Select Team member</HyperlinkButton>
                        </div>
                      </div>
                      <div className={'mb-large'}>
                        <SubTitle>Instructions (optional)</SubTitle>
                        <TextArea
                          placeholder={'Add instructions here...'}
                          value={instruction}
                          autoSize={{ minRows: 4, maxRows: 20 }}
                          onChange={this._updateInstruction}
                        />
                      </div>
                    </>
                  )
                )}
              </div>
              <div className={'mb-small'}>
                <Row type={'flex'} justify={'end'}>
                  <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size="large" disabled={!this.state.canGoNext} onClick={this._checkTeamMemberConflicts}>
                    Next
                  </PrimaryButton>
                </Row>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div className="mb-medium">
                <Text>
                  The selected team member cannot be confirmed for the following shifts as they are <b>confirmed</b> for
                  the following shift(s) at the same time.
                </Text>
              </div>
              <Row className="text-color-secondary text-uppercase pt-medium">
                <Col span={12} className="ph-medium">
                  <SubTitle>Booking in selected series</SubTitle>
                </Col>
                <Col span={12} className="ph-medium">
                  <SubTitle>Conflicting session/booking</SubTitle>
                </Col>
              </Row>
              <Divider className="mv-medium mb-none" />
              <div style={{ overflow: 'auto', maxHeight: '35vh' }} className={'mb-large'}>
                {_.map(conflicts.confirmedShiftConflicts, (conflict) => (
                  <ConflictResult
                    conflict={conflict}
                    timezone={bookingItem.timezone}
                    goToBooking={this._goToBooking}
                    goToSession={this._goToSession}
                  />
                ))}
              </div>
              <div className={'mb-small'}>
                <Row type={'flex'} justify={'end'}>
                  <SecondaryButton className="mr-medium" size="large" onClick={this._goToFirstStep}>
                    Previous
                  </SecondaryButton>
                  <PrimaryButton size="large" onClick={this._goToNextStep}>
                    Continue
                  </PrimaryButton>
                </Row>
              </div>
            </>
          )}
          {step === 3 && (
            <>
              <div className="mb-medium">
                <Paragraph>
                  The selected team member is assigned as <b>pending</b> to the following shift(s) that have time
                  conflicts with the shift you are attempting to assign them to.
                </Paragraph>
                <Paragraph>
                  If you elect to continue the team member will be removed from the following conflicting shifts.
                </Paragraph>
              </div>
              <Row className="text-color-secondary text-uppercase pt-medium">
                <Col span={12} className="ph-medium">
                  <SubTitle>Booking in selected series</SubTitle>
                </Col>
                <Col span={12} className="ph-medium">
                  <SubTitle>Conflicting session/booking</SubTitle>
                </Col>
              </Row>
              <Divider className="mv-medium mb-none" />
              <div style={{ overflow: 'auto', maxHeight: '35vh' }} className={'mb-large'}>
                {_.map(conflicts.pendingShiftConflicts, (conflict) => (
                  <ConflictResult
                    conflict={conflict}
                    timezone={bookingItem.timezone}
                    goToBooking={this._goToBooking}
                    goToSession={this._goToSession}
                  />
                ))}
              </div>
              <div className={'mb-small'}>
                <Row type={'flex'} justify={'end'}>
                  <SecondaryButton className="mr-medium" size="large" onClick={this._goToFirstStep}>
                    Previous
                  </SecondaryButton>
                  <PrimaryButton size="large" onClick={this._goToNextStep}>
                    Continue
                  </PrimaryButton>
                </Row>
              </div>
            </>
          )}
          {step === 4 && (
            <>
              <div className="mb-medium">
                <Text>
                  As a result of the changes made to the series the following new bookings will be created up till{' '}
                  <b>{moment(extendedEndDate).format('DD/MM/YYYY')}</b>.
                </Text>
              </div>
              <SubTitle>
                {newBookings.length} new booking{newBookings.length !== 0 && 's'} will be created.
              </SubTitle>
              <Row className="text-color-secondary text-uppercase pt-medium">
                <Col span={8} className="ph-medium">
                  <Text weight={'bold'}>Date</Text>
                </Col>
                <Col span={8} className="ph-medium">
                  <Text weight={'bold'}>Status</Text>
                </Col>
                <Col span={8} className="ph-medium">
                  <Text weight={'bold'}>Team member</Text>
                </Col>
              </Row>
              <Divider className="mv-medium mb-none" />
              <div style={{ overflow: 'auto', maxHeight: '35vh' }} className={'mb-large'}>
                {_.map(newBookings, (booking) => {
                  const hasWorkerAssigned =
                    selectedWorker &&
                    !_.find(
                      conflicts.confirmedShiftConflicts,
                      (conflict) =>
                        moment(booking.startDateTime).format('YYYY-MM-DD HH:mm') ===
                        moment(conflict.selectedShift.startDateTime).format('YYYY-MM-DD HH:mm')
                    ) && 
                    !_.find(bookingsNotSelectedToBeAssigned, 
                      (bookingNotSelected) => 
                      bookingNotSelected.serviceDateTimeId === booking.serviceDateTimeId
                    );
                  return (
                    <SummaryResult
                      booking={booking}
                      hasWorkerAssigned={hasWorkerAssigned}
                      selectedWorker={selectedWorker}
                      timezone={displayTimezone}
                    />
                  );
                })}
              </div>
              <div className={'mb-small'}>
                <Row type={'flex'} justify={'end'}>
                  <SecondaryButton className="mr-medium" size="large" onClick={this._goToFirstStep}>
                    Previous
                  </SecondaryButton>
                  <PrimaryButton size="large" onClick={this._onExtendRecurrence} loading={isLoading}>
                    Confirm
                  </PrimaryButton>
                </Row>
              </div>
            </>
          )}
          {step === 5 && (
            <>
              <div className="mb-small">
                <Paragraph>A new recurring booking has been successfully created.</Paragraph>
                <Text size={'x-large'}>{bookingItem.serviceName}</Text>
              </div>
              <div className={'bg-quaternary p-medium rounded-big mb-x2-large'}>
                <div className={'mb-large'}>
                  <SubTitle>Summary</SubTitle>
                  <Text>
                    This recurring series has been extended until <b>{moment(extendedEndDate).format('DD/MM/YYYY')}</b>,
                    for a total of <b>{newBookings.length}</b> new booking{newBookings.length !== 1 && 's'}.
                  </Text>
                </div>
                <div className={'mb-large'}>
                  <SubTitle>Team member</SubTitle>
                  {selectedWorker ? (
                    <div className={'flex-row align-center'}>
                      <Avatar
                        icon="user"
                        className="small mr-small"
                        shape="square"
                        src={selectedWorker.attachmentUrl}
                      />
                      <div className={'mr-small'}>
                        <Text>{selectedWorker.firstName + ' ' + selectedWorker.lastName}</Text>
                      </div>
                      {numberOfAssignedBookings &&
                        numberOfAssignedBookings < newBookings.length &&
                        `(${numberOfAssignedBookings}/${newBookings.length} bookings)`}
                    </div>
                  ) : (
                    <div>
                      <Icon type={'warning'} className={'mr-small text-color-warning-orange'} theme={'filled'} />
                      No team member selected
                    </div>
                  )}
                </div>
                <div className={'mb-large'}>
                  <SubTitle>Instructions</SubTitle>
                  <Text style={{ whiteSpace: 'pre-wrap' }}>{instruction ? instruction : '-'}</Text>
                </div>
                <div className={'mb-large'}>
                  <SubTitle>New booking created</SubTitle>
                  <Text>{newBookings && newBookings.length}</Text>
                </div>
              </div>
              <div className={'mb-small'}>
                <Row type={'flex'} justify={'end'}>
                  <PrimaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                    Close
                  </PrimaryButton>
                </Row>
              </div>
            </>
          )}
        </ActionModal>
      </div>
    );
  }
}

interface ISummaryResultProps {
  booking: any;
  hasWorkerAssigned: boolean;
  selectedWorker: any;
  timezone: timeZone;
}

function SummaryResult(props: ISummaryResultProps) {
  const { booking, timezone, hasWorkerAssigned, selectedWorker } = props;

  return (
    <Row className="pv-medium evenodd" type={'flex'} align={'middle'}>
      <Col span={8} className={'ph-medium'}>
        <Text>
          <b>{moment.tz(booking.startDateTime, timezone).format('D MMMM YYYY')}</b>
          <br />
          {moment.tz(booking.startDateTime, timezone).format('hh:mm A')} -{' '}
          {moment.tz(booking.endDateTime, timezone).format('hh:mm A')}
        </Text>
      </Col>
      <Col span={8} className={'ph-medium'}>
        Confirmed
      </Col>
      <Col span={8} className={'ph-medium'}>
        {hasWorkerAssigned ? (
          <div className={'flex-row align-center'}>
            <Avatar icon="user" className="small mr-small" shape="square" src={selectedWorker.attachmentUrl} />
            <Text>{selectedWorker.firstName + ' ' + selectedWorker.lastName}</Text>
          </div>
        ) : (
          <Text color={'red-dark'}>No assigned team member</Text>
        )}
      </Col>
    </Row>
  );
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCheckRecurringPattern: dispatch.bookingsStore.doCheckRecurringPattern,
  doCheckWorker: dispatch.bookingsStore.doCheckWorker,
  doExtendRecurrence: dispatch.bookingsStore.doExtendRecurrence
});

export default connect(
  null,
  mapDispatch
)(ExtendRecurringBookingsModal);
