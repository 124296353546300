// Base container row for permissions
import { Icon } from 'antd';
import { Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import React from 'react';

export function PermissionRowBase({ iconName, title, description, currentFlag, onClickModify, actionsDisabled }) {
  return (
    <div className="flex-row" style={{ maxWidth: '90%' }}>
      <div className="mr-large pv-medium flex-column justify-start">
        <div className="mt-x-small">
          <Icon type={iconName} className="text-size-x2-large" />
        </div>
      </div>

      <div className="bordered-top flex-1 pv-medium align-start flex-row justify-between">
        {/* Title/description Container */}
        <div>
          <div>
            <Text lineHeight={100} size="x-large">
              {title}
            </Text>
          </div>

          <Text size="regular" color="secondary" lineHeight={100}>
            {description}
          </Text>
        </div>

        {/* Permission + Action Container */}
        <div className="flex-row">
          <div className="mr-large">
            <Text size="large">{currentFlag}</Text>
          </div>
          <div className="mr-large">
            {!actionsDisabled && (
              <HyperlinkButton fontSize="large" onClick={onClickModify}>
                Modify
              </HyperlinkButton>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
