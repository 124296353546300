// Column widths
export const SHIFT_SLOT_COLUMN_WIDTHS = {
  CHECKBOX: '48px',
  SCHEDULED: '109px',
  TEAM_MEMBER: '177px',
  STATUS: '130px',
  START: '95px',
  FINISH: '95px',
  HOURS: '81px',
  WARNINGS: '145px',
  ACTION_ICON: '60px'
};
