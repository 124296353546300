import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Text } from 'common-components/typography';
import { Col, notification, Row, Icon } from 'antd';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import UploadAvatar from 'common-components/upload/UploadAvatar';
import _ from 'lodash';

interface IPortalEditAvatarActionModelProps {
  isOpen: boolean;
  portalUser: typeof state.authStore.portalUser;
  doUpdatePortalUserAvatar: typeof dispatch.authStore.doUpdatePortalUserAvatar;
  onCloseModel: () => void;
}

interface IPortalEditAvatarActionModelState {
  isLoading: boolean;
  url: string;
  urlPath: string;
  isUploading: boolean;
}

class PortalEditAvatarActionModel extends Component<
  IPortalEditAvatarActionModelProps,
  IPortalEditAvatarActionModelState
> {
  state = {
    isLoading: false,
    url: '',
    urlPath: '',
    isUploading: false
  };

  private _onFinishUploadImage = async ({ url, urlPath }) => {
    this.setState({ url, urlPath, isUploading: false });
  };

  private _onSaveChange = async () => {
    const { doUpdatePortalUserAvatar } = this.props;
    try {
      this.setState({ isLoading: true });
      await doUpdatePortalUserAvatar({ attachmentUrl: this.state.url, attachmentPath: this.state.urlPath });
      this._onCancelChange();
      this.setState({ isLoading: false });
    } catch (e) {
      notification.error({ message: 'Error', description: e.message });
      this.setState({ isLoading: false });
    }
  };

  private _onCancelChange = () => {
    const { onCloseModel } = this.props;
    this.setState({
      isLoading: false,
      url: '',
      urlPath: '',
      isUploading: false
    });
    onCloseModel();
  };

  private _onStartUpload = () => {
    this.setState({ isUploading: true });
  };

  render() {
    const { isOpen, portalUser } = this.props;
    return (
      <ActionModal isOpen={isOpen} title={'Change profile picture'} onClose={this._onCancelChange}>
        <div style={{ minHeight: 366 }}>
          <Text className={'mb-medium'}>This picture will be used across GoodHuman and is publicly displayed.</Text>

          <Row className="mt-x3-large">
            <Col span={24}>
              <UploadAvatar
                url={portalUser.attachmentUrl}
                onFinishUpload={this._onFinishUploadImage}
                onStartUpload={this._onStartUpload}
                uploadPath={`users/${portalUser.userId}`}
                uploadButtonText={'Upload new photo'}
                className="cursor-pointer"
              />
              {this.state.isUploading && <Icon type="loading" className="ml-medium" />}
            </Col>
          </Row>
        </div>
        <ActionModalFooter>
          <GhostButton size="large" onClick={this._onCancelChange} disabled={this.state.isLoading}>
            Cancel
          </GhostButton>
          <PrimaryButton
            className="mr-medium"
            size="large"
            onClick={this._onSaveChange}
            loading={this.state.isLoading}
            disabled={_.isEmpty(this.state.url)}
          >
            Confirm Changes
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({ portalUser: state.authStore.portalUser });
const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdatePortalUserAvatar: dispatch.authStore.doUpdatePortalUserAvatar
});

export default connect(
  mapState,
  mapDispatch
)(PortalEditAvatarActionModel);
