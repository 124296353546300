import React, { Component } from 'react';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import * as H from 'history';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import { Checkbox, Col, Divider, Empty, Form, Icon, notification, Row } from 'antd';
import { Warning } from 'common-components/alerts';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import { SubTitle, Text } from 'common-components/typography';
import AddCustomerModal from 'views/group-services/session-details/components/AddCustomerModal';
import SessionFullModal from 'views/group-services/session-details/components/SessionFullModal';
import _ from 'lodash';
import CustomerItem from 'views/group-services/session-details/components/CustomerItem';
import { FilterSection } from 'common-components/filter';
import Search from 'antd/es/input/Search';
import { FilterType, GroupServiceSessionStatus, PaymentStatus } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';
import { FormComponentProps } from 'antd/es/form';
import BookingBulkApproveModal from 'views/group-services/session-details/bulk-actions/customers/modals/BookingBulkApproveModal';
import BookingBulkWaiveModal from 'views/group-services/session-details/bulk-actions/customers/modals/BookingBulkWaiveModal';
import BookingBulkRevokeApprovalModal from 'views/group-services/session-details/bulk-actions/customers/modals/BookingBulkRevokeApprovalModal';
import BookingBulkAddLineItemModal from 'views/group-services/session-details/bulk-actions/customers/modals/BookingBulkAddLineItemModal';
import BookingBulkStartBookingModal from 'views/group-services/session-details/bulk-actions/customers/modals/BookingBulkStartBookingModal';
import BookingBulkEndBookingModal from 'views/group-services/session-details/bulk-actions/customers/modals/BookingBulkEndBookingModal';
import { Tooltip2 } from '@blueprintjs/popover2';

interface ISessionCustomerViewProps extends FormComponentProps {
  history: H.History;
  selectedSession: typeof state.groupServiceStore.selectedSession;
  sessionCustomerDetails: typeof state.groupServiceStore.sessionCustomerDetails;
  sessionCustomerCustomerBookings: typeof state.groupServiceStore.sessionCustomerCustomerBookings;
  sessionFilters: typeof state.groupServiceStore.sessionFilters;
  doFetchSessionCustomerDetails: typeof dispatch.groupServiceStore.doFetchSessionCustomerDetails;
  doFetchSessionCustomerDetailsFull: typeof dispatch.groupServiceStore.doFetchSessionCustomerDetailsFull;
  setSessionFilters: typeof dispatch.groupServiceStore.setSessionFilters;
  setResetSessionCustomers: typeof dispatch.groupServiceStore.setResetSessionCustomers;
  setSessionCustomerBookings: typeof dispatch.groupServiceStore.setSessionCustomerBookings;
  resetSessionCustomers: typeof state.groupServiceStore.resetSessionCustomers;
  sessionBookingsBilling: typeof state.groupBookingsStore.sessionBookingsBilling;
  setSelectedSession: typeof dispatch.groupServiceStore.setSelectedSession;
  doFetchSessionBookingBillings: typeof dispatch.groupBookingsStore.doFetchSessionBookingBillings;
}

interface ISessionCustomerViewState {
  isLoading: boolean;
  isSearching: boolean;
  isAddCustomerOpen: boolean;
  isSessionFullOpen: boolean;
  checkAllSessions: boolean;
  isBulkApproveOpen: boolean;
  isBulkWaiveOpen: boolean;
  isBulkRevokeApprovalOpen: boolean;
  isBulkAddLineItemOpen: boolean;
  isBulkStartBookingOpen: boolean;
  isBulkFinishBookingOpen: boolean;
  selectedItemsForModal: any;
  openedBookingBillings: string[];
  isLoadingBookingBillings: boolean;
  isSessionDetailsCollapsed: boolean;
}

const CustomerEmptyState = () => (
  <Row className="mv-x2-large" type={'flex'} align={'middle'}>
    <Col span={24} className={'align-center text-align-center'}>
      <div>
        <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
      </div>
      <div>
        <Text size="x2-large" color="secondary" weight="bold">
          No customer found.
        </Text>
      </div>
    </Col>
  </Row>
);

const availableFilters = [FilterType.BOOKING_STATUS, FilterType.PAYMENT_STATUS];

const FILTERCONFIGS = {
  ALL: {
    filters: [
      {
        filter: FilterType.BOOKING_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.BOOKING_STATUS).fullSelectionName
      },
      {
        filter: FilterType.PAYMENT_STATUS,
        values: [],
        selectionLabel: CommonUtils.getFilterSettings(FilterType.PAYMENT_STATUS).fullSelectionName
      }
    ]
  }
};

class SessionCustomerView extends Component<ISessionCustomerViewProps, ISessionCustomerViewState> {
  state = {
    isLoading: true,
    isSearching: false,
    isAddCustomerOpen: false,
    isSessionFullOpen: false,
    isBulkApproveOpen: false,
    isBulkWaiveOpen: false,
    isBulkRevokeApprovalOpen: false,
    isBulkAddLineItemOpen: false,
    isBulkStartBookingOpen: false,
    isBulkFinishBookingOpen: false,
    selectedItemsForModal: null,
    checkAllSessions: false,
    openedBookingBillings: [],
    isLoadingBookingBillings: false,
    isSessionDetailsCollapsed: false
  };

  private _openAddCustomerModal = () => {
    const { sessionCustomerDetails } = this.props;
    sessionCustomerDetails.bookedCapacity &&
    sessionCustomerDetails.capacity &&
    sessionCustomerDetails.bookedCapacity >= sessionCustomerDetails.capacity
      ? this.setState({ isSessionFullOpen: true })
      : this.setState({ isAddCustomerOpen: true });
  };

  private _closeAddCustomerModal = async (resetList = false) => {
    this.setState({ isAddCustomerOpen: false });
    if (resetList) {
      const { selectedSession } = this.props;
      await this.props.doFetchSessionCustomerDetailsFull({
        ...this._formatFilterQuery(),
        serviceId: selectedSession && selectedSession.serviceId,
        serviceDateTimeId: selectedSession && selectedSession.serviceDateTimeId
      });
      this.props.setSelectedSession({
        ...this.props.selectedSession,
        bookedCapacity: this.props.sessionCustomerDetails.bookedCapacity
          ? this.props.sessionCustomerDetails.bookedCapacity
          : 0
      });
    }
  };

  private _handleCollapseDetails = (collapsed) => {
    this.setState({ isSessionDetailsCollapsed: collapsed });
  };

  private _openSessionFullModal = () => {
    this.setState({ isSessionFullOpen: true });
  };

  private _closeSessionFullModal = () => {
    this.setState({ isSessionFullOpen: false });
  };

  private _setCheckAllCustomers = () => {
    this.setState({ checkAllSessions: !this.state.checkAllSessions });
    this.props.setSessionCustomerBookings(
      _.map(this.props.sessionCustomerCustomerBookings, (customer) => {
        return { ...customer, isChecked: !this.state.checkAllSessions };
      })
    );
  };

  private _handleItemClick = (attendanceId) => {
    this.props.setSessionCustomerBookings(
      _.map(this.props.sessionCustomerCustomerBookings, (customer) => {
        if (customer.attendanceId === attendanceId) {
          return { ...customer, isChecked: !customer.isChecked };
        } else {
          return { ...customer };
        }
      })
    );
  };

  private _openBookingBillingPanel = async (attendanceId) => {
    this.setState({ isLoadingBookingBillings: true });
    const newOpenedBookingBillings = _.clone(this.state.openedBookingBillings);
    newOpenedBookingBillings.push(attendanceId);
    this.setState({ openedBookingBillings: newOpenedBookingBillings });
    await this.props.doFetchSessionBookingBillings({ bookingId: attendanceId });
    this.setState({ isLoadingBookingBillings: false });
  };

  private _closeBookingBillingPanel = (attendanceId) => {
    this.setState({
      openedBookingBillings: _.filter(this.state.openedBookingBillings, (booking) => booking !== attendanceId)
    });
  };

  private _searchText = async (txt) => {
    const { sessionFilters, setSessionFilters } = this.props;
    const newSessionFilters = _.clone(sessionFilters);
    const existingSearchIndex = _.findIndex(newSessionFilters, (filter: any) => filter.filter === 'searchString');
    if (existingSearchIndex > -1) {
      if (txt === '') {
        newSessionFilters.splice(existingSearchIndex, 1);
      } else {
        newSessionFilters[existingSearchIndex].values = txt;
      }
    } else {
      newSessionFilters.push({ filter: 'searchString', values: txt });
    }
    setSessionFilters(newSessionFilters);
    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _onChangeFilter = (filters: Array<any>) => {
    this.props.setSessionFilters(filters);
  };

  private _formatFilterQuery = () => {
    const requestFilter: any = {};
    _.forEach(this.props.sessionFilters, (filter) => {
      if (!_.isEmpty(filter.values)) {
        switch (filter.filter) {
          case 'paymentStatus':
            requestFilter.paymentStatus = filter.values;
            break;
          case 'status':
            requestFilter.status = filter.values;
            break;
          case 'searchString':
            requestFilter.searchString = filter.values;
            break;
        }
      }
    });
    return requestFilter;
  };

  public refreshList = async () => {
    const { selectedSession } = this.props;
    try {
      this.setState({ isLoading: true, openedBookingBillings: [] });
      await this.props.doFetchSessionCustomerDetailsFull({
        ...this._formatFilterQuery(),
        serviceId: selectedSession && selectedSession.serviceId,
        serviceDateTimeId: selectedSession && selectedSession.serviceDateTimeId
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isLoading: false });
  };

  public onActionDeselect = () => {
    this.setState({ checkAllSessions: false });
    this.props.setSessionCustomerBookings(
      _.map(this.props.sessionCustomerCustomerBookings, (customer) => {
        return { ...customer, isChecked: false };
      })
    );
  };

  private _getFilteredSelectedItemsForModal = (selectionHistory) => {
    const { sessionCustomerCustomerBookings } = this.props;
    const selectedList = _.filter(sessionCustomerCustomerBookings, (booking) => booking.isChecked);
    const status = selectionHistory && selectionHistory[0];
    const paymentStatus = selectionHistory && selectionHistory[1];
    return status
      ? paymentStatus
        ? _.filter(selectedList, (customer) => customer.status === status && customer.paymentStatus === paymentStatus)
        : _.filter(selectedList, (customer) => customer.status === status)
      : null;
  };

  public onBulkApprove = (selectionHistory) => {
    this.setState({
      isBulkApproveOpen: true,
      selectedItemsForModal: this._getFilteredSelectedItemsForModal(selectionHistory)
    });
  };

  public onBulkWaive = (selectionHistory) => {
    this.setState({
      isBulkWaiveOpen: true,
      selectedItemsForModal: this._getFilteredSelectedItemsForModal(selectionHistory)
    });
  };

  public onBulkRevokeApproval = (selectionHistory) => {
    this.setState({
      isBulkRevokeApprovalOpen: true,
      selectedItemsForModal: this._getFilteredSelectedItemsForModal(selectionHistory)
    });
  };

  public onBulkAddLineItem = (selectionHistory) => {
    this.setState({
      isBulkAddLineItemOpen: true,
      selectedItemsForModal: this._getFilteredSelectedItemsForModal(selectionHistory)
    });
  };

  public onBulkStartBooking = (selectionHistory) => {
    this.setState({
      isBulkStartBookingOpen: true,
      selectedItemsForModal: this._getFilteredSelectedItemsForModal(selectionHistory)
    });
  };

  public onBulkEndBooking = (selectionHistory) => {
    this.setState({
      isBulkFinishBookingOpen: true,
      selectedItemsForModal: this._getFilteredSelectedItemsForModal(selectionHistory)
    });
  };

  private _onApproveAll = (e, selectedItemsForModal) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({
      isBulkApproveOpen: true,
      selectedItemsForModal
    });
  };

  private _closeBulkActionModal = (action, refreshListing = false) => {
    if (action === 'APPROVE') {
      this.setState({ isBulkApproveOpen: false, selectedItemsForModal: null });
    } else if (action === 'WAIVE_BOOKING') {
      this.setState({ isBulkWaiveOpen: false, selectedItemsForModal: null });
    } else if (action === 'REVOKE_APPROVAL') {
      this.setState({ isBulkRevokeApprovalOpen: false, selectedItemsForModal: null });
    } else if (action === 'ADD_LINE_ITEM') {
      this.setState({ isBulkAddLineItemOpen: false, selectedItemsForModal: null });
    } else if (action === 'START_BOOKING') {
      this.setState({ isBulkStartBookingOpen: false, selectedItemsForModal: null });
    } else if (action === 'END_BOOKING') {
      this.setState({ isBulkFinishBookingOpen: false, selectedItemsForModal: null });
    }
    if (refreshListing) {
      this.onActionDeselect();
      this.refreshList();
    }
  };

  componentDidMount = async () => {
    const { selectedSession } = this.props;
    this.setState({ isLoading: true });
    try {
      const sessionFilters = FILTERCONFIGS.ALL.filters;
      await this.props.setSessionCustomerBookings([]);
      await this.props.setSessionFilters(sessionFilters);
      await this.props.doFetchSessionCustomerDetailsFull({
        ...this._formatFilterQuery(),
        serviceId: selectedSession && selectedSession.serviceId,
        serviceDateTimeId: selectedSession && selectedSession.serviceDateTimeId
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isLoading: false });
  };

  componentDidUpdate = async (prevProps: Readonly<ISessionCustomerViewProps>) => {
    if (
      prevProps.sessionFilters !== this.props.sessionFilters ||
      (!prevProps.resetSessionCustomers && this.props.resetSessionCustomers)
    ) {
      const { selectedSession } = this.props;
      this.setState({ isLoading: true });
      await this.props.doFetchSessionCustomerDetails({
        ...this._formatFilterQuery(),
        serviceId: selectedSession && selectedSession.serviceId,
        serviceDateTimeId: selectedSession && selectedSession.serviceDateTimeId
      });
      await this.props.setResetSessionCustomers(false);
      this.setState({ isLoading: false, checkAllSessions: false });
    }
  };

  render() {
    const {
      sessionCustomerDetails,
      selectedSession,
      sessionFilters,
      sessionCustomerCustomerBookings,
      history
    } = this.props;
    const { isLoading, isSessionDetailsCollapsed } = this.state;

    const availableSlots =
      sessionCustomerDetails && sessionCustomerDetails.capacity
        ? sessionCustomerDetails.capacity - sessionCustomerDetails.bookedCapacity
        : 0;
    const isAllChecked =
      sessionCustomerCustomerBookings &&
      sessionCustomerCustomerBookings.length > 0 &&
      _.every(sessionCustomerCustomerBookings, (customer) => customer.isChecked);
    const isCheckIndeterminate =
      _.some(sessionCustomerCustomerBookings, (customer) => customer.isChecked) && !isAllChecked;

    const requiresApprovalBookings = _.filter(
      sessionCustomerDetails && sessionCustomerDetails.fullCustomerList,
      (customer) => customer.paymentStatus === PaymentStatus.REQUIRES_APPROVAL
    );

    const estimatedRevenue = _.reduce(
      sessionCustomerDetails && sessionCustomerDetails.fullCustomerList,
      (acc, booking) =>
        acc +
        Number(booking.billingTotal) +
        (booking.transportBeforeBooking && Number(booking.transportBeforeBooking.billingTotal)) +
        (booking.transportAfterBooking && Number(booking.transportAfterBooking.billingTotal)),
      0
    );

    return (
      <>
        <div style={{ overflow: 'hidden' }}>
          <AddCustomerModal
            isOpen={this.state.isAddCustomerOpen}
            history={history}
            onClose={this._closeAddCustomerModal}
          />
          <SessionFullModal
            isOpen={this.state.isSessionFullOpen}
            history={history}
            onClose={this._closeSessionFullModal}
          />
          <BookingBulkApproveModal
            isOpen={this.state.isBulkApproveOpen}
            selectedItems={this.state.selectedItemsForModal}
            onClose={(refreshListing) => this._closeBulkActionModal('APPROVE', refreshListing)}
          />
          <BookingBulkWaiveModal
            isOpen={this.state.isBulkWaiveOpen}
            selectedItems={this.state.selectedItemsForModal}
            onClose={(refreshListing) => this._closeBulkActionModal('WAIVE_BOOKING', refreshListing)}
          />
          <BookingBulkRevokeApprovalModal
            isOpen={this.state.isBulkRevokeApprovalOpen}
            selectedItems={this.state.selectedItemsForModal}
            onClose={(refreshListing) => this._closeBulkActionModal('REVOKE_APPROVAL', refreshListing)}
          />
          <BookingBulkAddLineItemModal
            isOpen={this.state.isBulkAddLineItemOpen}
            selectedItems={this.state.selectedItemsForModal}
            onClose={(refreshListing) => this._closeBulkActionModal('ADD_LINE_ITEM', refreshListing)}
          />
          <BookingBulkStartBookingModal
            isOpen={this.state.isBulkStartBookingOpen}
            selectedItems={this.state.selectedItemsForModal}
            onClose={(refreshListing) => this._closeBulkActionModal('START_BOOKING', refreshListing)}
          />
          <BookingBulkEndBookingModal
            isOpen={this.state.isBulkFinishBookingOpen}
            selectedItems={this.state.selectedItemsForModal}
            onClose={(refreshListing) => this._closeBulkActionModal('END_BOOKING', refreshListing)}
          />

          {sessionCustomerCustomerBookings && sessionCustomerDetails ? (
            <>
              {sessionCustomerDetails && sessionCustomerDetails.numberOfBookingNotConfirmed > 0 && (
                <Warning content={'Some bookings have not been confirmed'} className={'mb-large'} />
              )}
              {/*<div*/}
              {/*  className={*/}
              {/*    'mb-large p-medium bg-white bordered-left border-width-large border-blue-action rounded-big shadow-container'*/}
              {/*  }*/}
              {/*>*/}
              {/*  <div>*/}
              {/*    <Text color="secondary">Customers in session</Text>*/}
              {/*  </div>*/}
              {/*  <div className={'flex-row align-center'}>*/}
              {/*    {sessionCustomerDetails.capacity ? (*/}
              {/*      availableSlots > 0 ? (*/}
              {/*        <Icon type={'up-circle'} className={'text-color-green mr-small text-size-x2-large'} />*/}
              {/*      ) : (*/}
              {/*        <Icon type={'check-circle'} className={'text-color-red-dark mr-small text-size-x2-large'} />*/}
              {/*      )*/}
              {/*    ) : (*/}
              {/*      <></>*/}
              {/*    )}{' '}*/}
              {/*    <Text size={'x3-large'}>*/}
              {/*      {sessionCustomerDetails.bookedCapacity ? sessionCustomerDetails.bookedCapacity : 0}*/}
              {/*      {sessionCustomerDetails.capacity && '/' + sessionCustomerDetails.capacity}*/}
              {/*    </Text>*/}
              {/*  </div>*/}
              {/*  <div>*/}
              {/*    {sessionCustomerDetails.capacity ? (*/}
              {/*      availableSlots > 0 ? (*/}
              {/*        <Text color={'green'}>*/}
              {/*          This session has{' '}*/}
              {/*          <b>*/}
              {/*            {availableSlots} available slot{availableSlots !== 1 && 's'}*/}
              {/*          </b>*/}
              {/*        </Text>*/}
              {/*      ) : (*/}
              {/*        <Text color={'red-dark'}>*/}
              {/*          This session is a <b>maximum capacity.</b> No more customers can be added*/}
              {/*        </Text>*/}
              {/*      )*/}
              {/*    ) : (*/}
              {/*      <></>*/}
              {/*    )}*/}
              {/*  </div>*/}
              {/*</div>*/}

              <div className={'shadow-container rounded-big mb-medium'}>
                <div className={'flex-row justify-end'}>
                  <div className={'bg-white'} />
                  <div className={'text-align-right bg-tertiary rounded-big-top ph-small'}>
                    <HyperlinkButton
                      weight={'bold'}
                      hoverUnderline={false}
                      onClick={() => this._handleCollapseDetails(!isSessionDetailsCollapsed)}
                    >
                      <Text color={'tertiary'} size={'x-small'}>
                        {isSessionDetailsCollapsed ? 'Expand' : 'Collapse'}
                      </Text>
                      <Icon
                        type={isSessionDetailsCollapsed ? 'plus' : 'minus'}
                        className={'ml-small text-size-x-small'}
                      />
                    </HyperlinkButton>
                  </div>
                </div>
                <div className={'bg-tertiary p-x-small'}>
                  <Row
                    gutter={8}
                    type={'flex'}
                    onClick={
                      isSessionDetailsCollapsed
                        ? () => this._handleCollapseDetails(!isSessionDetailsCollapsed)
                        : () => false
                    }
                    className={`${isSessionDetailsCollapsed && 'cursor-pointer'}`}
                  >
                    <Col span={requiresApprovalBookings.length > 0 ? 12 : 24}>
                      <div
                        className={`bg-white rounded-big shadow-container bordered border-quaternary ph-large ${
                          isSessionDetailsCollapsed ? 'pv-small' : 'pv-medium'
                        } height-full`}
                      >
                        <div>
                          {isSessionDetailsCollapsed ? (
                            <Row className={'width-full'}>
                              <Col span={12}>
                                <Text color={'secondary'}>
                                  Revenue (Est.):{' '}
                                  <Text weight={'bold'}>{CommonUtils.formatPrice(estimatedRevenue)}</Text>
                                </Text>
                              </Col>
                              <Col span={12}>
                                <Text color={'secondary'}>
                                  Revenue (actual):{' '}
                                  <Text weight={'bold'}>
                                    {CommonUtils.formatPrice(
                                      sessionCustomerDetails && sessionCustomerDetails.totalProcessedAmount
                                    )}
                                  </Text>
                                </Text>
                              </Col>
                            </Row>
                          ) : (
                            <Text size={'x2-large'} weight="bold">
                              Revenue for this session
                            </Text>
                          )}
                        </div>
                        {!isSessionDetailsCollapsed && (
                          <Row type={'flex'} align={'middle'} className={'mv-12 line-height-120'}>
                            <Col span={12}>
                              <Text size={'x2-large'}>{CommonUtils.formatPrice(estimatedRevenue)}</Text>
                              <br />
                              <Text color={'tertiary'}>Revenue (Forecasted)</Text>
                              <Tooltip2
                                position="top"
                                content={'Total estimated revenue for all bookings in this session.'}
                              >
                                <Icon type={'info-circle'} className={'text-color-tertiary ml-small'} />
                              </Tooltip2>
                            </Col>
                            <Col span={12}>
                              <Text size={'x2-large'}>
                                {CommonUtils.formatPrice(
                                  sessionCustomerDetails && sessionCustomerDetails.totalProcessedAmount
                                )}
                              </Text>
                              <br />
                              <Text color={'tertiary'}>Revenue (Actual)</Text>
                              <Tooltip2 position="top" content={'Sum of all line items that have been processed.'}>
                                <Icon type={'info-circle'} className={'text-color-tertiary ml-small'} />
                              </Tooltip2>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </Col>
                    {requiresApprovalBookings.length > 0 && (
                      <Col span={12}>
                        <div
                          className={`bg-blue-lightest rounded-big shadow-container ph-large ${
                            isSessionDetailsCollapsed ? 'pv-small' : 'pv-medium'
                          } height-full`}
                        >
                          <div>
                            <Text weight="bold" size={!isSessionDetailsCollapsed && 'x2-large'}>
                              Booking ready for approval{' '}
                              {isSessionDetailsCollapsed && (
                                <Text weight="bold" color={'secondary'}>
                                  ({requiresApprovalBookings.length})
                                </Text>
                              )}
                            </Text>
                          </div>
                          {!isSessionDetailsCollapsed && (
                            <Row type={'flex'} align={'middle'} className={'mv-medium'}>
                              <Col span={8}>
                                <SubTitle color={'tertiary'}>Bookings</SubTitle>
                                <Text size={'x2-large'}>{requiresApprovalBookings.length}</Text>
                              </Col>
                              <Col span={8}>
                                <SubTitle color={'tertiary'}>Total</SubTitle>
                                <Text size={'x2-large'}>
                                  {CommonUtils.formatPrice(
                                    _.reduce(
                                      requiresApprovalBookings,
                                      (acc, booking) => acc + Number(booking.billingTotal),
                                      0
                                    )
                                  )}
                                </Text>
                              </Col>
                              <Col span={8} className={'text-align-right'}>
                                <PrimaryButton
                                  size={'large'}
                                  onClick={(event) => this._onApproveAll(event, requiresApprovalBookings)}
                                >
                                  Approve all
                                </PrimaryButton>
                              </Col>
                            </Row>
                          )}
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </div>

              <div className={'bg-white rounded-big ph-large pv-large mb-x3-large'}>
                <div className="flex-row justify-between align-center mb-large">
                  <div>
                    <div className="mb-x2-small">
                      <Text weight="bold" size={'x2-large'}>
                        Bookings{' '}
                        <span className="text-weight-regular">
                          ({sessionCustomerDetails.bookedCapacity}
                          {availableSlots ? '/' + availableSlots : ''})
                        </span>
                      </Text>
                    </div>
                    <Text>Bookings for this session</Text>
                  </div>
                  <div>
                    <PrimaryButton
                      size="large"
                      icon={'plus'}
                      onClick={this._openAddCustomerModal}
                      disabled={
                        (sessionCustomerDetails.capacity && availableSlots <= 0) ||
                        selectedSession.sessionStatus === GroupServiceSessionStatus.CANCELLED ||
                        selectedSession.sessionStatus === GroupServiceSessionStatus.CLOSED
                      }
                    >
                      Add booking
                    </PrimaryButton>
                  </div>
                </div>

                <Divider className="m-none" />

                <div className="flex-row pb-small align-center mt-medium">
                  <div className="mr-x2-large mb-small" style={{ minWidth: '300px' }}>
                    <Search
                      onChange={this._onEnterSearchText}
                      loading={this.state.isSearching}
                      placeholder="Search for"
                      allowClear={true}
                    />
                  </div>
                  <FilterSection
                    availableFilters={availableFilters}
                    filters={sessionFilters ? sessionFilters : []}
                    onChangeFilter={this._onChangeFilter}
                    displayTimezone={null}
                    containerClassName={'mb-none'}
                  />
                </div>

                <div>
                  <Row className="mv-medium pv-medium pr-small bordered-bottom" type={'flex'} align={'middle'}>
                    <Col span={1}>
                      <Checkbox
                        checked={isAllChecked}
                        onChange={this._setCheckAllCustomers}
                        indeterminate={isCheckIndeterminate}
                      />
                    </Col>
                    <Col span={23} className={'ph-medium'}>
                      <Row type={'flex'} align={'middle'}>
                        <Col span={5}>
                          <SubTitle>Customer</SubTitle>
                        </Col>
                        <Col span={3}>
                          <SubTitle>Booking time</SubTitle>
                        </Col>
                        <Col span={4}>
                          <SubTitle>Booking Status</SubTitle>
                        </Col>
                        <Col span={4}>
                          <SubTitle>Payment status</SubTitle>
                        </Col>
                        <Col span={3}>
                          <SubTitle>Forecast</SubTitle>
                        </Col>
                        <Col span={1} className="pr-medium"></Col>
                        <Col span={3} className="pr-medium"></Col>
                        <Col span={1} className="pr-medium"></Col>
                      </Row>
                    </Col>
                  </Row>
                  {isLoading ? (
                    <SpinningLoader size={50} message={'Fetching bookings...'} />
                  ) : sessionCustomerCustomerBookings && sessionCustomerCustomerBookings.length > 0 ? (
                    _.map(sessionCustomerCustomerBookings, (customer, idx) => (
                      <CustomerItem
                        customerItem={customer}
                        timezone={selectedSession.timezone}
                        handleItemClick={this._handleItemClick}
                        openBookingBillingPanel={this._openBookingBillingPanel}
                        closeBookingBillingPanel={this._closeBookingBillingPanel}
                        isBillingPanelOpened={
                          !!_.find(
                            this.state.openedBookingBillings,
                            (attendanceId) => attendanceId === customer.attendanceId
                          )
                        }
                        isBillingPanelLoading={this.state.isLoadingBookingBillings}
                        billings={_.find(
                          this.props.sessionBookingsBilling,
                          (billing) => billing.attendanceId === customer.attendanceId
                        )}
                        openSessionFull={this._openSessionFullModal}
                        history={history}
                        refreshList={this.refreshList}
                        key={idx}
                      />
                    ))
                  ) : (
                    <CustomerEmptyState />
                  )}

                  {/*{selectedSessions && selectedSessions.length > 0 && (*/}
                  {/*  <BottomActionSheet*/}
                  {/*    selectedItems={selectedSessions}*/}
                  {/*    onDeselect={this._onActionDeselect}*/}
                  {/*    refreshItemListings={this._refreshList}*/}
                  {/*  />*/}
                  {/*)}*/}
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession,
  sessionCustomerDetails: state.groupServiceStore.sessionCustomerDetails,
  sessionCustomerCustomerBookings: state.groupServiceStore.sessionCustomerCustomerBookings,
  sessionFilters: state.groupServiceStore.sessionFilters,
  resetSessionCustomers: state.groupServiceStore.resetSessionCustomers,
  sessionBookingsBilling: state.groupBookingsStore.sessionBookingsBilling
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchSessionCustomerDetails: dispatch.groupServiceStore.doFetchSessionCustomerDetails,
  doFetchSessionCustomerDetailsFull: dispatch.groupServiceStore.doFetchSessionCustomerDetailsFull,
  setSessionFilters: dispatch.groupServiceStore.setSessionFilters,
  setResetSessionCustomers: dispatch.groupServiceStore.setResetSessionCustomers,
  setSessionCustomerBookings: dispatch.groupServiceStore.setSessionCustomerBookings,
  setSelectedSession: dispatch.groupServiceStore.setSelectedSession,
  doFetchSessionBookingBillings: dispatch.groupBookingsStore.doFetchSessionBookingBillings
});

export default connect(mapState, mapDispatch)(Form.create<ISessionCustomerViewProps>()(SessionCustomerView));
