import { Icon } from 'antd';
import React, { PureComponent } from 'react';

const defaultColor = 'blue-action';

interface NavMenuButtonProps {
  icon?: string;
  isSelected?: boolean;
  onClick?: any;
  targetTab?: string;
  currentTab?: string;
  color?: string;
  text?: string;
}

class NavMenuButton extends PureComponent<NavMenuButtonProps> {
  render() {
    const {
      text,
      icon = 'setting',
      onClick = () => console.log('not implemented yet'),
      targetTab = '',
      currentTab = '',
      color = defaultColor,
    } = this.props;

    const finalIsSelected = currentTab === targetTab;

    return (
      <div
        className={`pl-medium pv-small mb-x-small ${
          finalIsSelected
            ? 'bg-' + color + ' text-color-white hover-text-color-white '
            : ' hover-bg-forest-lightest hover-text-color-black '
        } inline-block rounded width-full cursor-pointer`}
        onClick={() => onClick(targetTab)}
      >
        <Icon type={icon} className="mr-medium dimmer" />
        {text}
      </div>
    );
  }
}

export default NavMenuButton;
