import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { IGroupServiceTimesheetShiftSlot } from 'interfaces/service-interfaces';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { notification } from 'antd';

interface IApproveTimesheetModalProps {
  isOpen: boolean;
  onClose: (isRefresh: boolean) => void;
  selectedShiftSlot: IGroupServiceTimesheetShiftSlot;
  selectedSessionId: string;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
  doApproveShiftSlots: typeof dispatch.groupServiceStore.doApproveShiftSlots;
}

interface IApproveTimesheetModalState {
  title: string;
  step: number;
  canCloseOutside: boolean;
  isLoading: boolean;
  isRefreshAfterClose: boolean;
}

class ApproveTimesheetModal extends Component<IApproveTimesheetModalProps, IApproveTimesheetModalState> {
  state = {
    title: 'Approve timesheets',
    step: 1,
    canCloseOutside: true,
    isLoading: false,
    isRefreshAfterClose: false
  };

  private _onSubmitApprove = async () => {
    const { selectedShiftSlot, selectedGroupService, selectedSessionId, doApproveShiftSlots } = this.props;
    this.setState({ isLoading: true, canCloseOutside: false });

    console.log('here')

    try {
      const payload = {
        serviceId: selectedGroupService.serviceId,
        sessionId: selectedSessionId,
        shiftSlots: [selectedShiftSlot]
      };

      await doApproveShiftSlots(payload);
      this.setState({
        isLoading: false,
        title: 'Timesheet approved',
        step: 2,
        canCloseOutside: true,
        isRefreshAfterClose: true
      });
    } catch (e) {
      notification.error({ message: 'Oops, an error has occurred, please try again.' });
      this.setState({ isLoading: false, canCloseOutside: true });
    }
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    onClose(this.state.isRefreshAfterClose);
    this.setState({
      title: 'Approve timesheets',
      step: 1,
      canCloseOutside: true,
      isLoading: false,
      isRefreshAfterClose: false
    });
  };

  private _renderContent = () => {
    const { step } = this.state;

    if (step === 1) {
      return (
        <>
          <Paragraph>
            You're about to approve <b>1</b> shift for <b>1</b> team member.
          </Paragraph>
          <Paragraph>Would you like to proceed?</Paragraph>

          <ActionModalFooter>
            <SecondaryButton onClick={this._onCloseModal} disabled={this.state.isLoading} size="large">
              Cancel
            </SecondaryButton>
            <PrimaryButton
              onClick={this._onSubmitApprove}
              loading={this.state.isLoading}
              size="large"
              className="ml-medium"
            >
              Approve
            </PrimaryButton>
          </ActionModalFooter>
        </>
      );
    }

    if (step === 2) {
      return (
        <>
          <Paragraph>
            You've have successfully approved <b>1 shift</b> for <b>1 team member</b>.
          </Paragraph>
          <ActionModalFooter>
            <PrimaryButton onClick={this._onCloseModal} size="large">
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </>
      );
    }
  };

  render() {
    return (
      <ActionModal
        isOpen={this.props.isOpen}
        title={this.state.title}
        canCloseOutside={this.state.canCloseOutside}
        onClose={this._onCloseModal}
      >
        {this._renderContent()}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({ selectedGroupService: state.groupServiceStore.selectedGroupService });
const mapDispatch = (dispatch: IRootDispatch) => ({
  doApproveShiftSlots: dispatch.groupServiceStore.doApproveShiftSlots
});

export default connect(
  mapState,
  mapDispatch
)(ApproveTimesheetModal);
