import React, { Component } from 'react';

import _ from 'lodash';

import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { RouteComponentProps } from 'react-router-dom';

import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';

import themeVariables from 'theme/theme-variables';
// Custom CSS
import './css/plan-payment-listing.css';

import { PlanPayListNavigationSection } from './sections/PlanPayListNavigationSection';
import PlanPayListReadyToBeClaimSection from 'views/plan-management/payments/sections/PlanPayReadyToBeClaimSection';
import PlanPayListClaimedSection from 'views/plan-management/payments/sections/PlanPayListClaimedSection';
// import PlanPayListInvoicesSection from 'views/plan-management/payments/sections/PlanPayListPaidInvoicesSection';
import PlanPayListPaymentIssuesSection from 'views/plan-management/payments/sections/PlanPayListPaymentIssuesSection';
// import PlanPayListClaimBatchesSection from 'views/plan-management/payments/sections/PlanPayListClaimBatchesSection';
import PlanPayListWaivedSection from 'views/plan-management/payments/sections/PlanPayListWaivedSection';
import PlanPayListRejectedSection from 'views/plan-management/payments/sections/PlanPayListRejectedSection';
import { PLAN_PAYMENTLIST_FILTERCONFIGS } from 'views/plan-management/payments/components/planPayListFilterConfig';
import PlanPayListBatchSection from 'views/plan-management/payments/sections/PlanPayListBatchSection';
import PlanPayListPaidInvoiceSection from 'views/plan-management/payments/sections/PlanPayListPaidInvoicesSection';

// Default Body Height
const { bodyHeight } = themeVariables;

//endregion

interface IPlanPaymentViewProps extends RouteComponentProps, FormComponentProps {
  selectedPlanPaymentFilterKey: typeof state.planManagementStore.selectedPlanPaymentFilterKey;
  setSelectedPlanPaymentFilterKey: typeof dispatch.planManagementStore.setSelectedPlanPaymentFilterKey;
  setPlanManagementInvoiceListFilter: typeof dispatch.planManagementStore.setPlanManagementInvoiceListFilter;
  doFetchReadyForClaimInvoices: typeof dispatch.planManagementStore.doFetchReadyForClaimInvoices;
  setPlanManagementInvoiceList: typeof dispatch.planManagementStore.setPlanManagementInvoiceList;
  doFetchPlanManagementInvoiceDetails: typeof dispatch.planManagementStore.doFetchPlanManagementInvoiceDetails;
  doFetchReadyForClaimOverview: typeof dispatch.planManagementStore.doFetchReadyForClaimOverview;
  doFetchRejectedInvoices: typeof dispatch.planManagementStore.doFetchRejectedInvoices;
  doFetchPaidInvoices: typeof dispatch.planManagementStore.doFetchPaidInvoices;
  setSelectedItem: typeof dispatch.planManagementStore.setSelectedItem;
  planManagementInvoiceList: typeof state.planManagementStore.planManagementInvoiceList;
  planManagementInvoiceListFilter: typeof state.planManagementStore.planManagementInvoiceListFilter;
  selectedItem: typeof state.planManagementStore.selectedItem;
  planManagementLineItemList: typeof state.planManagementStore.planManagementLineItemList;
  planManagementOverviewData: typeof state.planManagementStore.planManagementOverviewData;

  isSelectedAll: boolean;

  // selectedPayments: IBillingBooking[];
  // selectedInvoices: IBillingBooking[];
  // selectedBatches: IBillingBooking[];
  // selectedRejected: IBillingBooking[];

  history: any;
}

class PlanPaymentView extends Component<IPlanPaymentViewProps, any> {
  _setFilter = async (filterKey) => {
    const { setSelectedPlanPaymentFilterKey } = this.props;

    setSelectedPlanPaymentFilterKey(filterKey);
  };

  componentDidMount = async () => {
    const {
      selectedPlanPaymentFilterKey,
      setSelectedPlanPaymentFilterKey,
      setPlanManagementInvoiceListFilter
    } = this.props;

    if (_.isEmpty(selectedPlanPaymentFilterKey)) {
      const defaultFilterKey = 'READY_FOR_CLAIM';
      await setSelectedPlanPaymentFilterKey(defaultFilterKey);
      await setPlanManagementInvoiceListFilter(PLAN_PAYMENTLIST_FILTERCONFIGS[defaultFilterKey].filters);
    }
  };

  render() {
    // Used to determine what filter to apply.
    let { selectedPlanPaymentFilterKey = 'READY_FOR_CLAIM' } = this.props;

    if (_.isEmpty(selectedPlanPaymentFilterKey)) {
      selectedPlanPaymentFilterKey = 'READY_FOR_CLAIM';
    }

    const currentFilterConfig = PLAN_PAYMENTLIST_FILTERCONFIGS[selectedPlanPaymentFilterKey];

    return (
      <div className="bg-red bordered-top border-tertiary" style={{ height: bodyHeight, borderWidth: '2px' }}>
        <div className="flex-row height-full">
          <PlanPayListNavigationSection currentFilterConfig={currentFilterConfig} setFilter={this._setFilter} />
          {selectedPlanPaymentFilterKey === 'READY_FOR_CLAIM' && (
            <PlanPayListReadyToBeClaimSection {...this.props} currentFilterConfig={currentFilterConfig} />
          )}
          {selectedPlanPaymentFilterKey === 'CLAIMED' && (
            <PlanPayListClaimedSection {...this.props} currentFilterConfig={currentFilterConfig} />
          )}
          {selectedPlanPaymentFilterKey === 'PAID' && (
            <PlanPayListPaidInvoiceSection {...this.props} currentFilterConfig={currentFilterConfig} />
          )}
          {selectedPlanPaymentFilterKey === 'PAYMENT_ISSUES' && (
            <PlanPayListPaymentIssuesSection {...this.props} currentFilterConfig={currentFilterConfig} />
          )}
          {selectedPlanPaymentFilterKey === 'CLAIM_BATCHES' && (
            <PlanPayListBatchSection {...this.props} currentFilterConfig={currentFilterConfig} />
          )}
          {/*{selectedFilterKey === 'WAITING_TO_BE_PROCESSED' && (*/}
          {/*  <PayListDetailsSection {...this.props} currentFilterConfig={currentFilterConfig} />*/}
          {/*)}*/}
          {/*{selectedPlanPaymentFilterKey === 'CLAIM_BATCHES' && (*/}
          {/*  <PlanPayListClaimBatchesSection {...this.props} currentFilterConfig={currentFilterConfig} />*/}
          {/*)}*/}

          {selectedPlanPaymentFilterKey === 'REJECTED' && (
            <PlanPayListRejectedSection {...this.props} currentFilterConfig={currentFilterConfig} />
          )}
          {selectedPlanPaymentFilterKey === 'WAIVED' && (
            <PlanPayListWaivedSection {...this.props} currentFilterConfig={currentFilterConfig} />
          )}
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedPlanPaymentFilterKey: state.planManagementStore.selectedPlanPaymentFilterKey,
  planManagementInvoiceList: state.planManagementStore.planManagementInvoiceList,
  planManagementInvoiceListFilter: state.planManagementStore.planManagementInvoiceListFilter,
  selectedItem: state.planManagementStore.selectedItem,
  planManagementLineItemList: state.planManagementStore.planManagementLineItemList,
  planManagementOverviewData: state.planManagementStore.planManagementOverviewData
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setSelectedPlanPaymentFilterKey: dispatch.planManagementStore.setSelectedPlanPaymentFilterKey,
  doFetchReadyForClaimInvoices: dispatch.planManagementStore.doFetchReadyForClaimInvoices,
  setPlanManagementInvoiceListFilter: dispatch.planManagementStore.setPlanManagementInvoiceListFilter,
  setPlanManagementInvoiceList: dispatch.planManagementStore.setPlanManagementInvoiceList,
  doFetchPlanManagementInvoiceDetails: dispatch.planManagementStore.doFetchPlanManagementInvoiceDetails,
  doFetchReadyForClaimOverview: dispatch.planManagementStore.doFetchReadyForClaimOverview,
  doFetchRejectedInvoices: dispatch.planManagementStore.doFetchRejectedInvoices,
  doFetchPaidInvoices: dispatch.planManagementStore.doFetchPaidInvoices,
  setSelectedItem: dispatch.planManagementStore.setSelectedItem
});

export default connect(
  mapState,
  mapDispatch
)(PlanPaymentView);
