import { Col, Icon, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import { FieldLabel, FieldValueText, Text } from 'common-components/typography';
import { ICustomer } from 'interfaces/customer-interfaces';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IRootState, state } from 'src/stores/rematch/root-store';
import CreateEditContactModal from './CreateEditContactModal';

interface ICustomerDetailsContactsPanelProps {
  selectedCustomer: ICustomer;
  history: any;
  openConnectionManager: (connected) => void;
  hasEditPermission: boolean;
  portalUser: typeof state.authStore.portalUser;
}

interface ICustomerDetailsContactsPanelState {
  isOpen: boolean;
  selectedContactId: string;
  isOpenGuardianModel: boolean;
}

class CustomerDetailsContactsPanel extends Component<
  ICustomerDetailsContactsPanelProps,
  ICustomerDetailsContactsPanelState
> {
  state = { isOpen: false, selectedContactId: '', isOpenGuardianModel: false };

  private _closeCreateEditContactModal = () => {
    this.setState({ isOpen: false });
  };

  private _closeCreateGuardianModel = () => {
    this.setState({ isOpenGuardianModel: false });
  };

  private _openGuardianModel = () => {
    this.setState({ isOpenGuardianModel: true });
  };

  private _openCreateEditContactModal = (userContactId) => {
    if (userContactId !== '') {
      this.setState({ isOpen: true, selectedContactId: userContactId });
    } else {
      this.setState({ isOpen: true, selectedContactId: '' });
    }
  };

  private _getConnectionStatusContent = (connectionStatus) => {
    const connectionTextColor = connectionStatus ? (connectionStatus === 'CONNECTED' ? 'green' : 'black') : 'secondary';
    // const toolTipContent = 'This is a tooltip';

    return (
      <Text color={connectionTextColor}>
        {!_.isEmpty(connectionStatus) && connectionStatus === 'CONNECTED'
          ? 'Connected'
          : connectionStatus === 'PENDING'
          ? 'Request sent'
          : 'Not connected'}{' '}
        {/*<Tooltip content={toolTipContent}>*/}
        {/*  <Icon type="question-circle" className="text-color-blue mr-x-small" theme="filled" />*/}
        {/*</Tooltip>*/}
      </Text>
    );
  };

  private _goToCustomer = async (userId) => {
    const { history } = this.props;
    history.push(`/customer/details/${userId}`);
  };

  render() {
    const { selectedCustomer, hasEditPermission } = this.props;

    if (!_.isEmpty(selectedCustomer)) {
      return (
        <div>
          <CreateEditContactModal
            closeCreateEditContactModal={() => {
              this._closeCreateEditContactModal();
            }}
            selectedCustomer={selectedCustomer}
            isOpen={this.state.isOpen}
            selectedContactId={this.state.selectedContactId}
          />

          <Row type={'flex'} justify={'space-between'} align={'bottom'}>
            <Col>
              <Title level={2} className={'m-none'}>
                Contacts
              </Title>
              <Text type={'secondary'}>Primary and other contacts of the customer.</Text>
            </Col>
            <Col className={'align-center'}>
              <div className={'flex-row-reverse align-center'}>
                {hasEditPermission && (
                  <PrimaryButton icon={'user'} onClick={() => this._openCreateEditContactModal('')}>
                    Add Contact
                  </PrimaryButton>
                )}
              </div>
            </Col>
          </Row>
          <div className={'pv-large'}>
            {!_.isEmpty(selectedCustomer.emergencyContacts) ? (
              selectedCustomer.emergencyContacts.map((contact, index) => {
                const btopcls =
                  index === 0
                    ? 'bordered-top bordered-bottom align-center flex pv-medium ph-large'
                    : 'bordered-bottom align-center flex pv-medium ph-large';
                return (
                  <Row className={btopcls} key={index}>
                    <Col span={7}>
                      <b>{contact.fullName}</b>
                      <div>
                        <Text>{contact.isPrimaryCarer ? 'Primary Carer' : null}</Text>
                      </div>
                    </Col>
                    <Col span={7}>
                      <FieldLabel text={'Contact Information'} />
                      <div>
                        <Row>
                          <Col span={2}>
                            <Icon type={'mobile'} />
                          </Col>
                          <Col span={12}>
                            <FieldValueText
                              text={
                                contact.phoneNumber
                                  ? parsePhoneNumberFromString(
                                      contact.phoneNumber,
                                      contact.phoneNumberCountryCode
                                    ).format('INTERNATIONAL')
                                  : 'Not Set'
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Row>
                          <Col span={2}>
                            <Icon type={'mail'} />
                          </Col>
                          <Col span={12}>
                            <FieldValueText text={contact.email !== null ? contact.email : 'Not Set'} />
                          </Col>
                        </Row>{' '}
                      </div>
                    </Col>
                    <Col span={7}>
                      <FieldLabel text={'Relationship'} />
                      <div>
                        <FieldValueText text={contact.relationToUser !== null ? contact.relationToUser : 'Not Set'} />
                      </div>
                    </Col>
                    <Col span={3}>
                      <GhostButton onClick={() => this._openCreateEditContactModal(contact.userContactId)}>
                        Edit
                      </GhostButton>
                    </Col>
                  </Row>
                );
              })
            ) : (
              <Row>
                <Col className=" bg-white">No Contacts found.</Col>
              </Row>
            )}
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser
});

export default connect(mapState, null)(CustomerDetailsContactsPanel);
