import React, { Component } from 'react';
import CenteredLayout from 'layouts/CenteredLayout';
import { Title } from 'common-components/typography';
import CustomerListingPanel from './listing/CustomerListingPanel';
import * as H from 'history';
import { PrimaryButton } from 'common-components/buttons';
import AddNewCustomerWizardModal from 'views/customers/listing/components/AddNewCustomerWizardModal';
import _ from 'lodash';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState } from 'src/stores/rematch/root-store';

interface ICustomerLandingViewProps {
  history: H.History;
  setRefreshList: typeof dispatch.customersStore.setRefreshList;
}

interface ICustomerLandingViewState {
  isLoading: boolean;
  openAddNewCustomerWizardModal: boolean;
}

class CustomerLandingView extends Component<ICustomerLandingViewProps, ICustomerLandingViewState> {
  state = {
    isLoading: false,
    openAddNewCustomerWizardModal: false
  };

  private _openAddNewCustomerWizardModal = () => this.setState({ openAddNewCustomerWizardModal: true });

  private _closeAddNewCustomerWizardModal = (serviceData = null) => {
    this.setState({ openAddNewCustomerWizardModal: false });
    this.props.setRefreshList(true);
  };

  render() {
    const { history } = this.props;

    return (
      <CenteredLayout backgroundColor="white" bordered>
        <div className="flex-row justify-between align-center">
          <Title level={3} className="mv-none" lineHeight={150}>
            Customers
          </Title>
          <PrimaryButton onClick={this._openAddNewCustomerWizardModal} size="large" icon="plus">
            Add new customer
          </PrimaryButton>
        </div>

        <AddNewCustomerWizardModal
          isOpen={this.state.openAddNewCustomerWizardModal}
          history={history}
          closeAddCustomerModal={this._closeAddNewCustomerWizardModal}
        />

        <div className="mb-x-large" style={{ position: 'sticky', top: '0px', zIndex: 10 }}>
          <CustomerListingPanel history={history} />
        </div>
      </CenteredLayout>
    );
  }
}

const mapState = (state: IRootState) => ({});

const mapDispatch = (dispatch: IRootDispatch) => ({
  setRefreshList: dispatch.customersStore.setRefreshList
});

export default connect(
  mapState,
  mapDispatch
)(CustomerLandingView);
