import { init, RematchRootState } from '@rematch/core';

import _ from 'lodash';

import createRematchPersist from '@rematch/persist';
// import createLoadingPlugin from '@rematch/loading';

// Note : Add models inside /models, and import it here
import authStore from 'stores/rematch/models/auth-store';
import bookingsStore from 'stores/rematch/models/bookings-store';
import companyStore from 'stores/rematch/models/company-store';
import customersStore from 'stores/rematch/models/customers-store';
import navigationStore from 'stores/rematch/models/navigation-store';
import profileStore from 'stores/rematch/models/profile-store';
import servicesStore from 'stores/rematch/models/services-store';
import storiesStore from 'stores/rematch/models/stories-store';
import teamStore from 'stores/rematch/models/team-store';
import billingsStore from 'stores/rematch/models/billings-store';
import notificationsStore from 'stores/rematch/models/notifications-store';

// Messages
import messagesStore from 'stores/rematch/models/messages-store';
import channelsStore from 'stores/rematch/models/channels-store';

// Account management
import accountStore from 'stores/rematch/models/account-store';

// Plan management
import planManagementStore from 'stores/rematch/models/plan-management-store';

// Group service
import groupServiceStore from 'stores/rematch/models/group-service-store';

// Group booking
import groupBookingsStore from 'stores/rematch/models/group-bookings-store';

// Roster view/management
import rosterStore from 'stores/rematch/models/roster-store';

import { createTransform } from 'redux-persist';

// The following intercepts the persist hydrated state, and transforms it to a new state.
// Why? Because isAppLoaded is persisted as true; it's cleaner to reset it to false every time it's re-hydrated.
const SetTransform = createTransform(
  null,
  (outbound) => {
    // clone state and reset app loaded property to false.
    const newState: any = _.cloneDeep(outbound);

    newState.isAppLoaded = false;

    // We can add any state that we wish to reset here, eg
    // newState.currentUser = null;

    return newState;
  },
  { whitelist: ['authStore'] }
);

// Persist Plugin for Rematch
const persistPlugin = createRematchPersist({
  // storage: AsyncStorage,
  // whitelist: ['userStore', 'authStore']
  whitelist: ['authStore'],
  transforms: [SetTransform]
  // whitelist: ['authStore', 'servicesStore']
  // blacklist: ['non-auth'],{}
  // throttle: 5000,
  // version: 1,
});

// Loading indicator Plugin for Rematch
// const loadingPlugin = createLoadingPlugin({
//   name: 'loading',
//   asNumber: false
//   // whitelist: [],
//   // blacklist: [],
// });

const models = {
  // Add imported stores here
  authStore,
  bookingsStore,
  companyStore,
  customersStore,
  navigationStore,
  profileStore,
  servicesStore,
  storiesStore,
  teamStore,
  billingsStore,
  notificationsStore,
  messagesStore,
  channelsStore,
  accountStore,
  planManagementStore,
  groupServiceStore,
  groupBookingsStore,
  rosterStore
};

const rootStore = init({
  models,
  plugins: [persistPlugin]

  // take out loadingPlugin. Not very useful
  // plugins: [persistPlugin, loadingPlugin]
});

export default rootStore;

export type IStore = typeof rootStore;
export type IRootDispatch = typeof rootStore.dispatch;
export type IRootState = RematchRootState<typeof models>;

export var dispatch: IRootDispatch;
export var state: IRootState;
