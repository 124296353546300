import React, { Component } from 'react';
import { Col, Row } from 'antd';
import { Paragraph } from 'common-components/typography';
import ActionModal from 'common-components/modal/ActionModal';
import { PrimaryButton } from 'common-components/buttons';

interface IErrorPriceExceedingInvoiceModalProps {
  closeModal: () => void;
  includesGst: boolean;
  isOpen: boolean;
}

class ErrorPriceExceedingInvoiceModal extends Component<IErrorPriceExceedingInvoiceModalProps> {
  render() {
    return (
      <div>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this.props.closeModal}
          title={'Error'}
          showCloseButton={true}
          width={'large'}
        >
          <div>
            <Paragraph>
              Adding this line item will result in the total value of claims for this invoice being greater than the
              invoice total{this.props.includesGst && ' (Excluding GST)'}.
            </Paragraph>
            <Paragraph>
              Please double check the claim/invoice total before adding this line item (Waived line items do not
              contribute to the claim total)
            </Paragraph>
            <div className={'mb-small mt-x2-large'}>
              <Row type={'flex'} className={'justify-end'}>
                <Col>
                  <PrimaryButton size="large" onClick={this.props.closeModal}>
                    Close
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </div>
        </ActionModal>
      </div>
    );
  }
}

export default ErrorPriceExceedingInvoiceModal;
