import React, { Component } from 'react';
import { FieldLabel, Title, Text } from 'common-components/typography';
import { Col, Empty, Input, Skeleton, Button, Icon, Popover, Row } from 'antd';
import { ProgressBar } from '@blueprintjs/core';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import _ from 'lodash';
import { GridHeader, GridRow } from 'common-components/grids';
import { ArchiveDebtorFrom, FilterType } from 'utilities/enum-utils';
import { FilterSection } from 'common-components/filter';
import CommonUtils from 'utilities/common-utils';
import CenteredLayout from 'layouts/CenteredLayout';
import { HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import AddDebtorModal from './components/DebtorManagementComponents/AddDebtorModal';
import InfiniteScrollLoading from 'common-components/loading/InfiniteScrollLoading';
import ArchiveDebtorModal from './components/DebtorManagementComponents/ArchiveDebtorModal';
import copy from 'copy-to-clipboard';
import TextTag from 'common-components/tags/TextTag';
import UnarchiveDebtorModal from './components/DebtorManagementComponents/UnarchiveDebtorModal';

const { Search } = Input;

interface IDebtorManagementViewProps extends RouteComponentProps {
  debtors: typeof state.accountStore.debtors;
  doFetchDebtorList: typeof dispatch.accountStore.doFetchDebtorList;
}

interface IDebtorManagementViewState {
  isLoading: boolean;
  isSearching: boolean;
  isOpenAddDebtorModal: boolean;
  debtorFilters: any;
  page: number;
  pageSize: number;
  pageTimestamp: Date;
  isLoadingInfiniteScrolling: boolean;
  isOpenArchiveModal: boolean;
  isOpenUnarchiveModal: boolean;
  selectedDebtor: any;
}

const availableFilters = [FilterType.CUSTOMER];

const ListEmptyState = () => (
  <div className="flex-1 bg-white  align-center flex-column">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No Debtors found.
    </Text>{' '}
    <br /> <br />
    <Text color="secondary">All Debtors under this filter will appear here.</Text>
    <Text color="secondary">Try adjusting your filter, or refreshing the page.</Text>
  </div>
);

const defaultFilters = [
  {
    filter: FilterType.CUSTOMER,
    values: [],
    selectionLabel: CommonUtils.getFilterSettings(FilterType.CUSTOMER).fullSelectionName
  }
];

class DebtorManagementView extends Component<IDebtorManagementViewProps, IDebtorManagementViewState> {
  state = {
    isLoading: false,
    isSearching: false,
    isOpenAddDebtorModal: false,
    isOpenUnarchiveModal: false,
    debtorFilters: [],
    page: 1,
    pageSize: 20,
    pageTimestamp: new Date(),
    isLoadingInfiniteScrolling: false,
    isOpenArchiveModal: false,
    selectedDebtor: null
  };

  private _goToLandingPage = () => {
    this.props.history.push('/account/landing');
  };

  async componentDidMount() {
    const { doFetchDebtorList } = this.props;
    const { debtorFilters, page, pageSize, pageTimestamp } = this.state;
    this.setState({ isLoading: true, debtorFilters: defaultFilters });
    await doFetchDebtorList({
      filters: debtorFilters,
      page: page,
      pageSize: pageSize,
      pageTimestamp: pageTimestamp
    });
    this.setState({ isLoading: false });
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const { doFetchDebtorList } = this.props;
    const { debtorFilters } = this.state;

    if (!_.isEqual(prevState.debtorFilters, this.state.debtorFilters)) {
      this.setState({ isLoading: true });
      const pageTimestamp = new Date();
      this.setState({ isLoading: true, page: 1, pageTimestamp });

      await doFetchDebtorList({
        filters: debtorFilters,
        page: 1,
        pageSize: this.state.pageSize,
        pageTimestamp
      });

      this.setState({ isLoading: false });
    }
  };

  componentWillUnmount() {
    this.setState({ debtorFilters: defaultFilters });
  }

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _searchText = async (txt) => {
    const { doFetchDebtorList } = this.props;
    const { debtorFilters } = this.state;
    this.setState({ page: 1 });
    await doFetchDebtorList({
      page: 1,
      pageSize: this.state.pageSize,
      pageTimestamp: this.state.pageTimestamp,
      filters: debtorFilters,
      search: txt
    });

    this.setState({ isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onChangeFilter = (filters: Array<any>) => {
    this.setState({ debtorFilters: filters });
  };

  private _onCloseAddDebtorModal = () => {
    this.setState({ isOpenAddDebtorModal: false });
  };

  private _fetchMoreDebtors = async () => {
    const { doFetchDebtorList, debtors } = this.props;
    const { debtorFilters, page, pageSize, pageTimestamp } = this.state;
    const nextPage = page + 1;
    this.setState({ isLoadingInfiniteScrolling: true, page: nextPage });

    await doFetchDebtorList({
      filters: debtorFilters,
      page: nextPage,
      pageSize: pageSize,
      pageTimestamp: pageTimestamp
    });

    this.setState({ isLoadingInfiniteScrolling: false });
  };

  render() {
    const {
      isOpenAddDebtorModal,
      debtorFilters,
      isOpenArchiveModal,
      selectedDebtor,
      isOpenUnarchiveModal
    } = this.state;
    const { history, debtors } = this.props;
    return (
      <>
        <ArchiveDebtorModal
          debtor={selectedDebtor}
          isOpen={isOpenArchiveModal}
          archiveDebtorFrom={ArchiveDebtorFrom.LISTING}
          closeArchiveModal={() => this.setState({ isOpenArchiveModal: false })}
        />
        <UnarchiveDebtorModal
          debtor={selectedDebtor}
          history={history}
          isOpen={isOpenUnarchiveModal}
          unarchiveDebtorFrom={ArchiveDebtorFrom.LISTING}
          closeUnarchiveModal={() => this.setState({ isOpenUnarchiveModal: false })}
        />
        <AddDebtorModal history={history} isOpen={isOpenAddDebtorModal} onClose={this._onCloseAddDebtorModal} />
        <CenteredLayout backgroundColor="white" bordered={true}>
          <div className="flex-row justify-between">
            <Title level={3} weight="regular" className="mv-none">
              <span className={'cursor-pointer text-color-blue-action'} onClick={this._goToLandingPage}>
                {'Account >'}
              </span>{' '}
              Debtor management
            </Title>
            <PrimaryButton size="large" icon={'plus'} onClick={() => this.setState({ isOpenAddDebtorModal: true })}>
              Add debtor
            </PrimaryButton>
          </div>
          <div>
            <div style={{ position: 'sticky', top: '0px', zIndex: 10 }}>
              <div className="bg-white">
                <div className="flex-row align-center justify-start mv-medium">
                  <div>
                    <Search
                      size="large"
                      placeholder="Search"
                      onChange={this._onEnterSearchText}
                      loading={this.state.isSearching}
                      style={{ width: '250px' }}
                      allowClear={true}
                    />
                  </div>

                  <div className="ml-small">
                    <FilterSection
                      availableFilters={availableFilters}
                      filters={debtorFilters}
                      onChangeFilter={this._onChangeFilter}
                      displayTimezone={null}
                      displayMoreFilter={false}
                    />
                  </div>
                </div>
                <GridHeader bordered>
                  <Col span={5} className="bg-white">
                    <FieldLabel text={'Debtor'} />
                  </Col>
                  <Col span={4} className="bg-white">
                    <FieldLabel text={'Debtor ID'} />
                  </Col>
                  <Col span={6} className="bg-white">
                    <FieldLabel text={'Email'} />
                  </Col>
                  <Col span={4} className="bg-white">
                    <FieldLabel text={'Contact Number'} />
                  </Col>
                  <Col span={3} className="bg-white">
                    <FieldLabel text={'Customers'} />
                  </Col>
                  <Col span={2} className="bg-white">
                    <FieldLabel text={''} />
                  </Col>
                </GridHeader>
              </div>
            </div>
            {this.state.isLoading ? (
              <div className="">
                <div className="pv-large">
                  <ProgressBar />
                </div>
                <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
                <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
                <Skeleton active avatar title={true} paragraph={{ rows: 1 }} />
              </div>
            ) : !_.isEmpty(debtors) ? (
              <InfiniteScrollLoading
                hasMore={debtors.length >= this.state.page * this.state.pageSize}
                loadingElementId={'content-container'}
                loadMore={this._fetchMoreDebtors}
                loaderColSpan={7}
                loadingOffSet={60}
              >
                {_.map(debtors, (debtor) => {
                  return (
                    <GridRow key={debtor.debtorId} className={'cursor-arrow'}>
                      <Col span={5}>
                        <HyperlinkButton onClick={() => history.push(`/account/debtor-management/${debtor.debtorId}`)}>
                          {debtor.debtorName}
                        </HyperlinkButton>
                      </Col>
                      <Col span={4}>
                        <Text>{debtor.debtorNumber}</Text>
                      </Col>
                      <Col span={6}>
                        <Text>{debtor.debtorEmail}</Text>
                      </Col>
                      <Col span={4}>
                        <Text>{debtor.debtorContactNumber}</Text>
                      </Col>
                      <Col span={3}>
                        {debtor.isArchived ? (
                          <TextTag className={'mt-x-small'} theme={'light'} color={'red'} content={'Archived'} />
                        ) : (
                          <Text>{debtor.numberOfCustomers}</Text>
                        )}
                      </Col>
                      <Col span={2} className={'justify-start'}>
                        <Popover
                          placement="bottomRight"
                          content={
                            <div style={{ margin: '-4px -16px' }}>
                              <Row
                                onClick={() => copy(debtor.debtorEmail)}
                                className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest"
                              >{`Copy ${debtor.debtorEmail} to clipboard`}</Row>
                              {debtor.isArchived ? (
                                <Row
                                  onClick={() => this.setState({ selectedDebtor: debtor, isOpenUnarchiveModal: true })}
                                  className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest text-color-red"
                                >
                                  Unarchive debtor
                                </Row>
                              ) : (
                                <Row
                                  onClick={() => this.setState({ selectedDebtor: debtor, isOpenArchiveModal: true })}
                                  className="cursor-pointer ph-medium pv-small pr-large hover-bg-blue-lightest text-color-red"
                                >
                                  Archive debtor
                                </Row>
                              )}
                            </div>
                          }
                          trigger="focus"
                        >
                          <Button className="ph-small border-standard-gray" onClick={() => {}}>
                            <Icon
                              className="text-color-blue-light text-weight-bolder"
                              type="ellipsis"
                              theme="outlined"
                            />
                          </Button>
                        </Popover>
                      </Col>
                    </GridRow>
                  );
                })}
              </InfiniteScrollLoading>
            ) : (
              <div style={{ borderBottom: '0px solid' }}>
                <ListEmptyState />
              </div>
            )}

            {this.state.isLoadingInfiniteScrolling && (
              <Skeleton paragraph={{ rows: 3, width: '100%' }} active={true} className="anim-slide-left" />
            )}
          </div>
        </CenteredLayout>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  debtors: state.accountStore.debtors
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchDebtorList: dispatch.accountStore.doFetchDebtorList
});

export default connect(mapState, mapDispatch)(DebtorManagementView);
