import React, { Component } from 'react';
import _ from 'lodash';
import { Icon } from 'antd';
import moment from 'moment-timezone';
import { SubTitle } from '../../../../../common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import PermissionUtils from 'utilities/permission-utils';
import { ServiceType } from 'utilities/enum-utils';
import { IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

interface IShiftItemRowRow {
  shiftItem?: any;
  portalUser: typeof state.authStore.portalUser;
  history?: any;
}

class ShiftItemRow extends Component<IShiftItemRowRow> {
  state = {
    showSubLines: false
  };

  private _onClickBooking = (attendanceId) => {
    const { history } = this.props;
    history.push(`/bookings/details/${attendanceId}`);
  };

  private _onClickService = (serviceId) => {
    const { history } = this.props;
    history.push(`/group-service/details/${serviceId}`);
  };

  private _onClickOpen = async () => {
    this.setState({ showSubLines: true });
  };

  private _onClickClose = async () => {
    this.setState({ showSubLines: false });
  };

  private _onClickWorker = (userId) => {
    const { history } = this.props;
    history.push(`/team/details/${userId}`);
  };

  render() {
    const { shiftItem } = this.props;

    if (_.isEmpty(shiftItem)) {
      return <></>;
    }

    const { firstName, lastName, items, supportWorkerId } = shiftItem;

    const totalShifts = items.length;
    const totalHours = _.sumBy(items, function(item: any) {
      return item.duration;
    });

    const totalKmTravelled = _.sumBy(items, function(item: any) {
      return item.totalTravelDistanceForBooking;
    });

    return (
      <>
        <tr
          onClick={this.state.showSubLines ? this._onClickClose : this._onClickOpen}
          className={`anim-fade-in bg-white ${this.state.showSubLines && 'no-border'}`}
        >
          <td className="fluid-cell">
            <HyperlinkButton color="black" onClick={() => this._onClickWorker(supportWorkerId)}>
              {firstName} {lastName}
            </HyperlinkButton>
          </td>
          <td className="fluid-cell" style={{ textAlign: 'right' }}>
            {totalShifts}
          </td>
          <td className="fluid-cell" style={{ textAlign: 'right' }}>
            {_.round(totalKmTravelled, 1)}
          </td>
          <td className="fluid-cell" style={{ textAlign: 'right' }}>
            {totalHours.toFixed(2)} hour{totalHours !== 1 && 's'}
          </td>
          <td style={{ width: '20px' }} onClick={this.state.showSubLines ? this._onClickClose : this._onClickOpen}>
            <div className="pr-small text-align-right">
              {this.state.showSubLines ? (
                <Icon className="size-x-large" type="up" />
              ) : (
                <Icon className="size-x-large" type="down" />
              )}
            </div>
          </td>
        </tr>
        <tr>
          {this.state.showSubLines && (
            <>
              <td colSpan={7} className="p-none mh-12 bg-tertiary">
                <table className="subLines m-medium" style={{ width: '98%' }}>
                  <tr>
                    <th>
                      <SubTitle>Booking Ref</SubTitle>
                    </th>
                    <th>
                      <SubTitle>Service</SubTitle>
                    </th>
                    <th>
                      <SubTitle>Start</SubTitle>
                    </th>
                    <th>
                      <SubTitle>End</SubTitle>
                    </th>
                    <th style={{ textAlign: 'right', width: '112px' }}>
                      <SubTitle>KM</SubTitle>
                    </th>
                    <th colSpan={2} style={{ textAlign: 'right' }}>
                      <SubTitle>Hours</SubTitle>
                    </th>
                  </tr>
                  {_.map(items, (shiftItem, key) => (
                    <>
                      <tr className={`text-align-middle ${items.length === key + 1 && 'no-border'} subLines-border`}>
                        <td className="subitem-width subLines-bgcolor">
                          {/** following HyperLink button will provide link to SUPPORT bookings
                           * when the attendanceId or referenceId are present
                           * else display '-' for GROUP bookings
                           */}
                          {shiftItem.serviceType === ServiceType.INDIVIDUAL ? (
                            <HyperlinkButton onClick={() => this._onClickBooking(shiftItem.attendanceId)}>
                              {shiftItem.referenceId}
                            </HyperlinkButton>
                          ) : shiftItem.serviceType === ServiceType.GROUP ? (
                            <div className="ml-medium"> - </div>
                          ) : null}
                        </td>
                        <td className="subLines-bgcolor" style={{ width: '400px' }}>
                          {shiftItem.serviceName}
                        </td>
                        <td className="subitem-width subLines-bgcolor">
                          {' '}
                          {shiftItem.startDateTime
                            ? moment.tz(shiftItem.startDateTime, shiftItem.timezone).format('D/MM/YYYY, hh:mm a')
                            : 0}
                        </td>
                        <td className="subitem-width subLines-bgcolor">
                          {' '}
                          {shiftItem.endDateTime
                            ? moment.tz(shiftItem.endDateTime, shiftItem.timezone).format('D/MM/YYYY, hh:mm a')
                            : 0}
                        </td>
                        <td className="subitem-width subLines-bgcolor" style={{ textAlign: 'right' }}>
                          {shiftItem.totalTravelDistanceForBooking > 0
                            ? `${_.round(shiftItem.totalTravelDistanceForBooking, 1)}`
                            : '-'}
                        </td>
                        <td colSpan={2} className="subLines-bgcolor" style={{ textAlign: 'right' }}>
                          {shiftItem.duration} hour{shiftItem.duration > 1 && 's'}
                        </td>
                      </tr>
                    </>
                  ))}
                </table>
              </td>
            </>
          )}
        </tr>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  portalUser: state.authStore.portalUser
});

export default connect(
  mapState,
  null
)(ShiftItemRow);
