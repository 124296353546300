import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Col, Form, Input, notification, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

interface IEditReplyToEmailModalProps extends FormComponentProps {
  companyData: typeof state.companyStore.companyData;
  doUpdateReplyEmailConfig: typeof dispatch.companyStore.doUpdateReplyEmailConfig;
  isOpen: any;
  onClose: () => void;
}

interface IEditReplyToEmailModalState {
  title: any;
  step: number;
  isLoading: boolean;
}

class EditReplyToEmailModal extends Component<IEditReplyToEmailModalProps, IEditReplyToEmailModalState> {
  state = { title: 'Reply-to email', step: 1, isLoading: false };

  private _onCloseModal = () => {
    this.props.onClose();
    this._resetState();
  };

  private _resetState = () => {
    this.setState({ title: 'Reply-to email', step: 1, isLoading: false });
  };

  private _onClickSave = async () => {
    const { form, doUpdateReplyEmailConfig } = this.props;
    this.setState({ isLoading: true });
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        await doUpdateReplyEmailConfig({
          replyEmailName: form.getFieldValue('name'),
          replyEmailAddress: form.getFieldValue('email')
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
      this.setState({
        isLoading: false,
        step: 2,
        title: 'Reply-to details edited'
      });
    } else {
      this.setState({ isLoading: false });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this._resetState();
    }
  }

  render() {
    const { isOpen, form, companyData } = this.props;
    const { step, isLoading } = this.state;
    const { getFieldDecorator } = form;

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="large"
        canCloseOutside={!isLoading}
      >
        {step === 1 && (
          <>
            <div className="mv-medium anim-slide-left">
              <Row>
                <SubTitle>reply-to email address</SubTitle>
                <Form.Item className={'m-none'}>
                  {getFieldDecorator('email', {
                    initialValue:
                      companyData && companyData.replyEmailConfig ? companyData.replyEmailConfig.replyEmailAddress : '',
                    rules: [
                      {
                        required: true,
                        type: 'email',
                        message: 'Please enter a valid Email'
                      }
                    ]
                  })(<Input placeholder="Input email address" size="large" />)}
                </Form.Item>
              </Row>
              <Row className="mt-medium">
                {/* </Col> */}
                <Col span={12}>
                  <SubTitle>reply-to name</SubTitle>
                  <Form.Item className="width-full m-none">
                    {getFieldDecorator('name', {
                      initialValue:
                        companyData && companyData.replyEmailConfig ? companyData.replyEmailConfig.replyEmailName : '',
                      rules: [
                        { required: true, message: 'Please enter a reply-to name' },
                        { max: 50, message: 'Please enter less than 50 characters' }
                      ]
                    })(<Input placeholder="Input name..." size={'large'} />)}
                  </Form.Item>
                </Col>
                <Col span={12} />
              </Row>
            </div>

            <Paragraph color="secondary">
              This email address will be used as the email reply destination replies for emails that we send out from
              GoodHuman on behalf of your business. The reply-to name will be used as the name in the reply-to subject
              header.
            </Paragraph>

            <ActionModalFooter>
              <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal} loading={isLoading}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onClickSave} loading={isLoading}>
                Save
              </PrimaryButton>
            </ActionModalFooter>
          </>
        )}
        {step === 2 && (
          <div>
            <Text>
              You have successfully changed the reply-to details for GoodHuman generated e-mails sent on behalf of your
              business.
            </Text>
            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Close
              </PrimaryButton>
            </ActionModalFooter>
          </div>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyData: state.companyStore.companyData
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateReplyEmailConfig: dispatch.companyStore.doUpdateReplyEmailConfig
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IEditReplyToEmailModalProps>()(EditReplyToEmailModal));
