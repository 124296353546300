import { FieldLabel, Text } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Button, Col, Icon, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { RecurringBookingPattern, ServiceAgreementStatus } from 'utilities/enum-utils';
import { IServiceAgreement } from 'interfaces/customer-interfaces';
import { AgreementStatusTag } from 'views/customers/details/tabs-panel/new-service-agreements/agreements-listing/AgreementStatusTag';
import _ from 'lodash';
import CommonUtils from 'utilities/common-utils';

interface IServiceAgreementDetailsProps {
  item: IServiceAgreement;
  onClickActionModal?: (item, action, value) => void;
  openViewModal: (selectedServiceLineItems) => void;
  onClickViewVersionHistory?: any;
  isHistory?: boolean;
  companyData: any;
  hasEditPermission: boolean;
}

export function ServiceAgreementDetails({
  item,
  onClickActionModal,
  openViewModal,
  onClickViewVersionHistory,
  isHistory,
  hasEditPermission,
  companyData
}: IServiceAgreementDetailsProps) {
  let estimatedDiff: any = 0;
  if (item.budgetTracking && !isHistory) {
    estimatedDiff = _.sumBy(item.budgetTracking, (item: any) => Number(item.estimatedDifference));
  }
  let totalQuoteAmount = 0;
  let totalAmountSpent = 0;
  let totalForecastedSpend = 0;
  _.forEach(item.budgetTracking, (tracking) => {
    totalQuoteAmount = Number(totalQuoteAmount) + Number(tracking.quoteAmount);
    totalAmountSpent = Number(totalAmountSpent) + Number(tracking.spentAmount) + Number(tracking.amountSpent);
    totalForecastedSpend = Number(totalForecastedSpend) + Number(tracking.forecastedSpend);
  });

  const [serviceLineItemsArray, setserviceLineItemsArray] = useState([]);

  //calculating length of service items to display
  useEffect(() => {
    let lengthArray = [];
    if (item && item.services) {
      lengthArray = _.map(item.services, (service) => {
        if (service.additionalCharges) {
          const uniqueArray = _.uniqBy(service.additionalCharges.autoChargedBillingItems, 'supportItemNumber');
          let totalLength = service.lineItems.length + uniqueArray.length;
          _.forEach(uniqueArray, (autoChargeLineItem) => {
            if (
              _.find(service.lineItems, (lineItem) => {
                return lineItem.supportItemNumber === autoChargeLineItem.supportItemNumber;
              })
            ) {
              totalLength--;
            }
          });
          return totalLength;
        }
      });
    }
    setserviceLineItemsArray(lengthArray);
  }, [item]);

  return (
    <div className="mt-x-large">
      {!isHistory && (
        <div className="mb-x-large">
          {/* Content Box */}
          <div className="bordered p-medium rounded">
            <FieldLabel text="STATUS" />

            {/* Status Tag */}
            <AgreementStatusTag status={item.status} />
            <Text>
              {item.signedStatus === ServiceAgreementStatus.SIGNED ? (
                <div>
                  <Text>
                    This service agreement has been <b>signed</b>
                  </Text>
                </div>
              ) : (
                <div>
                  <Text>
                    This service agreement is <b>unsigned</b>
                  </Text>
                </div>
              )}
            </Text>
          </div>
        </div>
      )}
      <div className="mb-x-large">
        {/* Label */}
        <div className="pb-small">
          <Text size="x-large" weight="bold">
            General Information
          </Text>
        </div>

        {/* Content Box */}
        <div className="bordered p-medium rounded">
          <Row gutter={16}>
            <Col span={7}>
              <FieldLabel text="START DATE" />
              <Text size="x-large">
                {item.startDate ? (
                  moment.tz(item.startDate, companyData.timezone).format('DD MMM YYYY')
                ) : (
                  <Text color={'secondary'}>Not set.</Text>
                )}
              </Text>
            </Col>
            <Col span={7}>
              <FieldLabel text="END DATE" />
              <Text size="x-large">
                {item.endDate ? (
                  moment.tz(item.endDate, companyData.timezone).format('DD MMM YYYY')
                ) : (
                  <Text color={'secondary'}>Not set.</Text>
                )}
              </Text>
            </Col>

            <Col span={7}>
              {item.signedStatus === ServiceAgreementStatus.SIGNED && (
                <>
                  <FieldLabel text="SIGNED ON" />
                  <Text size="x-large">{moment(item.signedDate).format('DD MMM YYYY')}</Text>
                </>
              )}
            </Col>
            {!isHistory && hasEditPermission && (
              <Col span={3} className={'text-align-right'}>
                <HyperlinkButton onClick={() => onClickActionModal(item, 'DATES', null)}>
                  <Icon type="edit" /> Modify...
                </HyperlinkButton>
              </Col>
            )}
          </Row>
        </div>
      </div>
      {/* Terms & Conditions*/}
      <div className="mb-x-large">
        {/* Label */}
        <div className="pb-small">
          <Text weight="bold">Terms & Conditions</Text>
        </div>

        {/* Content Box */}
        <div className="bordered p-medium mt-small" style={{ maxHeight: '200px', overflowY: 'auto' }}>
          <div className="flex-row justify-between">
            <FieldLabel text="TERMS & CONDITIONS" />
            {!isHistory && hasEditPermission && (
              <HyperlinkButton onClick={() => onClickActionModal(item, 'TERMS_CONDITIONS', null)}>
                <Icon type="edit" /> Modify...
              </HyperlinkButton>
            )}
          </div>
          {item.termsConditions ? (
            <div
              className="mt-small"
              dangerouslySetInnerHTML={{
                __html: item.termsConditions ? item.termsConditions : ''
              }}
            />
          ) : (
            <Text color={'secondary'}>No Terms and Conditions set.</Text>
          )}
        </div>
      </div>
      {/* Schedule of Support(s)*/}
      <div className="mb-x-large">
        <div className="pb-small">
          <Text weight="bold">Services being delivered</Text>
        </div>

        {/* Content Box */}
        <div className="bordered p-medium rounded">
          <div className="pb-small flex-row justify-between">
            <FieldLabel text="SERVICE(S)" />
            {!isHistory && hasEditPermission && (
              <HyperlinkButton onClick={() => onClickActionModal(item, 'SERVICE_ITEMS', null)}>
                <Icon type="edit" /> Modify...
              </HyperlinkButton>
            )}
          </div>

          {/* Header */}
          <div className="bordered-bottom pb-medium">
            <Row gutter={8}>
              <Col span={6}>
                <div className="ph-medium">
                  <FieldLabel text={'SERVICE NAME'} />
                </div>
              </Col>
              <Col span={6}>
                <div className="ph-medium">
                  <FieldLabel text={'LINE ITEM(S)'} />
                </div>
              </Col>
              <Col span={12}>
                <div className="ph-medium">
                  <FieldLabel text={'COMMENTS'} />
                </div>
              </Col>
            </Row>
          </div>

          {/* Item */}
          {item.services &&
            _.map(item.services, (service, index) => (
              <div className="pv-medium evenodd">
                <Row gutter={16}>
                  <Col span={6}>
                    <div className="ph-medium">{service.serviceName}</div>
                  </Col>
                  <Col span={6}>
                    <div className="ph-medium">
                      {service.lineItems && service.lineItems.length > 0 ? (
                        <Text>
                          {serviceLineItemsArray[index] ? serviceLineItemsArray[index] : service.lineItems.length} line
                          item
                          {service.lineItems.length !== 1 ? 's' : ''} selected
                        </Text>
                      ) : (
                        '0 line item selected'
                      )}
                    </div>
                  </Col>
                  <Col span={8}>
                    <div className="ph-medium">{service.comment} </div>
                  </Col>
                  <Col span={4}>
                    <div className="ph-medium text-align-right">
                      <HyperlinkButton onClick={() => openViewModal(service)}>View details...</HyperlinkButton>{' '}
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
        </div>
      </div>
      <div className="mb-x-large">
        <div className="pb-small">
          <Text weight="bold">Quote</Text>
        </div>

        {/* Content Box */}
        <div className="bordered p-medium rounded">
          <div className="pb-small flex-row justify-between">
            <FieldLabel text="Quote by service" />
            {!isHistory && hasEditPermission && (
              <HyperlinkButton onClick={() => onClickActionModal(item, 'QUOTE', null)}>
                <Icon type="edit" /> Modify...
              </HyperlinkButton>
            )}
          </div>

          {/* Header */}
          <div className="bordered-bottom pb-medium">
            <Row gutter={8}>
              <Col span={6}>
                <div className="ph-medium">
                  <FieldLabel text={'SERVICE NAME'} />
                </div>
              </Col>
              <Col span={4}>
                <div className="ph-medium">
                  <FieldLabel text={'QUOTE AMOUNT'} />
                </div>
              </Col>
              <Col span={14} />
            </Row>
          </div>

          {item.quotations && item.quotations.length > 0 ? (
            <>
              {_.map(item.quotations, (quote, idx) => {
                return (
                  <Row key={idx} className={'pv-medium evenodd'}>
                    <Col span={6} className={'ph-medium'}>
                      {quote.serviceName}
                    </Col>
                    <Col span={4} className={'text-align-right ph-medium'}>
                      {CommonUtils.formatPrice(quote.quoteAmount)}
                    </Col>
                    <Col span={14} />
                  </Row>
                );
              })}
              <Row key={'TOTAL'} className={'pv-small bordered-top'}>
                <Col span={6} className={'ph-medium'}>
                  <Text weight={'bold'}>Total</Text>
                </Col>
                <Col span={4} className={'text-align-right ph-medium'}>
                  {item.quotations ? (
                    <Text weight={'bold'} style={{ marginRight: '-24px' }}>
                      <HyperlinkButton onClick={() => onClickActionModal(item, 'VIEW_QUOTE', null)}>
                        {CommonUtils.formatPrice(_.sumBy(item.quotations, (item: any) => Number(item.quoteAmount)))}
                        <Icon type={'search'} className={'ml-small'} />
                      </HyperlinkButton>
                    </Text>
                  ) : (
                    0
                  )}
                </Col>
                <Col span={14} />
              </Row>
            </>
          ) : (
            <Row key={'EMPTY'} className={'pv-small bordered-top'}>
              <Col span={24} className={'ph-medium'}>
                <Text color={'secondary'}>
                  No quote created.{!isHistory && hasEditPermission && ` Click on 'Modify' to add a quote.`}
                </Text>
              </Col>
            </Row>
          )}
        </div>
      </div>
      {!isHistory && (
        <>
          {/* Budget tracking */}
          <div className="mb-x-large">
            <div className="pb-small">
              <Text weight="bold">Budget tracking</Text>
            </div>

            {/* Content Box */}
            <div className="bordered p-medium rounded">
              <div className="pb-small flex-row justify-between">
                <FieldLabel text="Budget tracking by service" />
              </div>

              {/* Header */}
              <div className="bordered-bottom pb-medium">
                <Row gutter={8}>
                  <Col span={5}>
                    <div className="ph-medium">
                      <FieldLabel text={'SERVICE'} />
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className="ph-small text-align-right">
                      <FieldLabel text={'QUOTE'} />
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className="ph-small text-align-right">
                      <FieldLabel text={'AMOUNT SPENT'} />
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className="ph-small text-align-right">
                      <FieldLabel text={'FORECASTED SPEND'} />
                    </div>
                  </Col>
                  <Col span={4}>
                    <div className="ph-small text-align-right">
                      <FieldLabel text={'ESTIMATED DIFF.'} />
                    </div>
                  </Col>
                  <Col span={3} />
                </Row>
              </div>

              {_.map(item.budgetTracking, (tracking, idx) => {
                return (
                  <Row key={idx} className={'pv-medium evenodd'}>
                    <Col span={5} className={'ph-medium'}>
                      {tracking.serviceName}
                    </Col>
                    <Col span={4} className={'text-align-right ph-small'}>
                      {CommonUtils.formatPrice(tracking.quoteAmount)}
                    </Col>
                    <Col span={4} className={'text-align-right ph-small'}>
                      {CommonUtils.formatPrice(tracking.spentAmount + tracking.amountSpent)}
                    </Col>
                    <Col span={4} className={'text-align-right ph-small'}>
                      {CommonUtils.formatPrice(tracking.forecastedSpend)}
                    </Col>
                    <Col span={4} className={'text-align-right ph-small'}>
                      <Text
                        color={
                          tracking.estimatedDifference > 0
                            ? 'green'
                            : tracking.estimatedDifference < 0
                            ? 'red-dark'
                            : 'black'
                        }
                      >
                        {tracking.estimatedDifference > 0 && '+'}
                        {CommonUtils.formatPrice(tracking.estimatedDifference)}
                      </Text>
                    </Col>
                    <Col span={3} className={'text-align-center'}>
                      {hasEditPermission && (
                        <HyperlinkButton onClick={() => onClickActionModal(item, 'VIEW_TRACKING', tracking)}>
                          View details
                        </HyperlinkButton>
                      )}
                    </Col>
                  </Row>
                );
              })}
              <Row key={'TOTAL'} className={'pv-small bordered-top'}>
                <Col span={5} className={'ph-medium'}>
                  <Text weight={'bold'}>Total</Text>
                </Col>
                <Col span={4} className={'text-align-right ph-small'}>
                  {CommonUtils.formatPrice(totalQuoteAmount)}
                </Col>
                <Col span={4} className={'text-align-right ph-small'}>
                  {CommonUtils.formatPrice(totalAmountSpent)}
                </Col>
                <Col span={4} className={'text-align-right ph-small'}>
                  {CommonUtils.formatPrice(totalForecastedSpend)}
                </Col>
                <Col span={4} className={'text-align-right ph-small'}>
                  <Text color={estimatedDiff > 0 ? 'green' : estimatedDiff < 0 ? 'red-dark' : 'black'}>
                    {estimatedDiff > 0 && '+'}
                    {CommonUtils.formatPrice(estimatedDiff)}
                  </Text>
                </Col>
                <Col span={3} className={'text-align-center'} />
              </Row>
            </div>
          </div>
        </>
      )}
      {!isHistory && hasEditPermission && (
        <div className="mb-small">
          {/* Created / Edited on */}
          <div className="mb-medium">
            <Text size="small" color="secondary">
              Created on {moment(item.createdOn).format('DD/MM/YYYY')}
              {item.updatedOn && `, last edited on ${moment(item.updatedOn).format('DD/MM/YYYY')}`}.
            </Text>
          </div>

          <div>
            {item.signedStatus === ServiceAgreementStatus.UNSIGNED && (
              <>
                <PrimaryButton
                  className="mr-medium"
                  icon="check"
                  onClick={() => onClickActionModal(item, 'STATUS', ServiceAgreementStatus.SIGNED)}
                >
                  Mark as Signed
                </PrimaryButton>
              </>
            )}

            <Button
              href={`/pdf?type=service-agreement&agreement=${item.userServiceAgreementId}`}
              target="_blank"
              icon={'eye'}
              type={'default'}
              className={'text-color-blue-action border-blue-action mr-medium'}
            >
              Download as PDF
            </Button>
            <Button
              type={'default'}
              className={'text-color-blue-action border-blue-action mr-medium'}
              onClick={onClickViewVersionHistory}
            >
              View version history
            </Button>
            <SecondaryButton
              className="mr-medium"
              icon="delete"
              color="red"
              onClick={() => onClickActionModal(item, 'DELETE', true)}
            >
              Delete
            </SecondaryButton>
          </div>
        </div>
      )}
    </div>
  );
}
