import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Col, Form, Icon, Input, notification, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import _ from 'lodash';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import CommonUtils from 'utilities/common-utils';
import Utils from 'utilities/Utils';
import FormItem from 'antd/es/form/FormItem';

const searchAutocompleteOptions = {
  componentRestrictions: { country: ['au'] }
};

interface IEditCompanyAddressModalProps extends FormComponentProps {
  isOpen: any;
  onClose: () => void;
  doEditCompanyAddress: typeof dispatch.companyStore.doEditCompanyAddress;
  address?: any;
}

interface IEditCompanyAddressModalState {
  title: any;
  isCreated: boolean;
  selectedLocation: boolean;
  selectedLattitude: any;
  selectedLongitude: any;
  address_components: any;
  inputAddress: string;
  savedInputAddress: string;
  addressLocation: any;
  isManual: boolean;
}

class EditCompanyAddressModal extends Component<IEditCompanyAddressModalProps, IEditCompanyAddressModalState> {
  state = {
    title: 'Change business address',
    isCreated: false,
    selectedLocation: false,
    selectedLattitude: null,
    selectedLongitude: null,
    address_components: null,
    inputAddress: '',
    savedInputAddress: '',
    addressLocation: null,
    isManual: false
  };

  private _onCloseModal = () => {
    this.setState({ title: 'Change business address', isCreated: false, isManual: false });
    this.props.onClose();
  };

  private _editBusinessAddress = async () => {
    const { form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      try {
        const addressLocation = await this._generateAddress();
        await this.props.doEditCompanyAddress(addressLocation);
        this.setState({
          title: (
            <>
              Change address successful
              <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
            </>
          ),
          isCreated: true,
          addressLocation
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
  };

  private _handleSelect = async (inputAddress) => {
    try {
      let selectedLocation = null;
      await geocodeByAddress(inputAddress)
        .then((results) => (selectedLocation = results[0]))
        .catch(() => this.setState({ selectedLocation: false }));

      if (selectedLocation) {
        const latLng = await getLatLng(selectedLocation);
        this.setState({
          selectedLocation: true,
          savedInputAddress: inputAddress,
          selectedLattitude: latLng.lat,
          selectedLongitude: latLng.lng,
          address_components: selectedLocation.address_components
        });
      }
    } catch {
      this.setState({ selectedLocation: false });
    }
    this.setState({ inputAddress: inputAddress });
  };

  private _generateAddress = async () => {
    const { form } = this.props;
    if (this.state.isManual) {
      const streetAddress1 = form.getFieldValue('streetaddress');
      const streetAddress2 = form.getFieldValue('streetaddress2') ? form.getFieldValue('streetaddress2') : '';
      const suburb = form.getFieldValue('suburb');
      const state = form.getFieldValue('state');
      const postcode = form.getFieldValue('postcode');
      //TODO: temporarily hardcode as country dropdown is disabled
      const country = 'Australia';
      const fulladdress = streetAddress1 + ' ' + streetAddress2 + ' , ' + suburb + ' , ' + state + ' , ' + country;
      let latlong = await CommonUtils.getLatLongByAddress(fulladdress);
      return {
        state: state,
        geoLat: latlong.lat,
        geoLng: latlong.lng,
        country: country,
        locality: suburb,
        postcode: postcode,
        fullAddress: CommonUtils.formatFullAddress({
          streetAddress1,
          streetAddress2,
          locality: suburb,
          state,
          postcode,
          country
        }),
        streetAddress1: streetAddress1,
        streetAddress2: streetAddress2
      };
    } else {
      const { selectedLattitude, selectedLongitude, inputAddress, address_components } = this.state;
      let postal_code = _.find(address_components, (address) => {
        return address.types[0] === 'postal_code';
      });

      let country = _.find(address_components, (address) => {
        return address.types[0] === 'country';
      });

      let province = _.find(address_components, (address) => {
        return address.types[0] === 'administrative_area_level_1';
      });

      let locality = _.find(address_components, (address) => {
        return address.types[0] === 'locality';
      });

      let street_number = _.find(address_components, (address) => {
        return address.types[0] === 'street_number';
      });

      let route = _.find(address_components, (address) => {
        return address.types[0] === 'route';
      });

      let subpremise = _.find(address_components, (address) => {
        return address.types[0] === 'subpremise';
      });

      const streetAddress1 = route
        ? `${subpremise ? subpremise.long_name.replace(/\D/g, '') + '/' : ''}${
            street_number ? street_number.long_name : ''
          } ${route.long_name}`
        : '';

      return {
        state: province !== undefined ? province.short_name : '',
        geoLat: selectedLattitude,
        geoLng: selectedLongitude,
        country: country !== undefined ? country.long_name : '',
        locality: locality !== undefined ? locality.long_name : '',
        postcode: postal_code !== undefined ? postal_code.long_name : '',
        streetAddress1,
        streetAddress2: '',
        fullAddress: CommonUtils.formatFullAddress({
          streetAddress1,
          streetAddress2: '',
          locality: locality !== undefined ? locality.long_name : '',
          state: province !== undefined ? province.short_name : '',
          postcode: postal_code !== undefined ? postal_code.long_name : '',
          country: country !== undefined ? country.long_name : ''
        })
      };
    }
  };

  private _handleChange = (inputAddress) => {
    this.setState({ inputAddress: inputAddress });
    if (inputAddress === '') {
      this.setState({ selectedLocation: false });
    }
  };

  private _switchToManual = () => {
    this.setState({ isManual: true, selectedLocation: false, inputAddress: '' });
  };

  private _switchToAuto = () => {
    this.setState({ isManual: false, selectedLocation: false, inputAddress: '' });
  };

  private _validatePostcode = (rule, value, callback) => {
    try {
      if (Number(value).toString().length !== 4) {
        throw Error('Postcode is required (4 Digits)');
      }
    } catch (e) {
      callback(e);
      return;
    }
    callback();
  };

  render() {
    const { isOpen, address, form } = this.props;
    const { address_components } = this.state;
    const { getFieldDecorator } = form;

    const defaultStyles = {
      autocompleteItem: {
        backgroundColor: '#000',
        padding: '10px',
        color: '#555555',
        cursor: 'pointer'
      },
      autocompleteItemActive: {
        backgroundColor: '#fafafa'
      }
    };

    const isIncorrectSelectedAddress =
      _.isEmpty(
        _.find(address_components, (address) => {
          return address.types[0] === 'postal_code';
        })
      ) ||
      _.isEmpty(
        _.find(address_components, (address) => {
          return address.types[0] === 'locality';
        })
      ) ||
      _.isEmpty(
        _.find(address_components, (address) => {
          return address.types[0] === 'route';
        })
      );

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {this.state.isCreated ? (
          <>
            <div className="mv-medium anim-slide-left">
              <SubTitle>Your new business address is</SubTitle>
              <Text size={'x-large'} style={{ whiteSpace: 'pre-line' }}>
                {CommonUtils.formatFullAddress(this.state.addressLocation)}
              </Text>
            </div>

            <ActionModalFooter>
              <PrimaryButton size="large" onClick={this._onCloseModal}>
                Done
              </PrimaryButton>
            </ActionModalFooter>
          </>
        ) : (
          <>
            <div className="mv-medium anim-slide-left">
              <div>
                <Paragraph>
                  Change your businesses primary address. This will be used in invoices and other templated documents.
                </Paragraph>
                {!this.state.isManual ? (
                  <>
                    <div className="mt-large" style={{ minHeight: '300px' }}>
                      <SubTitle>Lookup address</SubTitle>
                      <Form.Item className="width-full m-none">
                        <PlacesAutocomplete
                          value={this.state.inputAddress}
                          onSelect={this._handleSelect}
                          onChange={this._handleChange}
                          shouldFetchSuggestions={this.state.inputAddress.length > 2}
                          searchOptions={searchAutocompleteOptions}
                          styles={defaultStyles}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <input
                                value={this.state.inputAddress}
                                {...getInputProps({
                                  placeholder: 'Type your address ...',
                                  className: 'location-search-input width-full pl-small'
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                  const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                  const style = suggestion.active
                                    ? {
                                        backgroundColor: '#fafafa',
                                        cursor: 'pointer',
                                        borderTop: '1px ridge grey',
                                        borderLeft: '1px ridge grey',
                                        borderRight: '1px ridge grey'
                                      }
                                    : {
                                        backgroundColor: '#ffffff',
                                        cursor: 'pointer',
                                        borderTop: '1px ridge grey',
                                        borderLeft: '1px ridge grey',
                                        borderRight: '1px ridge grey'
                                      };
                                  return (
                                    <div
                                      {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style
                                      })}
                                    >
                                      <span>
                                        <Icon type={'environment'} /> {suggestion.description}
                                      </span>
                                    </div>
                                  );
                                })}
                                {suggestions.length === 0 &&
                                  !_.isEmpty(this.state.inputAddress) &&
                                  !this.state.selectedLocation && <div>No results found.</div>}
                              </div>
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </Form.Item>
                    </div>
                    <div>
                      <SubTitle>Selected address</SubTitle>
                      <Text>
                        {!Utils.isEmpty(this.state.savedInputAddress)
                          ? this.state.savedInputAddress
                          : 'No address selected yet.'}
                      </Text>
                      {!Utils.isEmpty(this.state.savedInputAddress) && isIncorrectSelectedAddress && (
                        <>
                          <br />
                          <Text color={'red-dark'}>This address have an incorrect format.</Text>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <React.Fragment>
                    <div style={{ minHeight: '50vh', overflow: 'auto' }}>
                      <div className="mb-small">
                        <SubTitle>Street Address</SubTitle>
                        <FormItem className={'m-none pr-small'}>
                          {getFieldDecorator('streetaddress', {
                            initialValue: address ? address.streetAddress1 : null,
                            rules: [
                              {
                                required: true,
                                message: 'Street address is required'
                              }
                            ]
                          })(<Input placeholder="Enter Street Address" />)}
                        </FormItem>
                      </div>
                      <div className="mb-small">
                        <SubTitle>Street Address 2</SubTitle>
                        <FormItem className={'m-none pr-small'}>
                          {getFieldDecorator('streetaddress2', {
                            initialValue: address ? address.streetAddress2 : null
                          })(<Input placeholder="Enter Street Address 2" />)}
                        </FormItem>
                      </div>
                      <div className="mb-small width-1/2">
                        <SubTitle>Suburb</SubTitle>
                        <FormItem className={'m-none pr-small'}>
                          {getFieldDecorator('suburb', {
                            initialValue: address ? address.locality : null,
                            rules: [
                              {
                                required: true,
                                message: 'Suburb is required'
                              }
                            ]
                          })(<Input placeholder="Enter Suburb" />)}
                        </FormItem>
                      </div>
                      <div className="mb-small width-1/2">
                        <SubTitle>State</SubTitle>
                        <FormItem className={'m-none pr-small'}>
                          {getFieldDecorator('state', {
                            initialValue: address ? address.state : null,
                            rules: [
                              {
                                required: true,
                                message: 'State is required'
                              }
                            ]
                          })(
                            <Select
                              placeholder="Select a State"
                              style={{ width: '200px' }}
                              showSearch={true}
                              optionFilterProp={'children'}
                            >
                              <Select.Option value={'ACT'}>Australian Capital Territory</Select.Option>
                              <Select.Option value={'NSW'}>New South Whales</Select.Option>
                              <Select.Option value={'NT'}>Northern Territory</Select.Option>
                              <Select.Option value={'QLD'}>Queensland</Select.Option>
                              <Select.Option value={'SA'}>South Australia</Select.Option>
                              <Select.Option value={'TAS'}>Tasmania</Select.Option>
                              <Select.Option value={'VIC'}>Victoria</Select.Option>
                              <Select.Option value={'WA'}>Western Australia</Select.Option>
                            </Select>
                          )}
                        </FormItem>
                      </div>
                      <div className="mb-small width-1/4">
                        <SubTitle>Postcode</SubTitle>
                        <FormItem className={'m-none pr-small'}>
                          {getFieldDecorator('postcode', {
                            initialValue: address ? address.postcode : null,
                            rules: [
                              {
                                validator: this._validatePostcode
                              }
                            ]
                          })(<Input maxLength={4} placeholder="Enter Postcode" />)}
                        </FormItem>
                      </div>
                      <div className="mb-small width-1/2">
                        <SubTitle>Country</SubTitle>
                        <FormItem className={'m-none pr-small'}>
                          <Select size={'large'} style={{ width: '200px' }} defaultValue={'AU'} disabled>
                            <Select.Option value={'AU'}>Australia</Select.Option>
                          </Select>
                        </FormItem>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </div>

              <ActionModalFooter>
                {!this.state.isManual ? (
                  <HyperlinkButton onClick={this._switchToManual} style={{ float: 'left' }} className={'mt-small'}>
                    Enter the address manually
                  </HyperlinkButton>
                ) : (
                  <HyperlinkButton onClick={this._switchToAuto} style={{ float: 'left' }} className={'mt-small'}>
                    Address lookup tool
                  </HyperlinkButton>
                )}
                <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton
                  size="large"
                  onClick={this._editBusinessAddress}
                  disabled={!this.state.isManual && (!this.state.selectedLocation || isIncorrectSelectedAddress)}
                >
                  Change address
                </PrimaryButton>
              </ActionModalFooter>
            </div>
          </>
        )}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doEditCompanyAddress: dispatch.companyStore.doEditCompanyAddress
});

export default connect(
  null,
  mapDispatch
)(Form.create<IEditCompanyAddressModalProps>()(EditCompanyAddressModal));
