import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from '../../../../common-components/modal/ActionModal';
import { ErrorSVG } from '../../../../assets/UndrawSVG';
import { Paragraph } from '../../../../common-components/typography';
import SpinningLoader from '../../../../common-components/loading/SpinningLoader';
import { GhostButton, PrimaryButton } from '../../../../common-components/buttons';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';
import CommonUtils from 'utilities/common-utils';

interface IProcessPaymentFailedActionModalProps {
  isOpen: any;
  onClose: any;
  deselect: any;
  planManagementInvoiceList: typeof state.planManagementStore.planManagementInvoiceList;
  selectedItem: typeof state.planManagementStore.selectedItem;
  doMarkAsPaymentFailedInvoices: typeof dispatch.planManagementStore.doMarkAsPaymentFailedInvoices;
}

class ProcessPaymentFailedActionModal extends Component<IProcessPaymentFailedActionModalProps, any> {
  state = {
    selectedInvoices: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    counts: {},
    title: 'Mark invoices as payment failed'
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;
    if (!this.state.isLoading) {
      this.setState({
        isLoading: false,
        isSuccess: false,
        selectedInvoices: [],
        paymentsSelectedAll: false,
        isError: false
      });
      onClose();
    }
  };

  private _onSubmitProcess = async () => {
    const { doMarkAsPaymentFailedInvoices, deselect, selectedItem } = this.props;

    this.setState({ isLoading: true });

    try {
      const invoiceIds = _.uniq(
        _.map(selectedItem.items, (item) => {
          return item.planManagementInvoiceId;
        })
      );
      await doMarkAsPaymentFailedInvoices({ invoiceIds });
      deselect();
      this.setState({
        isLoading: false,
        counts: 0,
        isSuccess: true,
        title: 'Invoices successfully marked as payment failed'
      });
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false, isError: true });
    }
  };

  render() {
    const { isOpen, selectedItem } = this.props;

    const numberOfInvoices = selectedItem.items.length;
    const numberOfCustomers = selectedItem.numberOfCustomers
      ? selectedItem.numberOfLineItems
      : _(selectedItem.items)
          .groupBy('customerUserId')
          .map((items, customerUserId) => ({ customerUserId }))
          .value().length;

    const payTotals = _.reduce(
      selectedItem.items,
      (sum, item) => {
        const total = item.invoiceTotal ? Number(item.invoiceTotal) : Number(item.total);
        return sum + total;
      },
      0
    );

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="medium"
        verticalAlignment="highest"
      >
        {!this.state.isLoading && !this.state.isSuccess && !this.state.isError && (
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>Are you sure you want to mark the selected invoices as payment failed?</Paragraph>
              <Paragraph>
                You have selected:
                <br />
                {numberOfInvoices} invoice{numberOfInvoices > 1 && 's'} for {numberOfCustomers} Customer
                {numberOfCustomers > 1 && 's'} for a total of {CommonUtils.formatPrice(payTotals)}.
              </Paragraph>
            </div>
          </div>
        )}
        {this.state.isLoading && <SpinningLoader size={100} message={'Loading'} />}
        {!this.state.isLoading && this.state.isSuccess && (
          <div className="text-align-left">
            <div className="mb-medium">
              <Paragraph>
                You have successfully marked as payment failed {numberOfInvoices} Invoice{numberOfInvoices > 1 && 's'}.
              </Paragraph>
            </div>
          </div>
        )}

        {!this.state.isSuccess && !this.state.isError && (
          <ActionModalFooter>
            <GhostButton
              size="large"
              onClick={this._onCloseModal}
              className="mr-medium"
              disabled={this.state.isLoading}
            >
              Not now
            </GhostButton>
            <PrimaryButton size="large" onClick={this._onSubmitProcess} loading={this.state.isLoading}>
              Mark as payment failed
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {this.state.isSuccess && !this.state.isError && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
        {!this.state.isLoading && this.state.isError && (
          <div className="text-align-center">
            <div className="pv-medium">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium">
              <Paragraph>An error has occurred.</Paragraph>
            </div>
          </div>
        )}

        {this.state.isError && (
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal} loading={this.state.isLoading}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        )}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  paymentsList: state.billingsStore.paymentsList
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doMarkAsPaymentFailedInvoices: dispatch.planManagementStore.doMarkAsPaymentFailedInvoices
});

export default connect(
  mapState,
  mapDispatch
)(ProcessPaymentFailedActionModal);
