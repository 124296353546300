import React, { Component } from 'react';
import { Icon } from 'antd';
import { Text } from 'common-components/typography';

class IBookingErrorBannerProps {
  errorMessage: any;
  hideErrorTitle?: boolean;
  className?: string;
}

export default class BookingErrorBanner extends Component<IBookingErrorBannerProps, any> {
  render() {
    const { errorMessage, hideErrorTitle = false, className = '' } = this.props;
    return (
      <div
        style={{
          width: '100%',
          backgroundColor: 'rgba(255,235,235,0.5)',
          border: '1px solid #FF7373',
          borderRadius: 8,
          verticalAlign: 'top'
        }}
        className={`p-medium flex justify-start mt-medium ${className}`}
      >
        <div className="mt-x2-small">
          <Icon type="close-circle" theme="filled" className="text-color-red-light text-size-x2-large" />
        </div>
        <div className="ml-small">
          {!hideErrorTitle && (
            <div>
              <Text className="text-color-red" style={{ verticalAlign: 'top' }}>
                Error
              </Text>
            </div>
          )}
          <div>{errorMessage}</div>
        </div>
      </div>
    );
  }
}
