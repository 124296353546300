import { Checkbox, Col, Form, Input, notification, Row, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { GhostButton, PrimaryButton } from 'common-components/buttons';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import FullScreenModal, { FullScreenModalFooter } from 'common-components/modal/FullScreenModal';
import { Paragraph, SubTitle, Text, Title } from 'common-components/typography';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { ICustomer } from 'interfaces/customer-interfaces';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';

const FormItem = Form.Item;
const Option = Select.Option;
const OptGroup = Select.OptGroup;
const { TextArea } = Input;

interface ICreateEditContactModalProps extends FormComponentProps {
  closeCreateEditContactModal: () => void;
  isOpen: boolean;
  selectedCustomer: ICustomer;
  doCreateCustomerContact?: typeof dispatch.customersStore.doCreateCustomerContact;
  doUpdateCustomerContact?: typeof dispatch.customersStore.doUpdateCustomerContact;
  doGetRelationshipLists?: typeof dispatch.customersStore.doGetRelationshipLists;
  relationships: typeof state.customersStore.relationships;
  selectedContactId: string;
}

interface ICreateEditContactModalState {
  isLoadingFilter: boolean;
  isActionModalOpen: boolean;
}

class CreateEditContactModal extends Component<ICreateEditContactModalProps, ICreateEditContactModalState> {
  state = { isLoadingFilter: false, isActionModalOpen: false };

  async componentDidMount() {
    const { doGetRelationshipLists } = this.props;
    await doGetRelationshipLists({});
  }

  private _renderRelationList() {
    const { relationships } = this.props;
    return relationships.map((relationGroup: any, index: number) => {
      const relationsOfGroup = !_.isEmpty(relationGroup.relations)
        ? _.sortBy(relationGroup.relations, (relation) => relation.relationTypeName)
        : [];
      return (
        <OptGroup key={index} label={`${relationGroup.relationGroupName} (${relationsOfGroup.length})`}>
          {relationsOfGroup.map((relation) => (
            <Option key={relation.relationTypeName} value={relation.relationTypeName}>
              {relation.relationTypeName}
            </Option>
          ))}
        </OptGroup>
      );
    });
  }

  private _validateMobileNumber = (rule, value, callback) => {
    const region = 'AU';
    if (value) {
      try {
        const phoneNumberUtil = PhoneNumberUtil.getInstance();
        const phoneNumber = phoneNumberUtil.parseAndKeepRawInput(value, region);
        const isValidateRegionalNumber = phoneNumberUtil.isValidNumberForRegion(phoneNumber, region);
        if (!isValidateRegionalNumber) {
          throw Error('Invalid Mobile Number');
        }

        const nationalNumber = phoneNumber.getNationalNumberOrDefault();
        if (!nationalNumber) {
          throw Error('Invalid Mobile Number');
        }
      } catch (err) {
        callback('Invalid Mobile Number');
        return 'Invalid Mobile Number';
      }
    }
    callback();
  };

  private _validateFullName = (rule, value, callback) => {
    try {
      if (_.isEmpty(value.trim())) {
        throw Error('Full Name is required');
      }
    } catch (e) {
      callback(e);
      return;
    }
    callback();
  };

  private _closeWithActionModal = () => {
    const { form, selectedCustomer, selectedContactId } = this.props;
    const selectedContact = _.find(selectedCustomer.emergencyContacts, (contact) => {
      return contact.userContactId === selectedContactId;
    });

    if (selectedContact !== undefined) {
      if (
        selectedContact.fullName !== form.getFieldValue('fullname') ||
        selectedContact.phoneNumber !== form.getFieldValue('phoneNumber') ||
        selectedContact.email !== form.getFieldValue('email') ||
        selectedContact.note !== form.getFieldValue('note') ||
        selectedContact.isPrimaryCarer !== form.getFieldValue('isPrimaryCarer') ||
        selectedContact.relationToUser !== form.getFieldValue('relationToUser')
      ) {
        this.setState({ isActionModalOpen: true });
      } else {
        this.props.closeCreateEditContactModal();
      }
    } else {
      if (
        form.getFieldValue('fullname') !== '' ||
        form.getFieldValue('phoneNumber') !== '' ||
        form.getFieldValue('email') !== '' ||
        form.getFieldValue('note') !== '' ||
        form.getFieldValue('relationToUser') !== 'Other'
      ) {
        this.setState({ isActionModalOpen: true });
      } else {
        this.props.closeCreateEditContactModal();
      }
    }
  };

  private _closeActionModal = () => {
    this.setState({ isActionModalOpen: false });
  };

  private _closeActionCreateModal = () => {
    this.setState({ isActionModalOpen: false });
    this.props.closeCreateEditContactModal();
  };

  private _saveCustomerContact = async () => {
    const { form, doCreateCustomerContact, selectedCustomer, doUpdateCustomerContact } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      const fullname = form.getFieldValue('fullname');
      const phoneNumber = form.getFieldValue('phoneNumber');
      const phoneNumberCountryCode = form.getFieldValue('phoneNumberCountryCode');
      const email = form.getFieldValue('email');
      const relationToUser = form.getFieldValue('relationToUser');
      const isPrimaryCarer = form.getFieldValue('isPrimaryCarer') ? form.getFieldValue('isPrimaryCarer') : false;
      const userContactId = form.getFieldValue('userContactId');
      const note = form.getFieldValue('note');

      const payload = {
        userId: selectedCustomer.userId,
        fullName: fullname,
        email: email ? email : null,
        phoneNumber: phoneNumber ? phoneNumber : null,
        phoneNumberCountryCode: phoneNumberCountryCode ? phoneNumberCountryCode : null,
        relationToUser: relationToUser,
        fullAddress: '',
        dateOfBirth: '',
        isEmergencyContact: false,
        isPrimaryCarer: isPrimaryCarer,
        isLivingWithUser: false,
        userContactId: userContactId,
        note: note ? note : null
      };
      this.setState({ isLoadingFilter: true });
      try {
        userContactId !== '' && userContactId !== undefined
          ? await doUpdateCustomerContact(payload)
          : await doCreateCustomerContact(payload);
        this.setState({ isLoadingFilter: false });
        this.props.closeCreateEditContactModal();
      } catch (e) {
        notification.error({ message: 'Error', description: e.message });
        this.setState({ isLoadingFilter: false });
      }
    }
  };

  render() {
    const { selectedCustomer, form, selectedContactId } = this.props;
    const { getFieldDecorator } = form;
    let selectedContact;

    if (!_.isEmpty(selectedCustomer.emergencyContacts)) {
      selectedContact = _.find(selectedCustomer.emergencyContacts, (contact) => {
        return contact.userContactId === selectedContactId;
      });
    }

    return (
      <div>
        <ActionModal
          isOpen={this.state.isActionModalOpen}
          onClose={this._closeActionModal}
          title={'Discard changes'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, proceeding will discard these changes.
          </Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton className="mr-medium" size="large" onClick={this._closeActionModal}>
              Cancel
            </PrimaryButton>
            <GhostButton size="large" onClick={this._closeActionCreateModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <FullScreenModal
          isOpen={this.props.isOpen}
          onClose={this.props.closeCreateEditContactModal}
          footerContent={
            <FullScreenModalFooter align={'left'}>
              <Row gutter={0} type="flex" align="middle">
                <Col span={8} />
                <Col span={8} />
                <Col span={8}>
                  <div className="text-align-right">
                    <div className="text-align-right">
                      <Text>
                        <span className="text-weight-bold">Hint</span> - You can exit this screen by pressing{' '}
                        <code>Esc</code>
                      </Text>
                    </div>
                  </div>
                </Col>
              </Row>
            </FullScreenModalFooter>
          }
        >
          <div className="anim-slide-left">
            <SpinningLoadingActionModel isOpen={this.state.isLoadingFilter} verticalAlignment={'highest'} />
            <Title level={2} className="text-weight-regular">
              {!_.isEmpty(selectedContact) && selectedContact !== 'undefined' ? 'Edit' : 'New'} <b>Contact</b>
            </Title>
            {!_.isEmpty(selectedContact) && selectedContact !== 'undefined' ? (
              <Paragraph>
                You are editing{' '}
                <span className="text-weight-bold">
                  {selectedCustomer.firstName} {selectedCustomer.lastName}
                </span>{' '}
                contact. Please enter the following details.
              </Paragraph>
            ) : (
              <Paragraph>
                You are adding a new contact to{' '}
                <span className="text-weight-bold">
                  {selectedCustomer.firstName} {selectedCustomer.lastName}
                </span>{' '}
                profile. Please enter the following details for this contact.
              </Paragraph>
            )}
            <div className={'customScrollDiv'}>
              <div className="mb-small">
                <SubTitle>Full Name</SubTitle>
                <FormItem className={'m-none'}>
                  {getFieldDecorator('fullname', {
                    initialValue:
                      !_.isEmpty(selectedContact) && selectedContact !== 'undefined' ? selectedContact.fullName : '',
                    rules: [{ validator: this._validateFullName }]
                  })(<Input placeholder="Enter full name" maxLength={50} />)}
                </FormItem>
              </div>
              <div className="mb-small">
                <SubTitle>Mobile Number</SubTitle>
                <Row type={'flex'} gutter={16}>
                  <Col>
                    <FormItem className={'m-none'}>
                      {getFieldDecorator('phoneNumberCountryCode', {
                        initialValue:
                          !_.isEmpty(selectedContact) && selectedContact !== 'undefined'
                            ? selectedContact.phoneNumberCountryCode
                            : 'AU'
                      })(
                        <Select placeholder={'Please select country code'} style={{ fontSize: '16px' }}>
                          <Option value={'AU'}>+61 (AU)</Option>
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                  <Col>
                    <FormItem className={'m-none'}>
                      {getFieldDecorator('phoneNumber', {
                        initialValue:
                          !_.isEmpty(selectedContact) && selectedContact !== 'undefined'
                            ? selectedContact.phoneNumber
                            : '',
                        rules: [
                          {
                            validator: this._validateMobileNumber
                          }
                        ]
                      })(<Input placeholder="Enter mobile number" />)}
                    </FormItem>
                  </Col>
                </Row>
              </div>
              <div className="mb-small">
                <SubTitle>Email Address</SubTitle>
                <FormItem className={'m-none'}>
                  {getFieldDecorator('email', {
                    initialValue:
                      !_.isEmpty(selectedContact) && selectedContact !== 'undefined' ? selectedContact.email : '',
                    rules: [
                      {
                        type: 'email',
                        message: 'Please enter valid Email'
                      }
                    ]
                  })(<Input placeholder="Enter email address" />)}
                </FormItem>
              </div>
              <div className="mb-small">
                <SubTitle>Relationship</SubTitle>
                <FormItem className={'m-none'}>
                  {getFieldDecorator('relationToUser', {
                    initialValue:
                      !_.isEmpty(selectedContact) && selectedContact !== 'undefined'
                        ? selectedContact.relationToUser
                        : 'Other',
                    rules: [{ required: true, message: 'Relationship to user is required' }]
                  })(
                    <Select
                      className={'width-full'}
                      placeholder={'Please select a relationship'}
                      size="large"
                      style={{ fontSize: '16px' }}
                    >
                      {this._renderRelationList()}
                    </Select>
                  )}
                </FormItem>
              </div>
              <div className={'mb-small'}>
                <FormItem className={'m-none'}>
                  {getFieldDecorator('isPrimaryCarer', {
                    initialValue:
                      !_.isEmpty(selectedContact) && selectedContact !== 'undefined'
                        ? selectedContact.isPrimaryCarer
                        : false
                  })(
                    <Checkbox
                      defaultChecked={
                        !_.isEmpty(selectedContact) && selectedContact !== 'undefined'
                          ? selectedContact.isPrimaryCarer
                          : false
                      }
                    />
                  )}{' '}
                  <b> Is this the primary contact?</b>
                  <Text type={'secondary'} className={'flex'}>
                    A customer can have only one Primary Contact.
                  </Text>
                  {getFieldDecorator('userContactId', {
                    initialValue:
                      !_.isEmpty(selectedContact) && selectedContact !== 'undefined'
                        ? selectedContact.userContactId
                        : ''
                  })(<Input type={'hidden'} />)}
                </FormItem>
              </div>
              <div className="mb-small">
                <SubTitle>Notes</SubTitle>
                <FormItem className={'m-none'}>
                  {getFieldDecorator('note', {
                    initialValue:
                      !_.isEmpty(selectedContact) && selectedContact !== 'undefined' ? selectedContact.note : ''
                  })(<TextArea placeholder="Leave a note here" />)}
                </FormItem>
              </div>
            </div>
            <div className={'mb-small'}>
              <Row type={'flex'} className={'justify-between'}>
                <Col>
                  <PrimaryButton onClick={this._saveCustomerContact}>
                    {!_.isEmpty(selectedContact) && selectedContact !== 'undefined'
                      ? 'Edit Contact'
                      : 'Create New Contact'}
                  </PrimaryButton>
                </Col>
                <Col>
                  <GhostButton onClick={this._closeWithActionModal}>Cancel</GhostButton>
                </Col>
              </Row>
            </div>
          </div>
        </FullScreenModal>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  relationships: state.customersStore.relationships
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateCustomerContact: dispatch.customersStore.doCreateCustomerContact,
  doGetRelationshipLists: dispatch.customersStore.doGetRelationshipLists,
  doUpdateCustomerContact: dispatch.customersStore.doUpdateCustomerContact
});

export default connect(mapState, mapDispatch)(Form.create<ICreateEditContactModalProps>()(CreateEditContactModal));
