// To make this obsolete; use the other Button Types instead of this.
import React, { PureComponent } from 'react';
import { Button } from 'antd';
import { isEmpty } from 'lodash';
import { ButtonProps } from 'antd/lib/button';
import { PORTAL_COLORS, PORTAL_FONT_WEIGHTS } from 'interfaces/common-ui-types';

// Type declarations
declare type PADDING_SIZES = 'small' | 'medium' | 'large' | 'none';

/**
 * Standard Button that handles onClick. See interface below for explanations.
 **/
interface ClickButtonProps extends ButtonProps {
  className?: string;
  color?: PORTAL_COLORS;
  fontColor?: PORTAL_COLORS;
  borderColor?: PORTAL_COLORS;
  paddingSize?: PADDING_SIZES;
  weight?: PORTAL_FONT_WEIGHTS;
  // shape?: 'circle' | 'circle-outline' | 'round' | string;
  children?: any;
}

interface ClickButtonState {}

class ObsoleteClickButton extends PureComponent<ClickButtonProps, ClickButtonState> {
  render() {
    // default values
    const {
      disabled,
      loading,
      type = 'default',
      size = 'default',
      weight,
      color,
      borderColor,
      fontColor,
      paddingSize,
      className = '',
      children,
      shape,
      ...otherProps
    } = this.props;

    // Temporary variables
    let finalBgColor = '';
    let finalFontColor = fontColor || '';
    let finalBorderColor = borderColor || '';
    const finalLoadingClass = loading ? 'dim cursor-wait ' : '';

    // small - ph-small, medium - ph, medium, large - ph-large, if empty then use ph-medium
    let finalPaddingSize = '';

    const finalShape = isEmpty(shape) ? 'round' : shape;

    // No padding size defined - use default based on size.
    if (isEmpty(paddingSize)) {
      if (size === 'small') {
        finalPaddingSize = 'ph-medium';
      } else if (size === 'default') {
        finalPaddingSize = 'ph-x-large';
      } else if (size === 'large') {
        finalPaddingSize = 'ph-x4-large';
      } else {
        // shouldn't end up here but anyway....
        finalPaddingSize = paddingSize;
      }
    } else {
      if (paddingSize === 'none') {
        // NONE
        finalPaddingSize = 'ph-none';
      } else if (paddingSize === 'small') {
        // SMALL
        finalPaddingSize = 'ph-medium';
      } else if (paddingSize === 'medium') {
        // MEDIUM
        finalPaddingSize = 'ph-large';
      } else if (paddingSize === 'large') {
        // MEDIUM
        finalPaddingSize = 'ph-x-large';
      } else {
        // assume that it's a custom class. just use whatever is passed in
        finalPaddingSize = paddingSize;
      }
    }

    // Loading needs a different padding space because of the loading i
    if (loading) {
      if (size === 'small') {
        finalPaddingSize = 'ph-medium';
      } else if (size === 'default') {
        finalPaddingSize = 'ph-x-large';
      } else if (size === 'large') {
        finalPaddingSize = 'ph-x4-large';
      } else {
        // shouldn't end up here but anyway....
        finalPaddingSize = paddingSize;
      }
    } else {
      if (paddingSize === 'none') {
        // NONE
        finalPaddingSize = 'ph-none';
      } else if (paddingSize === 'small') {
        // SMALL
        finalPaddingSize = 'ph-medium';
      } else if (paddingSize === 'medium') {
        // MEDIUM
        finalPaddingSize = 'ph-large';
      } else if (paddingSize === 'large') {
        // MEDIUM
        finalPaddingSize = 'ph-x-large';
      } else {
        // assume that it's a custom class. just use whatever is passed in
        finalPaddingSize = paddingSize;
      }
    }

    // if (loading) {
    //   finalPaddingSize =
    //     paddingSize === 'none'
    //       ? 'ph-none'
    //       : // Small
    //       'small'
    //       ? 'pl-large pr-small'
    //       : // Medium
    //       paddingSize === 'medium'
    //       ? 'pl-large pr-medium'
    //       : // Large
    //       paddingSize === 'large'
    //       ? 'pl-x-large pr-large'
    //       : 'pl-large pr-medium';
    // } else {
    //   finalPaddingSize =
    //     paddingSize === 'none'
    //       ? 'ph-none'
    //       : // Small
    //       'small'
    //       ? 'ph-small'
    //       : // Medium
    //       paddingSize === 'medium'
    //       ? 'ph-medium'
    //       : // Large
    //       paddingSize === 'large'
    //       ? 'ph-large'
    //       : 'ph-medium';
    // }

    if (shape === 'circle') {
      finalPaddingSize = '';
    }

    let finalHoverClasses = 'hover-dim';

    // Having color will always override the background color / font color / border color
    if (type === 'primary') {
      if (!isEmpty(color)) {
        finalBgColor = `bg-${color}`;
        finalFontColor = isEmpty(fontColor) ? 'text-color-white' : `text-color-${fontColor}`;
        finalBorderColor = isEmpty(borderColor) ? `border-${color}` : `border-${borderColor}`;
      }

      // if it's disabled, override all colors with bg-secondary/text-color-secondary instead
      if (disabled) {
        finalBgColor = `bg-secondary`;
        finalFontColor = 'text-color-secondary';
        finalBorderColor = 'bordered-none';
      }
    }

    if (type === 'default') {
      // use the color for background and border
      if (!isEmpty(color)) {
        finalBgColor = `bg-white`;
        finalFontColor = isEmpty(fontColor) ? `text-color-${color}-dark` : `text-color-${fontColor}`;
        finalBorderColor = isEmpty(borderColor) ? `border-${color}` : `border-${borderColor}`;

        let hoverColor = `hover-bg-${color}-light hover-border-${color} hover-text-color-white`;

        // Added an override for white color
        if (color === 'white') {
          finalFontColor = isEmpty(fontColor) ? `text-color-black` : `text-color-${fontColor}`;
          const hoverFontColor = isEmpty(fontColor)
            ? `hover-text-color-blue-dark`
            : `hover-text-color-${fontColor}-dark`;

          hoverColor = `hover-bg-white hover-border-tertiary ${hoverFontColor} hover-dim`;
        }

        if (color === 'black') {
          finalFontColor = isEmpty(fontColor) ? `text-color-black` : `text-color-${fontColor}`;
          const hoverFontColor = isEmpty(fontColor) ? `hover-text-color-black` : `hover-text-color-black`;

          hoverColor = `hover-bg-white hover-border-tertiary ${hoverFontColor} hover-dim`;
        }

        finalHoverClasses = `${hoverColor} dim`;
      } else {
        // use default blue
        finalBgColor = `bg-white`;
        finalFontColor = isEmpty(fontColor) ? `text-color-blue-dark` : `text-color-${fontColor}`;
        finalBorderColor = isEmpty(borderColor) ? `border-blue` : `border-${borderColor}`;
        finalHoverClasses = `hover-bg-blue-light hover-text-color-white dim`;
      }

      // if it's disabled, override all colors with bg-secondary/text-color-secondary instead
      if (disabled) {
        finalBgColor = `bg-white`;
        finalFontColor = 'text-color-secondary';
        finalBorderColor = 'border-none';
        finalHoverClasses = 'hover-bg-tertiary dim';
      }
    }

    if (type === 'link') {
      // color becomes the highlight color of the link
      finalFontColor = isEmpty(fontColor) ? `text-color-${color}-darker` : `text-color-${fontColor}`;
      finalHoverClasses = isEmpty(color) ? `hover-bg-tertiary ` : `hover-bg-${color}-lightest dim`;

      // if it's disabled, override all colors with bg-secondary/text-color-secondary instead
      if (disabled) {
        finalBgColor = `bg-white`;
        finalFontColor = 'text-color-secondary';
        finalBorderColor = 'border-secondary';
        finalHoverClasses = 'hover-bg-tertiary dim';
      }
    }

    const finalWeightClass = isEmpty(weight) ? 'text-weight-regular' : `text-weight-${weight}`;

    // let finalClassName = `${finalWeightClass} ${finalBgColor} ${finalFontColor} ${finalBorderColor} ${finalPaddingSize} ${finalLoadingClass} ${finalHoverClasses} ${className}`;
    const finalClassName = `${finalWeightClass} ${finalBgColor} ${finalFontColor} ${finalBorderColor} ${finalPaddingSize} ${finalLoadingClass} ${finalHoverClasses} ${className}`;

    return (
      <Button
        size={size}
        className={finalClassName}
        {...otherProps}
        type={type}
        disabled={disabled}
        loading={loading}
        shape={finalShape}
      >
        {children}
      </Button>
    );
  }
}

export default ObsoleteClickButton;
