import { Tabs } from 'antd';
import { Title } from 'common-components/typography';
import * as H from 'history';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IRootDispatch, IRootState } from 'src/stores/rematch/root-store';
import PlanManagementCustomerOverviewPanel from 'views/plan-management/listing/PlanManagementCustomerOverviewPanel';
import PlanManagementInvoiceOverviewPanel from 'views/plan-management/listing/PlanManagementInvoiceOverviewPanel';
import PlanManagementSupplierOverviewPanel from 'views/plan-management/listing/PlanManagementSupplierOverviewPanel';
import '../css/plan-management-listing.css';

const { TabPane } = Tabs;

interface ITeamManagementViewProps extends RouteComponentProps<{}, {}, { selectedTab?: any }> {
  history: H.History;
}

interface ITeamManagementViewState {
  selectedTab: string;
}

class PlanManagementView extends Component<ITeamManagementViewProps, ITeamManagementViewState> {
  state = {
    selectedTab:
      this.props.location && this.props.location.state && this.props.location.state.selectedTab
        ? this.props.location.state.selectedTab
        : 'INVOICES'
  };

  private _changeTab = async (newTab) => {
    this.setState({ selectedTab: newTab });
  };

  render() {
    const { history, location } = this.props;
    const { selectedTab } = this.state;

    // Reset the location.state.selectedTab once it's consumed by the class state.
    if (location && location.state && location.state.selectedTab) {
      window.history.replaceState(location.state.selectedTab, null);
    }

    return (
      <div
        className="bg-white bordered-top border-width-medium border-tertiary"
        style={{ height: 'calc(100vh - 64px)', overflow: 'auto' }}
      >
        <div className={'plan-management-header'}>
          <Title level={3} className="mv-none" lineHeight={150}>
            Plan Management
          </Title>
          <div className="mt-large">
            <Tabs activeKey={selectedTab} animated={true} onChange={this._changeTab} className={'plan-management-tab'}>
              <TabPane tab="Invoices" key="INVOICES" />
              <TabPane tab="Customers" key="CUSTOMERS" />
              <TabPane tab="Providers" key="PROVIDERS" />
            </Tabs>
          </div>
        </div>
        <div
          className="bg-white ph-x-large"
          // style={{ height: 'calc(100vh - 213px)', overflowY: 'auto', position: 'relative' }}
          id="content-container"
        >
          {this.state.selectedTab === 'INVOICES' && (
            <PlanManagementInvoiceOverviewPanel key={'INVOICES'} history={history} />
          )}
          {this.state.selectedTab === 'CUSTOMERS' && (
            <PlanManagementCustomerOverviewPanel key={'CUSTOMERS'} history={history} />
          )}
          {this.state.selectedTab === 'PROVIDERS' && (
            <PlanManagementSupplierOverviewPanel key={'PROVIDERS'} history={history} />
          )}
        </div>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  // If necessary
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  // If necesssary
});

export default connect(mapState, mapDispatch)(PlanManagementView);
