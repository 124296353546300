/* eslint-disable */
import React, { PureComponent, ReactNode } from 'react';
import { Icon } from 'antd';

interface IFilterWarningProps {
  className?: string;
  icon?: string;
  content: ReactNode;
  displayType?: 'block' | 'inline-block' | 'inline';
}

class Information extends PureComponent<IFilterWarningProps, any> {
  render() {
    const { className = '', icon = 'info-circle', content, displayType = 'inline-block' } = this.props;

    let finalClassName = `bordered-left border border-info-blue border-width-x-large bg-info-blue ${displayType} `;

    finalClassName += className;

    return (
      <div className={finalClassName}>
        <div className="flex p-small">
          <div>
            <Icon type={icon} theme="filled" className="text-size-x2-large text-color-info-blue" />
          </div>
          <div className="ml-small pr-x-large">{content}</div>
        </div>
      </div>
    );
  }
}

export default Information;
