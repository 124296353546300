import React from 'react';
import { ICareInfo, ICustomer } from 'interfaces/customer-interfaces';
import { Button, Col, Icon, List, Popover, Row, Tabs } from 'antd';
import Title from 'antd/lib/typography/Title';
import { Paragraph, Text } from 'common-components/typography';
import CareInformationFilter from '../../components/CareInformationFilter';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import AllCareInformation from 'views/customers/details/tabs-panel/care-information/tabs-panel/all-care-information/AllCareInformationPanel';
import { AlertLevel, CareInformationType } from 'utilities/enum-utils';
import _ from 'lodash';
import { ReactComponent as WheelChairSvg } from 'assets/Icons/careinfo-icons/wheelchair_icon.svg';
import { ReactComponent as MedicationSvg } from 'assets/Icons/careinfo-icons/medication_icon.svg';
import { ReactComponent as HearingAidSvg } from 'assets/Icons/careinfo-icons/hearing-aid_icon.svg';
import { ReactComponent as EmoticonPlusSvg } from 'assets/Icons/careinfo-icons/emoticon-plus_icon.svg';
import { ReactComponent as GlobeSvg } from 'assets/Icons/careinfo-icons/globe_icon.svg';

const { TabPane } = Tabs;

interface ICareInformationPanelProps {
  selectedCustomer: ICustomer;
  hasEditPermission: boolean;
  selectedCustomerCareInfoList: typeof state.customersStore.selectedCustomerCareInfoList;
  doFetchCareInfoList: typeof dispatch.customersStore.doFetchCareInfoList;
}

interface ICareInformationPanelState {
  careInformationList: ICareInfo[];
  filteredCareInformationList: ICareInfo[];
  levelFilter: AlertLevel;
  selectedTab: string;
  showAddCareInfoModal: CareInformationType;
}

class CareInformationPanel extends React.Component<ICareInformationPanelProps, ICareInformationPanelState> {
  state = {
    careInformationList: [],
    filteredCareInformationList: [],
    levelFilter: AlertLevel.ALL,
    selectedTab: 'ALL_TAB',
    showAddCareInfoModal: null
  };

  private _setFilter = (filterName) => {
    this.setState({ levelFilter: filterName });
  };

  private _setSelectedTab = async (tab: string) => {
    await this.setState({ filteredCareInformationList: [], selectedTab: tab, levelFilter: AlertLevel.ALL });
    this._setFilteredCareInformationList(tab);
  };

  private _setFilteredCareInformationList = (tab: string) => {
    const { careInformationList } = this.state;
    let filteredCareInformationList = careInformationList;

    if (tab === 'GENERAL') {
      filteredCareInformationList = this._filterCareInfoList(careInformationList, CareInformationType.GENERAL);
    } else if (tab === 'CONDITIONS') {
      filteredCareInformationList = this._filterCareInfoList(careInformationList, CareInformationType.PERMANENT);
    } else if (tab === 'MEDICATIONS') {
      filteredCareInformationList = this._filterCareInfoList(careInformationList, CareInformationType.MEDICATION);
    } else if (tab === 'SUPPORT_NEEDS') {
      filteredCareInformationList = this._filterCareInfoList(careInformationList, CareInformationType.SUPPORTNEEDS);
    } else if (tab === 'BEHAVIOUR') {
      filteredCareInformationList = this._filterCareInfoList(careInformationList, CareInformationType.BEHAVIOUR);
    }

    this.setState({ filteredCareInformationList });
  };

  private _filterCareInfoList = (careInfoList: ICareInfo[], careInfoType: CareInformationType): ICareInfo[] => {
    const filteredCareInformationList = careInfoList.filter((info) => info.careInformationType === careInfoType);
    return filteredCareInformationList;
  };

  private _sortCareInfoList = async () => {
    const { selectedCustomerCareInfoList } = this.props;
    let careInformationList = [];
    if (!_.isEmpty(selectedCustomerCareInfoList))
      careInformationList = selectedCustomerCareInfoList.sort((a: ICareInfo, b: ICareInfo) => {
        let result = a.alertLevel - b.alertLevel;
        if (result === 0) {
          result = a.careInformationType - b.careInformationType;
        }

        return result;
      });
    await this.setState({ careInformationList });
    this._setFilteredCareInformationList(this.state.selectedTab);
  };

  private _handleOpenAddCareInfoModal = (careInfoType: CareInformationType) => {
    this.setState({ showAddCareInfoModal: careInfoType });
  };
  private _handleCloseAddCareInfoModal = () => {
    this.setState({ showAddCareInfoModal: null });
  };

  private _renderAddCareInfoPopover = () => {
    const menu = [
      {
        type: CareInformationType.PERMANENT,
        icon: <Icon component={WheelChairSvg} />,
        title: 'Permanent Condition',
        description:
          'For permanent conditions and disabilities such as Intellectual disabilities, physical disabilities, or any other diagnose conditions.'
      },
      {
        type: CareInformationType.MEDICATION,
        icon: <Icon component={MedicationSvg} />,
        title: 'Medication',
        description: 'For prescribe or OTC medications that are required o be taken by the customer.'
      },
      {
        type: CareInformationType.SUPPORTNEEDS,
        icon: <Icon component={HearingAidSvg} />,
        title: 'Support needs',
        description: 'For support needs such as assistance bathing, walking, changing clothes and eating.'
      },
      {
        type: CareInformationType.BEHAVIOUR,
        icon: <Icon component={EmoticonPlusSvg} />,
        title: 'Behaviour',
        description:
          'For important behaviours that this customer exhibits as well as the corresponding management strategy.'
      },
      {
        type: CareInformationType.GENERAL,
        icon: <Icon component={GlobeSvg} />,
        title: 'General care information',
        description: 'For any important care information that does not fit into above categories.'
      }
    ];
    return (
      <Row>
        <List
          className="max-width-580"
          dataSource={menu}
          renderItem={(item) => (
            <Row
              align="top"
              className="bg-quaternary hover-bg-blue-lightest p-small mb-small rounded cursor-pointer"
              onClick={() => this._handleOpenAddCareInfoModal(item.type)}
            >
              <Col span={1} className="text-align-center">
                {item.icon}
              </Col>
              <Col span={23} className="pl-small">
                <Text className="text-weight-bold">{item.title}</Text>
                <br />
                <Paragraph className="mb-none mt-x-small">{item.description}</Paragraph>
              </Col>
            </Row>
          )}
        />
      </Row>
    );
  };

  private _onLoad = async () => {
    const { selectedCustomer, doFetchCareInfoList } = this.props;
    await doFetchCareInfoList(selectedCustomer);
    this._sortCareInfoList();
  };

  componentDidMount() {
    this._onLoad();
  }

  componentDidUpdate(prevProps: Readonly<ICareInformationPanelProps>, prevState: Readonly<ICareInformationPanelState>) {
    if (!_.isEqual(prevProps.selectedCustomerCareInfoList, this.props.selectedCustomerCareInfoList)) {
      this._sortCareInfoList();
    }
  }

  render() {
    const { selectedCustomer, hasEditPermission } = this.props;
    const {
      careInformationList,
      filteredCareInformationList,
      levelFilter,
      showAddCareInfoModal,
      selectedTab
    } = this.state;
    return (
      <>
        <Row type={'flex'} justify={'space-between'} align={'bottom'}>
          <Col>
            <Title level={3} className={'m-none'}>
              Care information
            </Title>
            <Text type={'secondary'}>View and manage this customer care information</Text>
          </Col>
          <Col className={'align-center'}>
            <div className={'flex-row-reverse align-center'}>
              {hasEditPermission && (
                <Popover content={this._renderAddCareInfoPopover()} placement="bottomRight" trigger="focus">
                  <Button type="primary" className="bg-blue-action hover-bg-blue">
                    Add... <Icon type="down" />
                  </Button>
                </Popover>
              )}
            </div>
          </Col>
        </Row>
        <div className={'pv-large'}>
          <Row>
            <Col>
              <Tabs
                type="card"
                className={'customer-tab mb-x-large'}
                activeKey={selectedTab}
                onChange={this._setSelectedTab}
                tabBarExtraContent={
                  <CareInformationFilter
                    itemList={filteredCareInformationList}
                    filterValue={levelFilter}
                    itemName={'care information'}
                    setFilter={this._setFilter}
                  />
                }
              >
                <TabPane key="ALL_TAB" tab="All">
                  <AllCareInformation
                    isActiveTab={selectedTab === 'ALL_TAB'}
                    careInfo={careInformationList}
                    selectedCustomer={selectedCustomer}
                    hasEditPermission={hasEditPermission}
                    levelFilter={levelFilter}
                    showAddCareInfoModal={showAddCareInfoModal}
                    onCloseModal={this._handleCloseAddCareInfoModal}
                  />
                </TabPane>
                <TabPane key="GENERAL" tab="General">
                  <AllCareInformation
                    isActiveTab={selectedTab === 'GENERAL'}
                    careInfo={filteredCareInformationList}
                    selectedCustomer={selectedCustomer}
                    hasEditPermission={hasEditPermission}
                    levelFilter={levelFilter}
                    showAddCareInfoModal={showAddCareInfoModal}
                    onCloseModal={this._handleCloseAddCareInfoModal}
                  />
                </TabPane>
                <TabPane key="CONDITIONS" tab="Permanent Condition">
                  <AllCareInformation
                    isActiveTab={selectedTab === 'CONDITIONS'}
                    careInfo={filteredCareInformationList}
                    selectedCustomer={selectedCustomer}
                    hasEditPermission={hasEditPermission}
                    levelFilter={levelFilter}
                    showAddCareInfoModal={showAddCareInfoModal}
                    onCloseModal={this._handleCloseAddCareInfoModal}
                  />
                </TabPane>
                <TabPane key="MEDICATIONS" tab="Medications">
                  <AllCareInformation
                    isActiveTab={selectedTab === 'MEDICATIONS'}
                    careInfo={filteredCareInformationList}
                    selectedCustomer={selectedCustomer}
                    hasEditPermission={hasEditPermission}
                    levelFilter={levelFilter}
                    showAddCareInfoModal={showAddCareInfoModal}
                    onCloseModal={this._handleCloseAddCareInfoModal}
                  />
                </TabPane>
                <TabPane key="SUPPORT_NEEDS" tab="Support Needs">
                  <AllCareInformation
                    isActiveTab={selectedTab === 'SUPPORT_NEEDS'}
                    careInfo={filteredCareInformationList}
                    selectedCustomer={selectedCustomer}
                    hasEditPermission={hasEditPermission}
                    levelFilter={levelFilter}
                    showAddCareInfoModal={showAddCareInfoModal}
                    onCloseModal={this._handleCloseAddCareInfoModal}
                  />
                </TabPane>
                <TabPane key="BEHAVIOUR" tab="Behaviour">
                  <AllCareInformation
                    isActiveTab={selectedTab === 'BEHAVIOUR'}
                    careInfo={filteredCareInformationList}
                    selectedCustomer={selectedCustomer}
                    hasEditPermission={hasEditPermission}
                    levelFilter={levelFilter}
                    showAddCareInfoModal={showAddCareInfoModal}
                    onCloseModal={this._handleCloseAddCareInfoModal}
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  selectedCustomerCareInfoList: state.customersStore.selectedCustomerCareInfoList
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doFetchCareInfoList: dispatch.customersStore.doFetchCareInfoList,
  doDeleteCustomerSensitivity: dispatch.customersStore.doDeleteCustomerSensitivity
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CareInformationPanel);
