import React, { Component } from 'react';

import BlockAccessModal from 'views/account-management/components/TeamManagementModals/BlockAccessModal';
import PermanentlyRemoveUserModal from 'views/account-management/components/TeamManagementModals/PermanentlyRemoveUserModal';
import UnblockAccess from 'views/account-management/components/TeamManagementModals/UnblockAccessModal';
import InviteUserModal from 'views/account-management/components/TeamManagementModals/InviteUserModal';
import DeleteDraftUserModal from 'views/account-management/components/TeamManagementModals/DeleteDraftUserModal';
import RevokeInvitationModal from 'views/account-management/components/TeamManagementModals/RevokeInvitationModal';
import ReSendInvitationModal from 'views/account-management/components/TeamManagementModals/ReSendInvitationModal';
import ModifyInvitationModal from 'views/account-management/components/TeamManagementModals/ModifyInvitationModal';
import EditPermissionsModal from 'views/account-management/edit-permissions/EditPermissionsModal';
import ReSendRevokedInvitationModal from 'views/account-management/components/TeamManagementModals/ReSendRevokedInvitationModal';
import ViewRemovalReasonModal from 'views/account-management/components/TeamManagementModals/ViewRemovalReasonModal';
import ReEnableUserModal from 'views/account-management/components/TeamManagementModals/ReEnableUserModal';
import EditRemovalDateModal from 'views/account-management/components/TeamManagementModals/EditRemovalDateModal';
import CancelRemovalDateModal from 'views/account-management/components/TeamManagementModals/CancelRemovalDateModal';

interface ITeamManagementModalsProps {
  isOpen: any;
  onClose: any;
  selectedModal: string;
  data: any;
}

interface ITeamManagementModalsState {}

class TeamManagementModals extends Component<ITeamManagementModalsProps, ITeamManagementModalsState> {
  render() {
    const { isOpen, selectedModal, data } = this.props;

    return (
      <>
        <BlockAccessModal
          isOpen={selectedModal === 'BlockAccessModal' && isOpen}
          onClose={this.props.onClose}
          worker={data}
        />
        <UnblockAccess
          isOpen={selectedModal === 'UnblockAccessModal' && isOpen}
          onClose={this.props.onClose}
          worker={data}
        />
        <PermanentlyRemoveUserModal
          isOpen={selectedModal === 'PermanentlyRemoveUserModal' && isOpen}
          onClose={this.props.onClose}
          worker={data}
        />
        <InviteUserModal
          isOpen={selectedModal === 'InviteUserModal' && isOpen}
          onClose={this.props.onClose}
          worker={data}
        />
        <DeleteDraftUserModal
          isOpen={selectedModal === 'DeleteDraftUserModal' && isOpen}
          onClose={this.props.onClose}
          worker={data}
        />
        <RevokeInvitationModal
          isOpen={selectedModal === 'RevokeInvitationModal' && isOpen}
          onClose={this.props.onClose}
          invite={data}
        />
        <ReSendInvitationModal
          isOpen={selectedModal === 'ReSendInvitationModal' && isOpen}
          onClose={this.props.onClose}
          invite={data}
        />
        <ReSendRevokedInvitationModal
          isOpen={selectedModal === 'ReSendRevokedInvitationModal' && isOpen}
          onClose={this.props.onClose}
          invite={data}
        />
        <ModifyInvitationModal
          isOpen={selectedModal === 'ModifyInvitationModal' && isOpen}
          onClose={this.props.onClose}
          invite={data}
        />
        <EditPermissionsModal
          isOpen={selectedModal === 'EditPermissionModal' && isOpen}
          onClose={this.props.onClose}
          worker={data}
        />
        <ViewRemovalReasonModal
          isOpen={selectedModal === 'ViewRemovalReason' && isOpen}
          onClose={this.props.onClose}
          worker={data}
        />

        <ReEnableUserModal
          isOpen={selectedModal === 'ReEnableUser' && isOpen}
          onClose={this.props.onClose}
          worker={data}
        />
        <EditRemovalDateModal
          isOpen={selectedModal === 'EditRemovalDateModal' && isOpen}
          onClose={this.props.onClose}
          worker={data}
        />
        <CancelRemovalDateModal
          isOpen={selectedModal === 'CancelRemovalDateModal' && isOpen}
          onClose={this.props.onClose}
          worker={data}
        />
      </>
    );
  }
}

export default TeamManagementModals;
