import React, { Component } from 'react';
import { Col, Icon, Row } from 'antd';
import { connect } from 'react-redux';
import { Paragraph, Title } from 'common-components/typography';
import { PrimaryButton } from 'common-components/buttons';
import { IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import NewScheduleDetailPanel from 'views/group-services/service-details/Create/components/NewScheduleDetailPanel';

interface ICreationCompletedStepProps {
  closeWizard: () => void;
  goToSession: (serviceDateTimeId) => void;
  newGroupServiceSchedule: typeof state.servicesStore.newGroupServiceSchedule;
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
}

interface ICreationCompletedStepState {
  isLoading: boolean;
}

class CreationCompletedStep extends Component<ICreationCompletedStepProps, ICreationCompletedStepState> {
  state = {
    isLoading: false
  };

  render() {
    const { selectedGroupService, newGroupServiceSchedule } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 88px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Title level={4}>Session created</Title>
              <Paragraph>
                <b>Nice work!</b> You've <>successfully created</> this session.
              </Paragraph>
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 88px)' }}>
            <div className="bg-white p-large rounded-big" style={{ minWidth: '250px' }}>
              <Title level={4} color="text-color-blue-action">
                <Icon type={'check-circle'} theme={'filled'} className={'mr-small text-color-blue-action'} />
                Schedule successfully created
              </Title>
              <Paragraph>
                This session has been successfully created for{' '}
                <b>{selectedGroupService && selectedGroupService.serviceName}</b>
              </Paragraph>
              <NewScheduleDetailPanel />
            </div>
            <div
              className="pv-medium width-full"
              style={{ backgroundColor: 'rgba(248,249,250,0.7)', position: 'sticky', bottom: 0 }}
            >
              <Row gutter={0} type="flex" align="middle" justify={'end'} className="bg-transparent">
                <div className="text-align-right pv-medium">
                  {newGroupServiceSchedule && newGroupServiceSchedule.serviceDateTimeId && (
                    <PrimaryButton
                      size="large"
                      className={'mr-large'}
                      loading={isLoading}
                      onClick={() => this.props.goToSession(newGroupServiceSchedule.serviceDateTimeId)}
                    >
                      Go to{' '}
                      {newGroupServiceSchedule.sessions && newGroupServiceSchedule.sessions.length > 1 ? 'first ' : ' '}{' '}
                      session
                    </PrimaryButton>
                  )}
                  <PrimaryButton size="large" disabled={isLoading} onClick={this.props.closeWizard}>
                    Close
                  </PrimaryButton>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  newGroupServiceSchedule: state.servicesStore.newGroupServiceSchedule,
  selectedGroupService: state.groupServiceStore.selectedGroupService
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateNewSession: dispatch.servicesStore.doCreateNewSession
});

export default connect(
  mapState,
  mapDispatch
)(CreationCompletedStep);
