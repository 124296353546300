/* eslint-disable */
import React, { Component } from 'react';
import { IRootDispatch, IRootState, dispatch } from 'stores/rematch/root-store';

import { connect } from 'react-redux';
import { Button, Checkbox, Col, notification, Row, Tag } from 'antd';
import { Card, ProgressBar } from '@blueprintjs/core';
import { Paragraph, Text, Title } from 'common-components/typography';
import { IBillingBooking, IBillingLineItem } from 'interfaces/booking-interfaces';
import DefaultLayout from 'layouts/DefaultLayout';
import BookingApproveLineItem from './approve/BookingApproveLineItem';
import BookingApproveFilterDrawer from './approve/BookingApproveFilterDrawer';

import moment from 'moment';
import _ from 'lodash';
import { GhostButton, PrimaryButton, SecondaryButton } from '../../common-components/buttons';

interface IBookingApproveViewProps {
  billingBookings: IBillingBooking[];
  doFetchBillingLineItemsToApprove: typeof dispatch.billingsStore.doFetchBillingLineItemsToApprove;
  doApproveBillingLineItems: typeof dispatch.billingsStore.doApproveBillingLineItems;
  doSendToFinanceBillingLineItems: typeof dispatch.billingsStore.doSendToFinanceBillingLineItems;
  setBillingBookingsSelected: typeof dispatch.billingsStore.setBillingBookingsSelected;
  setBillingBookingsFilter: typeof dispatch.billingsStore.setBillingBookingsFilter;
  billingBookingsFilter: any;
  isSelectedAll: boolean;
}

interface IBookingApproveViewState {
  isLoading: boolean;
  isApprove: boolean;
  isSendToFinance: boolean;
  isSaving: boolean;
  filterDrawerVisible: boolean;
}

class BookingApproveView extends Component<IBookingApproveViewProps, IBookingApproveViewState> {
  state = {
    isLoading: false,
    isApprove: false,
    isSendToFinance: false,
    isSaving: false,
    filterDrawerVisible: false
  };

  _loadBookings = async (bookingStatus, bookingDateRange) => {
    this.setState({ isLoading: true });
    const status = _.map(bookingStatus, (value) => {
      return { display: value, value };
    });

    const dateRange = _.map(bookingDateRange, (date) => {
      return { display: date.format('DD MMMM YYYY'), value: date.toDate() };
    });

    const payload = {
      status,
      dateRange
    };

    await this.props.doFetchBillingLineItemsToApprove(payload);
    this.props.setBillingBookingsFilter(payload);
    this.setState({ isLoading: false });
  };

  _onApplyFilter = async (filter: any) => {
    await this._loadBookings(filter.bookingStatus, filter.bookingDateRange);
  };

  private _showFilter = () => {
    this.setState({ filterDrawerVisible: true });
  };

  private _onCloseFilterDrawer = () => {
    this.setState({ filterDrawerVisible: false });
  };

  private _onChangeSelectAll = (e) => {
    this.props.setBillingBookingsSelected(e.target.checked);
  };

  private _onClickApprove = async () => {
    // TODO show error notifications
    this.setState({ isSaving: true, isApprove: true });

    try {
      await this.props.doApproveBillingLineItems({});
      notification.success({
        message: 'Billing line items approved',
        description: 'The billing line items has been approved.'
      });
    } catch (error) {
      console.log('error', error);
      notification.error({ description: error.meta.message, message: error.message });
    }

    this.setState({ isSaving: false, isApprove: false });
  };

  private _onClickSendToFinance = async () => {
    // TODO show error notifications
    this.setState({ isSaving: true, isSendToFinance: true });

    try {
      await this.props.doSendToFinanceBillingLineItems({});
      notification.success({
        message: 'Billing line items sent to finance',
        description: 'The billing line items has sent to finance.'
      });
    } catch (error) {
      notification.error({ description: error.meta.message, message: error.message });
    }
    this.setState({ isSaving: false, isSendToFinance: false });
  };

  componentDidMount = async () => {
    const bookingStatus = ['COMPLETED', 'APPROVED', 'BILLING_FAILED'];
    const bookingDateRange = [moment().add(-6, 'month'), moment()];
    await this._loadBookings(bookingStatus, bookingDateRange);
  };

  render() {
    const { billingBookingsFilter } = this.props;

    if (this.state.isLoading) {
      return (
        <Card className="m-medium">
          <div className="mb-medium">
            <Text>Fetching bookings...</Text>
          </div>
          <ProgressBar animate={true} />
        </Card>
      );
    }

    return (
      <DefaultLayout backgroundColor={'#fff'} bordered>
        <div className={'mt-x-large mb-x-large'}>
          <Row type={'flex'} justify={'space-between'}>
            <Col>
              <Title level={2}>Bookings approval</Title>
            </Col>
            <Col>
              {/* <Button icon={'reload'} type={'link'} className="mr-small" onClick={() => refreshContent(null)} /> */}
            </Col>
          </Row>

          <Row type={'flex'} justify={'space-between'} className="mb-x-large">
            <Col>
              <Paragraph>
                The following bookings have been completed, and are ready to be billed. <br /> Please select one or more
                row to proceed.
              </Paragraph>
            </Col>
            <Col>
              <div className={'flex-row align-center'}>
                <GhostButton
                  className={'mr-medium'}
                  icon={'filter'}
                  onClick={this._showFilter}
                  disabled={this.state.isSaving}
                  size={'large'}
                >
                  Filter
                </GhostButton>

                <PrimaryButton
                  icon={'check'}
                  className={'mr-medium'}
                  loading={this.state.isApprove}
                  disabled={this.state.isSaving}
                  onClick={this._onClickApprove}
                  size={'large'}
                >
                  Approve
                </PrimaryButton>
                <PrimaryButton
                  icon={'bank'}
                  loading={this.state.isSendToFinance}
                  disabled={this.state.isSaving}
                  onClick={this._onClickSendToFinance}
                  size={'large'}
                >
                  Send To Finance
                </PrimaryButton>

                {/* <Text className={'pr-medium'}>112 selected items</Text> */}
              </div>
            </Col>
          </Row>
          <Row type={'flex'} justify={'space-between'} className={'align-center'}>
            <Col>
              <div className={'text-size-small'}>Filter:</div>
              {billingBookingsFilter.dateRange && billingBookingsFilter.dateRange.length > 0 && (
                <Tag className="text-size-small">
                  {billingBookingsFilter.dateRange[0].display} - {billingBookingsFilter.dateRange[1].display}
                </Tag>
              )}
              {_.map(billingBookingsFilter.status, (status, index) => {
                return (
                  <Tag key={index} className="text-size-small">
                    {status.display}
                  </Tag>
                );
              })}
            </Col>
            <Col />
          </Row>
        </div>
        <div className="bordered-bottom pv-small">
          <Row gutter={32} className={'ph-medium'}>
            <Col span={1}>
              <Checkbox onChange={this._onChangeSelectAll} checked={this.props.isSelectedAll} />
            </Col>
            <Col span={3}>
              <Text>
                <b>Name</b>
              </Text>
            </Col>
            <Col span={4}>
              <Text>
                <b>Start Date</b>
              </Text>
            </Col>
            <Col span={4}>
              <Text>
                <b>End Date</b>
              </Text>
            </Col>
            <Col span={7}>
              <Text>
                <b>Service Name</b>
              </Text>
            </Col>
            <Col span={2}>
              <Text>
                <b>Status</b>
              </Text>
            </Col>
            <Col span={2}>
              <Text>
                <b>Total</b>
              </Text>
            </Col>
            <Col span={1} />
          </Row>
        </div>
        {_.map(this.props.billingBookings, (booking, index) => {
          return <BookingApproveLineItem key={index} index={index} billingBooking={booking} />;
        })}
        <BookingApproveFilterDrawer
          onCloseFilterDrawer={this._onCloseFilterDrawer}
          filterDrawerVisible={this.state.filterDrawerVisible}
          onApplyFilter={this._onApplyFilter}
        />
      </DefaultLayout>
    );
  }
}

const mapState = (state: IRootState) => ({
  isSelectedAll: state.billingsStore.billingBookingsSelectedAll,
  billingBookings: state.billingsStore.billingBookings,
  billingBookingsFilter: state.billingsStore.billingBookingsFilter
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchBillingLineItemsToApprove: dispatch.billingsStore.doFetchBillingLineItemsToApprove,
  doApproveBillingLineItems: dispatch.billingsStore.doApproveBillingLineItems,
  doSendToFinanceBillingLineItems: dispatch.billingsStore.doSendToFinanceBillingLineItems,
  setBillingBookingsFilter: dispatch.billingsStore.setBillingBookingsFilter,
  setBillingBookingsSelected: dispatch.billingsStore.setBillingBookingsSelected
});

export default connect(
  mapState,
  mapDispatch
)(BookingApproveView);
