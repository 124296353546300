import React, { Component } from 'react';
import ServiceAgreementItem from 'views/customers/details/tabs-panel/new-service-agreements/agreements-listing/ServiceAgreementItem';
import DeleteServiceAgreementModal from 'views/customers/details/tabs-panel/new-service-agreements/agreements-listing/DeleteServiceAgreementModal';
import ChangeStatusServiceAgreementModal from 'views/customers/details/tabs-panel/new-service-agreements/agreements-listing/ChangeStatusServiceAgreementModal';
import EditServiceAgreementDatesModal from 'views/customers/details/tabs-panel/new-service-agreements/agreements-listing/EditServiceAgreementDatesModal';
import EditServiceAgreementTermConditionsModal from 'views/customers/details/tabs-panel/new-service-agreements/agreements-listing/EditServiceAgreementTermConditionsModal';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { Empty, Skeleton, Tabs } from 'antd';
import EditServiceAgreementServiceItemsModal from 'views/customers/details/tabs-panel/new-service-agreements/agreements-listing/EditServiceAgreementServiceItemsModal';
import { IServiceAgreement } from 'interfaces/customer-interfaces';
import EditQuoteModal from 'views/customers/details/tabs-panel/new-service-agreements/agreements-listing/EditQuoteModal';
import ViewQuoteModal from 'views/customers/details/tabs-panel/new-service-agreements/agreements-listing/ViewQuoteModal';
import ViewBudgetTrackingDetailModal from 'views/customers/details/tabs-panel/new-service-agreements/agreements-listing/ViewBudgetTrackingDetailModal';
import { Text } from 'common-components/typography';
import { PaymentSources } from 'utilities/enum-utils';

interface IServiceAgreementsListProps {
  customerServiceAgreements: typeof state.customersStore.customerServiceAgreements;
  doFetchServiceAgreements: typeof dispatch.customersStore.doFetchServiceAgreements;
  hasEditPermission: boolean;
  paymentSourceFilter?: string;
  companyDataLite: typeof state.companyStore.companyDataLite;
  openedServiceAgreementId: string;
}

interface IServiceAgreementsListState {
  isLoading: boolean;
  isDeleteOpen: boolean;
  isChangeStatusOpen: boolean;
  isEditDatesOpen: boolean;
  isEditTAndC: boolean;
  isEditServiceItems: boolean;
  isEditQuote: boolean;
  isViewQuote: boolean;
  isViewTracking: boolean;
  newStatus: string;
  selectedServiceAgreement: IServiceAgreement;
  selectedTracking: any;
  page: number;
  pageSize: number;
  pageTimestamp: any;
  selectedTab: string;
}

const ServiceAgreementEmptyState = () => (
  <div className="flex-1 bg-white mt-large align-center flex-column mb-x-large">
    <div className="">
      <Empty description={false} image={Empty.PRESENTED_IMAGE_SIMPLE} className="mv-none" />
    </div>
    <Text size="x2-large" color="secondary" weight="bold">
      No service agreement found.
    </Text>
  </div>
);

class ServiceAgreementsList extends Component<IServiceAgreementsListProps, IServiceAgreementsListState> {
  state = {
    isLoading: false,
    isDeleteOpen: false,
    isChangeStatusOpen: false,
    isEditDatesOpen: false,
    isEditTAndC: false,
    isEditServiceItems: false,
    isEditQuote: false,
    isViewQuote: false,
    isViewTracking: false,
    newStatus: null,
    selectedServiceAgreement: null,
    selectedTracking: null,
    page: 1,
    pageSize: 10,
    pageTimestamp: moment(),
    selectedTab: 'ALL'
  };

  private _onCloseDelete = () => {
    this.setState({ isDeleteOpen: false });
  };

  private _onCloseChangeStatus = () => {
    this.setState({ isChangeStatusOpen: false });
  };

  private _closeEditServiceAgreementDates = () => {
    this.setState({ isEditDatesOpen: false });
  };

  private _closeEditServiceAgreementTAndC = () => {
    this.setState({ isEditTAndC: false });
  };

  private _closeEditServiceAgreementServiceItems = () => {
    this.setState({ isEditServiceItems: false });
  };

  private _closeEditQuote = () => {
    this.setState({ isEditQuote: false });
  };

  private _closeViewQuote = () => {
    this.setState({ isViewQuote: false });
  };

  private _closeViewTracking = () => {
    this.setState({ isViewTracking: false });
  };

  private _onClickActionModal = (item, action, value) => {
    this.setState({ selectedServiceAgreement: item });
    if (action === 'DELETE') {
      this.setState({ isDeleteOpen: value });
    } else if (action === 'STATUS') {
      this.setState({ isChangeStatusOpen: true, newStatus: value });
    } else if (action === 'DATES') {
      this.setState({ isEditDatesOpen: true });
    } else if (action === 'TERMS_CONDITIONS') {
      this.setState({ isEditTAndC: true });
    } else if (action === 'SERVICE_ITEMS') {
      this.setState({ isEditServiceItems: true });
    } else if (action === 'QUOTE') {
      this.setState({ isEditQuote: true });
    } else if (action === 'VIEW_QUOTE') {
      this.setState({ isViewQuote: true });
    } else if (action === 'VIEW_TRACKING') {
      this.setState({ isViewTracking: true, selectedTracking: value });
    }
  };

  private _fetchMoreServiceAgreements = async () => {
    const { doFetchServiceAgreements } = this.props;
    await this.setState({ isLoading: true, page: this.state.page + 1 });
    await doFetchServiceAgreements({});
    this.setState({ isLoading: false });
  };

  private _changeTab = (event) => {
    this.setState({ selectedTab: event });
  };

  render() {
    const { customerServiceAgreements, openedServiceAgreementId } = this.props;
    const { selectedTab } = this.state;
    const filteredServiceAgreements =
      selectedTab !== 'ALL'
        ? _.filter(customerServiceAgreements, (serviceAgreement) => serviceAgreement.paymentSourceType === selectedTab)
        : customerServiceAgreements;
    return (
      <div className="mt-large">
        <DeleteServiceAgreementModal
          isOpen={this.state.isDeleteOpen}
          onClose={this._onCloseDelete}
          serviceAgreement={this.state.selectedServiceAgreement}
        />
        <ChangeStatusServiceAgreementModal
          isOpen={this.state.isChangeStatusOpen}
          onClose={this._onCloseChangeStatus}
          serviceAgreement={this.state.selectedServiceAgreement}
          newStatus={this.state.newStatus}
        />
        <EditServiceAgreementDatesModal
          isOpen={this.state.isEditDatesOpen}
          closeEdit={this._closeEditServiceAgreementDates}
          serviceAgreement={this.state.selectedServiceAgreement}
          companyData={this.props.companyDataLite}
        />
        <EditServiceAgreementTermConditionsModal
          isOpen={this.state.isEditTAndC}
          closeEdit={this._closeEditServiceAgreementTAndC}
          serviceAgreement={this.state.selectedServiceAgreement}
        />
        <EditServiceAgreementServiceItemsModal
          isOpen={this.state.isEditServiceItems}
          closeEdit={this._closeEditServiceAgreementServiceItems}
          serviceAgreement={this.state.selectedServiceAgreement}
        />
        <EditQuoteModal
          isOpen={this.state.isEditQuote}
          closeEdit={this._closeEditQuote}
          serviceAgreement={this.state.selectedServiceAgreement}
        />
        <ViewQuoteModal
          isOpen={this.state.isViewQuote}
          closeEdit={this._closeViewQuote}
          serviceAgreement={this.state.selectedServiceAgreement}
        />
        <ViewBudgetTrackingDetailModal
          isOpen={this.state.isViewTracking}
          closeEdit={this._closeViewTracking}
          selectedTracking={this.state.selectedTracking}
        />
        <Tabs defaultActiveKey={'ALL'} animated={true} onChange={this._changeTab} className={'inner-tab mb-large'}>
          <Tabs.TabPane tab="All" key="ALL" />
          {_.some(
            customerServiceAgreements,
            (serviceAgreement) => serviceAgreement.paymentSourceType === PaymentSources.NDIS
          ) && <Tabs.TabPane tab={PaymentSources.NDIS} key={PaymentSources.NDIS} />}
          {_.some(
            customerServiceAgreements,
            (serviceAgreement) => serviceAgreement.paymentSourceType === PaymentSources.VCP
          ) && <Tabs.TabPane tab={PaymentSources.VCP} key={PaymentSources.VCP} />}
        </Tabs>
        {filteredServiceAgreements && filteredServiceAgreements.length > 0 ? (
          _.map(filteredServiceAgreements, (item, idx) => (
            <ServiceAgreementItem
              item={item}
              isServiceAgreementDetailsOpen={item.userServiceAgreementId === openedServiceAgreementId}
              key={idx}
              onClickActionModal={this._onClickActionModal}
              hasEditPermission={this.props.hasEditPermission}
            />
          ))
        ) : (
          <ServiceAgreementEmptyState />
        )}
        {this.state.isLoading && (
          <Skeleton paragraph={{ rows: 1, width: '50%' }} active={true} className="anim-slide-left" />
        )}
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  companyDataLite: state.companyStore.companyDataLite,
  customerServiceAgreements: state.customersStore.customerServiceAgreements
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchServiceAgreements: dispatch.customersStore.doFetchServiceAgreements
});

export default connect(
  mapState,
  mapDispatch
)(ServiceAgreementsList);
