import { RematchDispatcherAsync } from '@rematch/core';
import React, { Component } from 'react';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { GhostButton, HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import _ from 'lodash';
import { ActionModalFooter } from 'common-components/modal/ActionModal';
import ActionModal from 'common-components/modal/ActionModal';
import { CustomViewsModalType, CustomViewsType, FilterType } from 'utilities/enum-utils';
import { Alert, Avatar, Button, Checkbox, Col, Form, Icon, Input, notification, Radio, Row, Spin } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import Search from 'antd/lib/input/Search';
import { ICustomView, IFilterValue } from 'interfaces/custom-views-interface';
import moment from 'moment';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';

interface ICustomViewsModalProps extends FormComponentProps {
  doFetchWorkerListLite: typeof dispatch.teamStore.doFetchWorkerListLite;
  doAddView: (action) => ReturnType<RematchDispatcherAsync>;
  doUpdateViewTab: (action) => ReturnType<RematchDispatcherAsync>;
  doDuplicateView: (action) => ReturnType<RematchDispatcherAsync>;
  doDeleteView: (action) => ReturnType<RematchDispatcherAsync>;
  setPageFilter: (action) => void;
  onCloseViewModal: () => void;
  onUpdateViewModal: (type: CustomViewsModalType) => void;
  isOpen: boolean;
  type: CustomViewsModalType;
  workerListLite: typeof state.teamStore.workerListLite;
  pageViews: ICustomView[];
  pageFilter: IFilterValue[];
  pageListingActiveTab: ICustomView;
  defaultViews: ICustomView[];
  defaultFilterValue: IFilterValue[];
}

interface ICustomViewsModalState {
  currentFormRendered: CustomViewsModalType;
  previousFormRendered: CustomViewsModalType;
  filteredWorkersList: typeof state.teamStore.workerListLite;
  isSearching: boolean;
  selectedViewType: CustomViewsType;
  sharedWith: typeof state.teamStore.workerListLite;
  title: string;
  viewName: string;
  isLoading: boolean;
  showTeamMemberError: boolean;
}

const INITIAL_STATE = {
  currentFormRendered: CustomViewsModalType.CREATE,
  previousFormRendered: null,
  filteredWorkersList: [],
  isSearching: false,
  selectedViewType: CustomViewsType.PRIVATE,
  sharedWith: [],
  title: '',
  viewName: '',
  isLoading: false,
  showTeamMemberError: false,
};

class CustomViewsModal extends Component<ICustomViewsModalProps, ICustomViewsModalState> {
  state = _.cloneDeep(INITIAL_STATE);

  private _updateDefaultValues = () => {
    const { type, pageListingActiveTab } = this.props;

    if (type === CustomViewsModalType.MANAGE || type === CustomViewsModalType.SAVE_VIEW) {
      this._generateNewWorkersList();
      const { name, sharedWith, viewType } = pageListingActiveTab;
      this.setState({
        viewName: name,
        selectedViewType: viewType,
        sharedWith,
      });
    } else {
      const { viewName, selectedViewType } = _.cloneDeep(INITIAL_STATE);
      this.setState({
        viewName,
        selectedViewType,
        sharedWith: [],
      });
    }
  };

  private _onCreateNewView = () => {
    const {
      form,
      onCloseViewModal,
      doAddView,
      type,
      pageFilter,
      pageListingActiveTab,
      defaultFilterValue,
    } = this.props;
    const { selectedViewType, sharedWith } = this.state;
    form.validateFieldsAndScroll(async (err, { name }) => {
      if (selectedViewType === CustomViewsType.TEAM && _.isEmpty(sharedWith)) {
        this.setState({ showTeamMemberError: true });
        return;
      }
      if (!err) {
        this.setState({ isLoading: true, showTeamMemberError: false });

        const payload = {
          name: name.trim(),
          sharedWith,
          viewType: selectedViewType,
          isCustomer: true,
          filterValue: defaultFilterValue,
        };

        const copyCurrentFilter =
          type === CustomViewsModalType.DUPLICATE ||
          type === CustomViewsModalType.SAVE_AS_NEW ||
          type === CustomViewsModalType.SAVE_DEFAULT_VIEW ||
          type === CustomViewsModalType.SAVE_AS_NEW_COPY_FROM_OTHERS;
        if (copyCurrentFilter) {
          payload.filterValue = [...pageFilter];
          payload.isCustomer = pageListingActiveTab.isCustomer;
        }

        try {
          payload.filterValue = this._generateFilterValue(payload.filterValue);
          await doAddView(payload);
          this.setState({ isLoading: false });
          onCloseViewModal();
          this._showNotification(type);
        } catch (e) {
          onCloseViewModal();
          this.setState({ isLoading: false });
          this._showErrorNotif(e);
        }
      }
    });
  };

  private _onUpdateView = async () => {
    const {
      form,
      pageListingActiveTab,
      type,
      pageFilter,
      onCloseViewModal,
      doUpdateViewTab,
      doDuplicateView,
      doAddView,
    } = this.props;
    const { selectedViewType, sharedWith, viewName } = this.state;
    let hasError = false;
    let formNameField = viewName;

    if (type !== CustomViewsModalType.SAVE_VIEW) {
      form.validateFieldsAndScroll((err, { name }) => {
        hasError = err;
        formNameField = name;
      });
    }

    if (selectedViewType === CustomViewsType.TEAM && _.isEmpty(sharedWith)) {
      this.setState({ showTeamMemberError: true });
      return;
    }

    if (!hasError) {
      this.setState({ isLoading: true });
      const isShared = selectedViewType === CustomViewsType.TEAM && sharedWith;
      const payload = {
        ...pageListingActiveTab,
        name: formNameField.trim(),
        sharedWith: isShared ? sharedWith : [],
        viewType: selectedViewType,
      };

      if (type === CustomViewsModalType.SAVE_VIEW) {
        payload.filterValue = [...pageFilter];
      }

      try {
        payload.filterValue = this._generateFilterValue(payload.filterValue);
        if (type === CustomViewsModalType.DUPLICATE) {
          if (pageListingActiveTab.isDefault) {
            await doAddView(payload);
          } else {
            await doDuplicateView(payload);
          }
        } else {
          await doUpdateViewTab(payload);
        }
        this.setState({ isLoading: false });
        onCloseViewModal();
        this._showNotification(type);
      } catch (e) {
        onCloseViewModal();
        this.setState({ isLoading: false });
        this._showErrorNotif(e);
      }
    }
  };

  private _onDeleteView = async () => {
    const { pageListingActiveTab, type, doDeleteView, onCloseViewModal } = this.props;
    this.setState({ isLoading: true });
    const payload = { ...pageListingActiveTab };

    try {
      await doDeleteView(payload);
      this.setState({ isLoading: false });
      onCloseViewModal();
      this._showNotification(type);
    } catch (e) {
      onCloseViewModal();
      this.setState({ isLoading: false });
      this._showErrorNotif(e);
    }
  };

  private _onResetFilter = async () => {
    const { onCloseViewModal, setPageFilter } = this.props;
    const appliedFilters = this._generateFilters();
    await setPageFilter(appliedFilters);
    onCloseViewModal();
  };

  private _generateFilters = () => {
    const { pageListingActiveTab } = this.props;
    return pageListingActiveTab.filterValue
      .filter((filter) => !_.isEmpty(filter))
      .map((filter) => {
        const [[key, value]] = Object.entries(filter);
        return {
          filter: key,
          values: value || [],
          selectionLabel: !_.isEmpty(value)
            ? key === FilterType.DATE_RANGE
              ? CommonUtils.getFilterText(FilterType.DATE_RANGE, [
                moment(value[0].valueOf()),
                moment(value[1].valueOf()),
              ])
              : CommonUtils.getFilterText(key, value)
            : CommonUtils.getFilterSettings(key).fullSelectionName,
        };
      });
  };

  private _generateFilterValue = (filterValue) => {
    return filterValue.map((filter) => {
      if (filter.selectionLabel) {
        return {
          [filter.filter]: filter.values,
        };
      }
      return filter;
    });
  };

  private _showNotification = (type) => {
    let notifMessage = null;
    if (
      type === CustomViewsModalType.CREATE ||
      type === CustomViewsModalType.SAVE_AS_NEW ||
      type === CustomViewsModalType.SAVE_DEFAULT_VIEW ||
      type === CustomViewsModalType.SAVE_AS_NEW_COPY_FROM_OTHERS
    ) {
      notifMessage = {
        message: 'New view created',
        description: 'You have successfully created a new view.',
      };
    } else if (type === CustomViewsModalType.MANAGE) {
      notifMessage = {
        message: 'View settings updated',
        description: 'You have successfully updated the settings of this view.',
      };
    } else if (type === CustomViewsModalType.DUPLICATE) {
      notifMessage = {
        message: 'View duplicated',
        description: 'You have successfully duplicated the view.',
      };
    } else if (type === CustomViewsModalType.SAVE_VIEW) {
      notifMessage = {
        message: 'View updated',
        description: 'You have successfully updated this view.',
      };
    } else if (type === CustomViewsModalType.DELETE) {
      notifMessage = {
        message: 'View deleted',
        description: 'You have successfully deleted this view.',
      };
    }
    notifMessage && notification.success(notifMessage);
  };

  private _showErrorNotif = (e) => {
    notification.error({
      message: 'Oops, something went wrong, please try again.',
      description: (e.meta && e.meta.message) || e.message || e.status,
    });
  };

  private _onChangeRadioOption = (e) => {
    let { sharedWith } = this.state;
    ;
    const resetSharedWith = this.props.type === CustomViewsModalType.CREATE && e.target.value !== CustomViewsType.TEAM;
    if (resetSharedWith) {
      sharedWith = [];
    }
    this.setState({ selectedViewType: e.target.value, sharedWith, showTeamMemberError: false });
  };

  private _nameInputValidator = (rule, value, callback) => {
    const { type, pageViews, pageListingActiveTab, defaultViews } = this.props;
    value = value.trim();
    try {
      if (!value) {
        throw Error('Name is required');
      }
      if (_.trim(value).length > 75) {
        throw Error('Please enter less than 75 characters');
      }

      if (
        _.some(
          // If type is MANAGE (update mode), find by all views without current view.
          _.filter([...defaultViews, ...pageViews], (view: ICustomView) => {
            return (
              type !== CustomViewsModalType.MANAGE ||
              (pageListingActiveTab && pageListingActiveTab.customViewId !== view.customViewId)
            );
          }),
          (filteredView: ICustomView) => filteredView.name === value,
        )
      ) {
        throw Error('Name in use');
      }

      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _onNameChange = (event) => {
    this.setState({ viewName: event.currentTarget.value });
  };

  private _renderCreateView = () => {
    const { form, onCloseViewModal, type } = this.props;
    const { selectedViewType, sharedWith, viewName, isLoading, showTeamMemberError } = this.state;
    const { getFieldDecorator } = form;
    const loadingIcon = <Icon type="loading" style={{ fontSize: 16 }} spin />;

    return (
      <>
        <Row className="mb-large">
          <SubTitle>Name</SubTitle>
          <Form.Item className="mb-none">
            {getFieldDecorator('name', {
              initialValue: viewName,
              rules: [{ validator: this._nameInputValidator }],
            })(
              <Input
                placeholder="Enter a view name"
                className="mt-x-small"
                onChange={this._onNameChange}
                maxLength={75}
                required
              />,
            )}
          </Form.Item>
        </Row>

        <Row>
          <SubTitle>Shared With</SubTitle>
          <Radio.Group value={selectedViewType} onChange={this._onChangeRadioOption} className="mt-small">
            <Radio
              value={CustomViewsType.PRIVATE}
              className={`${selectedViewType === CustomViewsType.PRIVATE && 'text-weight-bold'} mb-x-small `}
            >
              Private
            </Radio>
            <br />
            <Radio
              value={CustomViewsType.EVERYONE}
              className={`${selectedViewType === CustomViewsType.EVERYONE && 'text-weight-bold'} mb-x-small `}
            >
              Everyone
            </Radio>
            <br />
            <Radio
              value={CustomViewsType.TEAM}
              className={`${selectedViewType === CustomViewsType.TEAM && 'text-weight-bold'} mb-x-small `}
            >
              Team
            </Radio>
          </Radio.Group>
        </Row>
        {selectedViewType === CustomViewsType.TEAM && (
          <Row>
            <Col className="mt-x-large mv-medium">
              <SubTitle textClassName="mv-small">TEAM MEMBER</SubTitle>
              {sharedWith.length > 0 && (
                <Alert
                  className="bg-tertiary bordered-none width-fit-content mt-x-small"
                  style={{ padding: '4px' }}
                  message={
                    <Row>
                      {_.map(sharedWith, (item) => {
                        return (
                          <div key={item.supportWorkerId} className={`pv-x-small ph-small inline-block`}>
                            <div className="flex-row justify-start align-center">
                              <Avatar className="mr-medium" icon="user" src={item.attachmentUrl} />
                              <div className="inline-block mr-small">
                                <Text>{item.firstName + ' ' + item.lastName}</Text>
                              </div>
                              <HyperlinkButton
                                className={'mh-x-small'}
                                onClick={(e) => this._removeTeamMember(e, item)}
                              >
                                <Icon type="cross" className="cursor-pointer text-color-red" />
                              </HyperlinkButton>
                            </div>
                          </div>
                        );
                      })}
                    </Row>
                  }
                />
              )}
              <div className="mt-x-small">
                <HyperlinkButton className="ph-none" onClick={this._onSelectTeamClick}>
                  Select team member...
                </HyperlinkButton>
              </div>
              {showTeamMemberError && (
                <Text className="anim-slide-down mt-x-small text-color-red">Team member is required.</Text>
              )}
            </Col>
          </Row>
        )}
        <ActionModalFooter>
          <SecondaryButton size="large" onClick={onCloseViewModal}>
            Cancel
          </SecondaryButton>
          {isLoading && (
            <PrimaryButton className="ml-medium" size="large" disabled={isLoading}>
              Saving... <Spin indicator={loadingIcon} />
            </PrimaryButton>
          )}
          {!isLoading && type === CustomViewsModalType.MANAGE && (
            <PrimaryButton className="ml-medium" size="large" onClick={this._onUpdateView} disabled={isLoading}>
              Save changes
            </PrimaryButton>
          )}
          {!isLoading && type === CustomViewsModalType.DUPLICATE && (
            <PrimaryButton className="ml-medium" size="large" onClick={this._onUpdateView} disabled={isLoading}>
              Duplicate view
            </PrimaryButton>
          )}
          {!isLoading &&
            (type === CustomViewsModalType.CREATE ||
              type === CustomViewsModalType.SAVE_AS_NEW ||
              type === CustomViewsModalType.SAVE_DEFAULT_VIEW ||
              type === CustomViewsModalType.SAVE_AS_NEW_COPY_FROM_OTHERS) && (
              <PrimaryButton className="ml-medium" size="large" onClick={this._onCreateNewView} disabled={isLoading}>
                Create view
              </PrimaryButton>
            )}
        </ActionModalFooter>
      </>
    );
  };

  private _updateSharedWith = (user) => {
    const { sharedWith, filteredWorkersList } = this.state;
    const filteredWorkerIndex = _.indexOf(filteredWorkersList, user);
    const sharedWithIndex = sharedWith.findIndex(
      (selectedUser) => selectedUser.supportWorkerId === user.supportWorkerId,
    );
    const isExisting = sharedWithIndex > -1;

    if (isExisting) {
      sharedWith.splice(sharedWithIndex, 1);
      filteredWorkersList[filteredWorkerIndex].isSelected = false;
    } else {
      sharedWith.push(user);
      filteredWorkersList[filteredWorkerIndex].isSelected = true;
    }

    this.setState({ sharedWith: [...sharedWith], filteredWorkersList: [...filteredWorkersList] });
  };

  private _removeTeamMember = (event, teamMember) => {
    const { sharedWith, filteredWorkersList } = this.state;

    const filteredIndex = filteredWorkersList.findIndex(
      (filteredWorker) => filteredWorker.supportWorkerId === teamMember.supportWorkerId,
    );
    if (filteredIndex >= 0) {
      filteredWorkersList[filteredIndex].isSelected = false;
    }

    _.remove(sharedWith, { supportWorkerId: teamMember.supportWorkerId });

    this.setState({ sharedWith: [...sharedWith], filteredWorkersList: [...filteredWorkersList] });
  };

  private _onSelectTeamClick = async () => {
    try {
      await this.props.doFetchWorkerListLite({
        hasAppAccess: ['PORTAL'],
      });
      this.setState({
        currentFormRendered: CustomViewsModalType.SELECT_TEAM,
        previousFormRendered: this.state.currentFormRendered,
      });
    } catch (e) {
      this._showErrorNotif(e);
    }
  };

  private _searchText = (txt) => {
    const targetStr = txt
      .toLowerCase()
      .split(' ')
      .filter(function (el) {
        return el.length !== 0;
      });
    const { workerListLite } = this.props;

    const result =
      targetStr.length > 0
        ? _.filter(workerListLite, (item) => {
          if (
            _.some(
              targetStr,
              (string) => `${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()}`.indexOf(string) >= 0,
            )
          ) {
            return item;
          }
        })
        : workerListLite;

    this.setState({ filteredWorkersList: result, isSearching: false });
  };

  private _debounceSearch = _.debounce(this._searchText, 500);

  private _onEnterSearchText = (e) => {
    this.setState({ isSearching: true });
    if (e.target.value.length >= 3 || e.target.value.length === 0) {
      this._debounceSearch(e.target.value);
    }
  };

  private _generateNewWorkersList = () => {
    const { workerListLite, pageListingActiveTab } = this.props;

    pageListingActiveTab && pageListingActiveTab.sharedWith && delete pageListingActiveTab.sharedWith;
    const sharedWith = _.get(pageListingActiveTab, 'sharedWith', this.state.sharedWith);
    const result = workerListLite.map((worker) => {
      worker.isSelected = sharedWith && sharedWith.find((team) => team.supportWorkerId === worker.supportWorkerId);
      return worker;
    });

    if (pageListingActiveTab) {
      pageListingActiveTab.sharedWith = sharedWith;
    }
    return result;
  };

  private _renderSelectTeam = () => {
    const { sharedWith, filteredWorkersList, previousFormRendered } = this.state;
    const goBack = () =>
      this.setState({
        currentFormRendered: previousFormRendered,
        previousFormRendered: null,
      });
    const goNext = () => {
      this.setState({
        currentFormRendered: previousFormRendered,
        previousFormRendered: null,
        showTeamMemberError: _.isEmpty(sharedWith),
      });
    };

    return (
      <>
        <h6 className="text-size-large line-height-135 mb-large pb-x-small" style={{ marginTop: '-12px' }}>
          Select team members to share the view with.{' '}
        </h6>
        <Row className="mb-large">
          <Search placeholder="Search for..." allowClear={true} onChange={this._onEnterSearchText} />
        </Row>
        <Row>
          <SubTitle>TEAM MEMBERS ({filteredWorkersList.length})</SubTitle>
          <div className="overflow-y-scroll" style={{ maxHeight: '50vh' }}>
            {_.map(filteredWorkersList, (item) => {
              return (
                <Row
                  key={item.userId}
                  className={`${item.isSelected ? 'bg-blue-lightest' : ''
                    } pv-small ph-medium pl-large hover-bg-tertiary cursor-pointer flex-row`}
                  onClick={() => this._updateSharedWith(item)}
                >
                  <Checkbox checked={item.isSelected} />
                  <Avatar className="mh-medium" size="large" icon="user" shape="square" src={item.attachmentUrl} />
                  <span
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'pre-line',
                    }}
                  >
                    <Text>{item.firstName + ' ' + item.lastName}</Text>
                  </span>
                </Row>
              );
            })}
          </div>
        </Row>
        <ActionModalFooter>
          <SecondaryButton size="large" onClick={goBack}>
            Cancel
          </SecondaryButton>
          <PrimaryButton className="ml-medium" size="large" onClick={goNext} disabled={sharedWith.length < 1}>
            Select team members ({sharedWith.length})
          </PrimaryButton>
        </ActionModalFooter>
      </>
    );
  };

  private _renderSaveViewModal = () => {
    const { onUpdateViewModal } = this.props;
    const showCreateView = () => onUpdateViewModal(CustomViewsModalType.SAVE_AS_NEW);
    return (
      <>
        <Row>
          <Paragraph>This view was created by you. You can save filters to this view.</Paragraph>
        </Row>
        <ActionModalFooter className="flex-row">
          <GhostButton size="large" onClick={showCreateView}>
            Save as new
          </GhostButton>
          <div className="flex flex-grow"></div>
          <Button size="large" onClick={this._onResetFilter} className="bordered">
            <span className="text-color-red">Reset</span>
          </Button>
          <PrimaryButton className="ml-medium" size="large" onClick={this._onUpdateView}>
            Save view
          </PrimaryButton>
        </ActionModalFooter>
      </>
    );
  };

  private _renderSaveNewViewModal = () => {
    const { onCloseViewModal } = this.props;
    const showCreateView = () =>
      this.setState({
        currentFormRendered: CustomViewsModalType.CREATE,
      });
    return (
      <>
        <Row>
          <Paragraph>This view is created by someone else.</Paragraph>
          <Paragraph>
            Saving this view will create a new view, as you cannot alter/overwrite views that have been created by
            someone else.
          </Paragraph>
        </Row>
        <ActionModalFooter className="flex-row">
          <div className="flex flex-grow"></div>
          <SecondaryButton size="large" onClick={onCloseViewModal}>
            Cancel
          </SecondaryButton>
          <PrimaryButton className="ml-medium" size="large" onClick={showCreateView}>
            Continue
          </PrimaryButton>
        </ActionModalFooter>
      </>
    );
  };

  private _renderSaveDefaultViewModal = () => {
    const { onCloseViewModal } = this.props;
    const showCreateView = () =>
      this.setState({
        currentFormRendered: CustomViewsModalType.CREATE,
      });
    return (
      <>
        <Row>
          <Paragraph>
            This view is a <b>default view</b>, and cannot be overwritten.
          </Paragraph>
          <Paragraph>
            This action will <b>create a new view</b>, as default views cannot be altered.
          </Paragraph>
        </Row>
        <ActionModalFooter className="flex-row">
          <div className="flex flex-grow"></div>
          <SecondaryButton size="large" onClick={onCloseViewModal}>
            Cancel
          </SecondaryButton>
          <PrimaryButton className="ml-medium" size="large" onClick={showCreateView}>
            Continue
          </PrimaryButton>
        </ActionModalFooter>
      </>
    );
  };

  private _renderDeleteViewModal = () => {
    return (
      <>
        <Row>
          <Paragraph>
            Are you sure you want to delete this view? This action is permanent and cannot be undone.
          </Paragraph>
        </Row>
        <ActionModalFooter className="flex-row">
          <div className="flex flex-grow"></div>
          <SecondaryButton size="large" color="red-dark" onClick={this.props.onCloseViewModal}>
            Cancel
          </SecondaryButton>
          <PrimaryButton className="ml-medium" color="red-dark" size="large" onClick={this._onDeleteView}>
            Delete view
          </PrimaryButton>
        </ActionModalFooter>
      </>
    );
  };

  private _generateModalTitle = (type: CustomViewsModalType) => {
    if (type === CustomViewsModalType.MANAGE) {
      return 'Manage view details';
    } else if (type === CustomViewsModalType.CREATE || type === CustomViewsModalType.SAVE_DEFAULT_VIEW) {
      return 'Create new view';
    } else if (type === CustomViewsModalType.DUPLICATE) {
      return 'Duplicate views';
    } else if (type === CustomViewsModalType.SELECT_TEAM) {
      return 'Select team member';
    } else if (type === CustomViewsModalType.SAVE_VIEW) {
      return 'Save view';
    } else if (type === CustomViewsModalType.SAVE_AS_NEW_COPY_FROM_OTHERS) {
      return 'Save new view';
    } else if (type === CustomViewsModalType.SAVE_AS_NEW) {
      return 'Create new saved view';
    } else if (type === CustomViewsModalType.DELETE) {
      return 'Delete view';
    }
  };

  private _renderModalBody = (type: CustomViewsModalType) => {
    if (type === CustomViewsModalType.SELECT_TEAM) {
      return this._renderSelectTeam();
    }
    if (type === CustomViewsModalType.SAVE_VIEW) {
      return this._renderSaveViewModal();
    }
    if (type === CustomViewsModalType.SAVE_DEFAULT_VIEW) {
      return this._renderSaveDefaultViewModal();
    }
    if (type === CustomViewsModalType.SAVE_AS_NEW_COPY_FROM_OTHERS) {
      return this._renderSaveNewViewModal();
    }
    if (type === CustomViewsModalType.DELETE) {
      return this._renderDeleteViewModal();
    }
    return this._renderCreateView();
  };

  componentDidMount() {
    this._updateDefaultValues();
    this.setState({ currentFormRendered: this.props.type });
  }

  componentDidUpdate(prevProps: Readonly<ICustomViewsModalProps>, prevState: Readonly<ICustomViewsModalState>) {
    const isResetModalStates = prevProps.isOpen !== this.props.isOpen;
    if (isResetModalStates) {
      const newState = _.cloneDeep(INITIAL_STATE);
      this.setState({ ...newState });
    }
    if (prevProps.type !== this.props.type) {
      this.setState({ currentFormRendered: this.props.type });
      this._updateDefaultValues();
    }
    // set filteredWorkersList if workersListList is updated
    if (prevState.filteredWorkersList.length === 0 && this.props.workerListLite.length > 0) {

      const filteredWorkersList = this._generateNewWorkersList();
      this.setState({ filteredWorkersList });
    }
  }

  render() {
    const { isOpen, onCloseViewModal } = this.props;
    const { currentFormRendered } = this.state;

    return (
      <ActionModal
        isOpen={isOpen}
        title={this._generateModalTitle(currentFormRendered)}
        showCloseButton={true}
        onClose={onCloseViewModal}
        width={'large'}
      >
        <div className="anim-slide-left">{this._renderModalBody(currentFormRendered)}</div>
      </ActionModal>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  workerListLite: state.teamStore.workerListLite.filter(
    (worker) => worker && state.authStore.portalUser && worker.userId !== state.authStore.portalUser.userId,
  ),
});

const mapDispatchToProps = (dispatch: IRootDispatch) => ({
  doFetchWorkerListLite: dispatch.teamStore.doFetchWorkerListLite,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Form.create<ICustomViewsModalProps>()(CustomViewsModal));
