import { Text } from 'common-components/typography';
import { HyperlinkButton } from 'common-components/buttons';
import React from 'react';
import { IPermissions } from 'interfaces/account-interfaces';
import { isEmpty, some } from 'lodash';
import { ServicePermissionType, UserPermissionRole } from 'utilities/enum-utils';

interface DepartmentPermissionRowProps {
  departmentId: string;
  departmentName: string;
  onClickModify: any;
  permissions: IPermissions;
  hasAllAccess: boolean;
  actionsDisabled: boolean;
}

export class DepartmentPermissionRow extends React.Component<DepartmentPermissionRowProps> {
  lookupCurrentPermission = (permissions: IPermissions, departmentId: string) => {
    const { permissionRoles } = permissions;

    if (this.props.hasAllAccess) {
      return ServicePermissionType.FULL_ACCESS;
    }

    const permissionsForDept = permissionRoles.filter((role) => role.serviceDepartmentId === departmentId);

    if (permissionsForDept.length > 0) {
      const servicePermissions = permissionsForDept.filter(
        (role) => role.permissionRole === UserPermissionRole.ServiceAdmin || UserPermissionRole.ServiceMember
      );

      if (servicePermissions.length > 0) {
        const permissionWithIds = servicePermissions.filter((role) => !isEmpty(role.serviceId));

        if (permissionWithIds.length > 0) {
          return ServicePermissionType.CUSTOM;
        } else {
          const hasServiceManager = some(servicePermissions, ['permissionRole', UserPermissionRole.ServiceAdmin]);
          const hasServiceMember = some(servicePermissions, ['permissionRole', UserPermissionRole.ServiceMember]);

          if (hasServiceManager) {
            return ServicePermissionType.MANAGER;
          } else if (hasServiceMember) {
            return ServicePermissionType.MEMBER;
          } else {
            return ServicePermissionType.NO_ACCESS;
          }
        }
      } else {
        return ServicePermissionType.NO_ACCESS;
      }
    } else {
      return ServicePermissionType.NO_ACCESS;
    }
  };

  render() {
    let { departmentName, departmentId, onClickModify, permissions } = this.props;

    const currentPermission = this.lookupCurrentPermission(permissions, departmentId);

    return (
      <div className="flex-row pv-medium bordered-bottom flex-1 justify-between">
        <div className="flex-column">
          <Text size="x-large">{departmentName}</Text>
          <Text size="regular" color="secondary">
            Service configuration and associated bookings
          </Text>
        </div>
        <div className="flex-row">
          <div className="mr-large">
            <Text>{currentPermission}</Text>
          </div>
          <div className="mr-large">
            {!this.props.actionsDisabled && (
              <HyperlinkButton onClick={() => onClickModify(departmentId)}>Modify</HyperlinkButton>
            )}
          </div>
        </div>
      </div>
    );
  }
}
