import { Popover } from '@blueprintjs/core';
import { Avatar, Col, notification, Row } from 'antd';
import { ref, uploadBytesResumable } from 'firebase/storage';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebaseApp from 'stores/firebase-app';
import { v4 as uuidv4 } from 'uuid';
import { ActionMenu, ActionMenuItem } from '../../../../../common-components/action-menu/index';
import { IconButton, PrimaryButton, SecondaryButton } from '../../../../../common-components/buttons/index';
import { FieldLabel, Paragraph, Text, Title } from '../../../../../common-components/typography/index';
import { INewActivityRecordData } from '../../../../../interfaces/booking-interfaces';
import { dispatch, IRootDispatch, IRootState, state } from '../../../../../stores/rematch/root-store';
import { ShiftSlotStatus } from '../../../../../utilities/enum-utils';

interface IActivityRecordStep5ReviewPanelProps {
  newActivityRecordData: INewActivityRecordData;
  doCreateActivityRecord: typeof dispatch.bookingsStore.doCreateActivityRecord;
  setNewActivityRecordData: typeof dispatch.bookingsStore.setNewActivityRecordData;
  portalUser: typeof state.authStore.portalUser;
  onNextStep: () => void;
  onPreviousStep: () => void;
}

interface IActivityRecordStep5ReviewPanelState {
  isLoading: boolean;
}

class ActivityRecordStep5ReviewPanel extends Component<
  IActivityRecordStep5ReviewPanelProps,
  IActivityRecordStep5ReviewPanelState
> {
  state = {
    isLoading: false
  };
  private _onNextStep = () => {
    this.props.onNextStep();
  };

  private _onCreate = async (approve = false) => {
    this.setState({ isLoading: true });
    const { newActivityRecordData, doCreateActivityRecord } = this.props;

    try {
      const noteAttachmentId = uuidv4();
      const payload = {
        customerUserId: newActivityRecordData.selectedCustomerId,
        serviceId: newActivityRecordData.selectedServiceId,
        address: newActivityRecordData.bookLocation,
        supportWorkerId: newActivityRecordData.selectedWorkerId,
        startDateTime: moment
          .tz(
            moment(newActivityRecordData.bookingStartDate).format('YYYY-MM-DD HH:mm'),
            newActivityRecordData.selectedService.timezone
          )
          .toDate(),
        endDateTime: moment
          .tz(
            moment(newActivityRecordData.bookingEndDate).format('YYYY-MM-DD HH:mm'),
            newActivityRecordData.selectedService.timezone
          )
          .toDate(),
        isDuration: newActivityRecordData.isDuration,
        userSelectedBillingItems: newActivityRecordData.selectedBillingItems,
        documents: _.map(newActivityRecordData.attachments, (attachment) => {
          console.log(attachment);
          return {
            documentName: attachment.attachment.name,
            documentId: attachment.attachmentId,
            description: attachment.description
          };
        }),
        note: newActivityRecordData.note
          ? {
              isIncident: newActivityRecordData.note.isIncident,
              noteContent: newActivityRecordData.note.noteContent,
              visibleType: newActivityRecordData.note.visibleType,
              documentId: newActivityRecordData.note.attachment ? noteAttachmentId : null,
              documentName: newActivityRecordData.note.attachment ? newActivityRecordData.note.attachment.name : null
            }
          : null,
        serviceDateTimeId: newActivityRecordData.serviceDateTimeId,
        isApproved: approve,
        shiftSlotStatus: newActivityRecordData.selectedWorker
          ? newActivityRecordData.isShiftSlotSentToTimesheet
            ? ShiftSlotStatus.CHECKED_OUT
            : ShiftSlotStatus.CONFIRMED
          : ShiftSlotStatus.UNASSIGNED,
        paymentSourceType: newActivityRecordData.paymentSourceType
      };

      const result: any = await doCreateActivityRecord(payload);

      const allAttachments =
        newActivityRecordData.note && newActivityRecordData.note.attachment
          ? newActivityRecordData.attachments.concat({
              attachment: newActivityRecordData.note.attachment,
              attachmentId: noteAttachmentId,
              noteId: result.noteId
            })
          : newActivityRecordData.attachments;

      if (allAttachments.length > 0) {
        const uploadTask = _.map(allAttachments, (attachment) => {
          const metaData = {
            customMetadata: {
              documentId: attachment.attachmentId,
              serviceProviderId: this.props.portalUser.serviceProviderId,
              attendanceId: result.bookingId,
              noteId: attachment.noteId
            }
          };

          const storageRef = ref(firebaseApp.storage, `${result.uploadBucketUrl}/${attachment.attachment.name}`);
          const uploadFile = uploadBytesResumable(storageRef, attachment.attachment, metaData);

          uploadFile.on(
            'state_changed',
            () => ({}),
            () => {
              this.setState({ isLoading: false });
            },
            () => {
              this.setState({ isLoading: false });
              notification.success({ message: 'Document is currently scanning.' });
            }
          );

          return;
        });
        try {
          await Promise.all(uploadTask);
          notification.success({ message: 'Document is currently scanning.' });
        } catch (e) {
          notification.error({ message: 'Upload failed! Please try again.' });
        }
      }

      this.props.setNewActivityRecordData({
        ...newActivityRecordData,
        isApproved: result.isApproved,
        bookingId: result.bookingId,
        bookingRequestId: result.bookingRequestId,
        referenceId: result.referenceId,
        isApprove: approve
      });

      this.setState({ isLoading: false });
      this._onNextStep();
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { newActivityRecordData, onPreviousStep } = this.props;
    const { isLoading } = this.state;
    return (
      <div className="anim-slide-left">
        <Row className="ph-x4-large mt-large">
          <Col span={6} style={{ position: 'sticky', top: '0px', height: 'calc(100vh - 184px)', overflow: 'auto' }}>
            <div className="width-3/4">
              <Paragraph>Please review the details of the activity record.</Paragraph>
              <Paragraph>
                You can also create and approve the activity record directly by clicking on the down icon, and select{' '}
                <b>Create and approve</b>.
              </Paragraph>
              {/*<Paragraph>*/}
              {/*  Visit the <a>Help Center</a> to learn more.*/}
              {/*</Paragraph>*/}
            </div>
          </Col>
          <Col span={18} className="pl-large" style={{ minHeight: 'calc(100vh - 184px)' }}>
            <div className="bg-white rounded-big p-large" style={{ minWidth: '250px', minHeight: '463px' }}>
              <Title level={4}>Summary review</Title>
              <div className={'mt-large'}>
                <FieldLabel text={'CUSTOMER'} />
                <div className={'flex-row align-center mt-x2-small'}>
                  <Avatar size={32} src={newActivityRecordData.selectedCustomer.attachmentUrl} />
                  <Text className={'ml-small'}>
                    {newActivityRecordData.selectedCustomer.firstName +
                      ' ' +
                      newActivityRecordData.selectedCustomer.lastName}
                  </Text>
                </div>
              </div>
              <div className={'mt-large'}>
                <FieldLabel text={'ASSIGNED TEAM MEMBER'} />
                {newActivityRecordData.selectedWorker && (
                  <div className={'flex-row align-center mt-x2-small'}>
                    <Avatar src={newActivityRecordData.selectedWorker.attachmentUrl} />
                    <Text className={'ml-small'}>
                      {newActivityRecordData.selectedWorker.firstName +
                        ' ' +
                        newActivityRecordData.selectedWorker.lastName}
                    </Text>
                  </div>
                )}
                {!newActivityRecordData.selectedWorker && (
                  <div className={'flex-row align-center mt-x2-small'}>
                    <Text>No worker assigned</Text>
                  </div>
                )}
              </div>
              <div className={'mt-large'}>
                <FieldLabel text={'SERVICE'} />
                <Text className={'mt-x2-small'}>{newActivityRecordData.selectedService.serviceName}</Text>
              </div>
              <div className={'mt-large'}>
                <FieldLabel text={'DATE & DURATION'} />
                <div className={'mt-x2-small'}>
                  <Text>{moment(newActivityRecordData.bookingDate).format('DD/MM/YYYY')}</Text>
                </div>
                {newActivityRecordData.isDuration && (
                  <div className={'mt-x2-small'}>
                    <Text>
                      ({newActivityRecordData.durationHours + 'hr ' + newActivityRecordData.durationMinutes + 'm'})
                    </Text>
                  </div>
                )}
                {!newActivityRecordData.isDuration && (
                  <div className={'mt-x2-small'}>
                    <Text>
                      (
                      {moment(newActivityRecordData.bookingStartDate).format('h:mmA') +
                        ' - ' +
                        moment(newActivityRecordData.bookingEndDate).format('h:mmA')}
                      )
                    </Text>
                  </div>
                )}
              </div>
              <div className={'mt-large'}>
                <FieldLabel text={'LOCATION'} />
                <Text className={'mt-x2-small'}>{newActivityRecordData.bookLocation.fullAddress}</Text>
              </div>
              <div className={'mt-large'}>
                <FieldLabel text={'NOTES'} />
                {newActivityRecordData.note && (
                  <Text className={'mt-x2-small'}>{newActivityRecordData.note.noteContent}</Text>
                )}
              </div>
              <div className={'mt-large'}>
                <FieldLabel text={'ATTACHMENTS(S)'} />
                {_.map(newActivityRecordData.attachments, (attachments) => (
                  <div key={attachments.attachmentId}>
                    <Text className={'mt-x2-small'}>{attachments.attachment.name}</Text>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-row justify-between align-center mt-x2-large">
              <SecondaryButton size="large" onClick={onPreviousStep}>
                Previous
              </SecondaryButton>
              <div className="ml-medium flex-row align-center">
                <PrimaryButton
                  size="large"
                  // onClick={() => this._onAssignTeamMember(ShiftSlotStatus.PENDING)}
                  onClick={() => this._onCreate()}
                  loading={isLoading}
                  className="rounded-left"
                >
                  Create activity record
                </PrimaryButton>

                <Popover
                  disabled={isLoading}
                  content={
                    <ActionMenu>
                      <ActionMenuItem text="Create and approve activity record" onClick={() => this._onCreate(true)} />
                    </ActionMenu>
                  }
                  position="top-right"
                  usePortal={false}
                >
                  <IconButton loading={isLoading} className={`rounded-right ml-x2-small`} icon="down" size="large" />
                </Popover>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  newActivityRecordData: state.bookingsStore.newActivityRecordData,
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateActivityRecord: dispatch.bookingsStore.doCreateActivityRecord,
  setNewActivityRecordData: dispatch.bookingsStore.setNewActivityRecordData
});

export default connect(mapState, mapDispatch)(ActivityRecordStep5ReviewPanel);
