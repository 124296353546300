import React, { Component } from 'react';
import { Text } from 'common-components/typography';
import { Avatar, Icon, Radio, Tooltip } from 'antd';
import _ from 'lodash';
import WorkerStatusTag from 'common-components/tags/WorkerStatusTag';
import { CustomerSupportWorkerPreference } from 'utilities/enum-utils';

class FoundWorkerRow extends Component<{
  worker: any;
  isSearch: boolean;
  selectWorker: (selectedWorker) => void;
  isSelected: boolean;
}> {
  private _workerAttributesText = (worker) => {
    let attributes = [];
    if (worker.skillMatched) {
      attributes.push('Skill match');
    }
    if (worker.previouslyUsed) {
      attributes.push('Previously used');
    }

    return !_.isEmpty(attributes) ? _.join(attributes, ', ') : '';
  };

  render() {
    const { worker, isSelected } = this.props;

    const attributeText = this._workerAttributesText(worker);
    const workerPreferenceBlocked = worker.preference === CustomerSupportWorkerPreference.BLOCKED;
    const workerPreferencePreferred = worker.preference === CustomerSupportWorkerPreference.PREFERRED;
    const isDisabled = worker.availability === 'NOT_AVAILABLE';
    return (
      <tr
        className={`bordered-bottom cursor-pointer ${isSelected &&
          'bg-blue-action-lightest'} ${workerPreferenceBlocked || (isDisabled && `cursor-not-allowed`)} `}
        onClick={() => (workerPreferenceBlocked || isDisabled ? null : this.props.selectWorker(worker))}
      >
        <td className="text-align-center pv-medium">
          <Radio key={worker.supportWorkerId} checked={isSelected} disabled={workerPreferenceBlocked || isDisabled} />
        </td>
        <td style={{ width: '50px' }}>
          <Avatar
            icon="user"
            size="large"
            shape="square"
            src={worker.attachmentUrl}
            style={{ opacity: workerPreferenceBlocked || isDisabled ? 0.5 : 1 }}
          />
        </td>
        <td>
          <table className="width-full">
            <tbody>
              <tr>
                <td colSpan={2}>
                  <Text weight="bold" style={{ opacity: workerPreferenceBlocked || isDisabled ? 0.5 : 1 }}>
                    {worker.firstName} {worker.lastName}
                  </Text>
                  {workerPreferencePreferred && (
                    <Tooltip title={'This worker is preferred by the customer'}>
                      <Icon type="star" className="text-color-warning-orange ml-x-small" theme="filled" />
                    </Tooltip>
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <WorkerStatusTag shiftSlotStatus={worker.availability} preference={worker.preference} />
                </td>
                <td>
                  {attributeText !== '' && (
                    <div style={{ opacity: workerPreferenceBlocked || isDisabled ? 0.5 : 1 }}>
                      <Icon type="info-circle" className="text-color-secondary mr-x-small" />
                      {attributeText}
                    </div>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  }
}

export default FoundWorkerRow;
