import React, { Component } from 'react';
import { Form, Input, notification, Select } from 'antd';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { SubTitle, Text } from 'common-components/typography';
import { ICustomer } from 'interfaces/customer-interfaces';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import CareLevelSelector from 'views/customers/details/components/CareLevelSelector';
import Utils from 'utilities/Utils';
import { AlertLevel, CareInformationType } from 'utilities/enum-utils';

const { TextArea } = Input;

interface ICreateEditSupportNeedModalProps extends FormComponentProps {
  closeCreateEditSupportNeedModal: () => void;
  isOpen: boolean;
  isNew: boolean;
  selectedCustomer: ICustomer;
  selectedSupportNeed: any;
  doCreateCustomerCareInfo: typeof dispatch.customersStore.doCreateCustomerCareInfo;
  doUpdateCustomerCareInfo: typeof dispatch.customersStore.doUpdateCustomerCareInfo;
  supportNeedCategories?: typeof state.customersStore.supportNeedCategories;
  supportNeedCategoriesNeeds?: typeof state.customersStore.supportNeedCategoriesNeeds;
}

interface ICreateEditSupportNeedModalState {
  isLoadingFilter: boolean;
  isActionModalOpen: boolean;
  selectedLevel: string;
  isLoading: boolean;
  supportNeed: string;
  isSupportNeedNameValid: boolean;
}

class CreateEditSupportNeedModal extends Component<ICreateEditSupportNeedModalProps, ICreateEditSupportNeedModalState> {
  state = {
    isLoadingFilter: false,
    isActionModalOpen: false,
    selectedLevel: '',
    isLoading: false,
    supportNeed: '',
    isSupportNeedNameValid: true
  };

  private _closeWithActionModal = () => {
    const { form, selectedSupportNeed, isNew } = this.props;
    const { selectedLevel } = this.state;
    let hasChanged = false;
    const formValues = form.getFieldsValue();

    // Check if it's an Add or Edit modal
    if (isNew) {
      if (
        selectedLevel ||
        !Utils.isEmpty(formValues.supportNeedName) ||
        !Utils.isEmpty(formValues.supportNeedCategory) ||
        !Utils.isEmpty(formValues.description)
      ) {
        hasChanged = true;
      }
    } else if (
      selectedLevel !== AlertLevel[selectedSupportNeed.alertLevel] ||
      formValues.description !== selectedSupportNeed.description
    ) {
      hasChanged = true;
    }

    if (hasChanged) {
      this.setState({ isActionModalOpen: true });
    } else {
      this._closeActionCreateModal();
    }
  };

  private _closeActionCreateModal = () => {
    this.setState({
      isActionModalOpen: false,
      isLoadingFilter: false,
      isLoading: false,
      selectedLevel: _.isEmpty(this.props.selectedSupportNeed)
        ? ''
        : AlertLevel[this.props.selectedSupportNeed.alertLevel],
      supportNeed: '',
      isSupportNeedNameValid: true
    });
    this.props.closeCreateEditSupportNeedModal();
  };

  private _changeLevel = (level) => {
    const { form } = this.props;
    form.setFieldsValue({ alertLevel: level });
    this.setState({ selectedLevel: level });
  };

  private _submitSupportNeed = async () => {
    const {
      form,
      doCreateCustomerCareInfo,
      doUpdateCustomerCareInfo,
      isNew,
      selectedSupportNeed,
      selectedCustomer
    } = this.props;
    const { selectedLevel, supportNeed } = this.state;

    this.setState({ isLoading: true });

    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });

    const isSupportNeedNameValid = isNew ? _.trim(supportNeed).length >= 3 && _.trim(supportNeed).length <= 50 : true;

    if (!isSupportNeedNameValid) {
      this.setState({
        isSupportNeedNameValid: false
      });
    }

    const formValues = form.getFieldsValue();

    if (isFormValid && isSupportNeedNameValid) {
      try {
        const payload = isNew
          ? {
              careInformationType: CareInformationType.SUPPORTNEEDS,
              alertLevel: AlertLevel[selectedLevel],
              description: formValues.description,
              userId: selectedCustomer.userId,
              name: _.trim(supportNeed)
            }
          : {
              careInformationType: CareInformationType.SUPPORTNEEDS,
              careInformationId: selectedSupportNeed.careInformationId,
              alertLevel: AlertLevel[selectedLevel],
              description: formValues.description,
              userId: selectedCustomer.userId
            };

        isNew ? await doCreateCustomerCareInfo(payload) : await doUpdateCustomerCareInfo(payload);
        notification.success({ message: `Support Need ${isNew ? 'added' : 'updated'} successfully.` });
        this._closeActionCreateModal();
      } catch (e) {
        console.log(e);
        notification.error({ message: 'Oops something went wrong! Please try again.' });
      }
    }

    this.setState({ isLoading: false });
  };

  componentDidUpdate(
    prevProps: Readonly<ICreateEditSupportNeedModalProps>,
    prevState: Readonly<ICreateEditSupportNeedModalState>,
    snapshot?: any
  ) {
    if (prevProps.selectedSupportNeed !== this.props.selectedSupportNeed) {
      this.setState({
        selectedLevel: this.props.selectedSupportNeed ? AlertLevel[this.props.selectedSupportNeed.alertLevel] : ''
      });
    }
  }

  render() {
    const { form, selectedSupportNeed, isNew } = this.props;
    const { selectedLevel, isLoading } = this.state;
    const { getFieldDecorator } = form;

    return (
      <div>
        <ActionModal
          isOpen={this.state.isActionModalOpen}
          onClose={this._closeActionCreateModal}
          title={'Discard changes'}
          showCloseButton={true}
        >
          <Text className={'mb-medium'}>
            You have <b>unsaved data</b>, proceeding will discard these changes.
          </Text>
          <br />
          <Text className={'mb-medium'}>Do you want to proceed?</Text>
          <ActionModalFooter>
            <PrimaryButton
              className="mr-medium"
              size="large"
              onClick={() => {
                this.setState({ isActionModalOpen: false });
              }}
            >
              Cancel
            </PrimaryButton>
            <GhostButton size="large" onClick={this._closeActionCreateModal}>
              Proceed
            </GhostButton>
          </ActionModalFooter>
        </ActionModal>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this._closeWithActionModal}
          title={
            isNew ? (
              <>
                Add a <b>Support Need</b>
              </>
            ) : (
              <>
                Edit a <b>Support Need</b>
              </>
            )
          }
          showCloseButton={true}
          verticalAlignment={'center'}
          width={'medium'}
        >
          {isNew && (
            <div className="mb-large">
              <Text>
                Add a <b>Support Need</b> that the customer has.
              </Text>
            </div>
          )}
          <div className="mb-large">
            <SubTitle>Support Need</SubTitle>
            {isNew ? (
              <>
                <Input
                  className={'inline-block '}
                  size={'large'}
                  onChange={(e) => {
                    this.setState({ supportNeed: e.target.value, isSupportNeedNameValid: true });
                  }}
                  value={this.state.supportNeed}
                  placeholder={'Enter support need'}
                />
                {!this.state.isSupportNeedNameValid && (
                  <Text color="red">Support need must be between 3 to 50 characters.</Text>
                )}
              </>
            ) : (
              selectedSupportNeed.name
            )}
          </div>
          <div className="mb-large">
            <div className="flex-row align-center mb-x-small">
              <SubTitle>ALERT LEVEL</SubTitle>
              <Text className="ml-x-small" size="regular">
                (Select one)
              </Text>
            </div>
            <CareLevelSelector selectedLevel={selectedLevel} changeLevel={this._changeLevel} />
            <Form.Item className="p-none">
              {getFieldDecorator('alertLevel', {
                rules: [{ required: true, message: 'Choose one alert level' }],
                initialValue: selectedLevel
              })(<Input type="hidden" />)}
            </Form.Item>
          </div>
          <div className="mb-x-large">
            <div className="flex-row align-center mb-x-small">
              <SubTitle>Description</SubTitle>
              <Text className="ml-x-small" size="regular">
                (Optional)
              </Text>
            </div>
            <Form.Item className="mb-large">
              {getFieldDecorator('description', {
                rules: [],
                initialValue: selectedSupportNeed ? selectedSupportNeed.description : null
              })(
                <TextArea placeholder={'Describe this support need, or leave a remark about this support need here.'} />
              )}
            </Form.Item>
          </div>
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._closeWithActionModal}>
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._submitSupportNeed} loading={isLoading}>
              {isNew ? 'Add Support Need' : 'Save Support Need'}
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      </div>
    );
  }
}

const mapState = (state: IRootState) => ({
  supportNeedCategories: state.customersStore.supportNeedCategories,
  supportNeedCategoriesNeeds: state.customersStore.supportNeedCategoriesNeeds
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCreateCustomerCareInfo: dispatch.customersStore.doCreateCustomerCareInfo,
  doUpdateCustomerCareInfo: dispatch.customersStore.doUpdateCustomerCareInfo
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<ICreateEditSupportNeedModalProps>()(CreateEditSupportNeedModal));
