import React, { Component } from 'react';
import moment from 'moment';
import { FormComponentProps } from 'antd/lib/form';
import { ITagFilter } from 'interfaces/filter-interfaces';
import { Col, DatePicker, Form, Row, Select } from 'antd';
import { BookingStatus } from 'utilities/enum-utils';
import { SubTitle } from 'common-components/typography';

const Option = Select.Option;

const statusOptions = [
  { label: 'All Status', val: '' },
  { label: BookingStatus.PENDING, val: BookingStatus.PENDING },
  { label: BookingStatus.ACCEPTED, val: BookingStatus.ACCEPTED },
  { label: BookingStatus.CONFIRMED, val: BookingStatus.CONFIRMED },
  { label: BookingStatus.INPROGRESS, val: BookingStatus.INPROGRESS },
  { label: BookingStatus.COMPLETED, val: BookingStatus.COMPLETED },
  // { label: BookingStatus.CANCELLED, val: BookingStatus.CANCELLED },
  // { label: BookingStatus.APPROVED, val: BookingStatus.APPROVED },
  // { label: BookingStatus.READY_FOR_BILLING, val: BookingStatus.READY_FOR_BILLING },
  // { label: BookingStatus.BILLED, val: BookingStatus.BILLED },
  // { label: BookingStatus.BILLING_FAILED, val: BookingStatus.BILLING_FAILED },
  { label: BookingStatus.REJECTED, val: BookingStatus.REJECTED },
  { label: BookingStatus.CUSTOMER_CANCELLED, val: BookingStatus.CUSTOMER_CANCELLED },
  { label: BookingStatus.CUSTOMER_CANCELLED_WITH_FEE, val: BookingStatus.CUSTOMER_CANCELLED_WITH_FEE },
  { label: BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE, val: BookingStatus.CUSTOMER_CANCELLED_WITHOUT_FEE },
  { label: BookingStatus.BUSINESS_CANCELLED, val: BookingStatus.BUSINESS_CANCELLED }
];

interface IBookingListFilterPaneProps extends FormComponentProps {
  filters: ITagFilter[];
  handleStatus: (status: string) => void;
  handleStartDate: (startDate: any) => void;
  handleEndDate: (endDate: any) => void;
  isOpen: boolean;
  isDisabled?: boolean;
}

interface IBookingListFilterPaneState {
  bookingStatus: string;
  searchName: string;
  startDate: any;
  endDate: any;
}

class WorkerBookingListFilterPanel extends Component<IBookingListFilterPaneProps, IBookingListFilterPaneState> {
  state = { bookingStatus: '', searchName: '', startDate: '', endDate: '' };

  private _handleStatus = (status) => {
    this.props.handleStatus(status);
    this.setState({ bookingStatus: status });
  };

  private _handleStartDate = (startDate) => {
    this.setState({ startDate: moment(startDate) });
    this.props.handleStartDate(startDate);
  };

  private _handleEndDate = (endDate) => {
    this.setState({ endDate: moment(endDate) });
    this.props.handleEndDate(endDate);
  };

  render() {
    const { form, isDisabled } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Row type={'flex'} gutter={30}>
        <Col span={4}>
          <Form.Item>
            {getFieldDecorator('bookStatus', {
              initialValue: this.state.bookingStatus
            })(
              <Select
                className="bg-tertiary focus-bg-white width-full"
                onChange={this._handleStatus}
                placeholder={'Select Status'}
                disabled={isDisabled}
              >
                {statusOptions.map((statusOption) => (
                  <Option value={statusOption.val}>{statusOption.label}</Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={8}>
          <Row type={'flex'}>
            <Col span={4} className={'mt-small'}>
              <SubTitle>From</SubTitle>
            </Col>
            <Col>
              <Form.Item>
                {getFieldDecorator('startDate', {
                  initialValue: this.state.startDate
                })(
                  <DatePicker
                    size="large"
                    placeholder={'Start Date'}
                    allowClear={true}
                    format="DD-MM-YYYY"
                    className={'width-full'}
                    name={'startDate'}
                    disabled={isDisabled}
                    onChange={(event) => this._handleStartDate(event)}
                    disabledDate={(current) => {
                      const endValue = this.props.form.getFieldValue('endDate');
                      if (endValue !== null && endValue !== '' && current > moment()) {
                        return current.valueOf() >= endValue.valueOf();
                      }
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row type={'flex'}>
            <Col span={3} className={'mt-small'}>
              <SubTitle>To</SubTitle>
            </Col>
            <Col>
              <Form.Item>
                {getFieldDecorator('endDate', {
                  initialValue: this.state.endDate
                })(
                  <DatePicker
                    size="large"
                    placeholder={'End Date'}
                    allowClear={true}
                    format="DD-MM-YYYY"
                    className={'width-full'}
                    name={'endDate'}
                    disabled={isDisabled}
                    onChange={(event) => this._handleEndDate(event)}
                    disabledDate={(current) => {
                      const startValue = this.props.form.getFieldValue('startDate');
                      if (startValue !== null && startValue !== '') {
                        return current.valueOf() < startValue.valueOf();
                      } else {
                        return current < moment();
                      }
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default WorkerBookingListFilterPanel;
