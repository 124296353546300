import React from 'react';
import ReactDOM from 'react-dom';

// Routing Related
import { Router, Switch } from 'react-router-dom';

// blueprint css include
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import '@blueprintjs/popover2/lib/css/blueprint-popover2.css';

// default css overrides for Ant/Blueprint
// Ant
import './theme/ant-css-overrides.css';

// Google Map
import './theme/googlemap-css-overrides.css';

// Blueprint
import './theme/blueprintjs-css-overrides.css';

// CSS overrides for portal.
import './theme/portal-common-styles.css';
import './theme/portal-utility-styles.css';
import './theme/portal-colors.css';

// React Big Calendar
import 'react-big-calendar/lib/css/react-big-calendar.css';

// React datepicker
import 'react-datepicker/dist/react-datepicker.css';
import './theme/react-datepicker-override.css';

// App bootstrapper
import App from './App';

// Persist Gate
import Gate from 'stores/rematch/components/Gate';

// Service worker. Probably can remove later.
import * as serviceWorker from './serviceWorker';

// Browser history for navigation/routing
import history from 'globalHistory';

// Global react-query client provider + client
// This needs to be placed here for state persistence.
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

ReactDOM.render(
  <Gate>
    <Router history={history}>
      <Switch>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Switch>
    </Router>
  </Gate>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
