import React, { Component } from 'react';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';
import CenteredLayout from 'layouts/CenteredLayout';
import { FieldValueText, Text, Title } from 'common-components/typography';
import { Avatar, Col, Icon, Row, Skeleton, Tabs } from 'antd';
import { HyperlinkButton } from 'common-components/buttons';
import { ProgressBar } from '@blueprintjs/core';
import { RouteComponentProps } from 'react-router-dom';
import { FormComponentProps } from 'antd/es/form';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import WorkerDetailsBookingPanel from './tabs-panel/bookings/WorkerDetailsBookingPanel';
import WorkerDetailTimeAvailabilityPanel from './tabs-panel/availability/WorkerDetailTimeAvailabilityPanel';
import TeamDetailsGeneralPanel from './tabs-panel/general/TeamDetailsGeneralPanel';
import DocumentsPanel from 'common-components/documents/DocumentsPanel';
import PermissionUtils from 'utilities/permission-utils';
import { TeamStatus } from 'utilities/enum-utils';
import moment from 'moment';

const { TabPane } = Tabs;

interface IWorkerUrlParams {
  workerId: string;
}

interface ITeamDetailsViewV2Props extends FormComponentProps, RouteComponentProps<IWorkerUrlParams> {
  doFetchSelectedWorker: typeof dispatch.teamStore.doFetchSelectedWorker;
  selectedWorkerItem: any;
  workerDocuments: typeof state.teamStore.workerDocuments;
  portalUser: typeof state.authStore.portalUser;
  doFetchWorkerDocuments: typeof dispatch.teamStore.doFetchWorkerDocuments;
  doFetchMoreDocuments: typeof dispatch.teamStore.doFetchMoreDocuments;
  setWorkerDocuments: typeof dispatch.teamStore.setWorkerDocuments;
  doDownloadDocument: typeof dispatch.teamStore.doDownloadDocument;
}

interface ITeamDetailsViewV2State {
  isLoading: boolean;
  activeKey: string;
}

class TeamDetailsViewV2 extends Component<ITeamDetailsViewV2Props, ITeamDetailsViewV2State> {
  state = {
    isLoading: false,
    activeKey: 'generalinfo'
  };

  async componentDidMount() {
    const { match, doFetchSelectedWorker } = this.props;
    const { params } = match;
    const selectedWorkerId = params.workerId;
    this.setState({ isLoading: true });
    await doFetchSelectedWorker({ supportWorkerId: selectedWorkerId });
    this.setState({ isLoading: false });
  }
  private changeTab = (key) => {
    this.setState({
      activeKey: key
    });
  };
  private _onAlertTagView = () => {
    this.setState({ activeKey: 'documents' });
  };

  render() {
    if (this.state.isLoading) {
      return (
        <CenteredLayout backgroundColor="white" bordered>
          <div className="item-container">
            <div className="pb-medium">
              <Text>Fetching team member details...</Text>
            </div>
            <ProgressBar />
          </div>
          <Skeleton loading={this.state.isLoading} active avatar title paragraph />
        </CenteredLayout>
      );
    } else {
      const { selectedWorkerItem } = this.props;

      let primaryAddress;
      let primaryContact;
      if (!_.isEmpty(selectedWorkerItem)) {
        primaryAddress = _.find(selectedWorkerItem.addresses, (address) => {
          return address.isPrimary === true;
        });
        primaryContact = _.find(selectedWorkerItem.contactNumbers, (contactNumber) => {
          return contactNumber.preferredContact === true;
        });
      } else {
        primaryAddress = '';
        primaryContact = '';
      }

      const hasEditPermission = PermissionUtils.validatePermission(
        'EditTeamMemberProfile',
        this.props.portalUser.permissions.permissionRoles
      );

      return (
        <div>
          {!_.isEmpty(selectedWorkerItem) ? (
            <CenteredLayout backgroundColor="white" bordered>
              <div className="mb-x7-large" id="scroll">
                <Row>
                  <div className="mb-medium">
                    <HyperlinkButton onClick={() => this.props.history.push('/team')}>
                      <Icon type="left" /> Back to Listing
                    </HyperlinkButton>
                  </div>
                </Row>
                <Title level={2}>Team Member Details</Title>

                <div className="mv-x-large  align-center">
                  <Row>
                    <Col span={3}>
                      <Avatar
                        icon={'user'}
                        shape={'circle'}
                        className="avatar-x3-large"
                        src={selectedWorkerItem.attachmentUrl}
                      />
                    </Col>
                    <Col span={20}>
                      <div className="text-align-left pr-medium">
                        <Row>
                          <Col>
                            <Title level={2} color="blue" className={'align-center flex'}>
                              {`${selectedWorkerItem.firstName} ${selectedWorkerItem.lastName}`}
                              {/* Commented out until we have a logic behind beeing online */}
                              {/* <PrimaryButton size={'small'} className={'ml-small'} color={'forest-dark'}>
                                Online
                              </PrimaryButton> */}
                            </Title>
                            {selectedWorkerItem.status === TeamStatus.REMOVED ? (
                              <>
                                <div
                                  style={{
                                    width: '100%',
                                    backgroundColor: 'rgba(255,235,235,0.5)',
                                    border: '1px solid #FF7373',
                                    borderRadius: 8,
                                    verticalAlign: 'top',
                                    height: '40px'
                                  }}
                                  className="p-small flex justify-start mt-small"
                                >
                                  <div className="mt-x2-small">
                                    <Icon
                                      type="close-circle"
                                      theme="filled"
                                      className="text-color-red-light text-size-x2-large"
                                    />
                                  </div>
                                  <div className="ml-small">
                                    <div>
                                      <Text style={{ verticalAlign: 'top' }}>
                                        This team member was removed from the system on{' '}
                                        <b>{moment(selectedWorkerItem.removedOn).format('DD/MM/YYYY')}</b>
                                      </Text>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <Row type={'flex'}>
                                  <Col span={1}>
                                    <Icon type={'environment'} />
                                  </Col>
                                  <Col span={8}>
                                    <FieldValueText
                                      text={
                                        primaryAddress && primaryAddress.locality && primaryAddress.locality !== ''
                                          ? primaryAddress.locality
                                          : 'Not Set'
                                      }
                                    />
                                  </Col>
                                </Row>
                                <Row type={'flex'}>
                                  <Col span={1}>
                                    <Icon type={'phone'} />
                                  </Col>
                                  <Col span={8}>
                                    <FieldValueText
                                      text={
                                        primaryContact !== '' && primaryContact !== undefined
                                          ? parsePhoneNumberFromString(
                                              primaryContact.number,
                                              primaryContact.numberCountryCode !== '' &&
                                                primaryContact.numberCountryCode !== ''
                                                ? primaryContact.numberCountryCode
                                                : 'AU'
                                            ).format('INTERNATIONAL')
                                          : 'Not Set'
                                      }
                                    />
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row className={'mt-medium'}>
                    <Col>
                      <Tabs
                        type="card"
                        className={'customer-tab'}
                        activeKey={this.state.activeKey}
                        onChange={this.changeTab}
                      >
                        <TabPane key="generalinfo" tab="General Information">
                          <TeamDetailsGeneralPanel
                            selectedWorkerItem={selectedWorkerItem}
                            hasEditPermission={hasEditPermission}
                            onAlertTagView={this._onAlertTagView}
                          />
                        </TabPane>
                        <TabPane key="availabilities" tab="Availabilities">
                          <WorkerDetailTimeAvailabilityPanel
                            selectedWorkerItem={selectedWorkerItem}
                            hasEditPermission={hasEditPermission}
                          />
                        </TabPane>
                        {PermissionUtils.validatePermission(
                          'ViewBookingListing',
                          this.props.portalUser.permissions.permissionRoles
                        ) && (
                          <TabPane key="bookings" tab="Bookings">
                            <WorkerDetailsBookingPanel
                              history={this.props.history}
                              portalUser={this.props.portalUser}
                            />
                          </TabPane>
                        )}
                        <TabPane key="documents" tab="Documents">
                          <DocumentsPanel
                            selectedItemId={selectedWorkerItem.supportWorkerId}
                            itemDocuments={this.props.workerDocuments}
                            doFetchDocuments={this.props.doFetchWorkerDocuments}
                            doFetchMoreDocuments={this.props.doFetchMoreDocuments}
                            setDocuments={this.props.setWorkerDocuments}
                            doDownloadDocument={this.props.doDownloadDocument}
                            hasEditPermission={hasEditPermission}
                            itemType="worker"
                          />
                        </TabPane>
                      </Tabs>
                    </Col>
                  </Row>
                </div>
              </div>
            </CenteredLayout>
          ) : (
            <></>
          )}
        </div>
      );
    }
  }
}

const mapState = (state: IRootState) => ({
  selectedWorkerItem: state.teamStore.selectedWorker,
  workerDocuments: state.teamStore.workerDocuments,
  portalUser: state.authStore.portalUser
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchSelectedWorker: dispatch.teamStore.doFetchSelectedWorker,
  doFetchWorkerDocuments: dispatch.teamStore.doFetchWorkerDocuments,
  doFetchMoreDocuments: dispatch.teamStore.doFetchMoreDocuments,
  setWorkerDocuments: dispatch.teamStore.setWorkerDocuments,
  doDownloadDocument: dispatch.teamStore.doDownloadDocument
});

export default connect(
  mapState,
  mapDispatch
)(TeamDetailsViewV2);
