import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { SubTitle, Text } from 'common-components/typography';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { IServiceDepartmentList } from 'interfaces/service-interfaces';
import { Form, Input, Radio, Col, Row, Select } from 'antd';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FormComponentProps } from 'antd/es/form';
import NumberInput from 'common-components/inputs/NumberInput';
import SpinningLoadingActionModel from 'common-components/loading/SpinningLoadingActionModel';
import UploadImage from 'common-components/upload/UploadImage';
import { PriceFrenquency } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';
import { GroupServiceClassification } from 'utilities/enum-utils';
import asyncDelay from 'utilities/asyncDelay';

const { TextArea } = Input;

export enum EditGroupServiceDetailModalType {
  NAME = 'SERVICE NAME',
  DESCRIPTION = 'SERVICE DESCRIPTION',
  CATEGORY = 'SERVICE CATEGORY',
  BANNER = 'BANNER IMAGE',
  LOCATION = 'SERVICE LOCATION',
  DEPARTMENT = 'SERVICE DEPARTMENT',
  PRICE = 'DISPLAY PRICE'
}

interface IEditGroupServiceDetailModalProps extends FormComponentProps {
  isOpen: boolean;
  onClose: () => void;
  editSection: EditGroupServiceDetailModalType;
  serviceId: string;
  selectedServiceDetail: typeof state.servicesStore.selectedServiceDetail;
  serviceDepartmentList: IServiceDepartmentList[];
  doUpdateGroupServiceDetail: typeof dispatch.servicesStore.doUpdateGroupServiceDetail;
  doFetchServiceDepartmentsLite: typeof dispatch.servicesStore.doFetchServiceDepartmentsLite;
}

interface IEditGroupServiceDetailModalState {
  isLoading: boolean;
  isNoBannerError: boolean;
  bannerUrl: any;
}

class EditGroupServiceDetailModal extends Component<
  IEditGroupServiceDetailModalProps,
  IEditGroupServiceDetailModalState
> {
  state = {
    isLoading: false,
    isNoBannerError: false,
    bannerUrl: null
  };

  private _validateForm = () => {
    const { form } = this.props;

    let isFormValid = true;

    form.validateFields(async (err) => {
      if (err) {
        isFormValid = false;
      }
    });

    if (this.props.editSection === EditGroupServiceDetailModalType.BANNER && _.isEmpty(this.state.bannerUrl)) {
      isFormValid = false;
      this.setState({ isNoBannerError: true });
    }

    return isFormValid;
  };

  private _onSave = async () => {
    if (!this._validateForm()) return;

    const payload = this._buildPayload();
    this.setState({ isLoading: true });
    await this.props.doUpdateGroupServiceDetail(payload);
    this.setState({ isLoading: false });
    this._onCloseModal();
  };

  private _buildPayload = () => {
    const { editSection, serviceId, form } = this.props;

    switch (editSection) {
      case EditGroupServiceDetailModalType.NAME:
        return { serviceId, serviceName: form.getFieldValue('serviceName') };
      case EditGroupServiceDetailModalType.DESCRIPTION:
        return { serviceId, serviceDescription: form.getFieldValue('serviceDescription') };
      case EditGroupServiceDetailModalType.CATEGORY:
        return { serviceId, groupServiceClassification: form.getFieldValue('category') };
      case EditGroupServiceDetailModalType.BANNER:
        return {
          serviceId,
          bannerUrl: this.state.bannerUrl.attachmentUrl,
          bannerPath: this.state.bannerUrl.attachmentPath
        };
      case EditGroupServiceDetailModalType.DEPARTMENT:
        const serviceDepartmentId = form.getFieldValue('serviceDepartment');
        const { serviceDepartmentName } = _.find(
          this.props.serviceDepartmentList,
          (department) => department.serviceDepartmentId === serviceDepartmentId
        );
        return { serviceId, serviceDepartmentId, serviceDepartmentName };
      case EditGroupServiceDetailModalType.PRICE:
        return { serviceId, price: form.getFieldValue('price'), priceFrequency: form.getFieldValue('priceFrequency') };
      default:
        return null;
    }
  };

  private _onCloseModal = () => {
    this.setState({ isLoading: false, isNoBannerError: false, bannerUrl: null });
    this.props.onClose();
  };

  private _onSuccessUploadImage = (item) => {
    this.setState({ bannerUrl: { attachmentUrl: item.url, attachmentPath: item.urlPath }, isNoBannerError: false });
  };

  componentDidUpdate = async (
    prevProps: Readonly<IEditGroupServiceDetailModalProps>,
    prevState: Readonly<IEditGroupServiceDetailModalState>,
    snapshot?: any
  ) => {
    if (
      (!prevProps.editSection || prevProps.editSection !== EditGroupServiceDetailModalType.DEPARTMENT) &&
      this.props.editSection === EditGroupServiceDetailModalType.DEPARTMENT
    ) {
      //await asyncDelay(1000);
      await this.props.doFetchServiceDepartmentsLite({});
    }
  };

  private _renderModalContent = () => {
    const { form, selectedServiceDetail, serviceId, serviceDepartmentList } = this.props;
    const { getFieldDecorator } = form;

    switch (this.props.editSection) {
      case EditGroupServiceDetailModalType.NAME:
        return (
          <div>
            <SubTitle>SERVICE NAME</SubTitle>
            <Form.Item className={'m-none'}>
              {getFieldDecorator('serviceName', {
                initialValue: selectedServiceDetail.serviceName,
                rules: [{ required: true, message: 'Please input the service name.' }]
              })(<Input className="p-small" size="large" />)}
            </Form.Item>
          </div>
        );
      case EditGroupServiceDetailModalType.DESCRIPTION:
        return (
          <div>
            <SubTitle>SERVICE DESCRIPTION</SubTitle>
            <Form.Item className={'m-none'}>
              {getFieldDecorator('serviceDescription', {
                initialValue: selectedServiceDetail.serviceDescription,
                rules: [{ required: true, message: 'Please enter the service description.' }]
              })(<TextArea className="p-small" style={{ height: '240px' }} />)}{' '}
            </Form.Item>
          </div>
        );
      case EditGroupServiceDetailModalType.CATEGORY:
        return (
          <div>
            <Form.Item className={'m-none'}>
              {getFieldDecorator('category', {
                initialValue: selectedServiceDetail.groupServiceClassification,
                rules: [{ required: true, message: 'Please select the service category.' }]
              })(
                <Radio.Group>
                  <Radio
                    style={{ height: '30px', lineHeight: '30px', display: 'block' }}
                    value={GroupServiceClassification.COMMUNITY}
                  >
                    Community based
                  </Radio>
                  <Radio
                    style={{ height: '30px', lineHeight: '30px', display: 'block' }}
                    value={GroupServiceClassification.CENTRE_BASED}
                  >
                    Centre-based
                  </Radio>
                </Radio.Group>
              )}
            </Form.Item>
          </div>
        );
      case EditGroupServiceDetailModalType.BANNER:
        return (
          <div>
            <Text size="x2-large">Recommended size: 400x400</Text>
            <div className="mv-medium">
              <UploadImage
                url={this.state.bannerUrl && this.state.bannerUrl.attachmentUrl}
                onFinishUpload={this._onSuccessUploadImage}
                uploadPath={`services/${serviceId}`}
                listType="text"
                uploadButton={2}
              />
            </div>
            {this.state.isNoBannerError && (
              <div>
                <Text color="red-dark">Please upload a banner image.</Text>
              </div>
            )}
          </div>
        );
      case EditGroupServiceDetailModalType.DEPARTMENT:
        return (
          <>
            <SubTitle>SELECT SERVICE DEPARTMENT</SubTitle>
            <Form.Item className={'m-none'}>
              {getFieldDecorator('serviceDepartment', {
                initialValue: serviceDepartmentList && selectedServiceDetail.serviceDepartmentId,
                rules: [{ required: true, message: 'Please select a service department.' }]
              })(
                <Select placeholder="Select a service department." size={'large'} className={'width-1/2'}>
                  {_.map(serviceDepartmentList, (department) => {
                    return (
                      <Select.Option value={department.serviceDepartmentId}>
                        {department.serviceDepartmentName}
                      </Select.Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>
          </>
        );
      case EditGroupServiceDetailModalType.PRICE:
        return (
          <>
            <Row>
              <Col span={9} className="mr-medium">
                <SubTitle>PRICE</SubTitle>
              </Col>
              <Col span={9} className="m-none">
                <SubTitle>UNIT</SubTitle>
              </Col>
              <Col span={6}></Col>
            </Row>
            <Row>
              <Col span={9} className="mr-medium">
                <Form.Item className={'m-none'}>
                  {getFieldDecorator('price', {
                    initialValue: selectedServiceDetail.price ? Number(selectedServiceDetail.price) : null,
                    rules: [
                      {
                        required: true,
                        type: 'number',
                        min: 0,
                        max: 999999,
                        message: 'Please enter a valid Price.'
                      }
                    ]
                  })(
                    <NumberInput
                      size="large"
                      className={'line-height-100'}
                      style={{ width: '200px' }}
                      precision={2}
                      addonBefore={'$'}
                    />
                  )}
                </Form.Item>
              </Col>
              <Col span={9} id="price-frequency-frorm">
                <Form.Item className={'m-none'}>
                  {getFieldDecorator('priceFrequency', {
                    initialValue: selectedServiceDetail.priceFrequency
                      ? selectedServiceDetail.priceFrequency
                      : 'PERHOUR',
                    rules: [
                      {
                        required: true,
                        message: 'Please enter a Frequency.'
                      }
                    ]
                  })(
                    <Select style={{ width: '200px' }} size="large">
                      {_.map(PriceFrenquency, (frequency) => (
                        <Select.Option value={frequency}>
                          {CommonUtils.formatPriceFrequencyString(frequency)}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}></Col>
            </Row>
          </>
        );
      default:
        return null;
    }
  };

  render() {
    const { editSection } = this.props;
    const title = editSection ? 'Edit ' + editSection.toLowerCase() : 'Edit';

    return (
      <>
        <ActionModal
          isOpen={this.props.isOpen}
          onClose={this._onCloseModal}
          showCloseButton={true}
          width="medium"
          className="p-small"
          title={title}
        >
          {this._renderModalContent()}
          <ActionModalFooter className="mt-large">
            <SecondaryButton
              className="mr-medium"
              size="large"
              onClick={this._onCloseModal}
              disabled={this.state.isLoading}
            >
              Cancel
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._onSave} loading={this.state.isLoading}>
              Save
            </PrimaryButton>
          </ActionModalFooter>
        </ActionModal>
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  serviceDepartmentList: state.servicesStore.serviceDepartmentListLite
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateGroupServiceDetail: dispatch.servicesStore.doUpdateGroupServiceDetail,
  doFetchServiceDepartmentsLite: dispatch.servicesStore.doFetchServiceDepartmentsLite
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IEditGroupServiceDetailModalProps>()(EditGroupServiceDetailModal));
