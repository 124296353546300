import React, { Component } from 'react';

import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { Paragraph, SubTitle, Text } from 'common-components/typography';
import { Avatar, Icon, notification } from 'antd';
import _ from 'lodash';
import { dispatch, IRootDispatch } from 'src/stores/rematch/root-store';
import { connect } from 'react-redux';

interface IUnblockAccessModalProps {
  isOpen: any;
  onClose: any;
  worker: any;
  doUnblockTeamMember: typeof dispatch.teamStore.doUnblockTeamMember;
}

interface IUnblockAccessModalState {
  title: any;
  isDone: boolean;
  isSaving: boolean;
}

class UnblockAccessModal extends Component<IUnblockAccessModalProps, IUnblockAccessModalState> {
  state = { title: 'Unblock Access', isDone: false, isSaving: false };

  private _onCloseModal = () => {
    this.setState({ title: 'Unblock Access', isDone: false });
    this.props.onClose();
  };

  private _saveAction = async () => {
    try {
      this.setState({ isSaving: true });
      await this.props.doUnblockTeamMember({ supportWorkerId: this.props.worker.supportWorkerId });
      this.setState({
        title: (
          <>
            User successfully unblocked
            <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
          </>
        ),
        isDone: true
      });
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
    this.setState({ isSaving: false });
  };

  render() {
    const { isOpen, worker } = this.props;

    return (
      <ActionModal title={this.state.title} isOpen={isOpen} onClose={this._onCloseModal} width="large">
        {!_.isEmpty(worker) &&
          (this.state.isDone ? (
            <>
              <div className="mv-medium ml-x4-large anim-slide-left">
                <div>
                  <SubTitle>User</SubTitle>
                  <div className={'flex-row align-center'}>
                    <Avatar shape={'square'} src={worker.attachmentUrl} className={'mr-medium'} />
                    <Text>{worker.firstName + ' ' + worker.lastName}</Text>
                    <div className={'ml-medium'}>
                      <Text size={'small'} color={'green'}>
                        ENABLED
                      </Text>
                    </div>
                  </div>
                </div>

                <ActionModalFooter>
                  <PrimaryButton size="large" onClick={this._onCloseModal}>
                    Done
                  </PrimaryButton>
                </ActionModalFooter>
              </div>
            </>
          ) : (
            <>
              <div className="mv-medium anim-slide-left">
                <div>
                  <Paragraph>
                    You are choosing to unblock access for <b>{worker.firstName + ' ' + worker.lastName}</b>. This users
                    status will be changed to enabled and they will be able to acces the workspace and business app
                    again.
                  </Paragraph>
                </div>

                <ActionModalFooter>
                  <SecondaryButton size="large" className="mr-medium" onClick={this._onCloseModal}>
                    Cancel
                  </SecondaryButton>
                  <PrimaryButton size="large" loading={this.state.isSaving} onClick={this._saveAction}>
                    Unblock Access
                  </PrimaryButton>
                </ActionModalFooter>
              </div>
            </>
          ))}
      </ActionModal>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  doUnblockTeamMember: dispatch.teamStore.doUnblockTeamMember
});

export default connect(
  null,
  mapDispatch
)(UnblockAccessModal);
