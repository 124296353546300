import React, { Component } from 'react';
import ActionModal, { ActionModalFooter } from '../../../../common-components/modal/ActionModal';
import { Paragraph, Text } from '../../../../common-components/typography';
import { GhostButton, PrimaryButton } from '../../../../common-components/buttons';
import { TransportAttendanceType } from '../../../../utilities/enum-utils';
import { Avatar, Col, notification, Row } from 'antd';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'src/stores/rematch/root-store';
import _ from 'lodash';
import moment from 'moment-timezone';

interface IReinstateTransportBookingModalProps {
  isOpen: boolean;
  onClose: () => void;
  transportBooking: any;
  doCheckWorkerConflict: typeof dispatch.groupBookingsStore.doCheckWorkerConflict;
  doReinstateTransportBooking: typeof dispatch.groupBookingsStore.doReinstateTransportBooking;
  selectedGroupBookingItem: typeof state.groupBookingsStore.selectedGroupBookingItem;
  attendanceType: TransportAttendanceType;
}

interface IReinstateTransportBookingModalState {
  title: string;
  step: number;
  canManuallyClose: boolean;
  isLoading: boolean;
  conflicts: any;
}

class ReinstateTransportBookingModal extends Component<
  IReinstateTransportBookingModalProps,
  IReinstateTransportBookingModalState
> {
  state = { step: 1, title: 'Re-instate booking', canManuallyClose: true, isLoading: false, conflicts: null };

  onCloseModal = () => {
    const { onClose } = this.props;
    this.setState({ step: 1, canManuallyClose: true, title: 'Re-instate booking' }, onClose);
  };

  onAccept = async () => {
    const {
      transportBooking,
      doCheckWorkerConflict,
      doReinstateTransportBooking,
      selectedGroupBookingItem,
      attendanceType
    } = this.props;
    try {
      this.setState({ canManuallyClose: false, isLoading: true });
      if (transportBooking.supportWorkerId) {
        const result = await doCheckWorkerConflict({
          bookingRequestId: selectedGroupBookingItem.bookingRequestId,
          transportBookingId: transportBooking.attendanceId
        });

        if (!_.isEmpty(result)) {
          this.setState({
            step: 3,
            isLoading: false,
            canManuallyClose: true,
            title: 'Team Member conflicts',
            conflicts: result
          });
        } else {
          await doReinstateTransportBooking({
            bookingId: selectedGroupBookingItem.bookingId,
            transportBookingId: transportBooking.attendanceId,
            attendanceType
          });
          this.setState({ step: 2, isLoading: false, canManuallyClose: true });
        }
      } else {
        await doReinstateTransportBooking({
          bookingId: selectedGroupBookingItem.bookingId,
          transportBookingId: transportBooking.attendanceId,
          attendanceType
        });
        this.setState({ step: 2, isLoading: false, canManuallyClose: true });
      }
    } catch (e) {
      this.setState({ canManuallyClose: true });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  doReinstate = async () => {
    const { transportBooking, doReinstateTransportBooking, selectedGroupBookingItem, attendanceType } = this.props;
    try {
      this.setState({ canManuallyClose: false, isLoading: true });
      await doReinstateTransportBooking({
        bookingId: selectedGroupBookingItem.bookingId,
        transportBookingId: transportBooking.attendanceId,
        attendanceType
      });
      this.setState({ step: 2, isLoading: false, canManuallyClose: true });
    } catch (e) {
      this.setState({ canManuallyClose: true });
      notification.error({ message: 'Oops, something went wrong, please try again.' });
    }
  };

  renderView = () => {
    const { step, conflicts } = this.state;

    const displayTimezone = this.props.selectedGroupBookingItem.timezone;

    if (step === 1) {
      return (
        <div className="anim-fade-in">
          <Paragraph>Are you sure you want to re-instate this booking?</Paragraph>
          <ActionModalFooter>
            <GhostButton className="mr-medium" size="large" onClick={this.onCloseModal} disabled={this.state.isLoading}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" icon="check" onClick={this.onAccept} loading={this.state.isLoading}>
              Re-instate
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="anim-fade-in">
          <Paragraph>Booking has been successfully re-instated</Paragraph>
          <ActionModalFooter>
            <PrimaryButton size="large" icon="check" onClick={this.onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 3) {
      return (
        <div>
          <div className="anim-slide-left">
            <div className="mb-medium">
              <Paragraph>The team member assigned to this shift is no longer available.</Paragraph>
              <Paragraph>
                This team member will be removed as the assigned team member for this booking if you press the confirm
                button.
              </Paragraph>
            </div>
          </div>

          <Row className="text-weight-bold bordered-bottom border-standard-gray p-small mt-large">
            <Col span={8}>Date</Col>
            <Col span={8}>Service</Col>
            <Col span={8}>Customer</Col>
          </Row>

          <div>
            {conflicts &&
              _.map(conflicts, (conflict) => (
                <Row type="flex" align="middle" className="p-small border-secondary evenodd">
                  <Col span={8}>
                    {moment
                      .tz(conflict.startDateTime, displayTimezone)
                      .startOf('day')
                      .isSame(moment.tz(conflict.endDateTime, displayTimezone).startOf('day')) ? (
                      <text>
                        <b>{moment.tz(conflict.startDateTime, displayTimezone).format('DD/MM/YYYY')}</b>
                        <br />
                        <Text>
                          {moment.tz(conflict.startDateTime, displayTimezone).format('hh:mm a')} -{' '}
                          {moment.tz(conflict.endDateTime, displayTimezone).format('hh:mm a')}
                        </Text>
                      </text>
                    ) : (
                      <text>
                        <b>{moment.tz(conflict.startDateTime, displayTimezone).format('DD/MM/YYYY')}</b>{' '}
                        {moment.tz(conflict.startDateTime, displayTimezone).format('hh:mm a')}
                        <br />
                        <b>{moment.tz(conflict.endDateTime, displayTimezone).format('DD/MM/YYYY')}</b>{' '}
                        {moment.tz(conflict.endDateTime, displayTimezone).format('hh:mm a')}
                      </text>
                    )}
                  </Col>
                  <Col span={8} title={conflict.serviceName} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {conflict.serviceName}
                  </Col>
                  <Col
                    span={8}
                    title={conflict.shiftSlotStatus}
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    <Text>
                      <Avatar className="mr-medium" size={'small'} src={conflict.attachmentUrl} /> {conflict.firstName}{' '}
                      {conflict.lastName}
                    </Text>
                  </Col>
                </Row>
              ))}
          </div>

          <ActionModalFooter>
            <GhostButton size="large" className="mr-medium" onClick={this.onCloseModal}>
              Cancel
            </GhostButton>
            <PrimaryButton size="large" onClick={this.doReinstate}>
              Reinstate booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
  };

  render() {
    let { isOpen } = this.props;
    const { title } = this.state;
    return (
      <ActionModal
        isOpen={isOpen}
        title={title}
        width="medium"
        onClose={this.onCloseModal}
        canCloseOutside={this.state.canManuallyClose}
        showCloseButton={this.state.canManuallyClose}
        verticalAlignment="highest"
      >
        <div>{this.renderView()}</div>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupBookingItem: state.groupBookingsStore.selectedGroupBookingItem
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doCheckWorkerConflict: dispatch.groupBookingsStore.doCheckWorkerConflict,
  doReinstateTransportBooking: dispatch.groupBookingsStore.doReinstateTransportBooking
});

export default connect(
  mapState,
  mapDispatch
)(ReinstateTransportBookingModal);
