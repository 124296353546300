import React, { Component } from 'react';
import { Checkbox, Col, Form, Icon, InputNumber, Row, Select, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { connect } from 'react-redux';
import { GhostButton, HyperlinkButton, PrimaryButton } from 'common-components/buttons';
import { Paragraph, Text } from 'common-components/typography';
import { ndisHelper } from 'variables/data-helpers';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import _ from 'lodash';
import { NdisSupportTypes, PaymentMethods } from 'utilities/enum-utils';
import CommonUtils from 'utilities/common-utils';

const { Option } = Select;

interface IFundingEditCategoriesPanelProps extends FormComponentProps {
  categoryData: any;
  updateCategoryData: (categoryData) => void;
}

interface IFundingEditCategoriesPanelState {
  isLoading: boolean;
  categoryData: any[];
  isActionModalOpen: boolean;
  rowID: number;
  isAddNewCategory: boolean;
  isSelectingCategory: boolean;
  isSavingNewCategory: boolean;
  isCreatingNew: boolean;
  newCategorySelection: any;
  newLineItemSelection: any;
  deletedItem: any;
}

class FundingEditCategoriesPanel extends Component<IFundingEditCategoriesPanelProps, IFundingEditCategoriesPanelState> {
  state = {
    isLoading: false,
    isActionModalOpen: false,
    isAddNewCategory: false,
    rowID: -1,
    categoryData: [],
    isSelectingCategory: false,
    isSavingNewCategory: false,
    isCreatingNew: false,
    newCategorySelection: null,
    newLineItemSelection: null,
    deletedItem: null
  };

  componentWillMount() {
    const categoryData = _.map(this.props.categoryData, (data) => {
      return {
        ...data,
        funding: Number(data.funding),
        supportItemNumber: data.supportItemNumber ? data.supportItemNumber : null
      };
    });
    this.setState({ categoryData }, () => this.props.updateCategoryData(this.state.categoryData));
  }

  private _addCategoryData = (data) => {
    const { categoryData } = this.state;

    const newCategoryData = _.cloneDeep(categoryData);

    const supportType = data.supportType ? data.supportType : null;
    const supportCategoryName = data.supportCategoryName ? data.supportCategoryName : null;
    const supportCategoryNumber = data.supportCategoryNumber ? data.supportCategoryNumber : null;
    const supportItemNumber = data.supportItemNumber ? data.supportItemNumber : null;
    const paymentMethod = data.paymentMethod ? data.paymentMethod : null;
    const funding = data.funding ? data.funding : 0;
    const note = data.note ? data.note : null;

    const newItem = {
      supportType: supportType,
      supportCategoryName: supportCategoryName,
      supportCategoryNumber: supportCategoryNumber,
      paymentMethod: paymentMethod,
      supportItemNumber: supportItemNumber,
      funding: funding,
      note: note
    };

    newCategoryData.push(newItem);

    this.setState({
      categoryData: newCategoryData,
      isCreatingNew: _.toUpper(supportType) !== _.toUpper('Core'),
      isSelectingCategory: _.toUpper(supportType) !== _.toUpper('Core')
    });
  };

  private _handleSupportTypeActivation = (e, supportType) => {
    const { categoryData } = this.state;

    if (e.target.checked) {
      const data = {
        supportType: supportType,
        supportCategoryNumber: supportType.toUpperCase() === NdisSupportTypes.CORE ? null : -1
      };
      this._addCategoryData(data);
    } else {
      // Retrieve any category added for this support Type (except for the un-yet-selected ones (-1))
      const supportTypeCategories = _.filter(
        categoryData,
        (data) => data.supportType === supportType && data.supportCategoryNumber !== -1
      );
      if (supportTypeCategories.length > 0) {
        const deletedItem = { supportType: supportType };
        this.setState({ isActionModalOpen: true, deletedItem });
      } else {
        const deletedItem = { supportType: supportType };
        this.setState({ deletedItem }, this._deleteSupportItem);
      }
    }
  };

  private _deleteSupportItem = () => {
    const { categoryData, deletedItem } = this.state;

    const newCategoryData = _.filter(categoryData, (data) => data.supportType !== deletedItem.supportType);
    this.setState({ categoryData: newCategoryData, isActionModalOpen: false });
  };

  private _updateCategoryData = (value, categoryNumber, dataType, lineItemNumber = null) => {
    const { categoryData } = this.state;

    if (value !== null && !(dataType === 'funding' && value > 999999)) {
      const updatedCategoryData = _.map(categoryData, (data) => {
        if (categoryNumber === data.supportCategoryNumber && data.supportItemNumber === lineItemNumber) {
          return { ...data, [dataType]: value };
        } else {
          return { ...data };
        }
      });

      this.setState({ categoryData: updatedCategoryData });
    }
  };

  private _paymentMethodSelect = (categoryNumber, lineItem = null) => {
    return (
      <Select
        placeholder="Payment Method"
        style={{ width: '150px' }}
        onChange={(event) => this._updateCategoryData(event, categoryNumber, 'paymentMethod', lineItem)}
      >
        <Option key={categoryNumber} disabled value={null}>
          <Text color="tertiary">Payment Method</Text>
        </Option>
        <Option key={categoryNumber + '_1'} value={PaymentMethods.NDIA}>
          NDIA-managed
        </Option>
        <Option key={categoryNumber + '_2'} value={PaymentMethods.PLAN}>
          PLAN-managed
        </Option>
        <Option key={categoryNumber + '_3'} value={PaymentMethods.SELF}>
          SELF-managed
        </Option>
      </Select>
    );
  };

  private _addStatedSupportItem = (supportType, categoryNumber) => {
    const data = { supportType: supportType, supportCategoryNumber: categoryNumber, supportItemNumber: '-' };
    this._addCategoryData(data);
  };

  private _onSelectCategory = (value) => {
    const data = _.split(value, '|');
    const newSelection = {
      supportCategoryNumber: Number(data[0]),
      supportCategoryName: data[1]
    };

    this.setState({ newCategorySelection: newSelection, isSelectingCategory: false });
  };

  private _onSelectLineItem = (value) => {
    const data = _.split(value, '|');
    const categoryName = ndisHelper.getBySupportItemNumber(data[1]).SupportCategories;

    const newSelection = {
      supportCategoryNumber: Number(data[0]),
      supportItemNumber: data[1],
      supportCategoryName: categoryName
    };

    this.setState({ newLineItemSelection: newSelection, isSelectingCategory: false });
  };

  private _saveSupportCategory = (supportType) => {
    const { categoryData, newCategorySelection } = this.state;

    const newCategoryData = _.cloneDeep(categoryData);

    const updatedCategoryData = _.map(newCategoryData, (data) => {
      if (data.supportType === supportType && data.supportCategoryNumber === -1) {
        return {
          ...data,
          supportCategoryName: newCategorySelection.supportCategoryName,
          supportCategoryNumber: newCategorySelection.supportCategoryNumber
        };
      } else {
        return { ...data };
      }
    });

    this.setState({ categoryData: updatedCategoryData, isCreatingNew: false });
  };

  private _saveLineItemCategory = (supportType, categoryNumber) => {
    const { categoryData, newLineItemSelection } = this.state;

    const newCategoryData = _.cloneDeep(categoryData);

    const updatedCategoryData = _.map(newCategoryData, (data) => {
      if (
        data.supportType === supportType &&
        (Number(data.supportCategoryNumber) === categoryNumber ||
          supportType.toUpperCase() === NdisSupportTypes.CORE) &&
        data.supportItemNumber === '-'
      ) {
        return {
          ...data,
          supportCategoryNumber: newLineItemSelection.supportCategoryNumber,
          supportItemNumber: newLineItemSelection.supportItemNumber,
          supportCategoryName: newLineItemSelection.supportCategoryName
        };
      } else {
        return { ...data };
      }
    });

    this.setState({ categoryData: updatedCategoryData, isCreatingNew: false });
  };

  private _addNewCategory = (supportType) => {
    const data = { supportType: supportType, supportCategoryNumber: -1 };
    this._addCategoryData(data);
  };

  private _deleteItemConfirmation = (categoryNumber, lineItem = null) => {
    const deletedItem = { categoryNumber, lineItem };

    if (categoryNumber === -1 || lineItem === '-') this.setState({ deletedItem }, this._deleteItem);
    else this.setState({ isActionModalOpen: true, deletedItem });
  };

  private _deleteItem = () => {
    const { categoryData, deletedItem } = this.state;

    const newCategoryData = _.cloneDeep(categoryData);
    let filteredCategoryData = [];

    if (deletedItem.lineItem) {
      filteredCategoryData = _.filter(newCategoryData, (data) => data.supportItemNumber !== deletedItem.lineItem);
    } else {
      filteredCategoryData = _.filter(
        newCategoryData,
        (data) => data.supportCategoryNumber !== deletedItem.categoryNumber
      );
    }

    const isCreatingNew =
      deletedItem.categoryNumber === -1 || deletedItem.lineItem === '-' ? false : this.state.isCreatingNew;

    this.setState({ categoryData: filteredCategoryData, isCreatingNew, isActionModalOpen: false });
  };

  private _closeActionModal = () => {
    this.setState({
      isActionModalOpen: false
    });
  };

  private validateFundingNumber = (rule, value, callback) => {
    try {
      if (value === null) {
        throw Error('Budget should not be empty');
      } else if (value > 999999.0) {
        throw Error(`Budget can't exceed $999,999.00.`);
      } else if (typeof value !== 'number') {
        throw Error('Budget must be a number');
      }
    } catch (e) {
      callback(e);
    }
    callback();
  };

  componentDidUpdate = () => {
    this.props.updateCategoryData(this.state.categoryData);
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { categoryData, deletedItem } = this.state;

    const supportTypes = ndisHelper.supportPurposeTypes;
    const totalFunding = _.sumBy(categoryData, (data: any) => Number(data.funding));

    return (
      <React.Fragment>
        <ActionModal
          isOpen={this.state.isActionModalOpen}
          onClose={this._closeActionModal}
          title={'Confirmation'}
          showCloseButton={true}
        >
          {deletedItem && (
            <>
              {!deletedItem.supportType ? (
                <>
                  <Paragraph className={'mb-medium'}>
                    This {!deletedItem.lineItem ? 'Category' : 'Stated Support Item'} will be removed from this Funding
                    package.
                  </Paragraph>
                  {!deletedItem.lineItem && (
                    <Paragraph className={'mb-medium'}>
                      All Stated Supports linked to this Category will be deleted as well.
                    </Paragraph>
                  )}
                </>
              ) : (
                <>
                  <Paragraph className={'mb-medium'}>
                    If you unselected funding for {deletedItem.supportType},{' '}
                    <b>all Categories linked to it will be deleted</b> as well.
                  </Paragraph>
                </>
              )}
              <Paragraph className={'mb-medium'}>Do you want to proceed?</Paragraph>
              <ActionModalFooter>
                <PrimaryButton className="mr-medium" size="large" onClick={this._closeActionModal}>
                  Cancel
                </PrimaryButton>
                <GhostButton
                  size="large"
                  onClick={!deletedItem.supportType ? this._deleteItem : this._deleteSupportItem}
                >
                  Proceed
                </GhostButton>
              </ActionModalFooter>
            </>
          )}
        </ActionModal>

        <Row className="bordered pv-medium ph-medium mt-x2-large bg-tertiary">
          <Col span={12}>
            <Text size="x2-large">Total Funding</Text>
          </Col>
          <Col span={12} className="text-align-right">
            <Text size="x2-large">{CommonUtils.formatPrice(Number(totalFunding))}</Text>
          </Col>
        </Row>
        {_.map(supportTypes, (supportType) => {
          const isCore = supportType.toUpperCase() === NdisSupportTypes.CORE;

          // Extract Categories for Support Type form categoryData and add the statedSupport if any
          const supportCategories = _.filter(categoryData, (data) => {
            return data.supportType === supportType && !data.supportItemNumber;
          }).map((category) => {
            const statedSupports = _.filter(categoryData, (supportData) => {
              if (isCore) return supportData.supportType === category.supportType && supportData.supportItemNumber;
              else {
                return (
                  supportData.supportCategoryNumber === category.supportCategoryNumber && supportData.supportItemNumber
                );
              }
            });
            return { ...category, statedSupports };
          });

          const isActivated = supportCategories.length > 0;

          // Calculate total funding for the Stated Support
          const totalAllStatedSupports = _.sumBy(supportCategories, (category) =>
            _.sumBy(category.statedSupports, (statedSupport: any) => statedSupport.funding)
          );

          // Calculate the total for this Support Type
          const totalSupport = _.sumBy(supportCategories, (category) => category.funding) + totalAllStatedSupports;

          // Get all Line Items for this Support Type
          const supportCategoryListFull = ndisHelper.getByPurposeType(supportType);

          // Group By Categories to get the list for this Support Types
          const supportCategoryList = _.groupBy(supportCategoryListFull, (b) => {
            return b.SupportCategories;
          });

          // For each categories create the Options object;
          const supportCategoryOptions = _.map(supportCategoryList, (category, key) => {
            const isAlreadySelected = _.some(
              categoryData,
              (data) => data.supportCategoryNumber === category[0].SupportCategoryNumber
            );
            return (
              <Option
                key={key}
                disabled={isAlreadySelected}
                value={category[0].SupportCategoryNumber + '|' + category[0].SupportCategories}
              >
                {key}
              </Option>
            );
          });

          return (
            <div className="bordered mv-x-large">
              <Row>
                <div
                  className={`pv-medium ph-medium width-full ${supportCategories.length > 0 &&
                    'bordered-bottom'} flex justify-between`}
                >
                  <div className="flex align-center">
                    <Checkbox
                      checked={isActivated}
                      disabled={this.state.isCreatingNew}
                      className="mr-medium"
                      onChange={(event) => this._handleSupportTypeActivation(event, supportType)}
                    />
                    <Text size="x2-large">{supportType}</Text>
                    {supportType.toUpperCase() !== NdisSupportTypes.CORE && (
                      <HyperlinkButton
                        className={`ml-medium ${this.state.isCreatingNew && 'none'}`}
                        onClick={() => this._addNewCategory(supportType)}
                      >
                        <Icon type="plus" className="mr-x-small" />
                        Add a category
                      </HyperlinkButton>
                    )}
                  </div>
                  {totalSupport > 0 && (
                    <div>
                      <Text size="x2-large">{CommonUtils.formatPrice(totalSupport)}</Text>
                    </div>
                  )}
                </div>
              </Row>
              {_.map(supportCategories, (category, key) => {
                // Retrieve the possible stated Support options
                const supportItemNumberOptions = _.filter(supportCategoryListFull, (lineItem) => {
                  if (!isCore) return lineItem.SupportCategoryNumber === category.supportCategoryNumber;
                  else return lineItem.SupportPurposeType === supportType;
                }).map((lineItem, key) => {
                  const isDisabled = _.some(
                    category.statedSupports,
                    (statedSupport) => statedSupport.supportItemNumber === lineItem.SupportItemNumber
                  );
                  return (
                    <Option
                      title={lineItem.SupportItemNumber + ' - ' + lineItem.SupportItem}
                      key={key}
                      disabled={isDisabled}
                      value={
                        lineItem.SupportCategoryNumber + '|' + lineItem.SupportItemNumber + '|' + lineItem.SupportItem
                      }
                    >
                      {lineItem.SupportItemNumber + ' - ' + lineItem.SupportItem}
                    </Option>
                  );
                });

                const totalCategory =
                  _.sumBy(category.statedSupports, (statedSupport: any) => statedSupport.funding) + category.funding;

                return (
                  <>
                    {key >= 1 && (
                      <Row>
                        <div style={{ height: '20px' }} className="bg-secondary" />
                      </Row>
                    )}
                    <Row className="mv-small pv-small ph-small">
                      <Row className="flex align-center bg-secondary pv-x-small ph-small">
                        {category.supportCategoryNumber > -1 ? (
                          <>
                            <Col span={11} className="align-center" style={{ paddingTop: '8px' }}>
                              <Text>
                                {category.supportCategoryNumber === null
                                  ? 'All Core Categories'
                                  : category.supportCategoryName}
                              </Text>
                            </Col>
                            <Col span={5}>
                              <Form.Item className="m-none" style={{ marginBottom: '1px' }}>
                                {getFieldDecorator('paymentMethod_' + category.supportCategoryNumber, {
                                  initialValue: category.paymentMethod,
                                  rules: [{ required: true, message: 'Please select payment method' }]
                                })(
                                  this._paymentMethodSelect(category.supportCategoryNumber, category.supportItemNumber)
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={7}>
                              <Form.Item className="text-align-right m-none" style={{ marginBottom: '1px' }}>
                                Budget: ${' '}
                                {getFieldDecorator('funding_' + category.supportCategoryNumber, {
                                  initialValue: category.funding,
                                  rules: [{ validator: this.validateFundingNumber }]
                                })(
                                  <InputNumber
                                    precision={2}
                                    min={0}
                                    style={{ width: '120px' }}
                                    max={999999.0}
                                    onChange={(event) =>
                                      this._updateCategoryData(event, category.supportCategoryNumber, 'funding')
                                    }
                                  />
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={1}>
                              <div className="pt-small" style={{ paddingLeft: '12px' }}>
                                <Tooltip title="Delete Category and related Stated Supports funds.">
                                  <HyperlinkButton
                                    color="black"
                                    className="text-size-x-large text-align-center"
                                    onClick={() => this._deleteItemConfirmation(category.supportCategoryNumber)}
                                  >
                                    X
                                  </HyperlinkButton>
                                </Tooltip>
                              </div>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col span={18}>
                              <Form.Item className="m-none" style={{ marginBottom: '1px' }}>
                                {getFieldDecorator('statedSupportsFunding', {
                                  rules: [{ required: true, message: 'Please select support category' }]
                                })(
                                  <Select
                                    placeholder="Select a Support Category"
                                    className="width-4/5"
                                    showSearch
                                    filterOption
                                    optionFilterProp={'children'}
                                    onChange={this._onSelectCategory}
                                  >
                                    {supportCategoryOptions}
                                  </Select>
                                )}
                              </Form.Item>
                            </Col>
                            <Col span={6} className="text-align-right">
                              <PrimaryButton
                                loading={this.state.isSavingNewCategory}
                                disabled={this.state.isSelectingCategory}
                                icon="save"
                                onClick={() => this._saveSupportCategory(supportType)}
                              >
                                Add
                              </PrimaryButton>
                              <HyperlinkButton className="ml-medium" onClick={() => this._deleteItemConfirmation(-1)}>
                                Cancel
                              </HyperlinkButton>
                            </Col>
                          </>
                        )}
                      </Row>
                      {category.supportCategoryNumber > -1 && (
                        <>
                          <Row className="mv-small ph-small">
                            <Text weight="bold">Stated Supports funding</Text>
                            <HyperlinkButton
                              className={`ml-medium ${this.state.isCreatingNew && 'none'}`}
                              onClick={() => this._addStatedSupportItem(supportType, category.supportCategoryNumber)}
                            >
                              + Add more
                            </HyperlinkButton>
                          </Row>
                          {_.map(category.statedSupports, (statedSupport) => {
                            const lineItemName =
                              statedSupport.supportItemNumber !== '-'
                                ? ndisHelper.getBySupportItemNumber(statedSupport.supportItemNumber).SupportItem
                                : null;
                            return (
                              <Row className="mv-small ph-small">
                                {statedSupport.supportItemNumber !== '-' ? (
                                  <>
                                    <Col span={11} className="pt-small">
                                      <Text>{lineItemName}</Text>
                                    </Col>
                                    <Col span={5}>
                                      <Form.Item className="m-none" style={{ marginBottom: '1px' }}>
                                        {getFieldDecorator('paymentMethod_' + statedSupport.supportItemNumber, {
                                          initialValue: statedSupport.paymentMethod,
                                          rules: [{ required: true, message: 'Please select payment method' }]
                                        })(
                                          this._paymentMethodSelect(
                                            statedSupport.supportCategoryNumber,
                                            statedSupport.supportItemNumber
                                          )
                                        )}
                                      </Form.Item>
                                    </Col>
                                    <Col span={7}>
                                      <Form.Item className="text-align-right m-none" style={{ marginBottom: '1px' }}>
                                        Budget: ${' '}
                                        {getFieldDecorator('funding_' + statedSupport.supportItemNumber, {
                                          initialValue: statedSupport.funding,
                                          rules: [{ validator: this.validateFundingNumber }]
                                        })(
                                          <InputNumber
                                            precision={2}
                                            min={0}
                                            style={{ width: '120px' }}
                                            max={1000000}
                                            onChange={(event) =>
                                              this._updateCategoryData(
                                                event,
                                                statedSupport.supportCategoryNumber,
                                                'funding',
                                                statedSupport.supportItemNumber
                                              )
                                            }
                                          />
                                        )}
                                      </Form.Item>
                                    </Col>
                                    <Col span={1}>
                                      <div className="align-center width-full pt-small" style={{ paddingLeft: '12px' }}>
                                        <Tooltip title="Delete Stated Support fund.">
                                          <HyperlinkButton
                                            color="black"
                                            className="text-size-x-large"
                                            onClick={() =>
                                              this._deleteItemConfirmation(
                                                statedSupport.supportCategoryNumber,
                                                statedSupport.supportItemNumber
                                              )
                                            }
                                          >
                                            X
                                          </HyperlinkButton>
                                        </Tooltip>
                                      </div>
                                    </Col>
                                  </>
                                ) : (
                                  <>
                                    <Col span={16}>
                                      <Form.Item className="m-none" style={{ marginBottom: '1px' }}>
                                        {getFieldDecorator('statedSupportsFunding', {
                                          rules: [
                                            {
                                              required: true,
                                              message: 'Please select support line item'
                                            }
                                          ]
                                        })(
                                          <Select
                                            placeholder="Select a Support Line Item"
                                            className="width-4/5"
                                            showSearch
                                            filterOption
                                            optionFilterProp={'children'}
                                            onChange={this._onSelectLineItem}
                                          >
                                            {supportItemNumberOptions}
                                          </Select>
                                        )}
                                      </Form.Item>
                                    </Col>
                                    <Col span={8} className="text-align-right">
                                      <PrimaryButton
                                        loading={this.state.isSavingNewCategory}
                                        disabled={this.state.isSelectingCategory}
                                        icon="save"
                                        onClick={() =>
                                          this._saveLineItemCategory(supportType, statedSupport.supportCategoryNumber)
                                        }
                                      >
                                        Add
                                      </PrimaryButton>
                                      <HyperlinkButton
                                        className="ml-medium"
                                        onClick={() =>
                                          this._deleteItemConfirmation(category.supportCategoryNumber, '-')
                                        }
                                      >
                                        Cancel
                                      </HyperlinkButton>
                                    </Col>
                                  </>
                                )}
                              </Row>
                            );
                          })}
                          {!category.statedSupports ||
                            (category.statedSupports && category.statedSupports.length === 0 && (
                              <Row className="mv-small ph-small">
                                <Text>No Stated Supports fund.</Text>
                              </Row>
                            ))}
                        </>
                      )}
                    </Row>
                    <Row className="text-align-right mv-small ph-small">
                      <Text weight="bold">Total: {CommonUtils.formatPrice(totalCategory)}</Text>
                    </Row>
                  </>
                );
              })}
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export default connect(
  null,
  null
)(Form.create<IFundingEditCategoriesPanelProps>({})(FundingEditCategoriesPanel));
