import React, { Component, ReactNode } from 'react';
import { PORTAL_COLORS, PORTAL_COMMON_SIZES } from 'interfaces/common-ui-types';

import Animate from 'rc-animate';
import { IconButton } from '../buttons';

interface IFullScreenModalProps {
  isOpen: boolean;
  onClose: () => void;
  footerContent?: ReactNode;
  width?: 'small' | 'medium' | 'large' | 'x-large' | number;
  hasFooter?: boolean;
  canClose?: boolean;
  children?: React.ReactNode;
}

interface IFullScreenModalState {
  canClose?: boolean;
}

export default class FullScreenModal extends Component<IFullScreenModalProps, IFullScreenModalState> {
  // width values mapping
  widthValues = {
    small: '640px',
    medium: '768px',
    large: '1024px',
    'x-large': '1152px',
    full: '100%',
  };

  private _handleEscButton = (e) => {
    if (e.keyCode === 27) {
      // handle ESC button
      if (this.state.canClose) {
        // will trigger close only if props.canClose = true
        this.props.onClose();
      }
    }
  };

  componentDidMount() {
    this.setState({
      canClose: this.props.canClose !== undefined ? this.props.canClose : true,
    });
    document.addEventListener('keydown', this._handleEscButton, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this._handleEscButton, false);
  }

  render() {
    const {
      isOpen,
      children,
      footerContent,
      onClose,
      width = 'medium',
      hasFooter = true,
      canClose = true,
    } = this.props;

    let maxWidth: string;

    if (typeof width === 'number') {
      maxWidth = `${width}px`;
    } else {
      maxWidth = this.widthValues[width];
    }

    return (
      <Animate transitionName="fade" transitionAppear>
        {isOpen && (
          <div
            style={{
              display: 'block',
              position: 'fixed',
              top: 0,
              left: 0,
              backgroundColor: 'white',
              height: '100vh',
              width: '100vw',
              zIndex: 999,
            }}
          >
            <div
              style={{
                overflow: 'scroll',
              }}
              className="flex-column height-full anim-slide-up"
            >
              {/* Header */}
              <FullScreenModalHeader onClick={onClose} canClose={canClose} />

              {/*   Content */}
              <div className="p-medium flex flex-grow justify-center">
                <div style={{ width: '100%', maxWidth }}>{children}</div>
              </div>

              {/* Footer */}
              {hasFooter && <div>{footerContent}</div>}
            </div>
          </div>
        )}
      </Animate>
    );
  }
}

class FullScreenModalHeader extends Component<{ onClick: () => void; canClose: boolean }> {
  render() {
    const { onClick, canClose } = this.props;

    return (
      <div className="">
        <div className="text-align-right pv-large ph-x4-large">
          <IconButton
            size="large"
            icon="close"
            shape="circle"
            color="white"
            iconColor="secondary"
            className="hover-bg-secondary text-size-x5-large"
            disabled={!canClose}
            onClick={onClick}
          />
        </div>
      </div>
    );
  }
}

interface FullScreenModalFooterProps {
  children?: ReactNode;
  align?: 'left' | 'center' | 'right'; // alignment of footer
  backgroundColor?: PORTAL_COLORS; // background color
  paddingSize?: PORTAL_COMMON_SIZES;
}

class FullScreenModalFooter extends Component<FullScreenModalFooterProps> {
  render() {
    const { children, align = 'right', backgroundColor = 'tertiary', paddingSize = 'medium' } = this.props;

    const alignment = `text-align-${align}`;

    return <div className={`p-${paddingSize} bg-${backgroundColor} ${alignment}`}>{children}</div>;
  }
}

export { FullScreenModalFooter };
