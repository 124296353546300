import { ProgressBar } from '@blueprintjs/core';
import { Skeleton, Tabs } from 'antd';
import {
  SESSION_CUSTOMER_AVAILABLE_BULK_ACTIONS,
  SESSION_TEAM_MEMBER_AVAILABLE_BULK_ACTIONS
} from 'common-components/bulk-actions/availableActions';
import { BottomActionSheet } from 'common-components/bulk-actions/BottomActionSheet';
import BreadcrumbNav from 'common-components/navigation/BreadcrumbNav';
import { Text } from 'common-components/typography';
import * as H from 'history';
import { ICrumb } from 'interfaces/common-interface';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import Script from 'react-load-script';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import globalConfig from 'variables/global-config';
import GroupServiceSessionNavigationPanel from 'views/group-services/service-details/components/GroupServiceSessionNavigationPanel';
import CloseSessionModal from 'views/group-services/session-details/components/CloseSessionModal';
import EndSessionModal from 'views/group-services/session-details/components/EndSessionModal';
import GroupServiceSessionHeader from 'views/group-services/session-details/components/GroupServiceSessionHeader';
import SessionCustomerView from 'views/group-services/session-details/components/SessionCustomerView';
import SessionOverviewView from 'views/group-services/session-details/components/SessionOverviewView';
import StartSessionModal from 'views/group-services/session-details/components/StartSessionModal';
import SessionCustomerNotesPanel from 'views/group-services/session-details/customer-notes/SessionCustomerNotesPanel';
import { SessionCalloutAction } from 'views/group-services/session-details/SessionCalloutAction';
import SessionTeamMembersPanel from 'views/group-services/session-details/team-members/SessionTeamMembersPanel';
import SessionActivityLogPanel from './SessionActivityLogPanel';

interface IGroupServiceSessionViewUrlParams {
  serviceId: string;
  serviceDateTimeId: string;
}

interface IGroupServiceSessionViewProps
  extends RouteComponentProps<IGroupServiceSessionViewUrlParams, {}, { bookingId?: any; selectedTab?: any }> {
  doFetchSingleSession: typeof dispatch.groupServiceStore.doFetchSingleSession;
  history: H.History;
  selectedSession: typeof state.groupServiceStore.selectedSession;
  setResetSessionCustomers: typeof dispatch.groupServiceStore.setResetSessionCustomers;
  sessionCustomerCustomerBookings: typeof state.groupServiceStore.sessionCustomerCustomerBookings;
  selectedShiftSlots: typeof state.groupServiceStore.selectedShiftSlots;
  setSessionCustomerBookings: typeof dispatch.groupServiceStore.setSessionCustomerBookings;
  selectedSessionTabKey: typeof state.groupServiceStore.selectedSessionTabKey;
  setSelectedSessionTabKey: typeof dispatch.groupServiceStore.setSelectedSessionTabKey;
  setSessionBookingsBilling: typeof dispatch.groupBookingsStore.setSessionBookingsBilling;
}

interface IGroupServiceSessionViewState {
  isCancelSessionOpen: boolean;
  isStartSessionOpen: boolean;
  isEndSessionOpen: boolean;
  isCloseSessionOpen: boolean;
  isLoading: boolean;
}

class GroupServiceSessionView extends Component<IGroupServiceSessionViewProps, IGroupServiceSessionViewState> {
  private customerPanelRef: any = null;
  private teamMemberPanelRef: any = null;
  private _sessionCustomerViewRef = React.createRef() as any;

  state = {
    isAddCustomerOpen: false,
    isSessionFullOpen: false,
    isCancelSessionOpen: false,
    isStartSessionOpen: false,
    isEndSessionOpen: false,
    isCloseSessionOpen: false,
    isLoading: true
  };

  private _onChangeTab = async (selectedTab) => {
    await this.props.setSelectedSessionTabKey(selectedTab);
  };

  private _goToTab = async (selectedTab, options = null) => {
    await this.props.setSelectedSessionTabKey(selectedTab);
  };

  private _resetCustomerList = () => {
    if (this.props.selectedSessionTabKey === 'OVERVIEW') {
      this.props.setResetSessionCustomers(true);
    }
    if (this.customerPanelRef) this.customerPanelRef.refreshList();
  };

  //region Action handlers
  private _openCancelSession = () => {
    this.setState({ isCancelSessionOpen: true });
  };

  private _closeCancelSession = () => {
    this.setState({ isCancelSessionOpen: false });
  };

  private _openStartSession = () => {
    this.setState({ isStartSessionOpen: true });
  };

  private _closeStartSession = (resetSessionList = false) => {
    this.setState({ isStartSessionOpen: false });
    if (resetSessionList) {
      this._resetCustomerList();
    }
  };

  private _openEndSession = () => {
    this.setState({ isEndSessionOpen: true });
  };

  private _closeEndSession = (resetSessionList = false) => {
    this.setState({ isEndSessionOpen: false });
    if (resetSessionList) {
      this._resetCustomerList();
    }
  };

  private _openCloseSession = () => {
    this.setState({ isCloseSessionOpen: true });
  };

  private _closeCloseSession = () => {
    this.setState({ isCloseSessionOpen: false });
  };

  private _setCustomerRef = (ref) => {
    if (ref) {
      this.customerPanelRef = ref;
    }
  };

  private _setTeamMemberRef = (ref) => {
    if (ref) {
      this.teamMemberPanelRef = ref;
    }
  };

  private _assignCustomerFunction = (actionName) => {
    if (this.customerPanelRef) {
      if (actionName === 'APPROVE') {
        return this.customerPanelRef.onBulkApprove;
      } else if (actionName === 'WAIVE_BOOKING') {
        return this.customerPanelRef.onBulkWaive;
      } else if (actionName === 'REVOKE_APPROVAL') {
        return this.customerPanelRef.onBulkRevokeApproval;
      } else if (actionName === 'ADD_LINE_ITEM') {
        return this.customerPanelRef.onBulkAddLineItem;
      } else if (actionName === 'START_BOOKING') {
        return this.customerPanelRef.onBulkStartBooking;
      } else if (actionName === 'END_BOOKING') {
        return this.customerPanelRef.onBulkEndBooking;
      } else {
        return () => false;
      }
    } else {
      return () => false;
    }
  };

  private _assignTeamMemberFunction = (actionName) => {
    if (this.teamMemberPanelRef) {
      if (actionName === 'REMOVE_SLOTS') {
        return this.teamMemberPanelRef.onOpenBulkRemoveShiftSlotModal;
      } else if (actionName === 'CONFIRM') {
        return this.teamMemberPanelRef.onOpenBulkConfirmShiftSlotModal;
      } else if (actionName === 'REMOVE_TEAM_MEMBER') {
        return this.teamMemberPanelRef.onOpenBulkRemoveTeamMemberModal;
      } else if (actionName === 'START_SHIFT') {
        return this.teamMemberPanelRef.onOpenBulkStartShiftSlotModal;
      } else if (actionName === 'FINISH_SHIFT') {
        return this.teamMemberPanelRef.onOpenBulkFinishShiftSlotModal;
      } else if (actionName === 'APPROVE') {
        return this.teamMemberPanelRef.onOpenBulkApproveShiftSlotModal;
      } else {
        return () => false;
      }
    } else {
      return () => false;
    }
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    if (this.props.location && this.props.location.state && this.props.location.state.bookingId) {
      await this.props.setSelectedSessionTabKey('CUSTOMERS');
    } else if (this.props.location && this.props.location.state && this.props.location.state.selectedTab) {
      await this.props.setSelectedSessionTabKey(this.props.location.state.selectedTab);
    } else if (
      this.props.selectedSession &&
      this.props.selectedSession.serviceDateTimeId !== this.props.match.params.serviceDateTimeId
    ) {
      await this.props.setSelectedSessionTabKey('OVERVIEW');
      await this.props.setSessionBookingsBilling([]);
    }
    await this.props.doFetchSingleSession({
      serviceId: this.props.match.params.serviceId,
      serviceDateTimeId: this.props.match.params.serviceDateTimeId
    });
    await this.props.setSessionBookingsBilling([]);
    this.setState({ isLoading: false });
  };

  componentDidUpdate = async (prevProps: Readonly<IGroupServiceSessionViewProps>) => {
    if (
      prevProps.selectedSessionTabKey !== this.props.selectedSessionTabKey &&
      this.props.selectedSessionTabKey === 'TEAM-MEMBERS'
    ) {
      await this.props.doFetchSingleSession({
        serviceId: this.props.match.params.serviceId,
        serviceDateTimeId: this.props.match.params.serviceDateTimeId
      });
    }
  };

  render() {
    const {
      history,
      selectedSession,
      sessionCustomerCustomerBookings,
      selectedShiftSlots,
      selectedSessionTabKey
    } = this.props;
    const { isLoading } = this.state;

    const filteredSelectedSession =
      selectedSessionTabKey === 'CUSTOMERS' &&
      _.filter(sessionCustomerCustomerBookings, (customer) => customer.isChecked);

    let selectedItems = null;

    if (selectedSessionTabKey === 'CUSTOMERS') {
      const groupedBookingByStatus = _.groupBy(filteredSelectedSession, 'status');
      selectedItems =
        !_.isEmpty(groupedBookingByStatus) &&
        _.map(groupedBookingByStatus, (groupedBookings, itemLabel) => {
          const availableActionsFound = _.find(
            SESSION_CUSTOMER_AVAILABLE_BULK_ACTIONS,
            (action) => action.itemLabel === itemLabel
          );
          return availableActionsFound
            ? {
                itemLabel,
                itemCount: groupedBookings && groupedBookings.length,
                actions:
                  availableActionsFound &&
                  _.map(availableActionsFound.actions, (action) => {
                    return { ...action, actionOnClick: this._assignCustomerFunction(action.actionName) };
                  }),
                children: _.filter(
                  _.map(_.groupBy(groupedBookings, 'paymentStatus'), (groupedPaymentStatus, subItemLabel) => {
                    const availableChildrenFound = _.find(
                      availableActionsFound.children,
                      (action) => action.itemLabel === subItemLabel
                    );
                    return availableChildrenFound
                      ? {
                          itemLabel: subItemLabel,
                          itemCount: groupedPaymentStatus && groupedPaymentStatus.length,
                          actions: _.map(availableChildrenFound.actions, (action) => {
                            return { ...action, actionOnClick: this._assignCustomerFunction(action.actionName) };
                          })
                        }
                      : null;
                  }),
                  (childrenList) => childrenList !== null
                )
              }
            : { itemLabel, itemCount: groupedBookings && groupedBookings.length, actions: null, children: [] };
        });
      selectedItems = !_.isEmpty(selectedItems) && selectedItems;
    } else if (selectedSessionTabKey === 'TEAM-MEMBERS') {
      const groupedShiftSlotByStatus = _.groupBy(selectedShiftSlots, 'shiftSlotStatus');
      selectedItems =
        !_.isEmpty(groupedShiftSlotByStatus) &&
        _.map(groupedShiftSlotByStatus, (groupedShiftSlots, itemLabel) => {
          const availableActionsFound = _.find(
            SESSION_TEAM_MEMBER_AVAILABLE_BULK_ACTIONS,
            (action) => action.itemLabel === itemLabel
          );
          return availableActionsFound
            ? {
                itemLabel,
                itemCount: groupedShiftSlots && groupedShiftSlots.length,
                actions:
                  availableActionsFound &&
                  _.map(availableActionsFound.actions, (action) => {
                    return { ...action, actionOnClick: this._assignTeamMemberFunction(action.actionName) };
                  }),
                children: []
              }
            : { itemLabel, itemCount: groupedShiftSlots && groupedShiftSlots.length, actions: null, children: [] };
        });
      selectedItems = !_.isEmpty(selectedItems) && selectedItems;
    }

    const crumbs: ICrumb[] = selectedSession
      ? [
          {
            title: 'Services',
            target: '/services'
          },
          {
            title: selectedSession.serviceName,
            target: '/group-service/details/' + selectedSession.serviceId
          },
          {
            title:
              moment.tz(selectedSession.startDateTime, selectedSession.timezone).format('DD MMMM YYYY hh:mm A') +
              ' - ' +
              moment.tz(selectedSession.endDateTime, selectedSession.timezone).format('hh:mm A')
          }
        ]
      : [];

    if (_.isEmpty(selectedSession)) {
      return <></>;
    }

    if (isLoading) {
      return (
        <div className="view-container">
          <div className="pb-medium">
            <div className="pb-medium">
              <Text>Fetching session details...</Text>
            </div>
            <ProgressBar />
          </div>
          <Skeleton loading={isLoading} />
        </div>
      );
    }

    return (
      <>
        {/* Content section */}
        <div style={{ overflow: 'auto', height: 'calc(100vh - 64px)' }}>
          <BreadcrumbNav history={history} icon={'home'} theme={'filled'} crumbs={crumbs} />

          <div className="width-full">
            <GroupServiceSessionHeader
              resetCustomerList={this._resetCustomerList}
              history={history}
              goToTab={this._goToTab}
            />

            <div className="flex-row justify-center height-full bg-quaternary">
              <div className="width-full" style={{ maxWidth: '1200px' }}>
                {/* Action callout container */}
                <div style={{ marginTop: '-40px' }}>
                  <SessionCalloutAction
                    startDate={selectedSession.startDateTime}
                    endDate={selectedSession.endDateTime}
                    sessionStatus={selectedSession.sessionStatus}
                    onStartSession={this._openStartSession}
                    onCloseSession={this._openCloseSession}
                    onEndSession={this._openEndSession}
                    timezone={selectedSession.timezone}
                  />
                </div>

                <Script
                  url={
                    'https://maps.googleapis.com/maps/api/js?key=' + globalConfig.googleMapApiKey + '&libraries=places'
                  }
                />

                <div className={'flex-row'}>
                  <GroupServiceSessionNavigationPanel
                    selectedTab={selectedSessionTabKey}
                    changeSelectedTab={this._onChangeTab}
                  />

                  <div className="flex-1">
                    <Tabs activeKey={selectedSessionTabKey} renderTabBar={() => <div />} animated={false}>
                      <Tabs.TabPane tab={'OVERVIEW'} key={'OVERVIEW'}>
                        {/* Main container */}
                        <div className="pv-medium pl-small">
                          {/* Content container  */}
                          <div className="flex-1">
                            <SessionOverviewView history={history} goToTab={this._goToTab} />
                          </div>
                        </div>
                      </Tabs.TabPane>

                      <Tabs.TabPane tab={'CUSTOMERS'} key={'CUSTOMERS'}>
                        {/* Main container */}
                        <div className="pb-medium pl-small">
                          {/* Content container  */}
                          <div className="flex-1">
                            <SessionCustomerView
                              ref={this._sessionCustomerViewRef as any}
                              wrappedComponentRef={this._setCustomerRef}
                              history={history}
                            />
                          </div>
                        </div>
                      </Tabs.TabPane>

                      <Tabs.TabPane tab={'TEAM-MEMBERS'} key={'TEAM-MEMBERS'}>
                        {/* Main container */}
                        {selectedSessionTabKey === 'TEAM-MEMBERS' && (
                          <div className="pl-small">
                            <SessionTeamMembersPanel
                              wrappedComponentRef={this._setTeamMemberRef}
                              selectedSession={selectedSession}
                            />
                          </div>
                        )}
                      </Tabs.TabPane>

                      <Tabs.TabPane tab={'NOTES'} key={'NOTES'}>
                        {/* Main container */}
                        {selectedSessionTabKey === 'NOTES' && (
                          <SessionCustomerNotesPanel selectedSession={this.props.selectedSession} />
                        )}
                      </Tabs.TabPane>

                      <Tabs.TabPane tab={'ACTIVITY-LOG'} key={'ACTIVITY-LOG'}>
                        {selectedSessionTabKey === 'ACTIVITY-LOG' && (
                          <SessionActivityLogPanel session={this.props.selectedSession} />
                        )}
                      </Tabs.TabPane>
                    </Tabs>
                    {selectedSessionTabKey === 'CUSTOMERS' &&
                      filteredSelectedSession &&
                      filteredSelectedSession.length > 0 && (
                        <BottomActionSheet
                          selectedItems={selectedItems}
                          onDeselect={this.customerPanelRef && this.customerPanelRef.onActionDeselect}
                          refreshItemListings={this.customerPanelRef && this.customerPanelRef.refreshList}
                        />
                      )}
                    {selectedSessionTabKey === 'TEAM-MEMBERS' &&
                      selectedShiftSlots &&
                      selectedShiftSlots.length > 0 && (
                        <BottomActionSheet
                          selectedItems={selectedItems}
                          onDeselect={this.teamMemberPanelRef && this.teamMemberPanelRef.onDeselectAllShiftSlots}
                          refreshItemListings={this.teamMemberPanelRef && this.teamMemberPanelRef.refreshList}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CloseSessionModal isOpen={this.state.isCloseSessionOpen} closeModal={this._closeCloseSession} />
        <StartSessionModal isOpen={this.state.isStartSessionOpen} closeModal={this._closeStartSession} />
        <EndSessionModal isOpen={this.state.isEndSessionOpen} closeModal={this._closeEndSession} />
      </>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedSession: state.groupServiceStore.selectedSession,
  sessionCustomerCustomerBookings: state.groupServiceStore.sessionCustomerCustomerBookings,
  selectedShiftSlots: state.groupServiceStore.selectedShiftSlots,
  selectedSessionTabKey: state.groupServiceStore.selectedSessionTabKey
});

const mapDispatch = (dispatch: IRootDispatch) => ({
  doFetchSingleSession: dispatch.groupServiceStore.doFetchSingleSession,
  setResetSessionCustomers: dispatch.groupServiceStore.setResetSessionCustomers,
  setSessionCustomerBookings: dispatch.groupServiceStore.setSessionCustomerBookings,
  setSelectedSessionTabKey: dispatch.groupServiceStore.setSelectedSessionTabKey,
  setSessionBookingsBilling: dispatch.groupBookingsStore.setSessionBookingsBilling
});

export default connect(mapState, mapDispatch)(GroupServiceSessionView);
