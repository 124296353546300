import { Checkbox, Col, Form, Icon, InputNumber, Radio, Row, Select, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import { ErrorSVG } from 'assets/UndrawSVG';
import { GhostButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import SpinningLoader from 'common-components/loading/SpinningLoader';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { Paragraph, Text } from 'common-components/typography';
import _ from 'lodash';
import moment, { Moment } from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dispatch, IRootDispatch, IRootState, state } from 'stores/rematch/root-store';
import CommonUtils from 'utilities/common-utils';
import { BookingStatus, EditRecurringMode, ServiceType, ShiftSlotStatus } from 'utilities/enum-utils';
import OptionsSelectorRow from 'views/bookings/components/OptionsSelectorRow';

interface IBookingCancelActionModelState {
  step: number;
  title: any;
  canManuallyClose: boolean;
  selectedOption: EditRecurringMode;
  selectedCustomerCancelledOption: number;
  totalNumberOfCancelledBookings: number;
  numberOfBookings: number;
  inputBookingsToChargeValue: number;
  numberOfBookingsToCharge: number;
  cancelType: any;
  isCharge: boolean;
  formValues: any;
  isCustomerNoShow: number;
  isChargeError: boolean;
  teamMemberShiftHours: number;
  isPayTeamMember: boolean;
}

interface IBookingCancelActionModelProps extends FormComponentProps {
  onClose: () => void;
  selectedBooking: typeof state.bookingsStore.selectedBookingItem;
  selectedGroupBooking: typeof state.groupBookingsStore.selectedGroupBookingItem;
  isOpen: boolean;
  serviceType: string;
  doUpdateBookingCancelBooking: typeof dispatch.bookingsStore.doUpdateBookingCancelBooking;
  doUpdateTeamMemberShiftHoursForCancelledBooking: typeof dispatch.bookingsStore.doUpdateTeamMemberShiftHoursForCancelledBooking;
  doBusinessCancelGroupBooking: typeof dispatch.groupBookingsStore.doBusinessCancelGroupBooking;
  doCustomerCancelGroupBooking: typeof dispatch.groupBookingsStore.doCustomerCancelGroupBooking;
}

class BookingCancelActionModel extends Component<IBookingCancelActionModelProps, IBookingCancelActionModelState> {
  state = {
    step: 1,
    title: 'Cancel Booking',
    canManuallyClose: true,
    selectedOption: EditRecurringMode.Current,
    selectedCustomerCancelledOption: 1,
    totalNumberOfCancelledBookings: 1,
    numberOfBookings: 1,
    inputBookingsToChargeValue: 1,
    numberOfBookingsToCharge: 1,
    cancelType: null,
    isCharge: undefined,
    formValues: null,
    isCustomerNoShow: 1,
    isChargeError: false,
    teamMemberShiftHours: 0,
    isPayTeamMember: false
  };

  private _onCloseModal = () => {
    const { onClose } = this.props;

    // Do any manual clean up; reset to initial state
    this._reset();

    onClose();
  };

  private _setBusinessCancel = () => {
    this.setState({
      title: 'Cancel on behalf of the business',
      cancelType: 'business',
      isCharge: false,
      step:
        this.props.serviceType === 'SUPPORT' &&
        this.props.selectedBooking.isRecurring &&
        this.props.selectedBooking.numberOfBookingLeft > 0
          ? 7
          : 2
    });
  };
  private _setCustomerCancel = () => {
    this.setState((prevState) => ({
      title: prevState.step !== 10 ? 'Cancel on behalf of the customer' : 'Cancel booking with no cancellation charges',
      cancelType: 'customer',
      step:
        this.props.serviceType === 'SUPPORT' &&
        this.props.selectedBooking.isRecurring &&
        this.props.selectedBooking.numberOfBookingLeft > 0
          ? 7
          : 3
    }));
  };

  private _goToChargeStep = () => {
    this.props.form.validateFieldsAndScroll(async (error, value) => {
      if (!error) {
        this.setState({
          formValues: value,
          step:
            this.props.serviceType === 'SUPPORT' &&
            this.props.selectedBooking.isRecurring &&
            this.state.totalNumberOfCancelledBookings > 1
              ? 9
              : 8
        });
      }
    });
  };

  private _onChangeOption = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  private _onChangeNumberOfBookings = (event) => {
    this.setState({ numberOfBookings: event });
  };

  private _onChangeCustomerCancelledOption = (event) => {
    const selectedCustomerCancelledOption = event.target.value;
    this.setState({
      selectedCustomerCancelledOption,
      isCharge: selectedCustomerCancelledOption > 1
    });
  };

  private _onChangeNumberOfBookingsToCharge = (event) => {
    this.setState({ inputBookingsToChargeValue: event });
  };

  private _onChangeTeamMemberShiftHours = (event) => {
    this.setState({ teamMemberShiftHours: event });
  };

  private _handleRecurringSelection = () => {
    this.setState({
      step: this.state.cancelType === 'customer' ? 3 : 2,
      totalNumberOfCancelledBookings:
        this.state.selectedOption === EditRecurringMode.CurrentAll
          ? this.props.selectedBooking.numberOfBookingLeft + 1
          : this.state.selectedOption === EditRecurringMode.CurrentNext
          ? this.state.numberOfBookings + 1
          : 1
    });
  };

  private _displayRecurringOptions = () => {
    this.props.form.validateFieldsAndScroll(async (error, value) => {
      if (!error) {
        this.setState({ formValues: value, step: 7 });
      }
    });
  };

  private _checkChargeSelection = (isCharge) => {
    this.setState({ isCharge }, () => {
      this.props.form.validateFieldsAndScroll(async (error, value) => {
        if (!error) {
          if (isCharge && this.props.selectedBooking.isRecurring && this.state.numberOfBookings > 1) {
            this._displayRecurringOptions();
          } else if (isCharge && this.props.selectedBooking.isRecurring && this.state.numberOfBookings === 1) {
            this.setState({ numberOfBookingsToCharge: 1 }, () => this._onCancelBooking());
          } else {
            this._onCancelBooking();
          }
        }
      });
    });
  };

  private _onChangeIsChargeOption = (event) => {
    this.setState({ isCharge: event.target.value, isChargeError: false });
  };

  private _onChangeIsPayTeamMember = (e) => {
    const isPayTeamMemberStatus = this.state.isPayTeamMember;
    this.setState({ isPayTeamMember: !isPayTeamMemberStatus });
  };

  private _validateCancellationReason = (rule, value, callback) => {
    try {
      if (!value || value === '') {
        throw Error('You must add a cancellation reason.');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _calculateTimeDifference = (startDateTime, endDateTime) => {
    const difference = moment(endDateTime).diff(moment(startDateTime), 'hours', true);
    return difference;
  };

  private _validateTeamMemberShiftHours = (rule, value, callback) => {
    const { startDateTime, endDateTime } = this.props.selectedBooking;
    const bookingTime: any = this._calculateTimeDifference(moment(startDateTime), moment(endDateTime));
    try {
      if (!value || value <= 0) {
        throw Error('Please enter a value');
      } else if (value > bookingTime) {
        throw Error('You cannot enter a value greater than the scheduled length of the booking');
      }
      callback();
    } catch (e) {
      callback(e);
    }
  };

  private _renderDuration = (startDateTime: Moment, endDateTime: Moment) => {
    const duration = CommonUtils.formatDuration(startDateTime, endDateTime);
    const durationDisplays = [];

    if (duration.days) {
      durationDisplays.push(
        <>
          <b>{duration.days.value}</b> {`${duration.days.label} `}
        </>
      );
    }
    if (duration.hours) {
      durationDisplays.push(
        <>
          <b>{duration.hours.value}</b> {`${duration.hours.label} `}
        </>
      );
    }

    if (duration.minutes) {
      durationDisplays.push(
        <>
          <b>{duration.minutes.value}</b> {duration.minutes.label}
        </>
      );
    }
    return (
      <Text color="tertiary">
        {_.map(durationDisplays, (display) => {
          return display;
        })}
      </Text>
    );
  };

  private _calculateTeamMembersShiftTime = (startDateTime, timezone, value) => {
    const addedTime = moment(startDateTime).add(value, 'hours');
    const portalCheckedOutDateTime = moment.tz(addedTime, timezone).toISOString();
    return { shiftStartTime: startDateTime, shiftEndTime: portalCheckedOutDateTime };
  };

  private _onCancelBookingChargeCustomer = () => {
    const { isCharge } = this.state;

    if (isCharge === undefined) {
      this.setState({ isChargeError: true });
      return;
    }

    this.props.form.validateFieldsAndScroll(async (error, value) => {
      if (!error) {
        this.setState(
          {
            isCustomerNoShow: value.chargeCustomerCancellationOption
          },
          () => {
            if (
              isCharge &&
              this.props.serviceType === 'SUPPORT' &&
              this.props.selectedBooking.isRecurring &&
              this.state.numberOfBookings > 1
            ) {
              this._displayRecurringOptions();
            } else if (
              isCharge &&
              this.props.serviceType === 'SUPPORT' &&
              this.props.selectedBooking.isRecurring &&
              this.state.numberOfBookings === 1
            ) {
              this.setState({ numberOfBookingsToCharge: 1 }, () => this._onCancelBooking());
            } else if (
              !isCharge &&
              this.props.serviceType === 'SUPPORT' &&
              this.props.selectedBooking.serviceType === ServiceType.INDIVIDUAL &&
              (this.props.selectedBooking.shiftSlotStatus === ShiftSlotStatus.PENDING ||
                this.props.selectedBooking.shiftSlotStatus === ShiftSlotStatus.CONFIRMED) &&
              this.props.selectedBooking.status === BookingStatus.CONFIRMED
            ) {
              this.setState({ numberOfBookingsToCharge: 1, step: 10 });
            } else {
              this._onCancelBooking();
            }
          }
        );
      }
    });
  };

  private _onCancelBooking = async () => {
    const {
      form,
      doUpdateBookingCancelBooking,
      doUpdateTeamMemberShiftHoursForCancelledBooking,
      serviceType,
      selectedBooking,
      selectedGroupBooking
    } = this.props;
    const { selectedCustomerCancelledOption, totalNumberOfCancelledBookings } = this.state;
    let isValid = true;
    let data = null;

    const numberOfBookingsToCharge =
      this.state.isCharge && this.state.cancelType === 'customer'
        ? totalNumberOfCancelledBookings === 1
          ? 1
          : selectedCustomerCancelledOption === 1
          ? 0
          : selectedCustomerCancelledOption === 2
          ? 1
          : selectedCustomerCancelledOption === 3
          ? this.state.totalNumberOfCancelledBookings
          : this.state.inputBookingsToChargeValue
        : 0;

    const currentBooking = serviceType === 'SUPPORT' ? selectedBooking : selectedGroupBooking;

    if (serviceType === 'SUPPORT') {
      // When the cancellation action happens after the reason form step:
      if (this.state.formValues) {
        data = {
          isBusinessCancel: this.state.cancelType === 'business',
          editRecurringMode: this.state.selectedOption,
          bookingRequestId: currentBooking.bookingRequestId,
          numberOfBookings: this.state.numberOfBookings,
          isRecurring: currentBooking.isRecurring,
          isCharge: !!this.state.isCharge,
          isCustomerNoShow: this.state.isCustomerNoShow === 1 ? true : false,
          numberOfBookingsToCharge,
          shiftSlot: {
            isPaidShift: this.state.isPayTeamMember,
            shiftHours: this.state.teamMemberShiftHours
          },
          ...this.state.formValues
        };
      } else {
        // For business cancelled on 1 bookings. (form is the last step before saving):
        form.validateFieldsAndScroll(async (error, value) => {
          if (!error) {
            data = {
              isBusinessCancel: this.state.cancelType === 'business',
              editRecurringMode: this.state.selectedOption,
              bookingRequestId: currentBooking.bookingRequestId,
              numberOfBookings: this.state.numberOfBookings,
              isRecurring: currentBooking.isRecurring,
              isCharge: !!this.state.isCharge,
              numberOfBookingsToCharge,
              shiftSlot: {
                isPaidShift: false,
                shiftHours: 0
              },
              ...value
            };
          } else {
            console.log(error);
            isValid = false;
          }
        });
      }

      if (isValid && !_.isEmpty(data)) {
        this.setState({ step: 4, inputBookingsToChargeValue: numberOfBookingsToCharge, canManuallyClose: false });
        try {
          await doUpdateBookingCancelBooking(data);
          if (data.shiftSlot.shiftHours > 0) {
            const { shiftStartTime, shiftEndTime } = this._calculateTeamMembersShiftTime(
              selectedBooking.startDateTime,
              selectedBooking.timezone,
              data.shiftSlot.shiftHours
            );
            await doUpdateTeamMemberShiftHoursForCancelledBooking({
              portalCheckedInDateTime: shiftStartTime,
              portalCheckedOutDateTime: shiftEndTime
            });
          }
          this.setState({
            title: (
              <>
                Booking cancelled successfully
                {this.props.serviceType === 'SUPPORT' && (
                  <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
                )}
              </>
            ),
            step: 5,
            canManuallyClose: true
          });
        } catch (e) {
          this.setState({ step: 6, canManuallyClose: true });
        }
      }
    } else {
      let payload;
      let isValid = true;
      if (this.state.formValues) {
        payload = {
          attendanceIds: [currentBooking.bookingId],
          serviceId: currentBooking.serviceId,
          serviceDateTimeId: currentBooking.serviceDateTimeId,
          ...this.state.formValues
        };
      } else {
        form.validateFieldsAndScroll(async (error, value) => {
          if (!error) {
            payload = {
              attendanceIds: [currentBooking.bookingId],
              serviceId: currentBooking.serviceId,
              serviceDateTimeId: currentBooking.serviceDateTimeId,
              ...value
            };
          } else {
            isValid = false;
          }
        });
      }

      if (isValid && !_.isEmpty(payload)) {
        this.setState({ step: 4, inputBookingsToChargeValue: numberOfBookingsToCharge, canManuallyClose: false });
        try {
          if (this.state.cancelType === 'customer') {
            const isCustomerNoShowValue = this.props.form.getFieldValue('chargeCustomerCancellationOption');

            payload.cancellationCode = payload.cancellationReason;
            payload.cancellationReason = payload.reason;
            payload.isChargeCancellationFee = !!this.state.isCharge;
            payload.isCustomerNoShow = isCustomerNoShowValue === 1 ? true : isCustomerNoShowValue === 0 ? false : null;

            await this.props.doCustomerCancelGroupBooking(payload);
          } else {
            payload.cancellationReason = payload.reason;
            await this.props.doBusinessCancelGroupBooking(payload);
          }
          this.setState({
            title: (
              <>
                Booking successfully cancelled
                {/*this.props.serviceType === 'SUPPORT' && (
                  <Icon type={'check-circle'} className={'ml-x-small text-color-green'} />
                )*/}
              </>
            ),
            step: 5,
            canManuallyClose: true
          });
        } catch (e) {
          this.setState({ step: 6, canManuallyClose: true });
        }
      }
    }
  };

  private _onPayTeamMemberForShift = () => {
    const { form } = this.props;
    let isFormValid: boolean = true;
    form.validateFields((error, value) => {
      if (error) {
        isFormValid = false;
      }
    });
    if (isFormValid) {
      this.setState(
        {
          teamMemberShiftHours:
            form.getFieldValue('payTeamMemberShiftHours') !== undefined
              ? form.getFieldValue('payTeamMemberShiftHours')
              : 0
        },
        () => this._onCancelBooking()
      );
    }
  };

  private _reset = () =>
    this.setState({
      step: 1,
      title: 'Cancel Booking',
      canManuallyClose: true,
      selectedOption: EditRecurringMode.Current,
      totalNumberOfCancelledBookings: 1,
      numberOfBookings: 1,
      inputBookingsToChargeValue: 1,
      selectedCustomerCancelledOption: 1,
      cancelType: null,
      isCharge: undefined,
      formValues: null
    });

  private _renderView = () => {
    const { step, numberOfBookings, totalNumberOfCancelledBookings } = this.state;
    const { selectedBooking, selectedGroupBooking, form, serviceType } = this.props;
    const { getFieldDecorator, getFieldValue } = form;
    const currentBooking = serviceType === 'SUPPORT' ? selectedBooking : selectedGroupBooking;

    if (step === 1) {
      return (
        <div>
          <div className="anim-slide-left">
            <div className="mb-medium">
              <Paragraph>Please select one of the following options.</Paragraph>
            </div>

            <div className="text-align-left mb-x-large">
              <OptionsSelectorRow
                title={'Cancel on behalf of the business'}
                description={'Select this option if you are cancelling on behalf of the business.'}
                onClick={() => this._setBusinessCancel()}
              />
              <OptionsSelectorRow
                title={'Cancel on behalf of the customer'}
                description={'Select this option if the customer has requested you to cancel this booking.'}
                onClick={() => this._setCustomerCancel()}
              />

              <div className="bordered-top" />
            </div>
          </div>

          <ActionModalFooter>
            <GhostButton size="large" onClick={this._onCloseModal}>
              I've changed my mind
            </GhostButton>
          </ActionModalFooter>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div>
          <div style={{ minHeight: 317 }}>
            <Paragraph>
              Please indicate the reason for <b>cancelling</b> this booking.
            </Paragraph>
            <Row className="mb-small" type="flex" align="top">
              <Col span={24}>
                <Form.Item>
                  {getFieldDecorator('reason', {
                    initialValue: currentBooking.cancellationReason,
                    rules: [{ validator: this._validateCancellationReason }]
                  })(
                    <TextArea
                      placeholder={'Cancellation reason'}
                      autoSize={{ minRows: 4, maxRows: 8 }}
                      className="width-full"
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>

          <ActionModalFooter>
            <GhostButton size="large" className="mr-medium" onClick={this._reset}>
              I've changed my mind
            </GhostButton>
            <PrimaryButton size="large" onClick={() => this._checkChargeSelection(false)}>
              Confirm
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (step === 3) {
      return (
        <div>
          <div style={{ minHeight: 317 }}>
            <Paragraph>
              Please select the reason the customer asked you to <b>cancel</b> this booking.
            </Paragraph>

            <div className="">
              <Row className="mb-small" type="flex" align="top">
                <Col span={24}>
                  <Form.Item>
                    {getFieldDecorator('cancellationReason', {
                      initialValue: currentBooking.cancellationCode ? currentBooking.cancellationCode : undefined,
                      rules: [{ required: true, message: 'Please select cancellation reason' }]
                    })(
                      <Select className="width-2/3" size={'large'} placeholder={'Cancellation reason'}>
                        <Select.Option value="NSDH">No show due to health reasons (NSDH)</Select.Option>
                        <Select.Option value="NSDF">No show due to family reasons (NSDF)</Select.Option>
                        <Select.Option value="NSDT">No show due to unavailability of transport (NSDT)</Select.Option>
                        <Select.Option value="NSDO">Other</Select.Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              {getFieldValue('cancellationReason') === 'NSDO' && (
                <Row className="mb-small" type="flex" align="top">
                  <Col span={24}>
                    <div className={'mb-medium'}>
                      <Text>Please specify the reason</Text>
                    </div>
                    <Form.Item>
                      {getFieldDecorator('reason', {
                        initialValue: currentBooking.cancellationReason,
                        rules: [{ validator: this._validateCancellationReason }]
                      })(
                        <TextArea
                          className="width-full"
                          placeholder={'Cancellation reason'}
                          autoSize={{ minRows: 3, maxRows: 6 }}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </div>
          </div>
          <ActionModalFooter>
            <GhostButton size="large" className="mr-medium" onClick={this._reset}>
              Back
            </GhostButton>
            <PrimaryButton size="large" onClick={this._goToChargeStep}>
              Confirm
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (step === 4) {
      return (
        <div>
          <div style={{ minHeight: 317 }}>
            <SpinningLoader size={150} message={'Loading'} />
          </div>
        </div>
      );
    }
    if (step === 5) {
      const { firstName, lastName } = currentBooking;
      return (
        <div>
          <div>
            <div className="mb-medium">
              {this.props.serviceType === 'SUPPORT' && (
                <Paragraph>
                  {!currentBooking.isRecurring ? (
                    <>
                      <Text>You have successfully</Text>{' '}
                      <Text weight={'bold'}>
                        cancelled {totalNumberOfCancelledBookings} booking
                        {totalNumberOfCancelledBookings !== 1 && 's'}
                      </Text>{' '}
                      {`on behalf of the ${
                        this.state.cancelType === 'customer'
                          ? `customer and ${!this.state.isCharge ? 'not' : ''} charged a cancellation fee`
                          : 'business'
                      }.`}
                      {currentBooking.shiftSlotStatus === ShiftSlotStatus.CANCELLED_PAID ? (
                        <div className={'mt-large'}>
                          <Text>You have opted to pay the assigned team member for this shift.</Text>
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <Text>You have successfully</Text>{' '}
                      <Text weight={'bold'}>
                        cancelled {totalNumberOfCancelledBookings} booking{totalNumberOfCancelledBookings !== 1 && 's'}
                      </Text>{' '}
                      {`on behalf of the ${
                        this.state.cancelType === 'customer'
                          ? `customer ${!this.state.isCharge ? 'not' : ''} and charged a cancellation fee${
                              this.state.isCharge && this.state.inputBookingsToChargeValue >= 1
                                ? ' for ' + this.state.inputBookingsToChargeValue + ' bookings'
                                : ''
                            }`
                          : 'business'
                      }.`}
                    </>
                  )}
                </Paragraph>
              )}
              {this.props.serviceType === 'GROUP' && (
                <Paragraph>
                  {this.state.cancelType === 'customer' ? (
                    <Text>
                      You have cancelled{' '}
                      <b>
                        {currentBooking.firstName} {currentBooking.lastName}
                      </b>{' '}
                      booking for this session on behalf of the customer & {!this.state.isCharge ? 'not ' : ''}charge a
                      fee.
                    </Text>
                  ) : (
                    <Text>
                      You have cancelled{' '}
                      <b>
                        {currentBooking.firstName} {currentBooking.lastName}
                      </b>{' '}
                      booking for this session on behalf of the business.
                    </Text>
                  )}
                </Paragraph>
              )}
            </div>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Close
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (step === 6) {
      return (
        <div>
          <div style={{ minHeight: 317 }} className="flex-column justify-center text-align-center">
            <div className="pv-medium flex-column justify-center">
              <img src={ErrorSVG} alt={'Error'} style={{ height: '200px' }} />
            </div>

            <div className="mb-medium flex-column justify-center ">
              <Paragraph>Oops something has gone wrong, please try again</Paragraph>
            </div>
          </div>
          <ActionModalFooter>
            <PrimaryButton size="large" onClick={this._onCloseModal}>
              Back to booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (this.state.step === 7) {
      return (
        <>
          <div className="anim-slide-left">
            <Paragraph>
              The booking you are editing is part of a recurring booking series. Please select one of the following
              options for editing this bookings.
            </Paragraph>
          </div>
          <div>
            <Radio.Group value={this.state.selectedOption} onChange={this._onChangeOption} className="ml-medium">
              <Radio
                value={EditRecurringMode.Current}
                className={`${this.state.selectedOption === EditRecurringMode.Current && 'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Cancel this booking only.
                </div>
              </Radio>
              <br />
              <Radio
                value={EditRecurringMode.CurrentAll}
                className={`${this.state.selectedOption === EditRecurringMode.CurrentAll &&
                  'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Cancel this booking and all following bookings.
                </div>
              </Radio>
              <br />
              <Radio
                value={EditRecurringMode.CurrentNext}
                className={`${this.state.selectedOption === EditRecurringMode.CurrentNext &&
                  'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Cancel this booking and the next{' '}
                  <InputNumber
                    className="mh-x-small"
                    style={{ width: '50px' }}
                    min={1}
                    max={currentBooking.numberOfBookingLeft}
                    value={numberOfBookings}
                    onChange={this._onChangeNumberOfBookings}
                  />{' '}
                  booking
                  {numberOfBookings !== 1 && 's'}.
                </div>
                <br />
                <Text size={'regular'} color="secondary" weight="regular" className="ml-x2-large">
                  (There {currentBooking.numberOfBookingLeft !== 1 ? 'are' : 'is'} {currentBooking.numberOfBookingLeft}{' '}
                  left in the recurring series after the currently selected booking)
                </Text>
              </Radio>
            </Radio.Group>
          </div>
          <div className={'mt-large'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
                Cancel
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._handleRecurringSelection}>
                Confirm
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }
    if (step === 8) {
      return (
        <div>
          <div className="anim-slide-left">
            <div className="mb-medium">
              <Paragraph>Please select one of the following options. You can change your mind later.</Paragraph>
            </div>

            <div className=" mb-x-large">
              <Radio
                value={true}
                onChange={this._onChangeIsChargeOption}
                checked={this.state.isCharge}
                className={`${this.state.isCharge === true && 'text-weight-bold'} mb-small width-full`}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Charge a cancellation fee
                </div>
              </Radio>
              {this.state.isCharge === true && (
                <Form.Item className="mt-small">
                  {getFieldDecorator('chargeCustomerCancellationOption', {
                    initialValue: undefined,
                    rules: [{ required: true, message: 'Please select a charge cancellation option' }]
                  })(
                    <Select
                      className="width-2/3"
                      size="large"
                      placeholder={'Select a charge cancellation option'}
                      dropdownMatchSelectWidth={true}
                    >
                      <Select.Option value={1}>Customer was a no show for the booking</Select.Option>
                      <Select.Option value={0}>Customer cancelled ahead of time</Select.Option>
                    </Select>
                  )}
                </Form.Item>
              )}
              <Radio
                value={false}
                onChange={this._onChangeIsChargeOption}
                checked={this.state.isCharge === false}
                className={`${this.state.isCharge === false && 'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Do not charge a cancellation fee
                </div>
              </Radio>
              {this.state.isChargeError && <div className="text-color-red-dark">Please select an option</div>}
            </div>
          </div>

          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={this._onCloseModal}>
              Back
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._onCancelBookingChargeCustomer}>
              Cancel booking
            </PrimaryButton>
          </ActionModalFooter>
        </div>
      );
    }
    if (this.state.step === 9) {
      return (
        <>
          <div className="anim-slide-left">
            <Paragraph>
              You are cancelling {totalNumberOfCancelledBookings} booking
              {totalNumberOfCancelledBookings !== 1 && 's'}. How many do you wish to charge a cancellation fee for?
            </Paragraph>
          </div>
          <div>
            <Radio.Group
              value={this.state.selectedCustomerCancelledOption}
              onChange={this._onChangeCustomerCancelledOption}
              className="ml-medium"
            >
              <Radio
                value={1}
                className={`${this.state.selectedCustomerCancelledOption === 1 && 'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Do not charge a cancellation fee for any bookings.
                </div>
              </Radio>
              <br />
              <Radio
                value={2}
                className={`${this.state.selectedCustomerCancelledOption === 2 && 'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Charge cancellation fee for this booking only.
                </div>
              </Radio>
              <br />
              <Radio
                value={3}
                className={`${this.state.selectedCustomerCancelledOption === 3 && 'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Charge cancellation fee for all bookings being cancelled.
                </div>
              </Radio>
              <Radio
                value={4}
                className={`${this.state.selectedCustomerCancelledOption === 4 && 'text-weight-bold'} mb-small `}
              >
                <div className="ml-medium inline-box inline-flex align-center" style={{ whiteSpace: 'normal' }}>
                  Charge cancellation fee for the first{' '}
                  <InputNumber
                    className="mh-x-small"
                    style={{ width: '50px' }}
                    min={1}
                    max={this.state.totalNumberOfCancelledBookings}
                    value={this.state.inputBookingsToChargeValue}
                    onChange={this._onChangeNumberOfBookingsToCharge}
                  />{' '}
                  booking
                  {this.state.inputBookingsToChargeValue !== 1 && 's'} being cancelled.
                </div>
              </Radio>
            </Radio.Group>
          </div>
          <div className={'mt-large'}>
            <Row type={'flex'} justify={'end'}>
              <SecondaryButton className="mr-medium" size="large" onClick={() => this.setState({ step: 3 })}>
                Back
              </SecondaryButton>
              <PrimaryButton size="large" onClick={this._onCancelBooking}>
                Confirm
              </PrimaryButton>
            </Row>
          </div>
        </>
      );
    }

    if (step === 10) {
      const { startDateTime, endDateTime, timezone } = selectedBooking;
      const bookingStartDateTime = moment.tz(startDateTime, timezone).format('hh:mm A , DD MMMM YYYY');
      const bookingEndDateTime = moment.tz(endDateTime, timezone).format('hh:mm A , DD MMMM YYYY');

      return (
        <>
          <div className="anim-slide-left">
            <Paragraph>
              You are choosing to not charge a cancellation fee for this booking. If the assigned team member still
              needs to be paid for this booking please check the box below. If not press the finish button to finalise
              the cancellation of the booking
            </Paragraph>
          </div>
          <div className={'mt-large'}>
            <Checkbox
              checked={this.state.isPayTeamMember}
              onChange={this._onChangeIsPayTeamMember}
              defaultChecked={false}
            >
              Pay Team member for this booking
            </Checkbox>
          </div>
          {this.state.isPayTeamMember && (
            <div>
              <div className={'mt-large'}>
                <Text className={'text-color-tertiary text-size-small'}>HOURS TEAM MEMBER IS TO BE PAID</Text>{' '}
                <Tooltip
                  title={"Decimal values only. Eg: if 1hour 30minutes needs to be paid, please enter '1.5'"}
                  placement="top"
                  style={{ width: '239px', height: '52px' }}
                >
                  <Icon type="question-circle" className="text-size-x-large text-color-blue ml-x-small" />
                </Tooltip>
              </div>
              <div>
                <Form.Item>
                  {getFieldDecorator('payTeamMemberShiftHours', {
                    initialValue: '',
                    rules: [{ validator: this._validateTeamMemberShiftHours }]
                  })(<InputNumber style={{ width: '320px', height: '40px' }} placeholder={'Input Value...'} />)}
                </Form.Item>
              </div>
              <div className={'mt-large'}>
                <Text className={'text-color-tertiary text-size-small'}>BOOKING SCHEDULED TIME</Text>
              </div>
              <div>
                <Text>
                  {bookingStartDateTime} - {bookingEndDateTime}
                </Text>
              </div>
              <div>{this._renderDuration(moment.tz(startDateTime, timezone), moment.tz(endDateTime, timezone))}</div>
            </div>
          )}
          <ActionModalFooter>
            <SecondaryButton className="mr-medium" size="large" onClick={() => this.setState({ step: 8 })}>
              Back
            </SecondaryButton>
            <PrimaryButton size="large" onClick={this._onPayTeamMemberForShift}>
              Finish
            </PrimaryButton>
          </ActionModalFooter>
        </>
      );
    }
  };

  render() {
    const { isOpen } = this.props;

    return (
      <ActionModal
        title={this.state.title}
        isOpen={isOpen}
        onClose={this._onCloseModal}
        width="large"
        verticalAlignment="highest"
        canCloseOutside={false}
      >
        {this._renderView()}
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedBooking: state.bookingsStore.selectedBookingItem,
  selectedGroupBooking: state.groupBookingsStore.selectedGroupBookingItem
});
const mapDispatch = (dispatch: IRootDispatch) => ({
  doUpdateBookingCancelBooking: dispatch.bookingsStore.doUpdateBookingCancelBooking,
  doUpdateTeamMemberShiftHoursForCancelledBooking:
    dispatch.bookingsStore.doUpdateTeamMemberShiftHoursForCancelledBooking,
  doBusinessCancelGroupBooking: dispatch.groupBookingsStore.doBusinessCancelGroupBooking,
  doCustomerCancelGroupBooking: dispatch.groupBookingsStore.doCustomerCancelGroupBooking
});

export default connect(mapState, mapDispatch)(Form.create<IBookingCancelActionModelProps>()(BookingCancelActionModel));
