import React, { Component } from 'react';
import { Col, Form, Input, notification, Row, Select } from 'antd';
import DatePicker from 'react-datepicker';
import _ from 'lodash';
import { connect } from 'react-redux';
import { IRootDispatch, IRootState } from 'stores/rematch/root-store';
import { SubTitle, Text } from 'common-components/typography';
import { HyperlinkButton, PrimaryButton, SecondaryButton } from 'common-components/buttons';
import { FormComponentProps } from 'antd/es/form';
import moment from 'moment';
import { ndisHelper } from 'variables/data-helpers';
import {
  NdisCancellationReason,
  NdisCancellationReasonLabel,
  NdisClaimType,
  NdisClaimTypeLabel,
  NdisUnitLabel,
  PlanManagementClaimStatus
} from 'utilities/enum-utils';
import NumberInput from 'common-components/inputs/NumberInput';
import CommonUtils from 'utilities/common-utils';
import { PlanManagementClaimTag } from 'common-components/tags';

interface IPlanManagementBillingLineItemProps extends FormComponentProps {
  lineItem: any;
  lineItemIndex: number;
  displayMode: string;
  invoiceIssuedDate?: Date;
  openLineItemModal: (lineItemIndex) => void;
  closeLineItemModal: () => void;
  saveInvoiceLineItem: (payload) => void;
  editLineItem?: (lineItemIndex) => void;
  deleteInvoiceLineItem?: (planManagementLineItemId) => void;
  disabledEdit: boolean;
  cancelEdit?: () => void;
  isLoading: boolean;
  hideActions?: boolean;
}

interface IPlanManagementBillingLineItemState {
  localLineItem: any;
  errorNoSupportItemSelected: boolean;
  total: number;
}

class PlanManagementBillingLineItem extends Component<
  IPlanManagementBillingLineItemProps,
  IPlanManagementBillingLineItemState
> {
  state = {
    localLineItem: null,
    errorNoSupportItemSelected: false,
    total: 0
  };

  private _saveLineItem = async () => {
    const { lineItem, saveInvoiceLineItem, form } = this.props;
    let isFormValid = true;
    form.validateFields((err) => {
      if (err) {
        isFormValid = false;
      }
    });
    if (!lineItem.supportItemNumber) {
      isFormValid = false;
      this.setState({ errorNoSupportItemSelected: true });
    }
    if (isFormValid) {
      try {
        await saveInvoiceLineItem({
          ...lineItem,
          billingDate: form.getFieldValue('billingDate'),
          claimType: form.getFieldValue('claimType'),
          unit: form.getFieldValue('unit'),
          price: form.getFieldValue('price'),
          qty: form.getFieldValue('qty'),
          cancellationCode: form.getFieldValue('cancellationCode') ? form.getFieldValue('cancellationCode') : null
        });
      } catch (e) {
        notification.error({ message: 'Oops! Something went wrong, please try again.' });
      }
    }
  };

  private _deleteLineItem = async () => {
    const { lineItem, deleteInvoiceLineItem } = this.props;
    try {
      await deleteInvoiceLineItem(lineItem.planManagementLineItemId);
    } catch (e) {
      notification.error({ message: 'Oops! Something went wrong, please try again.' });
    }
  };

  private _onChangePrice = (event) => {
    const { lineItem, displayMode, form } = this.props;
    const qty = displayMode === 'VIEW' ? lineItem.qty : form.getFieldValue('qty') ? form.getFieldValue('qty') : 0;

    if (event && qty) {
      this.setState({ total: _.round(event * qty, 2) });
    }
  };

  private _onChangeQuantity = (event) => {
    const { lineItem, displayMode, form } = this.props;
    const price =
      displayMode === 'VIEW' ? lineItem.price : form.getFieldValue('price') ? form.getFieldValue('price') : 0;

    if (event && price) {
      this.setState({ total: _.round(event * price, 2) });
    }
  };

  private _calculateTotal = () => {
    const { lineItem, displayMode, form } = this.props;
    const price =
      displayMode === 'VIEW' ? lineItem.price : form.getFieldValue('price') ? form.getFieldValue('price') : 0;
    const qty = displayMode === 'VIEW' ? lineItem.qty : form.getFieldValue('qty') ? form.getFieldValue('qty') : 0;

    if (price && qty) {
      return _.round(price * qty, 2);
    } else return 0;
  };

  componentDidUpdate(
    prevProps: Readonly<IPlanManagementBillingLineItemProps>,
    prevState: Readonly<IPlanManagementBillingLineItemState>,
    snapshot?: any
  ) {
    if (
      !prevProps.lineItem ||
      !this.props.lineItem ||
      prevProps.lineItem.supportItemNumber !== this.props.lineItem.supportItemNumber
    ) {
      this.setState({ errorNoSupportItemSelected: false });
    }
    if (prevProps.lineItem !== this.props.lineItem) {
      this.setState({ total: this._calculateTotal() });
    }
  }

  componentDidMount() {
    this.setState({ total: this._calculateTotal() });
  }

  render() {
    const {
      lineItem,
      openLineItemModal,
      displayMode,
      isLoading,
      lineItemIndex,
      disabledEdit,
      hideActions = false,
      form
    } = this.props;
    const { getFieldDecorator } = form;
    const { total, errorNoSupportItemSelected } = this.state;

    const displayLineItem = lineItem;
    const NDISPriceGuideItem =
      displayLineItem && displayLineItem.supportItemNumber
        ? ndisHelper.getBySupportItemNumber(lineItem.supportItemNumber)
        : null;
    const displayCancellation =
      displayMode === 'VIEW'
        ? displayLineItem.claimType === NdisClaimType.CANCELLATION
        : form.getFieldValue('claimType') === NdisClaimType.CANCELLATION;

    return (
      <div className={`${displayMode === 'EDIT' ? 'shadow-box' : 'bordered'} bg-white rounded mt-large`}>
        <Row className={'ph-large pv-medium'} type={'flex'} align={'middle'}>
          <Col span={12}>{lineItem.claimStatus ? <PlanManagementClaimTag status={lineItem.claimStatus} /> : ''}</Col>
          <Col span={12} className={'text-align-right'}>
            {displayMode === 'VIEW'
              ? lineItem.claimStatus === PlanManagementClaimStatus.UNCLAIMED && (
                  <>
                    <SecondaryButton
                      disabled={isLoading || disabledEdit}
                      onClick={() => this.props.editLineItem(lineItemIndex)}
                      className={'mr-medium'}
                      icon={'edit'}
                    >
                      Edit...
                    </SecondaryButton>
                    <SecondaryButton
                      loading={isLoading}
                      disabled={disabledEdit}
                      onClick={this._deleteLineItem}
                      color={'red-dark'}
                      icon={'delete'}
                    >
                      Delete
                    </SecondaryButton>
                  </>
                )
              : !hideActions && (
                  <>
                    <PrimaryButton
                      loading={isLoading}
                      disabled={disabledEdit}
                      className={'mr-medium'}
                      onClick={this._saveLineItem}
                    >
                      Save
                    </PrimaryButton>
                    <SecondaryButton disabled={isLoading || disabledEdit} onClick={this.props.cancelEdit}>
                      Cancel
                    </SecondaryButton>
                  </>
                )}
          </Col>
        </Row>
        <Row gutter={24} className={'ph-large pt-small pb-x-small'}>
          <Col span={4}>
            <SubTitle>Billing Date</SubTitle>
          </Col>
          <Col span={6}>
            <SubTitle>Support item</SubTitle>
          </Col>
          <Col span={4}>
            <SubTitle>Claim type</SubTitle>
          </Col>
          <Col span={3}>
            <SubTitle>Unit</SubTitle>
          </Col>
          <Col span={4}>
            <SubTitle>Price</SubTitle>
          </Col>
          <Col span={3}>
            <SubTitle>Quantity</SubTitle>
          </Col>
        </Row>
        <div className={'bordered-bottom border-standard-gray'} />
        <Row gutter={24} className={'ph-large pv-medium'}>
          <Col span={4}>
            {displayMode === 'VIEW' ? (
              moment(displayLineItem.billingDate).format('DD/MM/YYYY')
            ) : (
              <Form.Item className="m-none">
                {getFieldDecorator('billingDate', {
                  initialValue: moment(
                    displayLineItem.billingDate
                      ? displayLineItem.billingDate
                      : this.props.invoiceIssuedDate
                      ? this.props.invoiceIssuedDate
                      : moment()
                  ).toDate(),
                  valuePropName: 'selected',
                  rules: [{ required: true, message: 'Choose a date.' }]
                })(
                  <DatePicker
                    className="gh-datepicker-invoice-line-item rounded"
                    calendarClassName="gh-datepicker-calendar"
                    dateFormat="dd/MM/yyyy"
                    onChange={() => {}}
                  />
                )}
              </Form.Item>
            )}
          </Col>
          <Col span={6} className={'flex-row justify-between'}>
            {displayLineItem.supportItemNumber ? (
              <div className={displayMode === 'EDIT' && 'pt-small'}>
                <Text color={'secondary'}>{displayLineItem.supportItemNumber}</Text>
                <br />
                <Text>
                  {NDISPriceGuideItem ? (
                    NDISPriceGuideItem.SupportItem
                  ) : (
                    <Text color={'secondary'}>Line item not found</Text>
                  )}
                </Text>
              </div>
            ) : (
              <div className={'pt-small'}>
                <HyperlinkButton onClick={openLineItemModal}>Select line item...</HyperlinkButton>
              </div>
            )}
            {displayMode !== 'VIEW' && displayLineItem.supportItemNumber && (
              <HyperlinkButton onClick={openLineItemModal}>Change...</HyperlinkButton>
            )}
            {errorNoSupportItemSelected && <Text color={'red-dark'}>Please select a line item.</Text>}
          </Col>
          <Col span={4}>
            {displayMode === 'VIEW' ? (
              NdisClaimTypeLabel[displayLineItem.claimType]
            ) : (
              <Form.Item className="m-none">
                {getFieldDecorator('claimType', {
                  initialValue: displayLineItem.claimType ? displayLineItem.claimType : undefined,
                  rules: [{ required: true, message: 'Select a claim type.' }]
                })(
                  <Select
                    placeholder={'Select a claim type..'}
                    className={'width-full'}
                    dropdownMatchSelectWidth={false}
                  >
                    <Select.Option value={NdisClaimType.STANDARD}>{NdisClaimTypeLabel.STANDARD}</Select.Option>
                    <Select.Option value={NdisClaimType.PROVIDER_TRAVEL}>
                      {NdisClaimTypeLabel.PROVIDER_TRAVEL}
                    </Select.Option>
                    <Select.Option value={NdisClaimType.CANCELLATION}>{NdisClaimTypeLabel.CANCELLATION}</Select.Option>
                    <Select.Option value={NdisClaimType.NON_FACE_TO_FACE}>
                      {NdisClaimTypeLabel.NON_FACE_TO_FACE}
                    </Select.Option>
                    <Select.Option value={NdisClaimType.REPORT_WRITING}>
                      {NdisClaimTypeLabel.REPORT_WRITING}
                    </Select.Option>
                    <Select.Option value={NdisClaimType.TELEHEALTH_SUPPORTS}>
                      {NdisClaimTypeLabel.TELEHEALTH_SUPPORTS}
                    </Select.Option>
                    <Select.Option value={NdisClaimType.IRREGULAR_SIL_SUPPORTS}>
                      {NdisClaimTypeLabel.IRREGULAR_SIL_SUPPORTS}
                    </Select.Option>
                  </Select>
                )}
              </Form.Item>
            )}
          </Col>
          <Col span={3} className={`${displayMode !== 'VIEW' && 'pv-x-small'}`}>
            {displayMode === 'VIEW' ? (
              NdisUnitLabel[displayLineItem.unit]
            ) : (
              <>
                <Form.Item className="m-none">
                  {getFieldDecorator('unit', {
                    initialValue: displayLineItem.unit ? displayLineItem.unit : null
                  })(<Input type={'hidden'} />)}
                </Form.Item>
                <Input disabled={true} value={displayLineItem.unit ? NdisUnitLabel[displayLineItem.unit] : 'N/A'} />
              </>
            )}
          </Col>
          <Col span={4}>
            {displayMode === 'VIEW' ? (
              CommonUtils.formatPrice(Number(displayLineItem.price))
            ) : (
              <Form.Item className="m-none">
                {getFieldDecorator('price', {
                  initialValue: displayLineItem.price ? Number(displayLineItem.price) : undefined,
                  rules: [{ required: true, message: 'Enter price.' }]
                })(
                  <NumberInput precision={2} max={100000} onChange={this._onChangePrice} style={{ width: '100px' }} />
                )}
              </Form.Item>
            )}
          </Col>
          <Col span={3}>
            {displayMode === 'VIEW' ? (
              Number(displayLineItem.qty).toFixed(2)
            ) : (
              <Form.Item className="m-none">
                {getFieldDecorator('qty', {
                  initialValue: displayLineItem.qty ? Number(displayLineItem.qty) : undefined,
                  rules: [{ required: true, message: 'Enter quantity.' }]
                })(
                  <NumberInput precision={2} onChange={this._onChangeQuantity} max={9999} style={{ width: '100px' }} />
                )}
              </Form.Item>
            )}
          </Col>
        </Row>
        {displayCancellation && (
          <Row gutter={24} className={'ph-large pv-medium'}>
            <Col span={24}>
              <SubTitle>Cancellation code</SubTitle>
              {displayMode === 'VIEW' ? (
                displayLineItem.cancellationCode + ' - ' + NdisCancellationReasonLabel[displayLineItem.cancellationCode]
              ) : (
                <Form.Item className="m-none">
                  {getFieldDecorator('cancellationCode', {
                    initialValue: displayLineItem.cancellationCode ? displayLineItem.cancellationCode : undefined,
                    rules: [{ required: true, message: 'Enter a cancellation reason.' }]
                  })(
                    <Select style={{ width: '500px' }}>
                      <Select.Option value={NdisCancellationReason.NSDH}>
                        NSDH - No show due to health reasons
                      </Select.Option>
                      <Select.Option value={NdisCancellationReason.NSDF}>
                        NSDF - No show due to family issues
                      </Select.Option>
                      <Select.Option value={NdisCancellationReason.NSDT}>
                        NSDT - No show due to unavailability of transport
                      </Select.Option>
                      <Select.Option value={NdisCancellationReason.NSDO}>
                        NSDO - No show due to other reasons
                      </Select.Option>
                    </Select>
                  )}
                </Form.Item>
              )}
            </Col>
          </Row>
        )}
        <div className={'bordered-bottom border-standard-gray'} />
        <Row className={'ph-large pv-medium text-align-right'}>
          <Text weight={'bold'}>{CommonUtils.formatPrice(total)}</Text>
        </Row>
      </div>
    );
  }
}

const mapDispatch = (dispatch: IRootDispatch) => ({
  // if
});

const mapState = (state: IRootState) => ({
  // if needed
});

export default connect(
  mapState,
  mapDispatch
)(Form.create<IPlanManagementBillingLineItemProps>()(PlanManagementBillingLineItem));
