import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Paragraph } from 'common-components/typography';
import { IRootState, state } from 'stores/rematch/root-store';
import ActionModal, { ActionModalFooter } from 'common-components/modal/ActionModal';
import { PrimaryButton } from 'common-components/buttons';
import { IGroupServiceSession } from 'interfaces/service-interfaces';
import GroupServiceSessionsPanel from 'views/group-services/service-details/components/GroupServiceSessionsPanel';

interface IGroupBookingSessionsModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  sessions: IGroupServiceSession[];
  selectedGroupService: typeof state.groupServiceStore.selectedGroupService;
}

interface IGroupBookingSessionsModalState {}

class GroupBookingSessionsModal extends Component<IGroupBookingSessionsModalProps, IGroupBookingSessionsModalState> {
  render() {
    const { isOpen, sessions } = this.props;

    return (
      <ActionModal title={'Session preview'} isOpen={isOpen} onClose={this.props.onCloseModal} width="x-large">
        <div>
          <Paragraph>The following sessions will be created</Paragraph>
          <GroupServiceSessionsPanel
            sessions={sessions}
            hideEmptyDates={true}
            timezone={this.props.selectedGroupService.timezone}
          />
        </div>
        <ActionModalFooter>
          <PrimaryButton size="large" onClick={this.props.onCloseModal}>
            Close
          </PrimaryButton>
        </ActionModalFooter>
      </ActionModal>
    );
  }
}

const mapState = (state: IRootState) => ({
  selectedGroupService: state.groupServiceStore.selectedGroupService
});

export default connect(
  mapState,
  null
)(GroupBookingSessionsModal);
